import { shallowEqual, useSelector } from 'react-redux';
import getPartnerProperties from 'yola-editor/src/js/modules/user/selectors/partner-properties';
import paymentMethodsMap from '../helpers/payment-methods-map';

const usePaymentMethods = () => {
  const { availablePaymentMethods = [] } = useSelector(getPartnerProperties, shallowEqual);

  return availablePaymentMethods.reduce(
    (availableMethods, method) => [...availableMethods, ...(paymentMethodsMap.get(method) || [])],
    []
  );
};

export default usePaymentMethods;
