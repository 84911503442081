import React from 'react';
import { DragLayer } from '@yola/ws-ui';
import PropTypes from 'prop-types';
import constants from '../constants';
import GalleryItemPreview from './gallery-item-preview';

function getPreviewPositioning(initialOffset, currentOffset) {
  const transform = `translate3d(${currentOffset.x - initialOffset.x}px, ${
    currentOffset.y - initialOffset.y
  }px, 0px)`;

  return {
    top: initialOffset.y,
    left: initialOffset.x,
    transform,
  };
}

function getPreviewItemPositioning(previewStackOrder) {
  const stackOffset = 8;
  return {
    position: `absolute`,
    top: `${previewStackOrder * stackOffset}px`,
    left: `${previewStackOrder * stackOffset}px`,
  };
}

function GalleryDragPreview(props) {
  const { draggedItemsData, initialOffset, currentOffset, itemType, previewsStackLength } = props;

  if (
    !draggedItemsData ||
    !draggedItemsData.length ||
    !currentOffset ||
    itemType !== constants.dnd.DRAG_ITEM
  ) {
    return null;
  }

  // Reverse sorted items to render them using
  // natural stacking context without z-indexes
  const draggedItemsDataPrepared = draggedItemsData
    .sort((a, b) => a.previewStackOrder - b.previewStackOrder)
    .slice(0, previewsStackLength)
    .reverse();

  return (
    <div
      className="ws-gallery-item ws-gallery-item--drag-preview"
      style={{ ...getPreviewPositioning(initialOffset, currentOffset) }}
    >
      {draggedItemsDataPrepared.map((item) => (
        <GalleryItemPreview
          key={item.id}
          src={item.src}
          width={item.width}
          order={item.order}
          style={{ ...getPreviewItemPositioning(item.previewStackOrder) }}
          isPlaceholder={item.isPlaceholder}
        />
      ))}
    </div>
  );
}

GalleryDragPreview.propTypes = {
  draggedItemsData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      src: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
      order: PropTypes.number,
      previewStackOrder: PropTypes.number,
    })
  ),
  initialOffset: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }),
  currentOffset: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }),
  itemType: PropTypes.string,
  previewsStackLength: PropTypes.number,
};

GalleryDragPreview.defaultProps = {
  initialOffset: null,
  currentOffset: null,
  itemType: null,
  draggedItemsData: null,
  previewsStackLength: 2,
};

const collect = (monitor) => {
  const draggingData = monitor.getItem();
  return {
    initialOffset: monitor.getInitialSourceClientOffset(),
    itemType: monitor.getItemType(),
    currentOffset: monitor.getSourceClientOffset(),
    draggedItemsData: draggingData && draggingData.draggedItemsData,
  };
};

export default DragLayer(collect)(GalleryDragPreview);
