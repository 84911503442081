import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, designSystem } from '@yola/ws-ui';
import { template } from '@yola/ws-sdk';
import constants from '../constants';
import helpers from '../helpers';
import CookiebotIntegrationContainer from '../../integrations/containers/cookiebot-integration-container';
import InputGroupField from '../../common/components/input-group-field';
import CheckboxField from '../../common/components/checkbox-field';

const {
  Stack,
  ImagePickerPreview,
  Paragraph,
  Heading,
  HeadingGroup,
  ControlGroup,
  Box,
  ActionButton,
} = designSystem;
const { tabIdentifiers, settingTypes } = constants;

function GeneralSection(props) {
  const {
    captions,
    onWrongTypeFile,
    uploadImage,
    siteNameRef,
    onUnpublish,
    onResetSite,
    onSwitchTemplate,
    onExportSite,
    setImagePreview,
    integrationTypes,
    form,
    initialValues,
    showPromptForce,
    isBusinessNameSameAsSiteName,
    setFieldValue,
    onSiteNameDescriptionClick,
  } = props;

  const { favicon } = form;

  const [faviconPreview, setFaviconPreview] = useState(setImagePreview(favicon));
  const isNewTemplate = template.accessors.getBuildSlug().startsWith('ws-intense-next');
  const enableTemplateSwitching = isNewTemplate && onSwitchTemplate;

  const preventGoogleSearchIndexingSection = (
    <ControlGroup
      title={captions.dangerZoneCaptions.preventGoogleSearchIndexing.name}
      description={captions.dangerZoneCaptions.preventGoogleSearchIndexing.description}
    >
      <Field
        component={CheckboxField}
        id="googleSearch"
        name={settingTypes.PREVENT_INDEXING}
        checked={form[settingTypes.PREVENT_INDEXING]}
        label={captions.dangerZoneCaptions.preventGoogleSearchIndexing.label}
      />
    </ControlGroup>
  );

  const unpublishSection = (
    <ControlGroup
      title={captions.dangerZoneCaptions.unpublish.name}
      description={captions.dangerZoneCaptions.unpublish.description}
    >
      <ActionButton
        onClick={onUnpublish}
        appearance="danger"
        label={captions.dangerZoneCaptions.unpublish.button}
      />
    </ControlGroup>
  );

  const resetSiteSection = (
    <ControlGroup
      title={captions.dangerZoneCaptions.reset.name}
      description={captions.dangerZoneCaptions.reset.description}
    >
      <ActionButton
        onClick={onResetSite}
        appearance="danger"
        label={captions.dangerZoneCaptions.reset.button}
      />
    </ControlGroup>
  );

  const switchTemplateSection = (
    <ControlGroup
      title={captions.dangerZoneCaptions.switchTemplate.name}
      description={captions.dangerZoneCaptions.switchTemplate.description}
    >
      <ActionButton
        onClick={onSwitchTemplate}
        appearance="danger"
        label={captions.dangerZoneCaptions.switchTemplate.button}
      />
    </ControlGroup>
  );

  const dangerSection = (
    <HeadingGroup
      title={captions.dangerZoneCaptions.name}
      description={captions.dangerZoneCaptions.description}
      appearance="danger"
    >
      <Box marginTop="spacing-m">
        <Stack gap="spacing-m">
          {preventGoogleSearchIndexingSection}
          {onUnpublish && unpublishSection}
          {enableTemplateSwitching && switchTemplateSection}
          {onResetSite && resetSiteSection}
        </Stack>
      </Box>
    </HeadingGroup>
  );

  const validateSiteName = helpers.validateString(
    { required: true },
    captions.siteName.errorMessages
  );

  const onSiteNameChange = (value) => {
    if (isBusinessNameSameAsSiteName) {
      setFieldValue(settingTypes.BUSINESS_NAME, value);
    }
  };

  return (
    <Stack gap="spacing-m">
      {/* eslint-disable-next-line yola/react/no-unknown-property */}
      <div settings-field-name={settingTypes.SITE_NAME} ref={siteNameRef}>
        <Stack gap="spacing-3-xs">
          <Heading type="heading-6">{captions.siteName.name}</Heading>
          <Field
            component={InputGroupField}
            name={settingTypes.SITE_NAME}
            validate={validateSiteName}
            onChange={onSiteNameChange}
          />
          <Paragraph size="small" appearance="medium-emphasis">
            <span
              onClick={onSiteNameDescriptionClick}
              // eslint-disable-next-line yola/react/no-danger
              dangerouslySetInnerHTML={{ __html: captions.siteName.description }}
            />
          </Paragraph>
        </Stack>
      </div>

      {/* eslint-disable-next-line yola/react/no-unknown-property */}
      <div settings-field-name={settingTypes.FAVICON}>
        <Stack gap="spacing-3-xs">
          <Heading type="heading-6">{captions.favicon.name}</Heading>
          <Field
            name={settingTypes.FAVICON}
            render={({ field, form: formData }) => (
              <ImagePickerPreview
                imageSource={faviconPreview}
                accept=".ico, .png"
                label={captions.fileInputCaptions.button}
                placeholder={captions.fileInputCaptions.empty}
                fileItemName={helpers.getFileName(faviconPreview)}
                statusText={captions.favicon.description}
                onChange={(filesList) => {
                  uploadImage(filesList, form, settingTypes.FAVICON);
                  formData.setFieldValue(field.name, filesList[0].name, false);
                }}
                onClear={() => {
                  setFaviconPreview(null);
                  formData.setFieldValue(field.name, '', false);
                }}
                onError={(wrongExtList) =>
                  onWrongTypeFile(wrongExtList, form, settingTypes.FAVICON)
                }
              />
            )}
          />
        </Stack>
      </div>

      <HeadingGroup title={captions.cookiesUserData.name}>
        <Stack gap="spacing-xs">
          {!form[integrationTypes.COOKIEBOT_ID] && (
            <Field
              component={CheckboxField}
              id="cookiesUserData"
              name={settingTypes.IS_COOKIE_BANNER_ENABLED}
              checked={form[settingTypes.IS_COOKIE_BANNER_ENABLED]}
              label={captions.cookiesUserData.title}
              description={captions.cookiesUserData.description}
            />
          )}
          <CookiebotIntegrationContainer
            form={form}
            activeTabId={tabIdentifiers.GENERAL}
            captions={captions.integrations.cookiebot}
            initialValues={initialValues}
            showPromptForce={showPromptForce}
          />
        </Stack>
      </HeadingGroup>

      {(onUnpublish || onResetSite || enableTemplateSwitching) && dangerSection}

      {onExportSite && (
        <ControlGroup
          title={captions.exportSite.name}
          description={captions.exportSite.description}
        >
          <ActionButton onClick={onExportSite} label={captions.exportSite.button} />
        </ControlGroup>
      )}
    </Stack>
  );
}

GeneralSection.propTypes = {
  siteNameRef: PropTypes.shape().isRequired,
  setImagePreview: PropTypes.func.isRequired,
  onWrongTypeFile: PropTypes.func.isRequired,
  captions: PropTypes.shape().isRequired,
  form: PropTypes.shape().isRequired,
  uploadImage: PropTypes.func.isRequired,
  integrationTypes: PropTypes.shape().isRequired,
  onUnpublish: PropTypes.func,
  onResetSite: PropTypes.func,
  onSwitchTemplate: PropTypes.func,
  onExportSite: PropTypes.func,
  initialValues: PropTypes.shape().isRequired,
  showPromptForce: PropTypes.bool,
  isBusinessNameSameAsSiteName: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onSiteNameDescriptionClick: PropTypes.func.isRequired,
};

GeneralSection.defaultProps = {
  onUnpublish: null,
  onResetSite: null,
  onSwitchTemplate: null,
  onExportSite: null,
  showPromptForce: false,
};

export default GeneralSection;
