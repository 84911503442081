import React from 'react';
import PropTypes from 'prop-types';
import { ViewportConsumer } from 'yola-editor/src/js/utils/viewport-provider';
import ViewOverlay from '../components/view-overlay';

function ViewOverlayContainer({ children }) {
  return (
    <ViewportConsumer>
      {({ height, width }) => (
        <ViewOverlay height={`${height}px`} width={`${width}px`}>
          {children}
        </ViewOverlay>
      )}
    </ViewportConsumer>
  );
}

ViewOverlayContainer.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default ViewOverlayContainer;
