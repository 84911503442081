import { camelCaseObjectToSnakeCase } from '../../utils/convert-case';
import interpolateStringToTemplate from '../../utils/interpolate-string-to-template';

const compileCustomTemplate = (customTemplate, palette) => {
  const { variables } = palette;

  return interpolateStringToTemplate(customTemplate, camelCaseObjectToSnakeCase(variables));
};

export default compileCustomTemplate;
