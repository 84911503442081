import constants from './constants';
import control from './controls/control';

const { common } = constants;

const extension = {
  slug: common.SLUG,
  title: 'Link Extension',
  description: 'Extension for editing links',
  defaults: {
    querySelector: ':not(.emails):not(.social-links) > a[href]:not(.ws-nav__menu-link)',
  },
  ui: {
    control: [control],
  },
};

export default extension;
