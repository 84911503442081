import { USER_MODIFIED_ATTR } from '../constants/attributes';

function isUserModifiedAttributeByElement(element, attrName) {
  if (!element || !element.hasAttribute(USER_MODIFIED_ATTR)) {
    return false;
  }

  return element.getAttribute(USER_MODIFIED_ATTR).split(',').includes(attrName);
}

export default isUserModifiedAttributeByElement;
