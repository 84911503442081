import { textFormatting } from '@yola/ws-sdk';
import removeClassNameByPrefix from './remove-classname-by-prefix';

const { FONT_SIZE_MODIFIER_PREFIX } = textFormatting.constants;

const removeFontSizeModifierFromNode = (node) => {
  const classNameWithoutModifiers = removeClassNameByPrefix(
    node.className,
    FONT_SIZE_MODIFIER_PREFIX
  ).trim();
  node.setAttribute('class', classNameWithoutModifiers);
};

export default removeFontSizeModifierFromNode;
