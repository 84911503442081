import { hooks, site, template } from '@yola/ws-sdk';
import constants from '../constants';
import track from '../track';

const trackPageCreated = () => {
  hooks.operations.subscribe(hooks.availableHooks.ON_AFTER_ADD_NEW_PAGE, (pageId) => {
    track(constants.events.PAGE_CREATED_EVENT, {
      siteId: site.accessors.getSiteId(),
      templateBuildSlug: template.accessors.getBuildSlug(),
      pageTemplate: site.accessors.getPageTemplateId(pageId) || null,
      pageCount: site.accessors.getPages().length,
    });
  });
};

export default trackPageCreated;
