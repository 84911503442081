import actionTypes from '../constants/action-types';

const publishedSiteData = (state = null, action) => {
  switch (action.type) {
    case actionTypes.SET_PUBLISHED_SITE_DATA:
      return action.payload.publishedData;

    case actionTypes.SET_PRIMARY_VHOST:
      return { ...state, primaryVhost: action.payload.primaryVhost };

    case actionTypes.SUBMIT_SITEMAP: {
      return { ...state, sitemapManuallySubmitted: true };
    }

    case actionTypes.SET_PUBLISHED_SITE_VHOSTS: {
      // Save vhosts data to store only if vhosts are presented.
      // This is needed to keep existing `publishedSiteData` checks working.
      if (action.payload.vhosts && action.payload.vhosts.length) {
        return { ...state, vhosts: action.payload.vhosts };
      }
      return state && { ...state };
    }
    default:
      return state;
  }
};

export default publishedSiteData;
