import B2cDomainPurchaseDialogContainer from 'yola-editor/src/js/modules/upsell/containers/b2c-domain-purchase-dialog-container';
import ErrorDialog from 'yola-editor/src/js/modules/error/components/error-dialog';
import ProductSelectDialogContainer from 'yola-editor/src/js/modules/upsell/containers/product-select-dialog-container';
import PaywallContainer from 'yola-editor/src/js/modules/upsell/containers/paywall-container';
import SimpleDialogContainer from '../containers/simple-dialog-container';
import PublishSuccessDialogContainer from '../containers/publish-success-dialog-container';
import PublishProgressDialogContainer from '../containers/publish-progress-dialog-container';
import UnpublishProgressDialogContainer from '../containers/unpublish-progress-dialog-container';
import UnpublishSuccessDialogContainer from '../containers/unpublish-success-dialog-container';
import UnpublishConfirmActionDialogContainer from '../containers/unpublish-confirm-action-dialog-container';
import SiteResetDialogContainer from '../containers/site-reset-dialog-container';
import SiteResetUnpublishDialogContainer from '../containers/site-reset-unpublish-dialog-container';
import SiteResetProgressDialogContainer from '../containers/site-reset-progress-dialog-container';
import PurchasePendingDialogContainer from '../../upsell/containers/purchase-pending-dialog-container';
import PurchaseFailureDialogContainer from '../../upsell/containers/purchase-failure-dialog-container';
import PurchaseSuccessDialogContainer from '../../upsell/containers/purchase-success-dialog-container';
import CheckEmailDialogContainer from '../containers/check-email-dialog-container';
import VerifyEmailDialogContainer from '../containers/verify-email-dialog-container';
import ProgressDialogContainer from '../containers/progress-dialog-container';
import WlDomainPurchaseDialogContainer from '../../upsell/containers/wl-domain-purchase-dialog-container';
import UpgradeDialogContainer from '../../upsell/containers/upgrade-dialog-container';
import PendingDialog from '../../upsell/components/pending-dialog';
import ThankYouDialogContainer from '../../survey/containers/thank-you-dialog-container';
import PmfSurveyDialogContainer from '../../survey/pmf/containers/survey-dialog-container';
import NpsSurveyDialogContainer from '../../survey/nps/containers/survey-dialog-container';
import AiSurveyDialogContainer from '../../survey/ai/containers/ai-survey-dialog-container';
import ShareDialog from '../../refer-a-friend/components/share-dialog';
import operations from '../operations';
import dialogTypes from '../constants/dialog-types';
import RequiredCookiesErrorDialogContainer from '../containers/required-cookies-error-dialog-container';
import DifmPrivacyUpsellContainer from '../../difm-privacy-upsell/containers/difm-privacy-upsell-container';
import SubscriptionExpiredDialogContainer from '../../subscriptions/containers/subscription-expired-dialog-container';

const registerDialogs = () => {
  operations.register(dialogTypes.SIMPLE, SimpleDialogContainer);
  operations.register(dialogTypes.PUBLISH_PROGRESS, PublishProgressDialogContainer);
  operations.register(dialogTypes.PUBLISH_SUCCESS, PublishSuccessDialogContainer);
  operations.register(dialogTypes.PUBLISH_ERROR, ErrorDialog);
  operations.register(dialogTypes.B2C_DOMAIN_PURCHASE, B2cDomainPurchaseDialogContainer);
  operations.register(dialogTypes.UNPUBLISH_PROGRESS, UnpublishProgressDialogContainer);
  operations.register(dialogTypes.UNPUBLISH_CONFIRM_ACTION, UnpublishConfirmActionDialogContainer);
  operations.register(dialogTypes.UNPUBLISH_SUCCESS, UnpublishSuccessDialogContainer);
  operations.register(dialogTypes.UNPUBLISH_ERROR, ErrorDialog);
  operations.register(dialogTypes.SITE_RESET, SiteResetDialogContainer);
  operations.register(dialogTypes.SITE_RESET_ERROR, ErrorDialog);
  operations.register(dialogTypes.SITE_RESET_AND_UNPUBLISH, SiteResetUnpublishDialogContainer);
  operations.register(dialogTypes.SITE_RESET_PROGRESS, SiteResetProgressDialogContainer);
  operations.register(dialogTypes.IN_APP_PURCHASE, ProductSelectDialogContainer);
  operations.register(dialogTypes.IN_APP_PURCHASE_PENDING, PurchasePendingDialogContainer);
  operations.register(dialogTypes.IN_APP_PURCHASE_SUCCESS, PurchaseSuccessDialogContainer);
  operations.register(dialogTypes.IN_APP_PURCHASE_FAILURE, PurchaseFailureDialogContainer);
  operations.register(dialogTypes.PAYWALL, PaywallContainer);
  operations.register(dialogTypes.VERIFY_EMAIL, VerifyEmailDialogContainer);
  operations.register(dialogTypes.CHECK_VERIFICATION_EMAIL, CheckEmailDialogContainer);
  operations.register(dialogTypes.PROGRESS, ProgressDialogContainer);
  operations.register(dialogTypes.WL_DOMAIN_PURCHASE, WlDomainPurchaseDialogContainer);
  operations.register(dialogTypes.UPGRADE, UpgradeDialogContainer);
  operations.register(dialogTypes.PENDING, PendingDialog);
  operations.register(dialogTypes.SURVEY_THANK_YOU, ThankYouDialogContainer);
  operations.register(dialogTypes.PMF_SURVEY, PmfSurveyDialogContainer);
  operations.register(dialogTypes.NPS_SURVEY, NpsSurveyDialogContainer);
  operations.register(dialogTypes.AI_SURVEY, AiSurveyDialogContainer);
  operations.register(dialogTypes.SHARE_DIALOG, ShareDialog);
  operations.register(dialogTypes.REQUIRED_COOKIES_ERROR, RequiredCookiesErrorDialogContainer);
  operations.register(dialogTypes.DIFM_PRIVACY_UPSELL_DIALOG, DifmPrivacyUpsellContainer);
  operations.register(dialogTypes.SUBSCRIPTION_EXPIRED, SubscriptionExpiredDialogContainer);
  operations.register(dialogTypes.PREVIEW_PROGRESS, PublishProgressDialogContainer);
  operations.register(dialogTypes.PREVIEW_ERROR, ErrorDialog);
};

export default registerDialogs;
