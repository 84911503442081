import advertisingLimit from './limit-detectors/advertising-limit';
import analyticsLimit from './limit-detectors/analytics-limit';
import premiumBlocksLimit from './limit-detectors/premium-blocks-limit';
import premiumIconsLimit from './limit-detectors/premium-icons-limit';
import mobilePlusLimit from './limit-detectors/mobile-plus-limit';
import customCodeLimit from './limit-detectors/custom-code-limit';
import brandedFooterLimit from './limit-detectors/branded-footer-limit';
import brandedHeaderLimit from './limit-detectors/branded-header-limit';
import pagesLimit from './limit-detectors/pages-limit';
import storageLimit from './limit-detectors/storage-limit';
import uploadFileSizeLimit from './limit-detectors/upload-file-size-limit';
import onlineStoreLimit from './limit-detectors/online-store-limit';
import nofollowControlsLimit from './limit-detectors/nofollow-controls-limit';
import siteBackupsLimit from './limit-detectors/site-backups-limit';
import multilingualLimit from './limit-detectors/multilingual-limit';
import shopPageTemplateLimit from './limit-detectors/shop-page-template-limit';
import externalLinks from './limit-detectors/external-links';
import customDomain from './limit-detectors/custom-domain-limit';
import advancedColorCustomizationLimit from './limit-detectors/advanced-color-customization-limit';
import passwordProtectedPages from './limit-detectors/password-protected-pages-limit';
import formAttachmentsLimit from './limit-detectors/form-attachments-limit';
import aiPageWizardLimit from './limit-detectors/ai-page-wizard-limit';
import aiBlockGenerationQuota from './limit-detectors/ai-block-generation-limit';
import aiTextAssistantQuota from './limit-detectors/ai-text-assistant-limit';
import premiumButtonPresetsLimit from './limit-detectors/premium-button-presets-limit';
import premiumTextDecorationsLimit from './limit-detectors/premium-text-decorations-limit';

const calculateFeatureLimits = ({
  availablePlatformComponents,
  activeSubscriptions,
  availableSubscriptionTypes,
  availableNonPaidFeatures,
  userFeatures,
  targetPackage,
}) => ({
  advancedColorCustomization: advancedColorCustomizationLimit({ targetPackage }),
  advertising: advertisingLimit({ availableSubscriptionTypes }),
  analytics: analyticsLimit({ availableNonPaidFeatures, availablePlatformComponents }),
  premiumIcons: premiumIconsLimit({ targetPackage }),
  premiumBlocks: premiumBlocksLimit({ targetPackage }),
  mobilePlus: mobilePlusLimit({ targetPackage }),
  customCode: customCodeLimit({ targetPackage }),
  brandedFooter: brandedFooterLimit({ targetPackage, userFeatures }),
  brandedHeader: brandedHeaderLimit({ targetPackage, userFeatures }),
  pages: pagesLimit({ targetPackage }),
  storageLimit: storageLimit({ targetPackage }),
  uploadFileSize: uploadFileSizeLimit({ targetPackage }),
  onlineStore: onlineStoreLimit({ activeSubscriptions, availableSubscriptionTypes }),
  nofollowControls: nofollowControlsLimit({ targetPackage }),
  siteBackupsLimit: siteBackupsLimit({ targetPackage }),
  multilingualLimit: multilingualLimit({ targetPackage }),
  shopPageTemplateLimit: shopPageTemplateLimit({ availableSubscriptionTypes }),
  externalLinks: externalLinks({ targetPackage }),
  customDomain: customDomain({ targetPackage }),
  passwordProtectedPages: passwordProtectedPages({ targetPackage }),
  formAttachments: formAttachmentsLimit({ targetPackage }),
  aiPageWizard: aiPageWizardLimit({ targetPackage }),
  aiBlockGenerationQuota: aiBlockGenerationQuota({ targetPackage }),
  aiTextAssistantQuota: aiTextAssistantQuota({ targetPackage }),
  premiumButtonPresets: premiumButtonPresetsLimit({ targetPackage }),
  premiumTextDecorations: premiumTextDecorationsLimit({ targetPackage }),
});

export default calculateFeatureLimits;
