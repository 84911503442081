import React, { useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';
import { hooks, hdrm, site } from '@yola/ws-sdk';

const { Provider, Consumer } = React.createContext();

function mousePositionReducer(state, action) {
  switch (action.type) {
    case 'reset':
      return { x: null, y: null };
    case 'change': {
      const { mousePosition } = action;

      if (mousePosition.x !== state.x || mousePosition.y !== state.y) return mousePosition;

      return state;
    }
    default:
      return state;
  }
}

function MousePositionContext({ children }) {
  const [mousePosition, dispatch] = useReducer(mousePositionReducer, { x: null, y: null });
  const activePage = useSelector(site.selectors.getActivePageId);

  useEffect(() => {
    dispatch({ type: 'reset' });
  }, [activePage]);

  useEffect(() => {
    function onLiveDocumentConnected(liveDocument) {
      const onMouseMove = throttle((event) => {
        const { clientX: x, clientY: y } = event;

        dispatch({
          type: 'change',
          mousePosition: {
            x,
            y,
          },
        });
      }, 13);

      hdrm.listeners.eventListeners.add(liveDocument.body, 'mousemove', onMouseMove, {
        passive: true,
      });
    }

    hooks.operations.subscribe(
      hooks.availableHooks.ON_LIVE_DOCUMENT_CONNECTED,
      onLiveDocumentConnected
    );

    return () => {
      hooks.operations.unsubscribe(
        hooks.availableHooks.ON_LIVE_DOCUMENT_CONNECTED,
        onLiveDocumentConnected
      );
    };
  }, []);

  return <Provider value={mousePosition}>{children}</Provider>;
}

MousePositionContext.propTypes = {
  children: PropTypes.node,
};

MousePositionContext.defaultProps = {
  children: null,
};

export default {
  Provider: MousePositionContext,
  Consumer,
};
