import tours from './tours';

const mediaSources = {
  [tours.WELCOME_TOUR]: [
    {
      mobile: '/images/welcome-tour/mob-first-step.png',
      desktop: '/images/welcome-tour/desktop-first-step.png',
    },
    {
      mobile: 'https://cdn.ws-platform.net/welcome-tour/welcome-tour-mobile-add.mp4',
      desktop: 'https://cdn.ws-platform.net/welcome-tour/welcome-tour-desktop-add.mp4',
    },
    {
      mobile: 'https://cdn.ws-platform.net/welcome-tour/welcome-tour-mobile-modify.mp4',
      desktop: 'https://cdn.ws-platform.net/welcome-tour/welcome-tour-desktop-modify.mp4',
    },
    {
      mobile: 'https://cdn.ws-platform.net/welcome-tour/welcome-tour-mobile-brand.mp4',
      desktop: 'https://cdn.ws-platform.net/welcome-tour/welcome-tour-desktop-brand.mp4',
    },
    {
      mobile: 'https://cdn.ws-platform.net/welcome-tour/welcome-tour-mobile-publish.mp4',
      desktop: 'https://cdn.ws-platform.net/welcome-tour/welcome-tour-desktop-publish.mp4',
    },
    {
      mobile: 'https://cdn.ws-platform.net/welcome-tour/welcome-tour-mobile-grow.mp4',
      desktop: 'https://cdn.ws-platform.net/welcome-tour/welcome-tour-desktop-grow.mp4',
    },
  ],
  [tours.MULTILINGUAL_TOUR]: [
    {
      mobile: '/images/multilingual-tour/intro-mobile.png',
      desktop: '/images/multilingual-tour/intro-desktop.png',
    },
    {
      mobile: 'https://cdn.ws-platform.net/multilingual-tour/translate-mobile.mp4',
      desktop: 'https://cdn.ws-platform.net/multilingual-tour/translate-desktop-yola.mp4',
    },
    {
      mobile: 'https://cdn.ws-platform.net/multilingual-tour/navigate-mobile-yola.mp4',
      desktop: 'https://cdn.ws-platform.net/multilingual-tour/navigate-desktop-yola.mp4',
    },
    {
      mobile: 'https://cdn.ws-platform.net/multilingual-tour/adapt-mobile.mp4',
      desktop: 'https://cdn.ws-platform.net/multilingual-tour/adapt-desktop-yola.mp4',
    },
  ],
};

export default mediaSources;
