import ShortTextIcon from './short-text-icon';
import LongTextIcon from './long-text-icon';
import DropListIcon from './drop-list-icon';
import CheckboxesIcon from './checkboxes-icon';
import RadiobuttonsIcon from './radiobuttons-icon';
import AttachFileIcon from './attach-file-icon';
import DateIcon from './date-icon';
import TimeIcon from './time-icon';

export default {
  ShortTextIcon,
  LongTextIcon,
  DropListIcon,
  CheckboxesIcon,
  RadiobuttonsIcon,
  AttachFileIcon,
  DateIcon,
  TimeIcon,
};
