export default {
  primary600: '#34363c',
  primary700: '#262a31',
  primary800: '#141B21',
  cta600: '#0072ef',
  cta700: '#005ed9',
  cta800: '#094aba',
  info600: '#31B0D5',
  info700: '#1F9BBF',
  info800: '#0E87AB',
  success600: '#1AB744',
  success700: '#1B9B4A',
  success800: '#0B8738',
  warning600: '#FFCA28',
  warning700: '#FFB302',
  warning800: '#FF8F00',
  danger600: '#FF7043',
  danger700: '#F4511E',
  danger800: '#D84315',
};
