import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { dialogs } from '@yola/ws-sdk';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import SiteBackups from '../../site-backups/containers/site-backups';
import constants from '../constants';

const { tabIdentifiers } = constants;

function BackupsSection({ form }) {
  const dispatch = useDispatch();

  // Return to the current active tab with the form data kept
  const returnToCurrentTab = () => {
    dispatch(
      dialogs.actions.show(dialogTypes.WEBSITE_SETTINGS, {
        form,
        activeTabId: tabIdentifiers.BACKUPS,
      })
    );
  };

  return (
    <SiteBackups
      onBackupCreateSuccess={returnToCurrentTab}
      onCreateCancel={returnToCurrentTab}
      onRestoreCancel={returnToCurrentTab}
    />
  );
}

BackupsSection.propTypes = {
  form: PropTypes.shape().isRequired,
};

export default BackupsSection;
