import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import bowser from 'yola-bowser';
import {
  Dropdown,
  DropdownTrigger,
  DropdownContent,
  Menu,
  MenuItem,
  Tooltip,
  designSystem,
} from '@yola/ws-ui';

const { Icon, Paragraph } = designSystem;

function PageManagerItem({
  item,
  captions,
  onSetHomePageClick,
  onPageSettingsClick,
  onDeletePageClick,
  onCopyPageClick,
  onDeveloperConsoleClick,
  onItemClick,
  activeItem,
  isHomePage,
  isDeveloperConsoleEnabled,
}) {
  // We render controls only when we hover item
  // 'cause if we render all of the controls for
  // all items then browser will run out of memory
  // 'cause of plenty `<svg>` tags on the page.
  // (case with a lot of created pages)
  const [isHovered, setHoverState] = useState(false);

  const onItemClickHandler = useCallback(() => {
    onItemClick(item);
  }, [onItemClick, item]);

  const onSetHomePageClickHandler = useCallback(
    (e) => {
      onSetHomePageClick(e, item);
    },
    [onSetHomePageClick, item]
  );

  const onCopyPageClickHandler = useCallback(
    (e) => {
      onCopyPageClick(e, item.id);
    },
    [onCopyPageClick, item.id]
  );

  const onDeveloperConsoleClickHandler = useCallback(
    (e) => {
      onDeveloperConsoleClick(e, item);
    },
    [onDeveloperConsoleClick, item]
  );

  const onPageSettingsClickHandler = useCallback(
    (e) => {
      onPageSettingsClick(e, item);
    },
    [onPageSettingsClick, item]
  );

  const onDeletePageClickHandler = useCallback(
    (e) => {
      onDeletePageClick(e, item);
    },
    [onDeletePageClick, item]
  );

  useEffect(() => {
    if (isHovered) Tooltip.rebuild();
  }, [isHovered]);

  if (!item) return null;

  const isActivePage = item.id === activeItem;
  const isMobile = Boolean(bowser.mobile || bowser.tablet);
  const isDeveloperConsoleAvailable = isDeveloperConsoleEnabled && isActivePage;

  const className = classNames('ws-page-manager__item', {
    'ws-page-manager__item-active': isActivePage,
  });

  return (
    <div
      className={className}
      onClick={onItemClickHandler}
      onMouseEnter={() => setHoverState(true)}
      onMouseLeave={() => setHoverState(false)}
    >
      <Paragraph isClipped>
        <span className="ws-page-manager__item-logo">
          <Icon glyph={isHomePage ? 'home' : 'page'} size={20} />

          {item.password && (
            <span className="ws-page-manager__key-logo">
              <Icon glyph="key" size={12} type="light" strokeWidth={1.5} />
            </span>
          )}
        </span>
        <span translate="no">{item.name || item.title}</span>
      </Paragraph>
      {isMobile ? (
        <div className="ws-page-manager__item-mob-controls">
          <Dropdown dropOn="click" drop="left-top" onClick={(e) => e.stopPropagation()}>
            <DropdownTrigger>
              <span className="ws-page-manager__item-control-icon">
                <Icon glyph="more" size={16} type="inherit" />
              </span>
            </DropdownTrigger>
            <DropdownContent>
              <Menu>
                {!isHomePage && (
                  <MenuItem onClick={onSetHomePageClickHandler}>{captions.setHomePage}</MenuItem>
                )}
                <MenuItem onClick={onCopyPageClickHandler}>{captions.copyPage}</MenuItem>
                {isDeveloperConsoleAvailable && (
                  <MenuItem onClick={onDeveloperConsoleClickHandler}>
                    {captions.developerConsole}
                  </MenuItem>
                )}
                <MenuItem onClick={onPageSettingsClickHandler}>{captions.pageSettings}</MenuItem>
                {!isHomePage && (
                  <MenuItem onClick={onDeletePageClickHandler}>{captions.deletePage}</MenuItem>
                )}
              </Menu>
            </DropdownContent>
          </Dropdown>
        </div>
      ) : (
        isHovered && (
          <div className="ws-page-manager__item-controls">
            {!isHomePage && (
              <span className="ws-page-manager__item-control-icon">
                <Icon
                  data-tip={captions.setAsHomePage}
                  onClick={onSetHomePageClickHandler}
                  glyph="home"
                  size={20}
                  type="inherit"
                />
              </span>
            )}
            <span className="ws-page-manager__item-control-icon">
              <Icon
                data-tip={captions.copyPage}
                onClick={onCopyPageClickHandler}
                glyph="copy"
                size={20}
                type="inherit"
              />
            </span>
            {isDeveloperConsoleAvailable && (
              <span className="ws-page-manager__item-control-icon">
                <Icon
                  data-tip={captions.developerConsole}
                  onClick={onDeveloperConsoleClickHandler}
                  glyph="html"
                  size={20}
                  type="inherit"
                />
              </span>
            )}
            <span className="ws-page-manager__item-control-icon">
              <Icon
                data-tip={captions.pageSettings}
                onClick={onPageSettingsClickHandler}
                glyph="settings"
                size={20}
                type="inherit"
              />
            </span>
            {!isHomePage && (
              <span className="ws-page-manager__item-control-icon">
                <Icon
                  data-tip={captions.deletePage}
                  onClick={onDeletePageClickHandler}
                  glyph="trash"
                  size={20}
                  type="inherit"
                />
              </span>
            )}
          </div>
        )
      )}
    </div>
  );
}

PageManagerItem.propTypes = {
  onDeletePageClick: PropTypes.func.isRequired,
  onPageSettingsClick: PropTypes.func.isRequired,
  onSetHomePageClick: PropTypes.func.isRequired,
  onCopyPageClick: PropTypes.func.isRequired,
  onDeveloperConsoleClick: PropTypes.func.isRequired,
  item: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
    path: PropTypes.string,
    order: PropTypes.string,
    password: PropTypes.string,
  }),
  captions: PropTypes.shape({
    copyPage: PropTypes.string,
    setHomePage: PropTypes.string,
    pageSettings: PropTypes.string,
    deletePage: PropTypes.string,
    setAsHomePage: PropTypes.string,
    developerConsole: PropTypes.string,
  }).isRequired,
  activeItem: PropTypes.string,
  onItemClick: PropTypes.func.isRequired,
  isHomePage: PropTypes.bool.isRequired,
  isDeveloperConsoleEnabled: PropTypes.bool.isRequired,
};

PageManagerItem.defaultProps = {
  item: null,
  activeItem: '',
};

export default PageManagerItem;
