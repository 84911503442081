import { linkEditing } from '@yola/ws-sdk';
import constants from '../constants';
import getNewItemOrder from './get-new-item-order';

const { linkTypes } = linkEditing.constants;
const { selectors } = constants;

const getLinkConfig = (element) => {
  if (element.matches(selectors.textItem)) {
    return {
      linkType: linkTypes.NO_ACTION,
    };
  }

  return linkEditing.accessors.getLinkConfig(element);
};

const parseItemsFromDOM = (element, items = [], parentOrder = 0) => {
  element.querySelectorAll(`:scope > ${selectors.listItem}`).forEach((item, i) => {
    const id = items.length + 1;
    const order = parentOrder ? `${parentOrder}.${i + 1}` : getNewItemOrder(items);
    const contentElement = item.querySelector(`${selectors.link}, ${selectors.textItem}`);
    const subList = item.querySelector(selectors.subListContainer);

    if (!contentElement) return;

    items.push({
      id,
      order,
      title: contentElement.textContent.trim(),
      config: getLinkConfig(contentElement),
    });

    if (subList) {
      parseItemsFromDOM(subList, items, order);
    }
  });

  return items;
};

export default parseItemsFromDOM;
