import columns from '../registry/columns/columns';
import column from '../registry/columns/column';
import registerElementSettings from './register-element-settings';

const registerSettingsFromRegistry = () => {
  registerElementSettings(columns);
  registerElementSettings(column);
};

export default registerSettingsFromRegistry;
