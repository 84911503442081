import { SET, REMOVE } from 'src/js/modules/blocks/constants/attribute-operations';
import common from '../constants/common';

const {
  BACKGROUND_IMAGE_ATTRIBUTE,
  BACKGROUND_POSITION_ATTRIBUTE,
  BACKGROUND_SCALE_ATTRIBUTE,
  OLD_LOGIC_ATTRIBUTE,
  DEFAULT_POSITION_STRING_VALUE,
  DEFAULT_SCALE,
} = common;

const getReplaceBackgroundOperations = ({
  elementId,
  url,
  scale = DEFAULT_SCALE,
  position = DEFAULT_POSITION_STRING_VALUE,
}) => [
  { operation: SET, elementId, name: BACKGROUND_IMAGE_ATTRIBUTE, value: url },
  { operation: SET, elementId, name: BACKGROUND_POSITION_ATTRIBUTE, value: position },
  { operation: SET, elementId, name: BACKGROUND_SCALE_ATTRIBUTE, value: scale },
  { operation: REMOVE, elementId, name: OLD_LOGIC_ATTRIBUTE, value: url },
];

export default getReplaceBackgroundOperations;
