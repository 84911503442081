import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import ButtonGroup from 'yola-editor/src/js/components/button-group';
import Button from 'yola-editor/src/js/components/button';
import Render from 'yola-editor/src/js/components/render';

function ActionDialog(props) {
  const {
    captions: { title, description, cancel, submit },
    onCancel,
    onSubmit,
  } = props;
  return (
    <ReactModal
      isOpen
      onRequestClose={onCancel}
      className="dialog dialog--centered dialog--action"
      overlayClassName="dialog-overlay"
    >
      <div className="dialog__content">
        <h5 className="dialog__title">{title}</h5>
        <p className="dialog__description">{description}</p>
      </div>
      <ButtonGroup block stick="top">
        <Render if={onSubmit}>
          <Button stretch="block" size="large" onClick={onSubmit}>
            {submit}
          </Button>
        </Render>
        <Render if={onCancel}>
          <Button stretch="block" size="large" onMouseDown={onCancel}>
            {cancel}
          </Button>
        </Render>
      </ButtonGroup>
    </ReactModal>
  );
}

ActionDialog.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    cancel: PropTypes.string,
    submit: PropTypes.string,
  }).isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

ActionDialog.defaultProps = {
  onCancel: null,
  onSubmit: null,
};

export default ActionDialog;
