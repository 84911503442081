import bowser from 'yola-bowser';
import { view } from '@yola/ws-sdk';
import convertToCssValue from 'src/js/modules/context-menu/helpers/convert-to-css-value';
import { PANE_TRIGGER_SIZE } from '../constants/sizes';
import getNavbarHeight from '../../navbar/accessors/get-height';

const VERTICAL_GAP = 4;
const HORIZONTAL_GAP = 3;

const calculateMenuPosition = ({ menuElement, controlPanePosition, direction }) => {
  const scrollPosition = view.accessors.getScrollPosition();
  const liveDoc = view.accessors.getLiveDocument();
  const liveDocWidth = liveDoc.documentElement.clientWidth;
  // Keep the breakpoint in sync with the context menu styles
  // which is located in src/scss/context-menu.scss
  const isSmallScreen = liveDocWidth < 768;
  const navbarHeight = getNavbarHeight();
  const { width, height } = menuElement.getBoundingClientRect();

  const { clientWidth, clientHeight } = liveDoc.documentElement;
  const syncyFrameHeight = liveDoc.defaultView.frameElement.parentNode.offsetHeight;

  const viewHeight =
    bowser.ios && !bowser.isIpadOS ? syncyFrameHeight + navbarHeight : clientHeight + navbarHeight;

  const position = {
    top: controlPanePosition.y - scrollPosition + navbarHeight,
    left: controlPanePosition.x + HORIZONTAL_GAP,
    bottom: null,
    right: null,
  };

  if (isSmallScreen) {
    position.top = navbarHeight;
    position.right = 0;
    position.bottom = 0;
    position.left = 0;
  } else {
    const verticalPosition = position.top + VERTICAL_GAP + PANE_TRIGGER_SIZE;
    const altVerticalPosition = position.top + VERTICAL_GAP;

    switch (direction) {
      case 'up':
        if (altVerticalPosition + height > viewHeight) {
          position.top -= height + PANE_TRIGGER_SIZE + VERTICAL_GAP;
        } else {
          position.top += VERTICAL_GAP;
        }
        break;
      case 'right':
        position.top = verticalPosition;
        break;
      case 'left':
        position.top = verticalPosition;
        break;
      case 'down':
      default:
        if (verticalPosition + height > viewHeight) {
          position.top -= height + VERTICAL_GAP;
        } else {
          position.top = verticalPosition;
        }
    }
    if (position.left + width > clientWidth) {
      position.left -= width - PANE_TRIGGER_SIZE + HORIZONTAL_GAP;
    }
  }

  return Object.keys(position).reduce(
    (accum, key) => ({ ...accum, [key]: convertToCssValue(position[key]) }),
    {}
  );
};

export default calculateMenuPosition;
