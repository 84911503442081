import React, { useState } from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import ButtonGroup from 'yola-editor/src/js/components/button-group';
import Button from 'yola-editor/src/js/components/button';
import Input from 'yola-editor/src/js/components/input';
import noop from '../../../utils/noop';

function ConfirmActionDialog(props) {
  const {
    captions: { title, description, label, submit, cancel },
    onCancel,
    onSubmit,
    confirmPhrase = '',
  } = props;

  const [inputValue, setInputValue] = useState('');

  const isSubmitDisabled = (value) => value.toUpperCase().trim() !== confirmPhrase.toUpperCase();
  const handleChange = (e) => setInputValue(e.target.value);
  return (
    <ReactModal
      isOpen
      onRequestClose={onCancel}
      className="dialog dialog--centered dialog--confirm-action"
      overlayClassName="dialog-overlay"
    >
      <div className="dialog__content">
        <h5 className="dialog__title">{title}</h5>
        <h6 className="dialog__label">{label}</h6>
        <Input onChange={handleChange} />
        <p className="dialog__description">{description}</p>
      </div>
      <ButtonGroup block stick="top">
        <Button
          stretch="block"
          size="large"
          onClick={onSubmit}
          disabled={isSubmitDisabled(inputValue)}
        >
          {submit}
        </Button>
        <Button stretch="block" size="large" onMouseDown={onCancel}>
          {cancel}
        </Button>
      </ButtonGroup>
    </ReactModal>
  );
}

ConfirmActionDialog.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    submit: PropTypes.string.isRequired,
    cancel: PropTypes.string.isRequired,
  }).isRequired,
  confirmPhrase: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

ConfirmActionDialog.defaultProps = {
  onCancel: noop,
  onSubmit: noop,
};

export default ConfirmActionDialog;
