import get from 'lodash.get';
import { config } from '@yola/ws-sdk';

const getZendeskWidgetUrl = (rootState) => {
  const cfg = config.selectors.getConfig(rootState);

  return get(cfg, 'common.zendesk.widget_url', '');
};

export default getZendeskWidgetUrl;
