import getUserPreferences from 'yola-editor/src/js/modules/user/selectors/user-preferences';
import getWhiteLabelStatus from 'yola-editor/src/js/modules/user/selectors/white-label-status';
import getPartnerProperties from 'yola-editor/src/js/modules/user/selectors/partner-properties';
import getPublishedSiteData from './published-site-data';

const getSiteUrl = (rootState) => {
  const publishedSiteData = getPublishedSiteData(rootState);

  if (!publishedSiteData) return null;

  const properties = getPartnerProperties(rootState);
  const isWL = getWhiteLabelStatus(rootState);

  if (isWL && !properties.multisiteMode) {
    return getUserPreferences(rootState).siteUrl;
  }

  return `https://${publishedSiteData.primaryVhost}`;
};

export default getSiteUrl;
