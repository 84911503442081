import { dialogs, extensions, i18next } from '@yola/ws-sdk';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import constants from '../../constants/common';
import config from '../../constants/config';
import matchesOrContains from '../../helpers/matches-or-contains';
import getImageAltModalProps from '../../helpers/get-image-alt-modal-props';

const changeImageTitle = (additionalProperties = {}) => ({
  id: constants.CHANGE_IMAGE_TITLE,
  get caption() {
    return i18next.t('Change alt title');
  },
  onClick(element) {
    const settings = extensions.accessors.getExtensionSettings(config.slug);
    const imageElement = matchesOrContains(element, settings.imageActionSelector);

    const captions = {
      title: i18next.t('Change alt title'),
      label: i18next.t('Alt title'),
      description: i18next.t(
        'Search engines cannot see images but they can read their descriptions. Add image description to boost your search engine visibility.'
      ),
      submit: i18next.t('Save'),
      cancel: i18next.t('Cancel'),
    };

    dialogs.operations.show(
      dialogTypes.IMAGE_ALT_DIALOG,
      getImageAltModalProps(imageElement, captions)
    );
  },
  ...additionalProperties,
});

export default changeImageTitle;
