import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { utils } from '@yola/ws-sdk';

class ControlPaneTrigger extends React.Component {
  constructor(props) {
    super(props);

    const { active } = props;

    this.state = {
      active,
      isActive: active,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { active } = nextProps;

    if (nextProps.active !== prevState.active) {
      return {
        active,
        isActive: active,
      };
    }

    return null;
  }

  componentWillUnmount() {
    const { onMouseLeave } = this.props;
    onMouseLeave();
  }

  makeActive() {
    this.setState({
      isActive: true,
    });
  }

  makeInactive() {
    this.setState({
      isActive: false,
    });
  }

  render() {
    const { isActive } = this.state;
    const { component, active, ...otherProps } = this.props;
    const className = classNames('ws-control-pane-trigger', {
      'ws-control-pane-trigger--active': isActive,
    });

    const componentWithProps = React.cloneElement(component, {
      active: isActive,
    });

    return (
      <div className={className} {...otherProps}>
        {componentWithProps}
      </div>
    );
  }
}

ControlPaneTrigger.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func,
  component: PropTypes.node.isRequired,
  onMouseLeave: PropTypes.func,
};

ControlPaneTrigger.defaultProps = {
  active: false,
  onClick: utils.noop,
  onMouseLeave: utils.noop,
};

export default ControlPaneTrigger;
