import { constants } from '@yola/subscription-manager-js';

const {
  SubscriptionTerm: { ANNUAL, MONTHLY },
} = constants;

const MAP_TERM_NAME_TO_SUBSCRIPTION_TERM = {
  monthly: MONTHLY,
  annual: ANNUAL,
};

export default {
  ANNUAL,
  MONTHLY,
  MAP_TERM_NAME_TO_SUBSCRIPTION_TERM,
};
