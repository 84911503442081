import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { SubmitDialog } from '@yola/ws-ui';
import { i18next } from '@yola/ws-sdk';

const getCaptions = (oldBlock, newBlock, captionsOverrides) => {
  const description = i18next.t(
    `Your {oldBlockId} needs to be switched out with {newBlockId}. Remember, you can maintain just one type of block from this category on each page`,
    {
      oldBlockId: `<span class='ws-ui-text ws-ui-text--high-emphasis ws-text--type-heading-6'>'${oldBlock}'</span>`,
      newBlockId: `<span class='ws-ui-text ws-ui-text--high-emphasis ws-text--type-heading-6'>'${newBlock}'</span>`,
    }
  );

  return {
    title: i18next.t('Block replacement needed'),
    discard: '',
    submit: i18next.t('Replace'),
    cancel: i18next.t('Cancel'),
    description: (
      // eslint-disable-next-line yola/react/no-danger
      <span dangerouslySetInnerHTML={{ __html: description }} />
    ),
    ...captionsOverrides,
  };
};

function BlockReplacementDialog(props) {
  const {
    captions: captionsOverrides,
    newBlock,
    newBlockVariationId,
    oldBlock,
    oldBlockVariationId,
    onDialogMainAction,
    onDialogCancel,
    onSubmit,
    onCancel,
  } = props;
  const traits = { oldBlock, oldBlockVariationId, newBlock, newBlockVariationId };
  const captions = useMemo(
    () => getCaptions(oldBlock, newBlock, captionsOverrides),
    [oldBlock, newBlock, captionsOverrides]
  );

  return (
    <SubmitDialog
      onDialogMainAction={() => onDialogMainAction(traits)}
      onDialogCancel={() => onDialogCancel(traits)}
      captions={captions}
      onSubmit={onSubmit}
      onCancel={onCancel}
      height="auto"
    />
  );
}

BlockReplacementDialog.defaultProps = {
  captions: {},
  onSubmit: Function.prototype,
  onCancel: Function.prototype,
};

BlockReplacementDialog.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string,
    discard: PropTypes.string,
    description: PropTypes.string,
    submit: PropTypes.string,
    cancel: PropTypes.string,
  }),
  newBlock: PropTypes.string.isRequired,
  newBlockVariationId: PropTypes.string.isRequired,
  oldBlock: PropTypes.string.isRequired,
  oldBlockVariationId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onDialogCancel: PropTypes.func.isRequired,
  onDialogMainAction: PropTypes.func.isRequired,
};

export default BlockReplacementDialog;
