import { dialogs, integration, site } from '@yola/ws-sdk';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import helpers from '../helpers';
import constants from '../constants';

const welcomeTourInit = (store) => (next) => (action) => {
  if (action.type === constants.actionTypes.INIT_WELCOME_TOUR) {
    const state = store.getState();
    const initialSetupCompleted = site.selectors.getInitialSetupCompleted(state);
    const { userPreferences } = integration.selectors.getSettings(state);
    const { welcomeTourCompleted } = userPreferences;

    if (initialSetupCompleted && !welcomeTourCompleted) {
      helpers.preloadVideoSources(helpers.getItems(constants.tours.WELCOME_TOUR));
      store.dispatch(dialogs.actions.show(dialogTypes.WELCOME_TOUR));
    }
  }

  next(action);
};

export default welcomeTourInit;
