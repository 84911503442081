import React from 'react';
import PropTypes from 'prop-types';
import bowser from 'yola-bowser';
import { view, dialogs, i18next } from '@yola/ws-sdk';
import {
  Button,
  ButtonGroup,
  Modal,
  Panel,
  PanelGroup,
  Text,
  AceEditor,
  FieldStatus,
} from '@yola/ws-ui';

import { ViewportConsumer } from 'yola-editor/src/js/utils/viewport-provider';
import validateWsAssets from 'src/js/modules/common/helpers/validate-ws-assets';
import constants from '../constants/common';
import * as keyValues from '../constants/key-values';
import getElementContent from '../helpers/get-element-content';
import updateContent from '../helpers/update-content';
import preventEditorZooming from '../helpers/ios-workarounds/prevent-editor-zooming';

class EmbedHtmlSettingsDialog extends React.Component {
  static closeDialog() {
    dialogs.operations.hide();
  }

  constructor(props) {
    super(props);
    const element = view.accessors.getLiveElement(props.elementId);
    const position = dialogs.helpers.getPositionByElement(
      element,
      constants.WIDGET_WIDTH,
      constants.WIDGET_HEIGHT
    );
    const content = getElementContent(element);
    this.state = {
      html: content,
      modalOffsetX: position.offsetX,
      modalOffsetY: position.offsetY,
      error: null,
    };

    this.element = element;
    this.modalOffset = position;
    this.editorRef = React.createRef();
    this.editor = null;

    this.onModalResize = this.onModalResize.bind(this);
    this.onHTMLChange = this.onHTMLChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.hideError = this.hideError.bind(this);
    this.onValidate = this.onValidate.bind(this);
  }

  componentDidMount() {
    this.editor = this.editorRef.current.editor;
    preventEditorZooming(this.editor);
  }

  onModalResize() {
    this.editor.resize();
  }

  onHTMLChange(html) {
    this.setState({ html });

    if (!html) {
      this.editor.session.clearAnnotations();
    }
  }

  onKeyDown(event) {
    const { editor } = this.editorRef.current;
    const isEditorFocused = editor.isFocused();

    switch (event.key) {
      case keyValues.ESC: {
        const { onDialogCancel } = this.props;

        onDialogCancel();

        if (isEditorFocused) {
          editor.blur();
        } else {
          EmbedHtmlSettingsDialog.closeDialog();
        }

        break;
      }

      case keyValues.ENTER: {
        if (!isEditorFocused) {
          this.handleSubmit();
        }

        break;
      }

      default:
        break;
    }
  }

  onDragEnd(event, { x, y }) {
    this.setState({
      modalOffsetX: x,
      modalOffsetY: y,
    });
  }

  onValidate() {
    if (this.extraValidation) {
      return;
    }

    const { session } = this.editor;
    const annotations = validateWsAssets({
      value: this.editor.getValue(),
      session: this.editor.session,
    });
    const currentAnnotations = session.getAnnotations();

    this.extraValidation = true;
    session.setAnnotations(currentAnnotations.concat(annotations));
    this.extraValidation = false;
  }

  handleSubmit() {
    const errorAnnotation = this.editor
      .getSession()
      .getAnnotations()
      .find((annotation) => annotation.type === 'error');

    if (errorAnnotation) {
      this.showError(errorAnnotation);
      return;
    }

    const { html } = this.state;
    const { onDialogMainAction } = this.props;

    onDialogMainAction();

    updateContent(this.element, html);

    EmbedHtmlSettingsDialog.closeDialog();
  }

  handleCancel() {
    const { onDialogCancel } = this.props;

    onDialogCancel();
    EmbedHtmlSettingsDialog.closeDialog();
  }

  showError(annotation) {
    this.setState({
      error: {
        line: annotation.row + 1,
        column: annotation.column + 1,
        message: annotation.text,
      },
    });
  }

  hideError() {
    this.setState({ error: null });
  }

  render() {
    const { html, modalOffsetX, modalOffsetY, error } = this.state;
    const isMobile = bowser.mobile;

    return (
      <Modal
        {...this.modalOffset}
        className="ws-html-widget-modal"
        width={constants.WIDGET_WIDTH}
        height={constants.WIDGET_HEIGHT}
        minWidth={constants.WIDGET_MIN_WIDTH}
        minHeight={constants.WIDGET_MIN_HEIGHT}
        resizable={!isMobile}
        draggable={!isMobile}
        fullscreen={isMobile}
        dragHandleSelector=".ws-drag-trigger"
        handleSubmit={this.handleSubmit}
        handleCancel={this.handleCancel}
        onResize={this.onModalResize}
        onKeyDown={this.onKeyDown}
        onDragEnd={this.onDragEnd}
        onFocus={this.hideError}
      >
        <PanelGroup height="100%">
          <Panel
            className="ws-drag-trigger ws-modal__content"
            height={48}
            align="middle"
            theme="gray-100"
          >
            <Text type="heading-6">{i18next.t('Custom HTML')}</Text>
          </Panel>

          <div className="ws-panel ws-panel--theme-white ws-modal__content ws-html-widget-modal__editor">
            <AceEditor
              ref={this.editorRef}
              className="ws-html-widget-editor"
              mode="html"
              theme="chrome"
              options={{
                tabSize: 2,
              }}
              value={html}
              onChange={this.onHTMLChange}
              onFocus={this.hideError}
              onValidate={this.onValidate}
            />

            {error && (
              <div style={{ position: 'relative' }}>
                <FieldStatus
                  style={{
                    marginTop: 0,
                    left: 0,
                  }}
                  text={`${i18next.t('Line')} ${error.line}, ${i18next.t('Column')} ${
                    error.column
                  }: ${error.message}`}
                />
              </div>
            )}
          </div>

          <Panel height="48">
            <ButtonGroup stick="top" block>
              <Button stretch="block" size="large" onClick={this.handleSubmit}>
                <Text type="heading-6">{i18next.t('Submit')}</Text>
              </Button>
              <Button stretch="block" size="large" onMouseDown={this.handleCancel}>
                <Text type="heading-6">{i18next.t('Cancel')}</Text>
              </Button>
            </ButtonGroup>
          </Panel>
        </PanelGroup>

        <ViewportConsumer>
          {/* eslint-disable yola/react/no-danger */}
          {({ width }) => (
            <style
              dangerouslySetInnerHTML={{
                __html: `
                .ws-html-widget-editor .ace_tooltip {
                  max-width: ${width}px;
                  transform: translate(${-modalOffsetX}px, ${-modalOffsetY}px);
                }
              `,
              }}
            />
          )}
          {/* eslint-enable yola/react/no-danger */}
        </ViewportConsumer>
      </Modal>
    );
  }
}

EmbedHtmlSettingsDialog.propTypes = {
  elementId: PropTypes.string.isRequired,
  // Passed from ws-editor's `dialogs-container`:
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

export default EmbedHtmlSettingsDialog;
