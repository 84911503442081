import actionTypes from '../constants/action-types';

const userFeatures = (state = null, action) => {
  switch (action.type) {
    case actionTypes.SET_USER_FEATURES:
      return action.payload.userFeatures;
    default:
      return state;
  }
};

export default userFeatures;
