import bowser from 'yola-bowser';
import classNames from 'classnames';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { utils } from '@yola/ws-sdk';
import {
  Button,
  ButtonGroup,
  FloatingAction,
  Modal,
  Panel,
  PanelGroup,
  Text,
  TreeView,
  Align,
} from '@yola/ws-ui';

const LinkListSettingsDialog = ({
  modalRef,
  items,
  onAddNewItem,
  onClose,
  captions,
  interaction,
  maxNestingLevel,
  offsetX,
  offsetY,
  dialogWidth,
  dialogHeight,
  handleKeyDown,
  applyChanges,
  modalContentClassName,
  onOrderChange,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const isEmpty = items.length === 0;
  const contentClassName = classNames('ws-modal__content', 'ws-panel--scrollable', {
    modalContentClassName,
  });
  const contentPanelStyle = isEmpty
    ? {
        borderBottom: 'none',
        alignItems: 'center',
      }
    : {
        WebkitOverflowScrolling: isDragging ? 'auto' : 'touch',
        paddingTop: '16px',
        paddingBottom: '80px',
        borderBottom: 'none',
      };

  const onStartItemDrag = () => {
    setIsDragging(true);
  };

  const onEndItemDrag = () => {
    setIsDragging(false);
  };

  return (
    <Modal
      modalRef={modalRef}
      resizable
      width={dialogWidth}
      height={dialogHeight}
      minWidth={320}
      minHeight={200}
      offsetX={offsetX}
      offsetY={offsetY}
      handleCancel={onClose}
      handleSubmit={applyChanges}
      dragHandleSelector=".ws-drag-trigger"
      onKeyDown={handleKeyDown}
      fullscreen={bowser.mobile}
      className="ws-link-list-settings"
    >
      <PanelGroup height="100%">
        <Panel
          theme="gray-100"
          corners="squared"
          align="middle"
          style={{ flexShrink: '0' }}
          height={48}
          className="ws-drag-trigger ws-modal__content"
        >
          <Text type="heading-6">{captions.dialogTitle}</Text>
        </Panel>
        <Panel theme="white" className={contentClassName} style={contentPanelStyle} height="100%">
          {isEmpty ? (
            <Align horizontal="center" vertical="center" height="100%" width="100%">
              <div>
                <Text theme="light-grey" type="heading-1">
                  {captions.emptyTitle}
                </Text>
                <Text theme="light-grey" type="regular">
                  {captions.emptyDescription}
                </Text>
              </div>
            </Align>
          ) : (
            <TreeView
              items={items}
              onOrderChange={onOrderChange}
              onStartItemDrag={onStartItemDrag}
              onEndItemDrag={onEndItemDrag}
              interaction={interaction}
              maxNestingLevel={maxNestingLevel}
              translate="no"
            />
          )}
        </Panel>
        <Panel theme="white" style={{ flexShrink: '0' }} height={48}>
          <ButtonGroup stick="top" block>
            <Button stretch="block" size="large" disabled={isEmpty} onClick={applyChanges}>
              {captions.submit}
            </Button>
            <Button stretch="block" size="large" onClick={onClose}>
              {captions.cancel}
            </Button>
          </ButtonGroup>
        </Panel>
      </PanelGroup>
      <FloatingAction
        icon="plus"
        inverseOffsetOrigin
        offsetX={24}
        offsetY={72}
        onClick={onAddNewItem}
      />
    </Modal>
  );
};

LinkListSettingsDialog.propTypes = {
  modalRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })])
    .isRequired,
  offsetX: PropTypes.number.isRequired,
  offsetY: PropTypes.number.isRequired,
  modalContentClassName: PropTypes.string.isRequired,
  items: TreeView.propTypes.items,
  applyChanges: PropTypes.func,
  onClose: PropTypes.func,
  onAddNewItem: PropTypes.func.isRequired,
  dialogHeight: PropTypes.number.isRequired,
  dialogWidth: PropTypes.number.isRequired,
  interaction: PropTypes.string.isRequired,
  maxNestingLevel: PropTypes.number.isRequired,
  captions: PropTypes.shape({
    dialogTitle: PropTypes.string,
    emptyTitle: PropTypes.string,
    emptyDescription: PropTypes.string,
    submit: PropTypes.string,
    cancel: PropTypes.string,
  }).isRequired,
  handleKeyDown: PropTypes.func,
  onOrderChange: PropTypes.func,
};

LinkListSettingsDialog.defaultProps = {
  items: [],
  applyChanges: utils.noop,
  onClose: utils.noop,
  handleKeyDown: utils.noop,
  onOrderChange: utils.noop,
};

export default LinkListSettingsDialog;
