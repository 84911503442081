import actionTypes from '../constants/action-types';

const domainPurchase = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_DOMAIN_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case actionTypes.GET_DOMAIN_LIST_SUCCESS: {
      const { terms, domainList } = action.payload;

      return {
        ...state,
        isLoading: false,
        terms,
        domainList,
      };
    }

    case actionTypes.GET_DOMAIN_LIST_FAILURE: {
      return {
        isLoading: false,
      };
    }

    case actionTypes.SELECT_DOMAIN: {
      return {
        ...state,
        selectedDomain: action.payload.domain,
      };
    }

    case actionTypes.CLEAR_DOMAIN_PURCHASE_DATA: {
      return {};
    }

    default: {
      return state;
    }
  }
};

export default domainPurchase;
