import featureFlagsService from 'yola-editor/src/js/modules/feature-flags/service-client';
import dialogs from '../../dialogs';
import siteSelectors from '../../site/selectors';
import userSelectors from '../../user/selectors';
import helpers from '../helpers';

const showSurvey = (store) => (next) => async (action) => {
  if (action.type !== dialogs.actionTypes.HIDE_DIALOG) {
    next(action);
    return;
  }

  const state = store.getState();
  const { type } = dialogs.selectors.getDialog(state);

  if (type !== dialogs.dialogTypes.PUBLISH_SUCCESS) {
    next(action);
    return;
  }

  const publishCount = siteSelectors.getPublishCount(state);

  if (publishCount < 1) {
    next(action);
    return;
  }

  const { siteProperties = {} } = dialogs.selectors.getDialogProps(state);
  const userPreferences = userSelectors.getUserPreferences(state);
  const { signupDate } = userSelectors.getUserData(state);
  const currentDate = new Date();
  const {
    aiSurveyStatus,
    aiSurveyUpdatedAt,
    npsSurveyUpdatedAt,
    latestNpsScore,
    pmfSurveyUpdatedAt,
    pmfSurveyCompleted,
    npsSurveyStatus,
  } = userPreferences;

  const isAiSurveyAvailable = helpers.isAiSurveyAvailable(
    aiSurveyStatus,
    currentDate,
    aiSurveyUpdatedAt,
    siteProperties
  );

  const isNPSAvailable = helpers.isNPSAvailable(
    signupDate,
    currentDate,
    npsSurveyUpdatedAt,
    latestNpsScore,
    npsSurveyStatus
  );

  const isPMFAvailable = helpers.isPMFAvailable(
    signupDate,
    currentDate,
    pmfSurveyUpdatedAt,
    pmfSurveyCompleted
  );

  const featureFlagsManager = featureFlagsService.get();
  const {
    nps_survey: isNPSFeatureAvailable,
    pmf_survey: isPMFFeatureAvailable,
    ai_survey: isAiSurveyFeatureAvailable,
  } = await featureFlagsManager.request(['nps_survey', 'pmf_survey', 'ai_survey']);

  if (isAiSurveyAvailable && isAiSurveyFeatureAvailable) {
    store.dispatch(dialogs.actions.show(dialogs.dialogTypes.AI_SURVEY));
    return;
  }

  if (isNPSAvailable && isNPSFeatureAvailable) {
    store.dispatch(dialogs.actions.show(dialogs.dialogTypes.NPS_SURVEY));
    return;
  }

  if (
    (latestNpsScore && isNPSFeatureAvailable && isPMFAvailable && isPMFFeatureAvailable) ||
    (!isNPSFeatureAvailable && isPMFAvailable && isPMFFeatureAvailable)
  ) {
    store.dispatch(dialogs.actions.show(dialogs.dialogTypes.PMF_SURVEY));
    return;
  }

  next(action);
};

export default showSurvey;
