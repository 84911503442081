import React from 'react';
import { textFormatting, extensions, i18next, dialogs } from '@yola/ws-sdk';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import constants from '../constants';
import LinkFormattingTrigger from '../components/link-formatting-trigger';
import verifiers from '../verifiers';

const { isLinkSelected } = verifiers;
const { wrappersTypes } = textFormatting.constants;
const { keyCode } = wrappersTypes.link;
const { controlTypes } = extensions;
const triggerId = 'text-link';

const linkControl = {
  id: triggerId,
  trigger: <LinkFormattingTrigger triggerId={triggerId} triggerKeyCode={keyCode} />,
  get tooltip() {
    return i18next.t('Link');
  },
  priority: 60,
  onTriggerClick: (elementId) => {
    dialogs.operations.show(dialogTypes.LINK_FORMATTING_DIALOG, { elementId });
  },
  for: [controlTypes.TEXT, controlTypes.COLLAPSED_TEXT],
  matches: (element) => {
    const settings = extensions.accessors.getExtensionSettings(constants.common.SLUG);

    const isMatchQuerySelector = element.matches(settings.linkQuerySelector);
    if (!isMatchQuerySelector) return false;

    if (settings.linkRestrictedParentsSelector) {
      if (element.closest(settings.linkRestrictedParentsSelector)) return false;
    }

    const selection = textFormatting.accessors.getAdvancedSelection();

    if (selection.isCollapsed) {
      return isLinkSelected();
    }

    return true;
  },
  highlightElement: false,
};

export default linkControl;
