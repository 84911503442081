import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { dialogs, i18next } from '@yola/ws-sdk';
import user from 'yola-editor/src/js/modules/user';
import AiAlertDialog from '../components/ai-alert-dialog';
import errorTypes from '../constants/error-types';

const getCaptions = (errorType, { termsOfUseUrl, supportUrl }) => {
  const captions = {
    [errorTypes.UNKNOWN]: {
      title: i18next.t('Oops!'),
      description: i18next.t('There’s a hiccup with this feature. Please try again later.'),
      buttonLabel: i18next.t('Dismiss'),
    },
    [errorTypes.UNKNOWN_REPLACE_PAGE]: {
      title: i18next.t('Oops!'),
      description: i18next.t(
        "Seems like our AI is taking a quick breather. We're sorting it out. Please try again in a moment"
      ),
      buttonLabel: i18next.t('Try again'),
    },
    [errorTypes.UNKNOWN_GENERATE_PAGE]: {
      title: i18next.t('Oops!'),
      description: i18next.t(
        "Seems like our AI is taking a quick breather. We're sorting it out. Please try again in a moment"
      ),
      buttonLabel: i18next.t('Try again'),
    },
    [errorTypes.FORBIDDEN_BY_MODERATION]: {
      title: i18next.t('Inappropriate content!'),
      description: i18next.t(
        'Your input violates our {link}. Please adjust your input and try again.',
        {
          link: termsOfUseUrl
            ? `<a href="${termsOfUseUrl}" target="_blank">${i18next.t('terms of use')}</a>`
            : i18next.t('terms of use'),
        }
      ),
      buttonLabel: i18next.t('Close'),
    },
    [errorTypes.LAYOUT_NOT_FOUND]: {
      title: i18next.t('Feature unavailable'),
      description: i18next.t("Sorry, this type of block isn't supported yet."),
      buttonLabel: i18next.t('Got it'),
    },
    [errorTypes.UNKNOWN_GENERATE_TEXT]: {
      title: i18next.t("Oops! Something's not right"),
      description: supportUrl
        ? i18next.t(
            'We\'ve run into an issue. Please try again later or <a target="_blank" href="{supportUrl}">contact support</a> if this keeps up',
            { supportUrl }
          )
        : i18next.t("We've run into an issue. Please try again later"),
      buttonLabel: i18next.t('Dismiss'),
    },
  };

  return captions[errorType];
};

const AiErrorContainer = (props) => {
  const { errorType, onRenew, onDialogCancel, onDialogMainAction } = props;
  const termsOfUseUrl = useSelector(user.selectors.getTermsOfUseUrl);
  const supportUrl = useSelector(user.selectors.getSupportUrl);
  const error =
    errorType && Object.values(errorTypes).includes(errorType) ? errorType : errorTypes.UNKNOWN;
  const captions = getCaptions(error, { termsOfUseUrl, supportUrl });
  const shouldRenew =
    error === errorTypes.UNKNOWN_REPLACE_PAGE || error === errorTypes.UNKNOWN_GENERATE_PAGE;

  const handleClose = () => {
    onDialogCancel();
    dialogs.operations.hide();
  };

  const handleSubmit = () => {
    onDialogMainAction();

    if (shouldRenew) {
      onRenew();
      return;
    }

    dialogs.operations.hide();
  };

  const buttonProps = {
    ...(shouldRenew && {
      iconGlyph: 'renew',
    }),
  };

  return (
    <AiAlertDialog
      captions={captions}
      imageSrc="/images/ai/error-icon.png"
      buttonProps={buttonProps}
      onClose={handleClose}
      onSubmit={handleSubmit}
    />
  );
};

AiErrorContainer.propTypes = {
  errorType: PropTypes.string.isRequired,
  onRenew: PropTypes.func,
  onDialogCancel: PropTypes.func.isRequired,
  onDialogMainAction: PropTypes.func.isRequired,
};

AiErrorContainer.defaultProps = {
  onRenew: Function.prototype,
};

export default AiErrorContainer;
