import { view, globals, anodum } from '@yola/ws-sdk';
import isEnabled from '../verifiers/is-enabled';
import getStoreId from '../selectors/store-id';
import ids from '../constants/ids';
import regexps from '../constants/regexps';

const storeIdInjector = (store) => (next) => (action) => {
  if (action.type === view.actionTypes.INSERT_BLOCK && isEnabled()) {
    if (action.payload.blockId === ids.ONLINE_STORE_BLOCK) {
      const state = store.getState();

      const onlineStoreGlobal = globals.selectors.getGlobal(state, ids.ONLINE_STORE_GLOBAL);

      if (!onlineStoreGlobal) {
        next(action);
        return;
      }

      const ecwidStoreId = getStoreId(state);

      const globalNode = anodum.parseElementFromString(onlineStoreGlobal.html);
      const storeLoaderScript = globalNode.querySelector('.ws-online-store-loader');
      const storeLoaderUrl = storeLoaderScript.getAttribute('src');

      if (!storeLoaderUrl.endsWith(`?${ecwidStoreId}`)) {
        let newLoaderUrl;

        if (regexps.STORE_ID.test(storeLoaderUrl)) {
          newLoaderUrl = storeLoaderUrl.replace(regexps.STORE_ID, `?${ecwidStoreId}`);
        } else {
          newLoaderUrl = `${storeLoaderUrl}?${ecwidStoreId}`;
        }

        storeLoaderScript.setAttribute('src', newLoaderUrl);

        store.dispatch(
          globals.actions.setGlobalHTML(ids.ONLINE_STORE_GLOBAL, globalNode.outerHTML)
        );
      }
    }
  }

  next(action);
};

export default storeIdInjector;
