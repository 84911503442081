import getClosestValueTuple from './get-closest-value-tuple';

export function getWhiteSpaceTopValue(element) {
  const breakdowns = element.getCurrentWhiteSpaceBreakdowns();
  const value = element.getWhiteSpaceTopValue();

  return getClosestValueTuple(breakdowns, value)[0];
}

export function getWhiteSpaceBottomValue(element) {
  const breakdowns = element.getCurrentWhiteSpaceBreakdowns();
  const value = element.getWhiteSpaceBottomValue();

  return getClosestValueTuple(breakdowns, value)[0];
}
