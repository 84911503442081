import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';
import WebFont from 'webfontloader';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  view,
  dialogs,
  theme,
  customization,
  i18next,
  globals,
  integration,
  saving,
  template,
} from '@yola/ws-sdk';
import { Tooltip } from '@yola/ws-ui';
import { constants as subscriptionManagerConstants } from '@yola/subscription-manager-js';
import segment from '../../analytics/segment';
import {
  SUBMIT_BUTTON_TRIGGER_ID,
  DIALOG_BACKGROUND_TRIGGER_ID,
} from '../../dialogs/constants/common.js';
import dialogTypes from '../../dialogs/constants/dialog-types';
import WebsiteDesignDialog from '../components/website-design-dialog';
import helpers from '../helpers';
import constants from '../constant';
import actions from '../actions';
import triggerIds from '../constant/trigger-ids';
import upsell from '../../upsell';

const {
  composeFontsAssetsPreviewUrl,
  prepareNodeElement,
  getFontsConfig,
  setCurrentPalette,
  setCurrentFont,
  setCurrentButtonPreset,
  applyEffects,
  createMapById,
  createWebsiteAnimationScript,
  getSelectorByGlobalId,
  sortPresets,
  generateColorPresetLabel,
} = helpers;
const {
  constants: { events },
  trackers: { trackEvent, trackDialogActiveTabChanged },
} = segment;

const {
  GLOBAL_HEADER_CODE,
  GLOBAL_FOOTER_CODE,
  WEBSITE_DESIGN_MODAL_WIDTH,
  WEBSITE_DESIGN_MODAL_HEIGHT,
  DEFAULT_TAB_ID,
} = constants;

const {
  hostingPackageFeatureNames: { PREMIUM_BUTTON_PRESETS },
} = subscriptionManagerConstants;

const isEmpty = (obj) => isEqual(obj, {});

const getCaptions = () => ({
  title: i18next.t('Website design'),
  tabButtons: {
    submit: i18next.t('Save'),
    cancel: i18next.t('Cancel'),
  },
  tabs: {
    colors: i18next.t('Colors'),
    fonts: i18next.t('Fonts'),
    buttons: i18next.t('Buttons'),
    effects: i18next.t('Effects'),
    templates: i18next.t('Template'),
  },
  colors: {
    title: i18next.t('Library'),
    customPalettesTitle: i18next.t('Custom palettes'),
    paletteLabel: i18next.t('Palette'),
    customPaletteLabel: i18next.t('Custom palette'),
    editLabel: i18next.t('Settings'),
    deleteLabel: i18next.t('Delete'),
  },
  buttons: {
    presetLabel: i18next.t('Preset'),
    presetButtonLabel: i18next.t('Button'),
  },
  effects: {
    websiteAnimation: {
      title: i18next.t('Website animations'),
      description: i18next.t('Revitalize your site by enabling website animations'),
      label: i18next.t('Enable website animations'),
    },
  },
  templateSwitching: {
    title: i18next.t('Switch template'),
    description: i18next.t(
      'Switch website template if you want to start using another one. All text content and images will be saved.'
    ),
  },
});

class WebsiteDesignContainer extends React.Component {
  constructor(props) {
    super(props);

    const { innerWidth, innerHeight } = window;

    const {
      isMpt,
      colorPalettes,
      customColorPalettes,
      fonts,
      buttonPresets,
      effects,
      currentColorPalette,
      currentFontPair,
      currentButtonPreset,
      effectsVariables,
      activeTabId,
      getSharedData,
      offsetX: offsetXProp,
      offsetY: offsetYProp,
    } = props;

    const {
      offsetX,
      offsetY,
      initialColorPaletteId: sharedInitialColorPaletteId,
      initialFontPairId: sharedInitialFontPairId,
      initialButtonPresetId: sharedInitialButtonPresetId,
      initialEffects: sharedInitialEffects,
      activeColorPaletteId: sharedActiveColorPaletteId,
      activeFontPairId: sharedActiveFontPairId,
      activeButtonPresetId: sharedActiveButtonPresetId,
      activeEffects: sharedActiveEffects,
      updatedPalettes,
    } = getSharedData();

    const initialColorPaletteId = sharedInitialColorPaletteId || currentColorPalette?.id;
    const initialFontPairId = sharedInitialFontPairId || currentFontPair?.id;
    const initialButtonPresetId = sharedInitialButtonPresetId || currentButtonPreset?.id;
    const initialEffects = sharedInitialEffects || effects;

    const activeColorPaletteId = sharedActiveColorPaletteId || initialColorPaletteId;
    const activeFontPairId = sharedActiveFontPairId || initialFontPairId;
    const activeButtonPresetId = sharedActiveButtonPresetId || currentButtonPreset?.id;
    const activeEffects =
      sharedActiveEffects && !isEmpty(sharedActiveEffects) ? sharedActiveEffects : initialEffects;

    const sortedColorPalettes = sortPresets(colorPalettes, activeColorPaletteId);
    let stateCustomPalettes = [];

    if (updatedPalettes) {
      stateCustomPalettes = [...sortPresets(updatedPalettes, activeColorPaletteId)];
    } else if (customColorPalettes) {
      stateCustomPalettes = [...sortPresets(customColorPalettes, activeColorPaletteId)];
    }

    const isButtonsTabEnabled = currentButtonPreset && isMpt;
    const isEffectsTabEnabled = effectsVariables && effectsVariables.length > 0;
    const isTemplateSwitchingTabEnabled = isMpt;

    this.state = {
      activeTabId,
      colorPalettes: sortedColorPalettes,
      customColorPalettes: stateCustomPalettes,
      fonts: sortPresets(fonts, activeFontPairId),
      buttonPresets: isButtonsTabEnabled ? sortPresets(buttonPresets, activeButtonPresetId) : [],
      initialColorPaletteId,
      initialFontPairId,
      initialButtonPresetId,
      initialEffects,
      activeColorPaletteId,
      activeFontPairId,
      activeButtonPresetId,
      activeEffects,
      offsetX:
        offsetXProp ||
        offsetX ||
        Math.max(innerWidth - WEBSITE_DESIGN_MODAL_WIDTH - WEBSITE_DESIGN_MODAL_WIDTH / 2, 0),
      offsetY:
        offsetYProp || offsetY || Math.max(innerHeight / 2 - WEBSITE_DESIGN_MODAL_HEIGHT / 2, 0),
      isButtonsTabEnabled,
      isEffectsTabEnabled,
      isTemplateSwitchingTabEnabled,
    };

    this.preloadedFonts = {};
    this.palettesMap = createMapById(
      stateCustomPalettes ? [...sortedColorPalettes, ...stateCustomPalettes] : sortedColorPalettes
    );
    this.fontsMap = createMapById(fonts);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeColorPalette = this.onChangeColorPalette.bind(this);
    this.onChangeFonts = this.onChangeFonts.bind(this);
    this.onChangeEffects = this.onChangeEffects.bind(this);
    this.preLoadFonts = this.preLoadFonts.bind(this);
    this.applyFontPalette = this.applyFontPalette.bind(this);
    this.applyColorPalette = this.applyColorPalette.bind(this);
    this.applyButtonPreset = this.applyButtonPreset.bind(this);
    this.applyEffects = this.applyEffects.bind(this);
    this.handleAddPaletteClick = this.handleAddPaletteClick.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.handleEditPaletteClick = this.handleEditPaletteClick.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.handleDeletePaletteClick = this.handleDeletePaletteClick.bind(this);
    this.updateCustomPalettes = this.updateCustomPalettes.bind(this);
    this.handleSwitchTemplate = this.handleSwitchTemplate.bind(this);
    this.handleButtonPresetChange = this.handleButtonPresetChange.bind(this);
    this.setActiveTabId = this.setActiveTabId.bind(this);
    this.updateSharedState = this.updateSharedState.bind(this);
  }

  onCancel() {
    const { changeColorPalette, onDialogCancel, getSharedData, sourceId } = this.props;
    const {
      initialColorPaletteId,
      initialFontPairId,
      initialButtonPresetId,
      initialEffects,
      activeColorPaletteId,
      activeFontPairId,
      activeButtonPresetId,
      activeEffects,
    } = this.state;

    const sharedData = getSharedData();
    const websiteDesignProps = sharedData[dialogTypes.WEBSITE_DESIGN];
    const dialogType = dialogTypes.WEBSITE_DESIGN;

    if (websiteDesignProps && websiteDesignProps.onDialogCancel) {
      websiteDesignProps.onDialogCancel();
    } else {
      onDialogCancel({ dialogType, sourceId });
    }

    if (initialColorPaletteId !== activeColorPaletteId) {
      this.applyColorPalette(initialColorPaletteId);
      changeColorPalette(false);
    }

    if (initialFontPairId !== activeFontPairId) {
      this.applyFontPalette(initialFontPairId);
    }

    if (initialButtonPresetId !== activeButtonPresetId) {
      this.applyButtonPreset(initialButtonPresetId);
    }

    if (initialEffects.websiteAnimation !== activeEffects.websiteAnimation) {
      this.applyEffects(initialEffects);
    }
  }

  onSubmit(event) {
    const {
      isMpt,
      buttonPresets,
      customPaletteTemplate,
      customFontsTemplate,
      buttonPresetTemplate,
      fontsVariable,
      colorPaletteVariable,
      buttonPresetVariable,
      changeColorPalette,
      changeFontPairs,
      onDialogMainAction,
      getSharedData,
      sourceId,
    } = this.props;

    const {
      customColorPalettes,
      initialColorPaletteId,
      initialFontPairId,
      initialButtonPresetId,
      initialEffects,
      activeColorPaletteId,
      activeFontPairId,
      activeButtonPresetId,
      activeEffects,
      isButtonsTabEnabled,
      isEffectsTabEnabled,
    } = this.state;

    const { originPalette } = getSharedData();

    if (isMpt && customColorPalettes) {
      this.updateCustomPalettes();
    }

    const isPaletteEdited = originPalette
      ? !isEqual(originPalette, this.palettesMap[activeColorPaletteId])
      : initialColorPaletteId !== activeColorPaletteId;
    const isFontChanged = initialFontPairId !== activeFontPairId;
    const isButtonPresetChanged =
      isButtonsTabEnabled && initialButtonPresetId !== activeButtonPresetId;
    const isEffectsChanged =
      isEffectsTabEnabled && initialEffects.websiteAnimation !== activeEffects.websiteAnimation;

    const sharedData = getSharedData();
    const websiteDesignProps = sharedData[dialogTypes.WEBSITE_DESIGN];

    if (websiteDesignProps && websiteDesignProps.onDialogCancel) {
      websiteDesignProps.onDialogCancel();
    } else {
      onDialogMainAction({
        dialogType: dialogTypes.WEBSITE_DESIGN,
        sourceId,
        triggerId: !event ? DIALOG_BACKGROUND_TRIGGER_ID : SUBMIT_BUTTON_TRIGGER_ID,
        colorPaletteAdjusted: isPaletteEdited,
        fontsAdjusted: isFontChanged,
        buttonPresetAdjusted: isButtonPresetChanged,
        effectsAdjusted: activeEffects.websiteAnimation
          ? 'website-animation-enabled'
          : 'website-animation-disabled',
        ...(isPaletteEdited && { colorPreset: activeColorPaletteId }),
        ...(isFontChanged && { fontPreset: activeFontPairId }),
        ...(isButtonPresetChanged && { buttonPreset: activeButtonPresetId }),
      });
    }

    if (isPaletteEdited || isFontChanged || isButtonPresetChanged || isEffectsChanged) {
      globals.operations.changeGlobalContent(
        GLOBAL_HEADER_CODE,
        (global) => {
          const transformations = [];

          const palette = this.palettesMap[activeColorPaletteId];
          const colorSelector = getSelectorByGlobalId(colorPaletteVariable, GLOBAL_HEADER_CODE);
          const setCurrentPaletteCurry = (globalItem) =>
            setCurrentPalette(globalItem, palette, customPaletteTemplate, colorSelector);
          transformations.push(setCurrentPaletteCurry);

          const font = this.fontsMap[activeFontPairId];
          const fontSelector = getSelectorByGlobalId(fontsVariable, GLOBAL_HEADER_CODE);
          const setCurrentFontCurry = (globalItem) =>
            setCurrentFont(globalItem, font, customFontsTemplate, fontSelector);
          transformations.push(setCurrentFontCurry);

          if (isButtonPresetChanged) {
            const buttonPreset = buttonPresets.find((preset) => preset.id === activeButtonPresetId);
            const buttonPresetSelector = getSelectorByGlobalId(
              buttonPresetVariable,
              GLOBAL_HEADER_CODE
            );
            const setCurrentButtonPresetCurry = (globalItem) =>
              setCurrentButtonPreset(
                globalItem,
                buttonPreset,
                buttonPresetTemplate,
                buttonPresetSelector
              );
            transformations.push(setCurrentButtonPresetCurry);
          }

          if (isEffectsChanged) {
            const applyEffectsCurry = (globalItem) =>
              applyEffects(globalItem, activeEffects, GLOBAL_HEADER_CODE);
            transformations.push(applyEffectsCurry);
          }

          return compose(...transformations)(global);
        },
        false
      );

      globals.operations.changeGlobalContent(
        GLOBAL_FOOTER_CODE,
        (global) => {
          const applyEffectsCurry = (globalItem) =>
            applyEffects(globalItem, activeEffects, GLOBAL_FOOTER_CODE);
          return compose(applyEffectsCurry)(global);
        },
        false
      );
    }

    if (isPaletteEdited) {
      changeColorPalette(true);
    }

    if (isFontChanged) {
      changeFontPairs();
    }
  }

  onChangeColorPalette(colorPaletteId, additionalState = {}) {
    const { switchColorPalette } = this.props;
    const { activeColorPaletteId } = this.state;

    this.applyColorPalette(colorPaletteId);
    this.setState({
      activeColorPaletteId: colorPaletteId,
      ...additionalState,
    });

    if (activeColorPaletteId !== colorPaletteId) {
      switchColorPalette(this.palettesMap[colorPaletteId]);
    }

    trackEvent(events.DESIGN_SETTINGS_COLOR_PRESET_CHANGED, {
      colorPreset: colorPaletteId,
    });
  }

  onChangeFonts(fontPairId) {
    this.setState({
      activeFontPairId: fontPairId,
    });

    this.applyFontPalette(fontPairId);

    trackEvent(events.DESIGN_SETTINGS_FONT_PRESET_CLICKED, {
      fontPreset: fontPairId,
    });
  }

  onChangeEffects(value) {
    const activeEffects = {
      websiteAnimation: value,
    };

    this.setState({ activeEffects });
    this.applyEffects(activeEffects);

    trackEvent(events.DESIGN_SETTINGS_EFFECTS_SWITCHER_CLICKED);
  }

  setActiveTabId(id) {
    this.setState({
      activeTabId: id,
    });

    trackDialogActiveTabChanged({
      dialogId: dialogTypes.WEBSITE_DESIGN,
      tabId: id,
    });
  }

  updateSharedState(customData) {
    const { resolveSharedData } = this.props;
    const {
      activeTabId,
      offsetX,
      offsetY,
      initialColorPaletteId,
      initialFontPairId,
      initialButtonPresetId,
      initialEffects,
      activeFontPairId,
      activeButtonPresetId,
      activeEffects,
      customColorPalettes,
    } = this.state;

    resolveSharedData({
      activeTabId,
      offsetX,
      offsetY,
      initialColorPaletteId,
      initialFontPairId,
      initialButtonPresetId,
      initialEffects,
      activeFontPairId,
      activeButtonPresetId,
      activeEffects,
      customColorPalettes,
      ...customData,
    });
  }

  updateCustomPalettes() {
    const {
      customColorPalettes: initPalettes,
      deleteCustomPalette,
      updateCustomPalette,
      createCustomPalette,
    } = this.props;

    const { customColorPalettes: currentPalettes } = this.state;

    customization.helpers
      .getCustomPalettesToDelete(initPalettes, currentPalettes)
      .forEach((id) => deleteCustomPalette(id));
    customization.helpers
      .getCustomPalettesToCreate(initPalettes, currentPalettes)
      .forEach((id) => createCustomPalette(id));
    customization.helpers
      .getCustomPalettesToUpdate(initPalettes, currentPalettes)
      .forEach((id) => updateCustomPalette(id));
  }

  preLoadFonts(fontsId) {
    const { fonts } = this.props;
    const config = getFontsConfig(fontsId, fonts);

    if (!this.preloadedFonts[fontsId]) {
      WebFont.load(config);
    }
    this.preloadedFonts[fontsId] = true;
  }

  applyFontPalette(fontsId) {
    const { fontsVariable, customFontsTemplate } = this.props;
    const { element, paletteElement: fontsElement } = prepareNodeElement({
      elementVariable: fontsVariable,
      customTemplate: customFontsTemplate,
      currentPalette: this.fontsMap[fontsId],
      elementDataAttribute: theme.constants.FONTS_DATA_ATTRIBUTE,
    });
    if (element) {
      element.parentNode.replaceChild(fontsElement, element);
    }
  }

  applyColorPalette(paletteId) {
    const { colorPaletteVariable, customPaletteTemplate } = this.props;
    const { element, paletteElement: colorPaletteElement } = prepareNodeElement({
      elementVariable: colorPaletteVariable,
      customTemplate: customPaletteTemplate,
      currentPalette: this.palettesMap[paletteId],
      elementDataAttribute: theme.constants.COLOR_PALETTE_DATA_ATTRIBUTE,
    });

    if (element) {
      element.parentNode.replaceChild(colorPaletteElement, element);
    }
  }

  applyButtonPreset(buttonPresetId) {
    const { buttonPresets, buttonPresetVariable, buttonPresetTemplate } = this.props;

    const { element, paletteElement: buttonPresetElement } = prepareNodeElement({
      elementVariable: buttonPresetVariable,
      customTemplate: buttonPresetTemplate,
      currentPalette: buttonPresets.find((preset) => preset.id === buttonPresetId),
      elementDataAttribute: customization.constants.BUTTON_PRESET_DATA_ATTRIBUTE,
    });

    if (element) {
      element.parentNode.replaceChild(buttonPresetElement, element);
    }
  }

  applyEffects(effects) {
    const liveDocument = view.accessors.getLiveDocument();

    if (effects.websiteAnimation) {
      const { effectsVariables } = this.props;
      const headerCodeVariable = effectsVariables.find(
        (item) => item.globalId === GLOBAL_HEADER_CODE && item.querySelector
      );
      const footerCodeVariable = effectsVariables.find(
        (item) => item.globalId === GLOBAL_FOOTER_CODE && item.querySelector
      );
      const headerCode = liveDocument.querySelector(headerCodeVariable.querySelector);
      const footerCode = liveDocument.querySelector(footerCodeVariable.querySelector);
      const headerCodeScript = createWebsiteAnimationScript(GLOBAL_HEADER_CODE);
      const footerCodeScript = createWebsiteAnimationScript(GLOBAL_FOOTER_CODE);

      if (!headerCode && !footerCode) return;

      headerCode.appendChild(headerCodeScript);
      footerCode.appendChild(footerCodeScript);
    } else {
      const body = liveDocument.querySelector('body');
      const scripts = liveDocument.querySelectorAll(
        `[${customization.constants.WEBSITE_ANIMATION_DATA_ATTRIBUTE}]`
      );

      body.removeAttribute(customization.constants.INIT_WEBSITE_ANIMATION_DATA_ATTRIBUTE);
      scripts.forEach((script) => {
        script.remove();
      });
    }
  }

  handleAddPaletteClick() {
    const { onAddPaletteClick } = this.props;
    const { activeColorPaletteId } = this.state;

    const palette = this.palettesMap[activeColorPaletteId];

    this.updateSharedState({
      originPalette: palette,
      activePalette: palette,
    });

    onAddPaletteClick({
      triggerId: triggerIds.ADD_CUSTOM_PALETTE,
      colorPreset: generateColorPresetLabel(false, palette.id),
    });
  }

  handleDragEnd(_, { x, y }) {
    this.setState({ offsetX: x, offsetY: y });
  }

  handleEditPaletteClick(id, isCustom) {
    const { onAddPaletteClick } = this.props;
    const { activeColorPaletteId } = this.state;
    const palette = this.palettesMap[id];

    this.updateSharedState({
      originPalette: palette,
      activePalette: this.palettesMap[activeColorPaletteId],
      isEdit: isCustom,
    });

    onAddPaletteClick({
      triggerId: triggerIds.PALETTE_SETTINGS,
      colorPreset: generateColorPresetLabel(isCustom, palette.id),
    });
  }

  handleDeletePaletteClick(id) {
    const { colorPalettes, customColorPalettes, activeColorPaletteId } = this.state;
    const filteredColorPalettes = customColorPalettes.filter((palette) => palette.id !== id);

    Tooltip.hide();

    trackEvent(events.DESIGN_SETTINGS_COLOR_PRESET_DELETED, {
      dialogId: dialogTypes.WEBSITE_DESIGN,
    });

    if (activeColorPaletteId === id) {
      this.onChangeColorPalette(colorPalettes[0].id, {
        customColorPalettes: filteredColorPalettes,
      });
      return;
    }

    this.setState({ customColorPalettes: [...filteredColorPalettes] });
  }

  handleSwitchTemplate() {
    const { integrationSettings, onUnsavedActivePageChange, savingStatus, sourceId } = this.props;
    const { UNSAVED } = saving.constants.statuses;
    const dialogType = dialogTypes.WEBSITE_DESIGN;

    if (savingStatus === UNSAVED) {
      onUnsavedActivePageChange(integrationSettings.onSwitchTemplate);
    } else {
      integrationSettings.onSwitchTemplate({
        sendAnalytics: true,
        traits: {
          dialogType,
          sourceId,
        },
      });
    }
  }

  handleButtonPresetChange(buttonPresetId, isPremiumButtonPreset) {
    const applyChanges = () => {
      this.setState({
        activeButtonPresetId: buttonPresetId,
      });
      this.applyButtonPreset(buttonPresetId);
    };

    if (isPremiumButtonPreset) {
      upsell.operations
        .requestFeatureAvailability(PREMIUM_BUTTON_PRESETS)
        .then(() => {
          applyChanges();
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          console.log('Upgrade flow was canceled');
        });
    } else {
      applyChanges();
    }

    trackEvent(events.DESIGN_SETTINGS_BUTTON_PRESET_CLICKED, {
      buttonPreset: buttonPresetId,
    });
  }

  render() {
    const { isMpt, templatePreviewUrl, templateName } = this.props;

    const {
      activeTabId,
      colorPalettes,
      fonts,
      buttonPresets,
      activeColorPaletteId,
      activeFontPairId,
      activeButtonPresetId,
      activeEffects,
      customColorPalettes,
      offsetX,
      offsetY,
      isButtonsTabEnabled,
      isEffectsTabEnabled,
      isTemplateSwitchingTabEnabled,
    } = this.state;

    const captions = getCaptions();

    return (
      <WebsiteDesignDialog
        activeTabId={activeTabId}
        activeColorPaletteId={activeColorPaletteId}
        activeFontPairId={activeFontPairId}
        colorPalettes={colorPalettes}
        fonts={composeFontsAssetsPreviewUrl(fonts)}
        onCancel={this.onCancel}
        onSubmit={this.onSubmit}
        setActiveColorPalette={this.onChangeColorPalette}
        setActiveFonts={this.onChangeFonts}
        preLoadFonts={this.preLoadFonts}
        captions={captions}
        offsetX={offsetX}
        offsetY={offsetY}
        width={WEBSITE_DESIGN_MODAL_WIDTH}
        height={WEBSITE_DESIGN_MODAL_HEIGHT}
        onDragEnd={this.handleDragEnd}
        onChangeTab={this.setActiveTabId}
        isButtonsTabEnabled={isButtonsTabEnabled}
        isEffectsTabEnabled={isEffectsTabEnabled}
        isTemplateSwitchingTabEnabled={isTemplateSwitchingTabEnabled}
        {...(isMpt &&
          customColorPalettes && {
            customColorPalettes,
            onAddPaletteClick: this.handleAddPaletteClick,
            onEditPaletteClick: this.handleEditPaletteClick,
            onDeletePaletteClick: this.handleDeletePaletteClick,
          })}
        {...(isButtonsTabEnabled && {
          buttonPresets,
          activeButtonPresetId,
          onButtonPresetChange: this.handleButtonPresetChange,
        })}
        {...(isEffectsTabEnabled && {
          activeEffects,
          toggleWebsiteAnimation: this.onChangeEffects,
        })}
        {...(isTemplateSwitchingTabEnabled && {
          templatePreviewUrl,
          templateName,
          onSwitchTemplate: this.handleSwitchTemplate,
        })}
      />
    );
  }
}

WebsiteDesignContainer.propTypes = {
  buttonPresets: PropTypes.array.isRequired,
  buttonPresetVariable: PropTypes.array.isRequired,
  buttonPresetTemplate: PropTypes.string.isRequired,
  currentButtonPreset: PropTypes.shape(),
  colorPaletteVariable: PropTypes.array.isRequired,
  colorPalettes: PropTypes.array.isRequired,
  customColorPalettes: PropTypes.array,
  currentColorPalette: PropTypes.shape(),
  customPaletteTemplate: PropTypes.string.isRequired,
  fontsVariable: PropTypes.array.isRequired,
  effectsVariables: PropTypes.array.isRequired,
  customFontsTemplate: PropTypes.string.isRequired,
  currentFontPair: PropTypes.shape().isRequired,
  effects: PropTypes.shape().isRequired,
  integrationSettings: PropTypes.shape(),
  savingStatus: PropTypes.string.isRequired,
  fonts: PropTypes.array.isRequired,
  onAddPaletteClick: PropTypes.func.isRequired,
  deleteCustomPalette: PropTypes.func.isRequired,
  updateCustomPalette: PropTypes.func.isRequired,
  createCustomPalette: PropTypes.func.isRequired,
  onUnsavedActivePageChange: PropTypes.func.isRequired,
  switchColorPalette: PropTypes.func.isRequired,
  changeColorPalette: PropTypes.func.isRequired,
  isMpt: PropTypes.bool,
  templatePreviewUrl: PropTypes.string,
  templateName: PropTypes.string,
  activeTabId: PropTypes.string,
  changeFontPairs: PropTypes.func.isRequired,
  resolveSharedData: PropTypes.func.isRequired,
  getSharedData: PropTypes.func.isRequired,
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,
  sourceId: PropTypes.string.isRequired,
};

WebsiteDesignContainer.defaultProps = {
  customColorPalettes: null,
  isMpt: null,
  activeTabId: DEFAULT_TAB_ID,
  currentColorPalette: null,
  currentButtonPreset: null,
  templatePreviewUrl: null,
  templateName: null,
  integrationSettings: null,
  offsetX: null,
  offsetY: null,
};

const mapStateToProps = (state) => {
  const isMpt = template.verifiers.isMpt(state);

  if (isMpt) {
    return {
      isMpt,
      colorPaletteVariable: template.selectors.getVariableValueByPath(
        state,
        customization.constants.COLOR_PALETTE_VARIABLE
      ),
      fontsVariable: template.selectors.getVariableValueByPath(
        state,
        customization.constants.FONTS_VARIABLE
      ),
      buttonPresetVariable: template.selectors.getVariableValueByPath(
        state,
        customization.constants.BUTTON_PRESET_VARIABLE
      ),
      effectsVariables: template.selectors.getVariableValueByPath(
        state,
        customization.constants.EFFECTS_VARIABLE
      ),
      templatePreviewUrl: template.selectors.getPreview(state),
      templateName: template.selectors.getName(state),
      colorPalettes: customization.selectors.getColorPalettes(state),
      fonts: customization.selectors.getFontPairs(state),
      buttonPresets: customization.selectors.getButtonPresets(state),
      customColorPalettes: customization.selectors.getCustomColorPalettes(state),
      customPaletteTemplate: customization.selectors.getColorPaletteTemplate(state),
      customFontsTemplate: customization.selectors.getFontPairTemplate(state),
      buttonPresetTemplate: customization.selectors.getButtonPresetTemplate(state),
      currentColorPalette: customization.accessors.getCurrentColorPalette(),
      currentFontPair: customization.accessors.getCurrentFontPair(),
      currentButtonPreset: customization.accessors.getCurrentButtonPreset(),
      effects: customization.accessors.getEffectsConfig(),
      integrationSettings: integration.selectors.getSettings(state),
      savingStatus: saving.selectors.getStatus(state),
    };
  }

  return {
    colorPaletteVariable: template.selectors.getVariableValueByPath(
      state,
      theme.constants.COLOR_PALETTE_VARIABLE
    ),
    fontsVariable: template.selectors.getVariableValueByPath(state, theme.constants.FONTS_VARIABLE),
    currentColorPalette: theme.accessors.getCurrentPalette(state),
    colorPalettes: theme.selectors.getColorPalettes(state),
    customPaletteTemplate: theme.selectors.getCustomPaletteTemplate(state),
    customFontsTemplate: theme.selectors.getCustomFontsTemplate(state),
    currentFontPair: theme.accessors.getCurrentFonts(state),
    effects: customization.accessors.getEffectsConfig(),
    fonts: theme.selectors.getFonts(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  hideDialog() {
    dispatch(dialogs.actions.hide());
  },
  onAddPaletteClick(props) {
    dispatch(dialogs.actions.show(dialogTypes.WEBSITE_DESIGN_CUSTOM_PALETTE, props));
  },
  deleteCustomPalette(id) {
    dispatch(customization.actions.deleteCustomColorPalette(id));
  },
  updateCustomPalette(palette) {
    dispatch(customization.actions.updateCustomColorPalette(palette));
  },
  createCustomPalette(palette) {
    dispatch(customization.actions.addCustomColorPalette(palette));
  },
  onUnsavedActivePageChange(callback) {
    dispatch(
      dialogs.actions.show(dialogTypes.UNSAVED_WARNING, {
        callback,
        captions: { title: i18next.t('Your site has unsaved changes') },
      })
    );
  },
  changeFontPairs() {
    dispatch(actions.changeFontPairs());
  },
  changeColorPalette(isChanged) {
    dispatch(actions.changeColorPalette(isChanged));
  },
  switchColorPalette(colorPalette) {
    dispatch(actions.switchColorPalette(colorPalette));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WebsiteDesignContainer);
