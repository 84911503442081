import { MAX_PANE_ITEMS } from '../constants/pane-items';
import { PANE_TRIGGER_SIZE } from '../constants/sizes';
import deriveItemsWidth from './derive-items-width';
import cpDirection from '../constants/cp-direction';

const deriveMaxItemsCountByWidth = (width, items) => {
  let availableWidth = width;
  let fittingItems = 0;

  items.forEach((item) => {
    const itemWidth = item.width || PANE_TRIGGER_SIZE;
    if (availableWidth - itemWidth >= 0) {
      availableWidth -= itemWidth;
      fittingItems += 1;
    }
  });

  return fittingItems;
};

const getPaneMaxItems = ({ direction, offsetX }, documentWidth, items) => {
  const paneWidth = deriveItemsWidth(items);

  switch (direction) {
    case cpDirection.RIGHT: {
      const availableWidth = documentWidth - offsetX;
      const itemsCount = deriveMaxItemsCountByWidth(availableWidth, items);

      return availableWidth >= paneWidth ? itemsCount : itemsCount - 1;
    }

    case cpDirection.LEFT: {
      const itemsCount = deriveMaxItemsCountByWidth(offsetX, items);

      return offsetX >= paneWidth ? itemsCount : itemsCount - 1;
    }

    default:
      return MAX_PANE_ITEMS;
  }
};

export default getPaneMaxItems;
