import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import { dialogs, contentEditable, site, blocks, view, hdrm, template } from '@yola/ws-sdk';
import withSelection from 'src/js/modules/utils/with-selection';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import segment from 'src/js/modules/analytics/segment';
import dialogCallSourceIds from 'src/js/modules/website-design/constant/dialog-call-source-ids';
import websiteDesignTabIdentifiers from 'src/js/modules/website-design/constant/tab-identifiers';
import usePaneSelectHeight from 'src/js/modules/control-pane/helpers/use-pane-select-height';
import useElementTypeSelect from '../helpers/use-element-type-select';
import changeElementsType from '../helpers/change-elements-type';
import PreviewOption from './preview-option';
import constants from '../constants';

const { SimpleSelect } = designSystem;
const { text: hdrmText } = hdrm.injections.bindings;

const {
  track,
  constants: { events },
} = segment;

const MAP_ELEMENT_TYPE_TO_READABLE_NAME = {
  p: 'Normal text',
  h1: 'Heading 1',
  h2: 'Heading 2',
  h3: 'Heading 3',
  h4: 'Heading 4',
  h5: 'Heading 5',
  h6: 'Heading 6',
  blockquote: 'Quote',
};

const MAX_MENU_HEIGHT = 466;

const ElementTypeValue = ({ children: { text } }) => text;

const ElementTypeOption = ({ children: { text, icon = null, type } }) => {
  if (!type) return <div className="ws-element-type-preview--option">{text}</div>;

  return (
    <PreviewOption type={type}>
      {icon && <i className="ws-quote-mark-preview" style={{ backgroundImage: icon }} />}
      {text}
    </PreviewOption>
  );
};

function ElementTypeSelect({ elementId, getContext }) {
  const { elementType, focusElements, selection, elementTypeOptions } =
    useElementTypeSelect(elementId);
  const { scrollPosition, controlPane } = getContext();
  usePaneSelectHeight({ offsetY: controlPane.position.y, scrollPosition });

  const handleSelect = (newType) => {
    if (!newType) return;

    if (newType.value === constants.common.CUSTOMIZE_FONTS_HOTKEY) {
      dialogs.operations.show(dialogTypes.WEBSITE_DESIGN, {
        onDialogCancel: dialogs.operations.hide,
        onDialogMainAction: dialogs.operations.hide,
        activeTabId: websiteDesignTabIdentifiers.FONTS,
        sourceId: dialogCallSourceIds.CUSTOMIZE_FONTS,
      });
      return;
    }
    contentEditable.operations.addCheckpoint();
    const currentLiveElement = view.accessors.getLiveElement(elementId);
    const contentEditableElement = hdrmText.getRootTextElement(currentLiveElement);
    changeElementsType(newType, focusElements, selection, elementId);

    const allBlocks = view.accessors.getPageBlocks();
    const element = allBlocks.find((block) => block.contains(contentEditableElement));
    track(events.TEXT_ELEMENT_TYPE_SELECTED, {
      siteId: site.accessors.getSiteId(),
      templateBuildSlug: template.accessors.getBuildSlug(),
      blockId: blocks.accessors.getBlockIdByElement(element),
      blockVariationId: blocks.accessors.getVariationIdByElement(element),
      elementType: MAP_ELEMENT_TYPE_TO_READABLE_NAME[newType.value],
    });
  };

  return (
    <div className="ws-element-type-select">
      <SimpleSelect
        options={elementTypeOptions}
        components={{ Option: ElementTypeOption, SingleValue: ElementTypeValue }}
        onChange={handleSelect}
        value={elementType}
        size="medium"
        maxMenuHeight={MAX_MENU_HEIGHT}
      />
    </div>
  );
}

ElementTypeValue.propTypes = {
  children: PropTypes.node.isRequired,
};

ElementTypeOption.propTypes = {
  children: PropTypes.node.isRequired,
};

ElementTypeSelect.propTypes = {
  elementId: PropTypes.string.isRequired,
  getContext: PropTypes.func.isRequired,
};

export default withSelection(ElementTypeSelect);
