import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { DnDContext } from '@yola/ws-ui';
import { auth } from '@yola/ws-sdk';
import { selectors } from '@yola/subscription-manager-js';
import registerHotkeys from 'src/js/modules/hotkeys/initializers';
import focalPoint from 'src/js/modules/focal-point';
import spacer from 'src/js/modules/spacer';
import EditorContainer from 'src/js/modules/editor/containers/editor-container';
import ContextMenuContainer from 'src/js/modules/context-menu/containers/context-menu-container';
import CustomUIContainer from 'src/js/modules/custom-ui/containers/custom-ui-container';
import WSDialogsContainer from 'src/js/modules/dialogs/containers/dialogs-container';
import StarterScreenContainer from 'yola-editor/src/js/modules/starter-screen/containers/starter-screen-container';
import YBotAuthContainer from 'yola-editor/src/js/components/containers/y-bot-auth-container.js';
import DialogsContainer from 'yola-editor/src/js/modules/dialogs/containers/dialogs-container';
import PublishRoute from 'yola-editor/src/js/router/routes/publish-route';
import yolaAuth from 'yola-editor/src/js/modules/auth';
import user from 'yola-editor/src/js/modules/user';
import subscriptions from 'yola-editor/src/js/modules/subscriptions';
import site from 'yola-editor/src/js/modules/site';
import i18n from 'yola-editor/src/js/modules/i18n';
import config from 'src/js/modules/config';
import { redirectToUrl } from 'yola-editor/src/js/router/utils/redirect-to-url';
import NetworkErrorHandler from 'yola-editor/src/js/modules/error/containers/network-error-handler';
import { FeatureFlagsProvider } from 'yola-editor/src/js/modules/feature-flags/context';
import starterScreen from 'yola-editor/src/js/modules/starter-screen';
import { registerContextMenuNavigationItems } from 'src/js/modules/navigation/initializers';
import { registerContextMenuBlockItems } from 'src/js/modules/blocks/initializers';
import ViewDropAreaContainer from 'src/js/modules/drag-n-drop/containers/view-drop-area-container';
import PreviewModeContainer from 'src/js/modules/preview-mode/containers/preview-mode-container';
import SiteUnderMaintenanceScreen from 'yola-editor/src/js/modules/maintenance/containers/site-under-maintenance-screen';
import routesMap from 'yola-editor/src/js/router/helpers/routes-map';

const { SITE_ID_TO_RECREATE, TEMPLATE_BUILD_SLUG } = site.constants;
const LOADING_PROGRESS_INITIAL = 60;

function IndexRoute() {
  const token = useSelector(auth.selectors.getAuthToken);
  const partnerData = useSelector(user.selectors.getPartnerData);
  const userPreferences = useSelector(user.selectors.getUserPreferences);
  const userData = useSelector(user.selectors.getUserData);
  const userFeatures = useSelector(user.selectors.getUserFeatures);
  const areSubscriptionsLoaded = useSelector(subscriptions.selectors.getLoadingStatus);
  const isPublishedDataLoaded = useSelector(site.selectors.getPublishedDataLoadingStatus);
  const locale = useSelector(i18n.selectors.getLocale);
  const galleryLink = useSelector(config.selectors.getGalleryLink);
  const siteId = useSelector(yolaAuth.selectors.getSiteId);
  const siteData = useSelector(site.selectors.getSiteData);
  const templateBuildSlug = useSelector(site.selectors.getSiteTemplateSlug);
  const availablePackages = useSelector(selectors.getHostingPackages, shallowEqual);
  const currentPackage = useSelector(selectors.getCurrentPackage, shallowEqual);

  const dispatch = useDispatch();
  const localeAvailable = Boolean(locale);

  useEffect(() => {
    registerHotkeys();
    focalPoint.initializers.initFocalPoint();
    spacer.initializers.initSpacer();
  }, []);

  useEffect(() => {
    if (localeAvailable) {
      // Context menu items should be translated properly, so we need to init them when locale is set
      registerContextMenuNavigationItems();
    }
  }, [localeAvailable]);

  useEffect(() => {
    if (localeAvailable && templateBuildSlug && templateBuildSlug.startsWith('ws-intense-next')) {
      registerContextMenuBlockItems();
    }
  }, [localeAvailable, templateBuildSlug]);

  useEffect(() => {
    dispatch(starterScreen.actions.setProgress({ progress: LOADING_PROGRESS_INITIAL }));
  }, [dispatch]);

  if (
    !token ||
    !userPreferences ||
    !partnerData ||
    !userData ||
    !userFeatures ||
    !areSubscriptionsLoaded ||
    !isPublishedDataLoaded ||
    !locale ||
    !siteId ||
    !siteData ||
    !availablePackages ||
    !currentPackage
  ) {
    return null;
  }

  const isSiteReset = site.verifiers.isSiteReset(siteData);

  if (isSiteReset) {
    redirectToUrl(
      `${galleryLink}?${SITE_ID_TO_RECREATE}=${siteId}&${TEMPLATE_BUILD_SLUG}=${templateBuildSlug}`
    );
  }

  return (
    <div>
      <FeatureFlagsProvider>
        <YBotAuthContainer />
        <EditorContainer />
        <DialogsContainer />
        <WSDialogsContainer />
        <ContextMenuContainer />
        <CustomUIContainer />
        <StarterScreenContainer />
        <SiteUnderMaintenanceScreen />
        <NetworkErrorHandler />
        <ViewDropAreaContainer />
        <PreviewModeContainer />
        <Route path={routesMap.publish.pattern} component={PublishRoute} />
      </FeatureFlagsProvider>
    </div>
  );
}

export default DnDContext(IndexRoute);
