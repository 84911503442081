import publishedDataLoaded from './published-data-loaded';
import publishedSiteData from './published-site-data';
import siteName from './site-name';
import siteData from './site-data';
import publishCount from './publish-count';
import templateSwitched from './template-switched';

export default {
  publishedDataLoaded,
  publishedSiteData,
  siteData,
  siteName,
  publishCount,
  templateSwitched,
};
