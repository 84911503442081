import connectedStore from 'yola-editor/src/js/store/connected-store';
import actions from '../actions';

const show = (type, dialogProps) => {
  const store = connectedStore.get();

  store.dispatch(actions.show(type, dialogProps));
};

export default show;
