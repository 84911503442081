import { view } from '@yola/ws-sdk';
import bowser from 'yola-bowser';
import convertToCssValue from './convert-to-css-value';
import getNavbarHeight from '../../navbar/accessors/get-height';

function getMenuCssPosition(menuEl, eventX, eventY) {
  const liveDoc = view.accessors.getLiveDocument();
  const navbarHeight = getNavbarHeight();
  const eventYAdjusted = eventY + navbarHeight;

  const position = {
    top: eventYAdjusted || 0,
    left: eventX || 0,
  };

  if (menuEl) {
    const { width, height } = menuEl.getBoundingClientRect();
    const initialBounds = {
      top: eventYAdjusted,
      right: eventX + width,
      bottom: eventYAdjusted + height,
      left: eventX,
    };

    const liveDocWidth = liveDoc.documentElement.clientWidth;
    const liveDocHeight = liveDoc.documentElement.clientHeight;
    const syncyFrameHeight = liveDoc.defaultView.frameElement.parentNode.offsetHeight;

    const viewHeight =
      bowser.ios && !bowser.isIpadOS
        ? syncyFrameHeight + navbarHeight
        : liveDocHeight + navbarHeight;

    // Keep the breakpoint in sync with the context menu styles
    // which is located in src/scss/context-menu.scss
    const isSmallScreen = liveDocWidth < 768;

    if (isSmallScreen) {
      position.top = navbarHeight;
      position.right = 0;
      position.bottom = 0;
      position.left = 0;
    } else {
      // Order matters

      // Bottom cut-off
      if (initialBounds.bottom > viewHeight) {
        position.top = eventYAdjusted - height;
      }

      // Right cut-off
      if (initialBounds.right > liveDocWidth) {
        position.left = eventX - width;
      }

      // Top cut-off
      if (initialBounds.top < navbarHeight || position.top < navbarHeight) {
        position.top = navbarHeight;
      }

      // Left cut-off
      if (initialBounds.left < 0 || position.left < 0) {
        position.left = 0;
      }
    }
  }

  return {
    top: convertToCssValue(position.top),
    right: convertToCssValue(position.right),
    bottom: convertToCssValue(position.bottom),
    left: convertToCssValue(position.left),
  };
}

export default getMenuCssPosition;
