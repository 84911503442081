import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { constraints, view, site, blocks, utils, template } from '@yola/ws-sdk';
import segment from 'src/js/modules/analytics/segment';
import highlighter from 'src/js/modules/highlighter';
import highlighterOffset from 'src/js/modules/highlighter/helpers/highlighter-offset';
import useHandlePosition from 'src/js/modules/extensions/common/hooks/use-handle-position';
import calculateOffsetTop from 'src/js/modules/extensions/common/helpers/calculate-offset-top';
import useDrag from 'src/js/modules/extensions/common/hooks/use-drag';
import ResizeHandle from 'src/js/modules/extensions/common/components/resize-handle';
import getParentBlockByElement from 'src/js/modules/blocks/helpers/get-parent-block-by-element';
import resizeToolPosition from 'src/js/modules/extensions/common/constants/resize-handle-positions';
import { MIN_SPACER_HEIGHT, MAX_SPACER_HEIGHT } from '../constants/sizes';

const {
  track,
  constants: { events },
} = segment;

const SpaceResizeTool = ({
  elementId,
  appearanceStyle,
  onActionStart,
  onActionEnd,
  onMouseEnter,
  onMouseLeave,
}) => {
  const element = useMemo(() => view.accessors.getLiveElement(elementId), [elementId]);
  const handleRef = useRef(null);
  const cachedRect = useRef(null);
  const indent = highlighterOffset.get();
  const resizerElement = constraints.accessors.getResizerElement(element);

  const [handlePosition, updateHandlePosition] = useHandlePosition({
    element,
    positions: {
      [resizeToolPosition.BOTTOM_CENTER]: {
        correctionX: indent,
        correctionY: indent,
      },
    },
  });

  const showHighlighter = (options = {}) => {
    const { withElementSize = false } = options;
    highlighter.operations.show([element], {
      forceUpdate: true,
      labelGap: 'medium',
      withElementSize,
    });
  };

  const handleResize = (currentHeight, applyLiveChanges) => {
    const newHeight = Math.round(currentHeight);
    const limitedHeight = Math.max(MIN_SPACER_HEIGHT, Math.min(newHeight, MAX_SPACER_HEIGHT));

    const elementToResize = resizerElement;
    elementToResize.style.height = `${limitedHeight}px`;

    updateHandlePosition();

    if (applyLiveChanges) {
      const resizerElementId = view.accessors.getLiveElementId(resizerElement);
      view.operations.setInlineElementStyle(resizerElementId, 'height', `${limitedHeight}px`);

      const blockElement = getParentBlockByElement(element);
      const { height } = cachedRect.current;

      track(events.SPACER_HEIGHT_RESIZED, {
        siteId: site.accessors.getSiteId(),
        templateBuildSlug: template.accessors.getBuildSlug(),
        blockId: blocks.accessors.getBlockIdByElement(blockElement),
        blockVariationId: blocks.accessors.getVariationIdByElement(blockElement),
        oldHeight: height,
        newHeight: limitedHeight,
      });
    }
  };

  const handleDrag = (distance, applyLiveChanges = false) => {
    const { height } = cachedRect.current;
    const newHeight = height + distance[1];

    handleResize(newHeight, applyLiveChanges);
  };

  useDrag([handleRef], {
    onStart() {
      showHighlighter({ withElementSize: true });
      cachedRect.current = element.getBoundingClientRect();
      onActionStart();
    },

    onMove(distance) {
      handleDrag(distance);
      showHighlighter({ withElementSize: true });
    },

    onEnd(distance) {
      handleDrag(distance, true);
      onActionEnd();
      showHighlighter({ withElementSize: false });
    },
  });

  useEffect(() => highlighter.operations.hide, []);

  if (!handlePosition[resizeToolPosition.BOTTOM_CENTER]) return null;

  const [left, top] = handlePosition[resizeToolPosition.BOTTOM_CENTER];

  const offTop = calculateOffsetTop(element, top);

  return (
    <ResizeHandle
      ref={handleRef}
      left={left}
      top={offTop}
      appearanceStyle={appearanceStyle}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  );
};

SpaceResizeTool.propTypes = {
  elementId: PropTypes.string.isRequired,
  appearanceStyle: PropTypes.shape().isRequired,
  onActionStart: PropTypes.func.isRequired,
  onActionEnd: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

SpaceResizeTool.defaultProps = {
  onMouseEnter: utils.noop,
  onMouseLeave: utils.noop,
};

export default SpaceResizeTool;
