import { WS_ASSETS_REGEXP, WS_ASSETS_ERROR_MESSAGE } from '../constants/code-editor-validation';

function validateWsAssets({ value, session }) {
  const wsAssetsMatches = [];

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const match = WS_ASSETS_REGEXP.exec(value);

    if (!match) {
      break;
    }

    wsAssetsMatches.push(match);
  }

  return wsAssetsMatches.map((match) => {
    const editorPosition = session.doc.indexToPosition(match.index);

    return {
      ...editorPosition,
      type: 'error',
      text: WS_ASSETS_ERROR_MESSAGE,
    };
  });
}

export default validateWsAssets;
