import { anodum, textFormatting, view } from '@yola/ws-sdk';

const moveCursorLineUp = () => {
  const liveDocument = view.accessors.getLiveDocument();
  const selection = textFormatting.accessors.getAdvancedSelection();
  const { anchorNode: currentAnchorNode } = selection;
  const anchorNode = anodum.isTextNode(currentAnchorNode)
    ? currentAnchorNode.parentNode.previousSibling
    : currentAnchorNode.previousSibling;
  const range = liveDocument.createRange();

  range.setStart(anchorNode, 0);
  range.setEnd(anchorNode, 0);

  selection.removeAllRanges();
  selection.addRange(range);
  selection.refresh();
};

export default moveCursorLineUp;
