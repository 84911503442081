import { view, blocks } from '@yola/ws-sdk';
import getParentBlockByElement from '../../../blocks/helpers/get-parent-block-by-element';

const getParentBlockInfo = (elementId) => {
  const parentElement = view.accessors.getParentLiveElement(elementId);
  const parentBlock = getParentBlockByElement(parentElement);
  const blockId = blocks.accessors.getBlockIdByElement(parentBlock);
  const variationId = blocks.accessors.getVariationIdByElement(parentBlock);

  return { blockId, variationId, blockElement: parentBlock };
};

export default getParentBlockInfo;
