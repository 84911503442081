const MAX_LENGTH = 63;

function convertStringToDomainName(name) {
  return (name || '')
    .trim()
    .replace(/\s/g, '-')
    .replace(/_+/g, '-')
    .replace(/[^\w-]/g, '')
    .replace(/-+/g, '-')
    .slice(0, MAX_LENGTH)
    .toLowerCase();
}

export default convertStringToDomainName;
