import React from 'react';
import { i18next, view } from '@yola/ws-sdk';
import { designSystem } from '@yola/ws-ui';
import bowser from 'yola-bowser';
import store from 'src/js/store';
import setDisabledStatus from 'src/js/modules/view/actions/set-disabled-status';
import constants from '../../constants/common';
import config from '../../constants/config';
import copyImageToClipboard from '../../helpers/copy-image-to-clipboard';

const { Icon, Paragraph } = designSystem;

const copyImage = (additionalProperties = {}) => ({
  id: constants.COPY_IMAGE,
  get caption() {
    return i18next.t('Copy image');
  },
  get contentRight() {
    if (bowser.macOS) {
      return (
        <>
          <Icon glyph="command" size={16} type="light" />
          <Paragraph appearance="medium-emphasis">C</Paragraph>
        </>
      );
    }

    return <Paragraph appearance="medium-emphasis">Ctrl + C</Paragraph>;
  },
  async onClick(element) {
    store.dispatch(view.actions.setLoadedStatus(i18next.t('Copying to clipboard...')));
    store.dispatch(setDisabledStatus(true));

    await copyImageToClipboard(element, config);

    store.dispatch(view.actions.setLoadedStatus(true));
    store.dispatch(setDisabledStatus(false));
  },
  ...additionalProperties,
});

export default copyImage;
