export default Object.freeze({
  link: `<li>
    <a class="ws-link"></a>
  </li>`,
  nestableLink: `<li>
    <a class="ws-link"></a>
    <ul class="ws-footer-sublist"></ul>
  </li>`,
  textItem: `<li>
    <span></span>
  </li>`,
  nestableTextItem: `<li>
    <span></span>
    <ul class="ws-footer-sublist"></ul>
  </li>`,
});
