import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { i18next, blockTemplates, blocks, view, utils } from '@yola/ws-sdk';
import BlockLayout from '../components/block-layout';
import getDependenciesFromCategoryItems from '../helpers/get-dependencies-from-category-items';
import addInlineStylesToDependencies from '../helpers/add-inline-styles-to-dependencies';
import getStaticAssetsCategoryItems from '../helpers/get-static-assets-category-items';
import groupItemsByCategory from '../helpers/group-items-by-category';

const BlockLayoutContainer = ({
  blockLayoutDescription,
  blockItems,
  onBlockSelected,
  selectedLayoutVariationId,
  itemPreProcessFunction,
  blocksPreviewStylesheet,
}) => {
  const [mounted, setMountedState] = useState(false);

  const categories = {
    cover: {
      title: i18next.t('Cover'),
    },
  };

  const categoriesWithItems = groupItemsByCategory(blockItems, categories);

  const context = view.accessors.getLiveDocument();
  const itemDependencies = addInlineStylesToDependencies(
    getDependenciesFromCategoryItems(categoriesWithItems),
    blocksPreviewStylesheet
  );

  const itemStaticAssets = getStaticAssetsCategoryItems(categoriesWithItems);

  const onSelected = useCallback(
    (uuid) => () => {
      const block = blockItems.find((el) => el.uuid === uuid);
      if (block && block.interactive !== false) {
        onBlockSelected(block);
      }
    },
    [onBlockSelected, blockItems]
  );

  useEffect(() => {
    /**
     * Workaround for iPad.
     * iPad need some extra time
     * to render wrapper and calculate Box Model
     */
    requestAnimationFrame(() => {
      setMountedState(true);
    });

    if (context) {
      categoriesWithItems.forEach((category) =>
        category.items.forEach(({ id, templateId }) => {
          blockTemplates.helpers.injectDependencies(context, templateId);
          blocks.helpers.injectDependencies(context, id);
        })
      );
    }
  }, [context, categoriesWithItems]);

  return (
    <BlockLayout
      itemDependencies={itemDependencies}
      itemStaticAssets={itemStaticAssets}
      categoriesWithItems={categoriesWithItems}
      onSelected={onSelected}
      selectedLayoutVariationId={selectedLayoutVariationId}
      itemPreProcessFunction={itemPreProcessFunction}
      mounted={mounted}
      blockLayoutDescription={blockLayoutDescription}
    />
  );
};

BlockLayoutContainer.propTypes = {
  blockLayoutDescription: PropTypes.func,
  itemPreProcessFunction: PropTypes.func.isRequired,
  blockItems: PropTypes.array,
  onBlockSelected: PropTypes.func,
  selectedLayoutVariationId: PropTypes.string.isRequired,
  blocksPreviewStylesheet: PropTypes.string.isRequired,
};

BlockLayoutContainer.defaultProps = {
  blockLayoutDescription: null,
  blockItems: [],
  onBlockSelected: utils.noop,
};

export default BlockLayoutContainer;
