import React, { useContext, useEffect, useState, useLayoutEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import get from 'lodash.get';
import { StarterScreen } from '@yola/ws-ui';
import { i18next } from '@yola/ws-sdk';
import user from 'yola-editor/src/js/modules/user';
import { context } from 'yola-editor/src/js/utils/viewport-provider';
import getProgress from '../selectors/progress';
import usePrevious from '../hooks/use-previous';

const SUBPROGRESS_TIMER_DELAY = 5000;
let progressTimer;

function StarterScreenContainer() {
  const [initialProgress, setInitialProgress] = useState(0);
  const partnerData = useSelector(user.selectors.getPartnerData, shallowEqual);
  const { progress, updates } = useSelector(getProgress, shallowEqual);
  const previousUpdates = usePrevious(updates);
  const { width, height } = useContext(context);
  const [shouldShowUpdatesSubprogress, setShouldShowUpdatesSubprogress] = useState(false);
  let info;

  const handleRemoveFirstLoadingScreen = () => {
    const loadingItems = document.querySelectorAll('.js-loading-screen-item');
    loadingItems.forEach((item) => item.remove());
  };

  useLayoutEffect(() => {
    const loader = document.querySelector('.js-loading-screen-preloader-fill');
    loader.setAttribute('data-animation', 'stop');
    const initialProgressValue = loader.getAttribute('data-progress');
    setInitialProgress(parseInt(initialProgressValue, 10));
  }, []);

  useEffect(() => {
    setShouldShowUpdatesSubprogress(false);

    const updateProgress = () => {
      setShouldShowUpdatesSubprogress(true);
      clearTimeout(progressTimer);
    };

    clearTimeout(progressTimer);
    progressTimer = setTimeout(updateProgress, SUBPROGRESS_TIMER_DELAY);
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, [updates && updates.completed]);

  if (updates && updates.completed < updates.count) {
    info = `${i18next.t('Installing updates')}... ${i18next.t(
      `{currentUpdateInstalling} of {updatesCount}`,
      {
        currentUpdateInstalling: updates.completed + 1,
        updatesCount: updates.count,
      }
    )}`;

    if (shouldShowUpdatesSubprogress) {
      info += ` (${updates.subProgress}%)`;
    }
  } else if ((updates && updates.completed === updates.count) || progress >= 60) {
    info = `${i18next.t('Getting everything ready')}...`;
  }

  if (!get(partnerData, 'properties.wsBranding') || (!width && !height)) return null;

  const { companyLogo, productName, productSlogan } = partnerData.properties.wsBranding;

  const preventProgressInfoAnimation = Boolean(
    previousUpdates && updates && Object.keys(previousUpdates).length && Object.keys(updates).length
  );

  return (
    <StarterScreen
      logo={{ src: companyLogo }}
      onEntered={handleRemoveFirstLoadingScreen}
      progress={{
        initialValue: initialProgress,
        value: progress,
        info,
        preventInfoAnimation: preventProgressInfoAnimation,
      }}
      width={width}
      height={height}
      captions={{
        productName,
        productSlogan,
      }}
    />
  );
}

export default StarterScreenContainer;
