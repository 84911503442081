import React, { useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { blocks, extensions, hdrm } from '@yola/ws-sdk';
import { VARIATION_ATTRIBUTE_NAME } from 'src/js/modules/blocks/constants/common';
import highlighterOffset from 'src/js/modules/highlighter/helpers/highlighter-offset';
import highlighter from 'src/js/modules/highlighter';
import useDrag from '../../../common/hooks/use-drag';
import collectDOMData from '../helpers/collect-dom-data';
import useHandlePosition from '../../../common/hooks/use-handle-position';
import calculateOffsetTop from '../../../common/helpers/calculate-offset-top';
import resizeToolPosition from '../../../common/constants/resize-handle-positions';
import ResizeHandle from '../../../common/components/resize-handle';
import constants from '../constants/common';
import syncIconSize from '../../../common/helpers/sync-icon-size';
import calcIconSize from '../../../common/helpers/calc-icon-size';
import trackIconSizeResized from '../trackers/track-icon-size-resized';

const { DISABLE_BLUR_ATTR } = hdrm.constants.attributes;
const disableBlurAttribute = { [DISABLE_BLUR_ATTR]: true };

const SocialIconsResizeTool = ({
  elementId,
  appearanceStyle,
  onActionStart,
  onActionEnd,
  onMouseEnter,
  onMouseLeave,
}) => {
  const { element, groupElements, blockElement } = useMemo(
    () => collectDOMData(elementId),
    [elementId]
  );

  const handleRef = useRef(null);
  const cachedRect = useRef(null);

  const settings = extensions.accessors.getExtensionSettings(constants.slug);
  const { iconMaxSize: ICON_MAX_SIZE, iconMinSize: ICON_MIN_SIZE } = settings;

  const indent = highlighterOffset.get();
  const [handlePosition, updateHandlePosition] = useHandlePosition({
    element,
    positions: {
      [resizeToolPosition.BOTTOM_RIGHT]: {
        correctionX: indent,
        correctionY: indent,
      },
    },
  });

  const showHighlighter = (options = {}) => {
    const { withElementSize = false } = options;
    highlighter.operations.show([element], { forceUpdate: true, withElementSize });
  };

  const handleMouseEnter = () => {
    onMouseEnter();
    showHighlighter();
  };

  const handleMouseLeave = () => {
    onMouseLeave();
  };

  const handleResize = (currentSize, applyLiveChanges) => {
    const newSize = Math.round(currentSize);
    const { width } = cachedRect.current;

    groupElements.forEach((icon) => {
      const iconToResize = icon;
      iconToResize.style.width = `${newSize}px`;
      iconToResize.style.height = `${newSize}px`;
    });

    updateHandlePosition();

    if (!applyLiveChanges) return;

    syncIconSize(groupElements, newSize);

    const blockId = blocks.accessors.getBlockIdByElement(blockElement);
    const blockVariationId = blockElement.getAttribute(VARIATION_ATTRIBUTE_NAME);

    trackIconSizeResized({
      blockId,
      blockVariationId,
      oldSize: width,
      newSize,
    });
  };

  const handleDrag = (distance, applyLiveChanges = false) => {
    const { width } = cachedRect.current;
    const distanceCorrection = 1 / groupElements.length;
    const newSize = calcIconSize(
      distance[0] * distanceCorrection,
      distance[1] * distanceCorrection,
      width,
      ICON_MAX_SIZE,
      ICON_MIN_SIZE
    );
    handleResize(newSize, applyLiveChanges);
  };

  useDrag([handleRef], {
    onStart() {
      cachedRect.current = groupElements[0].getBoundingClientRect();
      showHighlighter({ withElementSize: true });
      onActionStart();
    },

    onMove(distance) {
      handleDrag(distance);
      showHighlighter({ withElementSize: true });
    },

    onEnd(distance) {
      handleDrag(distance, true);
      showHighlighter();
      onActionEnd();
    },
  });

  if (!handlePosition[resizeToolPosition.BOTTOM_RIGHT]) return null;

  const [left, top] = handlePosition[resizeToolPosition.BOTTOM_RIGHT];

  const offTop = calculateOffsetTop(element, top);

  return (
    <ResizeHandle
      ref={handleRef}
      direction="bottom-right"
      top={offTop}
      left={left}
      style={appearanceStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...disableBlurAttribute}
    />
  );
};

SocialIconsResizeTool.propTypes = {
  elementId: PropTypes.string.isRequired,
  scrollPosition: PropTypes.number.isRequired,
  appearanceStyle: PropTypes.shape().isRequired,
  onActionStart: PropTypes.func.isRequired,
  onActionEnd: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

SocialIconsResizeTool.defaultProps = {
  onMouseEnter: Function.prototype,
  onMouseLeave: Function.prototype,
};

export default SocialIconsResizeTool;
