import actionTypes from '../constants/action-types';

const setPrimaryVhost = (primaryVhost) => ({
  type: actionTypes.SET_PRIMARY_VHOST,
  payload: {
    primaryVhost,
  },
});

export default setPrimaryVhost;
