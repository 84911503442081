import React, { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { dialogs, integration, i18next, theme, site, customization, template } from '@yola/ws-sdk';
import { selectors } from '@yola/subscription-manager-js';
import get from 'lodash.get';
import publishing from 'src/js/modules/publishing';
import onlinestore from 'src/js/modules/onlinestore';
import navigation from 'src/js/modules/navigation';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import { saveSiteWithEffect } from 'src/js/modules/saving/helpers/save-site';
import segment from 'src/js/modules/analytics/segment';
import user from 'yola-editor/src/js/modules/user';
import yolaEditorSite from 'yola-editor/src/js/modules/site';
import useFeatureFlags from 'yola-editor/src/js/modules/feature-flags/hooks/use-feature-flags';
import getHelpMenuItemClickCallbacks from 'yola-editor/src/js/modules/editor/settings/get-help-menu-item-click-callbacks';
import getSortedItems from '../helpers/get-sorted-items';
import isWebsiteDesignAvailable from '../helpers/is-website-design-available';
import getComputedItems from '../helpers/get-computed-items';
import getComputedBanners from '../helpers/get-computed-banners';
import { ADVERTISING_ID, ANALYTICS_ID, HELP_MENU_ID } from '../constants';
import components from '../components';
import getUnfinishedStepsCount from '../../site-progress-checklist/helpers/get-unfinished-steps-count';
import MainMenuItemsClickEventTracker from '../../utils/main-menu-items-click-event-tracker';
import dialogCallSourceIds from '../../website-design/constant/dialog-call-source-ids';
import itemIds from '../../help-menu/constants/item-ids';

const { NavProvider, Nav, NavToggle, NavCollapse } = components;
const {
  constants: { triggerIds },
} = segment;
const { platformComponents } = user.constants;

function MainMenuContainer() {
  const isMultilingualSite = site.verifiers.isMultilingualSite();
  const settings = useSelector(integration.selectors.getSettings);
  const onlineStore = useSelector(onlinestore.selectors.getOnlineStoreLimit);
  const isFooterCodeAvailable = useSelector(() =>
    template.verifiers.isVariableGlobalsAvailable(template.constants.FOOTER_CODE)
  );
  const isNavigationAvailable = useSelector(navigation.verifiers.isAvailable);
  const templateBuildSlug = useSelector(template.selectors.getBuildSlug);
  const { colorPalettes, fonts, colorPaletteVariable, fontsVariable } = useSelector((state) => {
    if (templateBuildSlug.startsWith('ws-intense-next')) {
      return {
        colorPalettes: customization.selectors.getColorPalettes(state),
        fonts: customization.selectors.getFontPairs(state),
        colorPaletteVariable: template.selectors.getVariableValueByPath(
          state,
          customization.constants.COLOR_PALETTE_VARIABLE
        ),
        fontsVariable: template.selectors.getVariableValueByPath(
          state,
          customization.constants.FONTS_VARIABLE
        ),
      };
    }

    return {
      colorPalettes: theme.selectors.getColorPalettes(state),
      fonts: theme.selectors.getFonts(state),
      colorPaletteVariable: template.selectors.getVariableValueByPath(
        state,
        theme.constants.COLOR_PALETTE_VARIABLE
      ),
      fontsVariable: template.selectors.getVariableValueByPath(
        state,
        theme.constants.FONTS_VARIABLE
      ),
    };
  }, shallowEqual);
  const siteProgressChecklist = useSelector(site.selectors.getSiteProgressChecklist);
  const siteURL = useSelector(yolaEditorSite.selectors.getSiteURL);
  const activeHostingSubscription = useSelector(selectors.getActiveHostingSubscription);
  const availablePlatformComponents = useSelector(user.selectors.getAvailablePlatformComponents);

  const [featureFlags] = useFeatureFlags(['website_assistant', 'upgrade_to_publish']);
  const {
    website_assistant: isWebsiteAssistantFeatureEnabled,
    upgrade_to_publish: isUpgradeToPublishEnabled,
  } = featureFlags;

  const dispatch = useDispatch();

  const publish = useCallback(() => {
    saveSiteWithEffect(async () => {
      if (
        (!siteURL || !activeHostingSubscription) &&
        isUpgradeToPublishEnabled &&
        availablePlatformComponents.includes(platformComponents.DOMAINS)
      ) {
        dispatch(dialogs.actions.show(dialogTypes.PUBLISH_INTERRUPTION_DIALOG));
      } else {
        dispatch(publishing.actions.initiatePublishWebsite());
      }
    });
  }, [dispatch]);

  const showPageManager = useCallback(() => {
    dispatch(dialogs.actions.show(dialogTypes.PAGE_MANAGER));
  }, [dispatch]);
  const onWebsiteDesign = useCallback(() => {
    dispatch(
      dialogs.actions.show(dialogTypes.WEBSITE_DESIGN, {
        onDialogCancel: dialogs.operations.hide,
        onDialogMainAction: dialogs.operations.hide,
        sourceId: dialogCallSourceIds.APP_MAIN_MENU,
      })
    );
  }, [dispatch]);
  const onWebsiteSettings = useCallback(() => {
    dispatch(dialogs.actions.show(dialogTypes.WEBSITE_SETTINGS));
  }, [dispatch]);
  const redirectToStore = useCallback(() => {
    dispatch(onlinestore.actions.redirectToStore());
  }, [dispatch]);

  const baseIconPath = 'images/main-menu-icons/';
  const items = [
    {
      title: i18next.t('Publish'),
      onClick: publish,
      image: `${baseIconPath}/publish.svg`,
      heading: i18next.t('Publish'),
      description: i18next.t('Push your site live to the Internet for the world to see!'),
      id: 'publish',
      icon: {
        glyph: 'rocket-45',
        width: 26,
        height: 26,
        strokeWidth: 1.3,
      },
    },
    {
      title: i18next.t('Page Manager'),
      onClick: showPageManager,
      image: `${baseIconPath}/page-manager.svg`,
      heading: `${i18next.t('Pages')}...`,
      description: i18next.t('Create, Delete and Manage pages of your site'),
      id: 'page-manager',
      icon: {
        glyph: 'layers',
        width: 20,
        height: 20,
        strokeWidth: 1.5,
      },
    },
  ];

  if (isNavigationAvailable) {
    items.push({
      title: i18next.t('Navigation manager'),
      useEvent: true,
      onClick: (e) => {
        navigation.operations.openNavigationExtension(e.target, 'right');
      },
      heading: `${i18next.t('Navigation')}...`,
      description: i18next.t('Manage the navigation menu of your site'),
      id: 'navigation',
      icon: {
        glyph: 'hierarchy',
        width: 22,
        height: 22,
        strokeWidth: 1.1,
      },
    });
  }

  items.push({
    title: i18next.t('Settings'),
    onClick: onWebsiteSettings,
    heading: `${i18next.t('Settings')}...`,
    description: i18next.t('Configure your site'),
    id: 'settings',
    icon: {
      glyph: 'settings',
      width: 20,
      height: 20,
      strokeWidth: 1.5,
    },
  });

  if (isWebsiteDesignAvailable(colorPalettes, fonts, colorPaletteVariable, fontsVariable)) {
    items.push({
      title: i18next.t('Design'),
      onClick: onWebsiteDesign,
      heading: `${i18next.t('Design')}...`,
      description: i18next.t('Configure your site'),
      id: 'design',
      icon: {
        glyph: 'customize',
        width: 24,
        height: 24,
        strokeWidth: 1.5,
      },
    });
  }

  if (onlineStore.available) {
    items.push({
      heading: i18next.t('Online Store'),
      onClick: redirectToStore,
      order: 80,
      id: 'online-store',
      labelFor: 'helpMenu2',
      icon: {
        glyph: 'online-store',
        width: 24,
        height: 24,
        strokeWidth: 1.3,
      },
    });
  }

  const settingsMainMenuItems = get(settings, 'mainMenu.items', []);
  const settingsMainMenuBanners = get(settings, 'mainMenu.banners', []);
  const itemsFromSettings = settingsMainMenuItems.filter((item) => {
    if (item.id === ADVERTISING_ID || item.id === ANALYTICS_ID) {
      // if site does not have footer_code variable and global, analytics and advertising addons won't work
      return isFooterCodeAvailable;
    }

    return true;
  });

  const sortedItems = getSortedItems(items, itemsFromSettings);
  const helpMenuItem = sortedItems.find((item) => item.id === HELP_MENU_ID);
  const helpMenuItems = get(helpMenuItem, 'subMenu.submenu', []);

  let multilingualTourMenuItem = {};
  const { multilingualTourOnClick } = getHelpMenuItemClickCallbacks(triggerIds.MAIN_MENU);

  if (isMultilingualSite) {
    multilingualTourMenuItem = {
      title: i18next.t('Multilingual tour'),
      heading: i18next.t('Multilingual tour'),
      id: itemIds.MULTILINGUAL_TOUR,
      description: '',
      order: 4,
      onClick: multilingualTourOnClick,
    };
  }

  const menuSegments = {
    items: sortedItems,
    helpMenuItems: [...helpMenuItems, multilingualTourMenuItem],
  };

  const menu = getComputedItems(menuSegments);
  const banners = getComputedBanners(settingsMainMenuBanners);

  const isWebsiteAssistantEnabled =
    isWebsiteAssistantFeatureEnabled && templateBuildSlug.startsWith('ws-intense-next');
  const steps = Object.values(siteProgressChecklist).length;
  const stepsCompleted = steps - getUnfinishedStepsCount(siteProgressChecklist);
  const checklistProgressData = {
    isEnabled: isWebsiteAssistantEnabled,
    heading: 'Set up your site',
    title: `${stepsCompleted} of ${steps} completed`,
    steps,
    stepsCompleted,
    onClick: () => {
      dialogs.operations.show(dialogTypes.SITE_PROGRESS_CHECKLIST);
    },
  };

  return (
    <NavProvider>
      <Nav>
        <NavToggle />
        <MainMenuItemsClickEventTracker items={menu}>
          <NavCollapse
            items={menu}
            banners={banners}
            checklistProgressData={checklistProgressData}
          />
        </MainMenuItemsClickEventTracker>
      </Nav>
    </NavProvider>
  );
}

export default MainMenuContainer;
