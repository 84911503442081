import React from 'react';

const CompanyIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="144" height="90" viewBox="0 0 144 90" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M62 28.181C62 23.6628 65.5143 20 69.8495 20H74.1505C78.4857 20 82 23.6628 82 28.181V31.5431C82 32.3477 81.3742 33 80.6021 33C79.8301 33 79.2043 32.3477 79.2043 31.5431V28.181C79.2043 25.272 76.9417 22.9138 74.1505 22.9138H69.8495C67.0583 22.9138 64.7957 25.272 64.7957 28.181V31.5431C64.7957 32.3477 64.1699 33 63.3978 33C62.6258 33 62 32.3477 62 31.5431V28.181Z"
      fill="#646C75"
    />
    <path
      d="M46 48H98V64C98 66.2091 96.2091 68 94 68H50C47.7909 68 46 66.2091 46 64V48Z"
      fill="#DADDE0"
    />
    <rect x="44" y="30" width="56" height="24" rx="4" fill="#B0B8BF" />
    <path
      d="M69 52C69 50.8954 69.8954 50 71 50H73C74.1046 50 75 50.8954 75 52V56C75 57.1046 74.1046 58 73 58H71C69.8954 58 69 57.1046 69 56V52Z"
      fill="#646C75"
    />
  </svg>
);

export default CompanyIcon;
