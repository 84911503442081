import { history, contentEditable, integration, dialogs } from '@yola/ws-sdk';
import store from '../../../store';
import customUi from '../../custom-ui';
import isRedoEvent from './is-redo-event';
import trackRedoTriggerClicked from '../../analytics/segment/trackers/redo-trigger-clicked';

const redoHandler = (event) => {
  const isDialogVisible = dialogs.verifiers.isVisible() || integration.verifiers.isDialogVisible();
  if (isDialogVisible) return;

  const state = store.getState();
  const isCustomUiVisible = customUi.selectors.getVisibilityState(state);

  if (isCustomUiVisible) return;

  if (!isRedoEvent(event)) return;

  const { __detail = {} } = event;

  if (__detail.contentEditable) {
    store.dispatch(contentEditable.actions.redo());
    return;
  }

  const canRedo = !!history.selectors.getNextRedo(state);

  if (canRedo) {
    store.dispatch(history.actions.redo());
    trackRedoTriggerClicked();
  }
};

export default redoHandler;
