import get from 'lodash.get';
import { config } from '@yola/ws-sdk';

const getTestPagesEnabled = (rootState) => {
  const cfg = config.selectors.getConfig(rootState);

  return get(cfg, 'ws-editor.test_pages_enabled');
};

export default getTestPagesEnabled;
