import React from 'react';
import { dialogs, extensions, i18next } from '@yola/ws-sdk';
import { designSystem } from '@yola/ws-ui';
import Trigger from 'src/js/modules/common/components/trigger';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import { HTML_WIDGET_TRIGGER_ID } from 'src/js/modules/control-pane/constants/trigger-ids';
import constants from '../constants/common';

const { Icon } = designSystem;

export default {
  id: HTML_WIDGET_TRIGGER_ID,
  trigger: (
    <Trigger id={HTML_WIDGET_TRIGGER_ID}>
      <Icon glyph="html" />
    </Trigger>
  ),
  get tooltip() {
    return i18next.t('Custom HTML');
  },
  priority: 100,
  onTriggerClick(elementId) {
    dialogs.operations.show(dialogTypes.EMBED_HTML_SETTINGS_DIALOG, {
      elementId,
    });
  },
  matches(liveElement) {
    const settings = extensions.accessors.getExtensionSettings(constants.slug);
    return liveElement.matches(settings.querySelector);
  },
};
