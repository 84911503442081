import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionHeader,
  AccordionContent,
  Panel,
  PanelLeft,
  PanelRight,
  PanelGroup,
  Text,
  Modal,
} from '@yola/ws-ui';
import bowser from 'yola-bowser';
import segment from 'src/js/modules/analytics/segment';
import { dialogs, integration, site, template } from '@yola/ws-sdk';
import customUI from 'src/js/modules/custom-ui';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import useFeatureFlags from 'yola-editor/src/js/modules/feature-flags/hooks/use-feature-flags';
import { progressChecklistCategories, itemsMap } from '../helpers/site-progress-checklist-map';
import onContactSupportClick from '../helpers/on-contact-support-click';
import SiteProgressItem from '../components/site-progress-item';
import SiteTour from '../components/site-tour';
import RequestFeatureBanner from '../components/request-feature-banner';
import getUnfinishedStepsCount from '../helpers/get-unfinished-steps-count';
import getIsCategoryCompleted from '../helpers/get-is-category-completed';
import getFirstUncompletedCategorySlug from '../helpers/get-first-uncompleted-category-slug';
import getCategoryChecklist from '../helpers/get-category-checklist';
import getModalSizes from '../helpers/get-modal-sizes';
import resetLocalStorageData from '../helpers/reset-local-storage-data';
import getSafeLocalStorage from '../helpers/get-safe-local-storage';
import {
  SITE_PROGRESS_CHECKLIST_SCROLL_TOP,
  SITE_PROGRESS_CHECKLIST_OPENED_CATEGORIES,
  UPVOTE_INTERRUPTION_ID,
  SITE_ID,
} from '../constants';

const { track, constants } = segment;

const getInitialOpenedCategories = (siteProgressChecklist) => {
  let storedOpenedCategoriesString;
  try {
    storedOpenedCategoriesString = localStorage.getItem(SITE_PROGRESS_CHECKLIST_OPENED_CATEGORIES);
  } catch (err) {
    storedOpenedCategoriesString = '';
  }

  return storedOpenedCategoriesString
    ? JSON.parse(storedOpenedCategoriesString)
    : [getFirstUncompletedCategorySlug(progressChecklistCategories, siteProgressChecklist)];
};

const getUnfinishedPerAllString = (category, siteProgressChecklist) =>
  `${
    category.items.length -
    getUnfinishedStepsCount(getCategoryChecklist(category, siteProgressChecklist))
  } / ${category.items.length}`;

function SiteProgressChecklistContainer({ onDialogCancel }) {
  const isCustomUIVisible = useSelector(customUI.selectors.getVisibilityState);
  const siteProgressChecklist = useSelector(site.selectors.getSiteProgressChecklist);
  const templateBuildSlug = useSelector(template.selectors.getBuildSlug);
  const [featureFlags] = useFeatureFlags(['website_assistant']);
  const { website_assistant: isWebsiteAssistantFeatureEnabled } = featureFlags;
  const siteChecklistEnabled =
    isWebsiteAssistantFeatureEnabled && templateBuildSlug.startsWith('ws-intense-next');
  const requestBlockData = useSelector(integration.selectors.getRequestBlock);
  const roadmapUrl = requestBlockData && requestBlockData.publicRoadmapUrl;
  const integrationSettings = useSelector(integration.selectors.getSettings);
  const siteId = site.accessors.getSiteId();
  const { isDialogVisible: isYolaDialogVisible } = integrationSettings.ui;
  const { width, height, offsetX, offsetY } = getModalSizes();

  const dispatch = useDispatch();

  const itemRef = useRef(null);

  const safeLocalStorage = getSafeLocalStorage();

  if (siteId !== safeLocalStorage.getItem(SITE_ID)) {
    resetLocalStorageData(siteId);
  }

  const [openedCategories, setOpenedCategories] = useState(
    getInitialOpenedCategories(siteProgressChecklist)
  );

  const onClose = () => {
    onDialogCancel();

    track(constants.events.SITE_ASSISTANT_CLOSED, {
      siteId: site.accessors.getSiteId(),
      templateBuildSlug,
    });
    dialogs.operations.hide();
  };

  useEffect(() => {
    const contentElement = itemRef && itemRef.current;

    if (!contentElement) return null;

    track(constants.events.SITE_ASSISTANT_DISPLAYED, {
      siteId,
      templateBuildSlug,
    });
    const handleScroll = () => {
      safeLocalStorage.setItem(SITE_PROGRESS_CHECKLIST_SCROLL_TOP, contentElement.scrollTop);
    };

    contentElement.addEventListener('scroll', handleScroll);

    return () => {
      contentElement.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!siteChecklistEnabled || isCustomUIVisible || isYolaDialogVisible) {
      onClose();
    }
  }, [siteChecklistEnabled, isCustomUIVisible, isYolaDialogVisible]);

  const onAccordionMount = () => {
    if (itemRef && itemRef.current) {
      itemRef.current.scrollTop = safeLocalStorage.getItem(SITE_PROGRESS_CHECKLIST_SCROLL_TOP);
    }
  };

  const onItemCheck = (item, itemCategory, categoryIndex) => {
    const newSiteProgressChecklist = {
      ...siteProgressChecklist,
      [item]: !siteProgressChecklist[item],
    };

    if (siteProgressChecklist[item]) {
      track(constants.events.SITE_PROGRESS_CHECKLIST_ITEM_UNCHECKED, {
        siteId,
        itemId: item,
        templateBuildSlug,
      });
    } else {
      track(constants.events.SITE_PROGRESS_CHECKLIST_ITEM_CHECKED, {
        siteId,
        itemId: item,
        templateBuildSlug,
      });
    }

    if (getIsCategoryCompleted(itemCategory, newSiteProgressChecklist)) {
      const newOpenedCategories = openedCategories.filter(
        (category) => category !== itemCategory.slug
      );

      const nextUncompletedCategorySlug = getFirstUncompletedCategorySlug(
        progressChecklistCategories.slice(categoryIndex),
        newSiteProgressChecklist
      );

      if (!openedCategories.includes(nextUncompletedCategorySlug)) {
        newOpenedCategories.push(nextUncompletedCategorySlug);
      }

      safeLocalStorage.setItem(
        SITE_PROGRESS_CHECKLIST_OPENED_CATEGORIES,
        JSON.stringify(newOpenedCategories)
      );

      setOpenedCategories(newOpenedCategories);
    }

    dispatch(site.actions.setSiteProperty('siteProgressChecklist', newSiteProgressChecklist));
  };

  const onHeaderClick = (currentCategory, isOpen) => {
    const newOpenedCategories = isOpen
      ? openedCategories.filter((category) => category !== currentCategory)
      : [...openedCategories, currentCategory];
    setOpenedCategories(newOpenedCategories);

    safeLocalStorage.setItem(
      SITE_PROGRESS_CHECKLIST_OPENED_CATEGORIES,
      JSON.stringify(newOpenedCategories)
    );
  };

  const voteForFeature = () => {
    track(constants.events.UPVOTE_INTERRUPTION_DISPLAYED, {
      siteId,
      interruptionId: UPVOTE_INTERRUPTION_ID,
      templateBuildSlug,
    });

    dialogs.operations.show(dialogTypes.VOTE_FOR_THE_FEATURE, {
      onClose: () => {
        dialogs.operations.hide();
      },
      roadmapUrl,
    });
  };

  const onSiteTourClick = () => {
    dialogs.operations.show(dialogTypes.WELCOME_TOUR);
  };

  const onItemLinkClick = (itemId) => {
    track(constants.events.SITE_PROGRESS_CHECKLIST_TUTORIAL_OPENED, {
      siteId,
      itemId,
      templateBuildSlug,
    });
  };

  return (
    <Modal
      overlay="none"
      resizable={false}
      draggable={false}
      fullscreen={bowser.mobile}
      width={width}
      height={height}
      offsetX={offsetX}
      offsetY={offsetY}
      handleCancel={onClose}
      handleSubmit={onClose}
    >
      <PanelGroup height="100%" className="ws-site-progress-checklist">
        <Panel theme="gray-100" height="48" align="middle">
          <PanelLeft>
            <Text type="heading-6" className="ws-site-progress-checklist__heading">
              Website assistant
            </Text>
          </PanelLeft>

          <PanelRight>
            <Text type="heading-6" onClick={onClose} className="ws-site-progress-checklist__close">
              Close
            </Text>
          </PanelRight>
        </Panel>

        <Panel height="100%" scrollable rootRef={itemRef}>
          <SiteTour
            caption="Welcome tour"
            onClick={onSiteTourClick}
            imageSrc="/images/site-progress-checklist/site-tour-demo.png"
          />
          <Accordion
            onMount={onAccordionMount}
            openedAccordionItems={openedCategories}
            shouldPreventInitialAnimation
          >
            {progressChecklistCategories.map((category, categoryIndex) => (
              // eslint-disable-next-line yola/react/no-array-index-key
              <React.Fragment key={categoryIndex}>
                <AccordionHeader
                  className="ws-site-progress-checklist__header"
                  itemId={category.slug}
                  onClick={onHeaderClick}
                >
                  {(isOpen) => (
                    <React.Fragment>
                      {category.captions}

                      <span className="ws-site-progress-checklist__header-info">
                        {getUnfinishedPerAllString(category, siteProgressChecklist)}
                        <span
                          className={`ws-site-progress-checklist__arrow ws-site-progress-checklist__arrow--${
                            isOpen ? 'up' : 'down'
                          }`}
                        />
                      </span>
                    </React.Fragment>
                  )}
                </AccordionHeader>
                <AccordionContent itemId={category.slug}>
                  {category.items.map((item, itemIndex) => (
                    <SiteProgressItem
                      // eslint-disable-next-line yola/react/no-array-index-key
                      key={itemIndex}
                      captions={itemsMap[item].captions}
                      href={itemsMap[item].href}
                      onItemLinkClick={onItemLinkClick}
                      duration={itemsMap[item].duration}
                      isChecked={siteProgressChecklist[item]}
                      isDisabled={itemsMap[item].disabled}
                      id={item}
                      onCheckboxChange={() => {
                        onItemCheck(item, category, categoryIndex);
                      }}
                    />
                  ))}
                </AccordionContent>
              </React.Fragment>
            ))}
          </Accordion>

          <div className="ws-site-progress-checklist__banner-container">
            <RequestFeatureBanner
              captions={{
                title: "Can't find what you're looking for? Let us know!",
                button: 'Contact support',
              }}
              icon="help"
              onClick={() => {
                onClose();
                onContactSupportClick(integrationSettings);
              }}
            />
            {roadmapUrl && (
              <RequestFeatureBanner
                captions={{
                  title: 'Don`t have time to build a website by yourself?',
                  button: 'Hire a professional',
                }}
                icon="user-search"
                onClick={voteForFeature}
              />
            )}
          </div>
        </Panel>
      </PanelGroup>
    </Modal>
  );
}

SiteProgressChecklistContainer.propTypes = {
  onDialogCancel: PropTypes.func.isRequired,
};

export default SiteProgressChecklistContainer;
