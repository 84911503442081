import canPasteBlock from './can-paste-block';
import isCustomBlock from './is-custom-block';
import isWsBlock from './is-ws-block';
import hasActivePageNonIgnoredBlocks from './has-active-page-non-ignored-blocks';
import isLayoutSwitchingDisabled from './is-layout-switching-disabled';

export default {
  canPasteBlock,
  isCustomBlock,
  hasActivePageNonIgnoredBlocks,
  isWsBlock,
  isLayoutSwitchingDisabled,
};
