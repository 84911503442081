import { template } from '@yola/ws-sdk';
import settingTypes from '../constants/setting-types';

const doesElementMatchSettings = (element) => {
  const variable = template.accessors.getWebsiteVariableByElement(element);

  return Boolean(variable) && Object.values(settingTypes).includes(variable);
};

export default doesElementMatchSettings;
