import { i18next } from '@yola/ws-sdk';
import icons from '../icons';
import fieldTypeCategories from '../constants/field-type-categories';

const { basic, contacts } = icons;
const {
  ShortTextIcon,
  LongTextIcon,
  DropListIcon,
  CheckboxesIcon,
  RadiobuttonsIcon,
  AttachFileIcon,
  DateIcon,
  TimeIcon,
} = basic;
const { NameIcon, EmailIcon, PhoneIcon, AddressIcon, CompanyIcon, PositionIcon, LinkIcon } =
  contacts;

const getFieldTypeOptions = (types) => {
  const {
    singleline,
    multiline,
    dropdown,
    checkbox,
    radio,
    date,
    time,
    email,
    phone,
    link,
    attachfile,
  } = types;
  return {
    [fieldTypeCategories.BASIC]: [
      {
        type: singleline,
        label: i18next.t('Short text'),
        IconComponent: ShortTextIcon,
        category: fieldTypeCategories.BASIC,
      },
      {
        type: multiline,
        label: i18next.t('Long text'),
        IconComponent: LongTextIcon,
        category: fieldTypeCategories.BASIC,
      },
      {
        type: dropdown,
        label: i18next.t('Drop-list'),
        IconComponent: DropListIcon,
        category: fieldTypeCategories.BASIC,
      },
      {
        type: checkbox,
        label: i18next.t('Checkboxes'),
        IconComponent: CheckboxesIcon,
        category: fieldTypeCategories.BASIC,
      },
      {
        type: radio,
        label: i18next.t('Radiobuttons'),
        IconComponent: RadiobuttonsIcon,
        category: fieldTypeCategories.BASIC,
      },
      {
        type: attachfile,
        label: i18next.t('Attach File'),
        buttonText: i18next.t('Choose file'),
        IconComponent: AttachFileIcon,
        isPremiumFeature: true,
        category: fieldTypeCategories.BASIC,
      },
      {
        type: date,
        label: i18next.t('Date'),
        IconComponent: DateIcon,
        category: fieldTypeCategories.BASIC,
      },
      {
        type: time,
        label: i18next.t('Time'),
        IconComponent: TimeIcon,
        category: 'basic',
      },
    ],
    [fieldTypeCategories.CONTACTS]: [
      {
        type: singleline,
        label: i18next.t('Name'),
        placeholder: i18next.t('Enter your name'),
        IconComponent: NameIcon,
        category: fieldTypeCategories.CONTACTS,
      },
      {
        type: email,
        label: i18next.t('E-mail'),
        placeholder: i18next.t('Enter your e-mail'),
        IconComponent: EmailIcon,
        category: fieldTypeCategories.CONTACTS,
      },
      {
        type: phone,
        label: i18next.t('Phone'),
        placeholder: i18next.t('Enter your phone number'),
        IconComponent: PhoneIcon,
        category: fieldTypeCategories.CONTACTS,
      },
      {
        type: singleline,
        label: i18next.t('Address'),
        placeholder: i18next.t('Enter your address'),
        IconComponent: AddressIcon,
        category: fieldTypeCategories.CONTACTS,
      },
      {
        type: singleline,
        label: i18next.t('Company'),
        placeholder: i18next.t('Enter your company name'),
        IconComponent: CompanyIcon,
        category: fieldTypeCategories.CONTACTS,
      },
      {
        type: singleline,
        label: i18next.t('Position'),
        placeholder: i18next.t('Enter your position'),
        IconComponent: PositionIcon,
        category: fieldTypeCategories.CONTACTS,
      },
      {
        type: link,
        label: i18next.t('Link'),
        placeholder: i18next.t('Enter your website URL'),
        IconComponent: LinkIcon,
        category: fieldTypeCategories.CONTACTS,
      },
    ],
  };
};

export default getFieldTypeOptions;
