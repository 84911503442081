const getDefaultAction = (currentPage, currentItem) => {
  if (currentPage) {
    return currentPage.path;
  }
  if (currentItem) {
    return currentItem.href;
  }
  return '';
};

const getDefaultOptions = (currentItem, pages) => {
  if (currentItem) {
    const currentPage = pages.find((page) => page.path === currentItem.href);

    return {
      actionValue: getDefaultAction(currentPage, currentItem),
      title: currentItem.title,
      target: currentItem.target,
      fileName: currentItem.download,
    };
  }

  return {
    actionValue: '',
    title: '',
    target: '_self',
    fileName: null,
  };
};

export default getDefaultOptions;
