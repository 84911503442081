import { assets, integration, dialogs } from '@yola/ws-sdk';
import isBase64 from 'src/js/modules/utils/is-base-64';
import dialogsTypes from '../../../dialogs/constants/dialog-types';
import showImageUploadFailDialog from './show-image-upload-fail-dialog';
import handleImageUploadFail from './handle-image-upload-fail';
import handleWrongFormatImages from './handle-wrong-format-images';
import handleFileSizeLimit from './handle-file-size-limit';
import getDerivedUploadedImages from './get-derived-uploaded-images';
import processBase64Images from './process-base-64-images';

const filterBase64Urls = (urls) => urls.filter((url) => !isBase64(url));
const getBase64Urls = (urls) => urls.filter((url) => isBase64(url));

const externalImagePaster = (store) => (next) => async (action) => {
  const { actionTypes, constants } = assets;
  const { ALLOWED_IMAGE_FORMATS } = constants;

  if (action.type === actionTypes.CREATE_MEDIA_GALLERY_ASSETS_FROM_EXTERNAL_SOURCES) {
    const { assets: urls, constraints, resolve, reject } = action.payload;
    const state = store.getState();
    const limits = integration.selectors.getLimits(state);
    const { maxSize } = limits.uploadFileSize;
    const urlsToUpload = filterBase64Urls(urls);
    const base64Urls = getBase64Urls(urls);
    let base64Images = [];
    let isFileSizeLimitReached = false;

    dialogs.operations.show(dialogsTypes.IMAGE_LOADING_DIALOG);
    if (base64Urls) {
      ({ isFileSizeLimitReached, base64Images } = await processBase64Images(base64Urls));
    }

    if (isFileSizeLimitReached) {
      store.dispatch(assets.actions.fileSizeLimitReached(base64Images, resolve, reject));
      return;
    }

    if (!urlsToUpload.length) {
      dialogs.operations.hide();
      resolve(base64Images);
      return;
    }

    const data = {
      constraints: {
        size_limit: maxSize,
        allowed_formats: ALLOWED_IMAGE_FORMATS,
        ...constraints,
      },
      urls: filterBase64Urls(urls),
    };

    assets.helpers.downloadExternalAssets({
      data,
      onSuccess(images) {
        dialogs.operations.hide();
        handleFileSizeLimit({
          images,
          store,
          resolve,
          reject,
        });

        handleImageUploadFail(images);
        handleWrongFormatImages(images);

        const uploadedImages = [...getDerivedUploadedImages(images), ...base64Images];
        resolve(uploadedImages);
      },
      onError(error) {
        dialogs.operations.hide();
        console.error(error);
      },
      onFail(error) {
        store.dispatch(dialogs.actions.hide());
        if (error) {
          showImageUploadFailDialog();
        }

        const failedImages = urls.map((url) => ({
          assetSource: null,
          externalSource: url,
        }));
        resolve(failedImages);
      },
    });
  }
  next(action);
};

export default externalImagePaster;
