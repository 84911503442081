import get from 'lodash.get';
import { constants } from '@yola/subscription-manager-js';

const {
  hostingPackageFeatureNames: { PARTNER_ADS },
} = constants;

const brandedFooterLimit = ({ targetPackage, userFeatures }) => ({
  removable: !targetPackage.features[PARTNER_ADS] || get(userFeatures, 'yolaFooter.removed', false),
});

export default brandedFooterLimit;
