import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { blocks, globals, site, template, view } from '@yola/ws-sdk';
import segment from 'src/js/modules/analytics/segment';
import getParentBlockByElement from 'src/js/modules/blocks/helpers/get-parent-block-by-element';
import highlighter from '../../highlighter';
import ContextMenuItem from '../components/context-menu-item';
import isTouchSupport from '../verifiers/is-touch-support';

function ContextMenuGroupContainer({ group }) {
  const { element } = group;
  const isTouchDevice = isTouchSupport();
  const prevHighlightedElements = useMemo(() => highlighter.accessors.getHighlightedElements(), []);
  const focusedElementId = useSelector(view.selectors.getFocusedElement);
  const selectedElementId = useSelector(view.selectors.getSelectedElement);

  const onMouseEnterHandler = useCallback(() => {
    const { onMouseEnter } = group;

    let shouldPreventDefault = false;

    const preventDefaultHandler = () => {
      shouldPreventDefault = true;
    };

    if (typeof onMouseEnter === 'function') {
      onMouseEnter({
        preventDefaultHandler,
      });
    }

    if (shouldPreventDefault || isTouchDevice) return;

    highlighter.operations.show([element]);
  }, [element, isTouchDevice]);

  const onMouseLeaveHandler = useCallback(() => {
    const { onMouseLeave } = group;

    let shouldPreventDefault = false;

    const preventDefaultHandler = () => {
      shouldPreventDefault = true;
    };

    if (typeof onMouseLeave === 'function') {
      onMouseLeave({
        preventDefaultHandler,
      });
    }

    if (shouldPreventDefault || isTouchDevice) return;

    if (!element || (!focusedElementId && !selectedElementId)) {
      highlighter.operations.hide();
      return;
    }

    const wasElementHighlightedBefore = prevHighlightedElements.some(
      ([prevElement]) => prevElement === element
    );

    if (!wasElementHighlightedBefore) {
      highlighter.operations.hide([element]);
    }
  }, [isTouchDevice, element, focusedElementId, prevHighlightedElements, selectedElementId]);

  const onBeforeItemClickHandler = ({ id: itemId, element: currentElement }) => {
    const { onBeforeItemClick } = group;

    let shouldPreventDefault = false;

    const preventDefaultHandler = () => {
      shouldPreventDefault = true;
    };

    if (typeof onBeforeItemClick === 'function') {
      onBeforeItemClick({
        preventDefaultHandler,
      });
    }

    if (shouldPreventDefault) return;

    const parentBlock = getParentBlockByElement(currentElement);

    const isElementGlobal = globals.verifiers.isElementGlobal(
      view.accessors.getLiveElementId(currentElement)
    );
    const targetElementType = isElementGlobal ? 'global' : 'element';
    const wasGlobalOriginallyDetouched = isElementGlobal
      ? globals.verifiers.isElementDetached(currentElement)
      : null;

    segment.track(segment.constants.events.CONTEXT_MENU_ITEM_CLICKED, {
      siteId: site.accessors.getSiteId(),
      templateBuildSlug: template.accessors.getBuildSlug(),
      itemId,
      blockId: blocks.accessors.getBlockIdByElement(parentBlock),
      blockVariationId: blocks.accessors.getVariationIdByElement(parentBlock),
      targetElementType,
      wasGlobalOriginallyDetouched,
    });

    highlighter.operations.hide();
  };

  return (
    <React.Fragment>
      {group.items.map((item) => {
        if (item.menuNode) {
          return <React.Fragment key={item.id + item.caption}>{item.menuNode}</React.Fragment>;
        }

        return (
          <ContextMenuItem
            key={item.id + item.caption}
            id={item.id}
            element={group.element}
            caption={item.caption}
            glyph={item.glyph}
            contentRight={item.contentRight}
            disabled={item.disabled}
            onClick={item.onClick}
            active={item.active}
            onMouseEnter={onMouseEnterHandler}
            onMouseLeave={onMouseLeaveHandler}
            beforeItemClick={onBeforeItemClickHandler}
          />
        );
      })}
    </React.Fragment>
  );
}

ContextMenuGroupContainer.propTypes = {
  group: PropTypes.shape({
    name: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        caption: PropTypes.string,
        glyph: PropTypes.string,
        order: PropTypes.number,
        disabled: PropTypes.func,
        onClick: PropTypes.func,
        matches: PropTypes.func,
      })
    ),
    element: PropTypes.object.isRequired,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onBeforeItemClick: PropTypes.func,
  }).isRequired,
};

export default ContextMenuGroupContainer;
