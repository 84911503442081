import publishingJobStatuses from '../constants/publishing-job-statuses';
import constants from '../constants';
import publishingServiceClient from '../../publishing-service-client/service-client';

const pollGetPublishingJob = async ({
  jobId,
  getPollingCondition = (status) => status === publishingJobStatuses.IN_PROGRESS,
  onSuccess,
  onRequestFail,
}) => {
  try {
    const service = publishingServiceClient.get();
    const response = await service.getPublishingJob(jobId);
    const {
      data: { status },
    } = response;

    if (getPollingCondition(status)) {
      setTimeout(() => {
        pollGetPublishingJob({ jobId, getPollingCondition, onSuccess, onRequestFail });
      }, constants.POLLING_INTERVAL);
    } else {
      onSuccess(response);
    }
  } catch (error) {
    console.error(error);
    onRequestFail();
  }
};

export default pollGetPublishingJob;
