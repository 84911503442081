import React from 'react';
import PropTypes from 'prop-types';

class MediaBlockComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      containerWidth: 0,
      containerHeight: 0,
      x: 0,
      y: 0,
      contentWidth: 0,
      contentHeight: 0,
    };

    this.prepareStyles = this.prepareStyles.bind(this);
    this.setMediaBounds = this.setMediaBounds.bind(this);
  }

  componentDidMount() {
    const { mediaContainerRef } = this.props;
    mediaContainerRef.addEventListener('ws:resize', this.setMediaBounds);
    mediaContainerRef.renderMedia();
  }

  componentWillUnmount() {
    const { mediaContainerRef } = this.props;
    mediaContainerRef.removeEventListener('ws:resize', this.setMediaBounds);
  }

  setMediaBounds(event) {
    const {
      position: { x, y },
      contentWidth,
      contentHeight,
      containerWidth,
      containerHeight,
    } = event.detail;

    this.setState({
      x,
      y,
      contentWidth,
      contentHeight,
      containerWidth,
      containerHeight,
    });
  }

  prepareStyles() {
    const { x, y, contentWidth, contentHeight } = this.state;
    const { mediaSource } = this.props;

    return {
      position: 'absolute',
      left: `${x}%`,
      top: `${y}%`,
      width: `${contentWidth}%`,
      height: `${contentHeight}%`,
      transform: `translate(-50%, -50%)`,
      backgroundImage: `url(${mediaSource})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    };
  }

  render() {
    const {
      targetElementBounds: { left, top },
    } = this.props;
    const { containerWidth, containerHeight } = this.state;
    const containerStyles = {
      position: 'absolute',
      height: `${containerHeight}px`,
      width: `${containerWidth}px`,
      left: 0,
      top: 0,
      transform: `translate3d(${left}px, ${top}px, 0)`,
    };

    const mediaBlockStyles = this.prepareStyles();
    return (
      <div style={containerStyles}>
        <div style={mediaBlockStyles} />
      </div>
    );
  }
}

MediaBlockComponent.propTypes = {
  mediaContainerRef: PropTypes.shape({
    addEventListener: PropTypes.func.isRequired,
    removeEventListener: PropTypes.func.isRequired,
    renderMedia: PropTypes.func.isRequired,
  }).isRequired,
  targetElementBounds: PropTypes.shape({
    left: PropTypes.number.isRequired,
    top: PropTypes.number.isRequired,
  }).isRequired,
  mediaSource: PropTypes.string.isRequired,
};

export default MediaBlockComponent;
