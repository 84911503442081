import actionTypes from '../constants/action-types';

const triggerUpgrade = (upsellType, editorUpgradeAction, details) => ({
  type: actionTypes.TRIGGER_UPGRADE,
  payload: {
    upsellType,
    editorUpgradeAction,
    details,
  },
});

export default triggerUpgrade;
