import defaultSettings from '../constants/default-settings';

const {
  BACKGROUND_CONTAINER_TAG,
  MEDIA_CONTAINER_TAG,
  GALLERY_ITEM_TAG,
  BLOCK_WITH_BACKGROUND_TAG,
} = defaultSettings;

const settings = {
  imageContainer: MEDIA_CONTAINER_TAG,
  galleryItem: GALLERY_ITEM_TAG,
  backgroundImageNodeSelector: BLOCK_WITH_BACKGROUND_TAG,
  backgroundContainer: BACKGROUND_CONTAINER_TAG,
};

export const getSettings = () => settings;

export const setSetting = (name, value) => {
  settings[name] = value;
};

export default {
  getSettings,
  setSetting,
};
