import { site, template } from '@yola/ws-sdk';
import segment from 'src/js/modules/analytics/segment';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';

const trackBlockLayoutDialogCanceled = ({ blockId, blockVariationId }) => {
  if (!blockId) return;

  const { track, constants } = segment;

  track(constants.events.BLOCK_LAYOUT_DIALOG_CANCELED, {
    siteId: site.accessors.getSiteId(),
    templateBuildSlug: template.accessors.getBuildSlug(),
    dialogId: dialogTypes.BLOCK_SETTINGS,
    blockId,
    blockVariationId,
  });
};

export default trackBlockLayoutDialogCanceled;
