import { i18next } from '@yola/ws-sdk';

const hasControl = (slug, extensionControls) =>
  extensionControls.some((сontrol) => сontrol.extensionSlug === slug);

const getExtensionCaptions = (extensionControls) => {
  const { extensionSlug } = extensionControls[0];
  const hasGalleryControl = hasControl('ws-gallery', extensionControls);
  const hasSliderControl = hasControl('ws-slider', extensionControls);

  if (hasSliderControl) {
    return {
      id: 'Gallery slider',
      label: i18next.t('Gallery slider'),
    };
  }

  if (hasGalleryControl) {
    return {
      id: 'Gallery',
      label: i18next.t('Gallery'),
    };
  }

  // next fallbacks are needed for few cases:
  // 1. for image inside article or text, because it doesn't have corresponding optional child
  // 2. for elements, which corresponding optional child has different bounds
  // (for ex., icons inside `Business social profiles` optional child)
  switch (extensionSlug) {
    case 'ws-link': {
      return {
        id: 'Link',
        label: i18next.t('Link'),
      };
    }
    case 'ws-iframe': {
      return {
        id: 'Embed code',
        label: i18next.t('Embed code'),
      };
    }
    case 'ws-image': {
      return {
        id: 'Image',
        label: i18next.t('Image'),
      };
    }
    case 'ws-contact-form': {
      return {
        id: 'Contact form',
        label: i18next.t('Contact form'),
      };
    }
    case 'ws-icon': {
      return {
        id: 'Icon',
        label: i18next.t('Icon'),
      };
    }
    case 'ws-navigation': {
      return {
        id: 'Navigation',
        label: i18next.t('Navigation'),
      };
    }
    case 'ws-html-widget': {
      return {
        id: 'HTML code',
        label: i18next.t('HTML code'),
      };
    }
    case 'ws-google-map-embed': {
      return {
        id: 'Google map',
        label: i18next.t('Google map'),
      };
    }
    case 'ws-social-links': {
      return {
        id: 'Social links',
        label: i18next.t('Social links'),
      };
    }
    case 'ws-online-store': {
      return {
        id: 'Online Store',
        label: i18next.t('Online Store'),
      };
    }
    default: {
      return { id: '', label: '' };
    }
  }
};

export default getExtensionCaptions;
