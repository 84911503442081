import { uuid } from '@yola/ws-sdk';
import actionTypes from '../constants/action-types';

function registeredTools(state = [], action) {
  if (action.type !== actionTypes.REGISTER_TOOL) {
    return state;
  }

  return [
    ...state,
    {
      id: uuid(),
      ...action.payload.tool,
    },
  ];
}

export default registeredTools;
