import React from 'react';
import PropTypes from 'prop-types';
import { SubmitDialog } from '@yola/ws-ui';
import { i18next } from '@yola/ws-sdk';
import segment from 'src/js/modules/analytics/segment';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';

const {
  helpers: { getDefaultTraits },
} = segment;

const getCaptions = () => ({
  title: i18next.t('Deleting E-mail field'),
  description: i18next.t(
    "Deleting the E-mail field means you won't collect customer emails or be able to respond them by e-mail. Are you sure you want to continue?"
  ),
  submit: i18next.t('Delete'),
  cancel: i18next.t('Cancel'),
});

function DeleteEmailDialog(props) {
  const { onDialogMainAction, onDialogCancel, onSubmit, onCancel, elementId } = props;
  const traits = getDefaultTraits(elementId);
  const captions = getCaptions();

  return (
    <SubmitDialog
      onDialogMainAction={() =>
        onDialogMainAction({ dialogType: dialogTypes.CONTACT_FORM_DELETE_EMAIL_DIALOG, ...traits })
      }
      onDialogCancel={() =>
        onDialogCancel({ dialogType: dialogTypes.CONTACT_FORM_DELETE_EMAIL_DIALOG, ...traits })
      }
      captions={captions}
      onSubmit={onSubmit}
      onCancel={onCancel}
      width={338}
      height={250}
    />
  );
}

DeleteEmailDialog.propTypes = {
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  elementId: PropTypes.string.isRequired,
};

export default DeleteEmailDialog;
