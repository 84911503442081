import Vector from './vector';

function EulerMass(x, y, mass, drag) {
  this.position = new Vector(x, y);
  this.force = new Vector(0, 0);
  this.velocity = new Vector(0, 0);

  this.mass = mass;
  this.drag = drag;

  this.addForce = (value) => this.force.add(value);

  this.integrate = (value) => {
    this.multiplyPosition(value);
    this.updateVelocity(value);
    this.force = new Vector(0, 0);
  };

  this.multiplyPosition = (value) => {
    const positionDelta = new Vector(this.velocity.x, this.velocity.y);
    positionDelta.multiply(value);
    this.position.add(positionDelta);
  };

  this.updateVelocity = (value) => {
    const force = this.currentForce(this.position);
    force.divide(this.mass);
    force.multiply(value);
    this.velocity.add(force);
  };

  this.currentForce = () => {
    const force = new Vector(this.force.x, this.force.y);
    const dragVelocity = new Vector(this.velocity.x, this.velocity.y);

    dragVelocity.multiply(this.drag * this.mass * this.velocity.length());
    force.subtract(dragVelocity);

    return force;
  };
}

export default EulerMass;
