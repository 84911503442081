import React from 'react';

function AboutUsPreview() {
  return (
    <svg viewBox="0 0 1920 1870" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="1920" height="1870" fill="white" />
      <rect width="1920" height="120" fill="white" />
      <rect opacity="0.8" x="375" y="44" width="100" height="32" rx="8" fill="#DADDE0" />
      <rect x="993" y="48" width="100" height="24" rx="8" fill="#B0B8BF" />
      <rect opacity="0.8" x="1125" y="48" width="100" height="24" rx="8" fill="#DADDE0" />
      <rect opacity="0.8" x="1257" y="48" width="100" height="24" rx="8" fill="#DADDE0" />
      <rect x="1405" y="32" width="140" height="56" rx="8" fill="#8E959E" />
      <rect opacity="0.4" x="1433" y="52" width="84" height="16" rx="8" fill="white" />
      <rect width="1920" height="760" transform="matrix(1 0 0 -1 0 880)" fill="white" />
      <path
        opacity="0.8"
        d="M0 200H909C917.837 200 925 207.163 925 216V704C925 712.837 917.837 720 909 720H0V200Z"
        fill="#B0B8BF"
      />
      <g opacity="0.8">
        <path
          d="M516.334 477.778L493.775 455.219C489.87 451.314 483.538 451.314 479.633 455.219L421.519 513.333M423 513.333H503C510.364 513.333 516.334 507.364 516.334 500V420C516.334 412.636 510.364 406.667 503 406.667H423C415.637 406.667 409.667 412.636 409.667 420V500C409.667 507.364 415.637 513.333 423 513.333ZM442.26 448.148C447.169 448.148 451.148 444.168 451.148 439.259C451.148 434.35 447.169 430.37 442.26 430.37C437.35 430.37 433.371 434.35 433.371 439.259C433.371 444.168 437.35 448.148 442.26 448.148Z"
          stroke="white"
          strokeWidth="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M516.334 477.778L493.775 455.219C489.87 451.314 483.538 451.314 479.633 455.219L421.519 513.333M423 513.333H503C510.364 513.333 516.334 507.364 516.334 500V420C516.334 412.636 510.364 406.667 503 406.667H423C415.637 406.667 409.667 412.636 409.667 420V500C409.667 507.364 415.637 513.333 423 513.333ZM442.26 448.148C447.169 448.148 451.148 444.168 451.148 439.259C451.148 434.35 447.169 430.37 442.26 430.37C437.35 430.37 433.371 434.35 433.371 439.259C433.371 444.168 437.35 448.148 442.26 448.148Z"
          stroke="white"
          strokeWidth="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect x="995" y="320" width="476" height="48" rx="8" fill="#B0B8BF" />
      <rect x="995" y="384" width="265" height="48" rx="8" fill="#B0B8BF" />
      <rect opacity="0.8" x="995" y="488" width="530" height="24" rx="8" fill="#DADDE0" />
      <rect opacity="0.8" x="995" y="532" width="530" height="24" rx="8" fill="#DADDE0" />
      <rect opacity="0.8" x="995" y="576" width="433" height="24" rx="8" fill="#DADDE0" />
      <rect width="1920" height="870" transform="translate(0 880)" fill="white" />
      <rect x="761" y="880" width="398" height="48" rx="8" fill="#B0B8BF" />
      <rect opacity="0.8" x="649" y="968" width="622" height="24" rx="8" fill="#DADDE0" />
      <rect opacity="0.8" x="743.5" y="1012" width="433" height="24" rx="8" fill="#DADDE0" />
      <circle opacity="0.8" cx="510" cy="1235" r="135" fill="#B0B8BF" />
      <g opacity="0.8">
        <path
          d="M543.334 1246.11L531.886 1234.66C527.981 1230.76 521.649 1230.76 517.744 1234.66L484.074 1268.33M485 1268.33H535C539.603 1268.33 543.334 1264.6 543.334 1260V1210C543.334 1205.4 539.603 1201.67 535 1201.67H485C480.398 1201.67 476.667 1205.4 476.667 1210V1260C476.667 1264.6 480.398 1268.33 485 1268.33ZM497.037 1227.59C500.106 1227.59 502.593 1225.11 502.593 1222.04C502.593 1218.97 500.106 1216.48 497.037 1216.48C493.969 1216.48 491.482 1218.97 491.482 1222.04C491.482 1225.11 493.969 1227.59 497.037 1227.59Z"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M543.334 1246.11L531.886 1234.66C527.981 1230.76 521.649 1230.76 517.744 1234.66L484.074 1268.33M485 1268.33H535C539.603 1268.33 543.334 1264.6 543.334 1260V1210C543.334 1205.4 539.603 1201.67 535 1201.67H485C480.398 1201.67 476.667 1205.4 476.667 1210V1260C476.667 1264.6 480.398 1268.33 485 1268.33ZM497.037 1227.59C500.106 1227.59 502.593 1225.11 502.593 1222.04C502.593 1218.97 500.106 1216.48 497.037 1216.48C493.969 1216.48 491.482 1218.97 491.482 1222.04C491.482 1225.11 493.969 1227.59 497.037 1227.59Z"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect x="375" y="1410" width="148" height="32" rx="8" fill="#B0B8BF" />
      <rect opacity="0.8" x="375" y="1462" width="261" height="24" rx="8" fill="#DADDE0" />
      <rect opacity="0.8" x="375" y="1502" width="171" height="24" rx="8" fill="#DADDE0" />
      <rect x="375" y="1566" width="24" height="24" rx="8" fill="#B0B8BF" />
      <rect x="415" y="1566" width="24" height="24" rx="8" fill="#B0B8BF" />
      <circle opacity="0.8" cx="810" cy="1235" r="135" fill="#B0B8BF" />
      <g opacity="0.8">
        <path
          d="M843.334 1246.11L831.886 1234.66C827.981 1230.76 821.649 1230.76 817.744 1234.66L784.074 1268.33M785 1268.33H835C839.603 1268.33 843.334 1264.6 843.334 1260V1210C843.334 1205.4 839.603 1201.67 835 1201.67H785C780.398 1201.67 776.667 1205.4 776.667 1210V1260C776.667 1264.6 780.398 1268.33 785 1268.33ZM797.037 1227.59C800.106 1227.59 802.593 1225.11 802.593 1222.04C802.593 1218.97 800.106 1216.48 797.037 1216.48C793.969 1216.48 791.482 1218.97 791.482 1222.04C791.482 1225.11 793.969 1227.59 797.037 1227.59Z"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M843.334 1246.11L831.886 1234.66C827.981 1230.76 821.649 1230.76 817.744 1234.66L784.074 1268.33M785 1268.33H835C839.603 1268.33 843.334 1264.6 843.334 1260V1210C843.334 1205.4 839.603 1201.67 835 1201.67H785C780.398 1201.67 776.667 1205.4 776.667 1210V1260C776.667 1264.6 780.398 1268.33 785 1268.33ZM797.037 1227.59C800.106 1227.59 802.593 1225.11 802.593 1222.04C802.593 1218.97 800.106 1216.48 797.037 1216.48C793.969 1216.48 791.482 1218.97 791.482 1222.04C791.482 1225.11 793.969 1227.59 797.037 1227.59Z"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect x="675" y="1410" width="148" height="32" rx="8" fill="#B0B8BF" />
      <rect opacity="0.8" x="675" y="1462" width="261" height="24" rx="8" fill="#DADDE0" />
      <rect opacity="0.8" x="675" y="1502" width="171" height="24" rx="8" fill="#DADDE0" />
      <rect x="675" y="1566" width="24" height="24" rx="8" fill="#B0B8BF" />
      <rect x="715" y="1566" width="24" height="24" rx="8" fill="#B0B8BF" />
      <circle opacity="0.8" cx="1110" cy="1235" r="135" fill="#B0B8BF" />
      <g opacity="0.8">
        <path
          d="M1143.33 1246.11L1131.89 1234.66C1127.98 1230.76 1121.65 1230.76 1117.74 1234.66L1084.07 1268.33M1085 1268.33H1135C1139.6 1268.33 1143.33 1264.6 1143.33 1260V1210C1143.33 1205.4 1139.6 1201.67 1135 1201.67H1085C1080.4 1201.67 1076.67 1205.4 1076.67 1210V1260C1076.67 1264.6 1080.4 1268.33 1085 1268.33ZM1097.04 1227.59C1100.11 1227.59 1102.59 1225.11 1102.59 1222.04C1102.59 1218.97 1100.11 1216.48 1097.04 1216.48C1093.97 1216.48 1091.48 1218.97 1091.48 1222.04C1091.48 1225.11 1093.97 1227.59 1097.04 1227.59Z"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1143.33 1246.11L1131.89 1234.66C1127.98 1230.76 1121.65 1230.76 1117.74 1234.66L1084.07 1268.33M1085 1268.33H1135C1139.6 1268.33 1143.33 1264.6 1143.33 1260V1210C1143.33 1205.4 1139.6 1201.67 1135 1201.67H1085C1080.4 1201.67 1076.67 1205.4 1076.67 1210V1260C1076.67 1264.6 1080.4 1268.33 1085 1268.33ZM1097.04 1227.59C1100.11 1227.59 1102.59 1225.11 1102.59 1222.04C1102.59 1218.97 1100.11 1216.48 1097.04 1216.48C1093.97 1216.48 1091.48 1218.97 1091.48 1222.04C1091.48 1225.11 1093.97 1227.59 1097.04 1227.59Z"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect x="975" y="1410" width="148" height="32" rx="8" fill="#B0B8BF" />
      <rect opacity="0.8" x="975" y="1462" width="261" height="24" rx="8" fill="#DADDE0" />
      <rect opacity="0.8" x="975" y="1502" width="171" height="24" rx="8" fill="#DADDE0" />
      <rect x="975" y="1566" width="24" height="24" rx="8" fill="#B0B8BF" />
      <rect x="1015" y="1566" width="24" height="24" rx="8" fill="#B0B8BF" />
      <circle opacity="0.8" cx="1410" cy="1235" r="135" fill="#B0B8BF" />
      <g opacity="0.8">
        <path
          d="M1443.33 1246.11L1431.89 1234.66C1427.98 1230.76 1421.65 1230.76 1417.74 1234.66L1384.07 1268.33M1385 1268.33H1435C1439.6 1268.33 1443.33 1264.6 1443.33 1260V1210C1443.33 1205.4 1439.6 1201.67 1435 1201.67H1385C1380.4 1201.67 1376.67 1205.4 1376.67 1210V1260C1376.67 1264.6 1380.4 1268.33 1385 1268.33ZM1397.04 1227.59C1400.1 1227.59 1402.59 1225.11 1402.59 1222.04C1402.59 1218.97 1400.1 1216.48 1397.04 1216.48C1393.97 1216.48 1391.48 1218.97 1391.48 1222.04C1391.48 1225.11 1393.97 1227.59 1397.04 1227.59Z"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1443.33 1246.11L1431.89 1234.66C1427.98 1230.76 1421.65 1230.76 1417.74 1234.66L1384.07 1268.33M1385 1268.33H1435C1439.6 1268.33 1443.33 1264.6 1443.33 1260V1210C1443.33 1205.4 1439.6 1201.67 1435 1201.67H1385C1380.4 1201.67 1376.67 1205.4 1376.67 1210V1260C1376.67 1264.6 1380.4 1268.33 1385 1268.33ZM1397.04 1227.59C1400.1 1227.59 1402.59 1225.11 1402.59 1222.04C1402.59 1218.97 1400.1 1216.48 1397.04 1216.48C1393.97 1216.48 1391.48 1218.97 1391.48 1222.04C1391.48 1225.11 1393.97 1227.59 1397.04 1227.59Z"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect x="1275" y="1410" width="148" height="32" rx="8" fill="#B0B8BF" />
      <rect opacity="0.8" x="1275" y="1462" width="261" height="24" rx="8" fill="#DADDE0" />
      <rect opacity="0.8" x="1275" y="1502" width="171" height="24" rx="8" fill="#DADDE0" />
      <rect x="1275" y="1566" width="24" height="24" rx="8" fill="#B0B8BF" />
      <rect x="1315" y="1566" width="24" height="24" rx="8" fill="#B0B8BF" />
      <rect width="1920" height="120" transform="translate(0 1750)" fill="#646C75" />
      <g opacity="0.3">
        <rect x="770" y="1798" width="100" height="24" rx="8" fill="white" />
        <rect x="910" y="1798" width="100" height="24" rx="8" fill="white" />
        <rect x="1050" y="1798" width="100" height="24" rx="8" fill="white" />
      </g>
    </svg>
  );
}

export default AboutUsPreview;
