import * as Sentry from '@sentry/browser';
import get from 'lodash.get';

let sentryIsInitialized = false;
const ignoreErrors = ['ResizeObserver loop limit exceeded'];
const releasePlaceholder = 'BUILD_NUMBER_PLACEHOLDER';

const init = (dsn) => {
  const config = {
    dsn,

    // Session replay configuration
    replaysSessionSampleRate: 0.03,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    beforeSend(event) {
      const errors = get(event, 'exception.values');

      if (errors && errors.length) {
        const isIgnoredError = ignoreErrors.some((ignoreError) =>
          errors.some((error) => error.value.includes(ignoreError))
        );

        if (isIgnoredError) return null;
      }

      return event;
    },
  };

  // eslint-disable-next-line no-underscore-dangle
  const release = window.__WS__BUILD_NUMBER;
  if (release !== releasePlaceholder) {
    config.release = release;
  }

  Sentry.init(config);
  sentryIsInitialized = true;
};
const isInit = () => sentryIsInitialized;

const setSiteId = (siteId) => {
  if (siteId) {
    Sentry.configureScope((scope) => {
      scope.setTag('site-id', siteId);
      // eslint-disable-next-line no-console
      console.info(`Sentry set site ID: ${siteId}`);
    });
  }
};

const setUserId = (id) => {
  if (id) {
    Sentry.configureScope((scope) => {
      scope.setUser({ id });
      // eslint-disable-next-line no-console
      console.info(`Sentry set user ID: ${id}`);
    });
  }
};

const getInstance = () => Sentry;

export default {
  init,
  getInstance,
  setSiteId,
  setUserId,
  isInit,
};
