import { blocks } from '@yola/ws-sdk';
import { BLOCK_SETTINGS_APPEARANCE_TAB_ID } from 'src/js/modules/blocks/constants/common';

const ONLINE_STORE_BLOCK_ID = 'online-store';

const getActiveTabId = ({ blockId, tabId, itemsWithDependencies }) => {
  const blockSettings = blocks.accessors.getBlockSettings(blockId);
  const hasEnoughSettings =
    Boolean(blockSettings.displayOptions?.length) &&
    Boolean(blockSettings.optionalChildren?.length);

  return itemsWithDependencies.length < 2 && !hasEnoughSettings && blockId !== ONLINE_STORE_BLOCK_ID
    ? BLOCK_SETTINGS_APPEARANCE_TAB_ID
    : tabId;
};

export default getActiveTabId;
