import errorModule from 'yola-editor/src/js/modules/error';
import wlproxyClient from 'yola-editor/src/js/modules/wlproxy-client/service-client';
import i18n from 'yola-editor/src/js/modules/i18n';
import productTypes from '../constants/product-types';
import setUpgradeOptions from '../actions/set-upgrade-options';

const fetchUpgradeOptions = (siteId) => (dispatch, getState) => {
  const state = getState();
  const language = i18n.selectors.getLocale(state);
  const serviceClient = wlproxyClient.get();

  const getUpgradeOptionForProduct = (productType) =>
    serviceClient.listProducts({
      language,
      siteId,
      details: { productType },
    });

  return Promise.all([
    getUpgradeOptionForProduct(productTypes.LATITUDE),
    getUpgradeOptionForProduct(productTypes.ONLINE_STORE),
  ])
    .then(([{ data: latitudeOption }, { data: onlineStoreOption }]) => {
      const upgradeOptions = {
        [productTypes.LATITUDE]: latitudeOption,
        [productTypes.ONLINE_STORE]: onlineStoreOption,
      };

      dispatch(setUpgradeOptions(upgradeOptions));
    })
    .catch((error) => {
      dispatch(
        errorModule.actions.setCriticalError({
          error,
          logSentry: true,
        })
      );
    });
};

export default fetchUpgradeOptions;
