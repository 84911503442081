import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import TabContentBox from './tab-content-box';

const { ControlGroup, Checkbox } = designSystem;

function EffectsSection(props) {
  const { captions, isWebsiteAnimationChecked, onWebsiteAnimationChange } = props;
  const { websiteAnimation } = captions;

  return (
    <div className="ws-effects-section">
      <TabContentBox>
        <ControlGroup title={websiteAnimation.title} description={websiteAnimation.description}>
          <Checkbox
            id="enableWebsiteAnimation"
            label={websiteAnimation.label}
            checked={isWebsiteAnimationChecked}
            onChange={onWebsiteAnimationChange}
            value="website-animation"
          />
        </ControlGroup>
      </TabContentBox>
    </div>
  );
}

EffectsSection.propTypes = {
  captions: PropTypes.shape({
    websiteAnimation: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      label: PropTypes.string,
    }),
  }).isRequired,
  isWebsiteAnimationChecked: PropTypes.bool.isRequired,
  onWebsiteAnimationChange: PropTypes.func.isRequired,
};

export default EffectsSection;
