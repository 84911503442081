import { errors } from '@yola/ws-sdk';

const catchReduxError = (error, getState, lastAction) => {
  errors.operations.catchError(error, {
    errorInfo: {
      action: lastAction,
    },
  });
};

export default catchReduxError;
