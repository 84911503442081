import React from 'react';
import PropTypes from 'prop-types';
import { designSystem, Field } from '@yola/ws-ui';
import utils from '../../utils';
import settingTypes from '../constants/setting-types';
import settingLengthLimits from '../constants/setting-length-limits';
import validateString from '../helpers/validate-string';
import InputGroupField from '../../common/components/input-group-field';
import TextAreaField from '../../common/components/textarea-field';
import BusinessCategoryField from './business-category-field';

const { HeadingGroup, ControlGroup, Checkbox, FieldStatus, Box, Stack, AnimatedBanner } =
  designSystem;
const { MIN_BUSINESS_DESCRIPTION_CHARS, MAX_BUSINESS_DESCRIPTION_CHARS } = settingLengthLimits;

const BusinessInfoSection = (props) => {
  const {
    captions,
    formData,
    isBusinessTaxonomyEnabled,
    isBusinessNameSameAsSiteName,
    setIsBusinessNameSameAsSiteName,
    setFieldValue,
    addressesRef,
    phonesRef,
    emailsRef,
    businessHoursRef,
    areAiFeaturesAvailable,
    aiTutorialUrl,
    onAiBannerClick,
  } = props;

  const siteName = formData[settingTypes.SITE_NAME];

  const validateBusinessDescription = validateString(
    { minLength: MIN_BUSINESS_DESCRIPTION_CHARS, maxLength: MAX_BUSINESS_DESCRIPTION_CHARS },
    captions.errorMessages.businessDescription
  );
  const validatePhone = utils.validators.createPhoneValidator(captions.errorMessages.phone);
  const validateEmail = utils.validators.createEmailValidator(captions.errorMessages.email);

  const handleBusinessNameChange = (value) => {
    const trimmedValue = value.trim();

    if (trimmedValue !== siteName) {
      setIsBusinessNameSameAsSiteName(false);
    }
  };

  const handleBusinessNameBlur = (value) => {
    const trimmedValue = value.trim();

    if (!trimmedValue) {
      setFieldValue(settingTypes.BUSINESS_NAME, siteName);
      setIsBusinessNameSameAsSiteName(true);
    }
  };

  const handleBusinessNameAsSiteNameChange = (value) => {
    if (value) {
      setFieldValue(settingTypes.BUSINESS_NAME, siteName);
    }

    setIsBusinessNameSameAsSiteName(value);
  };

  return (
    <Stack gap="spacing-m">
      {areAiFeaturesAvailable && (
        <AnimatedBanner
          appearance="accent"
          title={captions.aiBanner.title}
          description={captions.aiBanner.description}
          actionButtonLabel={captions.aiBanner.buttonLabel}
          isBetaLabelShown
          onActionButtonClick={onAiBannerClick}
          actionButtonHref={aiTutorialUrl}
        />
      )}
      <HeadingGroup title={captions.profileSection.title} />
      <ControlGroup title={captions.businessName.name}>
        <Field
          component={InputGroupField}
          name={settingTypes.BUSINESS_NAME}
          placeholder={siteName}
          size="medium"
          onChange={handleBusinessNameChange}
          onBlur={handleBusinessNameBlur}
        />
        <Box marginTop="spacing-2-xs">
          <Checkbox
            id={settingTypes.BUSINESS_NAME}
            label={captions.businessName.checkboxLabel}
            checked={isBusinessNameSameAsSiteName}
            onChange={handleBusinessNameAsSiteNameChange}
            value={settingTypes.BUSINESS_NAME}
          />
        </Box>
      </ControlGroup>
      {isBusinessTaxonomyEnabled && (
        <ControlGroup title={captions.businessCategory.title}>
          <BusinessCategoryField
            name={settingTypes.BUSINESS_CATEGORY}
            placeholder={captions.businessCategory.placeholder}
            noResultsText={captions.businessCategory.noResultsText}
            errorMessage={captions.errorMessages.businessCategory}
            setFieldValue={setFieldValue}
            fieldValue={formData[settingTypes.BUSINESS_CATEGORY]}
          />
        </ControlGroup>
      )}
      <ControlGroup title={captions.businessDescription.name}>
        <div className="ws-text-area-with-length-annotation-field">
          <Field
            name={settingTypes.BUSINESS_DESCRIPTION}
            validate={validateBusinessDescription}
            render={({ field, form }) => {
              const { errors, touched } = form;
              const touchedField = touched[settingTypes.BUSINESS_DESCRIPTION];
              const errorMessage = errors[settingTypes.BUSINESS_DESCRIPTION];
              const invalid = !!(errorMessage && touchedField);
              const fieldStatusAppearance = invalid ? 'error' : 'default';
              const elementAfter = (
                <div className="ws-text-area-with-length-annotation-field__info">
                  <FieldStatus
                    text={errorMessage || captions.businessDescription.description}
                    appearance={fieldStatusAppearance}
                  />
                  <FieldStatus
                    text={`${field.value.length}/${MAX_BUSINESS_DESCRIPTION_CHARS}`}
                    appearance={fieldStatusAppearance}
                    align="right"
                  />
                </div>
              );

              return (
                <TextAreaField
                  resize
                  field={field}
                  form={form}
                  placeholder={captions.businessDescription.placeholder}
                  elementAfter={elementAfter}
                />
              );
            }}
          />
        </div>
      </ControlGroup>

      <HeadingGroup
        title={captions.contactInfoSection.title}
        description={captions.contactInfoSection.description}
      />
      <div ref={addressesRef}>
        <ControlGroup title={captions.address.title}>
          <Field
            component={InputGroupField}
            name={settingTypes.ADDRESSES}
            placeholder={captions.address.placeholder}
            iconGlyph="map-marker"
            size="medium"
          />
        </ControlGroup>
      </div>
      <div ref={phonesRef}>
        <ControlGroup title={captions.phone.title}>
          <Field
            component={InputGroupField}
            name={settingTypes.PHONES}
            placeholder={captions.phone.placeholder}
            iconGlyph="phone"
            size="medium"
            validate={validatePhone}
          />
        </ControlGroup>
      </div>
      <div ref={emailsRef}>
        <ControlGroup title={captions.email.title}>
          <Field
            component={InputGroupField}
            name={settingTypes.EMAILS}
            placeholder={captions.email.placeholder}
            iconGlyph="mail"
            size="medium"
            validate={validateEmail}
          />
        </ControlGroup>
      </div>
      <div ref={businessHoursRef}>
        <ControlGroup title={captions.businessHours.title}>
          <Field
            component={InputGroupField}
            name={settingTypes.BUSINESS_HOURS}
            placeholder={captions.businessHours.placeholder}
            iconGlyph="clock"
            size="medium"
          />
        </ControlGroup>
      </div>
    </Stack>
  );
};

BusinessInfoSection.propTypes = {
  captions: PropTypes.shape().isRequired,
  formData: PropTypes.shape().isRequired,
  isBusinessNameSameAsSiteName: PropTypes.bool.isRequired,
  isBusinessTaxonomyEnabled: PropTypes.bool.isRequired,
  setIsBusinessNameSameAsSiteName: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  phonesRef: PropTypes.shape().isRequired,
  addressesRef: PropTypes.shape().isRequired,
  emailsRef: PropTypes.shape().isRequired,
  businessHoursRef: PropTypes.shape().isRequired,
  areAiFeaturesAvailable: PropTypes.bool.isRequired,
  aiTutorialUrl: PropTypes.string.isRequired,
  onAiBannerClick: PropTypes.func.isRequired,
};

export default BusinessInfoSection;
