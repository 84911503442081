import { contentEditable } from '@yola/ws-sdk';

const setSelectionByNodePath = (element, selectionData) => {
  element.setAttribute('contenteditable', true);
  element.focus();

  contentEditable.helpers.setSelectionByNodePath(element, selectionData);
};

export default setSelectionByNodePath;
