import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { designSystem } from '@yola/ws-ui';
import bowser from 'yola-bowser';
import helpers from '../helpers';

const { Stack, Paragraph, Tag } = designSystem;

const FieldTypesItem = ({
  item,
  isB2C,
  formContainsAttachFileField,
  isFileAttachmetnFeatureAvailable,
  onSelect,
}) => {
  const { type, label, IconComponent, tag, isVoteForFeatureEnabled, isPremiumFeature } = item;

  const isMobile = bowser.mobile;
  const iconWrapperClasses = classNames('ws-contact-form-field-types-item__icon-wrapper', {
    'ws-contact-form-field-types-item__icon-wrapper--mobile': isMobile,
  });

  const fieldTypes = helpers.getFieldTypes();
  const { attachfile } = fieldTypes;

  const handleClick = () => onSelect(item);

  const getTag = () => {
    if (isVoteForFeatureEnabled) return <Tag label={tag} size="small" />;

    if (isPremiumFeature) {
      if (type === attachfile && isFileAttachmetnFeatureAvailable) return null;

      return <Tag iconGlyph="star" appearance="cta" size="small" label="" />;
    }

    return null;
  };

  if (isVoteForFeatureEnabled && !isB2C) return null;
  if (type === attachfile && formContainsAttachFileField) return null;

  return (
    <div className="ws-contact-form-field-types-item" onClick={handleClick}>
      <Stack gap="spacing-4-xs">
        <div className={iconWrapperClasses}>
          <IconComponent />
        </div>
        <Paragraph size="small" align="center" isClipped>
          {label}
        </Paragraph>
      </Stack>
      <span className="ws-contact-form-field-types-item__tag-wrapper">{getTag()}</span>
    </div>
  );
};

FieldTypesItem.propTypes = {
  item: PropTypes.shape({
    type: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    IconComponent: PropTypes.elementType.isRequired,
    tag: PropTypes.string,
    isVoteForFeatureEnabled: PropTypes.bool,
    isPremiumFeature: PropTypes.bool,
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
  isB2C: PropTypes.bool.isRequired,
  formContainsAttachFileField: PropTypes.bool,
  isFileAttachmetnFeatureAvailable: PropTypes.bool,
};

FieldTypesItem.defaultProps = {
  formContainsAttachFileField: false,
  isFileAttachmetnFeatureAvailable: false,
};

export default FieldTypesItem;
