import React from 'react';
import classNames from 'classnames';
import { designSystem } from '@yola/ws-ui';
import PropTypes from 'prop-types';
import LanguageItem from './language-item';

const { Stack, ControlGroup, ActionButton, AnimatedBanner } = designSystem;

function MultilingualSection({
  usedLanguages,
  isMobile,
  deleteLang,
  setMainLang,
  mainLocale,
  addLanguage,
  captions,
  hasAbilityToAddLocale,
  areAiFeaturesAvailable,
  tutorialUrl,
}) {
  const listClassName = classNames('ws-multilingual__languages', {
    'ws-multilingual__languages__single': usedLanguages.length <= 1,
  });

  const handleAddLanguage = () => {
    addLanguage();
  };

  const handleDeleteLanguage = (lang) => {
    deleteLang(lang);
  };

  return (
    <Stack gap="spacing-m">
      {areAiFeaturesAvailable && (
        <AnimatedBanner
          appearance="accent"
          title={captions.banner.title}
          description={captions.banner.description}
          actionButtonLabel={captions.banner.buttonLabel}
          isBetaLabelShown
          actionButtonHref={tutorialUrl}
          onActionButtonClick={Function.prototype}
        />
      )}
      <Stack gap="spacing-xs">
        <ControlGroup title={captions.title} description={captions.description} />
        <div className={listClassName}>
          {usedLanguages.map((lang) => (
            <LanguageItem
              {...lang}
              key={lang.code}
              isMobile={isMobile}
              deleteLang={handleDeleteLanguage}
              setMainLang={setMainLang}
              isMain={mainLocale === lang.code}
              captions={captions}
            />
          ))}
        </div>
        {hasAbilityToAddLocale && (
          <div>
            <ActionButton label={captions.addButton} onClick={handleAddLanguage} />
          </div>
        )}
      </Stack>
    </Stack>
  );
}

MultilingualSection.propTypes = {
  usedLanguages: PropTypes.array.isRequired,
  isMobile: PropTypes.bool.isRequired,
  deleteLang: PropTypes.func.isRequired,
  setMainLang: PropTypes.func.isRequired,
  mainLocale: PropTypes.string.isRequired,
  addLanguage: PropTypes.func.isRequired,
  captions: PropTypes.shape().isRequired,
  hasAbilityToAddLocale: PropTypes.bool.isRequired,
  areAiFeaturesAvailable: PropTypes.bool,
  tutorialUrl: PropTypes.string.isRequired,
};

MultilingualSection.defaultProps = {
  areAiFeaturesAvailable: false,
};

export default MultilingualSection;
