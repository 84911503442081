import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Spring } from 'react-spring/renderprops';
import { utils, i18next } from '@yola/ws-sdk';
import { Render, Tooltip, designSystem } from '@yola/ws-ui';
import ControlPaneBody from './control-pane-body';
import ControlPaneHeader from './control-pane-header';
import ControlPaneTriggers from './control-pane-triggers';
import Trigger from '../../common/components/trigger';
import makePages from '../helpers/make-pages';
import getSortedContent from '../helpers/get-sorted-content';
import { FADE_ANIMATION } from '../constants/animations';
import { PANE_TRIGGER_SIZE } from '../constants/sizes';
import deriveItemsWidth from '../helpers/derive-items-width';

const { Icon } = designSystem;

class ControlPane extends React.PureComponent {
  static extractItemState(item) {
    if (!item || !item.state) {
      return null;
    }

    return item.state;
  }

  constructor(props) {
    super(props);

    const { content, initPage } = props;
    const pages = makePages(props);
    this.state = {
      active: null,
      bodyHeight: null,
      beforeClose: false,
      page: initPage,
      content: getSortedContent(content),
    };

    this.pageContent = pages[initPage].items;
    this.defaultTriggerWidth = PANE_TRIGGER_SIZE;
    this.editorKey = new Date().getTime();
    this.headerRefs = [];
    this.bodyRefs = [];
    this.bindMethods();
  }

  componentDidMount() {
    const { documents } = this.props;

    documents.forEach((document) => {
      document.addEventListener('mousedown', this.instantCloseEditor);
    });

    Tooltip.rebuild();
  }

  componentDidUpdate() {
    Tooltip.rebuild();
  }

  componentWillUnmount() {
    const { documents, onActiveStateChange } = this.props;

    documents.forEach((document) => {
      document.removeEventListener('mousedown', this.instantCloseEditor);
    });

    onActiveStateChange(null);
    clearTimeout(this.bodyTimeout);
    clearTimeout(this.closeTimeout);
  }

  onCollapseListItemClick(index, triggerRef) {
    const { onTriggerActionStart } = this.props;

    const activeItem = this.getItemByIndex(index);
    onTriggerActionStart(activeItem);

    if (activeItem && activeItem.nextTrigger) {
      Tooltip.hide();
      this.nextPage();
      return;
    }

    if (activeItem && activeItem.prevTrigger) {
      Tooltip.hide();
      this.prevPage();
      return;
    }

    if (activeItem && activeItem.onTriggerClick) {
      activeItem.onTriggerClick(triggerRef);
    } else {
      if (!activeItem || !activeItem.header) {
        return;
      }

      const { onActiveStateChange } = this.props;

      onActiveStateChange(index);

      this.editorKey = new Date().getTime();

      this.setState({
        active: index,
        bodyHeight: null,
      });
    }
  }

  onMouseEnter(index) {
    const activeItem = this.getItemByIndex(index);

    if (activeItem && activeItem.onHover) {
      activeItem.onHover();
    }
  }

  onBeforeClose(options = {}) {
    const { checkBlurState } = options;
    const animationTime = 250;

    this.closeTimeout = setTimeout(() => {
      this.editorKey = new Date().getTime();

      this.executeItemCallback(checkBlurState);

      this.setState({
        active: null,
        beforeClose: false,
        renderBody: false,
      });

      clearTimeout(this.closeTimeout);
    }, animationTime);
  }

  getItemByIndex(index) {
    const { content } = this.state;
    const res = content.find((item) => item.index === index);
    if (!res) return this.pageContent.find((item) => item.index === index);

    return res;
  }

  getStyles(pageContent) {
    const { offsetX, offsetY, direction, expand } = this.props;
    const { beforeClose, bodyHeight, renderBody, active } = this.state;
    const translate = this.calcTranslateByDirection();

    const startStyles = {
      left: offsetX,
      top: offsetY,
      opacity: 0,
      width: this.calcEditorWidth(pageContent),
      translateX: translate.translateX,
      translateY: translate.translateY,
    };

    const endStyles = {
      left: offsetX,
      opacity: 1,
      width: this.calcEditorWidth(pageContent),
      translateX: translate.translateX,
      translateY: translate.translateY,
    };

    if (expand === 'middle' && active !== null) {
      const activeItem = this.getItemByIndex(active);
      const height = bodyHeight || activeItem.height;

      if (beforeClose) {
        startStyles.translateY = (height / 2) * -1;
        endStyles.translateY = translate.translateY;
      } else if (renderBody) {
        endStyles.translateY = ((height - 40) / 2) * -1;
      }
    }

    if (direction === 'left') {
      startStyles.left = offsetX - startStyles.width;
      endStyles.left = offsetX - startStyles.width;
    } else if (direction === 'up') {
      startStyles.top = offsetY - 40;
      endStyles.top = offsetY - 40;
    }

    return {
      startStyles,
      endStyles,
    };
  }

  setCollapseListRef(collapseList) {
    this.collapseList = collapseList;
  }

  setTriggersRef(triggersRef) {
    this.triggersRef = triggersRef;
  }

  setBodyHeight(height) {
    this.setState({
      bodyHeight: height,
    });
  }

  nextPage() {
    this.setState((state) => {
      let page = state.page + 1;
      const pages = makePages(this.props);
      if (page > pages.length - 1) page = pages.length - 1;
      return {
        page,
      };
    });
  }

  prevPage() {
    this.setState((state) => {
      let page = state.page - 1;
      const pages = makePages(this.props);
      if (page < 0) page = 0;
      if (page > pages.length - 1) page = pages.length - 2;
      return {
        page,
      };
    });
  }

  executeItemCallback(checkBlurState) {
    const { active } = this.state;
    const activeItem = this.getItemByIndex(active);
    const { closeIcon, onClose } = activeItem;

    const { onActiveStateChange } = this.props;

    onActiveStateChange(null);

    const headerStates = this.headerRefs.map(ControlPane.extractItemState);
    const bodyStates = this.bodyRefs.map(ControlPane.extractItemState);

    if (closeIcon === 'submit') {
      const { onSubmit, submitOnBlur } = activeItem;

      if (onSubmit) {
        if ((checkBlurState && submitOnBlur) || !checkBlurState) {
          onSubmit(headerStates, bodyStates);
        }
      }
    } else if (onClose) {
      onClose(headerStates, bodyStates);
    }
  }

  calcEditorWidth(pageContent) {
    const { active } = this.state;
    if (pageContent) {
      if (active !== null) {
        return this.getItemByIndex(active).activeWidth;
      }

      return deriveItemsWidth(pageContent);
    }

    return null;
  }

  calcTranslateByDirection() {
    const { direction } = this.props;

    switch (direction) {
      case 'left':
      case 'right':
        return {
          translateX: 0,
          translateY: 0,
        };
      default:
        return {
          translateX: -50,
          translateY: 0,
        };
    }
  }

  showCollapseDropdown() {
    if (!this.collapseList || !this.triggersRef) {
      return;
    }

    this.collapseList.makeActive();
  }

  closeEditor() {
    const { active } = this.state;
    const activeItem = this.getItemByIndex(active);
    let isValid = true;

    if (activeItem.validateContent) {
      isValid = activeItem.validateContent();
    }

    if (!isValid) return;

    if (activeItem && activeItem.body !== null) {
      this.setState(
        {
          beforeClose: true,
        },
        this.onBeforeClose
      );
    } else {
      this.setState({
        active: null,
      });

      this.executeItemCallback();
    }
  }

  instantCloseEditor() {
    const { active } = this.state;
    const activeItem = this.getItemByIndex(active);

    if (!activeItem) {
      return;
    }

    if (activeItem && activeItem.body !== null) {
      this.setState(
        {
          beforeClose: true,
        },
        this.onBeforeClose.bind(this, { checkBlurState: true })
      );

      return;
    }

    this.setState({
      active: null,
    });

    this.executeItemCallback(true);
  }

  bindMethods() {
    this.renderActiveContent = this.renderActiveContent.bind(this);
    this.renderTriggers = this.renderTriggers.bind(this);
    this.renderEditorBody = this.renderEditorBody.bind(this);
    this.setTriggersRef = this.setTriggersRef.bind(this);
    this.setCollapseListRef = this.setCollapseListRef.bind(this);
    this.showCollapseDropdown = this.showCollapseDropdown.bind(this);
    this.closeEditor = this.closeEditor.bind(this);
    this.instantCloseEditor = this.instantCloseEditor.bind(this);
    this.getItemByIndex = this.getItemByIndex.bind(this);
    this.onCollapseListItemClick = this.onCollapseListItemClick.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onBeforeClose = this.onBeforeClose.bind(this);
  }

  renderTriggers(pageContent) {
    const { isAnimationInAction } = this.state;

    return (
      <ControlPaneTriggers
        ref={this.setTriggersRef}
        triggers={pageContent}
        onItemClick={this.onCollapseListItemClick}
        onItemHover={this.onMouseEnter}
        isAnimationInAction={isAnimationInAction}
      />
    );
  }

  renderActiveContent() {
    const { active } = this.state;
    const { onTriggerActionStart } = this.props;
    const activeItem = this.getItemByIndex(active);
    const header = activeItem.header.slice();
    const { closeIcon } = activeItem;

    header.forEach((item, index) => {
      if (!item.type) {
        return;
      }

      const key = `${activeItem.id}-header-${index}`;

      const props = {
        ref: (ref) => {
          this.headerRefs[index] = ref;
        },
        key,
      };
      if (onTriggerActionStart) {
        props.onClick = () => {
          if (item.props && item.props.onClick) {
            item.props.onClick();
          }
          onTriggerActionStart({
            id: key,
            element: activeItem.element,
            extensionSlug: activeItem.extensionSlug,
          });
        };
      }

      header[index] = React.cloneElement(item, props);
    });

    const glyph = closeIcon === 'submit' ? 'check' : 'close';

    const onCloseHeader = (...rest) => {
      this.closeEditor(...rest);
      onTriggerActionStart({
        id: `${activeItem.id}-submit`,
        element: activeItem.element,
        extensionSlug: activeItem.extensionSlug,
        closeHeaderTrigger: true,
      });
    };

    header.push(
      <Trigger onClick={activeItem.id ? onCloseHeader : this.closeEditor}>
        <Icon glyph={glyph} />
      </Trigger>
    );

    return (
      <div className="ws-control-pane__editor-wrapper">
        {this.renderEditorBody()}
        <ControlPaneHeader items={header} />
      </div>
    );
  }

  renderEditorBody() {
    const { renderBody, active, beforeClose, bodyHeight } = this.state;
    const activeItem = this.getItemByIndex(active);

    if (activeItem.body) {
      if (renderBody) {
        const showPanel = beforeClose !== true;
        const { body, title } = activeItem;

        let height;

        if (bodyHeight) {
          height = bodyHeight + 40;
        } else {
          height = activeItem.height + 40;
        }

        body.forEach((item, index) => {
          if (!item.type) {
            return;
          }

          const key = `${title}-body-${index}`;

          body[index] = React.cloneElement(item, {
            ref: (ref) => {
              this.bodyRefs[index] = ref;
            },
            key,
          });
        });

        return (
          <ControlPaneBody style={{ height }} show={showPanel}>
            {body}
          </ControlPaneBody>
        );
      }

      if (!this.bodyTimeout) {
        this.bodyTimeout = setTimeout(
          () => {
            this.setState({ renderBody: true });

            clearTimeout(this.bodyTimeout);

            this.bodyTimeout = null;
          },
          (activeItem.header.length + 1) * 70
        );
      }
    }

    return null;
  }

  render() {
    const {
      direction,
      onMouseLeave,
      expand,
      arrowOffsetX,
      arrowOffsetY,
      attributes,
      disableCentering,
      controlPaneRef,
      className,
    } = this.props;
    const { active, page } = this.state;

    const pages = makePages(this.props);
    const currentPage = page < pages.length - 1 ? page : pages.length - 1;
    this.pageContent = pages[currentPage].items;

    const editorClass = classNames('ws-control-pane', className, {
      [`ws-control-pane--expand-${expand}`]: active !== null,
      [`ws-control-pane--direction-${direction}`]: direction !== 'none',
      'ws-control-pane--centered': !disableCentering,
    });

    const { startStyles, endStyles } = this.getStyles(this.pageContent);
    const arrowSize = 14;

    const arrowStyles = {
      arrowLeft: arrowOffsetX != null ? arrowOffsetX - arrowSize / 2 : 0,
      arrowWidth: arrowOffsetX != null ? arrowSize : 100,
      arrowTop: arrowOffsetY != null ? arrowOffsetY - arrowSize / 2 : 0,
      arrowHeight: arrowOffsetY != null ? arrowSize : 100,
    };

    return (
      <Spring
        from={startStyles}
        to={{ ...endStyles, ...arrowStyles }}
        config={FADE_ANIMATION}
        onRest={() => {
          this.setState({ isAnimationInAction: false });
        }}
        onStart={() => {
          this.setState({ isAnimationInAction: true });
        }}
      >
        {(interpolatingStyle) => {
          const style = {
            left: interpolatingStyle.left,
            top: startStyles.top,
            opacity: interpolatingStyle.opacity,
            transform: `scale(${interpolatingStyle.scale})
                        translate(${endStyles.translateX}%, ${interpolatingStyle.translateY}px)`,
            width: `${interpolatingStyle.width}px`,
          };

          return (
            <div
              key={this.editorKey}
              onMouseLeave={onMouseLeave}
              className={editorClass}
              style={style}
              ref={controlPaneRef}
              {...attributes}
            >
              <Render if={active !== null}>{this.renderActiveContent}</Render>
              {active === null && this.renderTriggers(this.pageContent)}
            </div>
          );
        }}
      </Spring>
    );
  }
}

const triggerType = PropTypes.shape({
  title: PropTypes.string,
  tooltip: PropTypes.string,
  priority: PropTypes.number,
  trigger: PropTypes.node,
  onTriggerClick: PropTypes.func,
  onHover: PropTypes.func,
  header: PropTypes.arrayOf(PropTypes.node),
  body: PropTypes.arrayOf(PropTypes.node),
  closeIcon: PropTypes.string,
  onClose: PropTypes.func,
  submitOnBlur: PropTypes.bool,
  onSubmit: PropTypes.func,
  onBeforeSubmit: PropTypes.func,
  validateContent: PropTypes.func,
});

ControlPane.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      addEventListener: PropTypes.func,
      removeEventListener: PropTypes.func,
    })
  ),
  maxItems: PropTypes.number,
  maxWidth: PropTypes.number,
  initPage: PropTypes.number,
  onTriggerActionStart: PropTypes.func,
  onActiveStateChange: PropTypes.func,
  onMouseLeave: PropTypes.func,
  direction: PropTypes.oneOf(['none', 'up', 'right', 'down', 'left']),
  expand: PropTypes.oneOf(['up', 'middle', 'down']),
  dropdown: PropTypes.oneOf(['bottom-left', 'bottom-right', 'top-left', 'top-right']),
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,
  arrowOffsetX: PropTypes.number,
  arrowOffsetY: PropTypes.number,
  attributes: PropTypes.shape(),
  content: PropTypes.arrayOf(triggerType),
  prevTriggerTitle: PropTypes.string,
  nextTriggerTitle: PropTypes.string,
  disableCentering: PropTypes.bool,
  controlPaneRef: PropTypes.shape({
    current: PropTypes.instanceOf(HTMLDivElement),
  }),
  className: PropTypes.string,
};

ControlPane.defaultProps = {
  documents: [],
  maxItems: 6,
  maxWidth: 360,
  initPage: 0,
  onTriggerActionStart: utils.noop,
  onActiveStateChange: utils.noop,
  onMouseLeave: utils.noop,
  direction: 'right',
  expand: 'down',
  offsetX: undefined,
  offsetY: undefined,
  arrowOffsetX: undefined,
  arrowOffsetY: undefined,
  content: [],
  dropdown: 'bottom-left',
  attributes: {},
  prevTriggerTitle: i18next.t('Previous'),
  nextTriggerTitle: i18next.t('Next'),
  disableCentering: false,
  controlPaneRef: null,
  className: '',
};

export default ControlPane;
