import { assets, integration, dialogs, utils } from '@yola/ws-sdk';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import user from 'yola-editor/src/js/modules/user';
import techLimitCaptions from '../constants/tech-limits-captions';

const handleTechLimitInterruption = ({ store, next, reject }) => {
  const { captions, premiumFeature } = techLimitCaptions.uploadFileSize;
  store.dispatch(
    dialogs.actions.show(dialogTypes.REACH_TECH_LIMIT_DIALOG, {
      premiumFeature,
      captions,
      onDismiss: () => {
        reject();
        next(dialogs.actions.hide());
      },
    })
  );
};

const handleFileSizeInterruption = ({
  store,
  action,
  next,
  upsells,
  reject,
  resolve,
  onClose = utils.noop,
  resolveValue = null,
}) => {
  const state = store.getState();
  const availablePlatformComponents = user.selectors.getAvailablePlatformComponents(state);
  const { captions, premiumFeature, purchaseFlowType } = upsells.uploadFileSize;
  const details = {
    size: upsells.uploadFileSize,
  };
  const onUpgrade = () => upsells.uploadFileSize.onUpgrade(details);
  const onUpgradeResolve = () => {
    resolve(resolveValue);
    next(action);
  };
  const onUpgradeReject = () => {
    reject();
    // eslint-disable-next-line no-console
    console.log('Upgrade flow was canceled');
  };

  // b2c & reseller platform upgrade
  if (availablePlatformComponents.includes(user.platformComponents.PAYMENTS)) {
    onUpgrade().then(onUpgradeResolve, onUpgradeReject);
    return;
  }

  store.dispatch(
    dialogs.actions.show(dialogTypes.UPGRADE, {
      onUpgrade,
      onClose: () => {
        reject();
        onClose();
        next(action);
      },
      onUpgradeResolve,
      captions,
      premiumFeature,
      purchaseFlowType,
    })
  );
};

const fileSizeInterrupter = (store) => (next) => (action) => {
  switch (action.type) {
    case assets.actionTypes.CLIENT_UPLOAD_ASSET: {
      const { asset, resolve, reject } = action.payload;
      const state = store.getState();
      const limits = integration.selectors.getLimits(state);
      const upsells = integration.selectors.getUpsells(state);

      if (asset.size >= limits.uploadFileSize.techLimitMaxSize) {
        handleTechLimitInterruption({ store, next, reject });
        return;
      }

      if (upsells.uploadFileSize && asset.size >= limits.uploadFileSize.maxSize) {
        if (!limits.uploadFileSize.abilityToIncreaseMaxSize) {
          handleTechLimitInterruption({ store, next, reject });
          return;
        }
        handleFileSizeInterruption({
          store,
          next,
          action,
          resolve,
          reject,
          upsells,
        });
        return;
      }

      resolve();
      next(action);
      break;
    }

    case assets.actionTypes.FILE_SIZE_LIMIT_REACHED: {
      const { resolve, reject } = action.payload;
      const state = store.getState();
      const upsells = integration.selectors.getUpsells(state);

      handleFileSizeInterruption({
        store,
        next,
        action,
        resolve,
        reject,
        onClose: () => {
          dialogs.operations.hide();
        },
        upsells,
      });
      break;
    }

    case assets.actionTypes.CLIENT_UPLOAD_ASSETS: {
      const { assets: clientAssets, resolve, reject } = action.payload;
      const state = store.getState();
      const limits = integration.selectors.getLimits(state);
      const upsells = integration.selectors.getUpsells(state);

      const isTechLimitReached = clientAssets.some(
        (asset) => asset.size >= limits.uploadFileSize.techLimitMaxSize
      );

      if (isTechLimitReached) {
        handleTechLimitInterruption({ store, next });
        return;
      }

      const { acceptedFiles, dismissedFiles } = clientAssets.reduce(
        (result, asset) => {
          if (upsells.uploadFileSize && asset.size >= limits.uploadFileSize.maxSize) {
            result.dismissedFiles.push(asset);
            return result;
          }
          result.acceptedFiles.push(asset);
          return result;
        },
        {
          acceptedFiles: [],
          dismissedFiles: [],
        }
      );

      const isFileSizeLimitReached = dismissedFiles.length > 0;

      if (isFileSizeLimitReached) {
        handleFileSizeInterruption({
          store,
          action,
          next,
          reject,
          resolve,
          upsells,
          resolveValue: {
            isFileSizeLimitReached,
            acceptedFiles,
            dismissedFiles,
          },
        });
        return;
      }

      resolve({ isFileSizeLimitReached, acceptedFiles, dismissedFiles });
      next(action);
      break;
    }

    default:
      next(action);
  }
};

export default fileSizeInterrupter;
