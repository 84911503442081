import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { linkEditing } from '@yola/ws-sdk';
import { designSystem } from '@yola/ws-ui';
import LinkSelector from './link-selector';
import isOpenAddress from '../verifiers/is-open-address';
import getHelperTextByLinkType from '../helpers/get-helper-text-by-link-type';

const { FieldStatus } = designSystem;

const { linkTypes } = linkEditing.constants;

class LinkSelectorField extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  onChange(value) {
    const {
      field: { name },
      form: { setFieldValue, setFieldError, setFieldTouched },
      onChange,
    } = this.props;

    if (!isOpenAddress(value.linkType)) {
      setFieldTouched(name, false, false);
    }

    setFieldError(name, '');
    setFieldValue(name, value[name], false);
    onChange(value);
  }

  onBlur() {
    const {
      field: { name },
      form: { setFieldTouched },
    } = this.props;
    setFieldTouched(name);
  }

  render() {
    const {
      field,
      field: { name },
      form: { errors, touched },
      values,
      ...otherProps
    } = this.props;

    const helperText = getHelperTextByLinkType(values.linkType);

    const hasError = !!(errors[name] && touched[name]);
    return (
      <Fragment>
        <LinkSelector
          {...otherProps}
          {...field}
          hasError={hasError}
          onChange={this.onChange}
          onInputBlur={this.onBlur}
          values={values}
        />
        {helperText && !hasError && <FieldStatus text={helperText} />}
        {hasError && <FieldStatus appearance="error" text={errors[name] || ''} />}
      </Fragment>
    );
  }
}

LinkSelectorField.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  values: PropTypes.shape({
    linkType: PropTypes.oneOf(Object.values(linkTypes)).isRequired,
    reference: PropTypes.string,
  }).isRequired,
  // Following props are set by Formik. Added only to pass snitch check.
  field: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
    setFieldError: PropTypes.func,
    setFieldTouched: PropTypes.func,
    touched: PropTypes.shape({}),
    errors: PropTypes.shape({}),
  }).isRequired,
};

LinkSelectorField.defaultProps = {
  disabled: false,
};

export default LinkSelectorField;
