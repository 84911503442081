import { integration, site } from '@yola/ws-sdk';
import traqing from '@yola/traqing.js';
import config from 'src/js/modules/config';
import integrations from '../constants/integrations';
import yolaDebuggingIntegration from '../integrations/yola-debugging-integration';
import initializeTrackers from '../initialize-trackers';
import identify from '../identify';

const segmentInitializer = (store) => (next) => (action) => {
  switch (action.type) {
    case config.actionTypes.SET_SERVICE_CONFIG: {
      next(action);
      const state = store.getState();
      const key = config.selectors.getSegmentIoKey(state);
      const publicApiUrl = config.selectors.getApiUrl(state);

      // To avoid unnecessary errors in console during development
      if (!key) return;

      traqing.segment.init(key, publicApiUrl);
      traqing.segment.registerIntegration(integrations.YOLA_DEBUGGING, yolaDebuggingIntegration);

      traqing.segment.useIntegration(traqing.segment.integrations.GOOGLE_CLIENT_ID);
      traqing.segment.useIntegration(traqing.segment.integrations.YOLA_COOKIES, {
        names: ['Yola'],
      });
      traqing.segment.useIntegration(integrations.YOLA_DEBUGGING);

      initializeTrackers();

      break;
    }

    case site.actionTypes.CONNECT_SITE: {
      next(action);
      const state = store.getState();
      const userId = integration.selectors.getUserId(state);
      identify(userId);
      break;
    }

    default: {
      next(action);
    }
  }
};

export default segmentInitializer;
