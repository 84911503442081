import * as convertCase from '../../utils/convert-case';
import colorValidation from './color-validation';
import compileCustomTemplate from './compile-custom-template';
import composeFontsAssetsPreviewUrl from './compose-fonts-assets-preview-url';
import createHslColorString from './create-hsl-color-string';
import createMapById from './create-map-by-id';
import createWebsiteAnimationScript from './create-website-animation-script';
import detectModalWidth from './detect-modal-width';
import extractScriptsFromTemplate from './extract-scripts-from-template';
import generateCustomPaletteId from './generate-custom-palette-id';
import generateCustomColorsForCssRule from './generate-custom-colors-for-css-rule';
import getColorsConfig from './get-colors-config';
import getFontsConfig from './get-fonts-config';
import mapColorTypes from './map-color-type';
import prepareNodeElement from './prepare-node-element';
import parseHslColorString from './parse-hsl-color-string';
import parseHslaColorString from './parse-hsla-color-string';
import setCurrentFont from './set-current-font';
import setCurrentPalette from './set-current-palette';
import setCurrentButtonPreset from './set-current-button-preset';
import applyEffects from './apply-effects';
import getSelectorByGlobalId from './get-selector-by-global-id';
import getSurfaceLinkColor from './get-surface-link-color';
import getBlockSurfaceBackground from './get-block-surface-background';
import getBlockSurfaceName from './get-block-surface-name';
import getColorNameByVariable from './get-color-name-by-variable';
import regeneratePropertiesForBlockCssRules from './regenerate-properties-for-block-css-rules';
import regeneratePropertiesForBlockCssRule from './regenerate-properties-for-block-css-rule';
import sortPresets from './sort-presets';
import getAdvancedColorsKeys from './get-advanced-colors-keys';
import getAdvancedColorsSections from './get-advanced-colors-sections';
import generateAdvancedColors from './generate-advanced-colors';
import getColorFromVariables from './get-color-from-variables';
import advancedColorKeyToVariableKey from './advanced-color-key-to-variable-key';
import generatePalette from './generate-palette';
import generateColorPresetLabel from './generate-color-preset-label';
import getInitialGeneralColor from './get-initial-general-color';
import setGeneralColor from './set-general-color';
import setAdvancedColor from './set-advanced-color';

export default {
  convertCase,
  colorValidation,
  compileCustomTemplate,
  composeFontsAssetsPreviewUrl,
  createHslColorString,
  createMapById,
  createWebsiteAnimationScript,
  detectModalWidth,
  extractScriptsFromTemplate,
  generateCustomPaletteId,
  generateCustomColorsForCssRule,
  getColorsConfig,
  getFontsConfig,
  mapColorTypes,
  parseHslColorString,
  parseHslaColorString,
  prepareNodeElement,
  setCurrentFont,
  setCurrentPalette,
  setCurrentButtonPreset,
  applyEffects,
  getSelectorByGlobalId,
  getSurfaceLinkColor,
  getBlockSurfaceBackground,
  getBlockSurfaceName,
  getColorNameByVariable,
  regeneratePropertiesForBlockCssRules,
  regeneratePropertiesForBlockCssRule,
  sortPresets,
  getAdvancedColorsKeys,
  getAdvancedColorsSections,
  generateAdvancedColors,
  getColorFromVariables,
  advancedColorKeyToVariableKey,
  generatePalette,
  generateColorPresetLabel,
  getInitialGeneralColor,
  setGeneralColor,
  setAdvancedColor,
};
