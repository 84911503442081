import paymentMethods from '../constants/payment-methods';

const paymentMethodsMap = new Map([
  [
    paymentMethods.STRIPE,
    [paymentMethods.VISA, paymentMethods.MASTER_CARD, paymentMethods.AMERICAN_EXPRESS],
  ],
  [paymentMethods.PAYPAL, [paymentMethods.PAYPAL]],
]);

export default paymentMethodsMap;
