import settingTypes from '../constants/setting-types';

const getSettingValueFromFieldValue = (setting, value) => {
  switch (setting) {
    case settingTypes.SITE_NAME:
    case settingTypes.BUSINESS_NAME:
    case settingTypes.BUSINESS_CATEGORY:
    case settingTypes.BUSINESS_DESCRIPTION: {
      const trimmedValue = value.trim();
      return trimmedValue || null;
    }
    case settingTypes.ADDRESSES:
    case settingTypes.PHONES:
    case settingTypes.EMAILS:
    case settingTypes.BUSINESS_HOURS: {
      const trimmedValue = value.trim();
      return trimmedValue ? [trimmedValue] : null;
    }
    default: {
      return value;
    }
  }
};

export default getSettingValueFromFieldValue;
