const getDiscountInfo = (initialPackageDiscount, signupDate, hasEverHadB2CPremiumSubscription) => {
  const DISCOUNT_PERIOD_IN_MILLISECONDS = 7 * 24 * 60 * 60 * 1000;
  const discount = parseInt(initialPackageDiscount, 10);
  const signUpDate = new Date(signupDate);
  const signUpUtcDate = new Date(
    Date.UTC(
      signUpDate.getFullYear(),
      signUpDate.getMonth(),
      signUpDate.getDate(),
      signUpDate.getHours(),
      signUpDate.getMinutes(),
      signUpDate.getSeconds(),
      signUpDate.getMilliseconds()
    )
  );
  const discountDuration = DISCOUNT_PERIOD_IN_MILLISECONDS - (Date.now() - signUpUtcDate.getTime());

  if (!hasEverHadB2CPremiumSubscription && discount && discountDuration > 0) {
    return {
      endDate: discountDuration + Date.now(),
      value: discount,
    };
  }

  return {
    endDate: Date.now(),
    value: 0,
  };
};

export default getDiscountInfo;
