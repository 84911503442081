import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { AlertDialog } from '@yola/ws-ui';
import { i18next } from '@yola/ws-sdk';

const getCaptions = (captionsOverrides) => ({
  title: i18next.t('Block limit exceeded'),
  description: i18next.t(
    "Oops! It seems you've added two blocks from the same category. Please ensure only one is present"
  ),
  submit: i18next.t('Got it'),
  ...captionsOverrides,
});

function BlockLimitDialog(props) {
  const { onSubmit, captions: captionsOverrides } = props;
  const captions = useMemo(() => getCaptions(captionsOverrides), [captionsOverrides]);

  return <AlertDialog onSubmit={onSubmit} captions={captions} />;
}

BlockLimitDialog.defaultProps = {
  captions: {},
  onSubmit: Function.prototype,
};

BlockLimitDialog.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    submit: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
};

export default BlockLimitDialog;
