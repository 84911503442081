export const DIALOG_PANEL_HEIGHT = 48;
export const DIALOG_WIDTH = 336;
export const DIALOG_HEIGHT = 595;
export const CURRENT_LAYOUT_PREVIEW_MAX_HEIGHT = 180;
export const VIEW_CUSTOMIZATION = {
  globalOffsetTop: '12px',
  globalOffsetBottom: '12px',
  globalOffsetLeft: '12px',
  globalOffsetRight: '12px',
  thumbnailOffsetTop: '24px',
  thumbnailFirstChildOffsetTop: '0',
};
