import { useSelector, useDispatch } from 'react-redux';
import getUpsellSettings from '../helpers/get-upsell-settings';

const useUpsellSettings = ({ isFreeDomainAvailable }) => {
  const dispatch = useDispatch();

  return useSelector((state) =>
    getUpsellSettings({
      state,
      dispatch,
      isFreeDomainAvailable,
    })
  );
};

export default useUpsellSettings;
