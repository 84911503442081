const getUsedIndexes = (dynamicItems) =>
  dynamicItems.reduce(
    (result, item) => ({
      ...result,
      [item.order]: true,
    }),
    {}
  );

export default getUsedIndexes;
