import React from 'react';
import { extensions } from '@yola/ws-sdk';
import bowser from 'yola-bowser';
import { TEXT_ELEMENT_TYPE_TRIGGER_ID } from 'src/js/modules/control-pane/constants/trigger-ids';
import isMultipurpose from '../../../common/helpers/is-multipurpose';
import constants from '../constants';
import ElementTypeSelect from '../components/element-type-select';
import ElementTypeTrigger from '../components/element-type-trigger';
import ElementTypeActionTrigger from '../components/element-type-action-trigger';
import getElementTypeOptions from '../helpers/get-element-type-options';
import typedElementsMatches from '../helpers/typed-elements-matches';

const { controlTypes } = extensions;

const triggerId = TEXT_ELEMENT_TYPE_TRIGGER_ID;

function elementMatches(element) {
  const { textContainer, elementTypeRestrictedParentsSelector } =
    extensions.accessors.getExtensionSettings(constants.common.SLUG);

  if (
    !isMultipurpose() ||
    !element.matches(textContainer) ||
    (elementTypeRestrictedParentsSelector && element.closest(elementTypeRestrictedParentsSelector))
  ) {
    return false;
  }

  const typedElements = typedElementsMatches();

  return Boolean(typedElements.length);
}

const control = bowser.mobile
  ? {
      id: triggerId,
      trigger: <ElementTypeTrigger triggerId={triggerId} />,
      header: getElementTypeOptions().map((option) => (
        <ElementTypeActionTrigger triggerId={triggerId} option={option} />
      )),
      activeWidth: 360,
      priority: 61,
      for: [controlTypes.COLLAPSED_TEXT, controlTypes.TEXT],
      highlightElement: false,
      matches: elementMatches,
    }
  : {
      id: triggerId,
      trigger: <ElementTypeSelect />,
      width: 160,
      priority: 61,
      for: [controlTypes.COLLAPSED_TEXT, controlTypes.TEXT],
      highlightElement: false,
      matches: elementMatches,
    };

export default control;
