import actionTypes from '../constants/action-types';

function activeTool(state = null, action) {
  if (action.type === actionTypes.SET_ACTIVE_TOOL) {
    return action.payload?.id ? action.payload : null;
  }

  return state;
}

export default activeTool;
