import { i18n } from '@yola/ws-sdk';
import store from 'src/js/store';
import config from 'src/js/modules/config';
import cleanRoute from 'yola-editor/src/js/utils/clean-route';
import sentry from 'src/js/modules/analytics/sentry';
import subscriptions from 'yola-editor/src/js/modules/subscriptions';

const initApp = async () => {
  await store.dispatch(config.thunks.fetchConfig());

  sentry.instance.init(config.selectors.getSentryDSN(store.getState()));
  subscriptions.helpers.configureSubscriptionManager(store);
  await i18n.initializers.initializeI18next();
  cleanRoute();
};

export default initApp;
