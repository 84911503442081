import reasons from '../../constants/reasons';
import on from '../on';
import nextBlockIsChanged from './next-block-is-changed';
import blockSettingsAreUpdated from './block-settings-are-updated';
import blockIsReplaced from './block-is-replaced';

const {
  INSERT_BLOCK,
  SET_ELEMENT_ATTRIBUTE,
  SHIFT_ELEMENT,
  PASTE_ELEMENT,
  DELETE_ELEMENT,
  REPLACE_ELEMENT,
} = reasons;

const init = () => {
  on({ reason: INSERT_BLOCK }, nextBlockIsChanged);
  on({ reason: SET_ELEMENT_ATTRIBUTE }, blockSettingsAreUpdated);
  on({ reason: SHIFT_ELEMENT }, nextBlockIsChanged);
  on({ reason: PASTE_ELEMENT }, nextBlockIsChanged);
  on({ reason: DELETE_ELEMENT }, nextBlockIsChanged);
  on({ reason: REPLACE_ELEMENT }, nextBlockIsChanged);
  on({ reason: REPLACE_ELEMENT }, blockIsReplaced);
};

export default init;
