import { hdrm } from '@yola/ws-sdk';
import customTools from '../../custom-tools';
import { EXTENSION } from '../constants/item-types';

const { text } = hdrm.injections.bindings;

const shouldItemBeAutoActivated = (itemToActivate, targetElement, sameBoundsElements) => {
  if (text.getRootTextElement(targetElement)) {
    return false;
  }

  const hasMatchedCustomTool = sameBoundsElements.some(
    ({ element: sameBoundsElement }) => customTools.filters.findAllFor(sameBoundsElement).length
  );
  if (hasMatchedCustomTool) {
    return false;
  }

  // workaround for ws-image control (non focal-point one)
  // that still is used for some cases in legacy template
  // and requires click on trigger (input[type="file"])
  if (itemToActivate.id === 'image') {
    return false;
  }

  return itemToActivate.type === EXTENSION;
};

export default shouldItemBeAutoActivated;
