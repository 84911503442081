import { view } from '@yola/ws-sdk';

const getActionsToApply = (data, options) => {
  const actions = [];

  Object.keys(data).forEach((elementId) => {
    Object.keys(data[elementId]).forEach((attribute) => {
      const value = data[elementId][attribute];
      if (typeof value === 'boolean') {
        if (value) {
          actions.push(view.actions.setElementAttribute(elementId, attribute, '', options));
        } else {
          actions.push(view.actions.removeElementAttribute(elementId, attribute, options));
        }

        return;
      }
      actions.push(view.actions.setElementAttribute(elementId, attribute, value, options));
    });
  });

  return actions;
};

export default getActionsToApply;
