import actionTypes from '../constants/action-types';

const setScrollTargetPath = (path) => ({
  type: actionTypes.SET_SCROLL_TARGET_PATH,
  payload: {
    path,
  },
});

export default setScrollTargetPath;
