import React from 'react';
import PropTypes from 'prop-types';
import { i18next, utils } from '@yola/ws-sdk';
import { Modal, PanelGroup, Panel, designSystem } from '@yola/ws-ui';
import bowser from 'yola-bowser';
import constants from '../constants';
import verifiers from '../verifiers';
import GeneralTab from './general-tab';
import AdvancedTab from './advanced-tab';
import tabIdentifiers from '../constants/tab-identifiers';

const {
  DialogHeader,
  DialogHeaderTitle,
  Tabs,
  TabsList,
  Tab,
  TabsContentList,
  TabContent,
  DialogButton,
  DialogButtonGroup,
  DialogButtonGroupSeparator,
  FloatingAction,
} = designSystem;

const { common } = constants;
const {
  MODAL_WIDTH,
  PANEL_HEIGHT,
  MODAL_HEIGHT,
  MODAL_HEIGHT_WITH_TABS,
  MIN_FORM_SETTINGS_MODAL_HEIGHT,
} = common;

class FormSettingsModal extends React.Component {
  constructor(props) {
    super(props);

    this.modalContentRef = React.createRef(null);
    this.enableBuilder = verifiers.isBuilderEnabled();
    this.state = {
      activeTabId: tabIdentifiers.GENERAL,
    };
    this.setActiveTabId = this.setActiveTabId.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.updateModalPosition = this.updateModalPosition.bind(this);
    this.isMobile = bowser.mobile;
  }

  onSubmit() {
    const { handleSubmit, values } = this.props;
    const { activeTabId } = this.state;
    const { emails, successMessage } = values;

    if (activeTabId === tabIdentifiers.GENERAL && (!emails.length || !successMessage)) {
      this.setActiveTabId(tabIdentifiers.ADVANCED);
    }

    handleSubmit();
  }

  setActiveTabId(id) {
    this.setState({
      activeTabId: id,
    });
  }

  updateModalPosition(event, { x, y }) {
    const { setModalPosition } = this.props;

    setModalPosition({ offsetX: x, offsetY: y });
  }

  render() {
    const {
      modalPosition,
      handleCancel,
      errors,
      openFieldSettings,
      addNewField,
      updateFields,
      setAdvancedFormState,
      fields,
      advancedFormState,
      elementId,
      setIsOverlayClicked,
      setIsOrderAdjusted,
      initialFields,
    } = this.props;
    const { activeTabId } = this.state;

    const modalHeight = this.enableBuilder ? MODAL_HEIGHT_WITH_TABS : MODAL_HEIGHT;

    return (
      <Modal
        width={MODAL_WIDTH}
        maxWidth={MODAL_WIDTH}
        minWidth={MODAL_WIDTH}
        height={modalHeight}
        minHeight={MIN_FORM_SETTINGS_MODAL_HEIGHT}
        fullscreen={this.isMobile}
        dragHandleSelector=".ws-drag-trigger"
        className="ws-contact-form-settings-modal"
        handleCancel={handleCancel}
        handleSubmit={() => {
          if (fields.length) {
            setIsOverlayClicked(true);
            this.onSubmit();
          }
        }}
        onDragEnd={this.updateModalPosition}
        isValid={!Object.values(errors).filter(Boolean).length}
        modalContentRef={this.modalContentRef}
        {...modalPosition}
      >
        <PanelGroup height="100%">
          <Panel
            height={PANEL_HEIGHT}
            align="middle"
            className="ws-drag-trigger"
            theme="gray-100"
            corners="squared"
            style={{ borderBottom: 'none' }}
          >
            <DialogHeader>
              <DialogHeaderTitle>{i18next.t('Form settings')}</DialogHeaderTitle>
            </DialogHeader>
          </Panel>
          <div className="ws-contact-form-settings-modal__tabs">
            <Tabs activeTabId={activeTabId} onTabChange={this.setActiveTabId}>
              <TabsList variant="fullWidth">
                <Tab tabId={tabIdentifiers.GENERAL} label={i18next.t('General')} />
                <Tab tabId={tabIdentifiers.ADVANCED} label={i18next.t('Advanced')} />
              </TabsList>
              <TabsContentList>
                <TabContent tabId={tabIdentifiers.GENERAL}>
                  <GeneralTab
                    elementId={elementId}
                    initialFields={initialFields}
                    fields={fields}
                    updateFields={updateFields}
                    openSettings={openFieldSettings}
                    modalContentRef={this.modalContentRef}
                    setIsOrderAdjusted={setIsOrderAdjusted}
                    advancedFormState={advancedFormState}
                    modalPosition={modalPosition}
                  />
                </TabContent>
                <TabContent tabId={tabIdentifiers.ADVANCED}>
                  <AdvancedTab
                    elementId={elementId}
                    setAdvancedFormState={setAdvancedFormState}
                    advancedFormState={advancedFormState}
                  />
                </TabContent>
              </TabsContentList>
            </Tabs>
          </div>
          <Panel height={PANEL_HEIGHT} align="middle" corners="squared">
            <DialogButtonGroup>
              <DialogButton disabled={!fields.length} onClick={this.onSubmit}>
                {i18next.t('Submit')}
              </DialogButton>
              <DialogButtonGroupSeparator />
              <DialogButton onMouseDown={handleCancel}>{i18next.t('Cancel')}</DialogButton>
            </DialogButtonGroup>
          </Panel>
        </PanelGroup>
        {this.enableBuilder && activeTabId === tabIdentifiers.GENERAL && (
          <div className="ws-contact-form-settings-modal__floating-action-wrapper">
            <FloatingAction onClick={addNewField} />
          </div>
        )}
      </Modal>
    );
  }
}

FormSettingsModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  openFieldSettings: PropTypes.func.isRequired,
  addNewField: PropTypes.func.isRequired,
  updateFields: PropTypes.func.isRequired,
  setAdvancedFormState: PropTypes.func.isRequired,
  elementId: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  initialFields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  advancedFormState: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  values: PropTypes.shape({
    emails: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      })
    ).isRequired,
    successMessage: PropTypes.string.isRequired,
  }).isRequired,
  dirty: PropTypes.bool.isRequired,
  setModalPosition: PropTypes.func.isRequired,
  modalPosition: PropTypes.shape({
    offsetX: PropTypes.number,
    offsetY: PropTypes.number,
  }).isRequired,
  setIsOverlayClicked: PropTypes.func,
  setIsOrderAdjusted: PropTypes.func,
};

FormSettingsModal.defaultProps = {
  setIsOverlayClicked: utils.noop,
  setIsOrderAdjusted: utils.noop,
};

export default FormSettingsModal;
