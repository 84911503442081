import connectedStore from 'yola-editor/src/js/store/connected-store';
import actions from '../actions';

const fetchUserPreferences = () =>
  new Promise((resolve, reject) => {
    const store = connectedStore.get();

    store.dispatch(actions.fetchUserPreferences(resolve, reject));
  });

export default fetchUserPreferences;
