import { transactions } from '@yola/ws-sdk';
import getElementBySelector from '../../helpers/get-element-by-selector';
import getWhitespaceAutomationInstructions from './helpers/get-whitespace-automation-instructions';

const getReplacedBlock = (payload, isStatic) => {
  const { element } = payload;
  const blockSelector = `[id="${element.id}"]`;
  const targetBlock = getElementBySelector(blockSelector, isStatic);

  return targetBlock;
};

const blockIsReplaced = (config, payload, transaction) => {
  const { context } = transaction;
  const isStatic = context !== transactions.transactionTypes.LIVE_DOCUMENT;
  const replacedBlock = getReplacedBlock(payload, isStatic);

  if (!replacedBlock) {
    return [];
  }

  return [
    ...getWhitespaceAutomationInstructions({
      prevSiblingNode: replacedBlock.previousElementSibling,
      targetNode: replacedBlock,
      isStatic,
    }),
    ...getWhitespaceAutomationInstructions({
      prevSiblingNode: replacedBlock,
      targetNode: replacedBlock.nextElementSibling,
      isStatic,
    }),
  ];
};

export default blockIsReplaced;
