import actionTypes from '../constants/action-types';

const setPublishedSiteVhosts = (vhosts) => ({
  type: actionTypes.SET_PUBLISHED_SITE_VHOSTS,
  payload: {
    vhosts,
  },
});

export default setPublishedSiteVhosts;
