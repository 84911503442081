import {
  BACKGROUND_HIDDEN,
  FULLSCREEN,
  SURFACES,
  WHITESPACE_BOTTOM,
  WHITESPACE_TOP,
} from '../../constants/display-options';
import createBreakpointsSliderOption from './create-breakpoints-slider-option';

const updateDependentWhiteSpaceOptions = ({
  displayOptions,
  editedOption,
  blockNode,
  onChange,
  onAfterChange,
}) => {
  const dependentOptionsIds = [SURFACES, FULLSCREEN, BACKGROUND_HIDDEN];
  const whiteSpaceOptionsIds = [WHITESPACE_TOP, WHITESPACE_BOTTOM];

  if (dependentOptionsIds.includes(editedOption.id)) {
    return displayOptions.map((option) =>
      whiteSpaceOptionsIds.includes(option.id)
        ? createBreakpointsSliderOption({
            displayOptions,
            blockNode,
            item: option,
            onChange,
            onAfterChange,
          })
        : option
    );
  }

  return displayOptions;
};

export default updateDependentWhiteSpaceOptions;
