import { blocks } from '@yola/ws-sdk';
import getParentBlockByElement from 'src/js/modules/blocks/helpers/get-parent-block-by-element';
import resizeHandlePositions from '../../../common/constants/resize-handle-positions';
import isPositioningImage from './is-positioning-image';

const getVisibleResizeTriggers = (element, handlersConfig) => {
  const fallback = {
    [resizeHandlePositions.BOTTOM_CENTER]: handlersConfig[resizeHandlePositions.BOTTOM_CENTER],
  };

  const positionSet = new Set([resizeHandlePositions.BOTTOM_CENTER]);

  if (!element) return fallback;

  const blockElement = getParentBlockByElement(element);
  const blockId = blocks.accessors.getBlockIdByElement(blockElement);
  const settings = blocks.accessors.getBlockSettings(blockId);

  if (!settings || !settings.resizing?.length) return fallback;

  const { resizing } = settings;

  const matchedSetting = resizing.find((setting) => element.matches(setting.querySelector));
  if (!matchedSetting) return fallback;
  const availableDirections = matchedSetting.directions;

  availableDirections.forEach((direction) => positionSet.add(direction));

  if (
    positionSet.has(resizeHandlePositions.BOTTOM_LEFT) ||
    positionSet.has(resizeHandlePositions.CENTER_LEFT)
  ) {
    const { marginLeftStyleProperty } = element;
    if (marginLeftStyleProperty !== 'auto') {
      positionSet.delete(resizeHandlePositions.BOTTOM_LEFT);
      positionSet.delete(resizeHandlePositions.CENTER_LEFT);
    }
  }

  if (
    positionSet.has(resizeHandlePositions.BOTTOM_RIGHT) ||
    positionSet.has(resizeHandlePositions.CENTER_RIGHT)
  ) {
    const { marginRightStyleProperty } = element;
    if (marginRightStyleProperty !== 'auto') {
      positionSet.delete(resizeHandlePositions.BOTTOM_RIGHT);
      positionSet.delete(resizeHandlePositions.CENTER_RIGHT);
    }
  }

  if (isPositioningImage(element)) {
    const elementBounds = element.getBoundingClientRect();
    const positioningAreaBounds = element.parentElement.getBoundingClientRect();
    const isElementOutsidePositioningAreaAlongYAxis =
      Math.round(elementBounds.height) > Math.round(positioningAreaBounds.height);

    if (isElementOutsidePositioningAreaAlongYAxis) {
      positionSet.delete(resizeHandlePositions.TOP_LEFT);
      positionSet.delete(resizeHandlePositions.TOP_CENTER);
      positionSet.delete(resizeHandlePositions.TOP_RIGHT);
    }
  }

  const result = {};

  positionSet.forEach((pos) => {
    result[pos] = handlersConfig[pos];
  });
  return result;
};

export default getVisibleResizeTriggers;
