import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { i18next } from '@yola/ws-sdk';
import { Text, Icon, PanelLeft, PanelRight } from '@yola/ws-ui';

function ModalHeader({
  enableSelect,
  switchSelectMode,
  selectedCount,
  onSelectAllItems,
  onDeleteItems,
  galleryItemsCount,
}) {
  if (enableSelect) {
    return (
      <Fragment>
        <PanelLeft>
          <Text type="heading-6">
            {selectedCount} {i18next.t('selected')}
          </Text>
        </PanelLeft>
        <PanelRight>
          <div className="ws-gallery-modal-header__buttons">
            <Icon
              className="ws-gallery-modal-header__icon-button"
              glyph="double-check"
              disabled={galleryItemsCount === 0}
              size={23}
              onClick={galleryItemsCount > 0 ? onSelectAllItems : null}
            />

            <Icon
              className="ws-gallery-modal-header__icon-button"
              disabled={selectedCount === 0}
              glyph="trash"
              size={20}
              onClick={selectedCount > 0 ? onDeleteItems : null}
            />
            <Icon
              className="ws-gallery-modal-header__icon-button"
              glyph="x"
              size={23}
              onClick={switchSelectMode}
            />
          </div>
        </PanelRight>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <PanelLeft>
        <Text type="heading-6">{i18next.t('Gallery')}</Text>
      </PanelLeft>
      <PanelRight>
        <Text
          type="heading-6"
          onClick={switchSelectMode}
          className="ws-gallery-modal-header__button"
        >
          {i18next.t('Select')}
        </Text>
      </PanelRight>
    </Fragment>
  );
}

ModalHeader.propTypes = {
  enableSelect: PropTypes.bool.isRequired,
  switchSelectMode: PropTypes.func.isRequired,
  selectedCount: PropTypes.number.isRequired,
  onSelectAllItems: PropTypes.func.isRequired,
  onDeleteItems: PropTypes.func.isRequired,
  galleryItemsCount: PropTypes.number.isRequired,
};

export default ModalHeader;
