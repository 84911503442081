import trackers from './trackers';

const initializeTrackers = () => {
  trackers.trackAppOpened();
  trackers.trackPageCreated();
  trackers.trackBlockAdded();
  trackers.trackBlockShifted();
  trackers.trackWidgetShifted();
  trackers.trackBlockDuplicated();
  trackers.trackWidgetDuplicated();
  trackers.trackBlockCopied();
  trackers.trackBlockCutout();
  trackers.trackBlockPasted();
  trackers.trackBlockDeleted();
  trackers.trackWidgetDeleted();
  trackers.trackWidgetAdded();
  trackers.trackContentCopied();
  trackers.trackContentCutout();
  trackers.trackContentPasted();
  trackers.trackSiteSaveInitiated();
  trackers.trackSiteSaveCompleted();
  trackers.trackSiteElementClicked();
  trackers.trackSiteLoaded();
};

export default initializeTrackers;
