import { view, anodum, linkEditing } from '@yola/ws-sdk';

const buildLinkElement = (elementId, config, title) => {
  const builtElement = view.accessors.getStaticElement(elementId).cloneNode(true);

  if (title && builtElement.innerText.trim() !== title) {
    let nodeToChange;
    anodum.traverseNode(builtElement, (node) => {
      if (anodum.isTextNode(node) && node.nodeValue.trim().length && !nodeToChange) {
        nodeToChange = node.parentNode;
      }
    });

    nodeToChange.innerText = title;
  }

  builtElement.setAttribute(
    linkEditing.constants.common.LINK_CONFIG_ATTRIBUTE,
    JSON.stringify(config)
  );

  return builtElement;
};

export default buildLinkElement;
