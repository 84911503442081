import chunkArray from './chunk-array';
import getElementsWithHeight from './get-elements-with-height';

const getElementsWithHeightInRow = (element, groupElements, withoutCurrent = true) => {
  const currentElementIndex = groupElements.indexOf(element);
  const columnsWrapper = element.closest('ws-columns');
  if (!columnsWrapper) return [];

  const layout = columnsWrapper.getAttribute('layout');
  if (!layout) return [];

  const countInRow = parseInt(layout, 10);
  const elementPlace = Math.ceil((currentElementIndex + 1) / countInRow) - 1;
  const chunks = chunkArray(groupElements, countInRow);

  let elements = chunks[elementPlace];

  if (withoutCurrent) {
    elements = elements.filter((item) => item !== element);
  }

  return getElementsWithHeight(elements);
};

export default getElementsWithHeightInRow;
