import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { i18next, saving, dialogs } from '@yola/ws-sdk';
import { designSystem } from '@yola/ws-ui';
import { selectors } from '@yola/subscription-manager-js';
import { saveSiteWithEffect } from 'src/js/modules/saving/helpers/save-site';
import segment from 'src/js/modules/analytics/segment';
import user from 'yola-editor/src/js/modules/user';
import site from 'yola-editor/src/js/modules/site';
import useFeatureFlags from 'yola-editor/src/js/modules/feature-flags/hooks/use-feature-flags';
import actions from '../actions';
import registerInterruptionPoints from '../initializer/register-interruption-points';
import dialogTypes from '../../dialogs/constants/dialog-types';

const { EditorHeader } = designSystem;
const { platformComponents } = user.constants;
const {
  trackers: { trackEvent },
  constants: { events, triggerIds },
} = segment;

const PublishContainer = () => {
  const [isPublishBlocked, setIsPublishBlocked] = useState(false);

  const savingStatus = useSelector(saving.selectors.getStatus);
  const availablePlatformComponents = useSelector(user.selectors.getAvailablePlatformComponents);
  const siteURL = useSelector(site.selectors.getSiteURL);
  const activeHostingSubscription = useSelector(selectors.getActiveHostingSubscription);

  const dispatch = useDispatch();

  const [featureFlags] = useFeatureFlags(['upgrade_to_publish']);
  const { upgrade_to_publish: isUpgradeToPublishEnabled } = featureFlags;

  const initiatePublishing = () => {
    const { ERROR, SAVING } = saving.constants.statuses;

    /**
     * In case when user has unsaved data publishing initiates the saving.
     * But in case, when user clicks `Publish` button during the saving,
     * we may see different errors, connected to try to save already saved data.
     * To avoid that we decided to ignore publish button click during saving.
     */
    if (savingStatus === ERROR || savingStatus === SAVING) return;

    setIsPublishBlocked(true);

    saveSiteWithEffect(async () => {
      dispatch(actions.initiatePublishWebsite());

      setIsPublishBlocked(false);
    });
  };

  const handlePublishClick = () => {
    trackEvent(events.PUBLISH_TRIGGER_CLICKED, {
      triggerId: triggerIds.EDITOR_HEADER,
    });

    if (
      (!siteURL || !activeHostingSubscription) &&
      isUpgradeToPublishEnabled &&
      availablePlatformComponents.includes(platformComponents.DOMAINS)
    ) {
      dispatch(dialogs.actions.show(dialogTypes.PUBLISH_INTERRUPTION_DIALOG));
      return;
    }

    initiatePublishing();
  };

  useEffect(() => {
    dispatch(registerInterruptionPoints());
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  return (
    <EditorHeader.PublishButton
      disabled={isPublishBlocked}
      onClick={handlePublishClick}
      label={i18next.t('Publish')}
      popoverProps={{
        title: i18next.t('Publish website'),
        content: i18next.t('Push your site live to the Internet for the world to see'),
      }}
    />
  );
};

export default PublishContainer;
