export const cache = new Map();

const getCanvasArea = (canvas, area, context = document) => {
  const ctx = canvas.getContext('2d');

  const {
    position: { x, y },
    width,
    height,
  } = area;

  const canvasUUID = canvas.getAttribute('data-uuid');

  const cacheKey = canvasUUID;

  if (cache.has(cacheKey)) {
    return cache.get(cacheKey);
  }

  const box = ctx.getImageData(x, y, width, height);

  const destCanvas = context.createElement('canvas');
  destCanvas.width = width;
  destCanvas.height = height;
  const destCtx = destCanvas.getContext('2d');

  destCtx.putImageData(box, 0, 0);
  cache.set(cacheKey, destCanvas);
  return destCanvas;
};

export default getCanvasArea;
