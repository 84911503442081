import handlersCollection from '../constants/handlers-collection';
import prefixes from '../constants/prefixes';

const has = (config) => {
  const { reason, prefix = prefixes.AFTER } = config;
  const identifier = `${prefix}-${reason}`;

  return handlersCollection.has(identifier);
};

export default has;
