import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import { textFormatting, contentEditable } from '@yola/ws-sdk';
import Trigger from 'src/js/modules/common/components/trigger';
import withSelection from 'src/js/modules/utils/with-selection';
import useTextFormattingKeyHandler from '../hooks/use-text-formatting-key-handler';

const { Icon } = designSystem;

const TextFormattingActionTrigger = ({
  id,
  icon,
  triggerId,
  getTooltip,
  className,
  tagName,
  keyCode,
  shiftKey,
}) => {
  const handleClick = () => {
    contentEditable.operations.addCheckpoint();
    textFormatting.operations.toggleWrapper(id);
  };

  useTextFormattingKeyHandler([{ id, className, tagName, keyCode, shiftKey }]);

  return (
    <Trigger
      data-tip={getTooltip()}
      id={triggerId}
      active={textFormatting.verifiers.isWrappedBy(id)}
      onClick={handleClick}
    >
      <Icon glyph={icon} size="24" strokeWidth="1.3" />
    </Trigger>
  );
};

TextFormattingActionTrigger.propTypes = {
  id: PropTypes.string.isRequired,
  triggerId: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  tagName: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  getTooltip: PropTypes.func.isRequired,
  keyCode: PropTypes.string.isRequired,
  shiftKey: PropTypes.bool,
};

TextFormattingActionTrigger.defaultProps = {
  shiftKey: false,
};

export default withSelection(TextFormattingActionTrigger);
