import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { dialogs, errors, site, template } from '@yola/ws-sdk';
import { TabPanels as TabPanelsDefault } from '@yola/ws-ui';
import segment from '../../analytics/segment';

const TabPanels = (props) => {
  const { children, onChange, value, defaultValue, ...restProps } = props;
  const siteId = useSelector(site.selectors.getSiteId);
  const templateBuildSlug = useSelector(template.selectors.getBuildSlug);
  const { type: dialogId } = useSelector(dialogs.selectors.getDialog);
  const prevTabIndex = useRef(value || defaultValue);

  const handleTabChange = (tabIndex, tabId) => {
    if (prevTabIndex.current !== tabIndex) {
      if (tabId) {
        segment.track(segment.constants.events.DIALOG_ACTIVE_TAB_CHANGED, {
          siteId,
          templateBuildSlug,
          dialogId,
          tabId,
        });
      } else {
        const error = new Error('`tabId` should be specified for DIALOG_ACTIVE_TAB_CHANGED event');
        errors.operations.catchError(error, { isCritical: false });
      }

      prevTabIndex.current = tabIndex;
    }

    onChange(tabIndex, tabId);
  };

  return (
    <TabPanelsDefault
      onChange={handleTabChange}
      value={value}
      defaultValue={defaultValue}
      {...restProps}
    >
      {children}
    </TabPanelsDefault>
  );
};

TabPanels.propTypes = {
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.number,
  defaultValue: PropTypes.number,
};

TabPanels.defaultProps = {
  onChange: Function.prototype,
  value: null,
  defaultValue: 0,
};

export default TabPanels;
