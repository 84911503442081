import { view } from '@yola/ws-sdk';

const syncIconSize = (icons, size) => {
  const { setInlineElementStyle } = view.operations;

  const operations = icons
    .map((element) => {
      const elementId = view.accessors.getLiveElementId(element);

      return [
        [setInlineElementStyle, [elementId, 'width', `${size}px`]],
        [setInlineElementStyle, [elementId, 'height', `${size}px`]],
      ];
    })
    .flat();

  view.operations.bulkViewOperations(operations);
};

export default syncIconSize;
