import { view, anodum, hdrm, blocks } from '@yola/ws-sdk';
import highlighter from '../../highlighter';
import actions from '../actions';
import scrollToElement from './scroll-to-element';

function waitWsBlockLoading(element, callback) {
  if (anodum.isTag(element, 'ws-block') && element.ready === false) {
    element.addEventListener('ready', callback, { once: true });
  } else {
    callback();
  }
}

function waitForBlockAndScroll(store, blockPath, callback = Function.prototype) {
  // Get the new added live element via provided path to exclude
  // intersections between old and new generated ref_id's
  const staticDocument = hdrm.instance.driver.getStaticDocument();
  const staticBlockElement = anodum.getNodeByTreePath(staticDocument, blockPath);
  const liveBlockElement = hdrm.accessors.getLiveElementByStaticElement(staticBlockElement);

  if (liveBlockElement) {
    waitWsBlockLoading(liveBlockElement, () => {
      const state = store.getState();
      const viewLoaded = view.selectors.getLoadedStatus(state);

      if (viewLoaded) {
        scrollToElement(liveBlockElement).then(() => {
          highlighter.helpers.highlightElements([liveBlockElement]);

          const isElementBlock = blocks.verifiers.isElementBlock(liveBlockElement);
          const liveElementId = view.accessors.getLiveElementId(liveBlockElement);

          if (isElementBlock) {
            callback(liveElementId);
          }
        });
      } else {
        store.dispatch(actions.setScrollTargetPath(blockPath));
      }
    });
  }
}

export default waitForBlockAndScroll;
