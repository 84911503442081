import { getWhiteSpaceTopValue, getWhiteSpaceBottomValue } from './white-space-values';
import {
  WHITESPACE_TOP_ATTRIBUTE,
  WHITESPACE_BOTTOM_ATTRIBUTE,
} from '../constants/display-options';

function getBreakpointsSliderValue(attribute, targetElement) {
  const value = targetElement.getAttribute(attribute);

  if (value) return value;

  // Specific fallback values calculations.
  // Needed due to reason that some blocks could not have all related attributes.
  switch (attribute) {
    case WHITESPACE_TOP_ATTRIBUTE:
      return getWhiteSpaceTopValue(targetElement);

    case WHITESPACE_BOTTOM_ATTRIBUTE:
      return getWhiteSpaceBottomValue(targetElement);

    default:
      return null;
  }
}

export default getBreakpointsSliderValue;
