import React, { useMemo } from 'react';
import bowser from 'yola-bowser';
import PropTypes from 'prop-types';
import {
  Text,
  Modal,
  Formik,
  Field,
  CheckboxField,
  ButtonGroup,
  Button,
  PanelGroup,
  Panel,
  designSystem,
} from '@yola/ws-ui';
import { i18next, utils } from '@yola/ws-sdk';
import SearchableSelectField from 'src/js/modules/common/components/searchable-select-field';
import LanguageSelectField from 'src/js/modules/common/components/language-select-field';

const { ControlGroup } = designSystem;

function TranslatePageDialog({
  initialValues,
  onSubmit,
  onCancel,
  onCheckboxChange,
  pagesOptions,
  languageOptions,
  captions,
  fieldsValidation,
  roadmapUrl,
}) {
  const adaptiveLanguageOptions = useMemo(
    () =>
      languageOptions.map((item) => ({
        value: item.code,
        iconComponent: item.iconComponent,
        label: item.name,
      })),
    [languageOptions]
  );

  const adaptivePageOptions = useMemo(
    () =>
      pagesOptions[initialValues.langCode].map((item) => ({
        value: item.id,
        label: item.title,
      })),
    [initialValues.langCode, pagesOptions]
  );

  const renderLanguageSelect = (formikProps) => (
    <LanguageSelectField
      {...formikProps}
      options={adaptiveLanguageOptions}
      defaultValue={adaptiveLanguageOptions[0]}
      placeholder={i18next.t('Select...')}
    />
  );

  const findDefaultOption = (optionsArray, pageId) =>
    optionsArray.find((obj) => obj.value === pageId) || null;

  const renderPagesSelect = (formikProps) => (
    <SearchableSelectField
      {...formikProps}
      options={adaptivePageOptions}
      defaultValue={findDefaultOption(adaptivePageOptions, formikProps.field.value)}
      placeholder={i18next.t('Select...')}
      noResultsText={i18next.t('No results found')}
    />
  );

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <Modal
          overlay="visible"
          centered
          resizable={false}
          draggable={false}
          fullscreen={bowser.mobile}
          className="ws-translate-page-dialog"
          width={368}
          height={361}
          handleCancel={onCancel}
          handleSubmit={handleSubmit}
        >
          <PanelGroup height="100%">
            <Panel
              align="middle"
              className="ws-modal__content"
              theme="gray-100"
              corners="squared"
              height="48"
            >
              <Text type="heading-6">{captions.title}</Text>
            </Panel>
            <Panel className="ws-modal__content">
              <div className="ws-modal__block">
                <ControlGroup title={captions.fieldLabels.language}>
                  <Field
                    name="langCode"
                    validate={fieldsValidation.langCode}
                    render={renderLanguageSelect}
                  />
                </ControlGroup>
              </div>
              <div className="ws-modal__block">
                <ControlGroup title={captions.fieldLabels.page}>
                  <Field
                    name="pageId"
                    validate={fieldsValidation.page}
                    render={renderPagesSelect}
                  />
                </ControlGroup>
              </div>
              {roadmapUrl && (
                <div className="ws-modal__block">
                  <Field
                    name="autoTrans"
                    render={(formikProps) => (
                      <CheckboxField
                        {...formikProps}
                        id="autoTransId"
                        checked={formikProps.field.value}
                        onChange={() => {
                          onCheckboxChange(formikProps.form.values);
                        }}
                      >
                        {/* eslint-disable-next-line yola/jsx-a11y/label-has-for */}
                        <label htmlFor="autoTransId">
                          <Text type="heading-6">{captions.fieldLabels.checkbox}</Text>
                        </label>
                      </CheckboxField>
                    )}
                  />
                </div>
              )}
            </Panel>
            <Panel>
              <ButtonGroup block stick="top">
                <Button stretch="block" size="large" onClick={handleSubmit}>
                  {captions.submit}
                </Button>
                <Button stretch="block" size="large" onMouseDown={onCancel}>
                  {captions.cancel}
                </Button>
              </ButtonGroup>
            </Panel>
          </PanelGroup>
        </Modal>
      )}
    </Formik>
  );
}

TranslatePageDialog.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onCheckboxChange: PropTypes.func,
  roadmapUrl: PropTypes.string.isRequired,
  initialValues: PropTypes.shape({
    pageId: PropTypes.string,
    langCode: PropTypes.string,
    autoTrans: PropTypes.bool,
  }),
  fieldsValidation: PropTypes.shape({
    page: PropTypes.func,
    langCode: PropTypes.func,
  }),
  languageOptions: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  pagesOptions: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  captions: PropTypes.shape().isRequired,
};

TranslatePageDialog.defaultProps = {
  onCancel: utils.noop,
  onSubmit: utils.noop,
  onCheckboxChange: utils.noop,
  initialValues: null,
  fieldsValidation: PropTypes.shape({
    page: utils.noop,
    langCode: utils.noop,
  }),
};

export default TranslatePageDialog;
