const parseHslaColorString = (hslString) => {
  const circleBracketsRexExp = /\(([^)]+)\)/;

  const hslExecArray = circleBracketsRexExp.exec(hslString);
  if (!hslExecArray) {
    throw new Error('HSLA color is not valid');
  }
  const valueString = hslExecArray[1];
  const [h, s, l, o] = valueString.split(',').map((value) => parseFloat(value));
  return {
    h,
    s: s / 100,
    l: l / 100,
    a: o,
  };
};

export default parseHslaColorString;
