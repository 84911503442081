import { hdrm } from '@yola/ws-sdk/src/index';

const getDocumentSizes = () => {
  const liveDocument = hdrm.instance.driver.getLiveDocument();
  const bodySizes = liveDocument.body.getBoundingClientRect();

  return {
    documentScrollWidth: bodySizes.width,
    windowInnerWidth: liveDocument.defaultView.innerWidth,
    windowInnerHeight: liveDocument.defaultView.innerHeight,
  };
};

export default getDocumentSizes;
