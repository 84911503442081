import { useRef, useEffect } from 'react';

function useMounted() {
  const ref = useRef(false);
  useEffect(() => {
    ref.current = true;

    return () => {
      ref.current = false;
    };
  }, []);
  return ref;
}

export default useMounted;
