import { view } from '@yola/ws-sdk';
import parseItemsFromDOM from './parse-items-from-dom';

const getFields = (elementId) => {
  const container = view.accessors.getLiveElement(elementId);

  return parseItemsFromDOM(container);
};

export default getFields;
