import actionTypes from '../constants/action-types';

const sendVerificationEmail = (options = {}) => ({
  type: actionTypes.SEND_VERIFICATION_EMAIL,
  payload: {
    options,
  },
});

export default sendVerificationEmail;
