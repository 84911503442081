const parseRGBColorString = (rgbString) => {
  const circleBracketsRexExp = /\(([^)]+)\)/;
  const rgbExecArray = circleBracketsRexExp.exec(rgbString);
  if (!rgbExecArray) {
    throw new Error('RGB color is not valid');
  }
  const valueString = rgbExecArray[1];
  const [r, g, b, a = 1] = valueString.split(',').map((value) => parseFloat(value));
  return {
    r,
    g,
    b,
    a,
  };
};

export default parseRGBColorString;
