import getNewNumericPositionBySibling from './get-new-numeric-position-by-sibling';
import getOldNumericPositionBySibling from './get-old-numeric-position-by-sibling';
import getElementNumericPosition from '../../../utils/helpers/get-element-numeric-position';

function getShiftedBlockNumericPositions({ currentPosition, previousPosition }) {
  const { refElementId: newSiblingRefId, position: blockNewAdjacentPosition } = currentPosition;
  const newSiblingNumericPosition = getElementNumericPosition(newSiblingRefId);
  const blockNewNumericPosition = getNewNumericPositionBySibling(
    blockNewAdjacentPosition,
    newSiblingNumericPosition
  );

  const { refElementId: oldSiblingRefId, position: blockOldAdjacentPosition } = previousPosition;
  const oldSiblingNumericPosition = getElementNumericPosition(oldSiblingRefId);
  const blockOldNumericPosition = getOldNumericPositionBySibling(
    blockOldAdjacentPosition,
    oldSiblingNumericPosition,
    blockNewNumericPosition
  );

  return {
    newPosition: blockNewNumericPosition,
    oldPosition: blockOldNumericPosition,
  };
}

export default getShiftedBlockNumericPositions;
