import { anodum } from '@yola/ws-sdk';
import { getSettings } from '../settings';

const hasBackgroundContainer = (node) => {
  const settings = getSettings();
  return (
    !anodum.isTag(node, 'img') &&
    node.matches(settings.backgroundImageNodeSelector) &&
    Boolean(node.querySelector(settings.backgroundContainer))
  );
};

export default hasBackgroundContainer;
