import actionTypes from '../constants/action-types';

const active = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SHOW:
      return action.payload;
    case actionTypes.HIDE:
      return {};
    default:
      return state;
  }
};

export default active;
