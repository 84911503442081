import { utils, anodum, bowser } from '@yola/ws-sdk';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function waitWsBlocksLoading(targetDocument, onSuccess, onFail = () => {}) {
  const wsBlocksNodes = [...targetDocument.querySelectorAll('ws-block[src]')];
  // comparison with `false` in order to avoid the case when imported site
  // may have `ws-block` which is different from our ws-block component
  const nonReadyBlocks = wsBlocksNodes.filter((wsBlock) => wsBlock.ready === false);

  if (!nonReadyBlocks.length) {
    onSuccess();
    return;
  }

  const blockLoadingQue = nonReadyBlocks.map(
    (wsBlock) =>
      new Promise((resolve, reject) => {
        function onReady() {
          resolve();

          // eslint-disable-next-line
          wsBlock.removeEventListener('error', onError);
        }

        function onError() {
          reject();

          wsBlock.removeEventListener('ready', onReady);
        }

        wsBlock.addEventListener('ready', onReady, { once: true });
        wsBlock.addEventListener('error', onError, { once: true });
      })
  );

  Promise.all(blockLoadingQue).then(onSuccess, onFail);
}

class Frame extends React.Component {
  constructor() {
    super();
    this.injectDOM = this.injectDOM.bind(this);
  }

  componentDidMount() {
    const { iframe } = this;
    const { contentWindow } = iframe;
    const { src, onBeforeLoad } = this.props;

    onBeforeLoad(iframe);

    if (src && typeof src !== 'string') {
      this.injectDOM(contentWindow);
    }
  }

  getDoctypeAsString() {
    const { src } = this.props;
    if (!src.doctype) return '';
    return anodum.serializeDocumentType(src);
  }

  injectDOM(contentWindow) {
    const { document } = contentWindow;
    const { onDocumentFetch, src, onDocumentReady } = this.props;

    const handleDomInjection = () => {
      document.open();
      document.onreadystatechange = () => {
        if (document.readyState === 'interactive') {
          waitWsBlocksLoading(document, onDocumentReady, onDocumentReady);
        }
      };

      document.write(this.getDoctypeAsString() + src.documentElement.outerHTML);
      document.close();

      onDocumentFetch(document);
    };

    /** Safari handles window.onload synchronously, that means that all handlers that we set on window.load or similar
     * events would be fired immediately without getting into event loop task queue. See this codepen example: https://codepen.io/iGoCooper/pen/wvzvXPZ
     * Problem appears when we have handlers in hdrm that gets data from liveDocument, and than run some code which reloads view, which will turns into situation when all
     * collected data becomes unreliable, also we can't prevent action by checking view loading status as it's being set to `loaded` already in `windowLoaderHandler` which is fired
     * on window.load(). So by wrapping this code in SetTimeout we make sure that all synchronious code will run before set view loading status to true.
     * So we would be able to change logic or prevent it.
     */
    if (bowser.safari) {
      setTimeout(handleDomInjection, 0);
    } else {
      handleDomInjection();
    }
  }

  render() {
    const { id, src, onLoad, className, sandbox } = this.props;
    const srcLink = typeof src === 'string' ? src : 'about:blank';
    const frameClass = classNames('ws-syncy-frame-window', className);

    return (
      <iframe
        id={id}
        title={id}
        name="syncy-frame"
        src={srcLink}
        ref={(iframe) => {
          this.iframe = iframe;
        }}
        className={frameClass}
        allowFullScreen
        onLoad={() => {
          if (src && typeof src !== 'string') {
            waitWsBlocksLoading(this.iframe.contentWindow.document, () => {
              onLoad(this.iframe);
            });
          } else {
            onLoad(this.iframe);
          }
        }}
        sandbox={sandbox}
        translate="no"
      />
    );
  }
}

Frame.defaultProps = {
  id: 'syncy-frame-instance',
  className: null,
  onBeforeLoad: utils.noop,
  onLoad: utils.noop,
  onDocumentFetch: utils.noop,
  onDocumentReady: utils.noop,
  sandbox:
    'allow-scripts allow-forms allow-same-origin allow-popups allow-pointer-lock allow-presentation',
};

Frame.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  onBeforeLoad: PropTypes.func,
  onLoad: PropTypes.func,
  onDocumentFetch: PropTypes.func,
  onDocumentReady: PropTypes.func,
  sandbox: PropTypes.string,
};

export default Frame;
