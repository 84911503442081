import React from 'react';
import { connect } from 'react-redux';
import { view } from '@yola/ws-sdk';

const withSelection = (Component) => {
  const mapStateToProps = (state) => ({
    selection: view.selectors.getSelection(state),
  });
  const ConnectedComponent = connect(mapStateToProps)(Component);
  return (props) => <ConnectedComponent {...props} />;
};

export default withSelection;
