import React from 'react';
import bowser from 'yola-bowser';
import { VoteForFeature, Modal, Button } from '@yola/ws-ui';
import PropTypes from 'prop-types';
import { i18next } from '@yola/ws-sdk';

const getCaptions = () => ({
  title: i18next.t('Vote for this feature'),
  description: i18next.t(
    'This functionality is not available yet. We are working towards adding new features. Help us understand what is most important for you'
  ),
  vote: i18next.t('Vote now'),
});

const VoteForFeatureContainer = ({ onClose, onVoteClick, onDialogCancel, roadmapUrl, ...rest }) => {
  const handleClose = () => {
    onDialogCancel();
    onClose({ roadmapUrl, ...rest });
  };
  const captions = getCaptions();

  return (
    <Modal
      resizable={false}
      draggable={false}
      fullscreen={bowser.mobile}
      overlay="visible"
      centered
      width={574}
      height="auto"
      maxHeight={850}
      className="ws-vote-for-feature-modal"
      onOverlayClick={handleClose}
      handleCancel={handleClose}
    >
      <div className="ws-vote-for-feature-modal__close-button">
        <Button type="link" size="small" onClick={handleClose}>
          {i18next.t('Close')}
        </Button>
      </div>
      <VoteForFeature
        captions={captions}
        href={roadmapUrl}
        imageUrl="/images/vote-icon.svg"
        onVoteClick={onVoteClick}
      />
    </Modal>
  );
};

VoteForFeatureContainer.defaultProps = {
  onVoteClick: Function.prototype,
};

VoteForFeatureContainer.propTypes = {
  roadmapUrl: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
  onVoteClick: PropTypes.func,
};

export default VoteForFeatureContainer;
