import {
  BRANDED_FOOTER,
  BRANDED_HEADER,
  CUSTOM_CODE,
  CUSTOM_DOMAIN,
  EXTERNAL_LINKS,
  MOBILE_PLUS,
  MULTILINGUAL,
  NOFOLLOW_CONTROLS,
  ONLINE_STORE,
  PAGES_COUNT,
  PREMIUM_BLOCKS,
  SITE_BACKUPS,
  STORAGE_LIMIT,
  UPLOAD_FILE_SIZE,
  PREMIUM_ICONS,
  ADVANCED_COLOR_CUSTOMIZATION,
  PASSWORD_PROTECTED_PAGES,
  FORM_ATTACHMENTS,
  AI_PAGE_WIZARD,
  AI_BLOCK_GENERATION_QUOTA,
  AI_TEXT_ASSISTANT_QUOTA,
  PREMIUM_BUTTON_PRESETS,
  PREMIUM_TEXT_DECORATIONS,
} from '../constants/upsell-types';

function isUpsellAllowed(upgradeDetails, limits) {
  switch (upgradeDetails.upsellType) {
    case PREMIUM_BLOCKS: {
      const limit = limits.premiumBlocks;
      return !!limit.available;
    }
    case MOBILE_PLUS: {
      const limit = limits.mobilePlus;
      return !!limit.available;
    }
    case CUSTOM_CODE: {
      const limit = limits.customCode;
      return !!limit.available;
    }
    case ONLINE_STORE: {
      const limit = limits.onlineStore;
      return !!limit.available;
    }
    case BRANDED_FOOTER: {
      const limit = limits.brandedFooter;
      return !!limit.removable;
    }
    case BRANDED_HEADER: {
      const limit = limits.brandedHeader;
      return !!limit.removable;
    }
    case PAGES_COUNT: {
      const limit = limits.pages;
      return upgradeDetails.count < limit.amount;
    }
    case STORAGE_LIMIT: {
      const limit = limits.storageLimit;
      /**
       * It's strange condition, in previous implementation it was
       *  case STORAGE_LIMIT: {
       *   const limit = limitDetectors.storageLimit(activeSubscriptions);
       *     return (
       *       limit.maxSize === features.limits.PREMIUM_STORAGE_SIZE ||
       *       upgradeDetails.size < limit.maxSize
       *     );
       *  }
       *
       * it seems like we have to double-check this upsell
       */
      return limit.upgradeAvailable === false || upgradeDetails.size < limit.maxSize;
    }
    case UPLOAD_FILE_SIZE: {
      const limit = limits.uploadFileSize;
      return upgradeDetails.size < limit.maxSize;
    }
    case CUSTOM_DOMAIN: {
      const limit = limits.customDomain;
      return !!limit.available;
    }
    case NOFOLLOW_CONTROLS: {
      const limit = limits.nofollowControls;
      return !!limit.available;
    }
    case SITE_BACKUPS: {
      const limit = limits.siteBackupsLimit;
      return !!limit.available;
    }
    case MULTILINGUAL: {
      const limit = limits.multilingualLimit;
      return upgradeDetails.count < limit.amount;
    }
    case EXTERNAL_LINKS: {
      const limit = limits.externalLinks;
      return !!limit.available;
    }
    case PREMIUM_ICONS: {
      const limit = limits.premiumIcons;
      return !!limit.available;
    }
    case ADVANCED_COLOR_CUSTOMIZATION: {
      const limit = limits.advancedColorCustomization;
      return !!limit.available;
    }
    case PASSWORD_PROTECTED_PAGES: {
      const limit = limits.passwordProtectedPages;
      return !!limit.available;
    }
    case FORM_ATTACHMENTS: {
      const limit = limits.formAttachments;
      return !!limit.available;
    }
    case AI_PAGE_WIZARD: {
      const limit = limits.aiPageWizard;
      return !!limit.available;
    }
    case AI_BLOCK_GENERATION_QUOTA: {
      const limit = limits.aiBlockGenerationQuota;
      return upgradeDetails.amount < limit.amount;
    }
    case AI_TEXT_ASSISTANT_QUOTA: {
      const limit = limits.aiTextAssistantQuota;
      return upgradeDetails.amount < limit.amount;
    }
    case PREMIUM_BUTTON_PRESETS: {
      const limit = limits.premiumButtonPresets;
      return !!limit.available;
    }
    case PREMIUM_TEXT_DECORATIONS: {
      const limit = limits.premiumTextDecorations;
      return !!limit.available;
    }
    default:
      return true;
  }
}

export default isUpsellAllowed;
