import React from 'react';

function ProductsPreview() {
  return (
    <svg viewBox="0 0 1920 2126" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="1920" height="2126" fill="white" />
      <rect width="1920" height="120" fill="white" />
      <rect opacity="0.8" x="375" y="44" width="100" height="32" rx="8" fill="#DADDE0" />
      <rect x="993" y="48" width="100" height="24" rx="8" fill="#B0B8BF" />
      <rect opacity="0.8" x="1125" y="48" width="100" height="24" rx="8" fill="#DADDE0" />
      <rect opacity="0.8" x="1257" y="48" width="100" height="24" rx="8" fill="#DADDE0" />
      <rect x="1405" y="32" width="140" height="56" rx="8" fill="#8E959E" />
      <rect opacity="0.4" x="1433" y="52" width="84" height="16" rx="8" fill="white" />
      <rect width="1920" height="1326" transform="translate(0 120)" fill="white" />
      <rect x="761" y="200" width="398" height="48" rx="8" fill="#B0B8BF" />
      <rect opacity="0.8" x="649" y="288" width="622" height="24" rx="8" fill="#DADDE0" />
      <rect opacity="0.8" x="743.5" y="332" width="433" height="24" rx="8" fill="#DADDE0" />
      <rect opacity="0.8" x="375" y="420" width="370" height="260" rx="16" fill="#B0B8BF" />
      <g opacity="0.8">
        <path
          d="M593.334 561.111L581.886 549.664C577.981 545.759 571.649 545.759 567.744 549.664L534.074 583.334M535 583.334H585C589.603 583.334 593.334 579.603 593.334 575V525C593.334 520.398 589.603 516.667 585 516.667H535C530.398 516.667 526.667 520.398 526.667 525V575C526.667 579.603 530.398 583.334 535 583.334ZM547.037 542.593C550.106 542.593 552.593 540.106 552.593 537.037C552.593 533.969 550.106 531.482 547.037 531.482C543.969 531.482 541.482 533.969 541.482 537.037C541.482 540.106 543.969 542.593 547.037 542.593Z"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M593.334 561.111L581.886 549.664C577.981 545.759 571.649 545.759 567.744 549.664L534.074 583.334M535 583.334H585C589.603 583.334 593.334 579.603 593.334 575V525C593.334 520.398 589.603 516.667 585 516.667H535C530.398 516.667 526.667 520.398 526.667 525V575C526.667 579.603 530.398 583.334 535 583.334ZM547.037 542.593C550.106 542.593 552.593 540.106 552.593 537.037C552.593 533.969 550.106 531.482 547.037 531.482C543.969 531.482 541.482 533.969 541.482 537.037C541.482 540.106 543.969 542.593 547.037 542.593Z"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect opacity="0.8" x="474.5" y="710" width="171" height="24" rx="8" fill="#DADDE0" />
      <rect x="509" y="750" width="102" height="24" rx="8" fill="#B0B8BF" />
      <rect opacity="0.8" x="775" y="420" width="370" height="260" rx="16" fill="#B0B8BF" />
      <g opacity="0.8">
        <path
          d="M993.334 561.111L981.886 549.664C977.981 545.759 971.649 545.759 967.744 549.664L934.074 583.334M935 583.334H985C989.603 583.334 993.334 579.603 993.334 575V525C993.334 520.398 989.603 516.667 985 516.667H935C930.398 516.667 926.667 520.398 926.667 525V575C926.667 579.603 930.398 583.334 935 583.334ZM947.037 542.593C950.106 542.593 952.593 540.106 952.593 537.037C952.593 533.969 950.106 531.482 947.037 531.482C943.969 531.482 941.482 533.969 941.482 537.037C941.482 540.106 943.969 542.593 947.037 542.593Z"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M993.334 561.111L981.886 549.664C977.981 545.759 971.649 545.759 967.744 549.664L934.074 583.334M935 583.334H985C989.603 583.334 993.334 579.603 993.334 575V525C993.334 520.398 989.603 516.667 985 516.667H935C930.398 516.667 926.667 520.398 926.667 525V575C926.667 579.603 930.398 583.334 935 583.334ZM947.037 542.593C950.106 542.593 952.593 540.106 952.593 537.037C952.593 533.969 950.106 531.482 947.037 531.482C943.969 531.482 941.482 533.969 941.482 537.037C941.482 540.106 943.969 542.593 947.037 542.593Z"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect opacity="0.8" x="874.5" y="710" width="171" height="24" rx="8" fill="#DADDE0" />
      <rect x="909" y="750" width="102" height="24" rx="8" fill="#B0B8BF" />
      <rect opacity="0.8" x="1175" y="420" width="370" height="260" rx="16" fill="#B0B8BF" />
      <g opacity="0.8">
        <path
          d="M1393.33 561.111L1381.89 549.664C1377.98 545.759 1371.65 545.759 1367.74 549.664L1334.07 583.334M1335 583.334H1385C1389.6 583.334 1393.33 579.603 1393.33 575V525C1393.33 520.398 1389.6 516.667 1385 516.667H1335C1330.4 516.667 1326.67 520.398 1326.67 525V575C1326.67 579.603 1330.4 583.334 1335 583.334ZM1347.04 542.593C1350.1 542.593 1352.59 540.106 1352.59 537.037C1352.59 533.969 1350.1 531.482 1347.04 531.482C1343.97 531.482 1341.48 533.969 1341.48 537.037C1341.48 540.106 1343.97 542.593 1347.04 542.593Z"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1393.33 561.111L1381.89 549.664C1377.98 545.759 1371.65 545.759 1367.74 549.664L1334.07 583.334M1335 583.334H1385C1389.6 583.334 1393.33 579.603 1393.33 575V525C1393.33 520.398 1389.6 516.667 1385 516.667H1335C1330.4 516.667 1326.67 520.398 1326.67 525V575C1326.67 579.603 1330.4 583.334 1335 583.334ZM1347.04 542.593C1350.1 542.593 1352.59 540.106 1352.59 537.037C1352.59 533.969 1350.1 531.482 1347.04 531.482C1343.97 531.482 1341.48 533.969 1341.48 537.037C1341.48 540.106 1343.97 542.593 1347.04 542.593Z"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect opacity="0.8" x="1274.5" y="710" width="171" height="24" rx="8" fill="#DADDE0" />
      <rect x="1309" y="750" width="102" height="24" rx="8" fill="#B0B8BF" />
      <rect opacity="0.8" x="375" y="804" width="370" height="260" rx="16" fill="#B0B8BF" />
      <g opacity="0.8">
        <path
          d="M593.334 945.111L581.886 933.664C577.981 929.759 571.649 929.759 567.744 933.664L534.074 967.334M535 967.334H585C589.603 967.334 593.334 963.603 593.334 959V909C593.334 904.398 589.603 900.667 585 900.667H535C530.398 900.667 526.667 904.398 526.667 909V959C526.667 963.603 530.398 967.334 535 967.334ZM547.037 926.593C550.106 926.593 552.593 924.106 552.593 921.037C552.593 917.969 550.106 915.482 547.037 915.482C543.969 915.482 541.482 917.969 541.482 921.037C541.482 924.106 543.969 926.593 547.037 926.593Z"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M593.334 945.111L581.886 933.664C577.981 929.759 571.649 929.759 567.744 933.664L534.074 967.334M535 967.334H585C589.603 967.334 593.334 963.603 593.334 959V909C593.334 904.398 589.603 900.667 585 900.667H535C530.398 900.667 526.667 904.398 526.667 909V959C526.667 963.603 530.398 967.334 535 967.334ZM547.037 926.593C550.106 926.593 552.593 924.106 552.593 921.037C552.593 917.969 550.106 915.482 547.037 915.482C543.969 915.482 541.482 917.969 541.482 921.037C541.482 924.106 543.969 926.593 547.037 926.593Z"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect opacity="0.8" x="474.5" y="1094" width="171" height="24" rx="8" fill="#DADDE0" />
      <rect x="509" y="1134" width="102" height="24" rx="8" fill="#B0B8BF" />
      <rect opacity="0.8" x="775" y="804" width="370" height="260" rx="16" fill="#B0B8BF" />
      <g opacity="0.8">
        <path
          d="M993.334 945.111L981.886 933.664C977.981 929.759 971.649 929.759 967.744 933.664L934.074 967.334M935 967.334H985C989.603 967.334 993.334 963.603 993.334 959V909C993.334 904.398 989.603 900.667 985 900.667H935C930.398 900.667 926.667 904.398 926.667 909V959C926.667 963.603 930.398 967.334 935 967.334ZM947.037 926.593C950.106 926.593 952.593 924.106 952.593 921.037C952.593 917.969 950.106 915.482 947.037 915.482C943.969 915.482 941.482 917.969 941.482 921.037C941.482 924.106 943.969 926.593 947.037 926.593Z"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M993.334 945.111L981.886 933.664C977.981 929.759 971.649 929.759 967.744 933.664L934.074 967.334M935 967.334H985C989.603 967.334 993.334 963.603 993.334 959V909C993.334 904.398 989.603 900.667 985 900.667H935C930.398 900.667 926.667 904.398 926.667 909V959C926.667 963.603 930.398 967.334 935 967.334ZM947.037 926.593C950.106 926.593 952.593 924.106 952.593 921.037C952.593 917.969 950.106 915.482 947.037 915.482C943.969 915.482 941.482 917.969 941.482 921.037C941.482 924.106 943.969 926.593 947.037 926.593Z"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect opacity="0.8" x="874.5" y="1094" width="171" height="24" rx="8" fill="#DADDE0" />
      <rect x="909" y="1134" width="102" height="24" rx="8" fill="#B0B8BF" />
      <rect opacity="0.8" x="1175" y="804" width="370" height="260" rx="16" fill="#B0B8BF" />
      <g opacity="0.8">
        <path
          d="M1393.33 945.111L1381.89 933.664C1377.98 929.759 1371.65 929.759 1367.74 933.664L1334.07 967.334M1335 967.334H1385C1389.6 967.334 1393.33 963.603 1393.33 959V909C1393.33 904.398 1389.6 900.667 1385 900.667H1335C1330.4 900.667 1326.67 904.398 1326.67 909V959C1326.67 963.603 1330.4 967.334 1335 967.334ZM1347.04 926.593C1350.1 926.593 1352.59 924.106 1352.59 921.037C1352.59 917.969 1350.1 915.482 1347.04 915.482C1343.97 915.482 1341.48 917.969 1341.48 921.037C1341.48 924.106 1343.97 926.593 1347.04 926.593Z"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1393.33 945.111L1381.89 933.664C1377.98 929.759 1371.65 929.759 1367.74 933.664L1334.07 967.334M1335 967.334H1385C1389.6 967.334 1393.33 963.603 1393.33 959V909C1393.33 904.398 1389.6 900.667 1385 900.667H1335C1330.4 900.667 1326.67 904.398 1326.67 909V959C1326.67 963.603 1330.4 967.334 1335 967.334ZM1347.04 926.593C1350.1 926.593 1352.59 924.106 1352.59 921.037C1352.59 917.969 1350.1 915.482 1347.04 915.482C1343.97 915.482 1341.48 917.969 1341.48 921.037C1341.48 924.106 1343.97 926.593 1347.04 926.593Z"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect opacity="0.8" x="1274.5" y="1094" width="171" height="24" rx="8" fill="#DADDE0" />
      <rect x="1309" y="1134" width="102" height="24" rx="8" fill="#B0B8BF" />
      <rect x="816" y="1222" width="24" height="24" rx="8" fill="#B0B8BF" />
      <rect opacity="0.8" x="774" y="1262" width="108" height="24" rx="8" fill="#DADDE0" />
      <rect x="948" y="1222" width="24" height="24" rx="8" fill="#B0B8BF" />
      <rect opacity="0.8" x="906" y="1262" width="108" height="24" rx="8" fill="#DADDE0" />
      <rect x="1080" y="1222" width="24" height="24" rx="8" fill="#B0B8BF" />
      <rect opacity="0.8" x="1038" y="1262" width="108" height="24" rx="8" fill="#DADDE0" />
      <rect width="1920" height="560" transform="translate(0 1446)" fill="#B0B8BF" />
      <rect x="761" y="1606" width="398" height="48" rx="8" fill="white" />
      <rect opacity="0.4" x="649" y="1674" width="622" height="24" rx="8" fill="white" />
      <rect opacity="0.4" x="743.5" y="1718" width="433" height="24" rx="8" fill="white" />
      <rect x="868" y="1790" width="184" height="56" rx="8" fill="white" />
      <rect x="918" y="1810" width="84" height="16" rx="8" fill="#B0B8BF" />
      <rect width="1920" height="120" transform="translate(0 2006)" fill="#646C75" />
      <g opacity="0.3">
        <rect x="770" y="2054" width="100" height="24" rx="8" fill="white" />
        <rect x="910" y="2054" width="100" height="24" rx="8" fill="white" />
        <rect x="1050" y="2054" width="100" height="24" rx="8" fill="white" />
      </g>
    </svg>
  );
}

export default ProductsPreview;
