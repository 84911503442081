import actionTypes from '../constants/action-types';

const publishCount = (state = null, action) => {
  switch (action.type) {
    case actionTypes.SET_PUBLISH_COUNT:
      return action.payload.publishCount;
    default:
      return state;
  }
};

export default publishCount;
