import React, { useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { dialogs, i18next, utils, view } from '@yola/ws-sdk';
import contextMenu from 'src/js/modules/context-menu';
import ContextMenuItem from 'src/js/modules/context-menu/components/context-menu-item';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import getParentBlockByElement from 'src/js/modules/blocks/helpers/get-parent-block-by-element';

const { PHOTO_STOCK_DIALOG } = dialogTypes;

function UploadImageMenu({
  id,
  elementId,
  accept,
  onImageUpload,
  onStockPhotoUpload,
  onWrongFileType,
  onClick,
  onTriggerUploadImageClick,
  onTriggerBrowseStockPhotosClick,
  onPhotoStockDialogCancel,
  className,
  children,
  calculatePositionDecorator,
  getContextMenuPosition,
  triggerNodeRef: triggerNodeRefProp,
  preserveSharedData,
  multiple,
  fileTriggerRef,
  menu,
}) {
  const element = view.accessors.getLiveElement(elementId);
  const blockId = view.accessors.getLiveElementId(getParentBlockByElement(element));

  const triggerRef = useRef(null);
  const triggerNodeRef = triggerNodeRefProp || triggerRef;

  const groups = [
    {
      name: 'Upload image',
      element,
      items: [
        {
          id: 'upload-image',
          menuNode: (
            <ContextMenuItem
              caption={`${i18next.t('Upload a file')}...`}
              fileTriggerConfig={{
                id: 'upload-image',
                onChange: (fileList) => {
                  contextMenu.operations.hideContextMenu();
                  onImageUpload(fileList);
                },
                onWrongTypeFile: (...params) => {
                  contextMenu.operations.hideContextMenu();
                  onWrongFileType(...params);
                },
                accept,
                onClick: onTriggerUploadImageClick,
                forwardRef: fileTriggerRef,
                multiple,
              }}
            />
          ),
        },
        {
          id: 'browse-stock-images',
          caption: `${i18next.t('Browse free stock photos')}...`,
          onClick: () => {
            onTriggerBrowseStockPhotosClick();
            contextMenu.operations.hideContextMenu();
            dialogs.operations.show(PHOTO_STOCK_DIALOG, {
              multiple,
              onDialogMainAction: onStockPhotoUpload,
              onDialogCancel: onPhotoStockDialogCancel,
              preserveSharedData,
              blockId,
            });
          },
        },
      ],
    },
  ];

  const showContextMenu = () => {
    if (menu.triggerId === id) {
      contextMenu.operations.hideContextMenu();
      return;
    }

    const position = getContextMenuPosition() || { x: 0, y: 0 };
    contextMenu.operations.showContextMenu({
      position,
      groups,
      triggerId: id,
      calculatePosition: calculatePositionDecorator,
    });
  };

  return (
    <div ref={triggerNodeRef} className={className} id={id}>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          onClick: () => {
            showContextMenu();
            onClick();
          },
        })
      )}
    </div>
  );
}

UploadImageMenu.propTypes = {
  id: PropTypes.string.isRequired,
  elementId: PropTypes.string,
  className: PropTypes.string,
  onImageUpload: PropTypes.func.isRequired,
  onStockPhotoUpload: PropTypes.func.isRequired,
  onWrongFileType: PropTypes.func.isRequired,
  accept: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onTriggerUploadImageClick: PropTypes.func,
  onTriggerBrowseStockPhotosClick: PropTypes.func,
  onPhotoStockDialogCancel: PropTypes.func,
  calculatePositionDecorator: PropTypes.func,
  getContextMenuPosition: PropTypes.func,
  triggerNodeRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  children: PropTypes.node.isRequired,
  preserveSharedData: PropTypes.bool,
  multiple: PropTypes.bool,
  fileTriggerRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  menu: PropTypes.shape({
    triggerId: PropTypes.string,
  }).isRequired,
};

UploadImageMenu.defaultProps = {
  onClick: utils.noop,
  onTriggerUploadImageClick: utils.noop,
  onTriggerBrowseStockPhotosClick: utils.noop,
  onPhotoStockDialogCancel: utils.noop,
  calculatePositionDecorator: undefined,
  getContextMenuPosition: utils.noop,
  triggerNodeRef: null,
  elementId: null,
  className: '',
  preserveSharedData: false,
  multiple: false,
  fileTriggerRef: null,
};

const mapStateToProps = (state) => ({
  menu: contextMenu.selectors.getMenu(state),
});

export default connect(mapStateToProps)(UploadImageMenu);
