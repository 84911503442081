import { extensions } from '@yola/ws-sdk';
import constants from '../constants';

const { slug } = constants.common;

const isGenericGalleryItem = (item) => {
  const { defaultAssetsPathPrefix: path } = extensions.accessors.getExtensionSettings(slug);
  return !item.originSrc.includes(path);
};

export default isGenericGalleryItem;
