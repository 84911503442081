import { linkEditing } from '@yola/ws-sdk';

const { linkTypes } = linkEditing.constants;

const getItemClickAction = (linkType) => {
  switch (linkType) {
    case linkTypes.EXTERNAL:
    case linkTypes.PATH:
      return 'open-address';
    case linkTypes.PAGE:
      return 'open-page';
    case linkTypes.ANCHOR:
      return 'scroll-to-block';
    case linkTypes.MAIL:
      return 'send-an-email';
    case linkTypes.PHONE:
      return 'make-a-phone-call';
    case linkTypes.DOWNLOAD:
      return 'download-file';
    case linkTypes.NO_ACTION:
      return 'no-action';
    case linkTypes.VIEW_FULL_SIZE_IMAGE:
      return 'view-full-size-image';
    case linkTypes.SOCIAL_MEDIA:
      return 'social-media';
    case linkTypes.WHATS_APP:
      return 'whats-app';
    default:
      return null;
  }
};

export default getItemClickAction;
