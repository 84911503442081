import { extensions, view } from '@yola/ws-sdk';
import store from '../../../store';
import { EXTENSION_SLUG } from '../constants/common';

function openNavigationExtension(triggerElement, positionDirection) {
  const state = store.getState();

  const extension = extensions.selectors.getExtension(state, EXTENSION_SLUG);
  const settings = extensions.selectors.getExtensionSettings(state, EXTENSION_SLUG);

  const liveDocument = view.accessors.getLiveDocument();
  const navigationElement = liveDocument.querySelector(settings.querySelector);
  const navigationElementId = view.accessors.getLiveElementId(navigationElement);

  const control = extension.ui.control[0];

  control.openExtension(navigationElementId, triggerElement, positionDirection);
}

export default openNavigationExtension;
