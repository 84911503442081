import React from 'react';
import PropTypes from 'prop-types';
import { i18next } from '@yola/ws-sdk';
import { SubmitDialog } from '@yola/ws-ui';
import constants from '../constants';

const { CONFIRMATION_DIALOG_WIDTH, CONFIRMATION_DIALOG_HEIGHT } = constants;

function GalleryDialogConfirmation({
  onSubmit,
  onDiscard,
  onCancel,
  onDialogMainAction,
  onDialogCancel,
}) {
  const handleSubmit = (...args) => {
    onDialogMainAction();
    onSubmit(...args);
  };

  const handleDiscard = (...args) => {
    onDialogMainAction();
    onDiscard(...args);
  };

  const handleCancel = (...args) => {
    onDialogCancel();
    onCancel(...args);
  };

  return (
    <SubmitDialog
      onSubmit={handleSubmit}
      onDiscard={handleDiscard}
      onCancel={handleCancel}
      width={CONFIRMATION_DIALOG_WIDTH}
      height={CONFIRMATION_DIALOG_HEIGHT}
      minHeight={CONFIRMATION_DIALOG_HEIGHT}
      captions={{
        title: i18next.t('Keep default content?'),
        description: i18next.t('Gallery contains a demo content. Do you want to keep it?'),
        submit: i18next.t('Yes'),
        discard: i18next.t('No'),
        cancel: i18next.t('Cancel'),
      }}
    />
  );
}

GalleryDialogConfirmation.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onDiscard: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

export default GalleryDialogConfirmation;
