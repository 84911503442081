import { view } from '@yola/ws-sdk';
import concatLists from './concat-lists';

const changeListType = (listType, elements) => {
  const liveDocument = view.accessors.getLiveDocument();
  const list = liveDocument.createElement(listType);
  const sourceList = elements[0].parentNode;

  const nextNode = sourceList.nextSibling;
  const parentSouceNode = sourceList.parentNode;

  const concatedList = concatLists(list, sourceList);

  if (nextNode) {
    nextNode.parentNode.insertBefore(concatedList, nextNode);
  } else {
    parentSouceNode.appendChild(concatedList);
  }

  return concatedList;
};

export default changeListType;
