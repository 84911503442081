import actionTypes from '../constants/action-types';

const setNetworkStatus = (status) => ({
  type: actionTypes.SET_NETWORK_STATUS,
  payload: {
    status,
  },
});

export default setNetworkStatus;
