import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { i18next } from '@yola/ws-sdk';
import classNames from 'classnames';
import bowser from 'yola-bowser';

const isMobile = bowser.mobile;

const FloatingActionButton = ({ counter, onMouseDown }) => {
  const className = useMemo(
    () =>
      classNames('ws-floating-action-btn', {
        'ws-floating-action-btn--mobile': isMobile,
      }),
    []
  );
  const counterClassName = useMemo(() => {
    const counterSymbols = `${counter}`.split('').length;

    return classNames('ws-floating-action-btn__counter', {
      'ws-floating-action-btn__counter--sm': counterSymbols === 3,
      'ws-floating-action-btn__counter--xs': counterSymbols >= 4,
    });
  }, [counter]);

  return (
    <div className={className} onMouseDown={onMouseDown}>
      {counter > 0 && <span className={counterClassName}>{counter}</span>}
      {i18next.t('Website assistant')}
    </div>
  );
};

FloatingActionButton.propTypes = {
  counter: PropTypes.number,
  onMouseDown: PropTypes.func,
};

FloatingActionButton.defaultProps = {
  counter: 0,
  onMouseDown: null,
};

export default FloatingActionButton;
