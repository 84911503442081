import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { i18next } from '@yola/ws-sdk';
import { SubmitDialog } from '@yola/ws-ui';

const getCaptions = () => ({
  title: i18next.t('Save changes?'),
  description: i18next.t('Your page has unsaved changes. Would you like to save them?'),
  submit: i18next.t('Yes'),
  discard: i18next.t('No'),
  cancel: i18next.t('Cancel'),
});

const UnsavedChangesPromptContainer = ({ onSubmit, onCancel, onDiscard }) => {
  const captions = useMemo(() => getCaptions(), []);
  return (
    <SubmitDialog
      captions={captions}
      onSubmit={onSubmit}
      onCancel={onCancel}
      onDiscard={onDiscard}
    />
  );
};

UnsavedChangesPromptContainer.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDiscard: PropTypes.func.isRequired,
};

export default UnsavedChangesPromptContainer;
