import { extensions } from '@yola/ws-sdk';
import typedElementsMatches from './typed-elements-matches';
import constants from '../constants';

const textAlignMatches = () => {
  const { alignTextRestrictedParentsSelector, alignTextQuerySelector } =
    extensions.accessors.getExtensionSettings(constants.common.SLUG);

  const typedElements = typedElementsMatches();

  const containsRestrictedElements = typedElements.find((element) => {
    if (alignTextRestrictedParentsSelector && element.closest(alignTextRestrictedParentsSelector)) {
      return true;
    }

    if (alignTextQuerySelector && !element.closest(alignTextQuerySelector)) {
      return true;
    }

    if (element.style.textAlign) return false;

    const elementDisplayStyle = window.getComputedStyle(element).getPropertyValue('display');

    return elementDisplayStyle === 'inline';
  });

  if (containsRestrictedElements) return false;

  return Boolean(typedElements.length);
};

export default textAlignMatches;
