const optionTypes = {
  RADIO: 'radio',
  CHECKBOX: 'checkbox',
  COLOR_SCHEME: 'color-scheme',
  SURFACE: 'surface',
  SLIDER: 'slider',
  ONLINE_STORE_CATEGORY: 'online-store-category',
  ONLINE_STORE_CLEAN_URL_SELECTOR: 'online-store-clean-url-selector',
  BREAKPOINTS_SLIDER: 'breakpoints-slider',
  DROPDOWN: 'dropdown',
};

export default optionTypes;
