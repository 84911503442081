import { hooks } from '@yola/ws-sdk';
import track from '../track';
import constants from '../constants';
import prepareContentEventTrackData from '../helpers/prepare-content-event-track-data';

const trackContentCutout = () => {
  hooks.operations.subscribe(hooks.availableHooks.ON_TEXT_CUT, (event) => {
    const trackingData = prepareContentEventTrackData(event);
    if (!trackingData) return;
    // we can't access clipboard data from "Cut" event to determine content types trait
    // so we keep it as [text]
    trackingData.contentTypes = [constants.contentTypes.TEXT];
    track(constants.events.CONTENT_CUTOUT, trackingData);
  });
};

export default trackContentCutout;
