import handlersCollection from '../constants/handlers-collection';
import prefixes from '../constants/prefixes';

const on = (config, handler) => {
  const { reason, prefix = prefixes.AFTER } = config;
  const identifier = `${prefix}-${reason}`;
  const isHandlersExist = handlersCollection.has(identifier);

  if (isHandlersExist) {
    handlersCollection.get(identifier).push(handler);
  } else {
    handlersCollection.set(identifier, [handler]);
  }
};

export default on;
