import React from 'react';
import { extensions, i18next, dialogs, view } from '@yola/ws-sdk';
import { designSystem } from '@yola/ws-ui';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import tabIdentifiers from 'src/js/modules/website-settings/constants/tab-identifiers';
import settingTypes from 'src/js/modules/website-settings/constants/setting-types';
import slots from 'src/js/modules/common/constants/slots';
import constants from '../constants';
import Trigger from '../../../../common/components/trigger';

const { Icon } = designSystem;
const { TRIGGER_ID } = constants.common;

const control = {
  id: TRIGGER_ID,
  trigger: (
    <Trigger id={TRIGGER_ID}>
      <Icon glyph="settings" />
    </Trigger>
  ),
  onTriggerClick: (elementId) => {
    const settings = extensions.accessors.getExtensionSettings(constants.common.SLUG);
    const targetElement = view.accessors.getLiveElement(elementId);
    const slotElement = targetElement.closest(settings.querySelector);
    const slot = slotElement.getAttribute('slot');
    let settingToScroll;

    if (slot === slots.BUSINESS_PHONE) {
      settingToScroll = settingTypes.PHONES;
    }

    if (slot === slots.BUSINESS_ADDRESS) {
      settingToScroll = settingTypes.ADDRESSES;
    }

    if (slot === slots.BUSINESS_EMAIL) {
      settingToScroll = settingTypes.EMAILS;
    }

    if (slot === slots.BUSINESS_HOURS) {
      settingToScroll = settingTypes.BUSINESS_HOURS;
    }

    dialogs.operations.show(dialogTypes.WEBSITE_SETTINGS, {
      activeTabId: tabIdentifiers.BUSINESS_INFO,
      settingToScroll,
    });
  },
  get tooltip() {
    return i18next.t('Edit');
  },
  matches(liveElement) {
    const settings = extensions.accessors.getExtensionSettings(constants.common.SLUG);
    return liveElement.matches(settings.querySelector);
  },
  priority: 100,
};

export default control;
