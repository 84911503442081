import { view, linkEditing } from '@yola/ws-sdk';

const buildLinkElement = (linkConfig) => {
  const liveDocument = view.accessors.getLiveDocument();
  const builtElement = liveDocument.createElement('a');

  builtElement.setAttribute(
    linkEditing.constants.common.LINK_CONFIG_ATTRIBUTE,
    JSON.stringify(linkConfig)
  );

  return builtElement;
};

export default buildLinkElement;
