import { useEffect, useRef } from 'react';
import { PANE_SIZE_WITH_LINING } from 'src/js/modules/control-pane/constants/sizes';

const MAX_SELECT_HEIGHT = 580;

const usePaneSelectHeight = ({ offsetY, scrollPosition }) => {
  const rootEl = useRef(document.documentElement);

  useEffect(() => {
    const offsetBottom = offsetY - scrollPosition;
    const bottomFreeSpace = window.innerHeight - offsetBottom - PANE_SIZE_WITH_LINING;
    const maxHeight = bottomFreeSpace > MAX_SELECT_HEIGHT ? MAX_SELECT_HEIGHT : bottomFreeSpace;

    rootEl.current.style.setProperty('--control-pane-select-max-height', `${maxHeight}px`);
  }, [offsetY, scrollPosition]);
};

export default usePaneSelectHeight;
