import trackWebsiteSettingsDialogDisplayed from './track-website-settings-dialog-displayed';
import trackWebsiteSettingsDialogSubmitted from './track-website-settings-dialog-submitted';
import trackWebsiteSettingsDialogCanceled from './track-website-settings-dialog-canceled';
import trackAiTutorialClicked from './track-ai-tutorial-clicked';

export default {
  trackWebsiteSettingsDialogDisplayed,
  trackWebsiteSettingsDialogSubmitted,
  trackWebsiteSettingsDialogCanceled,
  trackAiTutorialClicked,
};
