import { combineReducers } from 'redux';
import { reducer, auth } from '@yola/ws-sdk';
import { connectRouter } from 'connected-react-router';
import { reducers as subscriptionManagerReducers } from '@yola/subscription-manager-js';
import yolaAuth from 'yola-editor/src/js/modules/auth';
import dialogs from 'yola-editor/src/js/modules/dialogs';
import error from 'yola-editor/src/js/modules/error';
import starterScreen from 'yola-editor/src/js/modules/starter-screen';
import publishing from 'yola-editor/src/js/modules/publishing';
import user from 'yola-editor/src/js/modules/user';
import site from 'yola-editor/src/js/modules/site';
import subscriptions from 'yola-editor/src/js/modules/subscriptions';
import i18n from 'yola-editor/src/js/modules/i18n';
import upsell from 'yola-editor/src/js/modules/upsell';
import dragNDrop from './modules/drag-n-drop';
import highlighter from './modules/highlighter';
import controlPane from './modules/control-pane';
import scroller from './modules/scroller';
import editor from './modules/editor';
import customTools from './modules/custom-tools';
import contextMenu from './modules/context-menu';
import customUI from './modules/custom-ui';
import previewMode from './modules/preview-mode';
import navbar from './modules/navbar';
import view from './modules/view';

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    ui: combineReducers({
      highlighter: combineReducers({
        elements: highlighter.reducers.elements,
        anchors: highlighter.reducers.anchors,
      }),
      controlPane: combineReducers({
        location: controlPane.reducers.controlPaneLocation,
      }),
      scroller: combineReducers({
        scrollTargetPath: scroller.reducers.scrollTargetPath,
      }),
      editor: combineReducers({
        isShown: editor.reducers.isShown,
        isLoaded: editor.reducers.isLoaded,
      }),
      customTools: combineReducers({
        registeredTools: customTools.reducers.registeredTools,
        activeTool: customTools.reducers.activeTool,
      }),
      contextMenu: combineReducers(contextMenu.reducers),
      customUI: combineReducers({
        active: customUI.reducers.active,
      }),
      dialogs: combineReducers(dialogs.reducers),
      networkErrors: combineReducers({
        status: error.reducers.networkStatus,
      }),
      starterScreen: combineReducers({
        progress: starterScreen.reducers.progress,
      }),
      publishing: combineReducers({
        status: publishing.reducers.status,
      }),
      navbar: combineReducers(navbar.reducers),
      previewMode: combineReducers(previewMode.reducers),
      disabledStatus: view.reducers.disabledStatus,
    }),
    ...reducer,
    dnd: combineReducers({ ...dragNDrop.reducers }),
    auth: combineReducers({
      ...yolaAuth.reducers,
      token: auth.reducers.token,
    }),
    user: combineReducers(user.reducers),
    site: combineReducers(site.reducers),
    subscriptions: combineReducers({
      ...subscriptionManagerReducers,
      isLoaded: subscriptions.reducers.isLoaded,
    }),
    i18n: combineReducers(i18n.reducers),
    criticalError: error.reducers.criticalError,
    upgradeOptions: upsell.reducers.upgradeOptions,
    domainPurchase: upsell.reducers.domainPurchase,
    paymentURL: upsell.reducers.paymentURL,
  });

export default rootReducer;
