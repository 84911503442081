import upsell from 'src/js/modules/upsell';

function getPageSettingsTraits(pageSettings) {
  const {
    name,
    displayInNavigation,
    htmlLocale,
    password,
    description,
    keywords,
    preventIndexing,
    footerCode,
    headCode,
    headerCode,
  } = pageSettings;

  return {
    pageName: name,
    displayInNavigation,
    languageCode: htmlLocale,
    pageAccess: password ? 'password-protected' : 'open-for-everyone',
    pageProtectedAvailable: upsell.verifiers.isFeatureAvailable(
      upsell.features.PASSWORD_PROTECTED_PAGES
    ),
    metaDescriptionAdded: Boolean(description && description.trim()),
    metaKeywordsAdded: Boolean(keywords && keywords.trim()),
    customCodeAdded: Boolean(footerCode || headCode || headerCode),
    hiddenFromSearch: Boolean(preventIndexing),
  };
}

export default getPageSettingsTraits;
