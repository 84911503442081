import React from 'react';
import { dialogs, extensions, view, i18next } from '@yola/ws-sdk';
import { Icon } from '@yola/ws-ui';
import Trigger from '../../../../common/components/trigger';
import dialogTypes from '../../../../dialogs/constants/dialog-types';
import constants from '../constants/common';
import helpers from '../helpers';

let initialItems;
let targetElementId;

const showDialog = (items, dialog, componentProps, oldItems) => {
  const props = { ...componentProps };

  // Show 'Item Settings' dialog in case item 'id' is passed
  if (dialog === dialogTypes.NAVIGATION_ITEM_SETTINGS_DIALOG && componentProps.id) {
    props.messages = {
      title: i18next.t('Item settings'),
      save: i18next.t('Submit'),
    };
  } else if (dialog === dialogTypes.NAVIGATION_ITEM_SETTINGS_DIALOG) {
    props.messages = {
      title: i18next.t('New Item'),
      save: i18next.t('Submit'),
    };
  } else if (dialog === dialogTypes.NAVIGATION_SETTINGS_DIALOG) {
    props.initialItems = oldItems || initialItems;
    props.elementId = targetElementId;
  }

  dialogs.operations.show(dialog, { showDialog, items, ...props });
};

const triggerId = 'navigation';

const control = {
  id: triggerId,
  trigger: (
    <Trigger id={triggerId}>
      <Icon glyph="hierarchy" size="24" strokeWidth="1.2" />
    </Trigger>
  ),
  get tooltip() {
    return i18next.t('Navigation settings');
  },
  priority: 120,
  title: 'Navigation settings',
  openExtension(elementId, triggerElement, positionDirection) {
    targetElementId = elementId;
    initialItems = helpers.getInitialItems(elementId);

    dialogs.operations.show(dialogTypes.NAVIGATION_SETTINGS_DIALOG, {
      items: initialItems,
      initialItems,
      elementId,
      showDialog,
      triggerElement,
      positionDirection,
    });
  },
  onTriggerClick(elementId) {
    targetElementId = elementId;
    initialItems = helpers.getInitialItems(elementId);

    dialogs.operations.show(dialogTypes.NAVIGATION_SETTINGS_DIALOG, {
      items: initialItems,
      initialItems,
      elementId,
      showDialog,
    });
  },
  matches(liveElement) {
    const liveDocument = view.accessors.getLiveDocument();

    const windowWidth = liveDocument.defaultView?.innerWidth;

    if (!windowWidth || windowWidth < constants.RESOLUTION_DISABLE_CONTROL) return false;

    const settings = extensions.accessors.getExtensionSettings(constants.SLUG);
    return liveElement.matches(settings.querySelector);
  },
};

export default control;
