import Vector from './vector';
import EulerMass from './euler-mass';

const { PI, sqrt, random, floor, cos, sin } = Math;
const DEGREES_TO_RADIANS = PI / 180;
const { devicePixelRatio } = window;

function getRandomArrayItem(array) {
  return array[floor(random() * array.length)];
}

function Ribbon(x, y, count, distance, thickness, angle, mass, drag, colors, width, height) {
  const [firstColor, secondColor] = getRandomArrayItem(colors);
  this.frontColor = firstColor;
  this.backColor = secondColor;
  this.particles = [];
  this.particleDistance = distance;
  this.particleCount = count;
  this.particleMass = mass;
  this.particleDrag = drag;
  this.xOff = cos(DEGREES_TO_RADIANS * angle) * thickness;
  this.yOff = sin(DEGREES_TO_RADIANS * angle) * thickness;

  this.position = new Vector(x, y);
  this.prevPosition = new Vector(x, y);
  this.bounds = new Vector(width, height);

  this.oscillationSpeed = random() * 2 + 2;
  this.oscillationDistance = random() * 40 + 40;
  this.velocityInherit = random() * 2 + 4;
  this.time = random() * 100;
  this.ySpeed = random() * 40 + 80;

  for (let i = 0; i < this.particleCount; i += 1) {
    this.particles[i] = new EulerMass(
      x,
      y - i * this.particleDistance,
      this.particleMass,
      this.particleDrag
    );
  }

  this.update = (value) => {
    this.updatePreviousPosition();
    this.updateFirstParticlePosition(value);

    const delta = this.getDelta();

    for (let index = 1; index < this.particleCount; index += 1) {
      this.updateParticlesDirection(delta, value, index);
      this.updateParticlesPosition(index);
    }

    if (this.position.y > this.bounds.y + this.particleDistance * this.particleCount) {
      this.reset();
    }
  };

  this.reset = () => {
    this.particles = [];
    this.position.y = -random() * this.bounds.y;
    this.position.x = random() * this.bounds.x;
    this.time = random() * 100;

    for (let i = 0; i < this.particleCount; i += 1) {
      this.particles[i] = new EulerMass(
        this.position.x,
        this.position.y - i * this.particleDistance,
        this.particleMass,
        this.particleDrag
      );
    }
  };

  this.draw = (ctx) => {
    for (let i = 0; i < this.particleCount - 1; i += 1) {
      let particleType = 'middle';
      const firstElementIndex = 0;
      const lastElementIndex = this.particleCount - 2;
      const currentPosition = this.particles[i].position;
      const nextPosition = this.particles[i + 1].position;
      const particleCurrent = new Vector(
        currentPosition.x + this.xOff,
        currentPosition.y + this.yOff
      );
      const particleNext = new Vector(nextPosition.x + this.xOff, nextPosition.y + this.yOff);
      const isFrontSide =
        this.side(
          currentPosition.x,
          currentPosition.y,
          nextPosition.x,
          nextPosition.y,
          particleNext.x,
          particleNext.y
        ) < 0;
      const fillColor = isFrontSide ? this.frontColor : this.backColor;

      if (i === firstElementIndex) particleType = 'start';

      if (i === lastElementIndex) particleType = 'end';

      ctx.fillStyle = fillColor;
      ctx.strokeStyle = fillColor;

      ctx.beginPath();
      ctx.moveTo(currentPosition.x * devicePixelRatio, currentPosition.y * devicePixelRatio);
      ctx.lineTo(nextPosition.x * devicePixelRatio, nextPosition.y * devicePixelRatio);

      switch (particleType) {
        case 'start':
          ctx.lineTo(
            (nextPosition.x + particleNext.x) * 0.5 * devicePixelRatio,
            (nextPosition.y + particleNext.y) * 0.5 * devicePixelRatio
          );
          ctx.closePath();
          ctx.stroke();
          ctx.fill();
          ctx.beginPath();
          ctx.moveTo(particleNext.x * devicePixelRatio, particleNext.y * devicePixelRatio);
          ctx.lineTo(particleCurrent.x * devicePixelRatio, particleCurrent.y * devicePixelRatio);
          ctx.lineTo(
            (nextPosition.x + particleNext.x) * 0.5 * devicePixelRatio,
            (nextPosition.y + particleNext.y) * 0.5 * devicePixelRatio
          );
          break;
        case 'end':
          ctx.lineTo(
            (currentPosition.x + particleCurrent.x) * 0.5 * devicePixelRatio,
            (currentPosition.y + particleCurrent.y) * 0.5 * devicePixelRatio
          );
          ctx.closePath();
          ctx.stroke();
          ctx.fill();
          ctx.beginPath();
          ctx.moveTo(particleNext.x * devicePixelRatio, particleNext.y * devicePixelRatio);
          ctx.lineTo(particleCurrent.x * devicePixelRatio, particleCurrent.y * devicePixelRatio);
          ctx.lineTo(
            (currentPosition.x + particleCurrent.x) * 0.5 * devicePixelRatio,
            (currentPosition.y + particleCurrent.y) * 0.5 * devicePixelRatio
          );
          break;
        case 'middle':
          ctx.lineTo(particleNext.x * devicePixelRatio, particleNext.y * devicePixelRatio);
          ctx.lineTo(particleCurrent.x * devicePixelRatio, particleCurrent.y * devicePixelRatio);
          break;
        default:
          break;
      }

      ctx.closePath();
      ctx.stroke();
      ctx.fill();
    }
  };

  this.side = (x1, y1, x2, y2, x3, y3) => (x1 - x2) * (y3 - y2) - (y1 - y2) * (x3 - x2);

  this.updateParticlesPosition = (index) => {
    const currentPosition = this.particles[index].position;
    const previousPosition = this.particles[index - 1].position;
    const position = new Vector(currentPosition.x, currentPosition.y);

    position.subtract(previousPosition);
    position.normalize();
    position.multiply(this.particleDistance);
    position.add(previousPosition);

    this.particles[index].position = position;
  };

  this.updateParticlesDirection = (delta, value, index) => {
    const currentPosition = this.particles[index].position;
    const previousPosition = this.particles[index - 1].position;
    let direction = new Vector(0, 0);

    direction = direction.getSubtracted(previousPosition, currentPosition);

    direction.normalize();
    direction.multiply((delta / value) * this.velocityInherit);

    this.particles[index].addForce(direction);
    this.particles[index].integrate(value);
  };

  this.updateFirstParticlePosition = (value) => {
    this.time += value * this.oscillationSpeed;
    this.position.y += this.ySpeed * value;
    this.position.x += cos(this.time) * this.oscillationDistance * value;
    this.particles[0].position = this.position;
  };

  this.updatePreviousPosition = () => {
    this.prevPosition = new Vector(this.position.x, this.position.y);
  };

  this.updateBounds = (boundsWidth, boundsHeight) => {
    this.bounds = new Vector(boundsWidth, boundsHeight);
  };

  this.getDelta = () => {
    const deltaX = this.prevPosition.x - this.position.x;
    const deltaY = this.prevPosition.y - this.position.y;
    return sqrt(deltaX * deltaX + deltaY * deltaY);
  };
}

export default Ribbon;
