import getSupportedHost from 'src/js/modules/extensions/common/helpers/get-supported-host';

const hosts = {
  'api.whatsapp.com': 'whatsapp',
  'wa.me': 'whatsapp',
};

const isWhatsAppLink = (value) => {
  const url = value.toLowerCase();
  const isHostSupported = getSupportedHost(url, hosts);

  if (isHostSupported) {
    return true;
  }

  return false;
};

export default isWhatsAppLink;
