import getFilteredIcons from './get-filtered-advanced-icons';
import getIconsList from './get-icons-list';
import iconFormats from '../constants/icon-formats';
import getIconPreviewUrl from './get-icon-preview-url';

const extendIconsWithPreviews = ({ icons, iconColor, iconSize }) =>
  icons.map((icon) => {
    const { id, name, isAnimated } = icon;
    let params = { size: iconSize, id, format: iconFormats.PNG };

    if (!isAnimated) {
      params = { ...params, color: iconColor };
    }

    return {
      ...icon,
      title: name,
      src: getIconPreviewUrl(params),
      params,
    };
  });

const getIconsWithPreviews = async ({
  valueToFilter,
  iconSize,
  abortRequestController,
  ...options
}) => {
  const { icons, iconsCount } = valueToFilter
    ? await getFilteredIcons({ valueToFilter, abortRequestController, ...options })
    : await getIconsList({ ...options });

  const iconsWithPreviews = icons.length
    ? extendIconsWithPreviews({ icons, iconSize, ...options })
    : [];

  return { icons: abortRequestController?.signal?.aborted ? null : iconsWithPreviews, iconsCount };
};

export default getIconsWithPreviews;
