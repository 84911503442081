import getLanguageNameData from './get-language-name-data';

const getRelatedLanguageOptions = (usedLocales, languagesMap, countriesMap) => {
  const languageKeys = Object.keys(languagesMap);
  const usedLocalesCodes = usedLocales.map((locale) => locale);
  const languageOptions = [];

  languageKeys.forEach((code) => {
    const { countries } = languagesMap[code];
    countries.forEach((country) => {
      const { countryCode, languageCode } = country;
      if (usedLocalesCodes.includes(languageCode)) return;
      const { iconComponent, name: countryName } = countriesMap[countryCode];
      const { name } = getLanguageNameData({
        languageCode,
        language: languagesMap[code],
        country,
        countryName,
      });

      languageOptions.push({
        name,
        code: languageCode,
        iconComponent,
      });
    });
  });

  return languageOptions;
};

export default getRelatedLanguageOptions;
