import getLanguages from '../../website-settings/helpers/get-languages';
import getCountries from '../../website-settings/helpers/get-countries';
import getRelatedLanguageOptions from '../../website-settings/helpers/get-related-language-options';

const getLanguageCodesOptions = () =>
  getRelatedLanguageOptions([], getLanguages(), getCountries()).map(
    ({ iconComponent, code, name }) => ({ iconComponent, label: name, value: code })
  );

export default getLanguageCodesOptions;
