import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

function PortalToIframe({ children, mountTo }) {
  if (mountTo) return ReactDOM.createPortal(children, mountTo);

  return null;
}

PortalToIframe.propTypes = {
  children: PropTypes.element.isRequired,
  mountTo: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
};

export default PortalToIframe;
