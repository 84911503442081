import fieldNames from './field-names';
import setupSteps from './setup-steps';
import stepStatuses from './step-statuses';
import languageCodesMap from './language-codes-map';

export default {
  fieldNames,
  setupSteps,
  stepStatuses,
  languageCodesMap,
};
