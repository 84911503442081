import actionTypes from '../constants/action-types';

const fetchUserPreferences = (resolve = Function.prototype, reject = Function.prototype) => ({
  type: actionTypes.FETCH_USER_PREFERENCES,
  payload: {
    resolve,
    reject,
  },
});

export default fetchUserPreferences;
