import { MAX_PANE_ITEMS } from '../constants/pane-items';

const getTriggersCount = (items) =>
  items.reduce((triggersCount, item) => {
    if (!item.collapsed && triggersCount < MAX_PANE_ITEMS) {
      return triggersCount + 1;
    }
    return triggersCount;
  }, 0);

export default getTriggersCount;
