import React, { useContext, useMemo } from 'react';
import classNames from 'classnames';
import bowser from 'yola-bowser';
import { designSystem } from '@yola/ws-ui';
import getBlockSettingsTabs from '../helpers/get-block-settings-tabs';
import BlockSettingsContext from '../contexts/block-settings-context';

const { Tabs } = designSystem;

const BlockSettingsTabsContainer = () => {
  const blockSettingsContext = useContext(BlockSettingsContext);

  const {
    activeTabId,
    captions,
    options: { optionalChildren, colorSchemes, displayOptions },
    blockItems,
    timestamp,
    displayBlockLayout,
    scrollContainerRef,
    originalBlock,
  } = blockSettingsContext;

  const { isBlockLayoutSwitchingDisabled } = originalBlock;

  const blockSettingsTabs = useMemo(
    () =>
      getBlockSettingsTabs({
        captions,
        options: {
          optionalChildren,
          colorSchemes,
          displayOptions,
        },
        blockItems,
        isBlockLayoutSwitchingDisabled,
        timestamp,
        displayBlockLayout,
      }),
    [
      captions,
      optionalChildren,
      colorSchemes,
      displayOptions,
      blockItems,
      isBlockLayoutSwitchingDisabled,
      timestamp,
      displayBlockLayout,
    ]
  );

  const panelClassName = classNames('ws-block-settings-dialog__tab-panels', {
    'ws-block-settings-dialog__tab-panels--mobile': bowser.mobile,
  });

  return (
    <div className={panelClassName}>
      <Tabs
        defaultActiveTabId={activeTabId}
        scrollContainerRef={scrollContainerRef}
        skipRenderInactiveContent
      >
        {blockSettingsTabs}
      </Tabs>
    </div>
  );
};

export default BlockSettingsTabsContainer;
