import React, { useRef, useCallback, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FocalPoint, Tooltip } from '@yola/ws-ui';
import throttle from 'lodash.throttle';
import { utils, view, dialogs, assets, template } from '@yola/ws-sdk';
import ControlPane from 'src/js/modules/control-pane/components/control-pane';
import TriggerEventTracker from 'src/js/modules/utils/trigger-event-tracker';
import customUI from 'src/js/modules/custom-ui';
import getDefaultTraits from 'src/js/modules/analytics/segment/helpers/get-default-traits';
import navbar from 'src/js/modules/navbar';
import segment from 'src/js/modules/analytics/segment';
import withFocalPointContext from '../hoc/with-focal-point-context';
import constants from '../constants/common';
import operationTypes from '../constants/operation-types';
import helpers from '../helpers';
import ids from '../constants/triggers-ids';
import useFocalPoint from '../hooks/use-focal-point';
import { getSettings } from '../settings';

const {
  track,
  constants: { events },
} = segment;

const {
  CONTROLS_REQUIRED_SPACE,
  ZOOM_STEP,
  BACKGROUND_IMAGE,
  STOCK_PHOTOS,
  FILE_UPLOAD,
  SUBMIT_TRIGGER,
  FOCAL_POINT_AREA_TRIGGER,
} = constants;
const { addBaseHref, getImageSize } = assets.helpers;

/* eslint no-shadow: "off" */
/* eslint no-param-reassign: "off" */
/* eslint no-console: "off" */

function BackgroundImageContainer(props) {
  const {
    elementId,
    onOverlayClick,
    onSubmit,
    onDrag: onDragProp,
    onDragStart: onDragStartProp,
    onDragEnd: onDragEndProp,
    onScale,
    width: viewportWidth,
    height: viewportHeight,
    orientation,
    onCancel,
    shouldApplyChangesOnSubmit,
    preserveSharedData,
  } = props;
  const scaleBeforeChange = useRef(null);
  const previousImagePosition = useRef({ x: 0, y: 0 });

  const settings = getSettings();
  const navbarHeight = useSelector(navbar.selectors.getHeight);

  const [imageOffset, setImageOffset] = useState({ x: 50, y: 50 });
  // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  const onPositionChange = useCallback(
    throttle(({ x, y }) => {
      setImageOffset({ x, y });
    }, 200),
    []
  );

  const onDrag = useCallback(
    (...args) => {
      const [offsetInPercents] = args;

      onDragProp(...args);
      onPositionChange({
        x: offsetInPercents.xInPercents,
        y: offsetInPercents.yInPercents,
      });
    },
    [onDragProp, onPositionChange]
  );

  const onInitialImageLoad = useCallback(
    ({ initialData, imageNode }) => {
      const { height: initialContainerHeight } = helpers.getContainerBounds(imageNode);
      initialData.current.containerHeight = initialContainerHeight;

      track(events.FOCAL_POINT_SETTINGS_DISPLAYED, {
        ...getDefaultTraits(elementId),
        imageType: BACKGROUND_IMAGE,
      });
    },
    [elementId]
  );

  const onFitImageSize = useCallback(
    ({ oldZoom, newZoom }) => {
      track(events.FOCAL_POINT_FIT_IN_BUTTON_CLICKED, {
        ...getDefaultTraits(elementId),
        oldZoom,
        newZoom,
        imageType: BACKGROUND_IMAGE,
      });
    },
    [elementId]
  );

  const onDragStart = useCallback(
    (...args) => {
      const [, , { position }] = args;
      previousImagePosition.current = {
        x: position.x,
        y: position.y,
      };
      onDragStartProp(...args);
    },
    [onDragStartProp]
  );

  const onDragEnd = useCallback(
    (...args) => {
      onDragEndProp(...args);
      const [{ x, y }] = args;
      const { x: prevX, y: prevY } = previousImagePosition.current;

      if (prevX !== x || prevY !== y) {
        track(events.FOCAL_POINT_IMAGE_POSITION_ADJUSTED, {
          ...getDefaultTraits(elementId),
          imageType: BACKGROUND_IMAGE,
        });
      }
      previousImagePosition.current = { x, y };
    },
    [elementId, onDragEndProp]
  );

  const {
    position,
    scale,
    zoom,
    imageBounds,
    initialData,
    imageData,
    controlsRef,
    isLoadingImage,
    imageNode,
    imageComputedStyles,
    handleImageDrag,
    handleImageDragStart,
    handleImageDragEnd,
    handleScale,
    handleFitImageSize,
    handleCancelUpload,
    adjustContentScale,
    handleImageUploadStart,
    handleImageUploadError,
    currentSource,
    setCurrentSource,
  } = useFocalPoint({
    elementId,
    onInitialImageLoad,
    onFitImageSize,
    viewportWidth,
    viewportHeight,
    orientation,
    onDrag,
    onDragStart,
    onDragEnd,
    onScale,
    preserveSharedData,
    getInitialScrollPosition: (isHigherThanViewport) => ({
      sideToScroll: isHigherThanViewport ? 'top' : 'center',
    }),
  });

  useEffect(() => {
    const [x, y] = helpers.getPosition(imageNode);

    setImageOffset({ x, y });
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  const handleCancel = useCallback(() => {
    if (isLoadingImage.current) return;
    const {
      scale: initialScale,
      position: initialPosition,
      source: initialSource,
      isMigrated,
    } = initialData.current;

    helpers.setScale(imageNode, initialScale);
    helpers.setPosition(
      imageNode,
      helpers.createCssPositionString(initialPosition.x, initialPosition.y)
    );
    helpers.setCurrentImageUrl(imageNode, initialSource);

    if (isMigrated) {
      imageNode.setAttribute(constants.OLD_LOGIC_ATTRIBUTE, true);
    }

    track(events.FOCAL_POINT_SETTINGS_CANCELLED, {
      ...getDefaultTraits(elementId),
      imageType: BACKGROUND_IMAGE,
    });

    customUI.operations.hide();
    onOverlayClick();
    onCancel();
  }, [elementId, onCancel, isLoadingImage, imageNode, initialData, onOverlayClick]);

  const handleSubmit = useCallback(() => {
    const [x, y] = helpers.getPosition(imageNode);
    const { isMigrated } = initialData.current;
    const setSrcOperation = helpers.getViewOperation(operationTypes.SRC, elementId, currentSource);
    const setPositionOperation = helpers.getViewOperation(
      operationTypes.POSITION,
      elementId,
      helpers.createCssPositionString(x, y)
    );
    const setScaleOperation = helpers.getViewOperation(operationTypes.SCALE, elementId, scale);

    const operations = [setSrcOperation, setPositionOperation, setScaleOperation];

    if (isMigrated) {
      operations.push([
        view.operations.removeElementAttribute,
        [elementId, constants.OLD_LOGIC_ATTRIBUTE],
      ]);
    }

    if (currentSource !== initialData.current.source) {
      assets.operations.makePublishable(currentSource);
    }

    if (shouldApplyChangesOnSubmit) {
      view.operations.bulkViewOperations(operations);
    }

    onSubmit({ operations });
    customUI.operations.hide();
    Tooltip.hide();
  }, [
    imageNode,
    shouldApplyChangesOnSubmit,
    initialData,
    onSubmit,
    elementId,
    scale,
    currentSource,
  ]);

  const withSubmitAnalyticsEvent = useCallback(
    (cb, triggerId) => () => {
      const { scale: initialScale, position: initialPosition } = initialData.current;
      cb();

      track(events.FOCAL_POINT_SETTINGS_SUBMITTED, {
        ...getDefaultTraits(elementId),
        oldZoom: initialScale,
        newZoom: scale,
        zoomAdjusted: initialScale !== scale,
        positionAdjusted: initialPosition.x !== position.x || initialPosition.y !== position.y,
        imageType: BACKGROUND_IMAGE,
        triggerId,
      });
    },
    [elementId, position, initialData, scale]
  );

  const handleLegacyImageUploadEnd = useCallback(
    async ({ source, isStockPhoto }) => {
      const imageSize = await getImageSize(addBaseHref(source));

      imageData.current.width = imageSize.width;
      imageData.current.height = imageSize.height;
      setCurrentSource(source);

      track(events.FOCAL_POINT_NEW_IMAGE_SELECTED, {
        ...getDefaultTraits(elementId),
        source: isStockPhoto ? STOCK_PHOTOS : FILE_UPLOAD,
        imageType: BACKGROUND_IMAGE,
      });

      const originalImageUrl = addBaseHref(source);

      helpers.setCurrentImageUrl(imageNode, originalImageUrl);
      helpers.setProgressiveSrc(imageNode, source);
      await adjustContentScale(originalImageUrl);
      isLoadingImage.current = false;

      dialogs.operations.hide({ preserveSharedData });
    },
    [
      elementId,
      preserveSharedData,
      isLoadingImage,
      imageData,
      imageNode,
      adjustContentScale,
      setCurrentSource,
    ]
  );

  const getControlPaneInfo = useCallback(() => {
    const controlPaneElement = controlsRef.current;
    const { x, y } = controlPaneElement.getBoundingClientRect();
    const scrollPosition = view.accessors.getScrollPosition();
    const liveDocument = view.accessors.getLiveDocument();
    const windowHeight = liveDocument.defaultView.innerHeight;
    const hasNoSpaceForControl =
      imageBounds && imageBounds.height + CONTROLS_REQUIRED_SPACE > windowHeight;
    const direction = !hasNoSpaceForControl ? 'up' : 'none';
    const offsetY = y + scrollPosition - navbarHeight;

    return {
      scrollPosition,
      controlPane: {
        element: controlPaneElement,
        direction,
        position: {
          x,
          y: offsetY,
        },
      },
    };
  }, [controlsRef, imageBounds, navbarHeight]);

  const saveInitialSliderValueBeforeChange = useCallback(() => {
    // RCSlider component do not guarantee that `onBeforeChange` callback would run once
    // before `onAfterChange`. We've faced a use case when it's called once again between
    // `onChange`  and `onAfterChange` so we get wrong value. That's why we set initial
    // value only if it's empty, and empty it on purpose inside `onAfterChange` callback handler.
    if (scaleBeforeChange.current === null) {
      scaleBeforeChange.current = helpers.getScale(imageNode);
    }
  }, [imageNode]);

  const trackSliderChanges = useCallback(() => {
    if (scaleBeforeChange.current === null) return;

    const scale = helpers.getScale(imageNode);
    if (scale !== scaleBeforeChange.current) {
      track(events.FOCAL_POINT_ZOOM_SLIDER_ADJUSTED, {
        ...getDefaultTraits(elementId),
        oldZoom: scaleBeforeChange.current,
        newZoom: scale,
        imageType: BACKGROUND_IMAGE,
      });
    }
    scaleBeforeChange.current = null;
  }, [imageNode, elementId]);

  const imageSubmitControl = helpers.getSubmitControl({
    id: ids.BACKGROUND_IMAGE_SUBMIT,
    onClick: withSubmitAnalyticsEvent(handleSubmit, SUBMIT_TRIGGER),
  });

  const fitControl = helpers.getFitControl({
    id: ids.BACKGROUND_IMAGE_FIT_TRIGGER,
    onClick: handleFitImageSize,
  });

  let imageContainerControls;

  if (template.verifiers.isMpt()) {
    imageContainerControls = [
      helpers.getZoomOutControl({
        id: ids.BACKGROUND_IMAGE_ZOOM_OUT_TRIGGER,
        onClick: () => {
          const oldZoom = helpers.getScale(imageNode);
          Tooltip.hide();
          handleScale(zoom - ZOOM_STEP);
          track(events.FOCAL_POINT_ZOOM_OUT_BUTTON_CLICKED, {
            ...getDefaultTraits(elementId),
            oldZoom,
            newZoom: helpers.getScale(imageNode),
            imageType: BACKGROUND_IMAGE,
          });
        },
      }),
      helpers.getZoomSliderControl({
        id: ids.BACKGROUND_IMAGE_ZOOM_SLIDER,
        scale,
        zoom,
        onChange: (...args) => {
          saveInitialSliderValueBeforeChange();
          handleScale(...args);
        },
        onAfterChange: trackSliderChanges,
        onBeforeChange: saveInitialSliderValueBeforeChange,
      }),
      helpers.getZoomInControl({
        id: ids.BACKGROUND_IMAGE_ZOOM_IN_TRIGGER,
        onClick: () => {
          const oldZoom = helpers.getScale(imageNode);
          Tooltip.hide();
          handleScale(zoom + ZOOM_STEP);
          track(events.FOCAL_POINT_ZOOM_IN_BUTTON_CLICKED, {
            ...getDefaultTraits(elementId),
            oldZoom,
            newZoom: helpers.getScale(imageNode),
            imageType: BACKGROUND_IMAGE,
          });
        },
      }),
      fitControl,
      imageSubmitControl,
    ];
  } else {
    imageContainerControls = [
      helpers.getBackgroundZoomControl({
        id: ids.BACKGROUND_IMAGE_ZOOM_TRIGGER,
        onClick: Tooltip.hide,
        onScaleChange: (...args) => {
          saveInitialSliderValueBeforeChange();
          handleScale(...args);
        },
        onAfterChange: trackSliderChanges,
        onBeforeChange: saveInitialSliderValueBeforeChange,
        onFitControlClick: handleFitImageSize,
      }),
      helpers.getUploadImageControl({
        id: ids.BACKGROUND_IMAGE_FILE_TRIGGER,
        elementId,
        preserveSharedData,
        getContext: getControlPaneInfo,
        onImageUploadStart: handleImageUploadStart,
        onImageUploadEnd: handleLegacyImageUploadEnd,
        onImageUploadError: handleImageUploadError,
        onImageUploadCancel: handleCancelUpload,
        onClick: () => {
          track(events.FOCAL_POINT_UPLOAD_FILE_BUTTON_CLICKED, {
            ...getDefaultTraits(elementId),
            imageType: BACKGROUND_IMAGE,
          });
        },
        onTriggerUploadImageClick: () => {
          track(events.FOCAL_POINT_REPLACE_MEDIA_BUTTON_CLICKED, {
            ...getDefaultTraits(elementId),
            imageType: BACKGROUND_IMAGE,
          });
        },
        onTriggerBrowseStockPhotosClick: () => {
          track(events.FOCAL_POINT_BROWSE_STOCK_PHOTOS_BUTTON_CLICKED, {
            ...getDefaultTraits(elementId),
            imageType: BACKGROUND_IMAGE,
          });
        },
      }),
      imageSubmitControl,
    ];
  }

  const controlsProps = useMemo(() => {
    if (!imageBounds) return null;
    return {
      position: helpers.getControlsPosition(imageBounds, false, imageContainerControls),
    };
  }, [imageBounds, imageContainerControls]);

  if (imageNode && !helpers.hasBackgroundContainer(imageNode)) {
    console.warn(
      `You can't use focal point with any other node type except ${settings.backgroundContainer}`
    );
    return null;
  }

  if (!imageBounds) return null;

  const {
    position: { offsetX, offsetY },
  } = controlsProps;
  const controls = (
    <TriggerEventTracker>
      <ControlPane
        controlPaneRef={controlsRef}
        offsetX={offsetX}
        offsetY={offsetY}
        direction="none"
        content={imageContainerControls}
        expand="down"
        className="ws-focal-point-controls"
      />
    </TriggerEventTracker>
  );

  return (
    <FocalPoint
      controls={controls}
      onOverlayClick={handleCancel}
      onOverlayDoubleClick={withSubmitAnalyticsEvent(handleSubmit, FOCAL_POINT_AREA_TRIGGER)}
      onDrag={handleImageDrag}
      onDragStart={handleImageDragStart}
      onDragEnd={handleImageDragEnd}
      targetElementBounds={imageBounds}
      elementComputedStyles={imageComputedStyles}
      tooltipText={`X:${imageOffset.x}% Y:${imageOffset.y}%`}
    />
  );
}

BackgroundImageContainer.propTypes = {
  elementId: PropTypes.string.isRequired,
  onOverlayClick: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onDrag: PropTypes.func,
  onDragStart: PropTypes.func,
  onDragEnd: PropTypes.func,
  onScale: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
  orientation: PropTypes.string,
  preserveSharedData: PropTypes.bool,
  shouldApplyChangesOnSubmit: PropTypes.bool,
};

BackgroundImageContainer.defaultProps = {
  onOverlayClick: utils.noop,
  onDrag: utils.noop,
  onSubmit: utils.noop,
  onCancel: utils.noop,
  onDragStart: utils.noop,
  onDragEnd: utils.noop,
  onScale: utils.noop,
  width: null,
  height: null,
  orientation: null,
  shouldApplyChangesOnSubmit: true,
  preserveSharedData: false,
};

export default withFocalPointContext(BackgroundImageContainer);
