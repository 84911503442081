import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import { i18next } from '@yola/ws-sdk';
import BlocksLibraryContentHeader from './blocks-library-content-header';
import RequestBlock from './request-block';
import { trackTutorialClicked } from '../helpers/blocks-library-analytics';

const { List, ListItem, Box } = designSystem;

const getTutorials = () => [
  {
    analyticsName: 'How to accept donations with Yola',
    name: i18next.t('How to accept donations with Yola'),
    href: 'https://helpcenter.yola.com/hc/en-us/articles/5437981821458-How-to-accept-donations-with-Yola',
  },
  {
    analyticsName: 'How to use the Online Store block for fundraising',
    name: i18next.t('How to use the Online Store block for fundraising'),
    href: 'https://helpcenter.yola.com/hc/en-us/articles/5434759174546',
  },
  {
    analyticsName: 'How to add the PayPal button to your website',
    name: i18next.t('How to add the PayPal button to your website'),
    href: 'https://helpcenter.yola.com/hc/en-us/articles/5438843108242',
  },
  {
    analyticsName: 'Adding a donation option via a full-scale Online Store',
    name: i18next.t('Adding a donation option via a full-scale Online Store'),
    href: 'https://helpcenter.yola.com/hc/en-us/articles/5438736554642',
  },
];

const DonationContent = ({ onCloseContentSectionMobile, requestBlockData, captions }) => (
  <div className="ws-blocks-library__donation-content">
    <div>
      <BlocksLibraryContentHeader
        activeCategory={captions}
        submittedSearchValue=""
        onCloseContentSectionMobile={onCloseContentSectionMobile}
        isBlocksListEmpty
      />
      <Box marginTop="spacing-xs">
        <List>
          {getTutorials().map(({ name, analyticsName, href }) => (
            <ListItem key={href}>
              <a
                target="_blank"
                rel="noreferrer noopener"
                onClick={() => trackTutorialClicked(analyticsName)}
                href={href}
              >
                {name}
              </a>
            </ListItem>
          ))}
        </List>
      </Box>
    </div>
    {requestBlockData && (
      <RequestBlock captions={requestBlockData.captions} href={requestBlockData.publicRoadmapUrl} />
    )}
  </div>
);

DonationContent.propTypes = {
  captions: PropTypes.shape({ name: PropTypes.string, description: PropTypes.string }).isRequired,
  requestBlockData: PropTypes.shape({
    captions: PropTypes.shape({
      title: PropTypes.string.isRequired,
      button: PropTypes.string.isRequired,
    }).isRequired,
    publicRoadmapUrl: PropTypes.string.isRequired,
  }),
  onCloseContentSectionMobile: PropTypes.func.isRequired,
};

DonationContent.defaultProps = {
  requestBlockData: null,
};

export default DonationContent;
