import { view } from '@yola/ws-sdk';
import { SET } from '../constants/attribute-operations';

const convertAttributeOperationToAction = ({ operation, elementId, name, value, options }) => {
  if (operation === SET) {
    return view.actions.setElementAttribute(elementId, name, value, options);
  }

  return view.actions.removeElementAttribute(elementId, name, options);
};

export default convertAttributeOperationToAction;
