import { view, customization, globals, template } from '@yola/ws-sdk';
import store from 'src/js/store';
import constant from '../constant';
import compileCustomTemplate from './compile-custom-template';

export default function updateButtonPresetCode(preset) {
  const state = store.getState();
  const buttonPresetTemplate = customization.selectors.getButtonPresetTemplate(state);

  const compiledTemplate = compileCustomTemplate(buttonPresetTemplate, preset);

  const buttonPresetSelector = template.selectors
    .getVariableValueByPath(state, customization.constants.BUTTON_PRESET_VARIABLE)
    .find((variable) => variable.querySelector)?.querySelector;

  globals.operations.changeGlobalContent(constant.GLOBAL_HEADER_CODE, (globalContent) => {
    const newGlobalContent = globalContent.cloneNode(true);
    const presetElement = newGlobalContent.querySelector(buttonPresetSelector);
    presetElement.innerHTML = compiledTemplate;
    presetElement.setAttribute(customization.constants.BUTTON_PRESET_DATA_ATTRIBUTE, preset.id);
    return newGlobalContent;
  });

  const liveDocument = view.accessors.getLiveDocument();

  /**
   * `liveDocument` is unavailable when user haven't finished initial site setup.
   * So there is no sense to update live DOM as it will be initiated later.
   */
  if (liveDocument) {
    const presetElement = liveDocument.querySelector(buttonPresetSelector);
    presetElement.innerHTML = compiledTemplate;
    presetElement.setAttribute(customization.constants.BUTTON_PRESET_DATA_ATTRIBUTE, preset.id);
  }
}
