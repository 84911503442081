import { integration } from '@yola/ws-sdk';
import store from 'src/js/store';
import constants from '../constants';

const createBrandingComponent = () => {
  const state = store.getState();

  const { logo, message, url, logoWidth, theme, ctaText } =
    integration.selectors.getSiteHeader(state);
  const brandingHeaderElement = document.createElement('ws-branded-header');

  if (message) {
    brandingHeaderElement.setAttribute('message', message);
  }

  brandingHeaderElement.setAttribute('logo', logo);
  brandingHeaderElement.setAttribute('url', url);
  brandingHeaderElement.setAttribute('cta-text', ctaText);
  brandingHeaderElement.setAttribute('theme', theme || constants.DEFAULT_THEME);
  brandingHeaderElement.setAttribute('logo-width', logoWidth || constants.DEFAULT_LOGO_WIDTH);

  return brandingHeaderElement;
};

export default createBrandingComponent;
