import { SUBMODULE_NAME } from '../constants';
import getOpacitySteps from './get-opacity-steps';
import getSubmoduleConfig from '../../../helpers/get-submodule-config';

const opacityComputer =
  (computer, returnMaxOpacityWhenLimitIsReached = false) =>
  (canvasProperties, imgMeta, overlay, textAreasOnDarkSurface) =>
  async (initialOpacity = 0) => {
    const { maxAvailableOpacity, opacityIterationStep } = getSubmoduleConfig(SUBMODULE_NAME);
    let opacityToApply = initialOpacity;

    const steps = getOpacitySteps(opacityToApply, maxAvailableOpacity, opacityIterationStep);
    let isReadable = false;
    let index = 0;
    /* eslint-disable no-await-in-loop */
    while (index < steps.length) {
      isReadable = await computer(
        canvasProperties,
        imgMeta,
        {
          ...overlay,
          opacity: steps[index],
        },
        textAreasOnDarkSurface
      );
      if (isReadable) {
        opacityToApply = steps[index];
        break;
      }
      index += 1;
    }
    /* eslint-enable no-await-in-loop */

    const emergencyOpacity = returnMaxOpacityWhenLimitIsReached ? maxAvailableOpacity : null;
    return isReadable ? opacityToApply : emergencyOpacity;
  };

export default opacityComputer;
