import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { saving, i18next, integration, view } from '@yola/ws-sdk';
import { designSystem } from '@yola/ws-ui';
import bowser from 'yola-bowser';
import { startAutoSaving, stopAutoSaving } from 'src/js/modules/autosaving';
import saveSite from 'src/js/modules/saving/helpers/save-site';

const { EditorHeader } = designSystem;
const { SAVED, SAVING } = saving.constants.statuses;

class SavingContainer extends Component {
  constructor(props) {
    super(props);

    this.manageUnloadEvent = this.manageUnloadEvent.bind(this);

    const { userData } = this.props;
    this.userEmployeeId = userData.employee_id;
  }

  componentDidMount() {
    if (!this.userEmployeeId) {
      startAutoSaving();
    }
    this.manageUnloadEvent();
  }

  componentDidUpdate(prevProps) {
    const { status } = this.props;
    if (status !== prevProps.status) {
      this.manageUnloadEvent();
    }
  }

  componentWillUnmount() {
    if (!this.userEmployeeId) {
      stopAutoSaving();
    }
  }

  manageUnloadEvent() {
    const {
      status,
      integrationSettings: { beforeUnloadEvents },
      focusedElement,
    } = this.props;
    if (!beforeUnloadEvents) return;

    const properties = {
      [integration.constants.SYMBOL_SETTING]: {
        disableBlocking: true,
      },
    };

    if (status === SAVED && !focusedElement) {
      beforeUnloadEvents.end({ properties });
    } else {
      beforeUnloadEvents.start({ properties });
    }
  }

  render() {
    const { status } = this.props;

    return (
      <EditorHeader.SaveButton
        onClick={saveSite}
        status={status}
        disabled={status === SAVED || status === SAVING}
        popoverProps={{
          title: i18next.t('Save'),
          content: i18next.t('Save changes you made'),
          hotkey: bowser.macOS ? '\u2318S' : i18next.t('Ctrl+S'),
        }}
      />
    );
  }
}

SavingContainer.propTypes = {
  status: PropTypes.string.isRequired,
  userData: PropTypes.shape().isRequired,
  integrationSettings: PropTypes.shape({
    beforeUnloadEvents: PropTypes.shape({
      start: PropTypes.func.isRequired,
      end: PropTypes.func.isRequired,
    }),
  }).isRequired,
  focusedElement: PropTypes.string,
};

SavingContainer.defaultProps = {
  focusedElement: null,
};

const mapStateToProps = (state) => ({
  status: saving.selectors.getStatus(state),
  userData: integration.selectors.getUserData(state),
  integrationSettings: integration.selectors.getSettings(state),
  focusedElement: view.selectors.getFocusedElement(state),
});

export default connect(mapStateToProps)(SavingContainer);
