import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { interrupter, dialogs, view, bowser } from '@yola/ws-sdk';
import setDisabledStatus from '../../../../view/actions/set-disabled-status';
import dialogTypes from '../../../../dialogs/constants/dialog-types';
import errorTypes from '../../../../ai/constants/error-types';
import scroller from '../../../../scroller';

const useTextAssistantStatusChange = (getContext) => {
  const dispatch = useDispatch();
  const [isLoading, setLoadingStatus] = useState(false);

  const blockEditor = () => {
    dispatch(setDisabledStatus(true));
    dispatch(interrupter.actions.block());
  };

  const unblockEditor = () => {
    dispatch(interrupter.actions.unblock());
    dispatch(setDisabledStatus(false));

    // sometimes safari screen turnes white after coming back from pointer-events:none
    // content is rendered again after scroll
    if (bowser.safari) {
      const scrollPosition = view.accessors.getScrollPosition();
      scroller.helpers.scrollToPosition(scrollPosition + 10);
    }
  };

  const onLoading = () => {
    setLoadingStatus(true);
    blockEditor();
  };

  const onSuccess = () => {
    const {
      controlPane: { forceHighlighterRerender },
    } = getContext();

    unblockEditor();
    setLoadingStatus(false);
    forceHighlighterRerender();
  };

  const onError = (err, isAiTextAssistantQuotaExceeded) => {
    unblockEditor();

    if (isLoading) {
      setLoadingStatus(false);
    }

    if (!isAiTextAssistantQuotaExceeded) {
      const errorCode = err.results?.code;

      const errorType =
        errorCode === errorTypes.FORBIDDEN_BY_MODERATION
          ? errorTypes.FORBIDDEN_BY_MODERATION
          : errorTypes.UNKNOWN_GENERATE_TEXT;

      dispatch(dialogs.actions.show(dialogTypes.AI_TEXT_ASSISTANT_ERROR_DIALOG, { errorType }));
    }
  };

  const onCancel = () => {
    const {
      controlPane: { forceControlPaneRerender },
    } = getContext();

    unblockEditor();
    setLoadingStatus(false);
    forceControlPaneRerender();
  };

  return {
    isLoading,
    onLoading,
    onSuccess,
    onError,
    onCancel,
  };
};

export default useTextAssistantStatusChange;
