import observers from './observers';

const notifyObservers = (eventName, ...args) => {
  if (observers.has(eventName)) {
    observers.get(eventName).forEach((callback) => {
      callback(...args);
    });
  }
};

export default notifyObservers;
