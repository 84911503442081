import actionTypes from '../constants/action-types';

const previewVhost = (state = null, action) => {
  switch (action.type) {
    case actionTypes.SHOW:
      return action.payload.previewVhost;
    case actionTypes.HIDE:
      return null;
    default:
      return state;
  }
};

export default previewVhost;
