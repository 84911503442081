import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import common from '../../common';

const { Box, Stack, Heading, Paragraph, ControlGroup, InputGroupField, Checkbox } = designSystem;
const { TextAreaWithLengthAnnotationField } = common.components;

function WizardSetupDescriptionSection(props) {
  const { captions, businessNameField, descriptionField } = props;

  return (
    <Stack gap="spacing-m">
      <Stack gap="spacing-3-xs">
        <Heading type="heading-3">{captions.sectionTitle}</Heading>
        <Paragraph>{captions.sectionDescription}</Paragraph>
      </Stack>
      <Stack gap="spacing-xs">
        <ControlGroup title={businessNameField.label} required>
          <InputGroupField
            control={businessNameField.control}
            name={businessNameField.name}
            rules={businessNameField.rules}
            placeholder={businessNameField.placeholder}
            size="medium"
            onChange={businessNameField.onChange}
          />
          <Box marginTop="spacing-2-xs">
            <Checkbox
              id={businessNameField.name}
              label={businessNameField.checkboxLabel}
              checked={businessNameField.isSameAsSiteName}
              onChange={businessNameField.onSameAsSiteNameChange}
              value={businessNameField.name}
            />
          </Box>
        </ControlGroup>
        <ControlGroup title={descriptionField.label} required>
          <TextAreaWithLengthAnnotationField
            control={descriptionField.control}
            name={descriptionField.name}
            rules={descriptionField.rules}
            placeholder={descriptionField.placeholder}
            maxLength={descriptionField.rules.maxLength.value}
          />
        </ControlGroup>
      </Stack>
    </Stack>
  );
}

WizardSetupDescriptionSection.propTypes = {
  captions: PropTypes.shape().isRequired,
  businessNameField: PropTypes.shape().isRequired,
  descriptionField: PropTypes.shape().isRequired,
};

export default WizardSetupDescriptionSection;
