import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from 'yola-editor/src/js/components/button';

function GenericDialog(props) {
  const {
    captions: { mainAction, secondaryAction },
    children,
    mainActionLink,
    mainActionDisabled,
    onMainAction,
    onSecondaryAction,
    onClose,
    className,
  } = props;

  const isFooterShown = onMainAction || mainActionLink;
  const onMainActionHandler = onMainAction || Function.prototype;

  return (
    <ReactModal
      isOpen
      onRequestClose={onClose}
      className={classNames('dialog dialog--generic', className)}
      overlayClassName="dialog-overlay"
    >
      {secondaryAction && (
        <div className="dialog-header">
          <Button type="link" size="small" onClick={onSecondaryAction}>
            {secondaryAction}
          </Button>
        </div>
      )}
      <div className="dialog-body">{children}</div>
      {isFooterShown && (
        <div className="dialog-footer">
          <Button
            type="secondary"
            size="large"
            target="blank"
            href={mainActionLink}
            onClick={onMainActionHandler}
            disabled={mainActionDisabled}
          >
            {mainAction}
          </Button>
        </div>
      )}
    </ReactModal>
  );
}

GenericDialog.propTypes = {
  captions: PropTypes.shape({
    mainAction: PropTypes.string,
    secondaryAction: PropTypes.string,
  }).isRequired,
  children: PropTypes.node.isRequired,
  mainActionDisabled: PropTypes.bool,
  mainActionLink: PropTypes.string,
  onMainAction: PropTypes.func,
  onSecondaryAction: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
};

GenericDialog.defaultProps = {
  mainActionLink: null,
  mainActionDisabled: false,
  onSecondaryAction: Function.prototype,
  onMainAction: null,
  className: '',
};

export default GenericDialog;
