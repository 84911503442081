import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { designSystem, AceEditor } from '@yola/ws-ui';
import { i18next } from '@yola/ws-sdk';
import IntegrationSettingsDialogScaffolding from './integration-settings-dialog-scaffolding';

const { FieldStatus, Paragraph } = designSystem;

const GoogleSearchConsoleSettingsDialog = ({
  captions,
  defaultValue,
  onSubmit,
  onCancel,
  validate,
}) => {
  const [verificationTag, setVerificationTag] = useState(defaultValue);
  const [validationError, setValidationError] = useState('');

  const handleChange = (value) => {
    setValidationError('');
    setVerificationTag(value);
  };

  const handleSubmit = () => {
    const trimmedValue = verificationTag?.trim();
    const validationMessage = validate(trimmedValue);

    if (validationMessage) {
      setValidationError(validationMessage);
      return;
    }

    onSubmit(trimmedValue);
  };

  return (
    <IntegrationSettingsDialogScaffolding
      captions={{
        title: i18next.t('{integrationType} settings', { integrationType: captions.name }),
        controlName: captions.control.name,
        notes: captions.notes,
      }}
      onCancel={onCancel}
      onSubmit={handleSubmit}
    >
      <div>
        <AceEditor
          mode="html"
          theme="chrome"
          options={{
            showLineNumbers: true,
            tabSize: 2,
            autoScrollEditorIntoView: true,
          }}
          defaultValue={defaultValue}
          onChange={handleChange}
          style={{ minHeight: '72px' }}
        />
        {validationError && <FieldStatus appearance="error" text={validationError} />}
      </div>
      <Paragraph appearance="medium-emphasis" size="small">
        <span
          // eslint-disable-next-line yola/react/no-danger
          dangerouslySetInnerHTML={{ __html: captions.control.description }}
        />
      </Paragraph>
    </IntegrationSettingsDialogScaffolding>
  );
};

GoogleSearchConsoleSettingsDialog.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  captions: PropTypes.shape({
    name: PropTypes.string.isRequired,
    control: PropTypes.shape().isRequired,
    notes: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  validate: PropTypes.func,
};

GoogleSearchConsoleSettingsDialog.defaultProps = {
  validate: () => null,
};

export default GoogleSearchConsoleSettingsDialog;
