import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';

const { PresetContainer } = designSystem;

function FontPairPreset(props) {
  const { id, title, previewUrl, isSelected, observer, onClick } = props;
  const presetRef = useRef(null);

  useEffect(() => {
    observer.observe(presetRef.current);
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  const handleClick = () => onClick(id);

  return (
    <div id={id} className="ws-font-pair-preset" ref={presetRef}>
      <PresetContainer title={title} isSelected={isSelected} onClick={handleClick}>
        <img className="ws-font-pair-preset__image" src={previewUrl} alt={title} />
      </PresetContainer>
    </div>
  );
}

FontPairPreset.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  previewUrl: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  observer: PropTypes.shape({
    observe: PropTypes.func,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

FontPairPreset.defaultProps = {
  isSelected: false,
};

export default FontPairPreset;
