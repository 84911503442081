const getOriginImageSize = (imageNode) => {
  if (!imageNode) throw new Error('Image node of type Node Element should be provided');

  return {
    width: imageNode.getAttribute('origin-width'),
    height: imageNode.getAttribute('origin-height'),
  };
};

export default getOriginImageSize;
