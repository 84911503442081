import get from 'lodash.get';
import { site } from '@yola/ws-sdk';
import sessionTracker from '../helpers/session-tracker';

const sessionExpireChecker = () => (next) => (action) => {
  switch (action.type) {
    case site.actionTypes.SET_SESSION: {
      next(action);
      const ttl = get(action, 'payload.site.session.ttl', null);

      sessionTracker.stopTracking();
      sessionTracker.startTracking(ttl);

      break;
    }
    default:
      next(action);
  }
};

export default sessionExpireChecker;
