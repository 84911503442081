import { extensions } from '@yola/ws-sdk';
import constants from '../constants/common';
import IconResizeTool from '../components/icon-resize-tool';

const IconResizeToolSettings = {
  matches(element) {
    const settings = extensions.accessors.getExtensionSettings(constants.slug);
    const { querySelector, resizeRestrictedParentsSelector } = settings;

    if (resizeRestrictedParentsSelector && element.closest(resizeRestrictedParentsSelector)) {
      return false;
    }

    return element.matches(querySelector);
  },
  container: IconResizeTool,
};

export default IconResizeToolSettings;
