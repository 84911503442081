import attributeValuesList from 'src/js/modules/utils/attribute-values-list';

function isVisibleOption(condition, options, element, optionalChildren) {
  // eslint-disable-next-line no-use-before-define
  if (condition.enableIf) return isVisible(condition, options, element, optionalChildren);
  const { id, value, type } = condition;

  if (type === 'optional_children') {
    return optionalChildren.some((i) => i.id === id && i.isEnabled);
  }

  if (type === 'selector') {
    return !!element.querySelector(value);
  }

  const refOption = options.find((option) => option.id === id);

  if (!refOption) return true;

  const targetElement = refOption.querySelector
    ? element.querySelector(refOption.querySelector)
    : element;
  if (!targetElement) return false;

  if (typeof value === 'string') {
    return attributeValuesList.contains(targetElement, refOption.attribute, value);
  }

  if (typeof value === 'boolean') {
    const isLiveAttributeEnabled = targetElement.hasAttribute(refOption.attribute) === value;
    return refOption.inverted ? !isLiveAttributeEnabled : isLiveAttributeEnabled;
  }

  if (Array.isArray(value)) {
    return value.some((targetValue) =>
      attributeValuesList.contains(targetElement, refOption.attribute, targetValue)
    );
  }

  return true;
}

function isVisible(items, options, element, optionalChildren) {
  const conditions = items.enableIf;

  if (items.enableIfOperator !== 'or') {
    return conditions.every((condition) =>
      isVisibleOption(condition, options, element, optionalChildren)
    );
  }

  return conditions.some((condition) =>
    isVisibleOption(condition, options, element, optionalChildren)
  );
}

function optionsVisibilityCallBack(item, options, element, optionalChildren) {
  if (item.enableIf) {
    return isVisible(item, options, element, optionalChildren);
  }

  return options;
}

const getVisibleOptions = (options, element, optionalChildren = []) => {
  if (!options || !options.length) return options;
  return options.filter((item) =>
    optionsVisibilityCallBack(item, options, element, optionalChildren)
  );
};

export default getVisibleOptions;
