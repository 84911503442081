const shouldTriggersBeShown = ({
  isViewLoaded,
  isDialogVisible,
  customUIVisibility,
  isContextMenuVisible,
  focusedElementId,
  selectedElementId,
}) =>
  Boolean(
    isViewLoaded &&
      !isDialogVisible &&
      !customUIVisibility &&
      !isContextMenuVisible &&
      !focusedElementId &&
      !selectedElementId
  );

export default shouldTriggersBeShown;
