import { hooks, site, template } from '@yola/ws-sdk';
import constants from '../constants';
import track from '../track';

const trackWidgetAdded = () => {
  hooks.operations.subscribe(
    hooks.availableHooks.ON_AFTER_BLOCK_ADDED,
    ({ blockId, variationId: blockVariationId, targetBlockId, targetBlockVariationId }) => {
      if (!blockId) return;

      const isBlockWidget = blockId.startsWith('widget-');
      if (!isBlockWidget) return;

      track(constants.events.WIDGET_ADDED, {
        templateBuildSlug: template.accessors.getBuildSlug(),
        siteId: site.accessors.getSiteId(),
        blockId,
        blockVariationId,
        targetBlockId,
        targetBlockVariationId,
      });
    }
  );
};

export default trackWidgetAdded;
