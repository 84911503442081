import { i18next, site, serviceClient, dialogs as wsDialogs } from '@yola/ws-sdk';
import {
  selectors as subscriptionManagerSelectors,
  verifiers as subscriptionManagerVerifiers,
} from '@yola/subscription-manager-js';
import dialogs from 'yola-editor/src/js/modules/dialogs';
import auth from 'yola-editor/src/js/modules/auth';
import user from 'yola-editor/src/js/modules/user';
import yolaEditorSite from 'yola-editor/src/js/modules/site';
import setSettings from 'yola-editor/src/js/modules/editor/actions/set-settings';
import publishingServiceClient from 'yola-editor/src/js/modules/publishing-service-client/service-client';
import featureFlagsService from 'yola-editor/src/js/modules/feature-flags/service-client';
import { redirectToUrl } from 'yola-editor/src/js/router/utils/redirect-to-url';
import wsEditorPublishingActionTypes from 'src/js/modules/publishing/constants/action-types';
import isFreeDomainAvailable from 'yola-editor/src/js/modules/upsell/helpers/is-free-domain-available';
import actionTypes from '../constants/action-types';
import publishingJobStatuses from '../constants/publishing-job-statuses';
import constants from '../constants';
import actions from '../actions';
import selectors from '../selectors';
import pollGetPublishingJob from '../helpers/poll-get-publishing-job';
import thunks from '../thunks';
import continuePublishingProgress from '../thunks/continue-publishing-progress';
import getPublishingJobsInProgress from '../helpers/get-publishing-jobs-in-progress';

const { getActiveSubscriptions, getSubscriptionList } = subscriptionManagerSelectors;

const websitePublisher = (store) => (next) => async (action) => {
  const service = publishingServiceClient.get();
  switch (action.type) {
    case wsEditorPublishingActionTypes.INITIATE_PUBLISH_WEBSITE: {
      const state = store.getState();
      const { isWhiteLabel } = user.selectors.getPartnerData(state);
      store.dispatch(yolaEditorSite.actions.setSiteName(site.selectors.getSiteName(state)));

      if (isWhiteLabel) {
        store.dispatch(actions.publishWebsite());
      } else {
        const activeSubscriptions = subscriptionManagerSelectors.getActiveSubscriptions(state);

        const publish = () => {
          user.operations
            .fetchUserPreferences()
            .then((userPreferences) => {
              if (Number(userPreferences.isEmailVerified)) {
                store.dispatch(actions.publishWebsite());
              } else {
                store.dispatch(dialogs.actions.show(dialogs.dialogTypes.VERIFY_EMAIL));
              }
            })
            .catch((error) => {
              // eslint-disable-next-line no-console
              console.log(error);
            });
        };

        if (subscriptionManagerVerifiers.hasExpiredPaidSubscription(activeSubscriptions)) {
          store.dispatch(
            dialogs.actions.show(dialogs.dialogTypes.SUBSCRIPTION_EXPIRED, {
              onClose: publish,
            })
          );

          next(action);
          return;
        }

        publish();
      }

      next(action);
      break;
    }

    case actionTypes.PUBLISH_WEBSITE: {
      const { vhostName } = action.payload;
      const state = store.getState();
      const publishingStatus = selectors.getPublishingStatus(state);

      if (publishingStatus === publishingJobStatuses.IN_PROGRESS) {
        next(action);
        return;
      }

      const siteId = auth.selectors.getSiteId(state);
      const publishingType = user.selectors.getPublishingType(state);

      store.dispatch(
        dialogs.actions.show(dialogs.dialogTypes.PUBLISH_PROGRESS, {
          captions: {
            title: i18next.t('Preparing for launch'),
            description: i18next.t('Your website will be published soon'),
          },
        })
      );
      store.dispatch(actions.setPublishingStatus(publishingJobStatuses.IN_PROGRESS));

      getPublishingJobsInProgress(siteId, publishingType)
        .then((jobs) => {
          if (jobs.length) {
            return pollGetPublishingJob({
              jobId: jobs[0].id,
              onSuccess: (getPublishingJobResponse) =>
                store.dispatch(continuePublishingProgress({ response: getPublishingJobResponse })),
              onRequestFail: () =>
                store.dispatch(
                  continuePublishingProgress({
                    response: { data: { status: publishingJobStatuses.FAILED } },
                  })
                ),
            });
          }

          return service
            .initiatePublishing(siteId, constants.PUBLISHING_INITIATOR_APP_NAME, vhostName)
            .then(({ data: { id } }) =>
              pollGetPublishingJob({
                jobId: id,
                onRequestFail: () => {
                  store.dispatch(
                    continuePublishingProgress({
                      response: { data: { status: publishingJobStatuses.FAILED } },
                      nextCb: () => {
                        next(action);
                      },
                    })
                  );
                },
                onSuccess: (response) =>
                  store.dispatch(
                    thunks.continuePublishingProgress({
                      response,
                      nextCb: () => {
                        next(action);
                      },
                    })
                  ),
              })
            );
        })
        .catch(() => {
          store.dispatch(actions.setPublishingStatus(publishingJobStatuses.FAILED));

          store.dispatch(dialogs.actions.hide());
          next(action);
        });

      break;
    }

    case actionTypes.FINISH_PUBLISHING: {
      const { siteUrl, withUpsell = false, isPublished, partnerId } = action.payload;
      const featureFlagsManager = featureFlagsService.get();
      const state = store.getState();
      const activeSubscriptions = getActiveSubscriptions(state);
      const allSubscriptionsList = getSubscriptionList(state);

      const { refer_a_friend: isReferAFriendAvailable, freedomain: isFreeDomainEnabled } =
        await featureFlagsManager.request(['refer_a_friend', 'freedomain']);
      const client = serviceClient.get();
      const { data: siteData } = await client.getCurrentSite();
      const { properties: siteProperties } = siteData;

      store.dispatch(actions.setPublishingStatus(publishingJobStatuses.COMPLETE));
      store.dispatch(dialogs.actions.hide());
      store.dispatch(
        dialogs.actions.show(dialogs.dialogTypes.PUBLISH_SUCCESS, {
          siteUrl,
          withUpsell,
          isPublished,
          partnerId,
          isReferAFriendAvailable,
          isFreeDomainAvailable: isFreeDomainAvailable(
            activeSubscriptions,
            allSubscriptionsList,
            isFreeDomainEnabled
          ),
          siteProperties,
        })
      );

      next(action);
      break;
    }
    case actionTypes.UNPUBLISH_WEBSITE: {
      const state = store.getState();
      const siteId = auth.selectors.getSiteId(state);
      const { redirectUrl } = action.payload;

      store.dispatch(dialogs.actions.show(dialogs.dialogTypes.UNPUBLISH_PROGRESS));

      service
        .unpublishSite(siteId)
        .then(() => {
          store.dispatch(dialogs.actions.show(dialogs.dialogTypes.UNPUBLISH_SUCCESS));
          store.dispatch(yolaEditorSite.actions.setPublishedSiteData(null));
          store.dispatch(setSettings());

          if (redirectUrl) {
            store.dispatch(dialogs.actions.hide());
            redirectToUrl(redirectUrl);
          }
        })
        .catch((error) => {
          wsDialogs.operations.hide();
          store.dispatch(
            dialogs.actions.show(dialogs.dialogTypes.UNPUBLISH_ERROR, {
              captions: {
                title: i18next.t('Site unpublishing failed'),
                description: i18next.t('Error: {error}', { error: error.message }),
              },
            })
          );
        });
      next(action);
      break;
    }
    default:
      next(action);
  }
};

export default websitePublisher;
