import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Text, Button } from '@yola/ws-ui';
import { i18next } from '@yola/ws-sdk';
import classNames from 'classnames';

function DomainSearchResultItem({ product, onSelect }) {
  const { name, price, available } = product;

  // for available domains available property may be missing
  const isAvailable = !('available' in product) || available;

  function handleSelect() {
    onSelect(product);
  }
  return (
    <div
      className={classNames('domain-search__result-item', {
        'domain-search__result-item_available': isAvailable,
      })}
    >
      <Text className="domain-search__name">{name}</Text>
      {isAvailable ? (
        <Fragment>
          <Text className="domain-search__price">{price}</Text>
          <Button type="cta" className="domain-search__select-btn" onClick={handleSelect}>
            {i18next.t('Buy')}
          </Button>
        </Fragment>
      ) : (
        <Text className="domain-search__result-status" align="center">
          {i18next.t('Unavailable')}
        </Text>
      )}
    </div>
  );
}

DomainSearchResultItem.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    price: PropTypes.string,
    term: PropTypes.string,
    available: PropTypes.bool,
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default DomainSearchResultItem;
