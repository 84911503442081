import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Text, Formik, Field, InputField, Button, SelectField } from '@yola/ws-ui';
import { i18next } from '@yola/ws-sdk';
import classNames from 'classnames';
import bowser from 'yola-bowser';
import i18n from 'yola-editor/src/js/modules/i18n';
import getCountryOptions from '../helpers/get-country-options';
import validators from '../helpers/validators';

function DomainRegistrationForm({ initialFormData, terms, onSubmit, onCancel }) {
  const locale = useSelector(i18n.selectors.getLocale);
  const countryOptions = getCountryOptions(locale);

  const validation = {
    email: validators.email(i18next.t('Invalid email')),
    phone: validators.phone(i18next.t('Invalid phone')),
    fax: validators.phone(i18next.t('Invalid fax')),
    required: validators.required(i18next.t('This field is required')),
  };

  const validatorsList = (list) => (value) =>
    list.reduce((error, validator) => error || validator(value), false);

  const onSubmitForm = (values) => onSubmit(values);

  const handleKeyDown = (event, submitForm) => {
    if (event.key === 'Enter') {
      submitForm();
    }
  };

  return (
    <Formik initialValues={initialFormData} validateOnBlur={false} onSubmit={onSubmitForm}>
      {({ handleSubmit, submitForm }) => (
        <form
          className="domain-registration"
          onSubmit={handleSubmit}
          onKeyDown={(event) => handleKeyDown(event, submitForm)}
        >
          <Text className="domain-registration__title">{i18next.t('Domain registration')}</Text>
          <Text type="heading-5" className="domain-registration__subtitle">
            {i18next.t('Add contact details for your domain’s official registrant (owner)')}
          </Text>
          <div className="domain-registration__item">
            <Text type="heading-4">{i18next.t('Personal information')}</Text>

            {/* eslint-disable-next-line yola/jsx-a11y/label-has-for */}
            <label htmlFor="firstName">
              <Text className="domain-registration__label">{i18next.t('First name')}</Text>
            </label>
            <Field
              id="firstName"
              component={InputField}
              name="firstName"
              placeholder={i18next.t('Enter your name')}
              className="domain-registration__input"
              validate={validation.required}
              errorElementIsStatic
            />

            {/* eslint-disable-next-line yola/jsx-a11y/label-has-for */}
            <label htmlFor="lastName">
              <Text className="domain-registration__label">{i18next.t('Last name')}</Text>
            </label>
            <Field
              id="lastName"
              component={InputField}
              name="lastName"
              placeholder={i18next.t('Enter your last name')}
              className="domain-registration__input"
              validate={validation.required}
              errorElementIsStatic
            />

            {/* eslint-disable-next-line yola/jsx-a11y/label-has-for */}
            <label htmlFor="orgName">
              <Text className="domain-registration__label">{i18next.t('Company name')}</Text>
            </label>
            <Field
              id="orgName"
              component={InputField}
              name="orgName"
              placeholder={i18next.t('Enter your company name')}
              className="domain-registration__input"
              validate={validation.required}
              errorElementIsStatic
            />

            <Text type="heading-4" className="domain-registration__form-title">
              {i18next.t('Contact information')}
            </Text>

            {/* eslint-disable-next-line yola/jsx-a11y/label-has-for */}
            <label htmlFor="email">
              <Text className="domain-registration__label">{i18next.t('Email')}</Text>
            </label>
            <Field
              id="email"
              component={InputField}
              name="email"
              type="email"
              placeholder={i18next.t('Enter your email')}
              className="domain-registration__input"
              validate={validatorsList([validation.required, validation.email])}
              errorElementIsStatic
            />

            <div className="domain-registration__input-pair">
              <div className="domain-registration__input-wrapper">
                {/* eslint-disable-next-line yola/jsx-a11y/label-has-for */}
                <label htmlFor="phone">
                  <Text className="domain-registration__label">{i18next.t('Phone')}</Text>
                </label>
                <Field
                  id="phone"
                  component={InputField}
                  name="phone"
                  type="tel"
                  placeholder={i18next.t('Enter your phone')}
                  className="domain-registration__input"
                  validate={validatorsList([validation.required, validation.phone])}
                  errorElementIsStatic
                />
              </div>

              <div className="domain-registration__input-wrapper">
                {/* eslint-disable-next-line yola/jsx-a11y/label-has-for */}
                <label htmlFor="fax">
                  <Text className="domain-registration__label">
                    {i18next.t('Fax')}{' '}
                    <span className="domain-registration__label-annotation">
                      ({i18next.t('optional')})
                    </span>
                  </Text>
                </label>
                <Field
                  id="fax"
                  component={InputField}
                  name="fax"
                  placeholder={i18next.t('Enter your fax number')}
                  className="domain-registration__input"
                  validate={validation.fax}
                  errorElementIsStatic
                />
              </div>
            </div>

            <Text type="heading-4" className="domain-registration__form-title">
              {i18next.t('Billing address')}
            </Text>

            {/* eslint-disable-next-line yola/jsx-a11y/label-has-for */}
            <label htmlFor="address1">
              <Text className="domain-registration__label">{i18next.t('Address')}</Text>
            </label>
            <Field
              id="address1"
              component={InputField}
              name="address1"
              placeholder={i18next.t('Enter your address')}
              className="domain-registration__input"
              validate={validation.required}
              errorElementIsStatic
            />

            <div className="domain-registration__input-pair">
              <div className="domain-registration__input-wrapper">
                {/* eslint-disable-next-line yola/jsx-a11y/label-has-for */}
                <label htmlFor="city">
                  <Text className="domain-registration__label">{i18next.t('City')}</Text>
                </label>
                <Field
                  id="city"
                  component={InputField}
                  name="city"
                  placeholder={i18next.t('Enter your city')}
                  className="domain-registration__input"
                  validate={validation.required}
                  errorElementIsStatic
                />
              </div>

              <div className="domain-registration__input-wrapper">
                {/* eslint-disable-next-line yola/jsx-a11y/label-has-for */}
                <label htmlFor="state">
                  <Text className="domain-registration__label">
                    {i18next.t('State')}{' '}
                    <span className="domain-registration__label-annotation">
                      ({i18next.t('optional')})
                    </span>
                  </Text>
                </label>
                <Field
                  id="state"
                  component={InputField}
                  name="state"
                  placeholder={i18next.t('Enter your state')}
                  className="domain-registration__input"
                />
              </div>
            </div>

            <div className="domain-registration__input-pair">
              <div className="domain-registration__input-wrapper">
                {/* eslint-disable-next-line yola/jsx-a11y/label-has-for */}
                <label htmlFor="country">
                  <Text className="domain-registration__label">{i18next.t('Country')}</Text>
                </label>
                <Field
                  simpleValue
                  id="country"
                  component={SelectField}
                  name="country"
                  options={countryOptions}
                  clearable={false}
                  placeholder={i18next.t('Select your country')}
                  className="domain-registration__input"
                  validate={validation.required}
                  errorElementIsStatic
                />
              </div>

              <div className="domain-registration__input-wrapper">
                {/* eslint-disable-next-line yola/jsx-a11y/label-has-for */}
                <label htmlFor="postalCode">
                  <Text className="domain-registration__label">{i18next.t('Postal code')}</Text>
                </label>
                <Field
                  id="postalCode"
                  component={InputField}
                  name="postalCode"
                  placeholder={i18next.t('Enter your postal code')}
                  className="domain-registration__input"
                  validate={validation.required}
                  errorElementIsStatic
                />
              </div>
            </div>
          </div>
          {!!terms && (
            <Text
              className="domain-registration__terms"
              /* eslint-disable-next-line yola/react/no-danger */
              dangerouslySetInnerHTML={{ __html: terms }}
            />
          )}
          <div className="domain-registration__buttons-container">
            <Button
              className="domain-registration__button"
              type="cta"
              size="large"
              onClick={submitForm}
            >
              {i18next.t('Next')}
            </Button>
            <Button
              className={classNames(
                'domain-registration__button',
                'domain-registration__button--transparent',
                {
                  'domain-registration__button--hover': bowser.desktop,
                }
              )}
              type="link-primary"
              size="large"
              onClick={onCancel}
            >
              {i18next.t('Cancel')}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
}

DomainRegistrationForm.propTypes = {
  initialFormData: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    orgName: PropTypes.string.isRequired,
    address1: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    postalCode: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    fax: PropTypes.string,
    email: PropTypes.string.isRequired,
  }).isRequired,
  terms: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

DomainRegistrationForm.defaultProps = {
  terms: '',
};

export default DomainRegistrationForm;
