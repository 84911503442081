import { i18next } from '@yola/ws-sdk';
import tours from '../constants/tours';

const getCaptions = (tour) => {
  const captions = {
    [tours.WELCOME_TOUR]: [
      {
        title: i18next.t('Welcome to Sitebuilder'),
        subtitle: i18next.t('Your site is made of blocks. Here is how it works'),
      },
      {
        title: i18next.t('Add'),
        subtitle: i18next.t('Add new blocks to build your content'),
      },
      {
        title: i18next.t('Modify'),
        subtitle: i18next.t('Modify blocks to make them your own'),
      },
      {
        title: i18next.t('Brand'),
        subtitle: i18next.t('Adjust fonts and colors to match your brand'),
      },
      {
        title: i18next.t('Publish'),
        subtitle: i18next.t('Publish your site to share it to the world'),
      },
      {
        title: i18next.t('Grow'),
        subtitle: i18next.t('As your business grows, add new pages to accommodate'),
      },
    ],
    [tours.MULTILINGUAL_TOUR]: [
      {
        title: i18next.t('Multilingual site'),
        subtitle: i18next.t('Your site is now multilingual. Here are the basics you should know'),
      },
      {
        title: i18next.t('Translate'),
        subtitle: i18next.t('Manually translate your content by clicking on text or button'),
      },
      {
        title: i18next.t('Navigate'),
        subtitle: i18next.t(
          'Help your visitors browse through the secondary language of your site'
        ),
      },
      {
        title: i18next.t('Adapt'),
        subtitle: i18next.t('Change the structure of translated pages to adapt them to your needs'),
      },
    ],
  };
  return captions[tour];
};

export default getCaptions;
