import { view } from '@yola/ws-sdk';

const getWsNav = () => {
  const liveDocument = view.accessors.getLiveDocument();
  const headerComponent = liveDocument.querySelector('ws-header');

  // This check is needed for detect `ws-intense-next` template,
  // since there `ws-nav` component is inside shadowRoot.
  // TODO Delete this condition and second return
  // after removing `ws-intense` template.
  if (headerComponent?.isDynamicMode) {
    return headerComponent.shadowRoot.querySelector('ws-nav');
  }

  return liveDocument.querySelector('ws-nav');
};

export default getWsNav;
