import React from 'react';
import PropTypes from 'prop-types';

const ColorOption = ({ color, backgroundColor, handleClick }) => (
  <div className="ws-color-option">
    <button
      style={{ backgroundColor }}
      className="ws-color-option__background"
      type="button"
      onClick={handleClick}
    >
      <i className="ws-color-option__point" style={{ backgroundColor: color }} />
    </button>
  </div>
);

ColorOption.propTypes = {
  color: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
};

ColorOption.defaultProps = {
  handleClick: () => {},
};

export default ColorOption;
