import { view } from '@yola/ws-sdk';
import blocks from 'src/js/modules/blocks';
import hasBackgroundContainer from './has-background-container';
import isImageContainer from './is-image-container';
import isGalleryItem from './is-gallery-item';
import { getSettings } from '../settings';
import operationTypes from '../constants/operation-types';

// eslint-disable-next-line consistent-return
const getViewOperation = (operationType, elementId, value) => {
  const settings = getSettings();
  const element = view.accessors.getLiveElement(elementId);
  const { setElementAttribute } = view.operations;
  const backgroundContainerNode = element.querySelector(settings.backgroundContainer);
  const backgroundContainerNodeId =
    backgroundContainerNode && view.accessors.getLiveElementId(backgroundContainerNode);
  const imageContainerNodeId = view.accessors.getLiveElementId(element);
  switch (operationType) {
    case operationTypes.POSITION: {
      if (blocks.verifiers.isWsBlock(element)) {
        return [setElementAttribute, [elementId, 'background-position', value]];
      }
      if (hasBackgroundContainer(element)) {
        return [setElementAttribute, [backgroundContainerNodeId, 'position', value]];
      }
      if (isImageContainer(element)) {
        return [setElementAttribute, [imageContainerNodeId, 'content-position', value]];
      }
      break;
    }
    case operationTypes.SCALE: {
      if (blocks.verifiers.isWsBlock(element)) {
        return [setElementAttribute, [elementId, 'background-scale', value]];
      }
      if (hasBackgroundContainer(element)) {
        return [setElementAttribute, [backgroundContainerNodeId, 'scale', value]];
      }
      if (isImageContainer(element)) {
        return [setElementAttribute, [imageContainerNodeId, 'content-scale', value]];
      }
      break;
    }
    case operationTypes.SRC: {
      if (blocks.verifiers.isWsBlock(element)) {
        return [setElementAttribute, [elementId, 'background-image', value]];
      }
      if (isGalleryItem(element)) {
        return [setElementAttribute, [elementId, 'origin-src', value]];
      }
      if (hasBackgroundContainer(element)) {
        return [setElementAttribute, [backgroundContainerNodeId, 'src', value]];
      }
      if (isImageContainer(element)) {
        const image = element.querySelector('img');
        const imageId = image && view.accessors.getLiveElementId(image);
        return [setElementAttribute, [imageId, 'src', value]];
      }
      break;
    }

    case operationTypes.ORIGIN_WIDTH:
    case operationTypes.ORIGIN_HEIGHT:
      return [setElementAttribute, [elementId, operationType, value]];

    case operationTypes.THUMBNAIL: {
      const image = element.querySelector('img');
      const imageId = image && view.accessors.getLiveElementId(image);
      return [setElementAttribute, [imageId, 'src', value]];
    }
    default: {
      const computedValue = value === true ? '' : value;

      return [setElementAttribute, [elementId, operationType, computedValue]];
    }
  }
};

export default getViewOperation;
