import React from 'react';
import { extensions, i18next, template, view } from '@yola/ws-sdk';
import serviceClient from 'yola-editor/src/js/modules/feature-flags/service-client';
import TextFormattingActionTrigger from '../components/text-formatting-action-trigger';
import TextFormattingTrigger from '../components/text-formatting-trigger';
import DecorationTrigger from '../components/decoration-trigger';
import getTextFormattingOptions from '../helpers/get-text-formatting-options';
import ClearFormattingTrigger from '../components/clear-formatting-trigger';
import shouldShowClearTrigger from '../verifiers/should-show-clear-trigger';
import shouldShowTextFormattingTrigger from '../verifiers/should-show-text-formatting-trigger';

const { controlTypes } = extensions;
const textFormattingTriggerId = 'text-formatting';
const clearTriggerId = 'text-clear-formatting';

const textFormattingHeader = (elementId) => {
  const isMpt = template.verifiers.isMpt();
  const featureFlagsManager = serviceClient.get();
  const { decoration_text_formatting: isDecorationTextFormattingEnabled } =
    featureFlagsManager.state;
  const isDecorationAvailable = isMpt && isDecorationTextFormattingEnabled;
  const element = view.accessors.getLiveElement(elementId);

  return [
    ...getTextFormattingOptions().map((options) => <TextFormattingActionTrigger {...options} />),
    isDecorationAvailable && <DecorationTrigger elementId={elementId} />,
    shouldShowClearTrigger(element) && (
      <ClearFormattingTrigger
        triggerId={clearTriggerId}
        elementId={elementId}
        icon="type-clean"
        getTooltip={() => i18next.t('Clear')}
      />
    ),
  ].filter(Boolean);
};

const control = {
  id: textFormattingTriggerId,
  trigger: <TextFormattingTrigger triggerId={textFormattingTriggerId} />,
  header: textFormattingHeader,
  closeIcon: 'submit',
  get tooltip() {
    return i18next.t('Text formatting');
  },
  priority: 60,
  for: [controlTypes.TEXT],
  matches: shouldShowTextFormattingTrigger,
  highlightElement: false,
};

export default control;
