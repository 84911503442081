import React from 'react';
import PropTypes from 'prop-types';
import { dialogs, view, i18next } from '@yola/ws-sdk';
import {
  Modal,
  PanelGroup,
  Panel,
  ButtonGroup,
  Button,
  Text,
  Spinner,
  designSystem,
} from '@yola/ws-ui';
import bowser from 'yola-bowser';
import debounce from 'lodash.debounce';
import constants from '../constants/common';
import helpers from '../helpers';

const { Search } = designSystem;

const { MODAL_HEIGHT, MODAL_WIDTH, PANEL_HEIGHT } = constants;
const closeModal = () => dialogs.operations.hide();

class ModalContainer extends React.Component {
  constructor(props) {
    super(props);
    const { elementId } = this.props;
    const element = view.accessors.getLiveElement(elementId);
    const dialogPosition = dialogs.helpers.getPositionByElement(element, MODAL_WIDTH, MODAL_HEIGHT);
    const { offsetX, offsetY } = dialogPosition;

    const initialMapUrlSearchParams = helpers.getMapUrlSearchParams(elementId);
    this.initialSearchValue = initialMapUrlSearchParams.q || '';

    this.isMobile = bowser.mobile;

    this.state = {
      src: helpers.getUpdatedMapSource(this.initialSearchValue),
      isLoadingInProgress: true,
      offsetX,
      offsetY,
    };

    this.mapIframe = React.createRef();

    this.handleSearch = debounce(this.handleSearch.bind(this), 300);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.updateModalPosition = this.updateModalPosition.bind(this);
    this.onIframeLoaded = this.onIframeLoaded.bind(this);
  }

  componentDidMount() {
    this.mapIframe.current.addEventListener('load', this.onIframeLoaded);
  }

  componentWillUnmount() {
    this.mapIframe.current.removeEventListener('load', this.onIframeLoaded);
  }

  onIframeLoaded() {
    this.setState({ isLoadingInProgress: false });
    this.submitInitiated = false;
  }

  handleSearch(value) {
    if (!value) return;

    const { onSearchPerform } = this.props;
    const { src: currentSrc, isLoadingInProgress } = this.state;

    const src = helpers.getUpdatedMapSource(value);
    const isSourceChanged = currentSrc !== src;

    if (!isLoadingInProgress && isSourceChanged) {
      this.setState({ isLoadingInProgress: true, src });

      // Currently, just a single search result is possible
      const searchResults = 1;
      onSearchPerform(value, searchResults);
    }
  }

  handleSubmit() {
    const { elementId, onDialogMainAction } = this.props;
    const { src, isLoadingInProgress } = this.state;

    if (isLoadingInProgress) return;

    if (src) {
      helpers.setLiveMapSource(elementId, src);
    }
    onDialogMainAction();
    closeModal();
  }

  handleCancel() {
    const { onDialogCancel } = this.props;

    onDialogCancel();
    closeModal();
  }

  updateModalPosition(event, { x, y }) {
    this.setState({ offsetX: x, offsetY: y });
  }

  render() {
    const { offsetX, offsetY, isLoadingInProgress, src } = this.state;
    const { onSearchClick } = this.props;

    return (
      <Modal
        width={MODAL_WIDTH}
        maxWidth={MODAL_WIDTH}
        minWidth={MODAL_WIDTH}
        height={MODAL_HEIGHT}
        fullscreen={this.isMobile}
        dragHandleSelector=".ws-drag-trigger"
        handleCancel={this.handleCancel}
        handleSubmit={this.handleSubmit}
        onDragEnd={this.updateModalPosition}
        offsetX={offsetX}
        offsetY={offsetY}
      >
        <PanelGroup width="100%" height="100%">
          <Panel
            theme="gray-100"
            corners="squared"
            align="middle"
            style={{ flexShrink: '0' }}
            height={48}
            className="ws-drag-trigger ws-modal__content"
          >
            <Text type="heading-6">{i18next.t('Google Maps Settings')}</Text>
          </Panel>

          <Panel
            height={`calc(100% - ${PANEL_HEIGHT}px * 2)`}
            theme="white"
            corners="squared"
            scrollable
            className="ws-google-map-embed__content"
          >
            <div className="ws-google-map-embed__search">
              <Search
                defaultValue={this.initialSearchValue}
                onChange={this.handleSearch}
                onClear={this.handleSearch}
                onFocus={onSearchClick}
                placeholder={i18next.t('Search...')}
              />
            </div>

            <div className="ws-google-map-embed__preview-container">
              {isLoadingInProgress && (
                <div className="ws-google-map-embed__spinner-container">
                  <Spinner containerPosition="center" spinnerPosition="center" />
                </div>
              )}

              <iframe
                title="Google Embed Map"
                className="ws-google-map-embed__preview"
                src={src}
                frameBorder="0"
                ref={this.mapIframe}
              />
              <div className="ws-google-map-embed__preview-overlay" />
            </div>
          </Panel>
          <Panel height={PANEL_HEIGHT} align="middle" corners="squared">
            <ButtonGroup stick="top" block>
              <Button
                stretch="block"
                size="large"
                disabled={isLoadingInProgress}
                onClick={this.handleSubmit}
              >
                <Text type="heading-6">{i18next.t('Submit')}</Text>
              </Button>
              <Button stretch="block" size="large" onMouseDown={this.handleCancel}>
                <Text type="heading-6">{i18next.t('Cancel')}</Text>
              </Button>
            </ButtonGroup>
          </Panel>
        </PanelGroup>
      </Modal>
    );
  }
}

ModalContainer.propTypes = {
  elementId: PropTypes.string.isRequired,
  onSearchClick: PropTypes.func,
  onSearchPerform: PropTypes.func,
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

ModalContainer.defaultProps = {
  onSearchClick: Function.prototype,
  onSearchPerform: Function.prototype,
};

export default ModalContainer;
