export default {
  SET_SITE_NAME: '@YOLA_EDITOR/SITE/SET_SITE_NAME',
  FETCH_PUBLISHED_SITE: '@YOLA_EDITOR/SITE/FETCH_PUBLISHED_SITE',
  SET_PRIMARY_VHOST: '@YOLA_EDITOR/SITE/SET_PRIMARY_VHOST',
  SET_PUBLISHED_SITE_DATA: '@YOLA_EDITOR/SITE/SET_PUBLISHED_SITE_DATA',
  SET_PUBLISHED_DATA_LOADED_STATUS: '@YOLA_EDITOR/SITE/SET_PUBLISHED_DATA_LOADED_STATUS',
  SET_SITE_URL: '@YOLA_EDITOR/SITE/SET_SITE_URL',
  FETCH_DATA: '@YOLA_EDITOR/SITE/FETCH_DATA',
  SET_DATA: '@YOLA_EDITOR/SITE/SET_DATA',
  SITE_RESET: '@YOLA_EDITOR/SITE/SITE_RESET',
  SET_PUBLISH_COUNT: '@YOLA_EDITOR/SITE/SET_PUBLISH_COUNT',
  SET_TEMPLATE_SWITCHED: '@YOLA_EDITOR/SITE/SET_TEMPLATE_SWITCHED',
  SUBMIT_SITEMAP: '@YOLA_EDITOR/SITE/SUBMIT_SITEMAP',
  UPDATE_SITE_DATA: '@YOLA_EDITOR/SITE/UPDATE_SITE_DATA',
};
