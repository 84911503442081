import isWsBlock from 'src/js/modules/blocks/verifiers/is-ws-block';
import isImageContainer from './is-image-container';
import hasBackgroundContainer from './has-background-container';
import isGalleryItem from './is-gallery-item';
import { getSettings } from '../settings';

const setCurrentImageUrl = (node, url, thumbnailUrl) => {
  const settings = getSettings();
  const imageNode = node;

  if (isGalleryItem(imageNode)) {
    imageNode.setAttribute('origin-src', url);
    imageNode.querySelector('img').setAttribute('src', thumbnailUrl);
    return;
  }

  if (isWsBlock(imageNode)) {
    imageNode.setAttribute('background-image', url);
    return;
  }

  if (hasBackgroundContainer(imageNode)) {
    const backgroundContainerNode = imageNode.querySelector(settings.backgroundContainer);
    backgroundContainerNode.setAttribute('src', url);
    return;
  }

  if (isImageContainer(imageNode)) {
    imageNode.querySelector('img').setAttribute('src', url);
  }
};

export default setCurrentImageUrl;
