import regeneratePropertiesForBlockCssRule from './regenerate-properties-for-block-css-rule';

const getUniqProperties = (cssText) => {
  const uniqPropertiesMap = cssText
    .split(';')
    .reduce((map, propertyRule) => map.set(propertyRule.split(':')[0], propertyRule), new Map());
  return [...uniqPropertiesMap.values()].join(';');
};

const generateBlockIdCssRule = ({ stylesheet, blockId, surfaces, blockIdRulesMap }) => {
  const { cssRules } = stylesheet;

  Object.entries(cssRules)
    .reverse()
    .forEach(([index, itm]) => {
      if (itm.selectorText.includes(blockId)) {
        stylesheet.deleteRule(index);
      }
    });

  const blockIdRulesCssText = Array.from(blockIdRulesMap.values()).join();

  surfaces.split(' ').forEach((surface) => {
    const blockRuleSelector = `#${blockId} .${surface}`;

    const blockProps = getUniqProperties(blockIdRulesCssText);

    const blockRule = `${blockRuleSelector} { ${blockProps} }`;
    stylesheet.insertRule(blockRule, cssRules.length);

    const insertedRule = cssRules[cssRules.length - 1];

    regeneratePropertiesForBlockCssRule(insertedRule, blockId, surface);
  });
};

export default generateBlockIdCssRule;
