import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { designSystem, Field, Render, Text } from '@yola/ws-ui';
import CodeEditorField from 'src/js/modules/common/components/code-editor-field';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import segment from 'src/js/modules/analytics/segment';
import Annotation from './annotation';
import constants from '../constants';

const { Stack, Paragraph, CtaBanner, Box } = designSystem;
const {
  common: { HEAD_CODE_NAME, HEADER_CODE_NAME, FOOTER_CODE_NAME },
  CODE_EDITOR_HEIGHT,
} = constants;
const {
  trackers: { trackCodeEditorFocused },
} = segment;

function CustomCodeSection({
  captions,
  headCodeUpgradePopoverRef,
  headerCodeUpgradePopoverRef,
  footerCodeUpgradePopoverRef,
  onHeadCodeUpgradeClick,
  onHeaderCodeUpgradeClick,
  onFooterCodeUpgradeClick,
  onCodeFieldClick,
  headCodeDisabled,
  headerCodeDisabled,
  footerCodeDisabled,
  activeUpgradePopover,
}) {
  const onCodeEditorFocus = () => {
    trackCodeEditorFocused(dialogTypes.PAGE_SETTINGS);
  };

  const handleHeadCodeClick = useCallback(
    () => onCodeFieldClick(HEAD_CODE_NAME, headCodeUpgradePopoverRef, headCodeDisabled),
    [onCodeFieldClick, headCodeUpgradePopoverRef, headCodeDisabled]
  );
  const handleHeaderCodeClick = useCallback(
    () => onCodeFieldClick(HEADER_CODE_NAME, headerCodeUpgradePopoverRef, headerCodeDisabled),
    [onCodeFieldClick, headerCodeUpgradePopoverRef, headerCodeDisabled]
  );
  const handleFooterCodeClick = useCallback(
    () => onCodeFieldClick(FOOTER_CODE_NAME, footerCodeUpgradePopoverRef, footerCodeDisabled),
    [onCodeFieldClick, footerCodeUpgradePopoverRef, footerCodeDisabled]
  );
  return (
    <Stack gap="spacing-xs">
      <Paragraph appearance="medium-emphasis" size="small">
        {captions.description}
      </Paragraph>

      <div>
        <Text type="heading-6">{captions.headCode.name}</Text>
        <Box marginTop="spacing-3-xs" marginBottom="spacing-3-xs">
          <div onClick={handleHeadCodeClick}>
            <Field
              component={CodeEditorField}
              name={HEAD_CODE_NAME}
              disabled={headCodeDisabled}
              height={headCodeDisabled ? CODE_EDITOR_HEIGHT.min : CODE_EDITOR_HEIGHT.max}
              onFocus={onCodeEditorFocus}
              options={{
                readOnly: headCodeDisabled,
                showPrintMargin: true,
                showLineNumbers: true,
                tabSize: 2,
                autoScrollEditorIntoView: true,
              }}
            />
          </div>
        </Box>
        <Render if={headCodeDisabled}>
          <Box marginBottom="spacing-3-xs">
            <div ref={headCodeUpgradePopoverRef}>
              <CtaBanner
                title={captions.headCode.upgradeText.description}
                iconGlyph="upgrade"
                animate={activeUpgradePopover === HEAD_CODE_NAME}
                id={HEAD_CODE_NAME}
                ctaButton={{
                  label: captions.headCode.upgradeText.upgrade,
                  onClick: onHeadCodeUpgradeClick,
                }}
              />
            </div>
          </Box>
        </Render>
        <Annotation description={captions.headCode.description} link={captions.headCode.link} />
      </div>
      <div>
        <Text type="heading-6">{captions.headerCode.name}</Text>
        <Box marginTop="spacing-3-xs" marginBottom="spacing-3-xs">
          <div onClick={handleHeaderCodeClick}>
            <Field
              component={CodeEditorField}
              name={HEADER_CODE_NAME}
              disabled={headerCodeDisabled}
              height={headerCodeDisabled ? CODE_EDITOR_HEIGHT.min : CODE_EDITOR_HEIGHT.max}
              onFocus={onCodeEditorFocus}
              options={{
                readOnly: headerCodeDisabled,
                showPrintMargin: true,
                showLineNumbers: true,
                tabSize: 2,
                autoScrollEditorIntoView: true,
              }}
            />
          </div>
        </Box>
        <Render if={headerCodeDisabled}>
          <Box marginBottom="spacing-3-xs">
            <div ref={headerCodeUpgradePopoverRef}>
              <CtaBanner
                title={captions.headerCode.upgradeText.description}
                iconGlyph="upgrade"
                animate={activeUpgradePopover === HEADER_CODE_NAME}
                id={HEADER_CODE_NAME}
                ctaButton={{
                  label: captions.headerCode.upgradeText.upgrade,
                  onClick: onHeaderCodeUpgradeClick,
                }}
              />
            </div>
          </Box>
        </Render>
        <Annotation description={captions.headerCode.description} link={captions.headerCode.link} />
      </div>
      <div>
        <Text type="heading-6">{captions.footerCode.name}</Text>
        <Box marginTop="spacing-3-xs" marginBottom="spacing-3-xs">
          <div onClick={handleFooterCodeClick}>
            <Field
              component={CodeEditorField}
              name={FOOTER_CODE_NAME}
              disabled={footerCodeDisabled}
              height={footerCodeDisabled ? CODE_EDITOR_HEIGHT.min : CODE_EDITOR_HEIGHT.max}
              onFocus={onCodeEditorFocus}
              options={{
                readOnly: footerCodeDisabled,
                showPrintMargin: true,
                showLineNumbers: true,
                tabSize: 2,
                autoScrollEditorIntoView: true,
              }}
            />
          </div>
        </Box>
        <Render if={footerCodeDisabled}>
          <Box marginBottom="spacing-3-xs">
            <div ref={footerCodeUpgradePopoverRef}>
              <CtaBanner
                title={captions.footerCode.upgradeText.description}
                iconGlyph="upgrade"
                animate={activeUpgradePopover === FOOTER_CODE_NAME}
                id={FOOTER_CODE_NAME}
                ctaButton={{
                  label: captions.footerCode.upgradeText.upgrade,
                  onClick: onFooterCodeUpgradeClick,
                }}
              />
            </div>
          </Box>
        </Render>
        <Annotation description={captions.footerCode.description} link={captions.footerCode.link} />
      </div>
    </Stack>
  );
}

CustomCodeSection.propTypes = {
  captions: PropTypes.shape().isRequired,
  onHeadCodeUpgradeClick: PropTypes.func.isRequired,
  headCodeDisabled: PropTypes.bool.isRequired,
  onCodeFieldClick: PropTypes.func,
  headCodeUpgradePopoverRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.instanceOf(Element),
    }),
  ]).isRequired,
  headerCodeUpgradePopoverRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.instanceOf(Element),
    }),
  ]).isRequired,
  footerCodeUpgradePopoverRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.instanceOf(Element),
    }),
  ]).isRequired,
  activeUpgradePopover: PropTypes.string,
  headerCodeDisabled: PropTypes.bool.isRequired,
  onHeaderCodeUpgradeClick: PropTypes.func.isRequired,
  footerCodeDisabled: PropTypes.bool.isRequired,
  onFooterCodeUpgradeClick: PropTypes.func.isRequired,
};

CustomCodeSection.defaultProps = {
  onCodeFieldClick: Function.prototype,
  activeUpgradePopover: null,
};

export default CustomCodeSection;
