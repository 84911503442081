import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';

const { ActionListItem, ActionListFileTrigger } = designSystem;

function ContextMenuItem({
  caption,
  glyph,
  contentRight,
  onClick,
  onMouseEnter,
  onMouseLeave,
  id,
  element,
  beforeItemClick,
  active,
  disabled,
  fileTriggerConfig,
}) {
  const handleMouseEnter = useCallback(
    (e) => {
      if (!disabled()) {
        onMouseEnter(e);
      }
    },
    [onMouseEnter, disabled]
  );

  const handleClick = useCallback(
    (e) => {
      if (!disabled()) {
        beforeItemClick({ id, element });
        onClick(e);
      }
    },
    [beforeItemClick, onClick, id, element, disabled]
  );

  return Object.keys(fileTriggerConfig).length ? (
    <ActionListFileTrigger
      title={caption}
      iconGlyph={glyph}
      disabled={disabled()}
      onClick={handleClick}
      selected={active}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={onMouseLeave}
      fileTriggerConfig={fileTriggerConfig}
    />
  ) : (
    <ActionListItem
      title={caption}
      iconGlyph={glyph}
      contentRight={contentRight}
      disabled={disabled()}
      onClick={handleClick}
      selected={active}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  );
}

ContextMenuItem.propTypes = {
  id: PropTypes.string,
  caption: PropTypes.string.isRequired,
  element: PropTypes.instanceOf(Element),
  contentRight: PropTypes.elementType,
  glyph: PropTypes.string,
  disabled: PropTypes.func,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  beforeItemClick: PropTypes.func,
  active: PropTypes.bool,
  fileTriggerConfig: PropTypes.shape(),
};

ContextMenuItem.defaultProps = {
  glyph: null,
  contentRight: null,
  active: false,
  disabled: () => false,
  onClick: Function.prototype,
  onMouseEnter: Function.prototype,
  onMouseLeave: Function.prototype,
  beforeItemClick: Function.prototype,
  id: null,
  element: null,
  fileTriggerConfig: {},
};

export default ContextMenuItem;
