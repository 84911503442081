import { anodum, textFormatting } from '@yola/ws-sdk';
import constants from '../constants';
import wrapElementsIntoList from './wrap-elements-into-list';
import getListsFromElements from './get-lists-from-elements';
import unwrapListItems from './unwrap-list-items';
import changeListType from './change-list-type.js';
import restoreRange from './restore-range.js';
import getTopElementsFromSelection from './get-top-elements-from-selection';
import normalizeList from './normalize-list';
import getSelectedElement from './get-selected-element.js';
import getListFromSelection from './get-list-from-selection';
import isActiveList from '../verifiers/is-active-list.js';

const { LIST_ITEM_TAG } = constants.listFormatting;
const { isTag, isPhrasingElement } = anodum;

const toggleList = (listType, elementId) => {
  const selection = textFormatting.accessors.getAdvancedSelection();
  const selectedElement = getSelectedElement();
  const parentList = getListFromSelection(selectedElement);

  const range = selection.getRangeAt(0);
  const selectionElements = range.getNodes(
    [Node.ELEMENT_NODE],
    (el) =>
      !isPhrasingElement(el) &&
      !isTag(el, textFormatting.constants.wrappersTypes.customColor.tagName) &&
      !isTag(el, textFormatting.constants.wrappersTypes.decoration.tagName)
  );

  const selectedLists = getListsFromElements(selectionElements);

  const elements = getTopElementsFromSelection(selectionElements, selectedLists);

  if (selectionElements.length === 0) {
    elements.push(
      isTag(selectedElement.parentNode, LIST_ITEM_TAG)
        ? selectedElement.parentNode
        : selectedElement
    );
  }

  elements.forEach((element) => {
    if (element.style.textAlign) {
      element.style.removeProperty('text-align');
    }
  });

  const hasNotListItems = elements.some((el) => !isTag(el, LIST_ITEM_TAG));

  if (!hasNotListItems && parentList && !isTag(parentList, listType)) {
    changeListType(listType, elements);
    restoreRange(selection, elementId);

    return;
  }

  if (!hasNotListItems && isActiveList(listType)) {
    unwrapListItems(elements);
    restoreRange(selection, elementId);
  } else {
    const list = wrapElementsIntoList(listType, selectedLists, elements);
    normalizeList(list, listType);
    restoreRange(selection, elementId);

    return;
  }

  restoreRange(selection, elementId);
};

export default toggleList;
