import constants from '../constants';
import getIconDialogCommonTraits from '../helpers/get-icon-dialog-common-traits';
import track from '../track';

const { events } = constants;

export default function trackSelectIconTypeFilterSelected({
  dialogId,
  targetElementId,
  oldTypeId,
  newTypeId,
  tabId,
  premiumAvailable,
}) {
  const traits = {
    dialogId,
    oldTypeId,
    newTypeId,
    tabId,
    premiumAvailable,
    ...getIconDialogCommonTraits(targetElementId),
  };

  track(events.SELECT_ICON_TYPE_FILTER_SELECTED, traits);
}
