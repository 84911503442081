import { site, view, linkEditing } from '@yola/ws-sdk';
import urlJoin from 'url-join';
import constants from '../constants';
import getCurrentItemOrder from './get-current-item-order';
import generateRandomId from './generate-random-id';

const { selectors, common } = constants;

const parseItemsFromDOM = (elementId, originalItems = []) => {
  const items = [...originalItems];
  const element = view.accessors.getLiveElement(elementId);
  const assetsUrl = site.accessors.getAssetsUrl();

  element.querySelectorAll(selectors.link).forEach((link) => {
    const config = linkEditing.accessors.getLinkConfig(link);
    const icon = link.querySelector(selectors.icon);
    const iconBaseSrc = icon && icon.getAttribute('src');
    const iconSrc = urlJoin(assetsUrl, iconBaseSrc);
    const iconSize = icon?.style?.width;
    const iconIdAttr = icon && icon.getAttribute('data-icon-id');

    const currentPosition = getCurrentItemOrder(items);

    if (!config.target) {
      config.target = linkEditing.constants.common.BLANK;
    }

    items.push({
      config,
      iconBaseSrc,
      iconSrc,
      iconIdAttr,
      id: generateRandomId(),
      order: currentPosition,
      size: iconSize || common.AUTO_SIZE,
    });
  });

  return items;
};

export default parseItemsFromDOM;
