import { anodum } from '@yola/ws-sdk';

const isNodeValid = (node) => {
  const { nodeType } = node;
  const { nodeTypes } = anodum;

  switch (nodeType) {
    case nodeTypes.ELEMENT_NODE: {
      return node.nodeName === 'META' || node.nodeName === 'WRAPPER';
    }
    case nodeTypes.TEXT_NODE: {
      const text = node.textContent.replace(/(\r\n|\n|\r)/gm, '').trim();
      return !text.length;
    }
    case nodeTypes.COMMENT_NODE: {
      return false;
    }
    default:
      return false;
  }
};

const validateGoogleWebmasterCode = (validationError) => (code) => {
  if (!code) return null;
  const { traverseNode } = anodum;
  const parsedCode = document.createElement('wrapper');
  parsedCode.innerHTML = code;
  const nodes = [];

  traverseNode(parsedCode, (lNode) => {
    nodes.push(lNode);
  });

  if (nodes.length < 2 || nodes.some((node) => !isNodeValid(node))) return validationError;

  return null;
};

export default validateGoogleWebmasterCode;
