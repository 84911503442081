import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { dialogs, i18next } from '@yola/ws-sdk';
import { designSystem } from '@yola/ws-ui';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';

const { EditorHeader } = designSystem;

function PageButtonContainer() {
  const dispatch = useDispatch();
  const captions = {
    name: i18next.t('Pages'),
  };

  const showPageManager = useCallback(() => {
    dispatch(dialogs.actions.show(dialogTypes.PAGE_MANAGER));
  }, [dispatch]);

  return <EditorHeader.PagesNavItem onClick={showPageManager} label={captions.name} />;
}

export default PageButtonContainer;
