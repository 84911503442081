import React from 'react';
import PropTypes from 'prop-types';
import { Modal, designSystem } from '@yola/ws-ui';

const { Box, Stack, ActionButton, Heading, Paragraph, IconButton } = designSystem;

const MODAL_WIDTH = 480;
const MODAL_HEIGHT = 402;

const AiAlertDialog = ({ captions, imageSrc, buttonProps, onSubmit, onClose }) => (
  <Modal
    className="ws-ai-alert-dialog"
    width={MODAL_WIDTH}
    height="auto"
    minHeight={MODAL_HEIGHT}
    overlay="visible"
    draggable={false}
    resizable={false}
    centered
  >
    {onClose && (
      <div className="ws-ai-alert-dialog__close-button">
        <IconButton
          glyph="close"
          iconPlacement="after"
          size="medium"
          onClick={onClose}
          transparent
        />
      </div>
    )}
    <Box
      paddingTop="spacing-m"
      paddingBottom="spacing-xl"
      paddingLeft="spacing-2-xl"
      paddingRight="spacing-2-xl"
    >
      <Box marginBottom="spacing-xs">
        <div className="ws-ai-alert-dialog__image">
          <img src={imageSrc} alt={captions.title} />
        </div>
      </Box>
      <Stack gap="spacing-2-xs">
        <Heading type="heading-3" align="center">
          {captions.title}
        </Heading>
        <Paragraph size="large" align="center">
          <span
            // eslint-disable-next-line yola/react/no-danger
            dangerouslySetInnerHTML={{ __html: captions.description }}
          />
        </Paragraph>
      </Stack>
      <div className="ws-ai-alert-dialog__submit-button">
        <Box paddingTop="spacing-m">
          <ActionButton
            size="large"
            label={captions.buttonLabel}
            onClick={onSubmit}
            {...buttonProps}
          />
        </Box>
      </div>
    </Box>
  </Modal>
);

AiAlertDialog.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string.isRequired,
  }).isRequired,
  imageSrc: PropTypes.string.isRequired,
  buttonProps: PropTypes.shape({
    iconGlyph: PropTypes.string,
    appearance: PropTypes.string,
    format: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf([null])]),
};

AiAlertDialog.defaultProps = {
  buttonProps: {},
  onClose: null,
};

export default AiAlertDialog;
