import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { i18next, dialogs, integration, customization } from '@yola/ws-sdk';
import getCurrentPackageFeatureLimits from 'yola-editor/src/js/modules/features/accessors/get-current-package-feature-limits';
import ColorSchemeSettingsDialog from '../components/color-scheme-settings-dialog';
import segment from '../../analytics/segment';
import useInjectPaletteToTemplate from '../hooks/use-inject-palette-to-template';
import getAdvancedColorsSections from '../helpers/get-advanced-colors-sections';
import getGeneralColorsSection from '../helpers/get-general-colors-section';
import getInitialGeneralColor from '../helpers/get-initial-general-color';
import setGeneralColor from '../helpers/set-general-color';
import resetAdvancedColor from '../helpers/reset-advanced-color';
import generatePalette from '../helpers/generate-palette';
import generateColorPresetLabel from '../helpers/generate-color-preset-label';
import resetColorSchemeColors from '../helpers/reset-color-scheme-colors';
import triggerIds from '../constant/trigger-ids';
import advancedVariableCategoryIds from '../constant/advanced-variable-category-ids';
import advancedVariableIds from '../constant/advanced-variable-ids';
import { colorToSurfaceMap } from '../constant/colors-maps';
import dialogTypes from '../../dialogs/constants/dialog-types';

const {
  trackers: { trackEvent, trackUpgradeTriggerClicked },
  constants: { events },
} = segment;

const getCaptions = () => ({
  title: i18next.t('Color scheme settings'),
  description: i18next.t(
    "Define the block's look with color schemes in Block settings. Changes apply to all blocks with this scheme."
  ),
  buttons: {
    cancel: i18next.t('Cancel'),
    submit: i18next.t('Submit'),
  },
  general: {
    header: i18next.t('General'),
    description: i18next.t(
      'Scheme core colors are below. Our design system generates all other colors automatically for your convenience'
    ),
  },
  notification: {
    title: i18next.t('Some magic is happening here.'),
    description: i18next.t(
      "All the colors you see are automatically generated to match your block's background"
    ),
  },
  resetSchemeDialog: {
    title: i18next.t('Confirm color reset'),
    description: i18next.t(
      "All your color adjustments will be reset to the default color scheme. This action can't be undone."
    ),
    submit: i18next.t('Confirm'),
    cancel: i18next.t('Cancel'),
  },
});

const ColorSchemeSettingsContainer = (props) => {
  const {
    getSharedData,
    colorType,
    colorKey,
    onDialogMainAction,
    onDialogCancel,
    resolveSharedData,
    scrollTop,
  } = props;
  const {
    offsetX,
    offsetY,
    editPalette,
    editColorSchemePalette,
    activeColorSections,
    isEdit,
    isDeveloperMode,
  } = getSharedData();
  const colorPalette = editColorSchemePalette || editPalette;

  const { advancedColorCustomization } = useSelector(integration.selectors.getUpsells);
  const parentPalette = useSelector((state) =>
    customization.selectors.getColorPalette(state, colorPalette.parentId)
  );
  const colorPaletteConfig = useSelector(customization.selectors.getColorPaletteConfig);

  const dispatch = useDispatch();
  const contentRef = useRef(null);

  const captions = getCaptions();
  const limits = getCurrentPackageFeatureLimits();
  const generalColorsSection = getGeneralColorsSection(colorKey, colorPalette);
  const advancedColorsSections = getAdvancedColorsSections(colorKey, colorPalette);

  const surface = colorToSurfaceMap[colorKey];
  const isAdvancedColorCustomizationAvailable =
    limits.advancedColorCustomization.available || isDeveloperMode;
  const hasAdvancedColors = Object.keys(colorPalette.advancedColors || {}).some((key) =>
    key.includes(surface)
  );
  const areGeneralColorsChanged = generalColorsSection.some(({ isColorChanged }) => isColorChanged);
  const isColorSchemeResetDisabled = !areGeneralColorsChanged && !hasAdvancedColors;
  const activeAccordionItems = activeColorSections || advancedColorsSections.map(({ id }) => id);
  captions.upgradeBanner = advancedColorCustomization.captions;

  const [dialogPosition, updatePosition] = useState({
    offsetX,
    offsetY,
  });
  const [isBannerAnimated, setIsBannerAnimated] = useState(true);

  const handleDragEnd = (_, { x, y }) => {
    updatePosition({
      offsetX: x,
      offsetY: y,
    });
  };

  const toggleBannerAnimation = () => {
    setIsBannerAnimated((bool) => !bool);
  };

  const injectPaletteToTemplate = useInjectPaletteToTemplate();

  const updateSharedData = (customData = {}) => {
    resolveSharedData({
      offsetX: dialogPosition.offsetX,
      offsetY: dialogPosition.offsetY,
      ...customData,
    });
  };

  const onToggleAccordionItem = (activeItems) => {
    updateSharedData({ activeColorSections: activeItems });
  };

  const onColorSelect = (selectedColor) => {
    updateSharedData();

    dispatch(
      dialogs.actions.show(dialogTypes.WEBSITE_DESIGN_COLOR_SETTINGS, {
        ...selectedColor,
        colorType,
        backgroundColorKey: colorKey,
        scrollTop: contentRef.current.scrollTop,
      })
    );
  };

  const showColorSchemeSettings = () =>
    dialogs.operations.show(dialogTypes.COLOR_SCHEME_SETTINGS_DIALOG, { colorType, colorKey });

  const onColorSchemeReset = () => {
    trackEvent(events.DESIGN_SETTINGS_RESET_TRIGGER_CLICKED, {
      dialogId: dialogTypes.COLOR_SCHEME_SETTINGS_DIALOG,
      triggerId: triggerIds.COLOR_SCHEME_SETTINGS,
      colorsAdjusted: [colorKey],
      variableId: advancedVariableIds.BACKGROUND_COLOR,
      variableCategory: advancedVariableCategoryIds.GENERAL,
    });

    dialogs.operations.show(dialogTypes.CONFIRM_DIALOG, {
      onCancel: showColorSchemeSettings,
      onSubmit: () => {
        const compiledPalette = generatePalette(
          resetColorSchemeColors({
            colorKey,
            colorPalette,
            parentPalette,
          }),
          colorPaletteConfig
        );

        updateSharedData({
          editColorSchemePalette: compiledPalette,
        });
        injectPaletteToTemplate(compiledPalette);
        showColorSchemeSettings();
      },
      captions: captions.resetSchemeDialog,
    });
  };

  const onColorReset = ({
    colorKey: targetColorKey,
    variableCategory,
    variableId,
    isAdvancedColor,
  }) => {
    let updatedPalette;

    if (isAdvancedColor) {
      updatedPalette = resetAdvancedColor({
        colorKey: targetColorKey,
        backgroundColorKey: colorKey,
        colorPalette,
      });
    } else {
      const initialColors = parentPalette ? parentPalette.colors : colorPalette.colors;
      const initialColor = getInitialGeneralColor(targetColorKey, initialColors);

      updatedPalette = setGeneralColor({
        colorKey: targetColorKey,
        color: initialColor,
        colorPalette,
      });
    }

    const compiledPalette = generatePalette(updatedPalette, colorPaletteConfig);
    injectPaletteToTemplate(compiledPalette);
    updateSharedData({
      editColorSchemePalette: compiledPalette,
    });

    trackEvent(events.DESIGN_SETTINGS_RESET_TRIGGER_CLICKED, {
      dialogId: dialogTypes.COLOR_SCHEME_SETTINGS_DIALOG,
      triggerId: triggerIds.ITEM_COLOR,
      colorsAdjusted: [targetColorKey],
      variableCategory,
      variableId,
    });
  };

  const onUpgrade = () => {
    trackUpgradeTriggerClicked(segment.constants.triggerIds.ADVANCED_COLOR_CUSTOMIZATION);

    advancedColorCustomization.onUpgrade().catch(() => {
      // eslint-disable-next-line no-console
      console.log('Upgrade flow was canceled');
    });
  };

  const onClose = () => {
    dispatch(
      dialogs.actions.show(dialogTypes.WEBSITE_DESIGN_CUSTOM_PALETTE, {
        triggerId: triggerIds.COLOR_SCHEME_SETTINGS,
        colorPreset: generateColorPresetLabel(isEdit, editPalette.id),
      })
    );
  };

  const handleCancel = () => {
    injectPaletteToTemplate(editPalette);
    updateSharedData({
      editPalette,
      editColorSchemePalette: null,
      activeColorSections: null,
      isDeveloperMode: false,
    });
    onDialogCancel();
    onClose();
  };

  const handleSubmit = () => {
    updateSharedData({
      editPalette: colorPalette,
      editColorSchemePalette: null,
      activeColorSections: null,
      isDeveloperMode: false,
    });
    onDialogMainAction({
      colorScheme: colorKey,
      colorAdjusted: areGeneralColorsChanged,
      generateSchemeAutomatically: !hasAdvancedColors,
    });
    onClose();
  };

  const onOverlayClick = () => {
    handleSubmit();
  };

  useEffect(() => {
    if (scrollTop && contentRef.current) {
      contentRef.current.scrollTo(0, scrollTop);
    }
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, [contentRef.current]);

  return (
    <ColorSchemeSettingsDialog
      offsetX={dialogPosition.offsetX}
      offsetY={dialogPosition.offsetY}
      captions={captions}
      generalColorsSection={generalColorsSection}
      advancedColorsSections={advancedColorsSections}
      isAdvancedColorCustomizationAvailable={isAdvancedColorCustomizationAvailable}
      showReset={Boolean(colorPalette.parentId)}
      isResetDisabled={isColorSchemeResetDisabled}
      activeAccordionItems={activeAccordionItems}
      onToggleAccordionItem={onToggleAccordionItem}
      isBannerAnimated={isBannerAnimated}
      toggleBannerAnimation={toggleBannerAnimation}
      onUpgrade={onUpgrade}
      onColorSelect={onColorSelect}
      onReset={onColorSchemeReset}
      onColorReset={onColorReset}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
      onDragEnd={handleDragEnd}
      onOverlayClick={onOverlayClick}
      contentRef={contentRef}
    />
  );
};

ColorSchemeSettingsContainer.propTypes = {
  colorType: PropTypes.string.isRequired,
  colorKey: PropTypes.string.isRequired,
  getSharedData: PropTypes.func.isRequired,
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
  resolveSharedData: PropTypes.func.isRequired,
  scrollTop: PropTypes.number,
};

ColorSchemeSettingsContainer.defaultProps = {
  scrollTop: null,
};

export default ColorSchemeSettingsContainer;
