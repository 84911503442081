import { dialogs } from '@yola/ws-sdk';
import dialogTypes from '../../dialogs/constants/dialog-types';
import tabIdentifiers from '../constants/tab-identifiers';

const showIntegrationTab = (modalProps = {}) => {
  dialogs.operations.show(dialogTypes.WEBSITE_SETTINGS, {
    activeTabId: tabIdentifiers.INTEGRATIONS,
    ...modalProps,
  });
};

export default showIntegrationTab;
