import React, { useContext, useCallback } from 'react';
import { utils } from '@yola/ws-sdk';
import PropTypes from 'prop-types';
import bowser from 'yola-bowser';
import BlockSettingsContext from '../contexts/block-settings-context';
import BlockSettingsDialog from '../components/block-settings-dialog';

const BlockSettingsDialogContainer = ({
  isAutomationInProgress,
  onModalReady,
  onKeyDown,
  onCancel,
  offsetX,
  offsetY,
  onDragEnd,
}) => {
  const blockSettingsContext = useContext(BlockSettingsContext);
  const { captions, onSubmit } = blockSettingsContext;

  const handleOverlayClick = useCallback(() => {
    if (bowser.mobile) return;
    onSubmit({ isClickOnOverlay: true });
  }, [onSubmit]);

  return (
    <BlockSettingsDialog
      captions={captions}
      offsetX={offsetX}
      offsetY={offsetY}
      isMobile={bowser.mobile}
      onModalReady={onModalReady}
      onKeyDown={onKeyDown}
      onCancel={onCancel}
      onSubmit={onSubmit}
      onOverlayClick={handleOverlayClick}
      onDragEnd={onDragEnd}
      isInProgress={isAutomationInProgress}
    />
  );
};

BlockSettingsDialogContainer.propTypes = {
  offsetX: PropTypes.number.isRequired,
  offsetY: PropTypes.number.isRequired,
  isAutomationInProgress: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  onModalReady: PropTypes.func,
  onKeyDown: PropTypes.func,
  onDragEnd: PropTypes.func,
};

BlockSettingsDialogContainer.defaultProps = {
  onModalReady: utils.noop,
  onKeyDown: utils.noop,
  onCancel: utils.noop,
  onDragEnd: utils.noop,
};

export default BlockSettingsDialogContainer;
