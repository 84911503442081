import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors, verifiers } from '@yola/subscription-manager-js';
import serviceClient from '../../yows-service-client/service-client';

const usePurchasedDomainsData = (withPurchasedDomains) => {
  const [isLoading, setLoadingStatus] = useState(false);
  const [domains, setDomains] = useState([]);

  const activeSubscriptionsList = useSelector(selectors.getActiveSubscriptions);
  const service = serviceClient.get();

  useEffect(() => {
    const getPurchasedDomains = () => {
      if (!withPurchasedDomains) {
        return;
      }
      setLoadingStatus(true);
      service
        .fullSitesList()
        .then(({ data }) => {
          const purchasedDomains = activeSubscriptionsList
            .filter(({ type }) => verifiers.isDomainPackageType(type))
            .map(({ properties }) => {
              const { domain_name: domain } = properties;

              return {
                domain,
                available: !data.some(({ vhosts }) =>
                  vhosts.some((connectedDomain) => connectedDomain === domain)
                ),
              };
            });
          setDomains(purchasedDomains);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoadingStatus(false);
        });
    };

    getPurchasedDomains();
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    domains,
  };
};

export default usePurchasedDomainsData;
