import { integration } from '@yola/ws-sdk';
import wlMediaSources from '../constants/wl-media-sources';
import mediaSources from '../constants/media-sources';
import viewConfigurations from '../constants/view-configurations';
import getCaptions from './get-captions';

const getItems = (tour) => {
  if (!tour || !viewConfigurations[tour]) return [];

  const captions = getCaptions(tour);
  const userData = integration.accessors.getUserData();
  const isWL = userData && userData.is_white_label;
  const media = isWL ? wlMediaSources[tour] : mediaSources[tour];
  return viewConfigurations[tour].map((item, index) => ({
    ...item,
    captions: captions && captions[index],
    src: media && media[index],
  }));
};

export default getItems;
