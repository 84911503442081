import { interrupter, api } from '@yola/ws-sdk';
import actionTypes from '../constants/action-types';

const interruptPublishWebsite = (eventData) => {
  if (eventData.action === 'ws:website_publish') {
    return {
      siteName: api.site.accessors.getSiteName(),
    };
  }
  return null;
};

const registerInterruptionPoints = () =>
  interrupter.actions.registerInterruptionPoint(
    actionTypes.PUBLISH_WEBSITE,
    interruptPublishWebsite
  );

export default registerInterruptionPoints;
