import { textFormatting } from '@yola/ws-sdk';
import highlighter from 'src/js/modules/highlighter';
import trackBlockSettingsDialogCanceled from '../trackers/track-block-settings-dialog-canceled';
import blockSettingsHelpers from './block-settings';
import getChangedBackgroundOptions from './get-changed-background-options';

const { getChanges, hasTouchedGlobals, trackWsBlockContent } = blockSettingsHelpers;

const cancelBlockSettingsChanges = ({
  destinationBlockNode,
  isOnlineStoreBlock,
  usedCustomColorOptions,
  blockId,
  hideDialog,
  forceReloadView,
  onDialogCancel,
  optionalChildren,
  blockNode,
  colorSchemes,
  displayOptions,
  backgroundOptions,
  variationId,
}) => {
  trackBlockSettingsDialogCanceled({
    onDialogCancel,
    blockId,
    blockVariationId: variationId,
  });

  if (hasTouchedGlobals(optionalChildren) || destinationBlockNode || isOnlineStoreBlock) {
    highlighter.operations.hide([blockNode]);
    forceReloadView();
    hideDialog();
    return;
  }

  const [changedChildren, changesList] = getChanges({
    optionalChildren,
    colorSchemes,
    displayOptions,
  });

  if (changedChildren.length) {
    trackWsBlockContent(blockNode);
  }

  if (usedCustomColorOptions) {
    textFormatting.helpers.reloadCustomColorStylesheet();
  }

  const changedBackgroundOptions = getChangedBackgroundOptions(backgroundOptions);
  const areBackgroundOptionsChanged =
    changedBackgroundOptions && Object.values(changedBackgroundOptions).every(Boolean);

  if (changedChildren.length || changesList.length || areBackgroundOptionsChanged) {
    forceReloadView();
  }

  hideDialog();
};

export default cancelBlockSettingsChanges;
