import { useState, useEffect } from 'react';
import getPlatforms from '../helpers/get-platforms';
import getDefaultPlatformOption from '../helpers/get-default-platform-options';
import useMounted from '../../../../utils/custom-hooks/use-mounted';

const usePlatformOptions = ({ setOnReloadClick, shouldLoad }) => {
  const defaultPlatformOption = getDefaultPlatformOption();
  const [platformOptions, setPlatformOptions] = useState([defaultPlatformOption]);
  const mountedRef = useMounted();

  const loadPlatformsOptions = async (shouldLoadPlatforms) => {
    setOnReloadClick(null);
    let platforms = [];

    if (shouldLoadPlatforms) {
      const onRequestFail = () => {
        setOnReloadClick(loadPlatformsOptions);
      };

      platforms = await getPlatforms({ onRequestFail });

      if (!mountedRef.current) return;
    }

    setPlatformOptions([...platformOptions, ...platforms]);
  };

  useEffect(() => {
    loadPlatformsOptions(shouldLoad);
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, [shouldLoad]);

  return platformOptions;
};

export default usePlatformOptions;
