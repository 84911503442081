function trackPromiseState(callback, defaultData = []) {
  const state = {
    isLoaded: false,
    data: defaultData,
    promise: callback().then((data) => {
      state.data = data;
      state.isLoaded = true;

      return data;
    }),
    onLoad(onLoadCallback) {
      if (state.isLoaded) {
        Promise.resolve(state.data).then(onLoadCallback);
      } else {
        state.promise.then(onLoadCallback);
      }
    },
  };

  return state;
}

export default trackPromiseState;
