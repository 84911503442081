import { site, template } from '@yola/ws-sdk';
import segment from 'src/js/modules/analytics/segment';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';

const trackBlockSettingsDialogDisplayed = ({ tabId, blockId, blockVariationId, triggerId }) => {
  if (!tabId || !blockId) return;

  const { track, constants } = segment;

  track(constants.events.BLOCK_SETTINGS_DIALOG_DISPLAYED, {
    siteId: site.accessors.getSiteId(),
    templateBuildSlug: template.accessors.getBuildSlug(),
    dialogId: dialogTypes.BLOCK_SETTINGS,
    tabId,
    blockId,
    blockVariationId,
    triggerId,
  });
};

export default trackBlockSettingsDialogDisplayed;
