import React from 'react';
import { extensions, i18next } from '@yola/ws-sdk';
import focalPoint from 'src/js/modules/focal-point';
import config from '../constants/config';
import ReplaceImageTrigger from '../containers/triggers/replace-image-trigger-container';

const { hasBackgroundContainer } = focalPoint.helpers;

const triggerId = 'image';

const replaceLogoControl = {
  id: triggerId,
  trigger: <ReplaceImageTrigger id={triggerId} />,
  get title() {
    return i18next.t('Replace image');
  },
  get tooltip() {
    return i18next.t('Replace image');
  },
  priority: 100,
  closeIcon: 'submit',
  matches(liveElement) {
    const settings = extensions.accessors.getExtensionSettings(config.slug);

    return liveElement.matches(settings.querySelector) && !hasBackgroundContainer(liveElement);
  },
};

export default replaceLogoControl;
