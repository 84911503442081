import actionTypes from '../constants/action-types';

const orderDomain = (domainName, domainContact) => ({
  type: actionTypes.ORDER_DOMAIN,
  payload: {
    domainName,
    domainContact,
  },
});

export default orderDomain;
