export default {
  CONTROLS_MARGIN_TOP: 8,
  CONTROLS_REQUIRED_SPACE: 56,
  DRAGGABLE_BORDER_WIDTH: 3,
  MINIMUM_IMAGE_LOADING_TIME: 800,
  DEFAULT_SCALE: 1,
  DEFAULT_ZOOM: 0,
  DEFAULT_POSITION_STRING_VALUE: '50% 50%',
  ORIENTATION_CHANGE_DELAY: 350,
  IMAGE_ACCEPT_TYPE: '.jpg, .png, .gif, .jpeg, .svg',
  IMAGE_TYPE_GIF: 'image/gif',
  IMAGE_TYPE_SVG: 'image/svg+xml',
  INLINE_IMAGE: 'inline-image',
  BACKGROUND_IMAGE: 'background-image',
  FILE_UPLOAD: 'file-upload',
  STOCK_PHOTOS: 'stock-photos',
  SUBMIT_TRIGGER: 'submit-trigger',
  FOCAL_POINT_AREA_TRIGGER: 'focalpoint-area',
  FOCAL_POINTS_SLIDER_WIDTH: 196,
  FOCAL_POINTS_IMAGE_CONTROLS_WIDTH: 317,
  FOCAL_POINT_CONTROL_TRIGGER: 'focal-point',
  MAX_SCALE: 3,
  MIN_SCALE: 1,
  SCALE_STEP: 0.02,
  MAX_ZOOM: 200, // increase image size on MAX_ZOOM%
  MIN_ZOOM: -200,
  ZOOM_COEF: 90 / 200, // a / b, where a - minimum value the image size can be decreased on (in %), b - MIN_ZOOM
  ZOOM_STEP: 10,
  BODY_DRAGGABLE_CLASS: 'react-draggable-transparent-selection',
  NON_PROGRESSIVE_IMAGE_TYPES: ['.gif', '.svg', '.img'],
  IMAGE_PROGRESSIVE_ATTRIBUTE: 'progressive-image',
  RESIZE_EVENT: 'ws:resize',
  ADJUST_POSITION_EVENT: 'ws:adjust-image-position',
  OLD_LOGIC_ATTRIBUTE: 'cover-constraints',
  BACKGROUND_IMAGE_ATTRIBUTE: 'background-image',
  BACKGROUND_POSITION_ATTRIBUTE: 'background-position',
  BACKGROUND_SCALE_ATTRIBUTE: 'background-scale',
};
