import React from 'react';
import PropTypes from 'prop-types';
import { i18next } from '@yola/ws-sdk';
import { Modal, Text, designSystem } from '@yola/ws-ui';

const { DialogButtonGroup, DialogButton, DialogButtonGroupSeparator } = designSystem;

const RedirectDeleteDialog = (props) => {
  const { redirectTitle, redirectIndex, onSubmit, onCancel } = props;

  const captions = {
    title: i18next.t('Delete redirect?'),
    submit: i18next.t('Submit'),
    cancel: i18next.t('Cancel'),
  };

  const handleSubmit = () => {
    onSubmit(redirectIndex);
  };

  return (
    <Modal
      overlay="visible"
      centered
      resizable={false}
      draggable={false}
      width={334}
      height="auto"
      minWidth={312}
      minHeight={202}
      className="ws-redirect-delete-dialog"
      handleSubmit={handleSubmit}
      handleCancel={onCancel}
    >
      <div className="ws-redirect-delete-dialog__content">
        <Text type="heading-5" className="ws-redirect-delete-dialog__title">
          {captions.title}
        </Text>
        <Text theme="grey">
          {i18next.t('Are you sure you want to delete the "{redirectTitle}" redirect?', {
            redirectTitle,
          })}
        </Text>
      </div>
      <div className="ws-redirect-delete-dialog__button-group">
        <DialogButtonGroup>
          <DialogButton onClick={handleSubmit}>{captions.submit}</DialogButton>
          <DialogButtonGroupSeparator />
          <DialogButton onClick={onCancel}>{captions.cancel}</DialogButton>
        </DialogButtonGroup>
      </div>
    </Modal>
  );
};

RedirectDeleteDialog.propTypes = {
  redirectTitle: PropTypes.string.isRequired,
  redirectIndex: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default RedirectDeleteDialog;
