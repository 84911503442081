import { dialogs, integration } from '@yola/ws-sdk';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import user from 'yola-editor/src/js/modules/user';
import tabIdentifiers from '../../website-settings/constants/tab-identifiers';

const addLanguageInterrupter = (store) => (next) => (action) => {
  if (action.type === dialogs.actionTypes.SHOW_DIALOG) {
    const { type } = action.payload;
    if (type === dialogTypes.ADD_LANGUAGE) {
      const state = store.getState();
      const limits = integration.selectors.getLimits(state);
      const upsells = integration.selectors.getUpsells(state);
      const { locales, formData, showPromptForce } = action.payload.modalProps;
      const currentLanguagesAmount = locales.length;

      if (currentLanguagesAmount >= limits.multilingualLimit.amount && upsells.multilingual) {
        const { captions, premiumFeature, onUpgrade, purchaseFlowType } = upsells.multilingual;
        const availablePlatformComponents = user.selectors.getAvailablePlatformComponents(state);
        const onUpgradeResolve = () => next(action);
        const onUpgradeReject = () => {
          dialogs.operations.show(dialogTypes.WEBSITE_SETTINGS, {
            activeTabId: tabIdentifiers.MULTILINGUAL,
            formData,
            showPromptForce,
          });
        };

        // b2c & reseller platform upgrade
        if (availablePlatformComponents.includes(user.platformComponents.PAYMENTS)) {
          onUpgrade().then(onUpgradeResolve, onUpgradeReject);
          return;
        }

        store.dispatch(
          dialogs.actions.show(dialogTypes.UPGRADE, {
            purchaseFlowType,
            premiumFeature,
            captions,
            onUpgrade,
            onUpgradeResolve,
            onUpgradeReject,
          })
        );
        return;
      }
    }
  }
  next(action);
};

export default addLanguageInterrupter;
