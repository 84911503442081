import React from 'react';

function MediaPlaceholder() {
  return (
    <div className="ws-media-placeholder">
      <svg
        className="ws-media-placeholder__icon"
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M33.33 8H20.8c-4.48 0-6.72 0-8.43.87a8 8 0 0 0-3.5 3.5C8 14.07 8 16.32 8 20.8v22.4c0 4.48 0 6.72.87 8.43a8 8 0 0 0 3.5 3.5c1.7.87 3.95.87 8.43.87h24.53c2.48 0 3.72 0 4.74-.27a8 8 0 0 0 5.66-5.66c.27-1.02.27-2.26.27-4.74m-5.33-24v-16m-8 8h16M28 22.67a5.33 5.33 0 1 1-10.67 0 5.33 5.33 0 0 1 10.67 0Zm11.97 9.11-22.55 20.5c-1.27 1.16-1.9 1.74-1.96 2.24-.05.43.11.86.44 1.15.38.33 1.24.33 2.95.33h25.03c3.84 0 5.76 0 7.27-.64a8 8 0 0 0 4.2-4.21c.65-1.51.65-3.43.65-7.27 0-1.29 0-1.93-.14-2.54a5.33 5.33 0 0 0-1-2.07c-.38-.49-.88-.89-1.9-1.7l-7.45-5.96c-1.01-.81-1.51-1.21-2.07-1.36-.49-.12-1-.1-1.49.05-.54.18-1.02.61-1.98 1.48Z"
          stroke="#fff"
          strokeWidth="2.4"
          strokeLinecap="round"
          strokeLinejoin="round"
          opacity=".6"
        />
      </svg>
    </div>
  );
}

export default MediaPlaceholder;
