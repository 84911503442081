import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@yola/ws-ui';
import { i18next } from '@yola/ws-sdk';
import CurrentLayoutThumbnail from './current-layout-thumbnail';

const SwitchLayoutBlock = ({ displayBlockLayout }) => {
  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <div>
      {isMounted && <CurrentLayoutThumbnail />}
      <Button size="medium" onClick={displayBlockLayout}>
        {i18next.t('Switch block layout')}
      </Button>
    </div>
  );
};

SwitchLayoutBlock.propTypes = {
  displayBlockLayout: PropTypes.func.isRequired,
};
export default SwitchLayoutBlock;
