import calculateDaysDifference from './calculate-days-difference';
import constants from '../constants';

const { SURVEY_POSTPONED_PERIOD_DAYS } = constants;

const isPMFAvailable = (signupDate, currentDate, pmfSurveyUpdatedAt, pmfSurveyCompleted) => {
  const fromSignUpToCurrentDays = calculateDaysDifference(signupDate, currentDate);
  const fromPostponedToCurrent = calculateDaysDifference(pmfSurveyUpdatedAt, currentDate);

  if (pmfSurveyCompleted) {
    return false;
  }

  if (!pmfSurveyUpdatedAt && fromSignUpToCurrentDays >= SURVEY_POSTPONED_PERIOD_DAYS) {
    return true;
  }

  if (pmfSurveyUpdatedAt && fromPostponedToCurrent >= SURVEY_POSTPONED_PERIOD_DAYS) {
    return true;
  }

  return false;
};

export default isPMFAvailable;
