import { globals, view } from '@yola/ws-sdk';
import highlighterModule from '../../highlighter';
import { NAVIGATION_GLOBAL_ID } from '../constants/common';

const highlighter = () => (next) => (action) => {
  switch (action.type) {
    case highlighterModule.actionTypes.SHOW: {
      const [element] = action.payload.elements;
      const globalElement = element.closest(globals.constants.GLOBAL_TAG_NAME);

      if (!globalElement) {
        next(action);
        break;
      }

      const globalElementId = view.accessors.getLiveElementId(globalElement);

      if (
        !globalElementId ||
        !globals.verifiers.isElementGlobal(globalElementId, NAVIGATION_GLOBAL_ID)
      ) {
        next(action);
        break;
      }

      next({
        ...action,
        payload: {
          ...action.payload,
          options: {
            ...action.payload.options,
            labelIcon: globals.verifiers.isElementDetached(globalElement)
              ? 'detach-global'
              : 'make-global',
          },
        },
      });
      break;
    }
    default:
      next(action);
  }
};

export default highlighter;
