import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import classNames from 'classnames';

const { Autocomplete, BetaLabel, Paragraph, Loader, ActionButton } = designSystem;

const SELECT_MENU_WIDTH = 'min(330px, 100%)';
const SELECT_MENU_MAX_HEIGHT = 306;

const AiTextAssistant = ({
  captions,
  autocompleteOptions,
  isMobile,
  isLoading,
  isRetryAvailable,
  userInputId,
  userInput,
  actionButtonProps,
  filterAutocompleteOptions,
  onChange,
  onInputChange,
  onKeyDown,
  onCancel,
}) => {
  const uiClassNames = classNames('ws-ai-text-assistant', {
    'ws-ai-text-assistant--mobile': isMobile,
  });

  return (
    <div className={uiClassNames}>
      {isLoading ? (
        <div className="ws-ai-text-assistant__loader">
          <BetaLabel />
          <Paragraph>{captions.loading}</Paragraph>
          <Loader />
          <div className="ws-ai-text-assistant__close-btn">
            <ActionButton format="solid-white" size="small" iconGlyph="close" onClick={onCancel} />
          </div>
        </div>
      ) : (
        <Fragment>
          <div className="ws-ai-text-assistant__select">
            <Autocomplete
              id={userInputId}
              defaultInputValue={userInput}
              options={autocompleteOptions}
              placeholder={captions.placeholder}
              size="medium"
              filterOptionsFunction={filterAutocompleteOptions}
              iconComponent={<BetaLabel />}
              defaultMenuIsOpen={!isRetryAvailable}
              autoFocus
              transparent
              clearable={false}
              menuWidth={SELECT_MENU_WIDTH}
              {...(!isMobile && { maxMenuHeight: SELECT_MENU_MAX_HEIGHT })}
              onChange={onChange}
              onInputChange={onInputChange}
              onKeyDown={onKeyDown}
            />
          </div>
          {userInput.trim() && (
            <ActionButton format="solid-white" size="small" {...actionButtonProps} />
          )}
        </Fragment>
      )}
    </div>
  );
};

AiTextAssistant.propTypes = {
  captions: PropTypes.shape({
    placeholder: PropTypes.string.isRequired,
    loading: PropTypes.string.isRequired,
    cancel: PropTypes.string.isRequired,
  }).isRequired,
  autocompleteOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.shape(),
    })
  ).isRequired,
  isMobile: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isRetryAvailable: PropTypes.bool.isRequired,
  userInputId: PropTypes.string.isRequired,
  userInput: PropTypes.string.isRequired,
  actionButtonProps: PropTypes.shape({
    iconGlyph: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }).isRequired,
  filterAutocompleteOptions: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default AiTextAssistant;
