import attributeValuesList from 'src/js/modules/utils/attribute-values-list';
import optionTypes from '../constants/display-options-type';

const convertColorSchemeOptionsToOperations = (element, changedOptions) => {
  const colorSchemeOptions = changedOptions.filter(
    (option) => option.type === optionTypes.COLOR_SCHEME
  );

  if (!colorSchemeOptions.length) {
    return [];
  }

  const optionToRemove = colorSchemeOptions.find(({ isEnabled }) => !isEnabled);
  const optionToAdd = colorSchemeOptions.find(({ isEnabled }) => isEnabled);

  const toRemove = { name: optionToRemove.attribute, value: optionToRemove.value };
  const toAdd = { name: optionToAdd.attribute, value: optionToAdd.value };

  return attributeValuesList
    .getChangeOperations(element, toRemove, toAdd)
    .map((operation) => ({ ...operation, elementId: optionToAdd.elementId }));
};

export default convertColorSchemeOptionsToOperations;
