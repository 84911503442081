export default {
  BUSINESS_LOGO: 'businessLogo',
  SITE_NAME: 'siteName',
  FAVICON: 'favicon',
  HEAD_CODE: 'headCode',
  HEADER_CODE: 'headerCode',
  FOOTER_CODE: 'footerCode',
  BUSINESS_NAME: 'businessName',
  BUSINESS_CATEGORY: 'businessCategory',
  BUSINESS_DESCRIPTION: 'businessDescription',
  PHONES: 'phones',
  ADDRESSES: 'addresses',
  EMAILS: 'emails',
  BUSINESS_HOURS: 'businessHours',
  IS_COOKIE_BANNER_ENABLED: 'isCookieBannerEnabled',
  PREVENT_INDEXING: 'preventIndexing',
};
