import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { i18next } from '@yola/ws-sdk';
import dialogs from 'yola-editor/src/js/modules/dialogs/index';
import ActionDialog from '../components/action-dialog';
import { redirectToUrl } from '../../../router/utils/redirect-to-url';

function SiteResetUnpublishDialogContainer({ redirectUrl, onDialogMainAction, onDialogCancel }) {
  const dispatch = useDispatch();
  const captions = {
    title: i18next.t('Unpublish site?'),
    description: i18next.t(
      'You are going to reset your site. Do you want to unpublish it as well, so your visitors would not be able to see the old version?'
    ),
    submit: i18next.t('Yes'),
    cancel: i18next.t('No'),
  };

  function handleSubmit() {
    onDialogMainAction();
    dispatch(dialogs.actions.show(dialogs.dialogTypes.UNPUBLISH_CONFIRM_ACTION, { redirectUrl }));
  }

  function handleCancel() {
    onDialogCancel();
    if (redirectUrl) {
      redirectToUrl(redirectUrl);
    }
    dispatch(dialogs.actions.hide());
  }
  return <ActionDialog onCancel={handleCancel} onSubmit={handleSubmit} captions={captions} />;
}

SiteResetUnpublishDialogContainer.propTypes = {
  redirectUrl: PropTypes.string,
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

SiteResetUnpublishDialogContainer.defaultProps = {
  redirectUrl: null,
};

export default SiteResetUnpublishDialogContainer;
