import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { view, site, dialogs, i18next, clipboard, blocks, hdrm, template } from '@yola/ws-sdk';
import { AddContentTrigger } from '@yola/ws-ui';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import contextMenu from 'src/js/modules/context-menu';
import editor from 'src/js/modules/editor';
import navbar from 'src/js/modules/navbar';
import track from 'src/js/modules/analytics/segment/track';
import constants from 'src/js/modules/analytics/segment/constants';
import commonConstants from 'src/js/modules/common/constants';
import getEmptyPageTargetElementAndPosition from '../helpers/get-empty-page-target-element-and-position';
import { COPY_BLOCK, CUT_BLOCK, PASTE_BLOCK } from '../constants/context-menu-block-items';
import { CONTEXT_MENU_EMPTY_PAGE_GROUP_NAME } from '../constants/common';

const { RIGHT_CLICK_TRIGGER_ID } = commonConstants.common;
const { updateStrategies } = hdrm.constants;

const restrictedBlocksToPastSelector = 'ws-block[id*="header"], ws-block[id*="footer"]';

const trackBlockPasted = () => {
  const { element } = clipboard.accessors.getClipboardData();
  if (!element) return;

  const targetBlockId = blocks.accessors.getBlockIdByElement(element);
  const targetBlockVariationId = blocks.accessors.getVariationIdByElement(element);

  track(constants.events.BLOCK_PASTED, {
    siteId: site.accessors.getSiteId(),
    templateBuildSlug: template.accessors.getBuildSlug(),
    targetBlockId,
    targetBlockVariationId,
  });
};

const getContextMenuItems = (targetElement, adjacentPosition) => ({
  groupName: CONTEXT_MENU_EMPTY_PAGE_GROUP_NAME,
  element: targetElement,
  onMouseEnter: (e) => e.preventDefaultHandler(),
  onBeforeItemClick: (e) => {
    e.preventDefaultHandler();

    track(constants.events.CONTEXT_MENU_EMPTY_PAGE_CLICKED, {
      siteId: site.accessors.getSiteId(),
      templateBuildSlug: template.accessors.getBuildSlug(),
    });
  },
  items: [
    {
      id: COPY_BLOCK,
      caption: i18next.t('Copy block'),
      disabled: () => true,
    },
    {
      id: CUT_BLOCK,
      caption: i18next.t('Cut block'),
      disabled: () => true,
    },
    {
      id: PASTE_BLOCK,
      caption: i18next.t('Paste block'),
      disabled: () => {
        const clipboardData = clipboard.accessors.getClipboardData();
        return (
          !clipboard.verifiers.hasClipboardElementData() ||
          clipboardData.element.matches(restrictedBlocksToPastSelector)
        );
      },
      onClick() {
        const targetElementId = view.accessors.getLiveElementId(targetElement);
        const options = { strategy: updateStrategies.UPDATE_STATIC_ONLY };
        view.operations.pasteElement(targetElementId, adjacentPosition, options);
        trackBlockPasted();

        contextMenu.operations.hideContextMenu();
      },
    },
  ],
});

function EmptyPageTriggerContainer() {
  const dispatch = useDispatch();
  const [isEmpty, setIsEmpty] = useState(false);

  const emptyPageIgnoredBlocks = useSelector(template.selectors.getEmptyPageIgnoredBlocks);
  const isLoaded = useSelector(editor.selectors.getLoadedStatus);
  const usedBlocks = useSelector((state) => {
    const activePageId = site.selectors.getActivePageId(state);

    return site.selectors.getUsedBlocks(state, activePageId);
  });
  const navbarHeight = useSelector(navbar.selectors.getHeight);

  useEffect(() => {
    if (!isLoaded) return;

    if (usedBlocks.length) {
      setIsEmpty(!usedBlocks.some((blockId) => !emptyPageIgnoredBlocks.includes(blockId)));
    } else {
      setIsEmpty(view.verifiers.isEmpty());
    }
    // `usedBlocks` and `emptyPageIgnoredBlocks` props trigger hook with
    // old `isLoaded` prop state which leads to the early check of blocks
    // on page and as result to early `AddContentTrigger` hiding.
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, [isLoaded]);

  if (!isEmpty) return null;

  const { targetElement, adjacentPosition } = getEmptyPageTargetElementAndPosition();
  const targetElementId = view.accessors.getLiveElementId(targetElement);
  const onContextMenu = (offsetX, offsetY) => {
    const isMpt = template.verifiers.isMpt();

    if (!isMpt) return;
    const position = { x: offsetX, y: offsetY - navbarHeight };
    const groups = [getContextMenuItems(targetElement, adjacentPosition)];
    contextMenu.operations.showContextMenu({ position, groups, triggerId: RIGHT_CLICK_TRIGGER_ID });

    track(constants.events.CONTEXT_MENU_EMPTY_PAGE_DISPLAYED, {
      siteId: site.accessors.getSiteId(),
      templateBuildSlug: template.accessors.getBuildSlug(),
    });
  };

  const onClick = () => {
    dispatch(
      dialogs.actions.show(dialogTypes.ADD_BLOCK, {
        targetElementId,
        adjacentPosition,
      })
    );
  };

  return (
    <AddContentTrigger
      onClick={onClick}
      onContextMenu={onContextMenu}
      centered
      captions={{
        title: i18next.t('Start building your page'),
        annotation: i18next.t('Add a block'),
      }}
    />
  );
}

export default EmptyPageTriggerContainer;
