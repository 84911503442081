import React from 'react';
import PropTypes from 'prop-types';
import { Modal, InputField, CheckboxField, Formik, Field, designSystem } from '@yola/ws-ui';
import { integration, utils, i18next, template } from '@yola/ws-sdk';
import { useSelector } from 'react-redux';
import SimpleSelectField from 'src/js/modules/common/components/simple-select-field';
import fieldNames from '../constants/field-names';

const {
  ControlGroup,
  DialogHeader,
  DialogHeaderTitle,
  DialogButtonGroup,
  DialogButton,
  DialogButtonGroupSeparator,
  Paragraph,
  Heading,
  Stack,
  Box,
} = designSystem;

function NewPageDialog(props) {
  const {
    width,
    height,
    offsetX,
    offsetY,
    captions: { title, description, textInputLabel, selectLabel, checkboxLabel, submit, cancel },
    format,
    onCancel,
    onSubmit,
    titleValidation,
    pageTemplate,
    pageTitle,
    isCheckboxAvailable,
    isCheckboxChecked,
    options,
    isLoading,
  } = props;

  const limits = useSelector(integration.selectors.getLimits);
  const isShopPageTemplateAvailable = limits.shopPageTemplateLimit.available;

  const pageTemplateOptions = isShopPageTemplateAvailable
    ? options
    : options.filter((option) => option.value !== template.constants.SHOP_PAGE_TEMPLATE);
  const initialPageTemplate =
    (pageTemplate && pageTemplateOptions.find(({ value }) => value === pageTemplate)) ||
    pageTemplateOptions[0];

  const initialValues = {
    [fieldNames.PAGE_NAME_INPUT]: pageTitle,
    [fieldNames.PAGE_TEMPLATE_SELECT]: initialPageTemplate.value,
    ...(isCheckboxAvailable && { [fieldNames.DISPLAY_IN_NAVIGATION_CHECKBOX]: isCheckboxChecked }),
  };

  const requireValidation = (value) => {
    if (!value) {
      return i18next.t('This field is required');
    }
    return '';
  };

  const renderSimpleSelect = (formProps) => (
    <SimpleSelectField
      {...formProps}
      defaultValue={initialPageTemplate}
      options={pageTemplateOptions}
      placeholder={i18next.t('Select...')}
    />
  );

  return (
    <Modal
      overlay="visible"
      centered
      resizable={false}
      draggable={false}
      width={width}
      height={height}
      minWidth={312}
      minHeight={220}
      offsetX={offsetX}
      offsetY={offsetY}
      handleCancel={onCancel}
      handleSubmit={onSubmit}
    >
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <div className="ws-add-page__modal">
            <div className="ws-add-page__modal-header">
              <DialogHeader>
                <DialogHeaderTitle>{title}</DialogHeaderTitle>
              </DialogHeader>
            </div>
            <Box padding="spacing-m">
              <Stack gap="spacing-m">
                {pageTemplate && (
                  <ControlGroup title={selectLabel}>
                    <Field
                      render={renderSimpleSelect}
                      name={fieldNames.PAGE_TEMPLATE_SELECT}
                      validate={requireValidation}
                    />
                  </ControlGroup>
                )}
                <Stack gap="spacing-3-xs">
                  <ControlGroup title={textInputLabel}>
                    <Field
                      name={fieldNames.PAGE_NAME_INPUT}
                      format={format}
                      component={InputField}
                      validate={titleValidation}
                    />
                  </ControlGroup>
                  <Paragraph appearance="medium-emphasis" size="small">
                    {description}
                  </Paragraph>

                  {isCheckboxAvailable && (
                    <Field
                      component={CheckboxField}
                      id={fieldNames.DISPLAY_IN_NAVIGATION_CHECKBOX}
                      name={fieldNames.DISPLAY_IN_NAVIGATION_CHECKBOX}
                      checked={isCheckboxChecked}
                    >
                      {/* eslint-disable-next-line yola/jsx-a11y/label-has-for */}
                      <label htmlFor={fieldNames.DISPLAY_IN_NAVIGATION_CHECKBOX}>
                        <Heading type="heading-6">{checkboxLabel}</Heading>
                      </label>
                    </Field>
                  )}
                </Stack>
              </Stack>
            </Box>
            <DialogButtonGroup>
              <DialogButton onClick={handleSubmit} isLoading={isLoading}>
                {submit}
              </DialogButton>
              <DialogButtonGroupSeparator />
              <DialogButton onClick={onCancel}>{cancel}</DialogButton>
            </DialogButtonGroup>
          </div>
        )}
      </Formik>
    </Modal>
  );
}

NewPageDialog.propTypes = {
  width: PropTypes.number,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    textInputLabel: PropTypes.string.isRequired,
    selectLabel: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    checkboxLabel: PropTypes.string.isRequired,
    submit: PropTypes.string.isRequired,
    cancel: PropTypes.string.isRequired,
  }).isRequired,
  titleValidation: PropTypes.func,
  format: PropTypes.func,
  pageTitle: PropTypes.string,
  pageTemplate: PropTypes.string,
  isCheckboxAvailable: PropTypes.bool,
  isCheckboxChecked: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoading: PropTypes.bool,
};

NewPageDialog.defaultProps = {
  width: 360,
  height: 244,
  offsetX: 0,
  offsetY: 0,
  titleValidation: utils.noop,
  format: (value) => value,
  pageTitle: '',
  pageTemplate: null,
  isCheckboxAvailable: true,
  isCheckboxChecked: true,
  onCancel: utils.noop,
  onSubmit: utils.noop,
  isLoading: false,
};

export default NewPageDialog;
