import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { i18next } from '@yola/ws-sdk';
import { utils } from '@yola/webapp-plugins';
import { constants, selectors, verifiers } from '@yola/subscription-manager-js';
import { redirectToUrl } from 'yola-editor/src/js/router/utils/redirect-to-url';
import dialogs from 'yola-editor/src/js/modules/dialogs';
import user from 'yola-editor/src/js/modules/user';
import segment from 'src/js/modules/analytics/segment';
import SubscriptionExpiredDialog from '../components/subscription-expired-dialog';

const {
  SubscriptionType: { B2C_HOSTING_FREE, WL_HOSTING_FREE },
  SubscriptionStatus: { EXPIRED },
} = constants;
const { platformComponents } = user.constants;
const {
  constants: { events, triggerIds },
  track,
  trackAsync,
} = segment;
const CUSTOM_CARE_LIMITED = 'limited';

const getCaptions = () => ({
  title: i18next.t('Subscription expired'),
  cta: i18next.t('{cta} to keep your site up and running', {
    cta: `<b>${i18next.t('Renew now')}</b>`,
  }),
  ecommerceCta: i18next.t('{cta} and don’t miss out on potential revenue!', {
    cta: `<b>${i18next.t('Renew now')}</b>`,
  }),
  buttonLabel: i18next.t('Renew'),
  goOffline: i18next.t('Your website will go offline'),
  domainWillBeDisconnected: i18next.t('Custom domain will be disconnected'),
  noPremiumSupport: i18next.t('You will have no access to Premium support'),
  loseOnlineStore: i18next.t('You will lose access to Online store:'),
  ecommerceDescription: i18next.t(
    'Renewing will allow your visitors to continue browsing your Online store catalog and submit new orders'
  ),
  onlineStore: i18next.t('Online store'),
});

const subscriptionCategories = {
  HOSTING: 'hosting',
  ONLINE_STORE: 'online-store',
};

const SubscriptionExpiredDialogContainer = (props) => {
  const availablePackages = useSelector(selectors.getHostingPackages, shallowEqual);
  const isB2C = !useSelector(user.selectors.getWhiteLabelStatus);
  const availablePlatformComponents = useSelector(user.selectors.getAvailablePlatformComponents);
  const { onClose } = props;

  const freeSubscriptionType = isB2C ? B2C_HOSTING_FREE : WL_HOSTING_FREE;
  const customCareFeature = availablePackages[freeSubscriptionType]?.features?.custom_care;
  const hostingSubscriptionData = useSelector((state) => {
    const activeHostingSubscription = selectors.getActiveHostingSubscription(state);

    if (activeHostingSubscription) {
      return {
        name: selectors.getCurrentPackage(state).name,
        meta: activeHostingSubscription,
      };
    }

    return null;
  });

  const ecommerceSubscriptionData = useSelector((state) => {
    const activeEcommerceSubscription = selectors.getActiveEcommerceSubscription(state);

    if (
      activeEcommerceSubscription &&
      !verifiers.isFreeEcommercePlanType(activeEcommerceSubscription.type)
    ) {
      return {
        name: selectors.getCurrentEcommercePlan(state).name,
        meta: activeEcommerceSubscription,
      };
    }

    return null;
  });

  const {
    name: hostingSubscriptionName,
    meta: {
      type: hostingType,
      term: hostingTerm,
      status: hostingStatus,
      auto_renew: subscriptionAutoRenew,
    } = {},
  } = hostingSubscriptionData || {};
  const {
    name: ecommerceSubscriptionName,
    meta: {
      type: ecommerceType,
      term: ecommerceTerm,
      status: ecommerceStatus,
      auto_renew: onlineStoreAutoRenew,
    } = {},
  } = ecommerceSubscriptionData || {};

  const isHostingPlusEcommerce =
    ecommerceSubscriptionData && ecommerceStatus === EXPIRED && hostingStatus === EXPIRED;
  const isOnlyEcommerce = ecommerceStatus === EXPIRED && hostingStatus !== EXPIRED;
  const eventTraits = isOnlyEcommerce
    ? { type: ecommerceType, term: ecommerceTerm, onlineStoreAutoRenew }
    : {
        type: hostingType,
        term: hostingTerm,
        subscriptionAutoRenew,
        ...(isHostingPlusEcommerce && { ecommerceType, onlineStoreAutoRenew }),
      };

  const dispatch = useDispatch();

  const captions = useMemo(() => {
    const staticCaptions = getCaptions();

    if (isOnlyEcommerce) {
      return {
        title: staticCaptions.title,
        description: staticCaptions.ecommerceDescription,
        cta: staticCaptions.ecommerceCta,
        primaryButtonLabel: `${staticCaptions.buttonLabel} ${staticCaptions.onlineStore}`,
      };
    }

    const availabilitiesList = [staticCaptions.goOffline];
    const renewButtonLabel = `${staticCaptions.buttonLabel} ${hostingSubscriptionName}`;

    if (availablePlatformComponents.includes(platformComponents.DOMAINS)) {
      availabilitiesList.push(staticCaptions.domainWillBeDisconnected);
    }

    if (customCareFeature !== CUSTOM_CARE_LIMITED) {
      availabilitiesList.push(staticCaptions.noPremiumSupport);
    }

    if (isHostingPlusEcommerce) {
      availabilitiesList.push(`${staticCaptions.loseOnlineStore} ${ecommerceSubscriptionName}`);

      return {
        title: staticCaptions.title,
        list: availabilitiesList,
        cta: staticCaptions.cta,
        primaryButtonLabel: `${renewButtonLabel} + ${staticCaptions.onlineStore}`,
        secondaryButtonLabel: renewButtonLabel,
      };
    }

    return {
      title: staticCaptions.title,
      list: availabilitiesList,
      cta: staticCaptions.cta,
      primaryButtonLabel: renewButtonLabel,
    };
  }, [
    customCareFeature,
    ecommerceSubscriptionName,
    hostingSubscriptionName,
    isHostingPlusEcommerce,
    isOnlyEcommerce,
    availablePlatformComponents,
  ]);

  const handleClose = () => {
    track(events.SUBSCRIPTION_EXPIRED_DIALOG_CANCELLED, eventTraits);

    if (onClose) onClose();

    dispatch(dialogs.actions.hide());
  };

  const renewSubscription = async (category, targetSubscription, withBundledEcommerce = false) => {
    const { type, term, status } = targetSubscription.meta;
    const products = [type];

    if (withBundledEcommerce) {
      products.push(ecommerceType);
    }

    const paymentUrl = utils.generatePaymentUrl(
      { products, term, backUrl: window.location.href },
      isB2C
    );

    await trackAsync(events.RENEW_TRIGGER_CLICKED, {
      triggerId: triggerIds.SUBSCRIPTION_EXPIRED_DIALOG,
      category,
      type,
      term,
      status,
      ...(category === subscriptionCategories.HOSTING
        ? { subscriptionAutoRenew }
        : { onlineStoreAutoRenew }),
      ...(withBundledEcommerce && { ecommerceType, onlineStoreAutoRenew }),
    });

    redirectToUrl(paymentUrl);
  };

  const handlePrimaryButtonClick = () => {
    if (isHostingPlusEcommerce) {
      renewSubscription(subscriptionCategories.HOSTING, hostingSubscriptionData, true);
      return;
    }

    if (isOnlyEcommerce) {
      renewSubscription(subscriptionCategories.ONLINE_STORE, ecommerceSubscriptionData);
      return;
    }

    renewSubscription(subscriptionCategories.HOSTING, hostingSubscriptionData);
  };

  const handleSecondaryButtonClick = () => {
    renewSubscription(subscriptionCategories.HOSTING, hostingSubscriptionData);
  };

  useEffect(() => {
    track(events.SUBSCRIPTION_EXPIRED_DIALOG_DISPLAYED, {
      ...eventTraits,
    });
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  return (
    <SubscriptionExpiredDialog
      captions={captions}
      onClose={handleClose}
      onPrimaryButtonClick={handlePrimaryButtonClick}
      {...(isHostingPlusEcommerce && { onSecondaryButtonClick: handleSecondaryButtonClick })}
    />
  );
};

SubscriptionExpiredDialogContainer.propTypes = {
  onClose: PropTypes.func,
};

SubscriptionExpiredDialogContainer.defaultProps = {
  onClose: null,
};

export default SubscriptionExpiredDialogContainer;
