import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import bowser from 'yola-bowser';
import { Select } from '@yola/ws-ui';

function AsyncDropdownItem(props) {
  const { value, options, onChange, ...otherProps } = props;
  const [selectOptions, setOptions] = useState(options.data);
  const [isLoading, setLoadingState] = useState(!options.isLoaded);

  useEffect(() => {
    if (isLoading) {
      options.onLoad((optionsList) => {
        setLoadingState(false);
        setOptions(optionsList);
      });
    }
  }, [isLoading, options]);

  return (
    <Select
      {...otherProps}
      className="ws-async-dropdown-item"
      native={bowser.mobile}
      value={value}
      isLoading={isLoading}
      options={selectOptions}
      searchable={!bowser.mobile}
      clearable={false}
      onChange={onChange}
    />
  );
}

AsyncDropdownItem.propTypes = {
  value: PropTypes.string,
  options: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
      })
    ),
    isLoaded: PropTypes.bool,
    onLoad: PropTypes.func,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};
AsyncDropdownItem.defaultProps = {
  value: '',
};
export default AsyncDropdownItem;
