import auth from '../../auth';
import publishingServiceClient from '../../publishing-service-client/service-client';
import actions from '../actions';

const fetchPublishedSiteData = () => (dispatch, getState) => {
  const state = getState();
  const siteId = auth.selectors.getSiteId(state);
  const service = publishingServiceClient.get();

  return service
    .listSites(siteId)
    .then(({ data: { results } }) => {
      if (results.length) {
        dispatch(actions.setPublishedSiteData(results[0]));
      }
    })
    .catch((e) => {
      console.error(e);
    });
};

export default fetchPublishedSiteData;
