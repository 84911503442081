import React from 'react';
import bowser from 'yola-bowser';
import { extensions } from '@yola/ws-sdk';
import { designSystem } from '@yola/ws-ui';
import { FONT_SIZE_TRIGGER_ID } from 'src/js/modules/control-pane/constants/trigger-ids';
import isMultipurpose from '../../../common/helpers/is-multipurpose';
import FontSizeSlider from '../components/font-size-slider';
import FontSizeMobileTrigger from '../components/font-size-mobile-trigger';
import FonSizeSelect from '../components/font-size-select';
import constants from '../constants';
import typedElementsMatches from '../helpers/typed-elements-matches';

const { controlTypes } = extensions;

const { Icon } = designSystem;

function elementMatches(element) {
  const { textContainer, fontSizeRestrictedParentsSelector } =
    extensions.accessors.getExtensionSettings(constants.common.SLUG);

  if (
    !isMultipurpose() ||
    !element.matches(textContainer) ||
    (fontSizeRestrictedParentsSelector && element.closest(fontSizeRestrictedParentsSelector))
  ) {
    return false;
  }

  const typedElements = typedElementsMatches();

  return Boolean(typedElements.length);
}

const defaultProps = {
  id: FONT_SIZE_TRIGGER_ID,
  priority: 61,
  for: [controlTypes.COLLAPSED_TEXT, controlTypes.TEXT],
  highlightElement: false,
  matches: elementMatches,
};

const desktopControl = {
  ...defaultProps,
  width: 65,
  trigger: <FonSizeSelect isTablet={bowser.tablet} />,
};

const mobileControl = {
  ...defaultProps,
  trigger: <FontSizeMobileTrigger triggerId={FONT_SIZE_TRIGGER_ID} />,
  header: [
    <span>
      <Icon glyph="font-size" size={20} />
    </span>,
    <FontSizeSlider />,
  ],
  closeIcon: 'submit',
  activeWidth: 370,
};

const fontSizeControl = bowser.mobile ? mobileControl : desktopControl;

export default fontSizeControl;
