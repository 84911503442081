import React from 'react';
import PropTypes from 'prop-types';
import bowser from 'yola-bowser';

import { view, anodum, dialogs } from '@yola/ws-sdk';
import { Modal } from '@yola/ws-ui';

const ENTER_KEY = 'Enter';
const DIALOG_WIDTH = 440;
const DIALOG_HEIGHT = 'auto';

class ModalDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      position: { x: 0, y: 0 },
    };

    this.modalRef = React.createRef();

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.recalcModalPosition = this.recalcModalPosition.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  onDragEnd(event, { x, y }) {
    this.setState({
      position: { x, y },
    });
  }

  handleKeyDown(event) {
    if (event.key === ENTER_KEY) {
      const focusedElement = document.activeElement;

      if (!anodum.isTag(focusedElement, 'textarea')) {
        const { onSubmit } = this.props;
        onSubmit();
      }
    }
  }

  recalcModalPosition() {
    const { targetElementId } = this.props;
    const targetElement = view.accessors.getLiveElement(targetElementId);
    const modalSize = this.modalRef.current.state.modal;

    const position = dialogs.helpers.getPositionByElement(
      targetElement,
      modalSize.width,
      modalSize.height
    );

    this.setState({
      position: {
        x: position.offsetX,
        y: position.offsetY,
      },
    });
  }

  render() {
    const { children, onClose, onSubmit, isValid } = this.props;
    const { position } = this.state;
    const isMobile = bowser.mobile;

    return (
      <Modal
        modalRef={this.modalRef}
        resizable={false}
        width={DIALOG_WIDTH}
        height={DIALOG_HEIGHT}
        offsetX={position.x}
        offsetY={position.y}
        draggable={!isMobile}
        fullscreen={isMobile}
        onKeyDown={this.handleKeyDown}
        onReady={this.recalcModalPosition}
        dragHandleSelector=".ws-drag-trigger"
        onDragEnd={this.onDragEnd}
        handleCancel={onClose}
        handleSubmit={onSubmit}
        isValid={isValid}
      >
        {children}

        <style
          // eslint-disable-next-line yola/react/no-danger
          dangerouslySetInnerHTML={{
            __html: `
              .ace_tooltip {
                max-width: initial;
                transform: translate(${-position.x}px, ${-position.y}px);
              }
            `,
          }}
        />
      </Modal>
    );
  }
}

ModalDialog.propTypes = {
  isValid: PropTypes.bool.isRequired,
  children: PropTypes.element,
  targetElementId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

ModalDialog.defaultProps = {
  children: null,
};

export default ModalDialog;
