import React from 'react';
import { i18next, dialogs, extensions } from '@yola/ws-sdk';
import { designSystem } from '@yola/ws-ui';
import Trigger from 'src/js/modules/common/components/trigger';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import constants from '../constants';
import getSliderElementId from '../helpers/get-slider-element-id';

const { Icon } = designSystem;
const triggerId = 'slider-settings';

const control = {
  id: triggerId,
  trigger: (
    <Trigger id={triggerId}>
      <Icon glyph="slider-settings" />
    </Trigger>
  ),
  onTriggerClick: (elementId) => {
    const sliderElementId = getSliderElementId(elementId);

    if (sliderElementId) {
      dialogs.operations.show(dialogTypes.SLIDER_SETTINGS_DIALOG, { elementId: sliderElementId });
    }
  },
  get title() {
    return i18next.t('Slider settings');
  },

  get tooltip() {
    return i18next.t('Slider settings');
  },
  matches(liveElement) {
    const settings = extensions.accessors.getExtensionSettings(constants.common.slug);
    return liveElement.matches(settings.querySelector);
  },
  priority: 100,
};

export default control;
