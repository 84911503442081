import { customization } from '@yola/ws-sdk';
import updateButtonPresetCode from 'src/js/modules/website-design/helpers/update-button-preset-code';

const buttonPresetMigration = async (store) => {
  const state = store.getState();
  const buttonPresetVersion = customization.selectors.getButtonPresetVersion(state);
  const currentButtonPresetVersion = customization.selectors.getCurrentButtonPresetVersion(state);
  let preset = customization.accessors.getCurrentButtonPreset();
  const isButtonPresetVersionChanged = currentButtonPresetVersion !== buttonPresetVersion;

  if (!isButtonPresetVersionChanged && preset) return false;

  if (isButtonPresetVersionChanged) {
    store.dispatch(customization.actions.setCurrentButtonPresetVersion(buttonPresetVersion));
    preset = customization.accessors.getCurrentButtonPreset();
  }

  if (!preset) {
    const defaultButtonPresetId = customization.selectors.getDefaultButtonPresetId(state);
    preset = customization.selectors.getButtonPreset(state, defaultButtonPresetId);
  }

  updateButtonPresetCode(preset);
  return true;
};

export default buttonPresetMigration;
