import React from 'react';
import { i18next } from '@yola/ws-sdk';
import { designSystem } from '@yola/ws-ui';
import Trigger from 'src/js/modules/common/components/trigger';

const { Icon } = designSystem;

const getFitControl = ({ id, onClick }) => ({
  id,
  trigger: (
    <Trigger
      id={id}
      className="ws-focal-point-controls__trigger"
      onClick={onClick}
      data-tip={i18next.t('Fit')}
    >
      <Icon glyph="fill" />
    </Trigger>
  ),
});

export default getFitControl;
