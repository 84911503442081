import common from './common';
import defaultSettings from './default-settings';
import triggerIds from './triggers-ids';
import operationTypes from './operation-types';

export default {
  common,
  triggerIds,
  defaultSettings,
  operationTypes,
};
