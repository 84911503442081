import optionTypes from '../constants/display-options-type';
import { SET, REMOVE } from '../constants/attribute-operations';

const convertChangedOptionToOperation = (option, element) => {
  const { elementId, attribute, value: newValue } = option;

  switch (option.type) {
    case optionTypes.SURFACE:
    case optionTypes.RADIO:
    case optionTypes.BREAKPOINTS_SLIDER:
    case optionTypes.SLIDER:
    case optionTypes.ONLINE_STORE_CATEGORY:
      return { elementId, operation: SET, name: attribute, value: newValue };
    case optionTypes.ONLINE_STORE_CLEAN_URL_SELECTOR:
      /**
       * We don't need to execute any attribute operations for this option type.
       */
      return null;
    case optionTypes.CHECKBOX: {
      const targetElement =
        option.querySelector && !element.matches(option.querySelector)
          ? element.querySelector(option.querySelector)
          : element;
      const currentValue = targetElement.getAttribute(attribute) || '';
      const values = currentValue.split(' ');
      const shouldSetAttribute = option.inverted ? !option.isEnabled : option.isEnabled;

      if (shouldSetAttribute) {
        const result = [...values, newValue].join(' ').trim();
        return { elementId, operation: SET, name: attribute, value: result };
      }

      const filteredValues = values.filter((value) => value !== newValue);
      const result = filteredValues.join(' ').trim();

      return result
        ? { elementId, operation: SET, name: attribute, value: result }
        : { elementId, operation: REMOVE, name: attribute };
    }
    default:
      return null;
  }
};

export default convertChangedOptionToOperation;
