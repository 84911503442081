import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Modal, designSystem } from '@yola/ws-ui';
import animatedIcons from '../constants/animated-icons';

const { Box, Stack, IconButton, ActionButton, Heading, Paragraph, HorizontalStack, Tag } =
  designSystem;

const PublishInterruptionDialog = ({
  selectedOption,
  isTabletView,
  modalWidth,
  captions,
  options,
  languageCode,
  desktopBreakpoint,
  onSubmit,
  onClose,
  onClick,
  onKeyDown,
}) => (
  <Modal
    key={modalWidth}
    className={classNames('ws-publish-interruption-dialog', {
      'ws-publish-interruption-dialog--mobile-view': !isTabletView,
    })}
    width={modalWidth}
    height="auto"
    overlay="visible"
    fullscreen={!isTabletView}
    draggable={false}
    resizable={false}
    centered
    isContainerScrollable
  >
    <div className="ws-publish-interruption-dialog__close-button">
      <IconButton
        glyph="close"
        iconPlacement="after"
        size="medium"
        onClick={onClose}
        transparent
        selected
      />
    </div>
    <div className="ws-publish-interruption-dialog__image">
      <picture>
        <source
          media={`(min-width: ${desktopBreakpoint})`}
          srcSet={`/images/publish-interruption/desktop/promo-${languageCode}.jpg`}
        />
        <img
          src={`/images/publish-interruption/mobile/promo-${languageCode}.jpg`}
          alt={captions.title}
        />
      </picture>
    </div>
    <Box
      paddingLeft="spacing-xs"
      paddingRight="spacing-xs"
      paddingBottom="spacing-xs"
      paddingTop="spacing-s"
      breakpoints={{ sm: { padding: 'spacing-xl' } }}
    >
      <Box marginBottom="spacing-xs" breakpoints={{ sm: { marginBottom: 'spacing-m' } }}>
        <Heading type="heading-2">{captions.title}</Heading>
      </Box>
      <Box marginBottom="spacing-m" breakpoints={{ sm: { marginBottom: 'spacing-l' } }}>
        <Stack gap="spacing-xs">
          {options.map(({ id, title, description, withDecoration }) => (
            <div
              id={id}
              key={id}
              tabIndex="0"
              role="button"
              className={classNames('ws-publish-interruption-dialog__option', {
                'ws-publish-interruption-dialog__option--selected': selectedOption === id,
              })}
              onClick={() => onClick(id)}
              onKeyDown={onKeyDown}
            >
              <div className="ws-publish-interruption-dialog__option-title">
                <Paragraph size="large">{title}</Paragraph>
                {withDecoration && (
                  <Tag isLabelBold label="PRO" size="small" appearance="cta-secondary" />
                )}
              </div>
              {description && <Paragraph>{description}</Paragraph>}
              {withDecoration &&
                selectedOption === id &&
                animatedIcons.map((props) => (
                  <img className="ws-publish-interruption-dialog__option-icon" {...props} alt="" />
                ))}
            </div>
          ))}
        </Stack>
      </Box>
      <HorizontalStack align="right">
        <ActionButton
          appearance="accent"
          format="solid"
          size="large"
          fullWidth={!isTabletView}
          label={captions.buttonLabel}
          onClick={onSubmit}
        />
      </HorizontalStack>
    </Box>
  </Modal>
);

PublishInterruptionDialog.propTypes = {
  modalWidth: PropTypes.number.isRequired,
  selectedOption: PropTypes.string.isRequired,
  languageCode: PropTypes.string.isRequired,
  isTabletView: PropTypes.bool.isRequired,
  desktopBreakpoint: PropTypes.string.isRequired,
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string.isRequired,
  }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.node.isRequired,
      description: PropTypes.string,
      withDecoration: PropTypes.bool,
    })
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
};

export default PublishInterruptionDialog;
