import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { i18next } from '@yola/ws-sdk';
import dialogs from 'yola-editor/src/js/modules/dialogs';
import publishing from 'yola-editor/src/js/modules/publishing';
import ConfirmActionDialog from '../components/confirm-action-dialog';
import constants from '../constants';
import { redirectToUrl } from '../../../router/utils/redirect-to-url';

const { UNPUBLISH_CONFIRM_PHRASE } = constants;

function UnpublishConfirmActionDialogContainer(props) {
  const { redirectUrl, onDialogMainAction, onDialogCancel } = props;
  const dispatch = useDispatch();
  const captions = {
    title: i18next.t('Unpublish website'),
    label: i18next.t('Type "{confirmPhrase}" to confirm', {
      confirmPhrase: UNPUBLISH_CONFIRM_PHRASE,
    }),
    description: i18next.t(
      "Unpublish your site if you don't want it to remain online anymore. You will continue to own your domain and you will be able to publish another website using this domain name."
    ),
    submit: i18next.t('Submit'),
    cancel: i18next.t('Cancel'),
  };

  function handleCancel() {
    onDialogCancel();
    if (redirectUrl) {
      redirectToUrl(redirectUrl);
    }
    dispatch(dialogs.actions.hide());
  }

  function handleSubmit() {
    onDialogMainAction();
    dispatch(publishing.actions.unpublishWebsite(redirectUrl));
  }

  return (
    <ConfirmActionDialog
      captions={captions}
      confirmPhrase={UNPUBLISH_CONFIRM_PHRASE}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
      {...props}
    />
  );
}

UnpublishConfirmActionDialogContainer.propTypes = {
  redirectUrl: PropTypes.string,
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

UnpublishConfirmActionDialogContainer.defaultProps = {
  redirectUrl: null,
};

export default UnpublishConfirmActionDialogContainer;
