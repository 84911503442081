import { i18next } from '@yola/ws-sdk';
import userSelectors from 'yola-editor/src/js/modules/user/selectors';
import dialogs from 'yola-editor/src/js/modules/dialogs';
import * as errorTypes from '../constants/error-types';

const handleOrderSubscriptionFailure = (store, resp) => {
  const state = store.getState();
  const supportEmail = userSelectors.getSupportEmail(state);
  const errorDetailsMsg = resp.details && resp.details.error && `${resp.details.error} <br>`;

  switch (resp.errorType) {
    case errorTypes.ERROR_TYPE_TIMEOUT:
      store.dispatch(
        dialogs.actions.show(dialogs.dialogTypes.IN_APP_PURCHASE_FAILURE, {
          captions: {
            title: i18next.t('Your order was not completed within the expected duration'),
            description: i18next.t(
              'Your order may be still be processed successfully. Please check your email for confirmation or <br> <a target="_blank" href="mailto:{supportEmail}">contact customer support</a> for assistance.',
              { supportEmail }
            ),
          },
        })
      );
      break;

    case errorTypes.ERROR_TYPE_TASK_FAILURE:
    case errorTypes.ERROR_TYPE_IMMEDIATE:
      store.dispatch(
        dialogs.actions.show(dialogs.dialogTypes.IN_APP_PURCHASE_FAILURE, {
          captions: {
            title: i18next.t('We were unable to complete your order'),
            description: i18next.t(
              `{errorDetailsMsg} Please <a target="_blank" href="mailto:{supportEmail}">contact customer support</a> for assistance.`,
              { errorDetailsMsg, supportEmail }
            ),
          },
        })
      );
      break;

    default:
      store.dispatch(
        dialogs.actions.show(dialogs.dialogTypes.IN_APP_PURCHASE_FAILURE, {
          captions: {
            title: i18next.t('We were unable to complete your order'),
            description: i18next.t(
              'Please <a target="_blank" href="mailto:{supportEmail}">contact customer support</a> for assistance.',
              { supportEmail }
            ),
          },
        })
      );
  }
};

export default handleOrderSubscriptionFailure;
