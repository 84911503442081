import { view, textFormatting, contentEditable } from '@yola/ws-sdk';
import restoreRange from './restore-range';
import unwrapElement from './unwrap-element';
import cleanEmptyWrappers from './clean-empty-wrappers';

const { getSelectedElementsByTag } = contentEditable.helpers;

const wrapElement = (elementId, wrapper) => {
  const selection = textFormatting.accessors.getAdvancedSelection();
  const { id, className, tagName, attributes = {} } = wrapper;
  unwrapElement(selection, elementId, wrapper);

  textFormatting.helpers.wrapperMap.registerWrapper(id, className, tagName, attributes);
  textFormatting.operations.applyWrapper(id);
  textFormatting.helpers.wrapperMap.removeWrapper(id);
  cleanEmptyWrappers(tagName);

  restoreRange(selection, elementId);

  const liveElement = view.accessors.getLiveElement(elementId);
  const elementsWithClasses = liveElement.getElementsByClassName(className);
  Array.from(elementsWithClasses).forEach((node) => node.removeAttribute('class'));
  return getSelectedElementsByTag(tagName);
};

export default wrapElement;
