import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { view } from '@yola/ws-sdk';
import { designSystem } from '@yola/ws-ui';
import navbar from 'src/js/modules/navbar';

const { Preloader } = designSystem;

function ViewLoaderContainer({ loadedStatus, navbarHeight }) {
  const isStatusTextPresent = typeof loadedStatus === 'string';
  const isLoading = isStatusTextPresent || !loadedStatus;
  const loaderClass = classNames('ws-view-loader', {
    'ws-view-loader__active': isLoading,
  });

  if (!isLoading) return null;

  return (
    <div className={loaderClass} style={{ top: navbarHeight }}>
      <Preloader indeterminate label={isStatusTextPresent ? loadedStatus : null} />
    </div>
  );
}

ViewLoaderContainer.propTypes = {
  loadedStatus: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  navbarHeight: PropTypes.number,
};

ViewLoaderContainer.defaultProps = {
  navbarHeight: 0,
};

const mapStateToProps = (state) => ({
  loadedStatus: view.selectors.getLoadedStatus(state),
  navbarHeight: navbar.selectors.getHeight(state),
});

export default connect(mapStateToProps)(ViewLoaderContainer);
