import React, { useState, useEffect, useCallback } from 'react';
import { dialogs, view, i18next, utils, blocks } from '@yola/ws-sdk';
import PropTypes from 'prop-types';
import { TreeView } from '@yola/ws-ui';
import segment from 'src/js/modules/analytics/segment';
import LinkListSettingsDialogContainer from 'src/js/modules/common/components/link-list-settings-dialog-container';
import getParentBlockByElement from 'src/js/modules/blocks/helpers/get-parent-block-by-element';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import buildNewMarkup from '../helpers/build-new-markup';
import getDefaultTraits from '../../../../analytics/segment/helpers/get-default-traits';

const {
  track,
  constants: { events },
} = segment;

const DIALOG_HEIGHT = 472;
const DIALOG_WIDTH = 336;

const closeDialog = () => {
  dialogs.operations.hide();
};

const FooterNavigationSettingsDialog = ({
  items,
  getSharedData,
  resolveSharedData,
  onDialogCancel,
  onDialogMainAction,
  elementId,
  initialItems: initialItemsProp,
}) => {
  const {
    offsetX: dialogOffsetX,
    offsetY: dialogOffsetY,
    initialItems: initialItemsShared,
  } = getSharedData();
  const initialItems = initialItemsShared || initialItemsProp;
  const [dialogPosition, setDialogPosition] = useState({
    offsetX: dialogOffsetX,
    offsetY: dialogOffsetY,
  });

  useEffect(() => {
    if (dialogPosition.offsetX === undefined || dialogPosition.offsetY === undefined) {
      const element = view.accessors.getLiveElement(elementId);
      const { offsetX: x, offsetY: y } = dialogs.helpers.getPositionByElement(
        element,
        DIALOG_WIDTH,
        DIALOG_HEIGHT
      );
      setDialogPosition({ offsetX: x, offsetY: y });
    }
  }, [elementId, dialogPosition]);

  const handleClose = useCallback(() => {
    const element = view.accessors.getLiveElement(elementId);
    const blockElement = getParentBlockByElement(element);

    onDialogCancel({
      blockId: view.accessors.getLiveElementId(blockElement),
      blockVariationId: blocks.accessors.getVariationIdByElement(blockElement),
      dialogType: dialogTypes.FOOTER_NAVIGATION_SETTINGS_DIALOG,
    });

    closeDialog();
  }, [elementId, onDialogCancel]);

  const trackTriggerClicked = useCallback(
    (triggerId) => {
      track(events.LIST_SETTINGS_DIALOG_TRIGGER_CLICKED, {
        ...getDefaultTraits(elementId),
        triggerId,
        dialogId: dialogTypes.FOOTER_NAVIGATION_SETTINGS_DIALOG,
      });
    },
    [elementId]
  );

  const trackOrderChanged = useCallback(() => {
    track(events.LIST_SETTINGS_DIALOG_ITEMS_ORDER_ADJUSTED, {
      ...getDefaultTraits(elementId),
      dialogId: dialogTypes.FOOTER_NAVIGATION_SETTINGS_DIALOG,
    });
  }, [elementId]);

  const openChangeItemDialog = useCallback(
    ({ items: updatedItems, item, offsetX, offsetY }) => {
      resolveSharedData({
        initialItems,
        items: updatedItems,
        offsetX,
        offsetY,
      });

      closeDialog();

      dialogs.operations.show(dialogTypes.FOOTER_NAVIGATION_ITEM_SETTING_DIALOG, {
        elementId,
        itemId: item && item.id,
      });
    },
    [elementId, initialItems, resolveSharedData]
  );

  const applyChanges = useCallback(
    ({ items: updatedItems, isItemsChanged, event }) => {
      const targetLiveElement = view.accessors.getLiveElement(elementId);

      onDialogMainAction({
        elementId,
        dialogType: dialogTypes.FOOTER_NAVIGATION_SETTINGS_DIALOG,
        triggerId: event ? 'submit-button' : 'dialog-background',
        listItemsAdjusted: isItemsChanged,
      });

      if (!isItemsChanged) {
        closeDialog();
        return;
      }

      const targetLiveElementId = view.accessors.getLiveElementId(targetLiveElement);
      const targetStaticElement = view.accessors.getStaticElement(targetLiveElementId);
      const newTargetElement = buildNewMarkup(targetStaticElement, updatedItems);
      view.operations.replaceElement(newTargetElement, targetLiveElementId);

      closeDialog();
    },
    [elementId, onDialogMainAction]
  );

  return (
    <LinkListSettingsDialogContainer
      items={items}
      initialItems={initialItems}
      offsetX={dialogPosition.offsetX}
      offsetY={dialogPosition.offsetY}
      onSubmit={applyChanges}
      onClose={handleClose}
      onOpenChangeItemDialog={openChangeItemDialog}
      captions={{
        dialogTitle: i18next.t('List settings'),
        submit: i18next.t('Submit'),
        cancel: i18next.t('Cancel'),
        emptyTitle: i18next.t('Add item'),
        emptyDescription: i18next.t('Start building your links'),
      }}
      maxNestingLevel={2}
      handleDeleteItem={() => {
        trackTriggerClicked('delete-item');
      }}
      handleAddNewItem={() => {
        trackTriggerClicked('add-item');
      }}
      handleEditItem={() => {
        trackTriggerClicked('item-settings');
      }}
      handleOrderChange={trackOrderChanged}
    />
  );
};

FooterNavigationSettingsDialog.propTypes = {
  elementId: PropTypes.string,
  items: TreeView.propTypes.items,
  initialItems: TreeView.propTypes.items,
  getSharedData: PropTypes.func,
  resolveSharedData: PropTypes.func,
  onDialogCancel: PropTypes.func,
  onDialogMainAction: PropTypes.func,
};

FooterNavigationSettingsDialog.defaultProps = {
  elementId: '',
  items: [],
  initialItems: [],
  getSharedData: utils.noop,
  resolveSharedData: utils.noop,
  onDialogCancel: utils.noop,
  onDialogMainAction: utils.noop,
};

export default FooterNavigationSettingsDialog;
