import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';

const { Checkbox } = designSystem;

function CheckboxField(props) {
  const {
    field: { name, value, onChange },
    form: { setFieldValue },
    id,
    label,
    onClick,
    ...otherProps
  } = props;

  const handleChange = useCallback(
    (fieldValue) => {
      setFieldValue(name, fieldValue, false);
      onChange(fieldValue);
      onClick(fieldValue);
    },
    [onClick, onChange, setFieldValue, name]
  );

  return (
    <Checkbox
      id={id}
      label={label}
      checked={value}
      value={name}
      onChange={handleChange}
      onClick={onClick}
      {...otherProps}
    />
  );
}

CheckboxField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  // Following props are set by Formik. Added only to pass snitch check.
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
  }).isRequired,
};

CheckboxField.defaultProps = {
  onClick: Function.prototype,
};

export default CheckboxField;
