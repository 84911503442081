import {
  isMediaFitsInContainerByWidth,
  isMediaFitsInContainerByHeight,
} from './is-media-fits-in-container';

const computeAdjustedScale = (imageNode, config = {}) => {
  const { originalMediaBounds, scale } = config;

  if (!imageNode || !imageNode.getContainerBounds || !imageNode.getContentBounds) {
    throw new Error(
      'Provided "node" should do not implement required interface. Missing "getContainerBounds()" or "getContentBounds()" method'
    );
  }

  if (!scale) {
    throw new Error(
      'Provided second argument "config" is missing `scale` property which si required'
    );
  }

  const containerBounds = imageNode.getContainerBounds();
  const { width: containerWidth, height: containerHeight } = containerBounds;
  const { width: mediaWidth, height: mediaHeight } = originalMediaBounds;

  if (isMediaFitsInContainerByWidth(mediaWidth, mediaHeight, containerWidth, containerHeight)) {
    return parseFloat((mediaWidth / containerWidth).toFixed(2));
  }

  if (isMediaFitsInContainerByHeight(mediaWidth, mediaHeight, containerWidth, containerHeight)) {
    return parseFloat((mediaHeight / containerHeight).toFixed(2));
  }

  return scale;
};

export default computeAdjustedScale;
