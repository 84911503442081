import React, { useMemo, useRef, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { blocks, dialogs, site, template, view } from '@yola/ws-sdk';
import bowser from 'yola-bowser';
import highlighter from 'src/js/modules/highlighter';
import segment from 'src/js/modules/analytics/segment';
import getParentBlockByElement from 'src/js/modules/blocks/helpers/get-parent-block-by-element';
import customTools from '../../custom-tools';
import TextResizeHandler from '../components/text-resize-handler';
import getHandlerSidePosition from '../helpers/get-handler-side-position';
import getContainerWidth from '../helpers/get-container-width';
import getMinTextWidth from '../helpers/get-min-text-width';
import shouldElementHaveTextResizer from '../verifiers/should-element-have-text-resizer';
import useDrag from '../hooks/use-drag';
import ConditionalWrapper from '../../common/components/conditional-wrapper';
import positions from '../constants/positions';
import { TEXT_RESIZE_MAX_WIDTH_PROPERTY } from '../constants/common';
import { CONTAINER, COLUMN, BLOCK_CONTENT } from '../constants/selectors';
import { MAX_INITIAL_TEXT_WIDTH } from '../constants/text-width-limitations';

const {
  track,
  constants: { events },
} = segment;

const TextResizer = () => {
  const { scrollPosition, isLoaded, focusedElementId, isDialogVisible } = useSelector(
    (state) => ({
      scrollPosition: view.selectors.getScrollPosition(state),
      isLoaded: view.selectors.getLoadedStatus(state),
      focusedElementId: view.selectors.getFocusedElement(state),
      isDialogVisible: dialogs.verifiers.isVisible(),
      selection: view.selectors.getSelection(state),
    }),
    shallowEqual
  );

  const element = useMemo(
    () => focusedElementId && view.accessors.getLiveElement(focusedElementId),
    [focusedElementId]
  );
  const [elementRect, setElementRect] = useState(() => element && element.getBoundingClientRect());
  const position = useMemo(() => getHandlerSidePosition(focusedElementId), [focusedElementId]);

  const minTextWidth = useMemo(() => getMinTextWidth(element), [element]);
  const maxTextWidth = useMemo(() => {
    const computedStyles = element && getComputedStyle(element);
    const blockTitleMaxWidth = computedStyles?.getPropertyValue(TEXT_RESIZE_MAX_WIDTH_PROPERTY);
    const numericBlockTitleMaxWidth = parseInt(blockTitleMaxWidth, 10);

    if (blockTitleMaxWidth) {
      return numericBlockTitleMaxWidth;
    }

    return getContainerWidth(
      element &&
        (element.closest(BLOCK_CONTENT) || element.closest(COLUMN) || element.closest(CONTAINER))
    );
  }, [element]);

  const shouldShowResizer = useMemo(
    () => shouldElementHaveTextResizer(focusedElementId),
    [focusedElementId]
  );

  const cachedRect = useRef(null);
  const leftHandlerRef = useRef(null);
  const rightHandlerRef = useRef(null);
  const [isResizerActive, setIsResizerActive] = useState(false);
  const elementOffsetHeight = element && element.offsetHeight;
  const elementOffsetWidth = element && element.offsetWidth;

  useEffect(() => {
    setElementRect(element && element.getBoundingClientRect());
  }, [scrollPosition, element, elementOffsetHeight, elementOffsetWidth]);

  const handleDrag = (distance, dragStartX, applyLiveChanges = false, cleanUpChanges = false) => {
    const { width, x } = cachedRect.current;
    const newWidth = x < dragStartX ? width + distance[0] : width - distance[0];
    const limitedWidth = Math.max(minTextWidth, Math.min(newWidth, maxTextWidth));
    const isWidthToCleanUp =
      cleanUpChanges &&
      maxTextWidth < MAX_INITIAL_TEXT_WIDTH &&
      limitedWidth === maxTextWidth &&
      element.hasAttribute('style');

    if (isWidthToCleanUp) {
      element.removeAttribute('style');

      if (applyLiveChanges) {
        view.operations.removeElementAttribute(focusedElementId, 'style');
      }
    } else {
      element.style.width = `${limitedWidth}px`;

      if (applyLiveChanges) {
        view.operations.setInlineElementStyle(focusedElementId, 'width', `${limitedWidth}px`);
      }
    }

    const newElementRect = element.getBoundingClientRect();
    setElementRect(newElementRect);
  };

  useDrag([leftHandlerRef, rightHandlerRef], {
    onStart() {
      setIsResizerActive(true);
      customTools.operations.setActiveTool('text-resizer'); // this is temporary solution to hide control pane when resizing text
      cachedRect.current = element.getBoundingClientRect();
      highlighter.operations.show([element], { forceUpdate: true, withElementSize: true });
    },

    onMove(distance, dragStartX) {
      handleDrag(distance, dragStartX);
      highlighter.operations.show([element], { forceUpdate: true, withElementSize: true });
    },

    onEnd(distance, dragStartX) {
      setIsResizerActive(false);
      handleDrag(distance, dragStartX, true, true);
      customTools.operations.setActiveTool(null);
      highlighter.operations.show([element], { forceUpdate: true });

      const parentBlock = getParentBlockByElement(element);

      track(events.BLOCK_TITLE_WIDTH_RESIZED, {
        siteId: site.accessors.getSiteId(),
        templateBuildSlug: template.accessors.getBuildSlug(),
        blockId: blocks.accessors.getBlockIdByElement(parentBlock),
        blockVariationId: blocks.accessors.getVariationIdByElement(parentBlock),
        oldWidth: cachedRect.current.width,
        newWidth: elementRect.width,
      });
    },
  });

  if (!isLoaded || !focusedElementId || !elementRect || isDialogVisible || !shouldShowResizer)
    return null;

  return (
    <div className="ws-text-resize-tool">
      {isResizerActive && (
        <div
          className="ws-text-resize-tool__overlay"
          style={{ transform: `translate3d(0, ${scrollPosition}px, 0)` }}
        />
      )}
      <ConditionalWrapper
        condition={isResizerActive && bowser.firefox}
        wrapper={(children) => <div>{children}</div>}
      >
        {position === positions.BOTH ? (
          <React.Fragment>
            <TextResizeHandler
              ref={leftHandlerRef}
              elementRect={elementRect}
              scrollPosition={scrollPosition}
              elementSidePosition={positions.LEFT}
            />
            <TextResizeHandler
              ref={rightHandlerRef}
              elementRect={elementRect}
              scrollPosition={scrollPosition}
              elementSidePosition={positions.RIGHT}
            />
          </React.Fragment>
        ) : (
          <TextResizeHandler
            ref={position === positions.LEFT ? leftHandlerRef : rightHandlerRef}
            elementRect={elementRect}
            scrollPosition={scrollPosition}
            elementSidePosition={position}
          />
        )}
      </ConditionalWrapper>
    </div>
  );
};

export default TextResizer;
