import { i18next } from '@yola/ws-sdk';
import { SUPPORTED_SOCIALS_HOSTS_MAP } from '../../registry/ws-social-links/constants/icon-autocomplete-maps';
import getSupportedHost from './get-supported-host';
import getSupportedProtocol from './get-supported-protocol';

const validateSocialNetworks = (value) => {
  const url = value.toLowerCase();
  const isHostSupported = getSupportedHost(url, SUPPORTED_SOCIALS_HOSTS_MAP);

  if (isHostSupported) {
    return null;
  }

  const isProtocolSupported = getSupportedProtocol(url);

  if (isProtocolSupported) {
    return null;
  }

  return i18next.t('The URL should lead to social networks');
};

export default validateSocialNetworks;
