import { cssKeyMap } from '../constant/colors-maps';

const getInitialGeneralColor = (colorKey, initialColors) => {
  const key = colorKey.toLowerCase();

  if (key.endsWith('primary')) {
    return initialColors[cssKeyMap.primary];
  }

  if (key.endsWith('secondary')) {
    return initialColors[cssKeyMap.secondary];
  }

  return initialColors[colorKey];
};

export default getInitialGeneralColor;
