import React from 'react';

const PositionIcon = () => (
  <svg width="144" height="90" viewBox="0 0 144 90" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="34" y="19" width="76" height="52" rx="4" fill="#DADDE0" />
    <path
      d="M50.736 47.3571V48.14C50.736 49.0689 50.3129 50.5551 46.0821 51.3911C44.9186 51.6052 43.8449 52.2125 43.0107 53.1283C42.6955 53.4743 42.4204 53.8578 42.1897 54.2699C41.5852 55.3494 42.515 56.5 43.7157 56.5H64.2672C65.5515 56.5 66.4811 55.203 65.7325 54.1172C65.4946 53.7721 65.2278 53.4499 64.9348 53.155C64.0382 52.2528 62.9307 51.642 61.7361 51.3911C57.5053 50.5086 57.5053 49.0689 57.5053 48.14V47.3571"
      fill="#646C75"
    />
    <path
      d="M60.2858 42.1327C60.2858 46.2143 57.4572 49.6429 54.0001 49.6429C50.543 49.6429 47.7144 46.3776 47.7144 42.2959V38.3776C47.7144 34.1327 51.0368 32.5 54.0001 32.5C56.9633 32.5 60.2858 33.9694 60.2858 38.2143V42.1327Z"
      fill="#646C75"
    />
    <path
      d="M72 44C72 43.4477 72.4399 43 72.9825 43H101.018C101.56 43 102 43.4477 102 44V45C102 45.5523 101.56 46 101.018 46H72.9825C72.4399 46 72 45.5523 72 45V44Z"
      fill="#B0B8BF"
    />
    <path
      d="M72 39C72 38.4477 72.4399 38 72.9825 38H101.018C101.56 38 102 38.4477 102 39V40C102 40.5523 101.56 41 101.018 41H72.9825C72.4399 41 72 40.5523 72 40V39Z"
      fill="#646C75"
    />
    <path
      d="M72 49C72 48.4477 72.4477 48 73 48H97C97.5523 48 98 48.4477 98 49V50C98 50.5523 97.5523 51 97 51H73C72.4477 51 72 50.5523 72 50V49Z"
      fill="#B0B8BF"
    />
  </svg>
);

export default PositionIcon;
