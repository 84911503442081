import { hdrm } from '@yola/ws-sdk';

function ignoreContent(liveDocument) {
  liveDocument.defaultView.addEventListener(
    'WSLoad',
    hdrm.helpers.WSInternalEvent.bind(null, (event) => {
      event.stopPropagation();
      hdrm.injections.bindings.mutation.ignore(event.detail, true);
    })
  );
}

export default ignoreContent;
