import { serviceProvider } from '@yola/subscription-manager-js';
import user from 'yola-editor/src/js/modules/user';
import errorModule from 'yola-editor/src/js/modules/error';

const fetchEcommercePlans = () => async (dispatch, getState) => {
  const state = getState();
  const availableSubscriptionTypes = user.selectors.getAvailableSubscriptionTypes(state);

  try {
    await serviceProvider.fetchEcommercePlans(availableSubscriptionTypes);
  } catch (error) {
    dispatch(
      errorModule.actions.setCriticalError({
        error,
        logSentry: true,
      })
    );
  }
};

export default fetchEcommercePlans;
