import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { dialogs, saving, i18next } from '@yola/ws-sdk';
import { ProgressDialog, SubmitDialog } from '@yola/ws-ui';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import tabIdentifiers from 'src/js/modules/website-settings/constants/tab-identifiers';

function DeleteSiteBackup({ backupId, onCancel, onDialogMainAction, onDialogCancel }) {
  const dispatch = useDispatch();
  const [isPending, setIsPending] = useState(false);

  const captions = {
    title: i18next.t('Delete Site Backup?'),
    description: i18next.t("The site backup will be deleted. This action can't be undone."),
    submit: i18next.t('Submit'),
    cancel: i18next.t('Cancel'),
  };

  const handleSuccess = () => {
    dispatch(
      dialogs.actions.show(dialogTypes.WEBSITE_SETTINGS, { activeTabId: tabIdentifiers.BACKUPS })
    );
  };

  const handleCancel = () => {
    onDialogCancel();
    onCancel();
  };

  const handleError = () => {
    dialogs.operations.show(dialogTypes.SITE_BACKUP_ERROR, {
      captions: {
        title: i18next.t('Site backup deletion failed'),
        description: i18next.t('An error occurred while deleting your site backup'),
      },
    });
  };

  const deleteBackup = () => {
    onDialogMainAction();
    setIsPending(true);

    dispatch(saving.thunks.deleteSiteBackup(backupId)).then(handleSuccess).catch(handleError);
  };

  const pendingProps = {
    className: 'ws-site-backup-pending-dialog',
    overlay: 'visible',
    cancelable: false,
    progressType: 'indeterminate',
    width: 290,
    height: 108,
    captions: {
      title: `${i18next.t('Deleting site backup...')}`,
    },
  };

  return (
    <React.Fragment>
      {isPending && <ProgressDialog {...pendingProps} />}

      {!isPending && (
        <SubmitDialog
          onSubmit={deleteBackup}
          onCancel={handleCancel}
          captions={captions}
          height="auto"
        />
      )}
    </React.Fragment>
  );
}

DeleteSiteBackup.propTypes = {
  backupId: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

DeleteSiteBackup.defaultProps = {
  onCancel: null,
};

export default DeleteSiteBackup;
