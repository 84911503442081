import { render } from 'react-dom';
import { anodum } from '@yola/ws-sdk';

const { parseElementFromString } = anodum;

const getFlagSourceFromComponent = (component, root) => {
  render(component, root);
  const flagSvg = root.querySelector('[src]');

  return flagSvg && flagSvg.src;
};

const loadSvgMarkup = async (url) => fetch(url).then((r) => r.text());

const prepareLanguageSelectorFlagsMarkup = async (languages) => {
  const virtualRoot = document.createElement('div');

  const sources = languages.map((lang) =>
    getFlagSourceFromComponent(lang.country.iconComponent, virtualRoot)
  );
  const svgsMarkup = await Promise.all(sources.map((src) => loadSvgMarkup(src)));

  const symbols = svgsMarkup.map((svgMarkup, index) => {
    const language = languages[index];
    const symbol = document.createElement('symbol');
    const svg = parseElementFromString(svgMarkup);

    // it is important to have a unique mask ID for each svg flag
    const svgMask = svg.querySelector('mask');
    if (svgMask) {
      const svgGroup = svg.querySelector('[mask]');
      svgMask.setAttribute('id', `mask-${language.code}`);
      svgGroup.setAttribute('mask', `url(#mask-${language.code})`);
    }

    symbol.innerHTML = svg.innerHTML;
    symbol.id = `flag-${language.code}`;
    symbol.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    symbol.setAttribute('viewBox', svg.getAttribute('viewBox'));
    symbol.setAttribute('data-lang-title', language.nativeName);
    symbol.setAttribute('data-lang-code', language.code);

    return symbol;
  });

  const documentFragment = document.createDocumentFragment();

  symbols.forEach((node) => {
    documentFragment.appendChild(node);
  });

  const parentSvgElement = document.createElement('svg');
  parentSvgElement.appendChild(documentFragment);

  return parentSvgElement;
};

export default prepareLanguageSelectorFlagsMarkup;
