import getIsCategoryCompleted from './get-is-category-completed';

const getFirstUncompletedCategorySlug = (categories, checklist) => {
  const firstUncompletedCategory = categories.find(
    (category) => !getIsCategoryCompleted(category, checklist)
  );
  return firstUncompletedCategory ? firstUncompletedCategory.slug : '';
};

export default getFirstUncompletedCategorySlug;
