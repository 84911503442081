import common from './common';
import sizes from './sizes';
import tabIdentifiers from './tab-identifiers';
import { CODE_EDITOR_HEIGHT } from './code-editor-height';

export default {
  common,
  sizes,
  tabIdentifiers,
  CODE_EDITOR_HEIGHT,
};
