import { i18next } from '@yola/ws-sdk';
import tabIdentifiers from './tab-identifiers';
import settingTypes from './setting-types';

export default [
  {
    tabId: tabIdentifiers.GENERAL,
    name: settingTypes.SITE_NAME,
    glyphName: null,
    hasTrigger: false,
    title: i18next.t('Edit site name'),
  },
  {
    tabId: tabIdentifiers.GENERAL,
    name: settingTypes.FAVICON,
    glyphName: null,
    hasTrigger: false,
    title: i18next.t('Website settings'),
  },
  {
    tabId: tabIdentifiers.BUSINESS_INFO,
    name: settingTypes.BUSINESS_NAME,
    glyphName: null,
    hasTrigger: false,
    title: i18next.t('Website settings'),
  },
  {
    tabId: tabIdentifiers.BUSINESS_INFO,
    name: settingTypes.BUSINESS_CATEGORY,
    glyphName: null,
    hasTrigger: false,
    title: i18next.t('Website settings'),
  },
  {
    tabId: tabIdentifiers.BUSINESS_INFO,
    name: settingTypes.BUSINESS_DESCRIPTION,
    glyphName: null,
    hasTrigger: false,
    title: i18next.t('Website settings'),
  },
  {
    tabId: tabIdentifiers.BUSINESS_INFO,
    name: settingTypes.ADDRESSES,
    glyphName: null,
    hasTrigger: false,
    title: i18next.t('Website settings'),
  },
  {
    tabId: tabIdentifiers.BUSINESS_INFO,
    name: settingTypes.PHONES,
    glyphName: null,
    hasTrigger: false,
    title: i18next.t('Website settings'),
  },
  {
    tabId: tabIdentifiers.BUSINESS_INFO,
    name: settingTypes.EMAILS,
    glyphName: null,
    hasTrigger: false,
    title: i18next.t('Website settings'),
  },
  {
    tabId: tabIdentifiers.BUSINESS_INFO,
    name: settingTypes.BUSINESS_HOURS,
    glyphName: null,
    hasTrigger: false,
    title: i18next.t('Website settings'),
  },
  {
    tabId: tabIdentifiers.CUSTOM_CODE,
    name: settingTypes.HEAD_CODE,
    glyphName: null,
    hasTrigger: false,
    title: i18next.t('Custom code'),
  },
  {
    tabId: tabIdentifiers.CUSTOM_CODE,
    name: settingTypes.HEADER_CODE,
    glyphName: null,
    hasTrigger: false,
    title: i18next.t('Custom code'),
  },
  {
    tabId: tabIdentifiers.CUSTOM_CODE,
    name: settingTypes.FOOTER_CODE,
    glyphName: null,
    hasTrigger: false,
    title: i18next.t('Custom code'),
  },
  {
    tabId: tabIdentifiers.GENERAL,
    name: settingTypes.IS_COOKIE_BANNER_ENABLED,
    glyphName: null,
    hasTrigger: false,
    title: i18next.t('Website settings'),
  },
  {
    tabId: tabIdentifiers.GENERAL,
    name: settingTypes.PREVENT_INDEXING,
    glyphName: null,
    hasTrigger: false,
    title: i18next.t('Website settings'),
  },
  {
    tabId: tabIdentifiers.INTEGRATIONS,
    name: settingTypes.GOOGLE_ANALYTICS_ID,
    glyphName: 'edit',
    hasTrigger: true,
    title: i18next.t('Google Analytics Tracking ID'),
  },
  {
    tabId: tabIdentifiers.INTEGRATIONS,
    name: settingTypes.GOOGLE_WEBMASTER_CODE,
    glyphName: 'edit',
    hasTrigger: true,
    title: i18next.t('Google Webmaster Code'),
  },
];
