import constants from '../constants';
import getIconDialogCommonTraits from '../helpers/get-icon-dialog-common-traits';
import track from '../track';

const { events } = constants;

const trackSelectIconDialogDisplayed = ({ dialogId, targetElementId, tabId, premiumAvailable }) => {
  const traits = {
    dialogId,
    tabId,
    premiumAvailable,
    ...getIconDialogCommonTraits(targetElementId),
  };

  track(events.SELECT_ICON_DIALOG_DISPLAYED, traits);
};

export default trackSelectIconDialogDisplayed;
