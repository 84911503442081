import React, { useContext, useRef, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import UserInputContext from '../../common/components/user-input-context';
import NavContext from './nav-context';

function Nav({ children }) {
  const userInputContext = useContext(UserInputContext);
  const { close, isOpen } = useContext(NavContext);
  const navElement = useRef(null);
  const overlayClasses = classNames('ws-nav-overlay', {
    'ws-nav-overlay--active': isOpen,
  });

  useEffect(() => {
    const { subscribe } = userInputContext;

    const onWindowClick = (event) => {
      const { current } = navElement || {};

      if (current && event.target !== current && !current.contains(event.target) && isOpen) {
        close();
      }
    };

    const onKeyDown = (event) => {
      const isEscPressed = event.key && (event.key === 'Esc' || event.key === 'Escape');

      if (isOpen && isEscPressed) {
        close();
      }
    };

    const onClickSubscription = subscribe('click', onWindowClick);
    const onKeyDownSubscription = subscribe('keydown', onKeyDown);

    return () => {
      onClickSubscription.unsubscribe();
      onKeyDownSubscription.unsubscribe();
    };
  }, [userInputContext, isOpen, close]);

  return (
    <div id="nav-menu" className="ws-nav-container">
      <div className="ws-nav" ref={navElement}>
        {children}
      </div>
      <div className={overlayClasses} />
    </div>
  );
}

Nav.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
};

Nav.defaultProps = {
  children: null,
};

export default Nav;
