import React, { useEffect, useLayoutEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { site, dialogs, i18next, assets, siteLocales } from '@yola/ws-sdk';
import useFeatureFlags from 'yola-editor/src/js/modules/feature-flags/hooks/use-feature-flags';
import ai from '../../ai';
import segment from '../../analytics/segment';
import dialogTypes from '../../dialogs/constants/dialog-types';
import normalizeUrl from '../../extensions/registry/ws-social-links/helpers/normalize-url';
import useMatchBreakpoint from '../../utils/custom-hooks/use-match-breakpoint';
import constants from '../constants';
import prepareSetupSummaryData from '../helpers/prepare-setup-summary-data';
import websiteSettingsHelpers from '../../website-settings/helpers';
import useSetupSteps from '../hooks/use-setup-steps';
import useSetupForm from '../hooks/use-setup-form';
import WizardSetupDialog from '../components/wizard-setup-dialog';

const DESKTOP_VIEW_BREAKPOINT = '768px';

const {
  constants: { recipeIds },
} = ai;
const { fieldNames, setupSteps } = constants;
const {
  constants: { events },
  trackers: { trackEvent },
} = segment;
const { getLanguages } = websiteSettingsHelpers;

const getCaptions = (languageName) => ({
  backButtonLabel: i18next.t('Back'),
  skipButtonLabel: i18next.t('Skip'),
  nextButtonLabel: i18next.t('Continue'),
  submitButtonLabel: i18next.t('Generate site'),
  stepsPanel: {
    title: i18next.t('Generate your website with AI'),
    description: i18next.t('Follow these steps to generate a website tailored to you'),
    steps: {
      [setupSteps.GENERAL]: i18next.t('General'),
      [setupSteps.DESCRIPTION]: i18next.t('Description'),
      [setupSteps.LOGO]: i18next.t('Logo'),
      [setupSteps.CONTACTS]: i18next.t('Contacts'),
      [setupSteps.SOCIALS]: i18next.t('Social links'),
      [setupSteps.SUMMARY]: i18next.t('Summary'),
    },
  },
  [setupSteps.GENERAL]: {
    sectionTitle: i18next.t('General'),
    sectionDescription: i18next.t('Review and edit your information as needed'),
  },
  [setupSteps.DESCRIPTION]: {
    sectionTitle: i18next.t('Describe your business'),
    sectionDescription: i18next.t(
      'Please share what sets you apart, such as your services, products, or specialization'
    ),
  },
  [setupSteps.LOGO]: {
    sectionTitle: i18next.t('Upload your logo'),
    sectionDescription: i18next.t(
      `Your logo will automatically populate across relevant areas of the site. You can change it anytime`
    ),
  },
  [setupSteps.CONTACTS]: {
    sectionTitle: i18next.t('Add contact information'),
    sectionDescription: i18next.t('Help your visitors to contact you. You can change it anytime'),
  },
  [setupSteps.SOCIALS]: {
    sectionTitle: i18next.t('Add social links'),
    sectionDescription: i18next.t(
      'Connect with your visitors in social networks. You can change it anytime'
    ),
  },
  [setupSteps.SUMMARY]: {
    sectionTitle: i18next.t('Summary'),
    sectionDescription: i18next.t('Please review your website information'),
    siteNameTitle: i18next.t('Name'),
    businessCategoryTitle: i18next.t('Type'),
    businessNameTitle: i18next.t('Business name'),
    descriptionTitle: i18next.t('Description'),
    showDescriptionTitle: i18next.t('Show more...'),
    collapseDescriptionTitle: i18next.t('Collapse'),
    logoTitle: i18next.t('Logo'),
    contactsTitle: i18next.t('Contacts'),
    socialLinksTitle: i18next.t('Social links'),
    emptyState: i18next.t('Not added'),
    editButtonLabel: i18next.t('Edit'),
    notificationTitle: i18next.t('Your website will be generated in {languageName} language', {
      languageName,
    }),
    notificationDescription: i18next.t('You can add more languages in {path}', { path: '' }),
    notificationPath: i18next.t('Settings → Multilingual'),
  },
});

const SiteWizardSetupContainer = ({
  isReopening,
  getSharedData,
  resolveSharedData,
  onDialogCancel,
  onDialogMainAction,
}) => {
  const isDesktopView = useMatchBreakpoint(DESKTOP_VIEW_BREAKPOINT);
  const dispatch = useDispatch();
  const activePageId = useSelector(site.selectors.getActivePageId);
  const currentHtmlLocale = useSelector(siteLocales.selectors.getCurrentHtmlLocale);
  const languageName = useMemo(() => {
    const [langCode] = currentHtmlLocale.split('-');
    const languageData = getLanguages()[langCode];
    return languageData?.name || currentHtmlLocale;
  }, [currentHtmlLocale]);
  const captions = getCaptions(languageName);
  const sharedData = getSharedData();
  const businessCategory = useSelector(site.selectors.getBusinessCategory);
  const [featureFlags] = useFeatureFlags(['business_taxonomy']);
  const { business_taxonomy: isBusinessTaxonomyFeatureEnabled } = featureFlags;
  const defaultStepId =
    isBusinessTaxonomyFeatureEnabled && !businessCategory
      ? setupSteps.GENERAL
      : setupSteps.DESCRIPTION;
  const initialStepId = sharedData?.stepsState?.activeStepId || defaultStepId;

  const [isLoading, setIsLoading] = useState(false);
  const {
    steps,
    activeStepId,
    isFirstStep,
    isLastStep,
    getStepsState,
    onChangeStep,
    onPrevStep,
    onNextStep,
  } = useSetupSteps({
    stepsIds: Object.values(setupSteps),
    activeStepId: initialStepId,
    completedSteps: sharedData?.stepsState?.completedSteps || [setupSteps.GENERAL],
    passedSteps: sharedData?.stepsState?.passedSteps || [],
  });
  const {
    fields,
    updatedFields,
    someFieldsFilled,
    someFieldsRequired,
    resetFields,
    triggerValidation,
    getFieldsValues,
  } = useSetupForm({
    activeStepId,
    initialFieldsValues: sharedData?.fieldsValues,
  });

  const scrollableContainerRef = useRef(null);
  useLayoutEffect(() => {
    scrollableContainerRef.current.scrollTop = 0;
  }, [activeStepId]);

  useEffect(() => {
    if (isReopening) return;
    trackEvent(events.AI_SITE_WIZARD_SETUP_DIALOG_DISPLAYED, {
      stepId: initialStepId,
      businessCategory,
    });
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  const isActiveStepSkippable = !isLastStep && !someFieldsRequired();
  const summaryData =
    activeStepId === setupSteps.SUMMARY ? prepareSetupSummaryData(getFieldsValues()) : {};

  const getBusinessCategoryFieldValue = () => {
    const fieldsValues = getFieldsValues();

    return fieldsValues[fieldNames.BUSINESS_CATEGORY]?.value || null;
  };

  const handleBack = async () => {
    const isValid = await triggerValidation();

    if (!isValid) {
      return;
    }

    trackEvent(events.AI_SITE_WIZARD_SETUP_BACK_STEP_CLICKED, {
      stepId: activeStepId,
      businessCategory: getBusinessCategoryFieldValue(),
    });

    onPrevStep({
      markStepAsCompleted: someFieldsFilled(),
    });
  };

  const handleNext = async () => {
    const isValid = await triggerValidation();

    if (!isValid) {
      return;
    }

    trackEvent(events.AI_SITE_WIZARD_SETUP_NEXT_STEP_CLICKED, {
      stepId: activeStepId,
      businessCategory: getBusinessCategoryFieldValue(),
    });

    onNextStep({
      markStepAsCompleted: someFieldsFilled(),
    });
  };

  const handleSkip = () => {
    resetFields();

    trackEvent(events.AI_SITE_WIZARD_SETUP_SKIP_STEP_CLICKED, {
      stepId: activeStepId,
      businessCategory: getBusinessCategoryFieldValue(),
    });

    onNextStep({
      markStepAsCompleted: someFieldsFilled(),
    });
  };

  const handleChange = async (stepId) => {
    const isValid = await triggerValidation();

    if (!isValid) {
      return;
    }

    trackEvent(events.AI_SITE_WIZARD_SETUP_CHANGE_STEP_CLICKED, {
      stepId,
      businessCategory: getBusinessCategoryFieldValue(),
    });

    onChangeStep(stepId, {
      markStepAsCompleted: someFieldsFilled(),
    });
  };

  const handleEdit = (stepId) => {
    trackEvent(events.AI_SITE_WIZARD_SETUP_EDIT_STEP_CLICKED, {
      stepId,
      businessCategory: getBusinessCategoryFieldValue(),
    });

    onChangeStep(stepId);
  };

  const handleClose = () => {
    resolveSharedData({
      fieldsValues: getFieldsValues(),
      stepsState: getStepsState(),
    });

    dialogs.operations.show(dialogTypes.SITE_WIZARD_CONFIRM_EXIT_DIALOG, {
      onDialogMainAction: () => {
        trackEvent(events.AI_SITE_WIZARD_SETUP_DIALOG_CLOSED, {
          stepId: activeStepId,
          businessCategory,
        });
        onDialogCancel();
        dialogs.operations.hide();
      },
      onDialogCancel: () => {
        dialogs.operations.show(dialogTypes.SITE_WIZARD_SETUP_DIALOG, {
          isReopening: true,
          onDialogCancel,
          onDialogMainAction,
        });
      },
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    const fieldsValues = getFieldsValues();

    if (updatedFields.length) {
      const settingsToUpdate = {};

      if (updatedFields.includes(fieldNames.LOGO) && fieldsValues[fieldNames.LOGO]) {
        const newLogoFile = fieldsValues[fieldNames.LOGO];

        const { data } = await assets.operations.createMediaGalleryAssets({
          asset: newLogoFile,
          name: newLogoFile.name,
        });

        assets.operations.makePublishable(data.source);
        settingsToUpdate.businessLogo = data.source;
      }

      if (updatedFields.includes(fieldNames.SITE_NAME)) {
        settingsToUpdate.siteName = fieldsValues[fieldNames.SITE_NAME].trim();
      }

      if (updatedFields.includes(fieldNames.BUSINESS_NAME)) {
        settingsToUpdate.businessName = fieldsValues[fieldNames.BUSINESS_NAME].trim();
      }

      if (updatedFields.includes(fieldNames.DESCRIPTION)) {
        settingsToUpdate.businessDescription = fieldsValues[fieldNames.DESCRIPTION].trim();
      }

      const address = fieldsValues[fieldNames.ADDRESS].trim();
      if (updatedFields.includes(fieldNames.ADDRESS) && address) {
        settingsToUpdate.addresses = [address];
      }

      const phone = fieldsValues[fieldNames.PHONE].trim();
      if (updatedFields.includes(fieldNames.PHONE) && phone) {
        settingsToUpdate.phones = [phone];
      }

      if (updatedFields.includes(fieldNames.EMAIL)) {
        settingsToUpdate.emails = [fieldsValues[fieldNames.EMAIL]];
      }

      const hours = fieldsValues[fieldNames.HOURS].trim();
      if (updatedFields.includes(fieldNames.HOURS) && hours) {
        settingsToUpdate.businessHours = [hours];
      }

      if (updatedFields.includes(fieldNames.SOCIAL_LINKS)) {
        const socialLinks = fieldsValues[fieldNames.SOCIAL_LINKS].filter(({ link }) => link.trim());

        if (socialLinks.length) {
          settingsToUpdate.socialLinks = socialLinks.map(({ link }) => normalizeUrl(link.trim()));
        }
      }

      if (updatedFields.includes(fieldNames.BUSINESS_CATEGORY)) {
        settingsToUpdate.businessCategory = fieldsValues[fieldNames.BUSINESS_CATEGORY].value;
      }

      if (Object.keys(settingsToUpdate).length) {
        dispatch(site.actions.updateSettings(settingsToUpdate, true, true));
      }
    }

    onDialogMainAction({
      businessName: fieldsValues[fieldNames.SITE_NAME],
      businessDescription: fieldsValues[fieldNames.DESCRIPTION],
      logoAdded: Boolean(fieldsValues[fieldNames.LOGO]),
      addressAdded: Boolean(fieldsValues[fieldNames.ADDRESS]),
      phoneAdded: Boolean(fieldsValues[fieldNames.PHONE]),
      emailAdded: Boolean(fieldsValues[fieldNames.EMAIL]),
      hoursAdded: Boolean(fieldsValues[fieldNames.HOURS]),
      socialLinksAdded: Boolean(fieldsValues[fieldNames.SOCIAL_LINKS].filter(Boolean).length),
      businessCategory: fieldsValues[fieldNames.BUSINESS_CATEGORY]?.value || null,
    });

    dialogs.operations.show(dialogTypes.SITE_WIZARD_PROGRESS_DIALOG, {
      pageId: activePageId,
      recipeId: recipeIds.HOME_BUSINESS,
      locale: currentHtmlLocale,
    });
  };

  return (
    <WizardSetupDialog
      captions={captions}
      steps={steps}
      fields={fields}
      summaryData={summaryData}
      activeStepId={activeStepId}
      isDesktopView={isDesktopView}
      isLoading={isLoading}
      isBusinessTaxonomyFeatureEnabled={Boolean(isBusinessTaxonomyFeatureEnabled)}
      showBackButton={!isFirstStep}
      showSkipButton={isActiveStepSkippable}
      showSubmitButton={isLastStep}
      scrollableContainerRef={scrollableContainerRef}
      onBack={handleBack}
      onNext={handleNext}
      onSkip={handleSkip}
      onEdit={handleEdit}
      onChange={handleChange}
      onClose={handleClose}
      onSubmit={handleSubmit}
    />
  );
};

SiteWizardSetupContainer.propTypes = {
  isReopening: PropTypes.bool,
  getSharedData: PropTypes.func.isRequired,
  resolveSharedData: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
  onDialogMainAction: PropTypes.func.isRequired,
};

SiteWizardSetupContainer.defaultProps = {
  isReopening: false,
};

export default SiteWizardSetupContainer;

// TODO: added for PoC testing, should be removed later
window.showSiteSetup = () => dialogs.operations.show(dialogTypes.SITE_WIZARD_SETUP_DIALOG);
