// there're some edge cases
// when element should be included to same bounds list to source element
// event though their bounds differ

import isElementColumn from '../../blocks/helpers/is-element-column';
import isElementWidget from '../../blocks/helpers/is-element-widget';

const COLUMNS_WIDGET_SELECTOR = 'ws-block[widget="columns"]';
const QUOTE_WIDGET_SELECTOR = 'ws-block[widget="quote"]';
const LIST_WITH_ICONS_WIDGET_SELECTOR = 'ws-block[widget="list-with-icons"]';
const FOLLOW_US_WIDGET_SELECTOR = 'ws-block[widget="follow-us"]';

// 1. when it is columns widget with only one column inside
// to make possible to delete columns widget
const shouldIncludeColumnsWidgetForLastColumn = (sourceElement, targetElement) => {
  const isColumnsWidget = targetElement.matches(COLUMNS_WIDGET_SELECTOR);

  if (!isColumnsWidget) return false;

  const isLastColumn = sourceElement.matches(
    `${COLUMNS_WIDGET_SELECTOR} ws-columns > ws-column:only-child`
  );

  return isLastColumn;
};

// 2. when it is gallery widget
// to show gallery control pane trigger on gallery item select
const shouldIncludeGalleryWidget = (targetElement) =>
  targetElement.matches('ws-block[widget="gallery"]');

// 3. when it is media container with iframe inside
// to prevent auto activation of one control pane trigger during resize
const shouldIncludeMediaContainerForIframe = (sourceElement, targetElement) => {
  const isIframe = sourceElement.matches('ws-iframe');

  if (!isIframe) return false;

  const isMediaContainer = targetElement.matches('ws-media-container');

  return isMediaContainer;
};

// 4. When a narrower element is placed inside widget but we need
// to show merged controls for both this element and the widget
const shouldIncludeParentWidgetForElement = (sourceElement, targetElement) => {
  const isSourceElementColumn = isElementColumn(sourceElement);
  const isSourceElementWidget = isElementWidget(sourceElement);
  const isTargetElementFirstParentWidget =
    isElementWidget(targetElement) && sourceElement.closest('[widget]') === targetElement;

  // There are widgets with specific layout so we don't need to process it here
  const isTargetElementWidgetToSkip = targetElement.matches(
    `${QUOTE_WIDGET_SELECTOR}, ${FOLLOW_US_WIDGET_SELECTOR}, ${LIST_WITH_ICONS_WIDGET_SELECTOR}`
  );

  return (
    !isSourceElementColumn &&
    !isSourceElementWidget &&
    isTargetElementFirstParentWidget &&
    !isTargetElementWidgetToSkip
  );
};

const shouldBeTakenAsSameBounds = (sourceElement, targetElement) =>
  shouldIncludeColumnsWidgetForLastColumn(sourceElement, targetElement) ||
  shouldIncludeGalleryWidget(targetElement) ||
  shouldIncludeMediaContainerForIframe(sourceElement, targetElement) ||
  shouldIncludeParentWidgetForElement(sourceElement, targetElement);

export default shouldBeTakenAsSameBounds;
