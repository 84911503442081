import React from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { B2CDomainSelector, utils } from '@yola/webapp-plugins';
import { i18next, site } from '@yola/ws-sdk';
import {
  constants as subscriptionManagerConstants,
  selectors as subscriptionSelectors,
} from '@yola/subscription-manager-js';
import segment from 'src/js/modules/analytics/segment';
import common from 'src/js/modules/common';
import dialogs from 'yola-editor/src/js/modules/dialogs';
import actions from 'yola-editor/src/js/modules/upsell/actions';
import features from 'yola-editor/src/js/modules/features';
import beforeUnloadListener from 'yola-editor/src/js/utils/before-unload-listener';
import getUserPreferences from 'yola-editor/src/js/modules/user/selectors/user-preferences';
import getSiteId from 'yola-editor/src/js/modules/auth/selectors/site-id';
import getSiteTemplateSlug from 'yola-editor/src/js/modules/site/selectors/site-template-slug';
import { redirectToUrl } from 'yola-editor/src/js/router/utils/redirect-to-url';
import useUpsellSettings from 'yola-editor/src/js/modules/upsell/hooks/use-upsell-settings';
import getSiteData from 'yola-editor/src/js/modules/site/selectors/site-data';
import convertStringToDomainName from 'yola-editor/src/js/modules/upsell/helpers/convert-string-to-domain-name';
import useDomainsExtraPrice, {
  extraPrices,
} from 'yola-editor/src/js/modules/upsell/hooks/use-domains-extra-price';
import getPurchaseFlowType from 'yola-editor/src/js/modules/upsell/helpers/get-purchase-flow-type';
import { CUSTOM_DOMAIN } from 'yola-editor/src/js/modules/upsell/constants/upsell-types';
import subscriptionTerms from 'yola-editor/src/js/modules/upsell/constants/subscription-terms';
import publishing from 'yola-editor/src/js/modules/publishing';
import routesMap from 'yola-editor/src/js/router/helpers/routes-map';
import actionIds from '../constants/domain-purchase-action-ids';
import usePurchasedDomainsData from '../hooks/use-purchased-domains-data';
import { MAP_TERM_TO_READABLE_NAME } from '../constants/product-term';

const { DOMAIN_SUBSCRIPTION } = subscriptionManagerConstants.SubscriptionType;
const { MAP_TERM_NAME_TO_SUBSCRIPTION_TERM } = subscriptionTerms;

const getPublishUrl = (domain) =>
  `${window.location.origin}${routesMap.publish.url()}?domain=${domain}`;

function B2cDomainPurchaseDialogContainer(props) {
  const { constants, track, trackAsync } = segment;
  const {
    triggerId,
    subscription,
    domainNameSuggestion,
    withFreeSubdomain,
    withPurchasedDomains,
    isPublishOnSuccess,
    captionsOverrides,
    onClose,
  } = props;
  const dispatch = useDispatch();
  const { currency } = useSelector(getUserPreferences);
  const appName = constants.common.APP_NAME;
  const siteId = useSelector(getSiteId);
  const templateBuildSlug = useSelector(getSiteTemplateSlug);
  const purchaseFlowType = useSelector(getPurchaseFlowType);
  const siteData = useSelector(getSiteData, shallowEqual);
  const { name: siteName } = siteData;
  const siteSettings = useSelector(site.selectors.getSettings);
  const packageType = useSelector(subscriptionSelectors.getCurrentPackageType);
  const { businessName } = siteSettings;
  const isSubscriptionPlanSelected = Boolean(subscription.term && subscription.plan);
  const isAnnulSubscriptionSelected = subscription.term === MAP_TERM_TO_READABLE_NAME.perYear;
  const extraPriceType = useDomainsExtraPrice(
    isSubscriptionPlanSelected,
    isAnnulSubscriptionSelected
  );
  const purchasedDomainsData = usePurchasedDomainsData(withPurchasedDomains);

  const limits = features.accessors.getCurrentPackageFeatureLimits();
  const customDomainFeature = limits.customDomain;
  const upsellSettings = useUpsellSettings({
    isFreeDomainAvailable: extraPrices.NO_DISCOUNTS === extraPriceType,
  });

  const trackingData = {
    appName,
    siteId,
    templateBuildSlug,
  };

  const trackUpgradeInitiated = trackAsync(constants.events.UPGRADE_INITIATED, {
    ...trackingData,
    premiumFeature: CUSTOM_DOMAIN,
    purchaseFlowType,
    productCategory: constants.common.EDITOR_PRODUCT_CATEGORY,
  });

  const trackDomainPurchaseClicked = ({ domain, searchString, actionId }) => {
    const indexOfDomainTypeSeparator = domain.indexOf('.');
    const domainNameSelected = domain.substring(0, indexOfDomainTypeSeparator);
    const domainTypeSelected = domain.substring(indexOfDomainTypeSeparator + 1);

    track(constants.events.DOMAIN_PURCHASE_CLICKED, {
      ...trackingData,
      triggerId,
      domainName: searchString,
      domainNameSelected,
      domainTypeSelected,
      actionId,
      activeHostingSubscription: packageType,
    });
  };

  const onSelect = ({ domain, searchString }) => {
    trackDomainPurchaseClicked({ domain, searchString, actionId: actionIds.BUY_DOMAIN });

    if (customDomainFeature.available || isSubscriptionPlanSelected) {
      beforeUnloadListener.remove();

      dispatch(
        dialogs.actions.show(dialogs.dialogTypes.PENDING, {
          captions: {
            title: i18next.t('Loading...'),
          },
        })
      );

      const paymentParams = {
        products: [isSubscriptionPlanSelected && subscription.plan, DOMAIN_SUBSCRIPTION].filter(
          Boolean
        ),
        term: MAP_TERM_NAME_TO_SUBSCRIPTION_TERM[subscription.term],
        domain_name: domain,
        latitude_site_id: siteId,
        ...(isPublishOnSuccess && { success_url: getPublishUrl(domain) }),
      };
      const paymentUrl = utils.generatePaymentUrl(paymentParams);

      if (isSubscriptionPlanSelected) {
        trackUpgradeInitiated.then(() => redirectToUrl(paymentUrl));
        return;
      }

      redirectToUrl(paymentUrl);
    } else {
      upsellSettings.customDomain
        .onUpgrade({
          domainName: domain,
          description: i18next.t('To use your custom domain, please select a feature package'),
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          console.log('Upgrade flow was canceled');
        });
    }
  };

  const redirectToPaymentPageWithSubscription = (domain) => {
    beforeUnloadListener.remove();

    dispatch(
      dialogs.actions.show(dialogs.dialogTypes.PENDING, {
        captions: {
          title: i18next.t('Loading...'),
        },
      })
    );

    const paymentParams = {
      products: [subscription.plan],
      term: MAP_TERM_NAME_TO_SUBSCRIPTION_TERM[subscription.term],
      latitude_site_id: siteId,
      ...(isPublishOnSuccess && { success_url: getPublishUrl(domain) }),
    };

    trackUpgradeInitiated.then(() => redirectToUrl(utils.generatePaymentUrl(paymentParams)));
  };

  const publishOrRedirectToPaymentPage = (domain) => {
    dispatch(dialogs.actions.hide());

    if (isSubscriptionPlanSelected) {
      redirectToPaymentPageWithSubscription(domain);
      return;
    }

    dispatch(publishing.actions.publishWebsite(domain));
  };

  const onFreeSubdomainSelect = ({ domain, searchString }) => {
    trackDomainPurchaseClicked({
      domain,
      searchString,
      actionId: actionIds.GET_FREE_DOMAIN,
    });

    publishOrRedirectToPaymentPage(domain);
  };

  const onPurchaseDomainSelect = ({ domain, searchString }) => {
    trackDomainPurchaseClicked({
      domain,
      searchString,
      actionId: actionIds.USE_DOMAIN,
    });

    publishOrRedirectToPaymentPage(domain);
  };

  const handleClose = () => {
    track(constants.events.DOMAIN_PURCHASE_DIALOG_CANCELED, {
      ...trackingData,
      triggerId,
    });

    dispatch(dialogs.actions.hide());
    onClose();
  };

  let onNext = null;

  if (withFreeSubdomain || isSubscriptionPlanSelected) {
    onNext = ({ domain }) => {
      track(constants.events.DOMAIN_PURCHASE_NOT_NOW_CLICKED, {
        ...trackingData,
        triggerId,
      });

      if (withFreeSubdomain && domain) {
        publishOrRedirectToPaymentPage(domain);
        return;
      }
      if (isSubscriptionPlanSelected) {
        redirectToPaymentPageWithSubscription();
      }
    };
  }

  const onDomainSearchFailure = () => {
    dispatch(actions.getDomainListFailure());

    track(constants.events.DOMAIN_SEARCH_FAILED, {
      ...trackingData,
      triggerId,
    });
  };

  const onSearchStart = ({ searchString, domainsRequestOptions }) => {
    if (domainsRequestOptions && domainsRequestOptions.userAction) {
      track(constants.events.DOMAIN_PURCHASE_SEARCH_CLICKED, {
        ...trackingData,
        triggerId,
        domainName: searchString,
      });
    }
  };

  const onDomainFieldChange = (option) => {
    if (option && option.tld) {
      track(constants.events.DOMAIN_PURCHASE_AUTOCOMPLETE_DOMAIN_SELECTED, {
        ...trackingData,
        triggerId,
        domainName: option.value,
        tld: option.tld,
      });
    }
  };

  const onSearchPerformed = ({ searchString, searchResults, domainsRequestOptions }) => {
    const availableDomains = searchResults.filter(({ available }) => available);
    const isSearchedDomainAvailable = availableDomains.some(({ domain }) => {
      const domainName = domain.substring(0, domain.indexOf('.'));
      return searchString === domain || searchString === domainName;
    });
    const suggestedDomainsAvailable = availableDomains.length;
    const suggestedDomainsTaken = searchResults.length - availableDomains.length;

    track(constants.events.DOMAIN_PURCHASE_SEARCH_PERFORMED, {
      ...trackingData,
      triggerId,
      domainName: searchString,
      isSearchedDomainAvailable,
      suggestedDomainsAvailable,
      suggestedDomainsTaken,
      searchClicked: Boolean(domainsRequestOptions && domainsRequestOptions.userAction),
    });
  };

  return (
    <B2CDomainSelector
      currency={currency}
      appElementSelector={common.constants.common.APP_ELEMENT_SELECTOR}
      onSelect={onSelect}
      onClose={handleClose}
      onNext={onNext}
      onDomainFieldChange={onDomainFieldChange}
      domainName={domainNameSuggestion || convertStringToDomainName(businessName || siteName)}
      onDomainSearchFailure={onDomainSearchFailure}
      onSearchStart={onSearchStart}
      onSearchPerformed={onSearchPerformed}
      extraPrice={extraPriceType}
      siteId={siteId}
      withFreeSubdomain={withFreeSubdomain}
      onFreeSubdomainSelect={onFreeSubdomainSelect}
      purchasedDomainsData={purchasedDomainsData}
      onPurchaseDomainSelect={onPurchaseDomainSelect}
      captionsOverrides={captionsOverrides}
    />
  );
}

B2cDomainPurchaseDialogContainer.defaultProps = {
  subscription: {},
  domainNameSuggestion: '',
  withFreeSubdomain: false,
  withPurchasedDomains: false,
  isPublishOnSuccess: false,
  captionsOverrides: {},
  onClose: Function.prototype,
};

B2cDomainPurchaseDialogContainer.propTypes = {
  triggerId: PropTypes.string.isRequired,
  subscription: PropTypes.shape({ term: PropTypes.string, plan: PropTypes.string }),
  domainNameSuggestion: PropTypes.string,
  withFreeSubdomain: PropTypes.bool,
  withPurchasedDomains: PropTypes.bool,
  isPublishOnSuccess: PropTypes.bool,
  captionsOverrides: PropTypes.shape({ closeButton: PropTypes.string }),
  onClose: PropTypes.func,
};

export default B2cDomainPurchaseDialogContainer;
