import traqing from '@yola/traqing.js';
import common from './constants/common';

const track = (event, properties) => {
  if (!event) return;

  const { APP_NAME } = common;
  const props = { ...properties };

  if (props && !props.appName) {
    props.appName = APP_NAME;
  }

  traqing.segment.track(event, props);
};

export default track;
