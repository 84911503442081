const srcAttributeName = 'src';

const flatten = (items) => items.reduce((acc, item) => acc.concat(item), []);

const getStaticAssetsCategoryItems = (categoryItems) => {
  const categories = categoryItems.map(({ items }) => items);

  const elements = flatten(categories).map(({ html }) => {
    const node = document.createElement('div');
    node.innerHTML = html;

    const relatedElements = node.querySelectorAll(`[${srcAttributeName}]`);

    return Array.from(relatedElements);
  });

  return flatten(elements)
    .map((element) => element.getAttribute(`${srcAttributeName}`))
    .filter(Boolean);
};

export default getStaticAssetsCategoryItems;
