import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import prepareDialogSubmittedEventTraits from '../helpers/prepare-dialog-submitted-event-traits';

const trackWebsiteSettingsDialogSubmitted = ({
  onDialogMainAction,
  initialValues,
  settingsForUpdate,
  integrationsForUpdate,
}) => {
  const traits = prepareDialogSubmittedEventTraits(
    initialValues,
    settingsForUpdate,
    integrationsForUpdate
  );

  onDialogMainAction({
    dialogType: dialogTypes.WEBSITE_SETTINGS,
    ...traits,
  });
};

export default trackWebsiteSettingsDialogSubmitted;
