import React from 'react';
import PropTypes from 'prop-types';
import { i18next } from '@yola/ws-sdk';
import GenericDialog from 'yola-editor/src/js/modules/dialogs/components/generic-dialog';
import shareOptions from '../constants/share-options';
import helpers from '../helpers';
import ShareButtonsGroup from './share-buttons-group';
import ShareButton from './share-button';
import TextToCopy from './text-to-copy';

const { FACEBOOK, LINKEDIN, TWITTER, EMAIL, MANUAL_INVITE } = shareOptions;

function ShareDialog({ urls, invitationMessage, onAfterShare, onClose, captions, onDialogCancel }) {
  const shareButtonsProps = {
    [FACEBOOK]: {
      className: 'ws-share-button--facebook',
      glyph: 'facebook',
      label: i18next.t('On Facebook'),
      shareUrl: helpers.generateFacebookShareLink(urls[FACEBOOK]),
      onClick: () => onAfterShare(FACEBOOK),
    },
    [LINKEDIN]: {
      className: 'ws-share-button--linkedin',
      glyph: 'linkedin',
      label: i18next.t('On LinkedIn'),
      shareUrl: helpers.generateLinkedinShareLink(urls[LINKEDIN]),
      onClick: () => onAfterShare(LINKEDIN),
    },
    [TWITTER]: {
      className: 'ws-share-button--twitter',
      glyph: 'twitter',
      label: i18next.t('On Twitter'),
      shareUrl: helpers.generateTwitterShareLink(urls[TWITTER], invitationMessage),
      onClick: () => onAfterShare(TWITTER),
    },
    [EMAIL]: {
      glyph: 'mail',
      glyphSize: 22,
      label: i18next.t('Via e-mail'),
      shareUrl: helpers.generateEmailShareLink(urls[EMAIL], invitationMessage),
      onClick: () => onAfterShare(EMAIL),
    },
  };

  const handleClose = (...args) => {
    onDialogCancel();
    onClose(...args);
  };

  return (
    <GenericDialog
      className="ws-share-dialog"
      captions={{ secondaryAction: i18next.t('Close') }}
      onSecondaryAction={handleClose}
      onClose={handleClose}
    >
      <div className="dialog-body__title">{captions.title}</div>
      <div className="dialog-body__content">{captions.description}</div>
      <ShareButtonsGroup>
        <ShareButton {...shareButtonsProps[FACEBOOK]} />
        <ShareButton {...shareButtonsProps[LINKEDIN]} />
        <ShareButton {...shareButtonsProps[TWITTER]} />
        <ShareButton {...shareButtonsProps[EMAIL]} />
      </ShareButtonsGroup>

      <TextToCopy
        text={urls[MANUAL_INVITE]}
        copiedTextFormatter={(url) => `${invitationMessage.trim()} ${url}`}
        onAfterCopy={() => onAfterShare(MANUAL_INVITE)}
      />
    </GenericDialog>
  );
}

ShareDialog.propTypes = {
  urls: PropTypes.shape({
    facebook: PropTypes.string,
    twitter: PropTypes.string,
    linkedin: PropTypes.string,
    email: PropTypes.string,
    'manual-invite': PropTypes.string,
  }).isRequired,
  invitationMessage: PropTypes.string.isRequired,
  onAfterShare: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  captions: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

export default ShareDialog;
