import { LIVE_PREVIEW_MOCKED_ID } from 'src/js/modules/blocks/constants/common';
import { blocks } from '@yola/ws-sdk';
import injectGlobals from 'src/js/modules/blocks/helpers/inject-globals';
import compileBlockNode from './compile-block-node';
import replaceOptionalChildren from './replace-optional-children';
import handleBackgroundImage from './handle-background-image';
import getChildrenToUpdate from './get-children-to-update';
import handleBackgroundImageAsFallback from './handle-background-image-as-fallback';
import handleBlockMediaImageAsFallback from './handle-block-media-image-as-fallback';
import compileLivePreviewNode from '../block-settings/compile-live-preview-node';
import dropTextResizing from './drop-text-resizing';
import { REGULAR_IMAGE_ATTRIBUTES_FOR_SHALLOW_PROXY } from '../../constants/common';
import { OVERLAY_OPACITY_ATTRIBUTE, OVERLAY_ATTRIBUTE } from '../../constants/display-options';
import optionTypes from '../../constants/background-option-types';
import copyDisplayOptionByAttribute from '../copy-display-option-by-attribute';
import setMatchingSurface from './set-matching-surface';

const { BACKGROUND_IMAGE_ATTRIBUTE } = optionTypes;

const layoutBlockItemPreprocess = (
  block,
  {
    sortBlockVariationId,
    selectedLayoutVariationId,
    displayOptions,
    backgroundOptions,
    cachedBackgroundOptions,
    refId,
    originalStaticBlockNode,
    originalBlockNode,
    compiledNodesCache,
    selectedPreviewNode,
    originalVariationId,
    optionalChildrenState,
  }
) => {
  const { id, html, variationId } = block;
  const markup = injectGlobals(html);
  const cachedDestinationBlockNode = compiledNodesCache.get(variationId);

  let destinationBlockNode;

  const isLivePreview = variationId === LIVE_PREVIEW_MOCKED_ID;
  const isOriginalVariationId = variationId === originalVariationId;

  if (isLivePreview) {
    destinationBlockNode = compileLivePreviewNode({
      optionalChildren: optionalChildrenState,
      sortBlockVariationId,
      selectedLayoutVariationId,
      displayOptions,
      selectedPreviewNode,
      backgroundOptions,
    });
  } else {
    destinationBlockNode =
      cachedDestinationBlockNode || compileBlockNode(markup, id, variationId, refId);
  }

  if (!cachedDestinationBlockNode) {
    compiledNodesCache.set(variationId, destinationBlockNode);
  }

  const originalOptionalChildren = blocks.accessors.getOptionalChildrenData(originalBlockNode);
  const destinationOptionalChildren =
    blocks.accessors.getOptionalChildrenData(destinationBlockNode);

  const toUpdate = getChildrenToUpdate(originalBlockNode, destinationBlockNode);

  const attributesForShallowProxy =
    !isLivePreview && !isOriginalVariationId ? REGULAR_IMAGE_ATTRIBUTES_FOR_SHALLOW_PROXY : [];

  replaceOptionalChildren(
    toUpdate,
    originalStaticBlockNode,
    destinationBlockNode,
    attributesForShallowProxy
  );

  [OVERLAY_OPACITY_ATTRIBUTE, OVERLAY_ATTRIBUTE].forEach((attribute) =>
    copyDisplayOptionByAttribute({
      displayOptions,
      destinationBlockNode,
      attribute,
    })
  );

  if (selectedPreviewNode.hasAttribute(BACKGROUND_IMAGE_ATTRIBUTE)) {
    setMatchingSurface({ displayOptions, destinationBlockNode });
  }

  const backgroundHandledSuccessfully = handleBackgroundImage(
    destinationBlockNode,
    backgroundOptions,
    variationId,
    cachedBackgroundOptions
  );

  if (!backgroundHandledSuccessfully) {
    handleBackgroundImageAsFallback(
      originalStaticBlockNode,
      destinationBlockNode,
      originalOptionalChildren,
      destinationOptionalChildren,
      backgroundOptions
    );
  }

  handleBlockMediaImageAsFallback(
    originalStaticBlockNode,
    destinationBlockNode,
    originalOptionalChildren,
    destinationOptionalChildren,
    backgroundOptions
  );

  if (!isLivePreview && !isOriginalVariationId) {
    dropTextResizing(destinationBlockNode);
  }

  return destinationBlockNode.outerHTML;
};

export default layoutBlockItemPreprocess;
