import customUI from 'src/js/modules/custom-ui';
import InlineImageContainer from 'src/js/modules/focal-point/containers/inline-image-container';
import BackgroundImageContainer from 'src/js/modules/focal-point/containers/background-image-container';

const initFocalPoint = () => {
  customUI.operations.register(
    customUI.customUiTypes.EDIT_INLINE_IMAGE_FOCAL_POINT,
    InlineImageContainer,
    {
      disableScrollSelector: '.ws-focal-point__overlay',
      blurHoveredElement: true,
    }
  );

  customUI.operations.register(
    customUI.customUiTypes.EDIT_BACKGROUND_IMAGE_FOCAL_POINT,
    BackgroundImageContainer,
    {
      disableScrollSelector: '.ws-focal-point__overlay',
      blurHoveredElement: true,
    }
  );
};

export default initFocalPoint;
