import React from 'react';
import PropTypes from 'prop-types';
import ViewWithStaticAssets from './view-with-static-assets';
import Thumbnail from './thumbnail';
import { FORCED_DESKTOP_WIDTH } from '../constants/sizes';
import { scrolledElementType } from '../constants/live-preview';
import { VIEW_CUSTOMIZATION } from '../constants/block-settings';

const BlockLayout = ({
  mounted,
  itemDependencies,
  itemStaticAssets,
  categoriesWithItems,
  onSelected,
  selectedLayoutVariationId,
  itemPreProcessFunction,
  blockLayoutDescription: BlockLayoutDescription,
}) => (
  <div className="ws-block-layout-settings__preview">
    <BlockLayoutDescription />
    <div className="ws-block-layout-settings__content">
      {mounted && (
        <ViewWithStaticAssets
          dependencies={itemDependencies}
          staticAssets={itemStaticAssets}
          forcedWidth={FORCED_DESKTOP_WIDTH}
          scrolledElement={scrolledElementType.WRAPPER}
          customization={VIEW_CUSTOMIZATION}
        >
          <React.Fragment>
            {categoriesWithItems.map((category) =>
              category.items.map((item, index) => (
                <Thumbnail
                  {...(item.isPremium && {
                    marks: [
                      {
                        size: 'small',
                        appearance: 'cta',
                        iconGlyph: 'star',
                      },
                    ],
                  })}
                  blockId={item.id}
                  key={item.uuid}
                  title={item.title}
                  html={itemPreProcessFunction(item)}
                  data-index={index}
                  onClick={onSelected(item.uuid)}
                  renderRealSize
                  disabled={item.disabled}
                  interactive={item.interactive}
                  selected={selectedLayoutVariationId === item.variationId}
                  className="ws-block-layout-settings__thumbnail"
                />
              ))
            )}
          </React.Fragment>
        </ViewWithStaticAssets>
      )}
    </div>
  </div>
);

BlockLayout.propTypes = {
  mounted: PropTypes.bool.isRequired,
  itemDependencies: PropTypes.shape({}).isRequired,
  itemStaticAssets: PropTypes.array.isRequired,
  categoriesWithItems: PropTypes.array.isRequired,
  onSelected: PropTypes.func.isRequired,
  selectedLayoutVariationId: PropTypes.string.isRequired,
  itemPreProcessFunction: PropTypes.func.isRequired,
  blockLayoutDescription: PropTypes.func,
};

BlockLayout.defaultProps = {
  blockLayoutDescription: null,
};

export default BlockLayout;
