import { useState } from 'react';
import useInterval from '../../common/hooks/use-interval';
import constants from '../constants';

const { stepStatuses } = constants;

const getInitialSteps = (steps) =>
  steps.map((name, index) =>
    index === 0
      ? { name, status: stepStatuses.IN_PROGRESS }
      : { name, status: stepStatuses.PENDING }
  );

function useProgressSteps({ initialSteps, estimatedTime }) {
  const [steps, setSteps] = useState(getInitialSteps(initialSteps));

  const imitateProgress = () => {
    const stepInProgress = steps.findIndex(({ status }) => status === stepStatuses.IN_PROGRESS);
    const nextStepInProgress = stepInProgress + 1;

    if (stepInProgress === steps.length - 1) {
      return;
    }

    const updatedSteps = steps.map((step, index) => {
      switch (index) {
        case stepInProgress:
          return { ...step, status: stepStatuses.COMPLETE };
        case nextStepInProgress:
          return { ...step, status: stepStatuses.IN_PROGRESS };
        default:
          return step;
      }
    });

    setSteps(updatedSteps);
  };

  const progressImitationIntervalRef = useInterval(imitateProgress, estimatedTime / steps.length);

  const stopProgressSteps = () => {
    clearInterval(progressImitationIntervalRef.current);

    setSteps((currentSteps) =>
      currentSteps.map((step) => ({ ...step, status: stepStatuses.COMPLETE }))
    );
  };

  return {
    steps,
    stopProgressSteps,
  };
}

export default useProgressSteps;
