import { createContext } from 'react';

const BlocksLibraryContext = createContext({
  categories: null,
  activeCategory: null,
  searchValue: null,
  submittedSearchValue: null,
  blocksList: null,
  surface: null,
  onSearch: () => {},
  onSearchValueChange: () => {},
  onSearchValueClear: () => {},
  onSurfaceSelect: () => {},
  onCategorySelect: () => {},
  onBlockSelect: () => {},
  onBannerSelect: () => {},
  onClose: () => {},
  onCloseContentSectionMobile: () => {},
});

export default BlocksLibraryContext;
