import actionTypes from '../constants/action-types';

const publishWebsite = (vhostName = null) => ({
  type: actionTypes.PUBLISH_WEBSITE,
  payload: {
    vhostName,
  },
});

export default publishWebsite;
