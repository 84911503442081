import { hdrm } from '@yola/ws-sdk';
import matchBlockElement from './match-block-element';

const getClosestBlockElement = (sourceElement) => {
  let element = sourceElement;

  while (element) {
    if (matchBlockElement(element)) {
      return element;
    }

    const elementId = hdrm.instance.driver.referenceMap.getParentReferenceId(element);
    element = hdrm.accessors.getLiveElement(elementId);
  }

  return null;
};

export default getClosestBlockElement;
