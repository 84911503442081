import React from 'react';
import PropTypes from 'prop-types';
import { i18next, utils } from '@yola/ws-sdk';

import { Text, Field, InputField, CheckboxField } from '@yola/ws-ui';

import isGoogleMapsShortLink from '../helpers/is-google-maps-short-link';

const LinkForm = ({ isFullscreenAllowed, shouldValidate }) => (
  <React.Fragment>
    <div className="ws-modal__block">
      <Text type="heading-6" theme="grey">
        {i18next.t('Embed URL')}
      </Text>
      <Field
        name="urlValue"
        component={InputField}
        validate={(value) => {
          if (shouldValidate && (!utils.validation.isURL(value) || isGoogleMapsShortLink(value))) {
            return i18next.t('Please enter valid URL');
          }

          return null;
        }}
      />
      <Text type="annotation" theme="grey">
        {i18next.t(
          'Paste the URL of your third-party content here. To embed your content manually, select Embed Code.'
        )}
      </Text>
    </div>

    <div className="ws-modal__block">
      <Field
        name="isFullscreenAllowed"
        component={CheckboxField}
        id="fullscreen-checkbox"
        checked={isFullscreenAllowed}
      >
        {/* eslint-disable-next-line yola/jsx-a11y/label-has-for */}
        <label htmlFor="fullscreen-checkbox">
          <Text type="heading-6">{i18next.t('Allow full screen')}</Text>
        </label>
      </Field>
    </div>
  </React.Fragment>
);

LinkForm.propTypes = {
  isFullscreenAllowed: PropTypes.bool.isRequired,
  shouldValidate: PropTypes.bool,
};

LinkForm.defaultProps = {
  shouldValidate: true,
};

export default LinkForm;
