import { useLayoutEffect, useState, useCallback } from 'react';
import { view, bowser } from '@yola/ws-sdk';
import useEventListener from 'src/js/modules/utils/custom-hooks/use-event-listener';
import getNavbarHeight from '../../navbar/accessors/get-height';

const HIGHLIGHTER_OFFSET = 7;

const useHighlighter = ({ element, scrollPosition = view.accessors.getScrollPosition() }) => {
  const [highlightRect, setRect] = useState({
    left: 0,
    top: 0,
    width: 0,
    height: 0,
  });

  const updateHighlighterRect = ({ left, top, width, height }) => {
    const liveDocument = view.accessors.getLiveDocument();
    const { innerWidth } = liveDocument.defaultView;
    const candidateWidth = width + HIGHLIGHTER_OFFSET * 2;
    const isFit = candidateWidth < innerWidth;
    const xOffset = isFit ? HIGHLIGHTER_OFFSET : 0;
    const navbarHeight = getNavbarHeight();

    const handleCenter = {
      left: left - xOffset,
      top: top + navbarHeight - HIGHLIGHTER_OFFSET - (bowser.ios ? scrollPosition : 0),
      width: isFit ? candidateWidth : width,
      height: height + HIGHLIGHTER_OFFSET * 2,
    };

    setRect({ ...handleCenter });
  };

  const updateHighlighter = useCallback(
    () => updateHighlighterRect(element.getBoundingClientRect()),
    [element]
  );

  useLayoutEffect(() => updateHighlighter(), [element, scrollPosition, updateHighlighter]);

  useEventListener('resize', updateHighlighter, element.ownerDocument.defaultView);

  return [highlightRect, updateHighlighterRect];
};

export default useHighlighter;
