import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import user from 'yola-editor/src/js/modules/user';
import Render from 'yola-editor/src/js/components/render';
import { Icon } from '@yola/ws-ui';

function YBotAuthContainer() {
  const userData = useSelector(user.selectors.getUserData, shallowEqual);
  const { id: userId, email: userEmail, employee_id: userEmployeeId } = userData;
  const [isShown, changeDisplayStatus] = useState(Boolean(userEmployeeId));

  const onButtonClick = () => {
    changeDisplayStatus(false);
  };

  return (
    <Render if={isShown}>
      <div className="y-bot-auth-massage">
        You are logged in as {userId} ({userEmail}).
        <Icon glyph="x" strokeWidth="1.3" onClick={() => onButtonClick()} />
      </div>
    </Render>
  );
}

export default YBotAuthContainer;
