import actionTypes from '../constants/action-types';

const registeredGroups = (state = [], action) => {
  if (action.type !== actionTypes.REGISTER_ITEMS_GROUP) {
    return state;
  }

  return state.concat(action.payload.group);
};

export default registeredGroups;
