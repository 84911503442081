import getAvailableNonPaidFeatures from './available-non-paid-features';
import getAvailableSubscriptionTypes from './available-subscription-types';
import getUserData from './user-data';
import getPartnerData from './partner-data';
import getUserPreferences from './user-preferences';
import getHomeUrl from './home-url';
import getWhiteLabelStatus from './white-label-status';
import getSupportEmail from './support-email';
import getSupportUrl from './support-url';
import getPublishingType from './publishing-type';
import getPartnerSubmitUserActionUrl from './partner-submit-user-action-url';
import getPartnerUpgradeUrl from './partner-upgrade-url';
import getPartnerBrandedFooter from './partner-branded-footer';
import getPartnerBrandedHeader from './partner-branded-header';
import getPartnerDashboardUrl from './partner-dashboard-url';
import getUserFeatures from './user-features';
import getPartnerProperties from './partner-properties';
import getHtaccessSupported from './htaccess-supported';
import getAvailablePlatformComponents from './available-platform-components';
import getMoneyBackGuarantee from './money-back-guarantee';
import getAvailablePaymentMethods from './available-payment-methods';
import getExternalUpsellTypes from './external-upsell-types';
import getContactSupportUrl from './contact-support-url';
import getPrivacyPolicyUrl from './privacy-policy-url';
import getTermsOfUseUrl from './terms-of-use-url';

export default {
  getAvailableNonPaidFeatures,
  getAvailableSubscriptionTypes,
  getUserData,
  getPartnerData,
  getUserPreferences,
  getHomeUrl,
  getWhiteLabelStatus,
  getPublishingType,
  getSupportEmail,
  getSupportUrl,
  getPartnerSubmitUserActionUrl,
  getPartnerUpgradeUrl,
  getPartnerBrandedFooter,
  getPartnerBrandedHeader,
  getPartnerDashboardUrl,
  getUserFeatures,
  getHtaccessSupported,
  getAvailablePlatformComponents,
  getPartnerProperties,
  getMoneyBackGuarantee,
  getAvailablePaymentMethods,
  getExternalUpsellTypes,
  getContactSupportUrl,
  getPrivacyPolicyUrl,
  getTermsOfUseUrl,
};
