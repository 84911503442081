import { hooks, site, template } from '@yola/ws-sdk';
import constants from '../constants';
import track from '../track';

const trackSiteSaveInitiated = () => {
  hooks.operations.subscribe(hooks.availableHooks.ON_BEFORE_SITE_SAVE, () => {
    track(constants.events.SITE_SAVE_INITIATED, {
      siteId: site.accessors.getSiteId(),
      templateBuildSlug: template.accessors.getBuildSlug(),
    });
  });
};

export default trackSiteSaveInitiated;
