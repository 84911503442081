import store from 'src/js/store';
import getStoreId from '../selectors/store-id';
import ids from '../constants/ids';

const injectStoreId = (node) => {
  const onlineStoreComponent = node.querySelector(ids.ONLINE_STORE_SELECTOR);

  if (!onlineStoreComponent) return;

  const state = store.getState();
  const storeId = getStoreId(state);

  if (storeId) {
    onlineStoreComponent.setAttribute(ids.STORE_ID_ATTRIBUTE, storeId);
  }
};

export default injectStoreId;
