import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReactModal from 'react-modal';
import { errors } from '@yola/ws-sdk';
import constants from 'src/js/modules/common/constants';
import sentry from 'src/js/modules/analytics/sentry';
import store from 'src/js/store';
import userAgent from './user-agent';
import Router from './router/containers/router';
import ErrorBoundary from './error-boundary';
import errorModule from './modules/error';
import initApp from './init-app';

/*
  In order to prevent tracking redundant errors with "Network error"
  message we need to establish global variable on "beforeunload" event
  and use it in "errorHandler" middleware to prevent tracking such error
*/
window.addEventListener('beforeunload', () => {
  // eslint-disable-next-line no-underscore-dangle
  window._ws_resources_unloaded = true;
});

/*
  This listener indicates if a webpage is loaded from the cache.
  In this case we should reload the page.
  It's related to this issue: https://github.com/yola/production/issues/7572
*/
window.addEventListener('pageshow', (event) => event.persisted && window.location.reload());

userAgent.detect();
ReactModal.setAppElement(constants.common.APP_ELEMENT_SELECTOR);

initApp().then(() => {
  ReactDOM.render(
    <Provider store={store}>
      <ErrorBoundary>
        <Router />
      </ErrorBoundary>
    </Provider>,
    document.getElementById('app')
  );
});

const onCriticalError = ({ errorMessage, errorInfo, networkErrorDetails }) => {
  const error = errorModule.helpers.createEditorError({ errorMessage, networkErrorDetails });
  store.dispatch(
    errorModule.actions.setCriticalError({
      error,
      errorInfo,
      logSentry: false,
    })
  );
};

errors.operations.registerCatcher((error, errorData = {}) => {
  console.error(error);

  const { errorInfo, isCritical = true } = errorData;
  const networkErrorDetails = errors.helpers.getNetworkErrorDetails(error);

  if (sentry.instance.isInit()) {
    const sentryInstance = sentry.instance.getInstance();

    if (errorInfo) {
      sentryInstance.withScope((scope) => {
        scope.setExtras(errorInfo);
        sentryInstance.captureException(error);
      });
    } else {
      sentryInstance.captureException(error);
    }

    sentryInstance
      .getCurrentHub()
      .getClient()
      .close(2000)
      .then(() => {
        // eslint-disable-next-line no-unused-expressions
        isCritical &&
          onCriticalError &&
          onCriticalError({ errorMessage: error.message, errorInfo, networkErrorDetails });
      });

    return;
  }

  // eslint-disable-next-line no-unused-expressions
  isCritical &&
    onCriticalError &&
    onCriticalError({ errorMessage: error.message, errorInfo, networkErrorDetails });
});
