import { dialogs } from '@yola/ws-sdk';
import store from 'src/js/store';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import itemIds from 'src/js/modules/help-menu/constants/item-ids';
import { TRIGGER_IDS } from 'src/js/modules/difm-promo/constants/traits';
import yolaEditorDialogs from '../../dialogs';
import trackHelpMenuItemClick from '../trackers/track-help-menu-item-click';
import helpCenter from '../../help-center';

const getHelpMenuItemClickCallbacks = (triggerId) => ({
  requestFeatureOnClick: () => trackHelpMenuItemClick(itemIds.REQUEST_A_FEATURE, triggerId),
  gettingStartedOnClick: () => trackHelpMenuItemClick(itemIds.GETTING_STARTED, triggerId),
  helpCenterOnClick: () => trackHelpMenuItemClick(itemIds.HELP_CENTER, triggerId),
  termsOfUseOnClick: () => trackHelpMenuItemClick(itemIds.TERMS_OF_USE, triggerId),
  privacyPolicyOnClick: () => trackHelpMenuItemClick(itemIds.PRIVACY_POLICY, triggerId),
  contactSupportUrlOnClick: () => trackHelpMenuItemClick(itemIds.CONTACT_SUPPORT, triggerId),
  contactSupportOnClick: () => {
    trackHelpMenuItemClick(itemIds.CONTACT_SUPPORT, triggerId);
    helpCenter.operations.showContactForm();
  },
  tutorialsOnClick: () => {
    trackHelpMenuItemClick(itemIds.TUTORIALS, triggerId);
    helpCenter.operations.showSearchForm();
  },
  welcomeTourOnClick: () => {
    trackHelpMenuItemClick(itemIds.WELCOME_TOUR, triggerId);
    dialogs.operations.show(dialogTypes.WELCOME_TOUR);
  },
  multilingualTourOnClick: () => {
    trackHelpMenuItemClick(itemIds.MULTILINGUAL_TOUR, triggerId);
    dialogs.operations.show(dialogTypes.MULTILINGUAL_TOUR);
  },
  complianceServiceOnClick: () => {
    trackHelpMenuItemClick(itemIds.COMPLIANCE_SERVICE, triggerId);
    store.dispatch(
      yolaEditorDialogs.actions.show(yolaEditorDialogs.dialogTypes.DIFM_PRIVACY_UPSELL_DIALOG, {
        triggerId,
      })
    );
  },
  orderWebsiteOnClick: () => {
    trackHelpMenuItemClick(itemIds.ORDER_A_WEBSITE, triggerId);
    dialogs.operations.show(dialogTypes.DIFM_PROMO_BANNER_DIALOG, {
      triggerId: TRIGGER_IDS.orderWebsite,
    });
  },
});

export default getHelpMenuItemClickCallbacks;
