import { anodum, hdrm, i18next } from '@yola/ws-sdk';

function createPreviewElement(doc, content) {
  const markup = `
    <div class="ws-html-embed-preview">
      <div class="ws-html-embed-preview--caption">
        <p><strong>${i18next.t('HTML Block')}</strong></p>

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
          <polyline points="7 8 3 12 7 16" />
          <line x1="14.5" y1="5" x2="9.5" y2="19" />
          <polyline points="17 8 21 12 17 16" />
        </svg>

        <p>${
          content
            ? i18next.t('Preview or publish your site to execute this code.')
            : i18next.t('Once added, your custom HTML will be rendered here.')
        }</p>
      </div>

      <div class="ws-html-embed-preview--code" ${!content ? 'hidden' : ''}>
        <pre><code></code></pre>
      </div>

      <style>
        .ws-html-embed-preview {
          padding: 0 24px;
          background-color: hsl(216, 20%, 95%);
          font: 14px/24px var(--font-sans-serif-fallback);
          color: #30373d;
          overflow: hidden;
        }

        [data-surface].ws-dark-0 .ws-html-embed-preview,
        [data-surface].ws-dark-1 .ws-html-embed-preview {
          background-color: hsla(216, 20%, 95%, 0.03);
        }

        .ws-html-embed-preview p,
        .ws-html-embed-preview pre {
          margin: 0;
        }

        .ws-html-embed-preview strong {
          font-weight: 500;
        }

        .ws-html-embed-preview--caption {
          display: flex;
          justify-content: center;
          padding: 24px 0;
        }

        .ws-html-embed-preview--caption svg {
          margin: 0 16px;
          fill: none;
          stroke: #30373d;
          stroke-linecap: round;
        }

        [data-surface].ws-dark-0 .ws-html-embed-preview--caption svg,
        [data-surface].ws-dark-1 .ws-html-embed-preview--caption svg {
          stroke: hsla(var(--ws-surface-text-color), var(--ws-surface-text-medium-emphasis-opacity));
        }

        .ws-html-embed-preview--code {
          box-sizing: border-box;
          margin: 0 auto 32px;
          padding: 16px;
          max-width: 770px;
          max-height: 200px;
          background-color: white;
          overflow: hidden;
        }

        .ws-html-embed-preview--code code {
          font-family: var(--font-monospace-fallback);
        }

        @media (max-width: 660px) {
          .ws-html-embed-preview {
            padding: 0 15px;
          }

          .ws-html-embed-preview--caption {
            flex-direction: column;
            align-items: center;
            text-align: center;
          }
        }
      </style>
    </div>
  `;

  const element = anodum.parseElementFromString(markup);

  element.querySelector('code').textContent = content;

  return doc.importNode(element, true);
}

function renderPreview(element) {
  const siteDocument = element.ownerDocument;
  const contentTemplate = element.querySelector('template');
  const content = contentTemplate ? contentTemplate.innerHTML : '';
  const previewElement = createPreviewElement(siteDocument, content);

  hdrm.injections.bindings.mutation.ignore(previewElement);

  element.appendChild(previewElement);
}

export default renderPreview;
