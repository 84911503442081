import React from 'react';
import { extensions, dialogs, i18next } from '@yola/ws-sdk';
import { Icon } from '@yola/ws-ui';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import Trigger from 'src/js/modules/common/components/trigger';
import constants from '../constants';
import parseItemsFromDOM from '../helpers/parse-items-from-dom';

const { common } = constants;

const triggerId = 'social-links';

const control = {
  id: triggerId,
  trigger: (
    <Trigger id={triggerId}>
      <Icon glyph="share-social" size="20" strokeWidth="1.3" />
    </Trigger>
  ),
  get tooltip() {
    return i18next.t('Social links settings');
  },
  priority: 100,
  onTriggerClick: (elementId) => {
    dialogs.operations.show(dialogTypes.SOCIAL_LINKS_SETTINGS_DIALOG, {
      elementId,
      items: parseItemsFromDOM(elementId),
    });
  },
  matches(liveElement) {
    const settings = extensions.accessors.getExtensionSettings(common.slug);
    return liveElement.matches(settings.querySelector);
  },
};

export default control;
