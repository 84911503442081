import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { dialogs, saving, i18next } from '@yola/ws-sdk';
import {
  Text,
  Modal,
  ButtonGroup,
  Button,
  InputField,
  Formik,
  Field,
  PanelGroup,
  Panel,
  ProgressDialog,
} from '@yola/ws-ui';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import { saveSiteWithEffect } from 'src/js/modules/saving/helpers/save-site';

function CreateManualSiteBackup({
  onBackupCreateSuccess,
  onCancel,
  onDialogMainAction,
  onDialogCancel,
}) {
  const dispatch = useDispatch();
  const [isPending, setIsPending] = useState(false);

  const validateName = (name) =>
    !name || !name.trim() ? i18next.t('Site backup name can not be empty') : null;

  const getPromptCaptions = (values) => {
    const getDescription = (backupName) =>
      backupName.trim()
        ? i18next.t('Do you want to create the site backup with the "{backupName}" name?', {
            backupName,
          })
        : i18next.t('Do you want to create the site backup?');

    return {
      title: i18next.t('Create site backup'),
      description: getDescription(values.backupName),
      submit: i18next.t('Create'),
      discard: i18next.t('Discard'),
      cancel: i18next.t('Cancel'),
    };
  };

  const handleSuccess = () => {
    if (onBackupCreateSuccess) {
      onBackupCreateSuccess();
    } else {
      dispatch(dialogs.actions.hide());
    }
  };

  const handleCancel = () => {
    onDialogCancel();
    onCancel();
  };

  const handleError = () => {
    dialogs.operations.show(dialogTypes.SITE_BACKUP_ERROR, {
      captions: {
        title: i18next.t('Site backup creation failed'),
        description: i18next.t('An error occurred while creating your site backup'),
      },
    });
  };

  const createBackup = (values) => {
    onDialogMainAction();
    setIsPending(true);

    const options = { name: values.backupName };

    saveSiteWithEffect(() =>
      dispatch(saving.thunks.createSiteBackup(options)).then(handleSuccess).catch(handleError)
    );
  };

  const pendingProps = {
    className: 'ws-site-backup-pending-dialog',
    overlay: 'visible',
    cancelable: false,
    progressType: 'indeterminate',
    width: 290,
    height: 108,
    captions: {
      title: `${i18next.t('Creating site backup...')}`,
    },
  };

  return (
    <React.Fragment>
      {isPending && <ProgressDialog {...pendingProps} />}

      {!isPending && (
        <Formik initialValues={{ backupName: i18next.t('My site backup') }} onSubmit={createBackup}>
          {({ values, handleSubmit }) => (
            <Modal
              overlay="visible"
              centered
              resizable={false}
              draggable={false}
              className="ws-text-input-dialog"
              width={360}
              height={265}
              minWidth={312}
              minHeight={265}
              showPromptForce
              promptCaptions={getPromptCaptions(values)}
              handleCancel={handleCancel}
              handleSubmit={handleSubmit}
            >
              <PanelGroup height="100%">
                <Panel
                  theme="gray-100"
                  height="48"
                  align="middle"
                  corners="squared"
                  className="ws-panel--shrink ws-add-page__header"
                >
                  <Text type="heading-6">{i18next.t('Create site backup')}</Text>
                </Panel>

                <Panel className="ws-site-backup-create">
                  <Text type="heading-6">{i18next.t('Backup name')}</Text>
                  <Field name="backupName" component={InputField} validate={validateName} />
                  <Text type="annotation" theme="grey">
                    {i18next.t('Give your site backup a name')}
                  </Text>
                </Panel>

                <Panel>
                  <ButtonGroup block stick="top">
                    <Button stretch="block" size="large" onClick={handleSubmit}>
                      {i18next.t('Submit')}
                    </Button>
                    <Button stretch="block" size="large" onMouseDown={handleCancel}>
                      {i18next.t('Cancel')}
                    </Button>
                  </ButtonGroup>
                </Panel>
              </PanelGroup>
            </Modal>
          )}
        </Formik>
      )}
    </React.Fragment>
  );
}

CreateManualSiteBackup.propTypes = {
  onBackupCreateSuccess: PropTypes.func,
  onCancel: PropTypes.func,
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

CreateManualSiteBackup.defaultProps = {
  onBackupCreateSuccess: null,
  onCancel: null,
};

export default CreateManualSiteBackup;
