import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { i18next, integration } from '@yola/ws-sdk';
import { designSystem } from '@yola/ws-ui';
import segment from 'src/js/modules/analytics/segment';
import config from 'src/js/modules/config';
import hasSiteCustomDomain from 'yola-editor/src/js/modules/publishing/verifiers/has-site-custom-domain';
import convertStringToDomainName from 'yola-editor/src/js/modules/upsell/helpers/convert-string-to-domain-name';
import startB2CDomainPurchase from 'yola-editor/src/js/modules/upsell/actions/start-b2c-domain-purchase';
import useDomainsExtraPrice, {
  extraPrices,
} from 'yola-editor/src/js/modules/upsell/hooks/use-domains-extra-price';
import settingTypes from '../constants/setting-types';

const { List, ListItem, Heading, ActionButton, Stack, Paragraph, CtaBanner, Box, Mark } =
  designSystem;

const {
  trackers: { trackEvent },
  constants: { events, triggerIds },
} = segment;

function DomainSection({ formData }) {
  const userData = useSelector(integration.selectors.getUserData);
  const siteURL = useSelector(integration.selectors.getSiteURL);
  const siteName = formData[settingTypes.SITE_NAME];
  const businessName = formData[settingTypes.BUSINESS_NAME];

  const isCustomDomain = useSelector(hasSiteCustomDomain);
  const helpCenterConfig = useSelector(config.selectors.getHelpCenterConfig);
  const myYolaConfig = useSelector(config.selectors.getMyYolaConfig);

  const dispatch = useDispatch();
  const extraPriceType = useDomainsExtraPrice();

  const isB2C = !userData.is_white_label;
  const domainName = convertStringToDomainName(businessName || siteName);

  const tutorials = useMemo(
    () => [
      {
        url: helpCenterConfig.connect_your_domain_url,
        caption: i18next.t('Connecting your domain and website'),
        event: events.CONNECT_DOMAIN_TUTORIAL_LINK_CLICKED,
      },
      {
        url: helpCenterConfig.transfer_your_domain_url,
        caption: i18next.t('Transferring a domain to Yola'),
        event: events.TRANSFER_DOMAIN_TUTORIAL_LINK_CLICKED,
      },
      {
        url: helpCenterConfig.point_your_domain_url,
        caption: i18next.t('Pointing your domain to Yola'),
        event: events.POINT_DOMAIN_TUTORIAL_LINK_CLICKED,
      },
    ],
    [helpCenterConfig]
  );
  const hostname = useMemo(() => {
    if (!siteURL) return null;

    const urlInstance = new URL(siteURL);

    return urlInstance.hostname;
  }, [siteURL]);

  const buyOrGetDomain =
    extraPriceType === extraPrices.NO_DISCOUNTS
      ? i18next.t('Get a free domain')
      : i18next.t('Buy a new domain');

  return (
    <div className="ws-website-settings__domain-section">
      <Stack gap="spacing-m">
        {!isCustomDomain && isB2C && (
          <div>
            <Heading type="heading-6">
              {i18next.t('Don’t forget to connect your custom domain')}
            </Heading>
            <Box marginBottom="spacing-xs" marginTop="spacing-3-xs">
              <Paragraph appearance="medium-emphasis">
                {hostname ? (
                  <React.Fragment>
                    {i18next.t('You’re currently using a basic')}
                    &nbsp;
                    <Mark appearance="high-emphasis">
                      <b>{hostname}</b>
                    </Mark>
                    &nbsp;
                    {i18next.t(
                      `subdomain. While this allows you to publish your site, it's intended for temporary use only. {buyOrGetDomain} or connect your own to give your site a professional look and build trust with your visitors.`,
                      { buyOrGetDomain }
                    )}
                  </React.Fragment>
                ) : (
                  i18next.t(
                    '{buyOrGetDomain} or connect your own to give your site a professional look and build trust with your visitors.',
                    { buyOrGetDomain }
                  )
                )}
              </Paragraph>
            </Box>
            <CtaBanner
              title={
                <React.Fragment>
                  {i18next.t('Claim your perfect domain:')}
                  &nbsp;
                  <Mark appearance="cta">www.{domainName}.com</Mark>
                  &nbsp;
                  {i18next.t('may be available')}
                </React.Fragment>
              }
              animate={false}
              iconGlyph="domain"
              appearance="default-cta"
              ctaButton={{
                label:
                  extraPriceType === extraPrices.NO_DISCOUNTS
                    ? i18next.t('Get a free domain')
                    : i18next.t('Buy a domain'),
                onClick: () =>
                  dispatch(startB2CDomainPurchase(triggerIds.WEBSITE_SETTINGS, domainName)),
              }}
            />
          </div>
        )}
        <div>
          <Heading type="heading-6">
            {i18next.t('These tutorials can help you manage your domains:')}
          </Heading>
          <div className="ws-website-settings__domain-section-list-wrap">
            <List>
              {tutorials.map((item) => (
                <ListItem key={item.url}>
                  <a
                    href={item.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      trackEvent(item.event);
                    }}
                  >
                    {item.caption}
                  </a>
                </ListItem>
              ))}
            </List>
          </div>
          <ActionButton
            label={i18next.t('Manage domains')}
            onClick={() => {
              trackEvent(events.MANAGE_DOMAINS_BUTTON_CLICKED);
              open(myYolaConfig.domains_url, 'blank');
            }}
          />
        </div>
      </Stack>
    </div>
  );
}

DomainSection.propTypes = {
  formData: PropTypes.shape().isRequired,
};

export default DomainSection;
