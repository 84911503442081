/**
 * We need such store manager in order to make it possible to implement accessors.
 * Accessor - it's abstraction around redux selector which allows to get data
 * without direct access to the state. Without such abstraction webpack can't resolve
 * import when we try to directly import store to the accessor file.
 */

let connectedStore;

const set = (store) => {
  connectedStore = store;
};

const get = () => connectedStore;

const applySelector = (selector) => {
  const state = connectedStore.getState();

  return selector(state);
};

export default {
  set,
  get,
  applySelector,
};
