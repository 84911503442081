import React from 'react';
import { useSelector } from 'react-redux';
import { site } from '@yola/ws-sdk';
import PreviewContainer from './preview-container';
import getPreviewVhost from '../selectors/preview-vhost';

const PreviewModeContainer = () => {
  const previewVhost = useSelector(getPreviewVhost);
  const activePageId = useSelector(site.selectors.getActivePageId);
  const pageMeta = useSelector((state) => site.selectors.getPage(state, activePageId));

  if (!previewVhost) return null;

  return <PreviewContainer origin={`https://${previewVhost}`} path={pageMeta.path} />;
};

export default PreviewModeContainer;
