function getDragPreviewItemStackOrder({ currentItemId, draggedItemId, draggedItems }) {
  const isCurrentDragged = currentItemId === draggedItemId;

  // The dragged item should be displayed always on top
  if (isCurrentDragged) return 0;

  // All the rest items should be displayed as staked below the dragged one
  const restDraggedItems = draggedItems.filter((item) => item.id !== draggedItemId);
  return restDraggedItems.findIndex((item) => item.id === currentItemId) + 1;
}

export default getDragPreviewItemStackOrder;
