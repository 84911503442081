import actionTypes from '../constants/action-types';

const requestFeatureAvailability = (
  featureName,
  details = {},
  resolve = Function.prototype,
  reject = Function.prototype
) => ({
  type: actionTypes.REQUEST_FEATURE_AVAILABILITY,
  payload: {
    featureName,
    details,
    resolve,
    reject,
  },
});

export default requestFeatureAvailability;
