import { view } from '@yola/ws-sdk';
import getOuterDropTargetElement from './get-outer-drop-target-element';
import positionTypes from '../constants/position-types';

const { VERTICAL, HORIZONTAL } = view.elementFlowTypes;

const getInnerDropTargetElementAndPosition = (draggedElement, element, x, y) => {
  const { top, left, height, width } = element.getBoundingClientRect();
  const leftEdge = left + 0.2 * width;
  const topEdge = top + 0.2 * height;
  const rightEdge = left + 0.8 * width;
  const bottomEdge = top + 0.8 * height;

  if (x > leftEdge && x < rightEdge && y > topEdge && y < bottomEdge) {
    return {
      dropTargetElement: element,
      position: positionTypes.BEFORE_END,
    };
  }

  const outerDropTargetElement = getOuterDropTargetElement(draggedElement, element);

  if (!outerDropTargetElement) {
    return {
      dropTargetElement: element,
      position: positionTypes.BEFORE_END,
    };
  }

  const flowType = view.helpers.getElementFlowType(outerDropTargetElement);

  if ((flowType === HORIZONTAL && x <= leftEdge) || (flowType === VERTICAL && y <= topEdge)) {
    return {
      dropTargetElement: outerDropTargetElement,
      position: positionTypes.BEFORE_BEGIN,
    };
  }

  if ((flowType === HORIZONTAL && x >= bottomEdge) || (flowType === VERTICAL && y >= bottomEdge)) {
    return {
      dropTargetElement: outerDropTargetElement,
      position: positionTypes.AFTER_END,
    };
  }

  return {
    dropTargetElement: element,
    position: positionTypes.BEFORE_END,
  };
};

export default getInnerDropTargetElementAndPosition;
