import React from 'react';

const DropListIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="144" height="90" viewBox="0 0 144 90" fill="none">
    <rect x="20" y="33" width="104" height="24" rx="4" fill="#DADDE0" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M110.311 42.9779C110.565 42.724 110.977 42.724 111.23 42.9779L113.896 45.6433L116.561 42.9779C116.815 42.724 117.227 42.724 117.48 42.9779C117.734 43.2317 117.734 43.6433 117.48 43.8971L114.355 47.0221C114.234 47.144 114.068 47.2125 113.896 47.2125C113.723 47.2125 113.558 47.144 113.436 47.0221L110.311 43.8971C110.057 43.6433 110.057 43.2317 110.311 42.9779Z"
      fill="#646C75"
    />
    <rect x="28" y="42" width="48" height="6" rx="2" fill="#646C75" />
  </svg>
);

export default DropListIcon;
