import actionTypes from '../constants/action-types';

const setSiteName = (siteName) => ({
  type: actionTypes.SET_SITE_NAME,
  payload: {
    siteName,
  },
});

export default setSiteName;
