import { PANE_DEFAULT_INDENT } from '../constants/sizes';

const getPaneX = (position, { elLeft, elWidth, elRight, targetElementBounds }) => {
  const variants = {
    elMiddle: () => elLeft + elWidth / 2,
    elRight: () => elLeft + elWidth + PANE_DEFAULT_INDENT + (targetElementBounds.right - elRight),
    elLeft: () => targetElementBounds.left - PANE_DEFAULT_INDENT,
  };

  return variants[position]();
};

export default getPaneX;
