import { hdrm } from '@yola/ws-sdk';
import getGdprCheckboxQuerySelector from './get-gdpr-checkbox-query-selector';
import constants from '../constants';

const { attributes, common } = constants;

const setDerivedAttributes = (staticElement, data) => {
  const gdprQuerySelector = getGdprCheckboxQuerySelector();
  const emails = data.emails.map((email) => email.value);

  hdrm.helpers.updateChildrenPresence(
    staticElement,
    gdprQuerySelector,
    data.shouldDisplayGdpr,
    common.GDPR_SETTINGS_ID
  );

  staticElement.setAttribute(attributes.SUCCESS_MESSAGE, data.successMessage);
  if (emails.length) {
    staticElement.setAttribute(attributes.EMAILS, emails.join(', '));
  }
};

export default setDerivedAttributes;
