import { constraints, hdrm } from '@yola/ws-sdk';
import matchBlockElement from '../../common/helpers/match-block-element';

const COLUMNS_WIDGET_SELECTOR = 'ws-block[widget="columns"]';

const isColumnsWidgetWithMultipyColumns = (element) =>
  element.matches(COLUMNS_WIDGET_SELECTOR) &&
  element.querySelector(`${COLUMNS_WIDGET_SELECTOR} ws-columns > ws-column:not(:only-child)`);

const getClosestInteractiveOnHoverElement = (sourceElement) => {
  let element = sourceElement;

  while (element) {
    if (
      constraints.verifiers.canBeInteracted(element) &&
      !constraints.verifiers.canBeSelected(element) &&
      !matchBlockElement(element) &&
      !isColumnsWidgetWithMultipyColumns(element)
    ) {
      return element;
    }

    const elementId = hdrm.instance.driver.referenceMap.getParentReferenceId(element);
    element = hdrm.accessors.getLiveElement(elementId);
  }

  return null;
};

export default getClosestInteractiveOnHoverElement;
