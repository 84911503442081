import { linkEditing } from '@yola/ws-sdk';

const parseItemsFromDOM = (
  element,
  menuSelector,
  itemSelector,
  selectors,
  items = [],
  autocollapsingAttribute = 'auto-collapse',
  order
) => {
  element.querySelectorAll(`${menuSelector} > ${itemSelector}`).forEach((item, i) => {
    let currentPosition;
    if (order) {
      currentPosition = `${order}.${i + 1}`;
    } else {
      currentPosition = items.length ? `${parseInt(items[items.length - 1].order, 10) + 1}` : '1';
    }

    if (!item.hasAttribute(autocollapsingAttribute)) {
      const linkSelector = selectors.itemLink;
      const textSelector = selectors.itemText;
      const link = item.querySelector(linkSelector);
      const textContainer = linkSelector !== textSelector ? link.querySelector(textSelector) : link;
      const id = items.length + 1;

      items.push({
        title: textContainer.textContent.trim(),
        id,
        order: currentPosition,
        config: linkEditing.accessors.getLinkConfig(link),
      });

      // Recursively call function to handle submenu items
      parseItemsFromDOM(
        item,
        `${menuSelector} > ${itemSelector} > ${selectors.submenu}`,
        selectors.submenuItem,
        selectors,
        items,
        autocollapsingAttribute,
        currentPosition
      );
    } else if (item.getAttribute(autocollapsingAttribute) === 'enabled') {
      // Recursively call function to handle autocallapsed menu items
      parseItemsFromDOM(
        item,
        `[${autocollapsingAttribute}] > ${selectors.submenu}`,
        itemSelector,
        selectors,
        items,
        autocollapsingAttribute,
        order
      );
    }
  });

  return items;
};

export default parseItemsFromDOM;
