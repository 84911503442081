import { linkEditing } from '@yola/ws-sdk';
import isGalleryItem from 'src/js/modules/focal-point/helpers/is-gallery-item';
import mediaAttributes from '../constants/media-attributes';

const { linkTypes } = linkEditing.constants;

const getLinkConfig = (linkElement, mediaElement) => {
  if (linkElement) {
    return linkEditing.accessors.getLinkConfig(linkElement);
  }

  if (
    mediaElement &&
    isGalleryItem(mediaElement) &&
    !mediaElement.hasAttribute(mediaAttributes.LIGHTBOX_DISABLE_ATTRIBUTE)
  ) {
    return {
      linkType: linkTypes.VIEW_FULL_SIZE_IMAGE,
    };
  }

  return {
    linkType: linkTypes.NO_ACTION,
  };
};

export default getLinkConfig;
