import React from 'react';
import PropTypes from 'prop-types';
import { i18next, dialogs, bowser } from '@yola/ws-sdk';
import { PanelGroup, Panel, ButtonGroup, Button, Text, DnDContext } from '@yola/ws-ui';
import classNames from 'classnames';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import ModalHeader from './modal-header';
import GalleryItem from './gallery-item';
import constants from '../constants';
import ModalDialog from './modal-dialog';
import hasNoneDefaultImages from '../verifiers/has-none-default-images';
import showKeepDefaultImagesDialog from '../helpers/dialogs/show-keep-default-images';
import AddImagesButton from './add-images-button';

const {
  common: { MODAL_WIDTH, PANEL_HEIGHT, MODAL_HEIGHT },
} = constants;
const { PHOTO_STOCK_DIALOG, GALLERY_DIALOG, GALLERY_PHOTO_STOCK_SAVING_DIALOG } = dialogTypes;

function GalleryDialog({
  onSubmit,
  onCancel,
  items,
  elementId,
  onAddImage,
  onEndItemDrag,
  selectedItemsIds,
  enableSelect,
  onItemClick,
  switchSelectMode,
  onSelectAllItems,
  onDeleteItems,
  onWrongFileType,
}) {
  const modalRef = React.useRef();
  const isEmptyGallery = items.length === 0;

  const modalClassName = classNames('ws-gallery-modal', {
    'ws-gallery-modal--mobile': bowser.mobile,
    'ws-gallery-modal--selectable': enableSelect,
  });

  const mobDevice = bowser.mobile || (bowser.tablet && !bowser.iPadOS);

  return (
    <React.Fragment>
      <ModalDialog
        modalRef={modalRef}
        modalHeight={MODAL_HEIGHT}
        modalWidth={MODAL_WIDTH}
        className={modalClassName}
        handleCancel={onCancel}
        overlay="none"
        handleSubmit={() => {
          // When we use context menu inside modal for mob devices modal event outside modal trigger onSubmit before events from context menu triggers
          if (!mobDevice) {
            onSubmit();
          }
        }}
        isValid={!isEmptyGallery}
      >
        <PanelGroup height="100%">
          <Panel
            height={PANEL_HEIGHT}
            align="middle"
            className="ws-drag-trigger ws-gallery-modal-header"
            theme="gray-100"
            corners="squared"
            draggablePanel
          >
            <ModalHeader
              onSelectAllItems={onSelectAllItems}
              enableSelect={enableSelect}
              onDeleteItems={onDeleteItems}
              galleryItemsCount={items.length}
              switchSelectMode={switchSelectMode}
              selectedCount={selectedItemsIds.length}
            />
          </Panel>
          <Panel
            height={`calc(100% - ${PANEL_HEIGHT}px * 2)`}
            theme="white"
            className="ws-gallery-modal__content"
            corners="squared"
            scrollable
            style={{ borderBottom: 'none' }}
          >
            {isEmptyGallery ? (
              <div className="ws-gallery-empty-message">
                <Text theme="light-grey" type="heading-1" align="center">
                  {i18next.t('Collection is empty')}
                </Text>
                <Text theme="light-grey" type="regular" align="center">
                  {i18next.t(
                    'You haven’t added any items yet. Click "+" to add your first items and start organizing your collection'
                  )}
                </Text>
              </div>
            ) : (
              <div className="ws-gallery-items">
                {items.map((itemData, index) => (
                  <GalleryItem
                    {...itemData}
                    items={items}
                    elementId={elementId}
                    enableSelect={enableSelect}
                    onItemClick={onItemClick}
                    onEndItemDrag={onEndItemDrag}
                    key={`gallery-item-${itemData.id}`}
                    order={index}
                    selectedItemsIds={selectedItemsIds}
                  />
                ))}
              </div>
            )}
          </Panel>
          <Panel height={PANEL_HEIGHT} align="middle" corners="squared">
            <ButtonGroup stick="top" block>
              <Button stretch="block" size="large" onClick={onSubmit} disabled={isEmptyGallery}>
                <Text type="heading-6">{i18next.t('Submit')}</Text>
              </Button>
              <Button stretch="block" size="large" onMouseDown={onCancel}>
                <Text type="heading-6">{i18next.t('Cancel')}</Text>
              </Button>
            </ButtonGroup>
          </Panel>
        </PanelGroup>
        <AddImagesButton
          id="add-gallery-images"
          onImageUpload={onAddImage}
          onStockPhotoUpload={({ selectedPhotos }) => {
            const hasOnlyDefaultImages = items.length !== 0 && !hasNoneDefaultImages(items);
            if (hasOnlyDefaultImages) {
              showKeepDefaultImagesDialog({
                currentDialogType: PHOTO_STOCK_DIALOG,
                elementId,
                uploadedGalleryItems: selectedPhotos,
                galleryItems: items,
              });
              return;
            }

            dialogs.operations.show(GALLERY_PHOTO_STOCK_SAVING_DIALOG, {
              items,
              selectedPhotos,
              elementId,
            });
          }}
          onPhotoStockDialogCancel={() => {
            dialogs.operations.show(GALLERY_DIALOG, {
              items,
              elementId,
            });
          }}
          onWrongFileType={onWrongFileType}
          elementId={elementId}
        />
      </ModalDialog>
    </React.Fragment>
  );
}

GalleryDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedItemsIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  elementId: PropTypes.string.isRequired,
  onAddImage: PropTypes.func.isRequired,
  enableSelect: PropTypes.bool.isRequired,
  onItemClick: PropTypes.func.isRequired,
  switchSelectMode: PropTypes.func.isRequired,
  onSelectAllItems: PropTypes.func.isRequired,
  onDeleteItems: PropTypes.func.isRequired,
  onEndItemDrag: PropTypes.func.isRequired,
  onWrongFileType: PropTypes.func.isRequired,
};

export default DnDContext(GalleryDialog);
