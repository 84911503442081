import constants from '../constants';
import trackEvent from './track-event';

const { events } = constants;

const trackUndoTriggerClicked = () => {
  trackEvent(events.UNDO_TRIGGER_CLICKED);
};

export default trackUndoTriggerClicked;
