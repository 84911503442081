import { extensions } from '@yola/ws-sdk';

const { controlTypes } = extensions;

const getElementControlType = (focusedElementId, selection) => {
  const isTextSelected = focusedElementId && selection;

  if (isTextSelected && selection.isCollapsed) {
    return controlTypes.COLLAPSED_TEXT;
  }

  if (isTextSelected) {
    return controlTypes.TEXT;
  }

  return controlTypes.ELEMENT;
};

export default getElementControlType;
