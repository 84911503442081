import get from 'lodash.get';

const getAcceptableVariations = (items, displayIn = null) => {
  if (!displayIn) return items;

  return items.filter((item) => {
    const displayOptions = get(item, 'properties.displayIn', null);
    if (!displayOptions || !Array.isArray(displayOptions)) return true;

    return !!displayOptions.includes(displayIn);
  });
};

export default getAcceptableVariations;
