import React from 'react';
import { useSpring, animated } from 'react-spring';
import PropTypes from 'prop-types';
import { FADE_ANIMATION } from '../constants/animations';

function ControlPaneBody({ show, style, children }) {
  const { height, overflow } = useSpring({
    from: {
      height: show ? 0 : style.height,
      overflow: show ? 'hidden' : 'visible',
    },
    to: {
      height: show ? style.height : 0,
      overflow: show ? 'visible' : 'hidden',
    },
    config: FADE_ANIMATION,
  });

  return (
    <animated.div
      className="ws-control-pane-body"
      style={{
        overflow,
        height,
      }}
    >
      <div className="ws-control-pane-body__content" style={{ height }}>
        {children}
      </div>
    </animated.div>
  );
}

ControlPaneBody.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.node.isRequired,
  style: PropTypes.shape({
    height: PropTypes.number,
  }),
};

ControlPaneBody.defaultProps = {
  show: false,
  style: {},
};

export default ControlPaneBody;
