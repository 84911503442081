import { site, template } from '@yola/ws-sdk';
import segment from 'src/js/modules/analytics/segment';

const iconId = 'feature-icon';

const trackIconSizeResized = ({ blockId, blockVariationId, oldSize, newSize, iconAdjusted }) => {
  const { track, constants } = segment;

  track(constants.events.ICON_SIZE_RESIZED, {
    siteId: site.accessors.getSiteId(),
    templateBuildSlug: template.accessors.getBuildSlug(),
    blockId,
    blockVariationId,
    oldSize,
    newSize,
    iconAdjusted,
    iconId,
  });
};

export default trackIconSizeResized;
