import userData from './user-data';
import partnerData from './partner-data';
import userPreferences from './user-preferences';
import userFeatures from './user-features';

export default {
  userData,
  partnerData,
  userPreferences,
  userFeatures,
};
