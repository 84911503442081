import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Modal, designSystem } from '@yola/ws-ui';

const { ActionButton } = designSystem;

function SurveyDialog(props) {
  const {
    modalWidth,
    modalHeight,
    topPanelContent,
    submitButtonLabel,
    closeButtonLabel,
    children,
    isSubmitButtonDisabled,
    isDesktopView,
    onSubmit,
    onClose,
  } = props;
  const classes = classNames('ws-survey-dialog', {
    'ws-survey-dialog--desktop-view': isDesktopView,
  });

  return (
    <Modal
      className={classes}
      width={modalWidth}
      height="auto"
      minHeight={modalHeight}
      overlay="visible"
      fullscreen={!isDesktopView}
      draggable={false}
      resizable={false}
      centered
    >
      <div className="ws-survey-dialog__container">
        <div className="ws-survey-dialog__top-panel">
          {topPanelContent && (
            <div className="ws-survey-dialog__top-panel-content">{topPanelContent}</div>
          )}
          <div className="ws-survey-dialog__close-btn">
            <ActionButton
              format="text"
              fontWeight="regular"
              label={closeButtonLabel}
              onClick={onClose}
            />
          </div>
        </div>
        <div className="ws-survey-dialog__content">{children}</div>
        <div className="ws-survey-dialog__bottom-panel">
          <ActionButton
            format="solid"
            size="large"
            label={submitButtonLabel}
            onClick={onSubmit}
            fullWidth={!isDesktopView}
            disabled={isSubmitButtonDisabled}
          />
        </div>
      </div>
    </Modal>
  );
}

SurveyDialog.propTypes = {
  modalWidth: PropTypes.number.isRequired,
  modalHeight: PropTypes.number.isRequired,
  topPanelContent: PropTypes.node,
  submitButtonLabel: PropTypes.string.isRequired,
  closeButtonLabel: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isSubmitButtonDisabled: PropTypes.bool,
  isDesktopView: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

SurveyDialog.defaultProps = {
  topPanelContent: null,
  isSubmitButtonDisabled: false,
};

export default SurveyDialog;
