import { dialogs } from '@yola/ws-sdk';
import MainMenu from '../../common/components/main-menu';

const hideMainMenu = () => (next) => (action) => {
  switch (action.type) {
    case dialogs.actionTypes.SHOW_DIALOG: {
      MainMenu.hide();
      next(action);

      break;
    }
    default:
      next(action);
  }
};

export default hideMainMenu;
