import { dialogs, hooks, integration, linkEditing } from '@yola/ws-sdk';
import store from 'src/js/store';
import dialogTypes from '../../dialogs/constants/dialog-types';

let isInterrupterRegistered = false;

const registerExternalLinksInterrupter = async () => {
  const state = store.getState();
  const limits = integration.selectors.getLimits(state);
  const isExternalLinksAvailable = limits.externalLinks.available;

  if (isInterrupterRegistered || isExternalLinksAvailable) return;

  isInterrupterRegistered = true;

  const featureFlags = await integration.helpers.requestFeatureFlags(['limited_external_links']);
  const { limited_external_links: isLimitedExternalLinksEnabled } = featureFlags;

  if (!isLimitedExternalLinksEnabled) return;

  hooks.operations.subscribe(hooks.availableHooks.ON_TEXT_PASTE, (event, preventHandler) => {
    const hasExternalLink = linkEditing.verifiers.hasClipboardExternalLink(event);
    if (hasExternalLink) {
      preventHandler();
      event.preventDefault();

      const upsells = integration.selectors.getUpsells(state);
      const { captions, premiumFeature, onUpgrade, purchaseFlowType } = upsells.externalLinks;

      store.dispatch(
        dialogs.actions.show(dialogTypes.UPGRADE_LINKS, {
          purchaseFlowType,
          premiumFeature,
          captions,
          onUpgrade,
        })
      );
    }
  });
};

export default registerExternalLinksInterrupter;
