import { extensions } from '@yola/ws-sdk';
import common from '../constants/common';

const { ICON_EXTENSION_NAME, SOCIAL_NETWORK, YOLA_ICON_PREFIX } = common;

const getPreloadedIcons = () => {
  const extension = extensions.accessors.getExtension(ICON_EXTENSION_NAME);

  const {
    icons = {},
    api: { mapIconsData },
  } = extension;

  const { solid = [] } = icons[SOCIAL_NETWORK] || {};

  const supportedIcons = mapIconsData({
    [SOCIAL_NETWORK]: {
      solid,
    },
  });

  return supportedIcons.solid.reduce((accum, item) => {
    const accumLink = accum;
    const formattedTitle = item.title.replace(YOLA_ICON_PREFIX, '');
    accumLink[formattedTitle] = item;
    return accumLink;
  }, {});
};

export default getPreloadedIcons;
