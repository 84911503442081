import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import user from 'yola-editor/src/js/modules/user';
import { openInNewTab } from 'yola-editor/src/js/router/utils/open-in-new-tab';
import getIsB2C from 'src/js/modules/config/selectors/is-b2c';
import helpCenter from '../../help-center';

const useCustomerSupport = () => {
  const supportUrl = useSelector(user.selectors.getSupportUrl);
  const supportEmail = useSelector(user.selectors.getSupportEmail);
  const isB2C = useSelector(getIsB2C);
  const isCustomerSupportAvailable = Boolean(supportUrl || supportEmail);

  const onContactSupport = useCallback(() => {
    if (isB2C) {
      helpCenter.operations.showContactForm();
    } else {
      openInNewTab(supportUrl || `mailto:${supportEmail}`);
    }
  }, [isB2C, supportEmail, supportUrl]);

  return { supportUrl, supportEmail, onContactSupport, isCustomerSupportAvailable };
};

export default useCustomerSupport;
