import { dialogs } from '@yola/ws-sdk';
import { AlertDialog, ProgressDialog, SubmitDialog } from '@yola/ws-ui';
import pageManager from '../../page-manager';
import UpdateRequiredDialogContainer from '../../migrations/containers/update-required-dialog-container';
import UnsavedWarningContainer from '../../saving/containers/unsaved-warning-container';
import SavingProgressDialogContainer from '../../saving/containers/saving-progress-dialog-container';
import BlocksLibraryContainer from '../../blocks/containers/blocks-library-container';
import BlockSettingsContainer from '../../blocks/containers/block-settings-container';
import UnsavedChangesPromptContainer from '../../blocks/containers/unsaved-changes-prompt-container';
import BlockLayoutDialogContainer from '../../blocks/containers/block-layout-dialog-container';
import BlockReplacementDialog from '../../blocks/components/block-replacement-dialog';
import BlockLimitDialog from '../../blocks/components/block-limit-dialog';
import UpgradeDialogContainer from '../../upsell/containers/upgrade-dialog-container';
import WebsiteDesignContainer from '../../website-design/container/website-design-container';
import websiteSettings from '../../website-settings';
import AddLanguageDialog from '../../website-settings/components/add-language-dialog';
import ExportProgressDialog from '../../website-settings/components/export-pogress-dialog';
import ExportInformationDialog from '../../website-settings/components/export-information-dialog';
import ExportErrorDialog from '../../website-settings/components/export-error-dialog';
import PageSettingsContainer from '../../page-settings/containers/page-settings-container';
import ColorSettingsContainer from '../../website-design/container/color-settings-container';
import CustomPaletteContainer from '../../website-design/container/custom-palette-container';
import WelcomeTourContainer from '../../tours/containers/welcome-tour-container';
import MultilingualTourContainer from '../../tours/containers/multilingual-tour-container';
import CreateManualSiteBackup from '../../site-backups/containers/create-manual-site-backup';
import RestoreSiteBackup from '../../site-backups/containers/restore-site-backup';
import DeleteSiteBackup from '../../site-backups/containers/delete-site-backup';
import VoteForFeatureContainer from '../components/vote-for-feature';
import MultilingualIntroDialog from '../components/multilingual-intro-dialog';
import TranslatePageDialogContainer from '../../page-manager/containers/translate-page-dialog-container';
import SiteProgressChecklistContainer from '../../site-progress-checklist/containers/site-progress-checklist-container';
import DeveloperConsoleContainer from '../../page-manager/containers/developer-console-container';
import AddWidgetContainer from '../../widgets/containers/add-widget-container';
import FileTypeErrorDialog from '../components/file-type-error-dialog';
import ImageLoadingDialog from '../components/image-loading-dialog';
import dialogTypes from '../constants/dialog-types';
import FooterNavigationSettingsDialog from '../../extensions/registry/ws-footer-navigation/components/footer-navigation-settings-dialog';
import UploadFileDialog from '../../extensions/common/components/upload-file-dialog';
import ItemSettingDialog from '../../extensions/registry/ws-footer-navigation/components/item-setting-dialog';
import SocialLinksSettingsDialog from '../../extensions/registry/ws-social-links/components/social-links-settings-dialog';
import SocialLinksItemSettingsDialog from '../../extensions/registry/ws-social-links/components/item-settings-dialog';
import NavigationSettingsDialog from '../../extensions/registry/ws-navigation/components/navigation-settings-dialog';
import NavigationItemSettingsDialog from '../../extensions/registry/ws-navigation/components/item-settings-dialog';
import EmbedHtmlSettingsDialog from '../../extensions/registry/ws-html-widget/components/embed-html-settings-dialog';
import EditLinkDialog from '../../extensions/registry/ws-link/components/edit-link-dialog';
import IframeEditDialog from '../../extensions/registry/ws-iframe/components/iframe-edit-dialog';
import PhotoStockDialog from '../components/photo-stock-dialog';
import PhotoLoadErrorDialog from '../components/photo-load-error-dialog';
import PhotoSizeErrorDialog from '../components/photo-size-error-dialog';
import LinkFormattingDialog from '../../extensions/registry/ws-text-formatting/components/link-formatting-dialog';
import AddCustomColorDialog from '../../extensions/registry/ws-text-formatting/components/add-custom-color-dialog';
import RedirectDeleteDialog from '../../redirects/components/redirect-delete-dialog';
import RedirectEditContainer from '../../redirects/containers/redirect-edit-container';
import ImageAltDialogContainer from '../../extensions/registry/ws-image/components/image-alt-dialog-container';
import ClickActionDialog from '../../extensions/registry/ws-image/components/click-action-dialog';
import MediaSettingsDialogContainer from '../../extensions/common/containers/media-settings-dialog-container';
import GalleryDialogHoc from '../../extensions/registry/ws-gallery/components/gallery-dialog-hoc';
import GalleryDialogConfirmation from '../../extensions/registry/ws-gallery/components/gallery-dialog-confirmation';
import GallerySaveChangesConfirmationDialog from '../../extensions/registry/ws-gallery/components/gallery-save-changes-confirmation-dialog';
import FileTypeInfoDialog from '../../extensions/registry/ws-gallery/components/file-type-info-dialog';
import FileQuantityInfoDialog from '../../extensions/registry/ws-gallery/components/file-quantity-info-dialog';
import SavingImageProgressDialog from '../../extensions/registry/ws-gallery/components/saving-image-progress-dialog';
import SavingImageErrorDialog from '../../extensions/registry/ws-gallery/components/saving-image-error-dialog';
import PhotoStockSavingDialog from '../../extensions/registry/ws-gallery/components/photo-stock-saving-dialog';
import SelectIconModal from '../../extensions/registry/ws-icon/components/select-icon-modal';
import ConnectCookiebotDialog from '../../integrations/components/connect-cookiebot-dialog';
import CookiebotSettingsDialog from '../../integrations/components/cookiebot-settings-dialog';
import ConnectGoogleAnalyticsDialog from '../../integrations/components/connect-google-analytics-dialog';
import GoogleAnalyticsSettingsDialog from '../../integrations/components/google-analytics-settings-dialog';
import ConnectGoogleSearchConsoleDialog from '../../integrations/components/connect-google-search-console-dialog';
import GoogleSearchConsoleSettingsDialog from '../../integrations/components/google-search-console-settings-dialog';
import ContactFormSelectItemDialog from '../../extensions/registry/ws-contact-form/components/select-item-dialog';
import ContactFormSettingsDialog from '../../extensions/registry/ws-contact-form/components/form-settings-dialog';
import ContantFormEditFieldDialog from '../../extensions/registry/ws-contact-form/components/edit-field-dialog';
import ContactFormDeleteEmailDialog from '../../extensions/registry/ws-contact-form/components/delete-email-dialog';
import SliderSettingsDialog from '../../extensions/registry/ws-slider/components/slider-settings-dialog';
import DifmPromoDialogContainer from '../../difm-promo/containers/difm-promo-dialog-container';
import DifmPromoServicesDialogContainer from '../../difm-promo/containers/difm-promo-services-dialog-container';
import DifmPromoSurveyDialogContainer from '../../difm-promo/containers/difm-promo-survey-dialog-container';
import DifmPromoPackagesDialogContainer from '../../difm-promo/containers/difm-promo-packages-dialog-container';
import DifmPromoFinishDialogContainer from '../../difm-promo/containers/difm-promo-finish-dialog-container';
import ColorSchemeSettingsContainer from '../../website-design/container/color-scheme-settings-container';
import AiErrorContainer from '../../ai/containers/ai-error-container';
import AiSuccessContainer from '../../ai/containers/ai-success-container';
import AiOnboardingContainer from '../../ai/containers/ai-onboarding-container';
import SiteWizardIntroContainer from '../../ai-wizard/containers/site-wizard-intro-container';
import SiteWizardConfirmExitContainer from '../../ai-wizard/containers/site-wizard-confirm-exit-container';
import SiteWizardProgressContainer from '../../ai-wizard/containers/site-wizard-progress-container';
import SiteWizardSetupContainer from '../../ai-wizard/containers/site-wizard-setup-container';
import PageGenerationContainer from '../../ai-wizard/containers/page-generation-container';
import AddPageContainer from '../../page-manager/containers/add-page-container';
import TermsOfUseDialog from '../components/terms-of-use-dialog';
import ErrorDialog from '../components/error-dialog';
import PublishInterruptionDialogContainer from '../../upsell/containers/publish-interruption-dialog-container';
import ElementSettingsContainer from '../../element-settings/containers/element-settings-container';

const { PageManagerContainer, PageDeleteContainer, NewPageDialogContainer } =
  pageManager.containers;

const { WebsiteSettingsContainer } = websiteSettings.containers;

const registerDialogs = () => {
  dialogs.operations.register(dialogTypes.REDIRECT_EDIT_DIALOG, RedirectEditContainer);
  dialogs.operations.register(dialogTypes.REDIRECT_DELETE_DIALOG, RedirectDeleteDialog);
  dialogs.operations.register(dialogTypes.ADD_BLOCK, BlocksLibraryContainer);
  dialogs.operations.register(dialogTypes.BLOCK_SETTINGS, BlockSettingsContainer);
  dialogs.operations.register(dialogTypes.BLOCK_LAYOUT_DIALOG, BlockLayoutDialogContainer);
  dialogs.operations.register(dialogTypes.UNSAVED_CHANGES_PROMPT, UnsavedChangesPromptContainer);
  dialogs.operations.register(dialogTypes.BLOCK_REPLACEMENT_DIALOG, BlockReplacementDialog);
  dialogs.operations.register(dialogTypes.BLOCK_LIMIT_DIALOG, BlockLimitDialog);
  dialogs.operations.register(dialogTypes.PAGE_MANAGER, PageManagerContainer);
  dialogs.operations.register(dialogTypes.DELETE_PAGE, PageDeleteContainer);
  dialogs.operations.register(dialogTypes.PAGE_SETTINGS, PageSettingsContainer);
  dialogs.operations.register(dialogTypes.NEW_PAGE_DIALOG, NewPageDialogContainer);
  dialogs.operations.register(dialogTypes.UNSAVED_WARNING, UnsavedWarningContainer);
  dialogs.operations.register(dialogTypes.SAVING_PROGRESS, SavingProgressDialogContainer);
  dialogs.operations.register(dialogTypes.FILE_ERROR_DIALOG, AlertDialog);
  dialogs.operations.register(dialogTypes.UPLOAD_PROGRESS_DIALOG, ProgressDialog);
  dialogs.operations.register(dialogTypes.UPGRADE, UpgradeDialogContainer);
  dialogs.operations.register(dialogTypes.UPGRADE_LINKS, UpgradeDialogContainer);
  dialogs.operations.register(dialogTypes.WEBSITE_SETTINGS, WebsiteSettingsContainer);
  dialogs.operations.register(dialogTypes.ADD_LANGUAGE, AddLanguageDialog);
  dialogs.operations.register(dialogTypes.WEBSITE_DESIGN, WebsiteDesignContainer);
  dialogs.operations.register(dialogTypes.REACH_TECH_LIMIT_DIALOG, AlertDialog);
  dialogs.operations.register(dialogTypes.WEBSITE_DESIGN_COLOR_SETTINGS, ColorSettingsContainer);
  dialogs.operations.register(dialogTypes.WEBSITE_DESIGN_CUSTOM_PALETTE, CustomPaletteContainer);
  dialogs.operations.register(dialogTypes.WELCOME_TOUR, WelcomeTourContainer);
  dialogs.operations.register(dialogTypes.MULTILINGUAL_TOUR, MultilingualTourContainer);
  dialogs.operations.register(dialogTypes.SAVE_ERROR_DIALOG, ErrorDialog);
  dialogs.operations.register(dialogTypes.CREATE_MANUAL_SITE_BACKUP, CreateManualSiteBackup);
  dialogs.operations.register(dialogTypes.RESTORE_SITE_BACKUP, RestoreSiteBackup);
  dialogs.operations.register(dialogTypes.DELETE_SITE_BACKUP, DeleteSiteBackup);
  dialogs.operations.register(dialogTypes.SITE_BACKUP_ERROR, ErrorDialog);
  dialogs.operations.register(dialogTypes.CONFIRM_DIALOG, SubmitDialog);
  dialogs.operations.register(dialogTypes.VOTE_FOR_THE_FEATURE, VoteForFeatureContainer);
  dialogs.operations.register(dialogTypes.MULTILINGUAL_INTRO, MultilingualIntroDialog);
  dialogs.operations.register(dialogTypes.TRANSLATE_PAGE, TranslatePageDialogContainer);
  dialogs.operations.register(dialogTypes.SITE_PROGRESS_CHECKLIST, SiteProgressChecklistContainer);
  dialogs.operations.register(dialogTypes.DEVELOPER_CONSOLE, DeveloperConsoleContainer);
  dialogs.operations.register(dialogTypes.UPDATE_REQUIRED, UpdateRequiredDialogContainer);
  dialogs.operations.register(dialogTypes.ADD_WIDGET, AddWidgetContainer);
  dialogs.operations.register(dialogTypes.FILE_TYPE_ERROR_DIALOG, FileTypeErrorDialog);
  dialogs.operations.register(dialogTypes.IMAGE_LOADING_DIALOG, ImageLoadingDialog);
  dialogs.operations.register(
    dialogTypes.FOOTER_NAVIGATION_SETTINGS_DIALOG,
    FooterNavigationSettingsDialog
  );
  dialogs.operations.register(dialogTypes.UPLOAD_FILE_DIALOG, UploadFileDialog);
  dialogs.operations.register(dialogTypes.FOOTER_NAVIGATION_ITEM_SETTING_DIALOG, ItemSettingDialog);
  dialogs.operations.register(dialogTypes.EXPORT_PROGRESS_DIALOG, ExportProgressDialog);
  dialogs.operations.register(dialogTypes.EXPORT_INFORMATION_DIALOG, ExportInformationDialog);
  dialogs.operations.register(dialogTypes.EXPORT_ERROR_DIALOG, ExportErrorDialog);
  dialogs.operations.register(dialogTypes.SOCIAL_LINKS_SETTINGS_DIALOG, SocialLinksSettingsDialog);
  dialogs.operations.register(
    dialogTypes.SOCIAL_LINKS_ITEM_SETTINGS_DIALOG,
    SocialLinksItemSettingsDialog
  );
  dialogs.operations.register(dialogTypes.NAVIGATION_SETTINGS_DIALOG, NavigationSettingsDialog);
  dialogs.operations.register(
    dialogTypes.NAVIGATION_ITEM_SETTINGS_DIALOG,
    NavigationItemSettingsDialog
  );
  dialogs.operations.register(dialogTypes.EMBED_HTML_SETTINGS_DIALOG, EmbedHtmlSettingsDialog);
  dialogs.operations.register(dialogTypes.EDIT_LINK_DIALOG, EditLinkDialog);
  dialogs.operations.register(dialogTypes.EDIT_IFRAME_DIALOG, IframeEditDialog);
  dialogs.operations.register(dialogTypes.PHOTO_STOCK_DIALOG, PhotoStockDialog);
  dialogs.operations.register(dialogTypes.PHOTO_LOAD_ERROR, PhotoLoadErrorDialog);
  dialogs.operations.register(dialogTypes.PHOTO_SIZE_ERROR, PhotoSizeErrorDialog);
  dialogs.operations.register(dialogTypes.LINK_FORMATTING_DIALOG, LinkFormattingDialog);
  dialogs.operations.register(dialogTypes.ADD_CUSTOM_COLOR_DIALOG, AddCustomColorDialog);
  dialogs.operations.register(dialogTypes.IMAGE_ALT_DIALOG, ImageAltDialogContainer);
  dialogs.operations.register(dialogTypes.CLICK_ACTION_DIALOG, ClickActionDialog);
  dialogs.operations.register(dialogTypes.MEDIA_SETTINGS_DIALOG, MediaSettingsDialogContainer);
  dialogs.operations.register(dialogTypes.GALLERY_DIALOG, GalleryDialogHoc);
  dialogs.operations.register(dialogTypes.GALLERY_CONFIRMATION_DIALOG, GalleryDialogConfirmation);
  dialogs.operations.register(
    dialogTypes.GALLERY_SAVE_CONFIRMATION_DIALOG,
    GallerySaveChangesConfirmationDialog
  );
  dialogs.operations.register(dialogTypes.GALLERY_FILE_TYPE_INFO_DIALOG, FileTypeInfoDialog);
  dialogs.operations.register(
    dialogTypes.GALLERY_FILE_QUANTITY_INFO_DIALOG,
    FileQuantityInfoDialog
  );
  dialogs.operations.register(
    dialogTypes.GALLERY_SAVING_PROGRESS_DIALOG,
    SavingImageProgressDialog
  );
  dialogs.operations.register(
    dialogTypes.GALLERY_SAVING_IMAGE_ERROR_DIALOG,
    SavingImageErrorDialog
  );
  dialogs.operations.register(
    dialogTypes.GALLERY_PHOTO_STOCK_SAVING_DIALOG,
    PhotoStockSavingDialog
  );
  dialogs.operations.register(dialogTypes.SELECT_ICON, SelectIconModal);
  dialogs.operations.register(dialogTypes.CONNECT_COOKIEBOT_DIALOG, ConnectCookiebotDialog);
  dialogs.operations.register(dialogTypes.COOKIEBOT_SETTINGS_DIALOG, CookiebotSettingsDialog);
  dialogs.operations.register(
    dialogTypes.CONNECT_GOOGLE_ANALYTICS_DIALOG,
    ConnectGoogleAnalyticsDialog
  );
  dialogs.operations.register(
    dialogTypes.GOOGLE_ANALYTICS_SETTINGS_DIALOG,
    GoogleAnalyticsSettingsDialog
  );
  dialogs.operations.register(
    dialogTypes.CONNECT_GOOGLE_SEARCH_CONSOLE_DIALOG,
    ConnectGoogleSearchConsoleDialog
  );
  dialogs.operations.register(
    dialogTypes.GOOGLE_SEARCH_CONSOLE_SETTINGS_DIALOG,
    GoogleSearchConsoleSettingsDialog
  );
  dialogs.operations.register(
    dialogTypes.CONTACT_FORM_SELECT_ITEM_DIALOG,
    ContactFormSelectItemDialog
  );
  dialogs.operations.register(dialogTypes.CONTACT_FORM_SETTINGS_DIALOG, ContactFormSettingsDialog);
  dialogs.operations.register(
    dialogTypes.CONTACT_FORM_EDIT_FIELD_DIALOG,
    ContantFormEditFieldDialog
  );
  dialogs.operations.register(
    dialogTypes.CONTACT_FORM_DELETE_EMAIL_DIALOG,
    ContactFormDeleteEmailDialog
  );
  dialogs.operations.register(dialogTypes.ALERT_DIALOG, AlertDialog);
  dialogs.operations.register(dialogTypes.SLIDER_SETTINGS_DIALOG, SliderSettingsDialog);
  dialogs.operations.register(dialogTypes.DIFM_PROMO_BANNER_DIALOG, DifmPromoDialogContainer);
  dialogs.operations.register(
    dialogTypes.DIFM_PROMO_SERVICES_DIALOG,
    DifmPromoServicesDialogContainer
  );
  dialogs.operations.register(dialogTypes.DIFM_PROMO_SURVEY_DIALOG, DifmPromoSurveyDialogContainer);
  dialogs.operations.register(
    dialogTypes.DIFM_PROMO_PACKAGES_DIALOG,
    DifmPromoPackagesDialogContainer
  );
  dialogs.operations.register(dialogTypes.DIFM_PROMO_FINISH_DIALOG, DifmPromoFinishDialogContainer);
  dialogs.operations.register(
    dialogTypes.COLOR_SCHEME_SETTINGS_DIALOG,
    ColorSchemeSettingsContainer
  );
  dialogs.operations.register(dialogTypes.AI_ERROR_DIALOG, AiErrorContainer);
  dialogs.operations.register(dialogTypes.AI_SUCCESS_DIALOG, AiSuccessContainer);
  dialogs.operations.register(dialogTypes.AI_ONBOARDING_DIALOG, AiOnboardingContainer);
  dialogs.operations.register(dialogTypes.SITE_WIZARD_INTRO_DIALOG, SiteWizardIntroContainer);
  dialogs.operations.register(
    dialogTypes.SITE_WIZARD_CONFIRM_EXIT_DIALOG,
    SiteWizardConfirmExitContainer
  );
  dialogs.operations.register(dialogTypes.SITE_WIZARD_SETUP_DIALOG, SiteWizardSetupContainer);
  dialogs.operations.register(dialogTypes.SITE_WIZARD_PROGRESS_DIALOG, SiteWizardProgressContainer);
  dialogs.operations.register(dialogTypes.PAGE_GENERATION_DIALOG, PageGenerationContainer);
  dialogs.operations.register(dialogTypes.ADD_PAGE_DIALOG, AddPageContainer);
  dialogs.operations.register(dialogTypes.AI_TEXT_ASSISTANT_ERROR_DIALOG, AiErrorContainer);
  dialogs.operations.register(dialogTypes.TERMS_OF_USE_DIALOG, TermsOfUseDialog);
  dialogs.operations.register(dialogTypes.ELEMENT_SETTINGS_DIALOG, ElementSettingsContainer);
  dialogs.operations.register(
    dialogTypes.PUBLISH_INTERRUPTION_DIALOG,
    PublishInterruptionDialogContainer
  );
};

export default registerDialogs;
