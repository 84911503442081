import React from 'react';
import PropTypes from 'prop-types';
import { Text, Render } from '@yola/ws-ui';

function Annotation({ description, link }) {
  const shouldRender = !!(link.text && link.url);

  return (
    <Text type="annotation" className="ws-page-settings__annotation">
      {description}

      <Render if={shouldRender}>
        <a href={link.url} className="ws-page-settings__link">
          {link.text}
        </a>
      </Render>
    </Text>
  );
}

Annotation.defaultProps = {
  description: '',
  link: {},
};

Annotation.propTypes = {
  description: PropTypes.string,
  link: PropTypes.shape({
    text: PropTypes.string,
    url: PropTypes.string,
  }),
};

export default Annotation;
