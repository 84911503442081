import trackEvent from './track-event';
import constants from '../constants';

const { events } = constants;

const trackLinkSettingsInputClicked = (traits) => {
  trackEvent(events.LINK_SETTINGS_INPUT_CLICKED, traits);
};

export default trackLinkSettingsInputClicked;
