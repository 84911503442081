const processBlockHtmlForPreview = (
  html,
  googleMapStub = null,
  youtubeStub = null,
  processFullScreen = false
) => {
  const res = html
    .replace(
      /<ws-iframe([^>]+)maps([^>]+)><\/ws-iframe>/gim,
      `<picture><img src="${googleMapStub}" class="google-map-stub"></picture>`
    )
    .replace(
      /<ws-iframe([^>]+)youtube([^>]+)><\/ws-iframe>/gim,
      `<picture><img src="${youtubeStub}" class="youtube-stub"></picture>`
    )
    .replace(
      /<iframe([^>]+)maps([^>]+)><\/iframe>/gim,
      `<picture><img src="${googleMapStub}" class="google-map-stub"></picture>`
    )
    .replace(
      /<iframe([^>]+)youtube([^>]+)><\/iframe>/gim,
      `<picture><img src="${youtubeStub}" class="youtube-stub"></picture>`
    )
    .replace(/loading=("|')lazy("|')/gi, ` `)
    .replace(/<ws-block\s/gi, `<ws-block safe-mode `);

  if (processFullScreen) return res.replace(/fullscreen/gi, 'no-fullscreen');

  return res;
};

export default processBlockHtmlForPreview;
