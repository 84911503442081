import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Text,
  Modal,
  Formik,
  Field,
  CheckboxField,
  ButtonGroup,
  Button,
  PanelGroup,
  Panel,
  designSystem,
} from '@yola/ws-ui';
import { dialogs, utils, i18next, site, template } from '@yola/ws-sdk';
import LanguageSelectField from 'src/js/modules/common/components/language-select-field';
import segment from 'src/js/modules/analytics/segment';
import getLanguages from '../helpers/get-languages';
import getCountries from '../helpers/get-countries';
import getRelatedLanguageOptions from '../helpers/get-related-language-options';
import dialogTypes from '../../dialogs/constants/dialog-types';
import getLangData from '../helpers/get-language-data';
import constants from '../constants';

const { ControlGroup, FieldStatus } = designSystem;

const {
  track,
  constants: { events },
} = segment;
const { UPVOTE_INTERRUPTION_ID } = constants.common;

const requireValidation = (value) => {
  if (!value) {
    return i18next.t('This field is required');
  }
  return '';
};

function AddLanguageDialog({
  initialValues,
  locales,
  mainLocale,
  roadmapUrl,
  formData,
  onSubmit: onSubmitCallback,
  onCancel: onCancelCallback,
  onDialogMainAction,
  onDialogCancel,
}) {
  const langMap = useMemo(() => getLanguages(), []);
  const countriesMap = useMemo(() => getCountries(), []);
  const usedLocalesOptions = locales.map((locale) => {
    const langData = getLangData(locale, langMap, countriesMap);
    const { iconComponent } = langData.country;
    const { name } = langData;
    return {
      ...langData,
      name,
      iconComponent,
    };
  });

  const adaptiveUsedLocalesOptions = useMemo(
    () =>
      usedLocalesOptions.map((item) => ({
        value: item.code,
        iconComponent: item.iconComponent,
        label: item.name,
      })),
    [usedLocalesOptions]
  );

  const languageOptions = useMemo(
    () =>
      getRelatedLanguageOptions(locales, langMap, countriesMap).map((item) => ({
        value: item.code,
        iconComponent: item.iconComponent,
        label: item.name,
      })),
    [langMap, countriesMap, locales]
  );

  const onSubmit = (...values) => {
    onDialogMainAction();
    onSubmitCallback(...values);
  };

  const onCancel = () => {
    onDialogCancel();
    onCancelCallback();
  };

  const setInitialValue = () => {
    if (initialValues) return initialValues;

    const firstLangCode = languageOptions[0].value;
    const translateLocale = locales.find((locale) => locale === mainLocale);
    return {
      originLocale: translateLocale.originLocale || mainLocale,
      destinationLocale: firstLangCode,
      autoTrans: false,
    };
  };

  const renderLanguageSelect = (formProps) => (
    <LanguageSelectField
      {...formProps}
      defaultValue={languageOptions[0]}
      options={languageOptions}
      placeholder={i18next.t('Select...')}
    />
  );

  const renderUsedLanguageSelect = (formProps) => (
    <LanguageSelectField
      {...formProps}
      defaultValue={adaptiveUsedLocalesOptions[0]}
      options={adaptiveUsedLocalesOptions}
      placeholder={i18next.t('Select...')}
    />
  );

  const voteForFeature = (values) => {
    track(events.UPVOTE_INTERRUPTION_DISPLAYED, {
      siteId: site.accessors.getSiteId(),
      interruptionId: UPVOTE_INTERRUPTION_ID,
      templateBuildSlug: template.accessors.getBuildSlug(),
    });

    dialogs.operations.show(dialogTypes.VOTE_FOR_THE_FEATURE, {
      onClose: (returnedProps) => {
        dialogs.operations.show(dialogTypes.ADD_LANGUAGE, { ...returnedProps, onSubmit, onCancel });
      },
      formData,
      roadmapUrl,
      initialValues: values,
      locales,
      mainLocale,
    });
  };

  return (
    <Formik initialValues={setInitialValue()} onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <Modal
          overlay="visible"
          centered
          resizable={false}
          draggable={false}
          fullscreen={false}
          className="ws-text-input-dialog"
          width={368}
          height={540}
          handleCancel={onCancel}
          handleSubmit={handleSubmit}
        >
          <PanelGroup height="100%">
            <Panel
              align="middle"
              className="ws-modal__content"
              theme="gray-100"
              corners="squared"
              height="48"
            >
              <Text type="heading-6">{i18next.t('Add new language')}</Text>
            </Panel>
            <Panel className="ws-modal__content">
              <div className="ws-modal__block">
                <ControlGroup title={i18next.t('New language')}>
                  <Field
                    name="destinationLocale"
                    validate={requireValidation}
                    render={renderLanguageSelect}
                  />
                </ControlGroup>
                <FieldStatus text={i18next.t('Select a language to add to your site')} />
              </div>
              <div className="ws-modal__block">
                <ControlGroup title={i18next.t('Language to translate')}>
                  <Field
                    name="originLocale"
                    validate={requireValidation}
                    render={renderUsedLanguageSelect}
                  />
                </ControlGroup>
                <FieldStatus
                  text={i18next.t(
                    'Select a language that you want to translate. All existing content and pages of this language will be copied to the new language of your site.'
                  )}
                />
              </div>

              {roadmapUrl && (
                <div className="ws-modal__block">
                  <Field
                    name="autoTrans"
                    render={(formikProps) => (
                      <CheckboxField
                        {...formikProps}
                        id="autoTransId"
                        checked={formikProps.field.value}
                        onChange={() => {
                          voteForFeature(formikProps.form.values);
                        }}
                      >
                        {/* eslint-disable-next-line yola/jsx-a11y/label-has-for */}
                        <label htmlFor="autoTransId">
                          <Text type="heading-6">{i18next.t('Use automatic translation')}</Text>
                        </label>
                      </CheckboxField>
                    )}
                  />
                </div>
              )}
            </Panel>
            <Panel>
              <ButtonGroup block stick="top">
                <Button stretch="block" size="large" onClick={handleSubmit}>
                  {i18next.t('Submit')}
                </Button>
                <Button stretch="block" size="large" onMouseDown={onCancel}>
                  {i18next.t('Cancel')}
                </Button>
              </ButtonGroup>
            </Panel>
          </PanelGroup>
        </Modal>
      )}
    </Formik>
  );
}

AddLanguageDialog.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  roadmapUrl: PropTypes.string,
  initialValues: PropTypes.shape(),
  formData: PropTypes.shape().isRequired,
  locales: PropTypes.array.isRequired,
  mainLocale: PropTypes.string.isRequired,
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

AddLanguageDialog.defaultProps = {
  onCancel: utils.noop,
  onSubmit: utils.noop,
  initialValues: null,
  roadmapUrl: null,
};

export default AddLanguageDialog;
