import { TEXT_RESIZE_HANDLER_SIZE } from '../constants/common';

const getContainerWidth = (container) => {
  if (!container) return document.body.clientWidth - 2 * TEXT_RESIZE_HANDLER_SIZE;

  const styles = window.getComputedStyle(container, null);
  return (
    container.clientWidth - parseInt(styles.paddingLeft, 10) - parseInt(styles.paddingRight, 10)
  );
};

export default getContainerWidth;
