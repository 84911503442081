import constants from '../constants';
import trackEvent from './track-event';

const { events } = constants;

const trackAddAiPageTriggerClicked = ({ recipeId, businessCategory = null }) => {
  trackEvent(events.ADD_AI_PAGE_TRIGGER_CLICKED, { recipeId, businessCategory });
};

export default trackAddAiPageTriggerClicked;
