import bowser from 'yola-bowser';
import { view } from '@yola/ws-sdk';
import wouldBeDroppedInSamePosition from './would-be-dropped-in-same-position';
import getAutoScrollConfig from './get-auto-scroll-config';
import getDropTargetElementAndPosition from './get-drop-target-element-and-position';
import actions from '../actions';
import store from '../../../store';
import getNavbarHeight from '../../navbar/accessors/get-height';
import { AUTOSCROLL_AREA_HEIGHT } from '../constants/autoscroll';

const { autoScroll } = view.helpers;

const hoverHandler = (props, monitor, component) => {
  if (!monitor.getClientOffset()) return;

  const { elementId } = monitor.getItem();
  const element = view.accessors.getLiveElement(elementId);
  const { area } = component.area;
  const { scrollPosition } = props;
  const bounds = area.getBoundingClientRect();
  const navbarHeight = getNavbarHeight();
  const offset = monitor.getClientOffset();
  const clientX = offset.x - bounds.left;
  let clientY = offset.y - bounds.top - navbarHeight;

  if (bowser.ios) {
    clientY += scrollPosition;
  }

  const scrollConfig = getAutoScrollConfig(bounds, offset, scrollPosition);
  autoScroll.setDirection(scrollConfig.direction);
  autoScroll.setSpeed(scrollConfig.speed);

  if (
    !autoScroll.isScrolling() ||
    scrollPosition < AUTOSCROLL_AREA_HEIGHT ||
    scrollPosition > bounds.height - AUTOSCROLL_AREA_HEIGHT
  ) {
    const hoverTargetElement = view.accessors.getLiveElementByCoords(clientX, clientY);

    const { dropTargetElement, position } = getDropTargetElementAndPosition(
      element,
      hoverTargetElement,
      clientX,
      clientY
    );

    const dropTargetElementId = view.accessors.getLiveElementId(dropTargetElement);

    if (
      dropTargetElementId === null ||
      wouldBeDroppedInSamePosition(elementId, dropTargetElementId, position)
    ) {
      store.dispatch(actions.setDropTargetElement(null));
      store.dispatch(actions.setAdjacentPosition(null));
    } else {
      store.dispatch(actions.setDropTargetElement(dropTargetElementId));
      store.dispatch(actions.setAdjacentPosition(position));
    }
  }
};

export default hoverHandler;
