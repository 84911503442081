import get from 'lodash.get';
import { config } from '@yola/ws-sdk';

const getSentryDSN = (rootState) => {
  const cfg = config.selectors.getConfig(rootState);

  return get(cfg, 'ws-editor.sentry_dsn');
};

export default getSentryDSN;
