import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { assets, dialogs, view, utils } from '@yola/ws-sdk';
import UploadImageContainer from 'src/js/modules/common/containers/upload-image-container';
import UploadImageTrigger from 'src/js/modules/common/components/upload-image-trigger';
import focalPoint from 'src/js/modules/focal-point';
import getDefaultTraits from 'src/js/modules/analytics/segment/helpers/get-default-traits';
import segment from 'src/js/modules/analytics/segment';
import imageTypes from 'src/js/modules/extensions/common/constants/image-types';
import mediaAttributes from 'src/js/modules/extensions/common/constants/media-attributes';
import generateImageThumbnailSource from 'src/js/modules/extensions/registry/ws-gallery/helpers/generate-image-thumbnail-source';
import isPlaceholder from '../../helpers/is-placeholder';
import getOverlappedMediaContainerByText from '../../helpers/get-overlapped-media-container-by-text';

const {
  constants: { common: focalPointConstants, operationTypes },
  helpers: { isGalleryItem: isGalleryItemVerifier, getViewOperation, computeAdjustedScale },
} = focalPoint;
const {
  track,
  constants: { events },
} = segment;

const { DEFAULT_POSITION_STRING_VALUE, DEFAULT_SCALE, FILE_UPLOAD, STOCK_PHOTOS } =
  focalPointConstants;

function UploadImageTriggerContainer(props) {
  const { getContext, elementId: liveElementId, id, active } = props;
  const liveElement = view.accessors.getLiveElement(liveElementId);
  const overlappedMediaContainer = getOverlappedMediaContainerByText(liveElement, true);
  const elementId = overlappedMediaContainer
    ? view.accessors.getLiveElementId(overlappedMediaContainer)
    : liveElementId;

  const imageNode = useMemo(() => view.accessors.getLiveElement(elementId), [elementId]);

  const isGalleryItem = useMemo(() => isGalleryItemVerifier(imageNode), [imageNode]);
  const isImagePlaceholder = isPlaceholder(imageNode);

  const getOperation = useCallback(
    (operationType, value) => getViewOperation(operationType, elementId, value),
    [elementId]
  );

  const finishImageUpload = useCallback(
    async ({ file, source: assetSource, name: assetName, isStockPhoto }) => {
      track(events.CONTROL_PANE_NEW_IMAGE_SELECTED, {
        ...getDefaultTraits(elementId),
        source: isStockPhoto ? STOCK_PHOTOS : FILE_UPLOAD,
      });

      const imageUrl = assets.helpers.addBaseHref(assetSource);
      const imageSize = await assets.helpers.getImageSize(imageUrl);

      assets.operations.makePublishable(assetSource);

      const setSrcOperation = getOperation(operationTypes.SRC, assetSource);
      const setPositionOperation = getOperation(
        operationTypes.POSITION,
        DEFAULT_POSITION_STRING_VALUE
      );

      // adjusting content scale so small images want be stretching to container width or height
      const element = view.accessors.getLiveElement(elementId);
      const adjustedScale = computeAdjustedScale(element, {
        originalMediaBounds: imageSize,
        scale: DEFAULT_SCALE,
      });

      const scale = adjustedScale || DEFAULT_SCALE;
      const setScaleOperation = getOperation(operationTypes.SCALE, scale);

      let operations = [setSrcOperation, setPositionOperation, setScaleOperation];

      if (isGalleryItem) {
        const imageContainerSize = imageNode.getContainerBounds();
        const thumbnailSource = await generateImageThumbnailSource({
          assetSource,
          assetName,
          assetType: file?.type,
          imageSize,
          imageContainerSize,
          isStockPhoto,
        });

        const setOriginWidthOperation = getOperation(operationTypes.ORIGIN_WIDTH, imageSize.width);
        const setOriginHeightOperation = getOperation(
          operationTypes.ORIGIN_HEIGHT,
          imageSize.height
        );
        const setThumbnailOperation = getOperation(
          operationTypes.THUMBNAIL,
          thumbnailSource || assetSource
        );

        operations = [
          ...operations,
          setOriginWidthOperation,
          setOriginHeightOperation,
          setThumbnailOperation,
        ];
      }

      if (isImagePlaceholder) {
        operations.push([
          view.operations.removeElementAttribute,
          [elementId, mediaAttributes.PLACEHOLDER_ATTRIBUTE],
        ]);
      }

      view.operations.bulkViewOperations(operations);

      dialogs.operations.hide();
    },
    [elementId, imageNode, isGalleryItem, isImagePlaceholder, getOperation]
  );

  return (
    <UploadImageContainer onImageUploadEnd={finishImageUpload}>
      {({ onWrongFileType, onImageUpload, onStockPhotoUpload }) => (
        <UploadImageTrigger
          id={id}
          active={active}
          elementId={elementId}
          getContext={getContext}
          accept={imageTypes.IMAGE_ACCEPT_TYPE}
          onWrongFileType={onWrongFileType}
          onPhotoStockDialogCancel={dialogs.operations.hide}
          onClick={() => {
            track(events.CONTROL_PANE_REPLACE_MEDIA_BUTTON_CLICKED, {
              ...getDefaultTraits(elementId),
            });
          }}
          onTriggerUploadImageClick={() => {
            track(events.CONTROL_PANE_UPLOAD_FILE_BUTTON_CLICKED, {
              ...getDefaultTraits(elementId),
            });
          }}
          onTriggerBrowseStockPhotosClick={() => {
            track(events.CONTROL_PANE_BROWSE_STOCK_PHOTOS_BUTTON_CLICKED, {
              ...getDefaultTraits(elementId),
            });
          }}
          onImageUpload={onImageUpload}
          onStockPhotoUpload={onStockPhotoUpload}
        />
      )}
    </UploadImageContainer>
  );
}

UploadImageTriggerContainer.propTypes = {
  id: PropTypes.string.isRequired,
  elementId: PropTypes.string,
  getContext: PropTypes.func,
  active: PropTypes.bool,
};

UploadImageTriggerContainer.defaultProps = {
  elementId: null,
  getContext: utils.noop,
  active: false,
};

export default UploadImageTriggerContainer;
