import { useMemo, useState } from 'react';
import { view } from '@yola/ws-sdk';
import positionProperties from '../constants/position-properties';
import getSuitableTransformPosition from '../helpers/get-suitable-transform-position';
import getPositionCustomProperties from '../helpers/get-position-custom-properties';
import setPositionCustomProperties from '../helpers/set-position-custom-properties';
import usePositioningArea from './use-positioning-area';
import useDrag from './use-drag';

const DEFAULT_POSITION_X = 0;
const DEFAULT_POSITION_Y = 0;
const POSITION_X_MIN = 0;
const POSITION_X_MAX = 100;
const POSITION_Y_MIN = 0;
const POSITION_Y_MAX = 100;

const convertPxToPercentages = (value, length) => (value * 100) / length;
const parsePositionValue = (value) => {
  const parsedValue = parseFloat(value);

  if (value.includes('%')) {
    return parsedValue;
  }

  return null;
};

const useDragAndPositioning = ({
  handleRef,
  elementId,
  scrollPosition,
  onStart,
  onMove,
  onEnd,
}) => {
  const element = view.accessors.getLiveElement(elementId);
  const elementBounds = element.getBoundingClientRect();

  const { positioningAreaBounds, positioningAreaClipPath, updateClipPath } = usePositioningArea(
    element,
    scrollPosition
  );

  const initialPositon = useMemo(() => {
    const { x: inlineX, y: inlineY } = getPositionCustomProperties(element);

    if (inlineX && inlineY) {
      return {
        x: inlineX,
        y: inlineY,
      };
    }

    const computedStyle = window.getComputedStyle(element);
    const x =
      parsePositionValue(computedStyle.getPropertyValue(positionProperties.X)) ||
      DEFAULT_POSITION_X;
    const y =
      parsePositionValue(computedStyle.getPropertyValue(positionProperties.Y)) ||
      DEFAULT_POSITION_Y;

    return {
      x,
      y,
    };

    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  const [position, setPosition] = useState({ ...initialPositon });
  const [initialDragPosition, setInitialDragPosition] = useState({});
  const [lastDragDistance, setLastDragDistance] = useState(null);

  const getSuitablePosition = (distance, axisProp, startPosition) => {
    const distanceSign = Math.sign(distance);
    const absDistance = Math.abs(distance);

    return (
      getSuitableTransformPosition({
        elementBounds,
        areaBounds: positioningAreaBounds,
        distance: absDistance,
        axisProp,
        startPosition,
      }) * distanceSign
    );
  };

  const updatePosition = (xDistance, yDistance) => {
    const isXPositioningLocked = elementBounds.width > positioningAreaBounds.width;
    const isYPositioningLocked = elementBounds.height > positioningAreaBounds.height;

    if (isXPositioningLocked && isYPositioningLocked) {
      return;
    }

    let { x, y } = position;

    if (!isXPositioningLocked) {
      const xDistanceInPercentages = getSuitablePosition(
        xDistance,
        'width',
        convertPxToPercentages(xDistance, positioningAreaBounds.width)
      );

      x = Math.min(
        Math.max(initialDragPosition.x + xDistanceInPercentages, POSITION_X_MIN),
        POSITION_X_MAX
      );
    }

    if (!isYPositioningLocked) {
      const yDistanceInPercentages = getSuitablePosition(
        yDistance,
        'height',
        convertPxToPercentages(yDistance, positioningAreaBounds.height)
      );

      y = Math.min(
        Math.max(initialDragPosition.y + yDistanceInPercentages, POSITION_Y_MIN),
        POSITION_Y_MAX
      );
    }

    setPositionCustomProperties(element, { x, y });
    setPosition({
      x,
      y,
    });
  };

  useDrag([handleRef], {
    onStart() {
      setInitialDragPosition({ x: position.x, y: position.y });
      updateClipPath();
      onStart();
    },

    onMove(distance) {
      const isNoDistance = !distance[0] && !distance[1];
      const isDuplicatedDistance =
        lastDragDistance &&
        lastDragDistance[0] === distance[0] &&
        lastDragDistance[1] === distance[1];

      if (isNoDistance || isDuplicatedDistance) {
        return;
      }

      updateClipPath();
      updatePosition(distance[0], distance[1]);
      setLastDragDistance(distance);
      onMove();
    },

    onEnd() {
      setLastDragDistance(null);
      onEnd();
    },
  });

  return {
    positioningAreaBounds,
    positioningAreaClipPath,
  };
};

export default useDragAndPositioning;
