import React from 'react';
import PropTypes from 'prop-types';
import { i18next } from '@yola/ws-sdk';
import { Modal, Button } from '@yola/ws-ui';

function UpdateRequiredDialogContainer({ onAction, onDialogMainAction }) {
  const captions = {
    title: i18next.t('Update required'),
    content: i18next.t(
      'We have just released a new version of the application. Please, reload the app to update to the latest version. Don’t worry, you won’t lose your progress.'
    ),
    button: i18next.t('Update now'),
  };

  const handleClick = () => {
    onDialogMainAction();
    onAction();
  };

  return (
    <Modal
      resizable={false}
      draggable={false}
      centered
      overlay="visible"
      width={492}
      height="auto"
      className="ws-update-required-modal"
    >
      <div className="dialog-body">
        <span className="dialog-body__emoji" role="img" aria-label="Confetti Ball">
          &#x1f38a;
        </span>
        <h5 className="dialog-body__title">{captions.title}</h5>
        <p className="dialog-body__content">{captions.content}</p>
      </div>
      <div className="dialog-footer">
        <Button type="primary" size="large" onClick={handleClick}>
          {captions.button}
        </Button>
      </div>
    </Modal>
  );
}

UpdateRequiredDialogContainer.propTypes = {
  onAction: PropTypes.func,
  onDialogMainAction: PropTypes.func.isRequired,
};
UpdateRequiredDialogContainer.defaultProps = {
  onAction: Function.prototype,
};

export default UpdateRequiredDialogContainer;
