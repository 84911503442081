import { i18next } from '@yola/ws-sdk';
import dialogs from 'yola-editor/src/js/modules/dialogs';
import referralLinksStorage from 'yola-editor/src/js/modules/refer-a-friend/helpers/referral-links-storage';
import showShareDialog from 'yola-editor/src/js/modules/refer-a-friend/operations/show-share-dialog';

function showThankYouDialog({ selectedScore }) {
  const closeDialog = () => dialogs.operations.hide();
  const dialogType = dialogs.dialogTypes.SURVEY_THANK_YOU;
  const isDetractor = selectedScore < 7;
  let thankYouDialogData;

  if (isDetractor) {
    thankYouDialogData = {
      emoji: '&#x1f914;',
      captions: {
        emojiLabel: i18next.t('Thinking Face'),
        content: i18next.t(
          'We appreciate your feedback. We will use it to improve our product in future.'
        ),
        mainAction: i18next.t('Dismiss'),
      },
      onMainAction: closeDialog,
      onClose: closeDialog,
    };
  } else {
    thankYouDialogData = {
      captions: {
        content: i18next.t(
          'Thank you for such encouraging feedback. Please, take a moment to invite your friend or colleague to join our growing community.'
        ),
        mainAction: i18next.t('Recommend Yola to a friend'),
        secondaryAction: i18next.t('Close'),
      },
      onShow: () => referralLinksStorage.getAsync(),
      onMainAction: () => {
        referralLinksStorage.getAsync().then((links) => {
          showShareDialog(links);
        });
      },
      onSecondaryAction: closeDialog,
      onClose: closeDialog,
    };
  }

  dialogs.operations.show(dialogType, thankYouDialogData);
}

export default showThankYouDialog;
