import pageTypes from '../constants/page-types';
import HomePreview from '../components/page-previews/home-preview';
import AboutUsPreview from '../components/page-previews/about-us-preview';
import ContactsPreview from '../components/page-previews/contacts-preview';
import EmptyPreview from '../components/page-previews/empty-preview';
import FAQPreview from '../components/page-previews/faq-preview';
import MenuPreview from '../components/page-previews/menu-preview';
import OurWorkPreview from '../components/page-previews/our-work-preview';
import ProductsPreview from '../components/page-previews/products-preview';
import ServicesPreview from '../components/page-previews/services-preview';
import TestimonialsPreview from '../components/page-previews/testemonials-preview';

function getPagePreviewComponent(pageType) {
  switch (pageType) {
    case pageTypes.HOME:
      return HomePreview;
    case pageTypes.ABOUT_US:
      return AboutUsPreview;
    case pageTypes.SERVICES:
      return ServicesPreview;
    case pageTypes.PRODUCTS:
      return ProductsPreview;
    case pageTypes.MENU:
      return MenuPreview;
    case pageTypes.OUR_WORK:
      return OurWorkPreview;
    case pageTypes.CONTACT_US:
      return ContactsPreview;
    case pageTypes.TESTIMONIALS:
      return TestimonialsPreview;
    case pageTypes.FAQ:
      return FAQPreview;
    default:
      return EmptyPreview;
  }
}

export default getPagePreviewComponent;
