import { useLayoutEffect, useState, useEffect, useCallback } from 'react';
import { view, bowser } from '@yola/ws-sdk';
import resizePositions from '../constants/resize-handle-positions';

const useHandlePosition = ({
  element,
  scrollPosition = view.accessors.getScrollPosition(),
  positions = {
    [resizePositions.BOTTOM_CENTER]: {
      correctionX: 0,
      correctionY: 0,
    },
  },
}) => {
  const [handles, setHandles] = useState(positions);
  const [position, setPosition] = useState([
    {
      position: handles[0],
      coords: [0, 0],
    },
  ]);

  const updatePosition = useCallback(
    (newHandles) => {
      const elementRect = element.getBoundingClientRect();
      const currentHandles = newHandles || handles;
      const coords = {};

      Object.keys(currentHandles).forEach((pos) => {
        let x;
        let y;
        const correctionX = currentHandles[pos].correctionX || 0;
        const correctionY =
          (bowser.ios ? 0 : scrollPosition) + (currentHandles[pos].correctionY || 0);

        switch (pos) {
          case resizePositions.CENTER_LEFT: {
            x = elementRect.left + correctionX;
            y = elementRect.top + elementRect.height / 2 + correctionY;
            break;
          }
          case resizePositions.CENTER_RIGHT: {
            x = elementRect.left + elementRect.width + correctionX;
            y = elementRect.top + elementRect.height / 2 + correctionY;
            break;
          }
          case resizePositions.TOP_CENTER: {
            x = elementRect.left + elementRect.width / 2 + correctionX;
            y = elementRect.top + correctionY;
            break;
          }
          case resizePositions.TOP_LEFT: {
            x = elementRect.left + correctionX;
            y = elementRect.top + correctionY;
            break;
          }
          case resizePositions.TOP_RIGHT: {
            x = elementRect.left + elementRect.width + correctionX;
            y = elementRect.top + correctionY;
            break;
          }
          case resizePositions.BOTTOM_LEFT: {
            x = elementRect.left + correctionX;
            y = elementRect.top + elementRect.height + correctionY;
            break;
          }
          case resizePositions.BOTTOM_RIGHT: {
            x = elementRect.left + elementRect.width + correctionX;
            y = elementRect.top + elementRect.height + correctionY;
            break;
          }
          case resizePositions.CENTER_CENTER: {
            x = elementRect.left + elementRect.width / 2 + correctionX;
            y = elementRect.top + elementRect.height / 2 + correctionY;
            break;
          }
          default: {
            x = elementRect.left + elementRect.width / 2 + correctionX;
            y = elementRect.top + elementRect.height + correctionY;
          }
        }

        coords[pos] = [x, y];
      });

      setHandles(currentHandles);
      setPosition(coords);
      // eslint-disable-next-line yola/react-hooks/exhaustive-deps
    },
    [element, handles, scrollPosition]
  );

  useLayoutEffect(updatePosition, [element, updatePosition]);

  useEffect(() => {
    const handleUpdatePosition = () => updatePosition();
    element.ownerDocument.defaultView.addEventListener('resize', handleUpdatePosition);
    return () => {
      element.ownerDocument.defaultView.removeEventListener('resize', handleUpdatePosition);
    };
  }, [element, updatePosition]);

  return [position, updatePosition];
};

export default useHandlePosition;
