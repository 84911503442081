export default {
  a: 'IaI',
  A: 'IaI',
  b: 'IbI',
  B: 'IbI',
  c: 'IcI',
  C: 'IcI',
  d: 'IdI',
  D: 'IdI',
  e: 'IeI',
  E: 'IeI',
  f: 'IfI',
  F: 'IfI',
  g: 'IgI',
  G: 'IgI',
  h: 'IhI',
  H: 'IhI',
  i: 'IilI',
  I: 'IilI',
  j: 'IjI',
  J: 'IjI',
  k: 'IkI',
  K: 'IkI',
  l: 'IilI',
  L: 'IilI',
  m: 'ImI',
  M: 'ImI',
  n: 'InI',
  N: 'InI',
  o: 'IoqI',
  O: 'IoqI',
  p: 'IpI',
  P: 'IpI',
  q: 'IoqI',
  Q: 'IoqI',
  r: 'IrI',
  R: 'IrI',
  s: 'IsI',
  S: 'IsI',
  t: 'ItI',
  T: 'ItI',
  u: 'IuI',
  U: 'IuI',
  v: 'IvI',
  V: 'IvI',
  w: 'IwI',
  W: 'IwI',
  x: 'IxI',
  X: 'IxI',
  y: 'IyI',
  Y: 'IyI',
  z: 'IzI',
  Z: 'IzI',

  а: 'IaI',
  А: 'IaI',
  б: 'IbI',
  Б: 'IbI',
  в: 'IbI',
  В: 'IbI',
  г: 'IrI',
  Г: 'IrI',
  д: 'IdI',
  Д: 'IdI',
  е: 'IeI',
  Е: 'IeI',
  ё: 'IeI',
  Ё: 'IeI',
  ж: 'IxI',
  Ж: 'IxI',
  з: 'IeI',
  З: 'IeI',
  и: 'InI',
  И: 'InI',
  й: 'InI',
  Й: 'InI',
  к: 'IkI',
  К: 'IkI',
  л: 'InI',
  Л: 'InI',
  м: 'ImI',
  М: 'ImI',
  н: 'IhI',
  Н: 'InI',
  о: 'IoqI',
  О: 'IoqI',
  п: 'InI',
  П: 'InI',
  р: 'IpI',
  Р: 'IpI',
  с: 'IcI',
  С: 'IcI',
  т: 'ItI',
  Т: 'ItI',
  у: 'IyI',
  У: 'IuI',
  ф: 'IoqI',
  Ф: 'IoqI',
  х: 'IxI',
  Х: 'IxI',
  ц: 'IuI',
  Ц: 'IuI',
  ч: 'IhI',
  Ч: 'IhI',
  ш: 'ImI',
  Ш: 'ImI',
  щ: 'ImI',
  Щ: 'ImI',
  ъ: 'IbI',
  Ъ: 'IbI',
  ы: 'IbIIilI',
  Ы: 'IbIIilI',
  ь: 'IbI',
  Ь: 'IbI',
  э: 'IeI',
  Э: 'IeI',
  ю: 'IilIIoqI',
  Ю: 'IilIIoqI',
  я: 'IrI',
  Я: 'IrI',

  і: 'IilI',
  І: 'IilI',
  ї: 'IilI',
  Ї: 'IilI',
  ᴎ: 'InI',
  є: 'IeI',
  Є: 'IeI',
  ґ: 'IrI',
  Ґ: 'IrI',
};
