import setPositionCustomProperties from '../../../common/helpers/set-position-custom-properties';
import getSuitableTransformPosition from '../../../common/helpers/get-suitable-transform-position';
import resizeHandlePositions from '../../../common/constants/resize-handle-positions';
import positionProperties from '../../../common/constants/position-properties';

const suspendPositioningStyles = (element) => {
  const el = element;
  el.style.top = 'auto';
  el.style.left = 'auto';
  el.style.transform = 'none';
};

const removeTemporaryStyles = (element) => {
  element.style.removeProperty('top');
  element.style.removeProperty('bottom');
  element.style.removeProperty('left');
  element.style.removeProperty('right');
  element.style.removeProperty('transform');
};

const freezePosition = ({ element, positioningAreaBounds, direction }) => {
  const el = element;
  const elementBounds = el.getBoundingClientRect();
  const offsetLeft = elementBounds.left - positioningAreaBounds.left;
  const offsetTop = Math.abs(elementBounds.top - positioningAreaBounds.top);
  const positionLeft = `${offsetLeft}px`;
  const positionRight = `${positioningAreaBounds.width - (elementBounds.width + offsetLeft)}px`;
  const positionTop = `${offsetTop}px`;
  const positionBottom = `${positioningAreaBounds.height - (elementBounds.height + offsetTop)}px`;

  suspendPositioningStyles(element);

  // eslint-disable-next-line default-case
  switch (direction) {
    case resizeHandlePositions.TOP_LEFT: {
      el.style.right = positionRight;
      el.style.bottom = positionBottom;
      break;
    }

    case resizeHandlePositions.TOP_CENTER: {
      el.style.left = positionLeft;
      el.style.bottom = positionBottom;
      break;
    }

    case resizeHandlePositions.TOP_RIGHT: {
      el.style.left = positionLeft;
      el.style.bottom = positionBottom;
      break;
    }

    case resizeHandlePositions.BOTTOM_LEFT: {
      el.style.right = positionRight;
      el.style.top = positionTop;
      break;
    }

    case resizeHandlePositions.BOTTOM_CENTER: {
      el.style.left = positionLeft;
      el.style.top = positionTop;
      break;
    }

    case resizeHandlePositions.BOTTOM_RIGHT: {
      el.style.left = positionLeft;
      el.style.top = positionTop;
      break;
    }

    case resizeHandlePositions.CENTER_LEFT: {
      el.style.right = positionRight;
      el.style.top = positionTop;
      break;
    }

    case resizeHandlePositions.CENTER_RIGHT: {
      el.style.left = positionLeft;
      el.style.top = positionTop;
      break;
    }
  }
};

const unfreezePosition = ({ element, positioningAreaBounds }) => {
  const elementBounds = element.getBoundingClientRect();
  const x = getSuitableTransformPosition({
    elementBounds,
    areaBounds: positioningAreaBounds,
    distance: Math.abs(positioningAreaBounds.left - elementBounds.left),
    axisProp: 'width',
  });
  const y = getSuitableTransformPosition({
    elementBounds,
    areaBounds: positioningAreaBounds,
    distance: Math.abs(positioningAreaBounds.top - elementBounds.top),
    axisProp: 'height',
  });

  removeTemporaryStyles(element);
  setPositionCustomProperties(element, { x, y });
};

const fittingInPositioningArea = ({ element, positioningAreaBounds, currentY }) => {
  const elementBounds = element.getBoundingClientRect();
  const isElementOutsidePositioningAreaAlongY = elementBounds.height > positioningAreaBounds.height;

  if (isElementOutsidePositioningAreaAlongY) {
    setPositionCustomProperties(element, { y: 0 });
    return;
  }

  if (currentY !== null) {
    setPositionCustomProperties(element, { y: currentY });
  } else {
    element.style.removeProperty(positionProperties.Y);
  }
};

export { freezePosition, unfreezePosition, fittingInPositioningArea };
