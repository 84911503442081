import { anodum } from '@yola/ws-sdk';

const isNodeToRemove = (node) =>
  anodum.isCommentNode(node) || (anodum.isTextNode(node) && !anodum.isNonEmptyTextNode(node));

const prepareTargetElement = (element) => {
  const nodesToRemove = [];

  anodum.traverseNode(element, (node) => {
    if (isNodeToRemove(node)) {
      nodesToRemove.push(node);
    }
  });

  anodum.removeSetOfNodes(nodesToRemove);
};

export default prepareTargetElement;
