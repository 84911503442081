import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { bowser } from '@yola/ws-sdk';
import AiTextAssistant from '../components/ai-text-assistant';

const ENTER_KEYCODE = 13;
const USER_INPUT_ID = 'ai-text-assistant-user-input';

const filterAutocompleteOptions = ({ label }, query) =>
  label.toLowerCase().includes(query.toLowerCase());

const AiTextAssistantContainer = ({
  captions,
  autocompleteOptions,
  isLoading,
  onSelect,
  onSubmit,
  onRetry,
  onCancel,
}) => {
  const [userInput, setUserInput] = useState('');
  const [isRetryAvailable, setRetryStatus] = useState(false);
  const isMobile = bowser.mobile;

  const handleInputChange = (value) => {
    if (value === userInput) {
      return;
    }

    setUserInput(value);
    setRetryStatus(false);
  };

  const handleSubmit = () => {
    onSubmit(userInput);
    setRetryStatus(true);
  };

  const handleSelect = (option) => {
    const { value, label } = option;

    if (!value) {
      return;
    }

    setUserInput(label);
    const { withRetry = true } = onSelect(value);
    setRetryStatus(withRetry);
  };

  const handleKeyDown = (event, details) => {
    const { focusedOption } = details;

    if (
      event.keyCode !== ENTER_KEYCODE ||
      focusedOption ||
      document.activeElement?.id !== USER_INPUT_ID ||
      !userInput.trim() ||
      isLoading
    ) {
      setRetryStatus(false);
      return;
    }

    handleSubmit();
  };

  const iconButtonProps = isRetryAvailable
    ? {
        iconGlyph: 'reset',
        onClick: onRetry,
      }
    : {
        iconGlyph: 'paper-plane',
        onClick: handleSubmit,
      };

  return (
    <AiTextAssistant
      captions={captions}
      autocompleteOptions={autocompleteOptions}
      isMobile={isMobile}
      isLoading={isLoading}
      isRetryAvailable={isRetryAvailable}
      userInputId={USER_INPUT_ID}
      userInput={userInput}
      actionButtonProps={iconButtonProps}
      filterAutocompleteOptions={filterAutocompleteOptions}
      onChange={handleSelect}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      onCancel={onCancel}
    />
  );
};

AiTextAssistantContainer.propTypes = {
  captions: PropTypes.shape({
    placeholder: PropTypes.string.isRequired,
    loading: PropTypes.string.isRequired,
    cancel: PropTypes.string.isRequired,
  }).isRequired,
  autocompleteOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.shape(),
      formatLabel: PropTypes.func,
    })
  ),
  isLoading: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onRetry: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

AiTextAssistantContainer.defaultProps = {
  autocompleteOptions: [],
  isLoading: false,
  onCancel: Function.prototype,
};

export default AiTextAssistantContainer;
