import { useState } from 'react';

function useSetupSteps(props) {
  const {
    stepsIds,
    activeStepId,
    completedSteps: initialCompletedSteps = [],
    passedSteps: initialPassedSteps = [],
  } = props;
  const firstStepIndex = 0;
  const lastStepIndex = stepsIds.length - 1;
  const initialActiveStepIndex = stepsIds.indexOf(activeStepId);

  const [activeStepIndex, setActiveStepIndex] = useState(initialActiveStepIndex);
  const [passedSteps, setPassedSteps] = useState([...initialCompletedSteps, ...initialPassedSteps]);
  const [completedSteps, setCompletedSteps] = useState(initialCompletedSteps);

  const handlePassedState = (stepIndex) => {
    const stepId = stepsIds[stepIndex];

    if (!passedSteps.includes(stepId)) {
      setPassedSteps([...passedSteps, stepId]);
    }
  };

  const handleCompletedState = (stepIndex, state) => {
    if (stepIndex === lastStepIndex) {
      return;
    }

    const stepId = stepsIds[stepIndex];

    if (state && !completedSteps.includes(stepId)) {
      setCompletedSteps([...completedSteps, stepId]);
    }

    if (!state && completedSteps.includes(stepId)) {
      setCompletedSteps(completedSteps.filter((id) => id !== stepId));
    }
  };

  const handleChange = (targetStepIndex, options = {}) => {
    if (
      targetStepIndex < firstStepIndex ||
      targetStepIndex > lastStepIndex ||
      targetStepIndex === activeStepIndex
    ) {
      return;
    }

    const { markStepAsCompleted = false } = options;

    handlePassedState(activeStepIndex);
    handleCompletedState(activeStepIndex, markStepAsCompleted);

    setActiveStepIndex(targetStepIndex);
  };

  const onChangeStep = (stepId, options = {}) => {
    const targetStepIndex = stepsIds.indexOf(stepId);

    handleChange(targetStepIndex, options);
  };

  const onPrevStep = (options = {}) => {
    const targetStepIndex = activeStepIndex - 1;

    handleChange(targetStepIndex, options);
  };

  const onNextStep = (options = {}) => {
    const targetStepIndex = activeStepIndex + 1;

    handleChange(targetStepIndex, options);
  };

  const steps = stepsIds.map((stepId, i) => ({
    id: stepId,
    marker: i + 1,
    active: i === activeStepIndex,
    passed: passedSteps.includes(stepId),
    completed: completedSteps.includes(stepId),
    disabled: !passedSteps.includes(stepId),
  }));

  const getStepsState = () => ({
    activeStepId: stepsIds[activeStepIndex],
    passedSteps,
    completedSteps,
  });

  return {
    steps,
    activeStepId: stepsIds[activeStepIndex],
    isFirstStep: activeStepIndex === firstStepIndex,
    isLastStep: activeStepIndex === lastStepIndex,
    getStepsState,
    onChangeStep,
    onPrevStep,
    onNextStep,
  };
}

export default useSetupSteps;
