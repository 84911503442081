import getCssPropertyByNode from './get-css-property-by-node';
import getSubmoduleConfig from '../../../helpers/get-submodule-config';
import { SUBMODULE_NAME } from '../constants/index';
import getTextAreas from './get-text-areas';

const calculateCssPropertiesBySurface = (block) => {
  const { overlayColorVariableName, recomendedDarkSurfaceName } =
    getSubmoduleConfig(SUBMODULE_NAME);

  const clonedBlock = block.cloneNode(true);
  const { ownerDocument } = block;
  clonedBlock.style.position = 'absolute';
  clonedBlock.style.opacity = '0';
  clonedBlock.style.top = '-9999px';
  clonedBlock.style.left = '-9999px';
  ownerDocument.body.appendChild(clonedBlock);
  clonedBlock.setAttribute('surfaces', recomendedDarkSurfaceName);
  const [child] = clonedBlock.children;

  const overlayColor = getCssPropertyByNode(child, overlayColorVariableName);
  const textAreas = getTextAreas(clonedBlock);

  clonedBlock.remove();

  return {
    overlay: {
      opacity: Number(block.getAttribute('opacity')),
      color: overlayColor,
    },
    textAreas,
  };
};

export default calculateCssPropertiesBySurface;
