import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { i18next } from '@yola/ws-sdk';
import dialogs from 'yola-editor/src/js/modules/dialogs';
import ActionDialog from '../components/action-dialog';

function UnpublishSuccessDialogContainer(props) {
  const dispatch = useDispatch();
  const captions = {
    title: i18next.t('Site unpublished'),
    description: i18next.t('Your website was successfully unpublished'),
    cancel: i18next.t('Dismiss'),
  };

  function handleCancel() {
    const { onDialogCancel } = props;
    onDialogCancel();
    dispatch(dialogs.actions.hide());
  }

  return <ActionDialog captions={captions} onCancel={handleCancel} {...props} />;
}

UnpublishSuccessDialogContainer.propTypes = {
  onDialogCancel: PropTypes.func.isRequired,
};

export default UnpublishSuccessDialogContainer;
