import { template, view } from '@yola/ws-sdk';
import attributeValuesList from 'src/js/modules/utils/attribute-values-list';
import getOptionTargetElement from './get-option-target-element';

const createCheckboxOption = ({ item, blockNode, displayOptions, onClick }) => {
  const targetElement = getOptionTargetElement(item, blockNode);
  if (!targetElement) return null;

  const targetElementId = view.accessors.getLiveElementId(targetElement);
  const existedOptions = displayOptions && displayOptions.find((option) => option.id === item.id);
  const isLiveAttributeEnabled =
    'value' in item && typeof item.value !== 'boolean'
      ? attributeValuesList.contains(targetElement, item.attribute, item.value)
      : targetElement.hasAttribute(item.attribute);

  const isEnabled = item.inverted ? !isLiveAttributeEnabled : isLiveAttributeEnabled;
  const isInitialEnabled = existedOptions ? existedOptions.isInitialEnabled : isEnabled;

  return {
    ...item,
    onClick,
    isInitialEnabled,
    isEnabled,
    elementId: targetElementId,
    label: template.accessors.getMetaTranslations(item.label),
    title: template.accessors.getMetaTranslations(item.title),
    subtitle: template.accessors.getMetaTranslations(item.subtitle),
  };
};

export default createCheckboxOption;
