import normalizeUrl from '../../extensions/registry/ws-social-links/helpers/normalize-url';
import fieldNames from '../constants/field-names';

const prepareSetupSummaryData = (fields) => {
  const siteName = fields[fieldNames.SITE_NAME];
  const businessCategory =
    fields[fieldNames.BUSINESS_CATEGORY] && fields[fieldNames.BUSINESS_CATEGORY].label;
  const businessName = fields[fieldNames.BUSINESS_NAME];
  const description = fields[fieldNames.DESCRIPTION];
  const logo = fields[fieldNames.LOGO] && URL.createObjectURL(fields[fieldNames.LOGO]);
  const contacts = [
    fields[fieldNames.ADDRESS],
    fields[fieldNames.PHONE],
    fields[fieldNames.EMAIL],
    fields[fieldNames.HOURS],
  ].filter(Boolean);
  const socialLinks = fields[fieldNames.SOCIAL_LINKS]
    .filter(({ link }) => link.trim())
    .map(({ link }) => normalizeUrl(link.trim()));

  return {
    siteName,
    businessCategory,
    businessName: businessName === siteName ? null : businessName,
    description,
    logo,
    contacts,
    socialLinks,
  };
};

export default prepareSetupSummaryData;
