import actions from './actions';
import accessors from './accessors';
import reducers from './reducers';
import middleware from './middleware';
import operations from './operations';
import selectors from './selectors';
import actionTypes from './constants/action-types';
import preferences from './constants/preferences';
import platformComponents from './constants/platform-components';
import verifiers from './verifiers';
import constants from './constants';

export default {
  actions,
  accessors,
  reducers,
  selectors,
  middleware,
  operations,
  actionTypes,
  preferences,
  platformComponents,
  verifiers,
  constants,
};
