import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import ButtonGroup from 'yola-editor/src/js/components/button-group';
import Button from 'yola-editor/src/js/components/button';
import Render from 'yola-editor/src/js/components/render';
import Loader from 'yola-editor/src/js/components/loader';
import noop from '../../../utils/noop';

function ProgressDialog(props) {
  const {
    captions: { title, description, cancel },
    onCancel,
    cancelable,
    showProgress,
    progressType,
    value,
  } = props;
  return (
    <ReactModal
      isOpen
      onRequestClose={cancelable ? onCancel : null}
      className="dialog dialog--centered dialog--progress"
      overlayClassName="dialog-overlay"
    >
      <Render if={showProgress}>
        <Loader value={value} type={progressType} />
      </Render>
      <div className="dialog__content">
        <h5 className="dialog__title">{title}</h5>
        <p className="dialog__description">{description}</p>
      </div>
      <Render if={cancelable}>
        <ButtonGroup block stick="top">
          <Button stretch="block" size="large" onMouseDown={onCancel}>
            {cancel}
          </Button>
        </ButtonGroup>
      </Render>
    </ReactModal>
  );
}

ProgressDialog.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    cancel: PropTypes.string,
    submit: PropTypes.string,
  }).isRequired,
  value: PropTypes.number,
  showProgress: PropTypes.bool,
  progressType: PropTypes.oneOf(['determinate', 'indeterminate']),
  cancelable: PropTypes.bool,
  onCancel: PropTypes.func,
};

ProgressDialog.defaultProps = {
  onCancel: noop,
  cancelable: false,
  showProgress: true,
  value: 0,
  progressType: 'indeterminate',
};

export default ProgressDialog;
