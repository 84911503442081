import { site, dialogs } from '@yola/ws-sdk';
import bowser from 'yola-bowser';

const setActivePage = (store) => (next) => (action) => {
  if (action.type === site.actionTypes.SET_ACTIVE_PAGE && bowser.mobile) {
    store.dispatch(dialogs.actions.hide());
  }
  next(action);
};

export default setActivePage;
