import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { dialogs, view } from '@yola/ws-sdk';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import adjacentPositions from 'src/js/modules/blocks/constants/adjacent-positions';

const useAddWidgetEvent = (element) => {
  const dispatch = useDispatch();
  const eventName = 'ws:addWidget';

  useEffect(() => {
    const handler = (event) => {
      const { target } = event.detail.originalEvent;
      const targetElementId = view.accessors.getLiveElementId(target);
      const adjacentPosition = adjacentPositions.BEFORE_END;

      dispatch(dialogs.actions.show(dialogTypes.ADD_WIDGET, { targetElementId, adjacentPosition }));
    };

    element.addEventListener(eventName, handler);

    return () => {
      element.removeEventListener(eventName, handler);
    };
  }, [element, dispatch]);
};

export default useAddWidgetEvent;
