import { anodum, hooks } from '@yola/ws-sdk';
import helpers from '../helpers';

const elementCanBeEdited = (element, attribute) => {
  if (!element || attribute !== 'src') return false;

  return anodum.isTag(element, 'ws-iframe');
};

const onBeforeSetAttribute = (element, attribute, value) => {
  if (
    elementCanBeEdited(element, attribute) &&
    !helpers.doesElementHaveDefaultSrc(element, value)
  ) {
    element.removeAttribute('content-untouched');
  }
};

const onBeforeSetElementAttribute = () => {
  hooks.operations.subscribe(
    hooks.availableHooks.ON_BEFORE_SET_STATIC_ELEMENT_ATTRIBUTE,
    onBeforeSetAttribute
  );

  hooks.operations.subscribe(
    hooks.availableHooks.ON_BEFORE_SET_LIVE_ELEMENT_ATTRIBUTE,
    onBeforeSetAttribute
  );
};

export default onBeforeSetElementAttribute;
