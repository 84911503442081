import React, { useState, useEffect, useMemo } from 'react';
import { view, dialogs, utils, i18next } from '@yola/ws-sdk';
import PropTypes from 'prop-types';
import { TreeView } from '@yola/ws-ui';
import classNames from 'classnames';
import segment from 'src/js/modules/analytics/segment';
import LinkListSettingsDialogContainer from 'src/js/modules/common/components/link-list-settings-dialog-container';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import constants from '../constants';
import getDialogPosition from '../helpers/get-dialog-position';
import buildNewMarkup from '../helpers/build-new-markup';
import getTitleFromHref from '../helpers/get-title-from-href';
import getDefaultTraits from '../../../../analytics/segment/helpers/get-default-traits';
import itemsAddedCount from '../../../common/helpers/items-added-count';
import itemsDeletedCount from '../../../common/helpers/items-deleted-count';
import linksEditCount from '../helpers/links-edit-count';

const {
  track,
  constants: { events },
} = segment;

const { selectors, common } = constants;
const DIALOG_HEIGHT = 472;

const closeDialog = () => {
  dialogs.operations.hide();
};

const SocialLinksSettingsDialog = ({ elementId, items, getSharedData, resolveSharedData }) => {
  const sharedData = getSharedData();
  const {
    offsetX: dialogOffsetX,
    offsetY: dialogOffsetY,
    initialValues,
    orderChanged,
  } = sharedData;
  const [dialogPosition, setDialogPosition] = useState({
    offsetX: dialogOffsetX,
    offsetY: dialogOffsetY,
  });

  useEffect(() => {
    if (!initialValues) {
      resolveSharedData({
        initialValues: items,
        orderChanged: false,
      });
    }
  }, [initialValues, items, resolveSharedData]);

  useEffect(() => {
    if (dialogPosition.offsetX === undefined || dialogPosition.offsetY === undefined) {
      setDialogPosition(getDialogPosition(elementId, common.DIALOG_WIDTH, DIALOG_HEIGHT));
    }
  }, [elementId, dialogPosition]);

  const openChangeItemDialog = ({ items: updatedItems, item, offsetX, offsetY }) => {
    resolveSharedData({
      elementId,
      items: updatedItems,
      offsetX,
      offsetY,
      isTouched: false,
    });

    closeDialog();

    dialogs.operations.show(dialogTypes.SOCIAL_LINKS_ITEM_SETTINGS_DIALOG, {
      targetLink: item,
      elementId,
    });
  };

  const closeDialogTracking = () => {
    track(events.SOCIAL_LINKS_SETTINGS_DIALOG_CANCELED, {
      ...getDefaultTraits(elementId),
      dialogId: dialogTypes.SOCIAL_LINKS_SETTINGS_DIALOG,
    });
  };

  const applyChanges = ({ items: updatedItems }) => {
    const targetLiveElement = view.accessors.getLiveElement(elementId);
    const listLiveElement = targetLiveElement.querySelector(selectors.listContainer);
    const listLiveElementId = view.accessors.getLiveElementId(listLiveElement);
    const listStaticElement = view.accessors.getStaticElement(listLiveElementId);

    const newTargetElement = buildNewMarkup(listStaticElement, updatedItems);
    view.operations.replaceElement(newTargetElement, listLiveElementId);

    closeDialog();

    track(events.SOCIAL_LINKS_SETTINGS_DIALOG_SUBMITTED, {
      ...getDefaultTraits(elementId),
      dialogId: dialogTypes.SOCIAL_LINKS_SETTINGS_DIALOG,
      linksAddedCount: itemsAddedCount(initialValues, updatedItems),
      linksDeletedCount: itemsDeletedCount(initialValues, updatedItems),
      linksEditedCount: linksEditCount(initialValues, updatedItems),
      linksOrderChanged: orderChanged,
    });
  };

  const updatedItems = useMemo(
    () =>
      items.map((item) => ({
        ...item,
        title: getTitleFromHref(item.config.reference),
        customDragTrigger: (
          <img
            src={item.iconSrc}
            className={classNames('ws-social-links-icon', {
              'ws-social-links-icon--sm': item.iconIdAttr,
            })}
            alt=""
          />
        ),
      })),
    [items]
  );

  return (
    <LinkListSettingsDialogContainer
      items={updatedItems}
      initialItems={updatedItems}
      offsetX={dialogPosition.offsetX}
      offsetY={dialogPosition.offsetY}
      onSubmit={applyChanges}
      onClose={() => {
        closeDialog();
        closeDialogTracking();
      }}
      onOpenChangeItemDialog={openChangeItemDialog}
      captions={{
        dialogTitle: i18next.t('Social links settings'),
        submit: i18next.t('Submit'),
        cancel: i18next.t('Cancel'),
        emptyTitle: i18next.t('Add item'),
        emptyDescription: i18next.t('Start building your social links'),
      }}
      interaction="sortable"
      elementId={elementId}
      handleDeleteItem={() => {
        track(events.SOCIAL_LINKS_DELETE_LINK_BUTTON_CLICKED, {
          ...getDefaultTraits(elementId),
        });
      }}
      handleEditItem={() => {
        track(events.SOCIAL_LINKS_EDIT_LINK_BUTTON_CLICKED, {
          ...getDefaultTraits(elementId),
        });
      }}
      handleOrderChange={() => {
        track(events.SOCIAL_LINKS_ITEMS_ORDER_CHANGED, {
          ...getDefaultTraits(elementId),
        });
        resolveSharedData({
          orderChanged: true,
        });
      }}
      handleAddNewItem={() => {
        track(events.SOCIAL_LINKS_ADD_LINK_BUTTON_CLICKED, {
          ...getDefaultTraits(elementId),
        });
      }}
    />
  );
};

SocialLinksSettingsDialog.propTypes = {
  elementId: PropTypes.string,
  items: TreeView.propTypes.items,
  getSharedData: PropTypes.func,
  resolveSharedData: PropTypes.func,
};

SocialLinksSettingsDialog.defaultProps = {
  items: null,
  elementId: null,
  getSharedData: utils.noop,
  resolveSharedData: utils.noop,
};

export default SocialLinksSettingsDialog;
