import showContactForm from './show-contact-form';
import showSearchForm from './show-search-form';
import hideForm from './hide-form';
import preload from './preload';
import closeForm from './close-form';
import showForm from './show-form';

export default {
  showContactForm,
  showSearchForm,
  hideForm,
  preload,
  closeForm,
  showForm,
};
