import ImagePositioningTool from '../components/image-positioning-tool';
import isPositioningImage from '../helpers/is-positioning-image';

const imagePositioningToolSettings = {
  matches(element) {
    return isPositioningImage(element);
  },
  container: ImagePositioningTool,
};
export default imagePositioningToolSettings;
