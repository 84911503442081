import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { utils } from '@yola/ws-sdk';
import NavContext from '../nav-context';
import SubMenuGroup from './sub-menu-group';
import NavMenuItem from './nav-menu-item';
import Banner from '../banner';
import BrandContainer from '../../../brand/containers/brand-container';
import ChecklistProgressLine from '../../../site-progress-checklist/components/checklist-progress-line';

function NavCollapse({ items, onShow, isBrand, banners, checklistProgressData }) {
  const { close, isOpen } = useContext(NavContext);

  const { isEnabled, steps, stepsCompleted } = checklistProgressData;
  const isChecklistProgress = isEnabled && stepsCompleted < steps;

  const classes = classNames('ws-nav__collapse', {
    'ws-nav__collapse--active': isOpen,
  });
  const headerClasses = classNames('ws-nav__collapse-header', {
    'ws-nav__collapse-header--separator': isChecklistProgress,
  });

  useEffect(() => {
    if (!isOpen) {
      SubMenuGroup.dispatchClose();
    } else {
      onShow();

      banners.forEach(({ onShow: onBannerShow }) => {
        if (onBannerShow) {
          onBannerShow();
        }
      });
    }
  }, [isOpen, onShow, banners]);

  return (
    <div className={classes}>
      <div className="ws-nav__collapse-inner">
        {(isBrand || isChecklistProgress) && (
          <div className={headerClasses}>
            {isBrand && <BrandContainer />}
            {isChecklistProgress && (
              <ChecklistProgressLine
                captions={{
                  heading: checklistProgressData.heading,
                  title: checklistProgressData.title,
                }}
                steps={{
                  all: steps,
                  completed: stepsCompleted,
                }}
                onClick={() => {
                  close();
                  checklistProgressData.onClick();
                }}
              />
            )}
          </div>
        )}
        <ul className="ws-nav__collapse-main">
          {items
            .filter((item) => item.id !== 'dashboard')
            .map((item, index) => (
              // eslint-disable-next-line yola/react/no-array-index-key
              <NavMenuItem key={index} item={item} />
            ))}
        </ul>
        <div className="ws-nav__collapse-footer">
          {banners.map((banner, indx) => {
            const { id, isInteractive, icon, captions, onClick } = banner;
            return (
              <Banner
                key={`${id}_${indx}`}
                interactive={isInteractive}
                icon={icon}
                captions={captions}
                onClick={() => {
                  if (onClick) {
                    close();
                    onClick();
                  }
                }}
              />
            );
          })}
          {items
            .filter((item) => item.id === 'dashboard')
            .map((item, index) => (
              // eslint-disable-next-line yola/react/no-array-index-key
              <ul key={index} className="ws-nav__collapse-footer-list">
                <NavMenuItem item={item} />
              </ul>
            ))}
        </div>
      </div>
    </div>
  );
}

NavCollapse.propTypes = {
  items: PropTypes.array.isRequired,
  onShow: PropTypes.func,
  isBrand: PropTypes.bool,
  banners: PropTypes.array,
  checklistProgressData: PropTypes.shape({
    isEnabled: PropTypes.bool,
    heading: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    steps: PropTypes.number.isRequired,
    stepsCompleted: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
};

NavCollapse.defaultProps = {
  onShow: utils.noop,
  isBrand: true,
  banners: [],
  checklistProgressData: {
    isEnabled: false,
  },
};

export default NavCollapse;
