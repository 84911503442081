import React from 'react';
import PropTypes from 'prop-types';
import { Text, TagsInputField, Field, designSystem } from '@yola/ws-ui';
import InputGroupField from '../../common/components/input-group-field';
import CheckboxField from '../../common/components/checkbox-field';
import TextAreaField from '../../common/components/textarea-field';
import Annotation from './annotation';
import SearchPreview from './search-preview';

const { Stack, Heading, ControlGroup, CheckboxGroup, Mark } = designSystem;

function SeoSection({
  formValues,
  pathPrefix,
  businessName,
  captions,
  titleTagRef,
  descriptionRef,
  isBusinessInfoInWebsiteSettingsEnabled,
  isSameAsPageName,
  validateTitleTag,
  validateDescription,
  onChangeSameAsPageName,
}) {
  let { title } = formValues;
  const path = `${pathPrefix}${formValues.path}`;
  const description = formValues.description?.trim()
    ? formValues.description
    : captions.searchPreview.description;

  if (isBusinessInfoInWebsiteSettingsEnabled && businessName && formValues.displayBusinessName) {
    if (title) {
      title = `${title} | ${businessName}`;
    } else {
      title = businessName;
    }
  }

  return (
    <Stack gap="spacing-m">
      <Stack gap="spacing-3-xs">
        <Heading type="heading-6">{captions.searchPreview.heading}</Heading>
        <SearchPreview
          title={title || captions.searchPreview.title}
          path={path}
          description={description}
        />
      </Stack>

      <ControlGroup title={captions.title.name}>
        <div ref={titleTagRef}>
          <Field
            component={InputGroupField}
            name="title"
            subtitle={captions.title.description}
            disabled={isSameAsPageName}
            validate={validateTitleTag}
          />
        </div>
      </ControlGroup>

      <CheckboxGroup>
        <Field
          component={CheckboxField}
          id="sameAsPageNameCheckbox"
          name="sameAsPageName"
          label={captions.sameAsPageName.label}
          checked={isSameAsPageName}
          onClick={onChangeSameAsPageName}
        />

        {isBusinessInfoInWebsiteSettingsEnabled && (
          <Field
            component={CheckboxField}
            id="displayBusinessNameCheckbox"
            name="displayBusinessName"
            label={captions.displayBusinessName.label}
            description={
              !businessName && (
                <>
                  {captions.displayBusinessName.description}
                  <Mark appearance="high-emphasis">
                    {captions.displayBusinessName.settingsTitle} &#x2192;{' '}
                    {captions.displayBusinessName.businessInfoTitle}
                  </Mark>
                </>
              )
            }
            checked={formValues.displayBusinessName}
            disabled={!businessName}
          />
        )}
      </CheckboxGroup>

      <Field
        component={CheckboxField}
        id="preventIndexingCheckbox"
        name="preventIndexing"
        label={captions.preventIndexing.name}
        description={captions.preventIndexing.description}
        checked={formValues.preventIndexing}
      />

      <ControlGroup title={captions.description.name}>
        <div ref={descriptionRef}>
          <Field
            component={TextAreaField}
            name="description"
            statusText={captions.description.description}
            validate={validateDescription}
            resize
          />
        </div>
      </ControlGroup>

      <div>
        <Text type="heading-6" className="ws-page-settings__keywords-title">
          {captions.keywords.name}
        </Text>
        <Field component={TagsInputField} name="keywords" translate="no" />
        <Annotation description={captions.keywords.description} link={captions.keywords.link} />
      </div>
    </Stack>
  );
}

SeoSection.propTypes = {
  formValues: PropTypes.shape({
    title: PropTypes.string,
    path: PropTypes.string,
    description: PropTypes.string,
    displayBusinessName: PropTypes.bool,
    preventIndexing: PropTypes.bool,
  }).isRequired,
  pathPrefix: PropTypes.string.isRequired,
  businessName: PropTypes.string,
  captions: PropTypes.shape({
    name: PropTypes.string,
    searchPreview: {
      heading: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
    },
    title: PropTypes.shape({
      name: PropTypes.string.isRequired,
      error: PropTypes.string.isRequired,
      description: PropTypes.string,
    }).isRequired,
    description: PropTypes.shape({
      name: PropTypes.string.isRequired,
      error: PropTypes.string.isRequired,
      description: PropTypes.string,
      link: PropTypes.shape({
        text: PropTypes.string,
        url: PropTypes.string,
      }),
    }).isRequired,
    sameAsPageName: PropTypes.shape({
      label: PropTypes.string.isRequired,
    }).isRequired,
    displayBusinessName: PropTypes.shape({
      label: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      settingsTitle: PropTypes.string.isRequired,
      businessInfoTitle: PropTypes.string.isRequired,
    }).isRequired,
    preventIndexing: PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
    }).isRequired,
    keywords: PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      link: PropTypes.shape({
        text: PropTypes.string,
        url: PropTypes.string,
      }),
    }).isRequired,
  }).isRequired,
  titleTagRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.instanceOf(Element),
    }),
  ]).isRequired,
  descriptionRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.instanceOf(Element),
    }),
  ]).isRequired,
  isBusinessInfoInWebsiteSettingsEnabled: PropTypes.bool.isRequired,
  isSameAsPageName: PropTypes.bool.isRequired,
  validateTitleTag: PropTypes.func.isRequired,
  validateDescription: PropTypes.func.isRequired,
  onChangeSameAsPageName: PropTypes.func.isRequired,
};

SeoSection.defaultProps = {
  businessName: '',
};

export default SeoSection;
