import getSameBoundsElements from '../../common/helpers/get-same-bounds-elements';

function getMatchedMenuGroups(targetElement, menuGroups, featureFlags = {}) {
  const matchedMenuGroups = [];
  if (!targetElement) {
    return matchedMenuGroups;
  }

  const sameBoundsElements = targetElement ? getSameBoundsElements(targetElement) : [];

  sameBoundsElements.forEach((element) => {
    menuGroups.forEach((group) => {
      const filteredItems = group.items.filter((item) => item.matches(element, featureFlags));

      if (filteredItems.length) {
        const menuItems = filteredItems.map((item) => ({
          ...item,
          ...(item.onClick && { onClick: item.onClick.bind(item, element) }),
        }));

        // We should push only unique groups, so there are cases when matched one group twice
        if (matchedMenuGroups.find((matchedGroup) => matchedGroup.name === group.name)) return;

        const preparedElement = (group.prepareElement && group.prepareElement(element)) || element;
        matchedMenuGroups.push({ ...group, items: menuItems, element: preparedElement });
      }
    });
  });

  return matchedMenuGroups;
}

export default getMatchedMenuGroups;
