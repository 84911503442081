import getCaptionForElement from '../../common/helpers/get-caption-for-element';
import getTextWidth from '../../control-pane/helpers/get-text-width';

// Labeled drag trigger styles that needed for element width calculation
// are stored as custom CSS properties to get and share them easier
function getCssCustomPropValue(prop, targetDocument) {
  return getComputedStyle(targetDocument.documentElement).getPropertyValue(prop);
}

function getCssCustomPropNumericValue(prop, targetDocument) {
  return parseInt(getCssCustomPropValue(prop, targetDocument), 10);
}

function getDragTriggerContainerLabeledProps(
  targetElement,
  targetDocument = document,
  isMergedWithTextTriggers
) {
  const glyphSize = 24;

  if (isMergedWithTextTriggers) {
    // According to design, the drag trigger has
    // a specific width when rendered without label
    const widthWithoutLabel = getCssCustomPropNumericValue(
      '--ws-drag-trigger-width--without-label',
      targetDocument
    );

    return {
      glyph: 'drag',
      glyphSize,
      width: widthWithoutLabel,
    };
  }

  const { label } = getCaptionForElement(targetElement);
  const labelFontSize = getCssCustomPropValue('--ws-trigger-label__font-size', targetDocument);
  const labelFontFamily = getCssCustomPropValue('--ws-trigger-label__font-family', targetDocument);
  const labelTextWidthRaw = getTextWidth(
    label,
    `${labelFontSize} ${labelFontFamily}`,
    targetDocument
  );
  const labelMaxWidth = 110;
  const textEllipsisThreshold = 2; // Prevent text-overflow to be triggered too early
  const labelTextWidth =
    labelTextWidthRaw < labelMaxWidth
      ? labelTextWidthRaw + textEllipsisThreshold
      : labelMaxWidth + textEllipsisThreshold;

  const triggerInnerPadding = getCssCustomPropNumericValue(
    '--ws-trigger__inner-padding',
    targetDocument
  );
  const labelPaddingLeft = getCssCustomPropNumericValue(
    '--ws-trigger-label__padding-left',
    targetDocument
  );
  const labelPaddingRight = getCssCustomPropNumericValue(
    '--ws-trigger-label__padding-right',
    targetDocument
  );
  const triggerTotalWidth =
    triggerInnerPadding * 2 + glyphSize + labelPaddingLeft + labelTextWidth + labelPaddingRight;

  return {
    glyph: 'drag',
    glyphSize,
    width: triggerTotalWidth,
    label,
  };
}

export default getDragTriggerContainerLabeledProps;
