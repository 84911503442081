import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { ButtonGroup, Button, Icon } from '@yola/ws-ui';
import { i18next } from '@yola/ws-sdk';

function ResultDialog(props) {
  const {
    captions: { title, description, close },
    imgClass,
    onBackButtonClick,
    onClose,
  } = props;

  return (
    <ReactModal
      isOpen
      overlayClassName="dialog-overlay--shaded"
      className="dialog--overlay-controls-container"
    >
      {onBackButtonClick && (
        <button type="button" className="dialog__overlay-back-button" onClick={onBackButtonClick}>
          <Icon glyph="chevron-left" strokeWidth="1.3" />
          {i18next.t('Back')}
        </button>
      )}

      <CSSTransition in appear timeout={0} classNames="dialog--animated">
        <div className="dialog dialog--centered dialog--result">
          <div className="dialog__content">
            <div className={imgClass} />
            <h5 className="dialog__title">
              {/* eslint-disable-next-line yola/react/no-danger */}
              <span dangerouslySetInnerHTML={{ __html: title }} />
            </h5>
            <p className="dialog__description">
              {/* eslint-disable-next-line yola/react/no-danger */}
              <span dangerouslySetInnerHTML={{ __html: description }} />
            </p>
          </div>
          <ButtonGroup block stick="top">
            <Button stretch="block" size="large" onClick={onClose}>
              {close}
            </Button>
          </ButtonGroup>
        </div>
      </CSSTransition>
    </ReactModal>
  );
}

ResultDialog.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    close: PropTypes.string,
  }).isRequired,
  imgClass: PropTypes.string,
  onBackButtonClick: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

ResultDialog.defaultProps = {
  imgClass: '',
  onBackButtonClick: null,
};

export default ResultDialog;
