const TOOLTIP_HEIGHT = 16;
const TOOLTIP_OFFSET = 10;
const TRIGGER_BUTTON_HEIGHT = 48;
const ENOUGH_SPACE_FOR_TOOLTIP = TOOLTIP_OFFSET * 2 + TOOLTIP_HEIGHT + TRIGGER_BUTTON_HEIGHT / 2;

const getTooltipDirection = (triggerPositionY, scrollPosition) => {
  const IS_NOT_ENOUGH_SPACE_ABOVE_FOR_TOOLTIP =
    triggerPositionY - scrollPosition < ENOUGH_SPACE_FOR_TOOLTIP;

  if (IS_NOT_ENOUGH_SPACE_ABOVE_FOR_TOOLTIP) {
    return 'down';
  }

  return 'up';
};

export default getTooltipDirection;
