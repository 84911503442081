import { customization, dialogs, site } from '@yola/ws-sdk';
import dialogsTypes from '../../dialogs/constants/dialog-types';
import { snakeCaseObjectToCamelCase } from '../../utils/convert-case';

const { actionTypes } = dialogs;

const colorPaletteConfigLoader = (store) => (next) => (action) => {
  if (
    action.type !== actionTypes.SHOW_DIALOG ||
    action.payload.type !== dialogsTypes.WEBSITE_DESIGN
  ) {
    next(action);
    return;
  }

  const state = store.getState();
  const colorPaletteConfigUrl = customization.selectors.getColorPaletteConfigAssetUrl(state);
  const colorPaletteConfig = customization.selectors.getColorPaletteConfig(state);

  if (colorPaletteConfigUrl && !colorPaletteConfig) {
    site.helpers
      .fetchAsset(colorPaletteConfigUrl)
      .then(({ data }) => {
        store.dispatch(
          customization.actions.setColorPaletteConfig(snakeCaseObjectToCamelCase(data))
        );
      })
      .catch(console.error);
  }

  next(action);
};

export default colorPaletteConfigLoader;
