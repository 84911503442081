const shallowProxyAttributes = (from, to, attributes) => {
  attributes.forEach((attributeName) => {
    if (!from.hasAttribute(attributeName)) return;

    const value = from.getAttribute(attributeName);
    to.setAttribute(attributeName, value);
  });
};

export default shallowProxyAttributes;
