import bowser from 'yola-bowser';
import sizes from '../constant/color-settings-sizes';

const detectModalWidth = () => {
  if (bowser.mobile) {
    return sizes.MODAL_SMALL_WIDTH;
  }
  if (bowser.tablet) {
    return sizes.MODAL_MEDIUM_WIDTH;
  }
  return sizes.MODAL_LARGE_WIDTH;
};

export default detectModalWidth;
