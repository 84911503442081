import { view } from '@yola/ws-sdk';
import { MAX_SPEED, AUTOSCROLL_AREA_HEIGHT } from '../constants/autoscroll';

const { autoScroll } = view.helpers;

const getAutoScrollConfig = (bounds, offset, scrollPosition) => {
  let speed = 0;
  let scrollDirection = autoScroll.SCROLL_DOWN;

  if (scrollPosition > 0 && offset.y < AUTOSCROLL_AREA_HEIGHT) {
    scrollDirection = autoScroll.SCROLL_UP;
    speed = MAX_SPEED - (MAX_SPEED * (offset.y - bounds.top)) / AUTOSCROLL_AREA_HEIGHT;
  } else if (offset.y > bounds.height - AUTOSCROLL_AREA_HEIGHT) {
    scrollDirection = autoScroll.SCROLL_DOWN;
    speed = MAX_SPEED - (MAX_SPEED * (bounds.height - offset.y)) / AUTOSCROLL_AREA_HEIGHT;
  }

  return { speed, direction: scrollDirection };
};

export default getAutoScrollConfig;
