import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { customization, template, theme, view, hdrm } from '@yola/ws-sdk';
import prepareNodeElement from '../helpers/prepare-node-element';
import refreshStore from '../../onlinestore/helpers/refresh-store';

const { updateStrategies } = hdrm.constants;

const useInjectPaletteToTemplate = () => {
  const colorPaletteVariable = useSelector((state) =>
    template.selectors.getVariableValueByPath(state, customization.constants.COLOR_PALETTE_VARIABLE)
  );
  const customPaletteTemplate = useSelector(customization.selectors.getColorPaletteTemplate);

  return useCallback(
    (palette) => {
      // Don't inject a palette to the DOM in the case when the generation of the palette didn't compile successfully
      if (!palette) return;

      const prepareNodeElementConfig = {
        elementVariable: colorPaletteVariable,
        customTemplate: customPaletteTemplate,
        currentPalette: palette,
        elementDataAttribute: theme.constants.COLOR_PALETTE_DATA_ATTRIBUTE,
      };

      const { element, paletteElement } = prepareNodeElement(prepareNodeElementConfig);

      if (element) {
        const targetElementId = view.accessors.getLiveElementId(element);

        view.operations.replaceElement(paletteElement, targetElementId, {
          strategy: updateStrategies.UPDATE_LIVE_ONLY,
        });
        // we need trigger store refreshing each time when palette is changed
        refreshStore();
      }
    },
    [colorPaletteVariable, customPaletteTemplate]
  );
};

export default useInjectPaletteToTemplate;
