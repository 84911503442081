import get from 'lodash.get';
import { config } from '@yola/ws-sdk';

const getHelpCenterConfig = (rootState) => {
  const cfg = config.selectors.getConfig(rootState);

  return get(cfg, 'common.ws-helpcenter', {});
};

export default getHelpCenterConfig;
