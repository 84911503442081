import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from '@yola/ws-ui';
import FormSettingsModal from './form-settings-modal';

function FormSettingsContainer(props) {
  const { initFormData, fields, elementId, handleSubmit, ...otherProps } = props;

  return (
    <Formik initialValues={initFormData} onSubmit={handleSubmit} enableReinitialize>
      {(formikProps) => (
        <FormSettingsModal {...formikProps} {...otherProps} fields={fields} elementId={elementId} />
      )}
    </Formik>
  );
}

FormSettingsContainer.propTypes = {
  elementId: PropTypes.string.isRequired,
  initFormData: PropTypes.shape({}).isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default FormSettingsContainer;
