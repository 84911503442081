import { HSLColor } from '@yola/yola-palette-generator';
import { uuid } from '@yola/ws-sdk';
import parseHslColorString from '../../../../website-design/helpers/parse-hsl-color-string';
import {
  isMediaFitsInContainerByWidth,
  isMediaFitsInContainerByHeight,
} from '../../../../focal-point/helpers/is-media-fits-in-container';

const makeCoveredImage = (
  img,
  width,
  height,
  background = 'transparent',
  filter = null,
  overlay = null,
  context = document
) => {
  const canvas = context.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  canvas.setAttribute('data-uuid', uuid());

  const CANVAS_TOP = 0;
  const CANVAS_LEFT = 0;
  const ctx = canvas.getContext('2d');

  ctx.filter = filter ? `${filter}()` : 'none';

  const top = Number(img.getAttribute('data-top'));
  const left = Number(img.getAttribute('data-left'));
  const scaledWidth = Number(img.getAttribute('data-width'));
  const scaledHeight = Number(img.getAttribute('data-height'));

  if (
    isMediaFitsInContainerByWidth(scaledWidth, scaledHeight, width, height) ||
    isMediaFitsInContainerByHeight(scaledWidth, scaledHeight, width, height)
  ) {
    ctx.fillStyle = background;
    ctx.fillRect(0, 0, width, height);
    ctx.drawImage(img, left, top, scaledWidth, scaledHeight);
  } else {
    const { width: realWidth } = img;
    const scale = scaledWidth / realWidth;
    ctx.drawImage(
      img,
      (-1 * left) / scale,
      (-1 * top) / scale,
      canvas.width / scale,
      canvas.height / scale,
      CANVAS_LEFT,
      CANVAS_TOP,
      canvas.width,
      canvas.height
    );
  }

  ctx.filter = 'none';

  if (!overlay) return canvas;

  const { color, blendingMode, opacity } = overlay;
  const hslObject = parseHslColorString(`hsl(${color})`);
  const hslColor = new HSLColor(hslObject.h, hslObject.s, hslObject.l, opacity);

  ctx.globalCompositeOperation = blendingMode;
  ctx.fillStyle = hslColor.toCSSValue();
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  return canvas;
};

export default makeCoveredImage;
