import { assets } from '@yola/ws-sdk';

const getFileExtension = (fileName) => fileName.slice(fileName.lastIndexOf('.'));

const createLogoTypeValidator = (validationError) => (file) => {
  const acceptedTypes = assets.constants.IMAGE_ACCEPT_TYPE;

  if (file && !acceptedTypes.includes(getFileExtension(file.name))) {
    return validationError;
  }

  return null;
};

export default createLogoTypeValidator;
