import { useEffect } from 'react';
import { replace } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import routesMap from 'yola-editor/src/js/router/helpers/routes-map';

const PublishRoute = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    dispatch(replace(routesMap.index.url(), { publish: true, domain: searchParams.get('domain') }));
  });

  return null;
};

export default PublishRoute;
