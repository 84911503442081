import { requests } from '@yola/yousersjs';

const configureSnippet = (mountPoint) => {
  const {
    ownerDocument: { defaultView },
  } = mountPoint;

  defaultView.zESettings = {
    webWidget: {
      authenticate: {
        chat: {
          jwtFn: (callback) => {
            requests.getZendeskToken().then((jwt) => {
              callback(jwt);
            });
          },
        },
      },
      contactOptions: {
        enabled: true,
      },
      zIndex: 999,
    },
  };
};
export default configureSnippet;
