import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { i18next, dialogs, site, view, integration } from '@yola/ws-sdk';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import getDefaultTraits from 'src/js/modules/analytics/segment/helpers/get-default-traits';
import constants from '../constants';
import verifiers from '../verifiers';
import helpers from '../helpers';
import FormSettingsContainer from './form-settings-container';
import itemsAddedCount from '../../../common/helpers/items-added-count';
import itemsDeletedCount from '../../../common/helpers/items-deleted-count';

const { MODAL_HEIGHT, MODAL_HEIGHT_WITH_TABS, MODAL_WIDTH } = constants.common;
const closeModal = () => dialogs.operations.hide();
const { attributes } = constants;

function ContactFormSettingsDialog(props) {
  const {
    elementId,
    onDialogMainAction,
    onDialogCancel,
    modalPosition: modalPositionProp,
    fields: updatedFields,
    advancedFormState: advancedFormStateProp,
  } = props;

  const initialFields = helpers.getFields(elementId);
  const initialShouldDisplayGdpr = verifiers.isGdprCheckboxEnabled(elementId);
  const [fields, updateFields] = useState(updatedFields || initialFields);
  const [isOverlayClicked, setIsOverlayClicked] = useState(false);
  const [isOrderAdjusted, setIsOrderAdjusted] = useState(false);
  const [modalPosition, setModalPosition] = useState(modalPositionProp);

  const initSuccessMessage = view.accessors.getElementAttribute(
    elementId,
    constants.attributes.SUCCESS_MESSAGE
  );

  const [advancedFormState, setAdvancedFormState] = useState(
    advancedFormStateProp || {
      emails: [],
      successMessage: initSuccessMessage || i18next.t('Your message was successfully sent.'),
      shouldDisplayGdpr: initialShouldDisplayGdpr,
    }
  );

  const enableBuilder = verifiers.isBuilderEnabled();

  const modalHeight = enableBuilder ? MODAL_HEIGHT_WITH_TABS : MODAL_HEIGHT;

  const element = view.accessors.getLiveElement(elementId);

  if (!modalPosition) {
    const dialogPosition = dialogs.helpers.getPositionByElement(element, MODAL_WIDTH, modalHeight);
    const { offsetX, offsetY } = dialogPosition;

    setModalPosition({
      offsetX,
      offsetY,
    });
  }

  const handleFormSettingsSubmit = (newDerivedData) => {
    const staticElement = view.accessors.getStaticElement(elementId).cloneNode(true);
    const customEmails = view.accessors.getElementAttribute(elementId, attributes.EMAILS);

    onDialogMainAction({
      dialogType: dialogTypes.CONTACT_FORM_SETTINGS_DIALOG,
      ...getDefaultTraits(elementId),
      triggerId: isOverlayClicked ? 'dialog-background' : 'submit-button',
      itemsAdded: itemsAddedCount(initialFields, fields),
      itemsDeleted: itemsDeletedCount(initialFields, fields),
      itemsOrderAdjusted: isOrderAdjusted,
      itemsEdited: helpers.getItemsEditedCount(initialFields, fields),
      itemsCount: fields.length,
      displayGdprAdjusted: initialShouldDisplayGdpr !== newDerivedData.shouldDisplayGdpr,
      isDisplayGdprTurnedOn: newDerivedData.shouldDisplayGdpr,
    });

    if (verifiers.isBuilderEnabled()) {
      helpers.buildNewMarkup(staticElement, fields);
    }

    helpers.setDerivedAttributes(staticElement, {
      customEmails,
      ...newDerivedData,
    });

    setIsOverlayClicked(false);
    closeModal();
    view.operations.replaceElement(staticElement, elementId);
  };

  const handleFormSettingsCancel = () => {
    onDialogCancel({
      dialogType: dialogTypes.CONTACT_FORM_SETTINGS_DIALOG,
      ...getDefaultTraits(elementId),
    });

    closeModal();
  };

  const openFieldSettings = (id) => {
    dialogs.operations.show(dialogTypes.CONTACT_FORM_EDIT_FIELD_DIALOG, {
      elementId,
      fields,
      modalPosition,
      activeFieldId: id,
      advancedFormState,
    });
  };

  const addNewField = () => {
    dialogs.operations.show(dialogTypes.CONTACT_FORM_SELECT_ITEM_DIALOG, {
      elementId,
      fields,
      modalPosition,
      advancedFormState,
    });
  };

  useEffect(() => {
    if (!advancedFormStateProp) {
      let emailOptions = [];
      const hasCustomEmails =
        element.hasAttribute(attributes.EMAILS) && element.getAttribute(attributes.EMAILS).trim();

      if (hasCustomEmails) {
        const customEmails = view.accessors
          .getElementAttribute(elementId, attributes.EMAILS)
          .split(',');

        emailOptions = customEmails.map((email) => ({
          value: email.trim(),
          label: email.trim(),
        }));
      } else {
        const { email: userEmail, is_white_label: isWL } = integration.accessors.getUserData();

        const businessEmails = isWL ? [] : site.accessors.getUserEmails() || [userEmail];

        emailOptions = businessEmails.map((email) => ({
          value: email,
          label: email,
        }));
      }

      setAdvancedFormState({
        ...advancedFormState,
        emails: emailOptions,
      });
    }
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  return (
    <FormSettingsContainer
      elementId={elementId}
      initialFields={initialFields}
      initFormData={advancedFormState}
      fields={fields}
      handleSubmit={handleFormSettingsSubmit}
      handleCancel={handleFormSettingsCancel}
      modalPosition={modalPosition}
      setModalPosition={setModalPosition}
      setAdvancedFormState={setAdvancedFormState}
      openFieldSettings={openFieldSettings}
      addNewField={addNewField}
      updateFields={updateFields}
      advancedFormState={advancedFormState}
      setIsOverlayClicked={setIsOverlayClicked}
      setIsOrderAdjusted={setIsOrderAdjusted}
    />
  );
}

ContactFormSettingsDialog.propTypes = {
  modalPosition: PropTypes.shape({
    offsetX: PropTypes.number.isRequired,
    offsetY: PropTypes.number.isRequired,
  }),
  fields: PropTypes.array.isRequired,
  elementId: PropTypes.string.isRequired,
  advancedFormState: PropTypes.shape(),
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

ContactFormSettingsDialog.defaultProps = {
  modalPosition: null,
  advancedFormState: null,
};

export default ContactFormSettingsDialog;
