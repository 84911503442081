export const surfacesKeyMap = {
  altLight: 'ws-light-0',
  light: 'ws-light-1',
  dark: 'ws-dark-0',
  altDark: 'ws-dark-1',
  primary: 'ws-primary-0',
  secondary: 'ws-secondary-0',
};

export const accentColorsKeyMap = {
  altLightPrimary: 'ws-light-0-primary',
  altLightSecondary: 'ws-light-0-secondary',
  lightPrimary: 'ws-light-1-primary',
  lightSecondary: 'ws-light-1-secondary',
  darkPrimary: 'ws-dark-0-primary',
  darkSecondary: 'ws-dark-0-secondary',
  altDarkPrimary: 'ws-dark-1-primary',
  altDarkSecondary: 'ws-dark-1-secondary',
};

export const cssKeyMap = { ...surfacesKeyMap, ...accentColorsKeyMap };

export const colorToSurfaceMap = {
  'ws-light-0': 'SurfaceLight0',
  'ws-light-1': 'SurfaceLight1',
  'ws-dark-0': 'SurfaceDark0',
  'ws-dark-1': 'SurfaceDark1',
  'ws-primary-0': 'SurfacePrimary0',
  'ws-secondary-0': 'SurfaceSecondary0',
};
