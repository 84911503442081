import yousersjs from '@yola/yousersjs';
import { auth } from '@yola/ws-sdk';
import user from 'yola-editor/src/js/modules/user';
import site from 'yola-editor/src/js/modules/site';
import subscriptions from 'yola-editor/src/js/modules/subscriptions';
import { redirectToUrl } from 'yola-editor/src/js/router/utils/redirect-to-url';
import config from 'src/js/modules/config';
import actions from '../actions';
import actionTypes from '../constants/action-types';

const fetchToken = (store) => (next) => (action) => {
  switch (action.type) {
    case actionTypes.FETCH_TOKEN: {
      yousersjs.requests
        .generateAuthToken()
        .then(({ ws_auth_token: authToken, site_id: siteId }) => {
          store.dispatch(actions.setSiteId(siteId));
          store.dispatch(auth.actions.setToken(authToken));
          store.dispatch(user.actions.fetchUserPreferences());
          store.dispatch(site.actions.fetchPublishedSite());
          store.dispatch(user.actions.fetchUserFeatures());
          store.dispatch(site.actions.fetchSiteData(siteId));
          store.dispatch(subscriptions.thunks.fetchSubscriptions());
          store.dispatch(subscriptions.thunks.fetchCurrentPackage());
          next(action);
        })
        .catch(() => {
          const state = store.getState();
          const galleryLink = config.selectors.getGalleryLink(state);
          const galleryLinkWithParams = new URL(galleryLink);
          const searchParams = new URLSearchParams(window.location.search);

          searchParams.forEach((value, key) => {
            galleryLinkWithParams.searchParams.append(key, value);
          });

          redirectToUrl(galleryLinkWithParams.toString());
          next(action);
        });
      break;
    }
    default: {
      next(action);
    }
  }
};

export default fetchToken;
