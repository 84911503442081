import React from 'react';
import PropTypes from 'prop-types';
import bowser from 'yola-bowser';
import { Modal, Panel, PanelGroup, designSystem } from '@yola/ws-ui';
import 'intersection-observer';
import tabIdentifiers from '../constant/tab-identifiers';
import ColorsSection from './colors-section';
import FontsSection from './fonts-section';
import ButtonsSection from './buttons-section';
import EffectsSection from './effects-section';
import TemplateSwitchingSection from './template-switching-section';

const {
  Tabs,
  TabsList,
  Tab,
  TabsContentList,
  TabContent,
  DialogHeader,
  DialogHeaderTitle,
  DialogButtonGroup,
  DialogButton,
  DialogButtonGroupSeparator,
} = designSystem;

const DIALOG_CLASS_NAME = 'ws-website-design-dialog';

class WebsiteDesignDialog extends React.Component {
  constructor(props) {
    super(props);

    this.modalRef = React.createRef();
    this.isMobile = bowser.mobile;

    this.intersectionObserverHandler = this.intersectionObserverHandler.bind(this);

    this.observer = new IntersectionObserver(this.intersectionObserverHandler, {
      root: document.querySelector(`.${DIALOG_CLASS_NAME}`),
      rootMargin: '0px 0px 200px 0px',
    });
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  intersectionObserverHandler(entries, observer) {
    const { preLoadFonts } = this.props;

    entries.forEach(({ isIntersecting, target }) => {
      if (isIntersecting) {
        preLoadFonts(target.id);
        observer.unobserve(target);
      }
    });
  }

  render() {
    const {
      offsetX,
      offsetY,
      width,
      height,
      maxHeight,
      captions: {
        title,
        tabButtons,
        tabs,
        colors: colorsCaptions,
        buttons: buttonsCaptions,
        effects: effectsCaptions,
        templateSwitching: templateSwitchingCaptions,
      },
      activeTabId,
      colorPalettes,
      fonts,
      buttonPresets,
      activeColorPaletteId,
      activeFontPairId,
      activeEffects,
      setActiveColorPalette,
      setActiveFonts,
      toggleWebsiteAnimation,
      onDragEnd,
      customColorPalettes,
      templatePreviewUrl,
      templateName,
      activeButtonPresetId,
      onSubmit,
      onCancel,
      onChangeTab,
      onEditPaletteClick,
      onDeletePaletteClick,
      onAddPaletteClick,
      onSwitchTemplate,
      onButtonPresetChange,
      isButtonsTabEnabled,
      isEffectsTabEnabled,
      isTemplateSwitchingTabEnabled,
    } = this.props;

    return (
      <Modal
        modalRef={this.modalRef}
        draggable
        resizable
        fullscreen={this.isMobile}
        className={DIALOG_CLASS_NAME}
        width={width}
        height={height}
        minWidth={width}
        maxWidth={width}
        offsetX={offsetX}
        offsetY={offsetY}
        maxHeight={maxHeight}
        dragHandleSelector=".ws-drag-trigger"
        overlay="none"
        handleCancel={onCancel}
        handleSubmit={onSubmit}
        onDragEnd={onDragEnd}
      >
        <PanelGroup height="100%">
          <Panel
            corners="squared"
            align="middle"
            className="ws-drag-trigger"
            style={{ borderBottom: 'none', flexShrink: 0 }}
          >
            <DialogHeader>
              <DialogHeaderTitle>{title}</DialogHeaderTitle>
            </DialogHeader>
          </Panel>
          <div className="ws-website-design-dialog__tabs">
            <Tabs
              defaultActiveTabId={activeTabId}
              onTabChange={onChangeTab}
              noVerticalSpacing
              noSideSpacing
            >
              <TabsList>
                <Tab tabId={tabIdentifiers.COLORS} label={tabs.colors} />
                <Tab tabId={tabIdentifiers.FONTS} label={tabs.fonts} />
                {isButtonsTabEnabled && <Tab tabId={tabIdentifiers.BUTTONS} label={tabs.buttons} />}
                {isEffectsTabEnabled && <Tab tabId={tabIdentifiers.EFFECTS} label={tabs.effects} />}
                {isTemplateSwitchingTabEnabled && (
                  <Tab tabId={tabIdentifiers.TEMPLATE_SWITCHING} label={tabs.templates} />
                )}
              </TabsList>
              <TabsContentList>
                <TabContent tabId={tabIdentifiers.COLORS}>
                  <ColorsSection
                    palettes={colorPalettes}
                    customPalettes={customColorPalettes}
                    activePaletteId={activeColorPaletteId}
                    captions={colorsCaptions}
                    setActivePalette={setActiveColorPalette}
                    onEditPaletteClick={onEditPaletteClick}
                    onDeletePaletteClick={onDeletePaletteClick}
                    onAddPaletteClick={onAddPaletteClick}
                  />
                </TabContent>
                <TabContent tabId={tabIdentifiers.FONTS}>
                  <FontsSection
                    fonts={fonts}
                    activeFontId={activeFontPairId}
                    setActiveFont={setActiveFonts}
                    fontObserver={this.observer}
                  />
                </TabContent>
                {isButtonsTabEnabled && (
                  <TabContent tabId={tabIdentifiers.BUTTONS}>
                    <ButtonsSection
                      presets={buttonPresets}
                      activePresetId={activeButtonPresetId}
                      activePaletteId={activeColorPaletteId}
                      activeFontId={activeFontPairId}
                      captions={buttonsCaptions}
                      onChange={onButtonPresetChange}
                    />
                  </TabContent>
                )}
                {isEffectsTabEnabled && (
                  <TabContent tabId={tabIdentifiers.EFFECTS}>
                    <EffectsSection
                      isWebsiteAnimationChecked={activeEffects.websiteAnimation}
                      captions={effectsCaptions}
                      onWebsiteAnimationChange={toggleWebsiteAnimation}
                    />
                  </TabContent>
                )}
                {isTemplateSwitchingTabEnabled && (
                  <TabContent tabId={tabIdentifiers.TEMPLATE_SWITCHING}>
                    <TemplateSwitchingSection
                      captions={templateSwitchingCaptions}
                      templatePreviewUrl={templatePreviewUrl}
                      templateName={templateName}
                      onSwitchTemplate={onSwitchTemplate}
                    />
                  </TabContent>
                )}
              </TabsContentList>
            </Tabs>
          </div>
          <Panel corners="squared" style={{ flexShrink: '0', borderTop: 0 }}>
            <DialogButtonGroup>
              <DialogButton onClick={onSubmit}>{tabButtons.submit}</DialogButton>
              <DialogButtonGroupSeparator />
              <DialogButton onClick={onCancel}>{tabButtons.cancel}</DialogButton>
            </DialogButtonGroup>
          </Panel>
        </PanelGroup>
      </Modal>
    );
  }
}

WebsiteDesignDialog.propTypes = {
  width: PropTypes.number,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxHeight: PropTypes.number,
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,
  activeTabId: PropTypes.oneOf(Object.values(tabIdentifiers)).isRequired,
  isButtonsTabEnabled: PropTypes.bool.isRequired,
  isEffectsTabEnabled: PropTypes.bool.isRequired,
  isTemplateSwitchingTabEnabled: PropTypes.bool.isRequired,
  buttonPresets: PropTypes.array,
  activeButtonPresetId: PropTypes.string,
  onDragEnd: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onAddPaletteClick: PropTypes.func,
  onEditPaletteClick: PropTypes.func,
  onDeletePaletteClick: PropTypes.func,
  onSwitchTemplate: PropTypes.func,
  onButtonPresetChange: PropTypes.func,
  setActiveColorPalette: PropTypes.func,
  setActiveFonts: PropTypes.func,
  onChangeTab: PropTypes.func,
  toggleWebsiteAnimation: PropTypes.func,
  preLoadFonts: PropTypes.func,
  captions: PropTypes.shape({
    title: PropTypes.string,
    tabButtons: PropTypes.shape({
      submit: PropTypes.string,
      cancel: PropTypes.string,
    }),
    tabs: PropTypes.shape({
      colors: PropTypes.string,
      fonts: PropTypes.string,
      buttons: PropTypes.string,
      effects: PropTypes.string,
      templates: PropTypes.string,
    }),
    colors: PropTypes.shape({
      title: PropTypes.string,
      customPalettesTitle: PropTypes.string,
      paletteLabel: PropTypes.string,
      customPaletteLabel: PropTypes.string,
      editLabel: PropTypes.string,
      deleteLabel: PropTypes.string,
    }),
    buttons: PropTypes.shape({
      presetLabel: PropTypes.string,
      presetButtonLabel: PropTypes.string,
    }),
    effects: PropTypes.shape({
      websiteAnimation: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        label: PropTypes.string,
      }),
    }),
    templateSwitching: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
    }),
  }).isRequired,
  colorPalettes: PropTypes.array,
  customColorPalettes: PropTypes.array,
  fonts: PropTypes.array,
  activeColorPaletteId: PropTypes.string,
  activeFontPairId: PropTypes.string.isRequired,
  templatePreviewUrl: PropTypes.string,
  templateName: PropTypes.string,
  activeEffects: PropTypes.shape(),
};

WebsiteDesignDialog.defaultProps = {
  width: 416,
  height: 548,
  maxHeight: 1000,
  offsetX: 100,
  offsetY: 50,
  onCancel: () => {},
  onSubmit: () => {},
  onChangeTab: () => {},
  onDragEnd: () => {},
  onAddPaletteClick: null,
  onEditPaletteClick: null,
  onDeletePaletteClick: null,
  activeColorPaletteId: null,
  activeButtonPresetId: null,
  setActiveColorPalette: () => {},
  setActiveFonts: () => {},
  toggleWebsiteAnimation: () => {},
  preLoadFonts: () => {},
  colorPalettes: [],
  buttonPresets: [],
  customColorPalettes: [],
  fonts: [],
  activeEffects: {},
  templatePreviewUrl: null,
  templateName: null,
  onButtonPresetChange: null,
  onSwitchTemplate: null,
};

export default WebsiteDesignDialog;
