import getColorFromVariables from 'src/js/modules/website-design/helpers/get-color-from-variables';
import tagNameToTypographyKeyMap from 'src/js/modules/website-design/constant/tag-name-to-typography-key-map';

const getPredefinedColorsForBlock = (surfaceName, tagName, paletteVariables) => {
  // There is we get predefined colors from the colors variable
  // As we don't have access to :root rule of the template from the editor
  // at first we get a name of the CSS variables and after that get the value of that
  const typographyKeyMap = tagNameToTypographyKeyMap[tagName];

  const highEmphasisKey = `${surfaceName}TextColor`;
  const mediumEmphasisOpacityKey = `${surfaceName}TextMediumEmphasisOpacity`;
  const primaryEmphasisKey = `${surfaceName}PrimaryButton1BackgroundColor`;
  const typographyKey = `${surfaceName}${typographyKeyMap}Color`;
  const mediumEmphasisOpacity = paletteVariables[mediumEmphasisOpacityKey];

  const highEmphasisColor = getColorFromVariables(highEmphasisKey, paletteVariables);
  const primaryEmphasisColor = getColorFromVariables(primaryEmphasisKey, paletteVariables);
  const typographyColor = getColorFromVariables(typographyKey, paletteVariables);

  const highEmphasisHSL = `hsl(${highEmphasisColor})`;
  const mediumEmphasisHSLA = `hsla(${highEmphasisColor}, ${mediumEmphasisOpacity})`;
  const primaryEmphasisHSL = `hsl(${primaryEmphasisColor})`;
  const typographyHSL = `hsl(${typographyColor})`;

  return [
    { value: highEmphasisHSL },
    { value: mediumEmphasisHSLA },
    { value: primaryEmphasisHSL },
    highEmphasisHSL !== typographyHSL && { value: typographyHSL },
  ].filter(Boolean);
};

export default getPredefinedColorsForBlock;
