import { anodum, textFormatting, contentEditable } from '@yola/ws-sdk';
import moveCursorLineUp from './move-cursor-line-up';
import splitElementBySelection from './split-element-by-selection';
import prepareTargetElement from './prepare-target-element';
import prepareGeneratedContent from './prepare-generated-content';
import findBlockElementFromNode from './find-block-element-from-node';
import wrapInTag from './wrap-in-tag';

const splitElement = (isCursorAtTheStart, isCursorAtTheEnd) => {
  if (isCursorAtTheStart && isCursorAtTheEnd) {
    return;
  }

  if (isCursorAtTheStart) {
    splitElementBySelection();
    moveCursorLineUp();
    return;
  }

  if (isCursorAtTheEnd) {
    splitElementBySelection();
    return;
  }

  // twice to add new empty line
  splitElementBySelection();
  splitElementBySelection();
  moveCursorLineUp();
};

const insertGeneratedContent = async (element, content) => {
  prepareTargetElement(element);
  let selection = textFormatting.accessors.getAdvancedSelection();
  const [html, generatedFragment] = prepareGeneratedContent(content, selection);

  const {
    anchorOffset: initialAnchorOffset,
    anchorNode: initialAnchorNode,
    isCollapsed: isCollapsedSelection,
  } = selection;
  const initialAnchorNodePath = anodum.getTreePathOfNode(initialAnchorNode, element);

  const generatedBlockAnchorElement = findBlockElementFromNode(generatedFragment);
  const doesContentBeginWithNonBlockElement = !generatedBlockAnchorElement;
  const currentBlockAnchorElement = findBlockElementFromNode(initialAnchorNode);
  const doesContentBeginWithSameBlockElement =
    currentBlockAnchorElement &&
    generatedBlockAnchorElement &&
    generatedBlockAnchorElement.tagName === currentBlockAnchorElement.tagName;

  if (
    isCollapsedSelection ||
    doesContentBeginWithNonBlockElement ||
    doesContentBeginWithSameBlockElement
  ) {
    let preparedHtmlString = html;

    // wrap pure string with tag to insert generated content with formatting
    if (doesContentBeginWithNonBlockElement) {
      preparedHtmlString = wrapInTag(html, currentBlockAnchorElement.tagName);
    }

    await contentEditable.helpers.applyInsertion(element, preparedHtmlString);

    const anchorNode = anodum.getNodeByTreePath(element, initialAnchorNodePath);
    contentEditable.helpers.selectFromEnd(anchorNode, initialAnchorOffset);
    return;
  }

  const range = selection.getRangeAt(0);
  range.deleteContents();
  range.collapse();
  selection.refresh();

  selection = textFormatting.accessors.getAdvancedSelection();
  const { anchorOffset: currentAnchorOffset, anchorNode: currentAnchorNode } = selection;
  const isCursorAtTheStart = currentAnchorOffset === 0;
  const isCursorAtTheEnd =
    anodum.isTextNode(currentAnchorNode) &&
    currentAnchorNode.nodeValue.substring(currentAnchorOffset) === '';
  let shouldUseNextSibling = !isCursorAtTheStart;
  try {
    splitElement(isCursorAtTheStart, isCursorAtTheEnd);
  } catch (e) {
    shouldUseNextSibling = false;
  }

  await contentEditable.helpers.applyInsertion(element, html);

  let anchorNode = anodum.getNodeByTreePath(element, initialAnchorNodePath);
  if (shouldUseNextSibling) {
    anchorNode = anodum.isTextNode(anchorNode)
      ? anchorNode.parentNode.nextSibling
      : anchorNode.nextSibling;
  }

  contentEditable.helpers.selectFromEnd(anchorNode, 0);
};

export default insertGeneratedContent;
