import { view } from '@yola/ws-sdk';

const getFontsConfig = (fontsId, fonts) => {
  const selectedFonts = fonts.find((fontsPair) => fontsPair.id === fontsId);
  const liveDocument = view.accessors.getLiveDocument();

  if (!selectedFonts) return null;
  return {
    google: {
      families: [selectedFonts.variables.primaryFont, selectedFonts.variables.secondaryFont],
    },
    classes: false,
    context: liveDocument.defaultView,
  };
};

export default getFontsConfig;
