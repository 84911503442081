export default [
  {
    key: 1,
    src: '/images/publish-interruption/star-1.svg',
    style: { top: '25px', left: '-22px', animationDuration: '2.4s', animationDelay: '0.5s' },
  },
  {
    key: 2,
    src: '/images/publish-interruption/star-2.svg',
    style: { top: '85px', left: '5px', animationDuration: '2.3s' },
  },
  {
    key: 3,
    src: '/images/publish-interruption/point.svg',
    style: { top: '72px', left: '32px', animationDuration: '2.2s', animationDelay: '0.2s' },
  },
  {
    key: 4,
    src: '/images/publish-interruption/star-2.svg',
    style: { top: '-22px', left: '124px', animationDuration: '2.1s' },
  },
  {
    key: 5,
    src: '/images/publish-interruption/star-1.svg',
    style: { top: '15px', right: '75px', animationDuration: '2.5s', animationDelay: '0.7s' },
  },
  {
    key: 6,
    src: '/images/publish-interruption/star-1.svg',
    style: { top: '60px', right: '-22px', animationDuration: '3s' },
  },
  {
    key: 7,
    src: '/images/publish-interruption/star-1.svg',
    style: { top: '88px', right: '36px', animationDuration: '2.1s', animationDelay: '0.3s' },
  },
  {
    key: 8,
    src: '/images/publish-interruption/point.svg',
    style: { top: '52px', right: '12px', animationDuration: '2.6s', animationDelay: '1s' },
  },
  {
    key: 9,
    src: '/images/publish-interruption/star-2.svg',
    style: { top: '0px', right: '27px', animationDuration: '2.8s', animationDelay: '0.1s' },
  },
];
