import { hdrm } from '@yola/ws-sdk';
import getCommonConstants from './get-common-constants';
import getFieldTypes from './get-field-types';
import getEscapedString from './get-escaped-string';

const getPrefix = (fieldType) => {
  const { checkbox, dropdown, date, time, radio, phone, link, attachfile } = getFieldTypes();
  const {
    fieldIdPrefix,
    checkboxIdPrefix,
    dropdownIdPrefix,
    dateIdPrefix,
    timeIdPrefix,
    radioIdPrefix,
    phoneIdPrefix,
    linkIdPrefix,
    fileIdPrefix,
  } = getCommonConstants();

  switch (fieldType) {
    case checkbox:
      return checkboxIdPrefix;
    case dropdown:
      return dropdownIdPrefix;
    case date:
      return dateIdPrefix;
    case time:
      return timeIdPrefix;
    case radio:
      return radioIdPrefix;
    case phone:
      return phoneIdPrefix;
    case link:
      return linkIdPrefix;
    case attachfile:
      return fileIdPrefix;
    default:
      return fieldIdPrefix;
  }
};

const addNewField = (fields, newField) => {
  const prefix = getPrefix(newField.type);
  const suffix = hdrm.helpers.generateIdSuffix();
  return [
    ...fields,
    {
      ...newField,
      id: `${prefix}${fields.length + 1}${suffix}`,
      name: `${getEscapedString(newField.label)}${suffix}`,
      order: String(fields.length + 1),
    },
  ];
};

export default addNewField;
