import { textFormatting, hdrm, view, globals } from '@yola/ws-sdk';
import highlighter from 'src/js/modules/highlighter';
import designAutomation from 'src/js/modules/design-automation';
import { REGULAR_IMAGE_ATTRIBUTES_FOR_SHALLOW_PROXY } from 'src/js/modules/blocks/constants/common';
import getAttributesOperations from './get-attributes-operations';
import layoutSwitchingHelpers from './layout-switching';
import blockSettingsHelpers from './block-settings';
import trackBlockLayoutSwitched from '../trackers/track-block-layout-switched';
import optionTypes from '../constants/display-options-type';
import trackBlockSettingsDialogSubmitted from '../trackers/track-block-settings-dialog-submitted';
import dropTextResizing from './layout-switching/drop-text-resizing';
import getBackgroundAttributeOperations from './get-background-attribute-operations';
import getChangedBackgroundOptions from './get-changed-background-options';
import removeCoverConstraintsAttribute from './remove-cover-constraints-attribute';
import convertAttributeOperationToAction from './convert-attribute-operation-to-action';

const { replaceOptionalChildren, getChildrenToUpdate, prepareReplacedData } =
  layoutSwitchingHelpers;

const {
  getChanges,
  hasTouchedGlobals,
  handleOptions,
  handleBackgroundOptions,
  createBreakpointsSliderOption,
} = blockSettingsHelpers;

const { updateStrategies } = hdrm.constants;
const { webkitWorkarounds } = hdrm.helpers;

const saveBlockSettingsChanges = ({
  intermediateBlockId,
  destinationBlockNode,
  onDialogMainAction,
  isClickOnOverlay,
  optionalChildren,
  colorSchemes,
  displayOptions: stateDisplayOptions,
  backgroundOptions,
  cachedBackgroundOptions,
  hideDialog,
  bulkViewActions,
  originalBlock,
  blockNode,
  blockId: newBlockId,
  variationId: newBlockVariationId,
  refId,
  setCleanUrlsEnabled,
  initialElement,
  usedCustomColorOptions,
  isOnlineStoreBlock,
}) => {
  const safeDisplayOptions = stateDisplayOptions || [];

  const displayOptions = safeDisplayOptions.map((option) =>
    option.id === 'whitespace_top' || option.id === 'whitespace_bottom'
      ? createBreakpointsSliderOption({
          item: option,
          displayOptions: safeDisplayOptions,
          blockNode,
        })
      : option
  );

  const isBlockLayoutAdjusted = Boolean(destinationBlockNode);

  const blockSettingsChanges = getChanges({
    optionalChildren,
    colorSchemes,
    displayOptions,
  });

  const changedBackgroundOptions = getChangedBackgroundOptions(backgroundOptions);

  trackBlockSettingsDialogSubmitted({
    isOverlayClicked: isClickOnOverlay,
    onDialogMainAction,
    blockId: intermediateBlockId,
    blockVariationId: newBlockVariationId,
    blockLayoutAdjusted: isBlockLayoutAdjusted,
    blockSettingsChanges,
    changedBackgroundOptions,
  });

  if (isBlockLayoutAdjusted) {
    highlighter.operations.hide([blockNode]);
    const { blockId: oldBlockId } = originalBlock;

    optionalChildren.forEach((item) => {
      hdrm.helpers.updateChildrenPresence(
        destinationBlockNode,
        item.querySelector,
        item.isEnabled,
        item.id
      );
    });

    const toUpdate = getChildrenToUpdate(originalBlock.blockNode, destinationBlockNode);

    const attributesForShallowProxy =
      newBlockVariationId !== originalBlock.blockVariationId
        ? REGULAR_IMAGE_ATTRIBUTES_FOR_SHALLOW_PROXY
        : [];

    const replacedData = replaceOptionalChildren(
      toUpdate,
      originalBlock.staticBlockNode,
      destinationBlockNode,
      attributesForShallowProxy
    );
    const { targetText, replacedText } = prepareReplacedData(replacedData);

    if (newBlockVariationId !== originalBlock.blockVariationId) {
      dropTextResizing(destinationBlockNode);
    }

    handleOptions(destinationBlockNode, displayOptions);

    handleBackgroundOptions(destinationBlockNode, backgroundOptions, cachedBackgroundOptions);

    const replaceOptions = { strategy: updateStrategies.UPDATE_BOTH };

    const staticDocument = originalBlock.staticBlockNode.ownerDocument;
    const nodeToReplace = staticDocument.importNode(destinationBlockNode, true);
    webkitWorkarounds.removeLazyLoadingAttributeFromImages(nodeToReplace);

    if (usedCustomColorOptions) {
      const { globalId, html } = textFormatting.helpers.updateCustomColorStylesheetInGlobal(false);
      const actions = [
        globals.actions.setGlobalHTMLAndAffectHistory(globalId, html),
        view.actions.replaceElement(nodeToReplace, refId, replaceOptions),
      ];
      bulkViewActions(actions);
    } else {
      view.operations.replaceElement(nodeToReplace, refId, replaceOptions);
    }

    trackBlockLayoutSwitched({
      oldBlockId,
      newBlockId,
      oldBlockVariationId: originalBlock.blockVariationId,
      newBlockVariationId,
      oldText: replacedText,
      newText: targetText,
    });

    hideDialog();
    return;
  }

  const actions = [];
  const [changedChildren, changesList] = blockSettingsChanges;

  const attributesOperations = getAttributesOperations(initialElement, changesList, displayOptions);
  const isSurfaceChanged = changesList.some((item) => item.type === optionTypes.SURFACE);
  const backgroundAttributesOperations = getBackgroundAttributeOperations({
    changedBackgroundOptions,
    backgroundOptions,
  });

  [...attributesOperations, ...backgroundAttributesOperations].forEach((operation) => {
    const action = convertAttributeOperationToAction(operation);
    actions.push(action);

    // Here we detect attributes names which should be marked if modified by user.
    // This is needed to distinguish them during UI automation processes.
    const { elementId, name } = operation;
    const changedOption = changesList.find(({ attribute }) => attribute === name);

    if (changedOption?.userModified) {
      actions.push(designAutomation.actions.addUserModifiedAttribute(elementId, name));
    }
  });

  const removeCoverConstraintsAction = removeCoverConstraintsAttribute(originalBlock, blockNode);

  if (removeCoverConstraintsAction) {
    actions.push(removeCoverConstraintsAction);
  }

  if (changedChildren.length) {
    const childrenPresenceUpdates = changedChildren.map((child) => ({
      blockSettingId: child.id,
      childrenSelector: child.querySelector,
      enabled: child.isEnabled,
    }));
    const childrenPresenceOptions = { strategy: updateStrategies.UPDATE_STATIC_ONLY };

    actions.push(
      view.actions.bulkSetChildrenPresence(refId, childrenPresenceUpdates, childrenPresenceOptions)
    );
  }

  if (usedCustomColorOptions && isSurfaceChanged) {
    const { globalId, html } = textFormatting.helpers.updateCustomColorStylesheetInGlobal(false);
    actions.push(globals.actions.setGlobalHTMLAndAffectHistory(globalId, html));
  }

  if (actions.length) {
    if (
      (!hasTouchedGlobals(changedChildren) && hasTouchedGlobals(optionalChildren)) ||
      isOnlineStoreBlock
    ) {
      bulkViewActions(actions, { strategy: updateStrategies.UPDATE_STATIC_ONLY });
    } else {
      bulkViewActions(actions);
    }
  }

  const changedOnlineStoreCleanUrlSelector = changesList.find(
    (item) => item.type === optionTypes.ONLINE_STORE_CLEAN_URL_SELECTOR
  );

  if (changedOnlineStoreCleanUrlSelector) {
    setCleanUrlsEnabled(changedOnlineStoreCleanUrlSelector.isEnabled);
  }

  hideDialog();
};

export default saveBlockSettingsChanges;
