import { hooks, site, blocks, view, template } from '@yola/ws-sdk';
import constants from '../constants';
import track from '../track';

const trackWidgetDeleted = () => {
  hooks.operations.subscribe(
    hooks.availableHooks.ON_BEFORE_BLOCK_DELETED,
    ({ blockId, variationId: blockVariationId, elementId, constraintId }) => {
      if (!constraintId || (blockId && !blockId.startsWith('widget-'))) {
        return;
      }

      const parentElement = view.accessors.getParentLiveElement(elementId);
      const wsBlockElement = parentElement.closest(`[id^=${blocks.constants.BLOCK_ID_PREFIX}]`);
      const targetBlockId = blocks.accessors.getBlockIdByElement(wsBlockElement);
      const targetBlockVariationId = blocks.accessors.getVariationIdByElement(wsBlockElement);

      track(constants.events.WIDGET_DELETED, {
        templateBuildSlug: template.accessors.getBuildSlug(),
        siteId: site.accessors.getSiteId(),
        blockId,
        blockVariationId,
        targetBlockId,
        targetBlockVariationId,
        constraintId,
      });
    }
  );
};

export default trackWidgetDeleted;
