import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';

const { PresetContainer, ColorPalette } = designSystem;

const ORDERED_COLORS = [
  { legacy: 'light', latitude: 'ws-light-0' },
  { legacy: 'light-gray', latitude: 'ws-light-1' },
  { legacy: 'brand', latitude: 'ws-secondary-0' },
  { legacy: 'accent', latitude: 'ws-primary-0' },
  { legacy: 'dark', latitude: 'ws-dark-0' },
];

function ColorPalettePreset(props) {
  const {
    id,
    title,
    editLabel,
    deleteLabel,
    isSelected,
    isCustom,
    colors,
    onClick,
    onEditClick,
    onDeleteClick,
  } = props;

  const handleClick = () => onClick(id);
  const handleEditClick = () => onEditClick(id, isCustom);
  const handleDeleteClick = () => onDeleteClick(id);

  return (
    <PresetContainer
      title={title}
      editLabel={editLabel}
      isSelected={isSelected}
      onClick={handleClick}
      {...(onEditClick && {
        onEditClick: handleEditClick,
      })}
      {...(isCustom &&
        onDeleteClick && {
          deleteLabel,
          onDeleteClick: handleDeleteClick,
        })}
    >
      <ColorPalette
        colors={ORDERED_COLORS.map(({ legacy, latitude }) => colors[legacy] || colors[latitude])}
      />
    </PresetContainer>
  );
}

ColorPalettePreset.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  editLabel: PropTypes.string,
  deleteLabel: PropTypes.string,
  isSelected: PropTypes.bool,
  isCustom: PropTypes.bool,
  colors: PropTypes.shape({
    'ws-light-0': PropTypes.string,
    'ws-light-1': PropTypes.string,
    'ws-dark-0': PropTypes.string,
    'ws-primary-0': PropTypes.string,
    'ws-secondary-0': PropTypes.string,
    light: PropTypes.string,
    'light-gray': PropTypes.string,
    brand: PropTypes.string,
    accent: PropTypes.string,
    dark: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
};

ColorPalettePreset.defaultProps = {
  editLabel: '',
  deleteLabel: '',
  isSelected: false,
  isCustom: false,
  onEditClick: null,
  onDeleteClick: null,
};

export default ColorPalettePreset;
