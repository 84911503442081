export default {
  SLUG: 'ws-text-formatting',
  DARK_THEME_CR: 4,
  LIGHT_THEME_CR: 3,
  BLOCK_QUOTE_MARK_VARIABLE: '--ws-blockquote-icon',
  CUSTOMIZE_FONTS_HOTKEY: 'CUSTOMIZE_FONTS_HOTKEY',
  AI_WRITE_TEXT_ACTIVE_HEADER_WIDTH: 480,
  AI_WRITE_TEXT_ACTIVE_HEADER_MOBILE_WIDTH: 320,
  AI_REWRITE_TEXT_ACTIVE_HEADER_WIDTH: 480,
  AI_REWRITE_TEXT_ACTIVE_HEADER_MOBILE_WIDTH: 320,
  AI_WRITER_TRIGGER_WIDTH: 108,
  MIN_FONT_SIZE: 1,
  MAX_FONT_SIZE: 400,
  MAX_SAFE_FONT_SIZE: 64,
  FONT_SIZE_POPOVER_WIDTH: 250,
};
