import bowser from 'yola-bowser';
import { BOTTOM, TOP, DESKTOP_BOTTOM_OFFSET, IOS_BOTTOM_OFFSET } from '../constants/common';

const getScrollOffsetInContainer = (element, elementSideToScroll, scrollContainer) => {
  const scrollPosition = scrollContainer.scrollTop;
  const containerSizes = scrollContainer.getBoundingClientRect();
  const elementSizes = element.getBoundingClientRect();

  const { top: elementTop, bottom: elementBottom } = elementSizes;
  let offset = DESKTOP_BOTTOM_OFFSET;

  if (bowser.ios) {
    offset = IOS_BOTTOM_OFFSET;
  }

  switch (elementSideToScroll) {
    case TOP: {
      return scrollPosition + (elementTop - containerSizes.top);
    }
    case BOTTOM: {
      return elementBottom - containerSizes.bottom + scrollPosition + offset;
    }
    default: {
      return scrollPosition;
    }
  }
};

export default getScrollOffsetInContainer;
