import { blocks } from '@yola/ws-sdk';
import { SURFACES_ATTRIBUTE } from '../../constants/display-options';

const setMatchingSurface = ({ displayOptions, destinationBlockNode }) => {
  const destinationBlockDisplayOptions =
    blocks.accessors.getDisplayOptionsForElement(destinationBlockNode);
  const destinationBlockSurfaceOption = destinationBlockDisplayOptions.find(
    (option) => option.attribute === SURFACES_ATTRIBUTE
  );

  const sourceSurfaceOption = displayOptions.find(
    (option) => option.attribute === SURFACES_ATTRIBUTE
  );

  const matchingSurface =
    destinationBlockSurfaceOption.options.find(
      (option) => option.value === sourceSurfaceOption.value
    ) ||
    destinationBlockSurfaceOption.options.find((option) =>
      option.value.includes(sourceSurfaceOption.value)
    ) ||
    destinationBlockSurfaceOption.options.find((option) =>
      option.value.includes(sourceSurfaceOption.value.split(' ')[0])
    );

  if (matchingSurface && matchingSurface.value) {
    destinationBlockNode.setAttribute(SURFACES_ATTRIBUTE, matchingSurface.value);
  }
};

export default setMatchingSurface;
