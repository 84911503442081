import React from 'react';
import { dialogs, i18next, extensions } from '@yola/ws-sdk';
import { Icon } from '@yola/ws-ui';
import Trigger from 'src/js/modules/common/components/trigger';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import constants from '../constants';
import getGalleryData from '../helpers/get-gallery-data';
import getGalleryBlockId from '../helpers/get-gallery-block-id';

const triggerId = 'gallery';

const control = {
  id: triggerId,
  trigger: (
    <Trigger id={triggerId}>
      <Icon glyph="gallery" size="24" strokeWidth="1.3" />
    </Trigger>
  ),
  onTriggerClick: (elementId) => {
    const galleryBlockId = getGalleryBlockId(elementId);
    const items = getGalleryData(galleryBlockId);
    dialogs.operations.show(dialogTypes.GALLERY_DIALOG, { items, elementId: galleryBlockId });
  },
  get title() {
    return i18next.t('Gallery settings');
  },

  get tooltip() {
    return i18next.t('Gallery settings');
  },
  matches(liveElement) {
    const settings = extensions.accessors.getExtensionSettings(constants.common.slug);
    return liveElement.matches(settings.querySelector);
  },
  priority: 100,
  highlightElement: (element) => {
    const settings = extensions.accessors.getExtensionSettings(constants.common.slug);
    let gallery;

    if (element.matches(settings.galleryBlockQuerySelector)) {
      gallery = element.querySelector(settings.galleryQuerySelector);
    } else {
      gallery =
        element.closest(settings.galleryWidgetQuerySelector) ||
        element.closest(settings.galleryQuerySelector);
    }

    const galleryItems = gallery.querySelectorAll(settings.galleryItemSelector);

    return Array.from(galleryItems);
  },
};

export default control;
