import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { i18next } from '@yola/ws-sdk';
import { AlertDialog } from '@yola/ws-ui';
import { useDispatch } from 'react-redux';
import hide from '../actions/hide';

function CheckEmailDialogContainer({ onDialogCancel }) {
  const captions = {
    title: i18next.t('The confirmation email has been sent.'),
    description: i18next.t('Can’t find it? Check your spam.'),
    dismiss: i18next.t('Close'),
  };

  const dispatch = useDispatch();
  const hideDialog = useCallback(() => {
    onDialogCancel();
    dispatch(hide());
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  return <AlertDialog captions={captions} onDismiss={hideDialog} />;
}

CheckEmailDialogContainer.propTypes = {
  onDialogCancel: PropTypes.func.isRequired,
};

export default CheckEmailDialogContainer;
