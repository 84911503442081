const getChangedBackgroundOptions = (items) => {
  if (!items) return null;

  return items.reduce(
    (accum, item) => ({
      ...accum,
      [item.id]:
        !item.initialSrc.includes(item.src) ||
        item.initialScale !== item.scale ||
        item.initialPosition !== item.position,
    }),
    {}
  );
};

export default getChangedBackgroundOptions;
