const sortByOrder = (items) =>
  items.sort((firstItem, secondItem) => {
    if (!secondItem.order) {
      return -1;
    }
    if (!firstItem.order) {
      return 1;
    }
    return firstItem.order - secondItem.order;
  });

export default sortByOrder;
