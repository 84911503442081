const getBlockIdRulesMap = (stylesheet, blockId) => {
  if (!stylesheet) return null;
  const { cssRules } = stylesheet;
  const rulesMap = new Map();
  Object.values(cssRules).forEach((rule) => {
    if (!rule.selectorText.startsWith(`#${blockId}`)) return;
    rulesMap.set(rule.selectorText, rule.style.cssText);
  });
  return rulesMap;
};

export default getBlockIdRulesMap;
