import getTriggersCount from './get-triggers-count';
import { DROPDOWN_ITEM_HEIGHT, DROPDOWN_OFFSET } from '../constants/sizes';

const getDropDownHeight = (items) => {
  const triggersCount = getTriggersCount(items);
  const areItemsNotCollapsed = items.length === triggersCount;

  if (areItemsNotCollapsed) {
    return items.length * DROPDOWN_ITEM_HEIGHT + DROPDOWN_OFFSET;
  }

  const collapsedItems = items.length - triggersCount;

  return collapsedItems * DROPDOWN_ITEM_HEIGHT + DROPDOWN_OFFSET;
};

export default getDropDownHeight;
