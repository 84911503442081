import constants from '../constants/common';
import { PROTOCOL_ICON_MAP } from '../constants/icon-autocomplete-maps';

const { HTTPS_PROTOCOL } = constants;

const normalizeUrl = (url) => {
  const lowercaseUrl = url.toLowerCase();

  if (lowercaseUrl.trim().startsWith('#')) {
    return url;
  }

  const httpProtocolRegExp = new RegExp('^https?://', 'i');
  if (httpProtocolRegExp.test(lowercaseUrl)) {
    const urlInstance = new URL(lowercaseUrl);
    return url.replace(httpProtocolRegExp, `${urlInstance.protocol}//`);
  }

  const usedCustomProtocol = Object.keys(PROTOCOL_ICON_MAP).find((protocol) =>
    lowercaseUrl.startsWith(protocol)
  );

  if (usedCustomProtocol) {
    const expression = new RegExp(`^${usedCustomProtocol}`, 'i');
    return url.replace(expression, usedCustomProtocol);
  }

  if (lowercaseUrl.startsWith('//')) return `${HTTPS_PROTOCOL}${url}`;

  return `${HTTPS_PROTOCOL}//${url}`;
};

export default normalizeUrl;
