import makeUrlAbsolute from './make-url-absolute';
import getSubmoduleConfig from '../../../helpers/get-submodule-config';
import { SUBMODULE_NAME } from '../constants';

const getImgMeta = async (wsBlock, mediaSource = null) => {
  const { sourceAttributeName, filterAttributeName } = getSubmoduleConfig(SUBMODULE_NAME);

  const [backgroundNode] = wsBlock.getTargetElementForAttribute(sourceAttributeName);
  const baseTag = wsBlock.ownerDocument.head.querySelector('base');
  const baseURL = baseTag ? baseTag.href : '';

  const initialImgMeta = {
    width: 0,
    height: 0,
    src: makeUrlAbsolute(wsBlock.getAttribute(sourceAttributeName), baseURL),
    filter: wsBlock.getAttribute(filterAttributeName),
    position: {
      left: 0,
      top: 0,
    },
  };
  const imgBox = await backgroundNode.computeBackgroundRectForSource(
    mediaSource || initialImgMeta.src
  );
  const imgMeta = {
    ...initialImgMeta,
    ...imgBox,
  };
  const { ownerDocument: documentContext } = wsBlock;
  imgMeta.mediaSource = mediaSource;
  imgMeta.src = makeUrlAbsolute(imgMeta.src, baseURL);
  imgMeta.context = documentContext;

  return imgMeta;
};

export default getImgMeta;
