import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import BlockFloatingTrigger from './block-floating-trigger';

const BlockFloatingTriggers = (props) => {
  const { triggersInfo, showDialog, onMouseEnter, onMouseLeave } = props;

  if (!triggersInfo) {
    return null;
  }

  const { elementId, topTrigger, bottomTrigger } = triggersInfo;

  return (
    <Fragment>
      {topTrigger && (
        <BlockFloatingTrigger
          targetElementId={elementId}
          adjacentPosition={topTrigger.adjacentPosition}
          position={topTrigger.position}
          direction={topTrigger.tooltipDirection}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          showDialog={showDialog}
        />
      )}
      {bottomTrigger && (
        <BlockFloatingTrigger
          targetElementId={elementId}
          adjacentPosition={bottomTrigger.adjacentPosition}
          position={bottomTrigger.position}
          direction={bottomTrigger.tooltipDirection}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          showDialog={showDialog}
        />
      )}
    </Fragment>
  );
};

const triggerPropType = PropTypes.shape({
  adjacentPosition: PropTypes.string,
  tooltipDirection: PropTypes.string,
  position: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    stick: PropTypes.string,
  }),
});

BlockFloatingTriggers.propTypes = {
  triggersInfo: PropTypes.shape({
    topTrigger: triggerPropType,
    bottomTrigger: triggerPropType,
    elementId: PropTypes.string,
  }),
  showDialog: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

BlockFloatingTriggers.defaultProps = {
  triggersInfo: null,
  onMouseEnter: Function.prototype,
  onMouseLeave: Function.prototype,
};

export default BlockFloatingTriggers;
