import { anodum, linkEditing } from '@yola/ws-sdk';
import getTemplate from './get-template';

const hasItemCollapse = (item, items) => {
  const { id, order } = item;
  const orderSegments = order.split('.');

  return items.some((compared) => {
    const isSameId = compared.id === id;
    if (isSameId) return false;

    const comparedOrderSegments = compared.order.split('.');
    const isDeeper = comparedOrderSegments.length > orderSegments.length;
    const isNestedToItem =
      isDeeper &&
      comparedOrderSegments
        .slice(0, orderSegments.length)
        .every((segment, index) => segment === orderSegments[index]);

    return isNestedToItem;
  });
};

const buildNewMarkup = (staticElement, items, templates, selectors) => {
  const cloneNode = staticElement.cloneNode(false);
  const containers = {
    top: cloneNode,
  };

  items.forEach((item) => {
    const hasCollapse = hasItemCollapse(item, items);
    const isTopLevel = !item.order.includes('.');
    const template = getTemplate(isTopLevel, hasCollapse, templates);
    const container = isTopLevel
      ? containers.top
      : containers[item.order.substring(0, item.order.lastIndexOf('.'))];

    const element = anodum.parseElementFromString(template);

    if (hasCollapse) {
      containers[item.order] = element.querySelector(selectors.submenu);
      const toggle = element.querySelector('ws-nav-toggle');
      const collapse = element.querySelector('ws-nav-collapse');

      if (toggle) {
        toggle.setAttribute('toggle', `#ws-nav-collapse-${item.id}`);
        collapse.setAttribute('id', `ws-nav-collapse-${item.id}`);

        if (isTopLevel) {
          toggle.setAttribute('group-id', 'top');
        } else {
          toggle.setAttribute('group-id', item.order.slice(0, -2));
        }
      }
    }

    const linkSelector = selectors.itemLink;
    const textSelector = selectors.itemText;
    const link = element.querySelector(linkSelector);
    const textContainer = linkSelector !== textSelector ? link.querySelector(textSelector) : link;

    link.setAttribute(
      linkEditing.constants.common.LINK_CONFIG_ATTRIBUTE,
      JSON.stringify(item.config)
    );

    textContainer.textContent = item.title;

    container.insertAdjacentElement('beforeend', element);
  });

  return cloneNode;
};

export default buildNewMarkup;
