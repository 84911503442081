import { hdrm } from '@yola/ws-sdk';

const { IMAGE_SELECTOR } = hdrm.constants;
const ICON_SELECTOR = 'ws-icon';

const isNonEmptyNode = (node) =>
  !!(node.innerText && node.innerText.trim().length) ||
  !!(
    node.innerHTML &&
    (node.innerHTML.includes(IMAGE_SELECTOR) || node.innerHTML.includes(ICON_SELECTOR))
  );

export default isNonEmptyNode;
