/**
 * This is a specific helper that is needed to determine the exact percentage value for an element that is positioned as follows pattern (similar to positioning as in background-position CSS property):
 *
 *    element {
 *     left: [x];
 *     top: [y];
 *     transform: translate(calc([x] * -1), calc([y] * -1));
 *   }
 */

const convertPercentagesToPx = (value, length) => (value * length) / 100;

const getSuitableTransformPosition = ({
  elementBounds,
  areaBounds,
  distance,
  axisProp,
  startPosition,
}) => {
  if (distance <= 0) {
    return 0;
  }

  const minPosition = startPosition || 0;
  const maxPosition = 100;

  const getSuitableRange = (start, stop, step) => {
    let percentIterator = start;
    let suitableMin = start;
    let suitableMax = stop;

    while (percentIterator <= stop) {
      const distanceInPxRelativeToArea = convertPercentagesToPx(
        percentIterator,
        areaBounds[axisProp]
      );
      const distanceInPxRelativeToElement = convertPercentagesToPx(
        percentIterator,
        elementBounds[axisProp]
      );
      const diff = distanceInPxRelativeToArea - distanceInPxRelativeToElement;

      if (diff <= distance && percentIterator > suitableMin) {
        suitableMin = percentIterator;
      }

      if (diff > distance) {
        suitableMax = percentIterator;

        break;
      }

      percentIterator += step;
    }

    return [suitableMin, suitableMax];
  };

  const decadalRange = getSuitableRange(minPosition, maxPosition, 10);
  const unitRange = getSuitableRange(decadalRange[0], decadalRange[1], 1);
  const decimalRange = getSuitableRange(unitRange[0], unitRange[1], 0.1);

  return decimalRange[0];
};

export default getSuitableTransformPosition;
