import getPartnerData from 'yola-editor/src/js/modules/user/selectors/partner-data';
import getSiteURL from 'yola-editor/src/js/modules/site/selectors/site-url';

const hasWLSiteCustomDomain = (state) => {
  const partnerData = getPartnerData(state);
  const partnerProperties = partnerData && partnerData.properties;
  const freeHostingDomains = partnerProperties && partnerProperties.freeHostingDomains;
  const siteUrl = getSiteURL(state);
  const siteUrlHost = siteUrl ? new URL(siteUrl).host : null;

  // If site url host does NOT END with any domain name within freeHostingDomains - it refers to a custom domain
  return Boolean(
    siteUrlHost &&
      freeHostingDomains &&
      !freeHostingDomains.find((name) => siteUrlHost.endsWith(`.${name}`))
  );
};

export default hasWLSiteCustomDomain;
