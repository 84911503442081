import { i18next, linkEditing } from '@yola/ws-sdk';

const { linkTypes } = linkEditing.constants;

const getLinkTypeOptions = (allowedTypes) => {
  const options = [
    {
      label: i18next.t('No action'),
      value: linkTypes.NO_ACTION,
    },
    {
      label: i18next.t('View full-size image'),
      value: linkTypes.VIEW_FULL_SIZE_IMAGE,
    },
    {
      label: i18next.t('Open a page'),
      value: linkTypes.PAGE,
    },
    {
      label: i18next.t('Scroll to a block'),
      value: linkTypes.ANCHOR,
    },
    {
      label: i18next.t('Open a web address'),
      value: linkTypes.EXTERNAL,
    },
    {
      label: i18next.t('Social media profile'),
      value: linkTypes.SOCIAL_MEDIA,
    },
    {
      label: i18next.t('WhatsApp message'),
      value: linkTypes.WHATS_APP,
    },
    {
      label: i18next.t('Make a phone call'),
      value: linkTypes.PHONE,
    },
    {
      label: i18next.t('Send an email'),
      value: linkTypes.MAIL,
    },
    {
      label: i18next.t('Download a file'),
      value: linkTypes.DOWNLOAD,
    },
  ].filter((option) => allowedTypes.includes(option.value));

  return [
    {
      label: i18next.t('Select an action'),
      options,
    },
  ];
};

export default getLinkTypeOptions;
