import { view } from '@yola/ws-sdk';
import compute from './helpers/compute';
import isWsBlock from '../../../blocks/verifiers/is-ws-block';
import { BACKGROUND_HIDDEN_ATTRIBUTE } from '../../constants/attributes';

const backgroundIsEnabled = async (config, payload) => {
  const { elementId, attr } = payload;

  if (!elementId || attr !== BACKGROUND_HIDDEN_ATTRIBUTE) {
    return [];
  }

  // We need to operate only with liveElement when
  // we work with ws-sitecomponents, to prevent skipping
  // of overlay design-automatization
  const targetBlock = view.accessors.getLiveElement(elementId);

  if (!isWsBlock(targetBlock)) {
    return [];
  }

  return compute(targetBlock);
};

export default backgroundIsEnabled;
