import difference from 'lodash.difference';

const require = [
  'textAreaSelector',
  'contrastTreshold',
  'maxAvailableOpacity',
  'opacityIterationStep',
  'recomendedDarkSurfaceName',
  'overlayColorVariableName',
  'whiteTextColorVariableName',
  'sourceAttributeName',
  'filterAttributeName',
  'overlayAttributeName',
  'overlayOpacityAttributeName',
  'affectedBlocksSelector',
  'affectedBlocksReasonSelector',
];

const isModuleConfigured = (config) => {
  const keys = Object.keys(config);
  const diff = difference(require, keys);
  return diff.length === 0;
};

export default isModuleConfigured;
