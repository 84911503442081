import { textFormatting } from '@yola/ws-sdk';
import wrapElement from './wrap-element';

const wrapInCustomColor = (colorName, elementId) => {
  const { id, className, tagName } = textFormatting.constants.wrappersTypes.customColor;

  return wrapElement(elementId, {
    id,
    className,
    tagName,
    attributes: {
      style: `color: ${colorName}`,
    },
  });
};

export default wrapInCustomColor;
