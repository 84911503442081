import actionTypes from '../constants/action-types';

const show = (componentId, props) => ({
  type: actionTypes.SHOW,
  payload: {
    id: componentId,
    props,
  },
});

export default show;
