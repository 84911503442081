import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const NavContext = createContext();
const { Provider, Consumer } = NavContext;

function NavProvider({ children }) {
  const [isOpen, setOpenStatus] = useState(false);

  const open = () => {
    setOpenStatus(true);
  };

  const close = () => {
    setOpenStatus(false);
  };

  return <Provider value={{ open, close, isOpen }}>{children}</Provider>;
}

NavProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { NavProvider, Consumer as NavConsumer };
export default NavContext;
