import initiateBlockGeneration from './initiate-block-generation';
import initiatePageGeneration from './initiate-page-generation';
import initiatePageReplacement from './initiate-page-replacement';
import initiateTextGeneration from './initiate-text-generation';
import initiateTextReplacement from './initiate-text-replacement';

export default {
  initiateBlockGeneration,
  initiatePageGeneration,
  initiatePageReplacement,
  initiateTextGeneration,
  initiateTextReplacement,
};
