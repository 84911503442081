import actionTypes from '../constants/action-types';

const siteName = (state = '', action) => {
  switch (action.type) {
    case actionTypes.SET_SITE_NAME:
      return action.payload.siteName;
    default:
      return state;
  }
};

export default siteName;
