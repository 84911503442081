import React from 'react';
import PropTypes from 'prop-types';
import { i18next } from '@yola/ws-sdk';
import { Field, designSystem } from '@yola/ws-ui';
import InputGroupField from 'src/js/modules/common/components/input-group-field';

const { Stack, Box, HorizontalStack, ActionButton } = designSystem;

export default function OptionFields({ name, placeholder, form, push, remove, min }) {
  const { values, handleChange } = form;
  const options = values[name] || [];
  const firstEmptyOptionIndex = options.findIndex((option) => !option.trim());
  const placeholderLines = Array.isArray(placeholder) ? placeholder : [placeholder];

  return (
    <div className="ws-option-fields">
      <Stack gap="spacing-3-xs">
        {options.map((option, index) => (
          /* eslint-disable-next-line */
          <HorizontalStack key={`${name}${index}`} gap="spacing-3-xs">
            <div className="ws-option-fields__input">
              <Field
                component={InputGroupField}
                name={`${name}.${index}`}
                placeholder={placeholderLines[index % placeholderLines.length]}
                value={option}
                validate={
                  index === firstEmptyOptionIndex ? () => i18next.t('This field is required') : null
                }
                onChange={handleChange}
              />
            </div>
            <div className="ws-option-fields__icon">
              <ActionButton
                format="text"
                iconGlyph="trash"
                disabled={options.length <= min}
                onClick={() => remove(index)}
              />
            </div>
          </HorizontalStack>
        ))}
      </Stack>
      <Box marginTop="spacing-2-xs">
        <ActionButton label={i18next.t('Add option')} onClick={() => push('')} />
      </Box>
    </div>
  );
}

OptionFields.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.oneOf([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  form: PropTypes.shape({
    values: PropTypes.shape().isRequired,
    handleChange: PropTypes.func.isRequired,
  }).isRequired,
  push: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  min: PropTypes.number,
};

OptionFields.defaultProps = {
  placeholder: '',
  min: 1,
};
