function byOrder(a, b) {
  if (a.order == null && b.order == null) return 0;
  if (a.order == null) return 1;
  if (b.order == null) return -1;

  return a.order - b.order;
}

export default function prepareMenuGroups(groups) {
  const groupsMap = new Map();

  groups.forEach((group) => {
    const { groupName: name = 'default', element, prepareElement, groupOrder } = group;
    const existingGroup = groupsMap.get(name) || { items: [] };
    const newGroup = { ...existingGroup, name, element };

    group.items.forEach((item) => {
      newGroup.items.push({
        ...item,
        matches: item.matches || group.matches,
      });
    });

    if (groupOrder != null) {
      newGroup.order = groupOrder;
    }

    if (prepareElement) {
      newGroup.prepareElement = prepareElement;
    }

    groupsMap.set(name, newGroup);
  });

  groups.forEach((group) => group.items.sort(byOrder));

  return Array.from(groupsMap.values()).sort(byOrder);
}
