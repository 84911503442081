import React, { useState, useRef, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { extensions, dialogs, utils, i18next, linkEditing, integration } from '@yola/ws-sdk';
import {
  Button,
  ButtonGroup,
  Modal,
  Panel,
  PanelGroup,
  Text,
  Field,
  InputField,
  Formik,
  IconInput,
} from '@yola/ws-ui';
import bowser from 'yola-bowser';
import classNames from 'classnames';
import useFeatureFlags from 'yola-editor/src/js/modules/feature-flags/hooks/use-feature-flags';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import segment from 'src/js/modules/analytics/segment';
import common from 'src/js/modules/common/constants/common';
import constants from '../constants';
import getCurrentItemOrder from '../helpers/get-current-item-order';
import validateUrl from '../helpers/validate-url';
import getPreloadedIcons from '../helpers/get-preloaded-icons';
import matchIconName from '../helpers/match-icon-name';
import normalizeUrl from '../helpers/normalize-url';
import getDefaultTraits from '../../../../analytics/segment/helpers/get-default-traits';
import generateRandomId from '../helpers/generate-random-id';
import validateSocialNetworks from '../../../common/helpers/validate-social-networks';
import getCurrentItemSize from '../helpers/get-current-item-size';

const {
  track,
  constants: { events },
} = segment;

const { linkTypes } = linkEditing.constants;
const { BLANK } = linkEditing.constants.common;
const { URL_FIELD_NAME, ICON_EXTENSION_NAME, DIALOG_WIDTH } = constants.common;
const isMobile = bowser.mobile;
const DIALOG_HEIGHT = 308;

const updateItem = (items, id, iconSrc, linkUrl, iconBaseSrc, iconIdAttr) =>
  items.map((currItem) =>
    currItem.id === id
      ? {
          ...currItem,
          iconIdAttr,
          iconSrc,
          iconBaseSrc,
          config: {
            ...currItem.config,
            reference: linkUrl,
          },
        }
      : currItem
  );

const getDefaultItem = (items) => ({
  id: generateRandomId(),
  order: getCurrentItemOrder(items),
  config: {
    linkType: linkTypes.EXTERNAL,
    target: BLANK,
    reference: '',
  },
  iconSrc: '',
  iconBaseSrc: '',
  size: getCurrentItemSize(items),
});

const ItemSettingsDialog = ({ elementId, targetLink, getSharedData, resolveSharedData }) => {
  const id = targetLink && targetLink.id;
  const {
    offsetX: dialogOffsetX,
    offsetY: dialogOffsetY,
    items,
    currentItem,
    isTouched,
    initialItemUrl,
    initialItemIconSrc,
  } = getSharedData();
  const formikRef = useRef();
  const itemModalRef = useRef();
  const [featureFlags] = useFeatureFlags(['limited_external_links']);
  const { limited_external_links: isLimitedExternalLinksEnabled } = featureFlags;
  const userData = useSelector(integration.selectors.getUserData);
  const isB2C = !userData.is_white_label;

  const item = currentItem || targetLink || getDefaultItem(items);
  const [linkUrl, setLinkUrl] = useState(item.config.reference || '');
  const memoizedIcons = useMemo(getPreloadedIcons, []);

  useEffect(() => {
    resolveSharedData({
      initialItemUrl: typeof initialItemUrl === 'string' ? initialItemUrl : item.config.reference,
      initialItemIconSrc:
        typeof initialItemIconSrc === 'string' ? initialItemIconSrc : item.iconSrc,
    });
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  const iconAutocomplete = (url) => {
    if (isTouched) return;

    if (item.iconSrc) {
      const iconFileName = item.iconSrc.split('/').pop();
      const nextIconName = matchIconName(url);

      if (iconFileName.includes(nextIconName)) return;
    }

    const foundIconName = matchIconName(url);

    if (!foundIconName) return;

    const icon = memoizedIcons[foundIconName];

    resolveSharedData({
      currentItem: {
        ...item,
        iconSrc: icon.src,
        iconBaseSrc: null,
      },
    });

    setTimeout(formikRef.current.validateForm, 100);
  };

  const iconSrc = item.iconSrc || '';

  const updateSharedData = (customData) => {
    const {
      state: { offsetX, offsetY },
    } = itemModalRef.current;

    resolveSharedData({
      offsetX,
      offsetY,
      ...customData,
    });
  };

  const closeDialog = () => {
    updateSharedData({
      currentItem: null,
      isTouched: false,
      initialItemUrl: null,
      initialItemIconSrc: null,
    });

    dialogs.operations.show(dialogTypes.SOCIAL_LINKS_SETTINGS_DIALOG, { elementId, items });

    const eventName = id
      ? events.SOCIAL_LINKS_EDIT_LINK_DIALOG_CANCELED
      : events.SOCIAL_LINKS_ADD_LINK_DIALOG_CANCELED;

    track(eventName, {
      ...getDefaultTraits(elementId),
      dialogId: dialogTypes.SOCIAL_LINKS_ITEM_SETTINGS_DIALOG,
    });
  };

  const openIconExtension = () => {
    const extension = extensions.accessors.getExtension(ICON_EXTENSION_NAME);
    const control = extension.ui.control[0];
    const {
      state: { offsetX, offsetY },
    } = itemModalRef.current;

    const onDialogCancel = (newOffsetX, newOffsetY) => {
      resolveSharedData({
        offsetX: newOffsetX,
        offsetY: newOffsetY,
        currentItem: {
          ...item,
          config: {
            ...item.config,
            reference: linkUrl,
          },
        },
        isTouched,
      });

      dialogs.operations.show(dialogTypes.SOCIAL_LINKS_ITEM_SETTINGS_DIALOG, {
        elementId,
        targetLink,
      });
    };

    const onSubmit = ({ newIconSrc, newIconBaseSrc, newOffsetX, newOffsetY, iconIdAttr }) => {
      resolveSharedData({
        offsetX: newOffsetX,
        offsetY: newOffsetY,
        currentItem: {
          ...item,
          config: {
            ...item.config,
            reference: linkUrl,
          },
          iconSrc: newIconSrc,
          iconBaseSrc: newIconBaseSrc,
          iconIdAttr,
        },
        isTouched: true,
      });

      dialogs.operations.show(dialogTypes.SOCIAL_LINKS_ITEM_SETTINGS_DIALOG, {
        elementId,
        targetLink,
      });
    };

    control.openExtension({
      offsetX,
      offsetY,
      iconSrc,
      onDialogCancel,
      onSubmit,
      elementId,
      iconIdAttr: item.iconIdAttr,
    });
  };

  const onSubmit = async () => {
    const socialLink = { ...item };

    if (!socialLink.iconBaseSrc) {
      const {
        api: { copyExtensionAsset },
      } = extensions.accessors.getExtension(ICON_EXTENSION_NAME);
      socialLink.iconBaseSrc = await copyExtensionAsset(socialLink.iconSrc);
    }

    const url = normalizeUrl(linkUrl);

    const updatedItems = id
      ? updateItem(items, id, iconSrc, url, socialLink.iconBaseSrc, socialLink.iconIdAttr)
      : [...items, { ...socialLink, config: { ...socialLink.config, reference: url } }];

    if (id) {
      track(events.SOCIAL_LINKS_EDIT_LINK_DIALOG_SUBMITTED, {
        ...getDefaultTraits(elementId),
        dialogId: dialogTypes.SOCIAL_LINKS_ITEM_SETTINGS_DIALOG,
        iconSelected: initialItemIconSrc !== iconSrc,
        urlChanged: initialItemUrl !== linkUrl,
      });
    } else {
      track(events.SOCIAL_LINKS_ADD_LINK_DIALOG_SUBMITTED, {
        ...getDefaultTraits(elementId),
        dialogId: dialogTypes.SOCIAL_LINKS_ITEM_SETTINGS_DIALOG,
        iconSelected: initialItemIconSrc !== iconSrc,
        urlChanged: true,
      });
    }

    updateSharedData({
      items: updatedItems,
      currentItem: null,
      isTouched: false,
      initialItemUrl: null,
      initialItemIconSrc: null,
    });

    dialogs.operations.show(dialogTypes.SOCIAL_LINKS_SETTINGS_DIALOG, {
      elementId,
      items: updatedItems,
    });
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === common.ENTER_KEYCODE) {
      formikRef.current.handleSubmit();
    }
  };

  const handleLinkUrlChange = (value) => {
    setLinkUrl(value);

    iconAutocomplete(value);
  };

  const validateUrlField = (value) => {
    let validationMessage = validateUrl(value);

    if (validationMessage) return validationMessage;

    const hasValueChanged = value !== initialItemUrl;
    const shouldValidateSocialNetworks = isLimitedExternalLinksEnabled && hasValueChanged && isB2C;

    if (shouldValidateSocialNetworks) {
      validationMessage = validateSocialNetworks(value);
    }

    return validationMessage;
  };

  const handleInputBlur = () => {
    formikRef.current.validateField(URL_FIELD_NAME);
  };

  const itemSettingsClasses = classNames('ws-social-link-item-settings-dialog', {
    'ws-social-link-item-settings-dialog--mobile': isMobile,
  });

  return (
    <Formik
      ref={formikRef}
      initialValues={{
        url: linkUrl,
        icon: iconSrc,
      }}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, values, errors }) => (
        <Modal
          className={itemSettingsClasses}
          modalRef={itemModalRef}
          handleCancel={closeDialog}
          handleSubmit={handleSubmit}
          isValid={!Object.values(errors).filter(Boolean).length}
          resizable={false}
          width={isMobile ? window.innerWidth : DIALOG_WIDTH}
          maxHeight={DIALOG_HEIGHT}
          height="auto"
          minWidth={320}
          offsetX={isMobile ? 0 : dialogOffsetX}
          offsetY={isMobile ? window.innerHeight : dialogOffsetY}
          dragHandleSelector=".ws-drag-trigger"
          onKeyDown={handleKeyDown}
          fullscreen={false}
          data={values}
        >
          <PanelGroup
            className="ws-social-item-settings"
            height="100%"
            style={{ overflow: 'visible', flexShrink: '0' }}
          >
            <Panel
              theme="gray-100"
              corners="squared"
              align="middle"
              height={48}
              style={{ flexShrink: '0' }}
              className="ws-drag-trigger ws-modal__content"
            >
              <Text type="heading-6">
                {id ? i18next.t('Social link settings') : i18next.t('New item')}
              </Text>
            </Panel>
            <Panel
              theme="white"
              height="100%"
              style={{ overflow: 'visible', borderBottom: 'none' }}
            >
              <div className="ws-modal__content">
                <div className="ws-modal__block">
                  <Text type="heading-6" theme="default">
                    {i18next.t('Link URL')}
                  </Text>
                  <Field
                    className="ws-input--placeholder"
                    placeholder="http://"
                    name={URL_FIELD_NAME}
                    component={InputField}
                    onChange={handleLinkUrlChange}
                    onBlur={handleInputBlur}
                    validate={validateUrlField}
                  />
                </div>

                <div className="ws-modal__block">
                  <Text type="heading-6" theme="default">
                    {i18next.t('Link Icon')}
                  </Text>
                  <Field
                    className="ws-input--placeholder"
                    placeholder="http://"
                    name="icon"
                    iconSrc={iconSrc}
                    component={IconInput}
                    error={errors.icon}
                    onClick={openIconExtension}
                    captions={{
                      button: i18next.t('Choose icon'),
                      empty: i18next.t('No icon chosen'),
                    }}
                    validate={(value) => (value || item.iconSrc ? null : i18next.t('Choose icon'))}
                  />
                </div>
              </div>
            </Panel>
            <Panel theme="white" style={{ flexShrink: '0' }} height={48}>
              <ButtonGroup stick="top" block>
                <Button
                  stretch="block"
                  size="large"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  {i18next.t('Submit')}
                </Button>
                <Button stretch="block" size="large" onClick={closeDialog}>
                  {i18next.t('Cancel')}
                </Button>
              </ButtonGroup>
            </Panel>
          </PanelGroup>
        </Modal>
      )}
    </Formik>
  );
};

ItemSettingsDialog.propTypes = {
  elementId: PropTypes.string,
  targetLink: PropTypes.shape({
    id: PropTypes.string,
  }),
  getSharedData: PropTypes.func,
  resolveSharedData: PropTypes.func,
};

ItemSettingsDialog.defaultProps = {
  elementId: '',
  targetLink: null,
  getSharedData: utils.noop,
  resolveSharedData: utils.noop,
};

export default ItemSettingsDialog;
