import React, { useContext, useEffect, useRef, useState } from 'react';
import { i18next, template } from '@yola/ws-sdk';
import { designSystem } from '@yola/ws-ui';
import BlocksLibraryContext from '../contexts/block-library-context';
import CategoriesList from '../components/categories-list';
import Banner from '../components/banner';
import { CLICK_ACTIONS, POSITIONS } from '../constants/block-categories';
import handleBannerIntersection from '../helpers/handle-banner-intersection';
import { trackSearchClicked } from '../helpers/blocks-library-analytics';
import dashesToCapitalizeSentence from '../../utils/dashes-to-capitalize-sentence';
import { DONATIONS_CATEGORY_NAME } from '../constants/common';

const { Search, Box } = designSystem;

const convertCategoryNameToId = (name) => name.split(' ').join('-');

function BlocksLibrarySidebarContainer() {
  const {
    categories,
    activeCategory,
    onSearch,
    onSearchValueClear,
    onCategorySelect,
    onBannerSelect,
    onClose,
  } = useContext(BlocksLibraryContext);

  const scrollableContainerRef = useRef(null);
  const [observer, setObserver] = useState(null);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    const bannersIntersectionOptions = {
      root: scrollableContainerRef.current,
      threshold: 1,
    };

    if (!observer) {
      setObserver(
        new IntersectionObserver((entries) => {
          entries.forEach(handleBannerIntersection);
        }, bannersIntersectionOptions)
      );
    }
  }, [observer, setObserver]);

  const handleCategorySelect = (category) => {
    setSearchValue('');
    onCategorySelect(category);
  };

  const handleBannerSelect = (category) => {
    setSearchValue('');
    onBannerSelect(category.name);
  };

  const handleSearchValueClear = () => {
    setSearchValue('');
    onSearchValueClear();
  };

  const handleSearchValueChange = (value) => {
    setSearchValue(value);
    onSearch(value);
  };

  const bannersTopUi = [];
  const categoriesListUi = [];
  const bannersBottomUi = [];

  categories.forEach((category) => {
    if (category.hidden) return;

    if (category.clickAction === CLICK_ACTIONS.INSERT_BLOCK) {
      const bannerProps = {
        title: dashesToCapitalizeSentence(template.accessors.getMetaTranslations(category.name)),
        glyph: category.icon,
        description: template.accessors.getMetaTranslations(category.description),
        onClick: () => handleBannerSelect(category),
        id: convertCategoryNameToId(category.name),
        key: convertCategoryNameToId(category.name),
        observer,
      };

      if (category.position === POSITIONS.TOP) {
        bannersTopUi.push(bannerProps);
      }

      if (category.position === POSITIONS.BOTTOM) {
        bannersBottomUi.push(bannerProps);
      }
    } else {
      categoriesListUi.push({
        ...category,
        label:
          category.name === DONATIONS_CATEGORY_NAME
            ? i18next.t('Donations')
            : dashesToCapitalizeSentence(template.accessors.getMetaTranslations(category.name)),
      });
    }
  });

  return (
    <div className="ws-blocks-library__sidebar">
      <div className="ws-blocks-library__header">
        <span>{i18next.t('Blocks library')}</span>
        <button onClick={onClose} type="button" className="ws-blocks-library__header-close">
          {i18next.t('Close')}
        </button>
      </div>
      <div ref={scrollableContainerRef} className="ws-blocks-library__menu">
        <Box padding="spacing-xs">
          <Search
            defaultValue={searchValue}
            placeholder={i18next.t('Search...')}
            onChange={handleSearchValueChange}
            onClear={handleSearchValueClear}
            onFocus={trackSearchClicked}
          />
        </Box>

        {bannersTopUi.map((bannerProps) => (
          <Banner {...bannerProps} />
        ))}

        <CategoriesList
          categories={categoriesListUi}
          activeCategory={activeCategory}
          onSelect={handleCategorySelect}
        />

        {bannersBottomUi.map((props) => (
          <Banner {...props} />
        ))}
      </div>
    </div>
  );
}

export default BlocksLibrarySidebarContainer;
