import PropTypes from 'prop-types';
import classNames from 'classnames';
import React from 'react';
import { utils } from '@yola/ws-sdk';
import { connect } from 'react-redux';
import ControlPaneTrigger from './control-pane-trigger';
import ControlPaneTriggerDivider from './control-pane-trigger-divider';
import contextMenu from '../../context-menu';
import { TEXT_ELEMENT_TYPE_TRIGGER_ID, FONT_SIZE_TRIGGER_ID } from '../constants/trigger-ids';

/*
 * this array contains triggers that use react-select-plus under the hood
 * it's needed to determine whether we should add onTouchEnd handler or not
 * in order to fix click propagation to the context menu
 *
 * it should be removed as well as onTouchEnd handler after we replace react-select-plus with react-select
 */
const REACT_SELECT_TRIGGERS = [TEXT_ELEMENT_TYPE_TRIGGER_ID, FONT_SIZE_TRIGGER_ID];

class ControlPaneTriggers extends React.Component {
  constructor(props) {
    super(props);

    this.references = {};

    this.bindMethods();

    // we need this in order to prevent executing code in onClick function
    // which is duplication of code from onTouchEnd
    // it should be removed once onTouchEnd is removed
    this.preventClick = false;
  }

  onMouseEnter(event) {
    const { onItemHover } = this.props;
    const index = utils.getEventTargetIndex(event);

    onItemHover(index);
  }

  onTouchEnd(event) {
    // For some cases we use `react-select-plus` as a trigger.
    // This component prevents `touchend` event
    // https://github.com/HubSpot/react-select-plus/blob/8ae7465fc9b6b9600a01e4e23de97f2d403fd139/src/Select.js#L299-L300
    // and we are not able to process click event anymore.
    // As a temporary fix while `react-select-plus` is used
    // `touchend` event is used instead of `click`

    const { onItemClick } = this.props;
    const index = utils.getEventTargetIndex(event);

    onItemClick(index, this.references[index]);
    this.preventClick = true;
  }

  onClick(event) {
    if (!this.preventClick) {
      const { onItemClick } = this.props;
      const index = utils.getEventTargetIndex(event);

      onItemClick(index, this.references[index]);
    } else {
      this.preventClick = false;
    }
  }

  isActiveTrigger(triggerItem) {
    const {
      menu: { visibility, triggerId },
    } = this.props;

    return triggerItem.active || (visibility && triggerId === triggerItem.id);
  }

  bindMethods() {
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onClick = this.onClick.bind(this);
    this.isActiveTrigger = this.isActiveTrigger.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
  }

  render() {
    const { triggers, isAnimationInAction } = this.props;

    const shouldRenderDivider = (triggerItem, index) => {
      // Skip divider rendering if trigger is the last in the row
      const isLastItem = triggers.length === index + 1;
      return triggerItem.hasDivider && !isLastItem;
    };

    const getTriggerWrapperClassNames = (triggerItem, index) =>
      classNames(
        'ws-control-pane-triggers__trigger-wrapper',
        {
          'ws-control-pane-triggers__trigger-wrapper--is-animated': isAnimationInAction,
          'ws-control-pane-triggers__trigger-wrapper--has-divider': shouldRenderDivider(
            triggerItem,
            index
          ),
        },
        triggerItem.triggerWrapperClass
      );

    return (
      <div className="ws-control-pane-triggers">
        {triggers.map((triggerItem, index) => (
          <div key={triggerItem.index} className={getTriggerWrapperClassNames(triggerItem, index)}>
            <ControlPaneTrigger
              ref={(ref) => {
                this.references[triggerItem.index] = ref;
              }}
              active={this.isActiveTrigger(triggerItem)}
              data-tip={triggerItem.tooltip}
              data-index={triggerItem.index}
              onClick={this.onClick}
              onMouseDown={triggerItem.onMouseDown}
              onMouseEnter={this.onMouseEnter}
              onMouseLeave={triggerItem.onMouseLeave}
              onTouchEnd={
                REACT_SELECT_TRIGGERS.includes(triggerItem.id) ? this.onTouchEnd : undefined
              }
              component={triggerItem.trigger}
            />
            {shouldRenderDivider(triggerItem, index) && <ControlPaneTriggerDivider />}
          </div>
        ))}
      </div>
    );
  }
}
ControlPaneTriggers.propTypes = {
  showMore: PropTypes.shape({
    tooltip: PropTypes.string,
    onClick: PropTypes.func,
  }),
  triggers: PropTypes.arrayOf(
    PropTypes.shape({
      trigger: PropTypes.node.isRequired,
      tooltip: PropTypes.string,
      index: PropTypes.number,
    })
  ).isRequired,
  onItemClick: PropTypes.func,
  onItemHover: PropTypes.func,
  isAnimationInAction: PropTypes.bool,
  menu: PropTypes.shape({
    visibility: PropTypes.bool,
    triggerId: PropTypes.string,
  }).isRequired,
};

ControlPaneTriggers.defaultProps = {
  showMore: null,
  onItemClick: null,
  onItemHover: null,
  isAnimationInAction: false,
};

const mapStateToProps = (state) => ({
  menu: contextMenu.selectors.getMenu(state),
});

export default connect(mapStateToProps, null)(ControlPaneTriggers);
