import { view, hdrm, anodum, constraints } from '@yola/ws-sdk';

const getOuterDropTargetElement = (draggedElement, liveElement) => {
  if (
    liveElement === null ||
    liveElement === draggedElement ||
    anodum.isChildOfElement(liveElement, draggedElement)
  ) {
    return null;
  }

  const liveParentElement = view.accessors.getLiveElement(
    hdrm.instance.driver.referenceMap.getParentReferenceId(liveElement)
  );

  if (!liveParentElement) {
    return null;
  }

  if (constraints.verifiers.canBeNested(draggedElement, liveParentElement)) {
    return liveElement;
  }

  return getOuterDropTargetElement(draggedElement, liveParentElement);
};

export default getOuterDropTargetElement;
