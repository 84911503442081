import getRecaptchaKey from './get-recaptcha-key';
import getHcaptchaKey from './get-hcaptcha-key';
import getContactFormSubmissionUrl from './get-contact-form-submission-url';
import getGoogleMapsEmbedApiKey from './google-maps-embed-api-key';
import getClipboardAccessInstructionUrl from './get-clipboard-access-instruction-url';
import getEnableCopyPasteInstructionUrl from './get-enable-copy-paste-instruction-url';

export default {
  getRecaptchaKey,
  getHcaptchaKey,
  getContactFormSubmissionUrl,
  getGoogleMapsEmbedApiKey,
  getClipboardAccessInstructionUrl,
  getEnableCopyPasteInstructionUrl,
};
