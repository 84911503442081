import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Modal, designSystem } from '@yola/ws-ui';
// eslint-disable-next-line yola/import/no-extraneous-dependencies
import Realistic from 'react-canvas-confetti/dist/presets/realistic';

const { Box, Stack, IconButton, ActionButton, Heading, Paragraph, BetaLabel } = designSystem;

const MODAL_WIDTH = 680;
const MODAL_HEIGHT = 624;

const WizardIntroDialog = ({
  captions,
  imageSrc,
  isDesktopView,
  initConfetti,
  onClose,
  onSubmit,
}) => {
  const classes = classNames('ws-wizard-intro-dialog', {
    'ws-wizard-intro-dialog--desktop-view': isDesktopView,
  });

  const handleConfettiInit = ({ conductor }) =>
    conductor.run({ speed: 5, delay: 500, duration: 50 });

  return (
    <Fragment>
      <Modal
        className={classes}
        width={MODAL_WIDTH}
        height={MODAL_HEIGHT}
        overlay="visible"
        fullscreen={!isDesktopView}
        draggable={false}
        resizable={false}
        centered
      >
        <div className="ws-wizard-intro-dialog__close-button">
          <IconButton
            glyph="close"
            iconPlacement="after"
            size="medium"
            onClick={onClose}
            transparent
            selected
          />
        </div>
        <img className="ws-wizard-intro-dialog__image" src={imageSrc} alt={captions.description} />
        <Box
          padding="spacing-m"
          paddingTop="spacing-l"
          paddingBottom="spacing-l"
          breakpoints={{
            sm: {
              padding: 'spacing-xl',
              paddingTop: 'spacing-m',
              paddingBottom: 'spacing-l',
            },
            md: {
              padding: 'spacing-2-xl',
              paddingTop: 'spacing-m',
              paddingBottom: 'spacing-xl',
            },
          }}
        >
          <div className="ws-wizard-intro-dialog__content">
            <Box marginBottom="spacing-3-xs">
              <BetaLabel />
            </Box>
            <Stack gap="spacing-2-xs">
              <Heading type="heading-2">{captions.title}</Heading>
              <Paragraph size="large">{captions.description}</Paragraph>
            </Stack>
            <div className="ws-wizard-intro-dialog__submit-button">
              <Box marginTop="spacing-m">
                <ActionButton
                  appearance="accent"
                  format="solid"
                  size="large"
                  iconGlyph="ai"
                  fullWidth={!isDesktopView}
                  label={captions.buttonLabel}
                  onClick={onSubmit}
                />
              </Box>
            </div>
          </div>
        </Box>
      </Modal>
      {initConfetti && (
        <Realistic onInit={handleConfettiInit} className="ws-wizard-intro-dialog__confetti" />
      )}
    </Fragment>
  );
};

WizardIntroDialog.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.node.isRequired,
    description: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string.isRequired,
  }).isRequired,
  imageSrc: PropTypes.string.isRequired,
  isDesktopView: PropTypes.bool.isRequired,
  initConfetti: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default WizardIntroDialog;
