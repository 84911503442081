import { i18next } from '@yola/ws-sdk';
import { selectors, verifiers } from '@yola/subscription-manager-js';
import user from 'yola-editor/src/js/modules/user';
import isBrandedFooterFeatureEnabled from './is-branded-footer-feature-enabled';
import isBrandedHeaderFeatureEnabled from './is-branded-header-feature-enabled';
import actions from '../actions';
import {
  BRANDED_FOOTER,
  BRANDED_HEADER,
  CUSTOM_CODE,
  DEFAULT,
  PREMIUM_BLOCKS,
  MOBILE_PLUS,
  ONLINE_STORE,
  PAGES_COUNT,
  STORAGE_LIMIT,
  UPLOAD_FILE_SIZE,
  CUSTOM_DOMAIN,
  NOFOLLOW_CONTROLS,
  SITE_BACKUPS,
  MULTILINGUAL,
  EXTERNAL_LINKS,
  PREMIUM_ICONS,
  ADVANCED_COLOR_CUSTOMIZATION,
  PASSWORD_PROTECTED_PAGES,
  FORM_ATTACHMENTS,
  AI_PAGE_WIZARD,
  AI_BLOCK_GENERATION_QUOTA,
  AI_TEXT_ASSISTANT_QUOTA,
  PREMIUM_BUTTON_PRESETS,
  PREMIUM_TEXT_DECORATIONS,
} from '../constants/upsell-types';
import getPurchaseFlowType from './get-purchase-flow-type';
import hasSiteCustomDomain from '../../publishing/verifiers/has-site-custom-domain';
import hasWLSiteCustomDomain from '../../publishing/verifiers/has-wl-site-custom-domain';
import hasPartnersDomainUpsell from '../../user/verifiers/has-partners-domain-upsell';

const getUpsellSettings = (data) => {
  const { isFreeDomainAvailable, state, dispatch } = data;
  const partnerData = user.selectors.getPartnerData(state);

  const {
    isWhiteLabel: isWl,
    properties: { submitUserActionUrl, freeHostingDomains },
  } = partnerData;

  const currentPackageType = selectors.getCurrentPackageType(state);
  const hasUpgradeOpportunity = selectors.getHostingUpgradeAvailability(state);
  const UPGRADE_REQUIRED = i18next.t('Upgrade required');
  const UPGRADE_NOW = i18next.t('Upgrade Now');
  const UPGRADE = i18next.t('Upgrade');
  const purchaseFlowType = getPurchaseFlowType(state);

  // If partner is configured for in-app domains purchasing,
  // all upgrade modals should list this ability in entitlements
  const isInAppDomainsConfigured = Boolean(submitUserActionUrl && freeHostingDomains);
  const isAddOnPresented =
    isBrandedFooterFeatureEnabled(state) || isBrandedHeaderFeatureEnabled(state);
  const ENTITLEMENTS = [
    i18next.t('{count, plural, one {# page} other {# pages}}', {
      count: 1000,
    }),
    i18next.t('Upgraded storage'),
    i18next.t('Unlimited languages'),
    ...(isInAppDomainsConfigured ? [i18next.t('Publish to a custom domain')] : []),
    ...(isAddOnPresented ? [i18next.t('Remove ads')] : []),
    i18next.t('Advanced editing'),
    i18next.t('Premium blocks'),
  ];

  const ONLINE_STORE_ENTITLEMENTS = [
    i18next.t('Built-in shopping cart'),
    i18next.t('Manage your inventory online'),
    i18next.t('Dozens of supported languages'),
    i18next.t('Accept credit cards or PayPal'),
    i18next.t('Multiple payment gateways'),
    i18next.t('Local and international shipping providers'),
  ];

  const EXTERNAL_LINKS_ENTITLEMENTS = [
    i18next.t('{count, plural, one {# page} other {# pages}}', {
      count: 1000,
    }),
    i18next.t('Upgraded storage'),
    ...(isBrandedHeaderFeatureEnabled ? [i18next.t('Remove header branding')] : []),
    i18next.t('Advanced editing'),
    i18next.t('External links'),
    i18next.t('Premium blocks'),
  ];

  const PASSWORD_PROTECTED_PAGES_ENTITLEMENTS = [
    i18next.t('{count, plural, one {# page} other {# pages}}', {
      count: 1000,
    }),
    i18next.t('Upgraded storage'),
    i18next.t('Unlimited languages'),
    ...(isAddOnPresented ? [i18next.t('Remove ads')] : []),
    i18next.t('Advanced editing'),
    i18next.t('Page password protection'),
    i18next.t('Premium blocks'),
  ];

  const getInternalUpgradeCallback = (upsellType) => (details) =>
    new Promise((resolve, reject) => {
      const editorUpgradeAction = { resolve, reject };
      dispatch(actions.triggerUpgrade(upsellType, editorUpgradeAction, details));
    });

  const defaultUpsell = {
    purchaseFlowType,
    onUpgrade: getInternalUpgradeCallback(DEFAULT),
    captions: {
      title: i18next.t('Upgrade Now'),
      description: i18next.t(
        'Gain access to professional features by upgrading your subscription!'
      ),
      entitlements: isWl ? ENTITLEMENTS : null,
      upgrade: UPGRADE,
    },
  };

  let withUpsell = !hasSiteCustomDomain(state);

  if (isWl) {
    const hasCustomDomain = hasWLSiteCustomDomain(state);
    const withPartnersDomainUpsell = hasPartnersDomainUpsell(state);
    withUpsell = (isInAppDomainsConfigured && !hasCustomDomain) || withPartnersDomainUpsell;
  }

  return {
    default: hasUpgradeOpportunity && defaultUpsell,
    premiumBlocks: {
      purchaseFlowType,
      onUpgrade: getInternalUpgradeCallback(PREMIUM_BLOCKS),
      premiumFeature: PREMIUM_BLOCKS,
      captions: {
        title: UPGRADE_REQUIRED,
        description: i18next.t('Gain access to premium blocks by upgrading your subscription!'),
        entitlements: ENTITLEMENTS,
        upgrade: UPGRADE_NOW,
      },
    },
    premiumWidget: {
      purchaseFlowType,
      onUpgrade: getInternalUpgradeCallback(PREMIUM_BLOCKS),
      premiumFeature: PREMIUM_BLOCKS,
      captions: {
        title: UPGRADE_REQUIRED,
        description: i18next.t('Gain access to premium widgets by upgrading your subscription!'),
        entitlements: ENTITLEMENTS,
        upgrade: UPGRADE_NOW,
      },
    },
    premiumButtonPresets: {
      purchaseFlowType,
      onUpgrade: getInternalUpgradeCallback(PREMIUM_BUTTON_PRESETS),
      premiumFeature: PREMIUM_BUTTON_PRESETS,
      captions: {
        title: UPGRADE_REQUIRED,
        entitlements: ENTITLEMENTS,
        upgrade: UPGRADE_NOW,
      },
    },
    premiumTextDecorations: {
      purchaseFlowType,
      onUpgrade: getInternalUpgradeCallback(PREMIUM_TEXT_DECORATIONS),
      premiumFeature: PREMIUM_TEXT_DECORATIONS,
      captions: {
        title: UPGRADE_REQUIRED,
        entitlements: ENTITLEMENTS,
        upgrade: UPGRADE_NOW,
      },
    },
    mobilePlus: {
      purchaseFlowType,
      onUpgrade: getInternalUpgradeCallback(MOBILE_PLUS),
      premiumFeature: MOBILE_PLUS,
      captions: {
        title: UPGRADE_REQUIRED,
        description: i18next.t(
          'Gain access to the Mobile Plus block by upgrading to a premium subscription!'
        ),
        entitlements: ENTITLEMENTS,
        upgrade: UPGRADE_NOW,
      },
    },
    customCode: {
      purchaseFlowType,
      onUpgrade: getInternalUpgradeCallback(CUSTOM_CODE),
      premiumFeature: CUSTOM_CODE,
      captions: {
        title: UPGRADE_REQUIRED,
        description: i18next.t(
          'Gain access to custom code editing by upgrading your subscription!'
        ),
        upgrade: UPGRADE_NOW,
      },
    },
    brandedFooter: {
      purchaseFlowType,
      onUpgrade: getInternalUpgradeCallback(BRANDED_FOOTER),
      premiumFeature: BRANDED_FOOTER,
      captions: {
        title: UPGRADE_REQUIRED,
        description: i18next.t(
          'Remove the branding from the footer of your website, making it completely your own by upgrading now!'
        ),
        entitlements: ENTITLEMENTS,
        upgrade: UPGRADE_NOW,
      },
    },
    brandedHeader: {
      purchaseFlowType,
      onUpgrade: getInternalUpgradeCallback(BRANDED_HEADER),
      premiumFeature: BRANDED_HEADER,
      captions: {
        title: UPGRADE_REQUIRED,
        description: i18next.t(
          'Remove the branding from your website, making it completely your own by upgrading now!'
        ),
        entitlements: ENTITLEMENTS,
        upgrade: UPGRADE_NOW,
      },
    },
    pagesCount: {
      purchaseFlowType,
      onUpgrade: getInternalUpgradeCallback(PAGES_COUNT),
      premiumFeature: PAGES_COUNT,
      captions: {
        title: UPGRADE_REQUIRED,
        description: i18next.t(
          'You have reached your page limit. Gain access to increased page limits by upgrading your subscription!'
        ),
        entitlements: ENTITLEMENTS,
        upgrade: UPGRADE_NOW,
      },
    },
    storageLimit: {
      purchaseFlowType,
      onUpgrade: getInternalUpgradeCallback(STORAGE_LIMIT),
      premiumFeature: STORAGE_LIMIT,
      captions: {
        title: UPGRADE_REQUIRED,
        description: i18next.t(
          "You've reached your site storage limit. Gain access to unlimited site storage by upgrading now!"
        ),
        entitlements: ENTITLEMENTS,
        upgrade: UPGRADE_NOW,
      },
    },
    uploadFileSize: {
      purchaseFlowType,
      onUpgrade: getInternalUpgradeCallback(UPLOAD_FILE_SIZE),
      premiumFeature: UPLOAD_FILE_SIZE,
      captions: {
        title: UPGRADE_REQUIRED,
        description: i18next.t(
          "You've exceeded your individual file size limit. Increase your file size limit by upgrading your subscription!"
        ),
        entitlements: ENTITLEMENTS,
        upgrade: UPGRADE_NOW,
      },
    },
    onlineStore: {
      purchaseFlowType,
      onUpgrade: getInternalUpgradeCallback(ONLINE_STORE),
      premiumFeature: ONLINE_STORE,
      captions: {
        title: UPGRADE_REQUIRED,
        description: i18next.t('Sell online by upgrading to an Online Store subscription!'),
        entitlements: ONLINE_STORE_ENTITLEMENTS,
        upgrade: UPGRADE_NOW,
      },
    },
    customDomain: {
      purchaseFlowType,
      onUpgrade: getInternalUpgradeCallback(CUSTOM_DOMAIN),
      premiumFeature: CUSTOM_DOMAIN,
      captions: {
        title: UPGRADE_REQUIRED,
        description: i18next.t('Publish your website to your own custom domain by upgrading now!'),
        entitlements: ENTITLEMENTS,
        upgrade: UPGRADE_NOW,
      },
      topBarButton: withUpsell && {
        captions: {
          upgrade: isFreeDomainAvailable ? i18next.t('Get free domain') : i18next.t('Buy a domain'),
        },
      },
    },
    nofollowControls: {
      purchaseFlowType,
      onUpgrade: getInternalUpgradeCallback(NOFOLLOW_CONTROLS),
      premiumFeature: NOFOLLOW_CONTROLS,
      captions: {
        title: UPGRADE_REQUIRED,
        description: i18next.t(
          'Gain access to Nofollow tag controls by upgrading your subscription!'
        ),
        entitlements: ENTITLEMENTS,
        upgrade: UPGRADE_NOW,
      },
    },
    siteBackups: {
      purchaseFlowType,
      onUpgrade: getInternalUpgradeCallback(SITE_BACKUPS),
      premiumFeature: SITE_BACKUPS,
      captions: {
        title: UPGRADE_REQUIRED,
        description: verifiers.isFreeHostingPackageType(currentPackageType)
          ? i18next.t('Create more site backups by upgrading to a premium subscription')
          : i18next.t('Gain access to older site backups by upgrading to a premium subscription'),
        upgrade: UPGRADE_NOW,
      },
    },
    multilingual: {
      purchaseFlowType,
      onUpgrade: getInternalUpgradeCallback(MULTILINGUAL),
      premiumFeature: MULTILINGUAL,
      captions: {
        title: UPGRADE_REQUIRED,
        description: i18next.t(
          'Gain access to unlimited number of languages by upgrading your subscription!'
        ),
        entitlements: ENTITLEMENTS,
        upgrade: UPGRADE_NOW,
      },
    },
    externalLinks: {
      purchaseFlowType,
      onUpgrade: getInternalUpgradeCallback(EXTERNAL_LINKS),
      premiumFeature: EXTERNAL_LINKS,
      captions: {
        title: UPGRADE_REQUIRED,
        description: i18next.t(
          'Upgrade your subscription to be able to make external links by updating now!'
        ),
        entitlements: EXTERNAL_LINKS_ENTITLEMENTS,
        upgrade: UPGRADE_NOW,
      },
    },
    premiumIcons: {
      purchaseFlowType,
      onUpgrade: getInternalUpgradeCallback(PREMIUM_ICONS),
      premiumFeature: PREMIUM_ICONS,
      captions: {
        title: UPGRADE_REQUIRED,
        description: i18next.t('Unlock over 1.2 million premium icons with our upgraded plan'),
        upgrade: UPGRADE,
      },
    },
    advancedColorCustomization: {
      purchaseFlowType,
      onUpgrade: getInternalUpgradeCallback(ADVANCED_COLOR_CUSTOMIZATION),
      premiumFeature: ADVANCED_COLOR_CUSTOMIZATION,
      captions: {
        title: i18next.t('Unlock deep customization!'),
        description: i18next.t(
          "Take full control of every block's color scheme with Premium subscription"
        ),
        upgrade: UPGRADE,
      },
    },
    passwordProtectedPages: {
      purchaseFlowType,
      onUpgrade: getInternalUpgradeCallback(PASSWORD_PROTECTED_PAGES),
      premiumFeature: PASSWORD_PROTECTED_PAGES,
      captions: {
        title: UPGRADE_REQUIRED,
        description: i18next.t(
          'Upgrade your subscription to enable password protection for your pages!'
        ),
        paywallDescription: i18next.t(
          'Gain access to page access controls by upgrading your subscription!'
        ),
        entitlements: PASSWORD_PROTECTED_PAGES_ENTITLEMENTS,
        upgrade: UPGRADE_NOW,
      },
    },
    formAttachments: {
      purchaseFlowType,
      onUpgrade: getInternalUpgradeCallback(FORM_ATTACHMENTS),
      premiumFeature: FORM_ATTACHMENTS,
      captions: {
        title: UPGRADE_REQUIRED,
        description: i18next.t('Gain access to premium widgets by upgrading your subscription!'),
        paywallDescription: i18next.t(
          'Gain access to file upload inputs by upgrading your subscription!'
        ),
        entitlements: ENTITLEMENTS,
        upgrade: UPGRADE_NOW,
      },
    },
    aiPageWizard: {
      purchaseFlowType,
      onUpgrade: getInternalUpgradeCallback(AI_PAGE_WIZARD),
      premiumFeature: AI_PAGE_WIZARD,
      captions: {
        title: UPGRADE_REQUIRED,
        description: i18next.t(
          'Gain access to all AI-powered features by upgrading your subscription now!'
        ),
        paywallDescription: i18next.t(
          'Unpack the full power of Yola AI-powered features by upgrading your subscription now!'
        ),
        entitlements: ENTITLEMENTS,
        upgrade: UPGRADE_NOW,
      },
    },
    aiBlockGenerationQuota: {
      purchaseFlowType,
      onUpgrade: getInternalUpgradeCallback(AI_BLOCK_GENERATION_QUOTA),
      premiumFeature: AI_BLOCK_GENERATION_QUOTA,
      captions: {
        title: UPGRADE_REQUIRED,
        description: i18next.t(
          'Gain access to all AI-powered features by upgrading your subscription now!'
        ),
        paywallDescription: i18next.t(
          'Unpack the full power of Yola AI-powered features by upgrading your subscription now!'
        ),
        entitlements: ENTITLEMENTS,
        upgrade: UPGRADE_NOW,
      },
    },
    aiTextAssistantQuota: {
      purchaseFlowType,
      onUpgrade: getInternalUpgradeCallback(AI_TEXT_ASSISTANT_QUOTA),
      premiumFeature: AI_TEXT_ASSISTANT_QUOTA,
      captions: {
        title: UPGRADE_REQUIRED,
        description: i18next.t(
          'Gain access to all AI-powered features by upgrading your subscription now!'
        ),
        paywallDescription: i18next.t(
          'Unpack the full power of Yola AI-powered features by upgrading your subscription now!'
        ),
        entitlements: ENTITLEMENTS,
        upgrade: UPGRADE_NOW,
      },
    },
  };
};

export default getUpsellSettings;
