const calculateThumbnailSize = (originalImageSize, areaSize) => {
  const { width: originalWidth, height: originalHeight } = originalImageSize;
  const { width: areaWidth } = areaSize;
  const ratio = originalWidth / originalHeight;
  const width = Math.floor(areaWidth) * 2;
  const height = Math.floor(width / ratio);

  return {
    width,
    height,
  };
};

export default calculateThumbnailSize;
