import getSubmoduleConfig from '../../../helpers/get-submodule-config';
import { SUBMODULE_NAME } from '../constants';

const isHeader = (node) => {
  const { affectedBlocksSelector } = getSubmoduleConfig(SUBMODULE_NAME);
  if (!node || !node.id || !affectedBlocksSelector) return false;

  return node.matches(affectedBlocksSelector);
};

export default isHeader;
