import { useEffect, useCallback } from 'react';
import bowser from 'yola-bowser';
import { textFormatting, contentEditable } from '@yola/ws-sdk';

const isMobile = Boolean(bowser.mobile);

const useTextFormattingKeyHandler = (textFormattingOptions) => {
  const handleKeyDown = useCallback(
    (event) => {
      const { ctrlKey, code, metaKey, shiftKey } = event;

      const wrapper = textFormattingOptions.find(({ keyCode }) => keyCode === code);

      if (!wrapper) {
        return;
      }

      const { id, shiftKey: wrapperShiftKey } = wrapper;

      if (wrapperShiftKey && !shiftKey) {
        return;
      }

      if ((bowser.macOS && metaKey) || ((bowser.linux || bowser.windows) && ctrlKey)) {
        contentEditable.operations.addCheckpoint();
        textFormatting.operations.toggleWrapper(id);
      }
    },
    [textFormattingOptions]
  );

  useEffect(() => {
    textFormattingOptions.forEach(({ id, className, tagName }) => {
      if (!textFormatting.verifiers.isWrapperRegistered(id)) {
        textFormatting.helpers.wrapperMap.registerWrapper(id, className, tagName);
      }
    });

    if (!isMobile) {
      document.addEventListener('keydown', handleKeyDown, false);
    }

    return () => {
      if (!isMobile) {
        document.removeEventListener('keydown', handleKeyDown, false);
      }
    };
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);
};

export default useTextFormattingKeyHandler;
