import { i18next } from '@yola/ws-sdk';
import { CUSTOM_DOMAIN } from '../constants/upsell-types';

const getCaptionsForPaywall = (upsellType, upsells = {}, forcedUpsell = null) => {
  if (upsellType === CUSTOM_DOMAIN) {
    return {
      backButtonLabel: i18next.t('Back to editor'),
      title: i18next.t('Get your domain for free with annual subscription'),
      description: {
        first: i18next.t('To use a custom domain, please select a subscription plan'),
      },
    };
  }

  const targetUpsell = forcedUpsell
    ? upsells[forcedUpsell]
    : Object.values(upsells).find((upsellDetails) => upsellDetails?.premiumFeature === upsellType);

  const upsellDescription =
    targetUpsell?.captions?.paywallDescription || targetUpsell?.captions?.description;

  if (upsellDescription) {
    return {
      backButtonLabel: i18next.t('Back to editor'),
      description: {
        first: upsellDescription,
      },
    };
  }

  return {
    backButtonLabel: i18next.t('Back to editor'),
    description: {
      first: i18next.t('Select premium features that you need to build a professional website.'),
      second: i18next.t('Get visitors, sell products, and grow your business online'),
    },
  };
};

export default getCaptionsForPaywall;
