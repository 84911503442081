import React from 'react';
import { utils } from '@yola/ws-sdk';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Frame from './frame';

class Index extends React.Component {
  constructor(props) {
    super();
    this.state = {
      active: 0,
      frames: [props.src, null],
    };

    this.onFrameLoad = this.onFrameLoad.bind(this);
    this.renderFrames = this.renderFrames.bind(this);
  }

  componentDidUpdate(prevState) {
    const prevSrc = prevState.src;
    const { active, frames } = this.state;
    const { src } = this.props;

    if (src === prevSrc) {
      return;
    }

    const nextFrames = [active === 0 ? frames[0] : src, active === 1 ? frames[1] : src];

    // eslint-disable-next-line yola/react/no-did-update-set-state
    this.setState({
      freeze: active,
      active: 'all',
      frames: nextFrames,
    });
  }

  onFrameLoad(iframe, index) {
    const { frames } = this.state;

    const nextFrames = [index === 0 ? frames[0] : null, index === 1 ? frames[1] : null];

    clearTimeout(this.timer);
    const { transitionDelay, onLoad } = this.props;

    this.timer = setTimeout(() => {
      this.setState({
        active: index,
        activeHeight: iframe.offsetHeight,
        frames: nextFrames,
      });
      onLoad(iframe);
    }, transitionDelay);
  }

  renderFrames() {
    const { active, frames, freeze } = this.state;

    return frames.map((src, index) => {
      if (active !== index && active !== 'all') {
        return null;
      }

      const { onBeforeLoad, onDocumentFetch, onDocumentReady } = this.props;

      const id = `syncy-frame-instance-${index}`;
      const frameClasses = classNames({
        'ws-syncy-frame-window--active': active === index,
        'ws-syncy-frame-window--freeze': freeze === index,
      });

      return (
        <Frame
          id={id}
          className={frameClasses}
          key={id}
          src={src}
          onBeforeLoad={onBeforeLoad}
          onLoad={(iframe) => this.onFrameLoad(iframe, index)}
          onDocumentFetch={onDocumentFetch}
          onDocumentReady={onDocumentReady}
        />
      );
    });
  }

  render() {
    const { width, height } = this.props;
    const { activeHeight, active } = this.state;
    const frameHeight = active === 'all' ? activeHeight : height;

    return (
      <div className="ws-syncy-frame" style={{ width, height: frameHeight }}>
        {this.renderFrames()}
      </div>
    );
  }
}

Index.defaultProps = {
  width: 'auto',
  height: 'auto',
  transitionDelay: 0,
  onBeforeLoad: utils.noop,
  onLoad: utils.noop,
  onDocumentFetch: utils.noop,
  onDocumentReady: utils.noop,
};

Index.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  transitionDelay: PropTypes.number,
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  onBeforeLoad: PropTypes.func,
  onLoad: PropTypes.func,
  onDocumentFetch: PropTypes.func,
  onDocumentReady: PropTypes.func,
};

export default Index;
