import getUserData from '../selectors/user-data';

const isDeveloperMode = (state) => {
  const userData = getUserData(state);

  return (
    Boolean(userData.employee_id) || Boolean(window.devMode && userData.email.endsWith('@yola.com'))
  );
};

export default isDeveloperMode;
