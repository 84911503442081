import get from 'lodash.get';
import urlJoin from 'url-join';
import { selectors } from '@yola/subscription-manager-js';
import store from 'src/js/store';
import utils from 'yola-editor/src/js/router/utils';
import errorModule from 'yola-editor/src/js/modules/error';
import segment from 'src/js/modules/analytics/segment';
import config from 'src/js/modules/config';
import user from 'yola-editor/src/js/modules/user';
import site from 'yola-editor/src/js/modules/site';
import i18n from 'yola-editor/src/js/modules/i18n';
import getUpsellSettings from 'yola-editor/src/js/modules/upsell/accessors/get-upsell-settings';
import getPaymentURL from 'yola-editor/src/js/modules/upsell/selectors/payment-url';
import startB2CDomainPurchase from 'yola-editor/src/js/modules/upsell/actions/start-b2c-domain-purchase';
import startWLDomainPurchase from 'yola-editor/src/js/modules/upsell/actions/start-wl-domain-purchase';
import beforeUnloadListener from 'yola-editor/src/js/utils/before-unload-listener';
import { redirectToUrl } from 'yola-editor/src/js/router/utils/redirect-to-url';
import featureFlagsModule from 'yola-editor/src/js/modules/feature-flags/service-client';
import isFreeDomainAvailable from 'yola-editor/src/js/modules/upsell/helpers/is-free-domain-available';
import getCurrentPackageFeatureLimits from 'yola-editor/src/js/modules/features/accessors/get-current-package-feature-limits';
import publishingConstants from 'yola-editor/src/js/modules/publishing/constants';
import getMainMenuItems from './get-main-menu-items';
import getHelpMenuItemClickCallbacks from './get-help-menu-item-click-callbacks';
import getRequestBlock from '../helpers/get-request-block';
import getRequestWidget from '../helpers/get-request-widget';
import dialogs from '../../dialogs';
import getHelpMenuItems from './get-help-menu-items';
import { helpMenuUrls } from '../constants';
import onSwitchTemplateTrack from '../helpers/on-switch-template';

const { NETWORK_ONLINE } = errorModule.constants;
const { YOLA_PARTNER_PUBLISHING_TYPE } = publishingConstants;
const {
  constants: { triggerIds },
} = segment;

const getEditorSettings = async () => {
  const state = store.getState();
  const featureFlagsManager = featureFlagsModule.get();

  const advertisingUrl = config.selectors.getAdvertisingUrl(state);
  const analyticsUrl = config.selectors.getAnalyticsUrl(state);

  const paymentURL = getPaymentURL(state);
  const activeSubscriptions = selectors.getActiveSubscriptions(state);
  const allSubscriptionsList = selectors.getSubscriptionList(state);
  const onlineStoreUrl = config.selectors.getOnlineStoreUrl(state);
  const myyolaUrl = config.selectors.getMyyolaUrl(state);
  const partnerHomeUrl = user.selectors.getHomeUrl(state);
  const partnerDashboardUrl = user.selectors.getPartnerDashboardUrl(state);
  const userPreferences = user.selectors.getUserPreferences(state);
  const userData = user.selectors.getUserData(state);
  const availablePlatformComponents = user.selectors.getAvailablePlatformComponents(state);
  const siteURL = site.selectors.getSiteURL(state);
  const isTemplateSwitched = site.selectors.getTemplateSwitched(state);
  const locale = i18n.selectors.getLocale(state);
  const publishedSiteData = site.selectors.getPublishedSiteData(state);
  const publicApiUrl = config.selectors.getApiUrl(state);
  const partnerData = user.selectors.getPartnerData(state);
  const externalUpsellTypes = user.selectors.getExternalUpsellTypes(state);
  const partnerName = partnerData.name;
  const partnerId = partnerData.id;
  const { siteHeader, siteFooter, ...wsBranding } = partnerData.properties.wsBranding;
  const publishingType = user.selectors.getPublishingType(state);
  const supportUrl = user.selectors.getSupportUrl(state);
  const supportEmail = user.selectors.getSupportEmail(state);
  const contactSupportUrl = user.selectors.getContactSupportUrl(state);
  const termsOfUseUrl = user.selectors.getTermsOfUseUrl(state);
  const privacyPolicyUrl = user.selectors.getPrivacyPolicyUrl(state);
  const isAvailableRoadmapFromPartner = get(partnerData, 'properties.wsFeatures.publicRoadmap');
  const isWL = user.selectors.getWhiteLabelStatus(state);
  const hasSiteToUnpublish =
    publishedSiteData && (!isWL || publishingType === YOLA_PARTNER_PUBLISHING_TYPE);
  const limits = getCurrentPackageFeatureLimits();
  const htaccessSupported = user.selectors.getHtaccessSupported(state);
  const isCleanUrlsSupported =
    publishingType === YOLA_PARTNER_PUBLISHING_TYPE || Boolean(htaccessSupported);
  const { available: isAdvertisingAvailable } = limits.advertising;
  const { available: isOnlineStoreAvailable } = limits.onlineStore;
  const { available: isAnalyticsAvailable } = limits.analytics;
  const { id: siteId } = site.selectors.getSiteData(state);
  const { SESSION_EXPIRED } = errorModule.constants;
  const { SITE_ID_TO_SWITCH, TEMPLATE_BUILD_SLUG } = site.constants;
  const {
    refer_a_friend: isReferAFriendFeatureAvailable,
    branded_header: isBrandedHeaderEnabled,
    freedomain: isFreeDomainEnabled,
    nofollow_controls: isNofollowControlsEnabled,
    difm_latitude: isDifmLatitudeEnabled,
  } = await featureFlagsManager.request([
    'refer_a_friend',
    'branded_header',
    'freedomain',
    'nofollow_controls',
    'difm_latitude',
  ]);

  const hasAvailableFreeDomain = isFreeDomainAvailable(
    activeSubscriptions,
    allSubscriptionsList,
    isFreeDomainEnabled
  );
  const galleryLink = config.selectors.getGalleryLink(state);
  const templateBuildSlug = site.selectors.getSiteTemplateSlug(state);
  const galleryRedirectUrl = `${galleryLink}?${SITE_ID_TO_SWITCH}=${siteId}&${TEMPLATE_BUILD_SLUG}=${templateBuildSlug}`;
  const upsells = getUpsellSettings({
    state,
    dispatch: store.dispatch,
    isFreeDomainAvailable: hasAvailableFreeDomain,
  });
  const isDialogVisible = dialogs.verifiers.isVisible();
  const isNetworkOnline = errorModule.selectors.getNetworkStatus(state) === NETWORK_ONLINE;
  const featureFlags = featureFlagsManager.state;

  let redirectToAdvertising;
  let redirectToOnlineStore;
  let redirectToAnalytics;
  let redirectToDashboard;
  let publicRoadmapUrl;
  let requestBlock;
  let requestWidget;
  if (isAdvertisingAvailable) {
    redirectToAdvertising = () => {
      if (!isWL) {
        utils.redirectToUrl(urlJoin(analyticsUrl, '#advertising', siteId));
      } else {
        utils.redirectToUrl(advertisingUrl);
      }
    };
  }

  if (isOnlineStoreAvailable) {
    redirectToOnlineStore = () => {
      utils.redirectToUrl(onlineStoreUrl);
    };
  }

  if (isAnalyticsAvailable) {
    redirectToAnalytics = () => {
      utils.redirectToUrl(urlJoin(analyticsUrl, '#sites', siteId));
    };
  }

  if (availablePlatformComponents.includes(user.platformComponents.DASHBOARD)) {
    redirectToDashboard = () => {
      utils.redirectToUrl(myyolaUrl);
    };
  }

  if (isWL && partnerDashboardUrl) {
    redirectToDashboard = () => {
      utils.redirectToUrl(partnerDashboardUrl, { topWindow: true });
    };
  }

  if (isAvailableRoadmapFromPartner) {
    publicRoadmapUrl = config.selectors.getPublicRoadmapURL(state);
    requestBlock = getRequestBlock(publicRoadmapUrl);
    requestWidget = getRequestWidget(publicRoadmapUrl);
  }

  // In sdk we set default welcomeTourCompleted to 'True' if it doesnt exist.
  // This is done in order to make editor work correctly without yola-editor.
  // But it makes welcome tour to be never shown. So in order to make welcome tour shown when ws-editor
  // is in pair with yola-editor we set "default" falsy value for `welcomeTourCompleted` here

  if (userPreferences && !userPreferences.welcomeTourCompleted) {
    userPreferences.welcomeTourCompleted = false;
  }

  const isComplianceServiceMenuItemEnabled = externalUpsellTypes.includes(
    user.constants.externalUpsellTypes.DIFM_PRIVACY
  );
  const isOrderWebsiteMenuItemEnabled =
    isDifmLatitudeEnabled &&
    (!isWL || externalUpsellTypes.includes(user.constants.externalUpsellTypes.DIFM_SERVICE));
  const helpMenuItemClickCallbacks = getHelpMenuItemClickCallbacks(triggerIds.EDITOR_HEADER);

  const settings = {
    limits,
    ui: {
      isDialogVisible,
      isNetworkOnline,
    },
    mainMenu: getMainMenuItems({
      siteURL,
      partnerId,
      supportUrl,
      supportEmail,
      publicRoadmapUrl,
      isReferAFriendFeatureAvailable: !isWL && isReferAFriendFeatureAvailable,
      redirectToAnalytics,
      redirectToAdvertising,
      redirectToDashboard,
      redirectToWebsite() {
        utils.redirectToUrl(partnerHomeUrl, { topWindow: true });
      },
      upsells,
      partnerProperties: partnerData.properties,
      isFreeDomainAvailable: hasAvailableFreeDomain,
      isComplianceServiceMenuItemEnabled,
      isOrderWebsiteMenuItemEnabled,
    }),
    helpMenu: getHelpMenuItems({
      isWL,
      partnerId,
      contactSupportUrl,
      supportEmail,
      gettingStartedUrl: helpMenuUrls.GETTING_STARTED,
      helpCenterUrl: supportUrl,
      publicRoadmapUrl,
      termsOfUseUrl,
      privacyPolicyUrl,
      isComplianceServiceMenuItemEnabled,
      isOrderWebsiteMenuItemEnabled,
      helpMenuItemClickCallbacks,
    }),
    featureFlags,
    userData,
    userPreferences,
    branding: {
      ...wsBranding,
      siteHeader: isBrandedHeaderEnabled ? siteHeader : null,
      siteFooter: isBrandedHeaderEnabled ? null : siteFooter,
    },
    partnerData: {
      name: partnerName,
    },
    upsells,
    locale,
    requestBlock,
    requestWidget,
    onCriticalError({ errorMessage, errorInfo, networkErrorDetails }) {
      const error = errorModule.helpers.createEditorError({ errorMessage, networkErrorDetails });
      store.dispatch(
        errorModule.actions.setCriticalError({
          error,
          errorInfo,
          logSentry: false,
        })
      );
    },
    onResetSite() {
      store.dispatch(dialogs.actions.show(dialogs.dialogTypes.SITE_RESET));
    },
    onReloadEditor() {
      utils.reload();
    },
    onSessionExpired() {
      store.dispatch(
        errorModule.actions.setCriticalError({
          error: new Error(SESSION_EXPIRED),
          logSentry: false,
        })
      );
    },
    onUpdateSiteName({ siteName }) {
      store.dispatch(
        site.actions.updateSiteData({
          name: siteName,
        })
      );
    },
    updateUserPreference({ properties }) {
      const { property, value } = properties;
      store.dispatch(user.actions.updateUserPreference(property, value));
    },
    onDomainSelector({ triggerId }) {
      store.dispatch(startB2CDomainPurchase(triggerId));
    },
    onWLDomainSelector({ triggerId }) {
      store.dispatch(startWLDomainPurchase(triggerId));
    },
    onSwitchTemplate({ sendAnalytics, traits } = {}) {
      if (sendAnalytics) {
        onSwitchTemplateTrack({ templateBuildSlug, siteId, ...traits }).then(() => {
          redirectToUrl(galleryRedirectUrl);
        });
      } else {
        redirectToUrl(galleryRedirectUrl);
      }
    },
    beforeUnloadEvents: {
      start() {
        beforeUnloadListener.add();
      },
      end() {
        beforeUnloadListener.remove();
      },
    },
    requestFeatureFlags: async ({ flags }) => {
      const resolvedFlags = await featureFlagsManager.request(flags);
      return {
        flags: resolvedFlags,
      };
    },
    getFeatureFlagsState() {
      return featureFlagsManager.state;
    },
    siteURL,
    isTemplateSwitched,
    publicApiUrl,
    paymentURL,
    isCleanUrlsSupported,
    featureConfig: {
      textEditing: {
        nofollowEnabled: isNofollowControlsEnabled,
      },
    },
  };

  if (isOnlineStoreAvailable) {
    settings.onlinestore = {
      redirect: redirectToOnlineStore,
    };
  }

  if (hasSiteToUnpublish) {
    settings.onUnpublish = () => {
      store.dispatch(dialogs.actions.show(dialogs.dialogTypes.UNPUBLISH_CONFIRM_ACTION));
    };
  } else {
    settings.onUnpublish = null;
  }
  return settings;
};

export default getEditorSettings;
