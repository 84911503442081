import segment from 'src/js/modules/analytics/segment';
import { UPGRADE_BANNER_ID, DOMAIN_BANNER_ID } from '../constants';

const {
  trackers: { trackUpgradeTriggerClicked },
  constants: { triggerIds },
} = segment;

function getComputedBanners(banners) {
  if (!banners) return [];

  return banners.map((banner) => {
    switch (banner.id) {
      case UPGRADE_BANNER_ID:
        return {
          ...banner,
          onClick: () => {
            trackUpgradeTriggerClicked(triggerIds.MAIN_MENU);

            banner.onClick().catch(() => {
              // eslint-disable-next-line no-console
              console.log('Upgrade flow was canceled');
            });
          },
        };
      case DOMAIN_BANNER_ID:
        return {
          ...banner,
          onClick: () => {
            banner.onClick();
          },
        };
      default:
        return banner;
    }
  });
}

export default getComputedBanners;
