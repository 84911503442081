import { assets } from '@yola/ws-sdk';
import compute from './helpers/compute';
import isWsBlock from '../../../blocks/verifiers/is-ws-block';
import getElementBySelector from '../../helpers/get-element-by-selector';
import { BACKGROUND_IMAGE_ATTRIBUTE } from '../../constants/attributes';

const blockIsReplaced = async (config, payload, transaction) => {
  const { element } = payload;

  if (!element || !isWsBlock(element) || !element.hasAttribute(BACKGROUND_IMAGE_ATTRIBUTE)) {
    return [];
  }

  const { initialState: initialTransactionDOM } = transaction;
  const blockSelector = `[id="${element.id}"]`;
  const initialTargetBlock = initialTransactionDOM.querySelector(blockSelector);
  // We need to operate only with liveElement when
  // we work with ws-sitecomponents, to prevent skipping
  // of overlay design-automatization
  const targetBlock = getElementBySelector(blockSelector);

  if (!initialTargetBlock || !targetBlock) {
    return [];
  }

  const currentBackgroundImage = targetBlock.getAttribute(BACKGROUND_IMAGE_ATTRIBUTE);
  const initialBackgroundImage = initialTargetBlock.getAttribute(BACKGROUND_IMAGE_ATTRIBUTE);

  if (currentBackgroundImage === initialBackgroundImage) {
    return [];
  }

  const mediaSource = assets.helpers.addBaseHref(currentBackgroundImage);

  return compute(targetBlock, {
    mediaSource,
  });
};

export default blockIsReplaced;
