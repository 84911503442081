/* eslint-disable no-prototype-builtins */
function getObjectDifference(obj1, obj2) {
  const diff = {};

  // Check for keys in obj2 and compare with obj1
  Object.keys(obj2).forEach((key) => {
    if (obj2.hasOwnProperty(key)) {
      if (!obj1.hasOwnProperty(key)) {
        diff[key] = obj2[key];
      } else if (
        typeof obj2[key] === 'object' &&
        obj2[key] !== null &&
        typeof obj1[key] === 'object' &&
        obj1[key] !== null
      ) {
        const nestedDiff = getObjectDifference(obj1[key], obj2[key]);
        if (Object.keys(nestedDiff).length > 0) {
          diff[key] = nestedDiff;
        }
      } else if (obj2[key] !== obj1[key]) {
        diff[key] = obj2[key];
      }
    }
  });

  // Check for keys in obj1 that are not in obj2
  Object.keys(obj1).forEach((key) => {
    if (obj1.hasOwnProperty(key) && !obj2.hasOwnProperty(key)) {
      diff[key] = obj1[key];
    }
  });

  return diff;
}

export default getObjectDifference;
/* eslint-enable no-prototype-builtins */
