const sortBlocksByVariationIds = (blocks, blockId, selectedVariationId, variationId) =>
  [...blocks]
    .sort((first, second) => {
      if (first.id === second.id) return 0;
      if (first.id === blockId) return -1;
      if (second.id === blockId) return 1;
      return 0;
    })
    .sort((first, second) => {
      if (first.variationId === selectedVariationId) return -1;
      if (second.variationId === selectedVariationId) return 1;
      return 0;
    })
    .sort((first, second) => {
      if (first.variationId === variationId) return -1;
      if (second.variationId === variationId) return 1;
      return 0;
    });

export default sortBlocksByVariationIds;
