import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { designSystem, Render } from '@yola/ws-ui';
import { utils } from '@yola/ws-sdk';
import validateProhibitedHTMLTags from '../helpers/validate-prohibited-html-tags';
import validateWsAssets from '../helpers/validate-ws-assets';

const { CodeEditor, FieldStatus } = designSystem;

class CodeEditorField extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.validateEditorCode = this.validateEditorCode.bind(this);
    this.onAfterEditorConfigured = this.onAfterEditorConfigured.bind(this);
  }

  onAfterEditorConfigured(editor) {
    const {
      field: { name },
    } = this.props;

    // It is necessary for Formik correct work.
    editor.container.querySelector('textarea').setAttribute('name', name);
  }

  handleChange(value) {
    const {
      field,
      form: { setFieldValue },
      onChange,
    } = this.props;

    onChange(value);
    field.onChange(value);
    setFieldValue(field.name, value, false);
  }

  handleBlur(event) {
    const { form, onBlur } = this.props;

    form.handleBlur(event);
    onBlur(event);
  }

  validateEditorCode({ annotations = [], value, session }) {
    const { onEditorError } = this.props;
    let editorAnnotations = annotations;

    if (this.extraValidation) {
      return;
    }

    const wsAssetsErrors = validateWsAssets({ annotations: editorAnnotations, value, session });
    const prohibitedHTMLTagsErrors = validateProhibitedHTMLTags({
      annotations: editorAnnotations,
      value,
      session,
    });

    editorAnnotations = annotations.concat(wsAssetsErrors, prohibitedHTMLTagsErrors);

    this.extraValidation = true;
    session.setAnnotations(editorAnnotations);
    this.extraValidation = false;

    const error = editorAnnotations.find((annotation) => annotation.type === 'error');

    if (error) {
      const { text, row, column } = error;
      const errorText = `Line ${row + 1}, Column ${column}: ${text}`;
      onEditorError(errorText);
      return;
    }

    onEditorError('');
  }

  render() {
    const {
      field,
      field: { value, name },
      form: { errors },
      editorErrorRef,
      showError,
      errorText,
      ...rest
    } = this.props;

    const formErrorText = get(errors, name);
    const shouldShowError = Boolean((errorText && showError) || formErrorText);

    return (
      <React.Fragment>
        <CodeEditor
          {...rest}
          {...field}
          invalid={shouldShowError}
          name={name}
          value={value}
          onChange={this.handleChange}
          onValidate={this.validateEditorCode}
          onBlur={this.handleBlur}
          onAfterEditorConfigured={this.onAfterEditorConfigured}
        />
        <Render if={shouldShowError}>
          <div ref={editorErrorRef}>
            <FieldStatus isStatic text={errorText || formErrorText} />
          </div>
        </Render>
      </React.Fragment>
    );
  }
}
CodeEditorField.defaultProps = {
  onChange: utils.noop,
  onBlur: utils.noop,
  onEditorError: utils.noop,
  showError: false,
  errorText: '',
  editorErrorRef: { current: null },
};

CodeEditorField.propTypes = {
  onChange: PropTypes.func,
  onEditorError: PropTypes.func,
  onBlur: PropTypes.func,
  editorErrorRef: PropTypes.shape({
    current: PropTypes.element,
  }),
  showError: PropTypes.bool,
  // Following props are set by Formik. Added only to pass snitch check.
  field: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.string,
  }).isRequired,
  form: PropTypes.shape().isRequired,
  errorText: PropTypes.string,
};

export default CodeEditorField;
