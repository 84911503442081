/*
  Current util resolves issue on mobile devices. When dragSource element is  unmounted on beginDrag,
   user can't continue dragging element.
 */
let container;

const init = () => {
  container = document.createElement('div');
  container.classList.add('dnd-change');
  container.style.display = 'none';

  document.body.appendChild(container);
};

const add = (element) => {
  container.appendChild(element);
};

const clear = () => {
  container.innerHTML = '';
};

export default {
  init,
  add,
  clear,
};
