export const PANE_DEFAULT_SIZE = 0;
export const PANE_SIZE_WITH_LINING = 55;
export const PANE_TRIGGER_SIZE = 40;
export const PANE_DEFAULT_INDENT = 10;
export const PANE_ARROW_INDENT = 8;
export const DOCUMENT_MIN_WIDTH_ON_DESKTOP = 1280;
export const ELEMENT_MIN_HEIGHT = 150;
export const PANE_COLLAPSE_LIST_WIDTH = 260;
export const DROPDOWN_ITEM_HEIGHT = 32;
export const DROPDOWN_OFFSET = 8;
