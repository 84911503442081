const calcMaxItemsCountFitContainer = ({
  itemSize,
  itemsGap = 0,
  containerWidth,
  containerHeight,
}) => {
  if (!itemSize || !containerWidth || !containerHeight) return 0;

  const itemsInRow = Math.floor((containerWidth + itemsGap) / (itemSize + itemsGap));
  const itemsInColumn = Math.floor((containerHeight + itemsGap) / (itemSize + itemsGap));

  return itemsInRow * itemsInColumn;
};

export default calcMaxItemsCountFitContainer;
