import store from 'src/js/store';
import selectors from '../selectors';

const getHighlightedElements = () => {
  const state = store.getState();

  return selectors.getHighlightedElements(state);
};

export default getHighlightedElements;
