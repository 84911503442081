import constants from '../constants';
import getIconDialogCommonTraits from '../helpers/get-icon-dialog-common-traits';
import track from '../track';

const { events } = constants;

export default function trackSelectIconStyleFilterSelected({
  dialogId,
  targetElementId,
  styleTypeOld,
  styleTypeNew,
  tabId,
  premiumAvailable,
}) {
  const traits = {
    dialogId,
    styleTypeOld,
    styleTypeNew,
    tabId,
    premiumAvailable,
    ...getIconDialogCommonTraits(targetElementId),
  };

  track(events.SELECT_ICON_STYLE_FILTER_SELECTED, traits);
}
