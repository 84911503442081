import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import setupSteps from '../constants/setup-steps';
import WizardSetupSummaryItem from './wizard-setup-summary-item';
import TextParagraphCollapser from './text-paragraph-collapser';

const { Stack, Heading, Paragraph, Divider, NotificationMessage, Mark } = designSystem;

function WizardSetupSummarySection(props) {
  const {
    captions,
    summaryData,
    isDesktopView,
    onEditButtonClick,
    isBusinessTaxonomyFeatureEnabled,
  } = props;
  const { siteName, businessCategory, businessName, description, logo, contacts, socialLinks } =
    summaryData;

  const emptyState = <Paragraph appearance="low-emphasis">{captions.emptyState}</Paragraph>;

  return (
    <Stack gap="spacing-m">
      <Stack gap="spacing-3-xs">
        <Heading type="heading-3">{captions.sectionTitle}</Heading>
        <Paragraph>{captions.sectionDescription}</Paragraph>
      </Stack>
      <Stack gap="spacing-4-xs">
        <WizardSetupSummaryItem
          title={captions.siteNameTitle}
          buttonLabel={captions.editButtonLabel}
          isDesktopView={isDesktopView}
          onClick={() => onEditButtonClick(setupSteps.GENERAL)}
        >
          <Paragraph>{siteName}</Paragraph>
        </WizardSetupSummaryItem>
        <Divider />
        {isBusinessTaxonomyFeatureEnabled && (
          <Fragment>
            <WizardSetupSummaryItem
              title={captions.businessCategoryTitle}
              buttonLabel={captions.editButtonLabel}
              isDesktopView={isDesktopView}
              onClick={() => onEditButtonClick(setupSteps.GENERAL)}
            >
              <Paragraph>{businessCategory}</Paragraph>
            </WizardSetupSummaryItem>
            <Divider />
          </Fragment>
        )}
        {businessName && (
          <Fragment>
            <WizardSetupSummaryItem
              title={captions.businessNameTitle}
              buttonLabel={captions.editButtonLabel}
              isDesktopView={isDesktopView}
              onClick={() => onEditButtonClick(setupSteps.DESCRIPTION)}
            >
              <Paragraph>{businessName}</Paragraph>
            </WizardSetupSummaryItem>
            <Divider />
          </Fragment>
        )}
        <WizardSetupSummaryItem
          title={captions.descriptionTitle}
          buttonLabel={captions.editButtonLabel}
          isDesktopView={isDesktopView}
          onClick={() => onEditButtonClick(setupSteps.DESCRIPTION)}
        >
          <TextParagraphCollapser
            text={description}
            showTitle={captions.showDescriptionTitle}
            collapseTitle={captions.collapseDescriptionTitle}
          />
        </WizardSetupSummaryItem>
        <Divider />
        <WizardSetupSummaryItem
          title={captions.logoTitle}
          buttonLabel={captions.editButtonLabel}
          isDesktopView={isDesktopView}
          onClick={() => onEditButtonClick(setupSteps.LOGO)}
        >
          {(logo && <img src={logo} width="40" alt="" />) || emptyState}
        </WizardSetupSummaryItem>
        <Divider />
        <WizardSetupSummaryItem
          title={captions.contactsTitle}
          buttonLabel={captions.editButtonLabel}
          isDesktopView={isDesktopView}
          onClick={() => onEditButtonClick(setupSteps.CONTACTS)}
        >
          {(Boolean(contacts.length) && (
            <Stack gap="spacing-3-xs">
              {contacts.map((contact) => (
                <Paragraph key={contact}>{contact}</Paragraph>
              ))}
            </Stack>
          )) ||
            emptyState}
        </WizardSetupSummaryItem>
        <Divider />
        <WizardSetupSummaryItem
          title={captions.socialLinksTitle}
          buttonLabel={captions.editButtonLabel}
          isDesktopView={isDesktopView}
          onClick={() => onEditButtonClick(setupSteps.SOCIALS)}
        >
          {(Boolean(socialLinks.length) && (
            <Stack gap="spacing-3-xs">
              {socialLinks.map((link) => (
                <Paragraph key={link} appearance="accent" isClipped>
                  {link}
                </Paragraph>
              ))}
            </Stack>
          )) ||
            emptyState}
        </WizardSetupSummaryItem>
      </Stack>
      <NotificationMessage
        appearance="info"
        iconGlyph="info"
        title={captions.notificationTitle}
        description={
          <Paragraph size="small" appearance="medium-emphasis">
            {captions.notificationDescription}
            <Mark appearance="high-emphasis">{captions.notificationPath}</Mark>
          </Paragraph>
        }
      />
    </Stack>
  );
}

WizardSetupSummarySection.propTypes = {
  captions: PropTypes.shape().isRequired,
  summaryData: PropTypes.shape().isRequired,
  isDesktopView: PropTypes.bool.isRequired,
  onEditButtonClick: PropTypes.func.isRequired,
  isBusinessTaxonomyFeatureEnabled: PropTypes.bool.isRequired,
};

export default WizardSetupSummarySection;
