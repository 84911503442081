const optionsToAttributes = (optionsToUpdate, optionsFromNode, ignoredOptions = []) => {
  const attributesMap = optionsToUpdate.reduce((attributes, option) => {
    const { isSet, value, attribute, id, querySelector, userModified } = option;
    if (ignoredOptions.includes(id)) return attributes;
    if (!isSet) {
      return {
        ...attributes,
        [`${attribute}`]: {
          isSet,
          value,
          querySelector,
          userModified,
        },
      };
    }

    let compiledValue = value;
    const pairOption = optionsToUpdate.find(
      (item) => item.attribute === attribute && item.id !== id
    );
    const pairOptionFromNode = optionsFromNode.find(
      (item) => item.attribute === attribute && item.id !== id
    );

    if (pairOption) {
      compiledValue = `${value} ${pairOption.value}`;
    }

    if (pairOption) {
      compiledValue = `${value} ${pairOption.value}`;
    } else if (pairOptionFromNode) {
      compiledValue = `${value} ${pairOptionFromNode.value}`;
    }

    return {
      ...attributes,
      [`${attribute}`]: {
        isSet,
        value: compiledValue,
        querySelector,
        userModified,
      },
    };
  }, {});

  const keys = Object.keys(attributesMap);
  return keys.map((key) => ({
    ...attributesMap[key],
    attribute: key,
  }));
};

export default optionsToAttributes;
