import actionTypes from '../constants/action-types.js';

const setDropTargetElement = (elementId) => ({
  type: actionTypes.SET_TARGET_ELEMENT,
  payload: {
    elementId,
  },
});

export default setDropTargetElement;
