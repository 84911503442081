import { view } from '@yola/ws-sdk';
import bowser from 'yola-bowser';
import isElementWidget from '../../blocks/helpers/is-element-widget';
import isElementColumnInCustomBlock from '../../blocks/helpers/is-element-column-in-custom-block';
import isElementListItemInCustomBlock from '../../blocks/helpers/is-element-list-item-in-custom-block';

// This helper checks conditions to satisfy requirements from product epic:
// https://github.com/yola/production/issues/9349
function shouldRenderLabeledDragTrigger(elementId) {
  const isMobile = bowser.mobile;

  if (isMobile) return false;

  const element = view.accessors.getLiveElement(elementId);

  const matchedElementsVerifiers = [
    isElementWidget,
    isElementColumnInCustomBlock,
    isElementListItemInCustomBlock,
  ];

  return matchedElementsVerifiers.some((verifier) => verifier(element));
}

export default shouldRenderLabeledDragTrigger;
