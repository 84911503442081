import { blocks, clipboard } from '@yola/ws-sdk';

const canPasteBlock = (parentNode) => {
  const canBeInserted = blocks.verifiers.canBeInsertedInto(parentNode);
  const { element } = clipboard.accessors.getClipboardData();
  const blockId = blocks.accessors.getBlockIdByElement(element);

  return canBeInserted(blockId);
};

export default canPasteBlock;
