import isWsBlock from 'src/js/modules/blocks/verifiers/is-ws-block';
import hasBackgroundContainer from './has-background-container';
import commonConstants from '../constants/common';

const { IMAGE_PROGRESSIVE_ATTRIBUTE, NON_PROGRESSIVE_IMAGE_TYPES } = commonConstants;

const setProgressiveSrc = (node, imageSource) => {
  const imageFileType = imageSource.slice(imageSource.lastIndexOf('.'));

  if (isWsBlock(node) && hasBackgroundContainer(node)) {
    if (NON_PROGRESSIVE_IMAGE_TYPES.includes(imageFileType)) {
      node.setAttribute(IMAGE_PROGRESSIVE_ATTRIBUTE, 'false');
    } else {
      node.setAttribute(IMAGE_PROGRESSIVE_ATTRIBUTE, 'true');
    }
  }
};

export default setProgressiveSrc;
