import { PANE_TRIGGER_SIZE, PANE_DEFAULT_INDENT } from '../constants/sizes';
import { MAX_PANE_ITEMS } from '../constants/pane-items';
import deriveItemsWidth from './derive-items-width';

const getPaneWidth = (items) =>
  items.length > MAX_PANE_ITEMS
    ? deriveItemsWidth(items) + PANE_TRIGGER_SIZE + PANE_DEFAULT_INDENT
    : deriveItemsWidth(items) + PANE_DEFAULT_INDENT;

export default getPaneWidth;
