import React from 'react';
import PropTypes from 'prop-types';
import { textFormatting } from '@yola/ws-sdk';
import { designSystem } from '@yola/ws-ui';
import Trigger from 'src/js/modules/common/components/trigger';
import withFeatureFlags from 'yola-editor/src/js/modules/feature-flags/hoc/with-feature-flags';
import restoreRange from '../helpers/restore-range';
import getTextFormattingOptions from '../helpers/get-text-formatting-options';
import useTextFormattingKeyHandler from '../hooks/use-text-formatting-key-handler';

const { Icon } = designSystem;

const TextFormattingTrigger = ({ elementId, triggerId }) => {
  const selection = textFormatting.accessors.getAdvancedSelection();
  const textFormattingOptions = getTextFormattingOptions();

  const handleClick = () => {
    restoreRange(selection, elementId);
  };

  useTextFormattingKeyHandler(textFormattingOptions);

  return (
    <Trigger id={triggerId} onClick={handleClick}>
      <Icon glyph="bold" size="24" strokeWidth={1.3} />
    </Trigger>
  );
};

TextFormattingTrigger.propTypes = {
  elementId: PropTypes.string.isRequired,
  triggerId: PropTypes.string.isRequired,
};

// to ensure that feature flags is loaded, they are used in header function
export default withFeatureFlags(['decoration_text_formatting'])(TextFormattingTrigger);
