import auth from '../../auth';
import publishingServiceClient from '../../publishing-service-client/service-client';
import actions from '../actions';
import actionTypes from '../constants/action-types';

const publishedSiteData = (store) => (next) => (action) => {
  switch (action.type) {
    case actionTypes.FETCH_PUBLISHED_SITE: {
      const state = store.getState();
      const siteId = auth.selectors.getSiteId(state);
      const service = publishingServiceClient.get();
      service
        .listSites(siteId)
        .then(({ data: { results } }) => {
          if (results.length) {
            store.dispatch(actions.setPublishedSiteData(results[0]));
          }
          store.dispatch(actions.setPublishedDataLoadedStatus(true));
          next(action);
        })
        .catch(() => {
          store.dispatch(actions.setPublishedDataLoadedStatus(true));
          next(action);
        });
      break;
    }

    case actionTypes.FETCH_PUBLISHED_SITE_VHOSTS: {
      const { siteId } = action.payload;
      const service = publishingServiceClient.get();

      service
        .listVhosts(siteId)
        .then(({ data: { results } }) => {
          store.dispatch(actions.setPublishedSiteVhosts(results));
          next(action);
        })
        .catch(() => {
          store.dispatch(actions.setPublishedSiteVhosts(null));
          next(action);
        });
      break;
    }

    default:
      next(action);
  }
};

export default publishedSiteData;
