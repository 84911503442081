import get from 'lodash.get';
import { constants } from '@yola/subscription-manager-js';

const {
  hostingPackageFeatureNames: { AI_PAGE_WIZARD },
} = constants;

const aiPageWizardLimit = ({ targetPackage }) => ({
  available: get(targetPackage, ['features', AI_PAGE_WIZARD], false),
});

export default aiPageWizardLimit;
