import React from 'react';
import PropTypes from 'prop-types';
import { view } from '@yola/ws-sdk';
import { DropTarget } from '@yola/ws-ui';
import { connect } from 'react-redux';
import throttle from 'lodash.throttle';
import ViewDropArea from '../components/view-drop-area';
import selectors from '../selectors';
import DragTypes from '../constants/drag-types';
import hoverHandler from '../helpers/hover-handler';
import dropHandler from '../helpers/drop-handler';

class ViewDropAreaContainer extends React.Component {
  render() {
    const { dragSourceElementId, connectDropTarget } = this.props;
    const visible = dragSourceElementId !== null;

    return (
      <ViewDropArea
        visible={visible}
        connectDropTarget={connectDropTarget}
        ref={(area) => {
          this.area = area;
        }}
      />
    );
  }
}

ViewDropAreaContainer.propTypes = {
  dragSourceElementId: PropTypes.string,
  connectDropTarget: PropTypes.func,
};

ViewDropAreaContainer.defaultProps = {
  dragSourceElementId: null,
  connectDropTarget: null,
};

const viewDropTarget = {
  hover: throttle(hoverHandler, 100),
  drop: dropHandler,
};

function collectTarget(dNdConnect) {
  return {
    connectDropTarget: dNdConnect.dropTarget(),
  };
}

const mapStateToProps = (state) => ({
  dragSourceElementId: selectors.getDragSourceElement(state),
  dropTargetElementId: selectors.getDropTargetElement(state),
  adjacentPosition: selectors.getAdjacentPosition(state),
  scrollPosition: view.selectors.getScrollPosition(state),
});

export default connect(mapStateToProps)(
  DropTarget(DragTypes.VIEW_ELEMENT, viewDropTarget, collectTarget)(ViewDropAreaContainer)
);
