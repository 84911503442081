import { transactions } from '@yola/ws-sdk';
import getElementById from '../../helpers/get-element-by-id';
import getWhitespaceAutomationInstructions from './helpers/get-whitespace-automation-instructions';

const getDuplicatedBlock = (payload, isStatic) => {
  const { elementId } = payload;
  const targetBlock = getElementById(elementId, isStatic);

  if (!targetBlock) {
    return null;
  }

  return targetBlock.nextElementSibling;
};

const blockIsDuplicated = (config, payload, transaction) => {
  const { context } = transaction;
  const isStatic = context !== transactions.transactionTypes.LIVE_DOCUMENT;
  const duplicatedBlock = getDuplicatedBlock(payload, isStatic);

  if (!duplicatedBlock) {
    return [];
  }

  return getWhitespaceAutomationInstructions({
    prevSiblingNode: duplicatedBlock.previousElementSibling,
    targetNode: duplicatedBlock,
    isStatic,
  });
};

export default blockIsDuplicated;
