import { anodum } from '@yola/ws-sdk';
import constants from '../constants';

const { isOneOfTags } = anodum;
const { LIST_TYPE_NUMBER_TAG, LIST_TYPE_BULLET_TAG } = constants.listFormatting;

const getListsFromElements = (elements) =>
  elements.filter((item) => {
    if (isOneOfTags(item, [LIST_TYPE_NUMBER_TAG, LIST_TYPE_BULLET_TAG])) {
      return item.childNodes.length > 0;
    }
    return false;
  });

export default getListsFromElements;
