import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { designSystem, reactHookForm } from '@yola/ws-ui';

const { TextAreaField, FieldStatus } = designSystem;
const { useController } = reactHookForm;

const TextAreaWithLengthAnnotationField = ({
  control,
  name,
  rules,
  placeholder,
  statusText,
  maxLength,
}) => {
  const { field, fieldState } = useController({
    name,
    control,
    rules,
  });
  const lengthInfo = `${field.value.length}/${maxLength}`;

  const handleChange = useCallback(
    (value) => {
      field.onChange(value);
    },
    [field]
  );

  const { invalid, error } = fieldState;
  const fieldStatusText = invalid ? error?.message : statusText;
  const fieldStatusAppearance = invalid ? 'error' : 'default';
  const elementAfter = (
    <div className="ws-text-area-with-length-annotation-field__info">
      <FieldStatus text={fieldStatusText || ''} appearance={fieldStatusAppearance} />
      <FieldStatus text={lengthInfo} appearance={fieldStatusAppearance} align="right" />
    </div>
  );

  return (
    <div className="ws-text-area-with-length-annotation-field">
      <TextAreaField
        control={control}
        name={name}
        rules={rules}
        placeholder={placeholder}
        elementAfter={elementAfter}
        onChange={handleChange}
        resize
      />
    </div>
  );
};

TextAreaWithLengthAnnotationField.propTypes = {
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  rules: PropTypes.shape({}).isRequired,
  placeholder: PropTypes.string,
  statusText: PropTypes.string,
  maxLength: PropTypes.number.isRequired,
};

TextAreaWithLengthAnnotationField.defaultProps = {
  placeholder: '',
  statusText: '',
};

export default TextAreaWithLengthAnnotationField;
