import { site, integration } from '@yola/ws-sdk';
import get from 'lodash.get';

const siteNameUpdateHandler = (store) => (next) => (action) => {
  if (action.type === site.actionTypes.UPDATE_SETTINGS) {
    const state = store.getState();
    const oldSettings = site.selectors.getSettings(state);

    next(action);

    const newState = store.getState();
    const newSettings = site.selectors.getSettings(newState);

    if (oldSettings.siteName !== newSettings.siteName) {
      const integrationSettings = integration.selectors.getSettings(newState);
      const onUpdateSiteName = get(integrationSettings, 'onUpdateSiteName', Function.prototype);

      onUpdateSiteName({
        properties: {
          [integration.constants.SYMBOL_SETTING]: {
            disableBlocking: true,
          },
        },
        siteName: newSettings.siteName,
      });
    }

    return;
  }

  next(action);
};

export default siteNameUpdateHandler;
