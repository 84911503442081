import React, { useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import bowser from 'yola-bowser';
import { MultilingualIntroDialog, Modal, Button } from '@yola/ws-ui';
import PropTypes from 'prop-types';
import { i18next, site } from '@yola/ws-sdk';

const getCaptions = () => ({
  title: i18next.t('Create a multilingual site'),
  description: i18next.t('Speak to your visitors on their language'),
  list: [
    i18next.t('Manually translate your site into the languages you want'),
    i18next.t('Increase your reach by speaking to a wider audience'),
    i18next.t('Get freedom in creating special content for different groups of your audience'),
  ],
  buttonTitle: i18next.t('Get started'),
});

const MultilingualIntro = ({
  onClose,
  toContinue,
  onDialogMainAction,
  onDialogCancel,
  ...rest
}) => {
  const captions = useMemo(() => getCaptions(), []);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!site.verifiers.isMultilingualIntroShown()) {
      dispatch(site.actions.setSiteProperty('multilingualIntroShown', true));
    }
  }, [dispatch]);

  const handleClose = () => {
    onDialogCancel();
    onClose({ ...rest });
  };

  const handleNext = () => {
    onDialogMainAction();
    toContinue({ ...rest });
  };

  return (
    <Modal
      resizable={false}
      draggable={false}
      fullscreen={bowser.mobile}
      overlay="visible"
      centered
      width={574}
      height="auto"
      maxHeight={850}
      className="ws-vote-for-feature-modal"
      onOverlayClick={handleClose}
      handleCancel={handleClose}
    >
      <div className="ws-multilingual-intro-dialog-modal__close-button">
        <Button type="link" size="small" onClick={handleClose}>
          {i18next.t('Close')}
        </Button>
      </div>
      <MultilingualIntroDialog
        captions={captions}
        imageUrl="/images/multilingual-intro-dialog-icon.svg"
        onButtonClick={handleNext}
      />
    </Modal>
  );
};

MultilingualIntro.propTypes = {
  onClose: PropTypes.func.isRequired,
  toContinue: PropTypes.func.isRequired,
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

export default MultilingualIntro;
