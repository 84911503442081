import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { dialogs, i18next, integration } from '@yola/ws-sdk';
import config from 'src/js/modules/config';
import upsell from 'src/js/modules/upsell';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import segment from 'src/js/modules/analytics/segment';
import SelectItemModal from './select-item-modal';
import constants from '../constants';
import helpers from '../helpers';

const {
  track,
  trackers: { trackEvent },
  constants: { events },
  helpers: { getDefaultTraits },
} = segment;

const { common } = constants;
const { UPVOTE_INTERRUPTION_ID } = common;

const getCaptions = () => ({
  modalHeader: {
    title: i18next.t('Add new item'),
    closeButtonLabel: i18next.t('Close'),
  },
  inputCategories: {
    basic: i18next.t('Basic'),
    contacts: i18next.t('Contacts'),
  },
});

function SelectItemDialog(props) {
  const { elementId, fields, modalPosition: modalPositionProp, advancedFormState } = props;

  const [modalPosition, setModalPosition] = useState(modalPositionProp);

  const isFileAttachmetnFeatureAvailable = upsell.verifiers.isFeatureAvailable(
    upsell.features.FORM_ATTACHMENTS
  );
  const captions = getCaptions();

  const requestBlockData = useSelector(integration.selectors.getRequestBlock);
  const roadmapUrl = requestBlockData && requestBlockData.publicRoadmapUrl;
  const isB2C = useSelector(config.selectors.getIsB2C);
  const { formAttachments } = useSelector(integration.selectors.getUpsells);

  const fieldTypes = helpers.getFieldTypes();
  const { attachfile } = fieldTypes;
  const formContainsAttachFileField = fields.some(({ type }) => type === attachfile);

  const voteForFeature = () => {
    trackEvent(events.UPVOTE_INTERRUPTION_DISPLAYED, {
      interruptionId: UPVOTE_INTERRUPTION_ID,
    });

    dialogs.operations.show(dialogTypes.VOTE_FOR_THE_FEATURE, {
      onClose: () => {
        dialogs.operations.show(dialogTypes.CONTACT_FORM_SELECT_ITEM_DIALOG, {
          elementId,
          fields,
          modalPosition,
          advancedFormState,
        });
      },
      onVoteClick: () => {
        trackEvent(events.UPVOTE_INTERRUPTION_VOTE_BUTTON_CLICKED, {
          interruptionId: UPVOTE_INTERRUPTION_ID,
        });
      },
      roadmapUrl,
    });
  };

  const onUpgrade = (feature) => {
    feature.onUpgrade().catch(() => {
      dialogs.operations.show(dialogTypes.CONTACT_FORM_SELECT_ITEM_DIALOG, {
        elementId,
        modalPosition,
        fields,
        advancedFormState,
      });

      // eslint-disable-next-line no-console
      console.log('Upgrade flow was canceled');
    });
  };

  const onSelect = (item) => {
    if (item.type === attachfile && !isFileAttachmetnFeatureAvailable) {
      onUpgrade(formAttachments);
    } else if (item?.isVoteForFeatureEnabled) {
      voteForFeature();
    } else {
      dialogs.operations.show(dialogTypes.CONTACT_FORM_EDIT_FIELD_DIALOG, {
        elementId,
        fields,
        modalPosition,
        advancedFormState,
        ...item,
      });
    }
  };

  const onClose = () => {
    track(events.CONTACT_FORM_SELECT_ITEM_DIALOG_CLOSED, {
      ...getDefaultTraits(elementId),
      dialogId: dialogTypes.CONTACT_FORM_SELECT_ITEM_DIALOG,
    });

    dialogs.operations.show(dialogTypes.CONTACT_FORM_SETTINGS_DIALOG, {
      elementId,
      modalPosition,
      fields,
      advancedFormState,
    });
  };

  const updateModalPosition = (event, { x, y }) => {
    setModalPosition({ offsetX: x, offsetY: y });
  };

  return (
    <SelectItemModal
      elementId={elementId}
      onSelect={onSelect}
      onClose={onClose}
      modalPosition={modalPosition}
      updateModalPosition={updateModalPosition}
      captions={captions}
      isB2C={isB2C}
      formContainsAttachFileField={formContainsAttachFileField}
      isFileAttachmetnFeatureAvailable={isFileAttachmetnFeatureAvailable}
    />
  );
}

SelectItemDialog.propTypes = {
  elementId: PropTypes.string.isRequired,
  fields: PropTypes.array.isRequired,
  modalPosition: PropTypes.shape({
    offsetX: PropTypes.number.isRequired,
    offsetY: PropTypes.number.isRequired,
  }).isRequired,
  advancedFormState: PropTypes.shape(),
};

SelectItemDialog.defaultProps = {
  advancedFormState: null,
};

export default SelectItemDialog;
