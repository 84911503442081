import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { dialogs, hooks, site, i18next } from '@yola/ws-sdk';
import segment from '../../analytics/segment';
import ai from '../../ai';
import dialogTypes from '../../dialogs/constants/dialog-types';
import { saveSiteWithEffect } from '../../saving/helpers/save-site';
import WizardProgressDialog from '../components/wizard-progress-dialog';
import reloadSiteMeta from '../helpers/reload-site-meta';
import useProgressSteps from '../hooks/use-progress-steps';

const {
  constants: { events },
  trackers: { trackEvent },
} = segment;
const {
  constants: { errorTypes, recipeIds },
  helpers: { initiatePageReplacement },
} = ai;

const getCaptions = () => ({
  title: i18next.t('Generating your website...'),
  steps: [
    i18next.t('Creating homepage...'),
    i18next.t('Structuring...'),
    i18next.t('Designing layout...'),
    i18next.t('Text generation...'),
    i18next.t('Adding images...'),
    i18next.t('Finalizing...'),
  ],
});
const getSuccessDialogCaptions = () => ({
  title: i18next.t('Your website is ready'),
  description: i18next.t(
    'Dive in and add your unique touches to turn this draft into your perfect site'
  ),
  buttonLabel: i18next.t('Start editing'),
});

const ESTIMATED_GENERATION_TIME = 15 * 1000;

const SiteWizardProgressContainer = (props) => {
  const { pageId, recipeId, locale, onPageGenerated } = props;
  const dispatch = useDispatch();
  const captions = getCaptions();

  const { steps, stopProgressSteps } = useProgressSteps({
    initialSteps: captions.steps,
    estimatedTime: ESTIMATED_GENERATION_TIME,
  });

  const onAfterPageLoaded = async () => {
    stopProgressSteps();
    onPageGenerated();

    saveSiteWithEffect(() => {
      dialogs.operations.show(dialogTypes.AI_SUCCESS_DIALOG, {
        captions: getSuccessDialogCaptions(),
      });
    });
  };

  useEffect(() => {
    const handlePageReplacement = () => {
      saveSiteWithEffect(async () => {
        try {
          await initiatePageReplacement({
            pageId,
            locale,
            recipeId,
          });
          await reloadSiteMeta();
          dispatch(site.actions.setActivePage(pageId));

          hooks.operations.subscribe(hooks.availableHooks.ON_SITE_LOADED, onAfterPageLoaded, {
            once: true,
          });
        } catch (e) {
          const errorType = e.results?.code || errorTypes.UNKNOWN_REPLACE_PAGE;

          dialogs.operations.show(dialogTypes.AI_ERROR_DIALOG, {
            errorType,
            onRenew: () => {
              trackEvent(events.AI_WIZARD_TRY_AGAIN_TRIGGER_CLICKED);
              dialogs.operations.show(dialogTypes.SITE_WIZARD_PROGRESS_DIALOG, {
                pageId,
                recipeId,
                locale,
                onPageGenerated,
              });
            },
          });
        }
      });
    };

    handlePageReplacement();
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  return <WizardProgressDialog title={captions.title} steps={steps} />;
};

SiteWizardProgressContainer.propTypes = {
  pageId: PropTypes.string.isRequired,
  recipeId: PropTypes.oneOf([...Object.values(recipeIds), null]).isRequired,
  locale: PropTypes.string.isRequired,
  onPageGenerated: PropTypes.func,
};

SiteWizardProgressContainer.defaultProps = {
  onPageGenerated: Function.prototype,
};

export default SiteWizardProgressContainer;
