import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import { i18next } from '@yola/ws-sdk';
import IntegrationSettingsDialogScaffolding from './integration-settings-dialog-scaffolding';

const { InputGroup, FieldStatus } = designSystem;

const CookiebotSettingsDialog = ({ captions, defaultValue, onSubmit, onCancel, validate }) => {
  const [cookiebotId, setCookiebotId] = useState(defaultValue);
  const [validationError, setValidationError] = useState('');

  const handleChange = (value) => {
    setValidationError('');
    setCookiebotId(value);
  };

  const handleSubmit = () => {
    const trimmedValue = cookiebotId?.trim();
    const validationMessage = validate(trimmedValue);

    if (validationMessage) {
      setValidationError(validationMessage);
      return;
    }

    onSubmit(trimmedValue);
  };

  return (
    <IntegrationSettingsDialogScaffolding
      captions={{
        title: i18next.t('{integrationType} settings', { integrationType: captions.name }),
        controlName: captions.control.name,
        notes: captions.notes,
      }}
      onCancel={onCancel}
      onSubmit={handleSubmit}
    >
      <div>
        <InputGroup
          defaultValue={defaultValue}
          onChange={handleChange}
          invalid={!!validationError}
          value={cookiebotId}
        />
        {validationError && <FieldStatus appearance="error" text={validationError} />}
      </div>
    </IntegrationSettingsDialogScaffolding>
  );
};

CookiebotSettingsDialog.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  captions: PropTypes.shape({
    name: PropTypes.string.isRequired,
    control: PropTypes.shape().isRequired,
    notes: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  validate: PropTypes.func,
};

CookiebotSettingsDialog.defaultProps = {
  validate: () => null,
};

export default CookiebotSettingsDialog;
