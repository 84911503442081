const getRelativeOffsets = ({ width, mediaAspectRatio, containerHeight, position }) => {
  const imageScaledHeight = width / mediaAspectRatio;
  const imageRelevantHeight = (imageScaledHeight / containerHeight) * 100;
  const imageCenterPosition = imageRelevantHeight / 2;
  const y = position[1];
  const topOffset = y - imageCenterPosition;
  const bottomOffset = y + imageCenterPosition;

  return {
    top: Math.round(topOffset * 100) / 100,
    bottom: Math.round(bottomOffset * 100) / 100,
  };
};

export default getRelativeOffsets;
