import { textFormatting, contentEditable, globals, site } from '@yola/ws-sdk';
import { RGBColor, converters } from '@yola/yola-palette-generator';
import parseRGBColorString from './parse-rgb-color-string';
import getBlockSurface from './get-block-surface';
import { BLOCK_WITH_SURFACE_SELECTOR } from '../../blocks/constants/display-options';
import isCustomBlock from '../../blocks/verifiers/is-custom-block';

const { rgb2hsl } = converters;

const NATIVE_COLOR_EDITING_TAG_SELECTOR = 'font[color]';

const convertRGBtoHSL = (rgbColorString) => {
  const { r, g, b, a } = parseRGBColorString(rgbColorString);
  const targetRGBColor = new RGBColor(r / 255, g / 255, b / 255, a);
  const targetHSLObject = rgb2hsl(targetRGBColor);

  return targetHSLObject.toCSSValue();
};

function handleNativeColorEditing(liveElement) {
  const fontTags = liveElement.querySelectorAll(NATIVE_COLOR_EDITING_TAG_SELECTOR);
  const block = liveElement.closest(BLOCK_WITH_SURFACE_SELECTOR);

  // TODO: We would also need to extend this, when there will be a template driven text-formatting [epic: https://github.com/yola/production/issues/8586]
  //  to prevent this behaviour inside form labels etc. where we do not allow color options.
  if (
    (block && isCustomBlock(block)) || // TODO: remove this checkup when we close https://github.com/yola/ws-editor/issues/2538 and add support of color options to custom block
    globals.verifiers.isChildOfGlobal(liveElement) ||
    !fontTags ||
    !fontTags.length
  )
    return;

  const window = liveElement.ownerDocument.defaultView;
  const { wrappersTypes } = textFormatting.constants;
  const { transformElementTag } = contentEditable.helpers;

  const blockId = block.id;
  const surfaceAttribute = getBlockSurface(liveElement);
  let customStylesheet = textFormatting.helpers.getCustomTextColorsStylesheet();
  if (!customStylesheet) {
    customStylesheet = textFormatting.helpers.createCustomTextColorStylesheet();
  }

  fontTags.forEach((element) => {
    const { color } = window.getComputedStyle(element, wrappersTypes.customColor.tagName);
    const cssVariableName = textFormatting.helpers.getCssVariableNameByColor(
      convertRGBtoHSL(color)
    );

    let cssTextRule = textFormatting.helpers.getCssTextRule({
      customStylesheet,
      blockId,
      surfaceAttribute,
      name: cssVariableName,
    });

    if (!cssTextRule) {
      cssTextRule = textFormatting.helpers.createCssTextRule({
        name: cssVariableName,
        blockId,
        surfaceAttribute,
        customStylesheet,
        propertyValue: convertRGBtoHSL(color),
      });
    }

    const { textVariableName } = cssTextRule;

    const transformedElement = transformElementTag(element, wrappersTypes.customColor.tagName);
    transformedElement.removeAttribute('color');
    transformedElement.setAttribute('style', `color: var(${textVariableName});`);

    site.operations.addUsedCustomColor(textVariableName, site.accessors.getActivePageId());
  });

  // update stylesheet
  textFormatting.helpers.updateCustomColorStylesheetInGlobal();
}

export default handleNativeColorEditing;
