import React from 'react';
import PropTypes from 'prop-types';
import { AlertDialog } from '@yola/ws-ui';
import { redirectToUrl } from 'yola-editor/src/js/router/utils/redirect-to-url';
import routesMap from 'yola-editor/src/js/router/helpers/routes-map';

const CriticalErrorDialog = (props) => {
  const { handleCancel, onSubmit, onDismiss, onDialogMainAction, onDialogCancel } = props;

  const extendedProps = { ...props };

  if (handleCancel) {
    extendedProps.handleCancel = (...args) => {
      onDialogCancel();
      handleCancel(...args);
    };
  }

  if (onSubmit) {
    extendedProps.onSubmit = (...args) => {
      if (onDialogMainAction) onDialogMainAction();
      onSubmit(...args);
    };
  }

  if (onDismiss) {
    extendedProps.onDismiss = (...args) => {
      if (onDialogCancel) onDialogCancel();
      onDismiss(...args);
    };
  }

  return <AlertDialog {...extendedProps} />;
};

CriticalErrorDialog.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    dismiss: PropTypes.string,
  }),
  glyph: PropTypes.string,
  handleCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onDismiss: PropTypes.func,
  onDialogMainAction: PropTypes.func,
  onDialogCancel: PropTypes.func,
};

CriticalErrorDialog.defaultProps = {
  captions: {
    title: 'Something went wrong',
    description: 'Please reload the page',
    dismiss: 'Reload',
  },
  glyph: 'alert-circle',
  handleCancel: null,
  onSubmit: null,
  onDismiss: () => redirectToUrl(routesMap.index.url()),
  onDialogMainAction: null,
  onDialogCancel: null,
};

export default CriticalErrorDialog;
