import React, { useRef } from 'react';
import { designSystem } from '@yola/ws-ui';
import { dialogs, i18next, site, template } from '@yola/ws-sdk';
import segment from 'src/js/modules/analytics/segment';
import dialogTypes from '../../dialogs/constants/dialog-types';
import constants from '../constants';
import dialogCallSourceIds from '../../website-design/constant/dialog-call-source-ids';

const { EditorHeader } = designSystem;

const {
  track,
  constants: { events },
} = segment;

function WebsiteDesignTrigger() {
  const btnContainerRef = useRef();

  let dialogOffsetLeft = 0;
  let dialogOffsetTop = 0;

  if (btnContainerRef.current) {
    const { offsetLeft, offsetHeight } = btnContainerRef.current;

    dialogOffsetLeft = offsetLeft;
    dialogOffsetTop = offsetHeight;
  }

  const openWebsiteSettings = () => {
    track(events.WEBSITE_DESIGN_TRIGGER_CLICKED, {
      siteId: site.accessors.getSiteId(),
      templateBuildSlug: template.accessors.getBuildSlug(),
    });

    dialogs.operations.show(dialogTypes.WEBSITE_DESIGN, {
      onDialogCancel: dialogs.operations.hide,
      onDialogMainAction: dialogs.operations.hide,
      offsetX: dialogOffsetLeft,
      offsetY: dialogOffsetTop + constants.DIALOGS_TOP_INDENT,
      sourceId: dialogCallSourceIds.EDITOR_HEADER,
    });
  };

  return (
    <div ref={btnContainerRef}>
      <EditorHeader.NavItem onMouseDown={openWebsiteSettings} label={i18next.t('Design')} />
    </div>
  );
}

export default WebsiteDesignTrigger;
