const prepareReplacedData = (replacedData) => {
  const res = replacedData.reduce(
    (result, data) => {
      const { oldText, newText } = data;
      const { targetText, replacedText } = result;
      targetText.push(newText);
      replacedText.push(oldText);
      return {
        ...result,
        targetText,
        replacedText,
      };
    },
    {
      targetText: [],
      replacedText: [],
    }
  );
  const { targetText, replacedText } = res;
  const isTargetTextEmpty = !targetText.filter((text) => text).length;
  const isReplacedTextEmpty = !replacedText.filter((text) => text).length;
  return {
    ...res,
    targetText: !isTargetTextEmpty ? `["${targetText.join('","')}"]` : null,
    replacedText: !isReplacedTextEmpty ? `["${replacedText.join('","')}"]` : null,
  };
};

export default prepareReplacedData;
