import { useEffect, useRef } from 'react';
import bowser from 'yola-bowser';
import { utils } from '@yola/ws-sdk';

const isTouch = bowser.mobile || bowser.tablet || 'ontouchstart' in window;

const events = isTouch
  ? { start: 'touchstart', move: 'touchmove', end: 'touchend' }
  : { start: 'mousedown', move: 'mousemove', end: 'mouseup' };

function getClientCoords(event) {
  const source = isTouch ? event.changedTouches[0] : event;
  return [source.clientX, source.clientY];
}

function createDragHandler(callbacksRef) {
  return (startEvent) => {
    if (isTouch) {
      startEvent.preventDefault();
    }

    const [startX, startY] = getClientCoords(startEvent);

    callbacksRef.current.onStart(startEvent);

    function onMove(event) {
      const [x, y] = getClientCoords(event);
      callbacksRef.current.onMove([x - startX, y - startY], event);
    }

    function onEnd(event) {
      const [x, y] = getClientCoords(event);
      callbacksRef.current.onEnd([x - startX, y - startY]);

      document.removeEventListener(events.move, onMove);
      document.removeEventListener(events.end, onEnd);
    }

    document.addEventListener(events.move, onMove);
    document.addEventListener(events.end, onEnd);
  };
}

function useDrag(elementRefs, callbacks) {
  const elements = elementRefs
    .map((elementRef) => elementRef && elementRef.current)
    .filter((element) => element);
  const callbacksRef = useRef({});

  callbacksRef.current = callbacks;

  useEffect(() => {
    if (elements.length === 0) return utils.noop;

    const dragHandler = createDragHandler(callbacksRef);

    elements.forEach((element) => {
      element.addEventListener(events.start, dragHandler);
    });

    return () =>
      elements.forEach((element) => {
        element.removeEventListener(events.start, dragHandler);
      });
  }, [elements]);
}

export default useDrag;
