export default {
  id: 'column',
  priority: 2,
  matches(element, config) {
    return element.matches(config.querySelector) && element.closest(config.parentSelector);
  },
  settings: {
    parentSelector: 'ws-block[src*="/custom/"]',
    querySelector: 'ws-column',
  },
  tabs: [
    {
      id: 'appearance',
      accordionTitle: 'This column',
      options: [
        {
          id: 'border',
          type: 'toggle',
          title: 'Border',
          attribute: 'border',
        },
        {
          id: 'shadow',
          type: 'toggle',
          title: 'Shadow',
          attribute: 'shadow',
        },
      ],
    },
  ],
};
