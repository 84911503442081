import getParentElement from '../../common/helpers/get-parent-element';
import isInteractiveElement from '../verifiers/is-interactive-element';

const getClosestInteractiveElement = (sourceElement) => {
  let element = sourceElement;

  while (element) {
    if (isInteractiveElement(element)) {
      return element;
    }

    element = getParentElement(element);
  }

  return null;
};

export default getClosestInteractiveElement;
