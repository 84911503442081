const HTML_TAGS_REG_EXP = /(<([^>]+)>)/gi;
const SPECIAL_CHARACTERS_REG_EXP = /[.*+?^${}()|[\]\\]/g;

const atLeastOneWordStartsWithQuery = (queryString, targetString) => {
  const wordStartsWithQuery = new RegExp(`((?:)[\\s,-.:;"']|^)${queryString}`);
  return wordStartsWithQuery.test(targetString);
};

const isQueryMatchesInStrings = (queryString, targetStrings) =>
  targetStrings.filter(Boolean).some((string) => {
    const targetString = string.toLowerCase();
    return atLeastOneWordStartsWithQuery(queryString, targetString);
  });

const strippedHtmlString = (htmlString) => htmlString.replace(HTML_TAGS_REG_EXP, ' ').trim();

const prepareQueryString = (queryString) =>
  queryString && queryString.toLowerCase().trim().replace(SPECIAL_CHARACTERS_REG_EXP, '\\$&'); // escape special characters

const filterBlocksByQuery = (query, items) => {
  const queryString = prepareQueryString(query);

  return items.map((item) => {
    const { title, variationTitle, localizedCategory, localizedTags, html = '' } = item;
    const targetStrings = [
      // TODO: Cleanup `variationTitle` when all blocks will have readable title
      variationTitle || title,
      localizedCategory,
      localizedTags.join(' '),
      strippedHtmlString(html),
    ];
    const isQueryMatches = isQueryMatchesInStrings(queryString, targetStrings);

    return {
      ...item,
      disabled: !isQueryMatches,
    };
  });
};

export default filterBlocksByQuery;
