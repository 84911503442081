import bowser from 'yola-bowser';
import positionTypes from '../constants/position-types';
import directionTypes from '../constants/direction-types';

const getDropGuideSizeAndPosition = (
  element,
  elementFlowType,
  adjacentPosition,
  scrollPosition
) => {
  const { left, right, top, bottom, height, width } = element.getBoundingClientRect();

  let offsetX;
  let offsetY;
  let length;

  if (elementFlowType === directionTypes.VERTICAL) {
    offsetX = left;
    offsetY = adjacentPosition === positionTypes.AFTER_END ? bottom : top;

    length = width;
  } else {
    offsetX = adjacentPosition === positionTypes.AFTER_END ? right : left;
    offsetY = top;
    length = height;
  }

  if (!bowser.ios) {
    offsetY += scrollPosition;
  }

  return {
    offsetX,
    offsetY,
    length,
    width,
    height,
  };
};

export default getDropGuideSizeAndPosition;
