import React from 'react';

function EmptyPreview() {
  return (
    <svg viewBox="0 0 1920 2534" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="1920" height="2534" fill="white" />
      <rect width="1920" height="120" fill="white" />
      <rect opacity="0.8" x="375" y="44" width="100" height="32" rx="8" fill="#DADDE0" />
      <rect x="993" y="48" width="100" height="24" rx="8" fill="#B0B8BF" />
      <rect opacity="0.8" x="1125" y="48" width="100" height="24" rx="8" fill="#DADDE0" />
      <rect opacity="0.8" x="1257" y="48" width="100" height="24" rx="8" fill="#DADDE0" />
      <rect x="1405" y="32" width="140" height="56" rx="8" fill="#8E959E" />
      <rect opacity="0.4" x="1433" y="52" width="84" height="16" rx="8" fill="white" />
      <rect x="700" y="1113" width="520" height="308" rx="24" fill="#DADDE0" fillOpacity="0.3" />
      <rect x="836" y="1161" width="248" height="24" rx="8" fill="#B0B8BF" />
      <circle opacity="0.8" cx="960" cy="1267" r="50" fill="#DADDE0" />
      <g opacity="0.8">
        <path
          d="M960 1246.8L960 1286.8"
          stroke="white"
          strokeWidth="8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M980 1266.8L940 1266.8"
          stroke="white"
          strokeWidth="8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect opacity="0.8" x="890" y="1349" width="140" height="24" rx="8" fill="#DADDE0" />
      <rect width="1920" height="120" transform="translate(0 2414)" fill="#646C75" />
      <g opacity="0.3">
        <rect x="770" y="2462" width="100" height="24" rx="8" fill="white" />
        <rect x="910" y="2462" width="100" height="24" rx="8" fill="white" />
        <rect x="1050" y="2462" width="100" height="24" rx="8" fill="white" />
      </g>
    </svg>
  );
}

export default EmptyPreview;
