import React from 'react';
import { extensions, i18next, view } from '@yola/ws-sdk';
import { Icon } from '@yola/ws-ui';
import Trigger from 'src/js/modules/common/components/trigger';
import onlinestore from 'src/js/modules/onlinestore';
import constants from '../constants';

const ONLINE_STORE_SETTINGS_TRIGGER_ID = 'online-store-settings';

const control = {
  id: ONLINE_STORE_SETTINGS_TRIGGER_ID,
  trigger: (
    <Trigger id={ONLINE_STORE_SETTINGS_TRIGGER_ID}>
      <Icon size="20" glyph="cart" strokeWidth="1.3" />
    </Trigger>
  ),
  get tooltip() {
    return i18next.t('Online Store Settings');
  },
  priority: 100,
  onTriggerClick: () => {
    /**
     * ws-editor has logic which prevent leaving editor in case when user has
     * unsaved data. Online store extension makes redirect. If user has unsaved data,
     * redirect can be prevented, but `selectedElement` isn't cleared, as a result
     * we faced with following issue: https://github.com/yola/production/issues/9389
     * Because of `selectedElement` isn't reset and scroll position is changed -
     * control pane is being re-rendered and executes `onTriggerClick` on each scroll.
     * To prevent that, we have to reset selected element.
     * */
    view.operations.setSelectedElement(null);
    onlinestore.operations.redirectToStore();
  },
  matches(liveElement) {
    const settings = extensions.accessors.getExtensionSettings(constants.common.slug);

    return liveElement.matches(settings.querySelector);
  },
};

export default control;
