import createLogoSizeValidator from './create-logo-size-validator';
import createLogoTypeValidator from './create-logo-type-validator';
import createSocialLinkUrlValidator from './create-social-link-url-validator';
import createBusinessCategoryValidator from './create-business-category-validator';

export default {
  createLogoSizeValidator,
  createLogoTypeValidator,
  createSocialLinkUrlValidator,
  createBusinessCategoryValidator,
};
