import { template } from '@yola/ws-sdk';
import dashesToCapitalizeSentence from '../../utils/dashes-to-capitalize-sentence';

// Due to initial implementation of 'add widget' approach is matched with existing one for 'add block',
// this helper is just copied from `blocks` module almost as is. It isn't re-used for a better maintainability
// if we want to change this approach for widgets or need to tweak it somehow specifically.

function createWidgetsGroup(item, categoryName, categories = {}) {
  const categoryCaptionData = categories[categoryName] || {};
  const localizedCategoryName = template.accessors.getMetaTranslations(categoryName);

  const { title = dashesToCapitalizeSentence(localizedCategoryName), description = null } =
    categoryCaptionData;
  return {
    title,
    description,
    categoryName,
    items: [item],
  };
}

function groupWidgetsByCategory(items, categories = {}) {
  const itemsByCategories = {};
  const result = [];

  items.forEach((item) => {
    const { category } = item;
    const itemsGroup = itemsByCategories[category];

    if (itemsGroup) {
      itemsGroup.items.push(item);
    } else {
      const newItemsGroup = createWidgetsGroup(item, category, categories);

      itemsByCategories[category] = newItemsGroup;
      result.push(newItemsGroup);
    }
  });

  return result.map((group) => {
    const isEnabled = group.items.some((item) => !item.disabled);
    return {
      ...group,
      disabled: !isEnabled,
    };
  });
}

export default groupWidgetsByCategory;
