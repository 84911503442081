import React from 'react';
import PropTypes from 'prop-types';
import { Text, Button, Icon } from '@yola/ws-ui';

const RequestBlock = ({ captions, href }) => (
  <div className="ws-content-section">
    <div className="ws-add-content__request-block">
      <div className="ws-add-content__request-block-text">
        <Icon glyph="request" strokeWidth={1} />
        <Text>{captions.title}</Text>
      </div>
      <div>
        <Button type="primary" size="small" href={href} target="blank">
          {captions.button}
        </Button>
      </div>
    </div>
  </div>
);

RequestBlock.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
  }).isRequired,
  href: PropTypes.string.isRequired,
};

export default RequestBlock;
