import { hdrm } from '@yola/ws-sdk';
import getPageContainer from './get-page-container';

const getPageContainerOffset = (pageContainerSelector) => {
  const pageContainer = getPageContainer(pageContainerSelector);

  const { elPaddingTop, elBorderTop } = hdrm.accessors.getElementStyles(pageContainer);

  return elPaddingTop + elBorderTop;
};

export default getPageContainerOffset;
