const createYoutubeLink = (targetUrl) => {
  const url = new URL(targetUrl);
  let id;

  // Handle short links
  if (url.hostname.endsWith('youtu.be')) {
    id = url.pathname.slice(1);
  } else if (url.hostname.endsWith('youtube.com')) {
    id = url.searchParams.get('v');
  }

  if (id) {
    return `https://www.youtube.com/embed/${id}`;
  }

  return url;
};

export default createYoutubeLink;
