import { extensions } from '@yola/ws-sdk';
import LogoResizeTool from '../components/logo-resize-tool';
import config from '../constants/config';

export default {
  matches: (element) => {
    const settings = extensions.accessors.getExtensionSettings(config.slug);

    return element.matches(settings.resizeQuerySelector);
  },
  container: LogoResizeTool,
};
