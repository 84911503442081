import { converters } from '@yola/yola-palette-generator';
import { LIGHT_COLOR_TYPE } from '../constant/color-types';

const { hex2rgb, rgb2xyz, xyz2lab, lab2lch } = converters;

const colorValidation = (hex, colorType) => {
  const lch = lab2lch(xyz2lab(rgb2xyz(hex2rgb(hex))));
  if (colorType === LIGHT_COLOR_TYPE) {
    // Is light color
    return lch.lightness >= 62;
  }
  // Is dark color
  return lch.lightness <= 38;
};

export default colorValidation;
