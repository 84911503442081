import React from 'react';
import { i18next } from '@yola/ws-sdk';
import ProgressDialog from '../components/progress-dialog';

function UnpublishProgressDialogContainer(props) {
  const captions = {
    title: i18next.t('Unpublishing website...'),
    description: i18next.t('Your website will be unpublished soon'),
    cancel: i18next.t('Cancel'),
  };

  return <ProgressDialog captions={captions} cancelable={false} {...props} />;
}

export default UnpublishProgressDialogContainer;
