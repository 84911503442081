import { view, blocks } from '@yola/ws-sdk';
import getParentBlockByElement from 'src/js/modules/blocks/helpers/get-parent-block-by-element';
import trackEvent from './track-event';
import constants from '../constants';

const { events } = constants;

const trackTextElementSizeAdjusted = ({ elementId, fontSize }) => {
  const element = view.accessors.getLiveElement(elementId);
  const blockElement = getParentBlockByElement(element);

  trackEvent(events.TEXT_ELEMENT_SIZE_ADJUSTED, {
    blockId: blocks.accessors.getBlockIdByElement(blockElement),
    blockVariationId: blocks.accessors.getVariationIdByElement(blockElement),
    fontSize,
  });
};

export default trackTextElementSizeAdjusted;
