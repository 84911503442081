import matchBlockElement from '../../common/helpers/match-block-element';

const isNegativeIndent = (element) => {
  if (matchBlockElement(element)) {
    return true;
  }

  // workaround for legacy template
  if (element.matches('ws-nav')) {
    return true;
  }

  return false;
};

export default isNegativeIndent;
