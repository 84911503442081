import getPublishedSiteVhosts from 'yola-editor/src/js/modules/site/selectors/published-site-vhosts';
import getDomainHosting from 'src/js/modules/config/selectors/domain-hosting';

const hasSiteCustomDomain = (state) => {
  const vhosts = getPublishedSiteVhosts(state);
  const domainHosting = getDomainHosting(state);
  if (!vhosts || !domainHosting) return false;

  return vhosts.some((host) => !host.endsWith(`.${domainHosting}`));
};

export default hasSiteCustomDomain;
