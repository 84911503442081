import React from 'react';
import { designSystem } from '@yola/ws-ui';
import PropTypes, { oneOfType } from 'prop-types';

const { SearchableSelect } = designSystem;

const SearchableSelectField = (props) => {
  const {
    options,
    placeholder,
    defaultValue,
    field: { name },
    form: { setFieldValue, setFieldError, handleBlur },
    noResultsText,
  } = props;

  const handleChange = (option) => {
    setFieldValue(name, option.value, false);
  };

  const handleFocus = () => {
    setFieldError(name, '');
  };

  return (
    <SearchableSelect
      options={options}
      noResultsText={noResultsText}
      defaultValue={defaultValue}
      placeholder={placeholder}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
    />
  );
};

SearchableSelectField.propTypes = {
  options: PropTypes.shape({}).isRequired,
  defaultValue: oneOfType([PropTypes.shape({}), PropTypes.oneOf([null])]).isRequired,
  placeholder: PropTypes.string,
  field: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
    setFieldError: PropTypes.func,
    handleBlur: PropTypes.func,
  }).isRequired,
  noResultsText: PropTypes.string,
};

SearchableSelectField.defaultProps = {
  placeholder: '',
  noResultsText: '',
};

export default SearchableSelectField;
