const compileLivePreviewHeader = (globalStyles, liveAssets, inlineCSS) => {
  const { head, colorPalletes, customFonts, themeDependencies, buttonPreset, scripts, customCSS } =
    liveAssets;

  return `
      <!-- global styles: BEGIN -->
      ${globalStyles}
      <!-- global styles: END -->
      <!-- head: BEGIN -->
      ${head}
      <!-- head: END -->
      <!-- colorPalletes: BEGIN -->
      ${colorPalletes}
      <!-- colorPalletes: END -->
      <!-- customFonts: BEGIN -->
      ${customFonts}
      <!-- customFonts: END -->
      <!-- themeDependencies: BEGIN -->
      ${themeDependencies}
      <!-- themeDependencies: END -->
      <!-- buttonPreset: BEGIN -->
      ${buttonPreset}
      <!-- buttonPreset: END -->
      <!-- Custom CSS: BEGIN -->
      ${customCSS}
      <!-- Custom CSS: END -->
      <style>
        ${inlineCSS}
      </style>
      ${scripts}
    `;
};

export default compileLivePreviewHeader;
