import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import Trigger from 'src/js/modules/common/components/trigger';
import withSelection from 'src/js/modules/utils/with-selection';
import restoreRange from '../helpers/restore-range';
import useTextIndentControl from '../hooks/use-text-indent-control';

const { Icon } = designSystem;

function TextIndentTrigger({ elementId, triggerId }) {
  const { selection } = useTextIndentControl(elementId);

  const handleClick = () => {
    restoreRange(selection, elementId);
  };

  return (
    <Trigger id={triggerId} onClick={handleClick}>
      <Icon glyph="indent-increase" size="20" />
    </Trigger>
  );
}

TextIndentTrigger.propTypes = {
  elementId: PropTypes.string.isRequired,
  triggerId: PropTypes.string.isRequired,
};

export default withSelection(TextIndentTrigger);
