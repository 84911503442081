export default {
  ADD_BLOCK: '@WS_EDITOR/DIALOG/ADD_BLOCK',
  BLOCK_SETTINGS: '@WS_EDITOR/DIALOG/BLOCK_SETTINGS',
  BLOCK_LAYOUT_DIALOG: '@WS_EDITOR/DIALOG/BLOCK_LAYOUT_DIALOG',
  DELETE_PAGE: '@WS_EDITOR/DIALOG/PAGE_MANAGER/DELETE_PAGE',
  NEW_PAGE_DIALOG: '@WS_EDITOR/DIALOG/PAGE_MANAGER/NEW_PAGE_DIALOG',
  PAGE_MANAGER: '@WS_EDITOR/DIALOG/PAGE_MANAGER',
  PAGE_SETTINGS: '@WS_EDITOR/DIALOG/PAGE_SETTINGS',
  UNSAVED_WARNING: '@WS_EDITOR/DIALOG/UNSAVED_WARNING',
  SAVING_PROGRESS: '@WS_EDITOR/DIALOG/SAVING_PROGRESS',
  UPGRADE: '@WS_EDITOR/DIALOG/UPGRADE',
  UPGRADE_LINKS: '@WS_EDITOR/DIALOG/UPGRADE_LINKS',
  WEBSITE_SETTINGS: '@WS_EDITOR/DIALOG/WEBSITE_SETTINGS',
  ADD_LANGUAGE: '@WS_EDITOR/DIALOG/ADD_LANGUAGE',
  FILE_ERROR_DIALOG: '@WS_EDITOR/DIALOG/FILE_ERROR_DIALOG',
  UPLOAD_PROGRESS_DIALOG: '@WS_EDITOR/DIALOG/UPLOAD_PROGRESS_DIALOG',
  REACH_TECH_LIMIT_DIALOG: '@WS_EDITOR/DIALOG/REACH_TECH_LIMIT_DIALOG',
  WEBSITE_DESIGN: '@WS_EDITOR/DIALOG/WEBSITE_DESIGN',
  WEBSITE_DESIGN_COLOR_SETTINGS: '@WS_EDITOR/DIALOG/WEBSITE_DESIGN_COLOR_SETTINGS',
  WEBSITE_DESIGN_CUSTOM_PALETTE: '@WS_EDITOR/DIALOG/WEBSITE_DESIGN_CUSTOM_PALETTE',
  WELCOME_TOUR: '@WS_EDITOR/DIALOG/WELCOME_TOUR',
  MULTILINGUAL_TOUR: '@WS_EDITOR/DIALOG/MULTILINGUAL_TOUR',
  SAVE_ERROR_DIALOG: '@WS_EDITOR/DIALOG/SAVE_ERROR_DIALOG',
  CONFIRM_DIALOG: '@WS_EDITOR/DIALOG/CONFIRM_DIALOG',
  VOTE_FOR_THE_FEATURE: '@WS_EDITOR/DIALOG/VOTE_FOR_THE_FEATURE',
  MULTILINGUAL_INTRO: '@WS_EDITOR/DIALOG/MULTILINGUAL_INTRO',
  CREATE_MANUAL_SITE_BACKUP: '@WS_EDITOR/DIALOG/CREATE_MANUAL_SITE_BACKUP',
  RESTORE_SITE_BACKUP: '@WS_EDITOR/DIALOG/RESTORE_SITE_BACKUP',
  DELETE_SITE_BACKUP: '@WS_EDITOR/DIALOG/DELETE_SITE_BACKUP',
  SITE_BACKUP_ERROR: '@WS_EDITOR/DIALOG/SITE_BACKUP_ERROR',
  TRANSLATE_PAGE: '@WS_EDITOR/DIALOG/TRANSLATE_PAGE',
  SITE_PROGRESS_CHECKLIST: '@WS_EDITOR/DIALOG/SITE_PROGRESS_CHECKLIST',
  DEVELOPER_CONSOLE: '@WS_EDITOR/DIALOG/DEVELOPER_CONSOLE',
  UPDATE_REQUIRED: '@WS_EDITOR/DIALOG/UPDATE_REQUIRED',
  ADD_WIDGET: '@WS_EDITOR/DIALOG/ADD_WIDGET',
  FILE_TYPE_ERROR_DIALOG: '@WS_EDITOR/DIALOG/FILE_TYPE_ERROR_DIALOG',
  IMAGE_LOADING_DIALOG: '@WS_EDITOR/DIALOG/IMAGE_LOADING_DIALOG',
  FOOTER_NAVIGATION_SETTINGS_DIALOG:
    '@WS_EDITOR/WS_FOOTER_NAVIGATION/DIALOG/FOOTER_NAVIGATION_SETTINGS_DIALOG',
  FOOTER_NAVIGATION_ITEM_SETTING_DIALOG:
    '@WS_EDITOR/WS_FOOTER_NAVIGATION/DIALOG/FOOTER_NAVIGATION_ITEM_SETTING_DIALOG',
  UPLOAD_FILE_DIALOG: '@WS_EDITOR/DIALOG/UPLOAD_FILE_DIALOG',
  EXPORT_PROGRESS_DIALOG: '@WS_EDITOR/DIALOG/EXPORT_PROGRESS_DIALOG',
  EXPORT_INFORMATION_DIALOG: '@WS_EDITOR/DIALOG/EXPORT_INFORMATION_DIALOG',
  EXPORT_ERROR_DIALOG: '@WS_EDITOR/DIALOG/EXPORT_ERROR_DIALOG',
  SOCIAL_LINKS_SETTINGS_DIALOG: '@WS_EDITOR/WS_SOCIAL_LINKS/DIALOG/SOCIAL_LINKS_SETTINGS_DIALOG',
  SOCIAL_LINKS_ITEM_SETTINGS_DIALOG:
    '@WS_EDITOR/WS_SOCIAL_LINKS/DIALOG/SOCIAL_LINKS_ITEM_SETTINGS_DIALOG',
  SELECT_ICON: '@WS_ICON/DIALOG/SELECT_ICON',
  NAVIGATION_SETTINGS_DIALOG: '@WS_EDITOR/WS_NAVIGATION/DIALOG/NAVIGATION_SETTINGS_DIALOG',
  NAVIGATION_ITEM_SETTINGS_DIALOG: '@WS_NAVIGATION/DIALOG/NAVIGATION_ITEM_SETTINGS_DIALOG',
  UNSAVED_CHANGES_PROMPT: '@WS_EDITOR/DIALOG/UNSAVED_CHANGES_PROMPT',
  EMBED_HTML_SETTINGS_DIALOG: '@WS_EDITOR/WS_HTML_WIDGET/EMBED_HTML_SETTINGS_DIALOG',
  EDIT_LINK_DIALOG: '@WS_EDITOR/WS_LINK/DIALOG/EDIT_LINK_DIALOG',
  EDIT_IFRAME_DIALOG: '@WS_EDITOR/WS_IFRAME/EDIT_IFRAME',
  PHOTO_STOCK_DIALOG: '@WS_EDITOR/DIALOG/PHOTO_STOCK_DIALOG',
  PHOTO_LOAD_ERROR: '@WS_EDITOR/DIALOG/PHOTO_LOAD_ERROR',
  PHOTO_SIZE_ERROR: '@WS_EDITOR/DIALOG/PHOTO_SIZE_ERROR',
  LINK_FORMATTING_DIALOG: '@WS_EDITOR/WS_TEXT_FORMATTING/DIALOG/LINK',
  ADD_CUSTOM_COLOR_DIALOG: '@WS_EDITOR/WS_TEXT_FORMATTING/DIALOG/ADD_CUSTOM_COLOR',
  REDIRECT_DELETE_DIALOG: '@WS_EDITOR/DIALOG/REDIRECT_DELETE_DIALOG',
  REDIRECT_EDIT_DIALOG: '@WS_EDITOR/DIALOG/REDIRECT_EDIT_DIALOG',
  IMAGE_ALT_DIALOG: '@WS_IMAGE/DIALOG/IMAGE_ALT_DIALOG',
  CLICK_ACTION_DIALOG: '@WS_IMAGE/DIALOG/CLICK_ACTION_DIALOG',
  MEDIA_SETTINGS_DIALOG: '@WS_IMAGE/DIALOG/MEDIA_SETTINGS_DIALOG',
  GALLERY_DIALOG: '@WS_GALLERY/DIALOGS/CONTAINER',
  GALLERY_CONFIRMATION_DIALOG: '@WS_GALLERY/DIALOGS/CONFIRMATION',
  GALLERY_SAVE_CONFIRMATION_DIALOG: '@WS_GALLERY/DIALOGS/SAVE_CONFIRMATION',
  GALLERY_FILE_TYPE_INFO_DIALOG: '@WS_GALLERY/DIALOG/FILE_TYPE_INFO',
  GALLERY_FILE_QUANTITY_INFO_DIALOG: '@WS_GALLERY/DIALOG/FILE_QUANTITY_INFO',
  GALLERY_SAVING_PROGRESS_DIALOG: '@WS_GALLERY/DIALOG/SAVING_PROGRESS',
  GALLERY_SAVING_IMAGE_ERROR_DIALOG: '@WS_GALLERY/DIALOG/SAVING_ERROR',
  GALLERY_PHOTO_STOCK_SAVING_DIALOG: '@WS_GALLERY/DIALOG/PHOTO_STOCK_SAVING_DIALOG',
  CONNECT_COOKIEBOT_DIALOG: '@WS_EDITOR/CONNECT_COOKIEBOT_DIALOG',
  COOKIEBOT_SETTINGS_DIALOG: '@WS_EDITOR/COOKIEBOT_SETTINGS_DIALOG',
  CONNECT_GOOGLE_ANALYTICS_DIALOG: '@WS_EDITOR/CONNECT_GOOGLE_ANALYTICS_DIALOG',
  GOOGLE_ANALYTICS_SETTINGS_DIALOG: '@WS_EDITOR/GOOGLE_ANALYTICS_SETTINGS_DIALOG',
  CONNECT_GOOGLE_SEARCH_CONSOLE_DIALOG: '@WS_EDITOR/CONNECT_GOOGLE_SEARCH_CONSOLE_DIALOG',
  GOOGLE_SEARCH_CONSOLE_SETTINGS_DIALOG: '@WS_EDITOR/GOOGLE_SEARCH_CONSOLE_SETTINGS_DIALOG',
  CONTACT_FORM_DELETE_EMAIL_DIALOG: '@WS_CONTACT_FORM/DIALOG/DELETE_EMAIL_DIALOG',
  CONTACT_FORM_SELECT_ITEM_DIALOG: '@WS_CONTACT_FORM/DIALOG/SELECT_ITEM_DIALOG',
  CONTACT_FORM_SETTINGS_DIALOG: '@WS_CONTACT_FORM/DIALOG/SETTINGS_DIALOG',
  CONTACT_FORM_EDIT_FIELD_DIALOG: '@WS_CONTACT_FORM/DIALOG/EDIT_FIELD_DIALOG',
  CONTACT_FORM_EDIT_ITEM: '@WS_CONTACT_FORM/DIALOG/EDIT_ITEM',
  CONTACT_FORM_NEW_ITEM: '@WS_CONTACT_FORM/DIALOG/NEW_ITEM',
  ALERT_DIALOG: '@WS_EDITOR/ALERT_DIALOG',
  SLIDER_SETTINGS_DIALOG: '@WS_EDITOR/WS_SLIDER/DIALOG/SLIDER_SETTINGS_DIALOG',
  DIFM_PROMO_BANNER_DIALOG: '@WS_EDITOR/DIALOG/DIFM_PROMO_BANNER_DIALOG',
  DIFM_PROMO_FINISH_DIALOG: '@WS_EDITOR/DIALOG/DIFM_PROMO_FINISH_DIALOG',
  DIFM_PROMO_SERVICES_DIALOG: '@WS_EDITOR/DIALOG/DIFM_PROMO_SERVICES_DIALOG',
  DIFM_PROMO_SURVEY_DIALOG: '@WS_EDITOR/DIALOG/DIFM_PROMO_SURVEY_DIALOG',
  DIFM_PROMO_PACKAGES_DIALOG: '@WS_EDITOR/DIALOG/DIFM_PROMO_PACKAGES_DIALOG',
  BLOCK_REPLACEMENT_DIALOG: '@WS_EDITOR/DIALOG/BLOCK_REPLACEMENT_DIALOG',
  BLOCK_LIMIT_DIALOG: '@WS_EDITOR/DIALOG/BLOCK_LIMIT_DIALOG',
  COLOR_SCHEME_SETTINGS_DIALOG: '@WS_EDITOR/DIALOG/COLOR_SCHEME_SETTINGS_DIALOG',
  AI_ERROR_DIALOG: '@WS_EDITOR/DIALOG/AI_ERROR_DIALOG',
  AI_SUCCESS_DIALOG: '@WS_EDITOR/DIALOG/AI_SUCCESS_DIALOG',
  AI_ONBOARDING_DIALOG: '@WS_EDITOR/DIALOG/AI_ONBOARDING_DIALOG',
  SITE_WIZARD_INTRO_DIALOG: '@WS_EDITOR/DIALOG/SITE_WIZARD_INTRO_DIALOG',
  SITE_WIZARD_CONFIRM_EXIT_DIALOG: '@WS_EDITOR/DIALOG/SITE_WIZARD_CONFIRM_EXIT_DIALOG',
  SITE_WIZARD_PROGRESS_DIALOG: '@WS_EDITOR/DIALOG/SITE_WIZARD_PROGRESS_DIALOG',
  SITE_WIZARD_SETUP_DIALOG: '@WS_EDITOR/DIALOG/SITE_WIZARD_SETUP_DIALOG',
  PAGE_GENERATION_DIALOG: '@WS_EDITOR/DIALOG/PAGE_GENERATION_DIALOG',
  ADD_PAGE_DIALOG: '@WS_EDITOR/DIALOG/ADD_PAGE_DIALOG',
  AI_TEXT_ASSISTANT_ERROR_DIALOG: '@WS_EDITOR/DIALOG/AI_TEXT_ASSISTANT_ERROR_DIALOG',
  TERMS_OF_USE_DIALOG: '@WS_EDITOR/DIALOG/TERMS_OF_USE_DIALOG',
  PUBLISH_INTERRUPTION_DIALOG: '@WS_EDITOR/DIALOG/PUBLISH_INTERRUPTION_DIALOG',
  ELEMENT_SETTINGS_DIALOG: '@WS_EDITOR/DIALOG/ELEMENT_SETTINGS_DIALOG',
};
