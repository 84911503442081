import isWsBlock from 'src/js/modules/blocks/verifiers/is-ws-block';
import getBackgroundImageSource from 'src/js/modules/blocks/helpers/get-background-image-sourse';
import getUrlFromImageSource from './get-url-from-image-source';
import isImageContainer from './is-image-container';
import hasBackgroundContainer from './has-background-container';
import getUrlFromBackgroundContainer from './get-url-from-background-container';
import isGalleryItem from './is-gallery-item';

const getCurrentImageUrl = (imageNode) => {
  if (isGalleryItem(imageNode)) {
    return imageNode.getAttribute('origin-src');
  }

  if (isImageContainer(imageNode)) {
    return getUrlFromImageSource(imageNode.querySelector('img'));
  }

  if (hasBackgroundContainer(imageNode)) {
    return getUrlFromBackgroundContainer(imageNode);
  }

  if (isWsBlock(imageNode)) {
    return getBackgroundImageSource(imageNode);
  }

  return getUrlFromImageSource(imageNode);
};

export default getCurrentImageUrl;
