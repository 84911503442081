import axios from 'axios';
import { i18next, config } from '@yola/ws-sdk';
import editorConfig from 'src/js/modules/config';
import { DEFAULT_I18NEXT_NAMESPACE, DEFAULT_LOCALE } from '../constants';
import actionTypes from '../constants/action-types';
import actions from '../actions';
import editorActions from '../../editor/actions';
import setCookie from '../../../utils/set-cookie';
import getLocaleFromUrl from '../helpers/get-locale-from-url';

const translationsLoader = (store) => (next) => (action) => {
  switch (action.type) {
    case actionTypes.LOAD_TRANSLATIONS: {
      const { locale } = action.payload;
      const { language } = i18next;
      const isNotInitiaLoading = Boolean(language);

      if (language === locale) {
        next(action);
        break;
      }

      if (i18next.hasResourceBundle(locale, DEFAULT_I18NEXT_NAMESPACE)) {
        i18next.changeLanguage(locale).then(() => {
          store.dispatch(actions.setLocaLe(locale));
          document.documentElement.setAttribute('lang', locale);
          store.dispatch(editorActions.setSettings());
          next(action);
        });
        break;
      }

      axios
        .get(`/locale/${locale}.json`)
        .then(({ data }) => {
          i18next.addResourceBundle(locale, DEFAULT_I18NEXT_NAMESPACE, data, true, true);
          i18next.changeLanguage(locale).then(() => {
            store.dispatch(actions.setLocaLe(locale));
            document.documentElement.setAttribute('lang', locale);
            if (isNotInitiaLoading) {
              store.dispatch(editorActions.setSettings());
            }
            next(action);
          });
        })
        .catch(() => {
          store.dispatch(actions.loadTranslations(DEFAULT_LOCALE));
        });
      break;
    }
    case config.actionTypes.SET_SERVICE_CONFIG: {
      next(action);
      const state = store.getState();
      const localeCookieKey = editorConfig.selectors.getLocaleCookie(state);
      const supportedLocales = editorConfig.selectors.getBaseLanguageCodes(state);
      const cookieDomain = editorConfig.selectors.getCookieDomain(state);
      const locale = getLocaleFromUrl();
      if (localeCookieKey && locale && supportedLocales.includes(locale)) {
        setCookie(localeCookieKey, locale, cookieDomain);
      }
      break;
    }
    default:
      next(action);
  }
};

export default translationsLoader;
