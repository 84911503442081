const dashesToCapitalizeSentence = (phrase) => {
  if (typeof phrase !== 'string') throw new Error('Input phrase MUST be a String');
  return phrase
    .split('-')
    .map((word) => {
      const firstCharacter = word[0];

      return word.replace(firstCharacter, firstCharacter.toUpperCase());
    })
    .join(' ');
};

export default dashesToCapitalizeSentence;
