import React from 'react';
import PropTypes from 'prop-types';
import { utils, i18next, anodum } from '@yola/ws-sdk';
import { Text, Field, AceEditorField } from '@yola/ws-ui';

function extractIframeHtml(html) {
  const doc = anodum.parseDocumentFromString(html);
  const iframe = doc.body.querySelector('iframe');

  return iframe ? iframe.outerHTML : null;
}

class CodeForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shouldShowError: false,
      errorMessage: null,
    };

    this.onCodeChange = this.onCodeChange.bind(this);
    this.onCodeError = this.onCodeError.bind(this);
    this.validate = this.validate.bind(this);
  }

  onCodeChange() {
    this.setState({
      shouldShowError: false,
      errorMessage: null,
    });
  }

  /* eslint-disable class-methods-use-this */
  onCodePaste(pasteAction) {
    const iframeHtml = extractIframeHtml(pasteAction.text);

    if (iframeHtml) {
      // eslint-disable-next-line no-param-reassign
      pasteAction.text = iframeHtml;
    }
  }
  /* eslint-enable class-methods-use-this */

  onCodeError(error) {
    const { shouldValidate } = this.props;
    if (shouldValidate) {
      this.setState({ errorMessage: error });
    }
  }

  validateCode(code) {
    const { errorMessage: currentErrorMessage } = this.state;

    if (currentErrorMessage) {
      return currentErrorMessage;
    }

    if (!code) {
      return i18next.t('Invalid embed code. The empty embed code is not allowed.');
    }

    const doc = anodum.parseDocumentFromString(code);

    const containsIframesOnly = [...doc.body.childNodes].every(
      (node) => anodum.isElementNode(node) && anodum.isTag(node, 'iframe')
    );

    if (!containsIframesOnly) {
      return i18next.t('Invalid embed code. Any non-iframe html is not allowed.');
    }

    const iframes = doc.querySelectorAll('iframe');

    if (iframes.length > 1) {
      return i18next.t('Invalid embed code. Multiple iframe tags are not allowed.');
    }

    const [iframe] = iframes;
    const iframeSrc = iframe.getAttribute('src');

    if (!iframeSrc || !utils.validation.isURL(iframeSrc)) {
      return i18next.t('Invalid embed code. Iframe source is not specified.');
    }

    return null;
  }

  validate(value) {
    const { shouldValidate } = this.props;
    if (!shouldValidate) return null;
    const codeError = this.validateCode(value);

    if (codeError) {
      this.setState({
        shouldShowError: true,
        errorMessage: codeError,
      });
    }

    return codeError;
  }

  render() {
    const { shouldShowError, errorMessage } = this.state;

    return (
      <React.Fragment>
        <div className="ws-modal__block">
          <Text type="heading-6" theme="grey">
            {i18next.t('Embed Code')}
          </Text>
          <Field
            name="embedCode"
            component={AceEditorField}
            validate={this.validate}
            selected
            mode="html"
            theme="chrome"
            style={{
              margin: '8px 0',
              height: '170px',
            }}
            showError={shouldShowError}
            errorText={errorMessage}
            onChange={this.onCodeChange}
            onEditorError={this.onCodeError}
            onPaste={this.onCodePaste}
          />
          <Text type="annotation" theme="grey">
            {i18next.t(
              'Paste the code of your third-party content here. To embed your content via URL, select Embed URL.'
            )}
          </Text>
        </div>
      </React.Fragment>
    );
  }
}

CodeForm.propTypes = {
  shouldValidate: PropTypes.bool,
};

CodeForm.defaultProps = {
  shouldValidate: true,
};

export default CodeForm;
