import React, { useState, useEffect } from 'react';
import { i18next, i18n, dialogs, integration } from '@yola/ws-sdk';
import { selectors } from '@yola/subscription-manager-js';
import { useDispatch, useSelector } from 'react-redux';
import yolaEditorDialogs from 'yola-editor/src/js/modules/dialogs';
import PublishInterruptionDialog from '../components/publish-interruption-dialog';
import useMatchBreakpoint from '../../utils/custom-hooks/use-match-breakpoint';
import keyCodes from '../../common/constants/key-codes';
import publishInterruptionTypes from '../constants/publish-interruption-types';
import getPublishInterruptionOptions from '../helpers/get-publish-interruption-options';
import segment from '../../analytics/segment';
import dialogTypes from '../../dialogs/constants/dialog-types';
import { saveSiteWithEffect } from '../../saving/helpers/save-site';
import previewMode from '../../preview-mode';

const {
  trackers: { trackEvent },
  constants: { events, triggerIds },
} = segment;

const getCaptions = () => ({
  title: i18next.t('Publish your site to show it to the world!'),
  buttonLabel: i18next.t('Continue'),
  closeButtonLabel: i18next.t('Back'),
});
const DESKTOP_BREAKPOINT = '992px';
const TABLET_BREAKPOINT = '576px';
const DESKTOP_MODAL_WIDTH = 814;
const MOBILE_MODAL_WIDTH = 500;
const DEFAULT_LANGUAGE_CODE = 'en';
const LOCALIZED_IMAGES = ['en', 'cs', 'de', 'es', 'fr', 'it', 'pt-br', 'sk', 'sv'];

const PublishInterruptionDialogContainer = () => {
  const [selectedOption, setSelectedOption] = useState(publishInterruptionTypes.PUBLISH);

  const dispatch = useDispatch();
  const packageType = useSelector(selectors.getCurrentPackageType);
  const activeHostingSubscription = useSelector(selectors.getActiveHostingSubscription);
  const upsells = useSelector(integration.selectors.getUpsells);

  const isDesktopView = useMatchBreakpoint(DESKTOP_BREAKPOINT);
  const isTabletView = useMatchBreakpoint(TABLET_BREAKPOINT);

  const captions = getCaptions();
  const options = getPublishInterruptionOptions();
  const locale = i18n.accessors.getLocale();
  const languageCode = LOCALIZED_IMAGES[locale] || DEFAULT_LANGUAGE_CODE;
  const modalWidth = isDesktopView ? DESKTOP_MODAL_WIDTH : MOBILE_MODAL_WIDTH;
  const traits = { activeHostingSubscription: packageType };

  const onSubmit = () => {
    if (selectedOption === publishInterruptionTypes.PUBLISH) {
      const showPublishInterruption = () => {
        dispatch(dialogs.actions.show(dialogTypes.PUBLISH_INTERRUPTION_DIALOG));
      };

      if (!activeHostingSubscription) {
        upsells.default
          .onUpgrade({
            domainSelector: {
              triggerId: triggerIds.PUBLISH_INTERRUPTION,
              withFreeSubdomain: true,
              withPurchasedDomains: true,
              isPublishOnSuccess: true,
            },
          })
          .then(Function.prototype, showPublishInterruption);
      } else {
        dispatch(
          yolaEditorDialogs.actions.show(yolaEditorDialogs.dialogTypes.B2C_DOMAIN_PURCHASE, {
            triggerId: triggerIds.PUBLISH_INTERRUPTION,
            withFreeSubdomain: true,
            withPurchasedDomains: true,
            isPublishOnSuccess: true,
            captionsOverrides: {
              closeButton: captions.closeButtonLabel,
            },
            onClose: showPublishInterruption,
          })
        );
      }

      dialogs.operations.hide();
    }

    if (selectedOption === publishInterruptionTypes.PREVIEW) {
      saveSiteWithEffect(() => {
        dispatch(previewMode.thunks.generateSitePreview());
      });

      dialogs.operations.hide();
    }
  };

  const onClose = () => {
    trackEvent(events.PUBLISH_INTERRUPTION_CLOSED, traits);
    dialogs.operations.hide();
  };

  const onClick = (id) => {
    setSelectedOption(id);

    if (id === publishInterruptionTypes.PUBLISH) {
      trackEvent(events.PUBLISH_INTERRUPTION_CUSTOM_DOMAIN_CLICKED, traits);
    }

    if (id === publishInterruptionTypes.PREVIEW) {
      trackEvent(events.PUBLISH_INTERRUPTION_PREVIEW_CLICKED, traits);
    }
  };

  const onKeyDown = (e) => {
    if (e.keyCode === keyCodes.SPACE || e.keyCode === keyCodes.ENTER) {
      e.preventDefault();
      onClick(e.target.id);
    }
  };

  useEffect(() => {
    trackEvent(events.PUBLISH_INTERRUPTION_DISPLAYED, traits);
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  return (
    <PublishInterruptionDialog
      selectedOption={selectedOption}
      isTabletView={isTabletView}
      modalWidth={modalWidth}
      captions={captions}
      options={options}
      languageCode={languageCode}
      desktopBreakpoint={DESKTOP_BREAKPOINT}
      onSubmit={onSubmit}
      onClose={onClose}
      onClick={onClick}
      onKeyDown={onKeyDown}
    />
  );
};

export default PublishInterruptionDialogContainer;
