import { i18next } from '@yola/ws-sdk';

const getNewPageModalCaptions = (isCopiedPage = false, isGenarateWithAiAvailable = false) => {
  const description = isCopiedPage
    ? i18next.t('Name the copy of your page')
    : i18next.t('Name your page before saving');
  return {
    title: i18next.t('Add page'),
    description,
    checkboxLabel: i18next.t('Display in navigation'),
    submit: i18next.t('Submit'),
    cancel: i18next.t('Cancel'),
    textInputLabel: i18next.t('Page name'),
    selectLabel: i18next.t('Page template'),
    ...(isGenarateWithAiAvailable && { generateWithAiLabel: i18next.t('Generate page with AI') }),
  };
};

export default getNewPageModalCaptions;
