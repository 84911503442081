import linkControl from './link-control';
import listBulletControl from './list-bullet-control';
import listNumberControl from './list-number-control';
import elementTypeControl from './element-type-control';
import clearControl from './clear-control';
import alignControl from './align-control';
import textColorControl from './text-color-control';
import insertImageControl from './insert-image-control';
import fontSizeControl from './font-size-control';
import aiWriteTextControl from './ai-write-text-control';
import aiRewriteTextControl from './ai-rewrite-text-control';
import aiRewriteCollapsedTextControl from './ai-rewrite-collapsed-text-control';
import textFormattingControl from './text-formatting-control';
import textIndentControl from './text-indent-control';

export default {
  linkControl,
  listBulletControl,
  listNumberControl,
  elementTypeControl,
  clearControl,
  alignControl,
  textColorControl,
  insertImageControl,
  fontSizeControl,
  aiWriteTextControl,
  aiRewriteTextControl,
  aiRewriteCollapsedTextControl,
  textFormattingControl,
  textIndentControl,
};
