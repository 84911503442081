import { dialogs, site, siteLocales, integration } from '@yola/ws-sdk';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import user from 'yola-editor/src/js/modules/user';
import techLimitCaptions from '../constants/tech-limits-captions';
import tabIdentifiers from '../../website-settings/constants/tab-identifiers';

const handleTechLimit = (store, next) => {
  const { captions, premiumFeature } = techLimitCaptions.pages;
  store.dispatch(
    dialogs.actions.show(dialogTypes.REACH_TECH_LIMIT_DIALOG, {
      premiumFeature,
      captions,
      onDismiss: () => {
        next(dialogs.actions.hide());
      },
    })
  );
};

const pageLimitInterrupter = (store) => (next) => (action) => {
  if (action.type === dialogs.actionTypes.SHOW_DIALOG) {
    const state = store.getState();
    const currentAmountOfPages = site.selectors.getPages(state).length;
    const limits = integration.selectors.getLimits(state);
    const upsells = integration.selectors.getUpsells(state);
    const availablePlatformComponents = user.selectors.getAvailablePlatformComponents(state);

    switch (action.payload.type) {
      case dialogTypes.ADD_LANGUAGE: {
        const { locales, formData, showPromptForce } = action.payload.modalProps;
        const currentLocales = siteLocales.selectors.getSiteLocales(state);
        const pages = site.selectors.getPages(state);
        const mainLocale = siteLocales.selectors.getMainLocale(state);

        const currentPagesState = pages.filter((page) => locales.includes(page.locale));
        const mainLocalePages = pages.filter((page) => page.locale === mainLocale);
        const localesToAdd = locales.filter((locale) => !currentLocales.includes(locale));

        const finalAmountOfPages =
          currentPagesState.length + mainLocalePages.length * localesToAdd.length;

        if (finalAmountOfPages >= limits.pages.techLimitAmount) {
          handleTechLimit(store, next);
          return;
        }

        if (finalAmountOfPages >= limits.pages.amount && upsells.pagesCount) {
          const { captions, premiumFeature, onUpgrade, purchaseFlowType } = upsells.pagesCount;
          const onUpgradeResolve = () => next(action);
          const onUpgradeReject = () => {
            dialogs.operations.show(dialogTypes.WEBSITE_SETTINGS, {
              activeTabId: tabIdentifiers.MULTILINGUAL,
              formData,
              showPromptForce,
            });
          };

          // b2c & reseller platform upgrade
          if (availablePlatformComponents.includes(user.platformComponents.PAYMENTS)) {
            onUpgrade().then(onUpgradeResolve, onUpgradeReject);
            return;
          }

          store.dispatch(
            dialogs.actions.show(dialogTypes.UPGRADE, {
              purchaseFlowType,
              premiumFeature,
              captions,
              onUpgrade,
              onUpgradeResolve,
              onUpgradeReject,
            })
          );
          return;
        }
        break;
      }

      case dialogTypes.ADD_PAGE_DIALOG:
      case dialogTypes.NEW_PAGE_DIALOG:
      case dialogTypes.TRANSLATE_PAGE: {
        if (currentAmountOfPages >= limits.pages.techLimitAmount) {
          handleTechLimit(store, next);
          return;
        }

        if (currentAmountOfPages >= limits.pages.amount && upsells.pagesCount) {
          const { captions, premiumFeature, purchaseFlowType } = upsells.pagesCount;
          const details = {
            count: currentAmountOfPages,
          };
          const onUpgrade = () => upsells.pagesCount.onUpgrade(details);
          const onUpgradeResolve = () => next(action);
          const onUpgradeReject = () => {
            // eslint-disable-next-line no-console
            console.log('Upgrade flow was canceled');
          };

          // b2c & reseller platform upgrade
          if (availablePlatformComponents.includes(user.platformComponents.PAYMENTS)) {
            onUpgrade().then(onUpgradeResolve, onUpgradeReject);
            return;
          }

          store.dispatch(
            dialogs.actions.show(dialogTypes.UPGRADE, {
              purchaseFlowType,
              premiumFeature,
              captions,
              onUpgrade,
              onUpgradeResolve,
            })
          );
          return;
        }
        break;
      }
      default:
        next(action);
    }
  }
  next(action);
};

export default pageLimitInterrupter;
