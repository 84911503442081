import get from 'lodash.get';
import { config } from '@yola/ws-sdk';

const getPublishedSiteAnalyticsUrl = (rootState) => {
  const cfg = config.selectors.getConfig(rootState);

  return get(cfg, 'common.published_site_analytics.v2_url');
};

export default getPublishedSiteAnalyticsUrl;
