import React from 'react';
import PropTypes from 'prop-types';
import bowser from 'yola-bowser';
import classNames from 'classnames';
import { Modal, Panel, PanelGroup, designSystem } from '@yola/ws-ui';

const {
  Stack,
  DialogHeader,
  DialogHeaderTitle,
  DialogButtonGroup,
  DialogButton,
  DialogButtonGroupSeparator,
  Box,
  Paragraph,
  DialogHeaderControls,
  DialogHeaderIconButton,
  ColorItem,
  Divider,
  Accordion,
  AccordionGroup,
  AccordionHeader,
  AccordionContent,
  CtaBanner,
  NotificationMessage,
} = designSystem;

const DIALOG_WIDTH = 376;
const DIALOG_HEIGHT = 646;

const ColorSchemeSettingsDialog = ({
  offsetX,
  offsetY,
  captions: { title, description, buttons, general, upgradeBanner, notification },
  generalColorsSection,
  advancedColorsSections,
  isAdvancedColorCustomizationAvailable,
  showReset,
  isResetDisabled,
  activeAccordionItems,
  isBannerAnimated,
  toggleBannerAnimation,
  onToggleAccordionItem,
  onUpgrade,
  onColorSelect,
  onColorReset,
  onCancel,
  onSubmit,
  onDragEnd,
  onReset,
  onOverlayClick,
  contentRef,
}) => (
  <Modal
    draggable
    resizable={false}
    fullscreen={bowser.mobile}
    width={DIALOG_WIDTH}
    height={DIALOG_HEIGHT}
    offsetX={offsetX}
    offsetY={offsetY}
    dragHandleSelector=".ws-drag-trigger"
    dragCancelSelector=".ws-ui-dialog-header-controls"
    className="ws-color-scheme-settings"
    // we have to prevent submiting dialog on overlay click if upgrade dialog is showed
    floatingModalElementSelector=".wp-dialog-overlay, .product-select-container"
    overlay="none"
    handleCancel={onCancel}
    handleSubmit={onSubmit}
    onDragEnd={onDragEnd}
    onOverlayClick={onOverlayClick}
  >
    <PanelGroup height="100%">
      <Panel
        align="middle"
        corners="squared"
        className={classNames('ws-drag-trigger', 'ws-color-scheme-settings__flex-shrink-no')}
      >
        <DialogHeader>
          <DialogHeaderTitle>{title}</DialogHeaderTitle>
          {showReset && (
            <DialogHeaderControls>
              <DialogHeaderIconButton disabled={isResetDisabled} onClick={onReset} glyph="reset" />
            </DialogHeaderControls>
          )}
        </DialogHeader>
      </Panel>
      <div className="ws-color-scheme-settings__content" ref={contentRef}>
        <Box
          paddingTop="spacing-s"
          paddingBottom="spacing-s"
          paddingLeft="spacing-m"
          paddingRight="spacing-m"
        >
          <Stack gap="spacing-m">
            <Paragraph appearance="medium-emphasis" size="small">
              {description}
            </Paragraph>
            <Stack gap="spacing-xs">
              <div>
                <Box paddingTop="spacing-4-xs" paddingBottom="spacing-4-xs">
                  <Paragraph size="small">
                    <span className="ws-color-scheme-settings__section-title">
                      {general.header}
                    </span>
                  </Paragraph>
                </Box>
                <Divider />
              </div>
              <Paragraph appearance="medium-emphasis" size="small">
                {general.description}
              </Paragraph>
              <Stack gap="spacing-3-xs">
                {generalColorsSection.map(
                  ({
                    colorKey,
                    variableId,
                    variableCategory,
                    isColorChanged,
                    ...colorItemProps
                  }) => (
                    <ColorItem
                      {...colorItemProps}
                      key={variableId}
                      onEditClick={() => onColorSelect({ colorKey, variableId, variableCategory })}
                      {...(isColorChanged && {
                        onResetClick: () =>
                          onColorReset({ colorKey, variableId, variableCategory }),
                      })}
                    />
                  )
                )}
              </Stack>
              {isAdvancedColorCustomizationAvailable ? (
                <NotificationMessage
                  appearance="info"
                  iconGlyph="magic"
                  title={
                    <Paragraph>
                      <strong>{notification.title}</strong>
                    </Paragraph>
                  }
                  description={notification.description}
                />
              ) : (
                <CtaBanner
                  animate={isBannerAnimated}
                  layout="mobile"
                  iconGlyph="upgrade"
                  appearance="cta"
                  title={upgradeBanner.title}
                  subtitle={upgradeBanner.description}
                  ctaButton={{ label: upgradeBanner.upgrade, onClick: onUpgrade }}
                  onAnimationEnd={toggleBannerAnimation}
                />
              )}
            </Stack>
            <div
              className={classNames({
                'ws-color-scheme-settings__advance-customization-disabled':
                  !isAdvancedColorCustomizationAvailable,
              })}
            >
              <Accordion
                onToggleItem={onToggleAccordionItem}
                defaultActiveItem={activeAccordionItems}
              >
                {advancedColorsSections.map(
                  ({ id: variableCategory, title: sectionTitle, colorItems }) => (
                    <AccordionGroup key={variableCategory} itemId={variableCategory}>
                      <AccordionHeader title={sectionTitle.toUpperCase()} />
                      <AccordionContent>
                        <Stack gap="spacing-3-xs">
                          {colorItems.map(
                            ({ id: variableId, colorKey, isColorChanged, ...colorItemProps }) => {
                              const colorItem = {
                                colorKey,
                                variableId,
                                variableCategory,
                                opacity: colorItemProps.opacity,
                                isAdvancedColor: true,
                              };
                              return (
                                <ColorItem
                                  {...colorItemProps}
                                  key={variableId}
                                  onEditClick={() => onColorSelect(colorItem)}
                                  {...(isColorChanged && {
                                    onResetClick: () => onColorReset(colorItem),
                                  })}
                                />
                              );
                            }
                          )}
                        </Stack>
                      </AccordionContent>
                    </AccordionGroup>
                  )
                )}
              </Accordion>
            </div>
          </Stack>
        </Box>
      </div>
      <Panel
        corners="squared"
        className={classNames(
          'ws-color-scheme-settings__footer',
          'ws-color-scheme-settings__flex-shrink-no'
        )}
      >
        <DialogButtonGroup>
          <DialogButton onClick={onSubmit}>{buttons.submit}</DialogButton>
          <DialogButtonGroupSeparator />
          <DialogButton onClick={onCancel}>{buttons.cancel}</DialogButton>
        </DialogButtonGroup>
      </Panel>
    </PanelGroup>
  </Modal>
);

ColorSchemeSettingsDialog.propTypes = {
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    buttons: PropTypes.shape({
      submit: PropTypes.string.isRequired,
      cancel: PropTypes.string.isRequired,
    }),
    general: PropTypes.shape({
      header: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
    notification: PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
    upgradeBanner: PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      upgrade: PropTypes.string.isRequired,
    }),
  }).isRequired,
  generalColorsSection: PropTypes.arrayOf(
    PropTypes.shape({
      colorKey: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      variableId: PropTypes.string.isRequired,
      variableCategory: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      isColorChanged: PropTypes.bool,
    })
  ).isRequired,
  advancedColorsSections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      colorItems: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          color: PropTypes.string.isRequired,
          colorKey: PropTypes.string.isRequired,
          isColorChanged: PropTypes.bool.isRequired,
          opacity: PropTypes.string,
        })
      ).isRequired,
    })
  ).isRequired,
  isAdvancedColorCustomizationAvailable: PropTypes.bool.isRequired,
  isBannerAnimated: PropTypes.bool.isRequired,
  showReset: PropTypes.bool.isRequired,
  isResetDisabled: PropTypes.bool,
  activeAccordionItems: PropTypes.array.isRequired,
  toggleBannerAnimation: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  onColorSelect: PropTypes.func.isRequired,
  onColorReset: PropTypes.func.isRequired,
  onUpgrade: PropTypes.func.isRequired,
  onToggleAccordionItem: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onOverlayClick: PropTypes.func.isRequired,
  contentRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }).isRequired,
};

ColorSchemeSettingsDialog.defaultProps = {
  offsetX: 100,
  offsetY: 50,
  isResetDisabled: false,
};

export default ColorSchemeSettingsDialog;
