import React from 'react';

const PhoneIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="144" height="90" viewBox="0 0 144 90" fill="none">
    <path
      d="M93.7978 55.9922V62.5862C93.8003 63.1983 93.6746 63.8042 93.4289 64.3651C93.1832 64.926 92.8229 65.4295 92.3709 65.8433C91.919 66.2572 91.3854 66.5722 90.8044 66.7683C90.2234 66.9644 89.6078 67.0373 88.997 66.9822C82.2204 66.2473 75.711 63.9361 69.9919 60.2343C64.671 56.8597 60.1598 52.3571 56.7787 47.0463C53.0569 41.3122 50.7407 34.7837 50.0179 27.9897C49.9628 27.3818 50.0352 26.7693 50.2304 26.1909C50.4256 25.6125 50.7393 25.081 51.1515 24.6303C51.5637 24.1796 52.0655 23.8194 52.6248 23.5728C53.1842 23.3262 53.7888 23.1986 54.4003 23.198H61.0069C62.0756 23.1875 63.1118 23.5653 63.9221 24.2608C64.7324 24.9564 65.2617 25.9223 65.4113 26.9786C65.6902 29.0888 66.2073 31.1608 66.9529 33.155C67.2492 33.9417 67.3133 34.7967 67.1376 35.6187C66.962 36.4406 66.554 37.1951 65.9619 37.7927L65.3267 38.4267C64.0504 39.7006 63.765 41.6855 64.7902 43.169C67.2212 46.6866 70.2745 49.733 73.8 52.158C75.2813 53.177 77.2594 52.8928 78.532 51.6226L79.1751 50.9807C79.7739 50.3898 80.5298 49.9825 81.3534 49.8072C82.1769 49.6319 83.0336 49.6959 83.8218 49.9916C85.8198 50.7358 87.8957 51.2519 90.01 51.5302C91.0797 51.6809 92.0567 52.2187 92.7551 53.0414C93.4535 53.864 93.8246 54.9142 93.7978 55.9922Z"
      fill="#DADDE0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M77.2003 20.8567C77.2794 20.1431 77.9221 19.6288 78.6356 19.7079C83.397 20.2359 87.8375 22.364 91.2277 25.7436C94.6181 29.1231 96.7566 33.5533 97.2918 38.3067C97.3721 39.0201 96.8588 39.6636 96.1454 39.744C95.4319 39.8243 94.7884 39.311 94.7081 38.5976C94.2391 34.4315 92.3647 30.5481 89.3922 27.585C86.4196 24.6218 82.5255 22.7552 78.3491 22.2921C77.6355 22.213 77.1212 21.5703 77.2003 20.8567ZM77.2164 29.5435C77.3536 28.8388 78.0361 28.3787 78.7409 28.516C81.1453 28.9842 83.3554 30.1579 85.0882 31.8874C86.821 33.6169 87.9976 35.8234 88.467 38.2247C88.6047 38.9293 88.1451 39.6122 87.4405 39.75C86.7359 39.8877 86.053 39.4281 85.9153 38.7235C85.5453 36.8311 84.618 35.0915 83.2515 33.7276C81.8849 32.3637 80.1414 31.4375 78.2439 31.068C77.5392 30.9308 77.0791 30.2483 77.2164 29.5435Z"
      fill="#646C75"
    />
  </svg>
);

export default PhoneIcon;
