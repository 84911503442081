import { hdrm, view } from '@yola/ws-sdk';

const getElementBySelector = (selector, isStatic = false) => {
  const targetDocument = isStatic
    ? hdrm.instance.driver.getStaticDocument()
    : view.accessors.getLiveDocument();

  return targetDocument.querySelector(selector);
};

export default getElementBySelector;
