import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { dialogs, site } from '@yola/ws-sdk';
import yousers from '@yola/yousersjs';
import { DifmBannerPackagesContainer } from '@yola/webapp-plugins';
import segment from 'src/js/modules/analytics/segment';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import { TRIGGER_IDS } from '../constants/traits';

const {
  trackers: { trackEvent },
  constants: { events },
} = segment;

const DifmPromoPackagesDialogContainer = ({
  values,
  triggerId,
  onDialogCancel,
  onDialogMainAction,
}) => {
  const siteName = useSelector(site.selectors.getSiteName);
  const { serviceName } = values;

  const onSelect = (selectedPackage) => {
    trackEvent(events.DIFM_PROMO_DIALOG_PACKAGE_SELECTED, {
      selectedService: serviceName,
      selectedPackage,
      dialogId: dialogTypes.DIFM_PROMO_PACKAGES_DIALOG,
      triggerId,
    });
  };
  const onBack = (updatedValues) => {
    trackEvent(events.DIFM_PROMO_DIALOG_BACK_BUTTON_CLICKED, {
      dialogId: dialogTypes.DIFM_PROMO_PACKAGES_DIALOG,
    });

    dialogs.operations.show(dialogTypes.DIFM_PROMO_SURVEY_DIALOG, {
      values: updatedValues,
      triggerId,
    });
  };
  const onNext = ({ selectedPackage }) => {
    onDialogMainAction({
      triggerId,
      selectedService: serviceName,
      selectedPackage,
    });

    dialogs.operations.show(dialogTypes.DIFM_PROMO_FINISH_DIALOG, {
      selectedService: serviceName,
      selectedPackage,
    });
  };
  const onClose = () => {
    yousers.requests.declineDifm();
    onDialogCancel({
      selectedService: serviceName,
    });
    dialogs.operations.hide();
  };

  return (
    <DifmBannerPackagesContainer
      values={{ ...values, siteName }}
      onBack={onBack}
      onNext={onNext}
      onClose={onClose}
      onSelect={onSelect}
    />
  );
};

DifmPromoPackagesDialogContainer.propTypes = {
  values: PropTypes.shape({ serviceName: PropTypes.string }).isRequired,
  triggerId: PropTypes.oneOf(Object.values(TRIGGER_IDS)).isRequired,
  onDialogCancel: PropTypes.func.isRequired,
  onDialogMainAction: PropTypes.func.isRequired,
};

export default DifmPromoPackagesDialogContainer;
