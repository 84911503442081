import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Text, Button } from '@yola/ws-ui';
import { utils } from '@yola/ws-sdk';
import classNames from 'classnames';

const SiteProgressItem = ({
  captions,
  href,
  duration,
  isChecked,
  isDisabled,
  id,
  onCheckboxChange,
  onItemLinkClick,
}) => {
  const progressItemClassName = classNames('site-progress-item', {
    'site-progress-item--checked': isChecked,
  });

  const handleCheckboxChange = () => {
    onCheckboxChange();
  };

  const handleItemLinkClick = () => {
    onItemLinkClick(id);
  };

  return (
    <div className={progressItemClassName}>
      <Checkbox id={id} checked={isChecked} disabled={isDisabled} onChange={handleCheckboxChange}>
        <div className="site-progress-item__top">
          <Text type="heading-6">
            {/* eslint-disable-next-line yola/jsx-a11y/label-has-for */}
            <label htmlFor={id} className="title">
              {captions.title}
            </label>
          </Text>
          {!isChecked && (
            <Text type="annotation" theme="grey">
              {duration}
            </Text>
          )}
        </div>
        {!isChecked && (
          <React.Fragment>
            <Text type="annotation" theme="grey">
              {captions.description}
            </Text>
            <Button
              onMouseDown={handleItemLinkClick}
              type="link"
              size="large"
              href={href}
              target="blank"
            >
              {captions.link}
            </Button>
          </React.Fragment>
        )}
      </Checkbox>
    </div>
  );
};

SiteProgressItem.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
  href: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
  onItemLinkClick: PropTypes.func,
};

SiteProgressItem.defaultProps = {
  onItemLinkClick: utils.noop,
};

export default SiteProgressItem;
