import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import bowser from 'yola-bowser';
import { i18next } from '@yola/ws-sdk';
import {
  Modal,
  PanelGroup,
  Panel,
  Accordion,
  AccordionHeader,
  AccordionContent,
  Text,
  PanelLeft,
  PanelRight,
  Button,
  designSystem,
} from '@yola/ws-ui';
import AccordionHeaderTitle from 'src/js/modules/common/components/accordion-header-title';
import WidgetItem from './widget-item';
import RequestWidget from './request-widget';

const { Search } = designSystem;

const DIALOG_HEADER_HEIGHT = 48;
const DEFAULT_DIALOG_WIDTH = 360;
const DEFAULT_DIALOG_HEIGHT = 'auto';
const MIN_DIALOG_WIDTH = 320;
const MIN_DIALOG_HEIGHT = 570;

const AddWidgetDialog = (props) => {
  const {
    groupedItems,
    requestWidgetData,
    onRequestWidgetButtonClick,
    width,
    height,
    offsetX,
    offsetY,
    onClose,
    onOverlayClick,
    onSearch,
    onSearchFocus,
    onSelect,
    onModalReady,
  } = props;
  const isMobile = bowser.mobile;
  const modalRef = useRef(null);
  const searchQueryRef = useRef('');

  const handleSearch = (searchQuery) => {
    searchQueryRef.current = searchQuery;
    onSearch(searchQuery);
  };

  // Re-init Accordion component if certain props are changed - to prevent spare opening animation run
  const accordionCmpKey = `${offsetX}-${offsetY}-${searchQueryRef.current}`;

  return (
    <Modal
      draggable
      resizable
      fullscreen={isMobile}
      modalRef={modalRef}
      width={width}
      height={height}
      minWidth={MIN_DIALOG_WIDTH}
      minHeight={MIN_DIALOG_HEIGHT}
      offsetX={offsetX}
      offsetY={offsetY}
      className="ws-add-widget-dialog"
      dragHandleSelector=".ws-drag-trigger"
      handleCancel={onClose}
      onOverlayClick={onOverlayClick}
      handleSubmit={onClose}
      onReady={() => onModalReady(modalRef.current)}
    >
      <PanelGroup height="100%">
        <Panel
          height={DIALOG_HEADER_HEIGHT}
          theme="gray-100"
          corners="squared"
          align="middle"
          className="ws-add-widget-dialog__header ws-drag-trigger"
        >
          <PanelLeft className="ws-add-widget-dialog__header-left">
            <Text type="heading-6">{i18next.t('Widget library')}</Text>
          </PanelLeft>
          <PanelRight className="ws-add-widget-dialog__header-right">
            <Button type="link" onClick={onClose} className="ws-add-widget-dialog__close-button">
              {i18next.t('Close')}
            </Button>
          </PanelRight>
        </Panel>
        <Panel
          height={`calc(100% - ${DIALOG_HEADER_HEIGHT}px)`}
          corners="squared"
          scrollable
          className="ws-add-widget-dialog__inner"
        >
          <div className="ws-add-widget-dialog__search">
            <Search
              placeholder={i18next.t('Search widgets...')}
              onChange={handleSearch}
              onClear={handleSearch}
              onFocus={onSearchFocus}
            />
          </div>
          <div className="ws-add-widget-dialog__content">
            {groupedItems && groupedItems.length ? (
              <Accordion
                openedAccordionItems={groupedItems.map(({ categoryName }) => categoryName)}
                shouldPreventAnimation
                className="ws-add-widget-dialog__accordion"
                key={accordionCmpKey}
              >
                {groupedItems.map(({ categoryName, title, items }) => (
                  <React.Fragment key={categoryName}>
                    <AccordionHeader itemId={categoryName}>
                      {(isOpened) => <AccordionHeaderTitle title={title} isOpened={isOpened} />}
                    </AccordionHeader>
                    <AccordionContent
                      itemId={categoryName}
                      shouldRenderCollapsed
                      className="ws-add-widget-dialog__accordion-content"
                    >
                      {items.map((item) => (
                        <WidgetItem
                          item={item}
                          key={`${item.id}-${item.uuid}`}
                          onClick={onSelect}
                        />
                      ))}
                    </AccordionContent>
                  </React.Fragment>
                ))}
              </Accordion>
            ) : (
              <div className="ws-add-widget-dialog__empty-state">
                <Text
                  type="heading-1"
                  theme="light-grey"
                  className="ws-add-widget-dialog__empty-state-title"
                >
                  {i18next.t('Sorry!')}
                </Text>
                <Text type="heading-4" theme="light-grey">
                  {i18next.t('Nothing was found')}
                </Text>
              </div>
            )}
            {requestWidgetData && (
              <RequestWidget
                onClick={onRequestWidgetButtonClick}
                captions={requestWidgetData.captions}
                href={requestWidgetData.publicRoadmapUrl}
              />
            )}
          </div>
        </Panel>
      </PanelGroup>
    </Modal>
  );
};

AddWidgetDialog.propTypes = {
  groupedItems: PropTypes.arrayOf(
    PropTypes.shape({
      categoryName: PropTypes.string.isRequired,
      title: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          title: PropTypes.string,
          glyph: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  requestWidgetData: PropTypes.shape({
    captions: PropTypes.shape({
      title: PropTypes.string,
      button: PropTypes.string,
    }),
    publicRoadmapUrl: PropTypes.string,
  }),
  onRequestWidgetButtonClick: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  onOverlayClick: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSearchFocus: PropTypes.func,
  onModalReady: PropTypes.func,
};

AddWidgetDialog.defaultProps = {
  requestWidgetData: null,
  onRequestWidgetButtonClick: Function.prototype,
  width: DEFAULT_DIALOG_WIDTH,
  height: DEFAULT_DIALOG_HEIGHT,
  offsetX: 0,
  offsetY: 0,
  onModalReady: Function.prototype,
  onSearchFocus: Function.prototype,
  onOverlayClick: Function.prototype,
};

export default AddWidgetDialog;
