export const ASPECT_RATIO_ATTRIBUTE = 'aspect-ratio';
export const CONTENT_POSITION_ATTRIBUTE = 'content-position';
export const WIDTH_ATTRIBUTE = 'width';
export const MEDIA_GROUP_ATTRIBUTE = 'media-group-id';
export const MEDIA_CONTAINER_ANCHOR_HIGHLIGHT = 'ws-height-anchor-highlighter';
export const MEDIA_GROUP_ID_LENGTH = 6;
export const MIN_CONTAINER_HEIGHT = 16;
export const MAX_CONTAINER_HEIGHT = 4000;
export const MIN_CONTAINER_WIDTH = 16;
export const MAX_CONTAINER_WIDTH_PERCENTAGE = 100;
