import getDateObjectFrom from 'yola-editor/src/js/modules/survey/helpers/get-date-object-from';
import constants from '../constants';
import publishingJobStatuses from '../constants/publishing-job-statuses';

const { YOLA_PARTNER_PUBLISHING_TYPE } = constants;

const isStalePublishingJob = (job, publishingType) => {
  const { status, createdAt } = job;
  const createdAtDate = getDateObjectFrom(createdAt);
  const publishingDuration = Date.now() - createdAtDate.getTime();
  const limit = (publishingType === YOLA_PARTNER_PUBLISHING_TYPE ? 10 : 65) * 60 * 1000;

  return status === publishingJobStatuses.IN_PROGRESS && publishingDuration > limit;
};

export default isStalePublishingJob;
