import { hooks, site, template } from '@yola/ws-sdk';
import constants from '../constants';
import track from '../track';

const handler = ({ blockId, variationId }) => {
  track(constants.events.BLOCK_CUTOUT, {
    siteId: site.accessors.getSiteId(),
    templateBuildSlug: template.accessors.getBuildSlug(),
    targetBlockId: blockId,
    targetBlockVariationId: variationId,
  });
};

const trackBlockCutout = () => {
  hooks.operations.subscribe(hooks.availableHooks.ON_AFTER_BLOCK_CUTOUT, handler);
};

export default trackBlockCutout;
