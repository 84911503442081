import blocks from 'src/js/modules/blocks';
import hasBackgroundContainer from './has-background-container';
import isImageContainer from './is-image-container';
import { getSettings } from '../settings';

const setScale = (node, scale) => {
  const settings = getSettings();

  if (blocks.verifiers.isWsBlock(node)) {
    node.setAttribute('background-scale', scale);
    const backgroundContainer = node.querySelector(settings.backgroundContainer);

    return backgroundContainer.calculateBoundsByScale(scale);
  }

  if (hasBackgroundContainer(node)) {
    const backgroundContainer = node.querySelector(settings.backgroundContainer);
    backgroundContainer.setAttribute('scale', scale);
    return backgroundContainer.calculateBoundsByScale(scale);
  }

  if (isImageContainer(node)) {
    node.setAttribute('content-scale', scale);
    return node.calculateBoundsByScale(scale);
  }
};

export default setScale;
