import hideHighlighterAction from 'src/js/modules/highlighter/actions/hide';
import { dialogs, view } from '@yola/ws-sdk';
import customUI from '../../custom-ui';
import showHighlighterAction from '../actions/show';

const SHOW_HIGHLIGHTER_TIMEOUT = 120;
let highlighterTimer;

const selectedElementHighlighter = (store) => (next) => (action) => {
  const state = store.getState();
  const currentSelectedElementId = view.selectors.getSelectedElement(state);

  switch (action.type) {
    case customUI.actionTypes.HIDE:
    case dialogs.actionTypes.HIDE_DIALOG: {
      if (currentSelectedElementId) {
        store.dispatch(view.actions.setSelectedElement(null));
      }
      next(action);
      return;
    }
    case view.actionTypes.SET_SELECTED_ELEMENT: {
      const { elementId: selectedElementId } = action.payload;
      const selectedElement = view.accessors.getLiveElement(selectedElementId);

      if (currentSelectedElementId && currentSelectedElementId !== selectedElementId) {
        const currentSelectedElement = view.accessors.getLiveElement(currentSelectedElementId);
        store.dispatch(hideHighlighterAction([currentSelectedElement]));
      }

      if (selectedElementId && currentSelectedElementId !== selectedElementId) {
        highlighterTimer = setTimeout(() => {
          store.dispatch(showHighlighterAction([selectedElement]));
        }, SHOW_HIGHLIGHTER_TIMEOUT);
      }

      next(action);
      return;
    }
    case view.actionTypes.SET_FOCUSED_ELEMENT: {
      clearTimeout(highlighterTimer);

      next(action);
      return;
    }
    default:
      next(action);
  }
};

export default selectedElementHighlighter;
