import { theme } from '@yola/ws-sdk';

import compileCustomTemplate from './compile-custom-template';

const setCurrentPalette = (global, palette, customTemplate, selector) => {
  const node = global.cloneNode(true);

  const compiledCustomTemplate = compileCustomTemplate(customTemplate, palette);

  const el = node.querySelector(selector);
  el.innerHTML = compiledCustomTemplate;
  el.setAttribute(theme.constants.COLOR_PALETTE_DATA_ATTRIBUTE, palette.id);

  return node;
};

export default setCurrentPalette;
