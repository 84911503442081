import { site, template } from '@yola/ws-sdk';
import segment from 'src/js/modules/analytics/segment';

const trackBlockLayoutSwitched = ({
  oldBlockId,
  newBlockId,
  oldBlockVariationId,
  newBlockVariationId,
  oldText,
  newText,
}) => {
  const { track, constants } = segment;

  track(constants.events.BLOCK_LAYOUT_SWITCHED, {
    siteId: site.accessors.getSiteId(),
    templateBuildSlug: template.accessors.getBuildSlug(),
    pageId: site.accessors.getActivePageId(),
    oldBlockId,
    newBlockId,
    oldBlockVariationId,
    newBlockVariationId,
    oldText,
    newText,
  });
};

export default trackBlockLayoutSwitched;
