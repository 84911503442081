import { view } from '@yola/ws-sdk';
import getScrollOffsetInContainer from './get-scroll-offset-in-container';
import getElementSideToScrollInContainer from './get-element-side-to-scroll-in-container';

const scrollToElementInContainer = (scrollContainer, element) => {
  const elementSideToScroll = getElementSideToScrollInContainer(element, scrollContainer);

  if (elementSideToScroll === null) {
    return Promise.resolve();
  }

  const newScrollPosition = getScrollOffsetInContainer(
    element,
    elementSideToScroll,
    scrollContainer
  );

  return view.helpers.smoothScroll.smoothlyScrollInsideContainer(
    scrollContainer,
    newScrollPosition
  );
};

export default scrollToElementInContainer;
