import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';

const { LanguageSelect } = designSystem;

const LanguageSelectField = (props) => {
  const {
    placeholder,
    options,
    defaultValue,
    field: { name },
    form: { setFieldValue, setFieldError, handleBlur },
  } = props;

  const handleChange = (option) => {
    setFieldValue(name, option.value, false);
  };

  const handleFocus = () => {
    setFieldError(name, '');
  };

  return (
    <LanguageSelect
      defaultValue={defaultValue}
      options={options}
      appearance="searchable"
      placeholder={placeholder}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
    />
  );
};

LanguageSelectField.propTypes = {
  options: PropTypes.shape({}).isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.oneOf([null])]).isRequired,
  placeholder: PropTypes.string,
  field: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
    setFieldError: PropTypes.func,
    handleBlur: PropTypes.func,
  }).isRequired,
};

LanguageSelectField.defaultProps = {
  placeholder: '',
};

export default LanguageSelectField;
