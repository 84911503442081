import React from 'react';
import PropTypes from 'prop-types';

class ViewDropArea extends React.Component {
  render() {
    const { visible, connectDropTarget, ...otherProps } = this.props;

    if (!visible) return null;

    return connectDropTarget(
      <div
        className="ws-viewport-droparea"
        ref={(area) => {
          this.area = area;
        }}
        {...otherProps}
      />
    );
  }
}

ViewDropArea.propTypes = {
  connectDropTarget: PropTypes.func.isRequired,
  visible: PropTypes.bool,
};

ViewDropArea.defaultProps = {
  visible: false,
};

export default ViewDropArea;
