import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import bowser from 'yola-bowser';
import get from 'lodash.get';
import { blocks, i18next, site, view, hdrm, dialogs, integration } from '@yola/ws-sdk';
import { designSystem } from '@yola/ws-ui';
import { constants } from '@yola/subscription-manager-js';
import ai from 'src/js/modules/ai';
import segment from 'src/js/modules/analytics/segment';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import setDisabledStatus from 'src/js/modules/view/actions/set-disabled-status';
import tabIdentifiers from 'src/js/modules/website-settings/constants/tab-identifiers';
import contextMenuDisplayedTracker from 'src/js/modules/context-menu/helpers/context-menu-displayed-tracker';
import upsell from 'src/js/modules/upsell';
import useFeatureFlags from 'yola-editor/src/js/modules/feature-flags/hooks/use-feature-flags';
import getRegeneratedBlockHtml from '../helpers/get-regenerated-block-html';
import regenerateBlockByHTML from '../helpers/regenerate-block-by-html';
import isTextInBlockChanged from '../verifiers/is-text-in-block-changed';
import { AI_BLOCK_REGENERATION_TRIGGER_ID } from '../constants/trigger-ids';
import contextMenu from '../../context-menu';

const { ActionButton, Popover, PopoverContent, PopoverTrigger, BetaLabel } = designSystem;
const QUOTA_EXCEEDED_CODE = 'ai_block_generation_quota_exceeded';

const {
  hostingPackageFeatureNames: { AI_BLOCK_GENERATION_QUOTA },
} = constants;
const {
  constants: { sourceIds },
  hooks: { useAiOnboarding },
} = ai;
const {
  constants: { events },
  trackers: { trackEvent },
} = segment;

const POPOVER_WIDTH = 310;
const POPOVER_HEIGHT = 150;

function BlockRegenerationContainer({ element, offsetX, offsetY, viewportHeight }) {
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const { onNextDialog } = useAiOnboarding();
  const [labelShown, setLabelShown] = useState(true);
  const [featureFlags] = useFeatureFlags([
    'ai_block_generator',
    'business_info_in_website_settings',
  ]);
  const {
    ai_block_generator: isAIBlockGeneratorEnabled,
    business_info_in_website_settings: isBusinessInfoInWebsiteSettingsEnabled,
  } = featureFlags;
  const activePageId = useSelector(site.selectors.getActivePageId);
  const limits = useSelector(integration.selectors.getLimits);
  const pageMeta = useSelector((state) => site.selectors.getPage(state, activePageId));
  const businessCategory = useSelector(site.selectors.getBusinessCategory);
  const blockId = blocks.accessors.getBlockIdByElement(element);
  const block = useSelector((state) => blocks.selectors.getBlock(state, blockId));
  const scrollPosition = useSelector(view.selectors.getScrollPosition);
  const variationId = blocks.accessors.getVariationIdByElement(element);
  const canBeRegenerated = blocks.verifiers.canBeRegenerated(blockId, variationId);
  const [hidden, setHidden] = useState(!canBeRegenerated);

  useEffect(() => {
    setHidden(!canBeRegenerated);
    // We need to update button state only if pane is re-rendered on another block,
    // we can't set blockId, cause there is a delay with control pane re-rendering
    // eslint-disable-next-line
  }, [offsetY]);

  const handleOrientationChange = useCallback((e) => {
    const portrait = e.matches;

    setLabelShown(!portrait);
  }, []);

  useEffect(() => {
    // Hide button label on mobile devices with portrait orientation
    if (!bowser.mobile) return;

    const mediaQueryList = window.matchMedia('(orientation: portrait)');

    mediaQueryList.addEventListener('change', handleOrientationChange);
    handleOrientationChange(mediaQueryList);

    // eslint-disable-next-line consistent-return
    return () => {
      mediaQueryList.removeEventListener('change', handleOrientationChange);
    };
  }, [handleOrientationChange]);

  const regenerateBlock = useCallback(async () => {
    dispatch(view.actions.setLoadedStatus(i18next.t('Regenerating block...')));
    dispatch(setDisabledStatus(true));
    dispatch(view.actions.setHoveredElement(null));
    dispatch(view.actions.setSelectedElement(null));
    const options = { layout: true, content: true };

    try {
      const elementId = view.accessors.getLiveElementId(element);
      const html = await getRegeneratedBlockHtml(element, pageMeta, options);
      if (html) {
        const regeneratedBlock = regenerateBlockByHTML(elementId, html, options);

        if (regeneratedBlock) {
          dispatch(
            view.actions.replaceElement(regeneratedBlock, elementId, {
              strategy: hdrm.constants.updateStrategies.UPDATE_STATIC_ONLY,
            })
          );
        }
      }
    } catch (e) {
      if (e.response?.status === 409 && e.response?.data?.code === QUOTA_EXCEEDED_CODE) {
        const currentLimit = get(limits, 'aiBlockGenerationQuota');
        upsell.operations
          .requestFeatureAvailability(AI_BLOCK_GENERATION_QUOTA, { ...currentLimit })
          .then(() => {
            regenerateBlock();
          })
          .catch(() => {
            // eslint-disable-next-line no-console
            console.log('Upgrade flow was canceled');
          });
      } else {
        dialogs.operations.show(dialogTypes.AI_ERROR_DIALOG, {
          errorType: e.results?.code,
        });
      }

      console.error(e);
    }

    dispatch(view.actions.setLoadedStatus(true));
    dispatch(setDisabledStatus(false));
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, [element]);

  const handleRegenerateClick = () => {
    trackEvent(events.AI_BLOCK_REGENERATE_BUTTON_CLICKED, {
      businessCategory,
      blockId,
      blockVariationId: variationId,
      categoryId: block.category,
      isTextChanged: isTextInBlockChanged(element),
    });

    onNextDialog({
      sourceId: sourceIds.REGENERATE_BLOCK,
      title: i18next.t('Regenerate block'),
      onNext: () => {
        dialogs.operations.hide();
        regenerateBlock();
      },
    });
  };

  const openWebsiteSettings = (e) => {
    e.preventDefault();

    contextMenu.operations.hideContextMenu();
    dialogs.operations.show(dialogTypes.WEBSITE_SETTINGS, {
      activeTabId: tabIdentifiers.BUSINESS_INFO,
    });
  };

  const titleContent = (
    <span className="ws-regenerate-block-wrapper__popover-title">
      {i18next.t('Regenerate block')}
      <BetaLabel />
    </span>
  );

  const descriptionContent = (
    <>
      {i18next.t('Try a new layout and content based on your business profile.')}&nbsp;
      {isBusinessInfoInWebsiteSettingsEnabled && (
        <>
          {i18next.t('Customize it any time under {link}', { link: '' })}
          <a onClick={openWebsiteSettings} href="/">
            {i18next.t('Settings')} &#x2192; {i18next.t('Business Info')}
          </a>
        </>
      )}
    </>
  );

  const onRegenerateClick = () => {
    if (!bowser.mobile) {
      handleRegenerateClick();
      return;
    }

    const { current } = wrapperRef;
    const { left } = current.getBoundingClientRect();
    const position = { x: left, y: offsetY };
    const contextMenuGroups = [];

    dispatch(
      contextMenu.actions.showContextMenu({
        title: titleContent,
        description: descriptionContent,
        position,
        groups: contextMenuGroups,
        triggerId: AI_BLOCK_REGENERATION_TRIGGER_ID,
        submitButton: {
          label: i18next.t('Regenerate'),
          iconGlyph: 'ai',
          onClick: () => {
            contextMenu.operations.hideContextMenu();
            handleRegenerateClick();
          },
        },
      })
    );
    contextMenuDisplayedTracker({
      triggerId: AI_BLOCK_REGENERATION_TRIGGER_ID,
      targetElement: element,
    });
  };

  if (!element || !isAIBlockGeneratorEnabled || hidden) return null;

  const popoverPlacement =
    scrollPosition + viewportHeight - offsetY > POPOVER_HEIGHT ? 'bottom-center' : 'top-center';

  return (
    <div
      className="ws-regenerate-block-wrapper"
      style={{
        left: offsetX,
        top: offsetY,
      }}
      ref={wrapperRef}
    >
      <Popover
        disabled={bowser.mobile}
        placement={popoverPlacement}
        width={POPOVER_WIDTH}
        title={titleContent}
      >
        <PopoverTrigger>
          <div className="ws-regenerate-block-wrapper__button">
            <ActionButton
              size="medium"
              format="solid"
              appearance="accent"
              label={labelShown ? i18next.t('Regenerate') : null}
              iconGlyph="ai"
              onClick={onRegenerateClick}
            />
          </div>
        </PopoverTrigger>
        <PopoverContent>{descriptionContent}</PopoverContent>
      </Popover>
    </div>
  );
}

BlockRegenerationContainer.propTypes = {
  element: PropTypes.object.isRequired,
  offsetX: PropTypes.number.isRequired,
  offsetY: PropTypes.number.isRequired,
  viewportHeight: PropTypes.number.isRequired,
};

export default BlockRegenerationContainer;
