import { utils } from '@yola/ws-sdk';

const createPhoneValidator = (errorMessage) => (value) => {
  if (errorMessage && value && !utils.validation.isPhoneNumber(value)) {
    return errorMessage;
  }

  return null;
};

export default createPhoneValidator;
