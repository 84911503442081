import {
  SITE_ID,
  SITE_PROGRESS_CHECKLIST_OPENED_CATEGORIES,
  SITE_PROGRESS_CHECKLIST_SCROLL_TOP,
} from '../constants';
import getSafeLocalStorage from './get-safe-local-storage';

const resetLocalStorageData = (siteId) => {
  const safeLocalStorage = getSafeLocalStorage();

  safeLocalStorage.removeItem(SITE_PROGRESS_CHECKLIST_SCROLL_TOP);
  safeLocalStorage.removeItem(SITE_PROGRESS_CHECKLIST_OPENED_CATEGORIES);
  safeLocalStorage.setItem(SITE_ID, siteId);
};

export default resetLocalStorageData;
