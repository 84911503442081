import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { i18next } from '@yola/ws-sdk';
import GenericDialog from 'yola-editor/src/js/modules/dialogs/components/generic-dialog';
import DialogsEmoji from 'yola-editor/src/js/modules/dialogs/components/dialogs-emoji';
import { emojis, labels } from 'yola-editor/src/js/modules/common/constants/emoji';

function ThankYouDialogContainer(props) {
  const {
    emoji = emojis.raisingHands,
    captions: propsCaptions,
    onShow,
    onMainAction,
    onSecondaryAction,
    onDialogMainAction,
    onDialogCancel,
    onClose,
  } = props;

  const captions = {
    emojiLabel: labels.raisingHand(),
    title: i18next.t('Thank you!'),
    content: i18next.t(
      'People like you really help us make the product better. We will use your feedback for future improvement.'
    ),
    ...propsCaptions,
  };
  const genericDialogCaptions = {
    mainAction: captions.mainAction,
    secondaryAction: captions.secondaryAction,
  };

  useEffect(() => {
    onShow();
  }, [onShow]);

  const handleMainAction = (...args) => {
    onDialogMainAction();
    onMainAction(...args);
  };

  const handleSecondaryAction = (...args) => {
    onDialogCancel();
    onSecondaryAction(...args);
  };

  const handleClose = (...args) => {
    onDialogCancel();
    onClose(...args);
  };

  return (
    <GenericDialog
      captions={genericDialogCaptions}
      onClose={handleClose}
      onMainAction={handleMainAction}
      onSecondaryAction={handleSecondaryAction}
    >
      <DialogsEmoji label={labels.raisingHand()} html={emoji} />
      <h5 className="dialog-body__title">{captions.title}</h5>
      <p className="dialog-body__content">{captions.content}</p>
    </GenericDialog>
  );
}

ThankYouDialogContainer.propTypes = {
  emoji: PropTypes.string,
  captions: PropTypes.shape({
    emojiLabel: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string,
    mainAction: PropTypes.string,
    secondaryAction: PropTypes.string,
  }).isRequired,
  onShow: PropTypes.func,
  onMainAction: PropTypes.func,
  onSecondaryAction: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

ThankYouDialogContainer.defaultProps = {
  emoji: undefined,
  onShow: Function.prototype,
  onMainAction: null,
  onSecondaryAction: Function.prototype,
};

export default ThankYouDialogContainer;
