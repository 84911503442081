import { i18next } from '@yola/ws-sdk';

const getCaptions = () => ({
  title: i18next.t('Block settings'),
  tabs: {
    general: i18next.t('General'),
    appearance: i18next.t('Appearance'),
    layout: i18next.t('Layout'),
  },
  buttons: {
    submit: i18next.t('Save'),
    cancel: i18next.t('Cancel'),
  },
  optionalChildren: {
    title: i18next.t('Block elements'),
    subtitle: i18next.t('Select which elements should be displayed'),
  },
  colorSchemes: {
    title: i18next.t('Style options'),
    subtitle: i18next.t('Select a color scheme'),
  },
  displayOptions: {
    title: i18next.t('Display settings'),
    subtitle: i18next.t('Select additional display options of the block'),
  },
});

export default getCaptions;
