const getCategoryChecklist = (category, checklist) =>
  category.items.reduce(
    (categoryChecklist, item) => ({
      ...categoryChecklist,
      [item]: checklist[item],
    }),
    {}
  );

export default getCategoryChecklist;
