import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import GoogleAnalyticsIntegrationContainer from '../../integrations/containers/google-analytics-integration-container';
import GoogleSearchConsoleIntegrationContainer from '../../integrations/containers/google-search-console-integration-container';
import CookiebotIntegrationContainer from '../../integrations/containers/cookiebot-integration-container';
import constants from '../constants';

const { tabIdentifiers } = constants;
const { Stack, Paragraph } = designSystem;

function IntegrationsSection({ captions, form, initialValues, showPromptForce }) {
  return (
    <div>
      <Stack gap="spacing-xs">
        <Paragraph appearance="medium-emphasis" size="small">
          {captions.description}
        </Paragraph>
        <GoogleAnalyticsIntegrationContainer
          captions={captions.googleAnalytics}
          form={form}
          activeTabId={tabIdentifiers.INTEGRATIONS}
          initialValues={initialValues}
          showPromptForce={showPromptForce}
        />
        <GoogleSearchConsoleIntegrationContainer
          captions={captions.googleSearchConsole}
          form={form}
          activeTabId={tabIdentifiers.INTEGRATIONS}
          initialValues={initialValues}
          showPromptForce={showPromptForce}
        />
        <CookiebotIntegrationContainer
          captions={captions.cookiebot}
          form={form}
          activeTabId={tabIdentifiers.INTEGRATIONS}
          initialValues={initialValues}
          showPromptForce={showPromptForce}
        />
      </Stack>
    </div>
  );
}

IntegrationsSection.defaultProps = {
  showPromptForce: false,
};

IntegrationsSection.propTypes = {
  captions: PropTypes.shape({
    description: PropTypes.string.isRequired,
    googleAnalytics: PropTypes.shape().isRequired,
    googleSearchConsole: PropTypes.shape().isRequired,
    cookiebot: PropTypes.shape().isRequired,
  }).isRequired,
  form: PropTypes.shape().isRequired,
  initialValues: PropTypes.shape().isRequired,
  showPromptForce: PropTypes.bool,
};

export default IntegrationsSection;
