export const USER_MODIFIED_ATTR = 'user-modified';
export const SURFACES_ATTRIBUTE = 'surfaces';
export const OVERLAY_ATTRIBUTE = 'overlay';
export const OVERLAY_SECTION_ATTRIBUTE = 'overlay-section';
export const OVERLAY_OPACITY_ATTRIBUTE = 'overlay-opacity';
export const WHITESPACE_TOP_ATTRIBUTE = 'white-space-top';
export const WHITESPACE_BOTTOM_ATTRIBUTE = 'white-space-bottom';
export const FULLSCREEN_ATTRIBUTE = 'fullscreen';
export const BACKGROUND_HIDDEN_ATTRIBUTE = 'background-hidden';
export const BACKGROUND_IMAGE_ATTRIBUTE = 'background-image';
export const BORDER_BOTTOM_ATTRIBUTE = 'border-bottom';
