import { dialogs } from '@yola/ws-sdk';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import getDialogProps from './get-dialog-props';

const { GALLERY_DIALOG, GALLERY_CONFIRMATION_DIALOG, GALLERY_FILE_TYPE_INFO_DIALOG } = dialogTypes;

const showFileTypeInfoDialog = ({
  galleryItems,
  uploadedGalleryItems,
  dismissedFilesNamesList,
  hasOnlyDefaultImages,
  elementId,
}) => {
  const nextDialogToShow = hasOnlyDefaultImages ? GALLERY_CONFIRMATION_DIALOG : GALLERY_DIALOG;
  const nextDialogProps = getDialogProps({
    currentDialogType: GALLERY_FILE_TYPE_INFO_DIALOG,
    targetDialogType: nextDialogToShow,
    galleryItems,
    uploadedGalleryItems,
    dismissedFilesNamesList,
    hasOnlyDefaultImages,
    elementId,
  });
  dialogs.operations.show(GALLERY_FILE_TYPE_INFO_DIALOG, nextDialogProps);
};

export default showFileTypeInfoDialog;
