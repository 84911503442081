import { HOST_ICON_MAP } from '../../registry/ws-social-links/constants/icon-autocomplete-maps';

const getSupportedHost = (url, hostsMap = HOST_ICON_MAP) => {
  const hosts = Object.keys(hostsMap).join('|');
  const hostRegexpBase = new RegExp(
    `^(?:https?:\\/\\/)?(?:www.)?(?:[^.]+\\.)?(${hosts})(?:\\/.*)?$`
  );
  const matchedData = url.match(hostRegexpBase);

  return matchedData && matchedData[1];
};

export default getSupportedHost;
