const advancedVariableIds = {
  BACKGROUND_COLOR: 'background-color',
  SITE_NAME: 'site-name',
  HEADING_1: 'heading-1',
  HEADING_2: 'heading-2',
  HEADING_3: 'heading-3',
  HEADING_4: 'heading-4',
  HEADING_5: 'heading-5',
  HEADING_6: 'heading-6',
  QUOTE: 'quote',
  PARAGRAPH: 'paragraph',
  TEXT_LINK: 'text-link',
  MARK_TEXT: 'mark-text',
  ITEM_DEFAULT: 'item-default',
  ITEM_ACTIVE: 'item-active',
  FEATURE_ICONS: 'feature-icons',
  SOCIAL_ICONS: 'social-icons',
  CONTACT_INFORMATION_ICONS: 'contact-information-icons',
  BUTTON_BACKGROUND: 'button-background',
  BUTTON_TEXT: 'button-text',
  BORDER: 'border',
  INPUT_BORDER: 'input-border',
  ACCENT_1: 'accent-1',
  ACCENT_2: 'accent-2',
  FOOTER_ITEM_DEFAULT: 'footer-item-default',
  BACKGROUND_OVERLAY: 'background-overlay',
};

export default advancedVariableIds;
