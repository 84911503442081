import { textFormatting } from '@yola/ws-sdk';
import removeClassNameByPrefix from './remove-classname-by-prefix';

const { TEXT_INTENT_MODIFIER_PREFIX } = textFormatting.constants;

const changeIndent = (indentModifiersMap) => {
  indentModifiersMap.forEach((modifier, node) => {
    const classNameWithoutModifiers = removeClassNameByPrefix(
      node.className,
      TEXT_INTENT_MODIFIER_PREFIX
    );
    const className = `${classNameWithoutModifiers} ${modifier}`.trim();

    if (className) {
      node.setAttribute('class', className);
    } else {
      node.removeAttribute('class');
    }
  });
};

export default changeIndent;
