import React from 'react';
import UploadImageContainer from 'src/js/modules/common/containers/upload-image-container';
import UploadImageTrigger from 'src/js/modules/common/components/upload-image-trigger';
import constants from '../constants/common';

const { IMAGE_ACCEPT_TYPE } = constants;

const getUploadImageControl = (props) => {
  const {
    id,
    elementId,
    getContext,
    onClick,
    onTriggerUploadImageClick,
    onTriggerBrowseStockPhotosClick,
    onImageUploadStart,
    onImageUploadEnd,
    onImageUploadError,
    onImageUploadCancel,
    onPhotoStockDialogCancel,
    preserveSharedData,
  } = props;

  return {
    id,
    trigger: (
      <UploadImageContainer
        onImageUploadStart={onImageUploadStart}
        onImageUploadEnd={onImageUploadEnd}
        onImageUploadError={onImageUploadError}
        onImageUploadCancel={onImageUploadCancel}
        preserveSharedData={preserveSharedData}
      >
        {({ onWrongFileType, onImageUpload, onStockPhotoUpload, active }) => (
          <UploadImageTrigger
            id={id}
            active={active}
            elementId={elementId}
            getContext={getContext}
            accept={IMAGE_ACCEPT_TYPE}
            onWrongFileType={onWrongFileType}
            onImageUpload={onImageUpload}
            onStockPhotoUpload={onStockPhotoUpload}
            onPhotoStockDialogCancel={onPhotoStockDialogCancel}
            onClick={onClick}
            onTriggerUploadImageClick={onTriggerUploadImageClick}
            onTriggerBrowseStockPhotosClick={onTriggerBrowseStockPhotosClick}
            preserveSharedData={preserveSharedData}
          />
        )}
      </UploadImageContainer>
    ),
    onMouseDown: (e) => e.stopPropagation(),
  };
};

export default getUploadImageControl;
