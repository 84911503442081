import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { dialogs, i18n, i18next, integration, saving } from '@yola/ws-sdk';
import { Text, UpgradePopover, Button, Accordion } from '@yola/ws-ui';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import dateTime from 'src/js/modules/date-time';
import BackupsGroup from '../components/backups-group';
import BackupsSkeleton from '../components/backups-skeleton';

function SiteBackups({ onBackupCreateSuccess, onCreateCancel, onRestoreCancel }) {
  const BACKUPS_TITLE = i18next.t('Website backups');
  const BACKUPS_DESCRIPTION = i18next.t(
    'View and restore previous versions of your website. Site backups are automatically created on every 5th save, site publish, and whenever you restore the previous version.'
  );
  const CREATE_BACKUP_LABEL = i18next.t('Create site backup');
  const dispatch = useDispatch();
  const [backupsList, setBackupsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const limits = useSelector(integration.selectors.getLimits);
  const upsells = useSelector(integration.selectors.getUpsells);
  const locale = useSelector(i18n.accessors.getLocale);

  const createManualBackup = () => {
    dispatch(
      dialogs.actions.show(dialogTypes.CREATE_MANUAL_SITE_BACKUP, {
        onBackupCreateSuccess,
        onCancel: onCreateCancel,
      })
    );
  };

  const restoreBackup = ({ id }) => {
    dispatch(
      dialogs.actions.show(dialogTypes.RESTORE_SITE_BACKUP, {
        backupId: id,
        onCancel: onRestoreCancel,
      })
    );
  };

  const deleteBackup = ({ id }) => {
    dispatch(
      dialogs.actions.show(dialogTypes.DELETE_SITE_BACKUP, {
        backupId: id,
        onCancel: onRestoreCancel,
      })
    );
  };

  const onUpgradeClick = () =>
    upsells.siteBackups.onUpgrade().catch(() => {
      // eslint-disable-next-line no-console
      console.log('Upgrade flow was canceled');
    });

  useEffect(() => {
    const controller = new AbortController();

    function fetchSiteBackups() {
      if (controller.signal.aborted) {
        return;
      }

      dispatch(saving.thunks.fetchSiteBackups()).then(
        (backups) => {
          if (!controller.signal.aborted) {
            setBackupsList(backups);
            setIsLoading(false);
          }
        },
        (error) => {
          console.error(`Couldn't load site backups:`, error);
          setTimeout(fetchSiteBackups, 5000);
        }
      );
    }

    fetchSiteBackups();

    return () => {
      controller.abort();
    };
  }, [dispatch]);

  let backupsGroupsByDate;
  let sortedBackupsGroupsDateKeys;

  if (backupsList) {
    // Grouped site backups by creation date in a format of handy
    // object where keys are creation date timestamps
    backupsGroupsByDate = backupsList.reduce((groups, backup) => {
      if (backup.isAvailable) {
        const createdAt = dateTime.helpers.serviceDateTimeToIso(backup.createdAt);
        const createdDateTimestamp = new Date(createdAt).setHours(0, 0, 0, 0);
        const backupExtended = {
          ...backup,
          createdDateTimestamp,
        };

        // Sort backups per creation date
        if (!groups[createdDateTimestamp]) {
          // eslint-disable-next-line no-param-reassign
          groups[createdDateTimestamp] = [];
        }
        groups[createdDateTimestamp].push(backupExtended);
      }

      return groups;
    }, {});

    // Array of sorted creation dates - handy to loop through it to build UI groups
    sortedBackupsGroupsDateKeys = Object.keys(backupsGroupsByDate).sort((a, b) => b - a);
  }

  // Tune the skeleton items quantity according to the actual ones for the better UI
  // The array in config represents items count per each group
  const skeletonConfig = limits.siteBackupsLimit.available ? [2, 1] : [2];

  return (
    <div className="ws-site-backups">
      <div className="ws-site-backups__block">
        <Text type="heading-6">{BACKUPS_TITLE}</Text>
        <Text type="annotation">{BACKUPS_DESCRIPTION}</Text>
        <Button onClick={createManualBackup}>{CREATE_BACKUP_LABEL}</Button>
      </div>

      {isLoading && <BackupsSkeleton config={skeletonConfig} />}

      {!isLoading && !!sortedBackupsGroupsDateKeys && !!sortedBackupsGroupsDateKeys.length && (
        <React.Fragment>
          <Accordion
            openedAccordionItems={sortedBackupsGroupsDateKeys.map((dateKey) => dateKey)}
            shouldPreventInitialAnimation
            className="ws-site-backups__accordion"
          >
            {sortedBackupsGroupsDateKeys.map((dateKey) => (
              <BackupsGroup
                groupTimestamp={dateKey}
                locale={locale}
                backupsList={backupsGroupsByDate[dateKey]}
                onBackupSelect={restoreBackup}
                onBackupDelete={deleteBackup}
                key={dateKey}
              />
            ))}
          </Accordion>

          {!limits.siteBackupsLimit.available && (
            <UpgradePopover
              id="siteBackupsUpgradePopover"
              captions={upsells.siteBackups.captions}
              onUpgradeClick={onUpgradeClick}
            />
          )}
        </React.Fragment>
      )}
    </div>
  );
}

SiteBackups.propTypes = {
  onBackupCreateSuccess: PropTypes.func,
  onCreateCancel: PropTypes.func,
  onRestoreCancel: PropTypes.func,
};

SiteBackups.defaultProps = {
  onBackupCreateSuccess: null,
  onCreateCancel: null,
  onRestoreCancel: null,
};

export default SiteBackups;
