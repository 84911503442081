const shouldControlDisplayIn = (control, displayInOption) => {
  const { displayOptions } = control;

  if (displayOptions) {
    return displayOptions[displayInOption];
  }

  return true;
};

export const shouldControlDisplayInControlPane = (control) =>
  shouldControlDisplayIn(control, 'shouldDisplayInControlPane');

export const shouldControlDisplayInMoreMenu = (control) =>
  shouldControlDisplayIn(control, 'shouldDisplayInMoreMenu');
