import { hooks } from '@yola/ws-sdk';
import control from './controls/control';
import removeContentEditable from './helpers/remove-content-editable';
import constants from './constants/common';

const { ON_LIVE_DOCUMENT_CONNECTED, ON_AFTER_INSERT_LIVE_ELEMENT } = hooks.availableHooks;
const extension = {
  slug: constants.SLUG,
  title: 'WS Navigation',
  description: 'Extension for editing site navigation',
  defaults: {
    querySelector: 'nav',
  },
  ui: {
    control: [control],
  },
};

hooks.operations.subscribe(ON_LIVE_DOCUMENT_CONNECTED, removeContentEditable);
hooks.operations.subscribe(ON_AFTER_INSERT_LIVE_ELEMENT, removeContentEditable);

export default extension;
