import React from 'react';

const LogoPlaceholder = () => (
  <svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.86 4.05c1.1 0 2-.9 2-2.03 0-1.11-.9-2.02-2-2.02s-2 .9-2 2.02.9 2.03 2 2.03ZM12.3 16.3H1a1 1 0 0 1-.85-1.53L7.61 2.81a1 1 0 0 1 1.68-.02c1.25 1.88 3.63 5.5 5.68 8.66l2.12-3.42a1 1 0 0 1 1.67-.05l4.81 6.77a1 1 0 0 1-.8 1.58l-4.67.04-5.8-.06Z"
      fill="#DADDE0"
    />
  </svg>
);

export default LogoPlaceholder;
