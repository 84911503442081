import config from 'src/js/modules/config';

const addressRegexp = /\/maps\/place\/([^/]+)\/([^/]+)/;
const googleMapsApiUrl = 'https://www.google.com/maps/embed/v1/place';

const createGoogleMapsLink = (targetUrl) => {
  const { pathname } = new URL(targetUrl);
  const address = pathname.match(addressRegexp);

  if (address && address[1] && address[2]) {
    const [, , zoom] = address[2].split(',');
    const apiKey = config.accessors.getGoogleMapsEmbedApiKey();

    return `${googleMapsApiUrl}?key=${apiKey}&q=${address[1]}&zoom=${zoom.replace('z', '')}`;
  }

  return targetUrl;
};

export default createGoogleMapsLink;
