import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

function StickyTooltip({
  children,
  title,
  customTargetSelector,
  scrollParentSelector,
  isDisabled,
}) {
  const containerRef = useRef(null);
  const [position, setPosition] = useState({ x: null, y: null });
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isDisabled) return;

    const VERTICAL_SHIFT = 1.2;
    const container = containerRef.current;
    const scrollParent =
      (scrollParentSelector && container.closest(scrollParentSelector)) || document;
    const tooltip = container.querySelector('.ws-sticky-tooltip');

    const mousemoveHandler = ({ target, clientX, clientY }) => {
      const customTarget = customTargetSelector && container.querySelector(customTargetSelector);
      const isTooltipNotTarget = tooltip !== target;

      if (isTooltipNotTarget && customTarget && !customTarget.contains(target)) {
        setIsVisible(false);
        return;
      }
      setIsVisible(true);

      const { left, top, right } = container.getBoundingClientRect();
      const { width: tooltipWidth, height: tooltipHeight } = tooltip.getBoundingClientRect();

      let x = clientX - tooltipWidth / 2;
      if (clientX + tooltipWidth / 2 > right) x = right - tooltipWidth;
      if (clientX - tooltipWidth / 2 < left) x = left;

      const y = clientY - tooltipHeight * VERTICAL_SHIFT;

      // Subtract container coordinates to place tooltip within it
      requestAnimationFrame(() => {
        setPosition({
          x: Math.round(x - left),
          y: Math.round(y - top),
        });
      });
    };

    const mouseenterHandler = () => {
      setIsVisible(true);
    };

    const mouseleaveHandler = () => {
      setIsVisible(false);
    };

    const scrollHandler = () => {
      setIsVisible(false);
    };

    container.addEventListener('mouseenter', mouseenterHandler);
    container.addEventListener('mousemove', mousemoveHandler);
    container.addEventListener('mouseleave', mouseleaveHandler);
    scrollParent.addEventListener('scroll', scrollHandler);

    // eslint-disable-next-line consistent-return
    return () => {
      container.removeEventListener('mouseenter', mouseenterHandler);
      container.removeEventListener('mousemove', mousemoveHandler);
      container.removeEventListener('mouseleave', mouseleaveHandler);
      scrollParent.removeEventListener('scroll', scrollHandler);
    };
  }, [customTargetSelector, isDisabled, scrollParentSelector]);

  if (isDisabled) return <React.Fragment>{children}</React.Fragment>;

  const isPositionSet = position.x !== null && position.y !== null;
  const visibility = isVisible && isPositionSet ? 'visible' : 'hidden';
  const transform = isPositionSet ? `translate3d(${position.x}px, ${position.y}px, 0)` : 'none';

  return (
    <div ref={containerRef} className="ws-sticky-tooltip__container">
      <div
        className="ws-sticky-tooltip"
        style={{
          visibility,
          transform,
        }}
      >
        {title}
      </div>
      {children}
    </div>
  );
}

StickyTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  customTargetSelector: PropTypes.string,
  scrollParentSelector: PropTypes.string,
  isDisabled: PropTypes.bool,
};

StickyTooltip.defaultProps = {
  customTargetSelector: null,
  scrollParentSelector: null,
  isDisabled: false,
};

export default StickyTooltip;
