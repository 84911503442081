import { hdrm, textFormatting } from '@yola/ws-sdk';
import getBlockIdRulesMap from 'src/js/modules/blocks/helpers/get-block-id-rules-map';
import generateBlockIdCssRule from 'src/js/modules/website-design/helpers/generate-block-id-css-rule';
import getSupportedTagNames from './get-supported-tag-names';
import getRelatedClassName from './get-related-class-name';

const afterViewLoad = (liveDOM) => {
  if (!liveDOM) {
    return undefined;
  }

  const onSurfaceChange = (e) => {
    const blockNode = e.target;
    const blockId = blockNode.id;
    const { surface } = e.detail;
    const stylesheet = textFormatting.helpers.getCustomTextColorsStylesheet();
    const blockIdRulesMap = getBlockIdRulesMap(stylesheet, blockId);
    const usedTextColorOptions = textFormatting.helpers.getElementCustomColorVariables(blockNode);
    if (Boolean(usedTextColorOptions.length) && blockIdRulesMap && blockIdRulesMap.size > 0) {
      generateBlockIdCssRule({
        stylesheet,
        surfaces: surface,
        blockId,
        blockIdRulesMap,
      });
    }
  };

  hdrm.listeners.eventListeners.add(liveDOM.body, 'ws:surfaceChange', onSurfaceChange);

  liveDOM.querySelectorAll(getSupportedTagNames()).forEach((element) => {
    element.classList.add(getRelatedClassName(element.tagName.toLowerCase()));
  });

  return liveDOM;
};

export default afterViewLoad;
