const SECONDARY_BUTTONS_COUNT = 6;
const BACKGROUND = 'Background';
const TEXT = 'Text';

const getSecondaryButtonsAdvancedColorsKeys = (colorKey) =>
  [...Array(SECONDARY_BUTTONS_COUNT)].map((_, number) => {
    const isBackgroundColor = colorKey.includes(BACKGROUND);
    const name = isBackgroundColor ? BACKGROUND : TEXT;
    const index = colorKey.indexOf(`1${name}`);

    return colorKey.slice(0, index).concat(`${number + 1}${name}`);
  });

export default getSecondaryButtonsAdvancedColorsKeys;
