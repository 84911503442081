import positions from '../constants/positions';
import textResizePropertyValues from '../constants/text-resize-property-values';

const getHandlerSidePositionMap = () => ({
  [textResizePropertyValues.LEFT]: positions.RIGHT,
  [textResizePropertyValues.RIGHT]: positions.LEFT,
  [textResizePropertyValues.CENTER]: positions.BOTH,
});

export default getHandlerSidePositionMap;
