import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import LogoPickerField from './fields/logo-picker-field';

const { Stack, Heading, Paragraph } = designSystem;

function WizardSetupLogoSection({ captions, logoField }) {
  return (
    <Stack gap="spacing-m">
      <Stack gap="spacing-3-xs">
        <Heading type="heading-3">{captions.sectionTitle}</Heading>
        <Paragraph>{captions.sectionDescription}</Paragraph>
      </Stack>
      <Stack gap="spacing-xs">
        <LogoPickerField
          control={logoField.control}
          name={logoField.name}
          label={logoField.buttonLabel}
          placeholder={logoField.placeholder}
          rules={logoField.rules}
        />
      </Stack>
    </Stack>
  );
}

WizardSetupLogoSection.propTypes = {
  captions: PropTypes.shape().isRequired,
  logoField: PropTypes.shape().isRequired,
};

export default WizardSetupLogoSection;
