import { SURFACES_ATTRIBUTE, OVERLAY_SECTION_ATTRIBUTE } from '../../../constants/attributes';
import isRecomendedSurfaceAllowable from '../../overlay/helpers/is-recomended-surface-allowable';
import isAffectedBlock from '../verifiers/is-affected-block';
import canBlockBeOverlaidByHeader from './can-block-be-overlaid-by-header';

const getSurfaceAutomationInstructions = ({ headerNode, nextSiblingNode, isStatic }) => {
  if (!isAffectedBlock(headerNode)) {
    return [];
  }

  if (!nextSiblingNode || !canBlockBeOverlaidByHeader(nextSiblingNode)) {
    return [];
  }

  const headerBlockSurfacesAttr = headerNode.getAttribute(SURFACES_ATTRIBUTE);
  const headerBlockSurfaces = headerBlockSurfacesAttr && headerBlockSurfacesAttr.split(' ');
  const [headerBlockSurface] = headerBlockSurfaces || [];

  const nextSiblingSurfacesAttr = nextSiblingNode.getAttribute(SURFACES_ATTRIBUTE);
  const nextSiblingSurfaces = nextSiblingSurfacesAttr && nextSiblingSurfacesAttr.split(' ');
  const [nextSiblingSurface] = nextSiblingSurfaces || [];

  if (
    !nextSiblingSurface ||
    headerBlockSurface === nextSiblingSurface ||
    !isRecomendedSurfaceAllowable(headerNode, nextSiblingSurface)
  ) {
    return [];
  }

  return [
    {
      node: headerNode,
      static: isStatic,
      instructions: [
        {
          property: SURFACES_ATTRIBUTE,
          value: nextSiblingSurface,
        },
      ],
    },
  ];
};

export default getSurfaceAutomationInstructions;
