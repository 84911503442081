import { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { dialogs, utils, assets, serviceClient } from '@yola/ws-sdk';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';

const { IMAGE_LOADING_DIALOG, PHOTO_SIZE_ERROR, PHOTO_LOAD_ERROR, FILE_TYPE_ERROR_DIALOG } =
  dialogTypes;

function UploadImageContainer({
  children,
  onImageUploadStart,
  onImageUploadError,
  onImageUploadCancel,
  onImageUploadEnd,
  preserveSharedData,
  ...props
}) {
  const cancelRef = useRef(false);

  const handleImageUpload = useCallback(
    async (fileList) => {
      const [imageFile] = fileList;
      if (!imageFile) return;
      onImageUploadStart();

      // Check whether selected file satisfies asset file size requirements
      const [, filesSizeError] = await utils.handleAsyncError(
        assets.operations.clientUploadAsset(imageFile)
      );

      if (filesSizeError) {
        console.error(filesSizeError);
        onImageUploadError(filesSizeError);
        return;
      }

      // Upload file and create media gallery asset
      dialogs.operations.show(IMAGE_LOADING_DIALOG, {
        onCancel: () => {
          dialogs.operations.hide({ preserveSharedData });
          cancelRef.current = true;
          onImageUploadCancel();
        },
      });

      const [response, createAssetError] = await utils.handleAsyncError(
        assets.operations.createMediaGalleryAssets({
          asset: imageFile,
          name: imageFile.name,
        })
      );

      if (createAssetError) {
        dialogs.operations.hide({ preserveSharedData });
        const {
          status,
          data: { code, maxHeight, maxWidth },
        } = createAssetError.response;

        if (status === 413 && code === 'image_too_large') {
          const { width, height } = await assets.helpers.getImageSize(
            URL.createObjectURL(imageFile)
          );
          dialogs.operations.show(PHOTO_SIZE_ERROR, {
            preserveSharedData,
            captions: {
              width,
              height,
              maxWidth,
              maxHeight,
            },
          });
        }

        console.error(createAssetError);
        onImageUploadError(createAssetError);
        return;
      }

      const {
        data: { source, name },
      } = response;

      if (!cancelRef.current) {
        onImageUploadEnd({ source, name, file: imageFile, isStockPhoto: false });
      }
    },
    [
      preserveSharedData,
      onImageUploadCancel,
      onImageUploadEnd,
      onImageUploadStart,
      onImageUploadError,
    ]
  );

  const handleStockPhoto = useCallback(
    async ({ selectedPhotos }) => {
      const [selectedPhoto] = selectedPhotos;
      const { id } = selectedPhoto;

      dialogs.operations.show(IMAGE_LOADING_DIALOG, {
        onCancel: () => dialogs.operations.hide({ preserveSharedData }),
      });

      const stockPhotoPromise = serviceClient.get().unsplashCreateAssetsFromPhoto(id);

      onImageUploadStart();
      const [response, error] = await utils.handleAsyncError(stockPhotoPromise);

      if (error || !response) {
        dialogs.operations.show(PHOTO_LOAD_ERROR, {
          preserveSharedData,
          isApiRateExceeded: Boolean(error && error.code === 429),
        });

        console.error(error);
        onImageUploadError(error);
        return;
      }

      const {
        data: { source, name },
      } = response;

      onImageUploadEnd({ source, name, isStockPhoto: true });
    },
    [preserveSharedData, onImageUploadEnd, onImageUploadStart, onImageUploadError]
  );

  return children({
    onWrongFileType: () => dialogs.operations.show(FILE_TYPE_ERROR_DIALOG),
    onImageUpload: handleImageUpload,
    onStockPhotoUpload: handleStockPhoto,
    preserveSharedData,
    ...props,
  });
}

UploadImageContainer.propTypes = {
  children: PropTypes.func.isRequired,
  onImageUploadStart: PropTypes.func,
  onImageUploadEnd: PropTypes.func,
  onImageUploadError: PropTypes.func,
  onImageUploadCancel: PropTypes.func,
  preserveSharedData: PropTypes.bool,
};

UploadImageContainer.defaultProps = {
  onImageUploadStart: utils.noop,
  onImageUploadEnd: utils.noop,
  onImageUploadError: utils.noop,
  onImageUploadCancel: utils.noop,
  preserveSharedData: false,
};

export default UploadImageContainer;
