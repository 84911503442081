import React from 'react';

function MenuPreview() {
  return (
    <svg viewBox="0 0 1920 1676" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="1920" height="1676" fill="white" />
      <rect width="1920" height="120" fill="white" />
      <rect opacity="0.8" x="375" y="44" width="100" height="32" rx="8" fill="#DADDE0" />
      <rect x="993" y="48" width="100" height="24" rx="8" fill="#B0B8BF" />
      <rect opacity="0.8" x="1125" y="48" width="100" height="24" rx="8" fill="#DADDE0" />
      <rect opacity="0.8" x="1257" y="48" width="100" height="24" rx="8" fill="#DADDE0" />
      <rect x="1405" y="32" width="140" height="56" rx="8" fill="#8E959E" />
      <rect opacity="0.4" x="1433" y="52" width="84" height="16" rx="8" fill="white" />
      <rect width="1920" height="876" transform="translate(0 120)" fill="white" />
      <rect x="761" y="200" width="398" height="48" rx="8" fill="#B0B8BF" />
      <rect opacity="0.8" x="649" y="288" width="622" height="24" rx="8" fill="#DADDE0" />
      <rect opacity="0.8" x="743.5" y="332" width="433" height="24" rx="8" fill="#DADDE0" />
      <rect opacity="0.8" x="375" y="420" width="270" height="220" rx="16" fill="#B0B8BF" />
      <g opacity="0.8">
        <path
          d="M543.334 541.111L531.886 529.664C527.981 525.759 521.649 525.759 517.744 529.664L484.074 563.334M485 563.334H535C539.603 563.334 543.334 559.603 543.334 555V505C543.334 500.398 539.603 496.667 535 496.667H485C480.398 496.667 476.667 500.398 476.667 505V555C476.667 559.603 480.398 563.334 485 563.334ZM497.037 522.593C500.106 522.593 502.593 520.106 502.593 517.037C502.593 513.969 500.106 511.482 497.037 511.482C493.969 511.482 491.482 513.969 491.482 517.037C491.482 520.106 493.969 522.593 497.037 522.593Z"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M543.334 541.111L531.886 529.664C527.981 525.759 521.649 525.759 517.744 529.664L484.074 563.334M485 563.334H535C539.603 563.334 543.334 559.603 543.334 555V505C543.334 500.398 539.603 496.667 535 496.667H485C480.398 496.667 476.667 500.398 476.667 505V555C476.667 559.603 480.398 563.334 485 563.334ZM497.037 522.593C500.106 522.593 502.593 520.106 502.593 517.037C502.593 513.969 500.106 511.482 497.037 511.482C493.969 511.482 491.482 513.969 491.482 517.037C491.482 520.106 493.969 522.593 497.037 522.593Z"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect x="375" y="680" width="148" height="32" rx="8" fill="#B0B8BF" />
      <rect opacity="0.8" x="375" y="732" width="261" height="24" rx="8" fill="#DADDE0" />
      <rect opacity="0.8" x="375" y="772" width="171" height="24" rx="8" fill="#DADDE0" />
      <rect x="375" y="812" width="64" height="24" rx="8" fill="#B0B8BF" />
      <rect opacity="0.8" x="675" y="420" width="270" height="220" rx="16" fill="#B0B8BF" />
      <g opacity="0.8">
        <path
          d="M843.334 541.111L831.886 529.664C827.981 525.759 821.649 525.759 817.744 529.664L784.074 563.334M785 563.334H835C839.603 563.334 843.334 559.603 843.334 555V505C843.334 500.398 839.603 496.667 835 496.667H785C780.398 496.667 776.667 500.398 776.667 505V555C776.667 559.603 780.398 563.334 785 563.334ZM797.037 522.593C800.106 522.593 802.593 520.106 802.593 517.037C802.593 513.969 800.106 511.482 797.037 511.482C793.969 511.482 791.482 513.969 791.482 517.037C791.482 520.106 793.969 522.593 797.037 522.593Z"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M843.334 541.111L831.886 529.664C827.981 525.759 821.649 525.759 817.744 529.664L784.074 563.334M785 563.334H835C839.603 563.334 843.334 559.603 843.334 555V505C843.334 500.398 839.603 496.667 835 496.667H785C780.398 496.667 776.667 500.398 776.667 505V555C776.667 559.603 780.398 563.334 785 563.334ZM797.037 522.593C800.106 522.593 802.593 520.106 802.593 517.037C802.593 513.969 800.106 511.482 797.037 511.482C793.969 511.482 791.482 513.969 791.482 517.037C791.482 520.106 793.969 522.593 797.037 522.593Z"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect x="675" y="680" width="148" height="32" rx="8" fill="#B0B8BF" />
      <rect opacity="0.8" x="675" y="732" width="261" height="24" rx="8" fill="#DADDE0" />
      <rect opacity="0.8" x="675" y="772" width="171" height="24" rx="8" fill="#DADDE0" />
      <rect x="675" y="812" width="64" height="24" rx="8" fill="#B0B8BF" />
      <rect opacity="0.8" x="975" y="420" width="270" height="220" rx="16" fill="#B0B8BF" />
      <g opacity="0.8">
        <path
          d="M1143.33 541.111L1131.89 529.664C1127.98 525.759 1121.65 525.759 1117.74 529.664L1084.07 563.334M1085 563.334H1135C1139.6 563.334 1143.33 559.603 1143.33 555V505C1143.33 500.398 1139.6 496.667 1135 496.667H1085C1080.4 496.667 1076.67 500.398 1076.67 505V555C1076.67 559.603 1080.4 563.334 1085 563.334ZM1097.04 522.593C1100.11 522.593 1102.59 520.106 1102.59 517.037C1102.59 513.969 1100.11 511.482 1097.04 511.482C1093.97 511.482 1091.48 513.969 1091.48 517.037C1091.48 520.106 1093.97 522.593 1097.04 522.593Z"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1143.33 541.111L1131.89 529.664C1127.98 525.759 1121.65 525.759 1117.74 529.664L1084.07 563.334M1085 563.334H1135C1139.6 563.334 1143.33 559.603 1143.33 555V505C1143.33 500.398 1139.6 496.667 1135 496.667H1085C1080.4 496.667 1076.67 500.398 1076.67 505V555C1076.67 559.603 1080.4 563.334 1085 563.334ZM1097.04 522.593C1100.11 522.593 1102.59 520.106 1102.59 517.037C1102.59 513.969 1100.11 511.482 1097.04 511.482C1093.97 511.482 1091.48 513.969 1091.48 517.037C1091.48 520.106 1093.97 522.593 1097.04 522.593Z"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect x="975" y="680" width="148" height="32" rx="8" fill="#B0B8BF" />
      <rect opacity="0.8" x="975" y="732" width="261" height="24" rx="8" fill="#DADDE0" />
      <rect opacity="0.8" x="975" y="772" width="171" height="24" rx="8" fill="#DADDE0" />
      <rect x="975" y="812" width="64" height="24" rx="8" fill="#B0B8BF" />
      <rect opacity="0.8" x="1275" y="420" width="270" height="220" rx="16" fill="#B0B8BF" />
      <g opacity="0.8">
        <path
          d="M1443.33 541.111L1431.89 529.664C1427.98 525.759 1421.65 525.759 1417.74 529.664L1384.07 563.334M1385 563.334H1435C1439.6 563.334 1443.33 559.603 1443.33 555V505C1443.33 500.398 1439.6 496.667 1435 496.667H1385C1380.4 496.667 1376.67 500.398 1376.67 505V555C1376.67 559.603 1380.4 563.334 1385 563.334ZM1397.04 522.593C1400.1 522.593 1402.59 520.106 1402.59 517.037C1402.59 513.969 1400.1 511.482 1397.04 511.482C1393.97 511.482 1391.48 513.969 1391.48 517.037C1391.48 520.106 1393.97 522.593 1397.04 522.593Z"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1443.33 541.111L1431.89 529.664C1427.98 525.759 1421.65 525.759 1417.74 529.664L1384.07 563.334M1385 563.334H1435C1439.6 563.334 1443.33 559.603 1443.33 555V505C1443.33 500.398 1439.6 496.667 1435 496.667H1385C1380.4 496.667 1376.67 500.398 1376.67 505V555C1376.67 559.603 1380.4 563.334 1385 563.334ZM1397.04 522.593C1400.1 522.593 1402.59 520.106 1402.59 517.037C1402.59 513.969 1400.1 511.482 1397.04 511.482C1393.97 511.482 1391.48 513.969 1391.48 517.037C1391.48 520.106 1393.97 522.593 1397.04 522.593Z"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect x="1275" y="680" width="148" height="32" rx="8" fill="#B0B8BF" />
      <rect opacity="0.8" x="1275" y="732" width="261" height="24" rx="8" fill="#DADDE0" />
      <rect opacity="0.8" x="1275" y="772" width="171" height="24" rx="8" fill="#DADDE0" />
      <rect x="1275" y="812" width="64" height="24" rx="8" fill="#B0B8BF" />
      <rect width="1920" height="560" transform="translate(0 996)" fill="#B0B8BF" />
      <rect x="761" y="1156" width="398" height="48" rx="8" fill="white" />
      <rect opacity="0.4" x="649" y="1224" width="622" height="24" rx="8" fill="white" />
      <rect opacity="0.4" x="743.5" y="1268" width="433" height="24" rx="8" fill="white" />
      <rect x="868" y="1340" width="184" height="56" rx="8" fill="white" />
      <rect x="918" y="1360" width="84" height="16" rx="8" fill="#B0B8BF" />
      <rect width="1920" height="120" transform="translate(0 1556)" fill="#646C75" />
      <g opacity="0.3">
        <rect x="770" y="1604" width="100" height="24" rx="8" fill="white" />
        <rect x="910" y="1604" width="100" height="24" rx="8" fill="white" />
        <rect x="1050" y="1604" width="100" height="24" rx="8" fill="white" />
      </g>
    </svg>
  );
}

export default MenuPreview;
