import trackBlockSettingsDialogDisplayed from './track-block-settings-dialog-displayed';
import trackBlockSettingsDialogSubmitted from './track-block-settings-dialog-submitted';
import trackBlockSettingsDialogCanceled from './track-block-settings-dialog-canceled';
import trackBlockSettingsOptionClicked from './track-block-settings-option-clicked';
import trackBlockLayoutDialogDisplayed from './track-block-layout-dialog-displayed';
import trackBlockLayoutDialogCanceled from './track-block-layout-dialog-canceled';
import trackBlockLayoutDialogSubmitted from './track-block-layout-dialog-submitted';
import trackBlockLayoutOptionClicked from './track-block-layout-option-clicked';
import trackBlockLayoutSwitchTemplateButtonClicked from './track-block-layout-switch-template-button-clicked';
import trackBlockLayoutSwitched from './track-block-layout-switched';
import trackSwitchBlockLayoutButtonClicked from './track-switch-block-layout-button-clicked';

export default {
  trackSwitchBlockLayoutButtonClicked,
  trackBlockSettingsDialogDisplayed,
  trackBlockSettingsDialogSubmitted,
  trackBlockSettingsDialogCanceled,
  trackBlockSettingsOptionClicked,
  trackBlockLayoutDialogDisplayed,
  trackBlockLayoutDialogCanceled,
  trackBlockLayoutDialogSubmitted,
  trackBlockLayoutOptionClicked,
  trackBlockLayoutSwitchTemplateButtonClicked,
  trackBlockLayoutSwitched,
};
