import { useLayoutEffect, useState } from 'react';
import { bowser } from '@yola/ws-sdk';
import highlighter from 'src/js/modules/highlighter';

const {
  helpers: { highlighterOffset },
} = highlighter;

const getPositioningArea = (positioningAreaBounds, scrollPosition) => ({
  width: positioningAreaBounds.width,
  height: positioningAreaBounds.height,
  left: positioningAreaBounds.left,
  top: positioningAreaBounds.top,
  topWithScrollPosition: positioningAreaBounds.top + scrollPosition,
});

const usePositioningArea = (element, scrollPosition) => {
  const elementBounds = element.getBoundingClientRect();
  const positioningAreaElement = element.parentElement;
  const positioningAreaBounds = positioningAreaElement.getBoundingClientRect();
  const correctionY = bowser.ios ? 0 : scrollPosition;

  const [positioningArea, setPositioningArea] = useState(
    getPositioningArea(positioningAreaBounds, scrollPosition)
  );
  const [clipPath, setClipPath] = useState('');

  const updatePositioningArea = () =>
    setPositioningArea(getPositioningArea(positioningAreaBounds, correctionY));

  const updateClipPath = () => {
    const indent = highlighterOffset.get();

    const xTopLeft = elementBounds.left - positioningArea.left;
    const yTopLeft = elementBounds.top - positioningArea.top;
    const xTopRight = xTopLeft + elementBounds.width + indent * 2;
    const yTopRight = yTopLeft;
    const xBottomLeft = xTopLeft;
    const yBottomLeft = yTopLeft + elementBounds.height + indent * 2;
    const xBottomRight = xTopRight;
    const yBottomRight = yBottomLeft;

    const highlighterBorderWidth = '2px';
    const clamp = (value) =>
      `clamp(${highlighterBorderWidth}, ${value}px, calc(100% - ${highlighterBorderWidth}))`;

    setClipPath(
      `polygon(
        0 0,
        0 100%,
        ${clamp(xTopLeft)} 100%,
        ${clamp(xTopLeft)} ${clamp(yTopLeft)},
        ${clamp(xTopRight)} ${clamp(yTopRight)},
        ${clamp(xBottomRight)} ${clamp(yBottomRight)},
        ${clamp(xBottomLeft)} ${clamp(yBottomLeft)},
        ${clamp(xBottomLeft)} 100%,
        100% 100%,
        100% 0
      )`
    );
  };

  useLayoutEffect(() => {
    updatePositioningArea();
    updateClipPath();
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, [scrollPosition]);

  return {
    positioningAreaBounds: positioningArea,
    positioningAreaClipPath: clipPath,
    updateClipPath,
  };
};

export default usePositioningArea;
