import { blocks } from '@yola/ws-sdk';
import regeneratePropertiesForBlockCssRule from './regenerate-properties-for-block-css-rule';

const regeneratePropertiesForBlockCssRules = (cssStyleSheet, palette) => {
  const { cssRules } = cssStyleSheet;

  Object.values(cssRules).forEach((cssRule) => {
    const { selectorText } = cssRule;

    if (selectorText.startsWith(`#${blocks.constants.BLOCK_ID_PREFIX}`)) {
      const [blockId, surfaceName] = selectorText
        .split(' ')
        .map((selector) => selector.substring(1));
      regeneratePropertiesForBlockCssRule(cssRule, blockId, surfaceName, palette);
    }
  });
};

export default regeneratePropertiesForBlockCssRules;
