import { extensions } from '@yola/ws-sdk';
import config from '../constants/config';

const isPlaceholder = (node) => {
  const settings = extensions.accessors.getExtensionSettings(config.slug);

  return Boolean(node.matches(settings.placeholderSelector));
};

export default isPlaceholder;
