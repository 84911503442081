import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import bowser from 'yola-bowser';

class Trigger extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFocused: false,
    };

    this.triggerRef = createRef();

    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
  }

  componentDidMount() {
    this.triggerRef.current.addEventListener('mousedown', this.handleMouseDown);
    this.triggerRef.current.addEventListener('mouseup', this.handleMouseUp);
  }

  componentWillUnmount() {
    this.triggerRef.current.removeEventListener('mousedown', this.handleMouseDown);
    this.triggerRef.current.removeEventListener('mouseup', this.handleMouseUp);
  }

  handleMouseDown() {
    this.setState({
      isFocused: true,
    });
  }

  handleMouseUp() {
    this.setState({
      isFocused: false,
    });
  }

  render() {
    const {
      children,
      active,
      disabled,
      onClick,
      elementId,
      width,
      disableHover,
      isReadonly,
      getContext,
      className,
      ...otherProps
    } = this.props;
    const { isFocused } = this.state;

    const isMobile = bowser.mobile || bowser.tablet;
    const triggerClassName = classNames('ws-trigger', className, {
      'ws-trigger--active': active,
      'ws-trigger--disabled': disabled,
      'ws-trigger--readonly': isReadonly,
      'ws-trigger--focused': isFocused,
      'ws-trigger--mobile': isMobile,
      'ws-trigger--disable-hover': disableHover,
    });

    const widthNumber = Number.parseInt(width, 10);
    const widthValue = !Number.isNaN(widthNumber) && `${widthNumber}px`;
    const style = {
      ...(widthValue && { width: widthValue }),
    };

    return (
      <div
        className={triggerClassName}
        onClick={onClick}
        {...otherProps}
        ref={this.triggerRef}
        style={style}
      >
        <div className="ws-trigger__inner">{children}</div>
      </div>
    );
  }
}

Trigger.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func,
  getContext: PropTypes.func,
  children: PropTypes.node,
  elementId: PropTypes.string,
  disabled: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disableHover: PropTypes.bool,
  isReadonly: PropTypes.bool,
  className: PropTypes.string,
};

Trigger.defaultProps = {
  active: false,
  disabled: false,
  isReadonly: false,
  onClick: () => {},
  children: null,
  elementId: null,
  width: null,
  disableHover: false,
  getContext: () => {},
  className: '',
};

export default Trigger;
