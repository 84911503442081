import actionTypes from '../constants/action-types';

const setLocation = (location) => ({
  type: actionTypes.SET_LOCATION,
  payload: {
    location,
  },
});

export default setLocation;
