import React from 'react';
import PropTypes from 'prop-types';
import { site, template } from '@yola/ws-sdk';
import segment from '../analytics/segment';

const MainMenuItemsClickEventTracker = (props) => {
  const { items, children } = props;
  const {
    track,
    constants: { events },
  } = segment;

  const siteId = site.accessors.getSiteId();
  const templateBuildSlug = template.accessors.getBuildSlug();

  const itemsWithEventTracker = items.map((item) => {
    if (!item || !item.id || !item.onClick) return item;
    const originalClickAction = item.onClick;
    return {
      ...item,
      onClick: (event) => {
        originalClickAction(event);

        track(events.APP_MAIN_MENU_ITEM_CLICKED, {
          itemId: item.id,
          siteId,
          templateBuildSlug,
        });
      },
    };
  });

  return React.cloneElement(children, { items: itemsWithEventTracker });
};

MainMenuItemsClickEventTracker.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default MainMenuItemsClickEventTracker;
