import { blocks } from '@yola/ws-sdk';

function isEmptyCustomBlock(block) {
  if (!block.hasAttribute('custom')) {
    return false;
  }
  return block.querySelectorAll(`[id^=${blocks.constants.WIDGET_ID_PREFIX}]`).length === 0;
}

export default isEmptyCustomBlock;
