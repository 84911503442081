import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import referralLinksStorage from '../helpers/referral-links-storage';
import showShareDialog from '../operations/show-share-dialog';

const ReferAFriendOnClickInitiator = (props) => {
  const { children } = props;
  const preloadReferralLinks = () => referralLinksStorage.getAsync();

  useEffect(() => {
    preloadReferralLinks();
  }, []);

  const onClick = () => {
    referralLinksStorage.getAsync().then((links) => {
      showShareDialog(links);
    });
  };

  return React.cloneElement(children, { onClick });
};

ReferAFriendOnClickInitiator.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ReferAFriendOnClickInitiator;
