import PropTypes from 'prop-types';

const codeEditorPropTypes = {
  onEditorError: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  showError: PropTypes.bool,
  errorText: PropTypes.string,
  readOnly: PropTypes.bool,
};

export default codeEditorPropTypes;
