const languageCodesMap = {
  cs: 'cs',
  da: 'da',
  de: 'de',
  en: 'en',
  es: 'es',
  fr: 'fr',
  hu: 'hu',
  id: 'id',
  it: 'it',
  nb: 'nb',
  nl: 'nl',
  pl: 'pl',
  'pt-br': 'pt-br',
  uk: 'uk',
  sk: 'sk',
  sv: 'sv',
  fi: 'fi',
};

export default languageCodesMap;
