const HTML_TAGS_REG_EXP = /(<([^>]+)>)/gi;
const SPECIAL_CHARACTERS_REG_EXP = /[.*+?^${}()|[\]\\]/g;

const atLeastOneWordStartsWithQuery = (queryString, targetString) => {
  const wordStartsWithQuery = new RegExp(`((?:)[\\s,-.:;"']|^)${queryString}`);
  return wordStartsWithQuery.test(targetString);
};

const isQueryMatchesInStrings = (queryString, targetStrings) =>
  targetStrings.filter(Boolean).some((string) => {
    const targetString = string.toLowerCase();
    return atLeastOneWordStartsWithQuery(queryString, targetString);
  });

const strippedHtmlString = (htmlString) => htmlString.replace(HTML_TAGS_REG_EXP, ' ').trim();

const prepareQueryString = (queryString) =>
  queryString && queryString.toLowerCase().trim().replace(SPECIAL_CHARACTERS_REG_EXP, '\\$&'); // escape special characters

const filterWidgetsByQuery = (query, items) =>
  items.filter(({ title, localizedCategory, localizedTags, html = '' }) => {
    const targetStrings = [
      title,
      localizedCategory,
      localizedTags.join(' '),
      strippedHtmlString(html),
    ];

    return isQueryMatchesInStrings(query, targetStrings);
  });

const getWidgetSearchResult = (searchStr, initialWidgetList) => {
  const queryString = prepareQueryString(searchStr);

  return initialWidgetList
    .map((widgetCategory) => ({
      ...widgetCategory,
      items: filterWidgetsByQuery(queryString, widgetCategory.items),
    }))
    .filter(({ items }) => items && items.length);
};

export default getWidgetSearchResult;
