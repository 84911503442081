import { serviceProvider } from '@yola/subscription-manager-js';
import user from 'yola-editor/src/js/modules/user';
import errorModule from 'yola-editor/src/js/modules/error';
import fetchAvailablePackagesComplete from '../actions/fetch-available-packages-complete';

const fetchAvailablePackages = () => async (dispatch, getState) => {
  const state = getState();
  const partnerData = user.selectors.getPartnerData(state);
  const { id: partnerId } = partnerData;

  try {
    await serviceProvider.fetchPackages(partnerId);
    dispatch(fetchAvailablePackagesComplete());
  } catch (error) {
    dispatch(
      errorModule.actions.setCriticalError({
        error,
        logSentry: true,
      })
    );
  }
};

export default fetchAvailablePackages;
