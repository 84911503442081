import getNewItemOrder from './get-new-item-order';

const getUpdatedItems = ({ title, config, items, index: targetIndex }) => {
  const targetItem = items[targetIndex];

  if (!targetItem) {
    const newLinkOptions = {
      id: items.length ? items.length + 1 : 1,
      order: getNewItemOrder(items),
      title,
      config,
    };

    return [...items, newLinkOptions];
  }

  return items.map((item, index) => (index === targetIndex ? { ...item, title, config } : item));
};

export default getUpdatedItems;
