import getSubmoduleConfig from '../../../helpers/get-submodule-config';
import { SUBMODULE_NAME } from '../constants';

const getAffectedBlocksSelector = () => {
  const { affectedBlocksSelector } = getSubmoduleConfig(SUBMODULE_NAME);

  if (!affectedBlocksSelector) return null;

  return affectedBlocksSelector;
};

export default getAffectedBlocksSelector;
