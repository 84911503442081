import slots from 'src/js/modules/common/constants/slots';
import control from './controls/control';
import constants from './constants';

const {
  SITE_NAME,
  BUSINESS_NAME,
  BUSINESS_ADDRESS,
  BUSINESS_PHONE,
  BUSINESS_EMAIL,
  BUSINESS_HOURS,
} = slots;

const extension = {
  slug: constants.common.SLUG,
  title: 'WS Contact Information',
  description: 'Extension for editing contact information',
  defaults: {
    querySelector: `[slot="${SITE_NAME}"], [slot="${BUSINESS_NAME}"], [slot="${BUSINESS_PHONE}"], [slot="${BUSINESS_EMAIL}"], [slot="${BUSINESS_HOURS}"]. [slot="${BUSINESS_ADDRESS}"]`,
  },
  ui: {
    control: [control],
  },
};

export default extension;
