import React from 'react';
import PropTypes from 'prop-types';
import { i18next } from '@yola/ws-sdk';
import { Icon, designSystem } from '@yola/ws-ui';
import classNames from 'classnames';

const { Tag } = designSystem;

function CategoriesList({ categories, activeCategory, onSelect }) {
  const getItemClassName = ({ name, description }) =>
    classNames('ws-categories-list__item', {
      'ws-primary': description,
      'ws-active': activeCategory && activeCategory.name === name,
    });

  return (
    <div className="ws-categories-list">
      {categories.map((category) => (
        <div
          className={getItemClassName(category)}
          onClick={() => onSelect(category)}
          key={category.name}
        >
          <span className="ws-categories-list__item-name">{category.label || category.name}</span>
          {category.hasNewBlocks && (
            <span className="ws-categories-list__tag-wrapper">
              <Tag label={i18next.t('New')} />
            </span>
          )}
          <Icon
            glyph="chevron-right"
            size="20"
            strokeWidth="1.3"
            className="ws-categories-list__item-arrow"
          />
        </div>
      ))}
    </div>
  );
}

CategoriesList.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      description: PropTypes.string,
    })
  ).isRequired,
  activeCategory: PropTypes.shape({
    name: PropTypes.string,
  }),
  onSelect: PropTypes.func.isRequired,
};

CategoriesList.defaultProps = {
  activeCategory: null,
};

export default CategoriesList;
