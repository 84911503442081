import React from 'react';
import { customization, view } from '@yola/ws-sdk';
import PropTypes from 'prop-types';
import getCircleBracketsContent from 'src/js/modules/utils/get-circle-brackets-content';
import { dashedToCamelCase } from 'src/js/modules/utils/convert-case';

const getCamelCaseVariableName = (cssVar) => dashedToCamelCase(cssVar.replace('--ws-', ''));

const getVariableValue = (propName, variables) => {
  const propertyValue = variables[propName];
  const circleBracketsContent = getCircleBracketsContent(propertyValue);

  if (!circleBracketsContent) return propertyValue;
  const nestedProperty = getCamelCaseVariableName(circleBracketsContent);

  if (!variables[nestedProperty]) return nestedProperty;
  return getVariableValue(nestedProperty, variables);
};

const getFontFamily = (propName, variables) => {
  const liveDocument = view.accessors.getLiveDocument();
  const fontFamilyVariableName = getCamelCaseVariableName(
    getCircleBracketsContent(variables[propName])
  );
  const fontFamily = variables[fontFamilyVariableName];

  const fontFallbackCssVariable =
    variables[fontFamilyVariableName.replace('Family', 'TypeFallback')];
  const fontFallback = getComputedStyle(liveDocument.documentElement).getPropertyValue(
    getCircleBracketsContent(fontFallbackCssVariable)
  );

  return `'${fontFamily}', ${fontFallback}`;
};

const PreviewOption = ({ children, type }) => {
  const { variables } = customization.accessors.getCurrentFontPair();

  const styles = {
    fontFamily: getFontFamily(`${type}FontFamily`, variables),
    fontStyle: getVariableValue(`${type}FontStyle`, variables),
    fontWeight: getVariableValue(`${type}FontWeight`, variables),
    letterSpacing: getVariableValue(`${type}LetterSpacing`, variables),
    textTransform: getVariableValue(`${type}TextTransform`, variables),
  };

  const weightEmulationVariable = variables[`${type}FontWeightEmulation`];

  if (weightEmulationVariable && weightEmulationVariable !== 'none') {
    const weightEmulationPropertyName = getCamelCaseVariableName(
      getCircleBracketsContent(weightEmulationVariable)
    );
    const weightEmulationValue = variables[weightEmulationPropertyName];

    if (weightEmulationValue !== 'none') {
      styles.textShadow = weightEmulationValue;
    }
  }
  return (
    <div style={styles} className={`ws-element-type-preview--${type}`}>
      {children}
    </div>
  );
};

PreviewOption.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired,
};

export default PreviewOption;
