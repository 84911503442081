import {
  WHITESPACE_TOP_ATTRIBUTE,
  WHITESPACE_BOTTOM_ATTRIBUTE,
} from '../../../constants/attributes';
import COLLAPSE_RULES from '../constants/collapse-rules';
import canEditTopWhitespace from '../verifiers/can-edit-top-whitespace';
import shouldApplyWhitespaceAutomation from '../verifiers/should-apply-whitespace-automation';
import shouldRevertWhitespaceAutomation from '../verifiers/should-revert-whitespace-automation';

const getWhitespaceAutomationInstructions = ({
  targetNode,
  prevSiblingNode,
  isStatic = false,
  isRevertWhitespaceAutomationEnabled = true,
}) => {
  if (!canEditTopWhitespace(targetNode)) {
    return [];
  }

  if (shouldApplyWhitespaceAutomation(targetNode, prevSiblingNode)) {
    const targetWhitespaceTop = targetNode.getAttribute(WHITESPACE_TOP_ATTRIBUTE);
    const prevSiblingWhitespaceBottom = prevSiblingNode.getAttribute(WHITESPACE_BOTTOM_ATTRIBUTE);

    if (!targetWhitespaceTop || !prevSiblingWhitespaceBottom) {
      return [];
    }

    const newTargetWhitespaceTop = COLLAPSE_RULES[prevSiblingWhitespaceBottom][targetWhitespaceTop];

    if (targetWhitespaceTop === newTargetWhitespaceTop) {
      return [];
    }

    return [
      {
        node: targetNode,
        static: isStatic,
        instructions: [
          {
            property: WHITESPACE_TOP_ATTRIBUTE,
            value: newTargetWhitespaceTop,
          },
        ],
      },
    ];
  }

  if (
    isRevertWhitespaceAutomationEnabled &&
    shouldRevertWhitespaceAutomation(targetNode, prevSiblingNode)
  ) {
    const targetWhitespaceTop = targetNode.getAttribute(WHITESPACE_TOP_ATTRIBUTE);
    const newTargetWhitespaceTop = targetNode.getAttribute(WHITESPACE_BOTTOM_ATTRIBUTE);

    if (targetWhitespaceTop === newTargetWhitespaceTop) {
      return [];
    }

    return [
      {
        node: targetNode,
        static: isStatic,
        instructions: [
          {
            property: WHITESPACE_TOP_ATTRIBUTE,
            value: newTargetWhitespaceTop,
          },
        ],
      },
    ];
  }

  return [];
};

export default getWhitespaceAutomationInstructions;
