import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { i18next, template } from '@yola/ws-sdk';
import bowser from 'yola-bowser';
import { Modal, Panel, PanelGroup, designSystem } from '@yola/ws-ui';
import { constants as subscriptionConstants } from '@yola/subscription-manager-js';
import withFeatureFlags from 'yola-editor/src/js/modules/feature-flags/hoc/with-feature-flags';
import isFeatureAvailable from 'src/js/modules/upsell/verifiers/is-feature-available';
import segment from 'src/js/modules/analytics/segment';
import constants from '../constants/common';
import getHeight from '../helpers/get-element-height';
import getWidth from '../helpers/get-element-width';
import BasicIcons from './basic-icons';
import AdvancedIcons from './advanced-icons';
import getIconName from '../helpers/get-icon-name';
import createIconAssetSrc from '../helpers/create-icon-asset-src';
import dialogTypes from '../../../../dialogs/constants/dialog-types';
import iconFormats from '../constants/icon-formats';
import getIconPreviewUrl from '../helpers/get-icon-preview-url';
import getInlineImageBase64 from '../helpers/get-inline-image-base64';

const {
  hostingPackageFeatureNames: { PREMIUM_ICONS },
} = subscriptionConstants;

const {
  trackers: {
    trackSelectIconDialogDisplayed,
    trackSelectIconIconError,
    trackSelectIconStyleFilterSelected,
    trackSelectIconTypeFilterSelected,
    trackSelectIconErrorLoadingIcons,
    trackSelectIconErrorReloadClicked,
    trackDialogActiveTabChanged,
  },
} = segment;

/* eslint no-shadow: "off" */

const { ESC_KEYCODE, ENTER_KEYCODE, ICON_LIBRARIES_PREMIUM_TAB, ICON_LIBRARIES_FREE_TAB } =
  constants;
const {
  Tabs,
  TabsList,
  Tab,
  TabContent,
  TabsContentList,
  DialogButton,
  Heading,
  DialogButtonGroup,
  DialogButtonGroupSeparator,
} = designSystem;

const SelectIconDialog = ({
  onDialogMainAction,
  onSubmit,
  onDialogCancel,
  onDismiss,
  offsetX,
  offsetY,
  saveBtnText,
  cancelBtnText,
  onSearchClick,
  onIconSelect,
  onSearchPerform,
  originalIcon,
  originalIconDataId,
  featureFlags,
  iconColor,
  elementId,
}) => {
  const [selectedIcon, setSelectedIcon] = useState({});
  const [isButtonSaveActive, setIsButtonSaveActive] = useState(false);
  const [iconContainerHeight, setIconContainerHeight] = useState(null);
  const [iconContainerWidth, setIconContainerWidth] = useState(null);
  const [activeTabId, setActiveTabId] = useState(ICON_LIBRARIES_PREMIUM_TAB);
  const [isButtonSaveLoading, setIsButtonSaveLoading] = useState(false);
  const [hasIconProcessError, setHasIconProcessError] = useState(false);
  const iconModalRef = useRef(null);
  const iconContainerRef = useRef(null);
  const setIconContainerRef = useCallback((element) => {
    iconContainerRef.current = element;
  }, []);

  const containerHeight = bowser.mobile ? getHeight(iconContainerRef.current) : iconContainerHeight;
  const abortRequestControllerRef = useRef();

  const { icon8_integration: icon8Integration } = featureFlags;
  const shouldShowAdvancedIcons = icon8Integration && template.verifiers.isMpt();

  const handleDismiss = () => {
    const {
      state: { offsetX, offsetY },
    } = iconModalRef.current;

    abortRequestControllerRef.current?.abort();

    onDialogCancel();
    onDismiss({
      offsetX,
      offsetY,
      tabId: shouldShowAdvancedIcons ? activeTabId : null,
      premiumAvailable: isFeatureAvailable(PREMIUM_ICONS),
    });
  };

  const handleSubmit = async (isOverlayClick) => {
    const {
      state: { offsetX, offsetY },
    } = iconModalRef.current;

    let iconIdAttr = null;
    let iconSrc = selectedIcon.src;

    if (shouldShowAdvancedIcons && selectedIcon.params) {
      setIsButtonSaveLoading(true);
      iconIdAttr = selectedIcon.id;

      abortRequestControllerRef.current = new AbortController();

      iconSrc = await createIconAssetSrc({
        icon: selectedIcon,
        abortRequestController: abortRequestControllerRef.current,
        onError: () => {
          trackSelectIconIconError({
            dialogId: dialogTypes.SELECT_ICON,
            targetElementId: elementId,
            iconId: selectedIcon.id,
            isAnimated: Boolean(selectedIcon.isAnimated),
            isFree: selectedIcon.isFree || false,
            tabId: activeTabId,
            premiumAvailable: isFeatureAvailable(PREMIUM_ICONS),
          });
          setHasIconProcessError(true);
        },
      });
      setIsButtonSaveLoading(false);
    }

    onDialogMainAction();
    onSubmit({
      offsetX,
      offsetY,
      iconSrc,
      iconIdAttr,
      isOverlayClick,
      tabId: shouldShowAdvancedIcons ? activeTabId : null,
      premiumAvailable: isFeatureAvailable(PREMIUM_ICONS),
    });
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === ESC_KEYCODE) {
      handleDismiss();
    }
    if (e.keyCode === ENTER_KEYCODE) {
      handleSubmit();
    }
  };

  useEffect(() => {
    trackSelectIconDialogDisplayed({
      dialogId: dialogTypes.SELECT_ICON,
      targetElementId: elementId,
      tabId: shouldShowAdvancedIcons ? activeTabId : null,
      premiumAvailable: isFeatureAvailable(PREMIUM_ICONS),
    });

    document.addEventListener('keydown', handleKeyDown, false);

    return document.removeEventListener('keydown', handleKeyDown, false);
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  const onModalReady = (modalComponent) => {
    setIconContainerWidth(getWidth(modalComponent.modalRef.current));
  };

  const onModalResize = () => {
    const currentIconContainerHeight = getHeight(iconContainerRef.current);
    const currentIconContainerWidth = getWidth(iconModalRef.current.modalRef.current);

    if (iconContainerHeight !== currentIconContainerHeight) {
      setIconContainerHeight(currentIconContainerHeight);
    }

    if (iconContainerWidth !== currentIconContainerWidth) {
      setIconContainerWidth(currentIconContainerWidth);
    }
  };

  const handleOverlayClick = () => {
    if (selectedIcon.src) {
      handleSubmit(true);
    } else {
      handleDismiss();
    }
  };

  const selectIcon = async (icon, isAdvancedIcon = false) => {
    setSelectedIcon(icon);
    setHasIconProcessError(false);

    let newSrc = icon.src;

    if (isAdvancedIcon) {
      if (icon.isAnimated) {
        const params = {
          ...icon.params,
          format: icon.isAnimated ? iconFormats.GIF : iconFormats.PNG,
        };
        newSrc = getIconPreviewUrl(params);
      } else {
        try {
          newSrc = await getInlineImageBase64(icon.src);
        } catch {
          setHasIconProcessError(true);
        }
      }
    }

    onIconSelect({
      newSrc,
      tabId: shouldShowAdvancedIcons ? activeTabId : null,
      premiumAvailable: isFeatureAvailable(PREMIUM_ICONS),
    });
  };

  const onTabChange = (tabId) => {
    setActiveTabId(tabId);
    trackDialogActiveTabChanged({ dialogId: dialogTypes.SELECT_ICON, tabId });
  };

  const freeTabContent = (
    <BasicIcons
      originalIconName={getIconName(originalIcon)}
      containerHeight={containerHeight}
      containerWidth={iconContainerWidth}
      onSearchClick={() => {
        onSearchClick({
          tabId: shouldShowAdvancedIcons ? activeTabId : null,
          premiumAvailable: isFeatureAvailable(PREMIUM_ICONS),
        });
      }}
      selectedIcon={selectedIcon}
      setSelectedIcon={setSelectedIcon}
      selectIcon={selectIcon}
      onSearchPerform={({ searchTerm, searchResults }) => {
        onSearchPerform({
          searchTerm,
          searchResults,
          tabId: shouldShowAdvancedIcons ? activeTabId : null,
          premiumAvailable: isFeatureAvailable(PREMIUM_ICONS),
        });
      }}
      setIsButtonSaveActive={setIsButtonSaveActive}
    />
  );

  return (
    <Modal
      draggable
      modalRef={iconModalRef}
      minWidth={constants.DIALOG_MIN_WIDTH}
      width={constants.DIALOG_MIN_WIDTH}
      maxWidth={constants.DIALOG_MAX_WIDTH}
      height={constants.DIALOG_HEIGHT}
      maxHeight={constants.DIALOG_MAX_HEIGHT}
      minHeight={constants.DIALOG_MIN_HEIGHT}
      offsetX={offsetX}
      offsetY={offsetY / 2}
      onResize={onModalResize}
      onReady={() => onModalReady(iconModalRef.current)}
      dragHandleSelector=".ws-drag-trigger"
      fullscreen={bowser.mobile}
      onOverlayClick={handleOverlayClick}
      handleCancel={handleDismiss}
      handleSubmit={handleSubmit}
      floatingModalElementSelector=".product-select-container, .dialog--overlay-controls-container"
    >
      <PanelGroup width="100%" height="100%" className="ws-icon-modal">
        <Panel
          theme="gray-100"
          corners="squared"
          align="middle"
          style={{ flexShrink: '0' }}
          height={48}
          className={classNames(
            'ws-drag-trigger',
            'ws-modal__content',
            'ws-icon-modal__top-panel',
            {
              'ws-icon-modal__top-panel--with-tabs': shouldShowAdvancedIcons,
            }
          )}
        >
          <Heading type="heading-6">
            {shouldShowAdvancedIcons ? i18next.t('Icon libraries') : i18next.t('Icon settings')}
          </Heading>
        </Panel>
        <Panel
          theme="white"
          corners="squared"
          height="calc(100% - 48px * 2)"
          className="ws-icon-modal__content"
          rootRef={shouldShowAdvancedIcons ? null : setIconContainerRef}
          scrollable
        >
          {shouldShowAdvancedIcons && (
            <Tabs
              activeTabId={activeTabId}
              onTabChange={onTabChange}
              noSideSpacing
              noVerticalSpacing
            >
              <TabsList variant="fullWidth">
                <Tab
                  tabId={ICON_LIBRARIES_PREMIUM_TAB}
                  label={i18next.t('PREMIUM')}
                  disabled={isButtonSaveLoading}
                />
                <Tab
                  tabId={ICON_LIBRARIES_FREE_TAB}
                  label={i18next.t('FREE')}
                  disabled={isButtonSaveLoading}
                />
              </TabsList>
              <div
                style={{ height: 'calc(100% - 40px)', maxWidth: `${iconContainerWidth}px` }}
                ref={setIconContainerRef}
              >
                <TabsContentList>
                  <TabContent tabId={ICON_LIBRARIES_PREMIUM_TAB} noSideSpacing>
                    <AdvancedIcons
                      iconColor={iconColor}
                      originalIconDataId={originalIconDataId}
                      containerHeight={containerHeight}
                      containerWidth={iconContainerWidth}
                      onSearchClick={() => {
                        onSearchClick({
                          tabId: activeTabId,
                          premiumAvailable: isFeatureAvailable(PREMIUM_ICONS),
                        });
                      }}
                      onSearchPerform={({ searchTerm, searchResults }) => {
                        onSearchPerform({
                          searchTerm,
                          searchResults,
                          tabId: activeTabId,
                          premiumAvailable: isFeatureAvailable(PREMIUM_ICONS),
                        });
                      }}
                      onStyleFilterSelected={({ styleTypeOld, styleTypeNew }) => {
                        trackSelectIconStyleFilterSelected({
                          styleTypeOld,
                          styleTypeNew,
                          targetElementId: elementId,
                          tabId: activeTabId,
                          dialogId: dialogTypes.SELECT_ICON,
                          premiumAvailable: isFeatureAvailable(PREMIUM_ICONS),
                        });
                      }}
                      onTypeFilterSelected={({ oldTypeId, newTypeId }) => {
                        trackSelectIconTypeFilterSelected({
                          oldTypeId,
                          newTypeId,
                          targetElementId: elementId,
                          tabId: activeTabId,
                          dialogId: dialogTypes.SELECT_ICON,
                          premiumAvailable: isFeatureAvailable(PREMIUM_ICONS),
                        });
                      }}
                      onIconsLoadingError={() => {
                        trackSelectIconErrorLoadingIcons({
                          targetElementId: elementId,
                          tabId: activeTabId,
                          dialogId: dialogTypes.SELECT_ICON,
                          premiumAvailable: isFeatureAvailable(PREMIUM_ICONS),
                        });
                      }}
                      onReloadRequestClick={() => {
                        trackSelectIconErrorReloadClicked({
                          targetElementId: elementId,
                          tabId: activeTabId,
                          dialogId: dialogTypes.SELECT_ICON,
                          premiumAvailable: isFeatureAvailable(PREMIUM_ICONS),
                        });
                      }}
                      selectedIcon={selectedIcon}
                      setSelectedIcon={setSelectedIcon}
                      selectIcon={(icon) => {
                        selectIcon(icon, true);
                      }}
                      hasIconProcessError={hasIconProcessError}
                      setIsButtonSaveActive={setIsButtonSaveActive}
                    />
                  </TabContent>
                  <TabContent tabId={ICON_LIBRARIES_FREE_TAB} noSideSpacing>
                    {freeTabContent}
                  </TabContent>
                </TabsContentList>
              </div>
            </Tabs>
          )}

          {!shouldShowAdvancedIcons && freeTabContent}
        </Panel>

        <Panel theme="white" corners="squared">
          <DialogButtonGroup>
            <DialogButton
              isLoading={isButtonSaveLoading}
              onClick={handleSubmit}
              disabled={!isButtonSaveActive}
            >
              {saveBtnText}
            </DialogButton>
            <DialogButtonGroupSeparator />
            <DialogButton onClick={handleDismiss}>{cancelBtnText}</DialogButton>
          </DialogButtonGroup>
        </Panel>
      </PanelGroup>
    </Modal>
  );
};

SelectIconDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onIconSelect: PropTypes.func.isRequired,
  offsetX: PropTypes.number.isRequired,
  offsetY: PropTypes.number.isRequired,
  saveBtnText: PropTypes.string.isRequired,
  cancelBtnText: PropTypes.string.isRequired,
  originalIcon: PropTypes.string.isRequired,
  onSearchClick: PropTypes.func,
  onSearchPerform: PropTypes.func,
  iconColor: PropTypes.string,
  featureFlags: PropTypes.shape({
    icon8_integration: PropTypes.bool,
  }).isRequired,
  // Passed from ws-editor's `dialogs-container`:
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
  originalIconDataId: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  elementId: PropTypes.string,
};

SelectIconDialog.defaultProps = {
  onSearchClick: null,
  onSearchPerform: null,
  iconColor: '',
  originalIconDataId: null,
  elementId: null,
};

export default withFeatureFlags(['icon8_integration'])(SelectIconDialog);
