import segment from 'src/js/modules/analytics/segment';

const {
  constants: { events, common },
  trackAsync,
} = segment;

const onSwitchTemplateTrack = ({ siteId, templateBuildSlug, dialogType, sourceId }) =>
  trackAsync(events.DESIGN_SETTINGS_SWITCH_TEMPLATE_BUTTON_CLICKED, {
    appName: common.APP_NAME,
    templateBuildSlug,
    siteId,
    dialogId: dialogType,
    sourceId,
  });

export default onSwitchTemplateTrack;
