export default {
  id: 'columns',
  priority: 1,
  settings: {
    parentSelector: 'ws-block[src*="/custom/"]',
    querySelector: 'ws-columns',
    columnQuerySelector: 'ws-column',
  },
  matches(element, config) {
    const { querySelector, columnQuerySelector, parentSelector } = config;
    return (
      element.matches(`${querySelector}, ${columnQuerySelector}`) && element.closest(parentSelector)
    );
  },

  getTargetElement(element, config) {
    const { querySelector, columnQuerySelector } = config;
    if (element.matches(columnQuerySelector)) {
      return element.closest(querySelector);
    }

    return element;
  },

  tabs: [
    {
      id: 'appearance',
      accordionTitle: 'All columns',
      options: [
        {
          id: 'border',
          type: 'toggle',
          title: 'Border',
          querySelector: 'ws-column',
          attribute: 'border',
        },
        {
          id: 'shadow',
          type: 'toggle',
          title: 'Shadow',
          querySelector: 'ws-column',
          attribute: 'shadow',
        },
      ],
    },
  ],
};
