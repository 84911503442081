import isGalleryItem from 'src/js/modules/focal-point/helpers/is-gallery-item';
import isPlaceholder from 'src/js/modules/extensions/registry/ws-image/helpers/is-placeholder';

const getDataFromMediaNode = (mediaElement) => {
  const imageElement = mediaElement.querySelector('img');

  const imageData =
    mediaElement && imageElement
      ? {
          alt: imageElement.getAttribute('alt') || '',
          src: imageElement.getAttribute('src'),
          aspectRatio: mediaElement.getAttribute('aspect-ratio'),
          contentPosition: mediaElement.getAttribute('content-position'),
          contentScale: mediaElement.getAttribute('content-scale'),
          mediaGroupId: mediaElement.getAttribute('media-group-id'),
          isPlaceholder: isPlaceholder(mediaElement),
        }
      : {};

  const galleryData =
    mediaElement && isGalleryItem(mediaElement)
      ? {
          originSrc: mediaElement.getAttribute('origin-src'),
          originWidth: mediaElement.getAttribute('origin-width'),
          originHeight: mediaElement.getAttribute('origin-height'),
          title: imageElement.getAttribute('data-title') || '',
          description: imageElement.getAttribute('data-description') || '',
          lightboxDisabled: mediaElement.hasAttribute('lightbox-disabled'),
        }
      : {};

  return {
    ...imageData,
    ...galleryData,
  };
};

export default getDataFromMediaNode;
