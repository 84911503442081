import isTimestampFormat from './is-timestamp-format';
import convertTimestampToISO from './convert-timestamp-to-iso';
import isISOFormat from './is-iso-format';
import convertISOToZuluTime from './covert-iso-to-zulu-time';

const getDateObjectFrom = (value) => {
  if (value instanceof Date) {
    return value;
  }

  let date = value;

  if (isTimestampFormat(date)) {
    date = convertTimestampToISO(date);
  }

  if (isISOFormat(date)) {
    date = convertISOToZuluTime(date);
  }

  return new Date(date);
};

export default getDateObjectFrom;
