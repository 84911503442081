import ai from 'src/js/modules/ai';

const {
  constants: { recipeIds },
} = ai;

export default {
  EMPTY_PAGE: 'empty_page',
  HOME: recipeIds.HOME_BUSINESS,
  ABOUT_US: recipeIds.ABOUT_US,
  SERVICES: recipeIds.SERVICES,
  OUR_WORK: recipeIds.OUR_WORK,
  PRODUCTS: recipeIds.PRODUCTS,
  CONTACT_US: recipeIds.CONTACT_US,
  MENU: recipeIds.MENU,
  TESTIMONIALS: recipeIds.TESTIMONIALS,
  FAQ: recipeIds.FAQ,
};
