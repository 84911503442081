import { assets } from '@yola/ws-sdk';
import imageTypes from 'src/js/modules/extensions/common/constants/image-types';
import calculateThumbnailSize from './calculate-thumbnail-size';
import constants from '../constants/common';

const { PIXEL_CROP_SETTINGS } = constants;

const generateImageThumbnailSource = async ({
  assetSource,
  assetName,
  assetType,
  imageSize,
  imageContainerSize,
  abortRequestController,
  isStockPhoto = false,
}) => {
  const { width: imageWidth } = imageSize;
  const { width: imageContainerWidth } = imageContainerSize;

  if (
    isStockPhoto ||
    (!(assetType && assetType === imageTypes.IMAGE_TYPE_GIF) &&
      !(assetType && assetType === imageTypes.IMAGE_TYPE_SVG) &&
      !(imageWidth <= Math.floor(imageContainerWidth) * 2))
  ) {
    try {
      const thumbnailSize = calculateThumbnailSize(imageSize, imageContainerSize);
      const { x, y } = PIXEL_CROP_SETTINGS;

      const cropData = {
        originSource: assetSource,
        resultSource: assets.helpers.getNewAssetSrc(assetName, assetSource, thumbnailSize),
        cropArea: [x, y, imageSize.width, imageSize.height],
        size: [thumbnailSize.width, thumbnailSize.height],
      };
      const cropConfig = abortRequestController
        ? {
            signal: abortRequestController.signal,
          }
        : {};

      const response = await assets.operations.makeCroppedAndResizedAssetCopy(cropData, cropConfig);
      const { source: thumbnailSource } = response.data;

      // we need to pre-load image to omit blinking
      const thumbnailUrl = assets.helpers.addBaseHref(thumbnailSource);
      await assets.helpers.getImageSize(thumbnailUrl);

      assets.operations.makePublishable(thumbnailSource);

      return thumbnailSource;
    } catch (error) {
      throw error;
    }
  }

  return assetSource;
};

export default generateImageThumbnailSource;
