import onlinestore from 'src/js/modules/onlinestore';
import { dialogs, integration, site, template, view } from '@yola/ws-sdk';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';

const handleStoreInterruption = (store, next, action) => {
  const state = store.getState();
  const upsells = integration.selectors.getUpsells(state);
  const { onUpgrade, captions, premiumFeature, purchaseFlowType } = upsells.onlineStore;

  store.dispatch(
    dialogs.actions.show(dialogTypes.UPGRADE, {
      purchaseFlowType,
      premiumFeature,
      captions,
      onUpgrade,
      onUpgradeResolve(event) {
        if (!event || !event.data || !event.data.willRedirect) {
          next(action);
        }
      },
    })
  );
};

const onlineStoreInterrupter = (store) => (next) => (action) => {
  const state = store.getState();

  if (
    action.type === onlinestore.actionTypes.REDIRECT_TO_STORE ||
    (action.type === view.actionTypes.INSERT_BLOCK &&
      action.payload.blockId === onlinestore.constants.ids.ONLINE_STORE_BLOCK)
  ) {
    const onlineStoreLimits = onlinestore.selectors.getOnlineStoreLimit(state);
    if (onlineStoreLimits.available && !onlineStoreLimits.active) {
      handleStoreInterruption(store, next, action);
      return;
    }
  }

  if (action.type === site.actionTypes.CREATE_PAGE_FROM_TEMPLATE) {
    const { pageTemplateId, resolve, reject } = action.payload;
    const onlineStoreLimits = onlinestore.selectors.getOnlineStoreLimit(state);
    if (!onlineStoreLimits.active && pageTemplateId === template.constants.SHOP_PAGE_TEMPLATE) {
      handleStoreInterruption(store, next, action);
      reject();
      return;
    }
    resolve();
  }

  next(action);
};

export default onlineStoreInterrupter;
