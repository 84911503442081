import actionTypes from '../constants/action-types';

const addUserModifiedAttribute = (elementId, attribute) => ({
  type: actionTypes.ADD_USER_MODIFIED_ATTRIBUTE,
  payload: {
    elementId,
    attribute,
  },
});

export default addUserModifiedAttribute;
