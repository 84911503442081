import { template } from '@yola/ws-sdk';
import getContrastAdaptedColors from '../get-contrast-adapted-colors';
import sortSurfaces from '../sort-surfaces';

const createSurfaceOption = ({
  item,
  colorPalette,
  blockNode,
  elementId,
  displayOptions,
  onClick,
}) => {
  if (!colorPalette || !colorPalette.colors) return null;

  const existedOptions = displayOptions && displayOptions.find((option) => option.id === item.id);
  const liveInitialValue = blockNode.getAttribute(item.attribute);
  const initialValue = existedOptions ? existedOptions.initialValue : liveInitialValue;

  return {
    ...item,
    elementId,
    initialValue,
    onClick,
    colors: getContrastAdaptedColors(colorPalette.colors),
    options: sortSurfaces(item.options),
    value: liveInitialValue,
    title: template.accessors.getMetaTranslations(item.title),
    subtitle: template.accessors.getMetaTranslations(item.subtitle),
  };
};

export default createSurfaceOption;
