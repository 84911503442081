import { i18next, view, clipboard, blocks, dialogs, hdrm } from '@yola/ws-sdk';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import contextMenu from 'src/js/modules/context-menu';
import canPasteBlock from '../verifiers/can-paste-block';
import getRootBlockByElement from '../helpers/get-root-block-by-element';
import { CONTEXT_MENU_GROUP_NAME } from '../constants/common';
import {
  COPY_BLOCK,
  CUT_BLOCK,
  PASTE_BEFORE_THIS_BLOCK,
  PASTE_AFTER_THIS_BLOCK,
} from '../constants/context-menu-block-items';

const { updateStrategies } = hdrm.constants;

const showPasteWarning = (targetElementId, position) => {
  const { element } = clipboard.accessors.getClipboardData();
  const elementBlockId = blocks.accessors.getBlockIdByElement(element);

  const redundantElements = blocks.helpers.getElementsToBeReplaced(elementBlockId);

  // We try to cover case when there is more then one redundant block
  if (redundantElements.length > 1) {
    dialogs.operations.show(dialogTypes.BLOCK_LIMIT_DIALOG, {
      onSubmit: () => {
        dialogs.operations.hide();
      },
      blockId: elementBlockId,
    });
    return;
  }

  const [oldBlockElement] = redundantElements;
  const elementIdToDelete = view.accessors.getLiveElementId(oldBlockElement);
  const oldBlockId = blocks.accessors.getBlockIdByElement(oldBlockElement);
  const oldVariationId = blocks.accessors.getVariationIdByElement(oldBlockElement);
  const newVariationId = blocks.accessors.getVariationIdByElement(element);

  const deleteOperation = [view.operations.deleteElement, [elementIdToDelete]];
  const pasteOperation = [view.operations.pasteElement, [targetElementId, position]];

  dialogs.operations.show(dialogTypes.BLOCK_REPLACEMENT_DIALOG, {
    onSubmit: () => {
      view.operations.bulkViewOperations([deleteOperation, pasteOperation]);
      dialogs.operations.hide();
    },
    onCancel: () => {
      dialogs.operations.hide();
    },
    oldBlock: oldBlockId,
    oldBlockVariationId: oldVariationId,
    newBlock: elementBlockId,
    newBlockVariationId: newVariationId,
  });
};

const pasteBlock = (element, position) => {
  const elementId = view.accessors.getLiveElementId(element);
  if (canPasteBlock(element.parentNode)) {
    view.operations.pasteElement(elementId, position);
    return;
  }
  showPasteWarning(elementId, position);
};

export const registerContextMenuBlockItems = () => {
  const itemsGroup = {
    groupName: CONTEXT_MENU_GROUP_NAME,
    matches: (element) => Boolean(getRootBlockByElement(element)),
    prepareElement: (element) => getRootBlockByElement(element),
    items: [
      {
        id: COPY_BLOCK,
        caption: i18next.t('Copy block'),
        onClick(element) {
          const elementId = view.accessors.getLiveElementId(getRootBlockByElement(element));
          view.operations.copyElement(elementId);
          contextMenu.operations.hideContextMenu();
        },
      },
      {
        id: CUT_BLOCK,
        caption: i18next.t('Cut block'),
        onClick(element) {
          const elementId = view.accessors.getLiveElementId(getRootBlockByElement(element));
          const options = { strategy: updateStrategies.UPDATE_STATIC_ONLY };
          view.operations.cutElement(elementId, options);
          contextMenu.operations.hideContextMenu();
        },
      },
      {
        id: PASTE_BEFORE_THIS_BLOCK,
        caption: i18next.t('Paste before this block'),
        disabled: () => !clipboard.verifiers.hasClipboardElementData(),
        onClick(element) {
          pasteBlock(getRootBlockByElement(element), 'beforebegin');
          contextMenu.operations.hideContextMenu();
        },
      },
      {
        id: PASTE_AFTER_THIS_BLOCK,
        caption: i18next.t('Paste after this block'),
        disabled: () => !clipboard.verifiers.hasClipboardElementData(),
        onClick(element) {
          pasteBlock(getRootBlockByElement(element), 'afterend');
          contextMenu.operations.hideContextMenu();
        },
      },
    ],
  };

  contextMenu.operations.registerItemsGroup(itemsGroup);
};
