const isFilled = (settingForUpdate, initialSetting) =>
  settingForUpdate ? !!settingForUpdate?.length : !!initialSetting?.length;

const prepareDialogSubmittedEventTraits = (
  initialValues,
  settingsForUpdate,
  integrationsForUpdate
) => {
  if (!initialValues || !settingsForUpdate || !integrationsForUpdate) {
    return null;
  }

  return {
    faviconSet: Boolean(settingsForUpdate.favicon || initialValues.favicon),
    faviconChanged: Boolean(settingsForUpdate.favicon),
    nativeCookieBannerEnabled: Boolean(
      settingsForUpdate.isCookieBannerEnabled || initialValues.isCookieBannerEnabled
    ),
    preventIndexingEnabled: Boolean(
      settingsForUpdate.preventIndexing || initialValues.preventIndexing
    ),
    businessNameFilled: isFilled(settingsForUpdate.businessName, initialValues.businessName),
    businessNameChanged: Boolean(settingsForUpdate.businessName),
    businessDescriptionFilled: isFilled(
      settingsForUpdate.businessDescription,
      initialValues.businessDescription
    ),

    businessDescriptionChanged: Boolean(settingsForUpdate.businessDescription),
    businessCategoryFilled: isFilled(
      settingsForUpdate.businessCategory,
      initialValues.businessCategory
    ),
    businessCategoryChanged: Boolean(settingsForUpdate.businessCategory),
    googleAnalitycsEnabled: Boolean(
      integrationsForUpdate.googleAnalyticsId || initialValues.googleAnalyticsId
    ),
    googleSearchConsoleEnabled: Boolean(
      integrationsForUpdate.googleWebmasterCode || initialValues.googleWebmasterCode
    ),
    cookieBotEnabled: Boolean(integrationsForUpdate.cookiebotId || initialValues.cookiebotId),
    headerCodeFilled: isFilled(settingsForUpdate.headCode, initialValues.headCode),
    beforeBodyCodeFilled: isFilled(settingsForUpdate.headerCode, initialValues.headerCode),
    afterBodyCodeFilled: isFilled(settingsForUpdate.footerCode, initialValues.footerCode),
    businessAddressFilled: isFilled(settingsForUpdate.addresses, initialValues.addresses),
    businessAddressChanged: Boolean(settingsForUpdate.addresses),
    businessEmailFilled: isFilled(settingsForUpdate.emails, initialValues.emails),
    businessEmailChanged: Boolean(settingsForUpdate.emails),
    businessPhoneFilled: isFilled(settingsForUpdate.phones, initialValues.phones),
    businessPhoneChanged: Boolean(settingsForUpdate.phones),
    workingHoursFilled: isFilled(settingsForUpdate.businessHours, initialValues.businessHours),
    workingHoursChanged: Boolean(settingsForUpdate.businessHours),
  };
};

export default prepareDialogSubmittedEventTraits;
