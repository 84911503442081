import { extensions } from '@yola/ws-sdk';
import common from '../constants/common';

function getTagName() {
  const settings = extensions.accessors.getExtensionSettings(common.slug);

  return settings.tagName;
}

export default getTagName;
