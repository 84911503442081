import getOffsetTop from './get-offset-top';
import getOffsetLeft from './get-offset-left';
import { TEXT_RESIZE_HANDLER_SIZE } from '../constants/common';

const getHandlerStyles = ({ elementRect = {}, scrollPosition, elementSidePosition, indent }) => ({
  top: getOffsetTop(elementRect, scrollPosition, indent),
  left: getOffsetLeft(elementRect, elementSidePosition, indent),
  width: `${TEXT_RESIZE_HANDLER_SIZE}px`,
  height: `calc(${elementRect.height}px + 2 * ${indent}px)`,
});

export default getHandlerStyles;
