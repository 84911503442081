import { view, anodum } from '@yola/ws-sdk';
import isCustomBlock from '../../../blocks/verifiers/is-custom-block';
import getClosestBlockElement from '../../../common/helpers/get-closest-block-element';

// The `source` trait - informs about the source of the
// opened icon-select dialog. Can have the next values:
// - 'social-links' - all dialogs triggered from link settings.
// - 'custom-block-list' - all dialogs triggered from within custom block
//   (both icon widget itself, and icons from another widgets).
// - 'feature' - all other dialogs triggered from within regular blocks.
export default function getIconSourceTrait(targetElementId) {
  const SOCIAL_ICONS_SELECTOR = 'ws-social-icons';
  const iconSourceElement = view.accessors.getLiveElement(targetElementId);

  if (anodum.isTag(iconSourceElement, SOCIAL_ICONS_SELECTOR)) return 'social-links';

  const targetBlockElement = getClosestBlockElement(iconSourceElement);
  if (isCustomBlock(targetBlockElement)) return 'custom-block-list';

  return 'feature';
}
