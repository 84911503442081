import { anodum, view } from '@yola/ws-sdk';
import constants from '../constants';

const { isTag, isOneOfTags } = anodum;
const { LIST_TYPE_BULLET_TAG, LIST_TYPE_NUMBER_TAG, LIST_ITEM_TAG } = constants.listFormatting;

const wrapElementsIntoList = (listType, listElements, elements) => {
  const liveDocument = view.accessors.getLiveDocument();
  const list = liveDocument.createElement(listType);
  const lastElement = elements.filter((el) => !isTag(el, LIST_ITEM_TAG)).pop();
  const parentElement = lastElement.parentNode;
  let referenceElement = lastElement.nextSibling ? lastElement.nextSibling : null;

  elements.forEach((element) => {
    if (isOneOfTags(element, [LIST_TYPE_BULLET_TAG, LIST_TYPE_NUMBER_TAG])) return;
    if (isTag(element, LIST_ITEM_TAG)) {
      list.appendChild(element);
    } else {
      const li = liveDocument.createElement(LIST_ITEM_TAG);
      li.appendChild(element);
      list.appendChild(li);
    }
  });

  listElements.forEach((item) => {
    if (item.children.length === 0) {
      item.remove();
    } else if (item === lastElement) {
      referenceElement = lastElement;
    }
  });

  if (referenceElement) {
    parentElement.insertBefore(list, referenceElement);
  } else {
    parentElement.appendChild(list);
  }

  return list;
};

export default wrapElementsIntoList;
