import getCookie from 'yola-editor/src/js/utils/get-cookie';

const AJS_USER_ID_COOKIE = 'ajs_user_id';
const AJS_USER_ID_LOCAL_STORAGE_ITEM = 'ajs_user_id';
let wasTraqingSegmentIdentified = false;

const traqingSegmentIdentified = () => {
  wasTraqingSegmentIdentified = true;
};

const yolaDebuggingIntegration = (data) => {
  const ajsUserIdCookie = getCookie(AJS_USER_ID_COOKIE);
  const ajsUserIdLocalStorageItem = localStorage.getItem(AJS_USER_ID_LOCAL_STORAGE_ITEM);

  return Object.assign(data, {
    yolaDebugging: {
      ajsUserIdCookie,
      ajsUserIdLocalStorageItem,
      wasTraqingSegmentIdentified,
    },
  });
};

export { traqingSegmentIdentified };
export default yolaDebuggingIntegration;
