import { HSLColor, generateAccentPalette } from '@yola/yola-palette-generator';
import { rules } from '@yola/ws-color-rules';
import parseHslColorString from './parse-hsl-color-string';
import getAdvancedColorsKeys from './get-advanced-colors-keys';
import getColorFromVariables from './get-color-from-variables';
import getSecondaryButtonsAdvancedColorsKeys from './get-secondary-buttons-advanced-colors-keys';
import getPrimaryButtonsAdvancedColorsKeys from './get-primary-buttons-advanced-colors-keys';
import advancedColorKeyToVariableKey from './advanced-color-key-to-variable-key';
import constants from '../constant';

const createHslColorString = (hslColor) => `hsl(${hslColor.toColorString()})`;
const createHslaColorString = (hslaColor) => hslaColor.toCSSValue();

const generateTextColors = ({ colorKey, targetColor, opacity }) => ({
  [colorKey]: createHslColorString(targetColor),
  [`${colorKey}Opacity`]: `hsla(${targetColor.toColorString()}, ${opacity})`,
});

const generateLinkTextColors = ({
  targetColor,
  targetColorPalette,
  whiteColor,
  blackColor,
  colorKey,
}) => ({
  [colorKey]: createHslaColorString(targetColor),
  [`${colorKey}Hover`]: createHslaColorString(
    rules.link.hover.custom({ targetColor, targetColorPalette, whiteColor, blackColor })
  ),
});

const generateNavLinkColors = ({
  targetColor,
  targetColorPalette,
  whiteColor,
  blackColor,
  colorKey,
}) => ({
  [colorKey]: createHslColorString(targetColor),
  [`${colorKey}Hover`]: createHslColorString(
    rules.link.hover.custom({ targetColor, targetColorPalette, whiteColor, blackColor })
  ),
});

const generateFooterNavigationLinkTextColors = ({
  targetColor,
  targetColorPalette,
  whiteColor,
  blackColor,
  colorKey,
}) => ({
  [colorKey]: createHslColorString(targetColor),
  [`${colorKey}Hover`]: createHslColorString(
    rules.link.hover.custom({ targetColor, targetColorPalette, whiteColor, blackColor })
  ),
});

const generateSocialLinkColors = ({
  targetColor,
  targetColorPalette,
  whiteColor,
  blackColor,
  colorKey,
}) => ({
  [colorKey]: createHslColorString(targetColor),
  [`${colorKey}Hover`]: createHslColorString(
    rules.icon.social.hover.custom({ targetColor, targetColorPalette, whiteColor, blackColor })
  ),
});

const generatePrimaryButtonBackgroundColors = ({
  targetColor,
  targetColorPalette,
  whiteColor,
  blackColor,
  backgroundColor,
  colorKey,
}) =>
  getPrimaryButtonsAdvancedColorsKeys(colorKey).reduce(
    (acc, primaryButtonKey) => ({
      ...acc,
      ...{
        [primaryButtonKey]: createHslaColorString(targetColor),
        [`${primaryButtonKey}Hover`]: createHslaColorString(
          rules.primaryButton.brandedSolid.background.hover.custom({
            targetColor,
            targetColorPalette,
            whiteColor,
            blackColor,
            backgroundColor,
          })
        ),
        [`${primaryButtonKey}Active`]: createHslaColorString(
          rules.primaryButton.brandedSolid.background.active.custom({
            targetColor,
            targetColorPalette,
            whiteColor,
            blackColor,
            backgroundColor,
          })
        ),
        [`${primaryButtonKey}Disabled`]: createHslaColorString(
          rules.primaryButton.brandedSolid.background.disabled.custom({
            targetColor,
            backgroundColor,
          })
        ),
      },
    }),
    {}
  );

const generatePrimaryButtonsTextColors = ({
  targetColor,
  backgroundColor,
  editPaletteVariables,
  buttonBackgroundColorKey,
  colorKey,
}) => {
  const backgroundColorString = `hsl(${getColorFromVariables(
    advancedColorKeyToVariableKey(buttonBackgroundColorKey),
    editPaletteVariables
  )})`;
  const { h, s, l } = parseHslColorString(backgroundColorString);
  const disabledBackgroundColor = rules.primaryButton.brandedSolid.background.disabled.custom({
    targetColor: new HSLColor(h, s, l),
    backgroundColor,
  });
  return getPrimaryButtonsAdvancedColorsKeys(colorKey).reduce(
    (acc, primaryButtonKey) => ({
      ...acc,
      ...{
        [primaryButtonKey]: createHslaColorString(targetColor),
        [`${primaryButtonKey}Hover`]: createHslaColorString(targetColor),
        [`${primaryButtonKey}Active`]: createHslaColorString(targetColor),
        [`${primaryButtonKey}Disabled`]: createHslaColorString(
          rules.primaryButton.brandedSolid.text.disabled.custom({
            targetColor,
            backgroundColor: disabledBackgroundColor,
          })
        ),
      },
    }),
    {}
  );
};

const generateSecondaryButtonsBackgroundColors = ({ targetColor, backgroundColor, colorKey }) =>
  // We need to generate background color for all 5 secondary buttons
  getSecondaryButtonsAdvancedColorsKeys(colorKey).reduce(
    (acc, secondaryButtonKey) => ({
      ...acc,
      ...{
        [secondaryButtonKey]: createHslaColorString(targetColor),
        [`${secondaryButtonKey}Hover`]: createHslaColorString(
          rules.secondaryButton.lightGray.background.hover.custom({ targetColor })
        ),
        [`${secondaryButtonKey}Active`]: createHslaColorString(
          rules.secondaryButton.lightGray.background.active.custom({ targetColor })
        ),
        [`${secondaryButtonKey}Disabled`]: createHslaColorString(
          rules.secondaryButton.lightGray.background.disabled.custom({
            targetColor,
            backgroundColor,
          })
        ),
      },
    }),
    {}
  );

const generateSecondaryButtonsTextColors = ({
  targetColor,
  backgroundColor,
  editPaletteVariables,
  buttonBackgroundColorKey,
  colorKey,
}) => {
  const backgroundColorString = `hsl(${getColorFromVariables(
    advancedColorKeyToVariableKey(buttonBackgroundColorKey),
    editPaletteVariables
  )})`;
  const { h, s, l } = parseHslColorString(backgroundColorString);
  const disabledBackgroundColor = rules.secondaryButton.lightGray.background.disabled.custom({
    targetColor: new HSLColor(h, s, l),
    backgroundColor,
  });

  // We need to generate text color for all 5 secondary buttons
  return getSecondaryButtonsAdvancedColorsKeys(colorKey).reduce(
    (acc, secondaryButtonKey) => ({
      ...acc,
      ...{
        [secondaryButtonKey]: createHslaColorString(targetColor),
        [`${secondaryButtonKey}Hover`]: createHslaColorString(targetColor),
        [`${secondaryButtonKey}Active`]: createHslaColorString(targetColor),
        [`${secondaryButtonKey}Disabled`]: createHslaColorString(
          rules.secondaryButton.lightGray.text.disabled.custom({
            targetColor,
            backgroundColor: disabledBackgroundColor,
          })
        ),
      },
    }),
    {}
  );
};

const generateInputBorderColors = ({ targetColor, backgroundColor, colorKey, secondColorKey }) => ({
  [colorKey]: createHslaColorString(targetColor),
  [`${colorKey}Disabled`]: createHslaColorString(
    rules.input.borderColor.disabled.custom({
      targetColor,
      backgroundColor,
    })
  ),
  [secondColorKey]: createHslaColorString(targetColor),
  [`${secondColorKey}Disabled`]: createHslaColorString(
    rules.checkbox.switchedOff.borderColor.disabled.custom({
      targetColor,
      backgroundColor,
    })
  ),
});

const generateAdvancedColors = (
  { h, s, l, a },
  colorKey,
  backgroundColorKey,
  backgroundColorString,
  editPaletteVariables
) => {
  // randomly ColorPicker return `a` as NaN, to prevent crash we setup fallback value
  const opacity = a || constants.MAX_OPACITY_VALUE;
  const {
    h: backgroundH,
    s: backgroundS,
    l: backgroundL,
  } = parseHslColorString(backgroundColorString);

  const whiteColor = new HSLColor(0, 0, 1);
  const blackColor = new HSLColor(0, 0, 0);
  const targetColor = new HSLColor(h, s, l);
  const targetHslaColor = new HSLColor(h, s, l, opacity);
  const backgroundColor = new HSLColor(backgroundH, backgroundS, backgroundL);

  const targetColorPalette = generateAccentPalette(targetColor);
  const advancedColorsKeys = getAdvancedColorsKeys(backgroundColorKey);

  switch (colorKey) {
    case advancedColorsKeys.wsSiteName:
    case advancedColorsKeys.wsHeading1:
    case advancedColorsKeys.wsHeading2:
    case advancedColorsKeys.wsHeading3:
    case advancedColorsKeys.wsHeading4:
    case advancedColorsKeys.wsHeading5:
    case advancedColorsKeys.wsHeading6:
    case advancedColorsKeys.wsParagraph:
    case advancedColorsKeys.wsBlockquote:
      return generateTextColors({ colorKey, targetColor, opacity });
    case advancedColorsKeys.wsLinkText:
      return generateLinkTextColors({
        targetColor: targetHslaColor,
        targetColorPalette,
        whiteColor,
        blackColor,
        colorKey,
      });
    case advancedColorsKeys.wsNavLink:
      return generateNavLinkColors({
        targetColor,
        targetColorPalette,
        whiteColor,
        blackColor,
        colorKey,
      });
    case advancedColorsKeys.wsFooterNavLinkText:
      return generateFooterNavigationLinkTextColors({
        targetColor,
        targetColorPalette,
        whiteColor,
        blackColor,
        colorKey,
      });
    case advancedColorsKeys.wsSocialIcon:
      return generateSocialLinkColors({
        targetColor,
        targetColorPalette,
        whiteColor,
        blackColor,
        colorKey,
      });
    case advancedColorsKeys.wsPrimaryButtonBackground:
      return generatePrimaryButtonBackgroundColors({
        targetColor: targetHslaColor,
        targetColorPalette,
        whiteColor,
        blackColor,
        backgroundColor,
        colorKey,
      });
    case advancedColorsKeys.wsPrimaryButtonText:
      return generatePrimaryButtonsTextColors({
        targetColor: targetHslaColor,
        backgroundColor,
        editPaletteVariables,
        buttonBackgroundColorKey: advancedColorsKeys.wsPrimaryButtonBackground,
        colorKey,
      });
    case advancedColorsKeys.wsSecondaryButtonBackground:
      return generateSecondaryButtonsBackgroundColors({
        targetColor: targetHslaColor,
        backgroundColor,
        colorKey,
      });
    case advancedColorsKeys.wsSecondaryButtonText:
      return generateSecondaryButtonsTextColors({
        targetColor: targetHslaColor,
        backgroundColor,
        editPaletteVariables,
        buttonBackgroundColorKey: advancedColorsKeys.wsSecondaryButtonBackground,
        colorKey,
      });
    case advancedColorsKeys.wsMarkText:
    case advancedColorsKeys.wsContactIcon:
      return {
        [colorKey]: createHslaColorString(targetHslaColor),
      };
    case advancedColorsKeys.wsInputBorder:
      return generateInputBorderColors({
        targetColor: targetHslaColor,
        backgroundColor,
        colorKey,
        secondColorKey: advancedColorsKeys.wsCheckboxBorder,
      });
    case advancedColorsKeys.wsFeatureIcon:
    case advancedColorsKeys.wsNavLinkActive:
    case advancedColorsKeys.wsBorder:
    case advancedColorsKeys.wsOverlayBackground:
    default:
      return {
        [colorKey]: createHslColorString(targetColor),
      };
  }
};

export default generateAdvancedColors;
