import { view } from '@yola/ws-sdk';
import getPageContainer from '../../utils/helpers/get-page-container';

const isEmptySpaceHovered = (hoveredElementId, pageContainerSelector) => {
  const liveDocument = view.accessors.getLiveDocument();
  const pageContainerElement = getPageContainer(pageContainerSelector);
  const liveElement = view.accessors.getLiveElement(hoveredElementId);

  return liveElement === liveDocument.body || liveElement === pageContainerElement;
};

export default isEmptySpaceHovered;
