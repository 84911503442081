import get from 'lodash.get';
import actionTypes from 'yola-editor/src/js/modules/user/constants/action-types';
import operations from '../operations';

const initHelpCenter = () => (next) => (action) => {
  const { type, payload } = action;

  if (type !== actionTypes.SET_USER_PARTNER_DATA) {
    next(action);
    return;
  }

  const isWL = get(payload, 'partnerData.isWhiteLabel', true);

  if (!isWL) {
    operations.preload();
  }

  next(action);
};

export default initHelpCenter;
