import { dialogs, assets } from '@yola/ws-sdk';
import dialogsTypes from 'src/js/modules/dialogs/constants/dialog-types';

const handleWrongFormatImages = (images) => {
  const { WRONG_FILE_FORMAT_CODE } = assets.constants;
  const hasWrongFormatImages = images.some(
    (image) => image.detail && image.detail.code === WRONG_FILE_FORMAT_CODE
  );

  if (hasWrongFormatImages) {
    dialogs.operations.show(dialogsTypes.FILE_TYPE_ERROR_DIALOG);
  }
};

export default handleWrongFormatImages;
