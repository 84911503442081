import actions from './actions';
import middleware from './middleware';
import reducers from './reducers';
import selectors from './selectors';
import constants from './constants';

export default {
  actions,
  middleware,
  reducers,
  selectors,
  constants,
};
