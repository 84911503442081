import getCircleBracketsContent from '../../utils/get-circle-brackets-content';
import getColorNameByVariable from './get-color-name-by-variable';

const getColorFromVariables = (variableColorKey, variables) => {
  const isCSSColorVariable = getCircleBracketsContent(variables[variableColorKey]);

  return isCSSColorVariable
    ? variables[getColorNameByVariable(variables[variableColorKey])]
    : variables[variableColorKey];
};

export default getColorFromVariables;
