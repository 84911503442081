import { serviceProvider } from '@yola/subscription-manager-js';
import errorModule from 'yola-editor/src/js/modules/error';
import setLoadingStatus from '../actions/set-loading-status';

const fetchSubscriptions = () => async (dispatch) => {
  try {
    await serviceProvider.fetchSubscriptions();
    dispatch(setLoadingStatus(true));
  } catch (error) {
    dispatch(
      errorModule.actions.setCriticalError({
        error,
        logSentry: true,
      })
    );
  }
};

export default fetchSubscriptions;
