import actionTypes from '../constants/action-types';

const locale = (state = null, action) => {
  switch (action.type) {
    case actionTypes.SET_LOCALE:
      return action.payload.locale;
    default:
      return state;
  }
};

export default locale;
