import { blocks, site, template, view } from '@yola/ws-sdk';
import segment from 'src/js/modules/analytics/segment';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import getParentBlockByElement from '../../blocks/helpers/get-parent-block-by-element';

const {
  track,
  trackers: { trackEvent },
  constants: { events },
} = segment;

const getDefaultTraits = () => ({
  siteId: site.accessors.getSiteId(),
  templateBuildSlug: template.accessors.getBuildSlug(),
});

function onDialogCancel({ dialogType, ...traits }) {
  switch (dialogType) {
    case dialogTypes.LINK_FORMATTING_DIALOG:
    case dialogTypes.EDIT_LINK_DIALOG:
    case dialogTypes.NAVIGATION_ITEM_SETTINGS_DIALOG:
    case dialogTypes.FOOTER_NAVIGATION_ITEM_SETTING_DIALOG: {
      trackEvent(events.LINK_SETTINGS_DIALOG_CANCELLED, {
        dialogId: dialogType,
      });
      break;
    }
    case dialogTypes.FOOTER_NAVIGATION_SETTINGS_DIALOG: {
      track(events.LIST_SETTINGS_DIALOG_CANCELED, {
        ...getDefaultTraits(),
        ...traits,
        dialogId: dialogType,
      });
      break;
    }
    case dialogTypes.ADD_WIDGET: {
      track(events.WIDGET_SELECTOR_CLOSED, {
        ...getDefaultTraits(),
        ...traits,
      });
      break;
    }
    case dialogTypes.SELECT_ICON: {
      break;
    }
    case dialogTypes.BLOCK_SETTINGS: {
      track(events.BLOCK_SETTINGS_DIALOG_CANCELED, {
        ...getDefaultTraits(),
        ...traits,
        dialogId: dialogType,
      });
      break;
    }
    case dialogTypes.WEBSITE_DESIGN: {
      track(events.DESIGN_SETTINGS_DIALOG_CANCELLED, {
        ...getDefaultTraits(),
        ...traits,
        dialogId: dialogType,
      });
      break;
    }
    case dialogTypes.CONTACT_FORM_SETTINGS_DIALOG: {
      track(events.CONTACT_FORM_SETTINGS_DIALOG_CANCELED, {
        ...traits,
        dialogId: dialogType,
      });
      break;
    }
    case dialogTypes.CONTACT_FORM_NEW_ITEM: {
      track(events.CONTACT_FORM_ADD_ITEM_DIALOG_CANCELED, {
        ...traits,
        dialogId: dialogType,
      });
      break;
    }
    case dialogTypes.CONTACT_FORM_EDIT_ITEM: {
      track(events.CONTACT_FORM_EDIT_ITEM_DIALOG_CANCELED, {
        ...traits,
        dialogId: dialogType,
      });
      break;
    }
    case dialogTypes.CONTACT_FORM_DELETE_EMAIL_DIALOG: {
      track(events.CONTACT_FORM_DELETE_EMAIL_DIALOG_CANCELED, {
        ...traits,
        dialogId: dialogType,
      });
      break;
    }
    case dialogTypes.GALLERY_DIALOG: {
      const { elementId, ...restTraits } = traits;
      const element = view.accessors.getLiveElement(elementId);
      const blockElement = getParentBlockByElement(element);

      trackEvent(events.GALLERY_SETTINGS_CANCELLED, {
        dialogId: dialogType,
        blockId: blocks.accessors.getBlockIdByElement(blockElement),
        ...restTraits,
      });

      break;
    }
    case dialogTypes.SLIDER_SETTINGS_DIALOG: {
      const { elementId, ...restTraits } = traits;
      const element = view.accessors.getLiveElement(elementId);
      const blockElement = getParentBlockByElement(element);

      trackEvent(events.SLIDER_SETTINGS_CANCELLED, {
        dialogId: dialogType,
        blockId: blocks.accessors.getBlockIdByElement(blockElement),
        ...restTraits,
      });

      break;
    }
    case dialogTypes.DIFM_PROMO_BANNER_DIALOG:
    case dialogTypes.DIFM_PROMO_FINISH_DIALOG:
    case dialogTypes.DIFM_PROMO_SERVICES_DIALOG:
    case dialogTypes.DIFM_PROMO_SURVEY_DIALOG:
    case dialogTypes.DIFM_PROMO_PACKAGES_DIALOG: {
      const { selectedService = null } = traits;

      trackEvent(events.DIFM_PROMO_DIALOG_CLOSED, {
        dialogId: dialogType,
        selectedService,
      });

      break;
    }
    case dialogTypes.BLOCK_REPLACEMENT_DIALOG: {
      const { oldBlock, oldBlockVariationId, newBlock, newBlockVariationId } = traits;

      trackEvent(events.BLOCK_REPLACEMENT_DIALOG_CANCELLED, {
        dialogId: dialogType,
        oldBlock,
        oldBlockVariationId,
        newBlock,
        newBlockVariationId,
      });

      break;
    }
    case dialogTypes.WEBSITE_DESIGN_CUSTOM_PALETTE: {
      trackEvent(events.DESIGN_SETTINGS_COLOR_PRESET_SETTINGS_CANCELED, {
        dialogId: dialogType,
      });
      break;
    }
    case dialogTypes.COLOR_SCHEME_SETTINGS_DIALOG: {
      trackEvent(events.DESIGN_SETTINGS_COLOR_SCHEME_SETTINGS_CANCELED, {
        dialogId: dialogType,
      });
      break;
    }
    case dialogTypes.WEBSITE_DESIGN_COLOR_SETTINGS: {
      trackEvent(events.DESIGN_SETTINGS_COLOR_SETTINGS_CANCELLED, {
        dialogId: dialogType,
      });
      break;
    }

    case dialogTypes.SITE_WIZARD_INTRO_DIALOG:
    case dialogTypes.SITE_WIZARD_SETUP_DIALOG:
    case dialogTypes.SITE_WIZARD_CONFIRM_EXIT_DIALOG:
    case dialogTypes.AI_TEXT_ASSISTANT_ERROR_DIALOG: {
      break;
    }

    case dialogTypes.AI_ERROR_DIALOG: {
      trackEvent(events.AI_WIZARD_ERROR_DIALOG_CLOSED, {
        businessCategory: site.accessors.getBusinessCategory(),
      });
      break;
    }

    case dialogTypes.AI_ONBOARDING_DIALOG: {
      trackEvent(events.AI_WIZARD_ONBOARDING_DIALOG_CLOSED, {
        ...traits,
        businessCategory: site.accessors.getBusinessCategory(),
      });
      break;
    }

    case dialogTypes.ADD_PAGE_DIALOG:
    case dialogTypes.NEW_PAGE_DIALOG: {
      trackEvent(events.ADD_PAGE_DIALOG_CANCELLED);
      break;
    }

    case dialogTypes.PAGE_SETTINGS: {
      trackEvent(events.PAGE_SETTINGS_CANCELLED, {
        dialogId: dialogType,
      });
      break;
    }

    case dialogTypes.WEBSITE_SETTINGS: {
      trackEvent(events.WEBSITE_SETTINGS_DIALOG_CANCELED);
      break;
    }

    default: {
      track(events.DIALOG_CANCELLED, {
        ...getDefaultTraits(),
        dialogId: dialogType,
      });
    }
  }
}

export default onDialogCancel;
