const advancedVariableCategoryIds = {
  GENERAL: 'general',
  TYPOGRAPHY: 'typography',
  NAV_LINKS: 'nav-links',
  FOOTER_NAV_LINKS: 'footer-nav-links',
  DECORATIVE_ELEMENTS: 'decorative-elements',
  PRIMARY_BUTTON: 'primary-button',
  SECONDARY_BUTTON: 'secondary-button',
  MEDIA: 'media',
};

export default advancedVariableCategoryIds;
