import { site, template } from '@yola/ws-sdk';
import segment from 'src/js/modules/analytics/segment';

const {
  track,
  constants: { events },
} = segment;

export function trackSearchClicked() {
  track(events.BLOCK_LIBRARY_SEARCH_CLICKED, {
    siteId: site.accessors.getSiteId(),
    templateBuildSlug: template.accessors.getBuildSlug(),
  });
}

export function trackOnlineStoreSeen() {
  track(events.BLOCK_LIBRARY_ONLINE_STORE_SEEN, {
    siteId: site.accessors.getSiteId(),
    templateBuildSlug: template.accessors.getBuildSlug(),
  });
}

export function trackCustomBlockSeen() {
  track(events.BLOCK_LIBRARY_CUSTOM_BLOCK_SEEN, {
    siteId: site.accessors.getSiteId(),
    templateBuildSlug: template.accessors.getBuildSlug(),
  });
}

export function trackSearchPerformed({ searchTerm, searchResults }) {
  track(events.BLOCK_LIBRARY_SEARCH_PERFORMED, {
    searchTerm,
    searchResults,
    siteId: site.accessors.getSiteId(),
    templateBuildSlug: template.accessors.getBuildSlug(),
  });
}

export function trackCategorySelected(categoryId) {
  track(events.BLOCK_LIBRARY_CATEGORY_SELECTED, {
    categoryId,
    siteId: site.accessors.getSiteId(),
    templateBuildSlug: template.accessors.getBuildSlug(),
  });
}

export function trackBlocksStyleChanged({ categoryId, styleId }) {
  track(events.BLOCK_LIBRARY_BLOCKS_STYLE_CHANGED, {
    categoryId,
    styleId,
    siteId: site.accessors.getSiteId(),
    templateBuildSlug: template.accessors.getBuildSlug(),
  });
}

export function trackTutorialClicked(tutorialName) {
  track(events.BLOCK_LIBRARY_TUTORIAL_CLICKED, {
    siteId: site.accessors.getSiteId(),
    templateBuildSlug: template.accessors.getBuildSlug(),
    tutorialName,
  });
}

export function trackFilterSelected({ categoryId, selectedFilter }) {
  track(events.BLOCK_LIBRARY_FILTER_SELECTED, {
    categoryId,
    selectedFilter,
    siteId: site.accessors.getSiteId(),
    templateBuildSlug: template.accessors.getBuildSlug(),
  });
}
