const mixPageTrigger = (pages, prev, next) => {
  if (pages.length < 2) return pages;

  pages.map((page, index) => {
    switch (index) {
      case 0:
        if (prev) page.items.push(next);
        break;
      case pages.length - 1:
        if (prev) page.items.unshift(prev);
        break;
      default:
        if (prev) page.items.push(next);
        if (next) page.items.unshift(prev);
    }

    return page;
  });
  return pages;
};

export default mixPageTrigger;
