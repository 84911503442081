import { i18next } from '@yola/ws-sdk';
import dialogs from 'yola-editor/src/js/modules/dialogs';
import publishingJobStatuses from '../constants/publishing-job-statuses';
import actions from '../actions';
import pollGetPublishingJob from '../helpers/poll-get-publishing-job';
import continuePublishingProgress from './continue-publishing-progress';

const showLastPublishingProgress = (lastPublishingJobId) => async (dispatch) => {
  dispatch(
    dialogs.actions.show(dialogs.dialogTypes.PUBLISH_PROGRESS, {
      captions: {
        title: i18next.t('Preparing for launch'),
        description: i18next.t('Your website will be published soon'),
      },
    })
  );
  dispatch(actions.setPublishingStatus(publishingJobStatuses.IN_PROGRESS));

  await pollGetPublishingJob({
    jobId: lastPublishingJobId,
    onSuccess: (getPublishingJobResponse) =>
      dispatch(continuePublishingProgress({ response: getPublishingJobResponse })),
    onRequestFail: () =>
      dispatch(
        continuePublishingProgress({ response: { data: { status: publishingJobStatuses.FAILED } } })
      ),
  });
};

export default showLastPublishingProgress;
