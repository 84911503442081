const isWebsiteDesignAvailable = (colorPalettes, fonts, colorPaletteVariable, fontsVariable) =>
  Boolean(
    colorPalettes &&
      colorPalettes.length > 0 &&
      fonts &&
      fonts.length > 0 &&
      colorPaletteVariable &&
      colorPaletteVariable.length > 0 &&
      fontsVariable &&
      fontsVariable.length > 0
  );

export default isWebsiteDesignAvailable;
