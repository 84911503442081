import { view, extensions } from '@yola/ws-sdk';
import getParentBlockByElement from '../../../../blocks/helpers/get-parent-block-by-element';
import constants from '../constants/common';

const collectDOMData = (elementId) => {
  const settings = extensions.accessors.getExtensionSettings(constants.slug);
  const { resizeQuerySelector } = settings;
  const element = view.accessors.getLiveElement(elementId);
  const groupElements = [...element.querySelectorAll(resizeQuerySelector)];
  const blockElement = getParentBlockByElement(element);

  return {
    element,
    blockElement,
    groupElements,
  };
};

export default collectDOMData;
