import { view } from '@yola/ws-sdk';
import store from 'src/js/store';
import bowser from 'yola-bowser';
import { BOTTOM, TOP } from '../constants/common';

const getElementSideToScroll = (element) => {
  const state = store.getState();
  const scrollPosition = view.selectors.getScrollPosition(state);

  const { height, top, bottom } = element.getBoundingClientRect();
  const liveDocument = view.accessors.getLiveDocument();
  const windowHeight = liveDocument.defaultView.innerHeight;

  const higherThanViewport = height > windowHeight;
  let elementTop = top;
  let elementBottom = bottom;

  if (bowser.ios) {
    elementTop = top - scrollPosition;
    elementBottom = bottom - scrollPosition;
  }

  if (elementTop < 0 || higherThanViewport) {
    return TOP;
  }

  if (elementBottom > windowHeight) {
    return BOTTOM;
  }

  return null;
};

export default getElementSideToScroll;
