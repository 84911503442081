import { integration } from '@yola/ws-sdk';
import store from 'src/js/store';
import constants from '../constants';

const createBrandingComponent = () => {
  const state = store.getState();

  const { logo, message, url, logoWidth, theme } = integration.selectors.getSiteFooter(state);
  const brandingFooterElement = document.createElement('ws-branding');

  if (message) {
    brandingFooterElement.setAttribute('message', message);
  }

  brandingFooterElement.setAttribute('logo', logo);
  brandingFooterElement.setAttribute('url', url);
  brandingFooterElement.setAttribute('theme', theme || constants.DEFAULT_THEME);
  brandingFooterElement.setAttribute('logo-width', logoWidth || constants.DEFAULT_LOGO_WIDTH);

  return brandingFooterElement;
};

export default createBrandingComponent;
