import { i18next } from '@yola/ws-sdk';

const generatePageName = (pages, targetPageId = false) => {
  if (targetPageId) {
    const targetPage = pages.find((page) => page.id === targetPageId);
    const titleSuffix = i18next.t('Copy');
    const pageName = targetPage.name || targetPage.title;
    return `${pageName} (${titleSuffix})`;
  }

  const translatedTitle = i18next.t('Untitled');
  const SOURCE_NAME_REGEXP = new RegExp(`^${translatedTitle} ([0-9]*)$`);
  const sourceInitialIndex = 1;
  const newPageIndex = pages.reduce((newSourceIndex, page) => {
    const pageName = page.name || page.title;
    const matchedPath = pageName.match(SOURCE_NAME_REGEXP);

    if (matchedPath) {
      const pageSourceIndex = Number(matchedPath[1]);

      return newSourceIndex > pageSourceIndex ? newSourceIndex : pageSourceIndex + 1;
    }

    return newSourceIndex;
  }, sourceInitialIndex);

  return `${translatedTitle} ${newPageIndex}`;
};

export default generatePageName;
