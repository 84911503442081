import constants from '../constants';
import getIconDialogCommonTraits from '../helpers/get-icon-dialog-common-traits';
import track from '../track';

const { events } = constants;

export default function trackSelectIconSearchClicked({
  dialogId,
  targetElementId,
  premiumAvailable,
  tabId,
}) {
  const traits = {
    dialogId,
    premiumAvailable,
    tabId,
    ...getIconDialogCommonTraits(targetElementId),
  };

  track(events.SELECT_ICON_SEARCH_CLICKED, traits);
}
