import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { dialogs } from '@yola/ws-sdk';
import { connect } from 'react-redux';
import registerDialogs from '../initializers/register-dialogs';
import onDialogMainAction from '../helpers/on-dialog-main-action';
import onDialogCancel from '../helpers/on-dialog-cancel';
import onDialogDisplayed from '../helpers/on-dialog-displayed';

function DialogsContainer({ dialog }) {
  const DialogComponent = dialogs.accessors.getDialogByType(dialog.type);

  const [sharedData, setSharedData] = useState({});

  const getSharedData = useCallback(() => sharedData, [sharedData]);

  const resolveSharedData = useCallback(
    (data) => {
      const currentData = getSharedData();

      if (typeof data === 'function') {
        setSharedData(data(currentData));
        return;
      }

      setSharedData({ ...currentData, ...data });
    },
    [getSharedData]
  );

  const modalProps = dialog.modalProps || {};

  const { onDialogMainAction: onDialogMainActionProp, onDialogCancel: onDialogCancelProp } =
    modalProps;

  modalProps.onDialogMainAction = (props) => {
    if (onDialogMainActionProp) {
      onDialogMainActionProp(props);
    }

    const { dialogType, ...traits } = props || {};
    onDialogMainAction({ dialogType: dialogType || dialog.type, ...traits });
  };

  modalProps.onDialogCancel = (props) => {
    if (onDialogCancelProp) {
      onDialogCancelProp(props);
    }

    const { dialogType, ...traits } = props || {};
    onDialogCancel({ dialogType: dialogType || dialog.type, ...traits });
  };

  useEffect(() => {
    if (!dialog.type) {
      if (!modalProps.preserveSharedData) {
        setSharedData({});
      }
      return;
    }

    onDialogDisplayed(dialog);
  }, [modalProps.preserveSharedData, dialog]);

  useEffect(() => {
    // Sometimes after opening dialog there are selected all possible text, to fix this we
    // manually remove selection on each dialog render
    const selection = window.getSelection();
    selection.removeAllRanges();
  }, [dialog, dialog.type]);

  if (!DialogComponent) {
    return null;
  }

  return (
    <DialogComponent
      getSharedData={getSharedData}
      resolveSharedData={resolveSharedData}
      {...modalProps}
    />
  );
}

DialogsContainer.propTypes = {
  dialog: PropTypes.shape({
    type: PropTypes.string,
    modalProps: PropTypes.shape(),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  dialog: dialogs.selectors.getDialog(state),
});

registerDialogs();

export default connect(mapStateToProps)(DialogsContainer);
