import common from './constants/common';
import control from './controls/control';

const extension = {
  slug: common.slug,
  title: 'WS Iframe',
  description: 'Extension for editing iframe links',
  defaults: {
    querySelector: 'iframe[src]',
    tagName: 'iframe',
  },
  ui: {
    control: [control],
  },
};

export default extension;
