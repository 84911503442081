import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { i18next } from '@yola/ws-sdk';
import { AlertDialog } from '@yola/ws-ui';
import { useDispatch } from 'react-redux';
import user from 'yola-editor/src/js/modules/user';
import hide from '../actions/hide';

function VerifyEmailDialogContainer({ onDialogMainAction, onDialogCancel }) {
  const captions = {
    title: i18next.t('Confirm your email'),
    description: i18next.t('To publish your website, you will need to confirm your email address.'),
    dismiss: i18next.t('Send a verification email'),
  };

  const dispatch = useDispatch();

  const showCheckEmailDialog = useCallback(() => {
    onDialogMainAction();
    dispatch(user.actions.sendVerificationEmail({ showProgress: true }));
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  const handleCancel = useCallback(() => {
    onDialogCancel();
    dispatch(hide());
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  return (
    <AlertDialog captions={captions} onDismiss={showCheckEmailDialog} handleCancel={handleCancel} />
  );
}

VerifyEmailDialogContainer.propTypes = {
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

export default VerifyEmailDialogContainer;
