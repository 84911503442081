import React from 'react';

const NameIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="144" height="90" viewBox="0 0 144 90" fill="none">
    <path
      d="M65.2 51V52.5414C65.2 54.3701 64.3186 57.2961 55.5044 58.9419C53.0804 59.3635 50.8435 60.559 49.1056 62.3619C48.449 63.0431 47.8759 63.7982 47.3951 64.6096C46.1359 66.7348 48.0729 69 50.5743 69H93.39C96.0656 69 98.0023 66.4465 96.4427 64.3089C95.9471 63.6296 95.3913 62.9952 94.7808 62.4146C92.913 60.6384 90.6056 59.436 88.117 58.9419C79.3027 57.2046 79.3028 54.3701 79.3028 52.5414V51"
      fill="#DADDE0"
    />
    <path
      d="M86 39.1048C86 47.2 80.1499 54 73 54C65.8501 54 60 47.5238 60 39.4286V31.6571C60 23.2381 66.8714 20 73 20C79.1286 20 86 22.9143 86 31.3333V39.1048Z"
      fill="#DADDE0"
    />
  </svg>
);

export default NameIcon;
