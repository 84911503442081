import { anodum } from '@yola/ws-sdk';
import isCustomBlock from '../verifiers/is-custom-block';
import getRootBlockByElement from './get-root-block-by-element';

function isElementListItemInCustomBlock(element) {
  if (!element) return false;
  const rootBlock = getRootBlockByElement(element);
  return anodum.isTag(element, 'li') && isCustomBlock(rootBlock);
}

export default isElementListItemInCustomBlock;
