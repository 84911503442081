import { view } from '@yola/ws-sdk';

const getTranslateValue = ({ x, y }, tooltipRec, scrollPosition) => {
  if (!tooltipRec) return null;

  const liveDocument = view.accessors.getLiveDocument();
  const docWidth = liveDocument.documentElement.clientWidth;
  const { width, height } = tooltipRec;
  let adjustmentX;
  let adjustmentY;
  const tooltipHalfWidth = width / 2;
  const tooltipHalfHeight = height / 2;

  if (x + tooltipHalfWidth > docWidth) {
    adjustmentX = width + 10;
    adjustmentY = tooltipHalfHeight;
  } else if (x - tooltipHalfWidth < 0) {
    adjustmentX = -15;
    adjustmentY = tooltipHalfHeight;
  } else if (y - height - 10 < scrollPosition) {
    adjustmentX = tooltipHalfWidth;
    adjustmentY = -20;
  } else {
    adjustmentX = tooltipHalfWidth;
    adjustmentY = height + 10;
  }

  return `translate3d(${x - adjustmentX}px, ${y - adjustmentY}px, 0)`;
};

export default getTranslateValue;
