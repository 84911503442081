import subscriptionsActionTypes from 'yola-editor/src/js/modules/subscriptions/constants/action-types';
import setSettings from 'yola-editor/src/js/modules/editor/actions/set-settings';
import getCurrentPackageFeatureLimits from 'yola-editor/src/js/modules/features/accessors/get-current-package-feature-limits';
import isUpsellAllowed from '../helpers/is-upsell-allowed';
import upgradeDataStorage from '../helpers/upgrade-data-storage';

const updateEditorSettings = (store) => (next) => (action) => {
  switch (action.type) {
    case subscriptionsActionTypes.FETCH_CURRENT_PACKAGE_COMPLETE: {
      next(action);

      const editorUpgradeAction = upgradeDataStorage.getEditorUpgradeAction();
      const upgradeDetails = upgradeDataStorage.getUpgradeDetails();

      // fetch of current packages was triggered by successful upgrade
      if (editorUpgradeAction && upgradeDetails) {
        if (isUpsellAllowed(upgradeDetails, getCurrentPackageFeatureLimits())) {
          editorUpgradeAction.resolve();
        } else {
          editorUpgradeAction.reject();
        }

        store.dispatch(setSettings());
      }

      break;
    }
    default:
      next(action);
  }
};

export default updateEditorSettings;
