import { MIN_TEXT_WIDTH } from '../constants/text-width-limitations';
import { TEXT_RESIZE_MIN_WIDTH_PROPERTY } from '../constants/common';

const getMinTextWidth = (element) => {
  if (!element) {
    return MIN_TEXT_WIDTH;
  }

  const textResizeMinWidthProperty = parseFloat(
    window.getComputedStyle(element).getPropertyValue(TEXT_RESIZE_MIN_WIDTH_PROPERTY)
  );

  if (Number.isInteger(textResizeMinWidthProperty)) {
    return Math.max(MIN_TEXT_WIDTH, textResizeMinWidthProperty);
  }

  return MIN_TEXT_WIDTH;
};

export default getMinTextWidth;
