import { view } from '@yola/ws-sdk';
import getFloatingTriggersInfoForElement from './get-floating-triggers-info-for-element';
import isElementOutOfViewport from './is-element-out-of-viewport';
import stickTypes from '../constants/stick-types';
import { ADD_BLOCKS_BTN_HEIGHT } from '../constants/sizes';
import { PANE_DEFAULT_INDENT, PANE_SIZE_WITH_LINING } from '../../control-pane/constants/sizes';

const TWO_TRIGGERS_MIN_HEIGHT =
  ADD_BLOCKS_BTN_HEIGHT + PANE_SIZE_WITH_LINING + PANE_DEFAULT_INDENT * 2;

const getBlockTriggersInfo = ({
  elementId,
  scrollPosition,
  pageContainerSelector,
  viewportHeight,
}) => {
  const element = view.accessors.getLiveElement(elementId);

  if (!element) {
    return null;
  }

  // to prevent showing floating trigger if element has been scrolled out of viewport
  if (isElementOutOfViewport(element, viewportHeight, scrollPosition)) {
    return null;
  }

  const triggerInfo = getFloatingTriggersInfoForElement({
    elementId,
    scrollPosition,
    pageContainerSelector,
    viewportHeight,
  });

  if (!triggerInfo) {
    return null;
  }

  const { topTrigger, bottomTrigger } = triggerInfo;
  const distanceBetweenTriggers = bottomTrigger.position.y - topTrigger.position.y;
  const liveDocument = view.accessors.getLiveDocument();

  if (distanceBetweenTriggers >= TWO_TRIGGERS_MIN_HEIGHT) {
    return {
      topTrigger,
      bottomTrigger,
      elementId,
    };
  }

  if (liveDocument.body.scrollHeight <= bottomTrigger.position.y) {
    return {
      bottomTrigger,
      elementId,
    };
  }

  if (bottomTrigger.position.stick === stickTypes.BOTTOM) {
    return {
      topTrigger,
      elementId,
    };
  }

  return {
    bottomTrigger,
    elementId,
  };
};

export default getBlockTriggersInfo;
