import { ViewportConsumer } from 'yola-editor/src/js/utils/viewport-provider';
import React from 'react';
import { FocalPointProvider } from '../context';

const withFocalPointContext = (Component) => (props) => (
  <FocalPointProvider>
    <ViewportConsumer>
      {({ width, height, orientation }) => {
        if (!orientation || !width || !height) return null;
        return <Component width={width} height={height} orientation={orientation} {...props} />;
      }}
    </ViewportConsumer>
  </FocalPointProvider>
);

export default withFocalPointContext;
