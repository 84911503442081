import actionTypes from '../constants/action-types';
import constants from '../constants';

const { NETWORK_ONLINE } = constants;

const networkStatus = (state = NETWORK_ONLINE, action) => {
  if (action.type === actionTypes.SET_NETWORK_STATUS) {
    return action.payload.status;
  }
  return state;
};

export default networkStatus;
