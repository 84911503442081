import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { dialogs } from '@yola/ws-sdk';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import RedirectsContainer from 'src/js/modules/redirects/containers/redirects-container';
import constants from '../constants';

const { tabIdentifiers } = constants;

// Yep, that's the scope of https://github.com/yola/ws-editor/issues/3892
// But it's useful to have it already for the testing purpose
function HostingSection({ formData, setFieldValue }) {
  const dispatch = useDispatch();

  const handleRedirectsChange = (redirects) => {
    // While `WebsiteSettings` is mounted, we have to
    // update `redirects` form value in the `Formik` way.
    setFieldValue('redirects', redirects, false);

    // Re-init `WebsiteSettings` with the updated `redirects` included
    dispatch(
      dialogs.actions.show(dialogTypes.WEBSITE_SETTINGS, {
        formData: {
          ...formData,
          redirects,
        },
        activeTabId: tabIdentifiers.HOSTING,
      })
    );
  };

  const handleRedirectsChangeCancel = () => {
    dispatch(
      dialogs.actions.show(dialogTypes.WEBSITE_SETTINGS, {
        formData,
        activeTabId: tabIdentifiers.HOSTING,
      })
    );
  };

  return (
    <RedirectsContainer
      redirects={formData.redirects}
      onChange={handleRedirectsChange}
      onCancel={handleRedirectsChangeCancel}
    />
  );
}

HostingSection.propTypes = {
  formData: PropTypes.shape().isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default HostingSection;
