import positionProperties from '../constants/position-properties';

const getPositionCustomProperties = (element) => {
  const x = element.style.getPropertyValue(positionProperties.X);
  const y = element.style.getPropertyValue(positionProperties.Y);

  return {
    x: x ? parseFloat(x) : null,
    y: y ? parseFloat(y) : null,
  };
};

export default getPositionCustomProperties;
