import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { i18next, dialogs } from '@yola/ws-sdk';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import segment from 'src/js/modules/analytics/segment';
import config from 'src/js/modules/config';
import { RedirectsPropType } from '../constants/types';
import Redirects from '../components/redirects';
import extendTreeViewItemsWithActions from '../helpers/extend-tree-view-items-with-actions';
import getTreeViewItemsFromRedirects from '../helpers/get-tree-view-items-from-redirects';
import getRedirectsFromTreeViewItems from '../helpers/get-redirects-from-tree-view-items';
import { editRedirect, deleteRedirect, createRedirect } from '../helpers/update-redirects';

const {
  trackers: { trackEvent },
  constants: { events },
} = segment;

function RedirectsContainer(props) {
  const { redirects, onChange, onCancel } = props;
  const dispatch = useDispatch();
  const helpCenterUrl = useSelector(config.selectors.getHelpCenterUrl);

  const captions = {
    title: i18next.t('301 Redirects'),
    description: i18next.t(
      "Redirect old URLs to new ones so that you don't lose precious search engine ranking. Redirect to a page or set up wildcard rules to migrate sets of pages."
    ),
    helpCenterDescriptionNote: i18next.t(
      'PLEASE NOTE: GET parameters are not automatically passed from the Old path to the New path. Use regular expressions to pass them from one path to another explicitly.'
    ),
    helpCenterDescription: i18next.t('Learn more about 301 Redirects in {helpCenterLink}', {
      helpCenterLink: `<a href="${helpCenterUrl}" target="_blank">${i18next.t('Help Center')}</a>`,
    }),
    buttonLabel: i18next.t('Create redirect'),
    listViewCaption: i18next.t('Your redirects'),
  };

  const handleOrderChange = (treeViewItems) => {
    const updatedRedirects = getRedirectsFromTreeViewItems(treeViewItems, redirects);

    onChange(updatedRedirects);
  };

  const handleRedirectCreateCancel = () => {
    onCancel();
    trackEvent(events.ADD_REDIRECT_DIALOG_CANCELED);
  };

  const handleRedirectCreateSuccess = (redirect) => {
    const updatedRedirects = createRedirect(redirects, redirect);

    onChange(updatedRedirects);
    trackEvent(events.ADD_REDIRECT_DIALOG_SUBMITTED);
  };

  const handleRedirectCreate = () => {
    dispatch(
      dialogs.actions.show(dialogTypes.REDIRECT_EDIT_DIALOG, {
        warningMessage: captions.helpCenterDescriptionNote,
        description: captions.helpCenterDescription,
        onSubmit: handleRedirectCreateSuccess,
        onCancel: handleRedirectCreateCancel,
        onValidationError: () => {
          trackEvent(events.ADD_REDIRECT_VALIDATION_ERROR);
        },
      })
    );

    trackEvent(events.ADD_REDIRECT_BUTTON_CLICKED);
  };

  const handleRedirectEditCancel = () => {
    onCancel();
    trackEvent(events.EDIT_REDIRECT_DIALOG_CANCELED);
  };

  const handleRedirectEditSuccess = (redirect, index) => {
    const updatedRedirects = editRedirect(redirects, index, redirect);

    onChange(updatedRedirects);
    trackEvent(events.EDIT_REDIRECT_DIALOG_SUBMITTED);
  };

  const handleRedirectEdit = (redirectIndex) => {
    dispatch(
      dialogs.actions.show(dialogTypes.REDIRECT_EDIT_DIALOG, {
        redirect: redirects[redirectIndex],
        redirectIndex,
        warningMessage: captions.helpCenterDescriptionNote,
        description: captions.helpCenterDescription,
        onSubmit: handleRedirectEditSuccess,
        onCancel: handleRedirectEditCancel,
        onValidationError: () => {
          trackEvent(events.EDIT_REDIRECT_VALIDATION_ERROR);
        },
      })
    );

    trackEvent(events.EDIT_REDIRECT_BUTTON_CLICKED);
  };

  const handleRedirectDeleteCancel = () => {
    onCancel();
    trackEvent(events.DELETE_REDIRECT_DIALOG_CANCELED);
  };

  const handleRedirectDeleteSuccess = (index) => {
    const updatedRedirects = deleteRedirect(redirects, index);

    onChange(updatedRedirects);
    trackEvent(events.DELETE_REDIRECT_DIALOG_SUBMITTED);
  };

  const handleRedirectDelete = (redirectTitle, redirectIndex) => {
    dispatch(
      dialogs.actions.show(dialogTypes.REDIRECT_DELETE_DIALOG, {
        redirectTitle,
        redirectIndex,
        onSubmit: handleRedirectDeleteSuccess,
        onCancel: handleRedirectDeleteCancel,
      })
    );
    trackEvent(events.DELETE_REDIRECT_BUTTON_CLICKED);
  };

  const treeViewItems = extendTreeViewItemsWithActions({
    items: getTreeViewItemsFromRedirects(redirects),
    onSettingsClick: handleRedirectEdit,
    onDeleteClick: handleRedirectDelete,
  });

  return (
    <Redirects
      captions={captions}
      treeViewItems={treeViewItems}
      onOrderChange={handleOrderChange}
      onCreateRedirectButtonClick={handleRedirectCreate}
    />
  );
}

RedirectsContainer.propTypes = {
  redirects: RedirectsPropType.isRequired,
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
};

RedirectsContainer.defaultProps = {
  onChange: Function.prototype,
  onCancel: Function.prototype,
};

export default RedirectsContainer;
