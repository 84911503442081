import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import useFeatureFlags from 'yola-editor/src/js/modules/feature-flags/hooks/use-feature-flags';
import { view, dialogs, integration } from '@yola/ws-sdk';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import isGalleryItem from 'src/js/modules/focal-point/helpers/is-gallery-item';
import getGalleryBlockId from '../../registry/ws-gallery/helpers/get-gallery-block-id';
import getGalleryItems from '../../registry/ws-gallery/helpers/get-gallery-items';
import getClosestGalleryItemWrapper from '../../registry/ws-gallery/helpers/get-closest-gallery-item-wrapper';
import MediaSettingsDialog from '../components/media-settings-dialog';
import mediaTypes from '../constants/media-types';

const MediaSettingsDialogContainer = ({
  elementId,
  operations,
  getSharedData,
  resolveSharedData,
  mediaGalleryItems,
}) => {
  const dispatch = useDispatch();
  const [featureFlags] = useFeatureFlags(['nofollowControls', 'limited_external_links']);
  const mediaGalleryItem = useMemo(
    () => mediaGalleryItems.find((item) => item.elementId === elementId),
    [elementId, mediaGalleryItems]
  );
  const limits = useSelector(integration.selectors.getLimits);

  const mediaType = useMemo(() => {
    const element = view.accessors.getLiveElement(elementId);
    const isGallery = isGalleryItem(element);

    if (!isGallery) {
      return mediaTypes.REGULAR;
    }

    if (mediaGalleryItem) {
      return mediaTypes.GALLERY;
    }

    return mediaTypes.GALLERY_ITEM;
  }, [elementId, mediaGalleryItem]);

  const showDeleteButton = Boolean(
    mediaType === mediaTypes.GALLERY ||
      (mediaType === mediaTypes.GALLERY_ITEM && getGalleryItems(elementId).length > 1)
  );

  const forceReloadView = useCallback(() => {
    dispatch(view.actions.forceReloadView());
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  const resetSharedData = useCallback(() => {
    resolveSharedData({ latestValues: null, initialValues: null });
  }, [resolveSharedData]);

  const handleSubmit = useCallback(
    (operationsToSave, changedData) => {
      if (mediaType === mediaTypes.REGULAR || mediaType === mediaTypes.GALLERY_ITEM) {
        dialogs.operations.hide();
        view.operations.bulkViewOperations(operationsToSave);
      }

      if (mediaType === mediaTypes.GALLERY) {
        const newItems = mediaGalleryItems.map((item) => {
          if (item.elementId === elementId) {
            return {
              ...item,
              ...changedData,
              isSaved: true,
            };
          }

          return item;
        });

        resetSharedData();

        dialogs.operations.show(dialogTypes.GALLERY_DIALOG, {
          items: newItems,
          elementId: getGalleryBlockId(elementId),
          onDialogCancel: forceReloadView,
        });
      }
    },
    [mediaType, mediaGalleryItems, resetSharedData, elementId, forceReloadView]
  );

  const handleCancel = useCallback(
    (withForceReload = true) => {
      if (withForceReload) {
        forceReloadView();
      }

      if (mediaType === mediaTypes.REGULAR || mediaType === mediaTypes.GALLERY_ITEM) {
        dialogs.operations.hide();
      }

      if (mediaType === mediaTypes.GALLERY) {
        resetSharedData();

        dialogs.operations.show(dialogTypes.GALLERY_DIALOG, {
          items: mediaGalleryItems,
          elementId: getGalleryBlockId(elementId),
          onDialogCancel: forceReloadView,
        });
      }
    },
    [mediaType, resetSharedData, mediaGalleryItems, elementId, forceReloadView]
  );

  const handleOpenDialog = useCallback(
    (props = {}) => {
      dialogs.operations.show(dialogTypes.MEDIA_SETTINGS_DIALOG, {
        elementId,
        mediaGalleryItems,
        mediaType,
        ...props,
      });
    },
    [elementId, mediaGalleryItems, mediaType]
  );

  const handleCloseDialog = useCallback(() => {
    dialogs.operations.hide();
    resetSharedData();
  }, [resetSharedData]);

  const handleDeleteImage = useCallback(() => {
    if (mediaType === mediaTypes.GALLERY_ITEM) {
      const galleryItemElement = view.accessors.getLiveElement(elementId);
      const galleryItemWrapperElement = getClosestGalleryItemWrapper(galleryItemElement);
      const galleryItemWrapperElementId =
        view.accessors.getLiveElementId(galleryItemWrapperElement);

      dispatch(view.actions.deleteElement(galleryItemWrapperElementId));
      dialogs.operations.hide();
    }

    if (mediaType === mediaTypes.GALLERY) {
      const newItems = mediaGalleryItems.filter((item) => item.elementId !== elementId);

      resetSharedData();

      dialogs.operations.show(dialogTypes.GALLERY_DIALOG, {
        items: newItems,
        elementId: getGalleryBlockId(elementId),
        onDialogCancel: forceReloadView,
      });
    }
  }, [dispatch, elementId, forceReloadView, mediaGalleryItems, mediaType, resetSharedData]);

  const { limited_external_links: isLimitedExternalLinksEnabled } = featureFlags;
  const isExternalLinksDisabled = isLimitedExternalLinksEnabled && !limits.externalLinks.available;

  return (
    <MediaSettingsDialog
      mediaType={mediaType}
      elementId={elementId}
      operations={operations}
      getSharedData={getSharedData}
      resolveSharedData={resolveSharedData}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      onOpenDialog={handleOpenDialog}
      onCloseDialog={handleCloseDialog}
      onDeleteImage={handleDeleteImage}
      forceReloadView={forceReloadView}
      featureFlags={featureFlags}
      mediaGalleryItemState={mediaGalleryItem}
      showDeleteButton={showDeleteButton}
      isExternalLinksDisabled={isExternalLinksDisabled}
    />
  );
};

MediaSettingsDialogContainer.propTypes = {
  elementId: PropTypes.string.isRequired,
  getSharedData: PropTypes.func.isRequired,
  resolveSharedData: PropTypes.func.isRequired,
  operations: PropTypes.array,
  mediaGalleryItems: PropTypes.array,
};

MediaSettingsDialogContainer.defaultProps = {
  operations: [],
  mediaGalleryItems: [],
};

export default MediaSettingsDialogContainer;
