import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { dialogs, i18next, site, template } from '@yola/ws-sdk';
import { AlertDialog } from '@yola/ws-ui';
import segment from 'src/js/modules/analytics/segment';
import detectDeviceOrientation from '../../utils/detect-device-orientation';

const {
  track,
  constants: { events },
} = segment;

const getCaptions = (captions) => ({
  title: i18next.t('Unable to upload image'),
  description: i18next.t(
    'The resolution of your image ({width}x{height}) is too large to be uploaded to the site. Please, crop or resize the image such that the resulting resolution is not more than {max_width}x{max_height} pixels and try to upload it again.',
    {
      width: captions.width,
      height: captions.height,
      max_width: captions.maxWidth,
      max_height: captions.maxHeight,
    }
  ),
  dismiss: i18next.t('Return'),
  ...captions,
});

function ImageSizeErrorDialog({ captions, onDismiss }) {
  useEffect(() => {
    track(events.ERROR_CAUGHT, {
      errorMessage: 'The resolution of your image is too large to be uploaded to the site',
      siteId: site.accessors.getSiteId(),
      templateBuildSlug: template.accessors.getBuildSlug(),
      deviceOrientation: detectDeviceOrientation(),
    });
  }, []);

  const handleDismiss = useCallback(() => {
    dialogs.operations.hide();
  }, []);

  return (
    <AlertDialog
      glyph="alert-circle"
      onDismiss={onDismiss || handleDismiss}
      captions={getCaptions(captions)}
      height="auto"
    />
  );
}

ImageSizeErrorDialog.defaultProps = {
  captions: {},
  onDismiss: null,
};

ImageSizeErrorDialog.propTypes = {
  onDismiss: PropTypes.func,
  captions: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    dismiss: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
  }),
};

export default ImageSizeErrorDialog;
