import adjacentPositions from '../../../blocks/constants/adjacent-positions';

const { BEFORE_BEGIN, AFTER_END } = adjacentPositions;

const getOldNumericPositionBySibling = (
  oldAdjacentPosition,
  oldSiblingNumericPosition,
  newNumericPosition,
  isBlockChanged = false
) => {
  const isElementMovedUp = oldSiblingNumericPosition > newNumericPosition;
  const isElementMovedDown = oldSiblingNumericPosition < newNumericPosition;

  if (isBlockChanged || isElementMovedDown) {
    // If shifted element's old adjacent position was `beforebegin`
    // then referenced sibling element takes it's previous space now
    return oldAdjacentPosition === BEFORE_BEGIN
      ? oldSiblingNumericPosition
      : oldSiblingNumericPosition + 1;
  }

  if (isElementMovedUp) {
    // If shifted element's old adjacent position was `afterend`
    // then referenced sibling element takes it's previous space now
    return oldAdjacentPosition === AFTER_END
      ? oldSiblingNumericPosition
      : oldSiblingNumericPosition - 1;
  }

  return newNumericPosition;
};

export default getOldNumericPositionBySibling;
