export default {
  H1: 'Heading1',
  H2: 'Heading2',
  H3: 'Heading3',
  H4: 'Heading4',
  H5: 'Heading5',
  H6: 'Heading6',
  P: 'Paragraph',
  BLOCKQUOTE: 'Blockquote',
};
