import React from 'react';

const TimeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="144" height="90" viewBox="0 0 144 90" fill="none">
    <path
      d="M98 45C98 59.3594 86.3594 71 72 71C57.6406 71 46 59.3594 46 45C46 30.6406 57.6406 19 72 19C86.3594 19 98 30.6406 98 45Z"
      fill="#DADDE0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M71.8396 27C72.8555 27 73.6791 27.8136 73.6791 28.8172V44.468L83.9827 49.5571C84.8914 50.0059 85.2597 51.0974 84.8054 51.9951C84.351 52.8927 83.2461 53.2566 82.3373 52.8078L71.0169 47.2164C70.3937 46.9086 70 46.2794 70 45.5911V28.8172C70 27.8136 70.8236 27 71.8396 27Z"
      fill="#646C75"
    />
  </svg>
);

export default TimeIcon;
