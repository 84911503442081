import { integration } from '@yola/ws-sdk';
import store from 'src/js/store';
import constants from '../constants';

const isConfiguredProperly = (brandingHeaderElement) => {
  const state = store.getState();
  const { logo, message, url, logoWidth, theme, ctaText } =
    integration.selectors.getSiteHeader(state);
  const isMessageConfigured = message
    ? brandingHeaderElement.getAttribute('message') === message
    : true;

  return (
    brandingHeaderElement.getAttribute('logo') === logo &&
    isMessageConfigured &&
    brandingHeaderElement.getAttribute('url') === url &&
    brandingHeaderElement.getAttribute('cta-text') === ctaText &&
    brandingHeaderElement.getAttribute('logo-width') ===
      (logoWidth || constants.DEFAULT_LOGO_WIDTH) &&
    brandingHeaderElement.getAttribute('theme') === (theme || constants.DEFAULT_THEME)
  );
};

export default isConfiguredProperly;
