import { view } from '@yola/ws-sdk';
import positions from '../constants/positions';
import { TEXT_RESIZE_ALIGNMENT_PROPERTY } from '../constants/common';
import getHandlerSidePositionMap from './get-handler-side-position-map';

const DEFAULT_HANDLER_SIDE_POSITION = positions.RIGHT;

const getHandlerSidePosition = (elementId) => {
  if (!elementId) return DEFAULT_HANDLER_SIDE_POSITION;

  const element = view.accessors.getLiveElement(elementId);
  const computedStyle = window.getComputedStyle(element) || {};
  const textResizeProperty =
    computedStyle && computedStyle.getPropertyValue(TEXT_RESIZE_ALIGNMENT_PROPERTY);
  const handlerSidePositionMap = getHandlerSidePositionMap();

  return handlerSidePositionMap[textResizeProperty.trim()] || DEFAULT_HANDLER_SIDE_POSITION;
};

export default getHandlerSidePosition;
