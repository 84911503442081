import React, { Fragment, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import { siteLocales } from '@yola/ws-sdk';
import getLanguages from '../../website-settings/helpers/get-languages';
import getCountries from '../../website-settings/helpers/get-countries';
import getLanguageData from '../../website-settings/helpers/get-language-data';

const { SearchableSelect, LanguageSelect } = designSystem;

const findOptionByValue = (value, options) =>
  options.find((option) => option.value === value || option.value.value === value);

function PageSelector({ onChange, linkType, reference, onSelectActionValue, options, captions }) {
  const locales = siteLocales.accessors.getSiteLocales();
  const currentLocale = siteLocales.accessors.getCurrentLocale();

  const localesOptions = useMemo(() => {
    const langMap = getLanguages();
    const countriesMap = getCountries();
    return locales.map((local) => {
      const {
        name,
        country: { iconComponent },
      } = getLanguageData(local, langMap, countriesMap);
      return {
        value: local,
        label: name,
        iconComponent,
      };
    });
  }, [locales]);

  const findActiveLang = () => {
    const activeOption = options.find(({ value }) => value === reference);
    return activeOption ? activeOption.locale : currentLocale;
  };

  const [activeLocale, setActiveLocale] = useState(findActiveLang());

  const handleSwitchLanguage = ({ value: langValue }) => {
    setActiveLocale(langValue);
    const newActiveOption = options.find(({ locale }) => locale === langValue);
    onChange({
      linkType,
      reference: newActiveOption.value,
    });
  };

  const pagesOptions =
    localesOptions.length > 1 ? options.filter(({ locale }) => locale === activeLocale) : options;
  const defaultLanguageOption = findOptionByValue(activeLocale, localesOptions);
  const defaultPageOption = findOptionByValue(reference, pagesOptions);

  return (
    <Fragment>
      {localesOptions.length > 1 && (
        <LanguageSelect
          options={localesOptions}
          defaultValue={defaultLanguageOption}
          onChange={handleSwitchLanguage}
        />
      )}

      <SearchableSelect
        name="actionValue"
        noResultsText={captions.noResultsText}
        options={pagesOptions}
        defaultValue={defaultPageOption}
        onChange={onSelectActionValue}
      />
    </Fragment>
  );
}

PageSelector.propTypes = {
  linkType: PropTypes.string.isRequired,
  reference: PropTypes.string.isRequired,
  onSelectActionValue: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  captions: PropTypes.shape({
    noResultsText: PropTypes.string,
  }),
};

PageSelector.defaultProps = {
  captions: {
    noResultsText: '',
  },
};

export default PageSelector;
