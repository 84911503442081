import constants from 'src/js/modules/focal-point/constants/common';
import { view } from '@yola/ws-sdk';

const removeCoverConstraintsAttribute = (originalBlock, blockNode) => {
  const hasCoverConstraintsAttribute = originalBlock.staticBlockNode.hasAttribute(
    constants.OLD_LOGIC_ATTRIBUTE
  );

  if (!hasCoverConstraintsAttribute) return null;

  const elementId = view.accessors.getLiveElementId(blockNode);
  return view.actions.removeElementAttribute(elementId, constants.OLD_LOGIC_ATTRIBUTE);
};

export default removeCoverConstraintsAttribute;
