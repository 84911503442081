import { anodum, textFormatting, linkEditing } from '@yola/ws-sdk';
import verifiers from '../verifiers';
import isMultipurpose from '../../../common/helpers/is-multipurpose';

const { wrappersTypes } = textFormatting.constants;
const { isLinkSelected, isUnderlineSelected } = verifiers;
const { id, className, tagName } = wrappersTypes.link;
const wsLinkSelector = `${tagName}.${className}`;

const updateLink = ({ config, title }, selection) => {
  const { anchorNode } = selection;
  const configStr = JSON.stringify(config);

  if (isLinkSelected()) {
    const startNode = anodum.isTextNode(anchorNode) ? anchorNode.parentElement : anchorNode;
    const linkElement = startNode ? startNode.closest(wsLinkSelector) : null;
    if (linkElement) {
      linkElement.setAttribute(linkEditing.constants.common.LINK_CONFIG_ATTRIBUTE, configStr);

      if (title) {
        linkElement.innerText = title;
      }

      selection.selectAllChildren(linkElement);
    }

    return;
  }

  const range = selection.getRangeAt(0);
  const innerElements = range.getNodes([Node.ELEMENT_NODE]);
  const innerLinks = innerElements.filter((node) => anodum.isTag(node, tagName));

  innerLinks.forEach((link) => {
    link.setAttribute(linkEditing.constants.common.LINK_CONFIG_ATTRIBUTE, configStr);
  });

  const attrs = {
    href: '#',
    [linkEditing.constants.common.LINK_CONFIG_ATTRIBUTE]: configStr,
  };

  textFormatting.helpers.wrapperMap.registerWrapper(id, className, tagName, attrs);
  textFormatting.operations.applyWrapper(id);
  textFormatting.helpers.wrapperMap.removeWrapper(id);

  if (title) {
    const { focusNode } = selection;
    const nodeToUpdate = anodum.isTextNode(focusNode) ? focusNode.parentElement : focusNode;

    if (nodeToUpdate.matches(wsLinkSelector)) {
      nodeToUpdate.innerText = title;
    }

    selection.selectAllChildren(nodeToUpdate);
  }

  if (isMultipurpose() && !isUnderlineSelected()) {
    textFormatting.operations.applyWrapper(wrappersTypes.underline.id);
  }
};

export default updateLink;
