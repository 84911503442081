import getLocaleFromUrl from '../../modules/i18n/helpers/get-locale-from-url';

const normalizePath = (path) => (path ? `/${path}` : '');

let localesPattern = ':locale?';
let locale = '';

export default {
  initialize: (locales) => {
    const localeFromUrl = getLocaleFromUrl();
    locale = normalizePath(locales.includes(localeFromUrl) && localeFromUrl);
    localesPattern = `:locale(${locales.join('|')})?`;
  },
  index: {
    pattern: () => `/${localesPattern}`,
    url: () => `${locale}`,
  },
  'index.dummy': {
    pattern: '/dummy/:name',
    url: ({ name }) => `/dummy/${name}`,
  },
  publish: {
    pattern: '/publish',
    url: () => '/publish',
  },
};
