import { LIVE_PREVIEW_MOCKED_ID } from '../../constants/common';
import sortBlocksByVariationIds from '../sort-blocks-by-variation-ids';
import isStateTouched from '../block-settings/is-state-touched';

const getBlockItemsForRendering = ({
  blockId,
  selectedLayoutVariationId,
  originalBlock,
  category,
  optionalChildren,
  isDirtyOptions,
  variationId: referenceVariationId,
  itemsWithDependencies: propsItemsWithDependencies,
}) => {
  let itemsWithDependencies = [...propsItemsWithDependencies];

  const touched = isStateTouched({ isDirtyOptions, optionalChildren });
  const hasVariations = itemsWithDependencies.length > 1;
  const isPreviewVisible = (hasVariations && !originalBlock.isStrictlyClean) || touched;

  if (hasVariations && isPreviewVisible) {
    const referenceBlock = itemsWithDependencies.find(
      (item) => referenceVariationId === item.variationId
    );

    itemsWithDependencies.push({
      id: LIVE_PREVIEW_MOCKED_ID,
      html: null,
      dependencies: referenceBlock ? referenceBlock.dependencies : {},
      preview: null,
      source: null,
      category,
      templateId: null,
      uuid: LIVE_PREVIEW_MOCKED_ID,
      variationId: LIVE_PREVIEW_MOCKED_ID,
      interactive: true,
    });
  }

  if (!originalBlock.isStrictlyClean && originalBlock.isVisuallyClean) {
    itemsWithDependencies = itemsWithDependencies.filter(
      ({ variationId }) => variationId !== referenceVariationId
    );
  }

  const selectedVariationId =
    !isPreviewVisible && touched ? selectedLayoutVariationId : originalBlock.blockVariationId;

  return sortBlocksByVariationIds(
    itemsWithDependencies,
    blockId,
    selectedVariationId,
    LIVE_PREVIEW_MOCKED_ID
  );
};

export default getBlockItemsForRendering;
