import { textFormatting, anodum } from '@yola/ws-sdk';

function getAdvancedSelection() {
  const selection = textFormatting.accessors.getAdvancedSelection();
  let { anchorNode, anchorOffset } = selection;

  /**
   * Handle case when selection points to element node
   * */
  while (anodum.isElementNode(anchorNode) && !anodum.isTag(anchorNode, 'br')) {
    anchorNode = anchorNode.childNodes[anchorOffset];
    anchorOffset = 0;
    selection.anchorNode = anchorNode;
    selection.focusNode = anchorNode;
    selection.anchorOffset = anchorOffset;
    selection.focusOffset = anchorOffset;
  }

  return selection;
}

export default getAdvancedSelection;
