import { PANE_TRIGGER_SIZE, PANE_DEFAULT_INDENT } from '../constants/sizes';

const getPaneY = (
  position,
  { elTop, scrollTop, elHeight },
  navbarHeight,
  floatingTriggerHeight
) => {
  const elTopToPageStart = elTop + scrollTop - navbarHeight;

  const variants = {
    pageTop: () => floatingTriggerHeight + PANE_DEFAULT_INDENT,
    pageBottom: () =>
      elTopToPageStart + elHeight - floatingTriggerHeight - PANE_DEFAULT_INDENT - PANE_TRIGGER_SIZE,
    elTop: () => elTopToPageStart + PANE_DEFAULT_INDENT,
    elTopAddBlockBtn: () => elTopToPageStart + PANE_DEFAULT_INDENT + floatingTriggerHeight / 2,
    elTopStickAddBlockBtn: () => elTopToPageStart + PANE_DEFAULT_INDENT + floatingTriggerHeight,
    elAbove: () => elTopToPageStart - PANE_DEFAULT_INDENT,
    elMiddle: () => elTopToPageStart + elHeight / 2 - PANE_TRIGGER_SIZE / 2,
    elBelow: () => elTopToPageStart + elHeight + PANE_DEFAULT_INDENT,
    elBelowAddBlockBtn: () =>
      elTopToPageStart + elHeight + PANE_DEFAULT_INDENT + floatingTriggerHeight / 2,
    elAboveAddBlockBtn: () => elTopToPageStart - PANE_DEFAULT_INDENT - floatingTriggerHeight / 2,
    highElTop: () => scrollTop + PANE_DEFAULT_INDENT,
    elTopStickScroll: () => scrollTop + PANE_DEFAULT_INDENT + floatingTriggerHeight,
  };

  return variants[position]();
};

export default getPaneY;
