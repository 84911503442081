import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { designSystem, AceEditor } from '@yola/ws-ui';
import { i18next } from '@yola/ws-sdk';
import ConnectIntegrationDialogScaffolding from './connect-integration-dialog-scaffolding';

const { StepLine, Step, ControlGroup, FieldStatus } = designSystem;

const ConnectGoogleSearchConsoleDialog = ({ captions, onSubmit, onCancel, validate }) => {
  const [verificationTag, setVerificationTag] = useState('');
  const [validationError, setValidationError] = useState('');

  const handleChange = (value) => {
    setValidationError('');
    setVerificationTag(value);
  };

  const handleSubmit = () => {
    const trimmedValue = verificationTag?.trim();
    const validationMessage = validate(trimmedValue);

    if (validationMessage) {
      setValidationError(validationMessage);
      return;
    }

    onSubmit(trimmedValue);
  };

  return (
    <ConnectIntegrationDialogScaffolding
      captions={{
        title: i18next.t('Connect {integrationType}', { integrationType: captions.name }),
        about: captions.about,
        notes: captions.notes,
      }}
      onCancel={onCancel}
      onSubmit={handleSubmit}
    >
      <StepLine>
        <Step marker={1}>
          <ControlGroup title={captions.step1.title} descriptionHTML={captions.step1.description} />
        </Step>
        <Step marker={2}>
          <ControlGroup title={captions.step2.title}>
            <AceEditor
              mode="html"
              theme="chrome"
              options={{
                showLineNumbers: true,
                tabSize: 2,
                autoScrollEditorIntoView: true,
              }}
              defaultValue=""
              onChange={handleChange}
              style={{ minHeight: '72px' }}
            />
            {validationError && <FieldStatus appearance="error" text={validationError} />}
          </ControlGroup>
        </Step>
      </StepLine>
    </ConnectIntegrationDialogScaffolding>
  );
};

ConnectGoogleSearchConsoleDialog.propTypes = {
  captions: PropTypes.shape({
    name: PropTypes.string.isRequired,
    about: PropTypes.string.isRequired,
    step1: PropTypes.shape().isRequired,
    step2: PropTypes.shape().isRequired,
    control: PropTypes.shape().isRequired,
    notes: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  validate: PropTypes.func,
};

ConnectGoogleSearchConsoleDialog.defaultProps = {
  validate: () => null,
};

export default ConnectGoogleSearchConsoleDialog;
