import getControlsPosition from './get-controls-position';
import getSubmitControl from './get-submit-control';
import getZoomSliderControl from './get-zoom-slider-control';
import getUploadImageControl from './get-upload-image-control';
import getBackgroundZoomControl from './get-background-zoom-control';
import { getZoomOutControl, getZoomInControl } from './get-zoom-control';
import getUrlFromImageSource from './get-url-from-image-source';
import calculateBoundsWithoutBorder from './calculate-bounds-without-border';
import wait from './wait';
import isGalleryItem from './is-gallery-item';
import isImageContainer from './is-image-container';
import {
  isMediaFitsInContainerByWidth,
  isMediaFitsInContainerByHeight,
} from './is-media-fits-in-container';
import hasBackgroundContainer from './has-background-container';
import getViewOperation from './get-view-operation';
import getCurrentImageThumbnailUrl from './get-current-image-thumbnail-url';
import getOriginImageSize from './get-origin-image-size';
import getContentBounds from './get-content-bounds';
import getMediaMeta from './get-media-meta';
import getContentPositionInPercents from './get-content-position-in-percents';
import getContainerBounds from './get-container-bounds';
import getPosition from './get-position';
import getScale from './get-scale';
import setCurrentImageUrl from './set-current-image-url';
import setScale from './set-scale';
import setPosition from './set-position';
import setProgressiveSrc from './set-progressive-src';
import getUrlFromBackgroundContainer from './get-url-from-background-container';
import getUrlFromWsBlock from './get-url-from-ws-block';
import getCurrentImageUrl from './get-current-image-url';
import createCssPositionString from './create-css-position-string';
import setOriginImageSize from './set-origin-image-size';
import removeDuplicatedOperations from './remove-duplicated-operations';
import getFitControl from './get-fit-control';
import getImageBounds from './get-image-bounds';
import getImageContainerNode from './get-image-container-node';
import computeAdjustedScale from './compute-adjusted-scale';
import setRatio from './set-ratio';
import getReplaceBackgroundOperations from './get-replace-background-operations';

export default {
  getControlsPosition,
  getSubmitControl,
  getZoomSliderControl,
  getZoomOutControl,
  getZoomInControl,
  getUploadImageControl,
  getBackgroundZoomControl,
  getUrlFromImageSource,
  calculateBoundsWithoutBorder,
  isGalleryItem,
  isImageContainer,
  isMediaFitsInContainerByWidth,
  isMediaFitsInContainerByHeight,
  hasBackgroundContainer,
  getViewOperation,
  getCurrentImageThumbnailUrl,
  getOriginImageSize,
  getContentBounds,
  getMediaMeta,
  computeAdjustedScale,
  getContentPositionInPercents,
  getContainerBounds,
  getPosition,
  createCssPositionString,
  getScale,
  setCurrentImageUrl,
  setScale,
  setPosition,
  setOriginImageSize,
  getUrlFromBackgroundContainer,
  getUrlFromWsBlock,
  getCurrentImageUrl,
  wait,
  removeDuplicatedOperations,
  setProgressiveSrc,
  getFitControl,
  getImageBounds,
  getImageContainerNode,
  setRatio,
  getReplaceBackgroundOperations,
};
