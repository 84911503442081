import { blocks, anodum } from '@yola/ws-sdk';
import {
  BLOCK_ID_ATTRIBUTE_NAME,
  VARIATION_ATTRIBUTE_NAME,
  NODE_ID_ATTRIBUTE_NAME,
} from '../../constants/common';

const compileBlockNode = (html, id, variationId, refId) => {
  const destinationBlockNode = anodum.parseElementFromString(html);
  destinationBlockNode.setAttribute(NODE_ID_ATTRIBUTE_NAME, blocks.helpers.generateBlockId(id));
  destinationBlockNode.setAttribute(VARIATION_ATTRIBUTE_NAME, variationId);
  destinationBlockNode.setAttribute(BLOCK_ID_ATTRIBUTE_NAME, refId);
  return destinationBlockNode;
};

export default compileBlockNode;
