import Glider from 'glider-js';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { i18next } from '@yola/ws-sdk';
import { Button, Text, Icon } from '@yola/ws-ui';
import classNames from 'classnames';
import useWindowWidth from '../hooks/use-window-width';
import ProductsListItem from './products-list-item';

function ProductsList({ description, products, onSelect }) {
  // `isSlider` is used to toggle between slider and plain lists representation
  const [isSlider, setIsSlider] = useState(true);
  const [glider, setGlider] = useState(null);
  const sliderContainer = useRef();
  const itemsContainer = useRef();
  const windowWidth = useWindowWidth();
  const SMALL_SCREEN = 767;
  const LARGE_SCREEN = 1280;

  useEffect(() => {
    const productsListEl = sliderContainer.current.firstChild;

    if (!glider) {
      setGlider(
        new Glider(productsListEl, {
          slidesToScroll: 1,
          slidesToShow: 'auto',
          itemWidth: 300 + 40 / products.length, // include container padding
          exactWidth: true,
          draggable: true,
          arrows: {
            prev: '.products-list__prev-btn',
            next: '.products-list__next-btn',
          },
          responsive: [
            {
              breakpoint: LARGE_SCREEN,
              settings: {
                itemWidth: 360 + 255 / products.length, // include container padding
              },
            },
          ],
        })
      );
    }

    return () => {
      if (glider) glider.destroy();
    };
  }, [products, glider]);

  useLayoutEffect(() => {
    // Track plain list dimensions but not slider during window resize
    // This allows to achieve better results due to different padding settings
    const productsListEl = itemsContainer.current.firstChild;

    if (productsListEl && windowWidth > SMALL_SCREEN && windowWidth < productsListEl.scrollWidth) {
      setIsSlider(true);
    } else {
      setIsSlider(false);
    }
  }, [windowWidth]);

  return (
    <div className="products-list">
      <div className="product-select__header">
        <Text type="heading-1" className="product-select__title">
          {i18next.t('Select your plan')}
        </Text>
        <Text className="product-select__description">{description}</Text>
      </div>

      <div
        className={classNames({
          'products-list__items-container': !isSlider,
          'products-list__items-container--hidden': isSlider,
        })}
        ref={itemsContainer}
      >
        <div className="products-list__items">
          {products.map((product) => (
            <ProductsListItem key={product.sku} product={product} onSelect={onSelect} />
          ))}
        </div>
      </div>

      <div
        className={classNames({
          'products-list__items-container': isSlider,
          'products-list__items-container--hidden': !isSlider,
        })}
        ref={sliderContainer}
      >
        <div className="products-list__items">
          {products.map((product) => (
            <ProductsListItem key={product.sku} product={product} onSelect={onSelect} />
          ))}
        </div>
        <Button className="products-list__prev-btn" type="primary" stretch="square" size="small">
          <Icon glyph="chevron-left" strokeWidth="1.3" />
        </Button>
        <Button className="products-list__next-btn" type="primary" stretch="square" size="small">
          <Icon glyph="chevron-right" strokeWidth="1.3" />
        </Button>
      </div>
    </div>
  );
}

ProductsList.propTypes = {
  description: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string,
      current: PropTypes.bool,
      name: PropTypes.string,
      price: PropTypes.string,
      term: PropTypes.string,
      description: PropTypes.string,
      available: PropTypes.bool,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default ProductsList;
