const copyDisplayOptionByAttribute = ({ displayOptions, destinationBlockNode, attribute }) => {
  const sourceOption = displayOptions.find((option) => option.attribute === attribute);

  if (!sourceOption) return;

  if (sourceOption.value) {
    destinationBlockNode.setAttribute(attribute, sourceOption.value);
    return;
  }

  if (sourceOption.isEnabled) {
    destinationBlockNode.setAttribute(attribute, '');
  } else {
    destinationBlockNode.removeAttribute(attribute);
  }
};

export default copyDisplayOptionByAttribute;
