import React from 'react';
import PropTypes from 'prop-types';
import { useTrail, animated } from 'react-spring';
import classNames from 'classnames';
import { FADE_ANIMATION } from '../constants/animations';

function ControlPaneHeader({ items }) {
  const { length } = items;
  const lastElementIndex = length - 1;
  const trail = useTrail(items.length, {
    config: FADE_ANIMATION,
    opacity: 1,
    from: { opacity: 0 },
  });

  return (
    <div className="ws-control-pane-header">
      {trail.map((style, i) => {
        const key = `ws-control-pane-header__item-${i}`;
        const className = classNames('ws-control-pane-header__item', {
          'ws-control-pane-header__item--close': i === lastElementIndex,
        });
        return (
          <animated.div key={key} className={className} style={style}>
            {items[i]}
          </animated.div>
        );
      })}
    </div>
  );
}

ControlPaneHeader.propTypes = {
  items: PropTypes.node,
};

ControlPaneHeader.defaultProps = {
  items: [],
};

export default ControlPaneHeader;
