import get from 'lodash.get';
import { constants } from '@yola/subscription-manager-js';

const {
  hostingPackageFeatureNames: { PREMIUM_TEXT_DECORATIONS },
} = constants;

const premiumTextDecorationsLimit = ({ targetPackage }) => ({
  available: get(targetPackage, ['features', PREMIUM_TEXT_DECORATIONS], false),
});

export default premiumTextDecorationsLimit;
