import React from 'react';
import PropTypes from 'prop-types';
import bowser from 'yola-bowser';
import { Modal, designSystem } from '@yola/ws-ui';

const { ActionButton, Box } = designSystem;

const MODAL_WIDTH = 418;
const overlaySpacing = { y: 50 };

const DifmPromoModal = ({ children, paddingTop, paddingBottom, closeLabel, onClose }) => (
  <Modal
    isContainerScrollable
    centered
    className="ws-difm-promo-modal"
    width={MODAL_WIDTH}
    overlaySpacing={overlaySpacing}
    height="auto"
    overlay="visible"
    resizable={false}
    draggable={false}
    fullscreen={bowser.mobile}
  >
    <div className="ws-difm-promo-modal__content">
      <Box padding="spacing-l" paddingTop={paddingTop} paddingBottom={paddingBottom}>
        {onClose && (
          <div className="ws-difm-promo-modal__close-button">
            <ActionButton
              fontWeight="regular"
              format="text"
              iconGlyph="close"
              iconPlacement="after"
              label={closeLabel}
              onClick={onClose}
            />
          </div>
        )}
        {children}
      </Box>
    </div>
  </Modal>
);

DifmPromoModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  paddingTop: PropTypes.string,
  paddingBottom: PropTypes.string,
  closeLabel: PropTypes.string,
  onClose: PropTypes.func,
};

DifmPromoModal.defaultProps = {
  paddingTop: 'spacing-xl',
  paddingBottom: 'spacing-xl',
  closeLabel: '',
  onClose: null,
};

export default DifmPromoModal;
