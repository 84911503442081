import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { assets, dialogs, hdrm, i18next } from '@yola/ws-sdk';
import { ProgressDialog } from '@yola/ws-ui';
import dialogsTypes from 'src/js/modules/dialogs/constants/dialog-types';
import errorCodes from '../constants/error-codes';

const UploadFileDialog = ({ file, customPath, onFileUpload, onUploadCancel, onDialogCancel }) => {
  const [currProgress, setCurrProgress] = useState(0);
  const abortRequestController = useMemo(() => new AbortController(), []);
  const { DISABLE_BLUR_ATTR } = hdrm.constants.attributes;

  const onCancel = useCallback(() => {
    abortRequestController.abort();
    onDialogCancel();
    onUploadCancel();
  }, [abortRequestController, onDialogCancel, onUploadCancel]);

  const updateProgress = useCallback(({ loaded, total }) => {
    const completed = Math.round((loaded * 100) / total);
    setCurrProgress(completed);
  }, []);

  const uploadFile = useCallback(
    async (newFile, path) => {
      try {
        const { data } = await assets.operations.createMediaGalleryAssets(
          {
            ...(path ? { custom_path: path } : {}),
            asset: newFile,
            name: newFile.name,
          },
          {
            signal: abortRequestController.signal,
            onUploadProgress: updateProgress,
          }
        );

        onFileUpload({
          path: data.source,
          name: data.name,
          customPath: path || undefined,
        });
      } catch (error) {
        if (!error?.response) return;

        const {
          status,
          data: { code },
        } = error.response;

        if (status === 400 && code === errorCodes.PROHIBITED_FILE_TYPE) {
          dialogs.operations.show(dialogsTypes.FILE_ERROR_DIALOG, {
            captions: {
              title: i18next.t('File type not allowed'),
              description: '',
              dismiss: i18next.t('Dismiss'),
            },
            glyph: 'alert-circle',
            height: 150,
            minHeight: 150,
            onDismiss: () => dialogs.operations.hide(),
          });
        }
      }
    },
    [updateProgress, abortRequestController, onFileUpload]
  );

  useEffect(() => {
    uploadFile(file, customPath);
  }, [file, customPath, uploadFile]);

  return (
    <ProgressDialog
      captions={{
        title: i18next.t('{currProgress} % uploaded', {
          currProgress,
        }),
        description: i18next.t('Your file will be uploaded soon'),
        cancel: i18next.t('Cancel'),
      }}
      attributes={{ [DISABLE_BLUR_ATTR]: true }}
      value={currProgress}
      onCancel={onCancel}
      progressType="indeterminate"
      overlay="visible"
    />
  );
};

UploadFileDialog.propTypes = {
  file: PropTypes.shape().isRequired,
  customPath: PropTypes.string,
  onUploadCancel: PropTypes.func.isRequired,
  onFileUpload: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

UploadFileDialog.defaultProps = {
  customPath: null,
};

export default UploadFileDialog;
