import { view, hdrm, transactions } from '@yola/ws-sdk';
import convertInstructionsToActions from '../helpers/convert-instructions-to-actions';
import getAutomationInstructions from '../helpers/get-automation-instructions';
import isAutomationReason from '../verifiers/is-automation-reason';
import bulkActions from '../helpers/bulk-actions';
import hasHandlers from '../handlers/has';
import prefixes from '../constants/prefixes';
import eventTypes from '../constants/event-types';
import notifyObservers from '../helpers/notify-observers';

const { updateStrategies } = hdrm.constants;

const hasBeforeHandlers = (reason) => hasHandlers({ reason, prefix: prefixes.BEFORE });

const openAutomationTransaction = (store) => (next) => async (action) => {
  if (!isAutomationReason(action.type)) {
    next(action);
    return;
  }

  const { strategy, skipDesignAutomation } = action.payload.options;

  if (skipDesignAutomation) {
    next(action);
    return;
  }

  const isLiveOnlyStrategy = strategy === updateStrategies.UPDATE_LIVE_ONLY;
  let contextType;
  let initialState;

  if (isLiveOnlyStrategy) {
    contextType = transactions.transactionTypes.LIVE_DOCUMENT;
    initialState = view.accessors.getLiveDocument().cloneNode(true);
  } else {
    contextType = transactions.transactionTypes.STATIC_DOCUMENT;
    initialState = hdrm.instance.driver.getStaticDocument().cloneNode(true);
  }

  transactions.helpers.openTransaction(contextType, initialState);
  notifyObservers(eventTypes.AUTOMATION_STARTED);

  let instructions = [];

  if (action.type === view.actionTypes.BULK_VIEW_ACTIONS) {
    const { actions } = action.payload;
    const targetActions = actions.filter(
      ({ type: actionType }) => isAutomationReason(actionType) && hasBeforeHandlers(actionType)
    );

    if (targetActions.length) {
      await Promise.all(
        targetActions.map(async (currentAction) => {
          const currentInstructions = await getAutomationInstructions(currentAction, {
            prefix: prefixes.BEFORE,
          });
          instructions.push(...currentInstructions);
        })
      );
    }
  } else if (hasBeforeHandlers(action.type)) {
    instructions = await getAutomationInstructions(action, { prefix: prefixes.BEFORE });
  }

  if (!instructions.length) {
    next(action);
    return;
  }

  const automationActions = convertInstructionsToActions(instructions, strategy);

  let shouldReloadView = false;
  // view should not be realoaded every time during one particular action
  // but once at the end of automation transaction
  const postponeViewReload = (initialAction) => {
    if (initialAction.payload.options.strategy === updateStrategies.UPDATE_STATIC_ONLY) {
      // eslint-disable-next-line no-param-reassign
      initialAction.payload.options = {
        ...initialAction.payload.options,
        skipViewReload: true,
      };
      shouldReloadView = true;
    }
  };

  const bulkedAction = bulkActions(automationActions);

  postponeViewReload(bulkedAction);
  store.dispatch(bulkedAction);

  if (shouldReloadView) {
    store.dispatch(view.actions.forceReloadView());
  }

  transactions.helpers.closeTransaction(contextType);
  notifyObservers(eventTypes.AUTOMATION_COMPLETED, instructions);
};

export default openAutomationTransaction;
