import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, Text, Panel, PanelGroup, designSystem } from '@yola/ws-ui';
import { i18next } from '@yola/ws-sdk';
import { RedirectPropType } from '../constants/types';

const {
  Autocomplete,
  FieldStatus,
  DialogButton,
  DialogButtonGroup,
  DialogButtonGroupSeparator,
  NotificationMessage,
  Box,
  Paragraph,
} = designSystem;

const EMPTY_REDIRECT = { from: '', to: '' };

const RedirectEditDialog = (props) => {
  const {
    pagesOptions,
    redirect,
    redirectIndex,
    isLoading,
    validationMessages,
    onSubmit,
    onCancel,
    filterOptionsFunction,
    description,
    warningMessage,
  } = props;
  const hasRedirect = redirect !== null;
  const newRedirect = useRef(hasRedirect ? redirect : EMPTY_REDIRECT);

  const captions = {
    createTitle: i18next.t('Create redirect'),
    editTitle: i18next.t('Edit redirect'),
    submit: i18next.t('Submit'),
    cancel: i18next.t('Cancel'),
    oldPath: i18next.t('Old path'),
    newPath: i18next.t('New path'),
  };

  const handleSubmit = () => {
    onSubmit(newRedirect.current, redirectIndex);
  };

  const handleChange = (field) => (value) => {
    const label = value !== null ? value.label : '';
    newRedirect.current = {
      ...newRedirect.current,
      [field]: label,
    };
  };

  return (
    <Modal
      overlay="visible"
      centered
      resizable={false}
      draggable={false}
      width={336}
      height="auto"
      minWidth={312}
      minHeight={332}
      className="ws-redirect-edit-dialog"
      handleSubmit={handleSubmit}
      handleCancel={onCancel}
    >
      <PanelGroup height="100%">
        <Panel
          theme="gray-100"
          height="48"
          align="middle"
          corners="squared"
          className="ws-redirect-edit-dialog__header"
        >
          <Text type="heading-6">{hasRedirect ? captions.editTitle : captions.createTitle}</Text>
        </Panel>
        <Panel className="ws-redirect-edit-dialog__content">
          {warningMessage && (
            <Box marginBottom="spacing-xs">
              <NotificationMessage
                appearance="warning"
                title={
                  // eslint-disable-next-line yola/react/jsx-wrap-multilines
                  <Paragraph appearance="warning" size="small">
                    {warningMessage}
                  </Paragraph>
                }
              />
            </Box>
          )}
          <Text type="annotation" className="ws-redirect-edit-dialog__description">
            {/* eslint-disable-next-line yola/react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: description }} />
          </Text>
          <div className="ws-redirect-edit-dialog__control-group">
            <Text type="heading-6" className="ws-redirect-edit-dialog__control-group-title">
              {captions.oldPath}
            </Text>
            <Autocomplete
              filterOptionsFunction={filterOptionsFunction}
              onChange={handleChange('from')}
              options={pagesOptions}
              invalid={!!validationMessages?.from}
              placeholder=""
              preventOverflow
              defaultInputValue={hasRedirect ? redirect.from : ''}
            />
            {validationMessages?.from && (
              <FieldStatus text={validationMessages.from} appearance="error" />
            )}
          </div>
          <div className="ws-redirect-edit-dialog__control-group">
            <Text type="heading-6" className="ws-redirect-edit-dialog__control-group-title">
              {captions.newPath}
            </Text>
            <Autocomplete
              filterOptionsFunction={filterOptionsFunction}
              onChange={handleChange('to')}
              options={pagesOptions}
              invalid={!!validationMessages?.to}
              placeholder=""
              preventOverflow
              defaultInputValue={hasRedirect ? redirect.to : ''}
            />
            {validationMessages?.to && (
              <FieldStatus text={validationMessages.to} appearance="error" />
            )}
          </div>
        </Panel>

        <Panel>
          <DialogButtonGroup>
            <DialogButton onClick={handleSubmit} isLoading={isLoading}>
              {captions.submit}
            </DialogButton>
            <DialogButtonGroupSeparator />
            <DialogButton onClick={onCancel}>{captions.cancel}</DialogButton>
          </DialogButtonGroup>
        </Panel>
      </PanelGroup>
    </Modal>
  );
};

RedirectEditDialog.propTypes = {
  redirect: RedirectPropType,
  pagesOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string,
          note: PropTypes.string,
        })
      ),
    })
  ),
  redirectIndex: PropTypes.number,
  isLoading: PropTypes.bool,
  validationMessages: PropTypes.shape({ from: PropTypes.string, to: PropTypes.string }),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  filterOptionsFunction: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  warningMessage: PropTypes.string,
};

RedirectEditDialog.defaultProps = {
  redirect: null,
  redirectIndex: null,
  isLoading: false,
  validationMessages: null,
  pagesOptions: [],
  warningMessage: '',
};

export default RedirectEditDialog;
