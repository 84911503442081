import { CONTROL_PANE_TRIGGER_DIVIDER_WIDTH } from '../components/control-pane-trigger-divider';
import { PANE_TRIGGER_SIZE } from '../constants/sizes';

const deriveItemsWidth = (items) =>
  items.reduce((totalWidth, { width, hasDivider }) => {
    let itemWidth = width || PANE_TRIGGER_SIZE;

    // Add trigger divider width into calculation when needed
    itemWidth = hasDivider ? itemWidth + CONTROL_PANE_TRIGGER_DIVIDER_WIDTH : itemWidth;

    return totalWidth + itemWidth;
  }, 0);

export default deriveItemsWidth;
