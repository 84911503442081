export default {
  MULTILINGUAL_AI_TUTORIAL_URL:
    'https://helpcenter.yola.com/hc/en-us/articles/17801456157330-Generating-muntilingual-content-with-AI',
  MULTILINGUAL_AI_TUTORIAL_WL_URL:
    'https://support.sitebuilderhost.net/hc/en-us/articles/17801400234258-Generating-muntilingual-content-with-AI',
  AI_TUTORIAL_URL:
    'https://helpcenter.yola.com/hc/en-us/articles/17771392005266-About-Yola-AI-powered-tools',
  AI_TUTORIAL_WL_URL:
    'https://support.sitebuilderhost.net/hc/en-us/articles/17772540435986-About-Sitebuilder-AI-powered-tools',
};
