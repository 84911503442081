import { useEffect } from 'react';
import { view, bowser } from '@yola/ws-sdk';
import helpers from '../helpers';

const { applySelectionWrapper, undoSelectionWrapper } = helpers;

const useSelectionSimulation = (elementId) => {
  const clearSelectionImitation = () => {
    if (bowser.firefox) return;

    const element = view.accessors.getLiveElement(elementId);
    undoSelectionWrapper(element);
  };

  const restoreSelectionImitation = () => {
    if (bowser.firefox) return;

    applySelectionWrapper();
  };

  useEffect(() => {
    const element = view.accessors.getLiveElement(elementId);

    if (!bowser.firefox) {
      applySelectionWrapper();
    }

    element.blur();
    element.setAttribute('contenteditable', 'false');

    return () => {
      if (!bowser.firefox) {
        undoSelectionWrapper(element);
      }

      element.setAttribute('contenteditable', 'true');
    };
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  return {
    clearSelectionImitation,
    restoreSelectionImitation,
  };
};

export default useSelectionSimulation;
