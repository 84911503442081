import { dialogs, hooks, site } from '@yola/ws-sdk';
import { selectors, verifiers } from '@yola/subscription-manager-js';
import ai from 'src/js/modules/ai';
import user from 'yola-editor/src/js/modules/user';
import featureFlagsService from 'yola-editor/src/js/modules/feature-flags/service-client';
import store from 'src/js/store';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import { TRIGGER_IDS } from 'src/js/modules/difm-promo/constants/traits';

const { AI_SITE_WIZARD_PROPERTY_NAME, aiSiteWizardPropertyValues } = ai.constants.siteProperties;

const DIFM_PROMO_DELAY = 1000 * 60 * 15; // 15 minutes

const initializeDifmPromo = async () => {
  const state = store.getState();
  const { show_difm_banner: showDifmBanner, is_white_label: isWhiteLabel } =
    user.selectors.getUserData(state);

  if (!showDifmBanner) return;

  const activeSubscriptions = selectors.getActiveSubscriptions(state);
  const subscriptionTypes = activeSubscriptions.map(({ type }) => type);
  const hasPaidHostingSubscription = verifiers.hasPaidHostingPackageType(subscriptionTypes);

  if (!hasPaidHostingSubscription) return;

  const externalUpsellTypes = user.selectors.getExternalUpsellTypes(state);

  if (
    isWhiteLabel &&
    !externalUpsellTypes.includes(user.constants.externalUpsellTypes.DIFM_SERVICE)
  )
    return;

  const featureFlagsManager = featureFlagsService.get();
  const siteProperties = site.selectors.getSiteProperties(state);

  const { difm_latitude: isDifmLatitudeEnabled, ai_site_wizard: isAiSiteWizardEnabled } =
    await featureFlagsManager.request(['difm_latitude', 'ai_site_wizard']);

  if (!isDifmLatitudeEnabled) {
    return;
  }

  if (
    isAiSiteWizardEnabled &&
    AI_SITE_WIZARD_PROPERTY_NAME in siteProperties &&
    [aiSiteWizardPropertyValues.ENABLED, aiSiteWizardPropertyValues.INITIATED].includes(
      siteProperties[AI_SITE_WIZARD_PROPERTY_NAME]
    )
  ) {
    return;
  }

  const showDifmPromoDialog = () => {
    store.dispatch(
      dialogs.actions.show(dialogTypes.DIFM_PROMO_BANNER_DIALOG, {
        triggerId: TRIGGER_IDS.firstTime,
      })
    );
  };

  setTimeout(() => {
    const isDialogVisible = dialogs.verifiers.isVisible();

    if (isDialogVisible) {
      hooks.operations.subscribe(hooks.availableHooks.ON_AFTER_DIALOG_HIDE, showDifmPromoDialog, {
        once: true,
      });
      return;
    }

    showDifmPromoDialog();
  }, DIFM_PROMO_DELAY);
};

export default initializeDifmPromo;
