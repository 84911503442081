import { constraints } from '@yola/ws-sdk';
import { GRADIENT, DEFAULT } from '../constants/appearance';

const getHighlighterAppearance = (element) => {
  if (constraints.verifiers.canBeSpaceResizable(element)) {
    return GRADIENT;
  }

  return DEFAULT;
};

export default getHighlighterAppearance;
