export default {
  IMAGE_ALT_MODAL_WIDTH: 398,
  IMAGE_ALT_MODAL_HEIGHT: 'auto',
  SET_CLICK_ACTION: 'set-click-action',
  CHANGE_IMAGE_TITLE: 'change-image-title',
  PASTE_IMAGE: 'paste-image',
  COPY_IMAGE: 'copy-image',
  FOCAL_POINT_PICKER_TOOL: '@WS_IMAGE/CUSTOM_UI/FOCAL_POINT_PICKER_TOOL',
  IMAGE_HEIGHT_COMPLETE_AREA: 10,
  REGULAR_IMAGE_REPLACE_TRIGGER: 'regular-image-replace-trigger',
  WRONG_FILE_TYPE_ERROR: 'WRONG_FILE_TYPE_ERROR',
};
