import React from 'react';
import PropTypes from 'prop-types';
import { Modal, designSystem } from '@yola/ws-ui';
import { i18next } from '@yola/ws-sdk';

const { Paragraph, Box, Heading, Checkbox, ActionButton, IconButton } = designSystem;

const MODAL_WIDTH = 470;

const DifmPrivacyUpsellDialog = ({
  onSelfHandleCheckboxClick,
  onLearnMoreClick,
  onGoToSettingsClick,
  onClose,
  isCloseEnabled,
  isComplianceAccepted,
}) => (
  <Modal
    className="ws-difm-privacy-upsell-modal"
    width={MODAL_WIDTH}
    height="auto"
    overlay="visible"
    resizable={false}
    draggable={false}
    centered
  >
    <div className="ws-difm-privacy-upsell-modal__content">
      {isCloseEnabled && (
        <div className="ws-difm-privacy-upsell-modal__close-button">
          <IconButton glyph="close" onClick={onClose} size="small" transparent />
        </div>
      )}
      <Box padding="spacing-l" paddingBottom="spacing-2-xs">
        <Heading type="heading-4" align="center">
          {i18next.t('Privacy and Cookie Compliance')}
        </Heading>
        <Box marginTop="spacing-3-xs">
          <Paragraph>
            {i18next.t(
              'Ensure compliance with privacy and cookie usage requirements by providing clear information on collecting, processing, and storing user data. Please confirm that you are aware of this responsibility'
            )}
          </Paragraph>
        </Box>
        <Box marginTop="spacing-2-xs" marginBottom="spacing-xs">
          <Box marginBottom="spacing-xs" marginTop="spacing-xs">
            <Checkbox
              id="difm-privacy-self-handle"
              label={i18next.t('I accept my responsibilities')}
              onChange={onSelfHandleCheckboxClick}
              value="difm-privacy-self-handle"
              isLabelBold={false}
              checked={isComplianceAccepted}
              disabled={isComplianceAccepted}
            />
          </Box>
          <ActionButton
            label={i18next.t('Go to settings')}
            onClick={onGoToSettingsClick}
            disabled={!isComplianceAccepted}
          />
        </Box>
        <Box marginTop="spacing-xs" marginBottom="spacing-xs">
          <div className="ws-difm-privacy-upsell-modal__block">
            <Box
              marginTop="spacing-s"
              marginBottom="spacing-s"
              marginLeft="spacing-m"
              marginRight="spacing-m"
            >
              <Heading type="heading-5">
                {i18next.t('If you don’t know where to start, check us out!')}
              </Heading>
              <Box marginTop="spacing-2-xs">
                <ActionButton
                  label={i18next.t('Learn more')}
                  format="solid"
                  appearance="cta"
                  onClick={onLearnMoreClick}
                />
              </Box>
            </Box>
          </div>
        </Box>
      </Box>
    </div>
  </Modal>
);

DifmPrivacyUpsellDialog.propTypes = {
  onSelfHandleCheckboxClick: PropTypes.func.isRequired,
  onLearnMoreClick: PropTypes.func.isRequired,
  onGoToSettingsClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isCloseEnabled: PropTypes.bool.isRequired,
  isComplianceAccepted: PropTypes.bool.isRequired,
};

export default DifmPrivacyUpsellDialog;
