import { anodum, site, view, blocks, template } from '@yola/ws-sdk';
import constants from '../constants';

const prepareContentEventTrackData = (event) => {
  const { clipboardData, target } = event;
  let parsedElement;

  try {
    const html = clipboardData.getData('text/html');
    parsedElement = anodum.parseElementFromString(`<div>${html}</div>`);
  } catch (e) {
    parsedElement = null;
  }

  if (!parsedElement) return null;

  const contentTypes = [];
  const link = parsedElement.querySelector('a');
  const image = parsedElement.querySelector('img');

  if (image) {
    contentTypes.push(constants.contentTypes.IMAGE);
    image.remove();
  }

  if (link) {
    contentTypes.push(constants.contentTypes.LINK);
    link.remove();
  }

  if (parsedElement.innerText.length) {
    contentTypes.push(constants.contentTypes.TEXT);
  }

  const allBlocks = view.accessors.getPageBlocks();
  const element = allBlocks.find((block) => block.contains(target));
  if (!element) return null;

  return {
    siteId: site.accessors.getSiteId(),
    contentTypes,
    targetBlockId: blocks.accessors.getBlockIdByElement(element),
    targetBlockVariationId: blocks.accessors.getVariationIdByElement(element),
    templateBuildSlug: template.accessors.getBuildSlug(),
  };
};

export default prepareContentEventTrackData;
