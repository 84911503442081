import yousersjs from '@yola/yousersjs';
import get from 'lodash.get';
import { i18next } from '@yola/ws-sdk';
import dialogs from 'yola-editor/src/js/modules/dialogs';
import errorModule from 'yola-editor/src/js/modules/error';
import actionTypes from '../constants/action-types';

const emailVerification = (store) => (next) => (action) => {
  if (action.type === actionTypes.SEND_VERIFICATION_EMAIL) {
    const shouldShowProgress = get(action, 'payload.options.showProgress', false);

    if (shouldShowProgress) {
      store.dispatch(
        dialogs.actions.show(dialogs.dialogTypes.PROGRESS, {
          captions: {
            title: i18next.t('Preparing to send...'),
            description: i18next.t('Please wait while we send your confirmation email'),
          },
          overlay: 'visible',
          cancelable: false,
          progressType: 'indeterminate',
        })
      );
    }

    yousersjs.requests
      .sendVerificationEmail()
      .then(() => {
        if (shouldShowProgress) {
          store.dispatch(dialogs.actions.show(dialogs.dialogTypes.CHECK_VERIFICATION_EMAIL));
        }

        next(action);
      })
      .catch((error) => {
        store.dispatch(
          errorModule.actions.setCriticalError({
            error,
            logSentry: true,
          })
        );

        next(action);
      });

    return;
  }

  next(action);
};

export default emailVerification;
