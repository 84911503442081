import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { dialogs, i18next } from '@yola/ws-sdk';
import { AlertDialog } from '@yola/ws-ui';
import useCustomerSupport from 'yola-editor/src/js/modules/common/hooks/use-customer-support';
import trackApiRateExceededEvent from '../../trackers/track-api-rate-exceeded-event';

const getApiRateExceededCaptions = (isCustomerSupportAvailable) => ({
  title: i18next.t('API Rate exceeded'),
  description: i18next.t('The API rate for Unsplash integration is exceeded. {contactSupport}', {
    contactSupport: isCustomerSupportAvailable
      ? i18next.t('If you see this message, please contact customer support')
      : '',
  }),
  dismiss: i18next.t('Dismiss'),
  submit: isCustomerSupportAvailable ? i18next.t('Contact support') : '',
});

const getDefaultCaptions = (isCustomerSupportAvailable) => ({
  title: i18next.t('Something went wrong'),
  description: i18next.t('Please, try again later {contactSupport}', {
    contactSupport: isCustomerSupportAvailable ? i18next.t('or contact customer support.') : '',
  }),
  dismiss: i18next.t('Dismiss'),
  submit: isCustomerSupportAvailable ? i18next.t('Contact support') : '',
});

function PhotoLoadErrorDialog({
  onDialogCancel,
  preserveSharedData,
  isApiRateExceeded,
  onDismiss,
  onDialogMainAction,
}) {
  const { onContactSupport, isCustomerSupportAvailable } = useCustomerSupport();

  const captions = isApiRateExceeded
    ? getApiRateExceededCaptions(isCustomerSupportAvailable)
    : getDefaultCaptions(isCustomerSupportAvailable);

  useEffect(() => {
    if (isApiRateExceeded) trackApiRateExceededEvent();
  }, [isApiRateExceeded]);

  const handleDismiss = () => {
    onDialogCancel();
    dialogs.operations.hide({ preserveSharedData });
  };

  const handleSubmit = () => {
    onDialogMainAction();
    onContactSupport();
    dialogs.operations.hide({ preserveSharedData });
  };

  return (
    <AlertDialog
      onDismiss={onDismiss || handleDismiss}
      captions={captions}
      glyph="alert-circle"
      {...(isCustomerSupportAvailable && { onSubmit: handleSubmit })}
    />
  );
}

PhotoLoadErrorDialog.propTypes = {
  // Passed from ws-editor's `dialogs-container`:
  onDialogCancel: PropTypes.func.isRequired,
  onDialogMainAction: PropTypes.func.isRequired,
  preserveSharedData: PropTypes.bool,
  isApiRateExceeded: PropTypes.bool,
  onDismiss: PropTypes.func,
};

PhotoLoadErrorDialog.defaultProps = {
  preserveSharedData: false,
  isApiRateExceeded: false,
  onDismiss: null,
};

export default PhotoLoadErrorDialog;
