import { view } from '@yola/ws-sdk';

function getElementNumericPosition(refId) {
  const element = view.accessors.getStaticElement(refId);
  const numericPosition = [...element.parentElement.children].indexOf(element) + 1;

  return numericPosition;
}

export default getElementNumericPosition;
