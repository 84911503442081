const getTemplate = (isTopLevel, hasCollapse, templates) => {
  if (isTopLevel) {
    if (hasCollapse) {
      return templates.nestableMenuItem;
    }
    return templates.menuItem;
  }

  if (hasCollapse) {
    return templates.nestableSubmenuItem;
  }

  return templates.submenuItem;
};

export default getTemplate;
