import { blocks } from '@yola/ws-sdk';
import ids from '../constants/ids';

const isOnlineStoreBlock = (element) => {
  const block = blocks.accessors.getBlockByElement(element);

  return block ? block.id === ids.ONLINE_STORE_BLOCK : false;
};

export default isOnlineStoreBlock;
