import getShortReferralLinks from './get-short-referral-links';

let referralLinks;

const referralLinksStorage = {
  getAsync: async () => {
    if (!referralLinks) {
      referralLinks = await getShortReferralLinks();
    }

    return referralLinks;
  },
};

export default referralLinksStorage;
