import { hooks, view } from '@yola/ws-sdk';
import helpers from '../helpers';

const onBeforeSynchronizeElement = () => {
  hooks.operations.subscribe(
    hooks.availableHooks.ON_BEFORE_SYNCHRONIZE_STATIC_ELEMENT,
    (oldElement, newElement) => {
      if (helpers.isTextEdited(oldElement, newElement)) {
        newElement.removeAttribute('content-untouched');
      }
    }
  );

  hooks.operations.subscribe(
    hooks.availableHooks.ON_BEFORE_SYNCHRONIZE_LIVE_ELEMENT,
    (newElement) => {
      const elementId = view.accessors.getLiveElementId(newElement);
      const oldElement = view.accessors.getStaticElement(elementId);

      if (helpers.isTextEdited(oldElement, newElement)) {
        newElement.removeAttribute('content-untouched');
      }
    }
  );
};

export default onBeforeSynchronizeElement;
