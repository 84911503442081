import getDateObjectFrom from './get-date-object-from';

const calculateDaysDifference = (firstDate, secondDate) => {
  const dayOne = getDateObjectFrom(firstDate);
  const dayTwo = getDateObjectFrom(secondDate);

  if (!Date.parse(dayOne) || !Date.parse(dayTwo)) return 0;

  const oneDay = 24 * 60 * 60 * 1000;

  return Math.floor(Math.abs((dayOne - dayTwo) / oneDay));
};

export default calculateDaysDifference;
