import React from 'react';
import { useDispatch } from 'react-redux';
import { i18next } from '@yola/ws-sdk';
import PropTypes from 'prop-types';
import dialogs from 'yola-editor/src/js/modules/dialogs';
import ResultDialog from '../components/result-dialog';

function PurchaseSuccessDialogContainer(props) {
  const { captions, onClose, onDialogMainAction } = props;

  // Set translated default captions
  captions.title = captions.title || i18next.t('Order completed');
  captions.description =
    captions.description || i18next.t('Thank you. Your order has been completed.');
  captions.close = captions.close || i18next.t('OK');

  const dispatch = useDispatch();

  function handleClose() {
    onDialogMainAction();
    if (onClose) {
      onClose();
    } else {
      dispatch(dialogs.actions.hide());
    }
  }

  return <ResultDialog captions={captions} imgClass="dialog__img--success" onClose={handleClose} />;
}

PurchaseSuccessDialogContainer.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    close: PropTypes.string,
  }),
  onClose: PropTypes.func,
  onDialogMainAction: PropTypes.func.isRequired,
};

PurchaseSuccessDialogContainer.defaultProps = {
  captions: {},
  onClose: null,
};

export default PurchaseSuccessDialogContainer;
