import { useCallback, useEffect, useState } from 'react';
import { assets, linkEditing } from '@yola/ws-sdk';

const { linkTypes } = linkEditing.constants;
const defaultPathValue = '/';

const useCustomPathState = (linkType, reference, handlers = {}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [value, setValue] = useState(defaultPathValue);
  const { onDownloadUrlToggle = Function.prototype } = handlers;

  const toggleCustomPathField = useCallback(() => {
    setIsVisible((visibilityState) => {
      const newValue = !visibilityState;

      onDownloadUrlToggle(newValue);

      return newValue;
    });
  }, [onDownloadUrlToggle]);

  const setCustomPath = useCallback((path) => {
    setValue(path);
  }, []);

  const enableCustomPath = useCallback(() => {
    setIsDisabled(false);
  }, []);

  useEffect(() => {
    if (linkType !== linkTypes.DOWNLOAD || !reference || reference.startsWith('#')) {
      return () => {};
    }

    setIsLoading(true);

    let wasUnmount = false;

    assets.operations.getMediaLibraryAsset(reference).then((assetData) => {
      if (!wasUnmount) {
        if (assetData) {
          setValue(assetData.customPath || defaultPathValue);
          setIsVisible(!!assetData.customPath);
        }

        setIsLoading(false);
      }
    });

    return () => {
      wasUnmount = true;
    };
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  return {
    state: {
      disabled: isDisabled,
      visible: isVisible,
      isLoading,
      value,
    },
    setCustomPath,
    toggleCustomPathField,
    enableCustomPath,
  };
};

export default useCustomPathState;
