import { assets, dialogs, uuid } from '@yola/ws-sdk';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import imageMimeToExtension from './image-mime-to-extension';
import constants from '../constants/common';
import imageFileFormats from '../constants/image-file-formats';
import getFileExtension from './get-file-extension';

const getImageUrlFromPasteEvent = async (event) => {
  const { clipboardData } = event;

  const imageFile = Array.from(clipboardData.files).find((file) => {
    const extension = getFileExtension(file.name);
    return file.type.startsWith('image/') && imageFileFormats.includes(extension);
  });

  if (clipboardData.files.length > 0 && !imageFile) {
    throw new Error(constants.WRONG_FILE_TYPE_ERROR);
  }

  if (imageFile && clipboardData.files.length > 0) {
    dialogs.operations.show(dialogTypes.IMAGE_LOADING_DIALOG);
    await assets.operations.clientUploadAsset(imageFile);
    const newAsset = await assets.operations.createMediaGalleryAssets({
      asset: imageFile,
      name: `${uuid()}.${imageMimeToExtension(imageFile.type)}`,
    });

    return newAsset.data.source;
  }

  return null;
};

export default getImageUrlFromPasteEvent;
