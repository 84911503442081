import React from 'react';
import PropTypes from 'prop-types';
import { i18next } from '@yola/ws-sdk';
import { AlertDialog } from '@yola/ws-ui';

function ExportInformationDialog(props) {
  const { onDismiss, siteArchiveLink } = props;

  return (
    <AlertDialog
      captions={{
        title: `${i18next.t('Code export')}.`,
        description: `${i18next.t('Your site archive is ready')}.`,
        dismiss: i18next.t('Close'),
        descriptionLink: `[${i18next.t('Download ZIP')}]`,
      }}
      onDismiss={onDismiss}
      descriptionLinkHref={siteArchiveLink}
    />
  );
}

ExportInformationDialog.defaultProps = {
  captions: {},
  onDismiss: Function.prototype,
  siteArchiveLink: null,
};

ExportInformationDialog.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    cancel: PropTypes.string,
  }),
  onDismiss: PropTypes.func,
  siteArchiveLink: PropTypes.string,
};

export default ExportInformationDialog;
