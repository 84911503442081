import React from 'react';
import ReactModal from 'react-modal';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import { Loader } from '@yola/ws-ui';

function PendingDialog(props) {
  const {
    captions: { title },
  } = props;

  return (
    <ReactModal isOpen overlayClassName="dialog-overlay--shaded" className="dialog__wrapper">
      <CSSTransition in appear timeout={0} classNames="dialog--animated">
        <div className="dialog dialog--centered dialog--pending">
          <Loader type="indeterminate" />
          <div className="dialog__content">
            <h5 className="dialog__title">{title}</h5>
          </div>
        </div>
      </CSSTransition>
    </ReactModal>
  );
}

PendingDialog.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default PendingDialog;
