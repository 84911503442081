import { extensions, view } from '@yola/ws-sdk';
import constants from '../constants';

const getSliderBlockId = (elementId) => {
  const { sliderGalleryQuerySelector } = extensions.accessors.getExtensionSettings(
    constants.common.slug
  );
  const element = view.accessors.getLiveElement(elementId);

  const sliderBlock = element.closest(sliderGalleryQuerySelector);
  return view.accessors.getLiveElementId(sliderBlock);
};

export default getSliderBlockId;
