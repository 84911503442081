import React from 'react';
import { Icon, DragSource, DropTarget } from '@yola/ws-ui';
import { assets } from '@yola/ws-sdk';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MediaPlaceholder from 'src/js/modules/common/components/media-placeholder';
import BackgroundBox from 'src/js/modules/common/components/background-box';
import constants from '../constants';
import getDropPosition from '../helpers/get-drop-position';
import getDragPreviewItemStackOrder from '../helpers/get-drag-preview-item-stack-order';
import isBlob from '../verifiers/is-blob';

const galleryItemImageClassName = 'ws-gallery-item__image';

class GalleryItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropPosition: null,
      isOnClickAllowed: true,
    };
    this.rootElement = React.createRef();
  }

  componentDidUpdate(prevProps) {
    this.manageOnClickAllowed(prevProps.isDragging);
  }

  setDropPosition(newDropPosition) {
    const { dropPosition } = this.state;

    if (newDropPosition !== dropPosition) {
      this.setState({
        dropPosition: newDropPosition,
      });
    }
  }

  // Manages if click on item is processed.
  // Needed to prevent spare click/mouseout
  // event trigger immediately after drag end.
  manageOnClickAllowed(wasDragging) {
    const { isDragging } = this.props;

    if (!wasDragging && isDragging) {
      this.setState({ isOnClickAllowed: false });
    }

    if (wasDragging && !isDragging) {
      setTimeout(() => {
        this.setState({ isOnClickAllowed: true });
      });
    }
  }

  isSelected() {
    const { id, selectedItemsIds } = this.props;
    return selectedItemsIds.includes(id);
  }

  render() {
    const { dropPosition, isOnClickAllowed } = this.state;
    const { id, src, order, isPlaceholder, onItemClick, enableSelect } = this.props;
    const { addBaseHref } = assets.helpers;
    const isSelected = this.isSelected();

    // Drag&Drop props
    const { isDragging, isOver, draggedItemsData, connectDragSource, connectDropTarget } =
      this.props;
    const isCurrentItemDragged =
      draggedItemsData && draggedItemsData.some((item) => item.id === id);
    const galleryItemClassName = classNames('ws-gallery-item', {
      'ws-gallery-item--active': isSelected,
    });
    const dropOutlineClassName = classNames('ws-gallery-item__drop-outline', {
      [`ws-gallery-item__drop-outline-${dropPosition}`]: isOver,
    });
    const previewSource = isBlob(src) ? src : addBaseHref(src);

    const handleClick = (e) => {
      if (isOnClickAllowed) {
        onItemClick(e, id, isSelected);
      }
    };

    return connectDropTarget(
      connectDragSource(
        <div className={galleryItemClassName} onClick={handleClick} ref={this.rootElement}>
          <div
            className={galleryItemImageClassName}
            style={{
              opacity: isCurrentItemDragged ? 0 : 1,
            }}
          >
            <div className="ws-gallery-item__background-box">
              {isPlaceholder ? <MediaPlaceholder /> : <BackgroundBox url={previewSource} />}
            </div>

            {enableSelect && isSelected && (
              <div className="ws-gallery-item__сircle-icon">
                <Icon glyph="check" size={12} strokeWidth={3} />
              </div>
            )}

            {isDragging && (
              <div className="ws-gallery-item__сircle-icon ws-gallery-item__order">{order + 1}</div>
            )}
          </div>

          {isDragging && <span className={dropOutlineClassName} />}
        </div>
      )
    );
  }
}

GalleryItem.defaultProps = {
  isPlaceholder: false,
  draggedItemsData: [],
  onItemClick: null,
};

GalleryItem.propTypes = {
  src: PropTypes.string.isRequired,
  selectedItemsIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  enableSelect: PropTypes.bool.isRequired,
  onItemClick: PropTypes.func,
  id: PropTypes.string.isRequired,
  isPlaceholder: PropTypes.bool,
  draggedItemsData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      src: PropTypes.string.isRequired,
      width: PropTypes.number,
      order: PropTypes.number,
      isPlaceholder: PropTypes.bool,
    })
  ),
  order: PropTypes.number.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  isOver: PropTypes.bool.isRequired,
};

const dragSourceSpec = {
  beginDrag(props, monitor, component) {
    const {
      id: draggedItemId,
      order,
      src,
      isPlaceholder,
      selectedItemsIds,
      items: allItems,
    } = props;
    const { width } = component.rootElement.current
      .querySelector(`.${galleryItemImageClassName}`)
      .getBoundingClientRect();
    const isSelected = component.isSelected();

    document.body.classList.add(constants.dnd.DRAGGING_CLASS);

    let draggedItemsData;
    if (isSelected) {
      const draggedItems = allItems.filter((item) => selectedItemsIds.includes(item.id));

      draggedItemsData = draggedItems.map((item) => ({
        id: item.id,
        src: item.src,
        width,
        order: item.id === draggedItemId ? order : null,
        isPlaceholder: item.isPlaceholder,
        previewStackOrder: getDragPreviewItemStackOrder({
          currentItemId: item.id,
          draggedItemId,
          draggedItems,
        }),
      }));
    } else {
      draggedItemsData = [{ id: draggedItemId, src, order, width, isPlaceholder }];
    }

    return { draggedItemsData };
  },

  endDrag(props, monitor) {
    const dropResult = monitor.getDropResult();
    const { onEndItemDrag } = props;

    if (dropResult) {
      const { draggedItemsData, dropTargetId, dropPosition } = dropResult;
      const draggedItemsIds = draggedItemsData.map((data) => data.id);

      onEndItemDrag({ draggedItemsIds, dropTargetId, dropPosition });
    }

    document.body.classList.remove(constants.dnd.DRAGGING_CLASS);
  },

  isDragging() {
    return true;
  },
};

const dropTargetSpec = {
  hover: (props, monitor, component) => {
    if (!monitor.isOver({ shallow: true }) || !monitor.canDrop()) {
      component.setDropPosition(null);

      return;
    }

    const dropPosition = getDropPosition(monitor, component);

    component.setDropPosition(dropPosition);
  },
  canDrop(props, monitor) {
    if (!monitor.isOver({ shallow: true })) {
      return false;
    }

    const { id: dropTargetId } = props;
    const { draggedItemsData } = monitor.getItem();

    if (draggedItemsData.some((item) => item.id === dropTargetId)) {
      return false;
    }

    return true;
  },

  drop(props, monitor, component) {
    if (!monitor.isOver({ shallow: true })) {
      return undefined;
    }

    const { draggedItemsData } = monitor.getItem();
    const dropTargetId = props.id;
    const dropPosition = getDropPosition(monitor, component.getDecoratedComponentInstance());

    return {
      draggedItemsData,
      dropTargetId,
      dropPosition,
    };
  },
};

function collectSource(connect, monitor) {
  const draggingData = monitor.getItem();

  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
    draggedItemsData: draggingData && draggingData.draggedItemsData,
  };
}

const collectTarget = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver({ shallow: true }),
});

export default DropTarget(
  constants.dnd.DRAG_ITEM,
  dropTargetSpec,
  collectTarget
)(DragSource(constants.dnd.DRAG_ITEM, dragSourceSpec, collectSource)(GalleryItem));
