import { view, constraints } from '@yola/ws-sdk';

const shouldElementHaveTextResizer = (elementId) => {
  if (!elementId) return false;

  const element = view.accessors.getLiveElement(elementId);
  return constraints.verifiers.canBeTextResizable(element);
};

export default shouldElementHaveTextResizer;
