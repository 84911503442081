import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import { BLOCK_FILTERS } from '../constants/common';

const { ActionButton } = designSystem;

const FilterButton = (props) => {
  const { id, label, isSelected, iconGlyph, onBlocksFilterClick } = props;

  const handleClick = () => {
    const filterId = isSelected ? BLOCK_FILTERS.none : id;

    onBlocksFilterClick(filterId);
  };

  return (
    <ActionButton
      size="small"
      appearance={isSelected ? 'accent' : 'default'}
      iconGlyph={iconGlyph}
      selected={isSelected}
      label={label}
      onClick={handleClick}
    />
  );
};

FilterButton.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  iconGlyph: PropTypes.string,
  onBlocksFilterClick: PropTypes.func.isRequired,
};

FilterButton.defaultProps = {
  isSelected: false,
  iconGlyph: null,
};

export default FilterButton;
