import getPartnerData from './partner-data';

const getAvailableSubscriptionTypes = (rootState) => {
  const partnerData = getPartnerData(rootState);

  if (partnerData && partnerData.properties && partnerData.properties.availableSubscriptionTypes) {
    return partnerData.properties.availableSubscriptionTypes;
  }

  return [];
};

export default getAvailableSubscriptionTypes;
