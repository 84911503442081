import fromPercentageToFraction from './from-percentage-to-fraction';
import fromFractionToPercentage from './from-fraction-to-percentage';

const getOpacitySteps = (from, to, step) => {
  let current = fromFractionToPercentage(from);
  const steps = [];

  while (current < fromFractionToPercentage(to)) {
    steps.push(fromPercentageToFraction(current));
    current += fromFractionToPercentage(step);
  }
  if (steps.indexOf(to) === -1) steps.push(to);
  return steps;
};

export default getOpacitySteps;
