import control from './controls/control';
import constants from './constants';

const extension = {
  slug: constants.common.slug,
  title: 'WS Gallery Customizer',
  description: 'Extension for editing image gallery',
  defaults: {
    querySelector:
      '[id*="ws-block-gallery"], [id*="ws-block-gallery"] ws-media-container[origin-src]',
    galleryBlockQuerySelector: '[id*="ws-block-gallery"],[id*="ws-block-slider-gallery"]',
    sliderGalleryBlockQuerySelector: '[id*="ws-block-slider-gallery"]',
    galleryQuerySelector: 'ws-columns, ws-slider',
    defaultAssetsPathPrefix: '/ws/blocks/',
    galleryItemSelector: 'ws-media-container[origin-src]',
    sliderGalleryItemSelector:
      'ws-slider-item[data-ws-id], ws-slider-item:not([data-ws-id]) > ws-media-container, ws-column, figure',
  },
  ui: {
    control: [control],
  },
};

export default extension;
