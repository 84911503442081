import { textFormatting } from '@yola/ws-sdk';
import unwrapElement from './unwrap-element';
import getElementTypeOptions from './get-element-type-options';
import getTypedElementSelector from './get-typed-element-selector';
import findTypedParentElements from './find-typed-parent-elements';
import removeFontSizeModifierFromNode from './remove-font-size-modifier-from-node';
import removeTextIndentModifierFromNode from './remove-text-indent-modifier-from-node';

const { wrappersTypes } = textFormatting.constants;
const { isWrapperRegistered } = textFormatting.verifiers;

const clearFormatting = (selection, elementId) => {
  const elementTypeOptions = getElementTypeOptions(elementId);
  const typedElementSelector = getTypedElementSelector(elementTypeOptions);
  const focusElements = findTypedParentElements(typedElementSelector, selection);

  focusElements.forEach((node) => {
    removeFontSizeModifierFromNode(node);
    removeTextIndentModifierFromNode(node);
  });

  Object.keys(wrappersTypes).forEach((key) => {
    if (key === 'link') return;

    const { id } = wrappersTypes[key];

    if (id === wrappersTypes.customColor.id) {
      unwrapElement(selection, elementId, wrappersTypes.customColor);
      return;
    }
    if (id === wrappersTypes.decoration.id) {
      unwrapElement(selection, elementId, wrappersTypes.decoration);
      return;
    }
    if (!isWrapperRegistered(id)) return;

    textFormatting.operations.undoWrapper(id);
  });
};

export default clearFormatting;
