import { view, hdrm, transactions, history } from '@yola/ws-sdk';
import convertInstructionsToActions from '../helpers/convert-instructions-to-actions';
import getAutomationInstructions from '../helpers/get-automation-instructions';
import isAutomationReason from '../verifiers/is-automation-reason';
import bulkActions from '../helpers/bulk-actions';
import notifyObservers from '../helpers/notify-observers';
import prefixes from '../constants/prefixes';
import eventTypes from '../constants/event-types';

const { updateStrategies } = hdrm.constants;

const closeAutomationTransaction = (store) => (next) => async (action) => {
  if (!isAutomationReason(action.type)) {
    next(action);
    return;
  }

  const { strategy, skipDesignAutomation } = action.payload.options;

  if (skipDesignAutomation) {
    next(action);
    return;
  }

  const isLiveOnlyStrategy = strategy === updateStrategies.UPDATE_LIVE_ONLY;
  let contextType;
  let state;

  if (isLiveOnlyStrategy) {
    contextType = transactions.transactionTypes.LIVE_DOCUMENT;
    state = view.accessors.getLiveDocument().cloneNode(true);
  } else {
    contextType = transactions.transactionTypes.STATIC_DOCUMENT;
    state = hdrm.instance.driver.getStaticDocument().cloneNode(true);
  }

  transactions.helpers.setTransactionState(contextType, state);

  let instructions = [];

  if (action.type === view.actionTypes.BULK_VIEW_ACTIONS) {
    const { actions } = action.payload;

    await Promise.all(
      actions.map(async (currentAction) => {
        if (!isAutomationReason(currentAction.type)) return;

        const currentInstructions = await getAutomationInstructions(currentAction, {
          prefix: prefixes.AFTER,
        });
        instructions.push(...currentInstructions);
      })
    );
  } else {
    instructions = await getAutomationInstructions(action, {
      prefix: prefixes.AFTER,
    });
  }

  const transaction = transactions.helpers.getTransactionSession();
  const wasTransactionClosed = !transaction.id;

  if (wasTransactionClosed) {
    next(action);
    return;
  }

  if (!instructions.length) {
    transactions.helpers.closeTransaction(contextType);
    notifyObservers(eventTypes.AUTOMATION_COMPLETED, instructions);
    next(action);
    return;
  }

  const automationActions = convertInstructionsToActions(instructions, strategy);

  let shouldReloadView = false;
  // view should not be reloaded every time during one particular action
  // but once at the end of automation transaction
  const postponeViewReload = (initialAction) => {
    if (initialAction.payload.options.strategy === updateStrategies.UPDATE_STATIC_ONLY) {
      // eslint-disable-next-line no-param-reassign
      initialAction.payload.options = {
        ...initialAction.payload.options,
        skipViewReload: true,
      };
      shouldReloadView = true;
    }
  };

  postponeViewReload(action);
  next(action);

  const bulkedAction = bulkActions(automationActions);

  postponeViewReload(bulkedAction);
  store.dispatch(bulkedAction);

  if (!isLiveOnlyStrategy) {
    store.dispatch(history.actions.mergeHistory(1));
  }

  if (shouldReloadView) {
    store.dispatch(view.actions.forceReloadView());
  }

  transactions.helpers.closeTransaction(contextType);

  notifyObservers(eventTypes.AUTOMATION_COMPLETED, instructions);
};

export default closeAutomationTransaction;
