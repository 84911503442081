import { serviceClient } from '@yola/ws-sdk';
import pollAsyncJob from '../../utils/poll-async-job';

const POLLING_INTERVAL = 500;

const initiateTextReplacement = async (params, signal = {}) => {
  const client = serviceClient.get();
  const response = await client.initiateTextReplacement(params, { signal });
  const {
    data: { id: jobId },
  } = response;

  return pollAsyncJob(jobId, POLLING_INTERVAL, signal);
};

export default initiateTextReplacement;
