import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import { i18next } from '@yola/ws-sdk';
import ConnectIntegrationDialogScaffolding from './connect-integration-dialog-scaffolding';

const { StepLine, Step, ControlGroup, InputGroup, FieldStatus } = designSystem;

const ConnectGoogleAnalyticsDialog = ({ captions, onSubmit, onCancel, validate }) => {
  const [trackingId, setTrackingId] = useState('');
  const [validationError, setValidationError] = useState('');

  const handleChange = (value) => {
    setValidationError('');
    setTrackingId(value);
  };

  const handleSubmit = () => {
    const trimmedValue = trackingId?.trim();
    const validationMessage = validate(trimmedValue);

    if (validationMessage) {
      setValidationError(validationMessage);
      return;
    }

    onSubmit(trimmedValue);
  };

  return (
    <ConnectIntegrationDialogScaffolding
      captions={{
        title: i18next.t('Connect {integrationType}', { integrationType: captions.name }),
        about: captions.about,
        notes: captions.notes,
      }}
      onCancel={onCancel}
      onSubmit={handleSubmit}
    >
      <StepLine>
        <Step marker={1}>
          <ControlGroup title={captions.step1.title} descriptionHTML={captions.step1.description} />
        </Step>
        <Step marker={2}>
          <ControlGroup title={captions.step2.title}>
            <InputGroup
              placeholder={captions.control.name}
              onChange={handleChange}
              invalid={!!validationError}
              value={trackingId}
            />
            {validationError && <FieldStatus appearance="error" text={validationError} />}
          </ControlGroup>
        </Step>
      </StepLine>
    </ConnectIntegrationDialogScaffolding>
  );
};

ConnectGoogleAnalyticsDialog.propTypes = {
  captions: PropTypes.shape({
    name: PropTypes.string.isRequired,
    about: PropTypes.string.isRequired,
    step1: PropTypes.shape().isRequired,
    step2: PropTypes.shape().isRequired,
    control: PropTypes.shape().isRequired,
    notes: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  validate: PropTypes.func,
};

ConnectGoogleAnalyticsDialog.defaultProps = {
  validate: () => null,
};

export default ConnectGoogleAnalyticsDialog;
