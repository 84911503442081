import { site, blocks, template } from '@yola/ws-sdk';
import segment from 'src/js/modules/analytics/segment';

const {
  track,
  constants: { events },
} = segment;

export function trackRequestWidgetButtonClicked({ wsBlockElement }) {
  const targetBlockId = blocks.accessors.getBlockIdByElement(wsBlockElement);
  const targetBlockVariationId = blocks.accessors.getVariationIdByElement(wsBlockElement);

  track(events.WIDGET_SELECTOR_REQUEST_WIDGET_BUTTON_CLICKED, {
    siteId: site.accessors.getSiteId(),
    templateBuildSlug: template.accessors.getBuildSlug(),
    targetBlockId,
    targetBlockVariationId,
  });
}

export function trackWidgetSearchClicked({ wsBlockElement }) {
  const targetBlockId = blocks.accessors.getBlockIdByElement(wsBlockElement);
  const targetBlockVariationId = blocks.accessors.getVariationIdByElement(wsBlockElement);

  track(events.WIDGET_SELECTOR_SEARCH_CLICKED, {
    siteId: site.accessors.getSiteId(),
    templateBuildSlug: template.accessors.getBuildSlug(),
    targetBlockId,
    targetBlockVariationId,
  });
}

export function trackWidgetSearchPerformed({ wsBlockElement, searchTerm, searchResults }) {
  const targetBlockId = blocks.accessors.getBlockIdByElement(wsBlockElement);
  const targetBlockVariationId = blocks.accessors.getVariationIdByElement(wsBlockElement);

  track(events.WIDGET_SELECTOR_SEARCH_PERFORMED, {
    siteId: site.accessors.getSiteId(),
    templateBuildSlug: template.accessors.getBuildSlug(),
    targetBlockId,
    targetBlockVariationId,
    searchTerm,
    searchResults,
  });
}
