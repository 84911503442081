import { constants } from '@yola/subscription-manager-js';

const subscriptionToEnableShopPageTemplate = [
  ...constants.B2C_ECOMMERCE_PAID_TYPES,
  ...constants.WL_ECOMMERCE_PAID_TYPES,
];

const shopPageTemplateLimit = ({ availableSubscriptionTypes }) => {
  const hasSubscriptionToEnableShopPageTemplate = availableSubscriptionTypes.some(
    (subscriptionType) => subscriptionToEnableShopPageTemplate.includes(subscriptionType)
  );

  return {
    available: hasSubscriptionToEnableShopPageTemplate,
  };
};

export default shopPageTemplateLimit;
