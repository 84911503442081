import actionTypes from 'src/js/modules/context-menu/constants/action-types';

const initialState = {
  groups: [],
  visibility: false,
  position: {
    x: 0,
    y: 0,
  },
};

const menu = (state = initialState, action) => {
  if (action.type === actionTypes.SHOW_CONTEXT_MENU) {
    return {
      ...state,
      visibility: true,
      ...action.payload,
    };
  }

  if (action.type === actionTypes.HIDE_CONTEXT_MENU) {
    return {
      ...initialState,
    };
  }

  return state;
};

export default menu;
