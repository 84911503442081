import { hdrm } from '@yola/ws-sdk';
import getWsNav from './get-ws-nav';

const removeContentEditable = (node) => {
  const navComponent = getWsNav();

  if (!navComponent) return null;

  const { selectors } = navComponent;
  const navigationItems = node.querySelectorAll(
    `${selectors.menu} ${selectors.itemLink}, ${selectors.menu} ${selectors.itemText}`
  );

  navigationItems.forEach((element) => {
    hdrm.injections.bindings.text.remove(element);
  });

  return node;
};

export default removeContentEditable;
