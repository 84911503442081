let action;
let details;

const storage = {
  setEditorUpgradeAction: (editorUpgradeAction) => {
    action = editorUpgradeAction;
  },
  getEditorUpgradeAction: () => action,
  setUpgradeDetails: (upgradeDetails) => {
    details = upgradeDetails;
  },
  getUpgradeDetails: () => details,
};

export default storage;
