import PageManagerContainer from './page-manager-container';
import NewPageDialogContainer from './new-page-dialog-container';
import PageDeleteContainer from './page-delete-container';
import PageButtonContainer from './page-button-container';

export default {
  PageManagerContainer,
  NewPageDialogContainer,
  PageDeleteContainer,
  PageButtonContainer,
};
