import { view, hdrm } from '@yola/ws-sdk';

const { updateStrategies } = hdrm.constants;

const updateLiveElementAttribute = (elementId, attr, value) => {
  if (value) {
    view.operations.setElementAttribute(elementId, attr, value, {
      strategy: updateStrategies.UPDATE_LIVE_ONLY,
    });
  } else {
    view.operations.removeElementAttribute(elementId, attr, {
      strategy: updateStrategies.UPDATE_LIVE_ONLY,
    });
  }
};

export default updateLiveElementAttribute;
