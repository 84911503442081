import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Slider, designSystem } from '@yola/ws-ui';
import scoreTypes from '../constants/score-types';

const { Stack, Paragraph } = designSystem;

function Scores({
  minScore,
  maxScore,
  selectedScore,
  scoreType,
  captions,
  isDesktopView,
  onScoreChange,
}) {
  const scores = [...Array(maxScore - minScore + 1).keys()].map((n) => n + minScore);
  const hasType = Object.values(scoreTypes).includes(scoreType);
  const classes = classNames('ws-scores', {
    'ws-scores--desktop-view': isDesktopView,
  });

  return (
    <div className={classes}>
      <Stack gap="spacing-3-xs">
        {isDesktopView ? (
          <ul className="ws-scores__list">
            {scores.map((score) => (
              <li className="ws-scores__item" key={score}>
                <Paragraph
                  appearance={hasType && score <= selectedScore ? 'white' : 'high-emphasis'}
                  size="small"
                >
                  <button
                    type="button"
                    className={classNames('ws-scores__button', {
                      [`ws-scores__button--${scoreType}`]: hasType && score <= selectedScore,
                    })}
                    onClick={() => {
                      onScoreChange(score);
                    }}
                  >
                    {score}
                  </button>
                </Paragraph>
              </li>
            ))}
          </ul>
        ) : (
          <Fragment>
            <div className="ws-scores__captions">
              <Paragraph appearance="low-emphasis">{minScore}</Paragraph>
              <Paragraph appearance="low-emphasis">{maxScore}</Paragraph>
            </div>
            <Slider
              value={selectedScore}
              max={maxScore}
              min={minScore}
              className={classNames('ws-scores__slider', {
                [`ws-scores__slider--${scoreType}`]: hasType,
              })}
              tooltipEnabled
              onChange={onScoreChange}
            />
          </Fragment>
        )}
        <div className="ws-scores__captions">
          <Paragraph
            appearance="low-emphasis"
            size="small"
          >{`${captions.minScoreTitle}`}</Paragraph>
          <Paragraph
            appearance="low-emphasis"
            size="small"
          >{`${captions.maxScoreTitle}`}</Paragraph>
        </div>
      </Stack>
    </div>
  );
}

Scores.propTypes = {
  minScore: PropTypes.number,
  maxScore: PropTypes.number,
  selectedScore: PropTypes.number,
  scoreType: PropTypes.oneOfType([PropTypes.oneOf(Object.values(scoreTypes)), null]),
  captions: PropTypes.shape({
    minScoreTitle: PropTypes.string,
    maxScoreTitle: PropTypes.string,
  }),
  isDesktopView: PropTypes.bool.isRequired,
  onScoreChange: PropTypes.func.isRequired,
};

Scores.defaultProps = {
  minScore: 0,
  maxScore: 10,
  selectedScore: undefined,
  scoreType: null,
  captions: null,
};

export default Scores;
