import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import bowser from 'yola-bowser';

const context = React.createContext();
const { Provider, Consumer } = context;

const PORTRAIT = 'portrait';
const LANDSCAPE = 'landscape';

const calculateViewportSize = (window) => {
  let { innerHeight: height } = window;
  const { innerWidth: width } = window;
  if (bowser.mobile || bowser.tablet) {
    const { scrollTop } = document.body;
    if (scrollTop > 0) {
      height = window.innerHeight + scrollTop;
    }
  }

  const orientation = window.matchMedia(`(orientation: ${PORTRAIT})`).matches
    ? PORTRAIT
    : LANDSCAPE;
  return {
    width,
    height,
    orientation,
  };
};

function ViewportProvider(props) {
  const { children } = props;
  const [viewportInfo, setViewportInfo] = useState(() => calculateViewportSize(window));

  useEffect(() => {
    const onResize = () => {
      setViewportInfo(calculateViewportSize(window));
    };
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const { height, width, orientation } = viewportInfo;

  return <Provider value={{ height, width, orientation }}>{children}</Provider>;
}

ViewportProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ViewportProvider, Consumer as ViewportConsumer, context };
