import { i18next } from '@yola/ws-sdk';

const getLanguages = () => ({
  af: {
    name: i18next.t('Afrikaans'),
    nativeName: 'Afrikaans',
    countries: [
      {
        languageCode: 'af',
        countryCode: 'za',
      },
    ],
  },
  sq: {
    name: i18next.t('Albanian'),
    nativeName: 'Shqip',
    countries: [
      {
        languageCode: 'sq',
        countryCode: 'al',
      },
    ],
  },
  ar: {
    name: i18next.t('Arabic'),
    nativeName: 'اللغة العربية',
    countries: [
      {
        languageCode: 'ar',
        countryCode: 'arl',
      },
      {
        languageCode: 'ar-dz',
        countryCode: 'dz',
      },
      {
        languageCode: 'ar-bh',
        countryCode: 'bh',
      },
      {
        languageCode: 'ar-eg',
        countryCode: 'eg',
      },
      {
        languageCode: 'ar-iq',
        countryCode: 'iq',
      },
      {
        languageCode: 'ar-jo',
        countryCode: 'jo',
      },
      {
        languageCode: 'ar-kw',
        countryCode: 'kw',
      },
      {
        languageCode: 'ar-lb',
        countryCode: 'lb',
      },
      {
        languageCode: 'ar-ly',
        countryCode: 'ly',
      },
      {
        languageCode: 'ar-ma',
        countryCode: 'ma',
      },
      {
        languageCode: 'ar-om',
        countryCode: 'om',
      },
      {
        languageCode: 'ar-qa',
        countryCode: 'qa',
      },
      {
        languageCode: 'ar-sa',
        countryCode: 'sa',
      },
      {
        languageCode: 'ar-sy',
        countryCode: 'sy',
      },
      {
        languageCode: 'ar-tn',
        countryCode: 'tn',
      },
      {
        languageCode: 'ar-ae',
        countryCode: 'ae',
      },
      {
        languageCode: 'ar-ye',
        countryCode: 'ye',
      },
    ],
  },
  eu: {
    name: i18next.t('Basque'),
    nativeName: 'euskara',
    countries: [
      {
        languageCode: 'eu',
        countryCode: 'eu',
      },
    ],
  },
  be: {
    name: i18next.t('Belarusian'),
    nativeName: 'беларуская мова',
    countries: [
      {
        languageCode: 'be',
        countryCode: 'by',
      },
    ],
  },
  bg: {
    name: i18next.t('Bulgarian'),
    nativeName: 'български език',
    countries: [
      {
        languageCode: 'bg',
        countryCode: 'bg',
      },
    ],
  },
  ca: {
    name: i18next.t('Catalan'),
    nativeName: 'Català',
    countries: [
      {
        languageCode: 'ca',
        countryCode: 'est',
      },
    ],
  },
  zh: {
    name: i18next.t('Chinese Simplified'),
    nativeName: '简体',
    countries: [
      {
        languageCode: 'zh-cn',
        countryCode: 'cn',
      },
      {
        languageCode: 'zh-sg',
        countryCode: 'sg',
      },
      {
        languageCode: 'zh-tw',
        languageName: i18next.t('Chinese Traditional'),
        nativeName: '繁體',
        countryCode: 'tw',
      },
      {
        languageCode: 'zh-hk',
        languageName: i18next.t('Chinese Traditional'),
        nativeName: '繁體',
        countryCode: 'hk',
      },
      {
        languageCode: 'zh-mo',
        languageName: i18next.t('Chinese Traditional'),
        nativeName: '繁體',
        countryCode: 'mo',
      },
    ],
  },
  hr: {
    name: i18next.t('Croatian'),
    nativeName: 'hrvatski jezik',
    countries: [
      {
        languageCode: 'hr',
        countryCode: 'hr',
      },
    ],
  },
  cs: {
    name: i18next.t('Czech'),
    nativeName: 'čeština',
    countries: [
      {
        languageCode: 'cs',
        countryCode: 'cz',
      },
    ],
  },
  da: {
    name: i18next.t('Danish'),
    nativeName: 'dansk',
    countries: [
      {
        languageCode: 'da',
        countryCode: 'dk',
      },
    ],
  },
  nl: {
    name: i18next.t('Dutch'),
    nativeName: 'Nederlands',
    countries: [
      {
        languageCode: 'nl',
        countryCode: 'nl',
      },
      {
        languageCode: 'nl-be',
        countryCode: 'be',
      },
    ],
  },
  en: {
    name: i18next.t('English'),
    nativeName: 'English',
    countries: [
      {
        languageCode: 'en',
        countryCode: 'us',
      },
      {
        languageCode: 'en-au',
        countryCode: 'au',
      },
      {
        languageCode: 'en-bz',
        countryCode: 'bz',
      },
      {
        languageCode: 'en-ca',
        countryCode: 'ca',
      },
      {
        languageCode: 'en-ie',
        countryCode: 'ie',
      },
      {
        languageCode: 'en-jm',
        countryCode: 'jm',
      },
      {
        languageCode: 'en-nz',
        countryCode: 'nz',
      },
      {
        languageCode: 'en-za',
        countryCode: 'za',
      },
      {
        languageCode: 'en-tt',
        countryCode: 'tt',
      },
      {
        languageCode: 'en-gb',
        countryCode: 'gb',
      },
      {
        languageCode: 'en-us',
        countryCode: 'us',
      },
    ],
  },
  et: {
    name: i18next.t('Estonian'),
    nativeName: 'eesti',
    countries: [
      {
        languageCode: 'et',
        countryCode: 'ee',
      },
    ],
  },
  fo: {
    name: i18next.t('Faroese'),
    nativeName: 'føroyskt',
    countries: [
      {
        languageCode: 'fo',
        countryCode: 'dk',
      },
    ],
  },
  fa: {
    name: i18next.t('Farsi'),
    nativeName: 'فارسی',
    countries: [
      {
        languageCode: 'fa',
        countryCode: 'ir',
      },
      {
        languageCode: 'fa-iq',
        countryCode: 'iq',
      },
      {
        languageCode: 'fa-bh',
        countryCode: 'bh',
      },
      {
        languageCode: 'fa-kw',
        countryCode: 'kw',
      },
      {
        languageCode: 'fa-af',
        countryCode: 'af',
      },
      {
        languageCode: 'fa-pk',
        countryCode: 'pk',
      },
      {
        languageCode: 'fa-tj',
        countryCode: 'tj',
      },
      {
        languageCode: 'fa-uz',
        countryCode: 'uz',
      },
    ],
  },
  fi: {
    name: i18next.t('Finnish'),
    nativeName: 'suomi',
    countries: [
      {
        languageCode: 'fi',
        countryCode: 'fi',
      },
    ],
  },
  fr: {
    name: i18next.t('French'),
    nativeName: 'Français',
    countries: [
      { languageCode: 'fr', countryCode: 'fr' },
      { languageCode: 'fr-be', countryCode: 'be' },
      { languageCode: 'fr-ca', countryCode: 'ca' },
      { languageCode: 'fr-lu', countryCode: 'lu' },
      { languageCode: 'fr-ch', countryCode: 'ch' },
    ],
  },
  gd: {
    name: i18next.t('Scottish Gaelic'),
    nativeName: 'Gàidhlig',
    countries: [
      {
        languageCode: 'gd',
        countryCode: 'gd',
      },
    ],
  },
  de: {
    name: i18next.t('German'),
    nativeName: 'Deutsch',
    countries: [
      {
        languageCode: 'de',
        countryCode: 'de',
      },
      {
        languageCode: 'de-at',
        countryCode: 'at',
      },
      {
        languageCode: 'de-li',
        countryCode: 'li',
      },
      {
        languageCode: 'de-lu',
        countryCode: 'lu',
      },
      {
        languageCode: 'de-ch',
        countryCode: 'ch',
      },
    ],
  },
  el: {
    name: i18next.t('Greek'),
    nativeName: 'Ελληνικά',
    countries: [
      {
        languageCode: 'el',
        countryCode: 'gr',
      },
    ],
  },
  he: {
    name: i18next.t('Hebrew'),
    nativeName: 'עברית',
    countries: [
      {
        languageCode: 'he',
        countryCode: 'il',
      },
    ],
  },
  hi: {
    name: i18next.t('Hindi'),
    nativeName: 'हिन्दी',
    countries: [
      {
        languageCode: 'hi',
        countryCode: 'in',
      },
    ],
  },
  hu: {
    name: i18next.t('Hungarian'),
    nativeName: 'Magyar',
    countries: [
      {
        languageCode: 'hu',
        countryCode: 'hu',
      },
    ],
  },
  is: {
    name: i18next.t('Icelandic'),
    nativeName: 'Íslenska',
    countries: [
      {
        languageCode: 'is',
        countryCode: 'is',
      },
    ],
  },
  id: {
    name: i18next.t('Indonesian'),
    nativeName: 'Bahasa Indonesia',
    countries: [
      {
        languageCode: 'id',
        countryCode: 'id',
      },
    ],
  },
  ga: {
    name: i18next.t('Irish'),
    nativeName: 'Gaeilge',
    countries: [
      {
        languageCode: 'ga',
        countryCode: 'ie',
      },
    ],
  },
  it: {
    name: i18next.t('Italian'),
    nativeName: 'Italiano',
    countries: [
      {
        languageCode: 'it',
        countryCode: 'it',
      },
      {
        languageCode: 'it-ch',
        countryCode: 'ch',
      },
    ],
  },
  ja: {
    name: i18next.t('Japanese'),
    nativeName: '日本語',
    countries: [
      {
        languageCode: 'ja',
        countryCode: 'jp',
      },
    ],
  },
  ko: {
    name: i18next.t('Korean'),
    nativeName: '한국어',
    countries: [
      {
        languageCode: 'ko',
        countryCode: 'kr',
      },
    ],
  },
  ku: {
    name: i18next.t('Kurdish'),
    nativeName: 'کوردی',
    countries: [
      {
        languageCode: 'ku',
        countryCode: 'ku',
      },
      {
        languageCode: 'ku',
        countryCode: 'tr',
      },
      {
        languageCode: 'ku-iq',
        countryCode: 'iq',
      },
      {
        languageCode: 'ku-ir',
        countryCode: 'ir',
      },
      {
        languageCode: 'ku-sy',
        countryCode: 'sy',
      },
      {
        languageCode: 'ku-de',
        countryCode: 'de',
      },
      {
        languageCode: 'ku-af',
        countryCode: 'af',
      },
      {
        languageCode: 'ku-az',
        countryCode: 'az',
      },
      {
        languageCode: 'ku-lb',
        countryCode: 'lb',
      },
      {
        languageCode: 'ku-ru',
        countryCode: 'ru',
      },
      {
        languageCode: 'ku-ge',
        countryCode: 'ge',
      },
      {
        languageCode: 'ku-am',
        countryCode: 'am',
      },
      {
        languageCode: 'ku-kz',
        countryCode: 'kz',
      },
      {
        languageCode: 'ku-gb',
        countryCode: 'gb',
      },
      {
        languageCode: 'ku-il',
        countryCode: 'il',
      },
      {
        languageCode: 'ku-tm',
        countryCode: 'tm',
      },
    ],
  },
  lv: {
    name: i18next.t('Latvian'),
    nativeName: 'latviešu valoda',
    countries: [
      {
        languageCode: 'lv',
        countryCode: 'lv',
      },
    ],
  },
  lt: {
    name: i18next.t('Lithuanian'),
    nativeName: 'lietuvių kalba',
    countries: [
      {
        languageCode: 'lt',
        countryCode: 'lt',
      },
    ],
  },
  moh: {
    name: i18next.t('Mohawk'),
    nativeName: 'Kanien’kéha',
    countries: [
      {
        languageCode: 'moh',
        countryCode: 'moh',
      },
    ],
  },
  mk: {
    name: i18next.t('Macedonian'),
    nativeName: 'македонски јазик',
    countries: [
      {
        languageCode: 'mk',
        countryCode: 'mk',
      },
    ],
  },
  ml: {
    name: i18next.t('Malayalam'),
    nativeName: 'മലയാളം',
    countries: [
      {
        languageCode: 'ml',
        countryCode: 'in',
      },
    ],
  },
  mi: {
    name: i18next.t('Maori'),
    nativeName: 'te reo Māori',
    countries: [
      {
        languageCode: 'mi',
        countryCode: 'nz',
      },
    ],
  },
  ms: {
    name: i18next.t('Malay'),
    nativeName: 'Bahasa Malaysia',
    countries: [
      {
        languageCode: 'ms',
        countryCode: 'my',
      },
    ],
  },
  mt: {
    name: i18next.t('Maltese'),
    nativeName: 'Malti',
    countries: [
      {
        languageCode: 'mt',
        countryCode: 'mt',
      },
    ],
  },
  no: {
    name: i18next.t('Norwegian'),
    nativeName: 'Norsk',
    countries: [
      {
        languageCode: 'no',
        countryCode: 'no',
      },
    ],
  },
  nb: {
    name: i18next.t('Norwegian Bokmål'),
    nativeName: 'Norsk bokmål',
    countries: [
      {
        languageCode: 'nb',
        countryCode: 'no',
      },
    ],
  },
  nn: {
    name: i18next.t('Norwegian Nynorsk'),
    nativeName: 'Norsk nynorsk',
    countries: [
      {
        languageCode: 'nn',
        countryCode: 'no',
      },
    ],
  },
  uz: {
    name: i18next.t('Uzbek'),
    nativeName: 'Oʻzbekcha',
    countries: [
      {
        languageCode: 'uz',
        countryCode: 'uz',
      },
    ],
  },
  pl: {
    name: i18next.t('Polish'),
    nativeName: 'język polski',
    countries: [
      {
        languageCode: 'pl',
        countryCode: 'pl',
      },
    ],
  },
  pt: {
    name: i18next.t('Portuguese'),
    nativeName: 'Português',
    countries: [
      {
        languageCode: 'pt',
        countryCode: 'pt',
      },
      {
        languageCode: 'pt-br',
        countryCode: 'br',
      },
    ],
  },
  pa: {
    name: i18next.t('Punjabi'),
    nativeName: 'ਪੰਜਾਬੀ',
    countries: [
      {
        languageCode: 'pa',
        countryCode: 'pk',
      },
      {
        languageCode: 'pa-in',
        countryCode: 'in',
      },
    ],
  },
  rm: {
    name: i18next.t('Rhaeto-Romanic'),
    nativeName: 'rumantsch grischun',
    countries: [
      {
        languageCode: 'rm',
        countryCode: 'ch',
      },
    ],
  },
  ro: {
    name: i18next.t('Romanian'),
    nativeName: 'Română',
    countries: [
      {
        languageCode: 'ro',
        countryCode: 'ro',
      },
      {
        languageCode: 'ro-md',
        countryCode: 'md',
      },
    ],
  },
  ru: {
    name: i18next.t('Russian'),
    nativeName: 'Русский',
    countries: [
      {
        languageCode: 'ru',
        countryCode: 'ru',
      },
      {
        languageCode: 'ru-md',
        countryCode: 'md',
      },
    ],
  },
  sr: {
    name: i18next.t('Serbian'),
    nativeName: 'српски језик',
    countries: [
      {
        languageCode: 'sr',
        countryCode: 'rs',
      },
    ],
  },
  sk: {
    name: i18next.t('Slovak'),
    nativeName: 'slovenčina',
    countries: [
      {
        languageCode: 'sk',
        countryCode: 'sk',
      },
    ],
  },
  sl: {
    name: i18next.t('Slovene'),
    nativeName: 'slovenski jezik',
    countries: [
      {
        languageCode: 'sl',
        countryCode: 'si',
      },
    ],
  },
  sb: {
    name: i18next.t('Sorbian'),
    nativeName: 'Serbja',
    countries: [
      {
        languageCode: 'sb',
        countryCode: 'de',
      },
    ],
  },
  es: {
    name: i18next.t('Spanish'),
    nativeName: 'Español',
    countries: [
      {
        languageCode: 'es',
        countryCode: 'es',
      },
      {
        languageCode: 'es-ar',
        countryCode: 'ar',
      },
      {
        languageCode: 'es-bo',
        countryCode: 'bo',
      },
      {
        languageCode: 'es-cl',
        countryCode: 'cl',
      },
      {
        languageCode: 'es-co',
        countryCode: 'co',
      },
      {
        languageCode: 'es-cr',
        countryCode: 'cr',
      },
      {
        languageCode: 'es-do',
        countryCode: 'do',
      },
      {
        languageCode: 'es-ec',
        countryCode: 'ec',
      },
      {
        languageCode: 'es-sv',
        countryCode: 'sv',
      },
      {
        languageCode: 'es-gt',
        countryCode: 'gt',
      },
      {
        languageCode: 'es-hn',
        countryCode: 'hn',
      },
      {
        languageCode: 'es-mx',
        countryCode: 'mx',
      },
      {
        languageCode: 'es-ni',
        countryCode: 'ni',
      },
      {
        languageCode: 'es-pa',
        countryCode: 'pa',
      },
      {
        languageCode: 'es-py',
        countryCode: 'py',
      },
      {
        languageCode: 'es-pe',
        countryCode: 'pe',
      },
      {
        languageCode: 'es-pr',
        countryCode: 'pr',
      },
      {
        languageCode: 'es-uy',
        countryCode: 'uy',
      },
      {
        languageCode: 'es-ve',
        countryCode: 've',
      },
    ],
  },
  sv: {
    name: i18next.t('Swedish'),
    nativeName: 'svenska',
    countries: [
      {
        languageCode: 'sv',
        countryCode: 'se',
      },
      {
        languageCode: 'sv-fi',
        countryCode: 'fi',
      },
    ],
  },
  th: {
    name: i18next.t('Thai'),
    nativeName: 'ไทย',
    countries: [
      {
        languageCode: 'th',
        countryCode: 'th',
      },
    ],
  },
  ts: {
    name: i18next.t('Tsonga'),
    nativeName: 'Xitsonga',
    countries: [
      {
        languageCode: 'ts',
        countryCode: 'za',
      },
    ],
  },
  tn: {
    name: i18next.t('Tswana'),
    nativeName: 'Setswana',
    countries: [
      {
        languageCode: 'tn',
        countryCode: 'bw',
      },
    ],
  },
  tr: {
    name: i18next.t('Turkish'),
    nativeName: 'Türkçe',
    countries: [
      {
        languageCode: 'tr',
        countryCode: 'tr',
      },
    ],
  },
  uk: {
    name: i18next.t('Ukrainian'),
    nativeName: 'Українська',
    countries: [
      {
        languageCode: 'uk',
        countryCode: 'ua',
      },
    ],
  },
  ur: {
    name: i18next.t('Urdu'),
    nativeName: 'اردو',
    countries: [
      {
        languageCode: 'ur',
        countryCode: 'pk',
      },
      {
        languageCode: 'ur-in',
        countryCode: 'in',
      },
    ],
  },
  ve: {
    name: i18next.t('Venda'),
    nativeName: 'Tshivenḓa',
    countries: [
      {
        languageCode: 've',
        countryCode: 'za',
      },
      {
        languageCode: 've-zw',
        countryCode: 'zw',
      },
    ],
  },
  vi: {
    name: i18next.t('Vietnamese'),
    nativeName: 'Tiếng Việt',
    countries: [
      {
        languageCode: 'vi',
        countryCode: 'vn',
      },
    ],
  },
  cy: {
    name: i18next.t('Welsh'),
    nativeName: 'Cymraeg',
    countries: [
      {
        languageCode: 'cy',
        countryCode: 'wls',
      },
    ],
  },
  xh: {
    name: i18next.t('Xhosa'),
    nativeName: 'isiXhosa',
    countries: [
      {
        languageCode: 'xh',
        countryCode: 'za',
      },
    ],
  },
  ji: {
    name: i18next.t('Yiddish'),
    nativeName: 'יידיש',
    countries: [
      {
        languageCode: 'ji',
        countryCode: 'il',
      },
      {
        languageCode: 'ji-au',
        countryCode: 'au',
      },
      {
        languageCode: 'ji-at',
        countryCode: 'at',
      },
      {
        languageCode: 'ji-ar',
        countryCode: 'ar',
      },
      {
        languageCode: 'ji-by',
        countryCode: 'by',
      },
      {
        languageCode: 'ji-be',
        countryCode: 'be',
      },
      {
        languageCode: 'ji-ba',
        countryCode: 'ba',
      },
      {
        languageCode: 'ji-br',
        countryCode: 'br',
      },
      {
        languageCode: 'ji-gb',
        countryCode: 'gb',
      },
      {
        languageCode: 'ji-hu',
        countryCode: 'hu',
      },
      {
        languageCode: 'ji-de',
        countryCode: 'de',
      },
      {
        languageCode: 'ji-ca',
        countryCode: 'ca',
      },
      {
        languageCode: 'ji-cr',
        countryCode: 'cr',
      },
      {
        languageCode: 'ji-lv',
        countryCode: 'lv',
      },
      {
        languageCode: 'ji-lt',
        countryCode: 'lt',
      },
      {
        languageCode: 'ji-mx',
        countryCode: 'mx',
      },
      {
        languageCode: 'ji-md',
        countryCode: 'md',
      },
      {
        languageCode: 'ji-nl',
        countryCode: 'nl',
      },
      {
        languageCode: 'ji-pa',
        countryCode: 'pa',
      },
      {
        languageCode: 'ji-pl',
        countryCode: 'pl',
      },
      {
        languageCode: 'ji-pr',
        countryCode: 'pr',
      },
      {
        languageCode: 'ji-ru',
        countryCode: 'ru',
      },
      {
        languageCode: 'ji-us',
        countryCode: 'us',
      },
      {
        languageCode: 'ji-ua',
        countryCode: 'ua',
      },
      {
        languageCode: 'ji-uy',
        countryCode: 'uy',
      },
      {
        languageCode: 'ji-fr',
        countryCode: 'fr',
      },
      {
        languageCode: 'ji-ch',
        countryCode: 'ch',
      },
      {
        languageCode: 'ji-se',
        countryCode: 'se',
      },
      {
        languageCode: 'ji-ee',
        countryCode: 'ee',
      },
      {
        languageCode: 'ji-za',
        countryCode: 'za',
      },
    ],
  },
  zu: {
    name: i18next.t('Zulu'),
    nativeName: 'isiZulu',
    countries: [
      {
        languageCode: 'zu',
        countryCode: 'za',
      },
    ],
  },
});

export default getLanguages;
