import { constraints, extensions } from '@yola/ws-sdk';
import isEqualBounds, { isEqualBottomBounds } from 'src/js/modules/common/helpers/is-equal-bounds';
import isFocusedElement from 'src/js/modules/highlighter/verifiers/is-focused-element';
import config from '../constants/config';

// Here we keep a selector with an ancestor element that can contain a `ws-image-container` that overlaps with rich edited text. And since we rarely use `figure` tag in blocks, it allows us to quickly cut off unsuitable options and not conduct additional, more expensive checks. Also, if necessary, you can expand this selector.
const ANCESTOR_SELECTOR = 'figure';

const getOverlappedMediaContainerByText = (targetElement, checkOnlyBottomBounds) => {
  if (
    !targetElement ||
    !constraints.verifiers.canBeRichEdited(targetElement) ||
    !isFocusedElement(targetElement)
  ) {
    return null;
  }

  const ancestorElement = targetElement.closest(ANCESTOR_SELECTOR);

  if (!ancestorElement) {
    return null;
  }

  const settings = extensions.accessors.getExtensionSettings(config.slug);
  const mediaContainer = ancestorElement.querySelector(settings.imageContainer);

  if (!mediaContainer) {
    return null;
  }

  const mediaContainerBounds = mediaContainer.getBoundingClientRect();
  const textBounds = targetElement.getBoundingClientRect();

  if (checkOnlyBottomBounds && isEqualBottomBounds(mediaContainerBounds, textBounds)) {
    return mediaContainer;
  }

  if (isEqualBounds(mediaContainerBounds, textBounds)) {
    return mediaContainer;
  }

  return null;
};

export default getOverlappedMediaContainerByText;
