import { serviceClient } from '@yola/ws-sdk';
import getDefaultPlatformOption from './get-default-platform-options';
import iconTypes from '../constants/icon-types';
import constants from '../constants/common';

const { INIT_NUMBER_OF_ICONS_ON_SCREEN } = constants;

const getIconsList = async ({ offset, iconColor, platform, iconType, language, onRequestFail }) => {
  let params = {
    offset,
    language,
    limit: INIT_NUMBER_OF_ICONS_ON_SCREEN,
    color: iconColor,
  };

  if (platform !== getDefaultPlatformOption().value) {
    params = { ...params, platform };
  }

  if (iconType === iconTypes.STATIC) {
    params = { ...params, isAnimated: false };
  } else if (iconType === iconTypes.ANIMATED) {
    params = { ...params, isAnimated: true };
  }

  try {
    const client = serviceClient.get();
    const response = await client.icons8ListIcons(params);
    const {
      data: { docs, total },
    } = response;

    return { icons: docs, iconsCount: total };
  } catch (error) {
    console.error(error);

    // when no icons found icons8 sends 404 error
    if (error?.response?.status !== 404) {
      onRequestFail();
    }

    return { icons: [], iconsCount: 0 };
  }
};

export default getIconsList;
