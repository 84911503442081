import { extensions } from '@yola/ws-sdk';
import config from '../constants/config';

const isPositioningImage = (element) => {
  const { imagePositioningSelector, imagePositioningRestrictedParentsSelector } =
    extensions.accessors.getExtensionSettings(config.slug);

  if (
    imagePositioningRestrictedParentsSelector &&
    element.closest(imagePositioningRestrictedParentsSelector)
  ) {
    return false;
  }

  return element.matches(imagePositioningSelector);
};

export default isPositioningImage;
