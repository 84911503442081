import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@yola/ws-ui';
import BlockSettingsOption from './block-settings-option';

function BlockSettingsOptions(props) {
  const { captions, items, keySalt } = props;

  if (!items || !items.length) return null;

  return (
    <React.Fragment>
      {Boolean(captions) && (
        <div className="ws-block-settings-dialog__section">
          <Text type="heading-6">{captions.title}</Text>
          <Text type="annotation" theme="grey">
            {captions.subtitle}
          </Text>
        </div>
      )}

      {items.map((item) => (
        <BlockSettingsOption
          keySalt={keySalt}
          item={item}
          key={`${item.id}-${item.attribute}-${keySalt}`}
        />
      ))}
    </React.Fragment>
  );
}

BlockSettingsOptions.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      label: PropTypes.string,
      type: PropTypes.string.isRequired,
      isEnabled: PropTypes.bool,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
      onClick: PropTypes.func,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          onClick: PropTypes.func,
        })
      ),
    })
  ),
  captions: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
  }),
  keySalt: PropTypes.string,
};

BlockSettingsOptions.defaultProps = {
  items: null,
  captions: null,
  keySalt: null,
};

export default BlockSettingsOptions;
