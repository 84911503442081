import React, { useState, useRef } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { view, dialogs } from '@yola/ws-sdk';
import { Spring } from 'react-spring/renderprops';
import getSameBoundsElements from '../../common/helpers/get-same-bounds-elements';
import findAllFor from '../filters/find-all-for';
import operations from '../operations';

const animationConfig = {
  from: {
    scale: 0.6,
    opacity: 0,
  },
  to: {
    scale: 1,
    opacity: 1,
  },
  config: { tension: 250, friction: 16 },
};

function collectCustomTools(elements) {
  return elements.reduce((tools, element) => {
    const matchedTools = findAllFor(element);

    if (!matchedTools.length) {
      return tools;
    }

    return tools.concat(
      matchedTools.map(({ id, container }) => ({
        id,
        elementId: view.accessors.getLiveElementId(element),
        container,
      }))
    );
  }, []);
}

function CustomToolsContainer() {
  const { selectedElementId, scrollPosition, isLoaded, focusedElementId, isDialogVisible } =
    useSelector(
      (state) => ({
        selectedElementId: view.selectors.getSelectedElement(state),
        scrollPosition: view.selectors.getScrollPosition(state),
        isLoaded: view.selectors.getLoadedStatus(state),
        focusedElementId: view.selectors.getFocusedElement(state),
        isDialogVisible: dialogs.verifiers.isVisible(),
      }),
      shallowEqual
    );

  const [activeTool, setActiveTool] = useState(null);

  const savedSelectedElementId = useRef(null);

  // We do not need to show any custom tool when there opened dialog
  if (isDialogVisible) return null;

  // Why isLoaded is so important?(For better understanding you have to know how SyncyFrame component works)
  //
  // When editor reloads\changes page, we have some time(amount depends on your internet connection)
  // to iterate with elements on the page, which will be changed soon. And in that cases, if we
  // call any matches function from extensions, we will face the trouble that our element's document,
  // in some moment of time, will lose window context and editor will throw errors.
  // To prevent this behavior we added checking isLoaded status.
  if (!isLoaded) return null;

  let customTools = [];
  let liveElementId;

  if (focusedElementId) {
    const focuseElement = view.accessors.getLiveElement(focusedElementId);
    const elements = focuseElement ? [focuseElement] : [];

    customTools = collectCustomTools(elements);
    liveElementId = focusedElementId;
  }

  if (selectedElementId) {
    const selectedElement = view.accessors.getLiveElement(selectedElementId);
    const sameBoundsElements = selectedElement ? getSameBoundsElements(selectedElement) : [];

    customTools = collectCustomTools(sameBoundsElements);
    liveElementId = selectedElementId;
  }

  if (!liveElementId || !customTools.length) {
    return null;
  }

  const handleActionEnd = () => {
    setActiveTool(null);
    operations.setActiveTool(null);
  };

  const toolsList = activeTool ? [activeTool] : customTools;

  return (
    <div className="ws-custom-tools">
      {activeTool && (
        <div
          className="ws-custom-tools__overlay"
          style={{ transform: `translate3d(0, ${scrollPosition}px, 0)` }}
        />
      )}

      {toolsList.map((tool) => {
        const { id, elementId, container: ToolContainer } = tool;
        const key = activeTool
          ? `${savedSelectedElementId.current}_${id}`
          : `${liveElementId}_${id}`;

        const handleActionStart = () => {
          savedSelectedElementId.current = liveElementId;
          setActiveTool(tool);
          view.operations.setHoveredElement(null);
          operations.setActiveTool(id);
        };

        return (
          <Spring
            key={key}
            from={animationConfig.from}
            to={animationConfig.to}
            config={animationConfig.config}
          >
            {(interpolatingStyle) => (
              <ToolContainer
                appearanceStyle={{
                  opacity: interpolatingStyle.opacity,
                  transform: `scale(${interpolatingStyle.scale})`,
                }}
                elementId={elementId}
                scrollPosition={scrollPosition}
                onActionStart={handleActionStart}
                onActionEnd={handleActionEnd}
              />
            )}
          </Spring>
        );
      })}
    </div>
  );
}

export default CustomToolsContainer;
