import { i18next } from '@yola/ws-sdk';
import isSocialLinkUrlValid from './is-social-link-url-valid';

const validateUrl = (value) => {
  if (!value || !value.trim()) {
    return i18next.t('Link URL should not be empty');
  }

  if (isSocialLinkUrlValid(value)) {
    return null;
  }

  return i18next.t('Invalid URL. Please, check the URL and try again');
};

export default validateUrl;
