import React from 'react';
import { dialogs, extensions, i18next } from '@yola/ws-sdk';
import { Icon } from '@yola/ws-ui';
import Trigger from 'src/js/modules/common/components/trigger';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';

import constants from '../constants/common';

export default {
  id: constants.CONTROL_TRIGGER_ID,
  trigger: (
    <Trigger id={constants.CONTROL_TRIGGER_ID}>
      <Icon glyph="iframe" size="24" strokeWidth="1.2" />
    </Trigger>
  ),
  get tooltip() {
    return i18next.t('Iframe settings');
  },
  priority: 110,
  get title() {
    return i18next.t('Edit Iframe');
  },
  onTriggerClick(elementId) {
    dialogs.operations.show(dialogTypes.EDIT_IFRAME_DIALOG, { elementId });
  },
  matches(liveElement) {
    const settings = extensions.accessors.getExtensionSettings(constants.slug);

    return liveElement.matches(settings.querySelector);
  },
};
