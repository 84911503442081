import { ACCENT_COLOR_TYPE, LIGHT_COLOR_TYPE, DARK_COLOR_TYPE } from '../constant/color-types';

function mapColorTypes(colorType) {
  switch (colorType) {
    case 'primary':
    case 'secondary':
      return ACCENT_COLOR_TYPE;
    case 'altLight':
      return LIGHT_COLOR_TYPE;
    case 'altDark':
      return DARK_COLOR_TYPE;
    default:
      return colorType;
  }
}

export default mapColorTypes;
