import segment from 'src/js/modules/analytics/segment';

const {
  trackers: { trackEvent },
  constants: { events },
} = segment;

const trackAiTutorialClicked = ({ tutorialId, triggerId }) =>
  trackEvent(events.TUTORIAL_CLICKED, {
    tutorialId,
    triggerId,
  });

export default trackAiTutorialClicked;
