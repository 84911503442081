const prepareDialogDisplayedEventTraits = (initialValues) => {
  const {
    favicon,
    isCookieBannerEnabled,
    cookiebotId,
    preventIndexing,
    businessName,
    businessDescription,
    businessHours,
    emails,
    footerCode,
    googleAnalyticsId,
    googleWebmasterCode,
    headCode,
    headerCode,
    phones,
    addresses,
    businessCategory,
  } = initialValues;

  return {
    faviconSet: Boolean(favicon),
    nativeCookieBannerEnabled: Boolean(isCookieBannerEnabled),
    preventIndexingEnabled: Boolean(preventIndexing),
    businessNameFilled: Boolean(businessName?.length),
    businessCategoryFilled: Boolean(businessCategory?.length),
    businessDescriptionFilled: Boolean(businessDescription?.length),
    googleAnalitycsEnabled: Boolean(googleAnalyticsId),
    googleSearchConsoleEnabled: Boolean(googleWebmasterCode),
    cookieBotEnabled: Boolean(cookiebotId),
    headerCodeFilled: Boolean(headCode?.length),
    beforeBodyCodeFilled: Boolean(headerCode?.length),
    afterBodyCodeFilled: Boolean(footerCode?.length),
    businessAddressFilled: Boolean(addresses?.length),
    businessEmailFilled: Boolean(emails?.length),
    businessPhoneFilled: Boolean(phones?.length),
    workingHoursFilled: Boolean(businessHours?.length),
  };
};

export default prepareDialogDisplayedEventTraits;
