import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { dialogs, saving, site, i18next } from '@yola/ws-sdk';
import { SubmitDialog, Render } from '@yola/ws-ui';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';

const getCaptions = () => ({
  title: i18next.t('Your page has unsaved changes'),
  submit: i18next.t('Yes'),
  cancel: i18next.t('Cancel'),
  discard: i18next.t('No'),
  description: i18next.t('Would you like to save it?'),
});

function UnsavedWarningContainer({
  openSavingProgressDialog,
  closeDialog,
  activePageId,
  setPageHtml,
  htmlCheckpoint,
  savingStatus,
  callback,
  captions: captionsOverrides = {},
  onDialogMainAction,
  onDialogCancel,
}) {
  const { UNSAVED } = saving.constants.statuses;

  function onSubmit() {
    onDialogMainAction();
    openSavingProgressDialog(callback);
  }

  function onCancel() {
    onDialogCancel();
    closeDialog();
  }

  function onDiscard() {
    onDialogMainAction();
    callback();

    if (htmlCheckpoint) {
      setPageHtml(activePageId, htmlCheckpoint);
    }

    closeDialog();
  }

  const captions = {
    ...getCaptions(),
    ...captionsOverrides,
  };

  return (
    <Render if={savingStatus === UNSAVED}>
      <SubmitDialog
        captions={captions}
        onSubmit={onSubmit}
        onCancel={onCancel}
        onDiscard={onDiscard}
      />
    </Render>
  );
}

UnsavedWarningContainer.propTypes = {
  savingStatus: PropTypes.string.isRequired,
  closeDialog: PropTypes.func.isRequired,
  openSavingProgressDialog: PropTypes.func.isRequired,
  setPageHtml: PropTypes.func.isRequired,
  activePageId: PropTypes.string.isRequired,
  htmlCheckpoint: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
  captions: PropTypes.shape(),
  onDialogMainAction: PropTypes.func.isRequired,
  // eslint-disable-next-line yola/react/no-unused-prop-types
  onDialogCancel: PropTypes.func.isRequired,
};

UnsavedWarningContainer.defaultProps = {
  captions: {},
};

const mapStateToProps = (state) => {
  const activePageId = site.selectors.getActivePageId(state);
  const activePage = site.selectors.getPage(state, activePageId);
  const htmlCheckpoint = activePage && activePage.htmlCheckpoint;

  return {
    activePageId,
    htmlCheckpoint,
    savingStatus: saving.selectors.getStatus(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  openSavingProgressDialog(callback) {
    dispatch(dialogs.actions.show(dialogTypes.SAVING_PROGRESS, { callback }));
  },

  closeDialog() {
    dispatch(dialogs.actions.hide());
  },

  setPageHtml(pageId, html) {
    dispatch(site.actions.setPageHTML(pageId, html));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UnsavedWarningContainer);
