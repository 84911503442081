import React from 'react';
import PropTypes from 'prop-types';
import { Text, Button, Icon } from '@yola/ws-ui';

const RequestFeatureBanner = ({ captions, icon, onClick }) => (
  <div className="ws-request-feature-banner">
    <Icon
      className="ws-request-feature-banner__icon"
      glyph={icon}
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <Text className="ws-request-feature-banner__text">{captions.title}</Text>
    <Button
      className="ws-request-feature-banner__btn"
      type="primary"
      size="small"
      target="blank"
      onClick={onClick}
    >
      {captions.button}
    </Button>
  </div>
);

RequestFeatureBanner.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
  }).isRequired,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default RequestFeatureBanner;
