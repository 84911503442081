import accessors from './accessors';
import actions from './actions';
import actionTypes from './constants/action-types';
import reducers from './reducers';
import selectors from './selectors';

export default {
  accessors,
  actions,
  actionTypes,
  reducers,
  selectors,
};
