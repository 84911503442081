import { theme } from '@yola/ws-sdk';
import extractScriptsFromTemplate from './extract-scripts-from-template';
import compileCustomTemplate from './compile-custom-template';
import constants from '../constant';

const { SCRIPT_REGEXP } = constants;

const setCurrentFont = (global, font, customTemplate, selector) => {
  const node = global.cloneNode(true);

  const compiledCustomTemplate = compileCustomTemplate(customTemplate, font);

  const el = node.querySelector(selector);

  if (compiledCustomTemplate.match(SCRIPT_REGEXP)) {
    const { scripts, template } = extractScriptsFromTemplate(compiledCustomTemplate);

    el.innerHTML = template;

    el.append(...scripts);
  } else {
    el.innerHTML = compiledCustomTemplate;
  }

  el.setAttribute(theme.constants.FONTS_DATA_ATTRIBUTE, font.id);
  return node;
};

export default setCurrentFont;
