import constants from '../constants';
import trackEvent from './track-event';

const { events } = constants;

const trackRedoTriggerClicked = () => {
  trackEvent(events.REDO_TRIGGER_CLICKED);
};

export default trackRedoTriggerClicked;
