import { textFormatting, anodum, contentEditable } from '@yola/ws-sdk';
import verifiers from '../verifiers';

const { getElementAttributes } = contentEditable.helpers;
const { wrappersTypes } = textFormatting.constants;
const { isLinkSelected } = verifiers;

const { id, className, tagName } = wrappersTypes.link;
const FORM_LINK_CLASS_NAME = 'form__link';

const deleteLink = () => {
  if (!isLinkSelected()) return;

  const selection = textFormatting.accessors.getAdvancedSelection();
  const { anchorNode, isCollapsed } = selection;
  const startNode = anodum.isTextNode(anchorNode) ? anchorNode.parentElement : anchorNode;
  const linkElement = startNode ? startNode.closest(`${tagName}.${className}`) : null;

  if (!linkElement) return;
  if (linkElement.classList.contains(FORM_LINK_CLASS_NAME)) {
    linkElement.classList.remove(FORM_LINK_CLASS_NAME);
    // TODO: remove when service team make migration
  }
  if (isCollapsed) {
    if (anodum.isTextNode(linkElement.firstChild) && linkElement.childNodes.length === 1) {
      // in this case Rangy library will keep selection in Chrome - https://github.com/yola/production/issues/9391
      selection.selectAllChildren(linkElement.firstChild);
    } else {
      selection.selectAllChildren(linkElement);
    }
  }

  textFormatting.operations.undoWrapper(wrappersTypes.underline.id);

  const attributes = getElementAttributes(linkElement);
  textFormatting.helpers.wrapperMap.registerWrapper(id, className, tagName, attributes);
  textFormatting.operations.undoWrapper(id);
  textFormatting.helpers.wrapperMap.removeWrapper(id);
};

export default deleteLink;
