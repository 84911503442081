import { useState, useEffect, useMemo } from 'react';
import { anodum } from '@yola/ws-sdk';
import getAdvancedSelection from './get-advanced-selection';
import getElementTypeOptions from './get-element-type-options';
import getTypedElementSelector from './get-typed-element-selector';
import findTypedParentElements from './find-typed-parent-elements';

function useElementTypeSelect(elementId) {
  const selection = getAdvancedSelection();
  const elementTypeOptions = useMemo(() => getElementTypeOptions(elementId), [elementId]);
  const typedElementSelector = useMemo(
    () => getTypedElementSelector(elementTypeOptions),
    [elementTypeOptions]
  );
  const focusElements = findTypedParentElements(typedElementSelector, selection);
  const [elementType, setElementType] = useState(null);

  useEffect(() => {
    if (!focusElements.length) {
      return;
    }

    const uniqElementsTags = [
      ...new Set(focusElements.map((element) => element && element.tagName)),
    ];

    if (!uniqElementsTags.length) return;

    if (uniqElementsTags.length > 1) {
      setElementType('');
      return;
    }

    const typeOption = elementTypeOptions.find(({ value }) =>
      anodum.isTag(focusElements[0], value)
    );

    setElementType(typeOption);
  }, [focusElements, elementTypeOptions]);

  return { elementType, focusElements, selection, elementTypeOptions };
}

export default useElementTypeSelect;
