import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { designSystem } from '@yola/ws-ui';

const { FieldStatus, InputGroup, Paragraph, Box } = designSystem;

function InputGroupField(props) {
  const {
    field,
    form: { errors, touched, setFieldValue, setFieldError, setFieldTouched },
    onChange,
    onBlur,
    value,
    subtitle,
    disabled,
    ...otherProps
  } = props;

  const { name, onBlur: handleBlur, onChange: handleChange } = field;
  const touchedField = get(touched, name);
  const errorField = get(errors, name);
  const hasError = !!(errorField && touchedField);

  useEffect(() => {
    if (typeof value !== 'string') return;

    setFieldValue(name, value);
  }, [name, value, setFieldValue]);

  const onFieldChange = useCallback(
    (fieldValue) => {
      setFieldError(name, '');
      setFieldValue(name, fieldValue, false);
      handleChange(fieldValue);
      onChange(fieldValue);
    },
    [onChange, setFieldError, setFieldValue, name, handleChange]
  );

  const onFieldBlur = useCallback(
    (e) => {
      setFieldTouched(name);
      handleBlur(e);
      onBlur(e);
    },
    [name, setFieldTouched, onBlur, handleBlur]
  );

  return (
    <Box>
      <InputGroup
        {...otherProps}
        {...field}
        invalid={hasError}
        onChange={onFieldChange}
        onBlur={onFieldBlur}
        disabled={disabled}
      />

      {subtitle && !hasError && (
        <Box marginTop="spacing-3-xs">
          <Paragraph appearance="medium-emphasis" size="small">
            {subtitle}
          </Paragraph>
        </Box>
      )}
      {hasError && <FieldStatus appearance="error" text={errorField || ''} />}
    </Box>
  );
}

InputGroupField.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  subtitle: PropTypes.node,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  // Following props are set by Formik. Added only to pass snitch check.
  field: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
    setFieldError: PropTypes.func,
    setFieldTouched: PropTypes.func,
    touched: PropTypes.shape({}),
    errors: PropTypes.shape({}),
  }).isRequired,
};

InputGroupField.defaultProps = {
  disabled: false,
  subtitle: '',
  onBlur: Function.prototype,
  onChange: Function.prototype,
  value: undefined,
};

export default InputGroupField;
