import getPartnerData from './partner-data';

const getAvailableNonPaidFeatures = (rootState) => {
  const partnerData = getPartnerData(rootState);

  if (partnerData && partnerData.properties && partnerData.properties.availableNonPaidFeatures) {
    return partnerData.properties.availableNonPaidFeatures;
  }

  return [];
};

export default getAvailableNonPaidFeatures;
