import React, { useCallback } from 'react';
import { i18next } from '@yola/ws-sdk';
import { AlertDialog } from '@yola/ws-ui';
import utils from 'yola-editor/src/js/router/utils';

function RequiredCookiesErrorDialogContainer() {
  const captions = {
    title: i18next.t('Your browser has deleted required cookies'),
    description: i18next.t(
      'Refresh the page and authorise to continue using editor. It can happen because you deleted browsing data with all cookies'
    ),
    dismiss: i18next.t('Refresh'),
  };

  const hideDialog = useCallback(() => {
    utils.reload();
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  return (
    <AlertDialog captions={captions} onDismiss={hideDialog} glyph="alert-circle" height="auto" />
  );
}

export default RequiredCookiesErrorDialogContainer;
