import featureFlagsManager from '@yola/feature-flags-manager';
import auth from 'yola-editor/src/js/modules/auth';
import config from 'src/js/modules/config';
import serviceClient from '../service-client';

const clientLoader = (store) => (next) => async (action) => {
  switch (action.type) {
    case auth.actionTypes.SET_SITE_ID: {
      next(action);
      const state = store.getState();
      const apiUrl = config.selectors.getApiUrl(state);
      const siteId = auth.selectors.getSiteId(state);
      serviceClient.set(
        featureFlagsManager.initWith(apiUrl, {
          query: {
            site_id: siteId,
          },
        })
      );
      break;
    }
    default: {
      next(action);
    }
  }
};

export default clientLoader;
