import { anodum, blocks, i18next, hdrm, extensions, template } from '@yola/ws-sdk';
import getParentBlockByElement from 'src/js/modules/blocks/helpers/get-parent-block-by-element';
import isElementColumn from '../../blocks/helpers/is-element-column';
import isElementColumnInCustomBlock from '../../blocks/helpers/is-element-column-in-custom-block';
import isElementListItemInCustomBlock from '../../blocks/helpers/is-element-list-item-in-custom-block';
import isElementWidget from '../../blocks/helpers/is-element-widget';
import getOptionalChild from './get-optional-child';
import isEqualBounds from './is-equal-bounds';
import isElementButton from './is-element-button';
import getExtensionCaptions from './get-extension-captions';

const { TEXT_ATTRIBUTE } = hdrm.injections.bindings.text;
const textFallbackSelector = `[${TEXT_ATTRIBUTE}]`;

// label determination procedure
// 1. if target element is widget - return widget name
// 2. if target element has corresponding optional child with same bounds - return optional child name
// 3. if target element is button - return 'Button'
// 4. if target element has [data-ws-text] attribute - return 'Text'
// 5. if target element has corresponding extension control - return extension target name (hardcoded values)
// 6. otherwise - return empty string

const getCaptionForElement = (element) => {
  const closestBlock = getParentBlockByElement(element);
  const extensionControls = extensions.helpers.getExtensionControlsForElement(element);

  if (closestBlock) {
    const elementBounds = element.getBoundingClientRect();
    const block = blocks.accessors.getBlockByElement(closestBlock);
    const { title, availableSettings } = block;

    const isBlockWidget = isElementWidget(closestBlock);
    if (isBlockWidget) {
      if (extensionControls && extensionControls.length && !isElementButton(element)) {
        return getExtensionCaptions(extensionControls);
      }
      const widgetBounds = closestBlock.getBoundingClientRect();
      if (isEqualBounds(elementBounds, widgetBounds, true)) {
        return {
          id: title,
          label: template.accessors.getMetaTranslations(title),
        };
      }
    }

    // eslint-disable-next-line prefer-destructuring
    const optionalChildren = availableSettings?.optionalChildren;
    const child = getOptionalChild({ element, elementBounds, optionalChildren, closestBlock });

    if (child)
      return {
        id: child?.label,
        label: template.accessors.getMetaTranslations(child?.label),
      };
  }

  if (isElementButton(element)) {
    return {
      id: 'Button',
      label: i18next.t('Button'),
    };
  }

  if (element.matches(textFallbackSelector) || anodum.isTag(element, 'ws-text')) {
    return {
      id: 'Text',
      label: i18next.t('Text'),
    };
  }

  if (extensionControls && extensionControls.length) {
    return getExtensionCaptions(extensionControls);
  }

  // Added in scope of this product epic:
  // https://github.com/yola/production/issues/9349
  if (isElementColumnInCustomBlock(element)) {
    return { id: 'Column', label: i18next.t('Column') };
  }
  if (isElementListItemInCustomBlock(element)) {
    return { id: 'List item', label: i18next.t('List item') };
  }

  if (isElementColumn(element)) {
    return {
      id: 'Ws Column',
      label: '',
    };
  }

  return { id: '', label: '' };
};

export default getCaptionForElement;
