import React, { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import Gallery from 'react-photo-gallery';
import bowser from 'yola-bowser';
import { Modal, PanelGroup, Panel, LazyLoading, Spinner, designSystem } from '@yola/ws-ui';
import trackers from 'src/js/modules/analytics/segment/trackers';
import FreeStockImage from './free-stock-image';

const { trackPhotoStockSearchClicked } = trackers;
const {
  Search,
  FieldStatus,
  Box,
  DialogHeader,
  DialogHeaderTitle,
  DialogButtonGroup,
  DialogButtonGroupSeparator,
  DialogButton,
  Heading,
  Paragraph,
  Stack,
} = designSystem;

const MODAL_WIDTH = 1440;
const MODAL_HEIGHT = 800;
const IMAGES_SCROLL_THRESHOLD = 100;

const isMobile = bowser.mobile;

function FreeStockImages(props) {
  const {
    captions: {
      searchTip,
      searchPlaceholder,
      title,
      notFound,
      notFoundDescription,
      submit,
      cancel,
    },
    items,
    searchedValue,
    areLoading,
    onSearch,
    onLoading,
    onSubmit,
    onClose,
    isNotFound,
    blockId,
  } = props;
  const [containerHeight, setContainerHeight] = useState(null);
  const containerRef = useRef(null);

  const getElementHeight = (element) => {
    if (!element) return null;
    return element.clientHeight || element.offsetHeight;
  };

  const setContainerRef = useCallback((element) => {
    containerRef.current = element;
  }, []);

  useEffect(() => {
    if (bowser.mobile) {
      const currentContainerHeight = getElementHeight(containerRef.current);
      setContainerHeight(currentContainerHeight);
    }
  }, [items]);

  const onModalResize = useCallback(() => {
    const currentContainerHeight = getElementHeight(containerRef.current);
    if (containerHeight !== currentContainerHeight) {
      setContainerHeight(currentContainerHeight);
    }
  }, [containerHeight]);

  const renderImage = useCallback(
    ({ index, photo: { id, src, width, height, user, unsplash, isSelected } }) => (
      <FreeStockImage
        key={id}
        index={index}
        id={id}
        src={src}
        width={width}
        height={height}
        user={user}
        unsplash={unsplash}
        isSelected={isSelected}
      />
    ),
    []
  );

  const onFocus = useCallback(() => {
    trackPhotoStockSearchClicked({ targetElementId: blockId });
  }, [blockId]);

  return (
    <Modal
      className="ws-free-stock-images"
      width={MODAL_WIDTH}
      height={MODAL_HEIGHT}
      fullscreen={bowser.mobile}
      centered
      draggable={false}
      resizable={false}
      overlay="visible"
      onResize={onModalResize}
      handleCancel={onClose}
      handleSubmit={onSubmit}
    >
      <PanelGroup height="100%">
        <Panel className="ws-free-stock-images__header">
          <DialogHeader>
            <DialogHeaderTitle>{title}</DialogHeaderTitle>
          </DialogHeader>
        </Panel>
        <div className="ws-free-stock-images__body">
          <Box padding={isMobile ? 'spacing-xs' : 'spacing-m'} paddingBottom="spacing-4-xs">
            <Search
              defaultValue={searchedValue}
              placeholder={searchPlaceholder}
              disableClearWhileInput
              onChange={onSearch}
              onClear={onSearch}
              onFocus={onFocus}
            />
            {searchTip && <FieldStatus text={searchTip} />}
          </Box>
          <div ref={setContainerRef}>
            <LazyLoading
              onThresholdExceed={onLoading}
              threshold={IMAGES_SCROLL_THRESHOLD}
              height={containerHeight}
            >
              <Box padding={isMobile ? 'spacing-xs' : 'spacing-m'} paddingTop="spacing-xs">
                {isNotFound && (
                  <Box marginBottom="spacing-xs">
                    <Stack gap="spacing-3-xs">
                      <Heading type="heading-5">{notFound}</Heading>
                      <Paragraph>{notFoundDescription}</Paragraph>
                    </Stack>
                  </Box>
                )}
                <Gallery photos={items} renderImage={renderImage} />
                {areLoading && <Spinner containerPosition="bottom" spinnerPosition="center" />}
              </Box>
            </LazyLoading>
          </div>
        </div>

        <Panel className="ws-free-stock-images__footer">
          <DialogButtonGroup>
            <DialogButton onClick={onSubmit}>{submit}</DialogButton>
            <DialogButtonGroupSeparator />
            <DialogButton onClick={onClose}>{cancel}</DialogButton>
          </DialogButtonGroup>
        </Panel>
      </PanelGroup>
    </Modal>
  );
}

FreeStockImages.propTypes = {
  captions: PropTypes.shape({
    searchTip: PropTypes.string,
    searchPlaceholder: PropTypes.string,
    title: PropTypes.string,
    notFound: PropTypes.string,
    notFoundDescription: PropTypes.string,
    submit: PropTypes.string,
    cancel: PropTypes.string,
  }).isRequired,
  items: PropTypes.array.isRequired,
  areLoading: PropTypes.bool.isRequired,
  onSearch: PropTypes.func.isRequired,
  onLoading: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isNotFound: PropTypes.bool.isRequired,
  blockId: PropTypes.string.isRequired,
  searchedValue: PropTypes.string,
};

FreeStockImages.defaultProps = {
  searchedValue: '',
};

export default FreeStockImages;
