import get from 'lodash.get';
import { constants } from '@yola/subscription-manager-js';

const {
  hostingPackageFeatureNames: { FORM_ATTACHMENTS },
} = constants;

const formAttachmentsLimit = ({ targetPackage }) => ({
  available: get(targetPackage, ['features', FORM_ATTACHMENTS], false),
});

export default formAttachmentsLimit;
