import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function AccordionHeaderTitle({ title, isOpened }) {
  return (
    <div
      className={classNames('ws-accordion-header__title', {
        'ws-accordion-header__title--opened': isOpened,
      })}
    >
      {title}
    </div>
  );
}

AccordionHeaderTitle.propTypes = {
  title: PropTypes.string.isRequired,
  isOpened: PropTypes.bool.isRequired,
};

export default AccordionHeaderTitle;
