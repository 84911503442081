import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import serviceClient from 'yola-editor/src/js/modules/feature-flags/service-client';

const FeatureFlagsContext = createContext();
const { Provider, Consumer } = FeatureFlagsContext;

function FeatureFlagsProvider({ children }) {
  const featureFlagsManager = serviceClient.get();
  const [featureFlags, setFeatureFlags] = useState(featureFlagsManager.state);

  const requestFeatureFlags = async (flags) => {
    const resolvedFlags = await featureFlagsManager.request(flags);
    setFeatureFlags(featureFlagsManager.state);
    return resolvedFlags;
  };

  return <Provider value={{ featureFlags, requestFeatureFlags }}>{children}</Provider>;
}

FeatureFlagsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { FeatureFlagsProvider, Consumer as FeatureFlagsConsumer };
export default FeatureFlagsContext;
