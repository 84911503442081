export default {
  APP_OPENED_EVENT: 'APP_OPENED',
  PAGE_CREATED_EVENT: 'PAGE_CREATED',
  PAGE_MANAGER_SEARCH_CLICKED: 'PAGE_MANAGER_SEARCH_CLICKED',
  PAGE_MANAGER_SEARCH_PERFORMED: 'PAGE_MANAGER_SEARCH_PERFORMED',
  BLOCK_ADDED: 'BLOCK_ADDED',
  BLOCK_SHIFTED: 'BLOCK_SHIFTED',
  BLOCK_DUPLICATED: 'BLOCK_DUPLICATED',
  BLOCK_DELETED: 'BLOCK_DELETED',
  BLOCK_COPIED: 'BLOCK_COPIED',
  BLOCK_CUTOUT: 'BLOCK_CUTOUT',
  BLOCK_PASTED: 'BLOCK_PASTED',
  UPGRADE_INTERRUPTION_DISPLAYED: 'UPGRADE_INTERRUPTION_DISPLAYED',
  UPGRADE_INTERRUPTION_CANCELLED: 'UPGRADE_INTERRUPTION_CANCELLED',
  UPGRADE_INTERRUPTION_SUBMITTED: 'UPGRADE_INTERRUPTION_SUBMITTED',
  DIALOG_DISPLAYED: 'DIALOG_DISPLAYED',
  DIALOG_ACTIVE_TAB_CHANGED: 'DIALOG_ACTIVE_TAB_CHANGED',
  DIALOG_SUBMITTED: 'DIALOG_SUBMITTED',
  DIALOG_CANCELLED: 'DIALOG_CANCELLED',
  WELCOME_TOUR_SKIPPED: 'WELCOME_TOUR_SKIPPED',
  WELCOME_TOUR_COMPLETED: 'WELCOME_TOUR_COMPLETED',
  MULTILINGUAL_TOUR_SKIPPED: 'MULTILINGUAL_TOUR_SKIPPED',
  MULTILINGUAL_TOUR_COMPLETED: 'MULTILINGUAL_TOUR_COMPLETED',
  CONTENT_COPIED: 'CONTENT_COPIED',
  CONTENT_CUTOUT: 'CONTENT_CUTOUT',
  CONTENT_PASTED: 'CONTENT_PASTED',
  TEXT_ELEMENT_TYPE_SELECTED: 'TEXT_ELEMENT_TYPE_SELECTED',
  CONTROL_PANE_TRIGGER_CLICKED: 'CONTROL_PANE_TRIGGER_CLICKED',
  CONTEXT_MENU_DISPLAYED: 'CONTEXT_MENU_DISPLAYED',
  UPGRADE_TRIGGER_CLICKED: 'UPGRADE_TRIGGER_CLICKED',
  WEBSITE_LANGUAGE_ADDED: 'WEBSITE_LANGUAGE_ADDED',
  WEBSITE_LANGUAGE_DELETED: 'WEBSITE_LANGUAGE_DELETED',
  DEFAULT_WEBSITE_LANGUAGE_CHANGED: 'DEFAULT_WEBSITE_LANGUAGE_CHANGED',
  WEBSITE_LANGUAGE_SWITCHER_CLICKED: 'WEBSITE_LANGUAGE_SWITCHER_CLICKED',
  WEBSITE_LANGUAGE_SWITCHED: 'WEBSITE_LANGUAGE_SWITCHED',
  SITE_ASSISTANT_DISPLAYED: 'SITE_ASSISTANT_DISPLAYED',
  SITE_ASSISTANT_CLOSED: 'SITE_ASSISTANT_CLOSED',
  SITE_PROGRESS_CHECKLIST_ITEM_CHECKED: 'SITE_PROGRESS_CHECKLIST_ITEM_CHECKED',
  SITE_PROGRESS_CHECKLIST_ITEM_UNCHECKED: 'SITE_PROGRESS_CHECKLIST_ITEM_UNCHECKED',
  SITE_PROGRESS_CHECKLIST_TUTORIAL_OPENED: 'SITE_PROGRESS_CHECKLIST_TUTORIAL_OPENED',
  UPVOTE_INTERRUPTION_DISPLAYED: 'UPVOTE_INTERRUPTION_DISPLAYED',
  UPVOTE_INTERRUPTION_VOTE_BUTTON_CLICKED: 'UPVOTE_INTERRUPTION_VOTE_BUTTON_CLICKED',
  SITE_SAVE_INITIATED: 'SITE_SAVE_INITIATED',
  SITE_SAVE_COMPLETED: 'SITE_SAVE_COMPLETED',
  SITE_LOADED: 'SITE_LOADED',
  APP_MAIN_MENU_DISPLAYED: 'APP_MAIN_MENU_DISPLAYED',
  APP_HELP_MENU_DISPLAYED: 'APP_HELP_MENU_DISPLAYED',
  APP_HELP_MENU_ITEM_CLICKED: 'APP_HELP_MENU_ITEM_CLICKED',
  BLOCK_LIBRARY_SEARCH_PERFORMED: 'BLOCK_LIBRARY_SEARCH_PERFORMED',
  BLOCK_LIBRARY_SEARCH_CLICKED: 'BLOCK_LIBRARY_SEARCH_CLICKED',
  BLOCK_LIBRARY_CATEGORY_SELECTED: 'BLOCK_LIBRARY_CATEGORY_SELECTED',
  BLOCK_LIBRARY_ONLINE_STORE_SEEN: 'BLOCK_LIBRARY_ONLINE_STORE_SEEN',
  BLOCK_LIBRARY_CUSTOM_BLOCK_SEEN: 'BLOCK_LIBRARY_CUSTOM_BLOCK_SEEN',
  BLOCK_LIBRARY_BLOCKS_STYLE_CHANGED: 'BLOCK_LIBRARY_BLOCKS_STYLE_CHANGED',
  BLOCK_LIBRARY_FILTER_SELECTED: 'BLOCK_LIBRARY_FILTER_SELECTED',
  APP_MAIN_MENU_ITEM_CLICKED: 'APP_MAIN_MENU_ITEM_CLICKED',
  WEBSITE_DESIGN_TRIGGER_CLICKED: 'WEBSITE_DESIGN_TRIGGER_CLICKED',
  CUSTOMIZE_WEBSITE_PALETTES_TRIGGER_CLICKED: 'CUSTOMIZE_WEBSITE_PALETTES_TRIGGER_CLICKED',
  LIST_SETTINGS_DIALOG_SUBMITTED: 'LIST_SETTINGS_DIALOG_SUBMITTED',
  LIST_SETTINGS_DIALOG_CANCELED: 'LIST_SETTINGS_DIALOG_CANCELED',
  SESSION_RENEWAL_INITIATED: 'SESSION_RENEWAL_INITIATED',
  SESSION_RENEWAL_COMPLETED: 'SESSION_RENEWAL_COMPLETED',
  LIST_SETTINGS_DIALOG_DISPLAYED: 'LIST_SETTINGS_DIALOG_DISPLAYED',
  LIST_SETTINGS_DIALOG_TRIGGER_CLICKED: 'LIST_SETTINGS_DIALOG_TRIGGER_CLICKED',
  LIST_SETTINGS_DIALOG_ITEMS_ORDER_ADJUSTED: 'LIST_SETTINGS_DIALOG_ITEMS_ORDER_ADJUSTED',
  CONTEXT_MENU_ITEM_CLICKED: 'CONTEXT_MENU_ITEM_CLICKED',
  CONTEXT_MENU_EMPTY_PAGE_CLICKED: 'CONTEXT_MENU_EMPTY_PAGE_CLICKED',
  CONTEXT_MENU_EMPTY_PAGE_DISPLAYED: 'CONTEXT_MENU_EMPTY_PAGE_DISPLAYED',
  BLOCK_SETTINGS_DIALOG_DISPLAYED: 'BLOCK_SETTINGS_DIALOG_DISPLAYED',
  BLOCK_SETTINGS_DIALOG_SUBMITTED: 'BLOCK_SETTINGS_DIALOG_SUBMITTED',
  BLOCK_SETTINGS_DIALOG_CANCELED: 'BLOCK_SETTINGS_DIALOG_CANCELED',
  BLOCK_SETTINGS_OPTION_CLICKED: 'BLOCK_SETTINGS_OPTION_CLICKED',
  BLOCK_LAYOUT_DIALOG_DISPLAYED: 'BLOCK_LAYOUT_DIALOG_DISPLAYED',
  BLOCK_LAYOUT_DIALOG_SUBMITTED: 'BLOCK_LAYOUT_DIALOG_SUBMITTED',
  BLOCK_LAYOUT_DIALOG_CANCELED: 'BLOCK_LAYOUT_DIALOG_CANCELED',
  BLOCK_LAYOUT_OPTION_CLICKED: 'BLOCK_LAYOUT_OPTION_CLICKED',
  BLOCK_LAYOUT_SWITCH_TEMPLATE_BUTTON_CLICKED: 'BLOCK_LAYOUT_SWITCH_TEMPLATE_BUTTON_CLICKED',
  BLOCK_LAYOUT_SWITCHED: 'BLOCK_LAYOUT_SWITCHED',
  SOCIAL_LINKS_SETTINGS_DIALOG_DISPLAYED: 'SOCIAL_LINKS_SETTINGS_DIALOG_DISPLAYED',
  SOCIAL_LINKS_SETTINGS_DIALOG_SUBMITTED: 'SOCIAL_LINKS_SETTINGS_DIALOG_SUBMITTED',
  SOCIAL_LINKS_SETTINGS_DIALOG_CANCELED: 'SOCIAL_LINKS_SETTINGS_DIALOG_CANCELED',
  SOCIAL_LINKS_ADD_LINK_BUTTON_CLICKED: 'SOCIAL_LINKS_ADD_LINK_BUTTON_CLICKED',
  SOCIAL_LINKS_ADD_LINK_DIALOG_DISPLAYED: 'SOCIAL_LINKS_ADD_LINK_DIALOG_DISPLAYED',
  SOCIAL_LINKS_ADD_LINK_DIALOG_SUBMITTED: 'SOCIAL_LINKS_ADD_LINK_DIALOG_SUBMITTED',
  SOCIAL_LINKS_ADD_LINK_DIALOG_CANCELED: 'SOCIAL_LINKS_ADD_LINK_DIALOG_CANCELED',
  SOCIAL_LINKS_DELETE_LINK_BUTTON_CLICKED: 'SOCIAL_LINKS_DELETE_LINK_BUTTON_CLICKED',
  SOCIAL_LINKS_EDIT_LINK_BUTTON_CLICKED: 'SOCIAL_LINKS_EDIT_LINK_BUTTON_CLICKED',
  SOCIAL_LINKS_EDIT_LINK_DIALOG_DISPLAYED: 'SOCIAL_LINKS_EDIT_LINK_DIALOG_DISPLAYED',
  SOCIAL_LINKS_EDIT_LINK_DIALOG_SUBMITTED: 'SOCIAL_LINKS_EDIT_LINK_DIALOG_SUBMITTED',
  SOCIAL_LINKS_EDIT_LINK_DIALOG_CANCELED: 'SOCIAL_LINKS_EDIT_LINK_DIALOG_CANCELED',
  SOCIAL_LINKS_ITEMS_ORDER_CHANGED: 'SOCIAL_LINKS_ITEMS_ORDER_CHANGED',
  SELECT_ICON_DIALOG_DISPLAYED: 'SELECT_ICON_DIALOG_DISPLAYED',
  SELECT_ICON_DIALOG_SUBMITTED: 'SELECT_ICON_DIALOG_SUBMITTED',
  SELECT_ICON_DIALOG_CANCELED: 'SELECT_ICON_DIALOG_CANCELED',
  SELECT_ICON_ICON_ERROR: 'SELECT_ICON_ICON_ERROR',
  SELECT_ICON_STYLE_FILTER_SELECTED: 'SELECT_ICON_STYLE_FILTER_SELECTED',
  SELECT_ICON_TYPE_FILTER_SELECTED: 'SELECT_ICON_TYPE_FILTER_SELECTED',
  SELECT_ICON_ERROR_LOADING_ICONS: 'SELECT_ICON_ERROR_LOADING_ICONS',
  SELECT_ICON_ERROR_RELOAD_CLICKED: 'SELECT_ICON_ERROR_RELOAD_CLICKED',
  WIDGET_SELECTOR_SEARCH_CLICKED: 'WIDGET_SELECTOR_SEARCH_CLICKED',
  WIDGET_SELECTOR_SEARCH_PERFORMED: 'WIDGET_SELECTOR_SEARCH_PERFORMED',
  WIDGET_DELETED: 'WIDGET_DELETED',
  WIDGET_ADDED: 'WIDGET_ADDED',
  WIDGET_SHIFTED: 'WIDGET_SHIFTED',
  WIDGET_DUPLICATED: 'WIDGET_DUPLICATED',
  WIDGET_SELECTOR_DISPLAYED: 'WIDGET_SELECTOR_DISPLAYED',
  WIDGET_SELECTOR_CLOSED: 'WIDGET_SELECTOR_CLOSED',
  WIDGET_SELECTOR_REQUEST_WIDGET_BUTTON_CLICKED: 'WIDGET_SELECTOR_REQUEST_WIDGET_BUTTON_CLICKED',
  ONLINE_STORE_TRIGGER_CLICKED: 'ONLINE_STORE_TRIGGER_CLICKED',
  SITE_ELEMENT_CLICKED: 'SITE_ELEMENT_CLICKED',
  IMAGE_RESIZING_INITIATED: 'IMAGE_RESIZING_INITIATED',
  IMAGE_RESIZING_COMPLETED: 'IMAGE_RESIZING_COMPLETED',
  ICON_SIZE_RESIZED: 'ICON_SIZE_RESIZED',
  FOCAL_POINT_SETTINGS_DISPLAYED: 'FOCAL_POINT_SETTINGS_DISPLAYED',
  FOCAL_POINT_ZOOM_IN_BUTTON_CLICKED: 'FOCAL_POINT_ZOOM_IN_BUTTON_CLICKED',
  FOCAL_POINT_ZOOM_OUT_BUTTON_CLICKED: 'FOCAL_POINT_ZOOM_OUT_BUTTON_CLICKED',
  FOCAL_POINT_ZOOM_SLIDER_ADJUSTED: 'FOCAL_POINT_ZOOM_SLIDER_ADJUSTED',
  FOCAL_POINT_IMAGE_POSITION_ADJUSTED: 'FOCAL_POINT_IMAGE_POSITION_ADJUSTED',
  FOCAL_POINT_REPLACE_MEDIA_BUTTON_CLICKED: 'FOCAL_POINT_REPLACE_MEDIA_BUTTON_CLICKED',
  FOCAL_POINT_UPLOAD_FILE_BUTTON_CLICKED: 'FOCAL_POINT_UPLOAD_FILE_BUTTON_CLICKED',
  FOCAL_POINT_BROWSE_STOCK_PHOTOS_BUTTON_CLICKED: 'FOCAL_POINT_BROWSE_STOCK_PHOTOS_BUTTON_CLICKED',
  FOCAL_POINT_SETTINGS_SUBMITTED: 'FOCAL_POINT_SETTINGS_SUBMITTED',
  FOCAL_POINT_SETTINGS_CANCELLED: 'FOCAL_POINT_SETTINGS_CANCELLED',
  FOCAL_POINT_NEW_IMAGE_SELECTED: 'FOCAL_POINT_NEW_IMAGE_SELECTED',
  FOCAL_POINT_FIT_IN_BUTTON_CLICKED: 'FOCAL_POINT_FIT_IN_BUTTON_CLICKED',
  FOCAL_POINT_IMAGE_CONTAINER_RESIZED: 'FOCAL_POINT_IMAGE_CONTAINER_RESIZED',
  BLOCK_TITLE_WIDTH_RESIZED: 'BLOCK_TITLE_WIDTH_RESIZED',
  DESIGN_SETTINGS_DIALOG_DISPLAYED: 'DESIGN_SETTINGS_DIALOG_DISPLAYED',
  DESIGN_SETTINGS_DIALOG_SUBMITTED: 'DESIGN_SETTINGS_DIALOG_SUBMITTED',
  DESIGN_SETTINGS_DIALOG_CANCELLED: 'DESIGN_SETTINGS_DIALOG_CANCELLED',
  DESIGN_SETTINGS_COLOR_PRESET_CHANGED: 'DESIGN_SETTINGS_COLOR_PRESET_CHANGED',
  DESIGN_SETTINGS_FONT_PRESET_CLICKED: 'DESIGN_SETTINGS_FONT_PRESET_CLICKED',
  DESIGN_SETTINGS_BUTTON_PRESET_CLICKED: 'DESIGN_SETTINGS_BUTTON_PRESET_CLICKED',
  DESIGN_SETTINGS_EFFECTS_SWITCHER_CLICKED: 'DESIGN_SETTINGS_EFFECTS_SWITCHER_CLICKED',
  DESIGN_SETTINGS_SWITCH_TEMPLATE_BUTTON_CLICKED: 'DESIGN_SETTINGS_SWITCH_TEMPLATE_BUTTON_CLICKED',
  DESIGN_SETTINGS_COLOR_PRESET_SETTINGS_DISPLAYED:
    'DESIGN_SETTINGS_COLOR_PRESET_SETTINGS_DISPLAYED',
  DESIGN_SETTINGS_COLOR_PRESET_SETTINGS_SUBMITTED:
    'DESIGN_SETTINGS_COLOR_PRESET_SETTINGS_SUBMITTED',
  DESIGN_SETTINGS_COLOR_PRESET_SETTINGS_CANCELED: 'DESIGN_SETTINGS_COLOR_PRESET_SETTINGS_CANCELED',
  DESIGN_SETTINGS_RESET_TRIGGER_CLICKED: 'DESIGN_SETTINGS_RESET_TRIGGER_CLICKED',
  DESIGN_SETTINGS_COLOR_SCHEME_SETTINGS_DISPLAYED:
    'DESIGN_SETTINGS_COLOR_SCHEME_SETTINGS_DISPLAYED',
  DESIGN_SETTINGS_COLOR_SCHEME_SETTINGS_SUBMITTED:
    'DESIGN_SETTINGS_COLOR_SCHEME_SETTINGS_SUBMITTED',
  DESIGN_SETTINGS_COLOR_SCHEME_SETTINGS_CANCELED: 'DESIGN_SETTINGS_COLOR_SCHEME_SETTINGS_CANCELED',
  DESIGN_SETTINGS_COLOR_SETTINGS_DISPLAYED: 'DESIGN_SETTINGS_COLOR_SETTINGS_DISPLAYED',
  DESIGN_SETTINGS_COLOR_SETTINGS_SUBMITTED: 'DESIGN_SETTINGS_COLOR_SETTINGS_SUBMITTED',
  DESIGN_SETTINGS_COLOR_SETTINGS_CANCELLED: 'DESIGN_SETTINGS_COLOR_SETTINGS_CANCELLED',
  DESIGN_SETTINGS_COLOR_PRESET_DELETED: 'DESIGN_SETTINGS_COLOR_PRESET_DELETED',
  SWITCH_BLOCK_LAYOUT_BUTTON_CLICKED: 'SWITCH_BLOCK_LAYOUT_BUTTON_CLICKED',
  MEDIA_SETTINGS_DIALOG_DISPLAYED: 'MEDIA_SETTINGS_DIALOG_DISPLAYED',
  MEDIA_SETTINGS_DIALOG_SUBMITTED: 'MEDIA_SETTINGS_DIALOG_SUBMITTED',
  MEDIA_SETTINGS_DIALOG_CANCELED: 'MEDIA_SETTINGS_DIALOG_CANCELED',
  MEDIA_SETTINGS_CROP_BUTTON_CLICKED: 'MEDIA_SETTINGS_CROP_BUTTON_CLICKED',
  MEDIA_SETTINGS_REPLACE_MEDIA_BUTTON_CLICKED: 'MEDIA_SETTINGS_REPLACE_MEDIA_BUTTON_CLICKED',
  MEDIA_SETTINGS_UPLOAD_FILE_BUTTON_CLICKED: 'MEDIA_SETTINGS_UPLOAD_FILE_BUTTON_CLICKED',
  MEDIA_SETTINGS_BROWSE_STOCK_PHOTOS_BUTTON_CLICKED:
    'MEDIA_SETTINGS_BROWSE_STOCK_PHOTOS_BUTTON_CLICKED',
  MEDIA_SETTINGS_NEW_IMAGE_SELECTED: 'MEDIA_SETTINGS_NEW_IMAGE_SELECTED',
  MEDIA_SETTINGS_OPTION_CLICKED: 'MEDIA_SETTINGS_OPTION_CLICKED',
  MEDIA_SETTINGS_INPUT_CLICKED: 'MEDIA_SETTINGS_INPUT_CLICKED',
  MEDIA_SETTINGS_DELETE_IMAGE_CLICKED: 'MEDIA_SETTINGS_DELETE_IMAGE_CLICKED',
  CONTROL_PANE_REPLACE_MEDIA_BUTTON_CLICKED: 'CONTROL_PANE_REPLACE_MEDIA_BUTTON_CLICKED',
  CONTROL_PANE_UPLOAD_FILE_BUTTON_CLICKED: 'CONTROL_PANE_UPLOAD_FILE_BUTTON_CLICKED',
  CONTROL_PANE_BROWSE_STOCK_PHOTOS_BUTTON_CLICKED:
    'CONTROL_PANE_BROWSE_STOCK_PHOTOS_BUTTON_CLICKED',
  CONTROL_PANE_NEW_IMAGE_SELECTED: 'CONTROL_PANE_NEW_IMAGE_SELECTED',
  SELECT_ICON_SEARCH_CLICKED: 'SELECT_ICON_SEARCH_CLICKED',
  SELECT_ICON_SEARCH_PERFORMED: 'SELECT_ICON_SEARCH_PERFORMED',
  SELECT_ICON_ICON_CLICKED: 'SELECT_ICON_ICON_CLICKED',
  GOOGLE_MAP_EMBED_SEARCH_CLICKED: 'GOOGLE_MAP_EMBED_SEARCH_CLICKED',
  GOOGLE_MAP_EMBED_SEARCH_PERFORMED: 'GOOGLE_MAP_EMBED_SEARCH_PERFORMED',
  PHOTO_STOCK_SEARCH_CLICKED: 'PHOTO_STOCK_SEARCH_CLICKED',
  PHOTO_STOCK_SEARCH_PERFORMED: 'PHOTO_STOCK_SEARCH_PERFORMED',
  BLOCK_SETTINGS_REPLACE_MEDIA_BUTTON_CLICKED: 'BLOCK_SETTINGS_REPLACE_MEDIA_BUTTON_CLICKED',
  BLOCK_SETTINGS_UPLOAD_FILE_BUTTON_CLICKED: 'BLOCK_SETTINGS_UPLOAD_FILE_BUTTON_CLICKED',
  BLOCK_SETTINGS_BROWSE_STOCK_PHOTOS_BUTTON_CLICKED:
    'BLOCK_SETTINGS_BROWSE_STOCK_PHOTOS_BUTTON_CLICKED',
  BLOCK_SETTINGS_NEW_BACKGROUND_IMAGE_SELECTED: 'BLOCK_SETTINGS_NEW_BACKGROUND_IMAGE_SELECTED',
  BLOCK_SETTINGS_CROP_BUTTON_CLICKED: 'BLOCK_SETTINGS_CROP_BUTTON_CLICKED',
  ONBOARDING_PLAN_SELECTOR_CONTINUE_WITH_FREE_VERSION_BUTTON_CLICKED:
    'ONBOARDING_PLAN_SELECTOR_CONTINUE_WITH_FREE_VERSION_BUTTON_CLICKED',
  ADD_REDIRECT_BUTTON_CLICKED: 'ADD_REDIRECT_BUTTON_CLICKED',
  DELETE_REDIRECT_BUTTON_CLICKED: 'DELETE_REDIRECT_BUTTON_CLICKED',
  EDIT_REDIRECT_BUTTON_CLICKED: 'EDIT_REDIRECT_BUTTON_CLICKED',
  ADD_REDIRECT_DIALOG_DISPLAYED: 'ADD_REDIRECT_DIALOG_DISPLAYED',
  ADD_REDIRECT_DIALOG_CANCELED: 'ADD_REDIRECT_DIALOG_CANCELED',
  ADD_REDIRECT_DIALOG_SUBMITTED: 'ADD_REDIRECT_DIALOG_SUBMITTED',
  DELETE_REDIRECT_DIALOG_DISPLAYED: 'DELETE_REDIRECT_DIALOG_DISPLAYED',
  DELETE_REDIRECT_DIALOG_CANCELED: 'DELETE_REDIRECT_DIALOG_CANCELED',
  DELETE_REDIRECT_DIALOG_SUBMITTED: 'DELETE_REDIRECT_DIALOG_SUBMITTED',
  EDIT_REDIRECT_DIALOG_DISPLAYED: 'EDIT_REDIRECT_DIALOG_DISPLAYED',
  EDIT_REDIRECT_DIALOG_CANCELED: 'EDIT_REDIRECT_DIALOG_CANCELED',
  EDIT_REDIRECT_DIALOG_SUBMITTED: 'EDIT_REDIRECT_DIALOG_SUBMITTED',
  ADD_REDIRECT_VALIDATION_ERROR: 'ADD_REDIRECT_VALIDATION_ERROR',
  EDIT_REDIRECT_VALIDATION_ERROR: 'EDIT_REDIRECT_VALIDATION_ERROR',
  CONNECT_DOMAIN_TUTORIAL_LINK_CLICKED: 'CONNECT_DOMAIN_TUTORIAL_LINK_CLICKED',
  TRANSFER_DOMAIN_TUTORIAL_LINK_CLICKED: 'TRANSFER_DOMAIN_TUTORIAL_LINK_CLICKED',
  POINT_DOMAIN_TUTORIAL_LINK_CLICKED: 'POINT_DOMAIN_TUTORIAL_LINK_CLICKED',
  MANAGE_DOMAINS_BUTTON_CLICKED: 'MANAGE_DOMAINS_BUTTON_CLICKED',
  UPGRADE_INITIATED: 'UPGRADE_INITIATED',
  ERROR_CAUGHT: 'ERROR_CAUGHT',
  PLAN_SELECTOR_DISPLAYED: 'PLAN_SELECTOR_DISPLAYED',
  ONBOARDING_PLAN_SELECTOR_DISPLAYED: 'ONBOARDING_PLAN_SELECTOR_DISPLAYED',
  DOMAIN_SEARCH_FAILED: 'DOMAIN_SEARCH_FAILED',
  AI_FEEDBACK_DISPLAYED: 'AI_FEEDBACK_DISPLAYED',
  AI_FEEDBACK_POSTPONED: 'AI_FEEDBACK_POSTPONED',
  AI_FEEDBACK_COMPLETED: 'AI_FEEDBACK_COMPLETED',
  PMF_SURVEY_DISPLAYED: 'PMF_SURVEY_DISPLAYED',
  PMF_SURVEY_POSTPONED: 'PMF_SURVEY_POSTPONED',
  NPS_SURVEY_DISPLAYED: 'NPS_SURVEY_DISPLAYED',
  NPS_SURVEY_COMPLETED: 'NPS_SURVEY_COMPLETED',
  NPS_SURVEY_POSTPONED: 'NPS_SURVEY_POSTPONED',
  NETWORK_CHANGED: 'NETWORK_CHANGED',
  FRIEND_REFERRAL_INITIATED: 'FRIEND_REFERRAL_INITIATED',
  ONBOARDING_PLAN_SELECTOR_PLAN_SELECT_BUTTON_CLICKED:
    'ONBOARDING_PLAN_SELECTOR_PLAN_SELECT_BUTTON_CLICKED',
  ONBOARDING_PLAN_SELECTOR_TERM_TOGGLE_CLICKED: 'ONBOARDING_PLAN_SELECTOR_TERM_TOGGLE_CLICKED',
  ONBOARDING_PLAN_SELECTOR_SCROLLED_TO_BOTTOM: 'ONBOARDING_PLAN_SELECTOR_SCROLLED_TO_BOTTOM',
  ONBOARDING_PLAN_SELECTOR_TOOLTIP_DISPLAYED: 'ONBOARDING_PLAN_SELECTOR_TOOLTIP_DISPLAYED',
  PLAN_SELECTOR_PLAN_SELECT_BUTTON_CLICKED: 'PLAN_SELECTOR_PLAN_SELECT_BUTTON_CLICKED',
  PLAN_SELECTOR_TERM_TOGGLE_CLICKED: 'PLAN_SELECTOR_TERM_TOGGLE_CLICKED',
  PLAN_SELECTOR_SCROLLED_TO_BOTTOM: 'PLAN_SELECTOR_SCROLLED_TO_BOTTOM',
  PLAN_SELECTOR_TOOLTIP_DISPLAYED: 'PLAN_SELECTOR_TOOLTIP_DISPLAYED',
  PLAN_SELECTOR_BACK_TO_EDITOR_BUTTON_CLICKED: 'PLAN_SELECTOR_BACK_TO_EDITOR_BUTTON_CLICKED',
  BUY_A_DOMAIN_TRIGGER_CLICKED: 'BUY_A_DOMAIN_TRIGGER_CLICKED',
  DOMAIN_PURCHASE_DIALOG_DISPLAYED: 'DOMAIN_PURCHASE_DIALOG_DISPLAYED',
  DOMAIN_PURCHASE_DIALOG_CANCELED: 'DOMAIN_PURCHASE_DIALOG_CANCELED',
  DOMAIN_PURCHASE_SEARCH_CLICKED: 'DOMAIN_PURCHASE_SEARCH_CLICKED',
  DOMAIN_PURCHASE_SEARCH_PERFORMED: 'DOMAIN_PURCHASE_SEARCH_PERFORMED',
  DOMAIN_PURCHASE_CLICKED: 'DOMAIN_PURCHASE_CLICKED',
  DOMAIN_PURCHASE_AUTOCOMPLETE_DOMAIN_SELECTED: 'DOMAIN_PURCHASE_AUTOCOMPLETE_DOMAIN_SELECTED',
  DOMAIN_PURCHASE_NOT_NOW_CLICKED: 'DOMAIN_PURCHASE_NOT_NOW_CLICKED',
  HELP_DISPLAYED: 'HELP_DISPLAYED',
  TEXT_ELEMENT_SIZE_ADJUSTED: 'TEXT_ELEMENT_SIZE_ADJUSTED',
  LINK_SETTINGS_DIALOG_DISPLAYED: 'LINK_SETTINGS_DIALOG_DISPLAYED',
  LINK_SETTINGS_DIALOG_CANCELLED: 'LINK_SETTINGS_DIALOG_CANCELLED',
  LINK_SETTINGS_DIALOG_SUBMITTED: 'LINK_SETTINGS_DIALOG_SUBMITTED',
  LINK_SETTINGS_UPGRADE_TRIGGER_CLICKED: 'LINK_SETTINGS_UPGRADE_TRIGGER_CLICKED',
  LINK_SETTINGS_INPUT_CLICKED: 'LINK_SETTINGS_INPUT_CLICKED',
  LINK_SETTINGS_OPTION_CLICKED: 'LINK_SETTINGS_OPTION_CLICKED',
  LINK_SETTINGS_CHOOSE_FILE_CLICKED: 'LINK_SETTINGS_CHOOSE_FILE_CLICKED',
  PREVIEW_TRIGGER_CLICKED: 'PREVIEW_TRIGGER_CLICKED',
  PREVIEW_DEVICE_CLICKED: 'PREVIEW_DEVICE_CLICKED',
  PREVIEW_BACK_CLICKED: 'PREVIEW_BACK_CLICKED',
  PUBLISH_TRIGGER_CLICKED: 'PUBLISH_TRIGGER_CLICKED',
  BLOCK_LIBRARY_TUTORIAL_CLICKED: 'BLOCK_LIBRARY_TUTORIAL_CLICKED',
  CONTACT_FORM_SETTINGS_DIALOG_DISPLAYED: 'CONTACT_FORM_SETTINGS_DIALOG_DISPLAYED',
  CONTACT_FORM_DELETE_ITEM_BUTTON_CLICKED: 'CONTACT_FORM_DELETE_ITEM_BUTTON_CLICKED',
  CONTACT_FORM_ITEMS_ORDER_CHANGED: 'CONTACT_FORM_ITEMS_ORDER_CHANGED',
  CONTACT_FORM_EDIT_ITEM_DIALOG_DISPLAYED: 'CONTACT_FORM_EDIT_ITEM_DIALOG_DISPLAYED',
  CONTACT_FORM_EDIT_ITEM_MAKE_REQUIRED_AREA_CLICKED:
    'CONTACT_FORM_EDIT_ITEM_MAKE_REQUIRED_AREA_CLICKED',
  CONTACT_FORM_EDIT_ITEM_DIALOG_SUBMITTED: 'CONTACT_FORM_EDIT_ITEM_DIALOG_SUBMITTED',
  CONTACT_FORM_EDIT_ITEM_DIALOG_CANCELED: 'CONTACT_FORM_EDIT_ITEM_DIALOG_CANCELED',
  CONTACT_FORM_ADD_ITEM_DIALOG_DISPLAYED: 'CONTACT_FORM_ADD_ITEM_DIALOG_DISPLAYED',
  CONTACT_FORM_ADD_ITEM_DIALOG_SUBMITTED: 'CONTACT_FORM_ADD_ITEM_DIALOG_SUBMITTED',
  CONTACT_FORM_ADD_ITEM_DIALOG_CANCELED: 'CONTACT_FORM_ADD_ITEM_DIALOG_CANCELED',
  CONTACT_FORM_DISPLAY_GDPR_CHECKBOX_CLICKED: 'CONTACT_FORM_DISPLAY_GDPR_CHECKBOX_CLICKED',
  CONTACT_FORM_SETTINGS_DIALOG_SUBMITTED: 'CONTACT_FORM_SETTINGS_DIALOG_SUBMITTED',
  CONTACT_FORM_SETTINGS_DIALOG_CANCELED: 'CONTACT_FORM_SETTINGS_DIALOG_CANCELED',
  CONTACT_FORM_SELECT_ITEM_DIALOG_DISPLAYED: 'CONTACT_FORM_SELECT_ITEM_DIALOG_DISPLAYED',
  CONTACT_FORM_SELECT_ITEM_DIALOG_CLOSED: 'CONTACT_FORM_SELECT_ITEM_DIALOG_CLOSED',
  CONTACT_FORM_DELETE_EMAIL_DIALOG_DISPLAYED: 'CONTACT_FORM_DELETE_EMAIL_DIALOG_DISPLAYED',
  CONTACT_FORM_DELETE_EMAIL_DIALOG_SUBMITTED: 'CONTACT_FORM_DELETE_EMAIL_DIALOG_SUBMITTED',
  CONTACT_FORM_DELETE_EMAIL_DIALOG_CANCELED: 'CONTACT_FORM_DELETE_EMAIL_DIALOG_CANCELED',
  CODE_EDITOR_FOCUSED: 'CODE_EDITOR_FOCUSED',
  GALLERY_SETTINGS_DISPLAYED: 'GALLERY_SETTINGS_DISPLAYED',
  GALLERY_SETTINGS_SUBMITTED: 'GALLERY_SETTINGS_SUBMITTED',
  GALLERY_SETTINGS_CANCELLED: 'GALLERY_SETTINGS_CANCELLED',
  SLIDER_SETTINGS_DISPLAYED: 'SLIDER_SETTINGS_DISPLAYED',
  SLIDER_SETTINGS_SUBMITTED: 'SLIDER_SETTINGS_SUBMITTED',
  SLIDER_SETTINGS_CANCELLED: 'SLIDER_SETTINGS_CANCELLED',
  SLIDER_SETTINGS_INPUT_CLICKED: 'SLIDER_SETTINGS_INPUT_CLICKED',
  SLIDER_SETTINGS_OPTION_CLICKED: 'SLIDER_SETTINGS_OPTION_CLICKED',
  ORDER_A_WEBSITE_TRIGGER_CLICKED: 'ORDER_A_WEBSITE_TRIGGER_CLICKED',
  DIFM_PROMO_DIALOG_DISPLAYED: 'DIFM_PROMO_DIALOG_DISPLAYED',
  DIFM_PROMO_DIALOG_CLOSED: 'DIFM_PROMO_DIALOG_CLOSED',
  DIFM_PROMO_DIALOG_DONT_NEED_TRIGGER_CLICKED: 'DIFM_PROMO_DIALOG_DONT_NEED_TRIGGER_CLICKED',
  DIFM_PROMO_DIALOG_REMIND_ME_LATER_CLICKED: 'DIFM_PROMO_DIALOG_REMIND_ME_LATER_CLICKED',
  DIFM_PROMO_DIALOG_FINISH_DISPLAYED: 'DIFM_PROMO_DIALOG_FINISH_DISPLAYED',
  DIFM_PROMO_DIALOG_SUBMITTED: 'DIFM_PROMO_DIALOG_SUBMITTED',
  DIFM_PROMO_DIALOG_SERVICE_SELECTED: 'DIFM_PROMO_DIALOG_SERVICE_SELECTED',
  DIFM_PROMO_DIALOG_PACKAGE_SELECTED: 'DIFM_PROMO_DIALOG_PACKAGE_SELECTED',
  DIFM_PROMO_DIALOG_BACK_BUTTON_CLICKED: 'DIFM_PROMO_DIALOG_BACK_BUTTON_CLICKED',
  DIFM_PRIVACY_DIALOG_DISPLAYED: 'DIFM_PRIVACY_DIALOG_DISPLAYED',
  DIFM_PRIVACY_LEARN_MORE_TRIGGER_CLICKED: 'DIFM_PRIVACY_LEARN_MORE_TRIGGER_CLICKED',
  DIFM_PRIVACY_I_WILL_HANDLE_MYSELF_OPTION_CLICKED:
    'DIFM_PRIVACY_I_WILL_HANDLE_MYSELF_OPTION_CLICKED',
  DIFM_PRIVACY_DIALOG_CLOSED: 'DIFM_PRIVACY_DIALOG_CLOSED',
  DIFM_PRIVACY_GO_TO_SETTING_TRIGGER_CLICKED: 'DIFM_PRIVACY_GO_TO_SETTING_TRIGGER_CLICKED',
  DIFM_PROMO_YES_BUILD_A_WEBSITE_TRIGGER_CLICKED: 'DIFM_PROMO_YES_BUILD_A_WEBSITE_TRIGGER_CLICKED',
  SPACER_HEIGHT_RESIZED: 'SPACER_HEIGHT_RESIZED',
  BLOCK_REPLACEMENT_DIALOG_DISPLAYED: 'BLOCK_REPLACEMENT_DIALOG_DISPLAYED',
  BLOCK_REPLACEMENT_DIALOG_SUBMITTED: 'BLOCK_REPLACEMENT_DIALOG_SUBMITTED',
  BLOCK_REPLACEMENT_DIALOG_CANCELLED: 'BLOCK_REPLACEMENT_DIALOG_CANCELLED',
  BLOCK_LIMIT_DIALOG_DISPLAYED: 'BLOCK_LIMIT_DIALOG_DISPLAYED',
  AI_SITE_WIZARD_GENERATE_SITE_TRIGGER_CLICKED: 'AI_SITE_WIZARD_GENERATE_SITE_TRIGGER_CLICKED',
  AI_SITE_WIZARD_DIALOG_DISPLAYED: 'AI_SITE_WIZARD_DIALOG_DISPLAYED',
  AI_SITE_WIZARD_DIALOG_CLOSED: 'AI_SITE_WIZARD_DIALOG_CLOSED',
  AI_SITE_WIZARD_SETUP_DIALOG_DISPLAYED: 'AI_SITE_WIZARD_SETUP_DIALOG_DISPLAYED',
  AI_SITE_WIZARD_SETUP_DIALOG_CLOSED: 'AI_SITE_WIZARD_SETUP_DIALOG_CLOSED',
  AI_SITE_WIZARD_SETUP_NEXT_STEP_CLICKED: 'AI_SITE_WIZARD_SETUP_NEXT_STEP_CLICKED',
  AI_SITE_WIZARD_SETUP_BACK_STEP_CLICKED: 'AI_SITE_WIZARD_SETUP_BACK_STEP_CLICKED',
  AI_SITE_WIZARD_SETUP_SKIP_STEP_CLICKED: 'AI_SITE_WIZARD_SETUP_SKIP_STEP_CLICKED',
  AI_SITE_WIZARD_SETUP_CHANGE_STEP_CLICKED: 'AI_SITE_WIZARD_SETUP_CHANGE_STEP_CLICKED',
  AI_SITE_WIZARD_SETUP_EDIT_STEP_CLICKED: 'AI_SITE_WIZARD_SETUP_EDIT_STEP_CLICKED',
  AI_SITE_WIZARD_SETUP_COMPLETED: 'AI_SITE_WIZARD_SETUP_COMPLETED',
  AI_WIZARD_GENERATING_DIALOG_DISPLAYED: 'AI_WIZARD_GENERATING_DIALOG_DISPLAYED',
  AI_WIZARD_ERROR_DIALOG_DISPLAYED: 'AI_WIZARD_ERROR_DIALOG_DISPLAYED',
  AI_WIZARD_ERROR_DIALOG_CLOSED: 'AI_WIZARD_ERROR_DIALOG_CLOSED',
  AI_WIZARD_TRY_AGAIN_TRIGGER_CLICKED: 'AI_WIZARD_TRY_AGAIN_TRIGGER_CLICKED',
  AI_WIZARD_ONBOARDING_DIALOG_DISPLAYED: 'AI_WIZARD_ONBOARDING_DIALOG_DISPLAYED',
  AI_WIZARD_ONBOARDING_BUSINESS_INFO_ADDED: 'AI_WIZARD_ONBOARDING_BUSINESS_INFO_ADDED',
  AI_WIZARD_ONBOARDING_DIALOG_CLOSED: 'AI_WIZARD_ONBOARDING_DIALOG_CLOSED',
  AI_WIZARD_CLOSE_DIALOG_DISPLAYED: 'AI_WIZARD_CLOSE_DIALOG_DISPLAYED',
  AI_BLOCK_REGENERATE_BUTTON_CLICKED: 'AI_BLOCK_REGENERATE_BUTTON_CLICKED',
  ADD_PAGE_DIALOG_CANCELLED: 'ADD_PAGE_DIALOG_CANCELLED',
  ADD_PAGE_DIALOG_DISPLAYED: 'ADD_PAGE_DIALOG_DISPLAYED',
  ADD_PAGE_DIALOG_SUBMITTED: 'ADD_PAGE_DIALOG_SUBMITTED',
  SUBSCRIPTION_EXPIRED_DIALOG_DISPLAYED: 'SUBSCRIPTION_EXPIRED_DIALOG_DISPLAYED',
  SUBSCRIPTION_EXPIRED_DIALOG_CANCELLED: 'SUBSCRIPTION_EXPIRED_DIALOG_CANCELLED',
  RENEW_TRIGGER_CLICKED: 'RENEW_TRIGGER_CLICKED',
  PAGE_SETTINGS_DISPLAYED: 'PAGE_SETTINGS_DISPLAYED',
  PAGE_SETTINGS_SUBMITTED: 'PAGE_SETTINGS_SUBMITTED',
  PAGE_SETTINGS_CANCELLED: 'PAGE_SETTINGS_CANCELLED',
  ADD_AI_PAGE_TRIGGER_CLICKED: 'ADD_AI_PAGE_TRIGGER_CLICKED',
  AI_TEXT_USER_INPUT_SUBMITTED: 'AI_TEXT_USER_INPUT_SUBMITTED',
  AI_TEXT_ASSISTANT_TRY_AGAIN_TRIGGER_CLICKED: 'AI_TEXT_ASSISTANT_TRY_AGAIN_TRIGGER_CLICKED',
  AI_TEXT_ASSISTANT_ERROR_CAUGHT: 'AI_TEXT_ASSISTANT_ERROR_CAUGHT',
  WEBSITE_SETTINGS_DIALOG_DISPLAYED: 'WEBSITE_SETTINGS_DIALOG_DISPLAYED',
  WEBSITE_SETTINGS_DIALOG_SUBMITTED: 'WEBSITE_SETTINGS_DIALOG_SUBMITTED',
  WEBSITE_SETTINGS_DIALOG_CANCELED: 'WEBSITE_SETTINGS_DIALOG_CANCELED',
  BUSINESS_CATEGORY_INPUT_FOCUSED: 'BUSINESS_CATEGORY_INPUT_FOCUSED',
  BUSINESS_CATEGORY_SELECTED: 'BUSINESS_CATEGORY_SELECTED',
  ADD_PREMIUM_BLOCK_CLICKED: 'ADD_PREMIUM_BLOCK_CLICKED',
  TUTORIAL_CLICKED: 'TUTORIAL_CLICKED',
  PUBLISH_INTERRUPTION_DISPLAYED: 'PUBLISH_INTERRUPTION_DISPLAYED',
  PUBLISH_INTERRUPTION_CLOSED: 'PUBLISH_INTERRUPTION_CLOSED',
  PUBLISH_INTERRUPTION_PREVIEW_CLICKED: 'PUBLISH_INTERRUPTION_PREVIEW_CLICKED',
  PUBLISH_INTERRUPTION_CUSTOM_DOMAIN_CLICKED: 'PUBLISH_INTERRUPTION_CUSTOM_DOMAIN_CLICKED',
  UNDO_TRIGGER_CLICKED: 'UNDO_TRIGGER_CLICKED',
  REDO_TRIGGER_CLICKED: 'REDO_TRIGGER_CLICKED',
};
