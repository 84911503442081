import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import BusinessCategoryField from 'src/js/modules/ai/components/fields/business-category-field';

const { Stack, Heading, Paragraph, ControlGroup, InputGroupField } = designSystem;

function WizardSetupGeneralSection(props) {
  const { captions, siteNameField, businessCategoryField, isBusinessTaxonomyFeatureEnabled } =
    props;

  return (
    <Stack gap="spacing-m">
      <Stack gap="spacing-3-xs">
        <Heading type="heading-3">{captions.sectionTitle}</Heading>
        <Paragraph>{captions.sectionDescription}</Paragraph>
      </Stack>
      <Stack gap="spacing-xs">
        {isBusinessTaxonomyFeatureEnabled && (
          <ControlGroup title={businessCategoryField.label} required>
            <BusinessCategoryField
              control={businessCategoryField.control}
              name={businessCategoryField.name}
              placeholder={businessCategoryField.placeholder}
              noResultsText={businessCategoryField.noResultsText}
              rules={businessCategoryField.rules}
              onChange={businessCategoryField.onChange}
              onFocus={businessCategoryField.onFocus}
            />
          </ControlGroup>
        )}
        <ControlGroup title={siteNameField.label} required>
          <InputGroupField
            control={siteNameField.control}
            name={siteNameField.name}
            rules={siteNameField.rules}
            size="medium"
            onChange={siteNameField.onChange}
          />
        </ControlGroup>
      </Stack>
    </Stack>
  );
}

WizardSetupGeneralSection.propTypes = {
  captions: PropTypes.shape().isRequired,
  siteNameField: PropTypes.shape().isRequired,
  businessCategoryField: PropTypes.shape().isRequired,
  isBusinessTaxonomyFeatureEnabled: PropTypes.bool.isRequired,
};

export default WizardSetupGeneralSection;
