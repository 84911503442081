import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { dialogs, i18n, site, template } from '@yola/ws-sdk';
import useMatchBreakpoint from 'src/js/modules/utils/custom-hooks/use-match-breakpoint';
import segment from '../../analytics/segment';
import UpgradeInterruptionDialog from '../components/upgrade-interruption-dialog';

const {
  constants: { events },
  track,
} = segment;

const PRODUCT_CATEGORY = 'latitude';
const DESKTOP_VIEW_BREAKPOINT = '576px';
const DEFAULT_LANGUAGE_CODE = 'en';
const LOCALIZED_IMAGES = ['en', 'cs', 'de', 'es', 'fr', 'it', 'pt-br', 'sk', 'sv'];
const AI_FEATURES = ['ai_block_generation_quota', 'ai_page_wizard', 'ai_text_assistant'];

const UpgradeDialogContainer = (props) => {
  const {
    isReopening,
    onDialogCancel,
    onDialogMainAction,
    onUpgrade,
    onUpgradeResolve,
    onUpgradeReject,
    onClose,
    premiumFeature,
    purchaseFlowType,
    captions,
  } = props;

  const dispatch = useDispatch();
  const isDesktopView = useMatchBreakpoint(DESKTOP_VIEW_BREAKPOINT);
  const locale = i18n.accessors.getLocale();
  const languageCode = LOCALIZED_IMAGES.includes(locale) ? locale : DEFAULT_LANGUAGE_CODE;
  const isAIFeature = AI_FEATURES.includes(premiumFeature);

  const imageName = isAIFeature ? 'ai-feature' : 'upgrade-interruption';
  const imageSrc = `/images/upgrade-interruption/${
    isDesktopView || isAIFeature ? 'desktop' : 'mobile'
  }/${imageName}-${languageCode}.png`;

  const handleSubmit = () => {
    track(events.UPGRADE_INTERRUPTION_SUBMITTED, {
      siteId: site.accessors.getSiteId(),
      templateBuildSlug: template.accessors.getBuildSlug(),
      purchaseFlowType,
      productCategory: PRODUCT_CATEGORY,
      premiumFeature,
    });

    onDialogMainAction();
    dispatch(dialogs.actions.hide());

    onUpgrade().then(
      (event) => {
        onUpgradeResolve(event);
      },
      () => {
        onUpgradeReject();
      }
    );
  };

  const handleClose = () => {
    track(events.UPGRADE_INTERRUPTION_SUBMITTED, {
      siteId: site.accessors.getSiteId(),
      templateBuildSlug: template.accessors.getBuildSlug(),
      purchaseFlowType,
      productCategory: PRODUCT_CATEGORY,
      premiumFeature,
    });

    onDialogCancel();
    onClose();
    dispatch(dialogs.actions.hide());
    onUpgradeReject();
  };

  useEffect(() => {
    if (isReopening) return;

    track(events.UPGRADE_INTERRUPTION_DISPLAYED, {
      siteId: site.accessors.getSiteId(),
      templateBuildSlug: template.accessors.getBuildSlug(),
      purchaseFlowType,
      productCategory: PRODUCT_CATEGORY,
      premiumFeature,
    });
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  return (
    <UpgradeInterruptionDialog
      isDesktopView={isDesktopView}
      captions={captions}
      imageSrc={imageSrc}
      onClose={handleClose}
      onSubmit={handleSubmit}
    />
  );
};

UpgradeDialogContainer.propTypes = {
  isReopening: PropTypes.bool,
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    entitlements: PropTypes.arrayOf(PropTypes.string.isRequired),
    upgrade: PropTypes.string.isRequired,
  }).isRequired,
  premiumFeature: PropTypes.string.isRequired,
  onUpgrade: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onUpgradeReject: PropTypes.func,
  onUpgradeResolve: PropTypes.func,
  purchaseFlowType: PropTypes.string.isRequired,
};

UpgradeDialogContainer.defaultProps = {
  isReopening: false,
  onUpgradeResolve: Function.prototype,
  onClose: Function.prototype,
  onUpgradeReject: Function.prototype,
};

export default UpgradeDialogContainer;
