import React, { useEffect, useState, useMemo, useRef } from 'react';
import { designSystem, ImageGrid, LazyLoading, Spinner } from '@yola/ws-ui';
import { assets, i18next } from '@yola/ws-sdk';
import PropTypes from 'prop-types';
import usePrevious from 'src/js/modules/utils/custom-hooks/use-previous';
import constants from '../constants/common';
import getIconName from '../helpers/get-icon-name';
import mapIconsData from '../helpers/map-icons-data';
import getFilteredIcons from '../helpers/get-filtered-icons';
import useMounted from '../../../../utils/custom-hooks/use-mounted';
import getColumnsAmount from '../helpers/get-columns-amount';
import useRefHeightOnScreen from '../hooks/use-ref-height-on-screen';
import ConditionalWrapper from '../../../../common/components/conditional-wrapper';
import ImageGridSkeleton from './image-grid-skeleton';

const { Search, Heading, Paragraph } = designSystem;
const {
  INIT_NUMBER_OF_ICONS_ON_SCREEN,
  ROOT_ICONS_DIRECTORY,
  ICONS_SCROLL_THRESHOLD,
  ICON_PREVIEW_SIZE,
  IMAGE_GRID_SKELETON_TOP_INDENT,
} = constants;

const BasicIcons = ({
  originalIconName,
  containerHeight,
  containerWidth,
  onSearchClick,
  selectedIcon,
  setSelectedIcon,
  setIsButtonSaveActive,
  selectIcon,
  onSearchPerform,
}) => {
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [areIconsLoading, setAreIconsLoading] = useState(false);
  const [isFilteringInProgress, setIsFilteringInProgress] = useState(false);
  const [icons, setIcons] = useState([]);
  const [numOfIcons, setNumOfIcons] = useState(0);
  const [filterValue, setFilterValue] = useState('');
  const [totalIconsCount, setTotalIconsCount] = useState(0);
  const filterContainerRef = useRef();
  const mountedRef = useMounted();
  const prevFilterValue = usePrevious(filterValue);

  const visibleIcons = useMemo(
    () => getFilteredIcons(icons, filterValue).slice(0, numOfIcons),
    [icons, filterValue, numOfIcons]
  );

  const columnsAmount = getColumnsAmount(visibleIcons, containerWidth);
  const filterHeight = useRefHeightOnScreen(filterContainerRef);

  useEffect(() => {
    const selectedIconName = getIconName(selectedIcon.src);
    const isButtonSaveActive = selectedIconName && originalIconName !== selectedIconName;

    setIsButtonSaveActive(isButtonSaveActive);
  }, [selectedIcon.src, setIsButtonSaveActive, originalIconName]);

  const onIconsLoading = (iconsToLoad, valueToFilter) => {
    const nextStep = numOfIcons + INIT_NUMBER_OF_ICONS_ON_SCREEN;
    const filteredLengthOfIcons = getFilteredIcons(iconsToLoad, valueToFilter).length;
    const nextNumOfIcons = filteredLengthOfIcons < nextStep ? filteredLengthOfIcons : nextStep;

    setTotalIconsCount(filteredLengthOfIcons);

    if (!areIconsLoading) {
      setAreIconsLoading(true);

      Promise.all(
        getFilteredIcons(iconsToLoad, valueToFilter)
          .slice(numOfIcons, nextNumOfIcons)
          .map((icon) => {
            const img = new Image();

            if (!selectedIcon.src && originalIconName === getIconName(icon.src)) {
              setSelectedIcon(icon);
            }

            img.src = icon.src;
            return new Promise((resolve, reject) => {
              img.onload = () => {
                resolve();
              };
              img.onerror = () => {
                reject();
              };
            });
          })
      ).finally(() => {
        if (valueToFilter) {
          onSearchPerform({
            searchTerm: valueToFilter,
            searchResults: nextNumOfIcons,
          });
        }

        if (!mountedRef.current) return;

        setIsFirstLoad(false);
        setAreIconsLoading(false);
        setNumOfIcons(nextNumOfIcons);
        setIsFilteringInProgress(false);
      });
    }
  };

  useEffect(() => {
    assets.helpers.fetchWsAsset(`${ROOT_ICONS_DIRECTORY}/basic/icons.json`).then((response) => {
      const { data } = response;
      const mappedData = mapIconsData(data);
      const fetchedIcons = [...mappedData.line, ...mappedData.solid];

      if (mountedRef.current) {
        setIcons(fetchedIcons);
        onIconsLoading(fetchedIcons, filterValue);
      }
    });
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  const onFilter = (value = '') => {
    const prettifiedFilterValue = value.trim().toLowerCase();

    if (!prettifiedFilterValue && !prevFilterValue) return;

    setIsFilteringInProgress(true);
    setAreIconsLoading(false);

    setFilterValue(prettifiedFilterValue);
    setNumOfIcons(0);

    onIconsLoading(icons, prettifiedFilterValue);
  };

  return (
    <>
      <div className="ws-icon-modal__search" ref={filterContainerRef}>
        <Search
          onChange={onFilter}
          onClear={onFilter}
          onFocus={onSearchClick}
          placeholder={i18next.t('Search...')}
        />
      </div>
      <ConditionalWrapper
        condition={!isFilteringInProgress}
        wrapper={(children) => (
          <LazyLoading
            onThresholdExceed={() => {
              if (totalIconsCount > visibleIcons.length) {
                onIconsLoading(icons, filterValue);
              }
            }}
            threshold={ICONS_SCROLL_THRESHOLD}
            height={containerHeight - filterHeight}
          >
            {children}
          </LazyLoading>
        )}
      >
        <div className="ws-icon-modal__body">
          {!isFirstLoad && !isFilteringInProgress && !!visibleIcons.length && (
            <ImageGrid
              height="100%"
              items={visibleIcons}
              iconSize="68px"
              activeItemId={selectedIcon.id}
              onItemClick={selectIcon}
              columns={columnsAmount}
            />
          )}

          {(isFirstLoad || isFilteringInProgress) && (
            <div
              className="ws-icon-modal__skeleton-container"
              style={{ height: `${containerHeight - filterHeight}px` }}
            >
              <ImageGridSkeleton
                containerHeight={containerHeight - filterHeight - IMAGE_GRID_SKELETON_TOP_INDENT}
                containerWidth={containerWidth}
                itemSize={ICON_PREVIEW_SIZE}
              />
            </div>
          )}

          {!isFirstLoad && !isFilteringInProgress && !visibleIcons.length && (
            <div className="ws-icon-modal__not-found-container">
              <Heading type="heading-2" appearance="low-emphasis">
                {i18next.t('Sorry!')}
              </Heading>
              <Paragraph size="large" appearance="low-emphasis">
                {i18next.t('Nothing was found')}
              </Paragraph>
            </div>
          )}

          {!isFirstLoad && areIconsLoading && !isFilteringInProgress && (
            <Spinner containerPosition="bottom" spinnerPosition="center" />
          )}
        </div>
      </ConditionalWrapper>
    </>
  );
};

BasicIcons.propTypes = {
  originalIconName: PropTypes.string.isRequired,
  containerHeight: PropTypes.number,
  containerWidth: PropTypes.number,
  onSearchClick: PropTypes.func,
  selectedIcon: PropTypes.object,
  setSelectedIcon: PropTypes.func.isRequired,
  setIsButtonSaveActive: PropTypes.func.isRequired,
  selectIcon: PropTypes.func.isRequired,
  onSearchPerform: PropTypes.func,
};

BasicIcons.defaultProps = {
  onSearchClick: () => {},
  selectedIcon: {},
  onSearchPerform: () => {},
  containerHeight: null,
  containerWidth: null,
};

export default BasicIcons;
