import i18nSelectors from 'yola-editor/src/js/modules/i18n/selectors';
import authSelectors from 'yola-editor/src/js/modules/auth/selectors';

const getLanguage = (state) => i18nSelectors.getLocale(state);
const getSiteId = (state) => authSelectors.getSiteId(state);

export default (state) => ({
  language: getLanguage(state),
  siteId: getSiteId(state),
});
