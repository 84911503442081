import React, { useRef, useMemo, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { i18next, utils, blocks, view } from '@yola/ws-sdk';
import { designSystem } from '@yola/ws-ui';
import getLocation from 'src/js/modules/control-pane/selectors/location';
import highlighter from 'src/js/modules/highlighter';
import segment from 'src/js/modules/analytics/segment';
import getDefaultTraits from 'src/js/modules/analytics/segment/helpers/get-default-traits';
import useEventListener from 'src/js/modules/utils/custom-hooks/use-event-listener';
import { VARIATION_ATTRIBUTE_NAME } from 'src/js/modules/blocks/constants/common';
import ResizeHandle from '../../../common/components/resize-handle';
import useHandlePosition from '../../../common/hooks/use-handle-position';
import usePositioningArea from '../../../common/hooks/use-positioning-area';
import useDrag from '../../../common/hooks/use-drag';
import resizeHandlePositions from '../../../common/constants/resize-handle-positions';
import collectDOMData from '../../../common/helpers/collect-dom-data';
import getHandleDirectionByPosition from '../../../common/helpers/get-handle-direction-by-position';
import getHandleShapeByPosition from '../../../common/helpers/get-handle-shape-by-position';
import parseWidthToPx from '../../../common/helpers/parse-width-to-px';
import getPositionCustomProperties from '../../../common/helpers/get-position-custom-properties';
import {
  MAX_CONTAINER_HEIGHT,
  MAX_CONTAINER_WIDTH_PERCENTAGE,
  MEDIA_GROUP_ATTRIBUTE,
  MIN_CONTAINER_HEIGHT,
  MIN_CONTAINER_WIDTH,
} from '../constants/media-container';
import restoreScrollPosition from '../helpers/restore-scroll-position';
import getResizeOperations from '../helpers/get-resize-operations';
import getPositionOperations from '../helpers/get-position-operations';
import getElementsWithHeightInRow from '../helpers/get-elements-with-height-in-row';
import findClosestSiblingsByHeight from '../helpers/find-closest-siblings-by-height';
import trackImageResizingCompleted from '../trackers/track-image-resizing-completed';
import getRatio from '../helpers/get-ratio';
import isSingleImageResizingDisabled from '../helpers/is-single-image-resizing-disabled';
import isGallerySliderImage from '../helpers/is-gallery-slider-image';
import handleAspectRatioChanges from '../helpers/handle-aspect-ratio-changes';
import getOverlappedMediaContainerByText from '../helpers/get-overlapped-media-container-by-text';
import getVisibleResizeTriggers from '../helpers/get-visible-resize-triggers';
import getLimitedHeight from '../helpers/get-limited-height';
import isPositioningImage from '../helpers/is-positioning-image';
import getPositioningImages from '../helpers/get-positioning-images';
import {
  freezePosition,
  unfreezePosition,
  fittingInPositioningArea,
} from '../helpers/get-positioning-image-resize-handlers';
import getPositioningAreaConstraints from '../helpers/get-positioning-area-constraints';
import ResizeTooltip from './resize-tooltip';

const { Highlighter } = designSystem;
const {
  track,
  constants: { events },
} = segment;

const getCaptions = () => ({
  shiftPressed: i18next.t('Release <b>Shift</b> to resize this image only'),
  shiftReleased: i18next.t('Hold <b>Shift</b> to resize all images in a row'),
  default: i18next.t('Drag to resize'),
});

const ImageResizeTool = ({
  elementId: liveElementId,
  scrollPosition,
  onActionStart,
  onActionEnd,
  onMouseEnter,
  onMouseLeave,
}) => {
  const liveElement = view.accessors.getLiveElement(liveElementId);
  const overlappedMediaContainer = getOverlappedMediaContainerByText(liveElement, true);
  const elementId = overlappedMediaContainer
    ? view.accessors.getLiveElementId(overlappedMediaContainer)
    : liveElementId;

  const { element, blockElement, groupElements, nonResizableRowsCount } = useMemo(
    () => collectDOMData(elementId, MEDIA_GROUP_ATTRIBUTE),
    [elementId]
  );
  const overlayingImages = getPositioningImages(blockElement);

  const captions = useMemo(getCaptions, []);

  const [shiftPressed, setShiftStatus] = useState(false);
  const [isResizing, setResizingStatus] = useState(false);
  const [tooltipPosition, showTooltip] = useState(null);
  const activeDragHandlerPos = useRef(null);
  const currentAspectRatio = useRef(null);
  const cachedRect = useRef(null);
  const elementOffsetRatio = useRef(1);
  const constraints = useRef(null);
  const cachedSiblings = useRef([]);
  const cachedRowElements = useRef([]);
  const cachedResizableElements = useRef(new Map());
  const cachedHighlightedElements = useRef([]);
  const lastPositionRef = useRef(null);

  const supportGroupResize = groupElements.length > 1;
  const singleImageResizingDisabled = isSingleImageResizingDisabled(element);
  const isSliderImage = isGallerySliderImage(element);
  const isPositionedImage = isPositioningImage(element);
  const isBaseImageForPositioning = !isPositionedImage && overlayingImages.length;
  const shouldHandlePositioningImages = isPositionedImage || isBaseImageForPositioning;
  const isMultipleOrGalleryResize =
    (supportGroupResize && shiftPressed) || singleImageResizingDisabled || isSliderImage;

  const indent = highlighter.helpers.highlighterOffset.get();
  const controlPaneLocation = useSelector(getLocation);

  const handlersConfig = useMemo(
    () => ({
      [resizeHandlePositions.TOP_LEFT]: {
        correctionX: -indent,
        correctionY: -indent,
      },
      [resizeHandlePositions.TOP_CENTER]: {
        correctionY: -indent,
      },
      [resizeHandlePositions.TOP_RIGHT]: {
        correctionX: indent,
        correctionY: -indent,
      },
      [resizeHandlePositions.CENTER_LEFT]: {
        correctionX: -indent,
      },
      [resizeHandlePositions.CENTER_RIGHT]: {
        correctionX: indent,
      },
      [resizeHandlePositions.BOTTOM_LEFT]: {
        correctionX: -indent,
        correctionY: indent,
      },
      [resizeHandlePositions.BOTTOM_CENTER]: {
        correctionY: indent,
      },
      [resizeHandlePositions.BOTTOM_RIGHT]: {
        correctionX: indent,
        correctionY: indent,
      },
    }),
    [indent]
  );

  const [visibleResizeTriggers, setVisibleResizeTriggers] = useState(
    getVisibleResizeTriggers(element, handlersConfig)
  );
  const updateVisibleResizeTriggers = () => {
    setVisibleResizeTriggers(getVisibleResizeTriggers(element, handlersConfig));
  };

  const refsByPos = useMemo(() => {
    const refs = {};
    Object.keys(visibleResizeTriggers).forEach((item) => {
      refs[item] = React.createRef();
    });
    return refs;
  }, [visibleResizeTriggers]);

  const [handlePosition, updateHandlePosition] = useHandlePosition({
    element,
    positions: visibleResizeTriggers,
    controlPaneLocation,
  });

  const { positioningAreaBounds, positioningAreaClipPath, updateClipPath } = usePositioningArea(
    element,
    scrollPosition
  );

  const cacheSiblingsWithSameHeight = (elements) => {
    cachedSiblings.current.push(...elements);
  };

  const cacheRowElements = () => {
    cachedRowElements.current.push(...getElementsWithHeightInRow(element, groupElements, false));
  };

  const clearCacheRowElements = () => {
    cachedRowElements.current.splice(0, cachedRowElements.current.length);
  };

  const cacheResizableElements = () => {
    groupElements.forEach((resizableElement) => {
      const { width, height } = resizableElement.getContainerBounds();
      cachedResizableElements.current.set(resizableElement, {
        width,
        height,
      });
    });
  };

  const clearCacheResizableElements = () => {
    cachedResizableElements.current = new Map();
  };

  const hideAnchors = () => {
    highlighter.operations.hideAnchors();
    cachedSiblings.current.splice(0, cachedSiblings.current.length);
  };

  const showAnchors = () => {
    const elementsToShowAnchors = [element, ...cachedSiblings.current];
    highlighter.operations.showAnchors(elementsToShowAnchors);
  };

  const hideHighlighter = () => {
    highlighter.operations.hide(cachedHighlightedElements.current);
    cachedHighlightedElements.current = [];
  };

  const hideGroupHighlighter = () => {
    if (!isMultipleOrGalleryResize) return;

    const highlightedElements = cachedHighlightedElements.current.filter((el) => el !== element);
    highlighter.operations.hide(highlightedElements);
    cachedHighlightedElements.current = [element];
  };

  const showHighlighter = (options = {}) => {
    const { isGroup = isMultipleOrGalleryResize, withElementSize = false } = options;
    let highlightedGroupElements = [];

    highlighter.operations.show([element], {
      withElementSize,
      forceUpdate: true,
      labelGap: 'medium',
    });

    if (isGroup && !isSliderImage) {
      highlightedGroupElements = groupElements.filter((groupElement) => groupElement !== element);
      highlighter.operations.show(highlightedGroupElements, { forceUpdate: true });
    }

    cachedHighlightedElements.current = [element, ...highlightedGroupElements];
  };

  const setElementsHeightByCurrent = () => {
    const { height, width } = element.getBoundingClientRect();
    const { maxAspectRatio } = constraints.current;

    cachedResizableElements.current.forEach((bounds, resizableElement) => {
      const limitedHeight = getLimitedHeight({
        width,
        height,
        maxAspectRatio,
      });
      const ratio = getRatio({
        width,
        height: limitedHeight,
      });

      handleAspectRatioChanges({
        element: resizableElement,
        newAspectRatio: ratio,
        bounds,
      });
    });
  };

  const resetElementsHeight = () => {
    const { width } = cachedRect.current;
    const { maxAspectRatio } = constraints.current;

    cachedResizableElements.current.forEach((bounds, resizableElement) => {
      if (resizableElement === element) return;
      const limitedHeight = getLimitedHeight({
        width,
        height: bounds.height,
        maxAspectRatio,
      });

      const ratio = getRatio({
        width,
        height: limitedHeight,
      });

      handleAspectRatioChanges({
        element: resizableElement,
        newAspectRatio: ratio,
        bounds,
      });
    });
  };

  const getConstraints = (resizeDirection) => {
    const parent = element.parentElement;
    const parentStyles = window.getComputedStyle(parent, null);
    const styles = window.getComputedStyle(element, null);
    const parentWidth =
      parent.clientWidth -
      parseInt(parentStyles.paddingLeft, 10) -
      parseInt(parentStyles.paddingRight, 10);

    const defaultConstraints = {
      maxAspectRatio: element.maxAspectRatio,
      minHeight: MIN_CONTAINER_HEIGHT,
      maxHeight: element.maxAspectRatio
        ? parentWidth / element.maxAspectRatio
        : MAX_CONTAINER_HEIGHT,
      minWidth: parseWidthToPx(styles.minWidth, parentWidth) || MIN_CONTAINER_WIDTH,
      maxWidth:
        parseWidthToPx(styles.maxWidth, parentWidth) ||
        (parentWidth * MAX_CONTAINER_WIDTH_PERCENTAGE) / 100,
      parentWidth,
    };

    if (isPositionedImage) {
      return getPositioningAreaConstraints({
        elementBounds: element.getBoundingClientRect(),
        positioningAreaBounds,
        defaultConstraints,
        resizeDirection,
      });
    }

    return defaultConstraints;
  };

  const handleResize = (currentWidth, currentHeight, aspectRatio, applyLiveChanges) => {
    const { width, height, bottom } = cachedRect.current;
    const { maxAspectRatio, minHeight, minWidth, maxWidth, maxHeight, parentWidth } =
      constraints.current;
    let newWidth = currentWidth !== null ? currentWidth : width;

    let newHeight = Math.round(
      getLimitedHeight({
        width: parentWidth,
        height: currentHeight,
        maxAspectRatio,
        minHeight,
        maxHeight,
      })
    );

    if (currentWidth) {
      newWidth = Math.max(Math.min(Math.round(currentWidth), maxWidth), minWidth);

      if (isPositionedImage) {
        element.width = `${newWidth}px`;
      } else {
        element.width = newWidth !== maxWidth ? `${newWidth}px` : null;
      }
    }

    let ratio = getRatio({ width: newWidth, height: newHeight });
    if (aspectRatio) {
      const heightWithKeptRatio = newWidth / aspectRatio;
      if (heightWithKeptRatio < minHeight) {
        ratio = getRatio({ width: newWidth, height: minHeight });
      } else {
        ratio = aspectRatio;
      }
    }

    if (!supportGroupResize) {
      hideAnchors();
      handleAspectRatioChanges({
        element,
        newAspectRatio: ratio,
        bounds: cachedResizableElements.current.get(element),
      });
    }

    if (supportGroupResize) {
      if (isMultipleOrGalleryResize) {
        hideAnchors();
        groupElements.forEach((resizableElement) => {
          const el = resizableElement;

          if (currentWidth) {
            el.width = newWidth !== maxWidth ? `${newWidth}px` : null;
          }

          handleAspectRatioChanges({
            element: el,
            newAspectRatio: ratio,
            bounds: cachedResizableElements.current.get(element),
          });
        });
      } else {
        const matchedElements = findClosestSiblingsByHeight(
          getElementsWithHeightInRow(element, groupElements),
          newHeight
        );

        const [firstMatchedElement] = matchedElements;

        if (firstMatchedElement) {
          newHeight = firstMatchedElement.height;
          if (!aspectRatio || newHeight === minHeight) {
            newHeight = getLimitedHeight({
              width: newWidth,
              height: newHeight,
              maxAspectRatio,
            });
            ratio = getRatio({ width: newWidth, height: newHeight });
          }

          hideAnchors();
          cacheSiblingsWithSameHeight(matchedElements.map(({ element: sibling }) => sibling));
        } else {
          hideAnchors();
        }
        handleAspectRatioChanges({
          element,
          newAspectRatio: ratio,
          bounds: cachedResizableElements.current.get(element),
        });
      }
    }

    const elementRect = element.getBoundingClientRect();
    const heightDelta = Math.abs(Math.floor(elementRect.height) - Math.floor(height));
    const bottomDelta = Math.abs(Math.floor(elementRect.bottom) - Math.floor(bottom));

    elementOffsetRatio.current = bottomDelta === 0 ? 1 : bottomDelta / heightDelta;

    updateHandlePosition(visibleResizeTriggers);

    if (cachedSiblings.current.length) {
      showAnchors();
    }

    if (!applyLiveChanges) return;

    let resizeOperations = [];
    let positionOperations = [];

    if (isMultipleOrGalleryResize) {
      const biggestHeightInRow = cachedRowElements.current.reduce(
        (acc, itm) => (itm.height > acc ? itm.height : acc),
        0
      );
      const scrollDistance = (newHeight - biggestHeightInRow) * nonResizableRowsCount;

      resizeOperations = getResizeOperations(groupElements);
      restoreScrollPosition(scrollPosition + scrollDistance);
    } else {
      resizeOperations = getResizeOperations([element]);
    }

    if (isPositionedImage) {
      positionOperations = getPositionOperations([element]);
    }

    if (isBaseImageForPositioning) {
      positionOperations = getPositionOperations(overlayingImages);
    }

    view.operations.bulkViewOperations([...resizeOperations, ...positionOperations]);

    const blockId = blocks.accessors.getBlockIdByElement(blockElement);
    const blockVariationId = blockElement.getAttribute(VARIATION_ATTRIBUTE_NAME);

    trackImageResizingCompleted({
      blockId,
      blockVariationId,
      oldHeight: height,
      newHeight,
      affectedImages: isMultipleOrGalleryResize ? groupElements.length : 1,
      shiftKeyPressed: shiftPressed,
    });
  };

  const handleDrag = (distance, applyLiveChanges = false) => {
    const { width, height } = cachedRect.current;
    const [x, y] = distance;
    let newWidth = null;
    let newHeight = height;
    let aspectRatio = null;

    switch (activeDragHandlerPos.current) {
      case resizeHandlePositions.TOP_LEFT: {
        newWidth = width - x;
        newHeight = newWidth / currentAspectRatio.current;
        break;
      }

      case resizeHandlePositions.TOP_CENTER: {
        newHeight = height - y / elementOffsetRatio.current;
        break;
      }

      case resizeHandlePositions.TOP_RIGHT: {
        newWidth = width + x;
        newHeight = newWidth / currentAspectRatio.current;
        break;
      }

      case resizeHandlePositions.CENTER_LEFT: {
        newWidth = width - x;
        break;
      }

      case resizeHandlePositions.CENTER_RIGHT: {
        newWidth = width + x;
        break;
      }

      case resizeHandlePositions.BOTTOM_LEFT: {
        newWidth = width - x + y / 2;
        if (newWidth > MIN_CONTAINER_WIDTH) {
          aspectRatio = currentAspectRatio.current;
        } else {
          const currentHeight = newWidth / currentAspectRatio.current;
          if (height > MIN_CONTAINER_HEIGHT) {
            aspectRatio = getRatio({ width: MIN_CONTAINER_WIDTH, height: currentHeight - 1 });
          }
        }
        break;
      }

      case resizeHandlePositions.BOTTOM_CENTER: {
        newHeight = height + y / elementOffsetRatio.current;
        break;
      }

      case resizeHandlePositions.BOTTOM_RIGHT: {
        newWidth = width + x + y / 2;
        if (newWidth > MIN_CONTAINER_WIDTH) {
          aspectRatio = currentAspectRatio.current;
        } else {
          const currentHeight = newWidth / currentAspectRatio.current;
          if (height > MIN_CONTAINER_HEIGHT) {
            aspectRatio = getRatio({ width: MIN_CONTAINER_WIDTH, height: currentHeight - 1 });
          }
        }
        break;
      }

      default: {
        newHeight = height + y / elementOffsetRatio.current;
        break;
      }
    }

    handleResize(newWidth, newHeight, aspectRatio, applyLiveChanges);
  };

  const handleMouseEnter = (e) => {
    const { target } = e;
    onMouseEnter();
    showHighlighter();

    const position = Object.keys(refsByPos).find((pos) => refsByPos[pos]?.current === target);

    if (!isResizing) {
      showTooltip(position);
    }
  };

  const handleMouseLeave = () => {
    onMouseLeave();
    showTooltip(null);
    hideGroupHighlighter();
  };

  const handleStartResizingOfPositioningImages = (direction) => {
    if (isPositionedImage) {
      updateClipPath();
      freezePosition({
        element,
        positioningAreaBounds,
        direction,
      });
    }

    if (isBaseImageForPositioning) {
      lastPositionRef.current = overlayingImages.map((el) => getPositionCustomProperties(el).y);
    }
  };

  const handleResizingOfPositioningImages = () => {
    if (isPositionedImage) {
      updateVisibleResizeTriggers();
      updateClipPath();
    }

    if (isBaseImageForPositioning) {
      overlayingImages.forEach((el, index) =>
        fittingInPositioningArea({
          element: el,
          positioningAreaBounds: el.parentElement.getBoundingClientRect(),
          currentY: lastPositionRef.current[index],
        })
      );
    }
  };

  const handleStopResizingOfPositioningImages = () => {
    if (isPositionedImage) {
      unfreezePosition({
        element,
        positioningAreaBounds,
      });
    }

    if (isBaseImageForPositioning) {
      lastPositionRef.current = null;
    }
  };

  useDrag(Object.values(refsByPos), {
    onStart(event) {
      activeDragHandlerPos.current = Object.keys(refsByPos).find(
        (pos) => refsByPos[pos]?.current === event.target
      );
      currentAspectRatio.current = element.aspectRatio;
      cachedRect.current = element.getBoundingClientRect();
      constraints.current = getConstraints(activeDragHandlerPos.current);

      if (shouldHandlePositioningImages) {
        handleStartResizingOfPositioningImages(activeDragHandlerPos.current);
      }

      setResizingStatus(true);
      showTooltip(null);
      hideHighlighter();
      showHighlighter({ isGroup: isMultipleOrGalleryResize, withElementSize: true });
      cacheRowElements();
      cacheResizableElements();
      onActionStart();
      track(events.IMAGE_RESIZING_INITIATED, {
        ...getDefaultTraits(elementId),
      });
    },

    onMove(distance) {
      if (shouldHandlePositioningImages) {
        handleResizingOfPositioningImages();
      }

      handleDrag(distance);
      showHighlighter({ isGroup: isMultipleOrGalleryResize, withElementSize: true });
    },

    onEnd(distance) {
      if (shouldHandlePositioningImages) {
        handleStopResizingOfPositioningImages();
      }

      setResizingStatus(false);
      handleDrag(distance, true);
      showHighlighter({ isGroup: isMultipleOrGalleryResize });
      clearCacheRowElements();
      clearCacheResizableElements();
      hideAnchors();
      activeDragHandlerPos.current = null;
      currentAspectRatio.current = null;
      onActionEnd();
    },
  });

  const handleKey = ({ shiftKey }) => {
    setShiftStatus(shiftKey);
    if (isResizing) {
      if (shiftKey) {
        hideAnchors();
        setElementsHeightByCurrent();
      } else {
        resetElementsHeight();
      }
    }
    hideHighlighter();
    showHighlighter({ isGroup: shiftKey, withElementSize: isResizing });
  };

  const keyEventCallback =
    supportGroupResize && !singleImageResizingDisabled && !isSliderImage ? handleKey : utils.noop;

  useEventListener('keydown', keyEventCallback, document);
  useEventListener('keyup', keyEventCallback, document);
  useEventListener('keydown', keyEventCallback, element.ownerDocument);
  useEventListener('keyup', keyEventCallback, element.ownerDocument);

  useEffect(() => hideHighlighter, []);
  useEffect(() => {
    if (overlappedMediaContainer) {
      highlighter.operations.show([element]);
    }
  }, [element, overlappedMediaContainer]);

  const setActiveHandlerPosition = useCallback((pos) => {
    activeDragHandlerPos.current = pos;
  }, []);

  return (
    <React.Fragment>
      {isPositionedImage && (
        <Highlighter
          appearance="gradient"
          elementBounds={{
            width: positioningAreaBounds.width,
            height: positioningAreaBounds.height,
            top: positioningAreaBounds.topWithScrollPosition,
            left: positioningAreaBounds.left,
          }}
          clipPath={positioningAreaClipPath}
          visible={isResizing}
        />
      )}
      {Object.keys(handlePosition).map((pos) => {
        const direction = getHandleDirectionByPosition(pos);
        const shape = getHandleShapeByPosition(pos);

        return (
          <ResizeHandle
            key={pos}
            top={handlePosition[pos][1]}
            left={handlePosition[pos][0]}
            shape={shape}
            direction={direction}
            ref={refsByPos[pos]}
            onMouseDown={() => setActiveHandlerPosition(pos)}
            onTouchStart={() => setActiveHandlerPosition(pos)}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        );
      })}
      {tooltipPosition && (
        <ResizeTooltip
          left={handlePosition[tooltipPosition][0]}
          top={handlePosition[tooltipPosition][1]}
          direction={controlPaneLocation.direction}
        >
          {supportGroupResize && !singleImageResizingDisabled && !isSliderImage && (
            <div
              // eslint-disable-next-line yola/react/no-danger
              dangerouslySetInnerHTML={{
                __html: shiftPressed ? captions.shiftPressed : captions.shiftReleased,
              }}
            />
          )}
          {(!supportGroupResize || singleImageResizingDisabled || isSliderImage) &&
            captions.default}
        </ResizeTooltip>
      )}
    </React.Fragment>
  );
};

ImageResizeTool.propTypes = {
  elementId: PropTypes.string.isRequired,
  scrollPosition: PropTypes.number.isRequired,
  onActionStart: PropTypes.func.isRequired,
  onActionEnd: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

ImageResizeTool.defaultProps = {
  onMouseEnter: Function.prototype,
  onMouseLeave: Function.prototype,
};

export default ImageResizeTool;
