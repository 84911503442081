import { blocks, nanoid } from '@yola/ws-sdk';

function generateGroupId(groupId, groupIdsStorage) {
  const ID_LENGTH = 6;
  if (groupId === null) {
    return nanoid(ID_LENGTH);
  }

  if (groupIdsStorage.has(groupId)) {
    return groupIdsStorage.get(groupId);
  }

  const elementsGroupId = nanoid(ID_LENGTH);

  groupIdsStorage.set(groupId, elementsGroupId);

  return elementsGroupId;
}

function assignGroupId({ container, querySelector, groupAttribute }) {
  const isBlock = blocks.verifiers.isElementBlock(container);

  if (!isBlock) {
    return;
  }
  const elements = container.querySelectorAll(querySelector);
  const groupIdsStorage = new Map();

  elements.forEach((element) => {
    const initialGroupId = element.getAttribute(groupAttribute);

    element.setAttribute(groupAttribute, generateGroupId(initialGroupId, groupIdsStorage));
  });
}

export default assignGroupId;
