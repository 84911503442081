import { view, anodum } from '@yola/ws-sdk';

import copyAttributes from './copy-attributes';

function getIframeCode(elementId) {
  const targetNode = view.accessors.getStaticElement(elementId);

  if (anodum.isTag(targetNode, 'ws-iframe')) {
    const iframe = document.createElement('iframe');

    return copyAttributes(iframe, targetNode).outerHTML;
  }

  return targetNode.outerHTML;
}

export default getIframeCode;
