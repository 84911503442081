import React, { useContext, useMemo } from 'react';
import get from 'lodash.get';
import { designSystem } from '@yola/ws-ui';
import optionTypes from '../constants/option-types';
import ElementSettingsContext from '../helpers/element-settings-context';

const { ToggleSection, Divider, Box } = designSystem;

function ElementSettingsAppearance({ item }) {
  const { options } = item;

  const optsWithDivider = useMemo(
    () =>
      options.reduce((res, option, index) => {
        if (index > 0) {
          res.push({ type: 'divider' });
        }

        res.push(option);
        return res;
      }, []),
    [options]
  );

  const { state, updateState } = useContext(ElementSettingsContext);

  return optsWithDivider.map((option) => {
    switch (option.type) {
      case optionTypes.DIVIDER:
        return <Divider />;
      case optionTypes.CUSTOM:
        return option.component;
      case optionTypes.TOGGLE: {
        const onToggleChange = (value) => {
          const batch = option.elementIds.reduce((acc, id) => {
            acc[id] = { [option.attribute]: value };
            return acc;
          }, {});

          updateState(batch);
        };

        let currentValue = get(state, `${option.elementIds[0]}.${option.attribute}`);

        if (option.elementIds.length > 1) {
          currentValue = option.elementIds.every((id) => get(state, `${id}.${option.attribute}`));
        }

        return (
          <Box padding="spacing-s">
            <ToggleSection
              value={currentValue}
              title={option.title}
              content={null}
              onChange={onToggleChange}
            />
          </Box>
        );
      }

      default:
        return null;
    }
  });
}

export default ElementSettingsAppearance;
