import advertisingLimit from './advertising-limit';
import analyticsLimit from './analytics-limit';
import premiumBlocksLimit from './premium-blocks-limit';
import premiumIconsLimit from './premium-icons-limit';
import mobilePlusLimit from './mobile-plus-limit';
import customCodeLimit from './custom-code-limit';
import brandedFooterLimit from './branded-footer-limit';
import pagesLimit from './pages-limit';
import storageLimit from './storage-limit';
import uploadFileSizeLimit from './upload-file-size-limit';
import onlineStoreLimit from './online-store-limit';
import customDomainLimit from './custom-domain-limit';
import nofollowControlsLimit from './nofollow-controls-limit';
import siteBackupsLimit from './site-backups-limit';
import multilingualLimit from './multilingual-limit';
import shopPageTemplateLimit from './shop-page-template-limit';
import externalLinks from './external-links';
import advancedColorCustomizationLimit from './advanced-color-customization-limit';
import passwordProtectedPages from './password-protected-pages-limit';
import aiPageWizardLimit from './ai-page-wizard-limit';
import aiBlockGenerator from './ai-block-generation-limit';
import aiTextAssistantLimit from './ai-text-assistant-limit';
import premiumButtonPresetsLimit from './premium-button-presets-limit';
import premiumTextDecorationsLimit from './premium-text-decorations-limit';

export default {
  advertisingLimit,
  analyticsLimit,
  premiumBlocksLimit,
  premiumIconsLimit,
  mobilePlusLimit,
  customCodeLimit,
  brandedFooterLimit,
  pagesLimit,
  storageLimit,
  uploadFileSizeLimit,
  onlineStoreLimit,
  customDomainLimit,
  nofollowControlsLimit,
  siteBackupsLimit,
  multilingualLimit,
  shopPageTemplateLimit,
  externalLinks,
  advancedColorCustomizationLimit,
  passwordProtectedPages,
  aiPageWizardLimit,
  aiBlockGenerator,
  aiTextAssistantLimit,
  premiumButtonPresetsLimit,
  premiumTextDecorationsLimit,
};
