import { colorToSurfaceMap } from '../constant/colors-maps';

const getAdvancedColorsKeys = (colorKey) => {
  const surface = colorToSurfaceMap[colorKey];

  return {
    wsSiteName: `wsColor${surface}SiteName`,
    wsHeading1: `wsColor${surface}Heading1`,
    wsHeading2: `wsColor${surface}Heading2`,
    wsHeading3: `wsColor${surface}Heading3`,
    wsHeading4: `wsColor${surface}Heading4`,
    wsHeading5: `wsColor${surface}Heading5`,
    wsHeading6: `wsColor${surface}Heading6`,
    wsParagraph: `wsColor${surface}Paragraph`,
    wsBlockquote: `wsColor${surface}Blockquote`,
    wsLinkText: `wsColor${surface}LinkText`,
    wsMarkText: `wsColor${surface}MarkText`,
    wsNavLink: `wsColor${surface}NavLink`,
    wsNavLinkActive: `wsColor${surface}NavLinkActive`,
    wsFeatureIcon: `wsColor${surface}FeatureIcon`,
    wsSocialIcon: `wsColor${surface}SocialIcon`,
    wsContactIcon: `wsColor${surface}ContactIcon`,
    wsPrimaryButtonBackground: `wsColor${surface}PrimaryButton1Background`,
    wsPrimaryButtonText: `wsColor${surface}PrimaryButton1Text`,
    wsSecondaryButtonBackground: `wsColor${surface}SecondaryButton1Background`,
    wsSecondaryButtonText: `wsColor${surface}SecondaryButton1Text`,
    wsBorder: `wsColor${surface}Border`,
    wsInputBorder: `wsColor${surface}InputBorder`,
    wsCheckboxBorder: `wsColor${surface}FormCheckboxBorder`,
    wsFooterNavLinkText: `wsColor${surface}FooterLinkText`,
    wsOverlayBackground: `wsColor${surface}OverlayBackground`,
  };
};

export default getAdvancedColorsKeys;
