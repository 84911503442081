import React from 'react';
import { useDispatch } from 'react-redux';
import { i18next } from '@yola/ws-sdk';
import PropTypes from 'prop-types';
import dialogs from 'yola-editor/src/js/modules/dialogs';
import ResultDialog from '../components/result-dialog';

function PurchaseFailureDialogContainer(props) {
  const { captions, onBackButtonClick, onDialogCancel } = props;

  captions.close = captions.close || i18next.t('Dismiss');

  const dispatch = useDispatch();

  function handleClose() {
    onDialogCancel();
    dispatch(dialogs.actions.hide());
  }

  return (
    <ResultDialog
      captions={captions}
      imgClass="dialog__img--failure"
      onBackButtonClick={onBackButtonClick}
      onClose={handleClose}
    />
  );
}

PurchaseFailureDialogContainer.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    close: PropTypes.string,
  }).isRequired,
  onBackButtonClick: PropTypes.func,
  onDialogCancel: PropTypes.func.isRequired,
};

PurchaseFailureDialogContainer.defaultProps = {
  onBackButtonClick: null,
};

export default PurchaseFailureDialogContainer;
