import history from 'yola-editor/src/js/browser-history';

const allowedRoutes = ['/publish'];

const cleanRoute = () => {
  const { pathname } = window.location;

  if (!allowedRoutes.includes(pathname)) {
    history.replace('/');
  }
};

export default cleanRoute;
