import { transactions } from '@yola/ws-sdk';
import reasons from '../../constants/reasons';
import { SURFACES_ATTRIBUTE, OVERLAY_SECTION_ATTRIBUTE } from '../../constants/attributes';
import getElementById from '../../helpers/get-element-by-id';
import getSurfaceAutomationInstructions from './helpers/get-surface-automation-instructions';
import getOverlayOpacityAutomationInstructions from './helpers/get-overlay-opacity-automation-instructions';

const blockSettingsAreUpdated = (config, payload, transaction) => {
  const { reason } = config;

  if (reason !== reasons.SET_ELEMENT_ATTRIBUTE) return [];

  const { elementId, attr } = payload;
  const { context } = transaction;

  if (!elementId && ![SURFACES_ATTRIBUTE, OVERLAY_SECTION_ATTRIBUTE].includes(attr)) return [];

  const isStatic = context !== transactions.transactionTypes.LIVE_DOCUMENT;
  const targetElement = getElementById(elementId, isStatic);
  const instructions = [];

  switch (attr) {
    case SURFACES_ATTRIBUTE: {
      instructions.push(
        ...getSurfaceAutomationInstructions({
          headerNode: targetElement.previousElementSibling,
          nextSiblingNode: targetElement,
          isStatic,
        })
      );

      break;
    }
    case OVERLAY_SECTION_ATTRIBUTE: {
      instructions.push(
        ...getOverlayOpacityAutomationInstructions({
          headerNode: targetElement,
          nextSiblingNode: targetElement.nextElementSibling,
          isStatic,
        }),
        ...getSurfaceAutomationInstructions({
          headerNode: targetElement,
          nextSiblingNode: targetElement.nextElementSibling,
          isStatic,
        })
      );

      break;
    }
    default:
      break;
  }

  return instructions;
};

export default blockSettingsAreUpdated;
