import { extensions, view } from '@yola/ws-sdk';
import constants from '../../extensions/registry/ws-online-store/constants';

const refreshStore = () => {
  const onlineStoreSettings = extensions.accessors.getExtensionSettings(constants.common.slug);
  const liveDocument = view.accessors.getLiveDocument();
  const onlineStore = liveDocument.querySelector(onlineStoreSettings.querySelector);

  if (onlineStore) {
    onlineStore.refreshStore();
  }
};

export default refreshStore;
