import getPublishedDataLoadingStatus from './published-data-loading-status';
import getPublishedSiteData from './published-site-data';
import getPublishedSiteVhosts from './published-site-vhosts';
import getSiteName from './site-name';
import getSiteURL from './site-url';
import getSiteData from './site-data';
import getSiteLocale from './site-locale';
import getSiteTemplateSlug from './site-template-slug';
import getPublishCount from './publish-count';
import getTemplateSwitched from './template-switched';

export default {
  getPublishedDataLoadingStatus,
  getPublishedSiteData,
  getPublishedSiteVhosts,
  getSiteName,
  getSiteURL,
  getSiteData,
  getSiteLocale,
  getSiteTemplateSlug,
  getPublishCount,
  getTemplateSwitched,
};
