import bowser from 'yola-bowser';
import MODES from '../constants/modes';

const getAdaptionTarget = (mode) => {
  if (bowser.desktop) {
    return null;
  }

  switch (mode) {
    case MODES.desktop: {
      return MODES.desktop;
    }
    case MODES.tablet: {
      if (bowser.mobile) {
        return MODES.tablet;
      }
      return null;
    }
    case MODES.mobile:
    default:
      return null;
  }
};

const adaptFullscreenForMode = (mode, targetWindow, origin) => {
  const adaptFullscreenTarget = getAdaptionTarget(mode);
  targetWindow.postMessage(
    JSON.stringify({
      event: 'modechange',
      attributeValue: adaptFullscreenTarget,
    }),
    origin
  );
};

export default adaptFullscreenForMode;
