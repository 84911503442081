import components from './registered-components';

const registerComponent = (componentId, Component, config = {}) => {
  components.set(componentId, {
    component: Component,
    config,
  });
};

export default registerComponent;
