import { transactions } from '@yola/ws-sdk';
import evaluate from '../handlers/evaluate';
import prefixes from '../constants/prefixes';

async function getAutomationInstructions(action, config = {}) {
  const { type, payload } = action;
  const { prefix = prefixes.AFTER } = config;
  const transaction = transactions.helpers.getTransactionSession();

  if (!transaction.id) return [];

  const instructions = await evaluate({ reason: type, prefix }, payload, transaction);

  return instructions.reduce((acc, instruction) => [...acc, ...instruction], []);
}

export default getAutomationInstructions;
