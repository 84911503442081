import { bowser } from '@yola/ws-sdk';

const Z_KEY = 'z';
const Y_KEY = 'y';

const isRedoEvent = (event) => {
  const { ctrlKey, key, metaKey, shiftKey } = event;
  const isLinux = bowser.linux;
  const isMacOS = bowser.macOS;
  const isWindows = bowser.windows;

  if (isWindows) {
    return key.toLowerCase() === Y_KEY && ctrlKey;
  }

  if (isMacOS) {
    return key.toLowerCase() === Z_KEY && shiftKey && metaKey;
  }

  if (isLinux) {
    return key.toLowerCase() === Z_KEY && shiftKey && ctrlKey;
  }

  return false;
};

export default isRedoEvent;
