import { site } from '@yola/ws-sdk';

function setLiveDocLangAttribute(liveDoc) {
  const activePage = site.accessors.getActivePage();
  const { documentElement } = liveDoc;

  if (!activePage) return;

  const { htmlLocale } = activePage;

  // We need to sync up the current live doc's html `lang` value
  // with the one, that will be applied during the pre-publish hook.
  // And this future value is taken from active page's `htmlLocale` prop.
  // Check this comment also:
  // https://github.com/yola/ws-sdk/blob/master/src/core/site/middleware/page-settings.js#L20-L21
  if (documentElement.lang === htmlLocale || !htmlLocale) return;

  documentElement.lang = htmlLocale;
}

export default setLiveDocLangAttribute;
