import { hdrm } from '@yola/ws-sdk';
import handleOptions from './handle-options';
import restoreOptions from '../layout-switching/restore-options';
import { WHITESPACE_TOP, WHITESPACE_BOTTOM } from '../../constants/display-options';
import { LIVE_PREVIEW_MOCKED_ID } from '../../constants/common';

const compileLivePreviewNode = ({
  optionalChildren,
  sortBlockVariationId,
  selectedLayoutVariationId,
  displayOptions,
  selectedPreviewNode,
}) => {
  const isSelectedDefaultState =
    sortBlockVariationId === LIVE_PREVIEW_MOCKED_ID &&
    selectedLayoutVariationId === LIVE_PREVIEW_MOCKED_ID;

  const destinationBlockNode = selectedPreviewNode;

  if (isSelectedDefaultState) {
    (optionalChildren || []).forEach((item) => {
      hdrm.helpers.updateChildrenPresence(
        destinationBlockNode,
        item.querySelector,
        item.isEnabled,
        item.id
      );
    });
    handleOptions(destinationBlockNode, displayOptions);
  } else {
    restoreOptions(destinationBlockNode, [WHITESPACE_TOP, WHITESPACE_BOTTOM]);
  }
  return destinationBlockNode.cloneNode(true);
};

export default compileLivePreviewNode;
