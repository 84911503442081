import constants from '../constants';
import trackEvent from './track-event';

const { events } = constants;

const trackUpgradeTriggerClicked = (triggerId) => {
  trackEvent(events.UPGRADE_TRIGGER_CLICKED, {
    triggerId,
  });
};

export default trackUpgradeTriggerClicked;
