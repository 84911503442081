import { site, integration, template } from '@yola/ws-sdk';
import store from 'src/js/store';
import utils from 'src/js/modules/common/utils';
import segment from 'src/js/modules/analytics/segment';

const {
  track,
  constants: { events },
} = segment;

const TIME_AHEAD_OF = 60;
const TIME_CHECK_INTERVAL = 5000;
let timer;
let removeOnlineCallback = Function.prototype;
let removeOfflineCallback = Function.prototype;

export const OFFLINE_ERROR = 'Application is offline';

const updateSession = () =>
  new Promise((resolve, reject) => {
    const offlineError = new Error(OFFLINE_ERROR);
    const unregister = utils.network.registerOfflineCallback(() => {
      reject(offlineError);
    });

    store
      .dispatch(site.thunks.updateSession())
      .then(() => {
        unregister();
        resolve();
      })
      .catch((error) => {
        unregister();

        /**
         * XHR request staus code `0` means
         * that request was terminated for some reason.
         * It might be network issues, CORS,
         * page reloading, ect.
         */
        if (error?.request?.status === 0) {
          reject(offlineError);
          return;
        }

        reject(error);
      });
  });

const sessionTracker = {
  startTracking(ttl) {
    const startTime = Date.now();

    function checkSession() {
      const timeToExpire = ttl - TIME_AHEAD_OF;
      const currentTime = Date.now();
      const lifeTime = startTime + timeToExpire * 1000;

      clearTimeout(timer);

      if (timeToExpire <= 0 || lifeTime < currentTime) {
        const siteId = site.accessors.getSiteId();
        const templateBuildSlug = template.accessors.getBuildSlug();

        updateSession()
          .then(() => {
            track(events.SESSION_RENEWAL_COMPLETED, {
              siteId,
              templateBuildSlug,
            });
          })
          .catch((error) => {
            /**
             * In case if session updating request
             * was interupted with network issue (offline, cors, etc)
             * we should not show any errors
             */
            if (error?.message === OFFLINE_ERROR && utils.network.online) {
              timer = setTimeout(checkSession, TIME_CHECK_INTERVAL);

              return;
            }

            /**
             * In case if network status is offline
             * we should not show any errors
             */
            if (!utils.network.online) {
              return;
            }

            const state = store.getState();
            const settings = integration.selectors.getSettings(state);
            // eslint-disable-next-line
            console.error(error);
            settings.onSessionExpired();
          });

        track(events.SESSION_RENEWAL_INITIATED, {
          siteId,
          templateBuildSlug,
        });
      } else {
        timer = setTimeout(checkSession, TIME_CHECK_INTERVAL);
      }
    }

    removeOnlineCallback = utils.network.registerOnlineCallback(checkSession, {
      initialExecution: true,
    });
    removeOfflineCallback = utils.network.registerOfflineCallback(
      () => {
        clearTimeout(timer);
      },
      { initialExecution: true }
    );
  },

  stopTracking() {
    clearTimeout(timer);

    removeOnlineCallback();
    removeOfflineCallback();
  },
};

export default sessionTracker;
