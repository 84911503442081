import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Modal, Panel, PanelGroup, Text } from '@yola/ws-ui';
import { utils } from '@yola/ws-sdk';
import BlockLayoutContainer from '../containers/block-layout-container';
import { DIALOG_PANEL_HEIGHT, DIALOG_WIDTH } from '../constants/block-settings';

const BLOCK_LAYOUT_DIALOG_HEIGHT = 529;

const BlockLayoutDialog = ({
  fullscreen,
  offsetX,
  offsetY,
  onSubmit,
  onCancel,
  captions,
  blockLayoutDescription,
  onDragEnd,
  blockItems,
  onBlockSelected,
  selectedLayoutVariationId,
  itemPreProcessFunction,
  blocksPreviewStylesheet,
  onOverlayClick,
}) => (
  <Modal
    draggables
    resizable={false}
    fullscreen={fullscreen}
    className="ws-block-layout-settings__modal"
    width={DIALOG_WIDTH}
    height={BLOCK_LAYOUT_DIALOG_HEIGHT}
    minWidth={320}
    minHeight={200}
    offsetX={offsetX}
    offsetY={offsetY}
    dragHandleSelector=".ws-drag-trigger"
    handleCancel={onCancel}
    onOverlayClick={onOverlayClick}
    handleSubmit={onSubmit}
    onDragEnd={onDragEnd}
  >
    <PanelGroup height="100%">
      <Panel
        height={DIALOG_PANEL_HEIGHT}
        theme="gray-100"
        corners="squared"
        align="middle"
        className="ws-drag-trigger ws-block-layout-settings__title"
      >
        <Text type="heading-6">{captions.title}</Text>
      </Panel>
      <Panel
        className="ws-block-layout-settings__main"
        height={`calc(100% - 2 * ${DIALOG_PANEL_HEIGHT}px)`}
      >
        <div className="ws-block-layout-settings__wrapper">
          <BlockLayoutContainer
            blockLayoutDescription={blockLayoutDescription}
            blockItems={blockItems}
            onBlockSelected={onBlockSelected}
            selectedLayoutVariationId={selectedLayoutVariationId}
            itemPreProcessFunction={itemPreProcessFunction}
            blocksPreviewStylesheet={blocksPreviewStylesheet}
          />
        </div>
      </Panel>
      <Panel
        height={DIALOG_PANEL_HEIGHT}
        corners="squared"
        className="ws-block-layout-settings__footer"
      >
        <ButtonGroup stick="top" block>
          <Button stretch="block" size="large" onClick={onSubmit}>
            {captions.buttons.submit}
          </Button>
          <Button stretch="block" size="large" onMouseDown={onCancel}>
            {captions.buttons.cancel}
          </Button>
        </ButtonGroup>
      </Panel>
    </PanelGroup>
  </Modal>
);

BlockLayoutDialog.propTypes = {
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  blockItems: PropTypes.array.isRequired,
  onBlockSelected: PropTypes.func.isRequired,
  itemPreProcessFunction: PropTypes.func.isRequired,
  captions: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    buttons: PropTypes.shape({
      submit: PropTypes.string,
      cancel: PropTypes.string,
    }),
  }).isRequired,
  fullscreen: PropTypes.bool.isRequired,
  blockLayoutDescription: PropTypes.func,
  onDragEnd: PropTypes.func.isRequired,
  blocksPreviewStylesheet: PropTypes.string.isRequired,
  selectedLayoutVariationId: PropTypes.string.isRequired,
  onOverlayClick: PropTypes.func.isRequired,
};

BlockLayoutDialog.defaultProps = {
  offsetX: 0,
  offsetY: 0,
  onSubmit: utils.noop,
  onCancel: utils.noop,
  blockLayoutDescription: null,
};

export default BlockLayoutDialog;
