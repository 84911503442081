import { view } from '@yola/ws-sdk';
import { APPEARANCE_TAB_ID } from 'src/js/modules/element-settings/constants';

const getInitialState = (settings) =>
  settings.reduce((acc, setting) => {
    const { elementId, tabs } = setting;
    const appearanceTab = tabs.find((tab) => tab.id.endsWith(APPEARANCE_TAB_ID));
    if (!appearanceTab) return acc;

    appearanceTab.options.forEach((option) => {
      let targetElementIds = [elementId];
      if (option.querySelector) {
        const element = view.accessors.getLiveElement(elementId);
        const targetElements = element.querySelectorAll(option.querySelector);
        if (targetElements.length === 0) {
          targetElementIds = [];
        } else {
          targetElementIds = [...targetElements]
            .map((el) => view.accessors.getLiveElementId(el))
            .filter((el) => Boolean(el));
        }
      }

      targetElementIds.forEach((id) => {
        if (!acc[id]) {
          acc[id] = {};
        }

        if (option.attribute) {
          const hasAttr = view.verifiers.hasElementAttribute(id, option.attribute);
          const value = view.accessors.getElementAttribute(option.attribute);

          if (hasAttr) {
            acc[id][option.attribute] = value !== null ? value : true;
          } else {
            acc[id][option.attribute] = false;
          }
        }
      });
    });

    return acc;
  }, {});

export default getInitialState;
