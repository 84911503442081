import { linkEditing } from '@yola/ws-sdk';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import upsell from 'src/js/modules/upsell';
import errorCodes from 'src/js/modules/extensions/common/constants/error-codes';
import saveFile from './save-file';

const { linkTypes, common } = linkEditing.constants;

const processLinkSubmit = ({ config, isNofollowControlsFeatureEnabled }) =>
  new Promise((resolve, reject) => {
    const { linkType, file, rel } = config;
    const { customPath, ...newConfig } = config;

    if (linkType === linkTypes.DOWNLOAD && file) {
      saveFile({
        file,
        customPath,
        dialogName: dialogTypes.UPLOAD_FILE_DIALOG,
        onFileUpload: (result) => {
          newConfig.reference = result.path;
          newConfig.download = result.name;
          delete newConfig.file;
          delete newConfig.customPath;

          resolve(newConfig);
        },
        onUploadCancel: () => {
          reject(errorCodes.UPLOAD_FILE_CANCELED);
        },
        onSaveFileCancel: () => {
          reject(errorCodes.UPLOAD_FILE_CANCELED);
        },
      });
    } else if (
      linkType === linkTypes.EXTERNAL &&
      isNofollowControlsFeatureEnabled &&
      rel !== common.NOFOLLOW
    ) {
      upsell.operations
        .requestFeatureAvailability(upsell.features.NOFOLLOW_CONTROLS)
        .then(() => {
          resolve(newConfig);
        })
        .catch(() => {
          reject(errorCodes.UPSELL_INTERRUPTION_CANCELLED);
        });
    } else {
      resolve(newConfig);
    }
  });

export default processLinkSubmit;
