import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';
import omit from 'lodash.omit';
import { i18next, utils } from '@yola/ws-sdk';
import { TreeView } from '@yola/ws-ui';
import LinkListSettingsDialog from './link-list-settings-dialog';
import constants from '../constants';

const {
  common: { ENTER_KEYCODE },
} = constants;

const LinkListSettingsDialogContainer = ({
  items: originalItems,
  initialItems,
  offsetX,
  offsetY,
  dialogHeight,
  dialogWidth,
  modalContentClassName,
  onOpenChangeItemDialog,
  onSubmit,
  onClose,
  captions,
  interaction,
  maxNestingLevel,
  handleDeleteItem,
  handleAddNewItem,
  handleEditItem,
  handleOrderChange,
}) => {
  const modalRef = useRef();
  const [items, setItems] = useState(originalItems);

  const deleteItem = useCallback(
    (id) => {
      const { order } = items.find((item) => item.id === id);
      const updatedItems = items.filter((item) =>
        interaction === 'nestable' ? !item.order.startsWith(order) : item.order !== order
      );
      setItems(updatedItems);
      handleDeleteItem();
    },
    [items, interaction, handleDeleteItem]
  );

  const openChangeItemDialog = useCallback(
    (item = null) => {
      const {
        state: { offsetX: x, offsetY: y },
      } = modalRef.current;

      onOpenChangeItemDialog({ items, item, offsetX: x, offsetY: y });
    },
    [onOpenChangeItemDialog, items]
  );

  const createActionsHandlers = useCallback(
    (oldItems) =>
      oldItems.map((item) => {
        const settingsAction = {
          title: i18next.t('Settings'),
          glyph: 'settings',
          onClick: () => {
            handleEditItem();
            openChangeItemDialog(item);
          },
        };

        const deleteAction = {
          title: i18next.t('Delete'),
          glyph: 'trash',
          onClick: () => {
            deleteItem(item.id);
          },
        };

        const actions = [settingsAction, deleteAction];

        return {
          ...item,
          actions,
        };
      }),
    [deleteItem, openChangeItemDialog, handleEditItem]
  );

  const applyChanges = (event) => {
    if (!items.length) {
      onClose();
      return;
    }
    const filteredItems = Object.values(omit(items, ['actions']));
    onSubmit({ items, event, isItemsChanged: !isEqual(filteredItems, initialItems) });
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === ENTER_KEYCODE) {
      applyChanges(e);
    }
  };

  const itemsWithActionHandlers = createActionsHandlers(items);

  return (
    <LinkListSettingsDialog
      modalRef={modalRef}
      items={itemsWithActionHandlers}
      handleKeyDown={handleKeyDown}
      applyChanges={applyChanges}
      onClose={onClose}
      modalContentClassName={modalContentClassName}
      onOrderChange={(updatedItems) => {
        handleOrderChange();
        setItems(updatedItems);
      }}
      interaction={interaction}
      maxNestingLevel={maxNestingLevel}
      dialogWidth={dialogWidth}
      dialogHeight={dialogHeight}
      offsetX={offsetX}
      offsetY={offsetY}
      captions={captions}
      onAddNewItem={() => {
        handleAddNewItem();
        openChangeItemDialog();
      }}
    />
  );
};

LinkListSettingsDialogContainer.propTypes = {
  offsetX: PropTypes.number.isRequired,
  offsetY: PropTypes.number.isRequired,
  modalContentClassName: PropTypes.string,
  items: TreeView.propTypes.items,
  initialItems: TreeView.propTypes.items,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  onOpenChangeItemDialog: PropTypes.func,
  dialogHeight: PropTypes.number,
  dialogWidth: PropTypes.number,
  interaction: PropTypes.string,
  maxNestingLevel: PropTypes.number,
  captions: PropTypes.shape({
    dialogTitle: PropTypes.string,
    emptyTitle: PropTypes.string,
    emptyDescription: PropTypes.string,
    submit: PropTypes.string,
    cancel: PropTypes.string,
  }).isRequired,
  handleDeleteItem: PropTypes.func,
  handleAddNewItem: PropTypes.func,
  handleEditItem: PropTypes.func,
  handleOrderChange: PropTypes.func,
};

LinkListSettingsDialogContainer.defaultProps = {
  modalContentClassName: '',
  items: [],
  initialItems: [],
  onSubmit: utils.noop,
  onOpenChangeItemDialog: utils.noop,
  onClose: utils.noop,
  dialogHeight: 472,
  dialogWidth: 336,
  interaction: 'nestable',
  maxNestingLevel: 3,
  handleDeleteItem: utils.noop,
  handleAddNewItem: utils.noop,
  handleEditItem: utils.noop,
  handleOrderChange: utils.noop,
};

export default LinkListSettingsDialogContainer;
