import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { TermsOfUseDialog as Dialog } from '@yola/webapp-plugins';
import { dialogs } from '@yola/ws-sdk';
import config from 'src/js/modules/config';
import user from 'yola-editor/src/js/modules/user';

const TermsOfUseDialog = ({ onSubmit }) => {
  const dispatch = useDispatch();

  const termsOfUseUrl = useSelector(user.selectors.getTermsOfUseUrl);
  const privacyPolicyUrl = useSelector(user.selectors.getPrivacyPolicyUrl);
  const logoutUrl = useSelector(config.selectors.getLogoutUrl);

  const handleSubmit = () => {
    dispatch(
      user.actions.updateUserData(user.constants.userData.IS_LATEST_USER_AGREEMENT_ACCEPTED, true)
    );

    dialogs.operations.hide();
    onSubmit();
  };

  return (
    <Dialog
      termsOfUseUrl={termsOfUseUrl}
      privacyPolicyUrl={privacyPolicyUrl}
      logoutUrl={logoutUrl}
      onSubmit={handleSubmit}
    />
  );
};

TermsOfUseDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default TermsOfUseDialog;
