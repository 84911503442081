import yousersjs from '@yola/yousersjs';
import errorModule from 'yola-editor/src/js/modules/error';
import site from 'yola-editor/src/js/modules/site';
import i18n from 'yola-editor/src/js/modules/i18n';
import fetchAvailablePackages from 'yola-editor/src/js/modules/features/thunks/fetch-available-packages';
import fetchEcommercePlans from 'yola-editor/src/js/modules/subscriptions/thunks/fetch-ecommerce-plans';
import analytics from 'src/js/modules/analytics';
import actionTypes from '../constants/action-types';
import preferences from '../constants/preferences';
import actions from '../actions';
import selectors from '../selectors';

import { snakeCaseObjectToCamelCase } from '../../../utils/convert-case';

const userPartnerDataLoader = (store) => (next) => (action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_PARTNER_DATA: {
      const state = store.getState();
      const propertiesLocale = i18n.selectors.getLocale(state);
      const sitePropertiesLocale = site.selectors.getSiteLocale(state);

      yousersjs
        .partner()
        .get({
          propertiesLocale,
          sitePropertiesLocale,
        })
        .then(({ body }) => {
          const data = snakeCaseObjectToCamelCase(body);
          store.dispatch(actions.setUserPartnerData(data));
          store.dispatch(fetchAvailablePackages());
          store.dispatch(fetchEcommercePlans());
          next(action);
        })
        .catch((error) => {
          store.dispatch(
            errorModule.actions.setCriticalError({
              error,
              logSentry: true,
            })
          );

          next(action);
        });
      break;
    }

    case actionTypes.IDENTIFY_USER_WITH_PARTNER_DATA: {
      const state = store.getState();
      const userData = selectors.getUserData(state);
      const frontEndTrackingEnabled = analytics.verifiers.isAnalyticsEnabled();

      analytics.segment.identify(
        userData.id,
        {
          frontEndTrackingEnabled,
        },
        () => {
          store.dispatch(
            actions.updateUserPreference(
              preferences.FRONTEND_TRACKING_ENABLED,
              frontEndTrackingEnabled
            )
          );
        }
      );

      next(action);
      break;
    }

    default:
      next(action);
  }
};

export default userPartnerDataLoader;
