export default {
  AI_SURVEY_STATUS: 'ai_survey_status',
  AI_SURVEY_UPDATED_AT: 'ai_survey_updated_at',
  LATEST_AI_SCORE: 'latest_ai_score',
  FRONTEND_TRACKING_ENABLED: 'frontend_tracking_enabled',
  PMF_STATUS_UPDATE_AT: 'pmf_survey_updated_at',
  NPS_STATUS_UPDATE_AT: 'nps_survey_updated_at',
  LATEST_NPS_SCORE: 'latest_nps_score',
  NPS_SURVEY_STATUS: 'nps_survey_status',
  COMPLIANCE_ACCEPTED: 'compliance_accepted',
};
