import React from 'react';
import PropTypes from 'prop-types';
import PlayIcon from './play-icon';

const SiteTour = ({ caption, imageSrc, onClick }) => (
  <div className="ws-site-tour" onClick={onClick}>
    <img className="ws-site-tour__img" src={imageSrc} alt={caption} />
    <span className="ws-site-tour__title">
      <PlayIcon className="ws-site-tour__icon" />
      {caption}
    </span>
  </div>
);

SiteTour.propTypes = {
  caption: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SiteTour;
