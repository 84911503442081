import errorTypes from './error-types';
import recipeIds from './recipe-ids';
import siteProperties from './site-properties';
import sourceIds from './source-ids';
import textAssistantInstructions, {
  textAssistantInstructionSuggestions,
  textAssistantInstructionTypes,
} from './text-assistant-instructions';

export default {
  errorTypes,
  recipeIds,
  siteProperties,
  sourceIds,
  textAssistantInstructions,
  textAssistantInstructionSuggestions,
  textAssistantInstructionTypes,
};
