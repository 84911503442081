import { history, contentEditable, integration, dialogs } from '@yola/ws-sdk';
import store from '../../../store';
import customUi from '../../custom-ui';
import isUndoEvent from './is-undo-event';
import trackUndoTriggerClicked from '../../analytics/segment/trackers/undo-trigger-clicked';

const undoHandler = (event) => {
  const isDialogVisible = dialogs.verifiers.isVisible() || integration.verifiers.isDialogVisible();
  if (isDialogVisible) return;

  const state = store.getState();
  const isCustomUiVisible = customUi.selectors.getVisibilityState(state);

  if (isCustomUiVisible) return;

  if (!isUndoEvent(event)) return;

  const { __detail = {} } = event;

  if (__detail.contentEditable) {
    store.dispatch(contentEditable.actions.undo());
    return;
  }

  const canUndo = !!history.selectors.getNextUndo(state);

  if (canUndo) {
    trackUndoTriggerClicked();
    store.dispatch(history.actions.undo());
  }
};

export default undoHandler;
