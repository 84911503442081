import ai from 'src/js/modules/ai';
import calculateDaysDifference from './calculate-days-difference';
import constants from '../constants';

const {
  SURVEY_STATUS_POSTPONED,
  SURVEY_STATUS_COMPLETED,
  AI_SURVEY_POSTPONED_PERIOD_DAYS,
  AI_RE_SURVEY_PERIOD_DAYS,
} = constants;

const isAiSurveyAvailable = (status, currentDate, updatedAt, siteProperties) => {
  const { numberOfAiGeneratedPages, numberOfAiGeneratedBlocks, numberOfAiTextAssistantRequests } =
    siteProperties;
  const areAiFeaturesUsed = ai.verifiers.areAiFeaturesUsed({
    numberOfAiGeneratedPages,
    numberOfAiGeneratedBlocks,
    numberOfAiTextAssistantRequests,
  });

  if (!areAiFeaturesUsed) {
    return false;
  }

  if (!status || !updatedAt) {
    return true;
  }

  const fromPostponedToCurrent = calculateDaysDifference(updatedAt, currentDate);

  if (
    status === SURVEY_STATUS_POSTPONED &&
    fromPostponedToCurrent >= AI_SURVEY_POSTPONED_PERIOD_DAYS
  ) {
    return true;
  }

  if (status === SURVEY_STATUS_COMPLETED && fromPostponedToCurrent >= AI_RE_SURVEY_PERIOD_DAYS) {
    return true;
  }

  return false;
};

export default isAiSurveyAvailable;
