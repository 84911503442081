import actionTypes from '../constant/action-types';

const changeColor = (colorPalette = null) => ({
  type: actionTypes.CHANGE_COLOR,
  payload: {
    colorPalette,
  },
});

export default changeColor;
