export default {
  GENERAL: 'general',
  BUSINESS_INFO: 'business-info',
  MULTILINGUAL: 'multilingual',
  DOMAIN: 'domain',
  INTEGRATIONS: 'integrations',
  HOSTING: 'hosting',
  BACKUPS: 'backups',
  CUSTOM_CODE: 'custom-code',
};
