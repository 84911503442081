import actionTypes from '../constants/action-types';

const hideHovered = (elements) => ({
  type: actionTypes.HIDE_HOVERED,
  payload: {
    elements,
  },
});

export default hideHovered;
