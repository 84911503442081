import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import constants from '../constants';

const { Box, Heading, Paragraph, Divider } = designSystem;
const { common } = constants;

function SearchPreview({ title, path, description }) {
  return (
    <div className="ws-search-preview">
      <Box padding="spacing-xs" paddingLeft="spacing-m" paddingRightt="spacing-m">
        <Heading type="heading-5" appearance="accent">
          {title?.length > common.MAX_TITLE_TAG_LENGTH
            ? `${title.substring(0, common.MAX_TITLE_TAG_LENGTH)}...`
            : title}
        </Heading>
        <Paragraph size="small" appearance="medium-emphasis" isClipped>
          {path}
        </Paragraph>
        <Box marginTop="spacing-2-xs" marginBottom="spacing-2-xs">
          <Divider />
        </Box>
        <Paragraph>
          {description?.length > common.MAX_DESCRIPTION_LENGTH
            ? `${description.substring(0, common.MAX_DESCRIPTION_LENGTH)}...`
            : description}
        </Paragraph>
      </Box>
    </div>
  );
}

SearchPreview.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default SearchPreview;
