import { dialogs } from '@yola/ws-sdk';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import getDialogProps from './get-dialog-props';

const {
  GALLERY_DIALOG,
  GALLERY_CONFIRMATION_DIALOG,
  GALLERY_FILE_TYPE_INFO_DIALOG,
  GALLERY_FILE_QUANTITY_INFO_DIALOG,
} = dialogTypes;

const getNextDialogType = (isFileTypeError, hasOnlyDefaultImages) => {
  if (isFileTypeError) {
    return GALLERY_FILE_TYPE_INFO_DIALOG;
  }
  if (hasOnlyDefaultImages) {
    return GALLERY_CONFIRMATION_DIALOG;
  }
  return GALLERY_DIALOG;
};

const showFileQuantityInfoDialog = ({
  galleryItems,
  uploadedGalleryItems,
  dismissedFilesNamesList,
  isFileTypeError,
  hasOnlyDefaultImages,
  elementId,
}) => {
  const nextDialogToShow = getNextDialogType(isFileTypeError, hasOnlyDefaultImages);
  const nextDialogProps = getDialogProps({
    currentDialogType: GALLERY_FILE_QUANTITY_INFO_DIALOG,
    targetDialogType: nextDialogToShow,
    galleryItems,
    uploadedGalleryItems,
    dismissedFilesNamesList,
    hasOnlyDefaultImages,
    elementId,
  });
  dialogs.operations.show(GALLERY_FILE_QUANTITY_INFO_DIALOG, nextDialogProps);
};

export default showFileQuantityInfoDialog;
