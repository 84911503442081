import React from 'react';
import { dialogs, i18next, extensions } from '@yola/ws-sdk';
import { Icon } from '@yola/ws-ui';
import Trigger from 'src/js/modules/common/components/trigger';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import constants from '../constants';

const { common } = constants;

const triggerId = 'link';

const control = {
  id: triggerId,
  trigger: (
    <Trigger id={triggerId}>
      <Icon glyph="link" size="16" strokeWidth="1.8" />
    </Trigger>
  ),
  get tooltip() {
    return i18next.t('Edit link');
  },
  priority: 100,
  onTriggerClick: (elementId) => {
    dialogs.operations.show(dialogTypes.EDIT_LINK_DIALOG, { elementId });
  },
  matches(liveElement) {
    const settings = extensions.accessors.getExtensionSettings(common.SLUG);
    return liveElement.matches(settings.querySelector);
  },
};

export default control;
