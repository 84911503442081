import { i18next } from '@yola/ws-sdk';
import serviceDateTimeToIso from './service-date-time-to-iso';

function formatDateUiLabel(dateTime, locales, dateFormatOptions) {
  const dateTimeInt = parseInt(dateTime, 10);
  const dateTimeIso = serviceDateTimeToIso(dateTimeInt);
  const date = new Date(dateTimeIso);
  date.setHours(0, 0, 0, 0);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  yesterday.setHours(0, 0, 0, 0);

  if (date.getTime() === today.getTime()) {
    return i18next.t('today');
  }

  if (date.getTime() === yesterday.getTime()) {
    return i18next.t('yesterday');
  }

  return date.toLocaleDateString(locales, dateFormatOptions);
}

export default formatDateUiLabel;
