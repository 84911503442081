const adaptSizeForViewport = (elementContainer, size) => {
  const calculatedHeight = elementContainer.offsetHeight;

  const { width, height } = size;
  const scale = elementContainer.offsetWidth / width;

  if (scale < 1) {
    return {
      width,
      height: Math.round(calculatedHeight / scale),
    };
  }

  if (calculatedHeight < height) {
    return {
      width,
      height: calculatedHeight,
    };
  }

  return size;
};

export default adaptSizeForViewport;
