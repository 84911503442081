import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';

const { Box, Stack, Heading, Paragraph, StepLine, Step, ActionButton } = designSystem;

const WizardSetupStepsPanel = (props) => {
  const { captions, steps, isDesktopView, onStepButtonClick } = props;

  return (
    <div className="ws-wizard-setup-steps-panel">
      <Box
        {...(isDesktopView
          ? {
              padding: 'spacing-xl',
              paddingTop: 'spacing-3-xl',
              paddingRight: 'spacing-3-xl',
            }
          : {
              padding: 'spacing-xs',
              paddingTop: 'spacing-3-xs',
              paddingBottom: 'spacing-m',
            })}
      >
        <Stack gap="spacing-m">
          <Stack gap="spacing-3-xs">
            <Heading type="heading-3">{captions.title}</Heading>
            <Paragraph>{captions.description}</Paragraph>
          </Stack>
          <StepLine orientation={isDesktopView ? 'vertical' : 'horizontal'} gap="spacing-2-xs">
            {steps.map(({ id, marker, active, passed, completed, disabled }) => (
              <Step
                key={id}
                marker={marker}
                active={active}
                passed={passed}
                completed={completed}
                {...(!isDesktopView && {
                  label: captions.steps[id],
                  disabled,
                  onClick: () => onStepButtonClick(id),
                })}
              >
                {isDesktopView && (
                  <div>
                    {!disabled ? (
                      <ActionButton
                        format="text"
                        label={captions.steps[id]}
                        onClick={() => onStepButtonClick(id)}
                      />
                    ) : (
                      <Heading type="heading-6">{captions.steps[id]}</Heading>
                    )}
                  </div>
                )}
              </Step>
            ))}
          </StepLine>
        </Stack>
      </Box>
    </div>
  );
};

WizardSetupStepsPanel.propTypes = {
  captions: PropTypes.shape().isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      marker: PropTypes.number.isRequired,
      active: PropTypes.bool.isRequired,
      passed: PropTypes.bool.isRequired,
      completed: PropTypes.bool.isRequired,
    })
  ).isRequired,
  isDesktopView: PropTypes.bool.isRequired,
  onStepButtonClick: PropTypes.func.isRequired,
};

export default WizardSetupStepsPanel;
