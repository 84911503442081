import actionTypes from '../constants/action-types';

const identifyUserWithPartnerData = (partnerData = {}) => ({
  type: actionTypes.IDENTIFY_USER_WITH_PARTNER_DATA,
  payload: {
    partnerData,
  },
});

export default identifyUserWithPartnerData;
