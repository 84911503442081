import React from 'react';
import PropTypes from 'prop-types';
import bowser from 'yola-bowser';
import { i18next } from '@yola/ws-sdk';
import { DragLayer, ElementDragLayer } from '@yola/ws-ui';
import dragTypes from '../constants/drag-types';
import shouldRenderLabeledDragTrigger from '../helpers/should-render-labeled-drag-trigger';

const DRAG_TRIGGER_LEFT_SHIFT = 8;
const DRAG_TRIGGER_TOP_SHIFT = 6;

const collect = (monitor) => ({
  itemType: monitor.getItemType(),
  initialOffset: monitor.getInitialSourceClientOffset(),
  clientOffset: monitor.getClientOffset(),
  draggingItem: monitor.getItem(),
});

function getItemStyles(clientOffset, initialOffset) {
  const { x, y } = clientOffset;
  const shift = {
    x: DRAG_TRIGGER_LEFT_SHIFT,
    y: DRAG_TRIGGER_TOP_SHIFT,
  };

  const transform = `translate(${x - initialOffset.x - shift.x}px, ${
    y - initialOffset.y - shift.y
  }px)`;

  return {
    top: initialOffset.y,
    left: initialOffset.x,
    transform,
  };
}

function handleMouseDown(e) {
  const dragTrigger = document.querySelector('.ws-drag-trigger-wrapper');

  if (bowser.safari && dragTrigger && dragTrigger.contains(e.target)) {
    document.body.classList.add('ws-user-select-none');
  }
}

function handleMouseUp() {
  if (bowser.safari && document.body.classList.contains('ws-user-select-none')) {
    document.body.classList.remove('ws-user-select-none');
  }
}

class ElementDragLayerContainer extends React.Component {
  componentDidMount() {
    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mouseup', handleMouseUp);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', handleMouseDown);
    document.removeEventListener('mouseup', handleMouseUp);
  }

  render() {
    const { draggingItem, clientOffset, initialOffset, itemType } = this.props;

    if (!draggingItem || !clientOffset || itemType !== dragTypes.VIEW_ELEMENT) return null;

    const isDragTriggerLabeled = shouldRenderLabeledDragTrigger(draggingItem.elementId);
    const dragTriggerLabeledProps = {
      glyph: 'drag',
      glyphSize: 24,
      glyphStrokeWidth: 1.3,
      className: 'ws-element-drag-layer--drag-trigger-labeled',
    };

    return (
      <ElementDragLayer
        text={i18next.t('Move Element')}
        style={{ ...getItemStyles(clientOffset, initialOffset) }}
        {...(isDragTriggerLabeled && dragTriggerLabeledProps)}
      />
    );
  }
}

ElementDragLayerContainer.propTypes = {
  draggingItem: PropTypes.shape({
    elementId: PropTypes.string,
  }),
  initialOffset: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }),
  clientOffset: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }),
  itemType: PropTypes.string,
};

ElementDragLayerContainer.defaultProps = {
  draggingItem: null,
  initialOffset: null,
  clientOffset: null,
  itemType: null,
};

export default DragLayer(collect)(ElementDragLayerContainer);
