import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  ImageCrop,
  Modal,
  PanelGroup,
  Panel,
  PanelLeft,
  PanelRight,
  Icon,
  Button,
  ButtonGroup,
} from '@yola/ws-ui';
import { utils } from '@yola/ws-sdk';
import { ViewportConsumer } from 'yola-editor/src/js/utils/viewport-provider';

class ImageCropDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      crop: props.initialCrop,
      pixelCrop: {},
      isSmall: false,
    };

    this.onChange = this.onChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setWidthSize = this.setWidthSize.bind(this);
  }

  componentDidMount() {
    this.mediaQueryList = window.matchMedia('(max-width: 1024px)');
    this.mediaQueryList.addListener(this.setWidthSize);
    this.setWidthSize(this.mediaQueryList);
  }

  componentWillUnmount() {
    this.mediaQueryList.removeListener(this.setWidthSize);
  }

  onChange(crop, pixelCrop) {
    this.setState({ crop, pixelCrop });
  }

  setWidthSize({ matches }) {
    this.setState({
      isSmall: matches,
    });
  }

  handleSubmit() {
    const { onSubmit } = this.props;
    const { pixelCrop } = this.state;

    onSubmit(pixelCrop);
  }

  render() {
    const {
      onCancel,
      initialCrop,
      keepAspectRatio,
      width,
      height,
      src,
      onKeepAspectRatioClick,
      captions: { title, submit, cancel, tooltip },
    } = this.props;

    const { crop, isSmall } = this.state;
    const innerCrop = {
      width: crop.width,
      x: crop.x,
      y: crop.y,
    };
    if (keepAspectRatio) {
      innerCrop.aspect = initialCrop.aspect;
    } else {
      innerCrop.height = crop.height;
    }

    return (
      <Modal
        overlay="visible"
        centered
        width={width}
        height={height}
        fullscreen={isSmall}
        resizable={false}
        draggable={false}
        handleCancel={onCancel}
        handleSubmit={this.handleSubmit}
      >
        <PanelGroup height="100%">
          <Panel
            className="ws-image-crop-dialog__header"
            theme="gray-100"
            height="48"
            align="middle"
          >
            <PanelLeft className="ws-image-crop-dialog__title">{title}</PanelLeft>
            <PanelRight>
              <Icon
                data-tip={tooltip}
                data-place="left"
                glyph="aspect-ratio"
                className={classnames('ws-image-crop-dialog__icon', {
                  'ws-image-crop-dialog__icon--active': keepAspectRatio,
                })}
                onClick={onKeepAspectRatioClick}
              />
            </PanelRight>
          </Panel>
          <div className="ws-image-crop-dialog__main">
            <ViewportConsumer>
              {(viewportSizes) => (
                <ImageCrop
                  src={src}
                  crop={innerCrop}
                  onChange={this.onChange}
                  viewportHeight={viewportSizes.height}
                  viewportWidth={viewportSizes.width}
                  orientation={viewportSizes.orientation}
                />
              )}
            </ViewportConsumer>
          </div>
          <ButtonGroup className="ws-image-crop-dialog__buttons">
            <Button stretch="block" size="large" onClick={this.handleSubmit}>
              {submit}
            </Button>
            <Button stretch="block" size="large" onClick={onCancel}>
              {cancel}
            </Button>
          </ButtonGroup>
        </PanelGroup>
      </Modal>
    );
  }
}

ImageCropDialog.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  src: PropTypes.string.isRequired,
  initialCrop: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
    aspect: PropTypes.number,
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  keepAspectRatio: PropTypes.bool,
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    submit: PropTypes.string.isRequired,
    cancel: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
  onKeepAspectRatioClick: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

ImageCropDialog.defaultProps = {
  width: 1440,
  height: 800,
  initialCrop: {},
  keepAspectRatio: true,
  onKeepAspectRatioClick: utils.noop,
  onSubmit: utils.noop,
  onCancel: utils.noop,
};

export default ImageCropDialog;
