import { i18next, template, view } from '@yola/ws-sdk';
import onlineStore from 'src/js/modules/onlinestore';
import trackPromiseState from '../track-promise-state';
import getOptionTargetElement from './get-option-target-element';

function loadCategories() {
  const allCategoriesOption = {
    label: i18next.t('All categories'),
    value: '',
  };

  return trackPromiseState(() =>
    onlineStore.helpers.fetchCategories().then((data) => {
      const categoryOptions = data
        .map(({ id, name }) => ({ value: String(id), label: name }))
        .sort((category1, category2) => category1.label.localeCompare(category2.label));

      return [allCategoriesOption, ...categoryOptions];
    })
  );
}

const createOnlineStoreCategoryOption = ({ item, displayOptions, blockNode, onChange }) => {
  const targetElement = getOptionTargetElement(item, blockNode);
  if (!targetElement) return null;

  const targetElementId = view.accessors.getLiveElementId(targetElement);
  const existedOption = displayOptions && displayOptions.find((option) => option.id === item.id);
  const liveInitialValue = targetElement.getAttribute(item.attribute) || '';
  const initialValue = existedOption ? existedOption.initialValue : liveInitialValue;
  const options = existedOption ? existedOption.options : loadCategories();

  return {
    ...item,
    options,
    initialValue,
    onChange,
    elementId: targetElementId,
    value: liveInitialValue,
    title: template.accessors.getMetaTranslations(item.title),
    subtitle: template.accessors.getMetaTranslations(item.subtitle),
  };
};

export default createOnlineStoreCategoryOption;
