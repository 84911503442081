import { PROTOCOL_ICON_MAP } from '../constants/icon-autocomplete-maps';

const supportedProtocols = ['sms', 'Callto', 'mms'];

const validateProtocol = (url) => {
  const socialProtocols = Object.keys(PROTOCOL_ICON_MAP);
  const protocols = [...supportedProtocols, ...socialProtocols];
  const regExpBase = protocols.map((protocol) => protocol.replace(':', '')).join('|');
  const reg = new RegExp(`((${regExpBase}):)[-a-zA-Z0-9@:%._\\/?+~#=]{1,256}`, 'g');
  return reg.test(url);
};

export default validateProtocol;
