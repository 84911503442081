import { textFormatting, site } from '@yola/ws-sdk';
import wrapInCustomColor from './wrap-in-custom-color';

const setColorOption = (name, value, elementId, blockId, surfaceAttribute) => {
  let customStylesheet = textFormatting.helpers.getCustomTextColorsStylesheet();

  if (!customStylesheet) {
    customStylesheet = textFormatting.helpers.createCustomTextColorStylesheet();
  }

  let cssTextRule = textFormatting.helpers.getCssTextRule({
    customStylesheet,
    blockId,
    surfaceAttribute,
    name,
  });

  if (!cssTextRule) {
    cssTextRule = textFormatting.helpers.createCssTextRule({
      name,
      propertyValue: value,
      customStylesheet,
      surfaceAttribute,
      blockId,
    });
  }
  const { cssVariableName, textVariableName } = cssTextRule;

  textFormatting.helpers.updateCustomColorStylesheetInGlobal();

  wrapInCustomColor(`var(${textVariableName})`, elementId);
  site.operations.addUsedCustomColor(textVariableName, site.accessors.getActivePageId());

  return {
    rootVariableName: cssVariableName,
    textVariableName,
    value,
  };
};

export default setColorOption;
