import store from 'src/js/store';
import bowser from 'yola-bowser';
import { view } from '@yola/ws-sdk';
import { BOTTOM, TOP, CENTER, DESKTOP_BOTTOM_OFFSET, IOS_BOTTOM_OFFSET } from '../constants/common';

const getScrollOffset = (element, elementSideToScroll, includeOffset = true) => {
  const state = store.getState();
  const scrollPosition = view.selectors.getScrollPosition(state);
  const liveDocument = view.accessors.getLiveDocument();
  const windowHeight = liveDocument.defaultView.innerHeight;
  const elementSizes = element.getBoundingClientRect();

  let { top: elementTop, bottom: elementBottom } = elementSizes;
  const { height: blockHeight } = elementSizes;
  let offset = DESKTOP_BOTTOM_OFFSET;

  if (bowser.ios) {
    elementTop = elementSizes.top - scrollPosition;
    elementBottom = elementSizes.bottom - scrollPosition;
    offset = IOS_BOTTOM_OFFSET;
  }

  switch (elementSideToScroll) {
    case TOP: {
      return scrollPosition + elementTop;
    }
    case BOTTOM: {
      const result = scrollPosition + elementBottom - windowHeight;

      return includeOffset ? result + offset : result;
    }
    case CENTER: {
      return scrollPosition + elementTop - (windowHeight - blockHeight) / 2;
    }
    default: {
      return scrollPosition;
    }
  }
};

export default getScrollOffset;
