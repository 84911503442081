import React from 'react';
import PropTypes from 'prop-types';
import { designSystem, Field, Render, Text } from '@yola/ws-ui';
import CodeEditorField from 'src/js/modules/common/components/code-editor-field';
import segment from 'src/js/modules/analytics/segment';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import constants from '../constants';

const { Stack, Paragraph, CtaBanner, Box } = designSystem;
const { codeEditorPropTypes, CODE_EDITOR_HEIGHT, settingTypes } = constants;
const {
  trackers: { trackCodeEditorFocused },
} = segment;

function CustomCodeSection({
  captions,
  headCodeProps,
  headerCodeProps,
  footerCodeProps,
  animatePopoverId,
  headCodeUpgradePopoverRef,
  headerCodeUpgradePopoverRef,
  footerCodeUpgradePopoverRef,
  onHeadCodeUpgradeClick,
  onHeaderCodeUpgradeClick,
  onFooterCodeUpgradeClick,
}) {
  const onCodeEditorFocus = () => {
    trackCodeEditorFocused(dialogTypes.WEBSITE_SETTINGS);
  };

  return (
    <div>
      <Stack gap="spacing-xs">
        <Paragraph appearance="medium-emphasis" size="small">
          {captions.description}
        </Paragraph>

        <div>
          <Text type="heading-6">{captions.headCode.name}</Text>
          <Box marginTop="spacing-3-xs" marginBottom="spacing-3-xs">
            <div onClick={() => headCodeProps.readOnly && headCodeProps.onClick()}>
              <Field
                component={CodeEditorField}
                name={settingTypes.HEAD_CODE}
                height={headCodeProps.readOnly ? CODE_EDITOR_HEIGHT.min : CODE_EDITOR_HEIGHT.max}
                disabled={headCodeProps.readOnly}
                onFocus={onCodeEditorFocus}
                options={{
                  showLineNumbers: true,
                  tabSize: 2,
                  setAutoScrollEditorIntoView: true,
                  readOnly: headCodeProps.readOnly,
                }}
                {...headCodeProps}
                errorElementIsStatic
              />
            </div>
          </Box>
          <Render if={headCodeProps.readOnly}>
            <Box marginBottom="spacing-3-xs">
              <div ref={headCodeUpgradePopoverRef}>
                <CtaBanner
                  title={captions.headCode.upgradeText.description}
                  iconGlyph="upgrade"
                  animate={animatePopoverId === captions.headCode.name}
                  id={captions.headCode.name}
                  ctaButton={{
                    label: captions.headCode.upgradeText.upgrade,
                    onClick: onHeadCodeUpgradeClick,
                  }}
                />
              </div>
            </Box>
          </Render>
          <Text type="annotation">
            {/* eslint-disable-next-line yola/react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: captions.headCode.description }} />
          </Text>
        </div>

        <div>
          <Text type="heading-6">{captions.headerCode.name}</Text>
          <Box marginTop="spacing-3-xs" marginBottom="spacing-3-xs">
            <div onClick={() => headerCodeProps.readOnly && headerCodeProps.onClick()}>
              <Field
                component={CodeEditorField}
                name={settingTypes.HEADER_CODE}
                height={headerCodeProps.readOnly ? CODE_EDITOR_HEIGHT.min : CODE_EDITOR_HEIGHT.max}
                disabled={headerCodeProps.readOnly}
                onFocus={onCodeEditorFocus}
                options={{
                  showLineNumbers: true,
                  tabSize: 2,
                  autoScrollEditorIntoView: true,
                  readOnly: headerCodeProps.readOnly,
                }}
                {...headerCodeProps}
                errorElementIsStatic
              />
            </div>
          </Box>
          <Render if={headerCodeProps.readOnly}>
            <Box marginBottom="spacing-3-xs">
              <div ref={headerCodeUpgradePopoverRef}>
                <CtaBanner
                  title={captions.headerCode.upgradeText.description}
                  iconGlyph="upgrade"
                  animate={animatePopoverId === captions.headerCode.name}
                  id={captions.headerCode.name}
                  ctaButton={{
                    label: captions.headerCode.upgradeText.upgrade,
                    onClick: onHeaderCodeUpgradeClick,
                  }}
                />
              </div>
            </Box>
          </Render>
          <Text type="annotation">
            {/* eslint-disable yola/react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: captions.headerCode.description }} />
            {/* eslint-enable yola/react/no-danger */}
          </Text>
        </div>

        <div>
          <Text type="heading-6">{captions.footerCode.name}</Text>
          <Box marginTop="spacing-3-xs" marginBottom="spacing-3-xs">
            <div onClick={() => footerCodeProps.readOnly && footerCodeProps.onClick()}>
              <Field
                component={CodeEditorField}
                name={settingTypes.FOOTER_CODE}
                height={footerCodeProps.readOnly ? CODE_EDITOR_HEIGHT.min : CODE_EDITOR_HEIGHT.max}
                disabled={footerCodeProps.readOnly}
                onFocus={onCodeEditorFocus}
                options={{
                  showLineNumbers: true,
                  tabSize: 2,
                  autoScrollEditorIntoView: true,
                  readOnly: footerCodeProps.readOnly,
                }}
                {...footerCodeProps}
                errorElementIsStatic
              />
            </div>
          </Box>
          <Render if={footerCodeProps.readOnly}>
            <Box marginBottom="spacing-3-xs">
              <div ref={footerCodeUpgradePopoverRef}>
                <CtaBanner
                  title={captions.footerCode.upgradeText.description}
                  iconGlyph="upgrade"
                  animate={animatePopoverId === captions.footerCode.name}
                  id={captions.footerCode.name}
                  ctaButton={{
                    label: captions.footerCode.upgradeText.upgrade,
                    onClick: onFooterCodeUpgradeClick,
                  }}
                />
              </div>
            </Box>
          </Render>
          <Text type="annotation">
            {/* eslint-disable yola/react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: captions.footerCode.description }} />
            {/* eslint-enable yola/react/no-danger */}
          </Text>
        </div>
      </Stack>
    </div>
  );
}

CustomCodeSection.propTypes = {
  captions: PropTypes.shape().isRequired,
  headCodeProps: PropTypes.shape(codeEditorPropTypes).isRequired,
  headerCodeProps: PropTypes.shape(codeEditorPropTypes).isRequired,
  footerCodeProps: PropTypes.shape(codeEditorPropTypes).isRequired,
  headCodeUpgradePopoverRef: PropTypes.shape().isRequired,
  headerCodeUpgradePopoverRef: PropTypes.shape().isRequired,
  footerCodeUpgradePopoverRef: PropTypes.shape().isRequired,
  onHeadCodeUpgradeClick: PropTypes.func,
  onHeaderCodeUpgradeClick: PropTypes.func,
  onFooterCodeUpgradeClick: PropTypes.func,
  animatePopoverId: PropTypes.string,
};

CustomCodeSection.defaultProps = {
  onHeadCodeUpgradeClick: PropTypes.func,
  onHeaderCodeUpgradeClick: PropTypes.func,
  onFooterCodeUpgradeClick: PropTypes.func,
  animatePopoverId: null,
};

export default CustomCodeSection;
