import get from 'lodash.get';
import { config } from '@yola/ws-sdk';

const getPrivacyPolicyUrl = (rootState) => {
  const cfg = config.selectors.getConfig(rootState);

  return get(cfg, 'yola-editor.private_policy_page_url', null);
};

export default getPrivacyPolicyUrl;
