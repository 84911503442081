import { view, hdrm } from '@yola/ws-sdk';

const { updateStrategies } = hdrm.constants;

const convertInstructionsToActions = (blockInstructions, initialStrategy) =>
  blockInstructions
    .map((blockInstruction) => {
      const { instructions, node, static: isStatic = false } = blockInstruction;
      const elementId = isStatic
        ? hdrm.accessors.getReferenceIdByStaticElement(node)
        : view.accessors.getLiveElementId(node);
      let options = {
        skipDesignAutomation: true,
        ...(initialStrategy && { strategy: initialStrategy }),
      };

      return instructions.reduce((acc, item) => {
        if ('property' in item && 'value' in item) {
          const { property, value } = item;
          if (value !== null) {
            acc.push(view.actions.setElementAttribute(elementId, property, value, options));
            return acc;
          }

          acc.push(view.actions.removeElementAttribute(elementId, property, options));
          return acc;
        }

        if ('childrenSelector' in item && 'enabled' in item && 'blockSettingId' in item) {
          const { childrenSelector, enabled, blockSettingId } = item;
          const childrenPresenceUpdate = { childrenSelector, enabled, blockSettingId };

          if (options.strategy !== updateStrategies.UPDATE_LIVE_ONLY) {
            options = {
              ...options,
              strategy: updateStrategies.UPDATE_STATIC_ONLY,
            };
          }

          acc.push(view.actions.setChildrenPresence(elementId, childrenPresenceUpdate, options));
          return acc;
        }

        if ('enabled' in item) {
          const { enabled } = item;
          if (!enabled) {
            acc.push(view.actions.deleteElement(elementId, options));
            return acc;
          }
        }

        return acc;
      }, []);
    })
    .reduce((acc, sub) => [...acc, ...sub], []);

export default convertInstructionsToActions;
