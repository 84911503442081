import React, { Fragment, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { view } from '@yola/ws-sdk';
import { designSystem } from '@yola/ws-ui';
import highlighter from 'src/js/modules/highlighter';
import ResizeHandle from '../../../common/components/resize-handle';
import useDragAndPositioning from '../../../common/hooks/use-drag-and-positioning';
import useHandlePosition from '../../../common/hooks/use-handle-position';
import resizeToolPosition from '../../../common/constants/resize-handle-positions';
import getPositionOperations from '../helpers/get-position-operations';

const { Highlighter } = designSystem;

const ImagePositioningTool = (props) => {
  const { elementId, appearanceStyle, scrollPosition, onActionStart, onActionEnd } = props;
  const element = view.accessors.getLiveElement(elementId);

  const handleRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);

  const [handlePosition, updateHandlePosition] = useHandlePosition({
    element,
    positions: {
      [resizeToolPosition.CENTER_CENTER]: {
        correctionX: 0,
        correctionY: 0,
      },
    },
  });

  const showHighlighter = () => {
    highlighter.operations.show([element], {
      forceUpdate: true,
      labelGap: 'medium',
    });
  };

  const hideHighlighter = () => {
    highlighter.operations.hide([element]);
  };

  const handleStart = () => {
    setIsDragging(true);
    onActionStart();
    hideHighlighter();
    showHighlighter();
  };

  const handleMove = () => {
    updateHandlePosition();
    hideHighlighter();
    showHighlighter();
  };

  const handleEnd = () => {
    setIsDragging(false);
    onActionEnd();
    view.operations.bulkViewOperations(getPositionOperations([element]));
  };

  const { positioningAreaBounds, positioningAreaClipPath } = useDragAndPositioning({
    handleRef,
    elementId,
    scrollPosition,
    onStart: handleStart,
    onMove: handleMove,
    onEnd: handleEnd,
  });

  if (!handlePosition[resizeToolPosition.CENTER_CENTER]) {
    return null;
  }

  return (
    <Fragment>
      <Highlighter
        appearance="gradient"
        color="warning"
        elementBounds={{
          width: positioningAreaBounds.width,
          height: positioningAreaBounds.height,
          top: positioningAreaBounds.topWithScrollPosition,
          left: positioningAreaBounds.left,
        }}
        clipPath={positioningAreaClipPath}
        visible={isDragging}
      />
      <ResizeHandle
        ref={handleRef}
        direction="all"
        top={handlePosition[resizeToolPosition.CENTER_CENTER][1]}
        left={handlePosition[resizeToolPosition.CENTER_CENTER][0]}
        style={appearanceStyle}
      />
    </Fragment>
  );
};

ImagePositioningTool.propTypes = {
  elementId: PropTypes.string.isRequired,
  scrollPosition: PropTypes.number.isRequired,
  appearanceStyle: PropTypes.shape().isRequired,
  onActionStart: PropTypes.func.isRequired,
  onActionEnd: PropTypes.func.isRequired,
};

export default ImagePositioningTool;
