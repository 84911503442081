import actionTypes from 'src/js/modules/highlighter/constants/action-types';

const anchors = (state = [], action) => {
  switch (action.type) {
    case actionTypes.HIDE_ANCHOR_HIGHLIGHTERS: {
      return [];
    }
    case actionTypes.SHOW_ANCHOR_HIGHLIGHTERS: {
      const { elements } = action.payload;

      return [...elements];
    }
    default: {
      return state;
    }
  }
};

export default anchors;
