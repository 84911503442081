import React from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash.get';
import { integration } from '@yola/ws-sdk';
import { designSystem } from '@yola/ws-ui';
import segment from 'src/js/modules/analytics/segment';
import user from '../../../../../yola-editor/src/js/modules/user';

const {
  constants: { triggerIds },
} = segment;

const { EditorHeader } = designSystem;

function BuyDomainContainer() {
  const settings = useSelector(integration.selectors.getSettings);
  const userData = useSelector(integration.selectors.getUserData);
  const withPartnersDomainUpsell = useSelector(user.verifiers.hasPartnersDomainUpsell);
  const captions = get(settings, 'upsells.customDomain.topBarButton.captions', {});
  const isWL = userData.is_white_label;
  const { onDomainSelector, onWLDomainSelector } = settings;

  if ((isWL && !withPartnersDomainUpsell) || !Object.keys(captions).length) {
    return null;
  }

  return (
    <EditorHeader.BuyDomainButton
      onClick={() => {
        if (withPartnersDomainUpsell) {
          onWLDomainSelector({ triggerId: triggerIds.EDITOR_HEADER });
        } else {
          onDomainSelector({ triggerId: triggerIds.EDITOR_HEADER });
        }
      }}
      label={captions.upgrade}
    />
  );
}

export default BuyDomainContainer;
