const sortSurfaces = (surfaces) => {
  let temp = [];
  const indexes = {};
  const order = ['ws-light', 'ws-dark', 'ws-primary', 'ws-secondary'];

  surfaces.forEach((surface) => {
    const value = surface.value.trim();
    const isSingleSurface = !value.includes(' ');

    if (isSingleSurface) {
      if (indexes[value] === undefined) {
        temp.push([surface]);
        indexes[value] = temp.length - 1;
      } else {
        temp[indexes[value]].unshift(surface);
      }
    } else {
      const firstSurface = value.split(' ')[0];
      if (indexes[firstSurface] === undefined) {
        temp.push([surface]);
        indexes[firstSurface] = temp.length - 1;
      } else {
        temp[indexes[firstSurface]].push(surface);
      }
    }
  });

  temp = temp.sort((a, b) => {
    const aSurface = a[0].value;
    const bSurface = b[0].value;

    const aIndex = order.findIndex((surface) => aSurface.includes(surface));
    const bIndex = order.findIndex((surface) => bSurface.includes(surface));

    if (aIndex === bIndex) {
      return parseInt(aSurface.slice(-1), 10) - parseInt(bSurface.slice(-1), 10);
    }

    return aIndex - bIndex;
  });

  return temp.reduce((acc, arr) => acc.concat(arr), []);
};

export default sortSurfaces;
