export const DRAG_TRIGGER_ID = 'block-drag';
export const DUPLICATE_TRIGGER_ID = 'block-duplicate';
export const DELETE_TRIGGER_ID = 'block-delete';
export const ELEMENT_SETTINGS_TRIGGER_ID = 'element-settings';
export const WEBSITE_SETTINGS_TRIGGER_ID = 'website-settings';
export const BLOCK_SETTINGS_TRIGGER_ID = 'block-settings';
export const ALIGN_LEFT_TRIGGER_ID = 'element-align-left';
export const ALIGN_RIGHT_TRIGGER_ID = 'element-align-right';
export const ALIGN_CENTER_TRIGGER_ID = 'element-align-center';
export const ONLINE_STORE_SETTINGS_TRIGGER_ID = 'online-store-settings';
export const MOVE_TRIGGER_ID = 'block-move';
export const ALIGN_TRIGGER_ID = 'element-align';
export const MORE_ID = 'more';
export const BLOCK_CONTROL_TRIGGER_ID = 'block-control-pane';
export const HIDE_TRIGGER_ID = 'element-hide';
export const HTML_WIDGET_TRIGGER_ID = 'html-widget';
export const FONT_SIZE_TRIGGER_ID = 'text-font-size';
export const TEXT_ELEMENT_TYPE_TRIGGER_ID = 'text-element-type';
export const AI_WRITE_TEXT_TRIGGER_ID = 'write-with-ai';
export const AI_REWRITE_TEXT_TRIGGER_ID = 'ask-ai';
