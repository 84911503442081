const paymentMethods = {
  VISA: 'visa',
  MASTER_CARD: 'master-card',
  AMERICAN_EXPRESS: 'american-express',
  PAYPAL: 'paypal',
  STRIPE: 'stripe',
  BOLETO: 'boleto',
};

export default paymentMethods;
