import { view, blocks } from '@yola/ws-sdk';
import isCustomBlock from '../verifiers/is-custom-block';
import isElementWidget from './is-element-widget';
import getFloatingTriggersInfoForElement from './get-floating-triggers-info-for-element';

const getWidgetTriggersInfo = ({
  elementId,
  mousePosition,
  scrollPosition,
  pageContainerSelector,
  viewportHeight,
}) => {
  const element = view.accessors.getLiveElement(elementId);

  if (!element) {
    return null;
  }

  if (isElementWidget(element)) {
    const triggersInfo = getFloatingTriggersInfoForElement({
      elementId,
      scrollPosition,
      pageContainerSelector,
      viewportHeight,
    });

    return { ...triggersInfo, elementId };
  }

  if (!isCustomBlock(element)) {
    return null;
  }

  const bounds = element.getBoundingClientRect();
  const { top: elementTop, bottom: elementBottom } = bounds;
  const whitespaceTopValue = element.getWhiteSpaceTopValue();
  const whitespaceBottomValue = element.getWhiteSpaceBottomValue();
  const { y: mouseY } = mousePosition;

  // top whitespace is hovered
  if (mouseY > elementTop && mouseY < elementTop + whitespaceTopValue) {
    const targetElement = element.querySelector(
      `[id^=${blocks.constants.WIDGET_ID_PREFIX}]:first-child`
    );

    if (!targetElement) {
      return null;
    }

    const targetElementId = view.accessors.getLiveElementId(targetElement);
    const triggersInfo = getFloatingTriggersInfoForElement({
      elementId: targetElementId,
      scrollPosition,
      pageContainerSelector,
      viewportHeight,
    });

    if (!triggersInfo) {
      return null;
    }

    return {
      elementId: targetElementId,
      topTrigger: triggersInfo.topTrigger,
    };
  }

  // bottom whitespace is hovered
  if (mouseY > elementBottom - whitespaceBottomValue && mouseY < elementBottom) {
    const targetElement = element.querySelector(
      `[id^=${blocks.constants.WIDGET_ID_PREFIX}]:last-child`
    );

    if (!targetElement) {
      return null;
    }

    const targetElementId = view.accessors.getLiveElementId(targetElement);
    const triggersInfo = getFloatingTriggersInfoForElement({
      elementId: targetElementId,
      scrollPosition,
      pageContainerSelector,
      viewportHeight,
    });

    if (!triggersInfo) {
      return null;
    }

    return {
      elementId: targetElementId,
      bottomTrigger: triggersInfo.bottomTrigger,
    };
  }

  return null;
};

export default getWidgetTriggersInfo;
