import { dialogs, i18next } from '@yola/ws-sdk';
import { designSystem } from '@yola/ws-ui';
import bowser from 'yola-bowser';
import React from 'react';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import config from 'src/js/modules/config';

const { Paragraph, Icon, Heading } = designSystem;
const showCopyPasteHotkeysDialog = () => {
  const guideUrl = config.accessors.getEnableCopyPasteInstructionUrl();
  const description = i18next.t(
    'To copy and paste from clipboard by using context menus, please install the latest <a href="https://www.google.com/chrome/" target="_blank">Google Chrome</a> browser. Alternatively, you can always use your keyboard shortcuts. <a href="{learnMoreUrl}" target="_blank">Learn more</a>',
    { learnMoreUrl: guideUrl }
  );
  const isMac = bowser.macOS;

  const renderHotkey = (character) => {
    if (isMac) {
      return (
        <>
          <Icon glyph="command" size="24" />
          <Heading type="heading-2">{character}</Heading>
        </>
      );
    }

    return <Heading type="heading-2">Ctrl+{character}</Heading>;
  };

  dialogs.operations.show(dialogTypes.ALERT_DIALOG, {
    width: 386,
    height: 290,
    captions: {
      title: i18next.t('Enable clipboard copy and paste'),
      dismiss: i18next.t('Got it'),
    },
    content: (
      <>
        <Paragraph align="center" appearance="medium-emphasis">
          <span
            // eslint-disable-next-line yola/react/no-danger
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </Paragraph>
        <div className="ws-clipboard-copy-hotkeys">
          <div className="ws-clipboard-copy-hotkey-wrapper">
            <div className="ws-clipboard-copy-hotkey">{renderHotkey('C')}</div>
            <Paragraph appearance="medium-emphasis">{i18next.t('for copy')}</Paragraph>
          </div>
          <div className="ws-clipboard-copy-hotkey-wrapper">
            <div className="ws-clipboard-copy-hotkey">{renderHotkey('V')}</div>
            <Paragraph appearance="medium-emphasis">{i18next.t('for paste')}</Paragraph>
          </div>
        </div>
      </>
    ),
    onDismiss() {
      dialogs.operations.hide();
    },
  });
};

export default showCopyPasteHotkeysDialog;
