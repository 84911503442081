import common from './common';
import attributes from './attributes';
import attributesMap from './attributes-map';
import optionTypes from './option-types';

export default {
  common,
  attributes,
  attributesMap,
  optionTypes,
};
