import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { designSystem } from '@yola/ws-ui';

const { TextArea } = designSystem;

function TextAreaField(props) {
  const {
    field,
    form: { errors, touched, setFieldValue, setFieldError, setFieldTouched },
    onChange,
    onBlur,
    value,
    elementAfter,
    statusText: statusTextProp,
    ...otherProps
  } = props;

  const { name } = field;
  const touchedField = get(touched, name);
  const errorField = get(errors, name);
  const hasError = !!(errorField && touchedField);
  const isElementAfter = Boolean(elementAfter);
  const statusText = !isElementAfter && hasError ? errorField : statusTextProp;

  const onFieldChange = useCallback(
    (fieldValue) => {
      setFieldError(name, '');
      setFieldValue(name, fieldValue, false);
      onChange(fieldValue);
    },
    [onChange, setFieldError, setFieldValue, name]
  );

  const onFieldBlur = useCallback(
    (e) => {
      setFieldTouched(name);
      onBlur(e);
    },
    [name, setFieldTouched, onBlur]
  );

  return (
    <React.Fragment>
      <TextArea
        {...otherProps}
        {...field}
        invalid={hasError}
        statusText={statusText}
        onChange={onFieldChange}
        onBlur={onFieldBlur}
        value={value || field.value}
      />
      {isElementAfter && elementAfter}
    </React.Fragment>
  );
}

TextAreaField.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  elementAfter: PropTypes.node,
  statusText: PropTypes.string,
  // Following props are set by Formik. Added only to pass snitch check.
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
    setFieldError: PropTypes.func,
    setFieldTouched: PropTypes.func,
    touched: PropTypes.shape({}),
    errors: PropTypes.shape({}),
  }).isRequired,
};

TextAreaField.defaultProps = {
  elementAfter: null,
  value: undefined,
  statusText: '',
  onBlur: Function.prototype,
  onChange: Function.prototype,
};

export default TextAreaField;
