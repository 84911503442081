import { blocks, textFormatting, view, anodum } from '@yola/ws-sdk';
import generateBlockIdCssRule from 'src/js/modules/website-design/helpers/generate-block-id-css-rule';
import getChildrenToUpdate from './layout-switching/get-children-to-update';
import replaceOptionalChildren from './layout-switching/replace-optional-children';
import replaceBlockIdSelectorForRules from './replace-block-id-selector-for-rules';
import getBlockIdRulesMap from './get-block-id-rules-map';
import { SURFACES_ATTRIBUTE } from '../constants/display-options';
import { REGULAR_IMAGE_ATTRIBUTES_FOR_SHALLOW_PROXY } from '../constants/common';

const regenerateBlockByHTML = (elementId, newHTML, { layout, content }) => {
  if (layout && content) {
    return anodum.parseElementFromString(newHTML);
  }

  const originalBlockNode = view.accessors.getStaticElement(elementId);

  if (layout) {
    const newNode = anodum.parseElementFromString(newHTML);
    const originalVariationId = blocks.accessors.getVariationIdByElement(originalBlockNode);
    const variationId = blocks.accessors.getVariationIdByElement(newNode);
    const isOriginalVariationId = variationId === originalVariationId;
    const toUpdate = getChildrenToUpdate(originalBlockNode, newNode);

    const attributesForShallowProxy = !isOriginalVariationId
      ? REGULAR_IMAGE_ATTRIBUTES_FOR_SHALLOW_PROXY
      : [];

    replaceOptionalChildren(toUpdate, originalBlockNode, newNode, attributesForShallowProxy);

    const usedTextColorOptions =
      textFormatting.helpers.getElementCustomColorVariables(originalBlockNode);
    const stylesheet = textFormatting.helpers.getCustomTextColorsStylesheet();
    const blockIdRulesMap = getBlockIdRulesMap(stylesheet, originalBlockNode.id);

    const usedCustomColorOptions =
      Boolean(usedTextColorOptions.length) && blockIdRulesMap && blockIdRulesMap.size > 0;

    if (usedCustomColorOptions) {
      const originalBlockId = originalBlockNode.id;
      const blockOriginSurface = originalBlockNode.getAttribute(SURFACES_ATTRIBUTE);

      if (blockOriginSurface === newNode.getAttribute(SURFACES_ATTRIBUTE)) {
        replaceBlockIdSelectorForRules(stylesheet, originalBlockId, newNode.id);
        generateBlockIdCssRule({
          stylesheet,
          blockId: newNode.id,
          surfaces: newNode.getAttribute(SURFACES_ATTRIBUTE),
          blockIdRulesMap: getBlockIdRulesMap(stylesheet, newNode.id),
        });
      }
    }

    return newNode;
  }

  if (content) {
    const newNode = anodum.parseElementFromString(newHTML);
    const originalVariationId = blocks.accessors.getVariationIdByElement(originalBlockNode);
    const variationId = blocks.accessors.getVariationIdByElement(newNode);
    const isOriginalVariationId = variationId === originalVariationId;
    const toUpdate = getChildrenToUpdate(newNode, originalBlockNode);

    const attributesForShallowProxy = !isOriginalVariationId
      ? REGULAR_IMAGE_ATTRIBUTES_FOR_SHALLOW_PROXY
      : [];

    replaceOptionalChildren(toUpdate, newNode, originalBlockNode, attributesForShallowProxy);

    return originalBlockNode;
  }

  return null;
};

export default regenerateBlockByHTML;
