const ERROR_CODES = {
  FORBIDDEN_BY_MODERATION: 'forbidden_by_moderation',
  LAYOUT_NOT_FOUND: 'layout_not_found',
};

export default {
  UNKNOWN: 'unknown',
  UNKNOWN_REPLACE_PAGE: 'unknown-replace-page',
  UNKNOWN_GENERATE_PAGE: 'unknown-generate-page',
  UNKNOWN_GENERATE_TEXT: 'unknown-generate-text',
  ...ERROR_CODES,
};
