import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { designSystem } from '@yola/ws-ui';

const { FieldStatus, TagInput, Box } = designSystem;

function TagInputField(props) {
  const {
    field,
    form: { errors, touched, setFieldError, setFieldTouched },
    onBlur,
    onChange,
    tags,
    ...otherProps
  } = props;

  const { name } = field;
  const touchedField = get(touched, name);
  const errorField = get(errors, name);
  const hasError = !!(errorField && touchedField);

  const onFieldChange = useCallback(
    (newTags) => {
      setFieldError(name, '');
      onChange(newTags);
    },
    [onChange, setFieldError, name]
  );

  const onFieldBlur = useCallback(
    (e) => {
      setFieldTouched(name);
      onBlur(e);
    },
    [name, setFieldTouched, onBlur]
  );

  return (
    <Box>
      <TagInput
        {...otherProps}
        {...field}
        invalid={hasError}
        onChange={onFieldChange}
        onBlur={onFieldBlur}
        tags={tags}
      />

      {hasError && <FieldStatus appearance="error" text={errorField || ''} />}
    </Box>
  );
}

TagInputField.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  tags: PropTypes.array.isRequired,
  // Following props are set by Formik. Added only to pass snitch check.
  field: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  form: PropTypes.shape({
    setFieldError: PropTypes.func,
    setFieldTouched: PropTypes.func,
    touched: PropTypes.shape({}),
    errors: PropTypes.shape({}),
  }).isRequired,
};

TagInputField.defaultProps = {
  onBlur: Function.prototype,
};

export default TagInputField;
