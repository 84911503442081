import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import CreateAnimation from '../helpers/create-animation';

function ConfettiAnimation({ width, height, options }) {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const instance = new CreateAnimation(canvas, context, options);
    const handleResize = () => instance.resize();

    instance.start();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [options]);

  return <canvas className="ws-confetti-animation" ref={canvasRef} width={width} height={height} />;
}

ConfettiAnimation.defaultProps = {
  width: 1,
  height: 1,
  options: {
    speed: 65,
    ribbonsCount: 8,
    ribbonsLength: 30,
    ribbonsThick: 8,
    papersCount: 80,
    colors: [
      ['#df0049', '#660671'],
      ['#00e857', '#005291'],
      ['#2bebbc', '#05798a'],
      ['#ffd200', '#b06c00'],
    ],
  },
};

ConfettiAnimation.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  options: PropTypes.shape({
    speed: PropTypes.number,
    ribbonsCount: PropTypes.number,
    ribbonsLength: PropTypes.number,
    ribbonsThick: PropTypes.number,
    papersCount: PropTypes.number,
    colors: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  }),
};

export default ConfettiAnimation;
