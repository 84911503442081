/* eslint-disable yola/import/no-extraneous-dependencies */
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { connector } from '@yola/ws-sdk';
import reducer from 'src/js/reducer';
import middleware from 'src/js/middleware';
import yolaEditorMiddleware from 'yola-editor/src/js/middleware';
import connectedStore from 'yola-editor/src/js/store/connected-store';
import history from 'yola-editor/src/js/browser-history';

const store = createStore(
  reducer(history),
  composeWithDevTools(connector, applyMiddleware(...middleware.concat(yolaEditorMiddleware)))
);

connectedStore.set(store);

export default store;
