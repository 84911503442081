import constants from '../constants';
import trackEvent from './track-event';

const { events } = constants;

const trackDialogActiveTabChanged = ({ dialogId, tabId }) => {
  trackEvent(events.DIALOG_ACTIVE_TAB_CHANGED, {
    dialogId,
    tabId,
  });
};

export default trackDialogActiveTabChanged;
