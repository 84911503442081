import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';

const { RadioButtonGroup, RadioButton } = designSystem;

function RadioButtonGroupField(props) {
  const {
    field: { value, name, onChange },
    form: { setFieldValue },
    radioButtons,
  } = props;

  const handleChange = useCallback(
    (fieldValue) => {
      setFieldValue(name, fieldValue, false);
      onChange(fieldValue);
    },
    [onChange, setFieldValue, name]
  );

  return (
    <RadioButtonGroup name={name} onChange={handleChange} selectedValue={value}>
      {radioButtons.map(({ id, value: buttonValue, label, isLabelBold }) => (
        <RadioButton
          id={id}
          name={name}
          value={buttonValue}
          label={label}
          isLabelBold={isLabelBold}
        />
      ))}
    </RadioButtonGroup>
  );
}

RadioButtonGroupField.propTypes = {
  radioButtons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
      label: PropTypes.string,
      isLabelBold: PropTypes.bool,
    })
  ).isRequired,
  // Following props are set by Formik. Added only to pass snitch check.
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
  }).isRequired,
};

export default RadioButtonGroupField;
