import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { hdrm } from '@yola/ws-sdk';
import { Modal, designSystem } from '@yola/ws-ui';
import FeatureItem from './feature-item';

const { Box, Stack, IconButton, ActionButton, Heading, Tag, HorizontalStack } = designSystem;

const { DISABLE_BLUR_ATTR } = hdrm.constants.attributes;
const attributes = { [DISABLE_BLUR_ATTR]: true };

const UpgradeInterruptionDialog = ({ captions, imageSrc, isDesktopView, onClose, onSubmit }) => {
  const classes = classNames('ws-upgrade-interruption-dialog', {
    'ws-upgrade-interruption-dialog--desktop-view': isDesktopView,
  });

  const DynamicStack = isDesktopView ? HorizontalStack : Stack;
  const entitlements = captions.entitlements || [];

  const firstHalfOfEntitlements = entitlements.slice(0, Math.ceil(entitlements.length / 2));
  const secondHalfOfEntitlements = entitlements.slice(Math.ceil(entitlements.length / 2));

  return (
    <Modal
      attributes={attributes}
      className={classes}
      width={500}
      height={692}
      centered
      fullscreen={!isDesktopView}
      draggable={false}
      resizable={false}
      overlay="visible"
    >
      <div className="ws-upgrade-interruption-dialog__close-button">
        <IconButton
          glyph="close"
          iconPlacement="after"
          size="medium"
          onClick={onClose}
          transparent
          selected
        />
      </div>
      <img
        className="ws-upgrade-interruption-dialog__image"
        src={imageSrc}
        alt={captions.description}
      />
      <Box
        padding="spacing-m"
        breakpoints={{
          sm: {
            padding: 'spacing-xl',
          },
        }}
      >
        <div className="ws-upgrade-interruption-dialog__content">
          <Box
            marginBottom="spacing-3-xs"
            breakpoints={{
              sm: {
                marginBottom: 'spacing-2-xs',
              },
            }}
          >
            <Tag size="medium" appearance="cta-secondary" label={captions.title} />
          </Box>
          <Box
            marginBottom="spacing-m"
            breakpoints={{
              sm: {
                marginBottom: 'spacing-s',
              },
            }}
          >
            <Heading type="heading-3">{captions.description}</Heading>
          </Box>
          <DynamicStack gap="spacing-2-xs">
            <Stack gap="spacing-2-xs">
              {firstHalfOfEntitlements.map((item) => (
                <FeatureItem text={item} key={item} />
              ))}
            </Stack>
            <Stack gap="spacing-2-xs">
              {secondHalfOfEntitlements.map((item) => (
                <FeatureItem text={item} key={item} />
              ))}
            </Stack>
          </DynamicStack>
          <div className="ws-upgrade-interruption-dialog__submit-button">
            <Box
              marginTop="spacing-l"
              breakpoints={{
                sm: {
                  marginTop: 'spacing-m',
                },
              }}
            >
              <ActionButton
                appearance="cta"
                format="solid"
                size="extra-large"
                fullWidth={!isDesktopView}
                label={captions.upgrade}
                onClick={onSubmit}
              />
            </Box>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

UpgradeInterruptionDialog.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    entitlements: PropTypes.arrayOf(PropTypes.string.isRequired),
    upgrade: PropTypes.string.isRequired,
  }).isRequired,
  imageSrc: PropTypes.string.isRequired,
  isDesktopView: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default UpgradeInterruptionDialog;
