import { globals, integration, site, anodum, template } from '@yola/ws-sdk';
import isConfiguredProperly from '../helpers/is-configured-properly';
import createBrandingComponent from '../helpers/create-branding-component';

const brandedHeader = (store) => (next) => (action) => {
  if (action.type === site.actionTypes.SET_ACTIVE_PAGE) {
    const state = store.getState();
    const headerGlobalId = template.selectors.getVariableValueByPath(
      state,
      'headerCode[0].globalId'
    );
    const global = globals.selectors.getGlobal(state, headerGlobalId);
    const userData = integration.selectors.getUserData(state);
    const isWLUser = userData.is_white_label;

    if (global && global.html) {
      const globalHeaderElement = anodum.parseElementFromString(global.html);
      const isBrandedHeaderRemovable = integration.selectors.getBrandedHeaderLimit(state).removable;
      const brandedHeaderElement = globalHeaderElement.querySelector('ws-branded-header');
      const siteHeader = integration.selectors.getSiteHeader(state);

      // `siteHeader` is an optional partner property so not all partners has it configured
      // but integration settings has default value with Yola data, we don't wont that to appear for
      // those partners who hasn't configure it's own site header
      const isSiteHeaderConfigured = isWLUser
        ? Boolean(siteHeader) && !integration.verifiers.isDefaultSiteHeader(siteHeader)
        : Boolean(siteHeader);

      if (!siteHeader || !isSiteHeaderConfigured || isBrandedHeaderRemovable) {
        if (brandedHeaderElement) {
          brandedHeaderElement.remove();

          store.dispatch(
            globals.actions.setGlobalHTML(headerGlobalId, globalHeaderElement.outerHTML)
          );
        }
      } else if (!brandedHeaderElement || !isConfiguredProperly(brandedHeaderElement)) {
        const newBrandingHeader = createBrandingComponent();

        if (brandedHeaderElement) {
          globalHeaderElement.replaceChild(newBrandingHeader, brandedHeaderElement);
        } else {
          globalHeaderElement.append(newBrandingHeader);
        }

        store.dispatch(
          globals.actions.setGlobalHTML(headerGlobalId, globalHeaderElement.outerHTML)
        );
      }
    }
  }

  next(action);
};

export default brandedHeader;
