import { blocks, view } from '@yola/ws-sdk';
import getParentBlockByElement from '../../../blocks/helpers/get-parent-block-by-element';

const getWidgetNumericPosition = (refId) => {
  const element = view.accessors.getLiveElement(refId);
  const parentElement = view.accessors.getParentLiveElement(refId);
  const parentBlock = getParentBlockByElement(parentElement);
  const sameBlockWidgets = [
    ...parentBlock.querySelectorAll(`[id^=${blocks.constants.WIDGET_ID_PREFIX}]`),
  ].filter((widgetElement) => getParentBlockByElement(widgetElement.parentElement) === parentBlock);

  const numericPosition = sameBlockWidgets.indexOf(element) + 1;

  return numericPosition;
};

export default getWidgetNumericPosition;
