import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import TabContentBox from './tab-content-box';

const { Stack, ControlGroup, Paragraph, ActionButton } = designSystem;

function TemplateSwitchingSection(props) {
  const { templatePreviewUrl, templateName, captions, onSwitchTemplate } = props;
  const { title, description } = captions;

  return (
    <div className="ws-template-switching-section">
      <TabContentBox>
        <Stack gap="spacing-xs">
          <div className="ws-template-switching-section__preview" translate="no">
            <div className="ws-template-switching-section__image">
              <img src={templatePreviewUrl} alt="" />
            </div>
            <Paragraph appearance="medium-emphasis" size="small">
              {templateName}
            </Paragraph>
          </div>
          <ControlGroup title={title} description={description}>
            <ActionButton size="large" label={captions.title} onClick={onSwitchTemplate} />
          </ControlGroup>
        </Stack>
      </TabContentBox>
    </div>
  );
}

TemplateSwitchingSection.propTypes = {
  templatePreviewUrl: PropTypes.string,
  templateName: PropTypes.string,
  onSwitchTemplate: PropTypes.func.isRequired,
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};

TemplateSwitchingSection.defaultProps = {
  templatePreviewUrl: null,
  templateName: null,
};

export default TemplateSwitchingSection;
