import { view } from '@yola/ws-sdk';

const restoreRange = (selection, elementId) => {
  const liveDocument = view.accessors.getLiveDocument();

  if (elementId) {
    const liveElement = view.accessors.getLiveElement(elementId);
    liveElement.setAttribute('contenteditable', true);
    liveElement.focus();
  }

  const { anchorNode, anchorOffset, focusOffset, focusNode } = selection;
  const range = liveDocument.createRange();

  if (selection.isBackwards()) {
    range.setStart(focusNode, focusOffset);
    range.setEnd(anchorNode, anchorOffset);
  } else {
    range.setStart(anchorNode, anchorOffset);
    range.setEnd(focusNode, focusOffset);
  }

  selection.removeAllRanges();
  selection.addRange(range);
  selection.refresh();
};

export default restoreRange;
