import { extensions } from '@yola/ws-sdk';
import footerNavigationExtension from '../registry/ws-footer-navigation';
import socialLinksExtension from '../registry/ws-social-links';
import onlineStoreExtensions from '../registry/ws-online-store';
import navigationExtension from '../registry/ws-navigation';
import imageExtension from '../registry/ws-image';
import htmlWidgetExtensions from '../registry/ws-html-widget';
import galleryExtensions from '../registry/ws-gallery';
import linkExtension from '../registry/ws-link';
import iframeExtension from '../registry/ws-iframe';
import contactFormExtension from '../registry/ws-contact-form';
import textFormattingExtension from '../registry/ws-text-formatting';
import googleMapEmbedExtension from '../registry/ws-google-map-embed';
import iconExtension from '../registry/ws-icon';
import sliderExtension from '../registry/ws-slider';
import contactInfo from '../registry/ws-contact-info';

const addExtensionsToRegistry = () => {
  extensions.operations.addToRegistry(footerNavigationExtension);
  extensions.operations.addToRegistry(socialLinksExtension);
  extensions.operations.addToRegistry(onlineStoreExtensions);
  extensions.operations.addToRegistry(navigationExtension);
  extensions.operations.addToRegistry(imageExtension);
  extensions.operations.addToRegistry(htmlWidgetExtensions);
  extensions.operations.addToRegistry(galleryExtensions);
  extensions.operations.addToRegistry(linkExtension);
  extensions.operations.addToRegistry(iframeExtension);
  extensions.operations.addToRegistry(contactFormExtension);
  extensions.operations.addToRegistry(textFormattingExtension);
  extensions.operations.addToRegistry(googleMapEmbedExtension);
  extensions.operations.addToRegistry(iconExtension);
  extensions.operations.addToRegistry(sliderExtension);
  extensions.operations.addToRegistry(contactInfo);
};

export default addExtensionsToRegistry;
