import { view, anodum } from '@yola/ws-sdk';
import constants from '../constants';

const { SUPPORTED_TAGS } = constants.listFormatting;

const getChildElement = (el) => {
  const liveDocument = view.accessors.getLiveDocument();
  let child = null;

  if (el.children.length === 1 && anodum.isOneOfTags(el.children[0], SUPPORTED_TAGS)) {
    // eslint-disable-next-line prefer-destructuring
    child = el.children[0];
  } else {
    const childWrapper = liveDocument.createElement('p');
    childWrapper.append(...el.childNodes);
    child = childWrapper;
  }

  return child;
};

export default getChildElement;
