import React from 'react';
import { i18next, view } from '@yola/ws-sdk';
import { Icon } from '@yola/ws-ui';
import Trigger from 'src/js/modules/common/components/trigger';
import { MOVE_TRIGGER_ID } from '../constants/trigger-ids';
import adjacentPositions from '../../blocks/constants/adjacent-positions';
import scrollToElement from '../../scroller/helpers/scroll-to-element';
import dropHandler from '../../drag-n-drop/helpers/drop-handler';

const { BEFORE_BEGIN, AFTER_END } = adjacentPositions;

const getDirections = () => [
  {
    direction: 'up',
    title: i18next.t('Move up'),
  },
  {
    direction: 'down',
    title: i18next.t('Move down'),
  },
];

const getMoveBlockTriggers = ({ element, showHighlighter, hideHighlighter }) => {
  const handleClick = (dropTargetElement, adjacentPosition) => {
    const dragSourceElementId = view.accessors.getLiveElementId(element);
    const dropTargetElementId = view.accessors.getLiveElementId(dropTargetElement);

    dropHandler({ dragSourceElementId, dropTargetElementId, adjacentPosition });
    view.operations.setHoveredElement(null);
    scrollToElement(element).then(() => {
      view.operations.setHoveredElement(dragSourceElementId);
    });
  };

  return getDirections().map(({ direction, title }) => {
    const id = `${MOVE_TRIGGER_ID}-${direction}`;
    const dropTargetElement =
      direction === 'up' ? element.previousElementSibling : element.nextElementSibling;
    const adjacentPosition = direction === 'up' ? BEFORE_BEGIN : AFTER_END;

    return {
      id,
      element,
      trigger: (
        <Trigger id={id} disabled={!dropTargetElement}>
          <Icon size="24" glyph={`arrow-${direction}`} strokeWidth="1.3" />
        </Trigger>
      ),
      tooltip: title,
      priority: 20,
      title,
      onTriggerClick: dropTargetElement
        ? () => handleClick(dropTargetElement, adjacentPosition)
        : Function.prototype,
      onHover: showHighlighter,
      onMouseLeave: hideHighlighter,
    };
  });
};

export default getMoveBlockTriggers;
