import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { i18next } from '@yola/ws-sdk';
import dialogs from 'yola-editor/src/js/modules/dialogs';
import dialogTypes from 'yola-editor/src/js/modules/dialogs/constants/dialog-types';
import segment from 'src/js/modules/analytics/segment';
import auth from 'yola-editor/src/js/modules/auth';
import site from 'yola-editor/src/js/modules/site';
import actions from 'yola-editor/src/js/modules/user/actions';
import preferences from 'yola-editor/src/js/modules/user/constants/preferences';
import getUserData from 'yola-editor/src/js/modules/user/selectors/user-data';
import getUserPreferences from 'yola-editor/src/js/modules/user/selectors/user-preferences';
import GenericDialog from 'yola-editor/src/js/modules/dialogs/components/generic-dialog';
import useFeatureFlags from 'yola-editor/src/js/modules/feature-flags/hooks/use-feature-flags';
import helpers from '../../helpers';
import constant from '../../constants';
import NpsScores from '../components/nps-scores';
import NpsFeedback from '../components/nps-feedback';
import showThankYouDialog from '../helpers/show-thank-you-dialog';
import useWindowWidth from '../../../../common/hooks/use-window-width';

const {
  SURVEY_STATUS_FINISHED,
  SURVEY_STATUS_POSTPONED,
  SURVEY_STATUS_COMPLETED,
  STAGE_DESKTOP,
  STAGE_MOBILE_1,
  STAGE_MOBILE_2,
} = constant;

const { track, constants, identify } = segment;
const MOBILE_BREAKPOINT = 480;

function getNpsFeedbackCaptions(score) {
  const captions = {
    placeholder: i18next.t('Add you response here...'),
    label: i18next.t('Too bad! We want to fix that. What should we improve?'),
  };

  if (score > 6 && score < 9) {
    captions.label = i18next.t('What could we do to improve your experience?');
  }

  if (score >= 9) {
    captions.label = i18next.t('What do you like the most about our product and services?');
  }

  return captions;
}

function SurveyDialogContainer({ onDialogMainAction, onDialogCancel }) {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= MOBILE_BREAKPOINT;
  const [selectedScore, setSelectedScore] = useState();
  const [feedback, setFeedback] = useState(null);
  const [stage, setStage] = useState(isMobile ? STAGE_MOBILE_1 : STAGE_DESKTOP);
  const dispatch = useDispatch();
  const userData = useSelector(getUserData);
  const siteId = useSelector(auth.selectors.getSiteId);
  const templateBuildSlug = useSelector(site.selectors.getSiteTemplateSlug);
  const [featureFlags] = useFeatureFlags(['pmf_survey']);
  const { pmfSurveyUpdatedAt, pmfSurveyCompleted, latestNpsScore, npsSurveyStatus } =
    useSelector(getUserPreferences);
  const currentDate = new Date();

  const captions = {
    title: i18next.t('How likely are you to recommend Yola to a friend or colleague?'),
    secondaryAction: i18next.t('Remind me later'),
    mainAction: stage === STAGE_MOBILE_1 ? i18next.t('Continue') : i18next.t('Submit'),
  };

  const trackDisplayedEvent = () => {
    track(constants.events.NPS_SURVEY_DISPLAYED, {
      siteId,
      templateBuildSlug,
    });
  };

  const trackSubmittedEvent = () => {
    track(constants.events.NPS_SURVEY_COMPLETED, {
      npsScore: selectedScore,
      npsFeedback: feedback,
      siteId,
      templateBuildSlug,
    });

    identify(userData.id, {
      latestNpsScore: selectedScore,
    });
  };

  useEffect(trackDisplayedEvent, []);

  const updatePreferences = () => {
    if (latestNpsScore && npsSurveyStatus === SURVEY_STATUS_COMPLETED) {
      dispatch(actions.updateUserPreference(preferences.NPS_SURVEY_STATUS, SURVEY_STATUS_FINISHED));
    } else {
      dispatch(
        actions.updateUserPreference(preferences.NPS_SURVEY_STATUS, SURVEY_STATUS_COMPLETED)
      );
    }

    dispatch(
      actions.updateUserPreference(preferences.NPS_STATUS_UPDATE_AT, helpers.getCurrentDateTime())
    );

    dispatch(actions.updateUserPreference(preferences.LATEST_NPS_SCORE, selectedScore));
  };

  const closeDialog = () => dialogs.operations.hide();

  const handleSubmitSurvey = () => {
    const { pmf_survey: isPMFFeatureAvailable } = featureFlags;
    const isPMFAvailable = helpers.isPMFAvailable(
      userData.signupDate,
      currentDate,
      pmfSurveyUpdatedAt,
      pmfSurveyCompleted
    );

    onDialogMainAction();
    updatePreferences();
    trackSubmittedEvent();

    if (isPMFAvailable && isPMFFeatureAvailable) {
      dialogs.operations.show(dialogTypes.PMF_SURVEY);
    } else {
      showThankYouDialog({ selectedScore });
    }
  };

  const handlePostponeSurvey = () => {
    onDialogCancel();
    dispatch(
      actions.updateUserPreference(preferences.NPS_STATUS_UPDATE_AT, helpers.getCurrentDateTime())
    );

    dispatch(actions.updateUserPreference(preferences.NPS_SURVEY_STATUS, SURVEY_STATUS_POSTPONED));

    track(constants.events.NPS_SURVEY_POSTPONED, {
      siteId,
      templateBuildSlug,
    });

    closeDialog();
  };

  const goToNextStage = () => setStage(STAGE_MOBILE_2);

  return (
    <GenericDialog
      captions={captions}
      onMainAction={stage === STAGE_MOBILE_1 ? goToNextStage : handleSubmitSurvey}
      onSecondaryAction={handlePostponeSurvey}
      onClose={handlePostponeSurvey}
      mainActionDisabled={!Number.isInteger(selectedScore)}
      className="dialog--nps"
    >
      {stage !== STAGE_MOBILE_2 && (
        <React.Fragment>
          <h5 className="dialog-body__title">{captions.title}</h5>
          <NpsScores selectedScore={selectedScore} onSelectedScoreChange={setSelectedScore} />
        </React.Fragment>
      )}
      {stage !== STAGE_MOBILE_1 && Number.isInteger(selectedScore) && (
        <NpsFeedback
          captions={getNpsFeedbackCaptions(selectedScore)}
          onFeedbackChange={setFeedback}
        />
      )}
    </GenericDialog>
  );
}

SurveyDialogContainer.propTypes = {
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

export default SurveyDialogContainer;
