import { view } from '@yola/ws-sdk';
import removeFontSizeModifierFromNode from './remove-font-size-modifier-from-node';
import restoreRange from './restore-range';

function changeElementsType(newType, focusElements, selection, elementId) {
  const liveDocument = view.accessors.getLiveDocument();

  focusElements.forEach((node) => {
    const newElement = liveDocument.createElement(newType.value);
    removeFontSizeModifierFromNode(node);

    [...node.attributes].forEach((attributeNode) => {
      newElement.setAttributeNode(attributeNode.cloneNode(true));
    });
    newElement.append(...node.childNodes);
    node.replaceWith(newElement);
  });

  try {
    restoreRange(selection, elementId);
  } catch (error) {
    console.error(error);
  }
}

export default changeElementsType;
