import reduxCatch from 'redux-catch';
import auth from 'yola-editor/src/js/modules/auth';
import publishingServiceClient from 'yola-editor/src/js/modules/publishing-service-client';
import yowsServiceClient from 'yola-editor/src/js/modules/yows-service-client';
import featureFlags from 'yola-editor/src/js/modules/feature-flags';
import wlproxyClient from 'yola-editor/src/js/modules/wlproxy-client';
import publishing from 'yola-editor/src/js/modules/publishing';
import user from 'yola-editor/src/js/modules/user';
import site from 'yola-editor/src/js/modules/site';
import editor from 'yola-editor/src/js/modules/editor';
import branding from 'yola-editor/src/js/modules/branding';
import error from 'yola-editor/src/js/modules/error';
import i18n from 'yola-editor/src/js/modules/i18n';
import upsell from 'yola-editor/src/js/modules/upsell';
import webappPlugins from 'yola-editor/src/js/modules/webapp-plugins';
import yoproductsServiceClient from 'yola-editor/src/js/modules/yoproducts-service-client';
import router from './router/middleware';
import survey from './modules/survey';
import helpCenterMiddleware from './modules/help-center/middleware';
import difmPrivacyUpsell from './modules/difm-privacy-upsell';

export default [
  reduxCatch(error.helpers.catchReduxError),
  error.middleware.errorHandler,
  router,
  yowsServiceClient.middleware.clientLoader,
  publishingServiceClient.middleware.clientLoader,
  featureFlags.middleware.clientLoader,
  yoproductsServiceClient.middleware.clientLoader,
  wlproxyClient.middleware.clientLoader,
  user.middleware.yousersInitializer,
  auth.middleware.authenticate,
  auth.middleware.fetchToken,
  user.middleware.userPartnerDataLoader,
  user.middleware.userPreferencesLoader,
  user.middleware.userFeaturesLoader,
  user.middleware.emailVerification,
  site.middleware.siteData,
  site.middleware.publishedSiteData,
  site.middleware.siteReset,
  publishing.middleware.websitePublisher,
  branding.middleware.colorsInjector,
  i18n.middleware.translationsLoader,
  upsell.middleware.upgrade,
  upsell.middleware.orderSubscriptionPlan,
  upsell.middleware.updateEditorSettings,
  upsell.middleware.upgradeOptionsFetcher,
  upsell.middleware.domainPurchase,
  upsell.middleware.customDomainInterrupter,
  webappPlugins.middleware.configuration,
  helpCenterMiddleware.initHelpCenter,
  difmPrivacyUpsell.middleware.showDifmPrivacyUpsell,
  survey.middleware.showSurvey,
  editor.middleware.setSettings,
];
