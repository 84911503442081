import store from '../../../store';
import getOnlineStoreLimit from '../selectors/online-store-limit';

const isEnabled = () => {
  const state = store.getState();
  const onlineStoreLimits = getOnlineStoreLimit(state);

  return Boolean(onlineStoreLimits && onlineStoreLimits.available);
};

export default isEnabled;
