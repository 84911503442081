import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { dialogs, utils, site, siteLocales, i18next, integration } from '@yola/ws-sdk';
import websiteSettings from 'src/js/modules/website-settings';
import dialogTypes from '../../dialogs/constants/dialog-types';
import TranslatePageDialog from '../components/translate-page-dialog';

const { helpers } = websiteSettings;

const requireValidation = (value) => {
  if (!value) {
    return i18next.t('This field is required');
  }
  return '';
};

const getCaptions = () => ({
  title: i18next.t('Translate page'),
  fieldLabels: {
    language: i18next.t('Language to translate'),
    page: i18next.t('Page to translate'),
    checkbox: i18next.t('Use automatic translation'),
  },
  submit: i18next.t('Submit'),
  cancel: i18next.t('Cancel'),
});

function TranslatePageDialogContainer({
  onSubmit,
  onCancel,
  captions: propsCaptions,
  initialValues,
  onDialogMainAction,
  onDialogCancel,
}) {
  const countries = useMemo(() => helpers.getCountries(), []);
  const languages = useMemo(() => helpers.getLanguages(), []);
  const pages = useSelector(site.selectors.getPages);
  const currentLocale = useSelector(siteLocales.selectors.getCurrentLocale);
  const locales = useSelector(siteLocales.selectors.getSiteLocales);
  const requestBlockData = useSelector(integration.selectors.getRequestBlock);
  const roadmapUrl = requestBlockData && requestBlockData.publicRoadmapUrl;
  const captions = {
    ...getCaptions(),
    ...propsCaptions,
  };

  const usedLanguages = useMemo(
    () => locales.map((locale) => helpers.getLanguageData(locale, languages, countries)),
    [locales, languages, countries]
  );

  const languageOptions = useMemo(
    () =>
      usedLanguages
        .filter(({ code }) => code !== currentLocale)
        .map(({ code, name, country }) => ({
          code,
          name,
          iconComponent: country.iconComponent,
        })),
    [usedLanguages, currentLocale]
  );

  const pagesOptions = useMemo(() => {
    const languageCodes = usedLanguages.map(({ code }) => code);
    return languageCodes.reduce((res, code) => {
      const localeSpecificPagesList = pages
        .filter((page) => page.locale === code)
        .map(({ title, id }) => ({ title, id }));

      return {
        ...res,
        [code]: localeSpecificPagesList,
      };
    }, {});
  }, [usedLanguages, pages]);

  const setInitialValue = (values) => {
    if (values) return values;
    const availableLanguages = languageOptions.filter(
      (language) => language.code !== currentLocale
    );
    const firstLangCode = availableLanguages[0].code;
    const selectedLanguagePages = pages.filter((page) => page.locale === firstLangCode);
    const firstPage = selectedLanguagePages[0].id;

    return {
      langCode: firstLangCode,
      pageId: firstPage,
      autoTrans: false,
    };
  };

  const handleAutoTranslationChange = (values) => {
    dialogs.operations.show(dialogTypes.VOTE_FOR_THE_FEATURE, {
      onClose: (returnedProps) => {
        dialogs.operations.show(dialogTypes.TRANSLATE_PAGE, {
          ...returnedProps,
          onSubmit,
          onCancel,
        });
      },
      roadmapUrl,
      initialValues: values,
    });
  };

  const handleSubmit = (...values) => {
    onDialogMainAction();
    onSubmit(...values);
  };

  const handleCancel = () => {
    onDialogCancel();
    onCancel();
  };

  return (
    <TranslatePageDialog
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      roadmapUrl={roadmapUrl}
      onCheckboxChange={handleAutoTranslationChange}
      initialValues={setInitialValue(initialValues)}
      pagesOptions={pagesOptions}
      languageOptions={languageOptions}
      fieldsValidation={{
        page: requireValidation,
        langCode: requireValidation,
      }}
      captions={captions}
    />
  );
}

TranslatePageDialogContainer.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  captions: {},
  initialValues: PropTypes.shape({
    pageId: PropTypes.string,
    langCode: PropTypes.string,
    autoTrans: PropTypes.bool,
  }),
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

TranslatePageDialogContainer.defaultProps = {
  onCancel: utils.noop,
  onSubmit: utils.noop,
  captions: PropTypes.shape(),
  initialValues: null,
};

export default TranslatePageDialogContainer;
