import { hooks, extensions, assets } from '@yola/ws-sdk';
import customTools from 'src/js/modules/custom-tools';
import assignGroupId from 'src/js/modules/extensions/common/helpers/assign-group-id';
import control from './controls/control';
import ignoreContent from './helpers/ignore-content';
import mapIconsData from './helpers/map-icons-data';
import copyExtensionAsset from './helpers/copy-extension-asset';
import constants from './constants/common';
import iconResizeTool from './tools/icon-resize';

const { ON_LIVE_DOCUMENT_CONNECTED, ON_AFTER_INSERT_STATIC_ELEMENT } = hooks.availableHooks;
const ICON_MAX_SIZE_FALLBACK = 88;
const ICON_MIN_SIZE_FALLBACK = 16;

const extension = {
  slug: constants.slug,
  title: 'WS icon',
  description: 'Extension for editing icons',
  defaults: {
    querySelector: 'ws-icon:not(ws-social-icons ws-icon)',
    restrictedParentsSelector: 'ws-social-icons',
    iconMaxSize: ICON_MAX_SIZE_FALLBACK,
    iconMinSize: ICON_MIN_SIZE_FALLBACK,
  },
  ui: {
    control: [control],
  },
  api: {
    mapIconsData,
    copyExtensionAsset,
  },
  init() {
    assets.helpers
      .fetchWsAsset(`${constants.ROOT_ICONS_DIRECTORY}/basic/icons.json`)
      .then(({ data }) => {
        extensions.operations.updateExtension(constants.slug, { icons: data });
      });
    const settings = extensions.accessors.getExtensionSettings(constants.slug);
    const iconSelector = settings.querySelector;

    const onAfterInsert = (element) => {
      assignGroupId({
        container: element,
        querySelector: iconSelector,
        groupAttribute: constants.ICON_GROUP_ATTRIBUTE,
      });
    };
    hooks.operations.subscribe(ON_AFTER_INSERT_STATIC_ELEMENT, onAfterInsert);
    customTools.operations.registerTool(iconResizeTool);
  },
};
hooks.operations.subscribe(ON_LIVE_DOCUMENT_CONNECTED, ignoreContent);

export default extension;
