import React from 'react';
import PropTypes from 'prop-types';
import MediaPlaceholder from './media-placeholder';
import BackgroundBox from './background-box';

function MediaPreview({ url, size }) {
  const shouldUsePlaceholder = !url;

  return (
    <div className="ws-media-preview">
      {shouldUsePlaceholder ? <MediaPlaceholder /> : <BackgroundBox url={url} size={size} />}
    </div>
  );
}

MediaPreview.propTypes = {
  url: PropTypes.string,
  size: PropTypes.oneOf(['cover', 'contain']),
};

MediaPreview.defaultProps = {
  url: '',
  size: 'cover',
};

export default MediaPreview;
