import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { site } from '@yola/ws-sdk';
import store from 'src/js/store';
import segment from 'src/js/modules/analytics/segment';
import showIntegrationTab from 'src/js/modules/website-settings/helpers/show-integration-tab';
import DifmPrivacyUpsellDialog from '../components/difm-privacy-upsell-dialog';
import user from '../../user';
import dialogs from '../../dialogs';
import createDynamicUpgradeQueryString from '../helpers/create-dynamic-upgrade-query-string';
import { openInNewTab } from '../../../router/utils/open-in-new-tab';

const {
  trackers: { trackAsyncEvent, trackEvent },
  constants: { events, triggerIds },
  track,
} = segment;

const DifmPrivacyUpsellContainer = ({ triggerId }) => {
  const dispatch = useDispatch();
  const state = store.getState();
  const { complianceAccepted } = user.selectors.getUserPreferences(state);
  const path = useSelector(user.selectors.getPartnerUpgradeUrl);
  const { id: userId } = useSelector(user.selectors.getUserData);
  const { id: partnerId } = useSelector(user.selectors.getPartnerData);
  const [isSelfHandleCheckboxAdjusted, setIsSelfHandleCheckboxAdjusted] = useState(
    complianceAccepted || 'False'
  );
  const [isCloseEnabled, setIsCloseEnabled] = useState(false);
  const isComplianceAccepted = useMemo(
    () => isSelfHandleCheckboxAdjusted === 'True',
    [isSelfHandleCheckboxAdjusted]
  );

  const onSelfHandleCheckboxClick = () => {
    setIsSelfHandleCheckboxAdjusted((prev) => (prev === 'True' ? 'False' : 'True'));
    trackEvent(events.DIFM_PRIVACY_I_WILL_HANDLE_MYSELF_OPTION_CLICKED, {
      dialogId: dialogs.dialogTypes.DIFM_PRIVACY_UPSELL_DIALOG,
    });
  };

  const closeDialog = () => {
    track(events.DIFM_PRIVACY_DIALOG_CLOSED, {
      dialogId: dialogs.dialogTypes.DIFM_PRIVACY_UPSELL_DIALOG,
      siteId: site.accessors.getSiteId(),
      checkboxAdjusted: isSelfHandleCheckboxAdjusted,
    });
    dialogs.operations.hide();
  };

  const onLearnMoreClick = () => {
    if (path) {
      trackAsyncEvent(events.DIFM_PRIVACY_LEARN_MORE_TRIGGER_CLICKED, {
        dialogId: dialogs.dialogTypes.DIFM_PRIVACY_UPSELL_DIALOG,
      }).then(() => {
        const queryString = createDynamicUpgradeQueryString(userId, partnerId);

        openInNewTab(`${path}?${queryString}`);
        setIsCloseEnabled(true);
      });
    }
  };

  const onGoToSettingsClick = () => {
    trackEvent(events.DIFM_PRIVACY_GO_TO_SETTING_TRIGGER_CLICKED, {
      dialogId: dialogs.dialogTypes.DIFM_PRIVACY_UPSELL_DIALOG,
    });
    closeDialog();
    showIntegrationTab();
  };

  useEffect(() => {
    if (triggerId) {
      setIsCloseEnabled(true);
    } else {
      setTimeout(() => setIsCloseEnabled(true), 30000); // 30 seconds
    }
  }, [triggerId]);

  useEffect(() => {
    dispatch(
      user.actions.updateUserPreference(
        user.preferences.COMPLIANCE_ACCEPTED,
        isSelfHandleCheckboxAdjusted
      )
    );
  }, [dispatch, isSelfHandleCheckboxAdjusted]);

  return (
    <DifmPrivacyUpsellDialog
      onSelfHandleCheckboxClick={onSelfHandleCheckboxClick}
      onLearnMoreClick={onLearnMoreClick}
      onGoToSettingsClick={onGoToSettingsClick}
      onClose={closeDialog}
      isCloseEnabled={isCloseEnabled}
      isComplianceAccepted={isComplianceAccepted}
    />
  );
};

DifmPrivacyUpsellContainer.defaultProps = {
  triggerId: '',
};

DifmPrivacyUpsellContainer.propTypes = {
  triggerId: PropTypes.oneOf([triggerIds.EDITOR_HEADER, triggerIds.MAIN_MENU]),
};

export default DifmPrivacyUpsellContainer;
