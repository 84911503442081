import get from 'lodash.get';
import { config } from '@yola/ws-sdk';
import getIsB2C from './is-b2c';

const getApiUrl = (rootState) => {
  const cfg = config.selectors.getConfig(rootState);

  if (getIsB2C(rootState)) {
    return get(cfg, 'common.public-api-gateway.url');
  }

  return get(cfg, 'common.public-api-gateway.wl_url');
};

export default getApiUrl;
