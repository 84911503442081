import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { designSystem } from '@yola/ws-ui';
import { i18next } from '@yola/ws-sdk';
import verifiers from '../verifiers';
import operations from '../operations';

const { EditorHeader } = designSystem;

function NavigationContainer() {
  const isNavigationAvailable = useSelector(verifiers.isAvailable);
  const btnContainerRef = useRef();

  if (!isNavigationAvailable) return null;

  return (
    <div ref={btnContainerRef}>
      <EditorHeader.NavItem
        onClick={() => operations.openNavigationExtension(btnContainerRef.current, 'bottom')}
        label={i18next.t('Navigation')}
      />
    </div>
  );
}

export default NavigationContainer;
