import { anodum, dialogs, integration, view } from '@yola/ws-sdk';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import user from 'yola-editor/src/js/modules/user';

const brandedFooterTag = 'ws-branding';

const brandedFooterInterrupter = (store) => (next) => (action) => {
  if (action.type === view.actionTypes.DELETE_ELEMENT) {
    const liveElement = view.accessors.getLiveElement(action.payload.elementId);
    const isBrandedFooter = anodum.isTag(liveElement, brandedFooterTag);

    if (isBrandedFooter) {
      const state = store.getState();
      const limits = integration.selectors.getLimits(state);
      const upsells = integration.selectors.getUpsells(state);

      if (upsells.brandedFooter && !limits.brandedFooter.removable) {
        const { captions, onUpgrade, premiumFeature, purchaseFlowType } = upsells.brandedFooter;
        const availablePlatformComponents = user.selectors.getAvailablePlatformComponents(state);
        const onUpgradeResolve = () => next(action);
        const onUpgradeReject = () => {
          // eslint-disable-next-line no-console
          console.log('Upgrade flow was canceled');
        };

        // b2c & reseller platform upgrade
        if (availablePlatformComponents.includes(user.platformComponents.PAYMENTS)) {
          onUpgrade().then(onUpgradeResolve, onUpgradeReject);
          return;
        }

        store.dispatch(
          dialogs.actions.show(dialogTypes.UPGRADE, {
            purchaseFlowType,
            premiumFeature,
            captions,
            onUpgrade,
            onUpgradeResolve,
          })
        );
        return;
      }
    }
  }
  next(action);
};

export default brandedFooterInterrupter;
