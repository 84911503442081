import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { hdrm } from '@yola/ws-sdk';

const { DISABLE_BLUR_ATTR } = hdrm.constants.attributes;
const disableBlurAttribute = { [DISABLE_BLUR_ATTR]: true };

const ResizeHandle = React.forwardRef((props, ref) => {
  const {
    size,
    shape,
    direction,
    left,
    top,
    classNameModifier,
    style,
    onMouseDown,
    onMouseEnter,
    onMouseLeave,
    onTouchStart,
  } = props;
  const classes = classNames(
    'ws-resize-handle',
    `ws-resize-handle--size-${size}`,
    `ws-resize-handle--shape-${shape}`,
    `ws-resize-handle--direction-${direction}`,
    `ws-resize-handle--${classNameModifier}`,
    {
      [`ws-resize-handle--${classNameModifier}`]: classNameModifier,
    }
  );

  return (
    <button
      className={classes}
      type="button"
      ref={ref}
      style={{
        left,
        top,
        ...style,
      }}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...(onTouchStart && { onTouchStart })}
      {...disableBlurAttribute}
    />
  );
});

ResizeHandle.propTypes = {
  shape: PropTypes.oneOf(['circle', 'oval']),
  size: PropTypes.oneOf(['small', 'large']),
  direction: PropTypes.oneOf([
    'top-bottom',
    'left-right',
    'top-left',
    'top-right',
    'bottom-left',
    'bottom-right',
    'all',
  ]),
  left: PropTypes.number,
  top: PropTypes.number,
  style: PropTypes.shape(),
  classNameModifier: PropTypes.string,
  onMouseDown: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onTouchStart: PropTypes.func,
};

ResizeHandle.defaultProps = {
  shape: 'circle',
  size: 'small',
  direction: 'top-bottom',
  left: 0,
  top: 0,
  style: {},
  classNameModifier: null,
  onMouseDown: Function.prototype,
  onMouseEnter: Function.prototype,
  onMouseLeave: Function.prototype,
  onTouchStart: null,
};

export default ResizeHandle;
