import { anodum } from '@yola/ws-sdk';

const isTextEdited = (oldElement, newElement) => {
  if (!oldElement || !newElement) return false;

  const isTextElement = anodum.isTag(oldElement, 'ws-text') && anodum.isTag(newElement, 'ws-text');
  const hasContentUntouchedAttr = newElement.hasAttribute('content-untouched');

  if (isTextElement && hasContentUntouchedAttr) {
    return oldElement.innerText !== newElement.innerText;
  }

  return false;
};

export default isTextEdited;
