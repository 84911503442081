import get from 'lodash.get';
import { config } from '@yola/ws-sdk';

const getDomainHosting = (rootState) => {
  const cfg = config.selectors.getConfig(rootState);

  return get(cfg, 'common.domain.hosting', null);
};

export default getDomainHosting;
