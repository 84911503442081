import getLiveMapSource from './get-live-map-source';

const getMapUrlSearchParams = (elementId) => {
  const url = new URL(getLiveMapSource(elementId));
  const searchKeys = Array.from(url.searchParams.keys());

  return searchKeys.reduce((acc, key) => {
    if (key !== 'pb') {
      // pb query is encoded and generated by google map's shared link API
      acc[key] = url.searchParams.get(key);
    }
    return acc;
  }, {});
};

export default getMapUrlSearchParams;
