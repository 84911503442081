import React from 'react';
import PropTypes from 'prop-types';
import bowser from 'yola-bowser';
import { i18next, anodum, view, dialogs } from '@yola/ws-sdk';

import {
  Text,
  Button,
  ButtonGroup,
  Panel,
  PanelGroup,
  Formik,
  TabPanels,
  TabPanel,
} from '@yola/ws-ui';

import isIframeEmbed from '../helpers/is-iframe-embed';
import getIframeCode from '../helpers/get-iframe-code';
import formatUrl from '../helpers/format-url';
import copyAttributes from '../helpers/copy-attributes';
import ModalDialog from './modal-dialog';
import LinkForm from './link-form';
import CodeForm from './code-form';
import getTagName from '../helpers/get-tag-name';

const URL_TAB = 0;
const CODE_TAB = 1;
const PANEL_HEIGHT = 48;

const closeDialog = () => {
  dialogs.operations.hide();
};

class IframeEditDialog extends React.Component {
  constructor(props) {
    super(props);

    const { elementId } = props;
    const isEmbed = isIframeEmbed(elementId);

    this.state = {
      currentTab: isEmbed ? CODE_TAB : URL_TAB,
    };
    this.initValues = {
      urlValue: view.accessors.getElementAttribute(elementId, 'src'),
      isFullscreenAllowed: view.verifiers.hasElementAttribute(elementId, 'allowfullscreen'),
      embedCode: getIframeCode(elementId),
    };

    this.isMobile = bowser.mobile;
    this.tabs = [
      { label: i18next.t('Embed URL'), value: 'url' },
      { label: i18next.t('Embed Code'), value: 'code' },
    ];

    this.handleTabSwitch = this.handleTabSwitch.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.applyLinkSettings = this.applyLinkSettings.bind(this);
    this.applyCodeSettings = this.applyCodeSettings.bind(this);
  }

  onSubmit(data) {
    const { currentTab } = this.state;
    const { onDialogMainAction } = this.props;

    onDialogMainAction();

    if (currentTab === URL_TAB) {
      this.applyLinkSettings(data);
    }

    if (currentTab === CODE_TAB) {
      this.applyCodeSettings(data);
    }
  }

  onCancel() {
    const { onDialogCancel } = this.props;

    onDialogCancel();
    closeDialog();
  }

  handleTabSwitch(tab) {
    this.setState({
      currentTab: this.tabs.indexOf(tab),
    });
  }

  applyLinkSettings(values) {
    const { elementId } = this.props;
    const { urlValue, isFullscreenAllowed } = values;
    const doc = view.accessors.getLiveDocument();
    const iframeTagName = getTagName();
    const newIframeElement = doc.createElement(iframeTagName);

    newIframeElement.setAttribute('src', formatUrl(urlValue, elementId));

    if (isFullscreenAllowed) {
      newIframeElement.setAttribute('allowfullscreen', '');
    }

    view.operations.replaceElement(newIframeElement, elementId);

    closeDialog();
  }

  applyCodeSettings(values) {
    const { elementId } = this.props;
    const iframeTagName = getTagName();
    const parsedEmbedCode = anodum.parseElementFromString(values.embedCode);
    let newIframeElement;

    if (iframeTagName === 'ws-iframe') {
      newIframeElement = document.createElement('ws-iframe');

      copyAttributes(newIframeElement, parsedEmbedCode);
    } else {
      newIframeElement = parsedEmbedCode;
    }

    view.operations.replaceElement(newIframeElement, elementId);

    closeDialog();
  }

  render() {
    const { elementId } = this.props;
    const { currentTab } = this.state;

    return (
      <Formik initialValues={this.initValues} onSubmit={this.onSubmit}>
        {({ handleSubmit, errors }) => (
          <ModalDialog
            targetElementId={elementId}
            onClose={this.onCancel}
            onSubmit={handleSubmit}
            isValid={!Object.values(errors).filter(Boolean).length}
          >
            <PanelGroup height="100%">
              <Panel
                theme="gray-100"
                corners="squared"
                align="middle"
                height={PANEL_HEIGHT}
                className="ws-drag-trigger ws-modal__content ws-iframe__title"
              >
                <Text type="heading-6">{i18next.t('Iframe settings')}</Text>
              </Panel>
              <TabPanels
                value={currentTab}
                className="ws-iframe__tab-panels"
                variant="fullWidth"
                enableSwipeableViews={this.isMobile}
                onChange={(index) => {
                  this.setState({
                    currentTab: index,
                  });
                }}
              >
                <TabPanel id="EmbedURL" label={i18next.t('Embed URL')}>
                  <LinkForm
                    isFullscreenAllowed={this.initValues.isFullscreenAllowed}
                    shouldValidate={currentTab === URL_TAB}
                  />
                </TabPanel>
                <TabPanel id="EmbedCode" label={i18next.t('Embed Code')}>
                  <CodeForm shouldValidate={currentTab === CODE_TAB} />
                </TabPanel>
              </TabPanels>
              <Panel height={PANEL_HEIGHT} align="middle" corners="squared">
                <ButtonGroup stick="top" block>
                  <Button stretch="block" size="large" onClick={handleSubmit}>
                    <Text type="heading-6">{i18next.t('Submit')}</Text>
                  </Button>
                  <Button stretch="block" size="large" onMouseDown={this.onCancel}>
                    <Text type="heading-6">{i18next.t('Cancel')}</Text>
                  </Button>
                </ButtonGroup>
              </Panel>
            </PanelGroup>
          </ModalDialog>
        )}
      </Formik>
    );
  }
}

IframeEditDialog.propTypes = {
  elementId: PropTypes.string.isRequired,
  // Passed from ws-editor's `dialogs-container`:
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

export default IframeEditDialog;
