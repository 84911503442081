import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import { snakeCaseToCamelCase, dashedToCamelCase } from 'src/js/modules/utils/convert-case';
import backgroundOptionTypes from '../constants/background-option-types';

const { BACKGROUND_IMAGE_OPTION } = backgroundOptionTypes;

const getTraitName = (blockSettingId) => `${blockSettingId}Adjusted`;

const trackBlockSettingsDialogSubmitted = ({
  onDialogMainAction,
  isOverlayClicked,
  blockId,
  blockVariationId,
  blockLayoutAdjusted,
  blockSettingsChanges,
  changedBackgroundOptions,
}) => {
  if (!blockId) return;

  const [changedChildren, changesList] = blockSettingsChanges;
  const blockSettingAdjusted = {};

  if (changedChildren.length) {
    blockSettingAdjusted.optionalChildrenAdjusted = changedChildren.map((child) => child.id);
  }

  if (changesList.length) {
    changesList.forEach((setting) => {
      const { id, value, isEnabled } = setting;
      const blockSettingId = dashedToCamelCase(snakeCaseToCamelCase(id));
      blockSettingAdjusted[getTraitName(blockSettingId)] = value || isEnabled;
    });
  }

  // Trait for block background image option
  blockSettingAdjusted[
    getTraitName(dashedToCamelCase(snakeCaseToCamelCase(BACKGROUND_IMAGE_OPTION)))
  ] = changedBackgroundOptions
    ? changedBackgroundOptions[BACKGROUND_IMAGE_OPTION].toString()
    : 'not-available';

  const traits = {
    triggerId: isOverlayClicked ? 'dialog-background' : 'submit-button',
    blockId,
    blockVariationId,
    blockLayoutAdjusted,
    ...blockSettingAdjusted,
  };

  onDialogMainAction({
    dialogType: dialogTypes.BLOCK_SETTINGS,
    ...traits,
  });
};

export default trackBlockSettingsDialogSubmitted;
