const getScoreStatusClassName = (score, selectedScore) => {
  const isScoreNotInRange = selectedScore - score < 0;

  if (isScoreNotInRange) return 'nps-score-status--idle';

  switch (true) {
    case selectedScore > 8:
      return 'nps-score-status--high';
    case selectedScore > 6:
      return 'nps-score-status--average';
    case typeof selectedScore === 'number' && selectedScore >= 0:
      return 'nps-score-status--low';
    default:
      return 'nps-score-status--idle';
  }
};

export default getScoreStatusClassName;
