import { i18next } from '@yola/ws-sdk';
import dialogs from 'yola-editor/src/js/modules/dialogs';
import user from 'yola-editor/src/js/modules/user';
import segment from 'src/js/modules/analytics/segment';
import site from 'yola-editor/src/js/modules/site';
import auth from 'yola-editor/src/js/modules/auth';
import wlproxyClient from 'yola-editor/src/js/modules/wlproxy-client/service-client';
import actionTypes from '../constants/action-types';
import productTypes from '../constants/product-types';
import actions from '../actions';
import getCommonWlproxyRequestParams from '../helpers/get-common-wlproxy-request-params';
import handleOrderSubscriptionFailure from '../helpers/handle-order-subscription-failure';
import { openInNewTab } from '../../../router/utils/open-in-new-tab';
import { redirectToUrl } from '../../../router/utils/redirect-to-url';

const { constants, trackAsync, track } = segment;

const domainPurchase = (store) => (next) => (action) => {
  switch (action.type) {
    case actionTypes.START_B2C_DOMAIN_PURCHASE: {
      const state = store.getState();

      track(constants.events.BUY_A_DOMAIN_TRIGGER_CLICKED, {
        appName: constants.common.APP_NAME,
        siteId: auth.selectors.getSiteId(state),
        templateBuildSlug: site.selectors.getSiteTemplateSlug(state),
        triggerId: action.payload.triggerId,
      });

      store.dispatch(
        dialogs.actions.show(dialogs.dialogTypes.B2C_DOMAIN_PURCHASE, {
          triggerId: action.payload.triggerId,
          domainNameSuggestion: action.payload.domainName,
        })
      );
      break;
    }
    case actionTypes.START_WL_DOMAIN_PURCHASE: {
      const state = store.getState();
      const withPartnersDomainUpsell = user.verifiers.hasPartnersDomainUpsell(state);

      if (withPartnersDomainUpsell) {
        const path = user.selectors.getPartnerUpgradeUrl(state);

        if (path) {
          trackAsync(constants.events.BUY_A_DOMAIN_TRIGGER_CLICKED, {
            siteId: auth.selectors.getSiteId(state),
            templateBuildSlug: site.selectors.getSiteTemplateSlug(state),
            triggerId: action.payload.triggerId,
          }).then(() => {
            const { id: userId } = user.selectors.getUserData(state);
            const { id: partnerId } = user.selectors.getPartnerData(state);
            const queryString = `?u=${userId}&p=${partnerId}&action=purchase&product=domain`;

            // We’ve received a request from our partners who use editor inside the `iframe` not to open buy-domain page in a new tab.
            // So if editor is used in `iframe` - `buy-domain` page should be opened in the same window
            // if no - should be opened in new tab
            if (window !== window.top) {
              redirectToUrl(`${path}${queryString}`, { topWindow: true });
            } else {
              openInNewTab(`${path}${queryString}`);
            }
          });
        }
        break;
      }

      store.dispatch(dialogs.actions.show(dialogs.dialogTypes.WL_DOMAIN_PURCHASE));
      break;
    }

    case actionTypes.GET_DOMAIN_LIST: {
      const state = store.getState();
      const serviceClient = wlproxyClient.get();
      const { language, siteId } = getCommonWlproxyRequestParams(state);

      serviceClient
        .listProducts({
          siteId,
          language,
          details: {
            productType: productTypes.DOMAIN,
            query: action.payload.query,
          },
        })
        .then(
          ({ data }) => {
            store.dispatch(actions.getDomainListSuccess(data));
          },
          () => {
            store.dispatch(actions.getDomainListFailure());
          }
        );

      next(action);
      break;
    }

    case actionTypes.GET_DOMAIN_LIST_FAILURE: {
      store.dispatch(
        dialogs.actions.show(dialogs.dialogTypes.IN_APP_PURCHASE_FAILURE, {
          captions: {
            title: i18next.t('Domain search failed'),
            description: i18next.t('Please try again later.'),
          },
        })
      );

      next(action);
      break;
    }

    case actionTypes.ORDER_DOMAIN: {
      store.dispatch(dialogs.actions.show(dialogs.dialogTypes.IN_APP_PURCHASE_PENDING));

      const state = store.getState();
      const serviceClient = wlproxyClient.get();
      const { language, siteId } = getCommonWlproxyRequestParams(state);

      serviceClient
        .order({
          siteId,
          language,
          details: {
            productType: productTypes.DOMAIN,
            domainName: action.payload.domainName,
            domainContact: action.payload.domainContact,
          },
        })
        .then(
          () => {
            store.dispatch(
              dialogs.actions.show(dialogs.dialogTypes.IN_APP_PURCHASE_SUCCESS, {
                captions: {
                  title: i18next.t('Order complete'),
                  description: i18next.t(
                    'Success! Your order has been processed and your domain will be configured shortly.'
                  ),
                  onClose: () => store.dispatch(dialogs.actions.hide()),
                },
              })
            );

            store.dispatch(user.actions.fetchUserPreferences());
            store.dispatch(actions.clearDomainPurchaseData());
          },
          (error) => {
            handleOrderSubscriptionFailure(store, error);
            store.dispatch(actions.clearDomainPurchaseData());
          }
        );

      next(action);
      break;
    }

    default:
      next(action);
  }
};

export default domainPurchase;
