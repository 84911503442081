import { useEffect, useContext, useMemo, useCallback, useRef } from 'react';
import { assets, dialogs, i18next, utils, view } from '@yola/ws-sdk';
import scroller from 'src/js/modules/scroller';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import useEventListener from 'src/js/modules/utils/custom-hooks/use-event-listener';
import usePrevious from 'src/js/modules/utils/custom-hooks/use-previous';
import useMounted from 'src/js/modules/utils/custom-hooks/use-mounted';
import FocalPointContext from '../context';
import constants from '../constants/common';
import helpers from '../helpers';
import removeCoverConstraints from '../helpers/remove-cover-constraints-attribute';

const { IMAGE_LOADING_DIALOG } = dialogTypes;

const {
  MINIMUM_IMAGE_LOADING_TIME,
  DEFAULT_POSITION_STRING_VALUE,
  DEFAULT_SCALE,
  DEFAULT_ZOOM,
  MIN_SCALE,
  MAX_SCALE,
  MIN_ZOOM,
  MAX_ZOOM,
  ZOOM_COEF,
  ORIENTATION_CHANGE_DELAY,
  CONTROLS_MARGIN_TOP,
  BODY_DRAGGABLE_CLASS,
  ADJUST_POSITION_EVENT,
  RESIZE_EVENT,
} = constants;

const { addBaseHref, getImageSize } = assets.helpers;
const { handleAsyncError } = utils;

/* eslint no-shadow: "off" */
/* eslint no-param-reassign: "off" */

const useFocalPoint = ({
  elementId,
  onInitialImageLoad,
  onFitImageSize,
  viewportWidth,
  viewportHeight,
  orientation,
  onDragStart,
  onDrag,
  onDragEnd,
  onScale,
  preserveSharedData,
  getInitialScrollPosition,
}) => {
  const focalPointContext = useContext(FocalPointContext);
  const {
    position,
    setPosition,
    scale,
    zoom,
    setScale,
    setZoom,
    currentSource,
    setCurrentSource,
    imageBounds,
    setImageBounds,
    controlsRef,
    isLoadingImage,
    initialData,
    imageData,
  } = focalPointContext;

  const imageScaledBounds = useRef([]);
  const startImagePosition = useRef([]);
  const prevViewportWidth = usePrevious(viewportWidth);
  const prevViewportHeight = usePrevious(viewportHeight);
  const prevOrientation = usePrevious(orientation);
  const isMounted = useMounted();

  const imageNodeData = useMemo(() => {
    const node = view.accessors.getLiveElement(elementId);

    //  Update coordinates of the container, depending on whether it is a media container or a background container or ws-block
    const imageContainerNode = helpers.getImageContainerNode(node);
    const styles = node.ownerDocument.defaultView.getComputedStyle(imageContainerNode);
    const bounds = node.getBoundingClientRect();

    return {
      node,
      bounds,
      styles,
      imageContainerNode,
    };
  }, [elementId]);

  const { node: imageNode, styles: imageComputedStyles, imageContainerNode } = imageNodeData;

  // Update image bounds in state when media container or background container size is changed
  // example: use case is loading smaller then block image in Article block
  useEventListener(
    RESIZE_EVENT,
    (event) => {
      const boundsWithoutBorder = helpers.getImageBounds(imageNode, imageComputedStyles);
      const { containerWidth } = event.detail;

      if (boundsWithoutBorder.width !== containerWidth) {
        setImageBounds(boundsWithoutBorder);
      }
    },
    imageContainerNode
  );

  // Update position in state to keep it in sync when media or background container adjust it,
  // due to changes of scale or container size
  useEventListener(
    ADJUST_POSITION_EVENT,
    (event) => {
      const { position } = event.detail;
      setPosition(position);
    },
    imageContainerNode
  );

  // As we disable pointer events on Controls when user start dragging (see `handleImageDragStart`)
  // so it won't be interrupted once hover over controls we need to enable it back so user still use
  // them after he finish dragging image. We do that on document body cuz he can move mouse away
  // from viewport and we still need to get `mouseDownEvent`

  useEventListener(
    'mouseup',
    () => {
      document.body.classList.remove(BODY_DRAGGABLE_CLASS);
      if (controlsRef.current) {
        controlsRef.current.style.pointerEvents = 'initial';
      }
    },
    document.body
  );

  // Initialization:
  // We need to scroll image into view, load image and get it's initial values
  // and valid bounds after scroll to render UI properly
  useEffect(() => {
    const { height: imageHeight } = imageNode.getBoundingClientRect();
    const liveDocument = imageNode.ownerDocument;
    const windowHeight = (liveDocument.defaultView && liveDocument.defaultView.innerHeight) || 0;
    const isHigherThanViewport = imageHeight > windowHeight;

    const initialScrollPosition = getInitialScrollPosition(isHigherThanViewport);

    async function init() {
      // We've added `cover-constraints` attribute to all image containers, to protect existing sites
      // within https://github.com/yola/ws-service/issues/1912
      // but for new functionality with updated constraints to be working we need to remove it on demand
      const isMigrated = removeCoverConstraints(imageContainerNode);

      await scroller.helpers.scrollToElement(imageNode, {
        includeOffset: false,
        ...initialScrollPosition,
      });

      const initialSource = helpers.getCurrentImageUrl(imageNode);

      dialogs.operations.show(IMAGE_LOADING_DIALOG, {
        captions: {
          description: i18next.t('Image resizer will be loaded soon'),
          containerClassName: 'ws-focal-point-loading-dialog ws-dialog-fade',
        },
      });

      isLoadingImage.current = true;

      const [[{ width, height }], error] = await handleAsyncError(
        Promise.all([
          getImageSize(addBaseHref(initialSource)),
          helpers.wait(MINIMUM_IMAGE_LOADING_TIME),
        ])
      );

      if (error) {
        console.error(error);
        isLoadingImage.current = false;
        dialogs.operations.hide({ preserveSharedData });
        return;
      }

      const [x, y] = helpers.getPosition(imageNode);

      const bounds = helpers.getImageBounds(imageContainerNode, imageComputedStyles);
      const initialScale = helpers.getScale(imageNode);
      const initialZoom =
        initialScale >= 1 ? 100 * (initialScale - 1) : (100 * (initialScale - 1)) / ZOOM_COEF;

      const ratio = imageNode.getAttribute('aspect-ratio');

      dialogs.operations.hide({ preserveSharedData });
      setScale(initialScale);
      setZoom(initialZoom);
      setCurrentSource(initialSource);
      setPosition({ x, y });
      setImageBounds(bounds);
      initialData.current = {
        source: initialSource,
        width,
        height,
        position: { x, y },
        scale: initialScale,
        bounds,
        ratio,
        isMigrated,
      };
      imageData.current = {
        width,
        height,
        source: initialSource,
      };

      isLoadingImage.current = false;

      // Here you can override or set additional properties  for different type of focal point
      // containers
      onInitialImageLoad({
        imageNode,
        imageData,
        initialData,
        scale: initialScale,
        bounds,
        position: { x, y },
        width,
        height,
        source: initialSource,
        ratio,
      });
    }

    init();
  }, []);

  // We need it to resize UI when user changes viewport size and device orientation
  useEffect(() => {
    if (!isMounted.current || !imageBounds) return;

    // handle window resize
    if (prevViewportWidth !== viewportWidth || prevViewportHeight !== viewportHeight) {
      setImageBounds(helpers.getImageBounds(imageContainerNode, imageComputedStyles));
    }

    // handle orientation change
    if (prevOrientation !== orientation) {
      const handleOrientationChange = async () => {
        await helpers.wait(ORIENTATION_CHANGE_DELAY);

        const scrollPosition = scroller.helpers.getScrollOffset(imageContainerNode, 'top', true);
        await view.helpers.smoothScroll.scrollToPosition(scrollPosition - CONTROLS_MARGIN_TOP, 0);
        setImageBounds(helpers.getImageBounds(imageContainerNode, imageComputedStyles));
      };

      handleOrientationChange();
    }
  }, [
    viewportWidth,
    viewportHeight,
    orientation,
    imageNode,
    imageComputedStyles,
    prevOrientation,
    prevViewportHeight,
    prevViewportWidth,
    isMounted,
    imageBounds,
    setImageBounds,
    imageContainerNode,
  ]);

  const handleImageDragStart = useCallback(
    (...args) => {
      // Disable pointer events on controls so user can drag over them and not be interrupted
      document.body.classList.add(BODY_DRAGGABLE_CLASS);

      if (controlsRef.current) {
        controlsRef.current.style.pointerEvents = 'none';
      }

      startImagePosition.current = helpers.getPosition(imageNode);

      imageScaledBounds.current = helpers.getContentBounds(imageNode);

      onDragStart(...args, { position });
    },
    [position, controlsRef, imageNode, onDragStart]
  );

  const handleImageDrag = useCallback(
    ({ x, y }) => {
      const { bounds } = imageNodeData;
      const { width, height } = bounds;

      const l = (x / width) * 100;
      const t = (y / height) * 100;
      const [startX, startY] = startImagePosition.current;

      const currentX = Math.round((startX + l) * 100) / 100;
      const currentY = Math.round((startY + t) * 100) / 100;

      const [currentXPosition, currentYPosition] = helpers.getContentPositionInPercents(imageNode, {
        x: currentX,
        y: currentY,
      });
      helpers.setPosition(
        imageNode,
        helpers.createCssPositionString(currentXPosition, currentYPosition)
      );
      onDrag({
        xInPercents: currentXPosition,
        yInPercents: currentYPosition,
      });
    },
    [position, imageNode, onDrag]
  );
  const handleImageDragEnd = useCallback(
    (event, isDragged) => {
      if (!isDragged) return;

      const [x, y] = helpers.getPosition(imageNode);

      setPosition({ x, y });
      onDragEnd({ x, y }, event);
    },
    [imageNode, onDragEnd, setPosition]
  );

  const handleScale = useCallback(
    (value, isNegativeZoomEnabled = true) => {
      let scale = 0;

      if (isNegativeZoomEnabled) {
        const zoom = Number(value);
        if (zoom < MIN_ZOOM || zoom > MAX_ZOOM) return;

        setZoom(zoom);
        scale = zoom >= 0 ? 1 + zoom / 100 : 1 + (ZOOM_COEF * zoom) / 100;
      } else {
        scale = Number(value);
        if (scale < MIN_SCALE || scale > MAX_SCALE) return;
      }

      const roundedScale = Number(scale.toFixed(2));
      const newBounds = helpers.setScale(imageNode, roundedScale);
      setScale(roundedScale);
      onScale(roundedScale);

      if (!newBounds) return;
      // We should update position in the case when it's changed
      const { position } = newBounds;
      const [x, y] = position;
      helpers.setPosition(imageNode, helpers.createCssPositionString(x, y));
      setPosition({ x, y });
    },
    [imageNode, setScale, onScale, setZoom, setPosition]
  );

  const handleCancelUpload = useCallback(() => {
    isLoadingImage.current = false;
  }, [isLoadingImage]);

  const handleImageUploadStart = useCallback(() => {
    isLoadingImage.current = true;
  }, [isLoadingImage]);

  const handleImageUploadError = useCallback(() => {
    isLoadingImage.current = false;
  }, [isLoadingImage]);

  const setDefaultImagePositionAndScale = useCallback(() => {
    helpers.setPosition(imageNode, DEFAULT_POSITION_STRING_VALUE);
    helpers.setScale(imageNode, DEFAULT_SCALE);

    setZoom(DEFAULT_ZOOM);
    setScale(DEFAULT_SCALE);
    setPosition({
      x: 50,
      y: 50,
      xInPercents: 50,
      yInPercents: 50,
    });
  }, [setZoom, imageNode, setPosition, setScale]);

  const adjustContentScale = useCallback(
    async (newSource) => {
      helpers.setPosition(imageNode, DEFAULT_POSITION_STRING_VALUE);
      const originalMediaBounds = await assets.helpers.getImageSize(
        assets.helpers.addBaseHref(newSource)
      );
      const adjustedScale = await helpers.computeAdjustedScale(
        helpers.getImageContainerNode(imageNode),
        {
          originalMediaBounds,
          scale: DEFAULT_SCALE,
        }
      );

      helpers.setScale(imageNode, adjustedScale);
      const contentZoom =
        adjustedScale >= 1 ? 100 * (adjustedScale - 1) : (100 * (adjustedScale - 1)) / ZOOM_COEF;
      setZoom(contentZoom);
      setScale(adjustedScale);
    },
    [setScale, setZoom, imageNode]
  );

  const handleFitImageSize = useCallback(() => {
    const oldZoom = helpers.getScale(imageNode);
    setDefaultImagePositionAndScale();
    const newZoom = helpers.getScale(imageNode);
    onFitImageSize({
      newZoom,
      oldZoom,
    });
  }, [imageNode, setDefaultImagePositionAndScale, onFitImageSize]);

  return {
    imageNode,
    imageComputedStyles,
    position,
    setPosition,
    scale,
    zoom,
    setZoom,
    setScale,
    currentSource,
    setCurrentSource,
    imageBounds,
    initialData,
    imageData,
    setImageBounds,
    controlsRef,
    isLoadingImage,
    handleScale,
    handleFitImageSize,
    handleImageDragStart,
    handleImageDrag,
    handleImageDragEnd,
    handleCancelUpload,
    setDefaultImagePositionAndScale,
    handleImageUploadStart,
    handleImageUploadError,
    adjustContentScale,
  };
};

export default useFocalPoint;
