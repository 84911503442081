import { anodum } from '@yola/ws-sdk';

function findTypedParentElement(typedElementSelector, node) {
  if (!node) {
    return null;
  }
  const element = anodum.isElementNode(node) ? node : node.parentElement;
  return element.closest(typedElementSelector);
}

export default findTypedParentElement;
