import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import uniqby from 'lodash.uniqby';
import { designSystem, ImageGrid, LazyLoading, Spinner } from '@yola/ws-ui';
import { assets, i18next, integration } from '@yola/ws-sdk';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { constants as subscriptionConstants } from '@yola/subscription-manager-js';
import segment from 'src/js/modules/analytics/segment';
import usePrevious from 'src/js/modules/utils/custom-hooks/use-previous';
import UnableToLoad from './unable-to-load';
import constants from '../constants/common';
import getIconTypeOptions from '../helpers/get-icon-type-options';
import getDefaultPlatformOption from '../helpers/get-default-platform-options';
import getIconsLanguage from '../helpers/get-icons-language';
import getColumnsAmount from '../helpers/get-columns-amount';
import getPreviewIconSize from '../helpers/get-preview-icon-size';
import ImageGridSkeleton from './image-grid-skeleton';
import useMounted from '../../../../utils/custom-hooks/use-mounted';
import ConditionalWrapper from '../../../../common/components/conditional-wrapper';
import isFeatureAvailable from '../../../../upsell/verifiers/is-feature-available';
import getIconsWithPreviews from '../helpers/get-icons-with-previews';
import usePlatformOptions from '../hooks/use-platform-options';
import useRefHeightOnScreen from '../hooks/use-ref-height-on-screen';
import mapIconsData from '../helpers/map-icons-data';
import sleep from '../../../../utils/sleep';

const {
  hostingPackageFeatureNames: { PREMIUM_ICONS },
} = subscriptionConstants;

const {
  trackers: { trackUpgradeTriggerClicked },
  constants: { triggerIds },
} = segment;

const { Search, Heading, Paragraph, SimpleSelect, Box, CtaBanner, NotificationMessage } =
  designSystem;
const {
  INIT_NUMBER_OF_ICONS_ON_SCREEN,
  ICON_SIZE,
  ICONS_SCROLL_THRESHOLD,
  ICON_PREVIEW_SIZE,
  IMAGE_GRID_SKELETON_TOP_INDENT,
  BANNER_ANIMATION_TIME,
  ROOT_ICONS_DIRECTORY,
  MAX_RETRY_COUNT,
} = constants;

const AdvancedIcons = ({
  containerHeight,
  containerWidth,
  onSearchClick,
  onSearchPerform,
  selectedIcon,
  setSelectedIcon,
  setIsButtonSaveActive,
  selectIcon,
  iconColor,
  originalIconDataId,
  hasIconProcessError,
  onStyleFilterSelected,
  onTypeFilterSelected,
  onIconsLoadingError,
  onReloadRequestClick,
}) => {
  const [areIconsLoading, setAreIconsLoading] = useState(false);
  const [isFilteringInProgress, setIsFilteringInProgress] = useState(true);
  const [icons, setIcons] = useState([]);
  const [numOfIcons, setNumOfIcons] = useState(0);
  const [onReloadClick, setOnReloadClick] = useState(null);
  const [filterValue, setFilterValue] = useState('');
  const [totalIconsCount, setTotalIconsCount] = useState(0);
  const defaultPlatformOption = getDefaultPlatformOption();
  const [platformValue, setPlatformValue] = useState(defaultPlatformOption.value);
  const iconTypeOptions = getIconTypeOptions();
  const [iconTypeActiveOption, setIconTypeActiveOption] = useState(iconTypeOptions[0]);
  const [shouldAnimateBanner, setShouldAnimateBanner] = useState(true);
  const mountedRef = useMounted();
  const filterContainerRef = useRef();
  const iconSize = getPreviewIconSize();
  const language = getIconsLanguage();
  const columnsAmount = getColumnsAmount(icons, containerWidth);
  const arePremiumIconsAvailable = isFeatureAvailable(PREMIUM_ICONS);
  const platformOptions = usePlatformOptions({
    setOnReloadClick,
    shouldLoad: arePremiumIconsAvailable,
  });
  const isUnableToLoadError = Boolean(onReloadClick);
  const abortRequestControllerRef = useRef();
  const { premiumIcons } = useSelector(integration.selectors.getUpsells);
  const filterHeight = useRefHeightOnScreen(filterContainerRef);
  const prevFilterValue = usePrevious(filterValue);

  useEffect(() => {
    const isButtonSaveActive = selectedIcon.id && selectedIcon.id !== originalIconDataId;

    setIsButtonSaveActive(isButtonSaveActive);
  }, [selectedIcon.id, originalIconDataId, setIsButtonSaveActive]);

  const onIconsLoading = async ({
    valueToFilter = filterValue,
    loadMore = false,
    platform = platformValue,
    iconType = iconTypeActiveOption.value,
  }) => {
    const offset = loadMore ? numOfIcons : 0;
    const nextStep = offset + INIT_NUMBER_OF_ICONS_ON_SCREEN;

    setOnReloadClick(null);
    setAreIconsLoading(true);

    const onRequestFail = () => {
      setOnReloadClick(() => () => {
        setIsFilteringInProgress(true);
        onReloadRequestClick();
        onIconsLoading({ valueToFilter, loadMore, platform, iconType });
      });
      onIconsLoadingError();
    };

    abortRequestControllerRef.current?.abort();
    abortRequestControllerRef.current = new AbortController();

    const { icons: targetIcons, iconsCount } = await getIconsWithPreviews({
      valueToFilter,
      offset,
      platform,
      iconType,
      iconColor,
      onRequestFail,
      language,
      iconSize,
      abortRequestController: abortRequestControllerRef.current,
    });

    if (!mountedRef.current || !targetIcons) return;

    setTotalIconsCount(iconsCount);

    targetIcons.forEach((targetIcon) => {
      if (!selectedIcon.src && originalIconDataId === targetIcon.id) {
        setSelectedIcon(targetIcon);
      }
    });

    const loadedIcons = loadMore ? [...icons, ...targetIcons] : targetIcons;
    setIcons(uniqby(loadedIcons, 'id'));

    if (valueToFilter && !loadMore) {
      onSearchPerform({
        searchTerm: valueToFilter,
        searchResults: iconsCount,
      });
    }

    setAreIconsLoading(false);
    setNumOfIcons(nextStep);

    setIsFilteringInProgress(false);
  };

  useEffect(() => {
    if (arePremiumIconsAvailable) {
      onIconsLoading({});
    } else {
      assets.helpers
        .fetchWsAsset(`${ROOT_ICONS_DIRECTORY}/advanced/icons.json`)
        .then((response) => {
          const { data } = response;
          const mappedData = mapIconsData(data, `${ROOT_ICONS_DIRECTORY}/advanced`);

          if (mountedRef.current) {
            setIcons(mappedData['demo-icons']);
            setAreIconsLoading(false);
            setIsFilteringInProgress(false);
          }
        });
    }

    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, [arePremiumIconsAvailable]);

  const onFilter = (value = '') => {
    const prettifiedFilterValue = value.trim().toLowerCase();

    if (!prettifiedFilterValue && !prevFilterValue) return;

    setIsFilteringInProgress(true);
    setAreIconsLoading(false);

    setFilterValue(prettifiedFilterValue);
    onIconsLoading({ valueToFilter: prettifiedFilterValue });
  };

  const onPlatformChange = (platformOption) => {
    onStyleFilterSelected({ styleTypeOld: platformValue, styleTypeNew: platformOption.value });

    setIsFilteringInProgress(true);
    setPlatformValue(platformOption.value);

    onIconsLoading({ platform: platformOption.value });
  };

  const onIconTypeChange = (iconTypeOption) => {
    onTypeFilterSelected({
      oldTypeId: iconTypeActiveOption.value,
      newTypeId: iconTypeOption.value,
    });
    setIsFilteringInProgress(true);
    setIconTypeActiveOption(iconTypeOption);

    onIconsLoading({ iconType: iconTypeOption.value });
  };

  const onUpgradeClick = () => {
    trackUpgradeTriggerClicked(triggerIds.PREMIUM_ICON_LIBRARY);

    premiumIcons.onUpgrade().catch(() => {
      // eslint-disable-next-line no-console
      console.log('Upgrade flow was canceled');
    });
  };

  const onIconLoadError = async (item) => {
    let newIcons = icons.map((icon) => ({ ...icon, src: icon.id === item.id ? '' : icon.src }));
    setIcons(newIcons);

    const retryCount = item.retryCount || 0;
    if (retryCount < MAX_RETRY_COUNT) {
      await sleep(2000);
      newIcons = icons.map((icon) => ({
        ...icon,
        src: icon.id === item.id ? `${icon.src}&rs=${Date.now()}` : icon.src,
        ...(icon.id === item.id && { retryCount: retryCount + 1 }),
      }));
      setIcons(newIcons);
    }
  };

  const onDisabledContentClick = () => {
    setShouldAnimateBanner(true);
  };

  useEffect(() => {
    if (shouldAnimateBanner) {
      setTimeout(() => {
        setShouldAnimateBanner(false);
      }, BANNER_ANIMATION_TIME);
    }
  }, [shouldAnimateBanner]);

  return (
    <div
      className={classNames('ws-advanced-icons-container', {
        'ws-advanced-icons-container--disabled': !arePremiumIconsAvailable,
      })}
      {...(!arePremiumIconsAvailable && {
        onClick: onDisabledContentClick,
        style: { height: `${containerHeight}px` },
      })}
    >
      {!arePremiumIconsAvailable && (
        <Box marginTop="spacing-s" marginLeft="spacing-m" marginRight="spacing-m">
          <CtaBanner
            iconGlyph="upgrade"
            animate={shouldAnimateBanner}
            centered
            ctaButton={{
              label: premiumIcons.captions.upgrade,
              onClick: onUpgradeClick,
            }}
            layout="mobile"
            title={premiumIcons.captions.description}
          />
        </Box>
      )}
      <div
        className={classNames('ws-advanced-icons', {
          'ws-advanced-icons--disabled': !arePremiumIconsAvailable,
        })}
      >
        <div className="ws-icon-modal__search" ref={filterContainerRef}>
          <Search
            onChange={onFilter}
            onClear={onFilter}
            onFocus={onSearchClick}
            placeholder={i18next.t('Search through 1.2 million icons...')}
          />
          <div className="ws-icon-modal__filters">
            <SimpleSelect
              options={platformOptions}
              onChange={onPlatformChange}
              defaultValue={platformOptions[0]}
              disabled={isFilteringInProgress || areIconsLoading || isUnableToLoadError}
            />
            <SimpleSelect
              options={iconTypeOptions}
              onChange={onIconTypeChange}
              defaultValue={iconTypeActiveOption}
              disabled={isFilteringInProgress || areIconsLoading || isUnableToLoadError}
            />
          </div>
        </div>
        <ConditionalWrapper
          condition={arePremiumIconsAvailable && !isFilteringInProgress}
          wrapper={(children) => (
            <LazyLoading
              onThresholdExceed={() => {
                if (totalIconsCount > icons.length && !areIconsLoading) {
                  onIconsLoading({ loadMore: true });
                }
              }}
              threshold={ICONS_SCROLL_THRESHOLD}
              height={containerHeight - filterHeight}
            >
              {children}
            </LazyLoading>
          )}
        >
          {hasIconProcessError && !isFilteringInProgress && !onReloadClick && icons.length && (
            <div className="ws-advanced-icons__error">
              <NotificationMessage
                align="left"
                appearance="danger"
                title={i18next.t(
                  "We're unable to process that icon. Please try again or select a different icon"
                )}
              />
            </div>
          )}
          <div className="ws-icon-modal__body">
            {onReloadClick && <UnableToLoad onReload={onReloadClick} />}
            {!isFilteringInProgress && !!icons.length && !isUnableToLoadError && (
              <ImageGrid
                height="100%"
                items={icons}
                iconSize={`${ICON_SIZE}px`}
                activeItemId={selectedIcon.id}
                onItemClick={selectIcon}
                columns={columnsAmount}
                onError={onIconLoadError}
                // we need this property in order to be able to get image from cache using canvas
                crossOrigin="anonymous"
              />
            )}

            {isFilteringInProgress && !isUnableToLoadError && (
              <div
                className="ws-icon-modal__skeleton-container"
                style={{ height: `${containerHeight - filterHeight}px` }}
              >
                <ImageGridSkeleton
                  containerHeight={containerHeight - filterHeight - IMAGE_GRID_SKELETON_TOP_INDENT}
                  containerWidth={containerWidth}
                  itemSize={ICON_PREVIEW_SIZE}
                />
              </div>
            )}

            {areIconsLoading && !isFilteringInProgress && (
              <Spinner containerPosition="bottom" spinnerPosition="center" />
            )}

            {!isFilteringInProgress &&
              !areIconsLoading &&
              !icons.length &&
              !isUnableToLoadError && (
                <div className="ws-icon-modal__not-found-container">
                  <Box marginBottom="spacing-4-xs">
                    <Heading type="heading-4" appearance="medium-emphasis">
                      {i18next.t('Sorry, no results found')}
                    </Heading>
                  </Box>
                  {filterValue && (
                    <Paragraph size="medium" appearance="medium-emphasis">
                      {i18next.t('Please try searching in English for better matches')}
                    </Paragraph>
                  )}
                </div>
              )}
          </div>
        </ConditionalWrapper>
      </div>
    </div>
  );
};

AdvancedIcons.propTypes = {
  containerHeight: PropTypes.number,
  containerWidth: PropTypes.number,
  onSearchClick: PropTypes.func,
  selectedIcon: PropTypes.object,
  setSelectedIcon: PropTypes.func.isRequired,
  setIsButtonSaveActive: PropTypes.func.isRequired,
  selectIcon: PropTypes.func.isRequired,
  originalIconDataId: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  iconColor: PropTypes.string,
  hasIconProcessError: PropTypes.bool,
  onSearchPerform: PropTypes.func.isRequired,
  onStyleFilterSelected: PropTypes.func.isRequired,
  onTypeFilterSelected: PropTypes.func.isRequired,
  onIconsLoadingError: PropTypes.func.isRequired,
  onReloadRequestClick: PropTypes.func.isRequired,
};

AdvancedIcons.defaultProps = {
  onSearchClick: () => {},
  selectedIcon: {},
  containerHeight: null,
  containerWidth: null,
  originalIconDataId: null,
  iconColor: '',
  hasIconProcessError: false,
};

export default AdvancedIcons;
