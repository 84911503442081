const patchMenuItemsWithClickCallback = (groups, patchFunction = null) => {
  if (!patchFunction) return groups;

  return groups.map((group) => {
    const { items } = group;
    return {
      ...group,
      items: items.map((item) => {
        const { onClick } = item;
        return {
          ...item,
          onClick: (e) => {
            onClick(e);
            patchFunction(e);
          },
        };
      }),
    };
  });
};

export default patchMenuItemsWithClickCallback;
