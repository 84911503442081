import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { dialogs } from '@yola/ws-sdk';
import AiAlertDialog from '../components/ai-alert-dialog';

const AiSuccessContainer = ({ captions }) => {
  const dispatch = useDispatch();

  const handleSubmit = () => dispatch(dialogs.actions.hide());

  return (
    <AiAlertDialog
      captions={captions}
      imageSrc="/images/ai/success-icon.png"
      buttonProps={{ appearance: 'accent', format: 'solid' }}
      onSubmit={handleSubmit}
    />
  );
};

AiSuccessContainer.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string.isRequired,
  }).isRequired,
};

export default AiSuccessContainer;
