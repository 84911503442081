import { view } from '@yola/ws-sdk';
import showHighlighterAction from '../actions/show';
import hideHighlighterAction from '../actions/hide';

const WS_COUNTER_TAG_NAME = 'WS-COUNTER';
const SHOW_HIGHLIGHTER_TIMEOUT = 120;
let highlighterTimer;
let wsCounterTimer;

const focusElementHighlighter = (store) => (next) => (action) => {
  const state = store.getState();
  const currentFocusedElementId = view.selectors.getFocusedElement(state);

  switch (action.type) {
    case view.actionTypes.SET_FOCUSED_ELEMENT: {
      const { elementId: focusedElementId } = action.payload;
      const focusedElement = view.accessors.getLiveElement(focusedElementId);

      if (focusedElementId && currentFocusedElementId !== focusedElementId) {
        const currentSelectedElementId = view.selectors.getSelectedElement(state);
        const currentHoveredElementId = view.selectors.getHoveredElement(state);
        if (currentSelectedElementId || currentHoveredElementId) {
          store.dispatch(hideHighlighterAction());
        }

        // We do not need to show highlighter to prevent blinking
        // if user click on image inside text as we automatically blur focused element in that case
        highlighterTimer = setTimeout(() => {
          store.dispatch(showHighlighterAction([focusedElement]));
        }, SHOW_HIGHLIGHTER_TIMEOUT);
      }
      next(action);
      return;
    }
    case view.actionTypes.SET_SELECTION: {
      const currentFocusedElement = view.accessors.getLiveElement(currentFocusedElementId);

      // `ws-counter` site component has a behavior which sometimes delays proper
      // highlighter size calculation. The issue: https://git.io/JfPwc
      // To overcome this, we need to force re-render highlighter in such cases.
      if (currentFocusedElement && currentFocusedElement.tagName === WS_COUNTER_TAG_NAME) {
        wsCounterTimer = setTimeout(() => {
          store.dispatch(showHighlighterAction([currentFocusedElement], { forceUpdate: true }));
        });
      }
      next(action);
      return;
    }
    case view.actionTypes.BLUR_FOCUSED_ELEMENT: {
      const hideHiglighterForElements = [];

      if (currentFocusedElementId) {
        const currentFocusedElement = view.accessors.getLiveElement(currentFocusedElementId);
        hideHiglighterForElements.push(currentFocusedElement);
      }

      store.dispatch(hideHighlighterAction(hideHiglighterForElements));
      clearTimeout(wsCounterTimer);
      clearTimeout(highlighterTimer);
      next(action);
      return;
    }
    default:
      next(action);
  }
};

export default focusElementHighlighter;
