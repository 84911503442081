import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { designSystem } from '@yola/ws-ui';

const { Icon, Paragraph } = designSystem;

function PageManagerTemplateItem({ item, captions, onItemClick }) {
  // We render controls only when we hover item
  // 'cause if we render all of the controls for
  // all items then browser will run out of memory
  // 'cause of plenty `<svg>` tags on the page.
  // (case with a lot of created pages)
  const [isHovered, setHoverState] = useState(false);

  const onItemClickHandler = useCallback(() => {
    onItemClick(item);
  }, [onItemClick, item]);

  if (!item) return null;

  const className = classNames('ws-page-manager__item');

  return (
    <div
      className={className}
      onClick={onItemClickHandler}
      onMouseEnter={() => setHoverState(true)}
      onMouseLeave={() => setHoverState(false)}
    >
      <Paragraph isClipped>
        <span className="ws-page-manager__item-logo">
          <Icon glyph="page" size={20} />
        </span>
        <span translate="no">{item.name}</span>
      </Paragraph>
      <div className="ws-page-manager__item-controls">
        {isHovered ? (
          <span className="ws-page-manager__template-control">
            <Icon glyph="ai" size={20} type="accent" />

            {captions.hoveredControl && (
              <Paragraph appearance="accent">{captions.hoveredControl}</Paragraph>
            )}
          </span>
        ) : (
          <Icon glyph="ai" size={20} type="accent" />
        )}
      </div>
    </div>
  );
}

PageManagerTemplateItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  captions: PropTypes.shape({
    hoveredControl: PropTypes.string,
  }),
  onItemClick: PropTypes.func.isRequired,
};

PageManagerTemplateItem.defaultProps = {
  captions: {},
};

export default PageManagerTemplateItem;
