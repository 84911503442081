import React from 'react';
import PropTypes from 'prop-types';
import clonedeep from 'lodash.clonedeep';
import { dialogs, i18next, linkEditing } from '@yola/ws-sdk';
import { TreeView } from '@yola/ws-ui';
import LinkSettingContainer from 'src/js/modules/common/containers/link-setting-container';
import dialogTypes from '../../../../dialogs/constants/dialog-types';
import commonHelpers from '../../../common/helpers';

const { getItemClickAction } = commonHelpers;
const { linkTypes } = linkEditing.constants;

class ItemSettingsDialog extends React.Component {
  constructor(props) {
    super(props);
    const { items, id, initialConfig } = props;
    this.state = {
      isOverlayClicked: false,
    };
    this.index = items.findIndex((item) => item.id === id);
    this.item = items[this.index];
    this.config = initialConfig || this.item?.config || linkEditing.constants.defaultConfig;

    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.applyChanges = this.applyChanges.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.setIsOverlayClicked = this.setIsOverlayClicked.bind(this);
  }

  onCancel({ offsetX, offsetY }) {
    const { items, onDialogCancel } = this.props;
    const modalPosition = { offsetX, offsetY };

    onDialogCancel();
    this.closeDialog(items, modalPosition);
  }

  setIsOverlayClicked(value) {
    this.setState({
      isOverlayClicked: value,
    });
  }

  closeDialog(items, { offsetX, offsetY }) {
    const { showDialog } = this.props;
    dialogs.operations.hide();
    showDialog(items, dialogTypes.NAVIGATION_SETTINGS_DIALOG, { offsetX, offsetY });
  }

  applyChanges(title, config, modalPosition) {
    const { items } = this.props;

    const updatedItems = clonedeep(items);
    const isLinkExist = this.index !== -1;
    const linkOptions = isLinkExist ? updatedItems[this.index] : {};

    linkOptions.title = title;
    linkOptions.config = config;

    if (!isLinkExist) {
      const id = items.length ? Math.max(...items.map((item) => item.id)) + 1 : 1;
      const order = items.length ? `${parseInt(items[items.length - 1].order, 10) + 1}` : '1';

      linkOptions.id = id;
      linkOptions.order = order;

      updatedItems.push(linkOptions);
    }

    this.closeDialog(updatedItems, modalPosition);
  }

  handleSubmit({ title, offsetX, offsetY, config, customPath, setDownloadUrlAdjusted }) {
    const { elementId, onDialogMainAction } = this.props;
    const { isOverlayClicked } = this.state;

    onDialogMainAction({
      elementId,
      triggerId: isOverlayClicked ? 'dialog-background' : 'submit-button',
      itemClickAction: getItemClickAction(config.linkType),
      itemTitleAdjusted: this.item?.title !== title,
      dialogType: dialogTypes.NAVIGATION_ITEM_SETTINGS_DIALOG,
      addedFile: config.linkType === linkTypes.DOWNLOAD,
      customPath,
      setDownloadUrlAdjusted,
    });

    this.applyChanges(title, config, { offsetX, offsetY });
  }

  render() {
    const { offsetX, offsetY, messages, initialTitle, currentConfig } = this.props;

    return (
      <LinkSettingContainer
        currentConfig={currentConfig}
        initialConfig={this.config}
        initialTitle={initialTitle || this.item?.title || ''}
        sourceDialog={{
          id: dialogTypes.NAVIGATION_ITEM_SETTINGS_DIALOG,
          props: this.props,
        }}
        handleCancel={this.onCancel}
        handleSubmit={this.handleSubmit}
        offsetX={offsetX}
        offsetY={offsetY}
        setIsOverlayClicked={this.setIsOverlayClicked}
        captions={{
          dialogTitle: messages.title,
          actionTitle: i18next.t('Click action'),
          actionSubtitle: i18next.t('When user clicks on the link, take this action'),
          title: i18next.t('Title'),
          titleAnnotation: i18next.t('Display this name in website navigation'),
          save: messages.save,
        }}
      />
    );
  }
}

ItemSettingsDialog.propTypes = {
  elementId: PropTypes.string.isRequired,
  offsetX: PropTypes.number.isRequired,
  offsetY: PropTypes.number.isRequired,
  messages: PropTypes.shape({
    title: PropTypes.string,
    save: PropTypes.string,
  }).isRequired,
  showDialog: PropTypes.func.isRequired,
  items: TreeView.propTypes.items.isRequired,
  id: PropTypes.number,
  initialTitle: PropTypes.string,
  initialConfig: PropTypes.shape({}),
  currentConfig: PropTypes.shape({}),
  // Passed from ws-editor's `dialogs-container`:
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

ItemSettingsDialog.defaultProps = {
  id: null,
  initialTitle: null,
  initialConfig: null,
  currentConfig: null,
};

export default ItemSettingsDialog;
