const GOOGLE_ANALYTICS_TRACKING_ID_PATTERNS = {
  'G-': /^G-[\w\d]{10}$/,
  'UA-': /^UA-\d{5,10}-\d{1,2}$/,
  'GTM-': /^GTM-[\w\d]{5,10}$/,
  'GT-': /^GT-[\w\d]{5,10}$/,
  'AW-': /^AW-[\w\d]{5,10}$/,
  'DC-': /^DC-[\w\d]{5,10}$/,
};

const isGoogleAnalyticsIdValid = (id) => {
  const isValid = Object.values(GOOGLE_ANALYTICS_TRACKING_ID_PATTERNS).some((pattern) =>
    pattern.test(id)
  );

  return isValid;
};

export default isGoogleAnalyticsIdValid;
