import bowser from 'yola-bowser';

const detect = () => {
  if (bowser.ios || bowser.iPadOS) {
    document.documentElement.classList.add('ios');
  }
};

export default {
  detect,
};
