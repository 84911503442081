import { config } from '@yola/ws-sdk';
import get from 'lodash.get';

const getSegmentIoKey = (rootState) => {
  const cfg = config.selectors.getConfig(rootState);

  return get(cfg, 'ws-editor.segmentio.write_key');
};

export default getSegmentIoKey;
