import React from 'react';

const AttachFileIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="144" height="90" viewBox="0 0 144 90" fill="none">
    <path
      d="M100 64.4444C100 65.9179 99.41 67.3309 98.3598 68.3728C97.3096 69.4147 95.8852 70 94.4 70H49.6C48.1148 70 46.6904 69.4147 45.6402 68.3728C44.59 67.3309 44 65.9179 44 64.4444V25.5556C44 24.0821 44.59 22.6691 45.6402 21.6272C46.6904 20.5853 48.1148 20 49.6 20H61.4687C62.8009 20 64.0457 20.6632 64.7887 21.769L68.0113 26.5644C68.7543 27.6701 69.9991 28.3333 71.3313 28.3333H94.4C95.8852 28.3333 97.3096 28.9186 98.3598 29.9605C99.41 31.0024 100 32.4155 100 33.8889V64.4444Z"
      fill="#DADDE0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M70.5109 44.1217V57.4994C70.5109 58.3281 71.1786 59 72.0023 59C72.826 59 73.4937 58.3281 73.4937 57.4994V44.1264L78.454 49.1173C79.0364 49.7033 79.9807 49.7033 80.5632 49.1173C81.1456 48.5313 81.1456 47.5811 80.5632 46.9951L73.1107 39.4966C72.8378 39.1917 72.4423 39 72.0023 39C71.8564 39 71.7154 39.0211 71.5821 39.0604C71.349 39.1286 71.1292 39.2552 70.9454 39.4401L63.4368 46.9951C62.8544 47.5811 62.8544 48.5313 63.4368 49.1173C64.0193 49.7033 64.9636 49.7033 65.546 49.1173L70.5109 44.1217Z"
      fill="#646C75"
    />
  </svg>
);

export default AttachFileIcon;
