import { site } from '@yola/ws-sdk';
import emptySettings from '../constants/empty-settings';
import showEditor from '../actions/show-editor';

const initialEditorRender = (store) => (next) => (action) => {
  next(action);

  if (action.type === site.actionTypes.CONNECT_SITE) {
    const state = store.getState();
    const settings = site.selectors.getSettings(state);
    const { mainLocale } = action.payload.site.meta;

    const activePage =
      site.selectors.getActivePageId(state) || site.selectors.getHomePage(state, mainLocale).id;

    if (!settings || !Object.keys(settings).length) {
      const updateGlobals = false;
      store.dispatch(site.actions.updateSettings(emptySettings, updateGlobals));
    }

    store.dispatch(site.actions.setActivePage(activePage));

    store.dispatch(showEditor());
  }
};

export default initialEditorRender;
