const isMediaFitsInContainerByWidth = (
  mediaWidth,
  mediaHeight,
  containerWidth,
  containerHeight
) => {
  const mediaFitsByWidth = containerWidth / mediaWidth >= containerHeight / mediaHeight;

  return mediaFitsByWidth && containerWidth / 2 > mediaWidth;
};

const isMediaFitsInContainerByHeight = (
  mediaWidth,
  mediaHeight,
  containerWidth,
  containerHeight
) => {
  const mediaFitsByHeight = containerHeight / mediaHeight > containerWidth / mediaWidth;

  return mediaFitsByHeight && containerHeight / 2 > mediaHeight;
};

export { isMediaFitsInContainerByWidth, isMediaFitsInContainerByHeight };
