import generateItemId from './generate-item-id';
import getDataFromNode from './get-data-from-node';

const getDataFromNodes = (itemsArray = []) =>
  itemsArray.map((item, index) => {
    const id = generateItemId(index, Date.now());

    return {
      id,
      ...getDataFromNode(item),
    };
  });

export default getDataFromNodes;
