export default [
  'Cvv',
  'Login',
  'Password',
  'Credit number',
  'Bank Account',
  'Online Bank',
  'Recovery phrase',
  'Secret',
  'IC number',
  'Private key',
];
