import React from 'react';
import { extensions } from '@yola/ws-sdk';
import focalPoint from 'src/js/modules/focal-point';
import UploadImageTriggerContainer from '../containers/triggers/upload-image-trigger-container';
import config from '../constants/config';
import getOverlappedMediaContainerByText from '../helpers/get-overlapped-media-container-by-text';

const { triggerIds: ids } = focalPoint.constants;

const uploadImageControl = {
  id: ids.REGULAR_IMAGE_UPLOAD_TRIGGER,
  trigger: <UploadImageTriggerContainer id={ids.REGULAR_IMAGE_UPLOAD_TRIGGER} />,
  onMouseDown: (e) => e.stopPropagation(),
  priority: 60,
  closeIcon: 'submit',
  matches(liveElement) {
    const settings = extensions.accessors.getExtensionSettings(config.slug);

    if (liveElement.matches(settings.imageContainer)) {
      return Boolean(liveElement.querySelector('img'));
    }

    const overlappedMediaContainer = getOverlappedMediaContainerByText(liveElement);

    if (overlappedMediaContainer) {
      return Boolean(overlappedMediaContainer.querySelector('img'));
    }

    return false;
  },
  highlightElement: false,
};

export default uploadImageControl;
