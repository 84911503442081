import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { hooks, serviceClient } from '@yola/ws-sdk';
import { ViewportConsumer } from 'yola-editor/src/js/utils/viewport-provider';
import cookieStoreChangeListener from 'yola-editor/src/js/utils/cookie-store-change-listener';
import ViewOverlayContainer from 'src/js/modules/view-overlay/containers/view-overlay-container';
import NavbarContainer from 'src/js/modules/navbar/containers/navbar-container';
import EmptyPageTriggerContainer from 'src/js/modules/blocks/containers/empty-page-trigger-container';
import ToolsContainer from 'src/js/modules/tools/containers/tools-container';
import HighlighterContainer from 'src/js/modules/highlighter/containers/highlighter-container';
import FloatingTriggerContainer from 'src/js/modules/blocks/containers/floating-trigger-container';
import SiteProgressFloatingButtonContainer from 'src/js/modules/site-progress-checklist/containers/floating-action-button-container';
import ControlPaneContainer from 'src/js/modules/control-pane/containers/control-pane-container';
import BlockControlPaneContainer from 'src/js/modules/control-pane/containers/block-control-pane-container';
import DropGuideContainer from 'src/js/modules/drag-n-drop/containers/drop-guide-container';
import TooltipContainer from 'src/js/modules/tooltip/containers/tooltip-container';
import ElementDragLayerContainer from 'src/js/modules/drag-n-drop/containers/element-drag-layer-container';
import ViewLoaderContainer from 'src/js/modules/view-loader/containers/view-loader-container';
import CustomToolsContainer from 'src/js/modules/custom-tools/containers/custom-tools-container';
import TextResizer from 'src/js/modules/text-resizer/containers/text-resizer';
import editor from 'src/js/modules/editor';
import MousePositionContext from 'src/js/modules/common/components/mouse-position-context';
import { UserInputProvider } from 'src/js/modules/common/components/user-input-context';
import getDisabledStatus from 'src/js/modules/view/selectors/disabled-status';
import ViewContainer from 'src/js/modules/view/containers/view-container';
import useContextMenu from 'src/js/modules/context-menu/components/use-context-menu';
import InteractiveToolContainer from 'src/js/modules/interactive-tool/containers/interactive-tool-container';
import useAddWidgetEvent from 'src/js/modules/widgets/hooks/use-add-widget-event';
import getWsBlockInjectedTranslations from 'src/js/modules/blocks/helpers/get-ws-block-injected-translations';
import AnchorHighlighterContainer from 'src/js/modules/highlighter/containers/anchor-highlighter-container';
import injectTranslationsIntoLiveDoc from '../helpers/inject-translations-into-live-doc';
import injectEditorColors from '../helpers/inject-editor-colors';
import setLiveDocLangAttribute from '../helpers/set-live-doc-lang-attribute';
import initWSEditor from '../helpers/init-ws-editor';

function EditorContainer() {
  const isEditorShown = useSelector(editor.selectors.getVisibilityState);
  const disabled = useSelector(getDisabledStatus);

  useAddWidgetEvent(document);
  useContextMenu();

  useEffect(() => {
    const client = serviceClient.get();

    if (client) {
      // This is a temporary code used to collect error statistics of removing "Yola" cookie
      // More details here in the ticket: https://github.com/yola/ws-editor/issues/4252
      const YOLA_COOKIE = 'Yola';
      cookieStoreChangeListener.add([YOLA_COOKIE]);
      initWSEditor(client);
    }

    return () => {
      cookieStoreChangeListener.remove();
    };
  }, []);

  useEffect(() => {
    const { ON_LIVE_DOCUMENT_COMPOSED, ON_LIVE_DOCUMENT_CONNECTED } = hooks.availableHooks;

    const onLiveDocumentComposedHandler = (liveDoc) => {
      injectTranslationsIntoLiveDoc(liveDoc, getWsBlockInjectedTranslations());

      setLiveDocLangAttribute(liveDoc);
    };

    const onLiveDocumentConnectedHandler = (liveDoc) => {
      injectEditorColors(liveDoc);
    };

    hooks.operations.subscribe(ON_LIVE_DOCUMENT_COMPOSED, onLiveDocumentComposedHandler);

    hooks.operations.subscribe(ON_LIVE_DOCUMENT_CONNECTED, onLiveDocumentConnectedHandler);

    return () => {
      hooks.operations.unsubscribe(ON_LIVE_DOCUMENT_COMPOSED, onLiveDocumentComposedHandler);
      hooks.operations.unsubscribe(ON_LIVE_DOCUMENT_CONNECTED, onLiveDocumentConnectedHandler);
    };
  }, []);

  if (!isEditorShown) return null;

  const viewWrapperClass = classNames('ws-view-wrapper', {
    'ws-view-wrapper--disabled': disabled,
  });

  return (
    <UserInputProvider>
      <div className={viewWrapperClass}>
        <ViewContainer />
        <ViewOverlayContainer>
          <NavbarContainer />
          <EmptyPageTriggerContainer />
          <ToolsContainer>
            <MousePositionContext.Provider>
              <MousePositionContext.Consumer>
                {(mousePosition) => (
                  <ViewportConsumer>
                    {({ width, height }) => (
                      <React.Fragment>
                        <HighlighterContainer viewportHeight={height} />
                        <TextResizer />
                        <AnchorHighlighterContainer />
                        <FloatingTriggerContainer
                          viewportHeight={height}
                          mousePosition={mousePosition}
                        />
                        <BlockControlPaneContainer viewportWidth={width} viewportHeight={height} />
                        <ControlPaneContainer
                          viewportWidth={width}
                          viewportHeight={height}
                          mousePosition={mousePosition}
                        />
                        <SiteProgressFloatingButtonContainer />
                      </React.Fragment>
                    )}
                  </ViewportConsumer>
                )}
              </MousePositionContext.Consumer>
            </MousePositionContext.Provider>
            <CustomToolsContainer />
            <DropGuideContainer />
            <InteractiveToolContainer />
          </ToolsContainer>
          <ElementDragLayerContainer />
        </ViewOverlayContainer>
        <ViewLoaderContainer />
        <TooltipContainer />
      </div>
    </UserInputProvider>
  );
}

export default EditorContainer;
