import actionTypes from '../constants/action-types';

const controlPaneLocation = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SET_LOCATION: {
      return {
        ...state,
        ...action.payload.location,
      };
    }

    default: {
      return state;
    }
  }
};

export default controlPaneLocation;
