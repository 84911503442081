import { config } from '@yola/ws-sdk';
import getIsB2C from 'src/js/modules/config/selectors/is-b2c';
import get from 'lodash.get';

const getEnableCopyPasteInstructionUrl = (rootState) => {
  const cfg = config.selectors.getConfig(rootState);

  if (getIsB2C(rootState)) {
    return get(cfg, 'common.ws-helpcenter.enable_copy_paste_instruction_url');
  }

  return get(cfg, 'common.ws-helpcenter.enable_copy_paste_instruction_wl_url');
};

export default getEnableCopyPasteInstructionUrl;
