let isOnline = window.navigator.onLine;

const network = {
  get nativeOnline() {
    return window.navigator.onLine;
  },
  get online() {
    return isOnline;
  },
  registerOnlineCallback(callback, options = {}) {
    const { initialExecution } = options;

    if (initialExecution) {
      if (network.online) {
        callback();
      }
    }

    const setOnline = () => {
      isOnline = true;
      callback();
    };

    window.addEventListener('online', setOnline);

    return () => {
      window.removeEventListener('online', setOnline);
    };
  },
  registerOfflineCallback(callback, options = {}) {
    const { initialExecution } = options;

    if (initialExecution) {
      if (!network.online) {
        callback();
      }
    }

    const setOffline = () => {
      isOnline = false;
      callback();
    };

    window.addEventListener('offline', setOffline);

    return () => {
      window.removeEventListener('offline', setOffline);
    };
  },
};

export default network;
