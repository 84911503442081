import accessors from './accessors';
import actions from './actions';
import constants from './constants';
import reducers from './reducers';
import selectors from './selectors';
import middleware from './middleware';
import verifiers from './verifiers';
import thunks from './thunks';

export default {
  accessors,
  actions,
  constants,
  reducers,
  selectors,
  middleware,
  verifiers,
  thunks,
};
