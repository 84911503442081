import actionTypes from '../constants/action-types';

const isShown = (state = false, action) => {
  if (action.type === actionTypes.SHOW_EDITOR) {
    return true;
  }

  if (action.type === actionTypes.HIDE_EDITOR) {
    return false;
  }

  return state;
};

export default isShown;
