export default {
  CENTER_LEFT: 'center-left',
  CENTER_CENTER: 'center',
  CENTER_RIGHT: 'center-right',
  TOP_CENTER: 'top-center',
  BOTTOM_CENTER: 'bottom-center',
  TOP_LEFT: 'top-left',
  TOP_RIGHT: 'top-right',
  BOTTOM_LEFT: 'bottom-left',
  BOTTOM_RIGHT: 'bottom-right',
};

export const directions = {
  TOP_BOTTOM: 'top-bottom',
  LEFT_RIGHT: 'left-right',
  TOP_LEFT: 'top-left',
  TOP_RIGHT: 'top-right',
  BOTTOM_LEFT: 'bottom-left',
  BOTTOM_RIGHT: 'bottom-right',
  ALL: 'all',
};
