import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Modal, Panel, PanelGroup, Text, designSystem } from '@yola/ws-ui';
import { i18next } from '@yola/ws-sdk';
import { APPEARANCE_TAB_ID, DIALOG_HEIGHT, DIALOG_PANEL_HEIGHT, DIALOG_WIDTH } from '../constants';
import ElementSettingsAppearance from './element-settings-appearance';

const {
  Tabs,
  TabsList,
  Tab,
  TabsContentList,
  TabContent,
  Accordion,
  AccordionContent,
  AccordionGroup,
  AccordionHeader,
} = designSystem;

function ElementSettingsDialog({
  captions,
  settings,
  offsetX,
  offsetY,
  onSubmit,
  onCancel,
  onDragEnd,
}) {
  const customTabs = useMemo(
    () =>
      settings.reduce((result, setting) => {
        if (setting.tabs) {
          const custom = setting.tabs.filter((tab) => tab.id !== APPEARANCE_TAB_ID);
          return result.concat(custom);
        }

        return result;
      }, []),
    [settings]
  );

  const appearanceTabs = useMemo(
    () =>
      settings.reduce((result, setting) => {
        if (setting.tabs) {
          const custom = setting.tabs
            .filter((tab) => tab.id === APPEARANCE_TAB_ID)
            .map((tab) => ({
              ...tab,
              elementId: setting.elementId,
              id: `${setting.id}-${tab.id}`,
            }));
          return result.concat(custom);
        }

        return result;
      }, []),
    [settings]
  );
  const initialTab = customTabs.length > 0 ? customTabs[0].id : APPEARANCE_TAB_ID;
  const [activeTab, setActiveTab] = useState(initialTab);

  return (
    <Modal
      draggables
      resizable={false}
      fullscreen={false}
      className="ws-element-settings__modal"
      width={DIALOG_WIDTH}
      height={DIALOG_HEIGHT}
      minWidth={320}
      minHeight={200}
      offsetX={offsetX}
      offsetY={offsetY}
      dragHandleSelector=".ws-drag-trigger"
      handleCancel={onCancel}
      handleSubmit={onSubmit}
      onDragEnd={onDragEnd}
    >
      <PanelGroup height="100%">
        <Panel
          height={DIALOG_PANEL_HEIGHT}
          theme="gray-100"
          corners="squared"
          align="middle"
          className="ws-drag-trigger ws-block-layout-settings__title"
        >
          <Text type="heading-6">{captions.title}</Text>
        </Panel>
        <Panel
          className="ws-block-layout-settings__main"
          height={`calc(100% - 2 * ${DIALOG_PANEL_HEIGHT}px)`}
        >
          <Tabs
            activeTabId={activeTab}
            appearance="medium"
            onTabChange={setActiveTab}
            disableSwipeableContent
            skipRenderInactiveContent
            noBackground
            noSideSpacing
            noVerticalSpacing
          >
            <TabsList variant="scrollable">
              {customTabs.map((tab) => (
                <Tab tabId={tab.id} label={tab.title} />
              ))}
              <Tab tabId={APPEARANCE_TAB_ID} label={i18next.t('Appearance')} />
            </TabsList>

            <TabsContentList>
              {customTabs.map((tab) => (
                <TabContent tabId={tab.id}>{tab.component}</TabContent>
              ))}

              <TabContent tabId={APPEARANCE_TAB_ID}>
                <Accordion type="all" appearance="grey" defaultActiveItem={appearanceTabs[0].id}>
                  {appearanceTabs.map((item) => (
                    <AccordionGroup key={item.id} itemId={item.id}>
                      <AccordionHeader title={item.accordionTitle.toUpperCase()} />
                      <AccordionContent>
                        <ElementSettingsAppearance item={item} />
                      </AccordionContent>
                    </AccordionGroup>
                  ))}
                </Accordion>
              </TabContent>
            </TabsContentList>
          </Tabs>
        </Panel>
        <Panel
          height={DIALOG_PANEL_HEIGHT}
          corners="squared"
          className="ws-block-layout-settings__footer"
        >
          <ButtonGroup stick="top" block>
            <Button stretch="block" size="large" onClick={onSubmit}>
              {captions.buttons.submit}
            </Button>
            <Button stretch="block" size="large" onMouseDown={onCancel}>
              {captions.buttons.cancel}
            </Button>
          </ButtonGroup>
        </Panel>
      </PanelGroup>
    </Modal>
  );
}

ElementSettingsDialog.propTypes = {
  offsetX: PropTypes.number.isRequired,
  offsetY: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  captions: PropTypes.shape().isRequired,
  settings: PropTypes.shape().isRequired,
};

export default ElementSettingsDialog;
