import { saving, dialogs, integration } from '@yola/ws-sdk';
import store from 'src/js/store';
import saveSite from 'src/js/modules/saving/helpers/save-site';

const AUTO_SAVING_DELAY = 120000; // 2 minutes
let autoSavingInterval = null;

const saveWebsite = () => {
  const state = store.getState();
  const savingStatus = saving.selectors.getStatus(state);
  const currentDialog = dialogs.selectors.getDialog(state);
  const integrationSettings = integration.selectors.getSettings(state);
  const { isDialogVisible: isYolaDialogVisible, isNetworkOnline } = integrationSettings.ui;

  const { UNSAVED, ERROR } = saving.constants.statuses;

  const isDialogNotOpened = !currentDialog.type;
  const isSiteNotSaved = savingStatus === UNSAVED || savingStatus === ERROR;

  if (isSiteNotSaved && isDialogNotOpened && !isYolaDialogVisible && isNetworkOnline) {
    saveSite();
  }
};

export const startAutoSaving = () => {
  autoSavingInterval = setInterval(saveWebsite, AUTO_SAVING_DELAY);
};

export const stopAutoSaving = () => {
  clearInterval(autoSavingInterval);
};
