import { contrastRatio, HSLColor } from '@yola/yola-palette-generator';
import { customization, textFormatting } from '@yola/ws-sdk';
import parseHslColorString from './parse-hsl-color-string';
import constants from '../constant/custom-color-options';
import parseHslaColorString from './parse-hsla-color-string';
import getBlockSurfaceName from './get-block-surface-name';
import getBlockSurfaceBackground from './get-block-surface-background';
import getSurfaceLinkColor from './get-surface-link-color';

export const parseColor = (color) =>
  color.includes('hsla') ? parseHslaColorString(color) : parseHslColorString(color);

export const isSuitableColor = (targetHSLObject, whiteHSLObject, surfaceHSLObject) => {
  const isLight = contrastRatio(targetHSLObject, whiteHSLObject) < constants.LIGHT_CR;
  const acceptableCR = isLight ? constants.ACCEPTABLE_LIGHT_CR : constants.ACCEPTABLE_DARK_CR;
  return contrastRatio(targetHSLObject, surfaceHSLObject) > acceptableCR;
};

const getCustomTextColor = (
  textVariableName,
  surfaceName,
  palette = customization.accessors.getCurrentColorPalette()
) => {
  const rootVariableName = textFormatting.helpers.getCustomColorFromTextColor(textVariableName);
  const rootCssVariablesMap = textFormatting.helpers.getCssRootVariablesMap();
  const targetColor = rootCssVariablesMap[rootVariableName];
  const whiteColor = `hsl(${palette.variables.wsColorTextWhite})`;
  const blackColor = `hsl(${palette.variables.wsColorTextBlack})`;

  if (!targetColor) return '';
  const targetHSLObject = new HSLColor(...Object.values(parseColor(targetColor)));
  const whiteHSLObject = new HSLColor(...Object.values(parseHslColorString(whiteColor)));

  const surfaceCapitalizedName = getBlockSurfaceName(surfaceName);
  const surfaceColor = getBlockSurfaceBackground(surfaceCapitalizedName, palette.variables);
  const surfaceHSLObject = new HSLColor(...Object.values(parseColor(surfaceColor)));

  const isSuitable = isSuitableColor(targetHSLObject, whiteHSLObject, surfaceHSLObject);
  const cssVariable = `var(${rootVariableName})`;

  if (isSuitable) {
    return cssVariable;
  }

  const { HSLObject, CSSValue } = getSurfaceLinkColor(
    { surfaceName, surfaceColor },
    { targetColor, blackColor, whiteColor }
  );

  // Here we handle case where the color of the surface link is unsuitable due to the contrast ratio
  // This can happen if the user has changed the colors of the palette to a shade that is not recommended (for example: light color to dark)
  // So, if surface link color is not suitable, we apply default color
  const isLinkColorSuitable = isSuitableColor(HSLObject, whiteHSLObject, surfaceHSLObject);

  return isLinkColorSuitable ? CSSValue : cssVariable;
};

export default getCustomTextColor;
