import actionTypes from '../constant/action-types';

const switchColorPalette = (colorPalette = null) => ({
  type: actionTypes.SWITCH_COLOR_PALETTE,
  payload: {
    colorPalette,
  },
});

export default switchColorPalette;
