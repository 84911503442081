import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { integration } from '@yola/ws-sdk';
import { designSystem } from '@yola/ws-ui';

const { EditorHeader } = designSystem;
const DEFAULT_LOGO_WIDTH = 49;

function BrandContainer(props) {
  const { logo, width, height } = props;

  return <EditorHeader.Logo src={logo} width={width} height={height} alt="logo" />;
}

BrandContainer.propTypes = {
  logo: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

BrandContainer.defaultProps = {
  logo: '/images/yola-logo-2x.png',
  width: DEFAULT_LOGO_WIDTH,
  height: 'auto',
};

const mapStateToProps = (state) => {
  const settings = integration.selectors.getSettings(state);

  if (settings && settings.branding) {
    const { companyLogo, companyLogoWidth } = settings.branding;

    return {
      logo: companyLogo,
      width: companyLogoWidth,
    };
  }

  return {};
};

export default connect(mapStateToProps, null)(BrandContainer);
