import store from 'src/js/store';
import get from 'lodash.get';
import { integration } from '@yola/ws-sdk';

const isFeatureAvailable = (featureName) => {
  const state = store.getState();
  const limits = integration.selectors.getLimits(state);
  const featureLimit = get(limits, featureName);

  return get(featureLimit, 'available', true);
};

export default isFeatureAvailable;
