import { view, extensions } from '@yola/ws-sdk';
import constants from '../constants';
import getGalleryBlockId from './get-gallery-block-id';

const getGalleryItems = (elementId) => {
  const { galleryItemSelector } = extensions.accessors.getExtensionSettings(constants.common.slug);
  const galleryBlockId = getGalleryBlockId(elementId);
  const galleryBlock = view.accessors.getLiveElement(galleryBlockId);
  const galleryItems = galleryBlock.querySelectorAll(galleryItemSelector);

  return Array.from(galleryItems);
};

export default getGalleryItems;
