import actionTypes from '../constants/action-types.js';

const setDragSourceElement = (elementId) => ({
  type: actionTypes.SET_SOURCE_ELEMENT,
  payload: {
    elementId,
  },
});

export default setDragSourceElement;
