import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  dialogs,
  site,
  siteLocales,
  saving,
  i18next,
  integration,
  view,
  textFormatting,
  template,
} from '@yola/ws-sdk';
import { saveSiteWithEffect } from 'src/js/modules/saving/helpers/save-site';
import helpCenter from 'yola-editor/src/js/modules/help-center';
import MultilingualSection from '../components/multilingual-section';
import dialogTypes from '../../dialogs/constants/dialog-types';
import getLanguages from '../helpers/get-languages';
import getCountries from '../helpers/get-countries';
import getUsedLanguages from '../helpers/get-used-languages';
import prepareLanguageSelectorFlagsMarkup from '../helpers/prepare-language-selector-flags-markup';
import setLanguageSelector from '../helpers/set-language-selector';
import deleteLanguageSelector from '../helpers/delete-language-selector';
import constants from '../constants';
import segment from '../../analytics/segment';
import getRelatedLanguageOptions from '../helpers/get-related-language-options';

const { tabIdentifiers } = constants;
const {
  track,
  constants: { events },
} = segment;

const showMultilingualTab = (modalProps) => {
  dialogs.operations.show(dialogTypes.WEBSITE_SETTINGS, {
    activeTabId: tabIdentifiers.MULTILINGUAL,
    ...modalProps,
  });
};

const showSavingDialog = () => {
  dialogs.operations.show(dialogTypes.UPLOAD_PROGRESS_DIALOG, {
    captions: {
      title: i18next.t('Saving Website'),
      description: i18next.t('We are saving your website. Please wait.'),
    },
    overlay: 'visible',
    showProgress: true,
    cancelable: false,
    progressType: 'indeterminate',
  });
};

class MultilingualTabContainer extends PureComponent {
  constructor(props, state) {
    super(props, state);

    this.locales = siteLocales.accessors.getSiteLocales();
    this.mainLocale = siteLocales.accessors.getMainLocale();

    this.languages = getLanguages();
    this.countries = getCountries();

    this.availableLocales = getRelatedLanguageOptions(this.locales, this.languages, this.countries);

    this.tutorialUrl = helpCenter.accessors.getMultilingualAiTutorialUrl();

    this.setMainLang = this.setMainLang.bind(this);
    this.addLanguage = this.addLanguage.bind(this);
    this.deleteLang = this.deleteLang.bind(this);
    this.handleDeleteLocale = this.handleDeleteLocale.bind(this);
    this.onAfterLanguageDeleted = this.onAfterLanguageDeleted.bind(this);
    this.handleAddLocale = this.handleAddLocale.bind(this);
    this.onAfterLanguageAdded = this.onAfterLanguageAdded.bind(this);
    this.checkForUnsafeDecorator = this.checkForUnsafeDecorator.bind(this);
  }

  async onAfterLanguageDeleted() {
    const { formData } = this.props;

    await this.updateLanguageSelector();
    showMultilingualTab({
      formData,
    });
  }

  async onAfterLanguageAdded() {
    const { showPromptForce, formData, userPreferences } = this.props;
    const isMultilingualSite = site.verifiers.isMultilingualSite();

    await this.updateLanguageSelector();

    const showMultilingualTour = () => {
      const { multilingualTourCompleted } = userPreferences;
      if (!multilingualTourCompleted) {
        dialogs.operations.show(dialogTypes.MULTILINGUAL_TOUR, {
          onClose: () => showMultilingualTab({ formData, showPromptForce }),
        });
        return;
      }

      showMultilingualTab({ formData, showPromptForce });
    };

    if (!isMultilingualSite) {
      showMultilingualTour();
      return;
    }

    showMultilingualTab({ formData, showPromptForce });
  }

  setMainLang(locale) {
    const { formData, showPromptForce } = this.props;

    dialogs.operations.show(dialogTypes.CONFIRM_DIALOG, {
      onCancel: () =>
        showMultilingualTab({
          formData,
          showPromptForce,
        }),
      onSubmit: async () => {
        showSavingDialog();
        await siteLocales.operations.saveMainLocale(locale);
        await this.updateLanguageSelector();
        const siteId = site.accessors.getSiteId();
        const templateBuildSlug = template.accessors.getBuildSlug();
        track(events.DEFAULT_WEBSITE_LANGUAGE_CHANGED, {
          siteId,
          oldLanguageCode: this.mainLocale,
          newLanguageCode: locale,
          templateBuildSlug,
        });
        showMultilingualTab({
          formData,
          showPromptForce,
        });
      },
      captions: {
        title: i18next.t('Change main language?'),
        description: i18next.t('Are you sure you want to change the main language of your site?'),
        submit: i18next.t('Submit'),
        cancel: i18next.t('Cancel'),
      },
    });
  }

  addLanguage() {
    const { requestBlockData, showPromptForce, formData } = this.props;
    const isMl = site.verifiers.isMultilingualSite();
    const isMultilingualIntroShown = site.verifiers.isMultilingualIntroShown();

    const commonProps = {
      formData,
      showPromptForce,
      locales: this.locales,
      mainLocale: this.mainLocale,
      roadmapUrl: requestBlockData && requestBlockData.publicRoadmapUrl,
    };

    const showAddLanguageModal = (initialValues) => {
      dialogs.operations.show(dialogTypes.ADD_LANGUAGE, {
        ...commonProps,
        initialValues,
        onCancel: () => {
          showMultilingualTab({ formData });
        },
        onSubmit: this.checkForUnsafeDecorator({
          onBeforeSave: this.handleAddLocale,
          onCancel: showAddLanguageModal,
          onAfterSave: this.onAfterLanguageAdded,
        }),
      });
    };

    if (!isMl && !isMultilingualIntroShown) {
      dialogs.operations.show(dialogTypes.MULTILINGUAL_INTRO, {
        onClose: showMultilingualTab,
        toContinue: () => showAddLanguageModal(),
        ...commonProps,
      });
    } else {
      showAddLanguageModal();
    }
  }

  checkForUnsafeDecorator({ onBeforeSave, onCancel, onAfterSave }) {
    const { savingStatus, formData, showPromptForce } = this.props;
    return async (...props) => {
      const { UNSAVED } = saving.constants.statuses;

      if (savingStatus !== UNSAVED) {
        showSavingDialog();

        // we need to save all changes to the server as far as we've added changes to the store underhood
        await onBeforeSave(...props);
        saveSiteWithEffect(() => {
          onAfterSave(...props);
        });
        return;
      }

      dialogs.operations.show(dialogTypes.CONFIRM_DIALOG, {
        onCancel: () => {
          if (onCancel) {
            onCancel(...props);
            return;
          }

          showMultilingualTab({
            formData,
            showPromptForce,
          });
        },
        onSubmit: async () => {
          showSavingDialog();
          await saveSiteWithEffect();
          await onBeforeSave(...props);
          saveSiteWithEffect(() => {
            onAfterSave(...props);
          });
        },
        captions: {
          title: i18next.t('Save site?'),
          description: i18next.t('Your site has unsaved changes. Save them to proceed.'),
          submit: i18next.t('Submit'),
          cancel: i18next.t('Cancel'),
        },
      });
    };
  }

  async handleAddLocale(values) {
    const { copyCustomColorsCSSRulesForBlocks } = this.props;
    const siteId = site.accessors.getSiteId();
    const templateBuildSlug = template.accessors.getBuildSlug();
    const { destinationLocale, originLocale } = values;
    const isMultilingualSite = site.verifiers.isMultilingualSite();
    const { changes } = await siteLocales.operations.addLocale(
      { destinationLocale, originLocale },
      isMultilingualSite
    );

    const blocksIdsReferenceMap = changes && changes.blockIdsReplaceMap;

    if (blocksIdsReferenceMap) {
      copyCustomColorsCSSRulesForBlocks(changes.blockIdsReplaceMap);
    }

    track(events.WEBSITE_LANGUAGE_ADDED, {
      siteId,
      languageCode: destinationLocale,
      templateBuildSlug,
    });
  }

  async handleDeleteLocale(languageCode) {
    const siteId = site.accessors.getSiteId();
    const templateBuildSlug = template.accessors.getBuildSlug();
    const { removeCustomColorsCSSRulesForBlocks } = this.props;

    const { deletionInfo } = await siteLocales.operations.removeLocale(languageCode);

    const blocksIdsToRemove =
      deletionInfo && deletionInfo.pageBlocks && Object.values(deletionInfo.pageBlocks).flat();

    if (blocksIdsToRemove.length > 0) {
      removeCustomColorsCSSRulesForBlocks(blocksIdsToRemove);
    }

    track(events.WEBSITE_LANGUAGE_DELETED, {
      siteId,
      languageCode,
      templateBuildSlug,
    });
  }

  deleteLang(languageCode) {
    const { formData, showPromptForce } = this.props;

    dialogs.operations.show(dialogTypes.CONFIRM_DIALOG, {
      onCancel: () =>
        showMultilingualTab({
          formData,
          showPromptForce,
        }),
      onSubmit: this.checkForUnsafeDecorator({
        onBeforeSave: async () => {
          await this.handleDeleteLocale(languageCode);
        },
        onAfterSave: this.onAfterLanguageDeleted,
      }),
      captions: {
        title: i18next.t('Delete language?'),
        description: i18next.t(
          "You're about to delete a website language. All related content and pages will be deleted. Are you sure?"
        ),
        submit: i18next.t('Submit'),
        cancel: i18next.t('Cancel'),
      },
      height: 245,
    });
  }

  async updateLanguageSelector() {
    const { forceReload } = this.props;
    const mainLocale = siteLocales.accessors.getMainLocale();
    const locales = siteLocales.accessors.getSiteLocales();
    const languages = getLanguages();
    const countries = getCountries();
    const usedLanguages = getUsedLanguages(locales, languages, countries);

    if (usedLanguages.length > 1) {
      const languageSelectorMarkup = await prepareLanguageSelectorFlagsMarkup(usedLanguages);
      await setLanguageSelector(languageSelectorMarkup, mainLocale);
    } else {
      await deleteLanguageSelector();
    }
    forceReload();
  }

  render() {
    const { formData, isMobile, captions, areAiFeaturesAvailable } = this.props;
    const usedLanguages = getUsedLanguages(this.locales, this.languages, this.countries);

    return (
      <MultilingualSection
        usedLanguages={usedLanguages}
        mainLocale={this.mainLocale}
        isMobile={isMobile}
        deleteLang={this.deleteLang}
        setMainLang={this.setMainLang}
        addLanguage={this.addLanguage}
        captions={captions}
        formData={formData}
        hasAbilityToAddLocale={Boolean(this.availableLocales.length)}
        areAiFeaturesAvailable={areAiFeaturesAvailable}
        tutorialUrl={this.tutorialUrl}
      />
    );
  }
}

MultilingualTabContainer.defaultProps = {
  showPromptForce: false,
  requestBlockData: null,
  areAiFeaturesAvailable: false,
};

MultilingualTabContainer.propTypes = {
  formData: PropTypes.shape({}).isRequired,
  isMobile: PropTypes.bool.isRequired,
  captions: PropTypes.shape({}).isRequired,
  showPromptForce: PropTypes.bool,
  savingStatus: PropTypes.string.isRequired,
  requestBlockData: PropTypes.shape({
    publicRoadmapUrl: PropTypes.string.isRequired,
  }),
  forceReload: PropTypes.func.isRequired,
  copyCustomColorsCSSRulesForBlocks: PropTypes.func.isRequired,
  removeCustomColorsCSSRulesForBlocks: PropTypes.func.isRequired,
  userPreferences: PropTypes.shape({
    multilingualTourCompleted: PropTypes.bool,
  }).isRequired,
  areAiFeaturesAvailable: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
  forceReload() {
    dispatch(view.actions.forceReloadView());
  },
  copyCustomColorsCSSRulesForBlocks(blocksIdsReferenceMap) {
    return dispatch(
      textFormatting.actions.copyCustomColorsCSSRulesForBlocks(blocksIdsReferenceMap)
    );
  },
  removeCustomColorsCSSRulesForBlocks(blocksIdsToRemove) {
    return dispatch(textFormatting.actions.removeCustomColorsCSSRulesForBlocks(blocksIdsToRemove));
  },
});

const mapStateToProps = (state) => ({
  requestBlockData: integration.selectors.getRequestBlock(state),
  savingStatus: saving.selectors.getStatus(state),
  userPreferences: integration.selectors.getSettings(state).userPreferences,
});

export default connect(mapStateToProps, mapDispatchToProps)(MultilingualTabContainer);
