import { blocks } from '@yola/ws-sdk';
import constants from 'src/js/modules/focal-point/constants/common';

const { OLD_LOGIC_ATTRIBUTE } = constants;

const removeCoverConstraints = (imageContainer) => {
  if (!imageContainer.hasAttribute(OLD_LOGIC_ATTRIBUTE)) return false;

  imageContainer.removeAttribute(OLD_LOGIC_ATTRIBUTE);

  const closestBlock = imageContainer.closest(
    `${blocks.constants.BLOCK_ID_PREFIX}[${OLD_LOGIC_ATTRIBUTE}]`
  );

  if (closestBlock) {
    closestBlock.removeAttribute(OLD_LOGIC_ATTRIBUTE);
  }

  return true;
};

export default removeCoverConstraints;
