import actionTypes from '../constants/action-types.js';

const setAdjacentPosition = (position) => ({
  type: actionTypes.SET_ADJACENT_POSITION,
  payload: {
    position,
  },
});

export default setAdjacentPosition;
