import getRangeItemsIdByIndexes from './get-range-items-id-by-indexes';

const getRangeItemsIds = (items, startItemId, endtItemId) => {
  const startIndex = items.indexOf(items.find((item) => item.id === startItemId));
  const endIndex = items.indexOf(items.find((item) => item.id === endtItemId));

  return startIndex > endIndex
    ? getRangeItemsIdByIndexes(items, endIndex, startIndex)
    : getRangeItemsIdByIndexes(items, startIndex + 1, endIndex + 1);
};

export default getRangeItemsIds;
