import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import { i18next } from '@yola/ws-sdk';
import IntegrationSettingsDialogScaffolding from './integration-settings-dialog-scaffolding';

const { InputGroup, FieldStatus, Paragraph } = designSystem;

const GoogleAnalyticsSettingsDialog = ({
  captions,
  defaultValue,
  onSubmit,
  onCancel,
  validate,
}) => {
  const [trackingId, setTrackingId] = useState(defaultValue);
  const [validationError, setValidationError] = useState('');

  const handleChange = (value) => {
    setValidationError('');
    setTrackingId(value);
  };

  const handleSubmit = () => {
    const trimmedValue = trackingId?.trim();
    const validationMessage = validate(trimmedValue);

    if (validationMessage) {
      setValidationError(validationMessage);
      return;
    }

    onSubmit(trimmedValue);
  };

  return (
    <IntegrationSettingsDialogScaffolding
      captions={{
        title: i18next.t('{integrationType} settings', { integrationType: captions.name }),
        controlName: captions.control.name,
        notes: captions.notes,
      }}
      onCancel={onCancel}
      onSubmit={handleSubmit}
    >
      <div>
        <InputGroup
          defaultValue={defaultValue}
          onChange={handleChange}
          invalid={!!validationError}
          value={trackingId}
        />
        {validationError && <FieldStatus appearance="error" text={validationError} />}
      </div>
      <Paragraph appearance="medium-emphasis" size="small">
        {captions.control.description}
      </Paragraph>
    </IntegrationSettingsDialogScaffolding>
  );
};

GoogleAnalyticsSettingsDialog.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  captions: PropTypes.shape({
    name: PropTypes.string.isRequired,
    control: PropTypes.shape().isRequired,
    notes: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  validate: PropTypes.func,
};

GoogleAnalyticsSettingsDialog.defaultProps = {
  validate: () => null,
};

export default GoogleAnalyticsSettingsDialog;
