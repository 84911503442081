import { bowser, view } from '@yola/ws-sdk';
import highlighterOffset from 'src/js/modules/highlighter/helpers/highlighter-offset';

const calculateOffsetTop = (element, top) => {
  if (!bowser.iPadOS) return top;

  /**
   * Workaround for iPadOS to calculate
   * element offset properly
   */
  const indent = highlighterOffset.get();
  const { height } = element.getBoundingClientRect();
  return view.accessors.getElementOffset(element) + height + indent;
};

export default calculateOffsetTop;
