import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { history, i18next } from '@yola/ws-sdk';
import { designSystem } from '@yola/ws-ui';
import bowser from 'yola-bowser';
import segment from 'src/js/modules/analytics/segment';

const {
  trackers: { trackRedoTriggerClicked },
} = segment;

const { EditorHeader } = designSystem;

function RedoContainer() {
  const canRedo = !!useSelector(history.selectors.getNextRedo);
  const dispatch = useDispatch();
  const redo = useCallback(() => {
    dispatch(history.actions.redo());
    trackRedoTriggerClicked();
  }, [dispatch]);

  return (
    <EditorHeader.RedoButton
      onClick={redo}
      disabled={!canRedo}
      popoverProps={{
        title: i18next.t('Redo'),
        content: i18next.t('Redo your most recent change.'),
        hotkey: bowser.macOS ? '\u2318\u21E7Z' : i18next.t('Ctrl+\u21E7+Z'),
      }}
    />
  );
}

export default RedoContainer;
