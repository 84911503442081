import { hdrm } from '@yola/ws-sdk';
import shallowProxyAttributes from './shallow-proxy-attributes';
import deepProxyAttributes from './deep-proxy-attributes';

const attributeListForShallowProxy = ['slot', 'layout'];
const attributeListForMediaContainerProxy = ['content-scale', 'aspect-ratio'];

const getNodeText = (node) => node.innerText && node.innerText.trim();

const replaceOptionalChildren = (
  optionChildrenToUpdate,
  originalBlockNode,
  destinationBlockNode,
  attributesForShallowProxy = []
) => {
  const replacedData = [];
  optionChildrenToUpdate.forEach((item) => {
    hdrm.helpers.updateChildrenPresence(
      destinationBlockNode,
      item.originalQuerySelector || item.querySelector,
      true,
      item.id
    );
    hdrm.helpers.updateChildrenPresence(
      originalBlockNode,
      item.destinationQuerySelector || item.querySelector,
      true,
      item.id
    );
    const nodes = [
      ...destinationBlockNode.querySelectorAll(item.destinationQuerySelector || item.querySelector),
    ];
    const newNodes = [
      ...originalBlockNode.querySelectorAll(item.originalQuerySelector || item.querySelector),
    ];

    if (newNodes.length && nodes.length) {
      nodes.forEach((node, i) => {
        const newNode = newNodes[i] || newNodes[newNodes.length - 1];
        if (newNode) {
          const nodeToReplace = newNode.cloneNode(true);

          shallowProxyAttributes(node, nodeToReplace, [
            ...attributeListForShallowProxy,
            ...attributesForShallowProxy,
          ]);
          deepProxyAttributes(
            node,
            nodeToReplace,
            'ws-media-container',
            attributeListForMediaContainerProxy
          );
          replacedData.push({
            id: item.id,
            oldText: getNodeText(node) && node.innerHTML,
            newText: getNodeText(nodeToReplace) && nodeToReplace.innerHTML,
            sourceQuerySelector: item.originalQuerySelector,
            destinationQuerySelector: item.destinationQuerySelector,
          });
          node.replaceWith(nodeToReplace);
        }
      });
    }
    hdrm.helpers.updateChildrenPresence(
      destinationBlockNode,
      item.destinationQuerySelector || item.querySelector,
      item.isEnabled,
      item.id
    );
  });

  return replacedData;
};

export default replaceOptionalChildren;
