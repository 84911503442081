import { dialogs, assets } from '@yola/ws-sdk';

const saveFile = ({
  dialogName,
  file,
  customPath,
  onFileUpload,
  onUploadCancel,
  onSaveFileCancel = () => {},
}) => {
  assets.operations
    .clientUploadAsset(file)
    .then(() => {
      dialogs.operations.show(dialogName, {
        file,
        customPath,
        onFileUpload,
        onUploadCancel,
      });
    })
    .catch(onSaveFileCancel);
};

export default saveFile;
