import { dialogs, extensions, integration, view } from '@yola/ws-sdk';
import store from 'src/js/store';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import config from '../constants/config';
import constants from '../constants/common';
import getImageUrlFromPasteEvent from './get-image-url-from-paste-event';
import matchesOrContains from './matches-or-contains';
import cacheSiteImage from './cache-site-image';
import pasteImageFromClipboard from './paste-image-from-clipboard';
import imageFileFormats from '../constants/image-file-formats';

const handlePasteEvent = async (event) => {
  const settings = extensions.accessors.getExtensionSettings(config.slug);
  const state = store.getState();
  const focusedElementId = view.selectors.getFocusedElement(state);
  const featureFlags = await integration.helpers.requestFeatureFlags(['image_copy_paste']);

  const { image_copy_paste: isCopyPasteEnabled } = featureFlags;

  if (!isCopyPasteEnabled || focusedElementId) return;

  const selectedElementId = view.selectors.getSelectedElement(state);
  const hoveredElementId = view.selectors.getHoveredElement(state);
  const targetElementId = selectedElementId || hoveredElementId;
  const element = view.accessors.getLiveElement(targetElementId);

  if (
    !targetElementId ||
    !element?.matches(`${settings.imageContainer}, ${settings.blockWithBackgroundQuerySelector}`)
  ) {
    return;
  }

  let url;
  try {
    url = await getImageUrlFromPasteEvent(event);
  } catch (e) {
    if (e.message === constants.WRONG_FILE_TYPE_ERROR) {
      dialogs.operations.show(dialogTypes.FILE_TYPE_ERROR_DIALOG, {
        formats: imageFileFormats.map((item) => `.${item}`).join(', '),
      });
    } else {
      console.error(e);
      dialogs.operations.hide();
    }
    return;
  }

  if (url) {
    const isBackgroundImage = element.matches(settings.blockWithBackgroundQuerySelector);
    const imageElement = matchesOrContains(element, settings.imageActionSelector);
    const imageElementId = view.accessors.getLiveElementId(imageElement);
    await cacheSiteImage(url);

    let actions = [];

    if (isBackgroundImage) {
      actions = [
        view.actions.setElementAttribute(targetElementId, 'background-image', url),
        view.actions.setElementAttribute(targetElementId, 'background-position', '50% 50%'),
        view.actions.setElementAttribute(targetElementId, 'background-scale', '1'),
      ];
    } else {
      actions = [
        view.actions.setElementAttribute(imageElementId, 'src', url),
        view.actions.setElementAttribute(targetElementId, 'content-position', '50% 50%'),
        view.actions.setElementAttribute(targetElementId, 'content-scale', '1'),
      ];
    }

    store.dispatch(view.actions.bulkViewActions(actions));
    dialogs.operations.hide();
  } else {
    dialogs.operations.hide();
    await pasteImageFromClipboard(element, config);
  }
};

export default handlePasteEvent;
