import connectedStore from 'yola-editor/src/js/store/connected-store';
import yowsServiceClient from 'yola-editor/src/js/modules/yows-service-client/service-client';
import getUserData from 'yola-editor/src/js/modules/user/selectors/user-data';
import { snakeCaseToCamelCase } from 'yola-editor/src/js/utils/convert-case';
import shareOptions from '../constants/share-options';
import generateReferralLink from './generate-referral-link';

const { FACEBOOK, TWITTER, LINKEDIN, EMAIL, MANUAL_INVITE } = shareOptions;

const generateFullLinksByOptionsMap = (options, userId) =>
  options.reduce((acc, option) => {
    acc[option] = generateReferralLink(option, userId);
    return acc;
  }, {});

const generateShortLinksByOptionsMap = (fullLinksByOptionsMap, shortLinksByFullLinksMap) =>
  Object.entries(fullLinksByOptionsMap).reduce((acc, [option, fullLink]) => {
    const formattedFullLink = snakeCaseToCamelCase(fullLink);
    acc[option] = shortLinksByFullLinksMap[formattedFullLink];
    return acc;
  }, {});

const getShortReferralLinks = async () => {
  const store = connectedStore.get();
  const state = store.getState();
  const service = yowsServiceClient.get();
  const userData = getUserData(state);
  const { id: userId } = userData;
  const availableOptions = [FACEBOOK, TWITTER, LINKEDIN, EMAIL, MANUAL_INVITE];

  const fullLinksByOptionsMap = generateFullLinksByOptionsMap(availableOptions, userId);
  const fullLinks = Object.values(fullLinksByOptionsMap);

  const { data: shortLinksByFullLinksMap } = await service.createShortUrls({ urls: fullLinks });

  return generateShortLinksByOptionsMap(fullLinksByOptionsMap, shortLinksByFullLinksMap);
};

export default getShortReferralLinks;
