const getScale = (elementContainer, size) => {
  const { offsetWidth } = elementContainer;
  const { width } = size;

  const scale = offsetWidth / width;

  if (scale > 1) return 1;

  return scale;
};

export default getScale;
