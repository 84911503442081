import bowser from 'yola-bowser';
import supportedBrowsers from 'yola-editor/src/js/unsupported-browser-dialog/constants/supported-browsers';
import getNetworkErrorDetails from 'yola-editor/src/js/modules/error/helpers/get-network-error-details';
import sentryInstance from 'src/js/modules/analytics/sentry/instance';
import segment from 'src/js/modules/analytics/segment';
import actionTypes from '../constants/action-types';
import auth from '../../auth';
import site from '../../site';
import detectDeviceOrientation from '../../../utils/detect-device-orientation';
import getNetworkStatus from '../selectors/network-status';
import constants from '../constants';
import errorTypes from '../constants/error-types';

const { NETWORK_OFFLINE } = constants;

const errorHandler = (store) => (next) => (action) => {
  switch (action.type) {
    case actionTypes.SET_CRITICAL_ERROR: {
      const state = store.getState();
      const siteId = auth.selectors.getSiteId(state);
      const templateBuildSlug = site.selectors.getSiteTemplateSlug(state);
      const networkStatus = getNetworkStatus(state);
      const { error, errorInfo, logSentry } = action.payload;
      const isNetworkError = error.message.includes(errorTypes.NETWORK_ERROR);

      if (sentryInstance.isInit() && logSentry) {
        const instance = sentryInstance.getInstance();

        if (errorInfo) {
          instance.withScope((scope) => {
            scope.setExtras(errorInfo);
            instance.captureException(error);
          });
        } else {
          instance.captureException(error);
        }
      }

      /*
        In order to be sure that "beforeunload" event fired before
        tracking "ERROR_CAUGHT" event we need to wrap it into macrotask
      */
      setTimeout(() => {
        if (
          (isNetworkError && networkStatus === NETWORK_OFFLINE) ||
          window._ws_resources_unloaded // eslint-disable-line no-underscore-dangle
        ) {
          next(action);
          return;
        }

        const segmentProps = {
          appName: segment.constants.common.APP_NAME,
          errorName: error.name,
          errorMessage: error.message,
          siteId: siteId || null,
          templateBuildSlug,
          deviceOrientation: detectDeviceOrientation(),
          isSupportedBrowser: bowser.isSatisfied(supportedBrowsers),
        };

        if (isNetworkError) {
          segmentProps.networkErrorDetails = getNetworkErrorDetails(error);
        }
        segment.track(segment.constants.events.ERROR_CAUGHT, segmentProps);

        next(action);
      }, 0);

      break;
    }
    default:
      next(action);
  }
};

export default errorHandler;
