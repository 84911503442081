import { view } from '@yola/ws-sdk';
import {
  ASPECT_RATIO_ATTRIBUTE,
  CONTENT_POSITION_ATTRIBUTE,
  WIDTH_ATTRIBUTE,
} from 'src/js/modules/extensions/registry/ws-image/constants/media-container';

const getResizeOperations = (elements) => {
  const { setElementAttribute, removeElementAttribute } = view.operations;

  const operations = elements
    .map((element) => {
      const elementId = view.accessors.getLiveElementId(element);

      const aspectRatioInstruction = [
        setElementAttribute,
        [elementId, ASPECT_RATIO_ATTRIBUTE, element.getAttribute(ASPECT_RATIO_ATTRIBUTE)],
      ];

      const positionInstruction = [
        setElementAttribute,
        [elementId, CONTENT_POSITION_ATTRIBUTE, element.getAttribute(CONTENT_POSITION_ATTRIBUTE)],
      ];

      let widthInstruction;

      if (element.hasAttribute(WIDTH_ATTRIBUTE)) {
        widthInstruction = [
          setElementAttribute,
          [elementId, WIDTH_ATTRIBUTE, element.getAttribute(WIDTH_ATTRIBUTE)],
        ];
      } else {
        widthInstruction = [removeElementAttribute, [elementId, WIDTH_ATTRIBUTE]];
      }

      return [aspectRatioInstruction, positionInstruction, widthInstruction];
    })
    .flat();

  return operations;
};

export default getResizeOperations;
