export default {
  DIALOG_POSITION_MARGIN: 25,
  DIALOG_WIDTH: 336,
  ENTER_KEYCODE: 13,
  ICON_EXTENSION_NAME: 'ws-icon',
  SOCIAL_NETWORK: 'social-network',
  YOLA_ICON_PREFIX: 'ws-',
  WWW_PREFIX: 'www.',
  URL_FIELD_NAME: 'url',
  HTTPS_PROTOCOL: 'https:',
  slug: 'ws-social-links',
  AUTO_SIZE: 'auto',
};
