import { anodum, textFormatting, extensions } from '@yola/ws-sdk';
import getFirstLinkElementFromSelection from '../helpers/get-first-link-element-from-selection';
import constants from '../constants';

const isLinkDeletable = () => {
  const selection = textFormatting.accessors.getAdvancedSelection();
  const { linkDeleteButtonQuerySelector } = extensions.accessors.getExtensionSettings(
    constants.common.SLUG
  );

  if (selection.isCollapsed) {
    const linkElement = getFirstLinkElementFromSelection();
    if (linkElement) {
      return linkElement.matches(linkDeleteButtonQuerySelector);
    }
  }

  const range = selection.getRangeAt(0);
  const nodes = range.getNodes([Node.ELEMENT_NODE, Node.TEXT_NODE]);

  return nodes.some((node) => {
    const targetNode = anodum.isTextNode(node) ? node.parentElement : node;
    return targetNode.closest(linkDeleteButtonQuerySelector);
  });
};

export default isLinkDeletable;
