import attributeValuesList from 'src/js/modules/utils/attribute-values-list';
import optionTypes from '../../constants/display-options-type';

const createColorScheme = ({ item, colorPalette, blockNode, elementId, onClick }) => {
  const isEnabled = attributeValuesList.contains(blockNode, item.attribute, item.value);
  const color = colorPalette && colorPalette.colors[item.id];

  return {
    ...item,
    onClick,
    isEnabled,
    color,
    elementId,
    type: optionTypes.COLOR_SCHEME,
    isInitialEnabled: isEnabled,
  };
};

export default createColorScheme;
