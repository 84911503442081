import bowser from 'yola-bowser';

const isElementOutOfViewport = (element, viewportHeight, scrollPosition) => {
  let { top, bottom } = element.getBoundingClientRect();

  if (bowser.ios) {
    top -= scrollPosition;
    bottom -= scrollPosition;
  }

  if (top > viewportHeight || bottom < 0) {
    return true;
  }

  return false;
};

export default isElementOutOfViewport;
