import { selectors } from '@yola/subscription-manager-js';
import features from 'yola-editor/src/js/modules/features';
import dialogs from 'yola-editor/src/js/modules/dialogs';
import getEditorSettings from 'yola-editor/src/js/modules/editor/settings/get-editor-settings';
import actionTypes from '../constants/action-types';
import actions from '../actions';

const customDomainInterrupter = (store) => (next) => async (action) => {
  if (action.type !== actionTypes.SELECT_DOMAIN) {
    next(action);
    return;
  }

  const state = store.getState();
  const activeSubscriptions = selectors.getActiveSubscriptions(state);
  const customDomainFeature =
    features.helpers.limitDetectors.customDomainLimit(activeSubscriptions);

  if (customDomainFeature.available) {
    next(action);
    return;
  }

  const settings = await getEditorSettings();
  const { premiumFeature, captions, onUpgrade, purchaseFlowType } = settings.upsells.customDomain;

  store.dispatch(
    dialogs.actions.show(dialogs.dialogTypes.UPGRADE, {
      purchaseFlowType,
      premiumFeature,
      captions,
      onUpgrade,
      onUpgradeResolve() {
        next(action);
      },
      onUpgradeResolveFeedback() {
        store.dispatch(dialogs.actions.show(dialogs.dialogTypes.WL_DOMAIN_PURCHASE));
      },
      onUpgradeReject() {
        store.dispatch(actions.clearDomainPurchaseData());
      },
      onClose() {
        store.dispatch(actions.clearDomainPurchaseData());
      },
    })
  );
};

export default customDomainInterrupter;
