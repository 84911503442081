import {
  OVERLAY_ATTRIBUTE,
  OVERLAY_OPACITY_ATTRIBUTE,
  BACKGROUND_IMAGE_ATTRIBUTE,
  BACKGROUND_HIDDEN_ATTRIBUTE,
} from '../../../constants/attributes';
import isAffectedBlock from '../verifiers/is-affected-block';
import canEditOverlayOpacity from '../verifiers/can-edit-overlay-opacity';
import canBlockBeOverlaidByHeader from './can-block-be-overlaid-by-header';

const MIN_OVERLAY_OPACITY = '0';
const INITIAL_OVERLAY_OPACITY = '0.5';

const getOverlayOpacityAutomationInstructions = ({ headerNode, nextSiblingNode, isStatic }) => {
  if (
    !nextSiblingNode ||
    !isAffectedBlock(headerNode) ||
    !canEditOverlayOpacity(headerNode) ||
    !canBlockBeOverlaidByHeader(nextSiblingNode)
  ) {
    return [];
  }

  const currentOverlayOpacity = headerNode.getAttribute(OVERLAY_OPACITY_ATTRIBUTE);
  const hasMinOverlayOpacity = currentOverlayOpacity === MIN_OVERLAY_OPACITY;

  const isNextSiblingHasBackgroundImage =
    nextSiblingNode.hasAttribute(BACKGROUND_IMAGE_ATTRIBUTE) &&
    !nextSiblingNode.hasAttribute(BACKGROUND_HIDDEN_ATTRIBUTE);
  const isNextSiblingHasOverlay =
    nextSiblingNode.hasAttribute(OVERLAY_ATTRIBUTE) &&
    nextSiblingNode.getAttribute(OVERLAY_OPACITY_ATTRIBUTE) !== MIN_OVERLAY_OPACITY;

  if (!hasMinOverlayOpacity && (isNextSiblingHasOverlay || !isNextSiblingHasBackgroundImage)) {
    return [
      {
        node: headerNode,
        static: isStatic,
        instructions: [
          {
            property: OVERLAY_OPACITY_ATTRIBUTE,
            value: MIN_OVERLAY_OPACITY,
          },
        ],
      },
    ];
  }

  if (hasMinOverlayOpacity && isNextSiblingHasBackgroundImage && !isNextSiblingHasOverlay) {
    return [
      {
        node: headerNode,
        static: isStatic,
        instructions: [
          {
            property: OVERLAY_OPACITY_ATTRIBUTE,
            value: INITIAL_OVERLAY_OPACITY,
          },
        ],
      },
    ];
  }

  return [];
};

export default getOverlayOpacityAutomationInstructions;
