import setDefaultAttributes from './set-default-attributes';

const afterElementInsert = (element) => {
  if (!element || !element.querySelector('ws-contact-form-container')) {
    return undefined;
  }

  return setDefaultAttributes(element);
};

export default afterElementInsert;
