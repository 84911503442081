import React, { useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { integration, i18next, site, template } from '@yola/ws-sdk';
import { Dropdown } from '@yola/ws-ui';
import segment from 'src/js/modules/analytics/segment';
import getHelpMenuItemClickCallbacks from 'yola-editor/src/js/modules/editor/settings/get-help-menu-item-click-callbacks';
import MainMenu from '../../common/components/main-menu';
import getSettingByName from '../helpers/get-setting-by-name';
import sortMenuItems from '../helpers/sort-menu-items';
import itemIds from '../constants/item-ids';

const {
  track,
  constants: { events, triggerIds },
} = segment;

function HelpMenuContainer(props) {
  const { isMobile } = props;
  const settings = useSelector(integration.selectors.getSettings);

  let helpMenuItems = getSettingByName(settings, 'helpMenu.submenu', []);
  const helpMenuTitle = getSettingByName(settings, 'helpMenu.heading', '');
  const helpMenuHref = getSettingByName(settings, 'helpMenu.href', '');
  const helpMenuID = getSettingByName(settings, 'helpMenu.id', '');

  const menuElement = useRef(null);

  const isMultilingualSite = site.verifiers.isMultilingualSite();
  const { multilingualTourOnClick } = getHelpMenuItemClickCallbacks(triggerIds.EDITOR_HEADER);

  if (isMultilingualSite) {
    const multilingualTourMenuItem = {
      title: i18next.t('Multilingual tour'),
      heading: i18next.t('Multilingual tour'),
      id: itemIds.MULTILINGUAL_TOUR,
      description: '',
      order: 4,
      onClick: multilingualTourOnClick,
    };

    helpMenuItems = [...helpMenuItems, multilingualTourMenuItem];
  }

  useEffect(() => {
    menuElement.current = document.getElementById(helpMenuID);
  }, [helpMenuID]);

  const helpMenuOnShowHandler = () => {
    track(events.APP_HELP_MENU_DISPLAYED, {
      siteId: site.accessors.getSiteId(),
      templateBuildSlug: template.accessors.getBuildSlug(),
    });
  };

  const sortedMenuItems = useMemo(
    () =>
      sortMenuItems(helpMenuItems).map((item) => {
        if (item.onClick) {
          return {
            ...item,
            onClick: () => {
              Dropdown.dispatchHide();
              item.onClick();
            },
          };
        }
        return item;
      }),
    [helpMenuItems]
  );

  const captions = { name: helpMenuTitle };

  if (!helpMenuHref && helpMenuItems.length === 0) {
    return null;
  }

  return (
    <MainMenu
      href={helpMenuHref}
      isHidden={isMobile}
      onShow={helpMenuOnShowHandler}
      id={helpMenuID}
      captions={captions}
      className="ws-mainmenu--help-menu"
      items={sortedMenuItems}
      showDetails={false}
    />
  );
}

HelpMenuContainer.propTypes = {
  isMobile: PropTypes.bool,
};

HelpMenuContainer.defaultProps = {
  isMobile: false,
};

export default HelpMenuContainer;
