import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { site, i18next } from '@yola/ws-sdk';
import user from 'yola-editor/src/js/modules/user';

const SiteUnderMaintenanceScreen = () => {
  const siteProperties = useSelector(site.selectors.getSiteProperties);
  const userData = useSelector(user.selectors.getUserData, shallowEqual);
  const { employee_id: userEmployeeId } = userData;

  if (!siteProperties.maintenance || userEmployeeId) return null;

  return (
    <div className="ws-maintenance-screen">
      <h1 className="ws-maintenance-screen__title">{i18next.t('We are under maintenance')}</h1>
      <p className="ws-maintenance-screen__subtitle">
        {i18next.t(
          "Sorry for the inconvenience, but we are performing some maintenance at the moment. We'll be back on soon with new cool features!"
        )}
      </p>
    </div>
  );
};

export default SiteUnderMaintenanceScreen;
