const isTextInBlockChanged = (blockElement) => {
  if (!blockElement) return false;

  const textElements = [...blockElement.querySelectorAll('ws-text')];

  if (!textElements.length) return false;

  return textElements.some((element) => !element.hasAttribute('content-untouched'));
};

export default isTextInBlockChanged;
