import { blocks } from '@yola/ws-sdk';

const getChildrenToUpdate = (originalBlockNode, destinationBlockNode) => {
  const originalOptionalChildren = blocks.accessors.getOptionalChildrenData(originalBlockNode);

  const destinationOptionalChildren =
    blocks.accessors.getOptionalChildrenData(destinationBlockNode);

  return originalOptionalChildren
    .map(({ id, querySelector: originalQuerySelector }) => {
      const destinationChildren = destinationOptionalChildren.find(
        (children) => children.id === id
      );
      if (!destinationChildren) return null;

      return {
        ...destinationChildren,
        isEnabled: destinationChildren.isEnabled,
        originalQuerySelector,
        destinationQuerySelector: destinationChildren.querySelector,
      };
    })
    .filter((element) => element);
};

export default getChildrenToUpdate;
