import { view } from '@yola/ws-sdk';
import positionProperties from '../../../common/constants/position-properties';
import getPositionCustomProperties from '../../../common/helpers/get-position-custom-properties';

const getPositionOperations = (elements = []) => {
  const operations = [];

  elements.forEach((element) => {
    const elementId = view.accessors.getLiveElementId(element);
    const { x, y } = getPositionCustomProperties(element);

    if (x !== null) {
      operations.push([
        view.operations.setInlineElementStyle,
        [elementId, positionProperties.X, `${x}%`],
      ]);
    }

    if (y !== null) {
      operations.push([
        view.operations.setInlineElementStyle,
        [elementId, positionProperties.Y, `${y}%`],
      ]);
    }
  });

  return operations;
};

export default getPositionOperations;
