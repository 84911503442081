import React from 'react';
import { ProgressDialog } from '@yola/ws-ui';
import PropTypes from 'prop-types';
import ConfettiAnimation from 'yola-editor/src/js/modules/animation/components/confetti-animation';

function ProgressDialogContainer({ shouldActivateConfettiOverlay, ...otherProps }) {
  return (
    <React.Fragment>
      {shouldActivateConfettiOverlay && (
        <div className="custom-overlay">
          <ConfettiAnimation />
        </div>
      )}
      <ProgressDialog {...otherProps} />;
    </React.Fragment>
  );
}

ProgressDialogContainer.propTypes = {
  shouldActivateConfettiOverlay: PropTypes.bool,
};

ProgressDialogContainer.defaultProps = {
  shouldActivateConfettiOverlay: false,
};

export default ProgressDialogContainer;
