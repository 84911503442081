const updateAttributes = (attributesToUpdate, destinationBlockNode) => {
  attributesToUpdate.forEach((attributeItem) => {
    const { attribute, value, isSet, querySelector } = attributeItem;
    if (!isSet && querySelector) {
      destinationBlockNode
        .querySelectorAll(querySelector)
        .forEach((node) => node.removeAttribute(attribute));
      return;
    }
    if (!isSet && !querySelector) {
      destinationBlockNode.removeAttribute(attribute);
      return;
    }
    if (querySelector) {
      destinationBlockNode
        .querySelectorAll(querySelector)
        .forEach((node) => node.setAttribute(attribute, value));
    } else {
      destinationBlockNode.setAttribute(attribute, value);
    }
  });
};

export default updateAttributes;
