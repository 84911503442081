import changeColorPalette from './change-color-palette';
import switchColorPalette from './switch-color-palette';
import changeColor from './change-color';
import changeFontPairs from './change-font-pairs';

export default {
  changeColorPalette,
  switchColorPalette,
  changeColor,
  changeFontPairs,
};
