import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {
  i18next,
  view,
  site,
  blocks,
  textFormatting,
  contentEditable,
  siteLocales,
  integration,
} from '@yola/ws-sdk';
import { constants } from '@yola/subscription-manager-js';
import segment from '../../../../analytics/segment';
import ai from '../../../../ai';
import upsell from '../../../../upsell';
import getParentBlockByElement from '../../../../blocks/helpers/get-parent-block-by-element';
import helpers from '../helpers';
import useSelectionSimulation from '../hooks/use-selection-simulation';
import useTextAssistantStatusChange from '../hooks/use-text-assistant-status-change';
import useUndoRedoPrevention from '../hooks/use-undo-redo-prevention';
import AiTextAssistantContainer from './ai-text-assistant-container';

const {
  helpers: { initiateTextGeneration },
  constants: { textAssistantInstructionTypes },
  hooks: { useAiOnboarding },
} = ai;
const {
  constants: { events },
  trackers: { trackEvent },
} = segment;
const {
  insertGeneratedContent,
  getElementsContent,
  setSelectionByNodePath,
  findBlockElementFromNode,
} = helpers;
const {
  hostingPackageFeatureNames: { AI_TEXT_ASSISTANT_QUOTA },
} = constants;
const {
  constants: {
    editableElements: { EDITABLE_ELEMENTS },
  },
} = contentEditable;
const QUOTA_EXCEEDED_CODE = 'ai_text_assistant_quota_exceeded';

const getCaptions = () => ({
  placeholder: i18next.t('Tell AI what to write...'),
  loading: i18next.t('AI is writing...'),
  cancel: i18next.t('Discard'),
  onboardingTitle: i18next.t('Write with AI'),
});

const AiWriteTextContainer = ({ elementId, triggerId, getContext }) => {
  const cachedParams = useRef({});
  const pageId = useSelector(site.accessors.getActivePageId);
  const pageLocale = useSelector(siteLocales.selectors.getCurrentHtmlLocale);
  const businessCategory = useSelector(site.selectors.getBusinessCategory);
  const limits = useSelector(integration.selectors.getLimits);
  const captions = getCaptions();
  const abortController = useRef();
  const focusedElementInfo = useRef(contentEditable.helpers.getFocusedElementInfo());
  const dispatch = useDispatch();

  const { clearSelectionImitation, restoreSelectionImitation } = useSelectionSimulation(elementId);
  const { isLoading, onLoading, onSuccess, onError, onCancel } =
    useTextAssistantStatusChange(getContext);
  const { onNextDialog } = useAiOnboarding();
  useUndoRedoPrevention();

  if (!elementId) return null;

  const handleTextGeneration = async (params, shouldMoveCaretToNewLine = false) => {
    const element = view.accessors.getLiveElement(elementId);
    const blockElement = getParentBlockByElement(element);
    abortController.current = new AbortController();
    const { signal } = abortController.current;

    onLoading();

    try {
      const response = await initiateTextGeneration(params, signal);
      const { content } = response.results;

      if (signal.aborted) {
        return;
      }

      clearSelectionImitation();

      // workaround for missed selection on iOS 16
      const selection = textFormatting.accessors.getAdvancedSelection();
      if (!selection.rangeCount) {
        setSelectionByNodePath(element, focusedElementInfo.current.selectionData);
      }

      if (shouldMoveCaretToNewLine) {
        const selectedBlockElement = findBlockElementFromNode(selection.anchorNode);
        contentEditable.helpers.splitElementByTheEndOfNode(selectedBlockElement);
      }

      await insertGeneratedContent(element, content);
      focusedElementInfo.current = contentEditable.helpers.getFocusedElementInfo();
      restoreSelectionImitation();

      onSuccess();
    } catch (err) {
      if (!signal.aborted) {
        const isAiTextAssistantQuotaExceeded =
          err.response?.status === 409 && err.response?.data?.code === QUOTA_EXCEEDED_CODE;

        console.error(err?.name);
        onError(err, isAiTextAssistantQuotaExceeded);

        if (isAiTextAssistantQuotaExceeded) {
          const currentLimit = get(limits, 'aiTextAssistantQuota');
          upsell.operations
            .requestFeatureAvailability(AI_TEXT_ASSISTANT_QUOTA, { ...currentLimit })
            .then(() => {
              handleTextGeneration(params);
            })
            .catch(() => {
              // eslint-disable-next-line no-console
              console.log('Upgrade flow was canceled');
            });
        } else {
          trackEvent(events.AI_TEXT_ASSISTANT_ERROR_CAUGHT, {
            blockId: blocks.accessors.getBlockIdByElement(blockElement),
            blockVariationId: blocks.accessors.getVariationIdByElement(blockElement),
            triggerId,
            businessCategory,
          });
        }
      }
    }
  };

  const handleGenerate = (userInstruction) => {
    if (cachedParams.current && Object.keys(cachedParams.current).length) {
      const params = {
        ...cachedParams.current,
        userInstruction,
      };

      cachedParams.current = params;
      handleTextGeneration(params);
      return;
    }

    const selection = textFormatting.accessors.getAdvancedSelection();
    const element = view.accessors.getLiveElement(elementId);
    const selectedBlockElement = findBlockElementFromNode(selection.anchorNode);
    const allBlockElements = [...element.querySelectorAll(EDITABLE_ELEMENTS)];
    const selectedElementIndex = allBlockElements.indexOf(selectedBlockElement);
    let elementsBefore = allBlockElements.slice(0, selectedElementIndex);
    const elementsAfter = allBlockElements.slice(selectedElementIndex + 1);
    const isCaretInsideText = !!selectedBlockElement.innerText.trim();

    // if caret is inside non-empty text element it will be moved to the next new line
    if (isCaretInsideText) {
      elementsBefore = allBlockElements.slice(0, selectedElementIndex + 1);
    }

    const contentBefore = getElementsContent(elementsBefore);
    const contentAfter = getElementsContent(elementsAfter);

    const params = {
      contentBefore,
      contentAfter,
      pageId,
      userInstruction,
      locale: pageLocale,
    };

    cachedParams.current = params;

    handleTextGeneration(params, isCaretInsideText);
  };

  const handleRetry = () => {
    clearSelectionImitation();
    const element = view.accessors.getLiveElement(elementId);
    const blockElement = getParentBlockByElement(element);

    trackEvent(events.AI_TEXT_ASSISTANT_TRY_AGAIN_TRIGGER_CLICKED, {
      blockId: blocks.accessors.getBlockIdByElement(blockElement),
      blockVariationId: blocks.accessors.getVariationIdByElement(blockElement),
      triggerId,
      businessCategory,
    });

    handleTextGeneration(cachedParams.current);
  };

  const handleCancel = () => {
    abortController.current?.abort();
    onCancel();
  };

  const handleSubmit = (instruction) => {
    const element = view.accessors.getLiveElement(elementId);
    const blockElement = getParentBlockByElement(element);
    const isElementEmpty = !element.innerText || !element.innerText.trim();

    trackEvent(events.AI_TEXT_USER_INPUT_SUBMITTED, {
      blockId: blocks.accessors.getBlockIdByElement(blockElement),
      blockVariationId: blocks.accessors.getVariationIdByElement(blockElement),
      triggerId,
      userInstructionType: textAssistantInstructionTypes.USER_INPUT,
      userInput: instruction,
      businessCategory,
    });

    onNextDialog({
      sourceId: triggerId,
      title: captions.onboardingTitle,
      onNext: () => handleGenerate(instruction),
      preserveControlPane: true,
    });

    // prevent empty text element hiding when paywall or upgrade interruption will be shown
    // src/js/modules/design-automation/handlers/text-block/text-is-changed.js
    if (isElementEmpty) {
      dispatch(contentEditable.actions.undo());
    }
  };

  return (
    <AiTextAssistantContainer
      captions={captions}
      isLoading={isLoading}
      onSelect={handleSubmit}
      onSubmit={handleSubmit}
      onRetry={handleRetry}
      onCancel={handleCancel}
    />
  );
};

AiWriteTextContainer.propTypes = {
  triggerId: PropTypes.string.isRequired,
  elementId: PropTypes.string,
  getContext: PropTypes.func,
};

AiWriteTextContainer.defaultProps = {
  elementId: null,
  getContext: Function.prototype,
};

export default AiWriteTextContainer;
