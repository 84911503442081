import { view } from '@yola/ws-sdk';
import actionTypes from '../constants/action-types';

const hoveredElementWatcher = (store) => (next) => (action) => {
  switch (action.type) {
    case actionTypes.SHOW: {
      const state = store.getState();
      const hoveredElement = view.selectors.getHoveredElement(state);
      if (hoveredElement) {
        store.dispatch(view.actions.setHoveredElement(null));
      }
      next(action);
      break;
    }
    default:
      next(action);
  }
};

export default hoveredElementWatcher;
