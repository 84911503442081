/* eslint-disable yola/react/prop-types */
import React, { Fragment } from 'react';
import { designSystem } from '@yola/ws-ui';
import BlockSettingsOptions from '../components/block-settings-options';
import BlockBackgroundContainer from '../containers/block-background-container';
import SwitchLayoutBlock from '../components/switch-layout-block';
import {
  BLOCK_SETTINGS_APPEARANCE_TAB_ID,
  BLOCK_SETTINGS_LAYOUT_TAB_ID,
} from '../constants/common';

const { TabsList, Tab, TabsContentList, TabContent } = designSystem;

const layoutOptionsIds = ['clean_url_selector', 'product_category', 'items_count'];

const appearanceOptionsIds = [
  'surfaces',
  'fullscreen',
  'animation',
  'animation_speed',
  'animation_direction',
  'media_column_width',
  'space_between',
  'whitespace_top',
  'whitespace_bottom',
  'fullwidth',
  'expanded_items',
  'multiple',
  'content_direction',
  'vertical_alignment',
  'horizontal_alignment',
  'feature_direction',
  'button_placement',
  'columns',
  'stickup',
  'border',
  'shadow',
  'show_background_image',
  'filter',
  'overlay',
  'overlay_opacity',
  'filter_media',
  'media_decoration',
  'fill_item',
  'rounded-images',
  'logo_alignment',
  'gradient',
  'border_bottom',
  'border_width',
];

const getBlockSettingsTabs = ({
  captions: { tabs },
  options: { optionalChildren, displayOptions: display },
  blockItems,
  isBlockLayoutSwitchingDisabled,
  displayBlockLayout,
  timestamp,
}) => {
  const safeOptionalChildren = optionalChildren || [];

  const shouldDisplayLayoutSwitching =
    !isBlockLayoutSwitchingDisabled &&
    safeOptionalChildren.filter((option) => option.isEnabled).length > 0;

  const displayOptionsMap = display.reduce((accum, item) => {
    const accumClone = { ...accum };
    accumClone[item.id] = item;
    return accumClone;
  }, {});

  const [surfaces] = display;
  const blockLayoutOptions = layoutOptionsIds.map((id) => displayOptionsMap[id]).filter(Boolean);

  const items = [...safeOptionalChildren, ...blockLayoutOptions];

  const appearanceOptions = appearanceOptionsIds.map((id) => displayOptionsMap[id]).filter(Boolean);

  return (
    <Fragment>
      <TabsList variant="fullWidth">
        <Tab tabId={BLOCK_SETTINGS_LAYOUT_TAB_ID} label={tabs.layout} disabled={!items.length} />
        <Tab tabId={BLOCK_SETTINGS_APPEARANCE_TAB_ID} label={tabs.appearance} />
      </TabsList>
      <TabsContentList>
        <TabContent tabId={BLOCK_SETTINGS_LAYOUT_TAB_ID}>
          {blockItems.length > 1 && shouldDisplayLayoutSwitching && (
            <SwitchLayoutBlock displayBlockLayout={displayBlockLayout} />
          )}
          <BlockSettingsOptions keySalt={`${timestamp}-${surfaces.value}`} items={items} />
        </TabContent>
        <TabContent tabId={BLOCK_SETTINGS_APPEARANCE_TAB_ID}>
          <BlockBackgroundContainer />
          <BlockSettingsOptions
            keySalt={`${timestamp}-${surfaces.value}`}
            items={appearanceOptions}
          />
        </TabContent>
      </TabsContentList>
    </Fragment>
  );
};

export default getBlockSettingsTabs;
