import { site, blocks, template } from '@yola/ws-sdk';
import segment from 'src/js/modules/analytics/segment';
import getParentBlockByElement from 'src/js/modules/blocks/helpers/get-parent-block-by-element';
import getCaptionForElement from 'src/js/modules/common/helpers/get-caption-for-element';

const {
  track,
  constants: { events },
} = segment;

const contextMenuDisplayedTracker = ({ triggerId = null, targetElement }) => {
  const parentBlock = getParentBlockByElement(targetElement);
  const { id: elementId } = getCaptionForElement(targetElement);

  const traits = {
    siteId: site.accessors.getSiteId(),
    templateBuildSlug: template.accessors.getBuildSlug(),
    triggerId,
    elementId: elementId || null,
    blockId: blocks.accessors.getBlockIdByElement(parentBlock),
    blockVariationId: blocks.accessors.getVariationIdByElement(parentBlock),
  };

  track(events.CONTEXT_MENU_DISPLAYED, traits);
};

export default contextMenuDisplayedTracker;
