import { serviceProvider } from '@yola/subscription-manager-js';
import errorModule from 'yola-editor/src/js/modules/error';
import fetchCurrentPackageComplete from '../actions/fetch-current-package-complete';

const fetchCurrentPackage = () => async (dispatch) => {
  try {
    await serviceProvider.fetchCurrentPackage();
    dispatch(fetchCurrentPackageComplete());
  } catch (error) {
    dispatch(
      errorModule.actions.setCriticalError({
        error,
        logSentry: true,
      })
    );
  }
};

export default fetchCurrentPackage;
