import actionTypes from '../constants/action-types';

const show = (elements, options = {}) => ({
  type: actionTypes.SHOW,
  payload: {
    elements,
    options,
  },
});

export default show;
