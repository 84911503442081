/*
 *
 *  FACEBOOK_VIDEO_WIDTH is the default width of the facebook document.
 *  It is necessary for large resolution screens.
 *
 * */

export default {
  FACEBOOK_VIDEO_WIDTH: 4000,
  CONTROL_TRIGGER_ID: 'iframe',
  slug: 'ws-iframe',
};
