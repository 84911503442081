import { i18next } from '@yola/ws-sdk';

const getRequestBlock = (publicRoadmapUrl) => ({
  captions: {
    title: i18next.t("Can't find what you're looking for? Let us know!"),
    button: i18next.t('Request a block'),
  },
  publicRoadmapUrl,
});

export default getRequestBlock;
