import React from 'react';
import PropTypes from 'prop-types';
import { i18next, utils } from '@yola/ws-sdk';
import { Field, designSystem } from '@yola/ws-ui';
import InputGroupField from 'src/js/modules/common/components/input-group-field';
import RadioButtonGroupField from 'src/js/modules/common/components/radio-button-group-field';
import CheckboxField from 'src/js/modules/common/components/checkbox-field';
import helpers from '../helpers';
import constants from '../constants';

const { common, timeFormats } = constants;
const { LABEL_INPUT, PLACEHOLDER_INPUT } = common;

const { Stack, ControlGroup } = designSystem;

const TimeFieldContent = (props) => {
  const { labelValue, placeholderValue, placeholders, handleChange, onRequiredCheckboxClick } =
    props;

  return (
    <Stack gap="spacing-m">
      <ControlGroup title={i18next.t('Label')} required>
        <Field
          component={InputGroupField}
          value={labelValue}
          name="label"
          placeholder={placeholders[LABEL_INPUT]}
          onChange={handleChange}
          validate={(value) => helpers.validateRequired(value)}
        />
      </ControlGroup>
      <ControlGroup title={i18next.t('Placeholder')}>
        <Field
          component={InputGroupField}
          value={placeholderValue}
          name="placeholder"
          placeholder={placeholders[PLACEHOLDER_INPUT]}
          onChange={handleChange}
          validate={(value) => helpers.validatePersonalData(value)}
        />
      </ControlGroup>
      <ControlGroup title={i18next.t('Time format')}>
        <Field
          name="timeFormat"
          render={(formikProps) => (
            <RadioButtonGroupField
              {...formikProps}
              radioButtons={[
                {
                  id: timeFormats['12_HOUR'],
                  label: i18next.t('12-Hour time format'),
                  isLabelBold: true,
                  value: timeFormats['12_HOUR'],
                },
                {
                  id: timeFormats['24_HOUR'],
                  label: i18next.t('24-Hour time format'),
                  isLabelBold: true,
                  value: timeFormats['24_HOUR'],
                },
              ]}
            />
          )}
        />
      </ControlGroup>
      <Field
        name="required"
        component={CheckboxField}
        id="displayRequireCheckbox"
        label={i18next.t('Make required')}
        onClick={onRequiredCheckboxClick}
      />
    </Stack>
  );
};

TimeFieldContent.propTypes = {
  labelValue: PropTypes.string,
  placeholderValue: PropTypes.string,
  placeholders: PropTypes.shape({
    [LABEL_INPUT]: PropTypes.string,
    [PLACEHOLDER_INPUT]: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  onRequiredCheckboxClick: PropTypes.func,
};

TimeFieldContent.defaultProps = {
  labelValue: '',
  placeholderValue: '',
  onRequiredCheckboxClick: utils.noop,
};

export default TimeFieldContent;
