import React from 'react';
import PropTypes from 'prop-types';

const AnchorHighlighter = ({ x, y, height }) => (
  <div className="ws-anchor-highlighter" style={{ top: y, left: x, height }} />
);

AnchorHighlighter.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default AnchorHighlighter;
