export const DEFAULT = 'default';
export const PREMIUM_BLOCKS = 'premium_blocks';
export const MOBILE_PLUS = 'mobile_plus';
export const CUSTOM_CODE = 'custom_code';
export const BRANDED_FOOTER = 'branded_footer';
export const BRANDED_HEADER = 'branded_header';
export const PAGES_COUNT = 'pages_count';
export const STORAGE_LIMIT = 'storage_limit';
export const UPLOAD_FILE_SIZE = 'upload_file_size';
export const ONLINE_STORE = 'online_store';
export const CUSTOM_DOMAIN = 'custom_domain';
export const NOFOLLOW_CONTROLS = 'nofollow_control';
export const SITE_BACKUPS = 'site_backups';
export const MULTILINGUAL = 'multilingual';
export const EXTERNAL_LINKS = 'external_links';
export const PREMIUM_ICONS = 'premium_icons';
export const ADVANCED_COLOR_CUSTOMIZATION = 'advanced_color_customization';
export const PASSWORD_PROTECTED_PAGES = 'password_protected_pages';
export const FORM_ATTACHMENTS = 'form_attachments';
export const AI_PAGE_WIZARD = 'ai_page_wizard';
export const AI_BLOCK_GENERATION_QUOTA = 'ai_block_generation_quota';
export const AI_TEXT_ASSISTANT_QUOTA = 'ai_text_assistant_quota';
export const PREMIUM_BUTTON_PRESETS = 'premium_button_presets';
export const PREMIUM_TEXT_DECORATIONS = 'premium_text_decorations';
