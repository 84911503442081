import { constraints } from '@yola/ws-sdk';

const dropTextResizing = (blockNode) => {
  const textNodes = blockNode.querySelectorAll(
    constraints.accessors.getContentEditableRootSelector()
  );
  textNodes.forEach((text) => text.style.removeProperty('width'));
};

export default dropTextResizing;
