import actionTypes from '../constants/action-types';

const templateSwitched = (state = false, action) => {
  switch (action.type) {
    case actionTypes.SET_TEMPLATE_SWITCHED:
      return action.payload.isTemplateSwitched;
    default:
      return state;
  }
};

export default templateSwitched;
