import { customization } from '@yola/ws-sdk';
import updateCustomColorPalettes from '../helpers/update-custom-color-palettes';
import updateColorPaletteCode from '../helpers/update-color-palette-code';

const colorPaletteMigration = async (store) => {
  const state = store.getState();
  const colorPaletteVersion = customization.selectors.getColorPaletteVersion(state);
  const currentColorPaletteVersion = customization.selectors.getCurrentColorPaletteVersion(state);
  let palette = customization.accessors.getCurrentColorPalette();
  const isColorPaletteVersionChanged = currentColorPaletteVersion !== colorPaletteVersion;

  if (!isColorPaletteVersionChanged && palette) return false;

  if (isColorPaletteVersionChanged) {
    await updateCustomColorPalettes();
    store.dispatch(customization.actions.setCurrentColorPaletteVersion(colorPaletteVersion));
    palette = customization.accessors.getCurrentColorPalette();
  }

  if (!palette) {
    const defaultColorPaletteId = customization.selectors.getDefaultColorPaletteId(state);
    palette = customization.selectors.getColorPalette(state, defaultColorPaletteId);
  }

  updateColorPaletteCode(palette);
  return true;
};

export default colorPaletteMigration;
