import { serviceClient } from '@yola/ws-sdk';

const loadTaxonomyOptions = (locale, callbackAfter, callbackBefore) => async (inputValue) => {
  if (callbackBefore) callbackBefore();

  let options = [];
  const trimmedValue = inputValue.trim();

  if (!trimmedValue) return options;

  try {
    const client = serviceClient.get();
    const { data } = await client.taxonomySearchCategories({ searchQuery: trimmedValue, locale });
    if (data && data.results) {
      options = data.results.map(({ title, category, archetypes }) => ({
        label: title,
        value: category,
        archetype: archetypes[0],
      }));
    }
  } catch (error) {
    console.error(error);
  }

  if (callbackAfter) callbackAfter(options);

  return options;
};

export default loadTaxonomyOptions;
