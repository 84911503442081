import React, { Fragment } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { view } from '@yola/ws-sdk';
import AnchorHighlighter from '../components/anchor-highlighter';
import getElementsForAnchorHighlighters from '../selectors/get-elements-for-unchor-highlighters';
import normaliseElementTopValue from '../helpers/normalise-element-top-value';

const AnchorHighlighterContainer = () => {
  const { scrollTop, elements } = useSelector(
    (state) => ({
      scrollTop: view.selectors.getScrollPosition(state),
      elements: getElementsForAnchorHighlighters(state),
      isViewLoaded: view.selectors.getLoadedStatus(state),
    }),
    shallowEqual
  );

  if (!elements.length) return null;

  const data = elements.map((element) => {
    const { top, right, height } = element.getBoundingClientRect();
    const elementTop = normaliseElementTopValue({ element, top, scrollTop });
    const anchorTop = elementTop + scrollTop;
    return {
      x: right,
      y: anchorTop,
      height,
    };
  });

  return (
    <Fragment>
      {data.map((styles) => (
        <AnchorHighlighter key={styles.x + styles.y} {...styles} />
      ))}
    </Fragment>
  );
};

export default AnchorHighlighterContainer;
