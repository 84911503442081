import sleep from './sleep';

const withRetry =
  (asyncFunc, retryCount = 3, sleepTime = 3000, counter = 0) =>
  async (...params) => {
    let result;
    try {
      result = await asyncFunc(...params);
    } catch (error) {
      await sleep(sleepTime);
      const newCounter = counter + 1;

      if (newCounter >= retryCount) throw error;

      return withRetry(asyncFunc, retryCount, sleepTime, newCounter)(...params);
    }

    return result;
  };

export default withRetry;
