import { hooks } from '@yola/ws-sdk';
import prepareContentEventTrackData from '../helpers/prepare-content-event-track-data';
import track from '../track';
import constants from '../constants';

const trackContentPasted = () => {
  hooks.operations.subscribe(hooks.availableHooks.ON_TEXT_PASTE, (event) => {
    const trackingData = prepareContentEventTrackData(event);
    if (!trackingData) return;

    track(constants.events.CONTENT_PASTED, trackingData);
  });
};

export default trackContentPasted;
