import { site, template } from '@yola/ws-sdk';
import segment from 'src/js/modules/analytics/segment';

const trackBlockLayoutSwitchTemplateButtonClicked = () => {
  const { track, constants } = segment;

  track(constants.events.BLOCK_LAYOUT_SWITCH_TEMPLATE_BUTTON_CLICKED, {
    siteId: site.accessors.getSiteId(),
    templateBuildSlug: template.accessors.getBuildSlug(),
  });
};

export default trackBlockLayoutSwitchTemplateButtonClicked;
