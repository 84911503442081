import getParentBlockByElement from './get-parent-block-by-element';

const getRootBlockByElement = (element) => {
  let targetElement = null;
  let currentElement = element;

  while (currentElement) {
    currentElement = getParentBlockByElement(currentElement);

    if (currentElement) {
      targetElement = currentElement;
      const { parentNode } = currentElement;
      currentElement = parentNode;
    }
  }

  return targetElement;
};

export default getRootBlockByElement;
