import { PROTOCOL_ICON_MAP } from '../../registry/ws-social-links/constants/icon-autocomplete-maps';

const getSupportedProtocol = (url) => {
  const protocols = Object.keys(PROTOCOL_ICON_MAP).join('|');
  const protocolsRegexpBase = `^(${protocols})`;
  const matchedData = url.match(protocolsRegexpBase);

  return matchedData && matchedData[0];
};

export default getSupportedProtocol;
