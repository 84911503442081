import { anodum } from '@yola/ws-sdk';
import getSelectedElement from '../helpers/get-selected-element';
import getListFromSelection from '../helpers/get-list-from-selection';

const { isTag } = anodum;

const isActiveList = (listType) => {
  const element = getSelectedElement();
  const selectedList = getListFromSelection(element);

  return Boolean(selectedList && isTag(selectedList, listType));
};

export default isActiveList;
