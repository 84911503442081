import { integration, saving, view, hooks } from '@yola/ws-sdk';
import store from '../../../store';

const redirectToStore = () => {
  const state = store.getState();
  const settings = integration.selectors.getSettings(state);
  const focusedElement = view.selectors.getFocusedElement(state);

  const redirectWithSave = () => {
    store.dispatch(saving.thunks.save()).then(() => {
      if (settings.onlinestore) {
        settings.onlinestore.redirect();
      }
    });
  };

  if (focusedElement) {
    hooks.operations.subscribe(
      hooks.availableHooks.ON_AFTER_BLUR_FOCUSED_ELEMENT,
      () => {
        redirectWithSave();
      },
      {
        once: true,
      }
    );
  } else {
    redirectWithSave();
  }
};

export default redirectToStore;
