import actionTypes from '../constants/action-types';

const orderSubscriptionPlan = ({ productType, sku }) => ({
  type: actionTypes.ORDER_SUBSCRIPTION_PLAN,
  payload: {
    productType,
    sku,
  },
});

export default orderSubscriptionPlan;
