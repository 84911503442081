import bowser from 'yola-bowser';
import { saving } from '@yola/ws-sdk';
import saveSite from 'src/js/modules/saving/helpers/save-site';
import store from '../../../store';

const S_KEY = 's';
const { UNSAVED } = saving.constants.statuses;

const saveHandler = (event) => {
  const { ctrlKey, key, metaKey, __detail } = event;

  if (__detail && __detail.contentEditable) return;

  const isLinux = bowser.linux;
  const isMacOS = bowser.macOS;
  const isWindows = bowser.windows;

  let isSave;
  if (isMacOS) {
    isSave = key.toLowerCase() === S_KEY && metaKey;
  } else if (isWindows || isLinux) {
    isSave = key.toLowerCase() === S_KEY && ctrlKey;
  }

  if (isSave) {
    event.preventDefault();
    event.stopPropagation();
  }

  const state = store.getState();
  if (!isSave || saving.selectors.getStatus(state) !== UNSAVED) {
    return;
  }

  saveSite();
};

export default saveHandler;
