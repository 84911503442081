import React from 'react';
import { extensions, i18next, dialogs, view } from '@yola/ws-sdk';
import { Icon } from '@yola/ws-ui';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import constants from '../constants';
import Trigger from '../../../../common/components/trigger';
import parseItemsFromDOM from '../helpers/parse-items-from-dom';

const { triggerId } = constants.common;
const { controlTypes } = extensions;

const control = {
  id: triggerId,
  trigger: (
    <Trigger id={triggerId}>
      <Icon glyph="hierarchy" size="24" strokeWidth="1.3" />
    </Trigger>
  ),
  onTriggerClick: (elementId) => {
    const element = view.accessors.getLiveElement(elementId);
    const initialItems = parseItemsFromDOM(element);
    dialogs.operations.show(dialogTypes.FOOTER_NAVIGATION_SETTINGS_DIALOG, {
      elementId,
      initialItems,
      items: initialItems,
    });
  },
  get title() {
    return i18next.t('List settings');
  },

  get tooltip() {
    return i18next.t('List settings');
  },
  for: [controlTypes.ELEMENT],
  matches(liveElement) {
    const settings = extensions.accessors.getExtensionSettings(constants.common.slug);
    return liveElement.matches(settings.querySelector);
  },
  priority: 100,
};

export default control;
