import React from 'react';
import PropTypes from 'prop-types';
import { utils, siteLocales, linkEditing } from '@yola/ws-sdk';
import { designSystem } from '@yola/ws-ui';
import PageSelector from './page-selector';
import constants from '../constants';

const {
  linkSettings: { INPUTS_IDS },
} = constants;

const { linkTypes } = linkEditing.constants;
const { InputGroup, SimpleSelect, SearchableSelect, Stack, FilePicker } = designSystem;

const findOptionByValue = (value, options) => {
  const isGroupOptions = Boolean(options[0]?.options);
  let allOptions = options;

  if (isGroupOptions) {
    allOptions = options.reduce(
      (resultArray, currentOption) => resultArray.concat(currentOption.options),
      []
    );
  }

  return allOptions.find((option) => option.value === value);
};

function LinkSelector({
  values,
  latestAnchorReference,
  options,
  captions,
  onChange,
  onInputBlur,
  hasError,
  onFileUpload,
  onClick,
  disabled,
}) {
  const { linkType, reference } = values;
  const { pagesOptions, blocksOptions, linkTypeOptions } = options;
  const { blocksCaptions, pagesCaptions, fileUploadCaptions } = captions;
  const currentLocale = siteLocales.accessors.getCurrentLocale();
  const defaultLinkTypeOption = findOptionByValue(linkType, linkTypeOptions);

  const getInnerOptionsByLinkType = (type) => {
    switch (type) {
      case linkTypes.ANCHOR:
        return blocksOptions;
      case linkTypes.PAGE:
        return pagesOptions;
      default:
        return [];
    }
  };

  const innerOptions = getInnerOptionsByLinkType(linkType);

  const onUpload = (fileSet) => {
    onFileUpload(fileSet[0]);
  };

  const onSelectActionType = (option) => {
    const { value: selectedActionType } = option;
    const selectedOptions = getInnerOptionsByLinkType(selectedActionType);
    let selectedReference = '';
    let selectedPageId = null;

    onClick(INPUTS_IDS.actionTypeInput, selectedActionType);

    if (selectedActionType === linkTypes.PAGE) {
      selectedReference = selectedOptions.find((page) => page.locale === currentLocale).value;
    }

    if (selectedActionType === linkTypes.ANCHOR && Boolean(latestAnchorReference)) {
      selectedReference = latestAnchorReference;
      selectedPageId = findOptionByValue(latestAnchorReference, selectedOptions).pageId;
    }

    onChange({
      linkType: selectedActionType,
      reference: selectedReference,
      pageId: selectedPageId,
    });
  };

  const onSelectPage = (option) => {
    onChange({
      linkType,
      reference: option.value,
    });
  };

  const onSelectAnchor = (option) => {
    onChange({
      linkType,
      reference: option.value,
      pageId: option.pageId,
    });
  };

  const onResetUploadFileInput = () => {
    onChange({
      linkType,
      reference: '',
    });
  };

  const onTypeAction = (value) => {
    onChange({
      linkType,
      reference: value,
    });
  };

  const handleClick = (inputId) => () => {
    onClick(inputId);
  };

  return (
    <Stack gap="spacing-3-xs">
      <SimpleSelect
        name="linkType"
        options={linkTypeOptions}
        defaultValue={defaultLinkTypeOption}
        onChange={onSelectActionType}
      />

      {(() => {
        switch (linkType) {
          case linkTypes.VIEW_FULL_SIZE_IMAGE:
          case linkTypes.NO_ACTION:
            return null;
          case linkTypes.PHONE:
            return (
              <InputGroup
                name="reference"
                value={reference}
                invalid={hasError}
                onBlur={onInputBlur}
                onClick={handleClick(INPUTS_IDS.phoneInput)}
                onChange={onTypeAction}
                placeholder="+1-234-5678910"
              />
            );
          case linkTypes.MAIL:
            return (
              <InputGroup
                name="reference"
                value={reference}
                invalid={hasError}
                onBlur={onInputBlur}
                onClick={handleClick(INPUTS_IDS.emailInput)}
                onChange={onTypeAction}
                placeholder="example@email.com"
              />
            );
          case linkTypes.EXTERNAL:
            return (
              <InputGroup
                disabled={disabled}
                name="reference"
                value={reference}
                invalid={hasError}
                onBlur={onInputBlur}
                onClick={handleClick(INPUTS_IDS.linkInput)}
                onChange={onTypeAction}
                placeholder="https://example.com"
              />
            );
          case linkTypes.SOCIAL_MEDIA:
            return (
              <InputGroup
                disabled={disabled}
                name="reference"
                value={reference}
                invalid={hasError}
                onBlur={onInputBlur}
                onClick={handleClick(INPUTS_IDS.socialMediaInput)}
                onChange={onTypeAction}
                placeholder="https://facebook.com/profile"
              />
            );
          case linkTypes.WHATS_APP:
            return (
              <InputGroup
                disabled={disabled}
                name="reference"
                value={reference}
                invalid={hasError}
                onBlur={onInputBlur}
                onClick={handleClick(INPUTS_IDS.whatsAppInput)}
                onChange={onTypeAction}
                placeholder="https://wa.me/"
              />
            );
          case linkTypes.DOWNLOAD:
            return (
              <FilePicker
                onChange={onUpload}
                onClick={handleClick(INPUTS_IDS.downloadInput)}
                label={fileUploadCaptions.button}
                fileItemName={fileUploadCaptions.fileItemName}
                placeholder={fileUploadCaptions.empty}
                onClear={onResetUploadFileInput}
              />
            );

          case linkTypes.PAGE:
            return (
              <PageSelector
                onChange={onChange}
                linkType={linkType}
                reference={reference}
                onSelectActionValue={onSelectPage}
                options={innerOptions}
                captions={pagesCaptions}
              />
            );
          default:
            // eslint-disable-next-line no-case-declarations
            const currentOption = findOptionByValue(reference, innerOptions);

            return (
              <SearchableSelect
                name="reference"
                placeholder={blocksCaptions.placeholder}
                noResultsText={blocksCaptions.noResultsText}
                options={innerOptions}
                defaultValue={currentOption}
                invalid={hasError}
                onChange={onSelectAnchor}
              />
            );
        }
      })()}
    </Stack>
  );
}

LinkSelector.propTypes = {
  values: PropTypes.shape({
    linkType: PropTypes.oneOf(Object.values(linkTypes)).isRequired,
    reference: PropTypes.string,
  }).isRequired,
  latestAnchorReference: PropTypes.string.isRequired,
  options: PropTypes.shape({
    pagesOptions: PropTypes.array.isRequired,
    blocksOptions: PropTypes.array.isRequired,
    linkTypeOptions: PropTypes.array.isRequired,
  }).isRequired,
  captions: PropTypes.shape({
    fileUploadCaptions: PropTypes.shape({
      button: PropTypes.string.isRequired,
      empty: PropTypes.string.isRequired,
      fileItemName: PropTypes.string,
    }),
    pagesCaptions: PropTypes.shape({
      noResultsText: PropTypes.string,
    }),
    blocksCaptions: PropTypes.shape({
      placeholder: PropTypes.string,
      noResultsText: PropTypes.string,
    }),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onInputBlur: PropTypes.func,
  onFileUpload: PropTypes.func,
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

LinkSelector.defaultProps = {
  hasError: false,
  onInputBlur: utils.noop,
  onFileUpload: utils.noop,
  disabled: false,
  onClick: utils.noop,
};

export default LinkSelector;
