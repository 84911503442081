import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';

const { Box, ActionButton, IconButton } = designSystem;

const WizardSetupTopPanel = (props) => {
  const { backButtonLabel, showBackButton, isDesktopView, onBackButtonClick, onCloseButtonClick } =
    props;

  return (
    <div className="ws-wizard-setup-top-panel">
      <Box
        {...(isDesktopView
          ? { padding: 'spacing-xs' }
          : { padding: 'spacing-xs', paddingTop: 'spacing-2-xs' })}
      >
        <div className="ws-wizard-setup-top-panel__box">
          {showBackButton && (
            <ActionButton
              format="text"
              iconGlyph="back"
              size="small"
              label={backButtonLabel}
              onClick={onBackButtonClick}
            />
          )}
          <IconButton glyph="close" size="medium" onClick={onCloseButtonClick} />
        </div>
      </Box>
    </div>
  );
};

WizardSetupTopPanel.propTypes = {
  backButtonLabel: PropTypes.string.isRequired,
  showBackButton: PropTypes.bool.isRequired,
  isDesktopView: PropTypes.bool.isRequired,
  onBackButtonClick: PropTypes.func.isRequired,
  onCloseButtonClick: PropTypes.func.isRequired,
};

export default WizardSetupTopPanel;
