const getPermissionStatus = async (permissionName) => {
  let permissionStatus;

  try {
    permissionStatus = await navigator.permissions.query({ name: permissionName });
  } catch (e) {
    console.error(e);
    return null;
  }

  return permissionStatus.state;
};

export default getPermissionStatus;
