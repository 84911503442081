const getInlineImageBase64 = (iconSrc) =>
  new Promise((resolve, reject) => {
    const image = document.querySelector(`[src="${iconSrc}"]`);
    image.crossOrigin = 'anonymous';

    const toDataURL = () => {
      const canvas = document.createElement('canvas');
      canvas.width = image.naturalWidth;
      canvas.height = image.naturalHeight;
      canvas.getContext('2d').drawImage(image, 0, 0);
      const base64Src = canvas.toDataURL();
      resolve(base64Src);
    };

    if (image.complete) {
      toDataURL();
    } else {
      image.onload = toDataURL;
      image.onerror = reject;
    }
  });

export default getInlineImageBase64;
