import { view, hdrm, i18next, anodum } from '@yola/ws-sdk';

const { text } = hdrm.injections.bindings;
const { getClosestSelectableElement } = hdrm.helpers;

const getTooltipInfo = (hoveredElementId) => {
  const hoveredElement = view.accessors.getLiveElement(hoveredElementId);

  if (!hoveredElement) {
    return null;
  }

  const focusableElement = text.getRootTextElement(hoveredElement);
  const selectableElement = getClosestSelectableElement(hoveredElement);

  if (!focusableElement && !selectableElement) {
    return null;
  }

  let targetElement = focusableElement;
  let tooltipTitle = i18next.t('Edit');
  let shouldSetCursorPointer = false;

  if (!focusableElement || anodum.isSameOrContains(focusableElement, selectableElement)) {
    targetElement = selectableElement;
    tooltipTitle = i18next.t('Customize');
    shouldSetCursorPointer = true;
  }

  const targetElementId = view.accessors.getLiveElementId(targetElement);

  return {
    targetElementId,
    tooltipTitle,
    shouldSetCursorPointer,
  };
};

export default getTooltipInfo;
