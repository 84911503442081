import React, { useEffect, useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { dialogs, i18next, assets, view, serviceClient } from '@yola/ws-sdk';
import { ProgressDialog } from '@yola/ws-ui';
import withRetry from 'src/js/modules/utils/with-retry';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import getGroupAspectRatio from '../helpers/get-group-aspect-ratio';
import getMediaGroupId from '../helpers/get-media-group-id';
import generateItemId from '../helpers/generate-item-id';
import getImageData from '../helpers/get-image-data';
import generateImageThumbnailSource from '../helpers/generate-image-thumbnail-source';
import getGalleryItemAreaBounds from '../helpers/get-gallery-item-area-bounds';

const { PHOTO_LOAD_ERROR, GALLERY_DIALOG } = dialogTypes;

const defaultCaptions = (imageForLoadTotal, imageCount) => ({
  title: i18next.t('{progress}/{total} images uploaded', {
    total: imageForLoadTotal,
    progress: imageCount,
  }),
  description: i18next.t('Your uploads will be complete momentarily'),
  cancel: i18next.t('Cancel'),
});

function PhotoStockSavingDialog(props) {
  const { items, initialItems, elementId, selectedPhotos, onDialogCancel } = props;
  const imageForLoadTotal = selectedPhotos.length;
  const [count, setCounter] = useState(0);
  let counter = 0;
  const abortRequestController = useMemo(() => new AbortController(), []);

  const progress = (i) => {
    setCounter(i);
  };

  const handleCancel = useCallback(() => {
    abortRequestController.abort();
    onDialogCancel();
    dialogs.operations.hide();
  }, [abortRequestController, onDialogCancel]);

  const onDismiss = useCallback(() => {
    onDialogCancel({ dialogType: PHOTO_LOAD_ERROR });

    dialogs.operations.show(GALLERY_DIALOG, {
      items,
      elementId,
    });
  }, [elementId, items, onDialogCancel]);

  const handleOperation = useCallback(() => {
    const client = serviceClient.get();
    const unsplashCreateAssetsFromPhotoWithRetry = withRetry(
      client.unsplashCreateAssetsFromPhoto.bind(client)
    );
    const getImageDataWithRetry = withRetry(getImageData);

    const operation = selectedPhotos.map(async (item) => {
      const { data } = await unsplashCreateAssetsFromPhotoWithRetry(item.id);
      const { source: assetSource, name: assetName } = data;

      assets.operations.makePublishable(assetSource);

      // eslint-disable-next-line no-plusplus
      progress(++counter);

      const liveDoc = view.accessors.getLiveDocument();
      const { width, height } = await getImageDataWithRetry(assetSource, liveDoc.defaultView);
      const imageContainerSize = getGalleryItemAreaBounds(elementId);

      const thumbnailSource = await generateImageThumbnailSource({
        assetSource,
        assetName,
        imageSize: { width, height },
        imageContainerSize,
        abortRequestController,
        isStockPhoto: true,
      });

      return {
        id: generateItemId(counter, Date.now()),
        originWidth: width,
        originHeight: height,
        aspectRatio: getGroupAspectRatio(items),
        originSrc: assetSource,
        src: thumbnailSource,
        alt: '',
        title: '',
        description: '',
        contentPosition: '50% 50%',
        contentScale: 1,
        mediaGroupId: getMediaGroupId(items),
        isSaved: true,
      };
    });

    Promise.all(operation)
      .then((value) => {
        const operationResult = value.filter((item) => item);
        let result = items;

        if (operationResult.length) {
          result = [...items, ...value];
        }

        return result;
      })
      .then((result) => {
        dialogs.operations.show(GALLERY_DIALOG, {
          items: result,
          elementId,
        });
      })
      .catch((error) => {
        if (abortRequestController?.signal?.aborted) {
          dialogs.operations.show(GALLERY_DIALOG, {
            items: initialItems.length ? initialItems : items,
            elementId,
          });
        } else {
          dialogs.operations.show(PHOTO_LOAD_ERROR, {
            onDismiss,
            isApiRateExceeded: Boolean(error && error.code === 429),
          });
        }
      });
  }, [selectedPhotos, counter, elementId, abortRequestController, items, initialItems, onDismiss]);

  useEffect(() => {
    handleOperation();
  }, [handleOperation]);

  return (
    <ProgressDialog
      captions={defaultCaptions(imageForLoadTotal, count)}
      value={(count / imageForLoadTotal) * 100}
      cancelable
      overlay="visible"
      containerClassName="ws-loading-progress-dialog ws-dialog-fade"
      onCancel={handleCancel}
    />
  );
}

PhotoStockSavingDialog.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  initialItems: PropTypes.arrayOf(PropTypes.shape({})),
  selectedPhotos: PropTypes.arrayOf(PropTypes.shape({})),
  elementId: PropTypes.string.isRequired,
  // Passed from ws-editor's `dialogs-container`:
  onDialogCancel: PropTypes.func.isRequired,
};

PhotoStockSavingDialog.defaultProps = {
  items: [],
  initialItems: [],
  selectedPhotos: [],
};

export default PhotoStockSavingDialog;
