import React from 'react';
import { extensions, i18next, template } from '@yola/ws-sdk';
import constants from '../constants';
import TextColorTrigger from '../components/text-color-trigger';
import CustomColorHeader from '../components/custom-color-header';

const { controlTypes } = extensions;
const triggerId = 'text-color';

const textColorControl = {
  id: triggerId,
  trigger: <TextColorTrigger triggerId={triggerId} />,
  header: CustomColorHeader,
  closeIcon: 'submit',
  get tooltip() {
    return i18next.t('Text color');
  },
  priority: 60,
  for: [controlTypes.TEXT],
  matches: (element) => {
    const isMpt = template.verifiers.isMpt();
    if (!isMpt) return false;

    const settings = extensions.accessors.getExtensionSettings(constants.common.SLUG);
    const { textColorRestrictedParentsSelector } = settings;

    if (textColorRestrictedParentsSelector && element.closest(textColorRestrictedParentsSelector)) {
      return false;
    }

    return element.matches(settings.textColorQuerySelector);
  },
  highlightElement: false,
};

export default textColorControl;
