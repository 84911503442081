const getStatusOfChangedData = (state, initialValues) => ({
  isClickActionChanged:
    state.linkConfig.linkType !== initialValues.linkConfig.linkType ||
    state.linkConfig.reference !== initialValues.linkConfig.reference ||
    state.linkConfig.target !== initialValues.linkConfig.target ||
    state.linkConfig.rel !== initialValues.linkConfig.rel ||
    state.linkConfig.download !== initialValues.linkConfig.download,
  isSrcChanged: initialValues.src !== state.src,
  isTitleChanged: initialValues.title !== state.title,
  isAltChanged: initialValues.alt !== state.alt,
  isDescriptionChanged: initialValues.description !== state.description,
  isScaleChanged: initialValues.contentScale !== state.contentScale,
  isPositionChanged: initialValues.contentPosition !== state.contentPosition,
});

export default getStatusOfChangedData;
