import { hdrm } from '@yola/ws-sdk';
import isEqualBounds from '../../common/helpers/is-equal-bounds';
import matchBlockElement from '../../common/helpers/match-block-element';
import isLessBounds from './is-less-bounds';
import addInteractiveElement from './add-interactive-element';
import shouldBeTakenAsSameBounds from './should-be-taken-as-same-bounds';

const getSameBoundsElements = (sourceElement) => {
  const sameBoundsElements = [];
  let temporaryElements = [];

  addInteractiveElement(sameBoundsElements, sourceElement);
  let targetElementBounds = sourceElement.getBoundingClientRect();
  let elementId = hdrm.instance.driver.referenceMap.getParentReferenceId(sourceElement);
  let element = hdrm.accessors.getLiveElement(elementId);

  while (element && !matchBlockElement(element)) {
    const elementBounds = element.getBoundingClientRect();

    if (
      (!temporaryElements.length && isEqualBounds(elementBounds, targetElementBounds)) ||
      shouldBeTakenAsSameBounds(sourceElement, element)
    ) {
      addInteractiveElement(sameBoundsElements, element);
    } else {
      const liveDocument = hdrm.instance.driver.getLiveDocument();
      const elementStyles = liveDocument.defaultView.getComputedStyle(element, null);

      if (isLessBounds(targetElementBounds, elementBounds) && elementStyles.overflow === 'hidden') {
        temporaryElements.forEach((temporaryElement) => {
          addInteractiveElement(sameBoundsElements, temporaryElement);
        });

        temporaryElements = [];

        targetElementBounds = elementBounds;

        addInteractiveElement(sameBoundsElements, element);
      } else {
        temporaryElements.push(element);
      }
    }

    elementId = hdrm.instance.driver.referenceMap.getParentReferenceId(element);
    element = hdrm.accessors.getLiveElement(elementId);
  }

  return {
    sameBoundsElements,
    targetElementBounds,
  };
};

export default getSameBoundsElements;
