import isEqual from 'lodash.isequal';
import omit from 'lodash.omit';

const getItemsEditedCount = (initialItems, updatedItems) => {
  const initialItemsMap = initialItems.reduce(
    (map, item) => ({
      ...map,
      [item.id]: {
        ...item,
      },
    }),
    {}
  );

  return updatedItems.reduce((result, updatedItem) => {
    const { id } = updatedItem;

    const initialItem = initialItemsMap[id];

    if (
      initialItem &&
      !isEqual(omit(initialItem, ['order']), omit(updatedItem, ['order', 'title', 'actions']))
    ) {
      return result + 1;
    }

    return result;
  }, 0);
};

export default getItemsEditedCount;
