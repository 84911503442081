import { view, extensions } from '@yola/ws-sdk';
import constants from '../constants/common';
import parseItemsFromDOM from './parse-items-from-dom';
import getWsNav from './get-ws-nav';

const getInitialItems = (elementId) => {
  const targetElement = view.accessors.getLiveElement(elementId);
  const { querySelector } = extensions.accessors.getExtensionSettings(constants.SLUG);
  const navComponent = getWsNav();
  const { selectors } = navComponent;
  const autocollapsingAttribute =
    navComponent.getAutocollapsingAttribute && navComponent.getAutocollapsingAttribute();
  const navigation = targetElement.matches(querySelector)
    ? targetElement
    : targetElement.querySelector(selectors.menu);
  const navigationContainer = navigation.parentElement;

  return parseItemsFromDOM(
    navigationContainer,
    selectors.menu,
    selectors.menuItem,
    selectors,
    [],
    autocollapsingAttribute
  );
};

export default getInitialItems;
