import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@yola/ws-ui';
import { contentEditable, textFormatting } from '@yola/ws-sdk';
import Trigger from 'src/js/modules/common/components/trigger';
import withSelection from 'src/js/modules/utils/with-selection';
import restoreRange from '../helpers/restore-range';
import clearFormatting from '../helpers/clear-formatting';

const ClearFormattingTrigger = ({ icon, elementId, triggerId, getTooltip, getContext }) => {
  const handleClick = () => {
    const selection = textFormatting.accessors.getAdvancedSelection();
    restoreRange(selection, elementId);

    contentEditable.operations.addCheckpoint();
    clearFormatting(selection, elementId);

    if (selection.isCollapsed) {
      const { controlPane } = getContext();
      controlPane.forceControlPaneRerender();
    }
  };
  return (
    <Trigger id={triggerId} data-tip={getTooltip()} onClick={handleClick}>
      <Icon glyph={icon} size="16" strokeWidth="1.8" />
    </Trigger>
  );
};

ClearFormattingTrigger.propTypes = {
  icon: PropTypes.string.isRequired,
  triggerId: PropTypes.string.isRequired,
  elementId: PropTypes.string.isRequired,
  getTooltip: PropTypes.func,
  getContext: PropTypes.func.isRequired,
};

ClearFormattingTrigger.defaultProps = {
  getTooltip: Function.prototype,
};

export default withSelection(ClearFormattingTrigger);
