import store from 'src/js/store';
import selectors from '../selectors';

const getHeight = () => {
  const state = store.getState();

  return selectors.getHeight(state);
};

export default getHeight;
