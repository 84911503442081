import React from 'react';
import PropTypes from 'prop-types';
import { i18next } from '@yola/ws-sdk';
import { SubmitDialog } from '@yola/ws-ui';
import constants from '../constants';

const { CONFIRMATION_DIALOG_WIDTH, CONFIRMATION_DIALOG_HEIGHT } = constants;

function GallerySaveChangesConfirmationDialog({
  onSubmit,
  onCancel,
  onDialogMainAction,
  onDialogCancel,
}) {
  const handleSubmit = (...args) => {
    onDialogMainAction();
    onSubmit(...args);
  };

  const handleCancel = (...args) => {
    onDialogCancel();
    onCancel(...args);
  };

  return (
    <SubmitDialog
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      width={CONFIRMATION_DIALOG_WIDTH}
      height={CONFIRMATION_DIALOG_HEIGHT}
      minHeight={CONFIRMATION_DIALOG_HEIGHT}
      captions={{
        title: i18next.t('Save changes?'),
        description: i18next.t('Do you want to save changes you made in gallery settings?'),
        submit: i18next.t('Save'),
        cancel: i18next.t('Cancel'),
      }}
    />
  );
}

GallerySaveChangesConfirmationDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  // Passed from ws-editor's `dialogs-container`:
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

export default GallerySaveChangesConfirmationDialog;
