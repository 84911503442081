import React from 'react';

const CONTROL_PANE_TRIGGER_DIVIDER_WIDTH = 1;

function ControlPaneTriggerDivider() {
  return (
    <div
      className="ws-control-pane-trigger-divider"
      style={{ width: CONTROL_PANE_TRIGGER_DIVIDER_WIDTH }}
    />
  );
}

export { CONTROL_PANE_TRIGGER_DIVIDER_WIDTH };
export default ControlPaneTriggerDivider;
