import snakeCase from 'lodash.snakecase';

const isObject = (value) => value && typeof value === 'object' && !Array.isArray(value);

export function capitalize(word) {
  if (!word) {
    return '';
  }
  return `${word[0].toUpperCase()}${word.slice(1)}`;
}

export function decapitalize(word) {
  if (!word) {
    return '';
  }
  return `${word[0].toLowerCase()}${word.slice(1)}`;
}

export function camelCaseToSnakeCase(string) {
  return string
    .replace(/(.)([A-Z][a-z]+)/g, '$1_$2') // insert underscores before words
    .replace(/([a-z0-9])([A-Z]+)/g, '$1_$2') // insert underscores before abbreviations
    .toLowerCase();
}

export function snakeCaseToCamelCase(string) {
  const camelCaseString = string.split('_').map(capitalize).join('');
  return decapitalize(camelCaseString);
}

export function snakeCaseObjectToCamelCase(obj) {
  const isArray = Array.isArray(obj);
  return Object.keys(obj)
    .map((key) => {
      let value = obj[key];
      if (isObject(value) || Array.isArray(value)) {
        value = snakeCaseObjectToCamelCase(value);
      }
      return { [snakeCaseToCamelCase(key)]: value };
    })
    .reduce(
      (accumulator, currentValue) => Object.assign(accumulator, currentValue),
      isArray ? [] : {}
    );
}

export const camelCaseObjectToSnakeCase = (obj) => {
  const objValues = Object.keys(obj).map((key) => {
    const newKey = snakeCase(key);

    return { [newKey]: obj[key] };
  });

  return Object.assign({}, ...objValues);
};

export const dashedToCamelCase = (name) =>
  name
    .split('-')
    .filter(Boolean)
    .map((word, indx) => (indx === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)))
    .join('');
