import React from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { dialogs } from '@yola/ws-sdk';
import yousers from '@yola/yousersjs';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import user from 'yola-editor/src/js/modules/user';
import segment from '../../analytics/segment';
import DifmPromoDialog from '../components/difm-promo-dialog';
import { TRIGGER_IDS } from '../constants/traits';
import getWlDifmRedirectUrl from '../helpers/get-wl-difm-redirect-url';

const {
  trackers: { trackEvent },
  constants: { events },
} = segment;

const DifmPromoDialogContainer = ({ values, onDialogCancel, onDialogMainAction, triggerId }) => {
  const { show_difm_banner: showNotCompletedDifmBanner } = useSelector(
    user.selectors.getUserData,
    shallowEqual
  );
  const isWhiteLabel = useSelector(user.selectors.getWhiteLabelStatus);
  const upgradeUrl = getWlDifmRedirectUrl();

  const handleSubmit = () => {
    onDialogMainAction();

    if (isWhiteLabel) {
      yousers.requests.acceptDifmRequest();
      dialogs.operations.hide();

      return;
    }

    dialogs.operations.show(dialogTypes.DIFM_PROMO_SERVICES_DIALOG, {
      values,
      triggerId,
    });
  };

  const closeDialog = () => {
    dialogs.operations.hide();
    onDialogCancel({
      selectedService: values.serviceName,
    });
  };

  const handleCancel = () => {
    yousers.requests.remindLaterOfDifm();
    trackEvent(events.DIFM_PROMO_DIALOG_REMIND_ME_LATER_CLICKED);
    closeDialog();
  };

  const handleDialogClose = () => {
    yousers.requests.declineDifm();

    if (showNotCompletedDifmBanner) {
      trackEvent(events.DIFM_PROMO_DIALOG_DONT_NEED_TRIGGER_CLICKED);
    }

    closeDialog();
  };

  return (
    <DifmPromoDialog
      isAlreadyCompleted={!showNotCompletedDifmBanner}
      onSubmit={handleSubmit}
      submitHref={isWhiteLabel ? upgradeUrl : ''}
      onCancel={handleCancel}
      onDialogClose={handleDialogClose}
    />
  );
};

DifmPromoDialogContainer.defaultProps = {
  values: {},
};

DifmPromoDialogContainer.propTypes = {
  triggerId: PropTypes.oneOf(Object.values(TRIGGER_IDS)).isRequired,
  values: PropTypes.shape({ serviceName: PropTypes.string }),
  onDialogCancel: PropTypes.func.isRequired,
  onDialogMainAction: PropTypes.func.isRequired,
};

export default DifmPromoDialogContainer;
