import React from 'react';

const LongTextIcon = () => (
  <svg width="144" height="90" viewBox="0 0 144 90" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="20" y="21" width="104" height="48" rx="4" fill="#DADDE0" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.6498 27C27.0088 27 27.2998 27.291 27.2998 27.65L27.2998 37.65C27.2998 38.009 27.0088 38.3 26.6498 38.3C26.2908 38.3 25.9998 38.009 25.9998 37.65L25.9998 27.65C25.9998 27.291 26.2908 27 26.6498 27Z"
      fill="#646C75"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M120.117 58.5404C120.37 58.7942 120.37 59.2058 120.117 59.4596L114.46 65.1165C114.206 65.3703 113.794 65.3703 113.54 65.1165C113.287 64.8626 113.287 64.4511 113.54 64.1972L119.197 58.5404C119.451 58.2865 119.863 58.2865 120.117 58.5404Z"
      fill="#646C75"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M120.824 62.0759C121.078 62.3297 121.078 62.7413 120.824 62.9951L117.996 65.8236C117.742 66.0774 117.33 66.0774 117.076 65.8236C116.823 65.5697 116.823 65.1582 117.076 64.9043L119.905 62.0759C120.159 61.8221 120.57 61.8221 120.824 62.0759Z"
      fill="#646C75"
    />
  </svg>
);

export default LongTextIcon;
