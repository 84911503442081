import React from 'react';
import PropTypes from 'prop-types';

function TriggerLabel({ children }) {
  return <span className="ws-trigger-label">{children}</span>;
}

TriggerLabel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
};

export default TriggerLabel;
