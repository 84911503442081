import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Dropdown, DropdownTrigger, DropdownContent, designSystem } from '@yola/ws-ui';

const { EditorHeader, ActionListItem, ActionListDivider, ActionList, ActionListLink } =
  designSystem;

class MainMenu extends React.Component {
  static hide() {
    Dropdown.dispatchHide();
  }

  constructor(props) {
    super(props);

    this.state = {
      activeId: 0,
    };

    this.setActiveItem = this.setActiveItem.bind(this);
  }

  setActiveItem(e) {
    const activeId = Number(e.target.dataset.index);
    this.setState({
      activeId,
    });
  }

  renderItem(item, key) {
    const { onClick, useEvent, href, disabled, target, type, heading, labelFor } = item;

    if (type === 'divider') {
      return <ActionListDivider key={key} />;
    }

    const actionItemProps = {
      disabled,
      'data-index': key,
      title: heading,
      onMouseEnter: this.setActiveItem,
      onClick: (e) => onClick && onClick(useEvent ? e : undefined),
    };

    if (labelFor) {
      return (
        <DropdownTrigger for={labelFor} key={key}>
          <ActionListItem {...actionItemProps} />
        </DropdownTrigger>
      );
    }

    if (href) {
      return <ActionListLink key={key} href={href} target={target} {...actionItemProps} />;
    }

    return <ActionListItem key={key} {...actionItemProps} />;
  }

  render() {
    const { items, className, captions, showDetails, id, onShow, isHidden, href } = this.props;
    const { activeId } = this.state;
    const activeItem = items[activeId];

    const mainMenuClasses = classNames('ws-mainmenu', className, {
      'ws-mainmenu--hidden': isHidden,
    });

    return (
      <div className={mainMenuClasses}>
        {href && !isHidden && (
          <EditorHeader.NavItem href={href} target="blank" label={captions.name} />
        )}

        {!href && (
          <Dropdown drop="bottom-left" onShow={onShow} id={id} removeElement>
            {!isHidden && (
              <DropdownTrigger>
                <EditorHeader.NavItem label={captions.name} />
              </DropdownTrigger>
            )}
            {isHidden && <div className="ws-mainmenu__blank" />}
            <DropdownContent>
              <ActionList>
                {items.map((item, key) => this.renderItem(item, key, activeId))}
              </ActionList>
              {activeItem && showDetails && (
                <div className="ws-mainmenu__description">
                  <img
                    className="ws-mainmenu__description-image"
                    src={activeItem.image}
                    alt={activeItem.title}
                  />
                  <div className="ws-mainmenu__description-title">{activeItem.heading}</div>
                  <div className="ws-mainmenu__description-text">{activeItem.description}</div>
                </div>
              )}
            </DropdownContent>
          </Dropdown>
        )}
      </div>
    );
  }
}

MainMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      heading: PropTypes.string,
      description: PropTypes.string,
      title: PropTypes.string,
      useEvent: PropTypes.bool,
      onClick: PropTypes.func,
    })
  ),
  captions: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  showDetails: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string,
  onShow: PropTypes.func,
  isHidden: PropTypes.bool,
  href: PropTypes.string,
};

MainMenu.defaultProps = {
  items: [],
  className: null,
  showDetails: false,
  id: null,
  onShow: Function.prototype,
  isHidden: false,
  href: null,
};

export default MainMenu;
