import { extensions, site } from '@yola/ws-sdk';
import localConfig from '../constants/common';
import getUpdatedMapSource from './get-updated-map-source';

const setDefaultAttributes = (element) => {
  const settings = extensions.accessors.getExtensionSettings(localConfig.slug);
  const siteSettings = site.accessors.getSettings();
  const { defaultAddress, defaultSource, querySelector } = settings;
  const { addresses } = siteSettings;
  const initialAddress = addresses?.length ? addresses[0] : defaultAddress;

  const elements = element.querySelectorAll(querySelector);

  elements.forEach((el) => {
    const encodedUrl = new URL(el.getAttribute('src'));
    const encodedQuery = encodedUrl.searchParams.get('pb');
    const defaultUrl = new URL(defaultSource);
    const defaultEncodedQuery = defaultUrl.searchParams.get('pb');

    if (encodedQuery && encodedQuery === defaultEncodedQuery) {
      encodedUrl.searchParams.delete('pb');
      el.setAttribute('src', getUpdatedMapSource(initialAddress));
    }
  });
};

export default setDefaultAttributes;
