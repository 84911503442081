import React from 'react';
import PropTypes from 'prop-types';
import { Modal, designSystem } from '@yola/ws-ui';
import constants from '../constants';

const { stepStatuses } = constants;
const { Box, Stack, Heading, List, ListItem, CheckmarkIcon, LoaderIcon, Icon } = designSystem;

const MODAL_WIDTH = 480;
const MODAL_HEIGHT = 320;

const getListItemIconByStatus = (status) => {
  switch (status) {
    case stepStatuses.COMPLETE:
      return <CheckmarkIcon animated />;
    case stepStatuses.IN_PROGRESS:
      return <LoaderIcon animated />;
    default:
      return (
        <div className="ws-wizard-progress-dialog__list-icon">
          <Icon glyph="circle" type="inherit" />
        </div>
      );
  }
};

const WizardProgressDialog = ({ title, steps }) => (
  <Modal
    className="ws-wizard-progress-dialog"
    width={MODAL_WIDTH}
    height="auto"
    minHeight={MODAL_HEIGHT}
    overlay="visible"
    draggable={false}
    resizable={false}
    centered
  >
    <Box
      paddingTop="spacing-l"
      paddingBottom="spacing-l"
      paddingLeft="spacing-xl"
      paddingRight="spacing-xl"
    >
      <Stack gap="spacing-xs">
        <Heading type="heading-3" align="center">
          {title}
        </Heading>
        <List styleType="custom" spacing="spacing-3-xs">
          {steps.map(({ name, status }) => (
            <ListItem key={name} customIcon={getListItemIconByStatus(status)}>
              {name}
            </ListItem>
          ))}
        </List>
      </Stack>
    </Box>
  </Modal>
);

WizardProgressDialog.propTypes = {
  title: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      status: PropTypes.oneOf(Object.values(stepStatuses)).isRequired,
    })
  ).isRequired,
};

export default WizardProgressDialog;
