import React from 'react';
import PropTypes from 'prop-types';
import { i18next } from '@yola/ws-sdk';
import FilterButton from './filter-button';
import { BLOCK_FILTERS } from '../constants/common';

const BlocksFilterSelector = ({ selectedBlockFilter, onBlocksFilterClick }) => (
  <div className="ws-blocks-library__filter-buttons-wrapper">
    <FilterButton
      id={BLOCK_FILTERS.free}
      label={i18next.t('Free')}
      isSelected={selectedBlockFilter === BLOCK_FILTERS.free}
      onBlocksFilterClick={onBlocksFilterClick}
    />
    <FilterButton
      id={BLOCK_FILTERS.premium}
      label={i18next.t('Premium')}
      isSelected={selectedBlockFilter === BLOCK_FILTERS.premium}
      iconGlyph="star"
      onBlocksFilterClick={onBlocksFilterClick}
    />
  </div>
);

BlocksFilterSelector.propTypes = {
  selectedBlockFilter: PropTypes.oneOf(Object.values(BLOCK_FILTERS)).isRequired,
  onBlocksFilterClick: PropTypes.func.isRequired,
};

export default BlocksFilterSelector;
