import { textFormatting } from '@yola/ws-sdk';
import getBlockIdRulesMap from 'src/js/modules/blocks/helpers/get-block-id-rules-map';

const restoreOriginSurface = (stylesheet, currentBlockId, newBlockId) => {
  const blockIdRulesMap = getBlockIdRulesMap(stylesheet, currentBlockId);
  textFormatting.helpers.removeBlockIdRuleFromStylesheet(stylesheet, newBlockId);
  blockIdRulesMap.forEach((value, key) => {
    let selector = key;
    if (newBlockId) {
      selector = key.replace(currentBlockId, newBlockId);
    }
    stylesheet.insertRule(`${selector} {${value}}`, stylesheet.cssRules.length);
  });
};

export default restoreOriginSurface;
