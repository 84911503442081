import React from 'react';
import PropTypes from 'prop-types';
import { textFormatting, customization, view } from '@yola/ws-sdk';
import Trigger from 'src/js/modules/common/components/trigger';
import withSelection from 'src/js/modules/utils/with-selection';
import blocks from 'src/js/modules/blocks';
import websiteDesign from 'src/js/modules/website-design';
import ColorOption from './color-option';
import restoreRange from '../helpers/restore-range';
import getTextColorOfSelection from '../helpers/get-text-color-of-selection';

function TextColorTrigger({ elementId, triggerId }) {
  const { BLOCK_WITH_SURFACE_SELECTOR } = blocks.constants.common;
  const selection = textFormatting.accessors.getAdvancedSelection();
  const currentPalette = customization.accessors.getCurrentColorPalette();
  const element = view.accessors.getLiveElement(elementId);
  const block = element.closest(BLOCK_WITH_SURFACE_SELECTOR);

  if (!block) return null;

  const surfaceAttribute = block.getAttribute('surfaces').split(' ')[0];
  const blockSurfaceName = websiteDesign.helpers.getBlockSurfaceName(surfaceAttribute);

  const handleClick = () => {
    restoreRange(selection, elementId);
  };

  const color = getTextColorOfSelection(selection);
  const backgroundColor = websiteDesign.helpers.getBlockSurfaceBackground(
    blockSurfaceName,
    currentPalette.variables
  );

  return (
    <Trigger id={triggerId} active={false} onClick={handleClick}>
      <ColorOption color={color} backgroundColor={backgroundColor} />
    </Trigger>
  );
}

TextColorTrigger.propTypes = {
  elementId: PropTypes.string.isRequired,
  triggerId: PropTypes.string.isRequired,
};

export default withSelection(TextColorTrigger);
