import React from 'react';
import PropTypes from 'prop-types';
import { Field, CheckboxField, designSystem } from '@yola/ws-ui';
import { i18next } from '@yola/ws-sdk';
import InputGroupField from 'src/js/modules/common/components/input-group-field';
import LanguageSelectField from 'src/js/modules/common/components/language-select-field';
import constants from '../constants';

const { ControlGroup, FieldStatus, CardSelector, Box, Paragraph, Heading, Divider } = designSystem;

const {
  common: { PAGE_LOCALE },
} = constants;

function GeneralSection(props) {
  const {
    captions,
    onChangePath,
    validatePageName,
    validatePath,
    pathPrefix,
    isPathDisabled,
    isDisplayInNavAvailable,
    formData,
    pageNameRef,
    pathRef,
    languageCodeOptions,
    onChangePageName,
    passwordProtectedPagesAvailable,
    isProtectedPagesPlanFeatureAvailable,
    passwordProtection,
    enablePasswordProtection,
    disablePasswordProtection,
    passwordVisibility,
    switchPasswordVisibility,
    validatePagePassword,
    pagePasswordRef,
  } = props;

  const { displayInNavigation } = formData;
  const { general, customCode } = captions;

  const renderLanguageSelect = (formikProps) => (
    <LanguageSelectField
      {...formikProps}
      defaultValue={languageCodeOptions.find((el) => el.value === formData[PAGE_LOCALE])}
      options={languageCodeOptions}
      placeholder={i18next.t('Select...')}
    />
  );
  const requireValidation = (value) => {
    if (!value) {
      return i18next.t('This field is required');
    }
    return '';
  };

  return (
    <div>
      <div className="ws-page-settings__field">
        <ControlGroup title={general.title.name}>
          <div ref={pageNameRef}>
            <Field
              component={InputGroupField}
              name="name"
              onChange={onChangePageName}
              validate={validatePageName}
            />
          </div>
          <Box marginTop="spacing-3-xs">
            <div ref={pathRef}>
              <Field
                component={InputGroupField}
                name="path"
                size="tiny"
                iconGlyph="link"
                prefixText={pathPrefix}
                disabled={isPathDisabled}
                validate={validatePath}
                onChange={onChangePath}
              />
            </div>
          </Box>
        </ControlGroup>

        {isDisplayInNavAvailable && (
          <div className="ws-page-settings__field">
            <Field
              component={CheckboxField}
              id="displayInNavigationCheckbox"
              name="displayInNavigation"
              checked={displayInNavigation}
            >
              {/* eslint-disable-next-line yola/jsx-a11y/label-has-for */}
              <label htmlFor="displayInNavigationCheckbox">
                <Heading type="heading-6">{general.displayInNavigation.label}</Heading>
              </label>
            </Field>
          </div>
        )}
      </div>

      <div className="ws-page-settings__field">
        <ControlGroup title={customCode.languageCode.name}>
          <Field validate={requireValidation} name={PAGE_LOCALE} render={renderLanguageSelect} />
        </ControlGroup>
        <FieldStatus text={customCode.languageCode.description} />
      </div>
      {passwordProtectedPagesAvailable && (
        <React.Fragment>
          <div className="ws-page-settings__field">
            <Heading type="heading-5">{general.passwordProtectedPages.title}</Heading>
            <Box marginTop="spacing-4-xs" marginBottom="spacing-xs">
              <Divider />
            </Box>
            <Paragraph size="small" appearance="medium-emphasis">
              {general.passwordProtectedPages.subtitle}
            </Paragraph>
          </div>
          <div className="ws-page-settings__field">
            <div className="ws-page-settings__password-protection-switcher">
              <CardSelector
                selected={!passwordProtection}
                iconGlyph="globe"
                verticalAlign="center"
                onClick={disablePasswordProtection}
                title={general.passwordProtectedPages.openedOptionLabel}
              />

              <CardSelector
                selected={passwordProtection}
                iconGlyph="unlock"
                verticalAlign="center"
                title={general.passwordProtectedPages.protectedOptionLabel}
                onClick={enablePasswordProtection}
                premium={!isProtectedPagesPlanFeatureAvailable}
              />
            </div>
          </div>

          {passwordProtection && (
            <div className="ws-page-settings__field" ref={pagePasswordRef}>
              <Heading type="heading-6">{general.passwordProtectedPages.fieldTitle}</Heading>
              <Box marginTop="spacing-4-xs">
                <Paragraph size="small" appearance="medium-emphasis">
                  {general.passwordProtectedPages.fieldSubtitle}
                </Paragraph>
              </Box>
              <Box marginTop="spacing-2-xs" marginBottom="spacing-3-xs">
                <Field
                  component={InputGroupField}
                  name="password"
                  type={passwordVisibility ? 'text' : 'password'}
                  maxLength={50}
                  validate={validatePagePassword}
                  placeholder={general.passwordProtectedPages.fieldPlaceholder}
                  iconButtonGlyph={passwordVisibility ? 'eye-off' : 'eye'}
                  onIconButtonClick={switchPasswordVisibility}
                  disabled={!isProtectedPagesPlanFeatureAvailable}
                />
              </Box>
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
}

GeneralSection.propTypes = {
  pathPrefix: PropTypes.string.isRequired,
  captions: PropTypes.shape().isRequired,
  onChangePageName: PropTypes.func,
  onChangePath: PropTypes.func.isRequired,
  validatePageName: PropTypes.func.isRequired,
  validatePath: PropTypes.func.isRequired,
  isPathDisabled: PropTypes.bool.isRequired,
  isDisplayInNavAvailable: PropTypes.bool.isRequired,
  formData: PropTypes.shape().isRequired,
  pageNameRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.instanceOf(Element),
    }),
  ]).isRequired,
  pathRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.instanceOf(Element),
    }),
  ]).isRequired,
  languageCodeOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  passwordProtectedPagesAvailable: PropTypes.bool,
  isProtectedPagesPlanFeatureAvailable: PropTypes.bool,
  passwordVisibility: PropTypes.bool,
  passwordProtection: PropTypes.bool,
  enablePasswordProtection: PropTypes.func,
  disablePasswordProtection: PropTypes.func,
  switchPasswordVisibility: PropTypes.func,
  validatePagePassword: PropTypes.func.isRequired,
  pagePasswordRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.instanceOf(Element),
    }),
  ]).isRequired,
};

GeneralSection.defaultProps = {
  onChangePageName: Function.prototype,
  enablePasswordProtection: Function.prototype,
  disablePasswordProtection: Function.prototype,
  switchPasswordVisibility: Function.prototype,
  passwordProtection: false,
  passwordProtectedPagesAvailable: false,
  isProtectedPagesPlanFeatureAvailable: false,
  passwordVisibility: false,
};

export default GeneralSection;
