import callSiteBackupMigration from '../utils/call-site-backup-migration';
import getStatus from '../utils/get-status';
import statusWatcher from '../helpers/status-watcher';

const runSiteBackupMigration = async (backupId, options = {}) => {
  if (!backupId) throw new Error('`siteId` should be specified');

  const { onProgress } = options;
  const {
    data: { id, progress },
  } = await callSiteBackupMigration(backupId);

  if (typeof onProgress === 'function') {
    onProgress(progress);
  }

  const statusGetter = (jobId) => () => getStatus(jobId);
  return statusWatcher(statusGetter(id), onProgress);
};
export default runSiteBackupMigration;
