import get from 'lodash.get';
import { constants } from '@yola/subscription-manager-js';

const {
  hostingPackageFeatureNames: { ADVANCED_COLOR_CUSTOMIZATION },
} = constants;

const advancedColorCustomizationLimit = ({ targetPackage }) => ({
  available: get(targetPackage, ['features', ADVANCED_COLOR_CUSTOMIZATION], false),
});

export default advancedColorCustomizationLimit;
