import { LIVE_PREVIEW_MOCKED_ID } from '../constants/common';

const getSelectedLayoutVariationId = (
  { isStrictlyClean, blockVariationId },
  selectedLayoutVariationId
) => {
  if (selectedLayoutVariationId) return selectedLayoutVariationId;

  return isStrictlyClean ? blockVariationId : LIVE_PREVIEW_MOCKED_ID;
};

export default getSelectedLayoutVariationId;
