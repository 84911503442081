const AI_SITE_WIZARD_PROPERTY_NAME = 'aiSiteWizard';
const NUMBER_OF_AI_GENERATED_PAGES_PROPERTY_NAME = 'numberOfAiGeneratedPages';
const NUMBER_OF_AI_GENERATED_BLOCKS_PROPERTY_NAME = 'numberOfAiGeneratedBlocks';
const NUMBER_OF_AI_TEXT_ASSISTANT_REQUESTS_PROPERTY_NAME = 'numberOfAiTextAssistantRequests';

const aiSiteWizardPropertyValues = {
  ENABLED: 'enabled',
  COMPLETED: 'completed',
  SKIPPED: 'skipped',
  INITIATED: 'initiated',
};

export default {
  AI_SITE_WIZARD_PROPERTY_NAME,
  NUMBER_OF_AI_GENERATED_PAGES_PROPERTY_NAME,
  NUMBER_OF_AI_GENERATED_BLOCKS_PROPERTY_NAME,
  NUMBER_OF_AI_TEXT_ASSISTANT_REQUESTS_PROPERTY_NAME,
  aiSiteWizardPropertyValues,
};
