import { view, blocks, site, template } from '@yola/ws-sdk';
import { capitalize } from 'src/js/modules/utils/convert-case';
import isCustomBlock from '../verifiers/is-custom-block';
import isElementWidget from './is-element-widget';

const MAX_SYMBOLS = 70;
const LINE_BREAKS_AND_SPACES_REGEXP = /\r?\n|\r|\s/;
const BLOCK_TITLE_SELECTOR =
  'ws-text[slot="block-title"], ws-text[slot="product-description"], ws-text[slot="member-description"], .ws-m-rich-text ws-text';
const WIDGET_TITLE_SELECTOR = 'ws-text';

const trimText = (text) => {
  // function removes unnecessary line breaks, spaсes
  // and return cropped text
  const parts = text.split(LINE_BREAKS_AND_SPACES_REGEXP).filter((part) => part.trim());
  return parts.join(' ').slice(0, MAX_SYMBOLS);
};

const getBlocksReadableNames = () => {
  const pageBlocks = view.accessors.getPageBlocks();
  const pageId = site.accessors.getActivePageId();

  if (!pageBlocks.length) return [];

  return pageBlocks
    .filter((block) => !isElementWidget(block))
    .map((block) => {
      let label;
      const { category } = blocks.accessors.getBlockByElement(block);
      const translatedCategoryName = template.accessors.getMetaTranslations(category);

      if (isCustomBlock(block)) {
        const widgetTitleElement = block.querySelector(WIDGET_TITLE_SELECTOR);
        const widgetTitle = widgetTitleElement ? trimText(widgetTitleElement.innerText) : '';

        label = widgetTitle ? `${translatedCategoryName}: ${widgetTitle}` : translatedCategoryName;
      } else {
        const blockTitleElement = block.querySelector(BLOCK_TITLE_SELECTOR);
        const blockTitle = blockTitleElement ? trimText(blockTitleElement.innerText) : '';

        if (blockTitle) {
          label = blockTitle;
        } else {
          label = translatedCategoryName;
        }
      }

      return {
        label: capitalize(label),
        value: block.id,
        pageId,
      };
    });
};

export default getBlocksReadableNames;
