import createHslColorString from './create-hsl-color-string';

const setGeneralColor = ({ colorKey, color, colorPalette }) => {
  const colors = { ...colorPalette.colors };
  const colorString = typeof color === 'string' ? color : createHslColorString(color);
  colors[colorKey] = colorString;
  return { ...colorPalette, colors };
};

export default setGeneralColor;
