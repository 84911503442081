import React, { useCallback, useEffect, useRef, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import bowser from 'yola-bowser';
import { Icon, designSystem } from '@yola/ws-ui';
import processBlockHtmlForPreview from '../helpers/process-block-html-for-preview';
import IframeContext from '../contexts/iframe-context';
import getForcedDeviceWidth from '../helpers/get-forced-device-width';
import { FORCED_DESKTOP_WIDTH } from '../constants/sizes';
import { ONLINE_STORE_LOADED_EVENT } from '../constants/common';
import ThumbnailFooter from './thumbnail-footer';
import sleep from '../../migrations/helpers/sleep';
import applyBlockAttribute from '../helpers/apply-block-attribute';
import getBackgroundImageSource from '../helpers/get-background-image-sourse';

const { Tag } = designSystem;

const googleStub = '/images/stubs/google-map.jpg';
const youtubeStub = '/images/stubs/youtube.jpg';
const MAX_RENDER_TIME = 1000;
const RENDER_THROTTLE_TIME = 500;

function Thumbnail(props) {
  const {
    blockId,
    blockAttrs,
    src,
    title,
    variationTitle,
    html,
    className,
    renderRealSize,
    disabled,
    interactive,
    selected,
    showThumbnailFooter,
    marks,
    onThumbnailIntoView,
    onBlockAdapted,
    ...otherProps
  } = props;

  const forcedDeviceWidth = getForcedDeviceWidth() || FORCED_DESKTOP_WIDTH;

  const [isVisible, setIsVisible] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [scale, setScale] = useState(1);
  const iframeContext = useContext(IframeContext);
  const blockSize = useRef({ width: 0, height: 0 });
  const {
    intersectionObserver,
    intersectionHandler,
    resizeObserver,
    resizeHandler,
    onResizeCallback,
    semaphore,
  } = iframeContext;

  const thumbnail = useRef(null);

  const resolveSemaphore = useCallback(
    () =>
      new Promise((resolve) => {
        setTimeout(() => {
          semaphore.v(1);
          resolve(true);
        }, 1000);
      }),
    [semaphore]
  );

  const adaptBlock = useCallback(
    (parent) => {
      if (!parent) return;
      const wsBlock = parent.querySelector('ws-block');
      const wsMediaContainers = parent.querySelectorAll('ws-media-container');

      if (!wsBlock || renderRealSize) {
        setIsReady(true);
        resolveSemaphore();

        if (onBlockAdapted && wsBlock) {
          onBlockAdapted({
            height: wsBlock.offsetHeight,
            width: parent.offsetWidth,
          });
        }

        return;
      }

      const renderWidth = parent.offsetWidth;
      const height = wsBlock.offsetHeight;

      const scaleFactor = Number(renderWidth / forcedDeviceWidth).toFixed(5);

      wsBlock.removeAttribute('transform-scale-factor');
      wsBlock.setAttribute('transform-scale-factor', Number(scaleFactor));
      wsMediaContainers.forEach((el) => {
        // we have to remove attribute to trigger recalculate
        el.removeAttribute('transform-scale-factor');
        el.setAttribute('transform-scale-factor', Number(scaleFactor));
      });

      // eslint-disable-next-line no-param-reassign
      parent.style.minHeight = `${height * scaleFactor}px`;

      setScale(scaleFactor);
      setIsReady(true);
      resolveSemaphore();
    },

    // It should not depend on `semaphore`
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
    [forcedDeviceWidth, renderRealSize, onBlockAdapted, resolveSemaphore]
  );

  useEffect(() => {
    const { current } = thumbnail;
    const {
      ownerDocument: { defaultView },
    } = current;

    const handler = () => setTimeout(() => adaptBlock(current), 0);

    defaultView.addEventListener('resize', handler);
    return () => defaultView.removeEventListener('resize', handler);
  }, [adaptBlock]);

  useEffect(() => {
    const { current } = thumbnail;

    const setIsVisibleAsync = async (value) => {
      const safeTimer = setTimeout(() => {
        resolveSemaphore();
      }, MAX_RENDER_TIME * 2);
      await semaphore.p(1);
      clearTimeout(safeTimer);
      await sleep(RENDER_THROTTLE_TIME);
      setIsVisible(value);
    };

    const visibilityHandler = (entry) => {
      if (!isVisible && entry.target === current && entry.isIntersecting) {
        if (bowser.desktop) {
          setIsVisible(true);
        } else {
          setIsVisibleAsync(true);
        }
      }
    };

    const intoViewHandler = (entry) => {
      if (
        isVisible &&
        entry.intersectionRatio > 0.99 &&
        entry.target === current &&
        entry.isIntersecting
      ) {
        onThumbnailIntoView(current);
      }
    };

    const removeHandler = intersectionHandler((entries) => {
      entries.forEach((entry) => {
        visibilityHandler(entry);
        intoViewHandler(entry);
      });
    });
    intersectionObserver.observe(current);
    return () => {
      intersectionObserver.unobserve(current);
      removeHandler();
    };
    // It should not depend on `semaphore`
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, [intersectionHandler, intersectionObserver, isVisible, onThumbnailIntoView, resolveSemaphore]);

  useEffect(() => {
    onResizeCallback();
  }, [disabled, onResizeCallback]);

  useEffect(() => {
    const { current } = thumbnail;
    const {
      ownerDocument: { defaultView },
    } = current;
    const wsBlock = current.querySelector('ws-block');
    const wsOnlineStore = current.querySelector('ws-online-store');
    const container = current.querySelector('.ws-thumbnail__container');
    if (!wsBlock) return () => {};

    /**
     * Set timer for case if ws-block
     * does not emit `ready` event
     */
    const timer = setTimeout(() => {
      adaptBlock(current);
    }, MAX_RENDER_TIME);

    wsBlock.addEventListener('ready', () => {
      adaptBlock(current);
      clearTimeout(timer);
      const mediaSrc = getBackgroundImageSource(current);
      if (mediaSrc) {
        const wsBackgroundContainer = current.querySelector('ws-background-container');
        wsBackgroundContainer.computeBackgroundRectForSource(mediaSrc).then(() => {
          /**
           * It takes time to render media container and calculate its size
           * so we should postpone block adaptation
           */
          setTimeout(() => adaptBlock(current), MAX_RENDER_TIME);
        });
      }
    });

    const onOnlineStoreLoaded = (event) => {
      event.stopPropagation();
      setTimeout(() => adaptBlock(current), MAX_RENDER_TIME);
    };

    resizeObserver.observe(container);
    resizeHandler((elements) => {
      elements.forEach((element) => {
        const { width: prevWidth, height: prevHeight } = blockSize.current;
        const {
          contentRect: { width, height },
          target,
        } = element;

        if (target === container && (prevWidth !== width || prevHeight !== height)) {
          blockSize.current = {
            width,
            height,
          };

          if (wsOnlineStore) {
            defaultView.addEventListener(ONLINE_STORE_LOADED_EVENT, onOnlineStoreLoaded);
          } else {
            adaptBlock(current);
          }

          onResizeCallback();
        }
      });
    });

    return () => {
      resizeObserver.unobserve(container);
      defaultView.removeEventListener(ONLINE_STORE_LOADED_EVENT, onOnlineStoreLoaded);
    };
  }, [isVisible, adaptBlock, resizeObserver, resizeHandler, onResizeCallback]);

  useEffect(() => {
    if (!isVisible) return;
    const { current } = thumbnail;
    const wsBlock = current.querySelector('ws-block');

    if (wsBlock && blockAttrs) {
      Object.keys(blockAttrs).forEach((attr) => {
        applyBlockAttribute(wsBlock, attr, blockAttrs[attr]);
      });
    }
  }, [blockAttrs, isVisible, html]);

  const editorOrigin = location.origin;
  /**
   * Set `processFullScreen` is `true` to fix issues with `vh` inside scaled iframe
   * As far as issue will be resolved we will update it
   * */

  const htmlToRender =
    html &&
    processBlockHtmlForPreview(html, editorOrigin + googleStub, editorOrigin + youtubeStub, true);

  const thumbnailClass = classNames('ws-thumbnail', className, {
    'ws-thumbnail--empty': !isVisible || !isReady,
    'ws-thumbnail--scaled': !renderRealSize,
    'ws-thumbnail--touch': !bowser.desktop,
    'ws-thumbnail--disabled': disabled,
    'ws-thumbnail--non-interactive': !interactive,
    'ws-thumbnail--selected': selected,
  });

  const thumbnailWithImageClass = classNames('ws-thumbnail', className, {
    'ws-thumbnail--disabled': disabled,
  });
  const imageClass = classNames('ws-thumbnail__image', {
    'ws-thumbnail__image--mobile': bowser.mobile || bowser.tablet,
  });

  const scaledStyle = {};
  if (!renderRealSize) {
    scaledStyle.transformOrigin = 'left top';
    scaledStyle.transform = `scale(${scale})`;
    scaledStyle.width = forcedDeviceWidth;
  }

  const footerVisibility = isVisible ? 'visible' : 'hidden';

  const getMarks = (showSelected = false) => {
    if (!marks.length && !showSelected) {
      return null;
    }

    return (
      <div className="ws-thumbnail__marks">
        {marks.map((mark, idx) => (
          // eslint-disable-next-line yola/react/no-array-index-key
          <Tag key={idx} {...mark} />
        ))}
        {showSelected && (
          <span className="ws-thumbnail__slot-icon">
            <Icon size="68" strokeWidth="3" glyph="check" />
          </span>
        )}
      </div>
    );
  };

  if (html && !src) {
    return (
      <div className={thumbnailClass} data-id={blockId} {...otherProps}>
        {getMarks(selected)}
        <div
          ref={thumbnail}
          style={isVisible ? {} : { minHeight: '50vw' }}
          className="ws-thumbnail__slot"
        >
          {isVisible && (
            <div
              className="ws-thumbnail__container"
              style={scaledStyle}
              // eslint-disable-next-line yola/react/no-danger
              dangerouslySetInnerHTML={{ __html: htmlToRender }}
            />
          )}
        </div>
        {showThumbnailFooter && (
          <ThumbnailFooter
            style={{ visibility: footerVisibility }}
            // TODO: Replace `variationTitle || blockId` with `title` when all blocks will have readable title
            title={variationTitle || blockId}
          />
        )}
      </div>
    );
  }

  return (
    <div data-id={blockId} {...otherProps} className={thumbnailWithImageClass}>
      <div ref={thumbnail}>
        {getMarks()}
        <img className={imageClass} src={src} alt={title} />
        {showThumbnailFooter && (
          <ThumbnailFooter style={{ visibility: footerVisibility }} title={blockId} />
        )}
      </div>
    </div>
  );
}

Thumbnail.propTypes = {
  blockId: PropTypes.string.isRequired,
  blockAttrs: PropTypes.shape({}),
  className: PropTypes.string,
  src: PropTypes.string,
  title: PropTypes.string,
  showThumbnailFooter: PropTypes.bool,
  html: PropTypes.string,
  renderRealSize: PropTypes.bool,
  disabled: PropTypes.bool,
  interactive: PropTypes.bool,
  selected: PropTypes.bool,
  variationTitle: PropTypes.string,
  marks: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      size: PropTypes.string,
      appearance: PropTypes.string,
      iconGlyph: PropTypes.string,
    })
  ),
  onThumbnailIntoView: PropTypes.func,
  onBlockAdapted: PropTypes.func,
};

Thumbnail.defaultProps = {
  blockAttrs: null,
  className: null,
  html: null,
  showThumbnailFooter: false,
  src: null,
  renderRealSize: false,
  disabled: false,
  interactive: true,
  selected: false,
  onThumbnailIntoView: Function.prototype,
  title: null,
  onBlockAdapted: null,
  variationTitle: '',
  marks: [],
};

export default React.memo(Thumbnail);
