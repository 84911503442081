import isDefaultAsset from './is-default-asset';
import optionTypes from '../../constants/background-option-types';

const { BACKGROUND_IMAGE_ATTRIBUTE, BACKGROUND_IMAGE_OPTION } = optionTypes;

const handleBackgroundImageAsFallback = (
  originalNode,
  destinationNode,
  originalOptionalChildren,
  destinationOptionalChildren,
  backgroundOptions
) => {
  if (!backgroundOptions) return false;
  const backgroundImageOption = backgroundOptions.find(({ id }) => id === BACKGROUND_IMAGE_OPTION);

  if (!backgroundImageOption || !destinationNode.hasAttribute(BACKGROUND_IMAGE_ATTRIBUTE))
    return false;

  const originalBlockMedia = originalOptionalChildren.find((el) => el.id === 'block_media');
  const destinationBlockMedia = destinationOptionalChildren.find((el) => el.id === 'block_media');

  if (!originalBlockMedia || destinationBlockMedia) return false;

  const { querySelector } = originalBlockMedia;
  const blockMediaNode = originalNode.querySelector(querySelector);

  if (!blockMediaNode) return false;

  const sourcePicture = blockMediaNode.querySelector('img');

  if (!sourcePicture) return false;

  const { src } = backgroundImageOption;
  if (isDefaultAsset(src)) return false;

  destinationNode.setAttribute(BACKGROUND_IMAGE_ATTRIBUTE, src);

  return true;
};

export default handleBackgroundImageAsFallback;
