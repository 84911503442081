import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { site, blocks, template } from '@yola/ws-sdk';
import segment from 'src/js/modules/analytics/segment';
import getParentBlockByElement from '../blocks/helpers/get-parent-block-by-element';

const webComponentTags = {
  WS_COOKIE_BANNER: 'ws-cookie-banner',
  WS_BRANDING: 'ws-branding',
  WS_BRANDED_HEADER: 'ws-branded-header',
};

// fix for tracking events (https://github.com/yola/production/issues/10101)
// in case if element lies outside of ws-block, we are using web-component tag instead
function getWebComponentTagName(element) {
  const webComponent = element.closest(
    `${webComponentTags.WS_COOKIE_BANNER}, ${webComponentTags.WS_BRANDING}, ${webComponentTags.WS_BRANDED_HEADER}`
  );

  if (webComponent) {
    return webComponent.tagName.substr(3).toLowerCase();
  }
  return null;
}

const TriggerEventTracker = ({ children, beforeTriggerActionStart }) => {
  const onTriggerActionStart = useCallback(
    (trigger) => {
      if (beforeTriggerActionStart) {
        beforeTriggerActionStart(trigger);
      }

      if (!trigger || !trigger.id || !trigger.element) return;

      const {
        track,
        constants: { events },
      } = segment;

      const blockElement = getParentBlockByElement(trigger.element);

      const blockId = blockElement
        ? blocks.accessors.getBlockIdByElement(blockElement)
        : getWebComponentTagName(trigger.element);
      const blockVariationId = blockElement
        ? blocks.accessors.getVariationIdByElement(blockElement)
        : null;

      track(events.CONTROL_PANE_TRIGGER_CLICKED, {
        triggerId: trigger.id,
        extensionSlug: trigger.extensionSlug || null,
        siteId: site.accessors.getSiteId(),
        templateBuildSlug: template.accessors.getBuildSlug(),
        blockId,
        blockVariationId,
      });
    },
    [beforeTriggerActionStart]
  );

  return React.cloneElement(children, { onTriggerActionStart });
};

TriggerEventTracker.propTypes = {
  children: PropTypes.element.isRequired,
  beforeTriggerActionStart: PropTypes.func,
};

TriggerEventTracker.defaultProps = {
  beforeTriggerActionStart: null,
};

export default TriggerEventTracker;
