import { assets, serviceClient } from '@yola/ws-sdk';
import iconFormats from '../constants/icon-formats';

const createIconAssetSrc = async ({ icon, abortRequestController, onError }) => {
  try {
    const client = serviceClient.get();
    const {
      request: { response },
      headers,
    } = icon.isAnimated
      ? await client.icons8GetPreview(
          { ...icon.params, format: iconFormats.GIF },
          {
            signal: abortRequestController?.signal,
          }
        )
      : await client.icons8GetIcon(
          { ...icon.params, format: iconFormats.SVG },
          {
            signal: abortRequestController?.signal,
          }
        );

    const file = new File([response], icon.title, { type: headers['content-type'] });

    const { data } = await assets.operations.createMediaGalleryAssets(
      {
        asset: file,
        name: file.name,
      },
      {
        signal: abortRequestController?.signal,
      }
    );

    assets.operations.makePublishable(data.source);
    return data.source;
  } catch (error) {
    if (abortRequestController?.signal?.aborted) {
      return '';
    }

    onError();
    console.error(error);
    return '';
  }
};

export default createIconAssetSrc;
