import NameIcon from './name';
import EmailIcon from './email';
import PhoneIcon from './phone';
import AddressIcon from './address';
import CompanyIcon from './company';
import PositionIcon from './position';
import LinkIcon from './link';

export default {
  NameIcon,
  EmailIcon,
  PhoneIcon,
  AddressIcon,
  CompanyIcon,
  PositionIcon,
  LinkIcon,
};
