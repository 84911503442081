import { middleware, after, viewReloader, thunk, logger } from '@yola/ws-sdk';
import reduxCatch from 'redux-catch';
import segment from './modules/analytics/segment';
import sentry from './modules/analytics/sentry';
import tooltip from './modules/tooltip';
import mainMenu from './modules/main-menu';
import pageManager from './modules/page-manager';
import scroller from './modules/scroller';
import editor from './modules/editor';
import upsell from './modules/upsell';
import brandedFooter from './modules/branded-footer';
import brandedHeader from './modules/branded-header';
import cookieBanner from './modules/cookie-banner';
import onlinestore from './modules/onlinestore';
import catchReduxError from './catch-redux-errors.js';
import customUI from './modules/custom-ui';
import websiteDesign from './modules/website-design';
import highlighter from './modules/highlighter';
import session from './modules/session';
import navigation from './modules/navigation';
import tours from './modules/tours';
import designAutomation from './modules/design-automation';
import saving from './modules/saving';
import websiteSettings from './modules/website-settings';
import previewMode from './modules/preview-mode';

export default [
  thunk,
  reduxCatch(catchReduxError),
  sentry.middleware.setupSentry,
  tooltip.middleware.hideTooltips,
  mainMenu.middleware.hideMainMenu,
  pageManager.middleware.setActivePage,
  scroller.middleware.scroller,
  upsell.middleware.featureAvailabilityInterrupter,
  upsell.middleware.addPremiumBlockInterrupter,
  upsell.middleware.fileSizeInterrupter,
  upsell.middleware.addLanguageInterrupter,
  upsell.middleware.pageLimitInterrupter,
  upsell.middleware.onlineStoreInterrupter,
  onlinestore.middleware.storeIdInjector,
  onlinestore.middleware.storeRedirect,
  brandedFooter.middleware.brandedFooterInterrupter,
  brandedHeader.middleware.brandedHeaderInterrupter,
  designAutomation.middleware.openAutomationTransaction,
  designAutomation.middleware.userModifiedAttribute,
  editor.middleware.externalImagePaster,
  ...middleware,
  designAutomation.middleware.closeAutomationTransaction,
  after,
  logger.middleware.logAfterReloadAction,
  viewReloader,
  websiteSettings.middleware.siteNameUpdateHandler,
  websiteSettings.middleware.updateIntegrations,
  cookieBanner.middleware.setCookieBanner,
  websiteDesign.middleware.websiteDesignMigration,
  editor.middleware.initialEditorRender,
  editor.middleware.initialSettingsSetup,
  tours.middleware.welcomeTourInit,
  brandedFooter.middleware.setBrandedFooter,
  brandedHeader.middleware.setBrandedHeader,
  customUI.middleware.hoveredElementWatcher,
  websiteDesign.middleware.colorPaletteConfigLoader,
  websiteDesign.middleware.customColorAutomation,
  websiteDesign.middleware.userFontsLoader,
  highlighter.middleware.focusedElementHighlighter,
  highlighter.middleware.selectedElementHighlighter,
  session.middleware.sessionExpireChecker,
  navigation.middleware.highlighter,
  saving.middleware.savingError,
  segment.middleware.segmentInitializer,
  previewMode.middleware.editorVisibilityHandler,
];
