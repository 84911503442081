import { i18next } from '@yola/ws-sdk';
import getFileTypes from './get-file-types';
import constants from '../constants';

const {
  common: { ALL_FILE_TYPES_VALUE },
} = constants;

const getSupportedFileTypes = () => {
  const { image, document, video, audio } = getFileTypes();

  return [
    {
      value: ALL_FILE_TYPES_VALUE,
      label: i18next.t('All types'),
    },
    {
      value: image,
      label: i18next.t('Image'),
    },
    {
      value: document,
      label: i18next.t('Document'),
    },
    {
      value: video,
      label: i18next.t('Video'),
    },
    {
      value: audio,
      label: i18next.t('Audio'),
    },
  ];
};

export default getSupportedFileTypes;
