import { site } from '@yola/ws-sdk';

const getLocaleHomePage = (locale) => {
  const pages = site.accessors.getPages();
  const localeHomePage = pages.find(({ path }) => path === `/${locale}/`);

  return localeHomePage || null;
};

export default getLocaleHomePage;
