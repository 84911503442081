import { dialogs, extensions, i18next } from '@yola/ws-sdk';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import constants from '../../constants/common';
import config from '../../constants/config';
import matchesOrContains from '../../helpers/matches-or-contains';

const setClickAction = (additionalProperties = {}) => ({
  id: constants.SET_CLICK_ACTION,
  get caption() {
    return `${i18next.t('Set a click action')}...`;
  },
  onClick(element) {
    const settings = extensions.accessors.getExtensionSettings(config.slug);
    const imageElement = matchesOrContains(element, settings.imageActionSelector);
    dialogs.operations.show(dialogTypes.CLICK_ACTION_DIALOG, { element: imageElement });
  },
  ...additionalProperties,
});

export default setClickAction;
