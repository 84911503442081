const platformComponents = {
  DOMAINS: 'domains',
  TRAFFIC_BUILDER: 'traffic_builder',
  EMAIL: 'domain_email',
  SITEWIT: 'sitewit_affiliate',
  ANALYTICS: 'analytics',
  AUTH: 'user_auth',
  PAYMENTS: 'payments',
  DASHBOARD: 'dashboard',
};

export default platformComponents;
