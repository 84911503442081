import actionTypes from '../constants/action-types';

const getDomainListSuccess = ({ terms, options }) => ({
  type: actionTypes.GET_DOMAIN_LIST_SUCCESS,
  payload: {
    terms,
    domainList: options,
  },
});

export default getDomainListSuccess;
