import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import Trigger from 'src/js/modules/common/components/trigger';
import withSelection from 'src/js/modules/utils/with-selection';

const { Icon } = designSystem;

const FontSizeMobileTrigger = ({ triggerId }) => (
  <Trigger id={triggerId}>
    <Icon glyph="font-size" strokeWidth={1.3} size={20} />
  </Trigger>
);

FontSizeMobileTrigger.propTypes = {
  triggerId: PropTypes.string.isRequired,
};

export default withSelection(FontSizeMobileTrigger);
