import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { i18next } from '@yola/ws-sdk';
import { Button, Text, Checkbox } from '@yola/ws-ui';
import classNames from 'classnames';
import bowser from 'yola-bowser';

function DomainPurchaseConfirmation({ product, terms, onConfirm, onCancel }) {
  const [isAgreedToTerms, setAgreement] = useState(false);
  const { name, term, price } = product;

  function toggleAgreement() {
    setAgreement(!isAgreedToTerms);
  }

  function onPurchaseConfirm() {
    onConfirm(product);
  }

  function getTermText() {
    const [, value, period] = term.split('');

    const periodText = (() => {
      switch (period) {
        case 'M':
          return i18next.t('month(s)');
        case 'Y':
          return i18next.t('year(s)');
        default:
          return period;
      }
    })();

    return `${value} ${periodText}`;
  }

  return (
    <div className="purchase-confirmation">
      <div className="purchase-confirmation__header">
        <Text type="heading-1" className="product-select__title">
          {i18next.t('Domain purchase confirmation')}
        </Text>
      </div>
      <div className="purchase-confirmation__item">
        <div className="product-select__item-border-bottom">
          <Text type="heading-5" align="center" className="purchase-confirmation__plan-name">
            {name}
          </Text>
          <Text type="annotation" align="center">
            {i18next.t('1 domain')}
          </Text>
        </div>
        <div className="purchase-confirmation__detail">
          <div className="purchase-confirmation__detail-row">
            <Text className="purchase-confirmation__detail-text purchase-confirmation__detail-text--grey">
              {i18next.t('Term')}
            </Text>
            <Text className="purchase-confirmation__detail-text">{getTermText()}</Text>
          </div>
          <div className="purchase-confirmation__detail-row">
            <Text className="purchase-confirmation__detail-text purchase-confirmation__detail-text--grey">
              {i18next.t('Price')}
            </Text>
            <Text className="purchase-confirmation__detail-text">{price}</Text>
          </div>
        </div>
        <div className="product-select__item-border-top">
          <Text type="annotation" align="center">
            {i18next.t('Total')}
          </Text>
          <Text align="center" className="purchase-confirmation__item-price">
            {price}
          </Text>
        </div>
      </div>
      <Checkbox
        id="agreement-checkbox"
        className="purchase-confirmation__checkbox"
        onChange={toggleAgreement}
        checked={isAgreedToTerms}
      >
        <Text type="annotation" className="purchase-confirmation__checkbox-text">
          {/* eslint-disable-next-line yola/jsx-a11y/label-has-for */}
          <label
            htmlFor="agreement-checkbox"
            /* eslint-disable-next-line yola/react/no-danger */
            dangerouslySetInnerHTML={{ __html: terms }}
          />
        </Text>
      </Checkbox>
      <div className="purchase-confirmation__buttons-container">
        <Button
          className="purchase-confirmation__button"
          type="cta"
          size="large"
          onClick={onPurchaseConfirm}
          disabled={!isAgreedToTerms}
        >
          {i18next.t('Confirm')}
        </Button>
        <Button
          className={classNames(
            'purchase-confirmation__button',
            'purchase-confirmation__button--transparent',
            {
              'purchase-confirmation__button--hover': bowser.desktop,
            }
          )}
          type="link-primary"
          size="large"
          onClick={onCancel}
        >
          {i18next.t('Cancel')}
        </Button>
      </div>
    </div>
  );
}

DomainPurchaseConfirmation.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    term: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
  }).isRequired,
  terms: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default DomainPurchaseConfirmation;
