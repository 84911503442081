import { assets, dialogs, extensions, i18next } from '@yola/ws-sdk';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import matchesOrContains from './matches-or-contains';
import permissions from '../constants/permissions';
import showUnsupportedBrowserDialog from './show-unsupported-browser-dialog';
import handleClipboardPermissionDenied from './handle-clipboard-permission-denied';
import customDataTypes from '../constants/custom-data-types';

const getImageBlobFromURL = async (url) => {
  const response = await fetch(url);
  const blob = await response.blob();
  return { [`web ${blob.type}`]: blob };
};

const getImagePropsBlobFromElement = async (element, imageElement, isBackgroundImage) => {
  let attributesToCopy;
  if (isBackgroundImage) {
    attributesToCopy = {
      position: element.getAttribute('background-position'),
      scale: element.getAttribute('background-scale'),
      overlay: element.hasAttribute('overlay'),
      overlayOpacity: element.getAttribute('overlay-opacity'),
    };
  } else {
    attributesToCopy = {
      aspectRatio: element.aspectRatio,
      width: element.width,
      position: element.getAttribute('content-position'),
      scale: element.contentScale,
      alt: imageElement.getAttribute('alt'),
    };
  }
  if (!attributesToCopy) return null;
  const blob = new Blob([JSON.stringify(attributesToCopy)], { type: 'text/plain' });

  return { [customDataTypes.WEB_TEXT_PLAIN]: blob };
};

const copyImageToClipboard = async (element, config) => {
  const settings = extensions.accessors.getExtensionSettings(config.slug);
  const isBackgroundImage = element.matches(settings.blockWithBackgroundQuerySelector);
  const imageElement = matchesOrContains(element, settings.imageActionSelector);

  if (!isBackgroundImage && !imageElement) return;

  let isDenied = await handleClipboardPermissionDenied(permissions.features.CLIPBOARD_READ);
  if (isDenied) return;

  let imageSrc;
  if (isBackgroundImage) {
    imageSrc = element.getAttribute('background-image');
  } else {
    imageSrc = imageElement.getAttribute('src');
  }

  if (!imageSrc) return;
  const imageBlob = await getImageBlobFromURL(assets.helpers.addBaseHref(imageSrc));
  const propsBlob = await getImagePropsBlobFromElement(element, imageElement, isBackgroundImage);
  let clipboardItem;

  try {
    clipboardItem = new ClipboardItem({
      ...imageBlob,
      ...propsBlob,
    });
  } catch (e) {
    console.error(e);
    showUnsupportedBrowserDialog();
    return;
  }

  await navigator.clipboard
    .write([clipboardItem])
    .then(dialogs.operations.hide)
    .catch(async (e) => {
      console.error(e);
      isDenied = await handleClipboardPermissionDenied(permissions.features.CLIPBOARD_READ);

      if (isDenied) return;

      if (e.message.includes('Document is not focused.')) {
        dialogs.operations.show(dialogTypes.ALERT_DIALOG, {
          glyph: 'alert-circle',
          captions: {
            title: i18next.t('Oops!'),
            description: i18next.t(
              'Something went wrong while copying to the clipboard. Please try again.'
            ),
            dismiss: i18next.t('Got it'),
          },
          onDismiss: () => dialogs.operations.hide(),
        });
        return;
      }

      showUnsupportedBrowserDialog();
    });
};

export default copyImageToClipboard;
