import { globals, view } from '@yola/ws-sdk';
import store from '../../../store';
import { NAVIGATION_GLOBAL_ID } from '../constants/common';

function getNavigationElement() {
  const state = store.getState();
  const global = globals.selectors.getGlobal(state, NAVIGATION_GLOBAL_ID);
  const liveDocument = view.accessors.getLiveDocument();

  return liveDocument.querySelector(`ws-global[src="${global.source}"]`);
}

export default getNavigationElement;
