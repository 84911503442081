import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownContent,
  DropdownTrigger,
  Icon,
  Menu,
  MenuItem,
  Render,
  Text,
} from '@yola/ws-ui';

const LanguageItem = ({
  code,
  name,
  country,
  isMain,
  isMobile,
  setMainLang,
  deleteLang,
  captions,
}) => {
  const handleMakeMain = useCallback(() => setMainLang(code), [code, setMainLang]);
  const handleDelete = useCallback(() => deleteLang(code), [code, deleteLang]);

  return (
    <div className="ws-multilingual__item">
      <div className="ws-multilingual__language-desc">
        {country.iconComponent}
        <Text type="default">{name}</Text>
        {isMain && (
          <Text type="default" theme="grey" className="ws-multilingual__main-language">
            — {captions.mainLang}
          </Text>
        )}
      </div>

      <Render if={isMobile && !isMain}>
        <div className="ws-multilingual__item-mob-controls">
          <Dropdown dropOn="click" drop="left-top" onClick={(e) => e.stopPropagation()}>
            <DropdownTrigger>
              <Icon glyph="more-vertical" />
            </DropdownTrigger>
            <DropdownContent>
              <Menu>
                <MenuItem onClick={handleMakeMain}>{captions.setMainLang}</MenuItem>
                <MenuItem onClick={handleDelete}>{captions.deleteLang}</MenuItem>
              </Menu>
            </DropdownContent>
          </Dropdown>
        </div>
      </Render>

      <Render if={!isMobile && !isMain}>
        <div className="ws-multilingual__item-controls">
          <Icon data-tip={captions.setMainLang} glyph="home" size={16} onClick={handleMakeMain} />
          <Icon data-tip={captions.deleteLang} glyph="trash" size={16} onClick={handleDelete} />
        </div>
      </Render>
    </div>
  );
};

LanguageItem.propTypes = {
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  country: PropTypes.shape().isRequired,
  captions: PropTypes.shape().isRequired,
  isMain: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  setMainLang: PropTypes.func.isRequired,
  deleteLang: PropTypes.func.isRequired,
};

export default LanguageItem;
