import { blocks, template, uuid } from '@yola/ws-sdk';
import getAcceptableVariations from './get-acceptable-variations';
import excludeConflictingWidgets from './exclude-conflicting-widgets';
import { DISPLAY_ON_WIDGET_SELECTOR } from '../constants/common';

// Due to initial implementation of 'add widget' approach is matched with the existing one for 'add block',
// this helper is just copied from `blocks` module as is. It isn't re-used for a better maintainability
// if we want to change this approach for widgets or need to tweak it somehow specifically.

const getWidgetsList = ({ element, blockTemplatesList, featureFlags }) =>
  excludeConflictingWidgets(
    getAcceptableVariations(
      blocks.filters.findAllFor(element, featureFlags).reduce((result, block) => {
        const templates = blockTemplatesList.filter(
          (blockTemplate) => blockTemplate.id === block.templateId
        );
        const dependencies = templates.length ? templates[0].dependencies : [];
        const widget = {
          id: block.id,
          title: template.accessors.getMetaTranslations(block.title),
          tags: block.tags,
          localizedTags: block.tags.map((tag) => template.accessors.getMetaTranslations(tag)),
          category: block.category,
          localizedCategory: template.accessors.getMetaTranslations(block.category),
          isPremium: Boolean(block.premium),
          order: block.order,
          dependencies,
        };

        if (block.variations) {
          block.variations.forEach((variation) => {
            result.push({
              ...widget,
              ...(variation.title && {
                title: template.accessors.getMetaTranslations(variation.title),
              }),
              uuid: uuid(),
              variationId: variation.id,
              source: variation.source,
              preview: variation.preview,
              html: variation.html,
              properties: variation.properties,
            });
          });
          // TODO: Remove code below in `else` after full MPT migrations
        } else {
          widget.source = block.source;
          widget.preview = block.preview;

          result.push(widget);
        }
        return result;
      }, []),
      DISPLAY_ON_WIDGET_SELECTOR
    ),
    element
  );

export default getWidgetsList;
