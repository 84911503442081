import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { i18next, dialogs, site } from '@yola/ws-sdk';
import segment from '../../analytics/segment';
import ai from '../../ai';
import dialogTypes from '../../dialogs/constants/dialog-types';
import useProgressSteps from '../hooks/use-progress-steps';
import WizardProgressDialog from '../components/wizard-progress-dialog';

const {
  constants: { events },
  trackers: { trackEvent },
} = segment;
const {
  constants: { errorTypes },
  helpers: { initiatePageGeneration },
} = ai;

const getCaptions = ({ name }) => ({
  title: `${i18next.t('Generating your page')}...`,
  steps: [
    `${i18next.t('Creating {name}', { name })}...`,
    `${i18next.t('Structuring')}...`,
    `${i18next.t('Designing layout')}...`,
    `${i18next.t('Text generation')}...`,
    `${i18next.t('Adding images')}...`,
    `${i18next.t('Finalizing')}...`,
  ],
});

const getSuccessDialogCaptions = () => ({
  title: i18next.t('Your page is ready'),
  description: i18next.t(
    'Dive in and add your unique touches to turn this draft into your perfect page'
  ),
  buttonLabel: i18next.t('Start editing'),
});

const ESTIMATED_GENERATION_TIME = 5 * 1000;

function PageGenerationContainer(props) {
  const { displayInNavigation, name, recipeId, locale, displayBusinessName } = props;
  const captions = getCaptions({ name });
  const businessCategory = useSelector(site.selectors.getBusinessCategory);
  const dispatch = useDispatch();
  const { steps, stopProgressSteps } = useProgressSteps({
    initialSteps: captions.steps,
    estimatedTime: ESTIMATED_GENERATION_TIME,
  });

  useEffect(() => {
    async function handlePageGeneration() {
      try {
        const { results } = await initiatePageGeneration({
          recipeId,
          locale,
          name,
          displayInNavigation,
        });

        stopProgressSteps();

        const { html, usedBlocks, unsplashSearchQuery } = results;

        dispatch(
          site.actions.createPage({
            html,
            locale,
            displayInNavigation,
            usedBlocks,
            recipeId,
            isMultilingualSite: site.verifiers.isMultilingualSite(),
            name,
            unsplashSearchQuery: unsplashSearchQuery || null,
            displayBusinessName,
          })
        );

        dialogs.operations.show(dialogTypes.AI_SUCCESS_DIALOG, {
          captions: getSuccessDialogCaptions(),
        });
      } catch (e) {
        const errorType = e.results?.code || errorTypes.UNKNOWN_GENERATE_PAGE;

        dialogs.operations.show(dialogTypes.AI_ERROR_DIALOG, {
          errorType,
          onRenew: () => {
            trackEvent(events.AI_WIZARD_TRY_AGAIN_TRIGGER_CLICKED, {
              businessCategory,
            });
            dialogs.operations.show(dialogTypes.PAGE_GENERATION_DIALOG, {
              name,
              displayInNavigation,
              locale,
              recipeId,
            });
          },
        });
      }
    }

    handlePageGeneration();
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  return <WizardProgressDialog steps={steps} title={captions.title} />;
}

PageGenerationContainer.propTypes = {
  name: PropTypes.string,
  displayInNavigation: PropTypes.bool,
  displayBusinessName: PropTypes.bool,
  locale: PropTypes.string,
  recipeId: PropTypes.string.isRequired,
};

PageGenerationContainer.defaultProps = {
  name: 'Untitled',
  displayInNavigation: true,
  displayBusinessName: false,
  locale: 'en',
};
export default PageGenerationContainer;
