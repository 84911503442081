import dialogs from 'yola-editor/src/js/modules/dialogs';
import wlproxyClient from 'yola-editor/src/js/modules/wlproxy-client/service-client';
import subscriptions from 'yola-editor/src/js/modules/subscriptions';
import actionTypes from '../constants/action-types';
import getCommonWlproxyRequestParams from '../helpers/get-common-wlproxy-request-params';
import handleOrderSubscriptionFailure from '../helpers/handle-order-subscription-failure';
import upgradeDataStorage from '../helpers/upgrade-data-storage';

const orderSubscriptionPlan = (store) => (next) => (action) => {
  switch (action.type) {
    case actionTypes.ORDER_SUBSCRIPTION_PLAN: {
      store.dispatch(dialogs.actions.show(dialogs.dialogTypes.IN_APP_PURCHASE_PENDING));

      const state = store.getState();
      const { language, siteId } = getCommonWlproxyRequestParams(state);
      const { productType, sku } = action.payload;
      const editorUpgradeAction = upgradeDataStorage.getEditorUpgradeAction();
      const upgradeDetails = upgradeDataStorage.getUpgradeDetails();

      const serviceClient = wlproxyClient.get();

      serviceClient
        .order({
          language,
          siteId,
          details: {
            productType,
            sku,
          },
        })
        .then(() => {
          store.dispatch(
            dialogs.actions.show(dialogs.dialogTypes.IN_APP_PURCHASE_SUCCESS, {
              onClose: upgradeDetails.onUpgradeResolveFeedback,
            })
          );
          store.dispatch(subscriptions.thunks.fetchSubscriptions());
          store.dispatch(subscriptions.thunks.fetchCurrentPackage());
          next(action);
        })
        .catch((resp) => {
          handleOrderSubscriptionFailure(store, resp);
          editorUpgradeAction.reject();
          next(action);
        });

      break;
    }
    default:
      next(action);
  }
};

export default orderSubscriptionPlan;
