import store from 'src/js/store';
import getUpsellSettingsHelper from '../helpers/get-upsell-settings';

function getUpsellSettings({ isFreeDomainAvailable }) {
  const state = store.getState();

  return getUpsellSettingsHelper({ state, dispatch: store.dispatch, isFreeDomainAvailable });
}

export default getUpsellSettings;
