import getWhiteLabelStatus from '../selectors/white-label-status';
import getUserData from '../selectors/user-data';
import getExternalUpsellTypes from '../selectors/external-upsell-types';
import externalUpsellTypes from '../constants/external-upsell-types';

const hasPartnersDomainUpsell = (state) => {
  const isWL = getWhiteLabelStatus(state);
  const { custom_domain_connected: customDomainConnected } = getUserData(state);
  const externalUpsellTypesArray = getExternalUpsellTypes(state);

  return (
    isWL && externalUpsellTypesArray.includes(externalUpsellTypes.DOMAIN) && !customDomainConnected
  );
};

export default hasPartnersDomainUpsell;
