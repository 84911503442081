import React from 'react';
import PropTypes from 'prop-types';
import { TextInputDialog } from '@yola/ws-ui';

function ImageAltDialogContainer(props) {
  const { onDialogMainAction, onDialogCancel, onSubmit, onCancel, ...otherProps } = props;

  const handleSubmit = (...args) => {
    onDialogMainAction();
    onSubmit(...args);
  };
  const handleCancel = (...args) => {
    onDialogCancel();
    onCancel(...args);
  };

  return <TextInputDialog onSubmit={handleSubmit} onCancel={handleCancel} {...otherProps} />;
}

ImageAltDialogContainer.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    checkboxLabel: PropTypes.string,
    submit: PropTypes.string.isRequired,
    cancel: PropTypes.string.isRequired,
  }).isRequired,
  isCheckboxAvailable: PropTypes.bool,
  value: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  // Passed from ws-editor's `dialogs-container`:
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

ImageAltDialogContainer.defaultProps = {
  isCheckboxAvailable: false,
};

export default ImageAltDialogContainer;
