import React from 'react';
import PropTypes from 'prop-types';
import bowser from 'yola-bowser';
import classNames from 'classnames';
import {
  Icon,
  Modal,
  Panel,
  PanelGroup,
  PanelLeft,
  PanelRight,
  FloatingAction,
  Tooltip,
  Text,
  designSystem,
} from '@yola/ws-ui';
import isDeveloperMode from 'yola-editor/src/js/modules/user/accessors/is-developer-mode';
import getLanguageCodes from 'src/js/modules/website-settings/helpers/get-language-codes';
import PageManagerItem from './item';
import PageManagerTemplateItem from './template-item';
import stopPropagation from '../../utils/stop-propagation';
import PageLimitBanner from './page-limit-banner';

const { Search, Stack, Heading, Paragraph, Box } = designSystem;

const UPGRADE_BANNER_SIZE = 80;

class PageManager extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalSize: bowser.mobile,
      searchIsFocused: false,
      widthDefault: 360,
      heightDefault: 533,
      heightMin: 250,
    };

    this.languageCodes = getLanguageCodes();
    this.onSearchFocus = this.onSearchFocus.bind(this);
    this.onSetSizeModal = this.onSetSizeModal.bind(this);
    this.onChangeQuery = this.onChangeQuery.bind(this);
    this.onClear = this.onClear.bind(this);
  }

  onSearchFocus() {
    const { onSearchClick } = this.props;
    onSearchClick();
  }

  onSetSizeModal() {
    const { modalSize } = this.state;
    Tooltip.hide();
    this.setState(
      {
        modalSize: !modalSize,
      },
      () => Tooltip.rebuild()
    );
  }

  onClear() {
    const { filter } = this.props;
    const { searchIsFocused } = this.state;
    filter(null, searchIsFocused);
  }

  onChangeQuery(value) {
    const { filter } = this.props;
    filter(value);
  }

  render() {
    const {
      items,
      captions,
      onClose,
      onSetHomePageClick,
      onDeletePageClick,
      onPageSettingsClick,
      onDeveloperConsoleClick,
      onItemClick,
      onTemplateItemClick,
      upgradeBanner,
      optionsToCreatePage,
      activeItem,
      onAddNewPage,
      onCopyPage,
      styles,
      className,
      isPageCreationAvailable,
      locale,
      siteLocalesList,
      isHomePage,
      onTranslatePage,
      isDeveloperConsoleEnable,
    } = this.props;
    const { modalSize, widthDefault, heightDefault, heightMin } = this.state;

    const pageManagerClasses = classNames('ws-page-manager', className);
    const isMultiLocales = siteLocalesList && siteLocalesList.length > 1;
    const filteredItems = items.filter((item) => item.locale === locale);
    const isDeveloperConsoleEnabled = isDeveloperConsoleEnable || isDeveloperMode();
    const dragCancelSelector = `
      .ws-input,
      .ws-page-manager__header-fullscreen,
      .ws-page-manager__header-close
    `;

    return (
      <div className={pageManagerClasses} style={styles}>
        <Modal
          width={widthDefault}
          height={heightDefault}
          minHeight={heightMin}
          offsetX={100}
          offsetY={50}
          fullscreen={modalSize}
          dragHandleSelector=".ws-page-manager__header"
          dragCancelSelector={dragCancelSelector}
          handleCancel={onClose}
          handleSubmit={onClose}
        >
          <PanelGroup height="100%">
            <Panel
              theme="gray-100"
              height="48"
              align="middle"
              corners="squared"
              className="ws-panel--shrink ws-page-manager__header"
            >
              <PanelLeft style={{ paddingLeft: '24px' }}>
                <Text type="heading-6">{captions.title}</Text>
              </PanelLeft>

              <PanelRight>
                <div className="ws-page-manager__header-controls">
                  <Icon
                    glyph={modalSize ? 'minimize' : 'maximize'}
                    size={14}
                    style={{ cursor: 'pointer' }}
                    onClick={this.onSetSizeModal}
                    onMouseDown={stopPropagation}
                    data-tip={captions.fullscreenTooltip}
                    className="ws-page-manager__header-fullscreen"
                  />

                  <div
                    className="ws-page-manager__header-close"
                    onClick={onClose}
                    onMouseDown={stopPropagation}
                  >
                    {captions.close}
                  </div>
                </div>
              </PanelRight>
            </Panel>

            <Panel className="ws-page-manager__content" height="100%" scrollable>
              <div className="ws-page-manager__search">
                <Search
                  placeholder={captions.searchPlaceholder}
                  onFocus={this.onSearchFocus}
                  onChange={this.onChangeQuery}
                  onClear={this.onClear}
                />
              </div>
              {filteredItems.length ? (
                <React.Fragment>
                  {filteredItems.map((item) => (
                    <PageManagerItem
                      onSetHomePageClick={onSetHomePageClick}
                      onPageSettingsClick={onPageSettingsClick}
                      onDeletePageClick={onDeletePageClick}
                      onCopyPageClick={onCopyPage}
                      onDeveloperConsoleClick={onDeveloperConsoleClick}
                      onItemClick={onItemClick}
                      item={item}
                      key={item.id}
                      activeItem={activeItem}
                      captions={captions}
                      isHomePage={isHomePage(item.path, this.languageCodes)}
                      isDeveloperConsoleEnabled={isDeveloperConsoleEnabled}
                    />
                  ))}

                  {optionsToCreatePage && optionsToCreatePage.length > 0 && (
                    <React.Fragment>
                      <Box marginTop="spacing-2-xs" marginBottom="spacing-3-xs">
                        <Paragraph appearance="medium-emphasis" size="small">
                          <strong>{captions.pageTemplatesSection}</strong>
                        </Paragraph>
                      </Box>

                      {optionsToCreatePage.map((item) => (
                        <PageManagerTemplateItem
                          item={item}
                          key={item.name}
                          onItemClick={onTemplateItemClick}
                          captions={{
                            hoveredControl: captions.hoveredTemplateControl,
                          }}
                        />
                      ))}
                    </React.Fragment>
                  )}
                </React.Fragment>
              ) : (
                <div className="ws-page-manager__empty-state">
                  <Stack gap="spacing-3-xs">
                    <Heading type="heading-2" appearance="low-emphasis" align="center">
                      {captions.searchEmptyTitle}
                    </Heading>
                    <Paragraph size="large" appearance="low-emphasis" align="center">
                      {captions.searchEmptyText}
                    </Paragraph>
                  </Stack>
                </div>
              )}
            </Panel>

            {upgradeBanner && (
              <Panel className="ws-page-manager__banner-container">
                <Box margin="spacing-m" marginTop="spacing-s">
                  <PageLimitBanner
                    current={upgradeBanner.current}
                    total={upgradeBanner.total}
                    onButtonClick={upgradeBanner.onUpgrade}
                    captions={{
                      title: upgradeBanner.title,
                      buttonText: upgradeBanner.buttonText,
                    }}
                  />
                </Box>
              </Panel>
            )}
          </PanelGroup>
          {isMultiLocales && (
            <FloatingAction
              buttonColorType="primary"
              buttonSizeType="smaller"
              offsetX={28}
              offsetY={88 + (upgradeBanner ? UPGRADE_BANNER_SIZE : 0)}
              onClick={onTranslatePage}
              icon="translate"
              iconSize={24}
              iconStrokeWidth={0.5}
              inverseOffsetOrigin
            />
          )}
          {isPageCreationAvailable && (
            <FloatingAction
              icon="plus"
              inverseOffsetOrigin
              offsetX={24}
              offsetY={24 + (upgradeBanner ? UPGRADE_BANNER_SIZE : 0)}
              onClick={onAddNewPage}
            />
          )}
        </Modal>
      </div>
    );
  }
}

PageManager.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.string,
  onItemClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onDeletePageClick: PropTypes.func.isRequired,
  onPageSettingsClick: PropTypes.func.isRequired,
  onSetHomePageClick: PropTypes.func.isRequired,
  onDeveloperConsoleClick: PropTypes.func.isRequired,
  onAddNewPage: PropTypes.func.isRequired,
  onTranslatePage: PropTypes.func.isRequired,
  onCopyPage: PropTypes.func.isRequired,
  filter: PropTypes.func.isRequired,
  activeItem: PropTypes.string,
  locale: PropTypes.string,
  isPageCreationAvailable: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      path: PropTypes.string,
      order: PropTypes.string,
    })
  ),
  captions: PropTypes.shape({
    searchPlaceholder: PropTypes.string,
    close: PropTypes.string,
    addPage: PropTypes.string,
    dropHere: PropTypes.string,
    selected: PropTypes.string,
    cancel: PropTypes.string,
    maximize: PropTypes.string,
    batchDelete: PropTypes.string,
    setHomePage: PropTypes.string,
    pageSettings: PropTypes.string,
    deletePage: PropTypes.string,
    setAsHomePage: PropTypes.string,
    fullscreenTooltip: PropTypes.string,
    developerConsole: PropTypes.string,
    title: PropTypes.string,
    searchEmptyTitle: PropTypes.string,
    searchEmptyText: PropTypes.string,
    pageTemplatesSection: PropTypes.string,
    hoveredTemplateControl: PropTypes.string,
  }).isRequired,
  isHomePage: PropTypes.func.isRequired,
  siteLocalesList: PropTypes.arrayOf(PropTypes.string).isRequired,
  isDeveloperConsoleEnable: PropTypes.bool.isRequired,
  onSearchClick: PropTypes.func,
  optionsToCreatePage: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  upgradeBanner: PropTypes.shape({
    current: PropTypes.number,
    total: PropTypes.number,
    onUpgrade: PropTypes.func,
    title: PropTypes.string,
    buttonText: PropTypes.string,
  }),
  onTemplateItemClick: PropTypes.func.isRequired,
};

PageManager.defaultProps = {
  className: null,
  styles: null,
  activeItem: '',
  items: [],
  isPageCreationAvailable: false,
  locale: null,
  onSearchClick: null,
  upgradeBanner: null,
  optionsToCreatePage: [],
};

export default PageManager;
