import React from 'react';

function FAQPreview() {
  return (
    <svg viewBox="0 0 1920 1300" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="1920" height="1300" fill="white" />
      <rect width="1920" height="120" fill="white" />
      <rect opacity="0.8" x="375" y="44" width="100" height="32" rx="8" fill="#DADDE0" />
      <rect x="993" y="48" width="100" height="24" rx="8" fill="#B0B8BF" />
      <rect opacity="0.8" x="1125" y="48" width="100" height="24" rx="8" fill="#DADDE0" />
      <rect opacity="0.8" x="1257" y="48" width="100" height="24" rx="8" fill="#DADDE0" />
      <rect x="1405" y="32" width="140" height="56" rx="8" fill="#8E959E" />
      <rect opacity="0.4" x="1433" y="52" width="84" height="16" rx="8" fill="white" />
      <rect width="1920" height="1060" transform="translate(0 120)" fill="white" />
      <rect x="761" y="200" width="398" height="48" rx="8" fill="#B0B8BF" />
      <rect opacity="0.8" x="649" y="288" width="622" height="24" rx="8" fill="#DADDE0" />
      <rect opacity="0.8" x="743.5" y="332" width="433" height="24" rx="8" fill="#DADDE0" />
      <rect x="575" y="420" width="770" height="180" rx="24" fill="#DADDE0" fillOpacity="0.3" />
      <rect x="607" y="452" width="421" height="32" rx="8" fill="#B0B8BF" />
      <rect opacity="0.8" x="607" y="504" width="625" height="24" rx="8" fill="#DADDE0" />
      <rect opacity="0.8" x="607" y="544" width="531" height="24" rx="8" fill="#DADDE0" />
      <rect x="575" y="630" width="770" height="180" rx="24" fill="#DADDE0" fillOpacity="0.3" />
      <rect x="607" y="662" width="421" height="32" rx="8" fill="#B0B8BF" />
      <rect opacity="0.8" x="607" y="714" width="625" height="24" rx="8" fill="#DADDE0" />
      <rect opacity="0.8" x="607" y="754" width="531" height="24" rx="8" fill="#DADDE0" />
      <rect x="575" y="840" width="770" height="180" rx="24" fill="#DADDE0" fillOpacity="0.3" />
      <rect x="607" y="872" width="421" height="32" rx="8" fill="#B0B8BF" />
      <rect opacity="0.8" x="607" y="924" width="625" height="24" rx="8" fill="#DADDE0" />
      <rect opacity="0.8" x="607" y="964" width="531" height="24" rx="8" fill="#DADDE0" />
      <rect width="1920" height="120" transform="translate(0 1180)" fill="#646C75" />
      <g opacity="0.3">
        <rect x="770" y="1228" width="100" height="24" rx="8" fill="white" />
        <rect x="910" y="1228" width="100" height="24" rx="8" fill="white" />
        <rect x="1050" y="1228" width="100" height="24" rx="8" fill="white" />
      </g>
    </svg>
  );
}

export default FAQPreview;
