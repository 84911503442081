import segment from 'src/js/modules/analytics/segment';
import prepareDialogDisplayedEventTraits from '../helpers/prepare-dialog-displayed-event-traits';

const trackWebsiteSettingsDialogDisplayed = (initialValues) => {
  const { trackers, constants } = segment;

  const traits = prepareDialogDisplayedEventTraits(initialValues);

  trackers.trackEvent(constants.events.WEBSITE_SETTINGS_DIALOG_DISPLAYED, traits);
};

export default trackWebsiteSettingsDialogDisplayed;
