import fetchCategories from './fetch-categories';
import redirectToStore from './redirect-to-store';
import injectStoreId from './inject-store-id';
import refreshStore from './refresh-store';

export default {
  fetchCategories,
  injectStoreId,
  redirectToStore,
  refreshStore,
};
