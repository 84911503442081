import { hooks, site, template } from '@yola/ws-sdk';
import constants from '../constants';
import track from '../track';

const trackBlockDuplicated = () => {
  hooks.operations.subscribe(
    hooks.availableHooks.ON_AFTER_BLOCK_DUPLICATED,
    ({ blockId, variationId: blockVariationId }) => {
      if (!blockId) return;

      const isBlockWidget = blockId.startsWith('widget-');
      if (isBlockWidget) return;

      track(constants.events.BLOCK_DUPLICATED, {
        siteId: site.accessors.getSiteId(),
        templateBuildSlug: template.accessors.getBuildSlug(),
        pageId: site.accessors.getActivePageId(),
        blockId,
        blockVariationId,
      });
    }
  );
};

export default trackBlockDuplicated;
