import dialogs from '../../dialogs';
import siteSelectors from '../../site/selectors';
import userSelectors from '../../user/selectors';

const showDifmPrivacyUpsell = (store) => (next) => (action) => {
  if (action.type !== dialogs.actionTypes.HIDE_DIALOG) {
    next(action);
    return;
  }

  const state = store.getState();
  const { type } = dialogs.selectors.getDialog(state);
  const { complianceAccepted } = userSelectors.getUserPreferences(state);

  if (type !== dialogs.dialogTypes.PUBLISH_SUCCESS) {
    next(action);
    return;
  }

  const publishCount = siteSelectors.getPublishCount(state);
  const externalUpsellTypes = userSelectors.getExternalUpsellTypes(state);

  if (
    publishCount < 1 &&
    externalUpsellTypes.includes('difm_privacy') &&
    (!complianceAccepted || complianceAccepted === 'False')
  ) {
    store.dispatch(dialogs.actions.show(dialogs.dialogTypes.DIFM_PRIVACY_UPSELL_DIALOG));
    return;
  }

  next(action);
};

export default showDifmPrivacyUpsell;
