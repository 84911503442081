import React from 'react';
import PropTypes from 'prop-types';

const ThumbnailFooter = ({ title, ...rest }) => (
  <div className="ws-thumbnail__footer" {...rest}>
    <div className="ws-thumbnail__title">{title}</div>
  </div>
);

ThumbnailFooter.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ThumbnailFooter;
