import get from 'lodash.get';
import { config } from '@yola/ws-sdk';

const getTermsOfUseUrl = (rootState) => {
  const cfg = config.selectors.getConfig(rootState);

  return get(cfg, 'yola-editor.terms_of_use_page_url', null);
};

export default getTermsOfUseUrl;
