import React from 'react';
import PropTypes from 'prop-types';
import { view } from '@yola/ws-sdk';
import { DropGuide, DropGuideArea } from '@yola/ws-ui';
import { connect } from 'react-redux';
import selectors from '../selectors';
import getDropGuideSizeAndPosition from '../helpers/get-drop-guide-size-and-position';
import directionTypes from '../constants/direction-types';
import positionTypes from '../constants/position-types';

function DropGuideContainer(props) {
  const { dropTargetElementId, adjacentPosition, scrollPosition } = props;
  if (dropTargetElementId === null) {
    return null;
  }

  const element = view.accessors.getLiveElement(dropTargetElementId);
  const elementFlowType = view.helpers.getElementFlowType(element);
  if (!elementFlowType) return null;

  const direction =
    elementFlowType === view.elementFlowTypes.VERTICAL
      ? directionTypes.HORIZONTAL
      : directionTypes.VERTICAL;

  const details = getDropGuideSizeAndPosition(
    element,
    elementFlowType,
    adjacentPosition,
    scrollPosition
  );

  if (adjacentPosition === positionTypes.BEFORE_END) {
    return <DropGuideArea {...details} />;
  }

  return <DropGuide {...details} direction={direction} />;
}

DropGuideContainer.propTypes = {
  dropTargetElementId: PropTypes.string,
  adjacentPosition: PropTypes.string,
  scrollPosition: PropTypes.number,
};

DropGuideContainer.defaultProps = {
  dropTargetElementId: null,
  adjacentPosition: null,
  scrollPosition: null,
};

const mapStateToProps = (state) => ({
  dragSourceElementId: selectors.getDragSourceElement(state),
  dropTargetElementId: selectors.getDropTargetElement(state),
  scrollPosition: view.selectors.getScrollPosition(state),
  adjacentPosition: selectors.getAdjacentPosition(state),
});

export default connect(mapStateToProps)(DropGuideContainer);
