import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@yola/ws-ui';
import { dialogs } from '@yola/ws-sdk';
import bowser from 'yola-bowser';
import Trigger from 'src/js/modules/common/components/trigger';
import withSelection from 'src/js/modules/utils/with-selection';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import verifiers from '../verifiers';

const { isLinkSelected } = verifiers;

class LinkFormattingTrigger extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: isLinkSelected(),
    };
    this.isMobile = Boolean(bowser.mobile);
    this.updateActiveStatus = this.updateActiveStatus.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    if (!this.isMobile) {
      document.addEventListener('keydown', this.handleKeyDown, false);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { active } = prevState;
    const isActive = isLinkSelected();

    if (isActive !== active) {
      this.updateActiveStatus();
    }
  }

  componentWillUnmount() {
    if (!this.isMobile) {
      document.removeEventListener('keydown', this.handleKeyDown, false);
    }
  }

  handleKeyDown(event) {
    const { triggerKeyCode, elementId } = this.props;
    const { ctrlKey, code, metaKey } = event;

    if (code !== triggerKeyCode) {
      return;
    }

    if ((bowser.macOS && metaKey) || ((bowser.linux || bowser.windows) && ctrlKey)) {
      dialogs.operations.show(dialogTypes.LINK_FORMATTING_DIALOG, { elementId });
    }
  }

  updateActiveStatus() {
    this.setState({
      active: isLinkSelected(),
    });
  }

  render() {
    const { active } = this.state;
    const { triggerId } = this.props;
    return (
      <Trigger active={active} id={triggerId}>
        <Icon glyph="link" size="16" strokeWidth="1.8" />
      </Trigger>
    );
  }
}

LinkFormattingTrigger.propTypes = {
  triggerKeyCode: PropTypes.string.isRequired,
  triggerId: PropTypes.string.isRequired,
  elementId: PropTypes.string.isRequired,
};

export default withSelection(LinkFormattingTrigger);
