const getSafeLocalStorage = () => {
  let safeLocalStorage;

  try {
    safeLocalStorage = localStorage;
  } catch (err) {
    safeLocalStorage = {
      setItem: () => {},
      removeItem: () => {},
      getItem: () => null,
    };
  }

  return safeLocalStorage;
};

export default getSafeLocalStorage;
