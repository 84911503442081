import { BOTTOM, TOP } from '../constants/common';

const getElementSideToScrollInContainer = (element, scrollContainer) => {
  const { height, top: elementTop, bottom: elementBottom } = element.getBoundingClientRect();
  const {
    height: containerHeight,
    top: containerTop,
    bottom: containerBottom,
  } = scrollContainer.getBoundingClientRect();

  const higherThanContainer = height >= containerHeight;

  if (elementTop < containerTop || higherThanContainer) {
    return TOP;
  }

  if (elementBottom - containerBottom > 0) {
    return BOTTOM;
  }

  return null;
};

export default getElementSideToScrollInContainer;
