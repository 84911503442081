import React from 'react';
import PropTypes from 'prop-types';
import { Text, Button, TreeView } from '@yola/ws-ui';

const Redirects = (props) => {
  const { captions, treeViewItems, onOrderChange, onCreateRedirectButtonClick } = props;

  const handleCreateRedirect = () => {
    onCreateRedirectButtonClick();
  };

  return (
    <div className="ws-redirects">
      <div className="ws-redirects-heading">
        <Text type="heading-6">{captions.title}</Text>
        <Text type="annotation">
          {captions.description}
          &nbsp;
          <span
            // eslint-disable-next-line yola/react/no-danger
            dangerouslySetInnerHTML={{ __html: captions.helpCenterDescription }}
          />
        </Text>
        <Button onClick={handleCreateRedirect}>{captions.buttonLabel}</Button>
      </div>

      {Boolean(treeViewItems.length) && (
        <div className="ws-redirects-list">
          <Text type="heading-6" className="ws-redirects-list__title">
            {captions.listViewCaption}
          </Text>
          <TreeView items={treeViewItems} onOrderChange={onOrderChange} interaction="sortable" />
        </div>
      )}
    </div>
  );
};

Redirects.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    helpCenterDescription: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string.isRequired,
    listViewCaption: PropTypes.string.isRequired,
  }).isRequired,
  treeViewItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      order: PropTypes.string.isRequired,
      actions: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          glyph: PropTypes.string,
          onClick: PropTypes.func,
        })
      ),
    })
  ).isRequired,
  onOrderChange: PropTypes.func,
  onCreateRedirectButtonClick: PropTypes.func.isRequired,
};

Redirects.defaultProps = {
  onOrderChange: Function.prototype,
};

export default Redirects;
