import React from 'react';

const AddressIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="144" height="90" viewBox="0 0 144 90" fill="none">
    <path
      d="M92 40.2727C92 56.8182 72 71 72 71C72 71 52 56.8182 52 40.2727C52 34.6308 54.1071 29.22 57.8579 25.2306C61.6086 21.2412 66.6957 19 72 19C77.3043 19 82.3914 21.2412 86.1421 25.2306C89.8929 29.22 92 34.6308 92 40.2727Z"
      fill="#DADDE0"
    />
    <path
      d="M72 47C76.4183 47 80 43.4183 80 39C80 34.5817 76.4183 31 72 31C67.5817 31 64 34.5817 64 39C64 43.4183 67.5817 47 72 47Z"
      fill="#646C75"
    />
  </svg>
);

export default AddressIcon;
