import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FloatingAction } from '@yola/ws-ui';
import { utils } from '@yola/ws-sdk';
import UploadImageMenu from 'src/js/modules/common/components/upload-image-menu';
import getMenuCssPosition from 'src/js/modules/context-menu/helpers/get-menu-css-position';
import imageTypes from 'src/js/modules/extensions/common/constants/image-types';

const VERTICAL_GAP = 4;

export default function AddImagesButton(props) {
  const {
    id,
    elementId,
    onImageUpload,
    onWrongFileType,
    onStockPhotoUpload,
    onPhotoStockDialogCancel,
  } = props;
  const uploadMenuTriggerRef = React.useRef();
  const fileTriggerRef = React.useRef();

  const calculatePositionDecorator = useCallback((menuElement) => {
    const { x, y, width } = uploadMenuTriggerRef.current.firstElementChild.getBoundingClientRect();
    const { width: menuElWidth, height: menuElHeight } = menuElement.getBoundingClientRect();
    const eventX = x - menuElWidth + width;
    const eventY = y - menuElHeight + VERTICAL_GAP;

    return getMenuCssPosition(menuElement, eventX, eventY);
  }, []);

  const getContextMenuPosition = useCallback(() => {
    const { x, y } = uploadMenuTriggerRef.current.firstElementChild.getBoundingClientRect();
    return { x, y };
  }, []);

  return (
    <UploadImageMenu
      id={id}
      onStockPhotoUpload={onStockPhotoUpload}
      onPhotoStockDialogCancel={onPhotoStockDialogCancel}
      onImageUpload={onImageUpload}
      getContextMenuPosition={getContextMenuPosition}
      calculatePositionDecorator={calculatePositionDecorator}
      onWrongFileType={onWrongFileType}
      accept={imageTypes.IMAGE_ACCEPT_TYPE}
      fileTriggerRef={fileTriggerRef}
      triggerNodeRef={uploadMenuTriggerRef}
      elementId={elementId}
      multiple
    >
      <FloatingAction
        icon="plus"
        inverseOffsetOrigin
        offsetX={24}
        offsetY={72}
        onClick={utils.noop}
      />
    </UploadImageMenu>
  );
}

AddImagesButton.propTypes = {
  onImageUpload: PropTypes.func.isRequired,
  onWrongFileType: PropTypes.func.isRequired,
  onStockPhotoUpload: PropTypes.func.isRequired,
  onPhotoStockDialogCancel: PropTypes.func,
  id: PropTypes.string,
  elementId: PropTypes.string,
};

AddImagesButton.defaultProps = {
  onPhotoStockDialogCancel: utils.noop,
  id: null,
  elementId: null,
};
