import React, { useContext, useEffect, useState, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { integration, siteLocales, customization, i18next } from '@yola/ws-sdk';
import bowser from 'yola-bowser';
import { SurfaceSelector } from '@yola/ws-ui';
import { ViewportConsumer } from 'yola-editor/src/js/utils/viewport-provider';
// eslint-disable-next-line yola/import/no-webpack-loader-syntax
import BLOCK_LIBRARY_PREVIEWS_CSS from '!!raw-loader!../css/block-library-previews.css';
import BlocksLibraryContext from '../contexts/block-library-context';
import ViewWithStaticAssets from '../components/view-with-static-assets';
import getDependenciesFromCategoryItems from '../helpers/get-dependencies-from-category-items';
import getStaticAssetsCategoryItems from '../helpers/get-static-assets-category-items';
import addInlineStylesToDependencies from '../helpers/add-inline-styles-to-dependencies';
import RequestBlock from '../components/request-block';
import Thumbnail from '../components/thumbnail';
import injectGlobals from '../helpers/inject-globals';
import getContrastAdaptedColors from '../helpers/get-contrast-adapted-colors';
import { FORCED_DESKTOP_WIDTH_FOR_V2 } from '../constants/sizes';
import StickyTooltip from '../components/sticky-tooltip';
import useBlocksListSurfaces from '../hooks/use-blocks-list-surfaces';
import BlocksLibraryContentHeader from '../components/blocks-library-content-header';
import { trackBlocksStyleChanged, trackFilterSelected } from '../helpers/blocks-library-analytics';
import {
  DONATIONS_CATEGORY_NAME,
  PAGE_WRAPPER_CLASSNAME,
  BLOCK_FILTERS,
} from '../constants/common';
import { OVERLAY_OPACITY_ATTRIBUTE } from '../constants/display-options';
import filterBlocksByType from '../helpers/filter-blocks-by-type';
import DonationContent from '../components/donation-content';
import BlocksFilterSelector from '../components/blocks-filter-selector';

const { normalizeLocalizedSource } = siteLocales.helpers;

function BlocksLibraryContentContainer() {
  const MOBILE_BREAKPOINT = 480;
  const SURFACE_EMPTY_VALUE = 'none';
  const {
    activeCategory,
    submittedSearchValue,
    blocksList,
    onClose,
    onCloseContentSectionMobile,
    onBlockSelect,
  } = useContext(BlocksLibraryContext);

  const currentLocale = useSelector(siteLocales.selectors.getCurrentHtmlLocale);
  const requestBlockData = useSelector(integration.selectors.getRequestBlock);
  const [isMounted, setIsMounted] = useState(false);
  const [selectedSurface, setSelectedSurface] = useState(SURFACE_EMPTY_VALUE);
  const [selectedBlockFilter, setSelectedBlockFilter] = useState(BLOCK_FILTERS.none);

  const viewApi = useRef(null);

  const { blocksListSurfacesFiltered, blocksListSurfacesByBlockId } =
    useBlocksListSurfaces(blocksList);

  useEffect(() => {
    /**
     * Workaround for iPad.
     * iPad need some extra time
     * to render wrapper and calculate Box Model
     */
    requestAnimationFrame(() => {
      setIsMounted(true);
    });
  }, []);

  useEffect(() => {
    setSelectedBlockFilter(BLOCK_FILTERS.none);
    setSelectedSurface(SURFACE_EMPTY_VALUE);
    if (viewApi.current && viewApi.current.scrollTo) {
      viewApi.current.scrollTo(0);
    }
  }, [blocksList]);

  const itemDependencies = addInlineStylesToDependencies(
    getDependenciesFromCategoryItems([{ items: blocksList }]),
    BLOCK_LIBRARY_PREVIEWS_CSS
  );
  const itemStaticAssets = getStaticAssetsCategoryItems([{ items: blocksList }]);

  const categoryId = activeCategory ? activeCategory.name : 'search';

  const handleSelectSurface = (value) => {
    setSelectedSurface(value);

    trackBlocksStyleChanged({
      categoryId,
      styleId: value,
    });
  };

  const onBlocksFilterClick = (filterId) => {
    setSelectedBlockFilter(filterId);

    trackFilterSelected({
      categoryId,
      selectedFilter: filterId,
    });
  };

  const getBlockDefaultState = (html) => {
    const helperDiv = document.createElement('div');
    helperDiv.innerHTML = html;
    return helperDiv.querySelector('ws-block');
  };

  const getSurfaceSelectorProps = () => {
    const colorPalette = customization.accessors.getCurrentColorPalette();

    if (!colorPalette) {
      return null;
    }

    // TODO: refactor these props format after `ws-ui` tech debt resolved:
    // https://github.com/yola/ws-ui/issues/384
    const item = {
      options: blocksListSurfacesFiltered,
      onClick: (config, surface) => {
        handleSelectSurface(surface.value);
      },
      value: selectedSurface,
      colors: getContrastAdaptedColors(colorPalette.colors),
      attribute: 'surfaces',
      id: 'surfaces',
    };

    const defaultOption = { label: SURFACE_EMPTY_VALUE, value: SURFACE_EMPTY_VALUE };

    return { item, defaultOption };
  };

  const getModifiedSurfaceValue = (block) => {
    const { id, html } = block;
    const blockSurfaces = blocksListSurfacesByBlockId[id];

    if (!blockSurfaces) return null;

    if (selectedSurface === SURFACE_EMPTY_VALUE) {
      const defaultBlockState = getBlockDefaultState(html);

      if (!defaultBlockState) return null;

      const defaultSurfacesValue = defaultBlockState.getAttribute('surfaces');

      return defaultSurfacesValue;
    }

    // Surfaces could be represented either by a single value, or as a combined one.
    // But according to block library requirements, we need to show just a common
    // single values in the dynamic surfaces selector for a displayed blocks list.
    // At the same time, when the surface is selected and its value should be applied to each block,
    // we need to give a priority to a combined one which starts from a chosen value.
    const matchedSurfaces = blockSurfaces.filter((surfaceItem) =>
      surfaceItem.value.startsWith(selectedSurface)
    );

    if (!matchedSurfaces.length) return null;

    return matchedSurfaces.length > 1
      ? matchedSurfaces.find((surfaceItem) => surfaceItem.value.split(' ').length > 1).value
      : matchedSurfaces[0].value;
  };

  const getModifiedOverlayValue = (block) => {
    const { html } = block;
    const defaultBlockState = getBlockDefaultState(html);

    if (!defaultBlockState) return null;

    const hasOverlay = defaultBlockState.hasAttribute('overlay');

    if (selectedSurface === SURFACE_EMPTY_VALUE) {
      return hasOverlay || null;
    }

    // Currently, some blocks with bg-images and certain surfaces could
    // have `overlay-opacity` attr set without `overlay` attr set itself.
    // But we should manage this during dynamic surfaces switches and set it.
    // https://github.com/yola/ws-editor/pull/2918#issuecomment-842113553
    const hasBackgroundImage = defaultBlockState.hasAttribute('background-image');
    const hasOverlayOpacity = defaultBlockState.hasAttribute(OVERLAY_OPACITY_ATTRIBUTE);

    return (hasBackgroundImage && hasOverlayOpacity) || null;
  };

  const getModifiedBlockAttrs = (block) => {
    const surfaces = getModifiedSurfaceValue(block);
    const overlay = getModifiedOverlayValue(block);

    return {
      ...(surfaces && { surfaces }),
      overlay,
    };
  };

  const handleBlockSelect = (block) => {
    const blockAttrs = getModifiedBlockAttrs(block);

    onBlockSelect({ block, blockAttrs });
  };

  const handleOverlayClick = (event) => {
    if (event.currentTarget === event.target) {
      onClose();
    }
  };

  const handleViewReady = useCallback((api) => {
    viewApi.current = api;
  }, []);

  const getViewWithStaticAssetsCustomization = (viewportWidth) => {
    const offsetHorizontal = viewportWidth <= MOBILE_BREAKPOINT ? 0 : '34px';
    const offsetVertical = viewportWidth <= MOBILE_BREAKPOINT ? '18px' : '34px';

    return {
      globalOffsetTop: offsetVertical,
      globalOffsetBottom: offsetVertical,
      globalOffsetLeft: offsetHorizontal,
      globalOffsetRight: offsetHorizontal,
    };
  };

  const viewWithStaticAssetsForcedWidth = bowser.desktop && FORCED_DESKTOP_WIDTH_FOR_V2;
  const isDonationsCategory = activeCategory?.name === DONATIONS_CATEGORY_NAME;
  const isContentSectionVisible =
    isMounted &&
    ((blocksList && blocksList.length > 0) || submittedSearchValue || isDonationsCategory);
  const surfacesSelectorProps = getSurfaceSelectorProps();
  const isAllBlocksHaveStaticPreviews = blocksList.every((block) => block.preview.source);
  const isBlocksFilterAvailable = blocksList.some((block) => block.isPremium);
  const isSurfacesSelectorVisible =
    !isAllBlocksHaveStaticPreviews && surfacesSelectorProps?.item.options.length > 0;
  const surfaceSelectorKey = activeCategory
    ? `${selectedSurface}-${activeCategory.name}-${submittedSearchValue || ''}`
    : `${selectedSurface}-${submittedSearchValue || ''}`;

  return (
    <ViewportConsumer>
      {({ width }) => (
        <div
          onClick={handleOverlayClick}
          className={classNames('ws-blocks-library__content', {
            'ws-blocks-library__content--active': isContentSectionVisible,
          })}
        >
          {isContentSectionVisible && (
            <div
              className={classNames('ws-blocks-library__content-section', {
                'ws-mobile': width <= MOBILE_BREAKPOINT && isDonationsCategory,
              })}
            >
              {isDonationsCategory ? (
                <DonationContent
                  captions={activeCategory}
                  requestBlockData={requestBlockData}
                  onCloseContentSectionMobile={onCloseContentSectionMobile}
                />
              ) : (
                <ViewWithStaticAssets
                  dependencies={itemDependencies}
                  staticAssets={itemStaticAssets}
                  forcedWidth={viewWithStaticAssetsForcedWidth}
                  customization={getViewWithStaticAssetsCustomization(width)}
                  pageWrapperClassName={classNames({ 'ws-mobile': width <= MOBILE_BREAKPOINT })}
                  onViewReady={handleViewReady}
                >
                  <div className={classNames({ 'ws-mobile': width <= MOBILE_BREAKPOINT })}>
                    <BlocksLibraryContentHeader
                      submittedSearchValue={submittedSearchValue}
                      onCloseContentSectionMobile={onCloseContentSectionMobile}
                      activeCategory={activeCategory}
                      isBlocksListEmpty={blocksList.length === 0}
                    />

                    {(isSurfacesSelectorVisible || isBlocksFilterAvailable) && (
                      <div
                        className={classNames('ws-blocks-library__controls', {
                          'ws-blocks-library__controls--mobile': bowser.mobile,
                        })}
                      >
                        {isSurfacesSelectorVisible && (
                          <SurfaceSelector {...surfacesSelectorProps} key={surfaceSelectorKey} />
                        )}

                        {isBlocksFilterAvailable && (
                          <BlocksFilterSelector
                            selectedBlockFilter={selectedBlockFilter}
                            onBlocksFilterClick={onBlocksFilterClick}
                          />
                        )}
                      </div>
                    )}

                    <div className="ws-blocks-library__list" translate="no">
                      {filterBlocksByType(blocksList, selectedBlockFilter).map((block) => {
                        const marks = [
                          block.new && {
                            label: i18next.t('New'),
                            size: 'medium',
                          },
                          block.isPremium && {
                            label: i18next.t('Premium'),
                            appearance: 'cta',
                            iconGlyph: 'star',
                            size: 'medium',
                          },
                        ].filter(Boolean);

                        return (
                          <StickyTooltip
                            title={i18next.t('Add this block')}
                            customTargetSelector=".ws-thumbnail__container"
                            scrollParentSelector={`.${PAGE_WRAPPER_CLASSNAME}`}
                            key={block.uuid}
                            isDisabled={width <= MOBILE_BREAKPOINT}
                          >
                            <Thumbnail
                              blockId={block.id}
                              title={block.title}
                              variationTitle={block.variationTitle}
                              marks={marks}
                              showThumbnailFooter
                              src={normalizeLocalizedSource(block.preview.source, currentLocale)}
                              html={injectGlobals(block.html)}
                              onClick={() => handleBlockSelect(block)}
                              renderRealSize={bowser.mobile}
                              disabled={block.disabled}
                              blockAttrs={getModifiedBlockAttrs(block)}
                            />
                          </StickyTooltip>
                        );
                      })}
                    </div>

                    {requestBlockData && (
                      <RequestBlock
                        captions={requestBlockData.captions}
                        href={requestBlockData.publicRoadmapUrl}
                      />
                    )}
                  </div>
                </ViewWithStaticAssets>
              )}
            </div>
          )}
        </div>
      )}
    </ViewportConsumer>
  );
}

export default BlocksLibraryContentContainer;
