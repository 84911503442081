import { hooks, site, template } from '@yola/ws-sdk';
import constants from '../constants';
import track from '../track';
import getShiftedBlockNumericPositions from '../helpers/get-shifted-block-numeric-positions';

const handler = ({ blockId, variationId: blockVariationId, currentPosition, previousPosition }) => {
  if (!blockId) return;

  const isBlockWidget = blockId.startsWith('widget-');
  if (isBlockWidget) return;

  const { newPosition, oldPosition } = getShiftedBlockNumericPositions({
    currentPosition,
    previousPosition,
  });

  track(constants.events.BLOCK_SHIFTED, {
    siteId: site.accessors.getSiteId(),
    templateBuildSlug: template.accessors.getBuildSlug(),
    blockId,
    blockVariationId,
    oldPosition,
    newPosition,
    pageId: site.accessors.getActivePageId(),
  });
};

const trackBlockShifted = () => {
  hooks.operations.subscribe(hooks.availableHooks.ON_AFTER_BLOCK_SHIFTED, handler);
};

export default trackBlockShifted;
