import { site } from '@yola/ws-sdk';

const composeFontsAssetsPreviewUrl = (fonts) => {
  if (!fonts) return [];

  return fonts.map((fontsPair) => ({
    ...fontsPair,
    previewUrl: site.helpers.composeAssetUrl(fontsPair.previewUrl),
  }));
};

export default composeFontsAssetsPreviewUrl;
