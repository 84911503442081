import getParentElement from './get-parent-element';
import isEqualBounds from './is-equal-bounds';

function getSameBoundsElements(sourceElement) {
  const sameBoundsElements = [sourceElement];

  let bounds = sourceElement.getBoundingClientRect();
  let element = getParentElement(sourceElement);

  while (element) {
    const nextBounds = element.getBoundingClientRect();

    if (!isEqualBounds(bounds, nextBounds)) {
      break;
    }

    sameBoundsElements.push(element);
    bounds = nextBounds;
    element = getParentElement(element);
  }

  return sameBoundsElements;
}

export default getSameBoundsElements;
