import actionTypes from '../constants/action-types';

const setLoadingStatus = (isLoaded) => ({
  type: actionTypes.SET_LOADING_STATUS,
  payload: {
    isLoaded,
  },
});

export default setLoadingStatus;
