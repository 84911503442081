import { textFormatting } from '@yola/ws-sdk';

const { SUPPORTED_FONT_SIZES } = textFormatting.constants;

const getDefaultFontSizeOptions = () =>
  SUPPORTED_FONT_SIZES.sort().map((fontSize) => ({
    value: `${fontSize}`,
    label: `${fontSize}`,
  }));

export default getDefaultFontSizeOptions;
