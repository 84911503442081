import { anodum } from '@yola/ws-sdk';
import isEqualBounds from './is-equal-bounds';

const getOptionalChild = ({ element, elementBounds, optionalChildren, closestBlock }) => {
  if (!element || !anodum.isElementNode(element) || !optionalChildren) return null;

  const child = optionalChildren.find((childrenElement) =>
    element.matches(childrenElement.querySelector)
  );

  if (child) return child;

  const parent = element.parentNode;

  if (parent && closestBlock.contains(parent)) {
    const parentBounds = parent.getBoundingClientRect();

    if (isEqualBounds(elementBounds, parentBounds)) {
      return getOptionalChild({
        element: parent,
        elementBounds: parentBounds,
        optionalChildren,
        closestBlock,
      });
    }
  }

  return null;
};

export default getOptionalChild;
