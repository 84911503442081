function removeDuplicatedOperations(targetOperations, sourceOperations) {
  return targetOperations.filter((operation) => {
    const isOperationDuplicated = sourceOperations.some(
      (sourceOperation) => sourceOperation[1].join() === operation[1].join()
    );

    return !isOperationDuplicated;
  });
}

export default removeDuplicatedOperations;
