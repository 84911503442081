import { site } from '@yola/ws-sdk';

function getActivePage() {
  const activePageId = site.accessors.getActivePageId();
  const pages = site.accessors.getPages();

  return pages.find((page) => page.id === activePageId);
}

export default getActivePage;
