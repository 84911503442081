import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';
import { designSystem } from '@yola/ws-ui';
import { i18next, dialogs } from '@yola/ws-sdk';
import dialogTypes from '../../dialogs/constants/dialog-types';
import integrationTypes from '../../website-settings/constants/integration-types';
import externalLinks from '../../website-settings/constants/external-links';
import validateRequiredField from '../helpers/validate-required-field';

const { IntegrationItem } = designSystem;
const { COOKIEBOT_INSTRUCTIONS_URL } = externalLinks;
const iconSrc = '/images/integrations/cookiebot.svg';

const CookiebotIntegrationContainer = ({
  form,
  activeTabId,
  captions,
  initialValues,
  initialShowPromptForce,
}) => {
  const dispatch = useDispatch();
  const currentCookiebotId = form[integrationTypes.COOKIEBOT_ID];

  const returnToCurrentTab = (updatedForm = form, showPromptForce = false) => {
    dispatch(
      dialogs.actions.show(dialogTypes.WEBSITE_SETTINGS, {
        form: updatedForm,
        activeTabId,
        showPromptForce: showPromptForce || initialShowPromptForce || !isEqual(form, initialValues),
      })
    );
  };

  const onConnectClick = () => {
    dispatch(
      dialogs.actions.show(dialogTypes.CONNECT_COOKIEBOT_DIALOG, {
        captions,
        onCancel: () => returnToCurrentTab(),
        onSubmit: (cookiebotId) => {
          const updatedForm = {
            ...form,
            [integrationTypes.COOKIEBOT_ID]: cookiebotId,
          };
          returnToCurrentTab(updatedForm, true);
        },
        validate: validateRequiredField,
      })
    );
  };

  const onSettingsClick = () => {
    dispatch(
      dialogs.actions.show(dialogTypes.COOKIEBOT_SETTINGS_DIALOG, {
        captions,
        defaultValue: currentCookiebotId,
        onCancel: () => returnToCurrentTab(),
        onSubmit: (cookiebotId) => {
          const updatedForm = {
            ...form,
            [integrationTypes.COOKIEBOT_ID]: cookiebotId,
          };
          returnToCurrentTab(updatedForm, true);
        },
        validate: validateRequiredField,
      })
    );
  };

  const handleDisconnection = () => {
    dispatch(
      dialogs.actions.show(dialogTypes.ALERT_DIALOG, {
        captions: {
          title: i18next.t('Integration disconnected'),
          description: captions.afterDisconnectInfo,
          descriptionLink: i18next.t('Learn more'),
          submit: i18next.t('Got it'),
        },
        descriptionLinkHref: COOKIEBOT_INSTRUCTIONS_URL,
        onSubmit: () => {
          const updatedForm = {
            ...form,
            [integrationTypes.COOKIEBOT_ID]: '',
          };
          returnToCurrentTab(updatedForm, true);
        },
      })
    );
  };

  const onDisconnectClick = () => {
    dispatch(
      dialogs.actions.show(dialogTypes.CONFIRM_DIALOG, {
        onCancel: () => returnToCurrentTab(),
        onSubmit: handleDisconnection,
        captions: {
          title: i18next.t('Disconnect integration'),
          description: captions.disconnectAlert,
          submit: i18next.t('Submit'),
          cancel: i18next.t('Cancel'),
        },
      })
    );
  };

  if (currentCookiebotId) {
    return (
      <IntegrationItem
        title={captions.name}
        description={captions.description.connected}
        imageSrc={iconSrc}
        primaryButton={{
          iconGlyph: 'settings',
          onClick: onSettingsClick,
        }}
        secondaryButton={{
          appearance: 'danger',
          label: i18next.t('Disconnect'),
          onClick: onDisconnectClick,
        }}
        isSelected
      />
    );
  }

  return (
    <IntegrationItem
      title={captions.name}
      description={captions.description.disconnected}
      imageSrc={iconSrc}
      primaryButton={{
        appearance: 'accent',
        format: 'solid',
        label: i18next.t('Connect'),
        onClick: onConnectClick,
      }}
    />
  );
};

CookiebotIntegrationContainer.propTypes = {
  form: PropTypes.shape().isRequired,
  activeTabId: PropTypes.string.isRequired,
  captions: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.shape({
      connected: PropTypes.string.isRequired,
      disconnected: PropTypes.string.isRequired,
    }).isRequired,
    about: PropTypes.string.isRequired,
    step1: PropTypes.shape().isRequired,
    step2: PropTypes.shape().isRequired,
    step3: PropTypes.shape().isRequired,
    control: PropTypes.shape().isRequired,
    notes: PropTypes.arrayOf(PropTypes.string).isRequired,
    disconnectAlert: PropTypes.string.isRequired,
    afterDisconnectInfo: PropTypes.string.isRequired,
  }).isRequired,
  initialValues: PropTypes.shape().isRequired,
  initialShowPromptForce: PropTypes.bool,
};

CookiebotIntegrationContainer.defaultProps = {
  initialShowPromptForce: false,
};

export default CookiebotIntegrationContainer;
