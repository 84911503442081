import get from 'lodash.get';
import { blocks, site, view } from '@yola/ws-sdk';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import segment from 'src/js/modules/analytics/segment';
import getPageSettingsTraits from 'src/js/modules/analytics/segment/helpers/get-page-settings-traits';
import getParentBlockByElement from 'src/js/modules/blocks/helpers/get-parent-block-by-element';
import adjacentPositions from 'src/js/modules/blocks/constants/adjacent-positions';
import websiteDesignConstant from 'src/js/modules/website-design/constant';

const {
  track,
  trackers: { trackEvent },
  constants: { events, triggerIds },
  helpers: { getDefaultTraits },
} = segment;

const { AFTER_BEGIN, BEFORE_END } = adjacentPositions;

function onDialogDisplayed({ type, modalProps }) {
  switch (type) {
    case dialogTypes.LINK_FORMATTING_DIALOG:
    case dialogTypes.EDIT_LINK_DIALOG:
    case dialogTypes.NAVIGATION_ITEM_SETTINGS_DIALOG:
    case dialogTypes.FOOTER_NAVIGATION_ITEM_SETTING_DIALOG: {
      trackEvent(events.LINK_SETTINGS_DIALOG_DISPLAYED, {
        dialogId: type,
      });
      break;
    }
    case dialogTypes.FOOTER_NAVIGATION_SETTINGS_DIALOG: {
      const element = view.accessors.getLiveElement(modalProps.elementId);
      const blockElement = getParentBlockByElement(element);

      trackEvent(events.LIST_SETTINGS_DIALOG_DISPLAYED, {
        dialogId: type,
        blockId: blocks.accessors.getBlockIdByElement(blockElement),
        blockVariationId: blocks.accessors.getVariationIdByElement(blockElement),
      });
      break;
    }
    case dialogTypes.SOCIAL_LINKS_SETTINGS_DIALOG: {
      const element = view.accessors.getLiveElement(modalProps.elementId);
      const blockElement = getParentBlockByElement(element);

      trackEvent(events.SOCIAL_LINKS_SETTINGS_DIALOG_DISPLAYED, {
        dialogId: type,
        blockId: blocks.accessors.getBlockIdByElement(blockElement),
        blockVariationId: blocks.accessors.getVariationIdByElement(blockElement),
      });
      break;
    }
    case dialogTypes.SOCIAL_LINKS_ITEM_SETTINGS_DIALOG: {
      const element = view.accessors.getLiveElement(modalProps.elementId);
      const blockElement = getParentBlockByElement(element);

      if (modalProps.targetLink) {
        trackEvent(events.SOCIAL_LINKS_EDIT_LINK_DIALOG_DISPLAYED, {
          dialogId: type,
          blockId: blocks.accessors.getBlockIdByElement(blockElement),
          blockVariationId: blocks.accessors.getVariationIdByElement(blockElement),
        });
      } else {
        trackEvent(events.SOCIAL_LINKS_ADD_LINK_DIALOG_DISPLAYED, {
          dialogId: type,
          blockId: blocks.accessors.getBlockIdByElement(blockElement),
          blockVariationId: blocks.accessors.getVariationIdByElement(blockElement),
        });
      }
      break;
    }
    case dialogTypes.ADD_WIDGET: {
      const { adjacentPosition, targetElementId } = modalProps;
      const element =
        adjacentPosition === AFTER_BEGIN || adjacentPosition === BEFORE_END
          ? view.accessors.getLiveElement(targetElementId)
          : view.accessors.getParentLiveElement(targetElementId);
      const blockElement = getParentBlockByElement(element);

      trackEvent(events.WIDGET_SELECTOR_DISPLAYED, {
        targetBlockId: blocks.accessors.getBlockIdByElement(blockElement),
        targetBlockVariationId: blocks.accessors.getVariationIdByElement(blockElement),
      });

      break;
    }
    case dialogTypes.WEBSITE_DESIGN: {
      const { sourceId, activeTabId } = modalProps;

      trackEvent(events.DESIGN_SETTINGS_DIALOG_DISPLAYED, {
        dialogId: type,
        tabId: activeTabId || websiteDesignConstant.DEFAULT_TAB_ID,
        sourceId,
      });

      break;
    }
    case dialogTypes.BLOCK_LAYOUT_DIALOG:
    case dialogTypes.BLOCK_SETTINGS:
    case dialogTypes.SELECT_ICON:
    case dialogTypes.CONTACT_FORM_EDIT_FIELD_DIALOG:
    case dialogTypes.VOTE_FOR_THE_FEATURE:
    case dialogTypes.PUBLISH_INTERRUPTION_DIALOG: {
      break;
    }
    case dialogTypes.REDIRECT_DELETE_DIALOG: {
      trackEvent(events.DELETE_REDIRECT_DIALOG_DISPLAYED);

      break;
    }
    case dialogTypes.REDIRECT_EDIT_DIALOG: {
      if (modalProps.redirect) {
        trackEvent(events.EDIT_REDIRECT_DIALOG_DISPLAYED);
      } else {
        trackEvent(events.ADD_REDIRECT_DIALOG_DISPLAYED);
      }

      break;
    }
    case dialogTypes.MEDIA_SETTINGS_DIALOG: {
      const element = view.accessors.getLiveElement(modalProps.elementId);
      const blockElement = getParentBlockByElement(element);

      trackEvent(events.MEDIA_SETTINGS_DIALOG_DISPLAYED, {
        dialogId: type,
        blockId: blocks.accessors.getBlockIdByElement(blockElement),
        blockVariationId: blocks.accessors.getVariationIdByElement(blockElement),
      });

      break;
    }
    case dialogTypes.CONTACT_FORM_SETTINGS_DIALOG: {
      const element = view.accessors.getLiveElement(modalProps.elementId);
      const blockElement = getParentBlockByElement(element);

      trackEvent(events.CONTACT_FORM_SETTINGS_DIALOG_DISPLAYED, {
        dialogId: type,
        blockId: blocks.accessors.getBlockIdByElement(blockElement),
        blockVariationId: blocks.accessors.getVariationIdByElement(blockElement),
      });

      break;
    }
    case dialogTypes.CONTACT_FORM_SELECT_ITEM_DIALOG: {
      track(events.CONTACT_FORM_SELECT_ITEM_DIALOG_DISPLAYED, {
        dialogId: type,
        ...getDefaultTraits(modalProps.elementId),
      });

      break;
    }
    case dialogTypes.CONTACT_FORM_DELETE_EMAIL_DIALOG: {
      track(events.CONTACT_FORM_DELETE_EMAIL_DIALOG_DISPLAYED, {
        dialogId: type,
        ...getDefaultTraits(modalProps.elementId),
      });

      break;
    }
    case dialogTypes.GALLERY_DIALOG: {
      const element = view.accessors.getLiveElement(modalProps.elementId);
      const blockElement = getParentBlockByElement(element);
      trackEvent(events.GALLERY_SETTINGS_DISPLAYED, {
        dialogId: type,
        blockId: blocks.accessors.getBlockIdByElement(blockElement),
      });

      break;
    }
    case dialogTypes.SLIDER_SETTINGS_DIALOG: {
      const element = view.accessors.getLiveElement(modalProps.elementId);
      const blockElement = getParentBlockByElement(element);
      trackEvent(events.SLIDER_SETTINGS_DISPLAYED, {
        dialogId: type,
        blockId: blocks.accessors.getBlockIdByElement(blockElement),
      });

      break;
    }
    case dialogTypes.DIFM_PROMO_BANNER_DIALOG:
    case dialogTypes.DIFM_PROMO_SERVICES_DIALOG:
    case dialogTypes.DIFM_PROMO_SURVEY_DIALOG:
    case dialogTypes.DIFM_PROMO_PACKAGES_DIALOG: {
      const { triggerId, values } = modalProps;
      const serviceName = get(values, 'serviceName', null);

      trackEvent(events.DIFM_PROMO_DIALOG_DISPLAYED, {
        dialogId: type,
        triggerId,
        selectedService: serviceName,
      });

      break;
    }
    case dialogTypes.DIFM_PROMO_FINISH_DIALOG: {
      const { selectedService, selectedPackage } = modalProps;
      trackEvent(events.DIFM_PROMO_DIALOG_FINISH_DISPLAYED, {
        dialogId: type,
        selectedService,
        selectedPackage,
      });

      break;
    }

    case dialogTypes.BLOCK_REPLACEMENT_DIALOG: {
      const { oldBlock, oldBlockVariationId, newBlock, newBlockVariationId } = modalProps;

      trackEvent(events.BLOCK_REPLACEMENT_DIALOG_DISPLAYED, {
        dialogId: type,
        oldBlock,
        oldBlockVariationId,
        newBlock,
        newBlockVariationId,
      });

      break;
    }

    case dialogTypes.BLOCK_LIMIT_DIALOG: {
      const { blockId } = modalProps;

      trackEvent(events.BLOCK_LIMIT_DIALOG_DISPLAYED, {
        dialogId: type,
        blockId,
      });

      break;
    }

    case dialogTypes.WEBSITE_DESIGN_CUSTOM_PALETTE: {
      const { triggerId, colorPreset } = modalProps;

      trackEvent(events.DESIGN_SETTINGS_COLOR_PRESET_SETTINGS_DISPLAYED, {
        dialogId: type,
        triggerId,
        colorPreset,
      });
      break;
    }

    case dialogTypes.COLOR_SCHEME_SETTINGS_DIALOG: {
      const { colorKey } = modalProps;

      trackEvent(events.DESIGN_SETTINGS_COLOR_SCHEME_SETTINGS_DISPLAYED, {
        dialogId: type,
        colorScheme: colorKey,
      });
      break;
    }

    case dialogTypes.WEBSITE_DESIGN_COLOR_SETTINGS: {
      const { variableId, variableCategory } = modalProps;

      trackEvent(events.DESIGN_SETTINGS_COLOR_SETTINGS_DISPLAYED, {
        dialogId: type,
        variableId,
        variableCategory,
      });
      break;
    }

    case dialogTypes.SITE_WIZARD_INTRO_DIALOG:
    case dialogTypes.SITE_WIZARD_SETUP_DIALOG:
    case dialogTypes.AI_TEXT_ASSISTANT_ERROR_DIALOG: {
      break;
    }

    case dialogTypes.SITE_WIZARD_CONFIRM_EXIT_DIALOG: {
      trackEvent(events.AI_WIZARD_CLOSE_DIALOG_DISPLAYED);
      break;
    }

    case dialogTypes.SITE_WIZARD_PROGRESS_DIALOG:
    case dialogTypes.PAGE_GENERATION_DIALOG: {
      trackEvent(events.AI_WIZARD_GENERATING_DIALOG_DISPLAYED, {
        businessCategory: site.accessors.getBusinessCategory(),
      });
      break;
    }

    case dialogTypes.AI_ERROR_DIALOG: {
      trackEvent(events.AI_WIZARD_ERROR_DIALOG_DISPLAYED, {
        businessCategory: site.accessors.getBusinessCategory(),
      });
      break;
    }

    case dialogTypes.AI_ONBOARDING_DIALOG: {
      const { sourceId } = modalProps;

      trackEvent(events.AI_WIZARD_ONBOARDING_DIALOG_DISPLAYED, {
        sourceId,
        businessCategory: site.accessors.getBusinessCategory(),
      });
      break;
    }

    case dialogTypes.ADD_PAGE_DIALOG:
    case dialogTypes.NEW_PAGE_DIALOG: {
      trackEvent(events.ADD_PAGE_DIALOG_DISPLAYED, {
        triggerId: type === dialogTypes.ADD_PAGE_DIALOG ? triggerIds.AI_PAGE : triggerIds.ADD_PAGE,
      });
      break;
    }

    case dialogTypes.PAGE_SETTINGS: {
      const pageSettings = site.accessors.getPage(modalProps.pageId);

      trackEvent(events.PAGE_SETTINGS_DISPLAYED, {
        dialogId: type,
        ...getPageSettingsTraits(pageSettings),
      });
      break;
    }

    case dialogTypes.WEBSITE_SETTINGS: {
      break;
    }

    default: {
      trackEvent(events.DIALOG_DISPLAYED, {
        dialogId: type,
      });
    }
  }
}

export default onDialogDisplayed;
