import getColorNameByVariable from './get-color-name-by-variable';
import getCircleBracketsContent from '../../utils/get-circle-brackets-content';

const getBlockSurfaceBackground = (blockSurfaceName, paletteVariables) => {
  const blockBackgroundName = `${blockSurfaceName}BackgroundColor`;
  const backgroundColorVariable = paletteVariables[blockBackgroundName];
  const isCSSColorVariable = !!getCircleBracketsContent(backgroundColorVariable);

  const blockBackground = isCSSColorVariable
    ? paletteVariables[getColorNameByVariable(backgroundColorVariable)]
    : backgroundColorVariable;

  return `hsl(${blockBackground})`;
};

export default getBlockSurfaceBackground;
