import actionTypes from '../constants/action-types';

const updateUserPreference = (property, value) => ({
  type: actionTypes.UPDATE_USER_PREFERENCE,
  payload: {
    property,
    value,
  },
});

export default updateUserPreference;
