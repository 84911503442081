import customTools from '../../../custom-tools';
import control from './controls/control';
import constants from './constants';
import socialIconsResizeTool from './tools/social-icons-resize';

const ICON_MAX_SIZE_FALLBACK = 40;
const ICON_MIN_SIZE_FALLBACK = 16;

const extension = {
  slug: constants.common.slug,
  title: 'Social Links Extension',
  description: 'Extension for editing social links',
  defaults: {
    querySelector: 'ws-social-icons ul',
    resizeQuerySelector: 'ws-icon',
    iconMaxSize: ICON_MAX_SIZE_FALLBACK,
    iconMinSize: ICON_MIN_SIZE_FALLBACK,
  },
  ui: {
    control: [control],
  },
  init() {
    customTools.operations.registerTool(socialIconsResizeTool);
  },
};

export default extension;
