import { globals, i18next } from '@yola/ws-sdk';
import bowser from 'yola-bowser';
import contextMenu from 'src/js/modules/context-menu';
import verifiers from '../verifiers';
import helpers from '../helpers';
import {
  CONTEXT_MENU_GROUP_NAME,
  CONVERT_TO_LOCAL_CONTEXT_MENU_ITEM_ID,
  CONVERT_TO_GLOBAL_CONTEXT_MENU_ITEM_ID,
} from '../constants/common';

const isMobile = Boolean(bowser.mobile || bowser.tablet);

export const registerContextMenuNavigationItems = () => {
  const desktopItems = [
    {
      id: CONVERT_TO_LOCAL_CONTEXT_MENU_ITEM_ID,
      caption: i18next.t('Convert to local element'),
      onClick(element) {
        globals.operations.detachGlobal(element);
      },
      matches(element) {
        return (
          verifiers.isNavigationGlobalElement(element) &&
          !globals.verifiers.isElementDetached(element)
        );
      },
    },
    {
      id: CONVERT_TO_GLOBAL_CONTEXT_MENU_ITEM_ID,
      caption: i18next.t('Revert to global element'),
      onClick(element) {
        globals.operations.resetGlobal(element);
      },
      matches(element) {
        return (
          verifiers.isNavigationGlobalElement(element) &&
          globals.verifiers.isElementDetached(element)
        );
      },
    },
  ];

  const mobileItems = [
    {
      id: CONVERT_TO_LOCAL_CONTEXT_MENU_ITEM_ID,
      caption: i18next.t('Convert to local element'),
      onClick() {
        const navigationElement = helpers.getNavigationElement();
        globals.operations.detachGlobal(navigationElement);
        contextMenu.operations.hideContextMenu();
      },
      matches(element) {
        if (verifiers.isHeaderBlock(element)) {
          const navigationElement = helpers.getNavigationElement();
          return navigationElement && !globals.verifiers.isElementDetached(navigationElement);
        }
        return false;
      },
    },
    {
      id: CONVERT_TO_GLOBAL_CONTEXT_MENU_ITEM_ID,
      caption: i18next.t('Revert to global element'),
      onClick() {
        const navigationElement = helpers.getNavigationElement();
        globals.operations.resetGlobal(navigationElement);
        contextMenu.operations.hideContextMenu();
      },
      matches(element) {
        if (verifiers.isHeaderBlock(element)) {
          const navigationElement = helpers.getNavigationElement();
          return navigationElement && globals.verifiers.isElementDetached(navigationElement);
        }
        return false;
      },
    },
  ];

  const itemsGroup = {
    groupName: CONTEXT_MENU_GROUP_NAME,
    matches: verifiers.isNavigationGlobalElement,
    items: isMobile ? mobileItems : desktopItems,
  };

  contextMenu.operations.registerItemsGroup(itemsGroup);
};
