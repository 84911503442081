import { view, dialogs } from '@yola/ws-sdk';
import { Tooltip } from '@yola/ws-ui';
import dnd from 'src/js/modules/drag-n-drop';

const hideTooltips = () => (next) => (action) => {
  switch (action.type) {
    case dialogs.actionTypes.SHOW_DIALOG:
    case view.actionTypes.DUPLICATE_ELEMENT:
    case view.actionTypes.COPY_ELEMENT:
    case view.actionTypes.CUT_ELEMENT:
    case view.actionTypes.DELETE_ELEMENT:
    case dnd.actionTypes.SET_SOURCE_ELEMENT: {
      Tooltip.hide();
      next(action);

      break;
    }
    default:
      next(action);
  }
};

export default hideTooltips;
