import displayOptionTypes from 'src/js/modules/blocks/constants/display-options-type';
import blockSettingsHelpers from 'src/js/modules/blocks/helpers/block-settings';

const {
  createBreakpointsSliderOption,
  createCheckboxOption,
  createOnlineStoreCategoryOption,
  createOnlineStoreCleanUrlSelectorOption,
  createRadioOption,
  createSliderOption,
  createSurfaceOption,
} = blockSettingsHelpers;

const getUpdatedDisplayOptions = ({
  newDisplayOptions,
  oldDisplayOptions: displayOptions,
  blockNode,
  refId,
  colorPalette,
  switchRadioOption,
  switchCheckboxOption,
  switchSurface,
  switchSliderOption,
  afterSwitchSliderOption,
  switchOnlineStoreCategory,
  modifyDisplayOption,
  isOnlineStoreCleanUrlEnabled,
}) =>
  newDisplayOptions
    .reduce((options, item) => {
      switch (item.type) {
        case displayOptionTypes.RADIO:
          options.push(
            createRadioOption({
              item,
              blockNode,
              displayOptions,
              onClick: switchRadioOption,
            })
          );
          break;
        case displayOptionTypes.CHECKBOX:
          options.push(
            createCheckboxOption({
              item,
              blockNode,
              displayOptions,
              onClick: switchCheckboxOption,
            })
          );
          break;
        case displayOptionTypes.SURFACE: {
          options.push(
            createSurfaceOption({
              item,
              colorPalette,
              blockNode,
              elementId: refId,
              displayOptions,
              onClick: switchSurface,
            })
          );
          break;
        }
        case displayOptionTypes.SLIDER:
          options.push(
            createSliderOption({
              item,
              blockNode,
              displayOptions,
              onChange: switchSliderOption,
              onAfterChange: afterSwitchSliderOption,
            })
          );
          break;
        case displayOptionTypes.BREAKPOINTS_SLIDER:
          options.push(
            createBreakpointsSliderOption({
              item,
              displayOptions,
              blockNode,
              onChange: switchSliderOption,
              onAfterChange: afterSwitchSliderOption,
            })
          );
          break;
        case displayOptionTypes.ONLINE_STORE_CATEGORY:
          options.push(
            createOnlineStoreCategoryOption({
              item,
              displayOptions,
              blockNode,
              onChange: switchOnlineStoreCategory,
            })
          );
          break;
        case displayOptionTypes.ONLINE_STORE_CLEAN_URL_SELECTOR:
          options.push(
            createOnlineStoreCleanUrlSelectorOption({
              item,
              isEnabled: isOnlineStoreCleanUrlEnabled,
              displayOptions,
              onChange: modifyDisplayOption,
            })
          );
          break;
        default:
      }

      return options;
    }, [])
    .filter(Boolean);

export default getUpdatedDisplayOptions;
