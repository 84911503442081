import store from 'src/js/store';
import actions from 'src/js/modules/context-menu/actions';

function showContextMenu({
  position,
  groups,
  triggerId,
  calculatePosition,
  title,
  description,
  submitButton,
}) {
  store.dispatch(
    actions.showContextMenu({
      position,
      groups,
      triggerId,
      calculatePosition,
      title,
      description,
      submitButton,
    })
  );
}

export default showContextMenu;
