import { view } from '@yola/ws-sdk';
import getTriggersPositionInfo from './get-triggers-position-info';

const getFloatingTriggersInfoForElement = ({
  elementId,
  scrollPosition,
  pageContainerSelector,
  viewportHeight,
}) => {
  if (!elementId) return null;

  const element = view.accessors.getLiveElement(elementId);

  if (!element) return null;

  const triggersPos = getTriggersPositionInfo(
    element,
    scrollPosition,
    pageContainerSelector,
    viewportHeight
  );

  if (!triggersPos) return null;

  const { topTrigger, bottomTrigger } = triggersPos;

  return {
    topTrigger,
    bottomTrigger,
  };
};

export default getFloatingTriggersInfoForElement;
