import bowser from 'yola-bowser';

function isFullVideoPreloadingSupported() {
  const link = document.createElement('link');
  link.as = 'video';
  return link.as === 'video';
}

function isFirstSegmentPreloadingSupported() {
  const link = document.createElement('link');
  link.as = 'fetch';
  return link.as === 'fetch';
}

const getItemSource = (source) => {
  if (typeof source === 'string') {
    return source;
  }
  const isMobile = bowser.mobile;
  if (isMobile) {
    return source.mobile;
  }
  return source.desktop;
};

const preloadVideoSources = (sources) => {
  const link = document.createElement('link');
  link.rel = 'preload';
  link.type = 'video/mp4';
  if (isFirstSegmentPreloadingSupported()) {
    link.as = 'fetch';
  } else if (isFullVideoPreloadingSupported()) {
    link.as = 'video';
  }

  const linksToAppend = sources
    .filter(({ type }) => type === 'video')
    .map(({ src }) => {
      const clonedLink = link.cloneNode();
      clonedLink.setAttribute('href', getItemSource(src));
      return clonedLink;
    });
  document.head.append(...linksToAppend);
};

export default preloadVideoSources;
