const { sqrt } = Math;

function Vector(x, y) {
  this.x = x;
  this.y = y;

  this.sqrLength = () => this.x * this.x + this.y * this.y;

  this.length = () => sqrt(this.x * this.x + this.y * this.y);

  this.add = (vector) => {
    this.x += vector.x;
    this.y += vector.y;
  };

  this.subtract = (vector) => {
    this.x -= vector.x;
    this.y -= vector.y;
  };

  this.divide = (operand) => {
    this.x /= operand;
    this.y /= operand;
  };

  this.multiply = (operand) => {
    this.x *= operand;
    this.y *= operand;
  };

  this.normalize = () => {
    const sqrLen = this.sqrLength();
    if (sqrLen !== 0) this.multiply(1.0 / sqrt(sqrLen));
  };

  this.getNormalized = () => {
    const sqrLen = this.sqrLength();

    if (sqrLen !== 0) {
      return new Vector((this.x * 1.0) / sqrt(sqrLen), (this.y * 1.0) / sqrt(sqrLen));
    }

    return new Vector(0, 0);
  };

  this.getSubtracted = (firstVector, secondVector) =>
    new Vector(firstVector.x - secondVector.x, firstVector.y - secondVector.y);
}

export default Vector;
