import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactModal from 'react-modal';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { Icon } from '@yola/ws-ui';
import { i18next } from '@yola/ws-sdk';
import dialogs from 'yola-editor/src/js/modules/dialogs';
import user from 'yola-editor/src/js/modules/user';
import DomainPurchaseConfirmation from '../components/domain-purchase-confirmation';
import DomainSearch from '../components/domain-search';
import DomainRegistrationForm from '../components/domain-registration-form';
import actions from '../actions';
import selectors from '../selectors';

function getDefaultFormData(userData, userPreferences) {
  return {
    firstName: userData.name || '',
    lastName: userData.surname || '',
    orgName: '',
    address1: '',
    city: '',
    state: '',
    postalCode: '',
    country: userPreferences.location,
    phone: '',
    fax: '',
    email: userData.email || '',
  };
}

function WlDomainPurchaseDialogContainer() {
  const UI_STATE = {
    CONFIRM: 'confirm',
    FORM: 'form',
    SEARCH: 'search',
  };

  const dispatch = useDispatch();

  // Data from store
  const userData = useSelector(user.selectors.getUserData);
  const {
    domainList,
    isLoading = false,
    selectedDomain,
    terms,
  } = useSelector(selectors.getDomainPurchaseData);
  const userPreferences = useSelector(user.selectors.getUserPreferences);

  // Local state data
  const [formData, setFormData] = useState(getDefaultFormData(userData, userPreferences));
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const handleClose = () => {
    dispatch(dialogs.actions.hide());
    dispatch(actions.clearDomainPurchaseData());
  };

  const onDomainSearch = (query) => {
    dispatch(actions.getDomainList(query));
  };

  const onDomainSelect = (domain) => {
    dispatch(actions.selectDomain(domain));
  };

  const onFormSubmit = (data) => {
    setFormData(data);
    setIsFormSubmitted(true);
  };

  const onDomainPurchaseConfirm = (domain) => {
    dispatch(actions.orderDomain(domain.name, formData));
  };

  const onBackClick = (uiStateName) => {
    switch (uiStateName) {
      case UI_STATE.CONFIRM:
        setIsFormSubmitted(false);
        break;

      case UI_STATE.FORM:
        setIsFormSubmitted(false);
        setFormData(getDefaultFormData(userData, userPreferences));
        dispatch(actions.clearDomainPurchaseData());
        break;

      default:
        handleClose();
    }
  };

  const renderUiState = (uiStateName) => {
    switch (uiStateName) {
      case UI_STATE.FORM:
        return (
          <DomainRegistrationForm
            initialFormData={formData}
            onSubmit={onFormSubmit}
            onCancel={handleClose}
          />
        );

      case UI_STATE.CONFIRM:
        return (
          <DomainPurchaseConfirmation
            terms={terms}
            product={selectedDomain}
            onConfirm={onDomainPurchaseConfirm}
            onCancel={handleClose}
          />
        );

      default:
        return (
          <DomainSearch
            products={domainList}
            isLoading={isLoading}
            onSearch={onDomainSearch}
            onSelect={onDomainSelect}
          />
        );
    }
  };

  const getUiState = () => {
    if (selectedDomain) {
      return isFormSubmitted ? UI_STATE.CONFIRM : UI_STATE.FORM;
    }
    return UI_STATE.SEARCH;
  };

  const uiState = getUiState();

  return (
    <ReactModal
      isOpen
      overlayClassName="dialog-overlay--shaded dialog-overlay--middle-scroll"
      className="product-select-container"
    >
      <button
        type="button"
        className="dialog__overlay-back-button"
        onClick={() => onBackClick(uiState)}
      >
        <Icon glyph="chevron-left" strokeWidth="1.3" />
        {i18next.t('Back')}
      </button>

      <SwitchTransition>
        <CSSTransition key={uiState} classNames="fade-transition" timeout={250}>
          {renderUiState(uiState)}
        </CSSTransition>
      </SwitchTransition>
    </ReactModal>
  );
}

export default WlDomainPurchaseDialogContainer;
