import bowser from 'yola-bowser';
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { utils } from '@yola/ws-sdk';
import SyncyFrame from '../../common/components/syncy-frame';

class View extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.onBeforeLoad = this.onBeforeLoad.bind(this);
    this.onDocumentFetch = this.onDocumentFetch.bind(this);
  }

  onDocumentFetch(frameDocument) {
    const { onDocumentLoad } = this.props;
    onDocumentLoad(frameDocument);
  }

  onBeforeLoad(iframe) {
    const { onStart } = this.props;

    this.activeFrame = iframe;

    onStart(iframe);
  }

  render() {
    const { width, height, document, offsetY, onDocumentReady, onLoad } = this.props;
    const viewClass = classNames('ws-view', {
      'ios-12-and-below': bowser.ios && !bowser.isIpadOS, // ToDo: add workaround to detect iOS 12 and below and change `bowser.isIpadOS`
      ios: bowser.ios,
    });

    return (
      <div className={viewClass} style={{ width, height, paddingTop: offsetY }}>
        <SyncyFrame
          src={document}
          width="100%"
          height="100%"
          onBeforeLoad={this.onBeforeLoad}
          onLoad={onLoad}
          onDocumentFetch={this.onDocumentFetch}
          onDocumentReady={onDocumentReady}
        />
      </div>
    );
  }
}

View.propTypes = {
  document: PropTypes.instanceOf(Document).isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  offsetY: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onLoad: PropTypes.func,
  onStart: PropTypes.func,
  onDocumentLoad: PropTypes.func,
  onDocumentReady: PropTypes.func,
};

View.defaultProps = {
  width: '100%',
  height: '100%',
  offsetY: 0,
  onStart: utils.noop,
  onDocumentLoad: utils.noop,
  onDocumentReady: utils.noop,
  onLoad: utils.noop,
};

export default View;
