import { site } from '@yola/ws-sdk';
import sentry from '../instance';

const setupSentry = () => (next) => (action) => {
  switch (action.type) {
    case site.actionTypes.CONNECT_SITE:
      next(action);
      sentry.setSiteId(site.accessors.getSiteId());
      break;
    default:
      next(action);
  }
};

export default setupSentry;
