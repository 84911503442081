import React from 'react';
import {
  Button,
  Text,
  Panel,
  PanelGroup,
  ButtonGroup,
  Modal,
  InputField,
  Icon,
  Render,
  Tooltip,
  Field,
  CheckboxField,
  Formik,
  designSystem,
} from '@yola/ws-ui';
import PropTypes from 'prop-types';
import { assets, dialogs, i18next, view, bowser, linkEditing } from '@yola/ws-sdk';
import classNames from 'classnames';
import UploadImageContainer from 'src/js/modules/common/containers/upload-image-container';
import ReplaceImageButton from 'src/js/modules/common/components/replace-image-button';
import MediaPreview from 'src/js/modules/common/components/media-preview';
import customUI from 'src/js/modules/custom-ui';
import contextMenu from 'src/js/modules/context-menu';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import getDefaultTraits from 'src/js/modules/analytics/segment/helpers/get-default-traits';
import errorCodes from 'src/js/modules/extensions/common/constants/error-codes';
import imageTypes from 'src/js/modules/extensions/common/constants/image-types';
import linkAttributes from 'src/js/modules/extensions/common/constants/link-attributes';
import getStatusOfChangedData from 'src/js/modules/extensions/registry/ws-image/helpers/get-status-of-changed-data';
import generateImageThumbnailSource from 'src/js/modules/extensions/registry/ws-gallery/helpers/generate-image-thumbnail-source';
import focalPoint from 'src/js/modules/focal-point';
import segment from 'src/js/modules/analytics/segment';
import LinkSettingContainer from 'src/js/modules/common/containers/link-setting-container';
import constants from 'src/js/modules/common/constants';
import processLinkSubmit from 'src/js/modules/common/helpers/process-link-submit';
import mediaTypes from '../constants/media-types';
import mediaAttributes from '../constants/media-attributes';
import trackers from '../trackers';
import getLinkConfig from '../helpers/get-link-config';
import buildLinkElement from '../helpers/build-link-element';
import buildPictureElement from '../helpers/build-picture-element';
import getDataFromMediaNode from '../helpers/get-data-from-media-node';

const {
  constants: { common: focalPointConstants, operationTypes },
  helpers: focalPointHelpers,
} = focalPoint;
const { ActionButton } = designSystem;

const {
  getViewOperation,
  setPosition,
  setScale,
  setCurrentImageUrl,
  setOriginImageSize,
  computeAdjustedScale,
} = focalPointHelpers;
const {
  track,
  constants: { events },
} = segment;

const REGULAR_IMAGE_REPLACE_TRIGGER = 'regular-image-replace-trigger';
const PANEL_HEIGHT = 48;
const DIALOG_WIDTH = 336;
const DIALOG_HEIGHT = 595;

const { linkTypes } = linkEditing.constants;
const {
  trackMediaSettingsDialogSubmitted,
  trackMediaSettingsInputClicked,
  trackMediaSettingsOptionClicked,
} = trackers;

class MediaSettingsDialog extends React.Component {
  constructor(props) {
    super(props);

    const { elementId, getSharedData, mediaType, mediaGalleryItemState } = props;
    const mediaElement = view.accessors.getLiveElement(elementId);
    const linkElement = mediaElement.querySelector('a');
    const pictureElement = mediaElement.querySelector('picture');
    const imageElement = mediaElement.querySelector('img');
    const isGalleryMediaType =
      mediaType === mediaTypes.GALLERY || mediaType === mediaTypes.GALLERY_ITEM;

    const { latestValues } = getSharedData();
    let mediaState = {};

    if (latestValues) {
      mediaState = {
        ...latestValues,
      };
    } else if (mediaGalleryItemState) {
      mediaState = {
        ...mediaGalleryItemState,
      };
    } else {
      const mediaData = getDataFromMediaNode(mediaElement);
      const linkConfig = getLinkConfig(linkElement, mediaElement);
      const shouldUseTitleForAlt = mediaData?.alt === mediaData?.title;

      mediaState = {
        ...mediaData,
        linkConfig,
        shouldUseTitleForAlt,
      };
    }

    this.state = {
      ...mediaState,
    };

    this.allowedLinkTypes = [
      linkTypes.NO_ACTION,
      linkTypes.PAGE,
      linkTypes.PHONE,
      linkTypes.MAIL,
      linkTypes.DOWNLOAD,
      linkTypes.ANCHOR,
      linkTypes.EXTERNAL,
    ];

    if (isGalleryMediaType) {
      this.allowedLinkTypes.splice(1, 0, linkTypes.VIEW_FULL_SIZE_IMAGE);
    }

    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
    this.handleLinkChange = this.handleLinkChange.bind(this);
    this.handleElementUpdates = this.handleElementUpdates.bind(this);
    this.handleCrop = this.handleCrop.bind(this);
    this.onImageReplace = this.onImageReplace.bind(this);
    this.saveSharedData = this.saveSharedData.bind(this);
    this.handleUseTitleForAltChange = this.handleUseTitleForAltChange.bind(this);

    const { offsetX, offsetY } = dialogs.helpers.getPositionByElement(
      mediaElement,
      DIALOG_WIDTH,
      DIALOG_HEIGHT
    );

    this.dialogPosition = { offsetX, offsetY };
    this.formikRef = React.createRef();
    this.scrollableRef = React.createRef();
    this.mediaElement = mediaElement;
    this.linkElement = linkElement;
    this.pictureElement = pictureElement || imageElement;
    this.imageElement = imageElement;
    this.isGalleryMediaType = isGalleryMediaType;

    this.saveSharedData();
  }

  async onImageReplace({ file, source: assetSource, name: assetName, isStockPhoto }) {
    const { elementId, onOpenDialog } = this.props;
    const { isPlaceholder } = this.state;
    let replacedSharedData = {};

    track(events.MEDIA_SETTINGS_NEW_IMAGE_SELECTED, {
      ...getDefaultTraits(elementId),
      dialogId: dialogTypes.MEDIA_SETTINGS_DIALOG,
      source: isStockPhoto ? focalPointConstants.STOCK_PHOTOS : focalPointConstants.FILE_UPLOAD,
    });

    const imageUrl = assets.helpers.addBaseHref(assetSource);
    const imageSize = await assets.helpers.getImageSize(imageUrl);

    assets.operations.makePublishable(assetSource);

    if (this.isGalleryMediaType) {
      const { width, height } = imageSize;
      const imageContainerSize = this.mediaElement.getContainerBounds();
      const thumbnailSource = await generateImageThumbnailSource({
        assetSource,
        assetName,
        assetType: file?.type,
        imageSize,
        imageContainerSize,
        isStockPhoto,
      });

      setCurrentImageUrl(this.mediaElement, assetSource, thumbnailSource);
      setOriginImageSize(this.mediaElement, imageSize);

      replacedSharedData = {
        src: thumbnailSource,
        originSrc: assetSource,
        originWidth: width,
        originHeight: height,
      };
    } else {
      setCurrentImageUrl(this.mediaElement, assetSource);

      replacedSharedData = {
        src: assetSource,
      };
    }

    if (isPlaceholder) {
      this.mediaElement.removeAttribute(mediaAttributes.PLACEHOLDER_ATTRIBUTE);
    }

    // adjusting content scale so small images want be stretching to container width or height
    const contentScale = computeAdjustedScale(this.mediaElement, {
      originalMediaBounds: imageSize,
      scale: focalPointConstants.DEFAULT_SCALE,
    });
    const contentPosition = focalPointConstants.DEFAULT_POSITION_STRING_VALUE;

    setScale(this.mediaElement, contentScale);
    setPosition(this.mediaElement, contentPosition);

    this.saveSharedData({
      ...replacedSharedData,
      contentScale,
      contentPosition,
      isPlaceholder: false,
    });

    onOpenDialog();
  }

  handleCrop() {
    const { elementId, onOpenDialog } = this.props;

    this.saveSharedData();

    customUI.operations.show(customUI.customUiTypes.EDIT_INLINE_IMAGE_FOCAL_POINT, {
      elementId,
      onSubmit: ({ operations }) => {
        const contentPosition = this.mediaElement.getAttribute('content-position');
        const contentScale = this.mediaElement.getAttribute('content-scale');
        const aspectRatio = this.mediaElement.getAttribute('aspect-ratio');

        this.saveSharedData({ contentPosition, contentScale, aspectRatio });

        onOpenDialog({
          operations,
        });
      },
      onCancel: () => onOpenDialog(),
      preserveSharedData: true,
      shouldApplyChangesOnSubmit: false,
    });

    track(events.MEDIA_SETTINGS_CROP_BUTTON_CLICKED, {
      ...getDefaultTraits(elementId),
      dialogId: dialogTypes.MEDIA_SETTINGS_DIALOG,
    });
  }

  handleLinkChange(linkConfig) {
    const galleryData = this.isGalleryMediaType
      ? {
          lightboxDisabled: linkConfig.linkType !== linkTypes.VIEW_FULL_SIZE_IMAGE,
        }
      : {};

    this.setState(
      {
        linkConfig,
        ...galleryData,
      },
      () => Tooltip.rebuild()
    );
  }

  handleSubmitForm() {
    const { featureFlags } = this.props;
    const { linkConfig } = this.state;
    const { nofollow_controls: isNofollowControlsFeatureEnabled } = featureFlags;

    this.saveSharedData();

    processLinkSubmit({
      config: linkConfig,
      isNofollowControlsFeatureEnabled,
    })
      .then((finalLinkConfig) => {
        this.handleElementUpdates({
          ...this.state,
          linkConfig: finalLinkConfig,
        });
      })
      .catch((code) => {
        if (
          code === errorCodes.UPLOAD_FILE_CANCELED ||
          code === errorCodes.UPSELL_INTERRUPTION_CANCELLED
        ) {
          this.handleCancel();
        }
      });
  }

  handleElementUpdates(data) {
    const { elementId, getSharedData, operations: mediaOperations, onSubmit } = this.props;
    const { initialValues } = getSharedData();
    const changedData = getStatusOfChangedData(data, initialValues);
    const {
      isClickActionChanged,
      isSrcChanged,
      isTitleChanged,
      isAltChanged,
      isDescriptionChanged,
      isScaleChanged,
      isPositionChanged,
    } = changedData;
    const operations = [...mediaOperations];
    const isGalleryDataChanged =
      this.isGalleryMediaType && (isTitleChanged || isDescriptionChanged);

    if (this.isGalleryMediaType) {
      const { lightboxDisabled } = data;

      if (lightboxDisabled) {
        operations.push([
          view.operations.setElementAttribute,
          [elementId, mediaAttributes.LIGHTBOX_DISABLE_ATTRIBUTE, ''],
        ]);
      } else {
        operations.push([
          view.operations.removeElementAttribute,
          [elementId, mediaAttributes.LIGHTBOX_DISABLE_ATTRIBUTE],
        ]);
      }

      if (isSrcChanged) {
        const { originSrc, originWidth, originHeight } = data;

        operations.push(
          [view.operations.setElementAttribute, [elementId, 'origin-src', originSrc]],
          [view.operations.setElementAttribute, [elementId, 'origin-width', originWidth]],
          [view.operations.setElementAttribute, [elementId, 'origin-height', originHeight]]
        );
      }
    }

    if (isScaleChanged || isPositionChanged) {
      const { contentScale, contentPosition } = data;

      const setScaleOperation = getViewOperation(operationTypes.SCALE, elementId, contentScale);
      const setPositionOperation = getViewOperation(
        operationTypes.POSITION,
        elementId,
        contentPosition
      );

      operations.push(setScaleOperation, setPositionOperation);
    }

    if (isClickActionChanged || isSrcChanged || isAltChanged || isGalleryDataChanged) {
      const { linkType } = data.linkConfig;
      const { src, alt, title, description } = data;
      let galleryPictureAttrs = {};

      if (linkType === linkTypes.VIEW_FULL_SIZE_IMAGE) {
        galleryPictureAttrs = { title, description };
      }

      const pictureElement = buildPictureElement({ src, alt, ...galleryPictureAttrs });
      let newElement = pictureElement;

      if (linkType !== linkTypes.NO_ACTION && linkType !== linkTypes.VIEW_FULL_SIZE_IMAGE) {
        const linkElement = buildLinkElement(data.linkConfig);
        linkElement.classList.add(linkAttributes.CLICK_ACTION_LINK_CLASS);
        linkElement.appendChild(pictureElement);
        newElement = linkElement;
      }

      const target = this.linkElement || this.pictureElement;
      const targetElementId = view.accessors.getLiveElementId(target);

      operations.push([view.operations.replaceElement, [newElement, targetElementId]]);
    }

    if (initialValues?.isPlaceholder && isSrcChanged) {
      operations.push([
        view.operations.removeElementAttribute,
        [elementId, mediaAttributes.PLACEHOLDER_ATTRIBUTE],
      ]);
    }

    const { linkConfig } = this.state;

    trackMediaSettingsDialogSubmitted(elementId, {
      ...changedData,
      customPath: linkTypes.DOWNLOAD === linkConfig.linkType ? linkConfig.customPath || null : null,
      setDownloadUrlAdjusted:
        linkTypes.DOWNLOAD === linkConfig.linkType ? Boolean(linkConfig.customPath) : null,
    });

    onSubmit([...operations], data);
  }

  saveSharedData(values = {}) {
    const { resolveSharedData, getSharedData } = this.props;
    const { initialValues } = getSharedData();

    const sharedData = {
      latestValues: {
        ...this.state,
        ...this.dialogPosition,
        ...values,
      },
    };

    if (!initialValues) {
      sharedData.initialValues = {
        ...this.state,
      };
    }

    resolveSharedData(sharedData);
  }

  handleCancel() {
    const { elementId, onCancel, getSharedData } = this.props;
    const { initialValues } = getSharedData();
    const changedData = getStatusOfChangedData(this.state, initialValues);
    const wasDataChanged = Object.values(changedData).some(Boolean);

    track(events.MEDIA_SETTINGS_DIALOG_CANCELED, {
      ...getDefaultTraits(elementId),
      dialogId: dialogTypes.MEDIA_SETTINGS_DIALOG,
    });

    onCancel(wasDataChanged);
  }

  handleUseTitleForAltChange(value, setFieldValue) {
    const { elementId } = this.props;
    const { title } = this.state;

    trackMediaSettingsOptionClicked(elementId, value);

    this.setState({
      shouldUseTitleForAlt: value,
      alt: value && title ? title : '',
    });

    if (!value) {
      setFieldValue('alt', '');
    }
  }

  render() {
    const { src, linkConfig, alt, shouldUseTitleForAlt, isPlaceholder } = this.state;
    const { elementId, onOpenDialog, onDeleteImage, showDeleteButton, isExternalLinksDisabled } =
      this.props;
    const { offsetX, offsetY } = this.dialogPosition;

    const isViewFullSizeImageLinkType = linkConfig.linkType === linkTypes.VIEW_FULL_SIZE_IMAGE;

    const isSubmitDisabled =
      (linkConfig.linkType === linkTypes.PATH || linkConfig.linkType === linkTypes.EXTERNAL) &&
      isExternalLinksDisabled;

    const panelClassName = classNames('ws-media-settings-dialog__panel', {
      'ws-media-settings-dialog__panel--mobile': bowser.mobile,
    });
    const replaceImageButtonLabel = isPlaceholder
      ? i18next.t('Add image')
      : i18next.t('Replace image');
    const mediaPreviewUrl = isPlaceholder ? '' : assets.helpers.addBaseHref(src);

    return (
      <Formik ref={this.formikRef} initialValues={this.state} onSubmit={this.handleSubmitForm}>
        {({ handleSubmit, errors, setFieldValue }) => (
          <Modal
            dragHandleSelector=".ws-drag-trigger"
            resizable={false}
            width={DIALOG_WIDTH}
            height={DIALOG_HEIGHT}
            fullscreen={bowser.mobile}
            offsetX={offsetX}
            offsetY={offsetY}
            onDragEnd={(_, { x, y }) => {
              this.dialogPosition.offsetX = x;
              this.dialogPosition.offsetY = y;
            }}
            handleCancel={this.handleCancel}
            handleSubmit={() => {
              if (isSubmitDisabled) {
                this.handleCancel();
                return;
              }

              handleSubmit();
            }}
            className="ws-media-settings-dialog"
            isValid={!Object.values(errors).filter(Boolean).length}
            floatingModalElementSelector=".ws-context-menu"
          >
            <PanelGroup height="100%">
              <Panel
                height={PANEL_HEIGHT}
                align="middle"
                className="ws-drag-trigger ws-modal__content ws-media-settings-dialog__header"
                theme="gray-100"
                corners="squared"
              >
                <Text type="heading-6">{i18next.t('Media settings')}</Text>
              </Panel>
              <Panel
                theme="white"
                corners="squared"
                height="100%"
                className={panelClassName}
                scrollable
                rootRef={this.scrollableRef}
              >
                <div className="ws-modal__content">
                  <div className="ws-modal__block">
                    <Text type="heading-6">{i18next.t('Media')}</Text>
                    <div className="ws-media-settings-dialog__img-wrapper">
                      <MediaPreview url={mediaPreviewUrl} />
                      {!isPlaceholder && (
                        <div
                          className="ws-media-settings-dialog__crop-btn"
                          onClick={this.handleCrop}
                          data-tip={i18next.t('Crop')}
                          data-for="crop"
                        >
                          <Icon glyph="crop" size="24" strokeWidth="1.3" stroke="#ffffff" />
                          <Render if={!bowser.mobile}>
                            <Tooltip id="crop" />
                          </Render>
                        </div>
                      )}
                    </div>
                    <div className="ws-media-settings-dialog__btn-wrapper">
                      <UploadImageContainer
                        onImageUploadEnd={this.onImageReplace}
                        preserveSharedData
                      >
                        {(imageContainerProps) => (
                          <ReplaceImageButton
                            id={REGULAR_IMAGE_REPLACE_TRIGGER}
                            label={replaceImageButtonLabel}
                            elementId={elementId}
                            accept={imageTypes.IMAGE_ACCEPT_TYPE}
                            onPhotoStockDialogCancel={() => onOpenDialog()}
                            {...imageContainerProps}
                            onClick={() => {
                              track(events.MEDIA_SETTINGS_REPLACE_MEDIA_BUTTON_CLICKED, {
                                ...getDefaultTraits(elementId),
                                dialogId: dialogTypes.MEDIA_SETTINGS_DIALOG,
                              });

                              // we need to hide context menu when user scroll inside dialog
                              this.scrollableRef?.current?.addEventListener(
                                'scroll',
                                () => {
                                  contextMenu.operations.hideContextMenu();
                                },
                                { once: true }
                              );

                              this.saveSharedData();
                            }}
                            onWrongFileType={() => {
                              dialogs.operations.show(dialogTypes.FILE_TYPE_ERROR_DIALOG, {
                                onDismiss: () => onOpenDialog(),
                              });
                            }}
                            onTriggerUploadImageClick={() => {
                              track(events.MEDIA_SETTINGS_UPLOAD_FILE_BUTTON_CLICKED, {
                                ...getDefaultTraits(elementId),
                                dialogId: dialogTypes.MEDIA_SETTINGS_DIALOG,
                              });
                            }}
                            onTriggerBrowseStockPhotosClick={() => {
                              track(events.MEDIA_SETTINGS_BROWSE_STOCK_PHOTOS_BUTTON_CLICKED, {
                                ...getDefaultTraits(elementId),
                                dialogId: dialogTypes.MEDIA_SETTINGS_DIALOG,
                              });
                            }}
                          />
                        )}
                      </UploadImageContainer>
                    </div>
                  </div>

                  {isViewFullSizeImageLinkType && (
                    <div className="ws-modal__block">
                      <Text type="heading-6">{i18next.t('Image title')}</Text>
                      <Field
                        component={InputField}
                        name="title"
                        placeholder={i18next.t('Enter title for media')}
                        onChange={(value) => {
                          const trimmedValue = value.trim();

                          this.setState({
                            title: trimmedValue,
                            alt: shouldUseTitleForAlt ? trimmedValue : alt,
                          });
                        }}
                        onFocus={() => {
                          trackMediaSettingsInputClicked(elementId, 'image-title');
                        }}
                      />
                      <Field
                        component={CheckboxField}
                        id="edit-use-title-for-alt-field"
                        name="useTitleForAlt"
                        checked={shouldUseTitleForAlt}
                        onChange={(value) => this.handleUseTitleForAltChange(value, setFieldValue)}
                        className="ws-link-edit-modal__dynamic-field"
                      >
                        <Text type="heading-6">
                          {/* eslint-disable-next-line yola/jsx-a11y/label-has-for */}
                          <label htmlFor="edit-use-title-for-alt-field">
                            {i18next.t('Same title for Alt tag')}
                          </label>
                        </Text>
                      </Field>
                    </div>
                  )}

                  {(!isViewFullSizeImageLinkType || !shouldUseTitleForAlt) && (
                    <div className="ws-modal__block">
                      <Text type="heading-6">{i18next.t('Alt title')}</Text>
                      <Field
                        component={InputField}
                        name="alt"
                        onChange={(value) => {
                          this.setState({ alt: value.trim() });
                        }}
                        onFocus={() => {
                          trackMediaSettingsInputClicked(elementId, 'alt-title');
                        }}
                      />
                      <Text type="annotation" theme="grey">
                        {i18next.t(
                          'Search engines cannot see images but they can read their descriptions. Add image description to boost your search engine visibility.'
                        )}
                      </Text>
                    </div>
                  )}

                  {isViewFullSizeImageLinkType && (
                    <div className="ws-modal__block">
                      <Text type="heading-6">{i18next.t('Description')}</Text>
                      <Field
                        component={InputField}
                        type="textarea"
                        name="description"
                        placeholder={i18next.t('Enter description for media')}
                        onChange={(value) => {
                          this.setState({ description: value.trim() });
                        }}
                        onFocus={() => {
                          trackMediaSettingsInputClicked(elementId, 'description');
                        }}
                        style={{ height: '64px', minHeight: '64px', resize: 'none' }}
                      />
                    </div>
                  )}

                  <LinkSettingContainer
                    initialConfig={linkConfig}
                    allowedLinkTypes={this.allowedLinkTypes}
                    handleChange={this.handleLinkChange}
                    appearance={constants.common.DIALOG_BLOCK_APPEARANCE}
                    captions={{
                      actionTitle: i18next.t('Click action'),
                      actionSubtitle: i18next.t('When user clicks on the image, make this action'),
                    }}
                    sourceDialog={{
                      id: dialogTypes.MEDIA_SETTINGS_DIALOG,
                      props: {
                        elementId,
                      },
                    }}
                  />

                  {showDeleteButton && (
                    <div className="ws-modal__block">
                      <ActionButton
                        label={i18next.t('Delete image')}
                        appearance="danger"
                        onClick={() => {
                          track(events.MEDIA_SETTINGS_DELETE_IMAGE_CLICKED, {
                            ...getDefaultTraits(elementId),
                            dialogId: dialogTypes.MEDIA_SETTINGS_DIALOG,
                          });
                          onDeleteImage();
                        }}
                      />
                    </div>
                  )}
                </div>
              </Panel>
              <Panel
                height={PANEL_HEIGHT}
                align="middle"
                className="ws-media-settings-dialog__footer"
                corners="squared"
              >
                <ButtonGroup stick="top" block>
                  <Button
                    disabled={isSubmitDisabled}
                    stretch="block"
                    size="large"
                    onClick={handleSubmit}
                  >
                    {i18next.t('Save')}
                  </Button>
                  <Button stretch="block" size="large" onMouseDown={this.handleCancel}>
                    {i18next.t('Cancel')}
                  </Button>
                </ButtonGroup>
              </Panel>
            </PanelGroup>
          </Modal>
        )}
      </Formik>
    );
  }
}

MediaSettingsDialog.propTypes = {
  elementId: PropTypes.string.isRequired,
  operations: PropTypes.array,
  featureFlags: PropTypes.shape({
    nofollow_controls: PropTypes.bool,
  }).isRequired,
  getSharedData: PropTypes.func.isRequired,
  resolveSharedData: PropTypes.func.isRequired,
  forceReloadView: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onOpenDialog: PropTypes.func.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  onDeleteImage: PropTypes.func,
  mediaType: PropTypes.string,
  mediaGalleryItemState: PropTypes.object,
  showDeleteButton: PropTypes.bool,
  isExternalLinksDisabled: PropTypes.bool,
};

MediaSettingsDialog.defaultProps = {
  operations: [],
  mediaType: mediaTypes.REGULAR,
  mediaGalleryItemState: null,
  onDeleteImage: Function.prototype,
  showDeleteButton: false,
  isExternalLinksDisabled: false,
};

export default MediaSettingsDialog;
