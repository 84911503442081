import actionTypes from '../constants/action-types';

const elements = (state = [], action) => {
  switch (action.type) {
    case actionTypes.HIDE: {
      const { elements: listOfElements } = action.payload;

      if (!listOfElements || listOfElements.length === 0) return [];

      return state.filter(([element]) => !listOfElements.includes(element));
    }

    case actionTypes.SHOW: {
      const { elements: listOfElements, options } = action.payload;

      const newElements = listOfElements.filter(
        (element) => !state.find(([stateElement]) => stateElement === element)
      );

      const { forceUpdate, ...restOptions } = options;

      if (forceUpdate) {
        return state
          .filter(([element]) => !listOfElements.includes(element))
          .concat(listOfElements.map((element) => [element, restOptions]));
      }

      if (!newElements.length) {
        return state;
      }

      return state.concat(newElements.map((element) => [element, restOptions]));
    }

    case actionTypes.HIDE_HOVERED: {
      const { elements: listOfElements } = action.payload;

      if (!listOfElements || listOfElements.length === 0)
        return state.filter(([, options]) => !options.hovered);

      return state.filter(
        ([element, options]) => !listOfElements.includes(element) && !options.hovered
      );
    }

    default: {
      return state;
    }
  }
};

export default elements;
