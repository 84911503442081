import { transactions } from '@yola/ws-sdk';
import reasons from '../../constants/reasons';
import {
  SURFACES_ATTRIBUTE,
  FULLSCREEN_ATTRIBUTE,
  BACKGROUND_HIDDEN_ATTRIBUTE,
  BORDER_BOTTOM_ATTRIBUTE,
} from '../../constants/attributes';
import getElementById from '../../helpers/get-element-by-id';
import getWhitespaceAutomationInstructions from './helpers/get-whitespace-automation-instructions';

const possibleReasons = [reasons.SET_ELEMENT_ATTRIBUTE, reasons.REMOVE_ELEMENT_ATTRIBUTE];
const possibleAttributes = [
  SURFACES_ATTRIBUTE,
  FULLSCREEN_ATTRIBUTE,
  BACKGROUND_HIDDEN_ATTRIBUTE,
  BORDER_BOTTOM_ATTRIBUTE,
];

const blockSettingsAreUpdated = (config, payload, transaction) => {
  const { reason } = config;
  const { elementId, attr } = payload;
  const { context } = transaction;
  const isStatic = context !== transactions.transactionTypes.LIVE_DOCUMENT;
  const targetBlock = getElementById(elementId, isStatic);

  if (!targetBlock || !possibleReasons.includes(reason) || !possibleAttributes.includes(attr)) {
    return [];
  }

  return [
    ...getWhitespaceAutomationInstructions({
      prevSiblingNode: targetBlock.previousElementSibling,
      targetNode: targetBlock,
      isStatic,
    }),
    ...getWhitespaceAutomationInstructions({
      prevSiblingNode: targetBlock,
      targetNode: targetBlock.nextElementSibling,
      isStatic,
    }),
  ];
};

export default blockSettingsAreUpdated;
