import { view } from '@yola/ws-sdk';
import constants from '../constants/common';

const { FACEBOOK_VIDEO_WIDTH } = constants;

const calcVideoSize = (iframeId) => {
  const iframeElement = view.accessors.getLiveElement(iframeId);
  const { width, height } = iframeElement.getBoundingClientRect();

  const aspectHeight = Math.ceil((height / width) * FACEBOOK_VIDEO_WIDTH);
  return {
    width: FACEBOOK_VIDEO_WIDTH,
    height: aspectHeight,
  };
};

export default calcVideoSize;
