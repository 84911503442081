import yousersjs from '@yola/yousersjs';

import sentry from 'src/js/modules/analytics/sentry';
import config from 'src/js/modules/config';
import user from 'yola-editor/src/js/modules/user';
import site from 'yola-editor/src/js/modules/site';
import { redirectToUrl } from 'yola-editor/src/js/router/utils/redirect-to-url';
import { snakeCaseObjectToCamelCase } from 'yola-editor/src/js/utils/convert-case';

import actionTypes from '../constants/action-types';
import actions from '../actions';

const removeSSOParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.delete('auth_token');
  searchParams.delete('expires');
  searchParams.delete('user_id');
  searchParams.delete('employee_id');
  searchParams.delete('is_template_switched');
  let searchString = searchParams.toString();
  if (searchString.length) {
    searchString = `?${searchString}`;
  }
  const URLWithoutSSOParams = `${window.location.pathname}${searchString}`;
  window.history.replaceState(null, window.document.title, URLWithoutSSOParams);
};

const authenticate = (store) => (next) => (action) => {
  switch (action.type) {
    case actionTypes.AUTHENTICATE: {
      yousersjs
        .authenticate()
        .then(({ body: userData }) => {
          const data = snakeCaseObjectToCamelCase(userData);
          const searchParams = new URLSearchParams(window.location.search);
          store.dispatch(
            site.actions.setTemplateSwitched(searchParams.has('is_template_switched'))
          );

          removeSSOParams();
          store.dispatch(user.actions.setUserData(userData));
          sentry.instance.setUserId(data.id);
          store.dispatch(actions.fetchToken());

          /* WARNING
           ** Do not dispatch any action in this middleware if it's middleware
           ** contains error handling, 'cause request might be cancelled by the browser
           ** and mostly in Firefox before redirect to the template gallery user will
           ** see redundant error dialog and related error event will be fired if user
           ** does not have site yet but tried to get to the editor.
           **
           ** Related dispatched actions were moved from this middleware
           ** to the fetchToken one and only if auth token is generated, 'cause
           ** of possibility of request cancellation which could be triggered in this
           ** middleware at the same time with auth token generation and if token was
           ** not generated user will be redirected to the template gallery and
           ** related requests would be canceled by the browser while trying to
           ** generate auth token for users which do not have the site yet. And the
           ** request cancellation returns "Request aborted" message by HTTP client
           ** axios and in related middleware error handling handle this request
           ** rejection and mostly in Firefox user will see redundant error dialog before
           ** being redirected to the template gallery and ERROR_CAUGHT event with
           ** "Request aborted" error message will be triggered.
           */

          next(action);
        })
        .catch(() => {
          const state = store.getState();
          const isB2C = config.selectors.getIsB2C(state);
          const wsLoginUrl = config.selectors.getWsLoginUrl(state);

          if (!isB2C) {
            redirectToUrl('/errors/403.html');
          } else {
            const searchParams = new URLSearchParams(window.location.search);
            const searchParamsString = searchParams ? searchParams.toString() : '';
            const wsLoginUrlWithParams = new URL(wsLoginUrl);
            const urlToRedirect = `${window.location.origin}?${searchParamsString}`;
            wsLoginUrlWithParams.searchParams.append('next', urlToRedirect);

            redirectToUrl(wsLoginUrlWithParams.toString());
          }

          next(action);
        });
      break;
    }
    default: {
      next(action);
    }
  }
};

export default authenticate;
