import React, { useRef, useCallback, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FocalPoint, Tooltip } from '@yola/ws-ui';
import { utils, view, assets } from '@yola/ws-sdk';
import throttle from 'lodash.throttle';
import ControlPane from 'src/js/modules/control-pane/components/control-pane';
import TriggerEventTracker from 'src/js/modules/utils/trigger-event-tracker';
import segment from 'src/js/modules/analytics/segment';
import customUI from 'src/js/modules/custom-ui';
import navbar from 'src/js/modules/navbar';
import getDefaultTraits from 'src/js/modules/analytics/segment/helpers/get-default-traits';
import withFocalPointContext from '../hoc/with-focal-point-context';
import constants from '../constants/common';
import operationTypes from '../constants/operation-types';
import helpers from '../helpers';
import verifiers from '../verifiers';
import ids from '../constants/triggers-ids';
import MediaBlockComponent from '../components/media-block-component';
import useFocalPoint from '../hooks/use-focal-point';
import { getSettings } from '../settings';
import FocalPointImageResizer from '../components/focal-point-image-resizer';

const {
  track,
  constants: { events },
} = segment;

const { ZOOM_STEP, INLINE_IMAGE, FOCAL_POINT_AREA_TRIGGER, SUBMIT_TRIGGER } = constants;
const { addBaseHref } = assets.helpers;

/* eslint no-shadow: "off" */
/* eslint no-param-reassign: "off" */
/* eslint no-console: "off" */

function InlineImageContainer(props) {
  const {
    elementId,
    onOverlayClick,
    onSubmit,
    onDrag: onDragProp,
    onDragStart: onDragStartProp,
    onDragEnd: onDragEndProp,
    onScale,
    width: viewportWidth,
    height: viewportHeight,
    orientation,
    onCancel,
    shouldApplyChangesOnSubmit,
    preserveSharedData,
  } = props;
  const scaleBeforeChange = useRef(null);
  const previousImagePosition = useRef({ x: 0, y: 0 });
  const settings = useMemo(getSettings, []);
  const navbarHeight = useSelector(navbar.selectors.getHeight);

  const [imageOffset, setImageOffset] = useState({ x: 50, y: 50 });

  // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  const onPositionChange = useCallback(
    throttle(({ x, y }) => {
      setImageOffset({ x, y });
    }, 200),
    []
  );

  const onInitialImageLoad = useCallback(({ imageNode, initialData, imageData, width, height }) => {
    const isGalleryItem = helpers.isGalleryItem(imageNode);
    const initialThumbnailSource = isGalleryItem
      ? helpers.getCurrentImageThumbnailUrl(imageNode)
      : null;
    const initialOriginalGalleryItemSize = helpers.getOriginImageSize(imageNode);
    const { height: initialContainerHeight } = helpers.getContainerBounds(imageNode);

    const imageWidth = isGalleryItem ? initialOriginalGalleryItemSize.width : width;
    const imageHeight = isGalleryItem ? initialOriginalGalleryItemSize.height : height;

    initialData.current.width = imageWidth;
    initialData.current.height = imageHeight;
    initialData.current.thumbnailSource = initialThumbnailSource;
    initialData.current.containerHeight = initialContainerHeight;

    imageData.current.width = imageWidth;
    imageData.current.height = imageHeight;
    imageData.current.thumbnailSource = initialThumbnailSource;

    track(events.FOCAL_POINT_SETTINGS_DISPLAYED, {
      ...getDefaultTraits(elementId),
      imageType: INLINE_IMAGE,
    });
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  const onFitImageSize = useCallback(
    ({ oldZoom, newZoom }) => {
      track(events.FOCAL_POINT_FIT_IN_BUTTON_CLICKED, {
        ...getDefaultTraits(elementId),
        oldZoom,
        newZoom,
        imageType: INLINE_IMAGE,
      });
    },
    [elementId]
  );

  const onDragStart = useCallback(
    (...args) => {
      const [, , { position }] = args;
      previousImagePosition.current = {
        x: position.x,
        y: position.y,
      };
      onDragStartProp(...args);
    },
    [onDragStartProp]
  );

  const onDrag = useCallback(
    (...args) => {
      const [offsetInPercents] = args;

      onDragProp(...args);
      onPositionChange({
        x: offsetInPercents.xInPercents,
        y: offsetInPercents.yInPercents,
      });
    },
    [onDragProp, onPositionChange]
  );

  const onDragEnd = useCallback(
    (...args) => {
      onDragEndProp(...args);
      const [{ x, y }] = args;
      const { x: prevX, y: prevY } = previousImagePosition.current;
      if (prevX !== x || prevY !== y) {
        track(events.FOCAL_POINT_IMAGE_POSITION_ADJUSTED, {
          ...getDefaultTraits(elementId),
          imageType: INLINE_IMAGE,
        });
      }
      previousImagePosition.current = { x, y };
    },
    [elementId, onDragEndProp]
  );

  const {
    position,
    scale,
    zoom,
    imageBounds,
    initialData,
    imageData,
    controlsRef,
    isLoadingImage,
    imageNode,
    imageComputedStyles,
    handleImageDrag,
    handleImageDragStart,
    handleImageDragEnd,
    handleScale,
    handleFitImageSize,
    setImageBounds,
    currentSource,
  } = useFocalPoint({
    elementId,
    onInitialImageLoad,
    onFitImageSize,
    viewportWidth,
    viewportHeight,
    orientation,
    onDrag,
    onDragStart,
    onDragEnd,
    onScale,
    preserveSharedData,
    getInitialScrollPosition: (isHigherThanViewport) => ({
      sideToScroll: isHigherThanViewport ? 'bottom' : 'center',
      offset: isHigherThanViewport ? navbarHeight * 2 : 0,
    }),
  });

  const isGalleryItem = useMemo(() => helpers.isGalleryItem(imageNode), [imageNode]);

  const handleCancel = useCallback(() => {
    if (isLoadingImage.current) return;
    const {
      scale: initialScale,
      position: initialPosition,
      source: initialSource,
      thumbnailSource: initialThumbnailSource,
      width: initialImageWidth,
      height: initialImageHeight,
      ratio: initialRatio,
    } = initialData.current;

    helpers.setRatio(imageNode, initialRatio);
    helpers.setScale(imageNode, initialScale);
    helpers.setPosition(
      imageNode,
      helpers.createCssPositionString(initialPosition.x, initialPosition.y)
    );
    helpers.setCurrentImageUrl(imageNode, initialSource, initialThumbnailSource || initialSource);

    if (isGalleryItem) {
      helpers.setOriginImageSize(imageNode, {
        width: initialImageWidth,
        height: initialImageHeight,
      });
    }

    track(events.FOCAL_POINT_SETTINGS_CANCELLED, {
      ...getDefaultTraits(elementId),
      imageType: INLINE_IMAGE,
    });

    customUI.operations.hide();
    onOverlayClick();
    onCancel();
  }, [elementId, isLoadingImage, onCancel, isGalleryItem, imageNode, initialData, onOverlayClick]);

  const handleSubmit = useCallback(() => {
    const { thumbnailSource } = imageData.current;

    const { ratio: initialRatio } = initialData.current;

    const setSrcOperation = helpers.getViewOperation(operationTypes.SRC, elementId, currentSource);
    const [x, y] = helpers.getPosition(imageNode);
    const setPositionOperation = helpers.getViewOperation(
      operationTypes.POSITION,
      elementId,
      helpers.createCssPositionString(x, y)
    );
    const setScaleOperation = helpers.getViewOperation(operationTypes.SCALE, elementId, scale);

    let operations = [setSrcOperation, setPositionOperation, setScaleOperation];

    if (initialRatio) {
      const currentRatio = imageNode.getAttribute('aspect-ratio');
      if (initialRatio !== currentRatio) {
        operations.push(
          helpers.getViewOperation(operationTypes.ASPECT_RATIO, elementId, currentRatio)
        );
      }
    }

    if (helpers.isGalleryItem(imageNode)) {
      const { width, height } = imageData.current;
      const setOriginImageWidthOperation = helpers.getViewOperation(
        operationTypes.ORIGIN_WIDTH,
        elementId,
        width
      );
      const setOriginImageHeightOperation = helpers.getViewOperation(
        operationTypes.ORIGIN_HEIGHT,
        elementId,
        height
      );
      const replaceSrcWithThumbnailOperation = helpers.getViewOperation(
        operationTypes.THUMBNAIL,
        elementId,
        thumbnailSource || currentSource
      );

      operations = [
        ...operations,
        setOriginImageHeightOperation,
        setOriginImageWidthOperation,
        replaceSrcWithThumbnailOperation,
      ];
    }

    if (thumbnailSource) {
      assets.operations.makePublishable(thumbnailSource);
    }

    if (currentSource !== initialData.current.source) {
      assets.operations.makePublishable(currentSource);
    }

    if (shouldApplyChangesOnSubmit) {
      view.operations.bulkViewOperations(operations);
    }
    onSubmit({ operations });

    customUI.operations.hide();
    Tooltip.hide();
  }, [
    shouldApplyChangesOnSubmit,
    initialData,
    imageData,
    onSubmit,
    elementId,
    imageNode,
    scale,
    currentSource,
  ]);

  const withSubmitAnalyticsEvent = useCallback(
    (cb, triggerId) => () => {
      const { scale: initialScale, position: initialPosition } = initialData.current;
      const { height: containerHeight } = helpers.getContainerBounds(imageNode);
      cb();

      track(events.FOCAL_POINT_SETTINGS_SUBMITTED, {
        ...getDefaultTraits(elementId),
        oldZoom: initialScale,
        newZoom: scale,
        zoomAdjusted: initialScale !== scale,
        positionAdjusted: initialPosition.x !== position.x || initialPosition.y !== position.y,
        imageType: INLINE_IMAGE,
        triggerId,
        oldHeight: initialData.current.containerHeight,
        newHeight: containerHeight,
        containerResized: initialData.current.containerHeight !== containerHeight,
      });
    },
    [elementId, imageNode, position, initialData, scale]
  );

  const getMediaSource = useCallback(() => {
    const thumbnailSource = helpers.getCurrentImageThumbnailUrl(imageNode);

    if (isGalleryItem && thumbnailSource) {
      return addBaseHref(thumbnailSource);
    }

    return addBaseHref(currentSource);
  }, [imageNode, isGalleryItem, currentSource]);

  const saveInitialSliderValueBeforeChange = useCallback(() => {
    // RCSlider component do not guarantee that `onBeforeChange` callback would run once
    // before `onAfterChange`. We've faced a use case when it's called once again between
    // `onChange`  and `onAfterChange` so we get wrong value. That's why we set initial
    // value only if it's empty, and empty it on purpose inside `onAfterChange` callback handler.
    if (scaleBeforeChange.current === null) {
      scaleBeforeChange.current = helpers.getScale(imageNode);
    }
  }, [imageNode]);

  const trackSliderChanges = useCallback(() => {
    if (scaleBeforeChange.current === null) return;

    const scale = helpers.getScale(imageNode);
    if (scale !== scaleBeforeChange.current) {
      track(events.FOCAL_POINT_ZOOM_SLIDER_ADJUSTED, {
        ...getDefaultTraits(elementId),
        oldZoom: scaleBeforeChange.current,
        newZoom: scale,
        imageType: INLINE_IMAGE,
      });
    }

    scaleBeforeChange.current = null;
  }, [imageNode, elementId]);

  const imageZoomSliderControl = helpers.getZoomSliderControl({
    id: ids.REGULAR_IMAGE_ZOOM_SLIDER,
    scale,
    zoom,
    onBeforeChange: saveInitialSliderValueBeforeChange,
    onAfterChange: trackSliderChanges,
    onChange: (...args) => {
      saveInitialSliderValueBeforeChange();
      handleScale(...args);
    },
  });

  const imageSubmitControl = helpers.getSubmitControl({
    id: ids.REGULAR_IMAGE_SUBMIT,
    onClick: withSubmitAnalyticsEvent(handleSubmit, SUBMIT_TRIGGER),
  });

  const fitControl = helpers.getFitControl({
    id: ids.REGULAR_IMAGE_FIT_TRIGGER,
    onClick: handleFitImageSize,
  });

  const imageContainerControls = [
    helpers.getZoomOutControl({
      id: ids.REGULAR_IMAGE_ZOOM_OUT_TRIGGER,
      onClick: () => {
        Tooltip.hide();
        const oldZoom = helpers.getScale(imageNode);
        handleScale(zoom - ZOOM_STEP);
        track(events.FOCAL_POINT_ZOOM_OUT_BUTTON_CLICKED, {
          ...getDefaultTraits(elementId),
          oldZoom,
          newZoom: helpers.getScale(imageNode),
          imageType: INLINE_IMAGE,
        });
      },
    }),
    imageZoomSliderControl,
    helpers.getZoomInControl({
      id: ids.REGULAR_IMAGE_ZOOM_IN_TRIGGER,
      onClick: () => {
        const oldZoom = helpers.getScale(imageNode);
        Tooltip.hide();
        handleScale(zoom + ZOOM_STEP);
        track(events.FOCAL_POINT_ZOOM_IN_BUTTON_CLICKED, {
          ...getDefaultTraits(elementId),
          oldZoom,
          newZoom: helpers.getScale(imageNode),
          imageType: INLINE_IMAGE,
        });
      },
    }),
    fitControl,
    imageSubmitControl,
  ];

  const controlsProps = useMemo(() => {
    if (!imageBounds) return null;
    const hasNoSpaceForControl = imageBounds && imageBounds.top < navbarHeight * 2;
    const direction = !hasNoSpaceForControl ? 'up' : 'none';
    return {
      position: helpers.getControlsPosition(imageBounds, true, imageContainerControls),
      direction,
    };
  }, [imageBounds, imageContainerControls]);

  useEffect(() => {
    const [x, y] = helpers.getPosition(imageNode);
    setImageOffset({ x, y });
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  if (imageNode && imageNode.nodeName.toLowerCase() !== settings.imageContainer) {
    console.warn(
      `You can't use focal point with any other node type except ${settings.imageContainer}`
    );
    return null;
  }

  if (!imageBounds) return null;

  const {
    position: { offsetX, offsetY },
  } = controlsProps;

  const controls = (
    <TriggerEventTracker>
      <ControlPane
        controlPaneRef={controlsRef}
        offsetX={offsetX}
        offsetY={offsetY}
        direction={controlsProps.direction}
        content={imageContainerControls}
        expand="down"
        className="ws-focal-point-controls"
      />
    </TriggerEventTracker>
  );

  return (
    <FocalPoint
      controls={controls}
      onOverlayClick={handleCancel}
      onOverlayDoubleClick={withSubmitAnalyticsEvent(handleSubmit, FOCAL_POINT_AREA_TRIGGER)}
      onDrag={handleImageDrag}
      onDragStart={handleImageDragStart}
      onDragEnd={handleImageDragEnd}
      targetElementBounds={imageBounds}
      elementComputedStyles={imageComputedStyles}
      tooltipText={`X:${imageOffset.x}% Y:${imageOffset.y}%`}
    >
      <MediaBlockComponent
        mediaContainerRef={imageNode}
        targetElementBounds={imageBounds}
        mediaSource={getMediaSource()}
      />
      {verifiers.isResizable(imageNode) && (
        <FocalPointImageResizer
          elementId={elementId}
          onImageResize={setImageBounds}
          targetElementBounds={imageBounds}
        />
      )}
    </FocalPoint>
  );
}

InlineImageContainer.propTypes = {
  elementId: PropTypes.string.isRequired,
  onOverlayClick: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onDrag: PropTypes.func,
  onDragStart: PropTypes.func,
  onDragEnd: PropTypes.func,
  onScale: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
  orientation: PropTypes.string,
  preserveSharedData: PropTypes.bool,
  shouldApplyChangesOnSubmit: PropTypes.bool,
};

InlineImageContainer.defaultProps = {
  onOverlayClick: utils.noop,
  onDrag: utils.noop,
  onSubmit: utils.noop,
  onCancel: utils.noop,
  onDragStart: utils.noop,
  onDragEnd: utils.noop,
  onScale: utils.noop,
  width: null,
  height: null,
  orientation: null,
  shouldApplyChangesOnSubmit: true,
  preserveSharedData: false,
};

export default withFocalPointContext(InlineImageContainer);
