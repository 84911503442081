function copyAttributes(sourceElement, elementToCopy) {
  if (elementToCopy.hasAttributes()) {
    const attrs = [...elementToCopy.attributes];

    attrs.forEach((attr) => {
      if (attr.name !== 'content-untouched') {
        sourceElement.setAttribute(attr.name, attr.value);
      }
    });
  }

  return sourceElement;
}

export default copyAttributes;
