import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { i18next, dialogs, site, integration, anodum, template } from '@yola/ws-sdk';
import withFeatureFlags from 'yola-editor/src/js/modules/feature-flags/hoc/with-feature-flags';
import upsell from 'src/js/modules/upsell';
import segment from 'src/js/modules/analytics/segment';
import dialogTypes from '../../dialogs/constants/dialog-types';
import PageSettings from '../components/page-settings';
import getKeywordString from '../helpers/get-keywords-string';
import getKeywordsList from '../helpers/get-keywords-list';
import scrollToElementInContainer from '../../scroller/helpers/scroll-to-element-in-container';
import constants from '../constants';
import trimLocaleSuffixFromPath from '../helpers/trim-locale-suffix-from-path';
import appendLocaleSuffixToPath from '../helpers/append-locale-suffix-to-path';
import getLanguageCodesOptions from '../helpers/get-language-codes-options';
import getPathPrefix from '../helpers/get-path-prefix';
import getLanguageCodes from '../../website-settings/helpers/get-language-codes';

const { common, tabIdentifiers } = constants;

const {
  trackers: { trackDialogActiveTabChanged, trackUpgradeTriggerClicked },
  constants: { triggerIds },
} = segment;

const getPageSettingsCaptions = () => ({
  prompt: {
    title: i18next.t('Save changes?'),
    description: i18next.t('Do you want to save changes you made in page settings?'),
    discard: i18next.t('Discard'),
    submit: i18next.t('Save'),
    cancel: i18next.t('Cancel'),
  },
  apply: i18next.t('Submit'),
  cancel: i18next.t('Cancel'),
  pageSettings: i18next.t('Page Settings'),
  general: {
    name: i18next.t('General'),
    title: {
      name: i18next.t('Page name'),
      validationError: i18next.t('Page name must contain at list one letter or one number'),
      emptyError: i18next.t('Page name should not be empty'),
      homePageError: i18next.t('Page name cannot contain only language code (en, fr, de, etc)'),
      maxLengthError: i18next.t('Max length should be 70 characters.'),
      description: i18next.t(
        'Title the page. Optimal title length is approximately 55 characters.'
      ),
    },
    displayInNavigation: {
      label: i18next.t('Display in navigation'),
    },
    passwordProtectedPages: {
      title: i18next.t('Page access control'),
      subtitle: i18next.t('Manage the access to the content of your page'),
      openedOptionLabel: i18next.t('Open for everyone'),
      protectedOptionLabel: i18next.t('Password protected'),
      fieldTitle: i18next.t('Password'),
      fieldPlaceholder: i18next.t('Enter password for this page'),
      fieldSubtitle: i18next.t(
        'Set a password to ensure that only authorized visitors can view this page. The settings apply after publishing the website.'
      ),
      emptyPasswordError: i18next.t('Page password should not be empty'),
    },
  },
  seoSettings: {
    name: i18next.t('SEO'),
    searchPreview: {
      heading: i18next.t('Search results preview'),
      title: i18next.t('Your page title'),
      description: i18next.t(
        'Search engines will create a default description for your page, but to override that description, type your own below. This is what viewers see in search results, so use it to show what makes your page special!'
      ),
    },
    description: {
      name: i18next.t('Meta description'),
      error: i18next.t('Max description length should be 160 characters.'),
      description: i18next.t('Describe the page. Max description length 160 characters.'),
    },
    preventIndexing: {
      name: i18next.t('Hide this page from search results'),
      description: i18next.t(
        'Prevent your page from being indexed by search engines to hide it in Google search'
      ),
    },
    keywords: {
      name: i18next.t('Meta keywords'),
      description: i18next.t(
        'Add page meta keywords. Optimal description length is 155 to 300 characters.'
      ),
    },
    title: {
      name: i18next.t('Title tag'),
      error: i18next.t('Max length should be 70 characters.'),
      description: i18next.t(
        'This is your page title on Google. Add your page name, site name & what you do in 55-70 chars'
      ),
    },
    sameAsPageName: {
      label: i18next.t('Same as page name'),
    },
    displayBusinessName: {
      label: i18next.t('Include your Business Name in the page title'),
      description: i18next.t(
        'To use the Business Name in the page title, you need to add your business name in {pathToBusinessInfo}',
        {
          pathToBusinessInfo: '',
        }
      ),
      settingsTitle: i18next.t('Settings'),
      businessInfoTitle: i18next.t('Business Info'),
    },
  },
  customCode: {
    name: i18next.t('Custom code'),
    description: i18next.t(
      'Tweak the site by adding custom code, and integration snippets. The code added here will only apply to this page and will appear after the site-wide custom code.'
    ),
    headCode: {
      name: i18next.t('Inside <head> tag'),
      description: i18next.t(
        'Custom code and scripts added to this section will appear in the preview mode and on the published site.'
      ),
      upgradeText: {
        description: i18next.t(
          'Gain access to custom code editing by upgrading your subscription!'
        ),
        upgrade: i18next.t('Upgrade now'),
      },
    },
    headerCode: {
      name: i18next.t('After <body> tag'),
      description: i18next.t(
        'Custom code and scripts added to this section will appear in the preview mode and on the published site.'
      ),
      upgradeText: {
        description: i18next.t(
          'Gain access to custom code editing by upgrading your subscription!'
        ),
        upgrade: i18next.t('Upgrade now'),
      },
    },
    footerCode: {
      name: i18next.t('Before </body> tag'),
      description: i18next.t(
        'Custom code and scripts added to this section will appear in the preview mode and on the published site.'
      ),
      upgradeText: {
        description: i18next.t(
          'Gain access to custom code editing by upgrading your subscription!'
        ),
        upgrade: i18next.t('Upgrade now'),
      },
    },
    languageCode: {
      name: i18next.t('Language Code'),
      description: i18next.t(
        'Set page language code to allow browsers, translation apps, and other tools to perform language-sensitive tasks.'
      ),
    },
  },
  path: {
    name: i18next.t('Publish path'),
    emptyError: i18next.t('Publish path must not be empty'),
    startingSymbolError: i18next.t('Publish path should start with ‘/‘ symbol'),
    validationError: i18next.t(
      'Invalid path. Use alphanumeric characters, ‘-’, ‘_’, ‘.’ and ‘/’ symbols only'
    ),
    homePageError: i18next.t('Home page path is not allowed as publish path'),
    notUniqueError: i18next.t('Invalid path. A page with this path already exists'),
    reservedError: i18next.t(
      'Invalid path. The path you specified is reserved by our system and can not be used'
    ),
    pathsAmountError: i18next.t('Publish path cannot contain more than 3 levels'),
  },
});

class PageSettingsContainer extends React.Component {
  constructor(props, state) {
    super(props, state);
    const {
      pageSettings: { path, sameAsPageName, password },
    } = this.props;
    const initSameAsPageName = sameAsPageName !== undefined ? sameAsPageName : true;

    this.isMultilingualSite = site.verifiers.isMultilingualSite();

    this.state = {
      activeTabId: this.getActiveTabId(),
      pathValue: this.isMultilingualSite ? trimLocaleSuffixFromPath(path) : path,
      isTitleSameAsPageName: initSameAsPageName,
      passwordProtection: Boolean(password),
      passwordVisibility: false,
    };

    this.formRef = createRef();

    this.languageCodeOptions = getLanguageCodesOptions();
    this.languageCodes = getLanguageCodes();
    this.onSubmit = this.onSubmit.bind(this);
    this.onCustomCodeUpgrade = this.onCustomCodeUpgrade.bind(this);
    this.bindSubmitForm = this.bindSubmitForm.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
    this.setActiveTabId = this.setActiveTabId.bind(this);
    this.handlePathChange = this.handlePathChange.bind(this);
    this.onChangeSameAsPageName = this.onChangeSameAsPageName.bind(this);
    this.handleIsTitleSameAsPageName = this.handleIsTitleSameAsPageName.bind(this);
    this.getPageTitle = this.getPageTitle.bind(this);
    this.getPageName = this.getPageName.bind(this);
    this.onChangePageName = this.onChangePageName.bind(this);
    this.enablePasswordProtection = this.enablePasswordProtection.bind(this);
    this.disablePasswordProtection = this.disablePasswordProtection.bind(this);
    this.switchPasswordVisibility = this.switchPasswordVisibility.bind(this);
  }

  onSubmit(settings) {
    const { onSubmit, onClose, modalProps, pageSettings } = this.props;
    const { pageId } = modalProps;
    const { pathValue, passwordProtection } = this.state;
    const isDisplayInNavAvailable = template.verifiers.isVariableGlobalsAvailable('navigation');
    // Keep old `displayInNavigation` value if checkbox is not available on UI
    const displayInNavigation = isDisplayInNavAvailable
      ? settings.displayInNavigation
      : pageSettings.displayInNavigation;
    const password = passwordProtection && settings.password ? settings.password : null;

    const preparedSettings = {
      ...settings,
      password,
      displayInNavigation,
      path: this.isMultilingualSite
        ? appendLocaleSuffixToPath(pathValue, pageSettings.locale)
        : pathValue,
      title: settings.title,
      name: settings.name,
      keywords: getKeywordString(settings.keywords),
    };

    onSubmit(pageId, preparedSettings);
    onClose();
  }

  onCustomCodeUpgrade() {
    const { customCode } = this.props;

    trackUpgradeTriggerClicked(triggerIds.PAGE_SETTINGS);

    return customCode.onUpgrade().catch(() => {
      // eslint-disable-next-line no-console
      console.log('Upgrade flow was canceled');
    });
  }

  onTabChange(tabId) {
    this.setActiveTabId(tabId);
    trackDialogActiveTabChanged({ dialogId: dialogTypes.PAGE_MANAGER, tabId });
  }

  setActiveTabId(tabId) {
    return new Promise((resolve) => {
      this.setState(
        {
          activeTabId: tabId,
        },
        resolve
      );
    });
  }

  getActiveTabId() {
    const { modalProps } = this.props;
    return (modalProps && modalProps.activeTabId) || tabIdentifiers.GENERAL;
  }

  handlePathChange(value) {
    this.setState({
      pathValue: value,
    });
  }

  switchPasswordVisibility() {
    this.setState((state) => ({
      ...state,
      passwordVisibility: !state.passwordVisibility,
    }));
  }

  enablePasswordProtection() {
    const { passwordProtectedPages } = this.props;

    if (!upsell.verifiers.isFeatureAvailable(upsell.features.PASSWORD_PROTECTED_PAGES)) {
      passwordProtectedPages
        .onUpgrade()
        .then(() => {
          this.setState({
            passwordProtection: true,
          });
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          console.log('Upgrade flow was canceled');
        });

      return;
    }

    this.setState({
      passwordProtection: true,
    });
  }

  disablePasswordProtection() {
    this.setState({
      passwordProtection: false,
    });
  }

  onChangePageName(name) {
    const { setFieldValue } = this.formRef.current;
    const { isTitleSameAsPageName } = this.state;

    if (isTitleSameAsPageName) setFieldValue('title', name);
  }

  onChangeSameAsPageName(checked) {
    this.handleIsTitleSameAsPageName(checked);

    const { state: formikState, setFieldValue, setFieldTouched } = this.formRef.current;
    const { values, errors } = formikState;

    if (checked) {
      setFieldValue('title', values.name);
      delete errors.title;
      setFieldTouched('title', false);
    } else {
      setFieldTouched('title', true);
    }
  }

  handleIsTitleSameAsPageName(checkState = null) {
    if (checkState !== null) {
      this.setState({
        isTitleSameAsPageName: checkState,
      });
    } else {
      const {
        pageSettings: { name, title },
      } = this.props;

      if (name !== undefined && name !== title) {
        this.setState({
          isTitleSameAsPageName: false,
        });
      } else {
        this.setState({
          isTitleSameAsPageName: true,
        });
      }
    }
  }

  getPageTitle() {
    const { pageSettings } = this.props;
    const { isTitleSameAsPageName } = this.state;
    const pageTitle =
      isTitleSameAsPageName && !!pageSettings.name ? pageSettings.name : pageSettings.title;
    return pageTitle;
  }

  getPageName() {
    const { pageSettings } = this.props;
    const pageName = !pageSettings.name ? pageSettings.title : pageSettings.name;
    return pageName;
  }

  getFormData() {
    const { pageSettings, siteSettings, featureFlags } = this.props;
    const { business_info_in_website_settings: isBusinessInfoInWebsiteSettingsEnabled } =
      featureFlags;
    const pageName = this.getPageName();
    const pageTitle = this.getPageTitle();
    const path = this.isMultilingualSite
      ? trimLocaleSuffixFromPath(pageSettings.path)
      : pageSettings.path;

    const settings = {
      title: pageTitle,
      name: pageName,
      description: pageSettings.description,
      keywords: getKeywordsList(pageSettings.keywords),
      headCode: pageSettings.headCode,
      headerCode: pageSettings.headerCode,
      footerCode: pageSettings.footerCode,
      password: pageSettings.password || '',
      displayInNavigation: pageSettings.displayInNavigation,
      path,
      htmlLocale: pageSettings.htmlLocale,
      preventIndexing: pageSettings.preventIndexing || false,
      ...(isBusinessInfoInWebsiteSettingsEnabled && {
        displayBusinessName: Boolean(pageSettings.displayBusinessName && siteSettings.businessName),
      }),
    };

    return settings;
  }

  handleKeyDown(e) {
    if (e.key === common.ENTER_KEY) {
      if (anodum.isOneOfTags(e.target, ['input', 'textarea'])) return;
      this.submitSettings();
    }
  }

  bindSubmitForm(submitForm) {
    this.submitSettings = submitForm;
  }

  render() {
    const {
      onCancel,
      userLimits,
      pageSettings,
      customCode,
      modalProps,
      siteURL,
      featureFlags,
      siteSettings,
    } = this.props;
    const {
      password_protected_pages: passwordProtectedPages,
      business_info_in_website_settings: isBusinessInfoInWebsiteSettingsEnabled,
    } = featureFlags;
    const { pageId } = modalProps;
    const { businessName } = siteSettings;
    const captions = getPageSettingsCaptions();

    const codeFieldsDisabled = !userLimits.customCode.available;
    const isDisplayInNavAvailable = template.verifiers.isVariableGlobalsAvailable('navigation');
    if (customCode) {
      const customCodeCaptions = {
        ...customCode.captions,
      };
      captions.customCode.headCode.upgradeText = customCodeCaptions;
      captions.customCode.headerCode.upgradeText = customCodeCaptions;
      captions.customCode.footerCode.upgradeText = customCodeCaptions;
    }

    const { activeTabId, isTitleSameAsPageName, passwordProtection, passwordVisibility } =
      this.state;

    return (
      <PageSettings
        captions={captions}
        formData={this.getFormData()}
        isSameAsPageName={isTitleSameAsPageName}
        onChangeSameAsPageName={this.onChangeSameAsPageName}
        onChangePageName={this.onChangePageName}
        languageCodeOptions={this.languageCodeOptions}
        languageCodes={this.languageCodes}
        isHomePage={site.verifiers.isHomePage(pageSettings.path, this.languageCodes)}
        pathPrefix={getPathPrefix(siteURL, pageSettings.locale)}
        businessName={businessName}
        isHomePagePath={site.verifiers.isHomePage}
        onChangePath={this.handlePathChange}
        pageId={pageId}
        isDisplayInNavAvailable={isDisplayInNavAvailable}
        headCodeDisabled={codeFieldsDisabled}
        headerCodeDisabled={codeFieldsDisabled}
        footerCodeDisabled={codeFieldsDisabled}
        activeTabId={activeTabId}
        onHeadCodeUpgradeClick={this.onCustomCodeUpgrade}
        onHeaderCodeUpgradeClick={this.onCustomCodeUpgrade}
        onFooterCodeUpgradeClick={this.onCustomCodeUpgrade}
        scrollElementIntoView={scrollToElementInContainer}
        onSubmit={this.onSubmit}
        onCancel={onCancel}
        onTabChange={this.onTabChange}
        setActiveTabId={this.setActiveTabId}
        bindSubmitForm={this.bindSubmitForm}
        onKeyDown={this.handleKeyDown}
        isBusinessInfoInWebsiteSettingsEnabled={isBusinessInfoInWebsiteSettingsEnabled}
        passwordProtectedPagesAvailable={Boolean(passwordProtectedPages)}
        isProtectedPagesPlanFeatureAvailable={upsell.verifiers.isFeatureAvailable(
          upsell.features.PASSWORD_PROTECTED_PAGES
        )}
        passwordProtection={passwordProtection}
        passwordVisibility={passwordVisibility}
        enablePasswordProtection={this.enablePasswordProtection}
        disablePasswordProtection={this.disablePasswordProtection}
        switchPasswordVisibility={this.switchPasswordVisibility}
        formRef={this.formRef}
      />
    );
  }
}

PageSettingsContainer.defaultProps = {
  siteURL: null,
};

PageSettingsContainer.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  userLimits: PropTypes.shape({
    customCode: PropTypes.shape({
      available: PropTypes.bool,
    }),
  }).isRequired,
  modalProps: PropTypes.shape({
    pageId: PropTypes.string,
    activeTabId: PropTypes.string,
  }).isRequired,
  pageSettings: PropTypes.shape({
    name: PropTypes.string,
    password: PropTypes.string,
    title: PropTypes.string,
    sameAsPageName: PropTypes.bool,
    displayInNavigation: PropTypes.bool,
    description: PropTypes.string,
    keywords: PropTypes.string,
    footerCode: PropTypes.string,
    headCode: PropTypes.string,
    headerCode: PropTypes.string,
    path: PropTypes.string,
    htmlLocale: PropTypes.string,
    locale: PropTypes.string,
    preventIndexing: PropTypes.bool,
    displayBusinessName: PropTypes.bool,
  }).isRequired,
  siteSettings: PropTypes.shape({
    businessName: PropTypes.string,
  }).isRequired,
  customCode: PropTypes.shape({
    onUpgrade: PropTypes.func.isRequired,
    captions: PropTypes.object,
  }).isRequired,
  passwordProtectedPages: PropTypes.shape({
    onUpgrade: PropTypes.func.isRequired,
    captions: PropTypes.object,
  }).isRequired,
  siteURL: PropTypes.string,
  // eslint-disable-next-line yola/react/no-unused-prop-types
  onDialogMainAction: PropTypes.func.isRequired,
  // eslint-disable-next-line yola/react/no-unused-prop-types
  onDialogCancel: PropTypes.func.isRequired,
  featureFlags: PropTypes.shape({
    password_protected_pages: PropTypes.bool,
    business_info_in_website_settings: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = (state) => {
  const { modalProps } = dialogs.selectors.getDialog(state);
  const userLimits = integration.selectors.getLimits(state);
  const pageSettings = site.selectors.getPage(state, modalProps.pageId);
  const siteSettings = site.selectors.getSettings(state);
  const { customCode, passwordProtectedPages } = integration.selectors.getUpsells(state);
  const siteURL = integration.selectors.getSiteURL(state);

  return {
    modalProps,
    userLimits,
    pageSettings,
    siteSettings,
    customCode,
    siteURL,
    passwordProtectedPages,
  };
};

const mapDispatchToProps = (dispatch, { onDialogMainAction, onDialogCancel }) => ({
  onSubmit(pageId, settings) {
    onDialogMainAction(settings);
    dispatch(site.actions.updatePageSettings(pageId, settings));
  },
  onCancel() {
    onDialogCancel();
    dispatch(dialogs.actions.show(dialogTypes.PAGE_MANAGER));
  },
  onClose() {
    dispatch(dialogs.actions.hide());
  },
});

export default withFeatureFlags(['password_protected_pages', 'business_info_in_website_settings'])(
  connect(mapStateToProps, mapDispatchToProps)(PageSettingsContainer)
);
