import { integration } from '@yola/ws-sdk';
import store from 'src/js/store';
import config from 'src/js/modules/config';

const getCookiebotInstructionsUrl = () => {
  const state = store.getState();
  const helpCenterConfig = config.selectors.getHelpCenterConfig(state);
  const userData = integration.accessors.getUserData();
  const isWhiteLabel = userData && userData.is_white_label;

  return isWhiteLabel
    ? helpCenterConfig.cookiebot_instructions_wl_url
    : helpCenterConfig.cookiebot_instructions_url;
};

export default getCookiebotInstructionsUrl;
