import { globals, saving, template } from '@yola/ws-sdk';
import store from '../../../store';
import constants from '../constants/common';

const setLanguageSelector = async (markup, mainLocale) => {
  const state = store.getState();
  const { globalId } = template.selectors.getVariableValueByPath(state, 'languageSelector[0]');
  const languageSelector = document.createElement(constants.LANGUAGE_SELECTOR_TAG);

  languageSelector.appendChild(markup);
  languageSelector.dataset.mainLang = mainLocale;

  // It is necessary to avoid changing the saving status
  saving.utils.initialData.setGlobalHtml(globalId, languageSelector.outerHTML);
  store.dispatch(globals.actions.setGlobalHTML(globalId, languageSelector.outerHTML));
  await store.dispatch(saving.thunks.saveGlobal(globalId));
};

export default setLanguageSelector;
