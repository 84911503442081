const flatten = (items) =>
  items.reduce(
    (acc, item) => ({
      css: item.css ? acc.css.concat(item.css) : acc.css,
      js: item.js ? acc.js.concat(item.js) : acc.js,
    }),
    {
      css: [],
      js: [],
    }
  );

const getDependenciesFromCategoryItems = (categoryItems) =>
  categoryItems
    .map(({ items }) => items.map(({ dependencies }) => dependencies))
    .reduce(
      (acc, item) => {
        const { css, js } = acc;
        const { css: itemCSS, js: itemJS } = flatten(item);
        return {
          css: css.concat(itemCSS),
          js: js.concat(itemJS),
        };
      },
      {
        css: [],
        js: [],
      }
    );

export default getDependenciesFromCategoryItems;
