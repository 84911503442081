import React from 'react';
import { designSystem } from '@yola/ws-ui';
import { i18next, site, template } from '@yola/ws-sdk';
import segment from 'src/js/modules/analytics/segment';
import onlinestore from '../../onlinestore';

const { EditorHeader } = designSystem;

const {
  trackAsync,
  constants: { events },
} = segment;

function OnlineStoreTrigger() {
  const onOnlineStoreTriggerClick = () => {
    trackAsync(events.ONLINE_STORE_TRIGGER_CLICKED, {
      siteId: site.accessors.getSiteId(),
      templateBuildSlug: template.accessors.getBuildSlug(),
    }).then(() => onlinestore.helpers.redirectToStore());
  };

  return (
    <EditorHeader.NavItem onMouseDown={onOnlineStoreTriggerClick} label={i18next.t('Store')} />
  );
}

export default OnlineStoreTrigger;
