import React from 'react';
import PropTypes from 'prop-types';
import { i18next } from '@yola/ws-sdk';
import { AlertDialog } from '@yola/ws-ui';
import dialogs from 'yola-editor/src/js/modules/dialogs';
import useCustomerSupport from 'yola-editor/src/js/modules/common/hooks/use-customer-support';

const ErrorDialog = (props) => {
  const { onDialogMainAction, onDialogCancel, captions } = props;
  const { onContactSupport, isCustomerSupportAvailable } = useCustomerSupport();

  const dialogCaptions = {
    title: captions.title,
    description: `${captions.description}. ${i18next.t('Please, try again later {contactSupport}', {
      contactSupport: isCustomerSupportAvailable ? i18next.t('or contact customer support.') : '',
    })}`,
    dismiss: i18next.t('Dismiss'),
    ...(isCustomerSupportAvailable && { submit: i18next.t('Contact support') }),
  };

  const handleSubmit = () => {
    onDialogMainAction();
    onContactSupport();
    dialogs.operations.hide();
  };

  const handleDismiss = () => {
    onDialogCancel();
    dialogs.operations.hide();
  };

  return (
    <AlertDialog
      onDismiss={handleDismiss}
      onSubmit={handleSubmit}
      captions={dialogCaptions}
      glyph="alert-circle"
      height="auto"
      {...(isCustomerSupportAvailable && { onSubmit: handleSubmit })}
    />
  );
};

ErrorDialog.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

export default ErrorDialog;
