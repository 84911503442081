import { utils } from '@yola/ws-sdk';

const createEmailValidator = (errorMessage) => (value) => {
  if (errorMessage && value && !utils.validation.isEmail(value)) {
    return errorMessage;
  }

  return null;
};

export default createEmailValidator;
