import React from 'react';
import bowser from 'yola-bowser';
import { i18next } from '@yola/ws-sdk';
import { designSystem } from '@yola/ws-ui';
import constants from '../../constants/common';
import config from '../../constants/config';
import pasteImageFromClipboard from '../../helpers/paste-image-from-clipboard';

const { Icon, Paragraph } = designSystem;

const pasteImage = (additionalProperties = {}) => ({
  id: constants.PASTE_IMAGE,
  get caption() {
    return i18next.t('Paste from clipboard');
  },
  get contentRight() {
    if (bowser.macOS) {
      return (
        <>
          <Icon glyph="command" size={16} type="light" />
          <Paragraph appearance="medium-emphasis">V</Paragraph>
        </>
      );
    }

    return <Paragraph appearance="medium-emphasis">Ctrl + V</Paragraph>;
  },
  async onClick(element) {
    await pasteImageFromClipboard(element, config);
  },
  ...additionalProperties,
});

export default pasteImage;
