import { textFormatting } from '@yola/ws-sdk';
import isSelectionWrappedIn from './is-selection-wrapped-in';

const {
  wrappersTypes: { decoration },
} = textFormatting.constants;

const isDecorationSelected = () => isSelectionWrappedIn(decoration.tagName);

export default isDecorationSelected;
