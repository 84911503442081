import React from 'react';
import PropTypes from 'prop-types';
import { FloatingAction } from '@yola/ws-ui';
import { i18next } from '@yola/ws-sdk';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';

const WidgetFloatingTrigger = (props) => {
  const {
    targetElementId,
    adjacentPosition,
    position,
    triggerShift,
    direction,
    onMouseEnter,
    onMouseLeave,
    showDialog,
    indent,
  } = props;
  const { x: offsetX, y: offsetY, stick } = position;

  const showWidgetsDialog = () => {
    showDialog(dialogTypes.ADD_WIDGET, { targetElementId, adjacentPosition });
  };

  const triggerOffsetY = offsetY + indent;
  const triggerOffsetX = offsetX + triggerShift;

  // If the targetElementId get changed
  // Floating Action should be replaced by the new one
  const key = targetElementId;

  return (
    <div
      className="ws-floating-trigger-wrapper"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <FloatingAction
        key={`${key}-action`}
        icon="plus"
        iconSize={18}
        offsetX={triggerOffsetX}
        offsetY={triggerOffsetY}
        direction={direction}
        stick={stick}
        tooltip={i18next.t('Add widget')}
        onClick={showWidgetsDialog}
        buttonColorType="primary"
        buttonSizeType="extra-small"
      />
    </div>
  );
};

WidgetFloatingTrigger.propTypes = {
  targetElementId: PropTypes.string.isRequired,
  adjacentPosition: PropTypes.string.isRequired,
  position: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    stick: PropTypes.string,
  }),
  triggerShift: PropTypes.number,
  direction: PropTypes.string,
  showDialog: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  indent: PropTypes.number,
};

WidgetFloatingTrigger.defaultProps = {
  position: {},
  triggerShift: 0,
  direction: null,
  onMouseEnter: Function.prototype,
  onMouseLeave: Function.prototype,
  indent: 0,
};

export default WidgetFloatingTrigger;
