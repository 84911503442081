import tabIdentifiers from './tab-identifiers';

export default {
  SCRIPT_REGEXP: /<script[^>]*>[^<]*<\/script>/,
  GLOBAL_HEADER_CODE: 'header-code',
  GLOBAL_FOOTER_CODE: 'footer-code',
  WEBSITE_DESIGN_MODAL_WIDTH: 360,
  WEBSITE_DESIGN_MODAL_HEIGHT: 548,
  USER_FONTS_SCRIPT_TAG_ID: 'ws-user-font-pairs',
  DEFAULT_TAB_ID: tabIdentifiers.COLORS,
  MAX_OPACITY_VALUE: 1,
};
