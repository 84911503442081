import { textFormatting, hdrm } from '@yola/ws-sdk';
import unwrapSelectionImitation from './unwrap-selection-imitation';
import selectionSimulation from '../constants/selection-simulation';

const { SELECTION_WRAPPER_ID, SELECTION_CLASSNAME, SELECTION_TAG_NAME, SELECTION_STYLE } =
  selectionSimulation;
const {
  helpers: {
    wrapperMap: { registerWrapper, removeWrapper },
  },
  operations: { applyWrapper, undoWrapper },
  verifiers: { isWrapperRegistered },
} = textFormatting;

export const applySelectionWrapper = () => {
  if (!isWrapperRegistered(SELECTION_WRAPPER_ID)) {
    registerWrapper(SELECTION_WRAPPER_ID, SELECTION_CLASSNAME, SELECTION_TAG_NAME, {
      style: SELECTION_STYLE,
    });
  }

  try {
    applyWrapper(SELECTION_WRAPPER_ID);
    // eslint-disable-next-line no-empty
  } catch (e) {}
};

export const undoSelectionWrapper = (element) => {
  if (!isWrapperRegistered(SELECTION_WRAPPER_ID)) {
    return;
  }

  const selectedElements = element.querySelectorAll(`.${SELECTION_CLASSNAME}`);
  selectedElements.forEach((selectedElement) => {
    hdrm.injections.bindings.mutation.remove(selectedElement);
  });

  try {
    undoWrapper(SELECTION_WRAPPER_ID);
    // eslint-disable-next-line no-empty
  } catch (e) {}

  // for some cases when target elements are not fully inside selection or element is not focused
  const stillSelectedElements = element.querySelectorAll(`.${SELECTION_CLASSNAME}`);
  if (stillSelectedElements.length) {
    unwrapSelectionImitation(element);
  }

  removeWrapper(SELECTION_WRAPPER_ID);
};
