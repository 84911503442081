import { selectors } from '@yola/subscription-manager-js';
import store from 'src/js/store';
import getFeatureLimits from './get-feature-limits';

function getCurrentPackageFeatureLimits() {
  const state = store.getState();
  const targetPackage = selectors.getCurrentPackage(state);

  return getFeatureLimits(targetPackage);
}

export default getCurrentPackageFeatureLimits;
