import yousersjs from '@yola/yousersjs';
import errorModule from 'yola-editor/src/js/modules/error';
import actions from '../actions';
import actionTypes from '../constants/action-types';
import { snakeCaseObjectToCamelCase } from '../../../utils/convert-case';

const userFeaturesLoader = (store) => (next) => (action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_FEATURES: {
      yousersjs
        .features()
        .get()
        .then(({ body }) => {
          const data = snakeCaseObjectToCamelCase(body);
          store.dispatch(actions.setUserFeatures(data));

          next(action);
        })
        .catch((error) => {
          store.dispatch(
            errorModule.actions.setCriticalError({
              error,
              logSentry: true,
            })
          );

          next(action);
        });

      break;
    }

    default:
      next(action);
  }
};

export default userFeaturesLoader;
