import React from 'react';

const LinkIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="144" height="90" viewBox="0 0 144 90" fill="none">
    <path
      d="M98 45C98 59.3594 86.3594 71 72 71C57.6406 71 46 59.3594 46 45C46 30.6406 57.6406 19 72 19C86.3594 19 98 30.6406 98 45Z"
      fill="#DADDE0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M72.9908 34.0404C74.3242 32.7195 76.1159 31.9836 77.9814 32.0003C79.8468 32.0169 81.6255 32.7845 82.936 34.1287C84.2456 35.4719 84.9837 37.2832 84.9997 39.1711C85.0157 41.059 84.3085 42.8829 83.0221 44.2489L83.0089 44.2629L79.4235 47.9401C78.7071 48.6751 77.8431 49.2454 76.8894 49.6103C75.9356 49.9752 74.9153 50.1258 73.8984 50.0511C72.8814 49.9764 71.8933 49.6783 71.0011 49.1783C70.109 48.6784 69.3344 47.9889 68.7285 47.1581C68.369 46.6652 68.4772 45.9742 68.9701 45.6148C69.463 45.2553 70.1539 45.3635 70.5134 45.8564C70.934 46.4332 71.4691 46.9083 72.081 47.2511C72.6928 47.5939 73.3677 47.797 74.0602 47.8479C74.7526 47.8987 75.4482 47.7964 76.1 47.547C76.752 47.2975 77.3462 46.9064 77.8414 46.3982L81.42 42.7278C82.3068 41.7826 82.8019 40.5129 82.7907 39.1898C82.7794 37.8636 82.2606 36.6004 81.3543 35.6709C80.4488 34.7422 79.2299 34.2206 77.9617 34.2093C76.696 34.1981 75.471 34.6958 74.5509 35.6045L72.5011 37.6947C72.074 38.1302 71.3746 38.137 70.9391 37.7099C70.5035 37.2828 70.4967 36.5834 70.9238 36.1479L72.9908 34.0404Z"
      fill="#646C75"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.1106 40.3897C68.0644 40.0248 69.0847 39.8742 70.1016 39.9489C71.1186 40.0236 72.1067 40.3217 72.9989 40.8217C73.891 41.3216 74.6656 42.0111 75.2715 42.8419C75.631 43.3348 75.5228 44.0258 75.0299 44.3852C74.537 44.7447 73.8461 44.6365 73.4866 44.1436C73.066 43.5668 72.5309 43.0917 71.919 42.7489C71.3072 42.4061 70.6323 42.203 69.9398 42.1521C69.2474 42.1013 68.5518 42.2036 67.9 42.453C67.248 42.7025 66.6538 43.0936 66.1586 43.6018L62.58 47.2722C61.6932 48.2174 61.1981 49.4871 61.2093 50.8102C61.2206 52.1364 61.7394 53.3996 62.6457 54.3291C63.5512 55.2578 64.7701 55.7794 66.0383 55.7907C67.3035 55.8019 68.528 55.3046 69.448 54.3966L71.4847 52.3076C71.9106 51.8708 72.6099 51.862 73.0467 52.2879C73.4835 52.7137 73.4923 53.413 73.0665 53.8498L71.0093 55.9598C69.6759 57.2806 67.8841 58.0164 66.0186 57.9997C64.1532 57.9831 62.3745 57.2155 61.064 55.8713C59.7544 54.5281 59.0163 52.7168 59.0003 50.8289C58.9843 48.941 59.6915 47.1171 60.9779 45.7511L60.9911 45.7371L64.5765 42.0599C65.2928 41.3249 66.1569 40.7546 67.1106 40.3897Z"
      fill="#646C75"
    />
  </svg>
);

export default LinkIcon;
