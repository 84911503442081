import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { dialogs, i18next, serviceClient, bowser, site, i18n } from '@yola/ws-sdk';
import FreeStockImages from 'src/js/modules/common/components/free-stock-images';
import FreeStockContext from 'src/js/modules/common/components/free-stock-context';
import withRetry from 'src/js/modules/utils/with-retry';
import trackers from 'src/js/modules/analytics/segment/trackers';
import useFeatureFlags from 'yola-editor/src/js/modules/feature-flags/hooks/use-feature-flags';
import dialogsTypes from '../../constants/dialog-types';
import generateUnsplashBackLink from './helpers/generate-unsplash-back-link';
import getRangeItemsIds from './helpers/get-range-items-ids';
import getSearchTip from './helpers/get-search-tip';

const { PHOTO_LOAD_ERROR } = dialogsTypes;
const { trackPhotoStockSearchPerformed } = trackers;
const INITIAL_LOAD_COUNT = 30;

const getPhotosItemsWithSelected = (photos, itemsToReset, isSelected) =>
  photos.map((item) => {
    if (itemsToReset.includes(item.id)) {
      return {
        ...item,
        isSelected: isSelected === undefined ? !item.isSelected : isSelected,
      };
    }
    return item;
  });

const getComputedData = (data) =>
  data.map(({ id, urls, width, height, altDescription, user, links }) => ({
    id,
    src: bowser.mobile ? urls.small : urls.regular,
    width,
    height,
    alt: altDescription,
    user: {
      name: user.name,
      link: generateUnsplashBackLink(user.links.html),
    },
    unsplash: {
      link: generateUnsplashBackLink(links.html),
    },
    isSelected: false,
    isLoaded: false,
  }));

const filterOutDuplicatedPhotos = (photos) => {
  const uniquePhotos = new Map(photos.map((item) => [item.id, item]));

  return Array.from(uniquePhotos.values());
};

function PhotoStockDialog({
  onDialogMainAction,
  onDialogCancel,
  multiple,
  preserveSharedData,
  blockId,
}) {
  const [featureFlags] = useFeatureFlags(['ai_unsplash_search_query']);
  const { ai_unsplash_search_query: isAiUnsplashSearchQueryEnabled } = featureFlags;

  const { id: pageId, unsplashSearchQuery = '' } = site.accessors.getActivePage();
  const defaultSearchQuery = isAiUnsplashSearchQueryEnabled ? unsplashSearchQuery : '';
  const { unsplashListPhotos, unsplashSearchPhotos } = serviceClient.get();

  const [photos, setPhotos] = useState([]);
  const [shiftedItems, setShiftedItems] = useState([]);
  const [lastBareClickedItemId, setLastBareClickedItemId] = useState(null);
  const [isLoading, setLoadStatus] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchQuery, setSearchQuery] = useState(defaultSearchQuery);
  const [isNotFound, setIsNotFound] = useState(false);
  const [isPageLimit, setIsPageLimit] = useState(false);

  const dispatch = useDispatch();

  const photoList = useRef();

  const currentLocale = useSelector(i18n.accessors.getLocale);

  const searchTip = useMemo(() => getSearchTip(currentLocale), [currentLocale]);

  const handleCancel = useCallback(() => {
    onDialogCancel();
  }, [onDialogCancel]);

  useEffect(() => {
    photoList.current =
      isAiUnsplashSearchQueryEnabled && unsplashSearchQuery
        ? unsplashSearchPhotos({ per_page: INITIAL_LOAD_COUNT, query: unsplashSearchQuery })
        : unsplashListPhotos({ per_page: INITIAL_LOAD_COUNT });
    const getPhotoListWithRetry = withRetry(photoList.current.next.bind(photoList.current));

    setLoadStatus(true);
    getPhotoListWithRetry()
      .then(({ data }) => {
        setLoadStatus(false);
        setPhotos(getComputedData(Array.isArray(data) ? data : data.results));
      })
      .catch((error) => {
        console.error(error);
        dialogs.operations.show(PHOTO_LOAD_ERROR, {
          preserveSharedData,
          isApiRateExceeded: Boolean(error && error.code === 429),
        });
      });
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, [preserveSharedData]);

  const handleSearch = useCallback(
    (value = '') => {
      const trimmedValue = value.trim();

      setIsSearching(true);
      setLoadStatus(true);
      setSearchQuery(trimmedValue);
      setPhotos([]);
      setIsPageLimit(false);
      setIsNotFound(false);

      photoList.current = trimmedValue
        ? unsplashSearchPhotos({
            per_page: INITIAL_LOAD_COUNT,
            query: trimmedValue,
          })
        : unsplashListPhotos({ per_page: INITIAL_LOAD_COUNT });

      const getPhotoListWithRetry = withRetry(photoList.current.next.bind(photoList.current));

      getPhotoListWithRetry()
        .then(({ data }) => {
          setIsSearching(false);
          setLoadStatus(false);

          const computedData = getComputedData(Array.isArray(data) ? data : data.results);

          if (!computedData.length) setIsNotFound(true);

          setPhotos(computedData);
          trackPhotoStockSearchPerformed({
            targetElementId: blockId,
            searchTerm: trimmedValue,
            searchResults: computedData.length,
          });
        })
        .catch((error) => {
          setIsSearching(false);

          console.error(error);

          dialogs.operations.show(PHOTO_LOAD_ERROR, {
            isApiRateExceeded: Boolean(error && error.code === 429),
          });
        });
    },
    [blockId, unsplashSearchPhotos, unsplashListPhotos, setIsNotFound]
  );

  const handleSubmit = useCallback(() => {
    const selectedPhotos = photos.filter((photo) => photo.isSelected);

    if (!selectedPhotos.length) {
      onDialogCancel();
      return;
    }

    if (isAiUnsplashSearchQueryEnabled)
      dispatch(site.actions.setPageUnsplashSearchQuery(pageId, searchQuery));

    onDialogMainAction({ selectedPhotos });
  }, [
    onDialogCancel,
    photos,
    onDialogMainAction,
    isAiUnsplashSearchQueryEnabled,
    dispatch,
    pageId,
    searchQuery,
  ]);

  const onSelect = useCallback(
    (id, e) => {
      if (multiple) {
        const { shiftKey } = e;
        let selected;

        if (shiftKey && lastBareClickedItemId) {
          const photosUnshifted = getPhotosItemsWithSelected(photos, shiftedItems, false);
          const newSelectedItems = getRangeItemsIds(photos, lastBareClickedItemId, id);
          setShiftedItems(newSelectedItems);

          selected = getPhotosItemsWithSelected(photosUnshifted, newSelectedItems, true);
        } else {
          selected = getPhotosItemsWithSelected(photos, [id]);
          setLastBareClickedItemId(id);
          setShiftedItems([]);
        }

        setPhotos(selected);
      } else {
        const selected = photos.map((item) => ({
          ...item,
          isSelected: item.id === id ? !item.isSelected : false,
        }));
        setPhotos(selected);
      }
    },
    [multiple, lastBareClickedItemId, shiftedItems, photos]
  );

  const loadNextPage = useCallback(() => {
    if (isLoading || isPageLimit) return;
    setLoadStatus(true);
    const getNextPagetWithRetry = withRetry(photoList.current.next.bind(photoList.current));

    getNextPagetWithRetry()
      .then(({ data }) => {
        setLoadStatus(false);
        const { results } = data;
        if ((results && !results.length) || (Array.isArray(data) && !data.length)) {
          setIsPageLimit(true);
        }

        setPhotos(filterOutDuplicatedPhotos([...photos, ...getComputedData(results || data)]));
      })
      .catch((error) => {
        console.error(error);
        setLoadStatus(false);
        setIsPageLimit(true);
      });
  }, [isPageLimit, photos, isLoading]);

  return (
    <FreeStockContext.Provider value={{ onClick: onSelect, isSearchInProgress: isSearching }}>
      <FreeStockImages
        captions={{
          searchTip,
          searchPlaceholder: i18next.t('Search'),
          title: i18next.t('Free stock photos library'),
          notFound: i18next.t('Sorry, but we haven\'t found anything for "{searchQuery}"', {
            searchQuery,
          }),
          notFoundDescription: i18next.t(
            'Please try a different search term or check on of the popular photos bellow'
          ),
          submit: i18next.t('Submit'),
          cancel: i18next.t('Cancel'),
        }}
        items={photos}
        searchedValue={searchQuery}
        areLoading={isLoading}
        isNotFound={isNotFound}
        onSearch={handleSearch}
        onLoading={loadNextPage}
        onSubmit={handleSubmit}
        onClose={handleCancel}
        blockId={blockId}
      />
    </FreeStockContext.Provider>
  );
}

PhotoStockDialog.propTypes = {
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  blockId: PropTypes.string.isRequired,
  preserveSharedData: PropTypes.bool,
};

PhotoStockDialog.defaultProps = {
  preserveSharedData: false,
  multiple: false,
};

export default PhotoStockDialog;
