import { configure } from '@yola/webapp-plugins';
import config from 'src/js/modules/config';
import i18n from 'yola-editor/src/js/modules/i18n';

const configuration = (store) => (next) => (action) => {
  switch (action.type) {
    case config.actionTypes.SET_SERVICE_CONFIG: {
      next(action);
      const state = store.getState();
      const commonConfig = config.selectors.getCommonConfig(state);
      configure({ commonConfig });
      break;
    }
    case i18n.actionTypes.SET_LOCALE: {
      next(action);
      const { locale } = action.payload;
      configure({ locale });
      break;
    }
    default: {
      next(action);
    }
  }
};

export default configuration;
