import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import getLanguageCodes from 'src/js/modules/website-settings/helpers/get-language-codes';
import constants from 'src/js/modules/page-settings/constants';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import useFeatureFlags from 'yola-editor/src/js/modules/feature-flags/hooks/use-feature-flags';
import { dialogs, i18next, bowser, transliteration, template } from '@yola/ws-sdk';
import NewPageDialog from '../components/new-page-dialog';
import fieldNames from '../constants/field-names';

const { common } = constants;
const { ADD_PAGE_DIALOG_MOBILE_WIDTH, ADD_PAGE_DIALOG_WIDTH } = common;

function NewPageDialogContainer({
  pageTitle,
  pageTemplate,
  captions,
  isDisplayInNavAvailable,
  onCancel,
  onSubmit,
  onDialogMainAction,
  onDialogCancel,
}) {
  const errors = {
    invalidFormat: i18next.t('Page name must contain at list one letter or one number'),
    required: i18next.t('Page name can not be empty'),
    homePageError: i18next.t('Page name cannot contain only language code (en, fr, de, etc)'),
  };
  const dispatch = useDispatch();
  const [featureFlags] = useFeatureFlags(['contact_forms_disabled']);
  const { contact_forms_disabled: isContactFormsDisabled } = featureFlags;
  const pageTemplates = useSelector(template.selectors.getPageTemplates);
  const pageTemplatesOptions =
    pageTemplates &&
    pageTemplates
      .filter(({ id }) => {
        if (!isContactFormsDisabled) return true;

        return id !== 'contacts';
      })
      .map(({ id, title }) => ({
        value: id,
        label: template.accessors.getMetaTranslations(title),
      }));
  const [isLoading, setLoadingState] = useState(false);

  const handleSubmit = (values) => {
    const {
      [fieldNames.PAGE_NAME_INPUT]: pageName,
      [fieldNames.PAGE_TEMPLATE_SELECT]: pageTemplateId,
      [fieldNames.DISPLAY_IN_NAVIGATION_CHECKBOX]: displayInNavigation,
    } = values;

    onDialogMainAction({ pageTemplateId, pageName, displayInNavigation });

    onSubmit(values);

    setLoadingState(true);
  };

  const handleCancel = () => {
    onDialogCancel();
    dispatch(dialogs.actions.show(dialogTypes.PAGE_MANAGER));
    onCancel();
  };

  const languageCodes = useMemo(() => getLanguageCodes(), []);

  const titleValidation = (value) => {
    if (!value) {
      return errors.required;
    }
    const includeNumberOrString = value.match(common.LETTER_OR_NUMBER_REGEXP);

    if (!includeNumberOrString) {
      return errors.invalidFormat;
    }

    if (languageCodes.includes(transliteration.slugify(value))) {
      return errors.homePageError;
    }

    return '';
  };

  return (
    <NewPageDialog
      width={bowser.mobile ? ADD_PAGE_DIALOG_MOBILE_WIDTH : ADD_PAGE_DIALOG_WIDTH}
      captions={captions}
      pageTitle={pageTitle}
      pageTemplate={pageTemplate}
      options={pageTemplatesOptions}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      height="auto"
      titleValidation={titleValidation}
      isCheckboxAvailable={isDisplayInNavAvailable}
      isLoading={isLoading}
    />
  );
}

NewPageDialogContainer.propTypes = {
  pageTemplate: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  captions: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    checkboxLabel: PropTypes.string,
    submit: PropTypes.string,
    cancel: PropTypes.string,
    textInputLabel: PropTypes.string,
    selectLabel: PropTypes.string,
  }).isRequired,
  isDisplayInNavAvailable: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

NewPageDialogContainer.defaultProps = {
  onSubmit() {},
  onCancel() {},
};

export default NewPageDialogContainer;
