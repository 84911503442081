import { view, globals, linkEditing, textFormatting, anodum, bowser } from '@yola/ws-sdk';
import { EMAIL_REGEX, EMAILS_GLOBAL_ID } from './constants/common';

const { wrappersTypes } = textFormatting.constants;
const { id: wrapperId, className, tagName } = wrappersTypes.link;
const {
  linkTypes,
  common: { LINK_CONFIG_ATTRIBUTE },
} = linkEditing.constants;

const globalEmailIsChanged = (config, payload) => {
  const { elementId } = payload;

  if (!elementId) return [];

  const targetElement = view.accessors.getLiveElement(elementId);
  const globalElement = targetElement.closest(globals.constants.GLOBAL_TAG_NAME);

  if (!globalElement) return [];

  const globalElementId = view.accessors.getLiveElementId(globalElement);

  if (!globals.verifiers.isElementGlobal(globalElementId, EMAILS_GLOBAL_ID)) return [];

  const matchedEmails = [];

  anodum.traverseNode(targetElement, (node) => {
    if (!anodum.isNonEmptyTextNode(node)) return;

    const parentLink = node.parentElement.closest(tagName);
    const { nodeValue } = node;

    // firefox workaround for case when link content was replaces with another email but previous link was not deleted
    if (parentLink) {
      if (!bowser.firefox) return;

      const linkConfig = linkEditing.accessors.getLinkConfig(parentLink);
      const matches = nodeValue.match(EMAIL_REGEX);

      if (!matches) return;

      const [matchedValue] = matches;

      if (matchedValue === nodeValue && matchedValue !== linkConfig.reference) {
        const fixedLinkConfig = { linkType: linkTypes.MAIL, reference: matchedValue };
        parentLink.setAttribute(LINK_CONFIG_ATTRIBUTE, JSON.stringify(fixedLinkConfig));
      }

      return;
    }

    const matches = nodeValue.match(EMAIL_REGEX);

    if (!matches) return;

    matchedEmails.push(...matches);
  });

  if (!matchedEmails.length) return [];

  matchedEmails.forEach((matchedValue) => {
    const linkConfig = { linkType: linkTypes.MAIL, reference: matchedValue };
    const attrs = { href: '#', [LINK_CONFIG_ATTRIBUTE]: JSON.stringify(linkConfig) };

    textFormatting.helpers.wrapperMap.registerWrapper(wrapperId, className, tagName, attrs);
    textFormatting.operations.findAndApplyWrapper(matchedValue, wrapperId, elementId);
    textFormatting.helpers.wrapperMap.removeWrapper(wrapperId);
  });

  return [];
};

export default globalEmailIsChanged;
