import { view } from '@yola/ws-sdk';
import getPaneWidth from '../../control-pane/helpers/get-pane-width';
import constants from '../constants/common';
import getNavbarHeight from '../../navbar/accessors/get-height';

const { CONTROLS_MARGIN_TOP } = constants;

const getControlsPosition = (blockBounds, isRegularImage, controls) => {
  const { x: blockOffsetX, width: blockWidth, top: blockOffsetTop } = blockBounds;
  const liveDocument = view.accessors.getLiveDocument();
  const { innerWidth: windowWidth } = liveDocument.defaultView;
  const navbarHeight = getNavbarHeight();
  const hasNoVerticalSpace = blockOffsetTop < navbarHeight * 2;

  const controlsOffsetX = blockOffsetX + blockWidth / 2;
  const controlsWidth = getPaneWidth(controls);
  const halfControlsWidth = controlsWidth / 2;

  const hasNoRightHorizontalSpace = controlsOffsetX + halfControlsWidth > windowWidth;
  const hasNoLeftHorizontalSpace = controlsOffsetX - halfControlsWidth < 0;
  const rightDiff = Math.abs(controlsOffsetX + halfControlsWidth - windowWidth);
  const leftDiff = Math.abs(controlsOffsetX - halfControlsWidth);

  let offsetX = controlsOffsetX;
  let offsetY = blockOffsetTop + CONTROLS_MARGIN_TOP;

  if (isRegularImage) {
    offsetY = hasNoVerticalSpace
      ? CONTROLS_MARGIN_TOP + navbarHeight
      : blockOffsetTop - CONTROLS_MARGIN_TOP * 2;
  }

  if (hasNoRightHorizontalSpace) {
    offsetX = controlsOffsetX - rightDiff;
  }

  if (hasNoLeftHorizontalSpace) {
    offsetX = controlsOffsetX + leftDiff;
  }

  return {
    offsetX,
    offsetY,
  };
};

export default getControlsPosition;
