import { anodum, linkEditing } from '@yola/ws-sdk';
import constants from '../constants';

const { templates, selectors, common } = constants;

const buildNewMarkup = (staticElement, items) => {
  const cloneNode = staticElement.cloneNode(false);
  const template = templates.socialLink;

  items.forEach((item) => {
    const element = anodum.parseElementFromString(template);

    const link = element.querySelector(selectors.link);
    link.setAttribute(
      linkEditing.constants.common.LINK_CONFIG_ATTRIBUTE,
      JSON.stringify(item.config)
    );

    const icon = element.querySelector(selectors.icon);
    icon.setAttribute('src', item.iconBaseSrc);

    if (item.iconIdAttr) {
      icon.setAttribute('data-icon-id', item.iconIdAttr);
    }

    const { size } = item;
    if (size && size !== common.AUTO_SIZE) {
      icon.style.width = size;
      icon.style.height = size;
    }

    cloneNode.appendChild(element);
  });

  return cloneNode;
};

export default buildNewMarkup;
