import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import { integration, i18next, dialogs } from '@yola/ws-sdk';
import { selectors } from '@yola/subscription-manager-js';
import helpCenter from 'yola-editor/src/js/modules/help-center';
import site from 'yola-editor/src/js/modules/site';
import user from 'yola-editor/src/js/modules/user';
import useFeatureFlags from 'yola-editor/src/js/modules/feature-flags/hooks/use-feature-flags';
import segment from 'src/js/modules/analytics/segment';
import publishing from '../../publishing';
import actions from '../actions';
import PreviewModeLayout from '../components/preview-mode-layout';
import PreviewViewport from '../components/preview-viewport';
import getPreviewModeForDevice from '../helpers/get-preview-mode-for-device';
import adaptFullscreenForMode from '../helpers/adapt-fullscreen-for-mode';
import dialogTypes from '../../dialogs/constants/dialog-types';

const {
  trackers: { trackEvent },
  constants: { events },
} = segment;
const { platformComponents } = user.constants;

const { PreviewModeHeader } = designSystem;

const getCaptions = () => ({
  publish: i18next.t('Publish'),
  back: i18next.t('Back to editing'),
});

const PreviewContainer = ({ origin, path }) => {
  const [mode, setMode] = useState(getPreviewModeForDevice);
  const dispatch = useDispatch();
  const captions = useMemo(() => getCaptions(), []);
  const { userData = {} } = useSelector(integration.selectors.getSettings);
  const siteURL = useSelector(site.selectors.getSiteURL);
  const activeHostingSubscription = useSelector(selectors.getActiveHostingSubscription);
  const availablePlatformComponents = useSelector(user.selectors.getAvailablePlatformComponents);
  const { is_white_label: isWL } = userData;
  const iframeWindow = useRef(null);
  const [featureFlags] = useFeatureFlags(['upgrade_to_publish']);
  const { upgrade_to_publish: isUpgradeToPublishEnabled } = featureFlags;

  const hideZendeskWidget = useCallback(() => {
    if (isWL) return;

    helpCenter.operations.hideForm();
  }, [isWL]);

  const showZendeskWidget = useCallback(() => {
    if (isWL) return;

    helpCenter.operations.showForm();
  }, [isWL]);

  const handleDocumentLoad = (iframe) => {
    const { contentWindow } = iframe;
    iframeWindow.current = contentWindow;

    contentWindow.postMessage(JSON.stringify({ event: 'previewmode' }), origin);
  };

  const handleModeChange = (newMode) => {
    setMode(newMode);
    trackEvent(events.PREVIEW_DEVICE_CLICKED, {
      typeId: newMode,
    });

    if (iframeWindow.current) {
      adaptFullscreenForMode(newMode, iframeWindow.current, origin);
    }
  };

  const handleBackClick = () => {
    dispatch(actions.hide());
    trackEvent(events.PREVIEW_BACK_CLICKED);
    showZendeskWidget();
  };

  const handlePublish = () => {
    dispatch(actions.hide());

    if (
      (!siteURL || !activeHostingSubscription) &&
      isUpgradeToPublishEnabled &&
      availablePlatformComponents.includes(platformComponents.DOMAINS)
    ) {
      dispatch(dialogs.actions.show(dialogTypes.PUBLISH_INTERRUPTION_DIALOG));
    } else {
      dispatch(publishing.actions.initiatePublishWebsite());
    }

    trackEvent(events.PUBLISH_TRIGGER_CLICKED, {
      triggerId: 'preview-header',
    });
    showZendeskWidget();
  };

  useEffect(() => {
    hideZendeskWidget();
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  const previewSrc = `${origin}${path}`;

  return (
    <PreviewModeLayout>
      <div className="ws-preview-mode-header">
        <PreviewModeHeader
          onBack={handleBackClick}
          onPublish={handlePublish}
          onToggleMode={handleModeChange}
          selectedMode={mode}
          captions={captions}
        />
      </div>
      <PreviewModeLayout.Body>
        <PreviewViewport mode={mode} src={previewSrc} onDocumentLoad={handleDocumentLoad} />
      </PreviewModeLayout.Body>
    </PreviewModeLayout>
  );
};

PreviewContainer.propTypes = {
  origin: PropTypes.string.isRequired,
  path: PropTypes.string,
};

PreviewContainer.defaultProps = {
  path: '/',
};

export default PreviewContainer;
