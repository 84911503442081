import { i18next, dialogs as wsDialogs } from '@yola/ws-sdk';
import dialogs from 'yola-editor/src/js/modules/dialogs';
import site from 'yola-editor/src/js/modules/site/index';
import beforeUnloadListener from 'yola-editor/src/js/utils/before-unload-listener';
import getSearchString from 'yola-editor/src/js/common/helpers/get-search-string';
import publishingConstants from 'yola-editor/src/js/modules/publishing/constants';
import config from 'src/js/modules/config';
import actionTypes from '../constants/action-types';
import yowsServiceClient from '../../yows-service-client/service-client';
import { redirectToUrl } from '../../../router/utils/redirect-to-url';
import auth from '../../auth';
import constants from '../constants';
import user from '../../user';

const { SITE_ID_TO_RECREATE, TEMPLATE_BUILD_SLUG } = constants;
const { YOLA_PARTNER_PUBLISHING_TYPE } = publishingConstants;
const YOLA_PARTNER_ID = 'YOLA';

const siteReset = (store) => (next) => (action) => {
  switch (action.type) {
    case actionTypes.SITE_RESET: {
      const state = store.getState();
      const galleryLink = config.selectors.getGalleryLink(state);
      const siteId = auth.selectors.getSiteId(state);
      const publishedSiteData = site.selectors.getPublishedSiteData(state);
      const publishingType = user.selectors.getPublishingType(state);
      const partnerData = user.selectors.getPartnerData(state);
      const templateBuildSlug = site.selectors.getSiteTemplateSlug(state);
      const searchString = getSearchString();

      const redirectUrl = `${galleryLink}${
        searchString ? `${searchString}&` : '?'
      }${SITE_ID_TO_RECREATE}=${siteId}&${TEMPLATE_BUILD_SLUG}=${templateBuildSlug}`;
      const hasSiteToUnpublish =
        publishedSiteData &&
        (partnerData.id === YOLA_PARTNER_ID || publishingType === YOLA_PARTNER_PUBLISHING_TYPE);

      store.dispatch(dialogs.actions.show(dialogs.dialogTypes.SITE_RESET_PROGRESS));

      yowsServiceClient
        .get()
        .resetSite(siteId)
        .then(() => {
          // removeEventListener should be removed in scope of fix bug with save/unsaved prompt dialog
          beforeUnloadListener.remove();

          if (publishedSiteData && hasSiteToUnpublish) {
            store.dispatch(
              dialogs.actions.show(dialogs.dialogTypes.SITE_RESET_AND_UNPUBLISH, { redirectUrl })
            );
            return;
          }

          store.dispatch(dialogs.actions.hide());
          redirectToUrl(redirectUrl);
        })
        .catch((error) => {
          wsDialogs.operations.hide();
          store.dispatch(
            dialogs.actions.show(dialogs.dialogTypes.SITE_RESET_ERROR, {
              captions: {
                title: i18next.t('Site reset failed'),
                description: i18next.t('Error: {error}', { error: error.message }),
              },
            })
          );
        });

      next(action);
      break;
    }
    default:
      next(action);
  }
};

export default siteReset;
