import { globals, site, anodum, hdrm, template } from '@yola/ws-sdk';
import { COOKIE_BANNER_TAG_NAME, COOKIE_BANNER_SETTING_ID } from '../constants';

const setCookieBanner = (store) => (next) => (action) => {
  const getCookieBannerData = (state) => {
    const cookieBannerVariable = template.selectors.getVariableValueByPath(
      state,
      'cookieBanner[0]'
    );
    const { globalId } = cookieBannerVariable || {};

    return { globalId };
  };

  if (action.type === site.actionTypes.SET_ACTIVE_PAGE) {
    const state = store.getState();
    const { globalId } = getCookieBannerData(state);
    const global = globals.selectors.getGlobal(state, globalId);

    if (global && global.html) {
      const globalElement = anodum.parseElementFromString(global.html);
      const { isCookieBannerEnabled } = site.selectors.getSettings(state);

      if (isCookieBannerEnabled !== undefined) {
        hdrm.helpers.updateChildrenPresence(
          globalElement,
          COOKIE_BANNER_TAG_NAME,
          isCookieBannerEnabled,
          COOKIE_BANNER_SETTING_ID
        );
        store.dispatch(globals.actions.setGlobalHTML(globalId, globalElement.outerHTML));
      }
    }
  }

  if (action.type === site.actionTypes.UPDATE_SETTINGS) {
    const state = store.getState();
    const { globalId } = getCookieBannerData(state);
    const global = globals.selectors.getGlobal(state, globalId);

    if (global && global.html) {
      const globalElement = anodum.parseElementFromString(global.html);
      const { isCookieBannerEnabled } = action.payload.settings;

      if (isCookieBannerEnabled !== undefined) {
        hdrm.helpers.updateChildrenPresence(
          globalElement,
          COOKIE_BANNER_TAG_NAME,
          isCookieBannerEnabled,
          COOKIE_BANNER_SETTING_ID
        );
        store.dispatch(globals.actions.setGlobalHTML(globalId, globalElement.outerHTML));
      }
    }
  }

  next(action);
};

export default setCookieBanner;
