import { anodum } from '@yola/ws-sdk';
import constants from '../constant';

const { SCRIPT_REGEXP } = constants;

const extractScriptsFromTemplate = (template) => {
  const scripts = [];

  let string = template;
  let match = string.match(SCRIPT_REGEXP);

  while (match && match[0]) {
    const fullMatch = match[0];
    const script = document.createElement('script');
    const scriptElement = anodum.parseElementFromString(fullMatch);
    const src = scriptElement.getAttribute('src');
    const content = scriptElement.innerHTML;

    if (src) {
      script.src = src;
    }

    if (content) {
      script.innerHTML = content;
    }

    scripts.push(script);
    string = string.replace(fullMatch, '');
    match = string.match(SCRIPT_REGEXP);
  }

  return {
    scripts,
    template: string,
  };
};

export default extractScriptsFromTemplate;
