export const progressChecklistCategories = [
  {
    slug: 'getStarted',
    captions: 'Get started',
    items: [
      'createSite',
      'setYourSiteTitle',
      'addYourLogo',
      'setYourHeadline',
      'addLeadingImages',
      'customizeBlocks',
    ],
  },
  {
    slug: 'adjustWebsiteDesign',
    captions: 'Adjust website design',
    items: ['pickYourFonts', 'chooseYourColorPalette', 'enableWebsiteAnimations'],
  },
  {
    slug: 'finalizeYourHomepage',
    captions: 'Finalize your homepage',
    items: [
      'connectYourSocialProfiles',
      'addYourPhoneNumber',
      'addAndDeleteBlocks',
      'customizeYourButtons',
      'customizeYourFooterBlock',
      'enableACookieBanner',
    ],
  },
  {
    slug: 'extendWebsiteContent',
    captions: 'Extend website content',
    items: [
      'addContactsPage',
      'addMorePages',
      'finalizeWebsiteNavigation',
      'removeTheBrandedFooter',
    ],
  },
  {
    slug: 'sellOnline',
    captions: 'Sell online',
    items: ['addShopBlock', 'configureYourOnlineStore'],
  },
  {
    slug: 'launchYourWebsite',
    captions: 'Launch your website',
    items: [
      'publishYourWebsite',
      'shareYourSiteInSocialNetworks',
      'verifyYourSiteInGoogleSearchConsole',
      'optimizeForSearch',
    ],
  },
  {
    slug: 'connectCustomDomain',
    captions: 'Connect custom domain',
    items: ['addCustomDomain', 'addPersonalizedEmailAddress'],
  },
];

export const itemsMap = {
  createSite: {
    captions: {
      title: 'Create a site',
      description: '',
      link: '',
    },
    href: '',
    duration: '',
    disabled: true,
  },
  setYourSiteTitle: {
    captions: {
      title: 'Set your site title',
      description:
        'Edit the Header block to set a title for your site and boost your search ranking. Including both title and logo is strongly recommended',
      link: 'Learn more',
    },
    href: 'https://helpcenter.yola.com/hc/en-us/articles/360011571760-Changing-website-name',
    duration: '1 min',
    disabled: false,
  },
  addYourLogo: {
    captions: {
      title: 'Add your logo',
      description:
        'Edit the Header block to upload a logo and highlight your brand. Including both title and logo is strongly recommended',
      link: 'Learn more',
    },
    href: 'https://helpcenter.yola.com/hc/en-us/articles/360011425200-Adding-a-logo',
    duration: '2 min',
    disabled: false,
  },
  setYourHeadline: {
    captions: {
      title: 'Set your headline',
      description:
        'The headline is the first thing your visitors will see. Customize the text in your leading block to capture your visitors’ attention right away',
      link: 'Learn more',
    },
    href: 'https://helpcenter.yola.com/hc/en-us/articles/360011420820-Entering-text-on-a-page',
    duration: '2 min',
    disabled: false,
  },
  addLeadingImages: {
    captions: {
      title: 'Add leading images',
      description:
        'Images and video are the most engaging form of content on your website. Add your images in your leading block to get your visitors excited',
      link: 'Learn more',
    },
    href: 'https://helpcenter.yola.com/hc/en-us/articles/360011503839-Editing-images',
    duration: '2 min',
    disabled: false,
  },
  customizeBlocks: {
    captions: {
      title: 'Customize blocks',
      description:
        'First impressions are important. Customize the layout and appearance of your leading block to get the look you want',
      link: 'Learn more',
    },
    href: 'https://helpcenter.yola.com/hc/en-us/articles/360011492559-Customizing-block-settings',
    duration: '1 min',
    disabled: false,
  },
  pickYourFonts: {
    captions: {
      title: 'Pick your fonts',
      description:
        'Use the Website Design panel to pick the right fonts for the content of your site',
      link: 'Learn more',
    },
    href: 'https://helpcenter.yola.com/hc/en-us/articles/360011524560-Customizing-website-fonts',
    duration: '1 min',
    disabled: false,
  },
  chooseYourColorPalette: {
    captions: {
      title: 'Choose your color palette',
      description: 'Use the Website Design panel to set up the color palette for your website',
      link: 'Learn more',
    },
    href: 'https://helpcenter.yola.com/hc/en-us/articles/360011534020-Changing-a-color-palette',
    duration: '2 min',
    disabled: false,
  },
  enableWebsiteAnimations: {
    captions: {
      title: 'Enable website animations',
      description:
        "Use the Website Design panel to enable website animations and capture your visitors' attention",
      link: 'Learn more',
    },
    href: 'https://helpcenter.yola.com/hc/en-us/articles/360014493099-Adding-website-animations',
    duration: '1 min',
    disabled: false,
  },
  connectYourSocialProfiles: {
    captions: {
      title: 'Connect your social profiles',
      description:
        'Link your site to the platforms you already use in the Header block to connect with your customers',
      link: 'Learn more',
    },
    href: 'https://helpcenter.yola.com/hc/en-us/articles/360011544599-Adding-social-media-icons',
    duration: '4 min',
    disabled: false,
  },
  addYourPhoneNumber: {
    captions: {
      title: 'Add your phone number',
      description:
        'Add your contact number to the Header block. Prominently displaying your phone number makes it easier for visitors to contact you',
      link: 'Learn more',
    },
    href: 'https://helpcenter.yola.com/hc/en-us/articles/360011380320-Customizing-the-Header-block',
    duration: '2 min',
    disabled: false,
  },
  addAndDeleteBlocks: {
    captions: {
      title: 'Add and delete blocks',
      description:
        'To add more content to your page, just click the Plus button to add new blocks. Blocks are sorted by category to make it easy to find the best match for your content',
      link: 'Learn more',
    },
    href: 'https://helpcenter.yola.com/hc/en-us/articles/360011525099-Adding-a-new-block',
    duration: '1 min',
    disabled: false,
  },
  customizeYourButtons: {
    captions: {
      title: 'Customize your buttons',
      description:
        'Customize your buttons with short, specific calls to action, like “Learn More” or “Buy Now”',
      link: 'Learn more',
    },
    href: 'https://helpcenter.yola.com/hc/en-us/articles/360011590419-Changing-the-Call-to-Action-button',
    duration: '5 min',
    disabled: false,
  },
  customizeYourFooterBlock: {
    captions: {
      title: 'Customize your Footer block',
      description:
        'Add information that will be visible on every page. Visitors are 50% more likely to interact with your Footer than with the rest of your pages',
      link: 'Learn more',
    },
    href: 'https://helpcenter.yola.com/hc/en-us/articles/360011387440-Footer-block',
    duration: '5 min',
    disabled: false,
  },
  enableACookieBanner: {
    captions: {
      title: 'Enable a cookie banner',
      description:
        'If privacy is important to your visitors, consider adding a cookie banner, and check that your policies are easy to find',
      link: 'Learn more',
    },
    href: 'https://helpcenter.yola.com/hc/en-us/articles/360011489659-Adding-a-cookie-consent-banner',
    duration: '1 min',
    disabled: false,
  },
  addContactsPage: {
    captions: {
      title: 'Add contacts page',
      description:
        'Increase the chance of visitors contacting you by adding a Contact Us page. It’s a good place to display a contact form, and if appropriate, a map and hours of operation',
      link: 'Learn more',
    },
    href: 'https://helpcenter.yola.com/hc/en-us/articles/360011383660-Creating-a-Contact-Us-page',
    duration: '4 min',
    disabled: false,
  },
  addMorePages: {
    captions: {
      title: 'Add more pages',
      description:
        'Add more pages that are specific to your needs. Use one of the predefined page layouts to speed-up the process',
      link: 'Learn more',
    },
    href: 'https://helpcenter.yola.com/hc/en-us/articles/360014437119-Creating-a-page-from-a-page-template',
    duration: '10 min',
    disabled: false,
  },
  finalizeWebsiteNavigation: {
    captions: {
      title: 'Finalize website navigation',
      description:
        'Use the Navigation panel to customize your website menu and build easy-to-use website navigation for your visitors',
      link: 'Learn more',
    },
    href: 'https://helpcenter.yola.com/hc/en-us/articles/360011504199-Managing-navigation-in-the-header',
    duration: '2 min',
    disabled: false,
  },
  removeTheBrandedFooter: {
    captions: {
      title: 'Remove the Branded footer',
      description:
        'For a more professional look, remove the default branding from the footer of your website by upgrading now',
      link: 'Learn more',
    },
    href: 'https://helpcenter.yola.com/hc/en-us/articles/360011421900-Removing-Yola-logo-from-the-footer',
    duration: '3 min',
    disabled: false,
  },
  addShopBlock: {
    captions: {
      title: 'Add a Shop block',
      description: 'Add a Shop block to start selling online today',
      link: 'Learn more',
    },
    href: 'https://helpcenter.yola.com/hc/en-us/articles/360015285960-Creating-a-free-Online-Store',
    duration: '1 min',
    disabled: false,
  },
  configureYourOnlineStore: {
    captions: {
      title: 'Configure your online store',
      description: 'Visit the online store control panel to complete your store setup',
      link: 'Go to Online Store',
    },
    href: 'https://onlinestore.yola.com/',
    duration: '20 min',
    disabled: false,
  },
  publishYourWebsite: {
    captions: {
      title: 'Publish your website',
      description:
        'To go live and make your website public, click the Publish button in the top panel',
      link: 'Learn more',
    },
    href: 'https://helpcenter.yola.com/hc/en-us/articles/360011543559-How-to-publish-your-website',
    duration: '1 min',
    disabled: false,
  },
  shareYourSiteInSocialNetworks: {
    captions: {
      title: 'Share your site in social networks',
      description: 'Share your website in your social networks to attract your first visitors',
      link: 'Learn more',
    },
    href: 'https://helpcenter.yola.com/hc/en-us/articles/360016205339',
    duration: '5 min',
    disabled: false,
  },
  verifyYourSiteInGoogleSearchConsole: {
    captions: {
      title: 'Verify your site in Google Search console',
      description:
        'Verify your website in the Google Search console to appear in search results faster, and to see which keywords visitors are using to find you online',
      link: 'Learn more',
    },
    href: 'https://helpcenter.yola.com/hc/en-us/articles/360011709480-Using-Google-Search-Console',
    duration: '10 min',
    disabled: false,
  },
  optimizeForSearch: {
    captions: {
      title: 'Optimize for search',
      description:
        'Improve your search ranking by optimizing your site for search engines. Check out our SEO tutorials for guidance',
      link: 'Learn more',
    },
    href: 'https://helpcenter.yola.com/hc/en-us/sections/360003449879-Optimizing-a-site-for-search-engines',
    duration: '',
    disabled: false,
  },
  addCustomDomain: {
    captions: {
      title: 'Add a custom domain',
      description:
        'Improve the credibility of your site by connecting a custom domain. Visitors are 30% more likely to remember your site if it has a top-level domain',
      link: 'Learn more',
    },
    href: 'https://helpcenter.yola.com/hc/en-us/articles/360012493460-Publishing-your-site-to-a-custom-domain',
    duration: '5 min',
    disabled: false,
  },
  addPersonalizedEmailAddress: {
    captions: {
      title: 'Add personalized email address',
      description:
        'Gain trust of your visitors by getting a personalized email address. 75% of consumers say that professional email address is a key to trust',
      link: 'Learn more',
    },
    href: 'https://helpcenter.yola.com/hc/en-us/articles/360016164240-Getting-a-personalized-email-address',
    duration: '5 min',
    disabled: false,
  },
};
