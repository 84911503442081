// As we pass error using integration settings which are using postMessages we can't serialize actual error coming from ws-editor app
// that's why we pass each data individually and need to recreate original error here in order to pass it to Segment.

const createEditorError = ({ errorMessage, networkErrorDetails }) => {
  const error = new Error(`ws-editor error: ${errorMessage}`);

  if (!networkErrorDetails) return error;

  // Network Errors includes additional data from axios like response, and config with endpoint url,
  // we need to extend our custom Error with that data so it will be passed to ERROR_CAUGHT event in mixpanel

  const { response, url } = networkErrorDetails;
  error.config = { url };

  if (response) {
    error.response = {
      data: { ...response },
      status: response.status,
    };
  }

  return error;
};

export default createEditorError;
