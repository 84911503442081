import React from 'react';
import PropTypes from 'prop-types';
import { AccordionHeader, AccordionContent } from '@yola/ws-ui';
import dateTime from 'src/js/modules/date-time';
import AccordionHeaderTitle from 'src/js/modules/common/components/accordion-header-title';
import BackupItem from './backup-item';

function BackupsGroup({
  groupTimestamp,
  backupsList,
  onBackupSelect,
  onBackupDelete,
  dateStyle,
  timeStyle,
  locale,
}) {
  if (!backupsList || !backupsList.length) {
    return null;
  }

  const groupTitle = dateTime.helpers.formatDateUiLabel(groupTimestamp, locale, { dateStyle });

  return (
    <React.Fragment>
      <AccordionHeader itemId={groupTimestamp}>
        {(isOpened) => <AccordionHeaderTitle title={groupTitle} isOpened={isOpened} />}
      </AccordionHeader>
      <AccordionContent
        itemId={groupTimestamp}
        shouldRenderCollapsed
        contentWrapperClassName="ws-site-backups__group-list"
      >
        {backupsList.map((backup) => (
          <BackupItem
            backup={backup}
            locale={locale}
            key={backup.id}
            onSelect={() => onBackupSelect(backup)}
            onDelete={() => onBackupDelete(backup)}
            timeStyle={timeStyle}
          />
        ))}
      </AccordionContent>
    </React.Fragment>
  );
}

BackupsGroup.propTypes = {
  groupTimestamp: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  backupsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      createdAt: PropTypes.string,
      pagesCount: PropTypes.number,
      isAvailable: PropTypes.bool,
      isAutomatic: PropTypes.bool,
    })
  ).isRequired,
  onBackupSelect: PropTypes.func,
  onBackupDelete: PropTypes.func,
  dateStyle: PropTypes.oneOf(['full', 'long', 'medium', 'short']),
  timeStyle: PropTypes.oneOf(['full', 'long', 'medium', 'short']),
};

BackupsGroup.defaultProps = {
  onBackupSelect: Function.prototype,
  onBackupDelete: Function.prototype,
  dateStyle: 'medium',
  timeStyle: 'medium',
};

export default BackupsGroup;
