import { view, anodum, hdrm } from '@yola/ws-sdk';
import adjacentPositions from 'src/js/modules/blocks/constants/adjacent-positions';
import blocks from 'src/js/modules/blocks';
import actions from '../actions';
import getNextSiblingPath from '../helpers/get-next-sibling-path';
import getLastChildPath from '../helpers/get-last-child-path';
import waitForBlockAndScroll from '../helpers/wait-for-block-and-scroll';
import getPrevSiblingPath from '../helpers/get-prev-sibling-path';

const { BEFORE_BEGIN, BEFORE_END } = adjacentPositions;
const { updateStrategies } = hdrm.constants;

const scroller = (store) => (next) => (action) => {
  switch (action.type) {
    case view.actionTypes.DUPLICATE_ELEMENT: {
      const {
        elementId,
        options: { strategy },
      } = action.payload;
      const staticTargetElement = view.accessors.getStaticElement(elementId);
      const duplicatedElementPath = getNextSiblingPath(staticTargetElement);
      const isViewReloadRequired = strategy === updateStrategies.UPDATE_STATIC_ONLY;

      if (isViewReloadRequired) {
        store.dispatch(actions.setScrollTargetPath(duplicatedElementPath));
        next(action);
        return;
      }

      next(action);

      waitForBlockAndScroll(store, duplicatedElementPath);
      break;
    }
    case view.actionTypes.PASTE_ELEMENT:
    case view.actionTypes.INSERT_BLOCK: {
      const {
        refElementId,
        position,
        options: { strategy },
      } = action.payload;
      const staticTargetElement = view.accessors.getStaticElement(refElementId);
      let insertedElementPath;

      switch (position) {
        case BEFORE_BEGIN:
          insertedElementPath = anodum.getTreePathOfNode(staticTargetElement);
          break;
        case BEFORE_END:
          if (staticTargetElement.lastChild) {
            insertedElementPath = getNextSiblingPath(staticTargetElement.lastChild);
          } else {
            insertedElementPath = getLastChildPath(staticTargetElement);
          }
          break;
        default:
          insertedElementPath = getNextSiblingPath(staticTargetElement);
      }

      const isViewReloadRequired = strategy === updateStrategies.UPDATE_STATIC_ONLY;

      if (isViewReloadRequired) {
        next(action);

        store.dispatch(actions.setScrollTargetPath(insertedElementPath));
        return;
      }

      next(action);

      waitForBlockAndScroll(store, insertedElementPath, (liveElementId) => {
        const hasActivePageNonIgnoredBlocks = blocks.verifiers.hasActivePageNonIgnoredBlocks();

        if (hasActivePageNonIgnoredBlocks) {
          view.operations.setHoveredElement(liveElementId);
        }
      });
      break;
    }

    case view.actionTypes.BULK_VIEW_ACTIONS: {
      const {
        actions: viewActions,
        options: { strategy },
      } = action.payload;
      const targetViewAction = viewActions.find(
        (viewAction) => viewAction.type === view.actionTypes.PASTE_ELEMENT
      );

      if (!targetViewAction) {
        next(action);
        return;
      }
      const { payload } = targetViewAction;
      const { refElementId, position } = payload;
      const staticTargetElement = view.accessors.getStaticElement(refElementId);
      next(action);

      const insertedElementPath =
        position === 'beforebegin'
          ? getPrevSiblingPath(staticTargetElement)
          : getNextSiblingPath(staticTargetElement);

      const isViewReloadRequired = strategy === updateStrategies.UPDATE_STATIC_ONLY;

      if (isViewReloadRequired) {
        store.dispatch(actions.setScrollTargetPath(insertedElementPath));
        return;
      }
      waitForBlockAndScroll(store, insertedElementPath);
      break;
    }

    default: {
      next(action);
    }
  }
};

export default scroller;
