import React from 'react';
import bowser from 'yola-bowser';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { utils } from '@yola/ws-sdk';
import {
  Button,
  ButtonGroup,
  Formik,
  Modal,
  Panel,
  PanelGroup,
  PanelLeft,
  Text,
  designSystem,
} from '@yola/ws-ui';
import IntegrationsSection from './integrations-section';
import GeneralSection from './general-section';
import MultilingualTabContainer from '../containers/multilingual-tab-container';
import BackupsSection from './backups-section';
import constants from '../constants';
import HostingSection from './hosting-section';
import DomainSection from './domain-section';
import CustomCodeSection from './custom-code-section';
import BusinessInfoSection from './business-info-section';

const { common, webSettingsConfig, tabIdentifiers, settingTypes } = constants;
const { Tabs, TabsList, Tab, TabsContentList, TabContent } = designSystem;

class WebsiteSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      animatePopoverId: null,
    };

    this.siteNameRef = React.createRef();
    this.phonesRef = React.createRef();
    this.addressesRef = React.createRef();
    this.phonesRef = React.createRef();
    this.emailsRef = React.createRef();
    this.businessHoursRef = React.createRef();
    this.headCodeUpgradePopoverRef = React.createRef();
    this.headerCodeUpgradePopoverRef = React.createRef();
    this.footerCodeUpgradePopoverRef = React.createRef();
    this.scrollContainerRef = React.createRef();
    this.isMobile = bowser.mobile;

    this.bindMethods();
  }

  componentDidMount() {
    const { scrollElementIntoView, settingToScroll } = this.props;
    const scrollContainer = this.scrollContainerRef.current;
    let elementToScroll = null;

    if (settingToScroll === settingTypes.ADDRESSES) {
      elementToScroll = this.addressesRef.current;
    }

    if (settingToScroll === settingTypes.PHONES) {
      elementToScroll = this.phonesRef.current;
    }

    if (settingToScroll === settingTypes.EMAILS) {
      elementToScroll = this.emailsRef.current;
    }

    if (settingToScroll === settingTypes.BUSINESS_HOURS) {
      elementToScroll = this.businessHoursRef.current;
    }

    if (elementToScroll) {
      scrollElementIntoView(scrollContainer, elementToScroll);
    }
  }

  onCodeFieldClick(animatePopoverId, popoverRef) {
    const { scrollElementIntoView } = this.props;
    const element = popoverRef.current;
    const scrollContainer = this.scrollContainerRef.current;

    scrollElementIntoView(scrollContainer, element).then(() => {
      this.setState({ animatePopoverId }, () => {
        setTimeout(this.finishPopoverAnimation, common.POPOVER_ANIMATION_TIME);
      });
    });
  }

  onFormSubmit({ errors, handleSubmit }) {
    const { setActiveTabId, scrollElementIntoView } = this.props;
    const fieldsErrorKeys = Object.keys(errors);
    const fieldsErrorValues = Object.values(errors);
    const emptyMessage = '';

    if (fieldsErrorKeys.length && !fieldsErrorValues.includes(emptyMessage)) {
      const firstErrorField = webSettingsConfig.find((field) =>
        fieldsErrorKeys.includes(field.name)
      );

      setActiveTabId(firstErrorField.tabId).then(() => {
        const scrollContainer = this.scrollContainerRef.current;
        let firstErrorFieldElement = null;

        if (firstErrorField.name === settingTypes.SITE_NAME) {
          firstErrorFieldElement = this.siteNameRef.current;
        }

        if (firstErrorFieldElement) {
          scrollElementIntoView(scrollContainer, firstErrorFieldElement);
        }
      });

      return;
    }

    handleSubmit();
  }

  bindMethods() {
    const {
      captions: {
        customCode: { headCode, headerCode, footerCode },
      },
    } = this.props;

    this.finishPopoverAnimation = this.finishPopoverAnimation.bind(this);
    const headCodeClickArguments = [headCode.name, this.headCodeUpgradePopoverRef];
    const headerCodeClickArguments = [headerCode.name, this.headerCodeUpgradePopoverRef];
    const footerCodeClickArguments = [footerCode.name, this.footerCodeUpgradePopoverRef];
    this.onHeadCodeFieldClick = this.onCodeFieldClick.bind(this, ...headCodeClickArguments);
    this.onHeaderCodeFieldClick = this.onCodeFieldClick.bind(this, ...headerCodeClickArguments);
    this.onFooterCodeFieldClick = this.onCodeFieldClick.bind(this, ...footerCodeClickArguments);
  }

  finishPopoverAnimation() {
    this.setState({
      animatePopoverId: null,
    });
  }

  render() {
    const {
      captions,
      initialValues,
      styles,
      className,
      headCodeDisabled,
      headerCodeDisabled,
      footerCodeDisabled,
      onHeadCodeUpgradeClick,
      onHeaderCodeUpgradeClick,
      onFooterCodeUpgradeClick,
      mobile,
      onSubmit,
      onCancel,
      onWrongTypeFile,
      uploadImage,
      width,
      height,
      integrationTypes,
      setImagePreview,
      bindSubmitForm,
      onKeyDown,
      showPromptForce,
      onUnpublish,
      onResetSite,
      onExportSite,
      onSwitchTemplate,
      isMultilingualEnabled,
      isSiteExportEnabled,
      isBusinessInfoEnabled,
      onTabChange,
      activeTabId,
      isSyndicatedHosting,
      isDomainTabEnabled,
      isBusinessNameSameAsSiteName,
      isBusinessTaxonomyEnabled,
      setIsBusinessNameSameAsSiteName,
      onSiteNameDescriptionClick,
      formRef,
      areAiFeaturesAvailable,
      aiTutorialUrl,
      onAiBannerClick,
    } = this.props;

    const websiteSettingsClasses = classNames('ws-website-settings', className);

    const modalClasses = classNames(
      'ws-website-settings__modal',
      mobile && 'ws-website-settings__modal--mobile'
    );
    const { animatePopoverId } = this.state;

    const headCodeProps = {
      onClick: this.onHeadCodeFieldClick,
      readOnly: headCodeDisabled,
    };
    const headerCodeProps = {
      onClick: this.onHeaderCodeFieldClick,
      readOnly: headerCodeDisabled,
    };
    const footerCodeProps = {
      onClick: this.onFooterCodeFieldClick,
      readOnly: footerCodeDisabled,
    };

    const siteExportCallback = isSiteExportEnabled ? onExportSite : null;

    return (
      <div className={websiteSettingsClasses} style={styles}>
        <Formik initialValues={initialValues} onSubmit={onSubmit} ref={formRef}>
          {({ errors, values, handleSubmit, setFieldValue }) => {
            bindSubmitForm(() => this.onFormSubmit({ handleSubmit, errors }));
            return (
              <Modal
                promptCaptions={captions.prompt}
                handleSubmit={() => this.onFormSubmit({ handleSubmit, errors })}
                handleCancel={onCancel}
                data={values}
                className={modalClasses}
                width={width}
                height={height}
                centered
                overlay="visible"
                draggable={false}
                resizable={false}
                fullscreen={mobile}
                onKeyDown={onKeyDown}
                showPromptForce={showPromptForce}
              >
                <PanelGroup height="100%">
                  <Panel
                    theme="gray-100"
                    height={common.CONTROL_PANEL_HEIGHT}
                    align="middle"
                    corners="squared"
                    className="ws-panel--shrink ws-website-settings__header"
                  >
                    <PanelLeft style={{ paddingLeft: '20px' }}>
                      <Text type="heading-6">{captions.websiteSettings}</Text>
                    </PanelLeft>
                  </Panel>
                  <div className="ws-website-settings__tabs">
                    <Tabs
                      activeTabId={activeTabId}
                      onTabChange={onTabChange}
                      scrollContainerRef={this.scrollContainerRef}
                    >
                      <TabsList>
                        <Tab tabId={tabIdentifiers.GENERAL} label={captions.tabs.general} />
                        {isBusinessInfoEnabled && (
                          <Tab
                            tabId={tabIdentifiers.BUSINESS_INFO}
                            label={captions.tabs.businessInfo}
                          />
                        )}
                        {isMultilingualEnabled && (
                          <Tab
                            tabId={tabIdentifiers.MULTILINGUAL}
                            label={captions.tabs.multilingual}
                          />
                        )}
                        {isDomainTabEnabled && (
                          <Tab tabId={tabIdentifiers.DOMAIN} label={captions.tabs.domain} />
                        )}
                        <Tab
                          tabId={tabIdentifiers.INTEGRATIONS}
                          label={captions.tabs.integrations}
                        />
                        <Tab tabId={tabIdentifiers.CUSTOM_CODE} label={captions.tabs.customCode} />
                        {isSyndicatedHosting && (
                          <Tab tabId={tabIdentifiers.HOSTING} label={captions.tabs.hosting} />
                        )}
                        <Tab tabId={tabIdentifiers.BACKUPS} label={captions.tabs.siteBackups} />
                      </TabsList>
                      <TabsContentList>
                        <TabContent tabId={tabIdentifiers.GENERAL}>
                          <GeneralSection
                            siteNameRef={this.siteNameRef}
                            captions={captions}
                            onWrongTypeFile={onWrongTypeFile}
                            uploadImage={uploadImage}
                            form={values}
                            integrationTypes={integrationTypes}
                            setImagePreview={setImagePreview}
                            onUnpublish={onUnpublish}
                            onResetSite={onResetSite}
                            onSwitchTemplate={onSwitchTemplate}
                            onExportSite={siteExportCallback}
                            initialValues={initialValues}
                            showPromptForce={showPromptForce}
                            isBusinessNameSameAsSiteName={isBusinessNameSameAsSiteName}
                            setFieldValue={setFieldValue}
                            onSiteNameDescriptionClick={onSiteNameDescriptionClick}
                          />
                        </TabContent>
                        {isBusinessInfoEnabled && (
                          <TabContent tabId={tabIdentifiers.BUSINESS_INFO}>
                            <BusinessInfoSection
                              captions={captions.businessInfo}
                              formData={values}
                              isBusinessNameSameAsSiteName={isBusinessNameSameAsSiteName}
                              isBusinessTaxonomyEnabled={isBusinessTaxonomyEnabled}
                              setIsBusinessNameSameAsSiteName={setIsBusinessNameSameAsSiteName}
                              setFieldValue={setFieldValue}
                              phonesRef={this.phonesRef}
                              addressesRef={this.addressesRef}
                              emailsRef={this.emailsRef}
                              businessHoursRef={this.businessHoursRef}
                              areAiFeaturesAvailable={areAiFeaturesAvailable}
                              aiTutorialUrl={aiTutorialUrl}
                              onAiBannerClick={onAiBannerClick}
                            />
                          </TabContent>
                        )}
                        {isMultilingualEnabled && (
                          <TabContent tabId={tabIdentifiers.MULTILINGUAL}>
                            <MultilingualTabContainer
                              formData={values}
                              isMobile={this.isMobile}
                              captions={captions.multilingual}
                              showPromptForce={showPromptForce}
                              areAiFeaturesAvailable={areAiFeaturesAvailable}
                            />
                          </TabContent>
                        )}
                        {isDomainTabEnabled && (
                          <TabContent tabId={tabIdentifiers.DOMAIN}>
                            <DomainSection formData={values} />
                          </TabContent>
                        )}
                        <TabContent tabId={tabIdentifiers.INTEGRATIONS}>
                          <IntegrationsSection
                            captions={captions.integrations}
                            form={values}
                            initialValues={initialValues}
                            showPromptForce={showPromptForce}
                          />
                        </TabContent>
                        <TabContent tabId={tabIdentifiers.CUSTOM_CODE}>
                          <CustomCodeSection
                            captions={captions.customCode}
                            headCodeProps={headCodeProps}
                            headerCodeProps={headerCodeProps}
                            footerCodeProps={footerCodeProps}
                            headCodeUpgradePopoverRef={this.headCodeUpgradePopoverRef}
                            headerCodeUpgradePopoverRef={this.headerCodeUpgradePopoverRef}
                            footerCodeUpgradePopoverRef={this.footerCodeUpgradePopoverRef}
                            onHeadCodeUpgradeClick={onHeadCodeUpgradeClick}
                            onHeaderCodeUpgradeClick={onHeaderCodeUpgradeClick}
                            onFooterCodeUpgradeClick={onFooterCodeUpgradeClick}
                            animatePopoverId={animatePopoverId}
                          />
                        </TabContent>
                        {isSyndicatedHosting && (
                          <TabContent tabId={tabIdentifiers.HOSTING}>
                            <HostingSection formData={values} setFieldValue={setFieldValue} />
                          </TabContent>
                        )}
                        <TabContent tabId={tabIdentifiers.BACKUPS}>
                          <BackupsSection form={values} />
                        </TabContent>
                      </TabsContentList>
                    </Tabs>
                  </div>
                  <Panel
                    height={common.CONTROL_PANEL_HEIGHT}
                    align="middle"
                    corners="squared"
                    className="ws-panel--shrink"
                  >
                    <ButtonGroup stick="top" block>
                      <Button
                        className="ws-website-settings__button"
                        size="large"
                        type="default"
                        stretch="block"
                        onClick={() => this.onFormSubmit({ handleSubmit, errors })}
                      >
                        {captions.apply}
                      </Button>
                      <Button
                        className="ws-website-settings__button"
                        size="large"
                        type="default"
                        stretch="block"
                        onClick={onCancel}
                      >
                        {captions.cancel}
                      </Button>
                    </ButtonGroup>
                  </Panel>
                </PanelGroup>
              </Modal>
            );
          }}
        </Formik>
      </div>
    );
  }
}

WebsiteSettings.defaultProps = {
  width: common.MODAL_DESKTOP_WIDTH,
  height: common.MODAL_DESKTOP_HEIGHT,
  headCodeDisabled: false,
  headerCodeDisabled: false,
  footerCodeDisabled: false,
  className: '',
  styles: {},
  onHeadCodeUpgradeClick: utils.noop,
  onHeaderCodeUpgradeClick: utils.noop,
  onFooterCodeUpgradeClick: utils.noop,
  onUnpublish: null,
  onResetSite: null,
  onSwitchTemplate: null,
  onExportSite: null,
  onWrongTypeFile: utils.noop,
  setImagePreview: utils.noop,
  bindSubmitForm: utils.noop,
  onKeyDown: utils.noop,
  scrollElementIntoView: () => Promise.resolve(),
  isMultilingualEnabled: false,
  isSiteExportEnabled: false,
  isBusinessInfoEnabled: false,
  isDomainTabEnabled: false,
  isBusinessTaxonomyEnabled: false,
  settingToScroll: '',
  areAiFeaturesAvailable: false,
  aiTutorialUrl: '',
  onAiBannerClick: utils.noop,
};

WebsiteSettings.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  headCodeDisabled: PropTypes.bool,
  headerCodeDisabled: PropTypes.bool,
  footerCodeDisabled: PropTypes.bool,
  onHeadCodeUpgradeClick: PropTypes.func,
  onHeaderCodeUpgradeClick: PropTypes.func,
  onFooterCodeUpgradeClick: PropTypes.func,
  isMultilingualEnabled: PropTypes.bool,
  onUnpublish: PropTypes.func,
  onResetSite: PropTypes.func,
  onSwitchTemplate: PropTypes.func,
  onExportSite: PropTypes.func,
  mobile: PropTypes.bool.isRequired,
  className: PropTypes.string,
  activeTabId: PropTypes.string.isRequired,
  isBusinessNameSameAsSiteName: PropTypes.bool.isRequired,
  styles: PropTypes.shape(),
  captions: PropTypes.shape({
    apply: PropTypes.string,
    cancel: PropTypes.string,
    multilingual: PropTypes.shape().isRequired,
    prompt: PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      discard: PropTypes.string,
      submit: PropTypes.string.isRequired,
      cancel: PropTypes.string.isRequired,
    }).isRequired,
    websiteSettings: PropTypes.string.isRequired,
    tabs: PropTypes.shape({
      general: PropTypes.string,
      businessInfo: PropTypes.string,
      integrations: PropTypes.string,
      multilingual: PropTypes.string,
      hosting: PropTypes.string,
      siteBackups: PropTypes.string,
      domain: PropTypes.string,
      customCode: PropTypes.string,
    }),
    favicon: PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      link: PropTypes.shape({
        text: PropTypes.string,
        url: PropTypes.string,
      }),
    }).isRequired,
    siteName: PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      link: PropTypes.shape({
        text: PropTypes.string,
        url: PropTypes.string,
      }),
      errorMessages: PropTypes.shape(),
    }).isRequired,
    businessInfo: PropTypes.shape({
      errorMessages: PropTypes.shape().isRequired,
      profileSection: PropTypes.shape({
        title: PropTypes.string,
      }).isRequired,
      contactInfoSection: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
      }).isRequired,
      businessName: PropTypes.shape({
        name: PropTypes.string,
        checkboxLabel: PropTypes.string,
      }).isRequired,
      businessCategory: PropTypes.shape({
        title: PropTypes.string,
        placeholder: PropTypes.string,
        noResultsText: PropTypes.string,
      }).isRequired,
      businessDescription: PropTypes.shape({
        name: PropTypes.string,
        placeholder: PropTypes.string,
        description: PropTypes.string,
      }).isRequired,
      address: PropTypes.shape().isRequired,
      phone: PropTypes.shape().isRequired,
      email: PropTypes.shape().isRequired,
      businessHours: PropTypes.shape().isRequired,
    }).isRequired,
    customCode: PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      headCode: PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string,
        upgradeText: PropTypes.shape({
          description: PropTypes.string,
          upgrade: PropTypes.string,
        }),
      }),
      headerCode: PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string,
        upgradeText: PropTypes.shape({
          description: PropTypes.string,
          upgrade: PropTypes.string,
        }),
      }),
      footerCode: PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string,
        upgradeText: PropTypes.shape({
          description: PropTypes.string,
          upgrade: PropTypes.string,
        }),
      }),
    }).isRequired,
    cookiesUserData: PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.string,
      description: PropTypes.string,
    }),
    fileInputCaptions: PropTypes.shape({
      button: PropTypes.string.isRequired,
      empty: PropTypes.string.isRequired,
      fileItemName: PropTypes.string.isRequired,
      wrongFileType: PropTypes.string.isRequired,
    }),
    integrations: PropTypes.shape({
      description: PropTypes.string.isRequired,
      googleAnalytics: PropTypes.shape().isRequired,
      googleSearchConsole: PropTypes.shape().isRequired,
      cookiebot: PropTypes.shape().isRequired,
    }).isRequired,
  }).isRequired,
  initialValues: PropTypes.shape({
    siteName: PropTypes.string,
    businessName: PropTypes.string,
    businessDescription: PropTypes.string,
    favicon: PropTypes.string,
    headCode: PropTypes.string,
    headerCode: PropTypes.string,
    footerCode: PropTypes.string,
    isCookieBannerEnabled: PropTypes.bool,
    preventIndexing: PropTypes.bool,
    googleAnalyticsId: PropTypes.string,
    googleWebmasterCode: PropTypes.string,
  }).isRequired,
  showPromptForce: PropTypes.bool.isRequired,
  integrationTypes: PropTypes.shape().isRequired,
  scrollElementIntoView: PropTypes.func,
  onWrongTypeFile: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  uploadImage: PropTypes.func.isRequired,
  bindSubmitForm: PropTypes.func,
  setImagePreview: PropTypes.func,
  onKeyDown: PropTypes.func,
  isSiteExportEnabled: PropTypes.bool,
  isBusinessInfoEnabled: PropTypes.bool,
  onTabChange: PropTypes.func.isRequired,
  setActiveTabId: PropTypes.func.isRequired,
  setIsBusinessNameSameAsSiteName: PropTypes.func.isRequired,
  isSyndicatedHosting: PropTypes.bool.isRequired,
  isDomainTabEnabled: PropTypes.bool,
  isBusinessTaxonomyEnabled: PropTypes.bool,
  settingToScroll: PropTypes.oneOf(Object.values(settingTypes)),
  onSiteNameDescriptionClick: PropTypes.func.isRequired,
  formRef: PropTypes.shape().isRequired,
  areAiFeaturesAvailable: PropTypes.bool,
  aiTutorialUrl: PropTypes.string,
  onAiBannerClick: PropTypes.func,
};

export default WebsiteSettings;
