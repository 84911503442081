import { view } from '@yola/ws-sdk';
import getDataFromMediaNode from 'src/js/modules/extensions/common/helpers/get-data-from-media-node';
import getLinkConfig from 'src/js/modules/extensions/common/helpers/get-link-config';

const getDataFromNode = (mediaElement) => {
  const elementId = view.accessors.getLiveElementId(mediaElement);
  const linkElement = mediaElement.querySelector('a');

  const mediaData = getDataFromMediaNode(mediaElement);
  const linkConfig = getLinkConfig(linkElement, mediaElement);
  const shouldUseTitleForAlt = mediaData?.alt === mediaData?.title;

  return {
    ...mediaData,
    elementId,
    linkConfig,
    shouldUseTitleForAlt,
  };
};

export default getDataFromNode;
