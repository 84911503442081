import constants from '../constants';

const { LIST_TYPE_BULLET_TAG, LIST_TYPE_NUMBER_TAG } = constants.listFormatting;

const getListFromSelection = (element) => {
  if (!element) return false;
  const list = element.closest(
    `${LIST_TYPE_BULLET_TAG.toLowerCase()}, ${LIST_TYPE_NUMBER_TAG.toLowerCase()}`
  );
  if (list && !list.closest('[contenteditable]')) return false;

  return list;
};

export default getListFromSelection;
