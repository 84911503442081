import React from 'react';
import PropTypes from 'prop-types';
import bowser from 'yola-bowser';
import { Modal, Panel, PanelGroup, designSystem } from '@yola/ws-ui';
import mapColorTypes from '../helpers/map-color-type';
import { surfacesKeyMap } from '../constant/colors-maps';

const {
  Stack,
  DialogHeader,
  DialogHeaderTitle,
  DialogButtonGroup,
  DialogButton,
  DialogButtonGroupSeparator,
  Box,
  Paragraph,
  ColorScheme,
  DialogHeaderControls,
  DialogHeaderIconButton,
} = designSystem;

const DIALOG_WIDTH = 376;
const DIALOG_HEIGHT = 646;

const AddCustomPaletteDialog = ({
  offsetX,
  offsetY,
  captions: { title, description, buttons, colors: colorsCaptions },
  onCancel,
  onSubmit,
  onDragEnd,
  onColorSelect,
  colorSchemes,
  onReset,
  showReset,
  isResetDisabled,
  isDeveloperModeEnabled,
  onOverlayClick,
  onDeveloperModeClick,
}) => (
  <Modal
    draggable
    resizable={false}
    fullscreen={bowser.mobile}
    width={DIALOG_WIDTH}
    height={DIALOG_HEIGHT}
    offsetX={offsetX}
    offsetY={offsetY}
    dragHandleSelector=".ws-drag-trigger"
    dragCancelSelector=".ws-ui-dialog-header-controls"
    overlay="none"
    handleCancel={onCancel}
    handleSubmit={onSubmit}
    onDragEnd={onDragEnd}
    onOverlayClick={onOverlayClick}
  >
    <PanelGroup height="100%">
      <Panel
        align="middle"
        corners="squared"
        className="ws-drag-trigger"
        style={{ flexShrink: '0' }}
      >
        <DialogHeader>
          <DialogHeaderTitle>{title}</DialogHeaderTitle>
          <DialogHeaderControls>
            {showReset && (
              <DialogHeaderIconButton disabled={isResetDisabled} onClick={onReset} glyph="reset" />
            )}
          </DialogHeaderControls>
        </DialogHeader>
      </Panel>
      <Panel height="100%" scrollable style={{ borderBottom: 'none' }}>
        <Box
          paddingTop="spacing-s"
          paddingBottom="spacing-s"
          paddingLeft="spacing-m"
          paddingRight="spacing-m"
        >
          <Stack gap="spacing-m">
            <Paragraph appearance="medium-emphasis" size="small">
              {description}
            </Paragraph>
            <Stack gap="spacing-3-xs">
              {Object.entries(surfacesKeyMap).map(([colorType, colorKey]) => (
                <ColorScheme
                  key={colorType}
                  title={colorsCaptions[colorType]}
                  backgroundColor={colorSchemes[colorKey].value}
                  isDarkTheme={colorSchemes[colorKey].isDarkTheme}
                  onEditClick={() =>
                    onColorSelect({
                      colorType: mapColorTypes(colorType),
                      colorKey,
                    })
                  }
                  {...(isDeveloperModeEnabled && {
                    onDesignConfigure: () =>
                      onDeveloperModeClick({ colorType: mapColorTypes(colorType), colorKey }),
                  })}
                />
              ))}
            </Stack>
          </Stack>
        </Box>
      </Panel>
      <Panel corners="squared" style={{ flexShrink: '0' }}>
        <DialogButtonGroup>
          <DialogButton onClick={onSubmit}>{buttons.submit}</DialogButton>
          <DialogButtonGroupSeparator />
          <DialogButton onClick={onCancel}>{buttons.cancel}</DialogButton>
        </DialogButtonGroup>
      </Panel>
    </PanelGroup>
  </Modal>
);

AddCustomPaletteDialog.propTypes = {
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onColorSelect: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func,
  onReset: PropTypes.func,
  captions: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    buttons: PropTypes.shape({
      submit: PropTypes.string,
      cancel: PropTypes.string,
    }),
    colors: PropTypes.shape({
      altLight: PropTypes.string,
      light: PropTypes.string,
      dark: PropTypes.string,
      altDark: PropTypes.string,
      primary: PropTypes.string,
      secondary: PropTypes.string,
    }).isRequired,
  }).isRequired,
  colorSchemes: PropTypes.shape().isRequired,
  showReset: PropTypes.bool.isRequired,
  isResetDisabled: PropTypes.bool.isRequired,
  isDeveloperModeEnabled: PropTypes.bool,
  onOverlayClick: PropTypes.func.isRequired,
  onDeveloperModeClick: PropTypes.func.isRequired,
};

AddCustomPaletteDialog.defaultProps = {
  offsetX: 100,
  offsetY: 50,
  onReset: null,
  isDeveloperModeEnabled: false,
  onDragEnd: Function.prototype,
};

export default AddCustomPaletteDialog;
