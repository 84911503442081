import { RGBColor, converters } from '@yola/yola-palette-generator';
import { anodum } from '@yola/ws-sdk';
import parseRGBColorString from '../../../../website-design/helpers/parse-rgb-color-string';
import getCssPropertyByNode from './get-css-property-by-node';
import nodeHasBackground from './node-has-background';
import getSubmoduleConfig from '../../../helpers/get-submodule-config';
import { SUBMODULE_NAME } from '../constants';

const { traverseNode, isElementNode } = anodum;

const getTextAreas = (parentBlock) => {
  const textAreas = [];
  const { textAreaSelector } = getSubmoduleConfig(SUBMODULE_NAME);

  parentBlock.querySelectorAll(textAreaSelector).forEach((textNode) => {
    const colors = [];

    if (nodeHasBackground(textNode) || !isElementNode(textNode)) return;

    traverseNode(textNode, (targetNode) => {
      if (!isElementNode(targetNode)) return;

      try {
        const rgbColor = getCssPropertyByNode(targetNode, 'color');
        const { r, g, b, a } = parseRGBColorString(rgbColor);
        const rgbColorObject = new RGBColor(r / 255, g / 255, b / 255, a);
        colors.push(converters.rgb2hsl(rgbColorObject));
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    });

    const [lightestColor] = colors.sort(
      (hslaOne, hslaTwo) => hslaTwo.lightness - hslaOne.lightness
    );

    textAreas.push({
      width: textNode.offsetWidth,
      height: textNode.offsetHeight,
      position: {
        x: textNode.offsetLeft,
        y: textNode.offsetTop,
      },
      color: lightestColor.toCSSValue(),
    });
  });

  return textAreas;
};

export default getTextAreas;
