import bowser from 'yola-bowser';
import MODES from '../constants/modes';

const getPreviewModeForDevice = () => {
  if (bowser.mobile) return MODES.mobile;
  if (bowser.tablet) return MODES.tablet;

  return MODES.desktop;
};

export default getPreviewModeForDevice;
