import imageResizeToolSettings from 'src/js/modules/extensions/registry/ws-image/tools/image-resize';
import isSingleImageResizingDisabled from 'src/js/modules/extensions/registry/ws-image/helpers/is-single-image-resizing-disabled';
import isGallerySliderImage from 'src/js/modules/extensions/registry/ws-image/helpers/is-gallery-slider-image';

const isResizable = (node) =>
  imageResizeToolSettings.matches(node) &&
  !isSingleImageResizingDisabled(node) &&
  !isGallerySliderImage(node);

export default isResizable;
