const parseWidthToPx = (width, parentWidth) => {
  if (width.includes('%') && parentWidth) {
    return (parseInt(width, 10) * parentWidth) / 100;
  }

  if (width.includes('px')) {
    return parseInt(width, 10);
  }

  return null;
};

export default parseWidthToPx;
