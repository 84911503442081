import { customization } from '@yola/ws-sdk';
import compileCustomTemplate from './compile-custom-template';

const setCurrentButtonPreset = (global, buttonPreset, customTemplate, selector) => {
  const node = global.cloneNode(true);
  const compiledCustomTemplate = compileCustomTemplate(customTemplate, buttonPreset);

  const el = node.querySelector(selector);
  el.innerHTML = compiledCustomTemplate;
  el.setAttribute(customization.constants.BUTTON_PRESET_DATA_ATTRIBUTE, buttonPreset.id);

  return node;
};

export default setCurrentButtonPreset;
