const CUSTOM_PALETTE_ID_PREFIX = 'custom-palette';

const generateCustomPaletteId = (ids) => {
  const countNumb = ids.reduce((accum, id) => {
    const lastNumber = parseInt(id.split('-').pop(), 10);
    return lastNumber > accum ? lastNumber : accum;
  }, 0);

  return `${CUSTOM_PALETTE_ID_PREFIX}-${countNumb + 1}`;
};

export default generateCustomPaletteId;
