import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';

const { Tag, Paragraph, Stack, InteractiveBox } = designSystem;

const WidgetItem = ({ item, onClick }) => {
  const { title, isPremium, preview } = item;
  const { source: previewSrc } = preview;
  const handleClick = () => {
    onClick(item);
  };

  return (
    <div className="ws-add-widget-dialog__widget-item" onClick={handleClick}>
      <Stack gap="spacing-4-xs">
        <InteractiveBox borderRadius="small" focusable onClick={handleClick}>
          <div className="ws-add-widget-dialog__widget-item-preview">
            {previewSrc && <img src={previewSrc} alt={title} />}
            {isPremium && (
              <span className="ws-add-widget-dialog__widget-item-badge">
                <Tag iconGlyph="star" appearance="cta" size="small" label="" />
              </span>
            )}
          </div>
        </InteractiveBox>
        <Paragraph size="small" align="center" isClipped>
          {title}
        </Paragraph>
      </Stack>
    </div>
  );
};

WidgetItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    isPremium: PropTypes.bool,
    preview: PropTypes.shape({
      source: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default WidgetItem;
