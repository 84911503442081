import { assets } from '@yola/ws-sdk';

const handleFileSizeLimit = ({ images, store, resolve, reject }) => {
  const { actions, constants } = assets;
  const { SIZE_LIMIT_CODE } = constants;

  const serviceFileSizeLimitReached = images.some(
    (image) => image.detail && image.detail.code === SIZE_LIMIT_CODE
  );

  if (serviceFileSizeLimitReached) {
    store.dispatch(actions.fileSizeLimitReached(images, resolve, reject));
  }
};

export default handleFileSizeLimit;
