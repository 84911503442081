import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function BackgroundBox({ url, size }) {
  const classes = classNames('ws-background-box', `ws-background-box--${size}`);

  return (
    <div
      className={classes}
      style={{
        backgroundImage: `url(${url})`,
      }}
    />
  );
}

BackgroundBox.propTypes = {
  url: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['cover', 'contain']),
};

BackgroundBox.defaultProps = {
  size: 'cover',
};

export default BackgroundBox;
