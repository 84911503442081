import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@yola/ws-ui';

const AddCustomTextColorButton = ({ onShowDialog }) => (
  <div className="ws-color-option">
    <button
      className="ws-color-option__background ws-custom-text-color__button"
      type="button"
      onClick={onShowDialog}
    >
      <Icon glyph="plus" size="15" strokeWidth="1.8" />
    </button>
  </div>
);

AddCustomTextColorButton.propTypes = {
  onShowDialog: PropTypes.func.isRequired,
};

export default AddCustomTextColorButton;
