import bowser from 'yola-bowser';
import { FORCED_TABLET_WIDTH, FORCED_MOBILE_WIDTH } from '../constants/sizes';

const getForcedDeviceWidth = () => {
  if (bowser.mobile) return FORCED_MOBILE_WIDTH;
  if (bowser.tablet) return FORCED_TABLET_WIDTH;

  if (window.innerWidth < FORCED_TABLET_WIDTH) return FORCED_TABLET_WIDTH;

  return null;
};

export default getForcedDeviceWidth;
