import { extensions } from '@yola/ws-sdk';
import constants from '../constants';

const shouldShowTextFormattingTrigger = (element) => {
  const settings = extensions.accessors.getExtensionSettings(constants.common.SLUG);

  if (settings.textFormattingRestrictedParentsSelector) {
    if (element.closest(settings.textFormattingRestrictedParentsSelector)) return false;
  }

  return element.matches(settings.textFormattingQuerySelector);
};

export default shouldShowTextFormattingTrigger;
