import { useState, useEffect, useMemo } from 'react';
import { textFormatting, contentEditable } from '@yola/ws-sdk';
import getAdvancedSelection from '../helpers/get-advanced-selection';
import getElementTypeOptions from '../helpers/get-element-type-options';
import getTypedElementSelector from '../helpers/get-typed-element-selector';
import findTypedParentElements from '../helpers/find-typed-parent-elements';
import restoreRange from '../helpers/restore-range';
import changeIndent from '../helpers/change-indent';
import constants from '../constants';

const { TEXT_INTENT_MODIFIER_PREFIX, TEXT_INDENT_STEP, MAX_TEXT_INDENT_VALUE } =
  textFormatting.constants;
const { TEXT_INDENT_TRIGGER_ID, TEXT_OUTDENT_TRIGGER_ID } = constants.textIndentTriggerIds;

const getCurrentIndentsMap = (focusElements) => {
  const textIndentsMap = new Map();

  focusElements.forEach((element) => {
    const modifier = Array.from(element.classList).find((className) =>
      className.startsWith(TEXT_INTENT_MODIFIER_PREFIX)
    );

    const indentValue = Number(modifier?.slice(TEXT_INTENT_MODIFIER_PREFIX.length)) || 0;

    textIndentsMap.set(element, indentValue);
  });

  return textIndentsMap;
};

function useTextIndentControl(elementId) {
  const selection = getAdvancedSelection();
  const elementTypeOptions = useMemo(() => getElementTypeOptions(elementId), [elementId]);
  const typedElementSelector = useMemo(
    () => getTypedElementSelector(elementTypeOptions),
    [elementTypeOptions]
  );
  const focusElements = findTypedParentElements(typedElementSelector, selection);

  const [currentIndentValuesMap, setCurrentIndentValuesMap] = useState(new Map());
  const [shouldTriggersStateBeUpdated, setShouldTriggersStateBeUpdated] = useState(false);
  const [triggersState, setTriggersState] = useState({
    [TEXT_INDENT_TRIGGER_ID]: false,
    [TEXT_OUTDENT_TRIGGER_ID]: false,
  });

  const onTriggerClick = (triggerId) => {
    const indentModifiersMap = new Map();

    if (triggerId === TEXT_INDENT_TRIGGER_ID) {
      currentIndentValuesMap.forEach((value, key) => {
        let modifier;

        if (!value) {
          modifier = `${TEXT_INTENT_MODIFIER_PREFIX}${TEXT_INDENT_STEP}`;
        } else if (value < MAX_TEXT_INDENT_VALUE) {
          const newIndentValue = value + TEXT_INDENT_STEP;
          modifier = `${TEXT_INTENT_MODIFIER_PREFIX}${newIndentValue}`;
        } else {
          modifier = `${TEXT_INTENT_MODIFIER_PREFIX}${value}`;
        }

        indentModifiersMap.set(key, modifier);
      });
    }

    if (triggerId === TEXT_OUTDENT_TRIGGER_ID) {
      currentIndentValuesMap.forEach((value, key) => {
        let modifier;

        if (value - TEXT_INDENT_STEP <= 0) {
          modifier = '';
        } else {
          const newIndentValue = value - TEXT_INDENT_STEP;
          modifier = `${TEXT_INTENT_MODIFIER_PREFIX}${newIndentValue}`;
        }

        indentModifiersMap.set(key, modifier);
      });
    }

    contentEditable.operations.addCheckpoint();

    changeIndent(indentModifiersMap);

    try {
      restoreRange(selection, elementId);
    } catch (error) {
      console.error(error);
    }

    setShouldTriggersStateBeUpdated(true);
  };

  useEffect(() => {
    if (!focusElements.length) {
      return;
    }

    const currentIndentMap = getCurrentIndentsMap(focusElements);
    currentIndentMap.forEach((value, key) =>
      setCurrentIndentValuesMap((prev) => prev.set(key, value))
    );
    setShouldTriggersStateBeUpdated(true);
  }, [focusElements]);

  useEffect(() => {
    if (shouldTriggersStateBeUpdated) {
      const indentValues = Array.from(currentIndentValuesMap.values());

      if (indentValues.every((value) => value <= 0)) {
        setTriggersState({ [TEXT_INDENT_TRIGGER_ID]: false, [TEXT_OUTDENT_TRIGGER_ID]: true });
      } else if (indentValues.every((value) => value >= MAX_TEXT_INDENT_VALUE)) {
        setTriggersState({ [TEXT_INDENT_TRIGGER_ID]: true, [TEXT_OUTDENT_TRIGGER_ID]: false });
      } else {
        setTriggersState({ [TEXT_INDENT_TRIGGER_ID]: false, [TEXT_OUTDENT_TRIGGER_ID]: false });
      }
    }

    setShouldTriggersStateBeUpdated(false);

    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, [shouldTriggersStateBeUpdated]);

  return { selection, triggersState, onTriggerClick };
}

export default useTextIndentControl;
