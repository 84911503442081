import store from 'src/js/store';
import selectors from '../selectors';

const getClipboardAccessInstructionUrl = () => {
  const state = store.getState();

  return selectors.getClipboardAccessInstructionUrl(state);
};

export default getClipboardAccessInstructionUrl;
