import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { i18next } from '@yola/ws-sdk';
import dialogs from 'yola-editor/src/js/modules/dialogs';
import dialogTypes from 'yola-editor/src/js/modules/dialogs/constants/dialog-types';
import segment from 'src/js/modules/analytics/segment';
import user from 'yola-editor/src/js/modules/user';
import auth from 'yola-editor/src/js/modules/auth';
import site from 'yola-editor/src/js/modules/site';
import referralLinksStorage from 'yola-editor/src/js/modules/refer-a-friend/helpers/referral-links-storage';
import showShareDialog from 'yola-editor/src/js/modules/refer-a-friend/operations/show-share-dialog';
import getLocale from 'yola-editor/src/js/modules/i18n/selectors/locale';
import getPMFSurveyUrl from 'src/js/modules/config/selectors/pmf-survey-url';
import GenericDialog from 'yola-editor/src/js/modules/dialogs/components/generic-dialog';
import useFeatureFlags from 'yola-editor/src/js/modules/feature-flags/hooks/use-feature-flags';
import helpers from '../../helpers';

const { track, constants } = segment;

function SurveyDialogContainer({ onDialogMainAction, onDialogCancel }) {
  const captions = {
    title: i18next.t('Help us improve our product!'),
    content: i18next.t(
      'Please take a quick, 2-minute survey and let us know how we can help you be more successful online'
    ),
    secondaryAction: i18next.t('Remind me later'),
    mainAction: i18next.t('Take a survey'),
  };

  const dispatch = useDispatch();
  const userData = useSelector(user.selectors.getUserData);
  const locale = useSelector(getLocale);
  const siteId = useSelector(auth.selectors.getSiteId);
  const templateBuildSlug = useSelector(site.selectors.getSiteTemplateSlug);
  const url = useSelector(getPMFSurveyUrl);
  const link = helpers.generatePMFSurveyLink(url || '', userData.id, locale);

  const [featureFlags] = useFeatureFlags(['refer_a_friend']);
  const { refer_a_friend: isReferAFriendFeatureAvailable } = featureFlags;

  const closeDialog = () => dialogs.operations.hide();

  const handleDisplaySurvey = () => {
    onDialogMainAction();

    track(constants.events.PMF_SURVEY_DISPLAYED, {
      siteId,
      templateBuildSlug,
    });

    let thankYouDialogProps = {
      captions: {
        mainAction: i18next.t('Close'),
      },
      onMainAction: closeDialog,
      onClose: closeDialog,
    };

    if (isReferAFriendFeatureAvailable) {
      const preloadReferralLinks = () => referralLinksStorage.getAsync();

      thankYouDialogProps = {
        captions: {
          mainAction: i18next.t('Recommend Yola to a friend'),
          secondaryAction: i18next.t('Close'),
        },
        onShow: () => {
          preloadReferralLinks();
        },
        onMainAction: () => {
          referralLinksStorage.getAsync().then((links) => {
            showShareDialog(links);
          });
        },
        onSecondaryAction: closeDialog,
        onClose: closeDialog,
      };
    }

    dialogs.operations.show(dialogTypes.SURVEY_THANK_YOU, thankYouDialogProps);
  };

  const handlePostponeSurvey = () => {
    onDialogCancel();

    track(constants.events.PMF_SURVEY_POSTPONED, {
      siteId,
      templateBuildSlug,
    });

    dispatch(
      user.actions.updateUserPreference(
        user.preferences.PMF_STATUS_UPDATE_AT,
        helpers.getCurrentDateTime()
      )
    );

    closeDialog();
  };

  return (
    <GenericDialog
      captions={captions}
      mainActionLink={link}
      onMainAction={handleDisplaySurvey}
      onSecondaryAction={handlePostponeSurvey}
      onClose={handlePostponeSurvey}
    >
      <span className="dialog-body__emoji" role="img" aria-label="Waving Hand">
        &#x1f44b;
      </span>
      <h5 className="dialog-body__title">{captions.title}</h5>
      <p className="dialog-body__content">{captions.content}</p>
    </GenericDialog>
  );
}

SurveyDialogContainer.propTypes = {
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

export default SurveyDialogContainer;
