import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import { i18next } from '@yola/ws-sdk';
import ConnectIntegrationDialogScaffolding from './connect-integration-dialog-scaffolding';
import constants from '../../website-settings/constants';

const { StepLine, Step, ControlGroup, ActionButton, InputGroup, FieldStatus } = designSystem;

const {
  externalLinks: { COOKIEBOT_SIGNUP_URL },
} = constants;

const ConnectCookiebotDialog = ({ captions, onSubmit, onCancel, validate }) => {
  const [cookiebotId, setCookiebotId] = useState('');
  const [validationError, setValidationError] = useState('');
  const controlWrapperRef = useRef(null);

  const handleChange = (value) => {
    setValidationError('');
    setCookiebotId(value);
  };

  const handleSubmit = () => {
    const trimmedValue = cookiebotId?.trim();
    const validationMessage = validate(trimmedValue);

    if (validationMessage) {
      setValidationError(validationMessage);
      controlWrapperRef.current?.scrollIntoView({ behavior: 'smooth' });
      return;
    }

    onSubmit(trimmedValue);
  };

  return (
    <ConnectIntegrationDialogScaffolding
      captions={{
        title: i18next.t('Connect {integrationType}', { integrationType: captions.name }),
        about: captions.about,
        notes: captions.notes,
      }}
      onCancel={onCancel}
      onSubmit={handleSubmit}
    >
      <StepLine>
        <Step marker={1}>
          <ControlGroup title={captions.step1.title} description={captions.step1.description}>
            <ActionButton label={captions.step1.btnLabel} href={COOKIEBOT_SIGNUP_URL} />
          </ControlGroup>
        </Step>
        <Step marker={2}>
          <ControlGroup title={captions.step2.title} descriptionHTML={captions.step2.description} />
        </Step>
        <Step marker={3}>
          <div ref={controlWrapperRef}>
            <ControlGroup title={captions.step3.title} descriptionHTML={captions.step3.description}>
              <InputGroup
                placeholder={captions.control.name}
                onChange={handleChange}
                invalid={!!validationError}
                value={cookiebotId}
              />
              {validationError && <FieldStatus appearance="error" text={validationError} />}
            </ControlGroup>
          </div>
        </Step>
      </StepLine>
    </ConnectIntegrationDialogScaffolding>
  );
};

ConnectCookiebotDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  captions: PropTypes.shape({
    name: PropTypes.string.isRequired,
    about: PropTypes.string.isRequired,
    step1: PropTypes.shape().isRequired,
    step2: PropTypes.shape().isRequired,
    step3: PropTypes.shape().isRequired,
    control: PropTypes.shape().isRequired,
    notes: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  validate: PropTypes.func,
};

ConnectCookiebotDialog.defaultProps = {
  validate: () => null,
};

export default ConnectCookiebotDialog;
