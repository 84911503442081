import setUserData from './set-user-data.js';
import updateUserData from './update-user-data';
import fetchUserPartnerData from './fetch-user-partner-data';
import sendVerificationEmail from './send-verification-email';
import setUserPartnerData from './set-user-partner-data';
import setUserPreferences from './set-user-preferences';
import fetchUserPreferences from './fetch-user-preferences';
import updateUserPreference from './update-user-preference';
import identifyUserWithPartnerData from './identify-user-with-partner-data';
import setUserFeatures from './set-user-features';
import fetchUserFeatures from './fetch-user-features';

export default {
  setUserData,
  updateUserData,
  sendVerificationEmail,
  fetchUserPreferences,
  fetchUserPartnerData,
  setUserPartnerData,
  setUserPreferences,
  updateUserPreference,
  identifyUserWithPartnerData,
  setUserFeatures,
  fetchUserFeatures,
};
