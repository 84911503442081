import { site, template } from '@yola/ws-sdk';
import segment from 'src/js/modules/analytics/segment';

const trackImageResizingCompleted = ({
  blockId,
  blockVariationId,
  oldHeight,
  newHeight,
  affectedImages,
  shiftKeyPressed,
}) => {
  const { track, constants } = segment;

  track(constants.events.IMAGE_RESIZING_COMPLETED, {
    siteId: site.accessors.getSiteId(),
    templateBuildSlug: template.accessors.getBuildSlug(),
    blockId,
    blockVariationId,
    oldHeight,
    newHeight,
    affectedImages,
    shiftKeyPressed,
  });
};

export default trackImageResizingCompleted;
