import { site } from '@yola/ws-sdk';
import integrationTypes from '../constants/integration-types';
import settingTypes from '../constants/setting-types';

const updateIntegrations = (store) => (next) => (action) => {
  if (action.type === site.actionTypes.UPDATE_INTEGRATIONS) {
    const { integrations } = action.payload;

    if (integrations[integrationTypes.COOKIEBOT_ID]) {
      store.dispatch(
        site.actions.updateSettings({
          [settingTypes.IS_COOKIE_BANNER_ENABLED]: false,
        })
      );
    }
  }

  next(action);
};

export default updateIntegrations;
