import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { dialogs, siteLocales, i18next } from '@yola/ws-sdk';
import { reactHookForm } from '@yola/ws-ui';
import { constants as subscriptionConstants } from '@yola/subscription-manager-js';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import useFeatureFlags from 'yola-editor/src/js/modules/feature-flags/hooks/use-feature-flags';
import tabIdentifiers from 'src/js/modules/website-settings/constants/tab-identifiers';
import ai from '../../ai';
import upsell from '../../upsell';
import AddPageDialog from '../components/add-page-dialog';
import fieldNames from '../constants/field-names';
import getOptionsToCreatePage from '../helpers/get-options-to-create-page';
import pageTypes from '../constants/page-types';
import constants from '../constants';

const { useForm } = reactHookForm;
const {
  constants: { sourceIds },
  hooks: { useAiOnboarding },
} = ai;
const {
  hostingPackageFeatureNames: { AI_PAGE_WIZARD },
} = subscriptionConstants;

function AddPageContainer(props) {
  const { pageType, pageName, onAddPageFromTemplate, onDialogMainAction, onDialogCancel } = props;

  const pageOptions = useMemo(
    () =>
      getOptionsToCreatePage().map((option) => ({
        label: option.name,
        value: option.id,
      })),
    []
  );

  const [selectedOption, setSelectedOption] = useState(
    () => pageOptions.find((option) => option.value === pageType) || pageOptions[0]
  );
  const locale = useSelector(siteLocales.selectors.getCurrentLocale);
  const dispatch = useDispatch();
  const [featureFlags] = useFeatureFlags(['business_info_in_website_settings']);
  const { business_info_in_website_settings: isBusinessInfoInWebsiteSettingsEnabled } =
    featureFlags;
  const { control, handleSubmit, formState, getValues } = useForm({
    defaultValues: {
      [fieldNames.PAGE_NAME_INPUT]: pageName || i18next.t('Untitled'),
      [fieldNames.DISPLAY_IN_NAVIGATION_CHECKBOX]: true,
      [fieldNames.PAGE_TEMPLATE_SELECT]: selectedOption.value,
    },
    mode: 'onTouched',
  });
  const { onNextDialog } = useAiOnboarding();
  const isEmptyPage = selectedOption.value === pageTypes.EMPTY_PAGE;

  const fields = {
    [fieldNames.PAGE_NAME_INPUT]: {
      rules: {
        required: i18next.t('Field is required'),
        maxLength: {
          value: 70,
          message: i18next.t('Max length should be 70 characters.'),
        },
      },
    },
  };

  const onPageOptionChange = (value) => {
    setSelectedOption(value);
  };

  const onCancel = () => {
    onDialogCancel();
    dispatch(dialogs.actions.show(dialogTypes.PAGE_MANAGER));
  };

  const onSubmit = (data) => {
    const selectedPageType = data[fieldNames.PAGE_TEMPLATE_SELECT];

    onDialogMainAction({
      pageTemplateId:
        selectedPageType === pageTypes.EMPTY_PAGE ? constants.BLANK_PAGE_TEMPLATE_ID : null,
      displayInNavigation: data[fieldNames.DISPLAY_IN_NAVIGATION_CHECKBOX],
      recipeId:
        selectedPageType !== pageTypes.EMPTY_PAGE ? data[fieldNames.PAGE_TEMPLATE_SELECT] : null,
      generateWithAi: data[fieldNames.PAGE_TEMPLATE_SELECT] !== pageTypes.EMPTY_PAGE,
      pageName: data[fieldNames.PAGE_NAME_INPUT],
    });

    if (selectedPageType === pageTypes.EMPTY_PAGE) {
      dialogs.operations.show(dialogTypes.SAVING_PROGRESS, {
        progressType: 'indeterminate',
        callback: () => {
          onAddPageFromTemplate({
            ...data,
            [fieldNames.PAGE_TEMPLATE_SELECT]: constants.BLANK_PAGE_TEMPLATE_ID,
          });
        },
      });

      return;
    }

    upsell.operations
      .requestFeatureAvailability(AI_PAGE_WIZARD)
      .then(() => {
        dialogs.operations.show(dialogTypes.SAVING_PROGRESS, {
          progressType: 'indeterminate',
          callback: () => {
            onNextDialog({
              sourceId: sourceIds.PAGE_WIZARD,
              title: i18next.t('Generate page'),
              onNext: () =>
                dialogs.operations.show(dialogTypes.PAGE_GENERATION_DIALOG, {
                  name: data[fieldNames.PAGE_NAME_INPUT],
                  displayInNavigation: data[fieldNames.DISPLAY_IN_NAVIGATION_CHECKBOX],
                  displayBusinessName: isBusinessInfoInWebsiteSettingsEnabled,
                  recipeId: selectedPageType,
                  locale,
                }),
            });
          },
        });
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        console.log('Upgrade flow was canceled');
      });
  };

  const openWebsiteSettings = (e) => {
    e.preventDefault();

    dialogs.operations.show(dialogTypes.WEBSITE_SETTINGS, {
      activeTabId: tabIdentifiers.BUSINESS_INFO,
      onAfterDialogClose: () => {
        dialogs.operations.show(dialogTypes.ADD_PAGE_DIALOG, {
          pageType: selectedOption.value,
          pageName: getValues(fieldNames.PAGE_NAME_INPUT),
          onAddPageFromTemplate,
        });
      },
    });
  };

  const pageTemplateSubtitleWithLink = isBusinessInfoInWebsiteSettingsEnabled ? (
    <>
      {i18next.t('Have AI create this page from your business profile. Customize it under {link}', {
        link: '',
      })}{' '}
      <a onClick={openWebsiteSettings} href="/">
        {i18next.t('Settings')} &#x2192; {i18next.t('Business Info')}
      </a>
    </>
  ) : (
    ''
  );

  return (
    <AddPageDialog
      onCancel={onCancel}
      onSubmit={handleSubmit(onSubmit)}
      pageOptions={pageOptions}
      selectedOption={selectedOption}
      onPageOptionChange={onPageOptionChange}
      captions={{
        heading: i18next.t('Add page'),
        description: i18next.t(
          'Choose a page template and let AI generate it for you, or start with a blank page.'
        ),
        button: isEmptyPage ? i18next.t('Create page') : i18next.t('Generate page'),
        pageTemplateSubtitle: isEmptyPage
          ? i18next.t('Start from scratch with an empty page')
          : pageTemplateSubtitleWithLink,
        inputTitle: i18next.t('Page name'),
        inputSubtitle: i18next.t('Name your page before saving'),
        checkboxTitle: i18next.t('Display in navigation'),
        previewLabel: i18next.t('Generated'),
      }}
      fields={fields}
      control={control}
      formErrors={formState.errors}
      showPreviewLabel={!isEmptyPage}
    />
  );
}

AddPageContainer.propTypes = {
  pageType: PropTypes.string,
  pageName: PropTypes.string,
  onAddPageFromTemplate: PropTypes.func.isRequired,
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

AddPageContainer.defaultProps = {
  pageType: pageTypes.EMPTY_PAGE,
  pageName: null,
};

export default AddPageContainer;
