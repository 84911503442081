import { globals, saving, template } from '@yola/ws-sdk';
import constants from 'src/js/modules/website-settings/constants/common';
import store from '../../../store';

const deleteLanguageSelector = async () => {
  const state = store.getState();
  const { globalId } = template.selectors.getVariableValueByPath(state, 'languageSelector[0]');
  const languageSelector = document.createElement(constants.LANGUAGE_SELECTOR_TAG);
  languageSelector.hidden = true;

  // It is necessary to avoid changing the saving status
  saving.utils.initialData.setGlobalHtml(globalId, languageSelector.outerHTML);
  store.dispatch(globals.actions.setGlobalHTML(globalId, languageSelector.outerHTML));
  await store.dispatch(saving.thunks.saveGlobal(globalId));
};

export default deleteLanguageSelector;
