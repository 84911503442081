import dialogs from 'yola-editor/src/js/modules/dialogs';
import getCookie from 'yola-editor/src/js/utils/get-cookie';

let watchedCookies = [];
let intervalId;

const showRequiredCookiesDialog = () => {
  dialogs.operations.show(dialogs.dialogTypes.REQUIRED_COOKIES_ERROR);
};

const cookieStoreChangeListener = Object.freeze({
  add(cookies) {
    if (!cookies.length) return;

    watchedCookies = cookies;

    if (window.cookieStore) {
      window.cookieStore.addEventListener('change', this.handleCookieStoreChange);
    } else {
      intervalId = setInterval(() => {
        const areCookiesExist = watchedCookies.every((cookie) => Boolean(getCookie(cookie)));

        if (!areCookiesExist) {
          showRequiredCookiesDialog();
          clearInterval(intervalId);
        }
      }, 10000);
    }
  },

  remove() {
    if (!watchedCookies.length) return;

    if (window.cookieStore) {
      window.cookieStore.removeEventListener('change', this.handleCookieStoreChange);
    } else {
      clearInterval(intervalId);
    }
  },

  handleCookieStoreChange(event) {
    const deletedCookies = event?.deleted;
    const areWatchedCookiesDeleted =
      deletedCookies && deletedCookies.some(({ name }) => watchedCookies.includes(name));

    if (areWatchedCookiesDeleted) {
      showRequiredCookiesDialog();
    }
  },
});

export default cookieStoreChangeListener;
