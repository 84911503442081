import { HSLColor } from '@yola/yola-palette-generator';
import { cssKeyMap } from '../constant/colors-maps';
import parseHslColorString from './parse-hsl-color-string';
import parseHslaColorString from './parse-hsla-color-string';

const getColorsConfig = (colorsStrings, advancedColorsStrings) => {
  const colors = Object.keys(cssKeyMap).reduce((acc, key) => {
    const accumCopy = { ...acc };
    const colorString = colorsStrings[cssKeyMap[key]];
    if (!colorString) return accumCopy;
    const { h, s, l } = parseHslColorString(colorString);
    accumCopy[key] = new HSLColor(h, s, l);
    return accumCopy;
  }, {});

  const advancedColors = Object.entries(advancedColorsStrings).reduce((acc, [key, colorString]) => {
    const { h, s, l, a } = parseHslaColorString(colorString);
    acc[key] = new HSLColor(h, s, l, a);
    return acc;
  }, {});

  return {
    ...colors,
    advancedColors,
  };
};

export default getColorsConfig;
