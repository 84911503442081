// Some site components have UI strings which should be translated
// according to the editor locale.
// So we have to make translations accessible via injecting them
// into the live doc's Window in a format of a plain object.
export default function injectTranslationsIntoLiveDoc(liveDoc, translations) {
  const translationsObjectKey = '__TRANSLATIONS__';

  // Injection is made via the <script> appended to the live document
  // but not via setting it up directly to the associated window object.
  // This is made to make sure that injected translations are available
  // for our site components at the moment when they are initialized.
  const script = liveDoc.createElement('script');
  script.innerHTML = `window.${translationsObjectKey} = ${JSON.stringify(translations)}`;

  liveDoc.head.appendChild(script);
}
