import on from '../on';
import textIsChanged from './text-is-changed';
import globalEmailIsChanged from './global-email-is-changed';
import reasons from '../../constants/reasons';
import prefixes from '../../constants/prefixes';

const { SYNCHRONIZE_ELEMENT } = reasons;
const { BEFORE } = prefixes;

const init = () => {
  on({ reason: SYNCHRONIZE_ELEMENT, prefix: BEFORE }, textIsChanged);
  on({ reason: SYNCHRONIZE_ELEMENT, prefix: BEFORE }, globalEmailIsChanged);
};

export default init;
