import store from 'src/js/store';
import segment from 'src/js/modules/analytics/segment';
import config from 'src/js/modules/config';
import user from 'yola-editor/src/js/modules/user';
import dialogTypes from 'yola-editor/src/js/modules/dialogs/constants/dialog-types';
import configureSnippet from './configure-snippet';
import loadSnippet from './load-snippet';
import { SOURCE_ID } from './consts';
import i18n from '../../i18n';

const {
  track,
  constants: { events, common },
} = segment;

const getSnippetInstance = async () => {
  const state = store.getState();

  const zendeskWidgetUrl = config.selectors.getZendeskWidgetUrl(state);
  const locale = i18n.selectors.getLocale(state);
  const userData = user.selectors.getUserData(state);

  configureSnippet(document.body);

  const snippet = await loadSnippet(zendeskWidgetUrl, document.body);

  snippet('webWidget', 'setLocale', locale);

  snippet('webWidget', 'prefill', {
    email: {
      value: userData.email,
      readOnly: true,
    },
    name: {
      value: userData.name,
      readOnly: true,
    },
  });

  snippet('webWidget:on', 'close', async () => {
    const snippetCurrent = await loadSnippet(zendeskWidgetUrl, document.body);

    snippetCurrent('webWidget', 'updateSettings', {
      webWidget: {
        contactOptions: {
          enabled: true,
        },
        helpCenter: {
          suppress: false,
        },
        contactForm: {
          suppress: false,
        },
        chat: {
          suppress: false,
        },
      },
    });
    snippetCurrent('webWidget', 'close');
  });

  snippet('webWidget:on', 'open', () => {
    track(events.HELP_DISPLAYED, {
      appName: common.APP_NAME,
      dialogId: dialogTypes.HELP_DISPLAYED,
      sourceId: SOURCE_ID,
    });
  });

  return {
    close() {
      snippet('webWidget', 'close');
    },
    hide() {
      snippet('webWidget', 'close');
      snippet('webWidget', 'hide');
    },
    show() {
      snippet('webWidget', 'show');
    },
    showContactForm() {
      snippet('webWidget', 'updateSettings', {
        webWidget: {
          contactOptions: {
            enabled: true,
          },
          helpCenter: {
            suppress: true,
          },
          contactForm: {
            suppress: false,
          },
          chat: {
            suppress: false,
          },
        },
      });
      snippet('webWidget', 'open');
    },
    showSearchForm() {
      snippet('webWidget', 'updateSettings', {
        webWidget: {
          contactOptions: {
            enabled: false,
          },
          chat: {
            suppress: true,
          },
          contactForm: {
            suppress: true,
          },
          helpCenter: {
            suppress: false,
          },
        },
      });
      snippet('webWidget', 'open');
    },
  };
};

export default getSnippetInstance;
