import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';

const trackBlockSettingsDialogCanceled = ({ onDialogCancel, blockId, blockVariationId }) => {
  if (!blockId) return;

  const traits = {
    blockId,
    blockVariationId,
  };

  onDialogCancel({
    dialogType: dialogTypes.BLOCK_SETTINGS,
    ...traits,
  });
};

export default trackBlockSettingsDialogCanceled;
