import { transactions } from '@yola/ws-sdk';
import reasons from '../../constants/reasons';
import getElementBySelector from '../../helpers/get-element-by-selector';
import getAffectedBlocksSelector from './helpers/get-affected-blocks-selector';
import getSurfaceAutomationInstructions from './helpers/get-surface-automation-instructions';
import getOverlayOpacityAutomationInstructions from './helpers/get-overlay-opacity-automation-instructions';

const possibleReasons = [
  reasons.INSERT_BLOCK,
  reasons.PASTE_ELEMENT,
  reasons.SHIFT_ELEMENT,
  reasons.DELETE_ELEMENT,
  reasons.REPLACE_ELEMENT,
];

const nextBlockIsChanged = (config, payload, transaction) => {
  const { reason } = config;

  if (!possibleReasons.includes(reason)) return [];

  const { context, initialState: initialTransactionDOM, state: transactionDOM } = transaction;
  const affectedBlocksSelector = getAffectedBlocksSelector();
  const headerNode = transactionDOM.querySelector(affectedBlocksSelector);
  const initialHeaderNode = initialTransactionDOM.querySelector(affectedBlocksSelector);

  if (
    !headerNode ||
    !initialHeaderNode ||
    initialHeaderNode?.nextElementSibling?.isEqualNode(headerNode.nextElementSibling)
  ) {
    return [];
  }

  const isStatic = context !== transactions.transactionTypes.LIVE_DOCUMENT;

  return [
    ...getSurfaceAutomationInstructions({
      headerNode: getElementBySelector(affectedBlocksSelector, isStatic),
      nextSiblingNode: headerNode.nextElementSibling,
      isStatic,
    }),
    ...getOverlayOpacityAutomationInstructions({
      headerNode: getElementBySelector(affectedBlocksSelector, isStatic),
      nextSiblingNode: headerNode.nextElementSibling,
      isStatic,
    }),
  ];
};

export default nextBlockIsChanged;
