import { template } from '@yola/ws-sdk';

const createOnlineStoreCleanUrlSelectorOption = ({ item, isEnabled, displayOptions, onChange }) => {
  const options = displayOptions || [];
  const currentOption = options.find((option) => option.id === item.id);

  const handleChange = (event, option) => {
    onChange(option, (displayOption) => ({
      ...displayOption,
      isEnabled: event.target.checked,
    }));
  };

  return {
    ...item,
    onChange: handleChange,
    isEnabled: currentOption ? currentOption.isEnabled : isEnabled,
    isInitialEnabled: currentOption ? currentOption.isInitialEnabled : isEnabled,
    label: template.accessors.getMetaTranslations(item.label),
    title: template.accessors.getMetaTranslations(item.title),
    annotation: template.accessors.getMetaTranslations(item.annotation),
  };
};

export default createOnlineStoreCleanUrlSelectorOption;
