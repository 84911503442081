import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { MultipurposePaywall } from '@yola/webapp-plugins';
import { selectors, verifiers } from '@yola/subscription-manager-js';
import getDiscountInfo from 'yola-editor/src/js/modules/upsell/helpers/get-discount-info';
import getSiteId from 'yola-editor/src/js/modules/auth/selectors/site-id';
import getUserPreferences from 'yola-editor/src/js/modules/user/selectors/user-preferences';
import getUserData from 'yola-editor/src/js/modules/user/selectors/user-data';
import getIsB2C from 'src/js/modules/config/selectors/is-b2c';
import getPartnerData from 'yola-editor/src/js/modules/user/selectors/partner-data';
import getMoneyBackGuarantee from 'yola-editor/src/js/modules/user/selectors/money-back-guarantee';
import useFeatureFlags from 'yola-editor/src/js/modules/feature-flags/hooks/use-feature-flags';
import usePaymentMethods from '../hooks/use-payment-methods';

function PaywallContainer(props) {
  const siteId = useSelector(getSiteId);
  const { initialPackageDiscount } = useSelector(getUserPreferences, shallowEqual);
  const { signupDate } = useSelector(getUserData);
  const subscriptionsList = useSelector(selectors.getSubscriptionList, shallowEqual);
  const subscriptionsTypes = subscriptionsList.map(({ type }) => type);
  const isB2C = useSelector(getIsB2C);
  const hasEverHadPaidHostingSubscription = verifiers.hasPaidHostingPackageType(subscriptionsTypes);
  const hasEverHadB2CPremiumSubscription = isB2C && hasEverHadPaidHostingSubscription;
  const availablePackages = useSelector(selectors.getHostingPackages, shallowEqual);
  const partnerData = useSelector(getPartnerData, shallowEqual);
  const paymentMethods = usePaymentMethods();
  const moneyBackGuarantee = useSelector(getMoneyBackGuarantee);
  const [featureFlags] = useFeatureFlags([
    'ai_site_wizard',
    'ai_page_wizard',
    'ai_block_generator',
    'ai_text_assistant',
    'ai_text_assistant_rewrite',
    'ai_text_assistant_write',
    'site_export',
    'advanced_color_customization',
  ]);

  const discount = getDiscountInfo(
    initialPackageDiscount,
    signupDate,
    hasEverHadB2CPremiumSubscription
  );

  return (
    <MultipurposePaywall
      {...props}
      siteId={siteId}
      discount={discount}
      isB2C={isB2C}
      availablePlans={availablePackages}
      partnerId={partnerData.id}
      partnerName={partnerData.name}
      paymentMethods={paymentMethods}
      moneyBackGuarantee={moneyBackGuarantee}
      featureFlags={featureFlags}
    />
  );
}

export default PaywallContainer;
