import emailVerification from './email-verification';
import userPartnerDataLoader from './user-partner-data-loader';
import userPreferencesLoader from './user-preferences-loader';
import yousersInitializer from './yousers-initializer';
import userFeaturesLoader from './user-features-loader';

export default {
  emailVerification,
  userPartnerDataLoader,
  userPreferencesLoader,
  yousersInitializer,
  userFeaturesLoader,
};
