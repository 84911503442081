import { view } from '@yola/ws-sdk';
import compute from './helpers/compute';
import isWsBlock from '../../../blocks/verifiers/is-ws-block';
import { SURFACES_ATTRIBUTE } from '../../constants/attributes';

const surfaceIsChanged = async (config, payload) => {
  const { elementId, attr } = payload;

  if (!elementId || attr !== SURFACES_ATTRIBUTE) {
    return [];
  }

  // We need to operate only with liveElement when
  // we work with ws-sitecomponents, to prevent skipping
  // of overlay design-automatization
  const targetBlock = view.accessors.getLiveElement(elementId);

  if (!isWsBlock(targetBlock)) {
    return [];
  }

  return compute(targetBlock, { restrictSurfaceAutomation: true });
};

export default surfaceIsChanged;
