import { contentEditable, anodum } from '@yola/ws-sdk';
import parseTemplateFromString from './parse-template-from-string';

const { LIST_ELEMENTS } = contentEditable.constants.editableElements;
const LIST_ELEMENTS_SELECTOR = LIST_ELEMENTS.join(',');

const REGEX_TO_FIND_LIST_ITEMS = /<li>.*<\/li>/g;

const surroundListItemsWithListElement = (template, selection) => {
  const { anchorNode } = selection;
  const anchorElement = anodum.isElementNode(anchorNode) ? anchorNode : anchorNode.parentNode;
  const parentListElement = anchorElement.closest(LIST_ELEMENTS_SELECTOR);

  if (!parentListElement) {
    return template;
  }

  const fragment = template.content.cloneNode(true);
  const listElement = document.createElement(parentListElement.tagName);
  listElement.appendChild(fragment);
  return parseTemplateFromString(listElement.outerHTML);
};

const prepareGeneratedContent = (markup, selection) => {
  let template = parseTemplateFromString(markup.trim());
  contentEditable.helpers.prepareStructure(template.content);

  if (template.innerHTML.match(REGEX_TO_FIND_LIST_ITEMS)) {
    template = surroundListItemsWithListElement(template, selection);
  }

  return [template.innerHTML, template.content];
};

export default prepareGeneratedContent;
