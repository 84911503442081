export default {
  slug: 'ws-slider',
  MODAL_WIDTH: 338,
  MODAL_HEIGHT: 521,
  PANEL_HEIGHT: 48,
  DELAY: {
    min: 1,
    max: 25,
  },
};
