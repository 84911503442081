import { PANE_DEFAULT_SIZE } from '../constants/sizes';
import { MAX_PANE_ITEMS } from '../constants/pane-items';
import getItemsSize from './get-items-size';
import deriveItemsWidth from './derive-items-width';

const getMaxPaneSize = (items, windowInnerWidth, maxItems = MAX_PANE_ITEMS) => {
  const { itemsHeight, itemsWidth } = getItemsSize(items);
  const pagesCount = items.length / maxItems;

  let paneWidthWithIndent = 0;

  for (let i = 0; i < pagesCount; i += 1) {
    const pageItems = items.slice(i * maxItems, (i + 1) * maxItems);
    const pageWidth = deriveItemsWidth(pageItems);
    if (pageWidth > paneWidthWithIndent) paneWidthWithIndent = pageWidth;
  }

  const maxPaneWidth = Math.min(
    windowInnerWidth,
    Math.max(...itemsWidth, PANE_DEFAULT_SIZE, paneWidthWithIndent)
  );
  const maxPaneHeight = Math.max(...itemsHeight, PANE_DEFAULT_SIZE);

  return { maxPaneHeight, maxPaneWidth };
};

export default getMaxPaneSize;
