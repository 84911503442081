import React, { useContext, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { assets, dialogs, i18next, view, hdrm } from '@yola/ws-sdk';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import focalPoint from 'src/js/modules/focal-point';
import segment from 'src/js/modules/analytics/segment';
import getDefaultTraits from 'src/js/modules/analytics/segment/helpers/get-default-traits';
import customUI from 'src/js/modules/custom-ui';
import contextMenu from 'src/js/modules/context-menu';
import isPlaceholder from 'src/js/modules/extensions/registry/ws-image/helpers/is-placeholder';
import BlockSettingsContext from '../contexts/block-settings-context';
import BlockBackgroundImageOption from '../components/block-background-image-option';
import convertAttributeOperationToAction from '../helpers/convert-attribute-operation-to-action';
import {
  BLOCK_SETTINGS_APPEARANCE_TAB_ID,
  BACKGROUND_PLACEHOLDER_ATTRIBUTE,
} from '../constants/common';
import { REMOVE } from '../constants/attribute-operations';

const {
  track,
  constants: { events },
} = segment;

const { addBaseHref } = assets.helpers;
const {
  constants: { common: focalPointConstants },
  helpers: focalPointHelpers,
} = focalPoint;

const { updateStrategies } = hdrm.constants;

const LIVE_STRATEGY_OPTIONS = { strategy: updateStrategies.UPDATE_LIVE_ONLY };

const getCaptions = () => ({
  replaceMediaLabel: i18next.t('Replace media'),
  addMediaLabel: i18next.t('Add media'),
  cropBtnTooltip: i18next.t('Crop'),
});

const BlockBackgroundImageContainer = ({ mediaSrc, blockNode }) => {
  const { updateSharedData, scrollContainerRef } = useContext(BlockSettingsContext);

  const captions = getCaptions();
  const blockId = view.accessors.getLiveElementId(blockNode);
  const isBackgroundPlaceholder = isPlaceholder(blockNode);

  const dispatch = useDispatch();

  const openSettings = useCallback(() => {
    dialogs.operations.show(dialogTypes.BLOCK_SETTINGS, {
      blockId,
      tabId: BLOCK_SETTINGS_APPEARANCE_TAB_ID,
    });
  }, [blockId]);

  const onCrop = () => {
    track(events.BLOCK_SETTINGS_CROP_BUTTON_CLICKED, {
      ...getDefaultTraits(blockId),
    });
    updateSharedData();
    customUI.operations.show(customUI.customUiTypes.EDIT_BACKGROUND_IMAGE_FOCAL_POINT, {
      elementId: blockId,
      onSubmit: openSettings,
      onCancel: openSettings,
      preserveSharedData: true,
      shouldApplyChangesOnSubmit: false,
    });
  };

  const onImageReplace = useCallback(
    async ({ source, isStockPhoto }) => {
      track(events.BLOCK_SETTINGS_NEW_BACKGROUND_IMAGE_SELECTED, {
        ...getDefaultTraits(blockId),
        source: isStockPhoto ? focalPointConstants.STOCK_PHOTOS : focalPointConstants.FILE_UPLOAD,
      });
      assets.operations.makePublishable(source);

      const originalMediaBounds = await assets.helpers.getImageSize(
        assets.helpers.addBaseHref(source)
      );

      // adjusting content scale so small images want be stretching to container width or height
      const adjustedScale = focalPointHelpers.computeAdjustedScale(
        focalPointHelpers.getImageContainerNode(blockNode),
        {
          originalMediaBounds,
          scale: focalPointConstants.DEFAULT_SCALE,
        }
      );

      const attributesOperations = focalPointHelpers.getReplaceBackgroundOperations({
        elementId: blockId,
        url: source,
        scale: adjustedScale,
        isPlaceholder: true,
      });

      if (isBackgroundPlaceholder) {
        attributesOperations.push({
          operation: REMOVE,
          name: BACKGROUND_PLACEHOLDER_ATTRIBUTE,
          elementId: blockId,
        });
      }

      const actions = attributesOperations.map((operation) =>
        convertAttributeOperationToAction({ ...operation, options: LIVE_STRATEGY_OPTIONS })
      );
      openSettings();

      dispatch(view.actions.bulkViewActions(actions, LIVE_STRATEGY_OPTIONS));
    },
    [blockId, blockNode, dispatch, isBackgroundPlaceholder, openSettings]
  );

  if (!mediaSrc || !blockId) return null;

  const onClick = () => {
    track(events.BLOCK_SETTINGS_REPLACE_MEDIA_BUTTON_CLICKED, {
      ...getDefaultTraits(blockId),
    });
    scrollContainerRef.current?.addEventListener(
      'scroll',
      () => {
        contextMenu.operations.hideContextMenu();
      },
      { once: true }
    );
  };

  const onTriggerUploadImageClick = () => {
    updateSharedData();
    track(events.BLOCK_SETTINGS_UPLOAD_FILE_BUTTON_CLICKED, {
      ...getDefaultTraits(blockId),
    });
  };

  const onTriggerBrowseStockPhotosClick = () => {
    updateSharedData();
    track(events.BLOCK_SETTINGS_BROWSE_STOCK_PHOTOS_BUTTON_CLICKED, {
      ...getDefaultTraits(blockId),
    });
  };

  const onWrongFileType = () => {
    dialogs.operations.show(dialogTypes.FILE_TYPE_ERROR_DIALOG, {
      onDismiss: openSettings,
    });
  };

  return (
    <BlockBackgroundImageOption
      captions={captions}
      blockId={blockId}
      backgroundUrl={addBaseHref(mediaSrc)}
      isBackgroundPlaceholder={isBackgroundPlaceholder}
      onCrop={onCrop}
      onClick={onClick}
      onImageReplace={onImageReplace}
      openSettings={openSettings}
      onTriggerUploadImageClick={onTriggerUploadImageClick}
      onTriggerBrowseStockPhotosClick={onTriggerBrowseStockPhotosClick}
      onWrongFileType={onWrongFileType}
      onImageUploadCancel={openSettings}
    />
  );
};

BlockBackgroundImageContainer.propTypes = {
  mediaSrc: PropTypes.string,
  blockNode: PropTypes.oneOfType([PropTypes.object, PropTypes.node]).isRequired,
};

BlockBackgroundImageContainer.defaultProps = {
  mediaSrc: '',
};

export default BlockBackgroundImageContainer;
