import React from 'react';
import PropTypes from 'prop-types';
import { mobile } from 'yola-bowser';
import classNames from 'classnames';
import { hdrm, i18next, utils } from '@yola/ws-sdk';
import { Modal, Panel, PanelGroup, designSystem } from '@yola/ws-ui';
import detectModalWidth from '../helpers/detect-modal-width';
import sizes from '../constant/color-settings-sizes';

const {
  DialogButton,
  DialogButtonGroup,
  DialogButtonGroupSeparator,
  DialogHeader,
  DialogHeaderTitle,
  ColorPicker,
  ColorCircle,
  HorizontalStack,
  Divider,
  Box,
  Stack,
  Heading,
} = designSystem;

const ColorSettingsDialog = ({
  color,
  onColorChange,
  onCancel,
  onSubmit,
  position,
  captions,
  displayWarning,
  onColorChangeComplete,
  onDragEnd,
  overlay,
  isColorPaletteConfigLoading,
  enableAlpha,
  predefinedColors,
  onPredefinedColorClick,
}) => {
  const modalWidth = detectModalWidth();

  const attributes = { [hdrm.constants.attributes.DISABLE_BLUR_ATTR]: true };

  return (
    <Modal
      handleSubmit={onSubmit}
      handleCancel={onCancel}
      height="auto"
      attributes={attributes}
      width={modalWidth}
      offsetX={position.offsetX}
      offsetY={position.offsetY}
      draggable={!mobile}
      fullscreen={mobile}
      overlay={overlay}
      resizable={false}
      onDragEnd={onDragEnd}
      dragHandleSelector=".ws-color-settings-header"
    >
      <PanelGroup height="100%">
        <Panel
          height={sizes.MODAL_HEADER_HEIGHT}
          align="middle"
          corners="squared"
          style={{ flexShrink: '0' }}
          className="ws-color-settings-header"
        >
          <DialogHeader>
            <DialogHeaderTitle>{captions.title}</DialogHeaderTitle>
          </DialogHeader>
        </Panel>

        <Panel height="100%" scrollable style={{ borderBottom: 'none' }}>
          <ColorPicker
            color={color}
            onChange={onColorChange}
            onChangeComplete={onColorChangeComplete}
            enableAlpha={enableAlpha}
            {...(enableAlpha && { alphaTooltipLabel: captions.opacity })}
            {...(displayWarning && { notificationTitle: captions.warning })}
          />
          {!!predefinedColors.length && (
            <>
              <Divider />
              <Box
                paddingTop="spacing-2-xs"
                paddingBottom="spacing-s"
                paddingLeft="spacing-m"
                paddingRight="spacing-m"
              >
                <Stack gap="spacing-2-xs">
                  <Heading type="heading-6">{i18next.t('Website colors')}</Heading>
                  <div
                    className={classNames('ws-color-settings-used-colors', {
                      'ws-color-settings-used-colors--mobile': mobile,
                    })}
                  >
                    <HorizontalStack rowGap="spacing-3-xs" gap="spacing-3-xs" wrap>
                      {predefinedColors.map(({ value, opacity }) => (
                        <div
                          key={`${value}-${opacity}`}
                          className="ws-color-settings-used-colors__item"
                          onClick={() => {
                            onPredefinedColorClick({ hslString: value, opacity });
                          }}
                        >
                          <ColorCircle color={value} opacity={opacity} />
                        </div>
                      ))}
                    </HorizontalStack>
                  </div>
                </Stack>
              </Box>
            </>
          )}
        </Panel>
        <Panel style={{ flexShrink: '0' }}>
          <DialogButtonGroup>
            <DialogButton isLoading={isColorPaletteConfigLoading} onClick={onSubmit}>
              {captions.apply}
            </DialogButton>
            <DialogButtonGroupSeparator />
            <DialogButton onClick={onCancel}>{captions.cancel}</DialogButton>
          </DialogButtonGroup>
        </Panel>
      </PanelGroup>
    </Modal>
  );
};

ColorSettingsDialog.propTypes = {
  color: PropTypes.shape({}).isRequired,
  onColorChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  position: PropTypes.shape({
    offsetX: PropTypes.number.isRequired,
    offsetY: PropTypes.number.isRequired,
  }).isRequired,
  isColorPaletteConfigLoading: PropTypes.bool,
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    warning: PropTypes.string.isRequired,
    apply: PropTypes.string.isRequired,
    cancel: PropTypes.string.isRequired,
    opacity: PropTypes.string.isRequired,
  }).isRequired,
  overlay: PropTypes.string,
  displayWarning: PropTypes.bool.isRequired,
  enableAlpha: PropTypes.bool,
  onColorChangeComplete: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  predefinedColors: PropTypes.array,
  onPredefinedColorClick: PropTypes.func,
};

ColorSettingsDialog.defaultProps = {
  overlay: 'none',
  isColorPaletteConfigLoading: false,
  enableAlpha: false,
  predefinedColors: [],
  onPredefinedColorClick: utils.noop,
};

export default ColorSettingsDialog;
