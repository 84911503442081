import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { dialogs, assets, view } from '@yola/ws-sdk';
import { FileTrigger, Icon } from '@yola/ws-ui';
import UploadImageContainer from 'src/js/modules/common/containers/upload-image-container';
import Trigger from 'src/js/modules/common/components/trigger';
import imageTypes from 'src/js/modules/extensions/common/constants/image-types';

function ReplaceImageTriggerContainer(props) {
  const { id, elementId } = props;
  const { setElementAttribute, removeElementAttribute, bulkViewOperations } = view.operations;

  const finishImageUpload = useCallback(
    async ({ source, file: imageFile }) => {
      // we need to pre-load image to omit blinking
      const { width: imageWidth } = await assets.helpers.getImageSize(
        assets.helpers.addBaseHref(source)
      );

      // make changes to both static and live dom
      const operations = [[setElementAttribute, [elementId, 'src', source]]];

      // when user upload svg logo we can't get it's size, so we need to set some default width in order
      // to prevent image from collapsing so user can't select it anymore
      if (imageFile.type === imageTypes.IMAGE_TYPE_SVG) {
        const svgWidth = Math.min(imageWidth, 200) || 200;
        operations.push([setElementAttribute, [elementId, 'width', svgWidth]]);
      } else {
        operations.push([removeElementAttribute, [elementId, 'width']]);
      }

      bulkViewOperations(operations);
      dialogs.operations.hide();
    },
    [bulkViewOperations, removeElementAttribute, setElementAttribute, elementId]
  );

  return (
    <UploadImageContainer onImageUploadEnd={finishImageUpload}>
      {({ onImageUpload, onWrongFileType }) => (
        <FileTrigger
          id="file-trigger"
          name="file-trigger"
          accept={imageTypes.IMAGE_ACCEPT_TYPE}
          onWrongTypeFile={onWrongFileType}
          onChange={onImageUpload}
        >
          <Trigger id={id}>
            <Icon glyph="image" size="20" strokeWidth="1.5" />
          </Trigger>
        </FileTrigger>
      )}
    </UploadImageContainer>
  );
}

ReplaceImageTriggerContainer.propTypes = {
  elementId: PropTypes.string,
  id: PropTypes.string.isRequired,
};

ReplaceImageTriggerContainer.defaultProps = {
  elementId: null,
};

export default ReplaceImageTriggerContainer;
