import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEqual from 'lodash.isequal';
import {
  anodum,
  view,
  hdrm,
  extensions,
  dialogs,
  constraints,
  i18next,
  textFormatting,
  interrupter,
  blocks,
  template,
} from '@yola/ws-sdk';
import { Icon, Tooltip } from '@yola/ws-ui';
import customUI from 'src/js/modules/custom-ui';
import withFeatureFlags from 'yola-editor/src/js/modules/feature-flags/hoc/with-feature-flags';
import contextMenuDisplayedTracker from 'src/js/modules/context-menu/helpers/context-menu-displayed-tracker';
import filteredBlocksContextMenuGroups from 'src/js/modules/control-pane/helpers/filter-blocks-context-menu-groups';
import getParentBlockByElement from 'src/js/modules/blocks/helpers/get-parent-block-by-element';

import highlighter from '../../highlighter';
import onlinestore from '../../onlinestore';
import contextMenu from '../../context-menu';
import customTools from '../../custom-tools';
import dialogTypes from '../../dialogs/constants/dialog-types';
import actions from '../actions';
import ControlPane from '../components/control-pane';
import Trigger from '../../common/components/trigger';
import getSameBoundsElements from '../helpers/get-same-bounds-element';
import getPaneLocationForElement from '../helpers/get-pane-location-for-element';
import getCurrentElement from '../helpers/get-current-element';
import getPaneLocationForTextSelection from '../helpers/get-pane-location-for-text-selection';
import getClosestInteractiveOnHoverElement from '../helpers/get-closest-interactive-on-hover-element';
import DragTriggerContainer from '../../drag-n-drop/containers/drag-trigger-container';
import isEmptySpaceHovered from '../../blocks/helpers/is-empty-space-hovered';
import webSettingsConfig from '../../website-settings/constants/settings-config';
import getPaneMaxItems from '../helpers/get-pane-max-items';
import getElementControlType from '../helpers/get-element-control-type';
import isControlAlreadyExist from '../helpers/is-control-already-exist';
import isTouchSupport from '../helpers/is-touch-support';
import TriggerEventTracker from '../../utils/trigger-event-tracker';
import getAlignTriggers from '../helpers/get-align-triggers';
import getMoveBlockTriggers from '../helpers/get-move-block-triggers';
import calculateMenuPosition from '../helpers/calculate-context-menu-position';
import shouldItemBeAutoActivated from '../helpers/should-item-be-auto-activated';
import { MAX_PANE_ITEMS, TEXT_SELECTION_MAX_PANE_ITEMS } from '../constants/pane-items';
import { PANE_TRIGGER_SIZE } from '../constants/sizes';
import {
  CONSTRAINT,
  CONSTRAINT_FOR_SELECTED,
  SETTING,
  CONTEXT_MENU,
  ELEMENT_SETTINGS,
} from '../constants/item-types';
import {
  DRAG_TRIGGER_ID,
  DUPLICATE_TRIGGER_ID,
  DELETE_TRIGGER_ID,
  WEBSITE_SETTINGS_TRIGGER_ID,
  ALIGN_TRIGGER_ID,
  MORE_ID,
  ALIGN_CENTER_TRIGGER_ID,
  ALIGN_LEFT_TRIGGER_ID,
  ALIGN_RIGHT_TRIGGER_ID,
  HIDE_TRIGGER_ID,
  ELEMENT_SETTINGS_TRIGGER_ID,
} from '../constants/trigger-ids';
import shouldRenderLabeledDragTrigger from '../../drag-n-drop/helpers/should-render-labeled-drag-trigger';
import getDragTriggerContainerLabeledProps from '../../drag-n-drop/helpers/get-drag-trigger-container-labeled-props';
import getConstraintsDataFromSameBoundsElements from '../helpers/get-constraints-data-from-same-bounds-elements';
import { shouldControlDisplayInControlPane } from '../helpers/should-control-display-in';
import filterControlsOfContextMenuGroupsByDisplayProperties from '../helpers/filter-controls-of-context-menu-groups-by-display-properties';

const { controlTypes } = extensions;
const { getClosestSelectableElement } = hdrm.helpers;

const RE_RENDER_DELAY = 250;
const HIDE_PANE_DELAY = 50;
const CONTEXT_MENU_GROUP_NAME = 'context-menu';

class ControlPaneContainer extends React.Component {
  static prepareControlComponent(elementId, component, getContext) {
    if (typeof component.type === 'function') {
      return React.cloneElement(component, { elementId, getContext });
    }

    return component;
  }

  static prepareContextMenuGroups(items, element) {
    if (!items.length) return [];

    return [
      {
        name: CONTEXT_MENU_GROUP_NAME,
        element,
        matches: () => true,
        items: items.map(({ id, title, trigger, priority, onTriggerClick, active }) => ({
          id,
          caption: title,
          glyph: ControlPaneContainer.getGlyphFromTrigger(trigger),
          onClick: (e) => {
            contextMenu.operations.hideContextMenu();
            onTriggerClick(e);
          },
          active,
          order: priority * -1,
        })),
      },
    ];
  }

  static getGlyphFromTrigger(TriggerComponent) {
    if (!TriggerComponent) return null;

    const { children: IconComponent } = TriggerComponent.props;

    if (!IconComponent) return null;

    const { glyph } = IconComponent.props;

    return glyph;
  }

  constructor(props) {
    super(props);

    this.state = {
      offsetX: null,
      offsetY: null,
      direction: null,
      expand: null,
      items: null,
      targetElement: null,
      prevSameBoundsElements: [],
      hoveredElementId: null,
      selectedElementId: null,
      focusedElementId: null,
      isDeleteTriggered: false,
      isHideTriggered: false,
      maxItems: MAX_PANE_ITEMS,
    };

    this.mousePositionInElement = null;
    this.scrollPosition = null;
    this.activeIndex = null;
    this.isActive = false;
    this.isCollapsed = true;
    this.prevHighlightedElements = [];
    this.isTouchDevice = isTouchSupport();
    this.controlPaneRef = React.createRef();
    this.moreRef = React.createRef();
    this.savedViewportHeight = null;
    this.isCustomToolActive = false;

    const { DISABLE_BLUR_ATTR } = hdrm.constants.attributes;
    this.attributes = {
      [DISABLE_BLUR_ATTR]: true,
    };

    this.updateComponentState = this.updateComponentState.bind(this);
    this.onActiveStateChange = this.onActiveStateChange.bind(this);
    this.prepareExtension = this.prepareExtension.bind(this);
    this.clearTimer = this.clearTimer.bind(this);
    this.updatePane = this.updatePane.bind(this);
    this.resetActiveFlag = this.resetActiveFlag.bind(this);
    this.cancelPointerEvents = this.cancelPointerEvents.bind(this);
    this.restorePointerEvents = this.restorePointerEvents.bind(this);
    this.hideHighlighter = this.hideHighlighter.bind(this);
    this.showHighlighter = this.showHighlighter.bind(this);
    this.updateStateForElement = this.updateStateForElement.bind(this);
    this.updateStateForTextSelection = this.updateStateForTextSelection.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onTriggerActionStart = this.onTriggerActionStart.bind(this);
    this.updateArrowPosition = this.updateArrowPosition.bind(this);
    this.forceHighlighterRerender = this.forceHighlighterRerender.bind(this);
    this.forceControlPaneRerender = this.forceControlPaneRerender.bind(this);
  }

  componentDidUpdate() {
    if (this.shouldRerenderPane()) {
      this.updatePane();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  onActiveStateChange(index) {
    /**
     * `isActive` means that control pane is expanded
     */
    this.isActive = index !== null;

    if (this.isActive && !this.shouldRerenderPane()) {
      this.updateArrowPosition(index);
    } else if (this.activeIndex !== index) {
      this.forceControlPaneRerender();
    }

    this.activeIndex = index;
    Tooltip.hide();
  }

  onMouseLeave() {
    this.oldTargetElement = this.currentTargetElement;
  }

  onTriggerActionStart(trigger) {
    // When we're clicking on "next" or "previous" triggers,
    // or close header, or alignment triggers
    // focused element loses it's focus and we should focus it manually
    const { focusedElementId } = this.props;

    if (!focusedElementId || !trigger) {
      return;
    }

    if (
      trigger.prevTrigger ||
      trigger.nextTrigger ||
      trigger.closeHeaderTrigger ||
      trigger.id.startsWith(ALIGN_TRIGGER_ID)
    ) {
      const focusedElement = view.accessors.getLiveElement(focusedElementId);
      focusedElement.setAttribute('contenteditable', 'true');
      focusedElement.focus();
    }
  }

  getControlPaneInfo() {
    const { offsetX, offsetY, direction } = this.state;
    const { scrollPosition } = this.props;
    const element = this.controlPaneRef.current;

    return {
      scrollPosition,
      controlPane: {
        element,
        direction,
        position: {
          x: offsetX,
          y: offsetY,
        },
        forceControlPaneRerender: this.forceControlPaneRerender,
        forceHighlighterRerender: this.forceHighlighterRerender,
      },
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      hoveredElementId,
      selectedElementId,
      focusedElementId,
      pageContainerSelector,
      isLoaded,
    } = nextProps;
    let newState = {};

    // Why isLoaded is so important?(For better understanding you have to know how SyncyFrame component works)
    //
    // When editor reloads\changes page, we have some time(amount depends on your internet connection)
    // to iterate with elements on the page, which will be changed soon. And in that cases, if we
    // call any matches function from extensions, we will face the trouble that our element's document,
    // in some moment of time, will lose window context and editor will throw errors.
    // To prevent this behavior we added checking isLoaded status.
    if (!isLoaded) {
      return {
        hoveredElementId: null,
        selectedElementId: null,
        focusedElementId: null,
      };
    }

    if (
      (prevState.focusedElementId !== focusedElementId &&
        (prevState.focusedElementId === null || focusedElementId === null)) ||
      (prevState.selectedElementId !== selectedElementId &&
        (prevState.selectedElementId === null || selectedElementId === null))
    ) {
      newState = {
        ...newState,
        items: null,
        focusedElementId,
        selectedElementId,
      };
    }

    if (selectedElementId && prevState.selectedElementId !== selectedElementId) {
      const targetElement = view.accessors.getLiveElement(selectedElementId);

      if (targetElement) {
        newState = {
          ...newState,
          targetElement,
          hoveredElementId: null,
          selectedElementId,
        };
      }
    }

    if (focusedElementId && focusedElementId !== prevState.focusedElementId) {
      const targetElement = view.accessors.getLiveElement(focusedElementId);

      if (targetElement) {
        newState = {
          ...newState,
          targetElement,
          hoveredElementId: null,
          focusedElementId,
        };
      }
    }

    if (
      !focusedElementId &&
      !selectedElementId &&
      hoveredElementId !== prevState.hoveredElementId
    ) {
      let targetElement;

      if (hoveredElementId) {
        const hoveredElement = view.accessors.getLiveElement(hoveredElementId);
        targetElement = getClosestInteractiveOnHoverElement(hoveredElement);
      }

      if (
        !hoveredElementId ||
        !targetElement ||
        (targetElement && targetElement !== prevState.targetElement)
      ) {
        return {
          ...newState,
          targetElement,
          hoveredElementId,
          selectedElementId: null,
          focusedElementId: null,
        };
      }

      if (isEmptySpaceHovered(hoveredElementId, pageContainerSelector)) {
        return {
          targetElement: null,
          hoveredElementId: null,
          selectedElementId: null,
          focusedElementId: null,
        };
      }
    }

    if (!focusedElementId && !selectedElementId && !hoveredElementId) {
      return {
        targetElement: null,
        items: null,
        hoveredElementId,
        selectedElementId,
        focusedElementId,
      };
    }

    return Object.keys(newState).length ? newState : null;
  }

  getLiveDocument() {
    const targetDocument = view.accessors.getLiveDocument();
    if (!this.targetDocuments || this.targetDocuments[0] !== targetDocument) {
      this.targetDocuments = [targetDocument];
    }
    return this.targetDocuments;
  }

  getItemsForConstraints(constraintSameBoundsElements, isMergedWithTextTriggers = false) {
    const itemList = [];

    if (constraintSameBoundsElements && constraintSameBoundsElements.length) {
      const { duplicateElement, deleteElement } = this.props;
      const {
        dragConstraintData,
        cloneConstraintData,
        removeConstraintData,
        moveConstraintData,
        alignConstraintData,
        hideConstraintData,
      } = getConstraintsDataFromSameBoundsElements(constraintSameBoundsElements);

      if (alignConstraintData) {
        const { element, constraint } = alignConstraintData;
        const targetElement = constraints.accessors.getTargetElement(element, constraint);

        const { showHighlighter, hideHighlighter, forceHighlighterRerender } = this;
        const alignTriggers = getAlignTriggers({
          element: targetElement,
          showHighlighter,
          hideHighlighter,
          afterTriggerClick: () => {
            this.forceControlPaneRerender();
            forceHighlighterRerender();
          },
        });

        itemList.push(...alignTriggers);
      }

      if (dragConstraintData) {
        const { element, constraint, type } = dragConstraintData;
        const lowPriorityValue = 60;
        const highPriorityValue = 200;
        const targetElement = constraints.accessors.getTargetElement(element, constraint);
        const targetElementRefId = view.accessors.getLiveElementId(targetElement);
        const dragItemDefaultProperties = {
          id: DRAG_TRIGGER_ID,
          element: targetElement,
          tooltip: i18next.t('Move'),
          title: i18next.t('Move'),
          onTriggerClick: () => {},
          onHover: this.showHighlighter.bind(this, targetElement),
          onMouseLeave: this.hideHighlighter.bind(this, targetElement),
          type,
        };
        const isDragTriggerLabeled = shouldRenderLabeledDragTrigger(targetElementRefId);

        if (isDragTriggerLabeled) {
          const triggerWrapperClass =
            'ws-control-pane-triggers__trigger-wrapper--drag-trigger-labeled';
          const dragTriggerContainerLabeledProps = getDragTriggerContainerLabeledProps(
            targetElement,
            document,
            isMergedWithTextTriggers
          );

          itemList.push({
            ...dragItemDefaultProperties,
            trigger: (
              <DragTriggerContainer
                elementId={view.accessors.getLiveElementId(targetElement)}
                {...dragTriggerContainerLabeledProps}
              />
            ),
            priority: highPriorityValue,
            // If drag trigger is labeled, it has a variable width, so
            // we should pass this `width` prop to the control pane
            // separately, to let it calculate its whole size properly.
            width: dragTriggerContainerLabeledProps.width,
            triggerWrapperClass,
            hasDivider: !isMergedWithTextTriggers,
          });
        } else {
          itemList.push({
            ...dragItemDefaultProperties,
            trigger: (
              <DragTriggerContainer elementId={view.accessors.getLiveElementId(targetElement)} />
            ),
            priority: isMergedWithTextTriggers ? lowPriorityValue : highPriorityValue,
          });
        }
      }

      if (
        cloneConstraintData &&
        !onlinestore.verifiers.isOnlineStoreBlock(cloneConstraintData.element)
      ) {
        const { element, constraint, type } = cloneConstraintData;
        const targetElement = constraints.accessors.getTargetElement(element, constraint);

        itemList.push({
          id: DUPLICATE_TRIGGER_ID,
          element: targetElement,
          trigger: (
            <Trigger id={DUPLICATE_TRIGGER_ID}>
              <Icon size="20" glyph="copy" strokeWidth="1.3" />
            </Trigger>
          ),
          tooltip: i18next.t('Duplicate'),
          priority: 60,
          title: i18next.t('Duplicate'),
          onTriggerClick: () => {
            this.hideHighlighter();
            duplicateElement(view.accessors.getLiveElementId(targetElement));
            this.hidePane();
          },
          onHover: this.showHighlighter.bind(this, targetElement),
          onMouseLeave: this.hideHighlighter.bind(this, targetElement),
          type,
        });
      }

      if (removeConstraintData) {
        const { element, constraint, type } = removeConstraintData;
        const targetElement = constraints.accessors.getTargetElement(element, constraint);

        itemList.push({
          id: DELETE_TRIGGER_ID,
          element: targetElement,
          trigger: (
            <Trigger id={DELETE_TRIGGER_ID}>
              <Icon size="20" glyph="trash" strokeWidth="1.3" />
            </Trigger>
          ),
          tooltip: i18next.t('Delete'),
          priority: 40,
          title: i18next.t('Delete'),
          onTriggerClick: () => {
            deleteElement(view.accessors.getLiveElementId(targetElement));
            this.hideHighlighter();
            this.setState({
              isDeleteTriggered: true,
            });
            this.hidePane();
          },
          onHover: this.showHighlighter.bind(this, targetElement),
          onMouseLeave: this.hideHighlighter.bind(this, targetElement),
          type,
        });
      }

      if (moveConstraintData) {
        const { element, constraint } = moveConstraintData;
        const targetElement = constraints.accessors.getTargetElement(element, constraint);
        const moveBlockTriggers = getMoveBlockTriggers({
          element: targetElement,
          showHighlighter: this.showHighlighter.bind(this, targetElement),
          hideHighlighter: this.hideHighlighter.bind(this, targetElement),
        });

        itemList.push(...moveBlockTriggers);
      }

      if (hideConstraintData) {
        const { element, constraint, type } = hideConstraintData;
        const targetElement = constraints.accessors.getTargetElement(element, constraint);

        const blockElement = getParentBlockByElement(targetElement);
        const blockElementId = view.accessors.getLiveElementId(blockElement);
        const blockId = blocks.accessors.getBlockIdByElement(blockElement);
        const { optionalChildren } = blocks.accessors.getBlockSettings(blockId);
        let highlightedElements;

        const targetBlockSettings = optionalChildren.find((child) => {
          const elements = blockElement.querySelectorAll(child.querySelector);
          const elementsToHighlight = blockElement.querySelectorAll(child.highlighterQuerySelector);

          if (Array.from(elements).some((el) => el === targetElement)) {
            if (elementsToHighlight.length) {
              highlightedElements = Array.from(elementsToHighlight);
            } else {
              highlightedElements = Array.from(elements);
            }

            return true;
          }

          return false;
        });

        itemList.push({
          id: HIDE_TRIGGER_ID,
          element: targetElement,
          trigger: (
            <Trigger id={HIDE_TRIGGER_ID}>
              <Icon size="20" glyph="eye-off" strokeWidth="1.3" />
            </Trigger>
          ),
          tooltip: i18next.t('Hide'),
          priority: 40,
          title: i18next.t('Hide'),
          onTriggerClick: () => {
            const { showBlockSettingsDialog, blurElement, focusedElementId } = this.props;

            this.setState({
              isHideTriggered: true,
            });

            if (focusedElementId) {
              blurElement();
            }

            setTimeout(
              () =>
                showBlockSettingsDialog(blockElementId, blockId, blockElement, {
                  optionalChildren: {
                    [targetBlockSettings.id]: false,
                  },
                }),
              0
            );
          },
          onHover: this.showHighlighter.bind(this, highlightedElements),
          onMouseLeave: this.hideHighlighter.bind(this, highlightedElements),
          type,
        });
      }
    }

    return itemList;
  }

  async getItemsForHoveredElement(sameBoundsElements) {
    const constraintSameBoundsElements = sameBoundsElements.filter(
      (item) => item.type === CONSTRAINT
    );
    const elementSettingElements = sameBoundsElements.filter(
      (item) => item.type === ELEMENT_SETTINGS
    );
    const elements = [...new Set(sameBoundsElements.map(({ element }) => element))];
    const extensionsControls = elements
      .map((element) => ({
        controls: extensions.filters.findControlsFor(element, controlTypes.ELEMENT),
        element,
      }))
      .filter((item) => item.controls.length > 0)
      .flat();

    let itemList = [];

    if (constraintSameBoundsElements.length) {
      itemList = this.getItemsForConstraints(constraintSameBoundsElements);
    }

    if (elementSettingElements.length > 0) {
      itemList.push({
        id: ELEMENT_SETTINGS_TRIGGER_ID,
        trigger: (
          <Trigger id={ELEMENT_SETTINGS_TRIGGER_ID}>
            <Icon size="20" glyph="settings" strokeWidth="1.3" />
          </Trigger>
        ),
        tooltip: i18next.t('Settings'),
        priority: 40,
        title: i18next.t('Settings'),
        onTriggerClick: () => {
          this.hideHighlighter();
          dialogs.operations.show(dialogTypes.ELEMENT_SETTINGS_DIALOG, {
            elements: elementSettingElements.map((item) => item.element),
          });
          this.hidePane();
        },
        onHover: this.showHighlighter.bind(this, elementSettingElements[0].element),
        onMouseLeave: this.hideHighlighter.bind(this, elementSettingElements[0].element),
      });
    }

    itemList = sameBoundsElements.reduce(
      this.prepareExtension.bind(this, extensionsControls),
      itemList
    );

    return itemList;
  }

  getMatchedContextMenuGroups(sameBoundsElements) {
    const { registeredMenuGroups, featureFlags } = this.props;
    const { getMatchedMenuGroups, prepareMenuGroups } = contextMenu.helpers;
    const contextMenuItems = sameBoundsElements.filter((item) => item.type === CONTEXT_MENU);

    const matchedContextMenuItems = contextMenuItems.reduce((acc, contextMenuItem) => {
      const { element } = contextMenuItem;
      const matchedGroups = getMatchedMenuGroups(
        element,
        prepareMenuGroups(registeredMenuGroups),
        featureFlags
      );
      const uniqueMatchedGroups = matchedGroups.filter(
        (group) => !acc.some((existedGroup) => group.name === existedGroup.name)
      );

      return [...acc, ...uniqueMatchedGroups];
    }, []);
    return filteredBlocksContextMenuGroups(matchedContextMenuItems);
  }

  getItemsForMoreTrigger(itemsList, contextMenuGroups) {
    const { targetElement } = this.state;
    const { showContextMenu, hideContextMenu } = this.props;

    const preparedContextMenu =
      filterControlsOfContextMenuGroupsByDisplayProperties(contextMenuGroups);

    const { textAlignGroups, otherItems } = this.prepareTextAlignContextMenuGroups(itemsList);

    if (preparedContextMenu.length === 0 && textAlignGroups.length === 0) {
      return itemsList;
    }

    return [
      ...otherItems,
      {
        id: MORE_ID,
        trigger: (
          <Trigger id={MORE_ID} ref={this.moreRef}>
            <Icon size="20" glyph="more-horizontal" strokeWidth="1.3" />
          </Trigger>
        ),
        onMouseDown: (e) => e.stopPropagation(),
        onTriggerClick: () => {
          const { offsetY, direction } = this.state;
          const { menu } = this.props;

          if (menu.triggerId === MORE_ID) {
            hideContextMenu();
            return;
          }

          const groups = [...preparedContextMenu, ...textAlignGroups];
          const { triggerRef } = this.moreRef.current;
          const { left } = triggerRef.current.getBoundingClientRect();
          const position = { x: left, y: offsetY };

          const calculatePositionDecorator = (menuElement, x, y) =>
            calculateMenuPosition({
              menuElement,
              controlPanePosition: {
                x,
                y,
              },
              direction,
            });

          showContextMenu({
            position,
            groups,
            triggerId: MORE_ID,
            calculatePosition: calculatePositionDecorator,
          });
          contextMenuDisplayedTracker({ triggerId: MORE_ID, targetElement });
        },
        tooltip: `${i18next.t('More')}...`,
        priority: 1,
        title: `${i18next.t('More')}...`,
        element: targetElement,
      },
    ];
  }

  async getItemsForSelectedElement(sameBoundsElements, isMergedWithTextTriggers = false) {
    const { onWebsiteSettings } = this.props;

    let itemList = [];

    const constraintSameBoundsElements = sameBoundsElements.filter(
      (item) => item.type === CONSTRAINT_FOR_SELECTED
    );
    const settings = sameBoundsElements.find((item) => item.type === SETTING);
    const elements = [...new Set(sameBoundsElements.map(({ element }) => element))];
    const extensionsControls = elements
      .map((element) => ({
        controls: extensions.filters.findControlsFor(element, controlTypes.ELEMENT),
        element,
      }))
      .filter((item) => item.controls.length > 0)
      .flat();
    const contextMenuGroups = this.getMatchedContextMenuGroups(sameBoundsElements);

    if (constraintSameBoundsElements.length) {
      itemList = this.getItemsForConstraints(
        constraintSameBoundsElements,
        isMergedWithTextTriggers
      );
    }

    itemList = sameBoundsElements.reduce(
      this.prepareExtension.bind(this, extensionsControls),
      itemList
    );

    itemList = this.getItemsForMoreTrigger(itemList, contextMenuGroups);

    if (settings) {
      const { element } = settings;
      const settingName = template.accessors.getWebsiteVariableByElement(element);
      const setting = webSettingsConfig.find((item) => item.name === settingName);
      const targetElement = settings ? settings.element : sameBoundsElements[0].element;

      if (setting && setting.hasTrigger) {
        itemList.push({
          id: WEBSITE_SETTINGS_TRIGGER_ID,
          element,
          trigger: (
            <Trigger id={WEBSITE_SETTINGS_TRIGGER_ID}>
              <Icon size="20" glyph={setting.glyphName} strokeWidth="1.3" />
            </Trigger>
          ),
          tooltip: setting.title,
          priority: 60,
          title: setting.title,
          onTriggerClick: () => {
            onWebsiteSettings(setting.tab, setting.tabIndex, setting.name);
            this.hidePane();
          },
          onHover: this.showHighlighter.bind(this, targetElement),
          onMouseLeave: this.hideHighlighter.bind(this, targetElement),
        });
      }
    }

    return itemList;
  }

  async getItemsForTextSelection(element) {
    const { focusedElementId, selection } = this.props;
    const { targetElement } = this.state;
    const controlType = getElementControlType(focusedElementId, selection);
    const controlsList = extensions.filters.findControlsFor(element, controlType);

    let itemList = this.prepareExtension([{ controls: controlsList, element }], [], { element });

    if (controlType !== controlTypes.COLLAPSED_TEXT) {
      return itemList;
    }

    // populate items list with items for selected element
    // ex., widget text, alignment
    const selectableElement = getClosestSelectableElement(targetElement);

    if (selectableElement) {
      const { sameBoundsElements } = getSameBoundsElements(element);
      const sameBoundsSelectedItem = sameBoundsElements.find(
        ({ element: itemElement }) => itemElement === selectableElement
      );

      if (sameBoundsSelectedItem) {
        // Triggers of selectable element should be merged on UI with text
        // controls only when there is a `text-element-type` control included
        const isMergedWithTextTriggers = itemList.some((item) => item.id === 'text-element-type');
        const itemsForSelectedElement = await this.getItemsForSelectedElement(
          sameBoundsElements,
          isMergedWithTextTriggers
        );
        const filteredItemsForSelectedElement = itemsForSelectedElement.filter(
          (item) => !isControlAlreadyExist(item, itemList)
        );

        itemList = [...itemList, ...filteredItemsForSelectedElement];
      }
    }

    return itemList;
  }

  updateArrowPosition(index) {
    const { targetElement: element, items } = this.state;
    const { scrollPosition } = this.props;
    // Recalculate an arrow position if header is opened

    if (!items) return;
    const header = items[index] && items[index].header;

    // Empty object immulates a trigger for closing
    const activeItems = header ? [...header, {}] : items;

    const { arrowOffsetX, arrowOffsetY } = getPaneLocationForTextSelection({
      element,
      items: activeItems,
      scrollPosition,
    });

    this.setState({ arrowOffsetX, arrowOffsetY });
  }

  updatePane() {
    const { selection, highlightedElements, isScrolling } = this.props;
    const { targetElement, isDeleteTriggered } = this.state;

    this.oldTargetElement = targetElement;

    if (highlightedElements.length && !isScrolling) {
      this.prevHighlightedElements = highlightedElements;
    }

    if (this.shouldUpdatePanePosition && this.shouldSkipPositionUpdate) return;

    if (this.shouldUpdatePaneAfterScroll) {
      this.updateComponentState();
      this.shouldUpdatePaneAfterScroll = false;
      return;
    }

    this.clearTimer();

    let delay = this.isBoundsChanged ? 0 : HIDE_PANE_DELAY / 2;

    if (this.shouldUpdatePanePosition) {
      this.timer = setTimeout(() => {
        this.currentTargetElement = targetElement;
        this.updateComponentState();
      }, delay);
      return;
    }

    const shouldRerenderForText = this.isCollapseChanged && selection;
    if (shouldRerenderForText) this.shouldSkipPositionUpdate = true;
    delay = isDeleteTriggered || shouldRerenderForText ? HIDE_PANE_DELAY : RE_RENDER_DELAY;

    this.timer = setTimeout(() => {
      /**
       * `currentTargetElement` is needed to fix wrong behavior
       * described in https://github.com/yola/ws-editor/issues/374
       *
       * We need to store an element which control pane is bound to.
       * We could use `targetElement` but it changes it’s value while user is
       * moving cursor from the target element to control pane so the original
       * value becomes lost.
       */
      this.currentTargetElement = targetElement;
      this.forceControlPaneRerender();
      this.shouldSkipPositionUpdate = false;
    }, delay);
  }

  shouldHideControlPane() {
    const { isDialogVisible, dialogState, customUIVisibility, activeCustomTool } = this.props;
    const isCustomToolActive = Boolean(activeCustomTool);

    return (
      customUIVisibility ||
      isCustomToolActive ||
      (isDialogVisible && !dialogState.modalProps?.preserveControlPane)
    );
  }

  /**
   * ControlPane should be re-render in next cases:
   *   - when element is focused (just text elements can be focused) and control pane is visible
   *   - when focused element is losing focus
   *   - when focused element is first on the website and mouse position changes
   *   - when focused element's height was changed
   *   - when interactive element is selected
   *   - when ControlPane is not expanded and:
   *     - when closest interactive element for hovered element is changed
   *     - when page was scrolled
   *   - when element is focused or selected and state of active custom tool is changed
   */
  shouldRerenderPane() {
    const { targetElement, items, focusedElementId, selectedElementId, isHideTriggered } =
      this.state;
    const {
      scrollPosition,
      isScrolling,
      isDisabledStatus,
      selection,
      viewportHeight,
      activeCustomTool,
    } = this.props;

    this.shouldUpdatePanePosition = false;
    this.isCollapseChanged = false;
    this.isBoundsChanged = false;

    if (isDisabledStatus) return false;

    const isCustomToolActive = Boolean(activeCustomTool);
    const isCustomToolActiveStateChanged = this.isCustomToolActive !== isCustomToolActive;
    this.isCustomToolActive = isCustomToolActive;

    if (isScrolling && scrollPosition !== this.scrollPosition && !isHideTriggered) {
      this.shouldUpdatePaneAfterScroll = true;
      this.scrollPosition = scrollPosition;
      return true;
    }

    if (this.shouldHideControlPane()) {
      this.resetActiveFlag();
      if (items && items.length) {
        this.hidePane();
      }
      return false;
    }

    if (this.isActive) return false;

    // fix for android tablet
    // (on some android tablets vh value is changed after the keyboard open
    // and after keyboard open some extra time is needed to reflow elements due to the changed 100vh value;
    // that's why the highlighter position may be calculated wrong)
    // https://github.com/yola/production/issues/9229
    if (this.savedViewportHeight !== viewportHeight) {
      this.savedViewportHeight = viewportHeight;
      this.shouldUpdatePanePosition = true;
      return true;
    }

    if (focusedElementId && selection) {
      if (this.isCollapsed !== selection.isCollapsed) {
        this.isCollapsed = selection.isCollapsed;
        this.isCollapseChanged = true;
        return true;
      }

      if (!isEqual(this.selection, selection)) {
        this.selection = selection;
        this.shouldUpdatePanePosition = true;
        return true;
      }
    }

    const elementWasChanged = this.oldTargetElement !== targetElement;

    if (elementWasChanged) {
      this.targetElementBoundsHeight = null;
      this.targetElementBoundsTop = null;
    }

    if (targetElement && (focusedElementId || selectedElementId)) {
      const targetElementBounds = targetElement.getBoundingClientRect();

      if (
        this.targetElementBoundsHeight &&
        (this.targetElementBoundsHeight !== targetElementBounds.height ||
          this.targetElementBoundsTop !== targetElementBounds.top)
      ) {
        this.targetElementBoundsHeight = targetElementBounds.height;
        this.targetElementBoundsTop = targetElementBounds.top;
        this.isBoundsChanged = true;
        this.shouldUpdatePanePosition = true;
        return true;
      }

      this.targetElementBoundsHeight = targetElementBounds.height;
      this.targetElementBoundsTop = targetElementBounds.top;

      if (isCustomToolActiveStateChanged) {
        return true;
      }
    }

    return elementWasChanged;
  }

  prepareTextAlignContextMenuGroups(itemsList) {
    const { targetElement } = this.state;
    const textAlignTriggers = [
      ALIGN_RIGHT_TRIGGER_ID,
      ALIGN_LEFT_TRIGGER_ID,
      ALIGN_CENTER_TRIGGER_ID,
    ];

    // we should move alignment triggers to "more..." only for text blocks
    if (!constraints.verifiers.canBeRichEdited(targetElement)) {
      return {
        otherItems: itemsList,
        textAlignGroups: [],
      };
    }

    const { textAlignItems, otherItems } = itemsList.reduce(
      (collection, item) => {
        if (textAlignTriggers.includes(item.id)) {
          collection.textAlignItems.push(item);
        } else {
          collection.otherItems.push(item);
        }

        return collection;
      },
      { otherItems: [], textAlignItems: [] }
    );

    const textAlignGroups = ControlPaneContainer.prepareContextMenuGroups(
      textAlignItems,
      targetElement
    );

    return {
      otherItems,
      textAlignGroups,
    };
  }

  prepareExtension(extensionsControlsList, itemsList, item) {
    const { featureFlags } = this.props;
    const { element, type } = item;

    extensionsControlsList.forEach((extensionControl) => {
      if (element !== extensionControl.element) return;

      const elementId = view.accessors.getLiveElementId(element);

      extensionControl.controls.forEach(({ header, ...controlConfig }) => {
        if (isControlAlreadyExist(controlConfig, itemsList)) return;
        if (!shouldControlDisplayInControlPane(controlConfig, featureFlags)) return;

        const controlItem = { ...controlConfig, type, element };

        // 'highlightElement' property is optional in controls. By default it should be "true".
        if (controlItem.highlightElement === undefined) {
          controlItem.highlightElement = true;
        }

        controlItem.trigger = ControlPaneContainer.prepareControlComponent(
          elementId,
          controlItem.trigger,
          this.getControlPaneInfo.bind(this)
        );

        if (header) {
          const headerItems = typeof header === 'function' ? header(elementId) : header;

          controlItem.header = headerItems.map((headerItem) =>
            ControlPaneContainer.prepareControlComponent(
              elementId,
              headerItem,
              this.getControlPaneInfo.bind(this)
            )
          );

          controlItem.activeWidth =
            controlItem.activeWidth || (headerItems.length + 1) * PANE_TRIGGER_SIZE;
        }

        if (controlItem.body) {
          controlItem.body = controlItem.body.map((bodyItem) =>
            ControlPaneContainer.prepareControlComponent(
              elementId,
              bodyItem,
              this.getControlPaneInfo.bind(this)
            )
          );
        }

        if (controlItem.onTriggerClick) {
          // Following logic of trigger handler extension should be replaced after the implementation of image gallery extension
          // Ticket: https://github.com/yola/ws-editor/issues/297
          if (controlItem.trigger.props.name === 'file-trigger') {
            const trigger = controlItem.onTriggerClick;
            controlItem.onTriggerClick = () => {
              this.cancelPointerEvents();
              trigger.call(controlItem, elementId, {
                getContext: this.getControlPaneInfo.bind(this),
              });
            };
          } else {
            controlItem.onTriggerClick = controlItem.onTriggerClick.bind(controlItem, elementId, {
              getContext: this.getControlPaneInfo.bind(this),
            });
          }
        }

        if (controlItem.highlightElement) {
          if (typeof controlItem.highlightElement === 'function') {
            const elementToHighlight = controlItem.highlightElement(element);
            controlItem.onHover = this.showHighlighter.bind(this, elementToHighlight || element);
            controlItem.onMouseLeave = this.hideHighlighter.bind(
              this,
              elementToHighlight || element
            );
          } else {
            controlItem.onHover = this.showHighlighter.bind(this, element);
            controlItem.onMouseLeave = this.hideHighlighter.bind(this, element);
          }
        }

        itemsList.push(controlItem);
      });
    });

    return itemsList;
  }

  clearTimer() {
    clearTimeout(this.timer);
  }

  hidePane(cb) {
    const { hideContextMenu } = this.props;
    this.resetActiveFlag();
    hideContextMenu();
    this.setState(
      {
        showPane: false,
        offsetX: null,
        offsetY: null,
        direction: null,
        dropdown: null,
        expand: null,
        items: null,
        maxPaneWidth: null,
        arrowOffsetX: null,
        arrowOffsetY: null,
      },
      cb
    );
  }

  updateComponentState() {
    const { targetElement, focusedElementId } = this.state;
    const { selection } = this.props;
    const liveDocument = view.accessors.getLiveDocument();

    if ((liveDocument && !liveDocument.defaultView) || !targetElement) return;
    const isTextSelected = focusedElementId && selection;
    if (isTextSelected) {
      this.updateStateForTextSelection();
    } else {
      this.updateStateForElement();
    }
  }

  async updateStateForElement() {
    const {
      targetElement,
      prevSameBoundsElements,
      showPane,
      items: currentItems,
      selectedElementId,
    } = this.state;
    const { scrollPosition, setControlPaneLocation, featureFlags, hideContextMenu } = this.props;
    const { sameBoundsElements, targetElementBounds } = getSameBoundsElements(targetElement);

    if (!sameBoundsElements.length) {
      if (showPane) this.hidePane();
      return;
    }

    const isSameBounds = sameBoundsElements.every((element) =>
      prevSameBoundsElements.includes(element)
    );

    if (isSameBounds) return;

    let items;

    if (selectedElementId) {
      items = await this.getItemsForSelectedElement(sameBoundsElements);

      // if control pane contains only one extension trigger
      // do not show control pane, activate item automatically
      if (items.length === 1) {
        const [itemToActivate] = items;

        if (shouldItemBeAutoActivated(itemToActivate, targetElement, sameBoundsElements)) {
          if (showPane) this.hidePane();
          itemToActivate.onTriggerClick();
          return;
        }
      }
    } else {
      items = await this.getItemsForHoveredElement(sameBoundsElements);
    }

    if (!items.length || (currentItems && items.length !== currentItems.length)) {
      if (showPane) this.hidePane();
      return;
    }

    const element = selectedElementId ? targetElement : getCurrentElement(sameBoundsElements);

    if (!element || !element.ownerDocument || !element.ownerDocument.defaultView) {
      return;
    }

    const isElementSelected = !!selectedElementId;

    const { offsetX, offsetY, direction, dropdown, expand, maxPaneWidth } =
      getPaneLocationForElement({
        element,
        targetElementBounds,
        items,
        scrollPosition,
        featureFlags,
        isElementSelected,
      });

    const document = view.accessors.getLiveDocument();
    const documentWidth = document.documentElement.clientWidth;
    const maxItems = getPaneMaxItems({ offsetX, direction }, documentWidth, items);

    setControlPaneLocation({
      offsetX,
      offsetY,
      direction,
      dropdown,
      expand,
    });

    hideContextMenu();

    this.setState({
      offsetX,
      offsetY,
      direction,
      dropdown,
      expand,
      items,
      showPane: true,
      prevSameBoundsElements: sameBoundsElements,
      isDeleteTriggered: false,
      isHideTriggered: false,
      maxItems,
      maxPaneWidth,
    });
  }

  async updateStateForTextSelection() {
    const liveDocument = view.accessors.getLiveDocument();
    if (!liveDocument || !liveDocument.defaultView) {
      this.hidePane();
      return;
    }

    const { targetElement, items } = this.state;
    const { scrollPosition } = this.props;
    const selection = textFormatting.accessors.getAdvancedSelection();
    const { anchorNode, focusNode, isCollapsed } = selection;

    // There is a case, when selection doesn't contain anchor node. Try to click on image inside
    // contentedtiable area.
    if (!anchorNode || !focusNode) {
      this.hidePane();
      return;
    }

    // Hide pane when selection contains images
    if (!isCollapsed) {
      const range = selection.getRangeAt(0);

      const imageElements = range.getNodes([Node.ELEMENT_NODE], (el) =>
        anodum.isOneOfTags(el, ['ws-media-container', 'picture', 'img'])
      );

      if (imageElements.length) {
        this.hidePane();
        return;
      }
    }

    let newItems = await this.getItemsForTextSelection(targetElement);

    if (!newItems.length) {
      this.hidePane();
      return;
    }

    if (this.isActive) {
      const activeTrigger = items[this.activeIndex];
      newItems = activeTrigger.header ? [...activeTrigger.header, {}] : newItems;
    } else if (items && newItems && newItems.length !== items.length) {
      this.forceControlPaneRerender();
    }

    const { offsetX, offsetY, direction, expand, maxPaneWidth, arrowOffsetX } =
      getPaneLocationForTextSelection({
        element: targetElement,
        items: newItems,
        scrollPosition,
      });

    if (!direction) {
      this.hidePane();
      return;
    }

    this.setState({
      offsetX,
      offsetY,
      direction,
      expand,
      maxItems: TEXT_SELECTION_MAX_PANE_ITEMS,
      items: this.isActive ? items : newItems,
      showPane: true,
      isHideTriggered: false,
      maxPaneWidth,
      arrowOffsetX,
    });
  }

  resetActiveFlag() {
    this.isActive = false;
  }

  cancelPointerEvents() {
    const { setDisabledStatus } = this.props;
    setDisabledStatus(true);

    document.defaultView.addEventListener('mousedown', this.restorePointerEvents);
    document.defaultView.addEventListener('focus', this.restorePointerEvents);
  }

  restorePointerEvents() {
    const { setDisabledStatus } = this.props;
    setDisabledStatus(false);

    document.defaultView.removeEventListener('mousedown', this.restorePointerEvents);
    document.defaultView.removeEventListener('focus', this.restorePointerEvents);
  }

  hideHighlighter(element) {
    const { hideHighlighter, focusedElementId } = this.props;

    if (this.isTouchDevice) {
      return;
    }

    if (!element) {
      hideHighlighter();
      return;
    }

    const highlightedElements = Array.isArray(element) ? element : [element];

    const wasSomeOfElementsHighlightedBefore = this.prevHighlightedElements.some(([prevElement]) =>
      highlightedElements.some((el) => el === prevElement)
    );

    if (!wasSomeOfElementsHighlightedBefore) {
      hideHighlighter(highlightedElements);
    } else {
      const filteredHighlightedElements = highlightedElements.filter((el) =>
        this.prevHighlightedElements.every(([prevElement]) => {
          if (focusedElementId) {
            return el !== prevElement || el.getAttribute('data-ws-id') !== focusedElementId;
          }

          return el !== prevElement;
        })
      );

      if (filteredHighlightedElements.length) {
        hideHighlighter(filteredHighlightedElements);
      }
    }
  }

  showHighlighter(element) {
    const { showHighlighter, hideHoveredHighlighter, isScrolling } = this.props;

    if (this.isTouchDevice || isScrolling) {
      return;
    }

    const highlightedElements = Array.isArray(element) ? element : [element];

    hideHoveredHighlighter();
    showHighlighter(highlightedElements);
  }

  forceHighlighterRerender() {
    const { showHighlighter, highlightedElements } = this.props;

    if (highlightedElements.length) {
      const prevHighlightedElements = highlightedElements.map(([prevElement]) => prevElement);
      showHighlighter(prevHighlightedElements, { forceUpdate: true });
    }
  }

  forceControlPaneRerender() {
    this.hidePane(this.updateComponentState);
  }

  render() {
    const {
      offsetX,
      offsetY,
      arrowOffsetX,
      arrowOffsetY,
      direction,
      dropdown,
      expand,
      items,
      showPane,
      maxItems,
      maxPaneWidth,
    } = this.state;
    const { viewportWidth } = this.props;

    if (!items || !showPane || this.shouldHideControlPane()) return null;

    const disableCentering = direction === 'left' || direction === 'right';

    return (
      <div onMouseEnter={this.clearTimer}>
        <TriggerEventTracker beforeTriggerActionStart={this.onTriggerActionStart}>
          <ControlPane
            controlPaneRef={this.controlPaneRef}
            offsetX={offsetX}
            offsetY={offsetY}
            arrowOffsetX={arrowOffsetX}
            arrowOffsetY={arrowOffsetY}
            content={items}
            documents={this.getLiveDocument()}
            dropdown={dropdown}
            expand={expand}
            direction={direction}
            onActiveStateChange={this.onActiveStateChange}
            maxItems={maxItems}
            onMouseLeave={this.onMouseLeave}
            attributes={this.attributes}
            viewportWidth={viewportWidth}
            maxWidth={maxPaneWidth}
            disableCentering={disableCentering}
          />
        </TriggerEventTracker>
      </div>
    );
  }
}

ControlPaneContainer.propTypes = {
  // eslint-disable-next-line yola/react/no-unused-prop-types
  hoveredElementId: PropTypes.string,
  // eslint-disable-next-line yola/react/no-unused-prop-types
  selectedElementId: PropTypes.string,
  duplicateElement: PropTypes.func.isRequired,
  onWebsiteSettings: PropTypes.func.isRequired,
  deleteElement: PropTypes.func.isRequired,
  setDisabledStatus: PropTypes.func.isRequired,
  showHighlighter: PropTypes.func.isRequired,
  showBlockSettingsDialog: PropTypes.func.isRequired,
  blurElement: PropTypes.func.isRequired,
  hideHighlighter: PropTypes.func.isRequired,
  hideHoveredHighlighter: PropTypes.func.isRequired,
  focusedElementId: PropTypes.string,
  setControlPaneLocation: PropTypes.func.isRequired,
  isDialogVisible: PropTypes.bool,
  dialogState: PropTypes.shape(),
  customUIVisibility: PropTypes.bool,
  activeCustomTool: PropTypes.shape({}),
  scrollPosition: PropTypes.number.isRequired,
  highlightedElements: PropTypes.array,
  isScrolling: PropTypes.bool,
  isDisabledStatus: PropTypes.bool,
  selection: PropTypes.shape({
    isCollapsed: PropTypes.bool,
  }),
  mousePosition: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }).isRequired,
  viewportWidth: PropTypes.number,
  viewportHeight: PropTypes.number,
  featureFlags: PropTypes.shape({}).isRequired,
  registeredMenuGroups: PropTypes.array.isRequired,
  menu: PropTypes.shape({
    triggerId: PropTypes.string,
  }).isRequired,
  showContextMenu: PropTypes.func.isRequired,
  hideContextMenu: PropTypes.func.isRequired,
  setLoadedStatus: PropTypes.func.isRequired,
  blockEditor: PropTypes.func.isRequired,
  unblockEditor: PropTypes.func.isRequired,
  setChildrenPresence: PropTypes.func.isRequired,
};

ControlPaneContainer.defaultProps = {
  hoveredElementId: null,
  selectedElementId: null,
  focusedElementId: null,
  highlightedElements: [],
  isDialogVisible: false,
  dialogState: {},
  customUIVisibility: false,
  activeCustomTool: null,
  isScrolling: false,
  isDisabledStatus: false,
  selection: {},
  viewportWidth: window.innerWidth,
  viewportHeight: window.innerHeight,
};

const mapStateToProps = (state) => ({
  highlightedElements: highlighter.selectors.getHighlightedElements(state),
  hoveredElementId: view.selectors.getHoveredElement(state),
  selectedElementId: view.selectors.getSelectedElement(state),
  focusedElementId: view.selectors.getFocusedElement(state),
  isDialogVisible: dialogs.verifiers.isVisible(state),
  dialogState: dialogs.selectors.getDialog(state),
  isContextMenuVisible: contextMenu.selectors.getVisibilityStatus(state),
  customUIVisibility: customUI.selectors.getVisibilityState(state),
  activeCustomTool: customTools.selectors.getActiveTool(state),
  scrollPosition: view.selectors.getScrollPosition(state),
  isScrolling: view.selectors.getScrollStatus(state),
  isDisabledStatus: view.selectors.getDisabledStatus(state),
  pageContainerSelector: template.selectors.getPageContainerSelector(state),
  selection: view.selectors.getSelection(state),
  isLoaded: view.selectors.getLoadedStatus(state),
  registeredMenuGroups: contextMenu.selectors.getRegisteredGroups(state),
  menu: contextMenu.selectors.getMenu(state),
});

const mapDispatchToProps = (dispatch) => ({
  showContextMenu: (position, groups, triggerId, calculatePosition) => {
    dispatch(contextMenu.actions.showContextMenu(position, groups, triggerId, calculatePosition));
  },
  showBlockSettingsDialog: (elementId, blockId, element, optionsSetup) => {
    dispatch(
      dialogs.actions.show(dialogTypes.BLOCK_SETTINGS, {
        elementId,
        blockId,
        element,
        optionsSetup,
      })
    );
  },
  blurElement: () => {
    dispatch(view.actions.blurFocusedElement({ preserveFocusedTextElement: true }));
  },
  hideContextMenu: () => {
    dispatch(contextMenu.actions.hideContextMenu());
  },
  duplicateElement: (elementId) => {
    dispatch(view.actions.duplicateElement(elementId));
  },
  setChildrenPresence: (containerId, childrenPresenceUpdate, options) => {
    dispatch(view.actions.setChildrenPresence(containerId, childrenPresenceUpdate, options));
  },
  deleteElement: (elementId) => {
    dispatch(view.actions.deleteElement(elementId));
  },
  setDisabledStatus: (value) => {
    dispatch(view.actions.setDisabledStatus(value));
  },
  showHighlighter: (elements, options) => {
    dispatch(highlighter.actions.show(elements, options));
  },
  hideHighlighter: (elements) => {
    dispatch(highlighter.actions.hide(elements));
  },
  hideHoveredHighlighter: (elements) => {
    dispatch(highlighter.actions.hideHovered(elements));
  },
  onWebsiteSettings(tab, tabIndex, field) {
    dispatch(dialogs.actions.show(dialogTypes.WEBSITE_SETTINGS, { tab, tabIndex, field }));
  },
  setControlPaneLocation(location) {
    dispatch(actions.setLocation(location));
  },
  setLoadedStatus(status) {
    dispatch(view.actions.setLoadedStatus(status));
  },
  blockEditor() {
    dispatch(interrupter.actions.block());
  },
  unblockEditor() {
    dispatch(interrupter.actions.unblock());
  },
});

export default withFeatureFlags(['hidden_blocks', 'contact_forms_disabled', 'image_copy_paste'])(
  connect(mapStateToProps, mapDispatchToProps)(ControlPaneContainer)
);
