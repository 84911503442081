import { view, extensions, linkEditing } from '@yola/ws-sdk';
import buildLinkElement from 'src/js/modules/extensions/common/helpers/build-link-element';
import mediaAttributes from 'src/js/modules/extensions/common/constants/media-attributes';
import linkAttributes from 'src/js/modules/extensions/common/constants/link-attributes';
import constants from '../constants';

const { linkTypes } = linkEditing.constants;

const MEDIA_CONTAINER_SELECTOR = 'ws-media-container';
const IMG_SELECTOR = 'img';
const PICTURE_SELECTOR = 'picture';

const setGalleryItemAttributes = (galleryItem, data) => {
  galleryItem.setAttribute('origin-src', data.originSrc);
  galleryItem.setAttribute('origin-width', data.originWidth);
  galleryItem.setAttribute('origin-height', data.originHeight);
  galleryItem.setAttribute('aspect-ratio', data.aspectRatio);
  galleryItem.setAttribute('content-position', data.contentPosition);
  galleryItem.setAttribute('content-scale', data.contentScale);
  galleryItem.setAttribute('media-group-id', data.mediaGroupId);

  if (data.isPlaceholder) {
    galleryItem.setAttribute(mediaAttributes.PLACEHOLDER_ATTRIBUTE, 'true');
  }

  if (data.lightboxDisabled) {
    galleryItem.setAttribute(mediaAttributes.LIGHTBOX_DISABLE_ATTRIBUTE, '');
  } else {
    galleryItem.removeAttribute(mediaAttributes.LIGHTBOX_DISABLE_ATTRIBUTE);
  }
};

const setImgAttributes = (img, data) => {
  img.setAttribute('src', data.src);
  img.setAttribute('alt', data.alt);
  img.setAttribute('data-title', data.title);
  img.setAttribute('data-description', data.description);
};

const wrapWithLinkElement = (element, linkConfig) => {
  const newLinkElement = buildLinkElement(linkConfig);
  newLinkElement.classList.add(linkAttributes.CLICK_ACTION_LINK_CLASS);
  newLinkElement.appendChild(element);
  return newLinkElement;
};

const buildGalleryElement = (templateElement, data) => {
  const galleryItemElement = templateElement.cloneNode(true);
  const galleryItem = galleryItemElement.matches(MEDIA_CONTAINER_SELECTOR)
    ? galleryItemElement
    : galleryItemElement.querySelector(MEDIA_CONTAINER_SELECTOR);
  const imgElement = galleryItem.querySelector(IMG_SELECTOR);
  const pictureElement = galleryItem.querySelector(PICTURE_SELECTOR);

  setGalleryItemAttributes(galleryItem, data);
  setImgAttributes(imgElement, data);

  let newElement = pictureElement;

  if (
    data.linkConfig &&
    data.linkConfig.linkType !== linkTypes.NO_ACTION &&
    data.linkConfig.linkType !== linkTypes.VIEW_FULL_SIZE_IMAGE
  ) {
    newElement = wrapWithLinkElement(pictureElement, data.linkConfig);
  }

  galleryItem.innerHTML = '';
  galleryItem.appendChild(newElement);

  return galleryItemElement;
};

const buildGalleryElements = (galleryId, itemsData) => {
  const { galleryQuerySelector, sliderGalleryItemSelector } =
    extensions.accessors.getExtensionSettings(constants.common.slug);
  const targetLiveElement = view.accessors.getLiveElement(galleryId);
  const galleryWrapElement = targetLiveElement.querySelector(galleryQuerySelector);
  const galleryWrapLiveId = view.accessors.getLiveElementId(galleryWrapElement);
  const galleryWrapStaticElement = view.accessors.getStaticElement(galleryWrapLiveId);
  const galleryWrap = galleryWrapStaticElement.cloneNode(false);

  const galleryItemLiveElement = targetLiveElement.querySelector(sliderGalleryItemSelector);
  const galleryItemLiveId = view.accessors.getLiveElementId(galleryItemLiveElement);
  const galleryItemTemplateElement = view.accessors.getStaticElement(galleryItemLiveId);

  itemsData.forEach((itemData) => {
    const galleryItemElement = buildGalleryElement(galleryItemTemplateElement, itemData);
    galleryWrap.appendChild(galleryItemElement);
  });

  view.operations.replaceElement(galleryWrap, galleryWrapLiveId);
};

export default buildGalleryElements;
