import store from 'src/js/store';
import getRegisteredGroups from '../selectors/registered-groups';

const isInteractiveElement = (element) => {
  const state = store.getState();
  const registeredGroups = getRegisteredGroups(state);

  return registeredGroups.some((group) =>
    group.items.some((item) => {
      const matches = item.matches || group.matches;
      return matches(element);
    })
  );
};

export default isInteractiveElement;
