import get from 'lodash.get';
import { constants } from '@yola/subscription-manager-js';

const {
  hostingPackageFeatureNames: { PREMIUM_BUTTON_PRESETS },
} = constants;

const premiumButtonPresetsLimit = ({ targetPackage }) => ({
  available: get(targetPackage, ['features', PREMIUM_BUTTON_PRESETS], false),
});

export default premiumButtonPresetsLimit;
