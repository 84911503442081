import { textFormatting, anodum } from '@yola/ws-sdk';

const { isOneOfTags, isTag, isTextNode, isElementNode } = anodum;
const { wrappersTypes } = textFormatting.constants;
const { tagName } = wrappersTypes.customColor;

const getTextColorOfSelection = (selection = textFormatting.accessors.getAdvancedSelection()) => {
  if (!selection.rangeCount) return '';

  const range = selection.getRangeAt(0);
  const [firstElement] = range.getNodes();
  let element = firstElement;

  if (element && isElementNode(element) && isTag(element, tagName)) {
    const { color } = window.getComputedStyle(element);
    return color;
  }

  const isBackwards = selection.isBackwards();
  element = isBackwards ? selection.focusNode : selection.anchorNode;

  if (element.nodeType === Node.TEXT_NODE) {
    element = element.parentNode;
    const { color } = window.getComputedStyle(element);
    return color;
  }

  while (isTextNode(element.firstChild) && !isOneOfTags(element, [tagName, 'br'])) {
    if (element.firstChild.nodeType === Node.TEXT_NODE) break;
    element = element.firstChild;
  }

  const { color } = window.getComputedStyle(element);
  return color;
};

export default getTextColorOfSelection;
