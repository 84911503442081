const alignCenter = (containerSize, elementSize) => (containerSize - elementSize) / 2;

const getPositionByTriggerElement = (
  triggerElement,
  positionDirection,
  dialogWidth,
  dialogHeight
) => {
  const POSITION_MARGIN = 25;
  const { left, top, right, bottom } = triggerElement.getBoundingClientRect();
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;

  const isTopEdgeOverflow = (offsetY) => offsetY < 0;
  const isRightEdgeOverflow = (offsetX) => offsetX + dialogWidth > screenWidth;
  const isBottomEdgeOverflow = (offsetY) => offsetY + dialogHeight > screenHeight;
  const isLeftEdgeOverflow = (offsetX) => offsetX < 0;

  const adjustPositionFromOverflow = ({ offsetX, offsetY }) => {
    let x = offsetX;
    let y = offsetY;

    // Bottom edge overflow
    if (isBottomEdgeOverflow(y)) {
      y = screenHeight - dialogHeight - POSITION_MARGIN;
    }
    // Top edge overflow
    if (isTopEdgeOverflow(y)) {
      y = 0;
    }
    // Right edge overflow
    if (isRightEdgeOverflow(x)) {
      x = screenWidth - dialogWidth - POSITION_MARGIN;
    }
    // Left edge overflow
    if (isLeftEdgeOverflow(x)) {
      x = 0;
    }
    // Bigger than screen
    if (dialogWidth > screenWidth - POSITION_MARGIN) {
      x = 0;
    }
    if (dialogHeight > screenHeight - POSITION_MARGIN) {
      y = 0;
    }

    return {
      offsetX: x,
      offsetY: y,
    };
  };

  const getPositionOnTop = () => {
    const offsetX = left;
    const offsetY = top - dialogHeight - POSITION_MARGIN;
    return adjustPositionFromOverflow({ offsetX, offsetY });
  };

  const getPositionOnRight = () => {
    const offsetX = right + POSITION_MARGIN;
    const offsetY = top;
    return adjustPositionFromOverflow({ offsetX, offsetY });
  };

  const getPositionOnBottom = () => {
    const offsetX = left;
    const offsetY = bottom + POSITION_MARGIN;
    return adjustPositionFromOverflow({ offsetX, offsetY });
  };

  const getPositionOnLeft = () => {
    const offsetX = left - dialogWidth - POSITION_MARGIN;
    const offsetY = top;
    return adjustPositionFromOverflow({ offsetX, offsetY });
  };

  const getPositionOnCenter = () => {
    const offsetX = alignCenter(screenWidth, dialogWidth);
    const offsetY = alignCenter(screenHeight, dialogHeight);
    return adjustPositionFromOverflow({ offsetX, offsetY });
  };

  switch (positionDirection) {
    case 'top':
      return getPositionOnTop();
    case 'right':
      return getPositionOnRight();
    case 'bottom':
      return getPositionOnBottom();
    case 'left':
      return getPositionOnLeft();
    default:
      return getPositionOnCenter();
  }
};

export default getPositionByTriggerElement;
