import common from '../constants/common';

const findClosestSiblingsByHeight = (siblingsWithHeight, targetHeight) => {
  const matched = siblingsWithHeight.filter(
    ({ height }) =>
      targetHeight > height - common.IMAGE_HEIGHT_COMPLETE_AREA &&
      targetHeight < height + common.IMAGE_HEIGHT_COMPLETE_AREA
  );

  const closestHeight = matched.reduce((prev, { height }) => {
    const aDiff = Math.abs(prev - targetHeight);
    const bDiff = Math.abs(height - targetHeight);
    return bDiff < aDiff ? height : prev;
  }, 0);

  return matched.filter(({ height }) => height === closestHeight);
};

export default findClosestSiblingsByHeight;
