import { hooks, site, template } from '@yola/ws-sdk';
import constants from '../constants';
import track from '../track';

const trackAppOpened = () => {
  hooks.operations.subscribe(
    hooks.availableHooks.ON_AFTER_SITE_CONNECT,
    () => {
      track(constants.events.APP_OPENED_EVENT, {
        siteId: site.accessors.getSiteId(),
        templateBuildSlug: template.accessors.getBuildSlug(),
      });
    },
    { once: true }
  );
};

export default trackAppOpened;
