import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';

const { Paragraph, TextSkeleton } = designSystem;

function BackupsSkeleton({ config }) {
  const generateItems = (qty) => Array(qty).fill(undefined);
  const generateKey = (item, index) => `${item}-${index}`;

  return (
    <div className="ws-site-backups-skeleton">
      {config.map((itemsPerGroup, groupIndex) => (
        <div
          className="ws-site-backups__group"
          key={generateKey(itemsPerGroup, groupIndex)}
          data-foo={generateKey(itemsPerGroup, groupIndex)}
        >
          <div className="ws-site-backups__group-title">
            <Paragraph size="small">
              <TextSkeleton width="max(100px, 20%)" />
            </Paragraph>
          </div>

          <div className="ws-site-backups__group-list">
            {generateItems(itemsPerGroup).map((item, itemIndex) => (
              <div
                className="ws-site-backups__group-list-item"
                key={generateKey('item', itemIndex)}
                data-bar={generateKey('item', itemIndex)}
              >
                <div className="ws-site-backups__item-description">
                  <Paragraph>
                    <TextSkeleton width="80%" />
                  </Paragraph>
                  <Paragraph size="small">
                    <TextSkeleton width="50%" />
                  </Paragraph>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

// The array in config represents items count per each group
BackupsSkeleton.propTypes = {
  config: PropTypes.arrayOf(PropTypes.number),
};

BackupsSkeleton.defaultProps = {
  config: [2, 1],
};

export default BackupsSkeleton;
