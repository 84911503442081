import { integration } from '@yola/ws-sdk';
import store from 'src/js/store';
import constants from '../constants';

const isConfiguredProperly = (brandingFooterElement) => {
  const state = store.getState();
  const { logo, message, url, logoWidth, theme } = integration.selectors.getSiteFooter(state);
  const isMessageConfigured = message
    ? brandingFooterElement.getAttribute('message') === message
    : true;

  return (
    brandingFooterElement.getAttribute('logo') === logo &&
    isMessageConfigured &&
    brandingFooterElement.getAttribute('url') === url &&
    brandingFooterElement.getAttribute('logo-width') ===
      (logoWidth || constants.DEFAULT_LOGO_WIDTH) &&
    brandingFooterElement.getAttribute('theme') === (theme || constants.DEFAULT_THEME)
  );
};

export default isConfiguredProperly;
