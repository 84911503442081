import isTextReadable from './is-text-readable';
import getTextAreas from './get-text-areas';
import { SUBMODULE_NAME } from '../constants';
import getCssPropertyByNode from './get-css-property-by-node';
import calculateCssPropertiesBySurface from './calculate-css-properties-by-surface';
import isRecomendedSurfaceAllowable from './is-recomended-surface-allowable';
import isHeader from './is-header';
import mergeInstructions from './merge-instructions';
import opacityComputer from './opacity-computer';
import getImgMeta from './get-img-meta';
import isBlockTextDark from './is-block-text-dark';
import cleanup from './cleanup';
import getSubmoduleConfig from '../../../helpers/get-submodule-config';
import isModuleConfigured from './is-module-configured';
import getComputedStyleForNode from './get-css-computed-style-for-node';

const compute = (wsBlock, options = {}) =>
  new Promise(async (resolve) => {
    const blockInstructions = [];
    const headInstructions = [];
    const { mediaSource, restrictSurfaceAutomation = false } = options;
    const previousNode = wsBlock.previousElementSibling;
    const { surfaces } = wsBlock;
    const config = getSubmoduleConfig(SUBMODULE_NAME);
    const {
      sourceAttributeName,
      overlayColorVariableName,
      overlayAttributeName,
      affectedBlocksReasonSelector,
      overlayOpacityAttributeName,
      recomendedDarkSurfaceName,
    } = config;

    if (!wsBlock.getTargetElementForAttribute || !surfaces || !isModuleConfigured(config)) {
      resolve([]);
      return;
    }

    const [backgroundNode] = wsBlock.getTargetElementForAttribute(sourceAttributeName);
    if (
      !backgroundNode ||
      !backgroundNode.computeBackgroundRectForSource ||
      !wsBlock.getAttribute(sourceAttributeName)
    ) {
      resolve([]);
      return;
    }

    const imgMeta = await getImgMeta(wsBlock, mediaSource);
    const blendingMode = backgroundNode.computedBlendingMode;
    const canvasProperties = {
      width: wsBlock.offsetWidth,
      height: wsBlock.offsetHeight,
      background: getComputedStyleForNode(wsBlock.children[0], 'backgroundColor'),
    };
    const isOverlayEnabled = wsBlock.hasAttribute(overlayAttributeName);
    const overlay = {
      opacity: Number(wsBlock.getAttribute(affectedBlocksReasonSelector)),
      color: getCssPropertyByNode(backgroundNode, overlayColorVariableName),
      blendingMode,
    };
    const textAreas = getTextAreas(wsBlock);

    if (
      isBlockTextDark(wsBlock) &&
      (await isTextReadable(
        canvasProperties,
        imgMeta,
        isOverlayEnabled ? overlay : null,
        textAreas
      ))
    ) {
      resolve([]);
      return;
    }
    const computeOpacityForDarkText = opacityComputer(isTextReadable)(
      canvasProperties,
      imgMeta,
      overlay,
      textAreas
    );
    if (isBlockTextDark(wsBlock) && (isOverlayEnabled || restrictSurfaceAutomation)) {
      const opacityToApplyForDarkText = await computeOpacityForDarkText(0.5);

      if (opacityToApplyForDarkText !== null) {
        blockInstructions.push({
          property: overlayOpacityAttributeName,
          value: opacityToApplyForDarkText,
        });
        blockInstructions.push({
          property: overlayAttributeName,
          value: '',
        });
        resolve(
          mergeInstructions({
            node: wsBlock,
            instructions: blockInstructions,
          })
        );
        return;
      }
    }

    const recomendedSurfaces = [...surfaces];
    recomendedSurfaces[0] = recomendedDarkSurfaceName;
    if (!isRecomendedSurfaceAllowable(wsBlock, recomendedSurfaces.join(' '))) {
      resolve([]);
      return;
    }

    if (
      isHeader(previousNode) &&
      previousNode.hasAttribute(affectedBlocksReasonSelector) &&
      isRecomendedSurfaceAllowable(previousNode, recomendedDarkSurfaceName) &&
      !restrictSurfaceAutomation
    ) {
      headInstructions.push({
        property: 'surfaces',
        value: recomendedDarkSurfaceName,
      });
    }

    const darkProperties = calculateCssPropertiesBySurface(wsBlock, recomendedDarkSurfaceName);
    let textAreasOnDarkSurface = textAreas;

    if (isBlockTextDark(wsBlock) && !restrictSurfaceAutomation) {
      overlay.color = darkProperties.overlay.color;
      overlay.opacity = darkProperties.overlay.opacity;
      textAreasOnDarkSurface = darkProperties.textAreas;
      blockInstructions.push({
        property: 'surfaces',
        value: recomendedSurfaces.join(' '),
      });
    }

    if (
      await isTextReadable(
        canvasProperties,
        imgMeta,
        isOverlayEnabled ? overlay : null,
        textAreasOnDarkSurface
      )
    ) {
      resolve(
        mergeInstructions(
          {
            node: wsBlock,
            instructions: blockInstructions,
          },
          {
            node: previousNode,
            instructions: headInstructions,
          }
        )
      );
      return;
    }

    const computeOpacity = opacityComputer(isTextReadable, true)(
      canvasProperties,
      imgMeta,
      overlay,
      textAreasOnDarkSurface
    );
    const opacityToApply = await computeOpacity(0.5);

    blockInstructions.push({
      property: overlayOpacityAttributeName,
      value: opacityToApply,
    });
    blockInstructions.push({
      property: overlayAttributeName,
      value: opacityToApply > 0 ? '' : null,
    });

    resolve(
      mergeInstructions(
        {
          node: wsBlock,
          instructions: blockInstructions,
        },
        {
          node: previousNode,
          instructions: headInstructions,
        }
      )
    );
  }).then((data) => {
    cleanup();
    return data;
  });

export default compute;
