import React from 'react';
import PropTypes from 'prop-types';
import { i18next, utils } from '@yola/ws-sdk';
import { Field, designSystem } from '@yola/ws-ui';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import getDefaultTraits from 'src/js/modules/analytics/segment/helpers/get-default-traits';
import segment from 'src/js/modules/analytics/segment';
import TagInputField from 'src/js/modules/common/components/tag-input-field';
import TextAreaField from 'src/js/modules/common/components/textarea-field';
import CheckboxField from 'src/js/modules/common/components/checkbox-field';
import verifiers from '../verifiers';

const { Stack, Box, Heading, Paragraph } = designSystem;

const {
  track,
  constants: { events },
} = segment;

const emailsValidation = (emails) => {
  if (!emails.length) {
    return i18next.t('Please enter a valid email address');
  }

  if (emails.length > 10) {
    return i18next.t('Please enter no more than 10 email addresses');
  }

  const invalidEmails = [];
  emails.forEach(({ value }) => {
    if (!utils.validation.isEmail(value)) invalidEmails.push(value);
  });

  return invalidEmails.length
    ? `${i18next.t('Invalid email')}: ${invalidEmails.join(', ')}. ${i18next.t(
        'Please enter a valid email address'
      )}`
    : '';
};

const successMessageValidation = (value) => {
  if (!value.trim().length) {
    return i18next.t('Invalid field. Success message can not be empty.');
  }
  return '';
};

const AdvancedTab = (props) => {
  const { elementId, setAdvancedFormState, advancedFormState } = props;
  const { emails, successMessage } = advancedFormState;

  const onGdprFieldChange = (shouldDisplayGdpr) => {
    setAdvancedFormState({ ...advancedFormState, shouldDisplayGdpr });

    track(events.CONTACT_FORM_DISPLAY_GDPR_CHECKBOX_CLICKED, {
      ...getDefaultTraits(elementId),
      dialogId: dialogTypes.CONTACT_FORM_SETTINGS_DIALOG,
      action: shouldDisplayGdpr ? 'turn-on' : 'turn-off',
    });
  };

  return (
    <Stack gap="spacing-m">
      <Stack gap="spacing-3-xs">
        <Heading type="heading-6">{i18next.t('Recipients')}</Heading>
        <Field
          component={TagInputField}
          validate={emailsValidation}
          name="emails"
          tags={emails}
          onChange={(updatedEmails) => {
            setAdvancedFormState({ ...advancedFormState, emails: updatedEmails });
          }}
        />
        <Paragraph size="small" appearance="medium-emphasis">
          {i18next.t('Email address to which form submissions will be sent.')}
        </Paragraph>
      </Stack>
      <Stack gap="spacing-3-xs">
        <Heading type="heading-6">{i18next.t('Success message')}</Heading>
        <Field
          component={TextAreaField}
          validate={successMessageValidation}
          value={successMessage}
          onChange={(newSuccessMessage) =>
            setAdvancedFormState({ ...advancedFormState, successMessage: newSuccessMessage })
          }
          name="successMessage"
          style={{ height: '100px', minHeight: '100px', resize: 'none' }}
        />
        <Paragraph size="small" appearance="medium-emphasis">
          {i18next.t('Display this message to users after they submit your form.')}
        </Paragraph>
      </Stack>
      {verifiers.isGdprCheckboxEnabled(elementId) !== null && (
        <div>
          <Field
            name="shouldDisplayGdpr"
            component={CheckboxField}
            id="displayGdprCheckbox"
            label={i18next.t('Display GDPR consent field')}
            onClick={onGdprFieldChange}
          />
          <Box paddingLeft="spacing-l">
            <Paragraph size="small" appearance="medium-emphasis">
              {i18next.t(
                'Require your visitors to agree with the Terms & Conditions and Privacy Policy of your website.'
              )}
            </Paragraph>
          </Box>
        </div>
      )}
    </Stack>
  );
};

AdvancedTab.propTypes = {
  elementId: PropTypes.string.isRequired,
  setAdvancedFormState: PropTypes.func.isRequired,
  advancedFormState: PropTypes.shape({
    successMessage: PropTypes.string,
    emails: PropTypes.array,
  }).isRequired,
};

export default AdvancedTab;
