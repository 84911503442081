import { blocks, constraints, siteLocales } from '@yola/ws-sdk';
import ai from 'src/js/modules/ai';

const {
  helpers: { initiateBlockGeneration },
} = ai;

const getRegeneratedBlockHtml = async (element, pageMeta, { layout, content }) => {
  const blockId = blocks.accessors.getBlockIdByElement(element);
  const blockVariationId = blocks.accessors.getVariationIdByElement(element);
  const rootTextSelector = constraints.accessors.getContentEditableRootSelector();
  const textElements = element.querySelectorAll(rootTextSelector.join(','));
  const blockTextContent = [...textElements].map((el) => el.innerText).join(' ');
  const options = [];

  if (layout) options.push('layout');
  if (content) options.push('content');

  try {
    const response = await initiateBlockGeneration({
      blockId,
      blockVariationId,
      options,
      pageMeta,
      blockTextContent,
    });

    const { html } = response.results;

    return siteLocales.helpers.replaceGlobalSourceInMarkup(html, pageMeta.locale, {
      checkCommentedNodes: true,
    });
  } catch (e) {
    throw e;
  }
};

export default getRegeneratedBlockHtml;
