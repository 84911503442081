import React, { useState, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import bowser from 'yola-bowser';
import { i18next } from '@yola/ws-sdk';
import { designSystem } from '@yola/ws-ui';
import FreeStockContext from './free-stock-context';

const { Icon, Paragraph, SelectedIcon } = designSystem;

const IMAGE_INDENT = 12;

function FreeStockImage({ id, src, width, height, alt, user, unsplash, isSelected }) {
  const { onClick, isSearchInProgress } = useContext(FreeStockContext);
  const [isLoaded, setLoadedStatus] = useState(false);
  const isMobile = Boolean(bowser.mobile || bowser.tablet);
  const computedWidth = width - IMAGE_INDENT;
  const computedHeight = height - IMAGE_INDENT;
  const itemClasses = classNames('ws-free-stock-images__item', {
    'ws-free-stock-images__item--loaded': isLoaded && !isSearchInProgress,
    'ws-free-stock-images__item--active': isSelected,
    'ws-free-stock-images__item--mobile': isMobile,
  });

  const onLoad = useCallback(() => {
    setLoadedStatus(true);
  }, []);

  return (
    <div className={itemClasses} style={{ width: computedWidth, height: computedHeight }}>
      <div
        onClick={(e) => {
          onClick(id, e);
        }}
        className="ws-free-stock-images__image"
      >
        <img
          loading="lazy"
          onLoad={onLoad}
          src={src}
          width={computedWidth}
          height={computedHeight}
          alt={alt}
        />
      </div>
      <div className="ws-free-stock-images__caption">
        <div className="ws-free-stock-images__info">
          <Icon glyph="info-italic" type="white" />
        </div>
        <div className="ws-free-stock-images__content">
          <Paragraph appearance="white">
            <span
              // eslint-disable-next-line yola/react/no-danger
              dangerouslySetInnerHTML={{
                __html: i18next.t('Photo by {userLink} on {unsplashLink}', {
                  userLink: `<a href=${user.link} target="_blank">${user.name}</a>`,
                  unsplashLink: `<a href=${unsplash.link} target="_blank">Unsplash</a>`,
                }),
              }}
            />
          </Paragraph>
        </div>
      </div>
      {isSelected && (
        <div className="ws-free-stock-images__icon">
          <SelectedIcon />
        </div>
      )}
    </div>
  );
}

FreeStockImage.propTypes = {
  id: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  alt: PropTypes.string,
  user: PropTypes.shape({
    link: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  unsplash: PropTypes.shape({
    link: PropTypes.string,
  }).isRequired,
  isSelected: PropTypes.bool.isRequired,
};

FreeStockImage.defaultProps = {
  alt: '',
};

export default FreeStockImage;
