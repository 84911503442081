const getLargestBlock = (blocks, { viewportHeight, scrollPosition }) => {
  if (!blocks.length) return null;

  const computedBlocks = blocks
    .map((block) => {
      const { top, height } = block.getBoundingClientRect();

      const scrollTop = scrollPosition;
      const scrollBottom = scrollTop + viewportHeight;

      const offsetTop = top + scrollPosition;
      const offsetBottom = offsetTop + height;

      const visibleTop = offsetTop < scrollTop ? scrollTop : offsetTop;
      const visibleBottom = offsetBottom > scrollBottom ? scrollBottom : offsetBottom;

      const visibleHeight = visibleBottom - visibleTop;

      if (Math.sign(visibleHeight) === -1) return null;

      return {
        height: visibleHeight,
        block,
      };
    })
    .filter((block) => block);
  const sortedBlocks = computedBlocks.sort((a, b) => b.height - a.height);
  const largestBlock = sortedBlocks[0].block;

  return largestBlock;
};

export default getLargestBlock;
