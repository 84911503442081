import { dialogs, view } from '@yola/ws-sdk';
import constants from '../constants/common';

const getImageAltModalProps = (imageElement, captions) => {
  const imageId = view.accessors.getLiveElementId(imageElement);
  const currentAltTitle = imageElement.getAttribute('alt');

  return {
    captions,
    isCheckboxAvailable: false,
    value: currentAltTitle,
    width: constants.IMAGE_ALT_MODAL_WIDTH,
    height: constants.IMAGE_ALT_MODAL_HEIGHT,
    onSubmit: (value) => {
      const trimmedValue = value.trim();

      if (trimmedValue !== currentAltTitle) {
        view.operations.setElementAttribute(imageId, 'alt', trimmedValue);
      }

      dialogs.operations.hide();
    },
    onCancel: () => {
      dialogs.operations.hide();
    },
  };
};

export default getImageAltModalProps;
