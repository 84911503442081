import store from 'src/js/store';
import user from 'yola-editor/src/js/modules/user';

const getWlDifmRedirectUrl = () => {
  const state = store.getState();
  const { id: userId } = user.selectors.getUserData(state);
  const { id: partnerId } = user.selectors.getPartnerData(state);
  const upgradeUrl = user.selectors.getPartnerUpgradeUrl(state);

  return `${upgradeUrl}?u=${userId}&p=${partnerId}&action=purchase&product=${user.constants.externalUpsellTypes.DIFM_SERVICE}`;
};

export default getWlDifmRedirectUrl;
