import React, { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Icon, MenuItem } from '@yola/ws-ui';
import NavContext from '../nav-context';
import SubMenuGroup from './sub-menu-group';

function NavMenuItem({ item }) {
  const { close } = useContext(NavContext);
  const { onClick, useEvent, href, disabled, target, type, heading, id, icon, subMenu } = item;

  const handleClick = useCallback(
    (event) => {
      if (!disabled) {
        close();
      }
      if (onClick) {
        onClick(useEvent ? event : undefined);
      }
    },
    [disabled, close, onClick, useEvent]
  );

  if (subMenu) {
    return <SubMenuGroup item={item.subMenu} beforeItemClick={close} />;
  }

  return (
    <MenuItem
      onClick={handleClick}
      href={href}
      disabled={disabled}
      target={target}
      type={type}
      className={id ? `ws-menu-item-${id}` : ''}
    >
      {!href ? (
        <span className="ws-menu-item--container">
          {icon && <Icon className="ws-menu-item--icon" {...icon} />}
          <span className="ws-menu-item__heading">{heading}</span>
        </span>
      ) : (
        <React.Fragment>
          {icon && <Icon className="ws-menu-item--icon" {...icon} />}
          {heading}
        </React.Fragment>
      )}
    </MenuItem>
  );
}

NavMenuItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default NavMenuItem;
