import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import getComponent from '../selectors/component';
import getComponentById from '../accessors/get-component-by-id';
import useScrollPreventing from '../hooks/use-scroll-preventing';

function CustomUIContainer() {
  const component = useSelector(getComponent, shallowEqual);
  const { component: CustomComponent, config } = getComponentById(component.id);
  const componentsProps = component.props || {};
  useScrollPreventing(config);

  return CustomComponent ? <CustomComponent {...componentsProps} /> : null;
}

export default CustomUIContainer;
