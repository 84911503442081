import { prioritizer } from '@yola/subscription-manager-js';

const getSubscriptionType = (subscription) => subscription;

function sortBySubscriptionType(subscriptionList, subscriptionTypeAccessor = getSubscriptionType) {
  return subscriptionList.sort((item1, item2) => {
    const type1 = subscriptionTypeAccessor(item1);
    const type2 = subscriptionTypeAccessor(item2);

    return prioritizer.getPriority(type1) - prioritizer.getPriority(type2);
  });
}

export default sortBySubscriptionType;
