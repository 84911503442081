const UTC_SYMBOL = 'Z';

// fix yyyy-mm-ddThh:mm:ss to yyyy-mm-ddThh:mm:ssZ
// without Z at the end, browsers can interpret ISO data based on local time instead of zero UTC zone
const convertISOToZuluTime = (date) => {
  let zuluTimeDate = date;

  if (!date.endsWith(UTC_SYMBOL)) {
    zuluTimeDate = `${date}${UTC_SYMBOL}`;
  }

  return zuluTimeDate;
};

export default convertISOToZuluTime;
