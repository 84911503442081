export default {
  DIALOG_MAX_WIDTH: 505,
  DIALOG_MIN_WIDTH: 376,
  DIALOG_HEIGHT: 532,
  DIALOG_MAX_HEIGHT: 600,
  DIALOG_MIN_HEIGHT: 240,
  ICONS_SCROLL_THRESHOLD: 100,
  ICONS_SCROLL_DELAY: 300,
  ROOT_ICONS_DIRECTORY: 'extensions/ws-icon',
  ESC_KEYCODE: 27,
  ENTER_KEYCODE: 13,
  INIT_NUMBER_OF_ICONS_ON_SCREEN: 56,
  MIN_ICONS_QUANTITY: 7,
  ICON_SIZE: 68,
  ICON_MAX_PREVIEW_SIZE: 99, // max size for free png previews of icons8
  ICON_GROUP_ATTRIBUTE: 'icon-group-id',
  ICON_GROUP_ID_LENGTH: 6,
  slug: 'ws-icon',
  ICON_PREVIEW_SIZE: 40,
  ICON_LIBRARIES_PREMIUM_TAB: 'PREMIUM',
  ICON_LIBRARIES_FREE_TAB: 'FREE',
  DEFAULT_ICONS_LANGUAGE: 'en',
  ICON_ID_ATTRIBUTE: 'data-icon-id',
  IMAGE_GRID_SKELETON_TOP_INDENT: 24,
  BANNER_ANIMATION_TIME: 800,
  MIN_ICON_DIALOG_CONTRAST_RATIO: 1.4,
  MAX_RETRY_COUNT: 3,
};
