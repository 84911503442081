import {
  MAX_CONTAINER_HEIGHT,
  MIN_CONTAINER_HEIGHT,
} from 'src/js/modules/extensions/registry/ws-image/constants/media-container';

const getLimitedHeight = ({ width, height, maxAspectRatio, minHeight, maxHeight }) => {
  let maxContainerHeight = MAX_CONTAINER_HEIGHT;
  let minContainerHeight = MIN_CONTAINER_HEIGHT;

  if (minHeight) {
    minContainerHeight = Math.max(minHeight, MIN_CONTAINER_HEIGHT);
  }

  if (maxHeight) {
    maxContainerHeight = maxAspectRatio ? Math.min(maxHeight, width / maxAspectRatio) : maxHeight;
  }

  if (maxAspectRatio) {
    maxContainerHeight = maxHeight
      ? Math.min(width / maxAspectRatio, maxHeight)
      : width / maxAspectRatio;
  }

  return Math.max(minContainerHeight, Math.min(height, maxContainerHeight));
};

export default getLimitedHeight;
