import React from 'react';
import PropTypes from 'prop-types';
import { i18next } from '@yola/ws-sdk';
import { ProgressDialog } from '@yola/ws-ui';

function ImageLoadingDialog(props) {
  const { captions, ...restProps } = props;

  const getCaptions = (captionsOverrides) => ({
    title: `${i18next.t('Uploading image')}...`,
    description: i18next.t('Your image will be uploaded to the website soon'),
    cancel: i18next.t('Cancel'),
    ...captionsOverrides,
  });

  return (
    <ProgressDialog
      overlay="visible"
      progressType="indeterminate"
      cancelable={false}
      captions={getCaptions(captions)}
      {...restProps}
    />
  );
}

ImageLoadingDialog.defaultProps = {
  captions: {},
};

ImageLoadingDialog.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    cancel: PropTypes.string,
  }),
};

export default ImageLoadingDialog;
