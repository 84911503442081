import redoHandler from './redo-handler.js';
import saveHandler from './save-handler.js';
import undoHandler from './undo-handler.js';

const documentKeydownHandler = (e) => {
  // Browsers trigger `keydown` event after autofill some inputs(address, phone). In this events
  // `key`, `which` and other properties are undefined. It may cause errors in handlers.
  if (!e.key) return;

  redoHandler(e);
  saveHandler(e);
  undoHandler(e);
};

export default documentKeydownHandler;
