import { site, template } from '@yola/ws-sdk';
import constants from '../constants';
import track from '../track';

const { events } = constants;

const trackPageManagerSearchPerformed = ({ searchTerm, searchResults }) => {
  if (!searchTerm.trim()) return;

  const traits = {
    appName: 'yola-editor',
    siteId: site.accessors.getSiteId(),
    templateBuildSlug: template.accessors.getBuildSlug(),
    searchTerm,
    searchResults,
  };

  track(events.PAGE_MANAGER_SEARCH_PERFORMED, traits);
};

export default trackPageManagerSearchPerformed;
