const validateString =
  ({ required, minLength, maxLength }, errorMessages) =>
  (value) => {
    const trimmedValue = value.trim();

    if (required && !trimmedValue) {
      return errorMessages.required;
    }

    if (minLength && trimmedValue.length && trimmedValue.length < minLength) {
      return errorMessages.minLength;
    }

    if (maxLength && trimmedValue.length > maxLength) {
      return errorMessages.maxLength;
    }

    return null;
  };

export default validateString;
