import actionTypes from '../constants/action-types';

const fetchSiteData = (siteId) => ({
  type: actionTypes.FETCH_DATA,
  payload: {
    siteId,
  },
});

export default fetchSiteData;
