import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@yola/ws-ui';
import classNames from 'classnames';

function Banner({ interactive, icon, onClick, captions, className }) {
  const { title, description, actionLabel } = captions;
  const actionLabelProps = {
    className: 'menu-banner__action-label',
  };
  const containerProps = {
    className: classNames('menu-banner', className, {
      'menu-banner--interactive': interactive,
    }),
  };
  if (interactive) {
    containerProps.onClick = onClick;
  } else {
    actionLabelProps.onClick = onClick;
  }

  const iconProps = {};
  if (icon && icon.size) {
    iconProps.size = icon.size;
  }
  if (icon && icon.strokeWidth) {
    iconProps.strokeWidth = icon.strokeWidth;
  }

  return (
    <div {...containerProps}>
      {icon && (
        <div className="menu-banner__icon">
          <Icon glyph={icon.name} {...iconProps} />
        </div>
      )}
      <div className="menu-banner__content">
        <div className="menu-banner__title">{title}</div>
        <div className="menu-banner__description">{description}</div>
        <div {...actionLabelProps}>{actionLabel}</div>
      </div>
    </div>
  );
}

const iconShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
});

Banner.propTypes = {
  className: PropTypes.string,
  interactive: PropTypes.bool,
  icon: iconShape,
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    actionLabel: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

Banner.defaultProps = {
  className: '',
  interactive: false,
  icon: null,
};

export default Banner;
