import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { view, anodum, hdrm, blocks } from '@yola/ws-sdk';
import blocksModule from 'src/js/modules/blocks';
import { ViewportConsumer } from 'yola-editor/src/js/utils/viewport-provider';
import starterScreen from 'yola-editor/src/js/modules/starter-screen';
import upsell from 'src/js/modules/upsell';
import navbar from 'src/js/modules/navbar';
import editorHooks from 'src/js/modules/editor-hooks';
import bowser from 'yola-bowser';
import editor from '../../editor';
import View from '../components/view';
import scroller from '../../scroller';
import highlighter from '../../highlighter';

class ViewContainer extends React.Component {
  constructor(props) {
    super(props);

    this.onDocumentLoad = this.onDocumentLoad.bind(this);
    this.onDocumentLoadStart = this.onDocumentLoadStart.bind(this);
    this.getLargestBlockId = this.getLargestBlockId.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    const { updatedAt } = this.props;
    return updatedAt !== nextProps.updatedAt;
  }

  onDocumentLoadStart() {
    const { setLoadedStatus } = this.props;
    setLoadedStatus(false);
  }

  async onDocumentLoad(iframe) {
    const {
      scrollTargetPath,
      clearScrollTargetPath,
      hideStarterScreen,
      scrollPosition,
      setLoadedStatus,
    } = this.props;

    const hasActivePageNonIgnoredBlocks = blocksModule.verifiers.hasActivePageNonIgnoredBlocks();

    if (scrollPosition > 0 && !bowser.ios) {
      const liveDocument = hdrm.instance.driver.getLiveDocument();
      liveDocument.scrollingElement.scrollTop = scrollPosition;
    }

    view.helpers.autoScroll.setDocument(hdrm.instance.driver.getLiveDocument());
    view.helpers.smoothScroll.setDocument(hdrm.instance.driver.getLiveDocument());
    hideStarterScreen();
    setLoadedStatus(true);

    upsell.interrupters.registerExternalLinksInterrupter();

    if (scrollTargetPath) {
      const staticDocument = hdrm.instance.driver.getStaticDocument();
      const staticElement = anodum.getNodeByTreePath(staticDocument, scrollTargetPath);
      const liveElement = hdrm.accessors.getLiveElementByStaticElement(staticElement);

      if (!liveElement) {
        clearScrollTargetPath();
        return;
      }

      scroller.helpers.scrollToElement(liveElement).then(() => {
        highlighter.helpers.highlightElements([liveElement]);
        clearScrollTargetPath();

        if (hasActivePageNonIgnoredBlocks) {
          const isElementBlock = blocks.verifiers.isElementBlock(liveElement);

          if (isElementBlock) {
            const liveElementId = view.accessors.getLiveElementId(liveElement);
            view.operations.setHoveredElement(liveElementId);
          } else {
            const largestBlockId = this.getLargestBlockId(iframe);
            view.operations.setHoveredElement(largestBlockId);
          }
        }
      });
    } else if (hasActivePageNonIgnoredBlocks) {
      const largestBlockId = this.getLargestBlockId(iframe);
      view.operations.setHoveredElement(largestBlockId);
    }

    editorHooks.triggers.onViewReady();
  }

  getLargestBlockId(iframe) {
    const { scrollPosition } = this.props;
    const pageBlocks = view.accessors.getPageBlocks();
    const viewportHeight = iframe.contentWindow.innerHeight;
    const largestBlock = blocksModule.helpers.getLargestBlock(pageBlocks, {
      viewportHeight,
      scrollPosition,
    });

    return view.accessors.getLiveElementId(largestBlock);
  }

  render() {
    const { mappedHTML, navbarHeight } = this.props;

    if (!mappedHTML) return null;

    const dom = anodum.parseDocumentFromString(mappedHTML);

    return (
      <ViewportConsumer>
        {({ height }) => (
          <View
            document={dom}
            height={`${height}px`}
            offsetY={navbarHeight}
            onStart={this.onDocumentLoadStart}
            onLoad={this.onDocumentLoad}
          />
        )}
      </ViewportConsumer>
    );
  }
}

ViewContainer.propTypes = {
  updatedAt: PropTypes.number,
  mappedHTML: PropTypes.string,
  hideStarterScreen: PropTypes.func.isRequired,
  setLoadedStatus: PropTypes.func.isRequired,
  clearScrollTargetPath: PropTypes.func.isRequired,
  scrollPosition: PropTypes.number,
  navbarHeight: PropTypes.number,
  scrollTargetPath: PropTypes.array,
};

ViewContainer.defaultProps = {
  updatedAt: null,
  mappedHTML: null,
  scrollPosition: 0,
  navbarHeight: 0,
  scrollTargetPath: null,
};

const mapStateToProps = (state) => ({
  mappedHTML: view.selectors.getMappedHTML(state),
  updatedAt: view.selectors.getUpdatedAt(state),
  scrollPosition: view.selectors.getScrollPosition(state),
  scrollTargetPath: scroller.selectors.getScrollTargetPath(state),
  navbarHeight: navbar.selectors.getHeight(state),
});

const dispatchToProps = (dispatch) => ({
  hideStarterScreen() {
    dispatch(starterScreen.actions.setProgress({ progress: 100 }));
  },
  setLoadedStatus(value) {
    dispatch(editor.actions.setLoadedStatus(value));
  },
  clearScrollTargetPath() {
    dispatch(scroller.actions.clearScrollTargetPath());
  },
});

export default connect(mapStateToProps, dispatchToProps)(ViewContainer);
