export default {
  REGULAR_IMAGE_ZOOM_SLIDER: 'regular-image-focal-point-slider',
  REGULAR_IMAGE_ZOOM_IN_TRIGGER: 'regular-image-zoom-in',
  REGULAR_IMAGE_ZOOM_OUT_TRIGGER: 'regular-image-zoom-out',
  REGULAR_IMAGE_FILE_TRIGGER: 'regular-image-file-trigger',
  REGULAR_IMAGE_SUBMIT: 'regular-image-submit',
  REGULAR_IMAGE_UPLOAD_TRIGGER: 'regular-image-upload-trigger',
  BACKGROUND_IMAGE_ZOOM_TRIGGER: 'background-image-zoom-trigger',
  BACKGROUND_IMAGE_FILE_TRIGGER: 'background-image-file-trigger',
  BACKGROUND_IMAGE_SUBMIT: 'background-image-submit',
  BACKGROUND_IMAGE_ZOOM_IN_TRIGGER: 'background-image-zoom-in',
  BACKGROUND_IMAGE_ZOOM_OUT_TRIGGER: 'background-image-zoom-out',
  BACKGROUND_IMAGE_ZOOM_SLIDER: 'background-image-zoom-slider',
  REGULAR_IMAGE_FIT_TRIGGER: 'regular-image-fit-trigger',
  BACKGROUND_IMAGE_FIT_TRIGGER: 'background-image-fit-trigger',
};
