import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { blocks } from '@yola/ws-sdk';

function useCategories(blockList) {
  const categoryList = useSelector(blocks.selectors.getCategories, shallowEqual);
  const allCategories = useMemo(() => categoryList || [], [categoryList]);

  return useMemo(() => {
    const usedCategoriesMap = blockList.reduce((result, block) => {
      const categoriesMap = result;
      const { category } = block;
      categoriesMap[category] = categoriesMap[category] || Boolean(block.new);
      return result;
    }, {});

    return allCategories
      .filter((category) => category.name in usedCategoriesMap)
      .map((category) => ({
        ...category,
        hasNewBlocks: usedCategoriesMap[category.name],
      }));
  }, [blockList, allCategories]);
}

export default useCategories;
