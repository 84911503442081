import React from 'react';
import { i18next } from '@yola/ws-sdk';
import { Icon } from '@yola/ws-ui';
import Trigger from '../../common/components/trigger';
import splitByPages from './split-by-pages';
import mixPageTrigger from './mix-page-trigger';
import getSortedContent from './get-sorted-content';
import deriveItemsWidth from './derive-items-width';

const getNextTrigger = (title = i18next.t('Next')) => ({
  trigger: (
    <Trigger>
      <Icon glyph="chevron-right" strokeWidth={1.3} />
    </Trigger>
  ),
  tooltip: title,
  priority: 0,
  title,
  nextTrigger: true,
  index: -3,
});

const getPrevTrigger = (title = i18next.t('Previous')) => ({
  trigger: (
    <Trigger>
      <Icon glyph="chevron-left" strokeWidth={1.3} />
    </Trigger>
  ),
  tooltip: title,
  priority: 0,
  title,
  prevTrigger: true,
  index: -2,
});

const makePages = ({
  content,
  maxItems,
  maxWidth,
  offsetX,
  prevTriggerTitle,
  nextTriggerTitle,
  viewportWidth,
  direction,
}) => {
  const realTriggerWidth = deriveItemsWidth(content);
  let pages;

  if (content.length <= maxItems && realTriggerWidth <= maxWidth) {
    pages = [
      {
        items: getSortedContent(content),
        width: realTriggerWidth,
      },
    ];
  } else {
    let maxPageWidth;

    if (direction === 'right') {
      maxPageWidth = viewportWidth - offsetX;
    } else if (direction === 'left') {
      maxPageWidth = offsetX;
    } else {
      maxPageWidth = viewportWidth - (offsetX - maxWidth / 2);
    }

    pages = splitByPages(getSortedContent(content), maxItems, maxPageWidth);
  }

  return mixPageTrigger(pages, getPrevTrigger(prevTriggerTitle), getNextTrigger(nextTriggerTitle));
};

export default makePages;
