import store from 'src/js/store';
import isDeveloperModeVerifier from '../verifiers/is-developer-mode';

const isDeveloperMode = () => {
  const state = store.getState();

  return isDeveloperModeVerifier(state);
};

export default isDeveloperMode;
