import reasons from '../../constants/reasons';
import on from '../on';
import blockIsDuplicated from './block-is-duplicated';
import blockIsInserted from './block-is-inserted';
import blockIsPasted from './block-is-pasted';
import blockIsRemoved from './block-is-removed';
import blockIsReplaced from './block-is-replaced';
import blockIsShifted from './block-is-shifted';
import blockSettingsAreUpdated from './block-settings-are-updated';

const {
  DUPLICATE_ELEMENT,
  INSERT_BLOCK,
  PASTE_ELEMENT,
  DELETE_ELEMENT,
  REPLACE_ELEMENT,
  SHIFT_ELEMENT,
  SET_ELEMENT_ATTRIBUTE,
  REMOVE_ELEMENT_ATTRIBUTE,
} = reasons;

const init = () => {
  on({ reason: DUPLICATE_ELEMENT }, blockIsDuplicated);
  on({ reason: INSERT_BLOCK }, blockIsInserted);
  on({ reason: PASTE_ELEMENT }, blockIsPasted);
  on({ reason: DELETE_ELEMENT }, blockIsRemoved);
  on({ reason: REPLACE_ELEMENT }, blockIsReplaced);
  on({ reason: SHIFT_ELEMENT }, blockIsShifted);
  on({ reason: SET_ELEMENT_ATTRIBUTE }, blockSettingsAreUpdated);
  on({ reason: REMOVE_ELEMENT_ATTRIBUTE }, blockSettingsAreUpdated);
};

export default init;
