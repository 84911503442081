import blocks from 'src/js/modules/blocks';
import hasBackgroundContainer from './has-background-container';
import isImageContainer from './is-image-container';
import { getSettings } from '../settings';

const setPosition = (blockNode, position) => {
  const settings = getSettings();
  if (blocks.verifiers.isWsBlock(blockNode)) {
    blockNode.setAttribute('background-position', position);
    return;
  }

  if (hasBackgroundContainer(blockNode)) {
    const backgroundContainer = blockNode.querySelector(settings.backgroundContainer);
    backgroundContainer.setAttribute('position', position);
    return;
  }

  if (isImageContainer(blockNode)) {
    blockNode.setAttribute('content-position', position);
  }
};

export default setPosition;
