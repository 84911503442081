import { i18next } from '@yola/ws-sdk';

const extendTreeViewItemsWithActions = ({ items, onSettingsClick, onDeleteClick }) =>
  items.map((item) => {
    const redirectTitle = item.title;
    const redirectIndex = Number(item.order);
    const settingsAction = {
      title: i18next.t('Settings'),
      glyph: 'settings',
      onClick: () => onSettingsClick(redirectIndex),
    };
    const deleteAction = {
      title: i18next.t('Delete'),
      glyph: 'trash',
      onClick: () => onDeleteClick(redirectTitle, redirectIndex),
    };
    const actions = [settingsAction, deleteAction];

    return {
      ...item,
      actions,
    };
  });

export default extendTreeViewItemsWithActions;
