import { utils } from '@yola/ws-sdk';

import createYoutubeLink from './create-youtube-link';
import createFacebookLink from './create-facebook-link';
import createGoogleMapsLink from './create-google-maps-link';

const googleHostnameRegexp = /^(www\.)?google\.([a-z]{2,3})(\.[a-z]{2,3})?$/;

const formatUrl = (targetUrl, iframeId) => {
  const url = utils.forceHTTPS(targetUrl);
  const { hostname, pathname } = new URL(url);

  if (['youtube.com', 'youtu.be'].some((item) => hostname.endsWith(item))) {
    return createYoutubeLink(url);
  }

  if (hostname.endsWith('facebook.com')) {
    return createFacebookLink(url, iframeId);
  }

  if (googleHostnameRegexp.test(hostname) && pathname.startsWith('/maps/')) {
    return createGoogleMapsLink(url);
  }

  return url;
};

export default formatUrl;
