import React from 'react';
import PropTypes from 'prop-types';
import { Loader, Text } from '@yola/ws-ui';

function ChecklistProgressLine({ captions, steps, onClick }) {
  const { all, completed } = steps;
  const progressValue = Math.round((completed * 100) / all);

  return (
    <div className="ws-checklist-progress-line" onClick={onClick}>
      <Text className="ws-checklist-progress-line__heading" type="heading-4">
        {captions.heading}
      </Text>
      <figure className="ws-checklist-progress-line__box">
        <Loader className="ws-checklist-progress-line__loader" value={progressValue} />
        <figcaption className="ws-checklist-progress-line__caption">{captions.title}</figcaption>
      </figure>
    </div>
  );
}

ChecklistProgressLine.propTypes = {
  captions: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  steps: PropTypes.shape({
    all: PropTypes.number.isRequired,
    completed: PropTypes.number.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ChecklistProgressLine;
