import { assets } from '@yola/ws-sdk';
import React from 'react';
import PropTypes from 'prop-types';
import MediaPlaceholder from 'src/js/modules/common/components/media-placeholder';
import BackgroundBox from 'src/js/modules/common/components/background-box';
import isBlob from '../verifiers/is-blob';

function GalleryItemPreview({ src, width, order, style, isPlaceholder }) {
  const { addBaseHref } = assets.helpers;
  const previewSource = isBlob(src) ? src : addBaseHref(src);
  const styles = {
    width: `${width}px`,
    height: `${width}px`,
    ...style,
  };
  const currentOrder = order + 1;

  return (
    <div style={styles} className="ws-gallery-item-preview">
      <div className="ws-gallery-item-preview__background-box">
        {isPlaceholder ? <MediaPlaceholder /> : <BackgroundBox url={previewSource} />}
      </div>
      {currentOrder && (
        <div className="ws-gallery-item__сircle-icon ws-gallery-item__order">{currentOrder}</div>
      )}
    </div>
  );
}

GalleryItemPreview.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  order: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  style: PropTypes.shape(),
  isPlaceholder: PropTypes.bool,
};

GalleryItemPreview.defaultProps = {
  order: null,
  style: {},
  isPlaceholder: false,
};

export default GalleryItemPreview;
