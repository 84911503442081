import actionTypes from './action-types';
import common from './common';
import mediaSources from './media-sources';
import tours from './tours';
import viewConfigurations from './view-configurations';
import wlMediaSources from './wl-media-sources';

export default {
  actionTypes,
  common,
  mediaSources,
  tours,
  viewConfigurations,
  wlMediaSources,
};
