import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import { customization, blockTemplates } from '@yola/ws-sdk';
import ViewWithStaticAssets from '../../blocks/components/view-with-static-assets';
import compileCustomTemplate from '../helpers/compile-custom-template';
import generatePresetLabel from '../helpers/generate-preset-label';
import TabContentBox from './tab-content-box';
import ButtonPreset from './button-preset';

const { Stack } = designSystem;

const BUTTON_PRESET_PREVIEW_BLOCK_TEMPLATE_ID = 'button-preset-preview';

function ButtonsSection(props) {
  const { presets, activePresetId, activePaletteId, activeFontId, captions, onChange } = props;
  const { presetLabel, presetButtonLabel } = captions;
  const buttonPresetPreviewTemplate = useSelector(
    customization.selectors.getButtonPresetPreviewTemplate
  );
  const blockTemplate = useSelector((state) =>
    blockTemplates.selectors.getBlockTemplateById(state, BUTTON_PRESET_PREVIEW_BLOCK_TEMPLATE_ID)
  );

  const customizations = {
    globalOffsetTop: '0px',
    globalOffsetBottom: '0px',
  };
  const dependencies = useMemo(
    () => ({
      js: [],
      css: [],
      ...blockTemplate.dependencies,
    }),
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
    []
  );

  return (
    <div className="ws-buttons-section">
      <ViewWithStaticAssets
        customization={customizations}
        dependencies={dependencies}
        forcedScale={1}
        forcedColorPaletteId={activePaletteId}
        forcedFontPairId={activeFontId}
      >
        <TabContentBox>
          <Stack gap="spacing-m">
            {presets.map((preset) => {
              const extendedPreset = {
                variables: {
                  ...preset.variables,
                  primary_button_label: `${presetButtonLabel} 1`,
                  secondary_button_label: `${presetButtonLabel} 2`,
                },
              };
              const previewHTML = compileCustomTemplate(
                buttonPresetPreviewTemplate,
                extendedPreset
              );

              return (
                <ButtonPreset
                  key={preset.id}
                  id={preset.id}
                  isPremium={Boolean(preset.premium)}
                  title={generatePresetLabel(presetLabel, preset.id)}
                  contentHTML={previewHTML}
                  isSelected={preset.id === activePresetId}
                  onClick={onChange}
                />
              );
            })}
          </Stack>
        </TabContentBox>
      </ViewWithStaticAssets>
    </div>
  );
}

ButtonsSection.propTypes = {
  presets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      variables: PropTypes.shape().isRequired,
    })
  ).isRequired,
  activePresetId: PropTypes.string.isRequired,
  activePaletteId: PropTypes.string.isRequired,
  activeFontId: PropTypes.string.isRequired,
  captions: PropTypes.shape({
    presetLabel: PropTypes.string,
    presetButtonLabel: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ButtonsSection;
