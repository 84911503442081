import { view, bowser } from '@yola/ws-sdk';

const normaliseElementTopValue = ({ element, top, scrollTop }) => {
  if (bowser.ios) {
    return top - scrollTop;
  }

  if (bowser.iPadOS) {
    /**
     * Workaround for iPadOS to calculate
     * element offset properly
     */
    return view.accessors.getElementOffset(element) - scrollTop;
  }
  return top;
};

export default normaliseElementTopValue;
