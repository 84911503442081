import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { i18next, dialogs, utils, view, blocks, linkEditing } from '@yola/ws-sdk';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import getParentBlockByElement from 'src/js/modules/blocks/helpers/get-parent-block-by-element';
import LinkSettingContainer from 'src/js/modules/common/containers/link-setting-container';
import getUpdatedItems from '../helpers/get-updated-items';
import commonHelpers from '../../../common/helpers';

const { getItemClickAction } = commonHelpers;
const { linkTypes } = linkEditing.constants;

const allowedLinkTypes = [
  linkTypes.NO_ACTION,
  linkTypes.PAGE,
  linkTypes.PHONE,
  linkTypes.MAIL,
  linkTypes.DOWNLOAD,
  linkTypes.ANCHOR,
  linkTypes.EXTERNAL,
];

const ItemSettingDialog = ({
  itemId,
  elementId,
  initialConfig,
  currentConfig,
  initialTitle,
  getSharedData,
  resolveSharedData,
  onDialogCancel,
  onDialogMainAction,
}) => {
  const { offsetX, offsetY, items } = getSharedData();
  const index = items.findIndex((item) => item.id === itemId);
  const item = items[index] || {};
  const itemConfig = initialConfig || item.config || linkEditing.constants.defaultConfig;

  const [isOverlayClicked, setIsOverlayClicked] = useState(false);

  const handleClose = useCallback(
    (updatedItems) => {
      dialogs.operations.show(dialogTypes.FOOTER_NAVIGATION_SETTINGS_DIALOG, {
        elementId,
        items: updatedItems || items,
      });
    },
    [items, elementId]
  );

  const onCancel = useCallback(
    ({ offsetX: x, offsetY: y }) => {
      resolveSharedData({
        offsetX: x,
        offsetY: y,
      });

      const element = view.accessors.getLiveElement(elementId);
      const blockElement = getParentBlockByElement(element);

      onDialogCancel({
        blockId: view.accessors.getLiveElementId(blockElement),
        blockVariationId: blocks.accessors.getVariationIdByElement(blockElement),
        dialogType: dialogTypes.FOOTER_NAVIGATION_ITEM_SETTING_DIALOG,
      });

      handleClose();
    },
    [elementId, handleClose, onDialogCancel, resolveSharedData]
  );

  const handleSubmit = useCallback(
    ({ config, title, offsetX: x, offsetY: y, setDownloadUrlAdjusted, customPath }) => {
      resolveSharedData({ offsetX: x, offsetY: y });

      onDialogMainAction({
        elementId,
        triggerId: isOverlayClicked ? 'dialog-background' : 'submit-button',
        itemClickAction: getItemClickAction(config.linkType),
        itemTitleAdjusted: item.title !== title,
        dialogType: dialogTypes.FOOTER_NAVIGATION_ITEM_SETTING_DIALOG,
        addedFile: config.linkType === linkTypes.DOWNLOAD,
        customPath,
        setDownloadUrlAdjusted,
      });

      const updatedItems = getUpdatedItems({
        config,
        title,
        items,
        index,
      });

      handleClose(updatedItems);
    },
    [
      elementId,
      handleClose,
      isOverlayClicked,
      item.title,
      resolveSharedData,
      onDialogMainAction,
      index,
      items,
    ]
  );

  return (
    <LinkSettingContainer
      allowedLinkTypes={allowedLinkTypes}
      initialConfig={itemConfig}
      currentConfig={currentConfig}
      initialTitle={initialTitle || item.title}
      sourceDialog={{
        id: dialogTypes.FOOTER_NAVIGATION_ITEM_SETTING_DIALOG,
        props: {
          itemId,
          elementId,
          getSharedData,
          resolveSharedData,
          onDialogCancel,
          onDialogMainAction,
        },
      }}
      handleCancel={onCancel}
      handleSubmit={handleSubmit}
      offsetX={offsetX}
      offsetY={offsetY}
      setIsOverlayClicked={setIsOverlayClicked}
      captions={{
        dialogTitle: items[index] ? i18next.t('Item settings') : i18next.t('New item'),
        title: i18next.t('Title'),
        actionTitle: i18next.t('Click action'),
        actionSubtitle: i18next.t('When user clicks on the link, take this action'),
        titleAnnotation: i18next.t('Display this name in website navigation'),
        save: i18next.t('Submit'),
      }}
    />
  );
};

ItemSettingDialog.propTypes = {
  itemId: PropTypes.string.isRequired,
  elementId: PropTypes.string.isRequired,
  initialConfig: PropTypes.shape({}),
  currentConfig: PropTypes.shape({}),
  initialTitle: PropTypes.string,
  getSharedData: PropTypes.func,
  resolveSharedData: PropTypes.func,
  onDialogCancel: PropTypes.func,
  onDialogMainAction: PropTypes.func,
};

ItemSettingDialog.defaultProps = {
  initialConfig: null,
  currentConfig: null,
  initialTitle: null,
  getSharedData: utils.noop,
  resolveSharedData: utils.noop,
  onDialogCancel: utils.noop,
  onDialogMainAction: utils.noop,
};

export default ItemSettingDialog;
