import { constants } from '@yola/subscription-manager-js';

const {
  hostingPackageFeatureNames: { SITE_LANGUAGES },
} = constants;

const UNLIMITED = 'unlimited';
const MAX_SITE_LANGUAGES = 1000;

const multilingualLimit = ({ targetPackage }) => ({
  amount:
    targetPackage.features[SITE_LANGUAGES] === UNLIMITED
      ? MAX_SITE_LANGUAGES
      : targetPackage.features[SITE_LANGUAGES],
});

export default multilingualLimit;
