import { extensions, textFormatting } from '@yola/ws-sdk';
import constants from '../constants';

const { FONT_SIZE_MODIFIER_PREFIX, TEXT_INTENT_MODIFIER_PREFIX } = textFormatting.constants;

const shouldShowClearTrigger = (element) => {
  const settings = extensions.accessors.getExtensionSettings(constants.common.SLUG);
  const selection = textFormatting.accessors.getAdvancedSelection();
  const hasFontSizeModifier = selection.focusNode.parentElement.closest(
    `[class^='${FONT_SIZE_MODIFIER_PREFIX}']`
  );
  const hasTextIndentModifier = selection.focusNode.parentElement.closest(
    `[class^='${TEXT_INTENT_MODIFIER_PREFIX}']`
  );

  if (selection.isCollapsed && !hasFontSizeModifier && !hasTextIndentModifier) {
    return false;
  }

  if (settings.clearRestrictedParentsSelector) {
    if (element.closest(settings.clearRestrictedParentsSelector)) return false;
  }

  return element.matches(settings.clearQuerySelector);
};

export default shouldShowClearTrigger;
