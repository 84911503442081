import { extensions } from '@yola/ws-sdk';

import constants from '../constants/common';
import renderPreview from './render-preview';

function initHtmlEmbedElements(rootNode) {
  const { querySelector: selector } = extensions.accessors.getExtensionSettings(constants.slug);
  const elements = Array.from(rootNode.querySelectorAll(selector));

  if (rootNode.matches && rootNode.matches(selector)) {
    elements.push(rootNode);
  }

  elements.forEach(renderPreview);
}

export default initHtmlEmbedElements;
