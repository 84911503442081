const DEFAULT_BORDER_BOUNDS = {
  borderLeftWidth: '0',
  borderRightWidth: '0',
  borderTopWidth: '0',
  borderBottomWidth: '0',
};

const calculateBoundsWithoutBorder = (elementBounds, elementStyles = DEFAULT_BORDER_BOUNDS) => {
  const { top, left, right, bottom, x, y, width, height } = elementBounds;
  const { borderLeftWidth, borderRightWidth, borderTopWidth, borderBottomWidth } = elementStyles;

  return {
    top: top + parseFloat(borderTopWidth),
    bottom: bottom - parseFloat(borderBottomWidth),
    left: left + parseFloat(borderLeftWidth),
    right: right - parseFloat(borderRightWidth),
    x: x + parseFloat(borderLeftWidth),
    y: y - parseFloat(borderBottomWidth),
    width: width - (parseFloat(borderLeftWidth) + parseFloat(borderRightWidth)),
    height: height - (parseFloat(borderTopWidth) + parseFloat(borderBottomWidth)),
  };
};

export default calculateBoundsWithoutBorder;
