import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';
import { view, i18next } from '@yola/ws-sdk';
import highlighter from '../../highlighter';
import isTouchSupport from '../helpers/is-touch-support';
import getTranslateValue from '../helpers/get-translate-value';

function InteractiveTool({ elementId, scrollPosition, tooltipTitle }) {
  const isTouchDevice = isTouchSupport();
  const tooltipRef = useRef(null);
  const [isVisible, setVisibility] = useState(false);
  const [tooltipRec, setTooltipRec] = useState(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const element = view.accessors.getLiveElement(elementId);

    if (!element) {
      return () => {};
    }

    const updatePosition = throttle(({ pageX: x, pageY: y }) => {
      setVisibility(true);
      setPosition({ x, y });
    }, 16);

    const handleMouseEnter = (event) => {
      setVisibility(true);
      updatePosition(event);
      highlighter.operations.show([element], { hovered: true });
    };

    const handleMouseLeave = () => {
      updatePosition.cancel();
      setVisibility(false);
    };

    if (!isTouchDevice) {
      element.addEventListener('mouseenter', handleMouseEnter);
      element.addEventListener('mousemove', updatePosition);
      element.addEventListener('mouseleave', handleMouseLeave);
    }

    highlighter.operations.show([element], { hovered: true });

    return () => {
      if (!isTouchDevice) {
        updatePosition.cancel();
        element.removeEventListener('mouseenter', handleMouseEnter);
        element.removeEventListener('mousemove', updatePosition);
        element.removeEventListener('mouseleave', handleMouseLeave);
      }

      highlighter.operations.hideHovered();
    };
  }, []);

  useEffect(() => {
    setVisibility(false);
  }, [scrollPosition]);

  useEffect(() => {
    setTooltipRec(tooltipRef.current.getBoundingClientRect());
  }, [tooltipRef]);

  return (
    <div
      ref={tooltipRef}
      className="ws-tooltip-for-interactive"
      style={{
        opacity: isVisible && tooltipRec && !isTouchDevice ? 0.8 : 0,
        transform: getTranslateValue(position, tooltipRec, scrollPosition),
      }}
    >
      {tooltipTitle || i18next.t('Customize')}
    </div>
  );
}

InteractiveTool.propTypes = {
  elementId: PropTypes.string.isRequired,
  mousePosition: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  scrollPosition: PropTypes.number,
  tooltipTitle: PropTypes.string,
};

InteractiveTool.defaultProps = {
  mousePosition: {
    x: null,
    y: null,
  },
  scrollPosition: null,
  tooltipTitle: '',
};

export default InteractiveTool;
