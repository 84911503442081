import { i18next } from '@yola/ws-sdk';
import iconTypes from '../constants/icon-types';

const getIconTypeOptions = () => [
  {
    label: i18next.t('Animated and static'),
    value: iconTypes.ANIMATED_AND_STATIC,
  },
  {
    label: i18next.t('Animated'),
    value: iconTypes.ANIMATED,
  },
  {
    label: i18next.t('Static'),
    value: iconTypes.STATIC,
  },
];

export default getIconTypeOptions;
