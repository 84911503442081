import { view } from '@yola/ws-sdk';
import getChildElement from './get-child-element';

const unwrapListItems = (items) => {
  const list = items[0].parentNode;
  const prevItem = items[0].previousElementSibling;
  const listNextSibling = list.nextSibling;
  let lastUnwrapElement = null;

  items.forEach((item) => {
    const child = getChildElement(item);
    if (!prevItem) {
      list.parentNode.insertBefore(child, list);

      return;
    }

    if (list.nextSibling) {
      list.parentNode.insertBefore(child, listNextSibling || list.nextSibling);
    } else {
      list.parentNode.appendChild(child);
    }

    lastUnwrapElement = child;
  });

  items.forEach((item) => {
    item.remove();
  });

  if (prevItem && prevItem.nextElementSibling) {
    const liveDocument = view.accessors.getLiveDocument();
    const newList = liveDocument.createElement(list.tagName);

    while (prevItem.nextElementSibling) {
      newList.appendChild(prevItem.nextElementSibling);
    }

    if (listNextSibling) {
      list.parentNode.insertBefore(newList, lastUnwrapElement.nextSibling || listNextSibling);
    } else {
      list.parentNode.appendChild(newList);
    }
  }

  if (list.children.length === 0) {
    list.remove();
  }
};

export default unwrapListItems;
