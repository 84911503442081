function applyBlockAttribute(node, attribute, value = true) {
  if (value === null) {
    node.removeAttribute(attribute);
    return;
  }
  if (value === true) {
    node.setAttribute(attribute, '');
    return;
  }
  node.setAttribute(attribute, value);
}

export default applyBlockAttribute;
