import { rules } from '@yola/ws-color-rules';
import { generateAccentPalette, HSLColor } from '@yola/yola-palette-generator';
import parseHslaColorString from './parse-hsla-color-string';

const getHSLColor = (color) => new HSLColor(...Object.values(parseHslaColorString(color)));

const getSurfaceLinkColor = (
  { surfaceName, surfaceColor },
  { targetColor, whiteColor, blackColor }
) => {
  const { link } = rules;
  const surface = surfaceName.split('-')[1];

  const targetColorHSL = getHSLColor(targetColor);
  const colors = {
    targetColor: targetColorHSL,
    backgroundColor: getHSLColor(surfaceColor),
    whiteColor: getHSLColor(whiteColor),
    blackColor: getHSLColor(blackColor),
    targetColorPalette: generateAccentPalette(targetColorHSL),
  };

  const HSLObject = link.default[surface](colors);
  const CSSValue = HSLObject.toCSSValue();

  return {
    HSLObject,
    CSSValue,
  };
};

export default getSurfaceLinkColor;
