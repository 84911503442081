import { i18next } from '@yola/ws-sdk';
import getAdvancedColorsKeys from './get-advanced-colors-keys';
import getColorFromVariables from './get-color-from-variables';
import advancedColorKeyToVariableKey from './advanced-color-key-to-variable-key';
import advancedVariableCategoryIds from '../constant/advanced-variable-category-ids';
import advancedVariableIds from '../constant/advanced-variable-ids';

const getGenerateColorItem =
  ({ variables, advancedColors = {} }) =>
  ({ id, title, colorKey, withOpacity = false }) => {
    const color = getColorFromVariables(advancedColorKeyToVariableKey(colorKey), variables);

    if (!color) {
      return null;
    }

    return {
      id,
      title,
      color: `hsl(${color})`,
      colorKey,
      isColorChanged: !!advancedColors[colorKey],
      ...(withOpacity && {
        opacity: variables[`${advancedColorKeyToVariableKey(colorKey)}Opacity`],
      }),
    };
  };

const getAdvancedColorsSections = (colorKey, palette) => {
  const advancedColorsKeys = getAdvancedColorsKeys(colorKey);
  const generateColorItem = getGenerateColorItem(palette);

  return [
    {
      id: advancedVariableCategoryIds.TYPOGRAPHY,
      title: i18next.t('Typography'),
      colorItems: [
        generateColorItem({
          id: advancedVariableIds.SITE_NAME,
          title: i18next.t('Site name'),
          colorKey: advancedColorsKeys.wsSiteName,
          withOpacity: true,
        }),
        generateColorItem({
          id: advancedVariableIds.HEADING_1,
          title: i18next.t('Heading {number}', { number: 1 }),
          colorKey: advancedColorsKeys.wsHeading1,
          withOpacity: true,
        }),
        generateColorItem({
          id: advancedVariableIds.HEADING_2,
          title: i18next.t('Heading {number}', { number: 2 }),
          colorKey: advancedColorsKeys.wsHeading2,
          withOpacity: true,
        }),
        generateColorItem({
          id: advancedVariableIds.HEADING_3,
          title: i18next.t('Heading {number}', { number: 3 }),
          colorKey: advancedColorsKeys.wsHeading3,
          withOpacity: true,
        }),
        generateColorItem({
          id: advancedVariableIds.HEADING_4,
          title: i18next.t('Heading {number}', { number: 4 }),
          colorKey: advancedColorsKeys.wsHeading4,
          withOpacity: true,
        }),
        generateColorItem({
          id: advancedVariableIds.HEADING_5,
          title: i18next.t('Heading {number}', { number: 5 }),
          colorKey: advancedColorsKeys.wsHeading5,
          withOpacity: true,
        }),
        generateColorItem({
          id: advancedVariableIds.HEADING_6,
          title: i18next.t('Heading {number}', { number: 6 }),
          colorKey: advancedColorsKeys.wsHeading6,
          withOpacity: true,
        }),
        generateColorItem({
          id: advancedVariableIds.QUOTE,
          title: i18next.t('Quote'),
          colorKey: advancedColorsKeys.wsBlockquote,
          withOpacity: true,
        }),
        generateColorItem({
          id: advancedVariableIds.PARAGRAPH,
          title: i18next.t('Paragraph'),
          colorKey: advancedColorsKeys.wsParagraph,
          withOpacity: true,
        }),
        generateColorItem({
          id: advancedVariableIds.TEXT_LINK,
          title: i18next.t('Text link'),
          colorKey: advancedColorsKeys.wsLinkText,
          withOpacity: true,
        }),
        generateColorItem({
          id: advancedVariableIds.MARK_TEXT,
          title: i18next.t('Price label'),
          colorKey: advancedColorsKeys.wsMarkText,
          withOpacity: true,
        }),
      ].filter(Boolean),
    },
    {
      id: advancedVariableCategoryIds.NAV_LINKS,
      title: i18next.t('Header navigation'),
      colorItems: [
        generateColorItem({
          id: advancedVariableIds.ITEM_DEFAULT,
          title: i18next.t('Item default'),
          colorKey: advancedColorsKeys.wsNavLink,
        }),
        generateColorItem({
          id: advancedVariableIds.ITEM_ACTIVE,
          title: i18next.t('Item active'),
          colorKey: advancedColorsKeys.wsNavLinkActive,
        }),
      ].filter(Boolean),
    },
    {
      id: advancedVariableCategoryIds.FOOTER_NAV_LINKS,
      title: i18next.t('Footer navigation'),
      colorItems: [
        generateColorItem({
          id: advancedVariableIds.FOOTER_ITEM_DEFAULT,
          title: i18next.t('Item default'),
          colorKey: advancedColorsKeys.wsFooterNavLinkText,
        }),
      ].filter(Boolean),
    },
    {
      id: advancedVariableCategoryIds.DECORATIVE_ELEMENTS,
      title: i18next.t('Decorative Elements'),
      colorItems: [
        generateColorItem({
          id: advancedVariableIds.BORDER,
          title: i18next.t('Border'),
          colorKey: advancedColorsKeys.wsBorder,
        }),
        generateColorItem({
          id: advancedVariableIds.INPUT_BORDER,
          title: i18next.t('Input field border'),
          colorKey: advancedColorsKeys.wsInputBorder,
          withOpacity: true,
        }),
        generateColorItem({
          id: advancedVariableIds.FEATURE_ICONS,
          title: i18next.t('Feature icons'),
          colorKey: advancedColorsKeys.wsFeatureIcon,
        }),
        generateColorItem({
          id: advancedVariableIds.SOCIAL_ICONS,
          title: i18next.t('Social icons'),
          colorKey: advancedColorsKeys.wsSocialIcon,
        }),
        generateColorItem({
          id: advancedVariableIds.CONTACT_INFORMATION_ICONS,
          title: i18next.t('Contact information icons'),
          colorKey: advancedColorsKeys.wsContactIcon,
          withOpacity: true,
        }),
      ].filter(Boolean),
    },
    {
      id: advancedVariableCategoryIds.PRIMARY_BUTTON,
      title: i18next.t('Primary button'),
      colorItems: [
        generateColorItem({
          id: advancedVariableIds.BUTTON_BACKGROUND,
          title: i18next.t('Button background'),
          colorKey: advancedColorsKeys.wsPrimaryButtonBackground,
          withOpacity: true,
        }),
        generateColorItem({
          id: advancedVariableIds.BUTTON_TEXT,
          title: i18next.t('Button text'),
          colorKey: advancedColorsKeys.wsPrimaryButtonText,
          withOpacity: true,
        }),
      ].filter(Boolean),
    },
    {
      id: advancedVariableCategoryIds.SECONDARY_BUTTON,
      title: i18next.t('Secondary button'),
      colorItems: [
        generateColorItem({
          id: advancedVariableIds.BUTTON_BACKGROUND,
          title: i18next.t('Button background'),
          colorKey: advancedColorsKeys.wsSecondaryButtonBackground,
          withOpacity: true,
        }),
        generateColorItem({
          id: advancedVariableIds.BUTTON_TEXT,
          title: i18next.t('Button text'),
          colorKey: advancedColorsKeys.wsSecondaryButtonText,
          withOpacity: true,
        }),
      ].filter(Boolean),
    },
    {
      id: advancedVariableCategoryIds.MEDIA,
      title: i18next.t('Media'),
      colorItems: [
        generateColorItem({
          id: advancedVariableIds.BACKGROUND_OVERLAY,
          title: i18next.t('Background overlay'),
          colorKey: advancedColorsKeys.wsOverlayBackground,
        }),
      ].filter(Boolean),
    },
  ];
};

export default getAdvancedColorsSections;
