// needed in order to disable widgets that cannot be handled using the constraints mechanism
const getConflictingWidgetsIds = (parentElement) => {
  if (parentElement.querySelector('[id*="ws-block-widget-gallery"]')) {
    return ['widget-columns', 'widget-media-with-aside-text'];
  }

  if (parentElement.querySelector('[id*="ws-block-widget-columns"]')) {
    return ['widget-gallery', 'widget-media-with-aside-text'];
  }

  if (parentElement.querySelector('[id*="ws-block-widget-media-with-aside-text"]')) {
    return ['widget-columns', 'widget-gallery'];
  }

  return [];
};

const excludeConflictingWidgets = (widgetsList, parentElement) => {
  const conflictingWidgets = getConflictingWidgetsIds(parentElement);

  return widgetsList.filter(({ id }) => !conflictingWidgets.includes(id));
};

export default excludeConflictingWidgets;
