import { extensions } from '@yola/ws-sdk';
import constants from '../constants';

const hasNoneDefaultImages = (items) => {
  const { defaultAssetsPathPrefix: path } = extensions.accessors.getExtensionSettings(
    constants.common.slug
  );

  return items.some(({ originSrc }) => !originSrc.includes(path));
};

export default hasNoneDefaultImages;
