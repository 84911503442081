import getUsedIndexes from './get-used-indexes';
import getClosestFreeIndex from './get-closest-free-index';
import orderComparator from './order-comparator';

const getSortedItems = (defaultItems, dynamicItems) => {
  const usedIndexes = getUsedIndexes(dynamicItems);
  let currentIndex = -1;

  return [...defaultItems, ...dynamicItems]
    .map((item) => {
      if (item.order !== undefined) return item;

      currentIndex = getClosestFreeIndex(usedIndexes, currentIndex + 1);

      return {
        ...item,
        order: currentIndex,
      };
    })
    .sort(orderComparator);
};

export default getSortedItems;
