import { view } from '@yola/ws-sdk';
import { USER_MODIFIED_ATTR } from '../constants/attributes';

function getUpdatedUserModifiedAttributeValue(elementId, addedValue) {
  const currentValue = view.accessors.getElementAttribute(elementId, USER_MODIFIED_ATTR);

  if (!currentValue) {
    return addedValue;
  }

  // Update current value without duplications of modified attrs names
  const valuesArray = `${currentValue},${addedValue}`.split(',');
  const uniqueValuesArray = [...new Set(valuesArray)];
  const updatedValue = uniqueValuesArray.join(',');

  return updatedValue;
}

export default getUpdatedUserModifiedAttributeValue;
