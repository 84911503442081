import { verifiers } from '@yola/subscription-manager-js';

const onlineStoreLimit = ({ activeSubscriptions, availableSubscriptionTypes }) => {
  const hasActiveEcommerceSubscription = activeSubscriptions.some((subscription) =>
    verifiers.isEcommercePlanType(subscription.type)
  );
  const hasAvailableEcommerceSubscription = availableSubscriptionTypes.some((subscriptionType) =>
    verifiers.isEcommercePlanType(subscriptionType)
  );

  return {
    available: hasActiveEcommerceSubscription || hasAvailableEcommerceSubscription,
    active: hasActiveEcommerceSubscription,
  };
};

export default onlineStoreLimit;
