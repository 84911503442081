import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import classNames from 'classnames';
import { Icon } from '@yola/ws-ui';
import { i18next } from '@yola/ws-sdk';
import user from 'yola-editor/src/js/modules/user';
import upsell from 'yola-editor/src/js/modules/upsell';
import ReferAFriendOnClickInitiator from 'yola-editor/src/js/modules/refer-a-friend/containers/refer-a-friend-on-click-initiator';
import ConfettiAnimation from '../../animation/components/confetti-animation';
import isReferAFriendShouldBeShown from '../../refer-a-friend/helpers/is-refer-a-friend-should-be-shown';
import actions from '../actions';

class PublishSuccessDialogContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleUpsellClick = this.handleUpsellClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleUpsellClick() {
    const { isWhiteLabel, startB2CDomainPurchase, startWLDomainPurchase } = this.props;
    const triggerId = upsell.constants.triggerIds.PUBLISHING_POPUP;

    if (isWhiteLabel) {
      startWLDomainPurchase(triggerId);
    } else {
      startB2CDomainPurchase(triggerId);
    }
  }

  handleClose(...args) {
    const { onDialogCancel, onClose } = this.props;
    onDialogCancel();
    onClose(...args);
  }

  render() {
    const { withUpsell, isReferAFriendAvailable, siteUrl, isFreeDomainAvailable, isWhiteLabel } =
      this.props;

    const showReferAFriend = isReferAFriendShouldBeShown(withUpsell, isReferAFriendAvailable);
    const shouldDisplayWithUpsell = !showReferAFriend && withUpsell;
    const shouldDisplayFreeDomainInfo = !isWhiteLabel && isFreeDomainAvailable;

    const dialogClasses = classNames('dialog', 'dialog--publish-success', {
      'dialog--with-upsell': !showReferAFriend && withUpsell,
      'dialog--with-refer-a-friend': showReferAFriend,
    });

    return (
      <ReactModal
        isOpen
        onRequestClose={this.handleClose}
        className="modal-content"
        overlayClassName="publish-success-dialog-overlay"
        defaultStyles={{ content: {} }}
      >
        <div className="publish-success-dialog-overlay-animation">
          <ConfettiAnimation />
        </div>
        <div className={dialogClasses}>
          <div className="dialog__content">
            <div className="dialog-inner-content">
              <div className="dialog-header">
                <div onClick={this.handleClose} className="dialog__close">
                  <span className="link link--medium link--underline">{i18next.t('Close')}</span>
                </div>
              </div>
              <div className="dialog-body">
                <div className="dialog-body__content">
                  {!withUpsell && !showReferAFriend && (
                    <span className="dialog-body__emoji" role="img" aria-label="Party Popper">
                      &#x1f389;
                    </span>
                  )}
                  <p className="dialog-body__text">
                    {i18next.t('You did it! Your website is now online here:')}
                  </p>
                  <a
                    href={siteUrl}
                    className="link link--large link--underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="link-text">{siteUrl}</span>
                    <span className="link-icon">
                      <Icon glyph="external-link" size="18" />
                    </span>
                  </a>
                </div>
                {shouldDisplayWithUpsell && shouldDisplayFreeDomainInfo && (
                  <div className="additional-info">
                    <div className="freedomain">
                      <h3 className="freedomain__title">
                        {i18next.t('Your annual plan includes a free custom domain')}
                      </h3>
                      <p className="freedomain__text">
                        {i18next.t(
                          'Websites with custom domains do better on Google. Ready to take your website to the next level?'
                        )}
                      </p>
                      <button type="button" className="btn" onClick={this.handleUpsellClick}>
                        {i18next.t('Get free domain')}
                      </button>
                    </div>
                  </div>
                )}
                {shouldDisplayWithUpsell && !shouldDisplayFreeDomainInfo && (
                  <div className="additional-info">
                    <div className="upsell">
                      <h3 className="upsell__title">
                        {i18next.t('Ready to take your website to the next level?')}
                      </h3>
                      <p className="upsell__text">
                        {i18next.t(
                          'Do you know that websites with custom domains do better on Google? Add your custom domain and double your site visitors!'
                        )}
                      </p>
                      <button type="button" className="btn" onClick={this.handleUpsellClick}>
                        {i18next.t('Get your domain')}
                      </button>
                    </div>
                  </div>
                )}
                {showReferAFriend && !shouldDisplayFreeDomainInfo && (
                  <div className="additional-info">
                    <div className="refer-a-friend">
                      <h3 className="refer-a-friend__title">
                        {i18next.t('Recommend Yola to a friend')}
                      </h3>
                      <p className="refer-a-friend__text">
                        {i18next.t(
                          'Enjoying building a site on Yola? Invite a friend or colleague from your network to build their site with Yola'
                        )}
                      </p>
                      <ReferAFriendOnClickInitiator>
                        <button type="button" className="btn">
                          {i18next.t('Invite friends')}
                        </button>
                      </ReferAFriendOnClickInitiator>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
    );
  }
}

PublishSuccessDialogContainer.propTypes = {
  onClose: PropTypes.func.isRequired,
  startB2CDomainPurchase: PropTypes.func.isRequired,
  startWLDomainPurchase: PropTypes.func.isRequired,
  withUpsell: PropTypes.bool,
  siteUrl: PropTypes.string.isRequired,
  isWhiteLabel: PropTypes.bool.isRequired,
  isReferAFriendAvailable: PropTypes.bool.isRequired,
  isFreeDomainAvailable: PropTypes.bool.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

PublishSuccessDialogContainer.defaultProps = {
  withUpsell: false,
};

const mapStateToProps = (state) => ({
  isWhiteLabel: user.selectors.getWhiteLabelStatus(state),
});

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(actions.hide()),
  startB2CDomainPurchase: (triggerId) => dispatch(upsell.actions.startB2CDomainPurchase(triggerId)),
  startWLDomainPurchase: (triggerId) => dispatch(upsell.actions.startWLDomainPurchase(triggerId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PublishSuccessDialogContainer);
