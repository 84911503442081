import { anodum, hdrm, view, template } from '@yola/ws-sdk';

const buildPictureElement = (imageAttributes) => {
  const liveDocument = view.accessors.getLiveDocument();
  const { defaultView } = liveDocument;
  const mediaTemplates = defaultView.customElements
    .get(hdrm.constants.IMAGE_SELECTOR)
    .getTemplates();
  const mediaContainer = template.verifiers.isMpt()
    ? mediaTemplates.pictureContainer(imageAttributes)
    : mediaTemplates.imageContainer(imageAttributes);

  return anodum.parseElementFromString(mediaContainer);
};

export default buildPictureElement;
