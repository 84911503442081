import actionTypes from '../constants/action-types';

const showAnchors = (elements) => ({
  type: actionTypes.SHOW_ANCHOR_HIGHLIGHTERS,
  payload: {
    elements,
  },
});

export default showAnchors;
