import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Render, Tooltip } from '@yola/ws-ui';
import { bowser } from '@yola/ws-sdk';
import UploadImageContainer from 'src/js/modules/common/containers/upload-image-container';
import ReplaceImageButton from 'src/js/modules/common/components/replace-image-button';
import MediaPreview from 'src/js/modules/common/components/media-preview';
import focalPoint from 'src/js/modules/focal-point';
import backgroundOptionTypes from '../constants/background-option-types';

const {
  constants: { common: focalPointConstants },
} = focalPoint;

const BlockBackgroundImageOption = ({
  captions,
  backgroundUrl,
  onCrop,
  onClick,
  onImageReplace,
  openSettings,
  onTriggerUploadImageClick,
  onTriggerBrowseStockPhotosClick,
  onWrongFileType,
  blockId,
  onImageUploadCancel,
  isBackgroundPlaceholder,
}) => (
  <React.Fragment>
    <div className="ws-block-background">
      <MediaPreview size="contain" url={isBackgroundPlaceholder ? '' : backgroundUrl} />
      {!isBackgroundPlaceholder && (
        <div
          className="ws-block-background__crop-control"
          data-tip={captions.cropBtnTooltip}
          data-for="crop"
          onClick={onCrop}
        >
          <Icon glyph="crop" size="24" strokeWidth="1.3" stroke="#ffffff" />
          <Render if={!bowser.mobile}>
            <Tooltip id="crop" />
          </Render>
        </div>
      )}
    </div>

    <UploadImageContainer
      onImageUploadEnd={onImageReplace}
      onImageUploadCancel={onImageUploadCancel}
      preserveSharedData
    >
      {(imageContainerProps) => (
        <ReplaceImageButton
          id={backgroundOptionTypes.BACKGROUND_OPTION}
          label={isBackgroundPlaceholder ? captions.addMediaLabel : captions.replaceMediaLabel}
          elementId={blockId}
          accept={focalPointConstants.IMAGE_ACCEPT_TYPE}
          onPhotoStockDialogCancel={openSettings}
          {...imageContainerProps}
          onClick={onClick}
          onTriggerUploadImageClick={onTriggerUploadImageClick}
          onTriggerBrowseStockPhotosClick={onTriggerBrowseStockPhotosClick}
          onWrongFileType={onWrongFileType}
        />
      )}
    </UploadImageContainer>
  </React.Fragment>
);

BlockBackgroundImageOption.propTypes = {
  captions: PropTypes.shape({
    replaceMediaLabel: PropTypes.string,
    addMediaLabel: PropTypes.string,
    cropBtnTooltip: PropTypes.string,
  }).isRequired,
  blockId: PropTypes.string.isRequired,
  backgroundUrl: PropTypes.string.isRequired,
  isBackgroundPlaceholder: PropTypes.bool,
  onCrop: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onImageReplace: PropTypes.func.isRequired,
  openSettings: PropTypes.func.isRequired,
  onTriggerUploadImageClick: PropTypes.func.isRequired,
  onTriggerBrowseStockPhotosClick: PropTypes.func.isRequired,
  onWrongFileType: PropTypes.func.isRequired,
  onImageUploadCancel: PropTypes.func,
};

BlockBackgroundImageOption.defaultProps = {
  isBackgroundPlaceholder: false,
  onImageUploadCancel: Function.prototype,
};

export default BlockBackgroundImageOption;
