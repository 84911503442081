import { blocks, extensions } from '@yola/ws-sdk';
import assignGroupId from 'src/js/modules/extensions/common/helpers/assign-group-id';
import config from '../constants/config';
import { MEDIA_GROUP_ATTRIBUTE } from '../constants/media-container';

function updateWidgetMediaGroupId(staticElement) {
  const settings = extensions.accessors.getExtensionSettings(config.slug);

  const widgetsWithMediaContainer = Array.from(
    staticElement.querySelectorAll(`[id^=${blocks.constants.WIDGET_ID_PREFIX}]`)
  ).filter((widgetElement) => widgetElement.querySelector(settings.imageContainer));

  if (!widgetsWithMediaContainer.length) return;

  widgetsWithMediaContainer.forEach((widget) => {
    assignGroupId({
      container: widget,
      querySelector: settings.imageContainer,
      groupAttribute: MEDIA_GROUP_ATTRIBUTE,
    });
  });
}

export default updateWidgetMediaGroupId;
