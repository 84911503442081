import store from 'src/js/store';
import show from '../actions/show';
import hide from '../actions/hide';

const DEFAULT_DURATION = 1000;

const highlightElements = (elements, duration = DEFAULT_DURATION) =>
  new Promise((resolve) => {
    store.dispatch(show(elements));

    setTimeout(() => {
      store.dispatch(hide(elements));
      resolve();
    }, duration);
  });

export default highlightElements;
