import { view } from '@yola/ws-sdk';
import getInitialBackgroundOptionName from './get-initial-background-option-name';
import getBackgroundImageSource from '../get-background-image-sourse';
import optionTypes from '../../constants/background-option-types';

const { BACKGROUND_OPTION } = optionTypes;

const createBackgroundOption = ({ item, blockNode, backgroundOptions, variationId }) => {
  const { id, attributes, ...rest } = item;

  const elementId = view.accessors.getLiveElementId(blockNode);
  const existedOptions =
    backgroundOptions && backgroundOptions.find((option) => option.id === item.id);

  const backgroundAttributes = Object.keys(attributes).reduce((accum, attributeName) => {
    const clone = { ...accum };
    const liveValue = blockNode.getAttribute(attributeName);

    const propertyName = attributes[attributeName];
    const initialPropertyName = getInitialBackgroundOptionName(propertyName);
    clone[propertyName] =
      attributeName === 'background-image' ? getBackgroundImageSource(blockNode) : liveValue;
    clone[initialPropertyName] = existedOptions ? existedOptions[initialPropertyName] : liveValue;

    return clone;
  }, {});

  return {
    id,
    type: BACKGROUND_OPTION,
    blockNode,
    elementId,
    variationId,
    attributesMap: attributes,
    ...rest,
    ...backgroundAttributes,
  };
};

export default createBackgroundOption;
