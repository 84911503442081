import actionTypes from '../constants/action-types';

const userPreferences = (state = null, action) => {
  switch (action.type) {
    case actionTypes.SET_USER_PREFERENCES:
      return action.payload.userPreferences;
    case actionTypes.UPDATE_USER_PREFERENCE: {
      const { property, value } = action.payload;
      return {
        ...state,
        [property]: value,
      };
    }
    default:
      return state;
  }
};

export default userPreferences;
