import { useEffect } from 'react';
import helpers from '../../../../hotkeys/helpers';

const { isRedoEvent, isUndoEvent } = helpers;

const useUndoRedoPrevention = () => {
  useEffect(() => {
    const documentKeydownHandler = (event) => {
      if (isRedoEvent(event) || isUndoEvent(event)) {
        event.stopImmediatePropagation();
      }
    };

    window.addEventListener('keydown', documentKeydownHandler, true);

    return () => window.removeEventListener('keydown', documentKeydownHandler, true);
  }, []);
};

export default useUndoRedoPrevention;
