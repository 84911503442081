import { constraints } from '@yola/ws-sdk';

function makeConstraintData(element, type, constraint) {
  return {
    element,
    type,
    constraint,
  };
}

function getConstraintsDataFromSameBoundsElements(sameBoundsElements) {
  let dragConstraintData;
  let cloneConstraintData;
  let removeConstraintData;
  let moveConstraintData;
  let alignConstraintData;
  let hideConstraintData;

  // We consider, that the first found element in the list has the higher priority
  sameBoundsElements.forEach((interactiveElement) => {
    if (!interactiveElement) return;

    const { element, type } = interactiveElement;

    if (!dragConstraintData) {
      const dragConstraint = constraints.accessors.getDragConstraintForElement(element);
      dragConstraintData = dragConstraint && makeConstraintData(element, type, dragConstraint);
    }

    if (!cloneConstraintData) {
      const cloneConstraint = constraints.accessors.getCloneConstraintForElement(element);
      cloneConstraintData = cloneConstraint && makeConstraintData(element, type, cloneConstraint);
    }

    if (!removeConstraintData) {
      const removeConstraint = constraints.accessors.getRemoveConstraintForElement(element);
      removeConstraintData =
        removeConstraint && makeConstraintData(element, type, removeConstraint);
    }

    if (!moveConstraintData) {
      const moveConstraint = constraints.accessors.getMoveConstraintForElement(element);
      moveConstraintData = moveConstraint && makeConstraintData(element, type, moveConstraint);
    }

    if (!alignConstraintData) {
      const alignConstraint = constraints.accessors.getAlignConstraintForElement(element);
      alignConstraintData = alignConstraint && makeConstraintData(element, type, alignConstraint);
    }

    if (!hideConstraintData) {
      const hideConstraint = constraints.accessors.getHideConstraintForElement(element);
      hideConstraintData = hideConstraint && makeConstraintData(element, type, hideConstraint);
    }
  });

  return {
    dragConstraintData,
    cloneConstraintData,
    removeConstraintData,
    moveConstraintData,
    alignConstraintData,
    hideConstraintData,
  };
}

export default getConstraintsDataFromSameBoundsElements;
