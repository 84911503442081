import React from 'react';
import { designSystem } from '@yola/ws-ui';

const { SkeletonFill, TextSkeleton, Paragraph, Heading } = designSystem;

// The button skeleton is not from design system, but custom.
// This is done to match the animation with other elements.
const PreviewSkeletonBtn = () => (
  <div className="ws-preview-skeleton__btn">
    <SkeletonFill />
    <div className="ws-preview-skeleton__btn-inner">
      <div className="ws-preview-skeleton__btn-content">
        <SkeletonFill />
      </div>
    </div>
  </div>
);

const PreviewSkeleton = () => (
  <div className="ws-preview-skeleton">
    <div className="ws-preview-skeleton__text-block">
      <div className="ws-preview-skeleton__superscript">
        <Paragraph>
          <TextSkeleton />
        </Paragraph>
      </div>
      <div className="ws-preview-skeleton__title">
        <Heading type="heading-1">
          <TextSkeleton lines={2} />
        </Heading>
      </div>
      <div className="ws-preview-skeleton__subtitle">
        <Heading type="heading-2">
          <TextSkeleton lines={1} />
        </Heading>
      </div>
      <div className="ws-preview-skeleton__text">
        <Paragraph>
          <TextSkeleton lines={3} />
        </Paragraph>
      </div>
      <div className="ws-preview-skeleton__buttons">
        <PreviewSkeletonBtn />
        <PreviewSkeletonBtn />
      </div>
    </div>
    <div className="ws-preview-skeleton__image-block">
      <div className="ws-preview-skeleton__image">
        <SkeletonFill />
      </div>
    </div>
  </div>
);

export default React.memo(PreviewSkeleton);
