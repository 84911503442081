import clonedeep from 'lodash.clonedeep';
import orderComparator from './order-comparator';
import { HELP_MENU_ID } from '../constants';

function getComputedItems(props) {
  const { items, helpMenuItems } = props;

  const sortedHelpMenu = helpMenuItems.sort(orderComparator);

  const newItems = items.map((item) => {
    const currentItem = clonedeep(item);
    const { subMenu } = currentItem;

    if (currentItem.id === HELP_MENU_ID && subMenu) {
      subMenu.submenu = sortedHelpMenu;
    }

    return currentItem;
  });

  return newItems;
}

export default getComputedItems;
