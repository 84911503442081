import { extensions } from '@yola/ws-sdk';
import config from '../constants/config';
import isPositioningImage from './is-positioning-image';

const getPositioningImages = (blockElement) => {
  const { imagePositioningSelector } = extensions.accessors.getExtensionSettings(config.slug);
  const positioningImages = [...blockElement.querySelectorAll(imagePositioningSelector)];

  if (positioningImages.length) {
    return positioningImages.filter((element) => isPositioningImage(element));
  }

  return [];
};

export default getPositioningImages;
