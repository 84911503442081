import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@yola/ws-ui';
import bowser from 'yola-bowser';

function ModalDialog({ children, modalHeight, modalWidth, ...otherProps }) {
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  const [modalPosition, updatePosition] = useState({
    offsetX: windowWidth / 2 - modalWidth / 2,
    offsetY: windowHeight / 2 - modalHeight / 2,
  });

  const onDragEnd = (_, { x, y }) => {
    updatePosition({ offsetX: x, offsetY: y });
  };

  return (
    <Modal
      offsetX={modalPosition.offsetX}
      offsetY={modalPosition.offsetY}
      width={modalWidth}
      maxWidth={modalWidth}
      minWidth={modalWidth}
      height={modalHeight}
      fullscreen={bowser.mobile}
      dragHandleSelector=".drag-element"
      floatingModalElementSelector=".ws-context-menu"
      onDragEnd={onDragEnd}
      {...otherProps}
    >
      {children}
    </Modal>
  );
}
ModalDialog.propTypes = {
  children: PropTypes.array.isRequired,
  modalHeight: PropTypes.number.isRequired,
  modalWidth: PropTypes.number.isRequired,
};

ModalDialog.propTypes = {
  modalHeight: PropTypes.number.isRequired,
  modalWidth: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default ModalDialog;
