import get from 'lodash.get';
import { constants } from '@yola/subscription-manager-js';

const {
  hostingPackageFeatureNames: { CUSTOM_DOMAIN },
} = constants;

const customDomainLimit = ({ targetPackage }) => ({
  available: get(targetPackage, ['features', CUSTOM_DOMAIN], false),
});

export default customDomainLimit;
