import React, { useEffect, useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import { contentEditable, i18next } from '@yola/ws-sdk';
import withSelection from 'src/js/modules/utils/with-selection';
import analytics from 'src/js/modules/analytics';
import usePaneSelectHeight from 'src/js/modules/control-pane/helpers/use-pane-select-height';
import navbar from 'src/js/modules/navbar';
import useFontSizeControl from '../helpers/use-font-size-control';
import changeFontSize from '../helpers/change-font-size';
import restoreRange from '../helpers/restore-range';
import constants from '../../../../common/constants';
import textFormattingConstants from '../constants';
import normalizeFontSizeValue from '../helpers/normalize-font-size-value';
import isElementOutOfViewport from '../../../../blocks/helpers/is-element-out-of-viewport';

const {
  segment: {
    trackers: { trackTextElementSizeAdjusted },
  },
} = analytics;

const {
  common: { ENTER_KEYCODE },
} = constants;

const { Autocomplete, SimpleSelect, Popover, PopoverTrigger, PopoverContent } = designSystem;

const { MAX_SAFE_FONT_SIZE, FONT_SIZE_POPOVER_WIDTH } = textFormattingConstants.common;

const MAX_MENU_HEIGHT = 512;
const POPOVER_HEIGHT = 80;

const getPopoverPlacement = (element, scrollPosition) => {
  if (
    !element ||
    (element &&
      isElementOutOfViewport(
        element,
        POPOVER_HEIGHT + navbar.accessors.getHeight(),
        scrollPosition
      ))
  ) {
    return 'top-center';
  }

  return 'bottom-center';
};

function FonSizeSelect({ elementId, getContext, isTablet }) {
  const fontSizeSelectRef = useRef(null);
  const fontSizeSelectContainerRef = useRef(null);
  const { selection, focusElements, options, currentValue } = useFontSizeControl(elementId);
  const [inputValue, setInputValue] = useState(currentValue);
  const { scrollPosition, controlPane } = getContext();
  const [isFocused, setIsFocused] = useState(false);
  const [isTriggerHovered, setIsTriggerHovered] = useState(false);
  const popoverPlacement = useMemo(
    () => getPopoverPlacement(fontSizeSelectContainerRef?.current, scrollPosition),
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
    [isTriggerHovered]
  );

  usePaneSelectHeight({ offsetY: controlPane.position.y, scrollPosition });

  useEffect(() => {
    setInputValue(currentValue);

    const optionToSelect = options.find((option) => option.value === currentValue);
    if (currentValue && optionToSelect) {
      fontSizeSelectRef?.current?.selectOption(optionToSelect);
    }
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, [currentValue]);

  const handleSelect = (option) => {
    const fontSize = option?.label || '';
    if (fontSize === currentValue) return;

    contentEditable.operations.addCheckpoint();

    const newFontSize =
      inputValue === currentValue ? fontSize : normalizeFontSizeValue(inputValue, currentValue);
    changeFontSize(newFontSize, focusElements);

    try {
      restoreRange(selection, elementId);
    } catch (error) {
      console.error(error);
    }

    controlPane.forceControlPaneRerender();
    trackTextElementSizeAdjusted({ elementId, fontSize: newFontSize });
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === ENTER_KEYCODE) {
      handleSelect({ label: inputValue });
    }
  };

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleMouseEnter = (event) => {
    setIsTriggerHovered(!event.target?.closest('.ws-ui-base-select-menu'));
  };

  const handleMouseLeave = () => {
    setIsTriggerHovered(false);
  };

  return (
    <Popover
      width={FONT_SIZE_POPOVER_WIDTH}
      placement={popoverPlacement}
      appearance="dark"
      mode="popover"
      isOpen={isFocused && inputValue > MAX_SAFE_FONT_SIZE && isTriggerHovered}
      contentRef={fontSizeSelectContainerRef}
    >
      <PopoverTrigger>
        <div
          className="ws-font-size-select"
          ref={fontSizeSelectContainerRef}
          onMouseOver={handleMouseEnter}
          onFocus={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {isTablet ? (
            <SimpleSelect
              ref={fontSizeSelectRef}
              placeholder="..."
              options={options}
              onChange={handleSelect}
              onFocus={handleFocus}
              onBlur={handleBlur}
              size="medium"
            />
          ) : (
            <Autocomplete
              ref={fontSizeSelectRef}
              placeholder="..."
              options={options}
              inputValue={inputValue}
              onChange={handleSelect}
              onInputChange={handleInputChange}
              filterOptionsFunction={(value) => value}
              size="medium"
              shouldMarkSelectedOption
              withDropdownIndicator
              blurInputOnSelect
              clearable={false}
              onKeyDown={handleKeyDown}
              maxMenuHeight={MAX_MENU_HEIGHT}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
          )}
        </div>
      </PopoverTrigger>
      <PopoverContent>
        <b>{i18next.t('Note:')} </b>
        {i18next.t('Font sizes over 64px will be scaled down on tablets and mobile devices.')}
      </PopoverContent>
    </Popover>
  );
}

FonSizeSelect.propTypes = {
  elementId: PropTypes.string.isRequired,
  getContext: PropTypes.func.isRequired,
  isTablet: PropTypes.bool.isRequired,
};

export default withSelection(FonSizeSelect);
