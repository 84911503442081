import FastAverageColor from 'fast-average-color';
import { converters, HSLColor, RGBColor } from '@yola/yola-palette-generator';
import contrast from 'get-contrast';

import getImageByMeta from './get-image-by-meta';
import makeCoveredImage from './make-covered-image';
import getCanvasArea from './get-canvas-area';
import parseHslaColorString from '../../../../website-design/helpers/parse-hsla-color-string';
import { SUBMODULE_NAME } from '../constants';
import getSubmoduleConfig from '../../../helpers/get-submodule-config';

const isTextReadable = async (canvasProperties, imgMeta, overlay, textAreas) => {
  const { width, height, background } = canvasProperties;
  const { contrastTreshold } = getSubmoduleConfig(SUBMODULE_NAME);

  return getImageByMeta(imgMeta)
    .then((img) => {
      const canvas = makeCoveredImage(
        img,
        width,
        height,
        background,
        imgMeta.filter,
        overlay,
        imgMeta.context
      );
      const colorExtractor = new FastAverageColor();

      const textColors = textAreas.map((area) => {
        const canvasArea = getCanvasArea(canvas, area, imgMeta.context);
        const areaColor = colorExtractor.getColor(canvasArea);
        const hslObject = parseHslaColorString(area.color);

        return {
          text: new HSLColor(hslObject.h, hslObject.s, hslObject.l, hslObject.a),
          area: converters.hex2hsl(areaColor.hex),
        };
      });

      return textColors.every((color) => {
        const areaColorRGBAObject = converters.hsl2rgb({
          hue: color.area.hue,
          saturation: color.area.saturation,
          lightness: color.area.lightness,
          alpha: color.area.alpha,
        });
        const textColorRGBAObject = converters.hsl2rgb({
          hue: color.text.hue,
          saturation: color.text.saturation,
          lightness: color.text.lightness,
          alpha: color.text.alpha,
        });
        const areaRGBA = new RGBColor(
          areaColorRGBAObject.red,
          areaColorRGBAObject.green,
          areaColorRGBAObject.blue,
          areaColorRGBAObject.alpha
        );
        const textRGBA = new RGBColor(
          textColorRGBAObject.red,
          textColorRGBAObject.green,
          textColorRGBAObject.blue,
          textColorRGBAObject.alpha
        );

        return (
          contrast.ratio(areaRGBA.toCSSValue(), textRGBA.toCSSValue(), { ignoreAlpha: false }) >=
          contrastTreshold
        );
      });
    })
    .catch(() => true);
};

export default isTextReadable;
