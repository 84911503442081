import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import Render from './render';
import noop from '../utils/noop';

const LOADER_STEP = 13;

class Loader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      actualValue: 0,
      isActive: false,
    };

    this.updateLoaderState = this.updateLoaderState.bind(this);
    this.updateProgress = this.updateProgress.bind(this);
  }

  componentDidMount() {
    this.updateLoaderState();
  }

  componentDidUpdate(prevProps) {
    clearTimeout(this.tickTimer);

    this.updateLoaderState();

    const { value } = this.props;

    if (prevProps.value > value) {
      this.resetActualValue();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.tickTimer);
  }

  resetActualValue() {
    this.setState({ actualValue: 0 });
  }

  runLoader() {
    this.setState({
      isActive: true,
    });
  }

  stopLoader() {
    clearTimeout(this.tickTimer);

    this.setState({
      isActive: false,
    });
  }

  updateProgress() {
    const { actualValue } = this.state;

    this.setState({
      actualValue: actualValue + 1,
    });
  }

  updateLoaderState() {
    const { value } = this.props;
    const { actualValue, isActive } = this.state;

    if (value === null) {
      return null;
    }

    if (!isActive && actualValue < 100) {
      return this.runLoader();
    }

    if (isActive && actualValue >= 100) {
      return this.stopLoader();
    }

    if (value > actualValue) {
      this.tickTimer = setTimeout(this.updateProgress, LOADER_STEP);
    }

    return null;
  }

  render() {
    const { type, isShown, collapse, className, onStart, onEnd } = this.props;
    const { actualValue, isActive } = this.state;
    const isVisible = (type === 'indeterminate' && isShown) || isActive;

    return (
      <div className={classNames('loader', className)}>
        <CSSTransition
          in={isVisible}
          classNames="ws-loader-scale"
          onEntered={onStart}
          onExited={onEnd}
          timeout={350}
          unmountOnExit
        >
          <div className={classNames('loader__inner', `loader__inner--${collapse}`)}>
            <Render if={type === 'determinate'}>
              <div
                className="loader__determinate"
                style={{ transform: `translate3d(${actualValue - 100}%, 0, 0)` }}
              />
            </Render>
            <Render if={type === 'indeterminate'}>
              <div className="loader__indeterminate" />
            </Render>
          </div>
        </CSSTransition>
      </div>
    );
  }
}

Loader.defaultProps = {
  onStart: noop,
  onEnd: noop,
  className: null,
  collapse: 'down',
  value: null,
  isShown: true,
  type: 'determinate',
};

Loader.propTypes = {
  isShown: PropTypes.bool,
  onStart: PropTypes.func,
  onEnd: PropTypes.func,
  className: PropTypes.string,
  collapse: PropTypes.string,
  value: PropTypes.number,
  type: PropTypes.oneOf(['determinate', 'indeterminate']),
};

export default Loader;
