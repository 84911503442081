import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { i18next, site } from '@yola/ws-sdk';
import segment from 'src/js/modules/analytics/segment';
import useMatchBreakpoint from 'src/js/modules/utils/custom-hooks/use-match-breakpoint';
import dialogs from '../../../dialogs';
import user from '../../../user';
import helpers from '../../helpers';
import constant from '../../constants';
import scoreTypes from '../../constants/score-types';
import AiSurveyDialog from '../components/ai-survey-dialog';

const {
  constants: { events },
  trackers: { trackEvent },
} = segment;
const { SURVEY_STATUS_POSTPONED, SURVEY_STATUS_COMPLETED, SURVEY_STATUS_FINISHED } = constant;

const MIN_SCORE = 1;
const MAX_SCORE = 5;
const DESKTOP_VIEW_BREAKPOINT = '480px';

const getSelectedScoreType = (score) => {
  switch (true) {
    case score === MAX_SCORE:
      return scoreTypes.HIGH;
    case score === 4:
      return scoreTypes.AVERAGE;
    case score < 4:
      return scoreTypes.LOW;
    default:
      return null;
  }
};

const getCaptions = () => ({
  feature: i18next.t('AI features'),
  title: i18next.t('How would you rate your experience with our AI features?'),
  minScoreTitle: i18next.t('{minScore} - Very dissatisfied', { minScore: MIN_SCORE }),
  maxScoreTitle: i18next.t('{maxScore} - Very satisfied', { maxScore: MAX_SCORE }),
  fieldLabel: i18next.t('What is one thing we could do to improve your experience?'),
  fieldPlaceholder: i18next.t('Add your response here...'),
  submitLabel: i18next.t('Submit'),
  remindLabel: i18next.t('Remind later'),
});

function AiSurveyDialogContainer({ onDialogMainAction, onDialogCancel }) {
  const dispatch = useDispatch();
  const captions = getCaptions();
  const isDesktopView = useMatchBreakpoint(DESKTOP_VIEW_BREAKPOINT);
  const { aiSurveyStatus, latestAiScore } = useSelector(user.selectors.getUserPreferences);
  const businessCategory = useSelector(site.selectors.getBusinessCategory);
  const [score, setScore] = useState();
  const [feedback, setFeedback] = useState(null);

  useEffect(() => {
    trackEvent(events.AI_FEEDBACK_DISPLAYED, { businessCategory });
  }, []);

  const handleScoreChange = (value) => {
    setScore(value);
  };

  const handleFeedbackChange = (value) => {
    setFeedback(value);
  };

  const handleSubmit = () => {
    onDialogMainAction();
    trackEvent(events.AI_FEEDBACK_COMPLETED, {
      aiScore: score,
      aiFeedback: feedback,
      latestAiScore: latestAiScore ? Number(latestAiScore) : null,
      businessCategory,
    });

    if (latestAiScore && aiSurveyStatus === SURVEY_STATUS_COMPLETED) {
      dispatch(
        user.actions.updateUserPreference(user.preferences.AI_SURVEY_STATUS, SURVEY_STATUS_FINISHED)
      );
    } else {
      dispatch(
        user.actions.updateUserPreference(
          user.preferences.AI_SURVEY_STATUS,
          SURVEY_STATUS_COMPLETED
        )
      );
    }

    dispatch(
      user.actions.updateUserPreference(
        user.preferences.AI_SURVEY_UPDATED_AT,
        helpers.getCurrentDateTime()
      )
    );

    dispatch(user.actions.updateUserPreference(user.preferences.LATEST_AI_SCORE, score));

    dialogs.operations.show(dialogs.dialogTypes.SURVEY_THANK_YOU, {
      captions: {
        mainAction: i18next.t('Dismiss'),
      },
      onMainAction: () => dialogs.operations.hide(),
      onClose: () => dialogs.operations.hide(),
    });
  };

  const handleClose = () => {
    onDialogCancel();
    trackEvent(events.AI_FEEDBACK_POSTPONED, { businessCategory });

    dispatch(
      user.actions.updateUserPreference(
        user.preferences.AI_SURVEY_UPDATED_AT,
        helpers.getCurrentDateTime()
      )
    );

    dispatch(
      user.actions.updateUserPreference(
        user.constants.preferences.AI_SURVEY_STATUS,
        SURVEY_STATUS_POSTPONED
      )
    );

    dialogs.operations.hide();
  };

  return (
    <AiSurveyDialog
      minScore={MIN_SCORE}
      maxScore={MAX_SCORE}
      selectedScore={score}
      scoreType={getSelectedScoreType(score)}
      captions={captions}
      isDesktopView={isDesktopView}
      onScoreChange={handleScoreChange}
      onFeedbackChange={handleFeedbackChange}
      onSubmit={handleSubmit}
      onClose={handleClose}
    />
  );
}

AiSurveyDialogContainer.propTypes = {
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

export default AiSurveyDialogContainer;
