import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';

const { Paragraph, SolidIcon, HorizontalStack } = designSystem;

const FeatureItem = ({ text }) => (
  <div className="ws-upgrade-interruption-dialog__label-items">
    <HorizontalStack gap="spacing-2-xs" verticalAlign="start">
      <SolidIcon glyph="check" type="success" size="16" />
      <Paragraph>{text}</Paragraph>
    </HorizontalStack>
  </div>
);

FeatureItem.propTypes = {
  text: PropTypes.string.isRequired,
};

export default FeatureItem;
