import React, { createRef } from 'react';
import bowser from 'yola-bowser';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash.get';
import {
  site,
  dialogs,
  integration,
  assets,
  anodum,
  i18next,
  saving,
  template,
  siteLocales,
} from '@yola/ws-sdk';
import withFeatureFlags from 'yola-editor/src/js/modules/feature-flags/hoc/with-feature-flags';
import { ViewportConsumer } from 'yola-editor/src/js/utils/viewport-provider';
import helpCenter from 'yola-editor/src/js/modules/help-center';
import segment from 'src/js/modules/analytics/segment';
import saveSite from 'src/js/modules/saving/helpers/save-site';
import scrollToElementInContainer from '../../scroller/helpers/scroll-to-element-in-container';
import scrollToElement from '../../scroller/helpers/scroll-to-element';
import dialogTypes from '../../dialogs/constants/dialog-types';
import constants from '../constants';
import WebsiteSettings from '../components/website-settings';
import checkIfHostingIsSyndicated from '../helpers/check-if-hosting-is-syndicated';
import getCookiebotInstructionsUrl from '../helpers/get-cookiebot-instructions-url';
import getFieldValuesFromSettings from '../helpers/get-field-values-from-settings';
import getSettingValueFromFieldValue from '../helpers/get-setting-value-from-field-value';
import trackers from '../trackers';
import pageSettingsTabIdentifiers from '../../page-settings/constants/tab-identifiers';

const {
  common,
  webSettingsConfig,
  settingTypes,
  externalLinks,
  integrationTypes,
  tabIdentifiers,
  settingLengthLimits,
} = constants;
const {
  GOOGLE_ANALYTICS_INSTRUCTIONS_URL,
  GOOGLE_SEARCH_CONSOLE_INSTRUCTIONS_URL,
  GOOGLE_OWNERSHIP_VERIFICATION_URL,
} = externalLinks;
const {
  trackers: { trackDialogActiveTabChanged, trackUpgradeTriggerClicked },
  constants: { triggerIds },
} = segment;

const {
  trackWebsiteSettingsDialogCanceled,
  trackWebsiteSettingsDialogDisplayed,
  trackWebsiteSettingsDialogSubmitted,
  trackAiTutorialClicked,
} = trackers;

const MIN_WIDTH = 568;
const MAX_WIDTH = 800;
const MIN_HEIGHT = 600;
const MAX_HEIGHT = 720;

const getCaptions = (data = {}) => ({
  prompt: {
    title: i18next.t('Save changes?'),
    description: i18next.t('Do you want to save changes you made in website settings?'),
    discard: i18next.t('Discard'),
    submit: i18next.t('Save'),
    cancel: i18next.t('Cancel'),
  },
  websiteSettings: i18next.t('Website settings'),
  businessInfo: {
    errorMessages: {
      required: i18next.t('This field needs your input'),
      businessCategory: i18next.t('Please select a valid category'),
      businessDescription: {
        minLength: i18next.t(`Please provide a description with {number}+ characters`, {
          number: settingLengthLimits.MIN_BUSINESS_DESCRIPTION_CHARS,
        }),
        maxLength: i18next.t(`Maximum number of characters {number}`, {
          number: settingLengthLimits.MAX_BUSINESS_DESCRIPTION_CHARS,
        }),
      },
      phone: i18next.t('Invalid phone number. Please, check the phone number and try again'),
      email: i18next.t('Invalid email. Please, check the email and try again'),
    },
    profileSection: {
      title: i18next.t('Profile'),
    },
    contactInfoSection: {
      title: i18next.t('Contact Info'),
      description: i18next.t('Add your contact details so clients can reach you easily.'),
    },
    businessName: {
      name: i18next.t('Business name'),
      checkboxLabel: i18next.t('Same as website name'),
    },
    businessCategory: {
      title: i18next.t('Business category'),
      placeholder: i18next.t('E.g. Cleaning Company'),
      noResultsText: i18next.t('No result'),
    },
    businessDescription: {
      name: i18next.t('Business description'),
      placeholder: i18next.t(
        'Alex & Johnson - is a real estate investment and development company based in Georgia. We specialize in acquiring, managing, and maximizing the value of residential properties.'
      ),
      description: i18next.t(
        'Describe your business or project in a sentence or two, it will help visitors to better understand what you do'
      ),
    },
    address: {
      title: i18next.t('Address'),
      placeholder: i18next.t('e.g. 1st Example st., Example City, Example Country'),
    },
    phone: {
      title: i18next.t('Phone'),
      placeholder: i18next.t('e.g. +1 234 567 8910'),
    },
    email: {
      title: i18next.t('Email'),
      placeholder: i18next.t('e.g. example@gmail.com'),
    },
    businessHours: {
      title: i18next.t('Working hours'),
      placeholder: i18next.t('e.g. Sun: Closed, Mon-Fri: 8 am-6 pm, Sat: 10 am-4 pm'),
    },
    aiBanner: {
      title: i18next.t('Boost your business with AI!'),
      description: i18next.t(
        'Explore our AI-powered tools to complete your website in under 5 minutes.'
      ),
      buttonLabel: i18next.t('Learn more'),
    },
  },
  tabs: {
    general: i18next.t('General'),
    businessInfo: i18next.t('Business Info'),
    integrations: i18next.t('Integrations'),
    customCode: i18next.t('Custom code'),
    multilingual: i18next.t('Multilingual'),
    hosting: i18next.t('Hosting'),
    siteBackups: i18next.t('Backups'),
    domain: i18next.t('Domain'),
  },
  apply: i18next.t('Submit'),
  cancel: i18next.t('Cancel'),
  siteName: {
    name: i18next.t('Website name'),
    description: data.isWL
      ? i18next.t(
          'The name of your web project is not used to populate website content. To manage your website title, go to <a href>Page Settings → SEO</a> of your Home page'
        )
      : i18next.t(
          "Your website project name shows on My Sites page in your Dashboard. It's not used to populate website content. To manage your website title, go to <a href>Page Settings → SEO</a> of your Home page"
        ),
    errorMessages: {
      required: i18next.t('Field is required'),
    },
  },
  businessLogo: {
    name: i18next.t('Site logo'),
    description: i18next.t(
      "Upload your business logo. We'll ensure it's updated throughout your website."
    ),
    preview: i18next.t('Preview logo'),
  },
  favicon: {
    name: i18next.t('Favicon'),
    description: i18next.t(
      "Add your own branding to site visitors' bookmarks and browser tabs by including a favicon."
    ),
  },
  socialLinks: {
    name: i18next.t('Social profiles'),
    button: i18next.t('Add social profile'),
    validationError: i18next.t('Invalid link'),
  },
  multilingual: {
    title: i18next.t('Website languages'),
    description: i18next.t(
      'Add multiple languages to your site so visitors can browse in their language'
    ),
    addButton: i18next.t('Add language'),
    mainLang: i18next.t('Main language'),
    setMainLang: i18next.t('Set as main language'),
    deleteLang: i18next.t('Delete language'),
    banner: {
      title: i18next.t('Generate content in your preferred language'),
      description: i18next.t(
        'Set your preferred language for AI-generated content in you language settings'
      ),
      buttonLabel: i18next.t('Learn more'),
    },
  },
  customCode: {
    name: i18next.t('Custom code'),
    description: i18next.t(
      'Tweak the site by adding custom code, and integration snippets. The code added here will apply to all pages of your site.'
    ),
    headCode: {
      name: i18next.t('Inside <head> tag'),
      description: i18next.t(
        'Custom code and scripts added to this section will appear in the preview mode and on the published site.'
      ),
      upgradeText: {
        description: i18next.t(
          'Gain access to custom code editing by upgrading your subscription!'
        ),
        upgrade: i18next.t('Upgrade now'),
      },
    },
    headerCode: {
      name: i18next.t('After <body> tag'),
      description: i18next.t(
        'Custom code and scripts added to this section will appear in the preview mode and on the published site.'
      ),
      upgradeText: {
        description: i18next.t(
          'Gain access to custom code editing by upgrading your subscription!'
        ),
        upgrade: i18next.t('Upgrade now'),
      },
    },
    footerCode: {
      name: i18next.t('Before </body> tag'),
      description: i18next.t(
        'Custom code and scripts added to this section will appear in the preview mode and on the published site.'
      ),
      upgradeText: {
        description: i18next.t(
          'Gain access to custom code editing by upgrading your subscription!'
        ),
        upgrade: i18next.t('Upgrade now'),
      },
    },
  },
  cookiesUserData: {
    name: i18next.t('Cookies & User data'),
    title: i18next.t('Cookie consent banner'),
    description: i18next.t(
      'Show your visitors a banner informing them about the use of cookies on your website'
    ),
  },
  fileInputCaptions: {
    button: i18next.t('Choose file'),
    empty: i18next.t('No file chosen'),
    fileItemName: i18next.t('files'),
    wrongFileType: i18next.t('Wrong file type'),
  },
  exportSite: {
    name: i18next.t('Site export'),
    description: i18next.t(
      'Export the HTML, CSS, assets, and JavaScript of your site from inside the {productName}',
      {
        productName: data.productName,
      }
    ),
    button: i18next.t('Prepare ZIP'),
  },
  dangerZoneCaptions: {
    name: i18next.t('Danger zone'),
    description: i18next.t(
      'Changes in this area can negatively affect your website performance. Please proceed with care.'
    ),
    preventGoogleSearchIndexing: {
      name: i18next.t('Hide this website from search results'),
      description: i18next.t(
        'Prevent your website from being indexed by search engines to hide it in Google search'
      ),
      label: i18next.t('Hide this website'),
    },
    unpublish: {
      name: i18next.t('Unpublish site'),
      description: i18next.t(
        "Unpublish your site if you don't want it to remain online anymore. You will continue to own your domain and you will be able to publish another website using this domain name."
      ),
      button: i18next.t('Unpublish site'),
    },
    reset: {
      name: i18next.t('Reset site'),
      description: i18next.t(
        'Reset your website if you want to start using another template. Please proceed with this step carefully. You cannot recover your site after the reset.'
      ),
      button: i18next.t('Reset site'),
    },
    switchTemplate: {
      name: i18next.t('Switch template'),
      description: i18next.t(
        'Switch website template if you want to start using another one. All text content and images will be saved.'
      ),
      button: i18next.t('Switch template'),
    },
  },
  integrations: {
    description: i18next.t(
      'Easily add third-party integrations like Google Analytics and Webmaster tools to your website'
    ),
    googleAnalytics: {
      name: i18next.t('Google Analytics'),
      description: i18next.t(
        'Discover where your visitors come from and what they do on your site'
      ),
      about: i18next.t(
        "With Google Analytics, you can track your site's traffic and gain a deeper understanding of who's visiting your site. You can see information like the number of visitors to a page, where visitors come from, and how long they stay."
      ),
      step1: {
        title: i18next.t('Set up a Google Analytics Property ID'),
        description: i18next.t(
          `Follow <a target="_blank" href="{googleInstructionsUrl}">Google’s instructions</a> to set up a property ID.`,
          { googleInstructionsUrl: GOOGLE_ANALYTICS_INSTRUCTIONS_URL }
        ),
      },
      step2: {
        title: i18next.t('Add your Google Analytics Tracking ID to your site'),
        description: i18next.t('Google Analytics Tracking ID'),
      },
      control: {
        name: i18next.t('Google Analytics Tracking ID'),
        description: i18next.t(
          'Use the property Tracking ID from the property you have created in Google analytics'
        ),
        validationError: i18next.t('Invalid Google Analytics Tracking ID'),
      },
      notes: [
        i18next.t(
          'If you’ve pasted your Google Analytics into any of your site’s custom code areas, be sure to remove it to prevent inaccurate data.'
        ),
        i18next.t('Don’t forget to save and publish your site for changes to take effect'),
      ],
      disconnectAlert: i18next.t(
        `You’re about to disconnect the {integrationType} integration from your site. Are your sure?`,
        { integrationType: i18next.t('Google Analytics') }
      ),
    },
    googleSearchConsole: {
      name: i18next.t('Google Search Console'),
      description: i18next.t(
        'Verify and claim ownership of your site to get access to your performance statistics on Google Search'
      ),
      about: i18next.t(
        'With Google Search Console you can track and understand how you are performing on Google Search and what you can do to improve your appearance on search to bring more relevant traffic to your website.'
      ),
      step1: {
        title: i18next.t('Set up a Search Console property'),
        description: i18next.t(
          `Follow <a target="_blank" href="{googleInstructionsUrl}">Google’s instructions</a> to set up a property. Choose the “HTML tag” method on the <a target="_blank" href="{ownershipVerificationUrl}">Ownership verification page</a> for your property.`,
          {
            googleInstructionsUrl: GOOGLE_SEARCH_CONSOLE_INSTRUCTIONS_URL,
            ownershipVerificationUrl: GOOGLE_OWNERSHIP_VERIFICATION_URL,
          }
        ),
      },
      step2: {
        title: i18next.t('Add the verification tag from the Search Console to your site'),
        description: i18next.t('HTML tag'),
      },
      control: {
        name: i18next.t('HTML tag'),
        description: i18next.t(
          `Use the verification HTML tag you have recived on the <a target="_blank" href="{ownershipVerificationUrl}">Ownership verification page</a> of your property in Google Search Console`,
          {
            ownershipVerificationUrl: GOOGLE_OWNERSHIP_VERIFICATION_URL,
          }
        ),
        validationError: i18next.t('Invalid verification HTML tag'),
      },
      notes: [i18next.t('Don’t forget to save and publish your site for changes to take effect')],
      disconnectAlert: i18next.t(
        `You’re about to disconnect the {integrationType} integration from your site. Are your sure?`,
        { integrationType: i18next.t('Google Search Console') }
      ),
    },
    cookiebot: {
      name: i18next.t('Cookiebot by Usercentrics'),
      description: {
        connected: i18next.t(
          'You’re currently using Cookiebot CMP to manage user cookies. Disconnect it if you want to switch back to a basic cookie banner.'
        ),
        disconnected: i18next.t(
          "Don't risk non-compliance with key privacy laws GDPR and CCPA. Easily add the automated Cookiebot CMP now"
        ),
      },
      about: i18next.t(
        'Cookiebot Consent Management Platform (CMP) helps make your use of cookies and online tracking compliant with GDPR and CCPA.'
      ),
      step1: {
        title: i18next.t('Create a Cookiebot CMP account and add your domain'),
        description: i18next.t(
          'Sign up to create an account. Log in to your account and add your website domain name(s). The website will now be scanned (please allow up to 24 hours for the scan to complete). Please note: your website has to be already published for Cookiebot CMP to successfuly scan your website.'
        ),
        btnLabel: i18next.t('Create account'),
      },
      step2: {
        title: i18next.t('Configure the cookie consent widget you want to display on your website'),
        description: i18next.t(
          `Follow the <a target="_blank" href="{instructionsUrl}">instructions</a> to configure and customize your cookie consent widget`,
          {
            instructionsUrl: getCookiebotInstructionsUrl(),
          }
        ),
      },
      step3: {
        title: i18next.t('Copy and paste the Domain Group ID from the CookieBot account:'),
        description: i18next.t(
          `Follow the <a target="_blank" href="{instructionsUrl}">instructions</a> to find and copy the Domain Group ID`,
          {
            instructionsUrl: getCookiebotInstructionsUrl(),
          }
        ),
      },
      control: {
        name: i18next.t('Domain Group ID'),
      },
      notes: [
        i18next.t(
          "Connecting this integration will automatically disable the display of Yola's basic Cookie banner on your website"
        ),
        i18next.t(
          'It might take up to 24 hours for the scan to get finished completely after you add the domain name(s)'
        ),
        i18next.t('Don’t forget to save and publish your site for changes to take effect'),
      ],
      disconnectAlert: i18next.t(
        `You’re about to disconnect the {integrationType} integration from your site. Are your sure?`,
        { integrationType: i18next.t('Cookiebot by Usercentrics') }
      ),
      afterDisconnectInfo: i18next.t(
        'Please note that you also need to manually remove the domain from your Cookiebot account or close your account entirely.'
      ),
    },
  },
});

const getWrongFileDialogText = () => ({
  favicon: {
    title: i18next.t('Unsupported file type'),
    description: i18next.t(
      'Please ensure your file is in one of the following formats: .ico, .png'
    ),
  },
  businessLogo: {
    title: i18next.t('Unsupported file type'),
    description: i18next.t(
      'Please ensure your file is in one of the following formats: .img, .jpeg, .jpg, .png, .gif, .svg'
    ),
  },
});

class WebsiteSettingsContainer extends React.Component {
  constructor(props, state) {
    super(props, state);

    const initialValues = this.prepareInitialValues();

    this.state = {
      activeTabId: this.getActiveTabId(),
      isBusinessNameSameAsSiteName:
        initialValues[settingTypes.BUSINESS_NAME] === initialValues[settingTypes.SITE_NAME],
    };

    this.formRef = createRef();
    this.initialValues = initialValues;

    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onCustomCodeUpgrade = this.onCustomCodeUpgrade.bind(this);
    this.prepareInitialValues = this.prepareInitialValues.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.bindSubmitForm = this.bindSubmitForm.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSwitchTemplate = this.handleSwitchTemplate.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
    this.setActiveTabId = this.setActiveTabId.bind(this);
    this.setIsBusinessNameSameAsSiteName = this.setIsBusinessNameSameAsSiteName.bind(this);
    this.onSiteNameDescriptionClick = this.onSiteNameDescriptionClick.bind(this);
  }

  componentDidMount() {
    trackWebsiteSettingsDialogDisplayed(this.initialValues);
  }

  onSubmit(formData) {
    const {
      onUpdateSettings,
      onUpdateRedirects,
      onUpdateIntegrations,
      websiteSettings,
      redirects,
      onDialogMainAction,
    } = this.props;

    const settingsForUpdate = this.getSettingsToUpdate(formData);
    const integrationsForUpdate = this.getIntegrationsToUpdate(formData);

    // Set native cookie banner to false, if cookiebot is connected
    if (integrationsForUpdate.cookiebotId && websiteSettings.isCookieBannerEnabled) {
      settingsForUpdate.isCookieBannerEnabled = false;
    }

    const isSettingsForUpdate = !!Object.keys(settingsForUpdate).length;
    const isRedirectsForUpdate = formData.redirects !== redirects;
    const isIntegrationsForUpdate = !!Object.keys(integrationsForUpdate).length;

    trackWebsiteSettingsDialogSubmitted({
      onDialogMainAction,
      initialValues: this.initialValues,
      settingsForUpdate,
      integrationsForUpdate,
    });

    if (!isSettingsForUpdate && !isRedirectsForUpdate && !isIntegrationsForUpdate) {
      this.onClose();
      return;
    }

    if (isSettingsForUpdate) {
      onUpdateSettings(settingsForUpdate);
    }
    if (isRedirectsForUpdate) {
      onUpdateRedirects(formData.redirects);
    }
    if (isIntegrationsForUpdate) {
      onUpdateIntegrations(integrationsForUpdate);
    }

    saveSite();

    this.onClose();
  }

  onCancel() {
    const { onDialogCancel } = this.props;

    trackWebsiteSettingsDialogCanceled({
      onDialogCancel,
    });

    this.onClose();
  }

  onClose() {
    const { hideDialog, onAfterDialogClose } = this.props;

    hideDialog();
    onAfterDialogClose();
  }

  onCustomCodeUpgrade() {
    const { upsells } = this.props;

    trackUpgradeTriggerClicked(triggerIds.WEBSITE_SETTINGS);

    return upsells.customCode.onUpgrade().catch(() => {
      // eslint-disable-next-line no-console
      console.log('Upgrade flow was canceled');
    });
  }

  static setImagePreview(src) {
    if (!src) return null;
    return site.helpers.composeAssetUrl(src);
  }

  onTabChange(tabId) {
    this.setActiveTabId(tabId);
    trackDialogActiveTabChanged({ dialogId: dialogTypes.WEBSITE_SETTINGS, tabId });
  }

  onSiteNameDescriptionClick(e) {
    e.preventDefault();

    const { productName, userData } = this.props;
    const { is_white_label: isWL } = userData;
    const captions = getCaptions({ productName, isWL });

    if (e.target.tagName === 'A') {
      const {
        state: { errors, values },
      } = this.formRef.current;

      if (Object.keys(errors).length) {
        this.submitSettings();
        return;
      }

      if (this.shouldSettingsBeUpdated(values)) {
        dialogs.operations.show(dialogTypes.CONFIRM_DIALOG, {
          onCancel: () => this.openPageSettingsSEOTab(),
          onSubmit: () => {
            this.onSubmit(values, true);
            this.openPageSettingsSEOTab();
          },
          captions: captions.prompt,
        });
        return;
      }

      this.openPageSettingsSEOTab();
    }
  }

  setIsBusinessNameSameAsSiteName(value) {
    this.setState({
      isBusinessNameSameAsSiteName: value,
    });
  }

  getActiveTabId() {
    const { dialog } = this.props;
    const { modalProps } = dialog;
    return (modalProps && modalProps.activeTabId) || tabIdentifiers.GENERAL;
  }

  setActiveTabId(tabId) {
    return new Promise((resolve) => {
      this.setState(
        {
          activeTabId: tabId,
        },
        resolve
      );
    });
  }

  getIntegrationsToUpdate(formData) {
    const { integrations } = this.props;

    return Object.values(integrationTypes).reduce((acc, integrationName) => {
      const value = formData[integrationName];

      if (integrations[integrationName] === value) return acc;

      return {
        ...acc,
        [integrationName]: value,
      };
    }, {});
  }

  getSettingsToUpdate(formData) {
    const { websiteSettings } = this.props;

    return webSettingsConfig.reduce((accum, config) => {
      const { name } = config;
      let value = formData[name];

      if (typeof value === 'string') {
        value = value.trim();
      }

      if (websiteSettings[name] !== value) {
        return {
          [name]: getSettingValueFromFieldValue(name, value),
          ...accum,
        };
      }

      if (name === settingTypes.BUSINESS_NAME && this.initialValues[name] !== value) {
        return {
          [name]: getSettingValueFromFieldValue(name, value),
          ...accum,
        };
      }

      return accum;
    }, {});
  }

  shouldSettingsBeUpdated(formData) {
    const { websiteSettings, integrations, redirects } = this.props;

    const settingsForUpdate = this.getSettingsToUpdate(formData, websiteSettings);
    const integrationsForUpdate = this.getIntegrationsToUpdate(formData, integrations);

    const isSettingsForUpdate = !!Object.keys(settingsForUpdate).length;
    const isRedirectsForUpdate = formData.redirects !== redirects;
    const isIntegrationsForUpdate = !!Object.keys(integrationsForUpdate).length;

    return isSettingsForUpdate || isRedirectsForUpdate || isIntegrationsForUpdate;
  }

  openPageSettingsSEOTab() {
    const { homePageId } = this.props;

    dialogs.operations.show(dialogTypes.PAGE_SETTINGS, {
      pageId: homePageId,
      activeTabId: pageSettingsTabIdentifiers.SEO,
    });
  }

  bindSubmitForm(submitForm) {
    this.submitSettings = submitForm;
  }

  handleKeyDown(e) {
    if (e.key === common.ENTER_KEY) {
      if (anodum.isOneOfTags(e.target, ['input', 'textarea'])) return;
      this.submitSettings();
    }
  }

  prepareInitialValues() {
    const { websiteSettings, redirects, form, formData, integrations } = this.props;
    if (formData) {
      return formData;
    }

    return {
      ...websiteSettings,
      [settingTypes.BUSINESS_NAME]:
        websiteSettings[settingTypes.BUSINESS_NAME] || websiteSettings[settingTypes.SITE_NAME],
      ...integrations,
      redirects,
      ...form,
    };
  }

  uploadImage(file, form, type) {
    const { onLoadProgressDialog, backToWebsiteSettingsDialog } = this.props;
    const showPromptForce = true;
    onLoadProgressDialog(form);

    return assets.operations.clientUploadAsset(file[0]).then(() => {
      assets.operations
        .createMediaGalleryAssets({
          asset: file[0],
          name: file[0].name,
        })
        .then((result) => {
          backToWebsiteSettingsDialog(
            {
              ...form,
              [type]: result.data.source,
            },
            showPromptForce
          );
        });
    });
  }

  handleSwitchTemplate() {
    const { integrationSettings, savingStatus, onUnsavedActivePageChange } = this.props;
    const { UNSAVED } = saving.constants.statuses;

    if (savingStatus === UNSAVED) {
      onUnsavedActivePageChange(integrationSettings.onSwitchTemplate);
    } else {
      integrationSettings.onSwitchTemplate();
    }
  }

  render() {
    const isMobile = bowser.mobile || bowser.tablet;
    const {
      hideDialog,
      userLimits,
      dialog,
      onWrongTypeFile,
      upsells,
      showPromptForce,
      integrationSettings,
      featureFlags,
      isMpt,
      siteId,
      productName,
      onExportSite,
      userData,
      settingToScroll,
    } = this.props;
    const codeFieldsDisabled = !userLimits.customCode.available;
    const {
      site_export: isSiteExportFeatureFlagEnabled,
      business_info_in_website_settings: isBusinessInfoEnabled,
      business_taxonomy: isBusinessTaxonomyEnabled,
      ai_site_wizard: isAiSiteWizardEnabled,
      ai_page_wizard: isAiPageWizardEnabled,
      ai_block_generator: isAiBlockGeneratorEnabled,
      ai_text_assistant_rewrite: isTextAssistantRewriteEnabled,
      ai_text_assistant_write: isTextAssistantWriteEnabled,
    } = featureFlags;
    const { is_white_label: isWL, employee_id: employeeId } = userData;
    const isDomainTabEnabled = !isWL;
    const captions = getCaptions({ productName, isWL });
    const areAiFeaturesAvailable = Boolean(
      isAiSiteWizardEnabled ||
        isAiPageWizardEnabled ||
        isAiBlockGeneratorEnabled ||
        isTextAssistantRewriteEnabled ||
        isTextAssistantWriteEnabled
    );
    const aiTutorialUrl = helpCenter.accessors.getAiTutorialUrl();

    const isSiteExportEnabled = isSiteExportFeatureFlagEnabled || Boolean(employeeId);

    if (upsells.customCode) {
      const customCodeCaptions = {
        ...upsells.customCode.captions,
      };
      captions.customCode.headCode.upgradeText = customCodeCaptions;
      captions.customCode.headerCode.upgradeText = customCodeCaptions;
      captions.customCode.footerCode.upgradeText = customCodeCaptions;
    }

    const { modalProps } = dialog;
    const fieldNameForScroll = (modalProps && modalProps.field) || null;
    const { activeTabId, isBusinessNameSameAsSiteName } = this.state;
    const isSyndicatedHosting = checkIfHostingIsSyndicated(
      integrationSettings.userData.publishing_type
    );

    return (
      <ViewportConsumer>
        {({ width, height }) => (
          <WebsiteSettings
            width={width >= 1440 ? MAX_WIDTH : MIN_WIDTH}
            height={height >= 800 ? MAX_HEIGHT : MIN_HEIGHT}
            mobile={!!isMobile}
            captions={captions}
            headCodeDisabled={codeFieldsDisabled}
            headerCodeDisabled={codeFieldsDisabled}
            footerCodeDisabled={codeFieldsDisabled}
            initialValues={this.initialValues}
            onClose={hideDialog}
            onSubmit={(data) => this.onSubmit(data)}
            onHeadCodeUpgradeClick={this.onCustomCodeUpgrade}
            onHeaderCodeUpgradeClick={this.onCustomCodeUpgrade}
            onFooterCodeUpgradeClick={this.onCustomCodeUpgrade}
            onCancel={this.onCancel}
            scrollElementIntoView={scrollToElementInContainer}
            scrollToElement={scrollToElement}
            activeTabId={activeTabId}
            isBusinessNameSameAsSiteName={isBusinessNameSameAsSiteName}
            fieldNameForScroll={fieldNameForScroll}
            onWrongTypeFile={onWrongTypeFile}
            uploadImage={this.uploadImage}
            integrationTypes={integrationTypes}
            setImagePreview={WebsiteSettingsContainer.setImagePreview}
            bindSubmitForm={this.bindSubmitForm}
            onKeyDown={this.handleKeyDown}
            showPromptForce={showPromptForce}
            onUnpublish={integrationSettings.onUnpublish}
            onResetSite={integrationSettings.onResetSite}
            onExportSite={() => onExportSite(siteId)}
            onSwitchTemplate={this.handleSwitchTemplate}
            isMultilingualEnabled={isMpt}
            isSiteExportEnabled={isSiteExportEnabled}
            isBusinessInfoEnabled={isBusinessInfoEnabled}
            isBusinessTaxonomyEnabled={isBusinessTaxonomyEnabled}
            onTabChange={this.onTabChange}
            setActiveTabId={this.setActiveTabId}
            setIsBusinessNameSameAsSiteName={this.setIsBusinessNameSameAsSiteName}
            isSyndicatedHosting={isSyndicatedHosting}
            isDomainTabEnabled={isDomainTabEnabled}
            settingToScroll={settingToScroll}
            onSiteNameDescriptionClick={this.onSiteNameDescriptionClick}
            formRef={this.formRef}
            areAiFeaturesAvailable={areAiFeaturesAvailable}
            aiTutorialUrl={aiTutorialUrl}
            onAiBannerClick={() => {
              trackAiTutorialClicked({
                triggerId: 'website-settings',
                tutorialId: 'learn-about-ai',
              });
            }}
          />
        )}
      </ViewportConsumer>
    );
  }
}

WebsiteSettingsContainer.propTypes = {
  onUpdateSettings: PropTypes.func.isRequired,
  onUpdateRedirects: PropTypes.func.isRequired,
  onUpdateIntegrations: PropTypes.func.isRequired,
  backToWebsiteSettingsDialog: PropTypes.func.isRequired,
  onLoadProgressDialog: PropTypes.func.isRequired,
  hideDialog: PropTypes.func.isRequired,
  websiteSettings: PropTypes.shape().isRequired,
  redirects: PropTypes.arrayOf(
    PropTypes.shape({
      from: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    })
  ).isRequired,
  integrations: PropTypes.object.isRequired,
  formData: PropTypes.shape(),
  integrationSettings: PropTypes.shape().isRequired,
  userLimits: PropTypes.shape().isRequired,
  onWrongTypeFile: PropTypes.func.isRequired,
  dialog: PropTypes.shape().isRequired,
  form: PropTypes.shape(),
  upsells: PropTypes.shape().isRequired,
  showPromptForce: PropTypes.bool,
  savingStatus: PropTypes.string.isRequired,
  siteId: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  onExportSite: PropTypes.func.isRequired,
  onUnsavedActivePageChange: PropTypes.func.isRequired,
  isMpt: PropTypes.bool.isRequired,
  featureFlags: PropTypes.shape({
    site_export: PropTypes.bool,
    business_info_in_website_settings: PropTypes.bool,
    business_taxonomy: PropTypes.bool,
    ai_site_wizard: PropTypes.bool,
    ai_page_wizard: PropTypes.bool,
    ai_block_generator: PropTypes.bool,
    ai_text_assistant_rewrite: PropTypes.bool,
    ai_text_assistant_write: PropTypes.bool,
  }).isRequired,
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
  onAfterDialogClose: PropTypes.func,
  userData: PropTypes.object.isRequired,
  settingToScroll: PropTypes.oneOf([...Object.values(settingTypes), '']),
  homePageId: PropTypes.string.isRequired,
};

WebsiteSettingsContainer.defaultProps = {
  form: {},
  formData: null,
  showPromptForce: false,
  onAfterDialogClose: Function.prototype,
  settingToScroll: '',
};

const mapStateToProps = (state) => {
  const templateBuildSlug = template.selectors.getBuildSlug(state);
  const isMpt = templateBuildSlug.startsWith('ws-intense-next');
  const integrationSettings = integration.selectors.getSettings(state);
  const userData = integration.selectors.getUserData(state);
  const mainLocale = siteLocales.selectors.getMainLocale(state);

  const settings = site.selectors.getSettings(state);
  const { googleAnalyticsId, googleWebmasterCode, ...otherSettings } = settings;

  return {
    websiteSettings: getFieldValuesFromSettings(otherSettings),
    redirects: site.selectors.getRedirects(state),
    integrations: {
      googleAnalyticsId,
      googleWebmasterCode,
      ...site.selectors.getIntegrations(state),
    },
    userLimits: integration.selectors.getLimits(state),
    dialog: dialogs.selectors.getDialog(state),
    upsells: integration.selectors.getUpsells(state),
    integrationSettings: integration.selectors.getSettings(state),
    savingStatus: saving.selectors.getStatus(state),
    pages: site.selectors.getPages(state),
    siteId: site.selectors.getSiteId(state),
    productName: get(integrationSettings, 'branding.productName'),
    isMpt,
    userData,
    homePageId: site.selectors.getHomePage(state, mainLocale).id,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setMultilingual(isMultilingual) {
    dispatch(site.actions.setMultilingual(isMultilingual));
  },

  onUpdateSettings(options) {
    dispatch(site.actions.updateSettings(options));
  },

  onUpdateIntegrations(integrations) {
    dispatch(site.actions.updateIntegrations(integrations));
  },

  onUpdateRedirects(redirects) {
    dispatch(site.actions.updateRedirects(redirects));
  },

  hideDialog() {
    dispatch(dialogs.actions.hide());
  },

  onWrongTypeFile(file, form, type) {
    const wrongFileDialogText = getWrongFileDialogText();
    dialogs.operations.show(dialogTypes.FILE_ERROR_DIALOG, {
      captions: {
        ...wrongFileDialogText[type],
        dismiss: i18next.t('Cancel'),
      },
      glyph: 'alert-circle',
      onDismiss: () => {
        dispatch(dialogs.actions.show(dialogTypes.WEBSITE_SETTINGS, { form }));
      },
    });
  },

  onLoadProgressDialog() {
    dialogs.operations.show(dialogTypes.UPLOAD_PROGRESS_DIALOG, {
      overlay: 'visible',
      cancelable: false,
      progressType: 'indeterminate',
      captions: {
        title: `${i18next.t('Uploading image')}...`,
        description: i18next.t('Your image will be uploaded to the website soon'),
        cancel: i18next.t('Cancel'),
      },
    });
  },

  backToWebsiteSettingsDialog(form, showPromptForce) {
    dispatch(
      dialogs.actions.show(dialogTypes.WEBSITE_SETTINGS, {
        form,
        showPromptForce,
      })
    );
  },

  onUnsavedActivePageChange(callback) {
    dispatch(
      dialogs.actions.show(dialogTypes.UNSAVED_WARNING, {
        callback,
        captions: { title: i18next.t('Your site has unsaved changes') },
      })
    );
  },

  onExportSite(siteId) {
    function onSuccess(data) {
      const { url: siteArchiveLink } = data;

      dispatch(
        dialogs.actions.show(dialogTypes.EXPORT_INFORMATION_DIALOG, {
          siteArchiveLink,
          onDismiss() {
            dispatch(dialogs.actions.hide());
          },
        })
      );
    }

    function onFail() {
      dispatch(dialogs.actions.show(dialogTypes.EXPORT_ERROR_DIALOG));
    }

    dispatch(dialogs.actions.show(dialogTypes.EXPORT_PROGRESS_DIALOG));

    dispatch(
      site.actions.exportSite(siteId, {
        includeMeta: true,
        onSuccess,
        onFail,
      })
    );
  },
});

export default withFeatureFlags([
  'site_export',
  'business_info_in_website_settings',
  'business_taxonomy',
  'ai_site_wizard',
  'ai_page_wizard',
  'ai_block_generator',
  'ai_text_assistant_rewrite',
  'ai_text_assistant_write',
])(connect(mapStateToProps, mapDispatchToProps)(WebsiteSettingsContainer));
