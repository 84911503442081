import { hooks, site, template } from '@yola/ws-sdk';
import constants from '../constants';
import track from '../track';

const trackWidgetDuplicated = () => {
  hooks.operations.subscribe(
    hooks.availableHooks.ON_AFTER_BLOCK_DUPLICATED,
    ({
      blockId,
      variationId: blockVariationId,
      constraintId,
      targetBlockId,
      targetBlockVariationId,
    }) => {
      if (blockId && !blockId.startsWith('widget-')) return;

      track(constants.events.WIDGET_DUPLICATED, {
        siteId: site.accessors.getSiteId(),
        templateBuildSlug: template.accessors.getBuildSlug(),
        targetBlockId,
        targetBlockVariationId,
        blockId,
        blockVariationId,
        constraintId,
      });
    }
  );
};

export default trackWidgetDuplicated;
