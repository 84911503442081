import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@yola/ws-ui';
import bowser from 'yola-bowser';
import { i18next } from '@yola/ws-sdk';

function TextToCopy({ text, copiedTextFormatter, onAfterCopy }) {
  const [isCopied, setIsCopied] = useState(false);
  const targetRef = useRef(null);

  const buttonLabel = isCopied ? i18next.t('Copied') : i18next.t('Copy');

  const handleCopyButtonClick = () => {
    // Format text before copying by outer handler if needed
    const textToCopy = copiedTextFormatter(text);

    // Hidden input is used for selection and copying of formatted text
    // which could differ from that is shown on UI
    const hiddenInput = document.createElement('input');
    hiddenInput.contentEditable = true;
    hiddenInput.style.opacity = '0';
    hiddenInput.style.position = 'absolute';
    hiddenInput.style.height = '1px';
    hiddenInput.style.width = '1px';
    hiddenInput.value = textToCopy;
    targetRef.current.appendChild(hiddenInput);

    // To copy text on iOS devices there should be
    // a different approach for text selection
    if (bowser.ios || bowser.iPadOS) {
      const range = document.createRange();
      const selection = window.getSelection();
      selection.removeAllRanges();
      range.selectNodeContents(hiddenInput);
      selection.addRange(range);
      hiddenInput.setSelectionRange(0, textToCopy.length);
    } else {
      hiddenInput.select();
    }

    const isInputTextCopied = document.execCommand('copy');
    targetRef.current.removeChild(hiddenInput);

    setIsCopied(isInputTextCopied);

    onAfterCopy();
  };

  return (
    <div className="ws-text-to-copy__field">
      <div className="ws-text-to-copy__text" ref={targetRef}>
        {text}
      </div>
      <Button className="ws-text-to-copy__btn" onClick={handleCopyButtonClick}>
        {buttonLabel}
      </Button>
    </div>
  );
}

TextToCopy.propTypes = {
  text: PropTypes.string.isRequired,
  copiedTextFormatter: PropTypes.func,
  onAfterCopy: PropTypes.func,
};

TextToCopy.defaultProps = {
  copiedTextFormatter: (text) => text,
  onAfterCopy: Function.prototype,
};

export default TextToCopy;
