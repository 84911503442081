export default {
  TRIGGER_UPGRADE: '@YOLA_EDITOR/UPSELL/TRIGGER_UPGRADE',
  ORDER_SUBSCRIPTION_PLAN: '@YOLA_EDITOR/UPSELL/ORDER_SUBSCRIPTION_PLAN',
  FETCH_UPGRADE_OPTIONS: '@YOLA_EDITOR/UPSELL/FETCH_UPGRADE_OPTIONS',
  SET_UPGRADE_OPTIONS: '@YOLA_EDITOR/UPSELL/SET_UPGRADE_OPTIONS',
  START_B2C_DOMAIN_PURCHASE: '@YOLA_EDITOR/UPSELL/START_B2C_DOMAIN_PURCHASE',
  START_WL_DOMAIN_PURCHASE: '@YOLA_EDITOR/UPSELL/START_WL_DOMAIN_PURCHASE',
  GET_DOMAIN_LIST: '@YOLA_EDITOR/UPSELL/GET_DOMAIN_LIST',
  GET_DOMAIN_LIST_SUCCESS: '@YOLA_EDITOR/UPSELL/GET_DOMAIN_LIST_SUCCESS',
  GET_DOMAIN_LIST_FAILURE: '@YOLA_EDITOR/UPSELL/GET_DOMAIN_LIST_FAILURE',
  SELECT_DOMAIN: '@YOLA_EDITOR/UPSELL/SELECT_DOMAIN',
  ORDER_DOMAIN: '@YOLA_EDITOR/UPSELL/ORDER_DOMAIN',
  CLEAR_DOMAIN_PURCHASE_DATA: '@YOLA_EDITOR/UPSELL/CLEAR_DOMAIN_PURCHASE_DATA',
  SET_PAYMENT_URL: '@YOLA_EDITOR/UPSELL/SET_PAYMENT_URL',
};
