import observers from '../helpers/observers';

const unsubscribe = (eventName, callback) => {
  if (observers.has(eventName)) {
    const handlers = observers.get(eventName);
    observers.set(
      eventName,
      handlers.filter((handler) => handler !== callback)
    );
  }
};

export default unsubscribe;
