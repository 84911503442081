import React from 'react';
import PropTypes from 'prop-types';
import { DragSource } from '@yola/ws-ui';
import classNames from 'classnames';
import Trigger from '../../common/components/trigger';
import DragTypes from '../constants/drag-types';
import beginDragHandler from '../helpers/begin-drag-handler';
import endDragHandler from '../helpers/end-drag-handler';
import TriggerIcon from '../../common/components/trigger-icon';
import TriggerLabel from '../../common/components/trigger-label';
import { PANE_TRIGGER_SIZE } from '../../control-pane/constants/sizes';

class DragTriggerContainer extends React.Component {
  render() {
    const { connectDragSource, label, width, glyph, glyphSize, glyphStrokeWidth } = this.props;

    const className = classNames('ws-drag-trigger-wrapper', {
      'ws-drag-trigger-wrapper--labeled': label,
    });
    const triggerIconClass = classNames({
      'ws-drag-trigger__icon-drag': glyph === 'drag',
      'ws-drag-trigger__icon-move': glyph === 'move',
    });
    const widthNumber = Number.parseInt(width, 10);
    const widthValue = !Number.isNaN(widthNumber) && `${widthNumber}px`;

    return connectDragSource(
      <div
        className={className}
        ref={(triggerRef) => {
          this.triggerRef = triggerRef;
        }}
      >
        <Trigger disableHover width={widthValue || PANE_TRIGGER_SIZE}>
          <TriggerIcon
            className={triggerIconClass}
            glyph={glyph}
            size={glyphSize}
            strokeWidth={glyphStrokeWidth}
          />
          {label && <TriggerLabel>{label}</TriggerLabel>}
        </Trigger>
      </div>
    );
  }
}

DragTriggerContainer.propTypes = {
  connectDragSource: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  glyph: PropTypes.string,
  glyphSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  glyphStrokeWidth: PropTypes.number,
};

DragTriggerContainer.defaultProps = {
  connectDragSource: null,
  width: null,
  label: '',
  glyph: 'move',
  glyphSize: 20,
  glyphStrokeWidth: 1.3,
};

const dragSource = {
  beginDrag: beginDragHandler,
  endDrag: endDragHandler,
};

const collectSource = (connect) => ({
  connectDragSource: connect.dragSource(),
});

export default DragSource(DragTypes.VIEW_ELEMENT, dragSource, collectSource)(DragTriggerContainer);
