import { view, extensions } from '@yola/ws-sdk';
import constants from '../constants';

const getGalleryBlockId = (elementId) => {
  const { galleryBlockQuerySelector } = extensions.accessors.getExtensionSettings(
    constants.common.slug
  );
  const element = view.accessors.getLiveElement(elementId);

  if (element.matches(galleryBlockQuerySelector)) return elementId;

  const galleryBlock = element.closest(galleryBlockQuerySelector);
  return view.accessors.getLiveElementId(galleryBlock);
};

export default getGalleryBlockId;
