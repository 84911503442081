import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { view } from '@yola/ws-sdk';
import { designSystem } from '@yola/ws-ui';
import getHighlightedElements from '../selectors/highlighted-elements';
import normaliseElementTopValue from '../helpers/normalise-element-top-value';
import isNegativeIndent from '../helpers/is-negative-indent';
import calculateHighlighterOffset from '../helpers/calculate-highlighter-offset';
import highlighterOffset from '../helpers/highlighter-offset';
import getHighlighterAppearance from '../helpers/get-highlighter-appearance';
import getHighlighterLabelOptions from '../helpers/get-highlighter-label-options';

const { Highlighter } = designSystem;

function HighlighterContainer({ viewportHeight }) {
  const { scrollTop, elements, isViewLoaded } = useSelector(
    (state) => ({
      scrollTop: view.selectors.getScrollPosition(state),
      elements: getHighlightedElements(state),
      isViewLoaded: view.selectors.getLoadedStatus(state),
    }),
    shallowEqual
  );

  useEffect(() => {
    highlighterOffset.set(calculateHighlighterOffset());
  }, []);

  const [savedViewportHeight, setSavedViewportHeight] = useState(null);

  useEffect(() => {
    // fix for android tablet
    // (on some android tablets vh value is changed after the keyboard open
    // and after keyboard open some extra time is needed to reflow elements due to the changed 100vh value;
    // that's why the highlighter position may be calculated wrong)
    // https://github.com/yola/production/issues/9229
    if (savedViewportHeight !== viewportHeight) {
      requestAnimationFrame(() => {
        setSavedViewportHeight(viewportHeight);
      });
    }
  }, [viewportHeight, savedViewportHeight]);

  if (elements.length === 0 || !isViewLoaded) {
    return null;
  }

  return (
    <React.Fragment>
      {elements.map(([element, options]) => {
        const { height, width, left, top } = element.getBoundingClientRect();
        const elementTop = normaliseElementTopValue({ element, top, scrollTop });
        const highlighterTop = elementTop + scrollTop;
        const elementBounds = {
          width,
          height,
          left,
          top: highlighterTop,
        };
        const withNegativeIndent = isNegativeIndent(element);
        const wsId = view.accessors.getLiveElementId(element);
        const appearance = getHighlighterAppearance(element);
        const labelProps = getHighlighterLabelOptions(options, element, elementBounds);

        return (
          <Highlighter
            key={wsId}
            elementBounds={elementBounds}
            withNegativeIndent={withNegativeIndent}
            visible={!!element}
            hovered={options.hovered}
            iconGlyph={options.labelIcon}
            appearance={appearance}
            {...labelProps}
          />
        );
      })}
    </React.Fragment>
  );
}

HighlighterContainer.propTypes = {
  viewportHeight: PropTypes.number,
};

HighlighterContainer.defaultProps = {
  viewportHeight: window.innerHeight,
};

export default HighlighterContainer;
