import findTypedParentElement from './find-typed-parent-element';

const findTypedParentElements = (typedElementSelector, selection) => {
  let focusNodes = [];

  if (selection.isCollapsed) {
    focusNodes = [findTypedParentElement(typedElementSelector, selection.focusNode)];
  } else {
    const selectedNodes = selection.getRangeAt(0).getNodes();
    const setOfParentsNodes = selectedNodes.reduce((accum, node) => {
      const parentNode = findTypedParentElement(typedElementSelector, node);
      if (parentNode) accum.add(parentNode);

      return accum;
    }, new Set());
    focusNodes = Array.from(setOfParentsNodes);
  }

  return focusNodes.filter(Boolean);
};

export default findTypedParentElements;
