import { view, utils } from '@yola/ws-sdk';
import urlJoin from 'url-join';
import isBase64 from 'src/js/modules/utils/is-base-64';
import getUnique from './get-unique';
import { DATA_WS_DEPENDENCY_ATTRIBUTE } from '../constants/common';

const buildDependenciesHTML = (itemDependencies) => {
  const liveDom = view.accessors.getLiveDocument();
  const baseHref = liveDom.querySelector('base').href;
  const { css, js } = itemDependencies;
  const cssCode = css
    ? getUnique(css).reduce((code, item) => {
        const href = utils.isAbsoluteURL(item) || isBase64(item) ? item : urlJoin(baseHref, item);
        code.push(`<link ${DATA_WS_DEPENDENCY_ATTRIBUTE} href='${href}' rel='stylesheet'>`);
        return code;
      }, [])
    : [];
  const jsCode = js
    ? getUnique(js).reduce((code, item) => {
        const href = utils.isAbsoluteURL(item) || isBase64(item) ? item : urlJoin(baseHref, item);
        code.push(`<script ${DATA_WS_DEPENDENCY_ATTRIBUTE} src='${href}'></script>`);
        return code;
      }, [])
    : [];
  return `${cssCode.join('')}${jsCode.join('')}`;
};

export default buildDependenciesHTML;
