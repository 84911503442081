import actionTypes from './action-types';
import externalUpsellTypes from './external-upsell-types';
import platformComponents from './platform-components';
import preferences from './preferences';
import userData from './user-data';

export default {
  actionTypes,
  externalUpsellTypes,
  platformComponents,
  preferences,
  userData,
};
