export const cache = new Map();
const MAX_EXECUTION_TIME = 10000;

const getImageByMeta = (imgMeta) =>
  new Promise((resolve, reject) => {
    const {
      src,
      mediaSource,
      width,
      height,
      position: { left, top },
      context,
    } = imgMeta;

    if (cache.has(src)) {
      resolve(cache.get(src));
      return;
    }

    const tmpImage = context.createElement('img');
    tmpImage.crossOrigin = 'anonymous';
    tmpImage.src = mediaSource || src;

    tmpImage.setAttribute('data-width', width);
    tmpImage.setAttribute('data-height', height);

    tmpImage.setAttribute('data-top', top);
    tmpImage.setAttribute('data-left', left);

    const timer = setTimeout(reject, MAX_EXECUTION_TIME);
    tmpImage.onload = () => {
      clearTimeout(timer);
      cache.set(src, tmpImage);
      resolve(tmpImage);
    };
    tmpImage.onerror = reject;
  });

export default getImageByMeta;
