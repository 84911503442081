import store from 'src/js/store';
import selectors from '../selectors';

function findAllFor(element) {
  const state = store.getState();
  const registeredTools = selectors.getTools(state);

  return registeredTools.filter((tool) => tool.matches(element));
}

export default findAllFor;
