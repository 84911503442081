import { useSelector, shallowEqual } from 'react-redux';
import { selectors, verifiers } from '@yola/subscription-manager-js';
import useFeatureFlags from 'yola-editor/src/js/modules/feature-flags/hooks/use-feature-flags';
import getWhiteLabelStatus from 'yola-editor/src/js/modules/user/selectors/white-label-status';

export const extraPrices = {
  FREE_COM_DOMAIN: 'free_com_domain',
  NO_DISCOUNTS: 'no_discounts',
};

function useDomainsExtraPrice(isSubscriptionPlanSelected, isAnnulSubscriptionSelected) {
  const subscriptionsList = useSelector(selectors.getSubscriptionList, shallowEqual);
  const activeSubscriptions = useSelector(selectors.getActiveSubscriptions, shallowEqual);
  const [featureFlags] = useFeatureFlags(['freedomain']);
  const { freedomain: isFreeDomainFlagEnabled } = featureFlags;
  const isWL = useSelector(getWhiteLabelStatus);

  if (!subscriptionsList || !activeSubscriptions || isWL) return null;

  const activeSubscriptionTypes = activeSubscriptions.map(({ type }) => type);
  const hasPaidSubscription = verifiers.hasPaidHostingPackageType(activeSubscriptionTypes);
  const hasAnnualPaidHostingSubscription =
    verifiers.hasAnnualPaidHostingSubscription(activeSubscriptions);
  const hasEverHadDomainSubscription = subscriptionsList.some(({ type }) =>
    verifiers.isDomainPackageType(type)
  );
  const withFreeComDomainExtraPrice = isSubscriptionPlanSelected
    ? isAnnulSubscriptionSelected &&
      !hasAnnualPaidHostingSubscription &&
      !hasEverHadDomainSubscription
    : !hasPaidSubscription && !hasEverHadDomainSubscription;

  if (withFreeComDomainExtraPrice) {
    return extraPrices.FREE_COM_DOMAIN;
  }

  if (
    (isAnnulSubscriptionSelected || isFreeDomainFlagEnabled) &&
    hasAnnualPaidHostingSubscription &&
    !hasEverHadDomainSubscription
  ) {
    return extraPrices.NO_DISCOUNTS;
  }

  return null;
}

export default useDomainsExtraPrice;
