import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { history, i18next } from '@yola/ws-sdk';
import { designSystem } from '@yola/ws-ui';
import bowser from 'yola-bowser';
import segment from 'src/js/modules/analytics/segment';

const {
  trackers: { trackUndoTriggerClicked },
} = segment;

const { EditorHeader } = designSystem;

function UndoContainer() {
  const canUndo = !!useSelector(history.selectors.getNextUndo);
  const dispatch = useDispatch();
  const undo = useCallback(() => {
    dispatch(history.actions.undo());
    trackUndoTriggerClicked();
  }, [dispatch]);

  return (
    <EditorHeader.UndoButton
      onClick={undo}
      disabled={!canUndo}
      popoverProps={{
        title: i18next.t('Undo'),
        content: i18next.t('Undo your most recent change.'),
        hotkey: bowser.macOS ? '\u2318Z' : i18next.t('Ctrl+Z'),
      }}
    />
  );
}

export default UndoContainer;
