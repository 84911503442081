import { extensions, i18next, integration, view } from '@yola/ws-sdk';
import store from 'src/js/store';
import setDisabledStatus from 'src/js/modules/view/actions/set-disabled-status';
import config from '../constants/config';
import copyImageToClipboard from './copy-image-to-clipboard';

const handleCopyEvent = async (event) => {
  const settings = extensions.accessors.getExtensionSettings(config.slug);
  const featureFlags = await integration.helpers.requestFeatureFlags(['image_copy_paste']);

  const { image_copy_paste: isCopyPasteEnabled } = featureFlags;

  if (!isCopyPasteEnabled) return;

  const state = store.getState();
  const selectedElementId = view.selectors.getSelectedElement(state);
  const hoveredElementId = view.selectors.getHoveredElement(state);
  const targetElementId = selectedElementId || hoveredElementId;
  const element = view.accessors.getLiveElement(targetElementId);

  if (
    !targetElementId ||
    !element?.matches(`${settings.imageContainer}, ${settings.blockWithBackgroundQuerySelector}`)
  ) {
    return;
  }

  event.preventDefault();
  store.dispatch(view.actions.setLoadedStatus(i18next.t('Copying to clipboard...')));
  store.dispatch(setDisabledStatus(true));

  await copyImageToClipboard(element, config);

  store.dispatch(view.actions.setLoadedStatus(true));
  store.dispatch(setDisabledStatus(false));
};

export default handleCopyEvent;
