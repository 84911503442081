import React, { useContext } from 'react';
import BlockSettingsContext from '../contexts/block-settings-context';
import BlockBackgroundImageContainer from './block-background-image-container';

const BlockBackgroundContainer = () => {
  const {
    options: { backgroundOptions, displayOptions },
  } = useContext(BlockSettingsContext);

  if (!backgroundOptions || backgroundOptions.length === 0) return null;

  const [{ src, blockNode, enableIf }] = backgroundOptions;

  if (enableIf) {
    const [{ id, value }] = enableIf;
    const currentOption = displayOptions.find((option) => option.id === id);

    if (currentOption && currentOption.isEnabled !== value) return null;
  }

  return <BlockBackgroundImageContainer mediaSrc={src} blockNode={blockNode} />;
};

export default BlockBackgroundContainer;
