import React from 'react';
import PropTypes from 'prop-types';
import { i18next } from '@yola/ws-sdk';
import { Field, FieldArray, designSystem } from '@yola/ws-ui';
import InputGroupField from 'src/js/modules/common/components/input-group-field';
import CheckboxField from 'src/js/modules/common/components/checkbox-field';
import OptionFields from './option-fields';
import helpers from '../helpers';
import constants from '../constants';

const { common } = constants;
const { LABEL_INPUT, PLACEHOLDER_INPUT, OPTIONS_INPUT } = common;

const { Stack, ControlGroup } = designSystem;

const FieldContentWithOptions = (props) => {
  const {
    labelValue,
    isLabelRequired,
    minOptionsCount,
    placeholders,
    handleChange,
    onRequiredCheckboxClick,
  } = props;

  const labelFieldValidation = (value) => {
    if (isLabelRequired)
      return helpers.validateRequired(value) || helpers.validatePersonalData(value);

    return helpers.validatePersonalData(value);
  };

  return (
    <Stack gap="spacing-m">
      <ControlGroup title={i18next.t('Label')} {...{ required: isLabelRequired }}>
        <Field
          component={InputGroupField}
          value={labelValue}
          name="label"
          placeholder={placeholders[LABEL_INPUT]}
          onChange={handleChange}
          validate={(value) => labelFieldValidation(value)}
        />
      </ControlGroup>
      <ControlGroup title={i18next.t('Options')} required>
        <FieldArray name="options">
          {(arrayFieldProps) => (
            <OptionFields
              {...arrayFieldProps}
              placeholder={placeholders[OPTIONS_INPUT]}
              min={minOptionsCount}
            />
          )}
        </FieldArray>
      </ControlGroup>
      {onRequiredCheckboxClick && (
        <Field
          name="required"
          component={CheckboxField}
          id="displayRequireCheckbox"
          label={i18next.t('Make required')}
          onClick={onRequiredCheckboxClick}
        />
      )}
    </Stack>
  );
};

FieldContentWithOptions.propTypes = {
  labelValue: PropTypes.string,
  isLabelRequired: PropTypes.bool,
  minOptionsCount: PropTypes.number,
  placeholders: PropTypes.shape({
    [LABEL_INPUT]: PropTypes.string,
    [PLACEHOLDER_INPUT]: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  onRequiredCheckboxClick: PropTypes.func,
};

FieldContentWithOptions.defaultProps = {
  labelValue: '',
  isLabelRequired: false,
  minOptionsCount: 1,
  onRequiredCheckboxClick: null,
};

export default FieldContentWithOptions;
