import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';

const { Box, Paragraph, ActionButton } = designSystem;

const replaceWithBr = (text) => text.replace(/\n/g, '<br />');

const TextParagraphCollapser = ({ text, showTitle, collapseTitle, maxLength, collapsed }) => {
  const isTextOverflow = text.length > maxLength;
  const [isCollapsed, setIsCollapsed] = useState(collapsed);
  const label = isCollapsed ? showTitle : collapseTitle;
  const textToShow = isCollapsed && isTextOverflow ? `${text.slice(0, maxLength)}...` : text;

  const handleCollapse = () => setIsCollapsed(!isCollapsed);

  return (
    <div className="ws-text-collapser">
      <Paragraph>
        <span
          // eslint-disable-next-line yola/react/no-danger
          dangerouslySetInnerHTML={{ __html: replaceWithBr(textToShow) }}
        />
      </Paragraph>
      {isTextOverflow && (
        <Box marginTop="spacing-2-xs">
          <ActionButton format="text" size="small" label={label} onClick={handleCollapse} />
        </Box>
      )}
    </div>
  );
};

TextParagraphCollapser.propTypes = {
  text: PropTypes.string.isRequired,
  showTitle: PropTypes.string.isRequired,
  collapseTitle: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  collapsed: PropTypes.bool,
};

TextParagraphCollapser.defaultProps = {
  maxLength: 75,
  collapsed: true,
};

export default TextParagraphCollapser;
