import React from 'react';
import PropTypes from 'prop-types';
import { designSystem, Modal, PanelGroup, Panel } from '@yola/ws-ui';
import { i18next, bowser } from '@yola/ws-sdk';

const {
  DialogButtonGroup,
  DialogButton,
  DialogButtonGroupSeparator,
  ControlGroup,
  List,
  ListItem,
  Paragraph,
  DialogHeader,
  DialogHeaderTitle,
  Stack,
  Box,
} = designSystem;

const isMobile = bowser.mobile;

const WIDTH = 336;
const MIN_HEIGHT = 418;

const IntegrationSettingsDialogScaffolding = ({ captions, children, onSubmit, onCancel }) => (
  <Modal
    overlay="visible"
    width={WIDTH}
    height="auto"
    minHeight={MIN_HEIGHT}
    centered
    resizable={false}
    draggable={false}
    fullscreen={isMobile}
    handleSubmit={onSubmit}
    handleCancel={onCancel}
  >
    <PanelGroup height="100%">
      <Panel height="48" align="middle" corners="squared" style={{ flexShrink: '0' }}>
        <DialogHeader>
          <DialogHeaderTitle>{captions.title}</DialogHeaderTitle>
        </DialogHeader>
      </Panel>
      <Panel height="100%" scrollable style={{ borderBottom: 'none' }}>
        <Box margin="spacing-m" marginTop="spacing-s" marginBottom="spacing-s">
          <Stack gap="spacing-m">
            <ControlGroup title={captions.controlName}>
              <Stack gap="spacing-3-xs">{children}</Stack>
            </ControlGroup>
            <ControlGroup title={i18next.t('Important')}>
              <List>
                {captions.notes.map((note, index) => (
                  // eslint-disable-next-line yola/react/no-array-index-key
                  <ListItem key={`integration-note-${index}`} appearance="medium-emphasis">
                    <Paragraph appearance="medium-emphasis" size="small">
                      {note}
                    </Paragraph>
                  </ListItem>
                ))}
              </List>
            </ControlGroup>
          </Stack>
        </Box>
      </Panel>
      <Panel style={{ flexShrink: '0' }}>
        <DialogButtonGroup>
          <DialogButton onClick={onSubmit}>{i18next.t('Submit')}</DialogButton>
          <DialogButtonGroupSeparator />
          <DialogButton onClick={onCancel}>{i18next.t('Cancel')}</DialogButton>
        </DialogButtonGroup>
      </Panel>
    </PanelGroup>
  </Modal>
);

IntegrationSettingsDialogScaffolding.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    controlName: PropTypes.string.isRequired,
    notes: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default IntegrationSettingsDialogScaffolding;
