import { dialogs, i18next } from '@yola/ws-sdk';
import { designSystem } from '@yola/ws-ui';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import React from 'react';
import config from 'src/js/modules/config';

const { Paragraph } = designSystem;
const showClipboardAccessDeniedDialog = () => {
  const guideUrl = config.accessors.getClipboardAccessInstructionUrl();
  const description = i18next.t(
    'Access to the system clipboard is restricted by your browser settings. To grant access, update your browser permissions. <a href="{learnMoreUrl}" target="_blank">Learn more</a>',
    { learnMoreUrl: guideUrl }
  );

  dialogs.operations.show(dialogTypes.ALERT_DIALOG, {
    glyph: 'alert-circle',
    width: 384,
    captions: {
      title: i18next.t('Clipboard access denied'),
      dismiss: i18next.t('Got it'),
    },
    content: (
      <React.Fragment>
        <Paragraph align="center" appearance="medium-emphasis">
          <span
            // eslint-disable-next-line yola/react/no-danger
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </Paragraph>
      </React.Fragment>
    ),
    onDismiss() {
      dialogs.operations.hide();
    },
  });
};

export default showClipboardAccessDeniedDialog;
