import { i18next, customization } from '@yola/ws-sdk';
import store from 'src/js/store';
import getInitialGeneralColor from './get-initial-general-color';
import isAccentColorSupported from './is-accent-color-supported';
import advancedVariableIds from '../constant/advanced-variable-ids';
import advancedVariableCategoryIds from '../constant/advanced-variable-category-ids';

const getGeneralColorsSection = (colorKey, palette) => {
  const state = store.getState();
  const { colors, parentId } = palette;
  const parentPalette = parentId && customization.selectors.getColorPalette(state, parentId);
  const initialColors = parentPalette ? parentPalette.colors : colors;
  const initialBackgroundColor = getInitialGeneralColor(colorKey, initialColors);

  const generalColors = [
    {
      colorKey,
      title: i18next.t('Background block color'),
      variableId: advancedVariableIds.BACKGROUND_COLOR,
      variableCategory: advancedVariableCategoryIds.GENERAL,
      color: colors[colorKey],
      isColorChanged: colors[colorKey] !== initialBackgroundColor,
    },
  ];

  const accent1ColorKey = `${colorKey}-primary`;
  const accent2ColorKey = `${colorKey}-secondary`;

  if (isAccentColorSupported(accent1ColorKey)) {
    const initialColor = getInitialGeneralColor(accent1ColorKey, initialColors);
    const color = colors[accent1ColorKey];

    generalColors.push({
      colorKey: accent1ColorKey,
      title: i18next.t('Accent color #1'),
      description: i18next.t(
        'This color is used for primary button, active navigation items, and graphic elements'
      ),
      variableId: advancedVariableIds.ACCENT_1,
      variableCategory: advancedVariableCategoryIds.GENERAL,
      color,
      isColorChanged: color !== initialColor,
    });
  }

  if (isAccentColorSupported(accent2ColorKey)) {
    const initialColor = getInitialGeneralColor(accent2ColorKey, initialColors);
    const color = colors[accent2ColorKey];

    generalColors.push({
      colorKey: accent2ColorKey,
      title: i18next.t('Accent color #2'),
      description: i18next.t(
        'This color is used for links, feature and social icons, and other design elements'
      ),
      variableId: advancedVariableIds.ACCENT_2,
      variableCategory: advancedVariableCategoryIds.GENERAL,
      color,
      isColorChanged: color !== initialColor,
    });
  }

  return generalColors;
};

export default getGeneralColorsSection;
