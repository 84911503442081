const addInlineStylesToDependencies = (dependencies, cssString) => {
  if (!cssString) return dependencies;
  const { css } = dependencies;
  const base64String = btoa(cssString);
  css.push(`data:text/css;base64,${base64String}`);
  return {
    ...dependencies,
    css,
  };
};

export default addInlineStylesToDependencies;
