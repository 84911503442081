import { saving, dialogs, i18next } from '@yola/ws-sdk';
import dialogTypes from '../../dialogs/constants/dialog-types';

const savingError = (store) => (next) => (action) => {
  if (action.type === saving.actionTypes.SET_ERROR) {
    const { dispatch } = store;

    dispatch(
      dialogs.actions.show(dialogTypes.SAVE_ERROR_DIALOG, {
        captions: {
          title: i18next.t('Site saving failed'),
          description: i18next.t('An error occurred while saving your site'),
        },
      })
    );

    dispatch(saving.actions.setStatus(saving.constants.statuses.UNSAVED));
  }

  next(action);
};

export default savingError;
