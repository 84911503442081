let removeUnloadHandler = null;

const beforeUnloadListener = {
  isEnabled: false,
  add(opts) {
    if (this.isEnabled) this.remove();
    this.isEnabled = true;

    const onBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = '';
      if (opts && opts.once) this.isEnabled = false;
    };
    window.addEventListener('beforeunload', onBeforeUnload, opts);

    removeUnloadHandler = () => {
      this.isEnabled = false;
      removeUnloadHandler = null;
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  },
  remove() {
    if (removeUnloadHandler) removeUnloadHandler();
  },
};

export default beforeUnloadListener;
