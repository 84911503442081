import actionTypes from '../constants/action-types';

const setProgress = (progress = {}) => ({
  type: actionTypes.SET_PROGRESS,
  payload: {
    progress,
  },
});

export default setProgress;
