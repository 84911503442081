export default {
  POPOVER_ANIMATION_TIME: 800,
  MODAL_DESKTOP_WIDTH: 568, // in px
  MODAL_DESKTOP_HEIGHT: 600, // in px
  CONTROL_PANEL_HEIGHT: 48, // in px
  SCROLL_DELAY: 500,
  ENTER_KEY: 'Enter',
  LANGUAGE_SELECTOR_TAG: 'ws-language-selector',
  UPVOTE_INTERRUPTION_ID: 'automated-translations',
};
