import React from 'react';
import PropTypes from 'prop-types';
import { dialogs, i18next } from '@yola/ws-sdk';
import { AlertDialog } from '@yola/ws-ui';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';

function FileTypeInfoDialog(props) {
  const { captions, items, elementId, onDismiss, dismissedFilesNamesList, onDialogCancel } = props;

  const getCaptions = (captionsOverrides) => ({
    title: i18next.t('File format not supported'),
    description: i18next.t(
      'Only JPEG, JPG, PNG, SVG, and GIF files are allowed.\n Upload error: {dismissedFilesNamesList}',
      {
        dismissedFilesNamesList: dismissedFilesNamesList.join(', '),
      }
    ),
    dismiss: i18next.t('Return'),
    ...captionsOverrides,
  });

  const handleDismiss = (...args) => {
    onDialogCancel();
    if (onDismiss) {
      onDismiss(...args);
    } else {
      dialogs.operations.show(dialogTypes.GALLERY_DIALOG, { items, elementId });
    }
  };

  return <AlertDialog onDismiss={handleDismiss} captions={getCaptions(captions)} height="auto" />;
}

FileTypeInfoDialog.defaultProps = {
  dismissedFilesNamesList: [],
  captions: {},
  items: [],
  onDismiss: null,
};

FileTypeInfoDialog.propTypes = {
  onDismiss: PropTypes.func,
  dismissedFilesNamesList: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.arrayOf(PropTypes.shape()),
  elementId: PropTypes.string.isRequired,
  captions: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    dismiss: PropTypes.string,
  }),
  onDialogCancel: PropTypes.func.isRequired,
};

export default FileTypeInfoDialog;
