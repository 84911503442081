const normalizeValues = (optionsToCheck) =>
  optionsToCheck.map((option) => {
    const { value, options } = option;
    if (!options) return option;
    const [defaultValue] = options;

    if (options.some((currentOption) => currentOption.value === value)) {
      return option;
    }
    return {
      ...option,
      value: defaultValue.value,
    };
  });

export default normalizeValues;
