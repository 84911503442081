import { constants } from '@yola/subscription-manager-js';

const { ADVERTISING_TYPES } = constants;

const advertisingLimit = ({ availableSubscriptionTypes }) => {
  const hasAvailableAdvertisingSubscription = availableSubscriptionTypes.some((subscriptionType) =>
    ADVERTISING_TYPES.includes(subscriptionType)
  );

  return {
    available: hasAvailableAdvertisingSubscription,
  };
};

export default advertisingLimit;
