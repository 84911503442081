import colorPaletteConfigLoader from './color-palette-config-loader';
import customColorAutomation from './custom-color-automation';
import userFontsLoader from './user-fonts-loader';
import websiteDesignMigration from './website-design-migration';

export default {
  colorPaletteConfigLoader,
  customColorAutomation,
  userFontsLoader,
  websiteDesignMigration,
};
