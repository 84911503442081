import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import TabContentBox from './tab-content-box';
import FontPairPreset from './font-pair-preset';

const { Stack } = designSystem;

function FontsSection(props) {
  const { fonts, activeFontId, setActiveFont, fontObserver } = props;

  return (
    <div className="ws-fonts-section">
      <TabContentBox>
        <Stack gap="spacing-m">
          {fonts.map((font) => (
            <FontPairPreset
              key={font.id}
              id={font.id}
              title={font.label}
              previewUrl={font.previewUrl}
              isSelected={font.id === activeFontId}
              onClick={setActiveFont}
              observer={fontObserver}
            />
          ))}
        </Stack>
      </TabContentBox>
    </div>
  );
}

FontsSection.propTypes = {
  fonts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      previewUrl: PropTypes.string.isRequired,
      variables: PropTypes.shape().isRequired,
    })
  ).isRequired,
  activeFontId: PropTypes.string.isRequired,
  setActiveFont: PropTypes.func.isRequired,
  fontObserver: PropTypes.shape({
    observe: PropTypes.func,
  }).isRequired,
};

export default FontsSection;
