import actionTypes from '../constants/action-types';

const setPublishingStatus = (publishingStatus) => ({
  type: actionTypes.SET_PUBLISHING_STATUS,
  payload: {
    publishingStatus,
  },
});

export default setPublishingStatus;
