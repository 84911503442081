import { saving, site, template } from '@yola/ws-sdk';
import starterScreen from 'yola-editor/src/js/modules/starter-screen';
import { saveSiteWithEffect } from 'src/js/modules/saving/helpers/save-site';
import migrations from '../migrations';

const websiteDesignMigration = (store) => (next) => async (action) => {
  if (
    action.type !== starterScreen.actionTypes.SET_PROGRESS ||
    action.payload.progress.progress !== 100
  ) {
    next(action);
    return;
  }

  const state = store.getState();
  const isMpt = template.verifiers.isMpt(state);

  if (!isMpt) {
    next(action);
    return;
  }

  const migrationResults = await Promise.all(
    Object.values(migrations).map((migration) => migration(store))
  );

  if (migrationResults.includes(true)) {
    /**
     * TODO: move this call to saving-manager in SDK
     * (https://github.com/yola/ws-editor/issues/2735)
     */
    store.dispatch(saving.actions.setStatus(saving.constants.statuses.UNSAVED));

    /**
     * When initial site setup isn't complete we can omit calling saving action here
     * and rely on initial site setup to call saving afterwards.
     */

    const isSetupCompleted = site.selectors.getInitialSetupCompleted(state);
    const isInitialSettingsApplied = site.selectors.getInitialSettingsApplied(state);
    if (template.verifiers.hasInitialSetup() && !isSetupCompleted && !isInitialSettingsApplied) {
      next(action);
      return;
    }

    await saveSiteWithEffect(() => {
      next(action);
    });
  } else {
    next(action);
  }
};

export default websiteDesignMigration;
