import getSubmoduleConfig from '../../../helpers/get-submodule-config';
import { SUBMODULE_NAME } from '../constants';

const canBlockBeOverlaidByHeader = (node) => {
  const { notOverlappedBlocksSelector } = getSubmoduleConfig(SUBMODULE_NAME);

  if (!node) throw new Error('`node` should be specified');

  if (typeof notOverlappedBlocksSelector !== 'string')
    throw new Error('`notOverlappedBlocksSelector` should be a string');

  return !node.matches(notOverlappedBlocksSelector);
};

export default canBlockBeOverlaidByHeader;
