import registeredSettings from '../helpers/registered-settings';

const defaultSettings = {
  priority: 0,
};

const registerElementSettings = (config) => {
  const { id } = config;
  if (!id) {
    throw new Error(`Element settings config should have an unique id`);
  }

  if (registeredSettings.some((setting) => setting.id === id)) {
    throw new Error(`Element settings with id "${id}" is already registered`);
  }

  registeredSettings.push({
    ...defaultSettings,
    ...config,
  });
};

export default registerElementSettings;
