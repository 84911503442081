export default {
  TUTORIALS: 'tutorials',
  GETTING_STARTED: 'getting-started',
  WELCOME_TOUR: 'welcome-tour',
  MULTILINGUAL_TOUR: 'multilingual-tour',
  HELP_CENTER: 'help-center',
  CONTACT_SUPPORT: 'contact-support',
  REQUEST_A_FEATURE: 'request-a-feature',
  ORDER_A_WEBSITE: 'order-a-website',
  COMPLIANCE_SERVICE: 'compliance-service',
  TERMS_OF_USE: 'terms-of-use',
  PRIVACY_POLICY: 'privacy-policy',
};
