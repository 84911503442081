import { textFormatting } from '@yola/ws-sdk';
import getCustomTextColor from '../../website-design/helpers/get-custom-text-color';
import getCircleBracketsContent from '../../utils/get-circle-brackets-content';
import { AVAILABLE_SURFACES } from '../constants/common';

const generateBlockCustomOptionsStylesheet = (stylesheet, usedTextOptions, blockId) => {
  if (!stylesheet) return '';
  const rootVariableMap = textFormatting.helpers.getCssRootVariablesMap();

  const surfacesRules = AVAILABLE_SURFACES.reduce((accum, surfaceName) => {
    const variablesValues = usedTextOptions
      .map((textVariableName) => {
        let color = getCustomTextColor(textVariableName, surfaceName);
        if (!color) return '';

        if (color.includes('var')) {
          color = rootVariableMap[getCircleBracketsContent(color)];
        }

        return `${textVariableName}: ${color};`;
      })
      .join('');

    return `${accum} .${surfaceName} {${variablesValues}}`;
  }, '');

  const blockPrefix = blockId.substring(0, blockId.lastIndexOf('-'));
  const newBlockSelector = `[id^="${blockPrefix}-"]`;

  const blockRules = Array.from(stylesheet.cssRules)
    .filter((rule) => rule.selectorText.includes(blockId))
    .map(({ selectorText, style }) => {
      const selector = selectorText.replace(`#${blockId}`, newBlockSelector);

      const blockRule = style.cssText
        .split(';')
        .filter(Boolean)
        .map((itm) => {
          const [variableName, color] = itm.split(':');
          let rootColor = color;
          if (rootColor.includes('var')) {
            rootColor = rootVariableMap[getCircleBracketsContent(rootColor)];
          }
          return `${variableName}: ${rootColor};`;
        })
        .join('');

      return `${selector} {${blockRule}}`;
    })
    .join('');

  return `${surfacesRules} ${blockRules}`;
};

export default generateBlockCustomOptionsStylesheet;
