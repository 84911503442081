const calculateHighlighterOffset = () => {
  // css tokens from ws-ui Highlighter element (https://github.com/yola/ws-ui/pull/771)
  const highlighterIndent = parseFloat(
    getComputedStyle(document.body).getPropertyValue('--ws-highlighter-indent')
  );
  const highlighterBorderWidth = parseFloat(
    getComputedStyle(document.body).getPropertyValue('--ws-highlighter-border-width')
  );

  return highlighterIndent + highlighterBorderWidth / 2;
};

export default calculateHighlighterOffset;
