import {
  LARGE_DESKTOP_MIN_WIDTH,
  MODAL_HEIGHT,
  MODAL_HEIGHT_LARGE_DESKTOP,
  MODAL_OFFSET_BOTTOM,
  MODAL_OFFSET_RIGHT,
  MODAL_WIDTH,
  MODAL_WIDTH_LARGE_DESKTOP,
} from '../constants';

const getModalSizes = () => {
  const width =
    window.innerWidth < LARGE_DESKTOP_MIN_WIDTH ? MODAL_WIDTH : MODAL_WIDTH_LARGE_DESKTOP;
  const height =
    window.innerWidth < LARGE_DESKTOP_MIN_WIDTH ? MODAL_HEIGHT : MODAL_HEIGHT_LARGE_DESKTOP;
  const maxHeight = window.innerHeight - MODAL_OFFSET_BOTTOM;

  return {
    width,
    height: height < maxHeight ? height : maxHeight,
    offsetX: window.innerWidth - width - MODAL_OFFSET_RIGHT,
    offsetY: window.innerHeight - height - MODAL_OFFSET_BOTTOM,
  };
};

export default getModalSizes;
