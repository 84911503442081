import { view } from '@yola/ws-sdk';
import traverse from 'traverse';
import React from 'react';
import { APPEARANCE_TAB_ID } from 'src/js/modules/element-settings/constants';

const prepareElementSettings = (elements, elementSettings) =>
  elements.reduce((settingsMap, element) => {
    const matchedSettings = elementSettings.filter((setting) =>
      setting.matches(element, setting.settings)
    );
    if (!matchedSettings) return settingsMap;

    matchedSettings
      .sort((a, b) => b.priority - a.priority)
      .forEach((setting) => {
        const { settings } = setting;
        let targetElement = element;

        if (setting.getTargetElement) {
          targetElement = setting.getTargetElement(targetElement, settings);
        }

        const elementId = view.accessors.getLiveElementId(targetElement);
        if (!elementId) return;

        const preparedSettings = {
          priority: 0,
          ...setting,
          elementId,
        };

        // eslint-disable-next-line func-names
        traverse(preparedSettings).forEach(function (val) {
          if (React.isValidElement(val)) {
            // eslint-disable-next-line yola/react/no-this-in-sfc
            this.update(
              React.cloneElement(val, {
                elementId,
              })
            );
          }

          if (val?.id === APPEARANCE_TAB_ID) {
            const newOpts = val.options.map((option) => {
              let targetElementIds = [elementId];
              if (option.querySelector) {
                const liveElement = view.accessors.getLiveElement(elementId);
                const optionElements = liveElement.querySelectorAll(option.querySelector);
                targetElementIds = [...optionElements].map((el) =>
                  view.accessors.getLiveElementId(el)
                );
              }

              return {
                ...option,
                elementIds: targetElementIds,
              };
            });

            this.update({
              ...val,
              options: newOpts,
            });
          }
        });

        settingsMap.push(preparedSettings);
      });

    return settingsMap;
  }, []);
export default prepareElementSettings;
