import actionTypes from '../constants/action-types';

const fetchUpgradeOptions = (siteId) => ({
  type: actionTypes.FETCH_UPGRADE_OPTIONS,
  payload: {
    siteId,
  },
});

export default fetchUpgradeOptions;
