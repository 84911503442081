import constants from '../constants';

const getDropPosition = (monitor, componentInstance) => {
  const node = componentInstance.rootElement.current;
  const { x, width } = node.getBoundingClientRect();
  const currentOffset = monitor.getClientOffset();
  const diff = currentOffset.x - x;

  if (diff < width / 2) {
    return constants.dnd.BEFORE;
  }

  return constants.dnd.AFTER;
};

export default getDropPosition;
