import { view } from '@yola/ws-sdk';
import getNewNumericPositionBySibling from './get-new-numeric-position-by-sibling';
import getOldNumericPositionBySibling from './get-old-numeric-position-by-sibling';
import getWidgetNumericPosition from './get-widget-numeric-position';
import findSiblingWidget from './find-sibling-widget';
import matchWidgetElement from './match-widget-element';
import getElementNumericPosition from '../../../utils/helpers/get-element-numeric-position';
import adjacentPositions from '../../../blocks/constants/adjacent-positions';

const { BEFORE_END } = adjacentPositions;

const getSiblingNumericPosition = (siblingElementId) => {
  const siblingElement = view.accessors.getLiveElement(siblingElementId);

  if (matchWidgetElement(siblingElement)) {
    return getWidgetNumericPosition(siblingElementId);
  }

  return getElementNumericPosition(siblingElementId);
};

const getNewShiftedWidgetNumericPosition = (positionInfo) => {
  let { refElementId, position: adjacentPosition } = positionInfo;

  if (adjacentPosition === BEFORE_END) {
    const siblingWidget = findSiblingWidget(refElementId);

    if (!siblingWidget) return 1;

    ({ refElementId, adjacentPosition } = siblingWidget);
  }

  const siblingNumericPosition = getSiblingNumericPosition(refElementId);

  return getNewNumericPositionBySibling(adjacentPosition, siblingNumericPosition);
};

const getOldShiftedWidgetNumericPosition = (positionInfo, newNumericPosition, isBlockChanged) => {
  let { refElementId, position: adjacentPosition } = positionInfo;

  if (adjacentPosition === BEFORE_END) {
    const siblingWidget = findSiblingWidget(refElementId);

    if (!siblingWidget) return 1;

    ({ refElementId, adjacentPosition } = siblingWidget);
  }

  const siblingNumericPosition = getSiblingNumericPosition(refElementId);

  return getOldNumericPositionBySibling(
    adjacentPosition,
    siblingNumericPosition,
    newNumericPosition,
    isBlockChanged
  );
};

const getShiftedWidgetNumericPositions = ({
  currentPosition,
  previousPosition,
  isBlockChanged = false,
}) => {
  const newPosition = getNewShiftedWidgetNumericPosition(currentPosition);
  const oldPosition = getOldShiftedWidgetNumericPosition(
    previousPosition,
    newPosition,
    isBlockChanged
  );

  return { newPosition, oldPosition };
};

export default getShiftedWidgetNumericPositions;
