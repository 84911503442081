import isDefaultAsset from './is-default-asset';
import optionTypes from '../../constants/background-option-types';

const { BACKGROUND_IMAGE_ATTRIBUTE, BACKGROUND_IMAGE_OPTION } = optionTypes;

const handleBlockMediaImageAsFallback = (
  originalNode,
  destinationNode,
  originalOptionalChildren,
  destinationOptionalChildren,
  backgroundOptions
) => {
  if (!backgroundOptions) return false;
  const backgroundImageOption = backgroundOptions.find(({ id }) => id === BACKGROUND_IMAGE_OPTION);
  const originalBlockMedia = originalOptionalChildren.find((el) => el.id === 'block_media');
  const destinationBlockMedia = destinationOptionalChildren.find((el) => el.id === 'block_media');

  if (
    !originalNode.hasAttribute(BACKGROUND_IMAGE_ATTRIBUTE) ||
    destinationNode.hasAttribute(BACKGROUND_IMAGE_ATTRIBUTE) ||
    originalBlockMedia ||
    !destinationBlockMedia
  )
    return false;

  const { querySelector } = destinationBlockMedia;

  const blockMediaNode = destinationNode.querySelector(querySelector);
  if (!blockMediaNode) return false;
  const sourcePicture = blockMediaNode.querySelector('img');
  if (!sourcePicture) return false;

  const { src } = backgroundImageOption;
  if (isDefaultAsset(src)) return false;
  sourcePicture.setAttribute('src', src);
  return true;
};

export default handleBlockMediaImageAsFallback;
