import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import accessors from '../accessors';
import selectors from '../selectors';
import registerDialogs from '../initializers/register-dialogs';
import actions from '../actions';
import onDialogMainAction from '../helpers/on-dialog-main-action';
import onDialogCancel from '../helpers/on-dialog-cancel';
import onDialogDisplayed from '../helpers/on-dialog-displayed';

function DialogsContainer({ dialog, hideDialog }) {
  const DialogComponent = accessors.getDialogByType(dialog.type);
  const dialogProps = dialog.dialogProps || {};
  dialogProps.onDialogMainAction = () => onDialogMainAction(dialog.type);
  dialogProps.onDialogCancel = () => onDialogCancel(dialog.type);

  useEffect(() => {
    if (!dialog.type) return;

    onDialogDisplayed(dialog.type, dialogProps);
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, [dialog.type]);

  useEffect(() => {
    // safari back-forward cache fix
    window.addEventListener('pagehide', hideDialog);

    return () => {
      window.removeEventListener('pagehide', hideDialog);
    };
  }, [hideDialog]);

  return DialogComponent ? <DialogComponent {...dialogProps} /> : null;
}

DialogsContainer.propTypes = {
  dialog: PropTypes.shape({
    type: PropTypes.string,
    dialogProps: PropTypes.shape(),
  }).isRequired,
  hideDialog: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dialog: selectors.getDialog(state),
});

const mapDispatchToProps = {
  hideDialog: actions.hide,
};

registerDialogs();

export default connect(mapStateToProps, mapDispatchToProps)(DialogsContainer);
