import get from 'lodash.get';
import { blocks } from '@yola/ws-sdk';

const restoreOptions = (destinationBlockNode, options = []) => {
  const destinationBlock = blocks.accessors.getBlockByElement(destinationBlockNode);
  const destinationDisplayOptions = get(destinationBlock, 'availableSettings.displayOptions', []);

  destinationDisplayOptions.forEach((destinationOption) => {
    if (!options.includes(destinationOption.id)) return;

    const { value, values, querySelector, attribute } = destinationOption;

    if (!value && !values) return;

    const defaultValue = value || values[values.length - 1];
    if (!defaultValue) return;

    const nodes = querySelector
      ? [...destinationBlockNode.querySelectorAll(querySelector)]
      : [destinationBlockNode];
    nodes.forEach((node) => {
      node.setAttribute(attribute, defaultValue);
    });
  });
};

export default restoreOptions;
