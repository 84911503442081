import { view } from '@yola/ws-sdk';
import store from '../../../store';

const dropHandler = (props) => {
  const { autoScroll } = view.helpers;
  const { dragSourceElementId, dropTargetElementId, adjacentPosition } = props;
  autoScroll.stop();

  if (dropTargetElementId === null) return;

  store.dispatch(
    view.actions.shiftElement(dragSourceElementId, dropTargetElementId, adjacentPosition)
  );
};

export default dropHandler;
