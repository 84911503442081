import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import store from 'src/js/store';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import { i18next, site, dialogs } from '@yola/ws-sdk';
import { SubmitDialog } from '@yola/ws-ui';
import getLanguageCodes from 'src/js/modules/website-settings/helpers/get-language-codes';

function PageDeleteContainer({ onCancel, onSubmit, modalProps: { pageId, pageTitle } }) {
  const captions = {
    title: i18next.t('Delete page?'),
    submit: i18next.t('Submit'),
    cancel: i18next.t('Cancel'),
    discard: i18next.t(''),
  };

  return (
    <SubmitDialog
      captions={{
        ...captions,
        description: i18next.t('Are you sure you want to delete the "{pageTitle}" page?', {
          pageTitle,
        }),
      }}
      onSubmit={() => onSubmit(pageId)}
      onCancel={onCancel}
    />
  );
}

PageDeleteContainer.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  modalProps: PropTypes.shape({
    pageId: PropTypes.string.isRequired,
    pageTitle: PropTypes.string.isRequired,
  }).isRequired,
  // eslint-disable-next-line yola/react/no-unused-prop-types
  onDialogMainAction: PropTypes.func.isRequired,
  // eslint-disable-next-line yola/react/no-unused-prop-types
  onDialogCancel: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => dialogs.selectors.getDialog(state);

const mapDispatchToProps = (dispatch, { onDialogMainAction, onDialogCancel }) => ({
  onCancel() {
    onDialogCancel();
    dispatch(dialogs.actions.show(dialogTypes.PAGE_MANAGER));
  },

  onSubmit(pageId) {
    onDialogMainAction();
    store.dispatch(site.actions.deletePage(pageId, getLanguageCodes()));
    dispatch(dialogs.actions.show(dialogTypes.PAGE_MANAGER));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PageDeleteContainer);
