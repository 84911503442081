import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';
import { designSystem } from '@yola/ws-ui';
import { i18next, dialogs } from '@yola/ws-sdk';
import dialogTypes from '../../dialogs/constants/dialog-types';
import integrationTypes from '../../website-settings/constants/integration-types';
import validateRequiredField from '../helpers/validate-required-field';
import validateGoogleWebmasterCode from '../helpers/validate-google-webmaster-code';

const { IntegrationItem } = designSystem;
const iconSrc = '/images/integrations/google-search-console.png';

const GoogleSearchConsoleIntegrationContainer = ({
  captions,
  form,
  activeTabId,
  initialValues,
  initialShowPromptForce,
}) => {
  const dispatch = useDispatch();
  const currentValue = form[integrationTypes.GOOGLE_WEBMASTER_CODE];

  const returnToCurrentTab = (updatedForm = form, showPromptForce = false) => {
    dispatch(
      dialogs.actions.show(dialogTypes.WEBSITE_SETTINGS, {
        form: updatedForm,
        activeTabId,
        showPromptForce: showPromptForce || initialShowPromptForce || !isEqual(form, initialValues),
      })
    );
  };

  const handleValidatation = (value) =>
    validateRequiredField(value) ||
    validateGoogleWebmasterCode(captions.control.validationError)(value);

  const onConnectClick = () => {
    dispatch(
      dialogs.actions.show(dialogTypes.CONNECT_GOOGLE_SEARCH_CONSOLE_DIALOG, {
        captions,
        onCancel: () => returnToCurrentTab(),
        onSubmit: (value) => {
          const updatedForm = {
            ...form,
            [integrationTypes.GOOGLE_WEBMASTER_CODE]: value,
          };
          returnToCurrentTab(updatedForm, true);
        },
        validate: handleValidatation,
      })
    );
  };

  const onSettingsClick = () => {
    dispatch(
      dialogs.actions.show(dialogTypes.GOOGLE_SEARCH_CONSOLE_SETTINGS_DIALOG, {
        captions,
        defaultValue: currentValue,
        onCancel: () => returnToCurrentTab(),
        onSubmit: (value) => {
          const updatedForm = {
            ...form,
            [integrationTypes.GOOGLE_WEBMASTER_CODE]: value,
          };
          returnToCurrentTab(updatedForm, true);
        },
        validate: handleValidatation,
      })
    );
  };

  const onDisconnectClick = () => {
    dispatch(
      dialogs.actions.show(dialogTypes.CONFIRM_DIALOG, {
        onCancel: () => returnToCurrentTab(),
        onSubmit: () => {
          const updatedForm = {
            ...form,
            [integrationTypes.GOOGLE_WEBMASTER_CODE]: '',
          };
          returnToCurrentTab(updatedForm, true);
        },
        captions: {
          title: i18next.t('Disconnect integration'),
          description: captions.disconnectAlert,
          submit: i18next.t('Submit'),
          cancel: i18next.t('Cancel'),
        },
      })
    );
  };

  const connectedProps = {
    primaryButton: {
      iconGlyph: 'settings',
      onClick: onSettingsClick,
    },
    secondaryButton: {
      appearance: 'danger',
      label: i18next.t('Disconnect'),
      onClick: onDisconnectClick,
    },
    isSelected: true,
  };

  const disconnectedProps = {
    primaryButton: {
      appearance: 'accent',
      format: 'solid',
      label: i18next.t('Connect'),
      onClick: onConnectClick,
    },
  };

  return (
    <IntegrationItem
      title={captions.name}
      description={captions.description}
      imageSrc={iconSrc}
      {...(currentValue ? connectedProps : disconnectedProps)}
    />
  );
};

GoogleSearchConsoleIntegrationContainer.propTypes = {
  captions: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    about: PropTypes.string.isRequired,
    step1: PropTypes.shape().isRequired,
    step2: PropTypes.shape().isRequired,
    control: PropTypes.shape().isRequired,
    notes: PropTypes.arrayOf(PropTypes.string).isRequired,
    disconnectAlert: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape().isRequired,
  activeTabId: PropTypes.string.isRequired,
  initialValues: PropTypes.shape().isRequired,
  initialShowPromptForce: PropTypes.bool,
};

GoogleSearchConsoleIntegrationContainer.defaultProps = {
  initialShowPromptForce: false,
};

export default GoogleSearchConsoleIntegrationContainer;
