import { blocks, site, view, template } from '@yola/ws-sdk';
import getParentBlockByElement from 'src/js/modules/blocks/helpers/get-parent-block-by-element';

const getDefaultTraits = (elementId) => {
  const element = view.accessors.getLiveElement(elementId);
  const blockElement = getParentBlockByElement(element);

  return {
    siteId: site.accessors.getSiteId(),
    templateBuildSlug: template.accessors.getBuildSlug(),
    blockId: blocks.accessors.getBlockIdByElement(blockElement),
    blockVariationId: blocks.accessors.getVariationIdByElement(blockElement),
  };
};

export default getDefaultTraits;
