import constants from '../constants';
import getIconDialogCommonTraits from '../helpers/get-icon-dialog-common-traits';
import track from '../track';

const { events } = constants;

export default function trackSelectIconSearchPerformed({
  dialogId,
  targetElementId,
  searchTerm,
  searchResults,
  tabId,
  premiumAvailable,
}) {
  const traits = {
    dialogId,
    searchTerm,
    searchResults,
    tabId,
    premiumAvailable,
    ...getIconDialogCommonTraits(targetElementId),
  };

  track(events.SELECT_ICON_SEARCH_PERFORMED, traits);
}
