import { site, template } from '@yola/ws-sdk';
import store from 'src/js/store';

const hasActivePageNonIgnoredBlocks = () => {
  const state = store.getState();
  const emptyPageIgnoredBlocks = template.selectors.getEmptyPageIgnoredBlocks(state);
  const activePageId = site.selectors.getActivePageId(state);
  const usedBlocks = site.selectors.getUsedBlocks(state, activePageId);

  return usedBlocks.some((usedBlock) => !emptyPageIgnoredBlocks.includes(usedBlock));
};

export default hasActivePageNonIgnoredBlocks;
