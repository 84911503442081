import segment from 'src/js/modules/analytics/segment';
import auth from 'yola-editor/src/js/modules/auth';
import site from 'yola-editor/src/js/modules/site';
import dialogTypes from '../constants/dialog-types';

const {
  track,
  constants: { events, common },
} = segment;

function onDialogDisplayed(dialogType, dialogProps) {
  switch (dialogType) {
    case dialogTypes.B2C_DOMAIN_PURCHASE: {
      track(events.DOMAIN_PURCHASE_DIALOG_DISPLAYED, {
        appName: common.APP_NAME,
        siteId: auth.accessors.getSiteId(),
        templateBuildSlug: site.accessors.getSiteTemplateSlug(),
        triggerId: dialogProps.triggerId,
      });

      break;
    }

    case dialogTypes.DIFM_PRIVACY_UPSELL_DIALOG: {
      track(events.DIFM_PRIVACY_DIALOG_DISPLAYED, {
        siteId: auth.accessors.getSiteId(),
        templateBuildSlug: site.accessors.getSiteTemplateSlug(),
        dialogId: dialogTypes.DIFM_PRIVACY_UPSELL_DIALOG,
      });

      break;
    }

    case dialogTypes.PAYWALL: {
      const { meta } = dialogProps;

      track(segment.constants.events.PLAN_SELECTOR_DISPLAYED, {
        appName: segment.constants.common.APP_NAME,
        siteId: auth.accessors.getSiteId(),
        templateBuildSlug: site.accessors.getSiteTemplateSlug(),
        premiumFeature: meta && meta.upsellType,
        purchaseFlowType: meta && meta.purchaseFlowType,
        productCategory: segment.constants.common.EDITOR_PRODUCT_CATEGORY,
      });

      break;
    }

    case dialogTypes.SUBSCRIPTION_EXPIRED:
    case dialogTypes.AI_SURVEY: {
      break;
    }

    default: {
      track(events.DIALOG_DISPLAYED, {
        appName: common.APP_NAME,
        siteId: auth.accessors.getSiteId(),
        templateBuildSlug: site.accessors.getSiteTemplateSlug(),
        dialogId: dialogType,
      });
    }
  }
}

export default onDialogDisplayed;
