import { accessors, constants } from '@yola/subscription-manager-js';
import mbToBytes from '../mb-to-bytes';

const {
  hostingPackageFeatureNames: { UPLOAD_SIZE },
} = constants;

const uploadFileSizeLimit = ({ targetPackage }) => {
  const topPackage = accessors.getTopAvailableHostingPackage();

  return {
    // upload size in MB
    maxSize: mbToBytes(targetPackage.features[UPLOAD_SIZE]),
    abilityToIncreaseMaxSize:
      targetPackage.features[UPLOAD_SIZE] < topPackage.features[UPLOAD_SIZE],
    techLimitMaxSize: mbToBytes(topPackage.features[UPLOAD_SIZE]),
  };
};

export default uploadFileSizeLimit;
