import { utils } from '@yola/ws-sdk';

const validateUrl = (validationError) => (value) => {
  // eslint-disable-next-line yola/react/destructuring-assignment
  if (value && value.trim() && !utils.validation.isURL(value)) {
    return validationError;
  }
  return null;
};

export default validateUrl;
