import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { i18next } from '@yola/ws-sdk';

import dialogs from '../index';
import ConfirmActionDialog from '../components/confirm-action-dialog';
import site from '../../site';
import constants from '../constants';

const { RESET_CONFIRM_PHRASE } = constants;

function SiteResetDialogContainer({ onDialogMainAction, onDialogCancel }) {
  const dispatch = useDispatch();

  const captions = {
    title: i18next.t('Reset website'),
    label: i18next.t('Type "{RESET_CONFIRM_PHRASE}" to confirm', { RESET_CONFIRM_PHRASE }),
    description: i18next.t(
      'Reset your website if you want to start using another template. Please proceed with this step carefully. You cannot recover your site after the reset.'
    ),
    submit: i18next.t('Submit'),
    cancel: i18next.t('Cancel'),
  };

  function handleSubmit() {
    onDialogMainAction();
    dispatch(site.actions.siteReset());
  }

  function handleCancel() {
    onDialogCancel();
    dispatch(dialogs.actions.hide());
  }

  return (
    <ConfirmActionDialog
      onCancel={handleCancel}
      onSubmit={handleSubmit}
      captions={captions}
      confirmPhrase={RESET_CONFIRM_PHRASE}
    />
  );
}

SiteResetDialogContainer.propTypes = {
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

export default SiteResetDialogContainer;
