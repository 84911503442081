import { textFormatting, view, contentEditable, bowser } from '@yola/ws-sdk';

const isSafariOlderThan17 = () =>
  bowser.isSatisfied({
    safari: '<17',
  });

const selectAllText = () => {
  const selection = textFormatting.accessors.getAdvancedSelection();
  const { anchorNode } = selection;
  const closestContentEditableElement = anchorNode.parentElement.closest('[contenteditable]');

  if (!closestContentEditableElement) {
    return;
  }

  const liveDocument = view.accessors.getLiveDocument();
  const range = liveDocument.createRange();
  const firstTextNode = contentEditable.helpers.findFirstTextNode(
    closestContentEditableElement,
    true
  );
  const lastTextNode = contentEditable.helpers.findLastTextNode(
    closestContentEditableElement,
    true
  );

  if (!firstTextNode || !lastTextNode) {
    return;
  }

  const isSafariOlderThan17Version = isSafariOlderThan17();

  if (isSafariOlderThan17Version) {
    // Fix issue: https://github.com/yola/ws-editor/pull/5652#issuecomment-2161262523
    closestContentEditableElement.setAttribute('data-text-selectable', 'true');
  }

  range.setStart(firstTextNode, 0);
  range.setEnd(lastTextNode, lastTextNode.length);
  selection.removeAllRanges();
  selection.addRange(range);
  selection.refresh();

  if (isSafariOlderThan17Version) {
    closestContentEditableElement.removeAttribute('data-text-selectable');
  }
};

export default selectAllText;
