import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { i18next } from '@yola/ws-sdk';
import { Button, Text, Checkbox } from '@yola/ws-ui';
import classNames from 'classnames';
import bowser from 'yola-bowser';

function PurchaseConfirmation({ product, terms, onConfirm, onCancel }) {
  const [isAgreedToTerms, setAgreement] = useState(false);
  const { name, term, description, price } = product;

  function toggleAgreement() {
    setAgreement(!isAgreedToTerms);
  }

  function onPurchaseConfirm() {
    onConfirm(product);
  }

  return (
    <div className="purchase-confirmation">
      <Text type="heading-1" className="product-select__title">
        {i18next.t('Confirm your purchase')}
      </Text>
      <div className="purchase-confirmation__item">
        <div className="product-select__item-border-bottom">
          <Text type="heading-5" align="center" className="purchase-confirmation__plan-name">
            {name}
          </Text>
          <Text type="annotation" align="center">
            {(term === 'P1M' && i18next.t('Monthly subscription')) ||
              (term === 'P1Y' && i18next.t('Annual subscription'))}
          </Text>
        </div>
        <div
          className="product-select__item-description"
          /* eslint-disable-next-line yola/react/no-danger */
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <div className="product-select__item-border-top">
          <Text type="annotation" align="center">
            {i18next.t('Total')}
          </Text>
          <Text align="center" className="purchase-confirmation__item-price">
            {price}
          </Text>
        </div>
      </div>
      <Checkbox
        id="agreement-checkbox"
        className="purchase-confirmation__checkbox"
        onChange={toggleAgreement}
        checked={isAgreedToTerms}
      >
        <Text type="annotation" className="purchase-confirmation__checkbox-text">
          {/* eslint-disable-next-line yola/jsx-a11y/label-has-for */}
          <label
            htmlFor="agreement-checkbox"
            /* eslint-disable-next-line yola/react/no-danger */
            dangerouslySetInnerHTML={{ __html: terms }}
          />
        </Text>
      </Checkbox>
      <div className="purchase-confirmation__buttons-container">
        <Button
          className="purchase-confirmation__button"
          type="cta"
          size="large"
          onClick={onPurchaseConfirm}
          disabled={!isAgreedToTerms}
        >
          {i18next.t('Confirm')}
        </Button>
        <Button
          className={classNames(
            'purchase-confirmation__button',
            'purchase-confirmation__button--transparent',
            {
              'purchase-confirmation__button--hover': bowser.desktop,
            }
          )}
          type="link-primary"
          size="large"
          onClick={onCancel}
        >
          {i18next.t('Cancel')}
        </Button>
      </div>
    </div>
  );
}

PurchaseConfirmation.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    term: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
  }).isRequired,
  terms: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default PurchaseConfirmation;
