import applyInstruction from './apply-instruction';
import createBreakpointsSliderOption from './create-breakpoints-slider-option';
import createCheckboxOption from './create-checkbox-option';
import createColorScheme from './create-color-scheme';
import createOnlineStoreCategoryOption from './create-online-store-category-option';
import createOnlineStoreCleanUrlSelectorOption from './create-online-store-clean-url-selector-option';
import createOptionalChildren from './create-optional-children';
import createRadioOption from './create-radio-option';
import createSliderOption from './create-slider-option';
import createSurfaceOption from './create-surface-option';
import createBackgroundOption from './create-background-option';
import getCaptions from './get-captions';
import getChanges from './get-changes';
import getOptionTargetElement from './get-option-target-element';
import hasTouchedGlobals from './has-touched-globals';
import isStateTouched from './is-state-touched';
import trackWsBlockContent from './track-ws-block-content';
import updateDependentWhiteSpaceOptions from './update-dependent-white-space-options';
import handleOptions from './handle-options';
import compileLivePreviewNode from './compile-live-preview-node';
import handleBackgroundOptions from './handle-background-options';

export default {
  applyInstruction,
  createBreakpointsSliderOption,
  createCheckboxOption,
  createColorScheme,
  createOnlineStoreCategoryOption,
  createOnlineStoreCleanUrlSelectorOption,
  createOptionalChildren,
  createRadioOption,
  createSliderOption,
  createSurfaceOption,
  createBackgroundOption,
  getCaptions,
  getChanges,
  getOptionTargetElement,
  hasTouchedGlobals,
  isStateTouched,
  trackWsBlockContent,
  updateDependentWhiteSpaceOptions,
  handleOptions,
  compileLivePreviewNode,
  handleBackgroundOptions,
};
