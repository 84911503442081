import React from 'react';
import bowser from 'yola-bowser';
import { designSystem } from '@yola/ws-ui';
import constants from '../constants/common';

const { Slider } = designSystem;
const {
  MAX_SCALE,
  MIN_SCALE,
  SCALE_STEP,
  MAX_ZOOM,
  MIN_ZOOM,
  ZOOM_STEP,
  FOCAL_POINTS_SLIDER_WIDTH,
} = constants;

const getZoomSliderControl = ({
  id,
  scale,
  zoom,
  onChange,
  onBeforeChange,
  onAfterChange,
  isNegativeZoomEnabled = true,
}) => {
  const isMobile = bowser.mobile || bowser.tablet;
  const value = isNegativeZoomEnabled ? zoom : scale;

  return {
    id,
    trigger: (
      <div
        className="ws-focal-point-controls__slider"
        style={{ width: `${FOCAL_POINTS_SLIDER_WIDTH}px` }}
        id={id}
      >
        <Slider
          value={value}
          min={isNegativeZoomEnabled ? MIN_ZOOM : MIN_SCALE}
          max={isNegativeZoomEnabled ? MAX_ZOOM : MAX_SCALE}
          step={isNegativeZoomEnabled ? ZOOM_STEP : SCALE_STEP}
          {...(!isMobile && {
            handleProps: {
              'data-tip': `${Math.round(value)}%`,
              key: `${value}`,
            },
          })}
          onChange={(val) => {
            onChange(val, isNegativeZoomEnabled);
          }}
          onBeforeChange={onBeforeChange}
          onAfterChange={onAfterChange}
        />
      </div>
    ),
    width: FOCAL_POINTS_SLIDER_WIDTH,
  };
};

export default getZoomSliderControl;
