import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import calcMaxItemsCountFitContainer from '../helpers/calc-max-items-count-fit-container';
import constants from '../constants/common';

const { SkeletonFill } = designSystem;

const ITEMS_GAP = 28;

const ImageGridSkeleton = ({ itemSize, containerWidth, containerHeight }) => {
  const itemsCount = calcMaxItemsCountFitContainer({
    itemSize,
    containerWidth,
    containerHeight,
    itemsGap: ITEMS_GAP,
  });

  return (
    <div
      className="ws-image-grid-skeleton"
      style={{
        gridTemplateColumns: `repeat(auto-fit, minmax(${itemSize}px, 1fr))`,
        gap: `${ITEMS_GAP}px`,
      }}
    >
      {[...Array(itemsCount).keys()].map((item) => (
        <div
          key={item}
          className="ws-image-grid-skeleton__item"
          style={{ width: `${itemSize}px`, height: `${itemSize}px` }}
        >
          <SkeletonFill />
        </div>
      ))}
    </div>
  );
};

ImageGridSkeleton.propTypes = {
  itemSize: PropTypes.number,
  containerHeight: PropTypes.number,
  containerWidth: PropTypes.number,
};

ImageGridSkeleton.defaultProps = {
  itemSize: constants.ICON_PREVIEW_SIZE,
  containerWidth: constants.DIALOG_MIN_WIDTH,
  containerHeight: constants.ICON_PREVIEW_SIZE + ITEMS_GAP,
};

export default ImageGridSkeleton;
