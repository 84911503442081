const languageCodesMap = {
  fr: 'fr-FR',
  de: 'de-DE',
  it: 'it-IT',
  'pt-br': 'pt-BR',
  ru: 'ru-RU',
  es: 'es-ES',
  en: 'en-US',
};

export default languageCodesMap;
