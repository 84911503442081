import { i18next } from '@yola/ws-sdk';

const getWsBlockInjectedTranslations = () => ({
  WS_BLOCK_EMPTY_STATE_TITLE: i18next.t('Custom Block'),
  WS_BLOCK_EMPTY_STATE_DESCRIPTION: i18next.t(
    'Press the button to add your first widget to the block'
  ),
  WS_BLOCK_EMPTY_STATE_TOOLTIP: i18next.t('Add widget'),
});

export default getWsBlockInjectedTranslations;
