import { view } from '@yola/ws-sdk';

function updateContent(element, content) {
  const elementId = view.accessors.getLiveElementId(element);
  const newElement = element.cloneNode();
  const isContentPresent = Boolean(content.trim());

  if (isContentPresent) {
    const template = element.ownerDocument.createElement('template');
    template.innerHTML = content;
    newElement.appendChild(template);
  }

  view.operations.replaceElement(newElement, elementId);
}

export default updateContent;
