import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { utils } from '@yola/ws-sdk';
import { Button } from '@yola/ws-ui';
import UploadImageMenu from 'src/js/modules/common/components/upload-image-menu';
import getMenuCssPosition from 'src/js/modules/context-menu/helpers/get-menu-css-position';

const VERTICAL_GAP = 6;

function ReplaceImageButton(props) {
  const { label } = props;
  const triggerNodeRef = useRef(null);

  const calculatePositionDecorator = useCallback((menuElement) => {
    const { x, y } = triggerNodeRef.current.getBoundingClientRect();
    const { height } = menuElement.getBoundingClientRect();
    const eventY = y - height / 2 - VERTICAL_GAP;
    return getMenuCssPosition(menuElement, x, eventY);
  }, []);

  const getContextMenuPosition = useCallback(() => {
    if (!triggerNodeRef.current) return { x: 0, y: 0 };
    const { x, y } = triggerNodeRef.current.getBoundingClientRect();
    return {
      x,
      y,
    };
  }, []);

  return (
    <UploadImageMenu
      triggerNodeRef={triggerNodeRef}
      getContextMenuPosition={getContextMenuPosition}
      calculatePositionDecorator={calculatePositionDecorator}
      {...props}
    >
      <Button type="default" size="medium">
        {label}
      </Button>
    </UploadImageMenu>
  );
}

ReplaceImageButton.propTypes = {
  elementId: PropTypes.string,
  label: PropTypes.string.isRequired,
  onImageUpload: PropTypes.func.isRequired,
  onStockPhotoUpload: PropTypes.func.isRequired,
  onWrongFileType: PropTypes.func.isRequired,
  accept: PropTypes.string.isRequired,
  onTriggerUploadImageClick: PropTypes.func,
  onClick: PropTypes.func,
  onPhotoStockDialogCancel: PropTypes.func,
  onTriggerBrowseStockPhotosClick: PropTypes.func,
};

ReplaceImageButton.defaultProps = {
  elementId: null,
  onClick: utils.noop,
  onTriggerUploadImageClick: utils.noop,
  onTriggerBrowseStockPhotosClick: utils.noop,
  onPhotoStockDialogCancel: utils.noop,
};

export default ReplaceImageButton;
