import triggerUpgrade from './trigger-upgrade';
import orderSubscriptionPlan from './order-subscription-plan';
import fetchUpgradeOptions from './fetch-upgrade-options';
import setUpgradeOptions from './set-upgrade-options';
import startB2CDomainPurchase from './start-b2c-domain-purchase';
import startWLDomainPurchase from './start-wl-domain-purchase';
import getDomainList from './get-domain-list';
import getDomainListSuccess from './get-domain-list-success';
import getDomainListFailure from './get-domain-list-failure';
import selectDomain from './select-domain';
import orderDomain from './order-domain';
import clearDomainPurchaseData from './clear-domain-purchase-data';
import setPaymentURL from './set-payment-url';

export default {
  triggerUpgrade,
  orderSubscriptionPlan,
  fetchUpgradeOptions,
  setUpgradeOptions,
  startB2CDomainPurchase,
  startWLDomainPurchase,
  getDomainList,
  getDomainListSuccess,
  getDomainListFailure,
  selectDomain,
  orderDomain,
  clearDomainPurchaseData,
  setPaymentURL,
};
