import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { site, view, dialogs, template } from '@yola/ws-sdk';
import customUI from 'src/js/modules/custom-ui';
import useFeatureFlags from 'yola-editor/src/js/modules/feature-flags/hooks/use-feature-flags';
import FloatingActionButton from '../components/floating-action-button';
import getUnfinishedStepsCount from '../helpers/get-unfinished-steps-count';
import dialogsTypes from '../../dialogs/constants/dialog-types';
import useSiteProgressDataAttribute from '../hooks/use-site-progress-data-attribute';

const FloatingActionButtonContainer = () => {
  const isViewLoaded = useSelector(view.selectors.getLoadedStatus);
  const isCustomUIVisible = useSelector(customUI.selectors.getVisibilityState);
  const scrollPosition = useSelector(view.selectors.getScrollPosition);
  const siteProgressChecklist = useSelector(site.selectors.getSiteProgressChecklist);
  const templateBuildSlug = useSelector(template.selectors.getBuildSlug);
  const [featureFlags] = useFeatureFlags(['website_assistant']);
  const { website_assistant: isWebsiteAssistantFeatureEnabled } = featureFlags;
  const siteChecklistEnabled =
    isWebsiteAssistantFeatureEnabled && templateBuildSlug.startsWith('ws-intense-next');

  const handleClick = useCallback(() => {
    dialogs.operations.show(dialogsTypes.SITE_PROGRESS_CHECKLIST);
  }, []);

  // Here we do effect to add data attr on body for detecting if `FloatingActionButton` exist in editor
  // We need this data attr to fix issue with overlapping two floating elements (`ecwid minicart` and `progress checklist action btn`)
  // TODO We would be able to remove this effect if website assistant won't be hidden under feature flag anymore. Also we should correct styles in `hdrm/injections/iframe-additives/reset-styles.css` (ws-sdk)
  useSiteProgressDataAttribute(isViewLoaded);

  if (!siteChecklistEnabled || isCustomUIVisible || !isViewLoaded) return null;

  return (
    <div
      className="ws-floating-action-btn-container"
      style={{ transform: `translateY(${scrollPosition}px)` }}
    >
      <FloatingActionButton
        counter={getUnfinishedStepsCount(siteProgressChecklist)}
        onMouseDown={handleClick}
      />
    </div>
  );
};

export default FloatingActionButtonContainer;
