import { extensions } from '@yola/ws-sdk';
import constants from '../constants/common';

const { ICON_MAX_PREVIEW_SIZE, slug } = constants;

const getPreviewIconSize = () => {
  const settings = extensions.accessors.getExtensionSettings(slug);
  const { iconMaxSize } = settings;
  return Math.min(iconMaxSize, ICON_MAX_PREVIEW_SIZE);
};

export default getPreviewIconSize;
