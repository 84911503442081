import urlJoin from 'url-join';
import { i18next } from '@yola/ws-sdk';
import { selectors, verifiers } from '@yola/subscription-manager-js';
import user from 'yola-editor/src/js/modules/user';
import dialogs from 'yola-editor/src/js/modules/dialogs';
import site from 'yola-editor/src/js/modules/site';
import auth from 'yola-editor/src/js/modules/auth';
import config from 'src/js/modules/config';
import segment from 'src/js/modules/analytics/segment';
import { redirectToUrl } from 'yola-editor/src/js/router/utils/redirect-to-url';
import beforeUnloadListener from 'yola-editor/src/js/utils/before-unload-listener';
import featureFlagsService from 'yola-editor/src/js/modules/feature-flags/service-client';
import actionTypes from '../constants/action-types';
import productTypes from '../constants/product-types';
import upgradeTypes from '../constants/upgrade-types';
import { MAP_TERM_TO_READABLE_NAME } from '../constants/product-term';
import { ONLINE_STORE } from '../constants/upsell-types';
import triggerIds from '../constants/trigger-ids';
import trackUpgradeRedirect from '../helpers/track-upgrade-redirect';
import trackUpgradeInApp from '../helpers/track-upgrade-in-app';
import upgradeDataStorage from '../helpers/upgrade-data-storage';
import actions from '../actions';
import getUpgradeData from '../selectors/upgrade-data';
import getMinRequiredHostingPlan from '../helpers/get-min-required-hosting-plan';
import getPurchaseFlowType from '../helpers/get-purchase-flow-type';
import getCaptionsForPaywall from '../helpers/get-captions-for-paywall';
import isFreeDomainAvailable from '../helpers/is-free-domain-available';
import getUpsellSettings from '../helpers/get-upsell-settings';

const upgrade = (store) => (next) => async (action) => {
  switch (action.type) {
    case actionTypes.TRIGGER_UPGRADE: {
      const state = store.getState();
      const { upsellType, editorUpgradeAction, details } = action.payload;
      const siteId = auth.selectors.getSiteId(state);
      const isWL = user.selectors.getWhiteLabelStatus(state);
      const availablePlatformComponents = user.selectors.getAvailablePlatformComponents(state);
      const productType =
        upsellType === ONLINE_STORE ? productTypes.ONLINE_STORE : productTypes.LATITUDE;
      const { constants, track, trackAsync } = segment;

      const trackingData = {
        appName: segment.constants.common.APP_NAME,
        siteId,
        templateBuildSlug: site.selectors.getSiteTemplateSlug(state),
        premiumFeature: upsellType,
        purchaseFlowType: getPurchaseFlowType(state),
      };
      const upgradeDetails = { ...details, upsellType };

      upgradeDataStorage.setEditorUpgradeAction(editorUpgradeAction);
      upgradeDataStorage.setUpgradeDetails(upgradeDetails);

      const onCloseDialog = () => {
        editorUpgradeAction.reject();
        store.dispatch(dialogs.actions.hide());
        track(constants.events.PLAN_SELECTOR_BACK_TO_EDITOR_BUTTON_CLICKED, {
          ...trackingData,
          productCategory: constants.common.EDITOR_PRODUCT_CATEGORY,
        });
      };

      // b2c & reseller platform upgrade
      if (availablePlatformComponents.includes(user.platformComponents.PAYMENTS)) {
        if (upsellType === ONLINE_STORE) {
          trackAsync(constants.events.UPGRADE_INITIATED, {
            ...trackingData,
            productCategory: constants.common.EDITOR_PRODUCT_CATEGORY,
          }).then(() => {
            const checkoutserviceUrl = config.selectors.getCheckoutserviceUrl(state);
            beforeUnloadListener.remove();
            redirectToUrl(urlJoin(checkoutserviceUrl, '/initiate-payment/?products=ecommerce'));
          });
          return;
        }

        const featureFlagsManager = featureFlagsService.get();
        const { freedomain: isFreeDomainEnabled } = await featureFlagsManager.request([
          'freedomain',
        ]);
        const activeSubscriptions = selectors.getActiveSubscriptions(state);
        const subscriptionsList = selectors.getSubscriptionList(state);
        const hasAvailableFreeDomain = isFreeDomainAvailable(
          activeSubscriptions,
          subscriptionsList,
          isFreeDomainEnabled
        );
        const upsells = getUpsellSettings({
          state,
          dispatch: store.dispatch,
          isFreeDomainAvailable: hasAvailableFreeDomain,
        });
        const hasEverHadDomainSubscription = subscriptionsList.some(({ type }) =>
          verifiers.isDomainPackageType(type)
        );

        const { currency } = user.selectors.getUserPreferences(state);
        const { id: userId } = user.selectors.getUserData(state);

        const availablePackages = selectors.getHostingPackages(state);
        const currentPlan = selectors.getCurrentPackageType(state);
        const minRequiredPlan = getMinRequiredHostingPlan(upgradeDetails, availablePackages);

        const onSelect = (paymentUrl, selectedPlan, selectedTerm) => {
          beforeUnloadListener.remove();

          const showDomainOnAnnulSubscriptionSelected =
            !isWL &&
            !details?.domainName &&
            selectedTerm === MAP_TERM_TO_READABLE_NAME.perYear &&
            !hasEverHadDomainSubscription;

          if (details?.domainSelector || showDomainOnAnnulSubscriptionSelected) {
            track(constants.events.PLAN_SELECTOR_PLAN_SELECT_BUTTON_CLICKED, {
              ...trackingData,
              productCategory: constants.common.EDITOR_PRODUCT_CATEGORY,
              selectedTerm,
              selectedPlan,
            });

            store.dispatch(
              dialogs.actions.show(dialogs.dialogTypes.B2C_DOMAIN_PURCHASE, {
                triggerId: triggerIds.ANNUAL_SUBSCRIPTION_SELECTED,
                subscription: { term: selectedTerm, plan: selectedPlan },
                captionsOverrides: {
                  closeButton: i18next.t('Back'),
                },
                ...details?.domainSelector,
                onClose: () => {
                  upsells.default
                    .onUpgrade({
                      domainSelector: details?.domainSelector,
                    })
                    .then(editorUpgradeAction.resolve, editorUpgradeAction.reject);
                },
              })
            );

            return;
          }

          store.dispatch(
            dialogs.actions.show(dialogs.dialogTypes.PENDING, {
              captions: {
                title: i18next.t('Loading...'),
              },
            })
          );

          const trackUpgradeInitiated = trackAsync(constants.events.UPGRADE_INITIATED, {
            ...trackingData,
            productCategory: constants.common.EDITOR_PRODUCT_CATEGORY,
          });

          const trackPlanSelected = trackAsync(
            constants.events.PLAN_SELECTOR_PLAN_SELECT_BUTTON_CLICKED,
            {
              ...trackingData,
              productCategory: constants.common.EDITOR_PRODUCT_CATEGORY,
              selectedTerm,
              selectedPlan,
            }
          );

          Promise.all([trackUpgradeInitiated, trackPlanSelected]).then(() => {
            editorUpgradeAction.reject();
            beforeUnloadListener.remove();
            redirectToUrl(paymentUrl);
          });
        };

        const onTermChange = (term) => {
          track(constants.events.PLAN_SELECTOR_TERM_TOGGLE_CLICKED, {
            ...trackingData,
            productCategory: constants.common.EDITOR_PRODUCT_CATEGORY,
            selectedTerm: MAP_TERM_TO_READABLE_NAME[term],
          });
        };

        const dialogProps = {
          meta: {
            upsellType,
            purchaseFlowType: getPurchaseFlowType(state),
          },
          onTermChange,
          currentPlan,
          currency,
          userId,
          minRequiredPlan,
          domainName: details?.domainName,
          defaultPaymentPeriod: details?.defaultPaymentPeriod,
          periodTogglerDisabled: details?.periodTogglerDisabled,
          periodTogglerHidden: details?.periodTogglerHidden,
          captions: getCaptionsForPaywall(upsellType, upsells, details?.forcedUpsell),
          onPeriodChange: (plan, paymentPeriod) => {
            track(constants.events.PLAN_SELECTOR_TERM_TOGGLE_CLICKED, {
              ...trackingData,
              productCategory: constants.common.EDITOR_PRODUCT_CATEGORY,
              selectedTerm: paymentPeriod,
            });
          },
          onScroll: (event) => {
            const {
              target: { scrollTop, scrollHeight, offsetHeight },
            } = event;

            if (scrollTop === scrollHeight - offsetHeight) {
              track(constants.events.PLAN_SELECTOR_SCROLLED_TO_BOTTOM, {
                ...trackingData,
                productCategory: constants.common.EDITOR_PRODUCT_CATEGORY,
              });
            }
          },
          onSelect,
          onTooltipHover: (id) => {
            track(constants.events.PLAN_SELECTOR_TOOLTIP_DISPLAYED, {
              ...trackingData,
              productCategory: constants.common.EDITOR_PRODUCT_CATEGORY,
              featureTooltip: id,
            });
          },
          onBackButtonClick: () => onCloseDialog(),
          onClose: () => onCloseDialog(),
        };

        store.dispatch(dialogs.actions.show(dialogs.dialogTypes.PAYWALL, dialogProps));

        next(action);
        return;
      }

      // WL upgrade
      const onConfirmPurchase = (confirmedProduct) => {
        const { sku } = confirmedProduct;

        trackUpgradeInApp(trackingData);

        store.dispatch(
          actions.orderSubscriptionPlan({
            productType,
            sku,
          })
        );
      };

      if (user.selectors.getPartnerSubmitUserActionUrl(state)) {
        const upgradeData = getUpgradeData(state, productType);

        if (upgradeData.type === upgradeTypes.REDIRECT) {
          trackUpgradeRedirect(trackingData).then(() => {
            redirectToUrl(upgradeData.destination);

            // if user cancel redirect
            store.dispatch(dialogs.actions.hide());
            editorUpgradeAction.reject();
            next(action);
          });
        } else if (upgradeData.type === upgradeTypes.IN_APP) {
          // start in-app purchase flow
          const { description, terms, options: products } = upgradeData;

          store.dispatch(
            dialogs.actions.show(dialogs.dialogTypes.IN_APP_PURCHASE, {
              description,
              terms,
              products,
              onCloseDialog,
              onConfirmPurchase,
            })
          );

          track(constants.events.PLAN_SELECTOR_DISPLAYED, {
            ...trackingData,
            productCategory: constants.common.EDITOR_PRODUCT_CATEGORY,
          });

          next(action);
        } else {
          editorUpgradeAction.reject();
          next(action);
        }
      } else {
        const path = user.selectors.getPartnerUpgradeUrl(state);

        if (path) {
          // redirect user to the `partner.properties.upgrade_url`
          const { id: userId } = user.selectors.getUserData(state);
          const { id: partnerId } = user.selectors.getPartnerData(state);

          let queryString = `?u=${userId}&p=${partnerId}`;

          if (upsellType === ONLINE_STORE) {
            queryString = `${queryString}&action=upgrade&product=ecwid`;
          }

          trackUpgradeRedirect(trackingData).then(() => {
            redirectToUrl(`${path}${queryString}`, { topWindow: true });

            // if user cancel redirect
            store.dispatch(dialogs.actions.hide());
            editorUpgradeAction.reject();
            next(action);
          });
        } else {
          // for local development
          editorUpgradeAction.resolve();
          next(action);
        }
      }

      break;
    }
    default:
      next(action);
  }
};

export default upgrade;
