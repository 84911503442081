import scrollToPosition from './scroll-to-position';
import getScrollOffset from './get-scroll-offset';
import getElementSideToScroll from './get-element-side-to-scroll';

const scrollToElement = (element, options = { includeOffset: true }) => {
  const { includeOffset, sideToScroll, offset = 0 } = options;
  const elementSideToScroll = sideToScroll || getElementSideToScroll(element);

  if (elementSideToScroll === null) {
    return Promise.resolve();
  }

  const newScrollPosition = getScrollOffset(element, elementSideToScroll, includeOffset);
  return scrollToPosition(newScrollPosition + offset);
};

export default scrollToElement;
