import { anodum } from '@yola/ws-sdk';
import selectionSimulation from '../constants/selection-simulation';

const unwrapSelectionImitation = (element) => {
  const { ownerDocument } = element;
  const elementsToUnwrap = [];
  const nodesToMerge = [];

  anodum.traverseNode(element, (node) => {
    if (
      !anodum.isElementNode(node) ||
      !node.parentNode ||
      !node.matches(`.${selectionSimulation.SELECTION_CLASSNAME}`)
    ) {
      return;
    }

    elementsToUnwrap.push(node);
  });

  elementsToUnwrap.forEach((node) => {
    const fragment = ownerDocument.createDocumentFragment();
    const childNodes = Array.from(node.childNodes);

    fragment.append(...childNodes);
    node.parentNode.replaceChild(fragment, node);

    childNodes.forEach((childNode) => {
      if (anodum.isTextNode(childNode)) {
        nodesToMerge.push(childNode);
      }
    });
  });

  nodesToMerge.forEach((node) => anodum.mergeSiblingTextNodes(node));
};

export default unwrapSelectionImitation;
