import constants from '../constants';

const { fieldNames } = constants;

const isTruthy = (value) => {
  if (Array.isArray(value)) {
    return value.some((arrayEl) => isTruthy(arrayEl));
  }

  if (typeof value === 'object' && value !== null) {
    return Object.values(value).some((objectVal) => isTruthy(objectVal));
  }

  return Boolean(value);
};

const getUpdatedFields = (fields) =>
  Object.keys(fields).filter((key) => {
    // for business category we want to check just value (as far as label and archetype we get from server and its always differ from defaulValue)
    if (key === fieldNames.BUSINESS_CATEGORY && typeof fields[key] === 'object')
      return isTruthy(fields[key].value);
    return isTruthy(fields[key]);
  });

export default getUpdatedFields;
