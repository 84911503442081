import React from 'react';
import { extensions, i18next } from '@yola/ws-sdk';
import ClearFormattingTrigger from '../components/clear-formatting-trigger';
import shouldShowClearTrigger from '../verifiers/should-show-clear-trigger';
import shouldShowTextFormattingTrigger from '../verifiers/should-show-text-formatting-trigger';

const { controlTypes } = extensions;
const triggerId = 'text-clear-formatting';

const clearControl = {
  id: triggerId,
  trigger: <ClearFormattingTrigger triggerId={triggerId} icon="type-clean" />,
  get tooltip() {
    return i18next.t('Clear');
  },
  priority: 60,
  for: [controlTypes.COLLAPSED_TEXT, controlTypes.TEXT],
  matches: (element) =>
    shouldShowClearTrigger(element) && !shouldShowTextFormattingTrigger(element),
  highlightElement: false,
};

export default clearControl;
