import attributesMap from '../constants/attributes-map';
import optionTypes from '../constants/option-types';

const getChangedAttributes = (values, initialValues) =>
  Object.keys(values)
    .map((name) => {
      switch (name) {
        case optionTypes.NAVIGATION:
        case optionTypes.PAGINATION:
          return values[name] !== initialValues[name]
            ? { name: attributesMap[name], value: initialValues[name] }
            : null;
        default:
          return null;
      }
    })
    .filter(Boolean);

export default getChangedAttributes;
