import React from 'react';
import { i18next } from '@yola/ws-sdk';
import ProgressDialog from '../components/progress-dialog';

function SiteResetProgressDialogContainer(props) {
  const captions = {
    title: `${i18next.t('Reset website')}...`,
    description: i18next.t('Your website will be reset soon'),
    cancel: i18next.t('Cancel'),
  };

  return <ProgressDialog captions={captions} cancelable={false} {...props} />;
}

export default SiteResetProgressDialogContainer;
