import { view } from '@yola/ws-sdk';
import constants from '../constants';

const { common } = constants;

const alignCenter = (containerSize, elementSize) => (containerSize - elementSize) / 2;

const getDialogPosition = (elementId, dialogWidth, dialogHeight) => {
  const targetElement = view.accessors.getLiveElement(elementId);
  const { defaultView } = targetElement.ownerDocument;

  const { right } = targetElement.getBoundingClientRect();
  const screenWidth = defaultView.innerWidth;
  const screenHeight = defaultView.innerHeight;

  let offsetX = right + common.DIALOG_POSITION_MARGIN - dialogWidth;

  if (offsetX < 0 || dialogWidth > screenWidth - common.DIALOG_POSITION_MARGIN) {
    offsetX = 0;
  }

  const offsetY = alignCenter(screenHeight, dialogHeight);

  return {
    offsetX,
    offsetY,
  };
};

export default getDialogPosition;
