import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import bowser from 'yola-bowser';
import {
  view,
  hdrm,
  extensions,
  dialogs,
  constraints,
  blocks,
  i18next,
  globals,
  template,
} from '@yola/ws-sdk';
import { Icon, Tooltip } from '@yola/ws-ui';
import customUI from 'src/js/modules/custom-ui';
import contextMenuDisplayedTracker from 'src/js/modules/context-menu/helpers/context-menu-displayed-tracker';
import withFeatureFlags from 'yola-editor/src/js/modules/feature-flags/hoc/with-feature-flags';
import highlighter from '../../highlighter';
import onlinestore from '../../onlinestore';
import contextMenu from '../../context-menu';
import dialogTypes from '../../dialogs/constants/dialog-types';
import ControlPane from '../components/control-pane';
import Trigger from '../../common/components/trigger';
import getPaneLocationForBlock from '../helpers/get-pane-location-for-block';
import getClosestBlockElement from '../../common/helpers/get-closest-block-element';
import { shouldControlDisplayInControlPane } from '../helpers/should-control-display-in';
import filterControlsByDisplayProperties from '../helpers/filter-controls-by-display-properties';
import filterControlsOfContextMenuGroupsByDisplayProperties from '../helpers/filter-controls-of-context-menu-groups-by-display-properties';
import calculateMenuPosition from '../helpers/calculate-context-menu-position';
import DragTriggerContainer from '../../drag-n-drop/containers/drag-trigger-container';
import isEmptySpaceHovered from '../../blocks/helpers/is-empty-space-hovered';
import getPaneMaxItems from '../helpers/get-pane-max-items';
import isElementOutOfViewport from '../helpers/is-element-out-of-viewport';
import getMoveBlockTriggers from '../helpers/get-move-block-triggers';
import TriggerEventTracker from '../../utils/trigger-event-tracker';
import BlockRegenerationContainer from '../../blocks/containers/block-regeneration-container';
import { NAVIGATION_GLOBAL_ID } from '../../navigation/constants/common';
import { MAX_PANE_ITEMS } from '../constants/pane-items';
import { PANE_TRIGGER_SIZE } from '../constants/sizes';
import {
  DRAG_TRIGGER_ID,
  DUPLICATE_TRIGGER_ID,
  DELETE_TRIGGER_ID,
  BLOCK_SETTINGS_TRIGGER_ID,
  HTML_WIDGET_TRIGGER_ID,
  ONLINE_STORE_SETTINGS_TRIGGER_ID,
  BLOCK_CONTROL_TRIGGER_ID,
} from '../constants/trigger-ids';

const { controlTypes } = extensions;

const { DISABLE_BLUR_ATTR } = hdrm.constants.attributes;

const MAX_BLOCK_PANE_ITEMS_FOR_MOBILE = 2;
const RE_RENDER_DELAY = 250;
const HIDE_PANE_DELAY = 50;
const CONTEXT_MENU_GROUP_NAME = 'context-menu';

class BlockControlPaneContainer extends React.Component {
  static prepareControlComponent(elementId, component) {
    if (typeof component.type === 'function') {
      return React.cloneElement(component, { elementId });
    }

    return component;
  }

  static prepareContextMenuGroups(items, element) {
    const contextMenuGroup = {
      name: CONTEXT_MENU_GROUP_NAME,
      element,
      matches: () => true,
      items: items.map(({ id, title, trigger, priority, onTriggerClick }) => ({
        id,
        caption: title,
        glyph: BlockControlPaneContainer.getGlyphFromTrigger(trigger),
        onClick: (e) => {
          contextMenu.operations.hideContextMenu();
          onTriggerClick(e);
        },
        order: priority * -1,
      })),
    };

    return items.length ? [contextMenuGroup] : [];
  }

  static getGlyphFromTrigger(TriggerComponent) {
    if (!TriggerComponent) return null;

    const { children: IconComponent } = TriggerComponent.props;

    if (!IconComponent) return null;

    const { glyph } = IconComponent.props;

    return glyph;
  }

  static prepareMoreItemsForMobile(items) {
    const settingTriggerIds = [
      BLOCK_SETTINGS_TRIGGER_ID,
      ONLINE_STORE_SETTINGS_TRIGGER_ID,
      HTML_WIDGET_TRIGGER_ID,
    ];

    if (bowser.mobile && items.length > MAX_BLOCK_PANE_ITEMS_FOR_MOBILE) {
      return items.reduce(
        (result, item) => {
          if (settingTriggerIds.includes(item.id)) {
            result.settingsItems.push(item);
          } else {
            result.moreTriggerItems.push(item);
          }

          return result;
        },
        { settingsItems: [], moreTriggerItems: [] }
      );
    }

    return { settingsItems: items, moreTriggerItems: [] };
  }

  constructor(props) {
    super(props);

    this.state = {
      showPane: false,
      offsetX: null,
      offsetY: null,
      direction: null,
      dropdown: null,
      expand: null,
      maxPaneWidth: null,
      targetElement: null,
      items: null,
      isDeleteTriggered: false,
      maxItems: MAX_PANE_ITEMS,
    };

    this.scrollPosition = null;
    this.prevHighlightedElements = [];
    this.viewportWidth = props.viewportWidth;

    this.attributes = {
      [DISABLE_BLUR_ATTR]: true,
    };

    this.isMobile = bowser.mobile;
    this.isTouchDevice = this.isMobile || bowser.tablet;
    this.controlPaneRef = React.createRef();
    this.blockControlRef = React.createRef();

    this.updateComponentState = this.updateComponentState.bind(this);
    this.prepareExtension = this.prepareExtension.bind(this);
    this.clearTimer = this.clearTimer.bind(this);
    this.updatePane = this.updatePane.bind(this);
    this.cancelPointerEvents = this.cancelPointerEvents.bind(this);
    this.restorePointerEvents = this.restorePointerEvents.bind(this);
    this.hideHighlighter = this.hideHighlighter.bind(this);
    this.showHighlighter = this.showHighlighter.bind(this);
    this.updateStateForElement = this.updateStateForElement.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onTriggerActionStart = this.onTriggerActionStart.bind(this);
  }

  componentDidUpdate() {
    if (this.shouldRerenderPane()) {
      this.updatePane();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { hoveredElementId, pageContainerSelector, isLoaded } = nextProps;

    // Why isLoaded is so important?(For better understanding you have to know how SyncyFrame component works)
    //
    // When editor reloads\changes page, we have some time(amount depends on your internet connection)
    // to iterate with elements on the page, which will be changed soon. And in that cases, if we
    // call any matches function from extensions, we will face the trouble that our element's document,
    // in some moment of time, will lose window context and editor will throw errors.
    // To prevent this behavior we added checking isLoaded status.
    if (!isLoaded) {
      return {
        targetElement: null,
        hoveredElementId: null,
      };
    }

    if (hoveredElementId !== prevState.hoveredElementId) {
      let targetElement;

      if (hoveredElementId) {
        const hoveredElement = view.accessors.getLiveElement(hoveredElementId);

        // workaround for opened nav menu on touch device
        if (
          (bowser.mobile || bowser.tablet) &&
          hoveredElement &&
          globals.verifiers.isElementGlobal(hoveredElementId, NAVIGATION_GLOBAL_ID)
        ) {
          return {
            targetElement: null,
            hoveredElementId: null,
          };
        }

        targetElement = getClosestBlockElement(hoveredElement);
      }

      if ((!hoveredElementId && !targetElement) || targetElement !== prevState.targetElement) {
        return {
          hoveredElementId,
          targetElement,
        };
      }

      if (isEmptySpaceHovered(hoveredElementId, pageContainerSelector)) {
        return {
          targetElement: null,
          hoveredElementId: null,
        };
      }
    }

    return null;
  }

  onMouseLeave() {
    this.oldTargetElement = this.currentTargetElement;

    this.setState({
      targetElement: this.currentTargetElement,
      hoveredElementId: null,
    });
  }

  onTriggerActionStart() {
    this.hideHighlighter();
  }

  getLiveDocument() {
    const targetDocument = view.accessors.getLiveDocument();
    if (!this.targetDocuments || this.targetDocuments[0] !== targetDocument) {
      this.targetDocuments = [targetDocument];
    }
    return this.targetDocuments;
  }

  getItemsForBlock(element) {
    const elementId = view.accessors.getLiveElementId(element);
    const { duplicateElement, deleteElement, redirectToStore } = this.props;

    let itemList = [];

    if (constraints.verifiers.canBeDragged(element)) {
      itemList.push({
        id: DRAG_TRIGGER_ID,
        element,
        trigger: <DragTriggerContainer elementId={elementId} />,
        tooltip: i18next.t('Move'),
        priority: 150,
        title: i18next.t('Move'),
        onTriggerClick: () => {},
        onHover: this.showHighlighter.bind(this, element),
        onMouseLeave: this.hideHighlighter.bind(this, element),
      });
    }

    if (
      constraints.verifiers.canBeCloned(element) &&
      !onlinestore.verifiers.isOnlineStoreBlock(element)
    ) {
      itemList.push({
        id: DUPLICATE_TRIGGER_ID,
        element,
        trigger: (
          <Trigger id={DUPLICATE_TRIGGER_ID}>
            <Icon size="20" glyph="copy" strokeWidth="1.3" />
          </Trigger>
        ),
        tooltip: i18next.t('Duplicate'),
        priority: 60,
        title: i18next.t('Duplicate'),
        onTriggerClick: () => {
          this.hideHighlighter();
          duplicateElement(elementId);
          this.hidePane();
        },
        onHover: this.showHighlighter.bind(this, element),
        onMouseLeave: this.hideHighlighter.bind(this, element),
      });
    }

    if (constraints.verifiers.canBeRemoved(element)) {
      itemList.push({
        id: DELETE_TRIGGER_ID,
        element,
        trigger: (
          <Trigger id={DELETE_TRIGGER_ID}>
            <Icon size="20" glyph="trash" strokeWidth="1.3" />
          </Trigger>
        ),
        tooltip: i18next.t('Delete'),
        priority: 40,
        title: i18next.t('Delete'),
        onTriggerClick: () => {
          deleteElement(elementId);
          this.hideHighlighter();
          this.setState({
            isDeleteTriggered: true,
          });
          this.hidePane();
        },
        onHover: this.showHighlighter.bind(this, element),
        onMouseLeave: this.hideHighlighter.bind(this, element),
      });
    }

    if (constraints.verifiers.canBeMoved(element)) {
      const moveBlockTriggers = getMoveBlockTriggers({
        element,
        showHighlighter: this.showHighlighter.bind(this, element),
        hideHighlighter: this.hideHighlighter.bind(this, element),
      });

      itemList.push(...moveBlockTriggers);
    }

    itemList = this.prepareExtension(controlTypes.ELEMENT, itemList, { element });

    const block = blocks.accessors.getBlockByElementId(elementId);

    if (block && block.availableSettings) {
      const { showBlockSettingsDialog } = this.props;

      itemList.push({
        id: BLOCK_SETTINGS_TRIGGER_ID,
        element,
        trigger: (
          <Trigger id={BLOCK_SETTINGS_TRIGGER_ID}>
            <Icon size="20" glyph="settings" strokeWidth="1.3" />
          </Trigger>
        ),
        tooltip: i18next.t('Block settings'),
        priority: 50,
        title: i18next.t('Block settings'),
        onTriggerClick: () => {
          showBlockSettingsDialog(elementId, block.id, element);
          this.hidePane();
        },
        onHover: this.showHighlighter.bind(this, element),
        onMouseLeave: this.hideHighlighter.bind(this, element),
      });
    }

    const isNewTemplate = template.verifiers.isMpt();

    if (
      !isNewTemplate &&
      onlinestore.verifiers.isEnabled() &&
      onlinestore.verifiers.isOnlineStoreBlock(element)
    ) {
      itemList.push({
        id: ONLINE_STORE_SETTINGS_TRIGGER_ID,
        element,
        trigger: (
          <Trigger id={ONLINE_STORE_SETTINGS_TRIGGER_ID}>
            <Icon size="20" glyph="cart" strokeWidth="1.3" />
          </Trigger>
        ),
        tooltip: i18next.t('Online Store Settings'),
        priority: 60,
        title: i18next.t('Online Store Settings'),
        onTriggerClick: redirectToStore,
        onHover: this.showHighlighter.bind(this, element),
        onMouseLeave: this.hideHighlighter.bind(this, element),
      });
    }
    return itemList;
  }

  getMatchedContextMenuGroups(targetElement) {
    const { registeredMenuGroups, featureFlags } = this.props;
    const { getMatchedMenuGroups, prepareMenuGroups } = contextMenu.helpers;
    return getMatchedMenuGroups(
      targetElement,
      prepareMenuGroups(registeredMenuGroups),
      featureFlags
    );
  }

  getContextMenuGroups(itemsList, targetElement) {
    const contextMenuGroups = this.getMatchedContextMenuGroups(targetElement);
    const { moreTriggerItems } = BlockControlPaneContainer.prepareMoreItemsForMobile(itemsList);
    const moreTriggerMenuGroups = BlockControlPaneContainer.prepareContextMenuGroups(
      moreTriggerItems,
      targetElement
    );

    return [...contextMenuGroups, ...moreTriggerMenuGroups];
  }

  getItemsForMoreTrigger(itemsList, targetElement, groups) {
    if (!groups.length) {
      return itemsList;
    }

    const { settingsItems } = BlockControlPaneContainer.prepareMoreItemsForMobile(itemsList);
    const contextMenuTrigger = {
      id: BLOCK_CONTROL_TRIGGER_ID,
      trigger: (
        <Trigger id={BLOCK_CONTROL_TRIGGER_ID} ref={this.blockControlRef}>
          <Icon size="20" glyph="more-horizontal" strokeWidth="1.3" />
        </Trigger>
      ),
      onTriggerClick: this.triggerDefinedContextMenu.bind(this, groups),
      onMouseDown: (e) => e.stopPropagation(),
      tooltip: `${i18next.t('More')}...`,
      priority: 1,
      title: `${i18next.t('More')}...`,
      element: targetElement,
    };

    return [...settingsItems, contextMenuTrigger];
  }

  updatePane() {
    const { highlightedElements } = this.props;
    const { targetElement, isDeleteTriggered } = this.state;

    this.oldTargetElement = targetElement;
    this.oldPreviousElementSibling = targetElement?.previousElementSibling;
    this.oldNextElementSibling = targetElement?.nextElementSibling;

    this.prevHighlightedElements = highlightedElements;

    if (this.shouldUpdateWithoutDelay) {
      this.updateComponentState();
      this.shouldUpdateWithoutDelay = false;
      return;
    }

    this.clearTimer();

    const delay = isDeleteTriggered ? HIDE_PANE_DELAY : RE_RENDER_DELAY;

    this.timer = setTimeout(() => {
      /**
       * `currentTargetElement` is needed to fix wrong behavior
       * described in https://github.com/yola/ws-editor/issues/374
       *
       * We need to store an element which control pane is bound to.
       * We could use `targetElement` but it changes it’s value while user is
       * moving cursor from the target element to control pane so the original
       * value becomes lost.
       */
      contextMenu.operations.hideContextMenu();
      this.currentTargetElement = targetElement;
      this.hidePane(this.updateComponentState);
      this.shouldSkipPositionUpdate = false;
    }, delay);
  }

  /**
   * ControlPane should be re-render in next cases:
   *   - when closest block for hovered element is changed
   *   - when page was scrolled
   */
  shouldRerenderPane() {
    const { targetElement, items } = this.state;
    const {
      scrollPosition,
      isScrolling,
      isDialogVisible,
      customUIVisibility,
      isDisabledStatus,
      viewportWidth,
    } = this.props;

    if (
      targetElement?.previousElementSibling !== this.oldPreviousElementSibling ||
      targetElement?.nextElementSibling !== this.oldNextElementSibling
    ) {
      return true;
    }

    if (isDisabledStatus) return false;

    if (isScrolling && scrollPosition !== this.scrollPosition) {
      this.shouldUpdateWithoutDelay = true;
      this.scrollPosition = scrollPosition;
      return true;
    }

    if (viewportWidth && viewportWidth !== this.viewportWidth) {
      this.shouldUpdateWithoutDelay = true;
      this.viewportWidth = viewportWidth;
      return true;
    }

    if (isDialogVisible || customUIVisibility) {
      if (items && items.length) {
        this.hidePane();
      }
      this.oldTargetElement = null;
      return false;
    }

    const elementWasChanged = this.oldTargetElement !== targetElement;

    if (elementWasChanged) {
      return true;
    }

    return false;
  }

  prepareExtension(controlType, itemsList, item) {
    const { element } = item;
    const elementId = view.accessors.getLiveElementId(element);
    const controlsList = extensions.filters.findControlsFor(element, controlType);

    controlsList.forEach(({ header, ...controlConfig }) => {
      const controlAlreadyExist = itemsList.some(
        (controlItem) => controlItem.id === controlConfig.id
      );
      if (controlAlreadyExist) return;

      const controlItem = { ...controlConfig, element };

      // 'highlightElement' property is optional in controls. By default it should be "true".
      if (controlItem.highlightElement === undefined) {
        controlItem.highlightElement = true;
      }

      controlItem.trigger = BlockControlPaneContainer.prepareControlComponent(
        elementId,
        controlItem.trigger
      );

      if (header) {
        const headerItems = typeof header === 'function' ? header(elementId) : header;

        controlItem.header = headerItems.map(
          BlockControlPaneContainer.prepareControlComponent.bind(null, elementId)
        );

        controlItem.activeWidth =
          controlItem.activeWidth || (headerItems.length + 1) * PANE_TRIGGER_SIZE;
      }

      if (controlItem.body) {
        controlItem.body = controlItem.body.map(
          BlockControlPaneContainer.prepareControlComponent(null, elementId)
        );
      }

      if (controlItem.onTriggerClick) {
        // Following logic of trigger handler extension should be replaced after the implementation of image gallery extension
        // Ticket: https://github.com/yola/ws-editor/issues/297
        if (controlItem.trigger.props.name === 'file-trigger') {
          const trigger = controlItem.onTriggerClick;
          controlItem.onTriggerClick = () => {
            this.cancelPointerEvents();
            trigger.call(controlItem, elementId);
          };
        } else {
          controlItem.onTriggerClick = controlItem.onTriggerClick.bind(controlItem, elementId);
        }
      }
      if (controlItem.highlightElement) {
        if (typeof controlItem.highlightElement === 'function') {
          const elementToHighlight = controlItem.highlightElement(element);
          controlItem.onHover = this.showHighlighter.bind(this, elementToHighlight || element);
          controlItem.onMouseLeave = this.hideHighlighter.bind(this, elementToHighlight || element);
        } else {
          controlItem.onHover = this.showHighlighter.bind(this, element);
          controlItem.onMouseLeave = this.hideHighlighter.bind(this, element);
        }
      }
      itemsList.push(controlItem);
    });

    return itemsList;
  }

  clearTimer() {
    clearTimeout(this.timer);
  }

  hidePane(cb) {
    this.setState(
      {
        showPane: false,
        offsetX: null,
        offsetY: null,
        direction: null,
        dropdown: null,
        expand: null,
        items: null,
        maxPaneWidth: null,
      },
      cb
    );
    Tooltip.hide();
  }

  updateComponentState() {
    const { targetElement } = this.state;
    const liveDocument = view.accessors.getLiveDocument();

    if ((liveDocument && !liveDocument.defaultView) || !targetElement) return;
    this.updateStateForElement();
  }

  async updateStateForElement() {
    const { showPane, targetElement } = this.state;
    const { scrollPosition, viewportHeight, viewportWidth } = this.props;

    if (!targetElement) {
      if (showPane) this.hidePane();
      return;
    }

    let items = this.getItemsForBlock(targetElement);
    let contextMenuGroups = this.getContextMenuGroups(items, targetElement);

    items = filterControlsByDisplayProperties(items, shouldControlDisplayInControlPane);
    contextMenuGroups = filterControlsOfContextMenuGroupsByDisplayProperties(contextMenuGroups);

    if (!items.length) {
      if (showPane) this.hidePane();
      return;
    }

    if (
      !targetElement ||
      !targetElement.ownerDocument ||
      !targetElement.ownerDocument.defaultView
    ) {
      return;
    }

    if (isElementOutOfViewport(targetElement, viewportHeight, scrollPosition)) {
      if (showPane) this.hidePane();
      return;
    }

    items = this.getItemsForMoreTrigger(items, targetElement, contextMenuGroups);

    const { offsetX, offsetY, direction, dropdown, expand, maxPaneWidth } = getPaneLocationForBlock(
      {
        element: targetElement,
        items,
        scrollPosition,
        viewportWidth,
      }
    );

    const maxItems = getPaneMaxItems({ offsetX, direction }, viewportWidth, items);

    this.setState({
      offsetX,
      offsetY,
      direction,
      dropdown,
      expand,
      items,
      showPane: true,
      isDeleteTriggered: false,
      maxItems,
      maxPaneWidth,
    });
  }

  triggerDefinedContextMenu(contextMenuGroups) {
    const { offsetY, targetElement, direction } = this.state;
    const { showContextMenu, menu } = this.props;

    if (menu.triggerId === BLOCK_CONTROL_TRIGGER_ID) {
      contextMenu.operations.hideContextMenu();
      return;
    }

    const { triggerRef } = this.blockControlRef.current;
    const { left } = triggerRef.current.getBoundingClientRect();
    const position = { x: left, y: offsetY };

    const calculatePositionDecorator = (menuElement, x, y) =>
      calculateMenuPosition({
        menuElement,
        controlPanePosition: {
          x,
          y,
        },
        direction,
      });

    showContextMenu({
      position,
      groups: contextMenuGroups,
      triggerId: BLOCK_CONTROL_TRIGGER_ID,
      calculatePosition: calculatePositionDecorator,
    });
    contextMenuDisplayedTracker({ triggerId: BLOCK_CONTROL_TRIGGER_ID, targetElement });
  }

  cancelPointerEvents() {
    const { setDisabledStatus } = this.props;
    setDisabledStatus(true);

    document.defaultView.addEventListener('mousedown', this.restorePointerEvents);
    document.defaultView.addEventListener('focus', this.restorePointerEvents);
  }

  restorePointerEvents() {
    const { setDisabledStatus } = this.props;
    setDisabledStatus(false);

    document.defaultView.removeEventListener('mousedown', this.restorePointerEvents);
    document.defaultView.removeEventListener('focus', this.restorePointerEvents);
  }

  hideHighlighter(element) {
    const { hideHighlighter } = this.props;

    if (!this.isTouchDevice) {
      if (!element) {
        hideHighlighter();
        return;
      }

      const wasElementHighlighterBefore = this.prevHighlightedElements.some(
        ([prevElement]) => prevElement === element
      );

      if (!wasElementHighlighterBefore) {
        hideHighlighter([element]);
      }
    }
  }

  showHighlighter(element) {
    const { showHighlighter, hideHoveredHighlighter } = this.props;

    if (!this.isTouchDevice) {
      hideHoveredHighlighter();
      showHighlighter([element]);
    }
  }

  render() {
    const {
      offsetX,
      offsetY,
      direction,
      dropdown,
      expand,
      items,
      showPane,
      maxItems,
      maxPaneWidth,
      targetElement,
    } = this.state;
    const {
      isLoaded,
      isMPT,
      isDialogVisible,
      customUIVisibility,
      viewportWidth,
      selectedElementId,
      focusedElementId,
      viewportHeight,
    } = this.props;

    if (
      !isLoaded ||
      isDialogVisible ||
      customUIVisibility ||
      selectedElementId ||
      focusedElementId ||
      !targetElement ||
      !items ||
      !showPane
    )
      return null;

    return (
      <div onMouseEnter={this.clearTimer}>
        {isMPT && (
          <BlockRegenerationContainer
            element={targetElement}
            offsetX={offsetX}
            offsetY={offsetY}
            viewportHeight={viewportHeight}
          />
        )}
        <TriggerEventTracker beforeTriggerActionStart={this.onTriggerActionStart}>
          <ControlPane
            controlPaneRef={this.controlPaneRef}
            offsetX={offsetX}
            offsetY={offsetY}
            content={items}
            documents={this.getLiveDocument()}
            dropdown={dropdown}
            expand={expand}
            direction={direction}
            maxItems={maxItems}
            onMouseLeave={this.onMouseLeave}
            attributes={this.attributes}
            viewportWidth={viewportWidth}
            maxWidth={maxPaneWidth}
            disableCentering
          />
        </TriggerEventTracker>
      </div>
    );
  }
}

BlockControlPaneContainer.propTypes = {
  // eslint-disable-next-line yola/react/no-unused-prop-types
  hoveredElementId: PropTypes.string,
  selectedElementId: PropTypes.string,
  focusedElementId: PropTypes.string,
  featureFlags: PropTypes.shape({}).isRequired,
  duplicateElement: PropTypes.func.isRequired,
  deleteElement: PropTypes.func.isRequired,
  setDisabledStatus: PropTypes.func.isRequired,
  showHighlighter: PropTypes.func.isRequired,
  hideHighlighter: PropTypes.func.isRequired,
  hideHoveredHighlighter: PropTypes.func.isRequired,
  showBlockSettingsDialog: PropTypes.func.isRequired,
  redirectToStore: PropTypes.func.isRequired,
  isDialogVisible: PropTypes.bool,
  customUIVisibility: PropTypes.bool,
  scrollPosition: PropTypes.number.isRequired,
  highlightedElements: PropTypes.array,
  isScrolling: PropTypes.bool,
  isDisabledStatus: PropTypes.bool,
  viewportWidth: PropTypes.number,
  viewportHeight: PropTypes.number,
  isLoaded: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  menu: PropTypes.shape({
    triggerId: PropTypes.string,
  }).isRequired,
  showContextMenu: PropTypes.func.isRequired,
  registeredMenuGroups: PropTypes.array.isRequired,
  isMPT: PropTypes.bool.isRequired,
};

BlockControlPaneContainer.defaultProps = {
  hoveredElementId: null,
  selectedElementId: null,
  focusedElementId: null,
  highlightedElements: [],
  isDialogVisible: false,
  customUIVisibility: false,
  isScrolling: false,
  isDisabledStatus: false,
  viewportWidth: window.innerWidth,
  viewportHeight: window.innerHeight,
  isLoaded: false,
};

const mapStateToProps = (state) => ({
  highlightedElements: highlighter.selectors.getHighlightedElements(state),
  hoveredElementId: view.selectors.getHoveredElement(state),
  selectedElementId: view.selectors.getSelectedElement(state),
  focusedElementId: view.selectors.getFocusedElement(state),
  isDialogVisible: dialogs.verifiers.isVisible(state),
  customUIVisibility: customUI.selectors.getVisibilityState(state),
  scrollPosition: view.selectors.getScrollPosition(state),
  isScrolling: view.selectors.getScrollStatus(state),
  isDisabledStatus: view.selectors.getDisabledStatus(state),
  pageContainerSelector: template.selectors.getPageContainerSelector(state),
  isLoaded: view.selectors.getLoadedStatus(state),
  registeredMenuGroups: contextMenu.selectors.getRegisteredGroups(state),
  menu: contextMenu.selectors.getMenu(state),
  isMPT: template.verifiers.isMpt(state),
});

const mapDispatchToProps = (dispatch) => ({
  showContextMenu: (position, groups, triggerId, calculatePosition) => {
    dispatch(contextMenu.actions.showContextMenu(position, groups, triggerId, calculatePosition));
  },
  duplicateElement: (elementId) => {
    dispatch(view.actions.duplicateElement(elementId));
  },
  deleteElement: (elementId) => {
    dispatch(view.actions.deleteElement(elementId));
  },
  setDisabledStatus: (value) => {
    dispatch(view.actions.setDisabledStatus(value));
  },
  showHighlighter: (elements) => {
    dispatch(highlighter.actions.show(elements));
  },
  hideHighlighter: (elements) => {
    dispatch(highlighter.actions.hide(elements));
  },
  hideHoveredHighlighter: (elements) => {
    dispatch(highlighter.actions.hideHovered(elements));
  },
  showBlockSettingsDialog: (elementId, blockId, element) => {
    dispatch(dialogs.actions.show(dialogTypes.BLOCK_SETTINGS, { elementId, blockId, element }));
  },
  redirectToStore() {
    dispatch(onlinestore.actions.redirectToStore());
  },
});

export default withFeatureFlags(['image_copy_paste'])(
  connect(mapStateToProps, mapDispatchToProps)(BlockControlPaneContainer)
);
