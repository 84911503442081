import { view, i18next, site } from '@yola/ws-sdk';
import config from 'src/js/modules/config';
import constants from '../constants';
import verifiers from '../verifiers';

const { attributes } = constants;

const setDefaultAttributes = (liveDocument) => {
  const elements = liveDocument.querySelectorAll('ws-contact-form-container');
  const businessEmails = site.accessors.getUserEmails() || [];
  const isBuilderEnabled = verifiers.isBuilderEnabled();

  const defaultValues = [
    {
      value: config.accessors.getContactFormSubmissionUrl(),
      key: attributes.ACTION,
    },
    {
      value: businessEmails.join(', '),
      key: attributes.EMAILS,
    },
    isBuilderEnabled
      ? {
          value: config.accessors.getHcaptchaKey(),
          key: attributes.HCAPTCHA_KEY,
        }
      : {
          value: config.accessors.getRecaptchaKey(),
          key: attributes.RECAPTCHA_KEY,
        },
    {
      value: i18next.t('Your message was successfully sent.'),
      key: attributes.SUCCESS_MESSAGE,
    },
  ];

  if (elements.length > 0) {
    defaultValues.forEach((defaultValue) => {
      const { key, value } = defaultValue;
      elements.forEach((element) => {
        if (element.hasAttribute(attributes.SUCCESS_MESSAGE)) return;
        const elementId = view.accessors.getLiveElementId(element);
        view.operations.setElementAttributeAndDontAffectHistory(elementId, key, value);
      });
    });
  }

  return liveDocument;
};

export default setDefaultAttributes;
