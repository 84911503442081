import header from './header';
import textBlock from './text-block';
import whitespaces from './whitespaces';
import overlay from './overlay';

const initializeHandlers = () => {
  header.init();
  textBlock.init();
  whitespaces.init();
  overlay.init();
};

export default initializeHandlers;
