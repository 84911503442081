import { assets } from '@yola/ws-sdk';

const getDerivedUploadedImages = (images) =>
  images.map((image) => ({
    assetSource:
      image.status === assets.constants.STATUSES.COMPLETE ? image.detail.assetSource : null,
    externalSource: image.url,
  }));

export default getDerivedUploadedImages;
