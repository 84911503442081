import { customization } from '@yola/ws-sdk';
import constants from '../constant';
import websiteAnimationScriptContent from '../constant/website-animation-script-content';

const { GLOBAL_HEADER_CODE, GLOBAL_FOOTER_CODE } = constants;
const { HEADER_CODE_SCRIPT_CONTENT, FOOTER_CODE_SCRIPT_CONTENT } = websiteAnimationScriptContent;

const createWebsiteAnimationScript = (globalName) => {
  const script = document.createElement('script');

  if (globalName === GLOBAL_HEADER_CODE) {
    script.setAttribute('id', 'ws-website-animation-script-header-code');
    script.textContent = HEADER_CODE_SCRIPT_CONTENT;
  }

  if (globalName === GLOBAL_FOOTER_CODE) {
    script.setAttribute('id', 'ws-website-animation-script-footer-code');
    script.textContent = FOOTER_CODE_SCRIPT_CONTENT;
  }

  script.setAttribute(customization.constants.WEBSITE_ANIMATION_DATA_ATTRIBUTE, '');

  return script;
};

export default createWebsiteAnimationScript;
