import segment from 'src/js/modules/analytics/segment';

const {
  trackers: { trackEvent },
  constants: { events },
} = segment;

const trackHelpMenuItemClick = (itemId, triggerId) =>
  trackEvent(events.APP_HELP_MENU_ITEM_CLICKED, {
    itemId,
    triggerId,
  });

export default trackHelpMenuItemClick;
