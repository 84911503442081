import { config } from '@yola/ws-sdk';
import axios from 'axios';
import errorModule from 'yola-editor/src/js/modules/error';

const fetchConfig = () => async (dispatch) => {
  try {
    const response = await axios('/config.json');
    dispatch(config.actions.setServiceConfig(response.data));
  } catch (error) {
    console.error('Failed to load config: ', error);

    dispatch(
      errorModule.actions.setCriticalError({
        error,
        logSentry: true,
      })
    );
  }
};

export default fetchConfig;
