import bowser from 'yola-bowser';
import { PANE_ARROW_INDENT, PANE_DEFAULT_INDENT, PANE_SIZE_WITH_LINING } from '../constants/sizes';
import { TEXT_SELECTION_MAX_PANE_ITEMS } from '../constants/pane-items';
import getMaxPaneSize from './get-max-pane-size';
import getDocumentSizes from './get-document-sizes';

const getStyleWithoutUnit = (value) => parseFloat(value).toFixed(4);
const NATIVE_IOS_PANE_HEIGHT = 35;

const getPaneLocationForTextSelection = ({ element, items, scrollPosition }) => {
  const { windowInnerWidth, windowInnerHeight } = getDocumentSizes();
  const { maxPaneWidth, maxPaneHeight } = getMaxPaneSize(
    items,
    windowInnerWidth,
    TEXT_SELECTION_MAX_PANE_ITEMS,
    windowInnerWidth
  );

  const elementRect = element.getBoundingClientRect();

  const { top, left, width, bottom } = elementRect;

  const styles = element.ownerDocument.defaultView.getComputedStyle(element);
  const fontSize = getStyleWithoutUnit(styles.fontSize);
  const lineHeight =
    styles.lineHeight === 'normal' ? fontSize * 1.14 : getStyleWithoutUnit(styles.lineHeight);
  const textLineHeightIndent = (lineHeight - fontSize) / 2;
  const isIOS = bowser.ios;
  const halfPaneWidth = maxPaneWidth / 2;
  const rangeTop = isIOS ? top : top + scrollPosition;
  const rangeBottom = isIOS ? bottom : bottom + scrollPosition;

  let offsetY;
  let offsetX;
  let direction = null;
  let expand;

  offsetX = Number((left + width / 2).toFixed(1));

  let arrowOffsetX = maxPaneWidth / 2;

  if (rangeTop - PANE_SIZE_WITH_LINING > 0) {
    direction = 'up';
    offsetY = rangeTop - textLineHeightIndent - PANE_ARROW_INDENT - PANE_DEFAULT_INDENT;
  }

  if (
    rangeTop - PANE_SIZE_WITH_LINING > 0 &&
    rangeTop - scrollPosition - PANE_SIZE_WITH_LINING - PANE_DEFAULT_INDENT < 0
  ) {
    direction = 'none';
    offsetY = scrollPosition + PANE_DEFAULT_INDENT;
  }

  if (direction === null && rangeTop - PANE_SIZE_WITH_LINING < 0) {
    direction = 'down';
    offsetY = rangeBottom + textLineHeightIndent + PANE_ARROW_INDENT + PANE_DEFAULT_INDENT;
  }

  if (rangeBottom - scrollPosition < 0 || rangeTop - scrollPosition > windowInnerHeight) {
    direction = null;
  }

  if (offsetX < halfPaneWidth && offsetX + halfPaneWidth < windowInnerWidth) {
    if (maxPaneWidth === windowInnerWidth || maxPaneWidth + offsetX > windowInnerWidth) {
      offsetX = windowInnerWidth / 2;
      arrowOffsetX = null;
    } else {
      offsetX = left + halfPaneWidth;
      arrowOffsetX = width / 2;
    }
  } else if (offsetX + halfPaneWidth > windowInnerWidth && offsetX - halfPaneWidth > 0) {
    offsetX = left + width - halfPaneWidth;
    arrowOffsetX = maxPaneWidth - width / 2;
  }

  if (offsetY - maxPaneHeight < 0) {
    expand = 'down';
  } else {
    expand = 'up';
  }

  // Checking case, when native IOS pane overlaps control-pane below the element. We will hide control pane.
  if (isIOS && direction === 'down' && rangeTop - scrollPosition < NATIVE_IOS_PANE_HEIGHT) {
    direction = null;
  }

  return {
    offsetX,
    offsetY,
    direction,
    expand,
    maxPaneWidth,
    arrowOffsetX,
  };
};

export default getPaneLocationForTextSelection;
