import attributes from './attributes';
import common from './common';
import timeFormats from './time-formats';
import encryptedMap from './encrypted-map';
import latinizedMap from './latinized-map';
import blackList from './black-list';
import tabIdentifiers from './tab-identifiers';
import numberOfFilesAttachment from './number-of-files-attachment';

export default {
  attributes,
  common,
  timeFormats,
  encryptedMap,
  latinizedMap,
  blackList,
  tabIdentifiers,
  numberOfFilesAttachment,
};
