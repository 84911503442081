import isSocialLinkUrlValid from 'src/js/modules/extensions/registry/ws-social-links/helpers/is-social-link-url-valid';

const createSocialLinkUrlValidator = (validationError) => (value) => {
  if (value.trim() && !isSocialLinkUrlValid(value)) {
    return validationError;
  }

  return null;
};

export default createSocialLinkUrlValidator;
