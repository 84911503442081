import { serviceClient } from '@yola/ws-sdk';
import pollAsyncJob from '../../utils/poll-async-job';

const POLLING_INTERVAL = 500;

const initiateTextGeneration = async (params, signal = {}) => {
  const client = serviceClient.get();
  const response = await client.initiateTextGeneration(params, { signal });
  const {
    data: { id: jobId },
  } = response;

  return pollAsyncJob(jobId, POLLING_INTERVAL, signal);
};

export default initiateTextGeneration;
