export default {
  SEND_VERIFICATION_EMAIL: '@YOLA_EDITOR/SEND_VERIFICATION_EMAIL',
  SET_USER_DATA: '@YOLA_EDITOR/SET_USER_DATA',
  UPDATE_USER_DATA: '@YOLA_EDITOR/UPDATE_USER_DATA',
  SET_USER_PREFERENCES: '@YOLA_EDITOR/SET_USER_PREFERENCES',
  FETCH_USER_PREFERENCES: '@YOLA_EDITOR/FETCH_USER_PREFERENCES',
  FETCH_USER_PARTNER_DATA: '@YOLA_EDITOR/FETCH_USER_PARTNER_DATA',
  SET_USER_PARTNER_DATA: '@YOLA_EDITOR/SET_USER_PARTNER_DATA',
  UPDATE_USER_PREFERENCE: '@YOLA_EDITOR/UPDATE_USER_PREFERENCE',
  IDENTIFY_USER_WITH_PARTNER_DATA: '@YOLA_EDITOR/IDENTIFY_USER_WITH_PARTNER_DATA',
  SET_USER_FEATURES: '@YOLA_EDITOR/SET_USER_FEATURES',
  FETCH_USER_FEATURES: '@YOLA_EDITOR/FETCH_USER_FEATURES',
};
