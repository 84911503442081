import { site, template } from '@yola/ws-sdk';
import segment from 'src/js/modules/analytics/segment';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import { snakeCaseToCamelCase, dashedToCamelCase } from 'src/js/modules/utils/convert-case';

const trackBlockSettingsOptionClicked = ({ blockId, blockVariationId, blockSettingId }) => {
  if (!blockId || !blockSettingId) return;

  const { track, constants } = segment;
  const settingId = dashedToCamelCase(snakeCaseToCamelCase(blockSettingId));

  track(constants.events.BLOCK_SETTINGS_OPTION_CLICKED, {
    siteId: site.accessors.getSiteId(),
    templateBuildSlug: template.accessors.getBuildSlug(),
    dialogId: dialogTypes.BLOCK_SETTINGS,
    blockId,
    blockVariationId,
    blockSettingId: settingId,
  });
};

export default trackBlockSettingsOptionClicked;
