import bowser from 'yola-bowser';

const isSupportedColorOptions = () =>
  !bowser.isSatisfied({
    mobile: {
      safari: '<13',
    },
    tablet: {
      safari: '<13',
    },
  });

export default isSupportedColorOptions;
