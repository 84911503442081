import { textFormatting, anodum } from '@yola/ws-sdk';
import verifiers from '../verifiers';

const { isLinkSelected } = verifiers;
const { wrappersTypes } = textFormatting.constants;
const { tagName, className } = wrappersTypes.link;

const getFirstLinkElementFromSelection = () => {
  const selection = textFormatting.accessors.getAdvancedSelection();
  const { anchorNode } = selection;

  if (isLinkSelected()) {
    const startNode = anodum.isTextNode(anchorNode) ? anchorNode.parentElement : anchorNode;
    return startNode ? startNode.closest(`${tagName}.${className}`) : null;
  }

  if (selection.nativeSelection.type === 'Range') {
    const range = selection.getRangeAt(0);
    const innerLinks = range.getNodes([Node.ELEMENT_NODE], (node) => anodum.isTag(node, tagName));

    return innerLinks.length ? innerLinks[0] : null;
  }

  return null;
};

export default getFirstLinkElementFromSelection;
