import actionTypes from '../constants/action-types';

const setPublishedDataLoadedStatus = (status) => ({
  type: actionTypes.SET_PUBLISHED_DATA_LOADED_STATUS,
  payload: {
    status,
  },
});

export default setPublishedDataLoadedStatus;
