import { transactions } from '@yola/ws-sdk';
import adjacentPositions from 'src/js/modules/blocks/constants/adjacent-positions';
import getElementById from '../../helpers/get-element-by-id';
import getWhitespaceAutomationInstructions from './helpers/get-whitespace-automation-instructions';

const getPastedBlock = (payload, isStatic) => {
  const { refElementId, position } = payload;
  const targetBlock = getElementById(refElementId, isStatic);

  if (!targetBlock) {
    return null;
  }

  if (position === adjacentPositions.AFTER_END) {
    return targetBlock.nextElementSibling;
  }

  return targetBlock.previousElementSibling;
};

const blockIsPasted = (config, payload, transaction) => {
  const { context } = transaction;
  const isStatic = context !== transactions.transactionTypes.LIVE_DOCUMENT;
  const pastedBlock = getPastedBlock(payload, isStatic);

  if (!pastedBlock) {
    return [];
  }

  return [
    ...getWhitespaceAutomationInstructions({
      prevSiblingNode: pastedBlock.previousElementSibling,
      targetNode: pastedBlock,
      isStatic,
    }),
    ...getWhitespaceAutomationInstructions({
      prevSiblingNode: pastedBlock,
      targetNode: pastedBlock.nextElementSibling,
      isStatic,
    }),
  ];
};

export default blockIsPasted;
