import { extensions } from '@yola/ws-sdk';
import setDefaultAttributes from './set-default-attributes';
import constants from '../constants/common';

const beforeViewLoad = (staticDOM) => {
  const settings = extensions.accessors.getExtensionSettings(constants.slug);

  if (!staticDOM || !staticDOM.querySelector(settings.querySelector)) {
    return undefined;
  }

  return setDefaultAttributes(staticDOM);
};

export default beforeViewLoad;
