import React from 'react';
import { extensions, contentEditable, i18next } from '@yola/ws-sdk';
import ListFormattingTrigger from '../components/list-formatting-trigger';
import constants from '../constants';
import toggleList from '../helpers/toggle-list';
import isListAllowed from '../verifiers/is-list-allowed';

const { controlTypes } = extensions;
const { LIST_TYPE_BULLET_TAG } = constants.listFormatting;
const triggerId = 'text-bullet-list';

const listBulletControl = {
  id: triggerId,
  trigger: (
    <ListFormattingTrigger
      triggerId={triggerId}
      listType={LIST_TYPE_BULLET_TAG}
      icon="unordered-list"
    />
  ),
  get tooltip() {
    return i18next.t('Unordered list');
  },
  priority: 60,
  onTriggerClick: (elementId) => {
    contentEditable.operations.addCheckpoint();
    toggleList(LIST_TYPE_BULLET_TAG, elementId);
  },
  for: [controlTypes.TEXT, controlTypes.COLLAPSED_TEXT],

  matches: (element) => isListAllowed(element, LIST_TYPE_BULLET_TAG),
  highlightElement: false,
};

export default listBulletControl;
