import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@yola/ws-ui';
import Button from 'yola-editor/src/js/components/button';

function ShareButton({ className, glyph, glyphSize, label, shareUrl, onClick }) {
  let buttonProps = {
    className: `ws-share-button ${className}`,
    size: 'large',
    onClick,
  };

  if (shareUrl) {
    buttonProps = {
      ...buttonProps,
      target: 'blank',
      href: shareUrl,
    };
  }

  return (
    <Button {...buttonProps}>
      <div className="ws-share-button__icon-box">
        <Icon glyph={glyph} size={glyphSize} strokeWidth="1.3" />
      </div>
      <div className="ws-share-button__label">{label}</div>
    </Button>
  );
}

ShareButton.propTypes = {
  className: PropTypes.string,
  glyph: PropTypes.string.isRequired,
  glyphSize: PropTypes.number,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  shareUrl: PropTypes.string,
};

ShareButton.defaultProps = {
  className: '',
  shareUrl: null,
  glyphSize: 24,
};

export default ShareButton;
