import { textFormatting, anodum } from '@yola/ws-sdk';
import constants from '../constants';

const { SUPPORTED_TAGS, LIST_ITEM_TAG } = constants.listFormatting;
const { isTextNode, isNonEmptyTextNode, isElementNode } = anodum;

const getSelectedElement = () => {
  const selection = textFormatting.accessors.getAdvancedSelection();
  const supportedTags = [...SUPPORTED_TAGS, LIST_ITEM_TAG].join(', ').toLowerCase();
  const { anchorNode } = selection;

  if (isTextNode(anchorNode) && isNonEmptyTextNode(anchorNode)) {
    return anchorNode.parentElement.closest(supportedTags);
  }

  if (isElementNode(anchorNode)) {
    return anchorNode.closest(supportedTags);
  }

  return null;
};

export default getSelectedElement;
