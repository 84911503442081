import constants from '../constants';
import trackEvent from './track-event';

const { events } = constants;

const trackCodeEditorFocused = (dialogId) => {
  trackEvent(events.CODE_EDITOR_FOCUSED, {
    dialogId,
  });
};

export default trackCodeEditorFocused;
