import { customization } from '@yola/ws-sdk';
import createWebsiteAnimationScript from './create-website-animation-script';

const { constants } = customization;

const applyEffects = (global, activeEffects, globalCode) => {
  const node = global.cloneNode(true);

  // Website Animation
  const isWebsiteAnimationEnabled = Boolean(activeEffects.websiteAnimation);
  const websiteAnimationScriptElement = node.querySelector(
    `[${constants.WEBSITE_ANIMATION_DATA_ATTRIBUTE}]`
  );
  const websiteAnimationScript = createWebsiteAnimationScript(globalCode);

  // In order to not migrate header and footer globals of existed users who have
  // already broken website animation we always remove website animation script
  // if it exists. If there won't be new cases with broken animation then this
  // could be moved to the else statement of website animation check
  // (isWebsiteAnimationEnabled variable)
  websiteAnimationScriptElement?.remove();

  if (isWebsiteAnimationEnabled) {
    node.appendChild(websiteAnimationScript);
  }

  return node;
};

export default applyEffects;
