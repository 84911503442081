import React from 'react';
import { useDispatch } from 'react-redux';
import { dialogs, i18next } from '@yola/ws-sdk';
import { designSystem } from '@yola/ws-ui';
import user from 'yola-editor/src/js/modules/user';
import segment from '../../analytics/segment';
import dialogTypes from '../../dialogs/constants/dialog-types';
import { TRIGGER_IDS } from '../../difm-promo/constants/traits';

const {
  trackers: { trackEvent },
  constants: { events },
} = segment;

const { EditorHeader } = designSystem;

const OrderWebsiteContainer = () => {
  const dispatch = useDispatch();

  const handleOrderWebsiteTriggerClick = () => {
    dispatch(user.actions.updateUserData(user.constants.userData.SHOW_DIFM_BANNER, false));

    trackEvent(events.ORDER_A_WEBSITE_TRIGGER_CLICKED);
    dialogs.operations.show(dialogTypes.DIFM_PROMO_BANNER_DIALOG, {
      triggerId: TRIGGER_IDS.orderWebsite,
    });
  };

  return (
    <EditorHeader.NavItem
      appearance="accent"
      onClick={handleOrderWebsiteTriggerClick}
      label={i18next.t('Hire a professional')}
    />
  );
};

export default OrderWebsiteContainer;
