import { site } from '@yola/ws-sdk';
import common from '../constants/common';

const getPathPrefix = (siteUrl, locale) => {
  if (site.verifiers.isMultilingualSite()) {
    return `${siteUrl || common.DEFAULT_SITE_URL}/${locale}`;
  }

  return `${siteUrl || common.DEFAULT_SITE_URL}`;
};

export default getPathPrefix;
