import React from 'react';
import PropTypes from 'prop-types';
import { utils } from '@yola/ws-sdk';
import BasicFieldContent from './basic-field-content';
import FieldContentWithOptions from './field-content-with-options';
import TimeFieldContent from './time-field-content';
import AttachFileFieldContent from './attach-file-field-content';
import constants from '../constants';
import helpers from '../helpers';

const { common } = constants;
const { MIN_DROPDOWN_ITEMS, MIN_RADIO_ITEMS } = common;

export default function FieldContentSection({
  type,
  labelValue,
  placeholderValue,
  buttonTextValue,
  fileTypeValue,
  handleChange,
  onRequiredCheckboxClick,
}) {
  const {
    singleline,
    multiline,
    dropdown,
    email,
    date,
    time,
    checkbox,
    radio,
    phone,
    link,
    attachfile,
  } = helpers.getFieldTypes();
  const placeholders = helpers.getFieldTypePlaceholders(type);

  switch (type) {
    case checkbox:
      return (
        <FieldContentWithOptions
          labelValue={labelValue}
          placeholders={placeholders}
          handleChange={handleChange}
          onRequiredCheckboxClick={onRequiredCheckboxClick}
        />
      );
    case radio:
      return (
        <FieldContentWithOptions
          labelValue={labelValue}
          isLabelRequired
          minOptionsCount={MIN_RADIO_ITEMS}
          placeholders={placeholders}
          handleChange={handleChange}
          onRequiredCheckboxClick={onRequiredCheckboxClick}
        />
      );
    case dropdown:
      return (
        <FieldContentWithOptions
          labelValue={labelValue}
          isLabelRequired
          minOptionsCount={MIN_DROPDOWN_ITEMS}
          placeholders={placeholders}
          handleChange={handleChange}
        />
      );
    case time:
      return (
        <TimeFieldContent
          labelValue={labelValue}
          placeholderValue={placeholderValue}
          placeholders={placeholders}
          handleChange={handleChange}
          onRequiredCheckboxClick={onRequiredCheckboxClick}
        />
      );
    case attachfile:
      return (
        <AttachFileFieldContent
          labelValue={labelValue}
          buttonTextValue={buttonTextValue}
          fileTypeValue={fileTypeValue}
          placeholders={placeholders}
          handleChange={handleChange}
          onRequiredCheckboxClick={onRequiredCheckboxClick}
        />
      );
    case singleline:
    case multiline:
    case email:
    case date:
    case phone:
    case link:
      return (
        <BasicFieldContent
          labelValue={labelValue}
          placeholderValue={placeholderValue}
          placeholders={placeholders}
          handleChange={handleChange}
          onRequiredCheckboxClick={onRequiredCheckboxClick}
        />
      );
    default:
      return null;
  }
}

FieldContentSection.propTypes = {
  type: PropTypes.string.isRequired,
  labelValue: PropTypes.string,
  placeholderValue: PropTypes.string,
  buttonTextValue: PropTypes.string,
  fileTypeValue: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  onRequiredCheckboxClick: PropTypes.func,
};

FieldContentSection.defaultProps = {
  labelValue: '',
  placeholderValue: '',
  buttonTextValue: '',
  fileTypeValue: '',
  onRequiredCheckboxClick: utils.noop,
};
