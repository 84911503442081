import observers from '../helpers/observers';

const subscribe = (eventName, callback) => {
  if (typeof callback !== 'function') throw new Error('callback is not a function');

  if (observers.has(eventName)) {
    const handlers = observers.get(eventName);
    observers.set(eventName, [...handlers, callback]);
    return;
  }

  observers.set(eventName, [callback]);
};

export default subscribe;
