import get from 'lodash.get';
import { config } from '@yola/ws-sdk';

const getCommonConfig = (rootState) => {
  const cfg = config.selectors.getConfig(rootState);

  return get(cfg, 'common');
};

export default getCommonConfig;
