import { view } from '@yola/ws-sdk';
import { VARIATION_ATTRIBUTE_NAME } from '../constants/common';
import {
  FULLSCREEN,
  SURFACES_ATTRIBUTE,
  WHITESPACE_BOTTOM,
  WHITESPACE_TOP,
} from '../constants/display-options';
import isBlockNodeClean from './layout-switching/is-block-node-clean';
import isLayoutSwitchingDisabled from '../verifiers/is-layout-switching-disabled';

const getBlockVariationId = (blockNode, blockId, blockItems) => {
  const blockMeta = blockItems.find(({ id }) => id === blockId);

  if (!blockMeta.variations) {
    return null;
  }

  const initialVariationId = blockNode.getAttribute(VARIATION_ATTRIBUTE_NAME);
  const isVariationPresentInMeta = blockMeta.variations.some(({ id }) => id === initialVariationId);

  if (isVariationPresentInMeta) {
    return initialVariationId;
  }

  // covers case when template has been swithched and target block variationId is missing in the new template meta
  // https://github.com/yola/production/issues/9797
  return blockMeta.variations[0].id;
};

const getOriginalBlockData = (originBlock, { refId, blockNode, blockItems, blockId }) => {
  if (originBlock) return originBlock;

  const blockSurface = blockNode.getAttribute(SURFACES_ATTRIBUTE);
  const staticBlockNode = view.accessors.getStaticElement(refId).cloneNode(true);
  const blockVariationId = getBlockVariationId(blockNode, blockId, blockItems);
  const isBlockLayoutSwitchingDisabled = isLayoutSwitchingDisabled({ blockItems, blockId });

  const { isStrictlyClean, isVisuallyClean } = isBlockNodeClean(
    blockId,
    blockVariationId,
    staticBlockNode,
    blockItems,
    [WHITESPACE_TOP, WHITESPACE_BOTTOM, FULLSCREEN]
  );

  return {
    blockSurface,
    staticBlockNode,
    blockNode,
    blockVariationId,
    isStrictlyClean,
    isVisuallyClean,
    blockId,
    isBlockLayoutSwitchingDisabled,
  };
};

export default getOriginalBlockData;
