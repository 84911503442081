import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import { i18next, hdrm } from '@yola/ws-sdk';
import classnames from 'classnames';

const { ActionList, ActionTextGroup, ActionButton, Stack } = designSystem;
const { DISABLE_BLUR_ATTR } = hdrm.constants.attributes;
const disableBlurAttribute = { [DISABLE_BLUR_ATTR]: true };

const ContextMenu = React.forwardRef((props, ref) => {
  const { children, closeMenu, className, title, description, submitButton } = props;
  const contextMenuClassName = classnames('ws-context-menu', className);

  const WrapperComponent = title ? ActionTextGroup : ActionList;

  return (
    <div className={contextMenuClassName} ref={ref} {...disableBlurAttribute}>
      <div onClick={closeMenu} className="ws-context-menu__overlay--mob" />
      <div className="ws-context-menu__content-wrapper">
        <div className="ws-context-menu__content">
          <WrapperComponent title={title} description={description}>
            {children}
          </WrapperComponent>
        </div>
        <div className="ws-context-menu__button-group">
          <Stack gap="spacing-xs">
            {submitButton && (
              <ActionButton
                size="large"
                fontSize="large"
                format="solid"
                appearance="accent"
                {...submitButton}
              />
            )}
            <ActionButton
              size="large"
              fontSize="large"
              format="solid-white"
              appearance="danger"
              onClick={closeMenu}
              label={i18next.t('Cancel')}
            />
          </Stack>
        </div>
      </div>
    </div>
  );
});

ContextMenu.propTypes = {
  closeMenu: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  submitButton: PropTypes.shape(),
};
ContextMenu.defaultProps = {
  closeMenu: Function.prototype,
  children: null,
  className: null,
  submitButton: null,
  title: '',
  description: '',
};

export default ContextMenu;
