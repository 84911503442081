import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';

const { Stack, Heading, Paragraph, ControlGroup, InputGroupField } = designSystem;

function WizardSetupContactsSection(props) {
  const { captions, addressField, phoneField, emailField, hoursField } = props;

  return (
    <Stack gap="spacing-m">
      <Stack gap="spacing-3-xs">
        <Heading type="heading-3">{captions.sectionTitle}</Heading>
        <Paragraph>{captions.sectionDescription}</Paragraph>
      </Stack>
      <Stack gap="spacing-xs">
        <ControlGroup title={addressField.label}>
          <InputGroupField
            control={addressField.control}
            name={addressField.name}
            rules={addressField.rules}
            placeholder={addressField.placeholder}
            iconGlyph="map-marker"
            size="medium"
          />
        </ControlGroup>
        <ControlGroup title={phoneField.label}>
          <InputGroupField
            control={phoneField.control}
            name={phoneField.name}
            rules={phoneField.rules}
            placeholder={phoneField.placeholder}
            iconGlyph="phone"
            size="medium"
          />
        </ControlGroup>
        <ControlGroup title={emailField.label} required>
          <InputGroupField
            control={emailField.control}
            name={emailField.name}
            rules={emailField.rules}
            placeholder={emailField.placeholder}
            iconGlyph="mail"
            size="medium"
          />
        </ControlGroup>
        <ControlGroup title={hoursField.label}>
          <InputGroupField
            control={hoursField.control}
            name={hoursField.name}
            rules={hoursField.rules}
            placeholder={hoursField.placeholder}
            iconGlyph="clock"
            size="medium"
          />
        </ControlGroup>
      </Stack>
    </Stack>
  );
}

WizardSetupContactsSection.propTypes = {
  captions: PropTypes.shape().isRequired,
  addressField: PropTypes.shape().isRequired,
  phoneField: PropTypes.shape().isRequired,
  emailField: PropTypes.shape().isRequired,
  hoursField: PropTypes.shape().isRequired,
};

export default WizardSetupContactsSection;
