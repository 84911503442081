import { serviceClient } from '@yola/ws-sdk';
import pollAsyncJob from '../../utils/poll-async-job';
import asyncJobStatuses from '../../common/constants/async-job-statuses';

const POLLING_INTERVAL = 300;

const initiateBlockGeneration = async (params) => {
  const client = serviceClient.get();
  const response = await client.initiateBlockGeneration(params);
  const {
    data: { status, id: jobId },
  } = response;

  switch (status) {
    case asyncJobStatuses.COMPLETE: {
      return response.data;
    }
    case asyncJobStatuses.FAILED: {
      throw response.data;
    }
    default: {
      return pollAsyncJob(jobId, POLLING_INTERVAL);
    }
  }
};

export default initiateBlockGeneration;
