import resizeHandlePositions from '../../../common/constants/resize-handle-positions';

const getPositioningAreaConstraints = ({
  elementBounds,
  positioningAreaBounds,
  defaultConstraints,
  resizeDirection,
}) => {
  const leftSpace = elementBounds.left - positioningAreaBounds.left;
  const topSpace = elementBounds.top - positioningAreaBounds.top;
  const ratio = elementBounds.width / elementBounds.height;
  const isElementOutsidePositioningAreaAlongYAxis =
    Math.round(elementBounds.height) > Math.round(positioningAreaBounds.height);

  const getMaxWidthByRatio = (maxHeight) => maxHeight * ratio;
  const getMaxHeightByRatio = (maxWidth) => maxWidth / ratio;
  const getMaxWidth = (maxWidth, maxHeight) => {
    const values = [maxWidth, defaultConstraints.maxWidth];

    if (maxHeight) {
      values.push(getMaxWidthByRatio(maxHeight));
    }

    return Math.floor(Math.min(...values));
  };
  const getMaxHeight = (maxHeight, maxWidth) => {
    const values = [maxHeight, defaultConstraints.maxHeight];

    if (maxWidth) {
      values.push(getMaxHeightByRatio(maxWidth));
    }

    return Math.floor(Math.min(...values));
  };

  switch (resizeDirection) {
    case resizeHandlePositions.TOP_LEFT: {
      const maxWidth = leftSpace + elementBounds.width;
      const maxHeight = topSpace + elementBounds.height;

      return {
        ...defaultConstraints,
        maxWidth: getMaxWidth(maxWidth, maxHeight),
        maxHeight: getMaxHeight(maxHeight, maxWidth),
        ...(isElementOutsidePositioningAreaAlongYAxis && { minHeight: elementBounds.height }),
      };
    }

    case resizeHandlePositions.TOP_CENTER: {
      const maxHeight = topSpace + elementBounds.height;

      return {
        ...defaultConstraints,
        maxHeight: getMaxHeight(maxHeight),
        ...(isElementOutsidePositioningAreaAlongYAxis && { minHeight: elementBounds.height }),
      };
    }

    case resizeHandlePositions.TOP_RIGHT: {
      const maxWidth = positioningAreaBounds.width - leftSpace;
      const maxHeight = topSpace + elementBounds.height;

      return {
        ...defaultConstraints,
        maxWidth: getMaxWidth(maxWidth, maxHeight),
        maxHeight: getMaxHeight(maxHeight, maxWidth),
        ...(isElementOutsidePositioningAreaAlongYAxis && { minHeight: elementBounds.height }),
      };
    }

    case resizeHandlePositions.CENTER_LEFT: {
      const maxWidth = leftSpace + elementBounds.width;

      return {
        ...defaultConstraints,
        maxWidth: getMaxWidth(maxWidth),
      };
    }

    case resizeHandlePositions.CENTER_RIGHT: {
      const maxWidth = positioningAreaBounds.width - leftSpace;

      return {
        ...defaultConstraints,
        maxWidth: getMaxWidth(maxWidth),
      };
    }

    case resizeHandlePositions.BOTTOM_LEFT: {
      const maxWidth = leftSpace + elementBounds.width;
      const maxHeight = isElementOutsidePositioningAreaAlongYAxis
        ? elementBounds.height
        : positioningAreaBounds.height - topSpace;

      return {
        ...defaultConstraints,
        maxWidth: getMaxWidth(maxWidth, maxHeight),
        maxHeight: getMaxHeight(maxHeight, maxWidth),
      };
    }

    case resizeHandlePositions.BOTTOM_CENTER: {
      const maxHeight = isElementOutsidePositioningAreaAlongYAxis
        ? elementBounds.height
        : positioningAreaBounds.height - topSpace;

      return {
        ...defaultConstraints,
        maxHeight: getMaxHeight(maxHeight),
      };
    }

    case resizeHandlePositions.BOTTOM_RIGHT: {
      const maxWidth = positioningAreaBounds.width - leftSpace;
      const maxHeight = isElementOutsidePositioningAreaAlongYAxis
        ? elementBounds.height
        : positioningAreaBounds.height - topSpace;

      return {
        ...defaultConstraints,
        maxWidth: getMaxWidth(maxWidth, maxHeight),
        maxHeight: getMaxHeight(maxHeight, maxWidth),
      };
    }

    default: {
      return defaultConstraints;
    }
  }
};

export default getPositioningAreaConstraints;
