import constants from '../constants';

const { timeFormats } = constants;

export default function getTimeFormat() {
  const date = new Date();
  const lang = navigator.language;
  const time = date.toLocaleTimeString(lang).toUpperCase();

  return /AM|A\.M\.|PM|P\.M\./.test(time) ? timeFormats['12_HOUR'] : timeFormats['24_HOUR'];
}
