import { assets } from '@yola/ws-sdk';

const cacheSiteImage = (url) =>
  new Promise((resolve, reject) => {
    const preloadImg = new Image();
    preloadImg.onload = resolve;
    preloadImg.onerror = reject;
    preloadImg.src = assets.helpers.addBaseHref(url);
  });

export default cacheSiteImage;
