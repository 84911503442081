import React from 'react';

const IframeContext = React.createContext({
  resizeObserver: null,
  resizeHandler: null,
  intersectionObserver: null,
  intersectionHandler: null,
});

export default IframeContext;
