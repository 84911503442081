import getFieldSelectors from './get-field-selectors';

const removeEditableFields = (container) => {
  const { itemGroup } = getFieldSelectors();
  Array.from(container.querySelectorAll(itemGroup)).forEach((field) => {
    if (!field.querySelector('[name="gdpr-consent"], .g-recaptcha, .h-captcha')) {
      container.removeChild(field);
    }
  });
};

export default removeEditableFields;
