import React from 'react';
import { extensions, template, bowser } from '@yola/ws-sdk';
import { AI_WRITE_TEXT_TRIGGER_ID } from 'src/js/modules/control-pane/constants/trigger-ids';
import serviceClient from 'yola-editor/src/js/modules/feature-flags/service-client';
import constants from '../constants';
import typedElementsMatches from '../helpers/typed-elements-matches';
import AiWriterTrigger from '../components/ai-writer-trigger';
import AiWriteTextContainer from '../containers/ai-write-text-container';

const { controlTypes } = extensions;
const {
  SLUG,
  AI_WRITER_TRIGGER_WIDTH,
  AI_WRITE_TEXT_ACTIVE_HEADER_MOBILE_WIDTH,
  AI_WRITE_TEXT_ACTIVE_HEADER_WIDTH,
} = constants.common;

const triggerId = AI_WRITE_TEXT_TRIGGER_ID;

function elementMatches(element) {
  const featureFlagsManager = serviceClient.get();
  const { ai_text_assistant_write: isFeatureFlagEnabled } = featureFlagsManager.state;

  if (!isFeatureFlagEnabled) {
    return false;
  }

  const isMpt = template.verifiers.isMpt();
  const { textContainer } = extensions.accessors.getExtensionSettings(SLUG);

  if (!isMpt || !element.matches(textContainer)) {
    return false;
  }

  const typedElements = typedElementsMatches();
  return Boolean(typedElements.length);
}

const control = {
  id: triggerId,
  trigger: <AiWriterTrigger width={AI_WRITER_TRIGGER_WIDTH} />,
  width: AI_WRITER_TRIGGER_WIDTH,
  priority: 62,
  for: [controlTypes.COLLAPSED_TEXT],
  highlightElement: false,
  matches: elementMatches,
  header: [<AiWriteTextContainer triggerId={triggerId} />],
  activeWidth: bowser.mobile
    ? AI_WRITE_TEXT_ACTIVE_HEADER_MOBILE_WIDTH
    : AI_WRITE_TEXT_ACTIVE_HEADER_WIDTH,
};

export default control;
