import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { site, dialogs } from '@yola/ws-sdk';
import dialogTypes from '../../dialogs/constants/dialog-types';
import settingTypes from '../../website-settings/constants/setting-types';

const useAiOnboarding = () => {
  const siteSettings = useSelector(site.selectors.getSettings);
  const shouldShowAiOnboarding =
    !siteSettings[settingTypes.BUSINESS_NAME] || !siteSettings[settingTypes.BUSINESS_DESCRIPTION];

  const onNextDialog = useCallback(
    ({ onNext, ...onboardingProps }) => {
      if (shouldShowAiOnboarding) {
        dialogs.operations.show(dialogTypes.AI_ONBOARDING_DIALOG, {
          ...onboardingProps,
          onDialogMainAction: () => onNext(),
        });

        return;
      }

      onNext();
    },
    [shouldShowAiOnboarding]
  );

  return {
    onNextDialog,
  };
};

export default useAiOnboarding;
