import React from 'react';
import { i18next } from '@yola/ws-sdk';
import PendingDialog from '../components/pending-dialog';

function PurchasePendingDialogContainer() {
  const captions = {
    title: `${i18next.t('Your order is being processed...')}`,
  };

  return <PendingDialog captions={captions} />;
}

export default PurchasePendingDialogContainer;
