import React from 'react';
import PropTypes from 'prop-types';

function ShareButtonsGroup({ children }) {
  return (
    <div className="ws-share__buttons-group">
      {React.Children.map(children, (button) => (
        <div className="ws-share__button-wrapper">{button}</div>
      ))}
    </div>
  );
}

ShareButtonsGroup.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default ShareButtonsGroup;
