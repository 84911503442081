import constants from '../constants/common';

const getTreeViewItemsFromRedirects = (redirects) =>
  redirects.map(({ from, to }, index) => ({
    id: index,
    title: `${from} ${constants.REDIRECT_TITLE_SEPARATOR} ${to}`,
    order: String(index),
  }));

export default getTreeViewItemsFromRedirects;
