import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { site, i18next, serviceClient, siteLocales } from '@yola/ws-sdk';
import { snakeCaseToCamelCase } from '../../utils/convert-case';
import { RedirectPropType } from '../constants/types';
import RedirectEditDialog from '../components/redirect-edit-dialog';

const filterOptionsFunction = ({ value, data: { note } }, query) =>
  value.toLowerCase().includes(query.toLowerCase()) ||
  note.toLowerCase().includes(query.toLowerCase());

const createPageOptions = (pages, currentLocale) => {
  const createOption = (path, title) => ({
    value: path,
    label: path,
    note: title,
  });

  const currentLocaleOptions = [];
  const allOptions = [];

  pages.forEach(({ locale, path, title }) => {
    const currentLocaleSlug = `/${currentLocale}/`;
    if (locale === currentLocale && path.startsWith(currentLocaleSlug)) {
      // On the published site, the current locale URL slug is omitted
      const currentLocalePath = path.replace(currentLocaleSlug, '/');

      currentLocaleOptions.push(createOption(currentLocalePath, title));
    }

    allOptions.push(createOption(path, title));
  });

  return [...currentLocaleOptions, ...allOptions];
};

function RedirectEditContainer({
  redirect,
  redirectIndex,
  description,
  warningMessage,
  onSubmit,
  onCancel,
  onValidationError,
}) {
  const pages = useSelector(site.selectors.getPages);
  const currentLocale = useSelector(siteLocales.selectors.getCurrentLocale);
  const [isLoading, setIsLoading] = useState(false);
  const [validationMessages, setValidationMessages] = useState(null);

  // Pages paths suggestions config is presented in the
  // format of the single group with label and options,
  // to match our Autocomplete look with required UI.
  const pagesOptions = useMemo(
    () => [
      {
        label: i18next.t('Type a URL or choose a page from your site'),
        options: createPageOptions(pages, currentLocale),
      },
    ],
    [pages, currentLocale]
  );

  const validationMessagesTexts = useMemo(
    () => ({
      emptyField: i18next.t('This field can not be empty'),
      missingStartingSlash: i18next.t('The "/" is missing at the beginning'),
      invalidSyntax: i18next.t('The syntax is invalid'),
      invalidCaptureGroupReference: i18next.t('The capture group reference is invalid'),
      defaultError: i18next.t('This field value is not valid'),
      serverError: i18next.t('An error occurred while validating your redirect rule'),
    }),
    []
  );

  const getValidationMessageByErrors = (errors) => {
    const message = errors.reduce((accumulator, error) => {
      const textStart = accumulator.trim();
      const isStartEndsWithDot = textStart.charAt(textStart.length - 1) === '.';
      const separator = isStartEndsWithDot ? ' ' : '. ';
      const text = validationMessagesTexts[error];

      if (textStart && text) return `${textStart}${separator}${text}`;

      return text || textStart;
    }, '');

    return message.trim() || validationMessagesTexts.defaultError;
  };

  const getValidationErrors = (redirectItem) => {
    // Validate empty paths values immediately on the
    // frontend to avoid spare HTTP roundtrip
    const { from, to } = redirectItem;
    const isFromEmpty = !from || !from.trim();
    const isToEmpty = !to || !to.trim();

    if (isFromEmpty || isToEmpty) {
      return {
        ...(isFromEmpty && { from: ['emptyField'] }),
        ...(isToEmpty && { to: ['emptyField'] }),
      };
    }

    // Handle all the rest validations on the BE
    const { validateRedirect } = serviceClient.get();
    const siteId = site.accessors.getSiteId();

    return validateRedirect(siteId, redirectItem)
      .then(({ data }) => {
        const { valid } = data;

        if (valid) {
          return null;
        }

        // Fields errors are coming in a format of
        // { fieldName: ['some_error', 'another_error'] }
        const { fields } = data;
        // eslint-disable-next-line no-shadow
        const from = fields.from && fields.from.map((errorCode) => snakeCaseToCamelCase(errorCode));
        // eslint-disable-next-line no-shadow
        const to = fields.to && fields.to.map((errorCode) => snakeCaseToCamelCase(errorCode));

        return {
          ...(from && { from }),
          ...(to && { to }),
        };
      })
      .catch(() => ({
        from: ['serverError'],
        to: ['serverError'],
      }));
  };

  const handleSubmit = async (redirectItem, index) => {
    const isRedirectEdited =
      redirectItem.from !== redirect?.from || redirectItem.to !== redirect?.to;

    if (!isRedirectEdited) {
      onCancel();
      return;
    }

    setIsLoading(true);
    setValidationMessages(null);

    const validationErrors = await getValidationErrors(redirectItem);

    setIsLoading(false);

    if (validationErrors) {
      const { from, to } = validationErrors;

      setValidationMessages({
        ...(from && { from: getValidationMessageByErrors(from) }),
        ...(to && { to: getValidationMessageByErrors(to) }),
      });

      onValidationError();

      return;
    }

    setValidationMessages(null);
    onSubmit(redirectItem, index);
  };

  return (
    <RedirectEditDialog
      redirect={redirect}
      redirectIndex={redirectIndex}
      description={description}
      warningMessage={warningMessage}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      pagesOptions={pagesOptions}
      isLoading={isLoading}
      validationMessages={validationMessages}
      filterOptionsFunction={filterOptionsFunction}
    />
  );
}

export default RedirectEditContainer;

RedirectEditContainer.propTypes = {
  redirect: RedirectPropType,
  redirectIndex: PropTypes.number,
  description: PropTypes.string,
  warningMessage: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onValidationError: PropTypes.func,
};

RedirectEditContainer.defaultProps = {
  redirect: null,
  redirectIndex: null,
  description: '',
  warningMessage: '',
  onValidationError: Function.prototype,
};
