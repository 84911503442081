const collator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'base',
});

function sortPresets(presets, activePresetId) {
  const sortedPresets = [...presets].sort((preset1, preset2) =>
    collator.compare(preset1.id, preset2.id)
  );
  const activePreset =
    activePresetId && sortedPresets.find((preset) => preset.id === activePresetId);

  if (activePreset) {
    return [activePreset, ...sortedPresets.filter((preset) => preset.id !== activePresetId)];
  }

  return sortedPresets;
}

export default sortPresets;
