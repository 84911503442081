import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import AnimatedSprite from '../../animation/components/animated-sprite';
import preloadResource from '../../../utils/preload-resource.js';

const { Heading, Paragraph } = designSystem;

preloadResource('/images/publish-progress-sprite.svg', 'image');

function PublishProgressDialogContainer({ captions }) {
  return (
    <ReactModal
      isOpen
      overlayClassName="publish-progress-dialog__overlay"
      className="dialog dialog--centered publish-progress-dialog"
    >
      <div className="dialog-body">
        <div className="publish-progress-dialog__animation-background">
          <AnimatedSprite
            animationCount="infinite"
            animationLength="4.5s"
            animationName="play"
            animationSteps="steps(49)"
            height={90}
            spriteWidth={5880}
            style={{
              marginTop: '7px',
            }}
            src="/images/publish-progress-sprite.svg"
            width={120}
          />
          <div className="publish-progress-dialog__branding-overlay" />
        </div>
        <Heading type="heading-3" align="center">
          {captions.title}
        </Heading>
        {captions.description && (
          <Paragraph size="large" appearance="medium-emphasis" align="center">
            {captions.description}
          </Paragraph>
        )}
      </div>
    </ReactModal>
  );
}

PublishProgressDialogContainer.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
  }).isRequired,
};

export default PublishProgressDialogContainer;
