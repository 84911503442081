import React, { useEffect, useRef, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { view } from '@yola/ws-sdk';
import { designSystem } from '@yola/ws-ui';
import isClickedOutside from 'src/js/modules/context-menu/verifiers/is-clicked-outside';
import useEventListener from 'src/js/modules/utils/custom-hooks/use-event-listener';
import actions from 'src/js/modules/context-menu/actions';
import highlighter from 'src/js/modules/highlighter';
import isEscPressed from 'src/js/modules/context-menu/verifiers/is-esc-pressed';
import commonConstants from 'src/js/modules/common/constants';
import withFeatureFlags from 'yola-editor/src/js/modules/feature-flags/hoc/with-feature-flags';
import getMenuCssPosition from '../helpers/get-menu-css-position';
import ContextMenuGroupContainer from './context-menu-group-container';
import ContextMenu from '../components/context-menu';
import getMenu from '../selectors/menu';

const { ActionListDivider } = designSystem;
const { RIGHT_CLICK_TRIGGER_ID } = commonConstants.common;

const HIDE_SCROLL_LIMIT = 100;

function ContextMenuContainer({
  menu: {
    title,
    description,
    submitButton,
    visibility,
    groups,
    triggerId,
    position,
    calculatePosition = getMenuCssPosition,
  },
  hideContextMenu,
  scrollPosition,
  isScrolling,
}) {
  const [initialScroll, setInitialScroll] = useState(scrollPosition);

  const menuElementRef = useRef(null);
  // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  const prevHighlightedElements = useMemo(
    () => highlighter.accessors.getHighlightedElements(),
    [visibility]
  );

  const closeMenu = useCallback(() => {
    hideContextMenu();

    const highlightedElements = highlighter.accessors.getHighlightedElements();
    const elementsToHideHighlighterFor = highlightedElements.filter(
      (highlightedElement) => !prevHighlightedElements.includes(highlightedElement)
    );

    if (elementsToHideHighlighterFor.length) {
      highlighter.operations.hide(elementsToHideHighlighterFor.map(([element]) => element));
    }
  }, [hideContextMenu, prevHighlightedElements]);

  const handleMousedown = useCallback(
    (event) => {
      if (visibility && isClickedOutside(event, menuElementRef.current)) {
        closeMenu();
      }
    },
    [visibility, menuElementRef, closeMenu]
  );

  const handleKeydown = useCallback(
    (event) => {
      if (visibility && isEscPressed(event)) {
        closeMenu();
      }
    },
    [visibility, closeMenu]
  );

  useEventListener('mousedown', handleMousedown);
  useEventListener('keydown', handleKeydown);

  useEffect(() => {
    const menuEl = menuElementRef.current;

    if (!menuEl) return;

    const { x, y } = position;
    const { top, right, bottom, left } = calculatePosition(menuEl, x, y);

    menuEl.style.top = top;
    menuEl.style.right = right;
    menuEl.style.bottom = bottom;
    menuEl.style.left = left;
    menuEl.classList.add('ws-context-menu--visible');
  }, [visibility, calculatePosition, position]);

  useEffect(() => {
    setInitialScroll(scrollPosition);
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, [triggerId]);

  useEffect(() => {
    if (triggerId === RIGHT_CLICK_TRIGGER_ID) {
      const scrollDownLimit = initialScroll + HIDE_SCROLL_LIMIT;
      const scrollUpLimit = initialScroll - HIDE_SCROLL_LIMIT;
      const isScrollOutsideLimit =
        scrollPosition >= scrollDownLimit || scrollPosition <= scrollUpLimit;
      if (isScrollOutsideLimit) hideContextMenu();
    } else if (triggerId && isScrolling) {
      hideContextMenu();
    }
  }, [triggerId, hideContextMenu, initialScroll, isScrolling, scrollPosition]);

  return (
    visibility && (
      <ContextMenu
        title={title}
        description={description}
        submitButton={submitButton}
        ref={menuElementRef}
        closeMenu={closeMenu}
      >
        {groups.map((group, index) => {
          const isLastItem = groups.length === index + 1;

          return (
            <React.Fragment key={group.name}>
              <ContextMenuGroupContainer group={group} />
              {groups.length > 1 && !isLastItem && <ActionListDivider />}
            </React.Fragment>
          );
        })}
      </ContextMenu>
    )
  );
}

ContextMenuContainer.propTypes = {
  menu: PropTypes.shape({
    title: PropTypes.node,
    description: PropTypes.node,
    submitButton: PropTypes.shape(),
    visibility: PropTypes.bool.isRequired,
    groups: PropTypes.array.isRequired,
    triggerId: PropTypes.string,
    position: PropTypes.shape({
      x: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      y: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    }),
    calculatePosition: PropTypes.func,
  }).isRequired,
  hideContextMenu: PropTypes.func.isRequired,
  scrollPosition: PropTypes.number.isRequired,
  isScrolling: PropTypes.bool,
};

ContextMenuContainer.defaultProps = {
  isScrolling: false,
};

const mapStateToProps = (state) => ({
  menu: getMenu(state),
  scrollPosition: view.selectors.getScrollPosition(state),
  isScrolling: view.selectors.getScrollStatus(state),
});

const mapDispatchToProps = (dispatch) => ({
  hideContextMenu: () => {
    dispatch(actions.hideContextMenu());
  },
});

export default withFeatureFlags(['image_copy_paste'])(
  connect(mapStateToProps, mapDispatchToProps)(ContextMenuContainer)
);
