const linksEditCount = (initialItems, updatedItems) => {
  const initialItemsMap = initialItems.reduce(
    (map, item) => ({
      ...map,
      [item.id]: {
        ...item,
      },
    }),
    {}
  );

  return updatedItems.reduce((result, updatedItem) => {
    const { id, href, iconBaseSrc } = updatedItem;

    const editedItem = initialItemsMap[id];

    if (editedItem && (editedItem.href !== href || editedItem.iconBaseSrc !== iconBaseSrc)) {
      return result + 1;
    }

    return result;
  }, 0);
};

export default linksEditCount;
