import store from 'src/js/store';
import actions from '../actions';

/**
 * Context menu item
 * @typedef {Object} Item
 * @property {String} id          - The unique identifier that is intended to track analytic event.
 * @property {String} caption     - Indicates item name.
 * @property {String} [glyph]     - Indicates icon, optional.
 * @property {Number} [order]     - Indicates item order in scope of parent group, optional.
 * @property {Function} onClick   - Item click function
 * @property {Matches} [matches]  - Item matches {@link Matches|matches} function, optional. Use group matcher as a fallback
 */

/**
 * Item matches function
 * @callback Matches
 * @param {HTMLElement} element - Indicates the element which context menu item should match to be shown
 */

/**
 * Register context menu group
 * @param {Object} group                       - Group to register
 * @param {String} [group.groupName = Default] - Indicates group name, optional. If already exists then group will be merged.
 * @param {Number} [group.groupOrder]          - Indicates group order, optional. If groupName exists then will be overwritten.
 * @param {Matches} group.matches              - The group {@link Matches|matches} function. Use as a fallback for each {@link Item|item}
 * @param {Array.<Item>} group.items           - Array of {@link Item|item} objects
 */
function registerItemsGroup(group) {
  store.dispatch(actions.registerItemsGroup(group));
}

export default registerItemsGroup;
