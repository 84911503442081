import store from 'src/js/store';
import getHcaptchaSiteKey from '../selectors/hcaptcha-site-key';

const getHcaptchaKey = () => {
  const state = store.getState();

  return getHcaptchaSiteKey(state);
};

export default getHcaptchaKey;
