import { accessors, constants } from '@yola/subscription-manager-js';
import mbToBytes from '../mb-to-bytes';

const {
  hostingPackageFeatureNames: { SITE_STORAGE_QUOTA },
} = constants;

const storageLimit = ({ targetPackage }) => {
  const topPackage = accessors.getTopAvailableHostingPackage();

  return {
    // in MB
    maxSize: mbToBytes(targetPackage.features[SITE_STORAGE_QUOTA]),
    upgradeAvailable:
      topPackage.features[SITE_STORAGE_QUOTA] > targetPackage.features[SITE_STORAGE_QUOTA],
  };
};

export default storageLimit;
