import actionTypes from '../constants/action-types';

const setPublishedSiteData = (publishedData) => ({
  type: actionTypes.SET_PUBLISHED_SITE_DATA,
  payload: {
    publishedData,
  },
});

export default setPublishedSiteData;
