import { site, i18next } from '@yola/ws-sdk';
import publishingServiceClient from 'yola-editor/src/js/modules/publishing-service-client/service-client';
import pollGetPublishingJob from 'yola-editor/src/js/modules/publishing/helpers/poll-get-publishing-job';
import dialogs from 'yola-editor/src/js/modules/dialogs';
import show from '../actions/show';

const pollPublishingJob = (jobId) =>
  new Promise((resolve, reject) => {
    pollGetPublishingJob({
      jobId,
      onRequestFail: reject,
      onSuccess: resolve,
    });
  });

const generateSitePreview = () => async (dispatch, getState) => {
  const state = getState();
  const client = publishingServiceClient.get();
  const siteId = site.selectors.getSiteId(state);

  dispatch(
    dialogs.actions.show(dialogs.dialogTypes.PREVIEW_PROGRESS, {
      captions: {
        title: i18next.t('Generating website preview…'),
      },
    })
  );

  try {
    const {
      data: { id: jobId },
    } = await client.initiateSitePreviewGeneration(siteId);

    const { data } = await pollPublishingJob(jobId);
    const { primaryVhost } = data.results;

    dispatch(show(primaryVhost));
    dispatch(dialogs.actions.hide());
  } catch (err) {
    console.error(err);

    dispatch(
      dialogs.actions.show(dialogs.dialogTypes.PREVIEW_ERROR, {
        captions: {
          title: i18next.t('Site preview failed'),
          description: i18next.t('An error occurred while generating your site preview'),
        },
      })
    );
  }
};

export default generateSitePreview;
