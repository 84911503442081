import config from 'src/js/modules/config';
import constants from '../constants/common';

const getUpdatedMapSource = (q, src = constants.BASE_MAP_HREF) => {
  const url = new URL(src);
  const key = config.accessors.getGoogleMapsEmbedApiKey();

  url.searchParams.set('key', key);
  url.searchParams.set('q', q);
  return url.href;
};

export default getUpdatedMapSource;
