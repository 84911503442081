import { view } from '@yola/ws-sdk';

const bulkActions = (actions) => {
  const { viewActions, childrenPresenceActions } = actions.reduce(
    (acc, action) => {
      if (action.type === view.actionTypes.SET_CHILDREN_PRESENCE) {
        acc.childrenPresenceActions.push(action);
      } else {
        acc.viewActions.push(action);
      }
      return acc;
    },
    { viewActions: [], childrenPresenceActions: [] }
  );

  if (viewActions.length === 1 && !childrenPresenceActions.length) {
    return viewActions[0];
  }

  if (childrenPresenceActions.length === 1 && !viewActions.length) {
    return childrenPresenceActions[0];
  }

  const bulkSetChildrenPresenceActions = Object.entries(
    childrenPresenceActions.reduce((acc, action) => {
      const { containerId } = action.payload;

      if (acc[containerId]) {
        acc[containerId].push(action.payload);
      } else {
        acc[containerId] = [action.payload];
      }

      return acc;
    }, {})
  ).map(([containerId, targetChildrenPresenceActions]) => {
    const { options } = targetChildrenPresenceActions[0];
    const childrenPresenceUpdates = targetChildrenPresenceActions.map(
      ({ childrenPresenceUpdate }) => childrenPresenceUpdate
    );
    return view.actions.bulkSetChildrenPresence(containerId, childrenPresenceUpdates, options);
  });

  return view.actions.bulkViewActions([...bulkSetChildrenPresenceActions, ...viewActions], {
    skipDesignAutomation: true,
  });
};

export default bulkActions;
