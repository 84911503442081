import { getSettings } from '../settings';

const getUrlFromBackgroundContainer = (node) => {
  const settings = getSettings();
  const backgroundContainer = node.querySelector(settings.backgroundContainer);
  if (!backgroundContainer) {
    throw Error(`Node should include ${settings.backgroundContainer}`);
  }
  return backgroundContainer.src;
};

export default getUrlFromBackgroundContainer;
