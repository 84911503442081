import { transactions } from '@yola/ws-sdk';
import getElementBySelector from '../../helpers/get-element-by-selector';
import getAffectedBlocksSelector from './helpers/get-affected-blocks-selector';
import getSurfaceAutomationInstructions from './helpers/get-surface-automation-instructions';
import getOverlayOpacityAutomationInstructions from './helpers/get-overlay-opacity-automation-instructions';
import isHeader from './verifiers/is-header';

const blockIsReplaced = (config, payload, transaction) => {
  const { context, state: transactionDOM } = transaction;
  const { element } = payload;
  const affectedBlocksSelector = getAffectedBlocksSelector();
  const headerNode = transactionDOM.querySelector(affectedBlocksSelector);

  if (!headerNode || !isHeader(element)) {
    return [];
  }

  const isStatic = context !== transactions.transactionTypes.LIVE_DOCUMENT;

  return [
    ...getSurfaceAutomationInstructions({
      headerNode: getElementBySelector(affectedBlocksSelector, isStatic),
      nextSiblingNode: headerNode.nextElementSibling,
      isStatic,
    }),
    ...getOverlayOpacityAutomationInstructions({
      headerNode: getElementBySelector(affectedBlocksSelector, isStatic),
      nextSiblingNode: headerNode.nextElementSibling,
      isStatic,
    }),
  ];
};

export default blockIsReplaced;
