import on from '../on';
import backgroundIsEnabled from './background-is-enabled';
import surfaceIsChanged from './surface-is-changed';
import blockIsReplaced from './block-is-replaced';
import imageIsReplaced from './image-is-replaced';
import widgetIsInserted from './widget-is-inserted';
import reasons from '../../constants/reasons';

const { SET_ELEMENT_ATTRIBUTE, REMOVE_ELEMENT_ATTRIBUTE, REPLACE_ELEMENT, INSERT_BLOCK } = reasons;

const init = () => {
  on({ reason: REMOVE_ELEMENT_ATTRIBUTE }, backgroundIsEnabled);
  on({ reason: SET_ELEMENT_ATTRIBUTE }, surfaceIsChanged);
  on({ reason: REPLACE_ELEMENT }, blockIsReplaced);
  on({ reason: SET_ELEMENT_ATTRIBUTE }, imageIsReplaced);
  on({ reason: INSERT_BLOCK }, widgetIsInserted);
};

export default init;
