import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { utils, i18next } from '@yola/ws-sdk';
import { designSystem } from '@yola/ws-ui';
import calculateMenuPosition from 'src/js/modules/control-pane/helpers/calculate-context-menu-position';
import Trigger from 'src/js/modules/common/components/trigger';
import UploadImageMenu from './upload-image-menu';

const { Icon } = designSystem;

function UploadImageTrigger(props) {
  const { getContext, active, ...restProps } = props;
  const triggerNodeRef = useRef(null);

  const calculatePositionDecorator = useCallback(
    (menuElement) => {
      const context = getContext();
      const { controlPane = {} } = context;
      const { position: controlPanePosition, direction } = controlPane;
      const { left } = triggerNodeRef.current.getBoundingClientRect();

      controlPanePosition.x = left;

      return calculateMenuPosition({
        menuElement,
        controlPanePosition,
        direction,
      });
    },
    [getContext]
  );

  const getControlPanePosition = useCallback(() => {
    const context = getContext();
    if (!context || !context.controlPane) {
      return {
        x: 0,
        y: 0,
      };
    }
    const { position } = context.controlPane;

    return position;
  }, [getContext]);

  return (
    <UploadImageMenu
      calculatePositionDecorator={calculatePositionDecorator}
      getContextMenuPosition={getControlPanePosition}
      triggerNodeRef={triggerNodeRef}
      {...restProps}
    >
      <Trigger data-tip={i18next.t('Upload image')} active={active}>
        <Icon glyph="image-replace" />
      </Trigger>
    </UploadImageMenu>
  );
}

UploadImageTrigger.propTypes = {
  id: PropTypes.string.isRequired,
  elementId: PropTypes.string,
  onImageUpload: PropTypes.func.isRequired,
  onStockPhotoUpload: PropTypes.func.isRequired,
  onWrongFileType: PropTypes.func.isRequired,
  getContext: PropTypes.func,
  accept: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onTriggerUploadImageClick: PropTypes.func,
  onTriggerBrowseStockPhotosClick: PropTypes.func,
  preserveSharedData: PropTypes.bool,
  active: PropTypes.bool,
};

UploadImageTrigger.defaultProps = {
  onClick: utils.noop,
  onTriggerUploadImageClick: utils.noop,
  onTriggerBrowseStockPhotosClick: utils.noop,
  elementId: null,
  preserveSharedData: false,
  getContext: utils.noop,
  active: false,
};

export default UploadImageTrigger;
