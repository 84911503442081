import React, { useState, useRef, useEffect, useLayoutEffect, useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import { context as viewportContext } from 'yola-editor/src/js/utils/viewport-provider';
import bowser from 'yola-bowser';
import Frame from '../../common/components/syncy-frame/frame';
import MODES from '../constants/modes';
import getPreviewModeForDevice from '../helpers/get-preview-mode-for-device';
import calculateViewportSize from '../helpers/calculate-viewport-size';
import adaptSizeForViewport from '../helpers/adapt-size-for-viewport';
import getScale from '../helpers/get-scale';
import usePreloader from '../hooks/use-preloader';
import PreviewSkeleton from './preview-skeleton';

const { Preloader } = designSystem;

const PreviewViewport = ({ mode, src, onDocumentLoad }) => {
  const { height: viewportHeight } = useContext(viewportContext);
  const [{ width, height }, setSize] = useState({ width: 0, height: 0 });
  const [isLoaded, setIsLoaded] = useState(false);
  const [scale, setScale] = useState(1);
  const nodeElement = useRef(null);
  const progress = usePreloader(isLoaded);

  useLayoutEffect(() => {
    if (bowser.ios) {
      document.documentElement.style.setProperty('--viewport-height', `${viewportHeight}px`);
    }
  }, [viewportHeight]);

  useEffect(() => {
    const calculateViewport = () => {
      const realViewMode = getPreviewModeForDevice();
      const calculatedSize = calculateViewportSize(nodeElement.current, realViewMode, mode);
      const adaptedSize = adaptSizeForViewport(nodeElement.current, calculatedSize);
      setScale(getScale(nodeElement.current, adaptedSize));
      setSize(adaptedSize);
    };

    calculateViewport();

    const {
      ownerDocument: { defaultView },
    } = nodeElement.current;

    defaultView.addEventListener('resize', calculateViewport);

    return () => {
      defaultView.removeEventListener('resize', calculateViewport);
    };
  }, [mode]);

  const handleDocumentLoad = (iframe) => {
    onDocumentLoad(iframe);
    setIsLoaded(true);
  };

  const realViewMode = getPreviewModeForDevice();

  const classes = classNames('ws-preview-viewport', {
    'ws-preview-viewport--loaded': isLoaded,
    'ws-preview-viewport--mobile': mode === MODES.mobile,
    'ws-preview-viewport--tablet': mode === MODES.tablet,
    'ws-preview-viewport--desktop': mode === MODES.desktop,
    'ws-preview-viewport--real-view-tablet': realViewMode === MODES.tablet,
  });

  const frameClasses = classNames('ws-syncy-frame-window', {
    'ws-syncy-frame-window--active': isLoaded,
  });

  return (
    <div className={classes} ref={nodeElement}>
      {!isLoaded && (
        <React.Fragment>
          <div className="ws-preview-viewport__preloader">
            <Preloader progress={progress} isAnimated />
          </div>
          <div className="ws-preview-viewport__skeleton">
            <PreviewSkeleton />
          </div>
        </React.Fragment>
      )}
      {src && (
        <div
          style={{
            transform: `scale(${scale})`,
          }}
          className="ws-preview-viewport__inner"
        >
          <div className="ws-preview-viewport__frame" style={{ width, height }}>
            <Frame
              id="ws-preview-mode-frame"
              className={frameClasses}
              src={src}
              onLoad={handleDocumentLoad}
              sandbox="allow-downloads allow-forms allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-scripts"
            />
          </div>
        </div>
      )}
    </div>
  );
};

PreviewViewport.propTypes = {
  mode: PropTypes.oneOf(Object.values(MODES)).isRequired,
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onDocumentLoad: PropTypes.func,
};

PreviewViewport.defaultProps = {
  src: null,
  onDocumentLoad: Function.prototype,
};

export default PreviewViewport;
