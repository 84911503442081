import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@yola/ws-ui';
import Trigger from 'src/js/modules/common/components/trigger';
import withSelection from 'src/js/modules/utils/with-selection';
import useElementTypeSelect from '../helpers/use-element-type-select';
import restoreRange from '../helpers/restore-range';

const getCurrentAligh = (focusElements) => {
  if (!focusElements || !focusElements[0]) return 'left';
  return focusElements[0].style.getPropertyValue('text-align') || 'left';
};

function TextAlignTrigger({ elementId, triggerId }) {
  const { focusElements, selection } = useElementTypeSelect(elementId);
  const alignType = getCurrentAligh(focusElements);

  const handleClick = () => {
    restoreRange(selection, elementId);
  };

  return (
    <Trigger id={triggerId} active={false} onClick={handleClick}>
      <Icon glyph={`align-${alignType}`} strokeWidth="1.3" size="16" />
    </Trigger>
  );
}

TextAlignTrigger.propTypes = {
  elementId: PropTypes.string.isRequired,
  triggerId: PropTypes.string.isRequired,
};

export default withSelection(TextAlignTrigger);
