import { anodum, globals, hdrm, template } from '@yola/ws-sdk';
import optionTypes from '../../constants/display-options-type';
import getCaptions from './get-captions';

const createOptionalChildren = ({ blockNode, initialElement, item, index, onClick }) => {
  const isEnabled = hdrm.verifiers.areChildrenEnabledForNode(blockNode, item.querySelector);
  const isInitialEnabled = initialElement
    ? hdrm.verifiers.areChildrenEnabledForNode(initialElement, item.querySelector)
    : isEnabled;
  let optionalChildrenCaptions = {};
  let hasEnabledGlobals = false;

  if (isEnabled) {
    const optionalChildrenTarget = [...blockNode.querySelectorAll(item.querySelector)];
    const { GLOBAL_TAG_NAME } = globals.constants;

    hasEnabledGlobals = optionalChildrenTarget.some(
      (target) => anodum.isTag(target, GLOBAL_TAG_NAME) || target.querySelector(GLOBAL_TAG_NAME)
    );
  }

  if (index === 0) {
    const captions = getCaptions();

    optionalChildrenCaptions = {
      ...captions.optionalChildren,
    };
  }

  return {
    ...optionalChildrenCaptions,
    ...item,
    onClick,
    type: optionTypes.CHECKBOX,
    isInitialEnabled,
    isEnabled,
    hasEnabledGlobals,
    highlighted: false,
    label: template.accessors.getMetaTranslations(item.label),
  };
};

export default createOptionalChildren;
