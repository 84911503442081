import { assets } from '@yola/ws-sdk';
import showImageUploadFailDialog from './show-image-upload-fail-dialog';

const handleImageUploadFail = (images) => {
  const { constants } = assets;
  const { STATUSES, ASSET_DOWNLOAD_FAILED } = constants;

  const assetsDownloadFailed = images.some(
    (image) =>
      image.status === STATUSES.FAILED &&
      image.detail &&
      image.detail.code === ASSET_DOWNLOAD_FAILED
  );

  if (assetsDownloadFailed) {
    showImageUploadFailDialog();
  }
};

export default handleImageUploadFail;
