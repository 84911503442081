import { linkEditing, i18next } from '@yola/ws-sdk';

const { linkTypes } = linkEditing.constants;

const getHelperTextByLinkType = (linkType) => {
  if (!Object.values(linkTypes).includes(linkType)) {
    throw new Error('Please, pass correct linkType');
  }

  const texts = {
    [linkTypes.SOCIAL_MEDIA]: i18next.t('Add a link to your social profile here'),
    [linkTypes.WHATS_APP]: i18next.t('Add the WhatsApp click-to-chat link'),
  };

  return texts[linkType] || '';
};

export default getHelperTextByLinkType;
