import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { dialogs, site, i18next, i18n } from '@yola/ws-sdk';
import initializeDifmPromo from 'src/js/modules/difm-promo/operations/initialize-difm-promo';
import constants from '../constants';
import ai from '../../ai';
import segment from '../../analytics/segment';
import dialogTypes from '../../dialogs/constants/dialog-types';
import useMatchBreakpoint from '../../utils/custom-hooks/use-match-breakpoint';
import WizardIntroDialog from '../components/wizard-intro-dialog';
import saveSite from '../../saving/helpers/save-site';

const {
  trackers: { trackEvent },
  constants: { events },
} = segment;
const {
  siteProperties: { AI_SITE_WIZARD_PROPERTY_NAME, aiSiteWizardPropertyValues },
} = ai.constants;
const { languageCodesMap } = constants;

const DESKTOP_VIEW_BREAKPOINT = '576px';
const DEFAULT_LANGUAGE_CODE = 'en';

const getCaptions = () => ({
  title: (
    <>
      {i18next.t('From idea to reality.')}
      <br />
      {i18next.t('Build your site in 5 minutes.')}
    </>
  ),
  description: i18next.t('Let AI create a unique site for you, ready for your personal touch'),
  buttonLabel: i18next.t('Generate site with AI'),
});

const SiteWizardIntroContainer = ({ isReopening, onDialogCancel, onDialogMainAction }) => {
  const dispatch = useDispatch();
  const isDesktopView = useMatchBreakpoint(DESKTOP_VIEW_BREAKPOINT);
  const businessCategory = useSelector(site.selectors.getBusinessCategory);
  const siteProperties = useSelector(site.selectors.getSiteProperties);

  const captions = getCaptions();
  const locale = i18n.accessors.getLocale();
  const languageCode = languageCodesMap[locale] || DEFAULT_LANGUAGE_CODE;
  const imageSrc = useMemo(
    () =>
      isDesktopView
        ? `/images/ai-wizard/desktop/wizard-intro-${languageCode}.png`
        : `/images/ai-wizard/mobile/wizard-intro-${languageCode}.png`,
    [isDesktopView, languageCode]
  );

  const setIntroSiteWizardAsCompleted = () => {
    dispatch(
      site.actions.setSiteProperty(
        AI_SITE_WIZARD_PROPERTY_NAME,
        aiSiteWizardPropertyValues.COMPLETED
      )
    );
    saveSite();
    initializeDifmPromo();
  };

  const setIntroSiteWizardAsSkipped = () => {
    dispatch(
      site.actions.setSiteProperty(AI_SITE_WIZARD_PROPERTY_NAME, aiSiteWizardPropertyValues.SKIPPED)
    );
    saveSite();
    initializeDifmPromo();
  };

  const handleClose = () => {
    dialogs.operations.show(dialogTypes.SITE_WIZARD_CONFIRM_EXIT_DIALOG, {
      onDialogMainAction: () => {
        trackEvent(events.AI_SITE_WIZARD_DIALOG_CLOSED, { businessCategory });
        setIntroSiteWizardAsSkipped();
        onDialogCancel();
        dialogs.operations.hide();
      },
      onDialogCancel: () => {
        dialogs.operations.show(dialogTypes.SITE_WIZARD_INTRO_DIALOG, {
          isReopening: true,
        });
      },
    });
  };

  const handleSubmit = () => {
    onDialogMainAction();
    dialogs.operations.show(dialogTypes.SITE_WIZARD_SETUP_DIALOG, {
      onDialogMainAction: setIntroSiteWizardAsCompleted,
      onDialogCancel: setIntroSiteWizardAsSkipped,
    });
  };

  useEffect(() => {
    if (siteProperties[AI_SITE_WIZARD_PROPERTY_NAME] === aiSiteWizardPropertyValues.INITIATED) {
      dialogs.operations.show(dialogTypes.SITE_WIZARD_SETUP_DIALOG, {
        onDialogMainAction: setIntroSiteWizardAsCompleted,
        onDialogCancel: setIntroSiteWizardAsSkipped,
      });
      return;
    }

    if (isReopening) return;
    trackEvent(events.AI_SITE_WIZARD_DIALOG_DISPLAYED, { businessCategory });
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  return (
    <WizardIntroDialog
      captions={captions}
      imageSrc={imageSrc}
      isDesktopView={isDesktopView}
      initConfetti={!isReopening}
      onClose={handleClose}
      onSubmit={handleSubmit}
    />
  );
};

SiteWizardIntroContainer.propTypes = {
  isReopening: PropTypes.bool,
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

SiteWizardIntroContainer.defaultProps = {
  isReopening: false,
};

export default SiteWizardIntroContainer;
