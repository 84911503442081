import React from 'react';
import { Icon } from '@yola/ws-ui';
import { i18next, view } from '@yola/ws-sdk';
import Trigger from 'src/js/modules/common/components/trigger';
import { ALIGN_TRIGGER_ID } from '../constants/trigger-ids';

const getDirections = () => [
  {
    direction: 'left',
    values: ['left'],
    title: i18next.t('Align left'),
  },
  {
    direction: 'center',
    values: ['center'],
    title: i18next.t('Align center'),
  },
  {
    direction: 'right',
    values: ['right'],
    title: i18next.t('Align right'),
  },
];

const ALIGN_ATTRIBUTE = 'data-alignment';

const getAlignTriggers = ({ element, showHighlighter, hideHighlighter, afterTriggerClick }) => {
  const currentDirection = element.getAttribute(ALIGN_ATTRIBUTE);

  const handleClick = (direction) => {
    const elementId = view.accessors.getLiveElementId(element);

    if (currentDirection && direction === currentDirection) {
      view.operations.removeElementAttribute(elementId, ALIGN_ATTRIBUTE);
    } else {
      view.operations.setElementAttribute(elementId, ALIGN_ATTRIBUTE, direction);
    }

    afterTriggerClick();
  };

  return getDirections().map(({ direction, title, values }) => {
    const id = `${ALIGN_TRIGGER_ID}-${direction}`;

    return {
      id,
      trigger: (
        <Trigger id={id}>
          <Icon size="30" glyph={`move-${direction}`} strokeWidth="1.3" />
        </Trigger>
      ),
      active: values.includes(currentDirection),
      tooltip: title,
      priority: 150,
      title,
      element,
      onTriggerClick: () => handleClick(direction),
      onHover: () => showHighlighter(element),
      onMouseLeave: () => hideHighlighter(element),
    };
  });
};

export default getAlignTriggers;
