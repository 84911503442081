import { contentEditable } from '@yola/ws-sdk';

const { getSelectedElementsByTag } = contentEditable.helpers;

const cleanEmptyWrappers = (tagName) => {
  const selectedElements = getSelectedElementsByTag(tagName);

  selectedElements.forEach((element) => {
    if ((!element.children.length && !element.textContent) || element.textContent === '\n') {
      element.remove();
    }
  });
};

export default cleanEmptyWrappers;
