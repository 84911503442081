import React from 'react';
import PropTypes from 'prop-types';
import { i18next, hooks, dialogs } from '@yola/ws-sdk';
import { ProgressDialog } from '@yola/ws-ui';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import getGalleryItemAreaBounds from '../helpers/get-gallery-item-area-bounds';
import buildGalleryElements from '../helpers/build-gallery-elements';
import saveAssets from '../helpers/save-assets';
import getGalleryElementByIndex from '../helpers/get-gallery-element-by-index';

const { ON_AFTER_VIEW_SCROLLER_INITIALIZED } = hooks.extensionHooks;

class SavingImageProgressDialog extends React.Component {
  constructor(props) {
    super(props);
    const { items } = props;

    this.state = {
      savedImagesCount: 0,
    };

    this.userUploadedItems = items.filter((item) => Boolean(item.file));
    this.abortRequestController = new AbortController();
    this.isRequestCancelled = false;
    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidMount() {
    const { elementId, items, onAfterSaved, indexOfTargetItem } = this.props;
    const galleryItemAreaBounds = getGalleryItemAreaBounds(elementId);

    saveAssets({
      items,
      galleryItemAreaBounds,
      abortRequestController: this.abortRequestController,
      onImageLoadProgress: () => {
        if (this.isRequestCancelled) return;
        this.setState((state) => ({
          savedImagesCount: state.savedImagesCount + 1,
        }));
      },
    }).then(({ savedItems, failedFilesNames }) => {
      if (this.isRequestCancelled) return;

      buildGalleryElements(elementId, savedItems);

      if (failedFilesNames.length) {
        dialogs.operations.show(dialogTypes.GALLERY_SAVING_IMAGE_ERROR_DIALOG, {
          failedFilesNames,
        });
        return;
      }

      dialogs.operations.hide();

      if (indexOfTargetItem >= 0) {
        hooks.operations.subscribe(
          ON_AFTER_VIEW_SCROLLER_INITIALIZED,
          () => {
            const element = getGalleryElementByIndex(elementId, indexOfTargetItem);
            if (element) {
              onAfterSaved(element);
            }
          },
          { once: true }
        );
      }
    });
  }

  handleCancel(...args) {
    const { onCancel, items, elementId, onDialogCancel } = this.props;

    onDialogCancel();

    if (onCancel) {
      onCancel(...args);
      return;
    }

    this.isRequestCancelled = true;
    this.abortRequestController.abort();
    dialogs.operations.show(dialogTypes.GALLERY_DIALOG, {
      items,
      elementId,
    });
  }

  render() {
    const { captions, items, elementId, onCancel, ...restProps } = this.props;
    const { savedImagesCount } = this.state;
    const progress = (savedImagesCount / this.userUploadedItems.length) * 100;

    const defaultCaptions = {
      title: i18next.t('{progress}/{total} images uploaded', {
        total: this.userUploadedItems.length,
        progress: savedImagesCount,
      }),
      description: i18next.t('Your uploads will be complete momentarily'),
      cancel: i18next.t('Cancel'),
      ...captions,
    };

    return (
      <ProgressDialog
        captions={defaultCaptions}
        value={progress}
        cancelable
        overlay="visible"
        containerClassName="ws-loading-progress-dialog ws-dialog-fade"
        onCancel={this.handleCancel}
        {...restProps}
      />
    );
  }
}

SavingImageProgressDialog.propTypes = {
  onCancel: PropTypes.func,
  captions: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  items: PropTypes.arrayOf(PropTypes.shape()),
  elementId: PropTypes.string.isRequired,
  onAfterSaved: PropTypes.func,
  indexOfTargetItem: PropTypes.number,
  onDialogCancel: PropTypes.func.isRequired,
};

SavingImageProgressDialog.defaultProps = {
  captions: {},
  items: [],
  onCancel: null,
  onAfterSaved: () => {},
  indexOfTargetItem: -1,
};

export default SavingImageProgressDialog;
