export const editRedirect = (redirects, index, redirect) => {
  const copyRedirects = [...redirects];
  copyRedirects.splice(index, 1, redirect);

  return copyRedirects;
};

export const deleteRedirect = (redirects, index) => {
  const copyRedirects = [...redirects];
  copyRedirects.splice(index, 1);

  return copyRedirects;
};

export const createRedirect = (redirects, redirect) => {
  const copyRedirects = [...redirects];
  copyRedirects.splice(0, 0, redirect);

  return copyRedirects;
};
