import React from 'react';
import { i18next, utils } from '@yola/ws-sdk';
import { Text } from '@yola/ws-ui';
import PropTypes from 'prop-types';

const BlockLayoutDialogDescription = ({ handleLinkClick }) => (
  <div className="ws-block-layout-settings__description">
    <Text type="annotation">
      {i18next.t('Customize the block design by trying a different block layout. Go to')}
      &nbsp;
      <span onClick={handleLinkClick} className="ws-switch-template-link">
        {i18next.t('Design')} &#x2192; {i18next.t('Switch template')}
      </span>
      &nbsp;
      {i18next.t('if you want to try a completely different template')}
    </Text>
  </div>
);

BlockLayoutDialogDescription.propTypes = {
  handleLinkClick: PropTypes.func,
};

BlockLayoutDialogDescription.defaultProps = {
  handleLinkClick: utils.noop,
};

export default BlockLayoutDialogDescription;
