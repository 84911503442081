import control from './controls/control';
import constants from './constants';

const extension = {
  slug: constants.common.slug,
  title: 'Online Store Extension',
  description: 'Extension to work with Online Store',
  defaults: {
    querySelector: 'ws-online-store',
  },
  ui: {
    control: [control],
  },
};

export default extension;
