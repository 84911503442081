import {
  PROHIBITED_HTML_TAGS_REGEXP,
  PROHIBITED_HTML_TAGS_ERROR_MESSAGE,
} from '../constants/code-editor-validation';

const validateProhibitedHTMLTags = ({ value, session }) => {
  const hasProhibitedHtmlTagsMatch = [];

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const match = PROHIBITED_HTML_TAGS_REGEXP.exec(value);

    if (!match) {
      break;
    }

    hasProhibitedHtmlTagsMatch.push(match);
  }

  return hasProhibitedHtmlTagsMatch.map((match) => {
    const editorPosition = session.doc.indexToPosition(match.index);

    return {
      ...editorPosition,
      type: 'error',
      text: PROHIBITED_HTML_TAGS_ERROR_MESSAGE,
    };
  });
};

export default validateProhibitedHTMLTags;
