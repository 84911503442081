import React from 'react';
import PropTypes from 'prop-types';
import { Input } from '@yola/ws-ui';

function NpsFeedback({ onFeedbackChange, captions }) {
  return (
    <div className="nps-feedback">
      <span className="nps-feedback__label">{captions.label}</span>
      <Input
        type="textarea"
        placeholder={captions.placeholder}
        onChange={(event) => onFeedbackChange(event.target.value)}
      />
    </div>
  );
}

NpsFeedback.propTypes = {
  onFeedbackChange: PropTypes.func.isRequired,
  captions: PropTypes.shape({
    label: PropTypes.string,
    placeholder: PropTypes.string,
  }),
};

NpsFeedback.defaultProps = {
  captions: {},
};

export default NpsFeedback;
