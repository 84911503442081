import { globals } from '@yola/ws-sdk';
import store from 'src/js/store';
import { NAVIGATION_GLOBAL_ID } from '../constants/common';

const isNavigationGlobalElement = (element) => {
  if (!element.matches(globals.constants.GLOBAL_TAG_NAME)) {
    return false;
  }

  const state = store.getState();

  const source = element.getAttribute('src');
  const { id } = globals.selectors.getGlobalBySource(state, source);

  return id === NAVIGATION_GLOBAL_ID;
};

export default isNavigationGlobalElement;
