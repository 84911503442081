import get from 'lodash.get';
import { constants } from '@yola/subscription-manager-js';

const {
  hostingPackageFeatureNames: { CUSTOM_CODE },
} = constants;

const customCodeLimit = ({ targetPackage }) => ({
  available: get(targetPackage, ['features', CUSTOM_CODE], false),
});

export default customCodeLimit;
