const REACHED_TECH_LIMIT = 'Technical limit is reached';

export default {
  pages: {
    captions: {
      title: REACHED_TECH_LIMIT,
      description:
        'You have reached your page limit. Please contact customer support for assistance.',
      dismiss: 'ok',
    },
  },
  uploadFileSize: {
    captions: {
      title: REACHED_TECH_LIMIT,
      description:
        'You have reached your upload file size limit. Please contact customer support for assistance.',
      dismiss: 'ok',
    },
  },
};
