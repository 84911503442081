import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';

const { ActionButton, InputGroupField } = designSystem;

const InputGroupWithButtonField = ({ input, button }) => (
  <div className="ws-input-group-with-button-field">
    <InputGroupField {...input} />
    <ActionButton {...button} />
  </div>
);

InputGroupWithButtonField.propTypes = {
  input: PropTypes.shape({}).isRequired,
  button: PropTypes.shape({}).isRequired,
};

export default InputGroupWithButtonField;
