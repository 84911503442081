import get from 'lodash.get';
import { config } from '@yola/ws-sdk';

const getContactFormSubmissionUrl = (rootState) => {
  const cfg = config.selectors.getConfig(rootState);

  return get(cfg, 'ws-editor.contact_form_submission_url');
};

export default getContactFormSubmissionUrl;
