import { constraints } from '@yola/ws-sdk';
import getInnerDropTargetElementAndPosition from './get-inner-drop-target-element-and-position';
import getOuterDropTargetElement from './get-outer-drop-target-element';
import getOuterAdjacentPosition from './get-outer-adjacent-position';

const getDropTargetElementAndPosition = (draggedElement, hoverTargetElement, clientX, clientY) => {
  let dropTargetElement;
  let position;

  if (
    hoverTargetElement &&
    !hoverTargetElement.hasChildNodes() &&
    constraints.verifiers.canBeNested(draggedElement, hoverTargetElement)
  ) {
    ({ dropTargetElement, position } = getInnerDropTargetElementAndPosition(
      draggedElement,
      hoverTargetElement,
      clientX,
      clientY
    ));
  } else {
    dropTargetElement = getOuterDropTargetElement(draggedElement, hoverTargetElement);
    position = getOuterAdjacentPosition(dropTargetElement, clientX, clientY);
  }

  return {
    dropTargetElement,
    position,
  };
};

export default getDropTargetElementAndPosition;
