const getTitleFromHref = (href) => {
  if (href.trim() === '#') return '/#';

  const hrefPartsArray = href.split('/');
  const title = hrefPartsArray.pop();

  return `/${title || hrefPartsArray.pop()}`;
};

export default getTitleFromHref;
