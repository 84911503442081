import { anodum } from '@yola/ws-sdk';

const getNextSiblingPath = (element) => {
  const elementPath = anodum.getTreePathOfNode(element);

  elementPath[elementPath.length - 1] += 1;

  return elementPath;
};

export default getNextSiblingPath;
