import { useEffect } from 'react';
import { view } from '@yola/ws-sdk';
import { DATA_SITE_PROGRESS_ATTRIBUTE } from '../constants';

const useSiteProgressDataAttribute = (isViewLoaded) => {
  useEffect(() => {
    if (isViewLoaded) {
      const liveDom = view.accessors.getLiveDocument();
      if (!liveDom.body.hasAttribute(DATA_SITE_PROGRESS_ATTRIBUTE)) {
        liveDom.body.setAttribute(DATA_SITE_PROGRESS_ATTRIBUTE, true);
      }
    }
  }, [isViewLoaded]);
};

export default useSiteProgressDataAttribute;
