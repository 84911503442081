const handleBackgroundOptions = (
  destinationBlockNode,
  backgroundOptions,
  cachedBackgroundOptions
) => {
  const destinationBackgroundOptions = backgroundOptions || cachedBackgroundOptions;
  if (!destinationBackgroundOptions) return;

  const [backgroundImageOption] = destinationBackgroundOptions;
  const { attributesMap } = backgroundImageOption;

  Object.keys(backgroundImageOption.attributesMap).forEach((attribute) => {
    if (!destinationBlockNode.getAttribute(attribute)) return;
    destinationBlockNode.setAttribute(attribute, backgroundImageOption[attributesMap[attribute]]);
  });
};

export default handleBackgroundOptions;
