import { textFormatting, anodum } from '@yola/ws-sdk';

const { isNonEmptyTextNode } = anodum;

const isSelectionWrappedIn = (selector) => {
  const selection = textFormatting.accessors.getAdvancedSelection();
  const range = selection.getRangeAt(0);
  const selectedTextNodes = range
    .getNodes([Node.TEXT_NODE])
    .filter((textNode) => isNonEmptyTextNode(textNode));

  return (
    !!selectedTextNodes.length &&
    selectedTextNodes.every((textNode) => textNode.parentElement.closest(selector))
  );
};

export default isSelectionWrappedIn;
