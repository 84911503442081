import React from 'react';
import PropTypes from 'prop-types';
import { dialogs } from '@yola/ws-sdk';
import ImageSizeErrorDialog from 'src/js/modules/common/components/image-size-error-dialog';

function PhotoSizeErrorDialog(props) {
  const { onDialogCancel, preserveSharedData, ...otherProps } = props;

  const handleDismiss = () => {
    onDialogCancel();
    dialogs.operations.hide({ preserveSharedData });
  };

  return <ImageSizeErrorDialog onDismiss={handleDismiss} {...otherProps} />;
}

PhotoSizeErrorDialog.propTypes = {
  // Passed from ws-editor's `dialogs-container`:
  onDialogCancel: PropTypes.func.isRequired,
  preserveSharedData: PropTypes.bool,
};

PhotoSizeErrorDialog.defaultProps = {
  preserveSharedData: false,
};

export default PhotoSizeErrorDialog;
