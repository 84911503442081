import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { designSystem, reactHookForm } from '@yola/ws-ui';
import LogoPlaceholder from '../logo-placeholder';

const { FilePicker, Stack, ControlGroup } = designSystem;
const { useController } = reactHookForm;

const getUrlFromFile = (file) => (file ? URL.createObjectURL(file) : '');

const LogoPickerField = (props) => {
  const { name, control, rules, label, placeholder } = props;
  const { field, fieldState } = useController({
    name,
    control,
    rules,
  });

  const [previewUrl, setPreviewUrl] = useState(getUrlFromFile(field.value));

  const handleChange = useCallback(
    (filesList = [null]) => {
      const file = filesList[0];

      field.onChange(file);
      field.onBlur();
    },
    [field]
  );

  useEffect(() => {
    if (fieldState.invalid) {
      setPreviewUrl('');
    } else {
      setPreviewUrl(getUrlFromFile(field.value));
    }
  }, [field.value, fieldState.invalid]);

  return (
    <div className="ws-logo-picker-field">
      <ControlGroup title={label}>
        <Stack gap="spacing-2-xs">
          <div className="ws-logo-picker-field__preview-container">
            {previewUrl ? (
              <img src={previewUrl} className="ws-logo-picker-field__preview" alt="" />
            ) : (
              <LogoPlaceholder />
            )}
          </div>
          <FilePicker
            label={label}
            placeholder={placeholder}
            size="medium"
            fileItemName={field?.value?.name}
            onChange={handleChange}
            onClear={handleChange}
            invalid={fieldState.invalid}
            statusText={fieldState.error?.message}
          />
        </Stack>
      </ControlGroup>
    </div>
  );
};

LogoPickerField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  control: PropTypes.shape({}).isRequired,
  rules: PropTypes.shape({}),
  placeholder: PropTypes.string,
};

LogoPickerField.defaultProps = {
  rules: {},
  placeholder: '',
};

export default LogoPickerField;
