import getComputedStyleForNode from './get-css-computed-style-for-node';

const isEmptyBackground = (value) => value === 'none' || value === 'rgba(0, 0, 0, 0)';

const nodeHasBackground = (node) => {
  const backgroundColor = getComputedStyleForNode(node.parentElement, 'backgroundColor');
  const backgroundImage = getComputedStyleForNode(node.parentElement, 'backgroundImage');
  return !isEmptyBackground(backgroundColor) || !isEmptyBackground(backgroundImage);
};

export default nodeHasBackground;
