export default {
  IMPROVE: 'improve',
  FIX_GRAMMAR: 'fix_grammar',
  MAKE_LONGER: 'make_longer',
  MAKE_SHORTER: 'make_shorter',
};

export const textAssistantInstructionSuggestions = {
  WRITE_ABOUT: 'write-about',
  ADD_THAT: 'add-that',
  TRANSLATE_TO: 'translate-to',
  MENTION_THAT: 'mention-that',
  CHANGE_TONE_TO: 'change-tone-to',
};

export const textAssistantInstructionTypes = {
  USER_INPUT: 'user-input',
  USER_INPUT_WITH_SUGGESTION: 'user-input-with-suggestion',
  PREDEFINED_PROMPT: 'predefined-prompt',
};
