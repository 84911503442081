import { extensions } from '@yola/ws-sdk';
import setDefaultAttributes from './set-default-attributes';
import constants from '../constants/common';

const beforeElementInsert = (element) => {
  const settings = extensions.accessors.getExtensionSettings(constants.slug);
  if (!element || !element.querySelector(settings.querySelector)) {
    return undefined;
  }

  return setDefaultAttributes(element);
};

export default beforeElementInsert;
