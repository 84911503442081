import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { dialogs, saving, integration, i18next } from '@yola/ws-sdk';
import { ProgressDialog, SubmitDialog } from '@yola/ws-ui';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import migrations from 'src/js/modules/migrations';
import { BACKUP_HAS_PENDING_MIGRATIONS } from 'src/js/modules/migrations/constants';
import { saveSiteWithEffect } from 'src/js/modules/saving/helpers/save-site';

function RestoreSiteBackup({ backupId, onCancel, onDialogMainAction, onDialogCancel }) {
  const integrationSettings = useSelector(integration.selectors.getSettings);
  const dispatch = useDispatch();
  const [isPending, setIsPending] = useState(false);

  const captions = {
    title: i18next.t('Restore backup?'),
    description: i18next.t(
      'Please note that restoring your site version will restore your entire site to the version that you have selected. This will include the site and page custom code fields. You will need to reinstall any third-party integrations that you may have installed, such as Google Analytics, if this is missing from the version that you are restoring to.'
    ),
    submit: i18next.t('Submit'),
    cancel: i18next.t('Cancel'),
  };

  const handleSuccess = () => {
    integrationSettings.onReloadEditor();
  };

  const handleCancel = () => {
    onDialogCancel();
    onCancel();
  };

  const handleError = (error) => {
    const showErrorDialog = () => {
      dialogs.operations.show(dialogTypes.SITE_BACKUP_ERROR, {
        captions: {
          title: i18next.t('Site backup restore failed'),
          description: i18next.t('An error occurred while restoring your site backup'),
        },
      });
    };

    const {
      status,
      data: { code },
    } = error.response;

    if (status === 400 && code === BACKUP_HAS_PENDING_MIGRATIONS) {
      migrations.executers
        .runSiteBackupMigration(backupId)
        .then(() => {
          dispatch(saving.thunks.restoreSiteBackup(backupId))
            .then(handleSuccess)
            .catch(handleError);
        })
        .catch(showErrorDialog);
      return;
    }
    showErrorDialog();
  };

  const restoreBackup = () => {
    onDialogMainAction();
    setIsPending(true);

    saveSiteWithEffect(() =>
      dispatch(saving.thunks.restoreSiteBackup(backupId)).then(handleSuccess).catch(handleError)
    );
  };

  const pendingProps = {
    className: 'ws-site-backup-pending-dialog',
    overlay: 'visible',
    cancelable: false,
    progressType: 'indeterminate',
    width: 290,
    height: 108,
    captions: {
      title: `${i18next.t('Restoring site backup...')}`,
    },
  };

  return (
    <React.Fragment>
      {isPending && <ProgressDialog {...pendingProps} />}

      {!isPending && (
        <SubmitDialog
          onSubmit={restoreBackup}
          onCancel={handleCancel}
          captions={captions}
          height="auto"
        />
      )}
    </React.Fragment>
  );
}

RestoreSiteBackup.propTypes = {
  backupId: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

RestoreSiteBackup.defaultProps = {
  onCancel: null,
};

export default RestoreSiteBackup;
