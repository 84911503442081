import React from 'react';
import { i18next, extensions } from '@yola/ws-sdk';
import TextAlignTrigger from '../components/text-align-trigger';
import TextAlignActionTrigger from '../components/text-align-action-trigger';
import textAlignMatches from '../helpers/text-align-matches';

const { controlTypes } = extensions;
const triggerId = 'text-align';

const TEXT_ALIGN_OPTIONS = [
  {
    id: 'text-align-left',
    alignType: 'left',
    matchProperties: ['left', 'start'],
  },
  {
    id: 'text-align-center',
    alignType: 'center',
    matchProperties: ['center'],
  },
  {
    id: 'text-align-right',
    alignType: 'right',
    matchProperties: ['right', 'end'],
  },
];

const alignControl = {
  id: triggerId,
  trigger: <TextAlignTrigger triggerId={triggerId} />,
  header: TEXT_ALIGN_OPTIONS.map(({ id, alignType, matchProperties }) => (
    <TextAlignActionTrigger
      triggerId={id}
      matchProperties={matchProperties}
      alignType={alignType}
    />
  )),
  get tooltip() {
    return i18next.t('Align text');
  },
  priority: 60,
  for: [controlTypes.COLLAPSED_TEXT, controlTypes.TEXT],
  highlightElement: false,
  matches: textAlignMatches,
  closeIcon: 'submit',
};

export default alignControl;
