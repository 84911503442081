import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';
import { HSLColor, contrastRatio } from '@yola/yola-palette-generator';
import { customization, dialogs, i18next } from '@yola/ws-sdk';
import isDeveloperMode from 'yola-editor/src/js/modules/user/accessors/is-developer-mode';
import parseHslColorString from 'src/js/modules/website-design/helpers/parse-hsl-color-string';
import useFeatureFlags from 'yola-editor/src/js/modules/feature-flags/hooks/use-feature-flags';
import AddCustomPaletteDialog from '../components/add-custom-palette-dialog';
import dialogTypes from '../../dialogs/constants/dialog-types';
import dialogCallSourceIds from '../constant/dialog-call-source-ids';
import advancedVariableCategoryIds from '../constant/advanced-variable-category-ids';
import advancedVariableIds from '../constant/advanced-variable-ids';
import helpers from '../helpers';
import useInjectPaletteToTemplate from '../hooks/use-inject-palette-to-template';
import triggerIds from '../constant/trigger-ids';
import segment from '../../analytics/segment';
import { accentColorsKeyMap } from '../constant/colors-maps';

const LIGHT_THEME_CR = 3;

const {
  trackers: { trackEvent },
  constants: { events },
} = segment;
const { generateCustomPaletteId, getInitialGeneralColor } = helpers;

const getCaptions = (isACCAvailable) => ({
  title: i18next.t('Color palette settings'),
  description: isACCAvailable
    ? i18next.t(
        'Manage the set of color schemes available for blocks of your site. You can apply individual color scheme to a block by using the Block settings. Changes you make here may affect all blocks of your site'
      )
    : i18next.t(
        'Choose light or dark backgrounds to give your site a welcoming feel, then highlight buttons with your primary color, and accent links and icons with the secondary color for a touch of finesse'
      ),
  buttons: {
    cancel: i18next.t('Cancel'),
    submit: i18next.t('Submit'),
  },
  colors: {
    altLight: isACCAvailable ? i18next.t('Light color scheme') : i18next.t('Light color'),
    light: isACCAvailable ? i18next.t('Light-grey color scheme') : i18next.t('Light-grey color'),
    dark: isACCAvailable ? i18next.t('Dark color scheme') : i18next.t('Dark color'),
    altDark: isACCAvailable ? i18next.t('Dark-grey color scheme') : i18next.t('Dark-grey color'),
    primary: isACCAvailable ? i18next.t('Primary color scheme') : i18next.t('Primary color'),
    secondary: isACCAvailable ? i18next.t('Secondary color scheme') : i18next.t('Secondary color'),
  },
  resetDialog: {
    title: i18next.t('Confirm color reset'),
    description: i18next.t(
      "All your color adjustments will be reset to the default color palette. This action can't be undone."
    ),
    submit: i18next.t('Confirm'),
    cancel: i18next.t('Cancel'),
  },
});

function CustomPaletteContainer(props) {
  const dispatch = useDispatch();
  const {
    getSharedData,
    onDialogMainAction,
    onDialogCancel,
    resolveSharedData,
    triggerId,
    colorPreset,
  } = props;
  const {
    offsetX,
    offsetY,
    originPalette,
    editPalette,
    activePalette,
    isEdit,
    customColorPalettes,
    activeTabId,
  } = getSharedData();

  const [dialogPosition, updatePosition] = useState({
    offsetX,
    offsetY,
  });
  const parentPalette = useSelector((state) =>
    customization.selectors.getColorPalette(state, originPalette.parentId || editPalette?.parentId)
  );
  const [{ advanced_color_customization: isAdvancedColorCustomizationAvailable }] = useFeatureFlags(
    ['advanced_color_customization']
  );
  const isDeveloperModeEnabled = isDeveloperMode();

  const captions = getCaptions(isAdvancedColorCustomizationAvailable);

  const isPaletteChanged = () => {
    const colorPalette = editPalette || originPalette;

    if (colorPalette.advancedColors && Object.keys(colorPalette.advancedColors).length) {
      return true;
    }
    return !isEqual(colorPalette.colors, parentPalette?.colors);
  };

  const onClose = () =>
    dispatch(
      dialogs.actions.show(dialogTypes.WEBSITE_DESIGN, {
        sourceId: dialogCallSourceIds.BACK_FROM_CUSTOM_PALETTE,
        activeTabId,
        onDialogCancel: dialogs.operations.hide,
        onDialogMainAction: dialogs.operations.hide,
      })
    );

  const updateSharedData = (customData) => {
    resolveSharedData({
      offsetX: dialogPosition.offsetX,
      offsetY: dialogPosition.offsetY,
      ...customData,
    });
  };

  const handleDragEnd = (_, { x, y }) => {
    updatePosition({
      offsetX: x,
      offsetY: y,
    });
  };

  const injectPaletteToTemplate = useInjectPaletteToTemplate();

  const showCustomPaletteContainer = () =>
    dialogs.operations.show(dialogTypes.WEBSITE_DESIGN_CUSTOM_PALETTE, { triggerId, colorPreset });

  const getAdjustedColors = (oldPalette, newPalette) =>
    Object.keys(oldPalette.colors).filter(
      (colorKey) => oldPalette.colors[colorKey] !== newPalette.colors[colorKey]
    );

  const updateColorPalette = (paletteToUpdate) => {
    const filteredState = customColorPalettes.filter(({ id }) => id !== paletteToUpdate.id);
    return [...filteredState, paletteToUpdate];
  };

  const addColorPalette = (newPalette) => [...customColorPalettes, newPalette];

  const genNewPaletteId = () => {
    const palettesIds = customColorPalettes.map(({ id }) => id);

    return generateCustomPaletteId(palettesIds);
  };

  const getEditPalette = () => {
    const palette = editPalette || { ...originPalette };

    if (!isEdit && palette.id === originPalette.id) {
      palette.id = genNewPaletteId();
      palette.parentId = originPalette.id;
    }

    Object.values(accentColorsKeyMap).forEach((colorKey) => {
      if (palette.colors[colorKey]) return;
      palette.colors[colorKey] = getInitialGeneralColor(colorKey, palette.colors);
    });

    return palette;
  };

  const currentEditPalette = getEditPalette();

  const handleSubmit = ({ isClickOnOverlay } = {}) => {
    const newPalette = isEdit
      ? updateColorPalette(currentEditPalette)
      : addColorPalette(currentEditPalette);

    updateSharedData({
      activeColorPaletteId: currentEditPalette.id,
      updatedPalettes: newPalette,
      editPalette: null,
    });

    onDialogMainAction({
      triggerId: isClickOnOverlay ? 'dialog-background' : 'submit-button',
      colorsAdjusted: getAdjustedColors(originPalette, currentEditPalette),
    });
    onClose();
  };

  const handleCancel = () => {
    injectPaletteToTemplate(activePalette);

    updateSharedData({
      activeColorPaletteId: activePalette.id,
      editPalette: null,
    });
    onDialogCancel();
    onClose();
  };

  const onReset = () => {
    trackEvent(events.DESIGN_SETTINGS_RESET_TRIGGER_CLICKED, {
      dialogId: dialogTypes.WEBSITE_DESIGN_CUSTOM_PALETTE,
      triggerId: triggerIds.COLOR_PALETTE_SETTINGS,
      colorsAdjusted: getAdjustedColors(parentPalette, currentEditPalette),
    });

    dialogs.operations.show(dialogTypes.CONFIRM_DIALOG, {
      onCancel: showCustomPaletteContainer,
      onSubmit: () => {
        const colorPalette = {
          ...parentPalette,
          id: currentEditPalette.id,
          parentId: currentEditPalette.parentId,
        };

        injectPaletteToTemplate(colorPalette);
        updateSharedData({
          editPalette: colorPalette,
        });
        showCustomPaletteContainer();
      },
      captions: captions.resetDialog,
    });
  };

  const onOverlayClick = () => {
    handleSubmit({ isClickOnOverlay: true });
  };

  const handleColorSelect = ({ colorType, colorKey, isDevMode = false }) => {
    updateSharedData({ editPalette: currentEditPalette, isDeveloperMode: isDevMode });

    if (isAdvancedColorCustomizationAvailable || isDevMode) {
      dispatch(
        dialogs.actions.show(dialogTypes.COLOR_SCHEME_SETTINGS_DIALOG, { colorType, colorKey })
      );

      return;
    }
    dispatch(
      dialogs.actions.show(dialogTypes.WEBSITE_DESIGN_COLOR_SETTINGS, {
        colorType,
        colorKey,
        variableId: advancedVariableIds.BACKGROUND_COLOR,
        variableCategory: advancedVariableCategoryIds.GENERAL,
      })
    );
  };

  const onDeveloperModeClick = (colorProps) =>
    handleColorSelect({ ...colorProps, isDevMode: true });

  const {
    colors,
    variables: { wsColorTextWhite },
  } = currentEditPalette;

  const colorSchemes = useMemo(() => {
    const whiteHSL = new HSLColor(
      ...Object.values(parseHslColorString(`hsl(${wsColorTextWhite})`))
    );

    return Object.keys(colors).reduce((acc, colorKey) => {
      const colorValue = colors[colorKey];

      const colorValueHSL = new HSLColor(...Object.values(parseHslColorString(colorValue)));
      const isDarkTheme = contrastRatio(colorValueHSL, whiteHSL) >= LIGHT_THEME_CR;

      return {
        ...acc,
        [colorKey]: {
          value: colorValue,
          isDarkTheme,
        },
      };
    }, {});
  }, [colors, wsColorTextWhite]);

  return (
    <AddCustomPaletteDialog
      offsetX={dialogPosition.offsetX}
      offsetY={dialogPosition.offsetY}
      captions={captions}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
      colorSchemes={colorSchemes}
      onColorSelect={handleColorSelect}
      onDragEnd={handleDragEnd}
      onOverlayClick={onOverlayClick}
      onReset={onReset}
      showReset={Boolean(editPalette?.parentId || originPalette.parentId)}
      isResetDisabled={!isPaletteChanged()}
      isDeveloperModeEnabled={isDeveloperModeEnabled}
      onDeveloperModeClick={onDeveloperModeClick}
    />
  );
}

CustomPaletteContainer.propTypes = {
  getSharedData: PropTypes.func.isRequired,
  resolveSharedData: PropTypes.func.isRequired,
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
  triggerId: PropTypes.string.isRequired,
  colorPreset: PropTypes.string.isRequired,
};

export default CustomPaletteContainer;
