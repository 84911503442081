import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import ProgressIndication from './progress-indicator';

const { Heading, ActionButton, Paragraph, Box } = designSystem;

function PageLimitBanner(props) {
  const { current, total, onButtonClick, captions } = props;

  return (
    <div className="ws-page-limit-banner">
      <div className="ws-page-limit-banner__container">
        <div className="ws-page-limit-banner__info">
          <Heading type="heading-6" singleLine>
            {captions.title}
          </Heading>
          <Paragraph appearance="medium-emphasis">
            {current}/{total}
          </Paragraph>
        </div>

        <div className="ws-page-limit-banner__button-container">
          <ActionButton
            format="text"
            appearance="cta"
            onClick={onButtonClick}
            label={captions.buttonText}
          />
        </div>
      </div>
      <Box marginTop="spacing-3-xs">
        <ProgressIndication total={total} current={current} />
      </Box>
    </div>
  );
}

PageLimitBanner.propTypes = {
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  captions: PropTypes.shape({
    title: PropTypes.string,
    buttonText: PropTypes.string,
  }).isRequired,
};

export default PageLimitBanner;
