import store from 'src/js/store';
import selectors from '../selectors';

const getGoogleMapsEmbedApiKey = () => {
  const state = store.getState();

  return selectors.getGoogleMapsEmbedApiKey(state);
};

export default getGoogleMapsEmbedApiKey;
