import React from 'react';
import urlJoin from 'url-join';
import { view, dialogs, extensions, utils, i18next, site } from '@yola/ws-sdk';
import { Icon } from '@yola/ws-ui';
import { HSLColor, converters, contrastRatio } from '@yola/yola-palette-generator';
import trackers from 'src/js/modules/analytics/segment/trackers';
import Trigger from '../../../../common/components/trigger';
import constants from '../constants/common';
import copyExtensionAsset from '../helpers/copy-extension-asset';
import dialogTypes from '../../../../dialogs/constants/dialog-types';
import getCssPropertyByNode from '../../../../design-automation/handlers/overlay/helpers/get-css-property-by-node';
import parseHslColorString from '../../../../website-design/helpers/parse-hsl-color-string';

const { hsl2hex } = converters;

const {
  trackSelectIconSearchClicked,
  trackSelectIconSearchPerformed,
  trackSelectIconIconClicked,
  trackSelectIconDialogSubmitted,
  trackSelectIconDialogCanceled,
} = trackers;

const state = {
  isSelectionCancelled: false,
  iconComponent: {
    node: null,
    id: null,
  },
  icon: {
    src: null,
  },
  originalIcon: {
    src: null,
  },
};

const saveOriginalIcon = (elementId) => {
  const iconComponentNode = view.accessors.getLiveElement(elementId);

  state.iconComponent.node = iconComponentNode;
  state.originalIcon.src = iconComponentNode.getAttribute('src');
  state.originalIcon.dataId = iconComponentNode.getAttribute(constants.ICON_ID_ATTRIBUTE);
};

const hideDialog = () => {
  dialogs.operations.hide();
  state.icon.src = null;
  state.originalIcon.src = null;
  state.originalIcon.dataId = null;
};

const getIconColorHex = (elementId) => {
  const iconComponentNode = view.accessors.getLiveElement(elementId);
  const iconColor = getCssPropertyByNode(iconComponentNode, '--ws-icon-color');
  if (!iconColor) return undefined;
  const { h, s, l } = parseHslColorString(`hsl(${iconColor})`);
  const iconHSLColor = new HSLColor(h, s, l);
  const dialogBackgroundColor = new HSLColor(0, 0, 1); // dialog has white background
  let ratio = contrastRatio(iconHSLColor, dialogBackgroundColor);

  while (ratio < constants.MIN_ICON_DIALOG_CONTRAST_RATIO) {
    if (iconHSLColor.getLightness() <= 0) {
      break;
    }

    const iconLightness = ((iconHSLColor.getLightness() - 0.01) * 100).toFixed() / 100;
    iconHSLColor.setLightness(iconLightness);
    ratio = contrastRatio(iconHSLColor, dialogBackgroundColor);
  }

  return hsl2hex(iconHSLColor);
};

const insertOriginalIcon = () => {
  const iconComponentNode = state.iconComponent.node;
  const originalIconSrc = state.originalIcon.src;
  const originalIconDataId = state.originalIcon.dataId;

  iconComponentNode.setAttribute('src', originalIconSrc);
  if (originalIconDataId) {
    iconComponentNode.setAttribute(constants.ICON_ID_ATTRIBUTE, originalIconDataId);
  } else {
    iconComponentNode.removeAttribute(constants.ICON_ID_ATTRIBUTE);
  }
};

const showSelectDialog = (elementId) => {
  const targetElement = view.accessors.getLiveElement(elementId);

  const { offsetX, offsetY } = dialogs.helpers.getPositionByElement(
    targetElement,
    constants.DIALOG_MIN_WIDTH,
    constants.DIALOG_HEIGHT
  );

  const handleSearchClick = ({ tabId, premiumAvailable }) => {
    trackSelectIconSearchClicked({
      dialogId: dialogTypes.SELECT_ICON,
      targetElementId: elementId,
      tabId,
      premiumAvailable,
    });
  };

  const handleSearchPerform = ({ searchTerm, searchResults, tabId }) => {
    trackSelectIconSearchPerformed({
      dialogId: dialogTypes.SELECT_ICON,
      targetElementId: elementId,
      searchTerm,
      searchResults,
      tabId,
    });
  };

  const handleIconSelect = ({ newSrc, tabId, premiumAvailable }) => {
    const iconComponentNode = state.iconComponent.node;

    iconComponentNode.setAttribute('src', newSrc);
    state.icon.src = newSrc;

    trackSelectIconIconClicked({
      dialogId: dialogTypes.SELECT_ICON,
      targetElementId: elementId,
      tabId,
      premiumAvailable,
    });
  };

  const handleSubmit = async ({ isOverlayClick, iconSrc, iconIdAttr, tabId, premiumAvailable }) => {
    if (!iconSrc) return;

    trackSelectIconDialogSubmitted({
      dialogId: dialogTypes.SELECT_ICON,
      targetElementId: elementId,
      iconSelected: !!iconSrc,
      triggerId: isOverlayClick ? 'dialog-background' : 'submit-button',
      tabId,
      premiumAvailable,
    });

    const iconId = view.accessors.getLiveElementId(state.iconComponent.node);

    hideDialog();

    let iconSource = iconSrc;
    const operations = [];

    if (iconIdAttr) {
      operations.push([
        view.operations.setElementAttribute,
        [iconId, constants.ICON_ID_ATTRIBUTE, iconIdAttr],
      ]);
    } else {
      operations.push([
        view.operations.removeElementAttribute,
        [iconId, constants.ICON_ID_ATTRIBUTE],
      ]);
      iconSource = await copyExtensionAsset(iconSrc);
    }

    operations.push([view.operations.setElementAttribute, [iconId, 'src', iconSource]]);

    view.operations.bulkViewOperations(operations);
  };

  const handleDismiss = ({ tabId, premiumAvailable }) => {
    insertOriginalIcon();
    hideDialog();

    trackSelectIconDialogCanceled({
      dialogId: dialogTypes.SELECT_ICON,
      targetElementId: elementId,
      tabId,
      premiumAvailable,
    });
  };

  dialogs.operations.show(dialogTypes.SELECT_ICON, {
    onSubmit: handleSubmit,
    onDismiss: handleDismiss,
    onIconSelect: handleIconSelect,
    onSearchClick: handleSearchClick,
    onSearchPerform: handleSearchPerform,
    offsetX,
    offsetY,
    elementId,
    iconColor: getIconColorHex(elementId),
    originalIcon: state.originalIcon.src,
    originalIconDataId: state.originalIcon.dataId,
    saveBtnText: i18next.t('Save'),
    cancelBtnText: i18next.t('Cancel'),
    searchEmptyTitle: i18next.t('Sorry!'),
    searchEmptyText: i18next.t('Nothing was found'),
  });
};

const onTriggerClick = (elementId) => {
  saveOriginalIcon(elementId);
  showSelectDialog(elementId);
};

const triggerId = 'icon';

const control = {
  id: triggerId,
  trigger: (
    <Trigger id={triggerId}>
      <Icon glyph="smile" size="24" strokeWidth="1.5" />
    </Trigger>
  ),
  get title() {
    return i18next.t('Add icon');
  },

  get tooltip() {
    return i18next.t('Replace icon');
  },
  priority: 100,
  closeIcon: 'submit',
  onTriggerClick,
  openExtension({
    offsetX,
    offsetY,
    iconSrc: originalIcon,
    onDialogCancel,
    onSubmit,
    elementId,
    iconIdAttr: originalIconDataId,
  }) {
    const handleSearchClick = ({ tabId, premiumAvailable }) => {
      trackSelectIconSearchClicked({
        dialogId: dialogTypes.SELECT_ICON,
        targetElementId: elementId,
        tabId,
        premiumAvailable,
      });
    };

    const handleSearchPerform = ({ searchTerm, searchResults, tabId }) => {
      trackSelectIconSearchPerformed({
        dialogId: dialogTypes.SELECT_ICON,
        targetElementId: elementId,
        searchTerm,
        searchResults,
        tabId,
      });
    };

    const handleIconSelect = ({ newSrc, tabId, premiumAvailable }) => {
      state.icon.src = newSrc;

      trackSelectIconIconClicked({
        dialogId: dialogTypes.SELECT_ICON,
        targetElementId: elementId,
        tabId,
        premiumAvailable,
      });
    };

    // eslint-disable-next-line no-shadow
    const handleSubmit = async ({
      offsetX: dialogOffsetX,
      offsetY: dialogOffsetY,
      isOverlayClick,
      iconSrc,
      iconIdAttr,
      tabId,
      premiumAvailable,
    }) => {
      if (!iconSrc) return;

      trackSelectIconDialogSubmitted({
        dialogId: dialogTypes.SELECT_ICON,
        targetElementId: elementId,
        iconSelected: !!iconSrc,
        triggerId: isOverlayClick ? 'dialog-background' : 'submit-button',
        tabId,
        premiumAvailable,
      });

      let newIconSrc = iconSrc;
      let newIconBaseSrc = iconSrc;

      if (iconIdAttr) {
        const assetsUrl = site.accessors.getAssetsUrl();
        newIconSrc = urlJoin(assetsUrl, iconSrc);
      } else {
        newIconBaseSrc = await copyExtensionAsset(iconSrc);
      }

      onSubmit({
        newIconSrc,
        newIconBaseSrc,
        newOffsetX: dialogOffsetX,
        newOffsetY: dialogOffsetY,
        iconIdAttr,
      });
    };

    const handleDismiss = ({
      offsetX: dialogOffsetX,
      offsetY: dialogOffsetY,
      tabId,
      premiumAvailable,
    }) => {
      onDialogCancel(dialogOffsetX, dialogOffsetY);

      trackSelectIconDialogCanceled({
        dialogId: dialogTypes.SELECT_ICON,
        targetElementId: elementId,
        tabId,
        premiumAvailable,
      });
    };

    dialogs.operations.show(dialogTypes.SELECT_ICON, {
      offsetX,
      offsetY,
      originalIcon,
      originalIconDataId,
      iconColor: getIconColorHex(elementId),
      onSubmit: handleSubmit,
      onDismiss: handleDismiss,
      onDialogCancel: utils.noop,
      onIconSelect: handleIconSelect,
      onSearchClick: handleSearchClick,
      onSearchPerform: handleSearchPerform,
      saveBtnText: i18next.t('Save'),
      cancelBtnText: i18next.t('Cancel'),
      searchEmptyTitle: i18next.t('Sorry!'),
      searchEmptyText: i18next.t('Nothing was found'),
      elementId,
    });
  },
  matches(liveElement) {
    const settings = extensions.accessors.getExtensionSettings(constants.slug);
    const { restrictedParentsSelector } = settings;

    if (restrictedParentsSelector && liveElement.closest(restrictedParentsSelector)) {
      return false;
    }

    return liveElement.matches(settings.querySelector);
  },
};

export default control;
