import { serviceClient } from '@yola/ws-sdk';

const getPlatforms = async ({ onRequestFail }) => {
  const client = serviceClient.get();
  try {
    const response = await client.icons8GetPlatforms();
    const {
      data: { docs },
    } = response;

    return docs.map((doc) => ({ label: doc.title, value: doc.apiCode }));
  } catch (error) {
    console.error(error);
    onRequestFail();
    return [];
  }
};

export default getPlatforms;
