export default {
  businessLogo: null,
  businessName: null,
  businessDescription: null,
  addresses: null,
  emails: null,
  phones: null,
  businessHours: null,
  socialLinks: null,
};
