import base64toBlob from 'src/js/modules/utils/base64-to-blob';
import { assets } from '@yola/ws-sdk';

const processBase64Images = async (base64Urls) => {
  const base64ImagesFiles = await Promise.all(base64Urls.map(base64toBlob));

  const { isFileSizeLimitReached } = assets.operations.clientUploadAssets(base64ImagesFiles);

  if (isFileSizeLimitReached) {
    return {
      isFileSizeLimitReached,
      base64Images: [],
    };
  }

  const base64Images = base64Urls.map((url, index) => ({
    url,
    file: base64ImagesFiles[index],
  }));

  const base64ImagesSources = await Promise.all(
    base64Images.map(async (base64Image) => {
      const { file, url } = base64Image;

      const {
        data: { source: assetSource },
      } = await assets.operations.createMediaGalleryAssets({
        asset: file,
        name: `pasted-base64-image-${Date.now()}`,
      });

      assets.operations.makePublishable(assetSource);

      return {
        assetSource,
        externalSource: url,
      };
    })
  );

  return {
    isFileSizeLimitReached,
    base64Images: base64ImagesSources,
  };
};

export default processBase64Images;
