import codeEditorPropTypes from './code-editor-prop-types';
import common from './common';
import settingLengthLimits from './setting-length-limits';
import settingTypes from './setting-types';
import webSettingsConfig from './settings-config';
import externalLinks from './external-links';
import integrationTypes from './integration-types';
import tabIdentifiers from './tab-identifiers';
import { CODE_EDITOR_HEIGHT } from './code-editor-height';

export default {
  codeEditorPropTypes,
  common,
  settingLengthLimits,
  settingTypes,
  webSettingsConfig,
  externalLinks,
  integrationTypes,
  tabIdentifiers,
  CODE_EDITOR_HEIGHT,
};
