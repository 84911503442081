import store from 'src/js/store';
import { view } from '@yola/ws-sdk';

function getFocusedElement() {
  const state = store.getState();
  const focusedElementId = view.selectors.getFocusedElement(state);
  return focusedElementId ? view.accessors.getLiveElement(focusedElementId) : null;
}

export default getFocusedElement;
