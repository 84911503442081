import React from 'react';
import { extensions, contentEditable, i18next } from '@yola/ws-sdk';
import ListFormattingTrigger from '../components/list-formatting-trigger';
import constants from '../constants';
import toggleList from '../helpers/toggle-list';
import isListAllowed from '../verifiers/is-list-allowed';

const { controlTypes } = extensions;
const { LIST_TYPE_NUMBER_TAG } = constants.listFormatting;
const triggerId = 'text-number-list';

const listNumberControl = {
  id: triggerId,
  trigger: (
    <ListFormattingTrigger
      triggerId={triggerId}
      icon="ordered-list"
      listType={LIST_TYPE_NUMBER_TAG}
    />
  ),
  get tooltip() {
    return i18next.t('Ordered list');
  },
  priority: 60,
  onTriggerClick: (elementId) => {
    contentEditable.operations.addCheckpoint();
    toggleList(LIST_TYPE_NUMBER_TAG, elementId);
  },
  for: [controlTypes.TEXT, controlTypes.COLLAPSED_TEXT],
  matches: (element) => isListAllowed(element, LIST_TYPE_NUMBER_TAG),
  highlightElement: false,
};

export default listNumberControl;
