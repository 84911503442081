import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import bowser from 'yola-bowser';
import { useDispatch } from 'react-redux';
import { assets, dialogs, extensions, view } from '@yola/ws-sdk';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import getRangeItemsIds from 'src/js/modules/dialogs/components/photo-stock-dialog/helpers/get-range-items-ids';
import GalleryDialog from './gallery-dialog-component';
import constants from '../constants';
import hasNoneDefaultImages from '../verifiers/has-none-default-images';
import GalleryDragPreview from './gallery-drag-preview';
import uploadFilesToGallery from '../helpers/upload-files-to-gallery';
import showFileQuantityInfoDialog from '../helpers/dialogs/show-file-quantity-info';
import showFileTypeInfoDialog from '../helpers/dialogs/show-file-type-info';
import showKeepDefaultImagesDialog from '../helpers/dialogs/show-keep-default-images';
import showLoadingProgressDialog from '../helpers/dialogs/show-loading-progress';
import getGalleryData from '../helpers/get-gallery-data';
import getGalleryItems from '../helpers/get-gallery-items';

const {
  common: { MAX_FILE_UPLOAD_QUANTITY },
} = constants;
const {
  FILE_TYPE_ERROR_DIALOG,
  GALLERY_DIALOG,
  GALLERY_SAVING_PROGRESS_DIALOG,
  GALLERY_SAVE_CONFIRMATION_DIALOG,
  MEDIA_SETTINGS_DIALOG,
} = dialogTypes;

function GalleryDialogHoc({ elementId, items, onDialogMainAction, onDialogCancel }) {
  const [galleryItems, setGalleryItems] = useState(items);
  const [selectMode, setSelectMode] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [shiftedItems, setShiftedItems] = useState([]);
  const dispatch = useDispatch();

  const galleryBlock = view.accessors.getLiveElement(elementId);
  const { sliderGalleryBlockQuerySelector } = extensions.accessors.getExtensionSettings(
    constants.common.slug
  );
  const isSliderGallery = galleryBlock.matches(sliderGalleryBlockQuerySelector);

  const handleCancel = () => {
    dispatch(view.actions.forceReloadView);
    onDialogCancel({ elementId });
    dialogs.operations.hide();
  };

  const handleSubmit = () => {
    onDialogMainAction({
      elementId,
      numberOfMedia: galleryItems.length,
    });

    if (galleryItems.length) {
      dialogs.operations.show(GALLERY_SAVING_PROGRESS_DIALOG, {
        elementId,
        items: galleryItems,
      });
    }
  };

  const handleAddImage = async (fileList, dismissedFilesList = []) => {
    if (fileList.length === 0) return;
    try {
      const isFileQuantityError = fileList.length > MAX_FILE_UPLOAD_QUANTITY;
      const validFileList = isFileQuantityError
        ? fileList.slice(0, MAX_FILE_UPLOAD_QUANTITY)
        : fileList;

      const { isFileSizeLimitReached } = await assets.operations.clientUploadAssets(validFileList);

      const isFileTypeError = dismissedFilesList.length > 0;
      const dismissedFilesNamesList = dismissedFilesList.map(({ name }) => name);
      const hasOnlyDefaultImages = galleryItems.length !== 0 && !hasNoneDefaultImages(galleryItems);

      const cancelShowingLoadingDialog = showLoadingProgressDialog();
      const firstGalleryItem = getGalleryItems(elementId)[0];
      const uploadedGalleryItems = await uploadFilesToGallery(validFileList, {
        firstGalleryItem,
        mediaGroupId: firstGalleryItem.getAttribute('media-group-id'),
        aspectRatio: firstGalleryItem.getAttribute('aspect-ratio'),
      });
      const isLoadingDialogShown = cancelShowingLoadingDialog();
      const updatedGalleryItems = [...galleryItems, ...uploadedGalleryItems];

      if (isFileQuantityError) {
        showFileQuantityInfoDialog({
          elementId,
          galleryItems,
          uploadedGalleryItems,
          dismissedFilesNamesList,
          isFileTypeError,
          hasOnlyDefaultImages,
        });
        return;
      }

      if (isFileTypeError) {
        showFileTypeInfoDialog({
          elementId,
          galleryItems,
          uploadedGalleryItems,
          dismissedFilesNamesList,
          hasOnlyDefaultImages,
        });
        return;
      }

      if (hasOnlyDefaultImages) {
        showKeepDefaultImagesDialog({ elementId, uploadedGalleryItems, galleryItems });
        return;
      }

      if (isFileSizeLimitReached || isLoadingDialogShown) {
        dialogs.operations.show(GALLERY_DIALOG, {
          items: updatedGalleryItems,
          elementId,
        });
        return;
      }

      setGalleryItems(updatedGalleryItems);
    } catch (e) {
      // if user close upgrade file size interrupter promise got rejected, but there is no error
      if (!e) {
        dialogs.operations.show(GALLERY_DIALOG, {
          items: galleryItems,
          elementId,
        });
        return;
      }
      console.error(e);
    }
  };

  const handleWrongFileType = (wrongFileList, info) => {
    const { supportedFilesList, dismissedFilesList } = info;
    if (supportedFilesList.length === 0) {
      dialogs.operations.show(FILE_TYPE_ERROR_DIALOG, {
        onDismiss: () => {
          dialogs.operations.show(GALLERY_DIALOG, { items: galleryItems, elementId });
        },
      });
      return;
    }
    handleAddImage(supportedFilesList, dismissedFilesList);
  };

  const switchSelectMode = () => {
    if (selectMode) {
      setSelectedItems([]);
      setShiftedItems([]);
    }
    setSelectMode(!selectMode);
  };

  const handleSelectItem = (id) => {
    setSelectedItems([...selectedItems, id]);
  };

  const handleUnselectItem = (id) => {
    const filteredSelection = selectedItems.filter((itemId) => itemId !== id);
    setSelectedItems([...filteredSelection]);
  };

  const handleGalleryItemClick = (event, id, selected) => {
    const { ctrlKey, metaKey, shiftKey } = event;
    const isMacOS = bowser.macOS;
    const isSelectingImageWithKeyPressed = (isMacOS ? metaKey : ctrlKey) || shiftKey;

    if (isSelectingImageWithKeyPressed) {
      setSelectMode(true);
    }

    if (!shiftKey) {
      setShiftedItems([]);
    }

    if (shiftKey && selectedItems.length) {
      const selectedItemsBeforeShifted = selectedItems.filter(
        (selectedItem) => !shiftedItems.includes(selectedItem)
      );

      const lastSelectedItemBeforeShifted =
        selectedItemsBeforeShifted[selectedItemsBeforeShifted.length - 1];
      const newSelectedItems = getRangeItemsIds(galleryItems, lastSelectedItemBeforeShifted, id);

      setShiftedItems([...newSelectedItems]);
      setSelectedItems([...new Set([...selectedItemsBeforeShifted, ...newSelectedItems])]);
    } else if (selectMode || isSelectingImageWithKeyPressed) {
      if (selected) {
        handleUnselectItem(id);
      } else {
        handleSelectItem(id);
      }
    } else {
      const currentItem = galleryItems.find((item) => item.id === id);
      const indexOfTargetItem = galleryItems.indexOf(currentItem);

      const openMediaSettingsDialog = (mediaId, mediaItems) =>
        dialogs.operations.show(MEDIA_SETTINGS_DIALOG, {
          elementId: mediaId,
          mediaGalleryItems: mediaItems,
        });

      if (!currentItem.elementId) {
        const saveGalleryChanges = () => {
          dialogs.operations.show(GALLERY_SAVING_PROGRESS_DIALOG, {
            elementId,
            items: galleryItems,
            indexOfTargetItem,
            onAfterSaved: (el) => {
              if (isSliderGallery) {
                dialogs.operations.show(GALLERY_DIALOG, {
                  items: getGalleryData(elementId),
                  elementId,
                });
                return;
              }

              openMediaSettingsDialog(
                view.accessors.getLiveElementId(el),
                getGalleryData(elementId)
              );
            },
            onDialogCancel: () => {},
          });
        };

        const cancelGalleryChanges = () => {
          dialogs.operations.show(dialogTypes.GALLERY_DIALOG, {
            items: galleryItems,
            elementId,
          });
        };

        dialogs.operations.show(GALLERY_SAVE_CONFIRMATION_DIALOG, {
          onSubmit: saveGalleryChanges,
          onCancel: cancelGalleryChanges,
        });
      } else {
        if (isSliderGallery) {
          const slider = galleryBlock.querySelector('ws-slider');
          slider.showSlide(indexOfTargetItem);
        }
        openMediaSettingsDialog(currentItem.elementId, galleryItems);
      }
    }
  };

  const handleSelectAllItems = () => {
    if (selectedItems.length === galleryItems.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(galleryItems.map(({ id }) => id));
    }
  };

  const handleDeleteItems = () => {
    const newItemsList = galleryItems.filter(({ id }) => !selectedItems.includes(id));
    setGalleryItems([...newItemsList]);
    setSelectedItems([]);
  };

  const onEndItemDrag = ({ draggedItemsIds, dropTargetId, dropPosition }) => {
    const draggedItems = [];
    const resultItems = [];

    galleryItems.forEach((item) => {
      const isDragged = draggedItemsIds.includes(item.id);

      if (isDragged) {
        draggedItems.push(item);
      } else {
        resultItems.push(item);
      }
    });

    const dropTargetIndex = resultItems.findIndex((item) => item.id === dropTargetId);
    const targetOrder =
      dropPosition === constants.dnd.AFTER ? dropTargetIndex + 1 : dropTargetIndex;

    resultItems.splice(targetOrder, 0, ...draggedItems);

    setGalleryItems(resultItems);
  };

  return (
    <Fragment>
      <GalleryDialog
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        onAddImage={handleAddImage}
        onWrongFileType={handleWrongFileType}
        onEndItemDrag={onEndItemDrag}
        enableSelect={selectMode}
        onDeleteItems={handleDeleteItems}
        onItemClick={handleGalleryItemClick}
        onSelect={handleSelectItem}
        onSelectAllItems={handleSelectAllItems}
        onUnselect={handleUnselectItem}
        switchSelectMode={switchSelectMode}
        items={galleryItems}
        selectedItemsIds={selectedItems}
        elementId={elementId}
      />
      <GalleryDragPreview />
    </Fragment>
  );
}

GalleryDialogHoc.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      mediaGroupId: PropTypes.string,
    })
  ).isRequired,
  elementId: PropTypes.string.isRequired,
  // Passed from ws-editor's `dialogs-container`:
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

export default GalleryDialogHoc;
