import { anodum, textFormatting, extensions } from '@yola/ws-sdk';
import getListFromSelection from '../helpers/get-list-from-selection';
import constants from '../constants';

const { LIST_TYPE_NUMBER_TAG, BLOCK_ELEMENT_TAGS, LIST_ITEM_TAG, LIST_TYPE_BULLET_TAG } =
  constants.listFormatting;
const { wrappersTypes } = textFormatting.constants;

const isListAllowed = (element, listTagName) => {
  const {
    textContainer,
    numberedListQuerySelector,
    bulletListQuerySelector,
    bulletListRestrictedParentsSelector,
    numberedListRestrictedParentsSelector,
  } = extensions.accessors.getExtensionSettings(constants.common.SLUG);

  if (!element.matches(textContainer)) return false;

  if (listTagName === LIST_TYPE_BULLET_TAG && bulletListRestrictedParentsSelector) {
    if (element.closest(bulletListRestrictedParentsSelector)) return false;
  } else if (listTagName === LIST_TYPE_NUMBER_TAG && numberedListRestrictedParentsSelector) {
    if (element.closest(numberedListRestrictedParentsSelector)) return false;
  }

  const innerQuerySelector =
    listTagName === LIST_TYPE_BULLET_TAG ? bulletListQuerySelector : numberedListQuerySelector;

  if (innerQuerySelector) {
    const selection = textFormatting.accessors.getAdvancedSelection();
    const range = selection.getRangeAt(0);

    const { anchorNode } = selection;
    const anchorElementNode = anodum.isTextNode(anchorNode) ? anchorNode.parentElement : anchorNode;

    if (getListFromSelection(anchorElementNode)) return true;

    const selectionElements = range.getNodes(
      [Node.ELEMENT_NODE],
      (el) =>
        !anodum.isPhrasingElement(el) &&
        !anodum.isOneOfTags(el, [
          'br',
          wrappersTypes.customColor.tagName,
          wrappersTypes.decoration.tagName,
          LIST_TYPE_NUMBER_TAG,
          LIST_TYPE_BULLET_TAG,
          LIST_ITEM_TAG,
        ])
    );

    const blockElements = selectionElements.length
      ? selectionElements
      : [selection.anchorNode.parentElement.closest(BLOCK_ELEMENT_TAGS)];

    return blockElements.every((el) => el && el.matches(innerQuerySelector));
  }

  return true;
};

export default isListAllowed;
