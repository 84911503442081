import { i18next } from '@yola/ws-sdk';

const getRequestWidget = (publicRoadmapUrl) => ({
  captions: {
    title: i18next.t("Can't find what you're looking for? Let us know!"),
    button: i18next.t('Request a widget'),
  },
  publicRoadmapUrl,
});

export default getRequestWidget;
