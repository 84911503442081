import getCaptionForElement from '../../common/helpers/get-caption-for-element';

const APPROXIMATE_LABEL_WIDTH = 50;

const getHighlighterLabelOptions = (options, element, bounds) => {
  const { hovered, withElementSize, labelGap } = options;

  if (hovered) {
    const { label } = getCaptionForElement(element);
    return {
      label,
      fullwidthLabel: true,
    };
  }

  if (withElementSize) {
    const { left, width, height } = bounds;
    let position = 'center-bottom';

    if (left + width < APPROXIMATE_LABEL_WIDTH) {
      position = 'left-bottom';
    }

    return {
      label: `${Math.round(width)} x ${Math.round(height)}`,
      labelPosition: position,
      fullwidthLabel: true,
      ...(labelGap && { labelGap }),
    };
  }

  return {};
};

export default getHighlighterLabelOptions;
