import { constants } from '@yola/subscription-manager-js';

const {
  hostingPackageFeatureNames: { AI_BLOCK_GENERATION_QUOTA },
} = constants;

const UNLIMITED = 'unlimited';
const MAX_REGENERATION_UNLIMITED_PLACEHOLDER = 100000;

const aiBlockGenerationLimit = ({ targetPackage }) => ({
  amount:
    targetPackage.features[AI_BLOCK_GENERATION_QUOTA] === UNLIMITED
      ? MAX_REGENERATION_UNLIMITED_PLACEHOLDER
      : targetPackage.features[AI_BLOCK_GENERATION_QUOTA],
});

export default aiBlockGenerationLimit;
