import React, { useRef, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import throttle from 'lodash.throttle';
import { view, bowser, anodum } from '@yola/ws-sdk';
import highlighter from 'src/js/modules/highlighter';
import { RESIZE_MIN_WIDTH, RESIZE_MAX_WIDTH } from '../constants/group-resize';
import highlighterOffset from '../../../../highlighter/helpers/highlighter-offset';
import ResizeHandle from '../../../common/components/resize-handle';

const DELAY_BETWEEN_FRAMES = 1000 / 60;
const LEFT_MOUSE_BUTTON = 1;

function LogoResizeTool(props) {
  const {
    elementId,
    appearanceStyle,
    scrollPosition,
    onActionStart,
    onActionEnd,
    onMouseEnter,
    onMouseLeave,
  } = props;
  const indent = highlighterOffset.get();
  const element = useMemo(() => view.accessors.getLiveElement(elementId), [elementId]);
  const [watchMouseMoves, setWatchMouseMoves] = useState(false);
  const [elementRect, setElementRect] = useState(() => element.getBoundingClientRect());
  const initialElementRect = useRef(elementRect);
  const triggerRef = useRef(null);
  const showHighlighterRef = useRef(null);
  const cachedHighlightedElements = useRef([]);
  const cachedElementId = useRef(null);
  const [cursorPosition, setCursorPosition] = useState({
    x: 0,
    y: 0,
  });

  const dispatch = useDispatch();

  const cacheHighlightedElements = () => {
    if (cachedElementId.current !== elementId) {
      const currentHighlightedElements = highlighter.accessors.getHighlightedElements();
      cachedHighlightedElements.current = currentHighlightedElements;
      cachedElementId.current = elementId;
    }
  };

  const hideHighlighter = (targetElement) => {
    // hide highlighter for element, if it has not been highlighted before
    if (!cachedHighlightedElements.current.some(([prevElement]) => prevElement === targetElement)) {
      highlighter.operations.hide([targetElement]);
    }
  };

  function highlightElement(targetElement, options = {}) {
    if (!showHighlighterRef.current) {
      showHighlighterRef.current = throttle((el, currentOptions) => {
        highlighter.operations.show([el], { forceUpdate: true, ...currentOptions });
      }, DELAY_BETWEEN_FRAMES);
    }

    showHighlighterRef.current(targetElement, options);
  }

  highlightElement.cancel = function cancel() {
    if (showHighlighterRef.current) {
      showHighlighterRef.current.cancel();
    }
  };

  function onInteractionStart(event) {
    if (event.type === 'mousedown' && event.buttons !== LEFT_MOUSE_BUTTON) return;

    const { pageX, pageY } = event.type === 'touchstart' ? event.touches[0] : event;

    setCursorPosition({
      x: pageX,
      y: pageY,
    });

    onActionStart();
    setWatchMouseMoves(true);
    highlightElement(element, { withElementSize: true });
  }

  function onMouseCursorEnter() {
    onMouseEnter();
    cacheHighlightedElements();
    highlightElement(element);
  }

  function onMouseCursoerLeave() {
    onMouseLeave();

    if (!watchMouseMoves) {
      highlightElement.cancel();
      hideHighlighter(element);
    }
  }

  function getTriggerHandlers() {
    if (bowser.mobile || bowser.tablet) {
      return {
        onTouchStart: onInteractionStart,
      };
    }

    return {
      onMouseDown: onInteractionStart,
      onMouseEnter: onMouseCursorEnter,
      onMouseLeave: onMouseCursoerLeave,
    };
  }

  function getOffsetTop() {
    return bowser.ios ? elementRect.bottom + indent : elementRect.bottom + scrollPosition + indent;
  }

  function getOffsetLeft() {
    return elementRect.right + indent;
  }

  useEffect(() => {
    function preventDefault(event) {
      event.preventDefault();
    }

    const triggerNode = triggerRef.current;

    if (bowser.ios) {
      triggerNode.addEventListener('touchstart', preventDefault, false);
    }

    return () => {
      triggerNode.removeEventListener('touchstart', preventDefault, false);
    };
  }, []);

  useEffect(() => {
    setElementRect(element.getBoundingClientRect());
  }, [scrollPosition, element]);

  useEffect(() => {
    function calculateElementStyles(width, height) {
      const image = anodum.isTag(element, 'img') ? element : element.querySelector('img');

      const aspectRatio = image.naturalWidth / image.naturalHeight;

      const { maxWidth, minWidth, maxHeight } = getComputedStyle(element);

      const newMaxWidth = maxWidth || RESIZE_MAX_WIDTH;

      const newMinWidth = minWidth && parseInt(minWidth, 10) ? minWidth : RESIZE_MIN_WIDTH;

      if (aspectRatio >= 1) {
        return {
          newWidth: `${width}px`,
          newHeight: 'auto',
          newMinWidth,
          newMaxWidth,
        };
      }

      let newHeight = height;

      if (maxHeight !== 'none') {
        const maxHeightValue = parseInt(maxHeight, 10);
        newHeight = height >= maxHeightValue ? maxHeightValue : height;
      }

      return {
        newWidth: `${newHeight * aspectRatio}px`,
        newHeight: 'auto',
        newMaxWidth,
        newMinWidth,
      };
    }

    function onMove(event) {
      const { pageX, pageY } = event.type === 'touchmove' ? event.touches[0] : event;
      const { x, y } = cursorPosition;
      const {
        width: initialWidth,
        height: initialHeight,
        top: initialTop,
        left: initialLeft,
      } = initialElementRect.current;

      const deltaX = pageX - x + (initialLeft - elementRect.left);
      const deltaY = pageY - y + (initialTop - elementRect.top);

      const width = initialWidth + deltaX;
      const height = initialHeight + deltaY;

      const { newWidth, newHeight } = calculateElementStyles(width, height);

      const hasInlineStyles = element.style.minWidth || element.style.maxWidth;

      element.style.width = newWidth;
      element.style.height = newHeight;

      if (hasInlineStyles) {
        // Remove unnecessary inline styles properties that already exist for ws-intense-next template
        // We need these properties only for imported websites
        element.style.removeProperty('max-width');
        element.style.removeProperty('min-width');
      }

      const newRect = element.getBoundingClientRect();

      if (newRect.width !== elementRect.width || newRect.height !== elementRect.height) {
        setElementRect(newRect);
        highlightElement(element, { withElementSize: true });
      }
    }

    function onInteractionEnd() {
      const { width: initialWidth, height: initialHeight } = initialElementRect.current;
      const { width, height } = elementRect;

      setWatchMouseMoves(false);

      if (initialWidth !== width || initialHeight !== height) {
        const { newWidth, newHeight } = calculateElementStyles(width, height);

        const hasInlineStyles = element.style.minWidth || element.style.maxWidth;

        const { setInlineElementStyle } = view.operations;

        const operations = [
          [setInlineElementStyle, [elementId, 'width', newWidth]],
          [setInlineElementStyle, [elementId, 'height', newHeight]],
          [setInlineElementStyle, [elementId, 'max-width', newWidth]],
        ];

        if (hasInlineStyles) {
          operations.push(
            [setInlineElementStyle, [elementId, 'max-width', '']],
            [setInlineElementStyle, [elementId, 'min-width', '']]
          );
        }

        view.operations.bulkViewOperations(operations);
      }

      highlightElement.cancel();
      onActionEnd();
      highlightElement(element, { withElementSize: false });
    }

    if (watchMouseMoves) {
      if (bowser.mobile || bowser.tablet) {
        document.addEventListener('touchmove', onMove, false);
        document.addEventListener('touchend', onInteractionEnd, false);
      } else {
        document.addEventListener('mousemove', onMove, false);
        document.addEventListener('mouseup', onInteractionEnd, false);
      }
    }

    return () => {
      document.removeEventListener('mousemove', onMove, false);
      document.removeEventListener('touchmove', onMove, false);
      document.removeEventListener('mouseup', onInteractionEnd, false);
      document.removeEventListener('touchend', onInteractionEnd, false);
    };
  }, [watchMouseMoves, cursorPosition, elementRect, element, elementId, onActionEnd, dispatch]);

  return (
    <ResizeHandle
      ref={triggerRef}
      direction="bottom-right"
      top={getOffsetTop()}
      left={getOffsetLeft()}
      style={appearanceStyle}
      {...getTriggerHandlers()}
    />
  );
}

LogoResizeTool.propTypes = {
  scrollPosition: PropTypes.number.isRequired,
  onActionStart: PropTypes.func.isRequired,
  onActionEnd: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  elementId: PropTypes.string.isRequired,
  appearanceStyle: PropTypes.shape().isRequired,
};

LogoResizeTool.defaultProps = {
  onMouseEnter: Function.prototype,
  onMouseLeave: Function.prototype,
};

export default LogoResizeTool;
