import { transactions, blocks } from '@yola/ws-sdk';
import getElementBySelector from '../../helpers/get-element-by-selector';
import getWhitespaceAutomationInstructions from './helpers/get-whitespace-automation-instructions';

const getRemovedBlock = (payload, transaction) => {
  const { blockId } = payload;
  const { initialState: initialTransactionDOM, state: transactionDOM } = transaction;

  return [
    ...initialTransactionDOM.querySelectorAll(
      `[id^="${blocks.constants.BLOCK_ID_PREFIX}-${blockId}-"]`
    ),
  ].find((node) => !transactionDOM.querySelector(`[id="${node.id}"]`));
};

const blockIsRemoved = (config, payload, transaction) => {
  const { context } = transaction;
  const isStatic = context !== transactions.transactionTypes.LIVE_DOCUMENT;
  const removedBlock = getRemovedBlock(payload, transaction);

  if (!removedBlock) {
    return [];
  }

  if (removedBlock.matches(`[id^="${blocks.constants.WIDGET_ID_PREFIX}"]`)) {
    const closestCustomBlock = removedBlock.closest('ws-block[custom]');
    const isLastRemovedWidget =
      closestCustomBlock.querySelectorAll(`[id^=${blocks.constants.WIDGET_ID_PREFIX}]`).length ===
      1;

    if (!isLastRemovedWidget) {
      return [];
    }

    const customBlockSelector = `[id="${closestCustomBlock.id}"]`;
    const nextSiblingSelector = `[id="${closestCustomBlock.nextElementSibling?.id}"]`;

    return getWhitespaceAutomationInstructions({
      prevSiblingNode: getElementBySelector(customBlockSelector, isStatic),
      targetNode: getElementBySelector(nextSiblingSelector, isStatic),
      isStatic,
    });
  }

  const prevSiblingSelector = `[id="${removedBlock.previousElementSibling?.id}"]`;
  const nextSiblingSelector = `[id="${removedBlock.nextElementSibling?.id}"]`;

  return getWhitespaceAutomationInstructions({
    prevSiblingNode: getElementBySelector(prevSiblingSelector, isStatic),
    targetNode: getElementBySelector(nextSiblingSelector, isStatic),
    isStatic,
  });
};

export default blockIsRemoved;
