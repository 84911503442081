import getPermissionStatus from 'src/js/modules/extensions/registry/ws-image/helpers/get-permission-status';
import permissions from 'src/js/modules/extensions/registry/ws-image/constants/permissions';
import showClipboardAccessDeniedDialog from 'src/js/modules/extensions/registry/ws-image/helpers/show-clipboard-access-denied-dialog';
import showUnsupportedBrowserDialog from 'src/js/modules/extensions/registry/ws-image/helpers/show-unsupported-browser-dialog';

const handleClipboardPermissionDenied = async (permissionName) => {
  const permissionStatus = await getPermissionStatus(permissionName);

  if (!permissionStatus) {
    showUnsupportedBrowserDialog();
    return true;
  }

  if (permissionStatus && permissionStatus === permissions.statuses.DENIED) {
    showClipboardAccessDeniedDialog();
    return true;
  }

  return false;
};

export default handleClipboardPermissionDenied;
