import React from 'react';
import { Slider } from '@yola/ws-ui';
import PropTypes from 'prop-types';
import { i18next } from '@yola/ws-sdk';
import getScoreStatusClassName from '../helpers/get-score-status-class-name';

function NpsScores({ onSelectedScoreChange, selectedScore, captions }) {
  const scoreMin = 0;
  const scoreMax = 10;
  const generateScores = (max) => [...Array(max + 1).keys()];
  const generateListKey = (item, index) => `${item}-${index}`;
  const minScoreCaption =
    captions && captions.minScore ? captions.minScore : i18next.t('Very unlikely');
  const maxScoreCaption =
    captions && captions.maxScore ? captions.maxScore : i18next.t('Extremely likely');

  return (
    <React.Fragment>
      <div className="nps-scores-container">
        <div className="nps-scores">
          {generateScores(scoreMax).map((score, index) => (
            <button
              type="button"
              className={`nps-score-button ${getScoreStatusClassName(score, selectedScore)}`}
              key={generateListKey('score', index)}
              onClick={() => onSelectedScoreChange(score)}
            >
              {score}
            </button>
          ))}
        </div>
        <div className="nps-scores-captions">
          <div className="nps-scores-caption">{`${scoreMin} — ${minScoreCaption}`}</div>
          <div className="nps-scores-caption">{`${scoreMax} — ${maxScoreCaption}`}</div>
        </div>
      </div>

      <div className="nps-scores-slider">
        <div className="nps-scores-captions">
          <div className="nps-scores-caption">{scoreMin}</div>
          <div className="nps-scores-caption">{scoreMax}</div>
        </div>
        <Slider
          max={scoreMax}
          value={selectedScore}
          onChange={(score) => onSelectedScoreChange(score)}
          tooltipEnabled
          className={getScoreStatusClassName(selectedScore, selectedScore)}
        />
        <div className="nps-scores-captions">
          <div className="nps-scores-caption">{minScoreCaption}</div>
          <div className="nps-scores-caption">{maxScoreCaption}</div>
        </div>
      </div>
    </React.Fragment>
  );
}

NpsScores.propTypes = {
  selectedScore: PropTypes.number,
  onSelectedScoreChange: PropTypes.func.isRequired,
  captions: PropTypes.shape({
    minScore: PropTypes.string,
    maxScore: PropTypes.string,
  }),
};

NpsScores.defaultProps = {
  selectedScore: undefined,
  captions: null,
};

export default NpsScores;
