export default {
  0: {
    0: '0',
    8: '8',
    16: '16',
    24: '24',
    32: '32',
    40: '40',
    48: '48',
    56: '56',
    64: '64',
    72: '72',
    80: '80',
    88: '88',
    96: '96',
    104: '104',
    112: '112',
    120: '120',
    128: '128',
  },
  8: {
    0: '0',
    8: '0',
    16: '8',
    24: '16',
    32: '24',
    40: '32',
    48: '40',
    56: '48',
    64: '56',
    72: '64',
    80: '72',
    88: '80',
    96: '88',
    104: '96',
    112: '104',
    120: '112',
    128: '120',
  },
  16: {
    0: '0',
    8: '0',
    16: '0',
    24: '8',
    32: '16',
    40: '24',
    48: '32',
    56: '40',
    64: '48',
    72: '56',
    80: '64',
    88: '72',
    96: '80',
    104: '88',
    112: '96',
    120: '104',
    128: '112',
  },
  24: {
    0: '0',
    8: '0',
    16: '0',
    24: '0',
    32: '8',
    40: '16',
    48: '24',
    56: '32',
    64: '40',
    72: '48',
    80: '56',
    88: '64',
    96: '72',
    104: '80',
    112: '88',
    120: '96',
    128: '104',
  },
  32: {
    0: '0',
    8: '0',
    16: '0',
    24: '0',
    32: '0',
    40: '8',
    48: '16',
    56: '24',
    64: '32',
    72: '40',
    80: '48',
    88: '56',
    96: '64',
    104: '72',
    112: '80',
    120: '88',
    128: '96',
  },
  40: {
    0: '0',
    8: '0',
    16: '0',
    24: '0',
    32: '0',
    40: '0',
    48: '8',
    56: '16',
    64: '24',
    72: '32',
    80: '40',
    88: '48',
    96: '56',
    104: '64',
    112: '72',
    120: '80',
    128: '88',
  },
  48: {
    0: '0',
    8: '0',
    16: '0',
    24: '0',
    32: '0',
    40: '0',
    48: '0',
    56: '8',
    64: '16',
    72: '24',
    80: '32',
    88: '40',
    96: '48',
    104: '56',
    112: '64',
    120: '72',
    128: '80',
  },
  56: {
    0: '0',
    8: '0',
    16: '0',
    24: '0',
    32: '0',
    40: '0',
    48: '0',
    56: '0',
    64: '8',
    72: '16',
    80: '24',
    88: '32',
    96: '40',
    104: '48',
    112: '56',
    120: '64',
    128: '72',
  },
  64: {
    0: '0',
    8: '0',
    16: '0',
    24: '0',
    32: '0',
    40: '0',
    48: '0',
    56: '0',
    64: '0',
    72: '8',
    80: '16',
    88: '24',
    96: '32',
    104: '40',
    112: '48',
    120: '56',
    128: '64',
  },
  72: {
    0: '0',
    8: '0',
    16: '0',
    24: '0',
    32: '0',
    40: '0',
    48: '0',
    56: '0',
    64: '0',
    72: '0',
    80: '8',
    88: '16',
    96: '24',
    104: '32',
    112: '40',
    120: '48',
    128: '56',
  },
  80: {
    0: '0',
    8: '0',
    16: '0',
    24: '0',
    32: '0',
    40: '0',
    48: '0',
    56: '0',
    64: '0',
    72: '0',
    80: '0',
    88: '8',
    96: '16',
    104: '24',
    112: '32',
    120: '40',
    128: '48',
  },
  88: {
    0: '0',
    8: '0',
    16: '0',
    24: '0',
    32: '0',
    40: '0',
    48: '0',
    56: '0',
    64: '0',
    72: '0',
    80: '0',
    88: '0',
    96: '8',
    104: '16',
    112: '24',
    120: '32',
    128: '40',
  },
  96: {
    0: '0',
    8: '0',
    16: '0',
    24: '0',
    32: '0',
    40: '0',
    48: '0',
    56: '0',
    64: '0',
    72: '0',
    80: '0',
    88: '0',
    96: '0',
    104: '8',
    112: '16',
    120: '24',
    128: '32',
  },
  104: {
    0: '0',
    8: '0',
    16: '0',
    24: '0',
    32: '0',
    40: '0',
    48: '0',
    56: '0',
    64: '0',
    72: '0',
    80: '0',
    88: '0',
    96: '0',
    104: '0',
    112: '8',
    120: '16',
    128: '24',
  },
  112: {
    0: '0',
    8: '0',
    16: '0',
    24: '0',
    32: '0',
    40: '0',
    48: '0',
    56: '0',
    64: '0',
    72: '0',
    80: '0',
    88: '0',
    96: '0',
    104: '0',
    112: '0',
    120: '8',
    128: '16',
  },
  120: {
    0: '0',
    8: '0',
    16: '0',
    24: '0',
    32: '0',
    40: '0',
    48: '0',
    56: '0',
    64: '0',
    72: '0',
    80: '0',
    88: '0',
    96: '0',
    104: '0',
    112: '0',
    120: '0',
    128: '8',
  },
  128: {
    0: '0',
    8: '0',
    16: '0',
    24: '0',
    32: '0',
    40: '0',
    48: '0',
    56: '0',
    64: '0',
    72: '0',
    80: '0',
    88: '0',
    96: '0',
    104: '0',
    112: '0',
    120: '0',
    128: '0',
  },
};
