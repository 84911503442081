import React, { forwardRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { designSystem } from '@yola/ws-ui';

const { Autocomplete, FieldStatus } = designSystem;

const AutocompleteField = forwardRef((props, ref) => {
  const {
    placeholder,
    options,
    onChange,
    onBlur,
    onFocus,
    getIconComponent,
    field: { name, value, onBlur: handleBlur, onChange: handleChange },
    form: { errors, touched, setFieldValue, setFieldError, setFieldTouched },
    ...otherProps
  } = props;

  const touchedField = get(touched, name);
  const errorField = get(errors, name);
  const hasError = !!(errorField && touchedField);

  const onFieldChange = useCallback(
    (option) => {
      setFieldValue(name, option?.value || value, false);
      handleChange(option);

      if (onChange) onChange(option);
    },
    [onChange, setFieldValue, value, name, handleChange]
  );

  const onFieldBlur = useCallback(() => {
    setFieldTouched(name);
    handleBlur();

    if (onBlur) onBlur();
  }, [name, setFieldTouched, onBlur, handleBlur]);

  const handleFocus = () => {
    setFieldError(name, '');

    if (onFocus) onFocus();
  };

  return (
    <>
      <Autocomplete
        ref={ref}
        options={options}
        placeholder={placeholder}
        invalid={hasError}
        onChange={onFieldChange}
        onFocus={handleFocus}
        onBlur={onFieldBlur}
        iconComponent={getIconComponent(hasError)}
        {...otherProps}
      />
      {hasError && <FieldStatus appearance="error" text={errorField || ''} />}
    </>
  );
});

AutocompleteField.propTypes = {
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  getIconComponent: PropTypes.func,
  // Following props are set by Formik. Added only to pass snitch check.
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
    setFieldError: PropTypes.func,
    setFieldTouched: PropTypes.func,
    touched: PropTypes.shape({}),
    errors: PropTypes.shape({}),
  }).isRequired,
};

AutocompleteField.defaultProps = {
  placeholder: '',
  onChange: Function.prototype,
  onBlur: Function.prototype,
  onFocus: Function.prototype,
  getIconComponent: Function.prototype,
};

export default AutocompleteField;
