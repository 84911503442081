import { blocks } from '@yola/ws-sdk';
import layoutSwitchingHelpers from 'src/js/modules/blocks/helpers/layout-switching';
import { USER_MODIFIED_ATTR } from '../../../design-automation/constants/attributes';

const { getDisplayOptionsToUpdate, normalizeValues, optionsToAttributes, updateAttributes } =
  layoutSwitchingHelpers;

// This helper has side effect

const handleOptions = (destinationBlockNode, displayOptions, ignoredOptions = []) => {
  const destinationDisplayOptionsFromNode = blocks.accessors
    .getDisplayOptionsForElement(destinationBlockNode)
    .map((option) => {
      const { value, options } = option;
      if (!value) return option;

      const values = value.split(' ');
      const validOption =
        options && options.find((variant) => values.some((item) => item === variant.value));

      if (!validOption) return option;

      return {
        ...option,
        value: validOption.value,
      };
    });

  const currentOptions = displayOptions.map(
    ({
      id,
      attribute,
      value,
      type,
      querySelector,
      options,
      isEnabled = true,
      userModified = false,
    }) => ({
      id,
      attribute,
      value: value || '',
      options,
      type,
      querySelector,
      isSet: isEnabled,
      userModified,
    })
  );

  const displayOptionsToUpdate = normalizeValues(
    getDisplayOptionsToUpdate(currentOptions, destinationDisplayOptionsFromNode)
  );

  // Check default values
  destinationDisplayOptionsFromNode.forEach((option) => {
    if (option.querySelector) {
      const selectedNode = destinationBlockNode.querySelector(option.querySelector);
      const currentAttributeValue = selectedNode.getAttribute(option.attribute);
      if (!option.options.some((el) => el.value === currentAttributeValue)) {
        const [defaultValue] = option.options;
        selectedNode.setAttribute(option.attribute, defaultValue.value);
      }
    }
  });

  const attributesToUpdate = optionsToAttributes(
    displayOptionsToUpdate,
    destinationDisplayOptionsFromNode,
    ignoredOptions
  );

  updateAttributes(attributesToUpdate, destinationBlockNode);

  attributesToUpdate.forEach(({ attribute, userModified }) => {
    if (userModified) {
      const currentValue = destinationBlockNode.getAttribute(USER_MODIFIED_ATTR) || '';

      const uniqueValuesArray = [...new Set([...currentValue.split(','), attribute])];
      const updatedValue = uniqueValuesArray.join(',');

      destinationBlockNode.setAttribute(USER_MODIFIED_ATTR, updatedValue);
    }
  });
};

export default handleOptions;
