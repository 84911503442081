import { hooks, site, template } from '@yola/ws-sdk';
import constants from '../constants';
import track from '../track';
import getShiftedWidgetNumericPositions from '../helpers/get-shifted-widget-numeric-positions';
import getParentBlockInfo from '../helpers/get-parent-block-info';

const trackWidgetShifted = () => {
  hooks.operations.subscribe(
    hooks.availableHooks.ON_AFTER_BLOCK_SHIFTED,
    ({
      blockId,
      variationId: blockVariationId,
      currentPosition,
      previousPosition,
      constraintId,
    }) => {
      if (blockId && !blockId.startsWith('widget-')) return;

      const { refElementId: oldSiblingId } = previousPosition;
      const {
        blockId: oldBlockId,
        variationId: oldBlockVariationId,
        blockElement: oldBlockElement,
      } = getParentBlockInfo(oldSiblingId);

      const { refElementId: newSiblingId } = currentPosition;
      const {
        blockId: newBlockId,
        variationId: newBlockVariationId,
        blockElement: newBlockElement,
      } = getParentBlockInfo(newSiblingId);

      const isBlockChanged = oldBlockElement !== newBlockElement;

      const { newPosition, oldPosition } = getShiftedWidgetNumericPositions({
        currentPosition,
        previousPosition,
        isBlockChanged,
      });

      track(constants.events.WIDGET_SHIFTED, {
        siteId: site.accessors.getSiteId(),
        templateBuildSlug: template.accessors.getBuildSlug(),
        oldBlockId,
        oldBlockVariationId,
        oldPosition,
        newBlockId,
        newBlockVariationId,
        newPosition,
        blockId,
        blockVariationId,
        constraintId,
      });
    }
  );
};

export default trackWidgetShifted;
