import { serviceClient } from '@yola/ws-sdk';
import getDefaultPlatformOptions from './get-default-platform-options';
import iconTypes from '../constants/icon-types';
import constants from '../constants/common';

const { INIT_NUMBER_OF_ICONS_ON_SCREEN } = constants;

const getFilteredIcons = async ({
  offset,
  valueToFilter,
  platform,
  iconType,
  language,
  iconColor,
  onRequestFail,
  abortRequestController,
}) => {
  let params = {
    offset,
    language,
    term: valueToFilter,
    amount: INIT_NUMBER_OF_ICONS_ON_SCREEN,
    color: iconColor,
  };

  if (platform !== getDefaultPlatformOptions().value) {
    params = { ...params, platform };
  }

  if (iconType === iconTypes.STATIC) {
    params = { ...params, isAnimated: false };
  } else if (iconType === iconTypes.ANIMATED) {
    params = { ...params, isAnimated: true };
  }

  try {
    const client = serviceClient.get();
    const response = await client.icons8SearchIcons(params, {
      signal: abortRequestController?.signal,
    });
    const {
      data: {
        icons,
        parameters: { countAll },
      },
    } = response;

    return { icons, iconsCount: countAll };
  } catch (error) {
    if (!abortRequestController?.signal?.aborted) {
      console.error(error);
      onRequestFail();
    }

    return { icons: [], iconsCount: 0 };
  }
};

export default getFilteredIcons;
