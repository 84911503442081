import { view, site, blocks, template } from '@yola/ws-sdk';
import getClosestBlockElement from '../../../common/helpers/get-closest-block-element';
import getIconSourceTrait from './get-icon-source-trait';

export default function getIconDialogCommonTraits(targetElementId) {
  const siteId = site.accessors.getSiteId();
  const templateBuildSlug = template.accessors.getBuildSlug();
  const iconSourceElement = view.accessors.getLiveElement(targetElementId);
  const targetBlockElement = getClosestBlockElement(iconSourceElement);
  const targetBlockId = blocks.accessors.getBlockIdByElement(targetBlockElement);
  const targetBlockVariationId = blocks.accessors.getVariationIdByElement(targetBlockElement);
  const source = getIconSourceTrait(targetElementId);

  return {
    siteId,
    templateBuildSlug,
    targetBlockId,
    targetBlockVariationId,
    source,
  };
}
