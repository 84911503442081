import { view } from '@yola/ws-sdk';
import calcTriggerPosition from './calc-trigger-position';
import getTooltipDirection from './get-tooltip-direction';
import adjacentPositions from '../constants/adjacent-positions';

const { BEFORE_BEGIN, AFTER_END } = adjacentPositions;

const getTriggersPositionInfo = (
  element,
  scrollPosition,
  pageContainerSelector,
  viewportHeight
) => {
  if (!element || !element.ownerDocument || !element.ownerDocument.defaultView) return null;
  const bounds = element.getBoundingClientRect();
  const flowType = view.helpers.getElementFlowType(element);

  const positionForBottom = calcTriggerPosition(
    element,
    AFTER_END,
    scrollPosition,
    pageContainerSelector,
    viewportHeight
  );

  const tooltipDirectionForBottom = getTooltipDirection(positionForBottom.y, scrollPosition);

  const positionForTop = calcTriggerPosition(
    element,
    BEFORE_BEGIN,
    scrollPosition,
    pageContainerSelector,
    viewportHeight
  );

  const tooltipDirectionForTop = getTooltipDirection(positionForTop.y, scrollPosition);

  return {
    bounds,
    flowType,
    topTrigger: {
      adjacentPosition: BEFORE_BEGIN,
      position: positionForTop,
      tooltipDirection: tooltipDirectionForTop,
    },
    bottomTrigger: {
      adjacentPosition: AFTER_END,
      position: positionForBottom,
      tooltipDirection: tooltipDirectionForBottom,
    },
  };
};

export default getTriggersPositionInfo;
