const getKeywordsList = (keywords) => {
  if (!keywords) {
    return [];
  }

  const keywordsList = keywords.split(',');

  return keywordsList.map((keyword) => ({ value: keyword.trim() }));
};

export default getKeywordsList;
