import { globals, integration, site, anodum, template } from '@yola/ws-sdk';
import isConfiguredProperly from '../helpers/is-configured-properly';
import createBrandingComponent from '../helpers/create-branding-component';

const brandedFooter = (store) => (next) => (action) => {
  if (action.type === site.actionTypes.SET_ACTIVE_PAGE) {
    const state = store.getState();
    const footerGlobalId = template.selectors.getVariableValueByPath(
      state,
      'footerCode[0].globalId'
    );
    const global = globals.selectors.getGlobal(state, footerGlobalId);
    const userData = integration.selectors.getUserData(state);
    const isWLUser = userData.is_white_label;
    const siteFooter = integration.selectors.getSiteFooter(state);

    if (global && global.html) {
      const globalFooterElement = anodum.parseElementFromString(global.html);
      const isBrandedFooterRemovable = integration.selectors.getBrandedFooterLimit(state).removable;
      const brandedFooterElement = globalFooterElement.querySelector('ws-branding');

      // `siteFooter` is an optional partner property so not all partners has it configured
      // but integration settings has default value with Yola data, we don't wont that to appear for
      // those partners who hasn't configure it's own site footer
      const isSiteFooterConfigured = isWLUser
        ? Boolean(siteFooter) && !integration.verifiers.isDefaultSiteFooter(siteFooter)
        : Boolean(siteFooter);

      if (!siteFooter || !isSiteFooterConfigured || isBrandedFooterRemovable) {
        if (brandedFooterElement) {
          brandedFooterElement.remove();

          store.dispatch(
            globals.actions.setGlobalHTML(footerGlobalId, globalFooterElement.outerHTML)
          );
        }
      } else if (!brandedFooterElement || !isConfiguredProperly(brandedFooterElement)) {
        const newBrandingFooter = createBrandingComponent();

        if (brandedFooterElement) {
          globalFooterElement.replaceChild(newBrandingFooter, brandedFooterElement);
        } else {
          globalFooterElement.append(newBrandingFooter);
        }

        store.dispatch(
          globals.actions.setGlobalHTML(footerGlobalId, globalFooterElement.outerHTML)
        );
      }
    }
  }

  next(action);
};

export default brandedFooter;
