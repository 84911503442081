import { site, integration, hooks } from '@yola/ws-sdk';
import config from 'src/js/modules/config';
import saveSite from 'src/js/modules/saving/helpers/save-site';
import getLanguageCodes from 'src/js/modules/website-settings/helpers/get-language-codes';
import constants from '../constants';

const { common, emptySettings } = constants;

const initialSettingsSetup = (store) => (next) => (action) => {
  next(action);

  if (action.type === site.actionTypes.CONNECT_SITE) {
    const state = store.getState();
    const { featureFlags, userData } = integration.selectors.getSettings(state) || {};
    const { site_export: isSiteExportEnabled } = featureFlags;
    const isInitialSettingsApplied = site.selectors.getInitialSettingsApplied(state);
    const isSiteSetupCompleted = site.selectors.getInitialSetupCompleted(state);

    if (!isInitialSettingsApplied && !isSiteSetupCompleted) {
      store.dispatch(
        site.actions.setSiteProperty(common.INITIAL_SETTINGS_APPLIED_PROPERTY_NAME, true)
      );

      /**
       * We can not update globals right away because globals have not downloaded yet.
       * To be sure that globals are ready we use `ON_BEFORE_PAGE_LOADED` hook.
       */
      hooks.operations.subscribe(
        hooks.availableHooks.ON_BEFORE_PAGE_LOADED,
        () => {
          const pages = site.selectors.getPages(state);
          const isTestPageEnabled = config.selectors.getTestPagesEnabled(state);
          const activePageId = site.selectors.getActivePageId(state);
          const settings = site.selectors.getSettings(state);
          const currentSiteName = site.selectors.getSiteName(state);
          const { email: userEmail, is_white_label: isWL } = userData;
          const businessEmails = isWL ? [] : [userEmail];

          const settingsToSave = {
            ...emptySettings,
            ...settings,
            businessName: settings?.businessName || currentSiteName,
            siteName: settings?.siteName || currentSiteName,
            emails: settings?.emails || businessEmails,
          };
          const updateGlobals = true;
          const updateCopyrightGlobal = true;

          store.dispatch(
            site.actions.initSettingsForFirstLoading(
              settingsToSave,
              updateGlobals,
              updateCopyrightGlobal
            )
          );

          const languageCodes = getLanguageCodes();

          pages.forEach((page) => {
            if (site.verifiers.isHomePage(page.path, languageCodes)) return;

            if (page.id !== activePageId) {
              if (isSiteExportEnabled && page.source.startsWith(common.ALL_BLOCKS_SOURCE_PREFIX))
                return;
              if (!isTestPageEnabled || !page.source.startsWith(common.TEST_PAGES_SOURCE_PREFIX)) {
                store.dispatch(site.actions.deletePage(page.id, languageCodes));
              }
            }
          });
        },
        { once: true }
      );

      /**
       * We can not save changes right away
       * it is too early and editor has not ready yet,
       * so that we save changes when document is ready
       */
      hooks.operations.subscribe(
        hooks.availableHooks.ON_LIVE_DOCUMENT_CONNECTED,
        () => {
          saveSite();
        },
        { once: true }
      );
    }
  }
};

export default initialSettingsSetup;
