import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@yola/ws-ui';
import { contentEditable } from '@yola/ws-sdk';
import Trigger from 'src/js/modules/common/components/trigger';
import withSelection from 'src/js/modules/utils/with-selection';
import useElementTypeSelect from '../helpers/use-element-type-select';
import restoreRange from '../helpers/restore-range';

function TextAlignActionTrigger({ alignType, elementId, matchProperties, triggerId }) {
  const { focusElements, selection } = useElementTypeSelect(elementId);

  const isActive = focusElements.every((element) => {
    const currentAlign = element.style.getPropertyValue('text-align');
    return matchProperties.includes(currentAlign);
  });

  const changeTextAlign = () => {
    contentEditable.operations.addCheckpoint();

    if (isActive) {
      focusElements.forEach((element) => element.style.removeProperty('text-align'));
    } else {
      focusElements.forEach((element) => element.style.setProperty('text-align', alignType));
    }

    try {
      restoreRange(selection, elementId);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Trigger id={triggerId} active={isActive} onClick={changeTextAlign}>
      <Icon glyph={`align-${alignType}`} strokeWidth="1.3" size="16" />
    </Trigger>
  );
}

TextAlignActionTrigger.propTypes = {
  alignType: PropTypes.string.isRequired,
  elementId: PropTypes.string.isRequired,
  triggerId: PropTypes.string.isRequired,
  matchProperties: PropTypes.array.isRequired,
};

export default withSelection(TextAlignActionTrigger);
