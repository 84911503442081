import { useEffect, useRef } from 'react';

const useInterval = (callback, delay) => {
  const intervalRef = useRef(null);
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => savedCallback.current();

    intervalRef.current = setInterval(tick, delay);

    return () => clearInterval(intervalRef.current);
  }, [delay]);

  return intervalRef;
};

export default useInterval;
