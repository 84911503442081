export default {
  DIALOG_WIDTH: 368,
  DIALOG_HEIGHT: 457,
  PANEL_HEIGHT: 48,

  INPUTS_IDS: {
    phoneInput: 'phone-input',
    emailInput: 'email-input',
    linkInput: 'link-input',
    downloadInput: 'download-input',
    pageInput: 'page-input',
    blockInput: 'block-input',
    titleInput: 'title-input',
    actionTypeInput: 'action-type-input',
    socialMediaInput: 'social-media-input',
    whatsAppInput: 'whats-app-input',
  },
  DOWNLOAD_OPTIONS_VALUES: {
    downloadOnComputer: 'download-on-computer',
    openInNewTab: 'open-in-new-tab',
  },
};
