import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, PanelGroup, Panel, Formik, Field, designSystem } from '@yola/ws-ui';
import { i18next, dialogs, view, blocks } from '@yola/ws-sdk';
import segment from 'src/js/modules/analytics/segment';
import bowser from 'yola-bowser';
import constants from '../constants';
import getChangedAttributes from '../helpers/get-changed-attributes';
import updateLiveElementAttribute from '../helpers/update-live-element-attribute';
import dialogTypes from '../../../../dialogs/constants/dialog-types';
import getParentBlockByElement from '../../../../blocks/helpers/get-parent-block-by-element';

const {
  trackers: { trackEvent },
  constants: { events },
} = segment;

const getTransitionOptions = () => [
  { value: 'slide', label: i18next.t('Slide in') },
  { value: 'fade', label: i18next.t('Fade in') },
];

const {
  DialogButtonGroup,
  DialogButton,
  DialogButtonGroupSeparator,
  ControlGroup,
  CheckboxGroup,
  Checkbox,
  DialogHeader,
  DialogHeaderTitle,
  Stack,
  Box,
  SimpleSelect,
  Slider,
} = designSystem;
const { setElementAttribute, removeElementAttribute, bulkViewOperations } = view.operations;
const { common, attributes, optionTypes, attributesMap } = constants;

const { MODAL_WIDTH, MODAL_HEIGHT, DELAY } = common;
const { AUTO_CHANGE_DELAY } = attributes;
const isMobile = bowser.mobile;
const { SLIDER_SETTINGS_DIALOG } = dialogTypes;

const SlideSettingsDialog = ({ elementId, onDialogMainAction, onDialogCancel }) => {
  const element = view.accessors.getLiveElement(elementId);
  const blockElement = getParentBlockByElement(element);
  const blockId = blocks.accessors.getBlockIdByElement(blockElement);
  const dialogPosition = dialogs.helpers.getPositionByElement(element, MODAL_WIDTH, MODAL_HEIGHT);
  const transitionOptions = getTransitionOptions();

  const formRef = useRef(null);

  const initialState = {
    [optionTypes.PAGINATION]: element.pagination,
    [optionTypes.NAVIGATION]: element.navigation,
    [optionTypes.TRANSITION]: element.transition,
    [optionTypes.AUTO_CHANGE]: element.autoplay,
    [optionTypes.AUTO_CHANGE_DELAY]: element.autoplayDelay / 1000,
  };
  const { offsetX, offsetY } = dialogPosition;

  const handleSubmitForm = (data) => {
    const { autoChange, autoChangeDelay, transition, navigation, pagination } = data;
    const operations = Object.keys(data).reduce((acc, name) => {
      const value = data[name];
      const attribute = attributesMap[name];

      if (!attribute) {
        return acc;
      }

      return value
        ? [...acc, [setElementAttribute, [elementId, attribute, value]]]
        : [...acc, [removeElementAttribute, [elementId, attribute]]];
    }, []);

    if (autoChange) {
      operations.push([
        setElementAttribute,
        [elementId, AUTO_CHANGE_DELAY, autoChangeDelay * 1000],
      ]);
    } else {
      operations.push([removeElementAttribute, [elementId, AUTO_CHANGE_DELAY]]);
    }

    bulkViewOperations(operations);
    onDialogMainAction({
      elementId,
      autoChange,
      duration: autoChangeDelay,
      navigation,
      pagination,
      transitionType: transition,
    });
    dialogs.operations.hide();
  };

  const handleCancel = () => {
    const {
      state: { values },
    } = formRef.current;
    const changeAttributes = getChangedAttributes(values, initialState);

    changeAttributes.forEach(({ name, value }) =>
      updateLiveElementAttribute(elementId, name, value)
    );
    onDialogCancel({ elementId });
    dialogs.operations.hide();
  };

  const handleNavigation = ({ value, field, setFieldValue }) => {
    updateLiveElementAttribute(elementId, attributesMap[field.name], value);
    trackEvent(events.SLIDER_SETTINGS_OPTION_CLICKED, {
      dialogId: SLIDER_SETTINGS_DIALOG,
      blockId,
      navigation: value,
    });
    setFieldValue(field.name, value);
  };

  const handlePagination = ({ value, field, setFieldValue }) => {
    updateLiveElementAttribute(elementId, attributesMap[field.name], value);
    trackEvent(events.SLIDER_SETTINGS_OPTION_CLICKED, {
      dialogId: SLIDER_SETTINGS_DIALOG,
      blockId,
      pagination: value,
    });
    setFieldValue(field.name, value);
  };

  const handleTransition = ({ selection, field, setFieldValue }) => {
    trackEvent(events.SLIDER_SETTINGS_INPUT_CLICKED, {
      dialogId: SLIDER_SETTINGS_DIALOG,
      blockId,
      actionType: selection.value,
    });
    setFieldValue(field.name, selection.value);
  };

  const handleAutoChange = ({ value, field, setFieldValue }) => {
    trackEvent(events.SLIDER_SETTINGS_OPTION_CLICKED, {
      dialogId: SLIDER_SETTINGS_DIALOG,
      blockId,
      autoChange: value,
    });
    setFieldValue(field.name, value);
  };

  const handleAutoChangeDelay = ({ value, field, setFieldValue }) => {
    setFieldValue(field.name, value);
  };

  return (
    <Formik ref={formRef} initialValues={initialState} onSubmit={handleSubmitForm}>
      {({ handleSubmit, setFieldValue, values }) => (
        <Modal
          resizable={false}
          height="auto"
          width={MODAL_WIDTH}
          minWidth={MODAL_WIDTH}
          fullscreen={isMobile}
          dragHandleSelector=".ws-drag-trigger"
          offsetX={offsetX}
          offsetY={offsetY}
          handleSubmit={handleSubmit}
        >
          <PanelGroup height="100%">
            <Panel
              align="middle"
              corners="squared"
              style={{ flexShrink: '0' }}
              className="ws-drag-trigger"
            >
              <DialogHeader>
                <DialogHeaderTitle>{i18next.t('Slider settings')}</DialogHeaderTitle>
              </DialogHeader>
            </Panel>
            <Panel scrollable={isMobile} height="100%" style={{ borderBottom: 'none' }}>
              <Box margin="spacing-m" marginTop="spacing-s" marginBottom="spacing-s">
                <Stack gap="spacing-m">
                  <ControlGroup
                    title={i18next.t('Slider elements')}
                    description={i18next.t('Select which elements should be displayed')}
                  >
                    <CheckboxGroup>
                      <Field name={optionTypes.NAVIGATION}>
                        {({ field }) => (
                          <React.Fragment>
                            <Checkbox
                              id={field.name}
                              label={i18next.t('Arrows')}
                              checked={field.value}
                              value={field.name}
                              onChange={(value) => {
                                handleNavigation({ value, field, setFieldValue });
                              }}
                            />
                          </React.Fragment>
                        )}
                      </Field>
                      <Field name={optionTypes.PAGINATION}>
                        {({ field }) => (
                          <React.Fragment>
                            <Checkbox
                              id={field.name}
                              label={i18next.t('Pagination dots')}
                              checked={field.value}
                              value={field.name}
                              onChange={(value) => {
                                handlePagination({ value, field, setFieldValue });
                              }}
                            />
                          </React.Fragment>
                        )}
                      </Field>
                    </CheckboxGroup>
                  </ControlGroup>
                  <ControlGroup
                    title={i18next.t('Slide transition type')}
                    description={i18next.t('Choose the slide transition type for the gallery')}
                  >
                    <Field name={optionTypes.TRANSITION}>
                      {({ field }) => (
                        <SimpleSelect
                          defaultValue={transitionOptions.find(
                            ({ value }) => value === field.value
                          )}
                          onChange={(selection) => {
                            handleTransition({ selection, field, setFieldValue });
                          }}
                          options={transitionOptions}
                        />
                      )}
                    </Field>
                  </ControlGroup>
                  <ControlGroup
                    title={i18next.t('Slide options')}
                    description={i18next.t(
                      'Set your slides to automatically transition at certain intervals'
                    )}
                  >
                    <Field name={optionTypes.AUTO_CHANGE}>
                      {({ field }) => (
                        <React.Fragment>
                          <Checkbox
                            id={field.name}
                            label={i18next.t('Auto change of slides')}
                            checked={field.value}
                            value={field.name}
                            onChange={(value) => {
                              handleAutoChange({ value, field, setFieldValue });
                            }}
                          />
                        </React.Fragment>
                      )}
                    </Field>
                  </ControlGroup>
                  {values.autoChange && (
                    <Field name={optionTypes.AUTO_CHANGE_DELAY}>
                      {({ field }) => (
                        <ControlGroup
                          title={i18next.t('Auto change delay')}
                          description={i18next.t('Choose the time between photo transitions')}
                          additionalInfo={`${field.value} ${i18next.t('sec')}`}
                        >
                          <Slider
                            min={DELAY.min}
                            max={DELAY.max}
                            defaultValue={field.value}
                            onChange={(value) => {
                              handleAutoChangeDelay({ value, field, setFieldValue });
                            }}
                          />
                        </ControlGroup>
                      )}
                    </Field>
                  )}
                </Stack>
              </Box>
            </Panel>
            <Panel style={{ flexShrink: '0' }}>
              <DialogButtonGroup>
                <DialogButton onClick={handleSubmit}>{i18next.t('Submit')}</DialogButton>
                <DialogButtonGroupSeparator />
                <DialogButton onClick={handleCancel}>{i18next.t('Cancel')}</DialogButton>
              </DialogButtonGroup>
            </Panel>
          </PanelGroup>
        </Modal>
      )}
    </Formik>
  );
};

SlideSettingsDialog.propTypes = {
  elementId: PropTypes.string.isRequired,
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

export default SlideSettingsDialog;
