import { HSLColor } from '@yola/yola-palette-generator';
import getCssPropertyByNode from './get-css-property-by-node';
import getSubmoduleConfig from '../../../helpers/get-submodule-config';
import { SUBMODULE_NAME } from '../constants';
import getTextAreas from './get-text-areas';
import parseHslaColorString from '../../../../website-design/helpers/parse-hsla-color-string';

const isBlockTextLight = (block) => {
  const { whiteTextColorVariableName } = getSubmoduleConfig(SUBMODULE_NAME);
  const white = getCssPropertyByNode(block, whiteTextColorVariableName);
  const textAreas = getTextAreas(block);
  const textColors = textAreas.map(({ color }) => {
    const hslaObject = parseHslaColorString(color);
    const hslColor = new HSLColor(hslaObject.h, hslaObject.s, hslaObject.l, hslaObject.a);
    return hslColor.toColorString();
  });

  if (textColors.some((color) => color === white)) return true;

  return false;
};

export default isBlockTextLight;
