import { uuid, blocks, blockTemplates, hdrm, template } from '@yola/ws-sdk';
import store from 'src/js/store';
import onlinestore from 'src/js/modules/onlinestore';
import { DISPLAY_ON_BLOCK_SELECTOR } from '../constants/common';
import adjacentPositions from '../constants/adjacent-positions';
import getAcceptableVariations from './get-acceptable-variations';

const { AFTER_BEGIN, BEFORE_END } = adjacentPositions;

function getAllBlocksForTarget({ targetElementId, adjacentPosition, featureFlags }) {
  const state = store.getState();
  const blockTemplatesList = blockTemplates.selectors.getBlockTemplates(state);
  let target = hdrm.accessors.getParentLiveElement(targetElementId);
  if (adjacentPosition === AFTER_BEGIN || adjacentPosition === BEFORE_END) {
    target = hdrm.accessors.getLiveElement(targetElementId);
  }

  return getAcceptableVariations(
    blocks.filters.findAllFor(target, featureFlags).reduce((result, block) => {
      const blockTemplateItems = blockTemplatesList.filter(
        (blockTemplate) => blockTemplate.id === block.templateId
      );
      const blockDependencies = blockTemplateItems.length ? blockTemplateItems[0].dependencies : [];
      const isOnlineStoreBlock = block.id === onlinestore.constants.ids.ONLINE_STORE_BLOCK;
      const item = {
        id: block.id,
        isPremium: Boolean(block.premium),
        new: Boolean(block.new),
        title: template.accessors.getMetaTranslations(block.title),
        tags: block.tags,
        localizedTags: block.tags.map((tag) => template.accessors.getMetaTranslations(tag)),
        category: block.category,
        localizedCategory: template.accessors.getMetaTranslations(block.category),
        dependencies: blockDependencies,
      };

      if (block.variations) {
        block.variations.forEach((variation) => {
          if (variation.internal) return;

          const preview = isOnlineStoreBlock ? { source: null } : variation.preview;

          result.push({
            ...item,
            uuid: uuid(),
            variationId: variation.id,
            source: variation.source,
            preview,
            html: variation.html,
            properties: variation.properties,
            ...(variation.category && {
              category: variation.category,
              localizedCategory: template.accessors.getMetaTranslations(variation.category),
            }),
            // TODO: Replace `variationTitle` with `title` when all blocks will have readable title
            ...(variation.title && {
              variationTitle: template.accessors.getMetaTranslations(variation.title),
            }),
          });
        });
        // TODO: Remove code below in `else` after full MPT migrations
      } else {
        item.source = block.source;
        item.preview = block.preview;

        result.push(item);
      }
      return result;
    }, []),
    DISPLAY_ON_BLOCK_SELECTOR
  );
}

export default getAllBlocksForTarget;
