import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { i18next } from '@yola/ws-sdk';
import errorModule from './modules/error';
import CriticalErrorDialog from './modules/error/components/critical-error-dialog';
import errorTypes from './modules/error/constants/error-types';

const { SESSION_EXPIRED, NETWORK_OFFLINE } = errorModule.constants;

const getCaptions = () => ({
  sessionExpired: {
    title: i18next.t('Session has expired'),
    description: i18next.t('Please reload to continue building'),
    dismiss: i18next.t('Reload'),
  },
  defaultCaptions: {
    title: i18next.t('Something went wrong'),
    description: i18next.t('Please reload the page'),
    dismiss: i18next.t('Reload'),
  },
});

class ErrorBoundary extends React.Component {
  componentDidCatch(error, errorInfo) {
    const { setCriticalError } = this.props;
    setCriticalError({
      error,
      errorInfo,
      logSentry: true,
    });
  }

  render() {
    const { children, criticalError, networkStatus } = this.props;
    const { sessionExpired, defaultCaptions } = getCaptions();
    let captions = defaultCaptions;
    if (criticalError) {
      if (
        criticalError.message.includes(errorTypes.NETWORK_ERROR) &&
        networkStatus === NETWORK_OFFLINE
      ) {
        return children;
      }
      if (criticalError.message === SESSION_EXPIRED) {
        captions = sessionExpired;
      }
      return <CriticalErrorDialog captions={captions} />;
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  criticalError: PropTypes.instanceOf(Error),
  networkStatus: PropTypes.string,
  setCriticalError: PropTypes.func.isRequired,
};

ErrorBoundary.defaultProps = {
  criticalError: null,
  networkStatus: null,
};

const mapStateToProps = (state) => ({
  criticalError: errorModule.selectors.getCriticalError(state),
  networkStatus: errorModule.selectors.getNetworkStatus(state),
});

const mapDispatchToProps = {
  setCriticalError: errorModule.actions.setCriticalError,
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
