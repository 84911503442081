import { useEffect } from 'react';
import { utils } from '@yola/ws-sdk';
import bowser from 'yola-bowser';

const useScrollPreventing = (config = {}) => {
  const { disableScrollSelector } = config;
  const { preventBodyScroll } = utils;

  useEffect(() => {
    const isAppleWithTouch = bowser.ios || bowser.iPadOS;
    let handleScrollDisabling;

    if (disableScrollSelector) {
      if (isAppleWithTouch) {
        handleScrollDisabling = preventBodyScroll(disableScrollSelector);
        document.addEventListener('touchmove', handleScrollDisabling, {
          passive: true,
        });
      }
    }

    return () => {
      if (disableScrollSelector) {
        if (isAppleWithTouch) {
          document.removeEventListener('touchmove', handleScrollDisabling);
        }
      }
    };
  }, [disableScrollSelector]);
};

export default useScrollPreventing;
