import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import { i18next } from '@yola/ws-sdk';
import dialogs from '../index';

function SimpleDialogContainer({ onClose, titleText }) {
  return (
    <ReactModal isOpen onRequestClose={onClose} className="dialog">
      <div className="dialog-body">
        <div className="dialog-body__title">{titleText}</div>
      </div>
      <div className="dialog-footer">
        <button type="button" onClick={onClose} className="dialog-footer__button">
          {i18next.t('Close')}
        </button>
      </div>
    </ReactModal>
  );
}

SimpleDialogContainer.propTypes = {
  onClose: PropTypes.func.isRequired,
  titleText: PropTypes.string,
  // eslint-disable-next-line yola/react/no-unused-prop-types
  onDialogMainAction: PropTypes.func.isRequired,
};

SimpleDialogContainer.defaultProps = {
  titleText: '',
};

const mapDispatchToProps = (dispatch, { onDialogMainAction }) => ({
  onClose: () => {
    onDialogMainAction();
    dispatch(dialogs.actions.hide());
  },
});
export default connect(null, mapDispatchToProps)(SimpleDialogContainer);
