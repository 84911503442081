import React from 'react';

const CheckboxesIcon = () => (
  <svg width="144" height="90" viewBox="0 0 144 90" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47 49.3H33C32.0611 49.3 31.3 50.0611 31.3 51V65C31.3 65.9389 32.0611 66.7 33 66.7H47C47.9389 66.7 48.7 65.9389 48.7 65V51C48.7 50.0611 47.9389 49.3 47 49.3ZM33 48C31.3431 48 30 49.3431 30 51V65C30 66.6569 31.3431 68 33 68H47C48.6569 68 50 66.6569 50 65V51C50 49.3431 48.6569 48 47 48H33Z"
      fill="#646C75"
    />
    <path
      d="M30 25C30 23.3431 31.3431 22 33 22H47C48.6569 22 50 23.3431 50 25V39C50 40.6569 48.6569 42 47 42H33C31.3431 42 30 40.6569 30 39V25Z"
      fill="#646C75"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44.7071 28.9595C45.0976 29.35 45.0976 29.9832 44.7071 30.3737L39.2071 35.8737C39.0196 36.0613 38.7652 36.1666 38.5 36.1666C38.2348 36.1666 37.9804 36.0613 37.7929 35.8737L35.2929 33.3737C34.9024 32.9832 34.9024 32.35 35.2929 31.9595C35.6834 31.569 36.3166 31.569 36.7071 31.9595L38.5 33.7524L43.2929 28.9595C43.6834 28.569 44.3166 28.569 44.7071 28.9595Z"
      fill="white"
    />
    <rect x="58" y="29" width="57" height="6" rx="2" fill="#DADDE0" />
    <rect x="58" y="55" width="48" height="6" rx="2" fill="#DADDE0" />
  </svg>
);

export default CheckboxesIcon;
