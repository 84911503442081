import actionTypes from 'src/js/modules/context-menu/constants/action-types';

const showContextMenu = ({
  position = {},
  groups,
  triggerId,
  calculatePosition,
  title,
  description,
  submitButton,
}) => ({
  type: actionTypes.SHOW_CONTEXT_MENU,
  payload: {
    position,
    groups,
    calculatePosition,
    triggerId,
    title,
    description,
    submitButton,
  },
});

export default showContextMenu;
