import { i18next } from '@yola/ws-sdk';
import ai from 'src/js/modules/ai';

const {
  constants: { textAssistantInstructions },
} = ai;

const getOptions = () => {
  const ACTIONS = [
    {
      value: textAssistantInstructions.IMPROVE,
      label: i18next.t('Improve writing'),
      iconGlyph: 'magic',
    },
    {
      value: textAssistantInstructions.FIX_GRAMMAR,
      label: i18next.t('Fix grammar'),
      iconGlyph: 'checkbox-square',
    },
    {
      value: textAssistantInstructions.MAKE_LONGER,
      label: i18next.t('Make longer'),
      iconGlyph: 'text-long',
    },
    {
      value: textAssistantInstructions.MAKE_SHORTER,
      label: i18next.t('Make shorter'),
      iconGlyph: 'text-short',
    },
  ];

  return ACTIONS.map(({ value, label, iconGlyph }) => ({
    value,
    label,
    icon: {
      glyph: iconGlyph,
      type: 'accent',
      size: 20,
    },
  }));
};

const getTextAssistantActionGroupOption = () => ({
  value: 'actions',
  label: i18next.t('Quick actions'),
  options: getOptions(),
});

export default getTextAssistantActionGroupOption;
