import { transactions, blocks } from '@yola/ws-sdk';
import getElementById from '../../helpers/get-element-by-id';
import getWhitespaceAutomationInstructions from './helpers/get-whitespace-automation-instructions';
import getElementBySelector from '../../helpers/get-element-by-selector';
import isWsBlock from '../../../blocks/verifiers/is-ws-block';

const getAffectedBlocks = (payload, isStatic, transaction) => {
  const { elementId } = payload;
  const targetBlock = getElementById(elementId, isStatic);

  if (!targetBlock || !isWsBlock(targetBlock)) {
    return [];
  }

  const { initialState: initialTransactionDOM } = transaction;
  const initialTargetBlock = initialTransactionDOM.querySelector(`[id="${targetBlock.id}"]`);

  if (!initialTargetBlock) {
    return [];
  }

  if (targetBlock.matches(`[id^="${blocks.constants.WIDGET_ID_PREFIX}"]`)) {
    let affectedBlocks = [];
    const closestCustomBlock = targetBlock.closest('ws-block[custom]');
    const isFirstInsertedWidget =
      closestCustomBlock.querySelectorAll(`[id^=${blocks.constants.WIDGET_ID_PREFIX}]`).length ===
      1;

    if (isFirstInsertedWidget) {
      affectedBlocks = [
        {
          prevSiblingNode: closestCustomBlock.previousElementSibling,
          targetNode: closestCustomBlock,
        },
        {
          prevSiblingNode: closestCustomBlock,
          targetNode: closestCustomBlock.nextElementSibling,
        },
      ];
    }

    const initialClosestCustomBlock = initialTargetBlock.closest('ws-block[custom]');
    const isLastRemovedWidget =
      initialClosestCustomBlock.querySelectorAll(`[id^=${blocks.constants.WIDGET_ID_PREFIX}]`)
        .length === 1;

    if (isLastRemovedWidget) {
      const customBlockSelector = `[id="${initialClosestCustomBlock.id}"]`;
      const nextSiblingSelector = `[id="${initialClosestCustomBlock.nextElementSibling?.id}"]`;

      affectedBlocks = [
        ...affectedBlocks,
        {
          prevSiblingNode: getElementBySelector(customBlockSelector, isStatic),
          targetNode: getElementBySelector(nextSiblingSelector, isStatic),
        },
      ];
    }

    return affectedBlocks;
  }

  const previousInitialSiblingSelector = `[id="${initialTargetBlock.id}"]`;
  const nextInitialSiblingSelector = `[id="${initialTargetBlock.nextElementSibling?.id}"]`;

  return [
    {
      prevSiblingNode: getElementBySelector(previousInitialSiblingSelector, isStatic),
      targetNode: getElementBySelector(nextInitialSiblingSelector, isStatic),
    },
    {
      prevSiblingNode: targetBlock.previousElementSibling,
      targetNode: targetBlock,
    },
    {
      prevSiblingNode: targetBlock,
      targetNode: targetBlock.nextElementSibling,
    },
  ];
};

const blockIsShifted = (config, payload, transaction) => {
  const { context } = transaction;
  const isStatic = context !== transactions.transactionTypes.LIVE_DOCUMENT;
  const affectedBlocks = getAffectedBlocks(payload, isStatic, transaction);

  return affectedBlocks.reduce(
    (instructions, { prevSiblingNode, targetNode }) => [
      ...instructions,
      ...getWhitespaceAutomationInstructions({ prevSiblingNode, targetNode, isStatic }),
    ],
    []
  );
};

export default blockIsShifted;
