import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';

const { SimpleSelect } = designSystem;

const SimpleSelectField = (props) => {
  const {
    placeholder,
    options,
    defaultValue,
    onChange,
    field: { name },
    form: { setFieldValue, setFieldError, handleBlur },
  } = props;

  const handleChange = (value) => {
    setFieldValue(name, value.value, false);
    onChange(value);
  };

  const handleFocus = () => {
    setFieldError(name, '');
  };

  return (
    <SimpleSelect
      options={options}
      placeholder={placeholder}
      defaultValue={defaultValue}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
    />
  );
};

SimpleSelectField.propTypes = {
  options: PropTypes.array.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.oneOf([null])]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  field: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
    setFieldError: PropTypes.func,
    handleBlur: PropTypes.func,
  }).isRequired,
};

SimpleSelectField.defaultProps = {
  placeholder: '',
  defaultValue: null,
  onChange: Function.prototype,
};

export default SimpleSelectField;
