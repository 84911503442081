import { extensions } from '@yola/ws-sdk';
import config from 'src/js/modules/extensions/registry/ws-image/constants/config';

const isSingleImageResizingDisabled = (node) => {
  const settings = extensions.accessors.getExtensionSettings(config.slug);

  return Boolean(node.closest(settings.restrictSingleImageResizeSelector));
};

export default isSingleImageResizingDisabled;
