import countries from 'i18n-iso-countries';
import cs from 'i18n-iso-countries/langs/cs.json';
import da from 'i18n-iso-countries/langs/da.json';
import de from 'i18n-iso-countries/langs/de.json';
import en from 'i18n-iso-countries/langs/en.json';
import es from 'i18n-iso-countries/langs/es.json';
import fr from 'i18n-iso-countries/langs/fr.json';
import hu from 'i18n-iso-countries/langs/hu.json';
import id from 'i18n-iso-countries/langs/id.json';
import it from 'i18n-iso-countries/langs/it.json';
import nb from 'i18n-iso-countries/langs/nb.json';
import nl from 'i18n-iso-countries/langs/nl.json';
import pl from 'i18n-iso-countries/langs/pl.json';
import pt from 'i18n-iso-countries/langs/pt.json';
import ru from 'i18n-iso-countries/langs/ru.json';
import sk from 'i18n-iso-countries/langs/sk.json';
import sv from 'i18n-iso-countries/langs/sv.json';
import fi from 'i18n-iso-countries/langs/fi.json';

const getTranslations = (locale) => {
  switch (locale) {
    case 'cs':
      return cs;
    case 'da':
      return da;
    case 'de':
      return de;
    case 'es':
      return es;
    case 'fr':
      return fr;
    case 'hu':
      return hu;
    case 'id':
      return id;
    case 'it':
      return it;
    case 'nb':
      return nb;
    case 'nl':
      return nl;
    case 'pl':
      return pl;
    case 'pt':
      return pt;
    case 'ru':
      return ru;
    case 'sk':
      return sk;
    case 'sv':
      return sv;
    case 'fi':
      return fi;
    case 'en':
    default:
      return en;
  }
};

const getCountryOptions = (locale) => {
  const supportedLocale = locale === 'pt-br' ? 'pt' : locale;

  const translations = getTranslations(supportedLocale);
  countries.registerLocale(translations);

  return Object.entries(countries.getNames(supportedLocale)).map(([value, label]) => ({
    value,
    label,
  }));
};

export default getCountryOptions;
