import getUrlFromImageSource from './get-url-from-image-source';
import isGalleryItem from './is-gallery-item';

const getCurrentImageThumbnailUrl = (imageNode) => {
  if (isGalleryItem(imageNode)) {
    return getUrlFromImageSource(imageNode.firstElementChild);
  }

  return null;
};

export default getCurrentImageThumbnailUrl;
