import get from 'lodash.get';
import { config } from '@yola/ws-sdk';

const getRecaptchaSiteKey = (rootState) => {
  const cfg = config.selectors.getConfig(rootState);

  return get(cfg, 'common.recaptcha_v2.user_sites.site_key');
};

export default getRecaptchaSiteKey;
