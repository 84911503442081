import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Tour, TourDialog } from '@yola/ws-ui';
import bowser from 'yola-bowser';
import { dialogs, integration, i18next, utils, site, template } from '@yola/ws-sdk';
import { useDispatch, useSelector } from 'react-redux';
import segment from 'src/js/modules/analytics/segment';
import helpers from '../helpers';
import constants from '../constants';

const {
  track,
  constants: { events },
} = segment;

function MultilingualTourContainer({ onClose, onDialogMainAction, onDialogCancel }) {
  const siteId = site.accessors.getSiteId();
  const templateBuildSlug = template.accessors.getBuildSlug();
  const {
    updateUserPreference,
    userPreferences: { multilingualTourCompleted },
  } = useSelector(integration.selectors.getSettings);

  const setMultilingualTourCompleted = useCallback(() => {
    if (multilingualTourCompleted) {
      return;
    }
    const property = constants.common.MULTILINGUAL_TOUR_COMPLETED;
    const value = true;

    const properties = {
      [integration.constants.SYMBOL_SETTING]: {
        disableBlocking: true,
      },
      property,
      value,
    };
    if (!updateUserPreference) return;
    updateUserPreference({ properties });
  }, [multilingualTourCompleted, updateUserPreference]);

  const dispatch = useDispatch();
  const handleCloseDialog = useCallback(
    (step) => {
      onDialogCancel();

      if (!multilingualTourCompleted) {
        track(events.MULTILINGUAL_TOUR_SKIPPED, {
          step: step + 1,
          siteId,
          templateBuildSlug,
        });
      }

      setMultilingualTourCompleted();
      dispatch(dialogs.actions.hide());
      onClose();
    },
    [multilingualTourCompleted, setMultilingualTourCompleted, onClose, dispatch]
  );

  const handleFinishTour = useCallback(() => {
    onDialogMainAction();

    if (!multilingualTourCompleted) {
      track(events.MULTILINGUAL_TOUR_COMPLETED, {
        siteId,
        templateBuildSlug,
      });
    }
    setMultilingualTourCompleted();
    dispatch(dialogs.actions.hide());
    onClose();
  }, [multilingualTourCompleted, setMultilingualTourCompleted, onClose, dispatch]);

  const items = helpers.getItems(constants.tours.MULTILINGUAL_TOUR);
  if (!multilingualTourCompleted) {
    helpers.preloadVideoSources(items);
  }

  const isMobile = bowser.mobile;
  return (
    <TourDialog>
      <Tour
        className="ws-multilingual-tour"
        handleCloseDialog={handleCloseDialog}
        handleFinishTour={handleFinishTour}
        showPrevButton={!isMobile}
        showNextButton={!isMobile}
        showFinishButton={!isMobile}
        items={items}
        captions={{
          buttons: {
            skip: i18next.t('Skip'),
            next: i18next.t('Next'),
            back: i18next.t('Back'),
            finish: i18next.t('Start editing'),
          },
        }}
      />
    </TourDialog>
  );
}

MultilingualTourContainer.propTypes = {
  onClose: PropTypes.func,
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

MultilingualTourContainer.defaultProps = {
  onClose: utils.noop,
};

export default MultilingualTourContainer;
