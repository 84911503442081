import React from 'react';
import { i18next } from '@yola/ws-sdk';
import { designSystem } from '@yola/ws-ui';
import Trigger from 'src/js/modules/common/components/trigger';

const { Icon } = designSystem;

const getSubmitControl = ({ id, onClick }) => ({
  id,
  trigger: (
    <div
      id={id}
      className="ws-focal-point-controls__trigger ws-focal-point-controls__trigger--bordered"
    >
      <Trigger onClick={onClick} data-tip={i18next.t('Submit')}>
        <Icon glyph="check" />
      </Trigger>
    </div>
  ),
});

export default getSubmitControl;
