import React from 'react';
import { view, i18next, dialogs } from '@yola/ws-sdk';
import { designSystem } from '@yola/ws-ui';
import Trigger from 'src/js/modules/common/components/trigger';
import focalPoint from 'src/js/modules/focal-point';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import getOverlappedMediaContainerByText from '../helpers/get-overlapped-media-container-by-text';

const { Icon } = designSystem;
const { isImageContainer } = focalPoint.helpers;

const triggerId = 'media-setting-control';

const mediaSettingsControl = {
  id: triggerId,
  trigger: (
    <Trigger id={triggerId}>
      <Icon glyph="settings" />
    </Trigger>
  ),
  get title() {
    return i18next.t('Media setting');
  },
  get tooltip() {
    return i18next.t('Media setting');
  },
  priority: 50,
  onTriggerClick(elementId) {
    const liveElement = view.accessors.getLiveElement(elementId);

    if (isImageContainer(liveElement)) {
      dialogs.operations.show(dialogTypes.MEDIA_SETTINGS_DIALOG, { elementId });

      return;
    }

    const overlappedMediaContainer = getOverlappedMediaContainerByText(liveElement);

    if (overlappedMediaContainer) {
      dialogs.operations.show(dialogTypes.MEDIA_SETTINGS_DIALOG, {
        elementId: view.accessors.getLiveElementId(overlappedMediaContainer),
      });
    }
  },
  matches(liveElement) {
    if (isImageContainer(liveElement)) {
      return Boolean(liveElement.querySelector('img'));
    }

    const overlappedMediaContainer = getOverlappedMediaContainerByText(liveElement);

    if (overlappedMediaContainer) {
      return Boolean(overlappedMediaContainer.querySelector('img'));
    }

    return false;
  },
  highlightElement: false,
};

export default mediaSettingsControl;
