import React from 'react';
import { i18next } from '@yola/ws-sdk';
import { designSystem } from '@yola/ws-ui';
import Trigger from 'src/js/modules/common/components/trigger';

const { Icon } = designSystem;

export const getZoomOutControl = ({ id, onClick }) => ({
  id,
  trigger: (
    <Trigger id={id} onClick={onClick} data-tip={i18next.t('Zoom out')} isReadonly={!onClick}>
      <Icon glyph="zoom-out" />
    </Trigger>
  ),
});

export const getZoomInControl = ({ id, onClick }) => ({
  id,
  trigger: (
    <Trigger id={id} onClick={onClick} data-tip={i18next.t('Zoom in')} isReadonly={!onClick}>
      <Icon glyph="zoom-in" />
    </Trigger>
  ),
});
