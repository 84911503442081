import { site, linkEditing } from '@yola/ws-sdk';

const { linkTypes, defaultConfig } = linkEditing.constants;

const resolveInitialConfig = (initialConfig) => {
  const pages = site.accessors.getPages();
  const activePage = site.accessors.getActivePage();
  const { pageId, locale } = initialConfig;
  let { linkType, reference } = initialConfig;

  switch (linkType) {
    case linkTypes.PAGE: {
      const matchedPage = pages.find((item) => item.id === reference);
      if (!matchedPage) return defaultConfig;
      break;
    }

    case linkTypes.ANCHOR: {
      const matchedPage = pages.find((item) => item.id === pageId);
      if (!matchedPage) return defaultConfig;
      break;
    }

    case linkTypes.PATH: {
      if (site.verifiers.isHomePage(reference)) {
        const matchedPage = pages.find((item) => {
          if (site.verifiers.isMultilingualSite()) {
            if (locale) {
              return item.path === `/${locale}/`;
            }

            return item.path === `/${activePage.locale}/`;
          }

          return item.path === reference;
        });

        if (matchedPage) {
          return {
            linkType: linkTypes.PAGE,
            reference: matchedPage.id,
          };
        }

        reference = locale ? `/${locale}${reference}` : reference;
      }

      linkType = linkTypes.EXTERNAL;
      break;
    }
    default:
  }

  return {
    ...initialConfig,
    linkType,
    reference,
  };
};

export default resolveInitialConfig;
