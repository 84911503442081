import { i18next } from '@yola/ws-sdk';
import useFeatureFlags from 'yola-editor/src/js/modules/feature-flags/hooks/use-feature-flags';

import { DONATIONS_CATEGORY_NAME } from '../constants/common';
/*
  Position of the category is matched design (see in epic)
*/
const POSITION = 11;

const useInjectDonationsCategory = (categories) => {
  const [featureFlags] = useFeatureFlags(['donation_blocks']);
  const { donation_blocks: isDonationBlocksEnabled } = featureFlags;

  if (!isDonationBlocksEnabled) return categories;

  const expandedCategories = [...categories];

  expandedCategories.splice(
    expandedCategories.length > POSITION ? POSITION : expandedCategories.length,
    0,
    {
      name: DONATIONS_CATEGORY_NAME,
      description: i18next.t(
        'If you want to collect donation, please take a look at tutorials which we prepare for you:'
      ),
    }
  );

  return expandedCategories;
};

export default useInjectDonationsCategory;
