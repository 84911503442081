import positionProperties from '../constants/position-properties';
import getPositionCustomProperties from './get-position-custom-properties';

const getRoundedValue = (value) => `${Math.round(value * 100) / 100}%`;
const setXProperty = (element, value) =>
  element.style.setProperty(positionProperties.X, getRoundedValue(value));
const setYProperty = (element, value) =>
  element.style.setProperty(positionProperties.Y, getRoundedValue(value));

const setPositionCustomProperties = (element, { x, y }) => {
  const { x: currentX, y: currentY } = getPositionCustomProperties(element);

  if (x !== undefined && currentX !== x) {
    setXProperty(element, x);
  }

  if (y !== undefined && currentY !== y) {
    setYProperty(element, y);
  }
};

export default setPositionCustomProperties;
