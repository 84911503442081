import { template } from '@yola/ws-sdk';

const filterByCategory = (categoryQuery, items) =>
  items.map((item) => {
    if (item.category.toLowerCase() === categoryQuery.toLowerCase()) {
      return item;
    }
    return {
      ...item,
      disabled: true,
    };
  });

const getBlocksWithDependenciesByCategory = (blockItems, category, blockTemplateCollection) => {
  const allBlocksList = blockItems.reduce((result, item) => {
    const blockTemplateItems = blockTemplateCollection.filter(
      (blockTemplate) => blockTemplate.id === item.templateId
    );
    const blockDependencies = blockTemplateItems.length ? blockTemplateItems[0].dependencies : [];
    const tmpItem = {
      id: item.id,
      title: template.accessors.getMetaTranslations(item.title),
      isPremium: Boolean(item.premium),
      category: item.category,
      dependencies: blockDependencies,
      templateId: item.templateId,
      layoutSwitching: item.layoutSwitching === undefined ? true : item.layoutSwitching,
      ...(item.disabled !== undefined && {
        disabled: item.disabled,
      }),
    };

    if (item.variations) {
      item.variations.forEach((variation) => {
        result.push({
          ...tmpItem,
          uuid: `${variation.id}-${item.id}`,
          variationId: variation.id,
          source: variation.source,
          preview: variation.preview,
          html: variation.html,
          properties: variation.properties,
          internal: Boolean(variation.internal),
          ...(variation.category && {
            category: variation.category,
          }),
        });
      });
      // TODO: Remove code below in `else` after full MPT migrations
    } else {
      tmpItem.source = item.source;
      tmpItem.preview = item.preview;

      result.push(tmpItem);
    }
    return result;
  }, []);

  return filterByCategory(category, allBlocksList).filter((item) => !item.disabled);
};

export default getBlocksWithDependenciesByCategory;
