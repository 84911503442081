import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@yola/ws-ui';
import { contentEditable } from '@yola/ws-sdk';
import Trigger from 'src/js/modules/common/components/trigger';
import withSelection from 'src/js/modules/utils/with-selection';
import useElementTypeSelect from '../helpers/use-element-type-select';
import changeElementsType from '../helpers/change-elements-type';

function ElementTypeActionTrigger({ option, elementId, triggerId, onClick }) {
  const { elementType, focusElements, selection } = useElementTypeSelect(elementId);

  function handleClick() {
    onClick();
    if (!option) return;
    contentEditable.operations.addCheckpoint();
    changeElementsType(option, focusElements, selection, elementId);
  }

  return (
    <Trigger
      id={`${triggerId}-${option.value}`}
      active={elementType && elementType.value === option.value}
      onClick={handleClick}
    >
      <Icon glyph={option.glyph} strokeWidth="1.3" />
    </Trigger>
  );
}

ElementTypeActionTrigger.propTypes = {
  elementId: PropTypes.string.isRequired,
  triggerId: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  option: PropTypes.shape({
    value: PropTypes.string.isRequired,
    glyph: PropTypes.string.isRequired,
  }).isRequired,
};

export default withSelection(ElementTypeActionTrigger);
