import tours from './tours';

const viewConfigurations = {
  [tours.WELCOME_TOUR]: [
    {
      bgScreen: 'linear-gradient(150.54deg, #abdcff 0%, #0396ff 100%)',
      className: 'ws-welcome-tour__first-step-screen',
      type: 'image',
    },
    {
      bgScreen:
        'linear-gradient(119.46deg, #e64e4d 0%, #d83024 100%),radial-gradient(58.04% 66.06% at 50% 50%, #e99194 0%, #c72315 100%)',
      type: 'video',
    },
    {
      bgScreen:
        'linear-gradient(119.46deg, #f9de78 0%, #fec31b 100%),radial-gradient(52.89% 87.04% at 50% 50%, #f9de78 0%, #fec31b 100%), #040b13',
      type: 'video',
    },
    {
      bgScreen:
        'linear-gradient(150.54deg, #a1da54 0%, #7dbb24 100%),radial-gradient(50% 75.81% at 50% 50%, #d2f89f 0%, #7dbb24 100%),radial-gradient(67.32% 100.46% at 50% 50%, #a8fb81 0%, #5dcd35 100%)',
      type: 'video',
    },
    {
      bgScreen:
        'linear-gradient(134.59deg, #7cdcd4 -36.29%, #01c1ae 100%),radial-gradient(50% 66.97% at 50% 50%, #7cdcd4 0%, #01c1ae 100%)',
      type: 'video',
    },
    {
      bgScreen:
        'linear-gradient(119.46deg, #65bbf3 0%, #248ce9 100%),radial-gradient(51.86% 129.38% at 50% 50%, #65bbf3 0%, #248ce9 100%), #040b13',
      type: 'video',
    },
  ],
  [tours.MULTILINGUAL_TOUR]: [
    {
      bgScreen: 'linear-gradient(135deg, #8381CC 0%, #001253 100%)',
      type: 'image',
    },
    {
      bgScreen: 'linear-gradient(107.7deg, #9BD8DA 0%, #55BFC5 100%), #5099E6',
      type: 'video',
    },
    {
      bgScreen: 'linear-gradient(107.7deg, #E9A986 0%, #CA6045 100%)',
      type: 'video',
    },
    {
      bgScreen: 'linear-gradient(107.7deg, #82C8D1 0%, #AFDFE0 100%), #5099E6',
      type: 'video',
    },
  ],
};

export default viewConfigurations;
