export const WHITESPACE_TOP_ATTRIBUTE = 'white-space-top';
export const WHITESPACE_TOP = 'whitespace_top';
export const WHITESPACE_BOTTOM_ATTRIBUTE = 'white-space-bottom';
export const WHITESPACE_BOTTOM = 'whitespace_bottom';
export const FULLSCREEN = 'fullscreen';
export const BACKGROUND_HIDDEN = 'show_background_image';
export const BACKGROUND_HIDDEN_ATTRIBUTE = 'background-hidden';
export const SURFACES = 'surfaces';
export const SURFACES_ATTRIBUTE = 'surfaces';
export const STICK_UP_ATTRIBUTE = 'stick-up';
export const BLOCK_WITH_SURFACE_SELECTOR = 'ws-block[surfaces]';
export const OVERLAY_OPACITY_ATTRIBUTE = 'overlay-opacity';
export const OVERLAY_ATTRIBUTE = 'overlay';
export const OVERLAY_SECTION_ATTRIBUTE = 'overlay-section';
export const BORDER_WIDTH = 'border_width';

export const ATTRIBUTES_TO_MARK_AS_USER_MODIFIED = [
  WHITESPACE_TOP_ATTRIBUTE,
  WHITESPACE_BOTTOM_ATTRIBUTE,
  OVERLAY_OPACITY_ATTRIBUTE,
];
