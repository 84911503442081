import React from 'react';
import bowser from 'yola-bowser';
import { i18next } from '@yola/ws-sdk';
import { designSystem } from '@yola/ws-ui';
import Trigger from 'src/js/modules/common/components/trigger';
import { FocalPointConsumer } from '../context';
import constants from '../constants/common';
import ids from '../constants/triggers-ids';

const { Icon, Slider } = designSystem;
const {
  ZOOM_STEP,
  MIN_ZOOM,
  MAX_ZOOM,
  FOCAL_POINTS_IMAGE_CONTROLS_WIDTH,
  FOCAL_POINTS_SLIDER_WIDTH,
} = constants;

const getBackgroundZoomControl = ({
  id,
  onClick,
  onScaleChange,
  onBeforeChange,
  onAfterChange,
  onFitControlClick,
}) => {
  const isMobile = bowser.mobile || bowser.tablet;

  return {
    id,
    trigger: (
      <Trigger onClick={onClick} data-tip={i18next.t('Change zoom')} id={id}>
        <Icon glyph="zoom-in" />
      </Trigger>
    ),
    header: [
      <Icon glyph="zoom-in" />,
      <div
        className="ws-focal-point-controls__slider"
        style={{
          width: `${FOCAL_POINTS_SLIDER_WIDTH}px`,
        }}
      >
        <FocalPointConsumer>
          {({ zoom: zoomValue }) => (
            <Slider
              value={zoomValue}
              min={MIN_ZOOM}
              max={MAX_ZOOM}
              step={ZOOM_STEP}
              {...(!isMobile && {
                handleProps: {
                  'data-tip': `${Math.round(zoomValue)}%`,
                  key: `${zoomValue}`,
                },
              })}
              onChange={onScaleChange}
              onBeforeChange={onBeforeChange}
              onAfterChange={onAfterChange}
            />
          )}
        </FocalPointConsumer>
      </div>,
      <Trigger
        id={ids.BACKGROUND_IMAGE_FIT_TRIGGER}
        onClick={onFitControlClick}
        data-tip={i18next.t('Fit')}
        className="ws-focal-point-controls__trigger--bordered-right"
      >
        <Icon glyph="fill" />
      </Trigger>,
    ],
    activeWidth: FOCAL_POINTS_IMAGE_CONTROLS_WIDTH,
    closeIcon: 'submit',
    submitOnBlur: true,
  };
};

export default getBackgroundZoomControl;
