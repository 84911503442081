import calcVideoSize from './calc-video-size';

const createFacebookLink = (targetUrl, iframeId) => {
  const url = new URL(targetUrl);
  const pathArr = url.pathname.split('/');

  const videoIndex = pathArr.indexOf('videos');
  const videoId = pathArr[videoIndex + 1];

  if (videoIndex && videoId) {
    const newUrl = new URL('https://www.facebook.com/plugins/video.php');
    const params = new URLSearchParams();

    const { width, height } = calcVideoSize(iframeId);

    params.append('href', url);
    params.append('show_text', 0);
    params.append('show_captions', false);
    params.append('width', width);
    params.append('height', height);
    newUrl.search = params;

    return newUrl;
  }
  return url;
};

export default createFacebookLink;
