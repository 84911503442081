import { assets } from '@yola/ws-sdk';
import focalPoint from 'src/js/modules/focal-point';
import generateItemId from './generate-item-id';

const {
  constants: { common: focalPointConstants },
  helpers: { computeAdjustedScale },
} = focalPoint;

const uploadFilesToGallery = async (files, { firstGalleryItem, mediaGroupId, aspectRatio }) => {
  const { getImageOrientation, getImageSize, resetImageExifOrientation } = assets.helpers;
  const { hasExifOrientation } = assets.verifiers;
  try {
    return Promise.all(
      files.map(async (file, index) => {
        let previewAsset = file;
        const orientation = await getImageOrientation(file);

        if (hasExifOrientation(orientation)) {
          previewAsset = await resetImageExifOrientation(file, orientation);
        }

        const source = URL.createObjectURL(previewAsset);
        const imageSize = await getImageSize(source);

        // adjusting content scale so small images want be stretching to container width or height
        const contentScale = computeAdjustedScale(firstGalleryItem, {
          originalMediaBounds: imageSize,
          scale: focalPointConstants.DEFAULT_SCALE,
        });
        const contentPosition = focalPointConstants.DEFAULT_POSITION_STRING_VALUE;

        return {
          id: generateItemId(index, Date.now()),
          originWidth: imageSize.width,
          originHeight: imageSize.height,
          aspectRatio,
          originSrc: source,
          src: source,
          alt: '',
          title: '',
          description: '',
          contentPosition,
          contentScale,
          mediaGroupId,
          file,
        };
      })
    );
  } catch (e) {
    throw e;
  }
};

export default uploadFilesToGallery;
