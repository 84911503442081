import React, { useContext } from 'react';
import { template } from '@yola/ws-sdk';
import { designSystem, Panel } from '@yola/ws-ui';
import BlockSettingsTabsContainer from './block-settings-tabs-container';
import BlockSettingsContext from '../contexts/block-settings-context';
import SettingsSection from '../components/settings-section';

const { Box } = designSystem;

const BlockSettingsContentContainer = () => {
  const blockSettingsContext = useContext(BlockSettingsContext);

  const {
    captions,
    options: { optionalChildren, colorSchemes, displayOptions },
  } = blockSettingsContext;

  if (!template.verifiers.isMpt()) {
    return (
      <Panel height="100%" scrollable style={{ borderBottom: 'none' }}>
        <Box margin="spacing-m" marginTop="spacing-s" marginBottom="spacing-s">
          <SettingsSection key={Date.now()} captions={captions.colorSchemes} items={colorSchemes} />
          <SettingsSection
            key="display-options"
            captions={captions.displayOptions}
            items={displayOptions}
          />
          <SettingsSection
            key="optional-children"
            captions={optionalChildren ? optionalChildren[0] : {}}
            items={optionalChildren}
          />
        </Box>
      </Panel>
    );
  }

  return <BlockSettingsTabsContainer />;
};

export default BlockSettingsContentContainer;
