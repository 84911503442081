import { i18next } from '@yola/ws-sdk';
import bowser from 'yola-bowser';
import dialogs from 'yola-editor/src/js/modules/dialogs';
import auth from 'yola-editor/src/js/modules/auth';
import site from 'yola-editor/src/js/modules/site';
import segment from 'src/js/modules/analytics/segment';
import shareOptions from '../constants/share-options';

const {
  track,
  constants: { events, common },
} = segment;
const { MANUAL_INVITE, WEBSHARE_API, COPY_INVITE } = shareOptions;

const getCaptions = () => ({
  webshareDialogCaptions: {
    title: i18next.t('Yola - Make a Free Website'),
  },
  customDialogCaptions: {
    title: i18next.t('Recommend Yola to a friend'),
    description: i18next.t('Enjoying building a site on Yola? Recommend Yola to your network'),
  },
  invitationMessage: i18next.t(
    'Yola helped me create a professional website in minutes. Signup up to get your own free website today.'
  ),
});

const showShareDialog = (urls) => {
  const captions = getCaptions();

  const trackReferralInitiated = (shareOption) => {
    const option = shareOption === MANUAL_INVITE ? COPY_INVITE : shareOption;
    track(events.FRIEND_REFERRAL_INITIATED, {
      appName: common.APP_NAME,
      siteId: auth.accessors.getSiteId(),
      templateBuildSlug: site.accessors.getSiteTemplateSlug(),
      option,
    });
  };

  // use native WebShare API wherever it is available
  // except desktop safari, since it does not meet our requirements
  if (navigator.share && !(bowser.safari && bowser.desktop)) {
    const { webshareDialogCaptions, invitationMessage } = captions;
    const shortUrl = urls[MANUAL_INVITE];

    navigator
      .share({
        title: webshareDialogCaptions.title,
        text: invitationMessage,
        url: shortUrl,
      })
      .then(() => {
        trackReferralInitiated(WEBSHARE_API);
      });

    return;
  }

  const { customDialogCaptions, invitationMessage } = captions;

  dialogs.operations.show(dialogs.dialogTypes.SHARE_DIALOG, {
    urls,
    invitationMessage,
    onAfterShare: trackReferralInitiated,
    onClose: dialogs.operations.hide,
    captions: customDialogCaptions,
  });
};

export default showShareDialog;
