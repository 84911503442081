import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import InputGroupWithButtonField from './fields/input-group-with-button-field';

const { Stack, Heading, Paragraph, ControlGroup, ActionButton } = designSystem;

function SiteWizardSocialsSection(props) {
  const { captions, socialLinksField } = props;

  return (
    <Stack gap="spacing-m">
      <Stack gap="spacing-3-xs">
        <Heading type="heading-3">{captions.sectionTitle}</Heading>
        <Paragraph>{captions.sectionDescription}</Paragraph>
      </Stack>
      <Stack gap="spacing-xs">
        <ControlGroup title={socialLinksField.label}>
          <Stack gap="spacing-2-xs">
            {socialLinksField.links.map((link, index) => (
              <div key={link.id}>
                <InputGroupWithButtonField
                  input={{
                    control: socialLinksField.control,
                    name: `${socialLinksField.name}.${index}.link`,
                    rules: socialLinksField.rules,
                    placeholder: socialLinksField.placeholder,
                    size: 'medium',
                  }}
                  button={{
                    iconGlyph: 'trash',
                    format: 'text',
                    disabled: socialLinksField.links.length === 1,
                    onClick: () => socialLinksField.onRemoveSocialLink(index),
                  }}
                />
              </div>
            ))}
            <div>
              <ActionButton
                label={socialLinksField.buttonLabel}
                onClick={socialLinksField.onAddSocialLink}
              />
            </div>
          </Stack>
        </ControlGroup>
      </Stack>
    </Stack>
  );
}

SiteWizardSocialsSection.propTypes = {
  captions: PropTypes.shape().isRequired,
  socialLinksField: PropTypes.shape().isRequired,
};

export default SiteWizardSocialsSection;
