import React, { useState, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { utils, i18next, site, dialogs, linkEditing, integration } from '@yola/ws-sdk';
import get from 'lodash.get';
import withFeatureFlags from 'yola-editor/src/js/modules/feature-flags/hoc/with-feature-flags';
import segment from 'src/js/modules/analytics/segment';
import areUnresolvedFeatureFlags from 'yola-editor/src/js/modules/feature-flags/helpers/are-unresolved-feature-flags';
import blocks from 'src/js/modules/blocks';
import errorCodes from 'src/js/modules/extensions/common/constants/error-codes';
import upsell from 'src/js/modules/upsell';
import getItemClickAction from 'src/js/modules/extensions/common/helpers/get-item-click-action';
import validateSocialNetworks from 'src/js/modules/extensions/common/helpers/validate-social-networks';
import LinkSettingDialog from '../components/link-setting-dialog';
import isOpenAddress from '../verifiers/is-open-address';
import isValidWhatsAppLink from '../verifiers/is-whats-app-link';
import constants from '../constants';
import helpers from '../helpers';
import useCustomPathState from '../hooks/use-custom-path-state';

const {
  trackers: { trackEvent, trackLinkSettingsInputClicked },
  constants: { events },
} = segment;

const {
  validation: { isURL, isFullyURL, isPhoneNumber, isEmail },
  forceHTTPS,
} = utils;

const {
  linkSettings: { DIALOG_WIDTH, DIALOG_HEIGHT, INPUTS_IDS, DOWNLOAD_OPTIONS_VALUES },
} = constants;

const { BLANK, NOFOLLOW } = linkEditing.constants.common;
const { linkTypes } = linkEditing.constants;

const { getLinkTypeOptions, sortAlphabetically, getDefaultLinkTypes } = helpers;
const pathRegexp = /^\/(?!ws\/)([^\s?^/#]+\/?)+[^/]$/;

const LinkSettingContainer = (props) => {
  const activePageId = useSelector(site.selectors.getActivePageId);
  const activePage = useSelector((state) => site.selectors.getPage(state, activePageId));
  const pages = useSelector(site.selectors.getPages);
  const upsells = useSelector(integration.selectors.getUpsells);
  const limits = useSelector(integration.selectors.getLimits);
  const pagesOptions = useMemo(
    () =>
      pages
        .map(({ id, name, locale }) => ({ value: id, label: name, locale }))
        .sort((a, b) => sortAlphabetically(a.label, b.label)),
    [pages]
  );

  const currentPageBlocksOptions = useMemo(() => blocks.helpers.getBlocksReadableNames(), []);

  const noFollowLimit = useMemo(() => get(limits, upsell.features.NOFOLLOW_CONTROLS), [limits]);

  const {
    currentConfig,
    initialConfig,
    initialTitle,
    featureFlags,
    handleCancel,
    handleSubmit,
    handleChange,
    offsetX,
    offsetY,
    centered,
    element,
    sourceDialog,
    allowedLinkTypes,
    appearance,
    ...rest
  } = props;

  const isDialog = appearance === constants.common.DIALOG_APPEARANCE;
  const {
    nofollow_controls: isNofollowControlsFeatureEnabled,
    limited_external_links: isLimitedExternalLinksEnabled,
  } = featureFlags;

  const dialogPosition = useMemo(() => {
    if (!isDialog) return null;

    return !centered && (offsetX === null || offsetY === null)
      ? dialogs.helpers.getPositionByElement(element, DIALOG_WIDTH, DIALOG_HEIGHT)
      : { offsetX, offsetY };
  }, [offsetX, offsetY, element, centered, isDialog]);

  const cfg = currentConfig || initialConfig;

  let initialLinkType = currentConfig?.linkType;
  let initialReference = currentConfig?.reference;

  if (!initialLinkType) {
    const tmpConfig = helpers.resolveInitialConfig(initialConfig);
    initialLinkType = tmpConfig.linkType;
    initialReference = tmpConfig.reference;
  }

  const initialAnchorReference = initialLinkType === linkTypes.ANCHOR ? initialReference : '';

  const [linkType, setLinkType] = useState(initialLinkType);
  const [reference, setReference] = useState(initialReference);
  const [latestAnchorReference, setLatestAnchorReference] = useState(initialAnchorReference);
  const [pageId, setPageId] = useState(cfg.pageId);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(cfg.download);
  const [targetBlank, setTargetBlank] = useState(
    linkType !== linkTypes.DOWNLOAD && cfg.target ? cfg.target === BLANK : false
  );
  const [downloadOption, setDownloadOption] = useState(
    linkType === linkTypes.DOWNLOAD && cfg.target
      ? DOWNLOAD_OPTIONS_VALUES.openInNewTab
      : DOWNLOAD_OPTIONS_VALUES.downloadOnComputer
  );
  const [nofollowControl, setNofollowControl] = useState(
    cfg.rel ? cfg.rel === NOFOLLOW : !noFollowLimit.available
  );
  const [title, setTitle] = useState(initialTitle);

  const onDownloadUrlToggle = useCallback(() => {
    trackEvent(events.LINK_SETTINGS_OPTION_CLICKED, {
      dialogId: sourceDialog.id,
      optionId: 'set-download-url',
      newTabAdjusted: null,
    });
  }, [sourceDialog.id]);

  const {
    state: customPathState,
    setCustomPath,
    toggleCustomPathField,
    enableCustomPath,
  } = useCustomPathState(linkType, reference, {
    onDownloadUrlToggle,
  });

  const blocksOptions = useMemo(() => {
    let options = currentPageBlocksOptions;

    if (initialConfig.linkType === linkTypes.ANCHOR) {
      const { pageId: initialPageId } = initialConfig;

      if (activePageId !== initialPageId) {
        const matchedPage = pages.find((page) => page.id === initialPageId);
        if (matchedPage) {
          options = [
            {
              label: matchedPage.title,
              options: [
                {
                  label: `#${initialConfig.reference}`,
                  value: initialConfig.reference,
                  pageId: initialPageId,
                },
              ],
            },
            {
              label: activePage.title,
              options: currentPageBlocksOptions,
            },
          ];
        }
      }
    }

    return options;
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  const linkSelectorOptions = useMemo(() => {
    const allowedTypes =
      allowedLinkTypes.length !== 0
        ? allowedLinkTypes
        : getDefaultLinkTypes(isLimitedExternalLinksEnabled);

    return {
      pagesOptions,
      blocksOptions,
      linkTypeOptions: getLinkTypeOptions(allowedTypes),
    };
  }, [allowedLinkTypes, isLimitedExternalLinksEnabled, pagesOptions, blocksOptions]);

  const linkSelectorCaptions = useMemo(
    () => ({
      fileUploadCaptions: {
        button: i18next.t('Choose file'),
        empty: i18next.t('No file chosen'),
        fileItemName: fileName,
      },
      pagesCaptions: {
        noResultsText: i18next.t('No results found'),
      },
      blocksCaptions: {
        placeholder: i18next.t('Select block...'),
        noResultsText: i18next.t('No results found'),
      },
    }),
    [fileName]
  );

  const getChangePayload = () => {
    const config = {
      linkType,
      reference,
    };

    if (linkType === linkTypes.DOWNLOAD) {
      if (file) config.file = file;

      config.download = fileName;

      if (downloadOption === DOWNLOAD_OPTIONS_VALUES.openInNewTab) {
        config.target = BLANK;
      }

      if (customPathState.visible && customPathState.value && customPathState.value.trim()) {
        config.customPath = customPathState.value.trim();
      }
    }

    if (linkType === linkTypes.ANCHOR) {
      config.pageId = pageId;
    }

    if (linkType === linkTypes.PHONE || linkType === linkTypes.MAIL) {
      config.target = BLANK;
    }

    if (linkType === linkTypes.PAGE) {
      const page = site.accessors.getPages().find(({ id }) => id === reference);
      const isHome = site.verifiers.isHomePage(page.path);
      if (isHome) {
        const tmpConfig = {
          linkType: linkTypes.PATH,
          reference: '/',
        };

        if (page.locale !== activePage.locale) {
          tmpConfig.locale = page.locale;
        }

        return tmpConfig;
      }
    }

    if (isOpenAddress(linkType)) {
      if (isURL(reference.trim())) {
        config.linkType = linkTypes.EXTERNAL;
        config.reference = forceHTTPS(reference.trim());

        if (isNofollowControlsFeatureEnabled && nofollowControl) {
          config.rel = NOFOLLOW;
        }
      } else {
        config.linkType = linkTypes.PATH;
      }

      if (targetBlank) {
        config.target = BLANK;
      }
    }

    if (linkType === linkTypes.WHATS_APP || linkType === linkTypes.SOCIAL_MEDIA) {
      config.reference = forceHTTPS(reference.trim());
    }

    return config;
  };

  useEffect(
    () => {
      if (!isDialog) {
        handleChange(getChangePayload());
      }
    },
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
    [
      linkType,
      reference,
      targetBlank,
      nofollowControl,
      fileName,
      downloadOption,
      customPathState.visible,
      customPathState.value,
    ]
  );

  const onLinkChange = useCallback(
    (data) => {
      const { linkType: newLinkType, reference: newReference, pageId: newPageId } = data;

      if (newLinkType === linkTypes.ANCHOR) {
        setLatestAnchorReference(newReference);
      }

      if (newLinkType === linkTypes.DOWNLOAD) {
        setFile(null);
        setFileName('');
        enableCustomPath();
      }

      setReference(newReference);
      setLinkType(newLinkType);
      setPageId(newPageId);
    },
    [enableCustomPath]
  );

  const isExternalLinksDisabled = isLimitedExternalLinksEnabled && !limits.externalLinks.available;
  const isExternalLinksUnresolved = areUnresolvedFeatureFlags(
    ['limited_external_links'],
    featureFlags
  );

  // Do not use value directly from function parameter,
  // since it doesn't work when LinkSelectorContainer is rendered
  // in MediaSettingsDialog.
  const validatePath = () => {
    const { value } = customPathState;

    if (!value) {
      return i18next.t('Invalid URL. Please, check the URL and try again');
    }

    if (!value.startsWith('/')) {
      return i18next.t('The "/" is missing at the beginning');
    }

    if (value.endsWith('/')) {
      return i18next.t('URL can\'t end with "/". Please remove it');
    }

    if (!pathRegexp.test(value)) {
      return i18next.t('Invalid URL. Please, check the URL and try again');
    }

    return null;
  };

  const validateLink = () => {
    const { PHONE, MAIL, PATH, DOWNLOAD, EXTERNAL, SOCIAL_MEDIA, WHATS_APP, ANCHOR } = linkTypes;

    const isMakePhoneCall = linkType === PHONE;
    const isSendEmail = linkType === MAIL;
    const isDownloadFile = linkType === DOWNLOAD;
    const isOpenAddres = isOpenAddress(linkType);
    const isSocial = linkType === SOCIAL_MEDIA;
    const isWhatsApp = linkType === WHATS_APP;
    const isAnchor = linkType === ANCHOR;

    if (isSocial) {
      if (!reference) return i18next.t('Invalid URL. The URL can not be empty');
      return validateSocialNetworks(reference.trim());
    }

    if (isWhatsApp) {
      if (!reference) return i18next.t('Invalid URL. The URL can not be empty');

      if (!isValidWhatsAppLink(reference.trim())) {
        return i18next.t('The URL must lead to the WhatsApp account');
      }
    }

    if (isMakePhoneCall) {
      if (!reference) return i18next.t('Invalid phone number. The phone number can not be empty');
      if (!isPhoneNumber(reference.trim()))
        return i18next.t('Invalid phone number. Please, check the phone number and try again');
    }

    if (isSendEmail) {
      if (!reference) return i18next.t('Invalid email. The email can not be empty');
      if (!isEmail(reference.trim()))
        return i18next.t('Invalid email. Please, check the email and try again');
    }

    if (isAnchor) {
      if (!reference) return i18next.t('Please select some block');
    }

    if (!isOpenAddres && !isDownloadFile) return null;

    if (isDownloadFile && !fileName) {
      return i18next.t('You need to attach a file');
    }

    if (!isOpenAddres) return null;
    if (isOpenAddres && isExternalLinksDisabled) return null;

    const val = reference.trim();

    if (!val) {
      return i18next.t('Invalid URL. The URL can not be empty');
    }

    if (val === '#') return null;

    const type = val.length > 3 && isURL(val) ? EXTERNAL : PATH;

    if (type === PATH) {
      const tmpURL = `https://yola.com${val}`;
      if (!val.startsWith('/') || !isURL(tmpURL)) {
        return i18next.t('Invalid URL. Please, check the URL and try again');
      }
    } else if (!isFullyURL(val)) {
      return i18next.t('Invalid URL. Please, check the URL and try again');
    }

    return null;
  };

  const handleUploadFile = useCallback(
    (newFile) => {
      setFile(newFile);
      setFileName(newFile.name);
      setLinkType(linkTypes.DOWNLOAD);
      enableCustomPath();
    },
    [enableCustomPath]
  );

  const onSubmit = (newOffsetX, newOffsetY) => {
    if (!isDialog) return;

    const configData = getChangePayload();

    helpers
      .processLinkSubmit({
        config: configData,
        isNofollowControlsFeatureEnabled,
      })
      .then((newConfig) => {
        handleSubmit({
          customPath: customPathState.value || null,
          setDownloadUrlAdjusted: linkTypes.DOWNLOAD === linkType ? customPathState.visible : null,
          config: newConfig,
          title,
          offsetX: newOffsetX,
          offsetY: newOffsetY,
        });
      })
      .catch((error) => {
        if (error === errorCodes.UPLOAD_FILE_CANCELED) {
          handleCancel({ offsetX: newOffsetX, offsetY: newOffsetY });
          return;
        }

        if (error === errorCodes.UPSELL_INTERRUPTION_CANCELLED) {
          dialogs.operations.show(sourceDialog.id, {
            ...sourceDialog.props,
            initialConfig,
            currentConfig: {
              ...configData,
              rel: NOFOLLOW,
            },
            initialTitle: title,
            offsetX: newOffsetX,
            offsetY: newOffsetY,
          });

          return;
        }

        console.error(error);
      });
  };

  const onUpgradeLinksClick = () => {
    trackEvent(events.LINK_SETTINGS_UPGRADE_TRIGGER_CLICKED, {
      dialogId: sourceDialog.id,
    });

    upsells.externalLinks.onUpgrade().catch(() => {
      // eslint-disable-next-line no-console
      console.log('Upgrade flow was canceled');
    });
  };

  const handleTargetBlankChange = (checked) => {
    setTargetBlank(checked);
    trackEvent(events.LINK_SETTINGS_OPTION_CLICKED, {
      dialogId: sourceDialog.id,
      newTabAdjusted: checked,
      optionId: 'open-in-new-tab',
    });
  };

  const handleDownloadOptionChange = (value) => {
    setDownloadOption(value);
    trackEvent(events.LINK_SETTINGS_OPTION_CLICKED, {
      dialogId: sourceDialog.id,
      optionId: value,
      newTabAdjusted: null,
    });
  };

  const handleNoFollowChange = (checked) => {
    setNofollowControl(checked);
    trackEvent(events.LINK_SETTINGS_OPTION_CLICKED, {
      dialogId: sourceDialog.id,
      optionId: 'add-no-follow-tag',
      newTabAdjusted: null,
    });
  };

  const handleInputClick = (inputId, newLinkType) => {
    if (inputId === INPUTS_IDS.downloadInput) {
      trackEvent(events.LINK_SETTINGS_CHOOSE_FILE_CLICKED, {
        dialogId: sourceDialog.id,
      });
      return;
    }

    const isActionTypeSelectClicked = inputId === INPUTS_IDS.actionTypeInput;

    const traits = {
      dialogId: sourceDialog.id,
      inputId: isActionTypeSelectClicked ? null : inputId,
      actionType: isActionTypeSelectClicked ? getItemClickAction(newLinkType) : null,
    };

    trackLinkSettingsInputClicked(traits);
  };

  return (
    <LinkSettingDialog
      linkType={linkType}
      reference={reference}
      latestAnchorReference={latestAnchorReference}
      targetBlank={targetBlank}
      nofollowControl={nofollowControl}
      downloadOption={downloadOption}
      title={title}
      linkSelectorOptions={linkSelectorOptions}
      linkSelectorCaptions={linkSelectorCaptions}
      showNoFollowCheckbox={isNofollowControlsFeatureEnabled}
      handleDownloadOption={handleDownloadOptionChange}
      handleLinkChange={onLinkChange}
      validateLink={validateLink}
      handleSubmit={onSubmit}
      handleCancel={handleCancel}
      handleTitleChange={setTitle}
      handleTargetBlankChange={handleTargetBlankChange}
      handleNoFollowChange={handleNoFollowChange}
      handleUploadFile={handleUploadFile}
      dialogPosition={dialogPosition}
      centered={centered}
      appearance={appearance}
      onUpgradeLinksClick={onUpgradeLinksClick}
      isExternalLinksDisabled={isExternalLinksDisabled}
      isExternalLinksUnresolved={isExternalLinksUnresolved}
      onInputClick={handleInputClick}
      customPathVisible={customPathState.visible}
      customPathDisabled={customPathState.disabled}
      customPathIsLoading={customPathState.isLoading}
      customPathValue={customPathState.value}
      pathValidation={validatePath}
      toggleCustomPathField={toggleCustomPathField}
      onCustomPathChange={setCustomPath}
      {...rest}
    />
  );
};

LinkSettingContainer.propTypes = {
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,
  captions: PropTypes.shape().isRequired,
  initialConfig: PropTypes.shape({
    linkType: PropTypes.string.isRequired,
    reference: PropTypes.string,
    download: PropTypes.string,
    target: PropTypes.string,
    rel: PropTypes.string,
    pageId: PropTypes.string,
  }).isRequired,
  currentConfig: PropTypes.shape({
    linkType: PropTypes.string.isRequired,
    reference: PropTypes.string,
    download: PropTypes.string,
    target: PropTypes.string,
    rel: PropTypes.string,
    pageId: PropTypes.string,
  }),
  initialTitle: PropTypes.string,
  modalAttributes: PropTypes.shape({}),
  featureFlags: PropTypes.shape({
    nofollow_controls: PropTypes.bool,
    limited_external_links: PropTypes.bool,
  }).isRequired,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  handleChange: PropTypes.func,
  showTitle: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
  handleDeleteLink: PropTypes.func,
  element: PropTypes.object,
  setIsOverlayClicked: PropTypes.func,
  sourceDialog: PropTypes.shape({
    id: PropTypes.string.isRequired,
    props: PropTypes.shape({}),
  }).isRequired,
  allowedLinkTypes: PropTypes.array,
  centered: PropTypes.bool,
  appearance: PropTypes.oneOf([
    constants.common.DIALOG_APPEARANCE,
    constants.common.DIALOG_BLOCK_APPEARANCE,
  ]),
};

LinkSettingContainer.defaultProps = {
  offsetX: null,
  offsetY: null,
  element: {},
  currentConfig: null,
  initialTitle: '',
  modalAttributes: {},
  showTitle: true,
  showDeleteButton: false,
  handleDeleteLink: utils.noop,
  setIsOverlayClicked: utils.noop,
  handleSubmit: utils.noop,
  handleCancel: utils.noop,
  handleChange: utils.noop,
  allowedLinkTypes: [],
  centered: null,
  appearance: constants.common.DIALOG_APPEARANCE,
};

export default withFeatureFlags(['nofollow_controls', 'limited_external_links'])(
  LinkSettingContainer
);
