import React from 'react';
import PropTypes from 'prop-types';
import { i18next } from '@yola/ws-sdk';
import ErrorDialog from '../../dialogs/components/error-dialog';

const ExportErrorDialog = ({ onDialogMainAction, onDialogCancel }) => (
  <ErrorDialog
    captions={{
      title: i18next.t('Site export failed'),
      description: i18next.t('An error occurred while export your site'),
    }}
    onDialogMainAction={onDialogMainAction}
    onDialogCancel={onDialogCancel}
  />
);

ExportErrorDialog.propTypes = {
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

export default ExportErrorDialog;
