import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { context as viewportContext } from 'yola-editor/src/js/utils/viewport-provider';
import { Modal, designSystem } from '@yola/ws-ui';
import bowser from 'yola-bowser';
import getPagePreview from '../helpers/get-page-preview';
import fieldNames from '../constants/field-names';

const {
  Heading,
  Paragraph,
  Box,
  Stack,
  ActionButton,
  Divider,
  SimpleSelectField,
  FieldStatus,
  InputGroupField,
  CheckboxField,
  ControlGroup,
  Tag,
  BetaLabel,
} = designSystem;

const DEFAULT_WIDTH = 812;

function AddPageDialog(props) {
  const {
    captions,
    pageOptions,
    selectedOption,
    onCancel,
    onSubmit,
    onPageOptionChange,
    showPreviewLabel,
    control,
    fields,
    formErrors,
  } = props;
  const { width } = useContext(viewportContext);
  const isSmallScreen = bowser.mobile || width <= DEFAULT_WIDTH;
  const Preview = getPagePreview(selectedOption.value);

  return (
    <Modal
      width={DEFAULT_WIDTH}
      height="auto"
      overlay="visible"
      overlaySpacing={{
        x: 16,
        y: 16,
      }}
      centered
      draggable={false}
      resizable={false}
      handleCancel={onCancel}
    >
      <div className="ws-add-page-dialog">
        {!isSmallScreen && (
          <div className="ws-add-page-dialog__preview-container">
            <div className="ws-add-page-dialog__image-container">
              {showPreviewLabel && (
                <div className="ws-add-page-dialog__label-container">
                  <Tag label={captions.previewLabel} iconGlyph="ai" />
                </div>
              )}

              <Preview />
            </div>
          </div>
        )}

        <div className="ws-add-page-dialog__content-container">
          {onCancel && (
            <div className="ws-add-page-dialog__close-button">
              <ActionButton onClick={onCancel} format="text" iconGlyph="close" />
            </div>
          )}
          <Box
            paddingTop="spacing-3-xl"
            paddingBottom="spacing-3-xl"
            paddingLeft="spacing-xl"
            paddingRight="spacing-xl"
          >
            <Heading type="heading-3">
              {captions.heading}
              {showPreviewLabel && (
                <span>
                  {' '}
                  <BetaLabel />
                </span>
              )}
            </Heading>

            <Box marginTop="spacing-3-xs">
              <Paragraph>{captions.description}</Paragraph>
            </Box>

            <Box marginTop="spacing-m">
              <Stack gap="spacing-3-xs">
                <SimpleSelectField
                  name={fieldNames.PAGE_TEMPLATE_SELECT}
                  control={control}
                  options={pageOptions}
                  onChange={onPageOptionChange}
                />
                {captions.pageTemplateSubtitle && (
                  <Paragraph appearance="medium-emphasis" size="small">
                    {captions.pageTemplateSubtitle}
                  </Paragraph>
                )}
              </Stack>
            </Box>

            <Box marginTop="spacing-xs">
              <ControlGroup title={captions.inputTitle} required>
                <InputGroupField
                  id={fieldNames.PAGE_TEMPLATE_SELECT}
                  name={fieldNames.PAGE_NAME_INPUT}
                  control={control}
                  rules={fields[fieldNames.PAGE_NAME_INPUT].rules}
                />
                {(!formErrors[fieldNames.PAGE_NAME_INPUT] ||
                  !formErrors[fieldNames.PAGE_NAME_INPUT].message) && (
                  <FieldStatus text={captions.inputSubtitle} />
                )}
              </ControlGroup>
            </Box>

            <Box marginTop="spacing-xs">
              <CheckboxField
                id={fieldNames.DISPLAY_IN_NAVIGATION_CHECKBOX}
                name={fieldNames.DISPLAY_IN_NAVIGATION_CHECKBOX}
                label={captions.checkboxTitle}
                control={control}
                value={fieldNames.DISPLAY_IN_NAVIGATION_CHECKBOX}
              />
            </Box>
          </Box>

          <Divider />

          <Box
            paddingTop="spacing-xs"
            paddingBottom="spacing-xs"
            paddingLeft="spacing-xl"
            paddingRight="spacing-xl"
          >
            <div className="ws-add-page-dialog__submit-button-container">
              <ActionButton
                appearance="accent"
                format="solid"
                iconGlyph={showPreviewLabel ? 'ai' : null}
                label={captions.button}
                size="large"
                onClick={onSubmit}
              />
            </div>
          </Box>
        </div>
      </div>
    </Modal>
  );
}

AddPageDialog.propTypes = {
  onCancel: PropTypes.func,
  onPageOptionChange: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  captions: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    button: PropTypes.string,
    pageTemplateSubtitle: PropTypes.string,
    inputTitle: PropTypes.string,
    inputSubtitle: PropTypes.string,
    checkboxTitle: PropTypes.string,
    previewLabel: PropTypes.string,
  }),
  pageOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  selectedOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  showPreviewLabel: PropTypes.bool,
  fields: PropTypes.shape().isRequired,
  control: PropTypes.shape().isRequired,
  formErrors: PropTypes.shape().isRequired,
};

AddPageDialog.defaultProps = {
  onCancel: null,
  onPageOptionChange: Function.prototype,
  pageOptions: [],
  captions: {},
  selectedOption: null,
  showPreviewLabel: false,
};

export default AddPageDialog;
