import actionTypes from '../constants/action-types';

const setVisibilityStatus = (isVisible = false) => ({
  type: actionTypes.SET_VISIBILITY_STATUS,
  payload: {
    isVisible,
  },
});

export default setVisibilityStatus;
