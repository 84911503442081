import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { designSystem } from '@yola/ws-ui';

const { Stack, Box, Paragraph, IconButton } = designSystem;

const SiteWizardSummaryItem = (props) => {
  const { title, buttonLabel, children, isDesktopView, onClick } = props;
  const classes = classNames('ws-wizard-setup-summary-item', {
    'ws-wizard-setup-summary-item--desktop-view': isDesktopView,
  });

  return (
    <div className={classes}>
      <Box padding="spacing-xs" paddingTop="spacing-3-xs" paddingBottom="spacing-3-xs">
        <Stack gap="spacing-3-xs">
          <div className="ws-wizard-setup-summary-item__title">
            <Paragraph appearance="medium-emphasis" size="small">
              {title}
            </Paragraph>
            <div className="ws-wizard-setup-summary-item__button">
              <IconButton
                appearance="accent"
                glyph="contact-form"
                label={buttonLabel}
                size="small"
                transparent
                onClick={onClick}
                {...(isDesktopView && {
                  selected: true,
                })}
              />
            </div>
          </div>
          <div className="ws-wizard-setup-summary-item__content">{children}</div>
        </Stack>
      </Box>
      {isDesktopView && (
        <div className="ws-wizard-setup-summary-item__clickable-area" onClick={onClick} />
      )}
    </div>
  );
};

SiteWizardSummaryItem.propTypes = {
  title: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isDesktopView: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SiteWizardSummaryItem;
