import { view, site, blocks, template } from '@yola/ws-sdk';
import setDragSourceElement from '../actions/set-drag-source-element';
import cache from '../utils/cache';
import store from '../../../store';
import analytics from '../../analytics';
import getParentBlockByElement from '../../blocks/helpers/get-parent-block-by-element';

const beginDragHandler = (props, monitor, component) => {
  const { elementId } = props;
  const element = view.accessors.getLiveElement(elementId);
  const closestBlock = getParentBlockByElement(element);

  cache.add(component.triggerRef);

  store.dispatch(setDragSourceElement(elementId));
  store.dispatch(view.actions.setHoveredElement(null));
  store.dispatch(view.actions.setFocusedElement(null));
  store.dispatch(view.actions.setSelectedElement(null));

  view.helpers.autoScroll.start();
  element.setAttribute('data-ws-dragging', true);

  const {
    segment: {
      track,
      constants: { events },
    },
  } = analytics;

  track(events.CONTROL_PANE_TRIGGER_CLICKED, {
    triggerId: 'block-move',
    extensionSlug: null,
    siteId: site.accessors.getSiteId(),
    templateBuildSlug: template.accessors.getBuildSlug(),
    blockId: blocks.accessors.getBlockIdByElement(closestBlock),
    blockVariationId: blocks.accessors.getVariationIdByElement(closestBlock),
  });

  return props;
};

export default beginDragHandler;
