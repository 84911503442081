import { extensions, template, view } from '@yola/ws-sdk';
import store from '../../../store';
import { EXTENSION_SLUG, VARIABLE_NAME } from '../constants/common';

function isAvailable() {
  const state = store.getState();

  const extension = extensions.selectors.getExtension(state, EXTENSION_SLUG);
  const settings = extensions.selectors.getExtensionSettings(state, EXTENSION_SLUG);
  const isGlobalAvailable = template.verifiers.isVariableGlobalsAvailable(VARIABLE_NAME);

  const liveDocument = view.accessors.getLiveDocument();

  if (!liveDocument || !isGlobalAvailable || !extension || !settings) {
    return false;
  }

  const navigationElement = liveDocument.querySelector(settings.querySelector);

  return Boolean(navigationElement);
}

export default isAvailable;
