export default {
  ENTER_KEY: 'Enter',
  POPOVER_ANIMATION_TIME: 800,
  MAX_DESCRIPTION_LENGTH: 160,
  MAX_TITLE_TAG_LENGTH: 70,
  MAX_PAGE_NAME_LENGTH: 70,
  HEAD_CODE_NAME: 'headCode',
  HEADER_CODE_NAME: 'headerCode',
  FOOTER_CODE_NAME: 'footerCode',
  PAGE_LOCALE: 'htmlLocale',
  /* eslint-disable no-useless-escape */
  LETTER_OR_NUMBER_REGEXP: /[\p{L}0-9]+/u,
  PUBLISH_PATH_REGEXP: /^\/([\p{L}0-9-_.]+\/?)*$/u,
  /* eslint-enable no-useless-escape */
  PUBLISH_PATH_RESERVED_REGEXP: /\/(ws|e)(?=$|\/)/,
  DEFAULT_SITE_URL: 'https://yoursitedomain',
  ADD_PAGE_DIALOG_MOBILE_WIDTH: 314,
  ADD_PAGE_DIALOG_WIDTH: 360,
};
