import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import {
  i18next,
  site,
  siteLocales,
  dialogs,
  saving,
  template,
  hooks,
  integration,
} from '@yola/ws-sdk';
import withFeatureFlags from 'yola-editor/src/js/modules/feature-flags/hoc/with-feature-flags';
import trackers from 'src/js/modules/analytics/segment/trackers';
import { selectors } from '@yola/subscription-manager-js';
import getFeatureLimits from 'yola-editor/src/js/modules/features/accessors/get-feature-limits';
import PageManager from '../components/page-manager';
import getOptionsToCreatePage from '../helpers/get-options-to-create-page';
import generatePageName from '../helpers/generate-page-title';
import generateUniquePageTitle from '../helpers/generate-unique-page-title';
import getNewPageModalCaptions from '../helpers/get-new-page-modal-captions';
import constants from '../constants';
import isPageCreationAvailable from '../verifiers/is-page-creation-available';
import getLanguageCodes from '../../website-settings/helpers/get-language-codes';
import injectStoreId from '../../onlinestore/helpers/inject-store-id';
import fieldNames from '../constants/field-names';
import pageTypes from '../constants/page-types';

const {
  trackPageManagerSearchPerformed,
  trackPageManagerSearchClicked,
  trackAddAiPageTriggerClicked,
} = trackers;

function createFilterCb(pages, locale) {
  const pageManagerOptions = [pageTypes.ABOUT_US, pageTypes.CONTACT_US];
  const targetPages = pages.filter((page) => page.locale === locale);

  return (option) =>
    pageManagerOptions.includes(option.id) &&
    !targetPages.some((page) => page.recipeId === option.id);
}

class PageManagerContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pages: props.pages,
      filteredPages: [],
      filtered: false,
      optionsToCreatePage: getOptionsToCreatePage(createFilterCb(props.pages, props.locale)),
    };

    this.onItemClick = this.onItemClick.bind(this);
    this.filter = this.filter.bind(this);
    this.deletePage = this.deletePage.bind(this);
    this.editPageSetting = this.editPageSetting.bind(this);
    this.setHomePage = this.setHomePage.bind(this);
    this.onAddNewPage = this.onAddNewPage.bind(this);
    this.openPageCreation = this.openPageCreation.bind(this);
    this.onCopyPage = this.onCopyPage.bind(this);
    this.openDeveloperConsole = this.openDeveloperConsole.bind(this);
  }

  onItemClick(item) {
    const {
      onSetActivePage,
      savingStatus,
      onUnsavedActivePageChange,
      html,
      htmlCheckpoint,
      activePageId,
    } = this.props;
    const { UNSAVED } = saving.constants.statuses;

    // Do nothing if user clicks on active page
    if (item.id === activePageId) {
      return;
    }

    if (savingStatus === UNSAVED && html !== htmlCheckpoint) {
      onUnsavedActivePageChange(onSetActivePage.bind(null, item.id));
    } else {
      onSetActivePage(item.id);
    }
  }

  onCopyPage(e, targetPageId) {
    e.stopPropagation();
    const { onCopyPage, onAddNewPageClick, pages, locale } = this.props;
    const isCopiedPage = true;
    onAddNewPageClick({
      pageTitle: generatePageName(pages, targetPageId),
      captions: getNewPageModalCaptions(isCopiedPage),
      isDisplayInNavAvailable: template.verifiers.isVariableGlobalsAvailable('navigation'),
      onSubmit: this.onNestedDialogSubmit.bind(this, onCopyPage, targetPageId, locale),
    });
  }

  onTranslatePage = () => {
    const { onTranslatePage, pages, locale } = this.props;
    dialogs.operations.show(dialogTypes.TRANSLATE_PAGE, {
      onCancel: () => {
        dialogs.operations.show(dialogTypes.PAGE_MANAGER);
      },
      onSubmit: this.onNestedDialogSubmit.bind(this, onTranslatePage, locale, pages),
    });
  };

  onAddNewPage() {
    const { onAddNewPage, onAddNewPageClick, pages, featureFlags } = this.props;
    const { ai_page_wizard: isAiPageWizardEnabled } = featureFlags;
    const isGenarateWithAiAvailable = template.verifiers.isMpt() && isAiPageWizardEnabled;
    const isCopiedPage = false;

    onAddNewPageClick({
      pageTitle: generatePageName(pages),
      pageTemplate: constants.BLANK_PAGE_TEMPLATE_ID,
      captions: getNewPageModalCaptions(isCopiedPage, isGenarateWithAiAvailable),
      isDisplayInNavAvailable: template.verifiers.isVariableGlobalsAvailable('navigation'),
      isGenarateWithAiAvailable,
      onSubmit: this.onNestedDialogSubmit.bind(this, onAddNewPage),
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { pages, locale } = prevState;

    if (nextProps.pages !== pages || nextProps.locale !== locale) {
      return {
        pages: nextProps.pages,
        optionsToCreatePage: getOptionsToCreatePage(
          createFilterCb(nextProps.pages, nextProps.locale)
        ),
      };
    }

    return null;
  }

  onNestedDialogSubmit(callback, ...data) {
    const { savingStatus, onUnsavedActivePageChange, html, htmlCheckpoint } = this.props;
    const { UNSAVED } = saving.constants.statuses;

    if (savingStatus === UNSAVED && html !== htmlCheckpoint) {
      onUnsavedActivePageChange(() => callback(...data));
    } else {
      callback(...data);
    }
  }

  setHomePage(e, item) {
    e.stopPropagation();
    const { onSetHomePageClick, locale } = this.props;
    onSetHomePageClick(item.id, locale);
  }

  openPageCreation(pageTypeItem) {
    const { openPageCreation, onAddNewPage, businessCategory } = this.props;
    const { name = i18next.t('Untitled'), id = pageTypes.EMPTY_PAGE } = pageTypeItem || {};

    if (id !== pageTypes.EMPTY_PAGE) {
      trackAddAiPageTriggerClicked({
        recipeId: id,
        businessCategory,
      });
    }

    openPageCreation({
      pageType: id,
      pageName: name,
      onAddPageFromTemplate: onAddNewPage,
    });
  }

  editPageSetting(e, item) {
    const { onPageSettingsClick } = this.props;
    const { id: pageId } = item;

    e.stopPropagation();

    onPageSettingsClick(pageId);
  }

  deletePage(e, item) {
    e.stopPropagation();
    const { onDeletePageClick } = this.props;
    onDeletePageClick(item.id, item.title);
  }

  openDeveloperConsole(event, item) {
    event.stopPropagation();
    const { onDeveloperConsoleClick } = this.props;
    onDeveloperConsoleClick(item);
  }

  filter(value) {
    if (!value) {
      this.setState({ filtered: false });
      return;
    }

    const inputText = value.trim().toLowerCase();
    const { pages } = this.props;

    const filteredPages = pages.filter((item) => item.title.toLowerCase().includes(inputText));

    trackPageManagerSearchPerformed({ searchTerm: value, searchResults: filteredPages.length });

    if (value) {
      this.setState({
        filtered: true,
        filteredPages,
      });
    } else {
      this.setState({
        filtered: false,
      });
    }
  }

  render() {
    const { filteredPages, filtered, pages, optionsToCreatePage } = this.state;
    const {
      onClose,
      activePageId,
      pageTemplates,
      locale,
      siteLocalesList,
      featureFlags,
      currentPackage,
      upsells,
    } = this.props;
    const captions = {
      searchPlaceholder: `${i18next.t('Search')}...`,
      close: i18next.t('Close'),
      addPage: i18next.t('Add page'),
      cancel: i18next.t('Cancel'),
      batchDelete: i18next.t('Delete'),
      copyPage: i18next.t('Copy'),
      setHomePage: i18next.t('Set Home Page'),
      pageSettings: i18next.t('Settings'),
      deletePage: i18next.t('Delete'),
      setAsHomePage: i18next.t('Use as Homepage'),
      fullscreenTooltip: i18next.t('Full screen'),
      developerConsole: i18next.t('Developer console'),
      title: i18next.t('Page manager'),
      searchEmptyTitle: i18next.t('Sorry!'),
      searchEmptyText: i18next.t('Nothing was found'),
      pageTemplatesSection: i18next.t('Generate pages with AI'),
      hoveredTemplateControl: i18next.t('Generate'),
    };

    const { developer_console: isDeveloperConsoleEnable, ai_page_wizard: aiPageWizardEnabled } =
      featureFlags;
    const isAiGenerationAvailable = aiPageWizardEnabled && template.verifiers.isMpt();
    const pagesFeatureLimit = getFeatureLimits(currentPackage).pages;

    return (
      <PageManager
        items={filtered ? filteredPages : pages}
        activeItem={activePageId}
        isPageCreationAvailable={isPageCreationAvailable(pageTemplates)}
        onItemClick={this.onItemClick}
        onClose={onClose}
        onDeletePageClick={this.deletePage}
        onPageSettingsClick={this.editPageSetting}
        onSetHomePageClick={this.setHomePage}
        onDeveloperConsoleClick={this.openDeveloperConsole}
        onAddNewPage={isAiGenerationAvailable ? this.openPageCreation : this.onAddNewPage}
        onTranslatePage={this.onTranslatePage}
        onCopyPage={this.onCopyPage}
        onSearchClick={trackPageManagerSearchClicked}
        filter={this.filter}
        captions={captions}
        locale={locale}
        isHomePage={site.verifiers.isHomePage}
        siteLocalesList={siteLocalesList}
        isDeveloperConsoleEnable={Boolean(isDeveloperConsoleEnable)}
        optionsToCreatePage={isAiGenerationAvailable ? optionsToCreatePage : null}
        onTemplateItemClick={this.openPageCreation}
        upgradeBanner={
          pagesFeatureLimit.upgradeAvailable && isAiGenerationAvailable
            ? {
                title: `${i18next.t('Pages')}:`,
                buttonText: i18next.t('Get more'),
                current: pages.length,
                total: pagesFeatureLimit.amount,
                onUpgrade: () => {
                  upsells.default.onUpgrade().catch(() => {
                    console.error('Upsell cancelled');
                  });
                },
              }
            : null
        }
      />
    );
  }
}

PageManagerContainer.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      id: PropTypes.string,
    })
  ).isRequired,
  locale: PropTypes.string.isRequired,
  onDeletePageClick: PropTypes.func.isRequired,
  onPageSettingsClick: PropTypes.func.isRequired,
  onSetHomePageClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSetActivePage: PropTypes.func.isRequired,
  onAddNewPage: PropTypes.func.isRequired,
  onCopyPage: PropTypes.func.isRequired,
  onTranslatePage: PropTypes.func.isRequired,
  onAddNewPageClick: PropTypes.func.isRequired,
  onDeveloperConsoleClick: PropTypes.func.isRequired,
  openPageCreation: PropTypes.func.isRequired,
  activePageId: PropTypes.string.isRequired,
  pageTemplates: PropTypes.arrayOf(PropTypes.object).isRequired,
  savingStatus: PropTypes.string.isRequired,
  onUnsavedActivePageChange: PropTypes.func.isRequired,
  html: PropTypes.string.isRequired,
  htmlCheckpoint: PropTypes.string.isRequired,
  siteLocalesList: PropTypes.arrayOf(PropTypes.string).isRequired,
  featureFlags: PropTypes.shape({
    developer_console: PropTypes.bool,
    ai_page_wizard: PropTypes.bool,
  }).isRequired,
  upsells: PropTypes.shape({
    default: PropTypes.shape({
      onUpgrade: PropTypes.func,
    }),
  }).isRequired,
  currentPackage: PropTypes.shape().isRequired,
  businessCategory: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]).isRequired,
  // eslint-disable-next-line yola/react/no-unused-prop-types
  onDialogCancel: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const pageTemplates = template.selectors.getPageTemplates(state);
  const activePageId = site.selectors.getActivePageId(state);
  const activePage = site.selectors.getPage(state, activePageId);
  const htmlCheckpoint = activePage && activePage.htmlCheckpoint;
  const html = activePage && activePage.html;
  const locale = siteLocales.selectors.getCurrentLocale(state);
  const siteLocalesList = siteLocales.selectors.getSiteLocales(state);
  const currentPackage = selectors.getCurrentPackage(state);
  const upsells = integration.selectors.getUpsells(state);

  return {
    upsells,
    currentPackage,
    pageTemplates,
    activePageId,
    htmlCheckpoint,
    html,
    locale,
    pages: site.selectors.getPages(state),
    businessCategory: site.selectors.getBusinessCategory(state),
    savingStatus: saving.selectors.getStatus(state),
    siteLocalesList,
  };
};

const mapDispatchToProps = (dispatch, { featureFlags, onDialogCancel }) => ({
  onDeletePageClick(pageId, pageTitle) {
    dispatch(dialogs.actions.show(dialogTypes.DELETE_PAGE, { pageId, pageTitle }));
  },

  onPageSettingsClick(pageId) {
    dispatch(dialogs.actions.show(dialogTypes.PAGE_SETTINGS, { pageId }));
  },

  onSetHomePageClick(pageId, locale) {
    dispatch(
      site.actions.setHomePage(
        pageId,
        locale,
        site.verifiers.isMultilingualSite(),
        getLanguageCodes()
      )
    );
  },

  onClose() {
    onDialogCancel();
    dispatch(dialogs.actions.hide());
  },

  onSetActivePage(pageId) {
    dispatch(site.actions.setActivePage(pageId));
  },

  onUnsavedActivePageChange(callback) {
    dispatch(
      dialogs.actions.show(dialogTypes.UNSAVED_WARNING, {
        callback,
        closingCallback: () => dispatch(dialogs.actions.show(dialogTypes.PAGE_MANAGER)),
      })
    );
  },

  onAddNewPageClick(options) {
    dispatch(dialogs.actions.show(dialogTypes.NEW_PAGE_DIALOG, { ...options }));
  },

  openPageCreation(options) {
    dispatch(dialogs.actions.show(dialogTypes.ADD_PAGE_DIALOG, { ...options }));
  },

  async onAddNewPage(values) {
    const { business_info_in_website_settings: isBusinessInfoInWebsiteSettingsEnabled } =
      featureFlags;
    const {
      [fieldNames.PAGE_NAME_INPUT]: pageTitle,
      [fieldNames.PAGE_TEMPLATE_SELECT]: selectedPageTemplate,
      [fieldNames.DISPLAY_IN_NAVIGATION_CHECKBOX]: displayInNavigation,
    } = values;
    const pageModifiers = [];

    if (selectedPageTemplate === constants.SHOP_PAGE_TEMPLATE_ID) {
      pageModifiers.push(injectStoreId);
    }

    const pageModifier = (documentPage) => {
      pageModifiers.forEach((fn) => fn(documentPage));
    };

    const pageTemplate = selectedPageTemplate || constants.BLANK_PAGE_TEMPLATE_ID;

    const onAfterAddNewPage = () => {
      dispatch(dialogs.actions.show(dialogTypes.PAGE_MANAGER));
    };

    hooks.operations.subscribe(hooks.availableHooks.ON_AFTER_ADD_NEW_PAGE, onAfterAddNewPage, {
      once: true,
    });

    const displayBusinessName = isBusinessInfoInWebsiteSettingsEnabled ? true : null;

    try {
      await site.operations.createPageFromTemplate(
        pageTemplate,
        pageTitle,
        displayInNavigation,
        pageModifier,
        displayBusinessName
      );
    } catch (e) {
      hooks.operations.unsubscribe(hooks.availableHooks.ON_AFTER_ADD_NEW_PAGE, onAfterAddNewPage);
    }
  },

  onCopyPage(targetPageId, locale, values) {
    const {
      [fieldNames.PAGE_NAME_INPUT]: pageTitle,
      [fieldNames.DISPLAY_IN_NAVIGATION_CHECKBOX]: displayInNavigation,
    } = values;

    dispatch(
      site.actions.copyPage(
        targetPageId,
        locale,
        pageTitle,
        displayInNavigation,
        site.verifiers.isMultilingualSite()
      )
    );
    dispatch(dialogs.actions.show(dialogTypes.PAGE_MANAGER));
  },

  onTranslatePage(locale, pages, submitData) {
    const { pageId } = submitData;
    const localePages = pages.filter((page) => page.locale === locale);
    const targetPage = pages.find((page) => page.id === pageId);
    const pageTitle = generateUniquePageTitle(localePages, targetPage.title);
    const isDisplayInNavAvailable = template.verifiers.isVariableGlobalsAvailable('navigation');
    dispatch(
      site.actions.translatePage(
        pageId,
        locale,
        pageTitle,
        isDisplayInNavAvailable,
        site.verifiers.isMultilingualSite()
      )
    );
    dispatch(dialogs.actions.show(dialogTypes.PAGE_MANAGER));
  },

  onDeveloperConsoleClick(page) {
    dispatch(dialogs.actions.show(dialogTypes.DEVELOPER_CONSOLE, { page }));
  },
});

export default withFeatureFlags([
  'developer_console',
  'ai_page_wizard',
  'business_info_in_website_settings',
])(connect(mapStateToProps, mapDispatchToProps)(PageManagerContainer));
