import React from 'react';
import PropTypes from 'prop-types';
import { dialogs } from '@yola/ws-sdk';
import yousers from '@yola/yousersjs';
import { DifmBannerSurveyContainer } from '@yola/webapp-plugins';
import segment from 'src/js/modules/analytics/segment';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import { TRIGGER_IDS } from '../constants/traits';

const {
  trackers: { trackEvent },
  constants: { events },
} = segment;

const DifmPromoSurveyDialogContainer = ({ values, onDialogCancel, triggerId }) => {
  const onBack = (updatedValues) => {
    trackEvent(events.DIFM_PROMO_DIALOG_BACK_BUTTON_CLICKED, {
      dialogId: dialogTypes.DIFM_PROMO_SURVEY_DIALOG,
    });

    dialogs.operations.show(dialogTypes.DIFM_PROMO_SERVICES_DIALOG, {
      values: updatedValues,
      triggerId,
    });
  };
  const onNext = (updatedValues) => {
    dialogs.operations.show(dialogTypes.DIFM_PROMO_PACKAGES_DIALOG, {
      values: updatedValues,
      triggerId,
    });
  };
  const onClose = () => {
    yousers.requests.declineDifm();
    onDialogCancel({
      selectedService: values.serviceName,
    });
    dialogs.operations.hide();
  };

  return (
    <DifmBannerSurveyContainer values={values} onBack={onBack} onNext={onNext} onClose={onClose} />
  );
};

DifmPromoSurveyDialogContainer.propTypes = {
  values: PropTypes.shape({ serviceName: PropTypes.string }).isRequired,
  triggerId: PropTypes.oneOf(Object.values(TRIGGER_IDS)).isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

export default DifmPromoSurveyDialogContainer;
