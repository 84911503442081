import { view } from '@yola/ws-sdk';
import compileCustomTemplate from './compile-custom-template';
import extractScriptsFromTemplate from './extract-scripts-from-template';
import constants from '../constant';

const { SCRIPT_REGEXP } = constants;

const prepareNodeElement = ({
  elementVariable,
  customTemplate,
  currentPalette,
  elementDataAttribute,
} = {}) => {
  const paletteId = currentPalette.id;
  const liveDocument = view.accessors.getLiveDocument();
  const variable = elementVariable.find((item) => item.querySelector);
  const element = liveDocument.querySelector(variable.querySelector);
  if (!element)
    return {
      paletteElement: null,
      element: null,
    };
  const paletteElement = element.cloneNode(false);

  const compiledCustomTemplate = compileCustomTemplate(customTemplate, currentPalette);

  if (compiledCustomTemplate.match(SCRIPT_REGEXP)) {
    const { scripts, template } = extractScriptsFromTemplate(compiledCustomTemplate);

    paletteElement.innerHTML = template;

    scripts.forEach((scriptElement) => {
      paletteElement.appendChild(scriptElement);
    });
  } else {
    paletteElement.innerHTML = compiledCustomTemplate;
  }

  paletteElement.setAttribute(elementDataAttribute, paletteId);

  return {
    paletteElement,
    element,
  };
};

export default prepareNodeElement;
