import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@yola/ws-ui';
import Trigger from 'src/js/modules/common/components/trigger';
import withSelection from 'src/js/modules/utils/with-selection';
import isActiveList from '../verifiers/is-active-list.js';

class ListFormattingTrigger extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
    };

    this.updateActiveStatus = this.updateActiveStatus.bind(this);
  }

  componentDidMount() {
    const { listType } = this.props;
    const active = isActiveList(listType);
    this.setState({
      active,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { listType } = this.props;
    const { active } = prevState;
    const activeList = isActiveList(listType);

    if (activeList !== active) {
      this.updateActiveStatus();
    }
  }

  updateActiveStatus() {
    const { listType } = this.props;
    const active = isActiveList(listType);
    this.setState({
      active,
    });
  }

  render() {
    const { active } = this.state;
    const { icon, triggerId } = this.props;
    return (
      <Trigger id={triggerId} active={active} onClick={this.updateActiveStatus}>
        <Icon glyph={icon} size="18" strokeWidth="1.3" />
      </Trigger>
    );
  }
}

ListFormattingTrigger.propTypes = {
  icon: PropTypes.string.isRequired,
  triggerId: PropTypes.string.isRequired,
  listType: PropTypes.string.isRequired,
};

export default withSelection(ListFormattingTrigger);
