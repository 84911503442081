import { textFormatting, i18next } from '@yola/ws-sdk';

const { wrappersTypes } = textFormatting.constants;
const { bold, italic, underline, strikethrough } = wrappersTypes;

const getTextFormattingOptions = () => [
  {
    ...bold,
    triggerId: 'text-bold',
    icon: 'bold',
    getTooltip() {
      return i18next.t('Bold');
    },
  },
  {
    ...italic,
    triggerId: 'text-italic',
    icon: 'italic',
    getTooltip() {
      return i18next.t('Italic');
    },
  },
  {
    ...underline,
    triggerId: 'text-underline',
    icon: 'underline',
    getTooltip() {
      return i18next.t('Underline');
    },
  },
  {
    ...strikethrough,
    triggerId: 'text-strikethrough',
    icon: 'strikethrough',
    getTooltip() {
      return i18next.t('Strikethrough');
    },
  },
];

export default getTextFormattingOptions;
