import { textFormatting } from '@yola/ws-sdk';

const MIN_SYMBOLS_LENGTH = 3;

const isAiRewriteTextAllowed = () => {
  const selection = textFormatting.accessors.getAdvancedSelection();
  return selection.toString().length > MIN_SYMBOLS_LENGTH;
};

export default isAiRewriteTextAllowed;
