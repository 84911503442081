import getLanguageNameData from './get-language-name-data';

const getLanguageData = (code, languages, countries) => {
  if (!code) return null;
  const [langCode] = code.split('-');
  const { name: langName, nativeName: langNativeName, ...rest } = languages[langCode];
  const country = rest.countries.find(({ languageCode }) => languageCode === code);
  const { countryCode } = country;
  const { name: countryName } = countries[country.countryCode];
  const { name, nativeName } = getLanguageNameData({
    languageCode: code,
    language: languages[langCode],
    country,
    countryName,
  });

  return {
    code,
    name,
    nativeName,
    country: countries[countryCode],
  };
};

export default getLanguageData;
