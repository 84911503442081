import { constants } from '@yola/subscription-manager-js';
import platformComponents from 'yola-editor/src/js/modules/user/constants/platform-components';

const {
  hostingPackageFeatureNames: { ANALYTICS },
} = constants;

const analyticsLimit = ({ availableNonPaidFeatures, availablePlatformComponents }) => ({
  available:
    availablePlatformComponents.includes(platformComponents.ANALYTICS) ||
    availableNonPaidFeatures.includes(ANALYTICS),
});

export default analyticsLimit;
