import get from 'lodash.get';
import { config } from '@yola/ws-sdk';

const getMyYolaConfig = (rootState) => {
  const cfg = config.selectors.getConfig(rootState);

  return get(cfg, 'common.myyola', {});
};

export default getMyYolaConfig;
