import get from 'lodash.get';
import { constants } from '@yola/subscription-manager-js';

const {
  hostingPackageFeatureNames: { MOBILE_PLUS },
} = constants;

const mobilePlusLimit = ({ targetPackage }) => ({
  available: get(targetPackage, ['features', MOBILE_PLUS], false),
});

export default mobilePlusLimit;
