import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

function ButtonGroup(props) {
  const { className, style, block, stick, children, ...otherProps } = props;
  const hasStick = stick !== 'none';

  const buttonGroupClass = classNames(className, 'button-group', {
    'button-group--block': block,
    [`button-group--stick-${stick}`]: hasStick,
  });

  return (
    <div className={buttonGroupClass} style={style} {...otherProps}>
      {children}
    </div>
  );
}

ButtonGroup.propTypes = {
  block: PropTypes.bool,
  stick: PropTypes.oneOf(['none', 'top', 'bottom']),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  style: PropTypes.shape(),
};

ButtonGroup.defaultProps = {
  block: false,
  stick: 'none',
  className: null,
  style: {},
};

export default ButtonGroup;
