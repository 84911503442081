import errorTypes from '../constants/error-types';

const getNetworkErrorDetails = (error) => {
  const isNetworkError = error.message.includes(errorTypes.NETWORK_ERROR);
  if (!isNetworkError) return null;

  const { url } = error.config;
  let response = null;

  if (error.response) {
    const { status } = error.response;
    const { msg, code } = error.response.data;
    response = { msg, code, status };
  }

  return {
    url,
    response,
  };
};

export default getNetworkErrorDetails;
