import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Field, designSystem } from '@yola/ws-ui';
import { site, i18n, serviceClient } from '@yola/ws-sdk';
import segment from 'src/js/modules/analytics/segment';
import usePrevious from 'src/js/modules/utils/custom-hooks/use-previous';
import loadTaxonomyOptions from 'src/js/modules/common/helpers/load-taxonomy-options';
import validators from '../validators';
import AutocompleteField from '../../common/components/autocomplete-field';

const { Icon } = designSystem;
const { createBusinessCategoryValidator } = validators;

const MAX_MENU_HEIGHT = 352;

const {
  constants: { events, triggerIds },
  track,
} = segment;

const getIconComponent = (isInvalid) => (
  <Icon glyph="search" type={isInvalid ? 'error' : 'default'} />
);

const BusinessCategoryField = ({
  name,
  placeholder,
  noResultsText,
  errorMessage,
  setFieldValue,
  fieldValue,
}) => {
  const autocompleteRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [firstRenderOptions, setFirstRenderOptions] = useState([]);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [businessCategoryValue, setBusinessCategoryValue] = useState('');

  const prevBusinessCategoryValue = usePrevious(businessCategoryValue);

  const locale = i18n.accessors.getLocale();
  const siteId = site.accessors.getSiteId();

  const isValidCategory = createBusinessCategoryValidator(errorMessage);

  const onBeforeLoadOptions = () => {
    setDefaultOptions([]);
    setIsLoading(true);
  };

  const onAfterLoadOptions = (options) => {
    setIsLoading(false);
    setDefaultOptions(options);
  };

  const handleChange = (option) => {
    if (option?.value) {
      setSelectedOption(option);

      track(events.BUSINESS_CATEGORY_SELECTED, {
        siteId,
        stepId: null,
        businessCategory: option.value,
        archetypeId: option.archetype,
        source: triggerIds.WEBSITE_SETTINGS,
      });
    }

    setBusinessCategoryValue(option?.label || '');
  };

  const handleFocus = () => {
    setIsFocused(true);

    track(events.BUSINESS_CATEGORY_INPUT_FOCUSED, {
      siteId,
      stepId: null,
      source: triggerIds.WEBSITE_SETTINGS,
      businessCategory: selectedOption?.value || null,
    });

    if (businessCategoryValue && !defaultOptions?.length && firstRenderOptions?.length) {
      setDefaultOptions(firstRenderOptions);
      setFirstRenderOptions([]);
    }
  };

  const handleBlur = () => {
    setIsFocused(false);

    if (!businessCategoryValue) setFieldValue(name, '');

    if (businessCategoryValue && businessCategoryValue !== prevBusinessCategoryValue) {
      loadTaxonomyOptions(locale, onAfterLoadOptions, onBeforeLoadOptions)(businessCategoryValue);
    }
  };

  useEffect(() => {
    const getCategoryBySlug = async (slug) => {
      try {
        const client = serviceClient.get();
        const { data } = await client.taxonomyGetCategory({ slug, locale });
        const { title, category, archetypes } = data;

        loadTaxonomyOptions(locale, setFirstRenderOptions)(title);
        setBusinessCategoryValue(title);
        setSelectedOption({
          label: title,
          value: category,
          archetype: archetypes[0],
        });
      } catch (error) {
        console.error(error);
      }
    };

    if (fieldValue) getCategoryBySlug(fieldValue);

    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  return (
    <Field
      name={name}
      component={AutocompleteField}
      ref={autocompleteRef}
      placeholder={placeholder}
      size="medium"
      asyncConfig={{
        loadOptions: loadTaxonomyOptions(locale, onAfterLoadOptions, onBeforeLoadOptions),
        defaultOptions,
        isLoading,
        cacheOptions: false,
      }}
      validate={() => isValidCategory(businessCategoryValue, selectedOption)}
      options={[]}
      inputValue={businessCategoryValue}
      onInputChange={setBusinessCategoryValue}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      filterOptionsFunction={(value) => value}
      openMenuOnFocus={Boolean(businessCategoryValue)}
      menuIsOpen={
        !selectedOption ||
        (selectedOption &&
          businessCategoryValue &&
          selectedOption.label !== businessCategoryValue) ||
        (isFocused && Boolean(businessCategoryValue))
      }
      getIconComponent={getIconComponent}
      noResultsText={noResultsText}
      maxMenuHeight={MAX_MENU_HEIGHT}
      shouldMarkSelectedOption
      blurInputOnSelect
    />
  );
};

BusinessCategoryField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  noResultsText: PropTypes.string,
  errorMessage: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
  fieldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
};

BusinessCategoryField.defaultProps = {
  placeholder: '',
  noResultsText: '',
  errorMessage: '',
  fieldValue: null,
};

export default BusinessCategoryField;
