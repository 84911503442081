const parseHslColorString = (hslString) => {
  const circleBracketsRexExp = /\(([^)]+)\)/;
  const hslExecArray = circleBracketsRexExp.exec(hslString);
  if (!hslExecArray) {
    throw new Error('HSL color is not valid');
  }
  const valueString = hslExecArray[1];
  const [h, s, l] = valueString.split(',').map((value) => parseFloat(value));
  return {
    h,
    s: s / 100,
    l: l / 100,
    a: 1,
  };
};

export default parseHslColorString;
