const PRIMARY_BUTTONS_COUNT = 2;
const BACKGROUND = 'Background';
const TEXT = 'Text';

const getPrimaryButtonsAdvancedColorsKeys = (colorKey) =>
  [...Array(PRIMARY_BUTTONS_COUNT)].map((_, number) => {
    const isBackgroundColor = colorKey.includes(BACKGROUND);
    const name = isBackgroundColor ? BACKGROUND : TEXT;
    const index = colorKey.indexOf(`1${name}`);

    return colorKey.slice(0, index).concat(`${number + 1}${name}`);
  });

export default getPrimaryButtonsAdvancedColorsKeys;
