import getUnique from './get-unique';

const compilePreloadedEntities = (urls, crossorigin = false) =>
  getUnique(urls)
    .map((url) => {
      let type = 'fetch';
      const isImageRegexp = /\.(jpe?g)|gif|svg/gim;
      if (isImageRegexp.test(url)) type = 'image';
      const crossoriginAttribute = crossorigin ? 'crossorigin' : '';
      return `<link rel="preload" href="${url}" as="${type}" ${crossoriginAttribute}>`;
    })
    .join('');

export default compilePreloadedEntities;
