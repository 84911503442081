import { textFormatting, contentEditable } from '@yola/ws-sdk';
import findBlockElementFromNode from './find-block-element-from-node';

const splitElementBySelection = () => {
  const selection = textFormatting.accessors.getAdvancedSelection();
  const blockElement = findBlockElementFromNode(selection.anchorNode);
  contentEditable.helpers.splitElementByCollapsedSelection(blockElement);
};

export default splitElementBySelection;
