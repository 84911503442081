import React, { useContext, useRef, useCallback } from 'react';
import Thumbnail from './thumbnail';
import ViewWithStaticAssets from './view-with-static-assets';
import BlockSettingsContext from '../contexts/block-settings-context';
import getStaticAssetsCategoryItems from '../helpers/get-static-assets-category-items';
import groupItemsByCategory from '../helpers/group-items-by-category';
import addInlineStylesToDependencies from '../helpers/add-inline-styles-to-dependencies';
import getDependenciesFromCategoryItems from '../helpers/get-dependencies-from-category-items';
import { FORCED_DESKTOP_WIDTH } from '../constants/sizes';
import { scrolledElementType } from '../constants/live-preview';
import { VIEW_CUSTOMIZATION, CURRENT_LAYOUT_PREVIEW_MAX_HEIGHT } from '../constants/block-settings';

const CurrentLayoutThumbnail = () => {
  const blockSettingsContext = useContext(BlockSettingsContext);
  const wrapperRef = useRef(null);

  const handleBlockAdapt = useCallback(
    (data) => {
      const wrapper = wrapperRef.current;

      if (!wrapper || !data) return;
      const { height, width } = data;

      const parent = wrapper.parentNode;

      const { width: wrapperWidth } = parent.getBoundingClientRect();
      const scale = wrapperWidth / width;
      const scaledHeight = height * scale;

      const previewHeight =
        scaledHeight > CURRENT_LAYOUT_PREVIEW_MAX_HEIGHT
          ? CURRENT_LAYOUT_PREVIEW_MAX_HEIGHT
          : scaledHeight;

      wrapper.style.height = `${previewHeight}px`;
      wrapper.style.width = `${wrapperWidth}px`;
    },
    [wrapperRef]
  );

  const { blockItems, itemPreProcessFunction, blocksPreviewStylesheet, selectedLayoutVariationId } =
    blockSettingsContext;

  const currentLayout = blockItems.find((itm) => itm.variationId === selectedLayoutVariationId);

  if (!currentLayout) return null;

  const categoriesWithItems = groupItemsByCategory([currentLayout]);

  const itemStaticAssets = getStaticAssetsCategoryItems(categoriesWithItems);
  const itemDependencies = addInlineStylesToDependencies(
    getDependenciesFromCategoryItems(categoriesWithItems),
    blocksPreviewStylesheet
  );

  const [currentCategory] = categoriesWithItems;

  const [item] = currentCategory.items;

  return (
    <div
      className="ws-current-layout__thumbnail"
      ref={wrapperRef}
      style={{ height: `${CURRENT_LAYOUT_PREVIEW_MAX_HEIGHT}px`, width: '100%' }}
    >
      <ViewWithStaticAssets
        dependencies={itemDependencies}
        staticAssets={itemStaticAssets}
        forcedWidth={FORCED_DESKTOP_WIDTH}
        scrolledElement={scrolledElementType.WRAPPER}
        customization={VIEW_CUSTOMIZATION}
        className="ws-current-layout__iframe"
      >
        <Thumbnail
          blockId={item.id}
          key={item.uuid}
          title={item.title}
          html={itemPreProcessFunction(item)}
          data-index={0}
          renderRealSize
          disabled={item.disabled}
          interactive={item.interactive}
          selected={false}
          className="ws-block-layout-dialog__thumbnail"
          onBlockAdapted={handleBlockAdapt}
        />
      </ViewWithStaticAssets>
    </div>
  );
};

export default CurrentLayoutThumbnail;
