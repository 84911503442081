import { globals } from '@yola/ws-sdk';

const injectGlobals = (html) => {
  const node = document.createElement('div');
  node.innerHTML = html;

  const nodeWithGlobals = globals.helpers.injectGlobals(node);
  const markup = nodeWithGlobals.innerHTML;

  return markup;
};

export default injectGlobals;
