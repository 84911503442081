import { template } from '@yola/ws-sdk';
import dashesToCapitalizeSentence from '../../utils/dashes-to-capitalize-sentence';

function createItemsGroup(item, categoryName, categories = {}) {
  const categoryCaptionData = categories[categoryName] || {};
  const localizedCategoryName = template.accessors.getMetaTranslations(categoryName);

  const { title = dashesToCapitalizeSentence(localizedCategoryName), description = null } =
    categoryCaptionData;
  return {
    title,
    description,
    categoryName,
    items: [item],
  };
}

function groupItemsByCategory(items, categories = {}) {
  const itemsByCategories = {};
  const result = [];

  items.forEach((item) => {
    const itemsGroup = itemsByCategories[item.category];

    if (itemsGroup) {
      itemsGroup.items.push(item);
    } else {
      const newItemsGroup = createItemsGroup(item, item.category, categories);

      itemsByCategories[item.category] = newItemsGroup;
      result.push(newItemsGroup);
    }
  });

  return result.map((group) => {
    const isEnabled = group.items.some((item) => !item.disabled);
    return {
      ...group,
      disabled: !isEnabled,
    };
  });
}

export default groupItemsByCategory;
