import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Route } from 'react-router-dom';
import config from 'src/js/modules/config';
import routesMap from '../helpers/routes-map';
import history from '../../browser-history';
import IndexRoute from '../routes/index-route';
import i18n from '../../modules/i18n';
import { ViewportProvider } from '../../utils/viewport-provider';

const Router = ({ locale, supportedLocales }) => {
  if (!locale || !supportedLocales) return null;
  routesMap.initialize(supportedLocales);
  return (
    <div>
      <ConnectedRouter history={history}>
        <ViewportProvider>
          <Route path={routesMap.index.pattern()} component={IndexRoute} />
        </ViewportProvider>
      </ConnectedRouter>
    </div>
  );
};

const mapStateToProps = (state) => {
  const locale = i18n.selectors.getLocale(state);
  return {
    locale,
    supportedLocales: (Boolean(locale) && config.selectors.getBaseLanguageCodes(state)) || [],
  };
};

Router.propTypes = {
  locale: PropTypes.string,
  supportedLocales: PropTypes.array,
};

Router.defaultProps = {
  locale: null,
  supportedLocales: [],
};

export default connect(mapStateToProps, null)(Router);
