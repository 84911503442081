import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { designSystem, Modal, PanelGroup, Panel } from '@yola/ws-ui';
import bowser from 'yola-bowser';
import FieldTypesItem from './field-types-item';
import constants from '../constants';
import helpers from '../helpers';
import fieldTypeCategories from '../constants/field-type-categories';

const {
  DialogHeader,
  DialogHeaderTitle,
  DialogHeaderControls,
  DialogHeaderActionButton,
  Accordion,
  AccordionGroup,
  AccordionHeader,
  AccordionContent,
  Box,
} = designSystem;

const { common } = constants;
const { MODAL_WIDTH, SELECT_ITEM_MODAL_HEIGHT, PANEL_HEIGHT } = common;

const SelectItemModal = (props) => {
  const {
    modalPosition,
    updateModalPosition,
    onSelect,
    onClose,
    captions,
    isB2C,
    formContainsAttachFileField,
    isFileAttachmetnFeatureAvailable,
  } = props;
  const [defaultActiveItems, setDefaultActiveItems] = useState([
    fieldTypeCategories.BASIC,
    fieldTypeCategories.CONTACTS,
  ]);

  const fieldTypes = helpers.getFieldTypes();
  const fieldTypeOptions = helpers.getFieldTypeOptions(fieldTypes);
  const { basic: basicOptions, contacts: contactsOptions } = fieldTypeOptions;

  const {
    modalHeader: { title, closeButtonLabel },
    inputCategories: { basic, contacts },
  } = captions;

  const handleAccordionItemClick = (item) => {
    if (defaultActiveItems.includes(item)) {
      setDefaultActiveItems((prev) => prev.filter((el) => el !== item));
    } else {
      setDefaultActiveItems((prev) => [...prev, item]);
    }
  };

  return (
    <Modal
      width={MODAL_WIDTH}
      minWidth={MODAL_WIDTH}
      height={SELECT_ITEM_MODAL_HEIGHT}
      fullscreen={bowser.mobile}
      onDragEnd={updateModalPosition}
      onOverlayClick={onClose}
      dragHandleSelector=".ws-drag-trigger"
      className="ws-contact-form-select-item-modal"
      {...modalPosition}
    >
      <PanelGroup height="100%">
        <Panel
          height={PANEL_HEIGHT}
          align="middle"
          className="ws-drag-trigger"
          theme="gray-100"
          corners="squared"
        >
          <DialogHeader>
            <DialogHeaderTitle>{title}</DialogHeaderTitle>
            <DialogHeaderControls>
              <DialogHeaderActionButton label={closeButtonLabel} onClick={onClose} />
            </DialogHeaderControls>
          </DialogHeader>
        </Panel>
        <Panel height={`calc(100% - ${PANEL_HEIGHT}px)`} corners="squared" scrollable>
          <Box
            paddingTop="spacing-s"
            paddingRight="spacing-m"
            paddingBottom="spacing-s"
            paddingLeft="spacing-m"
          >
            <Accordion defaultActiveItem={defaultActiveItems}>
              <AccordionGroup itemId={fieldTypeCategories.BASIC}>
                <AccordionHeader
                  title={basic.toLocaleUpperCase()}
                  onClick={handleAccordionItemClick}
                />
                <AccordionContent>
                  <div className="ws-contact-form-select-item-modal__accordion-content">
                    {basicOptions.map((item) => (
                      <FieldTypesItem
                        key={item.label}
                        item={item}
                        isB2C={isB2C}
                        formContainsAttachFileField={formContainsAttachFileField}
                        isFileAttachmetnFeatureAvailable={isFileAttachmetnFeatureAvailable}
                        onSelect={onSelect}
                      />
                    ))}
                  </div>
                </AccordionContent>
              </AccordionGroup>
              <AccordionGroup itemId={fieldTypeCategories.CONTACTS}>
                <AccordionHeader
                  title={contacts.toLocaleUpperCase()}
                  onClick={handleAccordionItemClick}
                />
                <AccordionContent>
                  <div className="ws-contact-form-select-item-modal__accordion-content">
                    {contactsOptions.map((item) => (
                      <FieldTypesItem
                        key={item.label}
                        item={item}
                        isB2C={isB2C}
                        onSelect={onSelect}
                      />
                    ))}
                  </div>
                </AccordionContent>
              </AccordionGroup>
            </Accordion>
          </Box>
        </Panel>
      </PanelGroup>
    </Modal>
  );
};

SelectItemModal.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  modalPosition: PropTypes.shape({
    offsetX: PropTypes.number,
    offsetY: PropTypes.number,
  }).isRequired,
  updateModalPosition: PropTypes.func.isRequired,
  captions: PropTypes.shape({
    modalHeader: PropTypes.shape({
      title: PropTypes.string.isRequired,
      closeButtonLabel: PropTypes.string.isRequired,
    }),
    inputCategories: PropTypes.shape({
      basic: PropTypes.string.isRequired,
      contacts: PropTypes.string.isRequired,
    }),
  }).isRequired,
  isB2C: PropTypes.bool.isRequired,
  formContainsAttachFileField: PropTypes.bool,
  isFileAttachmetnFeatureAvailable: PropTypes.bool,
};

SelectItemModal.defaultProps = {
  formContainsAttachFileField: false,
  isFileAttachmetnFeatureAvailable: false,
};

export default SelectItemModal;
