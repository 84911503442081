import { blocks, view, hdrm } from '@yola/ws-sdk';
import adjacentPositions from '../../../blocks/constants/adjacent-positions';

const { BEFORE_BEGIN, AFTER_END } = adjacentPositions;

const findPreviousSiblingWidget = (wrapperElementId) => {
  const { referenceMap } = hdrm.instance.driver;
  const wrapperElement = view.accessors.getLiveElement(wrapperElementId);
  const previousWrapperElementId = referenceMap.getPreviousReferenceId(wrapperElement);

  if (!previousWrapperElementId) {
    return null;
  }

  const previousWrapperElement = view.accessors.getLiveElement(previousWrapperElementId);
  const siblingWidget = previousWrapperElement.querySelector(
    `[id^=${blocks.constants.WIDGET_ID_PREFIX}]:last-child`
  );

  if (siblingWidget) {
    return {
      refElementId: view.accessors.getLiveElementId(siblingWidget),
      adjacentPosition: AFTER_END,
    };
  }

  return findPreviousSiblingWidget(previousWrapperElementId);
};

const findNextSiblingWidget = (wrapperElementId) => {
  const { referenceMap } = hdrm.instance.driver;
  const wrapperElement = view.accessors.getLiveElement(wrapperElementId);
  const nextWrapperElementId = referenceMap.getNextReferenceId(wrapperElement);

  if (!nextWrapperElementId) {
    return null;
  }

  const nextWrapperElement = view.accessors.getLiveElement(nextWrapperElementId);
  const siblingWidget = nextWrapperElement.querySelector(
    `[id^=${blocks.constants.WIDGET_ID_PREFIX}]:first-child`
  );

  if (siblingWidget) {
    return {
      refElementId: view.accessors.getLiveElementId(siblingWidget),
      adjacentPosition: BEFORE_BEGIN,
    };
  }

  return findNextSiblingWidget(nextWrapperElementId);
};

const findSiblingWidget = (wrapperElementId) => {
  const previousSiblingWidget = findPreviousSiblingWidget(wrapperElementId);

  if (previousSiblingWidget) {
    return previousSiblingWidget;
  }

  return findNextSiblingWidget(wrapperElementId);
};

export default findSiblingWidget;
