export default {
  WEBSITE_SETTINGS: 'website-settings',
  MAIN_MENU: 'main-menu',
  EDITOR_HEADER: 'editor-header',
  PAGE_SETTINGS: 'page-settings',
  PREMIUM_ICON_LIBRARY: 'premium-icon-library',
  ADVANCED_COLOR_CUSTOMIZATION: 'advanced-color-customization',
  SUBSCRIPTION_EXPIRED_DIALOG: 'subscription-expired-dialog',
  AI_WIZARD_INTRO_DIALOG: 'ai-wizard-intro-dialog',
  ADD_PAGE: 'add-page',
  AI_PAGE: 'ai-page',
  PUBLISH_INTERRUPTION: 'publish-interruption',
};
