import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { i18next, utils } from '@yola/ws-sdk';
import { Formik, Modal, PanelGroup, Panel, designSystem } from '@yola/ws-ui';
import bowser from 'yola-bowser';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import segment from 'src/js/modules/analytics/segment';
import constants from '../constants';
import helpers from '../helpers';
import verifiers from '../verifiers';
import FieldContentSection from './field-content-section';
import fieldTypeCategories from '../constants/field-type-categories';

const {
  DialogHeader,
  DialogHeaderTitle,
  DialogButton,
  DialogButtonGroup,
  DialogButtonGroupSeparator,
  Box,
} = designSystem;

const {
  track,
  constants: { events },
  helpers: { getDefaultTraits },
} = segment;

const { common, numberOfFilesAttachment } = constants;
const { MODAL_WIDTH, MODAL_HEIGHT, MODAL_HEIGHT_WITH_TABS, PANEL_HEIGHT, ALL_FILE_TYPES_VALUE } =
  common;

function EditFieldModal(props) {
  const {
    elementId,
    initialField,
    modalPosition,
    setModalPosition,
    initFormData,
    type,
    label,
    buttonText,
    placeholder,
    category,
    onSubmit,
    onCancel,
    setIsOverlayClicked,
  } = props;

  const [newFormData, setNewFormData] = useState(null);

  const enableBuilder = verifiers.isBuilderEnabled();
  const modalHeight = enableBuilder ? MODAL_HEIGHT_WITH_TABS : MODAL_HEIGHT;

  const timeFormat = helpers.getTimeFormat();
  const fieldTypes = helpers.getFieldTypes();
  const { dropdown, radio, attachfile } = fieldTypes;

  const isNewField = !initFormData;
  let editedFieldLabel;

  if (!isNewField) {
    const fieldTypeOptions = helpers.getFieldTypeOptions(fieldTypes);
    const editedField =
      fieldTypeOptions[fieldTypeCategories.BASIC].find((el) => el.type === initFormData.type) ||
      fieldTypeOptions[fieldTypeCategories.CONTACTS].find((el) => el.type === initFormData.type);
    editedFieldLabel = editedField ? editedField.label : i18next.t('Short text');
  }

  const handleCancel = (data) => {
    onCancel(isNewField, data);
  };

  const updateModalPosition = (event, { x, y }) => {
    setModalPosition({ offsetX: x, offsetY: y });
  };

  const onRequiredCheckboxClick = (inputType) => {
    if (!isNewField) {
      track(events.CONTACT_FORM_EDIT_ITEM_MAKE_REQUIRED_AREA_CLICKED, {
        ...getDefaultTraits(elementId),
        dialogId: dialogTypes.CONTACT_FORM_EDIT_ITEM,
        itemId: initFormData.id,
        itemType: inputType,
      });
    }
  };

  useEffect(() => {
    if (isNewField) {
      setNewFormData({
        type,
        label: category === fieldTypeCategories.CONTACTS ? label : '',
        ...(placeholder && { placeholder }),
        ...(buttonText && { buttonText }),
        ...(type === attachfile && {
          numberOfFiles: numberOfFilesAttachment.SINGLE,
          fileType: ALL_FILE_TYPES_VALUE,
        }),
        options: type === dropdown || type === radio ? ['', ''] : [''],
        timeFormat,
      });

      track(events.CONTACT_FORM_ADD_ITEM_DIALOG_DISPLAYED, {
        ...getDefaultTraits(elementId),
        itemType: type,
        categoryId: category,
        dialogId: dialogTypes.CONTACT_FORM_NEW_ITEM,
      });
    } else {
      track(events.CONTACT_FORM_EDIT_ITEM_DIALOG_DISPLAYED, {
        ...getDefaultTraits(elementId),
        dialogId: dialogTypes.CONTACT_FORM_EDIT_ITEM,
        itemId: initFormData.id,
        itemCurrentLabel: initialField.label || null,
        itemNewLabel: initFormData.label,
        itemType: initFormData.type,
      });
    }
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  return (
    <Formik initialValues={initFormData || newFormData} onSubmit={onSubmit} enableReinitialize>
      {({ values, errors, handleChange, handleSubmit }) => (
        <Modal
          width={MODAL_WIDTH}
          height={modalHeight}
          fullscreen={bowser.mobile}
          resizable={false}
          dragHandleSelector=".ws-drag-trigger"
          handleCancel={() => {
            handleCancel(values);
          }}
          handleSubmit={(data) => {
            setIsOverlayClicked(true);
            handleSubmit(data);
          }}
          onDragEnd={updateModalPosition}
          isValid={!Object.values(errors).filter(Boolean).length}
          {...modalPosition}
        >
          <PanelGroup height="100%">
            <Panel
              height={PANEL_HEIGHT}
              align="middle"
              className="ws-drag-trigger"
              theme="gray-100"
              corners="squared"
            >
              <DialogHeader>
                <DialogHeaderTitle>
                  {isNewField
                    ? `${i18next.t('New Item')} - ${label}`
                    : `${i18next.t('Edit Item')} - ${editedFieldLabel}`}
                </DialogHeaderTitle>
              </DialogHeader>
            </Panel>
            <Panel
              height={`calc(100% - ${PANEL_HEIGHT}px * 2)`}
              theme="white"
              corners="squared"
              scrollable
              style={{ borderBottom: 'none', maxHeight: `calc(100vh - ${PANEL_HEIGHT}px * 2)` }}
            >
              <Box
                paddingTop="spacing-s"
                paddingRight="spacing-m"
                paddingBottom="spacing-s"
                paddingLeft="spacing-m"
              >
                <FieldContentSection
                  type={values.type}
                  labelValue={values.label}
                  placeholderValue={values.placeholder}
                  buttonTextValue={values.buttonText}
                  fileTypeValue={values.fileType}
                  handleChange={handleChange}
                  onRequiredCheckboxClick={() => {
                    onRequiredCheckboxClick(values.type);
                  }}
                />
              </Box>
            </Panel>
            <Panel height={PANEL_HEIGHT} align="middle" corners="squared">
              <DialogButtonGroup>
                <DialogButton onClick={handleSubmit}>{i18next.t('Submit')}</DialogButton>
                <DialogButtonGroupSeparator />
                <DialogButton
                  onMouseDown={() => {
                    handleCancel(values);
                  }}
                >
                  {i18next.t('Cancel')}
                </DialogButton>
              </DialogButtonGroup>
            </Panel>
          </PanelGroup>
        </Modal>
      )}
    </Formik>
  );
}

EditFieldModal.propTypes = {
  elementId: PropTypes.string.isRequired,
  initFormData: PropTypes.shape({
    type: PropTypes.string,
    label: PropTypes.string,
    id: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.string),
  }),
  initialField: PropTypes.shape({
    label: PropTypes.string,
  }),
  type: PropTypes.string,
  label: PropTypes.string,
  buttonText: PropTypes.string,
  placeholder: PropTypes.string,
  category: PropTypes.oneOf(Object.values(fieldTypeCategories)),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  modalPosition: PropTypes.shape({
    offsetX: PropTypes.number,
    offsetY: PropTypes.number,
  }).isRequired,
  setModalPosition: PropTypes.func.isRequired,
  setIsOverlayClicked: PropTypes.func,
};

EditFieldModal.defaultProps = {
  initFormData: undefined,
  setIsOverlayClicked: utils.noop,
  initialField: {},
  type: '',
  label: '',
  buttonText: '',
  placeholder: '',
  category: fieldTypeCategories.BASIC,
};

export default EditFieldModal;
