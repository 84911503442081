const preloadResource = (resource, resourceType) => {
  const resouceLink = document.createElement('link');
  resouceLink.as = resourceType;
  resouceLink.href = resource;
  resouceLink.rel = 'preload';

  document.head.appendChild(resouceLink);
};

export default preloadResource;
