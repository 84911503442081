import React from 'react';
import { i18next, dialogs, extensions } from '@yola/ws-sdk';
import { Icon } from '@yola/ws-ui';
import Trigger from 'src/js/modules/common/components/trigger';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import constants from '../constants';

const triggerId = 'contact-form';

const control = {
  id: triggerId,
  trigger: (
    <Trigger id={triggerId}>
      <Icon glyph="contact-form" size="24" strokeWidth="1.3" />
    </Trigger>
  ),
  onTriggerClick: (elementId) => {
    dialogs.operations.show(dialogTypes.CONTACT_FORM_SETTINGS_DIALOG, { elementId });
  },
  get title() {
    return i18next.t('Form settings');
  },

  get tooltip() {
    return i18next.t('Form settings');
  },
  matches(liveElement) {
    const settings = extensions.accessors.getExtensionSettings(constants.common.slug);
    return liveElement.matches(settings.querySelector);
  },
  priority: 100,
};

export default control;
