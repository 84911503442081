import React from 'react';
import { i18next } from '@yola/ws-sdk';
import publishInterruptionTypes from '../constants/publish-interruption-types';

const getPublishInterruptionOptions = () => [
  {
    id: publishInterruptionTypes.PUBLISH,
    withDecoration: true,
    title: <b>{i18next.t('Publish to a domain')}</b>,
    description: i18next.t('Select a domain name and hosting package to take you business online'),
  },
  {
    id: publishInterruptionTypes.PREVIEW,
    title: (
      <>
        <b>{i18next.t('Not ready yet?')}</b> {i18next.t('Preview your website for free!')}
      </>
    ),
  },
];

export default getPublishInterruptionOptions;
