import { dialogs } from '@yola/ws-sdk';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import getDialogProps from './get-dialog-props';

const { GALLERY_DIALOG, GALLERY_CONFIRMATION_DIALOG } = dialogTypes;

const showKeepDefaultImagesDialog = ({
  galleryItems,
  uploadedGalleryItems,
  elementId,
  currentDialogType = GALLERY_CONFIRMATION_DIALOG,
}) => {
  dialogs.operations.show(
    GALLERY_CONFIRMATION_DIALOG,
    getDialogProps({
      currentDialogType,
      targetDialogType: GALLERY_DIALOG,
      galleryItems,
      uploadedGalleryItems,
      elementId,
    })
  );
};

export default showKeepDefaultImagesDialog;
