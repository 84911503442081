import { transactions } from '@yola/ws-sdk';
import getElementById from '../../helpers/get-element-by-id';
import { BACKGROUND_HIDDEN_ATTRIBUTE } from '../../constants/attributes';
import getSubmoduleConfig from '../../helpers/get-submodule-config';
import { SUBMODULE_NAME } from './constants';

const DEFAULT_OVERLAY_OPACITY = 0.5;

const widgetIsInserted = (config, payload, transaction) => {
  const { blockId, refElementId } = payload;

  if (!blockId || !refElementId || !blockId.startsWith('widget-')) {
    return [];
  }

  const { overlayAttributeName, overlayOpacityAttributeName, textAreaSelector } =
    getSubmoduleConfig(SUBMODULE_NAME);
  const { context, initialState: initialTransactionDOM } = transaction;
  const isStatic = context !== transactions.transactionTypes.LIVE_DOCUMENT;
  const refElement = getElementById(refElementId, isStatic);

  if (!refElement) {
    return [];
  }

  const closestCustomBlock = refElement.closest('ws-block[custom]');

  if (!closestCustomBlock || closestCustomBlock.hasAttribute(BACKGROUND_HIDDEN_ATTRIBUTE)) {
    return [];
  }

  const initialCustomBlock = initialTransactionDOM.querySelector(`[id="${closestCustomBlock.id}"]`);
  const didCustomBlockHaveTextAreas = Boolean(initialCustomBlock?.querySelector(textAreaSelector));

  if (didCustomBlockHaveTextAreas) {
    return [];
  }

  return [
    {
      node: closestCustomBlock,
      static: isStatic,
      instructions: [
        {
          property: overlayOpacityAttributeName,
          value: DEFAULT_OVERLAY_OPACITY,
        },
        {
          property: overlayAttributeName,
          value: '',
        },
      ],
    },
  ];
};

export default widgetIsInserted;
