import { textFormatting } from '@yola/ws-sdk';
import removeClassNameByPrefix from './remove-classname-by-prefix';

const { TEXT_INTENT_MODIFIER_PREFIX } = textFormatting.constants;

const removeTextIndentModifierFromNode = (node) => {
  const classNameWithoutModifiers = removeClassNameByPrefix(
    node.className,
    TEXT_INTENT_MODIFIER_PREFIX
  ).trim();
  node.setAttribute('class', classNameWithoutModifiers);
};

export default removeTextIndentModifierFromNode;
