const SIZE_1_GB = 1 * 1024 * 1024 * 1024;
const SIZE_2_GB = 2 * 1024 * 1024 * 1024;
const SIZE_5_GB = 5 * 1024 * 1024 * 1024;
const SIZE_500_MB = 500 * 1024 * 1024;
const SIZE_250_MB = 250 * 1024 * 1024;
const SIZE_150_MB = 150 * 1024 * 1024;
const SIZE_100_MB = 100 * 1024 * 1024;
const SIZE_5_MB = 5 * 1024 * 1024;
const UNLIMITED = 'unlimited';

export default {
  MAX_PAGES_AMOUNT: 1000,
  FREE_USER_PAGES_AMOUNT: 2,
  FREE_USER_BLOCKS_AMOUNT: 8,
  MAX_BLOCKS_AMOUNT: UNLIMITED,
  BASIC_FILE_UPLOAD_SIZE: SIZE_100_MB,
  GOLD_FILE_UPLOAD_SIZE: SIZE_150_MB,
  PREMIUM_FILE_UPLOAD_SIZE: SIZE_250_MB,
  FREE_USER_FILE_UPLOAD_SIZE: SIZE_5_MB,
  FREE_USER_STORAGE_SIZE: SIZE_500_MB,
  BASIC_STORAGE_SIZE: SIZE_1_GB,
  STORAGE_SIZE_2GB: SIZE_2_GB,
  STORAGE_SIZE_5GB: SIZE_5_GB,
  PREMIUM_STORAGE_SIZE: UNLIMITED,
  FREE_MULTILINGUAL_AMOUNT: 2,
  PREMIUM_MULTILINGUAL_AMOUNT: UNLIMITED,
};
