import { anodum, hooks } from '@yola/ws-sdk';
import helpers from '../helpers';

const elementsCanBeEdited = (oldElement, newElement) => {
  if (!oldElement || !newElement) return false;

  return anodum.isTag(oldElement, 'ws-iframe') && anodum.isTag(newElement, 'ws-iframe');
};

const onBeforeReplace = (oldElement, newElement) => {
  if (
    elementsCanBeEdited(oldElement, newElement) &&
    helpers.doesElementHaveDefaultSrc(oldElement, newElement.getAttribute('src'))
  ) {
    newElement.setAttribute('content-untouched', '');
  }
};

const onBeforeReplaceElement = () => {
  hooks.operations.subscribe(
    hooks.availableHooks.ON_BEFORE_REPLACE_STATIC_ELEMENT,
    onBeforeReplace
  );

  hooks.operations.subscribe(hooks.availableHooks.ON_BEFORE_REPLACE_LIVE_ELEMENT, onBeforeReplace);
};

export default onBeforeReplaceElement;
