import { view, i18next, extensions } from '@yola/ws-sdk';
import bowser from 'yola-bowser';
import constants from '../constants';

function getElementTypeOptions(elementId) {
  const liveDocument = view.accessors.getLiveDocument();
  const liveElement = elementId && view.accessors.getLiveElement(elementId);
  const settings = extensions.accessors.getExtensionSettings(constants.common.SLUG);

  const isQuoteMarkAvailable =
    liveElement && liveElement.closest(settings.allowableQuoteMarkQuerySelector);

  const blockQuoteMark =
    isQuoteMarkAvailable &&
    getComputedStyle(liveDocument.documentElement).getPropertyValue(
      constants.common.BLOCK_QUOTE_MARK_VARIABLE
    );

  const options = [
    {
      label: {
        type: 'base',
        text: i18next.t('Normal text'),
      },
      value: 'p',
      glyph: 'paragraph',
    },
    {
      label: {
        type: 'heading1',
        text: i18next.t('Heading 1'),
      },
      value: 'h1',
      glyph: 'heading-1',
    },
    {
      label: {
        type: 'heading2',
        text: i18next.t('Heading 2'),
      },
      value: 'h2',
      glyph: 'heading-2',
    },
    {
      label: {
        type: 'heading3',
        text: i18next.t('Heading 3'),
      },
      value: 'h3',
      glyph: 'heading-3',
    },
    {
      label: {
        type: 'heading4',
        text: i18next.t('Heading 4'),
      },
      value: 'h4',
      glyph: 'heading-4',
    },
    {
      label: {
        type: 'heading5',
        text: i18next.t('Heading 5'),
      },
      value: 'h5',
      glyph: 'heading-5',
    },
    {
      label: {
        type: 'heading6',
        text: i18next.t('Heading 6'),
      },
      value: 'h6',
      glyph: 'heading-6',
    },
    {
      label: {
        type: 'blockquote',
        text: i18next.t('Quote'),
        icon: blockQuoteMark,
      },
      value: 'blockquote',
      glyph: 'quote',
    },
  ];

  if (!bowser.mobile) {
    options.push({
      label: {
        text: i18next.t('Customize fonts...'),
      },
      value: constants.common.CUSTOMIZE_FONTS_HOTKEY,
    });
  }

  return options;
}

export default getElementTypeOptions;
