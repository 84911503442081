function imageMimeToExtension(mimeType) {
  const mimeTypes = {
    'image/avif': 'avif',
    'image/jpeg': 'jpg',
    'image/png': 'png',
    'image/gif': 'gif',
    'image/webp': 'webp',
    'image/svg+xml': 'svg',
    'image/jfif': 'jfif',
  };

  return mimeTypes[mimeType] || null;
}

export default imageMimeToExtension;
