import React from 'react';
import PropTypes from 'prop-types';
import { DifmBannerServicesContainer } from '@yola/webapp-plugins';
import yousers from '@yola/yousersjs';
import { dialogs } from '@yola/ws-sdk';
import segment from 'src/js/modules/analytics/segment';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import { TRIGGER_IDS } from '../constants/traits';

const {
  trackers: { trackEvent },
  constants: { events },
} = segment;

const DifmPromoServicesDialogContainer = ({ values, onDialogCancel, triggerId }) => {
  const onSelect = (selectedService) => {
    trackEvent(events.DIFM_PROMO_DIALOG_SERVICE_SELECTED, {
      selectedService,
      dialogId: dialogTypes.DIFM_PROMO_SERVICES_DIALOG,
      triggerId,
    });
  };
  const onBack = (updatedValues) => {
    trackEvent(events.DIFM_PROMO_DIALOG_BACK_BUTTON_CLICKED, {
      dialogId: dialogTypes.DIFM_PROMO_SERVICES_DIALOG,
    });

    dialogs.operations.show(dialogTypes.DIFM_PROMO_BANNER_DIALOG, {
      values: updatedValues,
      triggerId,
    });
  };
  const onNext = (updatedValues) => {
    dialogs.operations.show(dialogTypes.DIFM_PROMO_SURVEY_DIALOG, {
      values: updatedValues,
      triggerId,
    });
  };
  const onClose = () => {
    yousers.requests.declineDifm();
    onDialogCancel({
      selectedService: values.serviceName,
    });
    dialogs.operations.hide();
  };

  return (
    <DifmBannerServicesContainer
      values={values}
      onBack={onBack}
      onNext={onNext}
      onClose={onClose}
      onSelect={onSelect}
    />
  );
};

DifmPromoServicesDialogContainer.propTypes = {
  values: PropTypes.shape({ serviceName: PropTypes.string }).isRequired,
  triggerId: PropTypes.oneOf(Object.values(TRIGGER_IDS)).isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

export default DifmPromoServicesDialogContainer;
