import getCustomTextColor from './get-custom-text-color';

const regeneratePropertiesForBlockCssRule = (cssRule, blockId, surfaceName, palette) => {
  const rule = cssRule;
  const { style } = rule;

  [...Array(style.length)].forEach((value, index) => {
    const cssVariable = style[index];
    const newColor = getCustomTextColor(cssVariable, surfaceName, palette);
    style.setProperty(cssVariable, newColor);
  });
  rule.selectorText = `#${blockId} .${surfaceName}`;
};

export default regeneratePropertiesForBlockCssRule;
