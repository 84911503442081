import actionTypes from '../constants/action-types';

const criticalError = (state = null, action) => {
  switch (action.type) {
    case actionTypes.SET_CRITICAL_ERROR:
      return action.payload.error;
    default:
      return state;
  }
};

export default criticalError;
