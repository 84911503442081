import { i18n, serviceClient } from '@yola/ws-sdk';
import get from 'lodash.get';
import store from 'src/js/store';
import getStoreId from '../selectors/store-id';

async function fetchCategories() {
  const client = serviceClient.get();
  const state = store.getState();
  const locale = i18n.selectors.getLocale(state);
  const localeCode = locale.replace('-', '_');
  const storeId = getStoreId(state);
  const response = await client.getOnlineStoreCategories(storeId);

  return response.data.map((category) => ({
    id: category.id,
    name: get(category, `nameTranslated[${localeCode}]`) || category.name,
  }));
}

export default fetchCategories;
