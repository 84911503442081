import { blocks } from '@yola/ws-sdk';

const getPresenceToUpdate = (originNode, destinationNode, optionChildrenState) => {
  const originalOptionalChildren = blocks.accessors.getOptionalChildrenData(originNode);

  const destinationOptionalChildren = blocks.accessors.getOptionalChildrenData(destinationNode);

  return optionChildrenState
    .map(({ id, isEnabled }) => {
      const originalChildren = originalOptionalChildren.find((el) => el.id === id);
      const destinationChildren = destinationOptionalChildren.find((el) => el.id === id);
      if (!destinationChildren) return null;
      return {
        id,
        isEnabled,
        originalQuerySelector: originalChildren && originalChildren.querySelector,
        destinationQuerySelector: destinationChildren && destinationChildren.querySelector,
      };
    })
    .filter((el) => el);
};

export default getPresenceToUpdate;
