import isCustomBlock from '../verifiers/is-custom-block';
import getRootBlockByElement from './get-root-block-by-element';
import isElementColumn from './is-element-column';

function isElementColumnInCustomBlock(element) {
  if (!element) return false;
  const rootBlock = getRootBlockByElement(element);
  return isElementColumn(element) && isCustomBlock(rootBlock);
}

export default isElementColumnInCustomBlock;
