import connectedStore from 'yola-editor/src/js/store/connected-store';
import actions from '../actions';

const hide = () => {
  const store = connectedStore.get();

  store.dispatch(actions.hide());
};

export default hide;
