import { hooks } from '@yola/ws-sdk';
import prepareContentEventTrackData from '../helpers/prepare-content-event-track-data';
import constants from '../constants';
import track from '../track';

const trackContentCopied = () => {
  hooks.operations.subscribe(hooks.availableHooks.ON_TEXT_COPY, (event) => {
    const trackingData = prepareContentEventTrackData(event);
    if (!trackingData) return;
    // we can't access clipboard data from "Copy" event to determine content types trait
    // so we keep it as: [text]
    trackingData.contentTypes = [constants.contentTypes.TEXT];

    track(constants.events.CONTENT_COPIED, trackingData);
  });
};

export default trackContentCopied;
