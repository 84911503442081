export default {
  AI_SURVEY_POSTPONED_PERIOD_DAYS: 1,
  AI_RE_SURVEY_PERIOD_DAYS: 30,
  SURVEY_POSTPONED_PERIOD_DAYS: 7,
  RE_SURVEY_PERIOD_DAYS: 90,
  SURVEY_STATUS_NEW: 'new',
  SURVEY_STATUS_POSTPONED: 'postponed',
  SURVEY_STATUS_COMPLETED: 'completed',
  SURVEY_STATUS_FINISHED: 'finished',
  INIT_SURVEY_PERIOD_HOURS: 2,
  STAGE_DESKTOP: 'desktop',
  STAGE_MOBILE_1: 'mobile-1',
  STAGE_MOBILE_2: 'mobile-2',
};
