function getTextWidth(text, font, doc = document) {
  const canvas = doc.createElement('canvas');
  const context = canvas.getContext('2d');
  const fontValue = font || '14px sans-serif';

  context.font = `${fontValue}`;

  return Math.ceil(context.measureText(text).width);
}

export default getTextWidth;
