import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { i18next, dialogs, view, hdrm } from '@yola/ws-sdk';
import ElementSettingsDialog from '../components/element-settings-dialog';
import { DIALOG_HEIGHT, DIALOG_WIDTH } from '../constants';
import getElementSettings from '../accessors/get-element-settings';
import ElementSettingsContext from '../helpers/element-settings-context';
import getInitialState from '../helpers/get-initial-state';
import getObjectDifference from '../helpers/get-object-difference';
import getActionsToApply from '../helpers/get-actions-to-apply';
import prepareElementSettings from '../helpers/prepare-element-settings';

const { UPDATE_LIVE_ONLY, UPDATE_STATIC_ONLY } = hdrm.constants.updateStrategies;

function ElementSettingsContainer({ elements, offsetX: initialOffsetX, offsetY: initialOffsetY }) {
  const captions = useMemo(
    () => ({
      title: i18next.t('Settings'),
      buttons: {
        submit: i18next.t('Save'),
        cancel: i18next.t('Cancel'),
      },
    }),
    []
  );

  const dispatch = useDispatch();

  const [offset, setOffset] = useState({ x: initialOffsetX, y: initialOffsetY });

  useEffect(() => {
    if (initialOffsetY || initialOffsetX) return;

    const { offsetX, offsetY } = dialogs.helpers.getPositionByElement(
      elements[0],
      DIALOG_WIDTH,
      DIALOG_HEIGHT
    );

    setOffset({ x: offsetX, y: offsetY });
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  const handleDragEnd = useCallback((_, { x, y }) => {
    setOffset({ x, y });
  }, []);

  const matchedSettings = useMemo(() => {
    const elementSettings = getElementSettings();

    return prepareElementSettings(elements, elementSettings);
  }, [elements]);

  const initialState = useMemo(() => {
    if (!matchedSettings) return null;

    return getInitialState(matchedSettings);
  }, [matchedSettings]);

  const [state, setState] = useState(initialState);

  const updateState = (batch) => {
    const actions = getActionsToApply(batch, { updateStrategy: UPDATE_LIVE_ONLY });

    dispatch(view.actions.bulkViewActions(actions));

    setState((currentState) => {
      const newState = {
        ...currentState,
      };
      Object.keys(batch).forEach((elementId) => {
        newState[elementId] = {
          ...currentState[elementId],
          ...batch[elementId],
        };
      });

      return newState;
    });
  };

  const onSubmit = useCallback(() => {
    const diff = getObjectDifference(initialState, state);
    if (Object.keys(diff).length > 0) {
      const actions = getActionsToApply(diff, { strategy: UPDATE_STATIC_ONLY });

      dispatch(view.actions.bulkViewActions(actions));
    }

    dialogs.operations.hide();
  }, [initialState, state, dispatch]);

  const onCancel = useCallback(() => {
    const diff = getObjectDifference(initialState, state);
    if (Object.keys(diff).length !== 0) {
      dispatch(view.actions.forceReloadView());
    }

    dialogs.operations.hide();
  }, [initialState, state, dispatch]);

  if (!matchedSettings) return null;

  return (
    <ElementSettingsContext.Provider
      value={{
        state,
        updateState,
      }}
    >
      <ElementSettingsDialog
        offsetX={offset.x}
        offsetY={offset.y}
        captions={captions}
        settings={matchedSettings}
        onSubmit={onSubmit}
        onCancel={onCancel}
        onDragEnd={handleDragEnd}
      />
    </ElementSettingsContext.Provider>
  );
}

ElementSettingsContainer.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.node).isRequired,
  offsetX: PropTypes.number.isRequired,
  offsetY: PropTypes.number.isRequired,
};

export default ElementSettingsContainer;
