import React, { createContext } from 'react';
import PropTypes from 'prop-types';

const UserInputContext = createContext();
const { Provider, Consumer } = UserInputContext;

function UserInputProvider({ children }) {
  const subscribe = (event, handler) => {
    window.addEventListener(event, handler, false);

    return {
      event,
      handler,
      unsubscribe: () => window.removeEventListener(event, handler, false),
    };
  };

  return <Provider value={{ subscribe }}>{children}</Provider>;
}

UserInputProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { UserInputProvider, Consumer as UserInputConsumer };
export default UserInputContext;
