import { trackCustomBlockSeen, trackOnlineStoreSeen } from './blocks-library-analytics';

const callbacksByBannerId = {
  'online-store': trackOnlineStoreSeen,
  'custom-block': trackCustomBlockSeen,
};

function handleBannerIntersection({ target: { id }, isIntersecting }) {
  if (isIntersecting) {
    const callback = callbacksByBannerId[id];

    if (callback) callback();
  }
}

export default handleBannerIntersection;
