import store from 'src/js/store';
import user from 'yola-editor/src/js/modules/user';
import references from '../constants/references';

const getAiTutorialUrl = () => {
  const state = store.getState();
  const isWhiteLabel = user.selectors.getWhiteLabelStatus(state);

  return isWhiteLabel ? references.AI_TUTORIAL_WL_URL : references.AI_TUTORIAL_URL;
};

export default getAiTutorialUrl;
