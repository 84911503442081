import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { integration } from '@yola/ws-sdk';
import { designSystem } from '@yola/ws-ui';
import segment from 'src/js/modules/analytics/segment';

const { EditorHeader } = designSystem;

const {
  trackers: { trackUpgradeTriggerClicked },
  constants: { triggerIds },
} = segment;

class UpgradeContainer extends React.Component {
  constructor(props) {
    super(props);

    this.onUpgrade = this.onUpgrade.bind(this);
  }

  onUpgrade() {
    const { settings } = this.props;
    trackUpgradeTriggerClicked(triggerIds.EDITOR_HEADER);

    settings.upsells.default.onUpgrade().catch(() => {
      // eslint-disable-next-line no-console
      console.log('Upgrade flow was canceled');
    });
  }

  render() {
    const { settings } = this.props;
    if (!settings || !settings.upsells || !settings.upsells.default) return null;

    const { default: upgradeData } = settings.upsells;

    return (
      <EditorHeader.UpgradeButton onClick={this.onUpgrade} label={upgradeData.captions.upgrade} />
    );
  }
}

UpgradeContainer.propTypes = {
  settings: PropTypes.shape(),
};

UpgradeContainer.defaultProps = {
  settings: {},
};

const mapStateToProps = (state) => ({
  settings: integration.selectors.getSettings(state),
});

export default connect(mapStateToProps)(UpgradeContainer);
