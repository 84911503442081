export default {
  SIMPLE: '@YOLA_EDITOR/DIALOG/SIMPLE',
  PUBLISH_PROGRESS: '@YOLA_EDITOR/DIALOG/PUBLISH_PROGRESS',
  PUBLISH_SUCCESS: '@YOLA_EDITOR/DIALOG/PUBLISH_SUCCESS',
  PUBLISH_ERROR: '@YOLA_EDITOR/DIALOG/PUBLISH_ERROR',
  B2C_DOMAIN_PURCHASE: '@YOLA_EDITOR/DIALOG/B2C_DOMAIN_PURCHASE',
  UNPUBLISH_PROGRESS: '@YOLA_EDITOR/DIALOG/UNPUBLISH_PROGRESS',
  UNPUBLISH_CONFIRM_ACTION: '@YOLA_EDITOR/DIALOG/UNPUBLISH_CONFIRM_ACTION',
  UNPUBLISH_SUCCESS: '@YOLA_EDITOR/DIALOG/UNPUBLISH_SUCCESS',
  UNPUBLISH_ERROR: '@YOLA_EDITOR/DIALOG/UNPUBLISH_ERROR',
  SITE_RESET: '@YOLA_EDITOR/DIALOG/SITE_RESET',
  SITE_RESET_ERROR: '@YOLA_EDITOR/DIALOG/SITE_RESET_ERROR',
  SITE_RESET_AND_UNPUBLISH: '@YOLA_EDITOR/DIALOG/SITE_RESET_AND_UNPUBLISH',
  SITE_RESET_PROGRESS: '@YOLA_EDITOR/DIALOG/SITE_RESET_PROGRESS',
  IN_APP_PURCHASE: '@YOLA_EDITOR/DIALOG/IN_APP_PURCHASE',
  IN_APP_PURCHASE_PENDING: '@YOLA_EDITOR/DIALOG/IN_APP_PURCHASE_PENDING',
  IN_APP_PURCHASE_SUCCESS: '@YOLA_EDITOR/DIALOG/IN_APP_PURCHASE_SUCCESS',
  IN_APP_PURCHASE_FAILURE: '@YOLA_EDITOR/DIALOG/IN_APP_PURCHASE_FAILURE',
  PAYWALL: '@YOLA_EDITOR/DIALOG/PAYWALL',
  VERIFY_EMAIL: '@YOLA_EDITOR/DIALOG/VERIFY_EMAIL',
  CHECK_VERIFICATION_EMAIL: '@YOLA_EDITOR/DIALOG/CHECK_VERIFICATION_EMAIL',
  PROGRESS: '@YOLA_EDITOR/DIALOG/PROGRESS',
  WL_DOMAIN_PURCHASE: '@YOLA_EDITOR/DIALOG/WL_DOMAIN_PURCHASE',
  UPGRADE: '@YOLA_EDITOR/DIALOG/UPGRADE',
  PENDING: '@YOLA_EDITOR/DIALOG/PENDING',
  SURVEY_THANK_YOU: '@YOLA_EDITOR/DIALOG/SURVEY_THANK_YOU',
  PMF_SURVEY: '@YOLA_EDITOR/DIALOG/PMF_SURVEY',
  NPS_SURVEY: '@YOLA_EDITOR/DIALOG/NPS_SURVEY',
  AI_SURVEY: '@YOLA_EDITOR/DIALOG/AI_SURVEY',
  SHARE_DIALOG: '@YOLA_EDITOR/DIALOG/SHARE_DIALOG',
  HELP_DISPLAYED: '@YOLA_EDITOR/DIALOG/HELP_DISPLAYED',
  REQUIRED_COOKIES_ERROR: '@YOLA_EDITOR/DIALOG/REQUIRED_COOKIES_ERROR',
  DIFM_PRIVACY_UPSELL_DIALOG: '@YOLA_EDITOR/DIALOG/DIFM_PRIVACY_UPSELL_DIALOG',
  SUBSCRIPTION_EXPIRED: '@YOLA_EDITOR/DIALOG/SUBSCRIPTION_EXPIRED',
  PREVIEW_PROGRESS: '@YOLA_EDITOR/DIALOG/PREVIEW_PROGRESS',
  PREVIEW_ERROR: '@YOLA_EDITOR/DIALOG/PREVIEW_ERROR',
};
