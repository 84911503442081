import { blocks, hdrm, view } from '@yola/ws-sdk';
import isEmptySpaceHovered from './is-empty-space-hovered';
import getPageContainer from '../../utils/helpers/get-page-container';

const getTargetElements = (hoveredElementId, pageContainerSelector, featureFlags) => {
  if (!hoveredElementId) return [];

  let loopElementId = hoveredElementId;
  let container = hdrm.accessors.getParentLiveElement(loopElementId);
  const elements = [];

  while (container) {
    if (blocks.filters.findAllFor(container, featureFlags).length > 0) {
      elements.push(loopElementId);
    }
    loopElementId = hdrm.instance.driver.referenceMap.getReferenceId(container);
    container = hdrm.accessors.getParentLiveElement(loopElementId);
  }

  if (
    !elements.length &&
    !view.verifiers.isEmpty() &&
    isEmptySpaceHovered(hoveredElementId, pageContainerSelector)
  ) {
    const pageContainer = getPageContainer(pageContainerSelector);
    const pageContainerId = view.accessors.getLiveElementId(pageContainer);

    const element = view.accessors.getLiveElementLastChild(pageContainerId);
    elements.push(view.accessors.getLiveElementId(element));
  }

  return elements;
};

export default getTargetElements;
