import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import { textFormatting, i18next } from '@yola/ws-sdk';
import Trigger from 'src/js/modules/common/components/trigger';
import withSelection from 'src/js/modules/utils/with-selection';
import wrapElement from '../helpers/wrap-element';
import unwrapElement from '../helpers/unwrap-element';
import isDecorationSelected from '../verifiers/is-decoration-selected';
import restoreRange from '../helpers/restore-range';

const { Icon } = designSystem;
const {
  wrappersTypes: { decoration },
} = textFormatting.constants;
const TRIGGER_ID = 'text-decoration';

const DecorationTrigger = ({ elementId }) => {
  const isSelected = isDecorationSelected();

  const handleClick = () => {
    restoreRange(textFormatting.accessors.getAdvancedSelection(), elementId);

    if (isSelected) {
      const selection = textFormatting.accessors.getAdvancedSelection();
      unwrapElement(selection, elementId, decoration);
      return;
    }

    wrapElement(elementId, decoration);
  };

  return (
    <Trigger
      data-tip={i18next.t('Label')}
      id={TRIGGER_ID}
      active={isSelected}
      onClick={handleClick}
    >
      <Icon glyph="label" size="24" strokeWidth="1.3" />
    </Trigger>
  );
};

DecorationTrigger.propTypes = {
  elementId: PropTypes.string.isRequired,
};

export default withSelection(DecorationTrigger);
