import { i18next } from '@yola/ws-sdk';
import itemIds from 'src/js/modules/help-menu/constants/item-ids';
import { HELP_MENU_ID } from '../constants';
import commonConstants from '../../common/constants';

const getHelpMenuItems = (params) => {
  const {
    isWL,
    partnerId,
    contactSupportUrl = null,
    supportEmail = null,
    gettingStartedUrl,
    helpCenterUrl,
    termsOfUseUrl,
    privacyPolicyUrl,
    publicRoadmapUrl,
    isComplianceServiceMenuItemEnabled,
    isOrderWebsiteMenuItemEnabled,
    helpMenuItemClickCallbacks = {},
  } = params;

  const {
    requestFeatureOnClick,
    gettingStartedOnClick,
    helpCenterOnClick,
    termsOfUseOnClick,
    privacyPolicyOnClick,
    tutorialsOnClick,
    contactSupportOnClick,
    contactSupportUrlOnClick,
    welcomeTourOnClick,
    complianceServiceOnClick,
    orderWebsiteOnClick,
  } = helpMenuItemClickCallbacks;

  const showSecondDivider = isComplianceServiceMenuItemEnabled || isOrderWebsiteMenuItemEnabled;
  const showLastDivider = termsOfUseUrl || privacyPolicyUrl;
  const showContactSupportMenuItemForWL = isWL && (contactSupportUrl || supportEmail);

  const menuItems = {
    heading: i18next.t('Help'),
    order: 90,
    target: '_blank',
    icon: {
      glyph: 'help',
      width: 24,
      height: 24,
      strokeWidth: 1.3,
    },
  };

  const subMenu = [];

  if (!isWL) {
    subMenu.push({
      title: i18next.t('Tutorials'),
      onClick: tutorialsOnClick,
      heading: `${i18next.t('Tutorials')}...`,
      id: itemIds.TUTORIALS,
      description: '',
      order: 1,
    });

    subMenu.push({
      title: i18next.t('Contact support'),
      onClick: contactSupportOnClick,
      heading: `${i18next.t('Contact support')}...`,
      id: itemIds.CONTACT_SUPPORT,
      description: '',
      order: 7,
    });
  }

  if (!isWL || partnerId === commonConstants.ACMEPRO_PARTNER_ID) {
    if (gettingStartedUrl) {
      subMenu.push({
        title: i18next.t('Getting started'),
        href: gettingStartedUrl,
        target: '_blank',
        heading: i18next.t('Getting started'),
        id: itemIds.GETTING_STARTED,
        description: '',
        order: 2,
        onClick: gettingStartedOnClick,
      });
    }
  }

  if (helpCenterUrl) {
    subMenu.push({
      title: i18next.t('Help center'),
      href: helpCenterUrl,
      target: '_blank',
      heading: i18next.t('Help center'),
      id: itemIds.HELP_CENTER,
      description: '',
      order: 5,
      onClick: helpCenterOnClick,
    });
  }

  if (publicRoadmapUrl || !isWL || showContactSupportMenuItemForWL) {
    subMenu.push({
      type: 'divider',
      order: 6,
    });
  }

  if (showContactSupportMenuItemForWL) {
    subMenu.push({
      title: i18next.t('Contact support'),
      href: contactSupportUrl || `mailto:${supportEmail}`,
      target: '_blank',
      heading: `${i18next.t('Contact support')}...`,
      id: itemIds.CONTACT_SUPPORT,
      description: '',
      order: 7,
      onClick: contactSupportUrlOnClick,
    });
  }

  if (publicRoadmapUrl) {
    subMenu.push({
      title: i18next.t('Request a feature'),
      href: publicRoadmapUrl,
      target: '_blank',
      heading: i18next.t('Request a feature'),
      id: itemIds.REQUEST_A_FEATURE,
      description: '',
      order: 8,
      onClick: requestFeatureOnClick,
    });
  }

  if (showSecondDivider) {
    subMenu.push({
      type: 'divider',
      order: 9,
    });
  }

  if (isOrderWebsiteMenuItemEnabled) {
    subMenu.push({
      title: i18next.t('Hire a professional'),
      heading: i18next.t('Hire a professional'),
      id: itemIds.ORDER_A_WEBSITE,
      description: '',
      order: 10,
      onClick: orderWebsiteOnClick,
    });
  }

  if (isComplianceServiceMenuItemEnabled) {
    subMenu.push({
      title: i18next.t('Compliance service'),
      heading: i18next.t('Compliance service'),
      id: itemIds.COMPLIANCE_SERVICE,
      description: '',
      order: 11,
      onClick: complianceServiceOnClick,
    });
  }

  if (showLastDivider) {
    subMenu.push({
      type: 'divider',
      order: 12,
    });
  }

  if (termsOfUseUrl) {
    subMenu.push({
      title: i18next.t('Terms of use'),
      href: termsOfUseUrl,
      target: '_blank',
      heading: i18next.t('Terms of use'),
      id: itemIds.TERMS_OF_USE,
      description: '',
      order: 13,
      onClick: termsOfUseOnClick,
    });
  }

  if (privacyPolicyUrl) {
    subMenu.push({
      title: i18next.t('Privacy policy'),
      href: privacyPolicyUrl,
      target: '_blank',
      heading: i18next.t('Privacy policy'),
      id: itemIds.PRIVACY_POLICY,
      description: '',
      order: 14,
      onClick: privacyPolicyOnClick,
    });
  }

  menuItems.id = HELP_MENU_ID;
  menuItems.submenu = [
    ...subMenu,
    {
      title: i18next.t('Welcome tour'),
      heading: i18next.t('Welcome tour'),
      id: itemIds.WELCOME_TOUR,
      description: '',
      order: 3,
      onClick: welcomeTourOnClick,
    },
  ];

  return menuItems;
};

export default getHelpMenuItems;
