import settingTypes from '../constants/setting-types';

const getFieldValuesFromSettings = (settings) => ({
  ...settings,
  [settingTypes.SITE_NAME]: settings.siteName || '',
  [settingTypes.BUSINESS_NAME]: settings.businessName || '',
  [settingTypes.BUSINESS_CATEGORY]: settings.businessCategory || '',
  [settingTypes.BUSINESS_DESCRIPTION]: settings.businessDescription || '',
  [settingTypes.ADDRESSES]: settings.addresses?.toString() || '',
  [settingTypes.PHONES]: settings.phones?.toString() || '',
  [settingTypes.EMAILS]: settings.emails?.toString() || '',
  [settingTypes.BUSINESS_HOURS]: settings.businessHours?.toString() || '',
});

export default getFieldValuesFromSettings;
