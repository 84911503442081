const replaceBlockIdSelectorForRules = (stylesheet, oldBlockId, newBlockId) => {
  const { cssRules } = stylesheet;

  Array.from(cssRules).forEach((rule) => {
    if (rule.selectorText.includes(oldBlockId)) {
      // eslint-disable-next-line no-param-reassign
      rule.selectorText = rule.selectorText.replace(oldBlockId, newBlockId);
    }
  });
};

export default replaceBlockIdSelectorForRules;
