import getForcedDeviceWidth from './get-forced-device-width';
import { PAGE_WRAPPER_CLASSNAME } from '../constants/common';
import {
  DEFAULT_IFRAME_WIDTH,
  DEFAULT_IFRAME_HEIGHT,
  DEFAULT_IFRAME_SCALE,
} from '../constants/sizes';

const calculateFrameSize = (iframe, forcedWidth = null, forcedScale = null) => {
  let contentHeight = DEFAULT_IFRAME_HEIGHT;
  const forcedDeviceWidth = forcedWidth || getForcedDeviceWidth();

  if (!forcedDeviceWidth || forcedScale) {
    return {
      width: DEFAULT_IFRAME_WIDTH,
      height: DEFAULT_IFRAME_HEIGHT,
      scale: forcedScale || DEFAULT_IFRAME_SCALE,
      contentHeight,
    };
  }

  if (iframe.contentDocument) {
    const container = iframe.contentDocument.body.querySelector(`.${PAGE_WRAPPER_CLASSNAME}`);
    contentHeight = container.offsetHeight;
  }

  const parent = iframe.parentElement;
  const parentWidth = parent.offsetWidth;
  const parentHeight = parent.offsetHeight;

  const scale = forcedScale || parentWidth / forcedDeviceWidth;
  const forcedDeviceHeight = Math.round(parentHeight / scale);

  return {
    width: forcedDeviceWidth,
    height: forcedDeviceHeight,
    contentHeight,
    scale,
  };
};

export default calculateFrameSize;
