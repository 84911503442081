import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { view } from '@yola/ws-sdk';
import navbar from 'src/js/modules/navbar';

function ToolsContainer(props) {
  const { scrollTop, navbarHeight, children } = props;
  const position = -scrollTop;

  return (
    <div className="tools" style={{ top: navbarHeight }}>
      <div className="tools-inner" style={{ transform: `translate3d(0, ${position}px, 0)` }}>
        {children}
      </div>
    </div>
  );
}

ToolsContainer.propTypes = {
  scrollTop: PropTypes.number.isRequired,
  navbarHeight: PropTypes.number,
  children: PropTypes.node,
};

ToolsContainer.defaultProps = {
  navbarHeight: 0,
  children: null,
};

const mapStateToProps = (state) => ({
  scrollTop: view.selectors.getScrollPosition(state),
  navbarHeight: navbar.selectors.getHeight(state),
});

export default connect(mapStateToProps)(ToolsContainer);
