import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import bowser from 'yola-bowser';

function Button(props) {
  const {
    className,
    style,
    active,
    disabled,
    stretch,
    onClick,
    href,
    target,
    behavior,
    size,
    type,
    children,
    ...otherProps
  } = props;

  const iconPosition = typeof children[0] === 'string' ? 'right' : 'left';
  const isMobile = bowser.mobile || bowser.tablet;

  const btnClass = classNames(
    className,
    'button',
    `button--${type}`,
    `button--stretch-${stretch}`,
    `button--${size}`,
    {
      'button--disabled': disabled,
      'button--active': active,
      [`button--icon-position-${iconPosition}`]: children.length > 0,
      'button--mobile': isMobile,
    }
  );

  if (behavior === 'label') {
    return (
      // eslint-disable-next-line yola/jsx-a11y/label-has-for
      <label className={btnClass} style={style} {...otherProps}>
        {children}
      </label>
    );
  }

  if (href) {
    if (disabled) {
      return (
        <span className={btnClass} style={style}>
          {children}
        </span>
      );
    }
    return (
      <a
        className={btnClass}
        style={style}
        href={href}
        target={`_${target}`}
        onClick={onClick}
        {...otherProps}
      >
        {children}
      </a>
    );
  }
  return (
    // eslint-disable-next-line yola/react/button-has-type
    <button
      className={btnClass}
      disabled={disabled}
      style={style}
      onClick={onClick}
      type={behavior}
      {...otherProps}
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  stretch: PropTypes.oneOf(['content', 'block', 'square']),
  onClick: PropTypes.func,
  href: PropTypes.string,
  target: PropTypes.oneOf(['blank', 'self']),
  behavior: PropTypes.oneOf(['button', 'reset', 'submit', 'label']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  type: PropTypes.oneOf(['primary', 'secondary', 'default', 'link', 'link-primary', 'cta']),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.shape(),
};

Button.defaultProps = {
  active: false,
  disabled: false,
  stretch: 'content',
  onClick: null,
  href: null,
  target: 'self',
  behavior: 'button',
  size: 'medium',
  type: 'default',
  className: null,
  style: {},
};

export default Button;
