import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const ResizeTooltip = ({ direction, children, left, top }) => {
  const logoPreviewClasses = classNames('ws-resize-tooltip', {
    'ws-resize-tooltip__down': direction === 'down',
  });

  return (
    <div
      className={logoPreviewClasses}
      style={{
        left,
        top,
      }}
    >
      {children}
    </div>
  );
};

ResizeTooltip.propTypes = {
  left: PropTypes.number.isRequired,
  top: PropTypes.number.isRequired,
  direction: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ResizeTooltip;
