import segment from 'src/js/modules/analytics/segment';
import getDefaultTraits from 'src/js/modules/analytics/segment/helpers/get-default-traits';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';

const trackMediaSettingsOptionClicked = (elementId, altTitleOption) => {
  const { track, constants } = segment;

  track(constants.events.MEDIA_SETTINGS_OPTION_CLICKED, {
    ...getDefaultTraits(elementId),
    dialogId: dialogTypes.MEDIA_SETTINGS_DIALOG,
    altTitleOption,
  });
};

export default trackMediaSettingsOptionClicked;
