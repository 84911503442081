import fetchPublishedSite from './fetch-published-site';
import setPublishedSiteVhosts from './set-published-site-vhosts';
import fetchSiteData from './fetch-site-data';
import setPublishedDataLoadedStatus from './set-published-data-loaded-status';
import setPublishedSiteData from './set-published-site-data';
import setPrimaryVhost from './set-primary-vhost';
import setSiteData from './set-site-data';
import setSiteName from './set-site-name';
import siteReset from './site-reset';
import setPublishCount from './set-publish-count';
import setTemplateSwitched from './set-template-switched';
import updateSiteData from './update-site-data';

export default {
  fetchPublishedSite,
  fetchSiteData,
  setPublishedDataLoadedStatus,
  setPublishedSiteData,
  setPublishedSiteVhosts,
  setPrimaryVhost,
  setSiteData,
  setSiteName,
  siteReset,
  setPublishCount,
  setTemplateSwitched,
  updateSiteData,
};
