import get from 'lodash.get';
import { config } from '@yola/ws-sdk';

const getDomain = (rootState) => {
  const cfg = config.selectors.getConfig(rootState);

  return get(cfg, 'ws-editor.domain');
};

export default getDomain;
