import { assets, uuid } from '@yola/ws-sdk';
import urlJoin from 'url-join';
import constants from '../constants/common';

const mapIconsJSON = (json, directory = `${constants.ROOT_ICONS_DIRECTORY}/basic`) => {
  const newObj = {};

  function handleData(data, currentPath) {
    if (Array.isArray(data)) {
      const type = currentPath[currentPath.length - 1];
      const mappedData = data.map((item) => {
        const src = assets.helpers.composeWsAssetUrl(urlJoin(...currentPath, item.src));
        return {
          ...item,
          src,
          id: uuid(),
        };
      });

      newObj[type] = newObj[type] ? [...newObj[type], ...mappedData] : mappedData;
      return;
    }

    Object.keys(data).forEach((key) => {
      handleData(data[key], [...currentPath, key]);
    });
  }

  handleData(json, [directory]);
  return newObj;
};

export default mapIconsJSON;
