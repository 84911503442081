import React from 'react';
import PropTypes from 'prop-types';
import { Icon, FileTrigger } from '@yola/ws-ui';
import { textFormatting, assets, dialogs, utils, contentEditable } from '@yola/ws-sdk';
import Trigger from 'src/js/modules/common/components/trigger';
import scroller from 'src/js/modules/scroller';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import detectSideToScroll from '../helpers/detect-side-to-scroll';

const insertImage = async ({ source, width, height }) => {
  contentEditable.operations.addCheckpoint();

  const { element, imageElement } = await textFormatting.operations.insertImage({
    source,
    width,
    height,
  });

  await scroller.helpers.scrollToElement(imageElement, {
    includeOffset: false,
    sideToScroll: detectSideToScroll(imageElement),
  });
  dialogs.operations.hide();

  // When we're clicking on fileInput element loses focus and we should focus it manually
  element.setAttribute('contenteditable', 'true');
  element.focus();
};

const onImageUpload = async (fileList) => {
  const [imageFile] = fileList;
  if (!imageFile) return;

  const { getImageSize, addBaseHref } = assets.helpers;
  const { handleAsyncError } = utils;

  dialogs.operations.show(dialogTypes.IMAGE_LOADING_DIALOG);

  const [, err] = await handleAsyncError(assets.operations.clientUploadAsset(imageFile));

  if (err) return;

  const data = {
    asset: imageFile,
    name: imageFile.name,
  };

  const response = await assets.operations.createMediaGalleryAssets(data);
  const { source } = response.data;
  const { width, height } = await getImageSize(addBaseHref(source));

  assets.operations.makePublishable(source);

  await insertImage({ source, width, height });
};

const showWrongTypeFileDialog = () => {
  dialogs.operations.show(dialogTypes.FILE_TYPE_ERROR_DIALOG);
};

function InsertImageTrigger({ triggerId }) {
  return (
    <FileTrigger
      id="file-trigger"
      name="file-trigger"
      accept={assets.constants.IMAGE_ACCEPT_TYPE}
      onChange={onImageUpload}
      onWrongTypeFile={showWrongTypeFileDialog}
    >
      <Trigger id={triggerId}>
        <Icon glyph="image" size="20" strokeWidth="1.5" />
      </Trigger>
    </FileTrigger>
  );
}

InsertImageTrigger.propTypes = {
  triggerId: PropTypes.string.isRequired,
};

export default InsertImageTrigger;
