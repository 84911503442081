import actionTypes from '../constants/action-types';

const scrollTargetPath = (state = null, action) => {
  switch (action.type) {
    case actionTypes.SET_SCROLL_TARGET_PATH: {
      return action.payload.path;
    }
    case actionTypes.CLEAR_SCROLL_TARGET_PATH: {
      return null;
    }
    default: {
      return state;
    }
  }
};

export default scrollTargetPath;
