import React from 'react';

function TestimonialsPreview() {
  return (
    <svg viewBox="0 0 1920 1076" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="1920" height="1076" fill="white" />
      <rect width="1920" height="120" fill="white" />
      <rect opacity="0.8" x="375" y="44" width="100" height="32" rx="8" fill="#DADDE0" />
      <rect x="993" y="48" width="100" height="24" rx="8" fill="#B0B8BF" />
      <rect opacity="0.8" x="1125" y="48" width="100" height="24" rx="8" fill="#DADDE0" />
      <rect opacity="0.8" x="1257" y="48" width="100" height="24" rx="8" fill="#DADDE0" />
      <rect x="1405" y="32" width="140" height="56" rx="8" fill="#8E959E" />
      <rect opacity="0.4" x="1433" y="52" width="84" height="16" rx="8" fill="white" />
      <rect width="1920" height="836" transform="translate(0 120)" fill="white" />
      <rect x="761" y="200" width="398" height="48" rx="8" fill="#B0B8BF" />
      <rect opacity="0.8" x="649" y="288" width="622" height="24" rx="8" fill="#DADDE0" />
      <rect opacity="0.8" x="743.5" y="332" width="433" height="24" rx="8" fill="#DADDE0" />
      <circle opacity="0.8" cx="560" cy="530" r="110" fill="#B0B8BF" />
      <g opacity="0.8">
        <path
          d="M586.666 538.89L578.923 531.146C575.017 527.241 568.686 527.241 564.78 531.146L539.259 556.667M540 556.667H580C583.682 556.667 586.666 553.683 586.666 550.001V510.001C586.666 506.319 583.682 503.334 580 503.334H540C536.318 503.334 533.333 506.319 533.333 510.001V550.001C533.333 553.683 536.318 556.667 540 556.667ZM549.629 524.075C552.084 524.075 554.074 522.085 554.074 519.63C554.074 517.176 552.084 515.186 549.629 515.186C547.175 515.186 545.185 517.176 545.185 519.63C545.185 522.085 547.175 524.075 549.629 524.075Z"
          stroke="white"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M586.667 538.89L578.924 531.146C575.018 527.241 568.687 527.241 564.781 531.146L539.26 556.667M540.001 556.667H580.001C583.683 556.667 586.667 553.683 586.667 550.001V510.001C586.667 506.319 583.683 503.334 580.001 503.334H540.001C536.319 503.334 533.334 506.319 533.334 510.001V550.001C533.334 553.683 536.319 556.667 540.001 556.667ZM549.63 524.075C552.085 524.075 554.075 522.085 554.075 519.63C554.075 517.176 552.085 515.186 549.63 515.186C547.176 515.186 545.186 517.176 545.186 519.63C545.186 522.085 547.176 524.075 549.63 524.075Z"
          stroke="white"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect x="606" y="576" width="64" height="64" rx="32" fill="#B0B8BF" />
      <g opacity="0.8">
        <path
          d="M636.566 612.554C636.566 613.998 636.075 615.167 635.092 616.063C634.11 616.91 632.87 617.333 631.37 617.333C629.561 617.333 628.062 616.76 626.873 615.615C625.736 614.47 625.167 612.828 625.167 610.687C625.167 608.398 625.839 606.158 627.183 603.967C628.579 601.727 630.517 599.96 632.999 598.666L634.782 601.279C633.438 602.175 632.353 603.171 631.526 604.266C630.75 605.361 630.233 606.63 629.975 608.074C630.44 607.875 630.983 607.775 631.603 607.775C633.051 607.775 634.24 608.223 635.17 609.119C636.101 610.015 636.566 611.16 636.566 612.554ZM650.834 612.554C650.834 613.998 650.343 615.167 649.36 616.063C648.378 616.91 647.137 617.333 645.638 617.333C643.829 617.333 642.33 616.76 641.141 615.615C640.004 614.47 639.435 612.828 639.435 610.687C639.435 608.398 640.107 606.158 641.451 603.967C642.847 601.727 644.785 599.96 647.267 598.666L649.05 601.279C647.706 602.175 646.621 603.171 645.793 604.266C645.018 605.361 644.501 606.63 644.243 608.074C644.708 607.875 645.251 607.775 645.871 607.775C647.318 607.775 648.507 608.223 649.438 609.119C650.368 610.015 650.834 611.16 650.834 612.554Z"
          fill="white"
        />
      </g>
      <rect x="486" y="680" width="148" height="32" rx="8" fill="#B0B8BF" />
      <rect opacity="0.8" x="429.5" y="732" width="261" height="24" rx="8" fill="#DADDE0" />
      <rect opacity="0.8" x="474.5" y="772" width="171" height="24" rx="8" fill="#DADDE0" />
      <circle opacity="0.8" cx="960" cy="530" r="110" fill="#B0B8BF" />
      <g opacity="0.8">
        <path
          d="M986.666 538.89L978.923 531.146C975.017 527.241 968.686 527.241 964.78 531.146L939.259 556.667M940 556.667H980C983.682 556.667 986.666 553.683 986.666 550.001V510.001C986.666 506.319 983.682 503.334 980 503.334H940C936.318 503.334 933.333 506.319 933.333 510.001V550.001C933.333 553.683 936.318 556.667 940 556.667ZM949.629 524.075C952.084 524.075 954.074 522.085 954.074 519.63C954.074 517.176 952.084 515.186 949.629 515.186C947.175 515.186 945.185 517.176 945.185 519.63C945.185 522.085 947.175 524.075 949.629 524.075Z"
          stroke="white"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M986.667 538.89L978.924 531.146C975.018 527.241 968.687 527.241 964.781 531.146L939.26 556.667M940.001 556.667H980.001C983.683 556.667 986.667 553.683 986.667 550.001V510.001C986.667 506.319 983.683 503.334 980.001 503.334H940.001C936.319 503.334 933.334 506.319 933.334 510.001V550.001C933.334 553.683 936.319 556.667 940.001 556.667ZM949.63 524.075C952.085 524.075 954.075 522.085 954.075 519.63C954.075 517.176 952.085 515.186 949.63 515.186C947.176 515.186 945.186 517.176 945.186 519.63C945.186 522.085 947.176 524.075 949.63 524.075Z"
          stroke="white"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect x="1006" y="576" width="64" height="64" rx="32" fill="#B0B8BF" />
      <g opacity="0.8">
        <path
          d="M1036.57 612.554C1036.57 613.998 1036.07 615.167 1035.09 616.063C1034.11 616.91 1032.87 617.333 1031.37 617.333C1029.56 617.333 1028.06 616.76 1026.87 615.615C1025.74 614.47 1025.17 612.828 1025.17 610.687C1025.17 608.398 1025.84 606.158 1027.18 603.967C1028.58 601.727 1030.52 599.96 1033 598.666L1034.78 601.279C1033.44 602.175 1032.35 603.171 1031.53 604.266C1030.75 605.361 1030.23 606.63 1029.97 608.074C1030.44 607.875 1030.98 607.775 1031.6 607.775C1033.05 607.775 1034.24 608.223 1035.17 609.119C1036.1 610.015 1036.57 611.16 1036.57 612.554ZM1050.83 612.554C1050.83 613.998 1050.34 615.167 1049.36 616.063C1048.38 616.91 1047.14 617.333 1045.64 617.333C1043.83 617.333 1042.33 616.76 1041.14 615.615C1040 614.47 1039.43 612.828 1039.43 610.687C1039.43 608.398 1040.11 606.158 1041.45 603.967C1042.85 601.727 1044.79 599.96 1047.27 598.666L1049.05 601.279C1047.71 602.175 1046.62 603.171 1045.79 604.266C1045.02 605.361 1044.5 606.63 1044.24 608.074C1044.71 607.875 1045.25 607.775 1045.87 607.775C1047.32 607.775 1048.51 608.223 1049.44 609.119C1050.37 610.015 1050.83 611.16 1050.83 612.554Z"
          fill="white"
        />
      </g>
      <rect x="886" y="680" width="148" height="32" rx="8" fill="#B0B8BF" />
      <rect opacity="0.8" x="829.5" y="732" width="261" height="24" rx="8" fill="#DADDE0" />
      <rect opacity="0.8" x="874.5" y="772" width="171" height="24" rx="8" fill="#DADDE0" />
      <circle opacity="0.8" cx="1360" cy="530" r="110" fill="#B0B8BF" />
      <g opacity="0.8">
        <path
          d="M1386.67 538.89L1378.92 531.146C1375.02 527.241 1368.69 527.241 1364.78 531.146L1339.26 556.667M1340 556.667H1380C1383.68 556.667 1386.67 553.683 1386.67 550.001V510.001C1386.67 506.319 1383.68 503.334 1380 503.334H1340C1336.32 503.334 1333.33 506.319 1333.33 510.001V550.001C1333.33 553.683 1336.32 556.667 1340 556.667ZM1349.63 524.075C1352.08 524.075 1354.07 522.085 1354.07 519.63C1354.07 517.176 1352.08 515.186 1349.63 515.186C1347.18 515.186 1345.19 517.176 1345.19 519.63C1345.19 522.085 1347.18 524.075 1349.63 524.075Z"
          stroke="white"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1386.67 538.89L1378.92 531.146C1375.02 527.241 1368.69 527.241 1364.78 531.146L1339.26 556.667M1340 556.667H1380C1383.68 556.667 1386.67 553.683 1386.67 550.001V510.001C1386.67 506.319 1383.68 503.334 1380 503.334H1340C1336.32 503.334 1333.33 506.319 1333.33 510.001V550.001C1333.33 553.683 1336.32 556.667 1340 556.667ZM1349.63 524.075C1352.08 524.075 1354.07 522.085 1354.07 519.63C1354.07 517.176 1352.08 515.186 1349.63 515.186C1347.18 515.186 1345.19 517.176 1345.19 519.63C1345.19 522.085 1347.18 524.075 1349.63 524.075Z"
          stroke="white"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect x="1406" y="576" width="64" height="64" rx="32" fill="#B0B8BF" />
      <g opacity="0.8">
        <path
          d="M1436.56 612.554C1436.56 613.998 1436.07 615.167 1435.09 616.063C1434.11 616.91 1432.87 617.333 1431.37 617.333C1429.56 617.333 1428.06 616.76 1426.87 615.615C1425.73 614.47 1425.17 612.828 1425.17 610.687C1425.17 608.398 1425.84 606.158 1427.18 603.967C1428.58 601.727 1430.52 599.96 1433 598.666L1434.78 601.279C1433.44 602.175 1432.35 603.171 1431.52 604.266C1430.75 605.361 1430.23 606.63 1429.97 608.074C1430.44 607.875 1430.98 607.775 1431.6 607.775C1433.05 607.775 1434.24 608.223 1435.17 609.119C1436.1 610.015 1436.56 611.16 1436.56 612.554ZM1450.83 612.554C1450.83 613.998 1450.34 615.167 1449.36 616.063C1448.38 616.91 1447.14 617.333 1445.64 617.333C1443.83 617.333 1442.33 616.76 1441.14 615.615C1440 614.47 1439.43 612.828 1439.43 610.687C1439.43 608.398 1440.11 606.158 1441.45 603.967C1442.85 601.727 1444.78 599.96 1447.27 598.666L1449.05 601.279C1447.71 602.175 1446.62 603.171 1445.79 604.266C1445.02 605.361 1444.5 606.63 1444.24 608.074C1444.71 607.875 1445.25 607.775 1445.87 607.775C1447.32 607.775 1448.51 608.223 1449.44 609.119C1450.37 610.015 1450.83 611.16 1450.83 612.554Z"
          fill="white"
        />
      </g>
      <rect x="1286" y="680" width="148" height="32" rx="8" fill="#B0B8BF" />
      <rect opacity="0.8" x="1229.5" y="732" width="261" height="24" rx="8" fill="#DADDE0" />
      <rect opacity="0.8" x="1274.5" y="772" width="171" height="24" rx="8" fill="#DADDE0" />
      <rect width="1920" height="120" transform="translate(0 956)" fill="#646C75" />
      <g opacity="0.3">
        <rect x="770" y="1004" width="100" height="24" rx="8" fill="white" />
        <rect x="910" y="1004" width="100" height="24" rx="8" fill="white" />
        <rect x="1050" y="1004" width="100" height="24" rx="8" fill="white" />
      </g>
    </svg>
  );
}

export default TestimonialsPreview;
