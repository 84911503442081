import { dialogs } from '@yola/ws-sdk';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import isGenericGalleryItem from '../../verifiers/is-generic-gallery-item';

const {
  PHOTO_STOCK_DIALOG,
  GALLERY_DIALOG,
  GALLERY_CONFIRMATION_DIALOG,
  GALLERY_FILE_TYPE_INFO_DIALOG,
  GALLERY_FILE_QUANTITY_INFO_DIALOG,
  GALLERY_PHOTO_STOCK_SAVING_DIALOG,
} = dialogTypes;

const getDialogProps = (params) => {
  const {
    currentDialogType,
    targetDialogType,
    galleryItems,
    uploadedGalleryItems,
    dismissedFilesNamesList,
    hasOnlyDefaultImages,
    elementId,
  } = params;
  const updateItems = [...galleryItems, ...uploadedGalleryItems];
  switch (currentDialogType) {
    case GALLERY_FILE_QUANTITY_INFO_DIALOG:
      switch (targetDialogType) {
        case GALLERY_FILE_TYPE_INFO_DIALOG: {
          const nextDialog = hasOnlyDefaultImages ? GALLERY_CONFIRMATION_DIALOG : GALLERY_DIALOG;
          return {
            items: updateItems,
            elementId,
            onDismiss() {
              dialogs.operations.show(
                GALLERY_FILE_TYPE_INFO_DIALOG,
                getDialogProps({
                  ...params,
                  currentDialogType: GALLERY_FILE_TYPE_INFO_DIALOG,
                  targetDialogType: nextDialog,
                })
              );
            },
          };
        }
        case GALLERY_CONFIRMATION_DIALOG:
          return {
            items: updateItems,
            elementId,
            onDismiss() {
              dialogs.operations.show(
                targetDialogType,
                getDialogProps({
                  ...params,
                  currentDialogType: GALLERY_CONFIRMATION_DIALOG,
                  targetDialogType: GALLERY_DIALOG,
                })
              );
            },
          };
        case GALLERY_DIALOG:
        default:
          return {
            items: updateItems,
            elementId,
            onDismiss() {
              dialogs.operations.show(
                targetDialogType,
                getDialogProps({ ...params, currentDialogType: GALLERY_DIALOG })
              );
            },
          };
      }
    case GALLERY_FILE_TYPE_INFO_DIALOG:
      switch (targetDialogType) {
        case GALLERY_CONFIRMATION_DIALOG:
          return {
            items: updateItems,
            elementId,
            dismissedFilesNamesList,
            onDismiss() {
              dialogs.operations.show(
                targetDialogType,
                getDialogProps({
                  ...params,
                  currentDialogType: GALLERY_CONFIRMATION_DIALOG,
                  targetDialogType: GALLERY_DIALOG,
                })
              );
            },
          };
        case GALLERY_DIALOG:
        default:
          return {
            items: updateItems,
            elementId,
            dismissedFilesNamesList,
            onDismiss() {
              dialogs.operations.show(
                targetDialogType,
                getDialogProps({ ...params, currentDialogType: GALLERY_DIALOG })
              );
            },
          };
      }
    case GALLERY_CONFIRMATION_DIALOG:
      return {
        onSubmit: () => {
          dialogs.operations.show(GALLERY_DIALOG, {
            items: updateItems,
            elementId,
          });
        },
        onDiscard: () => {
          const filteredGalleryItems = galleryItems.filter(isGenericGalleryItem);
          dialogs.operations.show(GALLERY_DIALOG, {
            items: [...filteredGalleryItems, ...uploadedGalleryItems],
            elementId,
          });
        },
        onCancel: () => {
          dialogs.operations.show(GALLERY_DIALOG, { items: galleryItems, elementId });
        },
      };
    case PHOTO_STOCK_DIALOG:
      return {
        onSubmit: () => {
          dialogs.operations.show(GALLERY_PHOTO_STOCK_SAVING_DIALOG, {
            items: galleryItems,
            selectedPhotos: uploadedGalleryItems,
            elementId,
          });
        },
        onDiscard: () => {
          dialogs.operations.show(GALLERY_PHOTO_STOCK_SAVING_DIALOG, {
            items: [],
            initialItems: galleryItems,
            selectedPhotos: uploadedGalleryItems,
            elementId,
          });
        },
        onCancel: () => {
          dialogs.operations.show(GALLERY_DIALOG, { items: galleryItems, elementId });
        },
      };
    case GALLERY_DIALOG:
    default:
      return { items: updateItems, elementId };
  }
};

export default getDialogProps;
