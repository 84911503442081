import React from 'react';
import PropTypes from 'prop-types';
import { template, i18next } from '@yola/ws-sdk';
import { Icon } from '@yola/ws-ui';
import dashesToCapitalizeSentence from '../../utils/dashes-to-capitalize-sentence';
import { DONATIONS_CATEGORY_NAME } from '../constants/common';

function BlocksLibraryContentHeader({
  submittedSearchValue,
  onCloseContentSectionMobile,
  activeCategory,
  isBlocksListEmpty,
}) {
  const isDonationsCategory = activeCategory?.name === DONATIONS_CATEGORY_NAME;
  return (
    <div className="ws-blocks-library__content-header">
      <button
        className="ws-blocks-library__content-close"
        type="button"
        onClick={onCloseContentSectionMobile}
      >
        <Icon glyph="chevron-left" size="20" strokeWidth="1.3" />
      </button>

      {activeCategory && (
        <React.Fragment>
          <div className="ws-blocks-library__content-title">
            {isDonationsCategory
              ? i18next.t('Donations')
              : dashesToCapitalizeSentence(
                  template.accessors.getMetaTranslations(activeCategory.name)
                )}
          </div>
          <div className="ws-blocks-library__content-subtitle">
            {isDonationsCategory
              ? activeCategory.description
              : template.accessors.getMetaTranslations(activeCategory.description)}
          </div>
        </React.Fragment>
      )}

      {submittedSearchValue && (
        <React.Fragment>
          {isBlocksListEmpty ? (
            <React.Fragment>
              <div className="ws-blocks-library__content-title">{i18next.t('Sorry!')}</div>
              <div className="ws-blocks-library__content-subtitle">
                {i18next.t('Nothing was found')}
              </div>
            </React.Fragment>
          ) : (
            <div className="ws-blocks-library__content-title">{`'${submittedSearchValue}'`}</div>
          )}
        </React.Fragment>
      )}
    </div>
  );
}

BlocksLibraryContentHeader.propTypes = {
  submittedSearchValue: PropTypes.string.isRequired,
  onCloseContentSectionMobile: PropTypes.func.isRequired,
  activeCategory: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  isBlocksListEmpty: PropTypes.bool.isRequired,
};

BlocksLibraryContentHeader.defaultProps = {
  activeCategory: null,
};

export default BlocksLibraryContentHeader;
