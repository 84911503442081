import generateEmailShareLink from './generate-email-share-link';
import generateFacebookShareLink from './generate-facebook-share-link';
import generateLinkedinShareLink from './generate-linkedin-share-link';
import generateTwitterShareLink from './generate-twitter-share-link';
import generateReferralLink from './generate-referral-link';
import getShortReferralLinks from './get-short-referral-links';
import isReferAFriendShouldBeShown from './is-refer-a-friend-should-be-shown';
import referralLinksStorage from './referral-links-storage';

export default {
  generateEmailShareLink,
  generateFacebookShareLink,
  generateLinkedinShareLink,
  generateTwitterShareLink,
  generateReferralLink,
  getShortReferralLinks,
  isReferAFriendShouldBeShown,
  referralLinksStorage,
};
