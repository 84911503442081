const isOptionFit = (item, sourceItem) =>
  !!item.options.find((option) => option.value === sourceItem.value);

const getDisplayOptionsToUpdate = (source, destination) =>
  destination.reduce((diff, item) => {
    const sourceItem = source.find((el) => el.id === item.id);
    if (!sourceItem) return diff;

    const { options } = item;

    if (options && isOptionFit(item, sourceItem)) {
      diff.push(sourceItem);
    } else if (options && !isOptionFit(item, sourceItem)) {
      const defaultValue = item.options[0].value;
      diff.push({
        ...sourceItem,
        value: defaultValue,
        options,
        sourceOptions: sourceItem.options,
      });
    } else {
      diff.push(sourceItem);
    }
    return diff;
  }, []);

export default getDisplayOptionsToUpdate;
