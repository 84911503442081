import publishWebsite from './publish-website';
import unpublishWebsite from './unpublish-website';
import finishPublishing from './finish-publishing';
import setPublishingStatus from './set-publishing-status';

export default {
  publishWebsite,
  finishPublishing,
  unpublishWebsite,
  setPublishingStatus,
};
