import { hooks, site, clipboard, blocks, template } from '@yola/ws-sdk';
import constants from '../constants';
import track from '../track';

const handler = () => {
  const { element } = clipboard.accessors.getClipboardData();
  if (!element) return;

  const targetBlockId = blocks.accessors.getBlockIdByElement(element);
  const targetBlockVariationId = blocks.accessors.getVariationIdByElement(element);

  track(constants.events.BLOCK_PASTED, {
    siteId: site.accessors.getSiteId(),
    templateBuildSlug: template.accessors.getBuildSlug(),
    targetBlockId,
    targetBlockVariationId,
  });
};

const trackBlockPasted = () => {
  hooks.operations.subscribe(hooks.availableHooks.ON_AFTER_BLOCK_PASTED, handler);
};

export default trackBlockPasted;
