import get from 'lodash.get';
import { config } from '@yola/ws-sdk';

const getLocaleCookie = (rootState) => {
  const cfg = config.selectors.getConfig(rootState);

  return get(cfg, 'common.authtkt.current_language_cookie');
};

export default getLocaleCookie;
