import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import bowser from 'yola-bowser';
import Render from './render';

class Input extends React.Component {
  constructor(props) {
    super(props);

    this.inputRef = createRef();
  }

  componentDidMount() {
    const { selected } = this.props;

    if (selected) {
      this.inputRef.current.select();
    }
  }

  render() {
    const { error, type, autoComplete, selected, size, mobileSize, ...otherProps } = this.props;
    const isMobile = bowser.mobile || bowser.tablet;
    const resultSize = isMobile ? mobileSize : size;

    return (
      <div
        className={classNames('input', {
          'input--error': error,
        })}
      >
        <Render if={type !== 'textarea'}>
          <input
            className={classNames('input--element', `input--${resultSize}`)}
            ref={this.inputRef}
            type={type}
            autoComplete={autoComplete}
            {...otherProps}
          />
        </Render>
        <Render if={type === 'textarea'}>
          <textarea
            className={classNames(
              'input--element',
              'input--textarea',
              `input--textarea-${resultSize}`
            )}
            ref={this.inputRef}
            {...otherProps}
          />
        </Render>
      </div>
    );
  }
}

Input.propTypes = {
  type: PropTypes.string,
  autoComplete: PropTypes.string,
  error: PropTypes.bool,
  selected: PropTypes.bool,
  size: PropTypes.oneOf(['medium', 'large']),
  mobileSize: PropTypes.oneOf(['medium', 'large']),
};

Input.defaultProps = {
  type: 'text',
  autoComplete: 'off',
  error: false,
  selected: false,
  size: 'medium',
  mobileSize: 'medium',
};

export default Input;
