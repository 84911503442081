import { extensions } from '@yola/ws-sdk';
import constants from '../constants/common';
import SocialIconsResizeTool from '../components/social-icons-resize-tool';

const socialIconsResizeToolSettings = {
  matches(element) {
    const settings = extensions.accessors.getExtensionSettings(constants.slug);
    const { querySelector, resizeRestrictedParentsSelector } = settings;

    if (resizeRestrictedParentsSelector && element.closest(resizeRestrictedParentsSelector)) {
      return false;
    }

    return element.matches(querySelector);
  },
  container: SocialIconsResizeTool,
};

export default socialIconsResizeToolSettings;
