import store from 'src/js/store';
import selectors from '../selectors';

const getContactFormSubmissionUrl = () => {
  const state = store.getState();

  return selectors.getContactFormSubmissionUrl(state);
};

export default getContactFormSubmissionUrl;
