import { hooks, blocks, site, view, globals, template } from '@yola/ws-sdk';
import getCaptionForElement from '../../../common/helpers/get-caption-for-element';
import constants from '../constants';
import track from '../track';

const sendAnalytics = (elementRefId) => {
  const templateBuildSlug = template.accessors.getBuildSlug();
  const siteId = site.accessors.getSiteId();
  const currentElement = view.accessors.getLiveElement(elementRefId);
  const parentElement = blocks.accessors.getClosestBlockByElementId(elementRefId);
  const blockId = blocks.accessors.getBlockIdByElement(parentElement);
  const blockVariationId = blocks.accessors.getVariationIdByElement(parentElement);
  const elementId = getCaptionForElement(currentElement).id;

  track(constants.events.SITE_ELEMENT_CLICKED, {
    siteId,
    templateBuildSlug,
    blockId,
    blockVariationId,
    elementId,
  });
};

const trackSiteElementClicked = () => {
  hooks.operations.subscribe(hooks.availableHooks.ON_AFTER_ELEMENT_SELECTED, ({ elementId }) => {
    if (!elementId) return;
    sendAnalytics(elementId);
  });

  hooks.operations.subscribe(hooks.availableHooks.ON_AFTER_ELEMENT_FOCUSED, ({ elementId }) => {
    if (!elementId) return;
    const element = view.accessors.getLiveElement(elementId);

    if (!element.closest(globals.constants.GLOBAL_TAG_NAME)) return;
    sendAnalytics(elementId);
  });
};

export default trackSiteElementClicked;
