import setDefaultAttributes from './set-default-attributes';

const afterViewLoad = (liveDOM) => {
  if (!liveDOM || !liveDOM.querySelector('ws-contact-form-container')) {
    return undefined;
  }

  return setDefaultAttributes(liveDOM);
};

export default afterViewLoad;
