import { dialogs, integration, saving } from '@yola/ws-sdk';
import store from 'src/js/store';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import checkForPendingMigrations from './check-for-pending-migrations';

const saveSiteWithEffect = async (fn = () => {}) => {
  const saveResponse = await store.dispatch(saving.thunks.save());

  const hasPendingMigration = checkForPendingMigrations(saveResponse);

  if (!hasPendingMigration) {
    await fn();

    return null;
  }

  const integrationSettings = integration.accessors.getSettings();

  const onAction = () => {
    integrationSettings.onReloadEditor();
  };

  store.dispatch(
    dialogs.actions.show(dialogTypes.UPDATE_REQUIRED, {
      onAction,
    })
  );

  return null;
};

const saveSite = () => {
  saveSiteWithEffect(Function.prototype);
};

export { saveSiteWithEffect };
export default saveSite;
