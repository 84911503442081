import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { utils, transliteration } from '@yola/ws-sdk';
import {
  Text,
  Modal,
  Formik,
  ButtonGroup,
  Panel,
  PanelGroup,
  PanelLeft,
  Button,
  designSystem,
} from '@yola/ws-ui';
import { ViewportConsumer } from 'yola-editor/src/js/utils/viewport-provider';
import SeoSection from './seo-section';
import GeneralSection from './general-section';
import CustomCodeSection from './custom-code-section';
import constants from '../constants';
import verifiers from '../verifiers';

const { common, sizes, tabIdentifiers } = constants;
const { Tabs, TabsList, Tab, TabsContentList, TabContent } = designSystem;

class PageSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeUpgradePopover: null,
    };

    this.languageCodeOptions = props.languageCodeOptions;
    this.pageNameError = false;
    this.pathError = false;
    this.descriptionError = false;
    this.titleTagError = false;
    this.pagePasswordError = false;

    this.headCodeUpgradePopoverRef = React.createRef();
    this.headerCodeUpgradePopoverRef = React.createRef();
    this.footerCodeUpgradePopoverRef = React.createRef();
    this.pageNameRef = React.createRef();
    this.pathRef = React.createRef();
    this.descriptionRef = React.createRef();
    this.titleTagRef = React.createRef();
    this.scrollContainer = React.createRef();
    this.pagePasswordRef = React.createRef();

    this.onCodeFieldClick = this.onCodeFieldClick.bind(this);
    this.finishPopoverAnimation = this.finishPopoverAnimation.bind(this);
    this.validatePageName = this.validatePageName.bind(this);
    this.validateDescription = this.validateDescription.bind(this);
    this.validateTitleTag = this.validateTitleTag.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.validatePath = this.validatePath.bind(this);
    this.validatePagePassword = this.validatePagePassword.bind(this);
  }

  onCodeFieldClick(codeFieldName, popoverRef, codeFieldsDisabled) {
    const { scrollElementIntoView } = this.props;

    if (codeFieldsDisabled) {
      const element = popoverRef.current;

      scrollElementIntoView(this.scrollContainer.current, element).then(() => {
        this.setState({ activeUpgradePopover: codeFieldName }, () => {
          setTimeout(this.finishPopoverAnimation, common.POPOVER_ANIMATION_TIME);
        });
      });
    }
  }

  onFormSubmit(onSuccess) {
    const { scrollElementIntoView, setActiveTabId, passwordProtection } = this.props;
    const errors = [];

    const scrollToError = () => {
      if (errors.length) {
        const { element, tabId } = errors[0];

        setActiveTabId(tabId).then(() => {
          scrollElementIntoView(this.scrollContainer.current, element);
        });

        return;
      }

      onSuccess();
    };

    if (this.pageNameError) {
      const element = this.pageNameRef.current;
      errors.push({ element, tabId: tabIdentifiers.GENERAL });
    }

    if (this.pathError) {
      const element = this.pathRef.current;
      errors.push({ element, tabId: tabIdentifiers.GENERAL });
    }

    if (this.descriptionError) {
      const element = this.descriptionRef.current;
      errors.push({ element, tabId: tabIdentifiers.SEO });
    }

    if (this.titleTagError) {
      const element = this.titleTagRef.current;
      errors.push({ element, tabId: tabIdentifiers.SEO });
    }

    if (this.pagePasswordError && passwordProtection) {
      const element = this.pagePasswordRef.current;

      errors.push({ element, tabId: tabIdentifiers.GENERAL });
    }

    scrollToError();
  }

  finishPopoverAnimation() {
    this.setState({
      activeUpgradePopover: null,
    });
  }

  validatePagePassword(value) {
    const {
      captions: {
        general: {
          passwordProtectedPages: { emptyPasswordError },
        },
      },
    } = this.props;

    if (!value) {
      this.pagePasswordError = true;

      return emptyPasswordError;
    }

    this.pagePasswordError = false;

    return null;
  }

  validatePageName(value = '') {
    const {
      captions: {
        general: {
          title: { validationError, emptyError, homePageError, maxLengthError },
        },
      },
      languageCodes,
    } = this.props;

    if (!value) {
      this.pageNameError = true;
      return emptyError;
    }

    if (languageCodes.includes(transliteration.slugify(value))) {
      this.pageNameError = true;
      return homePageError;
    }

    const includeNumberOrString = value.match(common.LETTER_OR_NUMBER_REGEXP);
    if (!includeNumberOrString) {
      this.pageNameError = true;
      return validationError;
    }

    if (value.length > common.MAX_PAGE_NAME_LENGTH) {
      this.pageNameError = true;
      return maxLengthError;
    }

    this.pageNameError = false;
    return null;
  }

  validatePath(value = '') {
    const { captions, pageId, isHomePage, isHomePagePath, languageCodes } = this.props;
    const { path: pathCaptions } = captions;
    const {
      emptyError,
      startingSymbolError,
      validationError,
      notUniqueError,
      homePageError,
      reservedError,
      pathsAmountError,
    } = pathCaptions;

    if (!value) {
      this.pathError = true;
      return emptyError;
    }

    if (!value.startsWith('/')) {
      this.pathError = true;
      return startingSymbolError;
    }

    if (!verifiers.isPathValid(value)) {
      this.pathError = true;
      return validationError;
    }

    if (value.split('/').filter((item) => item).length > 3) {
      this.pathError = true;
      return pathsAmountError;
    }

    if (!isHomePage && isHomePagePath(value, languageCodes)) {
      this.pathError = true;
      return homePageError;
    }

    if (!verifiers.isPathUnique(value, pageId)) {
      this.pathError = true;
      return notUniqueError;
    }

    if (verifiers.isPathReserved(value)) {
      this.pathError = true;
      return reservedError;
    }

    this.pathError = false;
    return null;
  }

  validateDescription(value = '') {
    if (value.length > common.MAX_DESCRIPTION_LENGTH) {
      const {
        captions: {
          seoSettings: {
            description: { error },
          },
        },
      } = this.props;

      this.descriptionError = true;

      return error;
    }

    this.descriptionError = false;
    return null;
  }

  validateTitleTag(value = '') {
    if (value.length > common.MAX_TITLE_TAG_LENGTH) {
      const {
        captions: {
          seoSettings: {
            title: { error },
          },
        },
      } = this.props;

      this.titleTagError = true;

      return error;
    }

    this.titleTagError = false;
    return null;
  }

  render() {
    const {
      captions,
      formData,
      styles,
      className,
      pathPrefix,
      businessName,
      headCodeDisabled,
      headerCodeDisabled,
      footerCodeDisabled,
      onHeadCodeUpgradeClick,
      onHeaderCodeUpgradeClick,
      onFooterCodeUpgradeClick,
      mobile,
      onCancel,
      onSubmit,
      onTabChange,
      bindSubmitForm,
      onKeyDown,
      isDisplayInNavAvailable,
      isHomePage,
      activeTabId,
      isSameAsPageName,
      onChangeSameAsPageName,
      onChangePath,
      formRef,
      onChangePageName,
      passwordProtectedPagesAvailable,
      isProtectedPagesPlanFeatureAvailable,
      passwordProtection,
      enablePasswordProtection,
      disablePasswordProtection,
      passwordVisibility,
      switchPasswordVisibility,
      isBusinessInfoInWebsiteSettingsEnabled,
    } = this.props;

    const pageSettingsClasses = classNames('ws-page-settings', className);

    const modalClasses = classNames(
      'ws-page-settings__modal',
      mobile && 'ws-page-settings__modal--mobile'
    );

    const { activeUpgradePopover } = this.state;

    return (
      <ViewportConsumer>
        {({ height, width } = {}) => {
          const modalWidth =
            width >= sizes.LARGE_RES_WIDTH ? sizes.MODAL_LARGE_WIDTH : sizes.MODAL_MEDIUM_WIDTH;
          const modalHeight =
            height >= sizes.LARGE_RES_HEIGHT ? sizes.MODAL_LARGE_HEIGHT : sizes.MODAL_MEDIUM_HEIGHT;

          return (
            <div className={pageSettingsClasses} style={styles}>
              <Formik initialValues={formData} ref={formRef} onSubmit={onSubmit}>
                {({ values, handleSubmit, submitForm }) => {
                  bindSubmitForm(submitForm);
                  return (
                    <Modal
                      handleSubmit={() => this.onFormSubmit(handleSubmit)}
                      handleCancel={onCancel}
                      data={values}
                      promptCaptions={captions.prompt}
                      className={modalClasses}
                      width={modalWidth}
                      height={modalHeight}
                      centered
                      overlay="visible"
                      draggable={false}
                      resizable={false}
                      dragHandleSelector=".ws-page-settings__header"
                      fullscreen={mobile}
                      onKeyDown={onKeyDown}
                    >
                      <PanelGroup height="100%">
                        <Panel
                          theme="gray-100"
                          height={sizes.CONTROL_PANEL_HEIGHT}
                          align="middle"
                          corners="squared"
                          className="ws-panel--shrink ws-page-settings__header"
                          style={{ borderBottom: 'none' }}
                        >
                          <PanelLeft style={{ paddingLeft: '24px' }}>
                            <Text type="heading-6">{captions.pageSettings}</Text>
                          </PanelLeft>
                        </Panel>
                        <div className="ws-page-settings__tabs">
                          <Tabs
                            activeTabId={activeTabId}
                            onTabChange={onTabChange}
                            scrollContainerRef={this.scrollContainer}
                          >
                            <TabsList>
                              <Tab tabId={tabIdentifiers.GENERAL} label={captions.general.name} />
                              <Tab tabId={tabIdentifiers.SEO} label={captions.seoSettings.name} />
                              <Tab
                                tabId={tabIdentifiers.CUSTOM_CODE}
                                label={captions.customCode.name}
                              />
                            </TabsList>
                            <TabsContentList>
                              <TabContent tabId={tabIdentifiers.GENERAL}>
                                <GeneralSection
                                  formData={formData}
                                  captions={captions}
                                  pathPrefix={pathPrefix}
                                  languageCodeOptions={this.languageCodeOptions}
                                  isDisplayInNavAvailable={isDisplayInNavAvailable}
                                  isPathDisabled={isHomePage}
                                  pageNameRef={this.pageNameRef}
                                  pathRef={this.pathRef}
                                  pagePasswordRef={this.pagePasswordRef}
                                  validatePageName={this.validatePageName}
                                  validatePath={this.validatePath}
                                  validatePagePassword={this.validatePagePassword}
                                  onChangePageName={onChangePageName}
                                  onChangePath={onChangePath}
                                  passwordProtectedPagesAvailable={passwordProtectedPagesAvailable}
                                  isProtectedPagesPlanFeatureAvailable={
                                    isProtectedPagesPlanFeatureAvailable
                                  }
                                  passwordProtection={passwordProtection}
                                  enablePasswordProtection={enablePasswordProtection}
                                  disablePasswordProtection={disablePasswordProtection}
                                  passwordVisibility={passwordVisibility}
                                  switchPasswordVisibility={switchPasswordVisibility}
                                />
                              </TabContent>
                              <TabContent tabId={tabIdentifiers.SEO}>
                                <SeoSection
                                  formValues={values}
                                  pathPrefix={pathPrefix}
                                  businessName={businessName}
                                  captions={captions.seoSettings}
                                  titleTagRef={this.titleTagRef}
                                  descriptionRef={this.descriptionRef}
                                  isBusinessInfoInWebsiteSettingsEnabled={
                                    isBusinessInfoInWebsiteSettingsEnabled
                                  }
                                  isSameAsPageName={isSameAsPageName}
                                  validateTitleTag={this.validateTitleTag}
                                  validateDescription={this.validateDescription}
                                  onChangeSameAsPageName={onChangeSameAsPageName}
                                />
                              </TabContent>
                              <TabContent tabId={tabIdentifiers.CUSTOM_CODE}>
                                <CustomCodeSection
                                  captions={captions.customCode}
                                  headCodeDisabled={headCodeDisabled}
                                  footerCodeDisabled={footerCodeDisabled}
                                  activeUpgradePopover={activeUpgradePopover}
                                  headCodeUpgradePopoverRef={this.headCodeUpgradePopoverRef}
                                  headerCodeUpgradePopoverRef={this.headerCodeUpgradePopoverRef}
                                  headerCodeDisabled={headerCodeDisabled}
                                  footerCodeUpgradePopoverRef={this.footerCodeUpgradePopoverRef}
                                  onCodeFieldClick={this.onCodeFieldClick}
                                  onHeadCodeUpgradeClick={onHeadCodeUpgradeClick}
                                  onHeaderCodeUpgradeClick={onHeaderCodeUpgradeClick}
                                  onFooterCodeUpgradeClick={onFooterCodeUpgradeClick}
                                />
                              </TabContent>
                            </TabsContentList>
                          </Tabs>
                        </div>
                        <Panel
                          height={sizes.CONTROL_PANEL_HEIGHT}
                          align="middle"
                          corners="squared"
                          className="ws-panel--shrink ws-page-settings__footer"
                        >
                          <ButtonGroup>
                            <Button
                              className="ws-page-settings__button ws-page-settings__button--apply"
                              size="large"
                              type="default"
                              stretch="block"
                              onClick={() => this.onFormSubmit(handleSubmit)}
                            >
                              {captions.apply}
                            </Button>
                            <Button
                              className="ws-page-settings__button"
                              size="large"
                              type="default"
                              stretch="block"
                              onClick={onCancel}
                            >
                              {captions.cancel}
                            </Button>
                          </ButtonGroup>
                        </Panel>
                      </PanelGroup>
                    </Modal>
                  );
                }}
              </Formik>
            </div>
          );
        }}
      </ViewportConsumer>
    );
  }
}

PageSettings.defaultProps = {
  headCodeDisabled: false,
  headerCodeDisabled: false,
  footerCodeDisabled: false,
  className: '',
  mobile: false,
  styles: {},
  onHeadCodeUpgradeClick: utils.noop,
  onHeaderCodeUpgradeClick: utils.noop,
  onFooterCodeUpgradeClick: utils.noop,
  scrollElementIntoView: () => Promise.resolve(),
  bindSubmitForm: utils.noop,
  onKeyDown: utils.noop,
  isDisplayInNavAvailable: true,
  isHomePagePath: utils.noop,
  onChangePath: Function.prototype,
  enablePasswordProtection: Function.prototype,
  disablePasswordProtection: Function.prototype,
  switchPasswordVisibility: Function.prototype,
  passwordProtection: false,
  passwordProtectedPagesAvailable: false,
  passwordVisibility: false,
  isProtectedPagesPlanFeatureAvailable: false,
  businessName: '',
};

PageSettings.propTypes = {
  headCodeDisabled: PropTypes.bool,
  headerCodeDisabled: PropTypes.bool,
  footerCodeDisabled: PropTypes.bool,
  onHeadCodeUpgradeClick: PropTypes.func,
  onHeaderCodeUpgradeClick: PropTypes.func,
  onFooterCodeUpgradeClick: PropTypes.func,
  mobile: PropTypes.bool,
  className: PropTypes.string,
  styles: PropTypes.shape(),
  captions: PropTypes.shape({
    pageSettings: PropTypes.string,
    apply: PropTypes.string,
    cancel: PropTypes.string,
    prompt: PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      discard: PropTypes.string,
      submit: PropTypes.string.isRequired,
      cancel: PropTypes.string.isRequired,
    }).isRequired,
    general: PropTypes.shape({
      name: PropTypes.string,
      title: PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string,
        error: PropTypes.string,
        emptyError: PropTypes.string,
        homePageError: PropTypes.string,
        maxLengthError: PropTypes.string,
        validationError: PropTypes.string,
        link: PropTypes.shape({
          text: PropTypes.string,
          url: PropTypes.string,
        }),
      }).isRequired,
      displayInNavigation: PropTypes.shape({
        label: PropTypes.string.isRequired,
      }),
      passwordProtectedPages: PropTypes.shape({
        emptyPasswordError: PropTypes.string,
      }),
    }).isRequired,
    seoSettings: PropTypes.shape().isRequired,
    customCode: PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      headCode: PropTypes.shape({
        link: PropTypes.string,
        name: PropTypes.string.isRequired,
        description: PropTypes.string,
        upgradeText: PropTypes.shape({
          description: PropTypes.string,
          upgrade: PropTypes.string,
        }).isRequired,
      }).isRequired,
      headerCode: PropTypes.shape({
        link: PropTypes.string,
        name: PropTypes.string.isRequired,
        description: PropTypes.string,
        upgradeText: PropTypes.shape({
          description: PropTypes.string,
          upgrade: PropTypes.string,
        }).isRequired,
      }).isRequired,
      footerCode: PropTypes.shape({
        link: PropTypes.string,
        name: PropTypes.string.isRequired,
        description: PropTypes.string,
        upgradeText: PropTypes.shape({
          description: PropTypes.string,
          upgrade: PropTypes.string,
        }).isRequired,
      }).isRequired,
      languageCode: PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
      }),
    }).isRequired,
    path: PropTypes.shape({
      name: PropTypes.string.isRequired,
      emptyError: PropTypes.string.isRequired,
      startingSymbolError: PropTypes.string.isRequired,
      validationError: PropTypes.string.isRequired,
      notUniqueError: PropTypes.string.isRequired,
      reservedError: PropTypes.string.isRequired,
      pathsAmountError: PropTypes.string.isRequired,
      homePageError: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  pathPrefix: PropTypes.string.isRequired,
  businessName: PropTypes.string,
  formData: PropTypes.shape({
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    displayInNavigation: PropTypes.bool,
    description: PropTypes.string,
    keywords: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
      })
    ).isRequired,
    htmlLocale: PropTypes.string.isRequired,
    headCode: PropTypes.string,
    headerCode: PropTypes.string,
    footerCode: PropTypes.string,
    path: PropTypes.string.isRequired,
    preventIndexing: PropTypes.bool,
  }).isRequired,
  scrollElementIntoView: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onTabChange: PropTypes.func.isRequired,
  setActiveTabId: PropTypes.func.isRequired,
  bindSubmitForm: PropTypes.func,
  onKeyDown: PropTypes.func,
  isDisplayInNavAvailable: PropTypes.bool,
  pageId: PropTypes.string.isRequired,
  isHomePage: PropTypes.bool.isRequired,
  isHomePagePath: PropTypes.func,
  languageCodeOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  languageCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeTabId: PropTypes.string.isRequired,
  isSameAsPageName: PropTypes.bool.isRequired,
  onChangeSameAsPageName: PropTypes.func.isRequired,
  onChangePath: PropTypes.func,
  formRef: PropTypes.func.isRequired,
  onChangePageName: PropTypes.func.isRequired,
  isBusinessInfoInWebsiteSettingsEnabled: PropTypes.bool.isRequired,
  passwordProtectedPagesAvailable: PropTypes.bool,
  isProtectedPagesPlanFeatureAvailable: PropTypes.bool,
  passwordVisibility: PropTypes.bool,
  passwordProtection: PropTypes.bool,
  enablePasswordProtection: PropTypes.func,
  disablePasswordProtection: PropTypes.func,
  switchPasswordVisibility: PropTypes.func,
};

export default PageSettings;
