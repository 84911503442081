import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Text, Radio, Render, designSystem } from '@yola/ws-ui';
import optionTypes from '../constants/display-options-type';

const { Checkbox } = designSystem;

function SettingsSection(props) {
  const { captions, items } = props;
  const colorSchemes = [];
  const checkboxes = [];
  const radios = [];

  if (!items || !items.length) return null;

  items.forEach((item) => {
    switch (item.type) {
      case optionTypes.COLOR_SCHEME:
        colorSchemes.push(item);
        break;
      case optionTypes.CHECKBOX:
        checkboxes.push(item);
        break;
      case optionTypes.RADIO:
        radios.push(item);
        break;
      default:
    }
  });

  return (
    <React.Fragment>
      <Render if={!!colorSchemes.length}>
        <div className="ws-block-settings-dialog__section">
          <Text type="heading-6">{captions.title}</Text>
          <Text type="annotation" theme="grey">
            {captions.subtitle}
          </Text>
          <div className="ws-block-settings-dialog__colors-container">
            {colorSchemes.map((colorScheme) => {
              const className = classNames('ws-button', 'ws-color-switch', {
                'ws-color-switch--active': colorScheme.isEnabled,
              });

              return (
                <button
                  type="button"
                  key={colorScheme.id}
                  onClick={(e) => colorScheme.onClick(e, colorScheme)}
                  className={className}
                  style={{ background: colorScheme.color }}
                />
              );
            })}
          </div>
        </div>
      </Render>
      <Render if={!!checkboxes.length}>
        <div className="ws-block-settings-dialog__section">
          <Text type="heading-6">{captions.title}</Text>
          <Text type="annotation" theme="grey">
            {captions.subtitle}
          </Text>
          {checkboxes.map((checkbox) => (
            <div className="ws-block-settings-dialog__checkbox-option">
              <Checkbox
                key={`${checkbox.id}-${checkbox.attribute}`}
                id={checkbox.id}
                value={checkbox.id}
                label={checkbox.label}
                checked={checkbox.isEnabled}
                onChange={() => checkbox.onClick(!checkbox.isEnabled, checkbox)}
              />
            </div>
          ))}
        </div>
      </Render>
      <Render if={!!radios.length}>
        <div className="ws-block-settings-dialog__section">
          {radios.map((radio) => (
            <React.Fragment key={radio.id}>
              <Text type="heading-6">{radio.label}</Text>
              <Text type="annotation" theme="grey">
                {radio.subtitle}
              </Text>
              {radio.options.map((option) => (
                <Radio
                  key={option.value}
                  id={option.value}
                  name="radio-group-name"
                  checked={radio.value === option.value}
                  onChange={(e) => radio.onClick(e, radio, option)}
                  value={option.value}
                >
                  <Text type="heading-6">
                    {/* eslint-disable-next-line yola/jsx-a11y/label-has-for */}
                    <label htmlFor={option.value}>{option.label}</label>
                  </Text>
                </Radio>
              ))}
            </React.Fragment>
          ))}
        </div>
      </Render>
    </React.Fragment>
  );
}

SettingsSection.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()),
  captions: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
  }).isRequired,
};

SettingsSection.defaultProps = {
  items: null,
};

export default SettingsSection;
