import React from 'react';
import { view, i18next } from '@yola/ws-sdk';
import { designSystem } from '@yola/ws-ui';
import Trigger from 'src/js/modules/common/components/trigger';
import customUI from 'src/js/modules/custom-ui';
import focalPoint from 'src/js/modules/focal-point';
import isPlaceholder from '../helpers/is-placeholder';
import getOverlappedMediaContainerByText from '../helpers/get-overlapped-media-container-by-text';

const { isImageContainer } = focalPoint.helpers;
const { Icon } = designSystem;

const triggerId = 'crop-control';

const cropControl = {
  id: triggerId,
  trigger: (
    <Trigger id={triggerId}>
      <Icon glyph="crop" />
    </Trigger>
  ),
  get title() {
    return i18next.t('Crop');
  },
  get tooltip() {
    return i18next.t('Crop');
  },
  priority: 50,
  onTriggerClick(elementId) {
    const liveElement = view.accessors.getLiveElement(elementId);

    if (isImageContainer(liveElement)) {
      customUI.operations.show(customUI.customUiTypes.EDIT_INLINE_IMAGE_FOCAL_POINT, {
        elementId,
      });

      return;
    }

    const overlappedMediaContainer = getOverlappedMediaContainerByText(liveElement);

    if (overlappedMediaContainer) {
      customUI.operations.show(customUI.customUiTypes.EDIT_INLINE_IMAGE_FOCAL_POINT, {
        elementId: view.accessors.getLiveElementId(overlappedMediaContainer),
      });
    }
  },
  matches(liveElement) {
    if (isPlaceholder(liveElement)) {
      return false;
    }

    if (isImageContainer(liveElement)) {
      return Boolean(liveElement.querySelector('img'));
    }

    const overlappedMediaContainer = getOverlappedMediaContainerByText(liveElement);

    if (overlappedMediaContainer) {
      return Boolean(overlappedMediaContainer.querySelector('img'));
    }

    return false;
  },
  highlightElement: false,
};

export default cropControl;
