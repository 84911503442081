import constants from '../constants';

const { MIN_FONT_SIZE, MAX_FONT_SIZE } = constants.common;

const normalizeFontSizeValue = (inputValue, currentValue) => {
  const newFontSize = Number(inputValue) || currentValue;
  return Math.round(Math.min(Math.max(newFontSize, MIN_FONT_SIZE), MAX_FONT_SIZE));
};

export default normalizeFontSizeValue;
