import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import { constants as subscriptionManagerConstants } from '@yola/subscription-manager-js';
import upsell from '../../upsell';

const { PresetContainer, Tag } = designSystem;

const {
  hostingPackageFeatureNames: { PREMIUM_BUTTON_PRESETS },
} = subscriptionManagerConstants;

function ButtonPreset(props) {
  const { id, title, contentHTML, isSelected, isPremium, onClick } = props;

  const handleClick = () => onClick(id, isPremium);
  const arePremiumButtonPresetsAvailable =
    upsell.verifiers.isFeatureAvailable(PREMIUM_BUTTON_PRESETS);

  return (
    <div className="ws-button-preset">
      <PresetContainer title={title} isSelected={isSelected} onClick={handleClick}>
        <div
          className="ws-button-preset__content"
          /* eslint-disable yola/react/no-danger */
          dangerouslySetInnerHTML={{ __html: contentHTML }}
        />
        {isPremium && !arePremiumButtonPresetsAvailable && (
          <div className="ws-button-preset__mark">
            <Tag iconGlyph="star" size="small" appearance="cta" />
          </div>
        )}
      </PresetContainer>
    </div>
  );
}

ButtonPreset.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  contentHTML: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  isPremium: PropTypes.bool,
};

ButtonPreset.defaultProps = {
  isSelected: false,
  isPremium: false,
};

export default ButtonPreset;
