import { site, template } from '@yola/ws-sdk';
import detectDeviceOrientation from 'src/js/modules/utils/detect-device-orientation';
import segment from 'src/js/modules/analytics/segment';

const {
  track,
  constants: { events },
} = segment;

const trackApiRateExceededEvent = () => {
  track(events.ERROR_CAUGHT, {
    errorMessage: 'The API rate for Unsplash integration is exceeded',
    siteId: site.accessors.getSiteId(),
    templateBuildSlug: template.accessors.getBuildSlug(),
    deviceOrientation: detectDeviceOrientation(),
  });
};

export default trackApiRateExceededEvent;
