import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  i18next,
  blocks,
  hdrm,
  textFormatting,
  dialogs,
  view,
  site,
  transactions,
} from '@yola/ws-sdk';
import { constants as subscriptionConstants } from '@yola/subscription-manager-js';
import bowser from 'yola-bowser';
import websiteDesignTabIdentifiers from 'src/js/modules/website-design/constant/tab-identifiers';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import switchBlockLayout from 'src/js/modules/blocks/helpers/switch-block-layout';
import getBlockIdRulesMap from 'src/js/modules/blocks/helpers/get-block-id-rules-map';
import scroller from 'src/js/modules/scroller';
import upsell from 'src/js/modules/upsell';
import segment from '../../analytics/segment';
import BlockLayoutDialog from '../components/block-layout-dialog';
import BlockLayoutDialogDescription from '../components/block-layout-dialog-description';
import getBlockItemsForRendering from '../helpers/layout-switching/get-block-items-for-rendering';
import layoutBlockItemPreprocess from '../helpers/layout-switching/block-layout-preprocess';
import cancelBlockSettingsChanges from '../helpers/cancel-block-settings-changes';
import saveBlockSettingsChanges from '../helpers/save-block-settings-changes';
import trackers from '../trackers';
import dialogCallSourceIds from '../../website-design/constant/dialog-call-source-ids';
import isFeatureAvailable from '../../upsell/verifiers/is-feature-available';
import sourceIds from '../constants/source-ids';

const {
  hostingPackageFeatureNames: { PREMIUM_BLOCKS },
} = subscriptionConstants;
const {
  constants: { events },
  trackers: { trackEvent },
} = segment;

const showSwitchTemplateTab = (modalProps = {}) => {
  dialogs.operations.show(dialogTypes.WEBSITE_DESIGN, {
    onDialogCancel: dialogs.operations.hide,
    onDialogMainAction: dialogs.operations.hide,
    preserveSharedData: false,
    activeTabId: websiteDesignTabIdentifiers.TEMPLATE_SWITCHING,
    sourceId: dialogCallSourceIds.SWITCH_BLOCK_LAYOUT,
    ...modalProps,
  });
};

const getBlockLayoutsDialogCaptions = () => ({
  title: i18next.t('Block layout'),
  buttons: {
    submit: i18next.t('Submit'),
    cancel: i18next.t('Cancel'),
  },
});

class BlockLayoutDialogContainer extends React.Component {
  constructor(props) {
    super(props);
    this.sharedData = props.getSharedData();

    const {
      offsetX,
      offsetY,
      refId,
      destinationBlockNode,
      variationId,
      isDirtyOptions,
      selectedLayoutVariationId,
      initialSelectedLayoutVariationId,
      blockNode,
      blockId,
      optionalChildrenState,
    } = this.sharedData;

    const stylesheet = textFormatting.helpers.getCustomTextColorsStylesheet();

    const block = blocks.accessors.getBlockByElement(blockNode);
    const blockElement = hdrm.accessors.getLiveElement(refId);
    const usedTextColorOptions =
      textFormatting.helpers.getElementCustomColorVariables(blockElement);
    const blockIdRulesMap = getBlockIdRulesMap(stylesheet, blockElement.id);
    const blockVariationWithCategories = block.variations?.find(
      (variation) => variationId === variation.id && variation.category
    );
    const category = blockVariationWithCategories?.category || block.category;

    this.usedCustomColorOptions =
      Boolean(usedTextColorOptions.length) && blockIdRulesMap && blockIdRulesMap.size > 0;

    this.category = category;

    this.state = {
      offsetX,
      offsetY,
      blockNode,
      blockId,
      variationId,
      selectedLayoutVariationId,
      initialSelectedLayoutVariationId:
        initialSelectedLayoutVariationId || selectedLayoutVariationId,
      isDirtyOptions,
      optionalChildrenState,
      destinationBlockNode,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.itemPreProcessFunction = this.itemPreProcessFunction.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onBlockSelected = this.onBlockSelected.bind(this);
    this.onSwitchTemplate = this.onSwitchTemplate.bind(this);
    this.scrollToBlock = this.scrollToBlock.bind(this);
    this.switchBlock = this.switchBlock.bind(this);

    this.captions = getBlockLayoutsDialogCaptions();
  }

  componentDidMount() {
    const { blockId, variationId: blockVariationId } = this.state;

    trackers.trackBlockLayoutDialogDisplayed({
      blockId,
      blockVariationId,
      premiumBlocksAvailable: isFeatureAvailable(PREMIUM_BLOCKS),
    });
  }

  onBlockSelected(newBlock) {
    if (newBlock.isPremium) {
      const onUpgradeResolve = () => {
        this.switchBlock(newBlock);
      };
      const onUpgradeReject = () => {
        const { category: categoryId, id: blockId, variationId: blockVariationId } = newBlock;

        transactions.helpers.releaseTransactionSession(transactions.transactionTypes.LIVE_DOCUMENT);
        saveBlockSettingsChanges(this.commonOptions);
        trackEvent(events.ADD_PREMIUM_BLOCK_CLICKED, {
          categoryId,
          blockId,
          blockVariationId,
          sourceId: sourceIds.LAYOUT_SWITCHING,
        });
      };

      upsell.operations
        .requestFeatureAvailability(PREMIUM_BLOCKS)
        .then(onUpgradeResolve, onUpgradeReject);
      return;
    }

    this.switchBlock(newBlock);
  }

  onDragEnd(_, { x, y }) {
    this.setState({ offsetX: x, offsetY: y });
  }

  onSwitchTemplate() {
    const { blockNode, selectedLayoutVariationId, initialSelectedLayoutVariationId } = this.state;
    const { resolveSharedData } = this.props;

    const { originalBlock, optionalChildren, isDirtyOptions } = this.sharedData;

    trackers.trackBlockLayoutSwitchTemplateButtonClicked();

    const isOptionalChildrenChanged = optionalChildren.find(
      ({ isEnabled, isInitialEnabled }) => isEnabled !== isInitialEnabled
    );

    if (
      !isOptionalChildrenChanged &&
      !isDirtyOptions &&
      blockNode.id === originalBlock.blockNode.id
    ) {
      showSwitchTemplateTab();
      return;
    }

    resolveSharedData({
      blockNode,
      selectedLayoutVariationId,
      initialSelectedLayoutVariationId,
    });

    dialogs.operations.show(dialogTypes.UNSAVED_CHANGES_PROMPT, {
      onDialogCancel: dialogs.operations.hide,
      onDialogMainAction: dialogs.operations.hide,
      onSubmit: () => {
        transactions.helpers.releaseTransactionSession(transactions.transactionTypes.LIVE_DOCUMENT);
        saveBlockSettingsChanges(this.commonOptions);
        showSwitchTemplateTab();
      },
      onDiscard: () => {
        const cancelProps = {
          ...this.commonOptions,
          blockId: originalBlock.blockId,
          variationId: originalBlock.blockVariationId,
        };
        transactions.helpers.releaseTransactionSession(transactions.transactionTypes.LIVE_DOCUMENT);
        cancelBlockSettingsChanges(cancelProps);
        showSwitchTemplateTab();
      },
      onCancel: () => {
        dialogs.operations.show(dialogTypes.BLOCK_LAYOUT_DIALOG);
      },
    });
  }

  get commonOptions() {
    const { destinationBlockNode, blockNode, variationId } = this.state;
    const {
      hideDialog,
      forceReloadView,
      onDialogCancel,
      onDialogMainAction,
      bulkViewActions,
      setCleanUrlsEnabled,
    } = this.props;

    const {
      colorSchemes,
      originalBlock,
      isOnlineStoreBlock,
      refId,
      optionalChildren,
      blockId,
      initialElement,
      displayOptions,
      backgroundOptions,
      cachedBackgroundOptions,
    } = this.sharedData;

    return {
      destinationBlockNode,
      isOnlineStoreBlock,
      refId,
      usedCustomColorOptions: this.usedCustomColorOptions,
      hideDialog,
      forceReloadView,
      onDialogCancel,
      colorSchemes,
      variationId,
      intermediateBlockId: blockId,
      onDialogMainAction,
      optionalChildren,
      displayOptions,
      backgroundOptions,
      cachedBackgroundOptions,
      bulkViewActions,
      originalBlock,
      blockNode,
      blockId,
      setCleanUrlsEnabled,
      initialElement,
    };
  }

  switchBlock(newBlock) {
    const {
      blockNode,
      variationId,
      selectedLayoutVariationId,
      isDirtyOptions,
      optionalChildrenState,
      destinationBlockNode,
    } = this.state;

    const {
      displayOptions,
      selectedPreviewNode,
      isDirtyChildren,
      refId,
      blockElementId,
      optionalChildren,
      sortBlockVariationId,
      originalBlock,
      compiledNodesCache,
      backgroundOptions,
      cachedBackgroundOptions,
    } = this.sharedData;

    const optionsToSwitch = {
      blockNode,
      optionalChildren,
      sortBlockVariationId,
      selectedLayoutVariationId,
      displayOptions,
      backgroundOptions,
      cachedBackgroundOptions,
      selectedPreviewNode,
      isDirtyChildren,
      refId,
      blockElementId,
      isDirtyOptions,
      originalBlockNode: originalBlock.blockNode,
      originalStaticBlockNode: originalBlock.staticBlockNode,
      originalVariationId: originalBlock.blockVariationId,
      blockOriginSurface: originalBlock.blockSurface,
      prevVariationId: variationId,
      currentBlockNode: destinationBlockNode,
      compiledNodesCache,
      optionalChildrenState,
      usedCustomColorOptions: this.usedCustomColorOptions,
    };

    const {
      newBlockNode,
      newBlockId,
      newVariationId,
      newSelectedLayoutVariationId,
      newOptionalChildrenState,
    } = switchBlockLayout(newBlock, optionsToSwitch);

    const newDestinationBlockNode = newBlockNode.cloneNode(true);

    trackers.trackBlockLayoutOptionClicked({
      blockId: newBlockId,
      blockVariationId: newVariationId,
    });

    this.setState(
      {
        optionalChildrenState: newOptionalChildrenState,
        destinationBlockNode: newDestinationBlockNode,
        blockNode: newBlockNode,
        blockId: newBlockId,
        variationId: newVariationId,
        selectedLayoutVariationId: newSelectedLayoutVariationId,
      },
      this.scrollToBlock
    );
  }

  itemPreProcessFunction(blockItem) {
    const { selectedLayoutVariationId, optionalChildrenState } = this.state;
    const {
      originalBlock,
      sortBlockVariationId,
      refId,
      displayOptions,
      compiledNodesCache,
      selectedPreviewNode,
      backgroundOptions,
      cachedBackgroundOptions,
    } = this.sharedData;

    return layoutBlockItemPreprocess(blockItem, {
      sortBlockVariationId,
      selectedLayoutVariationId,
      displayOptions,
      backgroundOptions,
      cachedBackgroundOptions,
      refId,
      compiledNodesCache,
      selectedPreviewNode,
      originalStaticBlockNode: originalBlock.staticBlockNode,
      originalBlockNode: originalBlock.blockNode,
      originalVariationId: originalBlock.blockVariationId,
      optionalChildrenState,
    });
  }

  handleCancel(itemsToRender) {
    const { resolveSharedData } = this.props;
    const {
      offsetX,
      offsetY,
      blockNode: stateBlockNode,
      variationId,
      initialSelectedLayoutVariationId,
    } = this.state;

    const {
      selectedLayoutVariationId,
      isDirtyOptions,
      isDirtyChildren,
      sortBlockVariationId,
      displayOptions,
      optionalChildren,
      refId,
      blockElementId,
      compiledNodesCache,
      selectedPreviewNode,
      destinationBlockNode,
      optionalChildrenState,
      originalBlock,
      backgroundOptions,
      cachedBackgroundOptions,
    } = this.sharedData;

    const block = itemsToRender.find((el) => el.variationId === initialSelectedLayoutVariationId);

    const optionsToSwitch = {
      blockNode: stateBlockNode,
      optionalChildren,
      sortBlockVariationId,
      selectedLayoutVariationId,
      displayOptions,
      backgroundOptions,
      cachedBackgroundOptions,
      selectedPreviewNode,
      isDirtyChildren,
      refId,
      blockElementId,
      isDirtyOptions,
      originalBlockNode: originalBlock.blockNode,
      originalStaticBlockNode: originalBlock.staticBlockNode,
      blockOriginSurface: originalBlock.blockSurface,
      prevVariationId: variationId,
      currentBlockNode: destinationBlockNode,
      compiledNodesCache,
      optionalChildrenState,
      usedCustomColorOptions: this.usedCustomColorOptions,
      originalVariationId: originalBlock.blockVariationId,
    };

    const {
      newBlockNode,
      newBlockId,
      newVariationId,
      newSelectedLayoutVariationId,
      newOptionalChildrenState,
    } = switchBlockLayout(block, optionsToSwitch);

    resolveSharedData({
      offsetX,
      offsetY,
      optionalChildren,
      optionalChildrenState: newOptionalChildrenState,
      destinationBlockNode: newBlockNode.cloneNode(true),
      blockNode: newBlockNode,
      blockId: newBlockId,
      variationId: newVariationId,
      selectedLayoutVariationId: newSelectedLayoutVariationId,
    });

    this.scrollToBlock(newBlockNode, () => {
      trackers.trackBlockLayoutDialogCanceled({
        blockId: newBlockId,
        blockVariationId: newVariationId,
      });

      dialogs.operations.show(dialogTypes.BLOCK_SETTINGS, {
        blockId: newBlockId,
      });
    });
  }

  handleSubmit({ isClickOnOverlay } = {}) {
    const {
      blockNode,
      variationId,
      selectedLayoutVariationId,
      initialSelectedLayoutVariationId,
      isDirtyOptions,
      optionalChildrenState,
      destinationBlockNode,
      blockId,
      offsetX,
      offsetY,
    } = this.state;

    const { resolveSharedData } = this.props;

    resolveSharedData({
      offsetX,
      offsetY,
      destinationBlockNode,
      variationId,
      isDirtyOptions,
      selectedLayoutVariationId,
      blockNode,
      blockId,
      optionalChildrenState,
    });

    trackers.trackBlockLayoutDialogSubmitted({
      isOverlayClicked: isClickOnOverlay,
      blockId,
      blockVariationId: variationId,
      newBlockLayoutSelected: selectedLayoutVariationId !== initialSelectedLayoutVariationId,
    });

    dialogs.operations.show(dialogTypes.BLOCK_SETTINGS);
  }

  scrollToBlock(node, callback = () => {}) {
    this.timer = setTimeout(() => {
      const { blockNode: stateBlockNode } = this.state;

      scroller.helpers
        .scrollToElement(node || stateBlockNode, { includeOffset: false })
        .then(callback);
    }, 200);
  }

  render() {
    const { selectedLayoutVariationId, isDirtyOptions, offsetX, offsetY, variationId } = this.state;

    const {
      optionalChildren,
      originalBlock,
      blocksPreviewStylesheet,
      blockId,
      itemsWithDependencies,
      variationId: initVariationId,
    } = this.sharedData;

    const itemsToRender = getBlockItemsForRendering({
      blockId,
      selectedLayoutVariationId,
      category: this.category,
      optionalChildren,
      isDirtyOptions,
      originalBlock,
      itemsWithDependencies,
      variationId,
    });

    const withoutInternalBlocks = itemsToRender.filter(
      (item) => !item.internal || item.variationId === initVariationId
    );

    const filteredItemsToRender = withoutInternalBlocks.filter(
      (item) => item.layoutSwitching !== false
    );

    return (
      <BlockLayoutDialog
        captions={this.captions}
        fullscreen={bowser.mobile}
        blockLayoutDescription={() => (
          <BlockLayoutDialogDescription handleLinkClick={this.onSwitchTemplate} />
        )}
        offsetX={offsetX}
        offsetY={offsetY}
        onSubmit={this.handleSubmit}
        onCancel={() => this.handleCancel(filteredItemsToRender)}
        onDragEnd={this.onDragEnd}
        blockItems={filteredItemsToRender}
        onBlockSelected={this.onBlockSelected}
        onOverlayClick={() => this.handleSubmit({ isClickOnOverlay: true })}
        selectedLayoutVariationId={selectedLayoutVariationId}
        itemPreProcessFunction={this.itemPreProcessFunction}
        blocksPreviewStylesheet={blocksPreviewStylesheet}
      />
    );
  }
}

BlockLayoutDialogContainer.propTypes = {
  resolveSharedData: PropTypes.func.isRequired,
  getSharedData: PropTypes.func.isRequired,
  hideDialog: PropTypes.func.isRequired,
  forceReloadView: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
  setCleanUrlsEnabled: PropTypes.func.isRequired,
  bulkViewActions: PropTypes.func.isRequired,
  onDialogMainAction: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  hideDialog: dialogs.actions.hide,
  forceReloadView: view.actions.forceReloadView,
  bulkViewActions: view.actions.bulkViewActions,
  setCleanUrlsEnabled: site.actions.setCleanUrlsEnabled,
};

export default connect(null, mapDispatchToProps)(BlockLayoutDialogContainer);
