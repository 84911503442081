import getFieldSelectors from './get-field-selectors';
import getFieldTypes from './get-field-types';
import constants from '../constants';

const { common, attributes, timeFormats, numberOfFilesAttachment } = constants;
const { ALL_FILE_TYPES_VALUE } = common;

const parseItemsFromDOM = (formContainer) => {
  const items = [];
  const types = getFieldTypes();
  const { checkbox, radio, dropdown, time, attachfile } = types;
  const {
    itemGroup,
    itemLabel,
    itemTextarea,
    itemInput,
    itemDropdown,
    itemTitle,
    itemGrouped,
    itemTime,
    itemFile,
    itemFileLabelText,
  } = getFieldSelectors();
  const fieldsSelector = Object.values(types).map((type) => `${itemGroup}[type=${type}]`);

  formContainer.querySelectorAll(fieldsSelector).forEach((item, i) => {
    const type = item.getAttribute('type');

    const getParseItemsFromDOM = () => {
      const input = item.querySelector(itemInput);
      const textarea = item.querySelector(itemTextarea);

      const textField = input || textarea;
      if (!textField) return {};
      const label = item.querySelector(itemLabel);

      return {
        label: label.innerText,
        order: String(i + 1),
        name: textField.getAttribute('name'),
        type,
        placeholder: textField.getAttribute('placeholder'),
        id: textField.getAttribute('id'),
        required: textField.hasAttribute('required'),
      };
    };

    switch (type) {
      case checkbox: {
        const groupTitle = item.querySelector(itemTitle);
        const groupItems = Array.from(item.querySelectorAll(itemGrouped));

        const options = groupItems.map((groupItem) => groupItem.querySelector(itemLabel).innerText);

        const isRequired = groupItems.every((groupItem) =>
          groupItem.querySelector(itemInput).hasAttribute('required')
        );

        items.push({
          label: groupTitle.innerText,
          order: String(i + 1),
          type,
          options,
          id: item.getAttribute('id'),
          required: isRequired,
        });

        break;
      }
      case radio: {
        const groupTitle = item.querySelector(itemTitle);
        const groupItems = Array.from(item.querySelectorAll(itemGrouped));

        const options = groupItems.map((groupItem) => groupItem.querySelector(itemLabel).innerText);

        const isRequired = groupItems.every((groupItem) =>
          groupItem.querySelector(itemInput).hasAttribute('required')
        );

        items.push({
          label: groupTitle.innerText,
          order: String(i + 1),
          type,
          options,
          id: item.getAttribute('id'),
          required: isRequired,
        });

        break;
      }
      case dropdown: {
        const labelElement = item.querySelector(itemLabel);
        const selectElement = item.querySelector(itemDropdown);

        items.push({
          type,
          label: labelElement.textContent,
          order: String(i + 1),
          id: selectElement.id,
          required: selectElement.required,
          name: selectElement.name,
          options: [...selectElement.options].map((option) => option.textContent),
        });
        break;
      }
      case time: {
        const timeElement = item.querySelector(itemTime);
        const timeFormat = timeElement.hasAttribute(attributes.TIME_24HR)
          ? timeFormats['24_HOUR']
          : timeFormats['12_HOUR'];
        const parseItems = getParseItemsFromDOM();

        if (!Object.keys(parseItems).length) return;

        items.push({
          ...parseItems,
          timeFormat,
        });
        break;
      }
      case attachfile: {
        const filePicker = item.querySelector(itemFile);
        const labelElement = item.querySelector(itemLabel);
        const input = item.querySelector(itemInput);
        const btnText = item.querySelector(itemFileLabelText);

        items.push({
          type,
          label: labelElement.textContent,
          order: String(i + 1),
          id: input.id,
          required: input.required,
          name: input.name,
          buttonText: btnText.textContent,
          numberOfFiles: input?.multiple
            ? numberOfFilesAttachment.MULTIPLE
            : numberOfFilesAttachment.SINGLE,
          fileType: filePicker.getAttribute('file-type') || ALL_FILE_TYPES_VALUE,
        });
        break;
      }
      default: {
        const parseItems = getParseItemsFromDOM();
        if (!Object.keys(parseItems).length) return;
        items.push(parseItems);
      }
    }
  });

  return items;
};

export default parseItemsFromDOM;
