import React from 'react';
import { extensions, i18next } from '@yola/ws-sdk';
import TextIndentTrigger from '../components/text-indent-trigger';
import TextIndentActionTrigger from '../components/text-indent-action-trigger';
import constants from '../constants';
import typedElementsMatches from '../helpers/typed-elements-matches';
import isMultipurpose from '../../../common/helpers/is-multipurpose';

const {
  common: { SLUG },
  textIndentTriggerIds: { TEXT_INDENT_TRIGGER_ID, TEXT_OUTDENT_TRIGGER_ID },
} = constants;
const { controlTypes } = extensions;
const triggerId = 'text-indent';

const TEXT_INDENT_OPTIONS = [
  {
    id: TEXT_INDENT_TRIGGER_ID,
    type: 'increase',
    getTooltip() {
      return i18next.t('Indent');
    },
  },
  {
    id: TEXT_OUTDENT_TRIGGER_ID,
    type: 'decrease',
    getTooltip() {
      return i18next.t('Outdent');
    },
  },
];

const elementMatches = (element) => {
  const { textContainer, textIndentQuerySelector, textIndentRestrictedParentsSelector } =
    extensions.accessors.getExtensionSettings(SLUG);

  const typedElements = typedElementsMatches();

  if (
    !isMultipurpose() ||
    !element.matches(textContainer) ||
    (textIndentRestrictedParentsSelector && element.closest(textIndentRestrictedParentsSelector)) ||
    (textIndentQuerySelector && !typedElements.every((el) => el.matches(textIndentQuerySelector)))
  ) {
    return false;
  }

  return Boolean(typedElements.length);
};

const textIndentsControl = {
  id: triggerId,
  trigger: <TextIndentTrigger triggerId={triggerId} />,
  header: TEXT_INDENT_OPTIONS.map(({ id, type, getTooltip }) => (
    <TextIndentActionTrigger key={id} triggerId={id} type={type} getTooltip={getTooltip} />
  )),
  get tooltip() {
    return i18next.t('Text indent');
  },
  priority: 60,
  for: [controlTypes.TEXT, controlTypes.COLLAPSED_TEXT],
  matches: elementMatches,
  highlightElement: false,
  closeIcon: 'submit',
};

export default textIndentsControl;
