import { SET, REMOVE } from 'src/js/modules/blocks/constants/attribute-operations';

const attributeValuesList = {
  getAddOperation(element, attributeName, attributeValue) {
    const currentAttributeValue = element.getAttribute(attributeName) || '';
    const values = currentAttributeValue.split(' ');

    if (!values.includes(attributeValue)) {
      values.push(attributeValue);
    }

    const result = values.join(' ').trim();

    return { operation: SET, name: attributeName, value: result };
  },

  getRemoveOperation(element, attributeName, attributeValue, options = { removeEmpty: false }) {
    const currentAttributeValue = element.getAttribute(attributeName) || '';
    const values = currentAttributeValue.split(' ');

    const filteredValues = values.filter((value) => value !== attributeValue);
    const result = filteredValues.join(' ').trim();

    if (!result && options.removeEmpty) {
      return { operation: REMOVE, name: attributeName };
    }

    return { operation: SET, name: attributeName, value: result };
  },

  getChangeOperations(element, toRemove, toAdd, options = { removeEmpty: false }) {
    const { name: nameToRemove, value: valueToRemove } = toRemove;
    const { name: nameToAdd, value: valueToAdd } = toAdd;

    if (nameToRemove === nameToAdd) {
      const currentAttributeValue = element.getAttribute(nameToRemove) || '';
      const values = currentAttributeValue.split(' ');

      const filteredValues = values.filter((value) => value !== valueToRemove);

      if (!filteredValues.includes(valueToAdd)) {
        filteredValues.push(valueToAdd);
      }

      const result = filteredValues.join(' ').trim();

      return [{ operation: SET, name: nameToAdd, value: result }];
    }

    const removeOperation = this.getRemoveOperation(element, nameToRemove, valueToRemove, options);
    const addOperation = this.getAddOperation(element, nameToAdd, valueToAdd);

    return [removeOperation, addOperation];
  },

  contains(element, attributeName, attributeValue) {
    if (!element.hasAttribute(attributeName)) {
      return false;
    }

    const targetAttributeValue = element.getAttribute(attributeName);
    const values = targetAttributeValue.split(' ');

    return values.some((value) => value === attributeValue);
  },
};

export default attributeValuesList;
