import store from 'src/js/store';
import { integration } from '@yola/ws-sdk';

const isOnlineStoreEnabled = () => {
  const state = store.getState();
  const settings = integration.selectors.getSettings(state);

  return settings.onlinestore;
};

export default isOnlineStoreEnabled;
