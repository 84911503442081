import { view } from '@yola/ws-sdk';
import positionTypes from '../constants/position-types';

const { VERTICAL, HORIZONTAL } = view.elementFlowTypes;

const getOuterAdjacentPosition = (element, x, y) => {
  if (element === null) return null;

  const flowType = view.helpers.getElementFlowType(element);
  const { top, bottom, left, right } = element.getBoundingClientRect();
  const centerX = (left + right) / 2;
  const centerY = (top + bottom) / 2;

  if ((flowType === HORIZONTAL && x < centerX) || (flowType === VERTICAL && y < centerY)) {
    return positionTypes.BEFORE_BEGIN;
  }

  return positionTypes.AFTER_END;
};

export default getOuterAdjacentPosition;
