import { customization, site } from '@yola/ws-sdk';
import store from 'src/js/store';
import { snakeCaseObjectToCamelCase } from '../../utils/convert-case';
import getInitialGeneralColor from './get-initial-general-color';
import { accentColorsKeyMap } from '../constant/colors-maps';

export default async function updateCustomColorPalettes() {
  const state = store.getState();
  const customColorPalettes = customization.selectors.getCustomColorPalettes(state);
  const colorPaletteConfigUrl = customization.selectors.getColorPaletteConfigAssetUrl(state);
  const colorPaletteConfigResponse = await site.helpers.fetchAsset(colorPaletteConfigUrl);
  const colorPaletteConfig = snakeCaseObjectToCamelCase(colorPaletteConfigResponse.data);

  function updatePalette(initialPalette) {
    return new Promise((resolve) => {
      const config = { ...colorPaletteConfig };
      const palette = { ...initialPalette };
      const worker = new Worker(
        new URL('../workers/generate-color-palette.worker.js', import.meta.url)
      );
      worker.addEventListener('message', (event) => {
        worker.terminate();
        store.dispatch(customization.actions.updateCustomColorPalette(event.data.palette));
        resolve();
      });

      Object.values(accentColorsKeyMap).forEach((colorKey) => {
        if (palette.colors[colorKey]) return;
        palette.colors[colorKey] = getInitialGeneralColor(colorKey, palette.colors);
      });

      worker.postMessage({
        config,
        colors: palette.colors,
        advancedColors: palette.advancedColors || {},
        id: palette.id,
        parentId: palette.parentId,
      });
    });
  }

  return Promise.all(customColorPalettes.map(updatePalette));
}
