import React from 'react';
import PropTypes from 'prop-types';

function ViewOverlay(props) {
  const { children, width, height } = props;
  const styles = {
    width,
    height,
  };

  return (
    <div className="ws-view-overlay" style={styles}>
      {children}
    </div>
  );
}

ViewOverlay.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
};

ViewOverlay.defaultProps = {
  width: '100%',
  height: '100%',
};

export default ViewOverlay;
