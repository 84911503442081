import get from 'lodash.get';
import { constants } from '@yola/subscription-manager-js';

const {
  hostingPackageFeatureNames: { UNLIMITED_SITE_BACKUPS },
} = constants;

const siteBackupsLimit = ({ targetPackage }) => ({
  available: get(targetPackage, ['features', UNLIMITED_SITE_BACKUPS], false),
});

export default siteBackupsLimit;
