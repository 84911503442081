import { anodum } from '@yola/ws-sdk';

const getPrevSiblingPath = (element) => {
  const elementPath = anodum.getTreePathOfNode(element);

  elementPath[elementPath.length - 1] -= 1;

  return elementPath;
};

export default getPrevSiblingPath;
