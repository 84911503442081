import { site, template } from '@yola/ws-sdk';
import segment from '../../../../analytics/segment';

const ICON_ID = 'social-media-icons';
const ICON_ADJUSTED = 'group-icon';

const trackIconSizeResized = ({ blockId, blockVariationId, oldSize, newSize }) => {
  const { track, constants } = segment;

  track(constants.events.ICON_SIZE_RESIZED, {
    siteId: site.accessors.getSiteId(),
    templateBuildSlug: template.accessors.getBuildSlug(),
    blockId,
    blockVariationId,
    oldSize,
    newSize,
    iconAdjusted: ICON_ADJUSTED,
    iconId: ICON_ID,
  });
};

export default trackIconSizeResized;
