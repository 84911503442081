import get from 'lodash.get';

// Due to initial implementation of 'add widget' approach is matched with existing one for 'add block',
// this helper is just copied from `blocks` module as is. It isn't re-used for a better maintainability
// if we want to change this approach for widgets or need to tweak it somehow specifically.

const getAcceptableVariations = (items, displayIn = null) => {
  if (!displayIn) return items;

  return items.filter((item) => {
    const displayOptions = get(item, 'properties.displayIn', null);
    if (!displayOptions || !Array.isArray(displayOptions)) return true;

    return !!displayOptions.includes(displayIn);
  });
};

export default getAcceptableVariations;
