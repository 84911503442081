import { COLORS_ATTRIBUTE } from '../constants/common';
import defaultColors from '../constants/default-colors';

function injectBrandingColors(brandingColors) {
  const currentStyleElement = document.querySelector(`[${COLORS_ATTRIBUTE}]`);

  if (currentStyleElement) {
    return;
  }

  const colors = {
    ...defaultColors,
    ...brandingColors,
  };

  const styleElement = document.createElement('style');

  styleElement.setAttribute(COLORS_ATTRIBUTE, true);

  styleElement.innerHTML = `
    :root {
      --primary-600: ${colors.primary600};
      --primary-700: ${colors.primary700};
      --primary-800: ${colors.primary800};
      --cta-600: ${colors.cta600};
      --cta-700: ${colors.cta700};
      --cta-800: ${colors.cta800};
      --info-600: ${colors.info600};
      --info-700: ${colors.info700};
      --info-800: ${colors.info800};
      --success-600: ${colors.success600};
      --success-700: ${colors.success700};
      --success-800: ${colors.success800};
      --warning-600: ${colors.warning600};
      --warning-700: ${colors.warning700};
      --warning-800: ${colors.warning800};
      --danger-600: ${colors.danger600};
      --danger-700: ${colors.danger700};
      --danger-800: ${colors.danger800};
    }
  `;

  document.head.append(styleElement);
}

export default injectBrandingColors;
