import { dialogs, i18next } from '@yola/ws-sdk';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import constants from '../../constants';

const { IMAGE_LOADING_DIALOG } = dialogTypes;
const {
  common: { LOADING_PROGRESS_TIMEOUT },
} = constants;

const showLoadingProgressDialog = (props) => {
  const captions = {
    title: i18next.t('Loading'),
    description: i18next.t('Image(s) will be loaded soon'),
  };
  let isLoadingDialogShown = false;
  const timer = setTimeout(() => {
    dialogs.operations.show(IMAGE_LOADING_DIALOG, { captions, ...props });
    isLoadingDialogShown = true;
  }, LOADING_PROGRESS_TIMEOUT);

  return () => {
    clearTimeout(timer);
    return isLoadingDialogShown;
  };
};

export default showLoadingProgressDialog;
