import Ribbon from './ribbon';
import Paper from './paper';

const { random } = Math;
const { devicePixelRatio, requestAnimationFrame, cancelAnimationFrame } = window;

function CreateAnimation(element, ctx, options) {
  const { speed, ribbonsCount, ribbonsLength, ribbonsThick, papersCount, colors } = options;
  const duration = 1.0 / speed;
  const ribbons = [];
  const papers = [];
  const canvas = element;
  const canvasParent = canvas.parentNode;
  const canvasWidth = canvasParent.offsetWidth;
  const canvasHeight = canvasParent.offsetHeight;

  canvas.width = canvasWidth * devicePixelRatio;
  canvas.height = canvasHeight * devicePixelRatio;

  for (let i = 0; i < ribbonsCount; i += 1) {
    ribbons[i] = new Ribbon(
      random() * canvasWidth,
      -random() * canvasHeight * 2,
      ribbonsLength,
      ribbonsThick,
      ribbonsThick,
      45,
      1,
      0.05,
      colors,
      canvasWidth,
      canvasHeight
    );
  }

  for (let i = 0; i < papersCount; i += 1) {
    papers[i] = new Paper(
      random() * canvasWidth,
      random() * canvasHeight,
      canvasWidth,
      canvasHeight,
      colors
    );
  }

  this.start = () => {
    this.stop();
    this.update();
  };

  this.stop = () => {
    cancelAnimationFrame(this.interval);
  };

  this.resize = () => {
    canvas.width = canvasParent.offsetWidth * devicePixelRatio;
    canvas.height = canvasParent.offsetHeight * devicePixelRatio;

    for (let i = 0; i < papersCount; i += 1) {
      papers[i].updateBounds(canvas.width, canvas.height);
    }

    for (let i = 0; i < ribbonsCount; i += 1) {
      ribbons[i].updateBounds(canvas.width, canvas.height);
    }
  };

  this.update = () => {
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    for (let i = 0; i < papersCount; i += 1) {
      papers[i].update(duration);
      papers[i].draw(ctx);
    }

    for (let i = 0; i < ribbonsCount; i += 1) {
      ribbons[i].update(duration);
      ribbons[i].draw(ctx);
    }

    this.interval = requestAnimationFrame(() => {
      this.update();
    });
  };
}

export default CreateAnimation;
