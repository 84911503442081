const SIDE_ID = 'site_id';

const getSearchString = () => {
  const searchParams = new URLSearchParams(window.location.search);

  searchParams.delete(SIDE_ID);

  const searchString = searchParams.toString();

  return searchString ? `?${searchString}` : '';
};

export default getSearchString;
