import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { dialogs, saving, i18next, integration } from '@yola/ws-sdk';
import { ProgressDialog } from '@yola/ws-ui';
import { saveSiteWithEffect } from 'src/js/modules/saving/helpers/save-site';
import { MAX_SAVING_PROGRESS, MAX_SAVED_PROGRESS } from '../constants';

const { SAVING, ERROR } = saving.constants.statuses;
const getCaptions = () => ({
  title: `${i18next.t('Saving Website')}...`,
  description: i18next.t('We are saving your website. Please wait.'),
  cancel: i18next.t('Cancel'),
});

function SavingProgressDialogContainer({
  savingStatus,
  closeDialog,
  progressType,
  modalProps: { callback, closingCallback = () => {} },
  integrationSettings: { beforeUnloadEvents },
}) {
  if (savingStatus === ERROR) {
    closeDialog(closingCallback);
  }

  const savingProgress = savingStatus === SAVING ? MAX_SAVING_PROGRESS : MAX_SAVED_PROGRESS - 1;
  const captions = getCaptions();

  useEffect(() => {
    saveSiteWithEffect(() => {
      const successCallback = () => {
        callback();
        closingCallback();
      };
      closeDialog(successCallback);
    });
  }, []);

  // in case callback invokes redirect we don't need to show beforeUnload confirmation popup
  // saying that user might lost his content, as we just saved it, and this message is misleading an confusing
  useEffect(() => {
    const properties = {
      [integration.constants.SYMBOL_SETTING]: {
        disableBlocking: true,
      },
    };

    beforeUnloadEvents.end({ properties });
    return () => {
      beforeUnloadEvents.start({ properties });
    };
  }, [beforeUnloadEvents]);

  return (
    <ProgressDialog
      value={savingProgress}
      captions={captions}
      cancelable={false}
      overlay="visible"
      progressType={progressType}
    />
  );
}

SavingProgressDialogContainer.defaultProps = {
  progressType: 'determinate',
};

SavingProgressDialogContainer.propTypes = {
  progressType: PropTypes.oneOf(['determinate', 'indeterminate']),
  savingStatus: PropTypes.string.isRequired,
  closeDialog: PropTypes.func.isRequired,
  integrationSettings: PropTypes.shape({
    beforeUnloadEvents: PropTypes.shape({
      start: PropTypes.func.isRequired,
      end: PropTypes.func.isRequired,
    }).isRequired,
  }).isRequired,
  modalProps: PropTypes.shape({
    callback: PropTypes.func.isRequired,
    closingCallback: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = (state) => {
  const { modalProps } = dialogs.selectors.getDialog(state);
  const savingStatus = saving.selectors.getStatus(state);
  const integrationSettings = integration.selectors.getSettings(state);

  return {
    modalProps,
    savingStatus,
    integrationSettings,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeDialog(callback) {
    dispatch(dialogs.actions.hide());
    callback();
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SavingProgressDialogContainer);
