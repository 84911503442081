import { hdrm, view } from '@yola/ws-sdk';
import positionTypes from '../constants/position-types';

const wouldBeDroppedInSamePosition = (elementId, dropTargetElementId, position) => {
  const { referenceMap } = hdrm.instance.driver;
  const dropTargetElement = view.accessors.getLiveElement(dropTargetElementId);

  return (
    (position === positionTypes.BEFORE_BEGIN &&
      elementId === referenceMap.getPreviousReferenceId(dropTargetElement)) ||
    (position === positionTypes.AFTER_END &&
      elementId === referenceMap.getNextReferenceId(dropTargetElement))
  );
};

export default wouldBeDroppedInSamePosition;
