import positions from '../constants/resize-handle-positions';

const getHandleShapeByPosition = (position) => {
  switch (position) {
    case positions.BOTTOM_CENTER:
    case positions.TOP_CENTER:
    case positions.CENTER_LEFT:
    case positions.CENTER_RIGHT:
      return 'oval';
    default:
      return 'circle';
  }
};

export default getHandleShapeByPosition;
