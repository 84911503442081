import { anodum, textFormatting } from '@yola/ws-sdk';

const isElementSelected = ({ className, tagName }) => {
  const selection = textFormatting.accessors.getAdvancedSelection();
  const { anchorNode, focusNode, isCollapsed } = selection;
  const selector = className ? `${tagName}.${className}` : tagName;

  const startElement = anodum.isTextNode(anchorNode) ? anchorNode.parentElement : anchorNode;
  const startTag = startElement ? startElement.closest(selector) : null;

  if (isCollapsed) {
    return Boolean(startTag);
  }

  const endElement = anodum.isTextNode(focusNode) ? focusNode.parentElement : focusNode;
  const endTag = endElement ? endElement.closest(selector) : null;

  return Boolean(startTag && endTag && startTag === endTag);
};

export default isElementSelected;
