import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { i18n } from '@yola/ws-sdk';
import dialogs from 'yola-editor/src/js/modules/dialogs';
import site from 'yola-editor/src/js/modules/site';
import auth from 'yola-editor/src/js/modules/auth';
import UpgradeInterruptionDialog from 'src/js/modules/upsell/components/upgrade-interruption-dialog';
import useMatchBreakpoint from 'src/js/modules/utils/custom-hooks/use-match-breakpoint';
import segment from 'src/js/modules/analytics/segment';
import productTypes from '../constants/product-types';

const { track, constants } = segment;
const DESKTOP_VIEW_BREAKPOINT = '576px';
const DEFAULT_LANGUAGE_CODE = 'en';
const LOCALIZED_IMAGES = ['en', 'cs', 'de', 'es', 'fr', 'it', 'pt-br', 'sk', 'sv'];

const UpgradeDialogContainer = (props) => {
  const dispatch = useDispatch();
  const isDesktopView = useMatchBreakpoint(DESKTOP_VIEW_BREAKPOINT);
  const locale = i18n.accessors.getLocale();
  const languageCode = LOCALIZED_IMAGES.includes(locale) ? locale : DEFAULT_LANGUAGE_CODE;

  const imageSrc = isDesktopView
    ? `/images/upgrade-interruption/desktop/upgrade-interruption-${languageCode}.png`
    : `/images/upgrade-interruption/mobile/upgrade-interruption-${languageCode}.png`;

  const {
    onUpgrade,
    onUpgradeResolve,
    onUpgradeResolveFeedback,
    onUpgradeReject,
    onClose,
    onDialogCancel,
    captions,
    onDialogMainAction,
    premiumFeature,
    purchaseFlowType,
  } = props;

  const handleClose = () => {
    onClose();
    onDialogCancel();
    dispatch(dialogs.actions.hide());
  };

  const handleSubmit = () => {
    onDialogMainAction();
    dispatch(dialogs.actions.hide());

    const upgradeDetails = { onUpgradeResolveFeedback };
    onUpgrade(upgradeDetails).then(onUpgradeResolve, onUpgradeReject);
  };

  useEffect(() => {
    track(constants.events.UPGRADE_INTERRUPTION_DISPLAYED, {
      appName: constants.common.APP_NAME,
      siteId: auth.accessors.getSiteId(),
      templateBuildSlug: site.accessors.getSiteTemplateSlug(),
      premiumFeature,
      productCategory: productTypes.LATITUDE,
      purchaseFlowType,
    });
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  return (
    <UpgradeInterruptionDialog
      imageSrc={imageSrc}
      captions={captions}
      isDesktopView={isDesktopView}
      onClose={handleClose}
      onSubmit={handleSubmit}
    />
  );
};

UpgradeDialogContainer.propTypes = {
  onUpgrade: PropTypes.func.isRequired,
  onUpgradeResolve: PropTypes.func,
  onUpgradeReject: PropTypes.func,
  premiumFeature: PropTypes.string.isRequired,
  purchaseFlowType: PropTypes.string.isRequired,
  captions: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    entitlements: PropTypes.arrayOf(PropTypes.string),
    upgrade: PropTypes.string,
  }),
  onClose: PropTypes.func,
  onUpgradeResolveFeedback: PropTypes.func,
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

UpgradeDialogContainer.defaultProps = {
  captions: null,
  onUpgradeResolve: () => {},
  onUpgradeReject: () => {
    // eslint-disable-next-line no-console
    console.log('Upgrade flow was canceled');
  },
  onClose: () => {},
  onUpgradeResolveFeedback: () => {},
};

export default UpgradeDialogContainer;
