export const SITE_PROGRESS_CHECKLIST_SCROLL_TOP = 'SITE_PROGRESS_CHECKLIST_SCROLL_POSITION';
export const SITE_PROGRESS_CHECKLIST_OPENED_CATEGORIES =
  'SITE_PROGRESS_CHECKLIST_OPENED_CATEGORIES';
export const SITE_ID = 'SITE_ID';

export const MODAL_WIDTH = 320;
export const MODAL_WIDTH_LARGE_DESKTOP = 360;

export const MODAL_HEIGHT = 484;
export const MODAL_HEIGHT_LARGE_DESKTOP = 720;

export const MODAL_OFFSET_RIGHT = 24;
export const MODAL_OFFSET_BOTTOM = 96;

export const LARGE_DESKTOP_MIN_WIDTH = 1280;

export const CONTACT_SUPPORT_ID = 'contactsupport';

export const UPVOTE_INTERRUPTION_ID = 'difm-services';
export const DATA_SITE_PROGRESS_ATTRIBUTE = 'data-ws-website-assistant';
