import { i18next } from '@yola/ws-sdk';
import upsell from 'yola-editor/src/js/modules/upsell';
import segment from 'src/js/modules/analytics/segment';
import user from 'yola-editor/src/js/modules/user';
import store from 'src/js/store';
import referralLinksStorage from '../../refer-a-friend/helpers/referral-links-storage';
import showShareDialog from '../../refer-a-friend/operations/show-share-dialog';
import hasSiteCustomDomain from '../../publishing/verifiers/has-site-custom-domain';
import hasWLSiteCustomDomain from '../../publishing/verifiers/has-wl-site-custom-domain';
import { HELP_MENU_ID, helpMenuUrls } from '../constants';
import getHelpMenuItems from './get-help-menu-items';
import getHelpMenuItemClickCallbacks from './get-help-menu-item-click-callbacks';

const {
  constants: { triggerIds },
} = segment;

const getMainMenuItems = (options) => {
  const {
    siteURL,
    partnerId,
    supportUrl,
    supportEmail,
    redirectToAdvertising,
    redirectToWebsite,
    redirectToAnalytics,
    redirectToDashboard,
    publicRoadmapUrl,
    upsells,
    partnerProperties,
    isReferAFriendFeatureAvailable,
    isFreeDomainAvailable,
    isComplianceServiceMenuItemEnabled,
    isOrderWebsiteMenuItemEnabled,
  } = options;

  const items = [
    {
      order: 1,
      disabled: !siteURL,
      href: siteURL,
      target: '_blank',
      heading: i18next.t('View published website'),
      id: 'view-publish',
      icon: {
        glyph: 'view-publish',
        width: 24,
        height: 24,
        strokeWidth: 1.3,
      },
    },
    {
      type: 'divider',
      order: 2,
    },

    /*
     * In order to make `Exit editor` a last item in the menu,
     * even in cases when other items is added, we set so big order
     * */
    {
      type: 'divider',
      order: 98,
    },
    {
      order: 99,
      heading: redirectToDashboard ? i18next.t('Go to dashboard') : i18next.t('Exit editor'),
      onClick: redirectToDashboard || redirectToWebsite,
      id: 'dashboard',
      icon: {
        glyph: 'home',
        width: 22,
        height: 22,
        strokeWidth: 1.3,
      },
    },
  ];

  const state = store.getState();
  const isWL = user.selectors.getWhiteLabelStatus(state);
  const withPartnersDomainUpsell = user.verifiers.hasPartnersDomainUpsell(state);
  const contactSupportUrl = user.selectors.getContactSupportUrl(state);
  const termsOfUseUrl = user.selectors.getTermsOfUseUrl(state);
  const privacyPolicyUrl = user.selectors.getPrivacyPolicyUrl(state);
  const helpMenuItemClickCallbacks = getHelpMenuItemClickCallbacks(triggerIds.MAIN_MENU);

  if (redirectToAdvertising) {
    items.push({
      heading: i18next.t('Advertising'),
      onClick: redirectToAdvertising,
      order: 85,
      id: 'advertising',
      icon: {
        glyph: 'advertising',
        width: 24,
        height: 24,
        strokeWidth: 1.3,
      },
    });
  }

  if (redirectToAnalytics) {
    items.push({
      heading: i18next.t('Analytics'),
      onClick: redirectToAnalytics,
      order: 87,
      id: 'analytics',
      icon: {
        glyph: 'analytics',
        width: 24,
        height: 24,
        strokeWidth: 1.3,
      },
    });
  }

  items.push({
    heading: i18next.t('Help'),
    order: 90,
    target: '_blank',
    labelFor: HELP_MENU_ID,
    subMenu: getHelpMenuItems({
      isWL,
      partnerId,
      contactSupportUrl,
      supportEmail,
      gettingStartedUrl: helpMenuUrls.GETTING_STARTED,
      helpCenterUrl: supportUrl,
      publicRoadmapUrl,
      termsOfUseUrl,
      privacyPolicyUrl,
      isComplianceServiceMenuItemEnabled,
      isOrderWebsiteMenuItemEnabled,
      helpMenuItemClickCallbacks,
    }),
    id: 'help',
    icon: {
      glyph: 'help',
      width: 24,
      height: 24,
      strokeWidth: 1.3,
    },
  });

  const banners = [];
  const defaultUpsell = upsells.default;
  const { submitUserActionUrl, freeHostingDomains } = partnerProperties || {};
  const isInAppDomainsConfigured = Boolean(submitUserActionUrl && freeHostingDomains);
  let withUpsell = !hasSiteCustomDomain(state);

  if (isWL) {
    const hasCustomDomain = hasWLSiteCustomDomain(state);
    withUpsell = isInAppDomainsConfigured && !hasCustomDomain;
  }

  if (isReferAFriendFeatureAvailable) {
    const preloadReferralLinks = () => referralLinksStorage.getAsync();

    const referAFriendBanner = {
      id: 'refer-a-friend',
      isInteractive: true,
      onShow: () => {
        preloadReferralLinks();
      },
      onClick: () => {
        referralLinksStorage.getAsync().then((links) => {
          showShareDialog(links);
        });
      },
      captions: {
        title: i18next.t('Recommend Yola to a friend'),
        description: i18next.t('Enjoying building a site on Yola? Recommend Yola to your network'),
        actionLabel: i18next.t('Refer a friend'),
      },
      icon: {
        name: 'refer-a-friend',
        strokeWidth: 1.3,
      },
    };

    banners.push(referAFriendBanner);
  }

  const upgradeBanner = {
    id: 'upgrade',
    isInteractive: true,
    onClick: defaultUpsell.onUpgrade || (() => {}),
    captions: {
      title: i18next.t('Upgrade subscription'),
      description: i18next.t('Gain access to new features by upgrading your subscription'),
      actionLabel: i18next.t('Upgrade Now'),
    },
    icon: {
      name: 'arrow-up-circle',
      strokeWidth: 1.3,
    },
  };
  const domainBanner = {
    id: 'domain',
    isInteractive: true,
    onClick: () => {
      const triggerId = upsell.constants.triggerIds.MAIN_MENU;

      if (withPartnersDomainUpsell) {
        store.dispatch(upsell.actions.startWLDomainPurchase(triggerId));
      } else {
        store.dispatch(upsell.actions.startB2CDomainPurchase(triggerId));
      }
    },
    captions: {
      title: i18next.t('Get a custom domain'),
      description: i18next.t('Improve credibility of your site by connecting a custom domain'),
      actionLabel: isFreeDomainAvailable ? i18next.t('Get free domain') : i18next.t('Buy a domain'),
    },
    icon: {
      name: 'domain',
      strokeWidth: 1.3,
    },
  };

  if (Object.keys(defaultUpsell).length) {
    banners.push(upgradeBanner);
  }
  if (withPartnersDomainUpsell || (!isWL && withUpsell)) {
    banners.push(domainBanner);
  }

  return {
    items,
    banners: banners.length ? banners : null,
  };
};

export default getMainMenuItems;
