import { dialogs, i18next } from '@yola/ws-sdk';
import dialogsTypes from 'src/js/modules/dialogs/constants/dialog-types';

const showImageUploadFailDialog = () =>
  dialogs.operations.show(dialogsTypes.FILE_ERROR_DIALOG, {
    captions: {
      title: i18next.t('Image upload fail'),
      description: i18next.t('Some of the images failed to upload'),
      dismiss: i18next.t('Dismiss'),
    },
    onDismiss: () => {
      dialogs.operations.hide();
    },
    glyph: 'alert-circle',
  });

export default showImageUploadFailDialog;
