import React, { useContext } from 'react';
import { site, template } from '@yola/ws-sdk';
import { designSystem } from '@yola/ws-ui';
import segment from 'src/js/modules/analytics/segment';
import NavContext from './nav-context';

const { EditorHeader } = designSystem;

const {
  track,
  constants: { events },
} = segment;

function NavToggle() {
  const { open, close, isOpen } = useContext(NavContext);

  const onClick = () => {
    if (isOpen) {
      close();
    } else {
      open();

      track(events.APP_MAIN_MENU_DISPLAYED, {
        siteId: site.accessors.getSiteId(),
        templateBuildSlug: template.accessors.getBuildSlug(),
      });
    }
  };

  return (
    <div className="ws-nav__toggle">
      <EditorHeader.Menu
        glyph={isOpen ? 'close' : 'floating-menu'}
        onClick={onClick}
        transparent={isOpen}
      />
    </div>
  );
}

export default NavToggle;
