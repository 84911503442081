import { HOST_ICON_MAP, PROTOCOL_ICON_MAP } from '../constants/icon-autocomplete-maps';
import getSupportedHost from '../../../common/helpers/get-supported-host';
import getSupportedProtocol from '../../../common/helpers/get-supported-protocol';

const matchIconName = (url) => {
  const lowercaseUrl = url.toLowerCase();
  const protocol = getSupportedProtocol(lowercaseUrl);
  const host = getSupportedHost(lowercaseUrl);

  const iconFromProtocol = PROTOCOL_ICON_MAP[protocol];
  const iconFromHost = HOST_ICON_MAP[host];

  return iconFromProtocol || iconFromHost || null;
};

export default matchIconName;
