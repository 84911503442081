import React from 'react';
import PropTypes from 'prop-types';
import bowser from 'yola-bowser';
import { i18next } from '@yola/ws-sdk';
import { Button, Text } from '@yola/ws-ui';
import classNames from 'classnames';

function ProductsListItem({ product, onSelect }) {
  const isProductDisabled = (productItem) => 'available' in productItem && !productItem.available;

  const onSelectClick = (productItem) => {
    if (!isProductDisabled(productItem)) {
      onSelect(productItem);
    }
  };

  return (
    <div
      onClick={() => onSelectClick(product)}
      className={classNames('products-list__item', {
        'products-list__item--hover': bowser.desktop,
        'products-list__item--disabled': isProductDisabled(product),
      })}
    >
      {product.current && (
        <div className="products-list__item-badge">{i18next.t('Current plan')}</div>
      )}
      <div className="product-select__item-border-bottom">
        <Text type="heading-5" align="center">
          {product.name}
        </Text>
        <Text align="center" className="products-list__item-price">
          {product.price}
        </Text>
        <Text type="annotation" align="center">
          {(product.term === 'P1M' && i18next.t('Monthly subscription')) ||
            (product.term === 'P1Y' && i18next.t('Annual subscription'))}
        </Text>
      </div>
      <div
        className="product-select__item-description"
        /* eslint-disable-next-line yola/react/no-danger */
        dangerouslySetInnerHTML={{ __html: product.description }}
      />
      <Button
        className="products-list__item-button"
        type="cta"
        stretch="block"
        size="large"
        disabled={isProductDisabled(product)}
      >
        {i18next.t('Buy')}
      </Button>
    </div>
  );
}

ProductsListItem.propTypes = {
  product: PropTypes.shape({
    sku: PropTypes.string,
    current: PropTypes.bool,
    name: PropTypes.string,
    price: PropTypes.string,
    term: PropTypes.string,
    description: PropTypes.string,
    available: PropTypes.bool,
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default ProductsListItem;
