import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { designSystem } from '@yola/ws-ui';

const { Box, ActionButton } = designSystem;

const WizardSetupBottomPanel = (props) => {
  const {
    backButtonLabel,
    skipButtonLabel,
    submitButtonLabel,
    nextButtonLabel,
    showBackButton,
    showSkipButton,
    showSubmitButton,
    isDesktopView,
    isLoading,
    onBackButtonClick,
    onSkipButtonClick,
    onSubmitButtonClick,
    onNextButtonClick,
  } = props;
  const classes = classNames('ws-wizard-setup-bottom-panel', {
    'ws-wizard-setup-bottom-panel--desktop-view': isDesktopView,
  });

  return (
    <div className={classes}>
      <Box
        {...(isDesktopView
          ? {
              padding: 'spacing-xl',
              paddingTop: 'spacing-xs',
              paddingBottom: 'spacing-xs',
            }
          : {
              padding: 'spacing-xs',
            })}
      >
        <div className="ws-wizard-setup-bottom-panel__box">
          {showBackButton && (
            <ActionButton
              format="text"
              iconGlyph="back"
              size="large"
              label={backButtonLabel}
              onClick={onBackButtonClick}
            />
          )}
          {showSkipButton && (
            <ActionButton
              format="text"
              size={isDesktopView ? 'large' : 'small'}
              label={skipButtonLabel}
              onClick={onSkipButtonClick}
            />
          )}
          {(showSubmitButton && (
            <ActionButton
              appearance="accent"
              format="solid"
              iconGlyph="ai"
              size="large"
              label={submitButtonLabel}
              isLoading={isLoading}
              onClick={onSubmitButtonClick}
              {...(!isDesktopView && { fullWidth: true })}
            />
          )) || (
            <ActionButton
              appearance="accent"
              format="solid"
              iconGlyph="next"
              iconPlacement="after"
              size="large"
              label={nextButtonLabel}
              onClick={onNextButtonClick}
              {...(!isDesktopView && { fullWidth: true })}
            />
          )}
        </div>
      </Box>
    </div>
  );
};

WizardSetupBottomPanel.propTypes = {
  backButtonLabel: PropTypes.string.isRequired,
  skipButtonLabel: PropTypes.string.isRequired,
  submitButtonLabel: PropTypes.string.isRequired,
  nextButtonLabel: PropTypes.string.isRequired,
  showBackButton: PropTypes.bool.isRequired,
  showSkipButton: PropTypes.bool.isRequired,
  showSubmitButton: PropTypes.bool.isRequired,
  isDesktopView: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onBackButtonClick: PropTypes.func.isRequired,
  onSkipButtonClick: PropTypes.func.isRequired,
  onSubmitButtonClick: PropTypes.func.isRequired,
  onNextButtonClick: PropTypes.func.isRequired,
};

export default WizardSetupBottomPanel;
