const getImageData = (url, context) =>
  new Promise((resolve, reject) => {
    const img = new context.Image();
    img.src = url;

    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height,
      });
    };
    img.onerror = reject;
  });

export default getImageData;
