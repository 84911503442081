import React, { useState, useEffect, useLayoutEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import analytics from 'src/js/modules/analytics';
import withSelection from 'src/js/modules/utils/with-selection';
import Trigger from 'src/js/modules/common/components/trigger';
import restoreRange from '../helpers/restore-range';
import useFontSizeControl from '../helpers/use-font-size-control';
import changeFontSize from '../helpers/change-font-size';

const {
  segment: {
    trackers: { trackTextElementSizeAdjusted },
  },
} = analytics;

const { Slider, Icon } = designSystem;

const ICON_SIZE = 20;
const ICON_STROKE_WIDTH = 2;

const FontSizeSlider = ({ elementId, getContext }) => {
  const { selection, focusElements, options, currentValue } = useFontSizeControl(elementId);
  const min = 0;
  const max = options.length - 1;
  const [sliderValue, setSliderValue] = useState(min);
  const stateRef = useRef({});
  const { controlPane } = getContext();

  const handleSubmit = useCallback(() => {
    const { current } = stateRef;
    const { value: modifier, label: fontSize } = current.options[current.value];
    if (current.value === current.initialValue) return;

    changeFontSize(modifier, current.focusElements);

    try {
      restoreRange(current.selection, elementId);
    } catch (error) {
      console.error(error);
    }

    trackTextElementSizeAdjusted({ elementId, fontSize });
  }, [elementId]);

  useLayoutEffect(() => {
    if (stateRef.current.initialValue !== undefined) return;

    const val = options.findIndex((item) => item.value === currentValue);
    if (val === -1) return;

    setSliderValue(val);
    stateRef.current = {
      ...stateRef.current,
      selection,
      options,
      focusElements,
      value: val,
      initialValue: val,
    };
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, [currentValue]);

  useEffect(
    () => () => {
      handleSubmit();
    },
    [handleSubmit]
  );

  const updateValue = (val) => {
    setSliderValue(val);
    stateRef.current = {
      ...stateRef.current,
      value: val,
    };

    changeFontSize(options[val].value, focusElements);

    controlPane.forceHighlighterRerender();
  };

  const handleChange = (val) => {
    // on the first render handleChange is being called with wrong value
    // to fix this we need this check in order to not set wrong className
    if (sliderValue === -1) return;

    updateValue(val);
  };

  const handleValueControlClick = (type) => () => {
    const boundary = type === 'decrease' ? min : max;
    if (sliderValue === boundary) return;

    const num = type === 'decrease' ? -1 : 1;
    const val = sliderValue + num;
    updateValue(val);
  };

  const tooltipValueFormatter = (value) => {
    const { label = min } = options[value] || {};
    return label;
  };

  return (
    <div className="ws-font-size-slider">
      <div className="ws-font-size-slider__value-control">
        <Trigger onClick={handleValueControlClick('decrease')}>
          <Icon glyph="minus" size={ICON_SIZE} strokeWidth={ICON_STROKE_WIDTH} />
        </Trigger>
      </div>
      <div className="ws-font-size-slider__control">
        <Slider
          tooltipValueFormatter={tooltipValueFormatter}
          isTooltipEnabled
          min={min}
          max={max}
          value={sliderValue}
          onChange={handleChange}
        />
      </div>
      <div className="ws-font-size-slider__value-control ws-font-size-slider__value-control--last">
        <Trigger onClick={handleValueControlClick('increase')}>
          <Icon glyph="plus-for-button" size={ICON_SIZE} strokeWidth={ICON_STROKE_WIDTH} />
        </Trigger>
      </div>
    </div>
  );
};

FontSizeSlider.propTypes = {
  elementId: PropTypes.string.isRequired,
  getContext: PropTypes.func.isRequired,
};

export default withSelection(FontSizeSlider);
