import { view } from '@yola/ws-sdk';

const extractLiveDocumentAssents = () => {
  const liveDom = view.accessors.getLiveDocument();
  const head = liveDom.head.cloneNode(true);

  const colorPalletesNodeId = 'ws-color-palette';
  const colorPalletesNode = liveDom.body.querySelector(`#${colorPalletesNodeId}`);
  const colorPalletesClone = colorPalletesNode && colorPalletesNode.cloneNode(true);

  if (colorPalletesClone) {
    colorPalletesClone.querySelector('style')?.setAttribute('id', colorPalletesNodeId);
  }

  const customFontsNodeId = 'ws-custom-fonts';
  const customFontsNode = liveDom.body.querySelector(`#${customFontsNodeId}`);
  const customFontsClone = customFontsNode && customFontsNode.cloneNode(true);

  if (customFontsClone) {
    customFontsClone.querySelector('style')?.setAttribute('id', customFontsNodeId);
  }

  const themeDependenciesNodeId = 'ws-theme-dependencies';
  const themeDependenciesNode = liveDom.body.querySelector(`#${themeDependenciesNodeId}`);
  const themeDependenciesClone = themeDependenciesNode && themeDependenciesNode.cloneNode(true);

  const buttonPresetNodeId = 'ws-button-preset';
  const buttonPresetNode = liveDom.body.querySelector(`#${buttonPresetNodeId}`);
  const buttonPresetClone = buttonPresetNode && buttonPresetNode.cloneNode(true);

  const customCSS = [...liveDom.body.querySelectorAll('#ws-site-header-wrapper link')]
    .map((element) => element.outerHTML)
    .join('');

  head.querySelectorAll('meta').forEach((el) => {
    el.remove();
  });
  head.querySelectorAll('title').forEach((el) => {
    el.remove();
  });

  head.querySelectorAll('link[data-ws-ignore="true"]').forEach((el) => {
    el.remove();
  });

  head.querySelectorAll('script').forEach((el) => {
    el.remove();
  });

  const scripts = [...liveDom.body.querySelectorAll('script[src]')]
    .filter((script) => script.src.indexOf('ecwid') === -1 && script.src.indexOf('unpkg') === -1)
    .map((element) => element.outerHTML)
    .join('');

  return {
    head: head.innerHTML.trim(),
    colorPalletes: colorPalletesClone ? colorPalletesClone.innerHTML : '',
    customFonts: customFontsClone ? customFontsClone.innerHTML : '',
    themeDependencies: themeDependenciesClone ? themeDependenciesClone.innerHTML : '',
    buttonPreset: buttonPresetClone ? buttonPresetClone.innerHTML : '',
    customCSS,
    scripts,
  };
};

export default extractLiveDocumentAssents;
