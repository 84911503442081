import React, { useRef, useMemo, useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { blocks, extensions, i18next, utils } from '@yola/ws-sdk';
import { VARIATION_ATTRIBUTE_NAME } from 'src/js/modules/blocks/constants/common';
import getLocation from 'src/js/modules/control-pane/selectors/location';
import highlighterOffset from 'src/js/modules/highlighter/helpers/highlighter-offset';
import highlighter from 'src/js/modules/highlighter';
import ResizeTooltip from 'src/js/modules/extensions/registry/ws-image/components/resize-tooltip';
import useEventListener from 'src/js/modules/utils/custom-hooks/use-event-listener';

import useDrag from '../../../common/hooks/use-drag';
import collectDOMData from '../../../common/helpers/collect-dom-data';
import useHandlePosition from '../../../common/hooks/use-handle-position';
import calculateOffsetTop from '../../../common/helpers/calculate-offset-top';
import resizeToolPosition from '../../../common/constants/resize-handle-positions';
import ResizeHandle from '../../../common/components/resize-handle';

import constants from '../constants/common';
import syncIconSize from '../../../common/helpers/sync-icon-size';
import calcIconSize from '../../../common/helpers/calc-icon-size';
import trackIconSizeResized from '../trackers/track-icon-size-resized';

const getCaptions = () => ({
  shiftPressed: i18next.t('Release <b>Shift</b> to resize this icon only'),
  shiftReleased: i18next.t('Hold <b>Shift</b> to resize all icons in a row'),
  default: i18next.t('Drag to resize'),
});

const { ICON_GROUP_ATTRIBUTE } = constants;

const IconResizeTool = ({
  elementId,
  appearanceStyle,
  onActionStart,
  onActionEnd,
  onMouseEnter,
  onMouseLeave,
}) => {
  const { element, groupElements, blockElement } = useMemo(
    () => collectDOMData(elementId, ICON_GROUP_ATTRIBUTE),
    [elementId]
  );

  const [isShiftPressed, setIsShiftPressed] = useState(false);
  const [tooltipVisibility, setTooltipVisibility] = useState(false);
  const [isResizing, setIsResizing] = useState(false);

  const handleRef = useRef(null);
  const cachedRect = useRef(null);
  const cachedHighlightedIcons = useRef([]);
  const cachedResizableElements = useRef(new Map());

  const settings = extensions.accessors.getExtensionSettings(constants.slug);
  const { iconMaxSize: ICON_MAX_SIZE, iconMinSize: ICON_MIN_SIZE } = settings;

  const supportGroupResize = groupElements.length > 1;
  const isMultipleResize = supportGroupResize && isShiftPressed;
  const captions = useMemo(getCaptions, []);

  const controlPaneLocation = useSelector(getLocation);
  const indent = highlighterOffset.get();
  const [handlePosition, updateHandlePosition] = useHandlePosition({
    element,
    positions: {
      [resizeToolPosition.BOTTOM_RIGHT]: {
        correctionX: indent,
        correctionY: indent,
      },
    },
    controlPaneLocation,
  });

  const cacheResizableElements = () => {
    groupElements.forEach((resizableElement) => {
      const { width, height } = resizableElement.getBoundingClientRect();
      cachedResizableElements.current.set(resizableElement, {
        width,
        height,
      });
    });
  };

  const clearCacheResizableElements = () => {
    cachedResizableElements.current = new Map();
  };

  const hideHighlighter = () => {
    highlighter.operations.hide(cachedHighlightedIcons.current);
    cachedHighlightedIcons.current = [];
  };

  const showHighlighter = (options = {}) => {
    const { isGroup = isMultipleResize, withElementSize = false } = options;
    let highlightedGroupElements = [];

    highlighter.operations.show([element], {
      withElementSize,
      forceUpdate: true,
      labelGap: 'medium',
    });

    if (isGroup) {
      highlightedGroupElements = groupElements.filter((groupElement) => groupElement !== element);
      highlighter.operations.show(highlightedGroupElements, { forceUpdate: true });
    }

    cachedHighlightedIcons.current = [element, ...highlightedGroupElements];
  };

  const handleMouseEnter = () => {
    onMouseEnter();
    showHighlighter();

    if (!isResizing) {
      setTooltipVisibility(true);
    }
  };

  const handleMouseLeave = () => {
    onMouseLeave();
    setTooltipVisibility(false);
  };

  const setIconsSizeByCurrent = () => {
    const { width: size } = element.getBoundingClientRect();

    groupElements.forEach((icon) => {
      if (icon === element) return;
      const iconToResize = icon;
      iconToResize.style.width = `${size}px`;
      iconToResize.style.height = `${size}px`;
    });
  };

  const resetElementsHeight = () => {
    cachedResizableElements.current.forEach((bounds, resizableElement) => {
      if (resizableElement === element) return;
      const iconToResize = resizableElement;
      iconToResize.style.width = `${bounds.width}px`;
      iconToResize.style.height = `${bounds.height}px`;
    });
  };

  const handleResize = (currentSize, applyLiveChanges) => {
    const newSize = Math.round(currentSize);
    const { width } = cachedRect.current;
    const elements = isMultipleResize ? groupElements : [element];

    elements.forEach((icon) => {
      const iconToResize = icon;
      iconToResize.style.width = `${newSize}px`;
      iconToResize.style.height = `${newSize}px`;
    });

    updateHandlePosition();

    if (!applyLiveChanges) return;

    syncIconSize(elements, newSize);

    const blockId = blocks.accessors.getBlockIdByElement(blockElement);
    const blockVariationId = blockElement.getAttribute(VARIATION_ATTRIBUTE_NAME);

    trackIconSizeResized({
      blockId,
      blockVariationId,
      oldSize: width,
      newSize,
      iconAdjusted: isMultipleResize ? 'group-icon' : 'single-icon',
    });
  };

  const handleDrag = (distance, applyLiveChanges = false) => {
    const { width } = cachedRect.current;
    const newSize = calcIconSize(distance[0], distance[1], width, ICON_MAX_SIZE, ICON_MIN_SIZE);
    handleResize(newSize, applyLiveChanges);
  };

  useDrag([handleRef], {
    onStart() {
      setIsResizing(true);
      setTooltipVisibility(false);
      hideHighlighter();
      showHighlighter({ withElementSize: true });
      cachedRect.current = element.getBoundingClientRect();
      cacheResizableElements();
      onActionStart();
    },

    onMove(distance) {
      handleDrag(distance);
      showHighlighter({ withElementSize: true });
    },

    onEnd(distance) {
      setIsResizing(false);
      handleDrag(distance, true);
      showHighlighter();
      clearCacheResizableElements();
      onActionEnd();
    },
  });

  const handleKey = ({ shiftKey }) => {
    setIsShiftPressed(shiftKey);
    if (isResizing) {
      if (shiftKey) {
        setIconsSizeByCurrent();
      } else {
        resetElementsHeight();
      }
    }
    hideHighlighter();
    showHighlighter({ isGroup: shiftKey, withElementSize: isResizing });
  };

  const keyEventCallback = supportGroupResize ? handleKey : utils.noop;

  useEventListener('keydown', keyEventCallback, document);
  useEventListener('keyup', keyEventCallback, document);
  useEventListener('keydown', keyEventCallback, element.ownerDocument);
  useEventListener('keyup', keyEventCallback, element.ownerDocument);

  useEffect(() => hideHighlighter, []);

  if (!handlePosition[resizeToolPosition.BOTTOM_RIGHT]) return null;

  const [left, top] = handlePosition[resizeToolPosition.BOTTOM_RIGHT];

  const offTop = calculateOffsetTop(element, top);

  return (
    <Fragment>
      <ResizeHandle
        ref={handleRef}
        direction="bottom-right"
        left={left}
        top={offTop}
        style={appearanceStyle}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
      {tooltipVisibility && (
        <ResizeTooltip left={left} top={top} direction={controlPaneLocation.direction}>
          {supportGroupResize && (
            <div
              // eslint-disable-next-line yola/react/no-danger
              dangerouslySetInnerHTML={{
                __html: isShiftPressed ? captions.shiftPressed : captions.shiftReleased,
              }}
            />
          )}
          {!supportGroupResize && captions.default}
        </ResizeTooltip>
      )}
    </Fragment>
  );
};

IconResizeTool.propTypes = {
  elementId: PropTypes.string.isRequired,
  appearanceStyle: PropTypes.shape().isRequired,
  onActionStart: PropTypes.func.isRequired,
  onActionEnd: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

IconResizeTool.defaultProps = {
  onMouseEnter: Function.prototype,
  onMouseLeave: Function.prototype,
};

export default IconResizeTool;
