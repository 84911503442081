import { hdrm, view, blocks, template } from '@yola/ws-sdk';
import store from 'src/js/store';
import getTargetElements from 'src/js/modules/blocks/helpers/get-target-elements';
import { ADD_BLOCKS_BTN_HEIGHT } from 'src/js/modules/blocks/constants/sizes';
import stickTypes from 'src/js/modules/blocks/constants/stick-types';
import {
  PANE_TRIGGER_SIZE,
  PANE_DEFAULT_INDENT,
  PANE_ARROW_INDENT,
  PANE_SIZE_WITH_LINING,
  DOCUMENT_MIN_WIDTH_ON_DESKTOP,
  PANE_COLLAPSE_LIST_WIDTH,
} from '../constants/sizes';
import getTemplateHeight from './get-template-height';
import getMaxPaneSize from './get-max-pane-size';
import getPaneY from './get-pane-y';
import getDocumentSizes from './get-document-sizes';
import getDropDownHeight from './get-dropdown-height';
import getPaneX from './get-pane-x';
import getPaneWidth from './get-pane-width';
import getPageContainerOffset from '../../utils/helpers/get-page-container-offset';
import getBlockTriggersInfo from '../../blocks/helpers/get-block-triggers-info';
import isElementWidget from '../../blocks/helpers/is-element-widget';
import getNavbarHeight from '../../navbar/accessors/get-height';

const RESIZE_TARGET = 'ws-media-container';
const RESIZE_INDENT = 6;

const hasWidgetInside = (element) =>
  Boolean(element.querySelector(`[id^=${blocks.constants.WIDGET_ID_PREFIX}]`));

const hasResizeTrigger = (element) => element.closest(RESIZE_TARGET);

const getPaneLocationForElement = ({
  element,
  targetElementBounds,
  items,
  scrollPosition,
  featureFlags,
  isElementSelected = false,
}) => {
  const state = store.getState();

  const pageContainerSelector = template.selectors.getPageContainerSelector(state);
  const pageContainerOffset = getPageContainerOffset(pageContainerSelector);
  const { documentScrollWidth, windowInnerWidth, windowInnerHeight } = getDocumentSizes();

  let triggersPos;
  let topTrigger;
  let bottomTrigger;
  let addBlocksRect;

  if (!isElementSelected) {
    const targetElements = getTargetElements(
      view.accessors.getLiveElementId(element),
      null,
      featureFlags
    );
    const addBlocksElementId = targetElements.find((targetElementId) => {
      const targetElement = view.accessors.getLiveElement(targetElementId);
      return !isElementWidget(targetElement);
    });
    const addBlocksElement = view.accessors.getLiveElement(addBlocksElementId);

    triggersPos = getBlockTriggersInfo({
      elementId: addBlocksElement,
      scrollPosition,
      pageContainerSelector,
      viewportHeight: windowInnerHeight,
    });

    ({ topTrigger, bottomTrigger } = triggersPos || {});

    addBlocksRect = addBlocksElement ? addBlocksElement.getBoundingClientRect() : null;
  }

  const navbarHeight = getNavbarHeight();
  const { elLeft, elRight, elWidth, elHeight, elTop, elOffsetTop, elBottom } =
    hdrm.accessors.getElementStyles(element);

  const isAddBlockElementOverlapsPane = !addBlocksRect
    ? false
    : Math.abs(addBlocksRect.bottom - elBottom) < ADD_BLOCKS_BTN_HEIGHT / 2;
  const isElementTopUnderNavbar = elTop - navbarHeight - pageContainerOffset >= 0;

  const { maxPaneWidth, maxPaneHeight } = getMaxPaneSize(items, windowInnerWidth);

  const templateHeight = getTemplateHeight(element);
  const isPageStart = scrollPosition === 0;
  const elTopToPageStart = elTop + scrollPosition - navbarHeight;
  const isElementFirst = elTop - navbarHeight - pageContainerOffset === 0;
  const isElementLast = elOffsetTop + Math.ceil(elHeight) === templateHeight;
  const elementIsFullWidth = documentScrollWidth - elWidth <= pageContainerOffset * 2;
  const elementIsLessHalfWidth = documentScrollWidth / 2 > elWidth;
  const pageYCalcParams = { elTop, scrollTop: scrollPosition, elHeight };
  const pageXCalcParams = { elLeft, elWidth, elRight, targetElementBounds };
  const paneCanBeInElementByWidth =
    documentScrollWidth > DOCUMENT_MIN_WIDTH_ON_DESKTOP
      ? !elementIsLessHalfWidth
      : elementIsFullWidth;

  const oneTriggerMinHeight =
    ADD_BLOCKS_BTN_HEIGHT / 2 + PANE_SIZE_WITH_LINING + PANE_DEFAULT_INDENT * 2;

  const twoTriggersMinHeight =
    ADD_BLOCKS_BTN_HEIGHT + PANE_SIZE_WITH_LINING + PANE_DEFAULT_INDENT * 2;

  const elementMinHeight = topTrigger && bottomTrigger ? twoTriggersMinHeight : oneTriggerMinHeight;

  const paneCanBeInElement = elHeight >= elementMinHeight && paneCanBeInElementByWidth;

  let visibleElementHeight = elHeight;

  if (elTop < 0) {
    visibleElementHeight += elTop - navbarHeight;
  }

  if (elBottom > windowInnerHeight) {
    visibleElementHeight -= elBottom - windowInnerHeight;
  }

  const canPaneBeInViewport = (paneYPosition) => {
    let paneSize = PANE_TRIGGER_SIZE + PANE_DEFAULT_INDENT * 2;
    if (isAddBlockElementOverlapsPane) {
      paneSize += ADD_BLOCKS_BTN_HEIGHT / 2;
    }

    if (paneYPosition === 'elAbove') {
      return elTop - navbarHeight > paneSize;
    }

    return elTop + elHeight - navbarHeight > paneSize;
  };

  let positionX = null;
  let positionY = null;
  let offsetY;
  let offsetX;
  let direction;
  let dropdown;
  let expand;
  let dropdownPart = 'top';

  // If Control Pane can be inserted inside element
  if (paneCanBeInElement && maxPaneWidth + PANE_SIZE_WITH_LINING < elWidth) {
    direction = 'none';

    if (triggersPos) {
      const addBlockTriggerOnElementHeight =
        topTrigger && bottomTrigger ? ADD_BLOCKS_BTN_HEIGHT : ADD_BLOCKS_BTN_HEIGHT / 2;
      if (
        visibleElementHeight >
        PANE_SIZE_WITH_LINING + PANE_DEFAULT_INDENT + addBlockTriggerOnElementHeight
      ) {
        if (topTrigger) {
          if (topTrigger.position.stick === stickTypes.TOP) {
            if (elTop < navbarHeight + pageContainerOffset) {
              positionY = 'elTopStickScroll';
            } else {
              positionY = 'elTopStickAddBlockBtn';
            }
          } else {
            positionY = 'elTopAddBlockBtn';
          }
        } else if (elTop < navbarHeight + pageContainerOffset) {
          positionY = 'highElTop';
        } else {
          positionY = 'elTop';
        }
      } else if (elTop < navbarHeight + pageContainerOffset) {
        if (
          topTrigger &&
          topTrigger.position.stick === stickTypes.TOP &&
          visibleElementHeight > ADD_BLOCKS_BTN_HEIGHT
        ) {
          positionY = 'elBelow';
        } else {
          positionY = 'elBelowAddBlockBtn';
        }

        direction = 'down';
      } else if (elBottom > windowInnerHeight) {
        if (bottomTrigger.position.stick === stickTypes.BOTTOM) {
          positionY = 'elAbove';
        } else {
          positionY = 'elAboveAddBlockBtn';
        }

        direction = 'up';
      }
    }

    if (!positionY) {
      // If top and bottom bounds of the element are out of screen
      if (!isElementTopUnderNavbar && elBottom > windowInnerHeight) {
        positionY = 'highElTop';
        expand = 'down';

        // If Top bound is visible
      } else if (isElementTopUnderNavbar) {
        positionY = 'elTop';

        if (elTopToPageStart - maxPaneHeight > 0) {
          expand = 'up';
        } else {
          expand = 'down';
        }

        dropdown = 'bottom-right';
      }
    }
  }

  if (
    isElementFirst &&
    isPageStart &&
    elHeight < ADD_BLOCKS_BTN_HEIGHT &&
    isAddBlockElementOverlapsPane
  ) {
    positionY = 'pageTop';
    expand = 'down';
    direction = 'down';
  }

  if (positionY === null) {
    // If Control Pane can be inserted above the element
    if (
      elTop - navbarHeight - PANE_SIZE_WITH_LINING > 0 &&
      elTopToPageStart - PANE_SIZE_WITH_LINING - maxPaneHeight > 0
    ) {
      direction = 'up';
      if (
        triggersPos &&
        topTrigger &&
        Math.abs(triggersPos.bounds.top - elTop) < 60 &&
        Math.round(elLeft + elWidth / 2) === topTrigger.position.x
      ) {
        if (
          elTop -
            navbarHeight -
            PANE_SIZE_WITH_LINING -
            PANE_ARROW_INDENT -
            ADD_BLOCKS_BTN_HEIGHT / 2 >
          0
        ) {
          positionY = 'elAboveAddBlockBtn';
        }
      } else {
        positionY = 'elAbove';
      }
      expand = 'up';
    }
    // If Control Pane can be inserted below the element
    if (
      (!positionY || (triggersPos && paneCanBeInElement)) &&
      elBottom + PANE_SIZE_WITH_LINING < windowInnerHeight &&
      elBottom + scrollPosition + maxPaneHeight < templateHeight
    ) {
      if (isAddBlockElementOverlapsPane) {
        positionY = 'elBelowAddBlockBtn';
      } else {
        positionY = 'elBelow';
      }

      direction = 'down';
      expand = 'down';
    }

    // for elements that are smaller than pane
    if (!paneCanBeInElement && !elementIsLessHalfWidth && isElementLast) {
      positionY = 'elAbove';
      expand = 'up';
      direction = 'up';
    }

    // for small elements with pane above that overlaps viewport
    if (elementIsLessHalfWidth && !canPaneBeInViewport(positionY)) {
      direction = 'down';
      expand = 'down';
      positionY = 'elBelow';
    }
  }

  // If pane can't be inserted above or below the element
  if (positionY === null) {
    // If pane can be inserted on left side of the element
    if (elLeft - maxPaneWidth > 0) {
      positionX = 'elLeft';
      direction = 'left';
      // If pane can be inserted on right side of the element
    } else if (elRight + maxPaneWidth < windowInnerWidth) {
      positionX = 'elRight';
      direction = 'right';
    }

    // Set positionY - middle, if pane will be inserted on left\right side of the element
    if (positionX !== null) {
      positionY = 'elMiddle';
      expand = 'middle';
    }
  } else {
    positionX = 'elMiddle';
  }

  /*
  If pane can't be inserted anywhere
  Now this case isn't work correctly. And pane may have wrong position in this case.
   */
  if (!positionX && !positionY) {
    positionY = 'elAbove';
    positionX = 'elMiddle';
    direction = 'up';
    expand = 'up';
  }

  offsetX = getPaneX(positionX, pageXCalcParams);

  // if pane overlaps document width
  if (direction !== 'none') {
    if (offsetX - maxPaneWidth / 2 < 0) {
      direction = 'right';
      expand = 'middle';
      positionX = 'elRight';
      positionY = 'elMiddle';
    } else if (offsetX + maxPaneWidth / 2 > windowInnerWidth) {
      direction = 'left';
      expand = 'middle';
      positionX = 'elLeft';
      positionY = 'elMiddle';
    }
  }

  const panelWidth = getPaneWidth(items);
  const dropdownHeight = getDropDownHeight(items);

  if (
    isElementFirst &&
    isPageStart &&
    elHeight < ADD_BLOCKS_BTN_HEIGHT &&
    isAddBlockElementOverlapsPane
  ) {
    offsetY =
      getPaneY(positionY, pageYCalcParams, navbarHeight, ADD_BLOCKS_BTN_HEIGHT) +
      pageContainerOffset;
  } else {
    offsetY = getPaneY(positionY, pageYCalcParams, navbarHeight, ADD_BLOCKS_BTN_HEIGHT);
  }

  const { referenceMap } = hdrm.instance.driver;

  if (triggersPos && positionY === 'elTopAddBlockBtn' && topTrigger) {
    const prevElementId = referenceMap.getPreviousReferenceId(element);
    if (prevElementId) {
      const prevElement = view.accessors.getLiveElement(prevElementId);
      const prevBounds = prevElement.getBoundingClientRect();
      if (topTrigger.position.stick === stickTypes.NONE && prevBounds.bottom > elTop) {
        offsetY += prevBounds.bottom + ADD_BLOCKS_BTN_HEIGHT - elTop;
      }
    }
  }

  if (offsetY - scrollPosition + dropdownHeight + PANE_TRIGGER_SIZE / 2 < windowInnerHeight) {
    dropdownPart = 'bottom';
  }

  offsetX = getPaneX(positionX, pageXCalcParams);

  if (offsetX + panelWidth / 2 + PANE_COLLAPSE_LIST_WIDTH > windowInnerWidth) {
    dropdown = `${dropdownPart}-left`;
  } else {
    dropdown = `${dropdownPart}-right`;
  }

  if (direction === 'left' || direction === 'right') {
    if (offsetY - maxPaneHeight / 2 < 0) {
      expand = 'down';
    } else if (offsetY + maxPaneHeight / 2 > templateHeight) {
      expand = 'up';
    }
  }

  if (direction === 'up') {
    offsetY -= PANE_ARROW_INDENT;
  }

  if (direction === 'down') {
    offsetY += PANE_ARROW_INDENT;
  }

  // for cases when targer element is columns that contains widget
  if (hasWidgetInside(element)) {
    if (positionY === 'elAbove') {
      offsetY -= PANE_ARROW_INDENT;
    }

    if (positionY === 'elBelow') {
      offsetY += PANE_ARROW_INDENT;
    }
  }

  if (hasResizeTrigger(element)) {
    if (positionY === 'elBelow') {
      offsetY += RESIZE_INDENT;
    }
  }

  return {
    offsetX,
    offsetY,
    positionX,
    positionY,
    direction,
    dropdown,
    expand,
    maxPaneWidth,
  };
};

export default getPaneLocationForElement;
