import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import helpers from '../helpers';

const { ActionListItem } = designSystem;

const LocaleMenuItem = (props) => {
  const { locale, language, onClick } = props;
  const { code, name, country } = language;

  const flagCode = country.iconComponent.props.code;
  const isActive = locale === code;
  const handleClick = useCallback(() => {
    if (isActive) return;

    const localeHomePage = helpers.getLocaleHomePage(code);
    if (localeHomePage) {
      onClick(localeHomePage.id, locale, code);
    }
  }, [isActive, code, onClick, locale]);

  return (
    <ActionListItem selected={isActive} title={name} flagCode={flagCode} onClick={handleClick} />
  );
};

LocaleMenuItem.propTypes = {
  locale: PropTypes.string.isRequired,
  language: PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    country: PropTypes.shape({
      iconComponent: PropTypes.shape({
        props: PropTypes.shape({
          code: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default LocaleMenuItem;
