import getLanguages from './get-languages';

const getLanguageCodes = () =>
  Object.values(getLanguages()).reduce((languageCodes, languageData) => {
    languageData.countries.forEach(({ languageCode }) => {
      if (languageCodes.includes(languageCode)) return;
      languageCodes.push(languageCode);
    });
    return languageCodes;
  }, []);

export default getLanguageCodes;
