import { transactions, blocks } from '@yola/ws-sdk';
import adjacentPositions from 'src/js/modules/blocks/constants/adjacent-positions';
import getElementById from '../../helpers/get-element-by-id';
import getWhitespaceAutomationInstructions from './helpers/get-whitespace-automation-instructions';

const getInsertedBlock = (payload, isStatic) => {
  const { refElementId, position, blockId } = payload;
  const targetBlock = getElementById(refElementId, isStatic);

  if (!targetBlock) {
    return null;
  }

  const isWidgetBlock = blockId && blockId.startsWith('widget-');

  if (isWidgetBlock) {
    const closestCustomBlock = targetBlock.closest('ws-block[custom]');
    const isFirstInsertedWidget =
      closestCustomBlock.querySelectorAll(`[id^=${blocks.constants.WIDGET_ID_PREFIX}]`).length ===
      1;

    if (!isFirstInsertedWidget) return null;

    return closestCustomBlock;
  }

  if (position === adjacentPositions.AFTER_END) {
    return targetBlock.nextElementSibling;
  }

  return targetBlock.previousElementSibling;
};

const blockIsInserted = (config, payload, transaction) => {
  const { context } = transaction;
  const isStatic = context !== transactions.transactionTypes.LIVE_DOCUMENT;
  const insertedBlock = getInsertedBlock(payload, isStatic);

  if (!insertedBlock) {
    return [];
  }

  return [
    ...getWhitespaceAutomationInstructions({
      prevSiblingNode: insertedBlock.previousElementSibling,
      targetNode: insertedBlock,
      isStatic,
      isRevertWhitespaceAutomationEnabled: false,
    }),
    ...getWhitespaceAutomationInstructions({
      prevSiblingNode: insertedBlock,
      targetNode: insertedBlock.nextElementSibling,
      isStatic,
    }),
  ];
};

export default blockIsInserted;
