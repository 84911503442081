import React from 'react';
import PropTypes from 'prop-types';
import { DifmBannerFinish } from '@yola/webapp-plugins';
import { dialogs } from '@yola/ws-sdk';

const DifmPromoFinishDialogContainer = ({ selectedService, onDialogCancel }) => {
  const onSubmit = () => {
    onDialogCancel({
      selectedService,
    });
    dialogs.operations.hide();
  };

  return <DifmBannerFinish onSubmit={onSubmit} />;
};

DifmPromoFinishDialogContainer.propTypes = {
  selectedService: PropTypes.string.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

export default DifmPromoFinishDialogContainer;
