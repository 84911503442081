import React from 'react';
import PropTypes from 'prop-types';
import { i18next, utils } from '@yola/ws-sdk';
import { Field, designSystem } from '@yola/ws-ui';
import InputGroupField from 'src/js/modules/common/components/input-group-field';
import CheckboxField from 'src/js/modules/common/components/checkbox-field';
import helpers from '../helpers';
import constants from '../constants';

const { common } = constants;
const { LABEL_INPUT, PLACEHOLDER_INPUT } = common;

const { Stack, ControlGroup } = designSystem;

const BasicFieldContent = (props) => {
  const { labelValue, placeholderValue, placeholders, handleChange, onRequiredCheckboxClick } =
    props;

  return (
    <Stack gap="spacing-m">
      <ControlGroup title={i18next.t('Label')} required>
        <Field
          component={InputGroupField}
          value={labelValue}
          name="label"
          placeholder={placeholders[LABEL_INPUT]}
          onChange={handleChange}
          validate={(value) =>
            helpers.validateRequired(value) || helpers.validatePersonalData(value)
          }
        />
      </ControlGroup>
      <ControlGroup title={i18next.t('Placeholder')}>
        <Field
          component={InputGroupField}
          value={placeholderValue}
          name="placeholder"
          placeholder={placeholders[PLACEHOLDER_INPUT]}
          onChange={handleChange}
          validate={(value) => helpers.validatePersonalData(value)}
        />
      </ControlGroup>
      <Field
        name="required"
        component={CheckboxField}
        id="displayRequireCheckbox"
        label={i18next.t('Make required')}
        onClick={onRequiredCheckboxClick}
      />
    </Stack>
  );
};

BasicFieldContent.propTypes = {
  labelValue: PropTypes.string,
  placeholderValue: PropTypes.string,
  placeholders: PropTypes.shape({
    [LABEL_INPUT]: PropTypes.string,
    [PLACEHOLDER_INPUT]: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  onRequiredCheckboxClick: PropTypes.func,
};

BasicFieldContent.defaultProps = {
  labelValue: '',
  placeholderValue: '',
  onRequiredCheckboxClick: utils.noop,
};

export default BasicFieldContent;
