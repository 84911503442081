import { useSelector } from 'react-redux';
import { blocks } from '@yola/ws-sdk';
import sortSurfaces from '../helpers/sort-surfaces';

function useBlocksListSurfaces(blocksList) {
  const allBlocksData = useSelector(blocks.selectors.getBlocks);

  const allBlocksSurfacesData = allBlocksData.reduce((result, block) => {
    const { availableSettings } = block;
    const displayOptions = availableSettings && availableSettings.displayOptions;
    const surfaces = displayOptions && displayOptions.find((option) => option.id === 'surfaces');

    return {
      ...result,
      [block.id]: surfaces && surfaces.options,
    };
  }, {});

  const blocksListSurfacesByBlockId = {};
  const countedSingleSurfacesByValue = {};

  blocksList.forEach((block) => {
    const surfaces = allBlocksSurfacesData[block.id];
    if (!surfaces) return;

    // According to requirements, we need to show just a single value surfaces
    // (not a combined ones) which are common among all blocks in a list
    const singleSurfaces = surfaces.reduce((acc, surfaceItem) => {
      const singleValue = surfaceItem.value.split(' ')[0];

      // Filter surfaces with duplicated single values
      const found = acc.find(({ value }) => value.split(' ')[0] === singleValue);
      if (found) return acc;

      acc.push({
        ...surfaceItem,
        value: singleValue,
      });

      return acc;
    }, []);

    singleSurfaces.forEach((surfaceItem) => {
      const { value } = surfaceItem;
      const surfaceItemCounted = countedSingleSurfacesByValue[value];

      countedSingleSurfacesByValue[value] =
        surfaceItemCounted !== undefined
          ? { ...surfaceItemCounted, count: surfaceItemCounted.count + 1 }
          : { ...surfaceItem, count: 1 };
    });

    blocksListSurfacesByBlockId[block.id] = surfaces;
  });

  // Get and sort surfaces options that are common among all blocks in the list
  const blocksListSurfacesFiltered = sortSurfaces(
    Object.values(countedSingleSurfacesByValue)
      .filter((surfaceItem) => surfaceItem.count === blocksList.length)
      .map((surfaceItem) => {
        // Delete spare internal 'count' property
        // eslint-disable-next-line no-param-reassign
        delete surfaceItem.count;
        return surfaceItem;
      })
  );

  return {
    blocksListSurfacesFiltered,
    blocksListSurfacesByBlockId,
  };
}

export default useBlocksListSurfaces;
