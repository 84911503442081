import React from 'react';
import PropTypes from 'prop-types';
import {
  utils,
  dialogs,
  textFormatting,
  customization,
  contentEditable,
  view,
  i18next,
} from '@yola/ws-sdk';
import bowser from 'yola-bowser';
import { converters, RGBColor, HSLColor, contrastRatio } from '@yola/yola-palette-generator';
import ColorSettingsDialog from 'src/js/modules/website-design/components/color-settings-dialog';
import parseHslColorString from 'src/js/modules/website-design/helpers/parse-hsl-color-string';
import parseRGBColorString from 'src/js/modules/website-design/helpers/parse-rgb-color-string';
import getTextColorOfSelection from '../helpers/get-text-color-of-selection';
import wrapInCustomColor from '../helpers/wrap-in-custom-color';
import restoreRange from '../helpers/restore-range';
import setColorOption from '../helpers/set-color-option';

const getCaptions = (isLight) => {
  const lightWarning = i18next.t(
    'We recommend using a lighter shade to increase the legibility on your website.'
  );

  const darkWarning = i18next.t(
    'We recommend using a darker shade to increase the legibility on your website.'
  );

  const warning = isLight ? darkWarning : lightWarning;

  return {
    title: i18next.t('Custom color'),
    apply: i18next.t('Submit'),
    cancel: i18next.t('Cancel'),
    warning,
    opacity: i18next.t('Opacity'),
  };
};

const MODAL_WIDTH = 416;
const MODAL_HEIGHT = 495;

class AddCustomColorDialogContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.selection = textFormatting.accessors.getAdvancedSelection();
    this.focusedElementInfo = contentEditable.helpers.getFocusedElementInfo();

    const currentColor = getTextColorOfSelection(this.selection);
    const { r, g, b, a } = parseRGBColorString(currentColor);
    const convertedHSLColor = converters.rgb2hsl(new RGBColor(r / 255, g / 255, b / 255, a));

    this.affectedNodes = wrapInCustomColor(convertedHSLColor.toCSSValue(), props.elementId);

    // Fix issue with not hidden keyboard once `Color settings dialog` is opened
    if (bowser.mobile || bowser.tablet) {
      this.focusedElement = view.accessors.getLiveElement(this.focusedElementInfo.id);
      this.focusedElement.blur();
    }

    const { hue: h, saturation: s, lightness: l } = convertedHSLColor;

    this.state = {
      color: { h, s, l },
      warning: false,
    };
    this.element = view.accessors.getLiveElement(props.elementId);
    this.dialogPosition = dialogs.helpers.getPositionByElement(
      this.element,
      MODAL_WIDTH,
      MODAL_HEIGHT
    );

    this.captions = getCaptions(props.isLight);

    this.updateColor = this.updateColor.bind(this);
    this.revertChanges = this.revertChanges.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleColorChangeComplete = this.handleColorChangeComplete.bind(this);
    this.restoreSelection = this.restoreSelection.bind(this);
  }

  handleCancel() {
    const { elementId, onDialogCancel } = this.props;
    let { selection } = this;

    if (bowser.mobile || bowser.tablet) {
      selection = this.restoreSelection();
    }

    onDialogCancel();
    restoreRange(selection, elementId);
    this.revertChanges();
    restoreRange(this.selection, elementId);
    dialogs.operations.hide();
  }

  updateColor({ hsl, hex }) {
    this.affectedNodes.forEach((node) => {
      // eslint-disable-next-line no-param-reassign
      node.style.color = hex;
    });
    this.setState({ color: { ...hsl } });
  }

  restoreSelection() {
    this.focusedElement.setAttribute('contenteditable', true);
    this.focusedElement.focus();
    return textFormatting.accessors.getAdvancedSelection();
  }

  handleSubmit() {
    const { elementId, blockId, surfaceAttribute, onDialogMainAction } = this.props;
    const {
      color: { h, s, l, a },
    } = this.state;
    const hslaColor = new HSLColor(h, s, l, a).toCSSValue();
    let { selection } = this;

    if (bowser.mobile || bowser.tablet) {
      selection = this.restoreSelection();
    }

    onDialogMainAction();

    restoreRange(selection, elementId);
    this.revertChanges();

    const cssVariableName = textFormatting.helpers.getCssVariableNameByColor(hslaColor);

    const { rootVariableName, value } = setColorOption(
      cssVariableName,
      hslaColor,
      elementId,
      blockId,
      surfaceAttribute
    );

    customization.operations.addCustomTextColor({
      value,
      name: rootVariableName,
    });

    restoreRange(selection, elementId);
    dialogs.operations.hide();
  }

  handleColorChangeComplete({ hsl }) {
    const { backgroundColor, optimalContrastRatio } = this.props;
    const targetColor = new HSLColor(...Object.values(hsl));
    const background = new HSLColor(...Object.values(parseHslColorString(backgroundColor)));
    const cr = contrastRatio(targetColor, background);

    this.setState({ warning: cr < optimalContrastRatio });
  }

  revertChanges() {
    const { innerHTML, selectionData } = this.focusedElementInfo;
    this.element.innerHTML = innerHTML;
    this.selection = contentEditable.helpers.setSelectionByNodePath(this.element, selectionData);
  }

  render() {
    const { onDragEnd } = this.props;
    const { color, warning } = this.state;
    const { offsetX, offsetY } = this.dialogPosition;

    return (
      <ColorSettingsDialog
        captions={this.captions}
        position={{ offsetX, offsetY }}
        color={color}
        onColorChange={this.updateColor}
        onColorChangeComplete={this.handleColorChangeComplete}
        onCancel={this.handleCancel}
        onSubmit={this.handleSubmit}
        onDragEnd={onDragEnd}
        overlay="transparent"
        displayWarning={warning}
      />
    );
  }
}

AddCustomColorDialogContainer.propTypes = {
  elementId: PropTypes.string.isRequired,
  blockId: PropTypes.string.isRequired,
  surfaceAttribute: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  optimalContrastRatio: PropTypes.number.isRequired,
  isLight: PropTypes.bool.isRequired,
  onDragEnd: PropTypes.func,
  // Passed from ws-editor's `dialogs-container`:
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

AddCustomColorDialogContainer.defaultProps = {
  onDragEnd: utils.noop,
};

export default AddCustomColorDialogContainer;
