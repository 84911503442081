import get from 'lodash.get';
import { config } from '@yola/ws-sdk';
import getIsB2C from './is-b2c';

const getClipboardAccessInstructionUrl = (rootState) => {
  const cfg = config.selectors.getConfig(rootState);

  if (getIsB2C(rootState)) {
    return get(cfg, 'common.ws-helpcenter.clipboard_access_instruction_url');
  }

  return get(cfg, 'common.ws-helpcenter.clipboard_access_instruction_wl_url');
};

export default getClipboardAccessInstructionUrl;
