export default {
  HOME_BUSINESS: 'home_business',
  ABOUT_US: 'about_us_business',
  SERVICES: 'services_business',
  OUR_WORK: 'our_work_business',
  PRODUCTS: 'products_business',
  CONTACT_US: 'contacts_business',
  MENU: 'menu_business',
  TESTIMONIALS: 'testimonials_business',
  FAQ: 'faq_business',
  SUBPAGE_GPT: 'subpage_gpt',
};
