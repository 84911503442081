import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FloatingAction, designSystem } from '@yola/ws-ui';
import generatePresetLabel from '../helpers/generate-preset-label';
import TabContentBox from './tab-content-box';
import ColorPalettePreset from './color-palette-preset';

const { Stack, Box, Heading } = designSystem;

function ColorsSection(props) {
  const {
    palettes,
    customPalettes,
    activePaletteId,
    captions,
    setActivePalette,
    onEditPaletteClick,
    onDeletePaletteClick,
    onAddPaletteClick,
  } = props;
  const { title, customPalettesTitle, paletteLabel, customPaletteLabel, editLabel, deleteLabel } =
    captions;
  const hasCustomPalettes = customPalettes.length > 0;

  const renderPalettes = (palettesList, isCustomPalettes = false) =>
    palettesList.map((palette) => {
      const label = isCustomPalettes ? customPaletteLabel : paletteLabel;

      return (
        <ColorPalettePreset
          key={palette.id}
          id={palette.id}
          title={generatePresetLabel(label, palette.id)}
          editLabel={editLabel}
          deleteLabel={deleteLabel}
          colors={palette.colors}
          isSelected={palette.id === activePaletteId}
          isCustom={isCustomPalettes}
          onClick={setActivePalette}
          {...(onEditPaletteClick && {
            onEditClick: onEditPaletteClick,
          })}
          {...(onDeletePaletteClick && {
            onDeleteClick: onDeletePaletteClick,
          })}
        />
      );
    });

  return (
    <div className="ws-colors-section">
      <TabContentBox>
        {hasCustomPalettes && (
          <Fragment>
            <Box marginBottom="spacing-2-xs">
              <Heading type="heading-6">{customPalettesTitle}</Heading>
            </Box>
            <Stack gap="spacing-m">{renderPalettes(customPalettes, true)}</Stack>
            <Box marginTop="spacing-m" marginBottom="spacing-2-xs">
              <Heading type="heading-6">{title}</Heading>
            </Box>
          </Fragment>
        )}
        <Stack gap="spacing-m">{renderPalettes(palettes)}</Stack>
        {onAddPaletteClick && (
          <FloatingAction
            icon="plus"
            inverseOffsetOrigin
            offsetX={24}
            offsetY={70}
            onClick={onAddPaletteClick}
          />
        )}
      </TabContentBox>
    </div>
  );
}

ColorsSection.propTypes = {
  palettes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      colors: PropTypes.shape().isRequired,
      variables: PropTypes.shape().isRequired,
    })
  ).isRequired,
  customPalettes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      colors: PropTypes.shape().isRequired,
      variables: PropTypes.shape().isRequired,
    })
  ).isRequired,
  activePaletteId: PropTypes.string.isRequired,
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    customPalettesTitle: PropTypes.string.isRequired,
    paletteLabel: PropTypes.string,
    customPaletteLabel: PropTypes.string,
    editLabel: PropTypes.string.isRequired,
    deleteLabel: PropTypes.string.isRequired,
  }).isRequired,
  setActivePalette: PropTypes.func.isRequired,
  onEditPaletteClick: PropTypes.func.isRequired,
  onDeletePaletteClick: PropTypes.func.isRequired,
  onAddPaletteClick: PropTypes.func.isRequired,
};

export default ColorsSection;
