import control from './controls/control';
import constants from './constants';

const extension = {
  slug: constants.common.slug,
  title: 'WS Footer Navigation Settings',
  description: 'Extension for editing footer navigation links',
  defaults: {
    querySelector: 'ws-global[src*="footer"] ul',
  },
  ui: {
    control: [control],
  },
};

export default extension;
