import React, { useState, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { view, dialogs, blocks, hdrm } from '@yola/ws-sdk';

import withFeatureFlags from 'yola-editor/src/js/modules/feature-flags/hoc/with-feature-flags';
import onlinestore from 'src/js/modules/onlinestore';

import BlocksLibrarySidebarContainer from './blocks-library-sidebar-container';
import BlocksLibraryContentContainer from './blocks-library-content-container';

import BlocksLibraryContext from '../contexts/block-library-context';
import getAllBlocksForTarget from '../helpers/get-all-blocks-for-target';
import filterBlocksByQuery from '../helpers/filter-blocks-by-query';
import { trackCategorySelected, trackSearchPerformed } from '../helpers/blocks-library-analytics';
import useCategories from '../hooks/use-categories';
import applyBlockAttribute from '../helpers/apply-block-attribute';
import useInjectDonationsCategory from '../hooks/use-inject-donations-category';
import dialogTypes from '../../dialogs/constants/dialog-types';
import adjacentPositions from '../constants/adjacent-positions';

function BlocksLibraryContainer({
  adjacentPosition,
  targetElementId,
  featureFlags,
  onDialogMainAction,
  onDialogCancel,
}) {
  const blurTimeout = useRef(null);
  const dispatch = useDispatch();
  const isViewLoaded = useSelector(view.selectors.getLoadedStatus);

  const allBlocksList = useMemo(() => {
    if (!targetElementId || !isViewLoaded) return [];

    return getAllBlocksForTarget({
      targetElementId,
      adjacentPosition,
      featureFlags,
    });
  }, [targetElementId, adjacentPosition, featureFlags, isViewLoaded]);

  const categories = [useCategories, useInjectDonationsCategory].reduce(
    (list, handler) => handler(list),
    allBlocksList
  );
  const [activeCategory, setActiveCategory] = useState(null);
  const [submittedSearchValue, setSubmittedSearchValue] = useState('');
  const [blocksList, setBlocksList] = useState([]);

  if (!allBlocksList.length) return null;

  const onReplaceBlockSubmit = ({
    id,
    variationId,
    category,
    blockModifier,
    oldBlockElementId,
  }) => {
    const targetBlockElement = hdrm.accessors.getLiveElement(targetElementId);
    const targetBlock = blocks.accessors.getBlockByElement(targetBlockElement);

    let newTargetElementId = targetElementId;
    let newAdjacentPosition = adjacentPosition;

    if (category === targetBlock?.category && targetBlockElement.previousElementSibling) {
      newTargetElementId = view.accessors.getLiveElementId(
        targetBlockElement.previousElementSibling
      );
      newAdjacentPosition = adjacentPositions.AFTER_END;
    }

    const actions = [
      view.actions.insertBlock(
        id,
        variationId,
        newTargetElementId,
        newAdjacentPosition,
        blockModifier
      ),
      view.actions.deleteElement(oldBlockElementId),
    ];

    dispatch(view.actions.bulkViewActions(actions));
    dispatch(dialogs.actions.hide());
  };

  const onSearch = (value) => {
    if (!value && activeCategory) {
      setSubmittedSearchValue('');
      return;
    }

    if (!value && !activeCategory) {
      setBlocksList([]);
      setSubmittedSearchValue('');
      return;
    }

    if (activeCategory) setActiveCategory(null);
    setSubmittedSearchValue(value);

    const filteredBlocks = filterBlocksByQuery(value, allBlocksList).filter(
      (block) => !block.disabled
    );

    setBlocksList(filteredBlocks);
    trackSearchPerformed({
      searchTerm: value,
      searchResults: filteredBlocks.length,
    });
  };

  const onSearchValueClear = () => {
    if (!submittedSearchValue) return;

    setSubmittedSearchValue('');
    setActiveCategory(null);
    setBlocksList([]);
  };

  const preventSearchInputBlur = () => {
    if (blurTimeout.current) {
      clearTimeout(blurTimeout.current);
      blurTimeout.current = null;
    }
  };

  const onCategorySelect = (category) => {
    preventSearchInputBlur();
    setSubmittedSearchValue('');
    setActiveCategory(category);

    trackCategorySelected(category.name);

    const categoryBlocks = allBlocksList
      .filter((block) => block.category && block.category === category.name)
      .sort(
        (a, b) =>
          2 * Number(b.isPremium) + Number(b.new) - (2 * Number(a.isPremium) + Number(a.new))
      );

    setBlocksList(categoryBlocks);
  };

  const onBlockSelect = ({ block, blockAttrs }) => {
    preventSearchInputBlur();

    dispatch(dialogs.actions.hide());
    onDialogMainAction();

    const { id, variationId, category } = block;
    const blockModifiers = [];
    const isOnlineStore = id === onlinestore.constants.ids.ONLINE_STORE_BLOCK;

    if (isOnlineStore) {
      blockModifiers.push(onlinestore.helpers.injectStoreId);
    }

    if (blockAttrs) {
      Object.keys(blockAttrs).forEach((attr) => {
        blockModifiers.push((blockNode) => {
          applyBlockAttribute(blockNode, attr, blockAttrs[attr]);
        });
      });
    }

    const blockModifier = (blockNode) => {
      blockModifiers.forEach((fn) => fn(blockNode));
    };

    const redundantElements = blocks.helpers.getElementsToBeReplaced(id);

    if (!redundantElements.length) {
      dispatch(
        view.actions.insertBlock(id, variationId, targetElementId, adjacentPosition, blockModifier)
      );
      return;
    }

    // We try to cover case when there is more then one redundant block
    if (redundantElements.length > 1) {
      dispatch(
        dialogs.actions.show(dialogTypes.BLOCK_LIMIT_DIALOG, {
          onSubmit: () => {
            dispatch(dialogs.actions.hide());
          },
          blockId: id,
        })
      );
      return;
    }

    const [oldBlockElement] = redundantElements;
    const oldBlockElementId = view.accessors.getLiveElementId(oldBlockElement);
    const oldBlockId = blocks.accessors.getBlockIdByElement(oldBlockElement);
    const oldVariationId = blocks.accessors.getVariationIdByElement(oldBlockElement);

    dispatch(
      dialogs.actions.show(dialogTypes.BLOCK_REPLACEMENT_DIALOG, {
        onSubmit: () =>
          onReplaceBlockSubmit({ id, variationId, category, blockModifier, oldBlockElementId }),
        onCancel: () => {
          dispatch(dialogs.actions.hide());
        },
        oldBlock: oldBlockId,
        oldBlockVariationId: oldVariationId,
        newBlock: id,
        newBlockVariationId: variationId,
      })
    );
  };

  const onBannerSelect = (categoryName) => {
    const block = allBlocksList.find((blockItem) => blockItem.category === categoryName);

    if (!block) return;
    onBlockSelect({ block });
  };

  const onClose = () => {
    dispatch(dialogs.actions.hide());
    onDialogCancel();
  };

  const onCloseContentSectionMobile = () => {
    setSubmittedSearchValue('');
    setActiveCategory(null);
    setBlocksList([]);
  };

  const contextValue = {
    categories,
    activeCategory,
    submittedSearchValue,
    allBlocksList,
    blocksList,
    onSearch,
    onSearchValueClear,
    onCategorySelect,
    onBlockSelect,
    onBannerSelect,
    onClose,
    onCloseContentSectionMobile,
  };

  return (
    <BlocksLibraryContext.Provider value={contextValue}>
      <div className="ws-blocks-library">
        <BlocksLibrarySidebarContainer />
        <BlocksLibraryContentContainer />
      </div>
    </BlocksLibraryContext.Provider>
  );
}

BlocksLibraryContainer.propTypes = {
  adjacentPosition: PropTypes.string.isRequired,
  targetElementId: PropTypes.string.isRequired,
  featureFlags: PropTypes.shape({}).isRequired,
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

export default withFeatureFlags(['hidden_blocks', 'contact_forms_disabled'])(
  BlocksLibraryContainer
);
