import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@yola/ws-ui';
import classNames from 'classnames';

function TriggerIcon({ className, glyph, size, strokeWidth }) {
  return (
    <div className={classNames('ws-trigger-icon', className)}>
      <Icon glyph={glyph} size={size} strokeWidth={strokeWidth} />
    </div>
  );
}

TriggerIcon.propTypes = {
  className: PropTypes.string,
  glyph: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  strokeWidth: PropTypes.number,
};

TriggerIcon.defaultProps = {
  className: '',
  glyph: '',
  size: 20,
  strokeWidth: 1.3,
};

export default TriggerIcon;
