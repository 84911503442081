import registerComponent from '../helpers/register-component';
import components from '../helpers/registered-components';

const register = (componentId, Component, config) => {
  if (components.has(componentId)) {
    throw new Error(`Custom component with type "${componentId}" is already registered`);
  }

  registerComponent(componentId, Component, config);
};

export default register;
