import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { site, template } from '@yola/ws-sdk';
import segment from 'src/js/modules/analytics/segment';
import { Icon, MenuItem } from '@yola/ws-ui';
import { HIDE_SUBMENU } from '../../constants';

const {
  track,
  constants: { events },
} = segment;

function SubMenuGroup({ item, beforeItemClick }) {
  const [isOpened, setOpenedStatus] = useState(false);
  const subMenuElementRef = useRef(null);
  const { id, heading, disabled, target, type, submenu, icon } = item;
  const classes = classNames('ws-menu-item-submenu', `ws-menu-item-${id}`);

  useEffect(() => {
    const onSubMenuClose = (event) => {
      if (event.target !== subMenuElementRef.current) {
        setOpenedStatus(false);
      }
    };

    document.addEventListener(HIDE_SUBMENU, onSubMenuClose, false);

    if (isOpened) {
      track(events.APP_HELP_MENU_DISPLAYED, {
        siteId: site.accessors.getSiteId(),
        templateBuildSlug: template.accessors.getBuildSlug(),
      });
    }

    return () => {
      document.removeEventListener(HIDE_SUBMENU, onSubMenuClose, false);
    };
  }, [isOpened]);

  const handleClick = useCallback(
    (event, options) => {
      if (!options.disabled) {
        beforeItemClick();
      }
      if (options.onClick) {
        options.onClick(options.useEvent ? event : undefined);
      }
    },
    [beforeItemClick]
  );

  const handleSubMenuClick = useCallback(() => {
    if (!disabled) {
      SubMenuGroup.dispatchClose(subMenuElementRef.current);

      if (!isOpened) {
        setOpenedStatus(true);
      } else {
        setOpenedStatus(false);
      }
    }
  }, [disabled, subMenuElementRef, isOpened, setOpenedStatus]);

  return (
    <React.Fragment>
      <MenuItem
        onClick={handleSubMenuClick}
        active={isOpened}
        disabled={disabled}
        target={target}
        type={type}
        className={classes}
      >
        <span className="ws-menu-item--container">
          {icon && <Icon className="ws-menu-item--icon" {...icon} />}
          {heading}
        </span>
      </MenuItem>
      {isOpened && (
        <li ref={subMenuElementRef} className="ws-menu-item-submenu__content">
          <ul className="ws-submenu__content">
            {submenu.map((subItem, index) => (
              <MenuItem
                // eslint-disable-next-line yola/react/no-array-index-key
                key={index}
                onClick={(event) => handleClick(event, subItem)}
                href={subItem.href}
                disabled={subItem.disabled}
                target={subItem.target}
                type={subItem.type}
                className={subItem.id ? `ws-menu-item-${subItem.id}` : ''}
              >
                {!subItem.href ? (
                  <span className="ws-menu-item--container">
                    {subItem.icon && <Icon className="ws-menu-item--icon" {...subItem.icon} />}
                    {subItem.heading}
                  </span>
                ) : (
                  <React.Fragment>
                    {subItem.icon && <Icon className="ws-menu-item--icon" {...subItem.icon} />}
                    {subItem.heading}
                  </React.Fragment>
                )}
              </MenuItem>
            ))}
          </ul>
        </li>
      )}
    </React.Fragment>
  );
}

SubMenuGroup.dispatchClose = (target) => {
  const HIDE_SUBMENU_EVENT = new Event(HIDE_SUBMENU);
  document.dispatchEvent(HIDE_SUBMENU_EVENT, target);
};

SubMenuGroup.propTypes = {
  item: PropTypes.object.isRequired,
  beforeItemClick: PropTypes.func.isRequired,
};

export default SubMenuGroup;
