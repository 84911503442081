import { hdrm, template, view } from '@yola/ws-sdk';
import store from 'src/js/store';
import getMaxPaneSize from './get-max-pane-size';
import getPageContainerOffset from '../../utils/helpers/get-page-container-offset';
import getNavbarHeight from '../../navbar/accessors/get-height';

const PANE_INDENT = 12;

const getPaneLocationForBlock = ({ element, items, scrollPosition, viewportWidth }) => {
  const state = store.getState();

  const pageContainerSelector = template.selectors.getPageContainerSelector(state);
  const pageContainerOffset = getPageContainerOffset(pageContainerSelector);
  const navbarHeight = getNavbarHeight();

  const elementStyles = hdrm.accessors.getElementStyles(element);
  let { elTop } = elementStyles;
  const { elRight } = elementStyles;

  // we use bounds of prev element, if it overlaps target one
  // (for ex., header that overlays next block)
  const { referenceMap } = hdrm.instance.driver;
  const prevElementId = referenceMap.getPreviousReferenceId(element);
  const prevElement = view.accessors.getLiveElement(prevElementId);

  if (prevElement) {
    const prevElementStyles = hdrm.accessors.getElementStyles(prevElement);
    const prevElementBottom = prevElementStyles.elTop + prevElementStyles.elHeight;
    if (prevElementBottom > elTop) {
      elTop = prevElementBottom;
    }
  }

  const elTopToPageStart = elTop + scrollPosition - navbarHeight;

  const isElementTopUnderNavbar = elTop - navbarHeight - pageContainerOffset >= 0;

  const { maxPaneWidth } = getMaxPaneSize(items, viewportWidth);

  let positionY;
  let offsetY;

  const positionX = 'elRight';
  const offsetX = elRight - maxPaneWidth - PANE_INDENT;
  const direction = 'none';
  const dropdown = 'bottom-left';
  const expand = 'down';

  if (isElementTopUnderNavbar) {
    positionY = 'elTop';
    offsetY = elTopToPageStart + PANE_INDENT;
  } else {
    positionY = 'elTopStickScroll';
    offsetY = scrollPosition + PANE_INDENT;
  }

  return {
    offsetX,
    offsetY,
    positionX,
    positionY,
    direction,
    dropdown,
    expand,
    maxPaneWidth,
  };
};

export default getPaneLocationForBlock;
