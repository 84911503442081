import { view } from '@yola/ws-sdk';
import actionTypes from '../constants/action-types';
import editor from '../../editor';

const editorVisibilityHandler = (store) => (next) => (action) => {
  if (action.type === actionTypes.SHOW) {
    store.dispatch(editor.actions.hideEditor());
  }

  if (action.type === actionTypes.HIDE) {
    store.dispatch(view.actions.forceReloadView());
    store.dispatch(view.actions.setScrollPosition(0));
    store.dispatch(editor.actions.showEditor());
  }

  next(action);
};

export default editorVisibilityHandler;
