import React from 'react';
import PropTypes from 'prop-types';
import { FloatingAction } from '@yola/ws-ui';
import { i18next, view } from '@yola/ws-sdk';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';

const GUIDE_HEIGHT = 2;

const BlockFloatingTrigger = (props) => {
  const {
    targetElementId,
    adjacentPosition,
    position,
    direction,
    onMouseEnter,
    onMouseLeave,
    showDialog,
  } = props;
  const { x: offsetX, y: offsetY, stick } = position;

  const element = view.accessors.getLiveElement(targetElementId);

  const showBlocksDialog = () => {
    showDialog(dialogTypes.ADD_BLOCK, { targetElementId, adjacentPosition });
  };

  const guideWidth = element.offsetWidth;
  const guideOffsetY = offsetY - GUIDE_HEIGHT / 2;
  const guideOffsetX = offsetX - guideWidth / 2;

  // If the targetElementId get changed
  // Floating Action should be replaced by the new one
  const key = targetElementId;

  return (
    <div
      className="ws-floating-trigger-wrapper"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div
        key={`${key}-guide`}
        className="ws-floating-trigger-guide"
        style={{
          top: guideOffsetY,
          left: guideOffsetX,
          width: guideWidth,
          height: GUIDE_HEIGHT,
        }}
      />
      <FloatingAction
        key={`${key}-action`}
        icon="plus"
        offsetX={offsetX}
        offsetY={offsetY}
        direction={direction}
        stick={stick}
        tooltip={i18next.t('Add block')}
        onClick={showBlocksDialog}
      />
    </div>
  );
};

BlockFloatingTrigger.propTypes = {
  targetElementId: PropTypes.string.isRequired,
  adjacentPosition: PropTypes.string.isRequired,
  position: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    stick: PropTypes.string,
  }),
  direction: PropTypes.string,
  showDialog: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

BlockFloatingTrigger.defaultProps = {
  position: {},
  direction: null,
  onMouseEnter: Function.prototype,
  onMouseLeave: Function.prototype,
};

export default BlockFloatingTrigger;
