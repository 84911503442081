import { constraints, extensions } from '@yola/ws-sdk';
import matchBlockElement from '../../common/helpers/match-block-element';
import {
  CONSTRAINT,
  CONSTRAINT_FOR_SELECTED,
  EXTENSION,
  SETTING,
  CUSTOM_TOOL,
  CONTEXT_MENU,
  ELEMENT_SETTINGS,
} from '../constants/item-types';
import websiteSettings from '../../website-settings';
import customTools from '../../custom-tools';
import contextMenu from '../../context-menu';
import elementSettings from '../../element-settings';

const addInteractiveElement = (collection, element) => {
  if (matchBlockElement(element)) {
    return;
  }

  if (elementSettings.verifiers.isElementMatchSettings(element)) {
    collection.push({
      element,
      type: ELEMENT_SETTINGS,
    });
  }

  if (
    constraints.verifiers.canBeSelected(element) &&
    constraints.verifiers.canBeInteracted(element)
  ) {
    collection.push({
      element,
      type: CONSTRAINT_FOR_SELECTED,
    });
  } else if (constraints.verifiers.canBeInteracted(element)) {
    collection.push({
      element,
      type: CONSTRAINT,
    });
  } else if (extensions.helpers.getExtensionControlsForElement(element).length) {
    collection.push({
      element,
      type: EXTENSION,
    });
  } else if (websiteSettings.verifiers.doesElementMatchSettings(element)) {
    collection.push({
      element,
      type: SETTING,
    });
  } else if (customTools.filters.findAllFor(element).length) {
    collection.push({
      element,
      type: CUSTOM_TOOL,
    });
  }

  if (contextMenu.verifiers.isInteractiveElement(element)) {
    collection.push({
      element,
      type: CONTEXT_MENU,
    });
  }
};

export default addInteractiveElement;
