import blocks from 'src/js/modules/blocks';
import hasBackgroundContainer from './has-background-container';
import isImageContainer from './is-image-container';
import { getSettings } from '../settings';

const getImageContainerNode = (node) => {
  const settings = getSettings();
  if (blocks.verifiers.isWsBlock(node) || hasBackgroundContainer(node)) {
    return node.querySelector(settings.backgroundContainer);
  }

  if (isImageContainer(node)) {
    return node;
  }

  return null;
};

export default getImageContainerNode;
