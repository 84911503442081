import { blocks, site, template } from '@yola/ws-sdk';
import store from 'src/js/store';
import getPageContainer from 'src/js/modules/utils/helpers/get-page-container';
import adjacentPositions from '../constants/adjacent-positions';

const { BEFORE_END, AFTER_END } = adjacentPositions;

const getUsedBlocks = (state) => {
  const activePageId = site.selectors.getActivePageId(state);

  return site.selectors.getUsedBlocks(state, activePageId);
};

const getEmptyPageTargetElementAndPosition = () => {
  const state = store.getState();

  const pageContainerSelector = template.selectors.getPageContainerSelector(state);
  const element = getPageContainer(pageContainerSelector);
  const usedBlocks = getUsedBlocks(state);

  let targetElement = element;
  let adjacentPosition = BEFORE_END;

  if (usedBlocks.length > 1) {
    targetElement = element.querySelector(`[id^=${blocks.constants.BLOCK_ID_PREFIX}-]`);
    adjacentPosition = AFTER_END;
  }

  return {
    targetElement,
    adjacentPosition,
  };
};

export default getEmptyPageTargetElementAndPosition;
