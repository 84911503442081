const DELTA = 20;

const isEqualBottomBounds = (bounds, testBounds) =>
  Math.abs(bounds.bottom - testBounds.bottom) < DELTA;

const isEqualBounds = (bounds, testBounds, isBottomConsidered = false) => {
  const isBottomEqual = isBottomConsidered ? isEqualBottomBounds(bounds, testBounds) : true;

  return (
    Math.abs(bounds.top - testBounds.top) < DELTA &&
    Math.abs(bounds.left - testBounds.left) < DELTA &&
    Math.abs(bounds.right - testBounds.right) < DELTA &&
    isBottomEqual
  );
};

export default isEqualBounds;
export { isEqualBottomBounds };
