import convertChangedOptionToOperation from './convert-changed-option-to-operation';
import convertColorSchemeOptionsToOperations from './convert-color-scheme-options-to-operations';

const getAttributesOperations = (element, changedOptions, displayOptions) => {
  const colorSchemeOperations = convertColorSchemeOptionsToOperations(element, changedOptions);

  const operations = changedOptions.reduce((accOperations, option) => {
    let operation = convertChangedOptionToOperation(option, element);

    if (!operation) {
      return accOperations;
    }

    const optionWithSameAttribute = displayOptions.find(
      ({ attribute, id }) => option.attribute === attribute && option.id !== id
    );

    if (optionWithSameAttribute) {
      operation = {
        ...operation,
        value: `${operation.value} ${optionWithSameAttribute.value}`,
      };
    }

    const isOperationAlreadyExists = accOperations.some(
      (accOperation) =>
        accOperation.name === operation.name &&
        accOperation.elementId === operation.elementId &&
        accOperation.operation === operation.operation
    );

    if (isOperationAlreadyExists) {
      return accOperations;
    }

    return [...accOperations, operation];
  }, []);

  return [...operations, ...colorSchemeOperations];
};

export default getAttributesOperations;
