import { textFormatting, anodum } from '@yola/ws-sdk';

const { wrappersTypes } = textFormatting.constants;
const { className, tagName } = wrappersTypes.link;

const wsLinkSelector = `${tagName}.${className}`;

const getLeftoverLinkText = (node, offset, isBeforeSelection) => {
  if (anodum.isTextNode(node) && node.parentElement.matches(wsLinkSelector)) {
    return isBeforeSelection ? node.data.slice(0, offset) : node.data.slice(offset);
  }
  return '';
};

const getInitialTitle = () => {
  const selection = textFormatting.accessors.getAdvancedSelection();
  const { anchorNode, anchorOffset, focusNode, focusOffset, isCollapsed } = selection;

  if (isCollapsed) {
    const node = anodum.isTextNode(anchorNode) ? anchorNode.parentElement : anchorNode;
    const linkElement = node ? node.closest(wsLinkSelector) : null;

    return linkElement ? linkElement.innerText : '';
  }

  const beforeSelectionLeftoverLinkText = selection.isBackwards()
    ? getLeftoverLinkText(focusNode, focusOffset, true)
    : getLeftoverLinkText(anchorNode, anchorOffset, true);

  const afterSelectionLeftoverLinkText = selection.isBackwards()
    ? getLeftoverLinkText(anchorNode, anchorOffset, false)
    : getLeftoverLinkText(focusNode, focusOffset, false);

  return beforeSelectionLeftoverLinkText + selection.toString() + afterSelectionLeftoverLinkText;
};

export default getInitialTitle;
