import { view } from '@yola/ws-sdk';
import store from '../../../store';

const isFocusedElement = (element) => {
  const state = store.getState();
  const focusedElementId = view.selectors.getFocusedElement(state);
  const elementId = view.accessors.getLiveElementId(element);

  return elementId === focusedElementId;
};

export default isFocusedElement;
