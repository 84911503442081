import React from 'react';
import PropTypes from 'prop-types';
import {
  site,
  dialogs,
  hdrm,
  contentEditable,
  view,
  i18next,
  linkEditing,
  textFormatting,
} from '@yola/ws-sdk';
import bowser from 'yola-bowser';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import LinkSettingContainer from 'src/js/modules/common/containers/link-setting-container';
import helpers from '../helpers';
import isLinkSelected from '../verifiers/is-link-selected';
import isLinkDeletable from '../verifiers/is-link-deletable';
import commonHelpers from '../../../common/helpers';

const CONTROL_PANE_RERENDER_TIMEOUT = 10;
const noop = Function.prototype;

const { deleteLink, updateLink, getInitialTitle, restoreRange } = helpers;
const { getItemClickAction } = commonHelpers;

const { DISABLE_BLUR_ATTR } = hdrm.constants.attributes;
const { linkTypes } = linkEditing.constants;

class LinkFormattingDialog extends React.Component {
  constructor(props) {
    super(props);

    const { initialConfig } = props;
    this.element = view.accessors.getLiveElement(props.elementId);
    this.state = {
      isOverlayClicked: false,
    };
    const node = helpers.getFirstLinkElementFromSelection();
    this.config = initialConfig || linkEditing.accessors.getLinkConfig(node);
    this.innerText = getInitialTitle();
    this.activePageId = site.accessors.getActivePageId();

    this.onSubmit = this.onSubmit.bind(this);
    this.onDeleteLink = this.onDeleteLink.bind(this);
    this.onCloseDialog = this.onCloseDialog.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.setIsOverlayClicked = this.setIsOverlayClicked.bind(this);
  }

  onDeleteLink() {
    // Code below fixes bugs https://github.com/yola/production/issues/9391 and https://github.com/yola/production/issues/7872
    // In Safari when we're clicking on dialog, element loses focus and we should focus it manually,
    // in order to keep proper selection for deleteLink();
    if (bowser.safari) {
      this.element.setAttribute('contenteditable', 'true');
      this.element.focus();
    }
    deleteLink();
    contentEditable.operations.addCheckpoint();
    this.onCloseDialog();
  }

  onCloseDialog(callback = noop) {
    dialogs.operations.hide();
    // When we're clicking on dialog element loses focus and we should focus it manually
    // we should also wait for Control Pane to re-render as otherwise user won't be able to type text
    setTimeout(() => {
      this.element.setAttribute('contenteditable', 'true');
      this.element.focus();
      callback();
    }, CONTROL_PANE_RERENDER_TIMEOUT);
  }

  onSubmit({ config, title, customPath, setDownloadUrlAdjusted }) {
    const { elementId, onDialogMainAction } = this.props;
    const { isOverlayClicked } = this.state;
    const selection = textFormatting.accessors.getAdvancedSelection();
    onDialogMainAction({
      elementId,
      triggerId: isOverlayClicked ? 'dialog-background' : 'submit-button',
      itemClickAction: getItemClickAction(config.linkType),
      itemTitleAdjusted: title !== this.innerText,
      dialogType: dialogTypes.LINK_FORMATTING_DIALOG,
      addedFile: config.linkType === linkTypes.DOWNLOAD,
      setDownloadUrlAdjusted,
      customPath,
    });

    const payload = {
      config,
    };

    if (title !== this.innerText) {
      payload.title = title;
    }

    try {
      restoreRange(selection, elementId);
    } catch (error) {
      console.error(error);
    }

    updateLink(payload, selection);
    contentEditable.operations.addCheckpoint();
    this.onCloseDialog();
  }

  setIsOverlayClicked(value) {
    this.setState({
      isOverlayClicked: value,
    });
  }

  handleCancel() {
    const { onDialogCancel } = this.props;

    onDialogCancel();
    this.onCloseDialog(() => {
      view.operations.triggerSelectionUpdate();
    });
  }

  render() {
    const { initialTitle, elementId, currentConfig } = this.props;

    return (
      <LinkSettingContainer
        showTitle={Boolean(this.innerText)}
        initialTitle={initialTitle || this.innerText}
        initialConfig={this.config}
        currentConfig={currentConfig}
        showDeleteButton={isLinkSelected() && isLinkDeletable()}
        modalAttributes={{ [DISABLE_BLUR_ATTR]: true }}
        element={this.element}
        handleDeleteLink={this.onDeleteLink}
        sourceDialog={{
          id: dialogTypes.LINK_FORMATTING_DIALOG,
          props: {
            elementId,
          },
        }}
        handleCancel={this.handleCancel}
        handleSubmit={this.onSubmit}
        setIsOverlayClicked={this.setIsOverlayClicked}
        captions={{
          dialogTitle: i18next.t('Link settings'),
          title: i18next.t('Title'),
          actionTitle: i18next.t('Click action'),
          actionSubtitle: i18next.t('When user clicks on the link, take this action'),
          titleAnnotation: i18next.t('Display this text inside the link'),
          save: i18next.t('Submit'),
        }}
      />
    );
  }
}

LinkFormattingDialog.propTypes = {
  elementId: PropTypes.string.isRequired,
  initialConfig: PropTypes.shape({}),
  currentConfig: PropTypes.shape({}),
  initialTitle: PropTypes.string,
  // Passed from ws-editor's `dialogs-container`:
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

LinkFormattingDialog.defaultProps = {
  initialTitle: null,
  initialConfig: null,
  currentConfig: null,
};

export default LinkFormattingDialog;
