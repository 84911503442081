import React from 'react';
import { Tooltip, Render } from '@yola/ws-ui';
import bowser from 'yola-bowser';

function TooltipContainer() {
  const isMobile = bowser.mobile || bowser.tablet;

  return (
    <Render if={!isMobile}>
      <Tooltip />
    </Render>
  );
}

export default TooltipContainer;
