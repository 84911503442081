import stickTypes from '../constants/stick-types';
import { ADD_BLOCKS_BTN_HEIGHT, ADD_WIDGET_BTN_HEIGHT, CONTROLS_GAP } from '../constants/sizes';

const TWO_TRIGGERS_MIN_DISTANCE =
  (ADD_BLOCKS_BTN_HEIGHT + ADD_WIDGET_BTN_HEIGHT) / 2 + CONTROLS_GAP;

const areTriggersInConflict = (firstTriggerPos, secondTriggerPos) => {
  if (Math.abs(firstTriggerPos.x - secondTriggerPos.x) >= TWO_TRIGGERS_MIN_DISTANCE) {
    return false;
  }

  if (Math.abs(firstTriggerPos.y - secondTriggerPos.y) < TWO_TRIGGERS_MIN_DISTANCE) {
    return true;
  }

  if (
    firstTriggerPos.stick !== stickTypes.NONE &&
    firstTriggerPos.stick === secondTriggerPos.stick
  ) {
    return true;
  }

  return false;
};

export default areTriggersInConflict;
