import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { site, dialogs, i18next, serviceClient, i18n } from '@yola/ws-sdk';
import { reactHookForm } from '@yola/ws-ui';
import useFeatureFlags from 'yola-editor/src/js/modules/feature-flags/hooks/use-feature-flags';
import websiteSettings from '../../website-settings';
import { saveSiteWithEffect } from '../../saving/helpers/save-site';
import AiOnboardingDialog from '../components/ai-onboarding-dialog';
import segment from '../../analytics/segment';
import constants from '../constants';

const { useForm } = reactHookForm;
const {
  constants: { events },
  track,
} = segment;
const { sourceIds } = constants;
const {
  constants: { settingTypes, settingLengthLimits },
  helpers: { getFieldValuesFromSettings, getSettingValueFromFieldValue },
} = websiteSettings;
const { SITE_NAME, BUSINESS_NAME, BUSINESS_DESCRIPTION, BUSINESS_CATEGORY } = settingTypes;

const getCaptions = () => ({
  description: i18next.t(
    'To create customized content for your business, enter your business details.'
  ),
  descriptionWithBusinessInfo: i18next.t(
    'You can update this information at any time under {pathToBusinessInfo}.',
    {
      pathToBusinessInfo: `<strong>${i18next.t('Settings')} &#x2192; ${i18next.t(
        'Business Info'
      )}</strong>`,
    }
  ),
  buttonLabel: i18next.t('Submit & Generate'),
});

const getFields = ({ control, siteName, isBusinessTaxonomyEnabled }) => {
  const errorMessages = {
    required: i18next.t('This field needs your input'),
  };

  return {
    businessName: {
      label: i18next.t('Business name'),
      checkboxLabel: i18next.t('Same as website name'),
      name: BUSINESS_NAME,
      placeholder: siteName,
      control,
      rules: {
        required: errorMessages.required,
      },
    },
    businessDescription: {
      label: i18next.t('What does your business do?'),
      name: BUSINESS_DESCRIPTION,
      placeholder: i18next.t(
        'Alex & Johnson - is a real estate investment and development company based in Georgia. We specialize in acquiring, managing, and maximizing the value of residential properties.'
      ),
      statusText: i18next.t('Describe your business or project in a sentence or two.'),
      control,
      rules: {
        required: errorMessages.required,
        minLength: {
          value: settingLengthLimits.MIN_BUSINESS_DESCRIPTION_CHARS,
          message: i18next.t(`Please provide a description with {number}+ characters`, {
            number: settingLengthLimits.MIN_BUSINESS_DESCRIPTION_CHARS,
          }),
        },
        maxLength: {
          value: settingLengthLimits.MAX_BUSINESS_DESCRIPTION_CHARS,
          message: i18next.t(`Maximum number of characters {number}`, {
            number: settingLengthLimits.MAX_BUSINESS_DESCRIPTION_CHARS,
          }),
        },
      },
    },
    businessCategory: isBusinessTaxonomyEnabled
      ? {
          label: i18next.t('Business category'),
          placeholder: i18next.t('E.g. Cleaning Company'),
          noResultsText: i18next.t('No result'),
          name: BUSINESS_CATEGORY,
          control,
          rules: {
            required: errorMessages.required,
          },
        }
      : null,
  };
};

const AiOnboardingContainer = ({ sourceId, title, onDialogMainAction, onDialogCancel }) => {
  const dispatch = useDispatch();
  const siteSettings = useSelector(site.selectors.getSettings);
  const [featureFlags] = useFeatureFlags([
    'business_info_in_website_settings',
    'business_taxonomy',
  ]);
  const {
    business_info_in_website_settings: isBusinessInfoInWebsiteSettingsEnabled,
    business_taxonomy: isBusinessTaxonomyEnabled,
  } = featureFlags;

  const settingFieldValues = getFieldValuesFromSettings(siteSettings);
  const defaultValues = {
    [BUSINESS_NAME]: settingFieldValues[BUSINESS_NAME] || settingFieldValues[SITE_NAME],
    [BUSINESS_DESCRIPTION]: settingFieldValues[BUSINESS_DESCRIPTION],
    [BUSINESS_CATEGORY]: settingFieldValues[BUSINESS_CATEGORY]
      ? {
          value: settingFieldValues[BUSINESS_CATEGORY],
        }
      : null,
  };
  const { control, formState, trigger, getValues, setValue } = useForm({
    defaultValues,
    mode: 'onSubmit',
  });
  const { isValid, dirtyFields } = formState;
  const siteId = site.accessors.getSiteId();
  const fields = getFields({
    control,
    siteName: settingFieldValues[SITE_NAME],
    isBusinessTaxonomyEnabled,
  });

  const handleBusinessCategoryChange = (option) => {
    setValue(BUSINESS_CATEGORY, option);
    track(events.BUSINESS_CATEGORY_SELECTED, {
      siteId,
      stepId: null,
      businessCategory: option.value,
      archetypeId: option.archetype,
      source: sourceIds.AI_ONBOARDING,
    });
  };

  useEffect(() => {
    const getCategoryBySlug = async (slug) => {
      try {
        const client = serviceClient.get();
        const locale = i18n.accessors.getLocale();
        const { data } = await client.taxonomyGetCategory({ slug, locale });

        setValue(BUSINESS_CATEGORY, {
          label: data.title,
          value: data.category,
          archetype: data.archetypes[0],
        });
      } catch (error) {
        console.error(error);
      }
    };

    if (settingFieldValues[BUSINESS_CATEGORY] && isBusinessTaxonomyEnabled) {
      getCategoryBySlug(settingFieldValues[BUSINESS_CATEGORY]);
    }
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  const [isBusinessNameSameAsSiteName, setIsBusinessNameSameAsSiteName] = useState(
    defaultValues[BUSINESS_NAME] === settingFieldValues[SITE_NAME]
  );

  const captions = getCaptions();
  const description = isBusinessInfoInWebsiteSettingsEnabled
    ? `${captions.description} ${captions.descriptionWithBusinessInfo}`
    : captions.description;

  const handleBusinessCategoryFocus = () => {
    const businessCategory = getValues(BUSINESS_CATEGORY) && getValues(BUSINESS_CATEGORY).value;

    track(events.BUSINESS_CATEGORY_INPUT_FOCUSED, {
      siteId,
      stepId: null,
      source: sourceIds.AI_ONBOARDING,
      businessCategory,
    });
  };

  const handleSubmit = () => {
    if (!isValid) {
      trigger();
      return;
    }

    const businessName = getValues(BUSINESS_NAME);
    const businessDescription = getValues(BUSINESS_DESCRIPTION);
    const businessCategory = getValues(BUSINESS_CATEGORY) && getValues(BUSINESS_CATEGORY).value;
    const updatedFields = [
      ...(settingFieldValues[BUSINESS_NAME] !== businessName ? [BUSINESS_NAME] : []),
      ...Object.keys(dirtyFields),
    ];
    const settingsToUpdate = {};

    if (updatedFields.includes(BUSINESS_NAME)) {
      settingsToUpdate[BUSINESS_NAME] = getSettingValueFromFieldValue(BUSINESS_NAME, businessName);
    }

    if (updatedFields.includes(BUSINESS_DESCRIPTION)) {
      settingsToUpdate[BUSINESS_DESCRIPTION] = getSettingValueFromFieldValue(
        BUSINESS_DESCRIPTION,
        businessDescription
      );
    }

    if (updatedFields.includes(BUSINESS_CATEGORY)) {
      settingsToUpdate[BUSINESS_CATEGORY] = getSettingValueFromFieldValue(
        BUSINESS_CATEGORY,
        businessCategory
      );
    }

    if (Object.keys(settingsToUpdate).length) {
      dispatch(site.actions.updateSettings(settingsToUpdate, true, true));
      dialogs.operations.hide();
      saveSiteWithEffect(() => {
        onDialogMainAction({
          businessName,
          businessDescription,
          sourceId,
          businessCategory,
        });
      });
      return;
    }

    onDialogMainAction({
      businessName,
      businessDescription,
      sourceId,
      businessCategory,
    });
  };

  const handleClose = () => {
    onDialogCancel({
      sourceId,
    });
    dialogs.operations.hide();
  };

  const handleBusinessNameChange = (value) => {
    if (value !== settingFieldValues[SITE_NAME]) {
      setIsBusinessNameSameAsSiteName(false);
    }
  };

  const handleBusinessNameAsSiteNameChange = (value) => {
    if (value) {
      setValue(BUSINESS_NAME, settingFieldValues[SITE_NAME], { shouldValidate: true });
    }

    setIsBusinessNameSameAsSiteName(value);
  };

  return (
    <AiOnboardingDialog
      fields={fields}
      captions={{
        title,
        description,
        buttonLabel: captions.buttonLabel,
      }}
      isBusinessNameSameAsSiteName={isBusinessNameSameAsSiteName}
      onBusinessNameChange={handleBusinessNameChange}
      onBusinessCategoryChange={handleBusinessCategoryChange}
      onBusinessCategoryFocus={handleBusinessCategoryFocus}
      onSameAsSiteNameChange={handleBusinessNameAsSiteNameChange}
      onSubmit={handleSubmit}
      onClose={handleClose}
    />
  );
};

AiOnboardingContainer.propTypes = {
  sourceId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
  onDialogMainAction: PropTypes.func.isRequired,
};

export default AiOnboardingContainer;
