import React, { createContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import constants from '../constants/common';

const { DEFAULT_SCALE, DEFAULT_ZOOM } = constants;

const FocalPointContext = createContext();
const { Provider, Consumer } = FocalPointContext;

function FocalPointProvider({ children }) {
  const [position, setPosition] = useState({ x: null, y: null });
  const [scale, setScale] = useState(DEFAULT_SCALE);
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const [imageBounds, setImageBounds] = useState(null);
  const [currentSource, setCurrentSource] = useState(null);
  const initialData = useRef({
    scale: DEFAULT_SCALE,
    position: { x: null, y: null },
    source: '',
    height: null,
    width: null,
    isMigrated: false,
  });
  const imageData = useRef({
    width: null,
    height: null,
    source: '',
    thumbnailSource: '',
    file: null,
  });
  const controlsRef = useRef(null);
  const isLoadingImage = useRef(false);

  return (
    <Provider
      value={{
        position,
        setPosition,
        scale,
        zoom,
        setScale,
        setZoom,
        imageBounds,
        initialData,
        imageData,
        setImageBounds,
        controlsRef,
        isLoadingImage,
        currentSource,
        setCurrentSource,
      }}
    >
      {children}
    </Provider>
  );
}

FocalPointProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { FocalPointProvider, Consumer as FocalPointConsumer };
export default FocalPointContext;
