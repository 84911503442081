import React from 'react';
import PropTypes from 'prop-types';
import { i18next } from '@yola/ws-sdk';
import WizardConfirmExitDialog from '../components/wizard-confirm-exit-dialog';

const getCaptions = () => ({
  title: i18next.t('Confirm Exit'),
  description: i18next.t(
    'This will cancel the site generation process. Are you sure you want to exit?'
  ),
  submitLabel: i18next.t('Submit'),
  cancelLabel: i18next.t('Cancel'),
});

const SiteWizardConfirmExitContainer = ({ onDialogMainAction, onDialogCancel }) => {
  const captions = getCaptions();

  const handleSubmit = () => {
    onDialogMainAction();
  };

  const handleCancel = () => {
    onDialogCancel();
  };

  return (
    <WizardConfirmExitDialog captions={captions} onSubmit={handleSubmit} onCancel={handleCancel} />
  );
};

SiteWizardConfirmExitContainer.propTypes = {
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

export default SiteWizardConfirmExitContainer;
