import React from 'react';
import { Icon } from '@yola/ws-ui';
import Trigger from 'src/js/modules/common/components/trigger';
import { extensions, dialogs, i18next } from '@yola/ws-sdk';
import trackers from 'src/js/modules/analytics/segment/trackers';
import constants from '../constants/common';
import ModalContainer from '../components/modal-container';

const { trackGoogleMapEmbedSearchClicked, trackGoogleMapEmbedSearchPerformed } = trackers;

dialogs.operations.register(constants.MODAL_TYPE, ModalContainer);

const triggerId = 'google-map-embed';

const control = {
  id: 'google-map-embed',
  trigger: (
    <Trigger id={triggerId}>
      <Icon glyph="map-pin" size="24" strokeWidth="1.3" />
    </Trigger>
  ),
  onTriggerClick: (elementId) => {
    const handleSearchClick = () => {
      trackGoogleMapEmbedSearchClicked({
        targetElementId: elementId,
      });
    };

    const handleSearchPerform = (searchTerm, searchResults) => {
      trackGoogleMapEmbedSearchPerformed({
        targetElementId: elementId,
        searchTerm,
        searchResults,
      });
    };

    dialogs.operations.show(constants.MODAL_TYPE, {
      elementId,
      onSearchClick: handleSearchClick,
      onSearchPerform: handleSearchPerform,
    });
  },
  get title() {
    return i18next.t('Google Maps Settings');
  },

  get tooltip() {
    return i18next.t('Google Maps Settings');
  },
  matches(liveElement) {
    const settings = extensions.accessors.getExtensionSettings(constants.slug);
    return liveElement.matches(settings.querySelector);
  },
  priority: 100,
};

export default control;
