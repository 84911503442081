import actionTypes from '../constants/action-types';
import redirectToStore from '../helpers/redirect-to-store';

const storeRedirect = () => (next) => (action) => {
  if (actionTypes.REDIRECT_TO_STORE === action.type) {
    redirectToStore();
  }

  next(action);
};

export default storeRedirect;
