import { i18next } from '@yola/ws-sdk';
import pageTypes from '../constants/page-types';

function getOptionsToCreatePage(filterFn = () => true) {
  return [
    {
      name: i18next.t('Empty page'),
      id: pageTypes.EMPTY_PAGE,
    },
    {
      name: i18next.t('Home'),
      id: pageTypes.HOME,
    },
    {
      name: i18next.t('About us'),
      id: pageTypes.ABOUT_US,
    },
    {
      name: i18next.t('Services'),
      id: pageTypes.SERVICES,
    },
    {
      name: i18next.t('Products'),
      id: pageTypes.PRODUCTS,
    },
    {
      name: i18next.t('Menu'),
      id: pageTypes.MENU,
    },
    {
      name: i18next.t('Our work'),
      id: pageTypes.OUR_WORK,
    },
    {
      name: i18next.t('Contact us'),
      id: pageTypes.CONTACT_US,
    },
    {
      name: i18next.t('Testimonials'),
      id: pageTypes.TESTIMONIALS,
    },
    {
      name: i18next.t('FAQ'),
      id: pageTypes.FAQ,
    },
  ].filter(filterFn);
}

export default getOptionsToCreatePage;
