import control from './controls/control';
import constants from './constants';

const extension = {
  slug: constants.common.slug,
  title: 'WS Slider Customizer',
  description: 'Extension for editing slider',
  defaults: {
    querySelector: '[id*="ws-block-slider-gallery"] ws-media-container[origin-src]',
    sliderGalleryQuerySelector: 'ws-slider',
  },
  ui: {
    control: [control],
  },
};

export default extension;
