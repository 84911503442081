import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Semaphore } from 'async-await-semaphore';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import bowser from 'yola-bowser';
import { view, utils } from '@yola/ws-sdk';
import urlJoin from 'url-join';
import iframeCSS from '../css/iframe.css';
import PortalToIframe from './portal-to-iframe';
import IframeContext from '../contexts/iframe-context';
import getForcedDeviceWidth from '../helpers/get-forced-device-width';
import buildDependenciesHTML from '../helpers/build-dependencies-html';
import {
  PAGE_WRAPPER_CLASSNAME,
  DATA_WS_DEPENDENCY_ATTRIBUTE,
  DEBOUNCE_DELAY,
} from '../constants/common';
import {
  DEFAULT_IFRAME_WIDTH,
  DEFAULT_IFRAME_HEIGHT,
  DEFAULT_IFRAME_SCALE,
  FORCED_DESKTOP_WIDTH,
  ASPECT_RATIO,
} from '../constants/sizes';
import extractLiveDocumentAssents from '../helpers/extract-live-document-assents';
import compileLivePreviewHeader from '../helpers/compile-live-preview-header';
import compileLivePreviewFrameHtml from '../helpers/compile-live-preview-frame-html';
import calculateFrameSize from '../helpers/calculate-frame-size';
import compilePreloadedEntities from '../helpers/compile-preloaded-entities';
import { scrolledElementType } from '../constants/live-preview';

const intersectionObserverOptions = {
  root: null,
  rootMargin: '0px 0px 0px 0px',
  threshold: [0, 1],
};

const THROTTLE_DELAY = 600;

function ViewWithStaticAssets({
  children,
  dependencies,
  staticAssets,
  onScroll,
  forcedWidth,
  forcedScale,
  scrolledElement,
  customization,
  onViewReady,
  className,
  forcedColorPaletteId,
  forcedFontPairId,
}) {
  const forcedScrollWorkaround = scrolledElement === scrolledElementType.WRAPPER;
  const isMobileAndroid = bowser.android && bowser.mobile;
  const shouldApplyScrollWorkaround = isMobileAndroid || forcedScrollWorkaround;
  const iframe = useRef(null);
  const iframeContainer = useRef(null);
  const [isReady, setIsReady] = useState(false);

  const [iframeSize, setIframeSize] = useState({
    width: DEFAULT_IFRAME_WIDTH,
    height: DEFAULT_IFRAME_HEIGHT,
    scale: DEFAULT_IFRAME_SCALE,
  });
  const [iframeContentHeight, setIframeContentHeight] = useState(DEFAULT_IFRAME_HEIGHT);
  const oldIframeHeight = useRef(0);

  const resizeObserver = useRef(null);
  const resizeObserverHandler = useRef(null);

  const intersectionObserver = useRef(null);
  const intersectionObserverHandler = useRef(null);
  const semaphoreInstance = useRef(null);

  const fitIframe = () => {
    if (!iframe.current) return;
    const forcedDeviceWidth = forcedWidth || getForcedDeviceWidth();
    const { width, height, scale } = calculateFrameSize(
      iframeContainer.current,
      forcedWidth,
      forcedScale
    );

    setIframeSize({
      width,
      height,
      scale,
    });

    const {
      ownerDocument: { defaultView, documentElement: editorDocument },
    } = iframe.current;
    const { documentElement } = iframe.current.contentDocument;

    const cta700Color = defaultView.getComputedStyle(editorDocument).getPropertyValue('--cta-700');
    const gray600Color = defaultView
      .getComputedStyle(editorDocument)
      .getPropertyValue('--gray-600');

    view.helpers.injectCustomizations(documentElement, customization);

    documentElement.style.setProperty('--scale', 1 / scale);
    documentElement.style.setProperty('--cta-700', cta700Color);
    documentElement.style.setProperty('--gray-600', gray600Color);

    const viewportWidth = forcedDeviceWidth || FORCED_DESKTOP_WIDTH;
    documentElement.style.setProperty(
      '--viewport-height',
      `${Math.round(viewportWidth / ASPECT_RATIO)}px`
    );
    if (!shouldApplyScrollWorkaround || !forcedDeviceWidth || forcedScale) {
      documentElement.style.setProperty('--max-container-height', '100vh');
      documentElement.style.setProperty('--container-overflow', 'auto');
    }
  };

  const onResizeCallback = useCallback(
    debounce(
      () => {
        if (!iframe.current) return;
        const { contentDocument } = iframe.current;
        if (!contentDocument) return;
        const container = contentDocument.body.querySelector(`.${PAGE_WRAPPER_CLASSNAME}`);
        if (!container) return;
        const newHeight = container.offsetHeight;
        if (newHeight === oldIframeHeight.current) return;

        setIframeContentHeight(newHeight);
        oldIframeHeight.current = newHeight;
      },
      THROTTLE_DELAY,
      { leading: false }
    ),
    [oldIframeHeight]
  );

  useEffect(() => {
    const { contentDocument: document } = iframe.current;
    const { css, js } = dependencies;
    const dependenciesSource = buildDependenciesHTML({ css });

    document.head.insertAdjacentHTML('beforeend', dependenciesSource);
    js.forEach((script) => {
      const el = document.createElement('script');
      el.src = script;
      el.setAttribute(DATA_WS_DEPENDENCY_ATTRIBUTE, true);
      document.head.appendChild(el);
    });

    return () => {
      const oldDependencies = document.head.querySelectorAll(`[${DATA_WS_DEPENDENCY_ATTRIBUTE}]`);
      oldDependencies.forEach((node) => node.remove());
    };
  }, [dependencies]);

  useEffect(() => {
    const { contentDocument } = iframe.current;
    const colorPaletteElement = contentDocument.querySelector('#ws-color-palette');

    if (
      colorPaletteElement &&
      forcedColorPaletteId !== colorPaletteElement.dataset.colorPaletteId
    ) {
      const { colorPalletes } = extractLiveDocumentAssents();
      const template = document.createElement('template');
      template.innerHTML = colorPalletes;
      template.content.firstChild.setAttribute('data-color-palette-id', forcedColorPaletteId);

      colorPaletteElement.parentNode.replaceChild(template.content.firstChild, colorPaletteElement);
    }
  }, [forcedColorPaletteId]);

  useEffect(() => {
    const { contentDocument } = iframe.current;
    const customFontsElement = contentDocument.querySelector('#ws-custom-fonts');

    if (customFontsElement && forcedFontPairId !== customFontsElement.dataset.fontPairId) {
      const { customFonts } = extractLiveDocumentAssents();
      const template = document.createElement('template');
      template.innerHTML = customFonts;
      template.content.firstChild.setAttribute('data-font-pair-id', forcedFontPairId);

      customFontsElement.parentNode.replaceChild(template.content.firstChild, customFontsElement);
    }
  }, [forcedFontPairId]);

  useEffect(() => {
    if (iframe.current && isReady) {
      const { contentDocument: document } = iframe.current;
      const scrollContainer = document.body.querySelector(`.${PAGE_WRAPPER_CLASSNAME}`);

      const scrollTo = (top) => {
        scrollContainer.scrollTop = top;
      };

      const getIframeContainer = () => iframeContainer.current;

      const getScrollContainer = () => scrollContainer;

      onViewReady({
        scrollTo,
        getIframeContainer,
        getScrollContainer,
      });
    }
  }, [onViewReady, isReady]);

  useEffect(() => {
    const { ownerDocument } = iframe.current;
    const { defaultView } = ownerDocument;
    const globalStyleElements = ownerDocument.head.querySelectorAll('link');
    const ignoredLinks = ['browser-support', 'publish-progress'];
    const globalStyles = [];
    globalStyleElements.forEach((element) => {
      const isIgnored = ignoredLinks.find((link) => element.href.includes(link));
      if (!isIgnored) {
        const href = element.getAttribute('href');
        if (utils.isAbsoluteURL(href)) {
          globalStyles.push(element.outerHTML);
        } else {
          const cloneNode = element.cloneNode();
          cloneNode.setAttribute('href', urlJoin(window.location.origin, href));
          globalStyles.push(cloneNode.outerHTML);
        }
      }
    });

    const liveAssets = extractLiveDocumentAssents();
    const compiledHeader = compileLivePreviewHeader(globalStyles.join(''), liveAssets, iframeCSS);
    const compiledPreloadedEntities = compilePreloadedEntities(
      staticAssets.filter((asset) => asset.indexOf('.html') !== -1),
      true
    );

    const { contentDocument: document } = iframe.current;

    const resizeCallback = debounce(fitIframe, DEBOUNCE_DELAY, { leading: false });

    document.open();
    document.onreadystatechange = () => {
      semaphoreInstance.current = new Semaphore(1);
      if (document.readyState === 'interactive') {
        fitIframe();

        defaultView.addEventListener('resize', resizeCallback);
        const scrollContainer = document.body.querySelector(`.${PAGE_WRAPPER_CLASSNAME}`);
        scrollContainer.addEventListener('scroll', () => onScroll(scrollContainer));

        let localResizeObserver;
        const localResizeCallbacks = [];
        try {
          localResizeObserver = new document.defaultView.ResizeObserver((elements) => {
            localResizeCallbacks.forEach((fn) => {
              fn(elements);
            });
          });
        } catch (e) {
          localResizeObserver = {
            observe: Function.prototype,
            unobserve: Function.prototype,
          };
        }

        resizeObserver.current = localResizeObserver;
        resizeObserverHandler.current = (fn) => {
          localResizeCallbacks.push(fn);
        };

        const browserSpecificIntersectionObserverOptions = {};
        if (bowser.firefox) {
          browserSpecificIntersectionObserverOptions.root = iframe.current.contentDocument;
        }
        const intersectionObserverCallbacks = [];
        intersectionObserver.current = new IntersectionObserver(
          (entries) => {
            // Copy callbacks array to break the reference cause we can modify
            // the original one during callbacks execution and cause bugs
            const callbacks = [...intersectionObserverCallbacks];
            callbacks.forEach((callback) => {
              callback(entries);
            });
          },
          {
            ...intersectionObserverOptions,
            ...browserSpecificIntersectionObserverOptions,
          }
        );

        intersectionObserverHandler.current = (fn) => {
          intersectionObserverCallbacks.push(fn);

          return () => {
            const index = intersectionObserverCallbacks.indexOf(fn);

            if (index === -1) return;

            intersectionObserverCallbacks.splice(index, 1);
          };
        };

        setIsReady(true);
      }
    };

    const workaroundClass = classnames('common-workaround', {
      'mobile-workaround': bowser.mobile,
      'tablet-workaround': bowser.tablet,
      'ios-workaround': bowser.ios,
    });

    const parameters = {
      compiledHeader,
      blockDependencies: buildDependenciesHTML(dependencies),
      preloadedEntities: compiledPreloadedEntities,
      bodyClassName: workaroundClass,
      wrapperClassName: classnames(PAGE_WRAPPER_CLASSNAME, className),
    };

    const compiledHTML = compileLivePreviewFrameHtml(parameters);
    document.write(compiledHTML);
    document.close();

    return () => defaultView.removeEventListener('resize', resizeCallback);
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  const { width, height, scale } = iframeSize;

  const wrapperNodeProperties = {
    style: {
      transform: `scale(${scale})`,
    },
  };

  const iframeNodeProperties = {
    height,
  };

  // Workaround for android mobile platform
  if (shouldApplyScrollWorkaround) {
    wrapperNodeProperties.style.width = `${width}px`;
    wrapperNodeProperties.style.height = `${iframeContentHeight}px`;

    iframeNodeProperties.scrolling = 'no';
    iframeNodeProperties.height = iframeContentHeight;
  }

  return (
    <React.Fragment>
      <div
        className="iframe-block-live-preview-viewport"
        ref={iframeContainer}
        {...wrapperNodeProperties}
      >
        <iframe
          ref={iframe}
          width={width}
          height={height}
          src="about:blank"
          title="render sandbox"
          id="block-sanbox"
          {...iframeNodeProperties}
          sandbox="allow-scripts allow-forms allow-same-origin allow-popups allow-pointer-lock allow-presentation allow-modals"
        />
      </div>
      {iframe.current && isReady && (
        <IframeContext.Provider
          value={{
            resizeObserver: resizeObserver.current,
            resizeHandler: resizeObserverHandler.current,
            intersectionObserver: intersectionObserver.current,
            intersectionHandler: intersectionObserverHandler.current,
            semaphore: semaphoreInstance.current,
            onResizeCallback,
            iframeScaleFactor: scale,
          }}
        >
          <PortalToIframe
            mountTo={iframe.current.contentDocument.querySelector(`.${PAGE_WRAPPER_CLASSNAME}`)}
          >
            {children}
          </PortalToIframe>
        </IframeContext.Provider>
      )}
    </React.Fragment>
  );
}

ViewWithStaticAssets.propTypes = {
  children: PropTypes.element.isRequired,
  dependencies: PropTypes.shape({
    css: PropTypes.arrayOf(PropTypes.string).isRequired,
    js: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
  onScroll: PropTypes.func,
  staticAssets: PropTypes.array,
  forcedWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  forcedScale: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  forcedColorPaletteId: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  forcedFontPairId: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  scrolledElement: PropTypes.oneOf(['iframe', 'wrapper']),
  customization: PropTypes.shape({
    globalOffsetTop: PropTypes.string,
    globalOffsetBottom: PropTypes.string,
    globalOffsetLeft: PropTypes.string,
    globalOffsetRight: PropTypes.string,
    thumbnailOffsetTop: PropTypes.string,
    thumbnailFirstChildOffsetTop: PropTypes.string,
  }),
  onViewReady: PropTypes.func,
  className: PropTypes.string,
};

ViewWithStaticAssets.defaultProps = {
  dependencies: {
    css: [],
    js: [],
  },
  className: '',
  onScroll: Function.prototype,
  staticAssets: [],
  forcedWidth: null,
  forcedScale: null,
  forcedColorPaletteId: null,
  forcedFontPairId: null,
  scrolledElement: scrolledElementType.IFRAME,
  customization: {},
  onViewReady: Function.prototype,
};

export default ViewWithStaticAssets;
