export const PAGE_WRAPPER_CLASSNAME = 'iframe-page-container';
export const DISPLAY_ON_BLOCK_SELECTOR = 'block_selector';
export const DISPLAY_ON_BLOCK_LAYOUT = 'block_layout';
export const VARIATION_ATTRIBUTE_NAME = 'data-ws-variation-id';
export const BLOCK_ID_ATTRIBUTE_NAME = 'data-ws-id';
export const NODE_ID_ATTRIBUTE_NAME = 'id';
export const SKIP_ANIMATION_ATTRIBUTE = 'skip-animation';
export const BACKGROUND_PLACEHOLDER_ATTRIBUTE = 'background-placeholder';
export const SURFACE_CLASS_NAME = 'ws-surface';
export const SURFACE_INDEX_ATTRIBUTE = 'data-surface';
export const BLOCK_WITH_SURFACE_SELECTOR = 'ws-block[surfaces]';
export const CONTEXT_MENU_GROUP_NAME = 'blocks';
export const CONTEXT_MENU_EMPTY_PAGE_GROUP_NAME = 'empty-page';
export const BLOCK_SETTINGS_LAYOUT_TAB_ID = 'layout';
export const BLOCK_SETTINGS_APPEARANCE_TAB_ID = 'appearance';
export const LIVE_PREVIEW_MOCKED_ID = 'live-preview';
export const AVAILABLE_SURFACES = [
  'ws-light-0',
  'ws-light-1',
  'ws-dark-0',
  'ws-dark-1',
  'ws-primary-0',
  'ws-secondary-0',
];
export const DATA_WS_DEPENDENCY_ATTRIBUTE = 'data-ws-dependency';
export const DEBOUNCE_DELAY = 600;
export const ONLINE_STORE_LOADED_EVENT = 'WSOnlineStoreLoaded';
export const DONATIONS_CATEGORY_NAME = 'donations';

export const REGULAR_IMAGE_ATTRIBUTES_FOR_SHALLOW_PROXY = [
  'content-position',
  'aspect-ratio',
  'content-scale',
];

export const BLOCK_FILTERS = {
  none: 'none',
  free: 'free',
  premium: 'premium',
};
