import segment from 'src/js/modules/analytics/segment';
import auth from '../../auth';
import site from '../../site';
import dialogTypes from '../constants/dialog-types';

const {
  track,
  constants: { events, common },
} = segment;

function onDialogCancel(dialogType) {
  switch (dialogType) {
    case dialogTypes.AI_SURVEY: {
      break;
    }

    default: {
      track(events.DIALOG_CANCELLED, {
        appName: common.APP_NAME,
        siteId: auth.accessors.getSiteId(),
        templateBuildSlug: site.accessors.getSiteTemplateSlug(),
        dialogId: dialogType,
      });
    }
  }
}

export default onDialogCancel;
