import { getLocation } from 'connected-react-router';
import get from 'lodash.get';
import { dialogs, integration, site, template } from '@yola/ws-sdk';
import { selectors } from '@yola/subscription-manager-js';
import user from 'yola-editor/src/js/modules/user';
import store from 'src/js/store';
import ai from 'src/js/modules/ai';
import tours from 'src/js/modules/tours';
import config from 'src/js/modules/config';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import initializeDifmPromo from 'src/js/modules/difm-promo/operations/initialize-difm-promo';
import publishing from 'yola-editor/src/js/modules/publishing';
import saveSite from '../../saving/helpers/save-site';

const { AI_SITE_WIZARD_PROPERTY_NAME, aiSiteWizardPropertyValues } = ai.constants.siteProperties;
const { platformComponents } = user.constants;

async function onEditorLoaded() {
  const state = store.getState();
  const siteProperties = site.selectors.getSiteProperties(state);
  const settings = site.selectors.getSettings(state);
  const userData = user.selectors.getUserData(state);
  const isB2C = config.selectors.getIsB2C(state);
  const publishingType = user.selectors.getPublishingType(state);
  const availablePlatformComponents = user.selectors.getAvailablePlatformComponents(state);
  const activeHostingSubscription = selectors.getActiveHostingSubscription(state);
  const location = getLocation(state);
  const featureFlags = await integration.helpers.requestFeatureFlags([
    'welcome_tour',
    'ai_site_wizard',
    'upgrade_to_publish',
  ]);

  const {
    welcome_tour: isWelcomeTourEnabled,
    ai_site_wizard: isAiSiteWizardEnabled,
    upgrade_to_publish: isUpgradeToPublishEnabled,
  } = featureFlags;

  const isAiSiteWizardAvailable =
    isAiSiteWizardEnabled &&
    [aiSiteWizardPropertyValues.ENABLED, aiSiteWizardPropertyValues.INITIATED].includes(
      siteProperties[AI_SITE_WIZARD_PROPERTY_NAME]
    ) &&
    template.verifiers.isMpt();

  const siteId = site.accessors.getSiteId();

  try {
    const jobs = await publishing.helpers.getPublishingJobsInProgress(siteId, publishingType);
    if (jobs.length) {
      await store.dispatch(publishing.thunks.showLastPublishingProgress(jobs[0].id));
    }
  } catch (error) {
    console.error(error);
  }

  const shouldShowTermsOfUseDalog =
    isB2C && !userData.is_latest_user_agreement_accepted && !userData.employee_id;

  const onNext = () => {
    const { publish, domain } = get(location, 'state', {});
    if (publish) {
      const showPublishInterruption =
        (!settings.siteURL || !activeHostingSubscription) &&
        isUpgradeToPublishEnabled &&
        availablePlatformComponents.includes(platformComponents.DOMAINS);

      if (!domain && showPublishInterruption) {
        // if user didn't complete AI_SITE_WIZARD flow and publish site,
        // we have to skip flow to prevent running on next enter
        if (isAiSiteWizardAvailable) {
          store.dispatch(
            site.actions.setSiteProperty(
              AI_SITE_WIZARD_PROPERTY_NAME,
              aiSiteWizardPropertyValues.SKIPPED
            )
          );
          saveSite();
        }

        store.dispatch(dialogs.actions.show(dialogTypes.PUBLISH_INTERRUPTION_DIALOG));
        return;
      }

      store.dispatch(publishing.actions.publishWebsite(domain));
    }

    if (isAiSiteWizardAvailable) {
      store.dispatch(dialogs.actions.show(dialogTypes.SITE_WIZARD_INTRO_DIALOG));
      return;
    }

    if (!settings.paymentURL && isWelcomeTourEnabled) {
      store.dispatch(tours.actions.initWelcomeTour());
    }

    initializeDifmPromo();
  };

  if (shouldShowTermsOfUseDalog) {
    store.dispatch(dialogs.actions.show(dialogTypes.TERMS_OF_USE_DIALOG, { onSubmit: onNext }));
    return;
  }

  onNext();
}

export default onEditorLoaded;
