import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NetworkErrorHandler } from '@yola/webapp-plugins';
import starterScreen from 'yola-editor/src/js/modules/starter-screen';
import { redirectToUrl } from 'yola-editor/src/js/router/utils/redirect-to-url';
import segment from 'src/js/modules/analytics/segment';
import auth from 'yola-editor/src/js/modules/auth';
import site from 'yola-editor/src/js/modules/site';
import routesMap from 'yola-editor/src/js/router/helpers/routes-map';
import constants from '../constants';
import actions from '../actions';

const { NETWORK_OFFLINE, NETWORK_ONLINE } = constants;
const {
  track,
  constants: { events, common },
} = segment;

function NetworkErrorHandlerContainer() {
  const [forceShow, setForceShow] = useState(false);
  const dispatch = useDispatch();
  const { progress } = useSelector(starterScreen.selectors.getProgress);
  const siteId = auth.accessors.getSiteId();
  const templateBuildSlug = site.accessors.getSiteTemplateSlug();
  const isEditorLoaded = progress === 100;

  const handleShow = useCallback(() => {
    dispatch(actions.setNetworkStatus(NETWORK_OFFLINE));
    track(events.NETWORK_CHANGED, {
      appName: common.APP_NAME,
      status: NETWORK_OFFLINE,
      siteId,
      templateBuildSlug,
    });
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleHide = useCallback(() => {
    dispatch(actions.setNetworkStatus(NETWORK_ONLINE));
    track(events.NETWORK_CHANGED, {
      appName: common.APP_NAME,
      status: NETWORK_ONLINE,
      siteId,
      templateBuildSlug,
    });
    setForceShow(!isEditorLoaded);
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, [dispatch, isEditorLoaded]);

  const reload = () => {
    redirectToUrl(routesMap.index.url());
  };

  const handleDismiss = !isEditorLoaded ? reload : null;

  return (
    <NetworkErrorHandler
      onHide={handleHide}
      onShow={handleShow}
      onDismiss={handleDismiss}
      forceShow={forceShow}
    />
  );
}

export default NetworkErrorHandlerContainer;
