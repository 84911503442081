import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import Trigger from 'src/js/modules/common/components/trigger';
import withSelection from 'src/js/modules/utils/with-selection';
import useTextIndentControl from '../hooks/use-text-indent-control';

const { Icon } = designSystem;

function TextIndentActionTrigger({ elementId, getContext, triggerId, type, getTooltip }) {
  const { triggersState, onTriggerClick } = useTextIndentControl(elementId);
  const { controlPane } = getContext();

  const handleClick = () => {
    onTriggerClick(triggerId);
    controlPane.forceHighlighterRerender();
  };

  return (
    <Trigger
      id={triggerId}
      data-tip={getTooltip()}
      disabled={triggersState[triggerId]}
      onClick={handleClick}
    >
      <Icon glyph={`indent-${type}`} />
    </Trigger>
  );
}

TextIndentActionTrigger.propTypes = {
  elementId: PropTypes.string.isRequired,
  getContext: PropTypes.func.isRequired,
  triggerId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  getTooltip: PropTypes.func.isRequired,
};

export default withSelection(TextIndentActionTrigger);
