import { textFormatting } from '@yola/ws-sdk';
import removeClassNameByPrefix from './remove-classname-by-prefix';
import getLineHeightByFontSize from './get-line-height-by-font-size';
import getMinFontSizeByFontSize from './get-min-font-size-by-font-size';

const { FONT_SIZE_MODIFIER_PREFIX, SUPPORTED_FONT_SIZES } = textFormatting.constants;

const changeFontSize = (modifier, focusElements) => {
  focusElements.forEach((node) => {
    const classNameWithoutModifiers = removeClassNameByPrefix(
      node.className,
      FONT_SIZE_MODIFIER_PREFIX
    );

    const className = `${classNameWithoutModifiers} ${FONT_SIZE_MODIFIER_PREFIX}${modifier}`.trim();
    node.setAttribute('class', className);

    if (SUPPORTED_FONT_SIZES.includes(Number(modifier))) {
      node.style.removeProperty('--font-size');
      node.style.removeProperty('--min-font-size');
      node.style.removeProperty('--line-height');
    } else {
      node.style.setProperty('--font-size', modifier);
      node.style.setProperty('--min-font-size', getMinFontSizeByFontSize(modifier));
      node.style.setProperty('--line-height', getLineHeightByFontSize(modifier));
    }
  });
};

export default changeFontSize;
