import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Icon, Text } from '@yola/ws-ui';
import classNames from 'classnames';

function Banner({
  title,
  glyph,
  iconProps,
  description,
  onClick,
  className,
  observer,
  ...otherProps
}) {
  const bannerRef = useRef(null);

  useEffect(() => {
    const bannerNode = bannerRef.current;

    if (observer) observer.observe(bannerNode);

    return () => {
      if (observer) observer.unobserve(bannerNode);
    };
  }, [observer]);

  const componentClassNames = classNames('ws-banner', className, {
    'ws-banner--clickable': onClick,
  });

  function handleClick() {
    if (onClick) onClick();
  }

  return (
    <div className={componentClassNames} onClick={handleClick} ref={bannerRef} {...otherProps}>
      <div className="ws-banner__content">
        <Text type="heading-6" className="ws-banner__title">
          {title}
        </Text>

        {description && (
          <Text type="annotation" className="ws-banner__description">
            {description}
          </Text>
        )}
      </div>
      <div className="ws-banner__icon">
        <Icon glyph={glyph} {...iconProps} />
      </div>
    </div>
  );
}

Banner.propTypes = {
  title: PropTypes.string.isRequired,
  glyph: PropTypes.string.isRequired,
  iconProps: PropTypes.shape({
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    strokeWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  description: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  observer: PropTypes.shape({
    observe: PropTypes.func.isRequired,
    unobserve: PropTypes.func.isRequired,
  }),
};

Banner.defaultProps = {
  iconProps: {
    strokeWidth: '1.3',
  },
  description: null,
  onClick: null,
  className: '',
  observer: null,
};

export default Banner;
