import { i18next } from '@yola/ws-sdk';
import constants from '../constants';

const {
  linkSettings: { DOWNLOAD_OPTIONS_VALUES },
} = constants;

const getDownloadOptions = () => [
  {
    label: i18next.t('Download on computer'),
    value: DOWNLOAD_OPTIONS_VALUES.downloadOnComputer,
  },
  {
    label: i18next.t('Open in a new tab'),
    value: DOWNLOAD_OPTIONS_VALUES.openInNewTab,
  },
];

export default getDownloadOptions;
