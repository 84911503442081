import get from 'lodash.get';
import { PANE_TRIGGER_SIZE } from '../constants/sizes';

const splitByPages = (content, perPage, maxWidth, includeNavigation = true) => {
  if (content.length === 0) return [];

  let currentPage = 0;

  return content.reduce((pages, item, itemIndex) => {
    const itemWidth = item.width || PANE_TRIGGER_SIZE;
    let pageItem = get(pages, `[${currentPage}]`, {
      items: [],
      width: 0,
    });
    const isLastPage = itemIndex === content.length - 1;
    const isFirstPage = currentPage === 0;
    const additionalTriggerCount = isLastPage || isFirstPage ? 1 : 2;
    const maxItemPerPage = includeNavigation ? perPage - additionalTriggerCount : perPage;
    let maxPaneWidth = includeNavigation
      ? maxWidth - additionalTriggerCount * PANE_TRIGGER_SIZE
      : maxWidth;

    if (itemWidth > maxPaneWidth) maxPaneWidth = itemWidth;

    if (pageItem.width + itemWidth > maxPaneWidth || pageItem.items.length >= maxItemPerPage) {
      currentPage += 1;
      pageItem = {
        items: [],
        width: 0,
      };
    }

    pageItem.items.push(item);
    pageItem.width += itemWidth;

    pages.splice(currentPage, 1, pageItem);

    return pages;
  }, []);
};

export default splitByPages;
