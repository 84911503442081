import { anodum, linkEditing } from '@yola/ws-sdk';
import constants from '../constants';

const { templates, selectors } = constants;
const { linkTypes } = linkEditing.constants;

const hasNestedItems = (item, items) => {
  const { id, order } = item;

  return items.some((compared) => {
    const isSameId = compared.id === id;
    if (isSameId) return false;

    const comparedOrderSegments = compared.order.split('.');
    const isNested = comparedOrderSegments.length > 1;
    const isNestedToTargetItem = isNested && comparedOrderSegments[0] === order;

    return isNestedToTargetItem;
  });
};

const getTemplate = (isNestable, isLink) => {
  if (isLink) {
    if (isNestable) {
      return templates.nestableLink;
    }

    return templates.link;
  }

  if (isNestable) {
    return templates.nestableTextItem;
  }

  return templates.textItem;
};

const buildNewMarkup = (staticElement, items) => {
  const cloneNode = staticElement.cloneNode(false);
  const containers = {
    top: cloneNode,
  };

  items.forEach((item) => {
    const isTopLevel = !item.order.includes('.');
    const isNestable = isTopLevel && hasNestedItems(item, items);
    const isLink = item.config.linkType !== linkTypes.NO_ACTION;
    const template = getTemplate(isNestable, isLink);
    const element = anodum.parseElementFromString(template);
    const container = isTopLevel
      ? containers.top
      : containers[item.order.substring(0, item.order.lastIndexOf('.'))];

    if (isNestable) {
      containers[item.order] = element.querySelector(selectors.listContainer);
    }

    if (isLink) {
      const link = element.querySelector(selectors.link);
      link.textContent = item.title;
      link.setAttribute(
        linkEditing.constants.common.LINK_CONFIG_ATTRIBUTE,
        JSON.stringify(item.config)
      );
    } else {
      const textElement = element.querySelector(selectors.textItem);
      textElement.textContent = item.title;
    }

    container.appendChild(element);
  });

  return cloneNode;
};

export default buildNewMarkup;
