import actionTypes from '../constants/action-types';

const setUpgradeOptions = (options) => ({
  type: actionTypes.SET_UPGRADE_OPTIONS,
  payload: {
    options,
  },
});

export default setUpgradeOptions;
