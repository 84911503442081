import { assets, dialogs } from '@yola/ws-sdk';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import generateImageThumbnailSource from './generate-image-thumbnail-source';

const { PHOTO_SIZE_ERROR } = dialogTypes;

const saveAssets = async ({
  items,
  onImageLoadProgress,
  galleryItemAreaBounds,
  abortRequestController,
}) => {
  try {
    const saveAssetsPromises = await Promise.all(
      items.map(async (item) => {
        try {
          const { file, originWidth, originHeight } = item;

          // skip files that we not uploaded by user
          if (!file) return item;

          if (item.isSaved) {
            return item;
          }

          const data = {
            asset: file,
            name: file.name,
          };

          const response = await assets.operations.createMediaGalleryAssets(data, {
            signal: abortRequestController.signal,
          });
          const { source: assetSource, name: assetName } = response.data;

          assets.operations.makePublishable(assetSource);

          const thumbnailSource = await generateImageThumbnailSource({
            assetSource,
            assetName,
            assetType: file.type,
            imageSize: { width: originWidth, height: originHeight },
            imageContainerSize: galleryItemAreaBounds,
            abortRequestController,
          });

          if (onImageLoadProgress) {
            onImageLoadProgress();
          }

          return {
            ...item,
            originSrc: assetSource,
            src: thumbnailSource,
          };
        } catch (e) {
          if (abortRequestController?.signal?.aborted) {
            return e;
          }

          // if file saving is failed, we still need to continue saving rest of the files,
          // so we catch and return error object extended with failed file name which will be displayed later
          if (onImageLoadProgress) {
            onImageLoadProgress();
          }
          console.error(e);
          e.fileName = item.file.name;

          const {
            status,
            data: { code, maxHeight, maxWidth },
          } = e.response;

          if (status === 413 && code === 'image_too_large') {
            const newLoadedImage = new Image();
            // eslint-disable-next-line func-names
            newLoadedImage.onload = function () {
              dialogs.operations.show(PHOTO_SIZE_ERROR, {
                captions: {
                  width: this.width,
                  height: this.height,
                  maxWidth,
                  maxHeight,
                },
              });
            };
            newLoadedImage.src = URL.createObjectURL(item.file);
          }

          return e;
        }
      })
    );

    const { savedItems, failedFilesNames } = saveAssetsPromises.reduce(
      (result, resolvedValue) => {
        if (resolvedValue instanceof Error) {
          result.failedFilesNames.push(resolvedValue.fileName);
          return result;
        }
        result.savedItems.push(resolvedValue);
        return result;
      },
      {
        savedItems: [],
        failedFilesNames: [],
      }
    );

    return { savedItems, failedFilesNames };
  } catch (e) {
    throw e;
  }
};

export default saveAssets;
