export default {
  SITE_NAME: 'siteName',
  BUSINESS_NAME: 'businessName',
  DESCRIPTION: 'description',
  LOGO: 'logo',
  ADDRESS: 'address',
  PHONE: 'phone',
  EMAIL: 'email',
  HOURS: 'hours',
  SOCIAL_LINKS: 'socialLinks',
  BUSINESS_CATEGORY: 'businessCategory',
};
