import { theme, customization } from '@yola/ws-sdk';
import store from 'src/js/store';
import { SURFACES_ATTRIBUTE } from '../../../constants/attributes';
import getSubmoduleConfig from '../../../helpers/get-submodule-config';
import isEmptyCustomBlock from './is-empty-custom-block';
import { SUBMODULE_NAME } from '../constants';

function getCurrentTheme() {
  const state = store.getState();
  const currentThemeId = theme.selectors.getCurrentTheme(state);

  return customization.accessors.getTheme(currentThemeId);
}

const shouldApplyWhitespaceAutomation = (targetNode, prevSiblingNode) => {
  if (!targetNode || !prevSiblingNode) {
    return false;
  }

  const { affectedBlocksSelector, restrictedBlocksSelector, restrictedPrevBlocksSelector } =
    getSubmoduleConfig(SUBMODULE_NAME);

  if (
    !targetNode.matches(affectedBlocksSelector) ||
    !prevSiblingNode.matches(affectedBlocksSelector) ||
    targetNode.matches(restrictedBlocksSelector) ||
    prevSiblingNode.matches(restrictedBlocksSelector) ||
    prevSiblingNode.matches(restrictedPrevBlocksSelector)
  ) {
    return false;
  }

  if (isEmptyCustomBlock(targetNode) || isEmptyCustomBlock(prevSiblingNode)) {
    return false;
  }

  const targetSurfacesValue = targetNode.getAttribute(SURFACES_ATTRIBUTE);
  const prevSiblingSurfacesValue = prevSiblingNode.getAttribute(SURFACES_ATTRIBUTE);
  const targetSurfaces = targetSurfacesValue ? targetSurfacesValue.split(' ') : [];
  const prevSiblingSurfaces = prevSiblingSurfacesValue ? prevSiblingSurfacesValue.split(' ') : [];

  if (targetSurfaces[0] !== prevSiblingSurfaces[0]) {
    return false;
  }

  const currentTheme = getCurrentTheme();
  const { restrictedBlocksSelector: themeRestrictedBlocksSelector } =
    currentTheme?.automatedWhiteSpace || {};

  if (targetNode.matches(themeRestrictedBlocksSelector)) {
    return false;
  }

  return true;
};

export default shouldApplyWhitespaceAutomation;
