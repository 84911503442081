export default {
  MODAL_WIDTH: 382,
  SELECT_ITEM_MODAL_HEIGHT: 740,
  MODAL_HEIGHT_WITH_TABS: 580,
  MODAL_HEIGHT: 514,
  MIN_FORM_SETTINGS_MODAL_HEIGHT: 250,
  PANEL_HEIGHT: 48,
  GDPR_SETTINGS_ID: 'ws_contact_form_gdpr_consent',
  LABEL_INPUT: 'label-input',
  PLACEHOLDER_INPUT: 'placeholder-input',
  OPTIONS_INPUT: 'options-input',
  BUTTON_TEXT_INPUT: 'button-text-input',
  MIN_DROPDOWN_ITEMS: 2,
  MIN_RADIO_ITEMS: 2,
  slug: 'ws-contact-form',
  UPVOTE_INTERRUPTION_ID: 'attach-file',
  ALL_FILE_TYPES_VALUE: '',
};
