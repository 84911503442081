import getEditorSettings from 'yola-editor/src/js/modules/editor/settings/get-editor-settings';
import * as ws from '@yola/ws-sdk';
import * as ui from '@yola/ws-ui';
import TabPanels from 'src/js/modules/common/components/tab-panels';
import * as contextMenu from 'src/js/modules/context-menu/components';
import * as editorAPI from 'src/js/api';
import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import withSelection from 'src/js/modules/utils/with-selection';
import DNDCache from 'src/js/modules/drag-n-drop/utils/cache';
import segment from 'src/js/modules/analytics/segment';
import websiteAssessment from 'src/js/modules/website-assessment';
import extensions from 'src/js/modules/extensions';
import migrations from 'src/js/modules/migrations';
import store from 'src/js/store';
import getRangeProgressValue from 'src/js/modules/utils/helpers/get-range-progress-value';
import getSubprogressMigrationPercent from 'src/js/modules/utils/helpers/get-subprogress-migration-percent';
import getLanguageCodes from 'src/js/modules/website-settings/helpers/get-language-codes';
import errorModule from 'yola-editor/src/js/modules/error';
import auth from 'yola-editor/src/js/modules/auth';
import site from 'yola-editor/src/js/modules/site';
import setProgress from 'yola-editor/src/js/modules/starter-screen/actions/set-progress';
import UNSUPPORTED_BROWSER_DIALOG_ID from 'yola-editor/src/js/unsupported-browser-dialog/constants/dialog-id';
import NOT_SUPPORTED_BROWSER_CLASSNAME from 'yola-editor/src/js/unsupported-browser-dialog/constants/not-supported-classname';
import editorHooks from 'src/js/modules/editor-hooks';
import onEditorLoaded from './on-editor-loaded';

const {
  trackGoogleMapEmbedSearchClicked,
  trackGoogleMapEmbedSearchPerformed,
  trackPageManagerSearchClicked,
  trackPageManagerSearchPerformed,
  trackSelectIconSearchClicked,
  trackSelectIconSearchPerformed,
  trackSelectIconIconClicked,
  trackSelectIconDialogDisplayed,
  trackSelectIconDialogSubmitted,
  trackSelectIconDialogCanceled,
} = segment.trackers;

const LOADING_PROGRESS_INITIAL = 60;
const LOADING_PROGRESS_FINAL = 85;

const initWSEditor = async (serviceClient) => {
  store.dispatch(setProgress({ progress: LOADING_PROGRESS_INITIAL }));
  const settings = await getEditorSettings();

  window.ws = {
    ...ws.api,
    ...ui,
    TabPanels,
    ...contextMenu,
    ...editorAPI,
    utils: {
      ...editorAPI.utils,
      ...ws.api.utils,
    },
    React,
    createPortal,
    PropTypes,
    withSelection,
    // TODO: remove the `analytics` field when all extensions
    // will be migrated to the `ws-editor` and use all trackers
    // there directly imported from the`analytics` module
    analytics: {
      trackGoogleMapEmbedSearchClicked,
      trackGoogleMapEmbedSearchPerformed,
      trackPageManagerSearchClicked,
      trackPageManagerSearchPerformed,
      trackSelectIconSearchClicked,
      trackSelectIconSearchPerformed,
      trackSelectIconIconClicked,
      trackSelectIconDialogDisplayed,
      trackSelectIconDialogSubmitted,
      trackSelectIconDialogCanceled,
    },
  };

  store.dispatch(ws.integration.actions.setSettings(settings));

  serviceClient
    .getCurrentSite()
    .then((clientResponse) => {
      if (clientResponse.data && clientResponse.data.hasPendingMigrations) {
        return migrations.executers
          .runSiteMigration(clientResponse.data.id, {
            onProgress: (data) => {
              if (data) {
                store.dispatch(
                  setProgress({
                    progress: getRangeProgressValue(
                      LOADING_PROGRESS_INITIAL,
                      LOADING_PROGRESS_FINAL,
                      Number(data.completedMigrationsCount),
                      Number(data.migrationsCount)
                    ),
                    updates: {
                      count: data.migrationsCount,
                      completed: data.completedMigrationsCount,
                      subProgress: getSubprogressMigrationPercent(data),
                    },
                  })
                );
              }
            },
          })
          .then(serviceClient.getCurrentSite);
      }

      return Promise.resolve(clientResponse);
    })
    .then(async (clientResponse) => {
      DNDCache.init();
      websiteAssessment.init();
      extensions.initializers.addExtensionsToRegistry();

      ws.hooks.operations.subscribe(editorHooks.availableHooks.ON_VIEW_READY, onEditorLoaded, {
        once: true,
      });

      store.dispatch(ws.site.actions.connectSite(clientResponse.data));
      // This action causes user preferences update which also
      // triggers `setSettings` action dispatch, therefore we need
      // to dispatch it when `ws` is already initialized to avoid errors.
      store.dispatch(ws.siteLocales.actions.setSupportedLocales(getLanguageCodes()));
      store.dispatch(setProgress({ progress: LOADING_PROGRESS_FINAL }));
      const isBrowserNotSupported = document.documentElement.classList.contains(
        NOT_SUPPORTED_BROWSER_CLASSNAME
      );
      if (isBrowserNotSupported) {
        segment.track(segment.constants.events.DIALOG_DISPLAYED, {
          appName: segment.constants.common.APP_NAME,
          siteId: auth.accessors.getSiteId(),
          templateBuildSlug: site.accessors.getSiteTemplateSlug(),
          dialogId: UNSUPPORTED_BROWSER_DIALOG_ID,
        });
      }
    })
    .catch((error) => {
      console.error(error);
      store.dispatch(
        errorModule.actions.setCriticalError({
          error,
          logSentry: true,
        })
      );
    });
};
export default initWSEditor;
