import { BLOCK_FILTERS } from '../constants/common';

const filterBlocksByType = (blocksList, type) => {
  switch (type) {
    case BLOCK_FILTERS.free: {
      return blocksList.filter((block) => !block.isPremium);
    }
    case BLOCK_FILTERS.premium: {
      return blocksList.filter((block) => block.isPremium);
    }

    default: {
      return blocksList;
    }
  }
};

export default filterBlocksByType;
