import { site, template } from '@yola/ws-sdk';
import track from '../track';

const trackEvent = (event, traits = {}) => {
  track(event, {
    siteId: site.accessors.getSiteId(),
    templateBuildSlug: template.accessors.getBuildSlug(),
    ...traits,
  });
};

export default trackEvent;
