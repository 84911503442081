import { integration } from '@yola/ws-sdk';
import actionTypes from '../constants/action-types';
import getEditorSettings from '../settings/get-editor-settings';
import dialogs from '../../dialogs';
import errorActionsTypes from '../../error/constants/action-types';

const setSettings = (store) => (next) => async (action) => {
  switch (action.type) {
    case actionTypes.SET_SETTINGS: {
      const settings = await getEditorSettings();
      store.dispatch(integration.actions.setSettings(settings));
      next(action);
      break;
    }
    case dialogs.actionTypes.HIDE_DIALOG:
    case dialogs.actionTypes.SHOW_DIALOG:
    case errorActionsTypes.SET_NETWORK_STATUS: {
      next(action);
      const settings = await getEditorSettings();
      store.dispatch(integration.actions.setSettings(settings));
      break;
    }
    default:
      next(action);
  }
};

export default setSettings;
