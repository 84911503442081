import { view } from '@yola/ws-sdk';
import actionTypes from '../constants/action-types';
import { USER_MODIFIED_ATTR } from '../constants/attributes';
import helpers from '../helpers';

const userModifiedAttribute = () => (next) => (action) => {
  switch (action.type) {
    case actionTypes.ADD_USER_MODIFIED_ATTRIBUTE: {
      const { elementId, attribute } = action.payload;

      const updatedValue = helpers.getUpdatedUserModifiedAttributeValue(elementId, attribute);
      const updatedAction = view.actions.setElementAttribute(
        elementId,
        USER_MODIFIED_ATTR,
        updatedValue
      );

      next(updatedAction);
      break;
    }
    case view.actionTypes.BULK_VIEW_ACTIONS: {
      const { actions: receivedActions } = action.payload;

      // Split received actions into user-modified attribute related and all others
      const userModifiedActions = [];
      const otherActions = [];
      receivedActions.forEach((receivedAction) => {
        if (receivedAction.type === actionTypes.ADD_USER_MODIFIED_ATTRIBUTE) {
          userModifiedActions.push(receivedAction);
        } else {
          otherActions.push(receivedAction);
        }
      });

      // Create collection of added attributes per element in a format of:
      // `{ elementId: 'comma,separated,attr,names' }`
      const modifiedAttributesPerElement = {};
      userModifiedActions.forEach((receivedAction) => {
        const { attribute, elementId: id } = receivedAction.payload;
        const attributes = modifiedAttributesPerElement[id];

        modifiedAttributesPerElement[id] = attributes
          ? `${attributes},${attribute}`
          : `${attribute}`;
      });

      // Create corresponding `setElementAttribute` actions per element
      // that set resulting `user-modified` attribute value without duplicates
      const updatedUserModifiedActions = [];
      Object.keys(modifiedAttributesPerElement).forEach((id) => {
        const newValue = modifiedAttributesPerElement[id];
        const updatedValue = helpers.getUpdatedUserModifiedAttributeValue(id, newValue);
        const updatedAction = view.actions.setElementAttribute(
          id,
          USER_MODIFIED_ATTR,
          updatedValue
        );
        updatedUserModifiedActions.push(updatedAction);
      });

      next({
        ...action,
        payload: {
          ...action.payload,
          actions: [...otherActions, ...updatedUserModifiedActions],
        },
      });
      break;
    }
    default: {
      next(action);
    }
  }
};

export default userModifiedAttribute;
