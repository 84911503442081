import React from 'react';
import { utils } from '@yola/ws-sdk';

const BlockSettingsContext = React.createContext({
  onSubmit: utils.noop,
  getSharedData: null,
  resolveSharedData: null,
});

export default BlockSettingsContext;
