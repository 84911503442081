import { textFormatting } from '@yola/ws-sdk';
import constants from './constants';
import controls from './controls';
import helpers from './helpers';

const {
  linkControl,
  listBulletControl,
  listNumberControl,
  elementTypeControl,
  alignControl,
  clearControl,
  textColorControl,
  insertImageControl,
  fontSizeControl,
  aiWriteTextControl,
  aiRewriteTextControl,
  aiRewriteCollapsedTextControl,
  textFormattingControl,
  textIndentControl,
} = controls;
const { afterViewLoad, isSupportedColorOptions } = helpers;
const { addRelatedClassesToElement, removeRelatedClassesFromElement } = textFormatting.helpers;

const controlList = [
  textFormattingControl,
  linkControl,
  insertImageControl,
  listBulletControl,
  listNumberControl,
  alignControl,
  clearControl,
  elementTypeControl,
  fontSizeControl,
  aiWriteTextControl,
  aiRewriteTextControl,
  aiRewriteCollapsedTextControl,
  textIndentControl,
];

if (isSupportedColorOptions()) {
  controlList.splice(1, 0, textColorControl);
}

const extension = {
  injections: {
    afterElementInsert: addRelatedClassesToElement,
    afterElementSynchronize: removeRelatedClassesFromElement,
    afterViewLoad,
  },
  hooks: {
    afterBlurFocusedElement: addRelatedClassesToElement,
    beforeBlurFocusedElement: removeRelatedClassesFromElement,
  },
  slug: constants.common.SLUG,
  title: 'Text Formatting Extension',
  description: 'Extension for editing text',
  defaults: {
    textContainer: 'ws-text',

    textFormattingRestrictedParentsSelector: '',
    textFormattingQuerySelector: '*',

    textColorRestrictedParentsSelector: '',
    textColorQuerySelector: '*',

    linkQuerySelector: 'a',
    linkRestrictedParentsSelector: '',
    linkDeleteButtonQuerySelector: '*',

    bulletListQuerySelector: 'p',
    bulletListRestrictedParentsSelector: '',

    numberedListQuerySelector: 'p',
    numberedListRestrictedParentsSelector: '',

    clearRestrictedParentsSelector: '',
    clearQuerySelector: '*',

    alignTextRestrictedParentsSelector: 'li',
    alignTextQuerySelector: '*',

    insertImageRestrictedParentsSelector: '',
    insertImageQuerySelector: '*',

    elementTypeRestrictedParentsSelector: '',

    fontSizeRestrictedParentsSelector: '',

    textIndentRestrictedParentsSelector: '',
  },
  ui: {
    control: controlList,
  },
};

export default extension;
