import { useState, useEffect, useMemo } from 'react';
import { view, textFormatting } from '@yola/ws-sdk';
import usePrevious from 'src/js/modules/utils/custom-hooks/use-previous';
import getAdvancedSelection from './get-advanced-selection';
import getElementTypeOptions from './get-element-type-options';
import getTypedElementSelector from './get-typed-element-selector';
import findTypedParentElements from './find-typed-parent-elements';
import getDefaultFontSizeOptions from './get-default-font-size-options';

const { FONT_SIZE_MODIFIER_PREFIX, SUPPORTED_FONT_SIZES } = textFormatting.constants;

const DEFAULT_OPTIONS = getDefaultFontSizeOptions();

const getCurrentValue = (focusElements) => {
  const liveDocument = view.accessors.getLiveDocument();

  const fontSizesSet = new Set(
    focusElements.map((element) => {
      const modifier = Array.from(element.classList).find((className) =>
        className.startsWith(FONT_SIZE_MODIFIER_PREFIX)
      );

      if (modifier) {
        return modifier.replace(FONT_SIZE_MODIFIER_PREFIX, '');
      }

      const { fontSize } = liveDocument.defaultView.getComputedStyle(element);
      const parsedFontSize = Math.round(parseFloat(fontSize));

      return `${parsedFontSize}`;
    })
  );

  if (fontSizesSet.size === 1) {
    return [...fontSizesSet][0];
  }

  return '';
};

const getExpandedOptions = (newValue) =>
  [...DEFAULT_OPTIONS, { value: newValue, label: newValue }].sort(
    ({ label: labelA }, { label: labelB }) => parseInt(labelA, 10) - parseInt(labelB, 10)
  );

function useFontSizeControl(elementId) {
  const selection = getAdvancedSelection();
  const elementTypeOptions = useMemo(() => getElementTypeOptions(elementId), [elementId]);
  const typedElementSelector = useMemo(
    () => getTypedElementSelector(elementTypeOptions),
    [elementTypeOptions]
  );
  const focusElements = findTypedParentElements(typedElementSelector, selection);
  const [currentValue, setCurrentValue] = useState('');
  const [options, setOptions] = useState([]);
  const previousCurrentValue = usePrevious(currentValue);

  useEffect(() => {
    if (!focusElements.length) {
      return;
    }

    const currentFontSize = getCurrentValue(focusElements);

    if (currentFontSize === previousCurrentValue) return;

    setCurrentValue(currentFontSize);

    if (currentFontSize === '' || SUPPORTED_FONT_SIZES.includes(Number(currentFontSize))) {
      setOptions(DEFAULT_OPTIONS);
      return;
    }

    const expandedOptions = getExpandedOptions(currentFontSize);

    setOptions(expandedOptions);
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, [focusElements]);

  return { selection, focusElements, options, currentValue };
}

export default useFontSizeControl;
