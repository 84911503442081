import constants from './constants/common';
import control from './controls/control';
import initHtmlEmbedElements from './helpers/init-html-embed-elements';

const extension = {
  injections: {
    afterViewLoad: initHtmlEmbedElements,
    afterElementInsert: initHtmlEmbedElements,
  },
  slug: constants.slug,
  title: 'HTML widget extension',
  description: 'Extension for adding custom HTML',
  defaults: {
    querySelector: 'ws-html-embed',
  },
  ui: {
    control: [control],
  },
};

export default extension;
