import { blocks } from '@yola/ws-sdk';
import compileBlockNode from './compile-block-node';

const isBlockNodeClean = (
  blockId,
  blockVariationId,
  node,
  blockItems,
  ignoreForPreviewOptions = []
) => {
  let isStrictlyClean = true;
  let isVisuallyClean = true;

  if (!blockVariationId) return { isStrictlyClean, isVisuallyClean };
  let variationHtml;
  blockItems.forEach((block) => {
    block.variations.forEach(({ id, html }) => {
      if (id === blockVariationId) variationHtml = html;
    });
  });
  if (!variationHtml) return { isStrictlyClean, isVisuallyClean };

  const tmpNode = compileBlockNode(variationHtml, blockId, blockVariationId, null);
  const referenceDisplayOptions = blocks.accessors.getDisplayOptionsForElement(tmpNode);
  const referenceOptionalChildren = blocks.accessors.getOptionalChildrenData(tmpNode);

  const displayOptions = blocks.accessors.getDisplayOptionsForElement(node);
  const optionalChildren = blocks.accessors.getOptionalChildrenData(node);

  const dirtyOptions = referenceDisplayOptions.filter(({ id: referenceId, value, isSet }) => {
    const option = displayOptions.find(({ id }) => id === referenceId);
    if (!option) return true;

    if (value) {
      return value !== option.value;
    }
    return isSet !== option.isSet;
  });

  const isChildrenClear = referenceOptionalChildren.every(
    ({ id: referenceId, isEnabled, querySelector }) => {
      const children = optionalChildren.find(({ id }) => id === referenceId);
      if (!children) return false;

      if (isEnabled !== children.isEnabled) return false;

      const childrenForTmpNode = [...tmpNode.querySelectorAll(querySelector)];
      const childrenForNode = [...node.querySelectorAll(children.querySelector)];
      return childrenForTmpNode.length === childrenForNode.length;
    }
  );

  isStrictlyClean = isChildrenClear && !dirtyOptions.length;

  isVisuallyClean =
    isStrictlyClean ||
    (isChildrenClear &&
      dirtyOptions.every(({ id: referenceId }) => ignoreForPreviewOptions.includes(referenceId)));

  return { isStrictlyClean, isVisuallyClean };
};

export default isBlockNodeClean;
