import hide from './hide';
import show from './show';
import hideHovered from './hide-hovered';
import showAnchors from './show-anchors';
import hideAnchors from './hide-anchors';

export default {
  hide,
  show,
  hideHovered,
  showAnchors,
  hideAnchors,
};
