import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { dialogs } from '@yola/ws-sdk';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import getDefaultTraits from 'src/js/modules/analytics/segment/helpers/get-default-traits';
import helpers from '../helpers';
import fieldTypeCategories from '../constants/field-type-categories';
import EditFieldModal from './edit-field-modal';

function ContantFormEditFieldDialog(props) {
  const {
    elementId,
    fields,
    modalPosition: modalPositionProp,
    activeFieldId,
    advancedFormState,
    type,
    label,
    buttonText,
    placeholder,
    category,
    onDialogMainAction,
    onDialogCancel,
  } = props;

  const initialFields = helpers.getFields(elementId);
  const fieldTypes = helpers.getFieldTypes();
  const { dropdown, radio, checkbox } = fieldTypes;

  const [isOverlayClicked, setIsOverlayClicked] = useState(false);
  const [modalPosition, setModalPosition] = useState(modalPositionProp);

  const openContactFormSettingsDialog = (updatedFields = null) => {
    dialogs.operations.show(dialogTypes.CONTACT_FORM_SETTINGS_DIALOG, {
      elementId,
      modalPosition,
      fields: updatedFields || fields,
      advancedFormState,
    });
  };

  const openSelectItemDialog = () => {
    dialogs.operations.show(dialogTypes.CONTACT_FORM_SELECT_ITEM_DIALOG, {
      elementId,
      modalPosition,
      fields,
      advancedFormState,
    });
  };

  const handleEditFieldSubmit = (data) => {
    let newFields;

    if (data.id) {
      const editedField = helpers.getItemById(fields, data.id);
      const initialField = helpers.getItemById(initialFields, data.id);

      onDialogMainAction({
        dialogType: dialogTypes.CONTACT_FORM_EDIT_ITEM,
        ...getDefaultTraits(elementId),
        itemId: data.id,
        itemType: data.type,
        triggerId: isOverlayClicked ? 'dialog-background' : 'submit-button',
        itemCurrentLabel: initialField.label || null,
        itemNewLabel: data.label,
        isRequired: !!data.required,
        labelAdjusted: editedField.label !== data.label,
        isRequiredAdjusted: editedField.required !== data.required,
      });
      newFields = [...fields.filter((field) => field.id !== activeFieldId), data];
    } else {
      newFields = helpers.addNewField(fields, data);
      const newField = newFields[newFields.length - 1];

      onDialogMainAction({
        dialogType: dialogTypes.CONTACT_FORM_NEW_ITEM,
        ...getDefaultTraits(elementId),
        triggerId: isOverlayClicked ? 'dialog-background' : 'submit-button',
        itemId: newField.id,
        itemType: newField.type,
        itemNewLabel: newField.label,
        isRequired: !!newField.required,
        categoryId: category,
        numberOfOptions:
          type === dropdown || type === radio || type === checkbox ? newField.options.length : null,
      });
    }

    setIsOverlayClicked(false);
    openContactFormSettingsDialog(newFields);
  };

  const handleEditFieldCancel = (isNewField, data = {}) => {
    const dialogType = isNewField
      ? dialogTypes.CONTACT_FORM_NEW_ITEM
      : dialogTypes.CONTACT_FORM_EDIT_ITEM;
    const initialField = helpers.getItemById(initialFields, data.id);

    const traits = isNewField
      ? {
          itemType: type,
          categoryId: category,
        }
      : {
          itemId: data.id,
          itemType: data.type,
          itemCurrentLabel: initialField.label || null,
          itemNewLabel: data.label,
        };

    onDialogCancel({
      dialogType,
      ...getDefaultTraits(elementId),
      ...traits,
    });

    if (isNewField) {
      openSelectItemDialog();
    } else {
      openContactFormSettingsDialog();
    }
  };

  return (
    <EditFieldModal
      elementId={elementId}
      initialField={helpers.getItemById(initialFields, activeFieldId)}
      initFormData={fields.find((field) => field.id === activeFieldId)}
      type={type}
      label={label}
      buttonText={buttonText}
      placeholder={placeholder}
      category={category}
      onSubmit={handleEditFieldSubmit}
      onCancel={handleEditFieldCancel}
      modalPosition={modalPosition}
      setModalPosition={setModalPosition}
      setIsOverlayClicked={setIsOverlayClicked}
    />
  );
}

ContantFormEditFieldDialog.propTypes = {
  elementId: PropTypes.string.isRequired,
  fields: PropTypes.array.isRequired,
  modalPosition: PropTypes.shape({
    offsetX: PropTypes.number.isRequired,
    offsetY: PropTypes.number.isRequired,
  }).isRequired,
  activeFieldId: PropTypes.string,
  advancedFormState: PropTypes.shape(),
  type: PropTypes.string,
  label: PropTypes.string,
  buttonText: PropTypes.string,
  placeholder: PropTypes.string,
  category: PropTypes.oneOf(Object.values(fieldTypeCategories)),
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

ContantFormEditFieldDialog.defaultProps = {
  activeFieldId: null,
  advancedFormState: null,
  type: '',
  label: '',
  buttonText: '',
  placeholder: '',
  category: fieldTypeCategories.BASIC,
};

export default ContantFormEditFieldDialog;
