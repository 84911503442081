import React from 'react';

function OurWorkPreview() {
  return (
    <svg viewBox="0 0 1920 1810" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="1920" height="1810" fill="white" />
      <rect width="1920" height="120" fill="white" />
      <rect opacity="0.8" x="375" y="44" width="100" height="32" rx="8" fill="#DADDE0" />
      <rect x="993" y="48" width="100" height="24" rx="8" fill="#B0B8BF" />
      <rect opacity="0.8" x="1125" y="48" width="100" height="24" rx="8" fill="#DADDE0" />
      <rect opacity="0.8" x="1257" y="48" width="100" height="24" rx="8" fill="#DADDE0" />
      <rect x="1405" y="32" width="140" height="56" rx="8" fill="#8E959E" />
      <rect opacity="0.4" x="1433" y="52" width="84" height="16" rx="8" fill="white" />
      <rect width="1920" height="1010" transform="translate(0 120)" fill="white" />
      <rect x="761" y="200" width="398" height="48" rx="8" fill="#B0B8BF" />
      <rect opacity="0.8" x="649" y="288" width="622" height="24" rx="8" fill="#DADDE0" />
      <rect opacity="0.8" x="743.5" y="332" width="433" height="24" rx="8" fill="#DADDE0" />
      <rect opacity="0.8" x="375" y="420" width="370" height="260" rx="16" fill="#B0B8BF" />
      <g opacity="0.8">
        <path
          d="M593.334 561.11L581.886 549.663C577.981 545.758 571.649 545.758 567.744 549.663L534.074 583.333M535 583.333H585C589.603 583.333 593.334 579.602 593.334 574.999V524.999C593.334 520.397 589.603 516.666 585 516.666H535C530.398 516.666 526.667 520.397 526.667 524.999V574.999C526.667 579.602 530.398 583.333 535 583.333ZM547.037 542.592C550.106 542.592 552.593 540.105 552.593 537.036C552.593 533.968 550.106 531.481 547.037 531.481C543.969 531.481 541.482 533.968 541.482 537.036C541.482 540.105 543.969 542.592 547.037 542.592Z"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M593.334 561.11L581.886 549.663C577.981 545.758 571.649 545.758 567.744 549.663L534.074 583.333M535 583.333H585C589.603 583.333 593.334 579.602 593.334 574.999V524.999C593.334 520.397 589.603 516.666 585 516.666H535C530.398 516.666 526.667 520.397 526.667 524.999V574.999C526.667 579.602 530.398 583.333 535 583.333ZM547.037 542.592C550.106 542.592 552.593 540.105 552.593 537.036C552.593 533.968 550.106 531.481 547.037 531.481C543.969 531.481 541.482 533.968 541.482 537.036C541.482 540.105 543.969 542.592 547.037 542.592Z"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect opacity="0.8" x="775" y="420" width="370" height="260" rx="16" fill="#B0B8BF" />
      <g opacity="0.8">
        <path
          d="M993.334 561.11L981.886 549.663C977.981 545.758 971.649 545.758 967.744 549.663L934.074 583.333M935 583.333H985C989.603 583.333 993.334 579.602 993.334 574.999V524.999C993.334 520.397 989.603 516.666 985 516.666H935C930.398 516.666 926.667 520.397 926.667 524.999V574.999C926.667 579.602 930.398 583.333 935 583.333ZM947.037 542.592C950.106 542.592 952.593 540.105 952.593 537.036C952.593 533.968 950.106 531.481 947.037 531.481C943.969 531.481 941.482 533.968 941.482 537.036C941.482 540.105 943.969 542.592 947.037 542.592Z"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M993.334 561.11L981.886 549.663C977.981 545.758 971.649 545.758 967.744 549.663L934.074 583.333M935 583.333H985C989.603 583.333 993.334 579.602 993.334 574.999V524.999C993.334 520.397 989.603 516.666 985 516.666H935C930.398 516.666 926.667 520.397 926.667 524.999V574.999C926.667 579.602 930.398 583.333 935 583.333ZM947.037 542.592C950.106 542.592 952.593 540.105 952.593 537.036C952.593 533.968 950.106 531.481 947.037 531.481C943.969 531.481 941.482 533.968 941.482 537.036C941.482 540.105 943.969 542.592 947.037 542.592Z"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect opacity="0.8" x="1175" y="420" width="370" height="260" rx="16" fill="#B0B8BF" />
      <g opacity="0.8">
        <path
          d="M1393.33 561.11L1381.89 549.663C1377.98 545.758 1371.65 545.758 1367.74 549.663L1334.07 583.333M1335 583.333H1385C1389.6 583.333 1393.33 579.602 1393.33 574.999V524.999C1393.33 520.397 1389.6 516.666 1385 516.666H1335C1330.4 516.666 1326.67 520.397 1326.67 524.999V574.999C1326.67 579.602 1330.4 583.333 1335 583.333ZM1347.04 542.592C1350.1 542.592 1352.59 540.105 1352.59 537.036C1352.59 533.968 1350.1 531.481 1347.04 531.481C1343.97 531.481 1341.48 533.968 1341.48 537.036C1341.48 540.105 1343.97 542.592 1347.04 542.592Z"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1393.33 561.11L1381.89 549.663C1377.98 545.758 1371.65 545.758 1367.74 549.663L1334.07 583.333M1335 583.333H1385C1389.6 583.333 1393.33 579.602 1393.33 574.999V524.999C1393.33 520.397 1389.6 516.666 1385 516.666H1335C1330.4 516.666 1326.67 520.397 1326.67 524.999V574.999C1326.67 579.602 1330.4 583.333 1335 583.333ZM1347.04 542.592C1350.1 542.592 1352.59 540.105 1352.59 537.036C1352.59 533.968 1350.1 531.481 1347.04 531.481C1343.97 531.481 1341.48 533.968 1341.48 537.036C1341.48 540.105 1343.97 542.592 1347.04 542.592Z"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect opacity="0.8" x="375" y="710" width="370" height="260" rx="16" fill="#B0B8BF" />
      <g opacity="0.8">
        <path
          d="M593.334 851.11L581.886 839.663C577.981 835.758 571.649 835.758 567.744 839.663L534.074 873.333M535 873.333H585C589.603 873.333 593.334 869.602 593.334 864.999V814.999C593.334 810.397 589.603 806.666 585 806.666H535C530.398 806.666 526.667 810.397 526.667 814.999V864.999C526.667 869.602 530.398 873.333 535 873.333ZM547.037 832.592C550.106 832.592 552.593 830.105 552.593 827.036C552.593 823.968 550.106 821.481 547.037 821.481C543.969 821.481 541.482 823.968 541.482 827.036C541.482 830.105 543.969 832.592 547.037 832.592Z"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M593.334 851.11L581.886 839.663C577.981 835.758 571.649 835.758 567.744 839.663L534.074 873.333M535 873.333H585C589.603 873.333 593.334 869.602 593.334 864.999V814.999C593.334 810.397 589.603 806.666 585 806.666H535C530.398 806.666 526.667 810.397 526.667 814.999V864.999C526.667 869.602 530.398 873.333 535 873.333ZM547.037 832.592C550.106 832.592 552.593 830.105 552.593 827.036C552.593 823.968 550.106 821.481 547.037 821.481C543.969 821.481 541.482 823.968 541.482 827.036C541.482 830.105 543.969 832.592 547.037 832.592Z"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect opacity="0.8" x="775" y="710" width="370" height="260" rx="16" fill="#B0B8BF" />
      <g opacity="0.8">
        <path
          d="M993.334 851.11L981.886 839.663C977.981 835.758 971.649 835.758 967.744 839.663L934.074 873.333M935 873.333H985C989.603 873.333 993.334 869.602 993.334 864.999V814.999C993.334 810.397 989.603 806.666 985 806.666H935C930.398 806.666 926.667 810.397 926.667 814.999V864.999C926.667 869.602 930.398 873.333 935 873.333ZM947.037 832.592C950.106 832.592 952.593 830.105 952.593 827.036C952.593 823.968 950.106 821.481 947.037 821.481C943.969 821.481 941.482 823.968 941.482 827.036C941.482 830.105 943.969 832.592 947.037 832.592Z"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M993.334 851.11L981.886 839.663C977.981 835.758 971.649 835.758 967.744 839.663L934.074 873.333M935 873.333H985C989.603 873.333 993.334 869.602 993.334 864.999V814.999C993.334 810.397 989.603 806.666 985 806.666H935C930.398 806.666 926.667 810.397 926.667 814.999V864.999C926.667 869.602 930.398 873.333 935 873.333ZM947.037 832.592C950.106 832.592 952.593 830.105 952.593 827.036C952.593 823.968 950.106 821.481 947.037 821.481C943.969 821.481 941.482 823.968 941.482 827.036C941.482 830.105 943.969 832.592 947.037 832.592Z"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect opacity="0.8" x="1175" y="710" width="370" height="260" rx="16" fill="#B0B8BF" />
      <g opacity="0.8">
        <path
          d="M1393.33 851.11L1381.89 839.663C1377.98 835.758 1371.65 835.758 1367.74 839.663L1334.07 873.333M1335 873.333H1385C1389.6 873.333 1393.33 869.602 1393.33 864.999V814.999C1393.33 810.397 1389.6 806.666 1385 806.666H1335C1330.4 806.666 1326.67 810.397 1326.67 814.999V864.999C1326.67 869.602 1330.4 873.333 1335 873.333ZM1347.04 832.592C1350.1 832.592 1352.59 830.105 1352.59 827.036C1352.59 823.968 1350.1 821.481 1347.04 821.481C1343.97 821.481 1341.48 823.968 1341.48 827.036C1341.48 830.105 1343.97 832.592 1347.04 832.592Z"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1393.33 851.11L1381.89 839.663C1377.98 835.758 1371.65 835.758 1367.74 839.663L1334.07 873.333M1335 873.333H1385C1389.6 873.333 1393.33 869.602 1393.33 864.999V814.999C1393.33 810.397 1389.6 806.666 1385 806.666H1335C1330.4 806.666 1326.67 810.397 1326.67 814.999V864.999C1326.67 869.602 1330.4 873.333 1335 873.333ZM1347.04 832.592C1350.1 832.592 1352.59 830.105 1352.59 827.036C1352.59 823.968 1350.1 821.481 1347.04 821.481C1343.97 821.481 1341.48 823.968 1341.48 827.036C1341.48 830.105 1343.97 832.592 1347.04 832.592Z"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect width="1920" height="560" transform="translate(0 1130)" fill="#B0B8BF" />
      <rect x="761" y="1290" width="398" height="48" rx="8" fill="white" />
      <rect opacity="0.4" x="649" y="1358" width="622" height="24" rx="8" fill="white" />
      <rect opacity="0.4" x="743.5" y="1402" width="433" height="24" rx="8" fill="white" />
      <rect x="868" y="1474" width="184" height="56" rx="8" fill="white" />
      <rect x="918" y="1494" width="84" height="16" rx="8" fill="#B0B8BF" />
      <rect width="1920" height="120" transform="translate(0 1690)" fill="#646C75" />
      <g opacity="0.3">
        <rect x="770" y="1738" width="100" height="24" rx="8" fill="white" />
        <rect x="910" y="1738" width="100" height="24" rx="8" fill="white" />
        <rect x="1050" y="1738" width="100" height="24" rx="8" fill="white" />
      </g>
    </svg>
  );
}

export default OurWorkPreview;
