import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { dialogs, integration, view, blockTemplates, hdrm, blocks } from '@yola/ws-sdk';
import useFeatureFlags from 'yola-editor/src/js/modules/feature-flags/hooks/use-feature-flags';
import getParentBlockByElement from 'src/js/modules/blocks/helpers/get-parent-block-by-element';
import AddWidgetDialog from '../components/add-widget-dialog';
import getWidgetSearchResult from '../helpers/get-widget-search-result';
import getWidgetsList from '../helpers/get-widgets-list';
import groupWidgetsByCategory from '../helpers/group-widgets-by-category';
import {
  trackWidgetSearchClicked,
  trackWidgetSearchPerformed,
  trackRequestWidgetButtonClicked,
} from '../helpers/widgets-analytics';
import sortGroupedWidgetsList from '../helpers/sort-grouped-widgets-list';
import { CLOSE_BUTTON_TRIGGER_ID, DIALOG_BACKGROUND_TRIGGER_ID } from '../constants/common';

function AddWidgetContainer({
  targetElementId,
  adjacentPosition,
  onDialogMainAction,
  onDialogCancel,
}) {
  const dispatch = useDispatch();
  const requestWidgetData = useSelector(integration.selectors.getRequestWidget);
  const blockTemplatesList = useSelector(blockTemplates.selectors.getBlockTemplates);
  const isViewLoaded = useSelector(view.selectors.getLoadedStatus);

  const [groupedWidgetsList, setGroupedWidgetsList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [offset, setOffset] = useState({ x: null, y: null });

  let element = hdrm.accessors.getParentLiveElement(targetElementId);
  if (adjacentPosition === 'afterbegin' || adjacentPosition === 'beforeend') {
    element = hdrm.accessors.getLiveElement(targetElementId);
  }

  const blockElement = element.closest(`[id^=${blocks.constants.BLOCK_ID_PREFIX}]`);

  const [featureFlags] = useFeatureFlags(['hidden_blocks']);

  const trackWidgetSearchPerformedDebounced = useCallback(
    debounce(trackWidgetSearchPerformed, 500),
    []
  );

  useEffect(() => {
    const list = getWidgetsList({ element, blockTemplatesList, featureFlags });
    const groupedList = groupWidgetsByCategory(list);
    const sortedGroupedList = sortGroupedWidgetsList(groupedList);

    if (searchQuery) {
      const wsBlockElement = element.closest('ws-block');
      const groupedListSearchResult = getWidgetSearchResult(searchQuery, sortedGroupedList);
      const searchResultsLength = groupedListSearchResult.reduce(
        (result, group) => result + group.items.length,
        0
      );

      trackWidgetSearchPerformedDebounced({
        wsBlockElement,
        searchTerm: searchQuery,
        searchResults: searchResultsLength,
      });

      setGroupedWidgetsList(groupedListSearchResult);
    } else {
      trackWidgetSearchPerformedDebounced.cancel();
      setGroupedWidgetsList(sortedGroupedList);
    }
  }, [element, blockTemplatesList, featureFlags, searchQuery, trackWidgetSearchPerformedDebounced]);

  const onRequestWidgetButtonClick = useCallback(() => {
    const wsBlockElement = getParentBlockByElement(element);
    trackRequestWidgetButtonClicked({ wsBlockElement });
  }, [element]);

  if (!targetElementId || !isViewLoaded) return null;

  const handleModalReady = (modalComponent) => {
    const {
      modal: { width, height },
    } = modalComponent.state;
    const { offsetX: x, offsetY: y } = dialogs.helpers.getPositionByElement(element, width, height);

    setOffset({ x, y });
  };

  const handleSearchFocus = () => {
    const wsBlockElement = element.closest('ws-block');

    trackWidgetSearchClicked({ wsBlockElement });
  };

  const handleSelect = ({ id, variationId }) => {
    onDialogMainAction();

    dispatch(dialogs.actions.hide());

    dispatch(view.actions.insertBlock(id, variationId, targetElementId, adjacentPosition));
  };

  const handleClose = () => {
    onDialogCancel({
      triggerId: CLOSE_BUTTON_TRIGGER_ID,
      targetBlockId: blocks.accessors.getBlockIdByElement(blockElement),
      targetBlockVariationId: blocks.accessors.getVariationIdByElement(blockElement),
    });

    dispatch(dialogs.actions.hide());
  };

  const handleOverlayClick = () => {
    onDialogCancel({
      triggerId: DIALOG_BACKGROUND_TRIGGER_ID,
      targetBlockId: blocks.accessors.getBlockIdByElement(blockElement),
      targetBlockVariationId: blocks.accessors.getVariationIdByElement(blockElement),
    });

    dispatch(dialogs.actions.hide());
  };

  return (
    <AddWidgetDialog
      groupedItems={groupedWidgetsList}
      requestWidgetData={requestWidgetData}
      onRequestWidgetButtonClick={onRequestWidgetButtonClick}
      offsetX={offset.x}
      offsetY={offset.y}
      onClose={handleClose}
      onOverlayClick={handleOverlayClick}
      onSearch={setSearchQuery}
      onSearchFocus={handleSearchFocus}
      onSelect={handleSelect}
      onModalReady={handleModalReady}
    />
  );
}

AddWidgetContainer.propTypes = {
  targetElementId: PropTypes.string,
  adjacentPosition: PropTypes.string,
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

AddWidgetContainer.defaultProps = {
  targetElementId: null,
  adjacentPosition: null,
};

export default AddWidgetContainer;
