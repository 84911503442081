import React from 'react';

const DateIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="144" height="90" viewBox="0 0 144 90" fill="none">
    <rect x="44" y="24" width="56" height="48" rx="4" fill="#DADDE0" />
    <path
      d="M44 28C44 25.7909 45.7909 24 48 24H96C98.2091 24 100 25.7909 100 28V37H44V28Z"
      fill="#B0B8BF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M82.5 18C83.8807 18 85 19.2312 85 20.75V26.25C85 27.7688 83.8807 29 82.5 29C81.1193 29 80 27.7688 80 26.25V20.75C80 19.2312 81.1193 18 82.5 18Z"
      fill="#646C75"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M61.5 18C62.8807 18 64 19.2312 64 20.75V26.25C64 27.7688 62.8807 29 61.5 29C60.1193 29 59 27.7688 59 26.25V20.75C59 19.2312 60.1193 18 61.5 18Z"
      fill="#646C75"
    />
  </svg>
);

export default DateIcon;
