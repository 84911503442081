import React from 'react';
import PropTypes from 'prop-types';
import { designSystem, Modal, PanelGroup, Panel } from '@yola/ws-ui';
import { i18next, bowser } from '@yola/ws-sdk';
import { ViewportConsumer } from 'yola-editor/src/js/utils/viewport-provider';

const {
  HeadingGroup,
  DialogButtonGroup,
  DialogButton,
  DialogButtonGroupSeparator,
  List,
  ListItem,
  Paragraph,
  DialogHeader,
  DialogHeaderTitle,
  Stack,
  Box,
} = designSystem;

const isMobile = bowser.tablet || bowser.mobile;

const MIN_WIDTH = 568;
const MAX_WIDTH = 800;
const MIN_HEIGHT = 600;
const MAX_HEIGHT = 720;

const ConnectIntegrationDialogScaffolding = ({ captions, children, onSubmit, onCancel }) => (
  <ViewportConsumer>
    {({ width, height }) => (
      <Modal
        overlay="visible"
        width={width >= 1440 ? MAX_WIDTH : MIN_WIDTH}
        height={height >= 800 ? MAX_HEIGHT : MIN_HEIGHT}
        centered
        resizable={false}
        draggable={false}
        fullscreen={isMobile}
        handleSubmit={onSubmit}
        handleCancel={onCancel}
      >
        <PanelGroup height="100%">
          <Panel height="48" align="middle" corners="squared" style={{ flexShrink: '0' }}>
            <DialogHeader>
              <DialogHeaderTitle>{captions.title}</DialogHeaderTitle>
            </DialogHeader>
          </Panel>
          <Panel height="100%" scrollable style={{ borderBottom: 'none' }}>
            <Box margin="spacing-m" marginTop="spacing-s" marginBottom="spacing-s">
              <Stack gap="spacing-m">
                <HeadingGroup
                  title={i18next.t('About this integration')}
                  description={captions.about}
                />
                <HeadingGroup title={i18next.t('How to connect')}>{children}</HeadingGroup>
                <HeadingGroup title={i18next.t('Important')}>
                  <List>
                    {captions.notes.map((note, index) => (
                      // eslint-disable-next-line yola/react/no-array-index-key
                      <ListItem key={`integration-note-${index}`}>
                        <Paragraph appearance="medium-emphasis" size="small">
                          {note}
                        </Paragraph>
                      </ListItem>
                    ))}
                  </List>
                </HeadingGroup>
              </Stack>
            </Box>
          </Panel>
          <Panel style={{ flexShrink: '0' }}>
            <DialogButtonGroup>
              <DialogButton onClick={onSubmit}>{i18next.t('Submit')}</DialogButton>
              <DialogButtonGroupSeparator />
              <DialogButton onClick={onCancel}>{i18next.t('Cancel')}</DialogButton>
            </DialogButtonGroup>
          </Panel>
        </PanelGroup>
      </Modal>
    )}
  </ViewportConsumer>
);

ConnectIntegrationDialogScaffolding.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    about: PropTypes.string.isRequired,
    notes: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  children: PropTypes.element.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ConnectIntegrationDialogScaffolding;
