import bowser from 'yola-bowser';

function preventEditorZooming(editor) {
  if (!bowser.ios) {
    return;
  }

  const textarea = editor.container.querySelector('textarea');
  textarea.style.fontSize = '16px';
}

export default preventEditorZooming;
