import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import WidgetFloatingTrigger from './widget-floating-trigger';
import { ADD_BLOCKS_BTN_HEIGHT, ADD_WIDGET_BTN_HEIGHT, CONTROLS_GAP } from '../constants/sizes';
import areTriggersInConflict from '../helpers/are-triggers-in-conflict';
import highlighterOffset from '../../highlighter/helpers/highlighter-offset';

const TOOLTIP_DIRECTION = 'right';
const CONFLICT_TOOLTIP_DIRECTION = 'left';
const CONFLICT_TRIGGER_SHIFT = -(
  (ADD_WIDGET_BTN_HEIGHT + ADD_BLOCKS_BTN_HEIGHT) / 2 +
  CONTROLS_GAP
);

const WidgetFloatingTriggers = (props) => {
  const { triggersInfo, blockTriggersInfo, onMouseEnter, onMouseLeave, showDialog } = props;

  if (!triggersInfo) {
    return null;
  }
  const indent = highlighterOffset.get();
  const { elementId, topTrigger, bottomTrigger } = triggersInfo;

  const { topTrigger: blockTopTrigger, bottomTrigger: blockBottomTrigger } =
    blockTriggersInfo || {};

  const areTopTriggersConflict =
    topTrigger &&
    blockTopTrigger &&
    areTriggersInConflict(blockTopTrigger.position, topTrigger.position);

  const areBottomTriggersConflict =
    bottomTrigger &&
    blockBottomTrigger &&
    areTriggersInConflict(blockBottomTrigger.position, bottomTrigger.position);

  return (
    <Fragment>
      {topTrigger && (
        <WidgetFloatingTrigger
          targetElementId={elementId}
          adjacentPosition={topTrigger.adjacentPosition}
          position={topTrigger.position}
          direction={TOOLTIP_DIRECTION}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          showDialog={showDialog}
          indent={-indent}
          {...(areTopTriggersConflict && {
            triggerShift: CONFLICT_TRIGGER_SHIFT,
            direction: CONFLICT_TOOLTIP_DIRECTION,
          })}
        />
      )}
      {bottomTrigger && (
        <WidgetFloatingTrigger
          targetElementId={elementId}
          adjacentPosition={bottomTrigger.adjacentPosition}
          position={bottomTrigger.position}
          direction={TOOLTIP_DIRECTION}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          showDialog={showDialog}
          indent={indent}
          {...(areBottomTriggersConflict && {
            triggerShift: CONFLICT_TRIGGER_SHIFT,
            direction: CONFLICT_TOOLTIP_DIRECTION,
          })}
        />
      )}
    </Fragment>
  );
};

const triggerPropType = PropTypes.shape({
  adjacentPosition: PropTypes.string,
  tooltipDirection: PropTypes.string,
  position: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    stick: PropTypes.string,
  }),
});

WidgetFloatingTriggers.propTypes = {
  triggersInfo: PropTypes.shape({
    topTrigger: triggerPropType,
    bottomTrigger: triggerPropType,
    elementId: PropTypes.string,
  }),
  blockTriggersInfo: PropTypes.shape({
    topTrigger: triggerPropType,
    bottomTrigger: triggerPropType,
  }),
  showDialog: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

WidgetFloatingTriggers.defaultProps = {
  triggersInfo: null,
  blockTriggersInfo: null,
  onMouseEnter: Function.prototype,
  onMouseLeave: Function.prototype,
};

export default WidgetFloatingTriggers;
