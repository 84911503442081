import optionTypes from '../constants/display-options-type';
import { ATTRIBUTES_TO_MARK_AS_USER_MODIFIED } from '../constants/display-options';

const getChangedItems = (items) => {
  if (!items) return [];

  return items.filter((item) => {
    switch (item.type) {
      case optionTypes.RADIO:
      case optionTypes.SURFACE:
      case optionTypes.ONLINE_STORE_CATEGORY:
      case optionTypes.BREAKPOINTS_SLIDER:
      case optionTypes.SLIDER: {
        if (ATTRIBUTES_TO_MARK_AS_USER_MODIFIED.includes(item.attribute)) {
          return item.userModified;
        }

        return item.initialValue !== item.value;
      }
      case optionTypes.COLOR_SCHEME:
      case optionTypes.CHECKBOX:
      case optionTypes.ONLINE_STORE_CLEAN_URL_SELECTOR:
        return item.isInitialEnabled !== item.isEnabled;
      default:
        return false;
    }
  });
};

export default getChangedItems;
