import { constants } from '@yola/subscription-manager-js';

const {
  hostingPackageFeatureNames: { AI_TEXT_ASSISTANT_QUOTA },
} = constants;

const UNLIMITED = 'unlimited';
const MAX_TEXT_ASSISTANT_UNLIMITED_PLACEHOLDER = 100000;

const aiTextAssistantLimit = ({ targetPackage }) => ({
  amount:
    targetPackage.features[AI_TEXT_ASSISTANT_QUOTA] === UNLIMITED
      ? MAX_TEXT_ASSISTANT_UNLIMITED_PLACEHOLDER
      : targetPackage.features[AI_TEXT_ASSISTANT_QUOTA],
});

export default aiTextAssistantLimit;
