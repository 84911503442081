import blocks from 'src/js/modules/blocks';
import hasBackgroundContainer from './has-background-container';
import isImageContainer from './is-image-container';
import constants from '../constants/common';
import { getSettings } from '../settings';

const { DEFAULT_SCALE } = constants;

const getScale = (blockNode) => {
  const settings = getSettings();
  if (blocks.verifiers.isWsBlock(blockNode) || hasBackgroundContainer(blockNode)) {
    const backgroundContainer = blockNode.querySelector(settings.backgroundContainer);
    return backgroundContainer.scale;
  }

  if (isImageContainer(blockNode)) {
    return blockNode.contentScale;
  }

  return DEFAULT_SCALE;
};

export default getScale;
