import { BACKGROUND_PLACEHOLDER_ATTRIBUTE } from '../constants/common';
import { SET, REMOVE } from '../constants/attribute-operations';

const getBackgroundAttributeOperations = ({ changedBackgroundOptions, backgroundOptions }) => {
  if (!backgroundOptions || !changedBackgroundOptions) return [];

  const backgroundOperations = backgroundOptions.filter(({ id }) => changedBackgroundOptions[id]);

  if (!backgroundOperations) return [];

  const operations = backgroundOperations.map((backgroundOption) => {
    const { elementId, attributesMap, src, initialSrc } = backgroundOption;

    const attributesOperations = Object.keys(attributesMap).map((attribute) => ({
      value: backgroundOption[attributesMap[attribute]],
      name: attribute,
      operation: SET,
      elementId,
    }));

    if (src !== initialSrc) {
      attributesOperations.push({
        operation: REMOVE,
        name: BACKGROUND_PLACEHOLDER_ATTRIBUTE,
        elementId,
      });
    }

    return attributesOperations;
  });

  return [].concat(...operations);
};

export default getBackgroundAttributeOperations;
