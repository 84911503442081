import { template, view } from '@yola/ws-sdk';
import getBreakpointsSliderValue from '../get-breakpoints-slider-value';
import getOptionTargetElement from './get-option-target-element';

const createBreakpointsSliderOption = ({
  item,
  displayOptions,
  blockNode,
  onChange,
  onAfterChange,
}) => {
  const targetElement = getOptionTargetElement(item, blockNode);
  if (!targetElement) return null;

  const targetElementId = view.accessors.getLiveElementId(targetElement);
  const existedOption = displayOptions && displayOptions.find((option) => option.id === item.id);
  const liveInitialValue = getBreakpointsSliderValue(item.attribute, targetElement);
  const initialValue = existedOption ? existedOption.initialValue : liveInitialValue;

  return {
    ...item,
    onChange,
    onAfterChange,
    initialValue,
    elementId: targetElementId,
    value: liveInitialValue,
    title: template.accessors.getMetaTranslations(item.title),
    subtitle: template.accessors.getMetaTranslations(item.subtitle),
    annotation: template.accessors.getMetaTranslations(item.annotation),
    userModified: existedOption?.userModified,
  };
};

export default createBreakpointsSliderOption;
