import { selectors } from '@yola/subscription-manager-js';
import store from 'src/js/store';
import getAvailableSubscriptionTypes from 'yola-editor/src/js/modules/user/selectors/available-subscription-types';
import getAvailableNonPaidFeatures from 'yola-editor/src/js/modules/user/selectors/available-non-paid-features';
import getAvailablePlatformComponents from 'yola-editor/src/js/modules/user/selectors/available-platform-components';
import getUserFeatures from 'yola-editor/src/js/modules/user/selectors/user-features';
import calculateFeatureLimits from '../helpers/calculate-feature-limits';

function getFeatureLimits(targetPackage) {
  if (!targetPackage) {
    throw new Error(`${getFeatureLimits.name}: targetPackage is required parameter`);
  }

  const state = store.getState();

  const availableSubscriptionTypes = getAvailableSubscriptionTypes(state);
  const availableNonPaidFeatures = getAvailableNonPaidFeatures(state);
  const availablePlatformComponents = getAvailablePlatformComponents(state);
  const userFeatures = getUserFeatures(state);
  const activeSubscriptions = selectors.getActiveSubscriptions(state);

  return calculateFeatureLimits({
    availablePlatformComponents,
    activeSubscriptions,
    availableSubscriptionTypes,
    availableNonPaidFeatures,
    userFeatures,
    targetPackage,
  });
}

export default getFeatureLimits;
