import { i18next } from '@yola/ws-sdk';
import blackList from '../constants/black-list';
import sanitizeString from './sanitize-string';

const encryptedBlackList = blackList.map((word) => sanitizeString(word));

function validatePersonalData(value) {
  if (!value) return null;

  const sanitizedString = sanitizeString(value);
  const containsProhibitedWords = encryptedBlackList.some((word) => sanitizedString.includes(word));

  if (containsProhibitedWords) {
    return i18next.t('The contact form field should not contain personal data requests');
  }

  return null;
}

export default validatePersonalData;
