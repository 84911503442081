import getSecondaryButtonsAdvancedColorsKeys from './get-secondary-buttons-advanced-colors-keys';
import getPrimaryButtonsAdvancedColorsKeys from './get-primary-buttons-advanced-colors-keys';
import getAdvancedColorsKeys from './get-advanced-colors-keys';

const resetAdvancedColor = ({ colorKey, backgroundColorKey, colorPalette }) => {
  const advancedColorsKeys = getAdvancedColorsKeys(backgroundColorKey);
  const advancedColors = { ...colorPalette.advancedColors };

  const filteredAdvancedColors = Object.fromEntries(
    Object.entries(advancedColors).filter(([key]) => {
      if (
        colorKey === advancedColorsKeys.wsSecondaryButtonBackground ||
        colorKey === advancedColorsKeys.wsSecondaryButtonText
      ) {
        const secondaryButtonsKeys = getSecondaryButtonsAdvancedColorsKeys(colorKey);
        return secondaryButtonsKeys.every((buttonKey) => !key.includes(buttonKey));
      }

      if (
        colorKey === advancedColorsKeys.wsPrimaryButtonBackground ||
        colorKey === advancedColorsKeys.wsPrimaryButtonText
      ) {
        const primaryButtonsKeys = getPrimaryButtonsAdvancedColorsKeys(colorKey);
        return primaryButtonsKeys.every((buttonKey) => !key.includes(buttonKey));
      }

      if (colorKey === advancedColorsKeys.wsNavLink && key === advancedColorsKeys.wsNavLinkActive) {
        return true;
      }

      if (colorKey === advancedColorsKeys.wsInputBorder) {
        const isIncluded =
          key.includes(colorKey) || key.includes(advancedColorsKeys.wsCheckboxBorder);
        return !isIncluded;
      }

      return !key.includes(colorKey);
    })
  );

  return { ...colorPalette, advancedColors: filteredAdvancedColors };
};

export default resetAdvancedColor;
