import websiteDesign from 'src/js/modules/website-design';
import { contrastRatio, HSLColor } from '@yola/yola-palette-generator';

const lightContrastRatio = 1.07;
const darkContrastRatio = 1.4;

const getContrastAdaptedColors = (colors) => {
  const hasNonHSLColor = Object.values(colors).some((color) => !color.startsWith('hsl'));
  if (hasNonHSLColor) return colors;

  const result = {
    'ws-light-0': colors['ws-light-0'],
    'ws-dark-0': colors['ws-dark-0'],
    'ws-primary-0': colors['ws-primary-0'],
    'ws-secondary-0': colors['ws-secondary-0'],
  };

  const { parseHslColorString } = websiteDesign.helpers;

  const lightColor = new HSLColor(...Object.values(parseHslColorString(colors['ws-light-0'])));
  const darkColor = new HSLColor(...Object.values(parseHslColorString(colors['ws-dark-0'])));
  const light1 = new HSLColor(...Object.values(parseHslColorString(colors['ws-light-1'])));

  let ratio = contrastRatio(lightColor, light1);

  while (ratio < lightContrastRatio) {
    if (light1.getLightness() === 0) {
      break;
    }

    const lightLightness = ((light1.getLightness() - 0.01) * 100).toFixed() / 100;
    light1.setLightness(lightLightness);
    ratio = contrastRatio(lightColor, light1);
  }

  result['ws-light-1'] = light1.toCSSValue();

  const dark1 = new HSLColor(...Object.values(parseHslColorString(colors['ws-dark-1'])));
  ratio = contrastRatio(darkColor, dark1);

  while (ratio < darkContrastRatio) {
    if (dark1.getLightness() === 1) {
      break;
    }

    const darkLightness = ((dark1.getLightness() + 0.01) * 100).toFixed() / 100;
    dark1.setLightness(darkLightness);
    ratio = contrastRatio(darkColor, dark1);
  }
  result['ws-dark-1'] = dark1.toCSSValue();

  return result;
};

export default getContrastAdaptedColors;
