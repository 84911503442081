import { blocks, view } from '@yola/ws-sdk';
import isElementWidget from '../../../blocks/helpers/is-element-widget';
import isNonEmptyNode from './verifiers/is-non-empty-node';

const blockTextIsChanged = (config, payload) => {
  const { elementId } = payload;

  if (!elementId) return [];

  const targetElement = view.accessors.getLiveElement(elementId);
  const blockNode = targetElement.closest('ws-block');

  // ws-intense template doesn't have ws-block's, so we don't need
  // to apply automation for legacy template
  if (!blockNode) return [];

  if (isElementWidget(blockNode)) {
    const widgetChildren = blockNode.children.length ? [...blockNode.children] : [blockNode];

    if (widgetChildren.some(isNonEmptyNode)) return [];

    return [
      {
        node: blockNode,
        instructions: [
          {
            enabled: false,
          },
        ],
      },
    ];
  }

  let targetElementChildren = targetElement.children.length
    ? [...targetElement.children]
    : [targetElement];

  const block = blocks.accessors.getBlockByElement(blockNode);
  const { optionalChildren } = blocks.accessors.getBlockSettings(block.id);

  const targetBlockSetting = optionalChildren.find(({ querySelector }) => {
    const el = blockNode.querySelector(querySelector);

    if (el === targetElement || targetElement.closest(querySelector)) {
      targetElementChildren = [...blockNode.querySelectorAll(querySelector)];
      return true;
    }

    return false;
  });

  if (!targetBlockSetting || targetElementChildren.some(isNonEmptyNode)) {
    return [];
  }

  const { id, querySelector } = targetBlockSetting;

  return [
    {
      node: blockNode,
      instructions: [
        {
          blockSettingId: id,
          childrenSelector: querySelector,
          enabled: false,
        },
      ],
    },
  ];
};

export default blockTextIsChanged;
