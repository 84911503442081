import { textFormatting, contentEditable } from '@yola/ws-sdk';
import restoreRange from './restore-range';

const { getElementAttributes, getSelectedElementsByTag } = contentEditable.helpers;

const unwrapElement = (
  selection = textFormatting.accessors.getAdvancedSelection(),
  elementId,
  wrapper
) => {
  const { id, className, tagName } = wrapper;

  const tags = getSelectedElementsByTag(tagName);

  tags.forEach((tag) => {
    tag.removeAttribute('class');
    tag.classList.add(className);

    const attributes = getElementAttributes(tag);
    restoreRange(selection, elementId);
    textFormatting.helpers.wrapperMap.registerWrapper(id, className, tagName, attributes);
    textFormatting.operations.undoWrapper(id);
    textFormatting.helpers.wrapperMap.removeWrapper(id);
    restoreRange(selection, elementId);
  });
};

export default unwrapElement;
