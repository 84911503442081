const generateUniquePageTitle = (pages, pageTitle) => {
  const copiedTitlePrefix = `${pageTitle}-`;
  let newTitleIndex = 0;

  return pages.reduce((newTitle, page) => {
    if (page.title === pageTitle) {
      return newTitle.startsWith(copiedTitlePrefix) ? newTitle : `${copiedTitlePrefix}2`;
    }

    if (!page.title.startsWith(copiedTitlePrefix)) return newTitle;

    const titleIndex = Number(page.title.replace(copiedTitlePrefix, ''));

    if (!titleIndex || newTitleIndex > titleIndex + 1) {
      return newTitle;
    }

    newTitleIndex = titleIndex + 1;

    return `${copiedTitlePrefix}${newTitleIndex}`;
  }, pageTitle);
};

export default generateUniquePageTitle;
