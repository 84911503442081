import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { siteLocales, site, saving, dialogs, i18next, template } from '@yola/ws-sdk';
import { Dropdown, DropdownTrigger, DropdownContent, designSystem } from '@yola/ws-ui';
import { connect } from 'react-redux';
import websiteSettings from 'src/js/modules/website-settings';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import LocaleMenuItem from '../components/locale-menu-item';
import segment from '../../analytics/segment';
import tabIdentifiers from '../../website-settings/constants/tab-identifiers';

const { IconButton, ActionListItem, ActionListDivider, ActionList, EditorHeader } = designSystem;
const { helpers } = websiteSettings;
const { UNSAVED } = saving.constants.statuses;
const {
  track,
  constants: { events },
} = segment;

const showMultilingualTab = (modalProps = {}) => {
  dialogs.operations.show(dialogTypes.WEBSITE_SETTINGS, {
    activeTabId: tabIdentifiers.MULTILINGUAL,
    ...modalProps,
  });
};

function LocalesSelectorContainer(props) {
  const {
    locales,
    currentLocale,
    savingStatus,
    onSetActivePage,
    onUnsavedActivePageChange,
    html,
    htmlCheckpoint,
  } = props;

  const siteId = useMemo(() => site.accessors.getSiteId(), []);
  const templateBuildSlug = useMemo(() => template.accessors.getBuildSlug(), []);
  const countries = useMemo(() => helpers.getCountries(), []);
  const languages = useMemo(() => helpers.getLanguages(), []);
  const language = useMemo(
    () => helpers.getLanguageData(currentLocale, languages, countries),
    [currentLocale, languages, countries]
  );
  const usedLanguages = useMemo(
    () => locales.map((locale) => helpers.getLanguageData(locale, languages, countries)),
    [locales, languages, countries]
  );

  const localeMenuItemClick = (id, oldLanguageCode, newLanguageCode) => {
    if (
      (savingStatus === UNSAVED && html !== htmlCheckpoint) ||
      saving.verifiers.modifiedGlobals()
    ) {
      onUnsavedActivePageChange(onSetActivePage.bind(null, id));
    } else {
      onSetActivePage(id);
    }

    track(events.WEBSITE_LANGUAGE_SWITCHED, {
      siteId,
      oldLanguageCode,
      newLanguageCode,
      templateBuildSlug,
    });
  };

  const onTriggerClick = () => {
    track(events.WEBSITE_LANGUAGE_SWITCHER_CLICKED, {
      siteId,
      templateBuildSlug,
    });
  };

  if (!language || !(locales.length > 1)) return null;
  const flagComponent = language.country.iconComponent;

  return (
    <EditorHeader.LocalesSelectorWrapper>
      <div className="ws-locales-selector">
        <Dropdown drop="bottom-left">
          <DropdownTrigger onClick={onTriggerClick}>
            <IconButton icon={flagComponent} />
          </DropdownTrigger>
          <DropdownContent>
            <ActionList>
              {usedLanguages.map((lang) => (
                <LocaleMenuItem
                  key={lang.code}
                  locale={currentLocale}
                  language={lang}
                  onClick={localeMenuItemClick}
                />
              ))}
              <ActionListDivider />
              <ActionListItem
                onClick={showMultilingualTab}
                title={i18next.t('Manage languages...')}
              />
            </ActionList>
          </DropdownContent>
        </Dropdown>
      </div>
    </EditorHeader.LocalesSelectorWrapper>
  );
}

LocalesSelectorContainer.propTypes = {
  locales: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  currentLocale: PropTypes.string.isRequired,
  savingStatus: PropTypes.string.isRequired,
  onSetActivePage: PropTypes.func.isRequired,
  onUnsavedActivePageChange: PropTypes.func.isRequired,
  htmlCheckpoint: PropTypes.string,
  html: PropTypes.string,
};

LocalesSelectorContainer.defaultProps = {
  htmlCheckpoint: null,
  html: null,
};

const mapStateToProps = (state) => {
  const activePageId = site.selectors.getActivePageId(state);
  const activePage = site.selectors.getPage(state, activePageId);
  const htmlCheckpoint = activePage && activePage.htmlCheckpoint;
  const html = activePage && activePage.html;
  return {
    locales: siteLocales.selectors.getSiteLocales(state),
    currentLocale: siteLocales.selectors.getCurrentLocale(state),
    savingStatus: saving.selectors.getStatus(state),
    htmlCheckpoint,
    html,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSetActivePage(pageId) {
    dispatch(site.actions.setActivePage(pageId));
  },
  onUnsavedActivePageChange(callback) {
    dispatch(dialogs.actions.show(dialogTypes.UNSAVED_WARNING, { callback }));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(LocalesSelectorContainer);
