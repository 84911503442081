export const PROTOCOL_ICON_MAP = {
  'viber:': 'viber',
  'whatsapp:': 'whatsapp',
  'tg:': 'telegram',
  'skype:': 'skype',
  'mailto:': 'e-mail',
  'callto:': 'phone',
  'tel:': 'phone',
};

export const HOST_ICON_MAP = {
  'dribbble.com': 'dribbble',
  'facebook.com': 'facebook',
  'fb.com': 'facebook',
  'twitter.com': 'twitter-x',
  'instagram.com': 'instagram',
  'linkedin.com': 'linkedin',
  'pinterest.com': 'pinterest',
  'vk.com': 'vk',
  'github.com': 'github',
  'medium.com': 'medium',
  'soundcloud.com': 'soundcloud',
  'tripadvisor.com': 'tripadvisor',
  'youtube.com': 'youtube',
  'youtu.be': 'youtube',
  'behance.net': 'behance',
  'twitch.tv': 'twitch',
  'wa.me': 'whatsapp',
  't.me': 'telegram',
  'm.me': 'messenger',
  'join.skype.com': 'skype',
  'skype.com': 'skype',
  'tiktok.com': 'tiktok',
};

export const SUPPORTED_SOCIALS_HOSTS_MAP = {
  ...HOST_ICON_MAP,
  'triller.co': 'triller',
  'periscope.tv': 'periscope',
  'vimeo.com': 'vimeo',
  'valence.community': 'valence',
  'untappd.com': 'untappd',
  'elpha.com': 'elpha',
  'yubo.live': 'yubo',
  'likee.video': 'likee',
  'academia.edu': 'academia',
  'tumblr.com': 'tumblr',
  'whatsapp.com': 'whatsapp',
  'snapchat.com': 'snapchat',
  'reddit.com': 'reddit',
  'telegram.org': 'telegram',
  'mix.com': 'mix',
  'livejournal.com': 'livejournal',
  'bubbly.net': 'bubbly',
  'flickr.com': 'flickr',
  'yelp.com': 'yelp',
  'viber.com': 'viber',
  'discord.com': 'discord',
  'badoo.com': 'badoo',
  'patreon.com': 'patreon',
  'spotify.com': 'spotify',
  'influencermarketinghub.com/linktree': 'linktree',
};
