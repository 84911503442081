import { useState, useEffect } from 'react';

const usePreloader = (isLoaded) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let timer = null;
    if (isLoaded) {
      setProgress(100);
    } else {
      timer = setInterval(() => {
        setProgress((oldProgress) => {
          const diff = Math.random() * 10;
          return Math.min(oldProgress + diff, 90);
        });
      }, 500);
    }

    return () => {
      clearInterval(timer);
    };
  }, [isLoaded]);
  return progress;
};

export default usePreloader;
