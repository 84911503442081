import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import withFeatureFlags from 'yola-editor/src/js/modules/feature-flags/hoc/with-feature-flags';

const { ActionButton } = designSystem;

function AiWriterTrigger({ elementId, isAllowedFunc, width, onClick }) {
  if (!elementId) return null;
  const isEnabled = isAllowedFunc();

  return (
    <div className="ws-ai-writer-trigger" style={{ width: `${width}px` }}>
      <ActionButton
        appearance="accent"
        format="solid-white"
        fullWidth
        rounded={false}
        label="Writer"
        disabled={!isEnabled}
        iconGlyph="ai"
        onClick={onClick}
      />
    </div>
  );
}

AiWriterTrigger.propTypes = {
  isAllowedFunc: PropTypes.func,
  elementId: PropTypes.string,
  width: PropTypes.number.isRequired,
  onClick: PropTypes.func,
};

AiWriterTrigger.defaultProps = {
  isAllowedFunc: () => true,
  elementId: '',
  onClick: Function.prototype,
};

// to ensure that feature flags is loaded, they are used in control `matches` function
export default withFeatureFlags(['ai_text_assistant_rewrite', 'ai_text_assistant_write'])(
  AiWriterTrigger
);
