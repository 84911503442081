import React from 'react';

const EmailIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="144" height="90" viewBox="0 0 144 90" fill="none">
    <rect x="44" y="25" width="56" height="40" rx="4" fill="#DADDE0" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.932 28.2589C46.3413 27.669 47.1513 27.5226 47.7412 27.9319L69.9608 43.3497C70.8865 43.992 72.1136 43.992 73.0393 43.3497L95.259 27.9319C95.8488 27.5226 96.6588 27.669 97.0681 28.2589C97.4774 28.8488 97.3311 29.6588 96.7412 30.0681L74.5215 45.4858C72.7045 46.7466 70.2956 46.7466 68.4786 45.4858L46.259 30.0681C45.6691 29.6588 45.5227 28.8488 45.932 28.2589Z"
      fill="#646C75"
    />
  </svg>
);

export default EmailIcon;
