import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { view } from '@yola/ws-sdk';
import useEventListener from 'src/js/modules/utils/custom-hooks/use-event-listener';
import useHighlighter from '../hooks/use-highlighter';

const FocalPointImageResizer = ({ elementId }) => {
  const scrollPosition = useSelector(view.selectors.getScrollPosition);
  const element = view.accessors.getLiveElement(elementId);
  const [highlighterRect] = useHighlighter({ element, scrollPosition });

  useEventListener(
    'contextmenu',
    (event) => {
      event.preventDefault();
    },
    document
  );

  return (
    <Fragment>
      <div className="ws-focal-point-highlight" style={highlighterRect} />
    </Fragment>
  );
};

FocalPointImageResizer.propTypes = {
  elementId: PropTypes.string.isRequired,
  targetElementBounds: PropTypes.shape({
    top: PropTypes.number.isRequired,
    bottom: PropTypes.number.isRequired,
  }).isRequired,
};

export default FocalPointImageResizer;
