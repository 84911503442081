import isClickedOutside from './is-clicked-outside';
import isEscPressed from './is-esc-pressed';
import isInteractiveElement from './is-interactive-element';
import isTouchSupport from './is-touch-support';

export default {
  isClickedOutside,
  isEscPressed,
  isInteractiveElement,
  isTouchSupport,
};
