import { blocks } from '@yola/ws-sdk';
import get from 'lodash.get';

const isRecomendedSurfaceAllowable = (node, surfaces) => {
  const blockData = blocks.accessors.getBlockByElement(node);
  const displayOptions = get(blockData, 'availableSettings.displayOptions', []);
  const blockSurfaces = displayOptions.find((el) => el.id === 'surfaces');
  const blockSurfacesOptions = get(blockSurfaces, 'options', []);

  return blockSurfacesOptions.some((option) => option.value === surfaces);
};

export default isRecomendedSurfaceAllowable;
