import get from 'lodash.get';
import { view } from '@yola/ws-sdk';

export default function injectEditorColors(liveDocument) {
  const defaultView = get(liveDocument, 'defaultView', null);
  const ownerDocument = get(liveDocument, 'defaultView.parent.document.documentElement', null);

  if (!defaultView && !ownerDocument) return;

  const computedStyle = defaultView.getComputedStyle(ownerDocument);

  view.helpers.injectCustomizations(defaultView.document.documentElement, {
    wsEditorGray200: computedStyle.getPropertyValue('--gray-200'),
    wsEditorGray400: computedStyle.getPropertyValue('--gray-400'),
    wsEditorGray700: computedStyle.getPropertyValue('--gray-700'),
    wsEditorGray800: computedStyle.getPropertyValue('--gray-800'),
    wsEditorInfo600: computedStyle.getPropertyValue('--info-600'),
    wsEditorWhite: computedStyle.getPropertyValue('--white'),
    wsEditorWarning700: computedStyle.getPropertyValue('--warning-700'),
    wsEditorWarning800: computedStyle.getPropertyValue('--warning-800'),
  });
}
