import React from 'react';
import PropTypes from 'prop-types';

function PlayIcon(props) {
  const { size, ...otherProps } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <path d="M12 1.5a10.5 10.5 0 100 21 10.5 10.5 0 000-21zm3.38 10.66l-5.12 3.73a.19.19 0 01-.3-.16V8.3a.19.19 0 01.3-.15l5.12 3.72a.18.18 0 010 .3z" />
    </svg>
  );
}

PlayIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fill: PropTypes.string,
};

PlayIcon.defaultProps = {
  size: '24',
  fill: 'currentColor',
};

export default PlayIcon;
