import React from 'react';
import PropTypes from 'prop-types';
import { dialogs, view, site, i18next, linkEditing } from '@yola/ws-sdk';
import LinkSettingContainer from 'src/js/modules/common/containers/link-setting-container';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import helpers from '../helpers';
import commonHelpers from '../../../common/helpers';

const closeDialog = () => {
  dialogs.operations.hide();
};

const { buildLinkElement } = helpers;
const { getItemClickAction } = commonHelpers;
const { linkTypes } = linkEditing.constants;

class EditLinkDialog extends React.Component {
  constructor(props) {
    super(props);

    const { elementId, initialConfig } = props;
    this.state = {
      isOverlayClicked: false,
    };
    this.element = view.accessors.getLiveElement(elementId);
    this.config = initialConfig || linkEditing.accessors.getLinkConfig(this.element);
    this.innerText = this.element.innerText.trim();
    this.activePageId = site.accessors.getActivePageId();
    this.changePayload = null;

    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.setIsOverlayClicked = this.setIsOverlayClicked.bind(this);
  }

  onCancel() {
    const { onDialogCancel } = this.props;

    onDialogCancel();
    closeDialog();
  }

  onSubmit({ config, title, customPath, setDownloadUrlAdjusted }) {
    const { elementId, onDialogMainAction } = this.props;
    const { isOverlayClicked } = this.state;

    onDialogMainAction({
      elementId,
      triggerId: isOverlayClicked ? 'dialog-background' : 'submit-button',
      itemClickAction: getItemClickAction(config.linkType),
      itemTitleAdjusted: this.innerText !== title,
      dialogType: dialogTypes.EDIT_LINK_DIALOG,
      addedFile: config.linkType === linkTypes.DOWNLOAD,
      customPath,
      setDownloadUrlAdjusted,
    });

    if (this.innerText !== title) {
      view.operations.replaceElement(buildLinkElement(elementId, config, title), elementId);
    } else {
      view.operations.setElementAttribute(
        elementId,
        linkEditing.constants.common.LINK_CONFIG_ATTRIBUTE,
        JSON.stringify(config)
      );
    }

    closeDialog();
  }

  setIsOverlayClicked(value) {
    this.setState({
      isOverlayClicked: value,
    });
  }

  render() {
    const { elementId, initialTitle, offsetX, offsetY, currentConfig } = this.props;

    return (
      <LinkSettingContainer
        showTitle={Boolean(this.innerText)}
        initialTitle={initialTitle || this.innerText}
        initialConfig={this.config}
        currentConfig={currentConfig}
        element={this.element}
        offsetX={offsetX}
        offsetY={offsetY}
        handleCancel={this.onCancel}
        handleSubmit={this.onSubmit}
        setIsOverlayClicked={this.setIsOverlayClicked}
        sourceDialog={{
          id: dialogTypes.EDIT_LINK_DIALOG,
          props: {
            elementId,
          },
        }}
        captions={{
          dialogTitle: i18next.t('Link settings'),
          title: i18next.t('Title'),
          actionTitle: i18next.t('Click action'),
          actionSubtitle: i18next.t('When user clicks on the link, take this action'),
          titleAnnotation: i18next.t('Display this text inside the link'),
          save: i18next.t('Submit'),
        }}
      />
    );
  }
}

EditLinkDialog.propTypes = {
  elementId: PropTypes.string.isRequired,
  initialConfig: PropTypes.shape({}),
  currentConfig: PropTypes.shape({}),
  initialTitle: PropTypes.string,
  // Passed from ws-editor's `dialogs-container`:
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,
};

EditLinkDialog.defaultProps = {
  initialConfig: null,
  currentConfig: null,
  initialTitle: null,
  offsetX: null,
  offsetY: null,
};

export default EditLinkDialog;
