import trackAppOpened from './app-opened';
import trackBlockAdded from './block-added';
import trackBlockShifted from './block-shifted';
import trackBlockDuplicated from './block-duplicated';
import trackBlockDeleted from './block-deleted';
import trackWidgetDeleted from './widget-deleted';
import trackBlockCopied from './block-copied';
import trackBlockCutout from './block-cutout';
import trackBlockPasted from './block-pasted';
import trackPageCreated from './page-create';
import trackContentCopied from './content-copied';
import trackContentCutout from './content-cutout';
import trackContentPasted from './content-paste';
import trackSiteSaveInitiated from './site-save-initiated';
import trackSiteSaveCompleted from './site-save-completed';
import trackSiteLoaded from './site-loaded';
import trackWidgetAdded from './widget-added';
import trackWidgetShifted from './widget-shifted';
import trackWidgetDuplicated from './widget-duplicated';
import trackSiteElementClicked from './site-element-clicked';
import trackSelectIconDialogDisplayed from './select-icon-dialog-displayed';
import trackSelectIconSearchClicked from './select-icon-search-clicked';
import trackSelectIconSearchPerformed from './select-icon-search-performed';
import trackSelectIconIconClicked from './select-icon-icon-clicked';
import trackSelectIconDialogSubmitted from './select-icon-dialog-submitted';
import trackSelectIconDialogCanceled from './select-icon-dialog-canceled';
import trackSelectIconIconError from './select-icon-icon-error';
import trackSelectIconErrorReloadClicked from './select-icon-error-reload-clicked';
import trackSelectIconStyleFilterSelected from './select-icon-style-filter-selected';
import trackSelectIconTypeFilterSelected from './select-icon-type-filter-selected';
import trackSelectIconErrorLoadingIcons from './select-icon-error-loading-icons';
import trackGoogleMapEmbedSearchClicked from './google-map-embed-search-clicked';
import trackGoogleMapEmbedSearchPerformed from './google-map-embed-search-performed';
import trackPhotoStockSearchClicked from './photo-stock-search-clicked';
import trackPhotoStockSearchPerformed from './photo-stock-search-performed';
import trackPageManagerSearchClicked from './page-manager-search-clicked';
import trackPageManagerSearchPerformed from './page-manager-search-performed';
import trackLinkSettingsInputClicked from './link-settings-input-clicked';
import trackEvent from './track-event';
import trackTextElementSizeAdjusted from './text-element-size-adjusted';
import trackDialogActiveTabChanged from './dialog-active-tab-changed';
import trackUpgradeTriggerClicked from './upgrade-trigger-clicked';
import trackCodeEditorFocused from './code-editor-focused';
import trackAsyncEvent from './track-async-event';
import trackAddAiPageTriggerClicked from './add-ai-page-trigger-clicked';
import trackUndoTriggerClicked from './undo-trigger-clicked';
import trackRedoTriggerClicked from './redo-trigger-clicked';

export default {
  trackAppOpened,
  trackBlockAdded,
  trackBlockShifted,
  trackBlockDeleted,
  trackWidgetDeleted,
  trackBlockCopied,
  trackBlockCutout,
  trackBlockPasted,
  trackPageCreated,
  trackContentCopied,
  trackContentCutout,
  trackContentPasted,
  trackBlockDuplicated,
  trackSiteSaveInitiated,
  trackSiteSaveCompleted,
  trackSiteLoaded,
  trackWidgetAdded,
  trackWidgetShifted,
  trackWidgetDuplicated,
  trackSiteElementClicked,
  trackSelectIconDialogDisplayed,
  trackSelectIconSearchClicked,
  trackSelectIconSearchPerformed,
  trackSelectIconIconClicked,
  trackSelectIconDialogSubmitted,
  trackSelectIconDialogCanceled,
  trackSelectIconIconError,
  trackSelectIconErrorReloadClicked,
  trackSelectIconStyleFilterSelected,
  trackSelectIconTypeFilterSelected,
  trackSelectIconErrorLoadingIcons,
  trackGoogleMapEmbedSearchClicked,
  trackGoogleMapEmbedSearchPerformed,
  trackPhotoStockSearchClicked,
  trackPhotoStockSearchPerformed,
  trackPageManagerSearchClicked,
  trackPageManagerSearchPerformed,
  trackEvent,
  trackTextElementSizeAdjusted,
  trackLinkSettingsInputClicked,
  trackDialogActiveTabChanged,
  trackUpgradeTriggerClicked,
  trackCodeEditorFocused,
  trackAsyncEvent,
  trackAddAiPageTriggerClicked,
  trackUndoTriggerClicked,
  trackRedoTriggerClicked,
};
