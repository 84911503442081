import connectedStore from 'yola-editor/src/js/store/connected-store';
import getActiveDialog from '../selectors/active-dialog';

const isVisible = () => {
  const store = connectedStore.get();
  const state = store.getState();

  return Boolean(getActiveDialog(state).type);
};

export default isVisible;
