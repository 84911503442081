import { verifiers } from '@yola/subscription-manager-js';

function isFreeDomainAvailable(activeSubscriptions, allSubscriptionsList, isFreeDomainEnabled) {
  const hasAnnualPaidHostingSubscription =
    verifiers.hasAnnualPaidHostingSubscription(activeSubscriptions);
  const hasEverHadDomainSubscription = allSubscriptionsList.some(({ type }) =>
    verifiers.isDomainPackageType(type)
  );

  return (
    Boolean(isFreeDomainEnabled) &&
    hasAnnualPaidHostingSubscription &&
    !hasEverHadDomainSubscription
  );
}

export default isFreeDomainAvailable;
