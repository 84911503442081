import { i18next } from '@yola/ws-sdk';

const extendItemsFieldsActions = (fields, [openSettings, deleteField]) =>
  fields.map((field) => {
    const settingsAction = {
      label: i18next.t('Settings'),
      glyph: 'settings',
      onClick: () => openSettings(field.id),
    };
    const deleteAction = {
      label: i18next.t('Delete'),
      glyph: 'trash',
      onClick: () => deleteField(field),
    };

    const actions = [settingsAction, deleteAction];

    return {
      ...field,
      actions,
      iconGlyph: 'drag-icon',
      iconType: 'light',
      allowChildren: true,
    };
  });

export default extendItemsFieldsActions;
