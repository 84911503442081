import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { view, dialogs } from '@yola/ws-sdk';
import customUI from 'src/js/modules/custom-ui';
import contextMenu from 'src/js/modules/context-menu';
import InteractiveTool from '../components/interactive-tool';
import getTooltipInfo from '../helpers/get-tooltip-info';

function InteractiveToolContainer() {
  const hoveredElementId = useSelector(view.selectors.getHoveredElement);
  const selectedElementId = useSelector(view.selectors.getSelectedElement);
  const focusedElementId = useSelector(view.selectors.getFocusedElement);
  const isViewLoaded = useSelector(view.selectors.getLoadedStatus);
  const isDialogVisible = useSelector(dialogs.verifiers.isVisible);
  const customUIVisibility = useSelector(customUI.selectors.getVisibilityState);
  const isContextMenuVisible = useSelector(contextMenu.selectors.getVisibilityStatus);
  const scrollPosition = useSelector(view.selectors.getScrollPosition);
  const liveDocument = view.accessors.getLiveDocument();

  const [tooltipInfo, setTooltipInfo] = useState(null);

  useEffect(() => {
    if (
      !isViewLoaded ||
      !hoveredElementId ||
      isDialogVisible ||
      customUIVisibility ||
      isContextMenuVisible
    ) {
      setTooltipInfo(null);
      return;
    }

    const currentTooltipInfo = getTooltipInfo(hoveredElementId);
    setTooltipInfo(currentTooltipInfo);
  }, [hoveredElementId, isViewLoaded, isDialogVisible, customUIVisibility, isContextMenuVisible]);

  useEffect(() => {
    if (!liveDocument) {
      return;
    }

    const cursorStyle = tooltipInfo?.shouldSetCursorPointer ? 'pointer' : 'default';
    liveDocument.documentElement.style.setProperty('--ws-cursor-style', cursorStyle);
  }, [tooltipInfo, liveDocument]);

  if (!tooltipInfo) {
    return null;
  }

  const { targetElementId, tooltipTitle } = tooltipInfo;

  if (targetElementId === selectedElementId || targetElementId === focusedElementId) {
    return null;
  }

  return (
    <InteractiveTool
      key={targetElementId}
      elementId={targetElementId}
      scrollPosition={scrollPosition}
      tooltipTitle={tooltipTitle}
    />
  );
}

export default InteractiveToolContainer;
