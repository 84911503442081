import blocks from 'src/js/modules/blocks';
import hasBackgroundContainer from './has-background-container';
import isImageContainer from './is-image-container';
import { getSettings } from '../settings';

const getContainerBounds = (node) => {
  const settings = getSettings();

  if (blocks.verifiers.isWsBlock(node) || hasBackgroundContainer(node)) {
    const backgroundContainer = node.querySelector(settings.backgroundContainer);
    return backgroundContainer.getContainerBounds();
  }

  if (isImageContainer(node)) {
    return node.getContainerBounds();
  }

  return null;
};

export default getContainerBounds;
