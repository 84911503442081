import React from 'react';
import PropTypes from 'prop-types';
import { i18next } from '@yola/ws-sdk';
import bowser from 'yola-bowser';
import classNames from 'classnames';
import { designSystem } from '@yola/ws-ui';
import dateTime from 'src/js/modules/date-time';

const { Paragraph, ActionButton } = designSystem;

function BackupItem(props) {
  const { onSelect, onDelete, backup, locale, timeStyle } = props;
  const isTouchDevice = bowser.mobile || bowser.tablet;

  const getCreatedTimeUiLabel = (createdTimeValue) =>
    dateTime.helpers.formatTimeUiLabel(createdTimeValue, locale, { timeStyle });

  const getPagesCountLabel = (count) =>
    i18next.t('{count, plural, one {# page} other {# pages}}', { count });

  const getBackupName = (backupInstance) => {
    if (backupInstance.name) {
      return backupInstance.name;
    }

    if (backupInstance.isAutomatic) {
      return i18next.t('Automatic backup');
    }

    return i18next.t('Untitled');
  };

  return (
    <div
      className={classNames('ws-site-backups__group-list-item', {
        'ws-site-backups__group-list-item--always-visible-buttons': isTouchDevice,
      })}
      onClick={onSelect}
    >
      <div className="ws-site-backups__item-description">
        <Paragraph isClipped>{getBackupName(backup)}</Paragraph>
        <Paragraph appearance="medium-emphasis" size="small">
          {`${getCreatedTimeUiLabel(backup.createdAt)} - ${getPagesCountLabel(backup.pagesCount)}`}
        </Paragraph>
      </div>

      <div className="ws-site-backups__item-control-buttons">
        <ActionButton
          iconGlyph="back-up"
          format="text"
          onClick={(event) => {
            event.stopPropagation();
            onSelect();
          }}
        />

        <ActionButton
          iconGlyph="trash"
          format="text"
          onClick={(event) => {
            event.stopPropagation();
            onDelete();
          }}
        />
      </div>
    </div>
  );
}

BackupItem.propTypes = {
  backup: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    createdAt: PropTypes.string,
    pagesCount: PropTypes.number,
    isAvailable: PropTypes.bool,
    isAutomatic: PropTypes.bool,
  }).isRequired,
  timeStyle: PropTypes.oneOf(['full', 'long', 'medium', 'short']),
  onDelete: PropTypes.func,
  onSelect: PropTypes.func,
  locale: PropTypes.string.isRequired,
};

BackupItem.defaultProps = {
  onSelect: Function.prototype,
  onDelete: Function.prototype,
  timeStyle: 'medium',
};

export default BackupItem;
