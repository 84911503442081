import React from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { i18next, dialogs, site, globals, view } from '@yola/ws-sdk';
import DeveloperConsoleDialog from '../components/developer-console-dialog';

function DeveloperConsoleContainer({ page, globalsData, onDialogMainAction, onDialogCancel }) {
  const dispatch = useDispatch();

  function onSubmit({ pageHTML, changedGlobals }) {
    onDialogMainAction();

    const globalsToUpdate = changedGlobals.map((global) => ({
      id: global.id,
      newHTML: global.html,
    }));

    dispatch(view.actions.changeHTML(pageHTML, globalsToUpdate));
    dialogs.operations.hide();
  }

  function onCancel() {
    onDialogCancel();
    dialogs.operations.hide();
  }

  const captions = {
    title: i18next.t('Developer console'),
    select: {
      // i18next function isn't added expressly. Current functionality now is used only by employees,
      // so we can skip adding multilingual translation to save costs & time
      label: 'Choose file:',
    },
    submit: i18next.t('Submit'),
    cancel: i18next.t('Cancel'),
  };

  return (
    <DeveloperConsoleDialog
      captions={captions}
      pageHTML={page.html}
      globals={globalsData}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  );
}

const mapStateToProps = (state) => ({
  page: site.selectors.getPage(state, site.selectors.getActivePageId(state)),
  globalsData: globals.selectors.getGlobals(state),
});

DeveloperConsoleContainer.propTypes = {
  page: PropTypes.shape({
    html: PropTypes.string.isRequired,
  }).isRequired,
  globalsData: PropTypes.array.isRequired,
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(DeveloperConsoleContainer);
