import { verifiers } from '@yola/subscription-manager-js';
import sortBySubscriptionType from 'yola-editor/src/js/modules/subscriptions/helpers/sort-by-subscription-type';
import getFeatureLimits from 'yola-editor/src/js/modules/features/accessors/get-feature-limits';
import isUpsellAllowed from './is-upsell-allowed';

function toPrioritizedList(availablePackages) {
  const packagesList = Object.entries(availablePackages)
    .filter(([type, config]) => verifiers.isPaidHostingPackageType(type) && config.available)
    .reduce(
      (result, [type, config]) => [
        ...result,
        {
          type,
          ...config,
        },
      ],
      []
    );

  return sortBySubscriptionType(packagesList, (targetPackage) => targetPackage.type);
}

function getMinRequiredHostingPlan(upgradeDetails, availablePackages) {
  const prioritizedPackages = toPrioritizedList(availablePackages);

  const minRequiredPlan = prioritizedPackages.find((targetPackage) =>
    isUpsellAllowed(upgradeDetails, getFeatureLimits(targetPackage))
  );

  return minRequiredPlan ? minRequiredPlan.type : '';
}

export default getMinRequiredHostingPlan;
