import { hooks, extensions } from '@yola/ws-sdk';
import customTools from 'src/js/modules/custom-tools';
import focalPoint from 'src/js/modules/focal-point';
import assignGroupId from 'src/js/modules/extensions/common/helpers/assign-group-id';
import config from './constants/config';
import replaceLogoControl from './controls/replace-logo-control';
import uploadImageControl from './controls/upload-image-control';
import mediaSettingsControl from './controls/media-settings-control';
import registerContextMenuActions from './controls/register-context-menu-actions';
import cropControl from './controls/crop-control';
import logoResizeTool from './tools/logo-resize';
import imageResizeTool from './tools/image-resize';
import imagePositioningTool from './tools/image-positioning';
import updateWidgetMediaGroupId from './helpers/update-widget-media-group-id';
import editBackgroundImageControl from './controls/edit-background-image-control';
import { MEDIA_GROUP_ATTRIBUTE } from './constants/media-container';
import handlePasteEvent from './helpers/handle-paste-event';
import handleCopyEvent from './helpers/handle-copy-event';

const {
  ON_AFTER_DUPLICATE_STATIC_ELEMENT,
  ON_AFTER_INSERT_STATIC_ELEMENT,
  ON_LIVE_DOCUMENT_COPY,
  ON_LIVE_DOCUMENT_PASTE,
} = hooks.availableHooks;

const controlList = [
  editBackgroundImageControl,
  cropControl,
  uploadImageControl,
  replaceLogoControl,
  mediaSettingsControl,
];

const extension = {
  slug: config.slug,
  title: 'WS image',
  description: 'Extension for editing images',
  defaults: {
    querySelector:
      'img[src], .background-image, ws-block[background-image], ws-global.logo-image-wrapper',
    blockSelector: 'ws-block',
    blockWithBackgroundQuerySelector: 'ws-block[background-image]',
    resizeQuerySelector: 'img[src].logo-image',
    imageContainer: 'ws-media-container',
    logoContainer: 'ws-global.logo-image-wrapper',
    galleryItemSelector: 'ws-media-container[origin-src]',
    backgroundContainer: 'ws-background-container',
    backgroundImageNodeSelector: '.background-image',
    imageResizeSelector: 'ws-media-container',
    nonResizableElementsSelector:
      '.section-cover ws-media-container, [fullscreen] ws-media-container',
    tooltipCursorPointerSelector: '.ws-media-content-container',
    imageAltSelector: 'img',
    imageAltTargetSelector: 'ws-media-container, picture, img',
    imageAltRestrictedParentsSelector: '',
    imageActionSelector: 'img',
    imageActionTargetSelector: 'ws-media-container, picture, img',
    imageActionRestrictedParentsSelector: 'ws-media-container[origin-src]',
    restrictSingleImageResizeSelector: '[id^="ws-block-gallery-full-width"]',
    gallerySliderParentSelector: '[id^="ws-block-slider-gallery"]',
    placeholderSelector: '[placeholder], [background-placeholder]',
    imagePositioningSelector: '[slot="block-overlaying-media"] ws-media-container',
    imagePositioningRestrictedParentsSelector: '',
  },
  ui: {
    control: controlList,
  },
  init() {
    const settings = extensions.accessors.getExtensionSettings(config.slug);
    focalPoint.settings.setSetting('imageContainer', settings.imageContainer);
    focalPoint.settings.setSetting('backgroundContainer', settings.backgroundContainer);
    focalPoint.settings.setSetting('galleryItem', settings.galleryItemSelector);
    focalPoint.settings.setSetting(
      'backgroundImageNodeSelector',
      settings.backgroundImageNodeSelector
    );
    focalPoint.settings.setSetting('nonResizableElements', settings.nonResizableElementsSelector);

    const onAfterInsert = (element) => {
      assignGroupId({
        container: element,
        querySelector: settings.imageContainer,
        groupAttribute: MEDIA_GROUP_ATTRIBUTE,
      });
    };

    hooks.operations.subscribe(ON_AFTER_INSERT_STATIC_ELEMENT, onAfterInsert);
    hooks.operations.subscribe(ON_AFTER_DUPLICATE_STATIC_ELEMENT, updateWidgetMediaGroupId);
    hooks.operations.subscribe(ON_LIVE_DOCUMENT_PASTE, handlePasteEvent);
    hooks.operations.subscribe(ON_LIVE_DOCUMENT_COPY, handleCopyEvent);
    customTools.operations.registerTool(logoResizeTool);
    customTools.operations.registerTool(imageResizeTool);
    customTools.operations.registerTool(imagePositioningTool);

    registerContextMenuActions();
  },
};

export default extension;
