import { site, blocks, view, template } from '@yola/ws-sdk';
import getParentBlockByElement from 'src/js/modules/blocks/helpers/get-parent-block-by-element';
import constants from '../constants';
import track from '../track';

const trackGoogleMapEmbedSearchPerformed = ({ targetElementId, searchTerm, searchResults }) => {
  if (!searchTerm.trim()) return;

  const element = view.accessors.getLiveElement(targetElementId);
  const targetElement = getParentBlockByElement(element);
  const targetBlockId = blocks.accessors.getBlockIdByElement(targetElement);
  const targetBlockVariationId = blocks.accessors.getVariationIdByElement(targetElement);

  track(constants.events.GOOGLE_MAP_EMBED_SEARCH_PERFORMED, {
    siteId: site.accessors.getSiteId(),
    templateBuildSlug: template.accessors.getBuildSlug(),
    targetBlockId,
    targetBlockVariationId,
    searchTerm,
    searchResults,
  });
};

export default trackGoogleMapEmbedSearchPerformed;
