import { SURFACES_ATTRIBUTE } from '../../../constants/attributes';
import getSubmoduleConfig from '../../../helpers/get-submodule-config';
import isEmptyCustomBlock from './is-empty-custom-block';
import { SUBMODULE_NAME } from '../constants';

const shouldRevertWhitespaceAutomation = (targetNode, prevSiblingNode) => {
  if (!targetNode) {
    return false;
  }

  if (!prevSiblingNode) {
    return true;
  }

  const { affectedBlocksSelector, restrictedBlocksSelector, restrictedPrevBlocksSelector } =
    getSubmoduleConfig(SUBMODULE_NAME);

  if (!targetNode.matches(affectedBlocksSelector)) {
    return false;
  }

  if (
    targetNode.matches(restrictedBlocksSelector) ||
    prevSiblingNode.matches(restrictedBlocksSelector) ||
    prevSiblingNode.matches(restrictedPrevBlocksSelector) ||
    isEmptyCustomBlock(prevSiblingNode)
  ) {
    return true;
  }

  const targetSurfacesValue = targetNode.getAttribute(SURFACES_ATTRIBUTE);
  const prevSiblingSurfacesValue = prevSiblingNode.getAttribute(SURFACES_ATTRIBUTE);
  const targetSurfaces = targetSurfacesValue ? targetSurfacesValue.split(' ') : [];
  const prevSiblingSurfaces = prevSiblingSurfacesValue ? prevSiblingSurfacesValue.split(' ') : [];

  if (targetSurfaces[0] !== prevSiblingSurfaces[0]) {
    return true;
  }

  return false;
};

export default shouldRevertWhitespaceAutomation;
