const livePreviewMode = `
  <script type="text/javascript" charset="utf-8">
    window.livePreviewMode = true;
  </script>
`;

const compileLivePreviewFrameHtml = ({
  compiledHeader,
  blockDependencies,
  preloadedEntities,
  bodyClassName,
  wrapperClassName,
}) =>
  `
  <!DOCTYPE html>
  <html>
    <head>
      ${compiledHeader}
      ${blockDependencies}
      ${preloadedEntities}
      ${livePreviewMode}
    </head>
    <body class="${bodyClassName}">
      <div class="${wrapperClassName}"></div>
    </body>
  </html>`.trim();

export default compileLivePreviewFrameHtml;
