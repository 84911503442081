import { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { site, i18next, integration, serviceClient, i18n } from '@yola/ws-sdk';
import { reactHookForm } from '@yola/ws-ui';
import utils from '../../utils';
import websiteSettings from '../../website-settings';
import constants from '../constants';
import validators from '../helpers/validators';
import aiConstants from '../../ai/constants';
import segment from '../../analytics/segment';
import getUpdatedFields from '../helpers/get-updated-fields';

const {
  constants: { events },
  track,
} = segment;

const { sourceIds } = aiConstants;

const { useForm, useFormState, useFieldArray } = reactHookForm;
const { createEmailValidator, createPhoneValidator } = utils.validators;
const { createLogoSizeValidator, createLogoTypeValidator, createSocialLinkUrlValidator } =
  validators;
const { fieldNames, setupSteps } = constants;
const {
  constants: { settingLengthLimits },
  helpers: { getFieldValuesFromSettings },
} = websiteSettings;

const getFields = ({
  control,
  siteName,
  maxLogoSize,
  socialLinks,
  isBusinessNameSameAsSiteName,
  handleSiteNameChange,
  handleBusinessNameChange,
  handleBusinessNameAsSiteNameChange,
  handleBusinessCategoryChange,
  handleBusinessCategoryFocus,
  shouldValidateBusinessCategoryRef,
}) => {
  const errorMessages = {
    required: i18next.t('This field needs your input'),
    phone: i18next.t('Invalid phone number. Please, check the phone number and try again'),
    email: i18next.t('Invalid email. Please, check the email and try again'),
    url: i18next.t('Invalid URL. Please, check the URL and try again'),
    inputMaxLength: i18next.t('Maximum number of characters {number}', {
      number: settingLengthLimits.MAX_ADDRESS_CHARS,
    }),
    siteNameMinLength: i18next.t(`Minimum number of characters {number}`, {
      number: settingLengthLimits.MIN_SITE_NAME_CHARS,
    }),
    siteNameMaxLength: i18next.t(`Maximum number of characters {number}`, {
      number: settingLengthLimits.MAX_SITE_NAME_CHARS,
    }),
    descriptionMinLength: i18next.t(`Please provide a description with {number}+ characters`, {
      number: settingLengthLimits.MIN_BUSINESS_DESCRIPTION_CHARS,
    }),
    descriptionMaxLength: i18next.t(`Maximum number of characters {number}`, {
      number: settingLengthLimits.MAX_BUSINESS_DESCRIPTION_CHARS,
    }),
    logoType: i18next.t(
      'Please ensure your file is in one of the following formats: .img, .jpeg, .jpg, .png, .gif, .svg'
    ),
    logoSize: i18next.t('Your file is too large. Please upload smaller one.'),
  };

  return {
    [fieldNames.SITE_NAME]: {
      label: i18next.t('Website name'),
      step: setupSteps.GENERAL,
      control,
      name: fieldNames.SITE_NAME,
      rules: {
        required: errorMessages.required,
        minLength: {
          value: settingLengthLimits.MIN_SITE_NAME_CHARS,
          message: errorMessages.siteNameMinLength,
        },
        maxLength: {
          value: settingLengthLimits.MAX_SITE_NAME_CHARS,
          message: errorMessages.siteNameMaxLength,
        },
      },
      onChange: handleSiteNameChange,
    },
    [fieldNames.BUSINESS_CATEGORY]: {
      label: i18next.t('Website type'),
      placeholder: i18next.t('E.g. Cleaning Company'),
      noResultsText: i18next.t('No result'),
      step: setupSteps.GENERAL,
      control,
      name: fieldNames.BUSINESS_CATEGORY,
      rules: {
        validate: (value) => {
          if (shouldValidateBusinessCategoryRef.current) {
            // eslint-disable-next-line no-param-reassign
            shouldValidateBusinessCategoryRef.current = false;
            return Boolean(value) || errorMessages.required;
          }

          return true;
        },
      },
      onChange: handleBusinessCategoryChange,
      onFocus: handleBusinessCategoryFocus,
    },
    [fieldNames.BUSINESS_NAME]: {
      label: i18next.t('Business name'),
      placeholder: siteName,
      checkboxLabel: i18next.t('Same as website name'),
      step: setupSteps.DESCRIPTION,
      control,
      name: fieldNames.BUSINESS_NAME,
      rules: {
        required: errorMessages.required,
        minLength: {
          value: settingLengthLimits.MIN_SITE_NAME_CHARS,
          message: errorMessages.siteNameMinLength,
        },
        maxLength: {
          value: settingLengthLimits.MAX_SITE_NAME_CHARS,
          message: errorMessages.siteNameMaxLength,
        },
      },
      isSameAsSiteName: isBusinessNameSameAsSiteName,
      onSameAsSiteNameChange: handleBusinessNameAsSiteNameChange,
      onChange: handleBusinessNameChange,
    },
    [fieldNames.DESCRIPTION]: {
      label: i18next.t('Business description'),
      placeholder: i18next.t(
        `Alex & Johnson - is a real estate investment and development company based in Georgia. We specialize in acquiring, managing, and maximizing the value of residential properties.`
      ),
      step: setupSteps.DESCRIPTION,
      control,
      name: fieldNames.DESCRIPTION,
      rules: {
        required: errorMessages.required,
        minLength: {
          value: settingLengthLimits.MIN_BUSINESS_DESCRIPTION_CHARS,
          message: errorMessages.descriptionMinLength,
        },
        maxLength: {
          value: settingLengthLimits.MAX_BUSINESS_DESCRIPTION_CHARS,
          message: errorMessages.descriptionMaxLength,
        },
      },
    },
    [fieldNames.LOGO]: {
      label: i18next.t('Your logo'),
      buttonLabel: i18next.t('Upload logo'),
      placeholder: i18next.t('No file chosen'),
      step: setupSteps.LOGO,
      control,
      name: fieldNames.LOGO,
      rules: {
        validate: {
          logoType: createLogoTypeValidator(errorMessages.logoType),
          logoSize: createLogoSizeValidator(errorMessages.logoSize, maxLogoSize),
        },
      },
    },
    [fieldNames.ADDRESS]: {
      label: i18next.t('Address'),
      placeholder: i18next.t('e.g. 1st Example st., Example City, Example Country'),
      step: setupSteps.CONTACTS,
      control,
      name: fieldNames.ADDRESS,
      rules: {
        maxLength: {
          value: settingLengthLimits.MAX_ADDRESS_CHARS,
          message: errorMessages.inputMaxLength,
        },
      },
    },
    [fieldNames.PHONE]: {
      label: i18next.t('Phone'),
      placeholder: i18next.t('e.g. +1 234 567 8910'),
      step: setupSteps.CONTACTS,
      control,
      name: fieldNames.PHONE,
      rules: {
        validate: createPhoneValidator(errorMessages.phone),
      },
    },
    [fieldNames.EMAIL]: {
      label: i18next.t('Email'),
      placeholder: i18next.t('e.g. example@gmail.com'),
      step: setupSteps.CONTACTS,
      control,
      name: fieldNames.EMAIL,
      rules: {
        required: errorMessages.required,
        validate: createEmailValidator(errorMessages.email),
      },
    },
    [fieldNames.HOURS]: {
      label: i18next.t('Working hours'),
      placeholder: i18next.t('e.g. Sun: Closed, Mon-Fri: 8 am-6 pm, Sat: 10 am-4 pm'),
      step: setupSteps.CONTACTS,
      control,
      name: fieldNames.HOURS,
      rules: {
        maxLength: {
          value: settingLengthLimits.MAX_BUSINESS_HOUR_CHARS,
          message: errorMessages.inputMaxLength,
        },
      },
    },
    [fieldNames.SOCIAL_LINKS]: {
      label: i18next.t('Social links'),
      buttonLabel: i18next.t('Add another link'),
      placeholder: 'https://facebook.com/profile',
      step: setupSteps.SOCIALS,
      control,
      name: fieldNames.SOCIAL_LINKS,
      rules: {
        validate: createSocialLinkUrlValidator(errorMessages.url),
      },
      links: socialLinks.fields,
      onAddSocialLink: () => {
        socialLinks.append({ link: '' });
      },
      onRemoveSocialLink: (index) => {
        socialLinks.remove(index);
      },
    },
  };
};

const getDefaultValues = ({ initialFieldsValues, siteSettings }) => {
  if (initialFieldsValues) {
    return initialFieldsValues;
  }

  const settingFieldValues = getFieldValuesFromSettings(siteSettings);

  return {
    [fieldNames.SITE_NAME]: settingFieldValues.siteName,
    [fieldNames.BUSINESS_NAME]: settingFieldValues.businessName || settingFieldValues.siteName,
    [fieldNames.DESCRIPTION]: settingFieldValues.businessDescription,
    [fieldNames.LOGO]: '',
    [fieldNames.ADDRESS]: settingFieldValues.addresses,
    [fieldNames.PHONE]: settingFieldValues.phones,
    [fieldNames.EMAIL]: settingFieldValues.emails,
    [fieldNames.HOURS]: settingFieldValues.businessHours,
    [fieldNames.SOCIAL_LINKS]: settingFieldValues.socialLinks?.map((link) => ({ link })) || [
      { link: '' },
    ],
    [fieldNames.BUSINESS_CATEGORY]: settingFieldValues.businessCategory
      ? {
          value: settingFieldValues.businessCategory,
        }
      : null,
  };
};

function useSetupForm({ activeStepId, initialFieldsValues }) {
  const siteSettings = useSelector(site.selectors.getSettings);
  const defaultValues = getDefaultValues({ initialFieldsValues, siteSettings });
  const limits = useSelector(integration.selectors.getLimits);
  const { maxSize: maxLogoSize } = limits.uploadFileSize;
  const siteId = site.accessors.getSiteId();
  const shouldValidateBusinessCategoryRef = useRef(false);

  const { control, trigger, getValues, setValue, setFocus, resetField } = useForm({
    defaultValues,
    mode: 'onTouched',
  });

  const socialLinks = useFieldArray({
    control,
    name: fieldNames.SOCIAL_LINKS,
  });
  const { dirtyFields } = useFormState({ control });

  const [isBusinessNameSameAsSiteName, setIsBusinessNameSameAsSiteName] = useState(
    defaultValues[fieldNames.SITE_NAME] === defaultValues[fieldNames.BUSINESS_NAME]
  );

  const handleSiteNameChange = (value) => {
    if (isBusinessNameSameAsSiteName) {
      setValue(fieldNames.BUSINESS_NAME, value, { shouldDirty: true });
    }
  };

  const handleBusinessCategoryChange = (option) => {
    setValue(fieldNames.BUSINESS_CATEGORY, option);
    track(events.BUSINESS_CATEGORY_SELECTED, {
      siteId,
      stepId: null,
      businessCategory: option.value,
      archetypeId: option.archetype,
      source: sourceIds.SITE_WIZARD,
    });
  };

  const handleBusinessCategoryFocus = (option) => {
    track(events.BUSINESS_CATEGORY_INPUT_FOCUSED, {
      siteId,
      stepId: null,
      businessCategory: option?.value || null,
      source: sourceIds.SITE_WIZARD,
    });
  };

  const handleBusinessNameChange = (value) => {
    if (value !== getValues(fieldNames.SITE_NAME)) {
      setIsBusinessNameSameAsSiteName(false);
    }
  };

  const handleBusinessNameAsSiteNameChange = (value) => {
    if (value) {
      setValue(fieldNames.BUSINESS_NAME, getValues(fieldNames.SITE_NAME), { shouldValidate: true });
    }

    setIsBusinessNameSameAsSiteName(value);
  };

  const fields = getFields({
    control,
    siteName: getValues(fieldNames.SITE_NAME),
    maxLogoSize,
    socialLinks,
    isBusinessNameSameAsSiteName,
    handleSiteNameChange,
    handleBusinessNameChange,
    handleBusinessNameAsSiteNameChange,
    handleBusinessCategoryChange,
    handleBusinessCategoryFocus,
    shouldValidateBusinessCategoryRef,
  });

  const fieldsOnActiveStep = Object.entries(fields)
    .filter(([, fieldConfig]) => fieldConfig.step === activeStepId)
    .map(([fieldName]) => fieldName);

  const getDirtyFields = () => {
    const isBusinessNameChaged = siteSettings.businessName !== getValues(fieldNames.BUSINESS_NAME);

    return {
      ...(isBusinessNameChaged && {
        [fieldNames.BUSINESS_NAME]: true,
      }),
      ...dirtyFields,
    };
  };

  const [initialValuesOnActiveStep, setInitialValuesOnActiveStep] = useState({});

  const setFocusOnEmptyRequiredField = useCallback(() => {
    const firstEmptyRequiredField = fieldsOnActiveStep.find(
      (field) => fields[field]?.rules?.required && !getValues(field)
    );

    if (firstEmptyRequiredField) {
      setFocus(firstEmptyRequiredField);
    }
  }, [fields, fieldsOnActiveStep, getValues, setFocus]);

  useEffect(() => {
    setFocusOnEmptyRequiredField();
  }, [setFocusOnEmptyRequiredField]);

  useEffect(() => {
    const initialValues = fieldsOnActiveStep.reduce(
      (acc, field) => ({
        ...acc,
        [field]: structuredClone(getValues(field)),
      }),
      {}
    );

    setInitialValuesOnActiveStep(initialValues);

    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, [activeStepId]);

  useEffect(() => {
    const getCategoryBySlug = async (slug) => {
      try {
        const client = serviceClient.get();
        const locale = i18n.accessors.getLocale();
        const { data } = await client.taxonomyGetCategory({ slug, locale });

        setValue(fieldNames.BUSINESS_CATEGORY, {
          label: data.title,
          value: data.category,
          archetype: data.archetypes[0],
        });
      } catch (error) {
        console.error(error);
      }
    };

    if (siteSettings.businessCategory) {
      getCategoryBySlug(siteSettings.businessCategory);
    }

    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  const resetFields = () =>
    fieldsOnActiveStep.forEach((field) => {
      setValue(field, initialValuesOnActiveStep[field]);

      if (field === fieldNames.LOGO) {
        resetField(fieldNames.LOGO);
      }
    });

  const someFieldsFilled = () =>
    fieldsOnActiveStep.some((field) => {
      const value = getValues(field);

      if (field === fieldNames.SOCIAL_LINKS) {
        return value.some(({ link }) => link.trim());
      }

      return value;
    });

  const someFieldsRequired = () =>
    fieldsOnActiveStep.some((field) => fields[field]?.rules?.required);

  const triggerValidation = () => {
    if (fieldsOnActiveStep.includes(fieldNames.BUSINESS_CATEGORY)) {
      shouldValidateBusinessCategoryRef.current = true;
    }

    return trigger(fieldsOnActiveStep);
  };

  return {
    fields,
    updatedFields: getUpdatedFields(getDirtyFields()),
    someFieldsFilled,
    someFieldsRequired,
    resetFields,
    triggerValidation,
    getFieldsValues: getValues,
  };
}

export default useSetupForm;
