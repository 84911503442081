import actionTypes from '../constant/action-types';

const changeColorPalette = (isChanged) => ({
  type: actionTypes.CHANGE_COLOR_PALETTE,
  payload: {
    isChanged,
  },
});

export default changeColorPalette;
