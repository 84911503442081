import getDataFromNodes from './get-data-from-nodes';
import getGalleryItems from './get-gallery-items';

const getGalleryData = (elementId) => {
  const galleryItems = getGalleryItems(elementId);

  return getDataFromNodes(galleryItems);
};

export default getGalleryData;
