import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import SurveyDialog from '../../components/survey-dialog';
import Scores from '../../components/scores';

const { Stack, HorizontalStack, Heading, Paragraph, ControlGroup, TextArea, BetaLabel } =
  designSystem;

const MODAL_WIDTH = 572;
const MODAL_HEIGHT = 360;

function AiSurveyDialog({
  minScore,
  maxScore,
  selectedScore,
  scoreType,
  captions,
  isDesktopView,
  onScoreChange,
  onFeedbackChange,
  onSubmit,
  onClose,
}) {
  const hasScore = Number.isInteger(selectedScore);
  const featureLabel = (
    <HorizontalStack
      align={isDesktopView ? 'center' : 'left'}
      verticalAlign="center"
      gap="spacing-4-xs"
    >
      <BetaLabel />
      <Paragraph>{captions.feature}</Paragraph>
    </HorizontalStack>
  );

  return (
    <SurveyDialog
      modalWidth={MODAL_WIDTH}
      modalHeight={MODAL_HEIGHT}
      topPanelContent={featureLabel}
      submitButtonLabel={captions.submitLabel}
      closeButtonLabel={captions.remindLabel}
      isSubmitButtonDisabled={!hasScore}
      isDesktopView={isDesktopView}
      onSubmit={onSubmit}
      onClose={onClose}
    >
      <Stack gap="spacing-l">
        <Heading type="heading-3" align="center">
          {captions.title}
        </Heading>
        <Scores
          minScore={minScore}
          maxScore={maxScore}
          selectedScore={selectedScore}
          scoreType={scoreType}
          captions={{
            minScoreTitle: captions.minScoreTitle,
            maxScoreTitle: captions.maxScoreTitle,
          }}
          isDesktopView={isDesktopView}
          onScoreChange={onScoreChange}
        />
        {hasScore && (
          <ControlGroup title={captions.fieldLabel}>
            <TextArea placeholder={captions.fieldPlaceholder} onChange={onFeedbackChange} resize />
          </ControlGroup>
        )}
      </Stack>
    </SurveyDialog>
  );
}

AiSurveyDialog.propTypes = {
  minScore: PropTypes.number.isRequired,
  maxScore: PropTypes.number.isRequired,
  selectedScore: PropTypes.number,
  scoreType: PropTypes.string,
  captions: PropTypes.shape({
    feature: PropTypes.string,
    submitLabel: PropTypes.string,
    remindLabel: PropTypes.string,
    title: PropTypes.string,
    minScoreTitle: PropTypes.string,
    maxScoreTitle: PropTypes.string,
    fieldLabel: PropTypes.string,
    fieldPlaceholder: PropTypes.string,
  }).isRequired,
  isDesktopView: PropTypes.bool.isRequired,
  onScoreChange: PropTypes.func.isRequired,
  onFeedbackChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

AiSurveyDialog.defaultProps = {
  selectedScore: null,
  scoreType: null,
};

export default AiSurveyDialog;
