import React from 'react';
import Trigger from 'src/js/modules/common/components/trigger';
import { Icon } from '@yola/ws-ui';
import { blocks, dialogs, extensions, i18next, view, template } from '@yola/ws-sdk';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import focalPoint from 'src/js/modules/focal-point';
import customUI from 'src/js/modules/custom-ui';
import { BLOCK_SETTINGS_APPEARANCE_TAB_ID } from 'src/js/modules/blocks/constants/common';
import config from '../constants/config';

const { hasBackgroundContainer } = focalPoint.helpers;

const triggerId = 'background-image';

const editBackgroundImageControl = {
  id: triggerId,
  trigger: (
    <Trigger id={triggerId}>
      <Icon glyph="image" size="20" strokeWidth="1.5" />
    </Trigger>
  ),
  get title() {
    return i18next.t('Edit image');
  },
  get tooltip() {
    return i18next.t('Edit image');
  },
  priority: 100,
  closeIcon: 'submit',
  onTriggerClick(elementId) {
    if (template.verifiers.isMpt()) {
      const element = view.accessors.getLiveElement(elementId);
      const block = blocks.accessors.getBlockByElementId(elementId);
      const blockId = block.id;

      dialogs.operations.show(dialogTypes.BLOCK_SETTINGS, {
        elementId,
        blockId,
        element,
        tabId: BLOCK_SETTINGS_APPEARANCE_TAB_ID,
      });
    } else {
      customUI.operations.show(customUI.customUiTypes.EDIT_BACKGROUND_IMAGE_FOCAL_POINT, {
        elementId,
      });
    }
  },
  matches(liveElement) {
    const settings = extensions.accessors.getExtensionSettings(config.slug);

    if (liveElement.matches(settings.querySelector)) {
      return hasBackgroundContainer(liveElement);
    }

    return false;
  },
};

export default editBackgroundImageControl;
