import { extensions } from '@yola/ws-sdk';
import constants from '../constants';

const getClosestGalleryItemWrapper = (galleryItemElement) => {
  const { sliderGalleryItemSelector } = extensions.accessors.getExtensionSettings(
    constants.common.slug
  );
  const wrapperSelectors = sliderGalleryItemSelector.split(/,\s*/);

  let galleryItemWrapperElement;
  wrapperSelectors.some((selector) => {
    galleryItemWrapperElement = galleryItemElement.closest(selector);
    return galleryItemWrapperElement;
  });

  return galleryItemWrapperElement;
};

export default getClosestGalleryItemWrapper;
