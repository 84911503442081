import store from 'src/js/store';
import user from 'yola-editor/src/js/modules/user';
import references from '../constants/references';

const getMultilingualAiTutorialUrl = () => {
  const state = store.getState();
  const isWhiteLabel = user.selectors.getWhiteLabelStatus(state);

  return isWhiteLabel
    ? references.MULTILINGUAL_AI_TUTORIAL_WL_URL
    : references.MULTILINGUAL_AI_TUTORIAL_URL;
};

export default getMultilingualAiTutorialUrl;
