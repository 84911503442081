const contentItemsComparator = (a, b) => {
  if (a.collapsed && !b.collapsed) {
    return 1;
  }

  if (!a.collapsed && b.collapsed) {
    return -1;
  }

  return b.priority - a.priority;
};

export default contentItemsComparator;
