import bowser from 'yola-bowser';
import classNames from 'classnames';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { utils, i18next, linkEditing } from '@yola/ws-sdk';
import {
  Button,
  ButtonGroup,
  Formik,
  Modal,
  Panel,
  PanelGroup,
  Field,
  Text,
  CheckboxField,
  Tooltip,
  designSystem,
} from '@yola/ws-ui';
import constants from '../constants';
import isOpenAddress from '../verifiers/is-open-address';
import LinkSelectorField from './link-selector-field';
import getDownloadOptions from '../helpers/get-download-options';
import InputGroupField from './input-group-field';

const {
  linkSettings: { DIALOG_WIDTH, DIALOG_HEIGHT, PANEL_HEIGHT, INPUTS_IDS, DOWNLOAD_OPTIONS_VALUES },
  common: { ENTER_KEYCODE },
} = constants;

const { linkTypes } = linkEditing.constants;
const {
  ActionButton,
  Paragraph,
  Box,
  Stack,
  RadioButtonGroup,
  Checkbox,
  RadioButton,
  Loader,
  Mark,
} = designSystem;

const LinkSettingDialog = ({
  linkType,
  reference,
  latestAnchorReference,
  nofollowControl,
  targetBlank,
  title,
  modalAttributes,
  showTitle,
  showDeleteButton,
  showNoFollowCheckbox,
  handleDeleteLink,
  captions,
  panelHeight,
  handleCancel,
  setIsOverlayClicked,
  dialogPosition,
  handleSubmit,
  linkSelectorOptions,
  linkSelectorCaptions,
  handleLinkChange,
  handleTargetBlankChange,
  handleNoFollowChange,
  handleTitleChange,
  handleUploadFile,
  downloadOption,
  handleDownloadOption,
  validateLink,
  overlay,
  centered,
  appearance,
  isExternalLinksDisabled,
  isExternalLinksUnresolved,
  onUpgradeLinksClick,
  onInputClick,
  customPathVisible,
  customPathIsLoading,
  customPathDisabled,
  customPathValue,
  toggleCustomPathField,
  onCustomPathChange,
  pathValidation,
}) => {
  const formikRef = useRef(null);
  const modalRef = useRef(null);
  const isDialog = appearance === constants.common.DIALOG_APPEARANCE;
  const isMobile = bowser.mobile || bowser.tablet;

  const isExternalLink = linkType === linkTypes.EXTERNAL;
  const isLinkSelectorDisabled = isExternalLink && isExternalLinksDisabled;
  const isLinkSelectorFieldDisabled =
    isLinkSelectorDisabled || (isExternalLink && isExternalLinksUnresolved);

  const handleKeyDown = (event) => {
    if (!isDialog) return;

    if (event.keyCode === ENTER_KEYCODE && !isLinkSelectorDisabled) {
      formikRef.current.handleSubmit();
    }
  };

  const onCancel = () => {
    if (!isDialog) return;

    const {
      state: { offsetX, offsetY },
    } = modalRef.current;

    handleCancel({ offsetX, offsetY });
  };

  const onFileUpload = (newFile) => {
    if (isDialog) {
      const { errors } = formikRef.current.state;
      formikRef.current.setErrors({
        ...errors,
      });
    }

    handleUploadFile(newFile);
  };

  const onSubmit = () => {
    if (!isDialog) return;

    const {
      state: { offsetX, offsetY },
    } = modalRef.current;

    handleSubmit(offsetX, offsetY);
  };

  const linkEditorJSX = (
    <div className="ws-modal__block">
      <Text type="heading-6">{captions.actionTitle}</Text>
      <Text type="annotation" theme="grey">
        {captions.actionSubtitle}
      </Text>
      <Box marginTop="spacing-3-xs">
        <Field
          component={LinkSelectorField}
          onClick={onInputClick}
          values={{ linkType, reference }}
          latestAnchorReference={latestAnchorReference}
          onChange={handleLinkChange}
          name="reference"
          validate={validateLink}
          onFileUpload={onFileUpload}
          options={linkSelectorOptions}
          captions={linkSelectorCaptions}
          linkTypes={linkTypes}
          disabled={isLinkSelectorFieldDisabled}
        />
      </Box>
      {isOpenAddress(linkType) && !isLinkSelectorDisabled && (
        <React.Fragment>
          <Field
            component={CheckboxField}
            id="edit-link-target-field"
            name="targetBlank"
            value={targetBlank}
            checked={targetBlank}
            onChange={handleTargetBlankChange}
            className="ws-link-edit-modal__dynamic-field"
          >
            <Text type="heading-6">
              {/* eslint-disable-next-line yola/jsx-a11y/label-has-for */}
              <label htmlFor="edit-link-target-field">{i18next.t('Open in a new tab')}</label>
            </Text>
          </Field>

          {showNoFollowCheckbox && (
            <Field
              component={CheckboxField}
              id="nofollow-control"
              name="nofollowControl"
              value={nofollowControl}
              checked={nofollowControl}
              onChange={handleNoFollowChange}
              className="ws-link-edit-modal__dynamic-field"
            >
              <Text type="heading-6">
                {/* eslint-disable-next-line yola/jsx-a11y/label-has-for */}
                <label
                  style={{
                    position: 'relative',
                  }}
                  htmlFor="nofollow-control"
                  data-multiline="true"
                  data-place="bottom"
                  data-arrow-color="transparent"
                  data-class="ws-link-edit-modal__tooltip"
                  data-tip={i18next.t(
                    'The Nofollow tag tells search engines to ignore this link so that it does not impact search engine rank for the target website'
                  )}
                  data-for="nofollow"
                >
                  {i18next.t('Add a Nofollow tag')}
                </label>
                {!isMobile && <Tooltip id="nofollow" />}
              </Text>
            </Field>
          )}
        </React.Fragment>
      )}
      {linkType === linkTypes.DOWNLOAD && (
        <Box marginTop="spacing-2-xs">
          <RadioButtonGroup
            onChange={handleDownloadOption}
            name="download-radio-button-group"
            selectedValue={downloadOption}
          >
            {getDownloadOptions().map(({ label, value }, index) => (
              <RadioButton
                value={value}
                label={label}
                key={label}
                id={`download-radio-button-id-${index}`}
                isLabelBold
              />
            ))}
          </RadioButtonGroup>

          {customPathIsLoading && (
            <Box marginTop="spacing-m">
              <Loader size="medium" />
            </Box>
          )}

          {!customPathIsLoading && (
            <Box marginTop="spacing-xs">
              <Stack gap="spacing-3-xs">
                <Checkbox
                  label={i18next.t('Set Download URL')}
                  disabled={customPathDisabled}
                  name="customPathSwitcher"
                  id="customPathSwitcher"
                  value="visibility"
                  checked={customPathVisible}
                  onChange={toggleCustomPathField}
                />
                {customPathVisible && (
                  <div>
                    <Field
                      component={InputGroupField}
                      disabled={customPathDisabled}
                      onChange={onCustomPathChange}
                      validate={pathValidation}
                      subtitle={
                        <React.Fragment>
                          {`${i18next.t('The URL will look like this:')} `}

                          <span style={{ whiteSpace: 'nowrap' }}>
                            yourdomain.com
                            <strong>
                              <Mark appearance="high-emphasis">/your-url</Mark>
                            </strong>
                            .
                          </span>

                          {i18next.t('To publish under a different URL, re-upload the file')}
                        </React.Fragment>
                      }
                      value={customPathValue}
                      iconGlyph="link"
                      name="customPath"
                      id="customPath"
                    />
                  </div>
                )}
              </Stack>
            </Box>
          )}
        </Box>
      )}
      {isLinkSelectorDisabled && (
        <React.Fragment>
          <div className="ws-link-edit-modal__upgrade-text">
            <Paragraph>
              {i18next.t(
                'This feature is available in Premium plan. Upgrade and get more for your website.'
              )}
            </Paragraph>
          </div>
          <ActionButton
            iconGlyph="upgrade"
            iconPlacement="before"
            format="solid"
            appearance="cta"
            label={i18next.t('Upgrade')}
            onClick={onUpgradeLinksClick}
          />
        </React.Fragment>
      )}
    </div>
  );

  if (appearance === 'dialog-block') {
    return linkEditorJSX;
  }

  return (
    <Formik
      enableReinitialize
      ref={formikRef}
      initialValues={{
        title,
        reference,
        customPath: customPathValue,
        customPathSwitcher: customPathVisible,
      }}
      onSubmit={onSubmit}
    >
      {({ handleSubmit: manageSubmit, values, errors }) => (
        <Modal
          modalRef={modalRef}
          handleCancel={onCancel}
          handleSubmit={() => {
            if (isLinkSelectorDisabled) {
              onCancel();
              return;
            }

            setIsOverlayClicked(true);
            manageSubmit();
          }}
          isValid={!Object.values(errors).filter(Boolean).length}
          resizable={false}
          width={DIALOG_WIDTH}
          maxHeight={DIALOG_HEIGHT}
          height="auto"
          minWidth={320}
          offsetX={dialogPosition.offsetX}
          offsetY={dialogPosition.offsetY}
          dragHandleSelector=".ws-drag-trigger"
          onKeyDown={handleKeyDown}
          fullscreen={bowser.mobile}
          data={values}
          className="ws-link-edit-modal"
          attributes={modalAttributes}
          overlay={overlay}
          centered={centered}
        >
          <PanelGroup
            height="100%"
            style={{ overflow: 'visible', flexShrink: '0' }}
            className="ws-link-edit-modal__panel"
          >
            <Panel
              theme="gray-100"
              corners="squared"
              align="middle"
              height={PANEL_HEIGHT}
              style={{ flexShrink: '0' }}
              className="ws-drag-trigger ws-modal__content"
            >
              <Text type="heading-6">{captions.dialogTitle}</Text>
            </Panel>
            <Panel
              theme="white"
              className={classNames('ws-link-edit-modal__panel', {
                'ws-link-edit-modal__panel--mobile': bowser.mobile,
              })}
              height={panelHeight}
              style={{ overflow: 'visible' }}
            >
              <div className="ws-modal__content">
                {linkEditorJSX}

                {showTitle && (
                  <div className="ws-modal__block">
                    <Stack gap="spacing-3-xs">
                      <Text type="heading-6" theme="default">
                        {captions.title}
                      </Text>
                      <div>
                        <Field
                          placeholder={i18next.t('Enter item name')}
                          name="title"
                          component={InputGroupField}
                          value={title}
                          onClick={() => onInputClick(INPUTS_IDS.titleInput)}
                          onChange={handleTitleChange}
                          subtitle={captions.titleAnnotation}
                          validate={(value) =>
                            value && value.trim()
                              ? null
                              : i18next.t('Item Name should not be empty')
                          }
                        />
                      </div>
                    </Stack>
                  </div>
                )}

                {showDeleteButton && (
                  <div className="ws-modal__block">
                    <Button
                      className="ws-link-edit-modal__delete-link-button"
                      onClick={handleDeleteLink}
                    >
                      {i18next.t('Delete link')}
                    </Button>
                  </div>
                )}
              </div>
            </Panel>
            <Panel theme="white" style={{ flexShrink: '0' }} height={48}>
              <ButtonGroup stick="top" block>
                <Button
                  disabled={isLinkSelectorDisabled}
                  stretch="block"
                  size="large"
                  onClick={manageSubmit}
                >
                  {captions.save}
                </Button>
                <Button stretch="block" size="large" onClick={onCancel}>
                  {i18next.t('Cancel')}
                </Button>
              </ButtonGroup>
            </Panel>
          </PanelGroup>
        </Modal>
      )}
    </Formik>
  );
};

LinkSettingDialog.propTypes = {
  dialogPosition: PropTypes.shape({
    offsetX: PropTypes.number,
    offsetY: PropTypes.number,
  }),
  captions: PropTypes.shape().isRequired,
  linkType: PropTypes.string.isRequired,
  reference: PropTypes.string,
  latestAnchorReference: PropTypes.string,
  nofollowControl: PropTypes.bool,
  targetBlank: PropTypes.bool,
  title: PropTypes.string,
  modalAttributes: PropTypes.shape({}),
  linkSelectorOptions: PropTypes.shape({}).isRequired,
  linkSelectorCaptions: PropTypes.shape({}).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  showTitle: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
  showNoFollowCheckbox: PropTypes.bool,
  handleDeleteLink: PropTypes.func,
  handleLinkChange: PropTypes.func,
  handleTargetBlankChange: PropTypes.func,
  handleNoFollowChange: PropTypes.func,
  handleTitleChange: PropTypes.func,
  handleUploadFile: PropTypes.func,
  validateLink: PropTypes.func,
  panelHeight: PropTypes.string,
  setIsOverlayClicked: PropTypes.func,
  overlay: PropTypes.oneOf(['transparent', 'visible', 'none']),
  centered: PropTypes.bool,
  appearance: PropTypes.oneOf([
    constants.common.DIALOG_APPEARANCE,
    constants.common.DIALOG_BLOCK_APPEARANCE,
  ]),
  isExternalLinksDisabled: PropTypes.bool,
  isExternalLinksUnresolved: PropTypes.bool,
  onUpgradeLinksClick: PropTypes.func,
  onInputClick: PropTypes.func,
  downloadOption: PropTypes.string,
  handleDownloadOption: PropTypes.func,
  customPathVisible: PropTypes.bool,
  customPathIsLoading: PropTypes.bool,
  customPathDisabled: PropTypes.bool,
  customPathValue: PropTypes.string,
  toggleCustomPathField: PropTypes.func,
  onCustomPathChange: PropTypes.func,
  pathValidation: PropTypes.func,
};

LinkSettingDialog.defaultProps = {
  dialogPosition: PropTypes.shape({
    offsetX: null,
    offsetY: null,
  }),
  modalAttributes: {},
  reference: null,
  latestAnchorReference: '',
  nofollowControl: true,
  targetBlank: true,
  title: '',
  showTitle: true,
  showDeleteButton: false,
  showNoFollowCheckbox: false,
  handleDeleteLink: utils.noop,
  handleLinkChange: utils.noop,
  handleTargetBlankChange: utils.noop,
  handleTitleChange: utils.noop,
  handleUploadFile: utils.noop,
  handleNoFollowChange: utils.noop,
  validateLink: utils.noop,
  panelHeight: `calc(100% - ${PANEL_HEIGHT}px * 2)`,
  setIsOverlayClicked: utils.noop,
  overlay: 'transparent',
  centered: null,
  appearance: constants.common.DIALOG_APPEARANCE,
  isExternalLinksDisabled: false,
  isExternalLinksUnresolved: false,
  onUpgradeLinksClick: utils.noop,
  onInputClick: utils.noop,
  downloadOption: DOWNLOAD_OPTIONS_VALUES.downloadOnComputer,
  handleDownloadOption: utils.noop,
  customPathVisible: false,
  customPathIsLoading: false,
  customPathDisabled: true,
  customPathValue: '/',
  toggleCustomPathField: utils.noop,
  onCustomPathChange: utils.noop,
  pathValidation: utils.noop,
};

export default LinkSettingDialog;
