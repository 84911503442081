import React, { useRef } from 'react';
import { Button, ButtonGroup, Modal, Panel, PanelGroup, Text } from '@yola/ws-ui';
import PropTypes from 'prop-types';
import { DIALOG_HEIGHT, DIALOG_PANEL_HEIGHT, DIALOG_WIDTH } from '../constants/block-settings';
import BlockSettingsContentContainer from '../containers/block-settings-content-container';

const BlockSettingsDialog = ({
  offsetX,
  offsetY,
  isMobile,
  onModalReady,
  onKeyDown,
  onCancel,
  onSubmit,
  onOverlayClick,
  onDragEnd,
  captions: { title, buttons },
  isInProgress,
}) => {
  const modalRef = useRef(null);

  return (
    <Modal
      modalRef={modalRef}
      draggable
      resizable={false}
      fullscreen={isMobile}
      className="ws-block-settings-dialog"
      floatingModalElementSelector=".ws-context-menu"
      width={DIALOG_WIDTH}
      height={DIALOG_HEIGHT}
      minWidth={320}
      minHeight={200}
      offsetX={offsetX}
      offsetY={offsetY}
      dragHandleSelector=".ws-drag-trigger"
      onReady={() => onModalReady(modalRef.current)}
      onKeyDown={onKeyDown}
      onOverlayClick={onOverlayClick}
      overlay="none"
      handleCancel={onCancel}
      handleSubmit={onSubmit}
      onDragEnd={onDragEnd}
    >
      <PanelGroup height="100%">
        <Panel
          height={DIALOG_PANEL_HEIGHT}
          theme="gray-100"
          corners="squared"
          align="middle"
          className="ws-drag-trigger ws-block-settings-dialog__title"
        >
          <Text type="heading-6">{title}</Text>
        </Panel>
        <BlockSettingsContentContainer />
        <Panel
          height={DIALOG_PANEL_HEIGHT}
          corners="squared"
          className="ws-block-settings-dialog__footer"
        >
          <ButtonGroup stick="top" block>
            <Button stretch="block" size="large" onClick={onSubmit} disabled={isInProgress}>
              {buttons.submit}
            </Button>
            <Button stretch="block" size="large" onMouseDown={onCancel} disabled={isInProgress}>
              {buttons.cancel}
            </Button>
          </ButtonGroup>
        </Panel>
      </PanelGroup>
    </Modal>
  );
};

BlockSettingsDialog.propTypes = {
  offsetX: PropTypes.number.isRequired,
  offsetY: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
  onModalReady: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  onOverlayClick: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  isInProgress: PropTypes.bool.isRequired,
  captions: PropTypes.shape().isRequired,
};

export default BlockSettingsDialog;
