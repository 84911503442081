import React from 'react';
import PropTypes from 'prop-types';
import { i18next, dialogs } from '@yola/ws-sdk';
import { AlertDialog } from '@yola/ws-ui';

function SavingImageErrorDialog(props) {
  const { captions, onDismiss, failedFilesNames, onDialogCancel } = props;

  const getCaptions = (captionsOverrides) => ({
    title: i18next.t('Upload Error'),
    description: i18next.t(
      'Some of your files failed to upload. Please upload the following files again: {failedFilesNames}',
      {
        failedFilesNames: failedFilesNames.join(', '),
      }
    ),
    dismiss: i18next.t('Return'),
    ...captionsOverrides,
  });

  const handleDismiss = (...args) => {
    onDialogCancel();
    if (onDismiss) {
      onDismiss(...args);
    } else {
      dialogs.operations.hide();
    }
  };

  return <AlertDialog onDismiss={handleDismiss} captions={getCaptions(captions)} />;
}

SavingImageErrorDialog.defaultProps = {
  captions: {},
  failedFilesNames: [],
  onDismiss: null,
};

SavingImageErrorDialog.propTypes = {
  onDismiss: PropTypes.func,
  failedFilesNames: PropTypes.arrayOf(PropTypes.string),
  captions: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    dismiss: PropTypes.string,
  }),
  onDialogCancel: PropTypes.func.isRequired,
};

export default SavingImageErrorDialog;
