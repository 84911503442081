import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Modal, designSystem } from '@yola/ws-ui';
import setupSteps from '../constants/setup-steps';
import fieldNames from '../constants/field-names';
import WizardSetupTopPanel from './wizard-setup-top-panel';
import WizardSetupStepsPanel from './wizard-setup-steps-panel';
import WizardSetupBottomPanel from './wizard-setup-bottom-panel';
import WizardSetupGeneralSection from './wizard-setup-general-section';
import WizardSetupDescriptionSection from './wizard-setup-description-section';
import WizardSetupLogoSection from './wizard-setup-logo-section';
import WizardSetupContactsSection from './wizard-setup-contacts-section';
import WizardSetupSocialsSection from './wizard-setup-socials-section';
import WizardSetupSummarySection from './wizard-setup-summary-section';

const { Box, Divider } = designSystem;

const MODAL_WIDTH = 872;
const MODAL_HEIGHT = 650;

const WizardSetupDialog = (props) => {
  const {
    captions,
    activeStepId,
    steps,
    fields,
    summaryData,
    isDesktopView,
    isLoading,
    isBusinessTaxonomyFeatureEnabled,
    showBackButton,
    showSkipButton,
    showSubmitButton,
    scrollableContainerRef,
    onBack,
    onNext,
    onSkip,
    onChange,
    onEdit,
    onClose,
    onSubmit,
  } = props;
  const layoutClasses = classNames('ws-wizard-setup-layout', {
    'ws-wizard-setup-layout--desktop-view': isDesktopView,
  });

  return (
    <Modal
      className="ws-wizard-setup-dialog"
      width={MODAL_WIDTH}
      height={MODAL_HEIGHT}
      overlay="visible"
      fullscreen={!isDesktopView}
      draggable={false}
      resizable={false}
      centered
    >
      <div className="ws-wizard-setup-dialog__container" ref={scrollableContainerRef}>
        <div className={layoutClasses}>
          <div className="ws-wizard-setup-layout__top-panel">
            <WizardSetupTopPanel
              backButtonLabel={captions.backButtonLabel}
              showBackButton={showBackButton && !isDesktopView}
              isDesktopView={isDesktopView}
              onBackButtonClick={onBack}
              onCloseButtonClick={onClose}
            />
          </div>
          <div className="ws-wizard-setup-layout__steps-panel">
            <WizardSetupStepsPanel
              captions={captions.stepsPanel}
              steps={steps}
              isDesktopView={isDesktopView}
              onStepButtonClick={onChange}
            />
          </div>
          <div className="ws-wizard-setup-layout__content">
            <Box
              {...(isDesktopView
                ? {
                    padding: 'spacing-xl',
                    paddingTop: 'spacing-3-xl',
                  }
                : {
                    padding: 'spacing-xs',
                    paddingTop: 'spacing-m',
                    paddingBottom: 'spacing-m',
                  })}
            >
              {activeStepId === setupSteps.GENERAL && (
                <WizardSetupGeneralSection
                  captions={captions[setupSteps.GENERAL]}
                  siteNameField={fields[fieldNames.SITE_NAME]}
                  businessCategoryField={fields[fieldNames.BUSINESS_CATEGORY]}
                  isBusinessTaxonomyFeatureEnabled={Boolean(isBusinessTaxonomyFeatureEnabled)}
                />
              )}
              {activeStepId === setupSteps.DESCRIPTION && (
                <WizardSetupDescriptionSection
                  captions={captions[setupSteps.DESCRIPTION]}
                  businessNameField={fields[fieldNames.BUSINESS_NAME]}
                  descriptionField={fields[fieldNames.DESCRIPTION]}
                />
              )}
              {activeStepId === setupSteps.LOGO && (
                <WizardSetupLogoSection
                  captions={captions[setupSteps.LOGO]}
                  logoField={fields[fieldNames.LOGO]}
                />
              )}
              {activeStepId === setupSteps.CONTACTS && (
                <WizardSetupContactsSection
                  captions={captions[setupSteps.CONTACTS]}
                  addressField={fields[fieldNames.ADDRESS]}
                  phoneField={fields[fieldNames.PHONE]}
                  emailField={fields[fieldNames.EMAIL]}
                  hoursField={fields[fieldNames.HOURS]}
                />
              )}
              {activeStepId === setupSteps.SOCIALS && (
                <WizardSetupSocialsSection
                  captions={captions[setupSteps.SOCIALS]}
                  socialLinksField={fields[fieldNames.SOCIAL_LINKS]}
                />
              )}
              {activeStepId === setupSteps.SUMMARY && (
                <WizardSetupSummarySection
                  captions={captions[setupSteps.SUMMARY]}
                  fields={fields}
                  summaryData={summaryData}
                  isDesktopView={isDesktopView}
                  onEditButtonClick={onEdit}
                  isBusinessTaxonomyFeatureEnabled={Boolean(isBusinessTaxonomyFeatureEnabled)}
                />
              )}
            </Box>
          </div>
          <div className="ws-wizard-setup-layout__bottom-panel">
            <Divider />
            <WizardSetupBottomPanel
              backButtonLabel={captions.backButtonLabel}
              skipButtonLabel={captions.skipButtonLabel}
              submitButtonLabel={captions.submitButtonLabel}
              nextButtonLabel={captions.nextButtonLabel}
              showSkipButton={showSkipButton}
              showBackButton={showBackButton && isDesktopView}
              showSubmitButton={showSubmitButton}
              isDesktopView={isDesktopView}
              isLoading={isLoading}
              onBackButtonClick={onBack}
              onSkipButtonClick={onSkip}
              onSubmitButtonClick={onSubmit}
              onNextButtonClick={onNext}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

WizardSetupDialog.propTypes = {
  captions: PropTypes.shape().isRequired,
  activeStepId: PropTypes.string.isRequired,
  steps: PropTypes.array.isRequired,
  fields: PropTypes.shape({}).isRequired,
  summaryData: PropTypes.shape({}).isRequired,
  isDesktopView: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  showBackButton: PropTypes.bool.isRequired,
  showSkipButton: PropTypes.bool.isRequired,
  showSubmitButton: PropTypes.bool.isRequired,
  scrollableContainerRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }).isRequired,
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onSkip: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isBusinessTaxonomyFeatureEnabled: PropTypes.bool.isRequired,
};

export default WizardSetupDialog;
