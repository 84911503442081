import { extensions } from '@yola/ws-sdk';
import config from '../constants/config';
import ImageResizeTool from '../components/image-resize-tool';
import getOverlappedMediaContainerByText from '../helpers/get-overlapped-media-container-by-text';

const PERCENTAGE_REGEXP = /\d+%/;

const imageResizeToolSettings = {
  matches(element) {
    const settings = extensions.accessors.getExtensionSettings(config.slug);

    if (element.matches(settings.nonResizableElementsSelector)) {
      return false;
    }

    if (element.matches(settings.imageResizeSelector)) {
      const {
        borderBottomLeftRadius,
        borderBottomRightRadius,
        borderTopLeftRadius,
        borderTopRightRadius,
      } = element.ownerDocument.defaultView.getComputedStyle(element);

      const radiuses = [
        borderBottomLeftRadius,
        borderBottomRightRadius,
        borderTopRightRadius,
        borderTopLeftRadius,
      ];

      return !radiuses.some(
        (radius) => PERCENTAGE_REGEXP.test(radius) && !parseInt(radius, 10) < 50
      );
    }

    const overlappedMediaContainer = getOverlappedMediaContainerByText(element, true);

    if (
      overlappedMediaContainer &&
      overlappedMediaContainer.matches(settings.imageResizeSelector)
    ) {
      return true;
    }

    return false;
  },
  container: ImageResizeTool,
};

export default imageResizeToolSettings;
