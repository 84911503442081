const deepProxyAttributes = (from, to, nodeSelector, attributes) => {
  attributes.forEach((attributeName) => {
    const fromNodes = [...from.querySelectorAll(nodeSelector)];
    const fromValues = fromNodes
      .map((node) => node.getAttribute(attributeName))
      .filter((value) => !!value);

    const toNodes = [...to.querySelectorAll(nodeSelector)];
    toNodes.forEach((node, index) => {
      const value = fromValues[index];
      if (!value) return;

      node.setAttribute(attributeName, value);
    });
  });
};

export default deepProxyAttributes;
