import React from 'react';
import PropTypes from 'prop-types';

function DialogsEmoji(props) {
  const { className, label, html } = props;
  return (
    <span
      className={className}
      role="img"
      aria-label={label}
      /* eslint-disable-next-line yola/react/no-danger */
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
}

DialogsEmoji.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  html: PropTypes.string.isRequired,
};

DialogsEmoji.defaultProps = {
  className: 'dialog-body__emoji',
};

export default DialogsEmoji;
