import { view } from '@yola/ws-sdk';

const ignoredAttributes = ['class', 'frameborder', 'loading'];

function isIframeEmbed(elementId) {
  const iframe = view.accessors.getStaticElement(elementId);
  const attributes = Array.from(iframe.attributes)
    .map((attribute) => attribute.name)
    .filter((attribute) => !ignoredAttributes.includes(attribute));

  if (attributes.length === 1 && attributes.includes('src')) {
    return false;
  }

  return !(
    attributes.length === 2 &&
    attributes.includes('src') &&
    attributes.includes('allowfullscreen')
  );
}

export default isIframeEmbed;
