import { site, template } from '@yola/ws-sdk';
import constants from '../constants';
import track from '../track';

const { events } = constants;

const trackPageManagerSearchClicked = () => {
  const traits = {
    appName: 'yola-editor',
    siteId: site.accessors.getSiteId(),
    templateBuildSlug: template.accessors.getBuildSlug(),
  };

  track(events.PAGE_MANAGER_SEARCH_CLICKED, traits);
};

export default trackPageManagerSearchClicked;
