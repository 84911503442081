import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import DifmPromoModal from './difm-promo-modal';

const { Stack, Box, Heading, Paragraph, List, ListItem, Divider, ActionButton } = designSystem;

const DifmPromoDialog = (props) => {
  const { isAlreadyCompleted, onSubmit, onCancel, onDialogClose, submitHref } = props;
  const closeLabel = isAlreadyCompleted ? '' : "I don't need it";

  return (
    <DifmPromoModal closeLabel={closeLabel} onClose={onDialogClose} paddingBottom="spacing-m">
      <Box marginBottom="spacing-m">
        <Paragraph align="center">
          <img
            width="234"
            height="184"
            src="/images/difm-promo/laptop-and-computer.svg"
            alt="Laptop and computer"
          />
        </Paragraph>
      </Box>
      <Stack gap="spacing-xs">
        <Stack gap="spacing-3-xs">
          <Heading type="heading-4" align="center">
            Struggling to build a site by yourself?
          </Heading>
          <Paragraph align="center">
            Let our experts build your dream website with our Do-It-For-Me service!
          </Paragraph>
        </Stack>
        <Stack gap="spacing-3-xs">
          <Paragraph>You will get:</Paragraph>
          <List styleType="icon" spacing="spacing-3-xs">
            <ListItem iconGlyph="check" iconAppearance="success">
              Professional website
            </ListItem>
            <ListItem iconGlyph="check" iconAppearance="success">
              Time-saving approach
            </ListItem>
            <ListItem iconGlyph="check" iconAppearance="success">
              Cost-effective solution
            </ListItem>
            <ListItem iconGlyph="check" iconAppearance="success">
              No hidden fees
            </ListItem>
          </List>
        </Stack>
        <Divider />
        <Paragraph align="center">Get the website of your dreams without the hassle!</Paragraph>
        <ActionButton
          appearance="cta"
          format="solid"
          size="large"
          target="_blank"
          href={submitHref}
          label="Yes, build me a site"
          onClick={onSubmit}
          fullWidth
        />
        {!isAlreadyCompleted && (
          <Paragraph align="center">
            <ActionButton format="text" label="Remind me later" onClick={onCancel} />
          </Paragraph>
        )}
      </Stack>
    </DifmPromoModal>
  );
};

DifmPromoDialog.propTypes = {
  isAlreadyCompleted: PropTypes.bool.isRequired,
  submitHref: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDialogClose: PropTypes.func.isRequired,
};

DifmPromoDialog.defaultProps = {
  submitHref: '',
};

export default DifmPromoDialog;
