import { view } from '@yola/ws-sdk';
import getParentBlockByElement from 'src/js/modules/blocks/helpers/get-parent-block-by-element';

const collectDOMData = (elementId, groupAttribite) => {
  const element = view.accessors.getLiveElement(elementId);
  const blockElement = getParentBlockByElement(element);
  const groupId = element.getAttribute(groupAttribite);

  if (!groupId) {
    return {
      element,
      blockElement,
      groupElements: [element],
      resizableElements: [element],
      nonResizableRowsCount: 0,
    };
  }

  const liveDocument = view.accessors.getLiveDocument();
  const groupSelector = `[${groupAttribite}="${groupId}"]`;
  const groupElements = [...liveDocument.querySelectorAll(groupSelector)];

  const elementRect = element.getBoundingClientRect();
  const resizableElements = groupElements.filter((groupElement) => {
    const groupElementRect = groupElement.getBoundingClientRect();
    return groupElementRect.top >= elementRect.top;
  });
  const nonResizableRowsCount = new Set(
    groupElements
      .filter((groupElement) => !resizableElements.includes(groupElement))
      .map((groupElement) => groupElement.getBoundingClientRect().top)
  ).size;

  return {
    element,
    blockElement,
    groupElements,
    resizableElements,
    nonResizableRowsCount,
  };
};

export default collectDOMData;
