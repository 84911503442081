import { assets, view } from '@yola/ws-sdk';
import compute from './helpers/compute';
import isWsBlock from '../../../blocks/verifiers/is-ws-block';
import { BACKGROUND_IMAGE_ATTRIBUTE } from '../../constants/attributes';

const imageIsReplaced = async (config, payload) => {
  const { elementId, attr, value } = payload;

  if (!elementId || attr !== BACKGROUND_IMAGE_ATTRIBUTE) {
    return [];
  }

  // We need to operate only with liveElement when
  // we work with ws-sitecomponents, to prevent skipping
  // of overlay design-automatization
  const targetBlock = view.accessors.getLiveElement(elementId);

  if (!targetBlock || !isWsBlock(targetBlock)) {
    return [];
  }

  const mediaSource = assets.helpers.addBaseHref(value);

  return compute(targetBlock, {
    mediaSource,
  });
};

export default imageIsReplaced;
