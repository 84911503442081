import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  view,
  blocks,
  dialogs,
  site,
  theme,
  customization,
  blockTemplates,
  textFormatting,
  hdrm,
  transactions,
  template,
} from '@yola/ws-sdk';
import attributeValuesList from 'src/js/modules/utils/attribute-values-list';
import designAutomation from 'src/js/modules/design-automation';
import onlineStore from 'src/js/modules/onlinestore';
import scroller from 'src/js/modules/scroller';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import store from 'src/js/store';
import getAcceptableVariations from '../helpers/get-acceptable-variations';
import getBlocksWithDependenciesByCategory from '../helpers/get-blocks-with-dependencies-by-category';
import BlockSettingsDialogContainer from './block-settings-dialog-container';
import getActiveColorScheme from '../helpers/get-active-color-scheme';
import getVisibleOptions from '../helpers/get-visible-options';
import getBlockIdRulesMap from '../helpers/get-block-id-rules-map';
import generateBlockCustomOptionsStylesheet from '../helpers/generate-block-custom-options-stylesheet';
import BlockSettingsContext from '../contexts/block-settings-context';
import blockSettingsHelpers from '../helpers/block-settings';
import layoutSwitchingHelpers from '../helpers/layout-switching';
import trackBlockSettingsOptionClicked from '../trackers/track-block-settings-option-clicked';
import { ENTER_KEY } from '../constants/key-codes';
import { HIDE_ELEMENT_TRIGGER_ID } from '../constants/trigger-ids';
import {
  DISPLAY_ON_BLOCK_LAYOUT,
  LIVE_PREVIEW_MOCKED_ID,
  BLOCK_SETTINGS_LAYOUT_TAB_ID,
} from '../constants/common';
import { ATTRIBUTES_TO_MARK_AS_USER_MODIFIED } from '../constants/display-options';
import { SET } from '../constants/attribute-operations';
import getOriginalBlockData from '../helpers/get-original-block-data';
import getSelectedLayoutVariationId from '../helpers/get-selected-layout-variation-id';
import getSortBlockVariationId from '../helpers/get-sort-block-variation-id';
import cancelBlockSettingsChanges from '../helpers/cancel-block-settings-changes';
import saveBlockSettingsChanges from '../helpers/save-block-settings-changes';
import trackers from '../trackers';
import getActiveTabId from '../helpers/get-active-tab-id';
import getUpdatedDisplayOptions from '../helpers/get-updated-display-options';
import convertAttributeOperationToAction from '../helpers/convert-attribute-operation-to-action';
import convertChangedOptionToOperation from '../helpers/convert-changed-option-to-operation';

const { getBlockItemsForRendering, layoutBlockItemPreprocess } = layoutSwitchingHelpers;

const {
  createColorScheme,
  createOptionalChildren,
  createBackgroundOption,
  getCaptions,
  getOptionTargetElement,
  trackWsBlockContent,
  updateDependentWhiteSpaceOptions,
} = blockSettingsHelpers;

const { updateStrategies } = hdrm.constants;

const LIVE_STRATEGY_OPTIONS = { strategy: updateStrategies.UPDATE_LIVE_ONLY };

class BlockSettingsContainer extends React.Component {
  constructor(props) {
    super(props);
    const {
      blockId: originBlockId,
      element: originBlockNode,
      blockItems,
      tabId,
      getSharedData,
      refId: originRefId,
      blockTemplateCollection,
    } = props;

    this.sharedData = getSharedData();

    const {
      offsetX = 0,
      offsetY = 0,
      isDirtyOptions = false,
      isDirtyChildren = false,
      optionalChildren = null,
      displayOptions = null,
      backgroundOptions = null,
      cachedBackgroundOptions = null,
      refId = originRefId,
      optionalChildrenState = [],
      compiledNodesCache = new Map(),
      destinationBlockNode = null,
      variationId = null,
      originalBlock = null,
      blockId = originBlockId,
      blockNode = originBlockNode,
      sortBlockVariationId = null,
      selectedPreviewNode = null,
      selectedLayoutVariationId = null,
      itemsWithDependencies = null,
      isOnlineStoreBlock,
      colorSchemes = null,
    } = this.sharedData;

    const usedTextColorOptions = textFormatting.helpers.getElementCustomColorVariables(blockNode);

    const block = blocks.accessors.getBlockByElement(blockNode);

    // Block dependencies
    this.refId = refId || view.accessors.getLiveElementId(blockNode);

    this.intermediateBlockId = blockId; // Block type ID
    this.blockElement = blockNode; // Block node element
    this.blockElementId = blockNode.id; // ID of ws-block element
    this.isOnlineStoreBlock =
      isOnlineStoreBlock || onlineStore.verifiers.isOnlineStoreBlock(blockNode);

    this.destinationBlockNode = destinationBlockNode;

    // Styles dependencies
    this.stylesheet = textFormatting.helpers.getCustomTextColorsStylesheet();
    this.blocksPreviewStylesheet = generateBlockCustomOptionsStylesheet(
      this.stylesheet,
      usedTextColorOptions,
      this.blockElementId
    );
    this.blockIdRulesMap = getBlockIdRulesMap(this.stylesheet, this.blockElementId);
    this.usedCustomColorOptions =
      Boolean(usedTextColorOptions.length) && this.blockIdRulesMap && this.blockIdRulesMap.size > 0;

    // Origin block data
    this.originalBlock = getOriginalBlockData(originalBlock, {
      refId: this.refId,
      blockNode,
      blockItems,
      blockId,
    });

    const blockVariationWithCategories = block.variations?.find(
      (variation) => this.originalBlock.blockVariationId === variation.id && variation.category
    );
    const category = blockVariationWithCategories?.category || block.category;

    this.category = category;
    this.selectedPreviewNode =
      selectedPreviewNode || this.originalBlock.staticBlockNode.cloneNode(true);

    const colorPalette = template.verifiers.isMpt()
      ? customization.accessors.getCurrentColorPalette()
      : theme.accessors.getCurrentPalette();

    this.itemsWithDependencies =
      itemsWithDependencies ||
      getAcceptableVariations(
        getBlocksWithDependenciesByCategory(blockItems, category, blockTemplateCollection),
        DISPLAY_ON_BLOCK_LAYOUT
      );

    const activeTabId = getActiveTabId({
      blockId: this.intermediateBlockId,
      tabId,
      itemsWithDependencies: this.itemsWithDependencies,
    });

    this.state = {
      offsetX,
      offsetY,
      selectedLayoutVariationId: getSelectedLayoutVariationId(
        this.originalBlock,
        selectedLayoutVariationId
      ),
      sortBlockVariationId: getSortBlockVariationId(this.originalBlock, sortBlockVariationId),
      optionalChildren,
      isDirtyOptions,
      isDirtyChildren,
      blockNode,
      blockId: this.intermediateBlockId,
      colorSchemes,
      variationId: variationId || this.originalBlock.blockVariationId,
      displayOptions,
      backgroundOptions,
      cachedBackgroundOptions,
      colorPalette,
      timestamp: Date.now(),
      tabId: activeTabId,
      isAutomationInProgress: false,
    };

    this.switchColorTheme = this.switchColorTheme.bind(this);
    this.switchRadioOption = this.switchRadioOption.bind(this);
    this.switchCheckboxOption = this.switchCheckboxOption.bind(this);
    this.switchChildDisplay = this.switchChildDisplay.bind(this);
    this.switchSliderOption = this.switchSliderOption.bind(this);
    this.afterSwitchSliderOption = this.afterSwitchSliderOption.bind(this);
    this.switchSurface = this.switchSurface.bind(this);
    this.switchOnlineStoreCategory = this.switchOnlineStoreCategory.bind(this);
    this.prepareData = this.prepareData.bind(this);
    this.setOffsets = this.setOffsets.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.scrollToBlock = this.scrollToBlock.bind(this);
    this.layoutBlockItemPreprocessDecorator = this.layoutBlockItemPreprocessDecorator.bind(this);
    this.getUpdatedOptions = this.getUpdatedOptions.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.displayBlockLayout = this.displayBlockLayout.bind(this);
    this.updateSharedData = this.updateSharedData.bind(this);
    this.modifyDisplayOption = this.modifyDisplayOption.bind(this);
    this.handleAutomationStart = this.handleAutomationStart.bind(this);
    this.handleAutomationEnd = this.handleAutomationEnd.bind(this);

    this.captions = getCaptions();
    this.optionalChildrenState = optionalChildrenState;
    this.compiledNodesCache = compiledNodesCache;
    this.scrollContainerRef = React.createRef();
    this.shouldPrepareDataAfterEmptyAutomation = true;
  }

  componentDidMount() {
    const blockSettings = blocks.accessors.getBlockSettings(this.intermediateBlockId);
    const { tabId, variationId } = this.state;
    const { optionsSetup } = this.props;
    const { optionalChildren: optionalChildrenSetup } = optionsSetup;
    const childrenSetupIds = Object.keys(optionalChildrenSetup || {});

    this.prepareData(blockSettings, () => {
      const { optionalChildren } = this.state;

      if (optionalChildren) {
        optionalChildren.forEach((child) => {
          if (childrenSetupIds.includes(child.id)) {
            this.switchChildDisplay(!child.isEnabled, child, { highlighted: true });
          }
        });
      }
    });

    trackers.trackBlockSettingsDialogDisplayed({
      tabId,
      blockId: this.intermediateBlockId,
      blockVariationId: variationId,
      triggerId: childrenSetupIds.length ? HIDE_ELEMENT_TRIGGER_ID : null,
    });

    const isOpenedInitially = !Object.keys(this.sharedData).length;

    if (isOpenedInitially) {
      const transaction = transactions.helpers.getTransactionSession();

      if (transaction.id) {
        transactions.helpers.releaseTransactionSession(transactions.transactionTypes.LIVE_DOCUMENT);
      }

      transactions.helpers.dedicateTransactionSession(transactions.transactionTypes.LIVE_DOCUMENT);
    }

    designAutomation.operations.subscribe(
      designAutomation.eventTypes.AUTOMATION_STARTED,
      this.handleAutomationStart
    );
    designAutomation.operations.subscribe(
      designAutomation.eventTypes.AUTOMATION_COMPLETED,
      this.handleAutomationEnd
    );
  }

  componentWillUnmount() {
    this.updateSharedData();

    designAutomation.operations.unsubscribe(
      designAutomation.eventTypes.AUTOMATION_STARTED,
      this.handleAutomationStart
    );
    designAutomation.operations.unsubscribe(
      designAutomation.eventTypes.AUTOMATION_COMPLETED,
      this.handleAutomationEnd
    );
  }

  onSubmit({ isClickOnOverlay } = {}) {
    const {
      onDialogMainAction,
      forceReloadView,
      setCleanUrlsEnabled,
      hideDialog,
      bulkViewActions,
    } = this.props;

    const {
      optionalChildren,
      colorSchemes,
      displayOptions,
      backgroundOptions,
      cachedBackgroundOptions,
      variationId,
      blockNode,
      blockId,
    } = this.state;

    transactions.helpers.releaseTransactionSession(transactions.transactionTypes.LIVE_DOCUMENT);

    saveBlockSettingsChanges({
      intermediateBlockId: this.intermediateBlockId,
      destinationBlockNode: this.destinationBlockNode,
      isClickOnOverlay,
      onDialogMainAction,
      optionalChildren,
      colorSchemes,
      displayOptions,
      backgroundOptions,
      cachedBackgroundOptions,
      variationId,
      hideDialog,
      bulkViewActions,
      originalBlock: this.originalBlock,
      blockNode,
      blockId,
      refId: this.refId,
      forceReloadView,
      setCleanUrlsEnabled,
      initialElement: this.initialElement,
      usedCustomColorOptions: this.usedCustomColorOptions,
      isOnlineStoreBlock: this.isOnlineStoreBlock,
    });
  }

  onCancel() {
    const { bulkSetChildrenPresence, hideDialog, forceReloadView, onDialogCancel } = this.props;
    const {
      blockId,
      blockVariationId,
      staticBlockNode: originalStaticBlockNode,
    } = this.originalBlock;
    const { optionalChildren, blockNode, colorSchemes, displayOptions, backgroundOptions } =
      this.state;

    transactions.helpers.releaseTransactionSession(transactions.transactionTypes.LIVE_DOCUMENT);

    cancelBlockSettingsChanges({
      destinationBlockNode: this.destinationBlockNode,
      isOnlineStoreBlock: this.isOnlineStoreBlock,
      refId: this.refId,
      usedCustomColorOptions: this.usedCustomColorOptions,
      blockId,
      bulkSetChildrenPresence,
      hideDialog,
      forceReloadView,
      onDialogCancel,
      optionalChildren,
      blockNode,
      originalStaticBlockNode,
      colorSchemes,
      displayOptions,
      backgroundOptions,
      variationId: blockVariationId,
    });
  }

  setOffsets(modalComponent) {
    const { offsetX: propsOffsetX, offsetY: propsOffsetY } = this.sharedData;
    if (propsOffsetX || propsOffsetY) return;

    const {
      modal: { width, height },
    } = modalComponent.state;

    const { offsetX, offsetY } = dialogs.helpers.getPositionByElement(
      this.blockElement,
      width,
      height
    );

    this.setState({
      offsetX,
      offsetY,
    });
  }

  getUpdatedOptions(data) {
    const newState = {};

    const { isOnlineStoreCleanUrlEnabled } = this.props;

    const { blockNode, displayOptions, colorPalette, backgroundOptions, variationId } = this.state;

    if (data.displayOptions) {
      newState.displayOptions = getUpdatedDisplayOptions({
        newDisplayOptions: data.displayOptions,
        oldDisplayOptions: displayOptions,
        blockNode,
        colorPalette,
        refId: this.refId,
        switchRadioOption: this.switchRadioOption,
        switchCheckboxOption: this.switchCheckboxOption,
        switchSurface: this.switchSurface,
        switchSliderOption: this.switchSliderOption,
        afterSwitchSliderOption: this.afterSwitchSliderOption,
        switchOnlineStoreCategory: this.switchOnlineStoreCategory,
        modifyDisplayOption: this.modifyDisplayOption,
        isOnlineStoreCleanUrlEnabled,
      });
    }

    if (data.backgroundOptions) {
      const newBackgroundOptions = data.backgroundOptions.map((item) =>
        createBackgroundOption({
          item,
          blockNode,
          backgroundOptions,
          variationId,
        })
      );

      newState.backgroundOptions = newBackgroundOptions;
      newState.cachedBackgroundOptions = newBackgroundOptions;
    } else {
      newState.backgroundOptions = null;
    }

    return newState;
  }

  setElementAttribute(element, attribute, value) {
    const { setElementAttribute } = this.props;
    const elementId = view.accessors.getLiveElementId(element);

    if (elementId) {
      setElementAttribute(elementId, attribute, value, LIVE_STRATEGY_OPTIONS);
    } else {
      element.setAttribute(attribute, value);
    }
  }

  prepareData(data, callback) {
    const { blockNode, colorPalette } = this.state;
    const newState = { ...this.getUpdatedOptions(data) };

    if (data.optionalChildren) {
      newState.optionalChildren = data.optionalChildren.map((item, index) =>
        createOptionalChildren({
          item,
          index,
          blockNode,
          initialElement: this.initialElement,
          onClick: this.switchChildDisplay,
        })
      );
    }

    if (data.colorSchemes) {
      // TODO: Remove this when the legacy template is deleted
      newState.colorSchemes = data.colorSchemes.map((item) =>
        createColorScheme({
          item,
          colorPalette,
          blockNode,
          elementId: this.refId,
          onClick: this.switchColorTheme,
        })
      );
    }

    this.initialElement = view.accessors.getStaticElement(this.refId).cloneNode(true);

    this.setState(
      {
        ...newState,
        timestamp: Date.now(),
      },
      callback
    );
  }

  switchChildDisplay(isEnabled, child, options = {}) {
    const { setChildrenPresence } = this.props;
    const { blockId: blockIdFromState, blockNode, optionalChildren, variationId } = this.state;

    const blockId = blockIdFromState || this.intermediateBlockId;

    const blockSettings = blocks.accessors.getBlockSettings(blockId);

    trackBlockSettingsOptionClicked({
      blockId,
      blockVariationId: variationId,
      blockSettingId: child.id,
    });

    const updatedChildren = optionalChildren.map((optionChild) => {
      if (optionChild.querySelector !== child.querySelector) return optionChild;

      const childrenPresenceUpdate = {
        blockSettingId: optionChild.id,
        childrenSelector: optionChild.querySelector,
        enabled: isEnabled,
      };

      setChildrenPresence(this.refId, childrenPresenceUpdate, LIVE_STRATEGY_OPTIONS);

      return {
        ...optionChild,
        isEnabled,
        highlighted: options.highlighted || false,
        touched: true,
      };
    });

    trackWsBlockContent(blockNode);
    clearTimeout(this.timer);

    this.setState(
      {
        optionalChildren: updatedChildren,
        isDirtyChildren: true,
        ...this.getUpdatedOptions(blockSettings),
      },
      this.scrollToBlock
    );
  }

  switchColorTheme(event, newColorScheme) {
    // TODO: Remove this when the legacy template is deleted
    const { colorSchemes, blockNode } = this.state;
    const { bulkViewActions } = this.props;
    const currentScheme = getActiveColorScheme(colorSchemes);

    const toRemove = { name: currentScheme.attribute, value: currentScheme.value };
    const toAdd = { name: newColorScheme.attribute, value: newColorScheme.value };

    const actions = attributeValuesList
      .getChangeOperations(blockNode, toRemove, toAdd)
      .map((operation) =>
        convertAttributeOperationToAction({
          ...operation,
          elementId: this.refId,
          options: LIVE_STRATEGY_OPTIONS,
        })
      );

    if (actions.length > 1) {
      bulkViewActions(actions, LIVE_STRATEGY_OPTIONS);
    } else {
      store.dispatch(actions[0]);
    }

    this.setState({
      colorSchemes: colorSchemes.map((item) => ({
        ...item,
        isEnabled: item.id === newColorScheme.id,
      })),
    });
  }

  switchRadioOption(event, option, newOption) {
    this.modifyDisplayOption(option, (displayOption) => {
      const { blockNode } = this.state;
      const targetElement = getOptionTargetElement(option, blockNode);
      const prevValue = targetElement.attributes[option.attribute].value;
      const isMultiValue = option.options.some(({ value }) => value !== prevValue);

      if (isMultiValue) {
        const newValue = prevValue.includes(option.value)
          ? prevValue.replace(option.value, newOption.value)
          : `${prevValue} ${newOption.value}`;
        this.setElementAttribute(targetElement, option.attribute, newValue);
      } else {
        this.setElementAttribute(targetElement, option.attribute, newOption.value);
      }

      return {
        ...displayOption,
        value: newOption.value,
      };
    });
  }

  switchOnlineStoreCategory(option, newValue) {
    this.modifyDisplayOption(option, (displayOption) => {
      const { blockNode } = this.state;
      const targetElement = getOptionTargetElement(option, blockNode);

      this.setElementAttribute(targetElement, option.attribute, newValue);

      return {
        ...displayOption,
        value: newValue,
      };
    });
  }

  switchSurface(surfaceDisplayOption, surface) {
    this.modifyDisplayOption(surfaceDisplayOption, (displayOption) => {
      const { setElementAttribute } = this.props;

      setElementAttribute(
        this.refId,
        surfaceDisplayOption.attribute,
        surface.value,
        LIVE_STRATEGY_OPTIONS
      );

      return {
        ...displayOption,
        value: surface.value,
      };
    });
  }

  switchSliderOption(option, value) {
    this.modifyDisplayOption(option, (displayOption) => {
      const { blockNode } = this.state;
      const targetElement = getOptionTargetElement(displayOption, blockNode);

      targetElement.setAttribute(displayOption.attribute, value);

      return {
        ...displayOption,
        value,
      };
    });
  }

  afterSwitchSliderOption(option, value) {
    const { blockNode } = this.state;
    const targetElement = getOptionTargetElement(option, blockNode);

    this.setElementAttribute(targetElement, option.attribute, value);
  }

  switchCheckboxOption(isEnabled, option) {
    clearTimeout(this.timer);

    this.modifyDisplayOption(option, (displayOption) => {
      const { blockNode } = this.state;
      const targetElement = getOptionTargetElement(option, blockNode);
      const displayOptionValue =
        typeof displayOption.value === 'boolean' ? '' : displayOption.value;

      const changedOption = {
        ...displayOption,
        isEnabled,
        value: displayOptionValue,
      };

      const operation = convertChangedOptionToOperation(changedOption, targetElement);

      if (operation.elementId) {
        const action = convertAttributeOperationToAction({
          ...operation,
          options: LIVE_STRATEGY_OPTIONS,
        });

        store.dispatch(action);
      } else if (operation.operation === SET) {
        targetElement.setAttribute(operation.name, operation.value);
      } else {
        targetElement.removeAttribute(operation.name);
      }

      return {
        ...displayOption,
        isEnabled,
      };
    }).then(this.scrollToBlock);
  }

  modifyDisplayOption(option, callback) {
    const { displayOptions, blockNode, variationId, blockId } = this.state;
    trackBlockSettingsOptionClicked({
      blockId,
      blockVariationId: variationId,
      blockSettingId: option.id,
    });

    let updatedOptions = displayOptions.map((displayOption) => {
      if (displayOption.id !== option.id) {
        return displayOption;
      }

      const modifiedOption = callback(displayOption);

      if (ATTRIBUTES_TO_MARK_AS_USER_MODIFIED.includes(modifiedOption.attribute)) {
        modifiedOption.userModified = true;
      }

      return modifiedOption;
    });

    updatedOptions = updateDependentWhiteSpaceOptions({
      displayOptions: updatedOptions,
      blockNode,
      editedOption: option,
      onChange: this.switchSliderOption,
      onAfterChange: this.afterSwitchSliderOption,
    });

    if (this.destinationBlockNode)
      this.selectedPreviewNode = this.destinationBlockNode.cloneNode(true);

    return new Promise((resolve) => {
      this.setState(
        {
          displayOptions: updatedOptions,
          isDirtyOptions: true,
          sortBlockVariationId: LIVE_PREVIEW_MOCKED_ID,
          selectedLayoutVariationId: LIVE_PREVIEW_MOCKED_ID,
        },
        resolve
      );
    });
  }

  scrollToBlock(callback = () => {}) {
    this.timer = setTimeout(() => {
      const { blockNode } = this.state;

      scroller.helpers.scrollToElement(blockNode, { includeOffset: false }).then(callback);
    }, 200);
  }

  handleKeyDown(e) {
    if (e.key === ENTER_KEY) {
      this.onSubmit();
    }
  }

  updateSharedData(propsOverwrite = {}) {
    const { resolveSharedData } = this.props;
    const {
      offsetX,
      offsetY,
      selectedLayoutVariationId,
      optionalChildren,
      sortBlockVariationId,
      displayOptions,
      blockNode,
      variationId,
      isDirtyOptions,
      isDirtyChildren,
      blockId,
      colorSchemes,
      backgroundOptions,
      cachedBackgroundOptions,
    } = this.state;

    resolveSharedData({
      blockId,
      blockNode,
      offsetX,
      offsetY,
      variationId,
      selectedLayoutVariationId,
      isDirtyOptions,
      isDirtyChildren,
      sortBlockVariationId,
      displayOptions,
      backgroundOptions,
      cachedBackgroundOptions,
      optionalChildren,
      colorSchemes,
      isOnlineStoreBlock: this.isOnlineStoreBlock,
      itemsWithDependencies: this.itemsWithDependencies,
      element: this.blockElement,
      refId: this.refId,
      blockElementId: this.blockElementId,
      compiledNodesCache: this.compiledNodesCache,
      selectedPreviewNode: this.selectedPreviewNode,
      destinationBlockNode: this.destinationBlockNode,
      optionalChildrenState: optionalChildren,
      blocksPreviewStylesheet: this.blocksPreviewStylesheet,
      originalBlock: this.originalBlock,
      initialElement: this.initialElement,
      ...propsOverwrite,
    });
  }

  displayBlockLayout() {
    const { blockId, variationId: blockVariationId } = this.state;
    this.updateSharedData();
    dialogs.operations.show(dialogTypes.BLOCK_LAYOUT_DIALOG);
    trackers.trackSwitchBlockLayoutButtonClicked({ blockId, blockVariationId });
  }

  handleDragEnd(_, { x, y }) {
    this.setState({ offsetX: x, offsetY: y });
  }

  layoutBlockItemPreprocessDecorator(category) {
    const {
      optionalChildren,
      displayOptions,
      backgroundOptions,
      cachedBackgroundOptions,
      selectedLayoutVariationId,
      sortBlockVariationId,
    } = this.state;

    return layoutBlockItemPreprocess(category, {
      optionalChildren,
      sortBlockVariationId,
      selectedLayoutVariationId,
      displayOptions,
      backgroundOptions,
      cachedBackgroundOptions,
      refId: this.refId,
      originalStaticBlockNode: this.originalBlock.staticBlockNode,
      originalBlockNode: this.originalBlock.blockNode,
      compiledNodesCache: this.compiledNodesCache,
      selectedPreviewNode: this.selectedPreviewNode,
      originalVariationId: this.originalBlock.blockVariationId,
    });
  }

  handleAutomationStart() {
    this.setState({
      isAutomationInProgress: true,
    });
  }

  handleAutomationEnd(instructions) {
    const isAutomationApplied = !!instructions.length;

    if (
      (isAutomationApplied || this.shouldPrepareDataAfterEmptyAutomation) &&
      template.verifiers.isMpt()
    ) {
      this.shouldPrepareDataAfterEmptyAutomation = false;
      const blockSettings = blocks.accessors.getBlockSettings(this.intermediateBlockId);
      this.prepareData(blockSettings);
    }

    this.setState({
      isAutomationInProgress: false,
    });
  }

  render() {
    const {
      optionalChildren,
      colorSchemes,
      displayOptions,
      offsetX,
      offsetY,
      blockNode,
      selectedLayoutVariationId,
      timestamp,
      isDirtyOptions,
      tabId,
      backgroundOptions,
      variationId,
      isAutomationInProgress,
    } = this.state;

    const { blockItems, blockTemplateCollection } = this.props;

    if (!optionalChildren && !colorSchemes && !displayOptions) return null;

    const { captions, itemsWithDependencies, category } = this;

    const visibleOptions = getVisibleOptions(displayOptions, blockNode, optionalChildren);

    const itemsToRender = getBlockItemsForRendering({
      blockId: this.intermediateBlockId,
      selectedLayoutVariationId,
      blockItems,
      category,
      itemsWithDependencies,
      blockTemplateCollection,
      optionalChildren,
      isDirtyOptions,
      variationId,
      originalBlock: this.originalBlock,
    });

    return (
      <BlockSettingsContext.Provider
        value={{
          blockItems: itemsToRender,
          selectedLayoutVariationId,
          captions,
          options: {
            optionalChildren,
            colorSchemes,
            displayOptions: visibleOptions,
            backgroundOptions, // Block background container
          },
          stateDisplayOptions: displayOptions, // Block image container
          originalBlock: this.originalBlock, // Current block layout preview
          displayBlockLayout: this.displayBlockLayout, // Block settings tabs container
          activeTabId: tabId, // Block settings tabs container
          blocksPreviewStylesheet: this.blocksPreviewStylesheet, // Block layout container
          itemPreProcessFunction: this.layoutBlockItemPreprocessDecorator, // Block layout container
          onSubmit: this.onSubmit, // Modals && block-settings-option
          timestamp, // Block settings tabs container
          updateSharedData: this.updateSharedData,
          scrollContainerRef: this.scrollContainerRef,
        }}
      >
        <BlockSettingsDialogContainer
          offsetX={offsetX}
          offsetY={offsetY}
          onDragEnd={this.handleDragEnd}
          onModalReady={this.setOffsets}
          onKeyDown={this.handleKeyDown}
          onCancel={this.onCancel}
          isAutomationInProgress={isAutomationInProgress}
        />
      </BlockSettingsContext.Provider>
    );
  }
}

BlockSettingsContainer.propTypes = {
  refId: PropTypes.string,
  blockId: PropTypes.string,
  element: PropTypes.object,
  hideDialog: PropTypes.func.isRequired,
  forceReloadView: PropTypes.func.isRequired,
  bulkViewActions: PropTypes.func.isRequired,
  setChildrenPresence: PropTypes.func.isRequired,
  bulkSetChildrenPresence: PropTypes.func.isRequired,
  setCleanUrlsEnabled: PropTypes.func.isRequired,
  blockItems: PropTypes.array.isRequired,
  blockTemplateCollection: PropTypes.array.isRequired,
  isOnlineStoreCleanUrlEnabled: PropTypes.bool.isRequired,
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
  tabId: PropTypes.string,
  getSharedData: PropTypes.func.isRequired,
  resolveSharedData: PropTypes.func.isRequired,
  setElementAttribute: PropTypes.func.isRequired,
  optionsSetup: PropTypes.shape({ optionalChildren: PropTypes.shape({}) }),
};

BlockSettingsContainer.defaultProps = {
  tabId: BLOCK_SETTINGS_LAYOUT_TAB_ID,
  element: null,
  blockId: null,
  refId: null,
  optionsSetup: {},
};

const mapDispatchToProps = {
  hideDialog: dialogs.actions.hide,
  setChildrenPresence: view.actions.setChildrenPresence,
  bulkSetChildrenPresence: view.actions.bulkSetChildrenPresence,
  bulkViewActions: view.actions.bulkViewActions,
  forceReloadView: view.actions.forceReloadView,
  setCleanUrlsEnabled: site.actions.setCleanUrlsEnabled,
  setElementAttribute: view.actions.setElementAttribute,
};

const mapStateToProps = (state) => ({
  blockItems: blocks.selectors.getBlocks(state),
  blockTemplateCollection: blockTemplates.selectors.getBlockTemplates(state),
  isOnlineStoreCleanUrlEnabled: site.selectors.getCleanUrlsEnabled(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(BlockSettingsContainer);
