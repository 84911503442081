import React from 'react';

const ShortTextIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="144" height="90" viewBox="0 0 144 90" fill="none">
    <rect x="20" y="33" width="104" height="24" rx="4" fill="#DADDE0" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.6498 39C27.0088 39 27.2998 39.291 27.2998 39.65L27.2998 49.65C27.2998 50.009 27.0088 50.3 26.6498 50.3C26.2908 50.3 25.9998 50.009 25.9998 49.65L25.9998 39.65C25.9998 39.291 26.2908 39 26.6498 39Z"
      fill="#646C75"
    />
  </svg>
);

export default ShortTextIcon;
