import getCustomTextColor from './get-custom-text-color';

const generateCustomColorsForCssRule = (cssRule, customColorsToGenerate = [], palette) => {
  if (!cssRule) return;
  const [, surface] = cssRule.selectorText.split(' ').map((selector) => selector.substring(1));

  customColorsToGenerate.forEach((customColor) => {
    const newColor = getCustomTextColor(customColor, surface, palette);
    cssRule.style.setProperty(customColor, newColor);
  });
};

export default generateCustomColorsForCssRule;
