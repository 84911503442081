import generateColorPalette from '@yola/ws-template-color-palette-generator';
import getColorsConfig from './get-colors-config';

const generatePalette = (colorPalette, colorPaletteConfig) => {
  const { colors: paletteColors, advancedColors = {}, id, parentId } = colorPalette;
  const colors = getColorsConfig(paletteColors, advancedColors);
  const compiledPalette = generateColorPalette({ config: colorPaletteConfig, colors });
  compiledPalette.id = id;

  if (parentId) {
    compiledPalette.parentId = parentId;
  }

  return compiledPalette;
};

export default generatePalette;
