import React from 'react';
import PropTypes from 'prop-types';
import { hdrm } from '@yola/ws-sdk';
import positions from '../constants/positions';
import getHandlerStyles from '../helpers/get-handler-styles';
import highlighterOffset from '../../highlighter/helpers/highlighter-offset';
import { TEXT_RESIZE_HANDLER_SIZE } from '../constants/common';

const { DISABLE_BLUR_ATTR } = hdrm.constants.attributes;
const disableBlurAttribute = { [DISABLE_BLUR_ATTR]: true };

const TextResizeHandler = React.forwardRef(
  ({ elementRect, scrollPosition, elementSidePosition }, ref) => {
    const indent = highlighterOffset.get();
    const styles = getHandlerStyles({
      elementRect,
      scrollPosition,
      elementSidePosition,
      indent,
    });

    return (
      <div className="ws-text-resize-handler" style={styles} ref={ref} {...disableBlurAttribute}>
        <svg
          className="ws-text-resize-handler__svg"
          style={{ width: `${TEXT_RESIZE_HANDLER_SIZE}px` }}
        >
          <circle
            className="ws-text-resize-handler__mask"
            r="5"
            cx="6"
            cy="6"
            strokeWidth="2"
            fill="white"
          />
        </svg>
      </div>
    );
  }
);

TextResizeHandler.propTypes = {
  elementRect: PropTypes.object.isRequired,
  scrollPosition: PropTypes.number.isRequired,
  elementSidePosition: PropTypes.oneOf([positions.LEFT, positions.RIGHT]).isRequired,
};

export default TextResizeHandler;
