import setGeneralColor from './set-general-color';
import isAccentColorSupported from './is-accent-color-supported';
import getInitialGeneralColor from './get-initial-general-color';
import { colorToSurfaceMap } from '../constant/colors-maps';

const resetColorSchemeColors = ({ colorKey: backgroundColorKey, colorPalette, parentPalette }) => {
  const surface = colorToSurfaceMap[backgroundColorKey];
  const accent1ColorKey = `${backgroundColorKey}-primary`;
  const accent2ColorKey = `${backgroundColorKey}-secondary`;
  const initialColors = parentPalette ? parentPalette.colors : colorPalette.colors;
  const advancedColors = colorPalette.advancedColors || {};
  const initialBackgroundColor = getInitialGeneralColor(backgroundColorKey, initialColors);

  let updatedPalette = setGeneralColor({
    colorKey: backgroundColorKey,
    color: initialBackgroundColor,
    colorPalette,
  });

  if (isAccentColorSupported(accent1ColorKey)) {
    const initialAccentColor = getInitialGeneralColor(accent1ColorKey, initialColors);

    updatedPalette = setGeneralColor({
      colorKey: accent1ColorKey,
      color: initialAccentColor,
      colorPalette: updatedPalette,
    });
  }

  if (isAccentColorSupported(accent2ColorKey)) {
    const initialAccentColor = getInitialGeneralColor(accent2ColorKey, initialColors);

    updatedPalette = setGeneralColor({
      colorKey: accent2ColorKey,
      color: initialAccentColor,
      colorPalette: updatedPalette,
    });
  }

  const filteredAdvancedColors = Object.fromEntries(
    Object.entries(advancedColors).filter(([key]) => !key.includes(surface))
  );

  return { ...updatedPalette, advancedColors: filteredAdvancedColors };
};

export default resetColorSchemeColors;
