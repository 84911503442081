import React, { Fragment, useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import bowser from 'yola-bowser';
import { view, dialogs, globals, template } from '@yola/ws-sdk';
import customUI from 'src/js/modules/custom-ui';
import contextMenu from 'src/js/modules/context-menu';
import useFeatureFlags from 'yola-editor/src/js/modules/feature-flags/hooks/use-feature-flags';
import { NAVIGATION_GLOBAL_ID } from '../../navigation/constants/common';
import getTargetElements from '../helpers/get-target-elements';
import BlockFloatingTriggers from '../components/block-floating-triggers';
import WidgetFloatingTriggers from '../components/widget-floating-triggers';
import isElementWidget from '../helpers/is-element-widget';
import getBlockTriggersInfo from '../helpers/get-block-triggers-info';
import getWidgetTriggersInfo from '../helpers/get-widget-triggers-info';
import shouldTriggersBeShown from '../helpers/should-triggers-be-shown';

const RE_RENDER_DELAY = 250;

const FloatingTriggerContainer = (props) => {
  const { mousePosition, viewportHeight } = props;

  const dispatch = useDispatch();

  const [delayedElementId, setDelayedElementId] = useState(null);

  const timer = useRef(null);

  const hoveredElementId = useSelector(view.selectors.getHoveredElement);
  const focusedElementId = useSelector(view.selectors.getFocusedElement);
  const selectedElementId = useSelector(view.selectors.getSelectedElement);
  const scrollPosition = useSelector(view.selectors.getScrollPosition);
  const isViewLoaded = useSelector(view.selectors.getLoadedStatus);
  const isDialogVisible = useSelector(dialogs.verifiers.isVisible);
  const customUIVisibility = useSelector(customUI.selectors.getVisibilityState);
  const isContextMenuVisible = useSelector(contextMenu.selectors.getVisibilityStatus);
  const pageContainerSelector = useSelector(template.selectors.getPageContainerSelector);
  const [featureFlags] = useFeatureFlags(['hidden_blocks']);

  const stopTimer = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
  }, []);

  const scheduleElementIdSetting = useCallback(() => {
    timer.current = setTimeout(() => setDelayedElementId(hoveredElementId), RE_RENDER_DELAY);
  }, [hoveredElementId]);

  const showDialog = useCallback(
    (modalType, modalProps) => {
      dispatch(dialogs.actions.show(modalType, modalProps));
    },
    [dispatch]
  );

  useEffect(() => {
    scheduleElementIdSetting();

    return stopTimer;
  }, [scheduleElementIdSetting, stopTimer]);

  if (
    !shouldTriggersBeShown({
      isViewLoaded,
      isDialogVisible,
      customUIVisibility,
      isContextMenuVisible,
      focusedElementId,
      selectedElementId,
    })
  ) {
    return null;
  }

  if ((bowser.mobile || bowser.tablet) && delayedElementId) {
    const delayedElement = view.accessors.getLiveElement(delayedElementId);

    if (
      delayedElement &&
      globals.verifiers.isElementGlobal(delayedElementId, NAVIGATION_GLOBAL_ID)
    ) {
      return null;
    }
  }

  const targetElements = getTargetElements(delayedElementId, pageContainerSelector, featureFlags);

  const blockTargetElementId = targetElements.find((elementId) => {
    const element = view.accessors.getLiveElement(elementId);
    return !isElementWidget(element);
  });

  const blockTriggersInfo = getBlockTriggersInfo({
    elementId: blockTargetElementId,
    scrollPosition,
    pageContainerSelector,
    viewportHeight,
  });

  const [widgetTargetElementId] = targetElements;

  const widgetTriggersInfo = getWidgetTriggersInfo({
    elementId: widgetTargetElementId,
    mousePosition,
    scrollPosition,
    pageContainerSelector,
    viewportHeight,
  });

  return (
    <Fragment>
      <WidgetFloatingTriggers
        triggersInfo={widgetTriggersInfo}
        blockTriggersInfo={blockTriggersInfo}
        onMouseEnter={stopTimer}
        onMouseLeave={scheduleElementIdSetting}
        showDialog={showDialog}
      />
      <BlockFloatingTriggers
        triggersInfo={blockTriggersInfo}
        onMouseEnter={stopTimer}
        onMouseLeave={scheduleElementIdSetting}
        showDialog={showDialog}
      />
    </Fragment>
  );
};

FloatingTriggerContainer.propTypes = {
  viewportHeight: PropTypes.number.isRequired,
  mousePosition: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
};

FloatingTriggerContainer.defaultProps = {
  mousePosition: {
    x: null,
    y: null,
  },
};

export default FloatingTriggerContainer;
