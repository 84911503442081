import getChangedItems from '../get-changed-items';

const getChanges = ({ optionalChildren, colorSchemes, displayOptions }) => {
  const changedChildren = getChangedItems(optionalChildren);
  const changedColorScheme = getChangedItems(colorSchemes);
  const changedDisplayOptions = getChangedItems(displayOptions);

  const changesList = [...changedColorScheme, ...changedDisplayOptions];

  return [changedChildren, changesList];
};

export default getChanges;
