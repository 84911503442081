import actionTypes from '../constants/action-types';

const siteId = (state = '', action) => {
  switch (action.type) {
    case actionTypes.SET_SITE_ID:
      return action.payload;
    default:
      return state;
  }
};

export default siteId;
