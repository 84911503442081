import { constraints } from '@yola/ws-sdk';
import SpacerResezeTool from '../containers/spacer-resize-tool';

const spacerResizeToolSettings = {
  matches(element) {
    return element && constraints.verifiers.canBeSpaceResizable(element);
  },
  container: SpacerResezeTool,
};

export default spacerResizeToolSettings;
