import getRelativeOffset from './get-relative-offsets';
import { ASPECT_RATIO_ATTRIBUTE, CONTENT_POSITION_ATTRIBUTE } from '../constants/media-container';

const handleAspectRatioChanges = ({ element, newAspectRatio, bounds }) => {
  const { width, height } = bounds;
  const { aspectRatio, contentPosition } = element;

  if (aspectRatio === newAspectRatio) return;

  const { top, bottom } = getRelativeOffset({
    width,
    mediaAspectRatio: aspectRatio,
    containerHeight: height,
    position: contentPosition,
  });

  if (top < 0 && bottom > 100) {
    const nextContainerHeight = width / newAspectRatio;
    const [x, y] = contentPosition;

    if (aspectRatio >= newAspectRatio) {
      element.setAttribute(ASPECT_RATIO_ATTRIBUTE, newAspectRatio);
      element.setAttribute(CONTENT_POSITION_ATTRIBUTE, `${x}% 50%`);
      return;
    }

    const { top: nextTopOffset, bottom: nextBottomOffset } = getRelativeOffset({
      width,
      mediaAspectRatio: aspectRatio,
      containerHeight: nextContainerHeight,
      position: contentPosition,
    });

    let newVerticalPosition;

    if (nextTopOffset >= 0) {
      newVerticalPosition = Math.round(y - nextTopOffset);
    } else if (nextBottomOffset < 100) {
      newVerticalPosition = Math.round(y + (100 - nextBottomOffset));
    }

    if (newVerticalPosition) {
      const newPositionAttribute = `${x}% ${newVerticalPosition}%`;
      element.setAttribute(CONTENT_POSITION_ATTRIBUTE, newPositionAttribute);
    }
  }

  element.setAttribute(ASPECT_RATIO_ATTRIBUTE, newAspectRatio);
};

export default handleAspectRatioChanges;
