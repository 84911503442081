export default {
  statuses: {
    GRANTED: 'granted',
    DENIED: 'denied',
    PROMPT: 'prompt',
  },
  features: {
    CLIPBOARD_READ: 'clipboard-read',
    CLIPBOARD_WRITE: 'clipboard-write',
  },
};
