import filterControlsByDisplayProperties from './filter-controls-by-display-properties';
import { shouldControlDisplayInMoreMenu } from './should-control-display-in';

function filterControlsOfContextMenuGroupsByDisplayProperties(controlGroups) {
  return controlGroups.reduce((filteredGroups, group) => {
    const { items, ...restGroup } = group;
    const filteredItems = filterControlsByDisplayProperties(items, shouldControlDisplayInMoreMenu);

    if (filteredItems.length) {
      const filteredGroup = {
        items: filteredItems,
        ...restGroup,
      };

      return [...filteredGroups, filteredGroup];
    }

    return filteredGroups;
  }, []);
}

export default filterControlsOfContextMenuGroupsByDisplayProperties;
