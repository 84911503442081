import React from 'react';
import PropTypes from 'prop-types';

class AnimatedSprite extends React.Component {
  constructor(props) {
    super(props);
    this.progressRef = React.createRef();
  }

  componentDidMount() {
    const target = this.progressRef.current;
    const { style } = target;
    const {
      animationName,
      animationLength,
      animationSteps,
      animationCount,
      width,
      height,
      spriteWidth,
      src,
    } = this.props;

    style.animation = `${animationName} ${animationLength} ${animationSteps} ${animationCount}`;
    style.backgroundImage = `url(${src})`;
    style.backgroundSize = `${spriteWidth}px ${height}px`;
    style.height = `${height}px`;
    style.width = `${width}px`;
  }

  render() {
    const { style } = this.props;
    return <div ref={this.progressRef} style={style} />;
  }
}

AnimatedSprite.propTypes = {
  animationCount: PropTypes.string.isRequired,
  animationLength: PropTypes.string.isRequired,
  animationName: PropTypes.string.isRequired,
  animationSteps: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  spriteWidth: PropTypes.number.isRequired,
  src: PropTypes.string.isRequired,
  style: PropTypes.shape(),
  width: PropTypes.number.isRequired,
};

AnimatedSprite.defaultProps = {
  style: {},
};

export default AnimatedSprite;
