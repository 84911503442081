import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { designSystem, reactHookForm } from '@yola/ws-ui';
import { i18n } from '@yola/ws-sdk';
import loadTaxonomyOptions from 'src/js/modules/common/helpers/load-taxonomy-options';
import usePrevious from '../../../utils/custom-hooks/use-previous';

const { useController } = reactHookForm;
const { AutocompleteField, Icon } = designSystem;

const getIconComponent = (isValid) => <Icon glyph="search" type={isValid ? 'default' : 'error'} />;

const BusinessCategoryField = ({
  control,
  name,
  rules,
  placeholder,
  noResultsText,
  onChange,
  onFocus,
}) => {
  const { field, fieldState } = useController({
    name,
    control,
    rules,
  });

  const locale = i18n.accessors.getLocale();
  const autocompleteRef = useRef(null);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [businessCategoryValue, setBusinessCategoryValue] = useState('');
  const prevBusinessCategoryValue = usePrevious(businessCategoryValue);
  const [firstRenderOptions, setFirstRenderOptions] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onBeforeLoadOptions = () => {
    setDefaultOptions([]);
    setIsLoading(true);
  };

  const onAfterLoadOptions = (options) => {
    setIsLoading(false);
    setDefaultOptions(options);
  };

  const handleChange = (option) => {
    if (option?.value) {
      setSelectedOption(option);
      onChange(option);
    }
    setBusinessCategoryValue(option?.label || '');
  };

  const handleFocus = () => {
    if (businessCategoryValue && !defaultOptions?.length && firstRenderOptions?.length) {
      setDefaultOptions(firstRenderOptions);
      setFirstRenderOptions([]);
    }
    setIsFocused(true);
    onFocus();
  };

  const handleBlur = () => {
    if (businessCategoryValue && businessCategoryValue !== prevBusinessCategoryValue) {
      loadTaxonomyOptions(locale, onAfterLoadOptions, onBeforeLoadOptions)(businessCategoryValue);
    }

    setIsFocused(false);
  };

  useEffect(() => {
    if (!businessCategoryValue && field?.value?.label && firstRenderOptions === null) {
      loadTaxonomyOptions(locale, setFirstRenderOptions)(field.value.label);
      autocompleteRef.current.selectOption(field.value);
    }
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, [field.value]);

  return (
    <AutocompleteField
      ref={autocompleteRef}
      control={control}
      name={name}
      rules={rules}
      asyncConfig={{
        defaultOptions,
        isLoading,
        loadOptions: loadTaxonomyOptions(locale, onAfterLoadOptions, onBeforeLoadOptions),
        cacheOptions: false,
      }}
      placeholder={placeholder}
      size="medium"
      filterOptionsFunction={(value) => value}
      options={[]}
      inputValue={businessCategoryValue}
      onInputChange={setBusinessCategoryValue}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      iconComponent={getIconComponent(!fieldState.invalid)}
      noResultsText={noResultsText}
      openMenuOnFocus={Boolean(businessCategoryValue)}
      menuIsOpen={
        !selectedOption ||
        (selectedOption &&
          businessCategoryValue &&
          selectedOption.label !== businessCategoryValue) ||
        (isFocused && Boolean(businessCategoryValue))
      }
      blurInputOnSelect
      shouldMarkSelectedOption
    />
  );
};

BusinessCategoryField.propTypes = {
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  rules: PropTypes.shape({}).isRequired,
  placeholder: PropTypes.string.isRequired,
  noResultsText: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
};

export default BusinessCategoryField;
