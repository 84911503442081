import { selectors } from '@yola/subscription-manager-js';
import featuresActionTypes from 'yola-editor/src/js/modules/features/constants/action-types';
import user from 'yola-editor/src/js/modules/user';
import subscriptions from 'yola-editor/src/js/modules/subscriptions';
import auth from 'yola-editor/src/js/modules/auth';
import fetchUpgradeOptions from '../thunks/fetch-upgrade-options';

const upgradeOptionsFetcher = (store) => (next) => (action) => {
  // due to rice condition we don't know which response will be handled last.
  if (
    action.type === featuresActionTypes.FETCH_AVAILABLE_PACKAGES_COMPLETE ||
    action.type === subscriptions.actionTypes.FETCH_CURRENT_PACKAGE_COMPLETE
  ) {
    next(action);

    const state = store.getState();
    const currentPackage = selectors.getCurrentPackage(state);
    const availablePackages = selectors.getHostingPackages(state);

    // let's wait until all data is loaded
    if (!currentPackage || !availablePackages) return;

    const partnerData = user.selectors.getPartnerData(state);
    const { submitUserActionUrl } = partnerData.properties;
    const hasUpgradeOpportunity = selectors.getHostingUpgradeAvailability(state);

    if (submitUserActionUrl && hasUpgradeOpportunity) {
      const siteId = auth.selectors.getSiteId(state);

      store.dispatch(fetchUpgradeOptions(siteId));
    }

    return;
  }

  next(action);
};

export default upgradeOptionsFetcher;
