import { textFormatting } from '@yola/ws-sdk';

const { wrappersTypes } = textFormatting.constants;

const getRelatedClassName = (tagName) => {
  const wrappersType = Object.values(wrappersTypes).find((type) => type.tagName === tagName);

  return wrappersType && wrappersType.className;
};

export default getRelatedClassName;
