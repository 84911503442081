import React from 'react';
import PropTypes from 'prop-types';

function ProgressIndicator(props) {
  const { total, current } = props;
  const progress = current && Math.ceil((current / total) * 100);

  return (
    <div className="ws-progress-indicator">
      <div
        className="ws-progress-indicator__progress"
        style={{ width: `${progress > 100 ? 100 : progress}%` }}
      />
    </div>
  );
}

ProgressIndicator.propTypes = {
  total: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
};

export default ProgressIndicator;
