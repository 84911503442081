import React from 'react';
import PropTypes from 'prop-types';
import { dialogs, i18next } from '@yola/ws-sdk';
import { AlertDialog } from '@yola/ws-ui';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import constants from '../constants';

const {
  common: { MAX_FILE_UPLOAD_QUANTITY },
} = constants;

function FileQuantityInfoDialog(props) {
  const { captions, items, elementId, onDismiss, onDialogCancel } = props;

  const getCaptions = (captionsOverrides) => ({
    title: i18next.t('Upload limit reached'),
    description: i18next.t(
      'File uploads are limited to {quantity} files at one time. Please try re-uploading your remaining files again.',
      {
        quantity: MAX_FILE_UPLOAD_QUANTITY,
      }
    ),
    dismiss: i18next.t('Return'),
    ...captionsOverrides,
  });

  const handleDismiss = (...args) => {
    onDialogCancel();
    if (onDismiss) {
      onDismiss(...args);
    } else {
      dialogs.operations.show(dialogTypes.GALLERY_DIALOG, { items, elementId });
    }
  };

  return <AlertDialog onDismiss={handleDismiss} captions={getCaptions(captions)} />;
}

FileQuantityInfoDialog.defaultProps = {
  captions: {},
  items: [],
  onDismiss: null,
};

FileQuantityInfoDialog.propTypes = {
  onDismiss: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.shape()),
  elementId: PropTypes.string.isRequired,
  captions: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    dismiss: PropTypes.string,
  }),
  onDialogCancel: PropTypes.func.isRequired,
};

export default FileQuantityInfoDialog;
