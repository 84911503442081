import React from 'react';
import PropTypes from 'prop-types';
import { view, dialogs, i18next } from '@yola/ws-sdk';
import { TreeView } from '@yola/ws-ui';
import buildNewMarkup from '../helpers/build-new-markup';
import getPositionByTriggerElement from '../helpers/get-position-by-trigger-element';
import getWsNav from '../helpers/get-ws-nav';
import LinkListSettingsDialogContainer from '../../../../common/components/link-list-settings-dialog-container';
import dialogTypes from '../../../../dialogs/constants/dialog-types';

const TABLET_BREAKPOINT = 860;
const DIALOG_WIDTH = 374;
const DIALOG_HEIGHT = 484;

const closeDialog = () => {
  dialogs.operations.hide();
};

class NavigationEditDialog extends React.Component {
  constructor(props) {
    super(props);

    const { elementId, offsetX, offsetY, triggerElement, positionDirection } = props;
    const targetElement = view.accessors.getLiveElement(elementId);

    if (offsetX !== null) {
      this.dialogPosition = { offsetX, offsetY };
    } else if (triggerElement && window.innerWidth > TABLET_BREAKPOINT) {
      // Dialog triggered via alternative trigger on the Desktop
      this.dialogPosition = getPositionByTriggerElement(
        triggerElement,
        positionDirection,
        DIALOG_WIDTH,
        DIALOG_HEIGHT
      );
    } else {
      // Dialog triggered via Control Pane in a default way
      this.dialogPosition = dialogs.helpers.getPositionByElement(
        targetElement,
        DIALOG_WIDTH,
        DIALOG_HEIGHT
      );
    }

    this.openChangeItemDialog = this.openChangeItemDialog.bind(this);
    this.applyChanges = this.applyChanges.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  openChangeItemDialog({ items, item, offsetX, offsetY }) {
    const { showDialog, elementId } = this.props;

    closeDialog();

    showDialog(items, dialogTypes.NAVIGATION_ITEM_SETTINGS_DIALOG, {
      elementId,
      offsetX,
      offsetY,
      id: item && item.id,
    });
  }

  applyChanges({ items, isItemsChanged }) {
    const { elementId, onDialogMainAction } = this.props;

    onDialogMainAction();

    if (!isItemsChanged) {
      closeDialog();
      return;
    }

    const { templates, selectors } = getWsNav();

    const targetLiveElement = view.accessors.getLiveElement(elementId);
    const menuLiveElement = targetLiveElement.matches(selectors.menu)
      ? targetLiveElement
      : targetLiveElement.querySelector(selectors.menu);
    const menuLiveElementId = view.accessors.getLiveElementId(menuLiveElement);
    const menuStaticElement = view.accessors.getStaticElement(menuLiveElementId);

    const targetElement = buildNewMarkup(menuStaticElement, items, templates, selectors);
    view.operations.replaceElement(targetElement, menuLiveElementId);

    closeDialog();
  }

  handleCancel() {
    const { onDialogCancel } = this.props;

    onDialogCancel();
    closeDialog();
  }

  render() {
    const { offsetX, offsetY } = this.dialogPosition;
    const { items, initialItems } = this.props;

    return (
      <LinkListSettingsDialogContainer
        items={items}
        initialItems={initialItems}
        offsetX={offsetX}
        offsetY={offsetY}
        onSubmit={this.applyChanges}
        onClose={closeDialog}
        onOpenChangeItemDialog={this.openChangeItemDialog}
        modalContentClassName="ws-page-manager"
        dialogWidth={DIALOG_WIDTH}
        dialogHeight={DIALOG_HEIGHT}
        captions={{
          dialogTitle: i18next.t('Navigation settings'),
          submit: i18next.t('Submit'),
          cancel: i18next.t('Cancel'),
          emptyTitle: i18next.t('Add item'),
          emptyDescription: i18next.t('Start building your navigation'),
        }}
      />
    );
  }
}

NavigationEditDialog.propTypes = {
  elementId: PropTypes.string,
  triggerElement: PropTypes.instanceOf(Element),
  showDialog: PropTypes.func.isRequired,
  items: TreeView.propTypes.items,
  initialItems: TreeView.propTypes.items,
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,
  positionDirection: PropTypes.string,
  // Passed from ws-editor's `dialogs-container`:
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

NavigationEditDialog.defaultProps = {
  elementId: '',
  triggerElement: null,
  items: null,
  initialItems: null,
  offsetX: null,
  offsetY: null,
  positionDirection: '',
};

export default NavigationEditDialog;
