import { assets, dialogs, extensions, uuid, view } from '@yola/ws-sdk';
import store from 'src/js/store';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import mediaAttributes from 'src/js/modules/extensions/common/constants/media-attributes';
import matchesOrContains from './matches-or-contains';
import constants from '../constants/common';
import permissions from '../constants/permissions';
import customDataTypes from '../constants/custom-data-types';
import imageFileFormats from '../constants/image-file-formats';
import showUnsupportedBrowserDialog from './show-unsupported-browser-dialog';
import imageMimeToExtension from './image-mime-to-extension';
import showCopyPasteHotkeysDialog from './show-copy-paste-hotkeys-dialog';
import handleClipboardPermissionDenied from './handle-clipboard-permission-denied';
import cacheSiteImage from './cache-site-image';
import getFileExtension from './get-file-extension';
import isPlaceholder from './is-placeholder';

const getActionsFromClipboard = async ({
  elementId,
  imageElementId,
  clipboardContent,
  isBackgroundImage,
}) => {
  const actions = [];
  const imageProps = {};
  if (clipboardContent.length === 0) return null;

  let isImageExists = false;
  let isSupportedImageExists = false;
  let isNativeImageExists = false;

  // eslint-disable-next-line no-restricted-syntax
  for (const clipboardItem of clipboardContent) {
    // eslint-disable-next-line no-restricted-syntax
    for (const type of clipboardItem.types) {
      /* eslint-disable no-await-in-loop */
      const blob = await clipboardItem.getType(type);

      if (type.includes('image')) {
        isImageExists = true;

        if (type.startsWith('web ') || imageMimeToExtension(type)) {
          isSupportedImageExists = true;
        }
        dialogs.operations.show(dialogTypes.IMAGE_LOADING_DIALOG);
        try {
          await assets.operations.clientUploadAsset(blob);
          const newAsset = await assets.operations.createMediaGalleryAssets({
            asset: blob,
            name: `${uuid()}.${imageMimeToExtension(type.replace('web ', ''))}`,
          });

          await cacheSiteImage(newAsset.data.source);

          if (isBackgroundImage) {
            actions.push(
              view.actions.setElementAttribute(elementId, 'background-image', newAsset.data.source)
            );
          } else {
            actions.push(
              view.actions.setElementAttribute(imageElementId, 'src', newAsset.data.source)
            );
          }

          if (Object.keys(imageProps).length === 0) {
            if (isBackgroundImage) {
              imageProps['background-position'] = '50% 50%';
              imageProps['background-scale'] = '1';
            } else {
              imageProps['content-position'] = '50% 50%';
              imageProps['content-scale'] = '1';
              imageProps.alt = '';
            }
          }
        } catch (e) {
          console.error(e);
        }
      }

      if (type === customDataTypes.WEB_TEXT_PLAIN) {
        const content = await blob.text();
        try {
          const properties = JSON.parse(content);
          if (properties.position !== undefined) {
            if (isBackgroundImage) {
              imageProps['background-position'] = properties.position;
              imageProps['background-scale'] = properties.scale;
              if (properties.overlay) {
                imageProps.overlay = properties.overlay;
                imageProps['overlay-opacity'] = properties.overlayOpacity;
              }
            } else {
              imageProps['aspect-ratio'] = properties.aspectRatio;
              imageProps['content-position'] = properties.position;
              imageProps['content-scale'] = properties.scale;
              imageProps.alt = properties.alt;
            }
          }
        } catch (e) {
          console.error(e);
        }
      } else if (type === 'text/plain') {
        const content = await blob.text();
        const extension = getFileExtension(content);
        if (extension && imageFileFormats.includes(extension.toLowerCase())) {
          isNativeImageExists = true;
        }
      }
      /* eslint-enable no-await-in-loop */
    }
  }

  if ((Object.keys(imageProps).length === 0 && isNativeImageExists) || !isImageExists) {
    return null;
  }

  if (isImageExists && !isSupportedImageExists) {
    throw new Error(constants.WRONG_FILE_TYPE_ERROR);
  }

  const element = view.accessors.getLiveElement(elementId);
  Object.keys(imageProps).forEach((propName) => {
    if (propName === 'overlay' && !imageProps.overlay) {
      actions.push(view.actions.removeElementAttribute(elementId, propName));
      return;
    }

    if (propName === 'alt' && imageProps.alt) {
      actions.push(
        view.actions.setElementAttribute(imageElementId, propName, imageProps[propName])
      );
      return;
    }

    if (imageProps[propName] !== undefined) {
      actions.push(view.actions.setElementAttribute(elementId, propName, imageProps[propName]));
    }
  });

  const isImagePlaceholder = isPlaceholder(element);
  if (isImagePlaceholder) {
    const attrToRemove = isBackgroundImage
      ? mediaAttributes.BACKGROUND_PLACEHOLDER_ATTRIBUTE
      : mediaAttributes.PLACEHOLDER_ATTRIBUTE;
    actions.push(view.actions.removeElementAttribute(elementId, attrToRemove));
  }

  return actions;
};

const pasteImageFromClipboard = async (element, config) => {
  const settings = extensions.accessors.getExtensionSettings(config.slug);
  const elementId = view.accessors.getLiveElementId(element);
  const imageElement = matchesOrContains(element, settings.imageActionSelector);
  const isBackgroundImage = element.matches(settings.blockWithBackgroundQuerySelector);
  const imageElementId = view.accessors.getLiveElementId(imageElement);

  if (!isBackgroundImage && !imageElement) return;

  let isDenied = await handleClipboardPermissionDenied(permissions.features.CLIPBOARD_READ);

  if (isDenied) return;

  let clipboardContent;
  try {
    clipboardContent = await navigator.clipboard.read();
  } catch (e) {
    console.error(e);
    isDenied = await handleClipboardPermissionDenied(permissions.features.CLIPBOARD_READ);
    if (isDenied) return;

    showUnsupportedBrowserDialog();
    return;
  }

  let actions;
  try {
    actions = await getActionsFromClipboard({
      elementId,
      imageElementId,
      clipboardContent,
      isBackgroundImage,
    });
  } catch (e) {
    if (e.message === constants.WRONG_FILE_TYPE_ERROR) {
      dialogs.operations.show(dialogTypes.FILE_TYPE_ERROR_DIALOG, {
        formats: imageFileFormats.map((item) => `.${item}`).join(', '),
      });
    } else {
      console.error(e);
    }
    return;
  }

  if (!actions) {
    showCopyPasteHotkeysDialog();
    return;
  }

  store.dispatch(view.actions.bulkViewActions(actions));
  dialogs.operations.hide();
};

export default pasteImageFromClipboard;
