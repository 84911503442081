import { site, template } from '@yola/ws-sdk';
import segment from 'src/js/modules/analytics/segment';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';

const trackBlockLayoutDialogSubmitted = ({
  isOverlayClicked,
  blockId,
  blockVariationId,
  newBlockLayoutSelected,
}) => {
  if (!blockId) return;

  const { track, constants } = segment;

  track(constants.events.BLOCK_LAYOUT_DIALOG_SUBMITTED, {
    siteId: site.accessors.getSiteId(),
    templateBuildSlug: template.accessors.getBuildSlug(),
    dialogId: dialogTypes.BLOCK_SETTINGS,
    triggerId: isOverlayClicked ? 'dialog-background' : 'submit-button',
    blockId,
    blockVariationId,
    newBlockLayoutSelected,
  });
};

export default trackBlockLayoutDialogSubmitted;
