import positions, { directions } from '../constants/resize-handle-positions';

const getHandleDirectionByPosition = (position) => {
  switch (position) {
    case positions.BOTTOM_CENTER:
    case positions.TOP_CENTER:
      return directions.TOP_BOTTOM;
    case positions.CENTER_LEFT:
    case positions.CENTER_RIGHT:
      return directions.LEFT_RIGHT;
    case positions.TOP_LEFT:
      return directions.TOP_LEFT;
    case positions.TOP_RIGHT:
      return directions.TOP_RIGHT;
    case positions.BOTTOM_LEFT:
      return directions.BOTTOM_LEFT;
    case positions.BOTTOM_RIGHT:
      return directions.BOTTOM_RIGHT;
    default:
      return directions.ALL;
  }
};

export default getHandleDirectionByPosition;
