import calculateDaysDifference from './calculate-days-difference';
import calculateHoursDifference from './calculate-hours-difference';
import constants from '../constants';

const {
  SURVEY_POSTPONED_PERIOD_DAYS,
  SURVEY_STATUS_COMPLETED,
  SURVEY_STATUS_POSTPONED,
  RE_SURVEY_PERIOD_DAYS,
  INIT_SURVEY_PERIOD_HOURS,
} = constants;

const isNPSAvailable = (signupDate, currentDate, npsSurveyUpdatedAt, npsScore, npsSurveyStatus) => {
  const fromSignUpToCurrentDays = calculateDaysDifference(signupDate, currentDate);
  const fromSignUpToCurrentHours = calculateHoursDifference(signupDate, currentDate);
  const fromPostponedToCurrentDays = calculateDaysDifference(npsSurveyUpdatedAt, currentDate);

  if (npsScore) {
    if (
      fromSignUpToCurrentDays >= RE_SURVEY_PERIOD_DAYS &&
      fromPostponedToCurrentDays >= RE_SURVEY_PERIOD_DAYS &&
      npsSurveyStatus === SURVEY_STATUS_COMPLETED
    ) {
      return true;
    }

    if (
      fromSignUpToCurrentDays >= RE_SURVEY_PERIOD_DAYS &&
      npsSurveyStatus === SURVEY_STATUS_POSTPONED &&
      npsSurveyUpdatedAt &&
      fromPostponedToCurrentDays >= SURVEY_POSTPONED_PERIOD_DAYS
    ) {
      return true;
    }

    return false;
  }

  if (!npsSurveyUpdatedAt && fromSignUpToCurrentHours >= INIT_SURVEY_PERIOD_HOURS) {
    return true;
  }

  if (npsSurveyUpdatedAt && fromPostponedToCurrentDays >= SURVEY_POSTPONED_PERIOD_DAYS) {
    return true;
  }

  return false;
};

export default isNPSAvailable;
