import { serviceClient } from '@yola/ws-sdk';
import pollAsyncJob from '../../utils/poll-async-job';

const POLLING_INTERVAL = 3000;

const initiatePageReplacement = async ({ pageId, locale, recipeId }) => {
  const client = serviceClient.get();
  const response = await client.initiatePageReplacement({ pageId, locale, recipeId });
  const {
    data: { id: jobId },
  } = response;

  return pollAsyncJob(jobId, POLLING_INTERVAL);
};

export default initiatePageReplacement;
