import store from 'src/js/store';
import getRecaptchaSiteKey from '../selectors/recaptcha-site-key';

const getRecaptchaKey = () => {
  const state = store.getState();

  return getRecaptchaSiteKey(state);
};

export default getRecaptchaKey;
