import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';

const { Box } = designSystem;

function TabContentBox({ children }) {
  return (
    <Box
      paddingTop="spacing-s"
      paddingRight="spacing-m"
      paddingBottom="spacing-s"
      paddingLeft="spacing-m"
    >
      {children}
    </Box>
  );
}

TabContentBox.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TabContentBox;
