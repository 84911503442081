import React from 'react';
import PropTypes from 'prop-types';
import { dialogs, view, extensions, i18next, linkEditing } from '@yola/ws-sdk';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import mediaAttributes from 'src/js/modules/extensions/common/constants/media-attributes';
import linkAttributes from 'src/js/modules/extensions/common/constants/link-attributes';
import LinkSettingContainer from 'src/js/modules/common/containers/link-setting-container';
import buildLinkElement from 'src/js/modules/extensions/common/helpers/build-link-element';
import getLinkConfig from 'src/js/modules/extensions/common/helpers/get-link-config';
import extensionConfig from '../constants/config';

const { linkTypes } = linkEditing.constants;

class ClickActionDialog extends React.Component {
  constructor(props) {
    super(props);

    const { element, initialConfig } = props;
    const linkElement = element.closest('a');

    const settings = extensions.accessors.getExtensionSettings(extensionConfig.slug);
    this.galleryItem = element.closest(settings.galleryItemSelector);

    this.config = initialConfig;

    if (!this.config) {
      this.config = getLinkConfig(linkElement, this.galleryItem);
    }

    this.allowedLinkTypes = [
      linkTypes.NO_ACTION,
      linkTypes.PAGE,
      linkTypes.PHONE,
      linkTypes.MAIL,
      linkTypes.DOWNLOAD,
      linkTypes.ANCHOR,
      linkTypes.EXTERNAL,
    ];

    if (this.galleryItem) {
      this.allowedLinkTypes.splice(1, 0, linkTypes.VIEW_FULL_SIZE_IMAGE);
    }

    this.linkElement = linkElement;

    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
    this.getAddLinkOperation = this.getAddLinkOperation.bind(this);
    this.getRemoveLinkOperation = this.getRemoveLinkOperation.bind(this);
  }

  getAddLinkOperation(config) {
    const { element } = this.props;
    const linkElement = buildLinkElement(config);
    const linkContent = element.closest('picture') || element;

    linkElement.classList.add(linkAttributes.CLICK_ACTION_LINK_CLASS);
    linkElement.appendChild(linkContent.cloneNode(true));

    const target = this.linkElement || linkContent;
    const targetElementId = view.accessors.getLiveElementId(target);

    return [view.operations.replaceElement, [linkElement, targetElementId]];
  }

  getRemoveLinkOperation() {
    if (this.linkElement) {
      const linkElementId = view.accessors.getLiveElementId(this.linkElement);
      const linkContent = this.linkElement.firstElementChild;

      return [view.operations.replaceElement, [linkContent, linkElementId]];
    }

    return null;
  }

  handleCancel() {
    const { onDialogCancel } = this.props;

    onDialogCancel();
    dialogs.operations.hide();
  }

  handleSubmitForm({ config }) {
    const { onDialogMainAction } = this.props;
    const operations = [];

    onDialogMainAction();

    const galleryItemId = view.accessors.getLiveElementId(this.galleryItem);

    switch (config.linkType) {
      case linkTypes.VIEW_FULL_SIZE_IMAGE: {
        if (this.galleryItem.hasAttribute(mediaAttributes.LIGHTBOX_DISABLE_ATTRIBUTE)) {
          operations.push([
            view.operations.removeElementAttribute,
            [galleryItemId, mediaAttributes.LIGHTBOX_DISABLE_ATTRIBUTE],
          ]);
        }

        const removeElementAction = this.getRemoveLinkOperation();
        if (removeElementAction) {
          operations.push(this.getRemoveLinkOperation());
        }

        break;
      }

      case linkTypes.NO_ACTION: {
        if (
          this.galleryItem &&
          !this.galleryItem.hasAttribute(mediaAttributes.LIGHTBOX_DISABLE_ATTRIBUTE)
        ) {
          operations.push([
            view.operations.setElementAttribute,
            [galleryItemId, mediaAttributes.LIGHTBOX_DISABLE_ATTRIBUTE, ''],
          ]);
        }

        const removeElementAction = this.getRemoveLinkOperation();
        if (removeElementAction) {
          operations.push(this.getRemoveLinkOperation());
        }

        break;
      }

      default: {
        if (
          this.galleryItem &&
          !this.galleryItem.hasAttribute(mediaAttributes.LIGHTBOX_DISABLE_ATTRIBUTE)
        ) {
          operations.push([
            view.operations.setElementAttribute,
            [galleryItemId, mediaAttributes.LIGHTBOX_DISABLE_ATTRIBUTE, ''],
          ]);
        }

        operations.push(this.getAddLinkOperation(config));

        break;
      }
    }

    if (operations.length > 0) {
      view.operations.bulkViewOperations(operations);
    }

    dialogs.operations.hide();
  }

  render() {
    const { currentConfig } = this.props;
    return (
      <LinkSettingContainer
        initialConfig={this.config}
        currentConfig={currentConfig}
        sourceDialog={{
          id: dialogTypes.CLICK_ACTION_DIALOG,
          props: this.props,
        }}
        allowedLinkTypes={this.allowedLinkTypes}
        centered
        handleCancel={this.handleCancel}
        handleSubmit={this.handleSubmitForm}
        overlay="visible"
        showTitle={false}
        captions={{
          dialogTitle: i18next.t('Set a click action'),
          actionTitle: i18next.t('Click action'),
          actionSubtitle: i18next.t('When user clicks on the image, make this action'),
          save: i18next.t('Save'),
        }}
      />
    );
  }
}

ClickActionDialog.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.instanceOf(HTMLImageElement),
    PropTypes.instanceOf(HTMLElement),
  ]).isRequired,
  initialConfig: PropTypes.shape({}),
  currentConfig: PropTypes.shape({}),
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
};

ClickActionDialog.defaultProps = {
  initialConfig: null,
  currentConfig: null,
};

export default ClickActionDialog;
