import * as ReactSpring from 'react-spring';
import * as ReactSpringRenderProps from 'react-spring/renderprops';
import * as yolaPaletteGenerator from '@yola/yola-palette-generator';
import featureFlags from 'yola-editor/src/js/modules/feature-flags';
import featureFlagsContext, {
  FeatureFlagsConsumer,
  FeatureFlagsProvider,
} from 'yola-editor/src/js/modules/feature-flags/context';
import customTools from '../modules/custom-tools';
import contextMenu from '../modules/context-menu';
import customUI from '../modules/custom-ui';
import highlighter from '../modules/highlighter';
import scroller from '../modules/scroller';
import parseHslColorString from '../modules/website-design/helpers/parse-hsl-color-string';
import interpolateStringToTemplate from '../modules/utils/interpolate-string-to-template';
import getCircleBracketsContent from '../modules/utils/get-circle-brackets-content';
import * as convertCase from '../modules/utils/convert-case';
import blocks from '../modules/blocks';
import upsell from '../modules/upsell';
import websiteDesign from '../modules/website-design';
import dialogsTypes from '../modules/dialogs/constants/dialog-types';
import { startAutoSaving, stopAutoSaving } from '../modules/autosaving';

export const editor = {
  autoSaving: {
    enable: startAutoSaving,
    disable: stopAutoSaving,
  },
  tools: {
    registerTool: customTools.operations.registerTool,
  },
  contextMenu: {
    registerItemsGroup: contextMenu.operations.registerItemsGroup,
  },
  customUI: {
    register: customUI.operations.register,
    show: customUI.operations.show,
    hide: customUI.operations.hide,
  },
  highlighter: {
    show: highlighter.operations.show,
    hide: highlighter.operations.hide,
    getHighlightedElements: highlighter.accessors.getHighlightedElements,
  },
  scroller: {
    operations: {
      scrollToElementInContainer: scroller.helpers.scrollToElementInContainer,
      scrollToElement: scroller.helpers.scrollToElement,
      scrollToPosition: scroller.helpers.scrollToPosition,
    },
    accessors: {
      getScrollOffset: scroller.helpers.getScrollOffset,
    },
  },
  blocks: {
    helpers: {
      getBlocksReadableNames: blocks.helpers.getBlocksReadableNames,
    },
    constants: {
      ...blocks.constants.common,
    },
  },
  upsell: {
    features: upsell.features,
    operations: {
      requestFeatureAvailability: upsell.operations.requestFeatureAvailability,
    },
    verifiers: {
      isFeatureAvailable: upsell.verifiers.isFeatureAvailable,
    },
  },
  featureFlags: {
    context: {
      featureFlagsContext,
      FeatureFlagsConsumer,
      FeatureFlagsProvider,
    },
    hooks: featureFlags.hooks,
    operations: featureFlags.operations,
    hoc: featureFlags.hoc,
  },
  websiteDesign: {
    helpers: {
      getSurfaceLinkColor: websiteDesign.helpers.getSurfaceLinkColor,
      getColorNameByVariable: websiteDesign.helpers.getColorNameByVariable,
      getBlockSurfaceBackground: websiteDesign.helpers.getBlockSurfaceBackground,
      getBlockSurfaceName: websiteDesign.helpers.getBlockSurfaceName,
    },
  },
  dialogs: {
    constants: {
      dialogsTypes,
    },
  },
};

export const utils = {
  interpolateStringToTemplate,
  parseHslColorString,
  getCircleBracketsContent,
  convertCase,
};

export { default as PageManager } from '../modules/page-manager/components/page-manager';
export { default as LinkSelector } from '../modules/common/components/link-selector';
export { default as LinkSelectorField } from '../modules/common/components/link-selector-field';
export { default as FreeStockContext } from '../modules/common/components/free-stock-context';
export { default as ImageSizeErrorDialog } from '../modules/common/components/image-size-error-dialog';
export { default as FreeStockImages } from '../modules/common/components/free-stock-images';
export { default as ControlPane } from '../modules/control-pane/components/control-pane';
export { default as ColorSettingsDialog } from '../modules/website-design/components/color-settings-dialog';
export { default as TriggerEventTracker } from '../modules/utils/trigger-event-tracker';
export { default as Trigger } from '../modules/common/components/trigger';
export { default as LinkListSettingsDialogContainer } from '../modules/common/components/link-list-settings-dialog-container';
export { ViewportProvider, ViewportConsumer } from 'yola-editor/src/js/utils/viewport-provider';
export { default as ImageCropDialog } from '../modules/dialogs/components/image-crop-dialog';
export { ReactSpring, ReactSpringRenderProps };
export { yolaPaletteGenerator };
export { default as LinkSettingDialog } from '../modules/common/components/link-setting-dialog';
export { default as UploadFileDialog } from '../modules/extensions/common/components/upload-file-dialog';
