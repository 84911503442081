import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ButtonGroup, Button, designSystem } from '@yola/ws-ui';

const { Box, Stack, Heading, Paragraph } = designSystem;

const MODAL_WIDTH = 338;
const MODAL_HEIGHT = 202;

const WizardConfirmExitDialog = ({ captions, onSubmit, onCancel }) => (
  <Modal
    className="ws-wizard-confirm-exit-dialog"
    width={MODAL_WIDTH}
    height="auto"
    minHeight={MODAL_HEIGHT}
    overlay="visible"
    draggable={false}
    resizable={false}
    centered
  >
    <Box padding="spacing-l" paddingTop="spacing-xl">
      <Stack gap="spacing-4-xs">
        <Heading type="heading-5" align="center">
          {captions.title}
        </Heading>
        <Paragraph align="center">{captions.description}</Paragraph>
      </Stack>
    </Box>
    <ButtonGroup className="ws-submit-dialog__buttons">
      <Button stretch="block" size="large" onClick={onSubmit}>
        {captions.submitLabel}
      </Button>
      <Button stretch="block" size="large" onClick={onCancel}>
        {captions.cancelLabel}
      </Button>
    </ButtonGroup>
  </Modal>
);

WizardConfirmExitDialog.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    submitLabel: PropTypes.string.isRequired,
    cancelLabel: PropTypes.string.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default WizardConfirmExitDialog;
