export default {
  PIXEL_CROP_SETTINGS: {
    x: 0,
    y: 0,
  },
  MAX_FILE_UPLOAD_QUANTITY: 20,
  MODAL_WIDTH: 488,
  MODAL_HEIGHT: 467,
  CONFIRMATION_DIALOG_WIDTH: 430,
  CONFIRMATION_DIALOG_HEIGHT: 192,
  IMAGE_SETTINGS_MODAL_HEIGHT: 738,
  PANEL_HEIGHT: 49,
  LOADING_PROGRESS_TIMEOUT: 150,
  slug: 'ws-gallery',
};
