import actions from './actions';
import middleware from './middleware';
import selectors from './selectors';
import reducers from './reducers';
import actionTypes from './constants/action-types';

export default {
  actions,
  middleware,
  selectors,
  reducers,
  actionTypes,
};
