import { view, extensions } from '@yola/ws-sdk';
import constants from '../constants';

const getGalleryItemAreaBounds = (galleryId) => {
  const { galleryItemSelector } = extensions.accessors.getExtensionSettings(constants.common.slug);
  const galleryBlock = view.accessors.getLiveElement(galleryId);
  const galleryItem = galleryBlock.querySelector(galleryItemSelector);

  return galleryItem.getContainerBounds();
};

export default getGalleryItemAreaBounds;
