import getTypedElementSelector from './get-typed-element-selector';
import getElementTypeOptions from './get-element-type-options';
import getAdvancedSelection from './get-advanced-selection';
import findTypedParentElements from './find-typed-parent-elements';

function typedElementsMatches() {
  const typedElementSelector = getTypedElementSelector(getElementTypeOptions());
  const selection = getAdvancedSelection();
  return findTypedParentElements(typedElementSelector, selection);
}

export default typedElementsMatches;
