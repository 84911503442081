import React from 'react';

const RadiobuttonsIcon = () => (
  <svg width="144" height="90" viewBox="0 0 144 90" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.3 58C31.3 62.8049 35.1951 66.7 40 66.7C44.8049 66.7 48.7 62.8049 48.7 58C48.7 53.1951 44.8049 49.3 40 49.3C35.1951 49.3 31.3 53.1951 31.3 58ZM40 48C34.4772 48 30 52.4772 30 58C30 63.5228 34.4772 68 40 68C45.5228 68 50 63.5228 50 58C50 52.4772 45.5228 48 40 48Z"
      fill="#646C75"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.3 32C31.3 36.8049 35.1951 40.7 40 40.7C44.8049 40.7 48.7 36.8049 48.7 32C48.7 27.1951 44.8049 23.3 40 23.3C35.1951 23.3 31.3 27.1951 31.3 32ZM40 22C34.4772 22 30 26.4772 30 32C30 37.5228 34.4772 42 40 42C45.5228 42 50 37.5228 50 32C50 26.4772 45.5228 22 40 22Z"
      fill="#646C75"
    />
    <path
      d="M34 32C34 28.6863 36.6863 26 40 26C43.3137 26 46 28.6863 46 32C46 35.3137 43.3137 38 40 38C36.6863 38 34 35.3137 34 32Z"
      fill="#646C75"
    />
    <rect x="58" y="29" width="57" height="6" rx="2" fill="#DADDE0" />
    <rect x="58" y="55" width="48" height="6" rx="2" fill="#DADDE0" />
  </svg>
);

export default RadiobuttonsIcon;
