import get from 'lodash.get';
import { config } from '@yola/ws-sdk';
import getIsB2C from './is-b2c';

const getLogoutUrl = (rootState) => {
  const cfg = config.selectors.getConfig(rootState);

  if (getIsB2C(rootState)) {
    return get(cfg, 'common.auth-gateway.logout_url', '');
  }

  return get(cfg, 'common.auth-gateway.logout_wl_url', '');
};

export default getLogoutUrl;
