import { useEffect, useRef, useState } from 'react';

const useRefHeightOnScreen = (ref) => {
  const [refHeight, setRefHeight] = useState(0);
  const observerRef = useRef(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setRefHeight(ref.current.offsetHeight);
      }
    });

    observerRef.current.observe(ref.current);

    return () => {
      observerRef.current.disconnect();
    };
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  return refHeight;
};

export default useRefHeightOnScreen;
