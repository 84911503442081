import { i18next } from '@yola/ws-sdk';
import getFieldTypes from './get-field-types';
import constants from '../constants';

const { common } = constants;
const { LABEL_INPUT, PLACEHOLDER_INPUT, OPTIONS_INPUT, BUTTON_TEXT_INPUT } = common;

const getFieldTypePlaceholders = (fieldType) => {
  const { singleline, multiline, dropdown, date, time, checkbox, radio, attachfile } =
    getFieldTypes();
  const placeholdersMap = {
    [singleline]: {
      [LABEL_INPUT]: i18next.t('e.g. Name'),
      [PLACEHOLDER_INPUT]: i18next.t('e.g. Enter your name'),
    },
    [multiline]: {
      [LABEL_INPUT]: i18next.t('e.g. Message'),
      [PLACEHOLDER_INPUT]: i18next.t('e.g. Enter your message'),
    },
    [date]: {
      [LABEL_INPUT]: i18next.t('e.g. Date'),
      [PLACEHOLDER_INPUT]: i18next.t('e.g. Select your date'),
    },
    [time]: {
      [LABEL_INPUT]: i18next.t('e.g. Time'),
      [PLACEHOLDER_INPUT]: i18next.t('e.g. Select your time'),
    },
    [checkbox]: {
      [LABEL_INPUT]: i18next.t('e.g. Services'),
      [OPTIONS_INPUT]: i18next.t('e.g. Option A'),
    },
    [radio]: {
      [LABEL_INPUT]: i18next.t('e.g. Services'),
      [OPTIONS_INPUT]: [i18next.t('e.g. Option A'), i18next.t('e.g. Option B')],
    },
    [dropdown]: {
      [LABEL_INPUT]: i18next.t('e.g. Gender'),
      [OPTIONS_INPUT]: [i18next.t('e.g. Male'), i18next.t('e.g. Female')],
    },
    [attachfile]: {
      [LABEL_INPUT]: i18next.t('e.g. Upload your CV'),
      [BUTTON_TEXT_INPUT]: i18next.t('e.g. Choose file'),
    },
  };

  return placeholdersMap[fieldType] || placeholdersMap[singleline];
};

export default getFieldTypePlaceholders;
