import segment from 'src/js/modules/analytics/segment';
import getDefaultTraits from 'src/js/modules/analytics/segment/helpers/get-default-traits';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';

const trackMediaSettingsDialogSubmitted = (elementId, changedData) => {
  const { track, constants } = segment;
  const {
    isClickActionChanged,
    isSrcChanged,
    isTitleChanged,
    isAltChanged,
    isDescriptionChanged,
    isScaleChanged,
    isPositionChanged,
    customPath,
    setDownloadUrlAdjusted,
  } = changedData;

  track(constants.events.MEDIA_SETTINGS_DIALOG_SUBMITTED, {
    ...getDefaultTraits(elementId),
    dialogId: dialogTypes.MEDIA_SETTINGS_DIALOG,
    newImageSelected: isSrcChanged,
    titleAdjusted: isTitleChanged,
    altTitleAdjusted: isAltChanged,
    descriptionAdjusted: isDescriptionChanged,
    clickActionAdjusted: isClickActionChanged,
    zoomAdjusted: isScaleChanged,
    positionAdjusted: isPositionChanged,
    downloadUrl: customPath,
    setDownloadUrlAdjusted,
  });
};

export default trackMediaSettingsDialogSubmitted;
