const PERCENTAGE_BASE = 100;

const getSubprogressMigrationPercent = (data) => {
  if (data.runningMigration && data.runningMigration.changesCount) {
    return Math.round(
      (data.runningMigration.completedChangesCount * PERCENTAGE_BASE) /
        data.runningMigration.changesCount
    );
  }

  return 0;
};

export default getSubprogressMigrationPercent;
