import React from 'react';
import useFeatureFlags from '../hooks/use-feature-flags';

const withFeatureFlags = (flags) => (Component) => (props) => {
  const [featureFlags, requestFeatureFlags] = useFeatureFlags(flags);

  return (
    <Component {...props} featureFlags={featureFlags} requestFeatureFlags={requestFeatureFlags} />
  );
};

export default withFeatureFlags;
