const pattern = /^(https?|\/\/)/;

const makeUrlAbsolute = (url, base) => {
  if (base && pattern.exec(url) === null) {
    return base + url.replace(/^\//, '');
  }

  return url;
};

export default makeUrlAbsolute;
