import { hdrm, anodum } from '@yola/ws-sdk';
import removeEditableFields from './remove-editable-fields';
import getFieldSelectors from './get-field-selectors';
import getFieldTemplates from './get-field-templates';
import getFieldTypes from './get-field-types';
import getEscapedString from './get-escaped-string';
import constants from '../constants';

const { attributes, timeFormats, numberOfFilesAttachment } = constants;

const getBuiltElement = (targetElement, elementData) => {
  const { attachfile } = getFieldTypes();

  const element = targetElement.cloneNode(true);
  const {
    id,
    placeholder,
    label: labelText,
    required,
    name,
    value,
    type,
    buttonText,
    fileType,
    numberOfFiles,
  } = elementData;
  const { itemLabel, itemTextarea, itemInput, itemFile, itemFileLabelText } = getFieldSelectors();

  const label = element.querySelector(itemLabel);
  label.textContent = labelText;
  label.setAttribute('for', id);

  const textField = element.querySelector(itemInput) || element.querySelector(itemTextarea);
  textField.setAttribute('name', name);
  textField.setAttribute('id', id);

  if (placeholder) {
    textField.setAttribute('placeholder', placeholder);
  }

  if (required) {
    textField.setAttribute('required', '');
  }

  if (value) {
    textField.setAttribute('value', value);
  }

  if (type === attachfile) {
    const btnText = element.querySelector(itemFileLabelText);
    btnText.textContent = buttonText;

    if (numberOfFiles === numberOfFilesAttachment.MULTIPLE) textField.setAttribute('multiple', '');

    if (fileType) {
      const filePicker = element.querySelector(itemFile);
      filePicker.setAttribute('file-type', fileType);
    }
  }

  return element;
};

const buildNewMarkup = (staticContainer, items) => {
  removeEditableFields(staticContainer);

  const templates = getFieldTemplates();
  const { checkbox, radio, dropdown, time } = getFieldTypes();

  const elements = [...items]
    .sort((prev, next) => Number(prev.order) - Number(next.order))
    .map((itemData) => {
      const { type } = itemData;
      const template = templates[type];

      const element = anodum.parseElementFromString(template);
      element.setAttribute('type', type);

      switch (type) {
        case checkbox: {
          const { label, options, id } = itemData;
          const { itemTitle, itemGrouped } = getFieldSelectors();

          const groupTitle = element.querySelector(itemTitle);
          groupTitle.textContent = label;
          element.setAttribute('id', id);

          const defaultGroupItem = element.querySelector(itemGrouped);

          const groupItems = options.map((option) => {
            const suffix = hdrm.helpers.generateIdSuffix();
            const escapedOption = getEscapedString(option);
            const optionId = `${type}-${escapedOption}${suffix}`;
            const optionName = `${escapedOption}${suffix}`;
            const groupItemData = { ...itemData, label: option, id: optionId, name: optionName };

            return getBuiltElement(defaultGroupItem, groupItemData);
          });

          element.removeChild(defaultGroupItem);
          element.append(...groupItems);

          return element;
        }
        case radio: {
          const { label, options, id } = itemData;
          const { itemTitle, itemGrouped } = getFieldSelectors();

          const groupTitle = element.querySelector(itemTitle);
          groupTitle.textContent = label;
          element.setAttribute('id', id);

          const defaultGroupItem = element.querySelector(itemGrouped);
          const nameSuffix = hdrm.helpers.generateIdSuffix();

          const groupItems = options.map((option) => {
            const suffix = hdrm.helpers.generateIdSuffix();
            const escapedOption = getEscapedString(option);
            const optionId = `${type}-${escapedOption}${suffix}`;
            const optionName = `${label}${nameSuffix}`;
            const groupItemData = {
              ...itemData,
              label: option,
              id: optionId,
              name: optionName,
              value: option,
            };

            return getBuiltElement(defaultGroupItem, groupItemData);
          });

          element.removeChild(defaultGroupItem);
          element.append(...groupItems);

          return element;
        }
        case dropdown: {
          const selectors = getFieldSelectors();
          const labelElement = element.querySelector(selectors.itemLabel);
          const selectElement = element.querySelector(selectors.itemDropdown);

          labelElement.textContent = itemData.label;
          labelElement.setAttribute('for', itemData.id);

          selectElement.id = itemData.id;
          selectElement.name = itemData.name;
          selectElement.required = itemData.required;

          itemData.options.forEach((optionText) => {
            const optionElement = document.createElement('option');
            optionElement.value = getEscapedString(optionText);
            optionElement.textContent = optionText;
            selectElement.options.add(optionElement);
          });

          return element;
        }
        case time: {
          const { itemTime } = getFieldSelectors();
          const { timeFormat } = itemData;
          const timeElement = element.querySelector(itemTime);

          if (timeFormat === timeFormats['24_HOUR']) {
            timeElement.setAttribute(attributes.TIME_24HR, '');
          }

          return getBuiltElement(element, itemData);
        }
        default:
          return getBuiltElement(element, itemData);
      }
    });

  staticContainer.prepend(...elements);
};

export default buildNewMarkup;
