import { clipboard, extensions } from '@yola/ws-sdk';
import contextMenu from 'src/js/modules/context-menu';
import config from '../constants/config';
import matchesOrContains from '../helpers/matches-or-contains';
import changeImageTitle from './context-menu-items/change-image-title';
import setClickAction from './context-menu-items/set-click-action';
import copyImage from './context-menu-items/copy-image';
import pasteImage from './context-menu-items/paste-image';

const isMatched = (element, selector, restrictedSelector) => {
  const imageElement = matchesOrContains(element, selector);
  return (
    imageElement &&
    ((restrictedSelector && !imageElement.closest(restrictedSelector)) || !restrictedSelector)
  );
};

const registerImagesActions = () => {
  const displayOptions = {
    shouldDisplayInMoreMenu: false,
  };

  contextMenu.operations.registerItemsGroup({
    groupName: 'image',
    groupOrder: 1,
    items: [changeImageTitle({ displayOptions })],
    matches(element) {
      const settings = extensions.accessors.getExtensionSettings(config.slug);
      const { imageAltSelector, imageAltTargetSelector, imageAltRestrictedParentsSelector } =
        settings;

      if (!element.matches(imageAltTargetSelector)) {
        return false;
      }

      return isMatched(element, imageAltSelector, imageAltRestrictedParentsSelector);
    },
  });

  contextMenu.operations.registerItemsGroup({
    groupName: 'image',
    groupOrder: 1,
    items: [setClickAction({ displayOptions })],
    disabled: () => !clipboard.verifiers.hasClipboardElementData(),
    matches(element) {
      const settings = extensions.accessors.getExtensionSettings(config.slug);
      const {
        imageActionSelector,
        imageActionTargetSelector,
        imageActionRestrictedParentsSelector,
      } = settings;

      if (!element.matches(imageActionTargetSelector)) {
        return false;
      }

      return isMatched(element, imageActionSelector, imageActionRestrictedParentsSelector);
    },
  });
};

const registerImageCopyActions = () => {
  const displayOptions = {
    shouldDisplayInMoreMenu: false,
  };

  contextMenu.operations.registerItemsGroup({
    groupName: 'image-copy',
    groupOrder: 0,
    items: [copyImage({ displayOptions }), pasteImage({ displayOptions })],
    matches(element, featureFlags = {}) {
      const settings = extensions.accessors.getExtensionSettings(config.slug);
      const isFeatureAvailable = Boolean(featureFlags.image_copy_paste);
      if (!isFeatureAvailable) return false;

      const {
        imageActionRestrictedParentsSelector,
        imageActionSelector,
        blockSelector,
        blockWithBackgroundQuerySelector,
      } = settings;

      if (element.matches(blockWithBackgroundQuerySelector)) return true;

      return (
        !element.matches(blockSelector) &&
        isMatched(element, imageActionSelector, imageActionRestrictedParentsSelector)
      );
    },
  });
};

export default function registerContextMenuActions() {
  registerImagesActions();
  registerImageCopyActions();
}
