import React, { useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { i18next, site, dialogs, integration, template } from '@yola/ws-sdk';
import { Text, Radio, SurfaceSelector, designSystem } from '@yola/ws-ui';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import segment from 'src/js/modules/analytics/segment';
import websiteDesignTabIdentifiers from 'src/js/modules/website-design/constant/tab-identifiers';
import dialogCallSourceIds from 'src/js/modules/website-design/constant/dialog-call-source-ids';
import displayOptionTypes from '../constants/display-options-type';
import AsyncDropdownItem from './async-dropdown-item';
import BlockSettingsContext from '../contexts/block-settings-context';

const POPOVER_WIDTH = 232;

const getCaptions = () => ({
  addSurfaceButtonCaptions: {
    dataTip: i18next.t('Customize colors'),
  },
  hideElementWarning: i18next.t('Element is hidden. You can always bring it back using checkbox'),
});

const { TabNonSwipeableArea, Slider, Checkbox, Popover, PopoverContent, PopoverTrigger } =
  designSystem;

function BlockSettingsOption(props) {
  const { item, keySalt } = props;
  const { id, type } = item;

  const hiddenChildRef = useRef(null);

  const blockSettingsContext = useContext(BlockSettingsContext);
  const { onSubmit } = blockSettingsContext;

  const { track, constants } = segment;
  const { events } = constants;

  const isSupportedType = type && Object.values(displayOptionTypes).includes(type);
  const { isCleanUrlsSupported } = useSelector(integration.selectors.getSettings);
  const isOnlineStoreCleanUrlSelectorEnabled =
    useSelector(site.selectors.getCreatedWithCleanUrlsDisabled) && Boolean(isCleanUrlsSupported);

  const checkboxOptionClasses = classNames('ws-block-settings-dialog__checkbox-option', {
    'ws-block-settings-dialog__checkbox-option--highlighted': item.highlighted,
  });

  const captions = getCaptions();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (item.highlighted) {
      hiddenChildRef.current.scrollIntoView();

      const clickHandler = () => item.onClick(item.isEnabled, item, { highlighted: false });

      document.addEventListener('click', clickHandler, { once: true });

      return () => document.removeEventListener('click', clickHandler);
    }
  }, [item]);

  if (!isSupportedType) return null;

  if (
    type === displayOptionTypes.ONLINE_STORE_CLEAN_URL_SELECTOR &&
    !isOnlineStoreCleanUrlSelectorEnabled
  ) {
    return null;
  }

  const onAddSurface = () => {
    track(events.CUSTOMIZE_WEBSITE_PALETTES_TRIGGER_CLICKED, {
      siteId: site.accessors.getSiteId(),
      templateBuildSlug: template.accessors.getBuildSlug(),
    });

    onSubmit();

    dialogs.operations.show(dialogTypes.WEBSITE_DESIGN, {
      onDialogCancel: () => {
        dialogs.operations.hide();
      },
      onDialogMainAction: () => {
        dialogs.operations.hide();
      },
      sourceId: dialogCallSourceIds.CUSTOMIZE_COLORS,
      activeTabId: websiteDesignTabIdentifiers.COLORS,
    });
  };

  return (
    <React.Fragment>
      {Boolean(item.title) && (
        <Text className="ws-block-settings-dialog__option-title" type="heading-6">
          {item.title}
          {Boolean(item.displayCurrentValue) && (
            <span className="ws-block-settings-dialog__value-in-title">{item.value}</span>
          )}
        </Text>
      )}
      {Boolean(item.subtitle) && (
        <Text className="ws-block-settings-dialog__option-subtitle" type="annotation" theme="grey">
          {item.subtitle}
        </Text>
      )}

      {type === displayOptionTypes.CHECKBOX && !item.disableChange && (
        <div className={checkboxOptionClasses} {...(item.highlighted && { ref: hiddenChildRef })}>
          <Popover
            disabled={!item.highlighted}
            width={POPOVER_WIDTH}
            placement="top-center"
            appearance="dark"
            mode="popover"
            showOnMount
          >
            <PopoverTrigger>
              <Checkbox
                key={id}
                id={id}
                value={id}
                label={item.label}
                checked={item.isEnabled}
                onChange={() => item.onClick(!item.isEnabled, item)}
              />
            </PopoverTrigger>
            <PopoverContent>{captions.hideElementWarning}</PopoverContent>
          </Popover>
        </div>
      )}

      {type === displayOptionTypes.RADIO &&
        item.options.map((option) => (
          <Radio
            key={`${id}-${option.value}-${keySalt}`}
            id={`${id}-${option.value}`}
            name={id}
            checked={item.value === option.value}
            onChange={(e) => item.onClick(e, item, option)}
            value={option.value}
          >
            <Text type="heading-6">
              {/* eslint-disable-next-line yola/jsx-a11y/label-has-for */}
              <label htmlFor={`${item.id}-${option.value}`}>{option.label}</label>
            </Text>
          </Radio>
        ))}

      {type === displayOptionTypes.SURFACE && (
        <SurfaceSelector
          item={item}
          captions={captions}
          onAddSurface={onAddSurface}
          showAddSurfaceButton
        />
      )}

      {type === displayOptionTypes.SLIDER && (
        <TabNonSwipeableArea>
          <Slider
            min={Number(item.min)}
            max={Number(item.max)}
            step={Number(item.step)}
            value={Number(item.value)}
            valueLabelDisplay={item.displayCurrentValue ? 'off' : 'on'}
            onChange={(value) => item.onChange(item, value)}
            onAfterChange={(value) => item.onAfterChange(item, value)}
          />
        </TabNonSwipeableArea>
      )}

      {type === displayOptionTypes.BREAKPOINTS_SLIDER && (
        <TabNonSwipeableArea>
          <Slider
            min={Number(item.values[0])}
            max={Number(item.values[item.values.length - 1])}
            step={Number(item.values[1]) - Number(item.values[0])}
            value={Number(item.value)}
            valueLabelDisplay="on"
            onChange={(value) => item.onChange(item, String(Math.round(value * 10) / 10))}
            onAfterChange={(value) => item.onAfterChange(item, String(Math.round(value * 10) / 10))}
          />
        </TabNonSwipeableArea>
      )}

      {type === displayOptionTypes.ONLINE_STORE_CATEGORY && (
        <AsyncDropdownItem
          options={item.options}
          value={item.value}
          placeholder=""
          onChange={(option) => item.onChange(item, option.value)}
        />
      )}

      {type === displayOptionTypes.ONLINE_STORE_CLEAN_URL_SELECTOR && (
        <Checkbox
          key={id}
          id={id}
          checked={item.isEnabled}
          onChange={(event) => item.onChange(event, item)}
        >
          <Text type="heading-6">
            {/* eslint-disable-next-line yola/jsx-a11y/label-has-for */}
            <label htmlFor={item.id}>{item.label}</label>
          </Text>
        </Checkbox>
      )}

      {Boolean(item.annotation) && (
        <Text
          className="ws-block-settings-dialog__option-annotation"
          type="annotation"
          theme="grey"
        >
          {item.annotation}
        </Text>
      )}
    </React.Fragment>
  );
}

BlockSettingsOption.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    annotation: PropTypes.string,
    label: PropTypes.string,
    displayCurrentValue: PropTypes.bool,
    type: PropTypes.string.isRequired,
    isEnabled: PropTypes.bool,
    disableChange: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
    values: PropTypes.arrayOf(PropTypes.string), // available values for breakpoints slider from API
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    onAfterChange: PropTypes.func,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    src: PropTypes.string,
    blockNode: PropTypes.object,
    highlighted: PropTypes.bool,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        onClick: PropTypes.func,
      })
    ),
  }),
  keySalt: PropTypes.string,
};

BlockSettingsOption.defaultProps = {
  item: null,
  keySalt: null,
};

export default BlockSettingsOption;
