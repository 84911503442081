import React from 'react';
import PropTypes from 'prop-types';

const PreviewModeBody = ({ children }) => <div className="ws-preview-mode-body">{children}</div>;

const PreviewModeLayout = ({ children }) => <div className="ws-preview-mode">{children}</div>;

PreviewModeBody.propTypes = {
  children: PropTypes.element.isRequired,
};

PreviewModeLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
    .isRequired,
};

PreviewModeLayout.Body = PreviewModeBody;

export default PreviewModeLayout;
