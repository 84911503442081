import { view } from '@yola/ws-sdk';

export default {
  INSERT_BLOCK: view.actionTypes.INSERT_BLOCK,
  REMOVE_ELEMENT_ATTRIBUTE: view.actionTypes.REMOVE_ELEMENT_ATTRIBUTE,
  SET_ELEMENT_ATTRIBUTE: view.actionTypes.SET_ELEMENT_ATTRIBUTE,
  SHIFT_ELEMENT: view.actionTypes.SHIFT_ELEMENT,
  PASTE_ELEMENT: view.actionTypes.PASTE_ELEMENT,
  BULK_VIEW_ACTIONS: view.actionTypes.BULK_VIEW_ACTIONS,
  DELETE_ELEMENT: view.actionTypes.DELETE_ELEMENT,
  DUPLICATE_ELEMENT: view.actionTypes.DUPLICATE_ELEMENT,
  REPLACE_ELEMENT: view.actionTypes.REPLACE_ELEMENT,
  SYNCHRONIZE_ELEMENT: view.actionTypes.SYNCHRONIZE_ELEMENT,
};
