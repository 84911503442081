import { view } from '@yola/ws-sdk';
import store from 'src/js/store';
import actions from '../actions';

const show = (customComponentId, props) => {
  const state = store.getState();
  const isViewLoaded = view.selectors.getLoadedStatus(state);
  // When editor reloads\changes page, we have some time(amount depends on your internet connection)
  // to iterate with elements on the page, which will be changed soon. And in that cases, if we
  // call any function from custom-ui, we will face the trouble that our element's document,
  // in some moment of time, will lose window context and editor will throw errors.
  // To prevent this behavior we added checking isViewLoaded status.
  if (!isViewLoaded) return;
  store.dispatch(actions.show(customComponentId, props));
};

export default show;
