import { textFormatting, anodum } from '@yola/ws-sdk';
import constants from '../constants';

const { isChildOfElement, isTag } = anodum;
const { LIST_ITEM_TAG } = constants.listFormatting;
const { wrappersTypes } = textFormatting.constants;

const getTopElementsFromSelection = (elements, lists) =>
  elements.filter((el) => {
    if (isTag(el, LIST_ITEM_TAG)) return true;
    if (lists.length && !lists.some((list) => isChildOfElement(el, list))) return true;
    if (isTag(el, wrappersTypes.customColor.tagName)) return false;
    const li = el.closest(LIST_ITEM_TAG);
    return Boolean(!li) || Boolean(!li.closest('[contenteditable]'));
  });

export default getTopElementsFromSelection;
