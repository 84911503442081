import uniqby from 'lodash.uniqby';
import highlighter from 'src/js/modules/highlighter';
import {
  BLOCK_ID_ATTRIBUTE_NAME,
  REGULAR_IMAGE_ATTRIBUTES_FOR_SHALLOW_PROXY,
  LIVE_PREVIEW_MOCKED_ID,
  SKIP_ANIMATION_ATTRIBUTE,
  VARIATION_ATTRIBUTE_NAME,
} from 'src/js/modules/blocks/constants/common';
import { blocks, hdrm, textFormatting, view } from '@yola/ws-sdk';
import injectGlobals from 'src/js/modules/blocks/helpers/inject-globals';
import isOriginLayout from 'src/js/modules/blocks/helpers/is-origin-layout';
import {
  OVERLAY_ATTRIBUTE,
  OVERLAY_OPACITY_ATTRIBUTE,
  OVERLAY_SECTION_ATTRIBUTE,
  SURFACES_ATTRIBUTE,
  STICK_UP_ATTRIBUTE,
} from 'src/js/modules/blocks/constants/display-options';
import replaceBlockIdSelectorForRules from 'src/js/modules/blocks/helpers/replace-block-id-selector-for-rules';
import generateBlockIdCssRule from 'src/js/modules/website-design/helpers/generate-block-id-css-rule';
import getBlockIdRulesMap from 'src/js/modules/blocks/helpers/get-block-id-rules-map';
import layoutSwitchingHelpers from 'src/js/modules/blocks/helpers/layout-switching';
import blockSettingsHelpers from 'src/js/modules/blocks/helpers/block-settings';
import restoreOriginSurface from './restore-origin-surface';
import dropTextResizing from './layout-switching/drop-text-resizing';
import copyDisplayOptionByAttribute from './copy-display-option-by-attribute';
import optionTypes from '../constants/background-option-types';
import setMatchingSurface from './layout-switching/set-matching-surface';

const {
  compileBlockNode,
  replaceOptionalChildren,
  handleBackgroundImage,
  getChildrenToUpdate,
  getPresenceToUpdate,
  handleBackgroundImageAsFallback,
  handleBlockMediaImageAsFallback,
} = layoutSwitchingHelpers;

const { compileLivePreviewNode } = blockSettingsHelpers;
const { webkitWorkarounds } = hdrm.helpers;
const { BACKGROUND_IMAGE_ATTRIBUTE } = optionTypes;

const switchBlockLayout = (
  newBlock,
  {
    blockNode,
    prevVariationId,
    optionalChildren,
    sortBlockVariationId,
    selectedLayoutVariationId,
    displayOptions,
    backgroundOptions,
    cachedBackgroundOptions,
    selectedPreviewNode,
    originalBlockNode,
    isDirtyChildren,
    refId,
    blockElementId,
    usedCustomColorOptions,
    isDirtyOptions,
    originalStaticBlockNode,
    blockOriginSurface,
    stylesheet = textFormatting.helpers.getCustomTextColorsStylesheet(),
    compiledNodesCache,
    currentBlockNode,
    originalVariationId,
    optionalChildrenState,
  }
) => {
  const { html, id, variationId } = newBlock;
  highlighter.operations.hide([blockNode]);

  let newDestinationBlockNode;
  let virtualVariationId;
  let virtualBlockId;

  const isLivePreview = variationId === LIVE_PREVIEW_MOCKED_ID;
  const isOriginalVariationId = variationId === originalVariationId;

  if (isLivePreview) {
    newDestinationBlockNode = compileLivePreviewNode({
      optionalChildren,
      sortBlockVariationId,
      selectedLayoutVariationId,
      displayOptions,
      selectedPreviewNode,
    });
    const virtualBlock = blocks.accessors.getBlockByElement(newDestinationBlockNode);
    virtualBlockId = virtualBlock.id;
    virtualVariationId = newDestinationBlockNode.getAttribute(VARIATION_ATTRIBUTE_NAME);
    newDestinationBlockNode.setAttribute(BLOCK_ID_ATTRIBUTE_NAME, refId);
  } else {
    const originRefId = view.accessors.getLiveElementId(originalBlockNode);
    virtualBlockId = id;
    virtualVariationId = variationId;
    const newHTML = injectGlobals(html);
    newDestinationBlockNode = compileBlockNode(
      newHTML,
      virtualBlockId,
      virtualVariationId,
      originRefId
    );
  }

  let newOptionalChildrenState = uniqby([...optionalChildren, ...optionalChildrenState], 'id');

  const toUpdate = getChildrenToUpdate(originalBlockNode, newDestinationBlockNode);

  if (isLivePreview) {
    newOptionalChildrenState = newOptionalChildrenState.map((child) => {
      const updatedChild = toUpdate.find((item) => item.id === child.id);
      if (!updatedChild) return child;
      return {
        ...child,
        isEnabled: updatedChild.isEnabled,
      };
    });
  }

  const presenceToUpdate = getPresenceToUpdate(
    originalBlockNode,
    newDestinationBlockNode,
    newOptionalChildrenState
  );

  const attributesForShallowProxy =
    !isLivePreview && !isOriginalVariationId ? REGULAR_IMAGE_ATTRIBUTES_FOR_SHALLOW_PROXY : [];

  replaceOptionalChildren(
    toUpdate,
    originalStaticBlockNode,
    newDestinationBlockNode,
    attributesForShallowProxy
  );

  const touched = optionalChildren && optionalChildren.some((children) => children.touched);

  if (touched || isDirtyChildren) {
    presenceToUpdate.forEach((item) => {
      hdrm.helpers.updateChildrenPresence(
        newDestinationBlockNode,
        item.destinationQuerySelector,
        item.isEnabled,
        item.id
      );
    });
  }

  [
    OVERLAY_OPACITY_ATTRIBUTE,
    OVERLAY_ATTRIBUTE,
    OVERLAY_SECTION_ATTRIBUTE,
    STICK_UP_ATTRIBUTE,
  ].forEach((attribute) =>
    copyDisplayOptionByAttribute({
      displayOptions,
      destinationBlockNode: newDestinationBlockNode,
      attribute,
    })
  );

  if (selectedPreviewNode.hasAttribute(BACKGROUND_IMAGE_ATTRIBUTE)) {
    setMatchingSurface({ displayOptions, destinationBlockNode: newDestinationBlockNode });
  }

  const backgroundHandledSuccessfully = handleBackgroundImage(
    newDestinationBlockNode,
    backgroundOptions,
    variationId,
    cachedBackgroundOptions
  );

  const originalOptionalChildren = blocks.accessors.getOptionalChildrenData(originalBlockNode);
  const destinationOptionalChildren =
    blocks.accessors.getOptionalChildrenData(newDestinationBlockNode);

  if (!backgroundHandledSuccessfully) {
    handleBackgroundImageAsFallback(
      originalStaticBlockNode,
      newDestinationBlockNode,
      originalOptionalChildren,
      destinationOptionalChildren,
      backgroundOptions
    );
  }

  handleBlockMediaImageAsFallback(
    originalStaticBlockNode,
    newDestinationBlockNode,
    originalOptionalChildren,
    destinationOptionalChildren,
    backgroundOptions || cachedBackgroundOptions
  );

  if (usedCustomColorOptions) {
    const oldBlockId = currentBlockNode ? currentBlockNode.id : blockElementId;
    const isOrigin = isOriginLayout(blockElementId, newDestinationBlockNode.id);

    if (
      isOrigin &&
      blockOriginSurface === newDestinationBlockNode.getAttribute(SURFACES_ATTRIBUTE)
    ) {
      restoreOriginSurface(
        stylesheet,
        currentBlockNode ? currentBlockNode.id : blockElementId,
        newDestinationBlockNode.id
      );
    } else {
      replaceBlockIdSelectorForRules(stylesheet, oldBlockId, newDestinationBlockNode.id);
      generateBlockIdCssRule({
        stylesheet,
        blockId: newDestinationBlockNode.id,
        surfaces: newDestinationBlockNode.getAttribute(SURFACES_ATTRIBUTE),
        blockIdRulesMap: getBlockIdRulesMap(stylesheet, newDestinationBlockNode.id),
      });
    }
  }

  if (variationId !== LIVE_PREVIEW_MOCKED_ID && variationId !== originalVariationId) {
    dropTextResizing(newDestinationBlockNode);
  }

  webkitWorkarounds.removeLazyLoadingAttributeFromImages(newDestinationBlockNode);

  /**
   * APPEND TO DOCUMENT
   */
  newDestinationBlockNode.setAttribute(SKIP_ANIMATION_ATTRIBUTE, '');
  blockNode.replaceWith(newDestinationBlockNode);
  newDestinationBlockNode.removeAttribute(SKIP_ANIMATION_ATTRIBUTE);

  highlighter.operations.show([newDestinationBlockNode]);

  /**
   * UPDATE STATE
   */
  if (isDirtyOptions) {
    compiledNodesCache.delete(prevVariationId);
  }

  return {
    newBlockNode: newDestinationBlockNode,
    newBlockId: virtualBlockId,
    newVariationId: virtualVariationId,
    newSelectedLayoutVariationId: variationId,
    newOptionalChildrenState,
  };
};

export default switchBlockLayout;
