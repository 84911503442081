import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { designSystem } from '@yola/ws-ui';
import { i18next } from '@yola/ws-sdk';
import segment from 'src/js/modules/analytics/segment';
import useFeatureFlags from 'yola-editor/src/js/modules/feature-flags/hooks/use-feature-flags';
import user from 'yola-editor/src/js/modules/user';
import BuyDomainContainer from './buy-domain-container';
import UpgradeContainer from './upgrade-container';
import LocalesSelectorContainer from './locales-selector-container';
import BrandContainer from '../../brand/containers/brand-container';
import MainMenuContainer from '../../main-menu/containers/main-menu-container';
import PageButtonContainer from '../../page-manager/containers/page-button-container';
import HelpMenuContainer from '../../help-menu/containers/help-menu-container';
import UndoContainer from '../../undo-redo/containers/undo-container';
import RedoContainer from '../../undo-redo/containers/redo-container';
import SavingContainer from '../../saving/containers/saving-container';
import PublishContainer from '../../publishing/container/publish-container';
import NavigationContainer from '../../navigation/containers/navigation-container';
import WebsiteDesignTrigger from '../components/website-design-trigger';
import OnlineStoreTrigger from '../components/online-store-trigger';
import OrderWebsiteContainer from './order-website-container';
import isOnlineStoreEnabled from '../verifiers/is-online-store-enabled';
import previewMode from '../../preview-mode';
import setHeight from '../actions/set-height';
import useMatchBreakpoint from '../../utils/custom-hooks/use-match-breakpoint';
import { saveSiteWithEffect } from '../../saving/helpers/save-site';
import constants from '../constants';

const { EditorHeader } = designSystem;

const {
  trackers: { trackEvent },
  constants: { events, triggerIds },
} = segment;

const NavbarContainer = () => {
  const dispatch = useDispatch();
  const isDesktopBreakpointMatched = useMatchBreakpoint(constants.DESKTOP_BREAKPOINT);
  const [featureFlags] = useFeatureFlags(['difm_latitude', 'ai_site_wizard']);
  const { difm_latitude: isDifmLatitudeEnabled } = featureFlags;
  const isWhiteLabel = useSelector(user.selectors.getWhiteLabelStatus);
  const externalUpsellTypes = useSelector(user.selectors.getExternalUpsellTypes);
  const isDifmAvailable =
    isDifmLatitudeEnabled &&
    (!isWhiteLabel ||
      externalUpsellTypes.includes(user.constants.externalUpsellTypes.DIFM_SERVICE));
  const isOrderWebsiteShowed = isDesktopBreakpointMatched && isDifmAvailable;

  useEffect(() => {
    dispatch(setHeight(EditorHeader.getHeight()));
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  const onPreviewBtnClick = () => {
    saveSiteWithEffect(async () => {
      dispatch(previewMode.thunks.generateSitePreview());
    });

    trackEvent(events.PREVIEW_TRIGGER_CLICKED, {
      triggerId: triggerIds.EDITOR_HEADER,
    });
  };

  return (
    <EditorHeader>
      <EditorHeader.HeaderLeft>
        <EditorHeader.MenuWrapper>
          <MainMenuContainer />
          <BrandContainer />
        </EditorHeader.MenuWrapper>
        <LocalesSelectorContainer />
        <EditorHeader.Nav>
          <PageButtonContainer />
          <NavigationContainer />
          <WebsiteDesignTrigger />
          {isOnlineStoreEnabled() && <OnlineStoreTrigger />}
          <HelpMenuContainer />
          {isOrderWebsiteShowed && <OrderWebsiteContainer />}
        </EditorHeader.Nav>
      </EditorHeader.HeaderLeft>
      <EditorHeader.HeaderRight>
        <EditorHeader.Controls>
          <UndoContainer />
          <RedoContainer />
          <SavingContainer />
        </EditorHeader.Controls>
        <EditorHeader.Actions>
          <EditorHeader.PreviewButton onClick={onPreviewBtnClick} label={i18next.t('Preview')} />
          <BuyDomainContainer />
          <UpgradeContainer />
          <PublishContainer />
        </EditorHeader.Actions>
      </EditorHeader.HeaderRight>
    </EditorHeader>
  );
};

export default NavbarContainer;
