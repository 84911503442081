function getClosestValueTuple(tuples, value) {
  const closestValueTuple = tuples.reduce((prev, curr) => {
    const currNumericValueDiff = Math.abs(curr[1] - value);
    const prevNumericValueDiff = Math.abs(prev[1] - value);
    return currNumericValueDiff < prevNumericValueDiff ? curr : prev;
  });

  return closestValueTuple;
}

export default getClosestValueTuple;
