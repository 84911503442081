import { i18next } from '@yola/ws-sdk';
import ai from 'src/js/modules/ai';

const {
  constants: { textAssistantInstructionSuggestions },
} = ai;

const formatLabel = (label) => `${label} ...`;

const getOptions = () => {
  const SUGGESTIONS = [
    {
      value: textAssistantInstructionSuggestions.WRITE_ABOUT,
      label: i18next.t('Write about'),
    },
    {
      value: textAssistantInstructionSuggestions.ADD_THAT,
      label: i18next.t('Add that'),
    },
    {
      value: textAssistantInstructionSuggestions.TRANSLATE_TO,
      label: i18next.t('Translate to'),
    },
    {
      value: textAssistantInstructionSuggestions.MENTION_THAT,
      label: i18next.t('Mention that'),
    },
    {
      value: textAssistantInstructionSuggestions.CHANGE_TONE_TO,
      label: i18next.t('Change tone to'),
    },
  ];

  return SUGGESTIONS.map(({ value, label }) => ({
    value,
    label: `${label} `,
    icon: {
      glyph: 'pen',
      type: 'accent',
      size: 20,
    },
    formatLabel,
  }));
};

const getTextAssistantSuggestionGroupOption = () => ({
  value: 'suggestions',
  label: i18next.t('Edit selection with AI'),
  options: getOptions(),
});

export default getTextAssistantSuggestionGroupOption;
