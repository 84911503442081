import { serviceClient } from '@yola/ws-sdk';
import asyncJobStatuses from '../common/constants/async-job-statuses';

const pollAsyncJob = async (jobId, interval, abortSignal = {}) => {
  const client = serviceClient.get();
  const { data } = await client.getAsyncJob(jobId);
  let jobData = data;
  const { status } = jobData;

  if (abortSignal.aborted) {
    throw new DOMException('Aborted by the user', 'AbortError');
  }

  switch (status) {
    case asyncJobStatuses.IN_PROGRESS: {
      return new Promise((resolve, reject) => {
        setTimeout(async () => {
          try {
            jobData = await pollAsyncJob(jobId, interval);
            resolve(jobData);
          } catch (e) {
            reject(e);
          }
        }, interval);
      });
    }
    case asyncJobStatuses.FAILED: {
      throw jobData;
    }
    case asyncJobStatuses.COMPLETE:
    default: {
      return jobData;
    }
  }
};

export default pollAsyncJob;
