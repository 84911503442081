import errorModule from '../../error';
import user from '../../user';
import yowsServiceClient from '../../yows-service-client/service-client';
import actions from '../actions';
import actionTypes from '../constants/action-types';

const siteData = (store) => (next) => (action) => {
  switch (action.type) {
    case actionTypes.FETCH_DATA: {
      const service = yowsServiceClient.get();
      const { siteId } = action.payload;
      service
        .fetchSite(siteId, { include_hidden: true })
        .then((data) => {
          store.dispatch(actions.setSiteData(data));
          next(action);
          store.dispatch(user.actions.fetchUserPartnerData());
        })
        .catch((error) => {
          store.dispatch(
            errorModule.actions.setCriticalError({
              error,
              logSentry: true,
            })
          );

          next(action);
        });
      break;
    }

    default:
      next(action);
  }
};

export default siteData;
