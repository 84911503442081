import { textFormatting, site, integration, hooks, view, template } from '@yola/ws-sdk';
import actionTypes from '../constant/action-types';
import regeneratePropertiesForBlockCssRules from '../helpers/regenerate-properties-for-block-css-rules';
import generateCustomColorsForCssRule from '../helpers/generate-custom-colors-for-css-rule';
import handleNativeColorEditing from '../helpers/handle-native-color-editing';
import getBlockSurface from '../helpers/get-block-surface';
import { BLOCK_WITH_SURFACE_SELECTOR } from '../../blocks/constants/common';

const customColorAutomation = (store) => (next) => (action) => {
  const state = store.getState();
  const isMpt = template.verifiers.isMpt(state);

  if (!isMpt) {
    next(action);
    return;
  }

  switch (action.type) {
    case actionTypes.CHANGE_COLOR_PALETTE: {
      const customTextColorsStylesheet = textFormatting.helpers.getCustomTextColorsStylesheet();

      if (!customTextColorsStylesheet) {
        next(action);
        return;
      }

      if (action.payload.isChanged) {
        textFormatting.helpers.updateCustomColorStylesheetInGlobal();
      } else {
        textFormatting.helpers.reloadCustomColorStylesheet();
      }

      next(action);
      break;
    }
    case actionTypes.SWITCH_COLOR_PALETTE:
    case actionTypes.CHANGE_COLOR: {
      const customTextColorsStylesheet = textFormatting.helpers.getCustomTextColorsStylesheet();

      if (customTextColorsStylesheet) {
        regeneratePropertiesForBlockCssRules(
          customTextColorsStylesheet,
          action.payload.colorPalette
        );
      }

      next(action);
      break;
    }
    case site.actionTypes.CONNECT_SITE: {
      const { isTemplateSwitched } = integration.selectors.getSettings(state);

      if (isTemplateSwitched) {
        hooks.operations.subscribe(
          hooks.availableHooks.ON_LIVE_DOCUMENT_CONNECTED,
          () => {
            const customTextColorsStylesheet =
              textFormatting.helpers.getCustomTextColorsStylesheet();

            if (customTextColorsStylesheet) {
              regeneratePropertiesForBlockCssRules(customTextColorsStylesheet);
            }
          },
          {
            once: true,
          }
        );
      }

      // we need this to handle native color editing in Safari for macbook with touchpad
      hooks.operations.subscribe(
        hooks.availableHooks.ON_BEFORE_BLUR_FOCUSED_ELEMENT,
        handleNativeColorEditing
      );

      next(action);
      break;
    }
    case view.actionTypes.SHIFT_ELEMENT: {
      const {
        elementId,
        refElementId,
        previousPositionData: { refElementId: prevRefElementId } = {},
      } = action.payload;

      const element = view.accessors.getLiveElement(elementId);
      const dragSourceElement = view.accessors.getLiveElement(prevRefElementId);
      const dropTargetElement = view.accessors.getLiveElement(refElementId);
      const sourceBlockElement = dragSourceElement.closest(BLOCK_WITH_SURFACE_SELECTOR);
      const targetBlockElement = dropTargetElement.closest(BLOCK_WITH_SURFACE_SELECTOR);
      const sourceSurface = getBlockSurface(dragSourceElement);
      const targetSurface = getBlockSurface(dropTargetElement);

      if (!sourceSurface || !targetSurface || sourceBlockElement === targetBlockElement) {
        next(action);
        return;
      }

      const customColorVariables = textFormatting.helpers.getElementCustomColorVariables(element);

      if (!customColorVariables.length) {
        next(action);
        return;
      }

      const sourceBlockSelector = `#${sourceBlockElement.id} .${sourceSurface}`;
      const targetBlockSelector = `#${targetBlockElement.id} .${targetSurface}`;
      const customTextColorsStylesheet = textFormatting.helpers.getCustomTextColorsStylesheet();

      let targetCssRule = textFormatting.helpers.getCssRuleBySelector(
        customTextColorsStylesheet,
        targetBlockSelector
      );

      if (!targetCssRule) {
        targetCssRule = textFormatting.helpers.createEmptyCssRule(
          customTextColorsStylesheet,
          targetBlockSelector
        );
      }

      if (sourceSurface === targetSurface) {
        const sourceCssRule = textFormatting.helpers.getCssRuleBySelector(
          customTextColorsStylesheet,
          sourceBlockSelector
        );

        textFormatting.helpers.copyCustomColorsBetweenCssRules(
          sourceCssRule,
          targetCssRule,
          customColorVariables
        );
      } else {
        const customColorsToGenerate = customColorVariables.filter(
          (cssVariable) => !targetCssRule.cssText.includes(cssVariable)
        );

        generateCustomColorsForCssRule(targetCssRule, customColorsToGenerate);
      }

      textFormatting.helpers.updateCustomColorStylesheetInGlobal();

      next(action);
      break;
    }
    default: {
      next(action);
    }
  }
};

export default customColorAutomation;
