import isPlaceholder from 'src/js/modules/extensions/registry/ws-image/helpers/is-placeholder';
import optionTypes from '../../constants/background-option-types';
import { LIVE_PREVIEW_MOCKED_ID, BACKGROUND_PLACEHOLDER_ATTRIBUTE } from '../../constants/common';

const { BACKGROUND_IMAGE_ATTRIBUTE, BACKGROUND_IMAGE_OPTION } = optionTypes;
const handleBackgroundImage = (
  destinationNode,
  backgroundOptions = [],
  variationId,
  cachedBackgroundOptions
) => {
  let destinationBackgroundOptions = backgroundOptions;

  if (!backgroundOptions) {
    if (!cachedBackgroundOptions) return false;
    destinationBackgroundOptions = cachedBackgroundOptions;
  }

  const backgroundImageOption = destinationBackgroundOptions.find(
    ({ id }) => id === BACKGROUND_IMAGE_OPTION
  );

  if (!backgroundImageOption) return false;

  const { attributesMap } = backgroundImageOption;

  if (!destinationNode.hasAttribute(BACKGROUND_IMAGE_ATTRIBUTE)) return false;

  const shouldCopyPosition =
    variationId === backgroundImageOption.variationId || variationId === LIVE_PREVIEW_MOCKED_ID;

  Object.keys(attributesMap).forEach((attributeName) => {
    const value =
      !shouldCopyPosition && attributeName === 'background-position'
        ? '50% 50%'
        : backgroundImageOption[attributesMap[attributeName]];
    destinationNode.setAttribute(attributeName, value);
  });

  const shouldRemoveBackgroundPlaceholder =
    isPlaceholder(destinationNode) && !isPlaceholder(backgroundImageOption.blockNode);

  if (shouldRemoveBackgroundPlaceholder) {
    destinationNode.removeAttribute(BACKGROUND_PLACEHOLDER_ATTRIBUTE);
  }

  return true;
};

export default handleBackgroundImage;
