import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import isEqual from 'lodash.isequal';
import bowser from 'yola-bowser';
import { customization, dialogs, i18next } from '@yola/ws-sdk';
import { converters, HSLColor } from '@yola/yola-palette-generator';
import useFeatureFlags from 'yola-editor/src/js/modules/feature-flags/hooks/use-feature-flags';
import ColorSettingsDialog from '../components/color-settings-dialog';
import { ACCENT_COLOR_TYPE, DARK_COLOR_TYPE } from '../constant/color-types';
import { cssKeyMap } from '../constant/colors-maps';
import constants from '../constant';
import triggerIds from '../constant/trigger-ids';
import helpers from '../helpers';
import actions from '../actions';
import dialogTypes from '../../dialogs/constants/dialog-types';
import useInjectPaletteToTemplate from '../hooks/use-inject-palette-to-template';
import createHslColorString from '../helpers/create-hsl-color-string';
import parseHslColorString from '../helpers/parse-hsl-color-string';

const {
  parseHslaColorString,
  colorValidation,
  generatePalette,
  getColorFromVariables,
  advancedColorKeyToVariableKey,
  generateColorPresetLabel,
  setGeneralColor,
  setAdvancedColor,
} = helpers;

const getColorSettingsCaptions = (colorType) => {
  const lightWarning = i18next.t(
    'We recommend using a lighter shade to increase the legibility on your website.'
  );
  const darkWarning = i18next.t(
    'We recommend using a darker shade to increase the legibility on your website.'
  );

  const warning = colorType === DARK_COLOR_TYPE ? darkWarning : lightWarning;

  return {
    title: i18next.t('Color settings'),
    warning,
    apply: i18next.t('Submit'),
    cancel: i18next.t('Cancel'),
    opacity: i18next.t('Opacity'),
  };
};

function ColorSettingsContainer(props) {
  const {
    colorType,
    colorKey,
    backgroundColorKey,
    opacity,
    isAdvancedColor,
    getSharedData,
    resolveSharedData,
    onDialogMainAction,
    onDialogCancel,
    scrollTop,
    variableId,
    variableCategory,
  } = props;

  const { offsetX, offsetY, editPalette, editColorSchemePalette, isEdit, originPalette } =
    getSharedData();
  const colorPalette = editColorSchemePalette || editPalette;

  const editColor =
    colorPalette.colors[colorKey] ||
    `hsla(${getColorFromVariables(
      advancedColorKeyToVariableKey(colorKey),
      colorPalette.variables
    )}, ${opacity})`;

  const dispatch = useDispatch();
  const [warning, setWarning] = useState(false);
  const [hslaColor, setColor] = useState(parseHslaColorString(editColor));
  const [dialogPosition, setDialogPosition] = useState({ offsetX, offsetY });
  const [isColorPaletteConfigLoading, setColorPaletteConfigLoading] = useState(false);
  const [{ advanced_color_customization: isAdvancedColorCustomizationAvailable }] = useFeatureFlags(
    ['advanced_color_customization']
  );

  const colorPaletteConfig = useSelector(customization.selectors.getColorPaletteConfig);
  const customColors = useSelector(customization.selectors.getCustomColors);

  const predefinedColors = useMemo(() => {
    if (isAdvancedColorCustomizationAvailable) {
      const originColors = Object.values(cssKeyMap).map((color) => ({
        value: originPalette.colors[color],
        opacity: constants.MAX_OPACITY_VALUE,
      }));
      const filteredCustomColors = customColors.filter(
        (customColor) =>
          !originColors.find(
            (originColor) =>
              originColor.value === customColor.value && customColor.opacity === originColor.opacity
          ) && (opacity ? customColor : customColor.opacity === constants.MAX_OPACITY_VALUE)
      );

      return [...originColors, ...filteredCustomColors];
    }
    return [];
  }, [customColors, isAdvancedColorCustomizationAvailable, opacity, originPalette.colors]);

  const workerRef = useRef(null);

  const onClose = () => {
    if (isAdvancedColorCustomizationAvailable) {
      dispatch(
        dialogs.actions.show(dialogTypes.COLOR_SCHEME_SETTINGS_DIALOG, {
          colorType,
          colorKey: backgroundColorKey,
          scrollTop,
        })
      );
      return;
    }

    dispatch(
      dialogs.actions.show(dialogTypes.WEBSITE_DESIGN_CUSTOM_PALETTE, {
        triggerId: triggerIds.COLOR_SETTINGS,
        colorPreset: generateColorPresetLabel(isEdit, editPalette.id),
      })
    );
  };

  const changeColor = useCallback((palette) => dispatch(actions.changeColor(palette)), [dispatch]);

  const injectPaletteToTemplate = useInjectPaletteToTemplate();

  const handleInjectPaletteToTemplate = (compiledPalette) => {
    injectPaletteToTemplate(compiledPalette);
    changeColor(compiledPalette);
  };

  const workerListener = ({ data }) => {
    handleInjectPaletteToTemplate(data.palette);
  };

  useEffect(() => {
    workerRef.current = new Worker(
      new URL('../workers/generate-color-palette.worker.js', import.meta.url)
    );
    workerRef.current.addEventListener('message', workerListener);
    return () => {
      workerRef.current.removeEventListener('message', workerListener);
      workerRef.current.terminate();
    };
  }, [workerRef]);

  const updateSharedData = (customData) => {
    resolveSharedData({
      offsetX: dialogPosition.offsetX,
      offsetY: dialogPosition.offsetY,
      ...customData,
    });
  };

  const getUpdatedPalette = (newColor) =>
    isAdvancedColor
      ? setAdvancedColor({ colorKey, color: newColor, backgroundColorKey, colorPalette })
      : setGeneralColor({ colorKey, color: newColor, colorPalette });

  const handleSubmit = () => {
    const colorAdjusted = !isEqual(hslaColor, parseHslaColorString(editColor));
    const compiledPalette = generatePalette(getUpdatedPalette(hslaColor), colorPaletteConfig);

    handleInjectPaletteToTemplate(compiledPalette || colorPalette);
    updateSharedData({
      [isAdvancedColorCustomizationAvailable ? 'editColorSchemePalette' : 'editPalette']:
        compiledPalette || colorPalette,
    });

    if (isAdvancedColorCustomizationAvailable) {
      const hslColorString = createHslColorString(hslaColor);
      const originColorsArray = Object.values(cssKeyMap).map(
        (color) => originPalette.colors[color]
      );

      if (
        !originColorsArray.includes(hslColorString) ||
        hslaColor.a !== constants.MAX_OPACITY_VALUE
      ) {
        dispatch(
          customization.actions.addCustomColor({
            value: hslColorString,
            opacity: hslaColor.a || constants.MAX_OPACITY_VALUE,
          })
        );
      }
    }

    onDialogMainAction({ colorAdjusted, variableId, variableCategory });
    onClose();
  };

  const handleCancel = () => {
    handleInjectPaletteToTemplate(colorPalette);

    updateSharedData({
      [isAdvancedColorCustomizationAvailable ? 'editColorSchemePalette' : 'editPalette']:
        colorPalette,
    });

    onDialogCancel();
    onClose();
  };

  const handleColorChangeComplete = ({ hex }) => {
    if (colorKey === backgroundColorKey && colorType !== ACCENT_COLOR_TYPE) {
      setWarning(!colorValidation(hex, colorType));
    }
  };

  const handleChangeColor = ({ hsl }) => {
    setColor({ ...hsl });

    if (!bowser.mobile) {
      const updatedPalette = getUpdatedPalette(hsl);

      workerRef.current.postMessage({
        config: colorPaletteConfig,
        ...updatedPalette,
      });
    }
  };

  const handlePredefinedColorClick = ({ hslString, opacity: alpha }) => {
    const { h, s, l } = parseHslColorString(hslString);
    handleChangeColor({ hsl: { h, s, l, a: alpha } });

    const hslColor = new HSLColor(h, s, l);
    handleColorChangeComplete({ hex: converters.hsl2hex(hslColor) });
  };

  const handleDragEnd = (_, { x, y }) => {
    setDialogPosition({
      offsetX: x,
      offsetY: y,
    });
  };

  const captions = getColorSettingsCaptions(colorType);

  useEffect(() => {
    if (!colorPaletteConfig) {
      setColorPaletteConfigLoading(true);
    } else {
      setColorPaletteConfigLoading(false);
    }
  }, [colorPaletteConfig]);

  return (
    <ColorSettingsDialog
      captions={captions}
      color={hslaColor}
      enableAlpha={Boolean(opacity)}
      predefinedColors={predefinedColors}
      position={dialogPosition}
      isColorPaletteConfigLoading={isColorPaletteConfigLoading}
      onColorChange={handleChangeColor}
      onColorChangeComplete={handleColorChangeComplete}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      displayWarning={warning}
      onDragEnd={handleDragEnd}
      onPredefinedColorClick={handlePredefinedColorClick}
    />
  );
}

ColorSettingsContainer.propTypes = {
  colorType: PropTypes.string.isRequired,
  colorKey: PropTypes.string.isRequired,
  backgroundColorKey: PropTypes.string,
  opacity: PropTypes.string,
  isAdvancedColor: PropTypes.bool,
  getSharedData: PropTypes.func.isRequired,
  resolveSharedData: PropTypes.func.isRequired,
  onDialogMainAction: PropTypes.func.isRequired,
  onDialogCancel: PropTypes.func.isRequired,
  scrollTop: PropTypes.number,
  variableId: PropTypes.string.isRequired,
  variableCategory: PropTypes.string.isRequired,
};

ColorSettingsContainer.defaultProps = {
  backgroundColorKey: '',
  opacity: '',
  isAdvancedColor: false,
  scrollTop: null,
};

export default ColorSettingsContainer;
