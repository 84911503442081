import { i18n } from '@yola/ws-sdk';
import languageCodesMap from '../constants/language-codes-map';
import constants from '../constants/common';

const { DEFAULT_ICONS_LANGUAGE } = constants;

const getIconsLanguage = () => {
  const locale = i18n.accessors.getLocale();

  return languageCodesMap[locale] || languageCodesMap[DEFAULT_ICONS_LANGUAGE];
};

export default getIconsLanguage;
