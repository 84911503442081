import yousers from '@yola/yousersjs';
import config from 'src/js/modules/config';
import authActions from 'yola-editor/src/js/modules/auth/actions';

const yousersInitializer = (store) => (next) => (action) => {
  switch (action.type) {
    case config.actionTypes.SET_SERVICE_CONFIG: {
      next(action);
      const state = store.getState();
      const apiURL = config.selectors.getApiUrl(state);
      yousers.setAPIRoot(apiURL);

      store.dispatch(authActions.authenticate());
      break;
    }
    default: {
      next(action);
    }
  }
};

export default yousersInitializer;
