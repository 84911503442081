import { template, customization } from '@yola/ws-sdk';
import editorActionTypes from '../../editor/constants/action-types';
import websiteDesignActionTypes from '../constant/action-types';
import constants from '../constant';

const getFontLoaderScript = (primary, secondary) => `
  WebFontConfig = {
    google: {
      families: ['${primary}', '${secondary}&display=swap'],
    },
    classes: !1,
    events: !1,
  };
  (function(doc) {
    var element = doc.createElement('script'),
    head = doc.head;
    element.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js';
    element.async = !0
    head.appendChild(element, head);
    })(document);
`;

const userFontsLoader = () => (next) => (action) => {
  next(action);

  if (!template.verifiers.isMpt()) return;

  if (
    action.type === websiteDesignActionTypes.CHANGE_FONT_PAIRS ||
    action.type === editorActionTypes.SHOW_EDITOR
  ) {
    const existingScript = document.getElementById(constants.USER_FONTS_SCRIPT_TAG_ID);

    if (existingScript) {
      existingScript.remove();
    }

    const fontPairs = customization.accessors.getCurrentFontPair();

    if (!fontPairs) return;

    const {
      variables: { primaryFont, secondaryFont },
    } = fontPairs;
    const scriptFunction = getFontLoaderScript(primaryFont, secondaryFont);
    const scriptElem = document.createElement('script');

    scriptElem.id = constants.USER_FONTS_SCRIPT_TAG_ID;
    scriptElem.innerHTML = scriptFunction;

    document.body.appendChild(scriptElem);
  }
};

export default userFontsLoader;
