import React from 'react';
import PropTypes from 'prop-types';
import { i18next, utils } from '@yola/ws-sdk';
import { Field, designSystem } from '@yola/ws-ui';
import InputGroupField from 'src/js/modules/common/components/input-group-field';
import SimpleSelectField from 'src/js/modules/common/components/simple-select-field';
import RadioButtonGroupField from 'src/js/modules/common/components/radio-button-group-field';
import CheckboxField from 'src/js/modules/common/components/checkbox-field';
import helpers from '../helpers';
import constants from '../constants';

const { common, numberOfFilesAttachment } = constants;
const { LABEL_INPUT, BUTTON_TEXT_INPUT } = common;

const { Stack, ControlGroup } = designSystem;

const AttachFileFieldContent = (props) => {
  const {
    labelValue,
    buttonTextValue,
    fileTypeValue,
    placeholders,
    handleChange,
    onRequiredCheckboxClick,
  } = props;

  const supportedFileTypes = helpers.getSupportedFileTypes();
  const selectedFileType = supportedFileTypes.find(({ value }) => value === fileTypeValue);

  return (
    <Stack gap="spacing-m">
      <ControlGroup title={i18next.t('Label')} required>
        <Field
          component={InputGroupField}
          value={labelValue}
          name="label"
          placeholder={placeholders[LABEL_INPUT]}
          onChange={handleChange}
          validate={(value) =>
            helpers.validateRequired(value) || helpers.validatePersonalData(value)
          }
        />
      </ControlGroup>
      <ControlGroup title={i18next.t('Button text')} required>
        <Field
          component={InputGroupField}
          value={buttonTextValue}
          name="buttonText"
          placeholder={placeholders[BUTTON_TEXT_INPUT]}
          onChange={handleChange}
          validate={(value) =>
            helpers.validateRequired(value) || helpers.validatePersonalData(value)
          }
        />
      </ControlGroup>
      <ControlGroup title={i18next.t('Supported file type')}>
        <Field
          component={SimpleSelectField}
          name="fileType"
          options={supportedFileTypes}
          defaultValue={selectedFileType}
          onChange={handleChange}
        />
      </ControlGroup>
      <ControlGroup title={i18next.t('Allowed number of files')}>
        <Field
          name="numberOfFiles"
          render={(formikProps) => (
            <RadioButtonGroupField
              {...formikProps}
              radioButtons={[
                {
                  id: numberOfFilesAttachment.SINGLE,
                  label: i18next.t('Single file'),
                  isLabelBold: true,
                  value: numberOfFilesAttachment.SINGLE,
                },
                {
                  id: numberOfFilesAttachment.MULTIPLE,
                  label: i18next.t('Multi-file upload'),
                  isLabelBold: true,
                  value: numberOfFilesAttachment.MULTIPLE,
                },
              ]}
            />
          )}
        />
      </ControlGroup>
      <Field
        name="required"
        component={CheckboxField}
        id="displayRequireCheckbox"
        label={i18next.t('Make required')}
        onClick={onRequiredCheckboxClick}
      />
    </Stack>
  );
};

AttachFileFieldContent.propTypes = {
  labelValue: PropTypes.string,
  buttonTextValue: PropTypes.string,
  fileTypeValue: PropTypes.string.isRequired,
  placeholders: PropTypes.shape({
    [LABEL_INPUT]: PropTypes.string,
    [BUTTON_TEXT_INPUT]: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  onRequiredCheckboxClick: PropTypes.func,
};

AttachFileFieldContent.defaultProps = {
  labelValue: '',
  buttonTextValue: '',
  onRequiredCheckboxClick: utils.noop,
};

export default AttachFileFieldContent;
