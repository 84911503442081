import React from 'react';
import { extensions, i18next } from '@yola/ws-sdk';
import constants from '../constants';
import InsertImageTrigger from '../components/insert-image-trigger';

const { controlTypes } = extensions;
const triggerId = 'insert-image';

const insertImageControl = {
  id: triggerId,
  trigger: <InsertImageTrigger triggerId={triggerId} />,
  get title() {
    return i18next.t('Insert image');
  },
  get tooltip() {
    return i18next.t('Insert image');
  },
  for: [controlTypes.COLLAPSED_TEXT, controlTypes.TEXT],
  priority: 60,
  closeIcon: 'submit',
  matches(liveElement) {
    const settings = extensions.accessors.getExtensionSettings(constants.common.SLUG);

    if (settings.insertImageRestrictedParentsSelector) {
      if (liveElement.closest(settings.insertImageRestrictedParentsSelector)) return false;
    }

    return liveElement.matches(settings.insertImageQuerySelector);
  },
  highlightElement: false,
};

export default insertImageControl;
