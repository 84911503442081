import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Text, Input, Button, FieldStatus, Spinner } from '@yola/ws-ui';
import { i18next } from '@yola/ws-sdk';
import classNames from 'classnames';
import DomainSearchResultItem from './domain-search-result-item';
import isDomainSearchQueryValid from '../helpers/is-domain-search-query-valid';

function DomainSearch({ products, isLoading, onSearch, onSelect }) {
  const [inputValue, setInputValue] = useState('');
  const [isValid, setValidationStatus] = useState(true);

  function handleInputChange(e) {
    setInputValue(e.target.value);

    if (!isValid) {
      setValidationStatus(true);
    }
  }

  function handleSearch() {
    if (isDomainSearchQueryValid(inputValue)) {
      setValidationStatus(true);
      onSearch(inputValue);
    } else {
      setValidationStatus(false);
    }
  }

  function handleKeyPress(e) {
    if (e.key === 'Enter') {
      handleSearch();
    }
  }

  return (
    <div
      className={classNames('domain-search', {
        'domain-search--short': !products.length,
        'domain-search--long': products.length,
      })}
    >
      <div className="domain-search__item">
        <div className="domain-search__search-container">
          <Text type="heading-1" align="center" className="domain-search__title">
            {i18next.t('Search for a custom domain')}
          </Text>
          <Text type="heading-5" align="center" className="domain-search__subtitle">
            {i18next.t('A successful, credible business starts with a custom domain name')}
          </Text>
          <div className="domain-search__input-container">
            <div className="domain-search__input-inner-container">
              <Input
                value={inputValue}
                error={!isValid}
                placeholder={i18next.t('Enter domain name')}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
              />
              {!isValid && (
                <FieldStatus
                  text={i18next.t('Invalid domain name')}
                  className="domain-search__error"
                />
              )}
            </div>
            <Button
              type="primary"
              className="domain-search__search-btn"
              disabled={isLoading}
              onClick={handleSearch}
            >
              {isLoading ? (
                <Spinner containerPosition="center" spinnerPosition="center" />
              ) : (
                i18next.t('Search')
              )}
            </Button>
          </div>
        </div>
        {!!products.length && (
          <div className="domain-search__results-container">
            {products.map((product) => (
              <DomainSearchResultItem key={product.name} product={product} onSelect={onSelect} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

DomainSearch.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      price: PropTypes.string,
      term: PropTypes.string,
      available: PropTypes.bool,
    })
  ),
  isLoading: PropTypes.bool.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

DomainSearch.defaultProps = {
  products: [],
};

export default DomainSearch;
