import purchaseFlowTypes from 'src/js/modules/analytics/segment/constants/purchase-flow-types';
import getIsB2C from 'src/js/modules/config/selectors/is-b2c';
import productTypes from '../constants/product-types';
import upgradeTypes from '../constants/upgrade-types';
import getUpgradeData from '../selectors/upgrade-data';

const getPurchaseFlowType = (state) => {
  const { type } = getUpgradeData(state, productTypes.LATITUDE) || {};
  const { PARTNER_REDIRECT, IN_APP, B2C, WL } = purchaseFlowTypes;

  if (type === upgradeTypes.REDIRECT) {
    return PARTNER_REDIRECT;
  }

  if (type === upgradeTypes.IN_APP) {
    return IN_APP;
  }

  const isB2C = getIsB2C(state);

  return isB2C ? B2C : WL;
};

export default getPurchaseFlowType;
