import actionTypes from '../constants/action-types';

const show = (previewVhost) => ({
  type: actionTypes.SHOW,
  payload: {
    previewVhost,
  },
});

export default show;
