import { hooks } from '@yola/ws-sdk';
import helpers from './helpers';
import control from './controls/control';
import constants from './constants';

const { ON_AFTER_INSERT_LIVE_ELEMENT, ON_LIVE_DOCUMENT_CONNECTED } = hooks.availableHooks;
const { afterViewLoad, afterElementInsert } = helpers;

const extension = {
  slug: constants.common.slug,
  title: 'WS Contact Form Customizer',
  description: 'Extension for editing contact form',
  defaults: {
    enableBuilder: false,
    querySelector: 'ws-contact-form-container',
  },
  ui: {
    control: [control],
  },
};

hooks.operations.subscribe(ON_LIVE_DOCUMENT_CONNECTED, afterViewLoad);
hooks.operations.subscribe(ON_AFTER_INSERT_LIVE_ELEMENT, afterElementInsert);

export default extension;
