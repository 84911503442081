import { linkEditing } from '@yola/ws-sdk';

const { linkTypes } = linkEditing.constants;

const DEFAULT_TYPES = [
  linkTypes.PAGE,
  linkTypes.PHONE,
  linkTypes.MAIL,
  linkTypes.DOWNLOAD,
  linkTypes.ANCHOR,
  linkTypes.EXTERNAL,
];

const LIMITED_EXTERNAL_LINKS_TYPES = [linkTypes.SOCIAL_MEDIA, linkTypes.WHATS_APP];

const getDefaultLinkTypes = (isLimitedExternalLinksEnabled = false) => {
  const limitedExternalLinks = isLimitedExternalLinksEnabled ? LIMITED_EXTERNAL_LINKS_TYPES : [];

  return [...DEFAULT_TYPES, ...limitedExternalLinks];
};

export default getDefaultLinkTypes;
