import get from 'lodash.get';
import getPartnerData from './partner-data';

const getHomeUrl = (rootState) => {
  const partnerData = getPartnerData(rootState);

  if (partnerData.isWhiteLabel) {
    return partnerData.properties.website;
  }

  return get(rootState, 'config.service.common.yolacom.url', null);
};

export default getHomeUrl;
