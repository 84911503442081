import { site, template } from '@yola/ws-sdk';
import trackAsync from '../track-async';

const trackAsyncEvent = (event, traits = {}) =>
  trackAsync(event, {
    siteId: site.accessors.getSiteId(),
    templateBuildSlug: template.accessors.getBuildSlug(),
    ...traits,
  });

export default trackAsyncEvent;
