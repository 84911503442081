import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {
  i18next,
  view,
  site,
  blocks,
  textFormatting,
  contentEditable,
  integration,
} from '@yola/ws-sdk';
import { constants } from '@yola/subscription-manager-js';
import segment from '../../../../analytics/segment';
import ai from '../../../../ai';
import upsell from '../../../../upsell';
import getParentBlockByElement from '../../../../blocks/helpers/get-parent-block-by-element';
import helpers from '../helpers';
import useSelectionSimulation from '../hooks/use-selection-simulation';
import useTextAssistantStatusChange from '../hooks/use-text-assistant-status-change';
import useUndoRedoPrevention from '../hooks/use-undo-redo-prevention';
import AiTextAssistantContainer from './ai-text-assistant-container';

const {
  helpers: { initiateTextReplacement },
  constants: {
    textAssistantInstructions,
    textAssistantInstructionSuggestions,
    textAssistantInstructionTypes,
  },
} = ai;
const {
  constants: { events },
  trackers: { trackEvent },
} = segment;
const {
  clearFormatting,
  insertGeneratedContent,
  setSelectionByNodePath,
  getTextAssistantActionGroupOption,
  getTextAssistantSuggestionGroupOption,
  restoreRange,
} = helpers;
const {
  hostingPackageFeatureNames: { AI_TEXT_ASSISTANT_QUOTA },
} = constants;
const QUOTA_EXCEEDED_CODE = 'ai_text_assistant_quota_exceeded';

const getCaptions = () => ({
  placeholder: i18next.t('Tell AI what to do, e.g. "Write about ..."'),
  loading: i18next.t('AI is writing...'),
  cancel: i18next.t('Discard'),
});

const isPredefinedInstruction = (instruction) =>
  Object.values(textAssistantInstructions).includes(instruction);
const isPredefinedSuggestion = (instruction) =>
  Object.values(textAssistantInstructionSuggestions).includes(instruction);

const getUserInstructionType = (instruction) => {
  if (isPredefinedInstruction(instruction)) {
    return textAssistantInstructionTypes.PREDEFINED_PROMPT;
  }

  if (isPredefinedSuggestion(instruction)) {
    return textAssistantInstructionTypes.USER_INPUT_WITH_SUGGESTION;
  }

  return textAssistantInstructionTypes.USER_INPUT;
};

const getAutocompleteOptions = () => [
  getTextAssistantActionGroupOption(),
  getTextAssistantSuggestionGroupOption(),
];

const AiRewriteTextContainer = ({ elementId, triggerId, getContext }) => {
  const cachedParams = useRef({});
  const pageId = useSelector(site.accessors.getActivePageId);
  const businessCategory = useSelector(site.selectors.getBusinessCategory);
  const limits = useSelector(integration.selectors.getLimits);
  const captions = getCaptions();
  const autocompleteOptions = getAutocompleteOptions();
  const abortController = useRef();
  const focusedElementInfo = useRef(contentEditable.helpers.getFocusedElementInfo());
  const [selectedInstruction, setSelectedInstruction] = useState(null);

  const { clearSelectionImitation, restoreSelectionImitation } = useSelectionSimulation(elementId);
  const { isLoading, onLoading, onSuccess, onError, onCancel } =
    useTextAssistantStatusChange(getContext);
  useUndoRedoPrevention();

  if (!elementId) return null;

  const handleTextReplacement = async (params) => {
    const element = view.accessors.getLiveElement(elementId);
    const blockElement = getParentBlockByElement(element);
    abortController.current = new AbortController();
    const { signal } = abortController.current;

    onLoading();

    try {
      const response = await initiateTextReplacement(params, signal);
      const { content } = response.results;

      if (signal.aborted) {
        return;
      }

      clearSelectionImitation();

      // workaround for missed selection on iOS 16
      const selection = textFormatting.accessors.getAdvancedSelection();
      if (!selection.rangeCount) {
        setSelectionByNodePath(element, focusedElementInfo.current.selectionData);
      }

      await insertGeneratedContent(element, content);
      focusedElementInfo.current = contentEditable.helpers.getFocusedElementInfo();
      restoreSelectionImitation();

      onSuccess();
    } catch (err) {
      if (!signal.aborted) {
        const isAiTextAssistantQuotaExceeded =
          err.response?.status === 409 && err.response?.data?.code === QUOTA_EXCEEDED_CODE;

        console.error(err);
        onError(err, isAiTextAssistantQuotaExceeded);

        if (isAiTextAssistantQuotaExceeded) {
          const currentLimit = get(limits, 'aiTextAssistantQuota');
          upsell.operations
            .requestFeatureAvailability(AI_TEXT_ASSISTANT_QUOTA, { ...currentLimit })
            .then(() => {
              handleTextReplacement(params);
            })
            .catch(() => {
              // eslint-disable-next-line no-console
              console.log('Upgrade flow was canceled');
            });
        } else {
          trackEvent(events.AI_TEXT_ASSISTANT_ERROR_CAUGHT, {
            blockId: blocks.accessors.getBlockIdByElement(blockElement),
            blockVariationId: blocks.accessors.getVariationIdByElement(blockElement),
            triggerId,
            businessCategory,
          });
        }
      }
    }
  };

  const handleRewrite = (instruction) => {
    clearSelectionImitation();

    const element = view.accessors.getLiveElement(elementId);
    let selection = textFormatting.accessors.getAdvancedSelection();

    // workaround for missed selection on iOS 16
    if (!selection.rangeCount) {
      setSelectionByNodePath(element, focusedElementInfo.current.selectionData);
      selection = textFormatting.accessors.getAdvancedSelection();
    }

    clearFormatting(selection, elementId);

    const entireContent = element.innerHTML.trim();
    const contentSelection = selection.toHtml();

    restoreSelectionImitation();

    const params = {
      entireContent,
      contentSelection,
      pageId,
      ...(isPredefinedInstruction(instruction)
        ? { instruction }
        : { userInstruction: instruction }),
    };

    cachedParams.current = params;

    handleTextReplacement(params);
  };

  const handleRetry = () => {
    const element = view.accessors.getLiveElement(elementId);
    const blockElement = getParentBlockByElement(element);

    trackEvent(events.AI_TEXT_ASSISTANT_TRY_AGAIN_TRIGGER_CLICKED, {
      blockId: blocks.accessors.getBlockIdByElement(blockElement),
      blockVariationId: blocks.accessors.getVariationIdByElement(blockElement),
      triggerId,
      businessCategory,
    });

    handleTextReplacement(cachedParams.current);
  };

  const handleCancel = () => {
    abortController.current?.abort();
    onCancel();

    const selection = textFormatting.accessors.getAdvancedSelection();
    restoreRange(selection, elementId);
  };

  const handleSubmit = (instruction) => {
    const element = view.accessors.getLiveElement(elementId);
    const blockElement = getParentBlockByElement(element);

    trackEvent(events.AI_TEXT_USER_INPUT_SUBMITTED, {
      blockId: blocks.accessors.getBlockIdByElement(blockElement),
      blockVariationId: blocks.accessors.getVariationIdByElement(blockElement),
      triggerId,
      userInstructionType: getUserInstructionType(selectedInstruction || instruction),
      userInput: instruction,
      businessCategory,
    });

    handleRewrite(instruction);
  };

  const handleSelect = (instruction) => {
    setSelectedInstruction(instruction);

    if (isPredefinedSuggestion(instruction)) {
      return {
        withRetry: false,
      };
    }

    handleSubmit(instruction);

    return {
      withRetry: true,
    };
  };

  return (
    <AiTextAssistantContainer
      captions={captions}
      autocompleteOptions={autocompleteOptions}
      isLoading={isLoading}
      onSelect={handleSelect}
      onSubmit={handleSubmit}
      onRetry={handleRetry}
      onCancel={handleCancel}
    />
  );
};

AiRewriteTextContainer.propTypes = {
  triggerId: PropTypes.string.isRequired,
  elementId: PropTypes.string,
  getContext: PropTypes.func,
};

AiRewriteTextContainer.defaultProps = {
  elementId: null,
  getContext: Function.prototype,
};

export default AiRewriteTextContainer;
