import { useDispatch, useSelector } from 'react-redux';
import { dialogs, view } from '@yola/ws-sdk';
import { useCallback, useEffect, useRef } from 'react';
import bowser from 'yola-bowser';
import actions from 'src/js/modules/context-menu/actions';
import highlighter from 'src/js/modules/highlighter';
import isTouchSupport from 'src/js/modules/context-menu/verifiers/is-touch-support';
import getFocusedElement from 'src/js/modules/context-menu/helpers/get-focused-element';
import prepareMenuGroups from 'src/js/modules/context-menu/helpers/prepare-menu-groups';
import getMatchedMenuGroups from 'src/js/modules/context-menu/helpers/get-matched-menu-groups';
import patchMenuItemsWithClickCallback from 'src/js/modules/context-menu/helpers/patch-menu-items-with-click-callback';
import constants from 'src/js/modules/common/constants';
import useFeatureFlags from 'yola-editor/src/js/modules/feature-flags/hooks/use-feature-flags';
import getVisibilityStatus from '../selectors/visibility-status';
import getRegisteredGroups from '../selectors/registered-groups';
import getClosestInteractiveElement from '../helpers/get-closest-interactive-element';
import contextMenuDisplayedTracker from '../helpers/context-menu-displayed-tracker';

const { RIGHT_CLICK_TRIGGER_ID } = constants.common;

const useContextMenu = () => {
  const dispatch = useDispatch();
  const isLoaded = useSelector(view.selectors.getLoadedStatus);
  const visibility = useSelector(getVisibilityStatus);
  const isDialogVisible = useSelector(dialogs.verifiers.isVisible);
  const registeredGroups = useSelector(getRegisteredGroups);
  const collectedMenuGroups = useRef(null);
  const hoveredElementId = useSelector(view.selectors.getHoveredElement);
  const scrollPosition = useSelector(view.selectors.getScrollPosition);
  const [featureFlags] = useFeatureFlags();

  const closeMenu = useCallback(() => {
    dispatch(actions.hideContextMenu());
    highlighter.operations.hide();
  }, [dispatch]);

  useEffect(() => {
    let liveDoc;
    const isTouchDevice = isTouchSupport();

    const hoveredElement = hoveredElementId
      ? view.accessors.getLiveElement(hoveredElementId)
      : null;

    const targetElement = hoveredElement && getClosestInteractiveElement(hoveredElement);

    function openMenu(eventX = 0, eventY = 0, definedMenuGroups = null) {
      if (isLoaded) {
        const focusedElement = getFocusedElement();

        if (!focusedElement && !isDialogVisible) {
          // Collect the most actual menu content at the moment when contextmenu was triggered
          const actualMenuGroups = definedMenuGroups
            ? prepareMenuGroups(definedMenuGroups)
            : getMatchedMenuGroups(
                targetElement,
                prepareMenuGroups(registeredGroups),
                featureFlags
              );

          collectedMenuGroups.current = patchMenuItemsWithClickCallback(
            actualMenuGroups,
            closeMenu
          );

          if (collectedMenuGroups.current && collectedMenuGroups.current.length) {
            // Event coordinates are related to the live document, but menu is positioned related to the editor's viewport.
            // Also, Y coordinate should be adjusted depending on browser / OS environment.
            // For iOS < 13 we're using workaround that sets iframe height inside 'syncy frame' explicitly.
            // To compensate this and get the proper Y coordinate related to the viewport, we should subtract scroll position.
            const y = bowser.ios && !bowser.isIpadOS ? eventY - scrollPosition : eventY;
            const position = { x: eventX, y };

            dispatch(
              actions.showContextMenu({
                position,
                groups: collectedMenuGroups.current,
                triggerId: RIGHT_CLICK_TRIGGER_ID,
              })
            );
            contextMenuDisplayedTracker({
              triggerId: RIGHT_CLICK_TRIGGER_ID,
              targetElement,
            });
          }
        }
      }
    }

    function handleContextMenu(event) {
      const focusedElement = getFocusedElement();

      const closestContentEditable = event.target.closest('[contenteditable]');
      if (isTouchDevice || (focusedElement && focusedElement === closestContentEditable)) return;
      event.preventDefault();
      openMenu(event.clientX, event.clientY);
    }

    // is used for block control pane on mobile devices
    function handleDefinedContextMenu(event) {
      const { contextMenuGroups, contextMenuPosition } = event.detail;
      const { offsetX, offsetY } = contextMenuPosition;

      openMenu(offsetX, offsetY, contextMenuGroups);
    }

    function handleLongPress(customEvent) {
      openMenu(customEvent.detail.clientX, customEvent.detail.clientY);
    }

    function handleTouchStart() {
      if (visibility) {
        closeMenu();
      }
    }

    function handleTouchEnd(e) {
      if (visibility) {
        e.preventDefault();
      }
    }

    if (isLoaded) {
      liveDoc = view.accessors.getLiveDocument();

      liveDoc.addEventListener('contextmenu', handleContextMenu);
      liveDoc.addEventListener('ws:contextmenu', handleDefinedContextMenu);

      if (isTouchDevice) {
        liveDoc.addEventListener('touchstart', handleTouchStart);
        liveDoc.addEventListener('ws:longpress', handleLongPress);
        liveDoc.addEventListener('touchend', handleTouchEnd);
      }
    }

    return () => {
      if (liveDoc) {
        liveDoc.removeEventListener('contextmenu', handleContextMenu);
        liveDoc.removeEventListener('ws:contextmenu', handleDefinedContextMenu);

        if (isTouchDevice) {
          liveDoc.removeEventListener('touchstart', handleTouchStart);
          liveDoc.removeEventListener('ws:longpress', handleLongPress);
          liveDoc.removeEventListener('touchend', handleTouchEnd);
        }
      }
    };
  }, [
    visibility,
    isLoaded,
    isDialogVisible,
    closeMenu,
    dispatch,
    registeredGroups,
    hoveredElementId,
    scrollPosition,
  ]);
};

export default useContextMenu;
