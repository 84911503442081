import latinizeString from './latinize-string';
import encryptString from './encrypt-string';
import replaceSymbolsCombinationsWithLetters from './replace-symbols-combinations-with-letters';

const sanitizeString = (word) => {
  const cleanedWord = replaceSymbolsCombinationsWithLetters(word);
  const latinizedWord = latinizeString(cleanedWord);
  const encryptedWord = encryptString(latinizedWord);

  return encryptedWord.replace(/[^a-zA-Zа-яА-ЯіІёЁ0-9]/gm, '').trim();
};

export default sanitizeString;
