import {
  BLOCK_WITH_SURFACE_SELECTOR,
  SURFACES_ATTRIBUTE,
} from '../../blocks/constants/display-options';
import { SURFACE_CLASS_NAME, SURFACE_INDEX_ATTRIBUTE } from '../../blocks/constants/common';

const SURFACE_SELECTOR = `.${SURFACE_CLASS_NAME}`;

const getBlockSurface = (element) => {
  const block = element.closest(BLOCK_WITH_SURFACE_SELECTOR);
  const surfaceElement = element.closest(SURFACE_SELECTOR);

  if (!block || !surfaceElement) return null;

  const surfaces = block.getAttribute(SURFACES_ATTRIBUTE).split(' ');
  const surfaceIndex = surfaceElement.getAttribute(SURFACE_INDEX_ATTRIBUTE);

  return surfaces[surfaceIndex];
};

export default getBlockSurface;
