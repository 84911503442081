import handlersCollection from '../constants/handlers-collection';
import prefixes from '../constants/prefixes';

const evaluate = async (config, ...restArguments) => {
  const { reason, prefix = prefixes.AFTER } = config;
  const handlers = handlersCollection.get(`${prefix}-${reason}`) || [];
  const evaluateHandler = async (handler) => handler(config, ...restArguments);
  const unresolvedHandlers = handlers.map(evaluateHandler);
  const results = await Promise.all(unresolvedHandlers);

  return results;
};

export default evaluate;
