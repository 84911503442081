import { hooks } from '@yola/ws-sdk';
import control from './controls/control';
import constants from './constants/common';
import helpers from './helpers';

const { ON_STATIC_DOCUMENT_CONNECTED, ON_BEFORE_INSERT_STATIC_ELEMENT } = hooks.availableHooks;
const { beforeViewLoad, beforeElementInsert } = helpers;

const extension = {
  slug: constants.slug,
  title: 'WS Google Maps Customizer',
  description: 'Extension for editing google maps',
  defaults: {
    querySelector: constants.DEFAULT_QUERY_SELECTOR,
  },
  ui: {
    control: [control],
  },
};

hooks.operations.subscribe(ON_STATIC_DOCUMENT_CONNECTED, beforeViewLoad);
hooks.operations.subscribe(ON_BEFORE_INSERT_STATIC_ELEMENT, beforeElementInsert);

export default extension;
