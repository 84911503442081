import generateAdvancedColors from './generate-advanced-colors';

const setAdvancedColor = ({ colorKey, color, backgroundColorKey, colorPalette }) => {
  const advancedColors = { ...colorPalette.advancedColors };
  const backgroundColor = colorPalette.colors[backgroundColorKey];
  const affectedAdvancedColors = generateAdvancedColors(
    color,
    colorKey,
    backgroundColorKey,
    backgroundColor,
    colorPalette.variables
  );

  return { ...colorPalette, advancedColors: { ...advancedColors, ...affectedAdvancedColors } };
};

export default setAdvancedColor;
