export const ASPECT_RATIO = 1.78;
export const ADD_BLOCKS_BTN_HEIGHT = 48;
export const ADD_WIDGET_BTN_HEIGHT = 24;
export const CONTROLS_GAP = 24;
export const FORCED_TABLET_WIDTH = 1280;
export const FORCED_DESKTOP_WIDTH = 1920;
export const FORCED_DESKTOP_WIDTH_FOR_V2 = 1280;
export const FORCED_MOBILE_WIDTH = 1024;
export const DEFAULT_IFRAME_WIDTH = '100%';
export const DEFAULT_IFRAME_HEIGHT = '100%';
export const DEFAULT_IFRAME_SCALE = 1;
