import React from 'react';
import PropTypes from 'prop-types';
import { utils, dialogs, i18next } from '@yola/ws-sdk';
import { designSystem } from '@yola/ws-ui';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import segment from 'src/js/modules/analytics/segment';
import helpers from '../helpers';

const { Stack, Heading, Paragraph, Box, TreeView } = designSystem;

const {
  track,
  constants: { events },
  helpers: { getDefaultTraits },
} = segment;

const { extendItemsFieldsActions, populateFieldsTitles } = helpers;

function GeneralTab(props) {
  const {
    elementId,
    fields,
    updateFields,
    openSettings,
    modalContentRef,
    setIsOrderAdjusted,
    initialFields,
    advancedFormState,
    modalPosition,
  } = props;

  const traits = getDefaultTraits(elementId);

  const deleteField = ({ id, type }) => {
    const initialField = helpers.getItemById(initialFields, id);

    if (type === 'email') {
      const emailFieldsCount = fields.filter((field) => field.type === 'email').length;

      if (emailFieldsCount === 1) {
        dialogs.operations.show(dialogTypes.CONTACT_FORM_DELETE_EMAIL_DIALOG, {
          onSubmit: () => {
            const updatedFields = [...fields.filter((field) => field.id !== id)];
            dialogs.operations.show(dialogTypes.CONTACT_FORM_SETTINGS_DIALOG, {
              elementId,
              fields: updatedFields,
              advancedFormState,
              modalPosition,
            });
          },
          onCancel: () =>
            dialogs.operations.show(dialogTypes.CONTACT_FORM_SETTINGS_DIALOG, {
              elementId,
              fields,
              advancedFormState,
              modalPosition,
            }),
          elementId,
        });
      } else {
        updateFields(fields.filter((field) => field.id !== id));
      }
    } else {
      updateFields(fields.filter((field) => field.id !== id));
    }

    track(events.CONTACT_FORM_DELETE_ITEM_BUTTON_CLICKED, {
      ...traits,
      dialogId: dialogTypes.CONTACT_FORM_SETTINGS_DIALOG,
      itemId: id,
      itemCurrentLabel: initialField.label || null,
      itemType: type,
    });
  };

  const onOrderChanged = (data) => {
    track(events.CONTACT_FORM_ITEMS_ORDER_CHANGED, {
      ...traits,
      dialogId: dialogTypes.CONTACT_FORM_SETTINGS_DIALOG,
    });

    setIsOrderAdjusted(true);
    updateFields(data);
  };

  return fields.length ? (
    <Box paddingBottom="spacing-3-xl">
      <TreeView
        items={extendItemsFieldsActions(populateFieldsTitles(fields), [openSettings, deleteField])}
        onChange={onOrderChanged}
        interaction="sortable"
        portalContainer={modalContentRef.current}
      />
    </Box>
  ) : (
    <div className="ws-contact-form-settings-modal__empty-state">
      <Stack gap="spacing-4-xs">
        <Heading type="heading-5" appearance="medium-emphasis" align="center">
          {i18next.t('Add items to the form')}
        </Heading>
        <Paragraph appearance="medium-emphasis" align="center">
          {i18next.t('For your form to function, add at least one item.')}
        </Paragraph>
      </Stack>
    </div>
  );
}

GeneralTab.propTypes = {
  elementId: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string,
      options: PropTypes.array,
      order: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      placeholder: PropTypes.string,
      required: PropTypes.bool.isRequired,
    })
  ).isRequired,
  initialFields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updateFields: PropTypes.func.isRequired,
  openSettings: PropTypes.func.isRequired,
  modalContentRef: PropTypes.shape({
    current: PropTypes.node,
  }).isRequired,
  setIsOrderAdjusted: PropTypes.func,
  advancedFormState: PropTypes.shape(),
  modalPosition: PropTypes.shape({
    offsetX: PropTypes.number,
    offsetY: PropTypes.number,
  }).isRequired,
};

GeneralTab.defaultProps = {
  setIsOrderAdjusted: utils.noop,
  advancedFormState: null,
};

export default GeneralTab;
