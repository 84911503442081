import { template, view } from '@yola/ws-sdk';
import getOptionTargetElement from './get-option-target-element';

const createSliderOption = ({ item, blockNode, displayOptions, onChange, onAfterChange }) => {
  const targetElement = getOptionTargetElement(item, blockNode);
  if (!targetElement) return null;

  const targetElementId = view.accessors.getLiveElementId(targetElement);
  const existedOption = displayOptions && displayOptions.find((option) => option.id === item.id);

  const liveInitialValue = targetElement.getAttribute(item.attribute) || 0;
  const initialValue = existedOption ? existedOption.initialValue : liveInitialValue;

  return {
    ...item,
    onChange,
    onAfterChange,
    initialValue,
    min: item.min || 0,
    max: item.max || 100,
    step: item.step || 1,
    elementId: targetElementId,
    value: liveInitialValue,
    title: template.accessors.getMetaTranslations(item.title),
    subtitle: template.accessors.getMetaTranslations(item.subtitle),
    userModified: existedOption?.userModified,
  };
};

export default createSliderOption;
