import PropTypes from 'prop-types';

import isFunction from '../utils/is-function';

function Render(props) {
  // eslint-disable-next-line yola/react/destructuring-assignment
  if (props.if) {
    const { children } = props;

    if (isFunction(children)) {
      return children();
    }

    return children;
  }

  return null;
}

Render.propTypes = {
  if: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

export default Render;
