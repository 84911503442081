// Yola has partners who use editor inside the iframe
// topWindow param allow us to redirect user on the top level window
export const redirectToUrl = (url, options) => {
  if (options?.topWindow) {
    window.top.location.href = url;
    return;
  }

  window.location.assign(url);
};
