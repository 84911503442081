import get from 'lodash.get';
import { i18next, site } from '@yola/ws-sdk';
import user from '../../user';
import publishingJobStatuses from '../constants/publishing-job-statuses';
import yolaEditorSite from '../../site';
import setSettings from '../../editor/actions/set-settings';
import hasWLSiteCustomDomain from '../verifiers/has-wl-site-custom-domain';
import commonConstants from '../../common/constants';
import actions from '../actions';
import hasSiteCustomDomain from '../verifiers/has-site-custom-domain';
import dialogs from '../../dialogs';

const continuePublishingProgress =
  ({ response, nextCb = () => {} }) =>
  async (dispatch, getState) => {
    const state = getState();
    const { id: partnerId, properties: partnerProperties } = user.selectors.getPartnerData(state);
    const { isWhiteLabel } = user.selectors.getPartnerData(state);
    const {
      data: { status, results },
    } = response;
    const publishIndex = get(response, 'data.stats.completedCount', null);
    switch (status) {
      case publishingJobStatuses.COMPLETE: {
        const publishedSiteData = yolaEditorSite.selectors.getPublishedSiteData(state);
        const { primaryVhost } = results;

        if (!publishedSiteData) {
          const siteId = site.accessors.getSiteId();
          await dispatch(yolaEditorSite.thunks.fetchPublishedSiteData(siteId));
          dispatch(setSettings());
        }

        if (isWhiteLabel) {
          const updatedState = getState();
          const siteUrl = yolaEditorSite.selectors.getSiteURL(updatedState);

          const withPartnersDomainUpsell = user.verifiers.hasPartnersDomainUpsell(state);

          // Upsell section should NOT be visible if partner is configured for in-app domains
          // and site already has at least 1 custom domain.
          const isInAppDomainsConfigured = Boolean(
            partnerProperties &&
              partnerProperties.submitUserActionUrl &&
              partnerProperties.freeHostingDomains
          );
          const hasCustomDomain = hasWLSiteCustomDomain(state);
          const withUpsell =
            partnerId === commonConstants.ACMEPRO_PARTNER_ID ||
            (isInAppDomainsConfigured && !hasCustomDomain) ||
            withPartnersDomainUpsell;
          dispatch(actions.finishPublishing({ siteUrl, withUpsell }));
        } else {
          // Upsell section should NOT be visible if site already has at least 1 custom domain.
          const updatedState = getState();
          const withUpsell = !hasSiteCustomDomain(updatedState);

          dispatch(
            actions.finishPublishing({
              siteUrl: `https://${primaryVhost}`,
              partnerId,
              isPublished: Boolean(publishedSiteData),
              withUpsell,
            })
          );
        }

        nextCb();
        break;
      }
      case publishingJobStatuses.FAILED: {
        dispatch(
          dialogs.actions.show(dialogs.dialogTypes.PUBLISH_ERROR, {
            captions: {
              title: i18next.t('Site publish failed'),
              description: i18next.t('An error occurred while publishing your site'),
            },
          })
        );
        dispatch(actions.setPublishingStatus(publishingJobStatuses.FAILED));

        nextCb();
        break;
      }
      default:
        dispatch(dialogs.actions.hide());
        nextCb();
    }

    dispatch(yolaEditorSite.actions.setPublishCount(publishIndex));
  };

export default continuePublishingProgress;
