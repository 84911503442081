import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@yola/ws-ui';
import Trigger from 'src/js/modules/common/components/trigger';
import withSelection from 'src/js/modules/utils/with-selection';
import useElementTypeSelect from '../helpers/use-element-type-select';

function ElementTypeTrigger({ elementId, triggerId }) {
  const { elementType } = useElementTypeSelect(elementId);

  return (
    <Trigger id={triggerId}>
      {elementType ? (
        <Icon glyph={elementType.glyph} strokeWidth="1.3" />
      ) : (
        <Icon glyph="font-size" strokeWidth="1.3" size="16" />
      )}
    </Trigger>
  );
}

ElementTypeTrigger.propTypes = {
  triggerId: PropTypes.string.isRequired,
  elementId: PropTypes.string.isRequired,
};

export default withSelection(ElementTypeTrigger);
