import React from 'react';
import PropTypes from 'prop-types';
import { i18next, dialogs, assets, utils } from '@yola/ws-sdk';
import { AlertDialog } from '@yola/ws-ui';

function FileTypeErrorDialog(props) {
  const { captions, onDismiss, onDialogCancel, formats } = props;

  const getCaptions = (captionsOverrides) => ({
    title: i18next.t('Unsupported file type'),
    description: i18next.t('Please ensure your files is one of the following formats: {formats}', {
      formats: formats || assets.constants.IMAGE_ACCEPT_TYPE,
    }),

    dismiss: i18next.t('Dismiss'),
    ...captionsOverrides,
  });

  const handleDismiss = (...args) => {
    onDialogCancel();
    if (onDismiss) {
      onDismiss(...args);
    } else {
      dialogs.operations.hide();
    }
  };

  return (
    <AlertDialog glyph="alert-circle" onDismiss={handleDismiss} captions={getCaptions(captions)} />
  );
}

FileTypeErrorDialog.defaultProps = {
  captions: {},
  onDismiss: null,
  onDialogCancel: utils.noop,
  formats: '',
};

FileTypeErrorDialog.propTypes = {
  onDismiss: PropTypes.func,
  onDialogCancel: PropTypes.func,
  formats: PropTypes.string,
  captions: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    dismiss: PropTypes.string,
  }),
};

export default FileTypeErrorDialog;
