import addPremiumBlockInterrupter from './add-premium-block-interrupter';
import featureAvailabilityInterrupter from './feature-availability-interrupter';
import fileSizeInterrupter from './file-size-interrupter';
import onlineStoreInterrupter from './online-store-interrupter';
import pageLimitInterrupter from './page-limit-interrupter';
import addLanguageInterrupter from './add-language-inrerrupter';

export default {
  addPremiumBlockInterrupter,
  featureAvailabilityInterrupter,
  fileSizeInterrupter,
  onlineStoreInterrupter,
  pageLimitInterrupter,
  addLanguageInterrupter,
};
