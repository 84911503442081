import { dialogs, integration, view, blocks, template } from '@yola/ws-sdk';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import user from 'yola-editor/src/js/modules/user';
import segment from '../../analytics/segment';
import sourceIds from '../../blocks/constants/source-ids';

const {
  constants: { events },
  trackers: { trackEvent },
} = segment;

const addPremiumBlockInterrupter = (store) => (next) => (action) => {
  if (action.type === view.actionTypes.INSERT_BLOCK) {
    const state = store.getState();
    const { blockId, variationId: blockVariationId } = action.payload;
    const block = blocks.selectors.getBlock(state, blockId);
    const templateBuildSlug = template.accessors.getBuildSlug();
    const isNewTpl = templateBuildSlug.startsWith('ws-intense-next');
    const premiumBlock = (block && block.premium) || (!isNewTpl && blockId === 'html-embed');

    if (premiumBlock) {
      const limits = integration.selectors.getLimits(state);
      const upsells = integration.selectors.getUpsells(state);
      const isBlockWidget = blockId.startsWith('widget-');
      const targetUpsellName = isBlockWidget ? 'premiumWidget' : 'premiumBlocks';
      const targetUpsell = upsells[targetUpsellName];
      const details = { forcedUpsell: targetUpsellName };

      if (!limits.premiumBlocks.available && targetUpsell) {
        const { captions, premiumFeature, onUpgrade, purchaseFlowType } = targetUpsell;
        const availablePlatformComponents = user.selectors.getAvailablePlatformComponents(state);
        const onUpgradeResolve = () => next(action);
        const onUpgradeReject = () => {
          // eslint-disable-next-line no-console
          console.log('Upgrade flow was canceled');
        };

        trackEvent(events.ADD_PREMIUM_BLOCK_CLICKED, {
          categoryId: block.category,
          blockId,
          blockVariationId,
          sourceId: sourceIds.BLOCK_SELECTOR,
        });

        // b2c & reseller platform upgrade
        if (availablePlatformComponents.includes(user.platformComponents.PAYMENTS)) {
          onUpgrade(details).then(onUpgradeResolve, onUpgradeReject);
          return;
        }

        store.dispatch(
          dialogs.actions.show(dialogTypes.UPGRADE, {
            purchaseFlowType,
            premiumFeature,
            captions,
            onUpgrade,
            onUpgradeResolve,
          })
        );
        return;
      }
    }
  }
  next(action);
};

export default addPremiumBlockInterrupter;
