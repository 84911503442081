import { constants } from '@yola/subscription-manager-js';
import get from 'lodash.get';

const {
  hostingPackageFeatureNames: { PASSWORD_PROTECTED_PAGES },
} = constants;

const passwordProtectedPagesLimit = ({ targetPackage }) => ({
  available: get(targetPackage, ['features', PASSWORD_PROTECTED_PAGES], false),
});

export default passwordProtectedPagesLimit;
