import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import { i18next } from '@yola/ws-sdk';

const { Icon, ActionButton, Paragraph, Heading, Box } = designSystem;

const getCaptions = () => ({
  unableToLoad: i18next.t('Unable to load the icons'),
  couldNotLoadIcons: i18next.t(
    "We couldn't load the icons due to a technical issue. Please reload"
  ),
  reload: i18next.t('Reload'),
});

const UnableToLoad = ({ onReload }) => {
  const captions = getCaptions();

  return (
    <div className="ws-icon-modal__unable-to-load">
      <Box paddingLeft="spacing-m" paddingRight="spacing-m">
        <Box marginBottom="spacing-2-xs">
          <div className="ws-icon-modal__unable-to-load-icon">
            <Icon glyph="heart-crack" size={40} type="error" />
          </div>
        </Box>
        <Box marginBottom="spacing-4-xs">
          <Heading type="heading-4" appearance="medium-emphasis" align="center">
            {captions.unableToLoad}
          </Heading>
        </Box>
        <Box marginBottom="spacing-m">
          <Paragraph appearance="medium-emphasis" align="center">
            {captions.couldNotLoadIcons}
          </Paragraph>
        </Box>
        <Paragraph align="center">
          <ActionButton label={captions.reload} iconGlyph="renew" onClick={onReload} />
        </Paragraph>
      </Box>
    </div>
  );
};

UnableToLoad.propTypes = {
  onReload: PropTypes.func.isRequired,
};

export default UnableToLoad;
