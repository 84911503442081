import { STATUS_IN_PROGRESS, FAILED } from '../constants';
import sleep from './sleep';

const statusWatcher = async (statusGetter, callback) => {
  const {
    data: { status, progress },
  } = await statusGetter();

  switch (status) {
    case FAILED:
      return new Error('Job was failed');
    case STATUS_IN_PROGRESS: {
      if (typeof callback === 'function') {
        callback(progress);
      }
      await sleep(1000);
      return statusWatcher(statusGetter, callback);
    }
    default:
      return progress;
  }
};

export default statusWatcher;
