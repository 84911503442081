const prepareContentItem = (item, index) => {
  const { priority, submitOnBlur } = item;

  return {
    ...item,
    index,
    priority: priority === null ? 100 : priority,
    submitOnBlur: submitOnBlur === null ? true : submitOnBlur,
  };
};

export default prepareContentItem;
