import get from 'lodash.get';
import { constants } from '@yola/subscription-manager-js';
import { dialogs, integration } from '@yola/ws-sdk';
import user from 'yola-editor/src/js/modules/user';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import actionTypes from '../constants/action-types';

const {
  hostingPackageFeatureNames: { AI_BLOCK_GENERATION_QUOTA, AI_TEXT_ASSISTANT_QUOTA },
} = constants;

const featureAvailabilityInterrupter = (store) => (next) => (action) => {
  if (action.type === actionTypes.REQUEST_FEATURE_AVAILABILITY) {
    const featureName = get(action.payload, 'featureName', '');
    const details = get(action.payload, 'details', {});
    const resolve = get(action.payload, 'resolve', Function.prototype);
    const reject = get(action.payload, 'reject', Function.prototype);

    const state = store.getState();
    const limits = integration.selectors.getLimits(state);
    const upsells = integration.selectors.getUpsells(state);
    const targetUpsell = get(upsells, featureName);
    const targetLimit = get(limits, featureName);

    if (!targetLimit || !targetUpsell) {
      resolve();
      next(action);
      return;
    }

    const { captions, premiumFeature, onUpgrade, purchaseFlowType } = targetUpsell;
    const availablePlatformComponents = user.selectors.getAvailablePlatformComponents(state);

    switch (featureName) {
      case AI_BLOCK_GENERATION_QUOTA:
      case AI_TEXT_ASSISTANT_QUOTA: {
        // b2c & reseller platform upgrade
        if (availablePlatformComponents.includes(user.platformComponents.PAYMENTS)) {
          onUpgrade(details).then(resolve, reject);
          return;
        }

        store.dispatch(
          dialogs.actions.show(dialogTypes.UPGRADE, {
            purchaseFlowType,
            premiumFeature,
            captions,
            onUpgrade,
            onUpgradeReject: reject,
            onUpgradeResolve: resolve,
            onClose: reject,
          })
        );

        break;
      }

      default: {
        if (!targetLimit.available) {
          // b2c & reseller platform upgrade
          if (availablePlatformComponents.includes(user.platformComponents.PAYMENTS)) {
            onUpgrade().then(resolve, reject);
            return;
          }

          store.dispatch(
            dialogs.actions.show(dialogTypes.UPGRADE, {
              purchaseFlowType,
              premiumFeature,
              captions,
              onUpgrade,
              onUpgradeReject: reject,
              onUpgradeResolve: resolve,
              onClose: reject,
            })
          );
        } else {
          resolve();
        }
      }
    }

    next(action);
    return;
  }

  next(action);
};

export default featureAvailabilityInterrupter;
