import yousersjs from '@yola/yousersjs';
import get from 'lodash.get';
import i18n from 'yola-editor/src/js/modules/i18n';
import errorModule from 'yola-editor/src/js/modules/error';
import editorActions from 'yola-editor/src/js/modules/editor/actions';
import config from 'src/js/modules/config';
import getCookie from '../../../utils/get-cookie';
import actionTypes from '../constants/action-types';
import actions from '../actions';
import { snakeCaseObjectToCamelCase, snakeCaseToCamelCase } from '../../../utils/convert-case';
import selectors from '../selectors';

const userPreferencesLoader = (store) => (next) => (action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_PREFERENCES: {
      yousersjs
        .preferences()
        .get()
        .then(({ body }) => {
          const state = store.getState();
          const data = snakeCaseObjectToCamelCase(body);
          const userPreferences = selectors.getUserPreferences(state);

          store.dispatch(actions.setUserPreferences(data));

          if (!userPreferences) {
            const localeCookie = config.selectors.getLocaleCookie(state);
            const supportedLocales = config.selectors.getBaseLanguageCodes(state);
            const localeFromCookie = getCookie(localeCookie);
            const localeFromPreferences = supportedLocales.includes(data.locale)
              ? data.locale
              : 'en';
            const locale = localeFromCookie || localeFromPreferences;
            store.dispatch(i18n.actions.loadTranslations(locale));
          }

          next(action);

          const resolve = get(action, 'payload.resolve', Function.prototype);

          resolve(data);
        })
        .catch((error) => {
          store.dispatch(
            errorModule.actions.setCriticalError({
              error,
              logSentry: true,
            })
          );

          next(action);

          const reject = get(action, 'payload.reject', Function.prototype);

          reject(error);
        });
      break;
    }
    case actionTypes.UPDATE_USER_PREFERENCE: {
      const { property, value } = action.payload;
      yousersjs
        .preferences()
        .put(property, value)
        .then(({ body }) => {
          next({
            ...action,
            payload: {
              property: snakeCaseToCamelCase(body.name),
              value: body.value,
            },
          });
          store.dispatch(editorActions.setSettings());
        })
        .catch(() => {
          next(action);
        });
      break;
    }
    default:
      next(action);
  }
};

export default userPreferencesLoader;
