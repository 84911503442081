import getAdvertisingUrl from './advertising-url';
import getAnalyticsUrl from './analytics-url';
import getOnlineStoreUrl from './online-store-url';
import getSegmentIoKey from './segment-io-key';
import getApiUrl from './api-url';
import getWsLoginUrl from './ws-login-url';
import getGalleryLink from './gallery-link';
import getLocaleCookie from './locale-cookie';
import getBaseLanguageCodes from './base-language-codes';
import getCookieDomain from './cookie-domain';
import getMyyolaUrl from './myyola-url';
import getDomainHosting from './domain-hosting';
import getCheckoutserviceUrl from './checkoutservice-url';
import getTermsOfUseUrl from './terms-of-use-url';
import getPrivacyPolicyUrl from './privacy-policy-url';
import getPublicRoadmapURL from './public-roadmap-url';
import getCommonConfig from './common-config';
import getPMFSurveyUrl from './pmf-survey-url';
import getZendeskWidgetUrl from './zendesk-widget-url';
import getIsB2C from './is-b2c';
import getContactFormSubmissionUrl from './contact-form-submission-url';
import getRecaptchaSiteKey from './recaptcha-site-key';
import getSentryDSN from './sentry-dsn';
import getGoogleMapsEmbedApiKey from './google-maps-embed-api-key';
import getHelpCenterConfig from './help-center-config';
import getHelpCenterUrl from './help-center-url';
import getMyYolaConfig from './my-yola-config';
import getTestPagesEnabled from './test-pages-enabled';
import getPublishedSiteAnalyticsUrl from './published-site-analytics-url';
import getLogoutUrl from './logout-url';
import getClipboardAccessInstructionUrl from './clipboard-access-instruction-url';
import getEnableCopyPasteInstructionUrl from './enable-copy-paste-instruction-url';

export default {
  getAdvertisingUrl,
  getAnalyticsUrl,
  getOnlineStoreUrl,
  getSegmentIoKey,
  getApiUrl,
  getWsLoginUrl,
  getGalleryLink,
  getLocaleCookie,
  getBaseLanguageCodes,
  getCookieDomain,
  getMyyolaUrl,
  getDomainHosting,
  getCheckoutserviceUrl,
  getTermsOfUseUrl,
  getPrivacyPolicyUrl,
  getPublicRoadmapURL,
  getCommonConfig,
  getPMFSurveyUrl,
  getZendeskWidgetUrl,
  getIsB2C,
  getContactFormSubmissionUrl,
  getRecaptchaSiteKey,
  getSentryDSN,
  getGoogleMapsEmbedApiKey,
  getHelpCenterConfig,
  getHelpCenterUrl,
  getMyYolaConfig,
  getTestPagesEnabled,
  getPublishedSiteAnalyticsUrl,
  getLogoutUrl,
  getClipboardAccessInstructionUrl,
  getEnableCopyPasteInstructionUrl,
};
