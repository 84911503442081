import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Modal, designSystem } from '@yola/ws-ui';
import { hdrm } from '@yola/ws-sdk';
import common from '../../common';
import utils from '../../utils';
import BusinessCategoryField from './fields/business-category-field';

const {
  Box,
  Stack,
  Heading,
  Paragraph,
  ActionButton,
  IconButton,
  BetaLabel,
  ControlGroup,
  InputGroupField,
  Checkbox,
  Divider,
} = designSystem;
const { TextAreaWithLengthAnnotationField } = common.components;
const { useMatchBreakpoint } = utils.hooks;
const { DISABLE_BLUR_ATTR } = hdrm.constants.attributes;

const MODAL_WIDTH = 618;
const MODAL_HEIGHT = 300;

const AiOnboardingDialog = ({
  fields,
  captions,
  isBusinessNameSameAsSiteName,
  onBusinessNameChange,
  onSameAsSiteNameChange,
  onSubmit,
  onClose,
  onBusinessCategoryChange,
  onBusinessCategoryFocus,
}) => {
  const { businessName, businessDescription, businessCategory } = fields;
  const isDesktopView = useMatchBreakpoint(`${MODAL_WIDTH}px`);
  const classes = classNames('ws-ai-onboarding-dialog', {
    'ws-ai-onboarding-dialog--desktop-view': isDesktopView,
  });

  return (
    <Modal
      className={classes}
      width={MODAL_WIDTH}
      height="auto"
      minHeight={MODAL_HEIGHT}
      overlay="visible"
      fullscreen={!isDesktopView}
      attributes={{ [DISABLE_BLUR_ATTR]: true }}
      draggable={false}
      resizable={false}
      centered
    >
      <div className="ws-ai-onboarding-dialog__top-panel">
        <Box
          {...(isDesktopView
            ? {
                paddingTop: 'spacing-xs',
                paddingLeft: 'spacing-xs',
                paddingRight: 'spacing-xs',
              }
            : {
                paddingTop: 'spacing-2-xs',
                paddingBottom: 'spacing-2-xs',
                paddingLeft: 'spacing-xs',
                paddingRight: 'spacing-xs',
              })}
        >
          <IconButton
            glyph="close"
            iconPlacement="after"
            size="medium"
            onClick={onClose}
            transparent
          />
        </Box>
      </div>
      <div className="ws-ai-onboarding-dialog__content">
        <Box
          {...(isDesktopView
            ? {
                paddingBottom: 'spacing-2-xs',
                paddingLeft: 'spacing-xl',
                paddingRight: 'spacing-xl',
              }
            : {
                padding: 'spacing-xs',
              })}
        >
          <Stack gap="spacing-m">
            <Stack gap="spacing-3-xs">
              <Heading type="heading-3">
                {captions.title}&nbsp;
                <BetaLabel />
              </Heading>
              <Paragraph>
                <span
                  // eslint-disable-next-line yola/react/no-danger
                  dangerouslySetInnerHTML={{ __html: captions.description }}
                />
              </Paragraph>
            </Stack>
            <Stack gap="spacing-xs">
              <ControlGroup
                title={businessName.label}
                required={Boolean(businessName.rules?.required)}
              >
                <InputGroupField
                  control={businessName.control}
                  name={businessName.name}
                  rules={businessName.rules}
                  placeholder={businessName.placeholder}
                  size="medium"
                  onChange={onBusinessNameChange}
                />
                <Box marginTop="spacing-2-xs">
                  <Checkbox
                    id={businessName.name}
                    label={businessName.checkboxLabel}
                    checked={isBusinessNameSameAsSiteName}
                    onChange={onSameAsSiteNameChange}
                    value={businessName.name}
                  />
                </Box>
              </ControlGroup>
              <ControlGroup
                title={businessDescription.label}
                required={Boolean(businessDescription.rules?.required)}
              >
                <TextAreaWithLengthAnnotationField
                  control={businessDescription.control}
                  name={businessDescription.name}
                  rules={businessDescription.rules}
                  placeholder={businessDescription.placeholder}
                  statusText={businessDescription.statusText}
                  maxLength={businessDescription.rules.maxLength.value}
                />
              </ControlGroup>
              {businessCategory && (
                <ControlGroup
                  title={businessCategory.label}
                  required={Boolean(businessCategory.rules?.required)}
                >
                  <BusinessCategoryField
                    control={businessCategory.control}
                    name={businessCategory.name}
                    rules={businessCategory.rules}
                    placeholder={businessCategory.placeholder}
                    noResultsText={businessCategory.noResultsText}
                    onChange={onBusinessCategoryChange}
                    onFocus={onBusinessCategoryFocus}
                  />
                </ControlGroup>
              )}
            </Stack>
          </Stack>
        </Box>
      </div>
      <div className="ws-ai-onboarding-dialog__bottom-panel">
        {!isDesktopView && <Divider />}
        <Box
          {...(isDesktopView
            ? {
                padding: 'spacing-xl',
                paddingTop: 'spacing-2-xs',
              }
            : {
                padding: 'spacing-xs',
              })}
        >
          <ActionButton
            appearance="accent"
            fontWeight="regular"
            format="solid"
            iconGlyph="ai"
            size="large"
            label={captions.buttonLabel}
            fullWidth={!isDesktopView}
            onClick={onSubmit}
          />
        </Box>
      </div>
    </Modal>
  );
};

AiOnboardingDialog.propTypes = {
  fields: PropTypes.shape({
    businessName: PropTypes.shape().isRequired,
    businessDescription: PropTypes.shape().isRequired,
    businessCategory: PropTypes.shape(),
  }).isRequired,
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string.isRequired,
  }).isRequired,
  isBusinessNameSameAsSiteName: PropTypes.bool.isRequired,
  onBusinessNameChange: PropTypes.func.isRequired,
  onBusinessCategoryChange: PropTypes.func.isRequired,
  onBusinessCategoryFocus: PropTypes.func.isRequired,
  onSameAsSiteNameChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AiOnboardingDialog;
