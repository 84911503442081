import { view } from '@yola/ws-sdk';
import actions from '../actions';
import highlighter from '../../highlighter';
import cache from '../utils/cache';
import store from '../../../store';

const endDragHandler = (props) => {
  const { elementId } = props;
  const element = view.accessors.getLiveElement(elementId);

  store.dispatch(actions.setDragSourceElement(null));
  store.dispatch(actions.setAdjacentPosition(null));
  store.dispatch(actions.setDropTargetElement(null));
  store.dispatch(highlighter.actions.hide());

  view.helpers.autoScroll.stop();
  element.removeAttribute('data-ws-dragging');
  cache.clear();
};

export default endDragHandler;
