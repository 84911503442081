import { template, view } from '@yola/ws-sdk';
import attributeValuesList from 'src/js/modules/utils/attribute-values-list';
import getOptionTargetElement from './get-option-target-element';

const createRadioOption = ({ item, blockNode, displayOptions, onClick }) => {
  const targetElement = getOptionTargetElement(item, blockNode);

  if (!targetElement) return null;

  const targetElementId = view.accessors.getLiveElementId(targetElement);
  const liveSelectedOption = item.options.find((option) =>
    attributeValuesList.contains(targetElement, item.attribute, option.value)
  );
  const existedOptions = displayOptions && displayOptions.find((option) => option.id === item.id);

  const safeInitialValue = liveSelectedOption ? liveSelectedOption.value : item.options[0].value;
  const currentValue = existedOptions ? existedOptions.value : safeInitialValue;
  const initialValue = existedOptions ? existedOptions.initialValue : safeInitialValue;

  return {
    ...item,
    onClick,
    initialValue,
    elementId: targetElementId,
    options: item.options.map((option) => ({
      ...option,
      label: template.accessors.getMetaTranslations(option.label),
    })),
    value: liveSelectedOption ? liveSelectedOption.value : currentValue,
    title: template.accessors.getMetaTranslations(item.title),
    subtitle: template.accessors.getMetaTranslations(item.subtitle),
  };
};

export default createRadioOption;
