import publishingServiceClient from 'yola-editor/src/js/modules/publishing-service-client/service-client';
import isStalePublishingJob from '../verifiers/is-stale-publishing-job';
import publishingJobStatuses from '../constants/publishing-job-statuses';

const getPublishingJobsInProgress = async (siteId, publishingType) => {
  const serviceClient = publishingServiceClient.get();
  const {
    data: { results },
  } = await serviceClient.getPublishingJobs({ siteId, status: publishingJobStatuses.IN_PROGRESS });

  return results.filter((job) => !isStalePublishingJob(job, publishingType));
};

export default getPublishingJobsInProgress;
