import React from 'react';
import { dialogs, textFormatting, customization, view, contentEditable } from '@yola/ws-sdk';
import { HSLColor, contrastRatio } from '@yola/yola-palette-generator';
import blocks from 'src/js/modules/blocks';
import websiteDesign from 'src/js/modules/website-design';
import parseHslColorString from 'src/js/modules/website-design/helpers/parse-hsl-color-string';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import ColorOption from './color-option';
import AddCustomTextColorButton from './add-custom-text-color-button';
import constants from '../constants';
import getPredefinedColorsForBlock from '../helpers/get-predefined-colors-for-block';
import setColorOption from '../helpers/set-color-option';
import restoreRange from '../helpers/restore-range';

const { BLOCK_ELEMENTS } = contentEditable.constants.editableElements;
const { common } = constants;

const customColorHeader = (elementId) => {
  const { BLOCK_WITH_SURFACE_SELECTOR, SURFACE_CLASS_NAME, SURFACE_INDEX_ATTRIBUTE } =
    blocks.constants.common;
  const currentPalette = customization.accessors.getCurrentColorPalette();
  const customColors = customization.accessors.getCustomTextColors();
  const selection = view.accessors.getSelection();
  const element = view.accessors.getLiveElement(elementId);
  const block = element.closest(BLOCK_WITH_SURFACE_SELECTOR);
  const surfaceElement = element.closest(`.${SURFACE_CLASS_NAME}`);
  const blockElement = selection.focusNode.parentNode.closest(BLOCK_ELEMENTS);

  if (!block || !surfaceElement || !blockElement) return [];

  const blockId = block.id;
  const surfaces = block.getAttribute('surfaces').split(' ');
  const surfaceIndex = surfaceElement.getAttribute(SURFACE_INDEX_ATTRIBUTE);
  const surfaceAttribute = surfaces[surfaceIndex];
  const blockSurfaceName = websiteDesign.helpers.getBlockSurfaceName(surfaceAttribute);
  const preDefinedColors = getPredefinedColorsForBlock(
    blockSurfaceName,
    blockElement.tagName,
    currentPalette.variables
  );
  const blockBackground = websiteDesign.helpers.getBlockSurfaceBackground(
    blockSurfaceName,
    currentPalette.variables
  );

  const colorOptions = [...preDefinedColors, ...customColors].map(({ value, name }) => (
    <ColorOption
      key={`${name}_${value}`}
      color={value}
      backgroundColor={blockBackground}
      handleClick={() => {
        let cssVariableName = name;

        if (!cssVariableName) {
          cssVariableName = textFormatting.helpers.getCssVariableNameByColor(value);
        }

        restoreRange(textFormatting.accessors.getAdvancedSelection(), elementId);
        setColorOption(cssVariableName, value, elementId, blockId, surfaceAttribute);
        restoreRange(textFormatting.accessors.getAdvancedSelection(), elementId);
      }}
    />
  ));

  const referentWhite = currentPalette.variables.wsColorTextWhite;
  const whiteHSL = new HSLColor(...Object.values(parseHslColorString(`hsl(${referentWhite})`)));
  const bgHSL = new HSLColor(...Object.values(parseHslColorString(blockBackground)));
  const isLight = contrastRatio(bgHSL, whiteHSL) < common.LIGHT_THEME_CR;
  const optimalContrastRatio = isLight ? common.LIGHT_THEME_CR : common.DARK_THEME_CR;

  colorOptions.push(
    <AddCustomTextColorButton
      key={`add_color_option_${Date.now()}`}
      onShowDialog={() => {
        restoreRange(textFormatting.accessors.getAdvancedSelection(), elementId);
        dialogs.operations.show(dialogTypes.ADD_CUSTOM_COLOR_DIALOG, {
          elementId,
          blockId,
          surfaceAttribute,
          backgroundColor: blockBackground,
          isLight,
          optimalContrastRatio,
        });
      }}
    />
  );

  return colorOptions;
};

export default customColorHeader;
