import { anodum } from '@yola/ws-sdk';
import concatLists from './concat-lists';

const { isTextNode, isElementNode, isNonEmptyTextNode } = anodum;

const normalizeList = (sourceList) => {
  const listTagName = sourceList.tagName;

  let prevNode = sourceList.previousSibling;
  let nextNode = sourceList.nextSibling;
  let list = sourceList;
  let isConcatenatedWithPreviousList = false;

  while (prevNode) {
    if (isTextNode(prevNode) && isNonEmptyTextNode(prevNode)) {
      break;
    }

    if (isElementNode(prevNode)) {
      if (prevNode.tagName === listTagName) {
        list = concatLists(prevNode, list);
        isConcatenatedWithPreviousList = true;
      }

      break;
    }

    prevNode = prevNode.previousSibling;
  }

  if (isConcatenatedWithPreviousList) return;

  while (nextNode) {
    if (isTextNode(nextNode) && isNonEmptyTextNode(nextNode)) {
      break;
    }

    if (isElementNode(nextNode)) {
      if (nextNode.tagName === listTagName) {
        list = concatLists(list, nextNode);
      }

      break;
    }

    nextNode = nextNode.nextSibling;
  }
};

export default normalizeList;
