import { bowser } from '@yola/ws-sdk';

const Z_KEY = 'z';

const isUndoEvent = (event) => {
  const { ctrlKey, key, metaKey, shiftKey } = event;
  const isLinux = bowser.linux;
  const isMacOS = bowser.macOS;
  const isWindows = bowser.windows;

  if (shiftKey) return false;

  if (isMacOS) {
    return key.toLowerCase() === Z_KEY && metaKey;
  }

  if (isWindows || isLinux) {
    return key.toLowerCase() === Z_KEY && ctrlKey;
  }

  return false;
};

export default isUndoEvent;
