import React from 'react';
import PropTypes from 'prop-types';
import { Text, Icon } from '@yola/ws-ui';

const RequestWidget = ({ captions, onClick, href }) => (
  <div className="ws-add-widget-dialog__request-widget">
    <Icon glyph="request" strokeWidth="1.3" className="ws-add-widget-dialog__request-widget-icon" />
    <div className="ws-add-widget-dialog__request-widget-content">
      <Text>{captions.title}</Text>
      <a
        className="ws-button ws-button--primary ws-button--stretch-content ws-button--small"
        target="blank"
        href={href}
        onClick={onClick}
      >
        {captions.button}
      </a>
    </div>
  </div>
);

RequestWidget.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
  }).isRequired,
  href: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default RequestWidget;
