import bowser from 'yola-bowser';
import { view, hdrm } from '@yola/ws-sdk';
import stickTypes from '../constants/stick-types';
import { ADD_BLOCKS_BTN_HEIGHT } from '../constants/sizes';
import getPageContainerOffset from '../../utils/helpers/get-page-container-offset';
import adjacentPositions from '../constants/adjacent-positions';
import isElementWidget from './is-element-widget';
import getNavbarHeight from '../../navbar/accessors/get-height';

const { AFTER_END } = adjacentPositions;
const DIRECTION_VERTICAL = 'vertical';

const calcTriggerPosition = (
  element,
  adjacentPosition,
  scrollPosition,
  pageContainerSelector,
  windowHeight
) => {
  let bounds = element.getBoundingClientRect();
  let isPrevElementBoundsUsed = false;

  // we use bounds of prev element, if it overlaps target one
  // (for ex., header that overlays next block)
  if (adjacentPosition !== AFTER_END) {
    const { referenceMap } = hdrm.instance.driver;
    const prevElementId = referenceMap.getPreviousReferenceId(element);
    const prevElement = view.accessors.getLiveElement(prevElementId);

    if (prevElement) {
      const prevElementBounds = prevElement.getBoundingClientRect();
      if (prevElementBounds.bottom > bounds.top) {
        bounds = prevElementBounds;
        isPrevElementBoundsUsed = true;
      }
    }
  }

  const { left, right, height, width } = bounds;
  const elementFlowType = view.helpers.getElementFlowType(element);
  const pageContainerOffset = getPageContainerOffset(pageContainerSelector);
  const { offsetHeight } = element;
  const isStickyModeDisabled = isElementWidget(element);

  let { top, bottom } = bounds;
  let { offsetTop } = element;

  if (bowser.ios) {
    top -= scrollPosition;
    bottom -= scrollPosition;
  }

  if (offsetTop === 0) {
    offsetTop += pageContainerOffset;
  }

  let offsetX;
  let offsetY;
  let stick = stickTypes.NONE;

  if (elementFlowType === DIRECTION_VERTICAL) {
    offsetX = left + width / 2;

    if (adjacentPosition === AFTER_END) {
      offsetY = bottom;

      if (!isStickyModeDisabled) {
        const navbarHeight = getNavbarHeight();
        if (bottom > windowHeight - navbarHeight) {
          offsetY = windowHeight - ADD_BLOCKS_BTN_HEIGHT;
          stick = stickTypes.BOTTOM;
        }

        const liveDocument = view.accessors.getLiveDocument();
        if (offsetTop + offsetHeight >= liveDocument.body.scrollHeight - pageContainerOffset) {
          stick = stickTypes.BOTTOM;
        }
      }
    } else if (isPrevElementBoundsUsed) {
      offsetY = bottom;
      if (!isStickyModeDisabled && bottom < 0) {
        offsetY = 0;
        stick = stickTypes.TOP;
      }
    } else {
      offsetY = top;
      if (!isStickyModeDisabled && top < 0) {
        offsetY = 0;
        stick = stickTypes.TOP;
      }
    }

    if (
      !isStickyModeDisabled &&
      offsetY < ADD_BLOCKS_BTN_HEIGHT / 3 &&
      (adjacentPosition !== AFTER_END || offsetHeight < ADD_BLOCKS_BTN_HEIGHT / 2)
    ) {
      stick = stickTypes.TOP;
      offsetY = 0;
    }
  } else {
    offsetX = adjacentPosition === AFTER_END ? right : left;
    offsetY = top + height / 2;
  }

  offsetY += scrollPosition;

  return {
    x: Math.round(offsetX),
    y: Math.round(offsetY),
    stick,
  };
};

export default calcTriggerPosition;
