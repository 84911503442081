import { accessors, constants } from '@yola/subscription-manager-js';

const {
  hostingPackageFeatureNames: { PAGE_COUNT },
} = constants;

const pagesLimit = ({ targetPackage }) => {
  const topPackage = accessors.getTopAvailableHostingPackage();

  return {
    amount: targetPackage.features[PAGE_COUNT],
    upgradeAvailable: topPackage.features[PAGE_COUNT] > targetPackage.features[PAGE_COUNT],
  };
};

export default pagesLimit;
