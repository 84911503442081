import actionTypes from '../constants/action-types';

const finishPublishing = ({ siteUrl, withUpsell, isPublished, partnerId }) => ({
  type: actionTypes.FINISH_PUBLISHING,
  payload: {
    siteUrl,
    withUpsell,
    isPublished,
    partnerId,
  },
});

export default finishPublishing;
