const CONFLICT_CONTROLS = ['link', 'text-link'];

const isControlAlreadyExist = (currentControl, list) =>
  list.some((controlItem) => {
    if (CONFLICT_CONTROLS.includes(currentControl.id)) {
      return CONFLICT_CONTROLS.includes(controlItem.id);
    }
    return controlItem.id === currentControl.id;
  });

export default isControlAlreadyExist;
