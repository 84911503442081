import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { Icon } from '@yola/ws-ui';
import bowser from 'yola-bowser';
import { i18next } from '@yola/ws-sdk';
import ProductsList from '../components/products-list';
import PurchaseConfirmation from '../components/purchase-confirmation';

function ProductSelectDialogContainer({
  description,
  terms,
  products,
  onCloseDialog,
  onConfirmPurchase,
}) {
  const [product, setProduct] = useState(null);

  const onBackButtonClick = () => {
    if (product) {
      setProduct(null);
    } else {
      onCloseDialog();
    }
  };

  const getBackButtonLabel = () => {
    const extendedLabel = product
      ? i18next.t('Back to plan selection')
      : i18next.t('Back to editing');
    return bowser.mobile ? i18next.t('Back') : extendedLabel;
  };

  return (
    <ReactModal
      isOpen
      overlayClassName={{
        base: 'dialog-overlay--shaded dialog-overlay--fade dialog-overlay--middle-scroll',
        afterOpen: 'dialog-overlay--after-open',
        beforeClose: 'dialog-overlay--before-close',
      }}
      className="product-select-container"
    >
      <button type="button" className="dialog__overlay-back-button" onClick={onBackButtonClick}>
        <Icon glyph="chevron-left" strokeWidth="1.3" />
        {getBackButtonLabel()}
      </button>

      <SwitchTransition>
        {!product ? (
          <CSSTransition key="list" classNames="fade-transition" timeout={250}>
            <ProductsList description={description} products={products} onSelect={setProduct} />
          </CSSTransition>
        ) : (
          <CSSTransition key="confirm" classNames="fade-transition" timeout={250}>
            <PurchaseConfirmation
              terms={terms}
              product={product}
              onConfirm={onConfirmPurchase}
              onCancel={onCloseDialog}
            />
          </CSSTransition>
        )}
      </SwitchTransition>
    </ReactModal>
  );
}

ProductSelectDialogContainer.propTypes = {
  description: PropTypes.string.isRequired,
  terms: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string,
      current: PropTypes.bool,
      name: PropTypes.string,
      price: PropTypes.string,
      term: PropTypes.string,
      description: PropTypes.string,
      available: PropTypes.bool,
    })
  ).isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  onConfirmPurchase: PropTypes.func.isRequired,
};

export default ProductSelectDialogContainer;
