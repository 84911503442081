import React, { useState, useRef, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import beautify from 'js-beautify';
import classnames from 'classnames';
import { Text, Modal, ButtonGroup, Button, PanelGroup, Panel, designSystem } from '@yola/ws-ui';
import bowser from 'yola-bowser';
import constants from '../constants';

const initialWidth = 850;
const initialHeight = 470;
const { SimpleSelect, Box, CodeEditor } = designSystem;
function getInitialOffset() {
  return {
    x: (window.innerWidth - initialWidth) / 2,
    y: (window.innerHeight - initialHeight) / 2,
  };
}

function createData(pageHTML, globals) {
  const data = {
    [constants.ACTIVE_PAGE_KEY]: {
      html: pageHTML,
      // i18next function isn't added expressly. Current functionality now is used only by employees,
      // so we can skip adding multilingual translation to save costs & time
      label: 'Active page',
      value: constants.ACTIVE_PAGE_KEY,
    },
  };

  globals.forEach((global) => {
    data[global.id] = {
      initialHTML: global.html,
      html: global.html,
      // Now globals don't have titles in definition. We can add them in future to make global names
      // more human readable
      label: global.id,
      value: global.id,
    };
  });

  return data;
}

export default function DeveloperConsoleDialog({
  captions,
  pageHTML,
  globals,
  onSubmit,
  onCancel,
}) {
  const [data, setData] = useState(createData(pageHTML, globals));
  const [options] = useState(Object.values(data));
  const [active, setActive] = useState(constants.ACTIVE_PAGE_KEY);
  const initialOffset = useMemo(getInitialOffset, []);
  const [modalOffset, setModalOffset] = useState(initialOffset);
  const initialHTML = beautify.html(pageHTML);
  const [html, setHTML] = useState(initialHTML);

  function onDragEnd(_, { x, y }) {
    setModalOffset({ x, y });
  }

  function getUpdatedData() {
    return {
      ...data,
      [active]: {
        ...data[active],
        html,
      },
    };
  }

  function onActiveChange(option) {
    setData(getUpdatedData());
    setActive(option.value);
  }

  function onChange(value) {
    setHTML(value);
  }

  function submit() {
    const updatedData = getUpdatedData();

    const activePage = updatedData[constants.ACTIVE_PAGE_KEY];
    const changedGlobals = [];

    Object.keys(updatedData).forEach((key) => {
      // Skip page html & unchanged globals
      if (
        key === constants.ACTIVE_PAGE_KEY ||
        updatedData[key].html === updatedData[key].initialHTML
      )
        return;

      changedGlobals.push({
        id: key,
        html: updatedData[key].html,
      });
    });

    onSubmit({ pageHTML: activePage.html, changedGlobals });
  }

  useEffect(() => {
    setHTML(beautify.html(data[active].html));
  }, [active, data]);

  return (
    <Modal
      className="ws-developer-console"
      width={initialWidth}
      height={initialHeight}
      minWidth={500}
      minHeight={350}
      offsetX={initialOffset.x}
      offsetY={initialOffset.y}
      fullscreen={bowser.mobile}
      dragHandleSelector=".ws-panel:first-of-type"
      onDragEnd={onDragEnd}
    >
      <PanelGroup height="100%">
        <Panel
          theme="gray-100"
          height="48"
          align="middle"
          corners="squared"
          className="ws-modal__content"
        >
          <Text type="heading-6">{captions.title}</Text>
        </Panel>

        <Panel className="ws-developer-console__content-panel" height="100%">
          <div className="ws-modal__content ws-modal__block">
            <Text type="heading-6">{captions.select.label}</Text>
            <Box marginTop="spacing-3-xs" marginBottom="spacing-m">
              <div className="ws-developer-console__select-container">
                <SimpleSelect
                  options={options}
                  name="file-select"
                  onChange={onActiveChange}
                  defaultValue={{ label: active, value: active }}
                />
              </div>
            </Box>

            <CodeEditor
              value={html}
              height="stretch"
              options={{
                tabSize: 4,
                fontSize: 14,
              }}
              onChange={onChange}
            />
            <style
              // eslint-disable-next-line yola/react/no-danger
              dangerouslySetInnerHTML={{
                __html: `
                .ace_tooltip {
                  max-width: initial;
                  transform: translate(${-modalOffset.x}px, ${-modalOffset.y}px);
                }
              `,
              }}
            />
          </div>
        </Panel>
        <Panel>
          <ButtonGroup block stick="top">
            <Button stretch="block" size="large" onClick={submit}>
              {captions.submit}
            </Button>
            <Button stretch="block" size="large" onClick={onCancel}>
              {captions.cancel}
            </Button>
          </ButtonGroup>
        </Panel>
      </PanelGroup>
    </Modal>
  );
}

DeveloperConsoleDialog.propTypes = {
  pageHTML: PropTypes.string.isRequired,
  globals: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  captions: PropTypes.shape.isRequired,
};
