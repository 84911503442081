import Vector from './vector';

const { PI, random, floor, cos, sin } = Math;
const DEGREES_TO_RADIANS = PI / 180;
const { devicePixelRatio } = window;

function getRandomArrayItem(array) {
  return array[floor(random() * array.length)];
}

function Paper(x, y, width, height, colors) {
  const [firstColor, secondColor] = getRandomArrayItem(colors);
  const angle = DEGREES_TO_RADIANS * random() * 360;

  this.corners = [];
  this.size = 5.0;
  this.time = random();
  this.frontColor = firstColor;
  this.backColor = secondColor;

  this.rotation = DEGREES_TO_RADIANS * random() * 360;
  this.rotationSpeed = random() * 600 + 800;
  this.currentRotationPosition = 1.0;
  this.xSpeed = 40.0;
  this.ySpeed = random() * 60 + 50.0;
  this.oscillationSpeed = random() * 1.5 + 0.5;

  this.position = new Vector(x, y);
  this.bounds = new Vector(width, height);

  for (let i = 0; i < 4; i += 1) {
    const deltaX = cos(angle + DEGREES_TO_RADIANS * (i * 90 + 45));
    const deltaY = sin(angle + DEGREES_TO_RADIANS * (i * 90 + 45));
    this.corners[i] = new Vector(deltaX, deltaY);
  }

  this.update = (value) => {
    this.time += value;

    this.updateRotation(value);
    this.updatePosition(value);
  };

  this.draw = (ctx) => {
    ctx.fillStyle = this.currentRotationPosition > 0 ? this.frontColor : this.backColor;

    ctx.beginPath();

    for (let i = 0; i < 4; i += 1) {
      const xPosition = (this.position.x + this.corners[i].x * this.size) * devicePixelRatio;
      const yPosition =
        (this.position.y + this.corners[i].y * this.size * this.currentRotationPosition) *
        devicePixelRatio;

      if (i === 0) {
        ctx.moveTo(xPosition, yPosition);
      } else {
        ctx.lineTo(xPosition, yPosition);
      }
    }

    ctx.closePath();
    ctx.fill();
  };

  this.updateRotation = (value) => {
    this.rotation += this.rotationSpeed * value;
    this.currentRotationPosition = cos(DEGREES_TO_RADIANS * this.rotation);
  };

  this.updatePosition = (value) => {
    this.position.x += cos(this.time * this.oscillationSpeed) * this.xSpeed * value;
    this.position.y += this.ySpeed * value;

    if (this.position.y > this.bounds.y) {
      this.position.x = random() * this.bounds.x;
      this.position.y = 0;
    }
  };

  this.updateBounds = (boundsWidth, boundsHeight) => {
    this.bounds = new Vector(boundsWidth, boundsHeight);
  };
}

export default Paper;
